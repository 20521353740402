import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { isMobile } from 'utils/mobileUtils'
import DazzedParagraph10 from 'components/common/text/DazzedParagraph10'
import useRolePermissions from 'hooks/useRolePermissions'
import AssignPatientFloatingPopup from './AssignPatientFloatingPopup'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import Actions from 'actions'
import AssignPatientTrigger from './AssignPatientTrigger'
import AssigneeActions from './AssigneeActions'

const useStyles = makeStyles(theme => ({
  mobileTitle: {
    color: '#828999',
    marginRight: 5
  },
  clickable: {
    cursor: 'pointer'
  },
  item: {
    display: 'flex'
  }
}))

const Assignee = ({ patient, assigneeTag }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { data: practiceMembers } = useSelector(state => state.profileReducer.practiceMembers)

  const isAssigned = useMemo(() => !!assigneeTag?.value, [assigneeTag?.value])

  const handleAssignToMemeber = useCallback(() => {
    if (!permissions.setPatientAssignee) {
      return
    }

    trackEvent('Assign patient - doctor click on assign patient to team member', {
      origin: 'Patients header'
    })
    dispatch(Actions.setAssignPatient({ patient }))
    setIsMenuOpen(true)
  }, [permissions, patient, dispatch])

  const assignee = useMemo(
    () => (isAssigned ? practiceMembers?.find(member => member.id === assigneeTag?.assigneeId) : null),
    [assigneeTag, isAssigned, practiceMembers]
  )

  return (
    <Grid id="patient-room-header-assignee" container alignItems="center">
      {isMobile() && isAssigned && (
        <Grid item>
          <DazzedParagraph10 className={classes.mobileTitle}>
            {t('pages.patients.selectedPatient.header.assignedTo')}:
          </DazzedParagraph10>
        </Grid>
      )}
      <Grid item className={permissions.setPatientAssignee ? classes.clickable : ''}>
        <AssignPatientFloatingPopup
          isOpen={isMenuOpen}
          onOpen={handleAssignToMemeber}
          onClose={() => setIsMenuOpen(false)}
          triggerComponent={
            <AssignPatientTrigger isAssigned={isAssigned} assignee={assignee} assigneeTag={assigneeTag} />
          }
        />
      </Grid>
      {!isMobile() && isAssigned && (
        <Grid item className={classes.item}>
          <AssigneeActions assigneeTag={assigneeTag} />
        </Grid>
      )}
    </Grid>
  )
}

export default Assignee
