import React, { Suspense } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, CircularProgress } from '@material-ui/core'
import useAutochart from './useAutochart'
import { useSelector } from 'react-redux'
const AutocartGridTeeth = React.lazy(() => import('./AutocartGridTeeth'))

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: 'var(--bg-color-55)',
    padding: '0 clamp(40px, 5%, 80px)',
    borderRadius: 16,
    height: 'var(--autochart-chart-height)',
    '&.expanded': {
      height: '65vh'
    }
  },
  loadingStateContainer: {
    height: '100%'
  }
}))

const AutochartGrid = ({ isLoading = false }) => {
  const classes = useStyles()

  const { autochart, updateToothConditions } = useAutochart()
  const { isRightPanelVisible } = useSelector(state => state.autochartReducer)

  const renderLoadingState = () => {
    return (
      <Grid className={classes.loadingStateContainer} container alignItems="center" justifyContent="center">
        <CircularProgress size={18} />
      </Grid>
    )
  }

  return (
    <div className={[classes.root, !isRightPanelVisible ? 'expanded' : ''].join(' ')}>
      <Suspense fallback={renderLoadingState()}>
        {isLoading ? (
          renderLoadingState()
        ) : (
          <AutocartGridTeeth teethData={autochart?.teeth} onToothUpdated={updateToothConditions} />
        )}
      </Suspense>
    </div>
  )
}

export default AutochartGrid
