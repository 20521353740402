import Actions from 'actions'
import TreatmentStatusLabel from 'components/common/inputs/StatusPicker/TreatmentStatusLabel'
import {
  ALIGNERS_TREATMENT_TYPE,
  APPLIANCE_SETS_MAX_NUMBER,
  APPLIANCE_SETS_MIN_NUMBER,
  BRACES_TREATMENT_TYPE,
  OTHER_TREATMENT_TYPE,
  TreatmentTypes
} from 'consts/treatmentConsts'
import usePatientFieldValidation from 'hooks/usePatientFieldValidation'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { ScanFrequencyOptions } from 'utils/statusUtils'
import { getEnvironment } from 'utils/awsUtils'
import { selectStatuses } from 'selectors/statusesSelector'
import usePlanLimits from 'hooks/usePlanLimits'
import useHiTools from 'hooks/useHiTools'
import { GuidelineCategories } from 'consts/hiToolsConsts'

const defaultStatus = 'active-treatment_rm'
const defaultGPStatus = 'observation_rm'

export default () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { validateFields: validatePatientFields } = usePatientFieldValidation()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [guardianFirstName, setGuardianFirstName] = useState('')
  const [guardianLastName, setGuardianLastName] = useState('')
  const [guardianEmail, setGuardianEmail] = useState('')
  const [showRecepientEmail, setShowRecepientEmail] = useState(null)
  const [countryCode, setCountryCode] = useState('')
  const [phone, setPhone] = useState('')
  const [phoneCountry, setPhoneCountry] = useState('')
  const [treatmentType, setTreatmentType] = useState('')
  const [cycleInterval, setCycleInterval] = useState(7)
  const [cycleIntervalPeriodType, setCycleIntervalPeriodType] = useState('day')
  const [firstScanDate, setFirstScanDate] = useState(moment().add(2, 'days'))
  const [formErrors, setFormErrors] = useState()
  const [shouldShowErrors, setShouldShowErrors] = useState(false)
  const [legalGuardianNeeded, setLegalGuardianNeeded] = useState(false)
  const [treatmentStatus, setTreatmentStatus] = useState('')
  const [totalAlignersSets, setTotalAlignersSets] = useState('')
  const [shouldLinkToSibling, setShouldLinkToSibling] = useState(false)
  const [siblingToLink, setSiblingToLink] = useState(null)

  const { id: doctorId, username: doctorUsername } = useSelector(state => state.profileReducer.doctor)
  const practiceDetails = useSelector(state => state.practiceReducer.details)
  const { isLoading: isFetchingSeats, data: seats } = useSelector(state => state.billingReducer.seats)
  const { data: statuses } = useSelector(selectStatuses)
  const { practiceGuidelines } = useHiTools()

  const { isGP } = usePlanLimits()

  const rmProgramStatuses = useMemo(() => statuses['rm'] || [], [statuses])
  const selectedStatus = useMemo(
    () => rmProgramStatuses.find(option => option.key === treatmentStatus),
    [rmProgramStatuses, treatmentStatus]
  )

  const scanFrequencyByPracticeGuidelines = useMemo(() => {
    let days = null

    if (selectedStatus?.key === defaultStatus) {
      switch (treatmentType) {
        case TreatmentTypes.Aligners:
          days =
            practiceGuidelines.categories[GuidelineCategories.AlignersTxTracking]?.items?.scanFrequency?.frequency
              ?.frequency
          break
        case TreatmentTypes.Braces:
          days =
            practiceGuidelines.categories[GuidelineCategories.BracesTxTracking]?.items?.scanFrequency?.frequency
              ?.frequency
          break
        default:
      }
    }

    return days
  }, [practiceGuidelines.categories, selectedStatus, treatmentType])

  const scanFrequencyDefaults = useMemo(
    () => JSON.parse(selectedStatus?.scanFrequencyDefaults || '{}'),
    [selectedStatus]
  )

  const treatmentTypeOptions = useMemo(
    () => [
      { label: t('dialogs.scanFrequency.aligners'), value: ALIGNERS_TREATMENT_TYPE },
      { label: t('dialogs.scanFrequency.braces'), value: BRACES_TREATMENT_TYPE },
      { label: t('dialogs.scanFrequency.other'), value: OTHER_TREATMENT_TYPE }
    ],
    [t]
  )

  const treatmentStatusOptions = useMemo(
    () =>
      [
        {
          label: <TreatmentStatusLabel status="observation_rm" />,
          value: 'observation_rm'
        },
        {
          label: <TreatmentStatusLabel status="active-treatment_rm" />,
          value: 'active-treatment_rm'
        },
        { label: <TreatmentStatusLabel status="retention_rm" />, value: 'retention_rm' }
      ].filter(statusOption => (isGP ? statusOption.value === 'observation_rm' : true)),
    [isGP]
  )

  const patientEmail = useMemo(
    () => (!legalGuardianNeeded || (legalGuardianNeeded && showRecepientEmail) ? email : guardianEmail),
    [legalGuardianNeeded, email, guardianEmail, showRecepientEmail]
  )

  const clearFields = useCallback(() => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setCountryCode('')
    setPhone('')
    setPhoneCountry('')
    setCycleInterval(7)
    setCycleIntervalPeriodType('day')
    setFirstScanDate(moment().add(2, 'days'))
    setTreatmentType('')
    setFormErrors()
    setShouldShowErrors(false)
    setLegalGuardianNeeded(false)
    setGuardianFirstName('')
    setGuardianLastName('')
    setGuardianEmail('')
    setTreatmentStatus()
    setTotalAlignersSets('')
    setShowRecepientEmail(null)
    setShouldLinkToSibling(false)
    setSiblingToLink(null)
  }, [])

  const showUpgradePlanWarningModal = useCallback(() => {
    dispatch(Actions.setUpgradePlanWarningModalVisibility(true))
  }, [dispatch])

  const inviteNewPatient = useCallback(
    ({ shouldOpenPatientBriefModal }) => {
      const inviteeEmail = siblingToLink?.email?.toLowerCase() ?? patientEmail?.toLowerCase()

      trackEvent('Invite patient modal - Patient invited', {
        treatmentType,
        treatmentStatus,
        cycleInterval,
        cycleIntervalPeriodType,
        isUnder18: legalGuardianNeeded,
        isSibling: !!siblingToLink,
        invitationSentTo: !showRecepientEmail && legalGuardianNeeded ? 'guardian' : 'patient',
        inviteeEmail,
        guardianEmail
      })

      dispatch(
        Actions.inviteNewPatient({
          patientEmail: inviteeEmail,
          patientFirstName: firstName,
          patientLastName: lastName,
          patientName: `${firstName} ${lastName}`,
          guardianFirstName,
          guardianLastName,
          guardianEmail,
          doctorUsername,
          doctorId,
          countryCode,
          treatmentType,
          postConfirmationStatusKey: treatmentStatus,
          phone,
          cycleInterval,
          cycleIntervalPeriodType,
          firstScanDate: firstScanDate.startOf('day').format('YYYY-MM-DD'),
          country: practiceDetails.country,
          totalApplianceNumber:
            treatmentType === ALIGNERS_TREATMENT_TYPE && totalAlignersSets ? totalAlignersSets : null,
          guardianId: siblingToLink?.guardianId,
          options: {
            shouldOpenPatientBriefModal
          }
        })
      )
    },
    [
      dispatch,
      patientEmail,
      firstName,
      lastName,
      guardianFirstName,
      guardianLastName,
      guardianEmail,
      siblingToLink,
      doctorUsername,
      doctorId,
      countryCode,
      treatmentType,
      treatmentStatus,
      phone,
      cycleInterval,
      cycleIntervalPeriodType,
      firstScanDate,
      practiceDetails.country,
      totalAlignersSets,
      legalGuardianNeeded,
      showRecepientEmail
    ]
  )

  const validateInputs = useCallback(() => {
    const errors = {}

    if (legalGuardianNeeded && !shouldLinkToSibling) {
      validatePatientFields({
        nameFields: [
          { fieldName: 'guardianFirstName', fieldValue: guardianFirstName },
          { fieldName: 'guardianLastName', fieldValue: guardianLastName },
          { fieldName: 'guardianLastName', fieldValue: guardianLastName }
        ],
        selectionFields: [{ fieldName: 'showRecepientEmail', fieldValue: showRecepientEmail }],
        emailFields: [{ fieldName: 'guardianEmail', fieldValue: guardianEmail }],
        errorsDict: errors
      })
    }

    validatePatientFields({
      nameFields: [
        { fieldName: 'firstName', fieldValue: firstName },
        { fieldName: 'lastName', fieldValue: lastName }
      ],
      emailFields:
        !shouldLinkToSibling && (!legalGuardianNeeded || (legalGuardianNeeded && showRecepientEmail))
          ? [{ fieldName: 'email', fieldValue: email }]
          : [],
      errorsDict: errors
    })

    if (shouldLinkToSibling && !siblingToLink) {
      errors.sibling = t('errors.requiredSelection')
    }

    if (!treatmentType) {
      errors.treatmentType = t('errors.requiredField')
    }

    if (
      treatmentType === ALIGNERS_TREATMENT_TYPE &&
      totalAlignersSets &&
      totalAlignersSets > APPLIANCE_SETS_MAX_NUMBER
    ) {
      errors.totalAlignersSets = t('errors.minSetsAmount', { number: APPLIANCE_SETS_MAX_NUMBER })
    }

    if (
      treatmentType === ALIGNERS_TREATMENT_TYPE &&
      totalAlignersSets &&
      totalAlignersSets < APPLIANCE_SETS_MIN_NUMBER
    ) {
      errors.totalAlignersSets = t('errors.minSetsAmount', { number: APPLIANCE_SETS_MIN_NUMBER })
    }

    setFormErrors(errors)
    return isEmpty(errors)
  }, [
    firstName,
    lastName,
    legalGuardianNeeded,
    validatePatientFields,
    guardianEmail,
    guardianFirstName,
    guardianLastName,
    showRecepientEmail,
    email,
    treatmentType,
    totalAlignersSets,
    shouldLinkToSibling,
    siblingToLink,
    t
  ])

  const scanFrequencyOptions = useMemo(
    () => ScanFrequencyOptions[treatmentStatus?.split?.('_')?.[0]],
    [treatmentStatus]
  )

  useEffect(() => {
    if (scanFrequencyOptions) {
      setCycleInterval(scanFrequencyByPracticeGuidelines || scanFrequencyOptions[0])
      setCycleIntervalPeriodType(scanFrequencyDefaults?.periodType)
    }
  }, [scanFrequencyByPracticeGuidelines, scanFrequencyDefaults, scanFrequencyOptions, treatmentStatus])

  useEffect(() => {
    clearFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (getEnvironment() !== 'production') {
      setEmail(`${firstName}+${lastName}@get-grin.com`)
    }
  }, [firstName, lastName])

  useEffect(() => {
    if (!legalGuardianNeeded) {
      setGuardianFirstName('')
      setGuardianLastName('')
      setGuardianEmail('')
    }
  }, [legalGuardianNeeded])

  useEffect(() => {
    if (!treatmentStatus && isGP !== null) {
      setTreatmentStatus(isGP ? defaultGPStatus : defaultStatus)
    }
  }, [isGP, treatmentStatus])

  useEffect(() => {
    if (!treatmentType && isGP !== null) {
      setTreatmentType(isGP ? TreatmentTypes.Other : '')
    }
  }, [isGP, treatmentStatus, treatmentType])

  return {
    values: {
      email,
      setEmail,
      firstName,
      setFirstName,
      lastName,
      setLastName,
      guardianFirstName,
      setGuardianFirstName,
      guardianLastName,
      setGuardianLastName,
      guardianEmail,
      setGuardianEmail,
      showRecepientEmail: showRecepientEmail,
      setShowRecepientEmail: setShowRecepientEmail,
      countryCode,
      setCountryCode,
      phone,
      setPhone,
      phoneCountry,
      setPhoneCountry,
      treatmentType,
      setTreatmentType,
      cycleInterval,
      setCycleInterval,
      cycleIntervalPeriodType,
      setCycleIntervalPeriodType,
      firstScanDate,
      setFirstScanDate,
      legalGuardianNeeded,
      setLegalGuardianNeeded,
      treatmentStatus,
      setTreatmentStatus,
      totalAlignersSets,
      setTotalAlignersSets,
      shouldLinkToSibling,
      setShouldLinkToSibling,
      siblingToLink,
      setSiblingToLink
    },
    formErrors,
    setFormErrors,
    shouldShowErrors,
    setShouldShowErrors,
    isFetchingSeats,
    seats,
    doctorId,
    clearFields,
    scanFrequencyDefaults,
    treatmentTypeOptions,
    treatmentStatusOptions,
    inviteNewPatient,
    validateInputs,
    scanFrequencyOptions,
    showUpgradePlanWarningModal
  }
}
