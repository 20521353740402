import React, { useCallback, useMemo } from 'react'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import DazzedHeading14 from 'components/common/text/DazzedHeading14'
import { useTranslation } from 'react-i18next'
import DazzedHeading12 from 'components/common/text/DazzedHeading12'

const useStyles = makeStyles({
  container: {
    backgroundColor: 'rgba(226, 229, 252, 0.35)',
    border: '0.85px solid var(--border-color-23)',
    borderRadius: 6,
    color: 'var(--text-color-31)',
    marginLeft: 2,
    marginRight: 2,
    padding: 10
  },
  title: { fontWeight: 600 },
  fieldTitle: {
    color: 'var(--text-color-32)',
    fontWeight: 600,
    padding: '0px !important',
    margin: '0px !important'
  },
  fieldValue: {
    color: '#031432',
    fontWeight: 500
  },
  divider: {
    marginTop: 5
  },
  cancelLinking: {
    textDecoration: 'underline',
    color: 'var(--text-color-3)',
    cursor: 'pointer'
  }
})

const LinkedSibling = ({ values, onDeselectSibling }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const renderField = useCallback(
    ({ fieldTitle, fieldValue, key }) => (
      <Grid key={key} item>
        <Grid direction="column" container>
          <Grid item>
            <DazzedHeading12 className={classes.fieldTitle}>{fieldTitle}</DazzedHeading12>
          </Grid>
          <Grid item>
            <DazzedHeading12 className={classes.fieldValue}>{fieldValue}</DazzedHeading12>
          </Grid>
        </Grid>
      </Grid>
    ),
    [classes]
  )
  const renderFieldRow = useCallback(
    (fields = []) => (
      <Grid spacing={3} direction="row" container>
        {fields.map((field, index) => renderField({ ...field, key: index }))}
      </Grid>
    ),
    [renderField]
  )

  const fieldsToRender = useMemo(() => {
    return {
      accountFields: [
        {
          fieldTitle: t('dialogs.invitePatient.siblingtoLinkInfo.accountLoginEmail'),
          fieldValue: values.siblingToLink?.email
        },
        {
          fieldTitle: t('dialogs.invitePatient.siblingtoLinkInfo.linkedPatients'),
          fieldValue: values.siblingToLink?.name
        }
      ],
      guardianFields: [
        {
          fieldTitle: t('dialogs.invitePatient.siblingtoLinkInfo.guardianName'),
          fieldValue: values.siblingToLink?.parentName
        },
        {
          fieldTitle: t('dialogs.invitePatient.siblingtoLinkInfo.guardianEmail'),
          fieldValue: values.siblingToLink?.parentEmail
        }
      ],
      emptyGuardian: [
        {
          fieldTitle: t('dialogs.invitePatient.siblingtoLinkInfo.noLegalGuardianTitle'),
          fieldValue: t('dialogs.invitePatient.siblingtoLinkInfo.noLegalGuardianDetails')
        }
      ]
    }
  }, [values.siblingToLink, t])

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} alignItems="center" className={classes.container}>
        <Grid item>
          <DazzedHeading14 className={classes.title}>
            {t('dialogs.invitePatient.siblingtoLinkInfo.title')}
          </DazzedHeading14>
        </Grid>

        <Grid xs={12} item>
          {renderFieldRow(fieldsToRender.accountFields)}

          {values.siblingToLink?.parentName?.trim() !== '' || values.siblingToLink?.parentEmail
            ? renderFieldRow(fieldsToRender.guardianFields)
            : renderFieldRow(fieldsToRender.emptyGuardian)}
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item>
          <Grid spacing={1} direction="row" container>
            <Grid item>
              <DazzedHeading12 className={classes.fieldValue}>
                {t('dialogs.invitePatient.siblingtoLinkInfo.wrongPatient')}
              </DazzedHeading12>
            </Grid>
            <Grid item>
              <DazzedHeading12
                className={[classes.fieldValue, classes.cancelLinking].join(' ')}
                onClick={onDeselectSibling}
              >
                {t('dialogs.invitePatient.siblingtoLinkInfo.cacnelLinking')}
              </DazzedHeading12>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LinkedSibling
