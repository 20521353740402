import React, { useMemo, useCallback } from 'react'
import OrderGrinKitsAmount from './OrderGrinKitsAmount'
import OrderGrinKitsAmountMobile from './OrderGrinKitsAmount.mobile'
import useClinic from 'hooks/useClinic'
import usePlanLimits from 'hooks/usePlanLimits'
import { isMobile } from 'utils/mobileUtils'
import { GRIN_SCOPE_MAX_IN_ORDER, GRIN_SCOPE_MIN_IN_ORDER, GRIN_SCOPE_TYPE_MINI } from 'consts/billingConsts'

const OrderGrinKitsAmountContainer = ({ products, shippingFee, formErrors, onShowProductComparison }) => {
  const { canClinicBuyScopes } = useClinic()
  const planLimits = usePlanLimits()

  const handleChangeQuantity = useCallback(({ quantity, onSetQuantity }) => {
    onSetQuantity(quantity)
  }, [])

  const handleClickMinusQuantity = useCallback(({ quantity, onSetQuantity, maxQuantity = GRIN_SCOPE_MAX_IN_ORDER }) => {
    const newQuantity =
      quantity % GRIN_SCOPE_MIN_IN_ORDER === 0
        ? quantity - GRIN_SCOPE_MIN_IN_ORDER
        : quantity - (quantity % GRIN_SCOPE_MIN_IN_ORDER)
    onSetQuantity(newQuantity <= 0 ? 0 : newQuantity > maxQuantity ? maxQuantity : newQuantity)
  }, [])

  const handleClickPlusQuantity = useCallback(({ quantity, maxQuantity = GRIN_SCOPE_MAX_IN_ORDER, onSetQuantity }) => {
    const newQuantity =
      quantity % GRIN_SCOPE_MIN_IN_ORDER === 0
        ? quantity + GRIN_SCOPE_MIN_IN_ORDER
        : quantity + (GRIN_SCOPE_MIN_IN_ORDER - (quantity % GRIN_SCOPE_MIN_IN_ORDER))
    onSetQuantity(newQuantity <= maxQuantity ? newQuantity : maxQuantity)
  }, [])

  const scopeMiniProduct = useMemo(
    () => products?.find(product => product.type === GRIN_SCOPE_TYPE_MINI) || {},
    [products]
  )

  const totalScopesQuantity = useMemo(() => products.reduce((acc, product) => acc + product.quantity, 0), [products])

  const hidePrice = useMemo(
    () => !canClinicBuyScopes() || planLimits.isSubscriptionPlan,
    [canClinicBuyScopes, planLimits.isSubscriptionPlan]
  )

  return isMobile() ? (
    <OrderGrinKitsAmountMobile
      products={products}
      shippingFee={shippingFee}
      formErrors={formErrors}
      onShowProductComparison={onShowProductComparison}
      handleChangeQuantity={handleChangeQuantity}
      handleClickMinusQuantity={handleClickMinusQuantity}
      handleClickPlusQuantity={handleClickPlusQuantity}
      scopeMiniProduct={scopeMiniProduct}
      hidePrice={hidePrice}
      canClinicBuyScopes={canClinicBuyScopes()}
      isSubscriptionPlan={planLimits.isSubscriptionPlan}
      hasAnyAmount={totalScopesQuantity > 0}
    />
  ) : (
    <OrderGrinKitsAmount
      products={products}
      shippingFee={shippingFee}
      formErrors={formErrors}
      onShowProductComparison={onShowProductComparison}
      handleChangeQuantity={handleChangeQuantity}
      handleClickMinusQuantity={handleClickMinusQuantity}
      handleClickPlusQuantity={handleClickPlusQuantity}
      scopeMiniProduct={scopeMiniProduct}
      hidePrice={hidePrice}
      canClinicBuyScopes={canClinicBuyScopes()}
      isSubscriptionPlan={planLimits.isSubscriptionPlan}
      hasAnyAmount={totalScopesQuantity > 0}
    />
  )
}

export default OrderGrinKitsAmountContainer
