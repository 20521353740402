import { AsyncStatus } from 'consts'
import Actions from '../actions'
import { addReferralToTimelineItem, addScanReviewToTimelineItem } from 'utils/timelineV2Utils'

export const TimelineVersions = {
  v1: 'v1',
  v2: 'v2'
}

const DEFAULT_TIMELINE_VERSION = TimelineVersions.v1

const initialState = {
  timelineVersion: DEFAULT_TIMELINE_VERSION,
  isTimelineV2: DEFAULT_TIMELINE_VERSION === TimelineVersions.v1,
  selectedTimelineItemId: null,
  mainFeed: {
    timelineItems: {
      loadStatus: null,
      nextToken: null,
      data: [],
      error: null
    }
  },
  scanFeed: {
    loadStatus: null,
    timelineItem: {}
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.DOCTOR_DETAILS_RECEIVED: {
      const featureFlags = JSON.parse(action.payload?.accountOwner?.user?.featureFlags?.flags || '{}')
      const timelineVersion = featureFlags.timelineV2 ? TimelineVersions.v2 : DEFAULT_TIMELINE_VERSION
      return {
        ...state,
        timelineVersion,
        isTimelineV2: timelineVersion === TimelineVersions.v2
      }
    }
    case Actions.FETCH_PATIENT_TIMELINE_ITEMS:
      return {
        ...state,
        mainFeed: {
          ...state.mainFeed,
          timelineItems: {
            ...initialState.mainFeed.timelineItems,
            loadStatus: AsyncStatus.Loading
          }
        }
      }
    case Actions.FETCH_PATIENT_TIMELINE_ITEMS_FAILED:
      return {
        ...state,
        mainFeed: {
          ...state.mainFeed,
          timelineItems: {
            ...state.mainFeed.timelineItems,
            loadStatus: AsyncStatus.Failed,
            error: action.payload
          }
        }
      }
    case Actions.FETCH_PATIENT_TIMELINE_ITEMS_RECEIVED:
      return {
        ...state,
        mainFeed: {
          ...state.mainFeed,
          timelineItems: {
            ...state.mainFeed.timelineItems,
            loadStatus: AsyncStatus.Completed,
            error: null,
            data: action.payload.data,
            nextToken: action.payload.nextToken
          }
        }
      }
    case Actions.SELECT_TIMELINE_ITEM:
      return {
        ...state,
        selectedTimelineItemId: action.payload.timelineItemId
      }
    case Actions.FETCH_TIMELINE_ITEM:
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          loadStatus: AsyncStatus.Loading
        }
      }
    case Actions.FETCH_TIMELINE_ITEM_RECEIVED:
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          loadStatus: AsyncStatus.Completed,
          timelineItem: action.payload.timelineItem
        }
      }
    case Actions.FETCH_TIMELINE_ITEM_FAILED:
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          timelineItem: initialState.scanFeed.timelineItem,
          loadStatus: AsyncStatus.Failed
        }
      }
    case Actions.UPDATE_GRIN_SCAN_APPLIANCE:
      return !state.isTimelineV2
        ? state
        : {
            ...state,
            scanFeed: {
              ...state.scanFeed,
              timelineItem: {
                ...state.scanFeed.timelineItem,
                payload: JSON.stringify({
                  ...JSON.parse(state.scanFeed.timelineItem.payload || '{}'),
                  alignerNumber: action.payload.scan?.applianceIndex
                })
              }
            }
          }
    case Actions.REQUEST_SEND_SCAN_REVIEW: {
      if (!state.isTimelineV2) {
        return state
      }

      const { scanReviewId, scan, blob, uploadingDate, reviewer } = action.payload

      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          timelineItem: addScanReviewToTimelineItem({
            scanId: scan.id,
            timelineItem: state.scanFeed.timelineItem,
            scanReview: {
              id: scanReviewId,
              createdAt: uploadingDate,
              isLocal: true,
              video: blob,
              reviewer
            }
          })
        }
      }
    }
    case Actions.NEW_SCAN_REVIEW_RECEIVED: {
      if (!state.isTimelineV2) {
        return state
      }

      const { scanReview } = action.payload
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          timelineItem: addScanReviewToTimelineItem({
            scanId: scanReview.grinScanId,
            timelineItem: state.scanFeed.timelineItem,
            scanReview
          })
        }
      }
    }
    case Actions.SHARE_SCAN_TO_REFERRAL_RECEIVED: {
      if (!state.isTimelineV2) {
        return state
      }

      const { grinScanId: scanId, referral } = action.payload
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          timelineItem: addReferralToTimelineItem({
            timelineItem: state.scanFeed.timelineItem,
            scanId,
            referral
          })
        }
      }
    }
    default:
      return state
  }
}
