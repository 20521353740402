import * as React from 'react'

export default () => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 3L3 7L10 11" stroke="#001335" />
    <circle cx="10" cy="3" r="2" fill="#F5F5F5" stroke="#001335" />
    <circle cx="3" cy="7" r="2" fill="#F5F5F5" stroke="#001335" />
    <circle cx="10" cy="11" r="2" fill="#F5F5F5" stroke="#001335" />
  </svg>
)
