import React, { useCallback, useMemo } from 'react'
import { makeStyles, TableFooter, TableRow, TableCell, ButtonBase } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ChevronIcon from '../../common/icons/ChevronDownSmall'

const useStyles = makeStyles({
  root: {
    height: '52px',
    padding: 0,
    border: 0
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    padding: '0px 8px'
  },
  button: {
    width: '22px',
    height: '22px',
    borderRadius: '11px',
    margin: '0px 3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chevronLeft: {
    transform: 'rotate(90deg)'
  },
  chevronRight: {
    transform: 'rotate(270deg)'
  },
  label: {
    marginRight: '46px',
    fontFamily: 'Dazzed',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'var(--text-color-11)'
  }
})

const PatientsTableFooter = ({ paginationData, onPageChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { page, total, from, to, of } = paginationData

  const handlePrevPageClick = useCallback(() => onPageChange(page - 1), [page, onPageChange])

  const handleNextPageClick = useCallback(() => onPageChange(page + 1), [page, onPageChange])

  const hasLeft = useMemo(() => page === 0, [page])
  const hasRight = useMemo(() => page + 1 === total, [page, total])

  return total > 1 ? (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={5} classes={{ root: classes.root }}>
          <div className={classes.content}>
            <ButtonBase className={classes.button} centerRipple disabled={hasRight} onClick={handleNextPageClick}>
              <ChevronIcon className={classes.chevronRight} fill={hasRight ? '#E7E7E7' : '#C4C4C4'} />
            </ButtonBase>
            <ButtonBase className={classes.button} centerRipple disabled={hasLeft} onClick={handlePrevPageClick}>
              <ChevronIcon className={classes.chevronLeft} fill={hasLeft ? '#E7E7E7' : '#C4C4C4'} />
            </ButtonBase>
            <div className={classes.label}>{t('pages.taskManager.pagination', { from, to, of })}</div>
          </div>
        </TableCell>
      </TableRow>
    </TableFooter>
  ) : null
}

export default React.memo(PatientsTableFooter)
