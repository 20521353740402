import * as React from 'react'

const RotateRight = () => (
  <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 8.5C12 11.5376 9.53757 14 6.5 14C3.46243 14 1 11.5376 1 8.5C1 5.46243 3.46243 3 6.5 3"
      stroke="#4451DE"
      strokeWidth="2"
    />
    <path d="M6 3V0L11 3L6 6V3Z" fill="#4451DE" />
    <circle cx="6.5" cy="8.5" r="1.5" fill="#4451DE" />
  </svg>
)

export default RotateRight
