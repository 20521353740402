import * as React from 'react'

export default () => {
  return (
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.07612 5.53172L4.29007 5.50901L4.3762 5.3293C5.00468 4.01812 6.87147 4.01812 7.49995 5.3293L7.58609 5.50901V14.544L7.34578 14.8501C6.47566 15.9584 4.71732 15.6232 4.31592 14.2726L3.43358 14.1395C2.24396 13.9601 1.55735 12.6913 2.05785 11.5973L2.51926 10.5888L2.42184 10.4921C1.64498 9.72141 1.80122 8.42544 2.739 7.86146L2.69505 7.74005C2.32951 6.73017 3.00811 5.64508 4.07612 5.53172Z"
        stroke="#041333"
        strokeLinecap="round"
      />
      <path
        d="M6.07828 15.528C5.04027 15.528 4.19881 14.6865 4.19881 13.6485C4.19881 13.0336 4.49409 12.4877 4.9506 12.1448"
        stroke="#041333"
        strokeLinecap="round"
      />
      <path
        d="M5.51354 8.47923C5.01415 8.19091 4.84305 7.55234 5.13137 7.05295C5.30217 6.75712 5.59587 6.5765 5.91074 6.53833"
        stroke="#041333"
        strokeLinecap="round"
      />
      <path
        d="M2.53082 10.515C2.83827 9.98247 3.36696 9.65733 3.93375 9.58862"
        stroke="#041333"
        strokeLinecap="round"
      />
      <path d="M7.57812 1V19.2031" stroke="#041333" strokeLinecap="round" strokeDasharray="1.61 1.61" />
      <path d="M11.0581 5.01538L9.18428 6.88923H7.57812" stroke="#041333" />
      <path d="M11.0581 15.1877L9.18428 13.3139H7.57812" stroke="#041333" />
      <path d="M13.1997 10.1016H7.57812" stroke="#041333" />
      <path d="M13.1997 7.15698H11.3258L9.71967 8.76314H7.57812" stroke="#041333" />
      <path d="M13.1997 13.0461H11.3258L9.71967 11.44H7.57812" stroke="#041333" />
      <circle cx="11.057" cy="5.01548" r="0.80308" fill="white" stroke="#041333" />
      <circle cx="11.057" cy="15.1878" r="0.80308" fill="white" stroke="#041333" />
      <circle cx="13.1976" cy="7.15684" r="0.80308" fill="white" stroke="#041333" />
      <circle cx="13.1976" cy="13.0462" r="0.80308" fill="white" stroke="#041333" />
      <circle cx="13.1976" cy="10.1017" r="0.80308" fill="white" stroke="#041333" />
    </svg>
  )
}
