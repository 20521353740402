import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import ServicesToggle from './ServicesToggle'
import DoctorLocatorIcon from 'components/common/icons/DoctorLocatorIcon'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import ConfirmationModal from 'components/common/ConfirmationModal'
import ExpandMore from 'components/common/ExpandMore'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import { Grid } from '@material-ui/core'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import usePlanLimits from 'hooks/usePlanLimits'

const useStyles = makeStyles(theme => ({
  description: {
    marginBottom: 10
  },
  bioInput: {
    opacity: 0.7,
    borderRadius: '10px',
    border: '1px solid var(--border-color-24)'
  },
  expandMore: {
    textDecoration: 'underline',
    color: 'var(--text-color-25)',
    fontWeight: 400
  }
}))

const DoctorLocatorSettings = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isFreePlan } = usePlanLimits()

  const practiceConfiguration = usePracticeConfiguration()
  const accountOwnerId = useSelector(state => state.practiceReducer.accountOwner.id)
  const isLoading = useSelector(state => state.practiceReducer.isLoading)

  const [bio, setBio] = useState('')
  const [confirmationModalMode, setConfirmationModalMode] = useState('closed')
  const [expanded, setExpanded] = useState(false)

  const handleToggleClicked = useCallback(checked => {
    const mode = checked ? 'toggleOn' : 'toggleOff'
    trackEvent(`Doctor Locator Settings - toggle clicked`, { value: mode })
    setConfirmationModalMode(mode)
  }, [])

  const handleCancelToggleChanges = useCallback(() => {
    trackEvent('Doctor Locator Settings - toggle canceled')
    setConfirmationModalMode('closed')
  }, [])

  const handleToggleExpand = useCallback(() => {
    setExpanded(!expanded)
    trackEvent('Doctor Locator Settings - show more toggled', { expanded: expanded ? 'Yes' : 'No' })
  }, [expanded])

  const handleSaveInfomration = useCallback(() => {
    trackEvent('Doctor Locator Settings - information saved')

    dispatch(
      Actions.updatePracticeDetails({
        id: accountOwnerId,
        bio
      })
    )
  }, [dispatch, accountOwnerId, bio])

  const handleConfirmToggle = useCallback(() => {
    trackEvent('Doctor Locator Settings - toggle confirmed', { value: confirmationModalMode })
    dispatch(
      Actions.updatePracticeDetails({
        id: accountOwnerId,
        hasLocatorConsent: confirmationModalMode === 'toggleOn'
      })
    )
  }, [confirmationModalMode, accountOwnerId, dispatch])

  useEffect(() => {
    setBio(practiceConfiguration.bio)
  }, [practiceConfiguration.bio])

  useEffect(() => {
    if (!isLoading) {
      setConfirmationModalMode('closed')
    }
  }, [isLoading])

  useEffect(() => {
    setExpanded(practiceConfiguration.isDoctorLocatorEnabled)
  }, [practiceConfiguration.isDoctorLocatorEnabled])

  return (
    <>
      <ServicesToggle
        checked={practiceConfiguration.isDoctorLocatorEnabled}
        onChange={handleToggleClicked}
        tagComponent={<DoctorLocatorIcon />}
        title={t('pages.accountSettings.services.doctorLocator.title')}
        disabled={isFreePlan}
      >
        <DazzedParagraph14 strong className={classes.description}>
          {t('pages.accountSettings.services.doctorLocator.description')}
        </DazzedParagraph14>
        <DazzedParagraph14 className={classes.expandMore}>
          <ExpandMore
            expanded={expanded}
            onClick={handleToggleExpand}
            className={classes.expandMore}
            showMoreText={t('pages.accountSettings.services.doctorLocator.showMoreSettings')}
            showLessText={t('pages.accountSettings.services.doctorLocator.hideMoreSettings')}
          />
        </DazzedParagraph14>
        {expanded && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextAreaAutosize
                isDisabled={!practiceConfiguration.isDoctorLocatorEnabled}
                rowsMax={4}
                style={{ bright: true, thick: true }}
                className={classes.bioInput}
                keepErrorContainerWhenInactive={false}
                value={bio}
                placeholder={t('pages.accountSettings.services.doctorLocator.bioInputPlaceholder')}
                inputStyle={{
                  height: 100,
                  marginTop: 10,
                  padding: 16,
                  fontSize: '12px',
                  fontFamily: 'Dazzed'
                }}
                containerClassName={classes.textAreaContainer}
                setValue={setBio}
              />
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton
                small
                label={t('general.save')}
                isLoading={isLoading && confirmationModalMode === 'closed'}
                onClick={handleSaveInfomration}
                disabled={!practiceConfiguration.isDoctorLocatorEnabled}
              />
            </Grid>
          </Grid>
        )}
      </ServicesToggle>
      <ConfirmationModal
        isOpen={confirmationModalMode !== 'closed'}
        title={t('pages.accountSettings.services.doctorLocator.confirmationPopup.title')}
        text={t(`pages.accountSettings.services.doctorLocator.confirmationPopup.${confirmationModalMode}`)}
        onConfirm={handleConfirmToggle}
        onClose={handleCancelToggleChanges}
        isLoading={isLoading}
      />
    </>
  )
}

export default DoctorLocatorSettings
