import React, { useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useMessageSender from 'hooks/useMessageSender'
import { DELETED_MESSAGE_CONTENT } from 'consts/chatConsts'
import { ButtonBase, CircularProgress, Grid, makeStyles } from '@material-ui/core'
import ImageMessage from './MessageTypes/ImageMessage'
import { isMobile } from 'utils/mobileUtils'
import PreliminaryPlanMessage from './PreliminaryPlanMessage'
import ScanMessage from './MessageTypes/ScanMessage'
import TextMessage from './MessageTypes/TextMessage'
import PromotionMessage from './MessageTypes/PromotionMessage'
import DoubleScanMessage from './MessageTypes/DoubleScanMessage'
import BeforeAfterMessage from './MessageTypes/BeforeAfterMessage'
import { TIME_FORMAT_8 } from 'consts/dateTimeConsts'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import Actions from 'actions'
import MessageScheduleTimeLabel from './MessageScheduleTimeLabel'
import { trackEvent } from 'utils/analyticsUtils'
import DeleteScheduledMessagePopup from './ScheduledMessages/DeleteScheduledMessagePopup'
import { getEnvironment } from 'utils/awsUtils'
import GrintelligenceMessage from './MessageTypes/GI/GrintelligenceMessage'
import { GI_RESULT_MSG_TYPE } from 'consts/giConsts'
import VideoMessage from './MessageTypes/VideoMessage'
import PdfMessage from './MessageTypes/PdfMessage'
import OralHygieneRecommendationMessage from './MessageTypes/OralHygieneNote/OralHygieneRecommendationMessage'
import { OH_NOTE_MSG_TYPE } from 'consts/oralHygieneNoteConsts'
import MessageAvatar from './MessageAvatar'
import ImageV2Message from './MessageTypes/ImageV2Message'

const useMessageStyles = ({ alignLeft, isFirstMessage }) =>
  makeStyles({
    container: {
      right: ({ alignLeft }) => (alignLeft ? 'initial' : 0),
      left: ({ alignLeft }) => (alignLeft ? 0 : 'initial'),
      alignSelf: ({ alignLeft }) => (alignLeft ? 'flex-start' : 'flex-end'),
      position: 'relative',
      maxWidth: 500,
      margin: '8px 40px 0 60px',
      marginTop: ({ isFirstMessage }) => (isFirstMessage ? 25 : 8),
      paddingRight: 20,
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'max-content',
      '&.isMobile': {
        marginTop: ({ isFirstMessage }) => (isFirstMessage ? 20 : 10),
        marginRight: 25,
        marginLeft: 45
      }
    },
    content: {
      minWidth: '180px',
      lineHeight: 1.5,
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      whiteSpace: 'pre-wrap',
      justifyContent: ({ alignLeft }) => (alignLeft ? 'flex-start' : 'flex-end')
    },
    contentScheduled: {
      opacity: 0.65
    },
    sendingContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginRight: 20,
      marginTop: 5,
      fontSize: 10
    },
    circular: {
      color: 'var(--bg-color-13)',
      margin: '0 10px'
    },
    deleteButtonContainer: {
      padding: '3px 7px'
    },
    deleteButton: {
      color: 'var(--text-color-25)',
      textDecoration: 'underline'
    }
  })({ alignLeft, isFirstMessage })

const Message = ({
  type,
  id,
  grinUserId,
  content,
  isLocal,
  cache,
  timestamp,
  owner,
  _version,
  isDeleting,
  metadata,
  isAdmin,
  isBroadcast,
  href,
  promotionData,
  previousMessageGrinUserId,
  nextMessageGrinUserId,
  scheduleTime,
  promotionId,
  grinScan
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const doctor = useSelector(state => state.profileReducer.doctor)
  const patient = useSelector(state => state.patientsReducer.patient)

  const { name, photo, email, userType } = useMessageSender({ grinUserId, metadata, isAdmin })

  const alignLeft = useMemo(
    () => type === GI_RESULT_MSG_TYPE || grinUserId !== doctor.user.id,
    [grinUserId, doctor, type]
  )
  const isFirstMessage = useMemo(
    () => previousMessageGrinUserId !== grinUserId,
    [previousMessageGrinUserId, grinUserId]
  )
  const classes = useMessageStyles({ alignLeft, isFirstMessage })

  const isLastMessage = useMemo(() => nextMessageGrinUserId !== grinUserId, [nextMessageGrinUserId, grinUserId])
  const messageTimestamp = useMemo(() => moment(timestamp).format(TIME_FORMAT_8), [timestamp])
  const isMessageDeleted = useMemo(() => content === DELETED_MESSAGE_CONTENT, [content])
  const messageMetadata = useMemo(() => JSON.parse(metadata || '{}'), [metadata])
  const isUrgent = useMemo(() => messageMetadata?.isUrgent, [messageMetadata])
  const urgencyReason = useMemo(() => messageMetadata?.urgencyReason, [messageMetadata])
  const messageFileTitle = useMemo(() => messageMetadata?.fileTitle, [messageMetadata])

  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteScheduledMessagePopupOpen, setIsDeleteScheduledMessagePopupOpen] = useState(false)

  const onHoverMessage = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true)
    }
  }, [isOpen])

  const onLeaveMessage = useCallback(() => {
    if (isOpen) {
      setIsOpen(false)
    }
  }, [isOpen])

  const parsedContent = useMemo(
    () =>
      isLocal ||
      ['image', 'video', 'pdf', 'scanReview', 'scan', 'doubleScan', 'newScanReview'].every(
        messageType => messageType !== type
      )
        ? content
        : JSON.parse(content),
    [isLocal, content, type]
  )

  const isBroadcastView = useMemo(() => {
    const { isPersonal } = JSON.parse(metadata || '{}')
    return isBroadcast && !isPersonal
  }, [isBroadcast, metadata])

  const isMessageDeletable = useMemo(() => scheduleTime && !isLocal && !isDeleting, [scheduleTime, isLocal, isDeleting])

  const handleOpenDeleteScheduleMessagePopup = useCallback(() => {
    trackEvent('Chat - delete scheduled message opened')
    setIsDeleteScheduledMessagePopupOpen(true)
  }, [])

  const handleCloseDeleteScheduleMessagePopup = useCallback(() => {
    trackEvent('Chat - delete scheduled message closed')
    setIsDeleteScheduledMessagePopupOpen(false)
  }, [])

  const handleDeleteScheduledMessage = useCallback(() => {
    trackEvent('Chat - scheduled message deleted')
    dispatch(Actions.deleteScheduledMessage({ id }))
    setIsDeleteScheduledMessagePopupOpen(false)
  }, [dispatch, id])

  const handleClick = useCallback(() => {
    if (getEnvironment() !== 'production') {
      navigator.clipboard.writeText(id)
    }
  }, [id])

  return (
    <div
      className={[classes.container, isMobile() && 'isMobile'].join(' ')}
      onMouseMove={onHoverMessage}
      onMouseLeave={onLeaveMessage}
    >
      {scheduleTime && <MessageScheduleTimeLabel scheduleTime={scheduleTime} />}
      <div className={classes.content} onClick={handleClick}>
        <Grid container direction="column" style={{ width: 'auto' }}>
          <Grid item className={scheduleTime ? classes.contentScheduled : ''}>
            {type === 'beforeAfter' ? (
              <BeforeAfterMessage
                content={parsedContent}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                timestamp={messageTimestamp}
                isBroadcast={isBroadcastView}
              />
            ) : type === 'imageV2' ? (
              <ImageV2Message
                content={parsedContent}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                timestamp={messageTimestamp}
                isBroadcast={isBroadcastView}
                fileTitle={messageFileTitle}
              />
            ) : type === 'image' ? (
              <ImageMessage
                content={parsedContent}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                timestamp={messageTimestamp}
                isBroadcast={isBroadcastView}
                fileTitle={messageFileTitle}
              />
            ) : type === 'video' ? (
              <VideoMessage
                content={parsedContent}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                timestamp={messageTimestamp}
                isBroadcast={isBroadcastView}
                fileTitle={messageFileTitle}
              />
            ) : type === 'pdf' ? (
              <PdfMessage
                content={parsedContent}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                timestamp={messageTimestamp}
                isBroadcast={isBroadcastView}
                fileTitle={messageFileTitle}
              />
            ) : type === 'promotion' ? (
              <PromotionMessage
                content={parsedContent}
                alignLeft={alignLeft}
                link={href}
                promotionData={promotionData}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                timestamp={messageTimestamp}
                isBroadcast={isBroadcastView}
                promotionId={promotionId}
              />
            ) : type === 'scanReview' || type === 'scan' || type === 'newScanReview' ? (
              <ScanMessage
                content={parsedContent}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                type={type}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                timestamp={messageTimestamp}
                isUrgent={isUrgent}
                urgencyReason={urgencyReason}
                id={id}
                patientId={patient?.id}
                grinScan={grinScan}
              />
            ) : type === 'doubleScan' ? (
              <DoubleScanMessage
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                content={parsedContent}
                name={name}
                userType={userType}
                type={type}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                timestamp={messageTimestamp}
                isUrgent={isUrgent}
                urgencyReason={urgencyReason}
                id={id}
                patientId={patient?.id}
                grinScan={grinScan}
              />
            ) : type === 'proposal' ? (
              <PreliminaryPlanMessage />
            ) : type === GI_RESULT_MSG_TYPE ? (
              <GrintelligenceMessage
                id={id}
                content={content}
                grinScan={grinScan}
                timestamp={messageTimestamp}
                patientId={patient?.id}
              />
            ) : type === OH_NOTE_MSG_TYPE ? (
              <OralHygieneRecommendationMessage
                id={id}
                content={content}
                timestamp={messageTimestamp}
                grinScanId={grinScan?.id}
              />
            ) : (
              type !== 'transfer' && (
                <TextMessage
                  isFirstMessage={isFirstMessage}
                  isLastMessage={isLastMessage}
                  name={name}
                  userType={userType}
                  content={content}
                  alignLeft={alignLeft}
                  deleted={isMessageDeleted}
                  timestamp={messageTimestamp}
                  isBroadcast={isBroadcastView}
                  metadata={messageMetadata}
                />
              )
            )}
            {isLastMessage && (
              <MessageAvatar alignLeft={alignLeft} email={email} messageType={type} name={name} photo={photo} />
            )}
          </Grid>
          <Grid item className={classes.deleteButtonContainer}>
            {isMessageDeletable && (
              <>
                <ButtonBase onClick={handleOpenDeleteScheduleMessagePopup}>
                  <DazzedParagraph12 className={classes.deleteButton}>{t('general.delete')}</DazzedParagraph12>
                </ButtonBase>
                <DeleteScheduledMessagePopup
                  isOpen={isDeleteScheduledMessagePopupOpen}
                  onClose={handleCloseDeleteScheduleMessagePopup}
                  onConfirm={handleDeleteScheduledMessage}
                />
              </>
            )}
          </Grid>
        </Grid>
      </div>
      <div className={classes.messageInfoContainer}>
        {(isLocal || isDeleting) && (
          <div className={classes.sendingContainer} id="patient-room-chat-sending-loader">
            <CircularProgress className={classes.circular} size={16} />
            {isDeleting
              ? t('pages.patients.selectedPatient.chat.deleting')
              : t('pages.patients.selectedPatient.chat.sending')}
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(Message)
