import React, { useEffect, createRef, useCallback, useMemo, useState } from 'react'
import Message from './Message'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { isUserOfAnyAdminRole } from 'utils/authUtils'
import { isMobile } from 'utils/mobileUtils'
import { Element, scroller } from 'react-scroll'
import { scrollBar } from 'styles/theme'
import usePlanLimits from 'hooks/usePlanLimits'
import { OH_NOTE_MSG_TYPE } from 'consts/oralHygieneNoteConsts'
import useRolePermissions from 'hooks/useRolePermissions'

const useStyles = ({ isMobile }) =>
  makeStyles({
    root: {
      overflowX: 'hidden',
      overflowY: 'auto',
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      '&>:first-child': {
        marginTop: 'auto'
      },
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      flex: 1,
      paddingBottom: 60,
      ...scrollBar
    },
    messageContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'max-content'
    }
  })({ isMobile })

const PatientChatContainer = ({ messages = [], loadMoreMessages }) => {
  const shouldAutoScroll = useSelector(state => state.chatReducer.shouldAutoScrollChat)
  const isAdmin = useMemo(() => isUserOfAnyAdminRole(), [])
  const containerRef = createRef()
  const { isOnHiOrGiPlan } = usePlanLimits()
  const { isHiUser, isGiUser } = useRolePermissions()

  const [didScrollManually, setDidScrollManually] = useState(false)
  const classes = useStyles({ isMobile: isMobile() })

  const messagesWithRefs = useMemo(() => {
    let filteredMessages = messages.filter(msg => msg.type !== 'transferred')

    if (isOnHiOrGiPlan && !isGiUser && !isHiUser) {
      filteredMessages = filteredMessages.filter(msg => msg.type !== OH_NOTE_MSG_TYPE)
    }

    return filteredMessages.map(message => ({
      message,
      ref: createRef()
    }))
  }, [messages, isOnHiOrGiPlan, isGiUser, isHiUser])

  const handleScroll = useCallback(
    e => {
      const el = e.target
      containerRef.current = el

      // Checking if the chat is scrolled all the way down. if no, it means that the user has scrolled somewhat up.
      // This is important in order to prevent auto scrolling from happening when trying to read something above in the chat.
      const isAllTheWayScrolled = el.scrollHeight - Math.round(el.scrollTop) === el.clientHeight
      setDidScrollManually(!isAllTheWayScrolled)

      if (el.scrollTop === 0) {
        loadMoreMessages()
        scroller.scrollTo('patientChatContainer', {
          offset: 1,
          containerId: 'patientChatContainerId'
        })
      }
    },
    [loadMoreMessages, containerRef]
  )

  const handleAutoScroll = useCallback(() => {
    if (messagesWithRefs.length > 0 && messagesWithRefs[0].ref.current) {
      const msgElementHeight = messagesWithRefs[messagesWithRefs.length - 1].ref.current.clientHeight
      const containerHeight = containerRef.current?.clientHeight

      // This fixes a bug where in small screens, the scroll bar would jump up as soon as you scroll down
      // Reference: https://get-grin.monday.com/boards/721918690/pulses/5088713531
      if (!containerHeight || msgElementHeight < containerHeight) {
        messagesWithRefs[messagesWithRefs.length - 1].ref.current.scrollIntoView({
          block: 'start'
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesWithRefs])

  useEffect(() => {
    !didScrollManually && shouldAutoScroll && handleAutoScroll()
  }, [shouldAutoScroll, handleAutoScroll, didScrollManually])

  return (
    <Element name="patientChatContainer" id="patientChatContainerId" className={classes.root} onScroll={handleScroll}>
      {messagesWithRefs.map(({ message, ref }, index) => (
        <div ref={ref} key={message.id} className={classes.messageContainer}>
          <Message
            {...message}
            previousMessageGrinUserId={messagesWithRefs[index - 1]?.message.grinUserId}
            nextMessageGrinUserId={messagesWithRefs[index + 1]?.message.grinUserId}
            isAdmin={isAdmin}
          />
        </div>
      ))}
    </Element>
  )
}

export default React.memo(PatientChatContainer)
