import React from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import NewTimelineAvailable from './NewTimelineAvailable'
import TreatmentTypeChanged from './TreatmentTypeChanged'

const EventItem = ({ event }) => {
  const classes = makeStyles({
    container: {
      paddingLeft: 20
    },
    boldEventText: {
      fontWeight: 600
    },
    timestamp: {
      fontSize: '10px'
    }
  })()

  const getTimelineItem = event => {
    switch (event.eventType) {
      case 'newTimelineAvailable':
        return <NewTimelineAvailable event={event} />
      case 'treatmentTypeChanged':
        return <TreatmentTypeChanged event={event} />
      default:
        return null
    }
  }

  return (
    <Grid container className={classes.scanContainer}>
      <Grid item xs={12}>
        {getTimelineItem(event)}
      </Grid>
    </Grid>
  )
}

export default EventItem
