import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import MedicalHistoryEmptyStateIcon from 'components/common/icons/MedicalHistoryEmptyStateIcon'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import UploadDropzone from 'components/common/UploadDropzone/UploadDropzone'
import { Grid } from '@material-ui/core'
import useRolePermissions from 'hooks/useRolePermissions'

const MedicalHistoryEmptyState = ({ onFileUpload }) => {
  const { t } = useTranslation()

  const { permissions } = useRolePermissions()

  const handleDropFile = useCallback(
    file => {
      if (file.length) {
        onFileUpload(file[0]?.file)
      }
    },
    [onFileUpload]
  )

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        {permissions.uploadInitialMedicalHistoryFile ? (
          <UploadDropzone
            onUploadFile={handleDropFile}
            isClosable={false}
            acceptedFormats={['application/pdf', 'image/jpeg', 'image/png', 'image/jpg']}
          />
        ) : (
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item></Grid>
            <Grid item>
              <MedicalHistoryEmptyStateIcon />
            </Grid>
            <Grid item>
              <DazzedParagraph14>{t('dialogs.patientInfo.medicalHistory.emptyStateText')}</DazzedParagraph14>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default MedicalHistoryEmptyState
