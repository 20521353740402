import Actions from 'actions'
import { AsyncStatus } from 'consts'

const initialState = {
  globalLoadingState: null,
  authorization: {
    status: 'unauthorized',
    sessionId: null,
    medium: null,
    destination: null,
    cooldownTime: null,
    resendLoadingState: null,
    verifyLoadingState: null,
    verificationCodeError: null
  },
  referral: {},
  viewScan: {
    isSubmitting: false
  },
  sessionExpiredModal: {
    isOpen: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.REFERRALS_FETCH_REFERRAL_DATA:
      return {
        ...state,
        globalLoadingState: AsyncStatus.Loading,
        authorization: {
          ...state.authorization
        }
      }
    case Actions.REFERRALS_FETCH_REFERRAL_DATA_RECEIVED:
      return {
        ...state,
        globalLoadingState: AsyncStatus.Completed,
        authorization: {
          ...state.authorization,
          ...(action.payload.status === 'unauthorized' ? action.payload : {})
        },
        referral: action.payload.status === 'authorized' ? action.payload.referral : initialState.referral
      }
    case Actions.REFERRALS_FETCH_REFERRAL_DATA_FAILED:
      return {
        ...state,
        globalLoadingState: AsyncStatus.Failed
      }
    case Actions.REFERRALS_RESEND_CODE:
      return {
        ...state,
        authorization: {
          ...state.authorization,
          ...action.payload,
          resendLoadingState: AsyncStatus.Loading
        }
      }
    case Actions.REFERRALS_RESEND_CODE_RECEIVED:
      return {
        ...state,
        authorization: {
          ...state.authorization,
          ...action.payload,
          resendLoadingState: AsyncStatus.Completed
        }
      }
    case Actions.REFERRALS_RESEND_CODE_FAILED:
      return {
        ...state,
        authorization: {
          ...state.authorization,
          resendLoadingState: AsyncStatus.Failed
        }
      }
    case Actions.REFERRALS_VERIFY_CODE:
      return {
        ...state,
        authorization: {
          ...state.authorization,
          verifyLoadingState: AsyncStatus.Loading,
          verificationCodeError: initialState.authorization.verificationCodeError
        }
      }
    case Actions.REFERRALS_VERIFY_CODE_RECEIVED:
      return {
        ...state,
        authorization: {
          ...state.authorization,
          verifyLoadingState: AsyncStatus.Completed
        }
      }
    case Actions.REFERRALS_VERIFY_CODE_FAILED:
      return {
        ...state,
        authorization: {
          ...state.authorization,
          verifyLoadingState: AsyncStatus.Failed,
          verificationCodeError: action.payload.otpError
        }
      }
    case Actions.REFERRALS_AUTH_COMPLETE:
      return {
        ...state,
        authorization: {
          ...state.authorization,
          status: 'authorized',
          resendLoadingState: AsyncStatus.Completed,
          verificationCodeError: null,
          verifyLoadingState: AsyncStatus.Completed
        }
      }
    case Actions.REFERRALS_SUBMIT_SCAN_REVIEW:
      return {
        ...state,
        viewScan: {
          isSubmitting: true
        }
      }
    case Actions.REFERRALS_SUBMIT_SCAN_REVIEW_RECEIVED:
      return {
        ...state,
        viewScan: {
          isSubmitting: false
        },
        referral: {
          ...state.referral,
          referralReview: action.payload.referralReview,
          reviewStatus: 'completed'
        }
      }
    case Actions.REFERRALS_SUBMIT_SCAN_REVIEW_FAILED:
      return {
        ...state,
        viewScan: {
          isSubmitting: false
        }
      }
    case Actions.REFERRALS_TOGGLE_SESSION_EXPIRED_MODAL:
      return {
        ...state,
        sessionExpiredModal: {
          isOpen: action.payload
        }
      }
    default:
      return state
  }
}
