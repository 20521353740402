import * as React from 'react'

export default ({ style = {} }) => (
  <span style={{ fontFamily: 'auto', display: 'inline-flex', ...style }}>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.97 0.866L9.87 4.954L4.97 9.042L4.074 7.908L6.972 5.696H0.644V4.198H6.944L4.074 2L4.97 0.866Z"
        fill="#001335"
      />
    </svg>
  </span>
)
