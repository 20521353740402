import * as React from 'react'

export default () => {
  return (
    <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 1C0 0.447716 0.447715 0 1 0H7C7.55228 0 8 0.447715 8 1V9C8 9.55228 7.55228 10 7 10H1C0.447715 10 0 9.55228 0 9V1Z"
        fill="#4451DE"
      />
      <rect x="3" y="2" width="4" height="1" fill="white" />
      <rect x="1" y="2" width="1" height="1" fill="white" />
      <rect x="3" y="4" width="4" height="1" fill="white" />
      <rect x="1" y="4" width="1" height="1" fill="white" />
      <rect x="3" y="6" width="4" height="1" fill="white" />
      <rect x="1" y="6" width="1" height="1" fill="white" />
      <path d="M9 5H16M16 5L13 2M16 5L13 8" stroke="#4451DE" />
    </svg>
  )
}
