import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../../actions'
import OggHeading40 from '../../common/text/OggHeading40'
import MobileAppPreview from '../components/MobileAppPreview'
import OnboardingSteps from '../components/OnboardingSteps'
import PatientsOnboardingVideo from '../components/PatientsOnboardingVideo'
import SignUpForm from '../components/SignUpForm'
import { setCloudFrontUrl } from '../../../utils/storageUtils'
import useRcDetails from '../useRcDetails'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(
  () => ({
    root: {},
    body: {
      padding: ({ isMobile }) => (isMobile ? '20px' : '50px 70px'),
      display: 'flex',
      flexDirection: 'row'
    },
    bodySection: {
      flex: 1
    },
    headline: {
      flex: 1,
      width: '100%',
      textAlign: ({ isMobile }) => (isMobile ? 'center' : 'start !important'),
      fontSize: '50px'
    }
  }),
  { isMobile }
)

const LandingPage = () => {
  const classes = useStyles({ isMobile })
  const dispatch = useDispatch()
  const rcDetails = useRcDetails()
  const pixelsLoaded = useSelector(state => state.pixelReducer.loaded)
  const { t } = useTranslation()

  const doctorName = useMemo(() => rcDetails.name, [rcDetails])
  const practiceName = useMemo(() => rcDetails.clinicName, [rcDetails])
  const [analyticsSent, setAnalyticsSent] = useState(false)

  useEffect(() => {
    setCloudFrontUrl()
  }, [])

  useEffect(() => {
    dispatch(Actions.setRcLandingPagaeFooterVariant('extended'))
    return () => dispatch(Actions.setRcLandingPagaeFooterVariant('compact'))
  }, [dispatch])

  useEffect(() => {
    dispatch(Actions.requestDoctorPublicDetails(rcDetails.rcToken))
  }, [dispatch, rcDetails.rcToken])

  useEffect(() => {
    if (pixelsLoaded) {
      dispatch(Actions.pixelContentViewed())
    }
  }, [pixelsLoaded, dispatch])

  useEffect(() => {
    if (!analyticsSent && doctorName && practiceName) {
      trackEvent('RC landing page - landing page navigated', {
        doctorName,
        practiceName,
        autoApproved: rcDetails?.approved,
        shipping: rcDetails?.shipping
      })
      setAnalyticsSent(true)
    }
  }, [doctorName, practiceName, rcDetails, analyticsSent])

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <div className={classes.bodySection}>
          <OggHeading40 className={classes.headline}>{t('pages.rcLanding.body.getStarted')}</OggHeading40>
          <SignUpForm
            rcToken={rcDetails.rcToken}
            colors={rcDetails.colorSettings}
            campaignParams={rcDetails.campaignParams}
            clinicCountry={rcDetails.country}
            skipCaptcha={rcDetails.skipCaptcha}
          />
        </div>
        {!isMobile && (
          <div className={classes.bodySection}>
            <MobileAppPreview />
          </div>
        )}
      </div>
      {isMobile && <PatientsOnboardingVideo colors={rcDetails.colorSettings} />}
      <OnboardingSteps />
    </div>
  )
}

export default LandingPage
