import * as React from 'react'

const ShareFeedback = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.41203 10.7834L2.88637 10.9415L2.95377 10.7393L2.85455 10.5506L2.41203 10.7834ZM1.00003 15.0194L0.525684 14.8613L0.209456 15.81L1.15814 15.4937L1.00003 15.0194ZM5.2436 13.6049L5.47475 13.1615L5.28669 13.0635L5.08549 13.1305L5.2436 13.6049ZM14.5058 7.67882C14.5058 11.0913 11.7394 13.8576 8.32696 13.8576V14.8576C12.2917 14.8576 15.5058 11.6436 15.5058 7.67882H14.5058ZM8.32696 1.5C11.7394 1.5 14.5058 4.26635 14.5058 7.67882H15.5058C15.5058 3.71407 12.2917 0.5 8.32696 0.5V1.5ZM2.14814 7.67882C2.14814 4.26635 4.91449 1.5 8.32696 1.5V0.5C4.36221 0.5 1.14814 3.71407 1.14814 7.67882H2.14814ZM2.85455 10.5506C2.40356 9.69317 2.14814 8.71656 2.14814 7.67882H1.14814C1.14814 8.88261 1.44483 10.0186 1.9695 11.0161L2.85455 10.5506ZM1.47437 15.1775L2.88637 10.9415L1.93768 10.6253L0.525684 14.8613L1.47437 15.1775ZM5.08549 13.1305L0.841912 14.545L1.15814 15.4937L5.40171 14.0792L5.08549 13.1305ZM8.32696 13.8576C7.29711 13.8576 6.32748 13.6061 5.47475 13.1615L5.01244 14.0482C6.00452 14.5655 7.13235 14.8576 8.32696 14.8576V13.8576Z"
        fill="#071331"
      />
      <path d="M8.75 11.5L7.5 11.5L7.5 6.5L8.75 6.5L8.75 11.5Z" fill="#071331" />
      <path d="M8.75 5.5L7.5 5.5L7.5 4L8.75 4L8.75 5.5Z" fill="#071331" />
    </svg>
  )
}

export default ShareFeedback
