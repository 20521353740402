import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { GuidelineCategories, PatientBriefActions } from 'consts/hiToolsConsts'
import CheckboxWithNote from 'components/common/inputs/CheckboxWithNote'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import CategorySubtitle from './CategorySubtitle'
import { trackEvent } from 'utils/analyticsUtils'

const TxGoalsCategory = ({ categoryValues = {}, onChange, viewMode }) => {
  const { t } = useTranslation()

  const handleTxGoalChanged = useCallback(
    (goal, changes) => {
      onChange({
        ...categoryValues,
        [goal]: {
          ...(categoryValues[goal] || {}),
          ...changes
        }
      })
    },
    [onChange, categoryValues]
  )

  const handleCheckboxClick = useCallback(
    ({ txGoal, isChecked }) => {
      trackEvent('Patient brief - action clicked', {
        action: txGoal,
        value: isChecked,
        category: GuidelineCategories.TxGoals
      })
      handleTxGoalChanged(txGoal, { isChecked })
    },
    [handleTxGoalChanged]
  )

  return (
    <Grid container direction="column" spacing={1}>
      {[
        PatientBriefActions.Class1Achieved,
        PatientBriefActions.CrossbiteCorrected,
        PatientBriefActions.MidlineCorrected,
        PatientBriefActions.OverbiteCorrected
      ]
        .filter(txGoal => !viewMode || categoryValues[txGoal]?.isChecked)
        .map(txGoal => (
          <Grid item key={`tx-goal-${txGoal}`}>
            {viewMode ? (
              categoryValues[txGoal]?.isChecked ? (
                <div>
                  <CategorySubtitle bold viewMode>
                    {t(`dialogs.patientBrief.actions.${txGoal}`)}
                  </CategorySubtitle>
                  {categoryValues[txGoal].note && (
                    <DazzedParagraph14>
                      <span style={{ fontWeight: '500', marginRight: 3 }}>
                        {t('dialogs.patientBrief.categoryNoteSectionTitle')}
                      </span>
                      {categoryValues[txGoal].note}
                    </DazzedParagraph14>
                  )}
                </div>
              ) : (
                <></>
              )
            ) : (
              <CheckboxWithNote
                label={t(`dialogs.patientBrief.actions.${txGoal}`)}
                isChecked={categoryValues[txGoal]?.isChecked || false}
                setIsChecked={isChecked => handleCheckboxClick({ txGoal, isChecked })}
                note={categoryValues[txGoal]?.note || ''}
                setNote={note => handleTxGoalChanged(txGoal, { note })}
                noteInputPlaceholder={t('dialogs.patientBrief.actions.customNote')}
              />
            )}
          </Grid>
        ))}
    </Grid>
  )
}

export default TxGoalsCategory
