import * as React from 'react'

const UpdateApplianceArrival = ({ color = '#344563', ...props }) => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.02681V11.5L7 13.8333V5.36014L0 3.02681ZM8 13.8333L15 11.5V3.02675L8 5.36008V13.8333ZM14.209 2.23633L7.5 0L4.64595 0.951349L11.3549 3.18768L14.209 2.23633ZM3.85538 1.21487L0.790926 2.23636L7.49991 4.47269L10.5644 3.4512L3.85538 1.21487Z"
        fill={color}
      />
    </svg>
  )
}

export default UpdateApplianceArrival
