import * as React from 'react'

const TrackingNoMovement = () => (
  <svg
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 4 }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5078 3.21265L7.27675 15.4437L1.13281 9.29978L3.11939 7.3132L7.27675 11.4706L17.5212 1.22607L19.5078 3.21265Z"
      stroke="#5CB664"
      strokeWidth="1.25"
    />
  </svg>
)

export default TrackingNoMovement
