import React, { useMemo } from 'react'
import useFeatureFlags from 'hooks/useFeatureFlags'
import usePlanLimits from 'hooks/usePlanLimits'
import useRolePermissions from 'hooks/useRolePermissions'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { isMobile } from 'utils/mobileUtils'
import SendScanReview from 'components/common/icons/scan-item-actions/SendScanReview'
import ShareProgress from 'components/common/icons/scan-item-actions/ShareProgress'
import Autochart from 'components/common/icons/scan-item-actions/Autochart'
import { Copy, ShareScan, TreatmentTrackerIcon } from 'components/common/icons'
import { trackEvent } from 'utils/analyticsUtils'
import { isScanEligableForBeforeAfter, scanHasScanSummaryCenterPose } from 'utils/scanUtils'
import { ScanSummaryStatus } from 'consts/scanSummaryConsts'
import CompareScans from 'components/common/icons/timelineV2/scan-feed/CompareScans'
import { Roles } from 'consts/authConsts'

/**
 * A hook that returns the list of actions available for a grin scan timeline item.
 * **Note:** for legacy reasons, it is required that the patient will be fully loaded into the state.
 *
 * @prop timelineItemScanIds - The list of scan ids associated with the timeline item.
 * @prop selectedScanId - The currently selected scan id: some actions are scan contextual (for example scan review). If not provided, such actions will be excluded.
 * @prop analyticsPayload - (Optional) an object that will be added to all analytics.
 * @returns A list of available action objects with the following structure:
 * ```
 * [{ icon, label, elementId, onClick }]
 * ```
 */
export default ({
  scanNumber = '',
  timelineItemScanIds = [],
  selectedScanId = '',
  withAligner,
  analyticsPayload = {}
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { permissions, isUserOfRole } = useRolePermissions()
  const { autochart: autochartFF, treatmentTracker: treatmentTrackerFF } = useFeatureFlags()
  const { isChurnedPlan } = usePlanLimits()

  const { patient } = useSelector(state => state.patientsReducer)
  const { grinScans: patientScans } = useSelector(state => state.treatmentReducer)

  const isPatientActive = useMemo(
    () => patient?.user?.isActive && !patient?.treatments.items[0]?.currentStatus?.statusKey?.includes('transferred'),
    [patient]
  )
  const grinScans = useMemo(
    () => patientScans?.filter(scan => timelineItemScanIds.includes(scan.id)),
    [patientScans, timelineItemScanIds]
  )
  const selectedScan = useMemo(
    () => patientScans.find(scan => scan.id === selectedScanId),
    [selectedScanId, patientScans]
  )
  const beforeAfterScan = useMemo(() => grinScans.find(isScanEligableForBeforeAfter), [grinScans])
  const autochartScan = useMemo(() => grinScans.find(scan => scan.autochartId), [grinScans])

  const actions = useMemo(
    () =>
      [
        {
          icon: <SendScanReview />,
          label: t('pages.patients.selectedPatient.timeline.actions.sendReview'),
          onClick: () => dispatch(Actions.openScanReviewEditor(selectedScan)),
          visible: selectedScan && !isChurnedPlan && !isMobile() && permissions.recordScanReviews && isPatientActive,
          elementId: `scan-review`
        },
        {
          icon: <CompareScans />,
          label: t('pages.patients.selectedPatient.timelineV2.scanFeed.scanActions.compareScans'),
          visible: true,
          elementId: 'compare-scans',
          onClick: () =>
            dispatch(
              Actions.setCompareScansDialogOpen({
                isOpen: true,
                right: {
                  scanIndex: scanNumber - 1,
                  withAligners: withAligner
                }
              })
            )
        },
        {
          icon: <ShareProgress />,
          label: t('pages.patients.selectedPatient.timeline.actions.shareProgress'),
          onClick: () =>
            dispatch(
              Actions.toggleBeforeAfterDialog({
                open: true,
                scanId: selectedScan.id,
                patientId: patient.id,
                analytics: {
                  ...analyticsPayload,
                  source: 'timeline',
                  scanIndex: scanNumber
                }
              })
            ),
          visible:
            isPatientActive && !isChurnedPlan && scanNumber > 1 && beforeAfterScan && permissions.shareScanProgress,
          elementId: `share-progress`
        },
        {
          icon: <Autochart />,
          label: t('pages.patients.selectedPatient.timeline.actions.autochart'),
          onClick: () =>
            dispatch(
              Actions.autochartToggleDialog({
                isOpen: true,
                grinScanId: selectedScan.id,
                patientId: selectedScan.patientId
              })
            ),
          visible: !isMobile() && autochartFF && autochartScan,
          elementId: 'autochart'
        },
        {
          icon: <ShareScan />,
          label: t('pages.patients.selectedPatient.timeline.actions.shareScan'),
          onClick: () => {
            trackEvent('Share scan modal - opened', {
              ...analyticsPayload,
              analyticSource: 'Timeline'
            })
            dispatch(Actions.toggleShareNewScanModal({ isOpen: true, grinScanId: selectedScan.id }))
          },
          visible:
            selectedScan && !isChurnedPlan && permissions.shareScan && scanHasScanSummaryCenterPose(selectedScan),
          elementId: 'shareScan'
        },
        {
          icon: <TreatmentTrackerIcon />,
          label: t('pages.patients.selectedPatient.timeline.actions.treatmentTracker'),
          onClick: () => {
            trackEvent('Treatment tracker popup - opened', {
              ...analyticsPayload,
              grinScanId: selectedScan.id,
              patientId: patient.id
            })
            dispatch(Actions.setTreatmentTrackerModalOpen({ isOpen: true, grinScanId: selectedScan.id }))
          },
          visible:
            !isMobile() &&
            selectedScan?.scanSummaryStatus === ScanSummaryStatus.Completed &&
            !!treatmentTrackerFF &&
            selectedScan?.treatmentTrackerStatus === 'completed' &&
            !!selectedScan.treatmentTrackerStatus,
          elementId: 'treatmentTracker'
        },
        {
          label: t('pages.patients.selectedPatient.timeline.actions.regenerateScan'),
          onClick: () => {
            dispatch(Actions.qaToggleRegenerateScanModal({ isOpen: true, grinScanId: selectedScan.id }))
          },
          visible: !isMobile() && permissions.regenerateScan && selectedScan,
          elementId: 'regenerateScan'
        },
        {
          label: t('pages.patients.selectedPatient.timeline.actions.copyScanId'),
          visible: selectedScan && isUserOfRole(Roles.DevAdmin),
          icon: <Copy />,
          elementId: 'copy-scan-id',
          onClick: () => navigator.clipboard.writeText(selectedScan.id)
        }
      ].filter(action => action.visible),
    [
      dispatch,
      t,
      selectedScan,
      autochartScan,
      beforeAfterScan,
      autochartFF,
      isChurnedPlan,
      isPatientActive,
      patient,
      permissions,
      scanNumber,
      treatmentTrackerFF,
      withAligner,
      analyticsPayload,
      isUserOfRole
    ]
  )

  return actions
}
