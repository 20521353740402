import { Grid, makeStyles } from '@material-ui/core'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactCrop from 'react-image-crop'
import { trackEvent } from 'utils/analyticsUtils'
import { ROTATION_DIRECTIONS, getCroppedImgWithRotation } from 'utils/imageUtils'
import { Cancel, Confirm, Crop, RotateLeft, RotateRight } from './icons/CropImage'

const useStyles = makeStyles({
  selectedImageContainer: {
    position: 'relative'
  },
  cropButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 15px 8px 15px',
    backgroundColor: 'white',
    borderRadius: 60
  },
  cropActionsContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 0px 8px 15px',
    top: 5,
    right: 10
  },
  cropIcon: {
    marginRight: 10
  },
  actionButton: {
    marginRight: 5
  }
})

const CropImage = ({ image, croppedImage, setCroppedImage, onCropToggled, imageWidth, imageHeight }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [isCropping, setIsCropping] = useState(false)

  const initialCrop = useMemo(
    () => ({
      x: 0,
      y: 0,
      width: imageWidth,
      height: imageHeight,
      aspect: imageWidth / imageHeight
    }),
    [imageHeight, imageWidth]
  )

  const imgRef = useRef()

  const [rotationDegrees, setRotationDegrees] = useState(0)
  const [cropDimensions, setCropDimensions] = useState(initialCrop)
  const [isLoading, setIsLoading] = useState(false)

  const onImageLoaded = useCallback(img => {
    imgRef.current = img
    return false
  }, [])

  const handleToggleCrop = useCallback(() => {
    trackEvent('Crop image - Editor toggled', {
      mode: !isCropping
    })
    if (isCropping) {
      setCropDimensions(initialCrop)
    }
    setIsCropping(!isCropping)
    onCropToggled(!isCropping)
  }, [isCropping, onCropToggled, initialCrop])

  const handleCropCompleted = useCallback(async () => {
    setIsLoading(true)
    const croppedImage = await getCroppedImgWithRotation({
      imageElement: imgRef?.current,
      cropDimensions,
      rotationDegrees
    })
    setIsLoading(false)
    setCroppedImage(croppedImage)
    trackEvent('Crop image - Image cropped')
    handleToggleCrop()
  }, [cropDimensions, handleToggleCrop, rotationDegrees, setCroppedImage])

  const handleRotate = useCallback(
    rotationDirection => {
      trackEvent('Crop image - Image rotated', {
        rotationDirection
      })
      setRotationDegrees(rotationDirection === ROTATION_DIRECTIONS.LEFT ? rotationDegrees - 1 : rotationDegrees + 1)
    },
    [rotationDegrees]
  )

  const cropModeButtons = useMemo(
    () => [
      {
        id: 'cancel-button',
        width: 110,
        height: 38,
        label: t('general.cancel'),
        onClick: handleToggleCrop,
        icon: <Cancel />,
        iconPosition: 'left'
      },
      {
        id: 'rotate-right-button',
        width: 150,
        height: 38,
        label: t('dialogs.replaceScanSummaryImage.crop.rotateRight'),
        onClick: () => handleRotate(ROTATION_DIRECTIONS.RIGHT),
        icon: <RotateRight />,
        iconPosition: 'left'
      },
      {
        id: 'rotate-left-button',
        width: 150,
        height: 38,
        label: t('dialogs.replaceScanSummaryImage.crop.rotateLeft'),
        onClick: () => handleRotate(ROTATION_DIRECTIONS.LEFT),
        icon: <RotateLeft />,
        iconPosition: 'left'
      },
      {
        id: 'confirm-button',
        width: 160,
        height: 38,
        label: t('dialogs.replaceScanSummaryImage.crop.confirmCrop'),
        onClick: handleCropCompleted,
        icon: <Confirm />,
        isLoading: isLoading
      }
    ],
    [handleCropCompleted, handleRotate, handleToggleCrop, isLoading, t]
  )

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <div className={classes.selectedImageContainer}>
          {isCropping ? (
            <ReactCrop
              aspect={initialCrop.aspect}
              keepSelection
              src={image}
              crop={cropDimensions}
              onChange={(crop, percentCrop) => setCropDimensions(crop)}
              imageStyle={{ width: imageWidth, height: imageHeight, transform: `rotate(${rotationDegrees}deg)` }}
              onImageLoaded={onImageLoaded}
              className={classes.crop}
            />
          ) : (
            <img src={croppedImage || image} style={{ width: imageWidth, height: imageHeight }} alt="" />
          )}
          {isCropping ? (
            <div className={classes.cropActionsContainer}>
              {cropModeButtons.map(button => (
                <SecondaryButton
                  key={button.id}
                  width={button.width}
                  height={button.height}
                  label={button.label}
                  onClick={button.onClick}
                  icon={button.icon}
                  isLoading={button.isLoading}
                  iconPosition="left"
                  className={classes.actionButton}
                />
              ))}
            </div>
          ) : (
            <div className={classes.cropActionsContainer}>
              <div className={classes.cropButton} onClick={handleToggleCrop}>
                <Crop style={{ marginRight: 10 }} />
                <DazzedParagraph14 strong color="var(--text-color-25)">
                  {t('dialogs.replaceScanSummaryImage.crop.label')}
                </DazzedParagraph14>
              </div>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

export default CropImage
