import * as React from 'react'

function NotifyPractice(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3H13" stroke="#001335" /> <path d="M3 6H7" stroke="#001335" /> <path d="M3 9H7" stroke="#001335" />
      <path d="M3 12H8" stroke="#001335" />
      <path
        d="M9.80256 11.2812V11.1705C9.8054 10.6563 9.85369 10.2472 9.94744 9.94318C10.044 9.6392 10.1804 9.39489 10.3565 9.21023C10.5327 9.02273 10.7472 8.85085 11 8.6946C11.1733 8.58665 11.3281 8.46875 11.4645 8.34091C11.6037 8.21023 11.7131 8.06534 11.7926 7.90625C11.8722 7.74432 11.9119 7.56392 11.9119 7.36506C11.9119 7.14063 11.8594 6.94602 11.7543 6.78125C11.6491 6.61648 11.5071 6.48864 11.3281 6.39773C11.152 6.30682 10.9545 6.26136 10.7358 6.26136C10.5341 6.26136 10.3423 6.3054 10.1605 6.39347C9.98153 6.47869 9.83239 6.60938 9.71307 6.78551C9.59659 6.95881 9.53125 7.17898 9.51705 7.44602H8C8.0142 6.90625 8.14489 6.45455 8.39205 6.09091C8.64205 5.72727 8.97159 5.45455 9.38068 5.27273C9.79261 5.09091 10.2472 5 10.7443 5C11.2869 5 11.7642 5.09517 12.1761 5.28551C12.5909 5.47585 12.9134 5.74716 13.1435 6.09943C13.3764 6.44886 13.4929 6.86364 13.4929 7.34375C13.4929 7.66761 13.4403 7.95739 13.3352 8.21307C13.233 8.46875 13.0866 8.69602 12.8963 8.89489C12.706 9.09375 12.4801 9.27131 12.2188 9.42756C11.9886 9.5696 11.7997 9.71733 11.652 9.87074C11.5071 10.0241 11.3991 10.2045 11.3281 10.4119C11.2599 10.6165 11.2244 10.8693 11.2216 11.1705V11.2812H9.80256ZM10.544 13.9403C10.2884 13.9403 10.0682 13.8494 9.88352 13.6676C9.69886 13.4858 9.60653 13.2642 9.60653 13.0028C9.60653 12.7472 9.69886 12.5284 9.88352 12.3466C10.0682 12.1648 10.2884 12.0739 10.544 12.0739C10.7969 12.0739 11.0156 12.1648 11.2003 12.3466C11.3878 12.5284 11.4815 12.7472 11.4815 13.0028C11.4815 13.1761 11.4375 13.3338 11.3494 13.4759C11.2642 13.6179 11.1506 13.7315 11.0085 13.8168C10.8693 13.8991 10.7145 13.9403 10.544 13.9403Z"
        fill="#001335"
      />
    </svg>
  )
}

export default NotifyPractice
