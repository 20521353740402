import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import AlignerMenu from './AlignerMenu'
import ScanDateLabel from 'components/Patients/Timeline/V2/ScanDateLabel'
import ScanStats from '../../ScanStats'

const ScanHeaderBodyLg = ({
  scanNumber,
  alignerNumber,
  numberOfReviews,
  numberOfShares,
  onReviewsClicked,
  onSharesClicked,
  scanDate,
  alignerMenuProps
}) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <DazzedParagraph14 bold>
                  {t('pages.patients.selectedPatient.timelineV2.scanHeader.scanNumber', { scanNumber })}
                </DazzedParagraph14>
              </Grid>
              {alignerNumber && (
                <Grid item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <DazzedParagraph14 strong>
                        {t('pages.patients.selectedPatient.timelineV2.scanHeader.alignerNumber', { alignerNumber })}
                      </DazzedParagraph14>
                    </Grid>
                    <Grid item>
                      <AlignerMenu {...alignerMenuProps} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <ScanStats
              numberOfReviews={numberOfReviews}
              numberOfShares={numberOfShares}
              onReviewsClicked={onReviewsClicked}
              onSharesClicked={onSharesClicked}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ScanDateLabel timestamp={scanDate} />
      </Grid>
    </Grid>
  )
}

export default React.memo(ScanHeaderBodyLg)
