import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import ProfilePicture from 'components/common/ProfilePicture'
import PatientStatus from 'components/Patients/PatientStatus'

const useStyles = makeStyles(theme => ({
  patientInfo: {
    marginLeft: 10,
    borderRight: '1px solid var(--border-color-14)',
    paddingRight: 20
  }
}))

const PatientInfo = ({ patient }) => {
  const classes = useStyles()
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item>
        <ProfilePicture name={patient.name} photo={patient.photo} />
      </Grid>
      <Grid item className={classes.patientInfo}>
        <DazzedParagraph16 strong>{patient.name}</DazzedParagraph16>
        <PatientStatus status={patient?.treatments?.items[0]?.currentStatus?.status} />
      </Grid>
    </Grid>
  )
}

export default PatientInfo
