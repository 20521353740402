import React, { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import SwooshLargeIcon from 'components/common/icons/SwooshLarge1'
import { ImagePlaceholder1, Trash1 as TrashIcon } from 'components/common/icons'
import OggHeading40 from 'components/common/text/OggHeading40'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import ImageUploaderWithCrop from 'components/Profile/ImageUploaderWithCrop'
import Actions from 'actions'
import config from '../../utils/awsUtils'
import CachedImage from 'components/common/CachedImage'
import ROUTES from 'consts/routesConsts'

const inputId = 'authPracticeMemberPhoto'

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  signUpSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  form: {
    width: 540
  },
  title: {
    color: 'var(--text-color-1)',
    marginBottom: 20
  },
  subtitle: {
    color: 'var(--text-color-1)',
    marginBottom: 10,
    textTransform: 'uppercase',
    opacity: '.5'
  },
  button: {
    marginTop: 30,
    marginBottom: 10
  },
  skipButton: {
    border: 'none',
    textDecoration: 'underline',
    opacity: '.5'
  },
  inputWrapper: {
    height: 185,
    backgroundColor: 'var(--bg-color-4)'
  },
  photoContainer: {
    width: 90,
    height: 90,
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: 'var(--bg-color-1)'
  },
  uploadButton: {
    padding: '0 15px',
    cursor: 'pointer',
    height: '100%'
  },
  uploadButtonText: {
    color: 'var(--text-color-1)',
    fontSize: 10,
    textAlign: 'center',
    lineHeight: '14px',
    marginTop: 10
  },
  imagePlaceholder: {
    marginLeft: 4
  },
  photoComponentContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
    '&:hover > div': {
      opacity: 1
    }
  },
  deleteComponentContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'rgba(60, 82, 239, 0.9)',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    opacity: 0,
    transition: '.2s',
    cursor: 'pointer'
  },
  deleteComponentText: {
    fontSize: '8px',
    color: 'var(--text-color-1)'
  },
  hiddenElement: {
    display: 'none'
  },
  photoImage: {
    height: '100%',
    width: '100%'
  }
})

const Phpto = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  const { photo } = useSelector(state => state.authReducer.practiceMembers)

  const handleChangePhoto = useCallback(
    keys =>
      dispatch(
        Actions.changePracticeMembersAuthForm({
          key: 'photo',
          value: {
            key: keys[0],
            bucket: config.aws_user_files_s3_bucket,
            region: config.aws_user_files_s3_bucket_region
          }
        })
      ),
    [dispatch]
  )

  const handleDeletePhoto = useCallback(
    () =>
      dispatch(
        Actions.changePracticeMembersAuthForm({
          key: 'photo',
          value: null
        })
      ),
    [dispatch]
  )

  const handleClickNext = useCallback(() => {
    history.push({ pathname: ROUTES.AUTH_PRACTICE_MEMBERS_TERMS, search: location.search })
  }, [history, location.search])

  const handleClickSkip = useCallback(() => {
    handleDeletePhoto()
    handleClickNext()
  }, [handleClickNext, handleDeletePhoto])

  const isPrimaryDisabled = useMemo(() => !photo, [photo])

  const photoComponent = useMemo(
    () => (
      <div className={classes.photoComponentContainer}>
        <CachedImage s3Object={photo} alt="photo" applyClassName={classes.photoImage} />
        <div className={classes.deleteComponentContainer} onClick={handleDeletePhoto}>
          <TrashIcon />
          <DazzedParagraph12 className={classes.deleteComponentText}>{t('general.delete')}</DazzedParagraph12>
        </div>
      </div>
    ),
    [
      classes.deleteComponentContainer,
      classes.deleteComponentText,
      classes.photoComponentContainer,
      classes.photoImage,
      handleDeletePhoto,
      photo,
      t
    ]
  )

  const photoEmptyState = useMemo(
    () => (
      <label htmlFor={inputId}>
        <Grid
          container
          className={classes.uploadButton}
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <DazzedParagraph12 className={classes.uploadButtonText}>
              {t('pages.authPracticeMembers.photo.selectPhoto')}
            </DazzedParagraph12>
          </Grid>
          <Grid item>
            <ImagePlaceholder1 className={classes.imagePlaceholder} />
          </Grid>
        </Grid>
      </label>
    ),
    [classes.imagePlaceholder, classes.uploadButton, classes.uploadButtonText, t]
  )

  return (
    <div className={classes.container}>
      <SwooshLargeIcon className={classes.signUpSwoosh} />
      <div className={classes.form}>
        <OggHeading40 className={classes.title} textAlign="left">
          {t('pages.authPracticeMembers.photo.uploadPhoto')}
        </OggHeading40>
        <DazzedParagraph12 className={classes.subtitle}>
          {t('pages.authPracticeMembers.photo.uploadYourProfilePicture')}
        </DazzedParagraph12>

        <Grid container className={classes.inputWrapper} justifyContent="center" alignItems="center">
          <Grid item className={classes.photoContainer}>
            {photo ? photoComponent : photoEmptyState}
          </Grid>
        </Grid>
        <PrimaryButton
          label={t('general.next')}
          onClick={handleClickNext}
          width="100%"
          className={classes.button}
          disabled={isPrimaryDisabled}
          variant={isPrimaryDisabled ? 'outlined' : 'contained'}
        />
        <PrimaryButton
          label={t('general.skip')}
          onClick={handleClickSkip}
          width="100%"
          className={classes.skipButton}
          variant="outlined"
        />
      </div>

      <div className={classes.hiddenElement}>
        <ImageUploaderWithCrop
          inputId={inputId}
          onUploadDone={handleChangePhoto}
          minWidth={90}
          minHeight={90}
          keyWithExtension
          fillIntoSquare
        />
      </div>
    </div>
  )
}

export default Phpto
