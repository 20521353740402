import React from 'react'
import TitleWithText from 'components/Patients/PatientGuidelines/PatientBrief/Categories/TitleWithText'
import { useTranslation } from 'react-i18next'

const IPR = ({ items }) => {
  const { t } = useTranslation()

  const { alignerNumberReached } = items

  return (
    <div>
      {alignerNumberReached ? (
        <TitleWithText
          title={`${t(`dialogs.hiPatientGuidelinesViewer.items.alignerNumberReached`)}:`}
          text={alignerNumberReached}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default IPR
