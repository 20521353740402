import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'

const useStyles = () =>
  makeStyles({
    sharpen: {
      position: 'absolute',
      left: 0,
      top: 0,
      padding: '2px 9px 6px 6px',
      color: 'var(--bg-color-10)',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'var(--bg-color-12)',
      borderRadius: '10px 0px'
    },
    sharpenText: {
      color: 'var(--bg-color-10)',
      lineHeight: '18px',
      fontWeight: 500,
      '&.notAvailable': {
        color: 'var(--text-color-11)'
      }
    },
    grinAi: {
      color: 'var(--text-color-10)',
      fontWeight: 600
    },
    sharpenButton: {
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    notDisplay: {
      display: 'none'
    }
  })()

const ScanSummarySharpenButton = ({ isLoading, isImageAvailable, sharpenedImages, setSharpenedImages }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={[classes.sharpen, isLoading ? classes.notDisplay : ''].join(' ')}>
      <DazzedParagraph12 className={[classes.sharpenText, !isImageAvailable ? 'notAvailable' : ''].join(' ')}>
        {sharpenedImages && (
          <>
            {t('dialogs.scanSummary.sharpenText')}
            <span className={classes.grinAi}>
              {t('dialogs.scanSummary.grinAi')}
              {'  '}
            </span>
          </>
        )}
        {!sharpenedImages && t('dialogs.scanSummary.notSharpenText')}
        <span className={classes.sharpenButton} onClick={() => setSharpenedImages(!sharpenedImages)}>
          {sharpenedImages ? t('dialogs.scanSummary.removeSharpening') : t('dialogs.scanSummary.sharpenImages')}
        </span>
      </DazzedParagraph12>
    </div>
  )
}

export default ScanSummarySharpenButton
