import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'

export default () => {
  const dispatch = useDispatch()
  const { requestedPatientId } = useSelector(state => state.patientsReducer)

  useEffect(() => {
    if (requestedPatientId) {
      dispatch(
        Actions.fetchPatientTimelineItems({
          patientId: requestedPatientId
        })
      )
    }
  }, [requestedPatientId, dispatch])
}
