import * as React from 'react'

function Filter(props) {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5435 16.1376C15.701 16.1376 15.852 16.075 15.9633 15.9637C16.0747 15.8523 16.1372 15.7013 16.1372 15.5438V13.6973C16.6182 13.5683 17.0431 13.2843 17.3462 12.8892C17.6493 12.4942 17.8135 12.0102 17.8135 11.5123C17.8135 11.0143 17.6493 10.5303 17.3462 10.1353C17.0431 9.74024 16.6182 9.45622 16.1372 9.32727V3.45508C16.1372 3.29761 16.0747 3.14658 15.9633 3.03523C15.852 2.92388 15.701 2.86133 15.5435 2.86133C15.386 2.86133 15.235 2.92388 15.1236 3.03523C15.0123 3.14658 14.9497 3.29761 14.9497 3.45508V9.3332C14.4688 9.46216 14.0439 9.74618 13.7408 10.1412C13.4377 10.5363 13.2734 11.0203 13.2734 11.5182C13.2734 12.0161 13.4377 12.5001 13.7408 12.8952C14.0439 13.2902 14.4688 13.5742 14.9497 13.7032V15.5498C14.9513 15.7062 15.0145 15.8557 15.1257 15.9658C15.2369 16.0758 15.387 16.1376 15.5435 16.1376ZM14.4569 11.5123C14.4569 11.2974 14.5206 11.0873 14.64 10.9086C14.7594 10.7299 14.9291 10.5907 15.1277 10.5084C15.3262 10.4262 15.5447 10.4047 15.7555 10.4466C15.9662 10.4885 16.1598 10.592 16.3118 10.744C16.4638 10.8959 16.5672 11.0895 16.6092 11.3003C16.6511 11.5111 16.6296 11.7295 16.5473 11.9281C16.4651 12.1266 16.3258 12.2963 16.1471 12.4157C15.9685 12.5351 15.7584 12.5988 15.5435 12.5988C15.2553 12.5988 14.9789 12.4844 14.7752 12.2806C14.5714 12.0768 14.4569 11.8004 14.4569 11.5123Z"
        fill="#898484"
      />
      <path
        d="M9.49868 16.1381C9.65615 16.1381 9.80717 16.0755 9.91852 15.9642C10.0299 15.8528 10.0924 15.7018 10.0924 15.5443V7.31495C10.6246 7.17102 11.0863 6.8387 11.3917 6.37982C11.6972 5.92094 11.8255 5.36673 11.7529 4.82028C11.6803 4.27384 11.4117 3.77237 10.9971 3.40915C10.5824 3.04593 10.0499 2.8457 9.49868 2.8457C8.94743 2.8457 8.41495 3.04593 8.00029 3.40915C7.58563 3.77237 7.31702 4.27384 7.24442 4.82028C7.17183 5.36673 7.30019 5.92094 7.60563 6.37982C7.91108 6.8387 8.3728 7.17102 8.90493 7.31495V15.5443C8.90493 15.7018 8.96748 15.8528 9.07883 15.9642C9.19018 16.0755 9.3412 16.1381 9.49868 16.1381ZM8.41211 5.13588C8.41094 4.92073 8.47366 4.71008 8.59234 4.53061C8.71102 4.35115 8.8803 4.21096 9.07874 4.12781C9.27717 4.04466 9.49583 4.02229 9.70699 4.06354C9.91815 4.1048 10.1123 4.20781 10.2649 4.35953C10.4174 4.51125 10.5215 4.70484 10.5639 4.91578C10.6063 5.12671 10.5851 5.34548 10.5031 5.54437C10.421 5.74326 10.2818 5.91331 10.103 6.03297C9.92414 6.15263 9.71383 6.21651 9.49868 6.21651C9.21153 6.21651 8.93605 6.10285 8.73245 5.90037C8.52885 5.69788 8.41368 5.42303 8.41211 5.13588Z"
        fill="#898484"
      />
      <path
        d="M2.86184 11.6845V15.5438C2.86184 15.7013 2.9244 15.8523 3.03575 15.9637C3.1471 16.075 3.29812 16.1376 3.4556 16.1376C3.61307 16.1376 3.76409 16.075 3.87544 15.9637C3.98679 15.8523 4.04935 15.7013 4.04935 15.5438V11.6845C4.53027 11.5555 4.95522 11.2715 5.25829 10.8764C5.56137 10.4814 5.72564 9.99737 5.72564 9.49945C5.72564 9.00154 5.56137 8.51753 5.25829 8.12248C4.95522 7.72743 4.53027 7.44341 4.04935 7.31445V3.45508C4.04935 3.29761 3.98679 3.14658 3.87544 3.03523C3.76409 2.92388 3.61307 2.86133 3.4556 2.86133C3.29812 2.86133 3.1471 2.92388 3.03575 3.03523C2.9244 3.14658 2.86184 3.29761 2.86184 3.45508V7.31445C2.38092 7.44341 1.95597 7.72743 1.6529 8.12248C1.34982 8.51753 1.18555 9.00154 1.18555 9.49945C1.18555 9.99737 1.34982 10.4814 1.6529 10.8764C1.95597 11.2715 2.38092 11.5555 2.86184 11.6845ZM3.4556 8.41289C3.67075 8.41172 3.8814 8.47444 4.06087 8.59312C4.24033 8.71179 4.38052 8.88108 4.46367 9.07951C4.54682 9.27795 4.56919 9.49661 4.52794 9.70777C4.48668 9.91893 4.38367 10.1131 4.23195 10.2656C4.08023 10.4182 3.88664 10.5223 3.6757 10.5647C3.46477 10.6071 3.246 10.5859 3.04711 10.5039C2.84822 10.4218 2.67817 10.2825 2.55851 10.1037C2.43885 9.92492 2.37497 9.71461 2.37497 9.49945C2.37497 9.2123 2.48863 8.93683 2.69111 8.73323C2.8936 8.52963 3.16845 8.41446 3.4556 8.41289Z"
        fill="#898484"
      />
    </svg>
  )
}

export default Filter
