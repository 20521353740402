import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CONTENT_HEIGHT } from 'consts/patientCard'
import AddTagIcon from 'components/common/icons/AddTag'
import BriefListItem from './BriefListItem'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import { trackEvent } from 'utils/analyticsUtils'
import Actions from 'actions'
import { AsyncStatus } from 'consts'
import LinkButton from 'components/common/buttons/LinkButton'
import usePatientBriefModalOpener from './usePatientBriefModalOpener'
import useRolePermissions from 'hooks/useRolePermissions'

const useStyles = makeStyles({
  container: {
    height: CONTENT_HEIGHT,
    padding: '0 50px 10px 5%',
    overflowY: 'auto'
  },
  updateBriefButton: {
    marginTop: 5,
    marginBottom: 5,
    border: 'none'
  },
  buttonContainer: {
    borderBottom: '1px solid var(--border-color-10)',
    borderTop: '1px solid var(--border-color-10)',
    marginTop: 10,
    padding: 10
  },
  loadMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10
  }
})

const PatientBriefTab = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { permissions } = useRolePermissions()
  const { id: patientId } = useSelector(state => state.patientsReducer.patient)
  const { data: patientBriefs, nextToken, loadingStatus } = useSelector(state => state.patientsReducer.patientBrief)

  const { updatePatientBrief, viewPatientBrief } = usePatientBriefModalOpener({ analyticsSource: 'patient-card' })

  const fetchMoreBriefs = useCallback(() => {
    trackEvent('Patient card - Patient brief - Fetch more briefs clicked', { patientId })
    dispatch(Actions.fetchPatientBrief({ patientId, nextToken }))
  }, [dispatch, patientId, nextToken])

  const handleUpdateBriefClicked = useCallback(() => {
    const latestPatientBrief = patientBriefs[0]
    trackEvent('Patient card - Patient brief - Update brief clicked', { patientId })
    updatePatientBrief({ patientBrief: latestPatientBrief })
  }, [patientId, updatePatientBrief, patientBriefs])

  const handleViewPatientBriefClicked = useCallback(
    brief => {
      trackEvent('Patient card - Patient brief - View patient brief clicked', { patientId, patientBriefId: brief.id })
      viewPatientBrief({ patientBrief: brief })
    },
    [patientId, viewPatientBrief]
  )

  return (
    <div className={classes.container}>
      {permissions.updatePatientBrief && (
        <div className={classes.buttonContainer}>
          <LinkButton
            fontSize={14}
            icon={<AddTagIcon />}
            label={t('dialogs.patientInfo.brief.addNewBrief')}
            onClick={handleUpdateBriefClicked}
            containerJustifyContent="center"
          />
        </div>
      )}
      {patientBriefs.map(brief => (
        <BriefListItem key={brief.id} brief={brief} onClick={() => handleViewPatientBriefClicked(brief)} />
      ))}
      {nextToken && (
        <div className={classes.loadMoreContainer}>
          <SecondaryButton
            isLoading={loadingStatus === AsyncStatus.Loading}
            label={t('dialogs.patientInfo.brief.loadMore')}
            width={120}
            onClick={fetchMoreBriefs}
          />
        </div>
      )}
    </div>
  )
}

export default PatientBriefTab
