import queryString from 'query-string'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'

export default () => {
  const location = useLocation()
  const history = useHistory()

  const getQueryParam = queryParam => {
    const queryParams = queryString.parse(location.search)
    return queryParams[queryParam]
  }

  const removeQueryParam = queryParam => {
    if (queryParam && location.search.includes(queryParam)) {
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete(queryParam)
      history.replace({
        search: searchParams.toString()
      })
    }
  }

  return {
    getQueryParam,
    removeQueryParam
  }
}
