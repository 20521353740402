import { sortBy } from 'lodash'

export const isScanEligableForBeforeAfter = scan => {
  if (!scan || scan.withAligner || scan.scanSummaryStatus !== 'completed') {
    return false
  }

  const scanSummaryData = JSON.parse(scan.scanSummaryData || '{}')
  return !!scanSummaryData.sharpenedImages.center
}

export const scanHasScanSummaryCenterPose = scan =>
  scan?.scanSummaryStatus === 'completed' && !!JSON.parse(scan.scanSummaryData || '{}')?.normalImages?.center

export const getAggregateScans = grinScans => {
  let seenPairIds = new Set()
  let aggregated = []

  grinScans = sortBy(
    grinScans.filter(scan => scan.status !== 'uploading'),
    grinScan => grinScan.createdAt
  )
  grinScans.forEach(scan => {
    if (!scan.scanPairId || !seenPairIds.has(scan.scanPairId)) {
      seenPairIds.add(scan.scanPairId)
      aggregated.push([scan])
    } else {
      aggregated = aggregated.map(scans => {
        if (scan.scanPairId === scans[0].scanPairId) {
          return [...scans, scan]
        }
        return scans
      })
    }
  })

  aggregated = aggregated.filter(scans => !scans[0].scanPairId || (scans[0].scanPairId && scans.length === 2))
  return aggregated
}

export const generateScanNumberDict = aggGrinScans => {
  let dict = {}

  aggGrinScans.forEach((currScanPair, index) => {
    currScanPair.forEach(scan => (dict[scan.id] = index))
  })

  return dict
}
