import * as React from 'react'

export default () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="4" r="3" fill="#001335" stroke="#001335" />
      <circle cx="15.5" cy="7.25" r="2.5" fill="#001335" stroke="#001335" />
      <path d="M1 13C1 10.7909 2.79086 9 5 9H9C11.2091 9 13 10.7909 13 13V18H1V13Z" fill="#001335" stroke="#001335" />
      <path
        d="M20 19H20.5V18.5V14.75C20.5 12.817 18.933 11.25 17 11.25H14C12.067 11.25 10.5 12.817 10.5 14.75V18.5V19H11H20Z"
        fill="#001335"
        stroke="white"
      />
    </svg>
  )
}
