import { createSelector } from 'reselect'
import { PATIENTS_PER_PAGE } from 'consts/taskManagerConsts'

const taskManagerState = state => state.taskManagerReducer

export const selectTaskManagerPaginationData = createSelector([taskManagerState], state => {
  const of = state.total
  const page = state.pageIndex
  const total = Math.ceil(of / PATIENTS_PER_PAGE)
  const from = page * PATIENTS_PER_PAGE + 1
  const to = Math.min(of, (page + 1) * PATIENTS_PER_PAGE)

  return { page, total, from, to, of }
})

export const selectTaskManagerCurrentPage = createSelector([taskManagerState], state => {
  const allPatients = state.items
  const pageIndex = state.pageIndex
  const results = allPatients.slice(pageIndex * PATIENTS_PER_PAGE, (pageIndex + 1) * PATIENTS_PER_PAGE)
  return results
})

export const selectTaskManagerFilters = createSelector([taskManagerState], state => state.filters)

export const selectIsLoading = createSelector([taskManagerState], state => state.isLoading)
