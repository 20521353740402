import * as React from 'react'

const AutomationRulesEmptyState = props => {
  return (
    <svg width="190" height="32" viewBox="0 0 190 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="64" height="32" rx="8" fill="#F0F0F0" />
      <path
        d="M31.0573 18.1364V18.0767C31.0639 17.4437 31.1302 16.9399 31.2561 16.5653C31.3821 16.1908 31.5611 15.8875 31.7931 15.6555C32.0251 15.4235 32.3035 15.2098 32.6283 15.0142C32.8238 14.8949 32.9995 14.754 33.1553 14.5916C33.311 14.4259 33.4337 14.2353 33.5232 14.0199C33.616 13.8045 33.6624 13.5658 33.6624 13.304C33.6624 12.9792 33.5861 12.6974 33.4337 12.4588C33.2812 12.2202 33.0774 12.0362 32.8222 11.907C32.567 11.7777 32.2836 11.7131 31.972 11.7131C31.7003 11.7131 31.4384 11.7694 31.1865 11.8821C30.9346 11.9948 30.7242 12.1721 30.5551 12.4141C30.3861 12.656 30.2883 12.9725 30.2618 13.3636H29.009C29.0355 12.8002 29.1813 12.3179 29.4465 11.9169C29.7149 11.5159 30.0679 11.2093 30.5054 10.9972C30.9462 10.785 31.4351 10.679 31.972 10.679C32.5554 10.679 33.0625 10.795 33.4933 11.027C33.9275 11.259 34.2623 11.5772 34.4976 11.9815C34.7362 12.3859 34.8556 12.8466 34.8556 13.3636C34.8556 13.7282 34.7992 14.058 34.6865 14.353C34.5771 14.648 34.4181 14.9115 34.2093 15.1435C34.0038 15.3755 33.7552 15.581 33.4635 15.7599C33.1718 15.9422 32.9382 16.1345 32.7625 16.3366C32.5869 16.5355 32.4593 16.7725 32.3797 17.0476C32.3002 17.3227 32.2571 17.6657 32.2504 18.0767V18.1364H31.0573ZM31.6936 21.0795C31.4484 21.0795 31.2379 20.9917 31.0622 20.8161C30.8866 20.6404 30.7987 20.4299 30.7987 20.1847C30.7987 19.9394 30.8866 19.7289 31.0622 19.5533C31.2379 19.3776 31.4484 19.2898 31.6936 19.2898C31.9389 19.2898 32.1494 19.3776 32.325 19.5533C32.5007 19.7289 32.5885 19.9394 32.5885 20.1847C32.5885 20.3471 32.5471 20.4962 32.4642 20.6321C32.3847 20.768 32.277 20.8774 32.1411 20.9602C32.0085 21.0398 31.8593 21.0795 31.6936 21.0795Z"
        fill="#AFAFAF"
      />
      <rect x="126" width="64" height="32" rx="8" fill="#F0F0F0" />
      <path
        d="M158.677 10.8182L158.577 18.1364H157.424L157.324 10.8182H158.677ZM158.001 21.0795C157.755 21.0795 157.545 20.9917 157.369 20.8161C157.194 20.6404 157.106 20.4299 157.106 20.1847C157.106 19.9394 157.194 19.7289 157.369 19.5533C157.545 19.3776 157.755 19.2898 158.001 19.2898C158.246 19.2898 158.456 19.3776 158.632 19.5533C158.808 19.7289 158.896 19.9394 158.896 20.1847C158.896 20.3471 158.854 20.4962 158.771 20.6321C158.692 20.768 158.584 20.8774 158.448 20.9602C158.315 21.0398 158.166 21.0795 158.001 21.0795Z"
        fill="#AFAFAF"
      />
      <path d="M64 16L126 16" stroke="#ABB0B8" strokeDasharray="3 3" />
      <circle cx="95" cy="16" r="7" fill="white" stroke="#ABB0B8" strokeWidth="1.5" />
      <path d="M95 13.2L97.8 16L95 18.8" stroke="#ABB0B8" strokeWidth="1.5" />
      <path d="M97.8003 16L91.5003 16" stroke="#ABB0B8" strokeWidth="1.5" />
    </svg>
  )
}

export default AutomationRulesEmptyState
