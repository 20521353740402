import React from 'react'
import { makeStyles } from '@material-ui/styles'
import GrinLabel from './text/GrinLabel'
import { isMobile } from 'utils/mobileUtils'

const useFormInputStyles = props =>
  makeStyles({
    formInput: {
      fontFamily: 'Dazzed, Arial',
      resize: 'none',
      width: '100%',
      marginTop: '5px',
      padding: '5px',
      fontSize: '16px',
      transition: 'border .5s ease',
      background: ({ isDisabled, bright }) =>
        isDisabled ? 'var(--bg-color-11)' : bright ? 'var(--bg-color-10)' : 'var(--bg-color-4)',
      border: ({ bright, dark }) => (bright ? '1px solid var(--border-color-2)' : '1px solid var(--border-color-6)'),
      color: ({ bright, dark }) => (bright ? 'var(--text-color-6)' : 'var(--text-color-1)'),
      '&:focus': {
        outline: 'none'
      },
      '&.error': {
        border: ({ bright }) => (bright ? '1px solid red' : '1px solid var(--board-card-color-2)')
      },
      '&::placeholder': {
        fontFamily: 'Dazzed, Arial',
        fontSize: '13px',
        color: ({ bright, dark }) => (bright ? 'black' : 'var(--text-color-1)'),
        fontStyle: 'italic',
        opacity: 0.3,
        ...props.placeholderStyles
      }
    },
    landingTheme: {
      width: '100%',
      height: '100px!important',
      lineHeight: 1.5,
      padding: ({ isMobile }) => (isMobile ? 15 : 24),
      fontSize: ({ isMobile }) => (isMobile ? '10px' : '16px'),
      border: '1px solid var(--border-color-1)!important',
      color: 'var(--text-color-6)',
      '&:focus': {
        outline: 'none'
      },
      '&.error': {
        border: '1px solid red'
      },
      '&::placeholder': {
        fontFamily: 'Dazzed, Arial',
        fontSize: ({ isMobile }) => (isMobile ? '10px' : '16px'),
        color: 'var(--text-color-11)!important',
        fontStyle: 'normal',
        opacity: 1
      }
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    titleCommentText: {
      marginLeft: 10,
      fontSize: 11,
      textTransform: 'none',
      letterSpacing: 'normal'
    },
    errorMessage: {
      opacity: 0,
      color: ({ bright }) => (bright ? 'red' : 'var(--text-color-10)'),
      marginTop: '2px',
      marginBottom: '5px',
      height: '17px',
      fontSize: '12px',
      transition: 'opacity .15s ease',
      '&.active': {
        opacity: 1
      }
    },
    label: {
      '@media (max-width: 960px)': {
        fontSize: '14px !important'
      }
    }
  })({ isDisabled: props.isDisabled, thick: props.thick, bright: props.bright, isMobile: isMobile() })

const TextArea = ({
  title,
  titleComment,
  value,
  setValue,
  type = 'text',
  placeholder,
  style = { bright: false, thick: false, landing: false },
  validationRules = {},
  errorMessage,
  isDisabled = false,
  inputStyle = {},
  className = '',
  titleCommentClassName = '',
  keepErrorContainerWhenInactive = true,
  rows = 4,
  onKeyDown,
  maxLength,
  placeholderStyles,
  onBlur = () => null
}) => {
  const classes = useFormInputStyles({
    isDisabled,
    bright: style.bright,
    thick: style.thick,
    placeholderStyles,
    isMobile: isMobile()
  })

  return (
    <div>
      <div className={classes.titleContainer}>
        {title && (
          <GrinLabel color={!style.bright && 'var(--text-color-1)'} className={classes.label}>
            {`${title}${validationRules.required ? '*' : ''}`}
          </GrinLabel>
        )}
        {titleComment && (
          <GrinLabel
            className={[classes.titleCommentText, titleCommentClassName].join(' ')}
            color={!style.bright && 'var(--text-color-1)'}
          >
            {titleComment}
          </GrinLabel>
        )}
      </div>
      <textarea
        className={[
          classes.formInput,
          style.landing ? classes.landingTheme : '',
          errorMessage ? 'error' : '',
          className
        ].join(' ')}
        style={inputStyle}
        onChange={({ target }) => setValue(target.value)}
        placeholder={placeholder}
        disabled={isDisabled}
        error={errorMessage}
        rows={rows}
        value={value}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
        onBlur={onBlur}
      />
      {keepErrorContainerWhenInactive && errorMessage && (
        <div className={`${classes.errorMessage} ${errorMessage ? 'active' : ''}`}>{errorMessage}</div>
      )}
    </div>
  )
}

export default TextArea
