import { API, graphqlOperation } from 'aws-amplify'
import { COUPON_ERRORS } from 'consts/billingConsts'
import { chain } from 'lodash'
import React from 'react'
import { ofType } from 'redux-observable'
import { concat, forkJoin, from, of } from 'rxjs'
import {
  catchError,
  filter,
  flatMap,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators'
import { evaluateRolePermissions } from 'utils/authUtils'
import { isCountrySupportScopesOrder } from 'utils/billingUtils'
import Actions from '../actions'
import BillingUpgradePlanSuccessMessage from '../components/Profile/BillingUpgradePlanSuccessMessage'
import { transactionsByUsernameSorted } from '../graphql/customQueries'
import i18n from '../resources/locales/i18n'
import { fetchAll } from '../utils/graphqlUtils'
import { INTERNAL_PLAN } from 'consts/grinPlanConsts'
import { trackEvent } from 'utils/analyticsUtils'
import { push } from 'connected-react-router'
import { ROUTES } from 'consts'
import { doctorPlansByEmail } from 'graphql/queries'
import { DoctorSignUpProgressOptions } from 'consts/authConsts'

export const fetchGrinPlansTrigger = action$ =>
  action$.pipe(ofType(Actions.DOCTOR_DETAILS_RECEIVED), mapTo(Actions.fetchGrinPlans()))

export const fetchGrinPlansEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_GRIN_PLANS),
    flatMap(() =>
      from(API.get('grinApi', '/billing/v1/products/plans')).pipe(
        map(response => chain(response).keyBy('key').value()),
        mergeMap(plans => of(Actions.grinPlansReceived(plans))),
        catchError(error => of(Actions.grinPlansFailed(error)))
      )
    )
  )

export const fetchDoctorPaymentMethodTrigger = action$ =>
  action$.pipe(
    ofType(Actions.DOCTOR_DETAILS_RECEIVED),
    filter(() => evaluateRolePermissions().billing),
    mapTo(Actions.fetchDoctorPaymentMethod())
  )

export const fetchDoctorPaymentMethodEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_DOCTOR_PAYMENT_METHOD),
    filter(() => evaluateRolePermissions().billing),
    flatMap(() =>
      from(API.get('grinApi', '/billing/v1/paymentMethods')).pipe(
        mergeMap(paymentMethod => of(Actions.doctorPaymentMethodReceived(paymentMethod))),
        catchError(error => of(Actions.doctorPaymentMethodFailed(error)))
      )
    )
  )

export const savePaymentMethodFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SAVE_PAYMENT_METHOD_FAILED),
    pluck('payload'),
    map(err => {
      if (err?.response?.data?.code === 'stripeApiError' && err?.response?.data?.message) {
        return Actions.showSnackbar({
          type: 'error',
          text: err.response.data.message
        })
      }

      return Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    })
  )

export const upgradePlanEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPGRADE_PLAN),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action.payload,
      couponCode: state.billingReducer.order.coupon.couponCode
    })),
    switchMap(({ plan, scopesQuantity, scopesMiniQuantity, shippingAddress, couponCode }) =>
      from(
        API.post('grinApi', '/billing/v1/subscriptions/', {
          body: {
            planKey: plan.key,
            scopesQuantity,
            ...(scopesMiniQuantity && { scopesMiniQuantity }),
            shippingAddress,
            couponCode
          }
        })
      ).pipe(
        mergeMap(response => {
          const canClinicBuyScopes = isCountrySupportScopesOrder(shippingAddress.country)
          return concat(
            of(Actions.resetCoupon({})),
            of(
              Actions.upgradePlanReceived({
                ...response,
                grinPlan: plan
              })
            ),
            canClinicBuyScopes
              ? of(
                  Actions.showAlert({
                    type: 'success',
                    title: i18n.t('dialogs.upgradePlan.paymentCompleteMessageTitle'),
                    message: (
                      <BillingUpgradePlanSuccessMessage
                        hasScopes={scopesQuantity > 0 || scopesMiniQuantity > 0}
                        planPeriod={plan.period}
                        planName={plan.displayName}
                      />
                    )
                  })
                )
              : of(
                  Actions.showPrompt({
                    type: 'success',
                    title: i18n.t('dialogs.upgradePlan.paymentCompleteMessageTitle'),
                    message: i18n.t('dialogs.orderScopes.orderAcceptedOrderScopesMessage'),
                    primaryButton: {
                      isLarge: true,
                      label: i18n.t('common.appMenu.orderScopes'),
                      action: () => Actions.setOrderGrinKitsModalVisibility(true)
                    },
                    secondaryButton: { label: i18n.t('general.cancel') }
                  })
                )
          )
        }),
        catchError(({ response }) => of(Actions.upgradePlanFailed(response)))
      )
    )
  )

export const upgradePlanFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPGRADE_PLAN_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('dialogs.upgradePlan.upgradePlanFailMessage'),
        time: 10000
      })
    )
  )

export const orderScopesNonPurchase = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_ORDER_GRIN_KITS),
    pluck('payload'),
    filter(({ purchase }) => !purchase),
    flatMap(({ quantity, quantityMini, shippingAddress }) =>
      from(
        API.post('grinApi', '/billing/v1/orders/scopes/request', {
          body: {
            quantity,
            ...(quantityMini && { quantityMini }),
            shippingAddress
          }
        })
      ).pipe(
        mergeMap(order => of(Actions.createOrderGrinKitsReceived(order))),
        catchError(({ response }) => of(Actions.createOrderGrinKitsFailed(response)))
      )
    )
  )

export const purchaseScopesEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_ORDER_GRIN_KITS),
    pluck('payload'),
    filter(({ purchase }) => !!purchase),
    flatMap(({ invoiceId, shippingAddress }) =>
      from(
        API.post('grinApi', '/billing/v1/orders', {
          body: {
            invoiceId
          }
        })
      ).pipe(
        mergeMap(order =>
          of(
            Actions.createOrderGrinKitsReceived({
              ...order,
              country: shippingAddress.country
            })
          )
        ),
        catchError(({ response }) => of(Actions.createOrderGrinKitsFailed(response)))
      )
    )
  )

export const createOrderGrinKitsReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_ORDER_GRIN_KITS_RECEIVED),
    pluck('payload'),
    mergeMap(() =>
      concat(
        of(Actions.setOrderGrinKitsModalVisibility(false)),
        of(Actions.setScopesPaymentAcceptedModalVisibility(true))
      )
    )
  )

export const createOrderGrinKitsFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_ORDER_GRIN_KITS_FAILED),
    pluck('payload', 'status'),
    map(status =>
      Actions.showSnackbar(
        status === 400
          ? invalidAddressSnackbarArgs
          : {
              type: 'error',
              text: i18n.t('messages.somethingWentWrongContactSupport')
            }
      )
    )
  )

export const fetchTransactionsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_DOCTOR_TRANSACTIONS),
    withLatestFrom(state$),
    map(([, state]) => state.practiceReducer.accountOwner.username),
    switchMap(username =>
      from(fetchAll(transactionsByUsernameSorted, { owner: username }, 'transactionsByUsernameSorted')).pipe(
        mergeMap(transactions => of(Actions.doctorTransactionsReceived(transactions))),
        catchError(err => of(Actions.doctorTransactionsReceived(err)))
      )
    )
  )

export const fetchDoctorSubscription = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_DOCTOR_SUBSCRIPTION),
    withLatestFrom(state$),
    map(([action, state]) => ({
      username: action.payload?.username,
      grinPlanKey: state.practiceReducer.billing.grinPlanKey
    })),
    map(({ username, grinPlanKey } = {}) => {
      let path = `/billing/v1/subscriptions`
      if (username) {
        path += `?username=${username}`
      }

      return { endpoint: path, grinPlanKey }
    }),
    switchMap(({ endpoint, grinPlanKey }) =>
      grinPlanKey === INTERNAL_PLAN
        ? of(Actions.doctorSubscriptionReceived({}))
        : from(API.get('grinApi', endpoint)).pipe(
            mergeMap(subscription => of(Actions.doctorSubscriptionReceived(subscription))),
            catchError(({ response }) => of(Actions.doctorSubscriptionFailed(response)))
          )
    )
  )

export const calculateScopesOrderTax = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CALCULATE_ORDER_TAX),
    pluck('payload'),
    withLatestFrom(state$),
    map(([{ quantity = 0, quantityMini = 0, shippingAddress, grinPlanKey, couponCode }, { practiceReducer }]) => {
      let path = `/billing/v1/orders/tax?shippingAddress=${encodeURIComponent(JSON.stringify(shippingAddress))}`
      if (grinPlanKey) {
        path += `&grinPlanKey=${grinPlanKey}`
      }

      if (!!quantity) {
        path += `&scopesQuantity=${quantity}`
      }

      if (!!quantityMini) {
        path += `&scopesMiniQuantity=${quantityMini}`
      }

      if (!!couponCode) {
        path += `&couponCode=${couponCode}`
      }

      return path
    }),
    switchMap(endpoint =>
      from(API.get('grinApi', endpoint)).pipe(
        mergeMap(taxes => {
          if (taxes.coupon) {
            return concat(of(Actions.ordersTaxReceived(taxes)), of(Actions.setCouponManually(taxes.coupon)))
          } else {
            return of(Actions.ordersTaxReceived(taxes))
          }
        }),
        catchError(({ response }) => of(Actions.ordersTaxFailed(response)))
      )
    )
  )

export const fetchDoctorSubscriptionFailed = action$ =>
  action$.pipe(
    ofType(Actions.DOCTOR_SUBSCRIPTION_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const submitPlanUpgradeRequestEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUBMIT_PLAN_UPGRADE_REQUEST),
    pluck('payload'),
    switchMap(({ userRequest }) =>
      from(
        API.post('grinApi', '/billing/v1/subscriptions/requests/upgrade', {
          body: { userRequest }
        })
      ).pipe(
        mergeMap(() => of(Actions.submitPlanUpgradeRequestReceived())),
        catchError(({ response }) => of(Actions.submitPlanUpgradeRequestFailed(response)))
      )
    )
  )

export const submitPlanUpgradeRequestSuccess = action$ =>
  action$.pipe(
    ofType(Actions.SUBMIT_PLAN_UPGRADE_REQUEST_RECEIVED),
    mapTo(
      Actions.showAlert({
        type: 'success',
        title: i18n.t('dialogs.upgradePlan.upgradePlanRequestTitle'),
        message: i18n.t('dialogs.upgradePlan.upgradeCancelPlanRequestMessage')
      })
    )
  )

export const submitPlanUpgradeRequestFailed = action$ =>
  action$.pipe(
    ofType(Actions.SUBMIT_PLAN_UPGRADE_REQUEST_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const calculateScopeOrderTaxFailed = action$ =>
  action$.pipe(
    ofType(Actions.ORDER_TAX_FAILED),
    pluck('payload', 'status'),
    map(status =>
      Actions.showSnackbar(
        status === 400
          ? invalidAddressSnackbarArgs
          : {
              type: 'error',
              text: i18n.t('dialogs.upgradePlan.calculateTaxErrorMessage')
            }
      )
    )
  )

export const submitCancelSubscriptionRequestEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUBMIT_PLAN_CANCELLATION_REQUEST),
    switchMap(() =>
      from(API.post('grinApi', '/billing/v1/subscriptions/requests/cancel')).pipe(
        mergeMap(() => of(Actions.submitPlanCancellationRequestReceived())),
        catchError(({ response }) => of(Actions.submitPlanCancellationRequestFailed(response)))
      )
    )
  )

export const submitCancelSubscriptionRequestSuccess = action$ =>
  action$.pipe(
    ofType(Actions.SUBMIT_PLAN_CANCELLATION_REQUEST_RECEIVED),
    mapTo(
      Actions.showAlert({
        type: 'success',
        title: i18n.t('dialogs.upgradePlan.cancelPlanRequestTitle'),
        message: i18n.t('dialogs.upgradePlan.upgradeCancelPlanRequestMessage')
      })
    )
  )

export const submitCancelSubscriptionRequestFailed = action$ =>
  action$.pipe(
    ofType(Actions.SUBMIT_PLAN_CANCELLATION_REQUEST_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const fetchDoctorSeatsEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_DOCTOR_SEATS),
    switchMap(() =>
      from(API.get('grinApi', '/accounts/doctors/seats')).pipe(
        mergeMap(seats => of(Actions.fetchDoctorSeatsReceived(seats))),
        catchError(({ response }) => of(Actions.fetchDoctorSeatsFailed(response)))
      )
    )
  )

export const validateAddressEpic = action$ =>
  action$.pipe(
    ofType(Actions.VALIDATE_ADDRESS),
    pluck('payload'),
    switchMap(({ country, state, zip, city, street, forwardTo }) =>
      from(
        API.get(
          'grinApi',
          `/billing/v1/customers/address/validation?country=${country}&state=${state}&zip=${zip}&city=${city}&street=${street}`
        )
      ).pipe(
        map(({ result }) => result === 'valid'),
        mergeMap(isValid => of(Actions.validateAddressReceived({ isValid, forwardTo }))),
        catchError(({ response }) => of(Actions.validateAddressFailed(response)))
      )
    )
  )

export const validateAddressReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.VALIDATE_ADDRESS_RECEIVED),
    pluck('payload'),
    filter(({ isValid, forwardTo }) => forwardTo && isValid),
    map(({ forwardTo }) => push(forwardTo))
  )

export const validateAddressFailed = action$ =>
  action$.pipe(
    ofType(Actions.VALIDATE_ADDRESS_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const clearDraftInvoiceEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SET_ORDER_GRIN_KITS_MODAL_VISIBILITY, Actions.SET_UPGRADE_PLAN_DIALOG_VISIBILITY),
    withLatestFrom(state$),
    map(([action, state]) => ({
      isVisible: action.payload,
      invoiceId: state.billingReducer.order.invoiceId
    })),
    filter(({ isVisible, invoiceId }) => invoiceId && !isVisible),
    map(({ invoiceId }) => Actions.deleteInvoice({ invoiceId }))
  )

export const planUpgradeClearDraftInvoiceEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPGRADE_PLAN_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      invoiceId: state.billingReducer.order.invoiceId
    })),
    map(({ invoiceId }) => Actions.deleteInvoice({ invoiceId }))
  )

export const deleteInvoiceEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_INVOICE),
    pluck('payload'),
    switchMap(({ invoiceId }) =>
      from(API.del('grinApi', `/billing/v1/orders/${invoiceId}`)).pipe(
        mergeMap(() => of(Actions.deleteInvoiceReceived({ invoiceId }))),
        catchError(({ response }) => of(Actions.deleteInvoiceFailed({ response, invoiceId })))
      )
    )
  )

const invalidAddressSnackbarArgs = {
  type: 'error',
  text: i18n.t('dialogs.upgradePlan.validAddressError')
}

export const applyCouponCode = (action$, state$) =>
  action$.pipe(
    ofType(Actions.APPLY_COUPON_CODE),
    withLatestFrom(state$),
    map(([action, state]) => ({
      couponCode: action.payload.couponCode,
      invoiceId: state.billingReducer.order.invoiceId
    })),
    switchMap(({ couponCode, invoiceId }) =>
      from(
        API.post('grinApi', '/billing/v1/coupons/apply', {
          body: {
            invoiceId,
            couponCode
          }
        })
      ).pipe(
        mergeMap(invoice =>
          invoice.coupon.discountAmount === 0
            ? of(Actions.applyCouponCodeFailure({ couponError: COUPON_ERRORS.COUPON_INVALID_FOR_INVOICE_ITEMS }))
            : of(Actions.applyCouponCodeSuccess(invoice))
        ),
        catchError(({ response }) => of(Actions.applyCouponCodeFailure()))
      )
    )
  )

export const clearCouponCode = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CLEAR_COUPON_CODE),
    withLatestFrom(state$),
    map(([action, state]) => ({
      deleteCoupons: true,
      invoiceId: state.billingReducer.order.invoiceId
    })),
    switchMap(body =>
      from(API.post('grinApi', '/billing/v1/coupons/apply', { body })).pipe(
        mergeMap(invoice => of(Actions.clearCouponCodeReceived(invoice))),
        catchError(({ response }) => of(Actions.clearCouponCodeFailed()))
      )
    )
  )

export const clearCouponCodeFailed = action$ =>
  action$.pipe(
    ofType(Actions.CLEAR_COUPON_CODE_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const attachCreditCardEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_ATTACH_CREDIT_CARD),
    pluck('payload'),
    switchMap(({ paymentMethodId, doctorUsername, email, isQuickSignup }) => {
      trackEvent('Sign up - Attach credit card requested', {
        email
      })
      return forkJoin({
        response: from(
          API.put('grinApi', '/billing/v1/paymentMethods', {
            body: {
              paymentMethodId: paymentMethodId,
              subjectUsername: doctorUsername
            }
          })
        ),
        doctorPlansResponse: from(API.graphql(graphqlOperation(doctorPlansByEmail, { email })))
      }).pipe(
        map(({ response, doctorPlansResponse }) => ({
          response,
          doctorPlans: doctorPlansResponse.data.doctorPlansByEmail.items[0]
        })),
        mergeMap(({ response, doctorPlans }) =>
          concat(
            of(Actions.attachCreditCardReceived({ email, isQuickSignup, doctorPlans })),
            of(Actions.savePaymentMethodReceived(response))
          )
        ),
        catchError(({ response }) =>
          of(
            Actions.attachCreditCardFailed({
              response,
              email
            })
          )
        )
      )
    })
  )

export const attachCreditCardFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.ATTACH_CREDIT_CARD_FAILED),
    pluck('payload'),
    mergeMap(({ email, response }) => {
      trackEvent('Sign up - Attach credit card failed', {
        email
      })

      return of(
        Actions.showSnackbar({
          type: 'error',
          time: 10000,
          text:
            response?.data.code === 'stripeApiError'
              ? response?.data.message
              : i18n.t('messages.somethingWentWrongContactSupport')
        })
      )
    })
  )

export const attachCreditCardReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.ATTACH_CREDIT_CARD_RECEIVED),
    pluck('payload'),
    tap(({ email }) => {
      trackEvent('Sign up - Attach credit card completed', {
        email
      })
    }),
    filter(
      ({ doctorPlans, isQuickSignup }) =>
        isQuickSignup || doctorPlans.signupProgress === DoctorSignUpProgressOptions.AccountCreated
    ),
    switchMap(({ email, isQuickSignup, doctorPlans }) =>
      from(
        API.put('grinServerlessApi', '/accounts/v2/doctors/signupProgress', {
          body: {
            doctorPlansEmail: email,
            signupProgress: DoctorSignUpProgressOptions.BillingInfoCompleted
          }
        })
      ).pipe(
        mergeMap(() => {
          return isQuickSignup
            ? concat(of(Actions.finishQuickSignup({ email })), of(push(ROUTES.QUICK_SIGN_UP)))
            : of(push(ROUTES.PATIENTS))
        }),
        catchError(({ response }) =>
          of(
            Actions.showSnackbar({
              type: 'error',
              text: i18n.t('error.updateBillingInfoCompletedError')
            })
          )
        )
      )
    )
  )

export const fetchTransactionPdfEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_TRANSACTION_PDF),
    pluck('payload'),
    switchMap(({ transactionId }) =>
      from(API.get('grinServerlessApi', `/billing/v1/transactions/${transactionId}/pdf`)).pipe(
        mergeMap(data => of(Actions.fetchTransactionPdfReceived({ ...data, transactionId }))),
        catchError(error => of(Actions.fetchTransactionPdfFailed({ transactionId, error })))
      )
    )
  )

export const fetchTransactonPdfFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_TRANSACTION_PDF_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('error.failedToFetchInvoicePdf'),
        time: 10000
      })
    )
  )

export const fetchTransactionPdfReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_TRANSACTION_PDF_RECEIVED),
    pluck('payload'),
    tap(({ invoicePdf }) => window.open(invoicePdf, '_blank')),
    ignoreElements()
  )
