import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'

const useStyles = makeStyles(theme => ({
  label: {
    color: 'var(--text-color-12)'
  }
}))

const OrbitControl = ({ icon, label }) => {
  const classes = useStyles()

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={1}>
      <Grid item>{icon}</Grid>
      <Grid item>
        <DazzedParagraph12 className={classes.label}>{label}</DazzedParagraph12>
      </Grid>
    </Grid>
  )
}

export default OrbitControl
