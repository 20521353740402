let initialized = false

export const initIntercom = ({
  appId,
  apiBase = 'https://api-iam.intercom.io',
  name,
  email,
  createdAt,
  properties = {}
}) => {
  if (initialized) {
    return
  }

  window.Intercom('boot', {
    api_base: apiBase,
    app_id: appId,
    name,
    email,
    created_at: createdAt,
    ...properties
  })

  initialized = true
}

export const isInitialized = () => initialized
