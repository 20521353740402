import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  divider: {
    height: 1,
    backgroundColor: '#e3ecf5',
    width: '100%',
    margin: '8px 0px 8px 0px'
  }
}))

const Divider = () => {
  const classes = useStyles()

  return <div className={classes.divider} />
}

export default Divider
