import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  addCondition: {
    color: 'var(--text-color-25)',
    textDecoration: 'underline',
    userSelect: 'none',
    cursor: 'pointer'
  }
}))

const AddCondiditionButton = ({ onClick = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item onClick={onClick}>
        <DazzedParagraph14 className={classes.addCondition}>
          {t('pages.accountSettings.automations.editorModal.addCondition')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <DazzedParagraph14 color={'var(--text-color-11)'}>
          {t('pages.accountSettings.automations.editorModal.optional')}
        </DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default AddCondiditionButton
