import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'

import Actions from 'actions'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import {
  setOrthoShowMore as setOrthoShowMoreToStorage,
  getOrthoShowMore as getOrthoShowMoreFromStorage
} from 'utils/storageUtils'
import { ORTHO_PROGRAM } from 'consts/appConsts'

import BaseModal from 'components/common/modals/BaseModal'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import ServicesToggle from '../ServicesToggle'
import ExpandMore from 'components/common/ExpandMore'
import OrthoConfiguration from './OrthoConfiguration'
import ProgramChip from '../../../Patients/ProgramChip'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 12
  },
  description: {
    marginRight: 15
  },
  spacer: {
    height: 30
  },
  largeSpacer: {
    height: 45
  },
  smallSpacer: {
    height: 15
  },
  noteText: {
    color: 'var(--text-color-17)',
    marginTop: 5,
    marginBottom: 10
  },
  expandMore: {
    textDecoration: 'underline',
    color: 'var(--text-color-25)',
    fontWeight: 400
  }
}))

const OrthoToggle = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isLoading = useSelector(state => state.profileReducer.isLoading)
  const { isOrthoEnabled } = usePracticeConfiguration()

  const [isTurnOnModalOpen, setIsTurnOnModalOpen] = useState(false)
  const [isTurnOffModalOpen, setIsTurnOffModalOpen] = useState(false)
  const [showMoreExpanded, setShowMoreExpanded] = useState(!!getOrthoShowMoreFromStorage())

  useEffect(() => {
    if (!isLoading) {
      setIsTurnOffModalOpen(false)
      setIsTurnOnModalOpen(false)
    }
  }, [isLoading])

  const handleToggleRcService = useCallback(
    isOrthoEnabled => dispatch(Actions.requestUpdateAppSettings({ isOrthoEnabled })),
    [dispatch]
  )

  const handleChangeShowMoreExpandable = useCallback(() => {
    setShowMoreExpanded(!showMoreExpanded)
    setOrthoShowMoreToStorage(!showMoreExpanded)
  }, [showMoreExpanded])

  return (
    <>
      <ServicesToggle
        tagComponent={<ProgramChip program={ORTHO_PROGRAM} />}
        title={t('pages.accountSettings.services.ortho.title')}
        checked={!!isOrthoEnabled}
        onChange={checked => (checked ? setIsTurnOnModalOpen(true) : setIsTurnOffModalOpen(true))}
      >
        <DazzedParagraph14 strong>{t('pages.accountSettings.services.ortho.description')}</DazzedParagraph14>

        <DazzedParagraph14 className={classes.noteText}>
          {t('pages.accountSettings.services.ortho.costNote')}
        </DazzedParagraph14>
        <Collapse in={showMoreExpanded}>
          <OrthoConfiguration />
        </Collapse>
        <DazzedParagraph14 strong>
          <ExpandMore
            showMoreText={t('pages.accountSettings.services.ortho.showMoreSettings')}
            showLessText={t('pages.accountSettings.services.ortho.hideMoreSettings')}
            expanded={showMoreExpanded}
            onClick={handleChangeShowMoreExpandable}
            className={classes.expandMore}
          />
        </DazzedParagraph14>
      </ServicesToggle>

      <BaseModal
        open={isTurnOnModalOpen}
        title={t('pages.accountSettings.services.ortho.warningTitle')}
        variant="alert"
        isLoading={isLoading}
        onPrimaryBtnClick={() => handleToggleRcService(true)}
        onSecondaryBtnClick={() => setIsTurnOnModalOpen(false)}
        primaryLabel={t('pages.accountSettings.services.ortho.turnOn')}
        secondaryLabel={t('general.cancel')}
        handleClose={() => setIsTurnOnModalOpen(false)}
      >
        <DazzedParagraph16 textAlign="center">
          {t('pages.accountSettings.services.ortho.turnOnWarning')}
        </DazzedParagraph16>
      </BaseModal>

      <BaseModal
        open={isTurnOffModalOpen}
        title={t('pages.accountSettings.services.ortho.warningTitle')}
        variant="alert"
        isLoading={isLoading}
        onPrimaryBtnClick={() => handleToggleRcService(false)}
        onSecondaryBtnClick={() => setIsTurnOffModalOpen(false)}
        primaryLabel={t('pages.accountSettings.services.ortho.turnOff')}
        secondaryLabel={t('general.cancel')}
        handleClose={() => setIsTurnOffModalOpen(false)}
      >
        <DazzedParagraph16 textAlign="center">
          {t('pages.accountSettings.services.ortho.turnOffWarning')}
        </DazzedParagraph16>
      </BaseModal>
    </>
  )
}

export default OrthoToggle
