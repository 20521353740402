import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import Step1Download from 'components/common/icons/bulk-invite/step1-download'
import Step2Upload from 'components/common/icons/bulk-invite/step2-upload'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { grinCommonCloudfrontUrl } from 'utils/storageUtils'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import UploadFormDialog from './UploadFormDialog'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  stepsList: {
    padding: '8px 8px!important'
  },
  bold: {
    fontWeight: 600
  },
  center: {
    textAlign: 'center'
  },
  downloadIcon: {
    marginTop: 12
  },
  uploadButtonContainer: {
    padding: '16px 0'
  }
}))

const BulkInvite = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const csvDownloadUrl = useMemo(() => `${grinCommonCloudfrontUrl}/public/BulkInvite/v3/BulkInvite.csv`, [])
  const handleOpenUploadForm = useCallback(() => {
    dispatch(Actions.toggleBulkInvitePopup({ isOpen: true }))
  }, [dispatch])

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <DazzedHeading20>{t('pages.accountSettings.advancedActions.bulkInvite.title')}</DazzedHeading20>
      </Grid>
      <Grid item>
        <DazzedParagraph14 strong>
          {t('pages.accountSettings.advancedActions.bulkInvite.description')}
        </DazzedParagraph14>
      </Grid>
      <Grid item className={classes.stepsList}>
        <Grid container spacing={1}>
          <Grid item>
            <Grid container alignItems="center" direction="row">
              <Grid item xs={2}>
                <div className={[classes.center, classes.downloadIcon].join(' ')}>
                  <Step1Download />
                </div>
              </Grid>
              <Grid item xs={10}>
                <DazzedParagraph14 strong>
                  <span className={classes.bold}>1. </span>
                  <a
                    href={csvDownloadUrl}
                    download
                    target="_blank"
                    onClick={() => trackEvent('bulk invite - download csv click')}
                    rel="noreferrer"
                  >
                    {t('pages.accountSettings.advancedActions.bulkInvite.steps.download_1')}
                  </a>
                  {t('pages.accountSettings.advancedActions.bulkInvite.steps.download_2')}
                </DazzedParagraph14>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" direction="row">
              <Grid item xs={2}>
                <div className={classes.center}>
                  <Step2Upload />
                </div>
              </Grid>
              <Grid item xs={10}>
                <DazzedParagraph14 strong>
                  <span className={classes.bold}>2. </span>
                  {t('pages.accountSettings.advancedActions.bulkInvite.steps.upload')}
                </DazzedParagraph14>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <div className={classes.uploadButtonContainer}>
          <PrimaryButton
            label={t('pages.accountSettings.advancedActions.bulkInvite.uploadButton')}
            onClick={handleOpenUploadForm}
          />
        </div>
      </Grid>
      <Grid item>
        <DazzedParagraph14 strong>{t('pages.accountSettings.advancedActions.bulkInvite.note')}</DazzedParagraph14>
      </Grid>
      <UploadFormDialog />
    </Grid>
  )
}

export default BulkInvite
