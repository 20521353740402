import * as React from 'react'

class HourGlass extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  render() {
    return (
      <svg ref={this.myRef} width={11} height={14} fill="none" viewBox="0 0 11 14" {...this.props}>
        <path
          d="M10.0625 0.875H10.1719C10.3531 0.875 10.5 0.728082 10.5 0.546875V0.328125C10.5 0.146918 10.3531 0 10.1719 0H0.328125C0.146918 0 0 0.146918 0 0.328125V0.546875C0 0.728082 0.146918 0.875 0.328125 0.875H0.4375C0.4375 3.38573 1.64358 6.17031 3.98251 7C1.63603 7.83237 0.4375 10.6224 0.4375 13.125H0.328125C0.146918 13.125 0 13.2719 0 13.4531V13.6719C0 13.8531 0.146918 14 0.328125 14H10.1719C10.3531 14 10.5 13.8531 10.5 13.6719V13.4531C10.5 13.2719 10.3531 13.125 10.1719 13.125H10.0625C10.0625 10.6143 8.85642 7.82969 6.51749 7C8.86397 6.16763 10.0625 3.37758 10.0625 0.875ZM1.3125 0.875H9.1875C9.1875 3.89531 7.42462 6.34375 5.25 6.34375C3.07538 6.34375 1.3125 3.89531 1.3125 0.875ZM9.1875 13.125H1.3125C1.3125 10.1047 3.07538 7.65625 5.25 7.65625C7.42462 7.65625 9.1875 10.1047 9.1875 13.125ZM2.69005 11.375H7.80998C7.88228 11.375 7.95256 11.3989 8.00992 11.4429C8.06728 11.4869 8.10851 11.5486 8.12722 11.6184C8.14766 11.6949 8.16643 11.7717 8.18352 11.8489C8.22902 12.0546 8.07414 12.25 7.86349 12.25H2.63654C2.42588 12.25 2.27101 12.0546 2.31651 11.8489C2.33346 11.7723 2.35225 11.6954 2.37281 11.6184C2.39151 11.5486 2.43274 11.4869 2.4901 11.4429C2.54746 11.3989 2.61775 11.375 2.69005 11.375ZM3.12651 9.98288C3.18512 9.90084 3.24691 9.82113 3.31174 9.74392C3.37466 9.66905 3.4668 9.62503 3.56461 9.62503H6.93544C7.03322 9.62503 7.1254 9.66905 7.18832 9.74392C7.25313 9.82114 7.31492 9.90085 7.37354 9.98288C7.52823 10.1992 7.37114 10.5 7.10522 10.5H3.39481C3.12892 10.5 2.97183 10.1991 3.12651 9.98288Z"
          fill="#727B8C"
        />
      </svg>
    )
  }
}

export default HourGlass
