import React, { useCallback, useState } from 'react'
import { makeStyles, Tooltip as MuiTooltip, ClickAwayListener } from '@material-ui/core'

const useStyles = () =>
  makeStyles({
    tooltipPopper: {
      opacity: 1,
      zIndex: '9999 !important'
    },
    tooltipPopperArrow: {
      color: 'var(--bg-color-31)'
    },
    tooltipPopperArrowDark: {
      color: 'var(--bg-color-17)'
    },
    tooltip: {
      opacity: 1,
      backgroundColor: 'var(--bg-color-31)',
      color: 'var(--text-color-7)'
    },
    tooltipDark: {
      backgroundColor: 'var(--bg-color-17)',
      color: 'var(--text-color-1)'
    }
  })()

const Tooltip = ({ children, value, placement = 'right', arrow, variant = 'dark' }) => {
  const classes = useStyles()
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)

  const handleClickAway = useCallback(() => {
    if (tooltipIsOpen) {
      setTooltipIsOpen(false)
    }
  }, [tooltipIsOpen])

  return (
    <ClickAwayListener onClickAway={handleClickAway} touchEvent="onTouchStart">
      <MuiTooltip
        open={tooltipIsOpen}
        title={value}
        arrow={arrow}
        placement={placement}
        classes={{
          popper: classes.tooltipPopper,
          tooltip: [classes.tooltip, variant === 'dark' && classes.tooltipDark].join(' '),
          arrow: [classes.tooltipPopperArrow, variant === 'dark' && classes.tooltipPopperArrowDark].join(' ')
        }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <span
          onClick={event => {
            event.stopPropagation()
            setTooltipIsOpen(true)
          }}
        >
          {children}
        </span>
      </MuiTooltip>
    </ClickAwayListener>
  )
}

export default Tooltip
