import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { programTagsOrder, treatmentTypeTagsOrder, createPracticeMemberTagValue } from 'utils/tagUtils'
import { omit } from 'lodash'
import { StatusTypesOrder } from 'utils/statusUtils'
import useAppliedPatientsFilter from './useAppliedPatientsFilter'
import useGaFlags from 'hooks/useGaFlags'
import { selectStatuses } from 'selectors/statusesSelector'
import { isUserOfRole } from 'utils/authUtils'
import { Roles } from 'consts/authConsts'
import useRolePermissions from 'hooks/useRolePermissions'

export default () => {
  const { t } = useTranslation()

  const { appliedFilters, applyFilters } = useAppliedPatientsFilter()
  const gaFlags = useGaFlags()
  const { isHiUser, isGiUser } = useRolePermissions()
  const isAdmin = useMemo(() => isUserOfRole([Roles.Admin]), [])
  const { data: practiceMembers } = useSelector(state => state.profileReducer.practiceMembers)
  const aggregatedCustomTags = useSelector(state => state.practiceReducer.aggregatedCustomTags.data)
  const systemTags = useSelector(state => state.profileReducer.systemTags?.data)
  const { types: statuses } = useSelector(selectStatuses)

  const assigneTags = useMemo(() => {
    let tags = (practiceMembers || [])
      .filter(practiceMember => practiceMember.status !== 'disabled')
      .map(practiceMember => ({
        value: practiceMember.id,
        label: createPracticeMemberTagValue(practiceMember),
        type: 'assignee'
      }))

    tags.push({
      value: 'Unassigned',
      label: t('pages.patients.patientsList.patientsTagFilter.unassignedLabel'),
      type: 'assignee'
    })

    return tags
  }, [practiceMembers, t])

  const treatmentTags = useMemo(
    () =>
      systemTags
        ?.filter(tag => tag.type === 'treatment')
        .map(tag => ({
          ...tag,
          label: tag.value
        }))
        .sort((a, b) => treatmentTypeTagsOrder[a.value] - treatmentTypeTagsOrder[b.value]),
    [systemTags]
  )

  const scanTags = useMemo(
    () =>
      systemTags
        ?.filter(tag => tag.type === 'scan' && (tag.value !== 'needsAttention' || gaFlags.grintelligence))
        .map(tag => ({
          ...tag,
          label: t(`common.tags.${tag.value}`)
        }))
        .concat(
          isHiUser || isGiUser || isAdmin
            ? [{ label: t(`common.tags.pendingReview`), type: 'scan', value: 'pendingReview', tagClass: 'system' }]
            : []
        ),
    [t, systemTags, gaFlags, isHiUser, isGiUser, isAdmin]
  )

  const programTags = useMemo(
    () =>
      systemTags
        .filter(tag => {
          return tag.type === 'program' && tag.value !== 'ortho'
        })
        .map(tag => ({
          ...omit(tag, ['settings']),
          label: tag.value === 'serviceAccount' ? t('common.tags.serviceAccount') : tag.value.toUpperCase()
        }))
        .sort((a, b) => programTagsOrder[a.value] - programTagsOrder[b.value]),
    [t, systemTags]
  )

  const statusTags = useMemo(
    () =>
      statuses
        .map(status => ({ value: status, type: 'status', label: t(`statuses.names.${status}`) }))
        .sort((a, b) => StatusTypesOrder[a.value] - StatusTypesOrder[b.value]),
    [statuses, t]
  )

  const customTags = useMemo(
    () =>
      aggregatedCustomTags?.map(tag => ({
        type: 'custom',
        label: tag.tagValue,
        value: tag.tagValue
      })),
    [aggregatedCustomTags]
  )

  const filterableSystemTags = useMemo(
    () => [{ label: t(`common.tags.unread`), type: 'chat', value: 'unread', tagClass: 'system' }],
    [t]
  )

  const tagsDictionary = useMemo(
    () => ({
      custom: customTags,
      program: programTags,
      treatment: treatmentTags,
      status: statusTags,
      scan: scanTags,
      chat: filterableSystemTags.filter(tag => tag.type === 'chat'),
      assignee: assigneTags
    }),
    [customTags, programTags, treatmentTags, statusTags, assigneTags, scanTags, filterableSystemTags]
  )

  const appliedFilterTags = useMemo(() => {
    return Object.keys(appliedFilters).reduce(
      (final, filterKey) => ({
        ...final,
        ...{
          [filterKey]: tagsDictionary[filterKey]?.filter(tag => appliedFilters[filterKey].includes(tag.value)) || {}
        }
      }),
      {}
    )
  }, [tagsDictionary, appliedFilters])

  const mapSelectedFiltersToSearchInput = useCallback(({ selectedFilters }) => {
    return Object.keys(selectedFilters)
      .filter(filterKey => selectedFilters[filterKey].length)
      .reduce(
        (final, filterKey) => ({
          ...final,
          [filterKey]: selectedFilters[filterKey].map(filter => filter.value)
        }),
        {}
      )
  }, [])

  return {
    assigneTags,
    treatmentTags,
    programTags,
    statusTags,
    customTags,
    scanTags,
    filterableSystemTags,
    tagsDictionary,
    appliedFilterTags,
    mapSelectedFiltersToSearchInput,
    applyFilters
  }
}
