import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import BeforeAfterStar from 'components/common/icons/BeforeAfter/BeforeAfterStar'
import { useMemo } from 'react'

const useStyles = makeStyles(theme => ({
  star: {
    cursor: 'pointer'
  }
}))

const NUM_OF_STARS = 5

const BeforeAfterFeedbackRating = ({ selectedRating, onRatingSelected = () => {} }) => {
  const classes = useStyles()

  const [hoveredRating, setHoveredRating] = useState(0)
  const rating = useMemo(() => hoveredRating || selectedRating, [selectedRating, hoveredRating])

  const handleRatingMouseEnter = useCallback(rating => {
    setHoveredRating(rating)
  }, [])

  const handleRatingMouseLeave = useCallback(selection => {
    setHoveredRating(0)
  }, [])

  const handleRatingClicked = useCallback(
    selection => {
      if (selectedRating === selection) {
        onRatingSelected(0)
      } else {
        onRatingSelected(selection)
      }
    },
    [onRatingSelected, selectedRating]
  )

  return (
    <div>
      {Array.from(Array(NUM_OF_STARS).keys()).map((_, i) => (
        <span
          key={i}
          onMouseEnter={() => handleRatingMouseEnter(i + 1)}
          onMouseLeave={() => handleRatingMouseLeave(i + 1)}
          onClick={() => handleRatingClicked(i + 1)}
          className={classes.star}
        >
          <BeforeAfterStar filled={i < rating} />
        </span>
      ))}
    </div>
  )
}

export default BeforeAfterFeedbackRating
