import * as React from 'react'

function SvgGreySearch({ width = 32, height = 32, ...props }) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="7" r="6" stroke="#ACB0B7" strokeWidth="1.5" />
      <path d="M11 11L16 16" stroke="#ACB0B7" strokeWidth="1.5" />
    </svg>
  )
}

export default SvgGreySearch
