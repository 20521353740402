import * as React from 'react'

export default () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#7F5FF9" />
      <path
        d="M13.4499 11.4643L13.8495 11.4219L14.0104 11.0863C15.1843 8.63723 18.6711 8.63723 19.845 11.0863L20.0059 11.4219V28.2975L19.557 28.8693C17.9318 30.9394 14.6476 30.3134 13.8978 27.7905L12.2498 27.542C10.0278 27.207 8.74532 24.8372 9.68017 22.7937L10.542 20.9099L10.36 20.7294C8.90899 19.2899 9.20083 16.8693 10.9524 15.8159L10.8703 15.5891C10.1876 13.7028 11.4551 11.6761 13.4499 11.4643Z"
        stroke="white"
        strokeWidth="1.4042"
        strokeLinecap="round"
      />
      <path
        d="M17.1857 30.1353C15.2469 30.1353 13.6752 28.5636 13.6752 26.6248C13.6752 25.4763 14.2267 24.4566 15.0794 23.8162"
        stroke="white"
        strokeWidth="1.4042"
        strokeLinecap="round"
      />
      <path
        d="M16.1327 16.9696C15.1999 16.4311 14.8803 15.2384 15.4189 14.3056C15.7379 13.7531 16.2865 13.4157 16.8746 13.3444"
        stroke="white"
        strokeWidth="1.4042"
        strokeLinecap="round"
      />
      <path
        d="M10.5633 20.7723C11.1376 19.7776 12.1251 19.1703 13.1837 19.042"
        stroke="white"
        strokeWidth="1.4042"
        strokeLinecap="round"
      />
      <path d="M20 3V37" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeDasharray="3 3" />
      <path d="M26.5 10.5L23 14H20" stroke="white" strokeWidth="1.4" />
      <path d="M26.5 29.5L23 26H20" stroke="white" strokeWidth="1.4" />
      <path d="M30.5 20H20" stroke="white" strokeWidth="1.4" />
      <path d="M30.5 14.5H27L24 17.5H20" stroke="white" strokeWidth="1.4" />
      <path d="M30.5 25.5H27L24 22.5H20" stroke="white" strokeWidth="1.4" />
      <circle cx="26.5" cy="10.5" r="1.5" fill="#8465FF" stroke="white" strokeWidth="1.4" />
      <circle cx="26.5" cy="29.5" r="1.5" fill="#8465FF" stroke="white" strokeWidth="1.4" />
      <circle cx="30.5" cy="14.5" r="1.5" fill="#8465FF" stroke="white" strokeWidth="1.4" />
      <circle cx="30.5" cy="25.5" r="1.5" fill="#8465FF" stroke="white" strokeWidth="1.4" />
      <circle cx="30.5" cy="20" r="1.5" fill="#8465FF" stroke="white" strokeWidth="1.4" />
    </svg>
  )
}
