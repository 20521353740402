import { TimelineItemTypes } from 'consts/timelineConsts'
import DateLabelTimelineItem from './DateLabelTimelineItem'
import GrinScanTimelineItem from './GrinScanTimelineItem'
import TreatmentTypeUpdatedTimelineItem from './TreatmentTypeUpdatedTimelineItem'
import StatusUpdatedTimelineItem from './StatusUpdatedTimelineItem'

export default {
  [TimelineItemTypes.Date]: DateLabelTimelineItem,
  [TimelineItemTypes.GrinScan]: GrinScanTimelineItem,
  [TimelineItemTypes.TreamtentTypeUpdated]: TreatmentTypeUpdatedTimelineItem,
  [TimelineItemTypes.StatusUpdated]: StatusUpdatedTimelineItem
}
