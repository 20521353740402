import React from 'react'
import { makeStyles } from '@material-ui/styles'
import GrinCheckbox from 'components/common/GrinCheckbox'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 14,
    paddingLeft: 5,
    userSelect: 'none'
  },
  checkbox: {
    padding: 0
  }
}))

const ActionCheckbox = ({ variant = 'filled', checked = false, caption, onChange }) => {
  const classes = useStyles()

  return (
    <GrinCheckbox
      size="small"
      captionClassName={classes.label}
      className={classes.checkbox}
      onChange={onChange}
      variant={variant}
      checked={checked}
      caption={caption}
      radioBorderColor="var(--border-color-36)"
    />
  )
}

export default ActionCheckbox
