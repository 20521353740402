import * as React from 'react'

const Eraser = ({ color = 'white', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3125 4.95712L11.6056 1.25025C11.2404 0.901579 10.7549 0.707031 10.25 0.707031C9.74505 0.707031 9.25955 0.901579 8.89435 1.25025L0.687473 9.45712C0.508895 9.63483 0.367189 9.84608 0.270495 10.0787C0.1738 10.3114 0.124023 10.5608 0.124023 10.8127C0.124023 11.0647 0.1738 11.3141 0.270495 11.5468C0.367189 11.7794 0.508895 11.9907 0.687473 12.1684L3.6631 15.1496C3.71566 15.2018 3.77799 15.243 3.84653 15.271C3.91506 15.299 3.98844 15.3132 4.06247 15.3127H7.43747C7.5115 15.3132 7.58489 15.299 7.65342 15.271C7.72195 15.243 7.78429 15.2018 7.83685 15.1496L15.3125 7.66837C15.4911 7.49066 15.6328 7.27942 15.7295 7.04678C15.8261 6.81413 15.8759 6.56468 15.8759 6.31275C15.8759 6.06081 15.8261 5.81136 15.7295 5.57872C15.6328 5.34608 15.4911 5.13483 15.3125 4.95712ZM7.20685 14.1877H4.2931L1.4806 11.3752C1.33221 11.2256 1.24894 11.0235 1.24894 10.8127C1.24894 10.602 1.33221 10.3998 1.4806 10.2502L5.18747 6.54337L10.0193 11.3752L7.20685 14.1877ZM14.5193 6.87525L10.8125 10.5821L5.9806 5.75025L9.68747 2.04337C9.83978 1.9002 10.0409 1.82049 10.25 1.82049C10.459 1.82049 10.6602 1.9002 10.8125 2.04337L14.5193 5.75025C14.6677 5.89985 14.751 6.10203 14.751 6.31275C14.751 6.52346 14.6677 6.72564 14.5193 6.87525V6.87525Z"
        fill={color}
      />
    </svg>
  )
}

export default Eraser
