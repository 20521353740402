import * as React from 'react'

export default () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.41391 1.41421C10.195 0.633166 11.4613 0.633165 12.2423 1.41421V1.41421C13.0234 2.19526 13.0234 3.46159 12.2423 4.24264L4.79323 11.6917C4.58066 11.9043 4.29876 12.0334 3.99894 12.0555L1.41049 12.2461L1.60106 9.65762C1.62313 9.3578 1.75223 9.0759 1.96481 8.86332L9.41391 1.41421Z"
        stroke="#3C52EF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M7.89062 2.47552L11.0726 5.65751" stroke="#3C52EF" strokeWidth="1.5" />
    </svg>
  )
}
