import * as React from 'react'

export default ({ style }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M5.05347 7.10133C5.05347 5.97046 5.97022 5.05371 7.10109 5.05371H15.2916C16.4224 5.05371 17.3392 5.97046 17.3392 7.10133V15.2918C17.3392 16.4227 16.4224 17.3394 15.2916 17.3394H7.10109C5.97022 17.3394 5.05347 16.4227 5.05347 15.2918V7.10133Z"
        fill="#041333"
      />
      <path
        d="M12.8913 10.1179C13.5257 9.98664 14.1525 9.81993 14.7689 9.61854C14.4005 6.74891 11.3619 6.71729 11.3619 6.71729C10.1112 6.71729 9.0835 7.15013 8.27887 8.01581C7.5014 8.85748 7.11267 9.89429 7.11267 11.1262C7.11267 12.3462 7.48743 13.3608 8.23695 14.17C9.04181 15.0358 10.1168 15.4687 11.462 15.4687C12.2036 15.4687 12.8742 15.3842 13.4738 15.2151C14.0735 15.046 14.5074 14.8769 14.7757 14.7078L14.7797 10.9673C14.7798 10.9497 14.7759 10.9323 14.7683 10.9165C14.7607 10.9007 14.7495 10.8869 14.7357 10.8764C14.7219 10.8658 14.7058 10.8587 14.6889 10.8556C14.6719 10.8525 14.6544 10.8535 14.6379 10.8586C14.1591 11.0107 12.9753 11.341 11.6573 11.5495V12.3137C12.103 12.322 12.3989 12.3623 12.5449 12.4346C12.7697 12.5514 12.9814 12.797 12.9814 13.1713V13.5341C12.9821 13.6491 12.9601 13.7632 12.9168 13.8694C12.8734 13.9756 12.8096 14.0719 12.7291 14.1525C12.5275 14.3513 12.2044 14.4847 11.6877 14.4847C10.7329 14.4847 9.9464 14.0419 9.66045 13.3726C9.31716 12.5695 9.36107 12.1277 9.36107 11.0961C9.36107 9.83989 9.36968 9.41748 9.77079 8.74936C10.0953 8.20824 10.7277 7.95611 11.2735 7.97252C11.2735 7.97252 12.2051 7.88898 12.6365 8.73651C12.9445 9.34291 12.8913 10.1179 12.8913 10.1179Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12503 2.75C8.54911 2.75 8.89289 3.09378 8.89289 3.51786V5.05357H7.35718V3.51786C7.35718 3.09378 7.70096 2.75 8.12503 2.75Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6428 8.12479C19.6428 8.54887 19.299 8.89265 18.875 8.89265L17.3393 8.89265L17.3393 7.35693L18.875 7.35693C19.299 7.35693 19.6428 7.70071 19.6428 8.12479Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12503 19.6428C8.54911 19.6428 8.89289 19.299 8.89289 18.875V17.3393H7.35718V18.875C7.35718 19.299 7.70096 19.6428 8.12503 19.6428Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 8.12479C2.75 8.54887 3.09378 8.89265 3.51786 8.89265L5.05357 8.89265L5.05357 7.35693L3.51786 7.35693C3.09378 7.35693 2.75 7.70071 2.75 8.12479Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1964 2.75C11.6205 2.75 11.9643 3.09378 11.9643 3.51786V5.05357H10.4286V3.51786C10.4286 3.09378 10.7724 2.75 11.1964 2.75Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6428 11.1963C19.6428 11.6204 19.299 11.9642 18.875 11.9642L17.3393 11.9642L17.3393 10.4285L18.875 10.4285C19.299 10.4285 19.6428 10.7722 19.6428 11.1963Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1964 19.6428C11.6205 19.6428 11.9643 19.299 11.9643 18.875V17.3393H10.4286V18.875C10.4286 19.299 10.7724 19.6428 11.1964 19.6428Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 11.1963C2.75 11.6204 3.09378 11.9642 3.51786 11.9642L5.05357 11.9642L5.05357 10.4285L3.51786 10.4285C3.09378 10.4285 2.75 10.7722 2.75 11.1963Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2679 2.75C14.6919 2.75 15.0357 3.09378 15.0357 3.51786V5.05357H13.5V3.51786C13.5 3.09378 13.8438 2.75 14.2679 2.75Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6428 14.2679C19.6428 14.6919 19.299 15.0357 18.875 15.0357L17.3393 15.0357L17.3393 13.5L18.875 13.5C19.299 13.5 19.6428 13.8438 19.6428 14.2679Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2679 19.6428C14.6919 19.6428 15.0357 19.299 15.0357 18.875V17.3393H13.5V18.875C13.5 19.299 13.8438 19.6428 14.2679 19.6428Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 14.2679C2.75 14.6919 3.09378 15.0357 3.51786 15.0357L5.05357 15.0357L5.05357 13.5L3.51786 13.5C3.09378 13.5 2.75 13.8438 2.75 14.2679Z"
        fill="#041333"
      />
    </svg>
  )
}
