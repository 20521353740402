import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import actions from 'actions'

const DeleteAutomationRuleModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isOpen, ruleId } = useSelector(state => state.practiceReducer.automationRules.deleteRuleModal)

  const handleClose = useCallback(() => {
    dispatch(
      actions.toggleDeleteRuleModal({
        isOpen: false
      })
    )
  }, [dispatch])

  const handleDelete = useCallback(() => {
    dispatch(actions.deleteAutomationRule({ id: ruleId }))
    handleClose()
  }, [dispatch, handleClose, ruleId])

  return (
    <BaseModal
      open={isOpen}
      withCloseIcon
      variant="alert"
      title={t('pages.accountSettings.automations.deleteRuleModal.title')}
      onPrimaryBtnClick={handleClose}
      onSecondaryBtnClick={handleDelete}
      primaryLabel={t('general.cancel')}
      secondaryLabel={t('general.delete')}
      handleClose={handleClose}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item></Grid>
      </Grid>
    </BaseModal>
  )
}

export default DeleteAutomationRuleModal
