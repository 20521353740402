import * as React from 'react'

const Delete = ({ color = '#3C52EF', ...props }) => {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.24297 4.22499V10.6H2.14297V4.22499H7.24297ZM6.28672 0.399994H3.09922L2.46172 1.03749H0.230469V2.31249H9.15547V1.03749H6.92422L6.28672 0.399994ZM8.51797 2.94999H0.867969V10.6C0.867969 11.3012 1.44172 11.875 2.14297 11.875H7.24297C7.94422 11.875 8.51797 11.3012 8.51797 10.6V2.94999Z"
        fill={color}
      />
    </svg>
  )
}

export default Delete
