import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { InputBase } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    fontSize: 12,
    fontFamily: 'Dazzed',
    fontWeight: 500,
    color: 'var(--text-color-27)'
  }
}))

const AutohartTableNotesCell = ({ notes = '', onEdit = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [value, setValue] = useState(notes)

  useEffect(() => {
    setValue(notes || '')
  }, [notes])

  return (
    <InputBase
      className={classes.input}
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={() => onEdit(value)}
      placeholder={t('dialogs.autochart.table.notesInputPlaceholder')}
    />
  )
}

export default AutohartTableNotesCell
