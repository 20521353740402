import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { secondsToHHMM } from '../../../utils/stringUtils'

const useStyles = makeStyles({
  timer: {
    padding: '6px 10px',
    margin: '0 10px',
    width: 'fit-content',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.14)',
    borderRadius: 100
  },
  timerAlertBeforeTimeout: {
    color: 'red'
  }
})

const Timer = ({ start = false, timeoutSeconds = 0, timeoutWarningGap, onTimeout = () => {}, className }) => {
  const classes = useStyles()
  const [time, setTime] = useState(0)
  const isAboutToTimeout = useMemo(
    () => timeoutSeconds !== 0 && time > timeoutSeconds - timeoutWarningGap,
    [timeoutSeconds, time, timeoutWarningGap]
  )

  useEffect(() => {
    if (start) {
      const recordingTimer = setInterval(() => setTime(time => time + 1), 1000)
      return () => clearInterval(recordingTimer)
    }
  }, [start])

  useEffect(() => {
    if (timeoutSeconds !== 0 && time === timeoutSeconds + 1) {
      onTimeout()
    }
  }, [time, timeoutSeconds, onTimeout])

  return (
    <div className={[classes.timer, className, isAboutToTimeout ? classes.timerAlertBeforeTimeout : ''].join(' ')}>
      {secondsToHHMM(time)} / {secondsToHHMM(timeoutSeconds)}
    </div>
  )
}

export default Timer
