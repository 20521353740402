import * as React from 'react'

function SvgPlusMen(props) {
  return (
    <svg width="38" height="30" viewBox="0 0 38 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M8.78222 1.21216C6.01403 1.21216 3.76196 3.46423 3.76196 6.23242C3.76196 9.0006 6.01403 11.2527 8.78222 11.2527C11.5504 11.2527 13.8025 9.0006 13.8025 6.23242C13.8025 3.46423 11.5504 1.21216 8.78222 1.21216ZM8.78222 9.67054C6.88637 9.67054 5.34399 8.12817 5.34399 6.23231C5.34399 4.33646 6.88637 2.79408 8.78222 2.79408C10.6781 2.79408 12.2204 4.33646 12.2204 6.23231C12.2204 8.12817 10.6781 9.67054 8.78222 9.67054Z"
          fill="#3C52EF"
        />
        <path
          d="M23.0449 14.9376H17.0982C16.9218 14.9376 16.7485 14.9504 16.5782 14.9729C15.477 13.2689 13.5608 12.1387 11.3854 12.1387H6.17815C2.77156 12.1387 0 14.9101 0 18.3169V24.9969C0 25.4338 0.354164 25.7879 0.791016 25.7879H13.9342H16.7726H26.209C26.6458 25.7879 27 25.4338 27 24.9969V18.8927C27 16.7117 25.2258 14.9376 23.0449 14.9376ZM13.1432 18.8927V24.2059H1.58203V18.3169C1.58203 15.7825 3.64389 13.7207 6.1782 13.7207H11.3855C12.8692 13.7207 14.1908 14.4276 15.0317 15.5221C13.8997 16.2187 13.1432 17.4689 13.1432 18.8927ZM25.418 24.2058H15.9817H14.7252V18.8927C14.7252 17.5842 15.7898 16.5196 17.0983 16.5196H23.0449C24.3534 16.5196 25.418 17.5842 25.418 18.8927V24.2058Z"
          fill="#3C52EF"
        />
        <path
          d="M20.0713 5.78052C17.844 5.78052 16.032 7.59252 16.032 9.81986C16.032 12.0471 17.844 13.8591 20.0713 13.8591C22.2986 13.8591 24.1107 12.0471 24.1107 9.81986C24.1107 7.59257 22.2987 5.78052 20.0713 5.78052ZM20.0713 12.2772C18.7163 12.2772 17.614 11.1749 17.614 9.81991C17.614 8.46496 18.7163 7.3626 20.0713 7.3626C21.4262 7.3626 22.5286 8.4649 22.5286 9.81991C22.5286 11.1748 21.4262 12.2772 20.0713 12.2772Z"
          fill="#3C52EF"
        />
        <circle cx="30" cy="23" r="6" fill="white" />
      </g>
      <path
        d="M35.4498 18.0502C34.1276 16.7281 32.3697 16 30.5 16C28.6303 16 26.8724 16.7281 25.5502 18.0502C24.2281 19.3724 23.5 21.1303 23.5 23C23.5 24.8697 24.2281 26.6276 25.5502 27.9498C26.8724 29.2719 28.6303 30 30.5 30C32.3697 30 34.1276 29.2719 35.4498 27.9498C36.7719 26.6276 37.5 24.8697 37.5 23C37.5 21.1303 36.7719 19.3724 35.4498 18.0502ZM30.5 28.9062C27.2433 28.9062 24.5938 26.2567 24.5938 23C24.5938 19.7433 27.2433 17.0938 30.5 17.0938C33.7567 17.0938 36.4062 19.7433 36.4062 23C36.4062 26.2567 33.7567 28.9062 30.5 28.9062ZM31.0469 22.4526H33.5078V23.5463H31.0469V26.0073H29.9531V23.5463H27.4922V22.4526H29.9531V19.9917H31.0469V22.4526Z"
        fill="#3C52EF"
      />
      <defs>
        <clipPath id="clip0">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgPlusMen
