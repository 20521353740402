import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { rangeArray } from 'utils/arrayUtils'
import { Skeleton } from '@material-ui/lab'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  tag: {
    borderRadius: 6,
    marginRight: 10
  }
}))

const TagsSkeleton = ({ numberOfTags = 5 }) => {
  const classes = useStyles()

  return (
    <Grid container alignItems="center">
      {rangeArray(numberOfTags).map(i => (
        <Grid item key={`tag-skeleton-${i}`}>
          <Skeleton width={50} height={37} className={classes.tag} />
        </Grid>
      ))}
      <Grid item>
        <Skeleton variant="circle" width={24} height={24} style={{ marginTop: 4 }} />
      </Grid>
    </Grid>
  )
}

export default TagsSkeleton
