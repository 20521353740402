import Actions from 'actions'
import { API } from 'aws-amplify'
import { ofType } from 'redux-observable'
import i18n from 'resources/locales/i18n'
import { concat, from, of } from 'rxjs'
import { catchError, mergeMap, pluck, switchMap } from 'rxjs/operators'

export const fetchTrackingRequiredGrinScansEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.HI_NOTIFY_PRACTICE),
    pluck('payload'),
    switchMap(({ patientId, reason, note, notifyDSO }) =>
      from(
        API.post('grinServerlessApi', '/treatments/v2/gi/notifyPractice', {
          body: {
            patientId,
            reason,
            note,
            notifyDSO
          }
        })
      ).pipe(
        mergeMap(() =>
          concat(
            of(Actions.hiNotifyPracticeReceived()),
            of(
              Actions.showSnackbar({
                text: i18n.t('messages.notifyPractice.submittedSuccessfully'),
                type: 'success'
              })
            )
          )
        ),
        catchError(
          error => concat(of(Actions.hiNotifyPracticeFailed(error))),
          of(
            Actions.showSnackbar({
              text: i18n.t('messages.notifyPractice.failedToNotify'),
              type: 'error'
            })
          )
        )
      )
    )
  )
