import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { CardMedia } from '@material-ui/core'
import PlayWithBackground from 'components/common/icons/PlayWithBackground'
import PoseCheckbox from './PoseCheckbox'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    position: 'relative',
    padding: 2,
    margin: 1,
    '&.clickable': {
      cursor: 'pointer'
    },
    '&.selected': {
      border: '2px solid var(--border-color-1)',
      borderRadius: '5px'
    },
    '&.unselected': {
      opacity: 0.75
    }
  },
  tileOption: {
    borderRadius: 5,
    objectFit: 'contain'
  },
  videoOverlay: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%'
  },
  checkboxContainer: {
    position: 'absolute',
    top: 9,
    right: 9,
    zIndex: 999
  }
}))

const ScanViewTileItem = ({
  src,
  mediaType,
  withOverlay = true,
  mediaProps = {},
  onClick,
  isSelected = false,
  optionMediaClassName = '',
  selectedOptionClassName = '',
  unselectedOptionClasName = '',
  withCheckbox = false,
  isChecked = false,
  setIsChecked
}) => {
  const classes = useStyles()

  return (
    <div
      className={[
        classes.root,
        onClick ? 'clickable' : '',
        isSelected ? `selected ${selectedOptionClassName}` : `unselected ${unselectedOptionClasName}`
      ].join(' ')}
      onClick={onClick}
    >
      {withOverlay && mediaType === 'video' && (
        <div className={classes.videoOverlay}>
          <PlayWithBackground />
        </div>
      )}
      {withCheckbox && (
        <div className={classes.checkboxContainer}>
          <PoseCheckbox isChecked={isChecked} setIsChecked={setIsChecked} />
        </div>
      )}
      <CardMedia
        component={mediaType}
        src={src}
        className={[classes.tileOption, optionMediaClassName].join(' ')}
        {...mediaProps}
      />
    </div>
  )
}

export default ScanViewTileItem
