import { TagFilterTypes } from 'consts/filterConsts'
import useQueryParams from 'hooks/useQueryParams'
import { useCallback, useMemo } from 'react'

export default () => {
  const { queryParams, setQueryParams } = useQueryParams()

  const appliedFilters = useMemo(() => {
    return Object.keys(queryParams)
      .filter(filterType => TagFilterTypes.includes(filterType))
      .reduce(
        (final, key) => ({
          ...final,
          [key]: [].concat(queryParams[key])
        }),
        {}
      )
  }, [ //eslint-disable-line
    queryParams.custom,
    queryParams.status,
    queryParams.program,
    queryParams.treatment,
    queryParams.scan,
    queryParams.assignee,
    queryParams.chat
  ])

  const applyFilters = useCallback(
    filters => {
      const updatedQueryParams = Object.keys({ ...queryParams, ...filters }).reduce(
        (final, param) => ({
          ...final,
          [param]: TagFilterTypes.includes(param) ? filters[param] : queryParams[param]
        }),
        {}
      )
      setQueryParams(updatedQueryParams)
    },
    [setQueryParams, queryParams]
  )

  return {
    appliedFilters,
    applyFilters
  }
}
