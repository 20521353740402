import React from 'react'
import { useTranslation } from 'react-i18next'
import useRolePermissions from 'hooks/useRolePermissions'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase, Grid } from '@material-ui/core'
import AddMember from 'components/common/icons/AddMember'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  buttonText: {
    color: 'var(--text-color-3)',
    whiteSpace: 'nowrap',
    lineHeight: '22px '
  },
  wrapper: {
    borderBottom: '1px solid transparent',
    '&:hover': {
      borderBottom: '1px solid var(--bg-color-7)'
    }
  }
}))

const AddPracticeMemberButton = ({ onClick = () => {} }) => {
  const classes = useStyles()
  const { permissions } = useRolePermissions()

  const { t } = useTranslation()

  return (
    permissions.editPracticeMembers && (
      <ButtonBase onClick={onClick} className={classes.wrapper}>
        <Grid container alignItems="center" wrap="nowrap" spacing={1}>
          <Grid item>
            <AddMember />
          </Grid>
          <Grid item>
            <DazzedParagraph14 className={classes.buttonText}>
              {t('pages.accountSettings.practiceMembers.addPracticeMemberBtn')}
            </DazzedParagraph14>
          </Grid>
        </Grid>
      </ButtonBase>
    )
  )
}

export default AddPracticeMemberButton
