import * as React from 'react'
const MobileInvitePatient = ({ ...props }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 10.1673C1 9.45913 1.42201 8.81914 2.07287 8.5402V8.5402C5.85781 6.91808 10.1422 6.91808 13.9271 8.5402V8.5402C14.578 8.81914 15 9.45913 15 10.1673V14H1V10.1673Z"
        stroke="#001335"
      />
      <circle cx="8" cy="4" r="3" stroke="#001335" />
      <path d="M16 10H14V13H11V15H14V18H16V15H19V13H16V10Z" fill="#001335" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8H18V11H21V17H18V20H12V17H9V11H12V8ZM14 10H16V13H19V15H16V18H14V15H11V13H14V10Z"
        fill="white"
      />
    </svg>
  )
}

export default MobileInvitePatient
