import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DazzedParagraph16 from '../common/text/DazzedParagraph16'
import { getRenewDate } from '../../utils/billingUtils'
import { BILLING_PERIOD_MONTHLY, BILLING_PERIOD_ANNUALLY } from '../../consts/grinPlanConsts'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  scopesSegment: {
    marginTop: 15
  },
  message: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px'
  },
  strong: {
    fontWeight: 600
  }
}))

const BillingUpgradePlanSuccessMessage = ({ hasScopes, planPeriod, planName }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <div className={classes.row}>
        {planPeriod === BILLING_PERIOD_MONTHLY && (
          <DazzedParagraph16 className={classes.message}>
            {t('dialogs.upgradePlan.paymentCompleteMessageDescription1')}&nbsp;
          </DazzedParagraph16>
        )}

        {planPeriod === BILLING_PERIOD_ANNUALLY && (
          <DazzedParagraph16 className={classes.message}>
            {t('dialogs.upgradePlan.paymentCompleteMessageDescription2')}&nbsp;
          </DazzedParagraph16>
        )}

        <DazzedParagraph16 className={[classes.message, classes.strong].join(' ')}>{planName} Plan.</DazzedParagraph16>
      </div>
      <div className={classes.row}>
        <DazzedParagraph16 className={classes.message}>
          {t('dialogs.upgradePlan.paymentCompleteMessageDescription3')}&nbsp;
        </DazzedParagraph16>
        <DazzedParagraph16 className={classes.strong}>{getRenewDate(planPeriod)}.</DazzedParagraph16>
      </div>

      {hasScopes && (
        <div className={classes.scopesSegment}>
          <DazzedParagraph16 textAlign="center" className={classes.message}>
            {t('dialogs.upgradePlan.paymentCompleteMessageDescription4')}
          </DazzedParagraph16>
        </div>
      )}
    </>
  )
}

export default BillingUpgradePlanSuccessMessage
