import React from 'react'
import { useTranslation } from 'react-i18next'
import images from 'resources'
import { GRIN_SCOPE_TYPE_REGULAR, GRIN_SCOPE_TYPE_MINI } from 'consts/billingConsts'

import OrderGrinKitInfo from './OrderGrinKitInfo'

const ScopeDescriptionPopup = ({ type }) => {
  const { t } = useTranslation()

  const scope = [
    {
      title: t('dialogs.orderScopes.grinScope'),
      subTitle: `${t('dialogs.orderScopes.grinScopeDescription')} ${t(
        'dialogs.orderScopes.grinScopeDescriptionWidth'
      )}`,
      scopeImage: images.grinScopeInfo,
      arrowImage: images.grinScopeWidthArrowMobile,
      width: `71.1 ${t('dialogs.orderScopes.grinScopeWidthUnit')}`,
      description: t('dialogs.orderScopes.grinScopeDescription2'),
      type: GRIN_SCOPE_TYPE_REGULAR
    },
    {
      title: t('dialogs.orderScopes.grinScopeMini'),
      subTitle: `${t('dialogs.orderScopes.grinScopeMiniDescription')} ${t(
        'dialogs.orderScopes.grinScopeMiniDescriptionWidth'
      )}`,
      scopeImage: images.grinScopeMiniInfo,
      arrowImage: images.grinScopeMiniWidthArrowMobile,
      width: `60 ${t('dialogs.orderScopes.grinScopeWidthUnit')}`,
      description: t('dialogs.orderScopes.grinScopeMiniDescription2'),
      type: GRIN_SCOPE_TYPE_MINI
    }
  ].find(scope => scope.type === type)

  return (
    <div>
      <OrderGrinKitInfo {...scope} />
    </div>
  )
}

export default ScopeDescriptionPopup
