import * as React from 'react'

const AssignPatient = ({ color = '#AFAFAF', width = 12, height = 10, ...props }) => {
  return (
    <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 13.1822C1 12.2224 1.57102 11.3528 2.47911 11.0419C3.72493 10.6153 5.53336 10.1001 7 10.1001C8.46664 10.1001 10.2751 10.6153 11.5209 11.0419C12.429 11.3528 13 12.2224 13 13.1822V16.4001C13 17.0628 12.4627 17.6001 11.8 17.6001H2.2C1.53726 17.6001 1 17.0628 1 16.4001V13.1822Z"
        fill="#041333"
      />
      <circle cx="6.9999" cy="5.6" r="3.6" fill="#041333" />
      <rect
        x="15.1467"
        y="8.6687"
        width="10.0959"
        height="15.9508"
        rx="2"
        transform="rotate(45 15.1467 8.6687)"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1991 17.0016C11.3657 17.1682 11.3657 17.4383 11.1991 17.6049L8.78601 20.0179C8.28624 20.5177 8.28624 21.328 8.78601 21.8278L9.38928 22.431C9.88905 22.9308 10.6993 22.9308 11.1991 22.431L13.6122 20.0179C13.7788 19.8514 14.0489 19.8514 14.2155 20.0179C14.382 20.1845 14.382 20.4546 14.2155 20.6212L11.8024 23.0343C10.9694 23.8673 9.61895 23.8673 8.78601 23.0343L8.18274 22.431C7.34979 21.5981 7.34979 20.2476 8.18274 19.4147L10.5958 17.0016C10.7624 16.835 11.0325 16.835 11.1991 17.0016Z"
        fill="#041333"
        stroke="#041333"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1725 19.0612C15.0059 18.8946 15.0059 18.6245 15.1725 18.4579L17.5856 16.0448C18.0853 15.545 18.0853 14.7347 17.5856 14.235L16.9823 13.6317C16.4825 13.1319 15.6723 13.1319 15.1725 13.6317L12.7594 16.0448C12.5928 16.2114 12.3227 16.2114 12.1561 16.0448C11.9895 15.8782 11.9895 15.6081 12.1561 15.4415L14.5692 13.0284C15.4022 12.1955 16.7526 12.1955 17.5856 13.0284L18.1888 13.6317C19.0218 14.4647 19.0218 15.8151 18.1888 16.6481L15.7758 19.0612C15.6092 19.2277 15.3391 19.2277 15.1725 19.0612Z"
        fill="#041333"
        stroke="#041333"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1725 16.0448C15.3391 16.2114 15.3391 16.4815 15.1725 16.6481L11.8027 20.0178C11.6361 20.1844 11.3661 20.1844 11.1995 20.0178C11.0329 19.8512 11.0329 19.5811 11.1995 19.4145L14.5692 16.0448C14.7358 15.8782 15.0059 15.8782 15.1725 16.0448Z"
        fill="#041333"
        stroke="#041333"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AssignPatient
