import * as React from 'react'

const Tracking = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 4 }}
  >
    <rect x="1" y="1" width="18" height="18" rx="4" fill="#D2FFD6" stroke="#C0F7C5" strokeWidth="2" />
    <path d="M5 9.85711L8.14286 13L15 6.14282" stroke="#5CB664" strokeWidth="2" />
  </svg>
)

export default Tracking
