import { Grid, makeStyles } from '@material-ui/core'
import SyncIcon from '@material-ui/icons/Sync'
import { SPECIAL_PROVIDERS, TreatmentSTLsStatuses } from 'components/Patients/TreatmentTracker/txTrackerConsts'
import SelectInput from 'components/common/SelectInput'
import Tooltip from 'components/common/Tooltip'
import { QuestionMarkIcon } from 'components/common/icons'
import { CONTENT_HEIGHT } from 'consts/patientCard'
import useUploadSTLs from 'hooks/useUploadSTLs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DriveUploaderDropzone from './DriveUploaderDropzone'
import InvalidFilesModal from './InvalidFilesModal'
import PreviouslyUploadedStls from './PreviouslyUploadedStls'
import UploadCompletedModal from './UploadCompletedModal'
import actions from 'actions'

const useStyles = makeStyles({
  container: {
    height: CONTENT_HEIGHT,
    padding: '30px 50px 10px 5%',
    overflowY: 'auto'
  },
  recommendationContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  recommendation: {
    background: '#ECEFFF',
    borderRadius: 10,
    height: '100%',
    width: '95%',
    marginRight: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  uploadedSTLsTitleContainer: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between'
  },
  requestInProgress: {
    color: '#FFA34D',
    fontWeight: 'bold',
    fontSize: 13,
    display: 'flex'
  }
})

const ManageSTLs = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isUploaderComponentLoading, setIsUploaderComponentLoading] = useState(false)

  const {
    id: treatmentId,
    patientId,
    type: treatmentType,
    txTrackerStls
  } = useSelector(state => state.treatmentReducer.treatment)
  const { status: requestSTLsStatus } = useSelector(state => state.txTrackerReducer.requestSTLs)

  const recommendationText = useMemo(() => t('dialogs.patientInfo.txTrackerSetup.recommendationMoreThan5'), [t])

  const {
    handleRequestProviderSTLs,
    invalidFiles,
    setInvalidFiles,
    provider,
    setProvider,
    providerOptions,
    isUploadCompletedOpen,
    setIsUploadCompletedOpen,
    treatmentStlsStatus,
    stls,
    DROPZONE_CONTAINER_ID
  } = useUploadSTLs({
    setIsUploaderComponentLoading,
    patientId,
    treatmentId,
    treatmentType,
    txTrackerStls
  })

  const handleChangeProvider = useCallback(
    ({ value }) => {
      !SPECIAL_PROVIDERS.includes(value) && setIsUploaderComponentLoading(true)
      setProvider(value)
    },
    [setProvider]
  )

  useEffect(() => () => dispatch(actions.clearProviderStlsRequestStatus()), [dispatch])

  return (
    <div className={classes.container}>
      <InvalidFilesModal isOpen={!!invalidFiles} invalidFiles={invalidFiles} onClose={() => setInvalidFiles(null)} />
      <UploadCompletedModal isOpen={isUploadCompletedOpen} onClose={() => setIsUploadCompletedOpen(false)} />
      <Grid container direction="column">
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <SelectInput
              selectStyle={{ fontSize: 14, fontWeight: 500, marginTop: -2 }}
              showArrow={true}
              dropdownClassName={classes.dropdown}
              style={{ bright: true, thick: true }}
              value={provider}
              options={providerOptions}
              keepErrorContainerWhenInactive={false}
              placeholder={t('dialogs.patientInfo.txTrackerSetup.selectProviderPlaceholder')}
              onChange={handleChangeProvider}
            />
          </Grid>
          <Grid item xs={6} className={classes.recommendationContainer}>
            <div className={classes.recommendation}>{recommendationText}</div>
            <Tooltip
              placement={'right'}
              value={t('dialogs.patientInfo.txTrackerSetup.recommendationTooltip')}
              variant="dark"
            >
              <div>
                <QuestionMarkIcon fontSize="inherit" />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={4} className={classes.uploadedSTLsTitleContainer}>
            <div>{t('dialogs.patientInfo.txTrackerSetup.uploadedSTLs')}</div>
            {treatmentStlsStatus === TreatmentSTLsStatuses.PendingFilesTransfer && (
              <div className={classes.requestInProgress}>
                <SyncIcon style={{ fontSize: 16, marginRight: 4 }} />
                {t('dialogs.patientInfo.txTrackerSetup.requestInProgress')}
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ height: 300 }}>
          <Grid item xs={8} style={{ height: '100%' }}>
            <DriveUploaderDropzone
              dropzoneContainerId={DROPZONE_CONTAINER_ID}
              isUploaderComponentLoading={isUploaderComponentLoading}
              provider={provider}
              providerOptions={providerOptions}
              onRequestProviderSTLs={handleRequestProviderSTLs}
              requestSTLsStatus={requestSTLsStatus}
            />
          </Grid>
          <Grid item xs={4} style={{ height: '100%' }}>
            <PreviouslyUploadedStls stls={stls} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default ManageSTLs
