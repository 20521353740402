import * as React from 'react'

const AroundTeeth = props => (
  <svg width="79" height="57" viewBox="0 0 79 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="78.5339" height="56.0956" rx="5.60956" fill="#BE5C5C" />
    <path
      d="M8.34491 40.7017C8.11536 44.4149 10.7998 47.5998 14.452 48.3079C16.5234 48.7094 18.8004 49.0076 20.6872 48.9021C27.0829 48.5443 32.9661 50.9906 39.1186 50.9906C45.2711 50.9906 51.1543 48.5443 57.5501 48.9021C59.4368 49.0076 61.7138 48.7094 63.7852 48.3079C67.4375 47.5998 70.1219 44.4149 69.8923 40.7017C69.8572 40.1336 69.8008 39.5848 69.7159 39.0859C69.0169 34.9788 69.7159 33.4468 69.7159 33.4468H8.52136C8.52136 33.4468 9.22035 34.9788 8.52136 39.0859C8.43646 39.5848 8.38002 40.1336 8.34491 40.7017Z"
      fill="#F7A2A2"
    />
    <path
      d="M67.1906 33.1831L66.99 29.9461C67.3291 29.8396 67.8743 29.6839 68.4757 29.572C69.0136 29.472 69.5482 29.4156 69.9293 29.385C69.8662 30.4417 69.638 31.8991 69.3283 33.1209C69.162 33.7767 68.9782 34.3403 68.7948 34.73C68.702 34.9271 68.621 35.0541 68.5597 35.1241C68.5596 35.1242 68.5596 35.1243 68.5595 35.1244C68.5203 35.1088 68.4626 35.0779 68.3854 35.0177C68.2513 34.9132 68.1045 34.7561 67.9549 34.5631C67.6568 34.1783 67.4007 33.72 67.2613 33.4524C67.2215 33.3759 67.1969 33.2855 67.1906 33.1831ZM68.518 35.1648C68.518 35.1647 68.5194 35.1636 68.5222 35.1619C68.5194 35.1641 68.518 35.1649 68.518 35.1648Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M11.0468 33.1831L11.2474 29.9461C10.9082 29.8396 10.3631 29.6839 9.76169 29.572C9.2238 29.472 8.68916 29.4156 8.30805 29.385C8.37121 30.4417 8.59935 31.8991 8.90911 33.1209C9.07536 33.7767 9.25912 34.3403 9.4426 34.73C9.53539 34.9271 9.61635 35.0541 9.67763 35.1241C9.67772 35.1242 9.67781 35.1243 9.6779 35.1244C9.71708 35.1088 9.77473 35.0779 9.85199 35.0177C9.98604 34.9132 10.1329 34.7561 10.2824 34.5631C10.5805 34.1783 10.8367 33.72 10.976 33.4524C11.0159 33.3759 11.0405 33.2855 11.0468 33.1831ZM9.71935 35.1648C9.71935 35.1647 9.71797 35.1636 9.7152 35.1619C9.71797 35.1641 9.71935 35.1649 9.71935 35.1648Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M64.1867 34.0432L63.9729 30.5915C64.3362 30.4766 64.938 30.3029 65.604 30.179C66.1947 30.0691 66.7815 30.0082 67.1938 29.9755C67.133 30.8629 67.0167 32.4166 66.7991 33.7834C66.6818 34.5199 66.5393 35.174 66.3708 35.6337C66.2856 35.8662 66.2051 36.0168 66.1396 36.1003C66.1301 36.1126 66.122 36.122 66.1154 36.1291C66.0158 36.1183 65.8895 36.0752 65.7355 35.9825C65.5567 35.875 65.3689 35.7177 65.1822 35.5286C64.8085 35.1503 64.487 34.6943 64.3 34.4069C64.2352 34.3072 64.1954 34.1833 64.1867 34.0432ZM66.0936 36.1493C66.0936 36.1492 66.0947 36.1483 66.0967 36.1471C66.0947 36.1487 66.0936 36.1494 66.0936 36.1493Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M14.0502 34.0432L14.2641 30.5915C13.9007 30.4766 13.2989 30.3029 12.633 30.179C12.0423 30.0691 11.4554 30.0082 11.0431 29.9755C11.1039 30.8629 11.2202 32.4166 11.4378 33.7834C11.5551 34.5199 11.6976 35.174 11.8661 35.6337C11.9513 35.8662 12.0319 36.0168 12.0973 36.1003C12.1069 36.1126 12.115 36.122 12.1216 36.1291C12.2212 36.1183 12.3474 36.0752 12.5014 35.9825C12.6802 35.875 12.868 35.7177 13.0547 35.5286C13.4285 35.1503 13.7499 34.6943 13.9369 34.4069C14.0018 34.3072 14.0415 34.1833 14.0502 34.0432ZM12.1433 36.1493C12.1433 36.1492 12.1423 36.1483 12.1402 36.1471C12.1423 36.1487 12.1433 36.1494 12.1433 36.1493Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M59.9455 34.5091L59.6959 30.886C60.1788 30.769 60.9704 30.6051 61.5832 30.6051C62.2315 30.6051 63.1172 30.6378 63.6649 30.6611L63.5434 34.314C63.531 34.6868 63.4978 35.0383 63.417 35.3711C63.3138 35.7963 63.1459 36.3616 62.9116 36.81C62.7942 37.0345 62.6736 37.2033 62.5575 37.3105C62.4456 37.4139 62.3645 37.4372 62.3058 37.4372C62.2203 37.4372 62.0659 37.389 61.8387 37.2175C61.6208 37.0531 61.3881 36.8147 61.1567 36.5349C60.695 35.9768 60.2903 35.3186 60.0726 34.9422C59.9997 34.8162 59.9565 34.6694 59.9455 34.5091Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M18.2916 34.5091L18.5412 30.886C18.0583 30.769 17.2666 30.6051 16.6539 30.6051C16.0055 30.6051 15.1199 30.6378 14.5721 30.6611L14.6937 34.314C14.7061 34.6868 14.7393 35.0383 14.8201 35.3711C14.9233 35.7963 15.0911 36.3616 15.3255 36.81C15.4428 37.0345 15.5635 37.2033 15.6795 37.3105C15.7915 37.4139 15.8725 37.4372 15.9313 37.4372C16.0167 37.4372 16.1712 37.389 16.3984 37.2175C16.6163 37.0531 16.8489 36.8147 17.0804 36.5349C17.5421 35.9768 17.9467 35.3186 18.1645 34.9422C18.2374 34.8162 18.2806 34.6694 18.2916 34.5091Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M54.4785 31.0694C55.0351 31.0073 55.9625 30.9185 56.6976 30.9185C57.4594 30.9185 58.5353 31.0641 59.1411 31.157L58.9834 35.5158C58.9797 35.6169 58.9751 35.7069 58.966 35.7945C58.9348 36.0955 58.8435 36.7779 58.6268 37.3725C58.518 37.6712 58.3897 37.9127 58.2487 38.0717C58.1153 38.2222 57.9968 38.2727 57.8775 38.2727C57.7426 38.2727 57.5374 38.2098 57.2616 38.0412C56.9943 37.8779 56.7061 37.6435 56.4168 37.3704C55.8389 36.825 55.305 36.1718 54.987 35.7594L54.5911 36.0647L54.987 35.7594C54.8518 35.584 54.772 35.3652 54.7558 35.1269L54.4785 31.0694Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M23.7586 31.0694C23.2021 31.0073 22.2746 30.9185 21.5396 30.9185C20.7778 30.9185 19.7018 31.0641 19.096 31.157L19.2537 35.5158C19.2574 35.6169 19.2621 35.7069 19.2711 35.7945C19.3023 36.0955 19.3936 36.7779 19.6103 37.3725C19.7192 37.6712 19.8474 37.9127 19.9884 38.0717C20.1218 38.2222 20.2403 38.2727 20.3596 38.2727C20.4945 38.2727 20.6997 38.2098 20.9755 38.0412C21.2428 37.8779 21.531 37.6435 21.8203 37.3704C22.3982 36.825 22.9321 36.1718 23.2501 35.7594L23.646 36.0647L23.2501 35.7594C23.3854 35.584 23.4651 35.3652 23.4814 35.1269L23.7586 31.0694Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M47.6946 31.1155C47.6943 31.1117 47.6948 31.1092 47.6953 31.1075C47.6959 31.1056 47.6968 31.1039 47.6978 31.1026C47.6988 31.1013 47.6997 31.1006 47.7001 31.1004L47.7001 31.1004C47.7003 31.1003 47.701 31.0999 47.703 31.0996C48.3998 30.9918 49.6927 30.8141 50.6054 30.8141C51.5266 30.8141 52.8348 30.9951 53.5263 31.1024C53.5283 31.1027 53.529 31.1032 53.5293 31.1033C53.5298 31.1036 53.5307 31.1043 53.5318 31.1056C53.5338 31.1081 53.5356 31.1118 53.5354 31.118L53.3787 35.4486C53.3557 36.0855 53.2879 36.6886 53.1017 37.2469C52.9362 37.7431 52.6984 38.3206 52.3924 38.7643C52.0749 39.2248 51.7702 39.4214 51.493 39.4214C51.0756 39.4214 50.5316 39.1765 49.9493 38.7732C49.3859 38.383 48.8643 37.8999 48.5078 37.5417C48.2859 37.3189 48.1531 37.0108 48.1265 36.6686L47.6946 31.1155Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M30.5424 31.1155C30.5427 31.1117 30.5422 31.1092 30.5417 31.1075C30.5411 31.1056 30.5402 31.1039 30.5391 31.1026C30.5381 31.1013 30.5372 31.1006 30.5368 31.1004L30.5368 31.1004C30.5366 31.1003 30.536 31.0999 30.5339 31.0996C29.8372 30.9918 28.5442 30.8141 27.6315 30.8141C26.7103 30.8141 25.4021 30.9951 24.7106 31.1024C24.7086 31.1027 24.7079 31.1032 24.7076 31.1033C24.7071 31.1036 24.7062 31.1043 24.7051 31.1056C24.7031 31.1081 24.7013 31.1118 24.7015 31.118L24.8582 35.4486C24.8813 36.0855 24.9491 36.6886 25.1353 37.2469C25.3007 37.7431 25.5385 38.3206 25.8445 38.7643C26.1621 39.2248 26.4667 39.4214 26.7439 39.4214C27.1614 39.4214 27.7053 39.1765 28.2876 38.7732C28.851 38.383 29.3726 37.8999 29.7292 37.5417C29.951 37.3189 30.0838 37.0108 30.1104 36.6686L30.5424 31.1155Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M39.6187 31.1825C39.6187 31.1761 39.6207 31.1721 39.6229 31.1695C39.6241 31.1681 39.6252 31.1673 39.626 31.1669C39.6265 31.1666 39.6275 31.1662 39.6298 31.1659C40.4173 31.0615 42.0479 30.8663 43.1913 30.8663C44.3347 30.8663 45.9654 31.0615 46.7529 31.1659C46.7552 31.1662 46.7562 31.1666 46.7567 31.1669C46.7574 31.1673 46.7585 31.1681 46.7597 31.1695C46.762 31.1721 46.764 31.1761 46.764 31.1825V34.1124C46.764 35.6241 46.5026 37.0853 45.6951 38.2447C45.3418 38.7519 44.932 39.2408 44.49 39.5984C44.0456 39.9579 43.6093 40.1524 43.1913 40.1524C42.7733 40.1524 42.3371 39.9579 41.8927 39.5984C41.4506 39.2408 41.0409 38.7519 40.6876 38.2447C39.88 37.0853 39.6187 35.6241 39.6187 34.1124V31.1825Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M38.6187 31.1825C38.6187 31.1761 38.6166 31.1721 38.6144 31.1695C38.6132 31.1681 38.6121 31.1673 38.6113 31.1669C38.6108 31.1666 38.6098 31.1662 38.6075 31.1659C37.82 31.0615 36.1894 30.8663 35.046 30.8663C33.9026 30.8663 32.2719 31.0615 31.4844 31.1659C31.4822 31.1662 31.4811 31.1666 31.4806 31.1669C31.4799 31.1673 31.4788 31.1681 31.4776 31.1695C31.4753 31.1721 31.4733 31.1761 31.4733 31.1825V34.1124C31.4733 35.6241 31.7347 37.0853 32.5422 38.2447C32.8955 38.7519 33.3053 39.2408 33.7473 39.5984C34.1917 39.9579 34.628 40.1524 35.046 40.1524C35.464 40.1524 35.9002 39.9579 36.3446 39.5984C36.7867 39.2408 37.1964 38.7519 37.5497 38.2447C38.3573 37.0853 38.6187 35.6241 38.6187 34.1124V31.1825Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M4.37652 13.653C4.14698 9.93988 6.83137 6.75491 10.4836 6.04688C12.555 5.64532 14.832 5.34716 16.7188 5.45269C21.2926 5.70849 23.4421 8.58551 28.0231 8.58551C32.604 8.58551 34.7463 5.45269 39.3273 5.45269C43.9083 5.45269 46.0506 8.58551 50.6316 8.58551C55.2126 8.58551 57.362 5.70849 61.9359 5.45269C63.8226 5.34716 66.0997 5.64532 68.171 6.04688C71.8233 6.75491 74.5077 9.93988 74.2781 13.653C74.243 14.2211 74.1866 14.77 74.1017 15.2689C73.4027 19.3759 69.9246 25.0851 69.9246 25.0851H8.73008C8.73008 25.0851 5.25197 19.3759 4.55298 15.2689C4.46807 14.77 4.41164 14.2211 4.37652 13.653Z"
      fill="#F7A2A2"
    />
    <path
      d="M68.9847 21.9911C68.9847 21.62 69.048 21.251 69.1998 20.9124C69.5035 20.2348 70.0837 19.1326 70.6442 19.1326C71.3319 19.1326 71.5377 20.7158 71.584 21.1918C71.5927 21.2814 71.5954 21.3706 71.5954 21.4606V24.3304C71.5954 24.9707 71.5364 25.6134 71.326 26.2182C71.0775 26.9325 70.6692 27.8001 70.1516 27.8001C69.4145 27.8001 69.0934 26.4803 69.0087 26.0525C68.9912 25.9645 68.9847 25.8753 68.9847 25.7856V21.9911Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M9.66998 21.9911C9.66998 21.62 9.6067 21.251 9.45492 20.9124C9.15124 20.2348 8.57106 19.1326 8.01049 19.1326C7.32285 19.1326 7.11704 20.7158 7.0707 21.1918C7.06198 21.2814 7.0593 21.3706 7.0593 21.4606V24.3304C7.0593 24.9707 7.11833 25.6134 7.32871 26.2182C7.57718 26.9325 7.98553 27.8001 8.50312 27.8001C9.24025 27.8001 9.56134 26.4803 9.64606 26.0525C9.66349 25.9645 9.66998 25.8753 9.66998 25.7856V21.9911Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M66.4785 22.227C66.4785 21.7041 66.6062 21.1884 66.8808 20.7434C67.3769 19.939 68.2212 18.715 68.7646 18.715C69.4723 18.715 69.6696 20.7115 69.708 21.2126C69.7138 21.288 69.7158 21.3635 69.7158 21.4391V24.4655C69.7158 25.0165 69.6689 25.568 69.5241 26.0997C69.2884 26.9652 68.8477 28.2179 68.2719 28.2179C67.7431 28.2179 67.2019 27.765 66.8544 27.4037C66.5937 27.1328 66.4785 26.7622 66.4785 26.3863V22.227Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M12.1763 22.227C12.1763 21.7041 12.0486 21.1884 11.774 20.7434C11.2779 19.939 10.4336 18.715 9.89022 18.715C9.18249 18.715 8.98515 20.7115 8.94676 21.2126C8.94099 21.288 8.93902 21.3635 8.93902 21.4391V24.4655C8.93902 25.0165 8.98593 25.568 9.13073 26.0997C9.36643 26.9652 9.80704 28.2179 10.3828 28.2179C10.9117 28.2179 11.4529 27.765 11.8004 27.4037C12.0611 27.1328 12.1763 26.7622 12.1763 26.3863V22.227Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M62.197 21.8477C62.197 21.1699 62.4122 20.5069 62.8586 19.9968C63.5589 19.1964 64.6671 18.0883 65.4343 18.0883C66.3884 18.0883 66.8152 19.7363 66.9503 20.4136C66.9882 20.6036 67.0007 20.7962 67.0007 20.99V25.6187C67.0007 25.7495 66.9825 25.878 66.9415 26.0022C66.7099 26.7052 65.8215 29.1576 64.7033 29.1576C63.7199 29.1576 62.7957 27.7944 62.3997 27.122C62.2599 26.8846 62.197 26.6123 62.197 26.3367V21.8477Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M16.4576 21.8477C16.4576 21.1699 16.2425 20.5069 15.7961 19.9968C15.0957 19.1964 13.9876 18.0883 13.2204 18.0883C12.2662 18.0883 11.8395 19.7363 11.7043 20.4136C11.6664 20.6036 11.654 20.7962 11.654 20.99V25.6187C11.654 25.7495 11.6722 25.878 11.7131 26.0022C11.9448 26.7052 12.8331 29.1576 13.9514 29.1576C14.9348 29.1576 15.8589 27.7944 16.2549 27.122C16.3948 26.8846 16.4576 26.6123 16.4576 26.3367V21.8477Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M57.1844 22.0841C57.1844 21.2634 57.5029 20.4715 58.1122 19.9215C59.0285 19.0944 60.3991 17.984 61.1527 17.984C61.8132 17.984 62.3158 18.4895 62.6343 18.9444C62.9435 19.3858 63.0324 19.932 63.0324 20.4709V25.8655C63.0324 26.1067 62.9692 26.3424 62.8414 26.5469C62.2882 27.432 60.6565 29.8887 59.5863 29.8887C58.4941 29.8887 57.5481 27.403 57.2683 26.5913C57.2104 26.4234 57.1844 26.2479 57.1844 26.0703V22.0841Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M21.4703 22.0841C21.4703 21.2634 21.1518 20.4715 20.5426 19.9215C19.6262 19.0944 18.2557 17.984 17.502 17.984C16.8415 17.984 16.3389 18.4895 16.0204 18.9444C15.7112 19.3858 15.6223 19.932 15.6223 20.4709V25.8655C15.6223 26.1067 15.6855 26.3424 15.8134 26.5469C16.3665 27.432 17.9983 29.8887 19.0684 29.8887C20.1607 29.8887 21.1066 27.403 21.3864 26.5913C21.4443 26.4234 21.4703 26.2479 21.4703 26.0703V22.0841Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M49.7701 22.0056C49.7701 21.2861 49.9484 20.5728 50.3774 19.9951C51.0884 19.0378 52.3472 17.6707 53.7384 17.6707C55.3011 17.6707 56.6969 19.9522 57.3367 21.1843C57.5965 21.6845 57.7066 22.2428 57.7066 22.8064V29.4362C57.7066 30.1582 57.1415 30.7539 56.4204 30.7918L51.6387 31.0435C50.623 31.097 49.7701 30.2878 49.7701 29.2707V22.0056Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M28.8846 22.0056C28.8846 21.2861 28.7064 20.5728 28.2774 19.9951C27.5664 19.0378 26.3075 17.6707 24.9164 17.6707C23.3537 17.6707 21.9579 19.9522 21.3181 21.1843C21.0583 21.6845 20.9482 22.2428 20.9482 22.8064V29.4362C20.9482 30.1582 21.5133 30.7539 22.2344 30.7918L27.0161 31.0435C28.0318 31.097 28.8846 30.2878 28.8846 29.2707V22.0056Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M39.3274 22.7876C39.3274 19.9039 41.6651 17.5662 44.5488 17.5662C47.4325 17.5662 49.7701 19.9039 49.7701 22.7876V29.3665C49.7701 30.347 48.9753 31.1418 47.9949 31.1418H41.1027C40.1222 31.1418 39.3274 30.347 39.3274 29.3665V22.7876Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M39.3274 22.7876C39.3274 19.9039 36.9897 17.5662 34.106 17.5662C31.2223 17.5662 28.8846 19.9039 28.8846 22.7876V29.3665C28.8846 30.347 29.6795 31.1418 30.6599 31.1418H37.5521C38.5326 31.1418 39.3274 30.347 39.3274 29.3665V22.7876Z"
      fill="white"
      stroke="#BE5C5C"
    />
  </svg>
)

export default AroundTeeth
