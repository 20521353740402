import * as React from 'react'

const Cog12x12 = props => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.38 12L4.14 10.08C4.01 10.03 3.8875 9.97 3.7725 9.9C3.6575 9.83 3.545 9.755 3.435 9.675L1.65 10.425L0 7.575L1.545 6.405C1.535 6.335 1.53 6.2675 1.53 6.2025V5.7975C1.53 5.7325 1.535 5.665 1.545 5.595L0 4.425L1.65 1.575L3.435 2.325C3.545 2.245 3.66 2.17 3.78 2.1C3.9 2.03 4.02 1.97 4.14 1.92L4.38 0H7.68L7.92 1.92C8.05 1.97 8.1725 2.03 8.2875 2.1C8.4025 2.17 8.515 2.245 8.625 2.325L10.41 1.575L12.06 4.425L10.515 5.595C10.525 5.665 10.53 5.7325 10.53 5.7975V6.2025C10.53 6.2675 10.52 6.335 10.5 6.405L12.045 7.575L10.395 10.425L8.625 9.675C8.515 9.755 8.4 9.83 8.28 9.9C8.16 9.97 8.04 10.03 7.92 10.08L7.68 12H4.38ZM6.06 8.1C6.64 8.1 7.135 7.895 7.545 7.485C7.955 7.075 8.16 6.58 8.16 6C8.16 5.42 7.955 4.925 7.545 4.515C7.135 4.105 6.64 3.9 6.06 3.9C5.47 3.9 4.9725 4.105 4.5675 4.515C4.1625 4.925 3.96 5.42 3.96 6C3.96 6.58 4.1625 7.075 4.5675 7.485C4.9725 7.895 5.47 8.1 6.06 8.1Z"
        fill="#4151E6"
      />
    </svg>
  )
}

export default Cog12x12
