import Actions from 'actions'
import { FILE_UPLOAD_MAX_SIZE } from 'consts/appConsts'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

export default ({ additionalMapper = () => {} }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const mapFile = useCallback(
    file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve({
            data: reader.result,
            type: file.type,
            originalKey: file.name,
            size: file.size,
            file,
            ...additionalMapper(file)
          })
        }
        reader.readAsDataURL(file)
      }),
    [additionalMapper]
  )

  const validateFilesSize = useCallback(
    async ({ files = [], maxFileSize = FILE_UPLOAD_MAX_SIZE }) => {
      const mappedFiles = await Promise.all([...files].map(mapFile))
      const filteredFilesBySize = mappedFiles.filter(file => file.size <= maxFileSize)
      const numOfExceededSizeFiles = mappedFiles.length - filteredFilesBySize.length

      if (numOfExceededSizeFiles > 0) {
        let message = t('pages.patients.selectedPatient.chat.dropzone.errors.multipleFileSizeExceeded', {
          numOfFiles: numOfExceededSizeFiles
        })

        if (mappedFiles.length === 1) {
          message = t('pages.patients.selectedPatient.chat.dropzone.errors.singleFileSizeExceeded')
        } else if (numOfExceededSizeFiles === 1) {
          message = t('pages.patients.selectedPatient.chat.dropzone.errors.multipleFileUploadSingleFileSizeExceeded')
        }

        dispatch(
          Actions.toggleUploadFileSizeExceeded({
            message
          })
        )
      }

      return filteredFilesBySize
    },
    [dispatch, mapFile, t]
  )

  return {
    validateFilesSize
  }
}
