import * as React from 'react'

const PNG = ({ ...props }) => {
  return (
    <svg width="33" height="41" viewBox="0 0 33 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3234 28.016H8.27544V34H9.02744V31.6H10.3234C11.7634 31.6 12.6194 30.936 12.6194 29.8C12.6194 28.68 11.7634 28.016 10.3234 28.016ZM10.3234 30.936H9.02744V28.68H10.3234C11.3074 28.68 11.8594 29.08 11.8594 29.8C11.8594 30.528 11.3074 30.936 10.3234 30.936ZM17.5453 28.016V32.896L17.2573 32.4L14.6173 28.016H13.6973V34H14.4493V29.12L14.7213 29.592L17.3693 34H18.2893V28.016H17.5453ZM25.0309 31.16H22.8629V31.792H24.2389C24.1269 32.8 23.4389 33.408 22.4069 33.408C21.1509 33.408 20.2469 32.408 20.2469 31C20.2469 29.592 21.1429 28.6 22.3589 28.6C23.2149 28.6 23.8789 29.16 24.0709 29.968L24.8229 29.824C24.5989 28.672 23.5989 27.92 22.3989 27.92C20.7109 27.92 19.4869 29.208 19.4869 31C19.4869 32.8 20.7189 34.096 22.4069 34.096C24.0869 34.096 25.0869 32.952 25.0309 31.16Z"
        fill="#646464"
      />
      <path
        d="M1 4C1 2.34315 2.34315 1 4 1H21.8431C22.904 1 23.9214 1.42143 24.6716 2.17157L30.8284 8.32843C31.5786 9.07857 32 10.096 32 11.1569V37C32 38.6569 30.6569 40 29 40H4C2.34315 40 1 38.6569 1 37V4Z"
        stroke="#646464"
        strokeWidth="0.75"
      />
    </svg>
  )
}

export default PNG
