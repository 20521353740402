import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Tooltip from 'components/common/Tooltip'
import { ButtonBase } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  miniTimelineButtonRoot: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    padding: '7px',
    textAlign: 'center',
    background: '#E1EDF9',
    cursor: 'pointer',
    visibility: ({ disabled }) => (disabled ? 'hidden' : 'visible')
  }
}))

const MiniTimelineButton = ({ icon, onClick = () => {}, tooltip = '', tooltipPlacement, disabled = false }) => {
  const classes = useStyles({ disabled })

  return (
    <Tooltip value={disabled ? '' : tooltip} placement={tooltipPlacement}>
      <ButtonBase className={classes.miniTimelineButtonRoot} onClick={onClick} disabled={disabled}>
        {icon}
      </ButtonBase>
    </Tooltip>
  )
}

export default MiniTimelineButton
