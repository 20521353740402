import React from 'react'
import { makeStyles } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import { Close } from 'components/common/icons'

const useStyles = () =>
  makeStyles({
    contentContainer: {
      position: 'absolute',
      bottom: 0,
      backgroundColor: 'var(--bg-color-10)',
      zIndex: 100,
      paddingBottom: 80,
      width: '100vw',
      '& ul': {
        padding: 0
      }
    },
    closeBtn: {
      position: 'absolute',
      right: 15,
      top: 10,
      zIndex: 15
    }
  })()

const ChatModal = ({ open, onClose, className = '', children, withCloseButton = true }) => {
  const classes = useStyles()
  return (
    <Backdrop open={open} onClick={onClose}>
      <div className={[classes.contentContainer, className].join(' ')} onClick={e => e.stopPropagation()}>
        {withCloseButton && <Close className={classes.closeBtn} color="var(--text-color-14)" onClick={onClose} />}
        {children}
      </div>
    </Backdrop>
  )
}

export default ChatModal
