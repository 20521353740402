import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import ScanStatItem from './ScanStatItem'
import { ScanFeedPageSectionNames } from 'consts/timelineConsts'
import NumberOfReviews from 'components/common/icons/timelineV2/NumberOfReviews'
import NumberOfShares from 'components/common/icons/timelineV2/NumberOfShares'

const ScanStats = ({ denseMode = false, numberOfReviews, numberOfShares, onReviewsClicked, onSharesClicked }) => {
  const { t } = useTranslation()

  const numberOfReviewsLabel = useMemo(
    () =>
      denseMode
        ? numberOfReviews
        : numberOfReviews > 0
        ? t('pages.patients.selectedPatient.timelineV2.scanStats.numberOfReviews', {
            numberOfReviews
          })
        : t('pages.patients.selectedPatient.timelineV2.scanStats.noReviews'),
    [denseMode, numberOfReviews, t]
  )
  const numberOfSharesLabel = useMemo(
    () =>
      denseMode
        ? numberOfShares
        : numberOfShares
        ? t('pages.patients.selectedPatient.timelineV2.scanStats.numberOfShares', { numberOfShares })
        : t('pages.patients.selectedPatient.timelineV2.scanStats.noShares'),
    [denseMode, numberOfShares, t]
  )

  return (
    <Grid container spacing={2}>
      <Grid item>
        <ScanStatItem
          href={`#${ScanFeedPageSectionNames.Reviews}`}
          onClick={onReviewsClicked}
          icon={<NumberOfReviews />}
          label={numberOfReviewsLabel}
        />
      </Grid>
      <Grid item>
        <ScanStatItem
          href={`#${ScanFeedPageSectionNames.Shares}`}
          onClick={onSharesClicked}
          icon={<NumberOfShares />}
          label={numberOfSharesLabel}
        />
      </Grid>
    </Grid>
  )
}

export default ScanStats
