import * as React from 'react'

const MarkReadLarge = () => {
  return (
    <svg width="41" height="26" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3964 9.57938L11.1667 9.72699V9.99997V20.9999C11.1667 21.8283 11.8383 22.4999 12.6667 22.4999H29.3333C30.1617 22.4999 30.8333 21.8283 30.8333 20.9999V9.99997V9.72699L30.6037 9.57938L21.2704 3.57941L21 3.4056L20.7297 3.57941L11.3964 9.57938Z"
        stroke="#4451DE"
      />
      <path d="M11 12.6665L20.9999 17.3331L30.9999 12.6665" stroke="#4451DE" />
      <path
        d="M14.3335 4.99988H27.6668V13.9998L21.0001 16.9998L14.3335 13.9998V4.99988Z"
        fill="white"
        stroke="#4451DE"
      />
    </svg>
  )
}

export default MarkReadLarge
