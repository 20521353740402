import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import Actions from 'actions'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import FormInput from 'components/common/FormInput'
import ScanWithPatientDetails from '../ShareScan/ScanWithPatientDetails'

const useStyles = makeStyles({
  modal: {
    padding: '50px 30px 0px 10px',
    minWidth: 780
  },
  content: {
    paddingRight: '0px !important'
  },
  patientIdInput: {
    width: 550
  }
})

const RegenerateScanModal = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isOpen, grinScanId, isLoading } = useSelector(state => state.qaReducer.regenerateScanModal)
  const { patient } = useSelector(state => state.patientsReducer)

  const [patientId, setPatientId] = useState(null)

  const handleClose = useCallback(() => {
    dispatch(Actions.qaToggleRegenerateScanModal({ isOpen: false }))
  }, [dispatch])

  const handleRegenerateScan = useCallback(
    () =>
      dispatch(
        Actions.qaRegenerateScan({
          patientId,
          grinScanId
        })
      ),
    [dispatch, grinScanId, patientId]
  )

  useEffect(() => {
    if (isOpen && patient && patientId === null) {
      setPatientId(patient.id)
    }
  }, [isOpen, patient, patientId])

  useEffect(() => {
    if (!isOpen) {
      setPatientId(null)
    }
  }, [isOpen])

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleClose}
      className={classes.modal}
      hideScroll
      primaryLabel={t('dialogs.regenerateScan.primaryBtnLabel')}
      contentClassName={classes.content}
      onPrimaryBtnClick={handleRegenerateScan}
      isPrimaryDisabled={!patientId}
      largerPrimaryButton
      buttonsWidth={130}
      onSecondaryBtnClick={handleClose}
      secondaryLabel={t('general.cancel')}
      isLoading={isLoading}
    >
      {isOpen && (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <DazzedHeading20>{t('dialogs.regenerateScan.title')}</DazzedHeading20>
          </Grid>
          <Grid item>
            <FormInput
              title={t('dialogs.regenerateScan.patientIdLabel')}
              style={{ bright: true, thick: true }}
              subtitle={t('dialogs.regenerateScan.patientIdSubtitle')}
              value={patientId}
              setValue={setPatientId}
              containerClassName={classes.patientIdInput}
            />
          </Grid>
          <Grid item>
            <ScanWithPatientDetails grinScanId={grinScanId} />
          </Grid>
        </Grid>
      )}
    </BaseModal>
  )
}

export default RegenerateScanModal
