export const TIME_FORMAT_1 = 'dddd MMMM DD, YYYY'
export const TIME_FORMAT_2 = 'H A'
export const TIME_FORMAT_3 = 'MMMM DD, YYYY'
export const TIME_FORMAT_4 = 'D MMM YYYY'
export const TIME_FORMAT_5 = 'h:mm a'
export const TIME_FORMAT_6 = 'D MMM YYYY h:mm a'
export const TIME_FORMAT_7 = 'll'
export const TIME_FORMAT_8 = 'lll'
export const TIME_FORMAT_9 = 'MMMM Do YYYY'
export const TIME_FORMAT_10 = 'hh:mm a'
export const TIME_FORMAT_11 = 'do MMMM yyyy'
export const TIME_FORMAT_12 = 'YYYY-MM-DD'
export const AWS_DATE_TIME = 'YYYY-MM-DDThh:mm:ss.sssZ'
export const AWS_DATE_TIME_UTC = 'YYYY-MM-DDTHH:mm:ss.sss[Z]'

export const DEFAULT_PERIOD_TYPE = 'day'

export const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
