import Actions from '../actions'

const initialState = {
  loaded: false,
  facebookPixelId: null,
  ga4MeasurementId: null,
  gtmId: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.DOCTOR_PUBLIC_DETAILS_RECEIVED:
      return {
        ...state,
        loaded: true,
        facebookPixelId: action.payload.pixels?.facebookPixelId,
        ga4MeasurementId: action.payload.pixels?.ga4MeasurementId,
        gtmId: action.payload.pixels?.gtmId
      }
    default:
      return state
  }
}
