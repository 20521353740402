import * as React from 'react'

const ServiceAccountActivate = props => (
  <svg width={21} height={21} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={7.667} cy={4.333} r={3.333} stroke="#4151E6" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M1 11.592c0-.675.308-1.315.904-1.631 1.058-.562 3.02-1.342 5.763-1.342 2.742 0 4.704.78 5.762 1.342.596.316.904.956.904 1.63v2.742H1v-2.741Z"
      stroke="#4151E6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={14.333} cy={14.333} r={6.667} fill="#fff" />
    <circle
      cx={14.333}
      cy={14.333}
      r={4}
      fill="#4151E6"
      stroke="#4151E6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="m17 12.5-3.167 3.167L12.167 14" stroke="#fff" strokeWidth={1.5} />
  </svg>
)

export default ServiceAccountActivate
