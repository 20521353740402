import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import LinkButton from 'components/common/buttons/LinkButton'
import { useTranslation } from 'react-i18next'
import { AttachFile } from '@material-ui/icons'
import { trackEvent } from 'utils/analyticsUtils'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import Attachments from './Attachments'

const useStyles = makeStyles(theme => ({
  attachFileLabel: {
    textDecoration: 'underline'
  },
  attachFileIcon: {
    color: 'var(--text-color-25)',
    fontSize: 16
  },
  attachFileIconContainer: {
    paddingRight: '0px !important'
  }
}))

const AttachFiles = ({ files, setFiles }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleAttachFilesClicked = useCallback(() => {
    trackEvent('Share scan modal - Attach files clicked')
    dispatch(Actions.toggleShareScanAttachFilesModal({ isOpen: true }))
  }, [dispatch])

  const handleUnselectFile = useCallback(
    fileKeyToUnselect => {
      trackEvent('Share scan modal - Unselected file from list')
      setFiles(files.filter(currFile => currFile.key !== fileKeyToUnselect))
    },
    [files, setFiles]
  )

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Attachments files={files} onUnselectFile={handleUnselectFile} />
      </Grid>
      <Grid item>
        <LinkButton
          icon={
            <div style={{ marginTop: 4 }}>
              <AttachFile className={classes.attachFileIcon} />
            </div>
          }
          label={t('dialogs.shareScan.attachFiles.buttonLabel')}
          labelClassName={classes.attachFileLabel}
          iconContainerClassName={classes.attachFileIconContainer}
          onClick={handleAttachFilesClicked}
        />
      </Grid>
    </Grid>
  )
}

export default AttachFiles
