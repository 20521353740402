import * as React from 'react'

const Mail = ({ color = 'black', ...props }) => {
  return (
    <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.2278 12.6765H1.77216C1.30229 12.6759 0.851821 12.489 0.519588 12.1567C0.187355 11.8245 0.000493018 11.374 0 10.9041V1.97643C0.000493018 1.50656 0.187355 1.05607 0.519588 0.72381C0.851821 0.391545 1.30229 0.204639 1.77216 0.204102H16.2278C16.6977 0.204639 17.1482 0.391545 17.4804 0.72381C17.8126 1.05607 17.9995 1.50656 18 1.97643V10.9041C17.9995 11.374 17.8126 11.8245 17.4804 12.1567C17.1482 12.489 16.6977 12.6759 16.2278 12.6765ZM1.77216 0.881468C1.48187 0.881782 1.20356 0.99725 0.998311 1.20253C0.79306 1.40781 0.677635 1.68614 0.677366 1.97643V10.9041C0.677635 11.1944 0.79306 11.4727 0.998311 11.678C1.20356 11.8833 1.48187 11.9988 1.77216 11.9991H16.2278C16.5181 11.9988 16.7964 11.8833 17.0017 11.678C17.2069 11.4727 17.3224 11.1944 17.3226 10.9041V1.97643C17.3224 1.68614 17.2069 1.40781 17.0017 1.20253C16.7964 0.99725 16.5181 0.881782 16.2278 0.881468H1.77216Z"
        fill={color}
      />
      <path
        d="M8.99976 8.43334C8.9172 8.43339 8.83747 8.40328 8.77555 8.34867L0.613281 1.14352L1.06153 0.635498L8.99976 7.64353L16.9383 0.636345L17.3866 1.14437L9.2243 8.34952C9.16222 8.40398 9.08234 8.4338 8.99976 8.43334Z"
        fill={color}
      />
      <path d="M0.429688 11.6128L5.91301 5.36513L6.42239 5.81219L0.939067 12.0599L0.429688 11.6128Z" fill={color} />
      <path d="M11.5957 5.80566L12.1051 5.3586L17.5885 11.6064L17.0791 12.0535L11.5957 5.80566Z" fill={color} />
    </svg>
  )
}

export default Mail
