import * as React from 'react'

export default () => {
  return (
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5.5" cy="3.5" r="2.5" fill="#001335" stroke="#001335" />
      <circle cx="12.5" cy="3.5" r="2.5" fill="#F9F9F9" stroke="#001335" />
      <path
        d="M1 11C1 9.34315 2.34315 8 4 8H7C8.65685 8 10 9.34315 10 11V14.75H1V11Z"
        fill="#001335"
        stroke="#001335"
      />
      <path
        d="M8 11C8 9.34315 9.34315 8 11 8H14C15.6569 8 17 9.34315 17 11V14.75H8V11Z"
        fill="#F9F9F9"
        stroke="#F9F9F9"
        strokeWidth="3"
      />
      <path
        d="M8 11C8 9.34315 9.34315 8 11 8H14C15.6569 8 17 9.34315 17 11V14.75H8V11Z"
        fill="#F9F9F9"
        stroke="#001335"
      />
    </svg>
  )
}
