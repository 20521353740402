import * as React from 'react'

const QuestionMarkExplain = props => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.7031 20.3479V20.2647C15.7124 19.3819 15.8048 18.6794 15.9805 18.1571C16.1561 17.6348 16.4057 17.2118 16.7293 16.8883C17.0528 16.5647 17.4411 16.2666 17.894 15.9939C18.1667 15.8275 18.4117 15.6311 18.6289 15.4046C18.8462 15.1735 19.0172 14.9077 19.142 14.6073C19.2714 14.3068 19.3361 13.974 19.3361 13.6089C19.3361 13.1559 19.2298 12.7631 19.0172 12.4303C18.8046 12.0975 18.5203 11.8409 18.1644 11.6607C17.8085 11.4804 17.4133 11.3903 16.9788 11.3903C16.5998 11.3903 16.2347 11.4689 15.8834 11.626C15.5321 11.7832 15.2386 12.0304 15.0029 12.3679C14.7672 12.7053 14.6308 13.1467 14.5938 13.6921H12.8467C12.8837 12.9063 13.087 12.2338 13.4568 11.6745C13.8312 11.1153 14.3234 10.6877 14.9336 10.3919C15.5483 10.0961 16.2301 9.94818 16.9788 9.94818C17.7923 9.94818 18.4995 10.11 19.1004 10.4335C19.7059 10.7571 20.1727 11.2008 20.5009 11.7647C20.8337 12.3286 21.0001 12.971 21.0001 13.6921C21.0001 14.2005 20.9215 14.6604 20.7644 15.0718C20.6118 15.4832 20.39 15.8506 20.0988 16.1742C19.8122 16.4977 19.4655 16.7843 19.0588 17.0339C18.6521 17.2881 18.3262 17.5562 18.0812 17.8381C17.8363 18.1155 17.6583 18.4459 17.5474 18.8296C17.4364 19.2132 17.3764 19.6916 17.3671 20.2647V20.3479H15.7031ZM16.5906 24.4524C16.2486 24.4524 15.9551 24.3299 15.7101 24.0849C15.4651 23.8399 15.3426 23.5464 15.3426 23.2044C15.3426 22.8624 15.4651 22.5689 15.7101 22.3239C15.9551 22.0789 16.2486 21.9564 16.5906 21.9564C16.9326 21.9564 17.2261 22.0789 17.4711 22.3239C17.7161 22.5689 17.8386 22.8624 17.8386 23.2044C17.8386 23.4309 17.7808 23.6389 17.6652 23.8284C17.5543 24.0179 17.4041 24.1704 17.2146 24.286C17.0297 24.3969 16.8217 24.4524 16.5906 24.4524Z"
      fill="#041433"
    />
    <circle cx="17" cy="17" r="16" stroke="#041433" />
  </svg>
)

export default QuestionMarkExplain
