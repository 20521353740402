import { getObjectForDownload } from './mediaUtils'

export const getVideoFrame = ({ videoUrl, time }) =>
  new Promise(async (resolve, reject) => {
    // Must be done in order to prevent exporting a tainted canvas
    const videoData = await getObjectForDownload(videoUrl)

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const video = document.createElement('video')

    video.preload = 'auto'

    // When data is loaded, seek manually to the desired time of the wanted frame
    video.addEventListener('loadeddata', () => {
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      video.currentTime = time
    })

    // After manually seeked, draw the current video frame over the canvas
    // export it and delete all objects from DOM
    video.addEventListener('seeked', () => {
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      const newFrame = canvas.toDataURL()

      canvas.remove()
      video.remove()
      resolve(newFrame)
    })
    video.src = URL.createObjectURL(videoData)
    video.load()
  })
