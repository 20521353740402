import * as React from 'react'

function Cancel({ color = 'black', ...props }) {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.2172 2.78283C14.4219 0.987584 12.0389 0 9.5 0C6.96107 0 4.57807 0.987584 2.78283 2.78283C0.987584 4.57807 0 6.96107 0 9.5C0 12.0389 0.987584 14.4219 2.78283 16.2172C4.57807 18.0124 6.96107 19 9.5 19C12.0389 19 14.4219 18.0124 16.2172 16.2172C18.0124 14.4219 19 12.0389 19 9.5C19 6.96107 18.0124 4.57807 16.2172 2.78283ZM15.4535 15.4535C13.8622 17.0448 11.7471 17.9205 9.5 17.9205C7.25295 17.9205 5.13784 17.0448 3.54651 15.4535C0.263889 12.1709 0.263889 6.82912 3.54651 3.54651C5.13784 1.95518 7.25295 1.07955 9.5 1.07955C11.7471 1.07955 13.8622 1.95518 15.4535 3.54651C18.7361 6.82912 18.7361 12.1709 15.4535 15.4535Z"
        fill={color}
      />
      <path
        d="M13.6857 5.31372C13.4738 5.10181 13.1339 5.10181 12.922 5.31372L9.49947 8.73628L6.07691 5.31372C5.865 5.10181 5.52514 5.10181 5.31323 5.31372C5.10132 5.52563 5.10132 5.86549 5.31323 6.0774L8.73579 9.49996L5.31323 12.9225C5.10132 13.1344 5.10132 13.4743 5.31323 13.6862C5.41719 13.7902 5.55713 13.8461 5.69307 13.8461C5.82901 13.8461 5.96895 13.7942 6.07291 13.6862L9.49547 10.2636L12.918 13.6862C13.022 13.7902 13.1619 13.8461 13.2979 13.8461C13.4378 13.8461 13.5738 13.7942 13.6777 13.6862C13.8896 13.4743 13.8896 13.1344 13.6777 12.9225L10.2632 9.49996L13.6857 6.0774C13.8976 5.86549 13.8976 5.52563 13.6857 5.31372Z"
        fill={color}
      />
    </svg>
  )
}

export default Cancel
