import { makeStyles } from '@material-ui/styles'
import actions from 'actions'
import useQueryParams from 'hooks/useQueryParams'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import UploadProviderSTLs from './UploadProviderSTLs'

const useStyles = makeStyles(theme => ({
  container: {
    width: 1200
  }
}))

const LandingPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { queryParams } = useQueryParams()

  const { provider, patientId, treatmentId, treatmentType } = queryParams

  useEffect(() => {
    dispatch(actions.fetchAppConfig())
  }, [dispatch])

  return (
    <div className={classes.container}>
      <UploadProviderSTLs
        provider={provider}
        patientId={patientId}
        treatmentId={treatmentId}
        treatmentType={treatmentType}
      />
    </div>
  )
}

export default LandingPage
