import { Badge, Box, makeStyles, Tab, Tabs } from '@material-ui/core'
import Actions from 'actions'
import { ROUTES } from 'consts'
import { Roles } from 'consts/authConsts'
import useBroadcast from 'hooks/useBroadcast'
import useFeatureFlags from 'hooks/useFeatureFlags'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import useRcDashboard from 'hooks/useRcDashboard'
import useRolePermissions from 'hooks/useRolePermissions'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { trackEvent } from 'utils/analyticsUtils'
import { isAnalystUser, isUserOfRole } from 'utils/authUtils'
import PrimaryButton from '../common/buttons/PrimaryButton'
import AppLogo from './AppLogo'
import AppProfile from './ProfileMenu'

const useAppHeaderStyles = makeStyles({
  appHeader: {
    width: '100%',
    minHeight: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '50px'
  },
  taskManagerTab: {
    display: 'flex',
    alignItems: 'center'
  },
  badgeRoot: {
    marginLeft: 20,
    marginRight: 10
  },
  badge: {
    fontFamily: 'Dazzed',
    fontWeight: 600
  }
})

const useAppTabsStyles = ({ hasIndicator }) =>
  makeStyles({
    root: {
      height: '35px',
      minHeight: '35px',
      marginLeft: '52px'
    },
    indicator: {
      height: hasIndicator ? '3px' : '0px',
      backgroundColor: 'var(--border-color-1)'
    }
  })({ hasIndicator })

const useAppTabStyles = makeStyles({
  root: {
    height: '35px',
    minHeight: '35px',
    minWidth: '50px',
    marginRight: '38px',
    padding: '0px 13px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    opacity: 1
  },
  wrapper: {
    fontFamily: 'Dazzed',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: 'var(--primary-color)',
    textTransform: 'none'
  },
  badge: {
    position: 'absolute'
  }
})

const AppTab = ({ label, to, id, name }) => {
  const classes = useAppTabStyles()
  return (
    <Tab
      id={id}
      component={RouterLink}
      to={to}
      classes={{
        root: classes.root,
        wrapper: classes.wrapper
      }}
      disableRipple
      label={label}
      value={to}
      onClick={() => trackEvent('App Header - Tab Click', { tab: name || id })}
    />
  )
}

const AppTabs = ({ children }) => {
  const routes = useMemo(() => children?.map(child => child?.props?.to).filter(child => !!child), [children])
  const hasIndicator = useMemo(() => routes.length > 1, [routes])
  const classes = useAppTabsStyles({ hasIndicator })
  const { pathname } = useLocation()
  const selected = routes.findIndex(route => pathname.includes(route))
  const value = selected === -1 ? false : selected
  return (
    <Tabs
      classes={{
        root: classes.root,
        indicator: classes.indicator
      }}
      value={value}
      TabIndicatorProps={{ children: <div /> }}
      aria-label="page_header_tabs"
    >
      {children}
    </Tabs>
  )
}

const AppHeader = () => {
  const classes = useAppHeaderStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()
  const { closeBroadcastMode } = useBroadcast()

  const { ortho: isOrthoFFEnabled = false } = useFeatureFlags()
  const { isRcEnabled } = usePracticeConfiguration()
  const hasContent = useMemo(() => !isAnalystUser(), [])
  const ddxUser = sessionStorage.getItem('ddx')
  const isAdmin = useMemo(() => isUserOfRole([Roles.Admin]), [])
  const newRC = useSelector(state => state.appReducer.appconfig?.app?.newRCFlow)

  const { totalLeads } = useRcDashboard()

  const { total: orthoTotalTasks } = useSelector(state => state.taskManagerReducer.taskCategories)

  const handleClickInvitePatient = useCallback(() => {
    dispatch(Actions.requestOpenInvitePatientModal(true))
    closeBroadcastMode('Invite patient clicked')
    trackEvent('Invite patient - modal opened', {
      source: 'Patient Header'
    })
  }, [closeBroadcastMode, dispatch])

  const isRcDashboardEnabled = useMemo(
    () => newRC && permissions.rcDashboard && (isRcEnabled || isAdmin),
    [newRC, permissions, isRcEnabled, isAdmin]
  )

  return (
    <header className={classes.appHeader}>
      <Box display="flex" alignItems="center" height="100%">
        <AppLogo />

        <AppTabs>
          {hasContent && (
            <AppTab
              id="header-patients-button"
              label={t('common.appHeader.patientsTab')}
              to={ROUTES.PATIENTS}
              name="Patients"
            />
          )}
          {isOrthoFFEnabled && (
            <AppTab
              id="header-task-manager-button"
              name="Task Manager"
              label={
                <div className={classes.taskManagerTab}>
                  {t('common.appHeader.taskManagerTab')}
                  {!!orthoTotalTasks && (
                    <Badge
                      badgeContent={orthoTotalTasks}
                      color="error"
                      classes={{ root: classes.badgeRoot, badge: classes.badge }}
                    />
                  )}
                </div>
              }
              to={ROUTES.TASK_MANAGER}
            />
          )}
          {isRcDashboardEnabled && (
            <AppTab
              id="header-rc-dashboard-button"
              name="RC Dashboard"
              label={
                <div className={classes.taskManagerTab}>
                  {t('common.appHeader.rcDashboardTab')}
                  {!!totalLeads && (
                    <Badge
                      badgeContent={totalLeads}
                      color="error"
                      classes={{ root: classes.badgeRoot, badge: classes.badge }}
                    />
                  )}
                </div>
              }
              to={ROUTES.RC_DASHBOARD}
            />
          )}
          {!!ddxUser && <AppTab id="header-ddx-button" label={t('common.appHeader.ddxTab')} to={ROUTES.DDX} />}
        </AppTabs>
      </Box>
      <Box display="flex" alignItems="center">
        {hasContent && permissions.invitePatient && (
          <PrimaryButton
            id="header-invite-a-patient-button"
            className={classes.invitePatientButton}
            onClick={handleClickInvitePatient}
            label={t('common.appHeader.invitePatientBtn')}
          />
        )}
        <AppProfile />
      </Box>
    </header>
  )
}

export default AppHeader
