import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { ColoredBroadcast } from 'components/common/icons'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    marginTop: 15,
    marginBottom: 8,
    color: 'var(--text-color-21)'
  },
  subtitle: {
    fontSize: 12,
    color: 'var(--text-color-21)'
  }
})

const TimelineBroadcastState = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <ColoredBroadcast />
      <DazzedParagraph16 className={classes.title} textAlign="center" bold>
        {t('pages.patients.selectedPatient.timeline.broadcast.title')}
      </DazzedParagraph16>
      <DazzedParagraph16 className={classes.subtitle} textAlign="center" bold>
        {t('pages.patients.selectedPatient.timeline.broadcast.subtitle')}
      </DazzedParagraph16>
    </div>
  )
}

export default TimelineBroadcastState
