import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import React, { createRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../../../actions'
import TimelineItem from './TimelineItem'
import useTimelineMilestones from './useTimelineMilestones'
import { CONTENT_HEIGHT } from 'consts/patientCard'

const useStyles = makeStyles({
  container: {
    height: CONTENT_HEIGHT,
    padding: '30px 50px 87px 5%'
  },
  loaderContainer: {
    height: CONTENT_HEIGHT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  timeLine: {
    height: '305px',
    overflowY: 'auto'
  }
})

const TimelineTab = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const patient = useSelector(state => state.patientsReducer.patientCard.patient)
  const proposal = useSelector(state => state.patientsReducer.patientCard.prelimPlan.data)
  const { data: treatment, isLoading } = useSelector(state => state.patientsReducer.patientCard.treatment)
  const initialScan = useSelector(state => state.patientsReducer.patientCard.scans.data)
  const treatmentPlan = useSelector(state => state.patientsReducer.patientCard.treatmentPlan.data)
  const { milestones, lastCompletedMilestone } = useTimelineMilestones({
    patient,
    proposal,
    treatment,
    initialScan,
    treatmentPlan
  })

  useEffect(() => {
    if (!treatment) {
      dispatch(Actions.requestPatientTreatment(patient))
    }
  }, [dispatch, patient, treatment])

  const activeRef = createRef()
  useEffect(() => {
    setTimeout(() => {
      if (activeRef?.current?.scrollIntoView) {
        activeRef.current.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        })
      }
    }, 100)
  }, [activeRef])

  return (
    <div className={classes.container}>
      {isLoading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div className={classes.timeLine}>
          {milestones.map(({ type, done, icon }, index) => {
            return (
              <TimelineItem
                ref={type === lastCompletedMilestone ? activeRef : null}
                key={type}
                type={type}
                text={t(`dialogs.patientInfo.timeline.milestones.${type}`)}
                filled={done}
                lastItem={milestones.length - 1 === index}
                icon={icon}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default TimelineTab
