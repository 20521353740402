import * as React from 'react'

function NotifyPractice(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="6" stroke="#001335" />
      <circle cx="5" cy="8" r="1" fill="#001335" />
      <circle cx="8" cy="8" r="1" fill="#001335" />
      <circle cx="11" cy="8" r="1" fill="#001335" />
    </svg>
  )
}

export default NotifyPractice
