import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import SentByServiceAccountIcon from 'components/common/icons/SentByServiceAccount'

const useStyles = makeStyles(theme => ({
  text: {
    color: 'var(--text-color-11)'
  }
}))

const ScanByServiceAccount = ({ className = '' }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <DazzedParagraph12 className={[classes.text, className].join(' ')}>
      <SentByServiceAccountIcon /> {t('pages.patients.selectedPatient.timeline.scanSentByRecordsApp')}
    </DazzedParagraph12>
  )
}

export default ScanByServiceAccount
