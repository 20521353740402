import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import useOpenPatientCard from 'hooks/useOpenPatientCard'
import useRolePermissions from 'hooks/useRolePermissions'
import { Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { PATIENT_CARD_TABS } from 'consts/patientCard'
import { isMobile } from 'utils/mobileUtils'
import Cog12x12 from 'components/common/icons/Cog12x12'

const useStyles = makeStyles(theme => ({
  cogIcon: {
    marginRight: 5,
    marginTop: 2
  },
  link: {
    color: 'var(--text-color-25)',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}))

const TeethTrackingSettingsButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const openPatientCard = useOpenPatientCard()
  const { permissions } = useRolePermissions()

  return (
    !isMobile() &&
    permissions.editGiTrackingSettings && (
      <Grid container alignItems="center">
        <Grid item className={classes.cogIcon}>
          <Cog12x12 />
        </Grid>
        <Grid item>
          <DazzedParagraph12
            className={classes.link}
            onClick={() => openPatientCard({ tabIndex: PATIENT_CARD_TABS.giTrackSettings.index })}
          >
            {t('pages.patients.selectedPatient.chat.gi.teethTrackingSettings')}
          </DazzedParagraph12>
        </Grid>
      </Grid>
    )
  )
}

export default TeethTrackingSettingsButton
