import Amplify, { Auth, PubSub } from 'aws-amplify'
import { MqttOverWSProvider } from '@aws-amplify/pubsub'
import { ofType } from 'redux-observable'
import * as Rx from 'rxjs'
import { from, of, delayWhen, timer } from 'rxjs'
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import Actions from 'actions'
import { getEnvironment } from '../utils/awsUtils'
import { getAccessToken } from '../utils/storageUtils'
import { v4 as uuidv4 } from 'uuid'
import { logInfo } from 'utils/logUtils'

export const configurePubsubEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CONFIGURE_PUBSUB),
    withLatestFrom(state$),
    map(([, state]) => ({
      pubsubEndpoint: state.appReducer.appconfig?.app?.pubsubEndpoint,
      clientId: uuidv4(),
      doctor: state.profileReducer.doctor
    })),
    tap(({ doctor }) =>
      logInfo('live updates: using new IoT pubsub', {
        username: doctor.username,
        doctorId: doctor.id,
        email: doctor.email
      })
    ),
    switchMap(({ pubsubEndpoint, clientId, doctor }) =>
      from(Auth.currentSession()).pipe(
        map(cognitoUser => cognitoUser?.accessToken?.jwtToken),
        tap(token => {
          PubSub.removePluggable('MqttOverWSProvider')
          Amplify.addPluggable(
            new MqttOverWSProvider({
              aws_pubsub_endpoint: `wss://${pubsubEndpoint}/mqtt?token=${token}`,
              clientId
            })
          )
          logInfo(`IoT configured. client id: ${clientId}`, {
            doctorId: doctor?.id,
            email: doctor?.email,
            username: doctor?.username,
            clientId
          })
        }),
        mergeMap(token =>
          of(
            pubsubEndpoint && token
              ? Actions.requestSubscribeToNotifications()
              : Actions.requestSubscribeToNotificationsFailed({
                  retriable: false,
                  message: 'missing pubsub endpoint or token',
                  pubsubEndpoint,
                  token
                })
          )
        )
      )
    )
  )

let pubsubSubscription = null
export const subscribeToPubsubNotifications = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_SUBSCRIBE_TO_NOTIFICATIONS),
    filter(() => !pubsubSubscription || pubsubSubscription?.closed),
    withLatestFrom(state$),
    map(([, state]) => ({
      username: state.profileReducer.doctor.username,
      token: getAccessToken(),
      env: getEnvironment(),
      allowedGroups: JSON.parse(state.profileReducer.doctor.user.allowed_groups_permissions || '[]'),
      doctor: state.profileReducer.doctor
    })),
    map(({ allowedGroups, username, env, token, pubsubEndpoint }) => ({
      topics: [`${env}/${username}`].concat(allowedGroups.map(groupPermission => `${env}/${groupPermission.groupKey}`))
    })),
    switchMap(({ topics }) =>
      from(
        new Rx.Observable(observer => {
          pubsubSubscription = PubSub.subscribe(topics).subscribe(observer)
          return pubsubSubscription
        }).pipe(
          map(event => event.value),
          mergeMap(notification => of(Actions.notificationReceived(notification))),
          catchError(error =>
            of(Actions.requestSubscribeToNotificationsFailed({ error, retriable: true, message: 'IoT Error' }))
          )
        )
      )
    )
  )

export const pubsubUnsubscribe = action$ =>
  action$.pipe(
    ofType(Actions.SIGNOUT_REQUESTED),
    filter(() => pubsubSubscription && !pubsubSubscription.closed),
    tap(() => pubsubSubscription.unsubscribe())
  )

export const subscribeToNotificationsRetry = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_SUBSCRIBE_TO_NOTIFICATIONS_FAILED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      retriable: action.payload?.retriable,
      grinUserId: state.profileReducer.doctor.user?.id,
      doctorId: state.profileReducer.doctor?.id,
      subscribeToNotificationsAttepmts: state.appReducer.subscribeToNotificationsAttepmts,
      maxAttempts: state.appReducer.appconfig?.app?.pubsubMaxAttempts || 12,
      error: action.payload,
      username: state.profileReducer.doctor?.username,
      email: state.profileReducer.doctor?.email
    })),
    filter(({ retriable }) => retriable),
    delayWhen(({ subscribeToNotificationsAttepmts }) => timer(1000 * Math.pow(1.5, subscribeToNotificationsAttepmts))), // at 12, will wait about 2 minutes
    tap(payload => logInfo('PubSub retry:', payload)),
    mergeMap(({ grinUserId, subscribeToNotificationsAttepmts, maxAttempts, doctorId, error, username }) =>
      subscribeToNotificationsAttepmts >= maxAttempts
        ? of(
            Actions.pubsubConnectFailed({
              error,
              doctorId,
              maxAttempts,
              subscribeToNotificationsAttepmts,
              username
            })
          )
        : of(Actions.configurePubsub())
    )
  )

export const pubsubResetTrigger = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PUBSUB_CONNECT_FAILED),
    withLatestFrom(state$),
    map(([, state]) => ({
      resetInSeconds: state.appReducer.appconfig?.app?.pubsubStateResetSeconds || 10 * 60,
      doctorId: state.profileReducer.doctor?.id,
      username: state.profileReducer.doctor?.username
    })),
    tap(({ resetInSeconds, doctorId, username }) =>
      logInfo(`reseting PubSub state in: ${resetInSeconds} seconds`, { doctorId, username })
    ),
    delayWhen(({ resetInSeconds }) => timer(1000 * resetInSeconds)),
    map(() => Actions.pubsubReset())
  )

export const pubsubReset = action$ =>
  action$.pipe(
    ofType(Actions.PUBSUB_RESET),
    map(() => Actions.configurePubsub())
  )
