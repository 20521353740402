import Actions from '../actions'
import { v4 as uuidv4 } from 'uuid'

const initialState = {
  versions: [],
  isLoadingVersions: false,
  publish: [],
  hasPublishFetched: false,
  editor: {
    isLoading: false,
    versionGuid: '',
    version: '',
    description: '',
    headlineMedia: null,
    isHeadlineDisabled: false,
    isSaving: false,
    slides: [],
    createdAt: null,
    headlineEditMode: true,
    isPublishing: false,
    mediaUploadingStatus: {
      main: false
    }
  },
  isModalOpen: false,
  modalReleaseNotes: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.OPEN_RELEASE_NOTES_EDITOR:
      if (action.payload) {
        const versionData = action.payload
        const slides = [...versionData.slides].map(slide => ({
          ...slide,
          editMode: true
        }))
        return {
          ...state,
          editor: {
            ...initialState.editor,
            ...versionData,
            slides
          }
        }
      }
      return {
        ...state,
        editor: {
          ...initialState.editor,
          versionGuid: uuidv4(),
          createdAt: new Date()
        }
      }
    case Actions.ADD_RELEASE_NOTES_SLIDE:
      return {
        ...state,
        editor: {
          ...state.editor,
          slides: state.editor.slides.concat({
            id: uuidv4(),
            title: '',
            subtitle: '',
            mediaObject: null,
            editMode: true,
            bullets: [
              {
                title: '',
                description: ''
              }
            ]
          })
        }
      }
    case Actions.REMOVE_RELEASE_NOTES_SLIDE: {
      const updatedSlides = [...state.editor.slides]
      updatedSlides.splice(action.payload, 1)
      return {
        ...state,
        editor: {
          ...state.editor,
          slides: updatedSlides
        }
      }
    }
    case Actions.SET_RELEASE_NOTES_SLIDE_DATA: {
      const updatedSlides = [...state.editor.slides]
      updatedSlides[action.payload.index] = action.payload.data
      return {
        ...state,
        editor: {
          ...state.editor,
          slides: updatedSlides
        }
      }
    }
    case Actions.SET_RELEASE_NOTES_HEADLINE_DETAILS:
      return {
        ...state,
        editor: {
          ...state.editor,
          version: action.payload.version,
          description: action.payload.description,
          isHeadlineDisabled: action.payload.isHeadlineDisabled
        }
      }
    case Actions.UPLOAD_RELEASE_NOTE_MEDIA:
      if (action.payload.slideId) {
        return {
          ...state,
          editor: {
            ...state.editor,
            mediaUploadingStatus: {
              ...state.editor.mediaUploadingStatus,
              [action.payload.slideId]: true
            }
          }
        }
      } else {
        return {
          ...state,
          editor: {
            ...state.editor,
            mediaUploadingStatus: {
              ...state.editor.mediaUploadingStatus,
              main: true
            }
          }
        }
      }
    case Actions.UPLOAD_RELEASE_NOTE_MEDIA_RECEIVED:
      if (action.payload.slideId) {
        const updatedSlides = [...state.editor.slides]
        const slideIndex = updatedSlides.findIndex(slide => slide.id === action.payload.slideId)
        if (slideIndex === -1) {
          return state
        }
        updatedSlides[slideIndex].mediaObject = action.payload.media

        return {
          ...state,
          editor: {
            ...state.editor,
            mediaUploadingStatus: {
              ...state.editor.mediaUploadingStatus,
              [action.payload.slideId]: false
            }
          }
        }
      } else {
        return {
          ...state,
          editor: {
            ...state.editor,
            headlineMedia: action.payload.media,
            mediaUploadingStatus: {
              ...state.editor.mediaUploadingStatus,
              main: false
            }
          }
        }
      }
    case Actions.SAVE_RELEASE_NOTES:
      return {
        ...state,
        editor: {
          ...state.editor,
          isSaving: true
        }
      }
    case Actions.SAVE_RELEASE_NOTES_RECEIVED:
    case Actions.SAVE_RELEASE_NOTES_FAILED:
      return {
        ...state,
        editor: {
          ...state.editor,
          isSaving: false
        }
      }
    case Actions.FETCH_ALL_RELEASE_NOTES:
      return {
        ...state,
        isLoadingVersions: true
      }
    case Actions.FETCH_ALL_RELEASE_NOTES_RECEIVED:
      return {
        ...state,
        editor: {
          ...state.editor
        },
        versions: action.payload,
        isLoadingVersions: false
      }
    case Actions.REMOVE_RELEASE_NOTES_SLIDE_MEDIA:
      if (action.payload !== undefined) {
        const updatedSlides = [...state.editor.slides]
        updatedSlides[action.payload].mediaObject = null
        return {
          ...state,
          editor: {
            ...state.editor,
            slides: updatedSlides
          }
        }
      }

      return {
        ...state,
        editor: {
          ...state.editor,
          headlineMedia: null
        }
      }
    case Actions.SET_RELEASE_NOTES_SLIDE_EDIT_MODE:
      if (action.payload.slide === undefined) {
        return {
          ...state,
          editor: {
            ...state.editor,
            headlineEditMode: !!action.payload.editMode
          }
        }
      }

      const slides = [...state.editor.slides]
      slides[action.payload.slide].editMode = action.payload.editMode
      return {
        ...state,
        editor: {
          ...state.editor,
          slides: slides
        }
      }
    case Actions.FETCH_PUBLISH_FILE_RECEIVED:
      return {
        ...state,
        publish: action.payload,
        hasPublishFetched: true
      }
    case Actions.PUBLISH_RELEASE_NOTES:
      return {
        ...state,
        editor: {
          ...state.editor,
          isPublishing: true
        }
      }
    case Actions.FETCH_REJECTED:
      return {
        ...state,
        editor: {
          ...state.editor,
          isPublishing: false,
          mediaUploadingStatus: initialState.editor.mediaUploadingStatus
        },
        isLoadingVersions: false
      }
    case Actions.PUBLISH_RELEASE_NOTES_RECEIVED:
      return {
        ...state,
        editor: {
          ...state.editor,
          isPublishing: false,
          mediaUploadingStatus: initialState.editor.mediaUploadingStatus
        },
        publish: action.payload,
        isLoadingVersions: false
      }
    case Actions.SET_RELEASE_NOTES_MODAL_VISIBILITY:
      return {
        ...state,
        isModalOpen: action.payload
      }
    case Actions.SET_MODAL_RELEASE_NOTES:
      return {
        ...state,
        modalReleaseNotes: action.payload
      }
    case Actions.REVIEW_VERSION_RELEASE_NOTES_RECEIVED:
      return {
        ...state,
        modalReleaseNotes: action.payload,
        isModalOpen: true
      }
    case Actions.REQUEST_EDIT_RELEASE_NOTES:
    case Actions.REQUEST_UNPUBLISH_RELEASE_NOTES:
    case Actions.REQUEST_DELETE_RELEASE_NOTES:
      return {
        ...state,
        editor: {
          ...state.editor,
          isLoading: true
        }
      }
    case Actions.DELETE_RELEASE_NOTES_RECEIVED:
    case Actions.UNPUBLISH_RELEASE_NOTES_RECEIVED:
    case Actions.DELETE_RELEASE_NOTES_FAILED:
    case Actions.UNPUBLISH_RELEASE_NOTES_FAILED:
      return {
        ...state,
        editor: {
          ...state.editor,
          isLoading: false
        }
      }
    default:
      return state
  }
}
