import * as React from 'react'

const Moderate = () => (
  <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0L8 4.33333L0 8.66667V0Z" fill="#869ED2" />
    <path d="M8 0L16 4.33333L8 8.66667V0Z" fill="#869ED2" />
  </svg>
)

export default Moderate
