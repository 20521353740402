import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'
import { Grid } from '@material-ui/core'
import { rangeArray } from 'utils/arrayUtils'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    background: '#fff',
    padding: 20
  },
  chatSkeletonContainer: {
    padding: '30px',
    height: '100%'
  },
  messageSkeleton: {
    margin: 10,
    borderRadius: 16
  }
}))

const ChatSkeleton = () => {
  const classes = useStyles()

  return (
    <Grid container direction="column" justifyContent="space-between" className={classes.root}>
      <Grid item>
        <Grid container direction="column">
          {rangeArray(5).map(i => {
            const isLeft = i % 2 === 0
            return (
              <Grid item key={`skeleton-message-${i}`} style={{ alignSelf: isLeft ? 'flex-end' : 'flex-start' }}>
                <Grid container alignItems="flex-end" direction={isLeft ? 'row-reverse' : 'row'}>
                  <Grid item>
                    <Skeleton variant="circle" width={40} height={40} />
                  </Grid>
                  <Grid item>
                    <Skeleton variant="rect" width={350} height={110} className={[classes.messageSkeleton].join(' ')} />
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ChatSkeleton
