import * as React from 'react'

const Trash1 = props => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_7977_224279)">
        <path
          d="M18.1201 4.47037L17.6785 3.14667C17.5104 2.64252 17.0403 2.30377 16.509 2.30377H12.7976V1.09543C12.7976 0.491486 12.3065 0 11.7027 0H8.30401C7.70037 0 7.20919 0.491486 7.20919 1.09543V2.30377H3.49795C2.96648 2.30377 2.49636 2.64252 2.32821 3.14667L1.88662 4.47037C1.78606 4.77173 1.83703 5.10544 2.02273 5.36316C2.20843 5.62088 2.50887 5.77484 2.82656 5.77484H3.28814L4.30407 18.3376C4.3796 19.2697 5.17061 20 6.10522 20H14.1077C15.0421 20 15.8333 19.2697 15.9087 18.3374L16.9246 5.77484H17.1802C17.4979 5.77484 17.7983 5.62088 17.984 5.36331C18.1697 5.10559 18.2207 4.77173 18.1201 4.47037ZM8.38106 1.17188H11.6257V2.30377H8.38106V1.17188ZM14.7406 18.243C14.7141 18.5712 14.4361 18.8281 14.1077 18.8281H6.10522C5.77685 18.8281 5.49883 18.5712 5.47228 18.243L4.46383 5.77484H15.7489L14.7406 18.243ZM3.07787 4.60297L3.43996 3.51746C3.4482 3.49243 3.47155 3.47565 3.49795 3.47565H16.509C16.5354 3.47565 16.5586 3.49243 16.5669 3.51746L16.929 4.60297H3.07787Z"
          fill="white"
        />
        <path
          d="M12.8699 18.2014C12.8803 18.202 12.8905 18.2022 12.9009 18.2022C13.2105 18.2022 13.4693 17.9596 13.4855 17.6467L14.0357 7.08431C14.0525 6.76113 13.8041 6.4854 13.481 6.46862C13.1571 6.45138 12.8823 6.70009 12.8653 7.02328L12.3153 17.5857C12.2985 17.9089 12.5467 18.1846 12.8699 18.2014Z"
          fill="white"
        />
        <path
          d="M6.5458 17.648C6.56289 17.9604 6.82137 18.2021 7.13036 18.2021C7.14104 18.2021 7.15203 18.2018 7.16286 18.2011C7.48589 18.1836 7.73354 17.9074 7.71599 17.5842L7.13967 7.0218C7.12212 6.69862 6.84594 6.45097 6.52276 6.46867C6.19973 6.48621 5.95208 6.7624 5.96963 7.08558L6.5458 17.648Z"
          fill="white"
        />
        <path
          d="M10.0098 18.2021C10.3334 18.2021 10.5957 17.9398 10.5957 17.6161V7.05371C10.5957 6.73007 10.3334 6.46777 10.0098 6.46777C9.68613 6.46777 9.42383 6.73007 9.42383 7.05371V17.6161C9.42383 17.9398 9.68613 18.2021 10.0098 18.2021Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7977_224279">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Trash1
