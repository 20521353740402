import * as React from 'react'

const GrinSwooshLogo = props => {
  return (
    <svg width="86" height="92" viewBox="0 0 86 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.6934 70.8333C26.6528 70.0404 30.0683 68.9022 30.0683 68.9022C28.6205 57.8007 16.6869 57.6777 16.6869 57.6777C11.7748 57.6777 7.73927 59.3524 4.57823 62.7012C1.52585 65.957 -1.52588e-05 69.9678 -1.52588e-05 74.7342C-1.52588e-05 79.4541 1.47184 83.3792 4.41556 86.5101C7.5766 89.8595 11.7983 91.5336 17.0819 91.5336C19.9943 91.5336 22.6283 91.2065 24.9838 90.5523C27.3387 89.8981 29.0428 89.2439 30.0969 88.5897L30.1125 74.1193C30.1125 73.8268 29.8334 73.611 29.5555 73.698C27.6751 74.2887 23.0252 75.565 17.849 76.371V79.3279C19.5994 79.3593 20.7615 79.5149 21.3348 79.7949C22.2177 80.247 23.05 81.1968 23.05 82.6452V84.0484C23.05 85.0297 22.6374 85.8755 22.0557 86.4408C21.2638 87.2101 19.995 87.7256 17.9655 87.7256C14.2156 87.7256 11.1268 86.0123 10.0037 83.423C8.65609 80.3163 8.82917 78.6037 8.82917 74.6164C8.82917 69.7565 8.86236 68.1224 10.4377 65.5377C11.7124 63.4463 14.1961 62.4696 16.3394 62.5324C16.3394 62.5324 19.9982 62.2092 21.6926 65.4887C22.9049 67.8352 22.6934 70.8333 22.6934 70.8333Z"
        fill="#001335"
      />
      <path
        d="M57.1796 66.9588C58.2246 66.9588 59.1004 66.6115 59.807 65.9148C60.513 65.2194 60.8664 64.3742 60.8664 63.3798C60.8664 62.3292 60.513 61.4487 59.807 60.7382C59.1004 60.0284 58.2246 59.6732 57.1796 59.6732C56.1905 59.6732 55.3498 60.0284 54.6581 60.7382C53.9658 61.4487 53.6196 62.3292 53.6196 63.3798C53.6196 64.346 53.9723 65.184 54.679 65.8938C55.3856 66.6043 56.2191 66.9588 57.1796 66.9588Z"
        fill="#001335"
      />
      <path
        d="M41.0498 68.9489C41.3387 68.7971 41.6823 69.0398 41.6823 69.3676L41.6764 74.2216C42.5275 73.0493 43.5433 72.0052 44.6156 71.1489C45.6873 70.2932 46.7746 69.8654 47.8788 69.8654C49.9909 69.8654 51.0476 70.6583 51.0476 72.2427C51.0476 73.0676 50.732 73.9305 50.1015 74.8339C49.4704 75.7373 48.808 76.1894 48.115 76.1894C47.8313 76.1894 47.5157 76.118 47.1689 75.9754C46.8214 75.8328 46.4746 75.666 46.1284 75.4763C45.2455 75.0328 44.6462 74.8103 44.3312 74.8103C43.4164 74.8103 42.6993 75.1512 42.1794 75.8328C41.6595 76.5151 41.3992 77.711 41.3992 79.4223V86.7446C41.3992 86.8708 41.4077 86.9886 41.4207 87.1017V90.7253L34.5612 90.722V71.7671C34.5612 71.7671 38.0202 70.5405 41.0498 68.9489Z"
        fill="#001335"
      />
      <path
        d="M54.0065 90.7241L54.0091 87.4794C54.0097 87.4559 54.0123 87.4336 54.0123 87.4101V77.1394C54.0123 77.0851 54.0104 77.0354 54.0091 76.9831V71.732C54.0091 71.732 57.1695 70.5688 60.2375 69.0688C60.5374 68.9223 60.8869 69.144 60.8869 69.4796V76.1595C60.8764 76.4414 60.8693 76.7083 60.8693 76.9491V87.3623C60.8693 87.4369 60.8764 87.5082 60.8869 87.5775V90.7241H54.0065Z"
        fill="#001335"
      />
      <path
        d="M78.3949 90.7085V87.5677C78.4007 87.4879 78.4046 87.4049 78.4046 87.3159V77.9487C78.4046 77.8296 78.4001 77.7145 78.3949 77.6L78.3448 77.0374C78.2439 76.2897 78.0123 75.6721 77.6479 75.19C77.143 74.5247 76.4812 74.1917 75.6613 74.1917C75.0939 74.1917 74.5734 74.2794 74.1003 74.4534C73.6273 74.628 73.1074 74.9048 72.5393 75.2855C72.2869 75.4445 72.1613 75.7297 72.1613 76.1412V87.3146C72.1613 87.3996 72.1645 87.4788 72.1697 87.5547V90.7255L65.3043 90.7222V71.7863C68.5643 70.5558 70.7343 69.5241 71.7442 69.0152C72.0455 68.8634 72.3975 69.0845 72.3975 69.4234V73.1941C73.5954 72.0852 74.7146 71.261 75.7557 70.7219C76.7961 70.1835 77.9941 69.9134 79.3501 69.9134C81.0835 69.9134 82.5027 70.5316 83.6062 71.768C84.7098 73.0044 85.2616 74.6686 85.2616 76.7607V87.3165C85.2616 87.382 85.2636 87.4441 85.2675 87.5043V90.7242L78.3942 90.7229"
        fill="#001335"
      />
      <path
        d="M12.7901 45.5759C25.752 45.6326 38.84 41.6312 50.0388 33.2681C61.2366 24.9051 68.8131 13.4742 72.4773 1"
        stroke="#FFBD39"
        strokeWidth="2"
      />
    </svg>
  )
}

export default GrinSwooshLogo
