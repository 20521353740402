import * as React from 'react'

const NotTrackingLarge = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 4 }}
  >
    <path d="M16 2L2 16" stroke="#CA6565" strokeWidth="3" />
    <path d="M2 2L16 16" stroke="#CA6565" strokeWidth="3" />
  </svg>
)

export default NotTrackingLarge
