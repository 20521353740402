import * as React from 'react'

export default () => {
  return (
    <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5.16667L5.58333 9.75L14.3333 1" stroke="#4151E6" strokeWidth="2.5" />
      <rect
        x="6.96094"
        y="2.89648"
        width="6.14705"
        height="4.56452"
        transform="rotate(45 6.96094 2.89648)"
        fill="white"
      />
      <path d="M6 5.16667L10.5833 9.75L19.3333 1" stroke="#4151E6" strokeWidth="2.5" />
    </svg>
  )
}
