import React, { useState, useRef, useEffect } from 'react'
import { isMobile } from 'utils/mobileUtils'
import { makeStyles } from '@material-ui/core'
import { Tooltip as MuiTooltip } from '@material-ui/core'
const useStyles = ({ isMobile }) =>
  makeStyles({
    container: {
      height: isMobile ? 48 : 32,
      display: 'flex',
      alignItems: 'center',
      padding: isMobile ? '0 6px' : '0 3px',
      position: 'relative',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: isMobile ? 'transparent' : 'var(--bg-color-37)'
      },
      transition: '.2s'
    },
    bold: {
      fontWeight: 'bold !important'
    },
    textStyle: {
      fontFamily: 'Dazzed',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '24px',
      color: 'var(--text-color-7)'
    },
    title: {
      width: 170,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    tooltip: {
      transform: 'translateX(calc(100% + 10px)) !important',
      backgroundColor: 'var(--bg-color-34)',
      padding: '5px 5px',
      whiteSpace: 'nowrap',
      maxWidth: '100%',
      cursor: 'pointer'
    }
  })({ isMobile })

const TemplatesListItem = ({ type, title, selected, onClick, withActions = false, onDelete, onTemplateCreated }) => {
  const ref = useRef(null)
  const classes = useStyles({ isMobile: isMobile() })

  const [disableTooltip, setDisableTooltip] = useState(true)

  useEffect(() => {
    if (ref.current && ref.current.scrollWidth > ref.current.clientWidth) {
      setDisableTooltip(false)
    }
  }, [title])

  return (
    <div className={classes.container} onClick={onClick}>
      <MuiTooltip
        title={title}
        placement="left"
        enterDelay={500}
        enterNextDelay={500}
        classes={{
          tooltip: `${classes.textStyle} ${classes.tooltip} ${selected ? classes.bold : ''}`
        }}
        disableHoverListener={!withActions || disableTooltip}
        interactive
      >
        <div ref={ref} className={`${classes.textStyle} ${classes.title} ${selected ? classes.bold : ''}`}>
          {title}
        </div>
      </MuiTooltip>
    </div>
  )
}

export default TemplatesListItem
