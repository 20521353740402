import { makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import DragFileImg from 'resources/dragFile.png'
import { Close } from '../icons'

const useStyles = ({ isBroadcastMode, showSavedFiles }) =>
  makeStyles({
    container: {
      marginBottom: 0,
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      paddingBottom: isBroadcastMode ? 140 : 90,
      backgroundColor: 'rgba(255,255,255,.95)',
      padding: 10
    },
    placeholderIcon: {
      marginBottom: 8
    },
    placeholderContainer: {
      background: '#fbfbfb95',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      position: 'relative'
    },
    placeholderText: {
      color: 'var(--text-color-6)'
    },
    dropzone: {
      border: '1px dashed var(--border-color-1)',
      borderRadius: 0,
      backgroundColor: 'transparent',
      cursor: 'auto'
    },
    placeholderSelectButton: {
      marginLeft: 5,
      textDecoration: 'underline',
      color: 'var(--text-color-8)',
      cursor: 'pointer'
    },
    close: {
      position: 'absolute',
      right: 10,
      top: 10,
      cursor: 'pointer'
    },
    note: {
      marginTop: 10,
      whiteSpace: 'pre-line'
    }
  })({ isBroadcastMode })

const DropzonePlaceholder = ({ handleCloseDropzone, openDropzoneFilePicker, isClosable, isBroadcastMode, note }) => {
  const { t } = useTranslation()

  const classes = useStyles({ isBroadcastMode })

  return (
    <div className={classes.placeholderContainer}>
      <img src={DragFileImg} alt="DragFile" />
      <DazzedParagraph14 className={classes.placeholderText} strong textAlign="center">
        {t('pages.patients.selectedPatient.chat.dropzone.title')}
      </DazzedParagraph14>
      <div style={{ display: 'flex' }}>
        <DazzedParagraph14 className={classes.placeholderText} textAlign="center">
          {t('pages.patients.selectedPatient.chat.dropzone.or')}
        </DazzedParagraph14>
        <DazzedParagraph14
          onClick={openDropzoneFilePicker}
          className={classes.placeholderSelectButton}
          textAlign="center"
        >
          {t('pages.patients.selectedPatient.chat.dropzone.select')}
        </DazzedParagraph14>
      </div>
      <DazzedParagraph14 textAlign="center" className={classes.note}>
        {note || t('pages.patients.selectedPatient.chat.dropzone.note')}
      </DazzedParagraph14>
      {isClosable && <Close color="var(--bg-color-7)" className={classes.close} onClick={handleCloseDropzone} />}
    </div>
  )
}

export default DropzonePlaceholder
