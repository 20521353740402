import { Grid, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core'
import React, { Fragment, useState, useCallback, useMemo } from 'react'
import { createIdForTest } from 'utils/testingUtils.js'
import { MoreVert } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Pencil4, Trash2 } from 'components/common/icons'
import { useDispatch } from 'react-redux'
import { default as appActions } from 'actions'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles({
  menuItem: {
    height: 50,
    padding: '5px 6px 5px 0px',
    width: 200,
    '&:hover': {
      backgroundColor: 'var(--bg-color-14)'
    }
  },
  menuItemIcon: { paddingTop: 4, paddingRight: 4, width: 40, textAlign: 'center' },
  menuButton: {
    margin: 0,
    marginRight: 5
  }
})

const RuleActionsMenu = ({ ruleId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = useCallback(() => {
    setIsOpen(false)
    setAnchorEl(null)
  }, [])

  const handleClick = useCallback(
    event => {
      trackEvent('Automation rule - Actions menu opened', { ruleId })
      setIsOpen(true)
      setAnchorEl(event.currentTarget)
    },
    [ruleId]
  )

  const handleItemClick = useCallback(
    onClick => {
      handleClose()
      onClick()
    },
    [handleClose]
  )

  const actions = useMemo(
    () => [
      {
        icon: <Pencil4 />,
        label: t('general.edit'),
        onClick: () => {
          trackEvent('Automation rule - Actions menu - Edit rule clicked', { ruleId })
          dispatch(
            appActions.toggleRuleEditorModal({
              isOpen: true,
              isEditMode: true,
              ruleId
            })
          )
        }
      },
      {
        icon: <Trash2 color="var(--text-color-7)" strokeWidth="1" />,
        label: t('general.delete'),
        onClick: () => {
          trackEvent('Automation rule - Actions menu - Delete rule clicked', { ruleId })
          dispatch(
            appActions.toggleDeleteRuleModal({
              isOpen: true,
              ruleId
            })
          )
          handleClose()
        }
      }
    ],
    [dispatch, handleClose, ruleId, t]
  )

  return (
    <Fragment>
      <IconButton onClick={handleClick} className={classes.menuButton}>
        <MoreVert />
      </IconButton>
      <Menu
        transformOrigin={{ vertical: -50, horizontal: 'left' }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        className={classes.menu}
      >
        {actions.map((action, index) => (
          <MenuItem
            id={createIdForTest({ componentId: 'automated-rules', title: action.label })}
            key={action.label}
            onClick={() => handleItemClick(action.onClick)}
            className={classes.menuItem}
          >
            <Grid container alignItems="center" style={{ height: 50 }}>
              <Grid item className={classes.menuItemIcon}>
                {action.icon}
              </Grid>
              <Grid item>{action.label}</Grid>
            </Grid>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  )
}

export default RuleActionsMenu
