import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles, Grid, CircularProgress } from '@material-ui/core'
import DazzedParagraph16 from '../../../../common/text/DazzedParagraph16'
import { useDispatch, useSelector } from 'react-redux'
import { Calendar2, Clock } from 'components/common/icons'
import { useTranslation } from 'react-i18next'
import SelectInput from 'components/common/SelectInput'
import actions from 'actions'
import moment from 'moment'
import CustomDatePicker from 'components/common/CustomDatePicker'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { trackEvent } from 'utils/analyticsUtils'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles({
  title: {
    color: 'var(--text-color-11)',
    marginBottom: 2
  },
  container: {
    marginTop: 10
  },
  header: {
    paddingBottom: '12px !important',
    marginBottom: 15,
    borderBottom: '1px solid var(--border-color-10)'
  },
  datePickerInput: {
    marginBottom: 25
  },
  datePickerLabel: {
    marginBottom: 10
  },
  clock: {
    marginTop: 6
  },
  note: {
    border: '0.5px solid #CED0D4 !important',
    borderRadius: 8
  },
  error: {
    color: 'red'
  },
  selectInput: {
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
})

const DolphinNewAppointment = ({ onDiscard = () => {} }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const [appointmentType, setAppointmentType] = useState('')
  const [location, setLocation] = useState('')
  const [chair, setChair] = useState('')
  const [fromDate, setFromDate] = useState(moment())
  const [toDate, setToDate] = useState(moment().add(1, 'day'))
  const [selectedSlotStartTime, setSelectedSlotStartTime] = useState()
  const [note, setNote] = useState('')

  const {
    appointmentTypes,
    locations,
    chairsByLocations,
    availableTimeSlots,
    isFetchAvailableTimeSlots,
    isSaving,
    currentPatient
  } = useSelector(state => state.pmsReducer)

  const appointmentTypeOptions = useMemo(
    () =>
      appointmentTypes.map(apt => ({
        value: apt.id,
        label: apt.name,
        minutes: apt.minutes
      })),
    [appointmentTypes]
  )
  const locationsOptions = useMemo(() => locations.map(loc => ({ value: loc.id, label: loc.name })), [locations])
  const chairsOptions = useMemo(
    () => [
      { value: 'all', label: t('general.all') },
      ...(location ? chairsByLocations[location].map(chair => ({ value: chair.id, label: chair.name })) : [])
    ],
    [chairsByLocations, location, t]
  )

  const generateTimeSlotLabel = useCallback(
    ({ timeSlot }) =>
      `${moment(timeSlot.startTime).format('DD-MMM, hh:mm a')}-${moment(timeSlot.startTime)
        .add(timeSlot.minutes, 'minutes')
        .format('hh:mm a')} ${chairsByLocations?.[location]?.find(chair => chair.id === timeSlot.chairId)?.name}`,
    [chairsByLocations, location]
  )
  const timeSlotsOptions = useMemo(
    () =>
      availableTimeSlots?.map((timeSlot, index) => ({
        value: timeSlot.startTime,
        label: generateTimeSlotLabel({ timeSlot, location })
      })) || [],
    [availableTimeSlots, generateTimeSlotLabel, location]
  )

  const isLoadingTimeSlots = useMemo(
    () => !availableTimeSlots && !selectedSlotStartTime && isFetchAvailableTimeSlots && chairsByLocations,
    [availableTimeSlots, chairsByLocations, isFetchAvailableTimeSlots, selectedSlotStartTime]
  )

  const handleAppointmentTypeChanged = useCallback(({ value }) => {
    setAppointmentType(value)
  }, [])

  const handleCreateAppointment = useCallback(() => {
    trackEvent('PMS appointments - Create new appointment')

    const chosenTimeSlot = availableTimeSlots.find(slot => slot.startTime === selectedSlotStartTime)
    dispatch(
      actions.pmsCreateNewAppointment({
        date: chosenTimeSlot.date,
        startTime: chosenTimeSlot.startTime,
        endTime: chosenTimeSlot.endTime,
        scheduleColumnId: chosenTimeSlot.chairId,
        patientId: currentPatient.id,
        providerId: currentPatient.providerId,
        appointmentTypeId: appointmentType,
        locationId: location,
        note
      })
    )
  }, [
    availableTimeSlots,
    dispatch,
    currentPatient.id,
    currentPatient.providerId,
    appointmentType,
    location,
    note,
    selectedSlotStartTime
  ])

  const hasFilledAllDetails = useMemo(
    () => appointmentType && chair && fromDate && toDate && selectedSlotStartTime,
    [appointmentType, chair, fromDate, selectedSlotStartTime, toDate]
  )

  useEffect(() => {
    if (!appointmentType && appointmentTypeOptions?.length) {
      handleAppointmentTypeChanged({ value: appointmentTypeOptions[0].value })
    }
  }, [appointmentType, handleAppointmentTypeChanged, appointmentTypeOptions])

  useEffect(() => {
    if (!location && locationsOptions?.length > 0) {
      setLocation(locationsOptions[0].value)
    }
  }, [location, locationsOptions])

  useEffect(() => {
    if (!chair && chairsOptions.length > 0) {
      setChair(chairsOptions[0].value)
    }
  }, [chair, chairsOptions])

  useEffect(() => {
    if (!selectedSlotStartTime && !!availableTimeSlots?.length) {
      setSelectedSlotStartTime(timeSlotsOptions[0].value)
    }
  }, [availableTimeSlots?.length, timeSlotsOptions, selectedSlotStartTime])

  useEffect(() => {
    if (fromDate && fromDate.isAfter(toDate)) {
      setToDate(fromDate.clone().add('day', 1))
    }
  }, [fromDate, toDate])

  useEffect(() => {
    if (location && appointmentType && chair && toDate && fromDate) {
      setSelectedSlotStartTime(null)

      const payload = {
        locationId: location,
        fromDate: moment(fromDate).toISOString(),
        toDate: moment(toDate).toISOString(),
        appointmentTypeId: appointmentType
      }

      if (chair !== 'all') {
        payload.chairId = chair
      }

      trackEvent('Dolphin new appointment - searched for appointment time slots', payload)
      dispatch(actions.pmsFetchAvailableTimeSlots(new URLSearchParams(payload).toString()))
    }
  }, [appointmentType, chair, dispatch, fromDate, location, toDate])

  return (
    <Grid container className={classes.container} spacing={1}>
      <Grid item xs={12} className={classes.header}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>{<Calendar2 />}</Grid>
          <Grid item>
            <DazzedParagraph16 strong className={classes.title}>
              {t('dialogs.patientInfo.appointments.setNewAppointment')}
            </DazzedParagraph16>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item xs={5}>
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item xs={5}>
                <SelectInput
                  placeholder={t('dialogs.patientInfo.appointments.pms.newAppointment.appointmentTypePlaceholder')}
                  label={t('dialogs.patientInfo.appointments.pms.newAppointment.appointmentType')}
                  options={appointmentTypeOptions}
                  value={appointmentType}
                  onChange={handleAppointmentTypeChanged}
                  style={{ bright: true, thick: true }}
                  valueClassName={classes.selectInput}
                />
              </Grid>
              <Grid item xs={4}>
                <SelectInput
                  placeholder={t('dialogs.patientInfo.appointments.pms.newAppointment.appointmentLocationPlaceholder')}
                  label={t('dialogs.patientInfo.appointments.pms.newAppointment.appointmentLocation')}
                  options={locationsOptions}
                  value={location}
                  onChange={({ value }) => setLocation(value)}
                  style={{ bright: true, thick: true }}
                  valueClassName={classes.selectInput}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectInput
                  placeholder={t('dialogs.patientInfo.appointments.pms.newAppointment.appointmentChairPlaceholder')}
                  label={t('dialogs.patientInfo.appointments.pms.newAppointment.appointmentChair')}
                  options={chairsOptions}
                  value={chair}
                  onChange={({ value }) => setChair(value)}
                  style={{ bright: true, thick: true }}
                  valueClassName={classes.selectInput}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item xs={3}>
                <CustomDatePicker
                  disabled={!chair}
                  label={t('dialogs.patientInfo.appointments.pms.newAppointment.appointmentFrom')}
                  inputVariant="outlined"
                  value={fromDate}
                  handleDateChange={newDate => setFromDate(moment(newDate))}
                  className={classes.datePickerInput}
                  disablePast
                  labelClassName={classes.datePickerLabel}
                  format="dd-MMM"
                />
              </Grid>
              <Grid item xs={3}>
                <CustomDatePicker
                  disabled={!chair}
                  label={t('dialogs.patientInfo.appointments.pms.newAppointment.appointmentTo')}
                  inputVariant="outlined"
                  value={toDate}
                  handleDateChange={newDate => setToDate(moment(newDate))}
                  className={classes.datePickerInput}
                  disablePast
                  labelClassName={classes.datePickerLabel}
                  format="dd-MMM"
                />
              </Grid>
              <Grid item xs={6}>
                {isLoadingTimeSlots ? (
                  <CircularProgress size={30} />
                ) : (
                  <SelectInput
                    disabled={!fromDate || !toDate || !chair}
                    label={t('dialogs.patientInfo.appointments.pms.newAppointment.appointmentAvailableSlots')}
                    options={timeSlotsOptions}
                    value={selectedSlotStartTime}
                    onChange={({ value: timeSlotStartTime }) => setSelectedSlotStartTime(timeSlotStartTime)}
                    style={{ bright: true, thick: true }}
                    iconComponent={<Clock className={classes.clock} />}
                    labelWrap="nowrap"
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {availableTimeSlots?.length === 0 ? (
          <DazzedParagraph14 className={classes.error}>
            {t('dialogs.patientInfo.appointments.pms.newAppointment.noAvailableTimeslots')}
          </DazzedParagraph14>
        ) : (
          ''
        )}
      </Grid>
      <Grid item xs={12}>
        <TextAreaAutosize
          rowsMax={4}
          style={{ bright: true }}
          className={classes.note}
          inputStyle={{
            margin: 0,
            height: 70,
            padding: 10
          }}
          placeholder={t('dialogs.notes.addNote')}
          value={note}
          setValue={setNote}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item>
            <SecondaryButton label={t('general.discard')} onClick={onDiscard} width="120px" />
          </Grid>
          <Grid item>
            <PrimaryButton
              disabled={!hasFilledAllDetails}
              label={t('general.save')}
              onClick={handleCreateAppointment}
              width="120px"
              isLoading={hasFilledAllDetails && isSaving}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DolphinNewAppointment
