import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Actions from 'actions'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { Grid } from '@material-ui/core'
import FormInput from 'components/common/FormInput'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import FilterTagsList from 'components/common/tags/FilterTagsList'
import { trackEvent } from 'utils/analyticsUtils'
import { TAGS_LIST_COMPRESSION_THRESHOLD_DENSE } from 'utils/tagUtils'
import usePatientsFilterData from './hooks/usePatientsFilterData'
import { AsyncStatus } from 'consts'

const useStyles = makeStyles({
  modal: {
    minWidth: 900,
    minHeight: 425
  },
  title: {
    padding: '15px 20px 0px 20px !important',
    fontSize: 20,
    alignItems: 'flex-start !important'
  },
  nameInput: {
    width: '250px'
  },
  content: {
    paddingLeft: 20
  },
  divider: {
    opacity: 0.75,
    height: 1,
    width: '100%',
    backgroundColor: 'var(--border-color-35)'
  }
})

const NewSavedViewModal = ({ currentSelectedTags }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { mapSelectedFiltersToSearchInput } = usePatientsFilterData()
  const { isOpen, status } = useSelector(state => state.practiceReducer.savedViews.newSavedViewModal)

  const [name, setName] = useState('')
  const [selectedFilters, setSelectedFilters] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  const parsedTags = useMemo(() => {
    let parsedTags = []
    Object.keys(selectedFilters).forEach(filter => (parsedTags = [...parsedTags, ...selectedFilters[filter]]))

    return parsedTags
  }, [selectedFilters])
  const hasAnyFilters = useMemo(() => !!parsedTags.length, [parsedTags])
  const isValid = useMemo(() => !!name, [name])

  const handleClose = useCallback(() => {
    trackEvent('New saved view modal - Canceled')
    dispatch(Actions.toggleNewSavedViewModal(true))
  }, [dispatch])

  const handleSubmit = useCallback(() => {
    if (!isValid) {
      setErrorMessage(t('dialogs.newSavedViewModal.missingNameError'))
      return
    }

    const parsesFilters = mapSelectedFiltersToSearchInput({ selectedFilters })

    trackEvent('New saved view modal - Saved a new view', {
      name,
      filters: parsesFilters
    })
    dispatch(
      Actions.createNewSavedView({
        name,
        filters: parsesFilters
      })
    )

    dispatch(Actions.toggleNewSavedViewModal(true))
  }, [dispatch, isValid, mapSelectedFiltersToSearchInput, name, selectedFilters, t])

  const handleClearTag = useCallback(
    tag => {
      trackEvent('New saved view modal - Clear tag', {
        value: tag.value
      })

      const updatedFilteres = {
        ...selectedFilters,
        [tag.type]: selectedFilters[tag.type].filter(currTag => currTag.value !== tag.value)
      }

      setSelectedFilters(updatedFilteres)
    },
    [selectedFilters]
  )

  useEffect(() => {
    if (isValid && errorMessage) {
      setErrorMessage('')
    }
  }, [errorMessage, isValid, name])

  useEffect(() => {
    if (isOpen) {
      setSelectedFilters({ ...currentSelectedTags })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (!isOpen) {
      setSelectedFilters([])
      setName('')
      setErrorMessage('')
    }
  }, [isOpen])

  return (
    <BaseModal
      open={isOpen}
      title={t('dialogs.newSavedViewModal.title')}
      titleClassName={classes.title}
      secondaryLabel={t('general.cancel')}
      primaryLabel={t('general.confirm')}
      className={classes.modal}
      variant="alert"
      handleClose={handleClose}
      onSecondaryBtnClick={handleClose}
      onPrimaryBtnClick={handleSubmit}
      isPrimaryDisabled={!hasAnyFilters}
      isLoading={status === AsyncStatus.Loading}
    >
      <Grid container direction="column" className={classes.content} spacing={2}>
        <Grid item>
          <FormInput
            value={name}
            setValue={setName}
            errorMessage={errorMessage}
            style={{ bright: true, thick: true, borderBottom: true }}
            placeholder={t('dialogs.newSavedViewModal.namePlaceholder')}
            containerClassName={classes.nameInput}
          />
        </Grid>
        <Grid item>
          <div className={classes.divider} />
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <DazzedParagraph14 bold>{t('dialogs.newSavedViewModal.selectedFilters')}</DazzedParagraph14>
            </Grid>
            {!!parsedTags.length && (
              <Grid item>
                <FilterTagsList
                  showIcon={false}
                  tags={parsedTags}
                  canDeleteCustom={true}
                  canDeleteSystem={true}
                  observeResize={false}
                  onDeleteTag={handleClearTag}
                  compressionThreshold={TAGS_LIST_COMPRESSION_THRESHOLD_DENSE}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default NewSavedViewModal
