import axios from 'axios'
import { CHECK_AVAILABILITY_ENDPOINT } from 'consts/appConsts'
import { logError, logWarn } from './logUtils'

export const initApp = async (history, callback) => {
  try {
    const localIsAppAvailable = localStorage.getItem('isAppAvailable')
    const response = await axios.request({
      timeout: 1000,
      method: 'GET',
      url: `${CHECK_AVAILABILITY_ENDPOINT}?${localIsAppAvailable ? `isAppAvailable=${localIsAppAvailable}` : ''}`
    })

    const isAppAvailable = response.data.isAppAvailable
    callback({ isAppAvailable })

    if (!isAppAvailable) {
      logWarn('Webapp is unavailable - received service is unavailable from external service')
      history.push('/unavailable')
    }
  } catch (ex) {
    logError('An error occured while checking app availability', { ex })
    callback({ isAppAvailable: true })
  }
}
