import React, { useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import Actions from 'actions'
import { Table, TableBody, TableHead, TableRow, TableCell, Grid, CircularProgress } from '@material-ui/core'
import MembersTableRow from './MembersTableRow'

const useStyles = makeStyles({
  table: {
    marginBottom: 40
  },
  header: {
    backgroundColor: 'var(--bg-color-14)',
    color: 'var(--text-color-6)',
    fontSize: '12px'
  },
  headerCell: {
    borderTop: '0.5px solid var(--border-color-13)',
    borderBottom: '0.5px solid var(--border-color-13)'
  }
})

const MembersTable = () => {
  const dispatch = useDispatch()

  const { data: practiceMembers, isLoading } = useSelector(state => state.profileReducer.practiceMembers)

  useEffect(() => {
    if (!practiceMembers) {
      dispatch(Actions.requestPracticeMembers())
    }
  }, [dispatch, practiceMembers])

  const classes = useStyles()
  const { t } = useTranslation()
  const HEADERS = useMemo(
    () => [
      { key: 'name', label: t('pages.accountSettings.practiceMembers.table.headers.name') },
      { key: 'accessType', label: t('pages.accountSettings.practiceMembers.table.headers.accessType') },
      { key: 'jobTitle', label: t('pages.accountSettings.practiceMembers.table.headers.jobTitle') },
      { key: 'dateAdded', label: t('pages.accountSettings.practiceMembers.table.headers.dateAdded') },
      { key: 'status', label: t('pages.accountSettings.practiceMembers.table.headers.status') },
      { key: 'actions', label: '' }
    ],
    [t]
  )

  return isLoading ? (
    <Grid container justifyContent="center">
      <CircularProgress color="secondary" />
    </Grid>
  ) : (
    <Table className={classes.table}>
      <TableHead className={classes.header}>
        <TableRow>
          {HEADERS.map(({ label, key }) => (
            <TableCell key={key} className={classes.headerCell}>
              {label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {practiceMembers?.map(member => (
          <MembersTableRow
            key={member.id}
            member={member}
            colums={HEADERS}
            onChangeAccessType={accessType => {
              dispatch(
                Actions.changePracticeMemberAccess({
                  id: member.id,
                  accessType
                })
              )
            }}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default MembersTable
