import * as React from 'react'

function CheckCircle({ color = 'black', ...props }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 20C4.48587 20 0 15.5137 0 10C0 4.48587 4.48587 0 10 0C15.5137 0 20 4.48587 20 10C20 15.5137 15.5137 20 10 20ZM10 0.999979C5.03757 0.999979 0.999979 5.03757 0.999979 10C0.999979 14.9629 5.03757 19 10 19C14.9629 19 19 14.963 19 10C19 5.03757 14.9629 0.999979 10 0.999979Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4999 7.50046C13.7051 7.68524 13.7218 8.0014 13.5371 8.20655L9.72401 12.4432C9.34084 12.869 8.67876 12.8864 8.27364 12.4813L6.49993 10.7074C6.30471 10.5122 6.30471 10.1956 6.49993 10.0003C6.69516 9.80497 7.01174 9.80497 7.20707 10.0003L8.60827 11.4015C8.81088 11.604 9.14182 11.5952 9.33345 11.3824L12.7938 7.5375C12.9785 7.33236 13.2946 7.31569 13.4999 7.50046V7.50046Z"
        fill={color}
      />
    </svg>
  )
}

export default CheckCircle
