import * as React from 'react'

function SvgCheckmark({ color = '#3C52EF', props }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9C18 13.9704 13.971 18 8.99961 18C4.02978 18 0 13.9704 0 9C0 4.02961 4.02978 0 8.99961 0C13.971 0 18 4.02961 18 9ZM13.3754 7.17462L8.49327 12.3535L5.29102 9.48045L6.5682 8.05691L8.38159 9.68387L11.9838 5.86273L13.3754 7.17462Z"
        fill={color}
      />
    </svg>
  )
}

export default SvgCheckmark
