import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import ScanSummaryCollapsibleSection from './ScanSummaryCollapsibleSection'
import { SCAN_SUMMARY_SECTIONS } from 'consts/scanSummaryConsts'

const useStyles = makeStyles(theme => ({
  root: {
    height: '500px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    flexWrap: 'nowrap',
    paddingRight: 10,
    marginRight: 10
  }
}))

const ScanSummaryPanels = ({
  className,
  sections = [],
  currentSectionId,
  onSectionSelected = () => {},
  preventiveProps = {},
  treatmentTrackingProps = {}
}) => {
  const classes = useStyles()

  const sectionProps = useMemo(() => {
    switch (currentSectionId) {
      case SCAN_SUMMARY_SECTIONS.PREVENTIVE:
        return preventiveProps
      case SCAN_SUMMARY_SECTIONS.TREATMENT_TRACKING:
        return treatmentTrackingProps
      default:
        return {}
    }
  }, [currentSectionId, preventiveProps, treatmentTrackingProps])

  return sections.length ? (
    <Grid container direction="column" className={[className || '', classes.root].join(' ')}>
      {sections.map(section => (
        <Grid item key={`scan-summary-section-${section.id}`}>
          <ScanSummaryCollapsibleSection
            id={section.id}
            onSelect={onSectionSelected}
            label={section.label}
            isOpen={currentSectionId === section.id}
          >
            {React.createElement(section.component, sectionProps)}
          </ScanSummaryCollapsibleSection>
        </Grid>
      ))}
    </Grid>
  ) : (
    <></>
  )
}

export default ScanSummaryPanels
