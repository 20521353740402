import { useEffect, useCallback } from 'react'
import { detect } from 'detect-browser'
import { useLocation } from 'react-router'
import { isMobile } from 'utils/mobileUtils'

export default forceScaleToMobile => {
  const browser = detect()
  const location = useLocation()

  const browserClass = { className: 'mobile', height: '100vh' }

  switch (browser && browser.name) {
    case 'chrome':
      browserClass.className = 'mobile-chrome'
      browserClass.height = '92.7vh'
      break
    case 'ios':
    case 'safari':
      browserClass.className = 'mobile-safari'
      browserClass.height = '85vh'
      break
    case 'crios':
      browserClass.className = 'mobile-chrome-ios'
      browserClass.height = '88vh'
      break
    default:
      break
  }

  if (isMobile() && location.pathname.includes('auth/rc')) {
    browserClass.className += browserClass.className + ' rc-mobile'
  }

  if (isMobile() && location.pathname.includes('referral')) {
    browserClass.className += browserClass.className + ' referral-mobile'
  }

  const resetClassesAndAttributes = useCallback(() => {
    document.querySelector('html').className = ''
    document.getElementById('mobile-viewport-tag').setAttribute('content', 'width=device-width, initial-scale=0.3')
  }, [])

  useEffect(() => {
    if (isMobile() || forceScaleToMobile) {
      document
        .getElementById('mobile-viewport-tag')
        .setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0')
      document.querySelector('html').className = browserClass.className
      return () => {
        resetClassesAndAttributes()
      }
    } else {
      resetClassesAndAttributes()
    }
  }, [browserClass.className, forceScaleToMobile, resetClassesAndAttributes])

  return browserClass
}
