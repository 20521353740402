import React, { useCallback } from 'react'
import { useAssignPatient } from 'components/Patients/AssignPatient/useAssignPatient'
import AssignPatientForm from 'components/Patients/AssignPatient/AssignPatientForm'
import { actionTypes } from 'consts/automatedActionConsts'

const AssignPatient = ({ onChange, params, errors }) => {
  const { practiceMembersSelectOptions } = useAssignPatient({ withUnassignOption: false })

  const handleChange = useCallback(
    ({ assigneeId, assigneeName, note }) => {
      onChange({
        type: actionTypes.AssignPatient,
        params: {
          assigneeId,
          assigneeName,
          note
        }
      })
    },
    [onChange]
  )

  const handleSelectAssignee = useCallback(
    assigneeId => {
      const selectedOption = practiceMembersSelectOptions.find(opt => opt.value === assigneeId)
      handleChange({ assigneeId, assigneeName: selectedOption?.label, note: params?.note || '' })
    },
    [handleChange, practiceMembersSelectOptions, params]
  )

  const handleNoteChanged = useCallback(
    note => {
      handleChange({
        ...(params || {}),
        note
      })
    },
    [params, handleChange]
  )

  return (
    <AssignPatientForm
      assignee={params?.assigneeId || ''}
      note={params?.note || ''}
      onChangeAssignee={({ value }) => handleSelectAssignee(value)}
      onChangeNote={handleNoteChanged}
      practiceMembersSelectOptions={practiceMembersSelectOptions}
      assignPatientCentered={false}
      selectInputProps={{
        selectStyle: { width: '100%' },
        keepErrorContainerWhenInactive: false,
        errorMessage: errors?.actions?.assignPatient
      }}
    />
  )
}

export default AssignPatient
