import * as React from 'react'

export default () => {
  return (
    <svg width="19" height="26" viewBox="0 0 19 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.11684 6.87885L5.39438 6.84939L5.50613 6.61627C6.32142 4.91533 8.74313 4.91533 9.55842 6.61627L9.67017 6.84939V18.5701L9.35842 18.9671C8.22965 20.4049 5.94865 19.9701 5.42792 18.2179L4.28331 18.0453C2.74006 17.8126 1.84935 16.1667 2.49864 14.7475L3.0972 13.4391L2.97082 13.3137C1.96303 12.314 2.16572 10.6327 3.38225 9.90112L3.32524 9.74363C2.85104 8.43354 3.73136 7.02591 5.11684 6.87885Z"
        stroke="#5532DB"
        strokeLinecap="round"
      />
      <path
        d="M7.71094 19.8464C6.36438 19.8464 5.27279 18.7548 5.27279 17.4082C5.27279 16.6105 5.65585 15.9023 6.24805 15.4575"
        stroke="#5532DB"
        strokeLinecap="round"
      />
      <path
        d="M6.97892 10.7026C6.33109 10.3285 6.10912 9.50016 6.48315 8.85232C6.70472 8.46855 7.08573 8.23423 7.49419 8.18472"
        stroke="#5532DB"
        strokeLinecap="round"
      />
      <path d="M3.11267 13.3436C3.5115 12.6528 4.19735 12.231 4.93262 12.1418" stroke="#5532DB" strokeLinecap="round" />
      <path d="M9.66821 1V24.6141" stroke="#5532DB" strokeLinecap="round" strokeDasharray="2.08 2.08" />
      <path d="M14.1827 6.20898L11.7518 8.63985H9.66821" stroke="#5532DB" />
      <path d="M14.1827 19.405L11.7518 16.9742H9.66821" stroke="#5532DB" />
      <path d="M16.9608 12.8071H9.66821" stroke="#5532DB" />
      <path d="M16.9608 8.98706H14.5299L12.4463 11.0707H9.66821" stroke="#5532DB" />
      <path d="M16.9608 16.627H14.5299L12.4463 14.5434H9.66821" stroke="#5532DB" />
      <circle cx="14.1819" cy="6.20903" r="1.0418" fill="white" stroke="#5532DB" />
      <circle cx="14.1819" cy="19.4051" r="1.0418" fill="white" stroke="#5532DB" />
      <circle cx="16.9566" cy="8.98711" r="1.0418" fill="white" stroke="#5532DB" />
      <circle cx="16.9566" cy="16.627" r="1.0418" fill="white" stroke="#5532DB" />
      <circle cx="16.9566" cy="12.8072" r="1.0418" fill="white" stroke="#5532DB" />
    </svg>
  )
}
