import Actions from '../actions'

const initialState = {
  regenerateScanModal: {
    isOpen: false,
    grinScanId: null,
    isLoading: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.QA_TOGGLE_REGENERATE_SCAN_MODAL:
      return {
        ...state,
        regenerateScanModal: {
          ...state.regenerateScanModal,
          isOpen: action.payload.isOpen,
          grinScanId: action.payload.isOpen ? action.payload?.grinScanId : null
        }
      }
    case Actions.QA_REGENERATE_SCAN:
      return {
        ...state,
        regenerateScanModal: {
          ...state.regenerateScanModal,
          isLoading: true
        }
      }
    case Actions.QA_REGENERATE_SCAN_RECEIVED:
      return {
        ...state,
        regenerateScanModal: initialState.regenerateScanModal
      }
    case Actions.QA_REGENERATE_SCAN_FAILED:
      return {
        ...state,
        regenerateScanModal: {
          ...state.regenerateScanModal,
          isLoading: false
        }
      }
    default:
      return state
  }
}
