import moment from 'moment'
import { Grid, makeStyles } from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles({
  clickable: {
    cursor: 'pointer',
    color: 'var(--text-color-25)',
    textDecoration: 'underline'
  }
})

const RCShippingStatus = ({ lead, withAdminActions = false }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const shippingDetails = useMemo(() => lead.rcData?.shippingDetails, [lead])
  const handleOpenTrackingUrl = useCallback(() => {
    trackEvent('RC lead shipment status click', {
      target: 'trackingUrl',
      leadId: lead.id,
      url: shippingDetails.trackingUrl
    })
    window.open(shippingDetails.trackingUrl, '_blank')
  }, [lead, shippingDetails])

  const shippingStatusLabel = useMemo(
    () =>
      t(
        `pages.rcDashboard.dialogs.shippingDetails.shippingStatuses.${
          lead.shippingStatus === 'shipmentRequired' && !withAdminActions ? `processing` : lead.shippingStatus
        }`
      ),
    [lead, t, withAdminActions]
  )

  return lead.shippingStatus ? (
    <Grid container direction="column">
      <Grid item>
        <DazzedParagraph14 strong>{shippingStatusLabel}</DazzedParagraph14>
      </Grid>
      <Grid item>
        {lead.shippingStatus === 'shipped'
          ? shippingDetails?.trackingUrl && (
              <Grid container>
                <Grid item>
                  <DazzedParagraph14>{t(`pages.rcDashboard.table.trackingNumber`)}&nbsp;</DazzedParagraph14>
                </Grid>
                <Grid item>
                  <DazzedParagraph14 onClick={handleOpenTrackingUrl} className={classes.clickable}>
                    {shippingDetails.trackingNumber}
                  </DazzedParagraph14>
                </Grid>
              </Grid>
            )
          : lead.shippingStatus === 'delivered' && (
              <DazzedParagraph14>{moment(shippingDetails.packageDeliveryDate).format('ll')}</DazzedParagraph14>
            )}
      </Grid>
    </Grid>
  ) : (
    '-'
  )
}

export default RCShippingStatus
