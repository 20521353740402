import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(({ color }) => ({
  root: {
    fontFamily: 'Dazzed',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    color: ({ color }) => color ?? '#000'
  },
  strong: {
    fontWeight: '500 !important'
  },
  bold: {
    fontWeight: '600 !important'
  }
}))

const DazzedParagraph14 = ({
  children,
  className,
  strong = false,
  bold = false,
  color,
  textAlign = 'left',
  ...props
}) => {
  const classes = useStyles({ color })

  return (
    <div
      style={{ textAlign }}
      className={[classes.root, strong && classes.strong, bold && classes.bold, className].join(' ')}
      {...props}
    >
      {children}
    </div>
  )
}

export default DazzedParagraph14
