import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import useAppliedPatientsFilter from 'components/Search/hooks/useAppliedPatientsFilter'

const useStyles = () =>
  makeStyles(theme => ({
    title: {
      marginTop: 50,
      marginBottom: 10
    },
    subtitle: {
      color: 'var(--text-color-11)',
      maxWidth: 230,
      textAlign: 'center'
    },
    clearAll: {
      cursor: 'pointer',
      marginTop: 20,
      paddingBottom: 10,
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    }
  }))()

const FilteredEmptyState = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { applyFilters } = useAppliedPatientsFilter()

  const handleClearAll = useCallback(() => applyFilters({}), [applyFilters])

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <DazzedParagraph16 className={classes.title} strong>
          {t('pages.patients.patientsList.oops')}
        </DazzedParagraph16>
      </Grid>
      <Grid item>
        <DazzedParagraph12 className={classes.subtitle}>
          {t('pages.patients.patientsList.noPatientMathingCriteria')}
        </DazzedParagraph12>
      </Grid>
      <DazzedParagraph14 strong={true} className={classes.clearAll} onClick={handleClearAll}>
        {t('pages.patients.patientsList.patientsTagFilter.clearLabel')}
      </DazzedParagraph14>
    </Grid>
  )
}

export default FilteredEmptyState
