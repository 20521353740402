import React, { useCallback, useMemo } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ReferenceLine } from 'recharts'
import { useTranslation } from 'react-i18next'
import useTeethTracking from '../useTeethTracking'
import moment from 'moment'
import { rangeWithStep } from 'utils/arrayUtils'
import { generateMonthsRange } from 'utils/dateUtils'
import GraphDot from './GraphDot'

const REFERENCE_LINE_COLOR = '#F25700'

const ToothProgressGraph = ({ toothData, toothNumber, shouldDisplayTotalProgress }) => {
  const { t } = useTranslation()
  const { txTrackerDataByTeeth, getTeethTrackingStatus } = useTeethTracking()

  // 0 to 5 in steps of 0.5
  const yAxisTicks = useMemo(() => rangeWithStep({ end: 2, step: 0.25 }), [])

  const toothDataOverTime = useMemo(() => txTrackerDataByTeeth[toothNumber], [toothNumber, txTrackerDataByTeeth])

  // Generating all months passed from treatment start until latest treatment update
  const xAxisTicks = useMemo(
    () =>
      generateMonthsRange({
        originDate: toothDataOverTime[0].createdAt,
        targetDate: toothDataOverTime[toothDataOverTime.length - 1].createdAt
      }),
    [toothDataOverTime]
  )

  const graphData = useMemo(
    () =>
      toothDataOverTime.map(({ createdAt, currentProgress, deviation, pastMonthSpeed }) => ({
        createdAt: moment(createdAt).valueOf(),
        value: currentProgress,
        trackingStatus: getTeethTrackingStatus({
          deviation,
          pastMonthSpeed
        })
      })),
    [getTeethTrackingStatus, toothDataOverTime]
  )

  const monthTickFormatter = useCallback(tick => moment(tick).format('MMM'), [])

  return (
    <LineChart
      width={600}
      height={400}
      data={graphData}
      margin={{
        top: 10,
        left: 10
      }}
    >
      <CartesianGrid stroke="#AEC7E1" strokeOpacity={0.5} fill="#F7FBFF" />
      <XAxis
        dataKey="createdAt"
        scale="time"
        type="number"
        domain={[graphData[0].createdAt, graphData[graphData.length - 1].createdAt]}
        ticks={xAxisTicks}
        tickFormatter={monthTickFormatter}
      />
      <YAxis type="number" width={25} ticks={yAxisTicks} domain={['dataMin', 'dataMax']} />
      {shouldDisplayTotalProgress && (
        <ReferenceLine
          y={toothData.totalPlannedMovement}
          stroke={REFERENCE_LINE_COLOR}
          label={{
            position: 'insideTopLeft',
            value: t('dialogs.treatmentTracker.teethTracking.table.tooltip.txGoal'),
            fill: REFERENCE_LINE_COLOR,
            fontSize: 14,
            fontWeight: 600
          }}
        />
      )}
      <Line type="monotone" dataKey="value" stroke="var(--text-color-25)" dot={<GraphDot />} />
    </LineChart>
  )
}

export default ToothProgressGraph
