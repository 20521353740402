import * as React from 'react'

const Pencil4 = props => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0858 4.41421C12.8668 3.63316 14.1332 3.63317 14.9142 4.41421L15.6213 5.12132C16.4024 5.90237 16.4024 7.1687 15.6213 7.94975L7.84315 15.7279L4.56501 15.4705L4.30761 12.1924L12.0858 4.41421Z"
        stroke="#282828"
      />
      <path d="M10.9769 5.56152L14.5125 9.09706" stroke="#282828" />
    </svg>
  )
}

export default Pencil4
