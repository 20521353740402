import actions from '../actions'
import Actions from '../actions'
import { replaceItem } from '../utils/arrayUtils'
import { updateSelectedDoctor } from 'utils/mappers/doctorsMappers'
import { updateWhere } from 'utils/arrayUtils'
import { AsyncStatus } from 'consts'

const initialState = {
  allowedDoctors: [],
  doctors: [],
  patients: [],
  isLoading: false,
  subscriptions: {
    isLoading: false,
    data: {}
  },
  doctorsDashboard: {
    isLoading: false,
    isChangingEmail: false,
    selectedDoctor: null,
    practiceMembers: {
      isLoading: false,
      data: null
    },
    seats: {
      isLoading: false,
      data: null
    },
    notes: {
      isLoading: false,
      isCreating: false,
      data: null
    },
    transactions: {
      isLoading: false,
      data: null
    }
  },
  patientsDashboard: {
    isChangingEmail: false,
    selectedPatient: {
      data: null,
      isLoading: false
    },
    search: {
      term: null,
      isLoading: false,
      results: [],
      error: null
    },
    dashboardUrl: {
      isLoading: false,
      url: null
    },
    authDetails: {
      isLoading: false,
      data: null
    }
  },
  userGroups: {
    data: [],
    isLoading: false
  },
  announcements: {
    list: {
      isLoading: false,
      data: []
    },
    isSaving: false
  },
  billingDashboard: {
    filters: {
      allPlans: false,
      month: null
    },
    data: {},
    isLoading: false,
    error: null
  },
  opsActions: {
    skipConfirmationCode: {
      isLoading: false
    }
  },
  articlesDashboard: {
    isSaving: false
  },
  scanSummaryVersions: {
    loadingState: null,
    data: []
  },
  suppressionListActions: {
    deleteEmailStatus: null,
    deleteEmailError: null
  },
  hiPracticesDashboard: {
    practices: null,
    status: null,
    updateHiGroupsModal: {
      isOpen: false,
      isLoading: false
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.SUPPORT_UPDATE_PATIENT_DETAILS:
      return {
        ...state,
        isLoading: true,
        patientsDashboard: {
          ...state.patientsDashboard,
          selectedPatient: {
            ...state.patientsDashboard.selectedPatient,
            data: {
              ...state.patientsDashboard.selectedPatient.data,
              patient: {
                ...(state.patientsDashboard.selectedPatient.data.patient || {}),
                ...action.payload
              }
            }
          }
        }
      }
    case Actions.REQUEST_ALLOWED_DOCTORS:
    case Actions.REQUEST_CREATE_ALLOWED_DOCTOR:
    case Actions.SUPPORT_REQUEST_OWNER_DOCTORS:
    case Actions.SUPPORT_REQUEST_DOCTORS:
    case Actions.SUPPORT_UPDATE_PATIENT_FF:
      return {
        ...state,
        isLoading: true
      }
    case Actions.CREATE_ALLOWED_DOCTOR_FAILED:
    case Actions.SUPPORT_UPDATE_PATIENT_DETAILS_FAILED:
    case Actions.SUPPORT_UPDATE_PATIENT_FF_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.SUPPORT_REQUEST_DOCTORS_RECEIVED:
      return {
        ...state,
        doctors: action.payload,
        isLoading: false,
        practiceMembers: initialState.practiceMembers
      }
    case Actions.ALLOWED_DOCTORS_RECEIVED:
      return {
        ...state,
        allowedDoctors: action.payload,
        isLoading: false
      }
    case Actions.UPDATE_DOCTOR_PLANS_RECEIVED:
      return {
        ...state,
        allowedDoctors: [action.payload].concat(state.allowedDoctors.filter(d => d.id !== action.payload.id)),
        isLoading: false
      }
    case Actions.CREATE_ALLOWED_DOCTOR_RECEIVED:
      return {
        ...state,
        allowedDoctors: [action.payload, ...state.allowedDoctors],
        isLoading: false
      }
    case Actions.REQUEST_IMPORT_ALLOWED_DOCTOR:
      return {
        ...state,
        isLoading: true
      }
    case Actions.IMPORT_ALLOWED_DOCTOR_RECEIVED:
      return {
        ...state,
        allowedDoctors: [...action.payload.allowedDoctors, ...state.allowedDoctors],
        isLoading: false
      }
    case Actions.FETCH_DOCTOR_SEATS_BY_USERNAME:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          seats: {
            ...state.seats,
            isLoading: true
          }
        }
      }
    case Actions.FETCH_DOCTOR_SEATS_BY_USERNAME_RECEIVED:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          seats: {
            ...state.seats,
            isLoading: true,
            data: action.payload
          }
        }
      }
    case Actions.FETCH_DOCTOR_SEATS_BY_USERNAME_FAILED:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          seats: {
            ...state.seats,
            isLoading: false
          }
        }
      }
    case Actions.FETCH_DOCTOR_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          isLoading: true
        }
      }
    case Actions.DOCTOR_SUBSCRIPTION_RECEIVED:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          isLoading: false,
          data: {
            ...state.subscriptions.data,
            ...(action.payload.metadata && {
              [action.payload.metadata.doctorId]: action.payload
            })
          }
        }
      }
    case Actions.DOCTOR_SUBSCRIPTION_FAILED:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          isLoading: false
        }
      }
    case Actions.FETCH_DOCTOR_TRANSACTIONS_BY_USERNAME:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          isLoading: true
        }
      }
    case Actions.FETCH_DOCTOR_TRANSACTIONS_BY_USERNAME_RECEIVED:
      const { username, transactions } = action.payload
      return {
        ...state,
        transactions: {
          ...state.transactions,
          isLoading: false,
          data: {
            ...state.transactions.data,
            [username]: transactions
          }
        }
      }
    case Actions.FETCH_DOCTOR_TRANSACTIONS_BY_USERNAME_FAILED:
      return {
        transactions: {
          ...state.transactions,
          isLoading: false
        }
      }

    case Actions.CHANGE_DOCTOR_EMAIL:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          isChangingEmail: true
        }
      }
    case Actions.CHANGE_DOCTOR_EMAIL_FAILED:
    case Actions.CHANGE_DOCTOR_EMAIL_RECEIVED:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          isChangingEmail: false
        }
      }
    case Actions.CHANGE_PATIENT_EMAIL:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          isChangingEmail: true
        }
      }
    case Actions.CHANGE_PATIENT_EMAIL_FAILED:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          isChangingEmail: false
        }
      }
    case Actions.CHANGE_PATIENT_EMAIL_RECEIVED:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          isChangingEmail: false,
          selectedPatient: {
            ...state.patientsDashboard.selectedPatient,
            data: {
              ...state.patientsDashboard.selectedPatient.data,
              patient: {
                ...state.patientsDashboard.selectedPatient.data.patient,
                _version: state.patientsDashboard.selectedPatient.data.patient._version + 1,
                details: {
                  ...state.patientsDashboard.selectedPatient.data.patient.details,
                  email: action.payload.newEmail
                }
              }
            }
          }
        }
      }
    case Actions.FETCH_USER_GROUPS:
      return {
        ...state,
        userGroups: {
          ...state.userGroups,
          isLoading: true
        }
      }
    case Actions.FETCH_USER_GROUPS_RECEIVED: {
      return {
        ...state,
        userGroups: {
          ...state.userGroups,
          data: action.payload?.sort((a, b) => a.displayName?.localeCompare(b.displayName)),
          isLoading: false
        }
      }
    }
    case actions.FETCH_USER_GROUPS_FAILED: {
      return {
        ...state,
        userGroups: {
          ...state.userGroups,
          isLoading: false
        }
      }
    }
    case actions.SUPPORT_SEARCH_PATIENT: {
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          search: {
            ...state.patientsDashboard.search,
            isLoading: !!action.payload,
            error: null,
            term: action.payload,
            results: action.payload ? state.patientsDashboard.search.results : []
          }
        }
      }
    }
    case actions.SUPPORT_SEARCH_PATIENT_RECEIVED: {
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          search: {
            ...state.patientsDashboard.search,
            isLoading: false,
            error: null,
            results: action.payload
          }
        }
      }
    }
    case actions.SUPPORT_SEARCH_PATIENT_FAILED: {
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          search: {
            ...state.patientsDashboard.search,
            isLoading: false,
            error: true,
            results: []
          }
        }
      }
    }
    case actions.SUPPORT_CLEAR_PATIENTS_SEARCH_RESULTS: {
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          search: initialState.patientsDashboard.search
        }
      }
    }
    case Actions.SUPPORT_UPDATE_PATIENT_DETAILS_RECEIVED: {
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          search: {
            ...state.patientsDashboard.search,
            error: null,
            results: [],
            isLoading: false
          },
          selectedPatient: {
            ...state.patientsDashboard.selectedPatient,
            patient: action.payload
          }
        }
      }
    }
    case Actions.SUPPORT_UPDATE_PATIENT_FF_RECEIVED: {
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          search: {
            ...state.patientsDashboard.search,
            error: null,
            results: [],
            isLoading: false
          },
          selectedPatient: {
            ...state.patientsDashboard.selectedPatient,
            data: {
              ...state.patientsDashboard.selectedPatient.data,
              featureFlags: JSON.parse(action.payload.flags || '{}'),
              patient: {
                ...state.patientsDashboard.selectedPatient.data.patient,
                user: {
                  ...state.patientsDashboard.selectedPatient.data.patient.user,
                  featureFlags: action.payload
                }
              }
            }
          }
        }
      }
    }
    case actions.SUPPORT_SET_SELECTED_PATIENT: {
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          selectedPatient: {
            ...state.patientsDashboard.selectedPatient,
            data: action.payload
          }
        }
      }
    }
    case actions.SUPPORT_FETCH_ANNOUNCEMENTS: {
      return {
        ...state,
        announcements: {
          ...state.announcements,
          list: {
            ...state.announcements.list,
            isLoading: true
          }
        }
      }
    }
    case actions.SUPPORT_FETCH_ANNOUNCEMENTS_FAILED: {
      return {
        ...state,
        announcements: {
          ...state.announcements,
          list: {
            ...state.announcements.list,
            isLoading: false
          }
        }
      }
    }
    case actions.SUPPORT_FETCH_ANNOUNCEMENTS_RECEIVED: {
      return {
        ...state,
        announcements: {
          ...state.announcements,
          list: {
            ...state.announcements.list,
            isLoading: false,
            data: action.payload
          }
        }
      }
    }
    case actions.SUPPORT_SAVE_ANNOUNCEMENT: {
      return {
        ...state,
        announcements: {
          ...state.announcements,
          isSaving: true
        }
      }
    }
    case actions.SUPPORT_SAVE_ANNOUNCEMENT_RECEIVED: {
      return {
        ...state,
        announcements: {
          ...state.announcements,
          isSaving: false,
          list: {
            ...state.announcements.list,
            data: action.payload.isNew
              ? [...state.announcements.list.data, action.payload.data]
              : replaceItem(state.announcements.list.data, action.payload.data)
          }
        }
      }
    }
    case actions.SUPPORT_REQUEST_DASHBOARD_URL:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          dashboardUrl: {
            url: action.payload,
            isLoading: true
          }
        }
      }
    case actions.SUPPORT_DASHBOARD_URL_RECEIVED:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          dashboardUrl: {
            ...state.patientsDashboard.dashboardUrl,
            url: action.payload,
            isLoading: false
          }
        }
      }
    case actions.SUPPORT_DASHBOARD_URL_FAILED:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          dashboardUrl: {
            ...state.patientsDashboard.dashboardUrl,
            url: null,
            isLoading: false
          }
        }
      }
    case actions.SUPPORT_FETCH_PRACTICE_MEMBERS:
    case actions.SUPPORT_UPDATE_PRACTICE_MEMBER:
    case actions.DEACTIVATE_PRACTICE_MEMBER:
    case actions.ACTIVATE_PRACTICE_MEMBER:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          practiceMembers: {
            ...state.practiceMembers,
            isLoading: true
          }
        }
      }
    case actions.SUPPORT_FETCH_PRACTICE_MEMBERS_RECEIVED:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          practiceMembers: {
            ...state.practiceMembers,
            isLoading: false,
            data: action.payload
          }
        }
      }

    case actions.SUPPORT_FETCH_PRACTICE_MEMBERS_FAILED:
    case actions.SUPPORT_UPDATE_PRACTICE_MEMBER_FAILED:
    case actions.DEACTIVATE_PRACTICE_MEMBER_FAILED:
    case actions.ACTIVATE_PRACTICE_MEMBER_FAILED:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          practiceMembers: {
            ...state.practiceMembers,
            isLoading: false
          }
        }
      }
    case actions.SUPPORT_UPDATE_PRACTICE_MEMBER_RECEIVED:
      const selectedDoctor = updateSelectedDoctor(action.payload, state.doctorsDashboard.selectedDoctor)
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          isLoading: false,
          selectedDoctor
        }
      }
    case Actions.SUPPORT_FETCH_DOCTOR:
    case Actions.SUPPORT_UPDATE_USER:
    case Actions.SUPPORT_UPDATE_DOCTOR_FF:
    case Actions.SUPPORT_UPDATE_DOCTOR:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          isLoading: true
        }
      }
    case Actions.SUPPORT_FETCH_DOCTOR_FAILED:
    case Actions.SUPPORT_UPDATE_USER_FAILED:
    case Actions.SUPPORT_UPDATE_DOCTOR_FF_FAILED:
    case Actions.SUPPORT_UPDATE_DOCTOR_FAILED:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          isLoading: false
        }
      }
    case Actions.SUPPORT_FETCH_DOCTOR_RECEIVED:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          selectedDoctor: action.payload,
          isLoading: false
        }
      }
    case Actions.SUPPORT_RESET_DOCTOR:
      return {
        ...state,
        doctorsDashboard: initialState.doctorsDashboard
      }
    case Actions.SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          notes: {
            ...state.doctorsDashboard.notes,
            isLoading: true
          }
        }
      }
    case Actions.SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID_RECEIVED:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          notes: {
            ...state.doctorsDashboard.notes,
            isLoading: false,
            data: action.payload
          }
        }
      }
    case Actions.SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID_FAILED:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          notes: {
            ...state.doctorsDashboard.notes,
            isLoading: false
          }
        }
      }
    case Actions.SUPPORT_CREATE_DOCTOR_NOTE:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          notes: {
            ...state.doctorsDashboard.notes,
            isCreating: true
          }
        }
      }
    case Actions.SUPPORT_CREATE_DOCTOR_NOTE_RECEIVED:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          notes: {
            ...state.doctorsDashboard.notes,
            isCreating: false,
            data: [...state.doctorsDashboard.notes.data, action.payload]
          }
        }
      }
    case Actions.SUPPORT_CREATE_DOCTOR_NOTE_FAILED:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          notes: {
            ...state.doctorsDashboard.notes,
            isCreating: false
          }
        }
      }
    case Actions.SUPPORT_FETCH_DOCTOR_TRANSACTIONS:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          transactions: {
            ...state.doctorsDashboard.transactions,
            isLoading: true
          }
        }
      }
    case Actions.SUPPORT_FETCH_DOCTOR_TRANSACTIONS_RECEIVED:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          transactions: {
            ...state.doctorsDashboard.transactions,
            isLoading: false,
            data: action.payload
          }
        }
      }
    case Actions.SUPPORT_FETCH_DOCTOR_TRANSACTIONS_FAILED:
      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          transactions: {
            ...state.doctorsDashboard.transactions,
            isLoading: false
          }
        }
      }
    case Actions.SUPPORT_FETCH_PATIENT:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          selectedPatient: {
            ...state.patientsDashboard.selectedPatient,
            data: action.payload,
            isLoading: true
          }
        }
      }
    case Actions.SUPPORT_FETCH_PATIENT_RECEIVED:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          selectedPatient: {
            ...state.patientsDashboard.selectedPatient,
            data: action.payload,
            isLoading: false
          }
        }
      }
    case Actions.SUPPORT_FETCH_PATIENT_FAILED:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          selectedPatient: {
            ...state.patientsDashboard.selectedPatient,
            isLoading: false
          }
        }
      }

    case Actions.SUPPORT_FETCH_LINKED_PATIENTS:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          selectedPatient: {
            ...state.patientsDashboard.selectedPatient,
            selectedPatientLinkedAccounts: {
              isLoading: true
            }
          }
        }
      }
    case Actions.SUPPORT_FETCH_LINKED_PATIENTS_RECEIVED:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          selectedPatient: {
            ...state.patientsDashboard.selectedPatient,
            selectedPatientLinkedAccounts: {
              data: action.payload,
              isLoading: false
            }
          }
        }
      }
    case Actions.SUPPORT_FETCH_LINKED_PATIENTA_FAILED:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          selectedPatient: {
            ...state.patientsDashboard.selectedPatient,
            selectedPatientLinkedAccounts: {
              isLoading: false
            }
          }
        }
      }
    case Actions.SUPPORT_FETCH_BILLING_REPORT:
      return {
        ...state,
        billingDashboard: {
          ...state.billingDashboard,
          filters: {
            month: action.payload?.month,
            allPlans: action.payload?.allPlans || state.billingDashboard.filters.allPlans
          },
          isLoading: true,
          error: initialState.billingDashboard.error
        }
      }
    case Actions.SUPPORT_FETCH_BILLING_REPORT_RECEIVED:
      return {
        ...state,
        billingDashboard: {
          ...state.billingDashboard,
          data: action.payload,
          isLoading: false,
          error: initialState.billingDashboard.error
        }
      }
    case Actions.SUPPORT_FETCH_BILLING_REPORT_FAILED:
      return {
        ...state,
        billingDashboard: {
          ...state.billingDashboard,
          isLoading: false,
          error: action.payload || true
        }
      }
    case Actions.SUPPORT_BILLING_SET_CUSTOM_ACTIVE_PATIENTS_COUNT:
      return {
        ...state,
        billingDashboard: {
          ...state.billingDashboard,
          data: {
            ...state.billingDashboard.data,
            practices: updateWhere(
              state.billingDashboard.data.practices,
              practice => practice.id === action.payload.practiceId,
              practice => (practice.activePatientsCount = action.payload.updatedCount)
            )
          }
        }
      }
    case Actions.SUPPORT_BILLING_CREATE_DRAFT_INVOICE:
    case Actions.SUPPORT_BILLING_CREATE_AND_PAY_INVOICE:
      return {
        ...state,
        billingDashboard: {
          ...state.billingDashboard,
          data: {
            ...state.billingDashboard.data,
            practices: updateWhere(
              state.billingDashboard.data.practices,
              practice => practice.id === action.payload.practiceId,
              practice => (practice.isLoading = true)
            )
          }
        }
      }
    case Actions.SUPPORT_BILLING_CREATE_DRAFT_INVOICE_RECEIVED:
    case Actions.SUPPORT_BILLING_CREATE_AND_PAY_INVOICE_RECEIVED:
      return {
        ...state,
        billingDashboard: {
          ...state.billingDashboard,
          data: {
            ...state.billingDashboard.data,
            practices: replaceItem(state.billingDashboard.data.practices, action.payload)
          }
        }
      }
    case Actions.SUPPORT_BILLING_CREATE_DRAFT_INVOICE_FAILED:
    case Actions.SUPPORT_BILLING_CREATE_AND_PAY_INVOICE_FAILED:
      return {
        ...state,
        billingDashboard: {
          ...state.billingDashboard,
          data: {
            ...state.billingDashboard.data,
            practices: updateWhere(
              state.billingDashboard.data.practices,
              practice => practice.id === action.payload.practiceId,
              practice => (practice.isLoading = false)
            )
          }
        }
      }
    case Actions.SUPPORT_SKIP_CONFIRMATION_CODE:
      return {
        ...state,
        opsActions: {
          ...state.opsActions,
          skipConfirmationCode: {
            ...state.opsActions.skipConfirmationCode,
            isLoading: true
          }
        }
      }
    case Actions.SUPPORT_SKIP_CONFIRMATION_CODE_RECEIVED:
    case Actions.SUPPORT_SKIP_CONFIRMATION_CODE_FAILED:
      return {
        ...state,
        opsActions: {
          ...state.opsActions,
          skipConfirmationCode: {
            ...state.opsActions.skipConfirmationCode,
            isLoading: false
          }
        }
      }
    case Actions.SUPPORT_SAVE_ARTICLE:
      return {
        ...state,
        articlesDashboard: {
          isSaving: true
        }
      }
    case Actions.SUPPORT_SAVE_ARTICLE_RECEIVED:
    case Actions.SUPPORT_SAVE_ARTICLE_FAILED:
      return {
        ...state,
        articlesDashboard: {
          isSaving: false
        }
      }
    case Actions.SUPPORT_UPDATE_APP_SETTINGS_RECEIVED:
      if (action.payload.id !== state.doctorsDashboard.selectedDoctor.user.id) {
        return state
      }

      return {
        ...state,
        doctorsDashboard: {
          ...state.doctorsDashboard,
          selectedDoctor: {
            ...state.doctorsDashboard.selectedDoctor,
            user: {
              ...state.doctorsDashboard.selectedDoctor.user,
              appSettings: action.payload.appSettings
            }
          }
        }
      }
    case Actions.SUPPORT_GET_AUTH_DETAILS:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          authDetails: {
            ...state.patientsDashboard.authDetails,
            isLoading: true
          }
        }
      }
    case Actions.SUPPORT_GET_AUTH_DETAILS_RECEIVED:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          authDetails: {
            ...state.patientsDashboard.authDetails,
            isLoading: false,
            data: action.payload
          }
        }
      }
    case Actions.SUPPORT_GET_AUTH_DETAILS_FAILED:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          authDetails: {
            ...state.patientsDashboard.authDetails,
            isLoading: false,
            data: null
          }
        }
      }
    case Actions.SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          isChangingEmail: true
        }
      }
    case Actions.SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL_FAILED:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          isChangingEmail: false
        }
      }
    case Actions.SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL_RECEIVED:
      return {
        ...state,
        patientsDashboard: {
          ...state.patientsDashboard,
          isChangingEmail: false,
          selectedPatient: {
            ...state.patientsDashboard.selectedPatient,
            data: {
              ...state.patientsDashboard.selectedPatient.data,
              patient: {
                ...state.patientsDashboard.selectedPatient.data.patient,
                user: {
                  ...state.patientsDashboard.selectedPatient.data.patient.user,
                  messagingPreferences: {
                    ...state.patientsDashboard.selectedPatient.data.patient.user.messagingPreferences,
                    contacts: [
                      {
                        ...state.patientsDashboard.selectedPatient.data.patient.user.messagingPreferences.contacts[0],
                        email: action.payload.newCommunicationEmail
                      }
                    ]
                  }
                }
              }
            }
          }
        }
      }
    case Actions.SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS:
      return {
        ...state,
        scanSummaryVersions: {
          ...state.scanSummaryVersions,
          loadingState: AsyncStatus.Loading
        }
      }
    case Actions.SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS_FAILED:
      return {
        ...state,
        scanSummaryVersions: {
          ...state.scanSummaryVersions,
          loadingState: AsyncStatus.Failed
        }
      }
    case Actions.SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS_RECEIVED:
      return {
        ...state,
        scanSummaryVersions: {
          ...state.scanSummaryVersions,
          loadingState: AsyncStatus.Completed,
          data: action.payload.versions
        }
      }
    case Actions.SUPPORT_CLEAR_SUPPRESSION_LIST_STATE:
      return {
        ...state,
        suppressionListActions: initialState.suppressionListActions
      }
    case Actions.SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST:
      return {
        ...state,
        suppressionListActions: {
          deleteEmailStatus: AsyncStatus.Loading,
          deleteEmailError: null
        }
      }
    case Actions.SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST_FAILED:
      return {
        ...state,
        suppressionListActions: {
          deleteEmailStatus: AsyncStatus.Failed,
          deleteEmailError: action.payload
        }
      }
    case Actions.SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST_RECEIVED:
      return {
        ...state,
        suppressionListActions: {
          deleteEmailStatus: AsyncStatus.Completed,
          deleteEmailError: null
        }
      }
    case Actions.SUPPORT_FETCH_HI_PRACTICES:
      return {
        ...state,
        hiPracticesDashboard: {
          ...state.hiPracticesDashboard,
          status: AsyncStatus.Loading
        }
      }
    case Actions.SUPPORT_FETCH_HI_PRACTICES_RECEIVED:
      return {
        ...state,
        hiPracticesDashboard: {
          ...state.hiPracticesDashboard,
          practices: action.payload,
          status: AsyncStatus.Completed
        }
      }
    case Actions.SUPPORT_FETCH_HI_PRACTICES_FAILED:
      return {
        ...state,
        hiPracticesDashboard: {
          ...state.hiPracticesDashboard,
          practices: [],
          status: AsyncStatus.Completed
        }
      }
    case Actions.SUPPORT_TOGGLE_UPDATE_HI_GROUPS_MODAL:
      return {
        ...state,
        hiPracticesDashboard: {
          ...state.hiPracticesDashboard,
          updateHiGroupsModal: {
            ...state.hiPracticesDashboard.updateHiGroupsModal,
            isOpen: action.payload.isOpen,
            accountOwnerIds: action.payload?.accountOwnerIds
          }
        }
      }
    case Actions.SUPPORT_UPDATE_HI_GROUPS:
      return {
        ...state,
        hiPracticesDashboard: {
          ...state.hiPracticesDashboard,
          practices: state.hiPracticesDashboard.practices.map(practice => ({
            ...practice,
            hiGroup: action.payload.accountOwnerIds.includes(practice.id)
              ? action.payload.newHiGroupKey
              : practice.hiGroup
          })),
          updateHiGroupsModal: {
            ...state.hiPracticesDashboard.updateHiGroupsModal,
            isLoading: true
          }
        }
      }
    case Actions.SUPPORT_UPDATE_HI_GROUPS_RECEIVED:
    case Actions.SUPPORT_UPDATE_HI_GROUPS_FAILED:
      return {
        ...state,
        hiPracticesDashboard: {
          ...state.hiPracticesDashboard,
          updateHiGroupsModal: {
            isOpen: false,
            isLoading: false
          }
        }
      }
    default:
      return state
  }
}
