import React from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedHeading16 from '../../common/text/DazzedHeading16'
import DazzedParagraph12 from '../../common/text/DazzedParagraph12'
import GrinSwitch from '../../common/GrinSwitch'
import { CircularProgress, Grid } from '@material-ui/core'
import { InfoSmall } from 'components/common/icons'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 60
  },
  header: {
    marginBottom: 5
  },
  title: {
    fontSize: 20,
    color: 'var(--text-color-6)'
  },
  content: {
    color: 'var(--text-color-6)'
  },
  progress: {
    color: 'var(--text-color-27)',
    margin: theme.spacing(1)
  }
}))

const ServicesToggle = ({ checked, disabled, onChange, title, children, tagComponent, tooltipText, isLoading }) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <DazzedHeading16 className={classes.title}>{title}</DazzedHeading16>
              </Grid>
              <Grid item>{tagComponent}</Grid>
              {tooltipText && (
                <Grid item style={{ marginTop: 5 }}>
                  <Tooltip value={tooltipText} arrow variant="light">
                    <InfoSmall fill="#BDBDBD" stroke="white" />
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            {isLoading ? (
              <CircularProgress className={classes.progress} size={26} />
            ) : (
              <GrinSwitch checked={checked} disabled={disabled} onChange={({ target }) => onChange(target.checked)} />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {!!children && <DazzedParagraph12 className={classes.content}>{children}</DazzedParagraph12>}
      </Grid>
    </Grid>
  )
}

export default ServicesToggle
