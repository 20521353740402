import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Actions from 'actions'

export default ({ patientId }) => {
  const dispatch = useDispatch()

  const handleResolveNeedsAttention = useCallback(
    ({ action, note }) => {
      dispatch(Actions.resolveHiNeedsAttention({ action, note, patientId }))
    },
    [dispatch, patientId]
  )

  return {
    handleResolveNeedsAttention
  }
}
