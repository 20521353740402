import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import FormInput from 'components/common/FormInput'

const useStyles = makeStyles(theme => ({
  inputWrapper: {
    margin: '0px !important',
    border: 'none !important'
  },
  input: {
    width: '200px !important',
    border: 'none !important',
    borderBottom: '1px solid !important',
    fontSize: '14px !important',
    fontFamily: 'Dazzed'
  }
}))

const TypicalWireSequence = ({ value = '', onChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleSequenceChanged = useCallback(
    newValue => {
      onChange({ value: newValue })
    },
    [onChange]
  )

  return (
    <Grid container alignItems="center">
      <Grid item>
        <DazzedParagraph14>
          {t('pages.accountSettings.practiceGuidelines.actions.typicalWireSequence')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <FormInput
          value={value}
          setValue={handleSequenceChanged}
          style={{ bright: true, thick: false }}
          keepErrorContainerWhenInactive={false}
          inputClassName={classes.input}
          inputWrapperClassName={classes.inputWrapper}
          validationRules={{ required: true }}
        />
      </Grid>
    </Grid>
  )
}

export default TypicalWireSequence
