import * as React from 'react'

const BracesTxTracking = props => (
  <svg
    width="17"
    height="23"
    viewBox="0 0 17 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 5 }}
  >
    <path
      d="M14.3293 10.4401L14.3253 10.4515L14.3219 10.463L12.2125 17.6243C11.9671 18.4575 10.7868 18.4569 10.5422 17.6236L9.23021 13.1541C8.94514 12.183 7.5695 12.183 7.28443 13.1541L5.97243 17.6236C5.7278 18.4569 4.54753 18.4575 4.30213 17.6243L2.19276 10.463L2.18935 10.4515L2.18538 10.4401L1.20669 7.62631C0.802957 6.46557 1.00817 5.17584 1.75069 4.19517C3.11121 2.39831 5.75906 2.23695 7.31989 3.85859C7.83172 4.39036 8.68292 4.39036 9.19475 3.85859L8.83451 3.51185L9.19475 3.85859C10.7556 2.23695 13.4034 2.39831 14.7639 4.19517C15.5065 5.17584 15.7117 6.46557 15.3079 7.62631L14.3293 10.4401Z"
      stroke="#001335"
    />
    <rect x="6.25" y="6.5" width="4" height="4" rx="0.5" fill="#001335" stroke="#001335" />
    <path d="M1.5 8.5H15" stroke="#001335" strokeWidth="1.5" />
  </svg>
)

export default BracesTxTracking
