import React, { useMemo } from 'react'
import { makeStyles, ImageList, ImageListItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useRolePermissions from 'hooks/useRolePermissions'
import DashboardCard from './DashboardCard'

const useSupportDashboardStyles = makeStyles({
  container: {
    height: '100%',
    maxWidth: '1200px',
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 70,
    margin: '0 auto'
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    marginTop: 90,
    marginBottom: 30
  },
  cardContainer: {
    overflow: 'initial'
  }
})

const SupportDashboardPicker = () => {
  const classes = useSupportDashboardStyles()
  const { permissions } = useRolePermissions()
  const { t } = useTranslation()

  const availableDashboards = useMemo(
    () => [
      {
        route: 'allowed-doctors',
        label: t('pages.opsDashboard.tiles.allowedDoctors'),
        condition: () => permissions.opsDashboardAllowedDoctors
      },
      {
        route: 'doctors-and-members',
        label: t('pages.opsDashboard.tiles.doctors'),
        condition: () => permissions.opsDashboardPractices
      },
      {
        route: 'patients',
        label: t('pages.opsDashboard.tiles.patients'),
        condition: () => permissions.opsDashboardPatients
      },
      {
        route: 'release-notes',
        label: t('pages.opsDashboard.tiles.releaseNotes'),
        condition: () => permissions.opsDashboardReleaseNotes
      },
      {
        route: 'stats',
        label: t('pages.opsDashboard.tiles.stats'),
        condition: () => permissions.opsDashboardStats
      },
      {
        route: 'promotions',
        label: t('pages.opsDashboard.tiles.promotions'),
        condition: () => permissions.opsDashboardPromotions
      },
      {
        route: 'announcements',
        label: t('pages.opsDashboard.tiles.announcements'),
        condition: () => permissions.opsDashboardAnnouncements
      },
      {
        route: 'ops-actions',
        label: t('pages.opsDashboard.tiles.opsActions'),
        condition: () => permissions.opsActions
      },
      {
        route: 'billing',
        label: t('pages.opsDashboard.tiles.billing'),
        condition: () => permissions.opsDashboardBilling
      },
      {
        route: 'articles',
        label: t('pages.opsDashboard.tiles.articles'),
        condition: () => permissions.opsDashboardArticles
      },
      {
        route: 'system-automations',
        label: t('pages.opsDashboard.tiles.systemAutomations'),
        condition: () => permissions.opsDashboardSystemAutomations
      },
      {
        route: 'hi-practices',
        label: t('pages.opsDashboard.tiles.hiPractices'),
        condition: () => permissions.opsDashboardHIPractices
      }
    ],
    [permissions, t]
  )

  return (
    <div className={classes.container}>
      <ImageList cols={3} rowHeight="auto">
        {availableDashboards
          .filter(dashboard => !dashboard.condition || dashboard.condition())
          .map(({ label, route }) => (
            <ImageListItem key={route} classes={{ item: classes.cardContainer }}>
              <DashboardCard label={label} route={route} />
            </ImageListItem>
          ))}
      </ImageList>
    </div>
  )
}

export default SupportDashboardPicker
