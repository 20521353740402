import * as React from 'react'

const Copy = ({ color = 'var(--text-color-3)', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="path-1-inside-1" fill="white">
        <rect x="4.73682" y="4.30762" width="11.2125" height="11.0256" rx="1" />
      </mask>
      <rect
        x="4.73682"
        y="4.30762"
        width="11.2125"
        height="11.0256"
        rx="1"
        stroke={color}
        strokeWidth="3"
        mask="url(#path-1-inside-1)"
      />
      <path
        d="M11.4643 5.41026V2C11.4643 1.44771 11.0166 1 10.4643 1H2.37305C1.82076 1 1.37305 1.44772 1.37305 2V9.92308C1.37305 10.4754 1.82076 10.9231 2.37305 10.9231H5.91412"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default Copy
