import { isMobile as isMobileDevice } from 'react-device-detect'

export const isMobile = () => {
  if (!isMobileDevice) {
    return false
  }

  return !sessionStorage.getItem('forceDesktop')
}

export const isLandscape = () => {
  return window.matchMedia('(orientation: landscape)').matches
}

export const setForceDesktop = flag => {
  if (flag) {
    sessionStorage.setItem('forceDesktop', 'true')
    document.getElementById('mobile-viewport-tag').setAttribute('content', 'width=device-width, initial-scale=0.3')
  } else {
    sessionStorage.removeItem('forceDesktop')
    document.getElementById('mobile-viewport-tag').setAttribute('content', 'width=device-width, initial-scale=1')
  }

  window.location.href = '/patients'
}

export const isDesktopForced = () => !!sessionStorage.getItem('forceDesktop')
