import * as React from 'react'

const Sketch = ({ color = 'white', ...props }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.1273 13.437V3.31172C19.1273 1.4856 17.716 0 15.9812 0C14.2463 0 12.835 1.4856 12.835 3.31172V13.437C12.8333 13.5211 12.8499 13.6103 12.8835 13.6895L15.1172 18.9544H1.68733C1.38738 18.9544 1.12075 18.7036 1.12075 18.3487C1.12075 18.0198 1.37494 17.7523 1.68733 17.7523H8.28025C9.21062 17.7523 9.96754 16.9555 9.96754 15.9762C9.96754 14.9968 9.21062 14.2001 8.28025 14.2001H4.76584C4.45636 14.2001 4.20547 14.4642 4.20547 14.7899C4.20547 15.1157 4.45636 15.3798 4.76584 15.3798H8.28025C8.59264 15.3798 8.84679 15.6473 8.84679 15.9762C8.84679 16.305 8.59264 16.5726 8.28025 16.5726H1.68733C0.771524 16.5726 0 17.3502 0 18.3581C0 19.3374 0.756955 20.1342 1.68733 20.1342H15.9812C16.2133 20.1342 16.4085 19.9892 16.4977 19.7735L19.0788 13.6896C19.1109 13.6137 19.129 13.5252 19.1273 13.437V13.437ZM17.1609 15.3169H14.8014L14.2592 14.0389H17.7031L17.1609 15.3169ZM15.9812 18.0977L15.3019 16.4967H16.6604L15.9812 18.0977ZM15.4208 4.37769V12.859H13.9557V4.37769H15.4208ZM18.0065 4.37769V12.859H16.5415V4.37769H18.0065ZM15.9812 1.17974C17.0617 1.17974 17.9472 2.07508 18.0036 3.19795H13.9587C14.0151 2.07508 14.9006 1.17974 15.9812 1.17974V1.17974Z"
        fill={color}
      />
    </svg>
  )
}

export default Sketch
