import React from 'react'
import { makeStyles } from '@material-ui/styles'
import images from 'resources'
import { Grid } from '@material-ui/core'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { useTranslation } from 'react-i18next'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10
  },
  image: {
    width: 810,
    height: 210
  }
}))

const AutomationPreview = ({ onUpgradePlanClicked }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container direction="column" spacing={2} className={classes.root}>
      <Grid item>
        <DazzedHeading18>{t('pages.accountSettings.automations.howItWorks.title')}</DazzedHeading18>
      </Grid>
      <Grid item>
        <DazzedParagraph14 strong>{t('pages.accountSettings.automations.howItWorks.description')}</DazzedParagraph14>
      </Grid>
      <Grid item>
        <img src={images.automationRulesExample} className={classes.image} alt="" />
      </Grid>
      <Grid item>
        <PrimaryButton
          label={t('pages.accountSettings.automations.howItWorks.upgradePlanLabel')}
          onClick={onUpgradePlanClicked}
        />
      </Grid>
    </Grid>
  )
}

export default AutomationPreview
