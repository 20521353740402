const FETCH_GRIN_SCOPE_PRODUCTS = 'FETCH_GRIN_SCOPE_PRODUCTS'
const FETCH_GRIN_SCOPE_PRODUCTS_FAILED = 'FETCH_GRIN_SCOPE_PRODUCTS_FAILED'
const FETCH_GRIN_SCOPE_PRODUCTS_RECEIVED = 'FETCH_GRIN_SCOPE_PRODUCTS_RECEIVED'

const fetchGrinScopeProducts = payload => ({
  type: FETCH_GRIN_SCOPE_PRODUCTS,
  payload
})

const fetchGrinScopeProductsReceived = payload => ({
  type: FETCH_GRIN_SCOPE_PRODUCTS_RECEIVED,
  payload
})

const fetchGrinScopeProductsFailed = payload => ({
  type: FETCH_GRIN_SCOPE_PRODUCTS_FAILED,
  payload
})

export default {
  FETCH_GRIN_SCOPE_PRODUCTS,
  FETCH_GRIN_SCOPE_PRODUCTS_FAILED,
  FETCH_GRIN_SCOPE_PRODUCTS_RECEIVED,
  fetchGrinScopeProducts,
  fetchGrinScopeProductsReceived,
  fetchGrinScopeProductsFailed
}
