import { makeStyles } from '@material-ui/styles'
import Actions from 'actions'
import ConfirmationModal from 'components/common/ConfirmationModal'
import UrgentIcon from 'components/common/icons/Urgent'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import ServicesToggle from '../../Services/ServicesToggle'

const useStyles = makeStyles(theme => ({
  description: {
    marginBottom: 10
  }
}))

const UrgentScanSettings = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isUrgentScanOn, setIsUrgentScanOn] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const isLoading = useSelector(state => state.profileReducer.isLoading)

  const practiceConfiguration = usePracticeConfiguration()

  const handleToggleClicked = useCallback(checked => {
    const mode = checked ? 'toggleOn' : 'toggleOff'
    trackEvent(`Urgent Scan Settings - toggle clicked`, { value: mode })
    setIsUrgentScanOn(checked)
    setShowConfirmation(true)
  }, [])

  const cancelSaveUrgentScanSettings = useCallback(() => {
    trackEvent(`Urgent Scan Settings - discard`)
    setIsUrgentScanOn(!isUrgentScanOn)
    setShowConfirmation(false)
  }, [isUrgentScanOn])

  const saveUrgentScanSettings = useCallback(() => {
    trackEvent(`Urgent Scan Settings - confirmed`, { value: isUrgentScanOn })
    setShowConfirmation(false)
    dispatch(
      Actions.requestUpdateAppSettings({
        urgentScanAllowed: isUrgentScanOn
      })
    )
  }, [dispatch, isUrgentScanOn])

  useEffect(() => {
    setIsUrgentScanOn(practiceConfiguration.urgentScanAllowed)
  }, [practiceConfiguration.urgentScanAllowed])

  return (
    <>
      <ServicesToggle
        checked={isUrgentScanOn}
        onChange={handleToggleClicked}
        tagComponent={<UrgentIcon />}
        title={t('pages.accountSettings.advancedActions.urgentScan.title')}
        isLoading={isLoading}
      >
        <DazzedParagraph14 strong className={classes.description}>
          {t('pages.accountSettings.advancedActions.urgentScan.description')}
        </DazzedParagraph14>
      </ServicesToggle>
      <ConfirmationModal
        isOpen={showConfirmation}
        onClose={cancelSaveUrgentScanSettings}
        onConfirm={saveUrgentScanSettings}
        title={t(
          `pages.accountSettings.advancedActions.urgentScan.${
            !isUrgentScanOn ? 'disableConfirmationTitle' : 'enableConfirmationTitle'
          }`
        )}
        additionalText={t(
          `pages.accountSettings.advancedActions.urgentScan.${
            isUrgentScanOn ? 'enableConfirmationDescription' : 'disableConfirmationDescription'
          }`
        )}
      />
    </>
  )
}

export default UrgentScanSettings
