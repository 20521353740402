import Actions from 'actions'
import { ofType } from 'redux-observable'
import { tap, ignoreElements, filter } from 'rxjs/operators'
import { timeEvent, finishTimeEvent } from 'utils/analyticsUtils'

export const startLoadupDurationTracking = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_APP_CONFIG),
    tap(() => timeEvent('Load Tracking - App Loadup')),
    ignoreElements()
  )

export const stopLoadupDurationTracking = action$ =>
  action$.pipe(
    ofType(Actions.ROOMS_RECEIVED),
    tap(() => finishTimeEvent('Load Tracking - App Loadup')),
    ignoreElements()
  )

export const startLoadPatientChatDurationTracking = action$ =>
  action$.pipe(
    ofType(Actions.PATIENT_REQUESTED),
    tap(() => timeEvent('Load Tracking - Chat Loadup')),
    ignoreElements()
  )
export const stopLoadPatientChatDurationTracking = action$ =>
  action$.pipe(
    ofType(Actions.PATIENT_MESSAGES_RECEIVED),
    tap(() => finishTimeEvent('Load Tracking - Chat Loadup')),
    ignoreElements()
  )

export const startLoadPatientTimelineDurationTracking = action$ =>
  action$.pipe(
    ofType(Actions.PATIENT_REQUESTED),
    tap(() => timeEvent('Load Tracking - Timeline Loadup')),
    ignoreElements()
  )
export const stopLoadPatientTimelineDurationTracking = action$ =>
  action$.pipe(
    ofType(Actions.PATIENT_TREATMENT_RECEIVED),
    tap(() => finishTimeEvent('Load Tracking - Timeline Loadup')),
    ignoreElements()
  )

export const startSendScanReviewDurationTracking = action$ =>
  action$.pipe(
    ofType([Actions.REQUEST_SEND_SCAN_REVIEW]),
    tap(() => {
      timeEvent('Load Tracking - Send Scan Review E2E')
      timeEvent('Load Tracking - Send Scan Review - Upload')
    }),
    ignoreElements()
  )

export const stopSendScanReviewUploadDurationTracking = action$ =>
  action$.pipe(
    ofType(Actions.SEND_SCAN_REVIEW_RECEIVED),
    tap(() => finishTimeEvent('Load Tracking - Send Scan Review - Upload')),
    ignoreElements()
  )

export const stopSendScanReviewE2EDurationTracking = action$ =>
  action$.pipe(
    ofType(Actions.MESSAGE_ADDED),
    filter(({ payload }) => payload.type === 'newScanReview'),
    tap(() => finishTimeEvent('Load Tracking - Send Scan Review E2E')),
    ignoreElements()
  )
