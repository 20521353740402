import Actions from 'actions'
import { API, graphqlOperation } from 'aws-amplify'
import {
  autochartGetAutochart,
  autochartGetGrinScan,
  autochartGetPatientSearchModel,
  updateAutochart
} from 'graphql/customQueries'
import { ofType } from 'redux-observable'
import i18n from 'resources/locales/i18n'
import { forkJoin, from, of } from 'rxjs'
import { catchError, filter, map, mapTo, mergeMap, pluck, switchMap } from 'rxjs/operators'

export const autochartLoadDataTrigger = action$ =>
  action$.pipe(
    ofType(Actions.AUTOCHART_TOGGLE_DIALOG),
    pluck('payload'),
    filter(payload => payload.isOpen === true),
    map(({ grinScanId, patientId }) => Actions.autochartLoadData({ grinScanId, patientId }))
  )

export const autochartLoadDataEpic = action$ =>
  action$.pipe(
    ofType(Actions.AUTOCHART_LOAD_DATA),
    pluck('payload'),
    switchMap(({ grinScanId, patientId }) =>
      forkJoin({
        grinScan: API.graphql(graphqlOperation(autochartGetGrinScan, { id: grinScanId })).then(
          res => res.data.getGrinScan
        ),
        patientSM: API.graphql(graphqlOperation(autochartGetPatientSearchModel, { id: patientId })).then(
          res => res.data.getPatientSearchModel
        )
      }).pipe(
        mergeMap(data => of(Actions.autochartLoadDataReceived(data))),
        catchError(err => of(Actions.autochartLoadDataFailed(err)))
      )
    )
  )

export const updateAutochartEpic = action$ =>
  action$.pipe(
    ofType(Actions.AUTOCHART_SAVE),
    pluck('payload'),
    switchMap(({ id, notes, teeth }) =>
      from(API.graphql(graphqlOperation(autochartGetAutochart, { id }))).pipe(
        map(res => res.data.getAutochart),
        switchMap(({ _version }) =>
          from(
            API.graphql(
              graphqlOperation(updateAutochart, {
                input: {
                  id,
                  notes,
                  teeth,
                  _version
                }
              })
            )
          ).pipe(
            map(res => res.data.updateAutochart),
            mergeMap(data => of(Actions.autochartSaveReceived(data))),
            catchError(ex => of(Actions.autochartSaveFailed(ex)))
          )
        ),
        catchError(ex => of(Actions.autochartSaveFailed(ex)))
      )
    )
  )

export const updateAutochartFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.AUTOCHART_SAVE_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const updateAutochartSuccessEpic = action$ =>
  action$.pipe(
    ofType(Actions.AUTOCHART_SAVE_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('dialogs.autochart.changedSavedSuccessfully')
      })
    )
  )
