import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { trackEvent } from 'utils/analyticsUtils'
import actions from 'actions'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import PatientsToInviteTable from './PatientsToInviteTable'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { DolphinGrinFeedback } from 'components/common/icons'

const useStyles = makeStyles({
  modal: {
    padding: '50px 50px 30px 50px',
    minWidth: 850
  },
  description: {
    whiteSpace: 'pre-line'
  },
  tellUs: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
})

const DolphinLatestPatientsDialog = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isOpen } = useSelector(state => state.pmsReducer.invitePatientsModal.dolphin)
  const { latestPatientsToInvite } = useSelector(state => state.pmsReducer)

  const handleClose = useCallback(() => {
    trackEvent('Dolphin latest patients dialog - closed')
    dispatch(actions.pmsToggleInvitePatientsModal())
  }, [dispatch])

  const handleClickTellUs = useCallback(() => {
    trackEvent('App Feedback Modal - modal opened', {
      source: 'Dolphin latest patients dialog',
      numOfPatients: latestPatientsToInvite?.length
    })
    handleClose()
    dispatch(
      actions.toggleAppFeedbackDialog({
        value: true,
        title: t('dialogs.shareFeedback.dolphinSyncTitle'),
        description: t('dialogs.shareFeedback.dolphinSyncDescription'),
        logo: <DolphinGrinFeedback />,
        inputPlaceholder: t('dialogs.shareFeedback.dolphinSyncPlaceholder'),
        displayNote: false
      })
    )
  }, [dispatch, handleClose, latestPatientsToInvite, t])

  return (
    <BaseModal open={isOpen} handleClose={handleClose} hideScroll className={classes.modal}>
      {isOpen && (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <DazzedHeading20>{t('pms.invitePatientsModal.dolphin.title')}</DazzedHeading20>
              </Grid>
              <Grid item>
                <DazzedParagraph14 textAlign="center" className={classes.description}>
                  {t('pms.invitePatientsModal.dolphin.description')}
                </DazzedParagraph14>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <PatientsToInviteTable />
          </Grid>
          <Grid item>
            <Grid container justifyContent="center">
              <Grid item>
                <DazzedParagraph16
                  bold
                  color="var(--text-color-25)"
                  onClick={handleClickTellUs}
                  className={classes.tellUs}
                >
                  {t('pms.invitePatientsModal.dolphin.inviteMorePatients')}
                </DazzedParagraph16>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="center">
              <Grid item>
                <SecondaryButton small label={t('general.close')} onClick={handleClose} transparentBackground />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </BaseModal>
  )
}

export default DolphinLatestPatientsDialog
