import * as React from 'react'

const InvitationLogo = props => (
  <svg width={21} height={11} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1 1h12v9H1V1Z" stroke="#041333" />
    <path d="m1 1 6 5 6-5" stroke="#041333" />
    <path d="M11 3.5h6v5h-6v-5Z" fill="#fff" />
    <path d="M12 6h8M17 3l3 3-3 3" stroke="#041333" />
  </svg>
)

export default InvitationLogo
