import * as React from 'react'

function SvgLittleMan(props) {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 8C4.79445 8 3 6.20558 3 3.99999C3 1.79439 4.79445 0 7 0C9.20556 0 11 1.79442 11 4.00001C11 6.20561 9.20556 8 7 8ZM7 1.00001C5.34572 1.00001 4.00001 2.3457 4.00001 4.00001C4.00001 5.65433 5.34569 7.00002 7 7.00002C8.65431 7.00002 9.99999 5.6543 9.99999 4.00001C9.99999 2.34573 8.65428 1.00001 7 1.00001Z"
        fill="#001335"
      />
      <path
        d="M13.2245 15H0.4898C0.219063 15 0 14.7798 0 14.5078V11.1197C0 10.6112 0.258764 10.1334 0.674908 9.87195C4.35314 7.56628 9.36447 7.56677 13.0389 9.87147C13.4555 10.1334 13.7143 10.6112 13.7143 11.1197V14.5078C13.7143 14.7798 13.4952 15 13.2245 15ZM0.9796 14.0156H12.7347V11.1197C12.7347 10.9481 12.6524 10.7895 12.5199 10.7064C9.15687 8.59684 4.55978 8.59538 1.19391 10.7069C1.0619 10.7895 0.979628 10.9481 0.979628 11.1197L0.9796 14.0156Z"
        fill="#001335"
      />
    </svg>
  )
}

export default SvgLittleMan
