import * as React from 'react'

const TxGoals = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 4 }}
  >
    <circle cx="9" cy="9" r="8" stroke="#001335" />
    <circle cx="9" cy="9" r="5" stroke="#001335" />
    <circle cx="9" cy="9" r="2" fill="#001335" />
    <path d="M9 17V1" stroke="#001335" />
    <path d="M1 9H17" stroke="#001335" />
  </svg>
)

export default TxGoals
