import * as React from 'react'

function ExternalLink({ color = '#ABB0B8' }) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.63636 1H1V16H16V7.36364M8.27273 9.18182L16 1M16 1V3.72727M16 1H13.2727"
        stroke={color}
        strokeWidth="1.4"
      />
    </svg>
  )
}

export default ExternalLink
