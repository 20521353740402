import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation, Trans } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { CircularProgress, Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import DeactivatePracticeMemberHeader from './DeactivatePracticeMemberHeader'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import { createPracticeMemberTagValue } from 'utils/tagUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  roleDescription: {
    color: 'var(--text-color-11)'
  },
  textContainer: {
    height: '80px',
    marginTop: '30px'
  }
}))

const DeactivatePracticeMemberModal = ({ isOpen, onClose, onSubmit, name, roleDescription, avatar, memberId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { count: numOfpatientsWithTag, isLoading: isLoadingPatientsWithTag } = useSelector(
    state => state.patientsReducer.patientsByTag
  )

  const handleSubmit = useCallback(() => {
    trackEvent('Deactivate Practice Member - confirmed', {
      numOfpatientsWithTag,
      roleDescription
    })
    onSubmit()
  }, [onSubmit, numOfpatientsWithTag, roleDescription])

  const handleClose = useCallback(() => {
    trackEvent('Deactivate Practice Member - canceled', {
      roleDescription,
      numOfpatientsWithTag
    })
    onClose()
  }, [onClose, roleDescription, numOfpatientsWithTag])

  useEffect(() => {
    if (isOpen) {
      dispatch(
        Actions.fetchPatientsWithTag({
          value: createPracticeMemberTagValue({ name, roleDescription })
        })
      )
    }
  }, [isOpen, name, roleDescription, dispatch])

  return (
    <BaseModal
      className={classes.root}
      handleClose={handleClose}
      open={isOpen}
      title={t('pages.accountSettings.practiceMembers.modals.deactivateUser.title')}
      onPrimaryBtnClick={handleSubmit}
      primaryLabel={t('general.confirm')}
      onSecondaryBtnClick={onClose}
      secondaryLabel={t('general.cancel')}
      isPrimaryDisabled={isLoadingPatientsWithTag}
    >
      <DeactivatePracticeMemberHeader name={name} roleDescription={roleDescription} avatar={avatar} />
      <Grid container justifyContent="center" alignItems="center" className={classes.textContainer}>
        {isLoadingPatientsWithTag ? (
          <CircularProgress size={24} />
        ) : numOfpatientsWithTag <= 0 ? (
          <DazzedParagraph16 textAlign="center">
            {t('pages.accountSettings.practiceMembers.modals.deactivateUser.areYouSureNoAssginedPatients')}
          </DazzedParagraph16>
        ) : (
          <>
            <DazzedParagraph16 textAlign="center">
              <Trans
                i18nKey={'pages.accountSettings.practiceMembers.modals.deactivateUser.assignedPatientsCount'}
                count={numOfpatientsWithTag}
              ></Trans>
            </DazzedParagraph16>
            <DazzedParagraph16 textAlign="center">
              <Trans
                i18nKey={'pages.accountSettings.practiceMembers.modals.deactivateUser.viewUnassignedPatients'}
                values={{ practiceName: name }}
              ></Trans>
            </DazzedParagraph16>
          </>
        )}
      </Grid>
    </BaseModal>
  )
}

export default DeactivatePracticeMemberModal
