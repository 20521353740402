import * as React from 'react'

export default () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.5" cy="11.5" r="11.5" fill="black" fillOpacity="0.5" />
      <path d="M18 11.5L7 5.5V17.5L18 11.5Z" fill="white" />
    </svg>
  )
}
