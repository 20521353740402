import React, { useMemo, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import MessageTypeWrapper from '../MessageTypeWrapper'
import MessageImageViewer from './Common/MessageImageViewer'
import DazzedParagraph10 from 'components/common/text/DazzedParagraph10'
import GifThumbnail from 'components/common/icons/SavedFiles/GifThumbnail'

const useStyles = makeStyles(() => ({
  message: {
    backgroundColor: '#FFFFFF'
  },
  messageUserInfo: {
    marginLeft: 5
  },
  timestamp: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 30,
    padding: '12px 18px 0 18px',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))',
    borderRadius: '0 0 8px 8px',
    color: 'white'
  },
  fileTitleContainer: {
    paddingTop: 5
  }
}))

const ImageMessage = ({
  content,
  isLocal,
  cache,
  alignLeft,
  name,
  userType,
  isFirstMessage,
  isLastMessage,
  timestamp,
  isBroadcast,
  fileTitle
}) => {
  const classes = useStyles()
  const [isImageViewerOpen, setImageViewerState] = useState(false)

  const isGif = useMemo(() => fileTitle?.includes?.('gif'), [fileTitle])

  return (
    <MessageTypeWrapper
      isFirstMessage={isFirstMessage}
      isLastMessage={isLastMessage}
      alignLeft={alignLeft}
      name={name}
      userType={userType}
      infoClassName={classes.messageUserInfo}
      isBroadcast={isBroadcast}
    >
      <MessageImageViewer
        isLocal={isLocal}
        image={content}
        isImageViewerOpen={isImageViewerOpen}
        setImageViewerState={setImageViewerState}
        classes={classes}
        cache={cache}
        timestamp={timestamp}
        alignLeft={alignLeft}
        messageClassName={classes.message}
        timestampClassName={classes.timestamp}
        name={name}
      />
      {isGif && (
        <Grid container alignItems="center" spacing={1} className={classes.fileTitleContainer}>
          <Grid item>
            <GifThumbnail />
          </Grid>
          <Grid item>
            <DazzedParagraph10>{fileTitle}</DazzedParagraph10>
          </Grid>
        </Grid>
      )}
    </MessageTypeWrapper>
  )
}

export default React.memo(ImageMessage)
