import { CircularProgress, makeStyles } from '@material-ui/core'
import { SPECIAL_PROVIDERS } from 'components/Patients/TreatmentTracker/txTrackerConsts'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import DazzedHeading13 from 'components/common/text/DazzedHeading13'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import { AsyncStatus } from 'consts'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  dropzone: {
    height: '100%',
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  specialProviderUpload: {
    flexDirection: 'column',
    textAlign: 'center',
    display: 'flex',
    height: '100%',
    width: '100%',
    border: '2px dashed #3c52ef',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default'
  },
  noProviderPlaceholder: {
    flex: 1,
    display: 'flex',
    height: '100%',
    border: '2px dashed lightgray',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    color: 'lightgray',
    fontStyle: 'italic',
    cursor: 'default'
  }
})

const DriveUploaderDropzone = ({
  mode = 'practice',
  dropzoneContainerId,
  isUploaderComponentLoading,
  provider,
  providerOptions,
  onRequestProviderSTLs,
  requestSTLsStatus
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.dropzone} id={dropzoneContainerId}>
      {isUploaderComponentLoading ? (
        <CircularProgress size={18} />
      ) : !provider ? (
        <div className={classes.noProviderPlaceholder}>
          {t('dialogs.patientInfo.txTrackerSetup.selectProviderPlaceholder')}
        </div>
      ) : SPECIAL_PROVIDERS.includes(provider) && mode === 'practice' ? (
        <div className={classes.specialProviderUpload}>
          <DazzedHeading20>
            {t('dialogs.patientInfo.txTrackerSetup.specialProviders.title', {
              provider: providerOptions.find(p => p.value === provider).label
            })}
          </DazzedHeading20>
          <br />
          <DazzedHeading13>
            {requestSTLsStatus === AsyncStatus.Completed
              ? t('dialogs.patientInfo.txTrackerSetup.specialProviders.requestSTLsSubmitted')
              : t('dialogs.patientInfo.txTrackerSetup.specialProviders.description', {
                  provider: providerOptions.find(p => p.value === provider).label
                })}
          </DazzedHeading13>
          <br />
          <br />
          {requestSTLsStatus !== AsyncStatus.Completed ? (
            <PrimaryButton
              label={t('dialogs.patientInfo.txTrackerSetup.specialProviders.requestSTLs')}
              isLoading={requestSTLsStatus === AsyncStatus.Loading}
              onClick={onRequestProviderSTLs}
            />
          ) : null}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
export default DriveUploaderDropzone
