import React, { useMemo } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'

const useStyles = makeStyles(theme => ({
  item: {
    position: 'relative',
    height: 30
  },
  checked: {
    color: theme.palette.info.main
  },
  filledCheckedMark: {
    position: 'absolute',
    width: 10,
    height: 10,
    backgroundColor: 'var(--bg-color-7)'
  }
}))

const FilledCheckBox = ({ checked, label, containerStyle = '', labelStyle = '', onChange = () => {} }) => {
  const classes = useStyles()

  const checkboxOffComponent = useMemo(() => <CheckBoxOutlineBlank style={{ color: 'var(--border-color-13)' }} />, [])
  const checkboxOnComponent = useMemo(
    () => (
      <div className={'MuiIconButton-label'}>
        <CheckBoxOutlineBlank style={{ color: 'var(--border-color-13)' }} />
        <div className={classes.filledCheckedMark} />
      </div>
    ),
    [classes.filledCheckedMark]
  )

  return (
    <Grid container alignItems="center" className={classNames(classes.item, containerStyle)}>
      <Checkbox
        icon={checkboxOffComponent}
        checkedIcon={checkboxOnComponent}
        classes={{ checked: classes.checked }}
        checked={checked}
        onChange={(e, checked) => onChange(checked)}
      />
      <DazzedParagraph12 className={labelStyle}>{label}</DazzedParagraph12>
    </Grid>
  )
}

export default FilledCheckBox
