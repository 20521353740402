import * as React from 'react'

const PracticeAnalyticsQuickLink = ({ className }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6" y="10.6157" width="4.61538" height="16.1538" rx="0.769231" stroke="#3C51EE" />
      <rect x="21.3848" y="16.7695" width="4.61538" height="10" rx="0.769231" stroke="#3C51EE" />
      <rect x="13.6924" y="5.23107" width="4.61538" height="21.5385" rx="0.769231" stroke="#3C51EE" />
    </svg>
  )
}

export default PracticeAnalyticsQuickLink
