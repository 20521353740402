import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import SmallDotIcon from '../../common/icons/SmallDot'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  text: {
    color: 'var(--text-color-11)',
    lineHeight: 2.5
  },
  status: {
    margin: '0 8px'
  },
  statusColor: {
    marginRight: '5px'
  },
  bold: {
    fontWeight: 'bold',
    marginRight: '6px'
  }
}))

const ArchivedStatusMessage = ({ className = '' }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container justifyContent="center">
      <DazzedParagraph16 textAlign="center" className={[classes.text, className].join(' ')}>
        <span className={classes.bold}>{t('statuses.updateStatusModal.archivedMessage.note')}</span>
        {t('statuses.updateStatusModal.archivedMessage.line1')}
        <span className={classes.status}>
          <span className={classes.statusColor}>
            <SmallDotIcon color="#ABB0B8" />
          </span>
          <strong>{t(`statuses.names.archived`)}</strong>
        </span>
        {t(`statuses.updateStatusModal.archivedMessage.status`)}
      </DazzedParagraph16>
    </Grid>
  )
}

export default ArchivedStatusMessage
