import * as React from 'react'

function SvgSwoosh(props) {
  return (
    <svg width={73} height={54} fill="none" {...props}>
      <path d="M1 53c15.419.066 30.988-4.603 44.308-14.358S67.641 15.552 72 1" stroke="#FFBD39" strokeMiterlimit={10} />
    </svg>
  )
}

export default SvgSwoosh
