import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid, InputBase } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import LinkButton from 'components/common/buttons/LinkButton'

const useStyles = makeStyles(theme => ({
  noteInput: {
    width: '100%',
    fontSize: 14,
    fontWeight: '500'
  },
  noteInnerInput: {
    padding: '4px 0'
  }
}))

const CategoryNote = ({
  note = '',
  editMode = false,
  onSaveChanges = note => {},
  onCancelChanges = () => {},
  onEnterEditMode = () => {}
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [draftNote, setDraftNote] = useState('')

  const isVisible = useMemo(() => note || editMode, [note, editMode])

  const handleSave = useCallback(() => {
    onSaveChanges(draftNote)
  }, [onSaveChanges, draftNote])

  const onInputKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault()
        handleSave()
      } else if (e.key === 'Escape') {
        e.preventDefault()
        onCancelChanges()
      }
    },
    [handleSave, onCancelChanges]
  )

  useEffect(() => {
    if (!editMode) {
      setDraftNote(note)
    }
  }, [note, editMode])

  return (
    isVisible && (
      <Grid container direction="column">
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <DazzedParagraph14 bold>{t('dialogs.patientBrief.categoryNoteSectionTitle')}</DazzedParagraph14>
            </Grid>
            {editMode ? (
              <>
                <Grid item>
                  <LinkButton label={t('general.cancel')} fontSize={14} underline onClick={onCancelChanges} />
                </Grid>
                <Grid item>
                  <LinkButton label={t('general.done')} fontSize={14} underline onClick={handleSave} />
                </Grid>
              </>
            ) : (
              <Grid item>
                <LinkButton label={t('general.edit')} fontSize={14} underline onClick={onEnterEditMode} />
              </Grid>
            )}
          </Grid>
        </Grid>
        {editMode ? (
          <Grid item>
            <InputBase
              value={draftNote}
              onChange={e => setDraftNote(e.target.value)}
              className={classes.noteInput}
              onKeyDown={onInputKeyDown}
              autoFocus
              classes={{ input: classes.noteInnerInput }}
            />
          </Grid>
        ) : (
          <Grid item onClick={onEnterEditMode}>
            <DazzedParagraph14 strong>{note}</DazzedParagraph14>
          </Grid>
        )}
      </Grid>
    )
  )
}

export default CategoryNote
