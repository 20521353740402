import { API, graphqlOperation } from 'aws-amplify'
import { ofType } from 'redux-observable'
import { from, of, EMPTY } from 'rxjs'
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'
import Actions from '../actions'
import { createFeatureFlags, updateFeatureFlags, updateGrinUser } from '../graphql/mutations'

export const upsertFeatureFlagsTriggerEpic = action$ =>
  action$.pipe(
    ofType(Actions.SET_WELCOME_POPUP_STATE),
    map(({ payload }) => payload),
    mergeMap(isOpen => (isOpen ? of(Actions.requestUpsertFeatureFlags({ isWelcomePopupShowed: true })) : EMPTY))
  )

export const upsertFeatureFlagsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_UPSERT_FEATURE_FLAGS),
    withLatestFrom(state$),
    map(([action, state]) => ({
      flags: action.payload,
      user: state.profileReducer.doctor.user
    })),
    switchMap(({ flags, user }) =>
      from(!user?.featureFlags ? createUserFeatureFlags(user, flags) : updateUserFeatureFlags(user, flags)).pipe(
        mergeMap(updatedFlags => of(Actions.upsertFeatureFlagsReceived(updatedFlags))),
        catchError(err => of(Actions.upsertFeatureFlagsFailed(err)))
      )
    )
  )

const updateUserFeatureFlags = async (user, flags) => {
  const userFlags = user.featureFlags
  const currentFlags = JSON.parse(userFlags?.flags)
  const updatedUserFlags = await API.graphql(
    graphqlOperation(updateFeatureFlags, {
      input: {
        id: userFlags.id,
        flags: JSON.stringify({
          ...currentFlags,
          ...flags
        }),
        _version: userFlags._version
      }
    })
  )

  return updatedUserFlags.data.updateFeatureFlags
}

const createUserFeatureFlags = async (user, flags) => {
  const createFeatureFlagsResponse = await API.graphql(
    graphqlOperation(createFeatureFlags, {
      input: {
        username: user.username,
        featureFlagsUserId: user.id,
        flags: JSON.stringify(flags)
      }
    })
  )
  const userFlags = createFeatureFlagsResponse.data.createFeatureFlags
  await API.graphql(
    graphqlOperation(updateGrinUser, {
      input: {
        id: user.id,
        grinUserFeatureFlagsId: userFlags.id,
        _version: user._version
      }
    })
  )
  return userFlags
}
