import React, { useMemo, useCallback } from 'react'
import { makeStyles, Grid, Card, CardMedia } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from '../../../common/text/DazzedParagraph12'
import { TriangleCheckMark } from '../../../common/icons'
import { trackEvent } from 'utils/analyticsUtils'
import { isMobile } from 'utils/mobileUtils'
import { getObjectUrl } from 'utils/mediaUtils'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'

const useStyles = ({ isMobile }) =>
  makeStyles({
    container: {
      marginBottom: 14,
      cursor: 'pointer'
    },
    previewContainer: {
      width: isMobile ? '19.97%' : 70,
      height: 40,
      paddingLeft: 3,
      paddingRight: 3
    },
    previewContainerMobileFirst: {
      paddingLeft: 0
    },
    previewContainerMobileLast: {
      paddingRight: 0
    },
    previewImageFilter: {
      backgroundColor: 'var(--bg-color-12)',
      filter: 'blur(0.7px)',
      width: isMobile ? '100%' : 65
    },
    media: {
      opacity: 0.8,
      objectFit: 'cover'
    },
    mediaNotAvailable: {
      cursor: 'default',
      color: 'var(--text-color-11)',
      backgroundColor: 'var(--bg-color-35)',
      textAlign: 'center',
      fontSize: 9,
      height: '40px',
      boxShadow: 'none',
      display: 'flex',
      alignItems: 'center'
    },
    emptyState: {
      color: 'var(--text-color-17)',
      marginBottom: 5,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: 5
      }
    },
    scanSummaryPendingText: {
      color: 'var(--text-color-11)',
      marginBottom: 4
    }
  })({ isMobile })

const ScanSummary = ({ scan, scanNumber }) => {
  const classes = useStyles({ isMobile: isMobile() })
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { patient } = useSelector(state => state.patientsReducer)

  const scanSummaryData = useMemo(() => JSON.parse(scan?.scanSummaryData || '{}'), [scan])
  const previewItems = useMemo(() => Object.entries(scanSummaryData?.normalImages || {}), [scanSummaryData])

  const isScanSummaryFailed = useMemo(
    () =>
      (scan.scanSummaryStatus !== 'completed' && scan.scanSummaryStatus !== 'pending') ||
      previewItems.filter(image => !!image[1]).length < 4,
    [previewItems, scan]
  )

  const handleImageClick = useCallback(
    (position, url) => {
      trackEvent('Scan Summary - scan summary opened', {
        patientId: patient.id,
        scanId: scan.id,
        scanNumber,
        newScanSummaryArchitecture: url?.key ? url.key.includes('private') : false
      })
      dispatch(
        Actions.setScanSummaryModalOpen({
          isOpen: true,
          scanId: scan.id,
          activeImage: url ? position : 'center'
        })
      )
    },
    [dispatch, patient, scan.id, scanNumber]
  )

  return scan.scanSummaryStatus === 'pending' ? (
    <DazzedParagraph12 className={classes.scanSummaryPendingText}>{t('dialogs.scanSummary.pending')}</DazzedParagraph12>
  ) : !isScanSummaryFailed ? (
    <>
      <Grid container direction="row" spacing={isMobile() ? 0 : 2} className={classes.container}>
        {previewItems.map(([position, url], index) => (
          <Grid
            item
            key={position}
            className={[
              classes.previewContainer,
              isMobile() && index === 0 && classes.previewContainerMobileFirst,
              isMobile() && index === previewItems.length - 1 && classes.previewContainerMobileLast
            ].join(' ')}
            onClick={() => handleImageClick(position, url)}
          >
            <Card className={classes.previewImageFilter}>
              {url ? (
                <CardMedia className={classes.media} component="img" height="40" src={getObjectUrl(url)} />
              ) : (
                <div className={classes.mediaNotAvailable} height="40">
                  {t('dialogs.scanSummary.couldntBeCaptured')}
                </div>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  ) : (
    <DazzedParagraph12 className={classes.emptyState}>
      <TriangleCheckMark />
      {t('dialogs.scanSummary.couldntBeGenerated')}
    </DazzedParagraph12>
  )
}

export default ScanSummary
