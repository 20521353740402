import * as React from 'react'

const Eye1 = props => {
  return (
    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.25 3.625C10.836 3.63147 10.425 3.69724 10.0297 3.82031C10.2125 4.14192 10.3099 4.50504 10.3125 4.875C10.3125 5.16227 10.2559 5.44672 10.146 5.71212C10.036 5.97752 9.8749 6.21867 9.67177 6.4218C9.46865 6.62492 9.2275 6.78605 8.9621 6.89599C8.6967 7.00592 8.41225 7.0625 8.12498 7.0625C7.75502 7.05993 7.3919 6.96256 7.07029 6.77969C6.81655 7.65969 6.84613 8.59721 7.15482 9.45947C7.46352 10.3217 8.03568 11.065 8.79028 11.584C9.54487 12.103 10.4436 12.3714 11.3593 12.3513C12.2749 12.3311 13.161 12.0233 13.892 11.4716C14.623 10.9199 15.1618 10.1521 15.4323 9.2771C15.7027 8.40209 15.6909 7.46417 15.3987 6.5962C15.1064 5.72823 14.5485 4.97421 13.8039 4.44096C13.0593 3.9077 12.1658 3.62224 11.25 3.625ZM22.364 7.42969C20.2457 3.29648 16.0519 0.5 11.25 0.5C6.44803 0.5 2.2531 3.29844 0.135916 7.43008C0.0465575 7.60684 0 7.80213 0 8.0002C0 8.19826 0.0465575 8.39355 0.135916 8.57031C2.25428 12.7035 6.44803 15.5 11.25 15.5C16.0519 15.5 20.2469 12.7016 22.364 8.56992C22.4534 8.39316 22.5 8.19787 22.5 7.9998C22.5 7.80174 22.4534 7.60645 22.364 7.42969ZM11.25 13.625C7.39646 13.625 3.86365 11.4766 1.95584 8C3.86365 4.52344 7.39607 2.375 11.25 2.375C15.1039 2.375 18.6363 4.52344 20.5441 8C18.6367 11.4766 15.1039 13.625 11.25 13.625Z"
        fill="#AFAFAF"
      />
    </svg>
  )
}

export default Eye1
