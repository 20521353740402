import React, { useCallback, useMemo, useState } from 'react'
import { APPLIANCE_SETS_MAX_NUMBER, APPLIANCE_SETS_MIN_NUMBER } from 'consts/treatmentConsts'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { conditionTypes } from 'consts/automatedActionConsts'
import ErrorMessage from 'components/common/text/ErrorMessage'

const useStyles = makeStyles(({ errorMessage }) => ({
  alignerNumberInput: {
    outline: 'none',
    fontSize: 16,
    textAlign: 'center',
    height: 32,
    width: 80,
    border: 'none',
    borderBottom: ({ errorMessage }) => (errorMessage ? '1px solid red' : '1px solid var(--border-color-11)'),
    paddingLeft: 14
  }
}))

const AlignerNumberCondition = ({ onChange, params, errors }) => {
  const errorMessage = useMemo(() => errors.conditions?.[conditionTypes.AlignerNumber], [errors])
  const classes = useStyles({ errorMessage })
  const { t } = useTranslation()

  const [alignerNumber, setAlignerNumber] = useState(params?.alignerNumber || '')

  const handleAlignerNumberChange = useCallback(
    newNumber => {
      onChange({
        type: conditionTypes.AlignerNumber,
        params: {
          alignerNumber: newNumber
        }
      })
      setAlignerNumber(newNumber)
    },
    [onChange]
  )

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <DazzedParagraph14 strong>
              {t('pages.accountSettings.automations.editorModal.enterAlignerNumber')}
            </DazzedParagraph14>
          </Grid>
          <Grid item>
            <input
              value={alignerNumber}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              placeholder={t('pages.accountSettings.automations.editorModal.enterAlignerNumberPlaceholder')}
              className={`${classes.alignerNumberInput} ${errorMessage ? classes.errorMessageInput : ''}`}
              onChange={({ target }) => handleAlignerNumberChange(target.value)}
              min={APPLIANCE_SETS_MIN_NUMBER}
              max={APPLIANCE_SETS_MAX_NUMBER}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>{errorMessage && <ErrorMessage text={errorMessage} active={!!errorMessage} />}</Grid>
    </Grid>
  )
}
export default AlignerNumberCondition
