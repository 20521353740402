import { ButtonBase, CircularProgress, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import usePlanLimits from 'hooks/usePlanLimits'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import useRolePermissions from 'hooks/useRolePermissions'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { trackEvent } from 'utils/analyticsUtils'
import { isUserOfRole } from 'utils/authUtils'
import { isOnFreePlan } from 'utils/billingUtils'
import Actions from '../../actions'
import SecondaryButton from '../../components/common/buttons/SecondaryButton'
import AddScopes from '../../components/common/icons/AddScopes'
import GrinTab from '../../components/common/tabs/GrinTab'
import GrinTabs from '../../components/common/tabs/GrinTabs'
import DazzedParagraph14 from '../../components/common/text/DazzedParagraph14'
import { Roles } from '../../consts/authConsts'
import ROUTES from '../../consts/routesConsts'
import OggHeading40 from '../common/text/OggHeading40'

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: '1px solid rgba(0, 19, 53, 0.1)',
    width: '100%',
    height: '100%'
  },
  loaderContainer: {
    position: 'absolute',
    transformOrigin: '50% 0',
    top: '50%',
    left: '50%'
  },
  content: {
    width: '80%',
    maxWidth: 1500,
    height: '100%',
    margin: '0 auto'
  },
  toolbar: {
    margin: '40px 0px 16px 40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  toolbarActions: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: 5
    }
  },
  dashboardContainer: {
    width: '100%',
    display: 'flex',
    height: '100%',
    justifyContent: 'center'
  },
  dashboardIframe: {
    width: '100%',
    height: '150%'
  },
  buttonText: {
    color: 'var(--text-color-3)',
    lineHeight: '22px '
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  orderScopesWrapper: {
    marginRight: '20px',
    borderBottom: '1px solid transparent',
    '&:hover': {
      borderBottom: '1px solid var(--bg-color-7)'
    }
  },
  upgradePlanWrapper: {
    padding: '10px',
    textAlign: 'center'
  }
}))

const DoctorStats = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const planLimits = usePlanLimits()
  const { isLoading, dashboardUrls } = useSelector(state => state.profileReducer.newDashboardStats)
  const userPlan = useSelector(state => state.profileReducer.grinPlan)
  const [activeTab, setActiveTab] = useState(0)
  const { isRcEnabled } = usePracticeConfiguration()
  const { permissions } = useRolePermissions()
  const { isOnHiOrGiPlan } = usePlanLimits()

  const tabNames = useMemo(
    () => [
      'pratice-overview',
      'patients-activity',
      'patients-communications',
      'exports',
      'remote-consultation',
      'treatment-tracking'
    ],
    []
  )
  const isDso = useMemo(() => permissions.dsoAnalytics, [permissions])

  const handleUpgradePlanClick = useCallback(() => history.push(ROUTES.BILLING_SUBSCRIPTION), [history])
  const handleChangeTab = useCallback(
    (e, value) => {
      setActiveTab(value)
      trackEvent('Doctor dashboard tab Clicked', {
        tab: tabNames[value]
      })
    },
    [tabNames]
  )

  useEffect(() => {
    if (isUserOfRole([Roles.Admin])) {
      history.push(ROUTES.STATS_DASHBOARD)
    }
  }, [history])

  useEffect(() => {
    dispatch(Actions.fetchNewDoctorDashboardUrl())
  }, [dispatch])

  const userType = useMemo(() => (isDso ? 'dso' : 'practice'), [isDso])

  const tabsInfo = useMemo(
    () => ({
      practice: {
        0: {
          label: t(`pages.accountSummary.practice.tab0Title`),
          condition: true
        },
        1: {
          label: t(`pages.accountSummary.practice.tab1Title`),
          condition: true
        },
        2: {
          label: t(`pages.accountSummary.practice.tab2Title`),
          condition: true
        },
        3: {
          label: t(`pages.accountSummary.practice.tab3Title`),
          condition: true
        },
        4: {
          label: t(`pages.accountSummary.practice.tab4Title`),
          condition: isRcEnabled
        },
        5: {
          label: t(`pages.accountSummary.practice.tab5Title`),
          condition: isOnHiOrGiPlan
        }
      },
      dso: {
        0: {
          label: t(`pages.accountSummary.dso.tab0Title`),
          condition: true
        },
        1: {
          label: t(`pages.accountSummary.dso.tab1Title`),
          condition: true
        },
        2: {
          label: t(`pages.accountSummary.dso.tab2Title`),
          condition: true
        },
        3: {
          label: t(`pages.accountSummary.dso.tab3Title`),
          condition: true
        }
      }
    }),
    [t, isRcEnabled, isOnHiOrGiPlan]
  )

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.toolbar}>
          <OggHeading40 textAlign="left">{t(`pages.accountSummary.title.${isDso ? 'dso' : 'practice'}`)}</OggHeading40>
          {!isDso && (
            <div className={classes.buttonsContainer}>
              <ButtonBase
                onClick={() =>
                  !isOnFreePlan(userPlan) || planLimits.maxScopes > 0
                    ? dispatch(Actions.setOrderGrinKitsModalVisibility(true))
                    : dispatch(Actions.setUpgradePlanForScopesWarningModalVisibility(true))
                }
                className={classes.orderScopesWrapper}
              >
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <AddScopes />
                  </Grid>
                  <Grid item>
                    <DazzedParagraph14 className={classes.buttonText}>
                      {t('pages.accountSummary.orderScopesBtn')}
                    </DazzedParagraph14>
                  </Grid>
                </Grid>
              </ButtonBase>
              <div className={classes.upgradePlanWrapper}>
                <SecondaryButton
                  label={t('pages.accountSummary.upgradePlanBtn')}
                  fontSize={14}
                  height={33}
                  width={134}
                  fontWeight={500}
                  disabled={false}
                  onClick={handleUpgradePlanClick}
                />
              </div>
            </div>
          )}
        </div>
        <GrinTabs value={activeTab} variant={'fullWidth'} centered={false} onChange={handleChangeTab}>
          {Object.entries(tabsInfo[userType]).map(
            ([pageIndex, tabInfo]) =>
              tabInfo.condition && (
                <GrinTab key={pageIndex} label={tabInfo.label} marginRight="0px" value={Number(pageIndex)} />
              )
          )}
        </GrinTabs>
        {isLoading ? (
          <div className={classes.loaderContainer}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <div className={classes.dashboardContainer}>
            <iframe
              title="dashboard"
              src={dashboardUrls ? dashboardUrls[activeTab] : ''}
              frameBorder="0"
              className={classes.dashboardIframe}
            ></iframe>
          </div>
        )}
      </div>
    </div>
  )
}

export default DoctorStats
