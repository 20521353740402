import React, { useCallback, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { filterObjectByFunction } from 'utils/generalUtils'
import { ActionTypes } from 'consts/hiToolsConsts'
import NotifyPractice from './Actions/NotifyPractice'
import InstructForChewies from './Actions/InstructForChewies'
import Frequency from './Actions/Frequency'
import KeepAlignersOrGoBack from './Actions/KeepAlignersOrGoBack'
import SendBrushingInstructions from './Actions/SendBrushingInstructions'
import TypicalWireSequence from './Actions/TypicalWireSequence'
import MessagePatient from './Actions/MessagePatient'

const useStyles = makeStyles(theme => ({
  title: {
    color: 'var(--text-color-11)'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20
  },
  actionsContainer: {
    padding: 10
  }
}))

const PracticeGuidelineGenericCategory = ({ items }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const actions = useMemo(
    () => [
      {
        key: ActionTypes.MessagePatient,
        component: MessagePatient
      },
      {
        key: ActionTypes.NotifyPractice,
        component: NotifyPractice
      },
      {
        key: ActionTypes.InstructForChewies,
        component: InstructForChewies
      },
      {
        key: ActionTypes.Frequency,
        component: Frequency
      },
      {
        key: ActionTypes.KeepAlignersOrGoBack,
        component: KeepAlignersOrGoBack
      },
      {
        key: ActionTypes.SendBrushingInstructionsToThePatient,
        component: SendBrushingInstructions
      },
      {
        key: ActionTypes.TypicalWireSequence,
        component: TypicalWireSequence
      }
    ],
    []
  )

  const isActionEnabled = useCallback(
    ({ value, isChecked, frequency, shouldGoBack, shouldKeepAligner, days }) =>
      !!value || isChecked || frequency || shouldGoBack || (shouldKeepAligner && days),
    []
  )

  const filteredItems = useMemo(() => {
    if (!items) {
      return null
    }

    // filter items with only the enabled actions and filter out the disabled ones
    const itemsWithFilteredActions = filterObjectByFunction(items, isActionEnabled)

    // filter out items which have no actions, after filtering the disabled ones
    const filteredEmptyItems = Object.keys(itemsWithFilteredActions).reduce(
      (filteredItems, itemKey) => ({
        ...filteredItems,
        ...(Object.values(itemsWithFilteredActions[itemKey]).length > 0
          ? { [itemKey]: itemsWithFilteredActions[itemKey] }
          : {})
      }),
      {}
    )

    return filteredEmptyItems
  }, [isActionEnabled, items])

  return (
    filteredItems &&
    Object.keys(filteredItems).map(itemKey => (
      <div key={itemKey} style={{ width: '100%' }}>
        <div className={classes.itemContainer}>
          <div>
            <DazzedParagraph14 bold className={classes.title}>
              {`${t(`dialogs.hiPatientGuidelinesViewer.items.${itemKey}`)}:`}
            </DazzedParagraph14>
          </div>
          <div className={classes.actionsContainer}>
            <Grid container spacing={1}>
              {Object.keys(filteredItems[itemKey]).map(actionKey => (
                <Grid item xs={6} key={actionKey}>
                  {React.createElement(actions.find(action => action.key === actionKey)?.component, {
                    ...(filteredItems?.[itemKey]?.[actionKey] || {})
                  })}
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    ))
  )
}
export default PracticeGuidelineGenericCategory
