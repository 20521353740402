import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements } from '@stripe/react-stripe-js'
import { isEmpty } from 'lodash'
import { isMobile } from 'react-device-detect'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import FormInput from 'components/common/FormInput'
import { Calendar6, CreditCard2, Cvv } from 'components/common/icons'
import CreditCardTermsOfSale from './TermsOfSale'
import StripeInputWrapper from 'components/common/inputs/billing/StripeInputWrapper'
import BillingAddressForm from 'components/common/inputs/BillingAddressForm'
import GrinLabel from 'components/common/text/GrinLabel'
import { useDispatch, useSelector } from 'react-redux'
import { isValidZipcode, toAddressString } from 'utils/generalUtils'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import Actions from 'actions'
import BaseModal from 'components/common/modals/BaseModal'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%'
  },
  formContainer: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  cardholderName: {
    '& input': {
      height: '42px',
      paddingLeft: 15
    }
  },
  inputIcon: {
    height: 'auto !important',
    display: 'flex',
    alignItems: 'center'
  },
  skipButton: {
    border: 'none',
    color: 'var(--text-color-1)',
    textDecoration: 'underline'
  },
  billingAddressLabel: {
    color: '#fff',
    opacity: 1,
    marginBottom: 20
  },
  billingAddressToggle: {
    color: 'var(--text-color-10)',
    cursor: 'pointer',
    textDecoration: 'underline',
    padding: '28px 16px'
  },
  addressErrorBox: {
    backgroundColor: 'var(--bg-color-46)',
    color: 'var(--text-color-17)',
    border: '1px solid var(--border-color-17)',
    padding: 16,
    margin: '15px 8px 25px 8px'
  }
}))

const CreditCardForm = ({
  onSubmit,
  onSkip,
  skipable,
  isLoading,
  mixpanelPrefix,
  mixpanelPayload,
  clinicAddress = {}
}) => {
  const classes = useStyles()
  const elements = useElements()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [errors, setErrors] = useState({})
  const [cardHolderName, setCardHolderName] = useState('')
  const [completedFields, setCompletedFields] = useState({})
  const [agreedTerms, setAgreedTerms] = useState(false)
  const [differentBillingAddressToggle, setDifferentBillingAddressToggle] = useState(false)
  const [billingAddress, setBillingAddress] = useState({})
  const [shouldSkipBillingInfo, setShouldSkipBillingInfo] = useState(false)

  const {
    isLoading: isValidatingAddress,
    validationResult,
    dirty
  } = useSelector(state => state.billingReducer.addressValidation)

  const hasErrors = useMemo(() => isEmpty(errors), [errors])
  const isCardCompleted = useMemo(
    () =>
      completedFields.cardNumber &&
      completedFields.cardExpiry &&
      completedFields.cardCvc &&
      !!cardHolderName &&
      billingAddress.country?.trim() &&
      isValidZipcode(billingAddress.zip, billingAddress.country) &&
      billingAddress.city?.trim() &&
      billingAddress.address1?.trim(),
    [cardHolderName, completedFields, billingAddress]
  )

  const handleCardChange = useCallback(cardData => {
    setCompletedFields(completed => ({
      ...completed,
      [cardData.elementType]: cardData.complete
    }))

    setErrors(errors => ({
      ...errors,
      [cardData.elementType]: cardData.error?.message
    }))
  }, [])

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault()
      if (hasErrors || !isCardCompleted || !cardHolderName) {
        return
      }

      dispatch(
        Actions.validateAddress({
          state: billingAddress.state,
          street: billingAddress.address1,
          zip: billingAddress.zip,
          country: billingAddress.country,
          city: billingAddress.city
        })
      )
    },
    [hasErrors, isCardCompleted, billingAddress, dispatch, cardHolderName]
  )

  const closeModal = useCallback(() => setShouldSkipBillingInfo(false), [setShouldSkipBillingInfo])

  useEffect(() => {
    if (dirty && validationResult && !hasErrors && isCardCompleted) {
      onSubmit({ element: elements.getElement(CardNumberElement), cardHolderName, billingAddress })
      dispatch(Actions.resetAddressValidationResult())
    }
  }, [
    elements,
    onSubmit,
    dirty,
    validationResult,
    hasErrors,
    cardHolderName,
    billingAddress,
    isCardCompleted,
    dispatch
  ])

  useEffect(() => {
    if (!differentBillingAddressToggle) {
      setBillingAddress({
        country: clinicAddress.country,
        state: clinicAddress.state,
        zip: clinicAddress.zip,
        city: clinicAddress.city,
        address1: clinicAddress.address1
      })
    }
  }, [clinicAddress, differentBillingAddressToggle])

  useEffect(() => {
    dispatch(Actions.resetAddressValidationResult())
  }, [dispatch])

  return (
    <>
      <form onSubmit={handleSubmit} id="credit-card-form" className={classes.root}>
        <Grid container direction="column" spacing={2} justifyContent="space-between">
          <Grid item className={classes.formContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={6}>
                    <StripeInputWrapper
                      label={t('pages.billingInfo.card.label')}
                      stripeElement={CardNumberElement}
                      withBrandIcon={false}
                      onChange={handleCardChange}
                      error={errors.cardNumber}
                      variant="dark"
                      placeholder={t('pages.billingInfo.card.placeholder')}
                      icon={<CreditCard2 />}
                      iconClassName={classes.inputIcon}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={isMobile ? 0 : 4}>
                      <Grid item xs={12} md={6}>
                        <StripeInputWrapper
                          label={t('pages.billingInfo.expiryDate.label')}
                          icon={<Calendar6 />}
                          stripeElement={CardExpiryElement}
                          onChange={handleCardChange}
                          error={errors.cardExpiry}
                          variant="dark"
                          placeholder={t('pages.billingInfo.expiryDate.placeholder')}
                          iconClassName={classes.inputIcon}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <StripeInputWrapper
                          label={t('pages.billingInfo.cvv.label')}
                          icon={<Cvv />}
                          stripeElement={CardCvcElement}
                          onChange={handleCardChange}
                          error={errors.cardCvc}
                          variant="dark"
                          placeholder={t('pages.billingInfo.cvv.placeholder')}
                          iconClassName={classes.inputIcon}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={6}>
                    <FormInput
                      title={t('pages.billingInfo.name.label')}
                      value={cardHolderName}
                      setValue={setCardHolderName}
                      style={{ bright: false, thick: true }}
                      errorMessage={errors.cardHolderName}
                      containerClassName={classes.cardholderName}
                      name="cardname"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={3}></Grid>
                  <Grid item xs={9}>
                    {differentBillingAddressToggle ? (
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <GrinLabel className={classes.billingAddressLabel}>
                            {t('pages.billingInfo.billingAddress.title')}
                          </GrinLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={8}>
                              <BillingAddressForm theme="dark" values={billingAddress} onChange={setBillingAddress} />
                            </Grid>
                            <Grid item xs={4}>
                              <DazzedParagraph16
                                className={classes.billingAddressToggle}
                                strong
                                textAlign="start"
                                onClick={() => setDifferentBillingAddressToggle(false)}
                              >
                                {t('pages.billingInfo.billingAddress.sameAsShippingAddress')}
                              </DazzedParagraph16>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={8}>
                          <FormInput
                            title={t('pages.billingInfo.billingAddress.title')}
                            style={{ bright: false, thick: true }}
                            value={toAddressString(clinicAddress)}
                            setValue={() => {}}
                            isDisabled={true}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <DazzedParagraph16
                            className={classes.billingAddressToggle}
                            strong
                            textAlign="start"
                            onClick={() => setDifferentBillingAddressToggle(true)}
                          >
                            {t('pages.billingInfo.billingAddress.changeAddress')}
                          </DazzedParagraph16>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {dirty && !validationResult && !isValidatingAddress && (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6} className={classes.addressErrorBox}>
                      {t('errors.invalidAddress')}
                    </Grid>
                    <Grid item xs={3}></Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={6}>
                    <CreditCardTermsOfSale
                      checked={agreedTerms}
                      onChange={setAgreedTerms}
                      mixpanelPrefix={mixpanelPrefix}
                      mixpanelPayload={mixpanelPayload}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={6}>
                    <PrimaryButton
                      label={t('general.done')}
                      type="submit"
                      width="100%"
                      disabled={isLoading || !isCardCompleted || !agreedTerms || isValidatingAddress}
                      isLoading={isLoading || isValidatingAddress}
                      id={`${mixpanelPrefix}-billing-done-btn`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {skipable && (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6}>
                      <SecondaryButton
                        label={t('general.skip')}
                        onClick={() => setShouldSkipBillingInfo(true)}
                        width="100%"
                        transparentBackground
                        id={`${mixpanelPrefix}-billing-skip-btn`}
                        className={classes.skipButton}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
      <BaseModal
        title={t('creditCard.skipModalTitle')}
        open={shouldSkipBillingInfo}
        variant="alert"
        onPrimaryBtnClick={onSkip}
        onSecondaryBtnClick={closeModal}
        handleClose={closeModal}
        primaryLabel={t('general.skip')}
        secondaryLabel={t('general.cancel')}
      />
    </>
  )
}

export default CreditCardForm
