import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Grid } from '@material-ui/core'
import DazzedParagraph14 from '../text/DazzedParagraph14'
import ThumbnailUnavailable from '../icons/timelineV2/mini-timeline/ThumbnailUnavailable'
import { ScanSummaryStatus } from 'consts/scanSummaryConsts'

const useStyles = makeStyles(theme => ({
  scanSummaryIncompleteState: {
    padding: 24
  }
}))

const ScanSummaryIncompleteState = ({ scanSummaryStatus }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container className={classes.scanSummaryIncompleteState} alignItems="center" spacing={1}>
      <Grid item>
        {scanSummaryStatus === ScanSummaryStatus.Pending ? <CircularProgress size={14} /> : <ThumbnailUnavailable />}
      </Grid>
      <Grid item>
        <DazzedParagraph14 strong>
          {scanSummaryStatus === ScanSummaryStatus.Pending
            ? t('pages.patients.selectedPatient.timelineV2.generatingScanSummary')
            : t('pages.patients.selectedPatient.timelineV2.scanSummaryFailed')}
        </DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default ScanSummaryIncompleteState
