import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import ApproveFilledIcon from 'components/common/icons/ApproveFilled'
import ApproveOutlinedIcon from 'components/common/icons/ApproveOutlined'
import DeclineFilledIcon from 'components/common/icons/DeclineFilled'
import DeclineOutlinedIcon from 'components/common/icons/DeclineOutlined'
import { ButtonBase, Grid } from '@material-ui/core'
import Tooltip from 'components/common/Tooltip'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  icon: {
    width: 32,
    height: 32,
    margin: '0 6px'
  },
  button: {
    '&.disabled': {
      opacity: 0.3
    }
  }
}))

const ScanApproval = ({ scan, onFeedbackSelected }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const isSubmittingScanFeedback = useSelector(state => state.treatmentReducer.isSubmittingScanFeedback)
  const currentFeedback = useMemo(() => JSON.parse(scan?.metadata || '{}').feedback, [scan])

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <Tooltip placement="bottom" value={t('pages.patients.selectedPatient.timeline.scanFeedbackPositive')} delay={0}>
          <ButtonBase
            className={[classes.button, isSubmittingScanFeedback ? 'disabled' : ''].join(' ')}
            onClick={() => onFeedbackSelected('positive')}
            disabled={isSubmittingScanFeedback}
          >
            {currentFeedback === 'positive' ? (
              <ApproveFilledIcon className={classes.icon} />
            ) : (
              <ApproveOutlinedIcon className={classes.icon} />
            )}
          </ButtonBase>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip placement="bottom" value={t('pages.patients.selectedPatient.timeline.scanFeedbackNegative')} delay={0}>
          <ButtonBase
            className={[classes.button, isSubmittingScanFeedback ? 'disabled' : ''].join(' ')}
            onClick={() => onFeedbackSelected('negative')}
            disabled={isSubmittingScanFeedback}
          >
            {currentFeedback === 'negative' ? (
              <DeclineFilledIcon className={classes.icon} />
            ) : (
              <DeclineOutlinedIcon className={classes.icon} />
            )}
          </ButtonBase>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default ScanApproval
