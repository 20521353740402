import * as React from 'react'
const PracticeAnalytics = ({ ...props }) => {
  return (
    <svg width="20" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="9" width="4" height="8" rx="1" stroke="#041333" />
      <rect x="7" y="1" width="4" height="16" rx="1" stroke="#041333" />
      <rect x="13" y="4" width="4" height="13" rx="1" stroke="#041333" />
    </svg>
  )
}

export default PracticeAnalytics
