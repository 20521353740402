import * as React from 'react'

export default ({ color = '#3C52EF' }) => (
  <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 14L7.14645 7.85355C7.34171 7.65829 7.34171 7.34171 7.14645 7.14645L1 0.999999"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
