import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Route, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import queryString from 'query-string'
import { Grid } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import useHideHelpWidget from 'hooks/useHideHelpWidget'
import Actions from 'actions'
import ROUTES from 'consts/routesConsts'
import PatientsHeaderMobile from './PatientsHeader.mobile'
import PatientsList from './PatientsList/PatientsList'
import PatientsFilter from '../Search/PatientsFilter'
import useParsedFilters from 'hooks/useParsedFilters'
import useCssClasses from 'hooks/useCssClasses'
import useBroadcast from 'hooks/useBroadcast'
import PatientMobile from './Patient.mobile'
import PatientCard from './PatientCard/PatientCard.mobile'
import usePatientsFilter from 'components/Search/hooks/usePatientsFilter'

const useStyles = ({ isFilterActivated, selectedTab, browserHeight }) =>
  makeStyles(theme => ({
    root: {
      width: '100%'
    },
    patientList: {
      height: `calc(${browserHeight} - ${isFilterActivated ? '240px' : '185px'})`
    },
    patientTimeline: {
      height: `calc(${browserHeight} - 265px)`
    }
  }))({ isFilterActivated, selectedTab, browserHeight })

const PatientsMobile = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname, search } = useLocation()
  const browserClass = useCssClasses()
  const { closeBroadcastMode } = useBroadcast()

  useHideHelpWidget()

  const { setDoctorFilter, patientsSearchFilter, setPatientsSearchFilter } = usePatientsFilter()

  const { pinnedPatient, filter } = useSelector(state => state.chatReducer)
  const doctor = useSelector(state => state.profileReducer.doctor)
  const selectedTab = useSelector(state => state.appReducer.mobile.selectedTab)
  const patient = useSelector(state => state.patientsReducer.patient)

  const patientsTagsFilter = useMemo(() => filter?.tagsFilter, [filter])

  const [isFilterOpen, setIsFilterOpen] = useState(null) //have to use null as initial value because we need to skip initial renders

  const { isFilterActivated } = useParsedFilters(patientsTagsFilter)
  const classes = useStyles({ isFilterActivated, selectedTab, browserHeight: browserClass.height })

  useEffect(() => {
    const checkIfSignedIn = async () => {
      try {
        await Auth.currentAuthenticatedUser()
      } catch (err) {
        history.push(ROUTES.WELCOME)
      }
    }

    checkIfSignedIn()
  }, [history])

  useEffect(() => {
    if (pathname === ROUTES.PATIENTS && pinnedPatient) {
      dispatch(Actions.unpinChatRoom())
    }
  }, [dispatch, pathname, pinnedPatient])

  const handleRedirectToPatients = useCallback(() => dispatch(Actions.resetPatient()), [dispatch])

  const pageMode = useMemo(() => queryString.parse(search).mode, [search])

  useEffect(() => {
    if (pageMode !== 'broadcast') {
      closeBroadcastMode('Mobile page Changed')
    }
  }, [closeBroadcastMode, pageMode])

  return (
    <Grid container className={classes.root}>
      {doctor?.id && (
        <Route path={ROUTES.PATIENTS} exact>
          <Grid item xs={12}>
            <PatientsFilter
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
              isFilterActivated={isFilterActivated}
              freeTextFilter={patientsSearchFilter}
              onFreeTextFilterChange={setPatientsSearchFilter}
              onRedirectToPatients={handleRedirectToPatients}
            />
          </Grid>
          <Grid item xs={12} className={classes.patientList}>
            <PatientsList setDoctorFilter={setDoctorFilter} />
          </Grid>
        </Route>
      )}
      <Route path={`${ROUTES.PATIENTS}/:patientId`}>
        <Grid item xs={12}>
          <PatientsHeaderMobile />
        </Grid>
        <Grid item xs={12} className={classes.patientTimeline}>
          <PatientMobile />
        </Grid>
      </Route>
      {!!Object.keys(patient).length && <PatientCard patient={patient} />}
    </Grid>
  )
}

export default PatientsMobile
