import React, { useCallback } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useDispatch, useSelector } from 'react-redux'
import actions from 'actions'
import {
  EmptyStateInviteMultiplePatients,
  EmptyStateInvitePatient,
  NoPatientsEmptyState
} from 'components/common/icons'
import OggHeading40 from 'components/common/text/OggHeading40'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { getEnvironmentHostname } from 'utils/awsUtils'
import { ROUTES } from 'consts'

const useStyles = () =>
  makeStyles({
    container: {
      paddingLeft: 170
    }
  })()

const InvitePatients = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const doctorName = useSelector(state => state.profileReducer.doctor.name)

  const handleOpenInvitePatientModal = useCallback(() => {
    trackEvent('Invite patient - modal opened', {
      source: 'Chat empty state'
    })
    dispatch(actions.setInvitePatientModalVisibility(true))
  }, [dispatch])

  const handleInviteMultilpeClick = useCallback(() => {
    const bulkInviteUrl = `${getEnvironmentHostname()}${ROUTES.ADVANCED_ACTIONS}`
    window.open(bulkInviteUrl, '_blank')
  }, [])

  return (
    <Grid container alignItems="center" spacing={2} className={classes.container}>
      <Grid item>
        <NoPatientsEmptyState />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="flex-start" justifyContent="space-between" spacing={5}>
          <Grid item>
            <OggHeading40>{t('pages.patients.getStartedSection.title')}</OggHeading40>
          </Grid>
          <Grid item>
            <DazzedParagraph14>
              <Trans
                i18nKey={'pages.patients.getStartedSection.descriptionLine'}
                values={{
                  doctorName
                }}
              />
            </DazzedParagraph14>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <PrimaryButton
                  id="get-started-invite-patient"
                  disableIconMargin
                  icon={<EmptyStateInvitePatient />}
                  label={t('pages.patients.getStartedSection.invitePatientBtn')}
                  onClick={handleOpenInvitePatientModal}
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  id="get-started-invite-multiple-patients"
                  disableIconMargin
                  icon={<EmptyStateInviteMultiplePatients />}
                  label={t('pages.patients.getStartedSection.inviteMultiplePatientsBtn')}
                  onClick={handleInviteMultilpeClick}
                  width={250}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InvitePatients
