import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Trans, useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { Grid } from '@material-ui/core'
import { SendMobile } from 'components/common/icons'
import { Clock as ClockIcon } from 'components/common/icons'
import SelectInput from 'components/common/SelectInput'
import moment from 'moment'
import CustomDatePicker from 'components/common/CustomDatePicker'
import useScheduledMessages from './useScheduledMessages'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20
  },
  title: {
    fontFamily: 'Ogg',
    fontWeight: 400,
    fontSize: 32
  },
  bodyContainer: {
    margin: '20px 0'
  },
  centerText: {
    textAlign: 'center'
  }
}))

const ScheduleMessagePopupMobile = ({ onSend = () => {}, isOpen = false, onClose = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    recipientTimezone,
    selectedDate,
    selectedTime,
    setSelectedDate,
    setSelectedTime,
    errors,
    maxDate,
    timeOptions,
    recipientScheduleTime,
    recipintTimezoneOffset,
    handleSend
  } = useScheduledMessages({ onSend, onClose, isModalOpen: isOpen })

  return (
    <BaseModal
      variant="fullscreen"
      open={isOpen}
      title={t('pages.patients.selectedPatient.chat.scheduleMessagePopup.title')}
      titleClassName={classes.title}
      onPrimaryBtnClick={handleSend}
      primaryLabel={t('pages.patients.selectedPatient.chat.scheduleMessagePopup.submitButton')}
      primaryBtnIcon={<SendMobile />}
      largerPrimaryButton
      handleClose={onClose}
      secondaryLabel={t('general.cancel')}
      onSecondaryBtnClick={onClose}
      className={classes.root}
    >
      <Grid container className={classes.bodyContainer}>
        <Grid item md={6} xs={12} className={classes.datePicker}>
          <CustomDatePicker
            inputVariant="outlined"
            disablePast
            value={selectedDate}
            maxDate={maxDate}
            handleDateChange={value => setSelectedDate(moment(value))}
            format="MMM dd, yyyy"
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item md={5} xs={12}>
          <SelectInput
            style={{ bright: true, thick: true }}
            value={selectedTime}
            onChange={({ value }) => setSelectedTime(value)}
            iconComponent={<ClockIcon />}
            options={timeOptions}
            errorMessage={errors?.time}
          />
          {recipientTimezone && recipientTimezone !== moment.tz.guess() && (
            <Trans
              i18nKey={'pages.patients.selectedPatient.chat.scheduleMessagePopup.differentTimezonesMessage'}
              values={{
                time: moment(recipientScheduleTime).format('hh:mm a'),
                timezoneOffset: recipintTimezoneOffset
              }}
            ></Trans>
          )}
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default ScheduleMessagePopupMobile
