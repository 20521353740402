import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PasswordStrength from 'components/common/PasswordStrength'
import FormInput from 'components/common/FormInput'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  passwordValidation: {
    color: 'var(--text-color-35)'
  },
  passwordInputContainer: {
    marginBottom: 30
  },
  note: {
    marginTop: 30,
    color: 'var(--border-color-33)'
  }
}))

const ServiceAccountPasswordValidation = ({
  password,
  passwordConfirmation,
  passwordError,
  passwordConfirmationError,
  onPasswordChange,
  onPasswordConfirmationChange
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <FormInput
        title={t('pages.accountSettings.services.serviceAccount.modals.passwordCommon.passwordTitle')}
        placeholder={t('pages.accountSettings.services.serviceAccount.modals.passwordCommon.passwordPlaceholder')}
        type="password"
        style={{ bright: true, thick: true }}
        value={password}
        setValue={onPasswordChange}
        showPasswordButton
        containerClassName={classes.passwordInputContainer}
        errorMessage={passwordError}
      />
      <FormInput
        title={t('pages.accountSettings.services.serviceAccount.modals.passwordCommon.repeatPasswordTitle')}
        placeholder={t('pages.accountSettings.services.serviceAccount.modals.passwordCommon.repeatPasswordPlaceholder')}
        type="password"
        style={{ bright: true, thick: true }}
        value={passwordConfirmation}
        setValue={onPasswordConfirmationChange}
        showPasswordButton
        containerClassName={classes.passwordInputContainer}
        errorMessage={passwordConfirmationError}
      />
      <PasswordStrength
        verifiedColor="var(--text-color-36)"
        textClassName={classes.passwordValidation}
        titleClassName={classes.passwordValidation}
        password={password}
      />
      <DazzedParagraph12 className={classes.note}>
        <Trans
          components={{ underline1: <u>{''}</u> }}
          i18nKey={'pages.accountSettings.services.serviceAccount.modals.passwordCommon.note'}
        />
      </DazzedParagraph12>
    </>
  )
}

export default ServiceAccountPasswordValidation
