import React, { useCallback, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Highlight from 'react-highlighter'
import GrinMenuItem from 'components/common/menu/GrinMenuItem'
import ProfilePicture from 'components/common/ProfilePicture'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import SearchFieldValue from './SearchFieldValue'

const useStyles = () =>
  makeStyles({
    searchItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer'
    },
    patientName: {
      fontWeight: 'bold'
    },
    guardianDetails: {
      paddingTop: 8,
      paddingBottom: 5,
      color: 'var(--text-color-30)'
    },
    disabledReason: {
      marginTop: 5,
      background: 'var(--bg-color-52)',
      border: '0.853963px solid var(--border-color-23)',
      borderRadius: '6.8317px',
      color: 'var(--text-color-27)',
      padding: 4,
      whiteSpace: 'pre-wrap'
    },
    divider: {
      background: 'var(--border-color-10)',
      width: '100%',
      height: '1px',
      marginTop: 10
    }
  })()

const SearchSiblingResultItem = ({ onSelectSibling, item, searchTerm }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { parentName, parentEmail, profilePicture, name = '', email = '' } = item
  const isEmptyParentName = useMemo(() => !parentName || parentName?.trim() === '', [parentName])
  const parentDetails = useMemo(
    () => `${parentName} ${parentEmail ? (isEmptyParentName ? parentEmail : ' | ' + parentEmail) : ''}`,
    [isEmptyParentName, parentName, parentEmail]
  )

  const handleSelect = useCallback(() => {
    onSelectSibling(item)
  }, [onSelectSibling, item])

  return (
    <GrinMenuItem onClick={handleSelect} className={classes.searchItem}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid xs={2} item>
              <ProfilePicture size="medium" photo={profilePicture} name={name} email={email} />
            </Grid>
            <Grid xs={10} item>
              <Grid direction="column" container>
                <Grid item>
                  <DazzedParagraph14 className={classes.patientName}>
                    <Highlight search={searchTerm}>{name}</Highlight>
                  </DazzedParagraph14>
                </Grid>
                <Grid item>
                  <SearchFieldValue searchTerm={searchTerm} value={email} />
                </Grid>

                {(!isEmptyParentName || parentEmail) && (
                  <span>
                    <Grid item>
                      <DazzedParagraph12 className={classes.guardianDetails}>
                        {t('dialogs.invitePatient.linkToSiblingRow.legalGuardianDetails')}
                      </DazzedParagraph12>
                    </Grid>
                    <SearchFieldValue searchTerm={searchTerm} value={parentDetails} />
                  </span>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.divider} />
        </Grid>
      </Grid>
    </GrinMenuItem>
  )
}

export default SearchSiblingResultItem
