import Actions from 'actions'
import useQueryParams from 'hooks/useQueryParams'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

export default () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { queryParams } = useQueryParams()

  const { patient } = useSelector(state => state.patientsReducer)

  const grinScanFromQueryParam = useMemo(
    () => patient?.grinScans?.items?.find(scan => scan.id === queryParams.grinScanId),
    [patient?.grinScans?.items, queryParams.grinScanId]
  )

  useEffect(() => {
    const { referralId, grinScanId } = queryParams
    if (referralId && grinScanId && grinScanFromQueryParam) {
      dispatch(
        Actions.toggleViewSharedScanModal({
          isOpen: true,
          referralId,
          grinScanId
        })
      )
      history.replace({
        search: ''
      })
    }
  }, [dispatch, grinScanFromQueryParam, queryParams.grinScanId, queryParams.referralId, history]) //eslint-disable-line
}
