import * as React from 'react'

const NotTracking = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 4 }}
  >
    <rect x="1" y="1" width="16" height="16" rx="3" fill="#FFD0D0" stroke="#F6B3B3" strokeWidth="2" />
    <path d="M5 5L13 13" stroke="#CA6565" strokeWidth="2" />
    <path d="M13 5L5 13" stroke="#CA6565" strokeWidth="2" />
  </svg>
)

export default NotTracking
