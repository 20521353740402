import React from 'react'
import { makeStyles } from '@material-ui/styles'
import GenericToothGridItem from './GenericToothGridItem'
import images from 'resources'

const useStyles = makeStyles(theme => ({
  root: {
    background: `var(--bg-color-58)`,
    transition: 'background 0.2s ease',
    '&.selected': {
      background: `url('${images.untrackedToothBackground}')`,
      backgroundSize: 'contain',
      '&:hover': {
        background: `url('${images.untrackedToothBackground}')`,
        backgroundSize: 'contain'
      }
    },
    '&:hover': {
      background: ({ editable }) => (editable ? 'var(--bg-color-46)' : `var(--bg-color-58)`)
    }
  },
  toothLabel: {
    color: 'var(--text-color-56)',
    '&.selected': {
      color: 'var(--text-color-17)!important'
    }
  }
}))

const UntrackedToothGridItem = ({ tooth, isSelected = false, onClick = () => {}, editable }) => {
  const classes = useStyles({ editable })

  return (
    <GenericToothGridItem
      tooth={tooth}
      editable={editable}
      isSelected={isSelected}
      onClick={onClick}
      customClasses={{ root: classes.root, toothLabel: classes.toothLabel }}
    />
  )
}

export default UntrackedToothGridItem
