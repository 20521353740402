import * as React from 'react'

function WarningHexagon({ color = '#CED0D4' }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.56289 0L0 4.56289V13.4371L4.56289 18H13.4371L18 13.4371V4.56289L13.4371 0L4.56289 0ZM9 14.2734C8.41859 14.2734 7.94531 13.8002 7.94531 13.2188C7.94531 12.6373 8.41859 12.1641 9 12.1641C9.58141 12.1641 10.0547 12.6373 10.0547 13.2188C10.0547 13.8002 9.58141 14.2734 9 14.2734ZM9.52734 11.1094H8.47266L7.94531 3.72656H10.0547L9.52734 11.1094Z"
        fill="black"
      />
    </svg>
  )
}

export default WarningHexagon
