import * as React from 'react'

const ModalAlert = ({ ...props }) => {
  return (
    <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="41" cy="41" r="40" stroke="#3C52EF" />
      <path d="M41 24V49.5" stroke="#3C52EF" strokeWidth="3" strokeLinecap="round" />
      <path d="M41 56V57.5" stroke="#3C52EF" strokeWidth="3" strokeLinecap="round" />
    </svg>
  )
}

export default ModalAlert
