import React, { useMemo, useCallback } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles({
  filter: {
    padding: '1px 8px 1px 8px',
    border: '1px solid var(--border-color-6)',
    borderRadius: 50,
    cursor: 'pointer',
    userSelect: 'none'
  },
  selectedFilterContainer: {
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.15)',
    border: '1px solid var(--bg-color-43)'
  },
  selectedFilterText: {
    color: 'var(--text-color-25)'
  }
})

const QuickFilters = ({ quickFilters, toggleQuickFilters, leads = [], withAdminActions = false }) => {
  const classes = useStyles()

  const filters = useMemo(
    () =>
      Object.keys(quickFilters).reduce(
        (filters, filterKey) =>
          !quickFilters[filterKey]?.isAdminAction || withAdminActions
            ? {
                ...filters,
                [filterKey]: quickFilters[filterKey]
              }
            : filters,
        {}
      ),
    [quickFilters, withAdminActions]
  )

  const handleFilterClick = useCallback(
    filterName => {
      trackEvent('RC dashboard filter clicked', {
        filter: filterName
      })
      toggleQuickFilters(filterName)
    },
    [toggleQuickFilters]
  )

  return (
    <Grid container alignItems="center" spacing={2}>
      {Object.keys(filters).map(filterKey => (
        <Grid item key={filterKey} onClick={() => handleFilterClick(filterKey)}>
          <Grid
            container
            alignItems="center"
            spacing={1}
            className={`${classes.filter} ${filters[filterKey].isEnabled ? classes.selectedFilterContainer : ''}`}
          >
            {filters[filterKey]?.icon && filters[filterKey]?.icon(filters[filterKey].isEnabled)}
            <Grid item>
              <DazzedParagraph14
                bold={filters[filterKey].count > 0}
                className={filters[filterKey].isEnabled ? classes.selectedFilterText : ''}
              >
                {`${filters[filterKey].label}${filters[filterKey].count > 0 ? ` (${filters[filterKey].count})` : ''}`}
              </DazzedParagraph14>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default QuickFilters
