import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { CrossedCircle } from 'components/common/icons'
import { urlRegex } from 'utils/stringUtils'
import MessageTypeWrapper from '../MessageTypeWrapper'

const useStyles = makeStyles(({ alignLeft, isFirstMessage }) => ({
  bubble: {
    padding: '8px 15px 5px 15px !important'
  },
  deletedMessage: {
    display: 'flex',
    alignItems: 'center',
    color: 'var(--text-color-11)',
    '& svg': {
      marginRight: 10
    }
  },
  timestamp: {
    marginTop: 5,
    fontSize: 10,
    textAlign: 'right',
    color: 'rgba(0, 19, 53, 0.45)'
  }
}))

const TextMessage = ({
  content,
  alignLeft,
  deleted,
  name,
  userType,
  timestamp,
  isFirstMessage,
  isLastMessage,
  isBroadcast,
  backgroundColor,
  metadata,
  className = ''
}) => {
  const { t } = useTranslation()

  const classes = useStyles({ alignLeft, isFirstMessage })

  const deletedMessageContent = useMemo(() => {
    return (
      <div className={classes.deletedMessage}>
        <CrossedCircle />
        {t('pages.patients.selectedPatient.chat.messageDeleted')}
      </div>
    )
  }, [classes.deletedMessage, t])

  const handledContent = useMemo(() => {
    return content?.split(urlRegex)
  }, [content])

  return (
    <MessageTypeWrapper
      isFirstMessage={isFirstMessage || metadata?.isGiAutomatedMessage} // In case this is GI automated message to the patient, we want to show the practice how it looks
      isLastMessage={isLastMessage}
      alignLeft={alignLeft}
      name={name}
      userType={userType}
      className={[classes.bubble, className].join(' ')}
      isBroadcast={isBroadcast}
      backgroundColor={backgroundColor}
    >
      {deleted
        ? deletedMessageContent
        : handledContent
            .filter(word => word)
            .map((word, i) =>
              word.match(urlRegex) ? (
                <a key={word + i} href={word} target="_blank" rel="noopener noreferrer">
                  {word}
                </a>
              ) : (
                <span key={word + i}>{word}</span>
              )
            )}
      <div className={classes.timestamp}>{timestamp}</div>
    </MessageTypeWrapper>
  )
}

export default TextMessage
