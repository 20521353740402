import * as React from 'react'

const AutomationsShareFeedback = props => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.58636 12C9.90007 12 12.5864 9.31371 12.5864 6C12.5864 2.68629 9.90007 0 6.58636 0C3.27266 0 0.586365 2.68629 0.586365 6C0.586365 6.94921 0.806785 7.84695 1.19923 8.6448L-2.58684e-05 12.2426L3.42537 11.1008C4.34327 11.6708 5.42638 12 6.58636 12Z"
        fill="#4151E6"
      />
    </svg>
  )
}

export default AutomationsShareFeedback
