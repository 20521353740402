import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import SmallDotIcon from 'components/common/icons/SmallDot'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useSelector } from 'react-redux'
import { ProgramTypes } from '@grin-rnd/grin-api-sdk/dist/Enums/Programs'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  statusContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row'
  },
  colorDot: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '4px 0'
  },
  status: {
    paddingLeft: '8px'
  },
  statusTitle: {
    fontSize: 13,
    fontWeight: 600,
    color: 'var(--text-color-11)!important'
  }
}))

const TreatmentStatusUpdated = ({ params }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const statuses = useSelector(state => state.treatmentReducer.statuses.data?.[ProgramTypes.RC])

  const currentStatus = useMemo(
    () => statuses?.find(currStatus => currStatus?.type === params?.statusType),
    [params, statuses]
  )
  const statusSettings = useMemo(() => JSON.parse(currentStatus?.settings || '{}'), [currentStatus])

  return (
    <div className={classes.statusContainer}>
      <div className={classes.colorDot}>
        <SmallDotIcon color={statusSettings?.color} />
      </div>
      <div className={classes.status}>
        <DazzedParagraph12 className={classes.statusTitle}>
          {t(`statuses.names.${currentStatus?.type}`)}
        </DazzedParagraph12>
      </div>
    </div>
  )
}

export default TreatmentStatusUpdated
