import React, { useMemo } from 'react'
import { ButtonBase, Box, CircularProgress, Grid } from '@material-ui/core'

const SecondaryButton = ({
  id = '',
  onClick,
  label,
  icon,
  iconPosition = 'right',
  small = false,
  dense = false,
  width = '192px',
  height = '45px',
  useContentHeight = false,
  useContentWidth = false,
  disabled = false,
  borderRadius = '30px',
  transparentBackground = false,
  className,
  wrapperClassName,
  isLoading = false,
  loaderStyle = {},
  hideBorder = false,
  disableRipple = false,
  iconMargin = '5px'
}) => {
  const actualWidth = useMemo(() => (dense ? 'auto' : small ? '110px' : width), [width, dense, small])
  const actualHeight = useMemo(() => (dense ? '36px' : height), [height, dense])

  return (
    <ButtonBase
      id={id}
      onClick={onClick}
      style={{ borderRadius, width: useContentWidth ? null : actualWidth }}
      disabled={disabled}
      className={wrapperClassName}
      disableRipple={disableRipple}
    >
      <Box
        bgcolor={disabled ? 'var(--bg-color-5)' : transparentBackground ? 'rgba(0,0,0,0)' : 'white'}
        borderRadius="30px"
        border={hideBorder ? '' : disabled ? '1px solid var(--border-color-8)' : '1px solid var(--border-color-1)'}
        pl="20px"
        pr="20px"
        color={disabled ? 'var(--text-color-13)' : 'var(--text-color-3)'}
        fontFamily="Dazzed"
        fontWeight="normal"
        fontSize="14px"
        height={useContentHeight ? null : actualHeight}
        width={useContentWidth ? null : actualWidth}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={className}
      >
        {isLoading ? (
          <CircularProgress color="inherit" size={16} style={loaderStyle} />
        ) : (
          <>
            {iconPosition === 'left' && icon ? <Box mr={iconMargin}>{icon}</Box> : null}
            {iconPosition !== 'top' && label}{' '}
            {iconPosition === 'right' && icon ? <Box ml={iconMargin}>{icon}</Box> : null}
            {iconPosition === 'top' && icon ? (
              <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center">
                <Grid item>
                  <Box mt={iconMargin}>{icon}</Box>
                </Grid>
                <Grid item>{label}</Grid>
              </Grid>
            ) : null}
          </>
        )}
      </Box>
    </ButtonBase>
  )
}

export default SecondaryButton
