import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core'
import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { animated, useSpring } from 'react-spring'
import { v4 as uuidv4 } from 'uuid'
import Actions from '../../../actions'
import { isSafari, isMobile } from 'react-device-detect'
import review_cursor from '../../../resources/cursor_pointer.png'
import BaseModal from '../../common/modals/BaseModal'
import GrantPermissionsDialog from './GrantPermissionsDialog'
import ScanReviewPreview from './ScanReviewPreview'
import ScanReviewVideoContainer from './ScanReviewVideoContainer'
import ScanReviewEditorHeader from './ScanReviewEditorHeader'
import DoctorCamera from './DoctorCamera'
import { trackEvent } from 'utils/analyticsUtils'
import { getObjectUrl, generateMediaPath } from 'utils/mediaUtils'
import useFeatureFlags from 'hooks/useFeatureFlags'
import getBlobDuration from 'get-blob-duration'
import { log } from 'utils/logUtils'
import useScanReviewOpenFromUrl from './useScanReviewOpenFromUrl'

const useStyles = ({ showPreviewMode, isCapturing, smallModal, overlayDrawingFF }) =>
  makeStyles({
    dialog: {
      minWidth: isCapturing && !showPreviewMode ? '100%' : smallModal ? 720 : '80%',
      height: isCapturing && !showPreviewMode ? '100vh' : 'auto',
      maxHeight: isCapturing && !showPreviewMode ? '100vh' : 'auto',
      borderRadius: isCapturing && !showPreviewMode ? '0 !important' : '30px'
    },
    dialogRoot: {
      padding: isCapturing && !showPreviewMode ? 0 : '0 30px',
      zIndex: '1500 !important'
    },
    dialogContent: {
      overflow: isCapturing && !showPreviewMode ? 'hidden' : 'auto'
    },
    notSupportedContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    scanReviewEditorContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between'
    },
    scanReviewActions: {
      display: 'flex',
      justifyContent: 'center',
      padding: '24px 24px 0'
    },
    button: {
      width: 110,
      margin: '0 10px'
    },
    scanSkeleton: {
      height: '100%',
      borderRadius: '20px',
      margin: '50px 10px'
    },
    video: {
      cursor: `url(${review_cursor}), auto`,
      borderRadius: 0,
      maxHeight: '100%',
      margin: '24px 0 0',
      alignSelf: 'center',
      outline: 'none',
      width: '100%',
      height: isCapturing ? '90vh' : '55vh',
      marginBottom: isCapturing && overlayDrawingFF ? 0 : 24
    },
    animatedContainer: {
      position: 'relative',
      width: '100%',
      left: 0
    }
  })({ showPreviewMode, isCapturing, smallModal })

const ScanReviewEditor = () => {
  useScanReviewOpenFromUrl()

  const dispatch = useDispatch()

  const [showPreviewMode, setShowPreviewMode] = useState(false)
  const [isCapturing, setIsCapturing] = useState(false)
  const [showCountDown, setShowCountDown] = useState(false)
  const [isCameraOn, setIsCameraOn] = useState(null)
  const [timerStarted, setTimerStarted] = useState(false)
  const [grantPermissionsDialog, setGrantPermissionsDialog] = useState({ open: false, permissionType: null })
  const [blob, setBlob] = useState(null)

  const { overlayDrawing: overlayDrawingFF } = useFeatureFlags()

  const { opacity, zIndex, display } = useSpring({
    opacity: showPreviewMode ? 1 : 0,
    zIndex: showPreviewMode ? 10 : 0,
    display: showPreviewMode ? 'block' : 'none',
    config: { mass: 5, tension: 500, friction: 80 }
  })

  const { open, scan, isLoading: isLoadingScan } = useSelector(state => state.treatmentReducer.scanReviewEditorDialog)
  const { patient, isLoadingPatient } = useSelector(state => state.patientsReducer)
  const { doctor } = useSelector(state => state.profileReducer)

  const classes = useStyles({ showPreviewMode, isCapturing, smallModal: showPreviewMode && !blob, overlayDrawingFF })

  const cameraRef = useRef(isCameraOn)

  const _setCamera = x => {
    cameraRef.current = x
    setIsCameraOn(x)
  }

  const cameraElement = useRef(null)
  const scanElement = useRef(null)
  const [scanElementDimensions, setScanElementDimensions] = useState()

  const recordingStream = useRef(null)
  const screenStream = useRef(null)
  const audioStream = useRef(null)
  const cameraStream = useRef(null)

  const recorder = useRef(null)
  const blobs = useRef(null)

  const blobRef = useRef(null)

  const isLoading = useMemo(() => isLoadingPatient || isLoadingScan, [isLoadingPatient, isLoadingScan])

  const _setBlob = x => {
    blobRef.current = x
    setBlob(x)
  }

  useEffect(() => {
    if (isMobile) {
      setGrantPermissionsDialog({ open: true, permissionType: 'mobile' })
    } else if (isSafari) {
      setGrantPermissionsDialog({ open: true, permissionType: 'safari' })
    }
  }, [setGrantPermissionsDialog, open])

  const toggleCameraOption = async val => {
    _setCamera(val)
    val ? await enableCamera() : disableCamera()
    trackEvent('ScanSummary_ToggleCamera', { scanId: scan.id, isCameraOn: val })
  }

  const enableCamera = async () => {
    if (!cameraStream.current) {
      try {
        cameraStream.current = await navigator.mediaDevices.getUserMedia({ video: cameraRef.current, audio: false })
      } catch (error) {
        /* this part doesn’t require any action,
        just avoiding stop code executing when permission for webcam is not granted */
      }
    }
    cameraElement.current.srcObject = cameraStream.current
    cameraElement.current.muted = true
  }

  const disableCamera = () => {
    stopStream(cameraStream)
    if (cameraElement.current) {
      cameraElement.current.srcObject = null
    }
  }

  const stopStream = stream => {
    if (stream.current) {
      stream.current.getTracks().forEach(s => s.stop())
    }
    stream.current = null
  }

  const startStopCaptureClicked = async () => {
    if (isCapturing) {
      await stopRecording({ showPreview: true })
    } else {
      setIsCapturing(await startRecording())
    }
  }

  const startRecording = async () => {
    const mimeType = 'video/webm; codecs=vp8,opus'

    try {
      new MediaRecorder(new MediaStream([]), { mimeType })
    } catch (error) {
      setGrantPermissionsDialog({ open: true, permissionType: 'media recorder' })
      return false
    }

    try {
      screenStream.current = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false })
    } catch (err) {
      setGrantPermissionsDialog({ open: true, permissionType: 'screen share' })
      return false
    }

    if (cameraRef.current === null) {
      await toggleCameraOption(true)
    }

    try {
      audioStream.current = await navigator.mediaDevices.getUserMedia({ video: false, audio: true })
    } catch (err) {
      setGrantPermissionsDialog({ open: true, permissionType: 'microphone' })
      return false
    }

    const tracks = [...screenStream.current.getVideoTracks(), ...audioStream.current.getAudioTracks()]
    recordingStream.current = new MediaStream(tracks)
    blobs.current = []

    recorder.current = new MediaRecorder(recordingStream.current, { mimeType, videoBitsPerSecond: 2000000 })
    recorder.current.ondataavailable = e => blobs.current.push(e.data)
    recorder.current.onstop = async () => {
      _setBlob(new Blob(blobs.current, { type: 'video/mp4' }))
    }

    setShowCountDown(true)
    setTimerStarted(false)
    return true
  }

  const stopRecording = async ({ showPreview }) => {
    if (recorder.current && recorder.current.state !== 'inactive') {
      recorder.current.stop()
    }

    stopStream(recordingStream)
    stopStream(audioStream)
    stopStream(screenStream)
    setShowCountDown(false)
    setShowPreviewMode(!!showPreview)
  }

  const handleCountDownComplete = () => {
    setShowCountDown(false)
    setTimerStarted(true)

    setTimeout(() => {
      recorder.current.start()
    }, 200)
  }

  const handleSendReview = async () => {
    const id = uuidv4()
    const s3UniqueId = `${isSafari ? 'videos' : 'uploads'}/${id}.mp4`
    const path = generateMediaPath({ type: 'scanReview', objectIdToAuthorize: scan.id, keySuffix: s3UniqueId })
    dispatch(
      Actions.requestSendScanReview({
        path,
        blob: blobRef.current,
        scan,
        uploadingDate: new Date().toISOString(),
        s3UniqueId,
        scanReviewId: id,
        reviewer: {
          id: doctor.id,
          name: doctor.name,
          roleDescription: doctor.roleDescription
        }
      })
    )

    let duration
    try {
      duration = await getBlobDuration(blobRef.current)
    } catch (e) {
      log('could not get duration for scan review', { error: e.message }, 'warn')
    }

    trackEvent('ScanReview_SendReviewClicked', {
      scanId: scan.id,
      size: blobRef.current?.size,
      duration
    })
    handleCloseModal()
  }

  const handleRetake = () => {
    if (scanElement?.current) {
      scanElement.current.pause()
      scanElement.current.currentTime = 0
    }
    setIsCapturing(false)
    setShowPreviewMode(false)
    setBlob(null)
    trackEvent('ScanReview_RetakeClicked', { scanId: scan.id })
  }

  const handleCloseModal = () => {
    toggleCameraOption(false)
    setShowCountDown(false)
    setIsCapturing(false)
    stopRecording({ showPreview: false })
    cameraRef.current = null
    dispatch(Actions.closeScanReviewEditor())
    trackEvent('ScanReview_closed', { scanId: scan.id })
  }

  useEffect(() => {
    if (scan && patient?.id !== scan?.patientId && !isLoadingPatient) {
      dispatch(
        Actions.requestPatient({
          patientId: scan?.patientId
        })
      )
    }
  }, [patient, isLoadingPatient, scan, dispatch])

  const videoRef = useCallback(
    input => {
      if (input) {
        scanElement.current = input
        setScanElementDimensions({
          width: input.offsetWidth,
          height: input.offsetHeight,
          left: input.offsetLeft
        })
      }
    },
    [isCapturing] // eslint-disable-line
  )

  const VideoElement = useMemo(
    () =>
      scan?.video.url ? (
        <video ref={videoRef} muted controls controlsList="nofullscreen nodownload" className={classes.video}>
          <source src={getObjectUrl(scan?.video)} type="video/mp4" />
        </video>
      ) : null,
    [scan?.video, videoRef, classes.video]
  )

  if (open && grantPermissionsDialog.open) {
    return (
      <GrantPermissionsDialog
        open={grantPermissionsDialog.open}
        permissionType={grantPermissionsDialog.permissionType}
        onClose={() => {
          handleCloseModal()
          setGrantPermissionsDialog({ open: false, permissionType: null })
        }}
      />
    )
  }

  return (
    <BaseModal
      open={open}
      handleClose={handleCloseModal}
      rootClassName={classes.dialogRoot}
      className={classes.dialog}
      contentClassName={classes.dialogContent}
    >
      {open ? (
        <>
          {(!isCapturing || showPreviewMode) && (
            <div>
              <ScanReviewEditorHeader patient={patient} isLoading={isLoading} scan={scan} />
            </div>
          )}
          <ScanReviewPreview
            zIndex={zIndex}
            opacity={opacity}
            video={blob}
            display={display}
            additionalClasses={classes}
            onSendClicked={handleSendReview}
            onRetakeClicked={handleRetake}
          />
          {!showPreviewMode && (
            <animated.div
              id="animated-div"
              style={{
                opacity: opacity.interpolate(o => 1 - o)
              }}
              className={classes.animatedContainer}
            >
              <div className={classes.scanReviewEditorContainer}>
                {!isLoading && (
                  <DoctorCamera
                    scanId={scan.id}
                    isCapturing={isCapturing}
                    cameraElement={cameraElement}
                    isCameraOn={isCameraOn}
                    onToggleCameraOption={toggleCameraOption}
                  />
                )}
                {isLoading ? (
                  <Skeleton variant="rect" className={classes.scanSkeleton}></Skeleton>
                ) : (
                  <ScanReviewVideoContainer
                    videoElement={VideoElement}
                    isCapturing={isCapturing}
                    timerStarted={timerStarted}
                    showCountDown={showCountDown}
                    scanElementDimensions={scanElementDimensions}
                    onStartStopCaptureClicked={startStopCaptureClicked}
                    onCountDownComplete={() => handleCountDownComplete()}
                  />
                )}
              </div>
            </animated.div>
          )}
        </>
      ) : null}
    </BaseModal>
  )
}

export default ScanReviewEditor
