import * as React from 'react'

const DownChevronCircle = ({ color, className }) => {
  return (
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.5 36.6298C2.5 17.7804 17.7804 2.5 36.6298 2.5V1.5C17.2282 1.5 1.5 17.2282 1.5 36.6298H2.5ZM36.6298 70.7596C17.7804 70.7596 2.5 55.4792 2.5 36.6298H1.5C1.5 56.0315 17.2282 71.7596 36.6298 71.7596V70.7596ZM70.7596 36.6298C70.7596 55.4792 55.4792 70.7596 36.6298 70.7596V71.7596C56.0315 71.7596 71.7596 56.0315 71.7596 36.6298H70.7596ZM36.6298 2.5C55.4792 2.5 70.7596 17.7804 70.7596 36.6298H71.7596C71.7596 17.2282 56.0315 1.5 36.6298 1.5V2.5ZM36.6298 0.5C16.6759 0.5 0.5 16.6759 0.5 36.6298H1.5C1.5 17.2282 17.2282 1.5 36.6298 1.5V0.5ZM72.7596 36.6298C72.7596 16.6759 56.5838 0.5 36.6298 0.5V1.5C56.0315 1.5 71.7596 17.2282 71.7596 36.6298H72.7596ZM36.6298 72.7596C56.5838 72.7596 72.7596 56.5838 72.7596 36.6298H71.7596C71.7596 56.0315 56.0315 71.7596 36.6298 71.7596V72.7596ZM0.5 36.6298C0.5 56.5838 16.6759 72.7596 36.6298 72.7596V71.7596C17.2282 71.7596 1.5 56.0315 1.5 36.6298H0.5Z"
        fill={color}
      />
      <path d="M17.8271 27.7237L37.1238 46.5256L56.4205 27.7237" stroke={color} strokeWidth="2" />
    </svg>
  )
}

export default DownChevronCircle
