import { useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Actions from 'actions'
import BaseModal from 'components/common/modals/BaseModal'
import PatientDetails from 'components/common/PatientDetails'
import useScans from 'hooks/useScans'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { compareTags } from 'utils/tagUtils'
import ArchesViewer from '../ArchesViewer/ArchesViewer'
import TeethTracking from '../TeethTracking/TeethTracking'
import ScanDetails from './ScanDetails'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    padding: 32
  },
  dialogContent: {
    padding: '0 24px 24px 24px!important'
  },
  title: {
    padding: '0px 36px 16px 36px!important'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  trackingViewerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    height: '100%'
  },
  trackingData: {
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    backgroundColor: 'var(--bg-color-76)',
    borderRadius: 32
  }
}))

const TreatmentTrackerModal = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const theme = useTheme()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { scanIdToNumberDict } = useScans()

  const { patient } = useSelector(state => state.patientsReducer)
  const { isOpen, grinScanId } = useSelector(state => state.treatmentReducer.treatmentTrackerModal)
  const patientGrinScans = useSelector(state => state.patientsReducer.patient?.grinScans?.items || [])
  const { treatment } = useSelector(state => state.treatmentReducer)

  const tags = useMemo(
    () =>
      patient.patientTags?.items
        ?.filter(patientTag => patientTag.tag.type === 'treatment' || patientTag.tag.type === 'program')
        .map(pt => pt.tag)
        .sort(compareTags),
    [patient]
  )

  const grinScan = useMemo(() => patientGrinScans.find(scan => scan.id === grinScanId), [grinScanId, patientGrinScans])
  const parsedTxTrackerData = useMemo(
    () => JSON.parse(grinScan?.treatmentTrackerData || '{}'),
    [grinScan?.treatmentTrackerData]
  )
  const scanNumber = useMemo(() => scanIdToNumberDict?.[grinScan?.id] + 1, [grinScan, scanIdToNumberDict])
  const archModels = useMemo(() => {
    const parsedStls = JSON.parse(treatment?.txTrackerStls || '{}')

    if (!!parsedStls?.stls) {
      return {
        baseArchModels: {
          upper: parsedTxTrackerData?.archModels?.upperS3Keys?.baseStl,
          lower: parsedTxTrackerData?.archModels?.lowerS3Keys?.baseStl
        },
        endArchModels: {
          upper: parsedTxTrackerData?.archModels?.upperS3Keys?.finalStl,
          lower: parsedTxTrackerData?.archModels?.lowerS3Keys?.finalStl
        },
        currentArchModels: {
          upper: parsedTxTrackerData?.archModels?.upperS3Keys?.currentPlannedStl,
          lower: parsedTxTrackerData?.archModels?.lowerS3Keys?.currentPlannedStl
        }
      }
    }

    return null
  }, [parsedTxTrackerData, treatment])

  console.log('archModels', archModels, parsedTxTrackerData)

  const handleClose = useCallback(() => {
    trackEvent('Treatment tracker popup - closed')
    dispatch(Actions.setTreatmentTrackerModalOpen({ isOpen: false }))
  }, [dispatch])

  return (
    isOpen && (
      <BaseModal
        open={isOpen}
        handleClose={handleClose}
        className={classes.root}
        contentClassName={classes.dialogContent}
        disableEnforceFocus
        title={t('dialogs.treatmentTracker.title')}
        titleClassName={classes.title}
      >
        <div className={classes.content}>
          <div style={{ marginBottom: 10 }}>
            <PatientDetails name={patient?.name} photo={patient?.photo} program={patient?.program} tags={tags} />
          </div>
          <div style={{ height: '100%' }}>
            <div className={classes.trackingViewerContainer}>
              <div className={classes.trackingData}>
                <div style={{ flex: 3 }}>
                  <ArchesViewer
                    baseArchModels={archModels?.baseArchModels}
                    endArchModels={archModels?.endArchModels}
                    currentArchModels={archModels?.currentArchModels}
                    currentTxTrackerData={parsedTxTrackerData}
                    treatmentType={treatment?.type}
                    alignerNumber={grinScan?.applianceIndex}
                    scanNumber={scanNumber}
                    isSmallerScreen={isSmallerScreen}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <TeethTracking treatmentTrackerData={parsedTxTrackerData} treatmentType={treatment?.type} />
                </div>
              </div>
              <div style={{ marginLeft: 10, flex: 1 }}>
                <ScanDetails grinScan={grinScan} />
              </div>
            </div>
          </div>
        </div>
      </BaseModal>
    )
  )
}

export default TreatmentTrackerModal
