import React from 'react'
import TitleWithText from 'components/Patients/PatientGuidelines/PatientBrief/Categories/TitleWithText'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const BracesTxTracking = ({ items, note }) => {
  const { t } = useTranslation()

  const { untrackedTeeth, wireSequence } = items

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        {untrackedTeeth && (
          <TitleWithText
            title={`${t(`dialogs.hiPatientGuidelinesViewer.items.untrackedTeeth`)}:`}
            text={untrackedTeeth.join(', ')}
          />
        )}
      </Grid>
      <Grid item>
        {wireSequence && (
          <TitleWithText title={`${t(`dialogs.hiPatientGuidelinesViewer.items.attachments`)}:`} text={wireSequence} />
        )}
      </Grid>
    </Grid>
  )
}

export default BracesTxTracking
