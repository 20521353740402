import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import MemberCell from './Cells/MemberCell'
import MemberNameCell from './Cells/MemberNameCell'
import MemberAccessCell from './Cells/MemberAccessCell'
import MemberDateCell from './Cells/MemberDateCell'
import MemberOptionsCell from './Cells/MemberOptionsCell'
import { TeamMemberStatuses } from 'consts/authConsts'
import { beautifyPhoneNumber } from 'utils/stringUtils'

const useStyles = makeStyles({
  row: {
    height: 84
  }
})

const MembersTableRow = ({ member, colums, onChangeAccessType = () => {}, handleEditMember }) => {
  const classes = useStyles()
  const {
    id,
    firstName,
    lastName,
    name,
    email,
    avatar,
    accessType,
    doctorId,
    roleDescription,
    dateAdded,
    addedBy,
    status,
    phoneNumber,
    countryCode
  } = member

  const { t } = useTranslation()

  const isMemberDisabled = useMemo(
    () => status === TeamMemberStatuses.disabled || accessType === 'owner',
    [status, accessType]
  )

  const Cells = useMemo(
    () => (
      <>
        {colums.map(({ key }) => {
          switch (key) {
            case 'name':
              return (
                <MemberNameCell
                  key={key}
                  firstName={firstName}
                  lastName={lastName}
                  name={name}
                  avatar={avatar}
                  email={email}
                  disabled={isMemberDisabled}
                />
              )
            case 'phone':
              return (
                <MemberCell key={key} disabled={isMemberDisabled}>
                  {countryCode ? `+${beautifyPhoneNumber(countryCode, phoneNumber)}` : ''}
                </MemberCell>
              )
            case 'accessType':
              return (
                <MemberAccessCell
                  key={key}
                  accessType={accessType}
                  disabled={isMemberDisabled}
                  id={id}
                  onChangeAccessType={onChangeAccessType}
                />
              )
            case 'jobTitle':
              return (
                <MemberCell key={key} disabled={isMemberDisabled}>
                  {roleDescription}
                </MemberCell>
              )
            case 'dateAdded':
              return (
                <MemberDateCell
                  key={key}
                  dateAdded={dateAdded}
                  addedBy={addedBy}
                  accessType={accessType}
                  disabled={isMemberDisabled}
                />
              )
            case 'status':
              return (
                <MemberCell key={key} capitalized disabled={isMemberDisabled}>
                  {status && t(`common.practiceMemberStatuses.${status}`)}
                </MemberCell>
              )
            case 'actions':
              return (
                <MemberOptionsCell
                  key={key}
                  status={status}
                  firstName={firstName}
                  lastName={lastName}
                  name={name}
                  email={email}
                  doctorId={doctorId}
                  memberId={id}
                  handleEditMember={handleEditMember}
                  isOwner={accessType === 'owner'}
                  avatar={avatar}
                  roleDescription={roleDescription}
                />
              )

            default:
              return <></>
          }
        })}
      </>
    ),
    [
      accessType,
      avatar,
      colums,
      countryCode,
      dateAdded,
      doctorId,
      email,
      firstName,
      handleEditMember,
      id,
      isMemberDisabled,
      lastName,
      name,
      onChangeAccessType,
      phoneNumber,
      roleDescription,
      status,
      addedBy,
      t
    ]
  )

  return <TableRow className={classes.row}>{Cells}</TableRow>
}

export default MembersTableRow
