import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { ButtonBase } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { TreatmentTrackOptions } from 'consts/giConsts'

const VariantColors = {
  [TreatmentTrackOptions.OnTrack]: '#57B63F',
  [TreatmentTrackOptions.OffTrack]: '#EB5757'
}

const useStyles = makeStyles(theme => ({
  root: {
    border: ({ variant }) => `2px solid ${VariantColors[variant]}`,
    borderRadius: 64,
    padding: '6px 16px',
    background: ({ variant, isSelected }) => (isSelected ? VariantColors[variant] : '#fff')
  },
  label: {
    color: ({ variant, isSelected }) => (isSelected ? '#fff' : VariantColors[variant])
  }
}))

const TrackButton = ({ value, onClick = () => {}, isSelected = false }) => {
  const classes = useStyles({ variant: value, isSelected })
  const { t } = useTranslation()

  return (
    <ButtonBase onClick={() => onClick(value)} className={[classes.root].join(' ')}>
      <DazzedParagraph14 bold className={classes.label}>
        {t(
          `dialogs.scanSummary.treatmentTracking.${
            value === TreatmentTrackOptions.OffTrack ? 'notOnTrackButton' : 'onTrackButton'
          }`
        )}
      </DazzedParagraph14>
    </ButtonBase>
  )
}

export default TrackButton
