import * as React from 'react'

export default () => {
  return (
    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.60177 7.05C7.31059 7.05 7.9125 6.80191 8.4075 6.30573C8.9025 5.80956 9.15 5.20706 9.15 4.49823C9.15 3.78941 8.90191 3.1875 8.40573 2.6925C7.90956 2.1975 7.30706 1.95 6.59823 1.95C5.88941 1.95 5.2875 2.19809 4.7925 2.69427C4.2975 3.19044 4.05 3.79294 4.05 4.50177C4.05 5.21059 4.29809 5.8125 4.79427 6.3075C5.29044 6.8025 5.89294 7.05 6.60177 7.05ZM6.59648 6.18C6.12883 6.18 5.7325 6.01632 5.4075 5.68897C5.0825 5.36162 4.92 4.96412 4.92 4.49648C4.92 4.02883 5.08367 3.6325 5.41103 3.3075C5.73839 2.9825 6.13589 2.82 6.60353 2.82C7.07118 2.82 7.4675 2.98367 7.7925 3.31102C8.1175 3.63838 8.28 4.03588 8.28 4.50352C8.28 4.97117 8.11633 5.3675 7.78898 5.6925C7.46162 6.0175 7.06412 6.18 6.59648 6.18ZM6.6 9C5.14 9 3.82 8.585 2.64 7.755C1.46 6.925 0.58 5.84 0 4.5C0.58 3.16 1.46 2.075 2.64 1.245C3.82 0.415 5.14 0 6.6 0C8.06 0 9.38 0.415 10.56 1.245C11.74 2.075 12.62 3.16 13.2 4.5C12.62 5.84 11.74 6.925 10.56 7.755C9.38 8.585 8.06 9 6.6 9Z"
        fill="var(--text-color-11)"
      />
    </svg>
  )
}
