import * as React from 'react'

const JPEG = ({ ...props }) => {
  return (
    <svg width="33" height="41" viewBox="0 0 33 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.529 34C8.313 34 8.817 33.576 8.817 32.888V28.016H8.065V32.768C8.065 33.104 7.817 33.344 7.457 33.344H6.793L6.889 34H7.529ZM12.3313 28.016H10.2833V34H11.0353V31.6H12.3313C13.7713 31.6 14.6273 30.936 14.6273 29.8C14.6273 28.68 13.7713 28.016 12.3313 28.016ZM12.3313 30.936H11.0353V28.68H12.3313C13.3153 28.68 13.8673 29.08 13.8673 29.8C13.8673 30.528 13.3153 30.936 12.3313 30.936ZM19.3611 28.016H15.7051V34H19.3611V33.336H16.4571V31.256H19.3291V30.592H16.4571V28.68H19.3611V28.016ZM25.9293 31.16H23.7613V31.792H25.1373C25.0253 32.8 24.3373 33.408 23.3053 33.408C22.0493 33.408 21.1453 32.408 21.1453 31C21.1453 29.592 22.0413 28.6 23.2573 28.6C24.1133 28.6 24.7773 29.16 24.9693 29.968L25.7213 29.824C25.4973 28.672 24.4973 27.92 23.2973 27.92C21.6093 27.92 20.3853 29.208 20.3853 31C20.3853 32.8 21.6173 34.096 23.3053 34.096C24.9853 34.096 25.9853 32.952 25.9293 31.16Z"
        fill="#646464"
      />
      <path
        d="M1 4C1 2.34315 2.34315 1 4 1H21.8431C22.904 1 23.9214 1.42143 24.6716 2.17157L30.8284 8.32843C31.5786 9.07857 32 10.096 32 11.1569V37C32 38.6569 30.6569 40 29 40H4C2.34315 40 1 38.6569 1 37V4Z"
        stroke="#646464"
        strokeWidth="0.75"
      />
    </svg>
  )
}

export default JPEG
