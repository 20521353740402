import { Collapse } from '@material-ui/core'
import { Grid, makeStyles } from '@material-ui/core'
import _, { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { isValidEmail, isValidNumber, isValidZipcode } from 'utils/generalUtils'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import FormInput from 'components/common/FormInput'
import SelectInput from 'components/common/SelectInput'
import TextArea from 'components/common/TextArea'
import { useCountry } from 'hooks/useCountry'
import { COUNTRY_CA, COUNTRY_CH, COUNTRY_IN, COUNTRY_US } from 'consts/countryConsts'

const useStyles = makeStyles(
  {
    container: {
      marginTop: 20
    },
    buttonContainer: {
      width: '100%',
      justifyContent: ({ isMobile }) => (isMobile ? 'center' : 'flex-end'),
      marginRight: '5px'
    },
    button: {
      fontSize: 22,
      backgroundColor: ({ colorSettings }) => colorSettings.footerBackgroundColor,
      color: ({ colorSettings }) => colorSettings.footerTextColor
    },
    errorMessage: {
      opacity: 1,
      color: 'red',
      marginTop: '2px',
      marginBottom: '7px',
      height: '17px',
      fontSize: ({ isMobile }) => (isMobile ? '10px' : '12px'),
      textAlign: ({ isMobile }) => (isMobile ? 'center' : 'right')
    }
  },
  { isMobile }
)

export default ({ rcToken, colors = {}, campaignParams, clinicCountry = COUNTRY_US, skipCaptcha = false }) => {
  const { t } = useTranslation()
  const classes = useStyles({
    isMobile,
    colorSettings: { ...colors }
  })

  const [patientFirstName, setPatientFirstName] = useState('')
  const [patientLastName, setPatientLastName] = useState('')
  const [patientEmail, setPatientEmail] = useState('')
  const [patientPhone, setPatientPhone] = useState('')
  const [ageRange, setAgeRange] = useState('')
  const [guardianFirstName, setGuardianFirstName] = useState('')
  const [guardianLastName, setGuardianLastName] = useState('')
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState(clinicCountry)
  const [zipcode, setZipcode] = useState('')
  const [recaptchaToken, seRecaptchaToken] = useState(null)
  const [stateKey, setStateKey] = useState('')
  const [errors, setErrors] = useState(null)
  const [showGuardianInputs, setShowGuardianInputs] = useState(false)

  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.authReducer.rcRegister.isLoading)

  const isUnderAge = useMemo(() => !!ageRange && !isNaN(ageRange) && Number(ageRange) < 18, [ageRange])

  const statesLabel = useMemo(
    () =>
      country === COUNTRY_CA
        ? t('pages.rcLanding.body.province')
        : country === COUNTRY_CH
        ? t('pages.rcLanding.body.canton')
        : t('pages.rcLanding.body.state'),
    [country, t]
  )

  const { countriesOptions, states, getStateKey, hasZipcode } = useCountry(country)

  useEffect(() => {
    if (state) {
      country === COUNTRY_IN ? setStateKey(state) : setStateKey(getStateKey(state, country))
    }
  }, [state, getStateKey, country])

  const handleFormSubmit = useCallback(
    e => {
      const errors = {}

      if (!patientFirstName?.trim().length) {
        errors.firstname = t('pages.rcLanding.validations.pleaseFillFirstName')
      }

      if (!patientLastName?.trim().length) {
        errors.lastname = t('pages.rcLanding.validations.pleaseFillLastName')
      }

      if (!patientEmail) {
        errors.email = t('pages.rcLanding.validations.pleaseFillEmail')
      } else if (!isValidEmail(patientEmail)) {
        errors.email = t('pages.rcLanding.validations.emailIsNotValid')
      }

      if (!patientPhone) {
        errors.phone = t('pages.rcLanding.validations.pleaseFillPhone')
      } else if (!isValidNumber(patientPhone)) {
        errors.phone = t('pages.rcLanding.validations.phoneIsNotValid')
      }

      if (!ageRange?.trim().length) {
        errors.ageRange = t('pages.rcLanding.validations.pleaseFillAge')
      } else if (isNaN(ageRange) || Number(ageRange) < 0) {
        errors.ageRange = t('pages.rcLanding.validations.ageIsNotValid')
      } else if (isUnderAge) {
        if (!guardianFirstName?.trim().length) {
          errors.guardianFirstName = t('pages.rcLanding.validations.pleaseFillGuardianFirstName')
        }
        if (!guardianLastName?.trim().length) {
          errors.guardianLastName = t('pages.rcLanding.validations.pleaseFillGuardianLastName')
        }
      }

      if (!address?.trim().length) {
        errors.address = t('pages.rcLanding.validations.pleaseFillAddress')
      }

      if (!city?.trim().length) {
        errors.city = t('pages.rcLanding.validations.pleaseFillCity')
      }

      if (!country?.trim().length) {
        errors.country = t('pages.rcLanding.validations.pleaseSelectCountry')
      }
      if (states.length && !stateKey) {
        errors.state =
          country === COUNTRY_CA
            ? t('pages.rcLanding.validations.pleaseSelectProvince')
            : t('pages.rcLanding.validations.pleaseSelectState')
      }

      if (hasZipcode) {
        if (!zipcode?.trim().length) {
          errors.zipcode = t('pages.rcLanding.validations.pleaseFillZip')
        } else if (!isValidZipcode(zipcode, country)) {
          errors.zipcode = t('pages.rcLanding.validations.zipIsNotValid')
        }
      }

      if (!skipCaptcha && !recaptchaToken) {
        errors.captcha = t('pages.rcLanding.validations.captchaRequired')
      }

      if (!isEmpty(errors)) {
        setErrors(errors)
        return
      } else {
        setErrors(null)
      }

      const model = {
        patientFirstName,
        patientLastName,
        patientEmail,
        patientPhone,
        ageRange,
        guardianFirstName,
        guardianLastName,
        address,
        address2,
        additionalInfo,
        city,
        state: country === COUNTRY_IN ? state : !states.length ? '-' : stateKey,
        country,
        zipcode,
        recaptchaToken,
        rcToken,
        ...campaignParams
      }

      dispatch(Actions.registerRCUserRequested(model))
    },
    [
      patientFirstName,
      patientLastName,
      patientEmail,
      patientPhone,
      ageRange,
      isUnderAge,
      address,
      city,
      country,
      states.length,
      stateKey,
      hasZipcode,
      zipcode,
      recaptchaToken,
      guardianFirstName,
      guardianLastName,
      address2,
      additionalInfo,
      state,
      rcToken,
      campaignParams,
      skipCaptcha,
      dispatch,
      t
    ]
  )

  const onCaptchaChange = useCallback(
    value => {
      setErrors(errors => (errors ? { ...errors, captcha: null } : null))
      seRecaptchaToken(value)
    },
    [seRecaptchaToken]
  )

  useEffect(() => {
    if (!hasZipcode) {
      setZipcode('')
    }
  }, [hasZipcode])

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12} md={6} id="rc-first-name">
        <FormInput
          placeholder={t('pages.rcLanding.body.patientFirstName')}
          value={patientFirstName}
          validationRules={{ required: true }}
          setValue={setPatientFirstName}
          style={{ bright: true, landing: true }}
          errorMessage={errors?.firstname}
        />
      </Grid>
      <Grid item xs={12} md={6} id="rc-last-name">
        <FormInput
          placeholder={t('pages.rcLanding.body.patientLastName')}
          value={patientLastName}
          validationRules={{ required: true }}
          setValue={setPatientLastName}
          style={{ bright: true, landing: true }}
          errorMessage={errors?.lastname}
        />
      </Grid>
      <Grid item xs={4} id="rc-age">
        <FormInput
          placeholder={t('pages.rcLanding.body.age')}
          value={ageRange}
          validationRules={{ required: true }}
          required
          errorMessage={errors?.ageRange}
          style={{ landing: true, bright: true }}
          setValue={value => setAgeRange(value.replace(/\s/g, ''))}
          onBlur={() => setShowGuardianInputs(isUnderAge)}
        />
      </Grid>
      <Grid item xs={8} id="rc-phone">
        <FormInput
          placeholder={t('pages.rcLanding.body.phone')}
          value={patientPhone}
          validationRules={{
            required: true,
            pattern: '^(\\([0-9]{3}\\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$'
          }}
          setValue={phone => setPatientPhone(phone?.trim())}
          style={{ bright: true, landing: true }}
          errorMessage={errors?.phone}
          allowAutoComplete={true}
        />
      </Grid>
      <Grid item xs={12}>
        <Collapse in={showGuardianInputs}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} id="rc-guardian-first-name">
              <FormInput
                placeholder={t('pages.rcLanding.body.guardianFirstName')}
                value={guardianFirstName}
                validationRules={{ required: true }}
                required
                errorMessage={errors?.guardianFirstName}
                style={{ landing: true, bright: true }}
                setValue={setGuardianFirstName}
              />
            </Grid>
            <Grid item xs={12} md={6} id="rc-guardian-last-name">
              <FormInput
                placeholder={t('pages.rcLanding.body.guardianLastName')}
                value={guardianLastName}
                setValue={setGuardianLastName}
                style={{ bright: true, landing: true }}
                errorMessage={errors?.guardianLastName}
              />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
      <Grid item xs={12} id="rc-email">
        <FormInput
          placeholder={t('pages.rcLanding.body.email')}
          value={patientEmail}
          validationRules={{
            required: true,
            pattern: '(^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$)'
          }}
          setValue={setPatientEmail}
          style={{ bright: true, landing: true }}
          errorMessage={errors?.email}
          allowAutoComplete={true}
        />
      </Grid>
      <Grid item xs={12} id="rc-tell-us-more">
        <TextArea
          placeholder={t('pages.rcLanding.body.tellUsMore')}
          value={additionalInfo}
          setValue={setAdditionalInfo}
          style={{ bright: true, landing: true }}
        />
      </Grid>
      <Grid item xs={12} id="rc-address">
        <FormInput
          placeholder={t('pages.rcLanding.body.address')}
          value={address}
          validationRules={{ required: true }}
          setValue={setAddress}
          style={{ bright: true, landing: true }}
          errorMessage={errors?.address}
        />
      </Grid>
      <Grid item xs={12} id="rc-address-2">
        <FormInput
          placeholder={t('pages.rcLanding.body.address2')}
          value={address2}
          setValue={setAddress2}
          style={{ bright: true, landing: true }}
        />
      </Grid>
      <Grid item xs={12} id="rc-city">
        <FormInput
          placeholder={t('pages.rcLanding.body.city')}
          value={city}
          validationRules={{ required: true }}
          setValue={setCity}
          style={{ bright: true, landing: true }}
          errorMessage={errors?.city}
        />
      </Grid>
      <Grid item lg={4} xs={12} id="rc-country">
        <SelectInput
          placeholder={t('pages.rcLanding.body.country')}
          value={country}
          options={countriesOptions}
          style={{ landing: true, bright: true }}
          autoWidth={isMobile}
          onChange={({ value: key }) => {
            setCountry(key)
            setState('')
            setStateKey('')
          }}
          disabled={true}
          errorMessage={errors?.country}
          keepErrorContainerWhenInactive={false}
        />
      </Grid>
      <Grid item lg={4} xs={12} id="rc-state">
        {country === COUNTRY_IN ? (
          <FormInput
            placeholder={t('pages.rcLanding.body.state')}
            value={state}
            setValue={setState}
            style={{ bright: true, landing: true }}
          />
        ) : (
          <SelectInput
            placeholder={statesLabel}
            noSideBorders={!isMobile}
            autoWidth={isMobile}
            value={stateKey}
            values={states.map(state => state.key)}
            required={!!states.length}
            disabled={!states.length}
            errorMessage={errors?.state}
            keepErrorContainerWhenInactive={false}
            style={{ landing: true, bright: true }}
            onChange={({ value: key }) => {
              setErrors(errors => (errors ? { ...errors, state: null } : null))
              setState(
                _.get(
                  states.find(s => s.key === key),
                  'name'
                )
              )
            }}
          />
        )}
      </Grid>
      <Grid item lg={4} xs={12} id="rc-zip-code">
        <FormInput
          placeholder={t('pages.rcLanding.body.zip')}
          value={zipcode}
          validationRules={{ required: hasZipcode }}
          setValue={zip => setZipcode(zip?.trim())}
          errorMessage={errors?.zipcode}
          style={{ bright: true, landing: true }}
          isDisabled={!hasZipcode}
        />
      </Grid>
      <Grid container direction="column" alignItems="flex-end" className={classes.buttonContainer}>
        {!skipCaptcha && (
          <>
            <ReCAPTCHA sitekey="6LfqX-QZAAAAAICSPSpjfCOEdW_JflHk_A3zQykw" onChange={onCaptchaChange} />
            <span className={classes.errorMessage}>{errors?.captcha}</span>
          </>
        )}
        <PrimaryButton
          label={t('pages.rcLanding.body.saveBtn')}
          isLoading={isLoading}
          type="button"
          borderRadius="0px 0px 24px 0px"
          height={64}
          width={isMobile ? '100%' : '300px'}
          className={classes.button}
          onClick={handleFormSubmit}
        />
      </Grid>
    </Grid>
  )
}
