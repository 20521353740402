import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, IconButton, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { Document, Page, pdfjs } from 'react-pdf'
import DazzedParagraph14 from './text/DazzedParagraph14'
import { GalleryLeftArrow, GalleryRightArrow } from './icons'
import { isMobile } from 'utils/mobileUtils'
import { logError } from 'utils/logUtils'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '0px !important',
    overflowY: 'scroll',
    minHeight: isMobile() ? 'auto' : 800,
    minWidth: isMobile() ? 'auto' : 600,
    maxWidth: 'fit-content'
  },
  content: {
    overflowX: 'hidden',
    padding: '0px !important'
  },
  footerContainer: {
    paddingBottom: 10
  },
  loader: {
    minHeight: 800,
    minWidth: 600
  }
}))

const PDFViewer = ({ isOpen, onClose, file }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages)
  }, [])

  const handleNextPage = useCallback(() => {
    if (pageNumber + 1 <= numPages) {
      setPageNumber(pageNumber + 1)
    }
  }, [numPages, pageNumber])

  const handlePreviousPage = useCallback(() => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1)
    }
  }, [pageNumber])

  return (
    <BaseModal
      open={isOpen}
      handleClose={() => onClose(false)}
      contentClassName={classes.content}
      className={classes.root}
    >
      <Grid container alignItems="center" direction="column">
        <Grid item>
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={error => logError('An error occured while loading pdf file', { error, source: 'viewer' })}
            loading={
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={isMobile() ? '' : classes.loader}
              >
                <Grid item>
                  <CircularProgress color="inherit" size={isMobile() ? 40 : 60} />
                </Grid>
                <Grid item>
                  <DazzedParagraph14>{t('general.loading')}</DazzedParagraph14>
                </Grid>
              </Grid>
            }
          >
            <Page height={isMobile() ? 450 : undefined} pageNumber={pageNumber} />
          </Document>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" className={classes.footerContainer}>
          <Grid item>
            <IconButton onClick={handlePreviousPage}>
              <GalleryLeftArrow />
            </IconButton>
          </Grid>
          <Grid item>
            <DazzedParagraph14 style={{ userSelect: 'none' }}>
              {t('common.pdfViewer.pdfPagesText', { pageNumber, numPages })}
            </DazzedParagraph14>
          </Grid>
          <Grid item>
            <IconButton onClick={handleNextPage}>
              <GalleryRightArrow />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default PDFViewer
