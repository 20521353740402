import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Trans, useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import GrinRadioGroup from 'components/common/GrinRadioGroup'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { BlueDotModes } from 'consts/chatConsts'
import usePlanLimits from 'hooks/usePlanLimits'

const useStyles = makeStyles(theme => ({
  optionLabel: {
    fontWeight: 'bold',
    fontSize: 14
  }
}))

const BlueDotSettings = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isLoading = useSelector(state => state.profileReducer.isLoading)
  const practiceConfiguration = usePracticeConfiguration()
  const { withScanTracking } = usePlanLimits()

  const options = useMemo(
    () => [
      {
        value: BlueDotModes.view,
        label: t('pages.accountSettings.advancedActions.blueDotSettings.options.view')
      },
      {
        value: BlueDotModes.reply,
        label: t('pages.accountSettings.advancedActions.blueDotSettings.options.reply')
      }
    ],
    [t]
  )

  const editDisabled = useMemo(() => withScanTracking, [withScanTracking])
  const [selectedOption, setSelectedOption] = useState(options[0].value)

  const handleSave = useCallback(() => {
    if (withScanTracking) {
      return
    }

    dispatch(Actions.requestUpdateAppSettings({ blueDotMode: selectedOption }))
  }, [dispatch, selectedOption, withScanTracking])

  useEffect(() => {
    setSelectedOption(practiceConfiguration.blueDotMode)
  }, [practiceConfiguration.blueDotMode])

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <DazzedHeading20>{t('pages.accountSettings.advancedActions.blueDotSettings.title')}</DazzedHeading20>
      </Grid>
      <Grid item>
        <DazzedParagraph14 strong>
          {t('pages.accountSettings.advancedActions.blueDotSettings.description')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <DazzedParagraph14 strong>
          <Trans i18nKey="pages.accountSettings.advancedActions.blueDotSettings.note" />
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <GrinRadioGroup
          options={options}
          value={selectedOption}
          setValue={setSelectedOption}
          labelClassName={classes.optionLabel}
          size="small"
          isDisabled={editDisabled}
        />
      </Grid>
      {editDisabled && (
        <Grid item>
          <DazzedParagraph14 strong>
            {t('pages.accountSettings.advancedActions.blueDotSettings.disabledDueToPlanRequirements')}
          </DazzedParagraph14>
        </Grid>
      )}
      <Grid item>
        <PrimaryButton
          isLoading={isLoading}
          label={t('general.save')}
          disabled={!selectedOption || editDisabled || selectedOption === practiceConfiguration.blueDotMode}
          onClick={handleSave}
        />
      </Grid>
    </Grid>
  )
}

export default BlueDotSettings
