import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { trackEvent } from 'utils/analyticsUtils'
import Actions from 'actions'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import GrinTabs from 'components/common/tabs/GrinTabs'
import RecordsTab from '../../PatientCard/RecordsTab/RecordsTab'
import GrinTab from 'components/common/tabs/GrinTab'
import UploadFromComputerTab from './UploadFromComputerTab'

const useStyles = makeStyles({
  modal: {
    padding: '50px 30px 0px 35px',
    minWidth: 1250
  },
  content: {
    paddingRight: '0px !important'
  },
  tab: {
    whiteSpace: 'pre',
    maxWidth: 250
  },
  loader: {
    padding: 180
  }
})

const ShareScanAttachFilesModal = ({ files, setFiles }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isOpen, isUploading, uploadedFiles } = useSelector(state => state.treatmentReducer.shareScanAttachFilesModal)

  const [selectedFiles, setSelectedFiles] = useState(files)
  const [selectedTab, setSelectedTab] = useState(0)

  const handleCancel = useCallback(() => {
    trackEvent('Share scan attach files - cancel clicked', {
      tab: selectedTab.label
    })
    dispatch(
      Actions.toggleShareScanAttachFilesModal({
        isOpen: false
      })
    )
  }, [dispatch, selectedTab.label])

  const tabs = useMemo(() => {
    return [
      {
        index: 0,
        label: t('dialogs.shareScanAttachFiles.tabs.recordsTab.title'),
        Component: RecordsTab,
        condition: true
      },
      {
        index: 1,
        label: t('dialogs.shareScanAttachFiles.tabs.localFiles.title'),
        Component: UploadFromComputerTab,
        condition: true
      }
    ]
      .sort((a, b) => (a.index > b.index ? 1 : -1))
      .filter(tab => tab.condition)
  }, [t])

  const handleTabSelection = useCallback(
    (event, value) => {
      const selectedTab = tabs.find(tab => tab.index === value)
      trackEvent('Share scan attach files - tab selected', {
        tab: selectedTab.label
      })
      setSelectedTab(value)
    },
    [tabs]
  )

  const handleFileSelectionToggled = useCallback(
    file => {
      let updatedFiles

      if (selectedFiles.some(currFile => currFile.key === file.key)) {
        updatedFiles = selectedFiles.filter(currFile => currFile.key !== file.key)
      } else {
        updatedFiles = [...selectedFiles, file]
      }

      setSelectedFiles(updatedFiles)
    },
    [selectedFiles, setSelectedFiles]
  )

  const handleAttachFiles = useCallback(
    newFile => {
      trackEvent('Share scan attach files - Attach files clicked', {
        numberOfFiles: selectedFiles.length
      })
      setFiles(selectedFiles)
      dispatch(
        Actions.toggleShareScanAttachFilesModal({
          isOpen: false
        })
      )
    },
    [dispatch, selectedFiles, setFiles]
  )

  useEffect(() => {
    if (isOpen) {
      setSelectedFiles(files)
    }
  }, [files, isOpen, setFiles])

  useEffect(() => {
    if (uploadedFiles?.length > 0) {
      setFiles([...selectedFiles, ...uploadedFiles])
      dispatch(
        Actions.toggleShareScanAttachFilesModal({
          isOpen: false
        })
      )
    }
  }, [dispatch, isUploading, selectedFiles, setFiles, uploadedFiles])

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleCancel}
      className={classes.modal}
      hideScroll
      primaryLabel={t('dialogs.shareScanAttachFiles.primaryButtonLabel', {
        count: selectedFiles.length > 0 ? `(${selectedFiles.length})` : ''
      })}
      contentClassName={classes.content}
      onPrimaryBtnClick={handleAttachFiles}
      isPrimaryDisabled={!selectedFiles.length || isUploading}
      largerPrimaryButton
      buttonsWidth={130}
      onSecondaryBtnClick={handleCancel}
      secondaryLabel={t('general.cancel')}
    >
      {isOpen && (
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <DazzedParagraph16 bold>{t('dialogs.shareScanAttachFiles.title')}</DazzedParagraph16>
          </Grid>
          <Grid item>
            <GrinTabs value={selectedTab} variant="fullWidth" onChange={handleTabSelection}>
              {tabs.map(tab => (
                <GrinTab
                  key={tab.index}
                  label={tab.label}
                  marginRight="0px"
                  value={tab.index}
                  className={classes.tab}
                />
              ))}
            </GrinTabs>
          </Grid>
          <Grid item>
            {isUploading ? (
              <Grid container alignItems="center" justifyContent="center">
                <Grid item className={classes.loader}>
                  <CircularProgress size={40} />
                </Grid>
              </Grid>
            ) : (
              tabs
                .filter(tab => tab.index === selectedTab)
                .map(Tab => (
                  <Tab.Component
                    key={Tab.index}
                    setActiveTab={setSelectedTab}
                    firstTabIndex={tabs[0].index}
                    mode="selection"
                    onFileToggled={handleFileSelectionToggled}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                  />
                ))
            )}
          </Grid>
        </Grid>
      )}
    </BaseModal>
  )
}

export default ShareScanAttachFilesModal
