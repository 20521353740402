import * as React from 'react'

const GrinAssetsQuickLink = ({ className }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.6665 7.24061C2.6665 6.0499 3.63176 5.08464 4.82247 5.08464H12.062C12.4207 5.08464 12.7559 5.26305 12.9562 5.56059L15.4255 9.22823H29.3332V24.1718C29.3332 25.2764 28.4377 26.1718 27.3332 26.1718H4.6665C3.56193 26.1718 2.6665 25.2764 2.6665 24.1718V7.24061Z"
        stroke="#3C52EF"
      />
      <path
        d="M13.6611 6.60173H26.9914C27.3501 6.60173 27.6853 6.78014 27.8856 7.07768L29.3329 9.22737"
        stroke="#3C52EF"
      />
      <path d="M15.4255 9.22993H2.6665" stroke="#3C52EF" />
    </svg>
  )
}

export default GrinAssetsQuickLink
