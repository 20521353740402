import * as React from 'react'

function PackageArrived(props) {
  return (
    <svg width="78" height="68" viewBox="0 0 78 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.434814 9.28888V9.25296L0.448287 9.24847L0.434814 9.28888ZM0.434814 11.1994V42.5636L26.3496 51.2019V19.8377L0.434814 11.1994ZM30.0507 51.1975L55.9526 42.5636V11.2074L30.0507 19.8414V51.1975ZM53.0322 8.2795L28.1937 0L17.6291 3.52154L42.4676 11.801L53.0322 8.2795ZM14.703 4.49689L3.36723 8.27549L28.2057 16.555L39.5415 12.7764L14.703 4.49689Z"
        fill="#344563"
      />
      <circle cx="57.0045" cy="47.4288" r="18.2857" fill="#26C36F" stroke="white" strokeWidth="3.42857" />
      <path d="M48.4775 47.1074L54.1086 52.7385L65.5316 41.3154" stroke="white" strokeWidth="3.42857" />
    </svg>
  )
}

export default PackageArrived
