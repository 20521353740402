import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { sortPromotions } from 'utils/promotionUtils'
import { getDoNotShowPromotionsPopupOnThisDevice } from 'utils/storageUtils'

const ARCHIVED_CATEGORY_KEY = 'Archived'

export default () => {
  const owenerFF = useSelector(state => state.practiceReducer?.accountOwner?.user?.featureFlags?.flags)
  const listOwnerFF = Object.keys(JSON.parse(owenerFF || '{}'))
  const doNowShowPromotionsPopupOnThisDevice = getDoNotShowPromotionsPopupOnThisDevice()

  const promotions = useSelector(state =>
    state.chatReducer.promotions.data.filter(promotion => promotion.categoryKey !== ARCHIVED_CATEGORY_KEY)
  )

  const promotionsByFF = useMemo(
    () => promotions.filter(promotion => promotion.featureFlagIds?.some(flag => listOwnerFF.includes(flag))),
    [listOwnerFF, promotions]
  )

  const groupedPromotions = useMemo(() => {
    const grouped = {}
    promotionsByFF.forEach(promotion => {
      if (grouped[promotion.categoryKey]) {
        grouped[promotion.categoryKey].products.push(promotion)
      } else {
        grouped[promotion.categoryKey] = {
          title: promotion.categoryKey,
          id: promotion.categoryKey,
          products: [promotion]
        }
      }
    })
    const categories = Object.keys(grouped)
      .map(key => grouped[key])
      .map(category => ({
        ...category,
        products: category.products.sort(sortPromotions)
      }))
    return categories
  }, [promotionsByFF])

  const keywords = useMemo(() => _.uniq(_(promotionsByFF).map('keywords').chain().value().flat()), [promotionsByFF])

  const isMessageContainKeyword = useCallback(
    message => {
      return (
        !doNowShowPromotionsPopupOnThisDevice &&
        !!keywords.find(keyword => message.toLowerCase().includes(keyword.toLowerCase()))
      )
    },
    [doNowShowPromotionsPopupOnThisDevice, keywords]
  )

  return {
    promotions,
    groupedPromotions,
    isMessageContainKeyword
  }
}
