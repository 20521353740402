import * as React from 'react'

const ArrowDropdownDown = ({ color = '#001335', size = 7, isMobile }) => {
  return isMobile ? (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.680844 4.65112L4.34042 0.468743L8 4.65112L0.680844 4.65112Z" fill="#041333" />
    </svg>
  ) : (
    <svg width={size} height={size} viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.22754 0L6.45525 3.22771L3.22754 3.22771L-0.000171403 3.22771L3.22754 0Z" fill={color} />
    </svg>
  )
}

export default ArrowDropdownDown
