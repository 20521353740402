import React, { useMemo, useState } from 'react'
import { Select, MenuItem, makeStyles, FormControl, Grid, InputBase } from '@material-ui/core'
import GrinLabel from './text/GrinLabel'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { isMobile } from 'utils/mobileUtils'
import Tooltip from '../common/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import { scrollBar } from 'styles/theme'
import { GreySearch as SearchIcon } from './icons'

const useStyles = makeStyles(
  {
    selectContainer: {
      width: ({ width }) => width || 'auto',
      '& .MuiInputBase-root': {
        background: ({ bright, dark, disabled }) =>
          bright ? (disabled ? 'rgba(242, 243, 245, 0.5)' : 'var(--bg-color-10)') : 'var(--bg-color-4)',
        border: ({ bright, dark }) => (bright ? '1px solid var(--border-color-2)' : '1px solid var(--border-color-6)'),
        color: ({ bright, dark }) => (bright ? 'var(--text-color-6)' : 'var(--text-color-1)'),
        borderRadius: 0,
        height: ({ thick }) => (thick ? '42px' : '31px'),
        width: '100%',
        paddingLeft: 0,
        marginTop: '5px',
        fontSize: '15px',
        lineHeight: '25px',
        overflow: 'hidden',
        position: 'relative',
        '& fieldset': {
          borderWidth: '0!important'
        },
        '& .MuiSelect-selectMenu:focus': {
          backgroundColor: 'transparent'
        },
        '&.error': {
          border: ({ bright }) => (bright ? '1px solid red' : '1px solid var(--board-card-color-2)')
        },
        '&.landingTheme': {
          fontSize: ({ isMobile }) => (isMobile ? '12px!important' : '16px!important'),
          height: '48px!important',
          border: '1px solid var(--border-color-1)!important',
          paddingLeft: ({ isMobile }) => (isMobile ? 0 : 16)
        },
        '&.noSideBorders': {
          borderLeftWidth: '0!important',
          borderRightWidth: '0!important'
        },
        '@media (max-width: 960px)': {
          height: '42px !important',
          marginBottom: 5
        }
      },
      '& .arrow svg': {
        transform: ({ open }) => (open ? 'rotate(180deg)' : 'none')
      }
    },
    value: {
      color: ({ bright, dark }) => (bright ? 'var(--text-color-6)' : 'var(--text-color-1)'),
      fontSize: '15px',
      lineHeight: '25px',
      textOverflow: 'ellipsis'
    },
    errorMessage: {
      opacity: 0,
      color: ({ bright }) => (bright ? 'red' : 'var(--text-color-10)'),
      marginTop: '2px',
      marginBottom: '7px',
      minHeight: '17px',
      fontSize: ({ isMobile }) => (isMobile ? '10px' : '12px'),
      transition: 'opacity .15s ease',
      '&.active': {
        opacity: 1
      }
    },
    disabledValue: {
      color: 'var(--text-color-11)!important'
    },
    placeholder: {
      color: 'var(--text-color-11)!important'
    },
    bright: {},
    thin: {},
    thick: {},
    dropdownContainer: {
      maxHeight: 200,
      ...scrollBar
    },
    iconComponent: {
      display: 'flex',
      padding: '0 5px 0 0'
    },
    label: {
      '@media (max-width: 960px)': {
        fontSize: '14px !important'
      }
    },
    filterContainer: {
      padding: '0px 16px',
      borderBottom: '1px solid var(--border-color-7)',
      marginBottom: 10
    },
    filterInputContainer: {
      flex: 1
    },
    filterInput: {
      width: '100%'
    }
  },
  { isMobile: isMobile() }
)

const SelectInput = ({
  label,
  value,
  onChange,
  values = [],
  options = [],
  defaultValue,
  disabled = false,
  autoWidth = false,
  required,
  tooltip,
  style = { bright: false, thick: false, landing: false, noSideBorders: false },
  errorMessage = '',
  keepErrorContainerWhenInactive = true,
  placeholder,
  renderPlaceholderOption = true,
  arrowIconComponent,
  iconComponent,
  selectStyle = {},
  dropdownClassName = '',
  containerClassName = '',
  valueClassName = '',
  searchable = false,
  labelWrap = 'wrap'
}) => {
  const [open, setOpen] = useState(false)
  const [filterValue, setFilterValue] = useState('')

  const classes = useStyles({
    bright: style.bright,
    dark: style.dark,
    thin: style.thin,
    thick: style.thick,
    disabled,
    width: style.width,
    isMobile: isMobile(),
    open
  })

  const filteredOptions = useMemo(
    () =>
      !filterValue?.length
        ? options
        : options.filter(option => option.label.toLowerCase().includes(filterValue.toLowerCase())),
    [filterValue, options]
  )

  return (
    <Grid container className={[classes.selectContainer, containerClassName].join(' ')}>
      <FormControl variant="outlined" style={{ width: style.width || '100%' }}>
        {label && (
          <Grid container spacing={1}>
            <Grid item>
              <GrinLabel color={!style.bright && 'var(--text-color-1)'} className={classes.label}>
                {`${label}${required ? '*' : ''}`}
              </GrinLabel>
            </Grid>
            {tooltip && (
              <Grid item>
                <Tooltip value={tooltip} variant="dark">
                  <InfoIcon color="disabled" fontSize="inherit" />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        )}

        <Select
          open={open}
          style={selectStyle}
          disabled={disabled}
          autoWidth={autoWidth}
          value={value || placeholder || ''}
          onChange={({ target: value }) => onChange(value)}
          onClick={() => !disabled && setOpen(!open)}
          renderValue={val => {
            const option = options.find(opt => opt.value === val)
            const value = option?.inputValue || option?.label || val
            const optionIcon = option?.icon

            return (
              <Grid container alignItems="center" wrap={labelWrap}>
                {(optionIcon || iconComponent) && (
                  <Grid item className={classes.iconComponent}>
                    {optionIcon || iconComponent}
                  </Grid>
                )}
                <Grid item className={[classes.value, valueClassName].join(' ')}>
                  {value}
                </Grid>
              </Grid>
            )
          }}
          className={[
            errorMessage && 'error',
            style.landing && 'landingTheme',
            style.noSideBorders && 'noSideBorders',
            placeholder && !value && classes.placeholder
          ].join(' ')}
          required={required}
          IconComponent={arrowIconComponent ? arrowIconComponent : ArrowDropDownIcon}
          classes={{
            icon: 'arrow'
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            classes: {
              paper: [classes.dropdownContainer, dropdownClassName].join(' ')
            },
            getContentAnchorEl: null
          }}
        >
          {searchable && open && (
            <Grid
              container
              alignItems="center"
              className={classes.filterContainer}
              spacing={1}
              onClick={e => e.stopPropagation()}
              onKeyDown={e => e.stopPropagation()}
            >
              <Grid item>
                <SearchIcon />
              </Grid>
              <Grid item className={classes.filterInputContainer}>
                <InputBase
                  value={filterValue}
                  onChange={e => setFilterValue(e.target.value)}
                  className={classes.filterInput}
                  autoFocus
                />
              </Grid>
            </Grid>
          )}
          {placeholder && renderPlaceholderOption && (
            <MenuItem value={placeholder} disabled className={classes.disabledValue}>
              {placeholder}
            </MenuItem>
          )}
          {!!values &&
            values.map(value => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          {!!options &&
            filteredOptions.map(({ value, label, icon, disabled = false }) => (
              <MenuItem
                key={value}
                value={value}
                disabled={disabled}
                onKeyDown={e => searchable && e.stopPropagation()}
              >
                <Grid container spacing={1} alignItems="center">
                  {icon && (
                    <Grid item style={{ display: 'flex' }}>
                      {icon}
                    </Grid>
                  )}
                  <Grid item>{label}</Grid>
                </Grid>
              </MenuItem>
            ))}
        </Select>
        {(keepErrorContainerWhenInactive || errorMessage) && (
          <div className={`${classes.errorMessage} ${errorMessage && 'active'}`}>{errorMessage}</div>
        )}
      </FormControl>
    </Grid>
  )
}

export default SelectInput
