import { makeStyles } from '@material-ui/core'
import React from 'react'
import { isMobile } from 'utils/mobileUtils'
import Aligners from '../../common/icons/Aligners'
import WithoutAligners from '../../common/icons/WithoutAligners'

const useStyles = ({ isMobile }) =>
  makeStyles({
    relativeContainer: {
      position: 'relative'
    },
    alignersOverlay: {
      position: 'absolute',
      zIndex: 1000,
      top: isMobile ? '10%' : 15,
      right: isMobile ? '6.333vw' : 35
    },
    withoutAlignersOverlay: {
      position: 'absolute',
      zIndex: 1000,
      top: isMobile ? '10%' : 5,
      right: isMobile ? '6.333vw' : 30
    }
  })({ isMobile })
const AlignersScanOverlay = ({ withAligners, containerClassName = '' }) => {
  const classes = useStyles({ isMobile: isMobile() })

  return (
    <div className={classes.relativeContainer}>
      {withAligners && (
        <div className={[classes.alignersOverlay, containerClassName].join(' ')}>
          <Aligners />
        </div>
      )}
      {withAligners === false && (
        <div className={[classes.withoutAlignersOverlay, containerClassName].join(' ')}>
          <WithoutAligners />
        </div>
      )}
    </div>
  )
}

export default AlignersScanOverlay
