import * as React from 'react'

function SvgTriangleCheckMark(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2858 11.2991C14.8345 12.1874 14.1956 13.3332 13.1514 13.3332L2.84839 13.3332C1.80426 13.3332 1.16529 12.1874 1.71405 11.2991L6.86558 2.96021C7.38663 2.11678 8.6132 2.11678 9.13425 2.96021L14.2858 11.2991ZM8.66674 9.33318H7.3334V5.99985H8.66674V9.33318ZM7.3334 11.3332H8.66674V9.99985H7.3334V11.3332Z"
        fill={props.color || '#F25700'}
      />
    </svg>
  )
}

export default SvgTriangleCheckMark
