import React, { useCallback, useMemo, useRef, useState } from 'react'
import { isEmpty } from 'lodash'
import LinkButton from 'components/common/buttons/LinkButton'
import { makeStyles } from '@material-ui/core'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  previewContainer: {
    cursor: ({ viewMode }) => (viewMode ? 'default' : 'pointer'),
    display: 'flex',
    alignItems: 'flex-end'
  }
}))

const TeethSelectionFlow = ({
  selections = [],
  setSelections = () => {},
  addSelectionsButtonText = '',
  withEditButton = true,
  SelectionsPreviewComponent = () => {},
  TeethSelectionPopupComponent = () => {},
  viewMode,
  category
}) => {
  const classes = useStyles({ viewMode })
  const containerRef = useRef()

  const [isTeethSelectionPopupVisible, setIsTeethSelectionPopupVisible] = useState(false)

  const isViewModeAndEmpty = useMemo(() => viewMode && isEmpty(selections), [viewMode, selections])

  const handleAddButtonClicked = useCallback(() => {
    setIsTeethSelectionPopupVisible(true)
    trackEvent('Patient brief - action clicked', { action: addSelectionsButtonText, category })
  }, [addSelectionsButtonText, category])

  return isViewModeAndEmpty ? (
    <> </>
  ) : (
    <div ref={containerRef}>
      {!viewMode && isEmpty(selections) ? (
        <LinkButton label={addSelectionsButtonText} onClick={handleAddButtonClicked} underline fontSize={14} />
      ) : (
        <div onClick={() => !viewMode && setIsTeethSelectionPopupVisible(true)} className={classes.previewContainer}>
          {React.createElement(SelectionsPreviewComponent, { selections })}
        </div>
      )}
      {React.createElement(TeethSelectionPopupComponent, {
        isOpen: isTeethSelectionPopupVisible,
        setIsOpen: setIsTeethSelectionPopupVisible,
        anchorEl: containerRef.current,
        selections,
        setSelections
      })}
    </div>
  )
}

export default TeethSelectionFlow
