import React, { useRef, useState, useEffect, useCallback } from 'react'
import { makeStyles, Grid, Popper, ClickAwayListener } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import GrinCheckbox from 'components/common/GrinCheckbox'
import { InfoSmall } from 'components/common/icons'
import BroadcastComparisonModal from './BroadcastComparisonModal/BroadcastComparisonModal'
import useBroadcast from 'hooks/useBroadcast'
import {
  getDoNotShowPersonalMessagePopupOnThisDevice,
  setDoNotShowPersonalMessagePopupOnThisDevice
} from 'utils/storageUtils'

const useStyles = makeStyles({
  container: {
    marginBottom: 10
  },
  text: {
    fontSize: '13px'
  },
  bold: {
    fontWeight: 600
  },
  info: {
    cursor: 'pointer',
    marginTop: 6,
    marginLeft: 5
  },
  personalMessagePopupContainer: {
    zIndex: 999,
    borderRadius: 16,
    '-webkit-filter': 'drop-shadow(0px 3px 8px var(--border-color-21))',
    filter: 'drop-shadow(0px 3px 8px var(--border-color-21))',
    paddingBottom: 10
  },
  personalMessagePopup: {
    position: 'relative',
    padding: 13,
    borderRadius: 16,
    backgroundColor: 'white',
    maxWidth: 240,
    '&:after': {
      content: "''",
      position: 'absolute',
      right: '50%',
      bottom: -23,
      transform: 'translateX(50%)',
      background: 'var(--bg-color-10)',
      width: 25,
      height: 25,
      clipPath: 'polygon(0 0, 100% 0, 50% 50%)'
    }
  },
  link: {
    marginTop: 3,
    cursor: 'pointer'
  }
})

const BroadcastPersonalMessageCheckbox = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const checkboxRef = useRef(null)
  const { isPersonalMessage, togglePersonalMessage } = useBroadcast()

  const [showPersonalMessagePopup, setShowPersonalMessagePopup] = useState(false)
  const [isComparationModalOpen, setIsComparationModalOpen] = useState(false)

  useEffect(() => {
    if (!getDoNotShowPersonalMessagePopupOnThisDevice()) {
      setShowPersonalMessagePopup(true)
    }
  }, [])

  const handleClickAway = useCallback(() => {
    if (showPersonalMessagePopup) {
      setShowPersonalMessagePopup(false)
      setDoNotShowPersonalMessagePopupOnThisDevice(true)
    }
  }, [showPersonalMessagePopup])

  return (
    <>
      <Grid container alignItems="center" className={classes.container}>
        <Grid item ref={checkboxRef}>
          <GrinCheckbox
            checked={isPersonalMessage}
            onChange={togglePersonalMessage}
            color="var(--bg-color-12)"
            id="broadcast-personalMessageCheckbox"
          />
        </Grid>
        <Grid item>
          <DazzedParagraph14 strong className={classes.text}>
            {t('pages.patients.selectedPatient.chat.sendAs')}&nbsp;
            <span className={classes.bold}>{t('pages.patients.selectedPatient.chat.personalMessage')}</span>
          </DazzedParagraph14>
        </Grid>
        <Grid item onClick={() => setIsComparationModalOpen(true)}>
          <InfoSmall className={classes.info} />
        </Grid>
      </Grid>
      {checkboxRef.current && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            anchorEl={checkboxRef.current}
            open={showPersonalMessagePopup}
            className={classes.personalMessagePopupContainer}
            placement="top"
          >
            <div className={classes.personalMessagePopup}>
              <DazzedParagraph14 strong className={classes.label}>
                {t('pages.patients.selectedPatient.chat.personalMessageDescription')}
              </DazzedParagraph14>
              <DazzedParagraph14
                strong
                color="var(--text-color-8)"
                className={[classes.label, classes.link].join(' ')}
                onClick={() => setIsComparationModalOpen(true)}
              >
                {t('pages.patients.selectedPatient.chat.learnMore')}
              </DazzedParagraph14>
            </div>
          </Popper>
        </ClickAwayListener>
      )}
      <BroadcastComparisonModal isOpen={isComparationModalOpen} handleClose={() => setIsComparationModalOpen(false)} />
    </>
  )
}

export default BroadcastPersonalMessageCheckbox
