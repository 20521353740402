import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseAIMessage from '../BaseAIMessage'
import GrinAiSmall from 'components/common/icons/GrinAiSmall'
import { Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { getObjectUrl } from 'utils/mediaUtils'
import OralHygieneNoteMessageInput from './OralHygieneNoteMessageInput'
import useSendChatMessage from 'hooks/useSendChatMessage'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import ImagesViewer from 'components/common/ImagesViewer'

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14
  },
  image: {
    borderRadius: 13,
    maxWidth: '100%',
    cursor: 'pointer'
  }
}))

const OralHygieneRecommendationMessage = ({ id, content, timestamp, patientId, grinScanId }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { sendMessage } = useSendChatMessage()
  const parsedContent = useMemo(() => JSON.parse(content || '{}'), [content])
  const imgUrl = useMemo(() => getObjectUrl(parsedContent.s3Object), [parsedContent])
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false)

  const handleSend = useCallback(
    messageText => {
      sendMessage({
        type: 'imageV2',
        content: JSON.stringify({
          s3Object: parsedContent.s3Object,
          text: messageText
        })
      })
      dispatch(Actions.softDeleteChatMessage({ messageId: id }))
      dispatch(Actions.oralHygieneRecommendationSetUserAction({ grinScanId, action: 'message-sent' }))
      trackEvent('oral hygiene note - message sent to patient', {
        messageId: id,
        category: parsedContent.category,
        patientId
      })
    },
    [sendMessage, parsedContent, dispatch, id, patientId, grinScanId]
  )

  const handleDismiss = useCallback(
    dismissReason => {
      trackEvent('oral hygiene note - ai message dismissed', {
        messageId: id,
        category: parsedContent.category,
        patientId,
        dismissReason
      })
      dispatch(Actions.softDeleteChatMessage({ messageId: id }))
      dispatch(Actions.oralHygieneRecommendationSetUserAction({ grinScanId, action: 'dismissed', dismissReason }))
    },
    [dispatch, id, parsedContent, patientId, grinScanId]
  )

  return (
    <BaseAIMessage id={id} timestamp={timestamp} titleIcon={<GrinAiSmall />} width={600}>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <DazzedParagraph12 thickness="bold" className={classes.text}>
              {t(`pages.patients.selectedPatient.chat.oralHygieneRecommendation.${parsedContent.category}.title`)}
            </DazzedParagraph12>
          </Grid>
          <Grid item>
            <DazzedParagraph12 thickness="thin" className={classes.text}>
              {t(`pages.patients.selectedPatient.chat.oralHygieneRecommendation.${parsedContent.category}.text`)}
            </DazzedParagraph12>
          </Grid>
          <Grid item>
            <Grid container alignItems="flex-start" justifyContent="space-between" spacing={1}>
              {parsedContent.s3Object && (
                <Grid item xs={12} md={4}>
                  {isImageViewerOpen}
                  <img
                    src={imgUrl}
                    alt="recommendation-img"
                    className={classes.image}
                    onClick={() => setIsImageViewerOpen(true)}
                  />
                  <ImagesViewer
                    imageS3Objects={[parsedContent.s3Object]}
                    onClose={() => setIsImageViewerOpen(false)}
                    isVisible={isImageViewerOpen}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={8}>
                <OralHygieneNoteMessageInput
                  onDismiss={handleDismiss}
                  onSend={handleSend}
                  category={parsedContent?.category}
                  treatmentType={parsedContent.treatmentType}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BaseAIMessage>
  )
}

export default OralHygieneRecommendationMessage
