import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import DazzedParagraph12 from '../../common/text/DazzedParagraph12'
import SmallDotIcon from '../../common/icons/SmallDot'
import PrimaryButton from '../../common/buttons/PrimaryButton'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: '32px',
    marginBottom: '32px',
    borderBottom: '1px solid var(--border-color-18)'
  },
  disabledText: {
    color: 'var(--text-color-18)'
  },
  statusTitle: {
    fontSize: '14px',
    fontWeight: 600
  },
  description: {
    fontWeight: 'normal'
  },
  selectedButton: {
    backgroundColor: 'transparent',
    color: 'var(--text-color-19)',
    fontWeight: 600
  }
}))

const StatusInfoItem = ({ status, isSelected, onSelect }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const statusSettings = useMemo(() => JSON.parse(status.settings || '{}'), [status.settings])

  const handleSelect = useCallback(() => {
    if (!isSelected) {
      onSelect()
    }
  }, [isSelected, onSelect])

  return (
    <Grid container direction="row" className={classes.root} alignItems="flex-start" spacing={isMobile() ? 2 : 0}>
      <Grid item xs={isMobile() ? 4 : 3}>
        <Grid container alignItems="flex-start" direction="row">
          <Grid item xs={2}>
            <SmallDotIcon color={statusSettings.color} />
          </Grid>
          <Grid item xs={10}>
            <DazzedParagraph16
              className={[classes.statusTitle, status.isManualAssignmentAllowed ? '' : classes.disabledText].join(' ')}
            >
              {t(`statuses.names.${status.type}`)}
            </DazzedParagraph16>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={isMobile() ? 8 : 6}>
        <DazzedParagraph12
          className={[classes.description, status.isManualAssignmentAllowed ? '' : classes.disabledText].join(' ')}
        >
          {t(`statuses.statusInfoModal.descriptions.${status.key}`)}
        </DazzedParagraph12>
      </Grid>
      <Grid item xs={isMobile() ? 12 : 3}>
        {status.isManualAssignmentAllowed && (
          <Grid container justifyContent="center">
            <PrimaryButton
              fontSize={12}
              small
              height={32}
              label={
                isSelected
                  ? t('statuses.statusInfoModal.buttons.selected')
                  : t('statuses.statusInfoModal.buttons.select')
              }
              className={isSelected ? classes.selectedButton : ''}
              disabled={isSelected}
              onClick={handleSelect}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default StatusInfoItem
