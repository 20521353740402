import React, { Fragment, useCallback, useMemo } from 'react'
import {
  Grid,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  CircularProgress
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Actions from 'actions'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import ProfilePicture from 'components/common/ProfilePicture'

const useStyles = makeStyles({
  listContainer: {
    width: 360,
    height: '100%',
    flexDirection: 'column',
    overflow: 'auto',
    borderRight: '1px solid var(--border-color-26)'
  },
  listItem: {
    height: 88,
    width: '100%'
  },
  firstListItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatar: {
    width: 58,
    height: 58,
    margin: 16
  },
  listItemPrimaryText: {
    fontFamily: 'Dazzed',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    paddingBottom: 8
  },
  listItemSecondaryText: {
    fontFamily: 'Dazzed',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 'var(--text-color-44)'
  },
  loaderContainer: { display: 'flex', justifyContent: 'center', padding: 10 },
  loader: { color: 'var(--text-color-10)' }
})

const GIGrinScanList = () => {
  const classes = useStyles()

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { data: scans } = useSelector(state => state.giReducer.grinScansList)

  const mappedScans = useMemo(
    () =>
      scans.map(scan => ({
        ...scan,
        timeSinceCreation: `${moment().diff(moment(scan.createdAt), 'hours', false)} hours ago`,
        patientName: `${scan.patient?.details?.firstName} ${scan.patient?.details?.lastName}`,
        patientPhoto: scan.patient?.photo
      })),
    [scans]
  )

  const loadMoreScans = useCallback(async () => {
    dispatch(Actions.fetchTrackingRequiredGrinScans())
  }, [dispatch])

  return (
    <List className={classes.listContainer} id="scrollContainer">
      <InfiniteScroll
        dataLength={mappedScans.length}
        next={loadMoreScans}
        hasMore={true}
        loader={
          <div className={classes.loaderContainer}>
            <CircularProgress className={classes.loader} size={25} />
          </div>
        }
        scrollableTarget="scrollContainer"
      >
        <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.listItem}>
          <Grid item>
            <DazzedParagraph16 strong>
              {t('giDashboard.scanLeftToReview', { count: mappedScans.length })}
            </DazzedParagraph16>
          </Grid>
        </Grid>
        <Divider component="li" />
        {mappedScans.map(scan => (
          <Fragment key={scan.id}>
            <ListItem className={classes.listItem}>
              <ListItemAvatar>
                <ProfilePicture
                  photo={scan.patientPhoto}
                  name={scan.patientName}
                  size="large"
                  className={classes.avatar}
                />
              </ListItemAvatar>
              <ListItemText
                classes={{
                  primary: classes.listItemPrimaryText,
                  secondary: classes.listItemSecondaryText
                }}
                primary={scan.patientName}
                secondary={scan.timeSinceCreation}
              />
            </ListItem>
            <Divider component="li" />
          </Fragment>
        ))}
      </InfiniteScroll>
    </List>
  )
}

export default GIGrinScanList
