import * as React from 'react'

const Tablet = props => {
  return (
    <svg width="25" height="34" viewBox="0 0 25 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.2292 15.625H10.9375C10.651 15.625 10.4167 15.8594 10.4167 16.1458V17.1875C10.4167 17.474 10.651 17.7083 10.9375 17.7083H18.2292C18.5156 17.7083 18.75 17.474 18.75 17.1875V16.1458C18.75 15.8594 18.5156 15.625 18.2292 15.625ZM18.2292 21.875H10.9375C10.651 21.875 10.4167 22.1094 10.4167 22.3958V23.4375C10.4167 23.724 10.651 23.9583 10.9375 23.9583H18.2292C18.5156 23.9583 18.75 23.724 18.75 23.4375V22.3958C18.75 22.1094 18.5156 21.875 18.2292 21.875ZM7.29167 15.1042C6.42578 15.1042 5.72917 15.8008 5.72917 16.6667C5.72917 17.5326 6.42578 18.2292 7.29167 18.2292C8.15755 18.2292 8.85417 17.5326 8.85417 16.6667C8.85417 15.8008 8.15755 15.1042 7.29167 15.1042ZM7.29167 21.3542C6.42578 21.3542 5.72917 22.0508 5.72917 22.9167C5.72917 23.7826 6.42578 24.4792 7.29167 24.4792C8.15755 24.4792 8.85417 23.7826 8.85417 22.9167C8.85417 22.0508 8.15755 21.3542 7.29167 21.3542ZM21.875 4.16667H16.1068C16.1328 3.9974 16.1458 3.82161 16.1458 3.64583C16.1458 1.63411 14.5117 0 12.5 0C10.4883 0 8.85417 1.63411 8.85417 3.64583C8.85417 3.82161 8.86719 3.9974 8.89323 4.16667H3.125C1.39974 4.16667 0 5.56641 0 7.29167V30.2083C0 31.9336 1.39974 33.3333 3.125 33.3333H21.875C23.6003 33.3333 25 31.9336 25 30.2083V7.29167C25 5.56641 23.6003 4.16667 21.875 4.16667ZM12.5 2.08333C13.3659 2.08333 14.0625 2.77995 14.0625 3.64583C14.0625 4.51172 13.3659 5.20833 12.5 5.20833C11.6341 5.20833 10.9375 4.51172 10.9375 3.64583C10.9375 2.77995 11.6341 2.08333 12.5 2.08333ZM22.9167 30.2083C22.9167 30.7812 22.4479 31.25 21.875 31.25H3.125C2.55208 31.25 2.08333 30.7812 2.08333 30.2083V7.29167C2.08333 6.71875 2.55208 6.25 3.125 6.25H6.25V7.55208C6.25 7.98177 6.60156 8.33333 7.03125 8.33333H17.9688C18.3984 8.33333 18.75 7.98177 18.75 7.55208V6.25H21.875C22.4479 6.25 22.9167 6.71875 22.9167 7.29167V30.2083Z"
        fill="#C9C9C9"
      />
    </svg>
  )
}

export default Tablet
