import * as React from 'react'

const PartiallyTracking = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 4 }}
  >
    <rect x="1" y="1" width="18" height="18" rx="4" fill="#FFECCF" stroke="#FFDBA4" strokeWidth="2" />
    <rect width="1.66667" height="6.66667" transform="matrix(-1 0 0 1 10.8281 5)" fill="#CF8922" />
    <rect width="1.66667" height="1.66667" transform="matrix(-1 0 0 1 10.8281 13.333)" fill="#CF8922" />
  </svg>
)

export default PartiallyTracking
