import * as React from 'react'

function SvgChevronDownSmall({ className, fill, ...rest }) {
  return (
    <svg
      className={className}
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 0.923325C12 1.1587 11.9076 1.39407 11.7233 1.57406L8.02332 5.18312C7.48331 5.71017 6.76474 6 5.99997 6C5.23521 6 4.51663 5.70971 3.97663 5.18312L0.276613 1.57406C-0.0938647 1.21223 -0.0919599 0.627955 0.280899 0.268434C0.653758 -0.091087 1.25709 -0.0887794 1.62805 0.272588L5.32806 3.88164C5.50759 4.05702 5.74664 4.15347 5.99997 4.15347C6.25331 4.15347 6.49236 4.05702 6.67236 3.88164L10.3724 0.272588C10.7433 -0.089241 11.3462 -0.091087 11.7195 0.268434C11.9062 0.448887 12 0.686106 12 0.923325Z"
        fill={fill ?? '#C4C4C4'}
      />
    </svg>
  )
}

export default SvgChevronDownSmall
