import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../../../actions'
import BulletsSlideDesigner from './BulletsSlideDesigner'
import HeadlineSlideDesigner from './HeadlineSlideDesigner'
import { RELEASE_NOTES_MODAL_Z_INDEX } from 'consts/modalConsts'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles({
  dialog: {
    zIndex: `${RELEASE_NOTES_MODAL_Z_INDEX} !important`
  },
  container: {
    '& .MuiPaper-root': {
      borderRadius: '20px',
      transition: 'all 0.5s ease-out',
      height: 'auto'
    }
  },
  paper: {
    '& .MuiCard-root': {
      margin: '0!important',
      overflowY: 'scroll'
    }
  }
})

export default props => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const isOpen = useSelector(state => state.releaseNotesReducer.isModalOpen)
  const { version, description, headlineMedia, isHeadlineDisabled } = useSelector(
    state => state.releaseNotesReducer.modalReleaseNotes
  )
  const slides = useSelector(state => state.releaseNotesReducer.modalReleaseNotes.slides?.filter(s => !s.isDisabled))

  const [slideIndex, setSlideIndex] = useState(0)
  const [slide, setSlide] = useState(null)
  const [totalSlides, setTotalSlides] = useState()

  const shouldHavePrevious = useCallback(
    () =>
      !isHeadlineDisabled || (isHeadlineDisabled && slides.slice(0, slideIndex - 1).some(slide => !slide.isDisabled)),
    [isHeadlineDisabled, slides, slideIndex]
  )

  const handleClose = useCallback(
    (closeTrigger = 'x button') => {
      trackEvent('Release notes - closed', {
        numberOfPages: totalSlides,
        pageNumber: slideIndex + 1,
        notesVersion: version,
        closeTrigger
      })
      dispatch(actions.setReleaseNotesModalVisibility(false))
    },
    [dispatch, totalSlides, version, slideIndex]
  )

  const handleNextSlide = useCallback(() => {
    if (slideIndex === totalSlides) {
      return
    }
    trackEvent('Release notes - next clicked', {
      pageNumber: slideIndex + 1,
      numberOfPages: totalSlides,
      notesVersion: version
    })
    setSlideIndex(s => s + 1)
  }, [slideIndex, totalSlides, version])

  const handlePreviousSlide = useCallback(() => {
    if (slideIndex === 0) {
      return
    }
    trackEvent('Release notes - back clicked', {
      numberOfPages: totalSlides,
      pageNumber: slideIndex + 1,
      notesVersion: version
    })
    setSlideIndex(s => s - 1)
  }, [slideIndex, totalSlides, version])

  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'ArrowRight') {
        handleNextSlide()
      } else if (e.key === 'ArrowLeft' && shouldHavePrevious()) {
        handlePreviousSlide()
      }
    },
    [handleNextSlide, handlePreviousSlide, shouldHavePrevious]
  )

  useEffect(() => {
    if (!slides || !slides.length) {
      setTotalSlides(1)
      return
    }
    setTotalSlides(slides.length + 1)
  }, [slides])

  useEffect(() => {
    if (isOpen) {
      trackEvent('Release notes - popup seen', {
        numberOfPages: totalSlides,
        notesVersion: version
      })
    }
  }, [isOpen, totalSlides, version])

  useEffect(() => {
    if (slideIndex === 0) {
      setSlide(null)
    } else if (slideIndex === totalSlides) {
      setSlide(slides[slides.length - 1])
    } else {
      setSlide(slides[slideIndex - 1])
    }
  }, [slideIndex, slides, totalSlides])

  useEffect(() => {
    setSlideIndex(isHeadlineDisabled ? 1 : 0)
  }, [isOpen, isHeadlineDisabled])

  return (
    <Dialog
      maxWidth={false}
      closeAfterTransition
      open={isOpen}
      onClose={() => handleClose('backdrop')}
      onKeyDown={handleKeyDown}
      className={classes.dialog}
      classes={{
        container: classes.container,
        paper: classes.paper
      }}
    >
      {slideIndex === 0 || !slide ? (
        <HeadlineSlideDesigner
          data={{ version, description, isHeadlineDisabled }}
          mediaS3Object={headlineMedia}
          slideNumber={slideIndex + 1}
          totalSlides={totalSlides}
          onNextSlide={handleNextSlide}
          onPreviousSlide={handlePreviousSlide}
          onClose={handleClose}
        />
      ) : (
        <BulletsSlideDesigner
          data={slide}
          mediaS3Object={slide?.mediaObject}
          isHeadlineDisabled={isHeadlineDisabled}
          slideNumber={slideIndex + 1}
          totalSlides={totalSlides}
          onNextSlide={handleNextSlide}
          onPreviousSlide={handlePreviousSlide}
          hasPrevious={shouldHavePrevious()}
          onClose={handleClose}
        />
      )}
    </Dialog>
  )
}
