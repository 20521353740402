import * as React from 'react'

const BaseSTLTooth = () => (
  <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 10.8352V9.92316C1 9.641 1.02787 9.35953 1.08321 9.08286C1.13453 8.82623 1.20926 8.57484 1.30646 8.33185L1.48107 7.89532L1.8646 7.05156C1.96411 6.83264 2.01559 6.59496 2.01559 6.35448C2.01559 6.13657 2.05787 5.92072 2.14009 5.7189L2.60356 4.58129L2.82471 4.10951C3.20887 3.28997 3.73267 2.5435 4.37268 1.90349L4.52784 1.74833L4.92994 1.41325C5.08829 1.28129 5.26832 1.17779 5.46204 1.10734C5.65734 1.03633 5.86354 1 6.07135 1H6.28758C6.46817 1 6.6484 1.01625 6.82608 1.04856L7.73497 1.21381L7.94946 1.25111C8.62314 1.36827 9.2785 1.57347 9.89871 1.86142L10.461 2.12249L11.348 2.58708C11.5044 2.66904 11.6504 2.76963 11.7827 2.88666L12.2747 3.3219C12.6913 3.69043 13.2978 3.74899 13.7772 3.46698C13.8469 3.42598 13.9203 3.39166 13.9964 3.36446L14.9022 3.04097C15.2192 2.92777 15.5456 2.84299 15.8776 2.78766C16.1859 2.73629 16.4978 2.71047 16.8103 2.71047H17.4039C17.8661 2.71047 18.3234 2.80558 18.7474 2.9899C19.135 3.15845 19.4875 3.39845 19.7865 3.69737L20.4031 4.31403L21.8998 5.86414L22.1424 6.19495C22.3679 6.50245 22.677 6.7387 23.0329 6.87558C23.1325 6.91392 23.229 6.96022 23.3212 7.01404L23.3654 7.03981C23.6681 7.21638 23.9324 7.45178 24.1426 7.73213L24.2401 7.86205C24.598 8.33921 24.8251 8.90151 24.8991 9.49336L24.9323 9.7589C24.9773 10.1187 24.9779 10.4827 24.9343 10.8427L24.7862 12.0646L24.5189 14.7906L24.441 15.3752C24.3512 16.049 24.14 16.7009 23.8177 17.2994C23.6442 17.6217 23.4396 17.9263 23.2069 18.2088L22.5673 18.9855C22.2301 19.395 21.852 19.769 21.4388 20.1018L20.9911 20.4625C20.4237 20.9196 19.7952 21.2949 19.1237 21.5776L18.2667 21.9384C17.4868 22.2668 16.6587 22.4662 15.8148 22.5287L15.0734 22.5836C14.4947 22.6265 13.9131 22.6047 13.3392 22.5186L10.9955 22.167L8.4833 21.6325L6.36953 20.8802C5.68054 20.6349 5.03438 20.2829 4.45471 19.837L4.15466 19.6062C3.55529 19.1452 3.06265 18.56 2.71047 17.8909L2.17595 16.5011L1.69488 15.1114L1.48107 14.0958L1.1069 12.0646L1 10.8352Z"
      fill="#00D6E4"
      fillOpacity="0.35"
      stroke="#467B7F"
      strokeWidth="0.780417"
    />
    <path
      d="M3.9375 6.29236L4.48066 6.37812C4.79357 6.42753 5.11239 6.42617 5.42486 6.37409L5.73073 6.32311C5.85286 6.30276 5.97073 6.26209 6.07944 6.20279V6.20279C6.3497 6.05538 6.66908 6.02694 6.96114 6.12429L7.02754 6.14643C7.10541 6.17239 7.18059 6.20584 7.25201 6.24631L8.02542 6.68457C8.5065 6.95719 8.96511 7.26768 9.3969 7.61311L9.81723 7.94938L10.0171 8.11592C10.2839 8.33823 10.659 8.37602 10.9647 8.21139V8.21139C11.0887 8.14466 11.2272 8.10973 11.368 8.10973H11.559C11.7155 8.10973 11.8711 8.08689 12.021 8.04192L12.3295 7.94938L12.731 7.84901C13.031 7.77401 13.3421 7.75409 13.6492 7.79022V7.79022C13.9431 7.82479 14.2288 7.91015 14.4934 8.04248L14.7396 8.16555C15.0206 8.30605 15.3167 8.41401 15.6222 8.48733L16.4987 8.69771L17.5143 9.07187"
      stroke="#467B7F"
      strokeWidth="0.390208"
      strokeLinecap="round"
    />
    <path
      d="M10.7216 9.60446L10.8871 10.2997C10.9543 10.5817 10.9643 10.8743 10.9167 11.1602V11.1602C10.8936 11.2987 10.8572 11.4346 10.8079 11.566L10.7216 11.796C10.6504 12.0454 10.5078 12.555 10.5078 12.5978V12.5978C10.5078 13.4135 10.6193 14.2255 10.8393 15.011L10.882 15.1635"
      stroke="#467B7F"
      strokeWidth="0.390208"
      strokeLinecap="round"
    />
    <path
      d="M18.3164 10.461V10.461C18.6704 11.2397 18.8943 12.0711 18.9795 12.9222L19.0113 13.2405V14.7371L18.8509 16.8752"
      stroke="#467B7F"
      strokeWidth="0.390208"
      strokeLinecap="round"
    />
    <path
      d="M12.543 18.585L13.2765 18.6966C14.4177 18.8703 15.5806 18.8412 16.7118 18.6108V18.6108C17.4931 18.4516 18.2522 18.1978 18.9721 17.855L19.0107 17.8366"
      stroke="#467B7F"
      strokeWidth="0.390208"
      strokeLinecap="round"
    />
    <path
      d="M16.8164 8.37609L17.5647 7.78811L17.8455 7.60093C18.0856 7.44086 18.3372 7.29881 18.5983 7.17594V7.17594C18.9419 7.01428 19.3003 6.88651 19.6686 6.79443L19.7563 6.77252V6.77252C20.4288 6.70174 21.1083 6.74208 21.7677 6.89194L21.9478 6.93288"
      stroke="#467B7F"
      strokeWidth="0.390208"
      strokeLinecap="round"
    />
  </svg>
)

export default BaseSTLTooth
