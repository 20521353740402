import * as React from 'react'

const SeekerPause = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16.668" cy="17" r="16" stroke="#4151E6" />
    <rect width="2" height="18" transform="matrix(-1 0 0 1 14.334 8.33325)" fill="#4151E6" />
    <rect width="2" height="18" transform="matrix(-1 0 0 1 21 8.33325)" fill="#4151E6" />
  </svg>
)

export default SeekerPause
