import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { useCallback, useMemo } from 'react'
import { trackEvent } from 'utils/analyticsUtils'
import * as autochartUtils from 'utils/autochartUtils'
import { removeWhere, updateWhere } from 'utils/arrayUtils'

export default () => {
  const dispatch = useDispatch()
  const { grinScan } = useSelector(state => state.autochartReducer)
  const { doctor } = useSelector(state => state.profileReducer)

  const autochart = useMemo(() => {
    if (!grinScan?.autochart) {
      return {}
    }

    return {
      ...grinScan.autochart,
      teeth: Object.keys(grinScan.autochart.teeth).reduce(
        (final, toothNumber) => ({
          ...final,
          [toothNumber]: {
            ...grinScan.autochart.teeth[toothNumber],
            conditions: JSON.parse(grinScan.autochart.teeth[toothNumber].conditions || '[]')
          }
        }),
        {}
      )
    }
  }, [grinScan?.autochart])

  const updateToothConditions = useCallback(
    ({ toothNumber, conditions }) => {
      dispatch(
        Actions.autochartUpdateToothConditions({
          toothNumber,
          conditions
        })
      )
    },
    [dispatch]
  )

  const closeAutochartDialog = useCallback(() => {
    trackEvent('Autochart - dialog closed')
    dispatch(Actions.autochartToggleDialog({ isOpen: false }))
  }, [dispatch])

  const saveAutochartData = useCallback(() => {
    trackEvent('Autochart - data saved')
    dispatch(Actions.autochartSave(grinScan.autochart))
  }, [dispatch, grinScan])

  const createBlankCondition = useCallback(() => {
    return autochartUtils.createBlankCondition({
      createdBy: doctor.id
    })
  }, [doctor])

  const editCondition = useCallback(
    ({ conditionType, conditionsList = [] }) => {
      const condition = conditionsList.find(condition => condition.type === conditionType)
      if (!condition) {
        return null
      }

      return {
        ...condition,
        editedBy: doctor.id,
        updatedAt: new Date().toISOString()
      }
    },
    [doctor]
  )

  const deleteCondition = useCallback(({ conditionType, conditionsList = [] }) => {
    return removeWhere(conditionsList, condition => condition.type === conditionType)
  }, [])

  const updateConditionNotes = useCallback(
    ({ toothNumber, conditionType, notes }) => {
      const tooth = autochart.teeth[toothNumber - 1]
      if (!tooth) {
        return
      }

      updateToothConditions({
        toothNumber: toothNumber,
        conditions: updateWhere(
          tooth.conditions,
          condition => condition.type === conditionType,
          condition => (condition.note = notes)
        )
      })
    },
    [autochart, updateToothConditions]
  )

  const setGlobalNotes = useCallback(
    notes => {
      dispatch(Actions.autochartSetGlobalNotes({ notes }))
    },
    [dispatch]
  )

  return {
    grinScan,
    autochart,
    updateToothConditions,
    closeAutochartDialog,
    saveAutochartData,
    createBlankCondition,
    editCondition,
    deleteCondition,
    updateConditionNotes,
    setGlobalNotes
  }
}
