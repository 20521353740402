import { Button, Card, CardContent, IconButton, makeStyles, Slide } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import React, { useEffect, useState } from 'react'
import CloseIcon from '../../../common/icons/Close'
import SlideImage from './SlideImage'
import SlideMediaPicker from './SlideMediaPicker'
import SvgSwooshEllipse from '../../../common/icons/SwooshEllipse'
import SvgSwoosh from '../../../common/icons/Swoosh'
import { RELEASE_NOTES_CLOUDFRONT_URL } from '../../../../consts/appConsts'
import { isMobile } from 'utils/mobileUtils'

const useStyles = ({ isMobile }) =>
  makeStyles(theme => ({
    root: {
      backgroundColor: '#fff',
      borderRadius: '20px',
      boxShadow: '1px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
      flexDirection: 'column',
      width: isMobile ? 'calc(100vw - 70px)' : 488,
      maxWidth: isMobile ? 500 : 700,
      margin: 16,
      overflow: 'hidden',
      padding: 28,
      color: theme.palette.primary.main,
      position: 'relative'
    },
    disabledOverlay: {
      zIndex: 10,
      background: 'repeating-linear-gradient( 45deg, #ffffff, #ffffff 10px, #cecece 10px, #cecece 20px)',
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      opacity: 0.3
    },
    contentContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 3,
      padding: '0!important'
    },
    mediaContainer: {
      display: 'flex',
      alignSelf: 'stretch',
      alignItems: 'flex-end',
      justifyContent: 'center',
      color: 'black',
      position: 'relative'
    },
    mediaObject: {
      width: '100%',
      height: '100%',
      maxHeight: 300,
      objectFit: 'contain',
      borderRadius: 10
    },
    mediaActions: {
      position: 'absolute',
      left: 0,
      right: 0,
      padding: 8,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10
    },
    mediaActionButton: {
      color: '#fff'
    },
    mediaPicker: {
      height: 230
    },
    actionButtonsContainer: {
      direction: 'ltr',
      justifyContent: 'center',
      display: 'flex',
      padding: '20px 20px 10px 20px'
    },
    footer: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      marginTop: 30
    },
    footerActionButton: {
      padding: 0
    },
    footerIcon: {
      fontSize: 24
    },
    actionBtn: {
      margin: '0 6px',
      border: '1px solid var(--border-color-1)',
      borderColor: 'var(--border-color-1)',
      color: 'var(--text-color-3)',
      fontFamily: 'Dazzed, Arial',
      textTransform: 'none',
      borderRadius: '34px',
      transition: 'borderColor 0.5s ease',
      fontSize: '18px',
      minWidth: 90,
      '&:disabled': {
        color: '#ccc'
      }
    },
    nextSlideBtn: {
      backgroundColor: 'var(--border-color-1)',
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    },
    clsoeIconBtn: {
      position: 'absolute',
      top: '10px',
      right: '10px'
    },
    swooshContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      margin: '-28px',
      overflow: 'hidden'
    },
    swoosh: {
      position: 'absolute',
      top: 30,
      left: 0
    },
    swooshEllipse: {
      position: 'absolute',
      bottom: -120,
      right: -71
    }
  }))({ isMobile })

const BaseSlideDesigner = ({
  children,
  onMediaPicked,
  contentStyle = {},
  isUploading,
  mediaS3Object,
  onMediaRemoved,
  editMode,
  isSlideDisabled,
  slideNumber,
  totalSlides,
  onNextSlide,
  onPreviousSlide,
  hasPrevious,
  onClose
}) => {
  const classes = useStyles({ isMobile: isMobile() })

  const [shouldShowMediaActions, setShouldShowMediaActions] = useState(false)
  const [hasNext, setHasNext] = useState(false)

  useEffect(() => {
    setHasNext(!!onNextSlide && slideNumber < totalSlides)
  }, [onNextSlide, onPreviousSlide, slideNumber, totalSlides])

  return (
    <Card className={classes.root}>
      {isSlideDisabled && <div className={classes.disabledOverlay} />}
      <>
        <div className={classes.swooshContainer}>
          <SvgSwoosh className={classes.swoosh} />
          <SvgSwooshEllipse className={classes.swooshEllipse} />
        </div>
        {!editMode && (
          <IconButton className={classes.clsoeIconBtn} onClick={() => !!onClose && onClose()}>
            <CloseIcon color="#000" />
          </IconButton>
        )}

        <div className={classes.contentContainer} style={{ marginBottom: editMode && 0 }}>
          <CardContent className={classes.content} style={contentStyle}>
            {children}
          </CardContent>
        </div>

        {editMode && !mediaS3Object && (
          <div className={[classes.mediaContainer, classes.mediaPicker].join(' ')}>
            <SlideMediaPicker isLoading={isUploading} onFileAccepted={onMediaPicked} />
          </div>
        )}

        {!!mediaS3Object && (
          <>
            <div
              className={classes.mediaContainer}
              onMouseEnter={() => setShouldShowMediaActions(true)}
              onMouseLeave={() => setShouldShowMediaActions(false)}
            >
              <SlideImage
                src={`${RELEASE_NOTES_CLOUDFRONT_URL}/${mediaS3Object.key}`}
                className={classes.mediaObject}
                alt="Media"
              />
              {editMode && (
                <Slide in={shouldShowMediaActions} direction="up" timeout={200}>
                  <div className={classes.mediaActions}>
                    <IconButton className={classes.mediaActionButton} onClick={onMediaRemoved}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Slide>
              )}
            </div>
          </>
        )}

        {!editMode && (
          <div className={classes.footer}>
            <div>
              <Button
                variant="outlined"
                className={classes.actionBtn}
                disabled={!hasPrevious}
                onClick={onPreviousSlide}
              >
                Back
              </Button>
            </div>
            <div>
              <Button
                variant="outlined"
                className={[classes.actionBtn, classes.nextSlideBtn].join(' ')}
                onClick={() => (hasNext ? onNextSlide() : onClose('close button'))}
              >
                {hasNext ? 'Next' : 'Close'}
              </Button>
            </div>
          </div>
        )}
      </>
    </Card>
  )
}

export default BaseSlideDesigner
