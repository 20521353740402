import * as React from 'react'

const Upload = ({ color = '#344563', ...props }) => {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 9.14844V11.1855H2V9.14844H0ZM1.81481 13.0003H13.2222V11.0003H1.81481V13.0003ZM15.037 11.1855V9.14844H13.037V11.1855H15.037ZM13.2222 13.0003C14.2245 13.0003 15.037 12.1878 15.037 11.1855H13.037C13.037 11.0832 13.1199 11.0003 13.2222 11.0003V13.0003ZM0 11.1855C0 12.1878 0.812521 13.0003 1.81481 13.0003V11.0003C1.91709 11.0003 2 11.0832 2 11.1855H0Z"
        fill={color}
      />
      <path d="M7.51849 8.74038V1.81445" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.51854 1L5.0741 3.44444H9.96299L7.51854 1Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Upload
