import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ActionCheckbox from './ActionCheckbox'

const SendBrushingInstructions = ({ isChecked, onChange }) => {
  const { t } = useTranslation()

  const handleChecked = useCallback(checked => onChange({ isChecked: checked }), [onChange])

  return (
    <ActionCheckbox
      checked={isChecked}
      onChange={handleChecked}
      caption={t('pages.accountSettings.practiceGuidelines.actions.sendBrushingInstructionsToThePatient')}
    />
  )
}

export default SendBrushingInstructions
