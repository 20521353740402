import * as React from 'react'

export default props => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.23246 11.5007C8.23214 11.3777 8.29072 11.262 8.39005 11.1894C8.56135 11.0641 8.8018 11.1013 8.92712 11.2726C8.92744 11.2731 8.92776 11.2735 8.92808 11.2739L13.9242 18.1916C14.0494 18.3635 14.0116 18.6044 13.8396 18.7297C13.6677 18.8549 13.4268 18.8171 13.3016 18.6451L8.30548 11.7275C8.25773 11.6615 8.23214 11.5821 8.23246 11.5007Z"
        fill="#A6AAB3"
      />
      <path
        d="M8.23248 11.5021C8.2322 11.4206 8.25774 11.3413 8.3055 11.2753L13.3016 4.35762C13.4268 4.18568 13.6677 4.14786 13.8396 4.27306C14.0116 4.3983 14.0494 4.63919 13.9242 4.8111L8.9281 11.7288C8.80351 11.9006 8.56317 11.9389 8.39135 11.8143C8.39091 11.814 8.39046 11.8136 8.39006 11.8133C8.29073 11.7408 8.23216 11.6251 8.23248 11.5021V11.5021Z"
        fill="#A6AAB3"
      />
    </svg>
  )
}
