import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import BaseModal from 'components/common/modals/BaseModal'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import NewMemberForm from '../AddPracticeMember/NewMemberForm'

const useStyles = makeStyles(theme => ({
  dialog: {
    width: 480,
    borderRadius: 20,
    justifyContent: 'center'
  }
}))

const ResendInvitationModal = ({
  isOpen,
  onClose,
  onConfirm,
  firstName: firstNameValue,
  lastName: lastNameValue,
  email: emailValue
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const errors = useSelector(state => state.profileReducer.practiceMembers.errors)

  return (
    <BaseModal
      className={classes.dialog}
      open={isOpen}
      handleClose={onClose}
      title={t('pages.accountSettings.practiceMembers.modals.resendInvitation.title')}
    >
      <Grid container alignItems="center" justifyContent="center">
        <NewMemberForm
          handleCancel={onClose}
          handleSubmit={onConfirm}
          existingFirstName={firstNameValue}
          existingLastName={lastNameValue}
          existingEmail={emailValue}
          errors={errors}
          showAccessPicker={false}
          primaryButtonLabel={t('pages.accountSettings.practiceMembers.modals.resendInvitation.primaryBtn')}
        />
      </Grid>
    </BaseModal>
  )
}

export default ResendInvitationModal
