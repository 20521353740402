export const COUNTRY_AT = 'AT'
export const COUNTRY_BE = 'BE'
export const COUNTRY_BG = 'BG'
export const COUNTRY_CA = 'CA'
export const COUNTRY_HR = 'HR'
export const COUNTRY_CY = 'CY'
export const COUNTRY_CZ = 'CZ'
export const COUNTRY_EE = 'EE'
export const COUNTRY_FI = 'FI'
export const COUNTRY_FR = 'FR'
export const COUNTRY_DE = 'DE'
export const COUNTRY_GR = 'GR'
export const COUNTRY_HU = 'HU'
export const COUNTRY_IE = 'IE'
export const COUNTRY_IL = 'IL'
export const COUNTRY_IT = 'IT'
export const COUNTRY_LV = 'LV'
export const COUNTRY_LT = 'LT'
export const COUNTRY_LU = 'LU'
export const COUNTRY_MT = 'MT'
export const COUNTRY_NL = 'NL'
export const COUNTRY_PL = 'PL'
export const COUNTRY_PT = 'PT'
export const COUNTRY_RO = 'RO'
export const COUNTRY_SK = 'SK'
export const COUNTRY_SI = 'SI'
export const COUNTRY_ES = 'ES'
export const COUNTRY_GB = 'GB'
export const COUNTRY_US = 'US'
export const COUNTRY_DK = 'DK'
export const COUNTRY_SE = 'SE'
export const COUNTRY_IN = 'IN'
export const COUNTRY_TR = 'TR'
export const COUNTRY_TC = 'TC'
export const COUNTRY_BR = 'BR'
export const COUNTRY_CH = 'CH'

export const DEFAULT_COUNTRY = {
  key: COUNTRY_US,
  name: 'United States'
}

export const US_STATES = [
  { key: 'AL', name: 'Alabama' },
  { key: 'AK', name: 'Alaska' },
  { key: 'AZ', name: 'Arizona' },
  { key: 'AR', name: 'Arkansas' },
  { key: 'CA', name: 'California' },
  { key: 'CO', name: 'Colorado' },
  { key: 'CT', name: 'Connecticut' },
  { key: 'DE', name: 'Delaware' },
  { key: 'DC ', name: 'District of Columbia' },
  { key: 'FL', name: 'Florida' },
  { key: 'GA', name: 'Georgia' },
  { key: 'HI', name: 'Hawaii' },
  { key: 'ID', name: 'Idaho' },
  { key: 'IL', name: 'Illinois' },
  { key: 'IN', name: 'Indiana' },
  { key: 'IA', name: 'Iowa' },
  { key: 'KS', name: 'Kansas' },
  { key: 'KY', name: 'Kentucky' },
  { key: 'LA', name: 'Louisiana' },
  { key: 'ME', name: 'Maine' },
  { key: 'MD', name: 'Maryland' },
  { key: 'MA', name: 'Massachusetts' },
  { key: 'MI', name: 'Michigan' },
  { key: 'MN', name: 'Minnesota' },
  { key: 'MS', name: 'Mississippi' },
  { key: 'MO', name: 'Missouri' },
  { key: 'MT', name: 'Montana' },
  { key: 'NE', name: 'Nebraska' },
  { key: 'NV', name: 'Nevada' },
  { key: 'NH', name: 'New Hampshire' },
  { key: 'NJ', name: 'New Jersey' },
  { key: 'NM', name: 'New Mexico' },
  { key: 'NY', name: 'New York' },
  { key: 'NC', name: 'North Carolina' },
  { key: 'ND', name: 'North Dakota' },
  { key: 'OH', name: 'Ohio' },
  { key: 'OK', name: 'Oklahoma' },
  { key: 'OR', name: 'Oregon' },
  { key: 'PA', name: 'Pennsylvania' },
  { key: 'PR', name: 'Puerto Rico' },
  { key: 'RI', name: 'Rhode Island' },
  { key: 'SC', name: 'South Carolina' },
  { key: 'SD', name: 'South Dakota' },
  { key: 'TN', name: 'Tennessee' },
  { key: 'TX', name: 'Texas' },
  { key: 'UT', name: 'Utah' },
  { key: 'VT', name: 'Vermont' },
  { key: 'VA', name: 'Virginia' },
  { key: 'WA', name: 'Washington' },
  { key: 'WV', name: 'West Virginia' },
  { key: 'WI', name: 'Wisconsin' },
  { key: 'WY', name: 'Wyoming' }
]

export const CA_STATES = [
  { key: 'AB', name: 'Alberta' },
  { key: 'BC', name: 'British Columbia' },
  { key: 'MB', name: 'Manitoba' },
  { key: 'NB', name: 'New Brunswick' },
  { key: 'NL', name: 'Newfoundland and Labrador' },
  { key: 'NT', name: 'Northwest Territories' },
  { key: 'NS', name: 'Nova Scotia' },
  { key: 'NU', name: 'Nunavut' },
  { key: 'ON', name: 'Ontario' },
  { key: 'PE', name: 'Prince Edward Island' },
  { key: 'QC', name: 'Quebec' },
  { key: 'SK', name: 'Saskatchewan' },
  { key: 'YT', name: 'Yukon' }
]

export const BR_STATES = [
  { key: 'AC', name: 'Acre' },
  { key: 'AL', name: 'Alagoas' },
  { key: 'AM', name: 'Amazonas' },
  { key: 'AP', name: 'Amapá' },
  { key: 'BA', name: 'Bahia' },
  { key: 'CE', name: 'Ceará' },
  { key: 'DF', name: 'Distrito Federal' },
  { key: 'ES', name: 'Espírito Santo' },
  { key: 'GO', name: 'Goiás' },
  { key: 'MA', name: 'Maranhão' },
  { key: 'MG', name: 'Minas Gerais' },
  { key: 'MS', name: 'Mato Grosso do Sul' },
  { key: 'MT', name: 'Mato Grosso' },
  { key: 'PA', name: 'Pará' },
  { key: 'PB', name: 'Paraíba' },
  { key: 'PE', name: 'Pernambuco' },
  { key: 'PI', name: 'Piauí' },
  { key: 'PR', name: 'Paraná' },
  { key: 'RJ', name: 'Rio de Janeiro' },
  { key: 'RN', name: 'Rio Grande do Norte' },
  { key: 'RO', name: 'Rondônia' },
  { key: 'RR', name: 'Roraima' },
  { key: 'RS', name: 'Rio Grande do Sul' },
  { key: 'SC', name: 'Santa Catarina' },
  { key: 'SE', name: 'Sergipe' },
  { key: 'SP', name: 'São Paulo' },
  { key: 'TO', name: 'Tocantins' }
]

export const CH_STATES = [
  { key: 'AR', name: 'Appenzell Ausserrhoden' },
  { key: 'AI', name: 'Appenzell Innerrhoden' },
  { key: 'AG', name: 'Aargau' },
  { key: 'BL', name: 'Basel Land' },
  { key: 'BS', name: 'Basel Stadt' },
  { key: 'FR', name: 'Freiburg' },
  { key: 'GE', name: 'Geneve' },
  { key: 'GL', name: 'Glarus' },
  { key: 'GR', name: 'Graubunden' },
  { key: 'JU', name: 'Jura' },
  { key: 'LU', name: 'Luzern' },
  { key: 'NE', name: 'Neuchatel' },
  { key: 'NW', name: 'Nidwalden' },
  { key: 'OW', name: 'Obwalden' },
  { key: 'SG', name: 'Sankt Gallen' },
  { key: 'SH', name: 'Schaffhausen' },
  { key: 'SZ', name: 'Schwyz' },
  { key: 'SO', name: 'Solothurn' },
  { key: 'TG', name: 'Thurgau' },
  { key: 'TI', name: 'Ticino' },
  { key: 'UR', name: 'Uri' },
  { key: 'VS', name: 'Valais' },
  { key: 'VD', name: 'Vaud' },
  { key: 'ZG', name: 'Zug' },
  { key: 'ZH', name: 'Zurich' }
]
export const DEFAULT_US_STATE = { key: 'AL', name: 'Alabama' }
export const DEFAULT_CA_STATE = { key: 'AB', name: 'Alberta' }

export default US_STATES

export const PHONE_MASKS_BY_COUNTRY_CODE = {
  1: '(###) ###-####-####',
  7: '(###) ###-##-##-##-##',
  31: '## ############',
  32: '### ## ## ## ## ##',
  33: '# ## ## ## ## ## ##',
  34: '### ### ### ###',
  39: '### ###########',
  41: '## ### ## ## ## ##',
  44: '#### ##########',
  45: '## ## ## ## ## ##',
  46: '(###) ###-###-###',
  47: '### ## ### ###',
  48: '###-###-###-###',
  49: '#### ############',
  52: '### ### #### ####',
  54: '(##) ############',
  55: '(##) #############',
  60: '##-####-####-####',
  61: '(##) #### #### ####',
  63: '#### ####### ####',
  64: '###-###-####-####',
  65: '####-####-####',
  81: '## #### #### ####',
  82: '### #### #### ####',
  86: '##-#############',
  90: '### ### ## ## ## ##',
  91: '#####-#####-####',
  92: '###-#######-####',
  225: '## ## ## ## ## ##',
  353: '## ####### ####',
  354: '### #### ####',
  357: '## ###### ####',
  358: '## ### ## ## ## ##',
  372: '#### ###### ####',
  373: '(##) ##-##-##-##-##',
  375: '(##) ### ## ## ## ##',
  380: '(##) ### ## ## ## ##',
  502: '####-####-####',
  503: '####-####-####',
  506: '####-####-####',
  509: '####-####-####',
  852: '#### #### ####',
  972: '### ### #### ####'
}
