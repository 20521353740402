import * as React from 'react'

const Check1 = ({ color = '#AFAFAF', width = 12, height = 10, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.8243 0.769496C11.59 0.535168 11.2101 0.535168 10.9757 0.769496L3.78737 7.95794L1.02428 5.19485C0.789973 4.96052 0.410098 4.96054 0.175746 5.19485C-0.058582 5.42915 -0.058582 5.80903 0.175746 6.04336L3.36311 9.23067C3.59734 9.46497 3.9775 9.46481 4.21164 9.23067L11.8243 1.61803C12.0586 1.38372 12.0586 1.00382 11.8243 0.769496Z"
        fill={color}
      />
    </svg>
  )
}

export default Check1
