import * as React from 'react'

const ScanReview = ({ color = '#344563', ...props }) => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.80616 0.5H1.10986C0.496882 0.5 0 0.996882 0 1.60986V8.30616C0 8.91913 0.496882 9.41602 1.10986 9.41602H7.80616C8.41913 9.41602 8.91602 8.91913 8.91602 8.30616V1.60986C8.91602 0.996882 8.41913 0.5 7.80616 0.5ZM12.2038 1.37535L9.65902 3.13069V6.78533L12.2038 8.53835C12.696 8.87734 13.374 8.53138 13.374 7.9393V1.97439C13.374 1.38464 12.6984 1.03635 12.2038 1.37535Z"
        fill={color}
      />
    </svg>
  )
}

export default ScanReview
