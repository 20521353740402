import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Copy } from 'components/common/icons'

const useStyles = makeStyles(theme => ({
  trackingUrl: {
    textOverflow: 'ellipsis',
    maxWidth: '450px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: '0 10px'
  }
}))

const TrackingUrl = ({ href }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(href)
  }, [href])

  return (
    <Grid container alignItems="center">
      <DazzedParagraph14 strong>{t('pages.rcDashboard.dialogs.shippingDetails.trackingUrl')}</DazzedParagraph14>
      <DazzedParagraph14 className={classes.trackingUrl}>
        <a href={href} target="_blank" rel="noreferrer">
          {href}
        </a>
      </DazzedParagraph14>
      <IconButton size="small" onClick={handleCopy}>
        <Copy color="var(--text-color-11)" />
      </IconButton>
    </Grid>
  )
}

export default TrackingUrl
