import * as React from 'react'

function SmallDotIcon({ color = '#CED0D4', style = {} }) {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <circle cx="4" cy="4" r="4" fill={color} />
    </svg>
  )
}

export default SmallDotIcon
