import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase, Grid } from '@material-ui/core'
import Add from 'components/common/icons/Add'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    height: 82,
    padding: 12,
    transition: '.4s',
    '&:hover': {
      background: '#F8F9FA'
    }
  },
  createNewButton: {
    background: 'transparent'
  },
  createNewButtonText: {
    color: 'var(--text-color-3)',
    lineHeight: '30px '
  }
}))

const CreateNewButton = ({ onClick = () => {} }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <ButtonBase className={classes.button} onClick={onClick}>
      <Grid container direction="row" className={classes.createNewButton} justifyContent="flex-start" spacing={1}>
        <Grid item>
          <Add height={30} width={30} plusColor={'var(--bg-color-10)'} backColor={'var(--bg-color-7)'} />
        </Grid>
        <Grid item>
          <DazzedParagraph14 className={classes.createNewButtonText}>
            {t('pages.accountSettings.savedReplies.createReplyButtonText')}
          </DazzedParagraph14>
        </Grid>
      </Grid>
    </ButtonBase>
  )
}

export default CreateNewButton
