import React from 'react'
import { makeStyles } from '@material-ui/core'
import ButtonBase from '@material-ui/core/ButtonBase'
import ExpandCollapseButton from 'components/common/buttons/ExpandCollapseButton'
import { isMobile } from 'utils/mobileUtils'

const TimelineButtonBase = ({ setExpand, expand, value = '', className = '' }) => {
  const classes = makeStyles({
    date: {
      fontFamily: 'Dazzed',
      fontSize: 25,
      fontWeight: 500,
      color: 'var(--text-color-6)'
    }
  })()

  return (
    <ButtonBase disableRipple={true} onClick={() => setExpand(!expand)}>
      <span className={className}>
        <ExpandCollapseButton size={isMobile() ? 12 : 9} color={'black'} expand={expand} setExpand={setExpand} />
      </span>
      <span className={classes.date}>{value}</span>
    </ButtonBase>
  )
}

export default TimelineButtonBase
