import React from 'react'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import Tooltip from 'components/common/Tooltip'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  label: {
    padding: '3px 0',
    fontWeight: '400!important',
    fontSize: 12,
    lineHeight: '12px',
    textAlign: 'center',
    color: 'var(--text-color-miniTimeline-date)',
    fontFamily: 'Dazzed',
    userSelect: 'none'
  }
}))

const DateLabelTimelineItem = ({ timelineItem }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Tooltip
      placement="right"
      value={t(`pages.patients.selectedPatient.timelineV2.miniTimeline.totalScansTooltip`, {
        value: timelineItem.payload.totalScans
      })}
    >
      <div className={classes.label}>{moment(timelineItem.eventDate).format("MMM  'YY").toUpperCase()}</div>
    </Tooltip>
  )
}

export default React.memo(DateLabelTimelineItem)
