import { Grid, makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import GrinSwitch from '../../common/GrinSwitch'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import GrinCheckbox from 'components/common/GrinCheckbox'
import { trackEvent } from 'utils/analyticsUtils'
import { NOTIFY_PRACTICE_REASONS } from 'consts/giConsts'

const FilterTypes = {
  Assignee: 'assignee',
  NeedsAttentionClinical: NOTIFY_PRACTICE_REASONS.CLINICAL,
  NeedsAttentionScheduling: NOTIFY_PRACTICE_REASONS.SCHEDULING,
  NeedsAttentionMissingInfo: NOTIFY_PRACTICE_REASONS.MISSING_INFO,
  NeedsAttentionPatientQuestion: NOTIFY_PRACTICE_REASONS.PATIENT_QUESTION,
  NeedsAttentionOther: NOTIFY_PRACTICE_REASONS.OTHER
}

const PREFERENCES_TEXTS = {
  Message: 'pages.accountSettings.notificationSettings.preferences.message',
  Scan: 'pages.accountSettings.notificationSettings.preferences.scan',
  Patient: 'pages.accountSettings.notificationSettings.preferences.patient',
  Assignee: 'pages.accountSettings.notificationSettings.preferences.assignee',
  RCLead: 'pages.accountSettings.notificationSettings.preferences.rcLead',
  ServiceAccountNewLead: 'pages.accountSettings.notificationSettings.preferences.ServiceAccountNewLead',
  NeedsAttention: 'pages.accountSettings.notificationSettings.preferences.needsAttention'
}

const entitiesMediumsOptions = {
  Message: ['email', 'sms'],
  Scan: ['email', 'sms'],
  Patient: ['email', 'sms'],
  Assignee: ['email', 'sms'],
  RCLead: ['email', 'sms'],
  ServiceAccountNewLead: ['email', 'sms'],
  NeedsAttention: ['email', 'sms']
}

const entityFilterOptions = {
  Message: [FilterTypes.Assignee],
  Scan: [FilterTypes.Assignee],
  NeedsAttention: [
    FilterTypes.NeedsAttentionClinical,
    FilterTypes.NeedsAttentionMissingInfo,
    FilterTypes.NeedsAttentionPatientQuestion,
    FilterTypes.NeedsAttentionScheduling,
    FilterTypes.NeedsAttentionOther
  ]
}

const useStyles = makeStyles(theme => ({
  checkboxLabel: {
    fontSize: 14
  }
}))

const Preference = ({ entity, updateSubscriptions }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleToggleFilter = useCallback(
    (filter, value) => {
      trackEvent(`Notifications settings - notification filter toggled`, {
        notificationType: entity.type,
        filter,
        toggle: value ? 'on' : 'off'
      })

      const filters = entity.filters || []

      updateSubscriptions({
        ...entity,
        filters: value ? [...new Set([...filters, filter])] : filters.filter(curr => curr !== filter)
      })
    },
    [entity, updateSubscriptions]
  )

  const handleMediumChanged = useCallback(
    ({ target, medium }) => {
      trackEvent('Notifications settings - medium changed', {
        entityType: entity.type,
        medium,
        action: target.checked ? 'on' : 'off'
      })
      updateSubscriptions({
        ...entity,
        type: entity.type,
        medium: target.checked ? [...entity.medium, medium] : entity.medium.filter(m => m !== medium)
      })
    },
    [updateSubscriptions, entity]
  )

  const renderMediumCheckbox = useCallback(
    medium => (
      <div>
        <GrinSwitch
          checked={entity.medium.includes(medium)}
          onChange={({ target }) => handleMediumChanged({ target, medium })}
        />
      </div>
    ),
    [entity, handleMediumChanged]
  )

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={8}>
            <DazzedParagraph14 strong>{t(PREFERENCES_TEXTS[entity.type])}</DazzedParagraph14>
          </Grid>
          <Grid item xs={2}>
            {entitiesMediumsOptions[entity.type].includes('email') && renderMediumCheckbox('email')}
          </Grid>
          <Grid item xs={2}>
            {entitiesMediumsOptions[entity.type].includes('sms') && renderMediumCheckbox('sms')}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {entityFilterOptions[entity.type] && (
          <Grid item xs={12}>
            {entityFilterOptions[entity.type].map((filterOption, i) => (
              <GrinCheckbox
                key={i}
                variant="filled"
                checked={entity.filters?.includes(filterOption)}
                onChange={value => handleToggleFilter(filterOption, value)}
                caption={t(`pages.accountSettings.notificationSettings.filters.${filterOption}`)}
                captionClassName={classes.checkboxLabel}
              />
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default Preference
