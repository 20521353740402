import React, { useEffect, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { isMobile } from 'utils/mobileUtils'
import { Grid } from '@material-ui/core'
import StatusPicker from 'components/common/inputs/StatusPicker/StatusPicker'
import { useCallback } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import AssignPatientForm from 'components/Patients/AssignPatient/AssignPatientForm'
import Actions from 'actions'
import { Assignee2 } from 'components/common/icons'
import usePatientTags from 'hooks/usePatientTags'
import { useAssignPatient } from 'components/Patients/AssignPatient/useAssignPatient'
import FrequencyPicker from 'components/common/inputs/FrequencyPicker'
import { getPeriodTypeOptions, ScanFrequencyOptions } from 'utils/statusUtils'
import ArchivedStatusMessage from 'components/Patients/Statuses/ArchivedStatusMessage'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 650,
    maxWidth: '650px !important'
  },
  container: {
    padding: '0px 20px 20px 20px'
  },
  titleContainer: {
    padding: '20px 20px 0px 20px'
  },
  statusPicker: {
    margin: 0,
    maxWidth: '160px'
  },
  divider: {
    border: '0.526316px solid var(--border-color-14)',
    margin: '5px 0px 5px 0px'
  }
}))

const RCResolvePatientPopup = ({ isOpen, lead, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { practiceMembersSelectOptions } = useAssignPatient({ withUnassignOption: true })
  const { assigneeTag } = usePatientTags({ patient: lead?.patient || {} })

  const newLeadStatus = useSelector(state =>
    state.treatmentReducer?.statuses?.data['rc']?.find(status => status.key === 'new-lead_rc')
  )

  const [status, setStatus] = useState(null)
  const [assignee, setAssignee] = useState('Unassigned')
  const [note, setNote] = useState('')
  const [cycleInterval, setCycleInterval] = useState('')
  const [cycleIntervalPeriodType, setCycleIntervalPeriodType] = useState('')

  const frequencyOptions = useMemo(() => ScanFrequencyOptions[status?.type], [status])
  const periodTypeOptions = useMemo(() => getPeriodTypeOptions(status?.key), [status])
  const scanFrequencyDefaults = useMemo(() => JSON.parse(status?.scanFrequencyDefaults || '{}'), [status])

  const handleClose = useCallback(() => {
    setStatus(newLeadStatus)
    setNote('')
    onClose()
  }, [newLeadStatus, onClose])

  const handleStatusChange = useCallback(status => {
    setStatus(status)

    const statusScanDefaults = JSON.parse(status?.scanFrequencyDefaults || '{}')
    setCycleInterval(statusScanDefaults.periodLength || ScanFrequencyOptions['active-treatment'][0])
    setCycleIntervalPeriodType(statusScanDefaults?.periodType || 'day')
  }, [])

  const handleSubmit = useCallback(() => {
    const pmToAssign = practiceMembersSelectOptions.find(option => option.value === assignee)
    const { key: statusKey } = status

    if (pmToAssign.value !== 'Unassigned') {
      trackEvent('Assign patient - doctor confirm assign patient', {
        newAssignee: pmToAssign.value,
        analyticsSource: 'Resolve lead card'
      })

      dispatch(
        Actions.assignPatientToPracticeMember({
          patientId: lead.id,
          patientUserId: lead.patient.user.id,
          assigneeId: assignee,
          tag: pmToAssign.label,
          note: note
            ? {
                id: uuidv4(),
                noteText: note
              }
            : null
        })
      )
    }

    if (statusKey !== 'new-lead_rc') {
      trackEvent('Patient status update - status updated', {
        statusKey,
        cycleInterval,
        cycleIntervalPeriodType,
        analyticsSource: 'Resolve patient card'
      })
      dispatch(
        Actions.updateTreatmentStatus({
          statusKey: statusKey,
          treatmentId: lead.patient.treatments.items[0].id,
          cycleInterval,
          cycleIntervalPeriodType
        })
      )
      dispatch(Actions.resolveLead(lead.id))
    }

    handleClose()
  }, [
    practiceMembersSelectOptions,
    status,
    handleClose,
    assignee,
    dispatch,
    lead,
    note,
    cycleInterval,
    cycleIntervalPeriodType
  ])

  useEffect(() => {
    if (newLeadStatus) {
      setStatus(newLeadStatus)
    }
  }, [newLeadStatus])

  useEffect(() => {
    setAssignee(assigneeTag?.assigneeId || 'Unassigned')
  }, [assigneeTag])

  useEffect(() => {
    if (scanFrequencyDefaults) {
      setCycleInterval(scanFrequencyDefaults.periodLength)
      setCycleIntervalPeriodType(scanFrequencyDefaults.periodType)
    }
  }, [scanFrequencyDefaults, status])

  return (
    <BaseModal
      open={isOpen}
      onSecondaryBtnClick={handleClose}
      handleClose={handleClose}
      title={t('pages.rcDashboard.dialogs.resolveLead.title')}
      variant={isMobile() ? 'fullscreen' : null}
      titleClassName={classes.titleContainer}
      className={classes.root}
      secondaryLabel={t('general.cancel')}
      onPrimaryBtnClick={handleSubmit}
      isPrimaryDisabled={status?.key === 'new-lead_rc'}
      primaryLabel={t('general.confirm')}
      primaryButtonId="resolve-patient-confirm-set-assignee"
      secondaryButtonId="resolve-patient-cancel-set-assignee"
    >
      <Grid container direction="column" spacing={2} className={classes.container}>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <DazzedParagraph14>{t('pages.rcDashboard.dialogs.resolveLead.note')}</DazzedParagraph14>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <DazzedParagraph14 bold>
                    {t('pages.rcDashboard.dialogs.resolveLead.changeStatusLabel')}
                  </DazzedParagraph14>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <StatusPicker
                id={lead?.id}
                program={'rc'}
                variant="noBorder"
                statusesToDisplay={['active-treatment', 'observation', 'archived', 'new-lead']}
                onSelect={newStatus => handleStatusChange(newStatus)}
                selectedStatus={status || newLeadStatus}
                className={classes.statusPicker}
              />
            </Grid>
            {status?.key !== 'new-lead_rc' &&
              (status?.type !== 'archived' ? (
                <Grid item>
                  <Grid container>
                    <DazzedParagraph14 className={classes.subtitle} textAlign={isMobile() ? 'start' : 'center'}>
                      {t('statuses.updateStatusModal.subtitle')}
                    </DazzedParagraph14>
                    <FrequencyPicker
                      radioGroupClassName={classes.radioGroup}
                      periodType={cycleIntervalPeriodType}
                      defaultPeriodType={scanFrequencyDefaults?.periodType || cycleIntervalPeriodType}
                      setPeriodType={setCycleIntervalPeriodType}
                      options={frequencyOptions}
                      value={cycleInterval}
                      setValue={setCycleInterval}
                      periodTypeOptions={periodTypeOptions}
                      size="small"
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <ArchivedStatusMessage className={classes.archivedMessage} status={status} />
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item>
          <div className={classes.divider}></div>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item style={{ paddingTop: 8 }}>
              <Assignee2 color={'var(--text-color-27)'} />
            </Grid>
            <Grid item>
              <DazzedParagraph14 bold>
                {t('pages.rcDashboard.dialogs.resolveLead.assignPatientLabel')}
              </DazzedParagraph14>
            </Grid>
            <Grid item>
              <DazzedParagraph14>{t('pages.rcDashboard.dialogs.resolveLead.optional')}</DazzedParagraph14>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AssignPatientForm
            assignee={assignee}
            practiceMembersSelectOptions={practiceMembersSelectOptions}
            onChangeAssignee={({ value }) => setAssignee(value)}
            onChangeNote={value => setNote(value)}
            assignPatientCentered={false}
          />
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default RCResolvePatientPopup
