import React, { useEffect, useMemo } from 'react'
import { getCloudFrontUrl } from 'utils/storageUtils'
import { getObjectUrl } from 'utils/mediaUtils'
import { Grid } from '@material-ui/core'
import AutochartScanTile from './AutochartScanTile'

const AutochartScanTilesList = ({ grinScanVideo, scanSummaryData, selectedOption, onSelect = () => {} }) => {
  const options = useMemo(() => {
    if (!scanSummaryData) {
      return []
    }

    const scanSummaryImages = scanSummaryData.normalImages || []

    return [
      {
        type: 'video',
        src: `${getCloudFrontUrl()}/${grinScanVideo.key}`
      },
      ...Object.keys(scanSummaryImages).map(pose => ({
        type: 'img',
        src: getObjectUrl(scanSummaryImages[pose]),
        pose
      }))
    ]
  }, [scanSummaryData, grinScanVideo])

  useEffect(() => {
    if (options.length > 0) {
      onSelect(options[0])
    }
  }, [onSelect, options])

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      {options.map((option, i) => (
        <Grid item key={`autochart-scan-tile-${i}`} xs={2}>
          <AutochartScanTile
            mediaType={option.type}
            src={option.src}
            onClick={() => onSelect(option)}
            isSelected={option.src === selectedOption?.src}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default AutochartScanTilesList
