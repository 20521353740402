import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = () =>
  makeStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%'
    }
  })()

const SelectedTemplate = ({ selectedTemplate, onButtonClick = () => {}, renderSelectedTemplate = () => {} }) => {
  const classes = useStyles()

  return <div className={classes.container}>{renderSelectedTemplate(selectedTemplate, onButtonClick)}</div>
}

export default SelectedTemplate
