import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase } from '@material-ui/core'
import BackButton from 'components/common/icons/timelineV2/mini-timeline/BackButton'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  timelineBackButton: {
    padding: '13px 0',
    width: '100%',
    textAlign: 'center'
  },
  timelineBackButtonPlaceholder: {
    width: '100%',
    height: 38
  }
}))

const TimelineBackButton = ({ visible = true, onClick = () => {} }) => {
  const classes = useStyles()

  const handleClick = useCallback(() => {
    trackEvent('Timeline - back button clicked')
    onClick()
  }, [onClick])

  return visible ? (
    <ButtonBase className={classes.timelineBackButton} onClick={handleClick}>
      <BackButton />
    </ButtonBase>
  ) : (
    <div className={classes.timelineBackButtonPlaceholder} />
  )
}

export default TimelineBackButton
