import * as React from 'react'

function NotifyPractice(props) {
  return (
    <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 20.5394C0.5 19.025 1.4025 17.6564 2.79445 17.0598L5.69502 15.8167C8.44409 14.6385 11.5559 14.6385 14.305 15.8167L17.2055 17.0598C18.5975 17.6564 19.5 19.025 19.5 20.5394V23.4277C19.5 23.9406 19.0843 24.3563 18.5714 24.3563H1.42857C0.915736 24.3563 0.5 23.9406 0.5 23.4277V20.5394Z"
        stroke="#8A8A8A"
      />
      <circle cx="9.99993" cy="7.71429" r="5.21429" stroke="#8A8A8A" />
      <path d="M7.14286 14.8574L10 24.8574L5 19.1431L7.14286 14.8574Z" fill="#8A8A8A" />
      <path d="M12.8571 14.8574L10 24.8574L15 19.1431L12.8571 14.8574Z" fill="#8A8A8A" />
      <path
        d="M21.2143 1.17889C21.1027 0.565046 21.5743 0 22.1982 0H25.8018C26.4257 0 26.8973 0.565045 26.7857 1.17889L25.1493 10.1789C25.0628 10.6544 24.6487 11 24.1654 11H23.8346C23.3513 11 22.9372 10.6544 22.8507 10.1789L21.2143 1.17889Z"
        fill="#8A8A8A"
      />
      <circle cx="24" cy="15" r="2" fill="#8A8A8A" />
    </svg>
  )
}

export default NotifyPractice
