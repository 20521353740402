import Actions from '../actions'

const initialState = {
  productPlans: {
    data: {},
    isLoading: false
  },
  isLoadingScopeProducts: false,
  shippingFees: {},
  shippingFee: 0,
  scope: {
    data: {}
  },
  scopeMini: {
    data: {}
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_GRIN_SCOPE_PRODUCTS:
      return {
        ...state,
        isLoadingScopeProducts: true
      }
    case Actions.FETCH_GRIN_SCOPE_PRODUCTS_RECEIVED:
      return {
        ...state,
        isLoadingScopeProducts: false,
        scope: {
          ...state.scope,
          data: action.payload.scope
        },
        scopeMini: {
          ...state.scopeMini,
          data: action.payload.scopeMini
        },
        shippingFee: action.payload?.shippingFee,
        shippingFees: action.payload?.shipping
      }
    case Actions.FETCH_GRIN_SCOPE_PRODUCTS_FAILED:
      return {
        ...state,
        isLoadingScopeProducts: false
      }
    case Actions.SIGNOUT_RECEIVED:
      return initialState
    default:
      return state
  }
}
