import * as React from 'react'

const TimelineArrowHorizontal = ({ color, className }) => {
  return (
    <svg
      className={className}
      width="1189"
      height="16"
      viewBox="0 0 1189 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.292847 7.29289C-0.0976562 7.68342 -0.0976562 8.31658 0.292847 8.70711L6.65686 15.0711C7.04736 15.4616 7.68054 15.4616 8.07104 15.0711C8.46155 14.6805 8.46155 14.0474 8.07104 13.6569L2.41418 8L8.07104 2.34315C8.46155 1.95262 8.46155 1.31946 8.07104 0.928932C7.68054 0.538408 7.04736 0.538408 6.65686 0.928932L0.292847 7.29289ZM1188.71 8.70711C1189.1 8.31658 1189.1 7.68342 1188.71 7.29289L1182.34 0.928932C1181.95 0.538408 1181.32 0.538408 1180.93 0.928932C1180.54 1.31946 1180.54 1.95262 1180.93 2.34315L1186.59 8L1180.93 13.6569C1180.54 14.0474 1180.54 14.6805 1180.93 15.0711C1181.32 15.4616 1181.95 15.4616 1182.34 15.0711L1188.71 8.70711ZM1 9L1188 9V7L1 7V9Z"
        fill={color}
      />
    </svg>
  )
}

export default TimelineArrowHorizontal
