import React, { useState, useCallback, useEffect } from 'react'
import { makeStyles, CircularProgress } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import Actions from 'actions'
import NoteInput from '../../NotesTab/NoteInput'
import Notes from '../../NotesTab/Notes'
import { CONTENT_HEIGHT } from 'consts/patientCard'
import usePatientTags from 'hooks/usePatientTags'
import { isMobile } from 'utils/mobileUtils'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const useStyles = makeStyles({
  container: {
    height: isMobile() ? '100%' : CONTENT_HEIGHT,
    padding: isMobile() ? '0px 5px 0px 10px' : '30px 50px 5% 5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  notesContainer: {
    height: '100%',
    maxHeight: '100%',
    marginBottom: 20,
    overflowY: 'auto',
    paddingRight: '5%',
    paddingBottom: 5
  },
  loaderContainer: {
    height: CONTENT_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const NotesTab = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { patientId } = useParams()

  const {
    notes: { data, isLoading, isCreating, isDeleting },
    patient
  } = useSelector(state => state.patientsReducer.patientCard)
  const { isLoadingPatient } = useSelector(state => state.patientsReducer)
  const doctor = useSelector(state => state.profileReducer.doctor)
  const { assigneeTag } = usePatientTags({ patient })
  const [note, setNote] = useState('')

  const handleAddNote = useCallback(() => {
    if (!!note.trim()) {
      dispatch(Actions.createPatientNote(note.trim()))
    }
  }, [dispatch, note])

  const handleDeleteNote = useCallback(
    note => {
      if (assigneeTag?.noteId === note.id) {
        assigneeTag.setUnreadIndicator()
      }
      dispatch(Actions.deletePatientNote(note))
    },
    [dispatch, assigneeTag]
  )

  const handleEditNote = useCallback(note => dispatch(Actions.editPatientNote(note)), [dispatch])

  useEffect(() => {
    if (!isCreating) {
      setNote('')
    }
  }, [isCreating, setNote])

  useEffect(() => {
    if (!patient) {
      dispatch(Actions.requestPatient({ patientId }))
    }
  }, [dispatch, patient, patientId])

  useEffect(() => {
    if (!data && patient?.user?.id) {
      dispatch(Actions.requestPatientNotes(patient.user.id))
    }
  }, [data, dispatch, patient, patientId])

  return (
    <>
      {isLoading || isLoadingPatient ? (
        <div className={classes.loaderContainer}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.notesContainer}>
            <Notes
              list={data || []}
              handleDelete={handleDeleteNote}
              handleEdit={handleEditNote}
              isDeleting={isDeleting}
              isCreating={isCreating}
              doctorUserId={doctor?.user?.id}
            />
          </div>
          <NoteInput note={note} setNote={setNote} isLoading={isCreating} onAddNote={handleAddNote} />
        </div>
      )}
    </>
  )
}

export default NotesTab
