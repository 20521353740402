import React from 'react'
import { Tab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../../styles/theme'

const useStyles = ({ marginRight, fontSize }) =>
  makeStyles({
    root: {
      fontSize: fontSize ? fontSize : '0.875rem',
      textTransform: 'none',
      minWidth: 72,
      fontWeight: 500,
      marginRight: marginRight ? marginRight : theme.spacing(4),
      fontFamily: 'Dazzed',
      '&:hover': {
        color: 'var(--text-color-3)',
        opacity: 1
      },
      '&$selected': {
        color: 'var(--text-color-3)',
        fontWeight: 600
      },
      '&:focus': {
        color: 'var(--text-color-3)'
      },
      '& .MuiTab-wrapper svg': {
        fill: 'var(--text-color-6)',
        opacity: 0.7
      },
      '&:hover .MuiTab-wrapper svg': {
        fill: 'var(--text-color-3)',
        opacity: 1
      },
      '&$selected .MuiTab-wrapper svg': {
        fill: 'var(--text-color-3)'
      },
      '&:focus .MuiTab-wrapper svg': {
        fill: 'var(--text-color-3)'
      }
    },
    wrapper: {
      flexDirection: 'row'
    },
    labelIcon: {
      minHeight: 45,
      '& .MuiTab-wrapper > *:first-child': {
        marginBottom: 0,
        marginRight: 5
      }
    },
    selected: {}
  })({ marginRight })

const GrinTab = ({ marginRight, fontSize, ...props }) => {
  const classes = useStyles({ marginRight, fontSize })

  return <Tab disableRipple {...props} classes={classes} />
}

export default GrinTab
