import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.main,
    fontFamily: 'Dazzed',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px'
  }
}))

const DazzedHeading16 = ({ className, regular, children }) => {
  const classes = useStyles()

  return (
    <div className={[classes.heading, className].join(' ')} style={regular ? { fontWeight: 400 } : {}}>
      {children}
    </div>
  )
}

export default DazzedHeading16
