import * as React from 'react'

export default () => (
  <svg width="171" height="154" viewBox="0 0 171 154" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M79.2909 23C92.145 23 96.6447 31.9421 109.582 31.9421C122.519 31.9421 126.795 23 139.873 23C157.715 23 170.164 36.413 170.164 57.2031C170.164 81.1229 155.857 154 134.843 154C115.171 154 121.877 116.891 109.582 116.891C97.2867 116.891 103.1 154 84.7692 154C63.9778 154 49 90.7355 49 57.2031C49 36.413 61.4487 23 79.2909 23Z"
      fill="#D8E4F0"
    />
    <circle cx="66" cy="44" r="44" fill="#F5FAFF" />
    <circle cx="66.0494" cy="43.9996" r="32.0494" fill="#F5FAFF" stroke="#D8E4F0" strokeWidth="10" />
    <path
      d="M33.5693 65.376C36.5734 62.8089 41.0465 62.984 43.8407 65.7782C46.6348 68.5723 46.81 73.0455 44.2429 76.0495L22.7026 101.256C18.8496 105.765 11.9719 106.034 7.77823 101.841C3.58453 97.6469 3.85383 90.7692 8.3626 86.9163L33.5693 65.376Z"
      fill="#D8E4F0"
    />
  </svg>
)
