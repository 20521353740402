import { pullAt } from 'lodash'

export const removeAt = (array, index) => {
  let updatedArray = [...array]
  updatedArray.splice(index, 1)
  return updatedArray
}

export const removeWhere = (array, predicate) => {
  const index = array.findIndex(curr => predicate(curr))
  if (index === -1) {
    return [...array]
  }

  return removeAt(array, index)
}

export const pullFirst = (array, predicate) => {
  const itemIndex = array.findIndex(predicate)
  if (itemIndex === -1) {
    return null
  }
  const pulled = pullAt(array, [itemIndex])
  return pulled[0]
}

export const replaceItem = (array, item, mergeOldProperties = false, idFieldName = 'id', condition = () => false) => {
  const index = array.findIndex(curr => curr[idFieldName] === item[idFieldName] || condition(curr))
  if (index === -1) {
    return array
  }

  let updatedArray = [...array]
  const itemToPut = !mergeOldProperties ? item : { ...array[index], ...item }
  updatedArray[index] = itemToPut

  return updatedArray
}

export const upsertItem = ({
  array,
  item,
  mergeOldProperties = false,
  idFieldName = 'id',
  condition = () => false
}) => {
  const index = array.findIndex(curr => curr[idFieldName] === item[idFieldName] || condition(curr))

  if (index === -1) {
    return [item, ...array]
  }

  return replaceItem(array, item, mergeOldProperties, idFieldName, condition)
}

export const updateWhere = (array, predicate, action) => {
  const newArray = [...array]
  const index = array.findIndex(predicate)
  if (index === -1) {
    return newArray
  }
  const newItem = { ...array[index] }
  action(newItem)
  newArray[index] = newItem
  return newArray
}

export const filterWhile = (array, filterPredicate, whilePredicate) => {
  const result = []
  let i = 0
  while (whilePredicate(result) && i < array.length) {
    if (filterPredicate(array[i])) {
      result.push(array[i])
    }
    i++
  }
  return result
}

export const countWhile = (array, predicate, itemMapper = item => item) => {
  const result = array.reduce(
    (accumulator, currentItem) => {
      let { count, accumulation, stopped } = accumulator
      const currentItemValue = itemMapper(currentItem)
      const nextAccumulation = accumulation + currentItemValue
      if (!stopped && predicate(nextAccumulation)) {
        count++
        accumulation = nextAccumulation
      } else {
        stopped = true
      }
      return { count, accumulation, stopped }
    },
    { count: 0, accumulation: 0, stopped: false }
  )

  return result.count
}

export const rangeArray = range => Array.from(Array(range).keys())
export const rangeArrayReversed = range => rangeArray(range).reverse()
export const rangeWithStep = ({ end, step }) => Array.from({ length: end / step + 1 }, (_, index) => index * step)

export const updateAllWhere = (array = [], predicate, mapper) => {
  const newArray = [...array]

  array.forEach((element, i) => {
    if (predicate(element)) {
      newArray[i] = mapper(element)
    }
  })

  return newArray
}

export const getObjectByIndexCyclic = (array, index) => {
  if (array.length === 0) {
    return null
  }

  const cyclicIndex = ((index % array.length) + array.length) % array.length

  return array[cyclicIndex]
}

/**
 * This function gets array of numbers (or numbers as string) and returns ranges within it.
 *
 * e.g: Input: [1,2,3,6,7,8] ===> Output: [[1,3],[6,8]]
 */
export const createNumberRanges = stringifiedNumbers => {
  const numbers = stringifiedNumbers.map(n => Number(n))

  if (numbers.length === 0) return []

  const sortedArr = numbers.sort((a, b) => a - b)
  const ranges = []

  let start = sortedArr[0]
  let end = start

  for (let i = 1; i < sortedArr.length; i++) {
    const current = sortedArr[i]

    if (current === end + 1) {
      end = current
    } else {
      ranges.push([start, end])
      start = current
      end = current
    }
  }

  ranges.push([start, end])

  return ranges
}
