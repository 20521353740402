import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { IconButton, Menu, MenuItem, TextField, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ConfirmationModal from 'components/common/ConfirmationModal'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import Actions from 'actions'
import TemplatesList from '../Patients/Chat/Templates/TemplatesList'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedHeading24 from 'components/common/text/DazzedHeading24'
import trashIcon from 'resources/icons/trash.png'
import duplicateIcon from 'resources/icons/duplicate.png'
import { TEMPLATES_TYPES } from 'consts/templatesConsts'
import DazzedHeading13 from 'components/common/text/DazzedHeading13'
import { usePrevious } from 'hooks/usePrevious'
import SavedRepliesList from '../Patients/Chat/SavedReplies/SavedRepliesList'
import { scrollBar } from 'styles/theme'
import GrinCheckbox from 'components/common/GrinCheckbox'
import useRolePermissions from 'hooks/useRolePermissions'

const useStyles = makeStyles(theme => ({
  container: {
    flexWrap: 'nowrap',
    height: '400px',
    width: '100%',
    maxWidth: 625,
    marginBottom: 100,
    paddingRight: 20
  },
  listContainer: {
    height: '100%',
    overflowY: 'auto',
    ...scrollBar
  },
  heading: {
    marginBottom: 30
  },
  emptyStateContainer: {
    padding: '12px 10px'
  },
  unsetText: {
    textAlign: 'center',
    color: 'var(--text-color-2)'
  },
  editorContainer: {
    marginLeft: 30,
    width: '100%',
    minHeight: 350
  },
  greyText: {
    color: 'rgba(0, 0, 0, 0.5)'
  },
  titleInput: {
    width: '95%'
  },
  titleInputRoot: {
    fontFamily: 'Dazzed',
    fontSize: 24,
    fontWeight: 600
  },
  messageWrapper: {
    borderRadius: 10,
    border: '1px solid #ABB0B8',
    marginTop: 20,
    height: 260,
    overflowY: 'hidden'
  },
  messageInput: {
    boxSizing: 'border-box',
    overflowY: 'auto',
    border: 0,
    borderRadius: 10,
    width: '100%',
    height: 258,
    fontFamily: 'Dazzed',
    fontSize: 14,
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.2px',
    textAlign: 'left',
    padding: '15px 26px 32px 20px',
    resize: 'none',
    whiteSpace: 'pre-wrap',
    '&:focus': {
      outline: 'none'
    },
    ...scrollBar
  },
  systemTemplateMessage: {
    background: 'rgba(196, 196, 196, 0.1)',
    '&::-webkit-scrollbar': {
      background: 'rgba(196, 196, 196, 0.1)'
    }
  },
  templatesSection: {
    width: '30%',
    position: 'relative'
  },
  openMenuButton: {
    padding: 0,
    paddingLeft: 1
  },
  openMenuIcon: {
    color: '#001335'
  },
  optionsMenu: {
    width: 193,
    alignItems: 'center'
  },
  optionsMenuItem: {
    justifyContent: 'flex-start',
    paddingLeft: 11,
    paddingRight: 16
  },
  optionsMenuItemIcon: {
    height: '30px',
    width: '30px',
    marginRight: 7
  },
  optionsMenuItemTitle: {
    fontWeight: 'bold',
    color: '#000000'
  },
  duplicateSection: {
    marginTop: 30
  },
  duplicateTextButton: {
    color: 'blue',
    fontWeight: 500,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontFamily: 'Dazzed',
    fontSize: 14,
    textTransform: 'none',
    paddingRight: 3,
    paddingLeft: 3,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  checkbox: {
    marginLeft: -9,
    marginTop: 5,
    marginBottom: 5
  }
}))

const SavedReplies = props => {
  const classes = useStyles()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { permissions } = useRolePermissions()

  const isLoading = useSelector(state => state.profileReducer.isLoading)
  const doctorId = useSelector(state => state.profileReducer.doctor.id)
  const selectedTemplate = useSelector(state => state.profileReducer.selectedTemplate)
  const setSelectedTemplate = useCallback(template => dispatch(Actions.updateSelectedTemplate(template)), [dispatch])
  const previousSelectedTemplate = usePrevious(selectedTemplate)

  useEffect(() => {
    if (selectedTemplate?.id !== previousSelectedTemplate?.id) {
      setText(selectedTemplate.text)
      setTitle(selectedTemplate.title)
      setIsAvailableForPracticeMembers(!selectedTemplate.isPrivate)
    }
  }, [
    previousSelectedTemplate,
    selectedTemplate.id,
    selectedTemplate.text,
    selectedTemplate.title,
    selectedTemplate.isPrivate
  ])

  const [title, setTitle] = useState('')
  const [text, setText] = useState('')
  const [isAvailableForPracticeMembers, setIsAvailableForPracticeMembers] = useState(false)

  const [templateToUpdate, setTemplateToUpdate] = useState(null)
  const [templateToDelete, setTemplateToDelete] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const handleTemplateSelection = useCallback(
    template => {
      setSelectedTemplate(template)
      setText(template.text)
      setTitle(template.title)
      setIsAvailableForPracticeMembers(!template.isPrivate)
    },

    [setText, setTitle, setSelectedTemplate]
  )

  const resetFields = useCallback(() => {
    setTitle('')
    setText('')
    setIsAvailableForPracticeMembers(true)
    setSelectedTemplate({ title: '', text: '' })
  }, [setTitle, setText, setSelectedTemplate])

  const handleDelete = useCallback(() => {
    dispatch(Actions.deleteMessageTemplate(templateToDelete))
    if (selectedTemplate.id === templateToDelete.id) {
      resetFields()
    }
    setTemplateToDelete(null)
  }, [dispatch, resetFields, selectedTemplate.id, templateToDelete])

  const handleDuplicate = useCallback(() => {
    dispatch(
      Actions.duplicateMessageTemplate({
        title: selectedTemplate.title,
        text: selectedTemplate.text,
        isPrivate: selectedTemplate.isPrivate
      })
    )
    if (anchorEl) {
      setAnchorEl(null)
    }
  }, [dispatch, selectedTemplate, anchorEl])

  const handleUpdate = useCallback(() => {
    dispatch(Actions.updateMessageTemplate(templateToUpdate))
    setTemplateToUpdate(null)
    setSelectedTemplate(templateToUpdate)
  }, [dispatch, templateToUpdate, setSelectedTemplate])

  const shouldDisableButtons = useMemo(
    () =>
      !selectedTemplate ||
      (selectedTemplate.title === title &&
        selectedTemplate.text === text &&
        !!selectedTemplate.isPrivate === !isAvailableForPracticeMembers) ||
      !title ||
      !text,
    [text, selectedTemplate, title, isAvailableForPracticeMembers]
  )

  const isCheckboxDisabled = useMemo(
    () => doctorId !== (selectedTemplate.creatorDoctorId || selectedTemplate.doctorId),
    [doctorId, selectedTemplate.creatorDoctorId, selectedTemplate.doctorId]
  )

  const handlePrimary = useCallback(() => {
    const updatedFields = { text, title, isPrivate: !isAvailableForPracticeMembers }
    if (selectedTemplate.id) {
      const updatedTemplate = { ...selectedTemplate, ...updatedFields }
      setTemplateToUpdate(updatedTemplate)
    } else {
      dispatch(Actions.createMessageTemplate(updatedFields))
      resetFields()
    }
  }, [dispatch, resetFields, selectedTemplate, text, title, isAvailableForPracticeMembers])

  const handleSecondary = useCallback(() => {
    setTitle(selectedTemplate.title)
    setText(selectedTemplate.text)
    setIsAvailableForPracticeMembers(!selectedTemplate.isPrivate)
  }, [selectedTemplate.text, selectedTemplate.title, selectedTemplate.isPrivate])

  return (
    <>
      <DazzedHeading18>{t('pages.accountSettings.savedReplies.title')}</DazzedHeading18>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container direction="row" className={classes.container} alignItems="stretch">
          <Grid item xs={4}>
            <TemplatesList
              selected={selectedTemplate}
              setSelected={handleTemplateSelection}
              withActions
              onDeleteTemplate={template => setTemplateToDelete(template)}
              searchPlaceholder={t('pages.patients.selectedPatient.chat.templates.searchPlaceholder')}
              renderList={props => <SavedRepliesList {...props} />}
              isSearchable={true}
              autoFocus={props.autoFocus}
            />
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            wrap="nowrap"
            className={classes.editorContainer}
          >
            <Grid container direction="column">
              {!selectedTemplate?.id ? (
                <div className={classes.emptyStateContainer}>
                  <DazzedParagraph14 className={classes.unsetText}>
                    {t('pages.accountSettings.savedReplies.unset')}
                  </DazzedParagraph14>
                </div>
              ) : (
                <>
                  <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
                    {selectedTemplate.type === TEMPLATES_TYPES.SYSTEM ? (
                      <DazzedHeading24 className={classes.greyText}>{title}</DazzedHeading24>
                    ) : (
                      <TextField
                        placeholder={t('pages.accountSettings.savedReplies.titlePlaceholder')}
                        className={classes.titleInput}
                        InputProps={{
                          classes: { input: classes.titleInputRoot },
                          value: title
                        }}
                        onChange={({ target }) => setTitle(target.value)}
                      />
                    )}
                    <IconButton
                      onClick={event => {
                        setAnchorEl(event.currentTarget)
                      }}
                      className={classes.openMenuButton}
                    >
                      <MoreVertIcon fontSize="small" className={classes.openMenuIcon} />
                    </IconButton>
                    <Menu
                      className={classes.optionsMenu}
                      transformOrigin={{ vertical: -19, horizontal: -30 }}
                      anchorEl={anchorEl}
                      onClose={() => {
                        setAnchorEl(null)
                      }}
                      open={!!anchorEl}
                    >
                      <MenuItem onClick={handleDuplicate} className={classes.optionsMenuItem}>
                        <img alt="duplicate" className={classes.optionsMenuItemIcon} src={duplicateIcon} />
                        <DazzedHeading13 className={classes.optionsMenuItemTitle}>
                          {t('pages.accountSettings.savedReplies.menuItemCopyButton')}
                        </DazzedHeading13>
                      </MenuItem>
                      <MenuItem
                        disabled={selectedTemplate?.type === TEMPLATES_TYPES.SYSTEM}
                        onClick={() => {
                          setAnchorEl(null)
                          setTemplateToDelete(selectedTemplate)
                        }}
                        className={classes.optionsMenuItem}
                      >
                        <img alt="delete" className={classes.optionsMenuItemIcon} src={trashIcon} />
                        <DazzedHeading13 className={classes.optionsMenuItemTitle}>
                          {t('pages.accountSettings.savedReplies.menuItemDeleteButton')}
                        </DazzedHeading13>
                      </MenuItem>
                    </Menu>
                  </Grid>
                  <div className={classes.messageWrapper}>
                    {selectedTemplate.type === TEMPLATES_TYPES.SYSTEM ? (
                      <div
                        className={classNames(classes.messageInput, classes.greyText, classes.systemTemplateMessage)}
                      >
                        {text}
                      </div>
                    ) : (
                      <textarea
                        className={classes.messageInput}
                        type="text"
                        name="message-content"
                        value={text}
                        column={15}
                        rows={15}
                        placeholder={t('pages.accountSettings.savedReplies.messagePlaceholder')}
                        autoComplete="off"
                        onChange={({ target }) => {
                          setText(target.value)
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </Grid>
            {selectedTemplate.type !== TEMPLATES_TYPES.SYSTEM && selectedTemplate.id && (
              <Grid item>
                {permissions.makeTemplatesPrivate && (
                  <GrinCheckbox
                    caption={t('dialogs.newMessageTemplate.checkbox')}
                    wrapperClassName={classes.checkbox}
                    checked={isAvailableForPracticeMembers}
                    disabled={isCheckboxDisabled}
                    onChange={setIsAvailableForPracticeMembers}
                  />
                )}
                <Grid container direction="row" justifyContent="flex-end">
                  <Grid item style={{ marginRight: 12 }}>
                    <SecondaryButton
                      disabled={shouldDisableButtons}
                      onClick={handleSecondary}
                      fontWeight={400}
                      fontSize={12}
                      small={true}
                      label={t('pages.accountSettings.savedReplies.secondaryButtonText')}
                    />
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      isLoading={isLoading}
                      disabled={shouldDisableButtons}
                      onClick={handlePrimary}
                      fontWeight={400}
                      fontSize={12}
                      small={true}
                      label={t('pages.accountSettings.savedReplies.saveReplyButtonText')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {selectedTemplate.type === TEMPLATES_TYPES.SYSTEM && (
              <Grid item className={classes.duplicateSection}>
                <DazzedParagraph14>
                  {t('pages.accountSettings.savedReplies.duplicateFooterTextPrefix')}
                </DazzedParagraph14>
                <DazzedParagraph14>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span onClick={handleDuplicate} className={classes.duplicateTextButton}>
                    {t('pages.accountSettings.savedReplies.duplicateFooterLinkText')}
                  </span>
                  &nbsp;{t('pages.accountSettings.savedReplies.duplicateFooterTextSuffix')}
                </DazzedParagraph14>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <ConfirmationModal
        isOpen={templateToUpdate !== null}
        onClose={() => setTemplateToUpdate(null)}
        onConfirm={handleUpdate}
        title={t('pages.accountSettings.savedReplies.confirmUpdateModalTitle')}
        acceptButtonText={t('pages.accountSettings.savedReplies.saveReplyButtonText')}
      />
      <ConfirmationModal
        isOpen={templateToDelete !== null}
        onClose={() => setTemplateToDelete(null)}
        onConfirm={handleDelete}
        title={t('pages.accountSettings.savedReplies.confirmDeleteModalTitle')}
        text={t('pages.accountSettings.savedReplies.confirmDeleteModalText')}
        additionalText={t('pages.accountSettings.savedReplies.confirmDeleteModalAddText')}
        acceptButtonText={t('pages.accountSettings.savedReplies.deleteReplyButtonText')}
      />
    </>
  )
}

export default SavedReplies
