// Action Types
const QUICK_SIGN_UP_FORM_CHANGED = 'QUICK_SIGN_UP_FORM_CHANGED'
const REQUEST_QUICK_DOCTOR_SIGN_UP = 'REQUEST_QUICK_DOCTOR_SIGN_UP'
const QUICK_DOCTOR_SIGN_UP_RECEIVED = 'QUICK_DOCTOR_SIGN_UP_RECEIVED'
const QUICK_DOCTOR_SIGN_UP_FAILED = 'QUICK_DOCTOR_SIGN_UP_FAILED'
const VERIFY_DOCTOR_EMAIL = 'VERIFY_DOCTOR_EMAIL'
const VERIFY_DOCTOR_EMAIL_RECEIVED = 'VERIFY_DOCTOR_EMAIL_RECEIVED'
const VERIFY_DOCTOR_EMAIL_FAILED = 'VERIFY_DOCTOR_EMAIL_FAILED'
const UPDATE_QUICK_SIGNUP_ERRORS = 'UPDATE_QUICK_SIGNUP_ERRORS'
const CHANGE_QUICK_SIGN_UP_BILLING_FORM = 'CHANGE_QUICK_SIGN_UP_BILLING_FORM'
const FINISH_QUICK_SIGN_UP = 'FINISH_QUICK_SIGN_UP'

// Action Creators
const quickSignUpFormChanged = payload => ({
  type: QUICK_SIGN_UP_FORM_CHANGED,
  payload
})

const requestQuickDoctorSignUp = () => ({
  type: REQUEST_QUICK_DOCTOR_SIGN_UP
})

const quickDoctorSignUpReceived = payload => ({
  type: QUICK_DOCTOR_SIGN_UP_RECEIVED,
  payload
})

const quickDoctorSignUpFailed = payload => ({
  type: QUICK_DOCTOR_SIGN_UP_FAILED,
  payload
})

const verifyDoctorEmail = () => ({
  type: VERIFY_DOCTOR_EMAIL
})

const verifyDoctorEmailReceived = payload => ({
  type: VERIFY_DOCTOR_EMAIL_RECEIVED,
  payload
})

const verifyDoctorEmailFailed = payload => ({
  type: VERIFY_DOCTOR_EMAIL_FAILED,
  payload
})

const updateQuickSignUpErrors = payload => ({
  type: UPDATE_QUICK_SIGNUP_ERRORS,
  payload
})

const changeQuickSignUpBillingForm = payload => ({
  type: CHANGE_QUICK_SIGN_UP_BILLING_FORM,
  payload
})
const finishQuickSignup = payload => ({
  type: FINISH_QUICK_SIGN_UP,
  payload
})

export default {
  QUICK_SIGN_UP_FORM_CHANGED,
  REQUEST_QUICK_DOCTOR_SIGN_UP,
  QUICK_DOCTOR_SIGN_UP_RECEIVED,
  QUICK_DOCTOR_SIGN_UP_FAILED,
  VERIFY_DOCTOR_EMAIL,
  VERIFY_DOCTOR_EMAIL_RECEIVED,
  VERIFY_DOCTOR_EMAIL_FAILED,
  UPDATE_QUICK_SIGNUP_ERRORS,
  CHANGE_QUICK_SIGN_UP_BILLING_FORM,
  FINISH_QUICK_SIGN_UP,
  finishQuickSignup,
  quickSignUpFormChanged,
  requestQuickDoctorSignUp,
  quickDoctorSignUpReceived,
  quickDoctorSignUpFailed,
  verifyDoctorEmail,
  verifyDoctorEmailReceived,
  verifyDoctorEmailFailed,
  updateQuickSignUpErrors,
  changeQuickSignUpBillingForm
}
