import { removeWhere, updateWhere } from 'utils/arrayUtils'
import Actions from '../actions'
import { AsyncStatus } from 'consts'

const initialState = {
  isLoading: false,
  details: {
    practiceName: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    state: '',
    country: '',
    phone: '',
    additionalPhone: '',
    countryCode: '',
    practicePhone: '',
    practiceCountryCode: '',
    email: '',
    calendarId: '',
    homepageUrl: '',
    logo: {},
    practiceTimezone: null
  },
  accountOwner: {},
  billing: {
    grinPlanKey: null,
    grinPlan: null,
    stripeCustomerId: null,
    planOverrides: '{}'
  },
  services: {
    rc: {
      flagTurnedOn: false,
      available: false
    },
    ortho: {
      flagTurnedOn: false,
      available: false
    },
    doctorLocator: {
      available: false,
      bio: ''
    }
  },
  assignPatient: {
    isModalOpen: false,
    patient: null
  },
  serviceAccount: {
    isLoading: false,
    user: null
  },
  automationRules: {
    data: null,
    isLoading: false,
    editorModal: {
      isSaving: false,
      isOpen: false,
      ruleId: null
    },
    deleteRuleModal: {
      isOpen: false,
      ruleId: null
    },
    toggleRuleModal: {
      isOpen: false,
      ruleId: null,
      isEnabled: null
    }
  },
  savedFiles: {
    status: null,
    files: []
  },
  savedViews: {
    status: null,
    views: [],
    newSavedViewModal: {
      isOpen: false,
      status: null
    }
  },
  hiPracticeGuidelines: {
    data: null,
    isLoading: false,
    isSaving: false
  },
  resolveNeedsAttention: {
    isSaving: false
  },
  aggregatedCustomTags: {
    status: null,
    data: []
  }
}

const removeAccountOwner = doctor => {
  const { accountOwner, accountOwnerId, ...restDoctor } = doctor
  return restDoctor
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.DOCTOR_DETAILS_RECEIVED:
      return {
        ...state,
        ...action.payload.practice,
        accountOwner: removeAccountOwner(action.payload.accountOwner)
      }
    case Actions.GRIN_PLANS_RECEIVED:
      return {
        ...state,
        billing: {
          ...state.billing,
          grinPlan: action.payload[state.billing.grinPlanKey]
        }
      }
    case Actions.UPGRADE_PLAN_RECEIVED:
      return {
        ...state,
        billing: {
          ...state.billing,
          grinPlan: action.payload.grinPlan,
          planOverrides: JSON.stringify(action.payload.planOverrides),
          grinPlanKey: action.payload.grinPlanKey
        }
      }
    case Actions.UPDATE_PRACTICE_DETAILS:
      return {
        ...state,
        isLoading: true
      }
    case Actions.UPDATE_PRACTICE_DETAILS_RECEIVED:
      return {
        ...state,
        isLoading: false,
        details: {
          ...state.details,
          ...action.payload.clinic
        },
        services: {
          ...state.services,
          doctorLocator: {
            ...state.services.doctorLocator,
            bio: action.payload.bio,
            available: action.payload.hasLocatorConsent
          }
        }
      }
    case Actions.UPDATE_PRACTICE_DETAILS_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.UPDATE_DOCTOR_DETAILS_RECEIVED:
      return {
        ...state,
        isLoading: false,
        accountOwner: {
          ...state.accountOwner,
          _version: action.payload.accountOwner._version,
          user: {
            ...state.accountOwner.user,
            appSettings: action.payload.accountOwner?.user?.appSettings || state.accountOwner?.user?.appSettings,
            _version: action.payload.accountOwner.user._version
          }
        },
        details: {
          ...state.details,
          ...action.payload.practice?.details
        },
        billing: {
          ...state.billing,
          ...action.payload.practice?.billing
        },
        services: {
          ...state.services,
          ...action.payload.practice?.services
        }
      }
    case Actions.PRACTICE_FEATURE_FLAGS_RECEIVED:
      return {
        ...state,
        accountOwner: {
          ...state.accountOwner,
          user: {
            ...state.accountOwner.user,
            featureFlags: {
              ...state.accountOwner.user.featureFlags,
              ...action.payload
            }
          }
        },
        services: {
          ...state.services,
          rc: {
            ...state.services.rc,
            flagTurnedOn: JSON.parse(action.payload?.flags || '{}').remoteConsultation
          }
        }
      }
    case Actions.UPDATE_THEME_COLORS:
      return {
        ...state,
        isLoading: true
      }
    case Actions.UPDATE_THEME_COLORS_RECEIVED:
      return {
        ...state,
        isLoading: false,
        accountOwner: {
          ...state.accountOwner,
          user: {
            ...state.accountOwner.user,
            ...action.payload.grinUser
          }
        }
      }
    case Actions.UPDATE_THEME_COLORS_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.INVITE_NEW_PATIENT_RECEIVED:
      return {
        ...state,
        accountOwner: {
          ...state.accountOwner,
          patients: {
            ...state.accountOwner.patients,
            items: [...state.accountOwner.patients.items, { id: action.payload.patientId, _deleted: null }]
          }
        }
      }
    case Actions.SIGNOUT_RECEIVED:
      return initialState
    case Actions.SET_ASSIGN_PATIENT:
      return {
        ...state,
        assignPatient: {
          ...state.assignPatient,
          isModalOpen: !!action.payload?.isModalOpen,
          patient: action.payload?.patient
        }
      }

    case Actions.CREATE_SERVICE_ACCOUNT_REQUESTED:
      return {
        ...state,
        serviceAccount: {
          ...state.serviceAccount,
          isLoading: true
        }
      }
    case Actions.CREATE_SERVICE_ACCOUNT_RECEIVED:
      return {
        ...state,
        serviceAccount: {
          ...state.serviceAccount,
          isLoading: false,
          user: { ...action.payload.user }
        }
      }
    case Actions.CREATE_SERVICE_ACCOUNT_FAILED:
      return {
        ...state,
        serviceAccount: {
          ...state.serviceAccount,
          isLoading: false
        }
      }
    case Actions.FETCH_SERVICE_ACCOUNT_REQUESTED:
      return {
        ...state,
        serviceAccount: {
          ...state.serviceAccount,
          isLoading: true
        }
      }
    case Actions.FETCH_SERVICE_ACCOUNT_RECEIVED:
      return {
        ...state,
        serviceAccount: {
          ...state.serviceAccount,
          isLoading: false,
          user: action.payload
        }
      }
    case Actions.FETCH_SERVICE_ACCOUNT_FAILED:
      return {
        ...state,
        serviceAccount: {
          ...state.serviceAccount,
          isLoading: false
        }
      }
    case Actions.SERVICE_ACCOUNT_SET_PASSWORD_REQUESTED:
      return {
        ...state,
        serviceAccount: {
          ...state.serviceAccount,
          isLoading: true
        }
      }
    case Actions.SERVICE_ACCOUNT_SET_PASSWORD_FAILED:
    case Actions.SERVICE_ACCOUNT_SET_PASSWORD_RECEIVED:
      return {
        ...state,
        serviceAccount: {
          ...state.serviceAccount,
          isLoading: false
        }
      }
    case Actions.DEACTIVATE_SERVICE_ACCOUNT_REQUESTED:
      return {
        ...state,
        serviceAccount: {
          ...state.serviceAccount,
          isLoading: true
        }
      }
    case Actions.DEACTIVATE_SERVICE_ACCOUNT_RECEIVED:
      return {
        ...state,
        serviceAccount: {
          ...state.serviceAccount,
          user: {
            ...state.serviceAccount.user,
            isActive: false
          },
          isLoading: false
        }
      }
    case Actions.DEACTIVATE_SERVICE_ACCOUNT_FAILED:
      return {
        ...state,
        serviceAccount: {
          ...state.serviceAccount,
          isLoading: false
        }
      }
    case Actions.ACTIVATE_SERVICE_ACCOUNT_REQUESTED:
      return {
        ...state,
        serviceAccount: {
          ...state.serviceAccount,
          isLoading: true
        }
      }
    case Actions.ACTIVATE_SERVICE_ACCOUNT_RECEIVED:
      return {
        ...state,
        serviceAccount: {
          ...state.serviceAccount,
          user: {
            ...state.serviceAccount.user,
            isActive: true
          },
          isLoading: false
        }
      }
    case Actions.ACTIVATE_SERVICE_ACCOUNT_FAILED:
      return {
        ...state,
        serviceAccount: {
          ...state.serviceAccount,
          isLoading: false
        }
      }
    case Actions.FETCH_DOCTOR_AUTOMATION_RULES:
      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          isLoading: true,
          data: null
        }
      }
    case Actions.FETCH_DOCTOR_AUTOMATION_RULES_RECEIVED:
      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          isLoading: false,
          data: action.payload
        }
      }
    case Actions.FETCH_DOCTOR_AUTOMATION_RULES_FAILED:
      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          isLoading: false,
          data: []
        }
      }
    case Actions.TOGGLE_AUTOMATION_RULE:
    case Actions.TOGGLE_AUTOMATION_RULE_FAILED:
      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          data: updateWhere(
            state.automationRules.data,
            rule => rule.id === action.payload.id,
            rule => (rule.isEnabled = !rule.isEnabled)
          )
        }
      }
    case Actions.TOGGLE_AUTOMATION_RULE_RECEIVED:
      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          data: updateWhere(
            state.automationRules.data,
            rule => rule.id === action.payload.id,
            rule => (rule._version = action.payload._version)
          )
        }
      }
    case Actions.TOGGLE_RULE_EDITOR_MODAL:
      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          editorModal: {
            ...state.automationRules.editorModal,
            isOpen: action.payload.isOpen,
            ruleId: action.payload?.ruleId
          }
        }
      }
    case Actions.TOGGLE_DELETE_RULE_MODAL:
      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          deleteRuleModal: {
            isOpen: action.payload.isOpen,
            ruleId: action.payload.ruleId
          }
        }
      }
    case Actions.DELETE_AUTOMATION_RULE:
      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          data: removeWhere(state.automationRules.data, rule => rule.id === action.payload.id)
        }
      }

    case Actions.UPDATE_AUTOMATION_RULE:
    case Actions.CREATE_AUTOMATION_RULE:
      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          editorModal: {
            ...state.automationRules.editorModal,
            isSaving: true
          }
        }
      }
    case Actions.UPDATE_AUTOMATION_RULE_FAILED:
    case Actions.CREATE_AUTOMATION_RULE_FAILED:
      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          editorModal: {
            ...state.automationRules.editorModal,
            isSaving: false
          }
        }
      }
    case Actions.UPDATE_AUTOMATION_RULE_RECEIVED:
      const { rule: updatedRule } = action.payload

      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          data: state.automationRules.data.map(currRule => {
            if (currRule.id !== updatedRule.id) {
              return currRule
            }

            return updatedRule
          }),
          editorModal: {
            ...state.automationRules.editorModal,
            isSaving: false
          }
        }
      }
    case Actions.CREATE_AUTOMATION_RULE_RECEIVED:
      const { rule: newRule } = action.payload

      return {
        ...state,
        automationRules: {
          isSaving: false,
          ...state.automationRules,
          data: [...state.automationRules.data, newRule],
          editorModal: {
            ...state.automationRules.editorModal,
            isSaving: false
          }
        }
      }
    case Actions.TOGGLE_ENABLE_AUTOMATION_RULE_POPUP:
      const { ruleId, isOpen, isEnabled } = action.payload

      return {
        ...state,
        automationRules: {
          ...state.automationRules,
          toggleRuleModal: {
            ruleId,
            isOpen,
            isEnabled
          }
        }
      }

    case Actions.FETCH_SAVED_FILES:
      return {
        ...state,
        savedFiles: {
          ...state.savedFiles,
          status: AsyncStatus.Loading
        }
      }

    case Actions.FETCH_SAVED_FILES_RECEIVED:
      return {
        ...state,
        savedFiles: {
          ...state.savedFiles,
          status: AsyncStatus.Completed,
          files: [...state.savedFiles.files, ...(action?.payload || [])]
        }
      }

    case Actions.FETCH_SAVED_VIEWS:
      return {
        ...state,
        savedViews: {
          ...state.savedViews,
          status: AsyncStatus.Loading
        }
      }

    case Actions.FETCH_SAVED_VIEWS_RECEIVED:
      return {
        ...state,
        savedViews: {
          ...state.savedViews,
          status: AsyncStatus.Completed,
          views: [...(action?.payload || [])]
        }
      }
    case Actions.TOGGLE_NEW_SAVED_VIEW_MODAL:
      return {
        ...state,
        savedViews: {
          ...state.savedViews,
          newSavedViewModal: {
            ...state.savedViews.newSavedViewModal,
            isOpen: action.payload
          }
        }
      }
    case Actions.CREATE_NEW_SAVED_VIEW:
      return {
        ...state,
        savedViews: {
          ...state.savedViews,
          newSavedViewModal: {
            ...state.savedViews.newSavedViewModal,
            status: AsyncStatus.Loading
          }
        }
      }
    case Actions.CREATE_NEW_SAVED_VIEW_RECEIVED:
      return {
        ...state,
        savedViews: {
          ...state.savedViews,
          views: [...state.savedViews.views, action.payload],
          newSavedViewModal: {
            ...state.savedViews.newSavedViewModal,
            status: AsyncStatus.Completed,
            isOpen: false
          }
        }
      }
    case Actions.CREATE_NEW_SAVED_VIEW_FAILED:
      return {
        ...state,
        savedViews: {
          ...state.savedViews,
          newSavedViewModal: {
            ...state.savedViews.newSavedViewModal,
            status: AsyncStatus.Failed
          }
        }
      }
    case Actions.DELETE_SAVED_VIEW:
      return {
        ...state,
        savedViews: {
          ...state.savedViews,
          views: state.savedViews.views.filter(view => view.id !== action.payload.id)
        }
      }

    case Actions.ADD_SAVED_FILE:
      return {
        ...state,
        savedFiles: {
          ...state.savedFiles,
          status: AsyncStatus.Loading
        }
      }
    case Actions.ADD_SAVED_FILE_RECEIVED:
      const newSavedFile = action.payload

      return {
        ...state,
        savedFiles: {
          ...state.savedFiles,
          status: AsyncStatus.Completed,
          files: [...state.savedFiles.files, newSavedFile]
        }
      }
    case Actions.ADD_SAVED_FILE_FAILED:
      return {
        ...state,
        savedFiles: {
          ...state.savedFiles,
          status: AsyncStatus.Failed
        }
      }

    case Actions.DELETE_SAVED_FILE:
      const fileToDelete = action.payload

      return {
        ...state,
        savedFiles: {
          ...state.savedFiles,
          files: removeWhere(state.savedFiles.files, currFile => currFile.id === fileToDelete.id)
        }
      }
    case Actions.FETCH_STATIC_DATA_RECEIVED:
      return {
        ...state,
        savedFiles: {
          ...state.savedFiles,
          files: [...state.savedFiles.files, ...(action.payload.systemSavedFiles || [])]
        }
      }
    case Actions.TOGGLE_SAVED_FILE_LOADING:
      return {
        ...state,
        savedFiles: {
          ...state.savedFiles,
          status: action.payload ? AsyncStatus.Loading : AsyncStatus.Completed
        }
      }
    case Actions.FETCH_PRACTICE_GUIDELINES:
      return {
        ...state,
        hiPracticeGuidelines: {
          ...state.hiPracticeGuidelines,
          isLoading: true
        }
      }
    case Actions.FETCH_PRACTICE_GUIDELINES_FAILED:
      return {
        ...state,
        hiPracticeGuidelines: {
          ...state.hiPracticeGuidelines,
          data: [],
          isLoading: false
        }
      }
    case Actions.FETCH_PRACTICE_GUIDELINES_RECEIVED:
      return {
        ...state,
        hiPracticeGuidelines: {
          ...state.hiPracticeGuidelines,
          data: action.payload || {},
          isLoading: false
        }
      }
    case Actions.SAVE_PRACTICE_GUIDELINES:
      return {
        ...state,
        hiPracticeGuidelines: {
          ...state.hiPracticeGuidelines,
          isSaving: true
        }
      }
    case Actions.SAVE_PRACTICE_GUIDELINES_RECEIVED:
      return {
        ...state,
        hiPracticeGuidelines: {
          ...state.hiPracticeGuidelines,
          data: action.payload,
          isSaving: false
        }
      }
    case Actions.SAVE_PRACTICE_GUIDELINES_FAILED:
      return {
        ...state,
        hiPracticeGuidelines: {
          ...state.hiPracticeGuidelines,
          isSaving: false
        }
      }
    case Actions.RESOLVE_HI_NEEDS_ATTENTION:
      return {
        ...state,
        resolveNeedsAttention: {
          ...state.resolveNeedsAttention,
          isSaving: true
        }
      }
    case Actions.RESOLVE_HI_NEEDS_ATTENTION_RECEIVED:
      return {
        ...state,
        resolveNeedsAttention: {
          ...state.resolveNeedsAttention,
          data: action.payload,
          isSaving: false
        }
      }
    case Actions.RESOLVE_HI_NEEDS_ATTENTION_FAILED:
      return {
        ...state,
        resolveNeedsAttention: {
          ...state.resolveNeedsAttention,
          isSaving: false
        }
      }
    case Actions.FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS:
      return {
        ...state,
        aggregatedCustomTags: {
          ...state.aggregatedCustomTags,
          status: AsyncStatus.Loading
        }
      }
    case Actions.FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS_FAILED:
      return {
        ...state,
        aggregatedCustomTags: {
          ...state.aggregatedCustomTags,
          status: AsyncStatus.Failed
        }
      }
    case Actions.FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS_RECEIVED:
      return {
        ...state,
        aggregatedCustomTags: {
          ...state.aggregatedCustomTags,
          status: AsyncStatus.Completed,
          data: action.payload.tags
        }
      }
    case Actions.ASSIGN_PATIENT_TAG_RECEIVED:
      return {
        ...state,
        aggregatedCustomTags: {
          ...initialState.aggregatedCustomTags
        }
      }
    default:
      return state
  }
}
