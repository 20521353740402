import Actions from 'actions'
import { API } from 'aws-amplify'
import axios from 'axios'
import { ReferralsSessionKey, ReferralsTokenKey } from 'consts/referralConsts'
import { ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, mapTo, mergeMap, pluck, switchMap, tap } from 'rxjs/operators'
import { push } from 'connected-react-router'
import * as otp from 'api/otp.api'
import { ROUTES } from 'consts'
import i18n from 'resources/locales/i18n'

export const fetchReferralDataEpic = action$ =>
  action$.pipe(
    ofType(Actions.REFERRALS_FETCH_REFERRAL_DATA),
    pluck('payload'),
    switchMap(({ referralId, token }) =>
      from(
        API.endpoint('grinServerlessApi').then(endpoint =>
          axios.get(
            `${endpoint}/platform/v1/referrals/${referralId}`,
            token
              ? {
                  headers: {
                    'x-api-key': token
                  }
                }
              : null
          )
        )
      ).pipe(
        tap(res => {
          if (res.data.status === 'unauthorized') {
            sessionStorage.setItem(
              ReferralsSessionKey,
              JSON.stringify({
                sessionId: res.data.sessionId,
                cooldownTime: res.data.cooldownTime,
                destination: res.data.destination
              })
            )
          }
        }),
        mergeMap(res => of(Actions.referralsFetchReferralDataReceived(res.data))),
        catchError(err => of(Actions.referralsFetchReferralDataFailed(err)))
      )
    )
  )

export const resendReferralVerificationCode = action$ =>
  action$.pipe(
    ofType(Actions.REFERRALS_RESEND_CODE),
    pluck('payload'),
    switchMap(({ sessionId, referralId }) =>
      from(otp.resendCode({ sessionId })).pipe(
        mergeMap(res => of(Actions.referralsResendCodeReceived(res.data))),
        catchError(err => {
          if (err.otpError === otp.OtpErrors.InvalidSession) {
            sessionStorage.removeItem(ReferralsSessionKey)
            return of(Actions.referralsFetchReferralData({ referralId }))
          }
          return of(Actions.referralsResendCodeFailed(err))
        })
      )
    )
  )

export const referralsVerifyOTP = action$ =>
  action$.pipe(
    ofType(Actions.REFERRALS_VERIFY_CODE),
    pluck('payload'),
    switchMap(({ sessionId, code }) =>
      from(otp.verifyCode({ sessionId, code })).pipe(
        mergeMap(res => {
          sessionStorage.removeItem(ReferralsSessionKey)
          const token = res.data.token
          sessionStorage.setItem(ReferralsTokenKey, token)
          return concat(
            of(Actions.referralsVerifyCodeReceived({ token })),
            of(Actions.referralsAuthComplete({ token }))
          )
        }),
        catchError(err => {
          if (err.otpError === otp.OtpErrors.InvalidSession) {
            sessionStorage.removeItem(ReferralsSessionKey)
          }
          return of(Actions.referralsVerifyCodeFailed(err))
        })
      )
    )
  )

export const referralsPostAuthenticationEpic = action$ =>
  action$.pipe(
    ofType(Actions.REFERRALS_AUTH_COMPLETE),
    pluck('payload'),
    tap(({ token }) => {
      sessionStorage.removeItem(ReferralsSessionKey)
      sessionStorage.setItem(ReferralsTokenKey, token)
    }),
    map(() => push(`${ROUTES.REFERRAL_VIEW_SCAN}${window.location.search}`))
  )

export const submitReferralScanReviewEpic = action$ =>
  action$.pipe(
    ofType(Actions.REFERRALS_SUBMIT_SCAN_REVIEW),
    pluck('payload'),
    switchMap(({ referralId, referralReview, token }) =>
      from(
        API.endpoint('grinServerlessApi').then(endpoint =>
          axios.post(
            `${endpoint}/platform/v1/referrals/scan/review`,
            { referralId, referralReview },
            {
              headers: {
                'x-api-key': token
              }
            }
          )
        )
      ).pipe(
        mergeMap(() => of(Actions.referralsSubmitScanReviewReceived({ referralReview }))),
        catchError(err => of(Actions.referralsSubmitScanReviewFailed(err)))
      )
    )
  )

export const submitReferralScanReviewReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.REFERRALS_SUBMIT_SCAN_REVIEW_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('pages.referrals.messages.submitSucceeded'),
        type: 'success'
      })
    )
  )

export const submitReferralScanReviewFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.REFERRALS_SUBMIT_SCAN_REVIEW_FAILED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('pages.referrals.messages.submitFailed'),
        type: 'error'
      })
    )
  )
