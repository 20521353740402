import * as React from 'react'
const GuidesAndTutorials = ({ ...props }) => {
  return (
    <svg width="90" height="66" viewBox="0 0 90 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 4.1522C1 2.41129 2.41129 1 4.1522 1H69.8478C71.5887 1 73 2.41129 73 4.1522V45.8478C73 47.5887 71.5887 49 69.8478 49H37H19L9.5 57.5L10 49H4.1522C2.41129 49 1 47.5887 1 45.8478V4.1522Z"
        fill="#D9DCFA"
        stroke="#4151E6"
        strokeWidth="1.5"
      />
      <path
        d="M35.5544 29.8027V29.6701C35.5692 28.2625 35.7166 27.1423 35.9966 26.3095C36.2766 25.4768 36.6746 24.8025 37.1905 24.2866C37.7064 23.7707 38.3254 23.2954 39.0476 22.8606C39.4824 22.5953 39.873 22.282 40.2194 21.9209C40.5658 21.5525 40.8384 21.1287 41.0374 20.6497C41.2438 20.1706 41.3469 19.64 41.3469 19.0578C41.3469 18.3356 41.1774 17.7092 40.8384 17.1786C40.4994 16.648 40.0462 16.239 39.4788 15.9515C38.9113 15.6641 38.2812 15.5204 37.5884 15.5204C36.9841 15.5204 36.4019 15.6457 35.8418 15.8963C35.2818 16.1468 34.8138 16.5411 34.4379 17.0791C34.0621 17.6171 33.8447 18.3209 33.7857 19.1905H31C31.059 17.9377 31.3832 16.8654 31.9728 15.9737C32.5697 15.0819 33.3546 14.4002 34.3274 13.9286C35.3075 13.4569 36.3946 13.2211 37.5884 13.2211C38.8855 13.2211 40.0131 13.479 40.9711 13.9949C41.9365 14.5108 42.6808 15.2183 43.2041 16.1174C43.7347 17.0165 44 18.0408 44 19.1905C44 20.0011 43.8747 20.7344 43.6242 21.3903C43.381 22.0462 43.0272 22.6321 42.5629 23.148C42.106 23.6638 41.5533 24.1208 40.9048 24.5187C40.2563 24.9241 39.7367 25.3515 39.3461 25.801C38.9555 26.2432 38.6718 26.7701 38.4949 27.3818C38.318 27.9935 38.2222 28.7563 38.2075 29.6701V29.8027H35.5544ZM36.9694 36.347C36.424 36.347 35.9561 36.1517 35.5655 35.7611C35.1749 35.3705 34.9796 34.9025 34.9796 34.3572C34.9796 33.8118 35.1749 33.3438 35.5655 32.9532C35.9561 32.5627 36.424 32.3674 36.9694 32.3674C37.5147 32.3674 37.9827 32.5627 38.3733 32.9532C38.7639 33.3438 38.9592 33.8118 38.9592 34.3572C38.9592 34.7183 38.8671 35.0499 38.6828 35.3521C38.5059 35.6542 38.2665 35.8974 37.9643 36.0817C37.6695 36.2585 37.3379 36.347 36.9694 36.347Z"
        fill="#4151E6"
      />
      <circle cx="73" cy="49" r="16" fill="white" stroke="#4151E6" strokeWidth="1.5" />
      <rect x="71" y="44" width="4" height="12" fill="#4151E6" />
      <rect x="69" y="44" width="6" height="3" fill="#4151E6" />
      <rect x="69" y="53" width="8" height="3" fill="#4151E6" />
      <circle cx="73" cy="41" r="2" fill="#4151E6" />
    </svg>
  )
}

export default GuidesAndTutorials
