import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import AutomationRule from './AutomationRule'

const useStyles = makeStyles(theme => ({
  modal: {
    minWidth: ({ isEnabled }) => (isEnabled ? 1200 : 'auto')
  }
}))

const ToggleRuleConfirmationPopup = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isOpen, ruleId, isEnabled } = useSelector(state => state.practiceReducer.automationRules.toggleRuleModal)
  const classes = useStyles({ isEnabled })
  const automationRules = useSelector(state => state.practiceReducer.automationRules)

  const selectedRule = useMemo(() => automationRules?.data?.find(rule => rule.id === ruleId), [automationRules, ruleId])

  const handleClose = useCallback(() => {
    dispatch(
      Actions.toggleEnableAutomationRulePopup({
        isOpen: false,
        ruleId: null,
        isEnabled: null
      })
    )
  }, [dispatch])

  const handleToggleRule = useCallback(() => {
    dispatch(
      Actions.toggleAutomationRule({
        id: ruleId,
        isEnabled
      })
    )
    handleClose()
  }, [dispatch, handleClose, isEnabled, ruleId])

  return (
    <BaseModal
      open={isOpen}
      withCloseIcon
      variant="alert"
      title={
        isEnabled
          ? t('pages.accountSettings.automations.toggleRuleModal.turnOnTitle')
          : t('pages.accountSettings.automations.toggleRuleModal.turnOffTitle')
      }
      onPrimaryBtnClick={handleToggleRule}
      onSecondaryBtnClick={handleClose}
      primaryLabel={t('general.confirm')}
      secondaryLabel={t('general.cancel')}
      handleClose={handleClose}
      className={classes.modal}
    >
      {isEnabled && selectedRule && (
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <DazzedParagraph14>
              {t('pages.accountSettings.automations.toggleRuleModal.turnOnDescription')}
            </DazzedParagraph14>
          </Grid>
          <Grid item>
            <Grid container justifyContent="center">
              <Grid item>
                <AutomationRule rule={selectedRule} shouldDisplayHeader={false} shouldDisplayMenu={false} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </BaseModal>
  )
}

export default ToggleRuleConfirmationPopup
