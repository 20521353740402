import i18n from 'resources/locales/i18n'

export const PREVENTIVE_LAYERS = [
  {
    type: 'inflammation',
    visible: false,
    label: i18n.t('dialogs.scanSummary.preventive.legend.inflamation'),
    color: '#54FFF5'
  },
  {
    type: 'plaque',
    visible: false,
    label: i18n.t('dialogs.scanSummary.preventive.legend.plaque'),
    color: '#FFE500'
  },
  {
    type: 'recession',
    visible: false,
    label: i18n.t('dialogs.scanSummary.preventive.legend.recession'),
    color: '#000AFF'
  },
  {
    type: 'tartar',
    visible: false,
    label: i18n.t('dialogs.scanSummary.preventive.legend.tartar'),
    color: '#FF9737'
  }
]
