import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import OggHeading32 from 'components/common/text/OggHeading32'
import SuccessIcon from 'components/common/icons/Success'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import BaseModal from 'components/common/modals/BaseModal'
import { ROUTES } from 'consts'
import useClinic from 'hooks/useClinic'
import Actions from 'actions'
import { isMobile } from 'utils/mobileUtils'
import { trackEvent } from 'utils/analyticsUtils'
import { COUNTRY_IL } from 'consts/countryConsts'

const useStyles = makeStyles(theme => ({
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
    marginBottom: 20
  },
  title: {
    marginBottom: 20
  },
  description: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    margin: '0 30px',
    marginBottom: 20
  },
  forMoreInfo: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px'
  },
  emailLink: {
    fontWeight: 500,
    color: 'var(--text-color-3)',
    cursor: 'pointer',
    textAlign: 'center'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50
  },
  content: {
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }
  }
}))

const ScopesPaymentAccepted = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { canClinicBuyScopes, practiceDetails } = useClinic()

  const isModalOpen = useSelector(state => state.billingReducer.order.isScopesPaymentAcceptedModalOpen)
  const practiceName = useSelector(state => state.practiceReducer.details.practiceName)
  const memberRole = useSelector(state => state.profileReducer.doctor.roleDescription)

  const handleClose = useCallback(() => {
    dispatch(Actions.setScopesPaymentAcceptedModalVisibility(false))
    if (isMobile()) {
      trackEvent('Mobile_confirm_popup', {
        practiceName,
        memberRole
      })
    }
  }, [dispatch, memberRole, practiceName])

  const handleClickBackToInbox = useCallback(() => {
    handleClose()
    history.push(ROUTES.PATIENTS)
  }, [history, handleClose])

  const supportEmail = useMemo(
    () => (practiceDetails?.country === COUNTRY_IL ? 'info@intertec-ortho.com' : 'support@get-grin.com'),
    [practiceDetails?.country]
  )

  return (
    <BaseModal
      open={isModalOpen}
      variant={isMobile() ? 'fullscreen' : 'alert'}
      contentClassName={classes.content}
      handleClose={handleClose}
    >
      <div className={classes.iconContainer}>
        <SuccessIcon />
      </div>
      <OggHeading32 className={classes.title}>
        {canClinicBuyScopes()
          ? t('dialogs.orderScopes.paymentCompleteMessageTitle')
          : t('dialogs.orderScopes.orderAcceptedMessageTitle')}
      </OggHeading32>
      <DazzedParagraph16 textAlign="center" className={classes.description}>
        {canClinicBuyScopes()
          ? t('dialogs.orderScopes.paymentCompleteMessageDescription')
          : t('dialogs.orderScopes.orderAcceptedMessageDescription1')}
      </DazzedParagraph16>

      {!canClinicBuyScopes() && (
        <>
          <DazzedParagraph16 className={classes.forMoreInfo} textAlign="center">
            {t('dialogs.orderScopes.orderAcceptedMessageDescription2')}
          </DazzedParagraph16>
          <a href={`mailto:${supportEmail}`}>
            <DazzedParagraph16 className={classes.emailLink} textAlign="center">
              {supportEmail}
            </DazzedParagraph16>
          </a>
        </>
      )}

      {isMobile() && (
        <div className={classes.buttonContainer}>
          <PrimaryButton label={t('general.backToInbox')} onClick={handleClickBackToInbox} />
        </div>
      )}
    </BaseModal>
  )
}

export default ScopesPaymentAccepted
