import * as React from 'react'

export default ({ disabled = false }) => (
  <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="4.5" r="3.5" stroke="white" />
    <path d="M1 11C1 11 3.5 9 8 9C12.5 9 15 11 15 11V15H1V11Z" stroke="white" />
    <mask id="path-3-outside-1_504_79" maskUnits="userSpaceOnUse" x="8" y="5" width="19" height="18" fill="black">
      <rect fill="white" x="8" y="5" width="19" height="18" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 14L18 10V12.5H15C12.2386 12.5 10 14.7386 10 17.5L10.5355 16.9645C11.4732 16.0268 12.745 15.5 14.0711 15.5H18V18L23 14Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 14L18 10V12.5H15C12.2386 12.5 10 14.7386 10 17.5L10.5355 16.9645C11.4732 16.0268 12.745 15.5 14.0711 15.5H18V18L23 14Z"
      fill="white"
    />
    <path
      d="M18 10L19.2494 8.43826L16 5.83875V10H18ZM23 14L24.2494 15.5617L26.2016 14L24.2494 12.4383L23 14ZM18 12.5V14.5H20V12.5H18ZM10 17.5H8V22.3284L11.4142 18.9142L10 17.5ZM10.5355 16.9645L9.12132 15.5503L9.12132 15.5503L10.5355 16.9645ZM18 15.5H20V13.5H18V15.5ZM18 18H16V22.1613L19.2494 19.5617L18 18ZM16.7506 11.5617L21.7506 15.5617L24.2494 12.4383L19.2494 8.43826L16.7506 11.5617ZM20 12.5V10H16V12.5H20ZM15 14.5H18V10.5H15V14.5ZM12 17.5C12 15.8431 13.3431 14.5 15 14.5V10.5C11.134 10.5 8 13.634 8 17.5H12ZM9.12132 15.5503L8.58579 16.0858L11.4142 18.9142L11.9497 18.3787L9.12132 15.5503ZM14.0711 13.5C12.2146 13.5 10.4341 14.2375 9.12132 15.5503L11.9497 18.3787C12.5124 17.8161 13.2754 17.5 14.0711 17.5V13.5ZM18 13.5H14.0711V17.5H18V13.5ZM20 18V15.5H16V18H20ZM21.7506 12.4383L16.7506 16.4383L19.2494 19.5617L24.2494 15.5617L21.7506 12.4383Z"
      fill={disabled ? '#fff' : '#4151E6'}
      mask="url(#path-3-outside-1_504_79)"
    />
  </svg>
)
