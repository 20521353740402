export const GRIN_SCOPE_TYPE_REGULAR = 'regular'
export const GRIN_SCOPE_TYPE_MINI = 'mini'
export const GRIN_SCOPE_MAX_IN_ORDER = 300
export const GRIN_SCOPE_MIN_IN_ORDER = 10
export const SUBSCRIPTION_PLAN_GRIN_SCOPE_MAX_IN_ORDER = 100
export const SUBSCRIPTION_PLAN_GRIN_SCOPE_MINI_MAX_IN_ORDER = 100

export const COUPON_ERRORS = {
  GENERAL: 'general',
  COUPON_INVALID_FOR_INVOICE_ITEMS: 'couponInvalidForInvoiceItems'
}

export const HI_GRUOP_IDS = [
  'group-1',
  'group-2',
  'group-3',
  'group-gi-1',
  'group-gi-2',
  'group-gi-3',
  'group-qa',
  'group-gi-qa'
]
