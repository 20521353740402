import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { conditionTypes } from 'consts/automatedActionConsts'
import { DAYS_OF_WEEK } from 'consts/dateTimeConsts'
import { compareDayOfWeekNames } from 'utils/dateUtils'
import { useTranslation } from 'react-i18next'
import ErrorMessage from 'components/common/text/ErrorMessage'

const useStyles = makeStyles(theme => ({
  day: {
    padding: 8,
    width: 40,
    height: 40,
    borderRadius: '100%',
    color: 'var(--text-color-25)',
    border: '1px solid var(--text-color-25)',
    cursor: 'pointer',
    userSelect: 'none'
  },
  selected: {
    backgroundColor: 'var(--bg-color-43)',
    color: 'white'
  }
}))

const DuringDaysCondition = ({ onChange, params, errors }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [selectedDays, setSelectedDays] = useState(params?.duringDays || [])

  const errorMessage = useMemo(() => errors.conditions?.[conditionTypes.DuringDays], [errors])
  const daysOfWeekOptions = useMemo(
    () =>
      DAYS_OF_WEEK.map(day => ({
        label: t(`general.daysOfWeek.${day}`)[0],
        value: day
      })),
    [t]
  )

  const handleDayClick = useCallback(
    selectedDay => {
      let updatedDays = [...selectedDays]

      if (updatedDays.some(day => day === selectedDay)) {
        updatedDays = updatedDays.filter(day => day !== selectedDay)
      } else {
        updatedDays = [...updatedDays, selectedDay]
      }

      updatedDays.sort(compareDayOfWeekNames)
      setSelectedDays(updatedDays)
      onChange({
        type: conditionTypes.DuringDays,
        params: {
          duringDays: updatedDays
        }
      })
    },
    [onChange, selectedDays]
  )

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Grid container alignItems="center" wrap="nowrap" spacing={1}>
          {daysOfWeekOptions.map(day => (
            <Grid item key={day.value} onClick={() => handleDayClick(day.value)}>
              <DazzedParagraph14
                textAlign="center"
                className={`${classes.day} ${selectedDays.includes(day.value) ? classes.selected : ''}`}
              >
                {day.label}
              </DazzedParagraph14>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>{errorMessage && <ErrorMessage text={errorMessage} active={!!errorMessage} />}</Grid>
    </Grid>
  )
}

export default DuringDaysCondition
