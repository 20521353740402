import React from 'react'
import TitleWithText from 'components/Patients/PatientGuidelines/PatientBrief/Categories/TitleWithText'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { sortTeeth } from 'utils/giUtils'

const AlignersTxTracking = ({ items }) => {
  const { t } = useTranslation()

  const { untrackedTeeth, attachments } = items

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        {untrackedTeeth && (
          <TitleWithText
            title={`${t(`dialogs.hiPatientGuidelinesViewer.items.untrackedTeeth`)}:`}
            text={sortTeeth(untrackedTeeth).join(', ')}
          />
        )}
      </Grid>
      <Grid item>
        {attachments && (
          <TitleWithText
            title={`${t(`dialogs.hiPatientGuidelinesViewer.items.attachments`)}:`}
            text={sortTeeth(attachments).join(', ')}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default AlignersTxTracking
