import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AsyncStatus } from 'consts'
import PatientDetails from 'components/common/PatientDetails'
import DownloadChart from 'components/common/icons/autochart/DownloadChart'
import AutochartHeaderButton from './AutochartHeaderButton'
import { trackEvent } from 'utils/analyticsUtils'
import { exportElementToImage } from 'utils/canvasUtils'

const AutochartHeader = () => {
  const { t } = useTranslation()

  const { patientSM, status } = useSelector(state => state.autochartReducer)

  const handleDownloadChart = useCallback(() => {
    trackEvent('Autochart - download chart as image', {
      patientId: patientSM.id
    })

    exportElementToImage({ selector: '#autochart-grid', fileName: `Autochart - ${patientSM.name}.png` })
  }, [patientSM])

  return (
    <Grid container justifyContent="space-between" alignItems="flex-end">
      <Grid item>
        <PatientDetails
          name={patientSM?.name}
          photo={patientSM?.photo}
          program={patientSM?.program}
          isLoading={status === AsyncStatus.Loading}
        />
      </Grid>
      {status === AsyncStatus.Completed && (
        <Grid item>
          <AutochartHeaderButton
            icon={<DownloadChart />}
            label={t('dialogs.autochart.header.actions.downloadChart')}
            onClick={handleDownloadChart}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default AutochartHeader
