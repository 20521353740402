import * as React from 'react'

function SvgBoards(props) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path d="M5 6h6v11a1 1 0 01-1 1H5V6zM13 6h6v11a1 1 0 01-1 1h-5V6z" fill="#001335" />
    </svg>
  )
}

export default SvgBoards
