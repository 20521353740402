import React, { useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { Info as InfoIcon } from 'components/common/icons'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { scrollBar } from 'styles/theme'
import { isMobile, isLandscape } from 'utils/mobileUtils'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = ({ isMobileLandscape }) =>
  makeStyles(theme => ({
    container: {
      border: '1px solid var(--border-color-19)',
      marginTop: 10,
      width: 204,
      height: ({ isMobileLandscape }) => (isMobileLandscape ? 216 : 343),
      borderRadius: '0px 0px 20px 0px',
      position: 'relative',
      overflow: 'hidden'
    },
    infoButton: {
      position: 'absolute',
      top: 9,
      right: 6,
      width: 25,
      height: 25,
      border: '1px solid #3C52EB',
      backgroundColor: 'var(--bg-color-10)',
      color: '#3C52EB',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      cursor: 'pointer'
    },
    infoContainer: {
      overflowY: 'auto',
      maxHeight: ({ isMobileLandscape }) => (isMobileLandscape ? 216 : 343),
      ...scrollBar
    },
    infoTitle: {
      margin: '33px 9px 5px 13px',
      userSelect: 'text'
    },
    infoDescription: {
      margin: '0px 9px 35px 13px',
      userSelect: 'text'
    },
    description: {
      whiteSpace: 'pre-wrap'
    },
    imageContainer: {
      marginTop: ({ isMobileLandscape }) => (isMobileLandscape ? 6 : 10),
      height: ({ isMobileLandscape }) => (isMobileLandscape ? 153 : 275),
      width: 186
    },
    image: {
      minHeight: 110,
      maxHeight: ({ isMobileLandscape }) => (isMobileLandscape ? 153 : 275),
      minWidth: 110,
      maxWidth: 186,
      marginBottom: '-113px' // ask Noa
    },
    lastMessageDate: {
      fontSize: 8,
      height: 19,
      backgroundColor: '#F8F9FA',
      lineHeight: '19px',
      textAlign: 'center',
      width: 202
    },
    selectButton: {
      borderRadius: '0px 0px 20px 0px',
      '&:hover': {
        backgroundColor: '#3C52EF',
        color: 'white'
      }
    }
  }))({ isMobileLandscape })

const Product = ({ product, onClick }) => {
  const classes = useStyles({ isMobileLandscape: isMobile() && isLandscape() })
  const { t } = useTranslation()

  const lastPromotionMessages = useSelector(state => state.chatReducer.promotions.lastMessages.data)
  const [showDescription, setShowDescription] = useState(false)

  const lastMessageDate = useMemo(() => {
    const lastPromotionMessage = lastPromotionMessages.find(message => message.promotionId === product.id)
    return lastPromotionMessage?.createdAt
  }, [lastPromotionMessages, product.id])

  const handleShowDescription = useCallback(() => {
    setShowDescription(!showDescription)
    trackEvent('Promotions - product info clicked', {
      productId: product.id,
      category: product.category
    })
  }, [product, showDescription])

  return (
    <Grid
      container
      direction="column"
      alignItems={showDescription ? 'flex-start' : 'center'}
      justifyContent={showDescription ? 'flex-start' : 'space-between'}
      className={classes.container}
    >
      <div className={classes.infoButton} width={25} height={25} onClick={handleShowDescription}>
        <InfoIcon isOpen={showDescription} />
      </div>
      {showDescription ? (
        <Grid item>
          <Grid container alignItems="flex-start" className={classes.infoContainer}>
            <Grid item xs={12} className={classes.infoTitle}>
              <DazzedParagraph12 thickness="bold">{product.title}</DazzedParagraph12>
            </Grid>
            <Grid item xs={12} className={classes.infoDescription}>
              <DazzedParagraph12 className={classes.description}>{product.description}</DazzedParagraph12>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item>
            <Grid container justifyContent="center" alignItems="center" className={classes.imageContainer}>
              <img src={product.imageUrl || product.thumbnailUrl} alt={product.description} className={classes.image} />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="center">
              {lastMessageDate && (
                <Grid item xs={12} className={classes.lastMessageDate}>
                  {t('pages.patients.selectedPatient.chat.promotions.lastMessageSent', {
                    date: moment(lastMessageDate).fromNow()
                  })}
                </Grid>
              )}
              <Grid item xs={12}>
                <PrimaryButton
                  className={classes.selectButton}
                  width={202}
                  height={36}
                  label={t('pages.patients.selectedPatient.chat.promotions.selectProductLabel')}
                  onClick={onClick}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default Product
