import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase, CircularProgress, Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '8px 12px',
    border: '1px solid var(--text-color-25)',
    borderRadius: 32
  },
  label: {
    color: 'var(--text-color-25)'
  }
}))

const ActionButton = ({ onClick, isLoading = false, icon, label, labelClassName = '' }) => {
  const classes = useStyles()

  return isLoading ? (
    <div>
      <CircularProgress size={14} />
    </div>
  ) : (
    <ButtonBase onClick={onClick} className={classes.root}>
      <Grid container spacing={1} alignItems="center">
        {icon && <Grid item>{icon}</Grid>}
        <Grid item>
          <DazzedParagraph12 className={[classes.label, labelClassName].join(' ')} thickness="bold">
            {label}
          </DazzedParagraph12>
        </Grid>
      </Grid>
    </ButtonBase>
  )
}

export default ActionButton
