import * as React from 'react'

export default props => (
  <svg width="80" height="16" viewBox="0 0 80 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6667 0H3.33333C1.49267 0 0 1.49267 0 3.33333V12.6667C0 14.5073 1.49267 16 3.33333 16H12.6667C14.508 16 16 14.5073 16 12.6667V3.33333C16 1.49267 14.508 0 12.6667 0ZM10.6667 4.66667H9.384C8.974 4.66667 8.66667 4.83467 8.66667 5.25933V6H10.6667L10.508 8H8.66667V13.3333H6.66667V8H5.33333V6H6.66667V4.718C6.66667 3.37 7.376 2.66667 8.974 2.66667H10.6667V4.66667Z"
      fill="#ABB0B8"
    />
    <path
      d="M50.691 3.55933C48.2677 3.55933 46.297 5.53 46.2957 7.952C46.295 8.94067 46.585 9.68133 47.0717 10.456L46.6277 12.0773L48.2903 11.6413C49.035 12.0833 49.7463 12.3487 50.687 12.3487C53.1083 12.3487 55.081 10.378 55.0817 7.95533C55.0837 5.528 53.1203 3.56 50.691 3.55933ZM53.275 9.84133C53.165 10.15 52.637 10.4313 52.3837 10.4693C52.1563 10.5033 51.8683 10.5173 51.5517 10.4173C51.3597 10.3567 51.1137 10.2753 50.799 10.1393C49.4743 9.56733 48.609 8.23333 48.543 8.14533C48.477 8.05733 48.0037 7.42933 48.0037 6.77933C48.0037 6.12933 48.345 5.81 48.4657 5.67733C48.587 5.544 48.7297 5.51067 48.8177 5.51067L49.071 5.51533C49.1523 5.51933 49.261 5.48467 49.3683 5.742C49.4783 6.00667 49.7423 6.65667 49.7757 6.72267C49.8083 6.78867 49.831 6.866 49.7863 6.954C49.7423 7.042 49.7203 7.09733 49.6543 7.174L49.4563 7.40533C49.3903 7.47133 49.3217 7.54267 49.3983 7.67467C49.4757 7.80667 49.7403 8.23933 50.133 8.58933C50.6377 9.03933 51.063 9.17867 51.195 9.24467C51.327 9.31067 51.4043 9.3 51.481 9.21133C51.5583 9.12333 51.811 8.826 51.899 8.69333C51.987 8.56067 52.075 8.58333 52.1963 8.62733C52.3177 8.67133 52.967 8.99067 53.099 9.05733C53.231 9.12333 53.319 9.15667 53.3523 9.21133C53.385 9.268 53.385 9.53267 53.275 9.84133ZM55.3337 0H46.0003C44.1597 0 42.667 1.49267 42.667 3.33333V12.6667C42.667 14.5073 44.1597 16 46.0003 16H55.3337C57.175 16 58.667 14.5073 58.667 12.6667V3.33333C58.667 1.49267 57.175 0 55.3337 0ZM50.689 13.2413C49.8043 13.2413 48.933 13.0193 48.161 12.598L45.359 13.3333L46.109 10.5947C45.6463 9.79333 45.403 8.884 45.4037 7.952C45.405 5.03733 47.7757 2.66667 50.689 2.66667C52.103 2.66733 53.4303 3.218 54.4283 4.21667C55.4263 5.21533 55.9757 6.544 55.975 7.956C55.9737 10.8707 53.6023 13.2413 50.689 13.2413Z"
      fill="#ABB0B8"
    />
    <path
      d="M74.1553 3.65867C73.5933 3.63333 73.424 3.628 72 3.628C70.576 3.628 70.4073 3.63333 69.8453 3.65867C68.3987 3.72467 67.7247 4.41 67.6593 5.84467C67.6333 6.40733 67.6273 6.576 67.6273 8C67.6273 9.424 67.6333 9.59267 67.6587 10.1553C67.7247 11.5873 68.396 12.2753 69.8447 12.3413C70.4067 12.3667 70.576 12.3727 72 12.3727C73.4247 12.3727 73.5933 12.3673 74.1553 12.342C75.602 12.276 76.2753 11.5893 76.3413 10.156C76.3667 9.59333 76.372 9.42467 76.372 8.00067C76.372 6.57667 76.3667 6.408 76.3413 5.846C76.2753 4.41067 75.6007 3.72467 74.1553 3.65867ZM72 10.7387C70.4873 10.7387 69.2613 9.51267 69.2613 8C69.2613 6.48733 70.488 5.26133 72 5.26133C73.512 5.26133 74.7387 6.48733 74.7387 8C74.7387 9.51267 73.5127 10.7387 72 10.7387ZM74.8473 5.79333C74.494 5.79333 74.2073 5.50667 74.2073 5.15333C74.2073 4.8 74.494 4.51333 74.8473 4.51333C75.2007 4.51333 75.4873 4.8 75.4873 5.15333C75.4873 5.50667 75.2007 5.79333 74.8473 5.79333ZM73.778 8C73.778 8.982 72.982 9.778 72 9.778C71.018 9.778 70.222 8.982 70.222 8C70.222 7.018 71.018 6.222 72 6.222C72.982 6.222 73.778 7.018 73.778 8ZM76.6667 0H67.3333C65.4927 0 64 1.49267 64 3.33333V12.6667C64 14.5073 65.4927 16 67.3333 16H76.6667C78.508 16 80 14.5073 80 12.6667V3.33333C80 1.49267 78.508 0 76.6667 0ZM77.3013 10.1987C77.2133 12.138 76.134 13.2127 74.1993 13.3013C73.63 13.3273 73.4487 13.3333 72 13.3333C70.5513 13.3333 70.3707 13.3273 69.8013 13.3013C67.8627 13.2127 66.788 12.136 66.6987 10.1987C66.6727 9.63 66.6667 9.44867 66.6667 8C66.6667 6.552 66.6727 6.37 66.6987 5.80133C66.788 3.86267 67.864 2.78733 69.8013 2.69933C70.3707 2.67267 70.5513 2.66667 72 2.66667C73.4487 2.66667 73.63 2.67267 74.1993 2.69867C76.138 2.78733 77.2147 3.866 77.3013 5.80067C77.3273 6.37 77.3333 6.552 77.3333 8C77.3333 9.44867 77.3273 9.63 77.3013 10.1987Z"
      fill="#ABB0B8"
    />
    <rect x="21" width="16" height="16" rx="3" fill="#ABB0B8" />
    <path d="M25.7688 12.5L33.4188 3.5H32.7312L25.0812 12.5H25.7688Z" stroke="white" />
    <path d="M30.75 12.5L24 3.5H26.75L33.5 12.5H30.75Z" fill="#ABB0B8" stroke="white" />
  </svg>
)
