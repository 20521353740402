import React from 'react'
import { makeStyles } from '@material-ui/styles'
import usePatientBanner from 'hooks/usePatientBanner'
import Banner from '../Banner'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  root: {
    height: 'var(--patient-banner-height)'
  }
}))

const PatientBanner = ({ isLoading }) => {
  const classes = useStyles()

  const bannerType = usePatientBanner()

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Skeleton variant="rect" height="100%" width="100%" />
      ) : bannerType ? (
        <Banner type={bannerType} />
      ) : (
        <></>
      )}
    </div>
  )
}

export default PatientBanner
