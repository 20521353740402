import * as React from 'react'

export default () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="12.5" fill="#EB5757" />
      <path d="M11.2493 5.83325H13.7493V14.9999H11.2493V5.83325Z" fill="white" />
      <path d="M11.2493 17.5H13.7493V20H11.2493V17.5Z" fill="white" />
    </svg>
  )
}
