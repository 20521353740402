import * as React from 'react'

function PatientBriefFloatingIcon({ style }) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <circle cx="24" cy="24" r="24" fill="white" />
      <rect x="16.75" y="14.75" width="14.5" height="18.5" rx="2.25" stroke="#3C52EF" strokeWidth="1.5" />
      <path d="M20 20H28" stroke="#3C52EF" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M20 24H28" stroke="#3C52EF" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M20 28H28" stroke="#3C52EF" strokeWidth="1.5" strokeLinecap="round" />
      <mask id="path-6-outside-1_443_777" maskUnits="userSpaceOnUse" x="19" y="10" width="10" height="7" fill="black">
        <rect fill="white" x="19" y="10" width="10" height="7" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26 13C26 11.8954 25.1046 11 24 11C22.8954 11 22 11.8954 22 13H21C20.4477 13 20 13.4477 20 14V15C20 15.5523 20.4477 16 21 16H27C27.5523 16 28 15.5523 28 15V14C28 13.4477 27.5523 13 27 13H26Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 13C26 11.8954 25.1046 11 24 11C22.8954 11 22 11.8954 22 13H21C20.4477 13 20 13.4477 20 14V15C20 15.5523 20.4477 16 21 16H27C27.5523 16 28 15.5523 28 15V14C28 13.4477 27.5523 13 27 13H26Z"
        fill="#3C52EF"
      />
      <path
        d="M26 13H25V14H26V13ZM22 13V14H23V13H22ZM24 12C24.5523 12 25 12.4477 25 13H27C27 11.3431 25.6569 10 24 10V12ZM23 13C23 12.4477 23.4477 12 24 12V10C22.3431 10 21 11.3431 21 13H23ZM21 14H22V12H21V14ZM21 14V12C19.8954 12 19 12.8954 19 14H21ZM21 15V14H19V15H21ZM21 15H19C19 16.1046 19.8954 17 21 17V15ZM27 15H21V17H27V15ZM27 15V17C28.1046 17 29 16.1046 29 15H27ZM27 14V15H29V14H27ZM27 14H29C29 12.8954 28.1046 12 27 12V14ZM26 14H27V12H26V14Z"
        fill="white"
        mask="url(#path-6-outside-1_443_777)"
      />
      <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 23 14)" fill="white" />
    </svg>
  )
}

export default PatientBriefFloatingIcon
