const PIXEL_CONTENT_VIEWED = 'PIXEL_CONTENT_VIEWED'
const PIXEL_EVENT_SUCCESS = 'PIXEL_EVENT_SUCCESS'
const PIXEL_EVENT_FAILURE = 'PIXEL_EVENT_FAILURE'

const pixelContentViewed = () => ({
  type: PIXEL_CONTENT_VIEWED
})

const pixelEventSuccess = () => ({
  type: PIXEL_EVENT_SUCCESS
})

const pixelEventFailure = error => ({
  type: PIXEL_EVENT_FAILURE,
  error
})

export default {
  PIXEL_CONTENT_VIEWED,
  PIXEL_EVENT_SUCCESS,
  PIXEL_EVENT_FAILURE,
  pixelContentViewed,
  pixelEventSuccess,
  pixelEventFailure
}
