import { makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SelectInput from '../../common/SelectInput'
import LabeledInputContainer from '../../common/inputs/LabeledInputContainer'

const useStyles = makeStyles(() => ({
  inputContainer: {
    marginTop: 3
  }
}))

const TreatmentTypePicker = ({
  treatmentType,
  keepErrorContainerWhenInactive = true,
  errorMessage,
  onSet,
  disabled = false,
  tooltip = true
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const options = useMemo(
    () => [
      { label: t('dialogs.scanFrequency.aligners'), value: 'Aligners' },
      { label: t('dialogs.scanFrequency.braces'), value: 'Braces' },
      { label: t('dialogs.scanFrequency.other'), value: 'Other' }
    ],
    [t]
  )

  return (
    <LabeledInputContainer
      title={t('dialogs.scanFrequency.treatmentType')}
      tooltip={tooltip ? t('tooltips.treatmentType') : null}
    >
      <div className={classes.inputContainer}>
        <SelectInput
          style={{ bright: true, thick: true }}
          options={options}
          value={treatmentType}
          keepErrorContainerWhenInactive={keepErrorContainerWhenInactive}
          errorMessage={errorMessage}
          onChange={option => onSet(option.value)}
          disabled={disabled}
          placeholder={t('dialogs.scanFrequency.treatmentTypePlaceholder')}
        />
      </div>
    </LabeledInputContainer>
  )
}

export default TreatmentTypePicker
