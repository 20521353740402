import React, { useCallback } from 'react'
import { makeStyles, Grid, Popover, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from '../../../common/text/DazzedParagraph14'
import DazzedParagraph12 from '../../../common/text/DazzedParagraph12'
import moment from 'moment'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  popoverContainer: {
    width: 300,
    padding: 16
  },
  greyText: {
    color: 'var(--text-color-14)'
  },
  itemContainer: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    marginTop: 5,
    padding: 5,
    borderRadius: 2,
    cursor: 'pointer'
  },
  loader: {
    marginTop: 10
  }
})

const PmsSearchPatientsResults = ({ isVisible, anchorEl, onClose, onSelectPatient }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { isLoading, patients } = useSelector(state => state.pmsReducer)

  const handleSelectPatient = useCallback(
    pmsPatient => {
      onSelectPatient(pmsPatient)
    },
    [onSelectPatient]
  )

  return (
    <Popover
      id={'patientResult'}
      open={isVisible}
      anchorEl={anchorEl?.current}
      onClose={onClose}
      disableAutoFocus
      disableEnforceFocus
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      classes={{ paper: classes.popoverContainer }}
    >
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <DazzedParagraph12 className={classes.greyText}>
            {t('dialogs.patientInfo.appointments.pms.patientNameDolphinId')}
          </DazzedParagraph12>
        </Grid>
        <Grid item xs={4}>
          <DazzedParagraph12 className={classes.greyText}>
            {t('dialogs.patientInfo.appointments.pms.birthdate')}
          </DazzedParagraph12>
        </Grid>
        {isLoading ? (
          <Grid item xs={12} className={classes.loader}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <CircularProgress size={30} />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          patients.map(patient => (
            <Grid
              key={patient.displayId}
              item
              xs={12}
              className={classes.itemContainer}
              onClick={() => handleSelectPatient(patient)}
            >
              <Grid container alignItems="center">
                <Grid item xs={8}>
                  <DazzedParagraph14 strong>{`${patient.firstName} ${patient.lastName}`}</DazzedParagraph14>
                  <DazzedParagraph12>{patient.displayId}</DazzedParagraph12>
                </Grid>
                <Grid item xs={4} className={classes.greyText}>
                  {moment(patient.birthDate).format('MMM Do YY')}
                </Grid>
              </Grid>
            </Grid>
          ))
        )}
      </Grid>
    </Popover>
  )
}

export default PmsSearchPatientsResults
