import { useCallback, useMemo } from 'react'
import useFeatureFlags from './useFeatureFlags'
import usePlanLimits from './usePlanLimits'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { isEmpty } from 'lodash'

export default () => {
  const dispatch = useDispatch()
  const { isHI: isOnHI } = usePlanLimits()
  const { hiTools: hiToolsFF } = useFeatureFlags()

  const { data: practiceGuidelinesData, isLoading: isLoadingPracticeGuidelines } = useSelector(
    state => state.practiceReducer.hiPracticeGuidelines
  )

  const hiToolsAvailable = useMemo(() => isOnHI && hiToolsFF, [isOnHI, hiToolsFF])
  const practiceGuidelines = useMemo(
    () => ({
      ...(practiceGuidelinesData || {}),
      categories: JSON.parse(practiceGuidelinesData?.categories || '{}')
    }),
    [practiceGuidelinesData]
  )
  const hasAnyPracticeGuidelinesConfigured = useMemo(
    () =>
      hiToolsAvailable &&
      !isEmpty(practiceGuidelines?.categories) &&
      Object.keys(practiceGuidelines.categories).some(category => practiceGuidelines.categories[category].isEnabled),
    [hiToolsAvailable, practiceGuidelines]
  )
  const hasPracticeGuidelinesLoaded = useMemo(() => !!practiceGuidelinesData, [practiceGuidelinesData])

  const fetchPracticeGuidelines = useCallback(
    (force = false) => {
      if (force || !hasPracticeGuidelinesLoaded) {
        dispatch(Actions.fetchPracticeGuidelines())
      }
    },
    [dispatch, hasPracticeGuidelinesLoaded]
  )

  return {
    hiToolsAvailable,
    isLoadingPracticeGuidelines,
    hasPracticeGuidelinesLoaded,
    practiceGuidelines,
    hasAnyPracticeGuidelinesConfigured,
    fetchPracticeGuidelines
  }
}
