import * as React from 'react'

const Avatar = props => {
  return (
    <svg width="42" height="61" viewBox="0 0 42 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42 45.5349C42 56.0283 32.598 61 21 61C9.40202 61 0 56.0283 0 45.5349C0 35.0415 9.40202 23 21 23C32.598 23 42 35.0415 42 45.5349Z"
        fill="#3E5477"
      />
      <circle cx="21" cy="14" r="13" fill="#3E5477" stroke="#001335" strokeWidth="2" />
    </svg>
  )
}

export default Avatar
