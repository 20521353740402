import Actions from 'actions'
import { PATIENT_CARD_TABS } from 'consts/patientCard'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default () => {
  const dispatch = useDispatch()

  const patient = useSelector(state => state.patientsReducer.patient)
  const isMobile = useSelector(state => state.appReducer.mobile.isMobile)

  const openCard = useCallback(
    ({ tabIndex } = {}) => {
      dispatch(
        isMobile
          ? Actions.toggleMobilePatientCard({
              isModalOpen: true
            })
          : Actions.togglePatientCard({
              isModalOpen: true,
              patientId: patient.id,
              selectedTab: tabIndex ?? PATIENT_CARD_TABS.scanSettings.index
            })
      )
    },
    [isMobile, patient?.id, dispatch]
  )

  return openCard
}
