import * as React from 'react'

const Chevron = ({ fill = '#ABB0B8', style }) => {
  return (
    <svg width="7" height="13" viewBox="0 0 7 13" style={style} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.68656 6.49997C6.68656 6.63583 6.63463 6.77183 6.5309 6.87556L1.2184 12.1881C1.01081 12.3956 0.674666 12.3956 0.467213 12.1881C0.25976 11.9805 0.259627 11.6443 0.467213 11.4369L5.40412 6.49997L0.467212 1.56306C0.259626 1.35548 0.259626 1.01933 0.467212 0.811873C0.674798 0.604421 1.01095 0.604288 1.2184 0.811873L6.5309 6.12437C6.63463 6.2281 6.68656 6.3641 6.68656 6.49997Z"
        fill={fill}
      />
    </svg>
  )
}

export default Chevron
