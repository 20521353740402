import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import FormInput from 'components/common/FormInput'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { GuidelineCategories, PatientBriefActions } from 'consts/hiToolsConsts'
import TeethSelectionFlow from './Actions/TeethSelectionFlow'
import TeethSelectionPreview from './Actions/TeethSelectionPreview'
import UntrackedTeethSelectionPopup from './Actions/UntrackedTeethSelectionPopup'
import CategorySubtitle from './CategorySubtitle'

const useStyles = makeStyles(theme => ({
  wireSequenceInput: {
    margin: '0px!important'
  }
}))

const BracesTxTrackingCategory = ({ categoryValues = {}, onChange, viewMode }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container direction="column">
      <Grid item>
        <TeethSelectionFlow
          selections={categoryValues[PatientBriefActions.UntrackedTeeth]}
          setSelections={selections => onChange({ [PatientBriefActions.UntrackedTeeth]: selections })}
          addSelectionsButtonText={t('dialogs.patientBrief.actions.setUntrackedTeeth')}
          TeethSelectionPopupComponent={UntrackedTeethSelectionPopup}
          viewMode={viewMode}
          category={GuidelineCategories.BracesTxTracking}
          SelectionsPreviewComponent={({ selections }) => (
            <TeethSelectionPreview
              viewMode={viewMode}
              label={t('dialogs.patientBrief.untrackedTeethPopup.listPreviewLabel')}
              selections={selections}
            />
          )}
        />
      </Grid>
      <Grid item style={{ marginTop: viewMode && !categoryValues[PatientBriefActions.UntrackedTeeth] ? 0 : 14 }}>
        <CategorySubtitle viewMode>{t('dialogs.patientBrief.actions.wireSequence')}</CategorySubtitle>
        {viewMode ? (
          <DazzedParagraph14 strong>{categoryValues?.[PatientBriefActions.WireSequence]}</DazzedParagraph14>
        ) : (
          <FormInput
            value={categoryValues?.[PatientBriefActions.WireSequence] || ''}
            setValue={value => onChange({ [PatientBriefActions.WireSequence]: value })}
            style={{ bright: true, borderBottom: true }}
            keepErrorContainerWhenInactive={false}
            inputWrapperClassName={classes.wireSequenceInput}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default BracesTxTrackingCategory
