import { makeStyles } from '@material-ui/core/styles'
import React, { useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import useInViewport from '../../../hooks/useInViewport'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Actions from '../../../actions'
import GrinLogo from '../../../resources/logo.png'

const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
      flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
      background: 'var(--bg-color-30)',
      padding: ({ isMobile }) => (isMobile ? '72px 0' : '40px 90px'),
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    hipaaLogoContainer: {
      flex: 1
    },
    hipaaLogo: {
      width: 150,
      height: 80
    },
    middle: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: ({ isMobile }) => (isMobile ? '70px 0' : '0')
    },
    text: {
      color: 'white',
      fontSize: '20px',
      lineHeight: '40px'
    },
    logo: {
      width: 140,
      height: 54,
      marginLeft: 15
    },
    left: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    emailLink: {
      color: 'white!important',
      fontSize: 22,
      fontWeight: 600
    }
  },
  { isMobile }
)

export default () => {
  const classes = useStyles({ isMobile })
  const { t } = useTranslation()
  const ref = useRef()
  const dispatch = useDispatch()
  const { isInViewport } = useInViewport(ref)

  useEffect(() => {
    dispatch(Actions.setHelpWidgetVisibility(!isInViewport))
  }, [isInViewport, dispatch])

  return (
    <div className={classes.root} ref={ref}>
      <div className={classes.hipaaLogoContainer}>
        <img
          className={classes.hipaaLogo}
          src="https://d1g4lo0dz8n4nh.cloudfront.net/images/rc-landing-page/hipaa-compliance.png"
          alt="Hipaa compliancy"
        />
      </div>
      <div className={classes.middle}>
        <DazzedParagraph16 className={classes.text}>{t('pages.rcLanding.footer.poweredBy')}</DazzedParagraph16>
        <img className={classes.logo} src={GrinLogo} alt="Grin" />
      </div>
      <div className={classes.left}>
        <DazzedParagraph16 textAlign={isMobile ? 'center' : 'end'} className={classes.text}>
          {t('pages.rcLanding.footer.contactUs')}
        </DazzedParagraph16>
        <DazzedParagraph16 textAlign={isMobile ? 'center' : 'end'}>
          <a className={classes.emailLink} href={`mailto:${t('pages.rcLanding.footer.supportEmail')}`}>
            {t('pages.rcLanding.footer.supportEmail')}
          </a>
        </DazzedParagraph16>
      </div>
    </div>
  )
}
