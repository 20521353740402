import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  textInput: {
    marginBottom: 20,
    backgroundColor: '#fff',
    '& textarea': {
      textAlign: 'center'
    }
  }
}))

const SlideTextareaInput = ({ placeholder, errorMessage, onChange, value, rows = 4 }) => {
  const classes = useStyles()

  return (
    <TextField
      className={classes.textInput}
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
      error={!!errorMessage}
      helperText={errorMessage}
      variant="outlined"
      multiline
      rows={rows}
    />
  )
}

export default SlideTextareaInput
