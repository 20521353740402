import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { normalizeProgram } from 'utils/programUtils'
import {
  ORTHO_PROGRAM,
  REMOTE_MONITORING_PROGRAM,
  REMOTE_CONSULTATION_PROGRAM,
  SERVICE_ACCOUNT_PROGRAM
} from 'consts/appConsts'

const useStyles = () =>
  makeStyles({
    content: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Dazzed',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 500,
      textTransform: 'uppercase'
    },
    tag: {
      borderRadius: '5px',
      padding: '2px 4px'
    },
    rm: {
      backgroundColor: 'var(--bg-color-22)',
      color: 'var(--text-color-1)'
    },
    rc: {
      backgroundColor: 'var(--bg-color-25)',
      color: 'var(--text-color-15)'
    },
    ortho: {
      backgroundColor: 'var(--bg-color-26)',
      color: 'var(--text-color-1)'
    },
    serviceAccount: {
      backgroundColor: 'var(--bg-color-22)',
      color: 'var(--text-color-1)',
      textTransform: 'none !important'
    }
  })()

const ProgramChip = ({ program = '' }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const normalizedProgram = useMemo(() => normalizeProgram(program, t), [program, t])

  return (
    <div className={classes.content}>
      <div
        className={[
          classes.tag,
          normalizedProgram === REMOTE_CONSULTATION_PROGRAM && classes.rc,
          normalizedProgram === REMOTE_MONITORING_PROGRAM && classes.rm,
          normalizedProgram === ORTHO_PROGRAM && classes.ortho,
          program === SERVICE_ACCOUNT_PROGRAM && classes.serviceAccount
        ].join(' ')}
      >
        {normalizedProgram}
      </div>
    </div>
  )
}

export default React.memo(ProgramChip)
