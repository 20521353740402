import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import ScanThumbnail from '../../ScanThumbnail'
import { getObjectUrl } from 'utils/mediaUtils'
import useScanStats from '../../useScanStats'
import { trackEvent } from 'utils/analyticsUtils'
import TrackStatusTag from '../../TrackStatusTag'
import { ScanFeedPageSectionNames } from 'consts/timelineConsts'
import ScanStats from '../../ScanStats'
import ScanDateLabel from '../../ScanDateLabel'
import Divider from './Divider'

const useStyles = makeStyles(theme => ({
  grinScanTimelineItemRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 0px 8px',
    borderRadius: 10,
    '&:hover': {
      background: '#eaf3fd',
      cursor: 'pointer',
      transition: '0.3s'
    }
  },
  scanDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  scanInfo: {
    display: 'flex',
    marginBottom: 10
  },
  alignerNumber: {
    marginLeft: 8
  },
  trackStatus: {
    marginTop: 10
  },
  scansContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  scanVideos: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  thumbnailContainer: {
    width: 177,
    height: 99,
    marginLeft: 5
  },
  scanDate: {
    marginTop: 15
  }
}))

const GrinScanTimelineItem = ({ timelineItem, onSelect = () => {} }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { numberOfReviews, numberOfShares } = useScanStats({ timelineItem })

  const { scanNumber, alignerNumber, scans, trackStatus } = useMemo(() => timelineItem?.payload, [timelineItem])

  const timelineItemOffTrackReasons = useMemo(
    () =>
      scans
        .map(scan => scan?.offTrackReasons)
        .filter(reasons => !!reasons)
        .reduce((final, currReasons) => [...final, ...currReasons], []),
    [scans]
  )

  const handleReviewsClicked = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      onSelect({ section: ScanFeedPageSectionNames.Reviews })
      trackEvent('Timeline - Main feed - Scan item - Reviews clicked')
    },
    [onSelect]
  )

  const handleSharesClicked = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      onSelect({ section: ScanFeedPageSectionNames.Shares })
      trackEvent('Timeline - Main feed - Scan item - Shares clicked')
    },
    [onSelect]
  )

  const handleTrackingReasonsClicked = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      onSelect({ section: ScanFeedPageSectionNames.ScanTracking })
      trackEvent('Timeline - Main feed - Scan item - Tracking reasons clicked')
    },
    [onSelect]
  )

  return (
    <div>
      <div className={classes.grinScanTimelineItemRoot} onClick={onSelect}>
        <div className={classes.scanDetails}>
          <div className={classes.scanInfo}>
            <DazzedParagraph14 strong>
              {t('pages.patients.selectedPatient.timelineV2.mainFeed.grinScanItem.title', { scanNumber })}
            </DazzedParagraph14>
            {alignerNumber && (
              <DazzedParagraph14 className={classes.alignerNumber}>
                {t('pages.patients.selectedPatient.timelineV2.mainFeed.grinScanItem.alignerNumber', { alignerNumber })}
              </DazzedParagraph14>
            )}
          </div>
          <ScanStats
            numberOfReviews={numberOfReviews}
            numberOfShares={numberOfShares}
            onReviewsClicked={handleReviewsClicked}
            onSharesClicked={handleSharesClicked}
          />
          {trackStatus && (
            <div className={classes.trackStatus}>
              <TrackStatusTag
                trackStatus={trackStatus}
                reasons={timelineItemOffTrackReasons}
                reasonsOnClick={handleTrackingReasonsClicked}
              />
            </div>
          )}
        </div>
        <div className={classes.scansContainer}>
          <div className={classes.scanVideos}>
            {scans.map(scan => (
              <ScanThumbnail
                key={`scan-thumbnail-${scan.id}`}
                mediaType="image"
                src={getObjectUrl(scan.thumbnail)}
                renderAlignerIconOverlay={scan?.withAligner !== null}
                withAligner={scan?.withAligner}
                containerClassName={classes.thumbnailContainer}
              />
            ))}
          </div>
          <ScanDateLabel
            timestamp={timelineItem?.eventDate}
            color="var(--text-color-11)"
            className={classes.scanDate}
          />
        </div>
      </div>
      <Divider />
    </div>
  )
}

export default React.memo(GrinScanTimelineItem)
