import Actions from 'actions'
import { US_STATES, DEFAULT_COUNTRY } from 'consts/countryConsts'
import { changeAuthForm } from 'utils/authUtils'

const initialState = {
  isLoading: false,
  errors: {},
  name: '',
  degrees: null,
  emailAddress: '',
  password: '',
  passwordConfirmation: '',
  username: null,
  practiceName: '',
  address: '',
  address2: '',
  city: '',
  state: US_STATES[0].name,
  country: DEFAULT_COUNTRY.key,
  zip: '',
  phoneValue: '',
  countryCode: '',
  practicePhoneValue: '',
  practiceCountryCode: '',
  practiceEmailAddress: '',
  clinicHomepageUrl: '',
  hasLocatorConsent: true,
  bio: '',
  roleDescription: '',
  clinicLogo: null,
  photo: null,
  firstName: '',
  lastName: '',
  billingInfo: {
    paymentMethodId: '',
    cardLast4: '',
    cardBrand: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.QUICK_SIGN_UP_FORM_CHANGED:
      const newValues = changeAuthForm(action.payload)
      return {
        ...state,
        ...newValues
      }
    case Actions.REQUEST_QUICK_DOCTOR_SIGN_UP:
    case Actions.VERIFY_DOCTOR_EMAIL:
      return {
        ...state,
        isLoading: true
      }
    case Actions.QUICK_DOCTOR_SIGN_UP_RECEIVED:
      return {
        ...state,
        username: action.payload.username
      }

    case Actions.VERIFY_DOCTOR_EMAIL_FAILED:
    case Actions.QUICK_DOCTOR_SIGN_UP_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.VERIFY_DOCTOR_EMAIL_RECEIVED:
      return {
        ...state,
        isLoading: false,
        errors: initialState.errors
      }
    case Actions.UPDATE_QUICK_SIGNUP_ERRORS:
      return {
        ...state,
        isLoading: false,
        errors: { ...action.payload }
      }
    case Actions.CHANGE_QUICK_SIGN_UP_BILLING_FORM:
      const newBillingValues = changeAuthForm(action.payload)
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          ...newBillingValues
        }
      }
    case Actions.FINISH_QUICK_SIGN_UP:
      return initialState
    default:
      return state
  }
}
