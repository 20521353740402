import * as React from 'react'
const PressKit = ({ ...props }) => {
  return (
    <svg width="102" height="93" viewBox="0 0 102 93" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="33" y="5" width="36" height="4" fill="#D9DCFA" />
      <rect x="73" y="13" width="19" height="4" fill="#D9DCFA" />
      <rect x="33" y="12" width="36" height="4" fill="#D9DCFA" />
      <rect x="73" y="20" width="19" height="4" fill="#D9DCFA" />
      <path d="M47 19H69V23H50.5L47 19Z" fill="#D9DCFA" />
      <path d="M47 26H69V30H50.5L47 26Z" fill="#D9DCFA" />
      <path
        d="M25 21.1522C25 19.4113 26.4113 18 28.1522 18H44.7922C45.6758 18 46.5188 18.3708 47.1158 19.0222L54.994 27.6165C55.591 28.2678 56.434 28.6387 57.3176 28.6387H97.1066C98.8475 28.6387 100.259 30.05 100.259 31.7909V71.1934C100.259 72.9343 98.8475 74.3456 97.1066 74.3456H28.1522C26.4113 74.3456 25 72.9343 25 71.1934V21.1522Z"
        fill="#D9DCFA"
        stroke="#4151E6"
        strokeWidth="1.5"
      />
      <path d="M29 18V3C29 1.89543 29.8954 1 31 1H71C72.1046 1 73 1.89543 73 3V28.5" stroke="#4151E6" />
      <path d="M96 28.5V11C96 9.89543 95.1046 9 94 9H73" stroke="#4151E6" />
      <path
        d="M5.1626 62.016C5.1626 60.5511 6.35017 59.3635 7.81511 59.3635H14.8453C16.3102 59.3635 17.4978 60.5511 17.4978 62.016V77.6379C17.4978 79.1029 16.3102 80.2904 14.8453 80.2904H7.81511C6.35017 80.2904 5.1626 79.1029 5.1626 77.6379V62.016Z"
        fill="white"
        stroke="#4151E6"
        strokeWidth="1.5"
      />
      <path
        d="M1 67.079C1 65.6141 2.18757 64.4265 3.65251 64.4265H5.05037V75.2275H3.65251C2.18757 75.2275 1 74.0399 1 72.575V67.079Z"
        fill="white"
        stroke="#4151E6"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.813 78.4836C17.6343 78.4615 17.4982 78.31 17.4982 78.13V61.524C17.4982 61.3439 17.6343 61.1925 17.813 61.1704C28.4527 59.8562 36.8876 54.7577 39.3022 48.2373C39.3547 48.0955 39.491 48 39.6422 48C39.8381 48 40.0002 48.1588 40.0002 48.3547V91.4323C40.0002 91.6169 39.8506 91.7665 39.6661 91.7665C39.5224 91.7665 39.3954 91.6749 39.347 91.5396C36.9934 84.9604 28.5195 79.806 17.813 78.4836Z"
        fill="white"
      />
      <path
        d="M39.347 91.5396L38.6408 91.7923L39.347 91.5396ZM39.3022 48.2373L40.0056 48.4977L39.3022 48.2373ZM17.813 78.4836L17.721 79.2279L17.813 78.4836ZM16.7482 61.524V78.13H18.2482V61.524H16.7482ZM38.5989 47.9768C37.4677 51.0314 34.8984 53.8183 31.2549 56.0138C27.6171 58.2059 22.952 59.78 17.721 60.4261L17.9049 61.9148C23.3136 61.2467 28.1859 59.6144 32.0291 57.2986C35.8667 54.9861 38.7221 51.9636 40.0056 48.4977L38.5989 47.9768ZM40.7502 91.4323V48.3547H39.2502V91.4323H40.7502ZM17.721 79.2279C22.9849 79.8781 27.6756 81.4679 31.3232 83.6815C34.9765 85.8985 37.5389 88.7119 38.6408 91.7923L40.0532 91.287C38.8015 87.7882 35.9501 84.7347 32.1014 82.3991C28.247 80.0601 23.3475 78.4115 17.9049 77.7392L17.721 79.2279ZM39.6661 91.0165C39.8469 91.0165 39.9974 91.1313 40.0532 91.287L38.6408 91.7923C38.7933 92.2186 39.1979 92.5165 39.6661 92.5165V91.0165ZM39.2502 91.4323C39.2502 91.2027 39.4364 91.0165 39.6661 91.0165V92.5165C40.2648 92.5165 40.7502 92.0311 40.7502 91.4323H39.2502ZM39.6422 48.75C39.4332 48.75 39.2502 48.5823 39.2502 48.3547H40.7502C40.7502 47.7353 40.243 47.25 39.6422 47.25V48.75ZM40.0056 48.4977C39.9499 48.6481 39.807 48.75 39.6422 48.75V47.25C39.1751 47.25 38.7596 47.5429 38.5989 47.9768L40.0056 48.4977ZM16.7482 78.13C16.7482 78.6951 17.1729 79.1602 17.721 79.2279L17.9049 77.7392C18.0956 77.7628 18.2482 77.925 18.2482 78.13H16.7482ZM18.2482 61.524C18.2482 61.729 18.0956 61.8912 17.9049 61.9148L17.721 60.4261C17.1729 60.4938 16.7482 60.9589 16.7482 61.524H18.2482Z"
        fill="#4151E6"
      />
      <path
        d="M8.95752 81H15.5888L18.8956 89.8181C19.2207 90.6852 18.5798 91.6101 17.6538 91.6101H13.8554C13.3025 91.6101 12.8077 91.2671 12.6136 90.7495L8.95752 81Z"
        fill="white"
        stroke="#4151E6"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default PressKit
