import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import Product from './Product'
import { scrollBar } from 'styles/theme'

const useStyles = makeStyles(() => ({
  container: {
    overflowY: 'hidden',
    overflowX: 'auto',
    padding: '0 5px 20px 10px',
    ...scrollBar,
    '&::-webkit-scrollbar': {
      height: 5
    }
  },
  product: {
    marginRight: 5,
    width: 204,
    height: 343
  }
}))

const SelectedCategory = ({ category, onSelect }) => {
  const [galleryIndex, setGalleryIndex] = useState(0)

  const classes = useStyles()

  useEffect(() => {
    setGalleryIndex(0)
  }, [category])

  return (
    <>
      {!!category && (
        <Grid item>
          <Grid container direction="row" wrap="nowrap" className={classes.container}>
            {category?.products?.map(currProduct => (
              <Grid item key={currProduct.title + galleryIndex} className={classes.product}>
                <Product product={currProduct} onClick={() => onSelect(currProduct)} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default SelectedCategory
