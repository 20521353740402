import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../common/buttons/PrimaryButton'
import SecondaryButton from '../common/buttons/SecondaryButton'
import SvgSwoosh from '../common/icons/Swoosh'
import SvgSwooshEllipse from '../common/icons/SwooshEllipse'
import Actions from '../../actions'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles(theme => ({
  modal: {
    padding: 16,
    overflow: 'hidden',
    borderRadius: '20px'
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: isMobile() ? 35 : 40,
    lineHeight: '40px',
    fontFamily: 'Ogg',
    textAlign: 'center',
    marginBottom: 10,
    position: 'relative',
    zIndex: 100
  },
  content: {
    fontSize: 16,
    lineHeight: '24px',
    marginBottom: 16
  },
  actions: {
    marginTop: 5,
    marginBottom: 5,
    maxWidth: isMobile() ? '100%' : '80%',
    margin: '0 auto'
  },
  swoosh: {
    position: 'absolute',
    top: 30,
    left: 0
  },
  swooshEllipse: {
    position: 'absolute',
    bottom: -130,
    right: -71
  }
}))

const ForceRefreshModal = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.appReducer.isForceRefreshModalOpen)
  const { t } = useTranslation()

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      classes={{
        paper: classes.modal
      }}
    >
      <DialogTitle>
        <div className={classes.title}>{t('dialogs.forceRefresh.title')}</div>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div>
          <SvgSwoosh className={classes.swoosh} />
          <SvgSwooshEllipse className={classes.swooshEllipse} />
        </div>
        {t('dialogs.forceRefresh.body')}
      </DialogContent>
      <Grid
        container
        justifyContent="center"
        direction={isMobile() ? 'column-reverse' : 'row'}
        spacing={1}
        className={classes.actions}
      >
        <Grid item md={6} xs={12}>
          <SecondaryButton
            label={t('dialogs.forceRefresh.later')}
            onClick={() => dispatch(Actions.dismissForceRefresh())}
            width="100%"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <PrimaryButton
            label={t('dialogs.forceRefresh.refresh')}
            onClick={() => window.location.reload()}
            width="100%"
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default ForceRefreshModal
