import React, { useCallback, useState, useEffect } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ServicesClinicLogo from './ServicesClinicLogo'
import EditableLabel from '../../common/text/EditableLabel'
import DazzedHeading16 from '../../common/text/DazzedHeading16'

import Actions from '../../../actions'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 25
  },
  titleContainer: {
    paddingLeft: 22
  },
  title: {
    marginBottom: 5
  },
  homepageUrl: {
    fontSize: '14px !important'
  }
}))

const ServicesHeader = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const doctor = useSelector(state => state.practiceReducer.accountOwner)
  const practiceDetails = useSelector(state => state.practiceReducer.details)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(false)
  }, [doctor, practiceDetails])

  const updateClinicLogo = useCallback(
    key =>
      dispatch(
        Actions.updateClinicLogo({
          doctor,
          key: key[0]
        })
      ),
    [dispatch, doctor]
  )

  const handleSaveHomepageUrl = useCallback(
    ({ homepageUrl }) =>
      dispatch(
        Actions.requestDoctorDetailsUpdate({
          ...doctor,
          clinic: {
            ...practiceDetails,
            homepageUrl
          }
        })
      ),
    [dispatch, practiceDetails, doctor]
  )

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item>
          <ServicesClinicLogo
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            clinicLogoS3Obj={practiceDetails.logo}
            updateClinicLogo={updateClinicLogo}
          />
        </Grid>

        <Grid item className={classes.titleContainer} xs>
          <DazzedHeading16 className={classes.title}>
            {t('pages.accountSettings.services.name', { doctor: doctor.name, clinic: practiceDetails.practiceName })}
          </DazzedHeading16>
          <EditableLabel
            className={classes.homepageUrl}
            value={practiceDetails.homepageUrl}
            handleSave={handleSaveHomepageUrl}
            fieldName="homepageUrl"
            allTextClickable
            allowSetEmptyValue
            placeholder={t('pages.accountSettings.services.editYourWebSiteLink')}
            validationRegex={/^$|http:\/\/|https:\/\//i}
            errorMessage={t('pages.accountSettings.services.remoteConsultation.urlValidationError')}
            valuePrefix="https://"
            minInputWith={250}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ServicesHeader
