import React, { useCallback, useMemo, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import GrinCheckbox from 'components/common/GrinCheckbox'
import SelectInput from 'components/common/SelectInput'
import FormInput from 'components/common/FormInput'
import { ANNOUNCEMENTS_MODAL_Z_INDEX } from 'consts/modalConsts'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import OggHeading40 from 'components/common/text/OggHeading40'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../actions'

const useStyles = makeStyles({
  modalContainer: {
    zIndex: `${ANNOUNCEMENTS_MODAL_Z_INDEX} !important`
  },
  title: { whiteSpace: 'pre-wrap' },
  announcementImage: {
    width: '100%',
    minWidth: '100%',
    marginBottom: 30
  },
  formInputClosed: {
    marginBottom: 100
  },
  question: {
    marginBottom: 10
  },
  contentWrapper: {
    padding: '0 0px 40px 0px !important',
    overflow: 'hidden'
  },
  container: {
    padding: '0 48px 0px 48px !important'
  },
  closeButtonContainer: {
    paddingTop: 80
  },
  inputContainer: { paddingTop: 10, marginBottom: 35 },
  inputWrapper: {
    backgroundColor: 'transparent !important',
    border: 'none !important',
    borderBottom: '1px solid var(--border-color-2) !important'
  }
})

const SurveyAnnouncementsModal = ({ isOpen, handleModalClose, content, announcementType, handleActionClicked }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [dontShowAgain, setDontShowAgain] = useState(false)
  const [surveyResultKey, setSurveyResultKey] = useState(null)
  const [surveyResult, setSurveyResult] = useState(null)

  const { isLoadingSurveyResults, surveyResultSuccess, surveyResultError } = useSelector(
    state => state.announcmentsReducer
  )

  let {
    image,
    surveyType,
    title,
    question,
    responsePlaceholder,
    responseOptions,
    allowFreeTextResponse,
    freeTextResponsePlaceholder
  } = content?.[0]

  const responseOptionsForSurvey = useMemo(() => {
    return allowFreeTextResponse ? [...responseOptions, t('announcements.survey.otherOptionText')] : responseOptions
  }, [allowFreeTextResponse, responseOptions, t])

  const onSubmitResponse = useCallback(() => {
    dispatch(Actions.submitSurveyResult({ surveyResult, surveyType }))
  }, [dispatch, surveyResult, surveyType])

  const onClosePressed = useCallback(() => {
    handleModalClose(dontShowAgain)
    dispatch(Actions.resetSurveyResult())
  }, [dontShowAgain, handleModalClose, dispatch])

  const renderSurveyResponsePicker = useCallback(() => {
    return (
      <>
        <Grid className={surveyResultKey !== t('announcements.survey.otherOptionText') && classes.formInputClosed} item>
          <SelectInput
            style={{ bright: true, thick: true }}
            value={surveyResultKey}
            values={responseOptionsForSurvey}
            errorMessage={t(surveyResultError)}
            keepErrorContainerWhenInactive={false}
            placeholder={t(responsePlaceholder)}
            onChange={({ value: key }) => {
              setSurveyResultKey(key)
              setSurveyResult(key !== t('announcements.survey.otherOptionText') ? key : null)
            }}
          />
        </Grid>
        {surveyResultKey === t('announcements.survey.otherOptionText') && (
          <Grid item>
            <FormInput
              inputWrapperClassName={classes.inputWrapper}
              containerClassName={classes.inputContainer}
              style={{ bright: true, thick: true }}
              placeholder={t(freeTextResponsePlaceholder)}
              validationRules={{ required: false }}
              value={surveyResult}
              setValue={value => setSurveyResult(value?.trim().length !== 0 ? value : null)}
            />
          </Grid>
        )}
        <Grid item>
          <Grid container spacing={4} direction="row">
            <Grid item>
              <GrinCheckbox
                caption={t('announcements.dontShowAgainOn')}
                checked={dontShowAgain}
                onChange={value => setDontShowAgain(value)}
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                onClick={onSubmitResponse}
                label={t('announcements.survey.submit')}
                isLoading={isLoadingSurveyResults}
                type="submit"
                disabled={!surveyResult}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }, [
    classes,
    dontShowAgain,
    freeTextResponsePlaceholder,
    responsePlaceholder,
    surveyResult,
    surveyResultKey,
    surveyResultError,
    onSubmitResponse,
    responseOptionsForSurvey,
    isLoadingSurveyResults,
    t
  ])

  return (
    <BaseModal
      className={classes.modalContainer}
      open={isOpen}
      handleClose={onClosePressed}
      withCloseIcon={true}
      contentClassName={classes.contentWrapper}
    >
      <Grid direction="column" container spacing={2}>
        <img className={classes.announcementImage} src={image} alt="" />
        {
          <div className={classes.container}>
            <Grid item>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <OggHeading40 className={classes.title}>
                    {surveyResultSuccess ? t('announcements.survey.submitResponseSuccessTitle') : t(title)}
                  </OggHeading40>
                </Grid>
                {!surveyResultSuccess && (
                  <Grid item>
                    <DazzedParagraph16 className={classes.question}>{t(question)}</DazzedParagraph16>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {!surveyResultSuccess ? (
              renderSurveyResponsePicker()
            ) : (
              <Grid className={classes.closeButtonContainer} item>
                <Grid justifyContent="center" direction="row" container>
                  <Grid item>
                    <PrimaryButton
                      onClick={onClosePressed}
                      label={t('announcements.survey.close')}
                      isLoading={false}
                      type="submit"
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
        }
      </Grid>
    </BaseModal>
  )
}

export default SurveyAnnouncementsModal
