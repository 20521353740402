const REMOVED_FROM_LOGS = 'REMOVED FROM LOGS'

export const mapStateToLog = state => ({
  ...state,
  billingReducer: {
    ...state.billingReducer,
    paymentMethod: REMOVED_FROM_LOGS,
    transactions: state.billingReducer.transactions.list?.map(t => t.id)
  },
  chatReducer: {
    rooms: state.chatReducer.rooms.map(r => ({
      roomId: r.id,
      patient: r.email
    }))
  },
  authReducer: {
    ...state.authReducer,
    password: REMOVED_FROM_LOGS,
    passwordConfirmation: REMOVED_FROM_LOGS
  }
})

export const extractUserDataFromState = state => ({
  user: {
    doctorId: state.profileReducer.doctor?.id,
    email: state.profileReducer.doctor?.email,
    username: state.profileReducer.doctor?.username
  }
})

export const mapErrorPayload = error => {
  if (!error) {
    return {}
  }

  if (error.response) {
    return {
      statusCode: error.response.status,
      data: error.response.data,
      requestUrl: error.response.config?.url,
      path: error.response.config?.path,
      host: error.response.config?.host,
      method: error.response.config?.method
    }
  }

  if (error instanceof Error) {
    return {
      message: error.message,
      stack: error.stack,
      name: error.name
    }
  }

  return error
}
