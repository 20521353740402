import * as React from 'react'

const UploadPracticeLogoIllustration = ({ style }) => (
  <svg width="75" height="56" viewBox="0 0 75 56" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <rect width="75" height="56" rx="4" fill="#E1E5FE" />
    <path
      opacity="0.3"
      d="M4 5C4 4.44771 4.44772 4 5 4H23C23.5523 4 24 4.44772 24 5V22C24 22.5523 23.5523 23 23 23H5C4.44772 23 4 22.5523 4 22V5Z"
      fill="#8FB4FF"
    />
    <path
      opacity="0.3"
      d="M4 27C4 26.4477 4.44772 26 5 26H23C23.5523 26 24 26.4477 24 27V37C24 37.5523 23.5523 38 23 38H5C4.44772 38 4 37.5523 4 37V27Z"
      fill="#8FB4FF"
    />
    <path d="M27 26H47V33H27V26Z" fill="#3C52EF" />
    <path
      opacity="0.3"
      d="M51 27C51 26.4477 51.4477 26 52 26H70C70.5523 26 71 26.4477 71 27V37C71 37.5523 70.5523 38 70 38H52C51.4477 38 51 37.5523 51 37V27Z"
      fill="#8FB4FF"
    />
    <path
      opacity="0.3"
      d="M4 42C4 41.4477 4.44772 41 5 41H23C23.5523 41 24 41.4477 24 42V52C24 52.5523 23.5523 53 23 53H5C4.44772 53 4 52.5523 4 52V42Z"
      fill="#8FB4FF"
    />
    <path
      opacity="0.3"
      d="M51 42C51 41.4477 51.4477 41 52 41H70C70.5523 41 71 41.4477 71 42V52C71 52.5523 70.5523 53 70 53H52C51.4477 53 51 52.5523 51 52V42Z"
      fill="#8FB4FF"
    />
    <path
      opacity="0.3"
      d="M27 42C27 41.4477 27.4477 41 28 41H47C47.5523 41 48 41.4477 48 42V52C48 52.5523 47.5523 53 47 53H28C27.4477 53 27 52.5523 27 52V42Z"
      fill="#8FB4FF"
    />
    <path
      opacity="0.3"
      d="M27 5C27 4.44771 27.4477 4 28 4H47C47.5523 4 48 4.44772 48 5V22C48 22.5523 47.5523 23 47 23H28C27.4477 23 27 22.5523 27 22V5Z"
      fill="#8FB4FF"
    />
    <path
      opacity="0.3"
      d="M51 5C51 4.44771 51.4477 4 52 4H70C70.5523 4 71 4.44772 71 5V22C71 22.5523 70.5523 23 70 23H52C51.4477 23 51 22.5523 51 22V5Z"
      fill="#8FB4FF"
    />
  </svg>
)

export default UploadPracticeLogoIllustration
