import * as THREE from 'three'
import React, { useCallback, useMemo, useState } from 'react'
import { loadArchModels } from './archesViewerUtils'
import Grin3DViewer from 'components/common/Grin3DViewer/Grin3DViewer'
import { Grid, makeStyles } from '@material-ui/core'
import ViewerControls from './ViewerControls'
import { useTranslation } from 'react-i18next'
import { ORIGIN_ARHC_MODELS_TYPES } from './archesViewerConsts'
import { TreatmentTypes } from 'consts/treatmentConsts'
import PoseController from './PoseController'

const useStyles = makeStyles(theme => ({
  viewerContainer: {
    padding: 20,
    height: '100%'
  },
  regularViewer: {
    width: '65%',
    height: '90%'
  },
  occlusalViewer: {
    width: '35%',
    height: '90%',
    borderRight: '1px solid var(--border-color-37)'
  }
}))

const ArchesViewer = ({
  baseArchModels,
  currentArchModels,
  endArchModels,
  currentTxTrackerData,
  treatmentType,
  scanNumber,
  alignerNumber,
  isSmallerScreen = false
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [regularViewerCamera, setRegularViewerCamera] = useState()
  const [occlusalViewerCamera, setOcclusalViewerCamera] = useState()

  const [viewerSettings, setViewerSettings] = useState({
    isBaseLayerVisible: true,
    isCurrentLayerVisible: true
  })

  const [selectedOriginModelType, setSelectedOriginModelType] = useState(
    treatmentType === TreatmentTypes.Aligners ? ORIGIN_ARHC_MODELS_TYPES.CURRENT : ORIGIN_ARHC_MODELS_TYPES.END
  )

  const originModelOptions = useMemo(
    () =>
      [
        {
          type: ORIGIN_ARHC_MODELS_TYPES.BASE,
          value: baseArchModels,
          label: t('dialogs.treatmentTracker.viewerControls.base'),
          condition: () => true
        },
        {
          type: ORIGIN_ARHC_MODELS_TYPES.CURRENT,
          value: currentArchModels,
          label: t('dialogs.treatmentTracker.viewerControls.current'),
          condition: () => treatmentType === TreatmentTypes.Aligners
        },
        {
          type: ORIGIN_ARHC_MODELS_TYPES.END,
          value: endArchModels,
          label: t('dialogs.treatmentTracker.viewerControls.end'),
          condition: () => true
        }
      ].filter(originModelOption => originModelOption.condition()),
    [baseArchModels, currentArchModels, endArchModels, t, treatmentType]
  )

  const viewerCameraSettings = useMemo(
    () => ({
      occlusal: {
        zoom: isSmallerScreen ? 2.3 : 3,
        cameraTopOffset: isSmallerScreen ? 100 : 80
      }
    }),
    [isSmallerScreen]
  )
  const selectedOriginModel = useMemo(
    () => originModelOptions.find(option => option.type === selectedOriginModelType).value,
    [originModelOptions, selectedOriginModelType]
  )
  const archesData = useMemo(
    () =>
      [
        {
          file: selectedOriginModel.upper,
          isUpper: true,
          isBase: true
        },
        {
          file: selectedOriginModel.lower,
          isUpper: false,
          isBase: true
        },
        {
          file: currentTxTrackerData.archModels.upperS3Keys.currentActualStl,
          isUpper: true,
          isBase: false
        },
        {
          file: currentTxTrackerData.archModels.lowerS3Keys.currentActualStl,
          isUpper: false,
          isBase: false
        }
      ].filter(
        arch =>
          (arch.isBase && viewerSettings.isBaseLayerVisible) || (!arch.isBase && viewerSettings.isCurrentLayerVisible)
      ),
    [selectedOriginModel, currentTxTrackerData, viewerSettings]
  )

  const handleViewerSceneLoaded = useCallback(
    ({ scene, camera }) => {
      loadArchModels({
        scene,
        archesData,
        teethData: currentTxTrackerData.teeth,
        treatmentType,
        upperArchInitRotation: { x: Math.PI * 1.5, y: 0, z: 0 }, // Rotate model on X axis to be displayed from the front
        lowerArchInitRotation: { x: Math.PI * 1.5, y: 0, z: 0 }
      })

      setRegularViewerCamera(camera)
    },
    [archesData, currentTxTrackerData, treatmentType]
  )

  const handleOcclusalViewerSceneLoaded = useCallback(
    ({ scene, camera }) => {
      loadArchModels({
        scene,
        archesData,
        teethData: currentTxTrackerData.teeth,
        treatmentType,
        upperArchInitRotation: currentTxTrackerData.needsRotation
          ? { x: Math.PI, y: 0, z: (-10 * Math.PI) / 180 }
          : { x: Math.PI, y: 0, z: Math.PI / 180 },
        lowerArchInitRotation: currentTxTrackerData.needsRotation
          ? { x: 0, y: 0, z: (-10 * Math.PI) / 180 }
          : { x: 0, y: 0, z: Math.PI / 180 },
        upperArchOffset: 110
      })

      setOcclusalViewerCamera(camera)
    },
    [archesData, currentTxTrackerData, treatmentType]
  )

  return (
    <Grid container justifyContent="space-between" className={classes.viewerContainer}>
      <Grid item xs={12}>
        <ViewerControls
          viewerSettings={viewerSettings}
          setViewerSettings={setViewerSettings}
          selectedOriginModelType={selectedOriginModelType}
          setSelectedOriginModelType={setSelectedOriginModelType}
          originModelOptions={originModelOptions}
          scanNumber={scanNumber}
          alignerNumber={alignerNumber}
        />
      </Grid>
      <Grid item className={classes.occlusalViewer}>
        <Grin3DViewer
          onSceneLoaded={handleOcclusalViewerSceneLoaded}
          zoom={viewerCameraSettings.occlusal.zoom}
          zoomOnly
          cameraTopOffset={viewerCameraSettings.occlusal.cameraTopOffset}
          zCameraPosition={140}
          customControls={{
            LEFT: THREE.MOUSE.PAN
          }}
        />
      </Grid>
      <Grid item className={classes.regularViewer}>
        <Grin3DViewer onSceneLoaded={handleViewerSceneLoaded} />
      </Grid>
      {(regularViewerCamera || occlusalViewerCamera) && (
        <Grid item xs={12}>
          <PoseController cameras={[regularViewerCamera, occlusalViewerCamera]} />
        </Grid>
      )}
    </Grid>
  )
}

export default ArchesViewer
