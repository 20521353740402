import React from 'react'
import { useTranslation } from 'react-i18next'
import GiResolve from 'components/common/icons/gi/GiResolve'
import ActionButton from '../Common/ActionButton'

const GiResolveButton = ({ onClick, isLoading = false }) => {
  const { t } = useTranslation()

  return (
    <ActionButton
      icon={<GiResolve />}
      label={t('pages.patients.selectedPatient.chat.gi.markAsDone')}
      onClick={onClick}
      isLoading={isLoading}
    />
  )
}

export default GiResolveButton
