import * as React from 'react'

export default () => {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 1L1 6L6 11" stroke="#001335" />
      <path d="M1.5 6H17.5" stroke="#001335" />
    </svg>
  )
}
