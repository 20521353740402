import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import PatientBriefFloatingPopup from '../../../PatientBriefFloatingPopup'
import ElasticListItem from './ElasticListItem'
import useElasticsEditor, { Modes } from './useElasticsEditor'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import LinkButton from 'components/common/buttons/LinkButton'
import ElasticsTeethSelectionGrid from './ElasticsTeethSelectionGrid'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 992
  },
  teethSelectionGridContainer: {
    border: '1px solid var(--border-color-7)',
    borderRadius: 7
  },
  teethGridRow: {
    margin: '10px'
  },
  descriptionText: {
    color: 'var(--text-color-11)'
  }
}))

const SetupElasticsPopup = ({ isOpen, setIsOpen, anchorEl, selections = [], setSelections }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    draftElastics,
    setDraftElastics,
    mode,
    elasticsPreview,
    modifiedElasticIndex,
    addElastic,
    cancelEdit,
    saveModifiedElastic,
    handleToothClick,
    removeElastic,
    enterEditMode
  } = useElasticsEditor()

  const [didAddInitialElastic, setDidAddInitialElastic] = useState(false)

  const handleConfirm = useCallback(() => {
    trackEvent('Patient brief - elastics saved', { numberOfElastics: draftElastics.length })
    setSelections(draftElastics)
    setIsOpen(false)
  }, [setSelections, setIsOpen, draftElastics])

  useEffect(() => {
    if (!isOpen) {
      setDraftElastics(selections)
      setDidAddInitialElastic(false)
    }
  }, [isOpen, selections, setDraftElastics])

  useEffect(() => {
    if (isOpen && !draftElastics.length && !selections.length && !didAddInitialElastic) {
      addElastic()
      setDidAddInitialElastic(true)
    }
  }, [isOpen, draftElastics, selections]) //eslint-disable-line

  return (
    <PatientBriefFloatingPopup
      isOpen={isOpen}
      anchorEl={anchorEl}
      title={t('dialogs.patientBrief.setupElasticsPopup.title')}
      onCancel={() => setIsOpen(false)}
      onConfirm={handleConfirm}
      isPrimaryBtnDisabled={mode === Modes.Edit}
      className={classes.root}
    >
      <Grid container direction="column" spacing={1}>
        {mode === Modes.Edit && (
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <DazzedParagraph14 strong className={classes.descriptionText}>
                  {t('dialogs.patientBrief.setupElasticsPopup.editModeDescription')}
                </DazzedParagraph14>
              </Grid>
              <Grid item style={{ marginLeft: 24 }}>
                <LinkButton label={t('general.cancel')} onClick={cancelEdit} underline fontSize={14} />
              </Grid>
              <Grid item style={{ marginLeft: 16 }}>
                <LinkButton label={t('general.done')} onClick={saveModifiedElastic} underline fontSize={14} />
              </Grid>
            </Grid>
          </Grid>
        )}
        {mode === Modes.View && (
          <Grid item style={{ padding: '7px 0' }}>
            <LinkButton
              label={t('dialogs.patientBrief.setupElasticsPopup.addElasticButton')}
              onClick={addElastic}
              underline
              fontSize={14}
            />
          </Grid>
        )}
        <Grid item>
          <Grid container>
            {draftElastics.map((elastic, i) => (
              <Grid item key={`elastic-item-${i}`} style={{ marginRight: 32 }}>
                <ElasticListItem
                  color={elastic.color}
                  name={elastic.name}
                  elasticSelections={elastic.selections}
                  disabled={mode === Modes.Edit && i !== modifiedElasticIndex}
                  showActionButtons={mode === Modes.View}
                  onRemoveClicked={() => removeElastic(i)}
                  onEditClicked={() => enterEditMode(i)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item>
          <div className={classes.teethSelectionGridContainer}>
            <ElasticsTeethSelectionGrid
              editable={mode === Modes.Edit}
              elastics={elasticsPreview}
              currentElasticIndex={modifiedElasticIndex}
              onToothClick={handleToothClick}
              maxElasticsOnTooth={3}
            />
          </div>
        </Grid>
      </Grid>
    </PatientBriefFloatingPopup>
  )
}

export default SetupElasticsPopup
