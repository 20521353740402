import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router'
import Actions from '../../../actions'
import ROUTES from '../../../consts/routesConsts'
import useInViewport from '../../../hooks/useInViewport'
import LandingPageFooter from '../components/LandingPageFooter'
import RcSignupUnavailable from '../components/RcSignupUnavailable'
import SignUpFail from '../components/SignUpFail'
import SignUpHeader from '../components/SignUpHeader'
import SignUpSuccess from '../components/SignUpSuccess'
import useRcDetails from '../useRcDetails'
import LandingPage from './LandingPage'
import useCssClasses from '../../../hooks/useCssClasses'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles(() => ({
  body: {
    maxWidth: ({ iframeMode }) => (iframeMode ? 'auto' : '1452px'),
    margin: '0 auto'
  }
}))

const LandingPageIndex = () => {
  const { iframeMode } = useRcDetails()
  const classes = useStyles({ iframeMode })
  const dispatch = useDispatch()
  const contentRef = useRef()
  const location = useLocation()
  const { isInViewport } = useInViewport(contentRef)

  useCssClasses(isMobile())

  useEffect(() => {
    dispatch(Actions.setRcContentVisible(isInViewport))
  }, [isInViewport, dispatch])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      })
    }, 300)
  }, [location.pathname])

  return (
    <div>
      <SignUpHeader />
      <div id="body" ref={contentRef} className={classes.body}>
        <Switch>
          <Route exact path={ROUTES.AUTH_RC_SUCCESS} component={SignUpSuccess} />
          <Route exact path={ROUTES.AUTH_RC_FAIL} component={SignUpFail} />
          <Route exact path={ROUTES.AUTH_RC_UNAVAILABLE} component={RcSignupUnavailable} />
          <Route path={ROUTES.AUTH_RC} component={LandingPage} />
        </Switch>
      </div>
      <LandingPageFooter />
    </div>
  )
}

export default LandingPageIndex
