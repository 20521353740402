import React from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'utils/mobileUtils'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import OggHeading40 from 'components/common/text/OggHeading40'
import { GalleryLeftArrow, GuidesAndTutorials, PressKit } from './common/icons'
import DazzedParagraph16 from './common/text/DazzedParagraph16'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { ROUTES } from 'consts'
import useCssClasses from 'hooks/useCssClasses'

const useStyles = makeStyles({
  container: {
    padding: isMobile() ? '20px 40px 0px 40px' : '50px 0px 0px 228px'
  },
  card: {
    height: isMobile() ? 194 : 256,
    width: isMobile() ? 'auto' : 256,
    boxShadow: '0px 2px 35px 3px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    marginRight: isMobile() ? 0 : 20,
    marginBottom: isMobile() ? 20 : 0,
    cursor: 'pointer'
  },
  cardText: {
    color: '#4151E6',
    position: 'absolute',
    bottom: 4
  },
  cardItemsWrapper: {
    height: '100%',
    position: 'relative'
  },
  pressKitText: {
    marginLeft: 15
  },
  title: {
    paddingLeft: isMobile() ? '0px !important' : 16
  }
})

const Assets = ({ history, location }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  useCssClasses(true)

  const grinAssetsLink = useSelector(state => state.appReducer.appconfig.app?.grinAssetsLink)

  const handleCardClick = useCallback(() => window.open(grinAssetsLink, '_blank'), [grinAssetsLink])
  const handleClickBackToInbox = useCallback(() => {
    history.push(ROUTES.PATIENTS)
  }, [history])

  return (
    <Grid container direction="column" className={classes.container} spacing={4}>
      <Grid item className={classes.title}>
        <Grid container direction="row" alignItems="center" spacing={1} onClick={handleClickBackToInbox}>
          <Grid item>
            <GalleryLeftArrow color="black" />
          </Grid>
          <Grid item>
            <OggHeading40 textAlign="left">{t('pages.assets.title')}</OggHeading40>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction={isMobile() ? 'column' : 'row'} spacing={3}>
          <Grid item className={classes.card} onClick={handleCardClick}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.cardItemsWrapper}
            >
              <Grid item>
                <PressKit />
              </Grid>
              <DazzedParagraph16 bold className={`${classes.pressKitText} ${classes.cardText}`}>
                {t('pages.assets.pressKit')}
              </DazzedParagraph16>
            </Grid>
          </Grid>
          <Grid item className={classes.card} onClick={handleCardClick}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.cardItemsWrapper}
            >
              <Grid item>
                <GuidesAndTutorials />
              </Grid>
              <DazzedParagraph16 bold className={classes.cardText}>
                {t('pages.assets.guidesAndTutorials')}
              </DazzedParagraph16>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Assets
