import React, { useCallback, useEffect, useMemo } from 'react'
import Actions from 'actions'
import { isMobile } from 'utils/mobileUtils'
import ViewSharedScanModalMobile from './ViewSharedScanModal.Mobile'
import ViewSharedScanModalDestkop from './ViewSharedScanModalDestkop'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import useOpenShareScanModalFromUrl from './useOpenShareScanModalFromUrl'

const ViewSharedScanModalHOC = () => {
  useOpenShareScanModalFromUrl()
  const dispatch = useDispatch()

  const { isOpen, referralId, grinScanId } = useSelector(state => state.treatmentReducer.viewSharedScanModal)
  const referral = useSelector(state =>
    state.patientsReducer.patient?.grinScans?.items
      ?.find(scan => grinScanId === scan.id)
      ?.referrals?.items?.find(referral => referral.id === referralId)
  )
  const parsedReferralData = useMemo(() => JSON.parse(referral?.referralData || '{}'), [referral?.referralData])
  const colleaguesName = useMemo(() => parsedReferralData?.name, [parsedReferralData?.name])
  const attachments = useMemo(() => parsedReferralData?.files, [parsedReferralData?.files])

  const handleClose = useCallback(() => {
    trackEvent('View shared scan modal - closed')
    dispatch(Actions.toggleViewSharedScanModal({ isOpen: false }))
  }, [dispatch])

  useEffect(() => {
    if (isOpen) {
      trackEvent('View shared scan modal - opened', {
        ...referral
      })
    }
  }, [isOpen, referral])

  return isMobile() ? (
    <ViewSharedScanModalMobile
      isOpen={isOpen}
      onClose={handleClose}
      colleaguesName={colleaguesName}
      grinScanId={grinScanId}
      referral={referral}
    />
  ) : (
    <ViewSharedScanModalDestkop
      isOpen={isOpen}
      onClose={handleClose}
      colleaguesName={colleaguesName}
      grinScanId={grinScanId}
      referral={referral}
      attachments={attachments}
    />
  )
}

export default ViewSharedScanModalHOC
