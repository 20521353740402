import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import ScanActionButton from 'components/Patients/Timeline/ScanActionButton'
import useScanActions from '../useScanActions'
import { scrollBar } from 'styles/theme'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  scanActionsRoot: {
    overflowX: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    ...scrollBar,
    '&::-webkit-scrollbar': {
      height: 5
    }
  },
  scanActionButton: {
    margin: '0 24px 0 0!important',
    width: 'auto',
    padding: 0
  },
  scanActionLabel: {
    fontWeight: 'bold!important'
  }
}))

const ScanActions = ({ analyticsPayload, scanNumber, displayedScanId, timelineItemScanIds, withAligner }) => {
  const classes = useStyles()

  const scanActions = useScanActions({
    analyticsPayload,
    scanNumber,
    selectedScanId: displayedScanId,
    timelineItemScanIds,
    withAligner
  })

  const handleActionClicked = useCallback(
    action => {
      trackEvent(`Scan feed - scan action clicked`, {
        ...analyticsPayload,
        action: action.label
      })
      action.onClick()
    },
    [analyticsPayload]
  )

  return (
    <div className={classes.scanActionsRoot}>
      {scanActions.map((action, i) => (
        <div key={`scan-action-${action.elementId}`}>
          <ScanActionButton
            label={action.label}
            icon={action.icon}
            onClick={() => handleActionClicked(action)}
            elementId={action.elementId}
            className={classes.scanActionButton}
            labelClassName={classes.scanActionLabel}
          />
        </div>
      ))}
    </div>
  )
}

export default ScanActions
