import * as React from 'react'

export default ({ className }) => {
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M42 83C64.6437 83 83 64.6437 83 42C83 19.3563 64.6437 1 42 1C19.3563 1 1 19.3563 1 42C1 64.6437 19.3563 83 42 83Z"
        stroke="#EF6F6F"
        strokeWidth="2"
      />
      <path d="M30 30L54 53" stroke="#EF6F6F" strokeWidth="4" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M53.5 29.5L30.5 53.5" stroke="#EF6F6F" strokeWidth="4" strokeLinecap="square" strokeLinejoin="round" />
    </svg>
  )
}
