const FETCH_TRACKING_REQUIRED_GRIN_SCANS = 'FETCH_TRACKING_REQUIRED_GRIN_SCANS'
const FETCH_TRACKNG_REQUIRED_GRIN_SCANS_RECEIVED = 'FETCH_TRACKNG_REQUIRED_GRIN_SCANS_RECEIVED'
const FETCH_TRACKNG_REQUIRED_GRIN_SCANS_FAILED = 'FETCH_TRACKNG_REQUIRED_GRIN_SCANS_FAILED'
const SUBMIT_SCAN_TRACKING_DATA = 'SUBMIT_SCAN_TRACKING_DATA'
const SUBMIT_SCAN_TRACKING_DATA_RECEIVED = 'SUBMIT_SCAN_TRACKING_DATA_RECEIVED'
const SUBMIT_SCAN_TRACKING_DATA_FAILED = 'SUBMIT_SCAN_TRACKING_DATA_FAILED'
const GI_MARK_SCAN_AS_DONE = 'GI_MARK_SCAN_AS_DONE'
const GI_MARK_SCAN_AS_DONE_RECEIVED = 'GI_MARK_SCAN_AS_DONE_RECEIVED'
const GI_MARK_SCAN_AS_DONE_FAILED = 'GI_MARK_SCAN_AS_DONE_FAILED'

const giMarkScanAsDone = payload => ({
  type: GI_MARK_SCAN_AS_DONE,
  payload
})

const giMarkScanAsDoneReceived = payload => ({
  type: GI_MARK_SCAN_AS_DONE_RECEIVED,
  payload
})

const giMarkScanAsDoneFailed = payload => ({
  type: GI_MARK_SCAN_AS_DONE_FAILED,
  payload
})

const submitScanTrackingData = payload => ({
  type: SUBMIT_SCAN_TRACKING_DATA,
  payload
})

const submitScanTrackingDataReceived = payload => ({
  type: SUBMIT_SCAN_TRACKING_DATA_RECEIVED,
  payload
})

const submitScanTrackingDataFailed = payload => ({
  type: SUBMIT_SCAN_TRACKING_DATA_FAILED,
  payload
})

const fetchTrackingRequiredGrinScans = payload => ({
  type: FETCH_TRACKING_REQUIRED_GRIN_SCANS,
  payload
})

const fetchTrackingRequiredGrinScansReceived = payload => ({
  type: FETCH_TRACKNG_REQUIRED_GRIN_SCANS_RECEIVED,
  payload
})

const fetchTrackingRequiredGrinScansFailed = payload => ({
  type: FETCH_TRACKNG_REQUIRED_GRIN_SCANS_FAILED,
  payload
})

export default {
  FETCH_TRACKING_REQUIRED_GRIN_SCANS,
  FETCH_TRACKNG_REQUIRED_GRIN_SCANS_RECEIVED,
  FETCH_TRACKNG_REQUIRED_GRIN_SCANS_FAILED,
  SUBMIT_SCAN_TRACKING_DATA,
  SUBMIT_SCAN_TRACKING_DATA_FAILED,
  SUBMIT_SCAN_TRACKING_DATA_RECEIVED,
  GI_MARK_SCAN_AS_DONE,
  GI_MARK_SCAN_AS_DONE_RECEIVED,
  GI_MARK_SCAN_AS_DONE_FAILED,
  fetchTrackingRequiredGrinScans,
  fetchTrackingRequiredGrinScansReceived,
  fetchTrackingRequiredGrinScansFailed,
  submitScanTrackingData,
  submitScanTrackingDataReceived,
  submitScanTrackingDataFailed,
  giMarkScanAsDone,
  giMarkScanAsDoneReceived,
  giMarkScanAsDoneFailed
}
