import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import BaseModal from '../../common/modals/BaseModal'
import VideoWrapper from '../../common/VideoWrapper'
import Actions from '../../../actions'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  dialog: {
    minWidth: '70%'
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  video: {
    width: '90%',
    height: '90%',
    alignSelf: 'center',
    outline: 'none',
    borderRadius: 20
  }
}))

const ScanViewer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { open, type, s3Object, isLocal, cache } = useSelector(state => state.treatmentReducer.viewScanDialog)
  const { t } = useTranslation()

  const handleClose = useCallback(() => {
    dispatch(Actions.closeScanViewDialog())
  }, [dispatch])

  if (!s3Object && !cache) {
    return null
  }

  return (
    <BaseModal
      title={
        type === 'scan' || type === 'doubleScan' ? t('dialogs.scanViewer.scan') : t('dialogs.scanViewer.scanReview')
      }
      open={open}
      handleClose={handleClose}
      className={classes.dialog}
    >
      <div className={classes.container}>
        <VideoWrapper
          s3Object={s3Object}
          isLocal={isLocal}
          cache={cache}
          className={classes.video}
          muted={type === 'scan'}
        />
      </div>
    </BaseModal>
  )
}

export default ScanViewer
