import * as React from 'react'

export default ({ color = '#3C52EF' }) => (
  <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 1L1.35355 7.14645C1.15829 7.34171 1.15829 7.65829 1.35355 7.85355L7.5 14"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
