import { Grid, makeStyles } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import Attachments from 'components/Patients/ShareScan/Attachments'
import LinkButton from 'components/common/buttons/LinkButton'
import { DownloadArrow } from 'components/common/icons'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import useAttachments from './useAttachments'

const useStyles = makeStyles(theme => ({
  downloadAttachments: {
    textDecoration: 'underline'
  }
}))

const ReferralViewScanAttachments = ({ attachments }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { downloadAttachments } = useAttachments()

  const [isLoading, setIsLoading] = useState(false)

  const handleDownloadClicked = useCallback(async () => {
    trackEvent('Referrals - Review scan - Download attachments clicked')

    setIsLoading(true)
    await downloadAttachments(attachments)
    setIsLoading(false)
  }, [attachments, downloadAttachments])

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <DazzedParagraph16 bold>{t('pages.referrals.review.attachments')}</DazzedParagraph16>
      </Grid>
      <Grid item>
        <Attachments files={attachments} editable={false} />
        <LinkButton
          label={t('general.download')}
          icon={<DownloadArrow />}
          onClick={handleDownloadClicked}
          isLoading={isLoading}
          labelClassName={classes.downloadAttachments}
        />
      </Grid>
    </Grid>
  )
}

export default ReferralViewScanAttachments
