import * as React from 'react'

function SvgClose({ color, height, width, ...props }) {
  return (
    <svg width={width || 24} height={height || 24} fill="none" {...props}>
      <path d="M6 6l12 12M18 6L6 18" stroke={color || '#fff'} strokeLinecap="round" />
    </svg>
  )
}

export default SvgClose
