import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from '../../text/DazzedParagraph12'
import SmallDotIcon from '../../icons/SmallDot'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row'
  },
  colorDot: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '4px 0'
  },
  body: {
    paddingLeft: '8px',
    '&.disabled *': {
      color: 'var(--text-color-12)!important'
    },
    '&.selected *': {
      color: 'var(--text-color-3)!important'
    }
  },
  title: {
    fontSize: 13,
    fontWeight: 600
  },
  subtitle: {
    color: 'var(--text-color-18)'
  }
}))

const StatusPickerOption = ({ status, isSelected, isDisabled }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const statusSettings = useMemo(() => JSON.parse(status.settings || '{}'), [status])
  const subtitleText = useMemo(() => {
    if (!status.hasLimitedScans) {
      return t('statuses.menu.subtitles.unlimited')
    }

    if (status.maxScansPerPeriod > 0) {
      return t('statuses.menu.subtitles.limited', { maxScansPerPeriod: status.maxScansPerPeriod })
    }

    return t('statuses.menu.subtitles.scansNotAvailable')
  }, [status, t])

  return (
    <div className={classes.root}>
      <div className={classes.colorDot}>
        <SmallDotIcon color={statusSettings.color} />
      </div>
      <div className={[classes.body, isDisabled ? 'disabled' : '', isSelected ? 'selected' : ''].join(' ')}>
        <DazzedParagraph12 className={classes.title}>{t(`statuses.names.${status.type}`)}</DazzedParagraph12>
        <DazzedParagraph12 className={classes.subtitle}>{subtitleText}</DazzedParagraph12>
      </div>
    </div>
  )
}

export default StatusPickerOption
