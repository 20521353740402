import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import useClinic from 'hooks/useClinic'
import usePlanLimits from 'hooks/usePlanLimits'
import { useCountry } from 'hooks/useCountry'
import BaseModal from '../../common/modals/BaseModal'
import OrderGrinKitsAmountContainer from './OrderGrinKitsAmountContainer'
import OrderGrinKitsCheckout from './OrderGrinKitsCheckout'
import OrderGrinKitsComparison from './OrderGrinKitsComparison'
import { isMobile } from 'utils/mobileUtils'
import { removeKitsOrder, setKistOrder, getKistOrder } from 'utils/storageUtils'
import {
  GRIN_SCOPE_MIN_IN_ORDER,
  GRIN_SCOPE_TYPE_MINI,
  GRIN_SCOPE_TYPE_REGULAR,
  SUBSCRIPTION_PLAN_GRIN_SCOPE_MAX_IN_ORDER,
  SUBSCRIPTION_PLAN_GRIN_SCOPE_MINI_MAX_IN_ORDER
} from 'consts/billingConsts'
import { COUNTRY_US, COUNTRY_IN } from 'consts/countryConsts'
import images from 'resources'
import useScopesShippingFee from 'hooks/useScopesShippingFee'
import { trackEvent } from 'utils/analyticsUtils'
import useGaFlags from 'hooks/useGaFlags'

const useStyles = makeStyles(theme => ({
  body: {
    minWidth: isMobile() ? 0 : 680,
    overflowX: 'hidden'
  },
  contentBody: {
    padding: '0 24px 40px 24px !important'
  },
  modalActions: {
    padding: '10px !important',
    flexDirection: 'column-reverse',
    '& > button': {
      marginLeft: '0 !important',
      marginTop: 10
    }
  },
  modalTitle: {
    paddingBottom: '5px !important',
    '& > div': {
      fontSize: '33px '
    }
  },
  confirmationStepBody: {
    minWidth: 540
  },
  horizontalSeparator: {
    marginBottom: 20,
    marginLeft: -24,
    marginRight: -24,
    height: '1px',
    borderTop: `1px solid ${theme.palette.grey[300]}`
  }
}))

const AMOUNT_STEP = 'AMOUNT_STEP'
const CONFIRMATION_STEP = 'CONFIRMATION_STEP'
const currency = '$'

const OrderGrinKitsModal = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { canClinicBuyScopes, hasFreeScopes } = useClinic()
  const gaFlags = useGaFlags()

  const doctorName = useSelector(state => state.profileReducer?.doctor?.name)

  const isOrderOpen = useSelector(state => state.billingReducer.order.isOrderGrinKitsModalOpen)
  const isSubmittingOrder = useSelector(state => state.billingReducer.order.isLoading)
  const isCalculatingTaxes = useSelector(state => state.billingReducer.order.isLoadingTaxes)
  const isLoadingScopeProduct = useSelector(state => state.productReducer.isLoadingScopeProducts)
  const practiceDetails = useSelector(state => state.practiceReducer.details)
  const productScope = useSelector(state => state.productReducer.scope.data)
  const productScopeMini = useSelector(state => state.productReducer.scopeMini.data)
  const shippingFee = useSelector(state => state.productReducer.shippingFee)
  const taxes = useSelector(state => state.billingReducer.order.taxAmount)
  const { coupon } = useSelector(state => state.billingReducer.order.coupon)
  const invoiceId = useSelector(state => state.billingReducer.order.invoiceId)
  const totalDiscount = useSelector(state => state.billingReducer.order.totalDiscount)
  const planLimits = usePlanLimits()
  const quantity = useSelector(state => state.billingReducer.order.grinKitsQuantity[GRIN_SCOPE_TYPE_REGULAR])
  const quantityMini = useSelector(state => state.billingReducer.order.grinKitsQuantity[GRIN_SCOPE_TYPE_MINI])
  const practicePlanGroup = useSelector(state => state.practiceReducer?.billing?.grinPlan?.planGroup)

  const [shippingAddress, setShippingAddress] = useState({})
  const [agreedTerms, setAgreedTerms] = useState(false)
  const [formErrors, setFormErrors] = useState()
  const [shouldShowErrors, setShouldShowErrors] = useState(false)
  const [step, setStep] = useState(AMOUNT_STEP)
  const [showProductComparison, setShowProductComparison] = useState(false)

  const { getStateKey } = useCountry(shippingAddress?.country)
  const { shippingFee: newShippingFee } = useScopesShippingFee(shippingAddress)

  const grinKitMaxScopeQuantity = useMemo(
    () =>
      planLimits.isSubscriptionPlan && hasFreeScopes ? SUBSCRIPTION_PLAN_GRIN_SCOPE_MAX_IN_ORDER : planLimits.maxScopes,
    [planLimits.isSubscriptionPlan, planLimits.maxScopes, hasFreeScopes]
  )
  const grinKitMiniMaxScopeQuantity = useMemo(
    () =>
      planLimits.isSubscriptionPlan && hasFreeScopes
        ? SUBSCRIPTION_PLAN_GRIN_SCOPE_MINI_MAX_IN_ORDER
        : planLimits.maxScopes,
    [planLimits.isSubscriptionPlan, planLimits.maxScopes, hasFreeScopes]
  )
  const isNewShippingFee = useMemo(() => gaFlags?.newShippingFee, [gaFlags])

  const setQuantity = useCallback(
    value =>
      dispatch(
        Actions.setScopeQuantity({
          scopeType: GRIN_SCOPE_TYPE_REGULAR,
          value
        })
      ),
    [dispatch]
  )

  const setQuantityMini = useCallback(
    value =>
      dispatch(
        Actions.setScopeQuantity({
          scopeType: GRIN_SCOPE_TYPE_MINI,
          value
        })
      ),
    [dispatch]
  )

  const mappedShippingAddress = useMemo(() => {
    const address = {
      street: shippingAddress.address1,
      detailedAddress: shippingAddress.address2,
      city: shippingAddress.city,
      country: shippingAddress.country,
      state: getStateKey(shippingAddress.state, shippingAddress.country),
      zip: shippingAddress.zip
    }
    if (shippingAddress.country === COUNTRY_IN) {
      address.detailedAddress = shippingAddress.address2
    }
    return address
  }, [getStateKey, shippingAddress])

  const setInitialShippingAddress = useCallback(() => {
    setShippingAddress({
      address1: practiceDetails.address1,
      address2: practiceDetails.address2,
      city: practiceDetails.city,
      country: practiceDetails.country || COUNTRY_US,
      state: practiceDetails.state,
      zip: practiceDetails.zip
    })
  }, [practiceDetails, setShippingAddress])

  const resetFields = useCallback(() => {
    setQuantity(10)
    setQuantityMini(null)
    setAgreedTerms(false)
    setInitialShippingAddress()
    setFormErrors()
    setShouldShowErrors(false)
    dispatch(Actions.resetCoupon({}))
  }, [setQuantity, setQuantityMini, setInitialShippingAddress, setFormErrors, dispatch])

  const validateForm = useCallback(() => {
    const errors = {}

    if (!quantity && !quantityMini) {
      errors.quantity = t('dialogs.orderScopes.quantityError')
    }
    if (quantity % GRIN_SCOPE_MIN_IN_ORDER !== 0) {
      errors.quantity = t('dialogs.orderScopes.quantityMultiplyError')
    }
    if (quantityMini % GRIN_SCOPE_MIN_IN_ORDER !== 0) {
      errors.quantityMini = t('dialogs.orderScopes.quantityMultiplyError')
    }
    if (quantity < GRIN_SCOPE_MIN_IN_ORDER && !quantityMini) {
      errors.quantity = t('dialogs.orderScopes.minQuantityError', { minScopes: GRIN_SCOPE_MIN_IN_ORDER })
    }
    if (quantityMini < GRIN_SCOPE_MIN_IN_ORDER && !quantity) {
      errors.quantityMini = t('dialogs.orderScopes.minQuantityError', { minScopes: GRIN_SCOPE_MIN_IN_ORDER })
    }
    if (quantity > grinKitMaxScopeQuantity) {
      errors.quantity = t('dialogs.orderScopes.maxquantityError', { maxScopes: grinKitMaxScopeQuantity })
    }
    if (quantityMini > grinKitMiniMaxScopeQuantity) {
      errors.quantityMini = t('dialogs.orderScopes.maxquantityError', { maxScopes: grinKitMiniMaxScopeQuantity })
    }

    setFormErrors(errors)
    return isEmpty(errors)
  }, [grinKitMaxScopeQuantity, grinKitMiniMaxScopeQuantity, quantity, quantityMini, t])

  const calculateOrderTax = useCallback(() => {
    dispatch(
      Actions.calculateOrderTax({
        quantity,
        quantityMini,
        shippingAddress: mappedShippingAddress,
        grinPlanKey: planLimits.grinPlanKey
      })
    )
  }, [dispatch, quantity, quantityMini, mappedShippingAddress, planLimits.grinPlanKey])

  const handleNoPaymentOrder = useCallback(() => {
    setShouldShowErrors(true)
    if (!validateForm()) {
      return
    }

    dispatch(
      Actions.createOrderGrinKits({
        purchase: false,
        quantity,
        quantityMini,
        shippingAddress: mappedShippingAddress
      })
    )
  }, [validateForm, dispatch, quantity, quantityMini, mappedShippingAddress])

  const handleRemoveDraftKistOrder = useCallback(() => {
    if (isMobile()) {
      removeKitsOrder()
      dispatch(Actions.setHasNotificationsIndicator(false))
    }
  }, [dispatch])

  const handleSetDraftKitsOrder = useCallback(() => {
    if (isMobile()) {
      dispatch(Actions.setHasNotificationsIndicator(true))
      setKistOrder({
        quantity,
        quantityMini
      })
    }
  }, [dispatch, quantity, quantityMini])

  const handlePayment = useCallback(() => {
    if (isSubmittingOrder) {
      return
    }

    setShouldShowErrors(true)
    if (!validateForm()) {
      return
    }

    if (!agreedTerms) {
      return
    }

    handleRemoveDraftKistOrder()
    trackEvent('Order grin scopes - scopes ordered', {
      regularScopesAmount: quantity,
      miniScopesAmoumt: quantityMini
    })
    dispatch(
      Actions.createOrderGrinKits({
        purchase: true,
        invoiceId,
        shippingAddress
      })
    )
  }, [
    isSubmittingOrder,
    validateForm,
    agreedTerms,
    handleRemoveDraftKistOrder,
    quantity,
    quantityMini,
    dispatch,
    invoiceId,
    shippingAddress
  ])

  const resetCoupon = useCallback(() => dispatch(Actions.clearCouponCode()), [dispatch])

  const handleClose = useCallback(() => {
    dispatch(Actions.setOrderGrinKitsModalVisibility(false))
    handleRemoveDraftKistOrder()
    setStep(AMOUNT_STEP)
    resetFields()
  }, [dispatch, resetFields, handleRemoveDraftKistOrder])

  const handleClickSecondaryOnConfirmStep = useCallback(() => {
    dispatch(Actions.resetCoupon({}))
    setStep(AMOUNT_STEP)

    if (invoiceId) {
      dispatch(Actions.deleteInvoice({ invoiceId }))
    }
  }, [dispatch, invoiceId])

  const handleSubmitAmountStep = useCallback(
    e => {
      e.preventDefault()
      if (!validateForm()) {
        return
      }

      if (!canClinicBuyScopes()) {
        handleNoPaymentOrder()
      } else {
        handleSetDraftKitsOrder()
        setStep(CONFIRMATION_STEP)
      }
    },
    [validateForm, canClinicBuyScopes, handleNoPaymentOrder, handleSetDraftKitsOrder]
  )

  const steps = useMemo(
    () => ({
      [AMOUNT_STEP]: {
        primaryLabel: t('general.continue'),
        handleSubmit: handleSubmitAmountStep,
        secondaryLabel: !isMobile() && t('general.cancel'),
        handleSecondary: handleClose,
        title: t('dialogs.orderScopes.title')
      },
      [CONFIRMATION_STEP]: {
        primaryLabel: isMobile() ? t('dialogs.orderScopes.purchaseButton') : t('general.confirm'),
        handleSubmit: () => {},
        secondaryLabel: t('general.back'),
        handleSecondary: handleClickSecondaryOnConfirmStep,
        title: isMobile() ? t('dialogs.orderScopes.confirmPayment') : t('dialogs.orderScopes.title')
      }
    }),
    [t, handleSubmitAmountStep, handleClose, handleClickSecondaryOnConfirmStep]
  )

  const handleShippingAddressChange = useCallback(
    address => {
      trackEvent('Order scopes modal - shipping address changed', {
        currentAddress: shippingAddress,
        newAddress: address,
        doctorName
      })
      setShippingAddress(address)
      isNewShippingFee && handleSetDraftKitsOrder()
    },
    [handleSetDraftKitsOrder, isNewShippingFee, shippingAddress, doctorName]
  )

  const products = useMemo(
    () => [
      {
        type: GRIN_SCOPE_TYPE_REGULAR,
        price: productScope?.price || 0,
        quantity: quantity,
        maxQuantity: grinKitMaxScopeQuantity,
        onSetQuantity: setQuantity,
        image: images.grinScope,
        name: t('dialogs.orderScopes.grinScope'),
        description: `${t('dialogs.orderScopes.grinScopeDescription')} ${t(
          'dialogs.orderScopes.grinScopeDescriptionWidth'
        )}`,
        error: formErrors?.quantity
      },
      {
        type: GRIN_SCOPE_TYPE_MINI,
        price: productScopeMini?.price || 0,
        quantity: quantityMini,
        maxQuantity: grinKitMiniMaxScopeQuantity,
        onSetQuantity: setQuantityMini,
        image: images.grinScopeMini,
        name: t('dialogs.orderScopes.grinScopeMini'),
        description: `${t('dialogs.orderScopes.grinScopeMiniDescription')} ${t(
          'dialogs.orderScopes.grinScopeMiniDescriptionWidth'
        )}`,
        error: formErrors?.quantityMini
      }
    ],
    [
      productScope?.price,
      quantity,
      grinKitMaxScopeQuantity,
      setQuantity,
      t,
      formErrors?.quantity,
      formErrors?.quantityMini,
      productScopeMini?.price,
      quantityMini,
      grinKitMiniMaxScopeQuantity,
      setQuantityMini
    ]
  )

  const isPrimaryDisabled = useMemo(
    () =>
      isCalculatingTaxes ||
      isSubmittingOrder ||
      !isEmpty(formErrors) ||
      (step === CONFIRMATION_STEP ? !agreedTerms : false),
    [agreedTerms, formErrors, isCalculatingTaxes, isSubmittingOrder, step]
  )

  useEffect(() => {
    setInitialShippingAddress()
  }, [practiceDetails, setInitialShippingAddress])

  useEffect(() => {
    validateForm()
  }, [quantity, quantityMini, validateForm])

  useEffect(() => {
    if (!isLoadingScopeProduct && isEmpty(productScope) && practicePlanGroup) {
      dispatch(Actions.fetchGrinScopeProducts())
    }
  }, [dispatch, isLoadingScopeProduct, isOrderOpen, practicePlanGroup, productScope])

  useEffect(() => {
    if (isOrderOpen && step === CONFIRMATION_STEP) {
      calculateOrderTax()
    }
  }, [isOrderOpen, mappedShippingAddress, calculateOrderTax, step])

  useEffect(() => {
    if (isOrderOpen && isMobile()) {
      const draftOrder = getKistOrder()
      if (draftOrder) {
        setQuantity(draftOrder.quantity)
        setQuantityMini(draftOrder.quantityMini)
      }
    }
  }, [setQuantity, setQuantityMini, isOrderOpen])

  useEffect(() => {
    if (!isOrderOpen) {
      setStep(AMOUNT_STEP)
    }
  }, [isOrderOpen, resetFields])

  return isOrderOpen ? (
    showProductComparison ? (
      <BaseModal
        open={showProductComparison}
        primaryLabel={t('general.back')}
        className={classes.body}
        withCloseIcon={false}
        onPrimaryBtnClick={() => setShowProductComparison(false)}
      >
        <OrderGrinKitsComparison />
      </BaseModal>
    ) : (
      <BaseModal
        open={isOrderOpen}
        title={steps[step].title}
        secondaryLabel={steps[step].secondaryLabel}
        primaryLabel={steps[step].primaryLabel}
        className={step === CONFIRMATION_STEP ? classes.confirmationStepBody : classes.body}
        contentClassName={isMobile() ? classes.contentBody : ''}
        titleContainerClassName={isMobile() ? classes.modalTitle : ''}
        actionsClassName={isMobile() ? classes.modalActions : ''}
        isLoading={isSubmittingOrder}
        handleClose={handleClose}
        onSecondaryBtnClick={steps[step].handleSecondary}
        onPrimaryBtnClick={steps[step].handleSubmit}
        isPrimaryDisabled={isPrimaryDisabled}
        primaryType="submit"
        formId="credit-card-form"
        variant={isMobile() ? 'fullscreen' : 'primary'}
        largerButtons={isMobile()}
      >
        {step === AMOUNT_STEP && (
          <OrderGrinKitsAmountContainer
            products={products}
            shippingFee={isNewShippingFee ? newShippingFee?.price : shippingFee || 0}
            formErrors={formErrors}
            onShowProductComparison={() => setShowProductComparison(true)}
          />
        )}
        {step === CONFIRMATION_STEP && (
          <OrderGrinKitsCheckout
            kitPrice={productScope?.price || 0}
            kitMiniPrice={productScopeMini?.price || 0}
            shippingFee={isNewShippingFee ? newShippingFee?.price : shippingFee || 0}
            taxes={taxes}
            coupon={coupon}
            quantity={quantity}
            quantityMini={quantityMini}
            currency={currency}
            shippingAddress={shippingAddress}
            agreedTerms={agreedTerms}
            shouldShowErrors={shouldShowErrors}
            setAgreedTerms={setAgreedTerms}
            onChangeShippingAddress={handleShippingAddressChange}
            resetShippingAddress={setInitialShippingAddress}
            isLoading={isCalculatingTaxes || isLoadingScopeProduct}
            onCardSaved={handlePayment}
            onResetCoupon={resetCoupon}
            totalDiscount={totalDiscount}
          />
        )}
      </BaseModal>
    )
  ) : null
}

export default OrderGrinKitsModal
