import * as React from 'react'

function RightArrow(props) {
  return (
    <svg width="19" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4138 8.97861L1.62579 0.214858C1.33786 -0.0721072 0.871692 -0.0716248 0.584244 0.216343C0.297018 0.504273 0.297761 0.970699 0.585728 1.25789L8.85069 9.50003L0.585431 17.7421C0.297501 18.0294 0.296758 18.4955 0.583947 18.7835C0.728042 18.9278 0.916817 19 1.10559 19C1.29388 19 1.48192 18.9283 1.62575 18.7849L10.4138 10.0214C10.5525 9.88344 10.6303 9.69567 10.6303 9.50003C10.6303 9.30439 10.5523 9.11684 10.4138 8.97861Z"
        fill="#A8A8A8"
      />
    </svg>
  )
}

export default RightArrow
