import { createPatientsListFilter } from 'utils/searchUtils'
import { s3ObjectToCloudfront, updateS3Key } from 'utils/storageUtils'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { AWS_DATE_TIME_UTC } from 'consts/dateTimeConsts'
import { getObjectForDownload, getObjectUrl } from 'utils/mediaUtils'

export const mapToMessageModel = ({ _deleted, _lastChangedAt, updatedAt, isSelf, ...restOfMessage }) => restOfMessage

export const mapToLastPromotionMessages = lastPromotionMessages => {
  const uniqueLastPromotionMessages = []
  lastPromotionMessages.forEach(({ promotionId, createdAt }) => {
    const isExist = uniqueLastPromotionMessages.find(uniqueMessage => uniqueMessage.promotionId === promotionId)
    if (!isExist) {
      uniqueLastPromotionMessages.push({ promotionId, createdAt })
    }
  })
  return uniqueLastPromotionMessages
}

export const mapToPromotions = promotions => promotions.map(mapToPromotion)

export const mapToPromotion = promotion => {
  const metadata = JSON.parse(promotion.metadata || '{}')
  return {
    ...promotion,
    metadata
  }
}

export const mapToBroadcastMessage = ([payload, state]) => {
  const allSelected = state.chatReducer.broadcast.allSelected
  const isPersonal = state.chatReducer.broadcast.isPersonalMessage
  const doctor = state.profileReducer.doctor

  const input = {
    messagePayload: payload,
    allSelected
  }

  if (isPersonal) {
    input.messagePayload.metadata = JSON.stringify({
      senderData: {
        name: doctor.name,
        email: doctor.email,
        photo: doctor.photo,
        roleDescription: doctor.roleDescription,
        userType: 'doctor'
      },
      isPersonal: true
    })
  }

  if (allSelected) {
    const filter = createPatientsListFilter({
      doctorId: state.profileReducer.accountOwnerId || state.practiceReducer.accountOwner.id,
      filter: {
        tagsFilter: state.chatReducer.filter.tagsFilter,
        searchFilter: state.chatReducer.filter.searchFilter,
        doctorFilter: state.chatReducer.filter.doctorFilter
      }
    })

    input.filter = filter
    input.excludedPatientIds = state.chatReducer.broadcast.excluded
  } else {
    input.patientIds = state.chatReducer.broadcast.selectedPatients
  }

  const localMessage = mapToLocalBroadcastMesage({
    payload,
    doctor: state.profileReducer.doctor,
    isBroadcast: !isPersonal
  })

  return { body: input, localMessage }
}

export const mapToImageMessage = ({ grinUserId, members, roomId, content, type, readBy, cache }) => {
  const messageModel = {
    id: uuidv4(),
    uploadingDate: moment.utc().format(AWS_DATE_TIME_UTC),
    roomId,
    grinUserId,
    members,
    type,
    content,
    readBy
  }
  return { messageModel, cache, content }
}

export const mapToScanSummaryImageMessages = async ([action, state]) => {
  const selectedImages = action.payload.selectedImages
  const editedImages = action.payload.editedImages
  const originalImages = action.payload.originalImages

  const patient = state.patientsReducer.patient
  const doctor = state.profileReducer.doctor
  const roomId = patient.user?.rooms.items?.length && patient.user?.rooms.items[0].roomId
  const messageMembers = [
    patient?.username,
    doctor?.username !== patient?.doctor?.username ? patient?.doctor?.username : doctor?.username
  ]

  const messageModel = {
    grinUserId: doctor.user.id,
    members: messageMembers,
    roomId,
    type: 'image',
    patientUsername: patient.username,
    readBy: doctor.username,
    metadata: JSON.stringify({
      senderData: {
        name: doctor.name,
        email: doctor.email,
        photo: doctor.photo
      }
    })
  }

  let messageInputs = []
  for (let index = 0; index < selectedImages.length; index++) {
    const position = selectedImages[index]
    const editedImage = editedImages[position]
    const initialImageContent = originalImages[position]
    const imageUrl = getObjectUrl(initialImageContent)
    const imageContent = updateS3Key(initialImageContent)
    const content = editedImage
      ? Buffer.from(editedImage.replace(/^data:image\/\w+;base64,/, ''), 'base64')
      : imageUrl?.includes('private')
      ? await getObjectForDownload(imageUrl)
      : JSON.stringify(imageContent)
    const cache = editedImage || imageUrl || s3ObjectToCloudfront(initialImageContent)
    messageInputs.push({
      ...messageModel,
      content,
      cache,
      isOriginalImage: !editedImage
    })
  }

  return messageInputs
}

export const mapToLocalBroadcastMesage = ({ payload, doctor = {}, isLocal = false, isBroadcast = true }) => {
  return {
    ...payload,
    id: payload.id || uuidv4(),
    uploadingDate: moment.utc().format(AWS_DATE_TIME_UTC),
    isBroadcast,
    grinUserId: doctor?.user?.id,
    isLocal,
    metadata: JSON.stringify({
      name: doctor.name,
      email: doctor.email,
      photo: doctor.photo,
      roleDescription: doctor.roleDescription,
      userType: 'doctor'
    })
  }
}
