import * as React from 'react'

export default () => {
  return (
    <svg width="46" height="19" viewBox="0 0 46 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="27" y="3" width="18" height="13" rx="1" stroke="#4151E6" strokeWidth="1.25" />
      <path d="M34 13V6L40 9.5L34 13Z" fill="#4151E6" />
      <rect x="1" y="3" width="18" height="13" rx="1" stroke="#4151E6" strokeWidth="1.25" />
      <path d="M8 13V6L14 9.5L8 13Z" fill="#4151E6" />
      <path d="M23 0V19" stroke="#4151E6" strokeWidth="0.75" />
    </svg>
  )
}
