import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isMacOs } from 'react-device-detect'
import FormInput from 'components/common/FormInput'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import images from 'resources'
import { GRIN_SCOPE_TYPE_REGULAR } from 'consts/billingConsts'

const useStyles = makeStyles({
  inputContainer: {
    alignSelf: 'center'
  },
  grinScopeImageContainer: {
    textAlign: 'center'
  },
  grinScopeImage: {
    width: 100
  },
  grinScope: {
    fontWeight: 600
  },
  amountContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5
  },
  currencyPrefix: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 400,
    marginTop: isMacOs ? 2 : 0
  },
  input: {
    marginTop: '0 !important',
    height: '31px !important'
  },
  inputWrapper: {
    marginTop: 20,
    maxWidth: 130,
    display: 'flex',
    '& input': {
      textAlign: 'center'
    }
  },
  controls: {
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 600,
    margin: '0 5px',
    marginBottom: 5,
    cursor: 'pointer',
    userSelect: 'none'
  },
  prompt: {
    width: 170,
    height: 20,
    textAlign: 'center',
    fontSize: 10,
    color: ({ quantityError }) => (quantityError ? 'var(--text-color-17)' : 'var(--text-color-11)')
  },
  disabled: {
    color: 'darkgray',
    cursor: 'auto'
  },
  note: {
    textAlign: 'center!important',
    marginTop: 30,
    color: 'red'
  }
})

const OrderGrinKitsItem = ({
  type,
  price,
  hidePrice,
  quantity,
  formErrors,
  onSetQuantity,
  onClickMinusQuantity,
  onClickPlusQuantity,
  maxQuantity
}) => {
  const { t } = useTranslation()

  const isRegular = useMemo(() => type === GRIN_SCOPE_TYPE_REGULAR, [type])

  const error = useMemo(
    () => (isRegular ? formErrors?.quantity : formErrors?.quantityMini),
    [formErrors?.quantity, formErrors?.quantityMini, isRegular]
  )

  const classes = useStyles({ quantityError: error })

  const handleClickPlusQuantity = useCallback(
    type => {
      onClickPlusQuantity(type)
    },
    [onClickPlusQuantity]
  )

  const hasReachedOrderLimit = useMemo(() => quantity === maxQuantity, [maxQuantity, quantity])

  const promptMessage = useMemo(() => {
    const orderMaxLimitText = hasReachedOrderLimit && t('dialogs.orderScopes.maxSubscriptionQuantityError')
    return error || orderMaxLimitText || t('dialogs.orderScopes.quantityMultiplyError')
  }, [error, hasReachedOrderLimit, t])

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={3} className={classes.grinScopeImageContainer}>
          <img
            className={classes.grinScopeImage}
            src={isRegular ? images.grinScope : images.grinScopeMini}
            alt="Grin scope"
          />
        </Grid>
        <Grid item xs={4}>
          <DazzedParagraph16 className={classes.grinScope}>
            {isRegular ? t('dialogs.orderScopes.grinScope') : t('dialogs.orderScopes.grinScopeMini')}
          </DazzedParagraph16>
          <DazzedParagraph12>
            {isRegular
              ? `${t('dialogs.orderScopes.grinScopeDescription')} ${t('dialogs.orderScopes.grinScopeDescriptionWidth')}`
              : `${t('dialogs.orderScopes.grinScopeMiniDescription')} ${t(
                  'dialogs.orderScopes.grinScopeMiniDescriptionWidth'
                )}`}
          </DazzedParagraph12>
        </Grid>
        <Grid item xs={5}>
          <Grid container alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={hidePrice ? 12 : 8}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item className={classes.inputWrapper}>
                  <span className={classes.controls} onClick={() => onClickMinusQuantity(type)}>
                    -
                  </span>
                  <FormInput
                    style={{ bright: true, thick: true }}
                    value={quantity}
                    keepErrorContainerWhenInactive={false}
                    inputWrapperClassName={classes.input}
                    containerClassName={classes.inputContainer}
                    setValue={value => {
                      const newValue = !isNaN(value) ? +value : quantity
                      onSetQuantity(+newValue)
                    }}
                  />
                  <span
                    className={[classes.controls, hasReachedOrderLimit && classes.disabled].join(' ')}
                    onClick={() => handleClickPlusQuantity(type)}
                  >
                    +
                  </span>
                </Grid>
                <Grid item>
                  <DazzedParagraph12 className={classes.prompt}>{promptMessage}</DazzedParagraph12>
                </Grid>
              </Grid>
            </Grid>
            {!hidePrice && (
              <Grid item xs={4} className={classes.amountContainer}>
                {!error && quantity > 0 && (
                  <DazzedParagraph12>
                    {`$${t('dialogs.orderScopes.scopesAmountPrice', { price: quantity * price })}`}
                  </DazzedParagraph12>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default OrderGrinKitsItem
