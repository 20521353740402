import AppLogo from './AppLogo'
import AppTabsMobile from './AppTabs.mobile'
import AppProfile from './ProfileMenu'
import { makeStyles } from '@material-ui/core'
const { Grid } = require('@material-ui/core')

const AppHeaderMobile = ({ showTabs = true }) => {
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%'
    },
    container: {
      backgroundColor: 'white'
    }
  }))

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container className={classes.container} direction="column" justifyContent="space-between">
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" spacing={1}>
            <Grid item>
              <AppLogo />
            </Grid>
            <Grid item style={{ paddingRight: '20px' }}>
              <AppProfile />
            </Grid>
          </Grid>
        </Grid>
        {showTabs && (
          <Grid item xs={12}>
            <AppTabsMobile />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default AppHeaderMobile
