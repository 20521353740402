import * as React from 'react'

export default ({ color = '#4151E6', filled = false }) => (
  <svg width="24" height="23" viewBox="0 0 24 23" fill={filled ? color : 'none'} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.875 3L14.8139 8.95489L21.3855 9.9098L16.6302 14.545L17.7528 21.0901L11.875 17.9999L5.99713 21.0901L7.11969 14.545L2.36443 9.9098L8.93604 8.95489L11.875 3Z"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
)
