import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { scrollBar } from 'styles/theme'
import TimelineItemComponents from './TimelineItems'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CircularProgress } from '@material-ui/core'
import useMainFeed from './useMainFeed'
import LinkButton from 'components/common/buttons/LinkButton'
import { isUserOfRole } from 'utils/authUtils'
import { Roles } from 'consts/authConsts'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
    ...scrollBar,
    '&::-webkit-scrollbar': {
      background: 'var(--bg-color-timelineV2)',
      width: 5
    }
  },
  noScroll: {
    flexDirection: 'column !important'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 10
  }
}))

const MAIN_FEED_CONTAINER_ID = 'main-feed-container'

const MainFeed = ({ timelineItems, setSelectedTimelineItem }) => {
  const classes = useStyles()
  const containerRef = useRef()
  const { displayedTimelineItems, hasMoreItems, loadMoreItems, handleSelectTimelineItem } = useMainFeed({
    timelineItems,
    setSelectedTimelineItem
  })

  const [hasVerticalScroll, setHasVerticalScroll] = useState(true)

  const isDevAdmin = useMemo(() => isUserOfRole([Roles.DevAdmin]), [])

  const handleClickCopyTimelineItemId = useCallback(timelineItem => navigator.clipboard.writeText(timelineItem.id), [])

  useEffect(() => {
    if (containerRef?.current) {
      setHasVerticalScroll(!!(containerRef.current.scrollHeight > containerRef.current.clientHeight))
    }
  }, [])

  return (
    <div
      id={MAIN_FEED_CONTAINER_ID}
      className={`${classes.root} ${hasVerticalScroll ? '' : classes.noScroll}`}
      ref={containerRef}
    >
      <InfiniteScroll
        dataLength={displayedTimelineItems.length}
        next={loadMoreItems}
        // Reversing the list so it will be from bottom to top
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          justifyContent: 'flex-end'
        }}
        hasMore={hasMoreItems}
        inverse
        loader={
          <div className={classes.loaderContainer}>
            <CircularProgress className={classes.loader} size={25} />
          </div>
        }
        endMessage=""
        scrollableTarget={MAIN_FEED_CONTAINER_ID}
      >
        {displayedTimelineItems.map((item, i) => (
          <>
            {React.createElement(TimelineItemComponents[item.type], {
              timelineItem: item,
              key: `timeline-item=${i}`,
              onSelect: ({ section }) => handleSelectTimelineItem({ timelineItem: item, section })
            })}
            {isDevAdmin && (
              <LinkButton underline label={'Copy ID'} onClick={() => handleClickCopyTimelineItemId(item)} spacing={0} />
            )}
          </>
        ))}
      </InfiniteScroll>
    </div>
  )
}

export default MainFeed
