import * as React from 'react'

const Phone = props => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.9696 13.5701L15.0575 10.6683C14.3966 10.0058 13.3238 10.0045 12.6613 10.6654C12.6603 10.6664 12.6594 10.6673 12.6584 10.6683L10.9899 12.3368C10.8637 12.4633 10.6648 12.4808 10.5184 12.3782C9.60522 11.7401 8.75037 11.0221 7.96389 10.233C7.2595 9.53023 6.61153 8.77314 6.02592 7.96863C5.91978 7.82411 5.9352 7.62372 6.06217 7.49711L7.77212 5.78716C8.4323 5.12571 8.4323 4.05468 7.77212 3.39323L4.86004 0.481152C4.189 -0.160384 3.132 -0.160384 2.46095 0.481152L1.53863 1.40347C0.145716 2.78072 -0.351543 4.82574 0.253559 6.68877C0.705093 8.05182 1.34335 9.34569 2.15005 10.5336C2.87629 11.6226 3.70947 12.6363 4.63725 13.5597C5.64592 14.5756 6.76322 15.4774 7.96905 16.249C9.29462 17.114 10.7501 17.7612 12.2802 18.1662C12.6735 18.2631 13.0772 18.3118 13.4823 18.3112C14.8711 18.3027 16.1998 17.7437 17.1769 16.7567L17.9697 15.9639C18.6298 15.3025 18.6298 14.2315 17.9696 13.5701ZM17.2358 15.2572C17.2352 15.2579 17.2345 15.2586 17.2338 15.2593L17.2389 15.2438L16.4461 16.0365C15.4275 17.068 13.944 17.4924 12.5339 17.1558C11.1133 16.7755 9.76286 16.1703 8.53371 15.363C7.39176 14.6331 6.33348 13.78 5.37807 12.8187C4.49899 11.9461 3.70923 10.9879 3.02039 9.95845C2.26694 8.85075 1.67058 7.64406 1.24826 6.37271C0.764116 4.87918 1.16531 3.24033 2.28461 2.13931L3.20693 1.21699C3.46336 0.959403 3.88004 0.958493 4.13757 1.21492C4.13823 1.21559 4.13896 1.21626 4.13963 1.21699L7.05171 4.12907C7.30929 4.3855 7.31021 4.80218 7.05377 5.0597C7.05311 5.06037 7.05244 5.06104 7.05171 5.06177L5.34176 6.77172C4.85112 7.25702 4.78942 8.02819 5.19669 8.58533C5.81515 9.43411 6.49956 10.2328 7.24347 10.9741C8.07288 11.8071 8.97455 12.5648 9.93792 13.2385C10.4946 13.6268 11.2492 13.5613 11.7308 13.0831L13.3837 11.4042C13.6401 11.1466 14.0568 11.1457 14.3143 11.4021C14.315 11.4028 14.3157 11.4035 14.3164 11.4042L17.2337 14.3267C17.4913 14.583 17.4923 14.9996 17.2358 15.2572Z"
        fill="black"
      />
    </svg>
  )
}

export default Phone
