import React, { useCallback, useMemo, useState, useEffect } from 'react'
import useRolePermissions from 'hooks/useRolePermissions'
import { makeStyles } from '@material-ui/styles'
import { MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Triangle } from 'components/common/icons'
import GrinMenu from 'components/common/menu/GrinMenu'
import MemberCell from './MemberCell'
import { MEMBER_TYPES } from 'consts/appConsts'
import { isBoolean } from 'lodash'
import { Check } from 'components/common/icons'
import { Grid, CircularProgress } from '@material-ui/core'

const useStyles = ({ disabled }) =>
  makeStyles({
    memberType: {
      color: ({ disabled }) => (disabled ? 'var(--text-color-21)' : 'inherit')
    },
    clickableCell: {
      cursor: 'pointer'
    },
    triangle: {
      marginLeft: 5,
      '& svg': {
        transform: 'rotate(180deg)'
      }
    },
    membersTableMenu: {
      color: 'var(--text-color-6)',
      '& ul': {
        padding: 0
      }
    },
    membersTableMenuItem: {
      height: 50,
      padding: '5px 6px 5px 0px',
      width: 192,
      fontSize: 14,
      '&:hover': {
        backgroundColor: 'var(--bg-color-14)'
      }
    },
    membersTableMenuItemIcon: {
      width: 44,
      height: 50,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center'
    }
  })({ disabled })

const MemberAccessCell = ({ accessType, disabled = false, onChangeAccessType = () => {} }) => {
  const classes = useStyles({ disabled })
  const { t } = useTranslation()

  const { permissions } = useRolePermissions()
  const [isAccessMenuOpen, setIsAccessMenuOpen] = useState(false)
  const [isLoadingAccessType, setIsLoadingAccessType] = useState(true)

  useEffect(() => {
    if (isLoadingAccessType) {
      setIsLoadingAccessType(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessType])

  const actions = useMemo(
    () => [
      {
        checked: accessType === 'manager',
        title: t('pages.accountSettings.practiceMembers.types.accountManager'),
        onClick: () => onChangeAccessType('manager'),
        condition: () => true
      },
      {
        checked: accessType === 'member',
        title: t('pages.accountSettings.practiceMembers.types.teamMember'),
        onClick: () => onChangeAccessType('member'),
        condition: () => true
      }
    ],
    [accessType, t, onChangeAccessType]
  )

  const clickable = useMemo(
    () => accessType !== MEMBER_TYPES.owner.key && !disabled && permissions.editPracticeMembers,
    [accessType, disabled, permissions.editPracticeMembers]
  )

  const handleMenuItemClick = useCallback(action => {
    setIsAccessMenuOpen(false)
    action.onClick()
    setIsLoadingAccessType(true)
  }, [])

  const TriggerComponent = () => (
    <span className={[classes.memberType, clickable && classes.clickableCell].join(' ')}>
      {accessType && MEMBER_TYPES[accessType].label}
      <span className={classes.triangle}>
        <Triangle color="black" />
      </span>
    </span>
  )

  const filteredActions = useMemo(() => actions.filter(action => !action.condition || action.condition()), [actions])

  return (
    <MemberCell>
      {isLoadingAccessType ? (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress color="secondary" />
          </Grid>
        </Grid>
      ) : accessType === MEMBER_TYPES.owner.key || disabled || !permissions.editPracticeMemberAccessType ? (
        <span className={classes.memberType}>{MEMBER_TYPES[accessType].label}</span>
      ) : (
        permissions.editPracticeMemberAccessType && (
          <GrinMenu
            open={isAccessMenuOpen}
            triggerComponent={<TriggerComponent />}
            transformOrigin={{ vertical: -40, horizontal: 'left' }}
            className={classes.membersTableMenu}
            onOpen={() => setIsAccessMenuOpen(true)}
            onClose={() => setIsAccessMenuOpen(false)}
          >
            {filteredActions.map(action => (
              <MenuItem
                key={action.title}
                onClick={() => handleMenuItemClick(action)}
                className={classes.membersTableMenuItem}
              >
                {isBoolean(action.checked) && (
                  <span className={classes.membersTableMenuItemIcon}>{action.checked && <Check color="black" />}</span>
                )}
                {action.title}
              </MenuItem>
            ))}
          </GrinMenu>
        )
      )}
    </MemberCell>
  )
}

export default MemberAccessCell
