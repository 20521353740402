import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'

const useStyles = makeStyles(theme => ({
  root: {
    height: '310px',
    width: '100%',
    backgroundColor: 'var(--bg-color-14)',
    padding: 80,
    borderRadius: 6
  }
}))

const MissingScanMessage = ({ message = '' }) => {
  const classes = useStyles()

  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.root}>
      <Grid item>
        <DazzedParagraph16 strong color="var(--text-color-57)" textAlign="center">
          {message}
        </DazzedParagraph16>
      </Grid>
    </Grid>
  )
}

export default MissingScanMessage
