import * as React from 'react'

const ScanSent = ({ color = '#001335', isEnabled = false }) => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 10.5C8 9.94772 8.44772 9.5 9 9.5H17C17.5523 9.5 18 9.94772 18 10.5V18.5C18 19.0523 17.5523 19.5 17 19.5H9C8.44772 19.5 8 19.0523 8 18.5V10.5Z"
        stroke={isEnabled ? '#4151E6' : color}
        strokeLinejoin="round"
      />
      <path
        d="M18 13.5352C18 13.2008 18.1671 12.8886 18.4453 12.7031L20.9453 11.0365C21.6099 10.5934 22.5 11.0698 22.5 11.8685V17.1315C22.5 17.9302 21.6099 18.4066 20.9453 17.9635L18.4453 16.2969C18.1671 16.1114 18 15.7992 18 15.4648V13.5352Z"
        stroke={isEnabled ? '#4151E6' : color}
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ScanSent
