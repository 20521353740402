import * as React from 'react'

export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8Z"
      fill="#26C36F"
    />
    <path d="M5 8L7 10L11.5 5.5" stroke="white" strokeWidth="2" />
  </svg>
)
