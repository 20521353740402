import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Collapse, Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Trans, useTranslation } from 'react-i18next'
import SharingMethods from './SharingMethods'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 15
  },
  autoApprovalLinksToggle: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  autoApprovalLinksContainer: {
    margin: '10px 0 10px 0'
  },
  note: {
    paddingBottom: 10
  }
}))

const AutoApprovaLinks = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [showAutoApprovalLinks, setShowAutoApprovalLinks] = useState(false)

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid item>
        <DazzedParagraph14 strong>
          {t('pages.accountSettings.services.remoteConsultation.setups.onlineLeads.note')}
        </DazzedParagraph14>
      </Grid>
      <Grid
        item
        onClick={() => {
          setShowAutoApprovalLinks(!showAutoApprovalLinks)
        }}
      >
        <DazzedParagraph14 strong className={classes.autoApprovalLinksToggle} color={'var(--text-color-25)'}>
          {t(
            `pages.accountSettings.services.remoteConsultation.setups.onlineLeads.${
              !showAutoApprovalLinks ? 'showAutoApprovalLinks' : 'hideAutoApprovalLinks'
            }`
          )}
        </DazzedParagraph14>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={showAutoApprovalLinks}>
          <Grid container direction="column" className={classes.autoApprovalLinksContainer}>
            <Grid item className={classes.note}>
              <DazzedParagraph14 strong>
                <Trans
                  i18nKey={'pages.accountSettings.services.remoteConsultation.setups.onlineLeads.autoApprovalLinksNote'}
                  components={{ underline1: <u>{''}</u> }}
                ></Trans>
              </DazzedParagraph14>
            </Grid>
            <Grid item>
              <SharingMethods recommendedMethod="landingPage" linkType="autoApproval" />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  )
}

export default AutoApprovaLinks
