import React, { useMemo, useState } from 'react'
import CachedImage from 'components/common/CachedImage'
import { makeStyles } from '@material-ui/core'
import { Download3 as DownloadIcon } from 'components/common/icons'
import { isMobile } from 'utils/mobileUtils'
import { getObjectUrl } from 'utils/mediaUtils'
import { useCallback } from 'react'
import { downloadFileFromUrl } from 'utils/fileUtils'
import ImagesViewer from 'components/common/ImagesViewer'

const useStyles = makeStyles(({ isImageLoading, alignLeft, isLocal }) => ({
  message: {
    cursor: 'pointer',
    borderRadius: 8,
    minWidth: 180,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: ({ alignLeft }) => (alignLeft ? 'flex-start' : 'flex-end'),
    position: 'relative',
    '&:hover div': {
      visibility: 'visible'
    }
  },
  preview: {
    objectFit: 'cover',
    maxWidth: 250,
    maxHeight: 500,
    borderRadius: 8
  },
  messageUserInfo: {
    marginLeft: 5
  },
  timestamp: {
    fontSize: 10,
    textAlign: 'right'
  },
  downloadButton: {
    visibility: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'var(--bg-color-10)',
    marginTop: 5,
    marginRight: 5,
    width: 30,
    height: 30,
    borderRadius: 8,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    '&:hover': {
      backgroundColor: 'var(--bg-color-9)'
    }
  },
  text: {
    paddingTop: 10
  }
}))

const MessageImageViewer = ({
  isLocal,
  image,
  isImageViewerOpen,
  setImageViewerState,
  cache,
  timestamp,
  text,
  alignLeft,
  timestampClassName,
  messageClassName,
  previewClassName,
  name
}) => {
  const [isImageLoading, setIsImageLoading] = useState(true)
  const classes = useStyles({ isImageLoading, alignLeft, isLocal })

  const src = useMemo(() => image && getObjectUrl(image), [image])
  const handleDownload = useCallback(
    async event => {
      event.stopPropagation()
      downloadFileFromUrl(src)
    },
    [src]
  )

  return (
    <>
      <ImagesViewer isVisible={isImageViewerOpen} onClose={() => setImageViewerState(false)} imageS3Objects={[image]} />
      <div
        className={[classes.message, messageClassName].join(' ')}
        onClick={() => !isImageLoading && setImageViewerState(true)}
      >
        {!isMobile() && (
          <div className={classes.downloadButton} onClick={handleDownload}>
            <DownloadIcon fill="var(--bg-color-7)" />
          </div>
        )}
        {isLocal && cache ? (
          <img src={cache} className={[classes.preview, previewClassName].join(' ')} alt="..." />
        ) : (
          <CachedImage
            s3Object={image}
            applyClassName={classes.preview}
            onLoad={url => {
              setIsImageLoading(false)
            }}
          />
        )}
        {text && <div className={classes.text}>{text}</div>}
        <div className={[classes.timestamp, timestampClassName].join(' ')}>{timestamp}</div>
      </div>
    </>
  )
}

export default MessageImageViewer
