import * as React from 'react'

const Send = ({ color = '#fff' }) => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.656826 0.0931737L12.5 5.75L0.656827 11.4069V7L7.5 5.75003L0.656827 4.5L0.656826 0.0931737Z"
        fill={color}
      />
    </svg>
  )
}

export default Send
