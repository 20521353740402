import * as React from 'react'

const InfoSmall = ({ fill = 'none', stroke = '#4151E6', ...props }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="7" cy="7" r="6" stroke={stroke} />
      <path d="M7 7V10" stroke={stroke} strokeLinecap="round" />
      <path d="M7 4.5L7 5" stroke={stroke} strokeLinecap="round" />
    </svg>
  )
}

export default InfoSmall
