import React from 'react'

const CATermsDoc = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '60vh',
        overflowY: 'auto',
        padding: '0 50px',
        color: 'white',
        '@media (maxWidth: 960px)': {
          padding: 0
        }
      }}
    >
      <p style={{ textAlign: 'justify' }}>
        <strong>Last Updated: July 23, 2021</strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        These Terms of Use (as modified, the &ldquo;<strong>Terms</strong>&rdquo;) govern (a) the use by you, a licensed
        orthodontist&nbsp; (&ldquo;<strong>Orthodontist</strong>&rdquo; or &ldquo;<strong>you</strong>&rdquo;) of the
        Grin scope product (the &ldquo;<strong>Devices</strong>&rdquo;) and (b) your right to access and use the Grin
        services (as modified the &ldquo;<strong>Grin Services</strong>&rdquo;) through your subscription (the &ldquo;
        <strong>Subscription</strong>&rdquo;) and our platform (as modified, the &ldquo;<strong>Platform</strong>
        &rdquo;). The website at get-grin.com&nbsp; (the &ldquo;<strong>Site</strong>&rdquo;), and the various other
        related services, premium and other features, functions, software, applications and websites (together with the
        Devices, the Grin Services, the Platform and the Site, collectively the &ldquo;<strong>Services</strong>&rdquo;)
        are provided and operated, and are being made available to you, your practice (the &ldquo;
        <strong>Practice</strong>&rdquo;), the other Orthodontists, and the other users of any of the Services
        (collectively, &ldquo;<strong>Users</strong>&rdquo;) by Get-Grin Inc. (&ldquo;<strong>Grin</strong>&rdquo;,
        &ldquo;<strong>we</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo; or &ldquo;<strong>us</strong>&rdquo;). All
        defined terms not defined in these Terms of Use shall have the meaning given to them in the applicable Order or
        the Terms and Conditions of Sale. These Terms are a legally binding contract between you and Grin regarding your
        Subscription and your access and use of the Devices, the Grin Services, the Platform and the other
        Services.&nbsp;
      </p>
      <p style={{ textAlign: 'center' }}>
        <strong>PLEASE READ THE FOLLOWING TERMS CAREFULLY.</strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>
          IF YOU ARE AGREEING TO THESE TERMS ON BEHALF OF YOUR PRACTICE, REFERENCES TO &ldquo;YOU&rdquo; OR
          &ldquo;YOUR&rdquo; SHALL MEAN YOU, YOUR PRACTICE AND ANY OTHER USER ACCESSING AND USING THE DEVICES, THE GRIN
          SERVICES AND THE PLATFORM ON BEHALF OF SUCH PRACTICE. BY CLICKING &ldquo;I ACCEPT,&rdquo; OR BY DOWNLOADING,
          INSTALLING, OR OTHERWISE ACCESSING OR USING THE SITE, THE DEVICES, THE GRIN SERVICES, THE PLATFORM OR ANY OF
          THE OTHER SERVICES OR OTHERWISE SIGNIFYING YOUR ACCEPTANCE OF THESE TERMS, YOU REPRESENT AND WARRANT THAT (A)
          YOU ARE AUTHORIZED TO ENTER THESE TERMS OF USE FOR AND ON BEHALF OF YOURSELF (AND YOUR PRACTICE), AND ARE
          DOING SO, (B) YOU (AND YOUR PRACTICE) CAN LEGALLY ENTER INTO THESE TERMS AND (C) YOU HAVE READ AND UNDERSTAND
          AND AGREE THAT YOU (AND YOUR PRACTICE) SHALL BE BOUND BY THESE TERMS OF USE AND GRIN&rsquo;S PRIVACY POLICY
          (HTTPS://GET-GRIN.COM/PRIVACY/)(THE &ldquo;PRIVACY POLICY&rdquo;) AND ALL MODIFICATIONS AND ADDITIONS PROVIDED
          FOR. IF YOU DO NOT AGREE TO THESE TERMS OF USE OR THE PRIVACY POLICY, YOU ARE NOT AUTHORIZED TO ACCESS AND USE
          THE DEVICES, THE GRIN SERVICES OR THE PLATFORM
        </strong>
        .&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        YOU MUST BE CURRENTLY DULY LICENSED AS AN ORTHODONTIST AND IN GOOD STANDING WITH THE STATE MEDICAL BOARD OF THE
        STATE OR, IF IN CANADA, THE APPLICABLE DENTAL REGULATORY COLLEGE IN THE PROVINCE, WHERE YOU ARE LICENSED TO
        ACCESS AND USE THE DEVICES, THE&nbsp; GRIN SERVICES AND THE PLATFORM.&nbsp;&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        YOU ARE SOLELY RESPONSIBLE FOR DETERMINING WHETHER YOUR PATIENTS (&ldquo;<strong>PATIENTS</strong>&rdquo;) ARE
        ELIGIBLE TO ACCESS AND USE THE DEVICES AND THE GRIN SERVICES. IF YOU DO NOT AUTHORIZE A PATIENT TO ACCESS AND
        USE THE DEVICE AND THE GRIN SERVICES, THEN THIS PATIENT WILL BE PROHIBITED FROM ACCESSING AND USING THE DEVICE
        AND THE GRIN SERVICES.
      </p>
      <ol>
        <li>
          <strong>ARBITRATION NOTICE. </strong>Except for certain kinds of disputes as described in Section 11, you
          agree that disputes arising under these Terms will be resolved by binding, individual arbitration, and BY
          ACCEPTING THESE TERMS, YOU AND GRIN ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY
          CLASS ACTION OR REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend
          your rights under this contract (except for matters that may be taken to small claims court). Your rights will
          be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury. <strong>Eligibility.</strong>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        TO ACCESS AND USE THE DEVICES, THE GRIN SERVICES AND THE PLATFORM YOU MUST HAVE REACHED THE AGE OF MAJORITY IN
        YOUR JURISDICTION OF RESIDENCE. BY CLICKING &ldquo;I AGREE,&rdquo; ACKNOWLEDGING YOUR AGREEMENT, AGREEING
        VERBALLYOR OTHERWISE ACCESSING OR USING THE DEVICES, THE GRIN SERVICES, THE PLATFORM OR ANY OF THE SERVICES, YOU
        REPRESENT THAT:&nbsp;
      </p>
      <ul>
        <li>
          YOU ARE CURRENTLY DULY LICENSED AS AN ORTHODONTIST AND IN GOOD STANDING WITH THE STATE MEDICAL BOARD OF THE
          STATE OR, IF IN CANADA, THE APPLICABLE DENTAL REGULATORY COLLEGE IN THE PROVINCE, WHERE YOU ARE LICENSED;
        </li>
      </ul>
      <ul>
        <li>&nbsp;YOU CONFIRM THAT YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH GRIN;</li>
      </ul>
      <ul>
        <li>
          YOU WILL COMPLY WITH THESE TERMS AND ALL APPLICABLE LOCAL, STATE, PROVINCIAL NATIONAL AND INTERNATIONAL LAWS,
          RULES AND REGULATIONS; AND
        </li>
      </ul>
      <ul>
        <li>
          YOU ARE NOT A COMPETITOR OF GRIN AND DO NOT INTEND TO USE THE DEVICES, THE GRIN SERVICES OR THE PLATFORM FOR
          REASONS THAT ARE IN COMPETITION WITH GRIN OR OTHERWISE TO REPLICATE THE DEVICES OR SOME OR ALL OF THE GRIN
          SERVICES FOR ANY REASON.&nbsp;
        </li>
      </ul>
      <ol>
        <li>
          <strong>Privacy.</strong>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        Your privacy and the privacy of your Patients are important to Grin. Our goal is to make the Devices, the Grin
        Services and the Platform as good, useful and rewarding for you and your Patients as possible. In order to do
        that, Grin may collect and process information from you or from or about your Patients, including personally
        identifiable information as set forth in more detail in our Privacy Policy. By accessing or using the Devices,
        the Grin Services, the Platform or any of the other Services, you agree that Grin may collect, use and disclose,
        as set forth in the Privacy Policy, the information you provide when you purchase any Devices or access and use
        the Devices, the Grin Services, the Platform or any of the other Services, and in some cases, information that
        is provided by or through any of the Services.&nbsp;
      </p>
      <h3 style={{ textAlign: 'justify' }}>
        You represent and warrant that you have provided all necessary notices and disclosures, obtained all necessary
        consents and otherwise have all authority under applicable privacy laws for Grin to collect, use and process
        personal information (including patient data) on your behalf to provide the Services and as otherwise described
        in our Privacy Policy. Grin has no obligation to review any notices or consents or determine the sufficiency of
        such processes under privacy laws. We will use personal information in performing the Services &ldquo;as
        is&rdquo;, and we are not responsible for reviewing, validating or otherwise confirming the accuracy,
        appropriateness or completeness of such data. Notwithstanding the foregoing, Grin shall comply with all
        applicable state, provincial and federal laws and regulations regarding the collection, use and disclosure of
        personal information and personal health information, including the privacy and confidentiality of patient
        records including but not limited to (i) The Health Insurance Portability and Accountability Act of 1996
        (&ldquo;HIPAA&rdquo;); (ii) the Privacy and Security Standards (45 C.F.R. Parts 160 and 164) and the Standards
        for Electronic Transactions (45 C.F.R Parts 160 and 162) (collectively, the &ldquo;Standards&rdquo;) promulgated
        or as to be promulgated by the Secretary of Health and Human Services on and after the applicable effective
        dates specified in the Standards; (iii) The Health Information Technology Economic and Clinical Health Act of
        2009 (the &ldquo;HITECH Act&rdquo;); and (iv) in Canada, federal and provincial privacy and health privacy
        legislation applicable to Grin. Grin shall not disclose to any third party, except where required or permitted
        by law and the Business Associate Agreement attached hereto as Exhibit A (the &ldquo;BAA&rdquo;) or Canadian
        Privacy Addendum attached hereto as Exhibit B, any medical record or other information regarding any Patients,
        and in such case, disclosures shall be made in accordance with the applicable policies of the parties, the BAA,
        Canadian Privacy Addendum and the Standards. All medical information and personal information concerning
        specific Patients, including but not limited to the identification of the Patients, derived from the access and
        use of the Devices and the Grin Services shall be treated and maintained in a confidential manner.
      </h3>
      <h3 style={{ textAlign: 'justify' }}>
        You acknowledge that Grin may from time-to-time issue updated or upgraded versions of the App and may (subject
        to your device settings) automatically electronically update or upgrade the version of the App that you are then
        currently using on your mobile device. You consent to receive updates or upgrades to the App automatically
        without providing further consent each time. The App (including any updates or upgrades) may: (i) cause your
        device to automatically communicate with our servers to deliver the functionality described in the App
        description or through new features as they are introduced, and to record usage metrics; (ii) affect preferences
        or data stored on your device; and (iii) collect personal information as set out in our Privacy Policy
        (www.get-grin.com/privacy). We are not responsible if an update or upgrade affects how the App works if this is
        caused by your own equipment or device not supporting the update or upgrade. You can withdraw consent at any
        time by uninstalling the app or by contacting us at support@get-grin.com.
      </h3>
      <ol>
        <li>
          <strong>General.</strong>
        </li>
      </ol>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>Communications</span>. The Devices, the Grin Services and the
          Platform, including the &ldquo;Remote Consultation&rdquo; feature are part of Grin&rsquo;s communication
          platform to assist you in communicating with your Patients. How you and your Patients choose to communicate
          with each other, however, is strictly between you and your Patients. The Grin Services are just one way of
          communicating with your Patients and you are free to use any other means to contact or interact with your
          Patients.&nbsp;
        </li>
        <li>
          <span style={{ textDecoration: 'underline' }}>Your Patients</span>. YOU ARE SOLELY RESPONSIBLE FOR (A)
          DECIDING WHICH PATIENTS YOU WILL ACCEPT INTO YOUR PRACTICE AND (B) ANY AND ALL ORTHODONTIC OR OTHER SERVICES
          OR ADVICE YOU PROVIDE IN CONNECTION WITH THE DEVICES, THE GRIN SERVICES OR OTHERWISE.
        </li>
        <li>
          <span style={{ textDecoration: 'underline' }}>Not Medical, Dental or Orthodontic Services</span>. GRIN DOES
          NOT PROVIDE MEDICAL, DENTAL OR ORTHODONTIC SERVICES. GRIN PROVIDES THE DEVICES AND THE GRIN SERVICES TO
          FACILITATE AN ORTHODONTIST&rsquo;S PROVISION OF ORTHODONTIC SERVICES TO HIS OR HER PATIENTS. NOTHING IN THE
          GRIN SERVICES OR THE PLATFORM SHOULD BE INTERPRETED AS MEDICAL, DENTAL OR ORTHODONTIC ADVICE, DIAGNOSIS OR
          TREATMENT. NOTHING IN THE GRIN SERVICES OR PLATFORM IS OR SHOULD BE CONSIDERED, OR USED AS A SUBSTITUTE FOR,
          MEDICAL, DENTAL OR ORTHODONTIC ADVICE, DIAGNOSIS OR TREATMENT. You should always discuss any questions or
          concerns that your Patients might have about their health or medical condition.&nbsp;
        </li>
      </ol>
      <ol>
        <li>
          <strong>Subscription; Account; Access.</strong>&nbsp;&nbsp;
        </li>
      </ol>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>Subscription</span>. You must purchase a Subscription to access
          and use the Grin Services, unless otherwise agreed by Grin. The purchase of a Subscription is governed by the
          applicable Order and the Terms and Conditions of Sale.&nbsp;
        </li>
        <li>
          <span style={{ textDecoration: 'underline' }}>Account</span>. In order to make use of the Devices, the Grin
          Services and the Platform, you will have to create an account (&ldquo;<strong>Account</strong>&rdquo;).&nbsp;
          You agree not to create an Account for anyone else (other than other Users within your Practice) or use the
          account of another without their permission (other than the account of other User within your Practice).&nbsp;
          When creating your Account, you will be required to provide us with some information about your Practice and
          yourself such as your name, email address or other contact information.&nbsp; You shall not share your Account
          or access to the Grin Services or the Platform with any other person except as authorized under these Terms.
          You agree that the information you provide is accurate and complete information, and that you will keep it
          accurate and up-to-date at all times. You are solely responsible for the activity that occurs in, and
          maintaining the confidentiality of, your Account and password.&nbsp; You must notify us immediately of any
          breach of security or unauthorized use of your Account. As between you and us, you are solely responsible and
          liable for the activity that occurs in connection with your Account.&nbsp;&nbsp;&nbsp;
        </li>
        <li>
          <span style={{ textDecoration: 'underline' }}>Access</span>. Subject to your complete and ongoing compliance
          with these Terms, Grin grants you, solely for use in your professional capacity as an orthodontist, a limited,
          non-exclusive, non-transferable, non-sublicensable, revocable right during the term of your Subscription to
          access and use the Devices, the Grin Services and the Platform.
        </li>
        <li>
          <span style={{ textDecoration: 'underline' }}>Prevention of Unauthorized Use</span>. Grin reserves the right
          to exercise whatever lawful means it deems necessary to prevent the unauthorized access or use of the Grin
          Services or the Platform or the circumvention of the other Services, including, but not limited to,
          technological barriers, IP mapping, and directly contacting your Internet Service Provider (ISP) regarding
          such unauthorized use.
        </li>
      </ol>
      <ol>
        <li>
          <strong>Reviews</strong>. Orthodontists, Patients and other Users may submit opinions, assessments and reviews
          about the Device, the Grin Services and the Platform (&ldquo;<strong>User Review</strong>&rdquo;). You shall
          be solely responsible for your User Review and the consequencesof posting, publishing or uploading them. We do
          not verify the correctness or reliability of any User Reviews. We have complete discretion whether to publish
          your User Review and we reserve the right in our sole discretion and without further notice to you, to
          monitor, censor, edit, remove, delete, and/or remove any User Review. You acknowledge and agree that Grin
          shall have no liability for any and all losses or damages that may arise or result from a User Review
          <strong>.</strong>By submitting a User Review, you hereby grant Grin a worldwide, irrevocable, non-exclusive,
          fully paid, royalty-free, perpetual, sublicensable and transferable license to use, reproduce, distribute,
          prepare derivative works of and display the User Review for any purpose, including without limitation for
          publishing and redistributing part or all of your User Reviews (and derivative works thereof) in any media
          formats and through any media channels and to improve the Device, the Grin Services, the Platform and the
          other Services without restriction and create other products and services, and you hereby waive any moral
          rights in your User Reviews or rights to compensation, to the extent permitted by law.&nbsp;
        </li>
        <li>
          <strong>Prohibited Conduct</strong>.&nbsp;&nbsp;
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        BY ACCESSING OR USING THE DEVICES, THE GRIN SERVICES, THE PLATFORM OR ANY OF THE OTHER SERVICES, YOU AGREE NOT
        TO:
      </p>
      <ol>
        <li>
          Decipher, decompile, disassemble, reverse engineer, modify, translate, or otherwise attempt to derive source
          code, algorithms, tags, specifications, architectures, structures or other elements of the Devices, the Grin
          Services, the Platform or any of the other Services, in whole or in part (except to the extent that the laws
          of your jurisdiction make such restrictions unenforceable);
        </li>
        <li>
          Access or use the Devices, the Grin Services or the Platform for the benefit of anyone other than your
          Practice or your Patients except in accordance with these Terms, including selling, renting, reselling or
          distributing, in whole or in part, the Devices, the Grin Services or the Platform;
        </li>
        <li>
          &nbsp;Copy, disclose, or distribute any data or other information available through the Devices, the Grin
          Services, the Platform or any of the other Services and/or information, in any medium, including without
          limitation on the Site, by any automated or non-automated &ldquo;scraping&rdquo;;
        </li>
        <li>
          Interfere with, circumvent or disable any security or other technological features or measures of any of the
          Grin Services or the Platform or attempt to gain unauthorized access to the Grin Services or the Platform or
          its related systems or networks;
        </li>
        <li>
          Use bots or other automated methods to access the Grin Services, the Platform or any of the other Services,
          download any information, send or redirect messages or perform any other activities through the Grin Services,
          the Platform or any of the other Services;
        </li>
        <li>
          Take any action that Grin determines, in its sole discretion, imposes or may impose, an unreasonable or
          disproportionately large load on its infrastructure;
        </li>
        <li>
          Upload invalid data, viruses, worms or other software agents through the Grin Services, the Platform or any of
          the other Services;
        </li>
        <li>
          Collect, harvest or derive any personally identifiable information, including names, email addresses from the
          Grin Services, the Platform and/or any of the other Services except as may be expressly contemplated by these
          Terms and the Privacy Policy;
        </li>
        <li>
          Impersonate any person or entity, use a fictitious name, or misrepresent your affiliation with a person or
          entity;
        </li>
        <li>
          Use the Devices, the Grin Services, the Platform or any of the other Services for any unlawful or
          inappropriate activities; or
        </li>
        <li>
          Use any of the Services for any commercial solicitation purposes except as authorized by these Terms,
          including sending commercial electronic messages.
        </li>
      </ol>
      <ol>
        <li>
          <strong>Device Use and Restrictions.</strong>
        </li>
        <ol>
          <li>
            Except in connection with the &ldquo;Remote Consultation&rdquo; feature, it is your responsibility to
            provide the Devices to your patients. With respect to the &ldquo;Remote Consultation&rdquo; feature, the
            Devices will be shipped directly to your Patients in accordance with the applicable Order and your
            directions.
          </li>
          <li>
            The Devices may only be used by your Patients in connection with the Grin Services in accordance with
            instructions provided by you or Grin.
          </li>
        </ol>
        <li>
          <strong>Intellectual Property Rights.</strong>
        </li>
        <ol>
          <li>
            <span style={{ textDecoration: 'underline' }}>Ownership</span>.&nbsp; You acknowledge and agree that all
            right, title and interest in (a) the underlying technology of the Devices, (b) the Grin Services, (c) the
            Platform, (d) the Site and (e) all intellectual property rights evidenced by, embodied in and/or related to
            the Devices, the Grin Services, the Platform and the other Services, including any copyrights, patents and
            trade secrets, are and shall remain the exclusive property of Grin and/or its third-party licensors. Grin
            shall also own all right, title and interest in any anonymous performance results generated under or by the
            Devices and the Grin Services. We reserve all rights not expressly granted hereunder or in the Terms and
            Conditions of Sale in and to the Devices, the Grin Services and the Platform.
          </li>
        </ol>
      </ol>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>Content and Marks</span>.&nbsp; You acknowledge and agree that:
          (i) the content on the Grin Services, the Platform, the Site and/or any of the other Services, including
          without limitation, the text, descriptions, software, applications, source code, object code, graphics,
          photos, sounds, videos, interactive features, and services (collectively, the &ldquo;
          <strong>Materials</strong>
          &rdquo;), and (ii) the trademarks, service marks and logos contained therein (&ldquo;<strong>Marks,</strong>
          &rdquo; together with the Materials, the &ldquo;<strong>Content</strong>&rdquo;), are the property of Grin
          and/or its third-party licensors and may be protected by applicable copyright or other intellectual property
          laws and treaties.&nbsp; &ldquo;Get-Grin&rdquo;, &ldquo;Grin&rdquo;, the Grin logo, and other marks are Marks
          of Grin or its affiliates.<em>&nbsp; </em>All other trademarks, service marks, and logos used on the Devices,
          the Grin Services, the Platform or any of the other Services are the trademarks, service marks, or logos of
          their respective owners.&nbsp; We reserve all rights not expressly granted in and to the
          Content.&nbsp;&nbsp;&nbsp;
        </li>
      </ol>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>Use of Content</span>.&nbsp; The Content is provided to you for
          your use only in connection with the Services, and may not be used, modified, copied, distributed,
          transmitted, broadcast, displayed, sold, licensed, de-compiled, or otherwise exploited for any other purposes
          except in connection with the advertising, marketing and promotion of the Grin Services, subject to
          Grin&rsquo;s prior written approval, which approval shall not be unreasonably withheld. &nbsp; If you download
          or print a copy of the Content you must retain all copyright and other proprietary notices contained therein.
          You shall not make any changes to any of the promotional or other marketing materials provided by Grin without
          Grin&rsquo;s prior written approval, which approval shall not be unreasonably withheld.
        </li>
      </ol>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>Feedback</span>.&nbsp; If you choose to provide input,
          suggestions, comments, feedback, improvement requests or other recommendations regarding the Devices, the Grin
          Services, the Platform or any of the other Services (collectively, &ldquo;<strong>Feedback</strong>&rdquo;),
          then you hereby grant Grin a worldwide, irrevocable, non-exclusive, fully paid, royalty-free, perpetual,
          sublicensable and transferable license to use or otherwise exploit the Feedback in any manner and for any
          purpose without restriction. You should not submit any Feedback to us that you do not wish to license to us as
          stated above. We have no obligation (a) to maintain any Feedback in confidence; (b) to pay any compensation
          for any Feedback; or (c) to respond to any Feedback. You grant us the right to use the name that you submit in
          connection with any Feedback.&nbsp;
        </li>
      </ol>
      <ol>
        <li>
          <strong>Warranty Disclaimers.</strong>
        </li>
      </ol>
      <ol>
        <li>
          This Section 8 applies whether or not you have paid for the Devices, the Grin Services or the Platform. SOME
          JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES. IF YOU RESIDE IN SUCH A JURISDICTION, THESE
          TERMS APPLY TO YOU TO THE FULLEST EXTENT SUCH EXCLUSIONS ARE PERMITTED UNDER THE LAWS OF THE JURISDICTION
          WHERE YOU RESIDE.&nbsp;&nbsp;
        </li>
        <li>
          THE DEVICES, THE GRIN SERVICES, THE PLATFORM AND THE OTHER SERVICES ARE PROVIDED ON AN &ldquo;AS IS&rdquo; AND
          &ldquo;AS AVAILABLE&rdquo; BASIS, AND WITHOUT REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND EITHER
          EXPRESS OR IMPLIED.&nbsp; GRIN HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
          TO IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND THOSE
          ARISING BY STATUTE OR FROM A COURSE OF DEALING OR USAGE OF TRADE PRACTICE.&nbsp; GRIN DOES NOT GUARANTEE THAT
          THE GRIN SERVICES, THE PLATFORM OR THE OTHER SERVICES WILL BE FREE OF BUGS, MALFUNCTIONS, SECURITY BREACHES,
          VIRUS ATTACKS, OR ILLEGAL PENETRATIONS. THE GRIN SERVICES, THE PLATFORM AND THE OTHER SERVICES MAY
          OCCASIONALLY BE UNAVAILABLE FOR ROUTINE MAINTENANCE, UPGRADING, OR OTHER REASONS.&nbsp; GRIN DOES NOT WARRANT
          THAT ANY OF THE AFOREMENTIONED ISSUES WILL BE CORRECTED. YOU AGREE THAT GRIN WILL NOT BE HELD RESPONSIBLE FOR
          ANY CONSEQUENCES TO YOU OR ANY PATIENT OR OTHER THIRD PARTY THAT MAY RESULT FROM TECHNICAL PROBLEMS OF THE
          INTERNET, SLOW CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF OUR OR OTHER SERVERS.&nbsp; WE DO NOT WARRANT,
          ENDORSE OR GUARANTEE ANY CONTENT, PRODUCT, OR SERVICE THAT IS FEATURED OR ADVERTISED ON THE GRIN SERVICES, THE
          PLATFORM OR ANY OF THE OTHER SERVICES BY A THIRD PARTY.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </li>
        <li>
          GRIN SHALL NOT BE LIABLE TO YOU, THE PATIENTS OR OTHER USERS IN ANY WAY FOR THE RESULTS OR OUTCOMES OF THE
          DEVICES OR THE GRIN SERVICES PROVIDED HEREUNDER OR FOR ANY ORTHODONTIC, MEDICAL OR CLINICAL ADVICE, TREATMENT
          OR DIAGNOSTIC PROVIDED THROUGH THE DEVICES, THE GRIN SERVICES OR THE PLATFORM, INCLUDING, WITHOUT LIMITATION,
          FOR YOUR ACTS OR OMISSIONS AND/OR OR ANY ACTIVITIES OCCURRING ON THE PREMISES OF YOUR PRACTICE. FOR CLARITY,
          ALL THE ABOVE ARE YOUR RESPONSIBILITY AND NOT GRIN&rsquo;S RESPONSIBILITY.&nbsp;
        </li>
        <li>
          YOU HEREBY ACKNOWLEDGE THAT YOUR USE OF THE DEVICES, THE GRIN SERVICES, THE PLATFORM OR ANY OF THE OTHER
          SERVICES IS VOLUNTARY AND AT YOUR OWN RISK, AND YOU AGREE THAT GRIN WILL NOT BE HELD RESPONSIBLE OR
          LIABLE&nbsp; FOR ANY CONSEQUENCES (SUCH AS, BUT NOT LIMITED TO, HEALTH PROBLEMS, PERSONAL INJURY, OR DEATH) TO
          ANY PATIENT OR OTHER THIRD PARTY THAT RESULT FROM (I) YOUR USE OF (OR INABILITY TO USE) THE DEVICES, THE GRIN
          SERVICES, THE PLATFORM OR ANY OF THE OTHER SERVICES OR (II) YOUR NEGLECT OR MISUSE OF THE DEVICES OR THE GRIN
          SERVICES. YOU ACKNOWLEDGE THAT GRIN IS NOT A LICENSED MEDICAL CARE PROVIDER AND THAT YOU OR OTHER MEDICAL
          PROVIDERS ARE SOLELY RESPONSIBLE FOR THE ORTHODONTIC OR MEDICAL INFORMATION OR ADVICE PROVIDED BY YOU OR THE
          OTHER PROVIDERS THROUGH THE DEVICES, THE GRIN SERVICES OR THE PLATFORM. YOU HEREBY ACKNOWLEDGE THAT ANY
          ORTHODONTIC, MEDICAL, OR OTHER SIMILAR PROFESSIONAL ADVICE PROVIDED VIA THE DEVICES, THE GRIN SERVICES OR THE
          PLATFORM IS YOUR RESPONSIBILITY.&nbsp;
        </li>
      </ol>
      <ol>
        <li>
          <strong>Limitation of Liability.</strong>&nbsp;&nbsp;
        </li>
      </ol>
      <ol>
        <li>
          TO THE FULLEST EXTENT PERMISSIBLE BY LAW, AND EXCEPT FOR GRIN&rsquo;S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT,
          NEITHER GRIN NOR ANY OF ITS AFFILIATES SHALL BE LIABLE FOR LOST PROFITS, LOST BUSINESS OPPORTUNITIES OR COSTS
          OF PROCUREMENT OF SUBSTITUTE GOODS BY ANYONE OR FOR ANY DIRECT, INDIRECT, EXEMPLARY, SPECIAL, CONSEQUENTIAL,
          PUNITIVE OR INCIDENTAL DAMAGES OF ANY KIND, OR FOR ANY LOSS OF DATA, REPUTATION OR ANY OTHER INTANGIBLE LOSS,
          ARISING FROM OR IN CONNECTION WITH THESE TERMS, YOUR ORTHODONTIC SERVICES OR OUT OF YOUR ACCESS OR USE OF, OR
          INABILITY TO ACCESS OR USE, THE DEVICES, THE GRIN SERVICES OR THE PLATFORM, WHETHER BASED ON WARRANTY,
          CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, EVEN IF&nbsp; GRIN OR ANY OF ITS
          AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES. Some jurisdictions do not allow the
          limitation or exclusion of liability for incidental or consequential damages, so the above limitations may not
          apply to you.
        </li>
      </ol>
      <ol>
        <li>
          IN NO EVENT SHALL THE AGGREGATE LIABILITY OF GRIN FOR ANY DAMAGES ARISING UNDER THESE TERMS, YOUR ORTHODONTIC
          SERVICES OR OUT OF YOUR ACCESS OR USE OF, OR INABILITY TO ACCESS AND USE, THE DEVICES, THE GRIN SERVICES OR
          THE PLATFORM EXCEED THE TOTAL AMOUNT OF FEES PAID BY YOU TO GRIN IN CONNECTION WITH THE PURCHASE OF THE
          DEVICES AND YOUR SUBSCRIPTION IN THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO
          THE CLAIM.&nbsp;
        </li>
      </ol>
      <ol>
        <li>
          EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR
          EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS. THIS
          ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS
          IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS SECTION 9 WILL
          APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
        </li>
      </ol>
      <ol>
        <li>
          <strong>Indemnity</strong>.&nbsp; To the fullest extent permitted by law, you agree to defend, indemnify and
          hold harmless Grin and its affiliates, and our and their respective officers, directors, employees and agents,
          from and against any and all claims, damages, obligations, losses, liabilities, costs and expenses (including
          but not limited to attorney&rsquo;s fees) arising from (a) the violation of these Terms by you (including any
          of your employees or other representatives), (b) the misuse of the Devices, the Grin Services or the Platform
          by you (including any of your employees or other representatives), (c) the violation of law by you (including
          any of your employees or other representatives) or (d) any claims asserted by or on behalf of any of your
          Patients related to, or in connection with, the orthodontic and other services that you (including any of your
          representatives) provide.&nbsp;
        </li>
      </ol>
      <ol>
        <li>
          <strong>Dispute Resolution and Arbitration&nbsp;</strong>
        </li>
      </ol>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>Generally</span>. In the interest of resolving disputes between
          you and Grin in the most expedient and cost-effective manner, and except as described in Sections 11(b) and
          11(c), you and Grin agree that every dispute arising in connection with these Terms will be resolved by
          binding arbitration. Arbitration is less formal than a lawsuit in court. Arbitration uses a neutral arbitrator
          instead of a judge or jury, may allow for more limited discovery than in court, and can be subject to very
          limited review by courts. Arbitrators can award the same damages and relief that a court can award. This
          agreement to arbitrate disputes includes all claims arising out of or relating to any aspect of these Terms,
          whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless
          of whether a claim arises during or after the termination of these Terms. YOU UNDERSTAND AND AGREE THAT, BY
          ENTERING INTO THESE TERMS, YOU AND GRIN ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A
          CLASS ACTION.
        </li>
      </ol>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>Exceptions</span>
          <strong>.</strong> Despite the provisions of Section 11(a), nothing in these Terms will be deemed to restrict
          or otherwise limit the right of either party to: (a) bring an individual action in small claims court to the
          extent that the applicable requirements have been met; (b) pursue an enforcement action through the applicable
          federal, state, provincial or local agency if that action is available; (c) seek injunctive relief in a court
          of law in aid of arbitration; or (d) to file suit in any court of competent jurisdiction to address an
          intellectual property infringement claim.
        </li>
      </ol>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>Opt-Out</span>. If you do not wish to resolve disputes by
          binding arbitration, you may opt out of the provisions of this Section 11 within 30 days after the date that
          you agree to these Terms by sending a letter to Get-Grin, Inc., Attention: Legal Department &ndash;
          Arbitration Opt-Out, 382A Rte 59, Airmont, NY 10952 that specifies: your full legal name, the email address
          associated with your account on the Grin Services, and a statement that you wish to opt out of arbitration
          (&ldquo;
          <strong>Opt-Out Notice</strong>&rdquo;). Once Grin receives your Opt-Out Notice, this Section 11 (other than
          Section 11(b)) will be null and void, and in that case, the exclusive jurisdiction and venue described in
          Section 17 will govern any action arising out of or related to these Terms or any of the Services. The
          remaining provisions of these Terms will not be affected by your Opt-Out Notice.&nbsp;
        </li>
      </ol>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>Arbitration</span>. Any arbitration between you and Grin will be
          settled under the Federal Arbitration Act and administered by the American Arbitration Association (&ldquo;
          <strong>AAA</strong>&rdquo;) under its (i) Arbitration Rules if Orthodontist is located in the United States
          or (ii) International Arbitration Rules if Orthodontist is located outside of the United States (collectively,
          &ldquo;<strong>AAA Rules</strong>&rdquo;) as modified by these Terms. The AAA Rules and filing forms are
          available online at <span style={{ textDecoration: 'underline' }}>www.adr.org</span>, by calling the AAA at
          1-800-778-7879, or by contacting Grin. The arbitrator has exclusive authority to resolve any dispute relating
          to the interpretation, applicability, or enforceability of this binding arbitration agreement. Any arbitration
          hearing will take place at a location to be agreed upon in New York County, New York, but if the claim is for
          $10,000 USD or less, you may choose whether the arbitration will be conducted: (a) solely on the basis of
          documents submitted to the arbitrator; or (b) through a non-appearance based telephone hearing. Regardless of
          the manner in which the arbitration is conducted, the arbitrator must issue a reasoned written decision
          sufficient to explain the essential findings and conclusions on which the decision and award, if any, are
          based.
        </li>
      </ol>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>Notice of Arbitration; Process</span>. A party who intends to
          seek arbitration must first send a written notice of the dispute to the other party by certified or registered
          mail or by Federal Express (signature required) or, only if that other party has not provided a current
          physical address, then by electronic mail (&ldquo;<strong>Notice of Arbitration</strong>&rdquo;). Grin&rsquo;s
          address for Notice is: Get-Grin, Inc., 382A Rte 59, Airmont, NY 10952. The Notice of Arbitration must: (a)
          describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought (&ldquo;
          <strong>Demand</strong>&rdquo;). The parties will make good faith efforts to resolve the claim directly, but
          if the parties do not reach an agreement to do so within thirty (30) days after the Notice of Arbitration is
          received, either you or Grin may commence an arbitration proceeding. All arbitration proceedings between the
          parties will be confidential unless otherwise agreed by the parties in writing. During the arbitration, the
          amount of any settlement offer made by you or Grin must not be disclosed to the arbitrator until after the
          arbitrator makes a final decision and award, if any. If the arbitrator awards you an amount higher than the
          last written settlement amount offered by Grin in settlement of the dispute prior to the award, then Grin will
          pay to you the higher of: (i) the amount awarded by the arbitrator; or (ii) $10,000.00 USD.&nbsp;
        </li>
      </ol>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>Fees; Prevailing Party</span>. If you commence arbitration in
          accordance with these Terms, Grin will reimburse you for your payment of the filing fee, unless your claim is
          for more than $10,000 USD, in which case the payment of any fees will be decided by the AAA Rules. The party
          prevailing in any arbitration shall be entitled to receive in addition to all other damages to which it may be
          entitled, the costs incurred by such party in conducting the suit, action or proceeding, including reasonable
          attorneys&rsquo; fees and expenses and court costs.The arbitrator may make rulings and resolve disputes as to
          the payment and reimbursement of fees or expenses at any time during the proceeding and upon request from
          either party made within fourteen (14) days of the arbitrator&rsquo;s ruling on the merits.
        </li>
      </ol>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>No Class Actions</span>. YOU AND GRIN AGREE THAT EACH PARTY MAY
          BRING CLAIMS AGAINST THE OTHER PARTY ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS
          MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and Grin otherwise agree,
          the arbitrator may not consolidate more than one person&rsquo;s claims, and may not otherwise preside over any
          form of a representative or class proceeding.
        </li>
      </ol>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>Modifications to this Arbitration Provision</span>. If Grin
          makes any future change to this arbitration provision, other than a change to Grin&rsquo;s address for Notice
          of Arbitration, you may reject the change by sending us written notice within 30 days of the change to
          Grin&rsquo;s address for Notice of Arbitration, in which case (i) your account with Grin and your right to
          access and use the Devices, the Grin Services and the Platform will be immediately terminated and (ii) this
          arbitration provision, as in effect immediately prior to the changes you rejected will survive.
        </li>
      </ol>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>Enforceability</span>. If Section 11(h) or the entirety of this
          Section 11 is found to be unenforceable, then the entirety of this Section 11 (other than Section 11(b)) will
          be null and void and, in that case, the exclusive jurisdiction and venue described in Section 17 will govern
          any action arising out of or related to these Terms or any of the Services.
        </li>
      </ol>
      <ol>
        <li>
          YOU AGREE THAT ANY CAUSE OF ACTION THAT YOU MAY HAVE ARISING OUT OF OR RELATED TO YOUR USE OF THE DEVICES, THE
          GRIN SERVICES, THE PLATFORM OR ANY OF THE OTHER SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF
          ACTION ACCRUES,&nbsp; OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
        </li>
      </ol>
      <ol>
        <li>
          <strong>Termination</strong>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        (a)<span style={{ textDecoration: 'underline' }}>Grin</span>.&nbsp; If you violate any of these Terms, Grin may,
        upon prior written notice to you, suspend your Subscription and your ability to access and use the Grin Services
        and the Platform. You agree that Grin in its sole discretion, for any or no reason, and without penalty, may
        terminate your access and use of the Devices, the Grin Services, the Platformor any of the other Services or any
        account (or any part thereof) that you may have with Grin and remove and discard all or any part of your
        account, user profile, at any time. Grin may also in its sole discretion and at any time prohibit you from
        accessing and using the Devices, the Grin Services and the Platformor discontinue providing access to the Grin
        Services and the Platformor any of the other Services, or any part thereof, with or without notice. You agree
        that any termination of your ability to access or use the Devices, the Grin Services, the Platformor any of the
        other Services or any account you may have, or portion thereof may be effected without prior notice, and you
        agree that Grin will not be liable to you or any third party for any such termination or suspect. These remedies
        are in addition to any other remedies that Grin may have at law or in equity. As provided herein, Grin does not
        permit copyright infringing activities on the Site or any of the other Services, and Grin shall be permitted to
        terminate access to the Site or any of the other Services. BY ACCEPTING THESE TERMS, YOU WAIVE AND SHALL HOLD
        GRIN HARMLESS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY GRIN DURING OR AS A RESULT OF ITS
        INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER GRIN OR LAW
        ENFORCEMENT AUTHORITIES.
      </p>
      <p style={{ textAlign: 'justify' }}>
        (b)<span style={{ textDecoration: 'underline' }}>You</span>. Your only remedy with respect to any
        dissatisfaction with (i) the Devices, the Grin Services, the Platformor any of the other Services, (ii) any term
        of these Terms or (iii) any policy or practice of Grin in operating the Grin Services, the Platform or the other
        Services, is to terminate your Subscription and your use of the Devices, the Grin Services and the Platform, any
        of the other Services and your account (if any). You may terminate your use of the Services and your account (if
        any) at any time by sending an email request to us at{' '}
        <span style={{ textDecoration: 'underline' }}>support@get-grin.com</span>.
      </p>
      <ol>
        <li>
          Upon termination of these Terms for any reason, you shall cease all use of the Devices, the Grin Services and
          the Platform.&nbsp;
        </li>
      </ol>
      <ol>
        <li>
          This Section 12 and Section 2 (<em>Privacy</em>), 5 (<em>Prohibited Conduct</em>), 7 (
          <em>Intellectual Property Rights</em>), 8 (<em>Warranty Disclaimers</em>), 9 (<em>Limitation of Liability</em>
          ), 10 (<em>Indemnity</em>), 11 (<em>Dispute Resolution and Arbitration</em>), Section 15 (<em>Export</em>),
          Section 17 (<em>Governing Law</em>) and Section 23 (<em>Miscellaneous</em>) shall survive the termination of
          these Terms.
        </li>
      </ol>
      <ol>
        <li>
          <strong>Independent Contractors.</strong>&nbsp; You and Grin are independent contractors. Nothing in these
          Terms creates a partnership, joint venture, agency, or employment relationship between you and Grin. You must
          not under any circumstances make, or undertake, any warranties, representations, commitments or obligations on
          behalf of Grin.
        </li>
        <li>
          <strong>Modification.&nbsp;&nbsp;</strong>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        (a)Grin reserves the right, in its sole discretion, to change, modify, add, or remove portions of these Terms at
        any time by posting the amended Terms to the Site or any of the other Services. If Grin updates these Terms, it
        will update the &ldquo;<strong>last updated</strong>&rdquo; date at the top of the Terms.&nbsp; Please check
        these Terms, including any Additional Terms, periodically for changes. Your continued use of the Devices, the
        Grin Services, the Platform or any of the other Services after the posting of changes constitutes your binding
        acceptance of such changes. In the event of a change to these Terms that materially modifies your rights or
        obligations, Grin will use commercially reasonable efforts to notify you of such change. Grin may provide notice
        through a pop-up or banner within any of the Services, by sending an email to any address you may have used to
        register for an account, or through other similar mechanisms. Additionally, if the changed Terms materially
        modify your rights or obligations, Grin may require you to provide consent by accepting the changed Terms. If
        Grin requires your acceptance of the changed Terms, changes are effective only after your acceptance. For all
        other changes, except as stated elsewhere by Grin, such amended Terms will automatically be effective, replacing
        the previously-effective Terms or fees, thirty (30) days after they are initially posted on any of the
        Services.&nbsp;{' '}
        <strong>
          IF AT ANY TIME YOU DO NOT AGREE TO THESE TERMS, PLEASE IMMEDIATELY TERMINATE YOUR USE OF ALL SERVICES.
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        To the extent that any modifications to the Terms or Additional Terms are not allowed under applicable laws, the
        prior most recent version of the Terms or Additional Terms shall continue to apply.
      </p>
      <p style={{ textAlign: 'justify' }}>
        (b)Grin reserves the right to modify, in its absolute discretion and without notice to you (or any other User),
        the Devices, the Grin Services and/or the Platform and their content, including its design and the services that
        are provided through them.
      </p>
      <ol>
        <li>
          <strong>Export Laws.</strong>You agree to comply fully with all applicable export laws and regulations to
          ensure that none of the Devices, the Grin Services or the Platform nor any technical data related thereto are
          exported or re-exported directly or indirectly in violation of, or used for any purposes prohibited by, such
          laws and regulations<strong>.</strong>
        </li>
        <li>
          <strong>Assignment.&nbsp; </strong>These Terms, and any rights and licenses granted hereunder, may not be
          transferred or assigned by you, in whole or in part, but may be assigned by Grin without restriction or
          notification to you. Any prohibited assignment shall be null and void.&nbsp;
        </li>
        <li>
          <strong>Governing Law.&nbsp; </strong>These Terms and the relationship between you and Grin shall be governed
          by and construed in accordance with the laws of the State of New York, without regard to its principles of
          conflict of laws. The exclusive jurisdiction in every matter and/or issue regarding the Terms and Conditions
          of Sale or the Terms of Use that is not subject to arbitration or any exception pursuant to Section 11 will be
          exclusively granted to the state and Federal courts in New York County, New York.&nbsp; The party prevailing
          in any legal suit, action or proceeding shall be entitled to receive in addition to all other damages to which
          it may be entitled, the costs incurred by such party in conducting the suit, action or proceeding, including
          reasonable attorneys&rsquo; fees and expenses and court costs.
        </li>
        <li>
          <strong>Additional Terms</strong>. Your Subscription and your access and use of the Devices, the Grin Services
          and the Platform are subject to all additional terms, policies, rules, or guidelines applicable to the
          Devices, the Grin Services or the Platform that we may post on or link to or from the Site, the Grin Services
          or the Platform (collectively, the &ldquo;<strong>Additional Terms</strong>&rdquo;). All Additional Terms are
          incorporated by this reference into, and made a part of, these Terms.&nbsp;
        </li>
        <li>
          <strong>Consent to Electronic Communications</strong>. By using the Devices, the Grin Services, the Platform
          or any of the other Services, you consent to receiving certain electronic communications from us as further
          described in our Privacy Policy. Please read our Privacy Policy to learn more about our electronic
          communications practices. You agree that any notices, agreements, disclosures, or other communications that we
          send to you electronically will satisfy any legal communication requirements, including that those
          communications be in writing.
        </li>
        <li>
          <strong>Support</strong>. Support shall be provided via email at{' '}
          <span style={{ textDecoration: 'underline' }}>support@get-grin.com</span> or through the &ldquo;Help&rdquo;
          link on our website between the hours of 9:00 am and 5:00 pm EST Monday to Friday, excluding all Federal,
          state and other company holidays. Grin shall use commercially reasonable efforts to respond to any issues
          within one (1) business day.&nbsp;
        </li>
        <li>
          <strong>Geographic Restrictions</strong>. Grin is based in the state of New York in the United States. The
          Devices, the Grin Services and the Platform are intended solely for users located within the United States and
          Canada. Grin makes no claims that accessing or using or that the Devices, the Grin Services or the Platform or
          any of the content is accessible or appropriate outside of the United States or Canada. Accessing or using the
          Devices, the Grin Services or the Platform may not be legal by certain persons or in certain countries. If you
          access or use the Devices, the Grin Services or the Platform from outside the United States or Canada, you do
          so on your own initiative and are responsible for compliance with local laws and you agree to waive, and
          hereby do waive, any legal or equitable rights or remedies you have or may have against Grin with respect
          thereto.
        </li>
        <li>
          <strong>Miscellaneous.</strong> These Terms, the Privacy Policy, the applicable Order and the Terms and
          Conditions of Sale constitute the entire agreement between you and Grin concerning your Subscription and the
          Devices, the Grin Services, the Platform and the other Services. Use of section headers in these Terms is for
          convenience only and will not have any impact on the interpretation of any provision. Throughout these Terms,
          the use of the word &ldquo;including&rdquo; means &ldquo;including but not limited to&rdquo;.&nbsp; If any
          provision of these Terms is deemed invalid by a court of competent jurisdiction, the invalidity of such
          provision shall not affect the validity of the remaining provisions of these Terms, which shall remain in full
          force and effect.&nbsp; NO WAIVER OF ANY TERM OF THESE TERMS SHALL BE DEEMED A FURTHER OR CONTINUING WAIVER OF
          SUCH TERM OR ANY OTHER TERM, AND A PARTY&rsquo;S FAILURE TO ASSERT ANY RIGHT OR PROVISION UNDER THESE TERMS
          SHALL NOT CONSTITUTE A WAIVER OF SUCH RIGHT OR PROVISION.&nbsp; You hereby waive any applicable rights to
          require an original (non-electronic) signature or delivery or retention of non-electronic records, to the
          extent not prohibited under applicable law.
        </li>
        <li>
          <strong>Contact Information</strong>. The Devices, the Grin Services, the Platform and the other Services are
          offered by Get-Grin Inc., located at 382A Rte 59, Airmont, NY 10952. If you have any questions, complaints, or
          claims regarding the Devices, the Grin Services, the Platform or any of the other Service, you may contact us
          by sending correspondence to that address or by emailing us at{' '}
          <a href="mailto:support@get-grin.com" target="_blank" rel="noopener noreferrer">
            <span style={{ color: '#0000ff' }}>support@get-grin.com</span>
          </a>
          .&nbsp;
        </li>
        <li>
          <strong>Notice to California Residents</strong>. If you are a California resident, under California Civil Code
          Section 1789.3, you may contact the Complaint Assistance Unit of the Division of Consumer Services of the
          California Department of Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202, Sacramento,
          California 95834, or by telephone at (800) 952-5210 in order to resolve a complaint regarding the Devices, the
          Grin Services or the Platform or to receive further information regarding use of the Devices, the Grin
          Services or the Platform.
        </li>
        <li>
          <strong>English Language.</strong> It is the express wish of the parties that these Terms and related
          documents be drawn up in English. Il est de la volont&eacute; expresse des parties que cette convention et
          tous les documents s&rsquo;y rattachant soient r&eacute;dig&eacute;s en anglais.
        </li>
      </ol>
      <p style={{ textAlign: 'center' }}>
        <strong>&copy; Get-Grin Inc. 2021</strong>
      </p>
      <p style={{ textAlign: 'center' }}>
        <strong>EXHIBIT A</strong>
      </p>
      <p style={{ textAlign: 'center' }}>
        <strong>HIPAA BUSINESS ASSOCIATE AGREEMENT</strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        This Business Associate Agreement (&ldquo;<strong>BAA</strong>&rdquo;) is entered into and effective upon your
        acceptance of the Terms between you (&ldquo;<strong>Covered Entity</strong>&rdquo;) and Get-Grin, Inc. (&ldquo;
        <strong>Business Associate</strong>&rdquo;) (each a &ldquo;<strong>Party&rdquo; </strong>and collectively, the
        &ldquo;<strong>Parties</strong>&rdquo;).
      </p>
      <p style={{ textAlign: 'center' }}>
        <strong>RECITALS</strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        A.Covered Entity is a &ldquo;covered entity&rdquo; as that term is defined under the Health Insurance
        Portability and Accountability Act of 1996 (Public Law 104-91), as amended, (&ldquo;<strong>HIPAA</strong>
        &rdquo;), and the regulations promulgated thereunder by the Secretary of the U.S. Department of Health and Human
        Services (&ldquo;<strong>Secretary</strong>&rdquo;), including, without limitation, the regulations codified at
        45 C.F.R. Parts 160 and 164 (&ldquo;<strong>HIPAA Regulations</strong>&rdquo;);&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        B.Business Associate performs Services (as defined below) for or on behalf of Covered Entity, and in performing
        said Services; Business Associate creates, receives, maintains, or transmits Protected Health Information
        (&ldquo;<strong>PHI</strong>&rdquo;);&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        C.The Parties intend to protect the privacy and provide for the security of PHI Disclosed by Covered Entity to
        Business Associate, or received or created by Business Associate, when providing Services in compliance with
        HIPAA, the Health Information Technology for Economic and Clinical Health Act (Public Law 111-005) (&ldquo;the{' '}
        <strong>HITECH Act</strong>&rdquo;) and its implementing regulations and guidance issued by the Secretary, and
        other applicable state and federal laws, all as amended from time to time; and
      </p>
      <p style={{ textAlign: 'justify' }}>
        D.As a covered entity, Covered Entity is required under HIPAA to enter into a BAA with Business Associate that
        meets certain requirements with respect to the Use and Disclosure of PHI, which are met by this BAA.
      </p>
      <p style={{ textAlign: 'center' }}>
        <strong>AGREEMENT</strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        In consideration of the Recitals and for other good and valuable consideration, the receipt and adequacy of
        which is hereby acknowledged, the Parties agree as follows:
      </p>
      <ol>
        <li>
          <h1 style={{ textAlign: 'center' }}>DEFINITIONS</h1>
        </li>
      </ol>
      <p>
        The following terms shall have the meaning set forth below.&nbsp; Capitalized terms used in this BAA and not
        otherwise defined shall have the meanings ascribed to them in HIPAA, the HIPAA Regulations, or the HITECH Act,
        as applicable.
      </p>
      <ol>
        <li>
          <h2>
            &ldquo;Breach&rdquo; shall have the meaning given under 42 U.S.C. &sect; 17921(1) and 45 C.F.R. &sect;
            164.402.
          </h2>
        </li>
        <li>
          <h2>
            &ldquo;Designated Record Set&rdquo; shall have the meaning given such term under 45 C.F.R. &sect;
            164.501.&nbsp;&nbsp;
          </h2>
        </li>
        <li>
          <h2 style={{ textAlign: 'justify' }}>
            &ldquo;Disclose&rdquo; and &ldquo;Disclosure&rdquo; mean, with respect to PHI, the release, transfer,
            provision of access to, or divulging in any other manner of PHI outside of Business Associate or to other
            than members of its Workforce, as set forth in 45 C.F.R. &sect; 160.103.
          </h2>
        </li>
        <li>
          <h2 style={{ textAlign: 'justify' }}>
            &ldquo;Electronic PHI&rdquo; or &ldquo;e-PHI&rdquo; means PHI that is transmitted or maintained in
            electronic media, as set forth in 45 C.F.R. &sect; 160.103.
          </h2>
        </li>
        <li>
          <h2 style={{ textAlign: 'justify' }}>
            &ldquo;Protected Health Information&rdquo; and &ldquo;PHI&rdquo; mean any information, whether oral or
            recorded in any form or medium, that: (a) relates to the past, present or future physical or mental health
            or condition of an individual; the provision of health care to an individual, or the past, present or future
            payment for the provision of health care to an individual; (b) identifies the individual (or for which there
            is a reasonable basis for believing that the information can be used to identify the individual); and (c)
            shall have the meaning given to such term under the Privacy Rule, including, but not limited to, 45 C.F.R.
            &sect; 160.103.&nbsp; Protected Health Information includes e-PHI.&nbsp;&nbsp;
          </h2>
        </li>
        <li>
          <h2 style={{ textAlign: 'justify' }}>
            &ldquo;Security Incident&rdquo; shall have the meaning given to such term under 45 C.F.R. &sect;
            164.304.&nbsp;&nbsp;
          </h2>
        </li>
        <li>
          <h2 style={{ textAlign: 'justify' }}>
            &ldquo;Services&rdquo; shall mean the services for or functions on behalf of Covered Entity provided by
            Business Associate pursuant to any service or other agreement(s) between Covered Entity and Business
            Associates which may be in effect now or from time to time (&ldquo;Underlying Agreement&rdquo;), or, if no
            such agreement is in effect, the services or functions performed by Business Associate that constitute a
            Business Associate relationship, as set forth in 45 C.F.R. &sect; 160.103.
          </h2>
        </li>
        <li>
          <h2>
            &ldquo;Unsecured PHI&rdquo; shall have the meaning given to such term under 42 U.S.C. &sect; 17932(h), 45
            C.F.R. &sect; 164.402, and guidance issued pursuant to the HITECH Act including, but not limited to the
            guidance issued on April 17, 2009 and published in 74 Federal Register 19006 (April 27, 2009) by the
            Secretary.
          </h2>
        </li>
        <li>
          <h2>
            &ldquo;Use&rdquo; or &ldquo;Uses&rdquo; mean, with respect to PHI, the sharing, employment, application,
            utilization, examination or analysis of such PHI within Business Associate&rsquo;s internal operations, as
            set forth in 45 C.F.R. &sect; 160.103.
          </h2>
        </li>
        <li>
          <h2>&ldquo;Workforce&rdquo; shall have the meaning given to such term under 45 C.F.R. &sect; 160.103.</h2>
        </li>
      </ol>
      <ol>
        <li>
          <h1 style={{ textAlign: 'center' }}>TERM AND TERMINATION</h1>
        </li>
        <ol>
          <li>
            <h2 style={{ textAlign: 'justify' }}>
              Term &nbsp; The term of this Agreement shall be effective as of the Effective Date and shall terminate
              upon the termination of the Underlying Agreement between the Parties, unless earlier terminated pursuant
              to the terms hereof.&nbsp;&nbsp;
            </h2>
          </li>
          <li>
            <h2 style={{ textAlign: 'justify' }}>
              Termination for Cause Upon Covered Entity&rsquo;s knowledge of a material breach or violation of this BAA
              by Business Associate, Covered Entity shall either:&nbsp;
            </h2>
          </li>
        </ol>
      </ol>
      <ol>
        <li>
          Notify Business Associate of the breach in writing, and provide an opportunity for Business Associate to cure
          the breach or end the violation within ten (10) business days of such notification; provided that if Business
          Associate fails to cure the breach or end the violation within such time period to the satisfaction of Covered
          Entity, Covered Entity may immediately terminate this BAA upon written notice to Business Associate;&nbsp;
        </li>
        <li>
          Upon written notice to Business Associate, immediately terminate this BAA if Covered Entity determines that
          such breach cannot be cured; or
        </li>
      </ol>
      <ol>
        <li>
          If neither termination nor cure is feasible, Covered Entity shall report the violation to the Secretary.
        </li>
      </ol>
      <ol>
        <li>
          <h2>Disposition of Protected Health Information Upon Termination or Expiration&nbsp;&nbsp;</h2>
        </li>
        <ol>
          <li>
            <h3 style={{ textAlign: 'justify' }}>
              Upon termination or expiration of this BAA, Business Associate shall either return or destroy all PHI
              received from, or created or received by Business Associate on behalf of Covered Entity, that Business
              Associate still maintains in any form and retain no copies of such PHI.&nbsp; If Covered Entity requests
              that Business Associate return PHI, PHI shall be returned in a mutually agreed upon format and timeframe,
              at no additional charge to Covered Entity.&nbsp;&nbsp;
            </h3>
          </li>
          <li>
            <h3 style={{ textAlign: 'justify' }}>
              If return or destruction is not feasible, Business Associate shall (a) retain only that PHI which is
              necessary for Business Associate to continue its proper management and administration or to carry out its
              legal responsibilities; (b) return to Covered Entity the remaining PHI that Business Associate still
              maintains in any form; (c) continue to extend the protections of this BAA to the PHI for as long as
              Business Associate retains the PHI; (d) limit further Uses and Disclosures of such PHI to those purposes
              that make the return or destruction of the PHI infeasible and subject to the same conditions set out in
              Section 3.1 and 3.2 above, which applied prior to termination; and (e) return to Covered Entity the PHI
              retained by Business Associate when it is no longer needed by Business Associate for its proper management
              and administration or to carry out its legal responsibilities.&nbsp;
            </h3>
          </li>
        </ol>
      </ol>
      <ol>
        <li>
          <h1 style={{ textAlign: 'center' }}>OBLIGATIONS OF BUSINESS ASSOCIATE</h1>
        </li>
        <ol>
          <li>
            <h2 style={{ textAlign: 'justify' }}>
              Permitted Uses and Disclosures of Protected Health Information Business Associate shall not Use or
              Disclose PHI other than to perform the Services, as permitted or required by this BAA, or as required by
              law.&nbsp; Business Associate shall not Use or Disclose PHI in any manner that would constitute a
              violation of Subpart E of 45 C.F.R. Part 164 if so Used or Disclosed by Covered Entity.&nbsp; However,
              Business Associate may Use or Disclose PHI (i) for the proper management and administration of Business
              Associate; (ii) to carry out the legal responsibilities of Business Associate, provided that with respect
              to any such Disclosure either: (a) the Disclosure is Required by Law; or (b) Business Associate obtains a
              written agreement from the person to whom the PHI is to be Disclosed that such person will hold the PHI in
              confidence and will not Use and further Disclose such PHI except as Required by Law and for the purpose(s)
              for which it was Disclosed by Business Associate to such person, and that such person will notify Business
              Associate of any instances of which it is aware in which the confidentiality of the PHI has been breached;
              (iii) for Data Aggregation purposes for the Health Care Operations of Covered Entity.&nbsp; To the extent
              that Business Associate carries out one or more of Covered Entity&rsquo;s obligations under Subpart E of
              45 C.F.R. Part 164, Business Associate must comply with the requirements of Subpart E that apply to the
              Covered Entity in the performance of such obligations.
            </h2>
          </li>
          <li>
            <h2 style={{ textAlign: 'justify' }}>
              Prohibited Marketing and Sale of PHI &nbsp; Notwithstanding any other provision in this BAA, Business
              Associate shall comply with the following requirements:&nbsp; (i) Business Associate shall not Use or
              Disclose PHI for fundraising or marketing purposes, except to the extent expressly authorized or permitted
              by this BAA and consistent with the requirements of 42 U.S.C. &sect; 17936, 45 C.F.R. &sect;&sect;
              164.514(f), and 164.508(a)(3)(ii), and (iii) Business Associate shall not directly or indirectly receive
              remuneration in exchange for PHI except with the prior written consent of Covered Entity and as permitted
              by the HITECH Act, 42 U.S.C. &sect; 17935(d)(2), and 45 C.F.R. &sect; 164.502(a)(5)(ii).&nbsp;&nbsp;
            </h2>
          </li>
          <li>
            <h2 style={{ textAlign: 'justify' }}>
              Adequate Safeguards of PHI Business Associate shall implement and maintain appropriate safeguards to
              prevent Use or Disclosure of PHI other than as provided for by this BAA.&nbsp; Business Associate shall
              reasonably and appropriately protect the confidentially, integrity, and availability of e-PHI that it
              creates, receives, maintains or transmits on behalf of Covered Entity in compliance with Subpart C of 45
              C.F.R. Part 164 to prevent Use or Disclosure of PHI other than as provided for by this BAA.
            </h2>
          </li>
          <li>
            <h2 style={{ textAlign: 'justify' }}>
              Mitigation Business Associate agrees to mitigate, to the extent practicable, any harmful effect that is
              known to Business Associate of a Use or Disclosure of PHI by Business Associate in violation of the
              requirements of this BAA.
            </h2>
          </li>
          <li>
            <h2 style={{ textAlign: 'justify' }}>Reporting Non-Permitted Use or Disclosure</h2>
          </li>
          <ol>
            <li>
              <h3 style={{ textAlign: 'justify' }}>
                Reporting Security Incidents and Non-Permitted Use or Disclosure&nbsp; Business Associate shall report
                to Covered Entity in writing each Security Incident or Use or Disclosure that is made by Business
                Associate, members of its Workforce or Subcontractors that is not specifically permitted by this BAA no
                later than three (3) business days after becoming aware of such Security Incident or non-permitted Use
                or Disclosure, in accordance with the notice provisions set forth herein.&nbsp; Business Associate shall
                investigate each Security Incident or non-permitted Use or Disclosure of Covered Entity&rsquo;s PHI that
                it discovers to determine whether such Security Incident or non-permitted Use or Disclosure constitutes
                a reportable Breach of Unsecured PHI.&nbsp; Business Associate shall document and retain records of its
                investigation of any Breach, including its reports to Covered Entity under this Section 3.5.1.&nbsp;
                Upon request of Covered Entity, Business Associate shall furnish to Covered Entity the documentation of
                its investigation and an assessment of whether such Security Incident or non-permitted Use or Disclosure
                constitutes a reportable Breach.&nbsp; If such Security Incident or non-permitted Use or Disclosure
                constitutes a reportable Breach of Unsecured PHI, then Business Associate shall comply with the
                additional requirements of Section 3.5.2 below.
              </h3>
            </li>
            <li>
              <h3 style={{ textAlign: 'justify' }}>
                Breach of Unsecured PHI If Business Associate determines that a reportable Breach of Unsecured PHI has
                occurred, Business Associate shall provide a written report to Covered Entity without unreasonable delay
                but no later than thirty (30) calendar days after discovery of the Breach. To the extent that
                information is available to Business Associate, Business Associate&rsquo;s written report to Covered
                Entity shall be in accordance with 45 C.F.R. &sect;164.410(c). Business Associate shall cooperate with
                Covered Entity in meeting Covered Entity&rsquo;s obligations under the HITECH Act with respect to such
                Breach. Covered Entity shall have sole control over the timing and method of providing notification of
                such Breach to the affected individual(s), the Secretary and, if applicable, the media, as required by
                the HITECH Act.&nbsp; Business Associate shall reimburse Covered Entity for its reasonable costs and
                expenses in providing the notification, including, but not limited to, any administrative costs
                associated with providing notice, printing and mailing costs, and costs of mitigating the harm (which
                may include the costs of obtaining credit monitoring services and identity theft insurance) for affected
                individuals whose PHI has or may have been compromised as a result of the Breach.&nbsp;
              </h3>
            </li>
          </ol>
          <li>
            <h2 style={{ textAlign: 'justify' }}>
              Availability of Internal Practices, Books, and Records to Government&nbsp; Business Associate agrees to
              make its internal practices, books and records relating to the Use and Disclosure of PHI received from, or
              created or received by the Business Associate on behalf of Covered Entity available to the Secretary for
              purposes of determining Covered Entity&rsquo;s compliance with HIPAA, the HIPAA Regulations, and the
              HITECH Act.&nbsp; Except to the extent prohibited by law, Business Associate shall notify Covered Entity
              of all requests served upon Business Associate for information or documentation by or on behalf of the
              Secretary.&nbsp; Business Associate agrees to provide to Covered Entity proof of its compliance with the
              HIPAA Security Standards.
            </h2>
          </li>
          <li>
            <h2 style={{ textAlign: 'justify' }}>
              Access to and Amendment of Protected Health Information&nbsp; &nbsp; To the extent that Business Associate
              maintains a Designated Record Set on behalf of Covered Entity and within fifteen (15) days of a request by
              Covered Entity, Business Associate shall (a) make the PHI it maintains (or which is maintained by its
              Subcontractors) in Designated Record Sets available to Covered Entity for inspection and copying, or to an
              individual to enable Covered Entity to fulfill its obligations under 45 C.F.R. &sect; 164.524, or (b)
              amend the PHI it maintains (or which is maintained by its Subcontractors) in Designated Record Sets to
              enable the Covered Entity to fulfill its obligations under 45 C.F.R. &sect; 164.526.&nbsp; Business
              Associate shall not Disclose PHI to a health plan for payment or Health Care Operations purposes if and to
              the extent that Covered Entity has informed Business Associate that the patient has requested this special
              restriction, and has paid out of pocket in full for the health care item or service to which the PHI
              solely relates, consistent with 42 U.S.C. &sect; 17935(a) and 42 C.F.R. &sect; 164.522(a)(1)(vi).&nbsp; If
              Business Associate maintains PHI in a Designated Record Set electronically, Business Associate shall
              provide such information in the electronic form and format requested by the Covered Entity if it is
              readily reproducible in such form and format, and, if not, in such other form and format agreed to by
              Covered Entity to enable Covered Entity to fulfill its obligations under 42 U.S.C. &sect; 17935(e) and 45
              C.F.R. &sect; 164.524(c)(2).&nbsp; Business Associate shall notify Covered Entity within fifteen (15) days
              of receipt of a request for access to PHI.
            </h2>
          </li>
          <li>
            <h2 style={{ textAlign: 'justify' }}>
              Accounting&nbsp; To the extent that Business Associate maintains a Designated Record Set on behalf of
              Covered Entity, within thirty (30) days of receipt of a request from Covered Entity or an individual for
              an accounting of disclosures of PHI, Business Associate and its Subcontractors shall make available to
              Covered Entity the information required to provide an accounting of disclosures to enable Covered Entity
              to fulfill its obligations under 45 C.F.R. &sect; 164.528 and its obligations under 42 U.S.C. &sect;
              17935(c).&nbsp; Business Associate shall notify Covered Entity within fifteen (15) days of receipt of a
              request by an individual or other requesting party for an accounting of disclosures of PHI.
            </h2>
          </li>
          <li>
            <h2 style={{ textAlign: 'justify' }}>
              Use of Subcontractors Business Associate shall require each of its Subcontractors that creates, maintains,
              receives, or transmits PHI on behalf of Business Associate, to execute a Business Associate Agreement that
              imposes on such Subcontractors the same restrictions, conditions, and requirements that apply to Business
              Associate under this BAA with respect to PHI.
            </h2>
          </li>
          <li>
            <h2 style={{ textAlign: 'justify' }}>
              Minimum Necessary&nbsp; Business Associate (and its Subcontractors) shall, to the extent practicable,
              limits its request, Use, or Disclosure of PHI to the minimum amount of PHI necessary to accomplish the
              purpose of the request, Use or Disclosure, in accordance with 42 U.S.C. &sect; 17935(b) and 45 C.F.R.
              &sect; 164.502(b)(1) or any other guidance issued thereunder.&nbsp;
            </h2>
          </li>
        </ol>
        <li>
          <h1 style={{ textAlign: 'center' }}>MISCELLANEOUS</h1>
        </li>
        <ol>
          <li>
            <h2 style={{ textAlign: 'justify' }}>
              Amendment to Comply with Law This BAA shall be deemed amended to incorporate any mandatory obligations of
              Covered Entity or Business Associate under the HITECH Act and its implementing HIPAA Regulations.&nbsp;
              Additionally, the Parties agree to take such action as is necessary to amend this BAA from time to time as
              necessary for Covered Entity to implement its obligations pursuant to HIPAA, the HIPAA Regulations, or the
              HITECH Act.&nbsp;&nbsp;&nbsp;&nbsp;
            </h2>
          </li>
        </ol>
      </ol>
      <ol>
        <li>
          <h2 style={{ textAlign: 'justify' }}>
            Notices&nbsp; Any notices required or permitted to be given hereunder by either Party to the other shall be
            given in writing:&nbsp; (1) by personal delivery; (2) by electronic mail or facsimile with confirmation sent
            by United States first class registered or certified mail, postage prepaid, return receipt requested; (3) by
            bonded courier or by a nationally recognized overnight delivery service; or (4) by United States first class
            registered or certified mail, postage prepaid, return receipt, in each case, addressed to a Party on the
            signature page(s) to this Agreement or to such other addresses as the Parties may request in writing by
            notice given pursuant to this Section 4.3.&nbsp; Notices shall be deemed received on the earliest of
            personal delivery; upon delivery by electronic facsimile with confirmation from the transmitting machine
            that the transmission was completed; twenty-four (24) hours following deposit with a bonded courier or
            overnight delivery service; or seventy-two (72) hours following deposit in the U.S. mail as required herein.
          </h2>
        </li>
        <li>
          <h2 style={{ textAlign: 'justify' }}>
            Relationship of Parties Business Associate is an independent contractor and not an agent of Covered Entity
            under this BAA.&nbsp; Business Associate has the sole right and obligation to supervise, manage, contract,
            direct, procure, perform or cause to be performed all Business Associate obligations under this BAA.&nbsp;
          </h2>
        </li>
        <li>
          <h2 style={{ textAlign: 'justify' }}>
            Survival The respective rights and obligations of the Parties under Section 2.3 and 4.2 of this BAA shall
            survive the termination of this BAA.
          </h2>
        </li>
        <li>
          <h2 style={{ textAlign: 'justify' }}>
            Applicable Law and Venue This Agreement shall be governed by and construed in accordance with the laws of
            the State of New York, without regards to its conflict of laws principles.&nbsp; The Parties agree that all
            actions or proceedings arising in connection with this BAA shall be tried and litigated exclusively in the
            State or federal (if permitted by law and if a Party elects to file an action in federal court) courts
            located in the State of New York.&nbsp;
          </h2>
        </li>
      </ol>
      <p style={{ textAlign: 'center' }}>
        <strong>EXHIBIT B</strong>
      </p>
      <p style={{ textAlign: 'center' }}>
        <strong>CANADIAN PRIVACY ADDENDUM</strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        In the event of any conflict between the Terms and this Canadian Privacy Addendum (&ldquo;
        <strong>Addendum</strong>&rdquo;), the terms and conditions of this Addendum shall control. Except as modified
        herein, all terms and conditions of the Terms shall remain in full force and effect.&nbsp;
      </p>
      <ol>
        <li>
          <strong>Terms. </strong>Capitalized terms used but not otherwise defined in this Addendum have the meaning set
          out in the Terms. The term &ldquo;Personal Information&rdquo; means information of an identifiable individual
          transferred by Orthodontist, or its permitted agents, to Grin or otherwise collected by Grin hereunder
          including patient name, mailing address, email address, age, images and video of dental and/or orthodontic
          treatments, and correspondence relating to same.&nbsp;&nbsp;
        </li>
        <li>
          <strong>Grin Obligations. </strong>To the extent that Grin receives Personal Information in the course of
          providing the Grin Remote monitoring and Remote consultation software services under the Agreement, Grin
          shall:
        </li>
        <ol>
          <li>
            collect, use, access, disclose, modify, or otherwise process Personal Information only to the extent
            necessary for the purpose of rendering the Services in accordance with this Addendum and as otherwise
            instructed by Orthodontist in writing or as otherwise permitted hereunder.&nbsp;
          </li>
          <li>
            notify Orthodontist without delayin writing of any (i) enquiry received from an individual relating to,
            among other things, the individual&rsquo;s right to access, modify or correct Personal Information, (ii)
            complaint received by Grin relating to the processing of Personal Information, and (iii) if not legally
            prohibited from doing so, order, demand, warrant or any other document purporting to compel the production
            of any Personal Information, and promptly co-operate with all reasonable instructions of Orthodontist with
            respect to any action taken with respect to such enquiry or complaint;&nbsp;
          </li>
          <li>
            limit access to Personal Information to its employees and personnel who have a need for access to the
            Personal Information solely for the purposes of Grin rendering the Services under this Addendum.
          </li>
          <li>
            where Grin provides access to Personal Information to a third party (including an affiliate) or contracts
            such rights or obligations, enter into a written agreement with each contractor, sub processor or third
            party that imposes obligations on the contractor or third party that are substantially similar to those
            imposed on Grin under this Addendum.&nbsp;&nbsp;
          </li>
          <li>
            only retain Personal Information for as long as necessary to provide the Services or as otherwise instructed
            by Orthodontist, and thereafter securely destroy or delete the information in accordance with medical record
            retention protocols.
          </li>
          <li>
            require its employees and other personnel with access to Personal Information to agree, in writing, to
            protect the confidentiality and security of Personal Information in accordance with the terms of this
            Addendum, and otherwise properly advise and train each of such employees and other personnel of the
            requirements of Grin under this Addendum and applicable privacy laws.
          </li>
        </ol>
        <li>
          <strong>Data Security</strong>. Grin agrees that it has established and shall maintain throughout the Term an
          information security program designed to meet applicable laws and regulations. Grin agrees that such program
          includes administrative, technical, and physical safeguards designed to: (i) protect the security,
          confidentiality, and integrity of Personal Information; (ii) protect against anticipated threats or hazards to
          the security, confidentiality, and integrity of Personal information; (iii) protect against unauthorized
          access to, or use of, Personal Information; (iv) ensure compliance with an incident response program; and (v)
          ensure the proper disposal of Personal Information.&nbsp;
        </li>
        <li>
          <strong>Security Incident; Response</strong>. Grin shall notify the Orthodontist at the first reasonable
          opportunity if it becomes aware of the loss, theft or unauthorized access to, use or disclosure of Personal
          Information in the custody or control of Grin or other material breach of Grin&rsquo;s obligations under this
          Addendum. Grin shall use commercially reasonable efforts to address the security incident in a timely manner.
        </li>
        <li>
          <strong>Orthodontist Obligations.</strong> Orthodontist agrees to provide all necessary notices and obtain all
          necessary authorizations or consents that may be required under and in accordance with applicable federal,
          provincial or local privacy laws, rules or regulations in order to transmit Personal Information to Grin and
          enable Grin and its authorized employees and personnel to use and share Personal Information as contemplated
          by the Agreement. Orthodontist shall notify Grin of any withdrawals of consent or other restrictions on the
          use or disclosure of Personal Information. Grin shall have no responsibility to review the sufficiency or
          accuracy of such notices or consents. Orthodontist shall ensure that all Personal Information is accurate and
          complete, and Grin will have no obligation to review or confirm the accuracy or completeness of any Personal
          Information.&nbsp;
        </li>
        <li>
          <strong>Compliance with Laws. </strong>Grin and Orthodontist shall comply with all applicable federal,
          provincial and local laws, rules and regulations concerning the privacy and security of Personal Information
          including, without limitation, federal and provincial private sector and health sector privacy legislation.
        </li>
        <li>
          <strong>Termination:</strong> Without limiting anything in the Agreement, Orthodontist shall be entitled to
          terminate the Agreement in the event of Grin&rsquo;s material breach of this Addendum.&nbsp;
        </li>
      </ol>{' '}
    </div>
  )
}

export default CATermsDoc
