import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { Speaker } from 'components/common/icons'
import { PATIENT_CARD_TABS } from 'consts/patientCard'
import useOpenPatientCard from 'hooks/useOpenPatientCard'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 50px',
    textAlign: 'center',
    backgroundColor: 'rgba(143, 180, 255, 0.4)',
    color: 'var(--text-color-20)'
  },
  icon: {
    marginRight: 7,
    transform: 'translateY(2px)'
  },
  link: {
    textDecoration: 'underline',
    color: 'var(--text-color-3)',
    cursor: 'pointer'
  }
}))

const TreatmentTypeCtaBanner = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const openPatientCard = useOpenPatientCard()

  return (
    <DazzedParagraph12 className={classes.root}>
      <Speaker className={classes.icon} />
      <strong>{t('pages.patients.selectedPatient.timeline.banners.newTimelineAvailable')}&nbsp;</strong>
      {t('pages.patients.selectedPatient.timeline.banners.updatePatientTreatmentType')}
      <span
        className={classes.link}
        onClick={() => openPatientCard({ tabIndex: PATIENT_CARD_TABS.scanSettings.index })}
      >
        {t('pages.patients.selectedPatient.timeline.banners.updateNow')}
      </span>
    </DazzedParagraph12>
  )
}

export default TreatmentTypeCtaBanner
