import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import NoScansState from 'components/common/icons/timelineV2/NoScansState'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'

const useStyles = makeStyles(theme => ({
  emptyStateRoot: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  label: {
    color: 'rgba(182, 199, 215, 1)',
    fontWeight: 400,
    marginTop: 40
  }
}))

const TimelineEmptyState = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.emptyStateRoot}>
      <div>
        <NoScansState />
      </div>
      <DazzedHeading20 className={classes.label}>
        {t('pages.patients.selectedPatient.timelineV2.noScansState')}
      </DazzedHeading20>
    </div>
  )
}

export default TimelineEmptyState
