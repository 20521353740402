import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCountry } from 'hooks/useCountry'
import useRolePermissions from 'hooks/useRolePermissions'
import _, { isEmpty } from 'lodash'
import { Box, Grid, makeStyles, CircularProgress } from '@material-ui/core'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import DazzedHeading24 from 'components/common/text/DazzedHeading24'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import Actions from 'actions'
import ProfilePicture from 'components/common/ProfilePicture'
import FormInput from 'components/common/FormInput'
import SelectInput from 'components/common/SelectInput'
import ImageUploaderWithCrop from './ImageUploaderWithCrop'
import PhoneNumberInput from 'components/common/PhoneNumberInput'
import GrinLabel from 'components/common/text/GrinLabel'
import LeaveModal from 'components/common/modals/LeaveModal'
import { removePlus, getCountryCode, getPhoneNumber } from 'utils/stringUtils'
import { isValidEmail, isValidZipcode } from 'utils/generalUtils'
import { COUNTRY_CA, COUNTRY_US, COUNTRY_IN, COUNTRY_CH } from 'consts/countryConsts'
import { updateS3PracticeLogoKey } from 'utils/storageUtils'
import { CLINIC_LOGO_FOLDER } from 'consts/settings'
import { trackEvent } from 'utils/analyticsUtils'
import useFeatureFlags from 'hooks/useFeatureFlags'

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    maxWidth: 520,
    paddingRight: 20,
    position: 'relative'
  },
  loader: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  phoneRequired: {
    color: 'red',
    fontWeight: 500,
    marginBottom: 15,
    height: 17,
    fontSize: 12
  },
  practiceLogo: {
    marginRight: 15
  },
  name: {
    fontSize: 22,
    fontWeight: 'normal',
    marginBottom: 5
  },
  nonEditableValue: {
    marginBottom: 20,
    fontWeight: 500
  }
}))

const PracticeProfile = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()
  const { doctor, isLoading: isLoadingDoctor } = useSelector(state => state.profileReducer)
  const { isLoading } = useSelector(state => state.practiceReducer)
  const accountOwnerId = useSelector(state => state.practiceReducer.accountOwner.id)
  const practiceDetails = useSelector(state => state.practiceReducer.details)
  const { roleDescription } = useSelector(state => state.authReducer)
  const { shouldDisplayRcTokenInProfile } = useFeatureFlags()

  const dispatch = useDispatch()

  const [practiceName, setPracticeName] = useState('')
  const [practiceEmail, setPracticeEmail] = useState('')
  const [practiceStreet, setPracticeStreet] = useState('')
  const [practiceDetailedAddress, setPracticeDetailedAddress] = useState('')
  const [practiceCity, setPracticeCity] = useState('')
  const [practiceState, setPracticeState] = useState('')
  const [practiceCountry, setPracticeCountry] = useState('')
  const [practiceZip, setPracticeZip] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [stateKey, setStateKey] = useState('')
  const [practicePhoneValue, setPracticePhoneValue] = useState('')

  const [formErrors, setFormErrors] = useState()

  const isDataUnsaved = useMemo(
    () =>
      practiceDetails.practiceName &&
      (practiceDetails.practiceName !== practiceName ||
        (practiceDetails.email || '') !== practiceEmail ||
        practiceDetails.phone !== getPhoneNumber(practicePhoneValue, countryCode) ||
        practiceDetails.address1 !== practiceStreet ||
        practiceDetails.city !== practiceCity ||
        practiceDetails.state !== practiceState ||
        (practiceDetails.country || COUNTRY_US) !== practiceCountry ||
        practiceDetails.zip !== practiceZip ||
        practiceDetails.countryCode !== getCountryCode(countryCode) ||
        practiceDetails.address2 !== practiceDetailedAddress),
    [
      countryCode,
      practiceCity,
      practiceCountry,
      practiceDetailedAddress,
      practiceDetails,
      practiceEmail,
      practiceName,
      practicePhoneValue,
      practiceState,
      practiceStreet,
      practiceZip
    ]
  )

  const requestUpdatePracticeLogo = useCallback(payload => dispatch(Actions.updatePracticeLogo(payload)), [dispatch])

  const removePracticeLogo = useCallback(() => dispatch(Actions.removePracticeLogo({ doctor })), [dispatch, doctor])

  const updatePracticeDetails = useCallback(() => {
    dispatch(
      Actions.updatePracticeDetails({
        id: accountOwnerId,
        clinic: {
          ...practiceDetails,
          practiceName,
          address1: practiceStreet,
          address2: practiceDetailedAddress,
          city: practiceCity,
          state: practiceState,
          country: practiceCountry,
          zip: practiceZip,
          email: practiceEmail,
          phone: getPhoneNumber(practicePhoneValue, countryCode),
          countryCode: getCountryCode(countryCode),
          practicePhone: getPhoneNumber(practicePhoneValue, countryCode),
          practiceCountryCode: getCountryCode(countryCode)
        }
      })
    )
  }, [
    countryCode,
    dispatch,
    accountOwnerId,
    practiceDetails,
    practicePhoneValue,
    practiceCity,
    practiceCountry,
    practiceEmail,
    practiceName,
    practiceState,
    practiceStreet,
    practiceDetailedAddress,
    practiceZip
  ])

  useEffect(() => {
    setPracticeName(practiceDetails.practiceName)
    if (practiceDetails.email) setPracticeEmail(practiceDetails.email)
    if (practiceDetails.countryCode && practiceDetails.phone) {
      setPracticePhoneValue(removePlus(practiceDetails.countryCode) + practiceDetails.phone)
    }
    setPracticeStreet(practiceDetails.address1)
    setPracticeCity(practiceDetails.city)
    setPracticeState(practiceDetails.state)
    setPracticeCountry(practiceDetails.country || COUNTRY_US)
    setPracticeZip(practiceDetails.zip)
    setCountryCode(practiceDetails.countryCode)
    setPracticeDetailedAddress(practiceDetails.address2)
  }, [practiceDetails])

  const { countriesOptions, states, getDefaultState, getStateKey, getZipCodeProperties, hasZipcode } =
    useCountry(practiceCountry)

  const defaultState = useMemo(() => getDefaultState(practiceCountry), [getDefaultState, practiceCountry])
  const { placeholder } = useMemo(() => getZipCodeProperties(practiceCountry), [getZipCodeProperties, practiceCountry])

  const displayStateFormElement = useMemo(
    () => !!states.length || practiceCountry === COUNTRY_IN,
    [states, practiceCountry]
  )

  useEffect(() => {
    if (practiceState) {
      setStateKey(getStateKey(practiceState, practiceCountry))
    }
  }, [practiceState, defaultState.key, states, getStateKey, practiceCountry])

  const validateInputs = useCallback(() => {
    const errors = {}

    if (!practiceName?.trim().length) {
      errors.practiceName = t('pages.accountSettings.practiceProfile.practiceNameRequiredError')
    }

    if (countryCode && !practicePhoneValue.slice(countryCode.length)?.trim().length) {
      errors.practicePhoneValue = t('pages.accountSettings.practiceProfile.practiceZipRequiredError')
    }

    if (!!practiceEmail && !isValidEmail(practiceEmail)) {
      errors.practiceEmail = t('pages.accountSettings.practiceProfile.practiceEmailError')
    }

    if (!practiceStreet?.trim().length) {
      errors.practiceStreet = t('pages.accountSettings.practiceProfile.practiceStreetRequiredError')
    }

    if (!practiceCity?.trim().length) {
      errors.practiceCity = t('pages.accountSettings.practiceProfile.practiceCityRequiredError')
    }

    if (states.length && !practiceState?.trim().length) {
      errors.practiceState =
        practiceCountry === 'CA'
          ? t('pages.accountSettings.practiceProfile.practiceProvinceRequiredError')
          : t('pages.accountSettings.practiceProfile.practiceStateRequiredError')
    }

    if (hasZipcode) {
      if (!practiceZip?.trim().length) {
        errors.practiceZip = t('pages.accountSettings.practiceProfile.practiceZipRequiredError')
      }
      if (!isValidZipcode(practiceZip, practiceCountry)) {
        errors.practiceZip = t('pages.accountSettings.practiceProfile.practiceZipFormatError')
      }
    }

    setFormErrors(errors)
    return isEmpty(errors)
  }, [
    practiceName,
    countryCode,
    practicePhoneValue,
    practiceEmail,
    practiceStreet,
    practiceCity,
    states,
    practiceState,
    hasZipcode,
    t,
    practiceCountry,
    practiceZip
  ])

  const handleFormSubmit = e => {
    e.preventDefault()
    if (!validateInputs()) return
    trackEvent('Practice Profile - practice details updated', {
      roleDescription
    })
    updatePracticeDetails()
  }

  useEffect(() => {
    setFormErrors()
  }, [
    countryCode,
    practiceCity,
    practiceName,
    practiceStreet,
    practiceZip,
    practicePhoneValue,
    practiceEmail,
    validateInputs
  ])

  return (
    <>
      <LeaveModal blockNavigation={!isLoading && !isLoadingDoctor && isDataUnsaved} />
      <form className={classes.form} onSubmit={handleFormSubmit} noValidate>
        {isLoadingDoctor && (
          <div className={classes.loader}>
            <CircularProgress size={36} color="secondary" />
          </div>
        )}
        <Box display="flex" alignItems="center" mb="40px">
          <ProfilePicture
            name={practiceDetails.practiceName}
            email={practiceDetails.email}
            photo={updateS3PracticeLogoKey(practiceDetails.logo)}
            size="big"
            className={classes.practiceLogo}
          />
          <Grid>
            <DazzedHeading24 className={classes.name}>{practiceDetails.practiceName}</DazzedHeading24>
            {permissions.editPracticeDetails && (
              <ImageUploaderWithCrop
                iconButtons
                label={
                  practiceDetails.logo?.key
                    ? t('pages.accountSettings.practiceProfile.updatePracticeLogo')
                    : t('pages.accountSettings.practiceProfile.uploadPracticeLogo')
                }
                secondaryLabel={
                  practiceDetails.logo?.key && t('pages.accountSettings.practiceProfile.deletePracticeLogo')
                }
                onUploadDone={key => requestUpdatePracticeLogo({ key: key[0], doctor })}
                s3Folder={CLINIC_LOGO_FOLDER}
                onSecondaryClick={removePracticeLogo}
              />
            )}
          </Grid>
        </Box>
        {shouldDisplayRcTokenInProfile && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormInput
                isDisabled={true}
                title={t('pages.accountSettings.practiceProfile.publicDoctorIdLabel')}
                style={{ bright: true, thick: true }}
                value={doctor.rcToken}
                validationRules={{ required: true }}
                errorMessage={formErrors?.practiceName}
              />
            </Grid>
          </Grid>
        )}
        {permissions.editPracticeDetails ? (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormInput
                title={t('pages.accountSettings.practiceProfile.practiceNameLabel')}
                style={{ bright: true, thick: true }}
                value={practiceName}
                validationRules={{ required: true }}
                errorMessage={formErrors?.practiceName}
                setValue={setPracticeName}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                title={t('pages.accountSettings.practiceProfile.practiceEmailLabel')}
                style={{ bright: true, thick: true }}
                placeholder={t('pages.accountSettings.practiceProfile.practiceEmailPlaceholder')}
                type="email"
                value={practiceEmail}
                notes={t('pages.accountSettings.practiceProfile.practiceEmailInfoMessage')}
                errorMessage={formErrors?.practiceEmail}
                validationRules={{ required: false }}
                setValue={setPracticeEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneNumberInput
                title={t('pages.accountSettings.practiceProfile.practicePhoneNumberLabel')}
                style={{ bright: true, thick: true }}
                value={practicePhoneValue}
                notes={t('pages.accountSettings.practiceProfile.practicePhoneInfoMessage')}
                validationRules={{ required: true }}
                setValue={setPracticePhoneValue}
                setCountryCode={setCountryCode}
              />
            </Grid>
            {formErrors?.practicePhoneValue && (
              <div className={classes.phoneRequired}>
                {t('pages.accountSettings.practiceProfile.phoneRequiredError')}
              </div>
            )}
            <Grid item xs={12}>
              <FormInput
                title={t('pages.accountSettings.practiceProfile.streetLabel')}
                style={{ bright: true, thick: true }}
                value={practiceStreet}
                validationRules={{ required: true }}
                errorMessage={formErrors?.practiceStreet}
                setValue={setPracticeStreet}
              />
            </Grid>
            {practiceCountry === COUNTRY_IN && (
              <Grid item xs={12}>
                <FormInput
                  title={t('pages.accountSettings.practiceProfile.detailedAddress')}
                  style={{ bright: true, thick: true }}
                  value={practiceDetailedAddress}
                  setValue={setPracticeDetailedAddress}
                />
              </Grid>
            )}

            <Grid item md={displayStateFormElement ? 6 : 12}>
              <FormInput
                title={t('pages.accountSettings.practiceProfile.cityLabel')}
                style={{ bright: true, thick: true }}
                value={practiceCity}
                validationRules={{ required: true }}
                errorMessage={formErrors?.practiceCity}
                setValue={setPracticeCity}
              />
            </Grid>
            {displayStateFormElement && (
              <Grid item md={6}>
                {practiceCountry === COUNTRY_IN ? (
                  <FormInput
                    title={t('pages.accountSettings.practiceProfile.stateLabel')}
                    placeholder={t('pages.accountSettings.practiceProfile.statePlaceholder')}
                    style={{ bright: true, thick: true }}
                    value={practiceState}
                    setValue={setPracticeState}
                  />
                ) : (
                  <SelectInput
                    label={
                      practiceCountry === COUNTRY_CA
                        ? t('pages.accountSettings.practiceProfile.provinceLabel')
                        : practiceCountry === COUNTRY_CH
                        ? t('pages.accountSettings.practiceProfile.cantonLabel')
                        : t('pages.accountSettings.practiceProfile.stateLabel')
                    }
                    style={{ bright: true, thick: true }}
                    value={stateKey}
                    values={states.map(state => state.key)}
                    errorMessage={formErrors?.practiceState}
                    keepErrorContainerWhenInactive={false}
                    required={!!states.length}
                    disabled={!states.length}
                    onChange={({ value: key }) => {
                      setPracticeState(
                        _.get(
                          states.find(s => s.key === key),
                          'name'
                        )
                      )
                    }}
                  />
                )}
              </Grid>
            )}
            <Grid item md={6}>
              <SelectInput
                disabled
                label={t('pages.accountSettings.practiceProfile.countryLabel')}
                style={{ bright: true, thick: true }}
                value={practiceCountry}
                options={countriesOptions}
                required
                onChange={({ value: key }) => {
                  setPracticeCountry(key)
                  setPracticeState('')
                  setStateKey('')
                }}
              />
            </Grid>
            <Grid item md={6}>
              <FormInput
                title={t('pages.accountSettings.practiceProfile.zipLabel')}
                style={{ bright: true, thick: true }}
                placeholder={placeholder}
                value={practiceZip}
                validationRules={{ required: hasZipcode }}
                errorMessage={formErrors?.practiceZip}
                setValue={setPracticeZip}
                isDisabled={!hasZipcode}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <GrinLabel>{t('pages.accountSettings.practiceProfile.practiceNameLabel')}</GrinLabel>
              <DazzedParagraph14 className={classes.nonEditableValue}>{practiceName}</DazzedParagraph14>
            </Grid>
            <Grid item xs={12}>
              <GrinLabel>{t('pages.accountSettings.practiceProfile.practiceEmailLabel')}</GrinLabel>
              <DazzedParagraph14 className={classes.nonEditableValue}>{practiceEmail ?? '-'}</DazzedParagraph14>
            </Grid>
            <Grid item xs={12}>
              <GrinLabel>{t('pages.accountSettings.practiceProfile.practicePhoneNumberLabel')}</GrinLabel>
              <DazzedParagraph14 className={classes.nonEditableValue}>
                {practicePhoneValue ? `+${practicePhoneValue}` : '-'}
              </DazzedParagraph14>
            </Grid>
            <Grid item xs={12}>
              <GrinLabel>{t('pages.accountSettings.practiceProfile.streetLabel')}</GrinLabel>
              <DazzedParagraph14 className={classes.nonEditableValue}>{practiceStreet ?? '-'}</DazzedParagraph14>
            </Grid>
            <Grid item xs={12}>
              <GrinLabel>{t('pages.accountSettings.practiceProfile.detailedAddress')}</GrinLabel>
              <DazzedParagraph14 className={classes.nonEditableValue}>
                {practiceDetailedAddress ?? '-'}
              </DazzedParagraph14>
            </Grid>
            <Grid item md={4}>
              <GrinLabel>{t('pages.accountSettings.practiceProfile.cityLabel')}</GrinLabel>
              <DazzedParagraph14 className={classes.nonEditableValue}>{practiceCity ?? '-'}</DazzedParagraph14>
            </Grid>
            {displayStateFormElement && (
              <Grid item md={2}>
                <GrinLabel>
                  {practiceCountry === COUNTRY_CA
                    ? t('pages.accountSettings.practiceProfile.provinceLabel')
                    : practiceCountry === COUNTRY_CH
                    ? t('pages.accountSettings.practiceProfile.cantonLabel')
                    : t('pages.accountSettings.practiceProfile.stateLabel')}
                </GrinLabel>
                <DazzedParagraph14 className={classes.nonEditableValue}>
                  {practiceCountry === COUNTRY_IN ? practiceState ?? '-' : stateKey ?? '-'}
                </DazzedParagraph14>
              </Grid>
            )}
            <Grid item md={displayStateFormElement ? 2 : 4}>
              <GrinLabel>{t('pages.accountSettings.practiceProfile.countryLabel')}</GrinLabel>
              <DazzedParagraph14 className={classes.nonEditableValue}>{practiceCountry ?? '-'}</DazzedParagraph14>
            </Grid>

            <Grid item md={4}>
              <GrinLabel>{t('pages.accountSettings.practiceProfile.zipLabel')}</GrinLabel>
              <DazzedParagraph14 className={classes.nonEditableValue}>{practiceZip ?? '-'}</DazzedParagraph14>
            </Grid>
          </Grid>
        )}

        {permissions.editPracticeDetails && (
          <Box mt="40px" mb="40px">
            <Box mr="20px" display="inline">
              <PrimaryButton
                label={t('pages.accountSettings.practiceProfile.primaryBtn')}
                isLoading={isLoading || isLoadingDoctor}
                type="submit"
                disabled={isLoading || isLoadingDoctor || !isDataUnsaved}
              />
            </Box>
          </Box>
        )}
      </form>
    </>
  )
}

export default PracticeProfile
