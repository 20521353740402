import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Actions from 'actions'
import ROUTES from 'consts/routesConsts'
import SwooshLargeIcon from '../common/icons/SwooshLarge2'
import { trackEvent } from 'utils/analyticsUtils'
import PersonalDetailsForm from './PersonalDetailsForm'

const useStyles = makeStyles(theme => ({
  pageContainer: {
    paddingTop: 50,
    paddingBottom: 50,
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  swoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  }
}))

const PracticeInfo = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const { firstName, lastName, phoneValue, countryCode, roleDescription, photo, username } = useSelector(
    state => state.authReducer
  )

  const handleFormSubmit = useCallback(() => {
    trackEvent('Sign up - personal info completed', {
      username,
      signupType: 'standard'
    })
    history.push(ROUTES.PRACTICE_INFO)
  }, [history, username])

  const handleChangeForm = useCallback(value => dispatch(Actions.changeAuthForm(value)), [dispatch])

  const hanldeChangePhoto = useCallback(
    photo => {
      handleChangeForm({ key: 'photo', value: photo })
      if (photo) {
        trackEvent('Sign up - personal details photo uploaded', {
          username,
          signupType: 'standard'
        })
      }
    },
    [handleChangeForm, username]
  )

  useEffect(() => {
    dispatch(Actions.loadPracticeInfoDraft())
  }, [dispatch])

  return (
    <div className={classes.pageContainer}>
      <SwooshLargeIcon className={classes.swoosh} />
      <PersonalDetailsForm
        firstName={firstName}
        lastName={lastName}
        phoneValue={phoneValue}
        countryCode={countryCode}
        roleDescription={roleDescription}
        photo={photo}
        handleFormSubmit={handleFormSubmit}
        hanldeChangePhoto={hanldeChangePhoto}
        handleChangeForm={handleChangeForm}
      />
    </div>
  )
}

export default PracticeInfo
