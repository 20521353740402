import React, { useMemo, useCallback } from 'react'
import { Grid, IconButton } from '@material-ui/core'
import { JPEG, PDF, JPG, PNG } from 'components/common/icons'
import TrashIcon from 'components/common/icons/TrashOutlined'
import Download from 'components/common/icons/Download3'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { downloadFile } from 'utils/fileUtils'
import { trackEvent } from 'utils/analyticsUtils'
import { useSelector } from 'react-redux'

const MedicalHistoryActions = ({ record, file, setShowFile, showFile, setIsDeleteModalOpen }) => {
  const doctor = useSelector(state => state.profileReducer.doctor)

  const fileType = useMemo(() => {
    const split = record?.name.split('.')
    return split?.length ? split[1].toLowerCase() : null
  }, [record])

  const toggleShowFile = useCallback(() => {
    if (!showFile) {
      trackEvent('Medical history record viewed', {
        doctorId: doctor.id
      })
    }

    setShowFile(showFile => !showFile)
  }, [doctor.id, setShowFile, showFile])

  const fileImage = useCallback(() => {
    switch (fileType) {
      case 'jpg':
        return <JPG />
      case 'jpeg':
        return <JPEG />
      case 'png':
        return <PNG />
      default:
        return <PDF />
    }
  }, [fileType])

  const downloadMedicalRecords = useCallback(() => {
    if (file) {
      trackEvent('Medical history record downloaded', {
        doctorId: doctor.id,
        fileType: file.type
      })
      downloadFile(file)
    }
  }, [doctor.id, file])

  return (
    <Grid container alignItems="center">
      <Grid item xs={10}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item>
            <div onClick={toggleShowFile} style={{ cursor: 'pointer' }}>
              <DazzedParagraph14>{fileImage()}</DazzedParagraph14>
            </div>
          </Grid>
          <Grid item>
            <DazzedParagraph14 color="#646464">{record?.name}</DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container justifyContent="center" alignItems="center">
          <>
            <Grid item xs={6}>
              <IconButton
                onClick={() => {
                  setIsDeleteModalOpen(true)
                }}
              >
                <TrashIcon width={18} height={18} />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton onClick={downloadMedicalRecords}>
                <Download width={18} height={18} />
              </IconButton>
            </Grid>
          </>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default MedicalHistoryActions
