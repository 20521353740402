import * as React from 'react'

const SeekLeft = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.334 26.2381V8.33325L13.0747 17.2857L22.334 26.2381Z" fill="#4151E6" />
    <rect width="2" height="18" transform="matrix(-1 0 0 1 14.334 8.33325)" fill="#4151E6" />
    <circle cx="16" cy="16" r="16" transform="matrix(-1 0 0 1 33 1)" stroke="#4151E6" />
  </svg>
)

export default SeekLeft
