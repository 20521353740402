import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress, Grid } from '@material-ui/core'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { GuidelineCategories } from 'consts/hiToolsConsts'
import GuidelineCategory from './GuidelineCategory'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import Actions from 'actions'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { isEqual } from 'lodash'
import LeaveModal from 'components/common/modals/LeaveModal'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { ROUTES } from 'consts'
import usePlanLimits from 'hooks/usePlanLimits'
import OggHeading40 from 'components/common/text/OggHeading40'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingBottom: 50
  },
  title: {
    marginBottom: 20
  },
  titleText: {
    lineHeight: '35px'
  },
  divider: {
    height: 1,
    backgroundColor: 'var(--text-color-6)',
    marginBottom: 20,
    width: 590
  },
  assistantTypesContainer: {
    width: 1000,
    marginBottom: 20
  },
  lastUpdatedAtLabel: {
    color: 'var(--text-color-11)'
  },
  quickLink: {
    textDecoration: 'underline',
    fontSize: 14,
    fontWeight: 500,
    color: 'var(--text-color-25)'
  },
  saveButton: {
    position: 'sticky',
    bottom: 0,
    marginTop: 10,
    paddingBottom: 20,
    backgroundColor: 'white'
  }
}))

const PracticeGuidelines = props => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { hiTools: hiToolsFF = false } = useFeatureFlags()
  const { isHI: isOnHI } = usePlanLimits()

  const {
    data: practiceGuidelines,
    isLoading,
    isSaving
  } = useSelector(state => state.practiceReducer.hiPracticeGuidelines)
  const accountOwnerId = useSelector(state => state.practiceReducer.accountOwner.id)
  const practiceMembers = useSelector(state => state.profileReducer.practiceMembers.data)

  const [categoriesData, setCategoriesData] = useState()
  const [categoriesCollapse, setCategoriesCollapse] = useState(
    Object.values(GuidelineCategories).reduce(
      (categories, categoryKey) => ({
        ...categories,
        [categoryKey]: true
      }),
      {}
    )
  )

  const isPermitted = useMemo(() => isOnHI && hiToolsFF, [hiToolsFF, isOnHI])
  const parsedCurrentCategoriesData = useMemo(
    () => JSON.parse(practiceGuidelines?.categories || '{}'),
    [practiceGuidelines]
  )
  const isDataUnsaved = useMemo(
    () => !isEqual(parsedCurrentCategoriesData, categoriesData),
    [categoriesData, parsedCurrentCategoriesData]
  )
  const lastUpdatedBy = useMemo(
    () => practiceMembers?.find(pm => pm.id === practiceGuidelines?.creatorDoctorId)?.name,
    [practiceGuidelines, practiceMembers]
  )
  const lastUpdatedAt = useMemo(
    () => (practiceGuidelines?.createdAt ? moment(practiceGuidelines?.createdAt).format('MMM D, YYYY') : null),
    [practiceGuidelines]
  )
  const quickNavigations = useMemo(
    () =>
      Object.values(GuidelineCategories).map(categoryKey => ({
        label: t(`pages.accountSettings.practiceGuidelines.categories.${categoryKey}.quickNav`),
        key: categoryKey
      })),
    [t]
  )
  const categories = useMemo(() => {
    if (!categoriesData) {
      return null
    }

    return Object.values(GuidelineCategories).reduce(
      (categories, categoryKey) => ({
        ...categories,
        [categoryKey]: {
          ...(categoriesData?.[categoryKey] || {})
        }
      }),
      {}
    )
  }, [categoriesData])

  const handleCategoryCollapseClicked = useCallback(
    ({ categoryKey, shouldCollapse }) => {
      setCategoriesCollapse({
        ...categoriesCollapse,
        [categoryKey]: shouldCollapse ?? !categoriesCollapse[categoryKey]
      })
    },
    [categoriesCollapse]
  )

  const handleQuickNavClick = useCallback(
    categoryKey => {
      trackEvent('Practice guidelines - Quick navigation clicked', {
        category: categoryKey
      })
      handleCategoryCollapseClicked({ categoryKey, shouldCollapse: false })
    },
    [handleCategoryCollapseClicked]
  )

  const handleCategoryChanged = useCallback(
    updatedCategory => {
      setCategoriesData({
        ...categoriesData,
        ...updatedCategory
      })
    },
    [categoriesData]
  )

  const handleSave = useCallback(() => {
    trackEvent('Practice guidelines - Save changes clicked', {
      categoriesData
    })
    dispatch(Actions.savePracticeGuidelines(categoriesData))
  }, [dispatch, categoriesData])

  useEffect(() => {
    if (accountOwnerId) {
      dispatch(Actions.fetchPracticeGuidelines())
    }
  }, [accountOwnerId, dispatch])

  useEffect(() => {
    if (parsedCurrentCategoriesData) {
      setCategoriesData(parsedCurrentCategoriesData)
    }
  }, [parsedCurrentCategoriesData])

  useEffect(() => {
    if (!isPermitted) {
      history.push(ROUTES.ACCOUNT_SETTINGS)
    }
  }, [history, isPermitted])

  return isLoading || !categories ? (
    <CircularProgress size={35} />
  ) : isPermitted ? (
    <>
      <LeaveModal blockNavigation={!isLoading && isDataUnsaved} />
      <Grid container direction="column" className={classes.root}>
        <Grid item className={classes.title}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item>
              <OggHeading40 className={classes.titleText}>
                {t('pages.accountSettings.practiceGuidelines.title')}
              </OggHeading40>
            </Grid>
            {lastUpdatedAt && (
              <Grid item>
                <DazzedParagraph12 className={classes.lastUpdatedAtLabel}>
                  {t('pages.accountSettings.practiceGuidelines.lastUpdated', {
                    updatedAt: lastUpdatedAt,
                    lastUpdatedBy
                  })}
                </DazzedParagraph12>
              </Grid>
            )}
          </Grid>
        </Grid>
        <div className={classes.divider} />
        <Grid item className={classes.assistantTypesContainer}>
          <Grid container spacing={1}>
            <Grid item>
              <DazzedParagraph14>{t('pages.accountSettings.practiceGuidelines.assistantTypes')}</DazzedParagraph14>
            </Grid>
            {quickNavigations.map(nav => (
              <Grid item key={`${nav.key}-quick-link`}>
                <a href={`#${nav.key}`} onClick={() => handleQuickNavClick(nav.key)} className={classes.quickLink}>
                  {nav.label}
                </a>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            {Object.keys(categories).map(categoryKey => (
              <Grid item key={categoryKey}>
                <GuidelineCategory
                  type={categoryKey}
                  onChange={handleCategoryChanged}
                  isEnabled={categories?.[categoryKey]?.isEnabled}
                  note={categories?.[categoryKey]?.note}
                  items={categories?.[categoryKey]?.items}
                  isCollapsed={categoriesCollapse[categoryKey]}
                  onCollapseClick={handleCategoryCollapseClicked}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item className={classes.saveButton}>
          <PrimaryButton
            label={t('general.save')}
            onClick={handleSave}
            isLoading={isSaving}
            disabled={!isDataUnsaved}
            small
          />
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  )
}

export default PracticeGuidelines
