import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { ROUTES } from 'consts'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedHeading16 from 'components/common/text/DazzedHeading16'
import PrimaryButton from 'components/common/buttons/PrimaryButton'

const useStyles = () =>
  makeStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      flex: 1,
      padding: 50
    },
    title: {
      marginBottom: 18,
      color: '#727B8C'
    },
    body: {
      color: '#727B8C',
      textAlign: 'center',
      marginBottom: 35
    }
  })()

const TemplatesEmptyState = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const handleBtnClick = useCallback(() => {
    history.push(ROUTES.CUSTOM_MESSAGES)
  }, [history])

  return (
    <div className={classes.container}>
      <DazzedHeading16 className={classes.title}>
        {t('pages.patients.selectedPatient.chat.templates.emptyState.title')}
      </DazzedHeading16>
      <DazzedParagraph14 className={classes.body}>
        {t('pages.patients.selectedPatient.chat.templates.emptyState.body')}
      </DazzedParagraph14>
      <PrimaryButton
        label={t('pages.patients.selectedPatient.chat.templates.emptyState.button')}
        onClick={handleBtnClick}
        small
      />
    </div>
  )
}

export default TemplatesEmptyState
