import * as React from 'react'

function OvalSvg({ diameter, color, width, height, className }) {
  return (
    <svg
      className={className}
      width={width || diameter}
      height={height || diameter}
      viewBox={`0 0 ${width || diameter} ${height || diameter}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={diameter / 2} cy={diameter / 2} r={diameter / 2} fill={color} />
    </svg>
  )
}

export default OvalSvg
