import * as React from 'react'

const ReinvitePatientsEmptyState = ({ className }) => {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 1.92308C1 1.41327 1.41328 1 1.92308 1H15.1099C15.6197 1 16.033 1.41328 16.033 1.92308V10.9341C16.033 11.4439 15.6197 11.8571 15.1099 11.8571H1.92308C1.41328 11.8571 1 11.4439 1 10.9341V1.92308Z"
        stroke="#3C51EE"
      />
      <path
        d="M1.15381 1.15385L7.86373 7.86377C8.22421 8.22426 8.80867 8.22426 9.16915 7.86377L15.8791 1.15385"
        stroke="#3C51EE"
      />
      <path d="M1.15381 11.7033L6.42853 6.42857" stroke="#3C51EE" />
      <path d="M15.8792 11.7033L10.6045 6.42857" stroke="#3C51EE" />
      <mask id="path-5-outside-1_41_2976" maskUnits="userSpaceOnUse" x="10" y="8" width="10" height="7" fill="black">
        <rect fill="white" x="10" y="8" width="10" height="7" />
        <path d="M12 10H18V13H12V10Z" />
      </mask>
      <path d="M12 10H18V13H12V10Z" fill="#3C51EE" />
      <path
        d="M12 10V8.5H10.5V10H12ZM18 10H19.5C19.5 9.17157 18.8284 8.5 18 8.5V10ZM18 13V14.5C18.8284 14.5 19.5 13.8284 19.5 13H18ZM12 13H10.5V14.5H12V13ZM12 11.5H18V8.5H12V11.5ZM16.5 10V13H19.5V10H16.5ZM18 11.5H12V14.5H18V11.5ZM13.5 13V10H10.5V13H13.5Z"
        fill="white"
        mask="url(#path-5-outside-1_41_2976)"
      />
      <path d="M17.2417 15.1978V7.6813L20.9999 11.4395L17.2417 15.1978Z" fill="#3C51EE" />
    </svg>
  )
}

export default ReinvitePatientsEmptyState
