import * as React from 'react'

export default ({ className }) => {
  return (
    <svg width="82" height="82" viewBox="0 0 82 82" xmlns="http://www.w3.org/2000/svg" className={className}>
      <circle cx="41" cy="41" r="41" fill="#EF6F6F" />
      <path d="M29 29L53 52" stroke="white" strokeWidth="4" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M52.5 28.5L29.5 52.5" stroke="white" strokeWidth="4" strokeLinecap="square" strokeLinejoin="round" />
    </svg>
  )
}
