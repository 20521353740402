import * as React from 'react'

function SvgCheckboxOff({ className }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.5" y="4.5" width="15" height="15" stroke="#727B8C" />
      <path d="M9 11.7171L11.6629 14L15.5 9.5" stroke="white" strokeWidth="2" />
    </svg>
  )
}

export default SvgCheckboxOff
