import React, { useCallback, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { UrgentLarge } from 'components/common/icons'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import actions from 'actions'
import useRolePermissions from 'hooks/useRolePermissions'
import usePatientTags from 'hooks/usePatientTags'

const useStyles = makeStyles(() => ({
  text: {
    textDecoration: 'underline',
    color: 'var(--text-color-48)',
    cursor: 'pointer'
  }
}))

const UrgentCTA = ({ id }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()

  const patient = useSelector(state => state.patientsReducer.patient)
  const { tags } = usePatientTags({ patient })

  const hasUrgentScanTag = useMemo(() => tags?.some(tag => tag.type === 'scan' && tag.value === 'urgent'), [tags])

  const handleResolveUrgency = useCallback(() => {
    trackEvent('Patients chat - resolve scan urgency clicked', { messageId: id })
    dispatch(actions.resolveScanUrgency({ messageId: id }))
  }, [dispatch, id])

  return (
    hasUrgentScanTag && (
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Grid item>
          <UrgentLarge />
        </Grid>
        {permissions.resolveScanUrgency && (
          <Grid item>
            <DazzedParagraph12 className={classes.text} onClick={handleResolveUrgency}>
              {t('pages.patients.selectedPatient.chat.urgentCTA')}
            </DazzedParagraph12>
          </Grid>
        )}
      </Grid>
    )
  )
}

export default UrgentCTA
