import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { GRIN_SCOPE_MAX_IN_ORDER } from 'consts/billingConsts'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import BaseModal from 'components/common/modals/BaseModal'
import ScopeDescriptionPopup from './ScopeDescriptionPopup.mobile'
import OrderGrinKitsItemMobile from './OrderGrinKitsItem.mobile'

const useStyles = makeStyles({
  content: {
    paddingBottom: 20
  },
  shippingFeeText: {
    paddingTop: 8,
    borderTop: '1px solid var(--text-color-4)'
  },
  addScopesMini: {
    alignSelf: 'center',
    marginTop: 50,
    fontSize: 18,
    color: 'var(--text-color-3)',
    cursor: 'pointer'
  }
})

const OrderGrinKitsAmountMobile = ({
  products,
  shippingFee,
  formErrors,
  handleChangeQuantity,
  handleClickMinusQuantity,
  handleClickPlusQuantity,
  scopeMiniProduct,
  hidePrice,
  canClinicBuyScopes,
  isSubscriptionPlan,
  hasAnyAmount
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [scopeDescriptionPopupType, setScopeDescriptionPopupType] = useState(null)

  return (
    <div className={classes.content}>
      {!isSubscriptionPlan && (
        <DazzedParagraph16 textAlign="center">
          {t('dialogs.orderScopes.scopesAmountText2', {
            amount: GRIN_SCOPE_MAX_IN_ORDER
          })}
        </DazzedParagraph16>
      )}
      <div>
        {products
          .filter(({ quantity }) => quantity !== null)
          .map(kit => (
            <OrderGrinKitsItemMobile
              key={kit.type}
              name={kit.name}
              description={kit.description}
              image={kit.image}
              type={kit.type}
              price={kit.price}
              hidePrice={hidePrice}
              quantity={kit.quantity}
              maxQuantity={kit.maxQuantity}
              formErrors={formErrors}
              onSetQuantity={quantity => handleChangeQuantity({ ...kit, quantity })}
              onClickMinusQuantity={() => handleClickMinusQuantity(kit)}
              onClickPlusQuantity={() => handleClickPlusQuantity(kit)}
              setScopeDescriptionPopupType={setScopeDescriptionPopupType}
            />
          ))}
      </div>
      <DazzedParagraph14 className={classes.shippingFeeText}>
        {!canClinicBuyScopes
          ? t('dialogs.orderScopes.manualShippingFeeNote')
          : hasAnyAmount
          ? t('dialogs.orderScopes.shippingFeeText', {
              fee: `${shippingFee ?? 0} USD`
            })
          : ''}
      </DazzedParagraph14>
      {!!scopeMiniProduct && scopeMiniProduct.quantity === null && (
        <DazzedParagraph16
          className={classes.addScopesMini}
          bold
          textAlign="center"
          onClick={() => scopeMiniProduct.onSetQuantity(0)}
        >
          {t('dialogs.orderScopes.addScopeMini')}
        </DazzedParagraph16>
      )}
      <BaseModal open={!!scopeDescriptionPopupType} handleClose={() => setScopeDescriptionPopupType(null)}>
        <ScopeDescriptionPopup type={scopeDescriptionPopupType} />
      </BaseModal>
    </div>
  )
}

export default OrderGrinKitsAmountMobile
