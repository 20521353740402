import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import DazzedParagraph12 from '../../../../common/text/DazzedParagraph12'
import DazzedParagraph16 from '../../../../common/text/DazzedParagraph16'
import Cogs from '../../../../common/icons/Cogs'
import GrinTag from '../../../../common/tags/GrinTag'

const TreatmentTypeChanged = ({ event }) => {
  const classes = makeStyles({
    container: {
      paddingLeft: 20
    },
    timestamp: {
      fontSize: '10px'
    },
    treatmentTag: {
      marginBottom: '5px'
    }
  })()

  const { t } = useTranslation()

  const eventParams = JSON.parse(event.params || '{}')
  const { data: systemTags } = useSelector(state => state.profileReducer.systemTags)
  const newTreatmentType = systemTags.find(tag => tag.value === eventParams.newTreatmentType)

  return (
    <Grid container direction="row" alignItems="center" spacing={2} className={classes.container}>
      <Grid item>
        <Cogs />
      </Grid>
      <Grid item>
        <DazzedParagraph12>{t('pages.patients.selectedPatient.timeline.events.timeLineChanges')}</DazzedParagraph12>
      </Grid>
      <Grid item className={classes.treatmentTag}>
        <GrinTag tag={newTreatmentType || {}}></GrinTag>
      </Grid>
      <Grid item>
        <DazzedParagraph16 soft className={classes.timestamp}>
          on {moment(event.timestamp).format('LLLL')}
        </DazzedParagraph16>
      </Grid>
    </Grid>
  )
}

export default TreatmentTypeChanged
