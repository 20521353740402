import { GuidelineCategories, PatientBriefActions } from 'consts/hiToolsConsts'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation()

  const briefSummaryStringifyMapper = useMemo(
    () => ({
      [GuidelineCategories.AlignersTxTracking]: items =>
        `${t('dialogs.patientInfo.brief.summary.categories.alignersTxTracking')} ${
          items.untrackedTeeth?.length > 0 ? `(${items.untrackedTeeth.join(', ')}` : ''
        }`,
      [GuidelineCategories.BracesTxTracking]: items =>
        `${t('dialogs.patientInfo.brief.summary.categories.bracesTxTracking')} ${
          items.untrackedTeeth?.length > 0 ? items.untrackedTeeth.join(', ') : ''
        }`,
      [GuidelineCategories.TxGoals]: items => {
        const enabledActionKeys = Object.keys(items)
          .map(itemKey => (items[itemKey].isChecked ? itemKey : null))
          .filter(action => action)

        if (!enabledActionKeys.length) {
          return ``
        }

        return `${t('dialogs.patientInfo.brief.summary.categories.txGoals')}: ${enabledActionKeys
          .map(actionKey => t(`dialogs.patientInfo.brief.summary.actions.${actionKey}`))
          .join(', ')}`
      },
      [GuidelineCategories.IPR]: items =>
        `${t('dialogs.patientInfo.brief.summary.categories.IPR')}: ${t(
          `dialogs.patientInfo.brief.summary.actions.IPR`,
          { number: items.alignerNumberReached }
        )}`,
      [GuidelineCategories.Elastics]: items =>
        t('dialogs.patientInfo.brief.summary.categories.elastics', {
          numOfElastics: items?.[PatientBriefActions.Elastics]?.length
        })
    }),
    [t]
  )

  const getBriefSummary = useCallback(
    briefCategories => {
      const summaryCategories = Object.keys(briefCategories)
        .map(
          categoryKey =>
            !!briefSummaryStringifyMapper[categoryKey] && briefCategories[categoryKey].isEnabled
              ? { key: categoryKey, items: briefCategories[categoryKey].items }
              : null,
          []
        )
        .filter(category => category)

      return summaryCategories.map(category => briefSummaryStringifyMapper[category.key](category.items)).join(' | ')
    },
    [briefSummaryStringifyMapper]
  )

  return {
    briefSummaryStringifyMapper,
    getBriefSummary
  }
}
