import * as React from 'react'
const ColorLegendIcon = ({ color }) => {
  return (
    <svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="6" fill={color} />
    </svg>
  )
}

export default ColorLegendIcon
