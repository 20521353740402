import * as React from 'react'

function InfoIcon(props) {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 0C3.36064 0 0 3.36064 0 7.5C0 11.6394 3.36064 15 7.5 15C11.6394 15 15 11.6394 15 7.5C15 3.36064 11.6394 0 7.5 0ZM7.5 0.527344C11.3483 0.527344 14.4727 3.65168 14.4727 7.5C14.4727 11.3483 11.3483 14.4727 7.5 14.4727C3.65168 14.4727 0.527344 11.3483 0.527344 7.5C0.527344 3.65168 3.65168 0.527344 7.5 0.527344ZM8.87695 7.60119C8.87695 6.84073 8.26049 6.2243 7.50006 6.2243H7.5C7.13481 6.2243 6.78457 6.36935 6.52635 6.6276C6.26813 6.88582 6.12305 7.23603 6.12305 7.60125V10.5327C6.12305 10.8979 6.26813 11.2481 6.52635 11.5063C6.78457 11.7645 7.13481 11.9096 7.5 11.9096H7.50006C8.26049 11.9096 8.87695 11.2932 8.87695 10.5327V7.60119ZM8.34961 7.60119C8.34961 7.13197 7.96925 6.75164 7.50006 6.75164H7.5C7.27468 6.75164 7.05855 6.84114 6.89924 7.00049C6.73989 7.1598 6.65039 7.3759 6.65039 7.60125V10.5327C6.65039 10.758 6.73989 10.9741 6.89924 11.1334C7.05855 11.2927 7.27468 11.3823 7.5 11.3823H7.50006C7.96925 11.3823 8.34961 11.0019 8.34961 10.5327V7.60119ZM7.5 3.09038C6.74004 3.09038 6.12305 3.70737 6.12305 4.46733C6.12305 5.22729 6.74004 5.84429 7.5 5.84429C8.25996 5.84429 8.87695 5.22729 8.87695 4.46733C8.87695 3.70737 8.25996 3.09038 7.5 3.09038ZM7.5 3.61772C7.96893 3.61772 8.34961 3.99844 8.34961 4.46733C8.34961 4.93626 7.96893 5.31694 7.5 5.31694C7.03107 5.31694 6.65039 4.93626 6.65039 4.46733C6.65039 3.99844 7.03107 3.61772 7.5 3.61772Z"
        fill="#3C52EF"
      />
    </svg>
  )
}

export default InfoIcon
