import * as React from 'react'

const ReadyToScan = ({ color = '#001335', isEnabled = false }) => {
  return (
    <svg width="31" height="29" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 13.5616C3 12.6438 3.62459 11.8439 4.51493 11.6213L13 9.5V19.5L4.51493 17.3787C3.62459 17.1561 3 16.3562 3 15.4384V13.5616Z"
        stroke={isEnabled ? '#4151E6' : color}
      />
      <path
        d="M11.1472 10.5634C11.6729 9.59966 12.6831 9 13.7809 9H20.2191C21.3169 9 22.3271 9.59966 22.8528 10.5634L24.2164 13.0634C24.7048 13.9589 24.7048 15.0411 24.2164 15.9366L22.8528 18.4366C22.3271 19.4003 21.3169 20 20.2191 20H13.7809C12.6831 20 11.6729 19.4003 11.1472 18.4366L9.78358 15.9366C9.29516 15.0411 9.29516 13.9589 9.78358 13.0634L11.1472 10.5634Z"
        fill="white"
        stroke={isEnabled ? '#4151E6' : color}
      />
      <path
        d="M13.1005 12.5794C13.6704 11.8955 14.5148 11.5 15.4051 11.5H18.5949C19.4852 11.5 20.3296 11.8955 20.8995 12.5794L21.433 13.2196C22.0511 13.9613 22.0511 15.0387 21.433 15.7804L20.8995 16.4206C20.3296 17.1045 19.4852 17.5 18.5949 17.5H15.4051C14.5148 17.5 13.6704 17.1045 13.1005 16.4206L12.567 15.7804C11.9489 15.0387 11.9489 13.9613 12.567 13.2196L13.1005 12.5794Z"
        fill="white"
        stroke={isEnabled ? '#4151E6' : color}
        strokeWidth="0.75"
      />
      <path
        d="M28.7375 17.7375L29.2678 17.2072L28.7375 16.6769L27.3233 15.2626L26.793 14.7323L26.2626 15.2626L21.5001 20.0252L19.7375 18.2626L19.2072 17.7323L18.6769 18.2626L17.2626 19.6769L16.7323 20.2072L17.2626 20.7375L20.9697 24.4446L21.5001 24.9749L22.0304 24.4446L28.7375 17.7375Z"
        fill={isEnabled ? '#4151E6' : color}
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default ReadyToScan
