import React, { useEffect, useMemo } from 'react'
import SelectInput from 'components/common/SelectInput'
import { generateHoursList } from 'utils/dateUtils'

const RuleExecutionHourInput = ({ value, onChange = () => {}, defaultHour = '10:00' }) => {
  const options = useMemo(() => generateHoursList(60, '00:00', '23:00', 'HH:mm'), [])

  useEffect(() => {
    if (!value) {
      onChange(defaultHour)
    }
  }, [onChange, value, defaultHour])

  return (
    <SelectInput
      value={value}
      onChange={({ value }) => onChange(value)}
      options={options}
      style={{ bright: true, thick: false }}
      selectStyle={{ border: 0, borderBottom: '0.75px solid var(--border-color-33)' }}
      keepErrorContainerWhenInactive={false}
    />
  )
}

export default RuleExecutionHourInput
