import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { CardMedia, Grid } from '@material-ui/core'
import PreventiveLayers from 'components/Patients/Timeline/ScanSummary/Preventive/PreventiveLayers'
import { PREVENTIVE_LAYERS } from 'consts/preventiveConsts'
import AutochartScanTilesList from './AutochartScanTilesList'
import { updateWhere } from 'utils/arrayUtils'
import PreventiveLegend from 'components/Patients/Timeline/ScanSummary/Preventive/PreventiveLegend'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    flexWrap: 'nowrap'
  },
  selectedMediaContainer: {
    height: '80%',
    width: '100%',
    textAlign: 'center'
  },
  preventiveLegendContainer: {
    padding: '20px 40px'
  },
  preventiveLegendTitle: {
    marginBottom: '10px'
  },
  preventiveLayers: {
    height: '100%',
    aspectRatio: 1.66
  },
  toggleAllButton: {
    color: 'var(--text-color-48)',
    cursor: 'pointer'
  }
}))

const AutochartScanView = ({ grinScanVideo, scanSummaryData }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [selectedOption, setSelectedOption] = useState()
  const [preventiveLayers, setPreventiveLayers] = useState([
    ...PREVENTIVE_LAYERS.map(layer => ({ ...layer, visible: true }))
  ])

  const parsedScanSummaryData = useMemo(() => JSON.parse(scanSummaryData || '{}'), [scanSummaryData])
  const displayedPreventiveLayers = useMemo(
    () => preventiveLayers.filter(layer => layer.visible).map(layer => layer.type),
    [preventiveLayers]
  )

  const handleTogglePreventiveLayer = useCallback(
    layerKey => {
      const updatedLayers = updateWhere(
        preventiveLayers,
        layer => layer.type === layerKey,
        layer => (layer.visible = !layer.visible)
      )

      setPreventiveLayers(updatedLayers)
    },
    [preventiveLayers]
  )

  const handleToggleAll = useCallback(() => {
    const isVisible = preventiveLayers.find(layer => layer.visible)
    setPreventiveLayers(preventiveLayers.map(layer => ({ ...layer, visible: !isVisible })))
  }, [preventiveLayers])

  return (
    <Grid container direction="column" className={classes.root} justifyContent="space-between">
      <Grid item>
        <Grid container style={{ height: 'var(--autochart-chart-height)' }}>
          <Grid item className={classes.selectedMediaContainer}>
            {selectedOption &&
              (selectedOption.type === 'video' ? (
                <CardMedia
                  style={{ height: '100%' }}
                  component={selectedOption.type}
                  src={selectedOption.src}
                  controls={true}
                  height={'100%'}
                />
              ) : selectedOption.type === 'img' ? (
                <PreventiveLayers
                  backgroundImageUrl={selectedOption.src}
                  displayedLayers={displayedPreventiveLayers}
                  preventiveImages={JSON.parse(scanSummaryData).preventiveLayersImages}
                  position={selectedOption.pose}
                  className={classes.preventiveLayers}
                />
              ) : (
                <></>
              ))}
          </Grid>
          <Grid item>
            <AutochartScanTilesList
              grinScanVideo={grinScanVideo}
              scanSummaryData={parsedScanSummaryData}
              selectedOption={selectedOption}
              onSelect={setSelectedOption}
            />
          </Grid>
        </Grid>
      </Grid>
      {selectedOption?.type === 'img' && (
        <Grid item className={classes.preventiveLegendContainer}>
          <DazzedParagraph16 strong className={classes.preventiveLegendTitle}>
            {t('dialogs.scanSummary.preventive.sectionTitle')}
          </DazzedParagraph16>
          <Grid container spacing={1}>
            <Grid item xs={12} xl={8}>
              <PreventiveLegend
                hasMultiLayers={true}
                layers={preventiveLayers}
                toggleLayer={handleTogglePreventiveLayer}
                legendItemXs={6}
                spacing={1}
              />
            </Grid>
            <Grid item xs={12} xl={4}>
              <DazzedParagraph16 className={classes.toggleAllButton} onClick={handleToggleAll}>
                {t('dialogs.scanSummary.preventive.legendToggleAll')}
              </DazzedParagraph16>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default AutochartScanView
