import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Triangle } from './icons'

const useStyles = makeStyles(theme => ({
  triangle: {
    marginLeft: 3,
    transform: ({ expanded }) => (expanded ? 'rotate(0)' : 'rotate(180deg)'),
    fill: ({ color }) => color
  },
  text: {
    textDecoration: 'underline',
    fontWeight: 'bold',
    marginLeft: 3,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    color: ({ color }) => color
  }
}))

const ExpandMore = ({ expanded, onClick, className, showMoreText, showLessText, color = 'var(--text-color-6)' }) => {
  const classes = useStyles({ expanded, color })
  const { t } = useTranslation()

  const showMore = useMemo(() => showMoreText || t('general.showMore'), [showMoreText, t])
  const showLess = useMemo(() => showLessText || t('general.showLess'), [showLessText, t])

  return (
    <span className={classNames(classes.text, className)} onClick={onClick}>
      {expanded ? showLess : showMore}
      <Triangle className={classes.triangle} color={color} />
    </span>
  )
}

export default ExpandMore
