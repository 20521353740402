import React from 'react'
import { makeStyles, Slider as MuiSLider } from '@material-ui/core'
import DazzedParagraph12 from './text/DazzedParagraph12'

const useStyles = () =>
  makeStyles({
    root: {
      color: 'var(--bg-color-7)'
    },
    label: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: -5,
      fontSize: '9px',
      color: 'var(--text-color-11)'
    }
  })()

const GrinSlider = ({ value, handleChange, min = 0, max = 100, minLabel, maxLabel }) => {
  const classes = useStyles()

  return (
    <div>
      {(minLabel || maxLabel) && (
        <DazzedParagraph12 className={classes.label}>
          <span>{minLabel}</span>
          <span>{maxLabel}</span>
        </DazzedParagraph12>
      )}
      <MuiSLider value={value} onChange={handleChange} min={min} max={max} classes={{ root: classes.root }} />
    </div>
  )
}

export default GrinSlider
