export const IMAGE_MODES = {
  normal: 'normalImages',
  sharpened: 'sharpenedImages',
  preventive: 'preventiveImages',
  preventiveLayers: 'preventiveLayersImages'
}

export const SCAN_SUMMARY_SECTIONS = {
  PREVENTIVE: 'preventive',
  TREATMENT_TRACKING: 'treatment-tracking'
}

export const SCAN_SUMMARY_POSES = {
  center: 'center',
  upper: 'upper',
  lower: 'lower',
  left: 'left',
  right: 'right'
}

export const ScanSummaryStatus = {
  Pending: 'pending',
  Completed: 'completed',
  Failed: 'failed'
}

export const ScanSummaryRatingOptions = {
  Positive: 'good',
  Negative: 'bad'
}
