import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import BaseModal from 'components/common/modals/BaseModal'
import { Grid, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import GrinCheckbox from 'components/common/GrinCheckbox'
import useRolePermissions from 'hooks/useRolePermissions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '660px'
  },
  titleInput: {
    height: 30,
    fontFamily: 'Dazzed',
    fontSize: 24,
    fontWeight: 600
  },
  messageInput: {
    borderRadius: 10,
    border: '1px solid #ABB0B8',
    height: 260,
    '&:focus': {
      outline: 'none'
    },
    fontFamily: 'Dazzed',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'left',
    paddingTop: 10,
    paddingLeft: 10,
    resize: 'none',
    marginTop: 30
  },
  checkbox: {
    marginLeft: -9,
    marginTop: 10
  }
}))

const NewTemplateModal = ({ isOpen, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()

  const isLoading = useSelector(state => state.profileReducer.isLoading)

  const [title, setTitle] = useState('')
  const [text, setText] = useState('')
  const [isAvailableForPracticeMembers, setIsAvailableForPracticeMembers] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const isValid = useMemo(() => text.trim() && title.trim(), [title, text])

  const handleSave = useCallback(() => {
    if (!isValid) {
      return
    }

    dispatch(Actions.createMessageTemplate({ text, title, isPrivate: !isAvailableForPracticeMembers }))
    setIsSubmitted(true)
  }, [dispatch, text, title, isValid, isAvailableForPracticeMembers])

  const clearForm = useCallback(() => {
    setTitle('')
    setText('')
    setIsAvailableForPracticeMembers(true)
    setIsSubmitted(false)
  }, [])

  const handleKeyDown = useCallback(
    e => {
      if (e.code === 'Enter' && (e.metaKey || e.ctrlKey)) {
        handleSave()
      }
    },
    [handleSave]
  )

  useEffect(() => {
    if (!isLoading && isSubmitted) {
      onClose()
    }
  }, [isSubmitted, onClose, isLoading])

  useEffect(() => {
    if (!isOpen) {
      clearForm()
    }
  }, [isOpen, clearForm])

  return (
    <BaseModal
      open={isOpen}
      handleClose={onClose}
      primaryLabel={t('general.save')}
      secondaryLabel={t('general.cancel')}
      onPrimaryBtnClick={handleSave}
      onSecondaryBtnClick={onClose}
      isPrimaryDisabled={!isValid}
      title={t('dialogs.newMessageTemplate.title')}
      isLoading={isLoading}
      className={classes.root}
      onKeyDown={e => e.stopPropagation()}
    >
      <Grid container direction="column">
        <TextField
          autoFocus={true}
          placeholder={t('pages.accountSettings.savedReplies.titlePlaceholder')}
          InputProps={{
            classes: { input: classes.titleInput }
          }}
          value={title}
          onChange={({ target }) => setTitle(target.value)}
        />
        <textarea
          className={classes.messageInput}
          type="text"
          name="message-content"
          value={text}
          column={15}
          rows={15}
          placeholder={t('pages.accountSettings.savedReplies.messagePlaceholder')}
          autoComplete="off"
          onChange={({ target }) => setText(target.value)}
          onKeyDown={handleKeyDown}
        />
        {permissions.makeTemplatesPrivate && (
          <GrinCheckbox
            caption={t('dialogs.newMessageTemplate.checkbox')}
            wrapperClassName={classes.checkbox}
            checked={isAvailableForPracticeMembers}
            onChange={setIsAvailableForPracticeMembers}
          />
        )}
      </Grid>
    </BaseModal>
  )
}

export default NewTemplateModal
