import * as React from 'react'

export default () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0558 1.42578C10.7338 1.42578 11.3143 1.66722 11.7972 2.15008C12.28 2.63295 12.5215 3.21342 12.5215 3.89149C12.5215 4.0045 12.5138 4.15604 12.4984 4.34611C12.483 4.53617 12.4598 4.75449 12.429 5.00106L11.5814 11.2116C11.5301 11.602 11.3528 11.9205 11.0498 12.167C10.7467 12.4136 10.3999 12.5369 10.0095 12.5369C9.77324 12.5369 9.55492 12.4855 9.35458 12.3828C9.15424 12.28 8.9873 12.1362 8.85374 11.9513L7.20479 9.54722C7.18424 9.50612 7.15085 9.47787 7.10462 9.46246C7.05839 9.44705 7.00959 9.43934 6.95822 9.43934C6.91713 9.43934 6.83494 9.48558 6.71165 9.57804L5.10894 11.9051C4.9651 12.1105 4.78788 12.2672 4.57727 12.3751C4.36666 12.483 4.1432 12.5369 3.9069 12.5369C3.5165 12.5369 3.17233 12.411 2.87439 12.1593C2.57645 11.9076 2.40179 11.5866 2.35042 11.1962L1.51825 5.00106C1.48742 4.75449 1.46431 4.53617 1.4489 4.34611C1.43349 4.15604 1.42578 4.0045 1.42578 3.89149C1.42578 3.21342 1.66722 2.63295 2.15008 2.15008C2.63295 1.66722 3.21342 1.42578 3.89149 1.42578C4.26135 1.42578 4.55672 1.47458 4.77761 1.57218C4.99849 1.66978 5.21168 1.77509 5.41715 1.8881C5.62263 2.00111 5.84095 2.10642 6.07211 2.20402C6.30327 2.30162 6.60378 2.35042 6.97363 2.35042C7.34349 2.35042 7.644 2.30162 7.87516 2.20402C8.10632 2.10642 8.32464 2.00111 8.53011 1.8881C8.73559 1.77509 8.95134 1.66978 9.17736 1.57218C9.40338 1.47458 9.69619 1.42578 10.0558 1.42578Z"
        fill="#747B8A"
      />
      <path d="M12.9999 0.963135L0.962891 13.0002" stroke="white" strokeWidth="1.38889" />
      <path d="M12.537 0.5L0.5 12.537" stroke="#747B8A" />
    </svg>
  )
}
