import * as React from 'react'

const Chewies = props => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="17.1734"
      y="11.317"
      width="6"
      height="16"
      rx="3"
      transform="rotate(120 17.1734 11.317)"
      fill="#527195"
      stroke="#F9F9FF"
    />
    <rect x="4.50012" y="0.5" width="6" height="16" rx="3" fill="#527195" stroke="#F9F9FF" />
  </svg>
)

export default Chewies
