import * as React from 'react'

const ResolveLead = props => {
  return (
    <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="4.5" r="3.5" stroke="#4151E6" />
      <path d="M1 11C1 11 3.5 9 8 9C12.5 9 15 11 15 11V15H1V11Z" stroke="#4151E6" />
      <path
        d="M11 19.3284C11.3905 19.719 12.0237 19.719 12.4142 19.3284L21.6213 10.1213C22.0118 9.7308 22.0118 9.09763 21.6213 8.70711L20.2071 7.29289C20.0196 7.10536 19.7652 7 19.5 7C19.2348 7 18.9804 7.10536 18.7929 7.29289L11.7071 14.3787L9.12132 11.7929C8.7308 11.4024 8.09763 11.4024 7.70711 11.7929L6.29289 13.2071C6.10536 13.3946 6 13.649 6 13.9142C6 14.1794 6.10536 14.4338 6.29289 14.6213L11 19.3284Z"
        fill="#4151E6"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ResolveLead
