import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import GrinMenu from 'components/common/menu/GrinMenu'
import { IconButton } from '@material-ui/core'
import KebabMenu from 'components/common/icons/KebabMenu'
import GrinMenuItem from 'components/common/menu/GrinMenuItem'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const AlignerMenu = ({ isOpen, setIsOpen, onEditAlignerNumberClicked }) => {
  const { t } = useTranslation()

  const handleActionClick = useCallback(
    actionCallback => {
      setIsOpen(false)
      actionCallback()
    },
    [setIsOpen]
  )

  return (
    <GrinMenu
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      triggerComponent={
        <IconButton size="small">
          <KebabMenu />
        </IconButton>
      }
    >
      <GrinMenuItem onClick={() => handleActionClick(onEditAlignerNumberClicked)}>
        <DazzedParagraph14 strong>
          {t('pages.patients.selectedPatient.timelineV2.scanHeader.editAlignerNumber')}
        </DazzedParagraph14>
      </GrinMenuItem>
    </GrinMenu>
  )
}

export default AlignerMenu
