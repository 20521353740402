import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty, merge } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import { Button, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import SwooshLargeIcon from 'components/common/icons/SwooshLarge1'
import FormInput from 'components/common/FormInput'
import WithLoader from 'components/common/WithLoader'
import { isValidEmail } from 'utils/generalUtils'
import { validatePasswords } from 'utils/authUtils'
import Actions from 'actions'

const useSignUpStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    height: '100%'
  },
  signUp: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main
  },
  signUpTitle: {
    fontFamily: 'Ogg',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '40px',
    lineHeight: '40px',
    marginBottom: '64px',
    color: 'var(--text-color-1)',
    '@media (max-width: 960px)': {
      textAlign: 'center',
      marginBottom: '45px'
    }
  },
  signUpSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0',
    '@media (max-width: 960px)': {
      display: 'none'
    }
  },
  nextButton: {
    background: 'var(--border-color-1)',
    color: 'var(--text-color-1)',
    width: '100%',
    borderRadius: '34px',
    '&:hover': {
      background: 'var(--border-color-1)'
    }
  },
  noFill: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      transition: 'color 0.2s ease',
      color: 'gray',
      boxShadow: 'none'
    }
  },
  formWrapper: {
    width: '578px',
    '@media (max-width: 960px)': {
      width: '75%',
      padding: '40px 20px'
    },
    '@media (max-width: 600px)': {
      width: '100%'
    }
  },
  accountForm: {
    marginBottom: 20,
    '@media (max-width: 960px)': {
      width: '100%'
    }
  },
  inputMargin: {
    marginBottom: 20
  }
}))

const QuickSignUp = () => {
  const classes = useSignUpStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const {
    password,
    passwordConfirmation,
    emailAddress,
    isLoading,
    errors: globalErrors
  } = useSelector(state => state.quickSignUpReducer)
  const [errors, setErrors] = useState({})
  const grinGroups = useSelector(state => state.supportReducer.userGroups.data)

  useEffect(() => {
    if (!grinGroups.length) {
      dispatch(Actions.fetchUserGroups())
    }
  }, [grinGroups, dispatch])

  useEffect(() => {
    if (globalErrors.emailAddress) {
      setErrors({ ...errors, emailAddress: globalErrors.emailAddress })
    }
  }, [globalErrors]) //eslint-disable-line

  const validateForm = useCallback(() => {
    let localErrors = {}
    if (!isValidEmail(emailAddress)) {
      localErrors.emailAddress = t('pages.signUp.invalidEmail')
    }
    const passwordErrors = validatePasswords(password, passwordConfirmation).errors

    if (passwordErrors) {
      localErrors = merge(localErrors, passwordErrors)
    }
    return localErrors
  }, [emailAddress, password, passwordConfirmation, t])

  const handleFormSubmit = useCallback(
    e => {
      e.preventDefault()
      const formErrors = validateForm()

      if (!isEmpty(formErrors)) {
        setErrors(formErrors)
      } else {
        dispatch(Actions.verifyDoctorEmail())
      }
    },
    [validateForm, dispatch]
  )

  const AccountForm = useMemo(
    () => (
      <div className={classes.accountForm}>
        <FormInput
          title={t('pages.signUp.email')}
          placeholder={t('pages.signUp.emailPlaceholder')}
          type="email"
          validationRules={{ required: true }}
          value={emailAddress}
          allowAutoComplete={true}
          errorMessage={errors.emailAddress}
          setValue={emailAddress =>
            dispatch(
              Actions.quickSignUpFormChanged({
                key: 'emailAddress',
                value: emailAddress?.toLowerCase()
              })
            )
          }
          containerClassName={classes.inputMargin}
        />
        <FormInput
          title={t('pages.signUp.password')}
          placeholder={t('pages.signUp.passwordPlaceholder')}
          validationRules={{ required: true }}
          value={password}
          type="password"
          errorMessage={errors.password}
          setValue={password =>
            dispatch(
              Actions.quickSignUpFormChanged({
                key: 'password',
                value: password
              })
            )
          }
          containerClassName={classes.inputMargin}
        />
        <FormInput
          title={t('pages.signUp.confirmPassword')}
          placeholder={t('pages.signUp.confirmPasswordPlaceholder')}
          type="password"
          validationRules={{ required: true }}
          value={passwordConfirmation}
          errorMessage={errors.passwordConfirmation}
          setValue={passwordConfirmation =>
            dispatch(
              Actions.quickSignUpFormChanged({
                key: 'passwordConfirmation',
                value: passwordConfirmation
              })
            )
          }
          containerClassName={classes.inputMargin}
        />
      </div>
    ),
    [errors, emailAddress, password, passwordConfirmation, classes.accountForm, classes.inputMargin, t, dispatch]
  )

  return (
    <div className={classes.container}>
      <form className={classes.signUp} onSubmit={handleFormSubmit}>
        <SwooshLargeIcon className={classes.signUpSwoosh} />
        <Grid container display="flex" direction="column" justifyContent="center" className={classes.formWrapper}>
          <div className={classes.signUpTitle}>{t('pages.signUp.createAccount')}</div>
          {AccountForm}
          <WithLoader isLoading={isLoading}>
            <Button variant="contained" type="submit" className={classes.nextButton}>
              {t('general.next')}
            </Button>
          </WithLoader>
        </Grid>
      </form>
    </div>
  )
}

export default QuickSignUp
