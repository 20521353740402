import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Avatar, Typography } from '@material-ui/core'
import PatientAvatar from '../PatientAvatar'
import OggHeading40 from 'components/common/text/OggHeading40'
import PatientStatus from '../PatientStatus'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Skeleton } from '@material-ui/lab'

const UNKNOWN_AGE = '-'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '24px 8px'
  },
  nameSkeleton: {
    margin: '0 10px',
    height: '30px',
    padding: '0 400px 0 0'
  },
  patientInfoSkeleton: {
    margin: '0 10px',
    height: '16px',
    padding: '0 250px 0 0'
  },
  statusText: {
    color: 'var(--text-color-6)!important'
  },
  age: {
    marginTop: 4
  }
}))

const ScanReviewEditorHeader = ({ patient, isLoading, scan }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const patientAge = useMemo(() => {
    const birthdate = patient?.details?.birthdate

    if (!birthdate) {
      return UNKNOWN_AGE
    }

    return moment().diff(moment(birthdate), 'years', false)
  }, [patient])

  const totalApplianceNumber = useMemo(() => patient?.treatments?.items?.[0]?.totalApplianceNumber, [patient])

  return (
    <Grid container direction="row" className={classes.root} spacing={2}>
      <Grid item>
        {isLoading ? (
          <Skeleton variant="circle" height={74} width={74}>
            <Avatar />
          </Skeleton>
        ) : (
          <PatientAvatar name={patient?.details?.name} photo={patient?.photo} program={patient?.user?.program} />
        )}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="flex-start" spacing={isLoading ? 2 : 0}>
          <Grid item>
            {isLoading ? (
              <Skeleton variant="rect" className={classes.nameSkeleton}>
                <Typography> </Typography>
              </Skeleton>
            ) : (
              <OggHeading40>{patient?.details?.name}</OggHeading40>
            )}
          </Grid>
          <Grid item>
            {isLoading ? (
              <Skeleton variant="rect" className={classes.patientInfoSkeleton}>
                <Typography>{'  '}</Typography>
              </Skeleton>
            ) : (
              <Grid container alignItems="center" justifyContent="space-around" spacing={4}>
                <Grid item>
                  <PatientStatus
                    status={patient?.treatments?.items[0]?.currentStatus?.status}
                    textClassName={classes.statusText}
                  />
                </Grid>
                <Grid item>
                  <DazzedParagraph12 className={classes.age}>
                    {t('dialogs.scanReviewEditor.age')}: {patientAge}
                  </DazzedParagraph12>
                </Grid>
                {scan.applianceIndex && (
                  <Grid item>
                    <DazzedParagraph12 className={classes.age}>
                      {scan?.appliance} {scan?.applianceIndex}{' '}
                      {totalApplianceNumber ? t('dialogs.editAppliance.of', { number: totalApplianceNumber }) : ''}
                    </DazzedParagraph12>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ScanReviewEditorHeader
