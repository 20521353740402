import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import GrinLabel from '../../text/GrinLabel'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1
  },
  inputContainer: {
    border: '1px solid var(--border-color-2)',
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0 8px 0',
    backgroundColor: ({ dark }) => (dark ? 'var(--bg-color-4)' : 'transparent')
  },
  icon: {
    marginRight: '12px',
    minWidth: '22px',
    height: '22px',
    textAlign: 'center'
  },
  stripeInput: {
    flex: 1
  },
  errorContainer: {
    height: '20px',
    width: 300,
    color: 'red'
  }
}))

const StripeInputWrapper = ({
  label,
  icon,
  stripeElement,
  onChange,
  error,
  withBrandIcon,
  variant = 'bright',
  placeholder = '',
  iconClassName = ''
}) => {
  const classes = useStyles({ dark: variant === 'dark' })

  const options = useMemo(
    () => ({
      placeholder,
      showIcon: withBrandIcon,
      style: {
        base: {
          fontSize: '16px',
          letterSpacing: '0.07em',
          color: variant === 'dark' ? '#fff' : '#000',
          '::placeholder': {
            color: 'rgba(255, 255, 255, 0.3)'
          }
        }
      }
    }),
    [placeholder, withBrandIcon, variant]
  )

  return (
    <div className={classes.root}>
      <GrinLabel color={variant === 'dark' ? 'var(--text-color-1)' : ' var(--text-color-6)'}>{label}</GrinLabel>
      <div className={classes.inputContainer}>
        {icon && <div className={`${classes.icon} ${iconClassName}`}>{icon}</div>}
        {stripeElement({ onChange, className: classes.stripeInput, options })}
      </div>
      {error && <div className={classes.errorContainer}>{error}</div>}
    </div>
  )
}

export default StripeInputWrapper
