import React, { useCallback, useEffect, useMemo } from 'react'
import { Grid, CircularProgress, makeStyles } from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import PmsAppointmentSection from './PmsAppointmentSection'
import { Calendar2, Calendar3, Calendar4 } from 'components/common/icons'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { APPOINTMENT_MODES } from 'consts/pmsConsts'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import actions from 'actions'
import usePms from 'hooks/usePms'
import moment from 'moment'

const useStyles = makeStyles({
  section: {
    paddingTop: 24,
    paddingBottom: 24,
    borderBottom: '1px solid var(--border-color-10)'
  },
  pastAppointments: {
    paddingTop: 24,
    paddingBottom: 24
  },
  newAppointment: {
    position: 'sticky',
    bottom: 10,
    marginTop: 10
  },
  noAppointmentsMessage: {
    padding: '20px 100px 20px 100px'
  },
  appointmentsLoader: {
    marginTop: 20
  }
})

const PmsAppointments = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const { renderPmsNewAppointment, getAppointmentType } = usePms()
  const { data: appointments } = useSelector(state => state.patientsReducer.patientCard.appointments)
  const {
    isLoading: isLoadingPms,
    isFetchingStaticData,
    currentPatient,
    appointmentMode,
    pmsType
  } = useSelector(state => state.pmsReducer)
  const { patient: grinPatient } = useSelector(state => state.patientsReducer.patientCard)
  const isPatientArchived = useMemo(
    () => grinPatient?.treatments?.items?.[0]?.currentStatus?.status?.type === 'archived',
    [grinPatient]
  )

  const appointmentsWithTypes = useMemo(
    () => appointments?.map(apt => ({ ...apt, type: getAppointmentType(apt) })) || [],
    [appointments, getAppointmentType]
  )

  const isLoading = useMemo(() => isLoadingPms || isFetchingStaticData, [isLoadingPms, isFetchingStaticData])

  const upcomingAppointments = useMemo(
    () =>
      (appointmentsWithTypes || [])
        .filter(appointment => moment(appointment.date).isAfter(moment.utc().add(moment().utcOffset(), 'minutes')))
        .sort((a, b) => moment(a.date) - moment(b.date)),
    [appointmentsWithTypes]
  )

  const pastAppointments = useMemo(
    () =>
      (appointmentsWithTypes || [])
        .filter(appointment => moment(appointment.date).isBefore(moment.utc().add(moment().utcOffset(), 'minutes')))
        .sort((a, b) => moment(a.date) - moment(b.date)),
    [appointmentsWithTypes]
  )

  const handleClickNewAppointment = useCallback(() => {
    trackEvent('PMS appointments - New appointment mode clicked', {
      pmsType
    })
    dispatch(actions.pmsSetAppointmentMode(APPOINTMENT_MODES.new))
  }, [dispatch, pmsType])

  const handleDiscardNewAppointment = useCallback(() => {
    dispatch(actions.pmsSetAppointmentMode(APPOINTMENT_MODES.view))
  }, [dispatch])

  useEffect(() => {
    if (currentPatient && !isLoadingPms && appointments === null) {
      dispatch(actions.pmsFetchAppointments(currentPatient.id))
    }
  }, [appointments, dispatch, currentPatient, isLoadingPms])

  return isLoading ? (
    <Grid item xs={12} className={classes.appointmentsLoader}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <CircularProgress size={40} />
        </Grid>
      </Grid>
    </Grid>
  ) : appointmentMode === APPOINTMENT_MODES.new ? (
    renderPmsNewAppointment({ onDiscard: handleDiscardNewAppointment })
  ) : (
    <Grid item xs={12}>
      <Grid container>
        {!appointmentsWithTypes?.length ? (
          <Grid item>
            <DazzedParagraph16
              textAlign="center"
              color="var(--text-color-44)"
              className={classes.noAppointmentsMessage}
            >
              <Trans i18nKey="dialogs.patientInfo.appointments.noAppointmentsMessage" />
              <a href="mailto:support@get-grin.com" target="_blank" rel="noreferrer">
                {'support@get-grin.com'}
              </a>
            </DazzedParagraph16>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className={classes.section}>
                <PmsAppointmentSection
                  icon={<Calendar2 />}
                  title={t('dialogs.patientInfo.appointments.upcomingAppointments')}
                  appointments={upcomingAppointments}
                />
              </Grid>
              <Grid item xs={12} className={classes.pastAppointments}>
                <PmsAppointmentSection
                  icon={<Calendar3 />}
                  title={t('dialogs.patientInfo.appointments.pastAppointments')}
                  appointments={pastAppointments}
                  isPastAppointments
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} className={classes.newAppointment}>
          <Grid container justifyContent="center">
            <Grid item>
              <PrimaryButton
                label={t('dialogs.patientInfo.appointments.newAppointmentLabel')}
                icon={<Calendar4 />}
                onClick={handleClickNewAppointment}
                disabled={isPatientArchived}
                width="fit-content"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PmsAppointments
