import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import BaseModal from 'components/common/modals/BaseModal'
import Actions from 'actions'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import PackageArrived from 'components/common/icons/PackageArrived'
import { ActionItemTypes } from 'consts/appConsts'

const useStyles = makeStyles({
  container: {
    padding: '60px 20px 0px 20px'
  },
  text: {
    fontWeight: '400 !important'
  }
})

const UpdateApplianceArrivalModal = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { patient, isLoading, isOpen } = useSelector(
    state => state.taskManagerReducer.modals.updateApplianceArrivalModal
  )

  const dispatch = useDispatch()

  const handleCancel = useCallback(() => {
    dispatch(Actions.toggleApplianceArrivalModal({ isOpen: false }))
  }, [dispatch])

  const handleSubmit = useCallback(
    e => {
      dispatch(
        Actions.updateApplianceArrivalStatus({
          patientId: patient.id,
          actionItemTypes: [ActionItemTypes.UPDATE_APPLIANCE_ARRIVAL]
        })
      )
    },
    [dispatch, patient.id]
  )

  return (
    <BaseModal
      open={isOpen}
      secondaryLabel={t('dialogs.taskManager.updateApplianceArrival.secondaryButtonLabel')}
      primaryLabel={t('dialogs.taskManager.updateApplianceArrival.primaryButtonLabel')}
      variant="alert"
      className={classes.container}
      handleClose={handleCancel}
      onSecondaryBtnClick={handleCancel}
      onPrimaryBtnClick={handleSubmit}
      isLoading={isLoading}
    >
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <PackageArrived />
        </Grid>
        <Grid item style={{ textAlign: 'center' }}>
          <Grid container direction="column">
            <Grid item>
              <Grid container>
                <Grid item>
                  <DazzedHeading18 className={classes.text}>
                    {t('dialogs.taskManager.updateApplianceArrival.prefix')}
                    &nbsp;
                  </DazzedHeading18>
                </Grid>
                <Grid item>
                  <DazzedHeading18>
                    {t('dialogs.taskManager.updateApplianceArrival.patientName', { patientName: patient.name })}
                  </DazzedHeading18>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <DazzedHeading18 className={classes.text}>
                {t('dialogs.taskManager.updateApplianceArrival.suffix')}
              </DazzedHeading18>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default UpdateApplianceArrivalModal
