import * as React from 'react'

const ImageThumbnail = ({ ...props }) => {
  return (
    <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 3.5735C1 2.15219 2.1522 1 3.5735 1H14.9024V8.07713H21.6579V22.8748C21.6579 24.2961 20.5057 25.4483 19.0844 25.4483H3.5735C2.1522 25.4483 1 24.2961 1 22.8748V3.5735Z"
        stroke="#4151E6"
        strokeLinejoin="round"
      />
      <path d="M21.6579 8.07713L14.9025 1V8.07713H21.6579Z" stroke="#4151E6" strokeLinejoin="round" />
      <path
        d="M18 17.4286C18 17.1549 17.8878 16.8932 17.6897 16.7044L12.75 12L7.5 17L5 14.5V18C5 18.5523 5.44772 19 6 19H17C17.5523 19 18 18.5523 18 18V17.4286Z"
        fill="#4151E6"
      />
      <circle cx="7.5" cy="11.5" r="1.5" fill="#4151E6" />
    </svg>
  )
}

export default ImageThumbnail
