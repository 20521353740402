import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import FormInput from 'components/common/FormInput'
import ActionCheckbox from './ActionCheckbox'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 14
  },
  checkbox: {
    padding: '0px 9px 0px 0px'
  },
  inputWrapper: {
    margin: '0px !important',
    border: 'none !important'
  },
  input: {
    textAlign: 'center',
    width: '40px !important',
    border: 'none !important',
    borderBottom: '1px solid !important',
    fontSize: '14px !important',
    fontFamily: 'Dazzed'
  }
}))

const DEFAULT_KEEP_ALIGNERS_AMOUNT = 3

const KeepAlignersOrGoBack = ({ shouldKeepAligner, shouldGoBack, days = DEFAULT_KEEP_ALIGNERS_AMOUNT, onChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleToggleShouldKeepAligner = useCallback(
    isChecked => {
      onChange({
        shouldGoBack: false,
        shouldKeepAligner: isChecked,
        days
      })
    },
    [days, onChange]
  )

  const handleToggleShouldGoBack = useCallback(
    isChecked => {
      onChange({
        shouldGoBack: isChecked,
        shouldKeepAligner: false,
        days
      })
    },
    [days, onChange]
  )

  const handleDaysChanged = useCallback(
    newDays => {
      const newDaysNumber = Number(newDays)

      if (!!newDays && (newDays > 90 || newDays < 1)) {
        return
      }

      onChange({
        shouldGoBack: false,
        shouldKeepAligner: true,
        days: !newDays ? '' : newDaysNumber
      })
    },
    [onChange]
  )

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <ActionCheckbox
              variant="radio"
              checked={shouldKeepAligner}
              onChange={handleToggleShouldKeepAligner}
              caption={t('pages.accountSettings.practiceGuidelines.actions.keepAlignersOrGoBack.keepAligner')}
            />
          </Grid>
          <Grid item>
            <FormInput
              type="number"
              value={days}
              setValue={handleDaysChanged}
              style={{ bright: true, thick: false }}
              keepErrorContainerWhenInactive={false}
              inputClassName={classes.input}
              inputWrapperClassName={classes.inputWrapper}
              inputSuffix={t('pages.accountSettings.practiceGuidelines.actions.keepAlignersOrGoBack.moreDays')}
              maxLength={2}
              validationRules={{ required: true, pattern: '^[0-9]+$' }}
            />
          </Grid>
          <Grid item>
            <DazzedParagraph14>
              {t('pages.accountSettings.practiceGuidelines.actions.keepAlignersOrGoBack.moreDays')}
            </DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ActionCheckbox
          variant="radio"
          checked={shouldGoBack}
          onChange={handleToggleShouldGoBack}
          caption={t('pages.accountSettings.practiceGuidelines.actions.keepAlignersOrGoBack.goBack')}
        />
      </Grid>
    </Grid>
  )
}

export default KeepAlignersOrGoBack
