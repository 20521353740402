import React, { useCallback } from 'react'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  modalDescription: {
    marginTop: 40,
    marginBottom: 10
  },
  modal: {
    width: 'auto !important'
  },
  modalContent: {
    padding: '0 47px 40px 47px !important'
  },
  modalButton: {
    width: 'auto !important'
  }
}))
const AutomationsBlockedForPlanModal = ({ isOpen, isLoading, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const onContactSupportClicked = useCallback(() => {
    trackEvent('Service account - automations blocked - contact support clicked')
    window.open('mailto:support@get-grin.com', '_blank')
  }, [])

  return (
    <BaseModal
      open={isOpen}
      title={t('pages.accountSettings.automations.automationsBlockedForPlanModal.title')}
      variant="alert"
      isLoading={isLoading}
      onPrimaryBtnClick={onContactSupportClicked}
      onSecondaryBtnClick={onClose}
      primaryLabel={t('general.contactSupport')}
      secondaryLabel={t('general.cancel')}
      handleClose={onClose}
      className={classes.modal}
      contentClassName={classes.modalContent}
      largerPrimaryButton={true}
    >
      <DazzedParagraph16 textAlign="center">
        {t('pages.accountSettings.automations.automationsBlockedForPlanModal.description')}
      </DazzedParagraph16>
    </BaseModal>
  )
}

export default AutomationsBlockedForPlanModal
