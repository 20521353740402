import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Grid } from '@material-ui/core'
import { createTimelineItems } from '../../../../utils/timelineUtils'
import TimeLineItemsByYear from './TimeLineItemsByYear'
import { useTranslation } from 'react-i18next'
import { Element, scroller } from 'react-scroll'
import usePatientBanner from 'hooks/usePatientBanner'
import Banner from '../Banner'
import TimelineBroadcastState from '../../Broadcast/TimelineBroadcastState'
import { scrollBar } from 'styles/theme'
import actions from 'actions'
import CompareScansButton from '../ScanComparison/CompareScansButton'
import CompareScansDialog from '../ScanComparison/CompareScansDialog'
import ScanSummaryModal from '../ScanSummary/ScanSummaryModal/ScanSummaryModal'

const Timeline = () => {
  const useStyles = makeStyles({
    wrapper: {
      width: '50%',
      height: '100%',
      flexWrap: 'nowrap',
      position: 'relative'
    },
    containers: {
      fontSize: 30,
      textAlign: 'left',
      color: '#dedede',
      position: 'relative',
      overflowY: 'auto',
      overflowX: 'hidden',
      height: '100%',
      padding: '20px 40px 40px 40px'
    },
    containersItem: {
      position: 'relative',
      overflow: 'auto',
      width: '100%',
      height: '100%'
    },
    noScans: {
      textAlign: 'center',
      fontSize: 24,
      width: '100%',
      height: '100%'
    },
    scrollbar: {
      marginRight: 5,
      ...scrollBar
    },
    banner: {
      width: '100%'
    },
    compareScansContainer: {
      position: 'absolute',
      top: '4px',
      right: '20px',
      zIndex: 10
    }
  })

  const bannerType = usePatientBanner()
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const grinScans = useSelector(state => state.treatmentReducer.grinScans)
  const treatment = useSelector(state => state.treatmentReducer.treatment)
  const isBroadcastMode = useSelector(state => state.chatReducer.broadcast.isModeOn)
  const { patient, fetchedScansLeadId } = useSelector(state => state.patientsReducer)

  const yearsWithTimelineItems = useMemo(
    () => createTimelineItems(grinScans || [], treatment?.timelineEventLog || []),
    [grinScans, treatment]
  )

  const isScansAndEventlogsLoaded = useMemo(
    () => (Array.isArray(grinScans) && grinScans.length) || Array.isArray(treatment?.timelineEventLog),
    [treatment, grinScans]
  )

  useEffect(() => {
    if (isScansAndEventlogsLoaded) {
      scroller.scrollTo('bottomRef', {
        duration: 700,
        delay: 1500,
        smooth: 'linear',
        containerId: 'timelineContainer',
        offset: 400
      })
    }
  }, [isScansAndEventlogsLoaded])

  useEffect(() => {
    if (patient?.id && !patient?.grinScans?.items?.length && fetchedScansLeadId !== patient.id) {
      dispatch(actions.fetchLeadScans({ leadId: patient.id }))
    }
  }, [dispatch, fetchedScansLeadId, patient])

  if (isBroadcastMode) {
    return (
      <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.wrapper}>
        <Grid item>
          <TimelineBroadcastState />
        </Grid>
      </Grid>
    )
  }

  if (!grinScans || !patient) return null

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        className={classes.wrapper}
      >
        {bannerType && (
          <Grid item className={classes.banner}>
            <Banner type={bannerType} />
          </Grid>
        )}
        <Grid item className={classes.containersItem}>
          {grinScans.length > 1 && (
            <div className={classes.compareScansContainer}>
              <CompareScansButton />
            </div>
          )}
          <Element className={[classes.containers, classes.scrollbar].join(' ')} id="timelineContainer">
            {grinScans?.length ? (
              Object.entries(yearsWithTimelineItems).map(([year, monthsTimelineItems]) => (
                <TimeLineItemsByYear key={year} year={year} monthsTimelineItems={monthsTimelineItems} />
              ))
            ) : (
              <Grid item style={{ height: '100%' }}>
                <Grid container alignItems="center" justifyContent="center" className={classes.noScans}>
                  <Grid item>{t('pages.patients.selectedPatient.timeline.noScansYet')}</Grid>
                </Grid>
              </Grid>
            )}
            <Element name="bottomRef" />
          </Element>
        </Grid>
      </Grid>
      <CompareScansDialog />
      <ScanSummaryModal />
    </>
  )
}

export default React.memo(Timeline)
