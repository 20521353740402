import { API } from 'aws-amplify'
import axios from 'axios'
import { logError, logInfo } from 'utils/logUtils'

export const OtpErrors = {
  InvalidSession: 'invalid-session',
  InvalidCode: 'invalid-code'
}

export const resendCode = async ({ sessionId }) => {
  try {
    logInfo(`OTP resendCode: ${sessionId}`, { otpSessionId: sessionId })
    const res = await API.endpoint('grinServerlessApi').then(api =>
      axios.post(`${api}/otp/v1/sessions/resend`, {
        sessionId
      })
    )
    logInfo(`OTP resendCode: complete (session id: ${sessionId})`, { otpSessionId: sessionId })
    return res
  } catch (ex) {
    if (ex.response.status === 403 || ex.response.status === 404) {
      logError(`OTP resendCode: session is invalid`, { otpSessionId: sessionId })
      ex.otpError = OtpErrors.InvalidSession
    }

    throw ex
  }
}

export const verifyCode = async ({ sessionId, code }) => {
  try {
    logInfo(`OTP verifyCode: ${sessionId}`, { otpSessionId: sessionId })
    const res = await API.endpoint('grinServerlessApi').then(api =>
      axios.post(`${api}/otp/v1/sessions/verify`, {
        sessionId,
        verificationCode: code
      })
    )
    logInfo(`OTP verifyCode: complete (session id: ${sessionId})`, { otpSessionId: sessionId })

    return res
  } catch (ex) {
    if (ex.response.status === 403 || ex.response.status === 404) {
      logError(`OTP verifyCode: session is invalid`, { otpSessionId: sessionId })
      ex.otpError = OtpErrors.InvalidSession
    } else if (ex.response.data.code === 'invalidValue') {
      logError(`OTP verifyCode: invalid code`, { otpSessionId: sessionId })
      ex.otpError = OtpErrors.InvalidCode
    }

    throw ex
  }
}
