import * as React from 'react'

const Calendar3 = ({ className, color = '#727B8C' }) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.0327 1.69641H10.9688V0.970192C10.9688 0.435992 10.5357 0.00292969 10.0015 0.00292969H9.9628C9.4286 0.00292969 8.99554 0.435992 8.99554 0.970192V1.69641H4.00446V0.970192C4.00446 0.435992 3.5714 0.00292969 3.0372 0.00292969H2.99851C2.46431 0.00292969 2.03125 0.435992 2.03125 0.970192V1.69641H0.967262C0.433062 1.69641 0 2.12948 0 2.66368V13.0327C0 13.567 0.433062 14 0.967262 14H12.0327C12.5669 14 13 13.567 13 13.0327V2.66368C13 2.12948 12.5669 1.69641 12.0327 1.69641ZM11.375 12.4717H1.625V4.61755H11.375V12.4717Z"
        fill={color}
      />
      <path d="M4 8.5L6 10.5L9.5 7" stroke={color} strokeLinecap="round" />
    </svg>
  )
}

export default Calendar3
