import { PREVENTIVE_LAYERS } from 'consts/preventiveConsts'
import { IMAGE_MODES } from 'consts/scanSummaryConsts'
import { useCallback, useMemo, useState } from 'react'
import { trackEvent } from 'utils/analyticsUtils'
import { updateWhere } from 'utils/arrayUtils'

export default ({ scanId, setImagesMode, defaultImageMode = IMAGE_MODES.normal }) => {
  const [preventiveLayers, setPreventiveLayers] = useState(PREVENTIVE_LAYERS)

  const displayedPreventiveLayers = useMemo(
    () => preventiveLayers.filter(layer => layer.visible).map(layer => layer.type),
    [preventiveLayers]
  )

  const preventiveLayersAllToggle = useMemo(() => preventiveLayers.every(layer => layer.visible), [preventiveLayers])

  const isAnyPreventiveLayerVisible = useMemo(
    () => displayedPreventiveLayers.length > 0,
    [displayedPreventiveLayers.length]
  )

  const togglePreventiveLayer = useCallback(
    layerKey => {
      const updatedLayers = updateWhere(
        preventiveLayers,
        layer => layer.type === layerKey,
        layer => (layer.visible = !layer.visible)
      )

      setPreventiveLayers(updatedLayers)
    },
    [preventiveLayers]
  )

  const togglePreventive = useCallback(
    checked => {
      trackEvent(`Scan Summary - Preventive Toggled ${checked ? 'On' : 'Off'}`, {
        scanId
      })
      setImagesMode(checked ? IMAGE_MODES.preventive : defaultImageMode)
    },
    [scanId, setImagesMode, defaultImageMode]
  )

  const toggleAllPreventiveLayers = useCallback(() => {
    trackEvent(`Scan Summary - Preventive Layers Toggled All ${!preventiveLayersAllToggle ? 'On' : 'Off'}`)
    setPreventiveLayers(preventiveLayers.map(layer => ({ ...layer, visible: !preventiveLayersAllToggle })))
  }, [preventiveLayers, preventiveLayersAllToggle])

  const clearActiveLayers = useCallback(() => {
    setPreventiveLayers(preventiveLayers.map(layer => ({ ...layer, visible: false })))
  }, [preventiveLayers])

  return {
    preventiveLayers,
    displayedPreventiveLayers,
    isAnyPreventiveLayerVisible,
    togglePreventive,
    togglePreventiveLayer,
    toggleAllPreventiveLayers,
    clearActiveLayers
  }
}
