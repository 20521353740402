import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { conditionTypes } from 'consts/automatedActionConsts'
import ErrorMessage from 'components/common/text/ErrorMessage'

const useStyles = makeStyles(
  theme => ({
    input: {
      outline: 'none',
      fontSize: 16,
      textAlign: 'center',
      height: 32,
      width: 80,
      border: 'none',
      borderBottom: ({ errorMessage }) => (errorMessage ? '1px solid red' : '1px solid var(--border-color-11)'),
      paddingLeft: 14
    }
  }),
  { errorMessage: '' }
)

const NumberOfDaysCondition = ({ onChange, params, errors }) => {
  const errorMessage = useMemo(() => errors.conditions?.[conditionTypes.OverdueDays], [errors])
  const classes = useStyles({ errorMessage })
  const { t } = useTranslation()

  const handleChange = useCallback(
    newNumber => {
      onChange({
        type: conditionTypes.OverdueDays,
        params: {
          overdueDays: newNumber
        }
      })
    },
    [onChange]
  )

  return (
    <Grid container alignItems="center">
      <Grid item>
        <DazzedParagraph14 strong>
          {t('pages.accountSettings.automations.editorModal.enterNumberOfDays')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <input
          value={params?.overdueDays || ''}
          type="number"
          inputMode="numeric"
          className={classes.input}
          onChange={({ target }) => handleChange(target.value)}
          min={1}
          max={30}
        />
      </Grid>
      <Grid item>{errorMessage && <ErrorMessage text={errorMessage} active={!!errorMessage} />}</Grid>
    </Grid>
  )
}

export default NumberOfDaysCondition
