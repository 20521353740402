import React, { useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import NormalGrinScanItemIcon from 'components/common/icons/timelineV2/mini-timeline/NormalGrinScanItem'
import { TreatmentTrackOptions } from 'consts/giConsts'
import OnTrackGrinScanItemIcon from 'components/common/icons/timelineV2/mini-timeline/OnTrackGrinScanItem'
import OffTrackGrinScanItemIcon from 'components/common/icons/timelineV2/mini-timeline/OffTrackGrinScanItem'
import GrinScanTooltip from 'components/Patients/Timeline/V2/MiniTimeline/GrinScanTooltip'

const useStyles = makeStyles(theme => ({
  grinScanTimelineItemRoot: {
    position: 'relative',
    textAlign: 'center',
    lineHeight: 0.8,
    cursor: 'pointer',
    marginBottom: 3
  },
  dotIcon: {
    display: 'inline-flex',
    fontFamily: 'auto',
    border: '3px solid transparent',
    borderRadius: '100%',
    padding: ({ trackStatus }) => (trackStatus ? 0 : 2)
  },
  selected: {
    borderColor: '#3C52EF'
  }
}))

const GrinScanTimelineItem = ({ timelineItem, onSelect = () => {}, isSelected = false }) => {
  const scanTooltipAnchorEl = useRef()
  const [isScanTooltipOpen, setIsScanTooltipOpen] = useState(false)
  const trackStatus = useMemo(() => timelineItem?.payload?.trackStatus, [timelineItem])
  const classes = useStyles({ trackStatus })

  return (
    <div className={classes.grinScanTimelineItemRoot} onClick={onSelect} ref={scanTooltipAnchorEl}>
      <span
        className={[classes.dotIcon, isSelected ? classes.selected : ''].join(' ')}
        onMouseEnter={() => setIsScanTooltipOpen(true)}
        onMouseLeave={() => setIsScanTooltipOpen(false)}
      >
        <GrinScanTooltip
          anchorEl={scanTooltipAnchorEl.current}
          isOpen={isScanTooltipOpen}
          date={timelineItem.eventDate}
          scanNumber={timelineItem?.payload?.scanNumber}
          alignerNumber={timelineItem?.payload?.alignerNumber}
          scans={timelineItem?.payload?.scans}
          scanType={timelineItem?.payload?.scanType}
          trackStatus={trackStatus}
        />
        {trackStatus === TreatmentTrackOptions.OnTrack ? (
          <OnTrackGrinScanItemIcon />
        ) : trackStatus === TreatmentTrackOptions.OffTrack ? (
          <OffTrackGrinScanItemIcon />
        ) : (
          <NormalGrinScanItemIcon />
        )}
      </span>
    </div>
  )
}

export default React.memo(GrinScanTimelineItem)
