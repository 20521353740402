import { IconButton, Menu, MenuItem } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { MoreVert } from '@material-ui/icons'

const ContactMenu = ({ iconSize, onClose, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)

  const handleOpen = event => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(null)

    if (!!onClose) {
      onClose()
    }
  }

  return (
    <Fragment>
      <IconButton onClick={handleOpen} size={iconSize} style={{ padding: 2, margin: 0, marginRight: 10 }}>
        <MoreVert />
      </IconButton>
      <Menu
        transformOrigin={{ vertical: -40, horizontal: 'left' }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#001335'
          }
        }}
      >
        <MenuItem
          style={{ color: 'white' }}
          onClick={event => {
            onDelete()
            handleClose(event)
          }}
        >
          {'Delete'}
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default ContactMenu
