import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { PATIENT_CARD_TABS } from 'consts/patientCard'

const usePatientTags = ({
  patient,
  tagsTypes = ['program', 'status', 'authStatus', 'treatment', 'scan', 'custom', 'overdue']
}) => {
  const dispatch = useDispatch()

  const { doctor } = useSelector(state => state.profileReducer)

  const handleAsigneeTagClicked = useCallback(
    assigneeTag => {
      dispatch(
        Actions.togglePatientCard({
          isModalOpen: true,
          patientId: patient.id,
          selectedTab: PATIENT_CARD_TABS.notes.index
        })
      )

      const assigneeTagMetadata = JSON.parse(assigneeTag?.metadata || '{}')

      if (doctor.id === assigneeTagMetadata.assigneeId) {
        dispatch(Actions.setAssigneeSeenTag({ patientTag: assigneeTag }))
      }
    },
    [dispatch, doctor, patient]
  )

  const filteredPatientTags = useMemo(
    () =>
      patient?.patientTags?.items.filter(patientTag => {
        if (patientTag._deleted) {
          return false
        }

        if (!patientTag.tag) {
          return false
        }

        if (!tagsTypes.find(tagType => tagType === patientTag.tag.type)) {
          return false
        }

        return true
      }),
    [patient, tagsTypes]
  )

  const availableTags = useMemo(
    () =>
      patient?.patientTags?.items
        .filter(patientTag => {
          if (patientTag._deleted) {
            return false
          }

          if (!patientTag.tag) {
            return false
          }

          if (!tagsTypes.find(tagType => tagType === patientTag.tag.type)) {
            return false
          }

          return true
        })
        .map(patientTag => patientTag.tag) || [],
    [patient, tagsTypes]
  )

  const needsAttentionTag = filteredPatientTags?.find(pt => pt.tag.value === 'needsAttention')
  const needsAttentionTagDetails = needsAttentionTag?.metadata ? JSON.parse(needsAttentionTag.metadata) : null

  const assigneeTag = useMemo(() => {
    const assigneeTag = patient?.patientTags?.items.find(
      patientTag => patientTag?.tag?.type === 'assignee' && !patientTag._deleted
    )

    const assigneeTagMetadata = JSON.parse(assigneeTag?.metadata || '{}')
    return assigneeTag
      ? {
          ...assigneeTag.tag,
          label: assigneeTag.tag.value,
          onClick: () => handleAsigneeTagClicked(assigneeTag),
          setUnreadIndicator: () => dispatch(Actions.setAssigneeSeenTag({ patientTag: assigneeTag })),
          showUnseenIndicator: assigneeTagMetadata.unreadIndication === true,
          assigneeId: assigneeTagMetadata.assigneeId,
          noteId: assigneeTagMetadata.noteId
        }
      : null
  }, [dispatch, handleAsigneeTagClicked, patient])

  return { tags: availableTags, assigneeTag, needsAttentionTag, needsAttentionTagDetails }
}

export default usePatientTags
