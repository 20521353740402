import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { rangeArray } from 'utils/arrayUtils'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20
  },
  box: {
    borderRadius: '8px'
  }
}))

const PatientBriefLoadingState = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {rangeArray(4).map(i => (
        <Grid container direction="column" key={`skeleton-row-${i}`} spacing={4}>
          <Grid item>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Skeleton variant="rect" width={'100%'} height={75} className={classes.box} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rect" width={'100%'} height={75} className={classes.box} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </div>
  )
}

export default PatientBriefLoadingState
