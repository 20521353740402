import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.main,
    fontFamily: 'Dazzed',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '24px'
  }
}))

const DazzedHeading12 = ({ className, children, onClick }) => {
  const classes = useStyles()

  return (
    <div onClick={onClick} className={[classes.heading, className].join(' ')}>
      {children}
    </div>
  )
}

export default DazzedHeading12
