import * as React from 'react'

const TriggerToActionArrow = props => {
  return (
    <svg width="137" height="22" viewBox="0 0 137 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 11H137" stroke="#ABB0B8" strokeDasharray="3 3" />
      <circle cx="68" cy="11" r="10" fill="white" stroke="#ABB0B8" strokeWidth="2" />
      <path d="M68 7L72 11L68 15" stroke="#ABB0B8" strokeWidth="2" />
      <path d="M72 11L63 11" stroke="#ABB0B8" strokeWidth="2" />
    </svg>
  )
}

export default TriggerToActionArrow
