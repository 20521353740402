import { useMemo } from 'react'
import useTeethTracking from '../useTeethTracking'

const GraphDot = ({ cx, cy, payload }) => {
  const { trackingStatuses } = useTeethTracking()

  const trackingStatusStyle = useMemo(
    () => trackingStatuses[payload.trackingStatus],
    [payload?.trackingStatus, trackingStatuses]
  )

  return (
    <circle
      cx={cx}
      cy={cy}
      r={5}
      fill={trackingStatusStyle.backgroundColor}
      stroke={trackingStatusStyle.borderColor}
      strokeWidth="3"
    />
  )
}

export default GraphDot
