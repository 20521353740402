import * as React from 'react'

function SvgDone(props) {
  return (
    <svg width={16} height={16} fill="none" {...props}>
      <circle cx={8} cy={8} r={8} fill="#3C52EF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 11.707L3.646 8.354a.5.5 0 01.708-.708L7 10.293l4.646-4.647a.5.5 0 11.708.708L7 11.707z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgDone
