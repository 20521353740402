import * as React from 'react'

function ThumbsUp({ isSelected, color = '#727B8C', width = 23, height = 23, unselectedColor = '' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.56836 18.254L8.56836 9.63534C8.56836 8.84147 7.92579 8.1989 7.13192 8.1989L3.30141 8.1989C2.50849 8.1989 1.86497 8.84147 1.86497 9.63534L1.86496 18.254C1.86496 19.0469 2.50849 19.6904 3.30141 19.6904C4.36437 19.6904 6.06895 19.6904 7.13192 19.6904C7.92579 19.6904 8.56836 19.0469 8.56836 18.254ZM7.61073 9.63534L7.61073 18.254C7.61073 18.5183 7.39622 18.7328 7.13192 18.7328L3.30141 18.7328C3.0371 18.7328 2.82259 18.5183 2.82259 18.254L2.82259 9.63534C2.82259 9.37104 3.0371 9.15653 3.30141 9.15653L7.13192 9.15653C7.39623 9.15653 7.61073 9.37104 7.61073 9.63534Z"
        fill={color}
        stroke={isSelected ? '#3C52EF' : unselectedColor}
        strokeWidth={0.5}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0784 11.021C21.2057 10.3229 21.0161 9.60374 20.5613 9.05885C20.1064 8.51396 19.4332 8.1989 18.7236 8.1989L15.8344 8.1989C15.7003 8.1989 15.573 8.1424 15.482 8.04376C15.391 7.94608 15.346 7.81393 15.3575 7.67986L15.6075 4.67387C15.709 3.46151 14.8844 2.36598 13.6912 2.12753L13.5562 2.10072C13.0199 1.99347 12.4693 2.19936 12.1361 2.63316L7.71086 8.38563C7.6467 8.46894 7.61127 8.57237 7.61127 8.67771L7.61126 17.2964C7.61126 17.4917 7.73097 17.6679 7.91292 17.7407C7.91292 17.7407 10.692 18.8525 12.0154 19.3821C12.5248 19.586 13.0678 19.6904 13.6165 19.6904C14.5684 19.6904 16.2529 19.6904 17.5045 19.6904C18.6613 19.6904 19.6534 18.863 19.8603 17.7244L21.0784 11.021ZM20.137 10.8496L18.918 17.553C18.7935 18.2358 18.1988 18.7328 17.5045 18.7328L13.6165 18.7328C13.1894 18.7328 12.7671 18.6514 12.3716 18.4934L8.56889 16.9717L8.56889 8.8405L12.8945 3.21731C13.0065 3.07271 13.1894 3.00376 13.3685 3.0392L13.5035 3.06601C14.2199 3.20965 14.714 3.86659 14.6537 4.59439L14.4028 7.60038C14.3692 8.00067 14.5052 8.39713 14.7772 8.69303C15.0492 8.98798 15.4332 9.15652 15.8344 9.15652C16.6216 9.15652 17.7315 9.15652 18.7236 9.15652C19.1497 9.15652 19.5529 9.34518 19.8258 9.67269C20.0987 9.99924 20.2127 10.4311 20.137 10.8496Z"
        fill={color}
        stroke={isSelected ? '#3C52EF' : unselectedColor}
        strokeWidth={0.5}
      />
    </svg>
  )
}

export default ThumbsUp
