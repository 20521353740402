import { isEmpty } from 'lodash'
import moment from 'moment'
import i18n from '../resources/locales/i18n'
import {
  GRIN_TRIAL_PLAN,
  GRIN_ENTERPRISE_PLAN_GROUP,
  BILLING_PERIOD_MONTHLY,
  GRIN_SUBSCRIPTION_GROUP,
  GRIN_SUBSCRIPTION_V2_GROUP,
  GRIN_PRACTICE_PLAN_GROUP,
  GRIN_PRACTICE_PLUS_PLAN_GROUP,
  GRIN_CHURNED_PRACTICE_GROUP
} from '../consts/grinPlanConsts'
import { TIME_FORMAT_3 } from '../consts/dateTimeConsts'
import { COUNTRY_US, COUNTRY_CA } from '../consts/countryConsts'

export const calculateAvailablePatientSlots = (allPlanOverrides, doctorGrinPlan, rooms) => {
  const occupiedSlots = rooms
    .map(room => room.patient)
    .filter(
      patient =>
        patient?.user?.isActive &&
        (patient?.statusKey === 'active-treatment_rc' || patient?.statusKey === 'active-treatment_rm')
    )
  return calculatePlanLimits(allPlanOverrides, doctorGrinPlan).maxPatients - occupiedSlots.length
}

export const calculatePlanLimits = (allPlanOverrides, doctorGrinPlan) => {
  const overrides = JSON.parse(allPlanOverrides || '{}')
  const planOverrides = overrides[doctorGrinPlan?.key]

  if (isEmpty(planOverrides)) {
    return {
      maxScopes: doctorGrinPlan?.maxScopes || 0,
      maxPatients: doctorGrinPlan?.maxPatients || 0
    }
  }

  return planOverrides
}

export const isOnTrial = grinPlan => {
  return grinPlan?.planGroup === GRIN_TRIAL_PLAN
}

export const isOnEnterprise = grinPlan => {
  return grinPlan?.planGroup === GRIN_ENTERPRISE_PLAN_GROUP
}

export const isOnPracticeLegacy1 = grinPlan =>
  grinPlan?.planGroup === GRIN_PRACTICE_PLAN_GROUP || grinPlan?.planGroup === GRIN_PRACTICE_PLUS_PLAN_GROUP

export const isOnLimitedSeatsPlan = grinPlan => {
  return isOnFreePlan(grinPlan) || isOnPracticeLegacy1(grinPlan)
}

export const isOnGrinSubscription = grinPlan => {
  return grinPlan?.planGroup === GRIN_SUBSCRIPTION_GROUP
}

export const isOnGrinSubscriptionV2 = grinPlan => {
  return grinPlan?.planGroup === GRIN_SUBSCRIPTION_V2_GROUP
}

export const isOnChurnedPlan = grinPlan => {
  return grinPlan?.planGroup === GRIN_CHURNED_PRACTICE_GROUP
}

export const isOnFreePlan = grinPlan => {
  return isOnChurnedPlan(grinPlan) || isOnTrial(grinPlan)
}

export const assemblePlanKey = (group, period) => {
  return [`${group}_${period}`]
}

export const assemblePlanDisplayFullName = plan => {
  if (plan.key === GRIN_TRIAL_PLAN) {
    return plan.displayName
  }

  if (plan.planGroup === GRIN_SUBSCRIPTION_V2_GROUP) {
    return `${plan.displayName} (2023)`
  }

  return `${plan.displayName} ${plan.period.charAt(0).toUpperCase() + plan.period.slice(1)}`
}

export const getRenewDate = planPeriod => {
  return moment()
    .add(1, planPeriod === BILLING_PERIOD_MONTHLY ? 'month' : 'year')
    .format(TIME_FORMAT_3)
}

export const getTransformedDescription = ({ type, scopesQuantity, description }) => {
  if (type === 'grin-scopes-purchase') {
    return i18n.t('pages.accountSettings.billing.transactionItemScopesPurchase', { scopesQuantity })
  } else if (type === 'subscription-purchase' && scopesQuantity > 0) {
    return i18n.t('pages.accountSettings.billing.transactionItemPlanAndScopesPurchase', { description, scopesQuantity })
  } else if ((type === 'subscription-purchase' && !scopesQuantity) || type === 'subscription-cycle') {
    return description
  } else if (type === 'refund') {
    return i18n.t('pages.accountSettings.billing.transactionItemRefund')
  } else if (scopesQuantity) {
    return i18n.t('pages.accountSettings.billing.transactionItemScopesPurchase', { description, scopesQuantity })
  } else {
    return description
  }
}

export const isCountrySupportScopesOrder = country => {
  return [COUNTRY_US, COUNTRY_CA].includes(country)
}

export const isHIPlan = planKey =>
  [
    'grin-subscription-hi_monthly',
    'grin-subscription-hi_annually',
    'grin-practice-premium_annually',
    'grin-practice-plus-gi_annually'
  ].includes(planKey)

export const isGIPlan = planKey => ['grin-practice-plus-gi_annually', ''].includes(planKey)
export const isGPPlan = planKey => ['grin-gp_annually', ''].includes(planKey)

export const getGrinSubscriptionPrices = country => {
  switch (country) {
    case 'BR':
      return {
        'grin-subscription_annually': 8,
        'grin-subscription_monthly': 8,
        'grin-subscription-hi_monthly': 0,
        'grin-subscription-hi_annually': 0,
        'grin-practice-core_annually': 8,
        'grin-practice-plus-gi_annually': 0,
        'grin-practice-premium_annually': 0
      }
    default:
      return {
        'grin-subscription_annually': 5,
        'grin-subscription_monthly': 5,
        'grin-subscription-hi_monthly': 9,
        'grin-subscription-hi_annually': 9,
        'grin-practice-core_annually': 6,
        'grin-practice-plus-gi_annually': 8,
        'grin-practice-premium_annually': 10
      }
  }
}
