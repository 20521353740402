import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { Arrow, List1 } from 'components/common/icons'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import usePricingUrls from 'hooks/usePricingUrls'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(() => ({
  container: {
    background: 'rgba(255, 255, 255, 0.15)',
    border: '0.5px solid rgba(255, 255, 255, 0.5)',
    padding: 24,
    marginBottom: 20
  },
  listIcon: {
    marginRight: '0 !important'
  },
  buttonLabel: {
    color: 'var(--text-color-1)'
  },
  icons: {
    marginTop: 2
  },
  text: {
    marginBottom: 20
  }
}))

const CreditCardDisclaimer = ({ mixpanelPayload, mixpanelPrefix }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const grinPricingUrl = usePricingUrls()

  const label = useMemo(
    () => (
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <List1 className={`${classes.listIcon} ${classes.icons}`} />
        </Grid>
        <Grid item>
          <DazzedParagraph12 className={classes.buttonLabel}>
            {t('pages.billingInfo.disclaimer.button')}
          </DazzedParagraph12>
        </Grid>
        <Grid item>
          <Arrow className={classes.icons} />
        </Grid>
      </Grid>
    ),
    [classes.buttonLabel, classes.icons, classes.listIcon, t]
  )

  const handleClick = useCallback(() => {
    trackEvent(`${mixpanelPrefix} - see pricing plan clicked`, mixpanelPayload)
    window.open(grinPricingUrl, '_blank')
  }, [grinPricingUrl, mixpanelPayload, mixpanelPrefix])

  return (
    <div className={classes.container}>
      <DazzedParagraph14 className={classes.text} color="var(--text-color-1)">
        {t('pages.billingInfo.disclaimer.text')}
      </DazzedParagraph14>
      <PrimaryButton
        label={label}
        onClick={handleClick}
        id={`${mixpanelPrefix}-billing-see-pricing-btn`}
        color="rgba(255, 255, 255, 0.15)"
        height="37px"
        fontSize="12px"
      />
    </div>
  )
}

export default CreditCardDisclaimer
