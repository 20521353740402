import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { API } from 'aws-amplify'
import { Grid } from '@material-ui/core'
import DazzedHeading36 from '../common/text/DazzedHeading36'
import FormInput from '../common/FormInput'
import DazzedHeading20 from '../common/text/DazzedHeading20'
import PrimaryButton from '../common/buttons/PrimaryButton'
import DazzedParagraph14 from '../common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1600,
    width: '80%',
    padding: 60,
    margin: '0 auto'
  },
  title: {
    marginBottom: 30
  },
  input: {
    maxWidth: 400
  },
  error: {
    color: 'red'
  },
  iframe: {
    width: '100%',
    height: '1000px',
    border: '0px'
  }
}))

const WhiteningDashboard = () => {
  const classes = useStyles()

  const [dashboardUrl, setDashboardUrl] = useState(null)
  const [dashboardError, setDashboardError] = useState(null)
  const [password, setPassword] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const loadDashboard = useCallback(() => {
    setIsLoading(true)
    API.get('grinServerlessApi', `/whitening/v1/dashboard?password=${password}`)
      .then(res => {
        setIsAuthenticated(true)
        setDashboardUrl(res.url)
      })
      .catch(err => {
        console.log('err', err.response?.data)
        setDashboardError(err.response?.data?.message || 'Unknown error')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [password])

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item xs={12} className={classes.title}>
        <DazzedHeading36>Whitening Dashboard</DazzedHeading36>
      </Grid>
      {!isAuthenticated ? (
        <Grid item xs={12}>
          <DazzedHeading20>Enter password</DazzedHeading20>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <FormInput
                value={password}
                setValue={setPassword}
                type="password"
                style={{ bright: true, thick: true }}
              />
            </Grid>
            <Grid item>
              <PrimaryButton label={'Go'} isLoading={isLoading} onClick={loadDashboard} small />
            </Grid>
          </Grid>
          {dashboardError && <DazzedParagraph14 className={classes.error}>{dashboardError}</DazzedParagraph14>}
        </Grid>
      ) : (
        <Grid item xs={12}>
          <iframe src={dashboardUrl} title="Dashboard" className={classes.iframe} allowtransparency />
        </Grid>
      )}
    </Grid>
  )
}

export default WhiteningDashboard
