import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import TriggerToActionArrow from 'components/common/icons/TriggerToActionArrow'
import GrinSwitch from 'components/common/GrinSwitch'
import useAutomationRules from 'hooks/useAutomationRules'
import RuleActionsMenu from './RuleActionsMenu'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  card: {
    width: 434,
    height: 132,
    padding: 20,
    backgroundColor: 'var(--bg-color-24)',
    borderRadius: 10,
    '@media only screen and (max-width: 1500px)': {
      width: 300
    }
  },
  divider: {
    borderBottom: '1px solid var(--bg-color-51)',
    margin: '20px 0px 15px 0px'
  },
  ruleActionsMenu: {
    marginLeft: 120
  },
  ruleContent: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  name: {
    maxWidth: 350,
    wordWrap: 'break-word'
  }
}))

const AutomationRule = ({ rule, shouldDisplayMenu = true, shouldDisplayHeader = true }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const parsedActions = useMemo(() => JSON.parse(rule?.actions || '{}'), [rule])
  const parsedConditions = useMemo(() => JSON.parse(rule?.conditions || '{}'), [rule])

  const { ruleActions, ruleConditions } = useAutomationRules()

  const handleRuleToggle = useCallback(
    checked => {
      trackEvent('Automation rule - Rule toggled', {
        ruleName: rule.name,
        isEnabled: checked
      })
      dispatch(
        Actions.toggleEnableAutomationRulePopup({
          ruleId: rule.id,
          isOpen: true,
          isEnabled: checked
        })
      )
    },
    [dispatch, rule]
  )

  return (
    <Grid container direction="column" spacing={2}>
      {shouldDisplayHeader && (
        <Grid item>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <GrinSwitch checked={rule.isEnabled} onChange={({ target }) => handleRuleToggle(target.checked)} />
            </Grid>
            <Grid item>
              <DazzedParagraph16 bold className={classes.name}>
                {rule.name}
              </DazzedParagraph16>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <Grid container direction="column">
              <Grid item className={classes.card}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <DazzedParagraph14 strong>
                      {t(`pages.accountSettings.automations.triggerTypes.${rule.triggerType}`)}
                    </DazzedParagraph14>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      {parsedConditions.map(condition => (
                        <Grid item key={condition.type}>
                          <DazzedParagraph14 strong color={'var(--text-color-11)'}>
                            {ruleConditions[condition.type]?.getContent(condition.params)}
                          </DazzedParagraph14>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TriggerToActionArrow />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item className={classes.card}>
                <Grid container direction="column">
                  {parsedActions.map(action => (
                    <Grid item key={action.type}>
                      <Grid container direction="column">
                        <Grid item>
                          <Grid container spacing={1}>
                            <Grid item>{ruleActions[action.type].icon}</Grid>
                            <Grid item>
                              <DazzedParagraph14 strong>{ruleActions[action.type].title}</DazzedParagraph14>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <DazzedParagraph14 strong color={'var(--text-color-11)'} className={classes.ruleContent}>
                            {ruleActions[action.type]?.getContent(action.params)}
                          </DazzedParagraph14>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {shouldDisplayMenu && (
            <Grid item className={classes.ruleActionsMenu}>
              <RuleActionsMenu ruleId={rule.id} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <div className={classes.divider} />
    </Grid>
  )
}

export default AutomationRule
