import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton } from '@material-ui/core'
import { ThumbsDown, ThumbsUp } from '../icons'
import { ScanSummaryRatingOptions } from 'consts/scanSummaryConsts'
import DazzedParagraph14 from '../text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  rateText: {
    color: 'rgba(4, 19, 51, 1)',
    marginRight: 5
  }
}))

const ScanSummaryRating = ({ rating = '', setRating = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" justifyContent="flex-end">
      <Grid item>
        <DazzedParagraph14 className={classes.rateText}>{t('dialogs.scanSummary.rateText')}</DazzedParagraph14>
      </Grid>
      <Grid item>
        <IconButton size="small" onClick={() => setRating(ScanSummaryRatingOptions.Positive)}>
          <ThumbsUp
            isSelected={rating === ScanSummaryRatingOptions.Positive}
            width={18}
            height={18}
            unselectedColor="rgba(4, 19, 51, 1)"
          />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton size="small" onClick={() => setRating(ScanSummaryRatingOptions.Negative)}>
          <ThumbsDown
            isSelected={rating === ScanSummaryRatingOptions.Negative}
            width={18}
            height={18}
            unselectedColor="rgba(4, 19, 51, 1)"
          />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default ScanSummaryRating
