import * as React from 'react'

export default () => {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: 4 }}
    >
      <path d="M2 7L6 11L15 2" stroke="black" strokeWidth="3" />
    </svg>
  )
}
