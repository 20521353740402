import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedHeading13 from 'components/common/text/DazzedHeading13'
import ArrowDropdownDown from '../../icons/ArrowDropdownDown'
import ArrowDropdownUp from '../../icons/ArrowDropdownUp'
import { useMemo } from 'react'
import SmallDotIcon from '../../icons/SmallDot'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'utils/mobileUtils'

const STATUS_PICKER_TRIGGER_WIDTH = 170

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    transition: 'border-color 0.2s ease',
    border: '1px solid var(--border-color-12)',
    borderColor: 'var(--border-color-12)',
    paddingLeft: 5,
    paddingRight: 5,
    '&.rounded': {
      borderRadius: '100px',
      minWidth: STATUS_PICKER_TRIGGER_WIDTH
    },
    '&.rectangle': {
      border: '1px solid var(--border-color-2)',
      borderRadius: 0,
      padding: '9px',
      '&.disabled': {
        borderColor: 'var(--border-color-12)!important'
      }
    },
    '&.noBorder': {
      border: 0,
      minWidth: STATUS_PICKER_TRIGGER_WIDTH
    },
    '&.rectangle div': {
      fontSize: '15px!important'
    },
    '&.open': {
      borderColor: '#3C52EF'
    },
    '&.disabled': {
      cursor: 'default'
    },
    '&.disabled div': {
      color: 'var(--text-color-13)'
    },
    '&.rectangle.disabled': {}
  },
  statusNameContainer: {
    paddingRight: 7
  },
  statusDot: {
    paddingRight: 7
  },
  arrow: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
}))

const StatusPickerTrigger = ({ variant = 'rounded', isOpen = false, selectedStatus, className = '', disabled }) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const statusSettings = useMemo(() => JSON.parse(selectedStatus?.settings || '{}'), [selectedStatus])

  return (
    <Grid
      container
      alignItems="center"
      spacing={1}
      className={[classes.root, variant, disabled ? 'disabled' : '', className].join(' ')}
      style={{
        borderColor: !isOpen
          ? variant === 'rounded'
            ? 'var(--border-color-12)'
            : 'var(--border-color-2)'
          : 'var(--border-color-1)'
      }}
    >
      <Grid item>
        <Grid container alignItems="center" className={classes.statusNameContainer}>
          <Grid item className={classes.statusDot}>
            <SmallDotIcon color={statusSettings.color} />
          </Grid>
          <Grid item>
            <DazzedHeading13>
              {selectedStatus ? t(`statuses.names.${selectedStatus.type}`) : t(`statuses.names.unset`)}
            </DazzedHeading13>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        {!disabled && (
          <div className={classes.arrow}>
            {isOpen ? <ArrowDropdownUp isMobile={isMobile()} /> : <ArrowDropdownDown isMobile={isMobile()} />}
          </div>
        )}
      </Grid>
    </Grid>
  )
}

export default StatusPickerTrigger
