import { Chip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getFullProgramName, normalizeProgram } from '../../utils/programUtils'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '6px',
    textTransform: 'uppercase',
    fontWeight: 500
  },
  rc: {
    background: 'var(--bg-color-13)'
  },
  rm: {
    background: 'var(--bg-color-14)'
  },
  ortho: {
    background: 'var(--bg-color-26)',
    color: 'var(--text-color-1)'
  },
  serviceAcccount: {
    textAlign: 'center !important',
    background: 'var(--bg-color-14)'
  }
}))

const ProgramChip = ({ program, fullName = false, ...props }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const programNormalized = useMemo(() => normalizeProgram(program, t), [program, t])

  return (
    <Chip
      label={!fullName ? programNormalized : getFullProgramName(programNormalized, t)}
      size="small"
      classes={{
        root: [
          classes.root,
          programNormalized === 'rc' && classes.rc,
          programNormalized === 'rm' && classes.rm,
          programNormalized === 'ortho' && classes.ortho,
          programNormalized === 'serviceAccount' && classes.serviceAccount
        ].join(' ')
      }}
      deleteIcon={<CloseIcon />}
      {...props}
    />
  )
}

export default ProgramChip
