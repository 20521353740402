import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import FormInput from '../FormInput'
import GrinRadioGroup from '../GrinRadioGroup'
import { useTranslation } from 'react-i18next'
import { scanFrequencyCustomOption } from 'utils/statusUtils'
import SelectInput from 'components/common/SelectInput'
import { isMobile } from 'utils/mobileUtils'
import { DEFAULT_PERIOD_TYPE } from 'consts/dateTimeConsts'
import { Sizes } from 'consts/styleConsts'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  daysLabel: {
    marginLeft: 10
  },
  customInputWrapper: {
    width: 'auto !important',
    border: '0 !important',
    borderBottom: '1px solid var(--border-color-2) !important',
    marginRight: 15
  },
  inputSuffixClassname: {
    marginLeft: ({ cycleInterval }) => (cycleInterval >= 10 ? 5 : -5),
    fontWeight: 500,
    color: 'black',
    alignSelf: 'center'
  },
  inputClassName: {
    width: '15%!important',
    paddingRight: '0px !important'
  },
  inputWrapper: {
    fontSize: 15,
    padding: '0 0 0 10px !important'
  },
  mobileCustomSelect: {
    width: '100%'
  }
}))

const FrequencyPicker = ({
  value,
  setValue,
  periodType,
  defaultPeriodType = DEFAULT_PERIOD_TYPE,
  setPeriodType = () => {},
  className = '',
  radioGroupClassName = '',
  options = [],
  periodTypeOptions,
  disabled,
  size = Sizes.Medium
}) => {
  const classes = useStyles({
    cycleInterval: value
  })
  const { t } = useTranslation()

  const [isCustom, setIsCustom] = useState(false)

  const labelledOptions = useMemo(
    () =>
      options
        .map(option => ({
          label: `${option} ${t(`periodTypes.${defaultPeriodType}`)}`,
          value: option
        }))
        .concat(scanFrequencyCustomOption),
    [options, defaultPeriodType, t]
  )

  const isCustomPeriodValue = useMemo(
    () => periodType !== defaultPeriodType || !options.includes(Number(value)),
    [value, options, periodType, defaultPeriodType]
  )

  const handleRadioSelection = useCallback(
    selection => {
      if (selection === 'custom') {
        setIsCustom(true)
      } else {
        setIsCustom(false)
        setValue(Number(selection))
      }
      setPeriodType(defaultPeriodType)
    },
    [defaultPeriodType, setPeriodType, setValue]
  )

  const setCustomValue = useCallback(
    value => {
      const num = Number(value)
      if (!value || num <= 0) {
        setValue(1)
        return
      }
      if (isNaN(num) || !Number.isInteger(num) || num > 90) {
        return
      }

      setValue(+value)
    },
    [setValue]
  )

  const handleChangePeriodType = useCallback(
    ({ value }) => {
      setPeriodType(value)
    },
    [setPeriodType]
  )

  useEffect(() => {
    setIsCustom(isCustomPeriodValue)
  }, [isCustomPeriodValue, options])

  const customPeriodTypeDropdown = (
    <Grid container alignItems="center" style={{ width: 'auto' }}>
      <Grid item>
        <FormInput
          type="number"
          value={value}
          setValue={setCustomValue}
          style={{ bright: true, thick: false }}
          inputStyle={{ width: 38, textAlign: 'center' }}
          inputWrapperClassName={classes.customInputWrapper}
          keepErrorContainerWhenInactive={false}
          isDisabled={disabled}
          notes
        />
      </Grid>
      <Grid item>
        {periodTypeOptions.length === 1 ? (
          periodTypeOptions[0].label
        ) : (
          <SelectInput
            options={periodTypeOptions}
            value={periodType}
            onChange={handleChangePeriodType}
            style={{ bright: true, thick: false }}
            keepErrorContainerWhenInactive={false}
            disabled={disabled}
          />
        )}
      </Grid>
    </Grid>
  )

  return (
    <Grid container spacing={0} className={className}>
      <Grid item xs={12}>
        {isMobile() ? (
          <div>
            <SelectInput
              options={labelledOptions}
              value={isCustom ? 'custom' : value ?? ''}
              onChange={({ value }) => handleRadioSelection(value)}
              style={{ bright: true, thick: true }}
              selectStyle={{ fontWeight: 500, paddingLeft: 0 }}
              keepErrorContainerWhenInactive={false}
              disabled={disabled}
            />
            {isCustom && (
              <Grid container>
                <FormInput
                  type="number"
                  value={value}
                  setValue={setCustomValue}
                  style={{ bright: true, thick: false }}
                  inputStyle={{ paddingLeft: 5 }}
                  keepErrorContainerWhenInactive={false}
                  isDisabled={disabled}
                  withCounter
                  inputClassName={classes.inputClassName}
                  inputSuffix={periodTypeOptions.length === 1 ? t('dialogs.scanFrequency.daysLowercase') : null}
                  inputSuffixClassname={classes.inputSuffixClassname}
                  maxLength={2}
                  validationRules={{ required: true, pattern: '^[0-9]+$' }}
                  inputWrapperClassName={classes.inputWrapper}
                  disabledDecrement={+value <= 1}
                  disabledIncrement={+value >= 90}
                />
                {periodTypeOptions.length > 1 && (
                  <SelectInput
                    options={periodTypeOptions}
                    value={periodType}
                    onChange={handleChangePeriodType}
                    style={{ bright: true, thick: false }}
                    keepErrorContainerWhenInactive={false}
                    disabled={disabled}
                  />
                )}
              </Grid>
            )}
          </div>
        ) : (
          <GrinRadioGroup
            size={size}
            customComponent={customPeriodTypeDropdown}
            value={isCustom ? 'custom' : value ?? ''}
            setValue={handleRadioSelection}
            options={labelledOptions}
            radioGroupClassName={radioGroupClassName}
            isDisabled={disabled}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default FrequencyPicker
