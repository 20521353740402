import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { InputBase } from '@material-ui/core'
import useAutochart from '../AutochartGrid/useAutochart'
import { useSelector } from 'react-redux'
import { AsyncStatus } from 'consts'

const useStyles = makeStyles(theme => ({
  input: {
    border: '1px solid var(--border-color-7)',
    borderRadius: 8,
    padding: '16px',
    fontSize: '14px',
    fontFamily: 'Dazzed',
    fontWeight: 500,
    width: '100%'
  }
}))

const AutochartGlobalNotes = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { autochart, setGlobalNotes } = useAutochart()
  const { status } = useSelector(state => state.autochartReducer)

  const [value, setValue] = useState('')

  const isDisabled = useMemo(() => status !== AsyncStatus.Completed, [status])

  const handleBlur = useCallback(() => {
    setGlobalNotes(value)
  }, [setGlobalNotes, value])

  useEffect(() => {
    setValue(autochart?.notes)
  }, [autochart?.notes])

  return (
    <InputBase
      className={classes.input}
      multiline
      placeholder={t('dialogs.autochart.table.additionalNotesPlaceholder')}
      rows={5}
      disabled={isDisabled}
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={handleBlur}
    />
  )
}

export default AutochartGlobalNotes
