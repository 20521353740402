import { IMAGE_MODES, SCAN_SUMMARY_SECTIONS } from 'consts/scanSummaryConsts'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'utils/mobileUtils'
import PreventivePanel from '../Preventive/PreventivePanel'
import TreatmentTrackingPanel from '../TreatmentTracking/TreatmentTrackingPanel'
import { trackEvent } from 'utils/analyticsUtils'
import useRolePermissions from 'hooks/useRolePermissions'

export default ({ items }) => {
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()
  const featureFlags = useFeatureFlags()

  const [currentSectionId, setCurrentSectionId] = useState(
    localStorage.getItem('scanSummarySection') || SCAN_SUMMARY_SECTIONS.PREVENTIVE
  )

  const sections = useMemo(
    () =>
      [
        {
          id: SCAN_SUMMARY_SECTIONS.PREVENTIVE,
          label: t('dialogs.scanSummary.preventive.sectionTitle'),
          component: PreventivePanel,
          condition: !isMobile() && featureFlags.scanSummaryPreventive && items[IMAGE_MODES.preventive]
        },
        {
          id: SCAN_SUMMARY_SECTIONS.TREATMENT_TRACKING,
          label: t('dialogs.scanSummary.treatmentTracking.sectionTitle'),
          component: TreatmentTrackingPanel,
          condition: !isMobile() && permissions.treatmentTracking
        }
      ].filter(section => section.condition),
    [featureFlags, items, t, permissions]
  )

  const handleSectionSelected = useCallback(
    id => {
      if (currentSectionId === id) {
        setCurrentSectionId(null)
      } else {
        localStorage.setItem('scanSummarySection', id)
        setCurrentSectionId(id)
        trackEvent('Scan Summary - panel selected', { panel: id })
      }
    },
    [currentSectionId]
  )

  return {
    sections,
    currentSectionId,
    handleSectionSelected
  }
}
