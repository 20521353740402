import * as React from 'react'

const OralHygiene = props => (
  <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0714 17.4401L15.0675 17.4515L15.0641 17.463L12.9547 24.6243C12.7093 25.4575 11.529 25.4569 11.2844 24.6236L9.9724 20.1541C9.68732 19.183 8.31169 19.183 8.02662 20.1541L6.71462 24.6236C6.46999 25.4569 5.28972 25.4575 5.04432 24.6243L2.93495 17.463L2.93154 17.4515L2.92757 17.4401L1.94888 14.6263C1.54514 13.4656 1.75036 12.1758 2.49288 11.1952C3.8534 9.39831 6.50125 9.23695 8.06208 10.8586C8.57391 11.3904 9.4251 11.3904 9.93694 10.8586L9.57669 10.5119L9.93694 10.8586C11.4978 9.23695 14.1456 9.39831 15.5061 11.1952C16.2487 12.1758 16.4539 13.4656 16.0501 14.6263L15.0714 17.4401Z"
      stroke="#001335"
    />
    <path
      d="M16.6562 2.41886L16.1819 3.84189L15.5583 5.71282C15.1104 7.05654 14.0559 8.11096 12.7122 8.55887L10.8413 9.18251L9.41826 9.65685L10.8413 10.1312L12.7122 10.7548C14.0559 11.2027 15.1104 12.2572 15.5583 13.6009L16.1819 15.4718L16.6562 16.8948L17.1306 15.4718L17.7542 13.6009C18.2021 12.2572 19.2566 11.2027 20.6003 10.7548L22.4712 10.1312L23.8942 9.65685L22.4712 9.18251L20.6003 8.55887C19.2566 8.11096 18.2021 7.05654 17.7542 5.71282L17.1306 3.84189L16.6562 2.41886Z"
      fill="#001335"
      stroke="#E3F0FF"
    />
  </svg>
)

export default OralHygiene
