import React from 'react'
import { Grid } from '@material-ui/core'
import { NotifyPractice as NotifyPracticeIcon } from 'components/common/icons'
import { useTranslation } from 'react-i18next'

const NotifyPractice = () => {
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={2}>
        <NotifyPracticeIcon />
      </Grid>
      <Grid item xs={10}>
        {t('dialogs.hiPatientGuidelinesViewer.actions.notifyPractice')}
      </Grid>
    </Grid>
  )
}

export default NotifyPractice
