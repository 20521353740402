import React from 'react'
import { makeStyles, TableCell } from '@material-ui/core'
import AccountIcon from 'components/common/icons/Account'
import CachedImage from 'components/common/CachedImage'

const useStyles = ({ highlighted, rowIndex }) =>
  makeStyles({
    root: {
      height: '78px',
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Dazzed',
      color: 'var(--text-color-6)',
      borderTop: rowIndex === 0 && highlighted ? '1px solid' : 0,
      borderLeft: highlighted ? '5px solid var(--border-color-1)' : '1px solid var(--border-color-11)',
      borderColor: highlighted ? 'var(--border-color-1)' : 'var(--border-color-11)'
    },
    content: {
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    avatarContainer: {
      width: '40px',
      height: '40px',
      minWidth: '40px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'var(--bg-color-23)',
      boxSizing: 'border-box',
      overflow: 'hidden'
    },
    avatarPlaceholder: {
      width: '25px',
      height: '25px'
    },
    avatar: {
      width: '40px',
      height: '40px',
      borderRadius: '50%'
    },
    label: {
      cursor: 'pointer',
      marginLeft: 13,
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  })({ highlighted, rowIndex })

const PatientDetailsCell = ({ rowIndex, actionRequired = false, name, photo, onClick = () => null }) => {
  const classes = useStyles({ highlighted: actionRequired, rowIndex })
  return (
    <TableCell align="left" classes={{ root: classes.root }}>
      <div className={classes.content}>
        <div className={classes.avatarContainer}>
          {photo ? (
            <CachedImage s3Object={photo} alt={name} applyClassName={classes.avatar} />
          ) : (
            <AccountIcon className={classes.avatarPlaceholder} fill="#A6AAB3" />
          )}
        </div>
        <div className={classes.label} onClick={onClick}>
          {name}
        </div>
      </div>
    </TableCell>
  )
}

export default React.memo(PatientDetailsCell)
