import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PatientBriefFloatingPopup from '../../PatientBriefFloatingPopup'
import TeethSelectionGrid from 'components/common/inputs/TeethSelectionGrid/TeethSelectionGrid'
import GenericToothGridItem from 'components/common/inputs/TeethSelectionGrid/GenericToothGridItem'
import { trackEvent } from 'utils/analyticsUtils'

const TeethAttachmentsSelectionPopup = ({ isOpen, setIsOpen, anchorEl, selections, setSelections }) => {
  const { t } = useTranslation()

  const [draftSelections, setDraftSelections] = useState([])

  const handleConfirm = useCallback(() => {
    trackEvent('Patient brief - attachments saved', { teeth: draftSelections })
    setSelections(draftSelections)
    setIsOpen(false)
  }, [setSelections, setIsOpen, draftSelections])

  useEffect(() => {
    if (!isOpen) {
      setDraftSelections(selections)
    }
  }, [isOpen, selections])

  return (
    <PatientBriefFloatingPopup
      isOpen={isOpen}
      anchorEl={anchorEl}
      title={t('dialogs.patientBrief.setAttachmentsPopup.title')}
      onCancel={() => setIsOpen(false)}
      onConfirm={handleConfirm}
      description={t('dialogs.patientBrief.setAttachmentsPopup.description')}
    >
      <TeethSelectionGrid
        editable
        selections={draftSelections}
        setSelections={setDraftSelections}
        toothGridItemComponent={GenericToothGridItem}
      />
    </PatientBriefFloatingPopup>
  )
}

export default TeethAttachmentsSelectionPopup
