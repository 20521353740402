import moment from 'moment'

const parseSubmissionType = ({
  shippingParamProvided,
  approvedParamProvided,
  withShipping,
  approved: isAutoApproval
}) => {
  let shipping = '-'
  let approval = '-'
  if (!shippingParamProvided) {
    shipping = `${shipping} Missing shipping submission data`
  } else {
    shipping = `${shipping} ${withShipping ? 'With Shipping' : 'No Shipping'}`
  }

  if (!approvedParamProvided) {
    approval = `${approval} Missing approval submission data`
  } else {
    approval = `${approval} ${isAutoApproval ? 'Auto Approval' : 'Manual Approval'}`
  }

  return `${shipping};${approval}`
}

export const mapToRCLeadsDashboard = leads => {
  return leads.filter(lead => lead.conversionStatus !== 'denied').map(mapToRcLead)
}

const mapLeadStatus = (lead, rcData) => {
  if (lead.conversionStatus === 'pending') {
    return 'newLead'
  } else if (
    !rcData.withShipping ||
    !rcData?.shippingDetails?.status ||
    rcData.shippingDetails.status === 'delivered' ||
    rcData.shippingDetails.status === 'notRequired'
  ) {
    if (!lead.patient || lead.patient?.grinScans?.items?.length === 0) {
      return 'readyToScan'
    } else {
      return 'scanSent'
    }
  } else if (
    rcData.shippingDetails?.status === 'shipmentRequired' ||
    rcData.shippingDetails?.status === 'processing' ||
    rcData.shippingDetails?.status === 'shipped' ||
    rcData.shippingDetails?.status === 'onHold'
  ) {
    return 'waitingForScope'
  }

  return 'newLead'
}

const getLastUpdatedDate = ({ lead, rcData, audit }) => {
  const firstScanCreatedAt = lead?.patient?.grinScans?.items[0]?.createdAt
  const lastShippingUpdateDate = rcData?.shippingDetails?.lastStatusUpdate
  const lastAuditItem = audit.sort((currAudit, nextAudit) =>
    moment.max(moment(currAudit.time), moment(currAudit.time))
  )[0]
  const leadCreatedAt = lead.updatedAt

  const dates = [firstScanCreatedAt, lastShippingUpdateDate, leadCreatedAt, lastAuditItem?.time]
    .filter(date => !!date)
    .map(date => moment(date))

  return moment.max(dates).toISOString()
}

export const mapToRcLead = lead => {
  const rcData = JSON.parse(lead.rcData || '{}')
  const audit = JSON.parse(lead?.audit || '[]')
  const leadStatus = mapLeadStatus(lead, rcData)
  const leadEmail = lead?.patient?.details?.email || lead.email

  return {
    leadId: lead.id,
    leadObj: {
      id: lead.id,
      _version: lead._version,
      conversionStatus: lead.conversionStatus,
      leadName: `${lead.firstName} ${lead.lastName}`,
      leadNameAndEmail: `${lead.firstName} ${lead.lastName} ${leadEmail}`,
      email: leadEmail,
      submissionType: parseSubmissionType(rcData),
      shippingStatus: rcData.shippingDetails?.status,
      status: leadStatus,
      patient: lead?.patient,
      address: `${rcData.address}, ${rcData.city}, ${rcData.zipcode}`,
      address2: rcData.address2 || '-',
      additionalInfo: rcData.additionalInfo || '-',
      createdAt: moment(lead.createdAt).format('ll'),
      doctorName: lead.doctor.name,
      practiceName: lead.doctor.clinic.practiceName,
      rcData,
      grinPlanKey: lead.doctor?.user?.grinPlanKey,
      statusType: lead.statusType
    },
    age: rcData.ageRange,
    leadName: `${lead.firstName} ${lead.lastName}`,
    email: leadEmail,
    address: `${rcData.address}, ${rcData.city}, ${rcData.zipcode}`,
    address2: rcData.address2 || '-',
    country: rcData.country,
    state: rcData?.state || '-',
    shippingStatus: rcData.shippingDetails?.status,
    additionalInfo: rcData.additionalInfo || '-',
    doctorName: lead.doctor.name,
    practiceName: lead.doctor.clinic.practiceName,
    phone: lead.phone,
    conversionStatus: lead.conversionStatus,
    createdAt: lead.createdAt,
    status: leadStatus,
    audit,
    lastUpdateDate: getLastUpdatedDate({ lead, rcData, audit }),
    leadApprovalDate: rcData.marketingData?.approvalDate || rcData.approvalDate,
    grinPlanKey: lead.doctor?.user?.grinPlanKey,
    statusType: lead.statusType
  }
}
