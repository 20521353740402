import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FromToArrow } from 'components/common/icons'
import { decodeStatusKey } from '@grin-rnd/grin-api-sdk/dist/Enums/Statuses'
import ScanDateLabel from '../../ScanDateLabel'
import PatientStatus from 'components/Patients/PatientStatus'
import Divider from './Divider'

const useStyles = makeStyles(() => ({
  root: {
    padding: '8px 8px 0px 8px'
  },
  status: {
    color: 'black'
  },
  statusChangedContainer: {
    padding: '22px 0px 22px 0px'
  },
  dateLabel: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column-reverse'
  }
}))

const StatusUpdatedTimelineItem = ({ timelineItem }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { data: statusTypes } = useSelector(state => state.treatmentReducer.statuses)
  const patientProgram = useMemo(() => decodeStatusKey(timelineItem?.payload?.statusKey)?.program, [timelineItem])
  const oldStatus = useMemo(
    () => statusTypes?.[patientProgram]?.find(status => status.key === timelineItem?.payload?.oldStatusKey),
    [patientProgram, statusTypes, timelineItem]
  )
  const status = useMemo(
    () => statusTypes?.[patientProgram]?.find(status => status.key === timelineItem?.payload?.statusKey),
    [patientProgram, statusTypes, timelineItem]
  )

  return (
    <div>
      <Grid container justifyContent="space-between" className={classes.root}>
        <Grid item className={classes.statusChangedContainer}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <DazzedParagraph14>
                {t('pages.patients.selectedPatient.timelineV2.mainFeed.statusTypeChangedItem.description')}
              </DazzedParagraph14>
            </Grid>
            <Grid item>
              <PatientStatus status={oldStatus} textClassName={classes.status} size="large" />
            </Grid>
            <Grid item>
              <FromToArrow style={{ marginTop: 10 }} />
            </Grid>
            <Grid item>
              <PatientStatus status={status} textClassName={classes.status} size="large" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div className={classes.dateLabel}>
            <ScanDateLabel timestamp={timelineItem.eventDate} color="var(--text-color-11)" />
          </div>
        </Grid>
      </Grid>
      <Divider />
    </div>
  )
}

export default StatusUpdatedTimelineItem
