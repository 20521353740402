import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DropzoneFilesInput from 'components/common/inputs/DropzoneFilesInput'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Grid } from '@material-ui/core'
import FileLarge from 'components/common/icons/bulk-invite/file-large'
import FileSmall from 'components/common/icons/bulk-invite/file-small'
import { useCallback } from 'react'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  selectedFile: {
    background: 'var(--bg-color-14)',
    height: '153px',
    borderRadius: 10
  },
  dropzone: {
    border: '1px dashed var(--border-color-28)',
    borderRadius: 10,
    height: '153px'
  },
  link: {
    color: 'var(--text-color-3)',
    textDecoration: 'underline'
  }
}))

const UploadFormFileInput = ({ onFileUpload = content => {}, selectedFileName }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleFileSelected = useCallback(
    files => {
      trackEvent('Bulk invite - upload form popup - file selected')

      const file = files[0]
      if (!file) {
        return
      }

      const content = new TextDecoder().decode(file.data)
      onFileUpload({ content, fileName: file.key })
    },
    [onFileUpload]
  )

  return selectedFileName ? (
    <Grid container className={classes.selectedFile} alignItems="center" justifyContent="center" spacing={1}>
      <Grid item>
        <FileSmall />
      </Grid>
      <Grid item>
        <DazzedParagraph14 bold>{selectedFileName}</DazzedParagraph14>
      </Grid>
    </Grid>
  ) : (
    <DropzoneFilesInput
      files={[]}
      onChange={handleFileSelected}
      className={classes.dropzone}
      acceptedFormats={['text/csv']}
      filenameGenerator={(extension, fileName) => fileName}
      multipleFiles={false}
      maxFilesNumber={1}
      emptyStateComponent={
        <Grid container alignItems="center" justifyContent="center" direction="column" spacing={1}>
          <Grid item>
            <FileLarge />
          </Grid>
          <Grid item xs={7}>
            <DazzedParagraph14 strong textAlign="center">
              {t('pages.accountSettings.advancedActions.bulkInvite.uploadDialog.dropzoneText_1')}
              <span className={classes.link}>
                {t('pages.accountSettings.advancedActions.bulkInvite.uploadDialog.dropzoneText_2')}
              </span>
            </DazzedParagraph14>
          </Grid>
        </Grid>
      }
    />
  )
}

export default UploadFormFileInput
