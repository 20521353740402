import * as React from 'react'

const SendSelfiesReminder = ({ disabled = false }) => {
  return (
    <svg width="35" height="40" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="20" height="20" rx="2" stroke={disabled ? '#A7A9BA' : '#4151E6'} />
      <circle cx="11" cy="7.5" r="3.5" stroke={disabled ? '#A7A9BA' : '#4151E6'} />
      <path d="M4 14C4 14 6.5 12 11 12C15.5 12 18 14 18 14V18H4V14Z" stroke={disabled ? '#A7A9BA' : '#4151E6'} />
      <path
        d="M14 14.5C13.1716 14.5 12.5 15.1716 12.5 16V28C12.5 28.6067 12.8655 29.1536 13.426 29.3858C13.9865 29.618 14.6317 29.4897 15.0607 29.0607L17.6213 26.5H27C27.8284 26.5 28.5 25.8284 28.5 25V16C28.5 15.1716 27.8284 14.5 27 14.5H14Z"
        fill="white"
        stroke="white"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M14 16H27V25H17L14 28V16Z"
        fill="white"
        stroke={disabled ? '#A7A9BA' : '#4151E6'}
        strokeLinejoin="round"
      />
      <path d="M17 19H24" stroke={disabled ? '#A7A9BA' : '#4151E6'} strokeLinecap="round" />
      <path d="M17 22H24" stroke={disabled ? '#A7A9BA' : '#4151E6'} strokeLinecap="round" />
    </svg>
  )
}

export default SendSelfiesReminder
