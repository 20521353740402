import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress, Grid, IconButton, makeStyles } from '@material-ui/core'
import CloseIcon from 'components/common/icons/Close'
import GrinMenu from 'components/common/menu/GrinMenu'
import GrinMenuItem from 'components/common/menu/GrinMenuItem'
import { useDispatch, useSelector } from 'react-redux'
import actions from 'actions'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import SearchSiblingResultItem from './SearchSiblingResultItem'
import { useDebouncedCallback } from 'use-debounce/lib'

const useStyles = makeStyles({
  input: {
    border: 0,
    flex: 1,
    borderBottom: '1px solid var(--border-color-8)',
    fontSize: '13px',
    fontFamily: 'Dazzed',
    background: 'transparent',
    outline: 'none',
    padding: '8px 0',
    width: '350px'
  },
  searchItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8,
    marginBottom: 8
  },
  error: {
    color: 'var(--text-color-17)'
  },
  menu: {
    marginTop: 15,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    width: '380px',
    maxHeight: '300px',
    '&::-webkit-scrollbar': {
      width: 8,
      height: 160
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 100,
      height: 160
    }
  },
  patientName: {
    fontWeight: 'bold'
  },
  indicator: {
    width: '24px'
  }
})

const SearchSibling = ({ onSelectSibling }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')

  const { isLoading = false, results = [], error } = useSelector(state => state.patientsReducer.siblingSearch)
  const searchInputRef = useRef()

  const onFocus = useCallback(() => {
    searchInputRef.current.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputRef.current])

  const searchSiblingPatients = useDebouncedCallback(value => dispatch(actions.searchSiblingPatients(value)), 250)

  const renderEmptyResults = useCallback(
    () => (
      <GrinMenuItem className={classes.searchItem}>
        <DazzedParagraph14 className={error && classes.error}>
          {error ?? t('dialogs.invitePatient.linkToSiblingRow.emptyResults')}
        </DazzedParagraph14>
      </GrinMenuItem>
    ),
    [classes, error, t]
  )

  useEffect(() => {
    searchSiblingPatients(searchTerm)
  }, [searchTerm, searchSiblingPatients])

  return (
    <Grid container justifyContent="space-around">
      <Grid item id="invite-patient-search-for-account">
        <GrinMenu
          keepMounted={false}
          onFocus={onFocus}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          disableMenuFocus
          triggerComponent={
            <input
              className={classes.input}
              placeholder={t('dialogs.invitePatient.linkToSiblingRow.searchPlaceholder')}
              value={searchTerm}
              ref={searchInputRef}
              onChange={({ target }) => setSearchTerm(target.value)}
            />
          }
          className={classes.menu}
          open={searchTerm !== ''}
          isLoading={isLoading}
          customAnchorEl={!isLoading && searchInputRef.current}
        >
          <div onFocus={onFocus}>
            {results.length === 0 && renderEmptyResults()}
            {results.map((item, i) => (
              <React.Fragment key={i}>
                <SearchSiblingResultItem onSelectSibling={onSelectSibling} item={item} searchTerm={searchTerm} />
              </React.Fragment>
            ))}
          </div>
        </GrinMenu>
      </Grid>
      <Grid item>
        <div className={classes.indicator}>
          {isLoading ? (
            <IconButton disabled size="small">
              <CircularProgress size={24} />
            </IconButton>
          ) : !!searchTerm ? (
            <IconButton size="small" onClick={() => setSearchTerm('')}>
              <CloseIcon color={'var(--bg-color-45)'} />
            </IconButton>
          ) : (
            <></>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

export default SearchSibling
