import * as React from 'react'

const QuestionMarkIcon = props => (
  <svg width={13} height={13} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.555 9.704c.147 0 .27-.05.371-.151.101-.101.152-.225.152-.372 0-.146-.05-.27-.152-.37a.505.505 0 0 0-.37-.152c-.148 0-.271.05-.372.151-.101.1-.151.225-.151.371 0 .147.05.27.15.372.102.1.225.15.372.15Zm-.481-2.008h.811c0-.238.03-.456.09-.653.059-.197.245-.424.556-.68.285-.239.486-.473.605-.702.12-.229.18-.48.18-.756 0-.486-.159-.875-.475-1.169-.317-.293-.736-.44-1.258-.44-.45 0-.846.113-1.19.337a1.924 1.924 0 0 0-.75.928l.73.275c.1-.256.252-.456.453-.598a1.21 1.21 0 0 1 .715-.213c.312 0 .564.085.757.254a.83.83 0 0 1 .288.654c0 .201-.06.391-.178.57a2.74 2.74 0 0 1-.523.557 2.797 2.797 0 0 0-.612.708c-.133.234-.2.543-.2.928ZM6.5 12a5.321 5.321 0 0 1-2.131-.433 5.578 5.578 0 0 1-1.753-1.183c-.5-.5-.894-1.083-1.183-1.753A5.322 5.322 0 0 1 1 6.501c0-.762.144-1.477.433-2.146A5.497 5.497 0 0 1 2.616 2.61a5.638 5.638 0 0 1 1.753-1.176A5.322 5.322 0 0 1 6.5 1c.76 0 1.476.144 2.145.433.67.289 1.251.68 1.746 1.176a5.553 5.553 0 0 1 1.176 1.746c.289.67.433 1.384.433 2.145 0 .752-.144 1.462-.433 2.131a5.637 5.637 0 0 1-1.176 1.753c-.495.5-1.077.894-1.746 1.183A5.355 5.355 0 0 1 6.5 12Zm0-.825c1.302 0 2.406-.456 3.314-1.368.907-.912 1.361-2.014 1.361-3.307 0-1.302-.454-2.406-1.361-3.314C8.906 2.28 7.802 1.825 6.5 1.825c-1.292 0-2.395.454-3.307 1.361-.912.908-1.368 2.012-1.368 3.314 0 1.293.456 2.395 1.368 3.307.912.912 2.015 1.368 3.307 1.368Z"
      fill="#626793"
      stroke="#626793"
      strokeWidth={0.25}
    />
  </svg>
)

export default QuestionMarkIcon
