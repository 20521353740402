import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'
import { Grid } from '@material-ui/core'
import { rangeArray } from 'utils/arrayUtils'

const useStyles = makeStyles(theme => ({
  timelineSkeletonContainer: {
    padding: '80px',
    borderRight: '1px solid var(--border-color-7)'
  },
  scanSkeletonContainer: {
    width: 350,
    marginBottom: 60
  },
  rounded: {
    borderRadius: 5
  },
  chatSkeletonContainer: {
    padding: '30px',
    height: '100%'
  },
  messageSkeleton: {
    margin: 10,
    borderRadius: 16
  },
  messageInputSkeleton: {
    borderRadius: 16
  }
}))

const PatientSkeleton = () => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item xs={6} className={classes.timelineSkeletonContainer}>
        <Grid container direction="column">
          {rangeArray(3).map(i => (
            <Grid item key={`scan-skeleton-${i}`} className={classes.scanSkeletonContainer}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Skeleton variant="rect" width={'100%'} height={195} className={classes.rounded} />
                </Grid>
                <Grid item>
                  <Grid container justifyContent="space-between">
                    {rangeArray(5).map(i => (
                      <Grid item key={`scan-summary-skeleton-${i}`}>
                        <Skeleton variant="rect" width={65} height={40} className={classes.rounded} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={6} className={classes.chatSkeletonContainer}>
        <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
          <Grid item>
            <Grid container direction="column">
              {rangeArray(5).map(i => {
                const isLeft = i % 2 === 0
                return (
                  <Grid item key={`skeleton-message-${i}`} style={{ alignSelf: isLeft ? 'flex-end' : 'flex-start' }}>
                    <Grid container alignItems="flex-end" direction={isLeft ? 'row-reverse' : 'row'}>
                      <Grid item>
                        <Skeleton variant="circle" width={40} height={40} />
                      </Grid>
                      <Grid item>
                        <Skeleton
                          variant="rect"
                          width={350}
                          height={110}
                          className={[classes.messageSkeleton].join(' ')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PatientSkeleton
