import { and, buildPatientsFilter } from 'utils/searchUtils'
import { ActionItemTypes } from 'consts/appConsts'

export const mapToRequestPatientsForTaskManager = ([action, state]) => {
  const doctorId = state.practiceReducer.accountOwner.id
  const from = action.payload?.showLoader ? state.taskManagerReducer.items.length : 0
  const filters = state.taskManagerReducer.filters
  const newSearch = action.payload?.newSearch
  const showLoader = action.payload?.showLoader

  return {
    filter: {
      doctorId: {
        eq: doctorId
      },
      lastActionItemType: {
        exists: true
      },
      lastActionItemStatus: {
        eq: 'open'
      },
      ...and([
        ...Object.entries(filters.tags).map(([key, value]) =>
          buildPatientsFilter(
            {
              filterData: { [key]: value },
              operator: 'eq'
            },
            'or'
          )
        ),
        buildPatientsFilter(
          {
            filterData: { lastActionItemType: filters.lastActionItemType },
            operator: 'eq'
          },
          'or'
        ),
        filters.name
          ? buildPatientsFilter({
              filterData: { name: [filters.name] },
              operator: 'matchPhrasePrefix'
            })
          : null
      ])
    },
    sort: {
      field: filters.sortBy || 'lastInteractionTimestamp',
      direction: filters.sortDirection || 'desc'
    },
    from,
    showLoader,
    newSearch
  }
}

export const mapToTaskCategories = items => {
  const relevantFields = [
    ActionItemTypes.REVIEW_FIRST_SCAN,
    ActionItemTypes.UPLOAD_PATIENT_SCANS,
    ActionItemTypes.SCHEDULE_VISIT,
    ActionItemTypes.TREATMENT_PROPOSAL_REQUIRED,
    ActionItemTypes.TREATMENT_PLAN_REQUIRED,
    ActionItemTypes.SET_SCAN_SETTINGS,
    ActionItemTypes.UPDATE_APPLIANCE_ARRIVAL
  ]
  const requiredActions = items.map(item => item.lastActionItemType).filter(item => relevantFields.includes(item))
  const taskCategories = requiredActions.reduce((acc, curr) => {
    acc[curr] = (acc[curr] || 0) + 1
    return acc
  }, {})
  taskCategories.total = requiredActions.length
  return taskCategories
}
