import { useMemo } from 'react'
import useAutomationRules from '../../../../../hooks/useAutomationRules'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

const noErrors = { conditions: {}, actions: {}, requiredFields: {} }

export default ({ name, triggerType, actionType, actions, conditions }) => {
  const { t } = useTranslation()
  const { ruleActions, ruleConditions } = useAutomationRules()

  const validateRule = useMemo(() => {
    let currErrors = noErrors

    if (!name) {
      currErrors = {
        ...currErrors,
        requiredFields: {
          ...currErrors.requiredFields,
          name: t('pages.accountSettings.automations.editorModal.errors.nameRequired')
        }
      }
    }

    if (!triggerType) {
      currErrors = {
        ...currErrors,
        requiredFields: {
          ...currErrors.requiredFields,
          triggerType: t('pages.accountSettings.automations.editorModal.errors.fieldRequired')
        }
      }
    }

    if (!actionType) {
      currErrors = {
        ...currErrors,
        requiredFields: {
          ...currErrors.requiredFields,
          actionType: t('pages.accountSettings.automations.editorModal.errors.fieldRequired')
        }
      }
    } else {
      const errorMessage = ruleActions[actionType]?.validate(actions?.[0]?.params)?.error
      if (errorMessage) {
        currErrors = {
          ...currErrors,
          actions: {
            ...currErrors.actions,
            [actionType]: errorMessage
          }
        }
      }
    }

    conditions.forEach(condition => {
      const errorMessage = ruleConditions[condition.type]?.validate(condition.params)?.error
      if (errorMessage) {
        currErrors = {
          ...currErrors,
          conditions: {
            ...currErrors.conditions,
            [condition.type]: errorMessage
          }
        }
      }
    })

    return currErrors
  }, [triggerType, actionType, actions, conditions, name, ruleActions, ruleConditions, t])

  const hasErrors = useMemo(() => {
    const currErrors = validateRule
    return !isEmpty(currErrors.conditions) || !isEmpty(currErrors.actions) || !isEmpty(currErrors.requiredFields)
  }, [validateRule])

  return {
    validateRule,
    errors: validateRule,
    hasErrors,
    noErrors
  }
}
