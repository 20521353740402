import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CodeTag, Link } from 'components/common/icons'
import QRCode from 'qrcode.react'
import SharingMethod from './SharingMethod'
import { trackEvent } from 'utils/analyticsUtils'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import { generateRcIframe } from 'utils/rcUtils'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  qrCode: {
    backgroundColor: 'white',
    boxShadow: 'inset 0px 0px 0px 1px var(--bg-color-43)',
    '& canvas': {
      maxHeight: 60,
      maxWidth: 60
    }
  }
}))

const SharingMethods = ({ linkType, recommendedMethod }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    rcConfiguration: { rcLinks }
  } = usePracticeConfiguration()

  const downloadQR = useCallback(
    link => {
      trackEvent('RC - QR downloaded')
      dispatch(
        Actions.downloadQRCode({
          qrCodeId: `qrCodeID-${link}`,
          fileName: t(`pages.accountSettings.services.remoteConsultation.qrCodeDownloadedFileName.${link}`)
        })
      )
    },
    [dispatch, t]
  )

  const copyToClipboard = useCallback(
    ({ link, type }) => {
      try {
        navigator.clipboard.writeText(link)
        dispatch(
          Actions.showSnackbar({
            text: t('general.copiedToClipboard', { type }),
            type: 'success'
          })
        )
      } catch (err) {
        dispatch(
          Actions.showSnackbar({
            text: t('general.genericError'),
            type: 'error'
          })
        )
      }
    },
    [dispatch, t]
  )

  const methods = useMemo(
    () => [
      {
        type: 'url',
        label: t('pages.accountSettings.services.remoteConsultation.url.label'),
        buttonLabel: t('pages.accountSettings.services.remoteConsultation.url.buttonLabel'),
        icon: () => <Link />,
        onClick: linkType => {
          trackEvent('RC - URL copied', { linkOption: linkType })
          copyToClipboard({ link: rcLinks[linkType].url, type: 'Link' })
        }
      },
      {
        type: 'iframe',
        label: t('pages.accountSettings.services.remoteConsultation.iFrame.label'),
        subtitle: t('pages.accountSettings.services.remoteConsultation.iFrame.subtitle'),
        buttonLabel: t('pages.accountSettings.services.remoteConsultation.iFrame.buttonLabel'),
        icon: () => <CodeTag />,
        onClick: linkType => {
          trackEvent('RC - iFrame link copied', { linkOption: linkType })
          copyToClipboard({ link: generateRcIframe({ selectedLink: rcLinks[linkType].url }), type: 'Code' })
        }
      },
      {
        type: 'qrCode',
        buttonLabel: t('pages.accountSettings.services.remoteConsultation.qrCode.buttonLabel'),
        icon: linkType => <QRCode value={rcLinks[linkType]?.url} size={500} id={`qrCodeID-${linkType}`} />,
        onClick: linkType => downloadQR(linkType),
        hideCopyIcon: true,
        className: classes.qrCode
      }
    ],
    [classes.qrCode, copyToClipboard, downloadQR, rcLinks, t]
  )

  return (
    <Grid container spacing={1} alignItems="flex-end">
      {methods.map((method, methodIndex) => (
        <Grid item key={methodIndex}>
          <SharingMethod
            index={methodIndex}
            isRecommended={recommendedMethod === method.type}
            linkType={linkType}
            method={method}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default SharingMethods
