import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.main,
    fontFamily: 'Dazzed',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '40px'
  },
  light: {
    fontWeight: 400
  }
}))

const DazzedHeading20 = ({ className, children, light = false, ...props }) => {
  const classes = useStyles()

  return (
    <div className={[classes.heading, className, light && classes.light].join(' ')} {...props}>
      {children}
    </div>
  )
}

export default DazzedHeading20
