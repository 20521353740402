import React, { useCallback } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import { scrollBar } from 'styles/theme'
import { Document, Page } from 'react-pdf'
import { isMobile } from 'utils/mobileUtils'

const useStyles = () =>
  makeStyles({
    container: {
      padding: 20,
      overflowY: 'auto',
      maxHeight: '350px',
      maxWidth: '100%',
      ...scrollBar
    },
    imageContaier: {
      position: 'relative'
    },
    image: {
      height: 190,
      width: 190,
      objectFit: 'cover',
      borderRadius: '20px 0 20px 20px',
      boxShadow: '0px 0px 2px 1px #00000042'
    },
    removeButton: {
      position: 'absolute',
      boxSizing: 'border-box',
      right: 2,
      top: 2,
      borderRadius: 14,
      background: '#FFFFFFAA',
      cursor: 'pointer'
    }
  })()

const ChatFilesPreview = ({ items, removePreview, isPromotion }) => {
  const classes = useStyles()

  const renderFilePreview = useCallback(
    item => {
      switch (item.messageType) {
        case 'image':
        case 'gif':
          return <img src={isPromotion ? item.thumbnailUrl : item.data} className={classes.image} alt="..." />
        case 'promotion':
          return <img src={item.thumbnailUrl} className={classes.image} alt="..." />
        case 'pdf':
          return (
            <Document file={item.data}>
              <Page pageNumber={1} width={190} height={190} />
            </Document>
          )
        case 'video':
          return (
            <video width="220" height="190" preload="metadata" controls autoPlay={isMobile()}>
              <source src={item.data} type="video/mp4" />
            </video>
          )
        default:
          break
      }
    },
    [classes, isPromotion]
  )

  return (
    <Grid container className={classes.container} spacing={3}>
      {items.map((currItem, i) => (
        <Grid item className={classes.imageContaier} key={i}>
          {renderFilePreview(currItem)}
          <div
            className={classes.removeButton}
            onClick={() => removePreview(isPromotion ? currItem.id : currItem.key, isPromotion)}
          >
            <Cancel style={{ color: 'var(--bg-color-4)' }} />
          </div>
        </Grid>
      ))}
    </Grid>
  )
}

export default ChatFilesPreview
