import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { trackEvent } from 'utils/analyticsUtils'
import ROUTES from 'consts/routesConsts'
import SwooshLargeIcon from 'components/common/icons/SwooshLarge2'
import PracticeInfoForm from '../Auth/PracticeInfoForm'
import BackButton from '../Auth/BackButton'
import Actions from 'actions'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    padding: '15px 15px 50px 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    flexDirection: 'column'
  },
  practiceInfoSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0',
    '@media (max-width: 960px)': {
      display: 'none'
    }
  }
}))

const PracticeInfo = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    isLoading: isLoadingAddressValidation,
    validationResult: isAddressValid,
    dirty: addressValidationDirty
  } = useSelector(state => state.billingReducer.addressValidation)

  const {
    practiceName,
    address,
    address2,
    city,
    country,
    state,
    zip,
    practiceCountryCode,
    practiceEmailAddress,
    hasLocatorConsent,
    clinicHomepageUrl,
    practicePhoneValue,
    bio,
    clinicLogo
  } = useSelector(state => state.quickSignUpReducer)

  useEffect(() => {
    dispatch(Actions.resetAddressValidationResult())
  }, [dispatch])

  const handleChangeForm = useCallback(value => dispatch(Actions.quickSignUpFormChanged(value)), [dispatch])
  const hanldeChangeLogo = useCallback(logo => handleChangeForm({ key: 'clinicLogo', value: logo }), [handleChangeForm])
  const handleFormSubmit = useCallback(() => {
    trackEvent('Sign up - practice info completed', {
      signupType: 'quick'
    })

    dispatch(
      Actions.validateAddress({
        state,
        street: address,
        zip,
        country,
        city,
        forwardTo: ROUTES.QUICK_SIGN_UP_TERMS
      })
    )
  }, [dispatch, address, state, zip, country, city])

  return (
    <div className={classes.container}>
      <SwooshLargeIcon className={classes.practiceInfoSwoosh} />
      <BackButton />
      <PracticeInfoForm
        clinicLogo={clinicLogo}
        clinicHomepageUrl={clinicHomepageUrl}
        practiceName={practiceName}
        address={address}
        address2={address2}
        city={city}
        state={state}
        zip={zip}
        country={country}
        practicePhoneValue={practicePhoneValue}
        practiceCountryCode={practiceCountryCode}
        practiceEmailAddress={practiceEmailAddress}
        hasLocatorConsent={hasLocatorConsent}
        bio={bio}
        handleFormSubmit={handleFormSubmit}
        hanldeChangeLogo={hanldeChangeLogo}
        handleChangeForm={handleChangeForm}
        isLoading={isLoadingAddressValidation}
        isAddressInvalid={addressValidationDirty && !isAddressValid}
      />
    </div>
  )
}

export default PracticeInfo
