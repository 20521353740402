import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import moment from 'moment'
import momenttz from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Calendar5, Clock } from 'components/common/icons'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import CustomDatePicker from 'components/common/CustomDatePicker'
import SelectInput from 'components/common/SelectInput'
import { generateHoursList } from 'utils/dateUtils'

const useStyles = makeStyles({
  container: {
    borderBottom: '1px solid var(--border-color-10)',
    padding: '12px 0'
  },
  datePicker: {
    paddingLeft: 30,
    cursor: 'pointer',
    fontSize: 14
  },
  datePickerIcon: {
    zIndex: 1,
    top: '50%',
    left: 5,
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    position: 'absolute'
  },
  timeDropdown: {
    '& .MuiInputBase-root': {
      marginTop: '0 !important'
    },
    '& .MuiSelect-root': {
      paddingLeft: 28
    }
  },
  discard: {
    color: 'var(--text-color-14)',
    fontWeight: 600
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 30
  },
  clockIcon: {
    position: 'absolute',
    top: '50%',
    left: 7,
    transform: 'translateY(-50%) !important'
  }
})

const AppointmentForm = ({ appointment = {}, handleSubmit, handleCancel }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const practiceTimezone = useSelector(state => state.practiceReducer.details.practiceTimezone)

  const [date, setDate] = useState(null)
  const [time, setTime] = useState('10:00')

  const handleChangeDate = useCallback(newDate => setDate(moment(newDate).format('YYYY-MM-DD')), [])
  const handleChangeTime = useCallback(({ value }) => setTime(value), [])

  useEffect(() => {
    if (appointment?.date) {
      setDate(momenttz(appointment?.date).tz(practiceTimezone).format('YYYY-MM-DD'))
      setTime(momenttz(appointment?.date).tz(practiceTimezone).format('HH:mm'))
    }
  }, [appointment, practiceTimezone])

  const onSubmit = useCallback(() => {
    const timezoneOffset = momenttz().tz(practiceTimezone).format('Z')
    handleSubmit({
      ...appointment,
      date: moment(`${date}T${time}${timezoneOffset}`).utc().format()
    })
  }, [appointment, date, handleSubmit, practiceTimezone, time])

  const timeOptions = useMemo(() => generateHoursList(), [])

  return (
    <Grid container className={classes.container} spacing={1}>
      <Grid item xs={4}>
        <CustomDatePicker
          value={date}
          handleDateChange={handleChangeDate}
          format="iiii, MMMM dd, yyyy"
          inputVariant="outlined"
          inputClassName={classes.datePicker}
          iconComponent={<Calendar5 className={classes.datePickerIcon} />}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectInput
          style={{ bright: true, thick: true }}
          options={timeOptions}
          value={time}
          onChange={handleChangeTime}
          keepErrorContainerWhenInactive={false}
          containerClassName={classes.timeDropdown}
          iconComponent={<Clock className={classes.clockIcon} />}
        />
      </Grid>
      <Grid item xs={5}>
        <div className={classes.actionsContainer}>
          <PrimaryButton
            label={t('general.discard')}
            onClick={handleCancel}
            small
            height="40px"
            variant="outlined"
            className={classes.discard}
          />
          <PrimaryButton
            label={t('general.save')}
            onClick={onSubmit}
            small
            height="40px"
            disabled={!date}
            className={classes.submit}
          />
        </div>
      </Grid>
    </Grid>
  )
}

export default AppointmentForm
