import React, { useMemo, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { createIdForTest } from 'utils/testingUtils.js'

const useStyles = makeStyles(theme => ({
  checkboxRoot: {
    padding: 3
  },
  checked: {
    color: theme.palette.info.main
  },
  filledCheckedMark: {
    position: 'absolute',
    width: 10,
    height: 10,
    backgroundColor: 'var(--bg-color-7)'
  },
  label: {
    fontWeight: 400
  },
  checkboxIcon: {
    color: 'var(--border-color-13)'
  },
  checkbox: {
    padding: '9px 9px 9px 0'
  }
}))

const CheckBoxFilter = ({ filterOptions = [], selectedFilters = {}, onChange = () => {}, className = '' }) => {
  const classes = useStyles()

  const checkboxOffComponent = useMemo(
    () => <CheckBoxOutlineBlank className={classes.checkboxIcon} />,
    [classes.checkboxIcon]
  )
  const checkboxOnComponent = useMemo(
    () => (
      <div className={'MuiIconButton-label'}>
        <CheckBoxOutlineBlank className={classes.checkboxIcon} />
        <div className={classes.filledCheckedMark} />
      </div>
    ),
    [classes.filledCheckedMark, classes.checkboxIcon]
  )

  const isChecked = useCallback(
    filterToCheck => {
      let isChecked = false
      Object.keys(selectedFilters).forEach(filter => {
        if (selectedFilters[filter].find(currFilter => currFilter.value === filterToCheck.value)) {
          isChecked = true
          return
        }
      })

      return isChecked
    },
    [selectedFilters]
  )

  return (
    <Grid container direction="column" wrap="nowrap" className={className}>
      {filterOptions.map((currFilter, index) => (
        <Grid item key={index} id={createIdForTest({ componentId: 'filter-patient', title: currFilter.label })}>
          <Grid container direction="row" wrap="nowrap" justifyContent="flex-start" alignItems="center">
            <Grid item>
              <Checkbox
                icon={checkboxOffComponent}
                checkedIcon={checkboxOnComponent}
                classes={{ checked: classes.checked, root: classes.checkboxRoot }}
                checked={isChecked(currFilter)}
                onChange={() => onChange(currFilter)}
              />
            </Grid>
            <Grid item zeroMinWidth>
              <DazzedParagraph12 className={classes.label}>{currFilter.label}</DazzedParagraph12>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default CheckBoxFilter
