import { API } from 'aws-amplify'
import { ofType } from 'redux-observable'
import i18n from 'resources/locales/i18n'
import { concat, from, of } from 'rxjs'
import { catchError, mergeMap, pluck, switchMap } from 'rxjs/operators'
import Actions from '../actions'

export const requestGrinScansEpic = action$ => {
  return action$.pipe(
    ofType(Actions.REQUEST_PROVIDER_STLS),
    pluck('payload'),
    switchMap(({ patientId, provider }) =>
      from(
        API.post('grinServerlessApi', '/treatment-tracker/v1/stls/request', {
          body: {
            patientId,
            provider
          }
        })
      ).pipe(
        mergeMap(scans => of(Actions.requestProviderStlsReceived())),
        catchError(error =>
          concat(
            of(Actions.requestProviderStlsFailed(error)),
            of(
              Actions.showSnackbar({
                type: 'error',
                text: i18n.t('messages.uploadSTLsFailed')
              })
            )
          )
        )
      )
    )
  )
}
