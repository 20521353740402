import Collapse from '@material-ui/core/Collapse'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { isMacOs } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import InfoIcon from '../../common/icons/Info'
import { GRIN_SCOPE_MAX_IN_ORDER } from 'consts/billingConsts'
import DazzedParagraph12 from '../../common/text/DazzedParagraph12'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import OrderGrinKitsItem from './OrderGrinKitsItem'
import usePlanLimits from 'hooks/usePlanLimits'
import { GRIN_SUBSCRIPTION_GROUP } from 'consts/grinPlanConsts'
import ScopesDiscount from 'resources/scopes-discount.png'
import useClinic from 'hooks/useClinic'

const useStyles = makeStyles({
  body: {
    display: 'flex',
    flexDirection: 'column'
  },
  subTitle: {
    marginBottom: 15,
    fontWeight: 500
  },
  currencyPrefix: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 400,
    marginTop: isMacOs ? 2 : 0
  },
  addScopesMini: {
    alignSelf: 'center',
    marginTop: 10,
    marginBottom: 15,
    fontSize: 15,
    fontWeight: 400,
    color: 'var(--text-color-3)',
    cursor: 'pointer'
  },
  learMoreContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  infoIcon: {
    marginBottom: 4,
    marginRight: 5,
    width: 16
  },
  learMore: {
    alignSelf: 'center',
    marginTop: 10,
    marginBottom: 15,
    fontSize: 14,
    textDecoration: 'underline',
    fontWeight: 400,
    color: 'var(--text-color-3)',
    cursor: 'pointer'
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'var(--border-color-7)',
    marginTop: 5,
    marginBottom: 15
  },
  note: {
    color: 'var(--text-color-6)'
  }
})

const OrderGrinKitsAmount = ({
  products,
  shippingFee,
  formErrors,
  onShowProductComparison,
  handleChangeQuantity,
  handleClickMinusQuantity,
  handleClickPlusQuantity,
  scopeMiniProduct,
  hidePrice,
  canClinicBuyScopes,
  isSubscriptionPlan,
  hasAnyAmount
}) => {
  const { t } = useTranslation()
  const classes = useStyles({ quantityError: formErrors?.quantity })
  const { grinPlanGroup } = usePlanLimits()
  const { hasFreeScopes } = useClinic()

  return (
    <div className={classes.body}>
      {!isSubscriptionPlan && (
        <DazzedParagraph16 className={classes.subTitle} textAlign="center">
          {t('dialogs.orderScopes.scopesAmountText', { amount: GRIN_SCOPE_MAX_IN_ORDER })}
        </DazzedParagraph16>
      )}

      {products
        .filter(product => product.quantity != null)
        .map(product => (
          <OrderGrinKitsItem
            key={product.type}
            type={product.type}
            price={product.price}
            hidePrice={hidePrice}
            quantity={product.quantity}
            maxQuantity={product.maxQuantity}
            formErrors={formErrors}
            onSetQuantity={quantity => handleChangeQuantity({ ...product, quantity })}
            onClickMinusQuantity={() => handleClickMinusQuantity(product)}
            onClickPlusQuantity={() => handleClickPlusQuantity(product)}
          />
        ))}

      {!!scopeMiniProduct && scopeMiniProduct.quantity == null && (
        <DazzedParagraph16 className={classes.addScopesMini} onClick={() => scopeMiniProduct.onSetQuantity(0)}>
          {t('dialogs.orderScopes.addScopeMini')}
        </DazzedParagraph16>
      )}

      <Collapse in={scopeMiniProduct.quantity != null}>
        <div className={classes.learMoreContainer}>
          <InfoIcon className={classes.infoIcon} />
          <DazzedParagraph16 className={classes.learMore} onClick={onShowProductComparison}>
            {t('dialogs.orderScopes.learnMore')}
          </DazzedParagraph16>
        </div>
      </Collapse>

      <div className={classes.separator} />
      {!canClinicBuyScopes ? (
        <DazzedParagraph12 className={classes.note}>{t('dialogs.orderScopes.manualShippingFeeNote')}</DazzedParagraph12>
      ) : (
        hasAnyAmount && (
          <>
            {grinPlanGroup.includes(GRIN_SUBSCRIPTION_GROUP) && !hasFreeScopes ? (
              <img src={ScopesDiscount} alt="" />
            ) : (
              <DazzedParagraph12 className={classes.note}>
                {t('dialogs.orderScopes.shippingFeeText1')}
                <span className={classes.currencyPrefix}>&nbsp;$</span>
                {t('dialogs.orderScopes.shippingFeeText2', {
                  fee: `${shippingFee} USD`
                })}
              </DazzedParagraph12>
            )}
          </>
        )
      )}
    </div>
  )
}

export default OrderGrinKitsAmount
