import * as React from 'react'

const OrderScopesQuickLink = ({ className }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.3335 15.0818C1.3335 13.8581 2.16629 12.7915 3.3534 12.4947L14.6668 9.66639V22.9997L3.3534 20.1714C2.16629 19.8746 1.3335 18.808 1.3335 17.5843V15.0818Z"
        stroke="#3C51EE"
      />
      <path
        d="M12.1964 11.0846C12.8974 9.79955 14.2443 9 15.708 9H24.2923C25.7561 9 27.1029 9.79955 27.8039 11.0846L29.6221 14.4179C30.2733 15.6118 30.2733 17.0548 29.6221 18.2487L27.8039 21.5821C27.1029 22.8671 25.7561 23.6667 24.2923 23.6667H15.708C14.2442 23.6667 12.8974 22.8671 12.1964 21.5821L10.3783 18.2487C9.72704 17.0548 9.72704 15.6118 10.3783 14.4179L12.1964 11.0846Z"
        fill="white"
        stroke="#3C51EE"
      />
      <path
        d="M14.8009 13.7727C15.5609 12.8608 16.6867 12.3335 17.8738 12.3335H22.1268C23.3139 12.3335 24.4397 12.8608 25.1997 13.7727L25.911 14.6263C26.7351 15.6152 26.7351 17.0517 25.911 18.0406L25.1997 18.8942C24.4397 19.8062 23.3139 20.3335 22.1268 20.3335H17.8738C16.6867 20.3335 15.5609 19.8062 14.8009 18.8942L14.0896 18.0406C13.2655 17.0517 13.2655 15.6152 14.0896 14.6263L14.8009 13.7727Z"
        fill="white"
        stroke="#3C51EE"
      />
    </svg>
  )
}

export default OrderScopesQuickLink
