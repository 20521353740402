import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import MessageTypeWrapper from '../MessageTypeWrapper'
import ScanMessage from './ScanMessage'
import UrgencyDetails from './UrgencyDetails'
import { ScanTrackingStatus } from 'consts/giConsts'
import ScanMessageTrackingStatus from './ScanMessageTrackingStatus'

const useStyles = ({ isPatientsListExpanded }) =>
  makeStyles({
    doubleScanContainer: {
      '@media (max-width: 1700px)': {
        flexDirection: ({ isPatientsListExpanded }) => (!isPatientsListExpanded ? 'row' : 'column')
      },
      '@media (max-width: 1300px)': {
        flexDirection: 'column !important'
      }
    },
    messageUserInfo: {
      marginLeft: 5
    }
  })({ isPatientsListExpanded })

const DoubleScanMessage = ({
  id,
  isFirstMessage,
  isLastMessage,
  content,
  name,
  userType,
  type,
  isLocal,
  cache,
  alignLeft,
  timestamp,
  isUrgent = false,
  urgencyReason = null,
  patientId,
  grinScan
}) => {
  const isPatientsListExpanded = useSelector(state => state.appReducer.isPatientsListExpanded)
  const classes = useStyles({ isPatientsListExpanded })

  return (
    <Grid container direction="row">
      <Grid item>
        <MessageTypeWrapper
          isFirstMessage={isFirstMessage}
          isLastMessage={isLastMessage}
          alignLeft={alignLeft}
          name={name}
          userType={userType}
          infoClassName={classes.messageUserInfo}
          urgencyReason={urgencyReason}
          isUrgent={isUrgent}
          id={id}
        >
          <Grid container spacing={1} wrap="nowrap" className={classes.doubleScanContainer}>
            <Grid item>
              <ScanMessage
                content={content.withAlignerScan}
                name={name}
                userType={userType}
                type={type}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                withAligners
                patientId={patientId}
              />
            </Grid>
            <Grid item>
              <ScanMessage
                content={content.withoutAlignerScan}
                type={type}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                withAligners={false}
                timestamp={timestamp}
                patientId={patientId}
              />
            </Grid>
          </Grid>
          <UrgencyDetails isUrgent={isUrgent} urgencyReason={urgencyReason} />
          {urgencyReason && grinScan.trackingStatus && grinScan.trackingStatus !== ScanTrackingStatus.NotRequired && (
            <div style={{ height: '8px' }} />
          )}
        </MessageTypeWrapper>
      </Grid>
      <Grid item>
        {grinScan?.trackingStatus && (
          <ScanMessageTrackingStatus
            grinScanId={grinScan.id}
            trackingStatus={grinScan.trackingStatus}
            trackingDetails={grinScan.trackingDetails}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default DoubleScanMessage
