import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import UploadDropzone from 'components/common/UploadDropzone/UploadDropzone'
import { FILE_UPLOAD_MAX_SIZE, IMAGE_INPUT_FILE_TYPES } from 'consts/appConsts'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'inherit!important',
    padding: '20px !important'
  },
  dropzone: {
    padding: 80
  }
}))

const UploadFromComputerTab = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { grinScanId } = useSelector(state => state.treatmentReducer.shareNewScanModal)

  const handleDropFiles = useCallback(
    files => {
      trackEvent('Share scan modal - Local files uploaded')
      dispatch(
        Actions.uploadShareScanAttachments({
          grinScanId,
          files
        })
      )
    },
    [dispatch, grinScanId]
  )

  return (
    <UploadDropzone
      onUploadFile={handleDropFiles}
      acceptedFormats={IMAGE_INPUT_FILE_TYPES}
      maxFileSize={FILE_UPLOAD_MAX_SIZE}
      containerClassName={classes.container}
      dropzoneClassName={classes.dropzone}
      isClosable={false}
      note={t('dialogs.shareScanAttachFiles.tabs.localFiles.dropzoneNote')}
    />
  )
}

export default UploadFromComputerTab
