import * as React from 'react'

const MarkUnread = (...props) => {
  return (
    <svg width="41" height="26" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="11" y="6.70593" width="20.2941" height="13.5294" rx="1.35294" stroke="#4451DE" />
      <path d="M11 10.0883L21.1471 15.5L31.2941 10.0883" stroke="#4451DE" />
      <circle cx="31.2941" cy="6.70588" r="3.70588" fill="#4451DE" stroke="white" strokeWidth="2" />
    </svg>
  )
}

export default MarkUnread
