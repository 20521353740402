import * as React from 'react'

export default () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="12.5" fill="#26C36F" />
      <path d="M5.83203 12.0834L10.4154 16.6667L19.1654 7.91675" stroke="white" strokeWidth="2.5" />
    </svg>
  )
}
