import React, { useState } from 'react'
import CategorySubtitle from '../CategorySubtitle'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import EditPencil from 'components/common/icons/patientBrief/EditPencil'
import { Fade } from '@material-ui/core'

const TeethSelectionPreview = ({ label = '', selections = [], viewMode, withPencil = true }) => {
  const [isEditButtonVisible, setIsEditButtonVisible] = useState(false)

  return (
    <div onMouseEnter={() => setIsEditButtonVisible(true)} onMouseLeave={() => setIsEditButtonVisible(false)}>
      <CategorySubtitle viewMode={viewMode}>{label}</CategorySubtitle>
      <DazzedParagraph14 strong>
        {selections.join(', ')}
        {!viewMode && withPencil && (
          <Fade in={isEditButtonVisible}>
            <span style={{ cursor: 'pointer', marginLeft: 5 }}>
              <EditPencil />
            </span>
          </Fade>
        )}
      </DazzedParagraph14>
    </div>
  )
}

export default TeethSelectionPreview
