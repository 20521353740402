import * as React from 'react'

export default props => {
  return (
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 1L8 8" stroke="#041333" strokeWidth="0.75" />
      <path d="M8 1L1 8" stroke="#041333" strokeWidth="0.75" />
    </svg>
  )
}
