import * as React from 'react'

function NotifyPractice(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.78593 2.81726C3.5678 1.44899 5.34514 1.02942 6.65637 1.90357L6.94614 2.09675C7.58435 2.52223 8.41579 2.52223 9.054 2.09675L9.34377 1.90357C10.655 1.02942 12.4323 1.44899 13.2142 2.81726C14.0607 4.29856 14.3504 6.03343 14.0311 7.70938L12.9526 13.3715C12.6249 15.0921 10.2238 15.2526 9.66991 13.591L8.66415 10.5737C8.45142 9.93548 7.54872 9.93549 7.33599 10.5737L6.33023 13.591C5.77634 15.2526 3.37523 15.0921 3.04749 13.3715L1.969 7.70938C1.64977 6.03342 1.93948 4.29856 2.78593 2.81726ZM6.10167 2.73562C5.27251 2.18285 4.14859 2.44817 3.65418 3.3134C2.92594 4.58781 2.67669 6.08038 2.95134 7.52227L4.02983 13.1844C4.16478 13.8928 5.15347 13.959 5.38155 13.2747L6.38731 10.2574C6.90394 8.70756 9.09621 8.70757 9.61283 10.2574L10.6186 13.2747C10.8467 13.959 11.8354 13.8928 11.9703 13.1844L13.0488 7.52227C13.3234 6.08038 13.0742 4.58781 12.346 3.3134C11.8515 2.44817 10.7276 2.18285 9.89847 2.73562L9.6087 2.9288C8.63459 3.57821 7.36555 3.57821 6.39144 2.9288L6.10167 2.73562Z"
        fill="#001335"
      />
    </svg>
  )
}

export default NotifyPractice
