import { Grid, makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import Tooltip from 'components/common/Tooltip'
import { NewLead, ReadyToScan, ScanSent } from 'components/common/icons'
import WaitingForScope from 'components/common/icons/WaitingForScope'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  leadStatusCell: {
    width: 90
  }
})

const RCLeadStatus = ({ status }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const leadStatusIcon = useMemo(() => {
    switch (status) {
      case 'newLead':
        return <NewLead />
      case 'waitingForScope':
        return <WaitingForScope />
      case 'readyToScan':
        return <ReadyToScan />
      case 'scanSent':
        return <ScanSent />
      default:
    }
  }, [status])

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.leadStatusCell}>
      <Grid item>
        <Tooltip
          arrow={true}
          disableTooltip={!status}
          value={t(`pages.rcDashboard.table.leadStatuses.${status}`)}
          variant="dark"
          placement="bottom"
        >
          {leadStatusIcon}
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default RCLeadStatus
