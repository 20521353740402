import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, CircularProgress } from '@material-ui/core'
import TreatmentForm from './TreatmentForm'
import { useTranslation } from 'react-i18next'
import Actions from 'actions'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { PrelimApproval } from 'components/common/icons'
import useRolePermissions from 'hooks/useRolePermissions'
import { CONTENT_HEIGHT } from 'consts/patientCard'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles({
  container: {
    height: CONTENT_HEIGHT,
    padding: '25px 50px 0 5%',
    position: 'relative',
    overflowY: 'auto'
  },
  loaderContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    zIndex: 999,
    backgroundColor: 'var(--bg-color-28)'
  },
  modalBody: {
    padding: '50px 15px 0 15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  modalSubtitle: {
    marginTop: 30
  }
})

const PrelimPlanTab = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()

  const patientId = useSelector(state => state.patientsReducer.patientCard.patientId)
  const treatmentId = useSelector(state => state.patientsReducer.patientCard.patient?.treatments?.items[0].id)
  const preliminaryPlanProducts = useSelector(state => state.treatmentReducer.preliminaryPlanProducts.data)
  const { isLoading: isPrelimPlanLoading, data: prelimPlan } = useSelector(
    state => state.patientsReducer.patientCard.prelimPlan
  )

  const [isLoading, setIsLoading] = useState(false)
  const [isPrelimPlanSubmitedModalOpen, setIsPrelimPlanSubmitedModalOpen] = useState(false)

  const isApprover = useMemo(() => permissions.approvePrelimPlan, [permissions])

  useEffect(() => {
    if (prelimPlan && isLoading && !isPrelimPlanLoading && !isApprover) {
      setIsPrelimPlanSubmitedModalOpen(true)
    }
    setIsLoading(isPrelimPlanLoading)
  }, [isApprover, isLoading, isPrelimPlanLoading, prelimPlan])

  const [prelimPlanValues, setPrelimPlanValues] = useState({
    ...prelimPlan,
    tier: '',
    cost: '',
    length: '',
    periodType: 'month',
    vendor: '',
    additionalVendor: '',
    notes: ''
  })
  const [isPrelimFormDirty, setIsPrelimFormDirty] = useState(true)

  useEffect(() => {
    if (prelimPlan) {
      setPrelimPlanValues({
        ...prelimPlan
      })
    }
  }, [prelimPlan])

  useEffect(() => {
    dispatch(Actions.fetchPreliminaryPlanProducts())
  }, [dispatch])

  const submitPrelimPlan = useCallback(
    e => {
      e.preventDefault()
      const action = isApprover ? Actions.approvePatientPrelimPlan : Actions.savePatientPrelimPlan
      dispatch(
        action({
          ...prelimPlanValues,
          treatmentId,
          patientId
        })
      )
      setIsPrelimFormDirty(false)
      trackEvent('Prelim plan tab - plan submitted')
    },
    [isApprover, dispatch, prelimPlanValues, treatmentId, patientId]
  )

  const handleChangePrelimValues = useCallback(
    values => {
      setPrelimPlanValues(values)
      !isPrelimFormDirty && setIsPrelimFormDirty(true)
    },
    [isPrelimFormDirty]
  )

  const hasAccessToEdit = useMemo(
    () => isApprover || permissions.submitPrelimPlan,
    [isApprover, permissions.submitPrelimPlan]
  )

  return (
    <>
      {isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress color="primary" />
        </div>
      )}
      <div className={classes.container}>
        <TreatmentForm
          type="prelim"
          values={prelimPlanValues}
          preliminaryPlanProducts={preliminaryPlanProducts}
          onSubmitForm={submitPrelimPlan}
          setValues={handleChangePrelimValues}
          disableFields={prelimPlanValues.status === 'purchased' || !hasAccessToEdit}
          hasAccessToEdit={hasAccessToEdit}
          buttonLabel={
            isApprover
              ? t('dialogs.patientInfo.prelimPlan.approveButtonLabel')
              : prelimPlan?.id
              ? t('dialogs.patientInfo.prelimPlan.againButtonLabel')
              : t('dialogs.patientInfo.prelimPlan.buttonLabel')
          }
          submitButton={isApprover}
          disableButton={
            prelimPlanValues.status === 'purchased' ||
            isLoading ||
            !prelimPlanValues.vendor ||
            (prelimPlanValues.tier === 3 && !prelimPlanValues.additionalVendor) ||
            !prelimPlanValues.periodType ||
            !prelimPlanValues.length ||
            prelimPlanValues.length < 1 ||
            !prelimPlanValues.cost ||
            !prelimPlanValues.tier ||
            (!isPrelimFormDirty && !isApprover)
          }
        />
      </div>
      <BaseModal
        open={isPrelimPlanSubmitedModalOpen}
        variant="alert"
        onPrimaryBtnClick={() => setIsPrelimPlanSubmitedModalOpen(false)}
        primaryLabel={t('general.close')}
        handleClose={() => setIsPrelimPlanSubmitedModalOpen(false)}
      >
        <div className={classes.modalBody}>
          <PrelimApproval />
          <DazzedParagraph16 textAlign="center" className={classes.modalSubtitle}>
            {t('dialogs.patientInfo.prelimPlan.prelimPlanSubmittedModal.body')}
          </DazzedParagraph16>
        </div>
      </BaseModal>
    </>
  )
}

export default PrelimPlanTab
