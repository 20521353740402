import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Checkbox } from '@material-ui/core'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckboxOnIcon from './icons/CheckboxOn'
import CheckboxOffIcon from './icons/CheckboxOff'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import DazzedParagraph16 from './text/DazzedParagraph16'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  checked: {
    color: theme.palette.info.main
  },
  error: {
    color: 'red'
  },
  captionDisabled: {
    color: 'var(--text-color-11)'
  },
  errorMessage: {
    opacity: 0,
    color: 'red',
    marginTop: '2px',
    marginBottom: '5px',
    height: '17px',
    fontSize: '12px',
    transition: 'opacity .15s ease',
    '&.active': {
      opacity: 1
    }
  },
  filledCheckedMark: {
    position: 'absolute',
    width: 10,
    height: 10,
    backgroundColor: 'var(--bg-color-7)'
  }
}))

const GrinCheckbox = ({
  checked,
  onChange = checked => {},
  onCheck = () => {},
  onUncheck = () => {},
  disabled = false,
  caption,
  errorMessage,
  keepErrorContainer = false,
  variant,
  className = '',
  captionClassName = '',
  wrapperClassName = '',
  color,
  borderColor,
  name,
  id,
  disabledWrapperClassName,
  customErrorMessageClassName = '',
  radioBorderColor = 'var(--text-color-3)',
  size = 'medium'
}) => {
  const classes = useStyles()

  const handleChange = event => {
    const checked = event.target.checked
    onChange(checked, name)

    checked ? onCheck() : onUncheck()
  }

  const checkboxOffComponent = useMemo(
    () =>
      variant === 'filled' ? (
        <CheckBoxOutlineBlank fontSize={size} style={{ color: borderColor ?? 'var(--border-color-13)' }} />
      ) : variant === 'radio' ? (
        <RadioButtonUncheckedIcon fontSize={size} style={{ color: radioBorderColor }} />
      ) : (
        <CheckboxOffIcon />
      ),
    [variant, size, borderColor, radioBorderColor]
  )

  const checkboxOnComponent = useMemo(
    () =>
      variant === 'filled' ? (
        <div className={'MuiIconButton-label'}>
          <CheckBoxOutlineBlank fontSize={size} style={{ color: borderColor ?? 'var(--border-color-13)' }} />
          <div className={classes.filledCheckedMark} />
        </div>
      ) : variant === 'radio' ? (
        <RadioButtonCheckedIcon fontSize={size} style={{ color: 'var(--text-color-3)' }} />
      ) : (
        <CheckboxOnIcon color={color} />
      ),
    [variant, size, borderColor, classes.filledCheckedMark, color]
  )

  return (
    <>
      <div className={[classes.root, wrapperClassName].join(' ')}>
        <Checkbox
          icon={checkboxOffComponent}
          checkedIcon={checkboxOnComponent}
          classes={{ checked: classes.checked, root: [className, disabled && disabledWrapperClassName].join(' ') }}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          id={id}
        />
        {caption && (
          <DazzedParagraph16 className={[disabled && classes.captionDisabled, captionClassName].join(' ')}>
            {caption}
          </DazzedParagraph16>
        )}
      </div>
      {(keepErrorContainer || !!errorMessage) && (
        <div className={[classes.errorMessage, customErrorMessageClassName, !!errorMessage && 'active'].join(' ')}>
          {errorMessage}
        </div>
      )}
    </>
  )
}

export default GrinCheckbox
