import React, { useMemo } from 'react'
import { ButtonBase, Box } from '@material-ui/core'

const PrimaryOutlinedButton = ({
  onClick,
  label,
  color,
  children,
  width = '192px',
  height = '40px',
  fontSize = '14px',
  icon,
  disabled = false,
  className = ''
}) => {
  const border = useMemo(
    () => `1px solid ${disabled ? 'var(--text-color-11)' : color ? color : 'var(--bg-color-7)'}`,
    [disabled, color]
  )
  const fontColor = useMemo(
    () => (disabled ? 'var(--text-color-11)' : color ? color : 'var(--text-color-3)'),
    [disabled, color]
  )
  return (
    <ButtonBase onClick={onClick} component="span" disabled={disabled}>
      <Box
        className={className}
        border={border}
        borderRadius="20px"
        pl="10px"
        pr="10px"
        color={fontColor}
        fontFamily="Dazzed"
        fontSize={fontSize}
        height={height}
        width={width}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {icon}
        {label}
        {children}
      </Box>
    </ButtonBase>
  )
}

export default PrimaryOutlinedButton
