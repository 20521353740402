import React from 'react'

const SvgSuccess = props => {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 36.3333L30.4423 48L53 25" stroke="#3C52EF" />
      <circle cx="36" cy="36" r="35.5" stroke="#3C52EF" />
    </svg>
  )
}

export default SvgSuccess
