import React, { useMemo } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import BaseModal from 'components/common/modals/BaseModal'
import TextMessage from 'components/Patients/Chat/MessageTypes/TextMessage'
import ProfilePicture from 'components/common/ProfilePicture'
import { TIME_FORMAT_8 } from 'consts/dateTimeConsts'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles({
  body: {
    minWidth: 700,
    alignItems: 'center'
  },
  message: {
    width: 480
  },
  profilePicture: {
    marginRight: 10
  },
  subtitle: {
    marginTop: 50
  }
})

const CONFIRMATION_MODAL_ID = 'account-settings-welcome-message-modal'

const WelcomeMessageConfirmationModal = ({ isOpen, accountOwner, content, handleClose, handleSubmit, isLoading }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const timestamp = useMemo(() => moment().format(TIME_FORMAT_8), [])

  return (
    <BaseModal
      open={isOpen}
      title={t('pages.accountSettings.welcomeMessage.modal.title')}
      secondaryLabel={t('general.discard')}
      primaryLabel={t('general.confirm')}
      variant="alert"
      className={classes.body}
      handleClose={handleClose}
      onSecondaryBtnClick={handleClose}
      onPrimaryBtnClick={handleSubmit}
      isLoading={isLoading}
      closeButtonId={`${CONFIRMATION_MODAL_ID}-close-button`}
      primaryButtonId={`${CONFIRMATION_MODAL_ID}-confirm-button`}
      secondaryButtonId={`${CONFIRMATION_MODAL_ID}-discard-button`}
    >
      <Grid container justifyContent="center" alignItems="flex-end" wrap="nowrap" className={classes.message}>
        <Grid item className={classes.profilePicture}>
          <ProfilePicture
            name={accountOwner?.name}
            email={accountOwner?.email}
            photo={accountOwner?.avatar}
            size="small"
          />
        </Grid>
        <Grid item>
          <TextMessage
            isFirstMessage
            isLastMessage
            alignLeft
            name={accountOwner?.name}
            userType={accountOwner?.roleDescription}
            content={content}
            deleted={false}
            timestamp={timestamp}
            isBroadcast={false}
            backgroundColor={'var(--bg-color-47)'}
          />
        </Grid>
      </Grid>
      <DazzedParagraph14 strong textAlign="center" className={classes.subtitle}>
        {t('pages.accountSettings.welcomeMessage.modal.subtitle1')}
      </DazzedParagraph14>
      <DazzedParagraph14 strong textAlign="center">
        {t('pages.accountSettings.welcomeMessage.modal.subtitle2')}
      </DazzedParagraph14>
    </BaseModal>
  )
}

export default WelcomeMessageConfirmationModal
