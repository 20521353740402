import * as React from 'react'

const LeftArrow1 = ({ color = 'var(--text-color-3)', ...props }) => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.5 5L1 5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 1L1 5L5 9" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default LeftArrow1
