import { makeStyles } from '@material-ui/styles'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Actions from 'actions'
import OggHeading40 from 'components/common/text/OggHeading40'
import SwooshLargeIcon from 'components/common/icons/SwooshLarge2'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { Chevron2 } from 'components/common/icons'
import TermsDoc from '../Auth/TermsDoc'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  title: {
    color: 'var(--text-color-1)',
    marginBottom: 25
  },
  form: {
    width: '100%',
    maxWidth: 1000
  },
  signUpSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0',
    pointerEvents: 'none'
  },
  button: {
    marginTop: 40,
    maxWidth: 540
  },
  arrowBackContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    cursor: 'pointer',
    marginTop: '1%',
    marginBottom: '1%',
    marginLeft: 150
  },
  arrowBackText: {
    marginLeft: 10,
    color: 'var(--text-color-1)'
  }
}))

const Terms = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { isLoading, country, email } = useSelector(state => state.authReducer.practiceMembers)

  const handleFormSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!isLoading) {
        dispatch(Actions.createPracticeMember())
        trackEvent('Practice Member registration completed', {
          inviteeEmail: email
        })
      }
    },
    [dispatch, email, isLoading]
  )

  const handleClickBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <div className={classes.container}>
      <SwooshLargeIcon className={classes.signUpSwoosh} />
      <div className={classes.arrowBackContainer} onClick={handleClickBack}>
        <Chevron2 />
        <DazzedParagraph14 className={classes.arrowBackText}>Back</DazzedParagraph14>
      </div>
      <form className={classes.form} onSubmit={handleFormSubmit}>
        <OggHeading40 className={classes.title} textAlign="left">
          {t('pages.terms.termsAndConditions')}
        </OggHeading40>
        <TermsDoc country={country} />
        <PrimaryButton
          isLoading={isLoading}
          type="submit"
          label={t('general.next')}
          width="100%"
          disabled={isLoading}
          className={classes.button}
        />
      </form>
    </div>
  )
}

export default Terms
