import * as React from 'react'

const ServiceAccountResetPassword = props => (
  <svg width={17} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7 13a6 6 0 1 1 6-6" stroke="#4151E6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.377 3.53 13.32 7.24 9.61 5.185" stroke="#4151E6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default ServiceAccountResetPassword
