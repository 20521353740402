import moment from 'moment'
import momenttz from 'moment-timezone'
import { PHONE_MASKS_BY_COUNTRY_CODE } from '../consts/countryConsts'
import i18n from 'resources/locales/i18n'

export const toFirstLetterUppercase = value =>
  value
    .split(' ')
    .map(str => str.charAt(0).toUpperCase() + str.slice(1))
    .join(' ')

export const removePlus = input => input.replace(/[+]/g, '')

export const daysAgo = ({ date, format, isFirstLetterCapital = false }) => {
  const daysAgo = moment().startOf('day').diff(moment(date).startOf('day'), 'days')
  return daysAgo === 0
    ? isFirstLetterCapital
      ? 'Today'
      : 'today'
    : daysAgo === 1
    ? isFirstLetterCapital
      ? 'Yesterday'
      : 'yesterday'
    : daysAgo <= 7
    ? `${daysAgo} days ago`
    : format
    ? moment(date).format(format)
    : new Date(date).toLocaleDateString()
}

export const formatScheduleTime = date => {
  const daysLeft = moment(date).startOf('day').diff(moment().startOf('day'), 'days')

  if (daysLeft < 1) {
    return `${i18n.t('general.today')}, ${moment(date).format('hh:mm a')}`
  }

  if (daysLeft === 1) {
    return `${i18n.t('general.tomorrow')}, ${moment(date).format('hh:mm a')}`
  }

  return moment(date).format('MMMM DD, hh:mm a')
}

export const toUTC = tz => {
  const [hours, minutes] = momenttz.tz(tz).format('Z').split(':')
  let UTCString = `UTC${hours[1] === '0' ? hours[0] + hours[2] : hours}`
  if (minutes !== '00') {
    UTCString = UTCString + `:${minutes}`
  }
  return UTCString
}

export const getLastPathItem = path => {
  const parts = path.split('/')
  return parts[parts.length - 1]
}

export const secondsToHHMM = timeInSeconds => {
  if (timeInSeconds <= 0) {
    return '00:00'
  }

  let minutes = Math.floor(timeInSeconds / 60).toString()
  let seconds = (timeInSeconds % 60).toString()

  minutes = minutes.length === 1 ? `0${minutes}` : minutes
  seconds = seconds.length === 1 ? `0${seconds}` : seconds

  return `${minutes}:${seconds}`
}

export const divideByThousands = (value, maximumFractionDigits = 2) => {
  return Number(value).toLocaleString('en-US', {
    maximumFractionDigits
  })
}

export const beautifyPhoneNumber = (countryCode = '', number = '') => {
  const code = `${countryCode}`.replace('+', '')
  const mask = PHONE_MASKS_BY_COUNTRY_CODE[code]
  if (!mask) {
    return `${code} ${number}`
  }

  let i = 0
  const beautified = mask.replace(/./g, el => {
    if (i === number.length) {
      if (i !== 0 && (el === '(' || el === ')')) {
        return el
      } else {
        return ''
      }
    }
    if (el === '#') {
      return number[i++]
    } else {
      return el
    }
  })
  return `${code} ${beautified}`
}

export const urlRegex =
  /(^|[\s.:;?\-\]<(])(https?:\/\/[-\w;/?:@&=+$|_.!~*|'()[\]%#,☺]+[\w/#](\(\))?)(?=$|[\s',|()!.:;?\-[\]>)])/i

export const getCountryCode = countryCode => (countryCode ? '+' + removePlus(countryCode) : '')
export const getPhoneNumber = (phoneNumber, countryCode) =>
  phoneNumber && countryCode ? phoneNumber.substring(removePlus(countryCode).length) : ''
