import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Shipping from 'components/common/icons/Shipping'
import AuditItemsContainer from 'components/common/Audit/AuditItemsContainer'

const ShippingAudit = ({ audit = [] }) => {
  const { t } = useTranslation()

  const auditItems = useMemo(
    () =>
      audit
        .filter(item => item.category === 'shipping')
        .map(({ type, payload, time }) => {
          switch (type) {
            case 'shippingStatusUpdate':
              return {
                time,
                icon: <Shipping color="var(--text-color-11)" />,
                text: (
                  <Trans
                    i18nKey={
                      payload.oldStatus
                        ? `pages.rcDashboard.dialogs.shippingDetails.audit.shippingStatusUpdate`
                        : `pages.rcDashboard.dialogs.shippingDetails.audit.shippingStatusFirstUpdate`
                    }
                    values={{
                      oldStatus: t(`pages.rcDashboard.dialogs.shippingDetails.shippingStatuses.${payload.oldStatus}`),
                      nextStatus: t(`pages.rcDashboard.dialogs.shippingDetails.shippingStatuses.${payload.nextStatus}`)
                    }}
                  />
                ),
                subtext:
                  payload.shipbobStatusInformation &&
                  t(`pages.rcDashboard.dialogs.shippingDetails.audit.shipbobStatusInformation`, {
                    shipbobStatusInformation: payload.shipbobStatusInformation
                  })
              }
            default:
              return {
                time,
                text: <Trans i18nKey={`pages.rcDashboard.dialogs.shippingDetails.audit.${type}`} values={payload} />
              }
          }
        }),
    [t, audit]
  )

  return <AuditItemsContainer auditItems={auditItems} />
}

export default ShippingAudit
