import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import BaseModal from 'components/common/modals/BaseModal'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import UploadFormFileInput from './UploadFormFileInput'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import Checkmark from 'components/common/icons/bulk-invite/checkmark'
import Error from 'components/common/icons/bulk-invite/error'

const useStyles = makeStyles(theme => ({
  root: {
    width: '520px!important'
  },
  inputContainer: {
    padding: '18px 38px 8px 38px'
  },
  divier: {
    background: 'var(--bg-color-14)',
    width: '100%',
    height: 1,
    margin: '24px 12px'
  },
  errorsContainer: {
    padding: '0 12px',
    maxHeight: 500,
    overflowY: 'scroll'
  }
}))

const UploadFormDialog = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isOpen, status, errors } = useSelector(state => state.patientsReducer.bulkInviteForm)
  const [selectedFile, setSelectedFile] = useState()

  const handleFileSelected = useCallback(file => {
    setSelectedFile(file)
  }, [])

  const handleSubmit = useCallback(() => {
    dispatch(Actions.uploadBulkInviteForm(selectedFile))
  }, [dispatch, selectedFile])

  const handleClose = useCallback(() => {
    dispatch(
      Actions.toggleBulkInvitePopup({
        isOpen: false
      })
    )
  }, [dispatch])

  useEffect(() => {
    trackEvent(`Bulk invite - upload form popup - ${isOpen ? 'open' : 'close'}`)
    setSelectedFile(null)
  }, [isOpen])

  if (status === 'successful') {
    return (
      <BaseModal
        className={classes.root}
        open={isOpen}
        variant="alert"
        handleClose={handleClose}
        onPrimaryBtnClick={handleClose}
        primaryLabel={t('pages.accountSettings.advancedActions.bulkInvite.uploadDialog.primaryBtnSuccess')}
        largerButtons
        title={
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Checkmark />
            </Grid>
            <Grid item>{t('pages.accountSettings.advancedActions.bulkInvite.uploadDialog.titleUploaded')}</Grid>
          </Grid>
        }
      >
        <DazzedParagraph14 strong textAlign="center">
          {t('pages.accountSettings.advancedActions.bulkInvite.uploadDialog.formUploadedText')}
        </DazzedParagraph14>
      </BaseModal>
    )
  }

  if (status === 'fail') {
    return (
      <BaseModal
        className={classes.root}
        open={isOpen}
        variant="alert"
        handleClose={handleClose}
        onPrimaryBtnClick={handleClose}
        primaryLabel={t('general.close')}
        largerButtons
        title={
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Error />
            </Grid>
            <Grid item>{t('pages.accountSettings.advancedActions.bulkInvite.uploadDialog.titleFailed')}</Grid>
          </Grid>
        }
      >
        <Grid container direction="column">
          <Grid item>
            <DazzedParagraph14 strong textAlign="center">
              {t(`pages.accountSettings.advancedActions.bulkInvite.uploadDialog.errorMessage`)}
            </DazzedParagraph14>
          </Grid>
          <Grid item>
            <div className={classes.divier} />
          </Grid>
          <Grid item>
            <Grid container className={classes.errorsContainer}>
              {errors.form && (
                <Grid item xs={12}>
                  <DazzedParagraph14 bold>
                    {t(`pages.accountSettings.advancedActions.bulkInvite.errorCodes.${errors.form}`)}
                  </DazzedParagraph14>
                </Grid>
              )}
              {errors.rows?.map((row, i) => (
                <Grid item key={i} xs={12}>
                  <DazzedParagraph14 bold>
                    {t(`pages.accountSettings.advancedActions.bulkInvite.uploadDialog.line`, { index: row.index + 2 })}
                  </DazzedParagraph14>
                  <ul>
                    {row.errors?.map(error => (
                      <li key={`${i}-${error.fieldName}-${error.error}`}>
                        <DazzedParagraph14 strong>
                          {t(`pages.accountSettings.advancedActions.bulkInvite.fields.${error.fieldName}`)} -&nbsp;
                          {t(`pages.accountSettings.advancedActions.bulkInvite.errorCodes.${error.error}`)}
                        </DazzedParagraph14>
                      </li>
                    ))}
                  </ul>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </BaseModal>
    )
  }

  return (
    <BaseModal
      className={classes.root}
      open={isOpen}
      title={t('pages.accountSettings.advancedActions.bulkInvite.uploadDialog.title')}
      handleClose={handleClose}
      variant="alert"
      primaryLabel={t('general.submit')}
      onPrimaryBtnClick={handleSubmit}
      isPrimaryDisabled={!selectedFile || status === 'uploading'}
      isLoading={status === 'uploading'}
      secondaryLabel={t('general.cancel')}
      onSecondaryBtnClick={handleClose}
    >
      <Grid container alignItems="center" justifyContent="center" className={classes.inputContainer}>
        <Grid item xs={12}>
          <UploadFormFileInput onFileUpload={handleFileSelected} selectedFileName={selectedFile?.fileName} />
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default UploadFormDialog
