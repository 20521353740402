import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import ImageUploaderWithCrop from 'components/Profile/ImageUploaderWithCrop'
import { v4 as uuidv4 } from 'uuid'
import config from '../../utils/awsUtils'
import CachedImage from 'components/common/CachedImage'
import { Trash1 as TrashIcon, Picture, Avatar } from 'components/common/icons'

const useStyles = makeStyles(theme => ({
  container: {
    height: 135,
    flex: 1,
    backgroundColor: 'var(--bg-color-4)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: 10
  },
  title: {
    color: '#5C749C'
  },
  button: {
    color: 'var(--text-color-1)',
    '& span': {
      textDecoration: 'underline'
    }
  },
  photo: {
    height: 80,
    width: 80,
    backgroundColor: '#001335',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    overflow: 'hidden'
  },
  pointer: {
    cursor: 'pointer'
  },
  photoComponentContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
    '&:hover > div': {
      opacity: 1
    }
  },
  photoImage: {
    height: '100%',
    width: '100%'
  },
  deleteComponentContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'rgba(60, 82, 239, 0.9)',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    opacity: 0,
    transition: '.2s',
    cursor: 'pointer'
  },
  deleteComponentText: {
    fontSize: '10px',
    color: 'var(--text-color-1)'
  },
  hiddenElement: {
    display: 'none'
  }
}))

const ImageUploader = ({ value, title, uploadtext, changeText, type, onChange, s3Folder, circularCrop }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const inputId = useMemo(() => uuidv4(), [])

  const handleChangePhoto = useCallback(
    keys => {
      onChange({
        key: keys[0],
        bucket: config.aws_user_files_s3_bucket,
        region: config.aws_user_files_s3_bucket_region
      })
    },
    [onChange]
  )

  const handleDeletePhoto = useCallback(() => {
    onChange(null)
  }, [onChange])

  const mapToFullPath = useCallback(
    s3Object => {
      return !value
        ? null
        : {
            ...s3Object,
            key: s3Folder ? `${s3Folder}/${s3Object.key}` : s3Object.key
          }
    },
    [s3Folder, value]
  )

  const photoComponent = useMemo(
    () => (
      <div className={classes.photoComponentContainer}>
        <CachedImage s3Object={mapToFullPath(value)} alt="photo" applyClassName={classes.photoImage} />
        <div className={classes.deleteComponentContainer} onClick={handleDeletePhoto}>
          <TrashIcon />
          <DazzedParagraph12 className={classes.deleteComponentText}>{t('general.delete')}</DazzedParagraph12>
        </div>
      </div>
    ),
    [
      classes.deleteComponentContainer,
      classes.deleteComponentText,
      classes.photoComponentContainer,
      classes.photoImage,
      handleDeletePhoto,
      mapToFullPath,
      t,
      value
    ]
  )

  const placeholderComponent = useMemo(() => (type === 'avatar' ? <Avatar /> : <Picture />), [type])

  return (
    <div className={classes.container}>
      <DazzedParagraph12 className={classes.title} thickness="thin">
        {title}
      </DazzedParagraph12>
      <div className={classes.photo}>{value ? photoComponent : placeholderComponent}</div>
      <label htmlFor={inputId} className={classes.pointer}>
        <DazzedParagraph12 className={classes.button} thickness="thin">
          + <span>{value ? changeText : uploadtext}</span>
        </DazzedParagraph12>
      </label>
      <div className={classes.hiddenElement}>
        <ImageUploaderWithCrop
          inputId={inputId}
          onUploadDone={handleChangePhoto}
          minWidth={90}
          minHeight={90}
          keyWithExtension
          s3Folder={s3Folder}
          circularCrop={circularCrop}
        />
      </div>
    </div>
  )
}

export default ImageUploader
