import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Actions from 'actions'
import SwooshLargeIcon from 'components/common/icons/SwooshLarge2'
import WithLoader from 'components/common/WithLoader'
import TermsDoc from './TermsDoc'
import BackButton from './BackButton'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  termsContainer: {
    padding: '100px 0',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main
  },
  termsTitle: {
    fontFamily: 'Ogg',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '40px',
    lineHeight: '40px',
    marginBottom: '64px',
    color: 'var(--text-color-1)'
  },
  termsSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  nextButton: {
    background: 'var(--border-color-1)',
    color: 'var(--text-color-1)',
    width: '478px',
    borderRadius: '34px',
    '&:hover': {
      background: 'var(--border-color-1)'
    }
  },
  noFill: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      transition: 'color 0.2s ease',
      color: 'gray',
      boxShadow: 'none'
    }
  },
  titleContainer: {
    width: '45%'
  },
  contentContainer: {
    width: '52%',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1
  }
}))

const Terms = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isLoading, practiceCountry } = useSelector(state => state.authReducer)

  const handleFormSubmit = useCallback(
    e => {
      e.preventDefault()
      trackEvent('Sign up - terms accepted', {
        signupType: 'standard'
      })
      dispatch(Actions.requestCreateDoctor())
    },
    [dispatch]
  )

  return (
    <form className={classes.termsContainer} onSubmit={handleFormSubmit}>
      <SwooshLargeIcon className={classes.termsSwoosh} />
      <BackButton />
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <div className={classes.titleContainer}>
          <div className={classes.termsTitle}>{t('pages.terms.termsAndConditions')}</div>
        </div>
        <div className={classes.contentContainer}>
          <TermsDoc country={practiceCountry} />
        </div>
        <Box display="flex" justifyContent="center" mt="50px">
          <div>
            <WithLoader isLoading={isLoading}>
              <Button variant="contained" className={classes.nextButton} type="submit">
                {t('pages.terms.iAgree')}
              </Button>
            </WithLoader>
          </div>
        </Box>
      </Box>
    </form>
  )
}

export default Terms
