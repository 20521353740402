import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { APPLIANCE_MIN_NUMBER, APPLIANCE_MAX_NUMBER } from 'consts/treatmentConsts'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import FormInput from 'components/common/FormInput'
import { trackEvent } from 'utils/analyticsUtils'
import { useDispatch } from 'react-redux'
import Actions from 'actions'

const useStyles = makeStyles(theme => ({
  input: {
    width: '60px!important',
    textAlign: 'center'
  }
}))

const EditAlignerNumberModal = ({
  isOpen,
  setIsOpen,
  grinScanId,
  grinScanVersion,
  alignerNumber,
  analyticsPayload
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [alignerNumberInput, setAlignerNumberInput] = useState(alignerNumber)

  const handleClose = useCallback(() => {
    trackEvent('Edit aligner number - cancel clicked', analyticsPayload)
    setIsOpen(false)
  }, [setIsOpen, analyticsPayload])

  const handleSave = useCallback(() => {
    trackEvent('Edit aligner number - save changes clicked', analyticsPayload)
    dispatch(
      Actions.updateGrinScanAppliance({
        scan: {
          id: grinScanId,
          _version: grinScanVersion,
          applianceIndex: alignerNumberInput
        }
      })
    )
    setIsOpen(false)
  }, [analyticsPayload, dispatch, alignerNumberInput, grinScanId, grinScanVersion, setIsOpen])

  useEffect(() => {
    setAlignerNumberInput(alignerNumber)
  }, [alignerNumber])

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleClose}
      variant="alert"
      title={t('pages.patients.selectedPatient.timelineV2.editAlignerNumberModal.title')}
      primaryLabel={t('general.save')}
      onPrimaryBtnClick={handleSave}
      secondaryLabel={t('general.cancel')}
      onSecondaryBtnClick={handleClose}
    >
      <Grid container alignItems="center" justifyContent="center" spacing={2}>
        <Grid item>
          <DazzedParagraph16 strong>
            {t('pages.patients.selectedPatient.timelineV2.editAlignerNumberModal.alignersSetNumber')}
          </DazzedParagraph16>
        </Grid>
        <Grid item>
          <FormInput
            value={alignerNumberInput}
            setValue={setAlignerNumberInput}
            style={{ bright: true, borderBottom: true }}
            keepErrorContainerWhenInactive={false}
            inputClassName={classes.input}
            type="number"
            minValue={APPLIANCE_MIN_NUMBER}
            maxValue={APPLIANCE_MAX_NUMBER}
          />
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default React.memo(EditAlignerNumberModal)
