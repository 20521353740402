import React, { useMemo } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ProfilePicture from 'components/common/ProfilePicture'
import TextMessage from '../../Chat/MessageTypes/TextMessage'
import { updateS3PracticeLogoKey } from 'utils/storageUtils'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles({
  messageContainer: {
    width: '90%',
    position: 'relative',
    paddingLeft: 50,
    marginBottom: isMobile() ? 20 : 40,
    marginTop: 20,
    height: isMobile() ? 'auto' : 115
  },
  regularMessageContainer: {
    width: 'auto'
  },
  profilePicture: {
    left: 0,
    justifyContent: 'flex-start',
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    '& div, .avatar, img': {
      justifyContent: 'center',
      margin: 0
    }
  },
  chatBubble: {
    width: '85%',
    fontSize: '12px'
  }
})

export const BroadcastMessage = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { logo, practiceName } = useSelector(state => state.practiceReducer.details)
  const practiceLogo = useMemo(() => logo && updateS3PracticeLogoKey(logo), [logo])

  return (
    <Grid container className={classes.messageContainer} alignItems="flex-end">
      <Grid item>
        <TextMessage
          isFirstMessage
          isLastMessage
          name={t('pages.patients.selectedPatient.chat.comparisonModal.broadcastMessage')}
          userType={practiceName}
          content={t('pages.patients.selectedPatient.chat.comparisonModal.message1')}
          alignLeft
          timestamp="12:00 PM"
          isBroadcast
          className={isMobile() && classes.chatBubble}
        />
      </Grid>
      <Grid item className={classes.profilePicture}>
        <ProfilePicture photo={practiceLogo} name={practiceName} size="small" />
      </Grid>
    </Grid>
  )
}

export const PersonalMessage = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { name, roleDescription, photo } = useSelector(state => state.profileReducer.doctor)

  return (
    <Grid
      container
      alignItems="flex-end"
      className={[classes.messageContainer, classes.regularMessageContainer].join(' ')}
    >
      <Grid item>
        <TextMessage
          isFirstMessage
          isLastMessage
          name={name}
          userType={roleDescription}
          content={t('pages.patients.selectedPatient.chat.comparisonModal.message2')}
          alignLeft
          timestamp="12:00 PM"
          backgroundColor="#2F36541A"
          className={isMobile() && classes.chatBubble}
        />
      </Grid>
      <Grid item className={classes.profilePicture}>
        <ProfilePicture photo={photo} name={name} size="small" />
      </Grid>
    </Grid>
  )
}
