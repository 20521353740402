import * as React from 'react'

const RotateLeft = () => (
  <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 8.5C1 11.5376 3.46243 14 6.5 14C9.53757 14 12 11.5376 12 8.5C12 5.46243 9.53757 3 6.5 3"
      stroke="#4451DE"
      strokeWidth="2"
    />
    <path d="M7 3V0L2 3L7 6V3Z" fill="#4451DE" />
    <circle cx="1.5" cy="1.5" r="1.5" transform="matrix(-1 0 0 1 8 7)" fill="#4451DE" />
  </svg>
)

export default RotateLeft
