import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import FormInput from 'components/common/FormInput'
import PatientBriefCategory from './PatientBriefCategory'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { PatientBriefCategoryOrder } from 'consts/hiToolsConsts'
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
  customNameInput: {
    width: '250px'
  },
  practiceNotesInput: {
    width: '457px'
  },
  practiceNoteText: {
    color: 'var(--text-color-11)'
  }
}))

const PatientBriefForm = ({ values, setValues, errors = {}, viewMode = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const categoryColumns = useMemo(() => {
    const categories = _.sortBy(Object.keys(values?.categories || {}), category => PatientBriefCategoryOrder[category])
    if (!categories.length) {
      return []
    }

    const middle = Math.ceil(categories.length / 2)
    const firstHalf = categories.slice(0, middle)
    const secondHalf = categories.slice(middle)
    return [firstHalf, secondHalf]
  }, [values])

  return (
    <Grid container direction="column" style={{ height: '100%' }} justifyContent="space-between">
      <Grid item>
        <Grid container direction="column">
          <Grid item style={{ marginBottom: 32 }}>
            {viewMode ? (
              <DazzedParagraph16 bold>{values.customTitle}</DazzedParagraph16>
            ) : (
              <FormInput
                value={values.customTitle}
                setValue={value => setValues({ ...values, customTitle: value })}
                style={{ bright: true, thick: true, borderBottom: true }}
                placeholder={t('dialogs.patientBrief.nameInputPlaceholder')}
                containerClassName={classes.customNameInput}
              />
            )}
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between">
              {categoryColumns.map((categories, rowIndex) => (
                <Grid item key={`column-${rowIndex}`}>
                  <Grid container direction="column">
                    {categories.map(category => (
                      <Grid item key={`patient-brief-category-${category}`} style={{ marginBottom: 16 }}>
                        <PatientBriefCategory
                          category={category}
                          categoryValues={values.categories[category]}
                          setCategoryValues={categoryValues =>
                            setValues({ ...values, categories: { ...values.categories, [category]: categoryValues } })
                          }
                          error={errors?.categories?.[category]}
                          viewMode={viewMode}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {viewMode ? (
          <DazzedParagraph14 className={classes.practiceNoteText}>
            <span style={{ fontWeight: 500, marginRight: 5 }}>{t('dialogs.patientBrief.generalNoteLabel')} </span>
            {values.practiceNotes ? <span>{values.practiceNotes}</span> : <span style={{ fontWeight: 500 }}>-</span>}
          </DazzedParagraph14>
        ) : (
          <FormInput
            value={values.practiceNotes}
            setValue={value => setValues({ ...values, practiceNotes: value })}
            style={{ bright: true, thick: true, borderBottom: true }}
            placeholder={t('dialogs.patientBrief.practiceNotesPlaceholder')}
            containerClassName={classes.practiceNotesInput}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default PatientBriefForm
