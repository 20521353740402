import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import { setCameraPosition } from 'components/common/Grin3DViewer/Grin3DViewerUtils'
import { ArchViewerCenter, ArchViewerLeft, ArchViewerRight } from 'components/common/icons'

const POSE_POSITIONS = {
  LEFT: { x: 40, y: 2, z: -10 },
  CENTER: { x: 0, y: 0, z: 40 },
  RIGHT: { x: -40, y: 2, z: -10 }
}

const useStyles = makeStyles(() => ({
  control: {
    cursor: 'pointer',
    marginRight: 2
  }
}))

const PoseController = ({ cameras = [] }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const controls = useMemo(
    () => [
      {
        icon: <ArchViewerLeft />,
        onClick: () => {
          trackEvent('Treatment Tracker - Arches viewer - selected pose', {
            pose: 'left'
          })
          cameras.map(camera => setCameraPosition({ camera, ...POSE_POSITIONS.LEFT }))
        }
      },
      {
        icon: <ArchViewerCenter />,
        onClick: () => {
          trackEvent('Treatment Tracker - Arches viewer - selected pose', {
            pose: 'center'
          })
          cameras.map(camera => setCameraPosition({ camera, ...POSE_POSITIONS.CENTER }))
        }
      },
      {
        icon: <ArchViewerRight />,
        onClick: () => {
          trackEvent('Treatment Tracker - Arches viewer - selected pose', {
            pose: 'right'
          })
          cameras.map(camera => setCameraPosition({ camera, ...POSE_POSITIONS.RIGHT }))
        }
      }
    ],
    [cameras]
  )

  return (
    <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
      <Grid item>
        <DazzedParagraph14>{t('dialogs.treatmentTracker.poseController.view')}</DazzedParagraph14>
      </Grid>
      <Grid item>
        <Grid container style={{ marginTop: 4 }}>
          {controls.map((control, index) => (
            <Grid item key={`pose-control-${index}`} onClick={control.onClick} className={classes.control}>
              {control.icon}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PoseController
