import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import ProfilePicture from 'components/common/ProfilePicture'
import { useSelector } from 'react-redux'
import { GrinAutoChartAI } from 'components/common/icons'

const useStyles = makeStyles(theme => ({
  profilePicture: {
    padding: 5
  }
}))

const AutochartTableDescriptionCell = ({ label, createdByGrin = false, editorId }) => {
  const classes = useStyles()

  const { data: practiceMembers } = useSelector(state => state.profileReducer.practiceMembers)

  const editor = useMemo(() => practiceMembers.find(member => member.id === editorId), [editorId, practiceMembers])

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <DazzedParagraph12 thickness="bold">{label}</DazzedParagraph12>
      </Grid>
      {createdByGrin ? (
        <Grid item>
          <GrinAutoChartAI style={{ marginRight: 3 }} />
        </Grid>
      ) : (
        editor && (
          <Grid item>
            <ProfilePicture
              size="tiny"
              name={editor.name}
              email={editor.email}
              photo={editor.avatar}
              className={classes.profilePicture}
              tooltip
            />
          </Grid>
        )
      )}
    </Grid>
  )
}

export default AutochartTableDescriptionCell
