import React from 'react'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import DazzedParagraph12 from '../../../../common/text/DazzedParagraph12'
import DazzedParagraph16 from '../../../../common/text/DazzedParagraph16'
import Megaphone from '../../../../common/icons/Megaphone'

const NewTimelineAvailable = ({ event }) => {
  const classes = makeStyles({
    container: {
      paddingLeft: 20
    },
    boldEventText: {
      fontWeight: 600
    },
    timestamp: {
      fontSize: '10px'
    }
  })()

  const { t } = useTranslation()

  return (
    <Grid container direction="row" alignItems="center" spacing={2} className={classes.container}>
      <Grid item>
        <Megaphone />
      </Grid>
      <Grid item>
        <DazzedParagraph12 className={classes.boldEventText}>
          {t('pages.patients.selectedPatient.timeline.events.newTimelineAvailable')}
        </DazzedParagraph12>
      </Grid>
      <Grid item>
        <DazzedParagraph16 soft className={classes.timestamp}>
          since {moment(event.timestamp).format('LLLL')}
        </DazzedParagraph16>
      </Grid>
    </Grid>
  )
}

export default NewTimelineAvailable
