import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import usePlanLimits from './usePlanLimits'
import { isOnFreePlan } from 'utils/billingUtils'
import { isMobile } from 'utils/mobileUtils'
import actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'

export default () => {
  const dispatch = useDispatch()
  const planLimits = usePlanLimits()
  const userPlan = useSelector(state => state.practiceReducer.billing.grinPlan)
  const practiceName = useSelector(state => state.practiceReducer.details.practiceName)
  const memberRole = useSelector(state => state.profileReducer.doctor.roleDescription)

  const openOrderScopesModal = useCallback(() => {
    if (!isOnFreePlan(userPlan) || planLimits.maxScopes > 0) {
      dispatch(actions.setOrderGrinKitsModalVisibility(true))
    } else {
      dispatch(actions.setUpgradePlanForScopesWarningModalVisibility(true))
    }
    if (isMobile()) {
      trackEvent('Mobile_menu_scope', {
        practiceName,
        memberRole
      })
    }
  }, [dispatch, memberRole, planLimits.maxScopes, practiceName, userPlan])

  return {
    openOrderScopesModal
  }
}
