import Actions from 'actions'
import { API, graphqlOperation } from 'aws-amplify'
import { isMobile } from 'utils/mobileUtils'
import { createSurveyResult } from 'graphql/mutations'
import { ofType } from 'redux-observable'
import { catchError, filter, map, mergeMap, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { from, of } from 'rxjs'
import { dismissAnnouncementForGood, getDismissedAnnouncementsList } from '../utils/storageUtils'

export const fetchAnnouncementEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_ANNOUNCEMENTS),
    pluck('payload'),
    filter(() => !isMobile()),
    switchMap(doctorId =>
      from(API.get('grinApi', '/ops/v1/announcements')).pipe(
        map(({ announcements }) =>
          announcements
            .filter(announcement => !getDismissedAnnouncementsList().includes(announcement.id))
            .map(announcement =>
              announcement.trigger === 'startup'
                ? { ...announcement, content: JSON.parse(announcement.content), isOpen: true }
                : null
            )
        ),
        mergeMap(announements => of(Actions.announcementsReceived(announements))),
        catchError(err => of(Actions.fetchAnnouncementsFailed(err)))
      )
    )
  )

export const dismissAnnouncementOnceEpic = action$ =>
  action$.pipe(
    ofType(Actions.DISMISS_ANNOUNCEMENT),
    pluck('payload'),
    filter(({ dontShowAgain }) => !dontShowAgain),
    mergeMap(({ announcementId }) => of(Actions.toggleAnnouncementOpen(announcementId)))
  )

export const dismissAnnouncementForGoodEpic = action$ =>
  action$.pipe(
    ofType(Actions.DISMISS_ANNOUNCEMENT),
    pluck('payload'),
    filter(({ dontShowAgain }) => dontShowAgain),
    tap(({ announcementId }) => dismissAnnouncementForGood(announcementId)),
    mergeMap(({ announcementId }) => of(Actions.toggleAnnouncementOpen(announcementId)))
  )

export const submitSurveyResult = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SUBMIT_SURVEY_RESULT),
    withLatestFrom(state$),
    map(([action, state]) => {
      const { surveyResult, surveyType } = action?.payload
      const { doctor } = state?.profileReducer
      const { accountOwner } = state?.practiceReducer
      return {
        type: surveyType,
        responderId: doctor?.id,
        responderAccountOwnerId: accountOwner?.id ?? doctor?.id,
        a_doctor: doctor.username,
        content: surveyResult
      }
    }),
    switchMap(input =>
      from(
        API.graphql(
          graphqlOperation(createSurveyResult, {
            input
          })
        )
      ).pipe(
        mergeMap(() => of(Actions.submitSurveyResultReceived())),
        catchError(err => of(Actions.submitSurveyResultFailed()))
      )
    )
  )
