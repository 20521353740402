import React, { useState, useCallback } from 'react'
import ChangeAddressModal from '../../../ChangeAddressModal'
import GrayActionBox from '../../GrayActionBox'
import EditIcon from '../../icons/EditV2'
import DazzedParagraph16 from '../../text/DazzedParagraph16'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { toAddressString } from 'utils/generalUtils'

const useStyles = makeStyles({
  address: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})

const BillingAddressPicker = ({ label, address, setAddress }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)

  const onCloseRequested = useCallback(() => setIsAddressModalOpen(false), [])

  return (
    <>
      <GrayActionBox
        label={label == null ? t('creditCard.billingAddress') : label}
        onAction={() => setIsAddressModalOpen(true)}
        actionIcon={<EditIcon />}
      >
        <DazzedParagraph16 className={classes.address}>{toAddressString(address)}</DazzedParagraph16>
      </GrayActionBox>
      <ChangeAddressModal
        isOpen={isAddressModalOpen}
        address={address}
        onChangeRequested={setAddress}
        onCloseRequested={onCloseRequested}
      />
    </>
  )
}

export default BillingAddressPicker
