import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import Title from '../Title'
import ColorPicker from '../../../../../common/ColorPicker/ColorPicker'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 10,
    flexWrap: 'noWrap',
    paddingBottom: 15,
    borderBottom: '1px solid var(--border-color-14)'
  },
  fontColorContainer: {
    paddingLeft: 25
  }
}))

const BasicColors = ({
  handleBgColor = () => {},
  handleBgColorOptions = () => {},
  handleFontColor = () => {},
  handleFontColorOptions = () => {},
  suggestedFontColors = [],
  suggestedBgColors = [],
  selectedFontColor = '',
  selectedBgColor = ''
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid container className={classes.container}>
      <Grid>
        <Grid item>
          <Title
            label={t('pages.accountSettings.services.remoteConsultation.themeColors.headerBackgroundColorTitle')}
            optional
          />
        </Grid>
        <Grid item>
          <ColorPicker
            suggestedColors={suggestedBgColors}
            selectedColor={selectedBgColor}
            handleColor={handleBgColor}
            handleOptions={handleBgColorOptions}
          />
        </Grid>
      </Grid>
      <Grid className={classes.fontColorContainer}>
        <Grid item>
          <Title label={t('pages.accountSettings.services.remoteConsultation.themeColors.fontColorTitle')} optional />
        </Grid>
        <Grid item>
          <ColorPicker
            suggestedColors={suggestedFontColors}
            selectedColor={selectedFontColor}
            handleColor={handleFontColor}
            handleOptions={handleFontColorOptions}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BasicColors
