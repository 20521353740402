import { getEnvironment } from './awsUtils'
import * as Stripe from '@stripe/stripe-js'

let stripePromise

export const getStripeKey = () => {
  const env = getEnvironment()
  if (env === 'production') {
    return 'pk_live_NumMCwOjFX7FLLKeuGDksXDd00zMT6QvNy'
  } else {
    return 'pk_test_3chJ0YdkTU0WgFH5C2btW9fQ00AQDMWXUS'
  }
}

export const loadStripe = () => {
  if (!stripePromise) {
    stripePromise = Stripe.loadStripe(getStripeKey())
  }

  return stripePromise
}

export const createPaymentMethod = async ({ element, cardHolderName, address }) => {
  const stripe = await loadStripe()
  const stripePaymentMethod = await stripe.createPaymentMethod({
    type: 'card',
    card: element,
    billing_details: {
      name: cardHolderName,
      address
    }
  })
  return stripePaymentMethod
}
