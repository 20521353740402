import { Flag } from 'components/common/icons'
import { ROUTES } from 'consts'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { selectCustomTemplates, selectSystemTemplates } from 'selectors/profileSelector'
import { trackEvent } from 'utils/analyticsUtils'
import Templates from '../Templates/Templates'
import SavedRepliesEmptyState from './SavedRepliesEmptyState'
import SavedRepliesList from './SavedRepliesList'
import SelectedSavedReply from './SelectedSavedReply'

const SavedReplies = ({ id = '', onTemplateApplied, setInputFocus, setShowDropzone }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const customTemplates = useSelector(selectCustomTemplates)
  const systemTemplates = useSelector(selectSystemTemplates)
  const templates = useMemo(() => (systemTemplates ?? []).concat(customTemplates), [systemTemplates, customTemplates])

  const handleOpenTemplatesLink = useCallback(() => {
    trackEvent('Saved replies - modal redirected to settings screen')
    history.push(ROUTES.CUSTOM_MESSAGES, { focus: 'savedReplies' })
  }, [history])

  const renderIcon = useCallback(() => <Flag fontSize="small" />, [])
  const renderSelectedReply = useCallback(
    (selectedTemplate, onButtonClick) => (
      <SelectedSavedReply
        selectedReply={selectedTemplate}
        onButtonClick={() => {
          trackEvent('Saved replies - reply selected', {
            savedReplyType: selectedTemplate.type,
            title: selectedTemplate.title
          })
          onButtonClick()
        }}
      />
    ),
    []
  )

  const renderEmptyState = useCallback(() => <SavedRepliesEmptyState />, [])

  const handleOpenAction = useCallback(() => {
    setShowDropzone(false)
  }, [setShowDropzone])

  return (
    <Templates
      id={id}
      onTemplateApplied={onTemplateApplied}
      onSetInputFocus={setInputFocus}
      templates={templates}
      renderIcon={renderIcon}
      onOpenTemplatesLink={handleOpenTemplatesLink}
      searchPlaceholder={t('pages.patients.selectedPatient.chat.templates.searchPlaceholder')}
      renderSelectedTemplate={renderSelectedReply}
      renderEmptyState={renderEmptyState}
      title={t('pages.patients.selectedPatient.chat.templates.title')}
      tooltipValue={t('pages.patients.selectedPatient.chat.templates.title')}
      renderList={props => <SavedRepliesList {...props} />}
      withActions
      width={560}
      onOpenCallback={handleOpenAction}
    />
  )
}

export default SavedReplies
