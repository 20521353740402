import * as React from 'react'

const Picture = props => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30 26.6667V3.33333C30 1.5 28.5 0 26.6667 0H3.33333C1.5 0 0 1.5 0 3.33333V26.6667C0 28.5 1.5 30 3.33333 30H26.6667C28.5 30 30 28.5 30 26.6667ZM9.16667 17.5L13.3333 22.5167L19.1667 15L26.6667 25H3.33333L9.16667 17.5Z"
        fill="#3E5477"
      />
    </svg>
  )
}

export default Picture
