import * as React from 'react'

export default ({ className }) => {
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M42 83C64.6437 83 83 64.6437 83 42C83 19.3563 64.6437 1 42 1C19.3563 1 1 19.3563 1 42C1 64.6437 19.3563 83 42 83Z"
        stroke="#86E18A"
        strokeWidth="2"
      />
      <path d="M25 42L37 54L62 29" stroke="#86E18A" strokeWidth="4" strokeLinecap="square" />
    </svg>
  )
}
