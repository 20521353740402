import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { SmallSearch as SmallSearchIcon } from 'components/common/icons'
import NewTemplateModal from './NewTemplateModal'
import CreateNewButton from './CreateNewButton'
import { scrollBar } from 'styles/theme'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = () =>
  makeStyles({
    container: {
      overflow: 'hidden',
      borderRight: '1px solid var(--border-color-11)',
      height: '100%',
      width: '100%',
      paddingTop: 13,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch'
    },
    searchSection: {
      flex: 1
    },
    inputContainer: {
      borderRadius: 28,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'var(--bg-color-36)',
      padding: '7px 15px',
      margin: '0 5px 5px',
      '& svg': {
        marginRight: 5
      }
    },
    searchInput: {
      fontFamily: 'Dazzed',
      fontSize: 14,
      background: 'transparent',
      border: 'none',
      width: '100%',
      '&:focus': {
        outline: 'none'
      }
    },
    listContainer: {
      flex: 9,
      maxWidth: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
      ...scrollBar
    }
  })()

const TemplatesList = ({
  templates,
  selected,
  setSelected,
  onDeleteTemplate,
  searchPlaceholder,
  isCreatable = true,
  isSearchable,
  renderList,
  autoFocus = true
}) => {
  const classes = useStyles()
  const [filter, setFilter] = useState('')
  const [isNewTemplateModalOpen, setIsNewTemplateModalOpen] = useState(false)

  const filterTemplates = useCallback(
    (type, templates) =>
      templates
        ?.filter(
          template =>
            template?.type === type && template?.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
        )
        .sort((a, b) => a.title.localeCompare(b.title)) || [],
    [filter]
  )

  const handleNewTemplate = useCallback(() => {
    trackEvent('Saved replies - Create new saved reply clicked')
    setIsNewTemplateModalOpen(true)
  }, [setIsNewTemplateModalOpen])

  return (
    <>
      <div className={classes.container}>
        {isSearchable && (
          <div className={classes.searchSection}>
            <div className={classes.inputContainer}>
              <SmallSearchIcon />
              <input
                autoFocus={autoFocus}
                className={classes.searchInput}
                type="text"
                placeholder={searchPlaceholder}
                value={filter}
                onChange={({ target }) => {
                  setFilter(target.value)
                }}
                onKeyDown={e => e.stopPropagation()}
              />
            </div>
          </div>
        )}
        <div className={classes.listContainer}>
          {renderList({ templates, selected, setSelected, onDeleteTemplate, filterTemplates })}
        </div>
        {isCreatable && (
          <div>
            <CreateNewButton onClick={handleNewTemplate} />
          </div>
        )}
      </div>
      <NewTemplateModal isOpen={isNewTemplateModalOpen} onClose={() => setIsNewTemplateModalOpen(false)} />
    </>
  )
}

export default TemplatesList
