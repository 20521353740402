import * as React from 'react'

export default () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6985 1.63219C9.39064 1.63219 8.54502 0.465002 5.76361 1.30251C2.63203 2.24546 -0.531929 10.487 1.8132 13.501C3.12131 15.1822 4.97192 15.102 7.41251 15.9737C9.72033 16.7979 11.6985 16.7979 13.1821 16.3034C14.6391 15.8177 15.6047 13.6658 16.1493 13.0066C19.2813 9.21503 19.9182 4.56375 17.6329 2.62124C14.9333 0.326615 13.1821 1.63219 11.6985 1.63219Z"
        stroke="#4151E6"
      />
      <path
        d="M4.29675 8.22648C4.57149 8.28143 5.28582 8.35835 5.9452 8.22648C6.76942 8.06163 7.59365 7.23741 8.25303 7.23741C8.78053 7.23741 10.6707 7.78689 11.5499 8.06163M11.5499 8.06163C12.5939 7.40225 15.0776 6.0835 16.6601 6.0835M11.5499 8.06163C11.9895 8.88586 13.2643 10.7321 14.8468 11.5234"
        stroke="#4151E6"
        strokeWidth="0.75"
      />
      <path d="M4.46338 6.0835C5.12276 6.41319 6.11183 7.43522 6.11183 8.22648" stroke="#4151E6" strokeWidth="0.75" />
      <path
        d="M7.75897 12.0173C7.92381 12.6767 8.2535 13.6658 8.74803 14.1603C8.41834 14.6548 7.56115 15.8088 6.7699 15.8088"
        stroke="#4151E6"
        strokeWidth="0.75"
      />
      <path
        d="M11.0564 5.09448C11.0564 5.09448 10.6597 5.5089 10.5617 5.75386C10.2323 6.57811 10.562 7.732 10.562 7.732"
        stroke="#4151E6"
        strokeWidth="0.75"
      />
      <path
        d="M15.2326 14.4298C18.0362 10.4582 19.6714 6.72026 18.5038 4.38403C18.281 6.83109 16.152 5.4652 11.9149 7.7496C11.5056 7.97027 11.2612 8.40654 11.2614 8.87152C11.2614 8.99423 11.2746 9.11839 11.3252 9.23017C11.9822 10.6803 15.2327 10.6047 15.2326 14.4298Z"
        fill="#4151E6"
      />
    </svg>
  )
}
