import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useInvitePatientData from 'components/Profile/InvitePatientModal/useInvitePatientData'
import useHideHelpWidget from 'hooks/useHideHelpWidget'
import useCssClasses from 'hooks/useCssClasses'

import { MailSuccess, MailFailed } from 'components/common/icons'
import DazzedHeading24 from 'components/common/text/DazzedHeading24'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import BaseModal from 'components/common/modals/BaseModal'
import Actions from 'actions'

const useStyles = makeStyles({
  popupBody: {
    textAlign: 'center',
    minHeight: 300
  },
  popupIcon: {
    textAlign: 'center',
    marginTop: 20
  },
  popupTitle: {
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 10
  },
  popupBody1: {
    textAlign: 'center',
    maxWidth: '80%',
    margin: '0 auto'
  },
  popupBody2: {
    textAlign: 'center',
    maxWidth: '60%',
    margin: '0 auto',
    marginTop: 15
  }
})

const InvitationResultModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  useHideHelpWidget()
  useCssClasses(true)

  const { doctorId, clearFields } = useInvitePatientData()

  const { mobilePopup } = useSelector(state => state.patientsReducer.invite)

  const handleClosePopup = useCallback(() => {
    dispatch(Actions.clearInviteModalPopupProps())
  }, [dispatch])

  useEffect(() => {
    if (mobilePopup?.type === 'success') {
      clearFields()
    }
  }, [clearFields, mobilePopup])

  if (!doctorId) return null

  return (
    <BaseModal
      open={mobilePopup.isOpen}
      largerButtons
      variant="alert"
      onPrimaryBtnClick={handleClosePopup}
      primaryLabel={t('dialogs.invitePatient.mobilePopup.goToInbox')}
      handleClose={handleClosePopup}
    >
      <div className={classes.popupBody}>
        {mobilePopup?.type === 'success' ? (
          <MailSuccess className={classes.popupIcon} />
        ) : (
          <MailFailed className={classes.popupIcon} />
        )}
        <DazzedHeading24 className={classes.popupTitle}>
          {mobilePopup?.type === 'success'
            ? t('dialogs.invitePatient.mobilePopup.invitationSent')
            : t('dialogs.invitePatient.mobilePopup.existingUser')}
        </DazzedHeading24>
        <DazzedParagraph14 strong className={classes.popupBody1} textAlign="center">
          {mobilePopup?.type === 'success'
            ? t('dialogs.invitePatient.mobilePopup.successBody1', { patientName: mobilePopup?.patientName })
            : t('dialogs.invitePatient.mobilePopup.userExistBody1', {
                patientName: mobilePopup?.patientName
              })}
        </DazzedParagraph14>
        <DazzedParagraph14 className={classes.popupBody2} textAlign="center">
          {mobilePopup?.type === 'success'
            ? t('dialogs.invitePatient.mobilePopup.successBody2')
            : t('dialogs.invitePatient.mobilePopup.userExistBody2')}
        </DazzedParagraph14>
      </div>
    </BaseModal>
  )
}

export default InvitationResultModal
