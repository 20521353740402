import * as React from 'react'

const ArchViewerLeft = () => (
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 7C0 3.13401 3.13401 0 7 0H16.2838C17.3203 0 18.2524 0.63107 18.6374 1.59347C18.8769 2.19232 19 2.83138 19 3.47636V7V10.5236C19 11.1686 18.8769 11.8077 18.6374 12.4065C18.2524 13.3689 17.3203 14 16.2838 14H7C3.13401 14 0 10.866 0 7Z"
      fill="#4151E6"
    />
    <path d="M15 5C15 3.89543 15.8954 3 17 3V3C18.1046 3 19 3.89543 19 5V7H15V5Z" stroke="#EFF6FF" />
    <path d="M15 9C15 10.1046 15.8954 11 17 11V11C18.1046 11 19 10.1046 19 9V7H15V9Z" stroke="#EFF6FF" />
    <path d="M11 5C11 3.89543 11.8954 3 13 3V3C14.1046 3 15 3.89543 15 5V7H11V5Z" stroke="#EFF6FF" />
    <path d="M11 9C11 10.1046 11.8954 11 13 11V11C14.1046 11 15 10.1046 15 9V7H11V9Z" stroke="#EFF6FF" />
    <path d="M7 5C7 3.89543 7.89543 3 9 3V3C10.1046 3 11 3.89543 11 5V7H7V5Z" stroke="#EFF6FF" />
    <path d="M7 9C7 10.1046 7.89543 11 9 11V11C10.1046 11 11 10.1046 11 9V7H7V9Z" stroke="#EFF6FF" />
    <path d="M3 6C3 4.89543 3.89543 4 5 4V4C6.10457 4 7 4.89543 7 6V7H3V6Z" stroke="#EFF6FF" />
    <path d="M3 8C3 9.10457 3.89543 10 5 10V10C6.10457 10 7 9.10457 7 8V7H3V8Z" stroke="#EFF6FF" />
  </svg>
)

export default ArchViewerLeft
