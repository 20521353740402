import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from '../../../../../common/text/DazzedParagraph16'
import OggHeading40 from '../../../../../common/text/OggHeading40'
import Oval from '../../../../../common/icons/Oval'

const useStyles = makeStyles(theme => ({
  root: {
    width: '70%'
  },
  title: {
    lineHeight: 3,
    fontSize: 7,
    color: ({ color }) => color
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  image: {
    height: '45px',
    zIndex: 2,
    marginRight: 10
  },
  textContainer: {
    flex: 1.2
  },
  patientQuote: {
    fontSize: 5,
    lineHeight: '8px',
    color: ({ color }) => color
  },
  patientName: {
    fontSize: 3,
    lineHeight: '28.8px',
    color: ({ color }) => color
  },
  oval: {
    position: 'absolute',
    left: 45,
    bottom: -3,
    zIndex: -1,
    width: 20
  }
}))

const PatientRecommendation = ({ color = '', accentColor = '' }) => {
  const { t } = useTranslation()
  const classes = useStyles({ color })

  return (
    <div className={classes.root}>
      <OggHeading40 className={classes.title}>{t('pages.rcLanding.body.patientReview.title')}</OggHeading40>
      <div className={classes.content}>
        <div>
          <img
            src="https://d1g4lo0dz8n4nh.cloudfront.net/images/rc-landing-page/patient-review-image.png"
            alt="Recommendation"
            className={classes.image}
          />
          <Oval diameter={134} color={accentColor} className={classes.oval} />
        </div>
        <div className={classes.textContainer}>
          <DazzedParagraph16 className={classes.patientQuote}>
            {t('pages.rcLanding.body.patientReview.quote')}
          </DazzedParagraph16>
          <DazzedParagraph16 className={classes.patientName}>
            {t('pages.rcLanding.body.patientReview.patientName')}
          </DazzedParagraph16>
        </div>
      </div>
    </div>
  )
}

export default PatientRecommendation
