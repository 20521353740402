import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { isMobile } from 'react-device-detect'
import PaintWaves from '../../common/icons/rc-landing-page/PaintWaves'
import useRcDetails from '../useRcDetails'

const useStyles = makeStyles(
  theme => ({
    root: {
      margin: ({ isMobile }) => (isMobile ? '100px auto' : 0),
      textAlign: 'center',
      position: 'relative',
      height: ({ withBackground }) => (withBackground ? 620 : 'auto')
    },
    video: {
      width: ({ isMobile }) => (isMobile ? '100%' : 897),
      height: ({ isMobile }) => (isMobile ? 'auto' : 502),
      zIndex: 2,
      position: 'relative'
    },
    paintWaves: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 0,
      right: 0,
      zIndex: 1
    }
  }),
  { isMobile }
)

const PatientsOnboardingVideo = ({ withBackground = false, colors = {} }) => {
  const classes = useStyles({ isMobile, withBackground })
  const doctorDetails = useRcDetails()

  return (
    <div className={classes.root}>
      <video
        src="https://d1g4lo0dz8n4nh.cloudfront.net/images/rc-landing-page/Grin_Patient_Onboarding_07.mp4"
        controls
        poster={
          isMobile
            ? 'https://d1g4lo0dz8n4nh.cloudfront.net/images/rc-landing-page/video-thumbnail-450X251.jpeg'
            : 'https://d1g4lo0dz8n4nh.cloudfront.net/images/rc-landing-page/video-thumbnail-1000X560.jpeg'
        }
        muted={false}
        className={classes.video}
      />
      {withBackground && (
        <PaintWaves
          color={doctorDetails.colorSettings.footerBackgroundColor || doctorDetails.colorSettings.primary}
          className={classes.paintWaves}
        />
      )}
    </div>
  )
}

export default PatientsOnboardingVideo
