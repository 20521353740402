import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  sendOptionLabel: {
    color: 'var(--text-color-6)'
  }
}))

const SendOption = ({ icon, label }) => {
  const classes = useStyles()

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>{icon}</Grid>
      <Grid item>
        <DazzedParagraph14 className={classes.sendOptionLabel}>{label}</DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default SendOption
