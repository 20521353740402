import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { CardMedia } from '@material-ui/core'
import Play from 'components/common/icons/Play'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    position: 'relative',
    padding: 4,
    margin: 2,
    '&.clickable': {
      cursor: 'pointer'
    },
    '&.selected': {
      border: '1px solid var(--border-color-30)',
      borderRadius: '12px'
    }
  },
  tileOption: {
    borderRadius: 12,
    objectFit: 'contain'
  },
  videoOverlay: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%'
  }
}))

const AutochartScanTile = ({ src, mediaType, withOverlay = true, mediaProps = {}, onClick, isSelected = false }) => {
  const classes = useStyles()

  return (
    <div
      className={[classes.root, onClick ? 'clickable' : '', isSelected ? 'selected' : ''].join(' ')}
      onClick={onClick}
    >
      {withOverlay && mediaType === 'video' && (
        <div className={classes.videoOverlay}>
          <Play color="#fff" />
        </div>
      )}
      <CardMedia component={mediaType} src={src} className={classes.tileOption} {...mediaProps} />
    </div>
  )
}

export default AutochartScanTile
