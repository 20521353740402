import * as React from 'react'

export default () => {
  return (
    <svg width="55" height="28" viewBox="0 0 55 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 1C6.8203 1 1 6.8203 1 14C1 21.1797 6.8203 27 14 27H41C48.1797 27 54 21.1797 54 14C54 6.8203 48.1797 1 41 1H14Z"
        fill="#7F5FF9"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M12.3825 8.37885L12.66 8.34939L12.7718 8.11627C13.587 6.41533 16.0088 6.41533 16.824 8.11627L16.9358 8.34939V20.0701L16.624 20.4671C15.4953 21.9049 13.2143 21.4701 12.6935 19.7179L11.5489 19.5453C10.0057 19.3126 9.11498 17.6667 9.76426 16.2475L10.3628 14.9391L10.2364 14.8137C9.22866 13.814 9.43135 12.1327 10.6479 11.4011L10.5909 11.2436C10.1167 9.93354 10.997 8.52591 12.3825 8.37885Z"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M14.9749 21.3464C13.6283 21.3464 12.5367 20.2548 12.5367 18.9082C12.5367 18.1105 12.9198 17.4023 13.512 16.9575"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M14.2441 12.2026C13.5962 11.8285 13.3743 11.0002 13.7483 10.3523C13.9699 9.96855 14.3509 9.73423 14.7593 9.68472"
        stroke="white"
        strokeLinecap="round"
      />
      <path d="M10.3776 14.8436C10.7764 14.1528 11.4622 13.731 12.1975 13.6418" stroke="white" strokeLinecap="round" />
      <path d="M16.9319 4.5V24.1141" stroke="white" strokeLinecap="round" strokeDasharray="2.08 2.08" />
      <path d="M21.4463 7.70898L19.0155 10.1398H16.9319" stroke="white" />
      <path d="M21.4463 20.9053L19.0155 18.4744H16.9319" stroke="white" />
      <path d="M24.2245 14.3071H16.9319" stroke="white" />
      <path d="M24.2245 10.4873H21.7936L19.71 12.5709H16.9319" stroke="white" />
      <path d="M24.2245 18.127H21.7936L19.71 16.0434H16.9319" stroke="white" />
      <circle cx="21.4461" cy="7.70903" r="1.0418" fill="#7F5FF9" stroke="white" />
      <circle cx="21.4461" cy="20.9051" r="1.0418" fill="#7F5FF9" stroke="white" />
      <circle cx="24.2195" cy="10.4871" r="1.0418" fill="#7F5FF9" stroke="white" />
      <circle cx="24.2195" cy="18.127" r="1.0418" fill="#7F5FF9" stroke="white" />
      <circle cx="24.2195" cy="14.3072" r="1.0418" fill="#7F5FF9" stroke="white" />
      <circle cx="41" cy="14" r="10" fill="#EB5757" />
      <path d="M40.001 8.66675H42.001V16.0001H40.001V8.66675Z" fill="white" />
      <path d="M40.001 18H42.001V20H40.001V18Z" fill="white" />
    </svg>
  )
}
