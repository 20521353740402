import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { daysAgo } from '../../../../utils/stringUtils'
import actions from '../../../../actions'
import { useTranslation } from 'react-i18next'
import DazzedHeading16 from '../../../common/text/DazzedHeading16'

const ScanReviewItem = ({ item }) => {
  const classes = makeStyles({
    container: {
      paddingLeft: 0
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      fontSize: '12px',
      fontWeight: 500
    },
    text: {
      marginLeft: 5,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 'normal',
      color: 'var(--text-color-11)',
      opacity: 0.7
    },
    reviewText: {
      display: 'inline',
      marginLeft: '0px',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 0,
      color: 'var(--text-color-11)',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    date: {
      marginLeft: 0,
      whiteSpace: 'nowrap'
    }
  })()

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const doctor = useSelector(state => state.profileReducer.doctor)
  const currentPatientDoctor = useSelector(state => state.patientsReducer.patient?.doctor)

  const viewScan = useCallback(() => {
    dispatch(
      actions.openScanViewDialog({
        s3Object: item.video,
        isLocal: item.isLocal,
        cache: item.isLocal ? item.cache : null,
        type: 'scanReview'
      })
    )
  }, [dispatch, item.video, item.cache, item.isLocal])

  const sentByLabel = useMemo(() => {
    if (doctor?.id === item.reviewer?.id || (!item.reviewer && doctor?.id === currentPatientDoctor?.id)) {
      return t('pages.patients.selectedPatient.timeline.reviewSentTimeLabel2_self')
    } else {
      const reviewerName = item.reviewer?.name || currentPatientDoctor?.name
      return t('pages.patients.selectedPatient.timeline.reviewSentTimeLabel2_other', { name: reviewerName })
    }
  }, [doctor, item, currentPatientDoctor, t])

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <DazzedHeading16>
          <span onClick={viewScan} className={classes.reviewText}>
            {t('pages.patients.selectedPatient.timeline.reviewSentTimeLabel1')}
          </span>
          <span className={classes.text}>{sentByLabel} </span>
          <span className={[classes.text, classes.date].join(' ')}>
            {daysAgo({ date: item.createdAt, format: 'MMM Do, HH:mm A' })}
          </span>
        </DazzedHeading16>
      </div>
    </div>
  )
}

export default ScanReviewItem
