import moment from 'moment'
import { API, graphqlOperation } from 'aws-amplify'
import { ofType } from 'redux-observable'
import { empty, from, of } from 'rxjs'
import { catchError, map, mergeMap, pluck, switchMap, withLatestFrom } from 'rxjs/operators'
import Actions from '../actions'
import i18n from 'resources/locales/i18n'
import { updateGrinUser } from 'graphql/mutations'
import { logError } from 'utils/logUtils'
import { trackEvent } from 'utils/analyticsUtils'
import { searchPatientSearchModelsForPmsRedirect } from 'graphql/customQueries'
import { ROUTES } from 'consts'
import { getAccessToken } from 'utils/storageUtils'

export const pmsAuthenticationEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PMS_AUTHENTICATION),
    pluck('payload'),
    mergeMap(({ pmsType }) =>
      from(API.get('grinServerlessApi', `/pms/v1/${pmsType}/auth`)).pipe(
        mergeMap(data => of(Actions.pmsAuthenticationReceived(data))),
        catchError(err => of(Actions.pmsAuthenticationFailed(err)))
      )
    )
  )

export const pmsAuthenticationReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PMS_AUTHENTICATION_RECEIVED),
    withLatestFrom(state$),
    switchMap(([{ payload }, { pmsReducer }]) => {
      localStorage.setItem(`${pmsReducer.pmsType}_token`, payload.access_token)

      return empty()
    })
  )

export const pmsFetchPatientsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PMS_FETCH_PATIENTS),
    withLatestFrom(state$),
    switchMap(([{ payload: searchValue }, { pmsReducer }]) =>
      from(
        API.get('grinServerlessApi', `/pms/v1/${pmsReducer.pmsType}/patients?searchInput=${searchValue}`, {
          headers: {
            'x-api-key': getAccessToken(),
            'pms-token': localStorage.getItem(`${pmsReducer.pmsType}_token`)
          }
        })
      ).pipe(
        mergeMap(({ patients }) => of(Actions.pmsFetchPatientsReceived({ patients }))),
        catchError(err => of(Actions.pmsFetchPatientsFailed(err)))
      )
    )
  )

export const pmsRequestFailedEpic = action$ =>
  action$.pipe(
    ofType(
      Actions.PMS_FETCH_PATIENTS_FAILED,
      Actions.PMS_LINK_PATIENT_FAILED,
      Actions.PMS_UNLINK_PATIENT_FAILED,
      Actions.PMS_FETCH_PATIENT_FAILED,
      Actions.PMS_FETCH_APPOINTMENTS_FAILED,
      Actions.PMS_FETCH_AVAILABLE_TIME_SLOTS_FAILED,
      Actions.PMS_CREATE_NEW_APPOINTMENT_FAILED,
      Actions.PMS_DELETE_APPOINTMENT_FAILED,
      Actions.PMS_FETCH_STATIC_DATA_FAILED,
      Actions.PMS_AUTHENTICATION_FAILED
    ),
    map(({ payload: error }) => {
      logError('An error occured while integrating with pms', error)

      const errorData = error?.response?.data

      return Actions.showSnackbar({
        type: 'error',
        text:
          errorData?.code === 'PMS_DOWN'
            ? i18n.t('dialogs.patientInfo.appointments.pms.dolphinDownError')
            : i18n.t('messages.somethingWentWrongContactSupport')
      })
    })
  )

export const pmsLinkPatientEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PMS_LINK_PATIENT),
    withLatestFrom(state$),
    map(([{ payload }, { patientsReducer, pmsReducer }]) => {
      const patientGrinUser = patientsReducer.patientCard.patient.user

      return {
        patientGrinUser,
        patientExternalIds: JSON.parse(patientGrinUser.externalIds || '{}'),
        pmsPatientId: payload.pmsPatientId,
        pmsType: payload.pmsType,
        isSilent: !!payload?.isSilent
      }
    }),
    switchMap(({ patientGrinUser, pmsPatientId, pmsType, patientExternalIds, isSilent }) =>
      from(
        API.graphql(
          graphqlOperation(updateGrinUser, {
            input: {
              id: patientGrinUser.id,
              _version: patientGrinUser._version,
              externalIds: JSON.stringify({ ...patientExternalIds, [pmsType]: pmsPatientId })
            }
          })
        )
      ).pipe(
        mergeMap(response => of(Actions.pmsLinkPatientReceived({ user: response.data.updateGrinUser, isSilent }))),
        catchError(err => of(Actions.pmsLinkPatientFailed(err)))
      )
    )
  )

export const pmsLinkPatientReceviedEpic = action$ =>
  action$.pipe(
    ofType(Actions.PMS_LINK_PATIENT_RECEIVED),
    switchMap(({ payload }) => {
      if (!payload.isSilent) {
        return of(
          Actions.showSnackbar({
            type: 'success',
            text: i18n.t('pms.linkedPatientSuccessMessage')
          })
        )
      }

      return empty()
    })
  )

export const pmsUnlinkPatientEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PMS_UNLINK_PATIENT),
    withLatestFrom(state$),
    map(([{ payload }, { patientsReducer }]) => {
      const patientGrinUser = patientsReducer.patientCard.patient.user
      let patientExternalIds = JSON.parse(patientGrinUser.externalIds || '{}')

      delete patientExternalIds[payload.pmsType]

      return {
        patientGrinUser,
        patientExternalIds: JSON.stringify(patientExternalIds),
        pmsType: payload.pmsType
      }
    }),
    switchMap(({ patientGrinUser, pmsType, patientExternalIds }) =>
      from(
        API.graphql(
          graphqlOperation(updateGrinUser, {
            input: {
              id: patientGrinUser.id,
              _version: patientGrinUser._version,
              externalIds: patientExternalIds
            }
          })
        )
      ).pipe(
        mergeMap(response => of(Actions.pmsUnlinkPatientReceived(response.data.updateGrinUser))),
        catchError(err => of(Actions.pmsUnlinkPatientFailed(err)))
      )
    )
  )

export const pmsFetchAppointmentsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PMS_FETCH_APPOINTMENTS),
    withLatestFrom(state$),
    switchMap(([{ payload }, { pmsReducer }]) =>
      from(
        API.get('grinServerlessApi', `/pms/v1/${pmsReducer.pmsType}/appointments?patientId=${payload}`, {
          headers: {
            'x-api-key': getAccessToken(),
            'pms-token': localStorage.getItem(`${pmsReducer.pmsType}_token`)
          }
        })
      ).pipe(
        mergeMap(({ appointments }) => of(Actions.pmsFetchAppointmentsReceived({ appointments }))),
        catchError(err => of(Actions.pmsFetchAppointmentsFailed(err)))
      )
    )
  )

export const pmsFetchPatientEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PMS_FETCH_PATIENT),
    withLatestFrom(state$),
    switchMap(([{ payload: patientId }, { pmsReducer }]) =>
      from(
        API.get('grinServerlessApi', `/pms/v1/${pmsReducer.pmsType}/patients/${patientId}`, {
          headers: {
            'x-api-key': getAccessToken(),
            'pms-token': localStorage.getItem(`${pmsReducer.pmsType}_token`)
          }
        })
      ).pipe(
        mergeMap(({ patient, appointments }) => of(Actions.pmsFetchPatientReceived({ patient, appointments }))),
        catchError(err => of(Actions.pmsFetchPatientFailed(err)))
      )
    )
  )

export const pmsFetchAvailableTimeSlotsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PMS_FETCH_AVAILABLE_TIME_SLOTS),
    withLatestFrom(state$),
    switchMap(([{ payload: queryParams }, { pmsReducer }]) =>
      from(
        API.get('grinServerlessApi', `/pms/v1/${pmsReducer.pmsType}/appointments/timeslots?${queryParams}`, {
          headers: {
            'x-api-key': getAccessToken(),
            'pms-token': localStorage.getItem(`${pmsReducer.pmsType}_token`)
          }
        })
      ).pipe(
        mergeMap(({ availableTimeSlots }) => of(Actions.pmsFetchAvailableTimeSlotsReceived({ availableTimeSlots }))),
        catchError(err => of(Actions.pmsFetchAvailableTimeSlotsFailed(err)))
      )
    )
  )

export const pmsCreateNewAppointmentEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PMS_CREATE_NEW_APPOINTMENT),
    withLatestFrom(state$),
    switchMap(([{ payload: appointmentData }, { pmsReducer }]) =>
      from(
        API.post('grinServerlessApi', `/pms/v1/${pmsReducer.pmsType}/appointments`, {
          body: {
            appointmentData
          },
          headers: {
            'x-api-key': getAccessToken(),
            'pms-token': localStorage.getItem(`${pmsReducer.pmsType}_token`)
          }
        })
      ).pipe(
        mergeMap(({ appointment }) => of(Actions.pmsCreateNewAppointmentReceived({ appointment }))),
        catchError(err => of(Actions.pmsCreateNewAppointmentFailed(err)))
      )
    )
  )

export const pmsCreateNewAppointmentReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.PMS_CREATE_NEW_APPOINTMENT_RECEIVED),
    map(() =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('pms.newAppointmentSuccessMessage')
      })
    )
  )

export const pmsDeleteAppointmentEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PMS_DELETE_APPOINTMENT),
    withLatestFrom(state$),
    switchMap(([{ payload: appointmentId }, { pmsReducer }]) =>
      from(
        API.del('grinServerlessApi', `/pms/v1/${pmsReducer.pmsType}/appointments/${appointmentId}`, {
          headers: {
            'x-api-key': getAccessToken(),
            'pms-token': localStorage.getItem(`${pmsReducer.pmsType}_token`)
          }
        })
      ).pipe(
        mergeMap(() => of(Actions.pmsDeleteAppointmentReceived())),
        catchError(err => of(Actions.pmsDeleteAppointmentFailed(err)))
      )
    )
  )

export const pmsDeleteAppointmentReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.PMS_DELETE_APPOINTMENT_RECEIVED),
    map(() =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('pms.deleteAppointmentSuccessMessage')
      })
    )
  )

export const pmsFetchStaticDataEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PMS_FETCH_STATIC_DATA),
    withLatestFrom(state$),
    switchMap(([, { pmsReducer }]) =>
      from(
        API.get('grinServerlessApi', `/pms/v1/${pmsReducer.pmsType}/staticData`, {
          headers: {
            'x-api-key': getAccessToken(),
            'pms-token': localStorage.getItem(`${pmsReducer.pmsType}_token`)
          }
        })
      ).pipe(
        mergeMap(staticData => of(Actions.pmsFetchStaticDataReceived(staticData))),
        catchError(err => of(Actions.pmsFetchStaticDataFailed(err)))
      )
    )
  )

export const pmsAutomaticLinkingAttemptEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PMS_AUTOMATIC_LINKING_ATTEMPT),
    withLatestFrom(state$),
    switchMap(([{ payload }, { pmsReducer }]) =>
      from(
        API.post('grinServerlessApi', `/pms/v1/${pmsReducer.pmsType}/patients/autolink`, {
          headers: {
            'x-api-key': getAccessToken(),
            'pms-token': localStorage.getItem(`${pmsReducer.pmsType}_token`)
          },
          body: {
            patientDetails: payload
          }
        })
      ).pipe(
        mergeMap(response => {
          if (response?.matchingPatient) {
            trackEvent('Dolphin linkage - auto patient linking succeeded', {
              matchingParameter: response?.matchingParameter
            })
            return of(
              Actions.pmsLinkPatient({
                pmsPatientId: response.matchingPatient.id,
                pmsType: pmsReducer.pmsType,
                isSilent: true
              })
            )
          }

          trackEvent('Dolphin linkage - patient auto linking failed - no match was found')
          return empty()
        }),
        catchError(err => of(Actions.pmsAutomaticLinkingAttemptFailed(err)))
      )
    )
  )

export const pmsFetchLatestPatientsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PMS_FETCH_LATEST_PATIENTS),
    withLatestFrom(state$),
    switchMap(([, { pmsReducer }]) =>
      from(
        API.get(
          'grinServerlessApi',
          `/pms/v1/${pmsReducer.pmsType}/patients/latest?latestTimestamp=${moment()
            .subtract('months', 2)
            .toISOString()}`,
          {
            headers: {
              'x-api-key': getAccessToken(),
              'pms-token': localStorage.getItem(`${pmsReducer.pmsType}_token`)
            }
          }
        )
      ).pipe(
        mergeMap(patientsToInvite => of(Actions.pmsFetchLatestPatientsReceived(patientsToInvite))),
        catchError(err => of(Actions.pmsFetchLatestPatientsFailed(err)))
      )
    )
  )

export const pmsFetchLatestPatientsFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.PMS_FETCH_LATEST_PATIENTS_FAILED),
    switchMap(({ payload: error }) => {
      logError('An error occured while trying to fetch latest patients for doctor', error)
      return empty()
    })
  )

export const pmsRedirectFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.PMS_REDIRECT_FAILED),
    map(({ payload: error }) => {
      logError('An error occured while redirecting to Grin from Dolphin', { error })

      return Actions.showSnackbar({
        type: 'error',
        text: i18n.t('pms.redirectPatientNotFoundModal.error')
      })
    })
  )

export const pmsRedirectSearchLinkedPatientEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PMS_REDIRECT_SEARCH_LINKED_PATIENT),
    pluck('payload'),
    switchMap(({ pmsPatientId, pmsPatientName }) =>
      from(
        API.graphql(
          graphqlOperation(searchPatientSearchModelsForPmsRedirect, {
            filter: {
              or: [
                {
                  externalIdsList: {
                    match: pmsPatientId
                  }
                },
                {
                  name: {
                    matchPhrasePrefix: pmsPatientName
                  }
                }
              ]
            }
          })
        )
      ).pipe(
        mergeMap(response =>
          of(
            Actions.pmsRedirectSearchLinkedPatientReceived({
              patientsResults: response.data.searchPatientSearchModels?.items,
              pmsPatientName
            })
          )
        ),
        catchError(err => of(Actions.pmsRedirectFailed(err)))
      )
    )
  )

export const pmsRedirectSearchLinkedPatientReceivedEpic = (action$, state$, { history }) =>
  action$.pipe(
    ofType(Actions.PMS_REDIRECT_SEARCH_LINKED_PATIENT_RECEIVED),
    pluck('payload'),
    mergeMap(({ patientsResults, pmsPatientName }) => {
      switch (patientsResults.length) {
        case 0:
          history.push(ROUTES.PATIENTS)
          return of(Actions.pmsToggleRedirectPatientNotFoundModal({ isOpen: true, pmsPatientName }))
        case 1:
          history.push(`${ROUTES.PATIENTS}/${patientsResults[0].id}`)
          return empty()
        default:
          history.push(`${ROUTES.PATIENTS}`)
          return of(Actions.setPatientsPresetFilter({ name: pmsPatientName }))
      }
    }),
    catchError(err => of(Actions.pmsRedirectFailed(err)))
  )
