import { Roles } from 'consts/authConsts'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useAllFeatureFlags = () => {
  const { t } = useTranslation()

  return useMemo(
    () => ({
      patientFeatureFlags: [
        {
          key: 'orthoSeenProposal',
          type: 'patient',
          label: t('featureFlags.orthoSeenProposal'),
          availableFor: [Roles.DevAdmin]
        },
        { key: 'rcSeenWelcome', type: 'patient', label: t('featureFlags.rcSeenWelcome') },
        {
          key: 'classifierAvailable',
          type: 'patient',
          label: t('featureFlags.classifierAvailable'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'classifierSettingsAvailable',
          type: 'patient',
          label: t('featureFlags.classifierSettingsAvailable'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'skipForceDetails',
          type: 'patient',
          label: t('featureFlags.skipForceDetails'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'medicalHistory',
          type: 'patient',
          label: t('featureFlags.medicalHistory'),
          availableFor: [Roles.DevAdmin]
        },
        { key: 'careCredit', type: 'patient', label: t('featureFlags.careCredit'), availableFor: [Roles.DevAdmin] },
        {
          key: 'preventiveDiagnosis',
          type: 'patient',
          label: t('featureFlags.preventiveDiagnosis'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'checkNotificationSettingsPopup_newPatients',
          type: 'patient',
          label: t('featureFlags.checkNotificationSettingsPopup_newPatients'),
          availableFor: [Roles.DevAdmin]
        }
      ].sort((a, b) => (a.label > b.label ? 1 : -1)),
      doctorFeatureFlags: [
        {
          key: 'remoteConsultation',
          type: 'doctor',
          label: t('featureFlags.remoteConsultation'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'ortho',
          type: 'doctor',
          label: t('featureFlags.ortho'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'promotionsListA',
          type: 'doctor',
          label: t('featureFlags.promotionsListA'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'promotionsListB',
          type: 'doctor',
          label: t('featureFlags.promotionsListB'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'promotionsListC',
          type: 'doctor',
          label: t('featureFlags.promotionsListC'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'promotionsListD',
          type: 'doctor',
          label: t('featureFlags.promotionsListD'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'medicalHistory',
          type: 'doctor',
          label: t('featureFlags.medicalHistory'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'scanSummaryPreventive',
          type: 'doctor',
          label: t('featureFlags.scanSummaryPreventive'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'autochart',
          type: 'doctor',
          label: t('featureFlags.autochart'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        { key: 'intercom', type: 'doctor', label: t('featureFlags.intercom'), availableFor: [Roles.DevAdmin] },
        {
          key: 'oralHygieneNote',
          type: 'doctor',
          label: t('featureFlags.oralHygieneNote'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'newScanFlow',
          type: 'doctor',
          label: t('featureFlags.newScanFlow'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'viewScanSummaryModelVersion',
          type: 'doctor',
          label: t('featureFlags.viewScanSummaryModelVersion'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'treatmentTracker',
          type: 'doctor',
          label: t('featureFlags.treatmentTracker'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'recordsAppSkipEmail',
          type: 'doctor',
          label: t('featureFlags.recordsAppSkipEmail'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'checkNotificationSettingsPopup_existingPatients',
          type: 'doctor',
          label: t('featureFlags.checkNotificationSettingsPopup_existingPatients'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'hiTools',
          type: 'doctor',
          label: t('featureFlags.hiTools'),
          isPractice: true
        },
        {
          key: 'txTrackerTxGoals',
          type: 'doctor',
          label: t('featureFlags.txTrackerTxGoals'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'timelineV2',
          type: 'doctor',
          label: t('featureFlags.timelineV2'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'overlayDrawingFF',
          type: 'doctor',
          label: t('featureFlags.overlayDrawingFF'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'dynamicAssistedScan',
          type: 'doctor',
          label: t('featureFlags.dynamicAssistedScan'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'practiceAutomations',
          type: 'doctor',
          label: t('featureFlags.practiceAutomations'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        }
      ].sort((a, b) => (a.label > b.label ? 1 : -1))
    }),
    [t]
  )
}
