import * as React from 'react'

function Assignee(props) {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.79841 0.853156C8.38486 0.853156 7.23895 1.99907 7.23895 3.41262C7.23895 4.82618 8.38486 5.97209 9.79841 5.97209C11.212 5.97209 12.3579 4.82618 12.3579 3.41262C12.3579 1.99907 11.212 0.853156 9.79841 0.853156ZM6.38579 3.41262C6.38579 1.52788 7.91367 0 9.79841 0C11.6832 0 13.211 1.52788 13.211 3.41262C13.211 5.29736 11.6832 6.82525 9.79841 6.82525C7.91367 6.82525 6.38579 5.29736 6.38579 3.41262Z"
        fill={props.color || '#4151E6'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5327 9.6403C15.4169 9.72954 15.3055 9.82723 15.1994 9.93339L14.9002 10.2325C14.3741 9.38143 13.4325 8.81433 12.3584 8.81433L7.23947 8.81433C5.59033 8.81433 4.25343 10.1512 4.25343 11.8004L4.25343 14.7864L10.3468 14.7864C10.0956 15.0448 9.89344 15.333 9.7403 15.6396L3.40027 15.6396L3.40027 11.8004C3.40027 9.68005 5.11914 7.96118 7.23947 7.96118L12.3584 7.96118C13.6777 7.96118 14.8416 8.62668 15.5327 9.6403Z"
        fill={props.color || '#4151E6'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8847 14.2842C14.0512 14.4508 14.0512 14.7209 13.8847 14.8875L11.4716 17.3006C10.9718 17.8003 10.9718 18.6106 11.4716 19.1104L12.0748 19.7137C12.5746 20.2134 13.3849 20.2134 13.8847 19.7137L16.2977 17.3006C16.4643 17.134 16.7344 17.134 16.901 17.3006C17.0676 17.4672 17.0676 17.7373 16.901 17.9039L14.4879 20.3169C13.655 21.1499 12.3045 21.1499 11.4716 20.3169L10.8683 19.7137C10.0354 18.8807 10.0354 17.5303 10.8683 16.6973L13.2814 14.2842C13.448 14.1176 13.7181 14.1176 13.8847 14.2842Z"
        fill={props.color || '#4151E6'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8579 16.3439C17.6913 16.1773 17.6913 15.9072 17.8579 15.7406L20.271 13.3275C20.7708 12.8278 20.7708 12.0175 20.271 11.5177L19.6677 10.9145C19.168 10.4147 18.3577 10.4147 17.8579 10.9145L15.4448 13.3275C15.2783 13.4941 15.0082 13.4941 14.8416 13.3275C14.675 13.161 14.675 12.8909 14.8416 12.7243L17.2547 10.3112C18.0876 9.47824 19.4381 9.47824 20.271 10.3112L20.8743 10.9145C21.7072 11.7474 21.7072 13.0979 20.8743 13.9308L18.4612 16.3439C18.2946 16.5105 18.0245 16.5105 17.8579 16.3439Z"
        fill={props.color || '#4151E6'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.858 13.3276C18.0246 13.4941 18.0246 13.7642 17.858 13.9308L14.4882 17.3006C14.3217 17.4672 14.0516 17.4672 13.885 17.3006C13.7184 17.134 13.7184 16.8639 13.885 16.6973L17.2547 13.3276C17.4213 13.161 17.6914 13.161 17.858 13.3276Z"
        fill={props.color || '#4151E6'}
      />
    </svg>
  )
}

export default Assignee
