import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import FormInput from 'components/common/FormInput'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import GrinRadioGroup from 'components/common/GrinRadioGroup'
import PhoneNumberInput from 'components/common/PhoneNumberInput'
import ScanWithPatientDetails from './ScanWithPatientDetails'
import { SmallLock } from 'components/common/icons'
import { SHARE_METHODS } from './shareScanConsts'

const useStyles = makeStyles({
  modal: {
    padding: '50px 30px 0px 10px'
  },
  container: {
    height: '100%',
    paddingRight: 3,
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  content: {
    height: '100%',
    paddingRight: '0px !important'
  },
  video: {
    width: '100% !important',
    height: '100% !important'
  }
})

const ShareNewScanModalMobile = ({
  isOpen,
  isLoading,
  onClose,
  onShareScan,
  grinScanId,
  hasFilledAllFields,
  referralName,
  setReferralName,
  speciality,
  setSpeciality,
  shareMethod,
  setShareMethod,
  shareDestination,
  setShareDestination,
  note,
  setNote,
  shareTypeOptions,
  error
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <BaseModal
      open={isOpen}
      variant="fullscreen"
      handleClose={onClose}
      className={classes.modal}
      hideScroll
      primaryLabel={t('dialogs.shareScan.shareScanButtonLabel')}
      contentClassName={classes.content}
      onPrimaryBtnClick={onShareScan}
      isPrimaryDisabled={!hasFilledAllFields}
      largerPrimaryButton
      buttonsWidth={130}
      onSecondaryBtnClick={onClose}
      secondaryLabel={t('general.cancel')}
      isLoading={isLoading}
    >
      {isOpen && (
        <div className={classes.container}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <DazzedParagraph16 bold>{t('dialogs.shareScan.title')}</DazzedParagraph16>
                </Grid>
                <Grid item>
                  <Grid container wrap="nowrap" spacing={1}>
                    <Grid item style={{ marginTop: 2 }}>
                      <SmallLock />
                    </Grid>
                    <Grid item>
                      <DazzedParagraph14 strong color="var(--text-color-59)">
                        {t('dialogs.shareScan.secureNote')}
                      </DazzedParagraph14>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ScanWithPatientDetails grinScanId={grinScanId} videoClassName={classes.video} />
            </Grid>
            <Grid item>
              <FormInput
                style={{ bright: true, thick: true }}
                title={t('dialogs.shareScan.colleagueNamelabel')}
                titleStyle={{ fontSize: '12px !important' }}
                value={referralName}
                setValue={setReferralName}
                validationRules={{
                  required: true
                }}
              />
            </Grid>
            <Grid item>
              <FormInput
                style={{ bright: true, thick: true }}
                title={t('dialogs.shareScan.specialityLabel')}
                value={speciality}
                setValue={setSpeciality}
                validationRules={{
                  required: true
                }}
              />
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <DazzedParagraph14 strong>{t('dialogs.shareScan.shareBy.label')}</DazzedParagraph14>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ marginLeft: 10 }}>
                    <GrinRadioGroup
                      size="small"
                      value={shareMethod}
                      setValue={newMethod => {
                        setShareMethod(newMethod)
                        setShareDestination('')
                      }}
                      options={shareTypeOptions}
                      radioGroupClassName={classes.shareTypeOptions}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {shareMethod === SHARE_METHODS.email ? (
                <FormInput
                  style={{ bright: true, thick: true }}
                  placeholder={t(`dialogs.shareScan.shareBy.emailPlaceholder`)}
                  value={shareDestination}
                  setValue={setShareDestination}
                  validationRules={{
                    required: true
                  }}
                  errorMessage={error}
                />
              ) : (
                <PhoneNumberInput
                  style={{ bright: true, thick: true }}
                  setValue={setShareDestination}
                  value={shareDestination}
                />
              )}
            </Grid>
            <Grid item>
              <TextAreaAutosize
                inputStyle={{ fontSize: 14, fontWeight: 500, height: 100, overflow: 'auto' }}
                rowsMax={4}
                style={{ bright: true }}
                title={t('dialogs.shareScan.noteLabel')}
                placeholder={t('dialogs.shareScan.notePlaceholder')}
                value={note}
                keepErrorContainerWhenInactive={false}
                setValue={setNote}
                validationRules={{
                  required: true
                }}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </BaseModal>
  )
}

export default ShareNewScanModalMobile
