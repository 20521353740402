export const mapEditorToDto = ({ versionGuid, version, description, isHeadlineDisabled, headlineMedia, slides }) => ({
  versionGuid,
  version,
  isHeadlineDisabled,
  description,
  headlineMedia,
  slides: slides.map(({ id, title, subtitle, bullets, mediaObject, isDisabled }) => ({
    id,
    title,
    subtitle,
    bullets,
    isDisabled,
    mediaObject
  }))
})
