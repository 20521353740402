import * as React from 'react'

function LeftBite(props) {
  return (
    <svg width="41" height="16" viewBox="0 0 41 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M39.1425 5.71423C40.3541 5.47786 39.9711 3.42854 39.8282 2.62855C39.8282 2.62855 39.4853 2.62855 38.971 2.79998C38.5731 2.93261 38.0281 3.31426 38.0281 3.31426C38.0281 3.05712 37.8567 2.79998 37.5139 2.79998C36.8967 2.79998 35.771 3.94282 35.2853 4.51424C35.2282 4.19996 34.9425 3.5714 34.2568 3.5714C33.5711 3.5714 32.7711 3.91425 32.4568 4.08568C32.5425 3.59997 32.5768 2.62855 32.0282 2.62855C31.4796 2.62855 29.9711 4.11425 29.2854 4.8571C29.3997 3.88568 29.4054 1.94285 28.514 1.94285C27.6226 1.94285 26.5997 3.31426 26.1997 3.99996C26.1997 3.25712 25.874 1.77142 24.5712 1.77142C23.2683 1.77142 22.0855 3.48569 21.6569 4.34282C21.6569 3.59997 21.2798 2.11427 19.7712 2.11427C18.2627 2.11427 17.3141 3.99996 17.0284 4.94281C16.657 4.08568 15.5198 2.37141 13.9427 2.37141C12.3656 2.37141 11.5142 4.02854 11.2856 4.8571C10.8285 3.97139 9.62276 2.19999 8.45706 2.19999C7.29136 2.19999 6.71422 3.51426 6.57137 4.17139C6.08566 3.25712 5.45709 1.59999 4.8571 1.59999C4.39552 1.59999 4.25711 2.37141 3.99997 3.22855C3.48569 1.94285 2.2857 1 1.85714 1C1.51428 1 1.25714 1.45714 1.17143 1.68571C1.17143 1.68571 1 2.62232 1 3.22855C1 3.83477 1 3.91425 1.17143 4.77138C1.34285 5.62852 2.45713 8.19992 3.82854 7.4285C4.30853 8.31991 5.68566 8.19992 6.31422 8.02849C6.57137 8.45706 7.4285 9.31419 8.79991 9.31419C10.1713 9.31419 10.9142 9.14276 11.1142 9.05705C11.2285 9.25705 11.9542 9.65704 13.9427 9.65704C15.9313 9.65704 16.7712 8.74277 16.9427 8.28563C17.0855 8.57134 17.7655 9.14276 19.3426 9.14276C20.9198 9.14276 21.5426 8.22849 21.6569 7.77135C21.9426 8.19992 22.7883 9.05705 23.8855 9.05705C24.9826 9.05705 25.8283 8.02849 26.114 7.51421C26.1711 7.94278 26.4568 8.79991 27.1426 8.79991C27.8283 8.79991 28.6854 7.65706 29.0282 7.08564C29.1997 7.54278 29.714 8.45706 30.3997 8.45706C31.0854 8.45706 31.7711 7.54278 32.0282 7.08564C33.2282 7.4285 34.2568 7.34278 35.0282 6.48565C36.7424 7.10279 37.6281 6.05708 37.8567 5.45709C38.1994 5.71423 38.6399 5.81228 39.1425 5.71423Z"
        stroke="#737B8A"
      />
      <path
        d="M37.856 5.54285C38.3417 5.82856 39.1245 6.51998 38.3702 6.99997C37.616 7.47997 36.6559 6.99997 36.3131 6.74283C36.7131 7.39997 37.4102 8.86852 36.6559 9.1428C35.9017 9.41709 34.2845 9.02852 33.5703 8.79995C33.7131 9.62851 33.8103 11.3542 33.056 11.6285C32.3017 11.9028 30.6846 11.1142 29.9703 10.6856C29.9989 11.3999 30.056 12.9142 29.7132 13.1713C29.4065 13.4013 27.7132 12.3713 26.8846 11.7999C26.7989 12.657 26.4903 14.3713 25.9418 14.3713C25.3932 14.3713 23.9989 12.4285 23.3704 11.4571C23.2275 12.4856 22.6504 14.5427 21.4847 14.5427C20.319 14.5427 19.2276 12.5428 18.8276 11.5428C18.6847 12.5428 18.1247 14.5427 17.0276 14.5427C15.9305 14.5427 15.3133 12.7142 15.1419 11.7999C14.9419 12.6285 14.3191 14.2856 13.4276 14.2856C12.5362 14.2856 12.0848 12.7428 11.9705 11.9713C11.6562 12.657 10.8391 14.0285 10.0848 14.0285C9.33054 14.0285 9.08483 12.3713 9.05626 11.5428C8.74198 12.2571 7.92484 13.6856 7.17057 13.6856C6.41629 13.6856 6.08487 12.2571 6.05629 11.4571C5.71344 12.0571 4.92488 13.0856 4.1706 13.0856C3.41632 13.0856 3.34204 11.8856 3.39918 11.2856C3.31347 11.6285 3.07347 12.3142 2.79919 12.3142C2.52491 12.3142 1.85634 9.91422 2.79919 7.42854"
        stroke="#737B8A"
      />
      <path d="M38.0273 3.31421C37.9988 3.9142 37.9245 5.18276 37.8559 5.45704" stroke="#737B8A" />
      <path d="M35.2852 4.42859C35.2566 4.9143 35.1652 6.00571 35.028 6.48571" stroke="#737B8A" />
      <path d="M32.457 4.08569L32.0285 7.17137" stroke="#737B8A" />
      <path d="M29.2852 4.77136L29.028 7.17133" stroke="#737B8A" />
      <path d="M26.1992 3.82849L26.1135 7.68559" stroke="#737B8A" />
      <path d="M21.6562 4.08569V7.85708" stroke="#737B8A" />
      <path d="M17.0273 4.85706L16.9416 8.3713" stroke="#737B8A" />
      <path d="M11.2852 4.77136L11.1137 9.05703" stroke="#737B8A" />
      <path d="M6.48633 3.99988L6.3149 8.11412" stroke="#737B8A" />
      <path d="M4 3.14282L3.82857 7.42849" stroke="#737B8A" />
      <path d="M33.5703 8.79994C33.3132 8.39994 32.7303 7.51424 32.456 7.17139" stroke="#737B8A" />
      <path d="M29.9707 10.7713C29.885 10.1999 29.5936 8.71419 29.1136 7.34277" stroke="#737B8A" />
      <path d="M26.8852 11.8856C26.9424 11.257 27.0224 9.75992 26.8852 8.79993" stroke="#737B8A" />
      <path d="M23.3711 11.5427C23.3425 10.9427 23.2682 9.58845 23.1997 8.97131" stroke="#737B8A" />
      <path d="M18.8281 11.7141V9.1427" stroke="#737B8A" />
      <path d="M15.1426 12.1427L15.0569 9.57129" stroke="#737B8A" />
      <path d="M11.9707 11.9713V9.4856" stroke="#737B8A" />
      <path d="M9.05664 11.7142L8.97093 9.31421" stroke="#737B8A" />
      <path d="M5.88521 8.19983L6.05664 11.4569" stroke="#737B8A" />
      <path d="M3.82812 7.51416C3.6567 8.31415 3.33099 10.2398 3.39956 11.5427" stroke="#737B8A" />
      <path
        d="M39.1425 5.71423C40.3541 5.47786 39.9711 3.42854 39.8282 2.62855C39.8282 2.62855 39.4853 2.62855 38.971 2.79998C38.5731 2.93261 38.0281 3.31426 38.0281 3.31426C38.0281 3.05712 37.8567 2.79998 37.5139 2.79998C36.8967 2.79998 35.771 3.94282 35.2853 4.51424C35.2282 4.19996 34.9425 3.5714 34.2568 3.5714C33.5711 3.5714 32.7711 3.91425 32.4568 4.08568C32.5425 3.59997 32.5768 2.62855 32.0282 2.62855C31.4796 2.62855 29.9711 4.11425 29.2854 4.8571C29.3997 3.88568 29.4054 1.94285 28.514 1.94285C27.6226 1.94285 26.5997 3.31426 26.1997 3.99996C26.1997 3.25712 25.874 1.77142 24.5712 1.77142C23.2683 1.77142 22.0855 3.48569 21.6569 4.34282C21.6569 3.59997 21.2798 2.11427 19.7712 2.11427C18.2627 2.11427 17.3141 3.99996 17.0284 4.94281C16.657 4.08568 15.5198 2.37141 13.9427 2.37141C12.3656 2.37141 11.5142 4.02854 11.2856 4.8571C10.8285 3.97139 9.62276 2.19999 8.45706 2.19999C7.29136 2.19999 6.71422 3.51426 6.57137 4.17139C6.08566 3.25712 5.45709 1.59999 4.8571 1.59999C4.39552 1.59999 4.25711 2.37141 3.99997 3.22855C3.48569 1.94285 2.2857 1 1.85714 1C1.51428 1 1.25714 1.45714 1.17143 1.68571C1.17143 1.68571 1 2.62232 1 3.22855C1 3.83477 1 3.91425 1.17143 4.77138C1.34285 5.62852 2.45713 8.19992 3.82854 7.4285C4.30853 8.31991 5.68566 8.19992 6.31422 8.02849C6.57137 8.45706 7.4285 9.31419 8.79991 9.31419C10.1713 9.31419 10.9142 9.14276 11.1142 9.05705C11.2285 9.25705 11.9542 9.65704 13.9427 9.65704C15.9313 9.65704 16.7712 8.74277 16.9427 8.28563C17.0855 8.57134 17.7655 9.14276 19.3426 9.14276C20.9198 9.14276 21.5426 8.22849 21.6569 7.77135C21.9426 8.19992 22.7883 9.05705 23.8855 9.05705C24.9826 9.05705 25.8283 8.02849 26.114 7.51421C26.1711 7.94278 26.4568 8.79991 27.1426 8.79991C27.8283 8.79991 28.6854 7.65706 29.0282 7.08564C29.1997 7.54278 29.714 8.45706 30.3997 8.45706C31.0854 8.45706 31.7711 7.54278 32.0282 7.08564C33.2282 7.4285 34.2568 7.34278 35.0282 6.48565C36.7424 7.10279 37.6281 6.05708 37.8567 5.45709C38.1994 5.71423 38.6399 5.81228 39.1425 5.71423Z"
        fill="#737B8A"
        fillOpacity="0.25"
      />
      <path
        d="M38.3702 6.99997C39.1245 6.51998 38.3417 5.82856 37.856 5.54285L36.5 6.5H35L33.5 7.5L32 7L30.5 8.5L29 7L27 9L26 7.5L24 9L21.5 8L19.5 9L17 8.5L14 9.5L11 9H9L6.5 8H5L3.82031 7.42854H2.79919C1.85635 9.91422 2.52491 12.3142 2.79919 12.3142C3.07347 12.3142 3.31347 11.6285 3.39918 11.2856C3.34204 11.8856 3.41632 13.0856 4.1706 13.0856C4.92488 13.0856 5.71344 12.0571 6.05629 11.4571C6.08487 12.2571 6.41629 13.6856 7.17057 13.6856C7.92484 13.6856 8.74198 12.2571 9.05626 11.5428C9.08483 12.3713 9.33054 14.0285 10.0848 14.0285C10.8391 14.0285 11.6562 12.657 11.9705 11.9713C12.0848 12.7428 12.5362 14.2856 13.4276 14.2856C14.3191 14.2856 14.9419 12.6285 15.1419 11.7999C15.3133 12.7142 15.9305 14.5427 17.0276 14.5427C18.1247 14.5427 18.6847 12.5428 18.8276 11.5428C19.2276 12.5428 20.319 14.5427 21.4847 14.5427C22.6504 14.5427 23.2275 12.4856 23.3704 11.4571C23.9989 12.4285 25.3932 14.3713 25.9418 14.3713C26.4903 14.3713 26.7989 12.657 26.8846 11.7999C27.7132 12.3713 29.4065 13.4013 29.7132 13.1713C30.056 12.9142 29.9989 11.3999 29.9703 10.6856C30.6846 11.1142 32.3017 11.9028 33.056 11.6285C33.8103 11.3542 33.7131 9.62851 33.5703 8.79995C34.2845 9.02852 35.9017 9.41709 36.6559 9.1428C37.4102 8.86852 36.7131 7.39997 36.3131 6.74283C36.6559 6.99997 37.616 7.47997 38.3702 6.99997Z"
        fill="#737B8A"
        fillOpacity="0.25"
      />
    </svg>
  )
}

export default LeftBite
