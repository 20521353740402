import React from 'react'
import { makeStyles } from '@material-ui/styles'
import SecondaryButton from './buttons/SecondaryButton'
import GrinLabel from './text/GrinLabel'
import { IconButton } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 9
  },
  grayBox: {
    background: ({ dark }) => (dark ? 'var(--bg-color-4)' : 'var(--bg-color-15)'),
    padding: 12,
    flex: 1,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    height: ({ height }) => height
  },
  buttonContainer: {
    marginLeft: 10
  },
  darkButton: {
    backgroundColor: 'rgba(60, 82, 239, 0.2)',
    color: 'white'
  }
}))

const GrayActionBox = ({
  label = '',
  height = 48,
  onAction,
  buttonText,
  children,
  actionIcon,
  className = '',
  variant = 'bright'
}) => {
  const classes = useStyles({ height, dark: variant === 'dark' })

  return (
    <div className={className}>
      {label && (
        <GrinLabel color={variant === 'dark' ? 'var(--text-color-1)' : ' var(--text-color-6)'}>{label}</GrinLabel>
      )}
      <div className={classes.container}>
        <div className={classes.grayBox}>
          {children}
          {actionIcon && (
            <IconButton size="small" onClick={onAction}>
              {actionIcon}
            </IconButton>
          )}
        </div>
        {!!buttonText && (
          <div className={classes.buttonContainer}>
            <SecondaryButton
              small={variant !== 'dark'}
              width="132px"
              label={buttonText}
              onClick={onAction}
              className={variant === 'dark' ? classes.darkButton : ''}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default GrayActionBox
