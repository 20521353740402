import * as React from 'react'

const Show = ({ color = 'white', ...props }) => {
  return (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.23549 11.9036C5.84223 12.8902 7.66266 13.4118 9.49998 13.4118C11.3373 13.4118 13.1577 12.8902 14.7645 11.9036C16.3076 10.9561 17.6511 9.58423 18.6497 7.93649C19.1168 7.16592 19.1168 6.24584 18.6497 5.47527C17.6511 3.82753 16.3076 2.45571 14.7645 1.50814C13.1577 0.521532 11.3373 0 9.49998 0C7.66266 0 5.84223 0.521532 4.23549 1.50814C2.69237 2.45574 1.3489 3.82757 0.350287 5.47527C-0.116762 6.24584 -0.116762 7.16592 0.350287 7.93649C1.3489 9.58423 2.69237 10.9561 4.23549 11.9036ZM1.37406 6.05978C3.23621 2.98718 6.27395 1.1528 9.49998 1.1528C12.726 1.1528 15.7638 2.98718 17.6259 6.05978C17.8784 6.47644 17.8784 6.93536 17.6259 7.35202C15.7637 10.4246 12.726 12.259 9.49998 12.259C6.27395 12.259 3.23621 10.4246 1.37406 7.35202C1.12152 6.93536 1.12152 6.47644 1.37406 6.05978ZM9.49998 10.5833C11.7029 10.5833 13.4951 8.84392 13.4951 6.70588C13.4951 4.56784 11.7029 2.82842 9.49998 2.82842C7.29709 2.82842 5.50491 4.56784 5.50491 6.70588C5.50491 8.84392 7.29709 10.5833 9.49998 10.5833ZM9.49998 3.98122C11.0479 3.98122 12.3073 5.2035 12.3073 6.70588C12.3073 8.20826 11.0479 9.43055 9.49998 9.43055C7.95203 9.43055 6.69267 8.20826 6.69267 6.70588C6.69267 5.2035 7.95203 3.98122 9.49998 3.98122Z"
        fill={color}
      />
    </svg>
  )
}

export default Show
