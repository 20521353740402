import React, { useCallback, useRef, useState } from 'react'
import BaseModal from 'components/common/modals/BaseModal'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import ServiceAccountPasswordValidation from './ServiceAccountPasswordValidation'
import FormInput from 'components/common/FormInput'
import { isValidEmail } from 'utils/generalUtils'
import { validatePasswords } from 'utils/authUtils'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { SERVICE_ACCOUNT_SUFFIX } from 'consts/practiceConsts'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { GreenSuccess } from 'components/common/icons'

const useStyles = makeStyles(theme => ({
  modal: {
    width: 'auto !important',
    paddingTop: 20
  },
  modalContent: {
    padding: '20px 47px 40px 47px !important'
  },
  passwordValidation: {
    color: 'var(--text-color-35)'
  },
  passwordInputContainer: {
    marginBottom: 30
  },
  note: {
    marginTop: 30,
    color: 'var(--border-color-33)'
  },
  inputSuffixClassname: {
    fontWeight: 500,
    color: 'var(--text-color-38)',
    paddingRight: 16
  },
  serviceAccountText: { paddingTop: 24, paddingBottom: 32 },
  successIconTitle: { marginRight: 5 }
}))

const ServiceAccountCreateAccountModal = ({
  isOpen,
  isLoading,
  handleToggleRcService,
  onDeactivatePressed,
  onClose,
  serviceAccountEmail
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { practiceName } = useSelector(state => state.practiceReducer.details)

  const [email, setEmail] = useState(
    practiceName
      ?.toLowerCase()
      ?.replace(' ', '')
      ?.replace(/[^a-zA-Z0-9]/g, '') || ''
  )
  const [emailError, setEmailError] = useState(null)
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('')

  const onSubmitPressed = useCallback(() => {
    const { errors: passwordErrors } = validatePasswords(password, passwordConfirmation)
    const fullEmail = `${email.toLowerCase()}${SERVICE_ACCOUNT_SUFFIX}`
    const emailError = !isValidEmail(fullEmail)
      ? t('pages.accountSettings.services.serviceAccount.modals.createAccount.usernameError')
      : null
    if (!passwordErrors && !emailError) {
      setEmailError(null)
      setPasswordError(null)
      setPasswordConfirmationError(null)
      dispatch(Actions.createServiceAccount({ email: fullEmail, password }))
    } else {
      setEmailError(emailError)
      setPasswordError(passwordErrors?.password)
      setPasswordConfirmationError(passwordErrors?.passwordConfirmation)
    }
  }, [dispatch, setPasswordError, password, passwordConfirmation, email, t])

  const emailInputRef = useRef()

  const renderCreateAccountForm = useCallback(
    () => (
      <>
        <FormInput
          title={t('pages.accountSettings.services.serviceAccount.modals.createAccount.usernameTitle')}
          placeholder={t('pages.accountSettings.services.serviceAccount.modals.createAccount.usernamePlaceholder')}
          type="email"
          style={{ bright: true, thick: true }}
          value={email}
          setValue={setEmail}
          showPasswordButton
          containerClassName={classes.passwordInputContainer}
          errorMessage={emailError}
          inputSuffix={SERVICE_ACCOUNT_SUFFIX}
          inputSuffixClassname={classes.inputSuffixClassname}
          inputSuffixJustifyContent={'flex-end'}
          ref={emailInputRef}
        />
        <ServiceAccountPasswordValidation
          password={password}
          passwordConfirmation={passwordConfirmation}
          passwordError={passwordError}
          passwordConfirmationError={passwordConfirmationError}
          onPasswordChange={setPassword}
          onPasswordConfirmationChange={setPasswordConfirmation}
        />
      </>
    ),
    [classes, email, emailError, password, passwordConfirmation, passwordError, passwordConfirmationError, t]
  )

  const renderSuccess = useCallback(
    () => (
      <>
        <DazzedParagraph14>
          {t('pages.accountSettings.services.serviceAccount.modals.createAccount.success.description1')}
        </DazzedParagraph14>
        <DazzedParagraph14 className={classes.serviceAccountText} bold>
          {serviceAccountEmail}
        </DazzedParagraph14>
        <DazzedParagraph14>
          {t('pages.accountSettings.services.serviceAccount.modals.createAccount.success.description2')}
        </DazzedParagraph14>
      </>
    ),
    [classes, serviceAccountEmail, t]
  )
  const renderTitle = useCallback(
    () =>
      serviceAccountEmail ? (
        <>
          <GreenSuccess className={classes.successIconTitle} />
          {t('pages.accountSettings.services.serviceAccount.modals.createAccount.success.title')}
        </>
      ) : (
        t('pages.accountSettings.services.serviceAccount.modals.createAccount.title')
      ),
    [serviceAccountEmail, classes, t]
  )

  return (
    <BaseModal
      open={isOpen}
      title={renderTitle()}
      variant="alert"
      isLoading={isLoading}
      onPrimaryBtnClick={serviceAccountEmail ? onClose : onSubmitPressed}
      onSecondaryBtnClick={onClose}
      primaryLabel={
        serviceAccountEmail
          ? t('pages.accountSettings.services.serviceAccount.modals.createAccount.success.primaryButton')
          : t('pages.accountSettings.services.serviceAccount.modals.createAccount.primaryButton')
      }
      secondaryLabel={!serviceAccountEmail && t('general.cancel')}
      handleClose={onClose}
      className={classes.modal}
      contentClassName={classes.modalContent}
      largerPrimaryButton={true}
    >
      {serviceAccountEmail ? renderSuccess() : renderCreateAccountForm()}
    </BaseModal>
  )
}

export default ServiceAccountCreateAccountModal
