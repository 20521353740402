import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  text: {
    '& strong': {
      color: 'var(--text-color-28)'
    }
  },
  icon: {
    paddingRight: '12px'
  },
  subtext: {
    color: 'var(--text-color-11)'
  }
}))

const AuditItem = ({ icon = <></>, text, subtext }) => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item>
        <span className={classes.icon}>{icon}</span>
      </Grid>
      <Grid item>
        <DazzedParagraph14 className={classes.text}>{text}</DazzedParagraph14>
        {subtext && <DazzedParagraph14 className={classes.subtext}>{subtext}</DazzedParagraph14>}
      </Grid>
    </Grid>
  )
}

export default AuditItem
