import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Actions from 'actions'
import CustomDatePicker from 'components/common/CustomDatePicker'
import FormInput from 'components/common/FormInput'
import BaseModal from 'components/common/modals/BaseModal'
import SelectInput from 'components/common/SelectInput'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import TextArea from 'components/common/TextArea'

const useStyles = makeStyles({
  body: {
    overflowY: 'hidden',
    minWidth: '30%'
  },
  dialogContent: {
    paddingLeft: 35,
    paddingRight: 35
  },
  errors: {
    color: 'red',
    display: 'flex',
    justifyContent: 'center'
  }
})

const RCOpsDetailsDialog = ({ lead, isOpen, isSaving, handleClose }) => {
  const PLACEHOLDER = '-'
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const yesNoOptions = useMemo(() => [PLACEHOLDER, t('general.no'), t('general.yes')], [t])

  const [errors, setErrors] = useState(null)
  const [hasTextSent, setHasTextSent] = useState(yesNoOptions[0])
  const [textSentDate, setTextSentDate] = useState(moment())
  const [hasPhoneCalled, setHasPhoneCalled] = useState(yesNoOptions[0])
  const [firstCallDate, setFirstCallDate] = useState(moment())
  const [secondCallDate, setSecondCallDate] = useState(moment())
  const [patientCallFeedback, setPatientCallFeedback] = useState('')

  useEffect(() => {
    if (lead?.rcData?.opsDetails) {
      setHasTextSent(lead.rcData?.opsDetails?.hasTextSent)
      setTextSentDate(moment(lead.rcData?.opsDetails?.textSentDate))
      setHasPhoneCalled(lead.rcData?.opsDetails?.hasPhoneCalled)
      setFirstCallDate(moment(lead.rcData?.opsDetails?.firstCallDate))
      setSecondCallDate(moment(lead.rcData?.opsDetails?.secondCallDate))
      setPatientCallFeedback(lead.rcData?.opsDetails?.patientCallFeedback)
    }
  }, [lead])

  const handleSubmit = useCallback(() => {
    dispatch(
      Actions.updateRCLead({
        id: lead.id,
        _version: lead._version,
        rcData: JSON.stringify({
          ...lead.rcData,
          opsDetails: {
            hasTextSent,
            textSentDate: textSentDate.toISOString(),
            hasPhoneCalled,
            firstCallDate: firstCallDate.toISOString(),
            secondCallDate: secondCallDate.toISOString(),
            patientCallFeedback
          }
        })
      })
    )
    handleClose()
  }, [
    dispatch,
    lead,
    handleClose,
    hasTextSent,
    hasPhoneCalled,
    textSentDate,
    firstCallDate,
    secondCallDate,
    patientCallFeedback
  ])

  return (
    <BaseModal
      open={isOpen}
      withCloseIcon={true}
      isLoading={isSaving}
      title={t('pages.rcDashboard.dialogs.opsDetails.title')}
      contentClassName={classes.dialogContent}
      onSecondaryBtnClick={handleClose}
      secondaryLabel={t('general.cancel')}
      onPrimaryBtnClick={handleSubmit}
      primaryLabel={t('general.save')}
      handleClose={handleClose}
      className={classes.body}
      smothBorders
      primaryButtonId="save-lead-ops-details"
      secondaryButtonId="cancel-lead-ops-details"
    >
      <Grid container alignItems={'center'} justifyContent={'center'} spacing={3} style={{ overflowY: false }}>
        {errors && (
          <Grid item xs={12} className={classes.errors}>
            {errors}
          </Grid>
        )}
        <Grid item xs={12}>
          <FormInput
            title={t('pages.rcDashboard.dialogs.opsDetails.email')}
            placeholder={t('pages.rcDashboard.dialogs.opsDetails.email')}
            style={{ bright: true, thick: true }}
            isDisabled={true}
            value={lead?.email}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label={t('pages.rcDashboard.dialogs.opsDetails.hasTextSent')}
            style={{ bright: true, thick: true }}
            value={hasTextSent}
            values={yesNoOptions}
            onChange={({ value }) => {
              setErrors(null)
              setHasTextSent(value)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomDatePicker
            label={t('pages.rcDashboard.dialogs.opsDetails.textSentDate')}
            value={textSentDate}
            handleDateChange={newDate => setTextSentDate(moment(newDate))}
            disablePast={false}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label={t('pages.rcDashboard.dialogs.opsDetails.hasPhoneCalled')}
            style={{ bright: true, thick: true }}
            value={hasPhoneCalled}
            values={yesNoOptions}
            onChange={({ value }) => {
              setErrors(null)
              setHasPhoneCalled(value)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomDatePicker
            label={t('pages.rcDashboard.dialogs.opsDetails.firstCallDate')}
            value={firstCallDate}
            handleDateChange={newDate => setFirstCallDate(moment(newDate))}
            disablePast={false}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomDatePicker
            label={t('pages.rcDashboard.dialogs.opsDetails.secondCallDate')}
            value={secondCallDate}
            handleDateChange={newDate => setSecondCallDate(moment(newDate))}
            disablePast={false}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            title={t('pages.rcDashboard.dialogs.opsDetails.patientCallFeedback')}
            style={{ bright: true }}
            inputStyle={{ height: 100 }}
            value={patientCallFeedback}
            setValue={feedback => setPatientCallFeedback(feedback)}
          />
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default RCOpsDetailsDialog
