import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isUserOfAnyAdminRole } from 'utils/authUtils'
import {
  calculatePlanLimits,
  isOnTrial,
  isOnChurnedPlan,
  isOnGrinSubscription,
  isGPPlan,
  isOnPracticeLegacy1,
  isOnFreePlan
} from 'utils/billingUtils'

export default () => {
  const userGrinPlan = useSelector(state => state.practiceReducer.billing.grinPlan)
  const contextPracticeGrinPlan = useSelector(state => state.multiPracticeReducer.context?.grinPlan)
  const planOverrides = useSelector(state => state.practiceReducer.billing.planOverrides)

  const isAdmin = useMemo(() => isUserOfAnyAdminRole(), [])
  const grinPlan = useMemo(
    () => (isAdmin ? contextPracticeGrinPlan : userGrinPlan),
    [isAdmin, userGrinPlan, contextPracticeGrinPlan]
  )
  const planLimits = useMemo(() => calculatePlanLimits(planOverrides, grinPlan), [planOverrides, grinPlan])

  const isTrialPlan = useMemo(() => isOnTrial(grinPlan), [grinPlan])
  const isChurnedPlan = useMemo(() => isOnChurnedPlan(grinPlan), [grinPlan])
  const isFreePlan = useMemo(() => isOnFreePlan(grinPlan), [grinPlan])
  const isLegacy1Plan = useMemo(() => isOnPracticeLegacy1(grinPlan), [grinPlan])
  const isSubscriptionPlan = useMemo(() => isOnGrinSubscription(grinPlan), [grinPlan])
  const planSettings = useMemo(() => JSON.parse(grinPlan?.settings || '{}'), [grinPlan])
  const parsedPlanOverrides = useMemo(() => JSON.parse(planOverrides || '{}'), [planOverrides])

  return {
    ...planLimits,
    isTrialPlan,
    isChurnedPlan,
    isFreePlan,
    isLegacy1Plan,
    isSubscriptionPlan,
    grinPlanKey: grinPlan?.key,
    grinPlanGroup: grinPlan?.planGroup,
    withScanTracking: grinPlan?.withScanTracking,
    isHI: planSettings.isHI,
    isGI: planSettings.isGI,
    isOnHiOrGiPlan: planSettings.isHI || planSettings.isGI,
    isGP: grinPlan ? isGPPlan(grinPlan.key) : null,
    parsedPlanOverrides
  }
}
