import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import GrinMenuItem from '../../menu/GrinMenuItem'
import DazzedParagraph12 from '../../text/DazzedParagraph12'
import { useTranslation } from 'react-i18next'
import { InfoIcon } from '../../icons'
import StatusPickerOption from './StatusPickerOption'

const useStyles = makeStyles(theme => ({
  menuTitle: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  info: {
    cursor: 'pointer'
  },
  bottomSpacer: {
    height: 10
  }
}))

const StatusPickerDropdownBody = ({
  statusOptions = [],
  onInfoClick = () => {},
  onSelect,
  selectedStatus,
  showOnlyAssignableStatuses = true
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const isStatusActionable = useCallback(
    status => !showOnlyAssignableStatuses || (status?.isManualAssignmentAllowed && status?.key !== selectedStatus?.key),
    [selectedStatus?.key, showOnlyAssignableStatuses]
  )

  const handleSelect = useCallback(
    status => {
      if (isStatusActionable(status)) {
        onSelect(status)
      }
    },
    [isStatusActionable, onSelect]
  )

  return (
    <div>
      <div className={classes.menuTitle}>
        <div>
          <DazzedParagraph12>{t('statuses.menu.title')}</DazzedParagraph12>
        </div>
        <div className={classes.info} onClick={onInfoClick}>
          <InfoIcon color="#BCB8B8" width={16} height={16} />
        </div>
      </div>

      {statusOptions.map(status => (
        <GrinMenuItem key={status.id} nonActionable={!isStatusActionable(status)} onClick={() => handleSelect(status)}>
          <StatusPickerOption
            status={status}
            isSelected={status.id === selectedStatus?.id}
            isDisabled={!isStatusActionable(status)}
          />
        </GrinMenuItem>
      ))}

      <div className={classes.bottomSpacer}></div>
    </div>
  )
}

export default StatusPickerDropdownBody
