export const TeethTrackingStatuses = {
  Tracking: 'tracking',
  TrackingNoMovement: 'trackingNoMovement',
  PartiallyTracking: 'partiallyTracking',
  NotTracking: 'notTracking',
  NoMovement: 'noMovement',
  Unknown: 'unknown',
  MissingTooth: 'missingTooth',
  Fast: 'fast',
  Moderate: 'moderate',
  Slow: 'slow'
}

export const TreatmentSTLsStatuses = {
  PendingFilesTransfer: 'pending-files-transfer',
  Completed: 'completed'
}

export const SPECIAL_PROVIDERS = ['spark']
