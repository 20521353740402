import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: 40,
    lineHeight: '40px',
    marginBottom: 30,
    fontFamily: 'Ogg',
    textAlign: 'center'
  }
}))

const SlideTitle = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.title}>{children}</div>
}

export default SlideTitle
