import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { calculateTimezoneHoursOffset } from 'utils/dateUtils'

export default () => {
  const practiceTimezone = useSelector(state => state.practiceReducer.details?.practiceTimezone)
  const accountOwnerTimezone = useSelector(state => state.practiceReducer.accountOwner?.user?.timezone)

  const timezone = useMemo(
    () => practiceTimezone || accountOwnerTimezone || 'UTC',
    [practiceTimezone, accountOwnerTimezone]
  )
  const utcOffset = useMemo(() => calculateTimezoneHoursOffset(timezone), [timezone])

  return {
    timezone,
    timezoneUtcOffset: utcOffset
  }
}
