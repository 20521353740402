import * as React from 'react'

const Elastics = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 3 }}
  >
    <path
      d="M2.73411 8.29678L2.45054 8.70858L2.73411 8.29678ZM3.01769 7.88497C2.09994 7.25299 1.5 6.1965 1.5 5H0.5C0.5 6.53937 1.27339 7.89798 2.45054 8.70858L3.01769 7.88497ZM1.5 5C1.5 3.067 3.067 1.5 5 1.5V0.5C2.51472 0.5 0.5 2.51472 0.5 5H1.5ZM5 1.5C6.1965 1.5 7.25299 2.09994 7.88497 3.01769L8.70858 2.45054C7.89798 1.27339 6.53937 0.5 5 0.5V1.5ZM10.5 4.5C13.8137 4.5 16.5 7.18629 16.5 10.5H17.5C17.5 6.63401 14.366 3.5 10.5 3.5V4.5ZM16.5 10.5C16.5 13.8137 13.8137 16.5 10.5 16.5V17.5C14.366 17.5 17.5 14.366 17.5 10.5H16.5ZM10.5 16.5C7.18629 16.5 4.5 13.8137 4.5 10.5H3.5C3.5 14.366 6.63401 17.5 10.5 17.5V16.5ZM7.88497 3.01769C8.44787 3.83512 9.42621 4.5 10.5 4.5V3.5C9.83236 3.5 9.13335 3.06738 8.70858 2.45054L7.88497 3.01769ZM2.45054 8.70858C3.06738 9.13335 3.5 9.83236 3.5 10.5H4.5C4.5 9.42621 3.83512 8.44787 3.01769 7.88497L2.45054 8.70858Z"
      fill="#001335"
    />
  </svg>
)

export default Elastics
