import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Trans, useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Grid } from '@material-ui/core'
import FormInput from 'components/common/FormInput'
import { Calendar5 as CalendarIcon } from 'components/common/icons'
import { Clock as ClockIcon } from 'components/common/icons'
import SelectInput from 'components/common/SelectInput'
import moment from 'moment'
import { isUserOfAnyAdminRole } from 'utils/authUtils'
import DazzedHeading16 from 'components/common/text/DazzedHeading16'
import useScheduledMessages from './useScheduledMessages'

const useStyles = makeStyles(theme => ({
  root: {
    width: '800px',
    maxWidth: '100%',
    padding: '20px 60px'
  },
  bodyContainer: {
    margin: '20px 0'
  },
  datePicker: {
    marginTop: 5,
    boxShadow: '0px 0px 25px -5px rgba(0, 0, 0, 0.25);'
  },
  centerText: {
    textAlign: 'center'
  }
}))

const ScheduleMessagePopup = ({ onSend = () => {}, isOpen = false, onClose = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    recipientTimezone,
    selectedDate,
    selectedTime,
    setSelectedDate,
    setSelectedTime,
    errors,
    maxDate,
    timeOptions,
    recipientScheduleTime,
    recipintTimezoneOffset,
    handleSend
  } = useScheduledMessages({ onSend, onClose, isModalOpen: isOpen })

  return (
    <BaseModal
      open={isOpen}
      title={t('pages.patients.selectedPatient.chat.scheduleMessagePopup.title')}
      onPrimaryBtnClick={handleSend}
      primaryLabel={t('pages.patients.selectedPatient.chat.scheduleMessagePopup.submitButton')}
      handleClose={onClose}
      secondaryLabel={t('general.cancel')}
      onSecondaryBtnClick={onClose}
      className={classes.root}
      largerButtons
    >
      <Grid container className={classes.bodyContainer}>
        <Grid item md={6} xs={12} className={classes.datePicker}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="static"
              disablePast
              onChange={value => setSelectedDate(moment(value))}
              value={selectedDate}
              maxDate={maxDate}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item md={5} xs={12}>
          <FormInput
            style={{ bright: true, thick: true }}
            value={selectedDate?.format('MMMM DD, YYYY')}
            setValue={() => {}}
            iconComponent={<CalendarIcon />}
          />
          <SelectInput
            style={{ bright: true, thick: true }}
            value={selectedTime}
            onChange={({ value }) => setSelectedTime(value)}
            iconComponent={<ClockIcon />}
            options={timeOptions}
            errorMessage={errors?.time}
          />
          {recipientTimezone && recipientTimezone !== moment.tz.guess() && (
            <Trans
              i18nKey={'pages.patients.selectedPatient.chat.scheduleMessagePopup.differentTimezonesMessage'}
              values={{
                time: moment(recipientScheduleTime).format('hh:mm a'),
                timezoneOffset: recipintTimezoneOffset
              }}
            ></Trans>
          )}
        </Grid>
      </Grid>
      {isUserOfAnyAdminRole() && (
        <DazzedHeading16 className={classes.centerText}>
          {t('pages.patients.selectedPatient.chat.scheduleMessagePopup.messageWilNotBeSeenUntilSent')}
        </DazzedHeading16>
      )}
    </BaseModal>
  )
}

export default ScheduleMessagePopup
