import { Grid } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SendBrushingInstructions as SendBrushingInstructionsIcon } from 'components/common/icons'

const SendBrushingInstructions = () => {
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={1} wrap="nowrap">
      <Grid item xs={2}>
        <SendBrushingInstructionsIcon />
      </Grid>
      <Grid item xs={10}>
        {t('dialogs.hiPatientGuidelinesViewer.actions.sendBrushingInstructions')}
      </Grid>
    </Grid>
  )
}

export default SendBrushingInstructions
