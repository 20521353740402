import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer'
  },
  label: {
    fontSize: 13,
    marginLeft: 3,
    textDecoration: 'underline',
    color: 'var(--text-color-48)'
  },
  icon: {
    marginTop: 2
  }
}))

const AutochartHeaderButton = ({ icon, label, onClick = () => {} }) => {
  const classes = useStyles()

  return (
    <Grid container alignItems="center" onClick={onClick} className={classes.root}>
      {icon && (
        <Grid item className={classes.icon}>
          {icon}
        </Grid>
      )}
      <Grid item>
        <DazzedParagraph14 className={classes.label}>{label}</DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default AutochartHeaderButton
