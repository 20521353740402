import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import Actions from '../../../../actions'
import SendScanReview from 'components/common/icons/scan-item-actions/SendScanReview'
import ShareProgress from 'components/common/icons/scan-item-actions/ShareProgress'
import Autochart from 'components/common/icons/scan-item-actions/Autochart'
import { isMobile } from 'utils/mobileUtils'
import useRolePermissions from 'hooks/useRolePermissions'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { isScanEligableForBeforeAfter, scanHasScanSummaryCenterPose } from 'utils/scanUtils'
import useFeatureFlags from 'hooks/useFeatureFlags'
import ScanActionButton from '../ScanActionButton'
import { ShareScan, TreatmentTrackerIcon } from 'components/common/icons'
import { trackEvent } from 'utils/analyticsUtils'
import usePlanLimits from 'hooks/usePlanLimits'

const useStyles = makeStyles(theme => ({
  actionsContainer: {
    marginBottom: 20,
    lineHeight: 0
  },
  buttonContainer: {
    height: '27px',
    '&.withBorder': {
      borderRight: '1px solid var(--border-color-10)'
    }
  }
}))

const ScanItemActions = ({ scanIndex, scan }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()
  const dispatch = useDispatch()
  const { autochart: autochartFF, treatmentTracker: treatmentTrackerFF } = useFeatureFlags()
  const { isChurnedPlan } = usePlanLimits()

  const patient = useSelector(state => state.patientsReducer.patient)
  const treatment = useSelector(state => state.treatmentReducer.treatment)

  const isPatientActive = useMemo(
    () => patient.user?.isActive && !treatment?.currentStatus?.statusKey?.includes('transferred'),
    [patient, treatment]
  )

  const actions = useMemo(
    () =>
      [
        {
          icon: <SendScanReview />,
          label: scan.scanReviews?.items?.length
            ? t('pages.patients.selectedPatient.timeline.actions.sendAnotherReview')
            : t('pages.patients.selectedPatient.timeline.actions.sendReview'),
          onClick: () => dispatch(Actions.openScanReviewEditor(scan)),
          visible: !isChurnedPlan && !isMobile() && permissions.recordScanReviews && isPatientActive,
          elementId: `scan-review`
        },
        {
          icon: <ShareProgress />,
          label: t('pages.patients.selectedPatient.timeline.actions.shareProgress'),
          onClick: () =>
            dispatch(
              Actions.toggleBeforeAfterDialog({
                open: true,
                scanId: scan.id,
                patientId: patient.id,
                analytics: {
                  source: 'timeline',
                  scanIndex
                }
              })
            ),
          visible:
            !isChurnedPlan &&
            scanIndex > 1 &&
            isPatientActive &&
            isScanEligableForBeforeAfter(scan) &&
            permissions.shareScanProgress,
          elementId: `share-progress`
        },
        {
          icon: <Autochart />,
          label: t('pages.patients.selectedPatient.timeline.actions.autochart'),
          onClick: () =>
            dispatch(Actions.autochartToggleDialog({ isOpen: true, grinScanId: scan.id, patientId: scan.patientId })),
          visible: !isMobile() && autochartFF && scan.autochartId,
          elementId: 'autochart'
        },
        {
          icon: <ShareScan />,
          label: t('pages.patients.selectedPatient.timeline.actions.shareScan'),
          onClick: () => {
            trackEvent('Share scan modal - opened', {
              analyticSource: 'Timeline'
            })
            dispatch(Actions.toggleShareNewScanModal({ isOpen: true, grinScanId: scan.id }))
          },
          visible: !isChurnedPlan && permissions.shareScan && scanHasScanSummaryCenterPose(scan),
          elementId: 'shareScan'
        },
        {
          icon: <TreatmentTrackerIcon />,
          label: t('pages.patients.selectedPatient.timeline.actions.treatmentTracker'),
          onClick: () => {
            trackEvent('Treatment tracker popup - opened', {
              grinScanId: scan.id,
              patientId: patient.id
            })
            dispatch(Actions.setTreatmentTrackerModalOpen({ isOpen: true, grinScanId: scan.id }))
          },
          visible:
            !isMobile() &&
            scan.scanSummaryStatus === 'completed' &&
            !!treatmentTrackerFF &&
            scan.treatmentTrackerStatus === 'completed' &&
            !!scan.treatmentTrackerStatus,
          elementId: 'treatmentTracker'
        },
        {
          label: t('pages.patients.selectedPatient.timeline.actions.regenerateScan'),
          onClick: () => {
            dispatch(Actions.qaToggleRegenerateScanModal({ isOpen: true, grinScanId: scan.id }))
          },
          visible: !isMobile() && permissions.regenerateScan,
          elementId: 'regenerateScan'
        }
      ].filter(action => action.visible),
    [
      scan,
      t,
      permissions,
      isPatientActive,
      scanIndex,
      autochartFF,
      dispatch,
      patient,
      isChurnedPlan,
      treatmentTrackerFF
    ]
  )

  return (
    <Grid container justifyContent="flex-start" alignItems="center" className={classes.actionsContainer}>
      {actions.map((action, i) => (
        <Grid
          item
          key={`scan-action-${i}`}
          className={[classes.buttonContainer, i < actions.length - 1 ? 'withBorder' : ''].join(' ')}
        >
          <ScanActionButton
            label={action.label}
            icon={action.icon}
            onClick={action.onClick}
            className={action.elementId}
            disabled={action.disabled}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default ScanItemActions
