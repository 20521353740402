import { makeStyles } from '@material-ui/styles'
import ImagesViewer from 'components/common/ImagesViewer'
import React, { useState } from 'react'

const useStyles = makeStyles({
  image: {
    cursor: 'zoom-in'
  }
})

const SlideImage = ({ src, className, alt }) => {
  const classes = useStyles()

  const [isImageZoomed, setIsImageZoomed] = useState(false)

  return (
    <>
      <img
        alt={alt}
        className={[classes.image, className].join(' ')}
        src={src}
        onClick={() => setIsImageZoomed(true)}
      />
      <ImagesViewer
        isOpen={isImageZoomed}
        closeImageViewer={() => setIsImageZoomed(false)}
        imageUrls={[{ src, alt }]}
      />
    </>
  )
}

export default SlideImage
