import { AsyncStatus } from 'consts'
import Actions from '../actions'

const initialState = {
  requestSTLs: {
    status: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.REQUEST_PROVIDER_STLS:
      return {
        ...state,
        requestSTLs: {
          status: AsyncStatus.Loading
        }
      }
    case Actions.REQUEST_PROVIDER_STLS_RECEIVED:
      return {
        ...state,
        requestSTLs: {
          status: AsyncStatus.Completed
        }
      }
    case Actions.REQUEST_PROVIDER_STLS_FAILED:
      return {
        ...state,
        requestSTLs: {
          status: AsyncStatus.Failed
        }
      }
    case Actions.CLEAR_REQUEST_PROVIDER_STLS_STATUS:
      return {
        ...state,
        requestSTLs: {
          status: null
        }
      }
    default:
      return state
  }
}
