import React, { useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import OggHeading40 from 'components/common/text/OggHeading40'
import SavedReplies from '../SavedReplies'
import WelcomeMessage from './WelcomeMessage'
import { CircularProgress, Grid } from '@material-ui/core'
import Actions from 'actions'
import useRolePermissions from 'hooks/useRolePermissions'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import { REMOTE_MONITORING_PROGRAM, REMOTE_CONSULTATION_PROGRAM, SERVICE_ACCOUNT_PROGRAM } from 'consts/appConsts'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useLocation } from 'react-router'

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: 30
  },
  loader: {
    height: 500
  },
  welcomeMessageHeader: {
    marginBottom: 30
  }
}))

const CustomMessages = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { state: pathState } = useLocation()

  const accountOwnerId = useSelector(state => state.practiceReducer.accountOwner?.id)
  const { data: welcomeMessages } = useSelector(state => state.profileReducer.welcomeMessages)
  const { data: customTemplates } = useSelector(state => state.profileReducer.customTemplates)
  const { data: systemTemplates } = useSelector(state => state.profileReducer.systemTemplates)
  const { permissions } = useRolePermissions()
  const { isRcEnabled } = usePracticeConfiguration()

  const isLoading = useMemo(
    () => !welcomeMessages || !customTemplates || !systemTemplates,
    [welcomeMessages, customTemplates, systemTemplates]
  )

  useEffect(() => {
    !welcomeMessages && accountOwnerId && dispatch(Actions.fetchWelcomeMessages())
  }, [dispatch, welcomeMessages, accountOwnerId])

  return (
    <>
      <OggHeading40 textAlign="left" className={classes.heading}>
        {t('pages.accountSettings.customMessages.title')}
      </OggHeading40>
      {isLoading ? (
        <Grid container justifyContent="flex-end" alignItems="center" className={classes.loader}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {permissions.editWelcomeMessage && (
            <>
              <div className={classes.welcomeMessageHeader}>
                <DazzedHeading18>{t('pages.accountSettings.welcomeMessage.title')}</DazzedHeading18>
                <DazzedParagraph14>{t('pages.accountSettings.welcomeMessage.subtitle')}</DazzedParagraph14>
              </div>
              <WelcomeMessage program={REMOTE_MONITORING_PROGRAM} />
              {isRcEnabled && <WelcomeMessage program={REMOTE_CONSULTATION_PROGRAM} />}
              <WelcomeMessage program={SERVICE_ACCOUNT_PROGRAM} />
            </>
          )}
          <SavedReplies autoFocus={pathState?.focus === 'savedReplies'} />
        </>
      )}
    </>
  )
}

export default CustomMessages
