import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, IconButton } from '@material-ui/core'
import SmallDotIcon from 'components/common/icons/SmallDot'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import EditPencil from 'components/common/icons/patientBrief/EditPencil'
import RemoveElastic from 'components/common/icons/patientBrief/DisableCategory'
import { sortTeeth } from 'utils/giUtils'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  disableOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0.7,
    background: '#fff'
  }
}))

const ElasticListItem = ({
  name,
  color,
  elasticSelections = {},
  disabled,
  showActionButtons = true,
  onRemoveClicked = () => {},
  onEditClicked = () => {}
}) => {
  const classes = useStyles()

  const teethNames = useMemo(() => Object.keys(elasticSelections || {}), [elasticSelections])

  return (
    <Grid container alignItems="center" spacing={1} className={classes.root}>
      <Grid item>
        <SmallDotIcon color={color} style={{ marginBottom: 1 }} />
      </Grid>
      <Grid item>
        <DazzedParagraph14 strong>
          <span style={{ fontWeight: 'bold' }}>{name}</span>
          {teethNames.length ? (
            <>
              <span style={{ margin: '0 3px' }}>-</span>
              <span>{sortTeeth(teethNames).join(', ')}</span>
            </>
          ) : (
            <></>
          )}
        </DazzedParagraph14>
      </Grid>
      {showActionButtons && (
        <>
          <Grid item>
            <IconButton size="small" onClick={onEditClicked}>
              <EditPencil />
            </IconButton>
            <IconButton size="small" onClick={onRemoveClicked}>
              <RemoveElastic />
            </IconButton>
          </Grid>
        </>
      )}
      {disabled && <div className={classes.disableOverlay} />}
    </Grid>
  )
}

export default ElasticListItem
