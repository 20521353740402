// Action Types
const DOCTOR_DETAILS_REQUESTED = 'DOCTOR_DETAILS_REQUESTED'
const DOCTOR_DETAILS_RECEIVED = 'DOCTOR_DETAILS_RECEIVED'
const UPDATE_DOCTOR_DETAILS_REQUESTED = 'UPDATE_DOCTOR_DETAILS_REQUESTED'
const UPDATE_DOCTOR_DETAILS_RECEIVED = 'UPDATE_DOCTOR_DETAILS_RECEIVED'
const UPDATE_PRACTICE_DETAILS = 'UPDATE_PRACTICE_DETAILS'
const UPDATE_PRACTICE_DETAILS_RECEIVED = 'UPDATE_PRACTICE_DETAILS_RECEIVED'
const UPDATE_PRACTICE_DETAILS_FAILED = 'UPDATE_PRACTICE_DETAILS_FAILED'
const CHANGE_PROFILE_ITEM = 'CHANGE_PROFILE_ITEM'
const CREATE_DOCTOR_REQUESTED = 'CREATE_DOCTOR_REQUESTED'
const CREATE_DOCTOR_RECEIVED = 'CREATE_DOCTOR_RECEIVED'
const CREATE_DOCTOR_FAILED = 'CREATE_DOCTOR_FAILED'
const ADD_SCHEDULE = 'ADD_SCHEDULE'
const DELETE_SCHEDULE = 'DELETE_SCHEDULE'
const DOCTOR_PHOTO_UPDATE_REQUESTED = 'DOCTOR_PHOTO_UPDATE_REQUESTED'
const REMOVE_DOCTOR_PHOTO_REQUESTED = 'REMOVE_DOCTOR_PHOTO_REQUESTED'
const UPDATE_PRACTICE_LOGO = 'UPDATE_PRACTICE_LOGO'
const REMOVE_PRACTICE_LOGO = 'REMOVE_PRACTICE_LOGO'
const UPDATE_MESSAGING_PREFERENCES_REQUESTED = 'UPDATE_MESSAGING_PREFERENCES_REQUESTED'
const TIMEZONE_UPDATED = 'TIMEZONE_UPDATED'
const REQUEST_DOCTOR_PLANS = 'REQUEST_DOCTOR_PLANS'
const DOCTOR_PLANS_RECEIVED = 'DOCTOR_PLANS_RECEIVED'
const SET_WELCOME_POPUP_STATE = 'SET_WELCOME_POPUP_STATE'
const UPDATE_BILLING_INFO_REQUESTED = 'UPDATE_BILLING_INFO_REQUESTED'
const REQUEST_UPDATE_APP_SETTINGS = 'REQUEST_UPDATE_APP_SETTINGS'
const FETCH_DOCTOR_TAGS = 'FETCH_DOCTOR_TAGS'
const DOCTOR_TAGS_RECEIVED = 'DOCTOR_TAGS_RECEIVED'
const DOCTOR_TAGS_FAILED = 'DOCTOR_TAGS_FAILED'
const DOWNLOAD_QR_CODE = 'DOWNLOAD_QR_CODE'
const QR_CODE_DOWNLOADED = 'QR_CODE_DOWNLOADED'
const UPDATE_CLINIC_LOGO = 'UPDATE_CLINIC_LOGO'
const GET_ZIP_COORDINATES = 'GET_ZIP_COORDINATES'
const ZIP_COORDINATES_RECEIVED = 'ZIP_COORDINATES_RECEIVED'
const ZIP_COORDINATES_FAILED = 'ZIP_COORDINATES_FAILED'
const GET_AREA_OF_COVERAGE = 'GET_AREA_OF_COVERAGE'
const GET_AREA_OF_COVERAGE_RECEIVED = 'UPDATE__AREA_OF_COVERAGE_RECEIVED'
const GET_AREA_OF_COVERAGE_FAILED = 'GET_AREA_OF_COVERAGE_FAILED'
const FETCH_EMBEDDED_DASHBOARD_URL = 'FETCH_EMBEDDED_DASHBOARD_URL'
const EMBEDDED_DASHBOARD_URL_RECEIVED = 'EMBEDDED_DASHBOARD_URL_RECEIVED'
const EMBEDDED_DASHBOARD_URL_FAILED = 'EMBEDDED_DASHBOARD_URL_FAILED'
const FETCH_NEW_DOCTOR_DASHBOARD_URL = 'FETCH_NEW_DOCTOR_DASHBOARD_URL'
const NEW_DOCTOR_DASHBOARD_URL_RECEIVED = 'NEW_DOCTOR_DASHBOARD_URL_RECEIVED'
const NEW_DOCTOR_DASHBOARD_URL_FAILED = 'NEW_DOCTOR_DASHBOARD_URL_FAILED'
const UPDATE_THEME_COLORS = 'UPDATE_THEME_COLORS'
const UPDATE_THEME_COLORS_RECEIVED = 'UPDATE_THEME_COLORS_RECEIVED'
const UPDATE_THEME_COLORS_FAILED = 'UPDATE_THEME_COLORS_RECEIVED'
const CREATE_MESSAGE_TEMPLATE = 'CREATE_MESSAGE_TEMPLATE'
const CREATE_MESSAGE_TEMPLATE_RECEIVED = 'CREATE_MESSAGE_TEMPLATE_RECEIVED'
const CREATE_MESSAGE_TEMPLATE_FAILED = 'CREATE_MESSAGE_TEMPLATE_FAILED'
const UPDATE_MESSAGE_TEMPLATE = 'UPDATE_MESSAGE_TEMPLATE'
const UPDATE_MESSAGE_TEMPLATE_RECEIVED = 'UPDATE_MESSAGE_TEMPLATE_RECEIVED'
const UPDATE_MESSAGE_TEMPLATE_FAILED = 'UPDATE_MESSAGE_TEMPLATE_FAILED'
const DELETE_MESSAGE_TEMPLATE = 'DELETE_MESSAGE_TEMPLATE'
const DELETE_MESSAGE_TEMPLATE_RECEIVED = 'DELETE_MESSAGE_TEMPLATE_RECEIVED'
const DELETE_MESSAGE_TEMPLATE_FAILED = 'DELETE_MESSAGE_TEMPLATE_FAILED'
const FETCH_CUSTOM_TEMPLATES = 'FETCH_CUSTOM_TEMPLATES'
const CUSTOM_TEMPLATES_RECEIVED = 'CUSTOM_TEMPLATES_RECEIVED'
const CUSTOM_TEMPLATES_FAILED = 'CUSTOM_TEMPLATES_FAILED'
const DUPLICATE_MESSAGE_TEMPLATE = 'DUPLICATE_MESSAGE_TEMPLATE'
const DUPLICATE_MESSAGE_TEMPLATE_FAILED = 'DUPLICATE_MESSAGE_TEMPLATE_FAILED'
const DUPLICATE_MESSAGE_TEMPLATE_RECEIVED = 'DUPLICATE_MESSAGE_TEMPLATE_RECEIVED'
const UPDATE_SELECTED_TEMPLATE = 'UPDATE_SELECTED_TEMPLATE'
const REQUEST_FEATURE_FLAGS = 'REQUEST_FEATURE_FLAGS'
const FEATURE_FLAGS_RECEIVED = 'FEATURE_FLAGS_RECEIVED'
const FEATURE_FLAGS_FAILED = 'FEATURE_FLAGS_FAILED'
const PRACTICE_FEATURE_FLAGS_RECEIVED = 'PRACTICE_FEATURE_FLAGS_RECEIVED'
const PREPARE_ORTHO_CONFIGURATION_FOR_SAVING = 'PREPARE_ORTHO_CONFIGURATION_FOR_SAVING'
const PREPARE_ORTHO_CONFIGURATION_FOR_SAVING_FAILED = 'PREPARE_ORTHO_CONFIGURATION_FOR_SAVING_FAILED'
const DELETE_DOCTOR_TAG = 'DELETE_DOCTOR_TAG'
const DELETE_DOCTOR_TAG_RECEIVED = 'DELETE_DOCTOR_TAG_RECEIVED'
const DELETE_DOCTOR_TAG_FAILED = 'DELETE_DOCTOR_TAG_FAILED'
const FETCH_WELCOME_MESSAGES = 'FETCH_WELCOME_MESSAGES'
const WELCOME_MESSAGES_RECEIVED = 'WELCOME_MESSAGES_RECEIVED'
const FETCH_WELCOME_MESSAGES_FAILED = 'FETCH_WELCOME_MESSAGES_FAILED'
const CREATE_WELCOME_MESSAGE = 'CREATE_WELCOME_MESSAGE'
const CREATE_WELCOME_MESSAGE_RECEIVED = 'CREATE_WELCOME_MESSAGE_RECEIVED'
const CREATE_WELCOME_MESSAGE_FAILED = 'CREATE_WELCOME_MESSAGE_FAILED'
const UPDATE_WELCOME_MESSAGE = 'UPDATE_WELCOME_MESSAGE'
const UPDATE_WELCOME_MESSAGE_RECEIVED = 'UPDATE_WELCOME_MESSAGE_RECEIVED'
const UPDATE_WELCOME_MESSAGE_FAILED = 'UPDATE_WELCOME_MESSAGE_FAILED'
const DOLPHIN_AUTHENTICATION = 'DOLPHIN_AUTHENTICATION'
const DOLPHIN_AUTHENTICATION_RECEIVED = 'DOLPHIN_AUTHENTICATIO_RECEIVED'
const DOLPHIN_AUTHENTICATION_FAILED = 'DOLPHIN_AUTHENTICATIO_FAILED'

const dolphinAuthentication = payload => ({
  type: DOLPHIN_AUTHENTICATION,
  payload
})

const dolphinAuthenticationReceived = payload => ({
  type: DOLPHIN_AUTHENTICATION_RECEIVED,
  payload
})

const dolphinAuthenticationFailed = payload => ({
  type: DOLPHIN_AUTHENTICATION_FAILED,
  payload
})

// Action Creators
const requestDoctorDetails = () => ({
  type: DOCTOR_DETAILS_REQUESTED
})

const doctorDetailsReceived = payload => ({
  type: DOCTOR_DETAILS_RECEIVED,
  payload
})

const requestDoctorPhotoUpdate = payload => ({
  type: DOCTOR_PHOTO_UPDATE_REQUESTED,
  payload
})

const removeDoctorPhoto = payload => ({
  type: REMOVE_DOCTOR_PHOTO_REQUESTED,
  payload
})

const updatePracticeLogo = payload => ({
  type: UPDATE_PRACTICE_LOGO,
  payload
})

const removePracticeLogo = payload => ({
  type: REMOVE_PRACTICE_LOGO,
  payload
})

const requestDoctorDetailsUpdate = payload => ({
  type: UPDATE_DOCTOR_DETAILS_REQUESTED,
  payload
})

const updatePracticeDetails = payload => ({
  type: UPDATE_PRACTICE_DETAILS,
  payload
})

const updateDoctorDetailsReceived = payload => ({
  type: UPDATE_DOCTOR_DETAILS_RECEIVED,
  payload
})

const updatePracticeDetailsReceived = payload => ({
  type: UPDATE_PRACTICE_DETAILS_RECEIVED,
  payload
})

const updatePracticeDetailsFailed = payload => ({
  type: UPDATE_PRACTICE_DETAILS_FAILED,
  payload
})

const changeProfileItem = payload => ({
  type: CHANGE_PROFILE_ITEM,
  payload
})

const requestCreateDoctor = () => ({
  type: CREATE_DOCTOR_REQUESTED
})

const createDoctorReceived = payload => ({
  type: CREATE_DOCTOR_RECEIVED,
  payload
})

const createDoctorFailed = payload => ({
  type: CREATE_DOCTOR_FAILED,
  payload
})

const addSchedule = () => ({
  type: ADD_SCHEDULE
})

const deleteSchedule = id => ({
  type: DELETE_SCHEDULE,
  payload: {
    id
  }
})

const requestUpdateMessagingPreferences = payload => ({
  type: UPDATE_MESSAGING_PREFERENCES_REQUESTED,
  payload
})

const requestDoctorPlans = payload => ({
  type: REQUEST_DOCTOR_PLANS,
  payload
})

const doctorPlansReceived = payload => ({
  type: DOCTOR_PLANS_RECEIVED,
  payload
})

const timezoneUpdated = payload => ({
  type: TIMEZONE_UPDATED,
  payload
})

const setWelcomePopupState = payload => ({
  type: SET_WELCOME_POPUP_STATE,
  payload
})

const requestUdpateBillingInfo = payload => ({
  type: UPDATE_BILLING_INFO_REQUESTED,
  payload
})

const requestUpdateAppSettings = payload => ({
  type: REQUEST_UPDATE_APP_SETTINGS,
  payload
})

const fetchDoctorTags = payload => ({
  type: FETCH_DOCTOR_TAGS,
  payload
})

const doctorTagsReceived = payload => ({
  type: DOCTOR_TAGS_RECEIVED,
  payload
})

const doctorTagsFailed = payload => ({
  type: DOCTOR_TAGS_FAILED,
  payload
})
const downloadQRCode = payload => ({
  type: DOWNLOAD_QR_CODE,
  payload
})

const qrCodeDownloaded = () => ({
  type: QR_CODE_DOWNLOADED
})

const updateClinicLogo = payload => ({
  type: UPDATE_CLINIC_LOGO,
  payload
})

const getZipCoordinates = payload => ({
  type: GET_ZIP_COORDINATES,
  payload
})

const zipCoordinatesReceived = payload => ({
  type: ZIP_COORDINATES_RECEIVED,
  payload
})

const zipCoordinatesFailed = payload => ({
  type: ZIP_COORDINATES_FAILED,
  payload
})

const getAreaOfCoverage = payload => ({
  type: GET_AREA_OF_COVERAGE,
  payload
})

const getAreaOfCoverageReceived = payload => ({
  type: GET_AREA_OF_COVERAGE_RECEIVED,
  payload
})

const getAreaOfCoverageFailed = payload => ({
  type: GET_AREA_OF_COVERAGE_FAILED,
  payload
})

const fetchEmbeddedDashboardUrl = payload => ({
  type: FETCH_EMBEDDED_DASHBOARD_URL,
  payload
})

const embeddedDashboardUrlReceived = payload => ({
  type: EMBEDDED_DASHBOARD_URL_RECEIVED,
  payload
})

const embeddedDashboardUrlFailed = payload => ({
  type: EMBEDDED_DASHBOARD_URL_FAILED,
  payload
})

const fetchNewDoctorDashboardUrl = payload => ({
  type: FETCH_NEW_DOCTOR_DASHBOARD_URL,
  payload
})

const newDoctorDashboardUrlReceived = payload => ({
  type: NEW_DOCTOR_DASHBOARD_URL_RECEIVED,
  payload
})

const newDoctorDashboardUrlFailed = payload => ({
  type: NEW_DOCTOR_DASHBOARD_URL_FAILED,
  payload
})

const updateThemeColors = payload => ({
  type: UPDATE_THEME_COLORS,
  payload
})

const updateThemeColorsReceived = payload => ({
  type: UPDATE_THEME_COLORS_RECEIVED,
  payload
})

const updateThemeColorsFailed = payload => ({
  type: UPDATE_THEME_COLORS_FAILED,
  payload
})

const createMessageTemplate = payload => ({
  type: CREATE_MESSAGE_TEMPLATE,
  payload
})
const createMessageTemplateFailed = payload => ({
  type: CREATE_MESSAGE_TEMPLATE_FAILED,
  payload
})
const createMessageTemplateRecieved = payload => ({
  type: CREATE_MESSAGE_TEMPLATE_RECEIVED,
  payload
})

const updateMessageTemplate = payload => ({
  type: UPDATE_MESSAGE_TEMPLATE,
  payload
})
const updateMessageTemplateFailed = payload => ({
  type: UPDATE_MESSAGE_TEMPLATE_FAILED,
  payload
})
const updateMessageTemplateRecieved = payload => ({
  type: UPDATE_MESSAGE_TEMPLATE_RECEIVED,
  payload
})

const deleteMessageTemplate = payload => ({
  type: DELETE_MESSAGE_TEMPLATE,
  payload
})
const deleteMessageTemplateFailed = payload => ({
  type: DELETE_MESSAGE_TEMPLATE_FAILED,
  payload
})
const deleteMessageTemplateRecieved = payload => ({
  type: DELETE_MESSAGE_TEMPLATE_RECEIVED,
  payload
})

const duplicateMessageTemplate = payload => ({
  type: DUPLICATE_MESSAGE_TEMPLATE,
  payload
})
const duplicateMessageTemplateFailed = payload => ({
  type: DUPLICATE_MESSAGE_TEMPLATE_FAILED,
  payload
})
const duplicateMessageTemplateRecieved = payload => ({
  type: DUPLICATE_MESSAGE_TEMPLATE_RECEIVED,
  payload
})

const fetchCustomTemplates = () => ({
  type: FETCH_CUSTOM_TEMPLATES
})

const customTemplatesReceived = payload => ({
  type: CUSTOM_TEMPLATES_RECEIVED,
  payload
})

const customTemplatesFailed = () => ({
  type: CUSTOM_TEMPLATES_FAILED
})
const updateSelectedTemplate = payload => ({
  type: UPDATE_SELECTED_TEMPLATE,
  payload
})

const requestFeatureFlags = payload => ({
  type: REQUEST_FEATURE_FLAGS,
  payload
})

const featureFlagsReceived = payload => ({
  type: FEATURE_FLAGS_RECEIVED,
  payload
})

const practiceFeatureFlagsReceived = payload => ({
  type: PRACTICE_FEATURE_FLAGS_RECEIVED,
  payload
})

const featureFlagsFailed = payload => ({
  type: FEATURE_FLAGS_FAILED,
  payload
})

const prepareOrthoConfigurationForSaving = payload => ({
  type: PREPARE_ORTHO_CONFIGURATION_FOR_SAVING,
  payload
})

const prepareOrthoConfigurationForSavingFailed = payload => ({
  type: PREPARE_ORTHO_CONFIGURATION_FOR_SAVING_FAILED,
  payload
})

const deleteDoctorTag = payload => ({
  type: DELETE_DOCTOR_TAG,
  payload
})

const deleteDoctorTagReceived = payload => ({
  type: DELETE_DOCTOR_TAG_RECEIVED,
  payload
})

const deleteDoctorTagFailed = payload => ({
  type: DELETE_DOCTOR_TAG_FAILED,
  payload
})

const fetchWelcomeMessages = payload => ({
  type: FETCH_WELCOME_MESSAGES,
  payload
})

const welcomeMessagesReceived = payload => ({
  type: WELCOME_MESSAGES_RECEIVED,
  payload
})

const fetchWelcomeMessagesFailed = payload => ({
  type: FETCH_WELCOME_MESSAGES_FAILED,
  payload
})

const createWelcomeMessage = payload => ({
  type: CREATE_WELCOME_MESSAGE,
  payload
})

const createWelcomeMessageReceived = payload => ({
  type: CREATE_WELCOME_MESSAGE_RECEIVED,
  payload
})

const createWelcomeMessageFailed = payload => ({
  type: CREATE_WELCOME_MESSAGE_FAILED,
  payload
})

const updateWelcomeMessage = payload => ({
  type: UPDATE_WELCOME_MESSAGE,
  payload
})

const updateWelcomeMessageReceived = payload => ({
  type: UPDATE_WELCOME_MESSAGE_RECEIVED,
  payload
})

const updateWelcomeMessageFailed = payload => ({
  type: UPDATE_WELCOME_MESSAGE_FAILED,
  payload
})

export default {
  ADD_SCHEDULE,
  DELETE_SCHEDULE,
  DOCTOR_DETAILS_REQUESTED,
  DOCTOR_DETAILS_RECEIVED,
  CHANGE_PROFILE_ITEM,
  CREATE_DOCTOR_REQUESTED,
  CREATE_DOCTOR_RECEIVED,
  CREATE_DOCTOR_FAILED,
  UPDATE_DOCTOR_DETAILS_REQUESTED,
  UPDATE_DOCTOR_DETAILS_RECEIVED,
  UPDATE_PRACTICE_DETAILS,
  UPDATE_PRACTICE_DETAILS_RECEIVED,
  UPDATE_PRACTICE_DETAILS_FAILED,
  REMOVE_DOCTOR_PHOTO_REQUESTED,
  DOCTOR_PHOTO_UPDATE_REQUESTED,
  REMOVE_PRACTICE_LOGO,
  UPDATE_PRACTICE_LOGO,
  UPDATE_MESSAGING_PREFERENCES_REQUESTED,
  TIMEZONE_UPDATED,
  REQUEST_DOCTOR_PLANS,
  DOCTOR_PLANS_RECEIVED,
  SET_WELCOME_POPUP_STATE,
  UPDATE_BILLING_INFO_REQUESTED,
  REQUEST_UPDATE_APP_SETTINGS,
  FETCH_DOCTOR_TAGS,
  DOCTOR_TAGS_FAILED,
  DOCTOR_TAGS_RECEIVED,
  DOWNLOAD_QR_CODE,
  QR_CODE_DOWNLOADED,
  UPDATE_CLINIC_LOGO,
  GET_ZIP_COORDINATES,
  ZIP_COORDINATES_RECEIVED,
  ZIP_COORDINATES_FAILED,
  GET_AREA_OF_COVERAGE,
  GET_AREA_OF_COVERAGE_RECEIVED,
  GET_AREA_OF_COVERAGE_FAILED,
  FETCH_EMBEDDED_DASHBOARD_URL,
  EMBEDDED_DASHBOARD_URL_FAILED,
  EMBEDDED_DASHBOARD_URL_RECEIVED,
  FETCH_NEW_DOCTOR_DASHBOARD_URL,
  NEW_DOCTOR_DASHBOARD_URL_FAILED,
  NEW_DOCTOR_DASHBOARD_URL_RECEIVED,
  UPDATE_THEME_COLORS,
  UPDATE_THEME_COLORS_RECEIVED,
  UPDATE_THEME_COLORS_FAILED,
  CREATE_MESSAGE_TEMPLATE,
  CREATE_MESSAGE_TEMPLATE_FAILED,
  CREATE_MESSAGE_TEMPLATE_RECEIVED,
  UPDATE_MESSAGE_TEMPLATE,
  UPDATE_MESSAGE_TEMPLATE_FAILED,
  UPDATE_MESSAGE_TEMPLATE_RECEIVED,
  DELETE_MESSAGE_TEMPLATE,
  DELETE_MESSAGE_TEMPLATE_FAILED,
  DELETE_MESSAGE_TEMPLATE_RECEIVED,
  FETCH_CUSTOM_TEMPLATES,
  CUSTOM_TEMPLATES_RECEIVED,
  CUSTOM_TEMPLATES_FAILED,
  DUPLICATE_MESSAGE_TEMPLATE,
  DUPLICATE_MESSAGE_TEMPLATE_FAILED,
  DUPLICATE_MESSAGE_TEMPLATE_RECEIVED,
  UPDATE_SELECTED_TEMPLATE,
  REQUEST_FEATURE_FLAGS,
  FEATURE_FLAGS_RECEIVED,
  FEATURE_FLAGS_FAILED,
  PRACTICE_FEATURE_FLAGS_RECEIVED,
  PREPARE_ORTHO_CONFIGURATION_FOR_SAVING,
  PREPARE_ORTHO_CONFIGURATION_FOR_SAVING_FAILED,
  DELETE_DOCTOR_TAG,
  DELETE_DOCTOR_TAG_RECEIVED,
  DELETE_DOCTOR_TAG_FAILED,
  FETCH_WELCOME_MESSAGES,
  WELCOME_MESSAGES_RECEIVED,
  FETCH_WELCOME_MESSAGES_FAILED,
  CREATE_WELCOME_MESSAGE,
  CREATE_WELCOME_MESSAGE_RECEIVED,
  CREATE_WELCOME_MESSAGE_FAILED,
  UPDATE_WELCOME_MESSAGE,
  UPDATE_WELCOME_MESSAGE_RECEIVED,
  UPDATE_WELCOME_MESSAGE_FAILED,
  DOLPHIN_AUTHENTICATION,
  DOLPHIN_AUTHENTICATION_FAILED,
  DOLPHIN_AUTHENTICATION_RECEIVED,
  dolphinAuthentication,
  dolphinAuthenticationFailed,
  dolphinAuthenticationReceived,
  addSchedule,
  requestDoctorDetails,
  doctorDetailsReceived,
  changeProfileItem,
  requestCreateDoctor,
  createDoctorReceived,
  createDoctorFailed,
  requestDoctorDetailsUpdate,
  updateDoctorDetailsReceived,
  updatePracticeDetails,
  updatePracticeDetailsReceived,
  updatePracticeDetailsFailed,
  deleteSchedule,
  requestDoctorPhotoUpdate,
  removeDoctorPhoto,
  updatePracticeLogo,
  removePracticeLogo,
  requestUpdateMessagingPreferences,
  timezoneUpdated,
  requestDoctorPlans,
  doctorPlansReceived,
  setWelcomePopupState,
  requestUdpateBillingInfo,
  requestUpdateAppSettings,
  fetchDoctorTags,
  doctorTagsFailed,
  doctorTagsReceived,
  downloadQRCode,
  qrCodeDownloaded,
  updateClinicLogo,
  getZipCoordinates,
  zipCoordinatesReceived,
  zipCoordinatesFailed,
  getAreaOfCoverage,
  getAreaOfCoverageReceived,
  getAreaOfCoverageFailed,
  fetchEmbeddedDashboardUrl,
  embeddedDashboardUrlFailed,
  embeddedDashboardUrlReceived,
  fetchNewDoctorDashboardUrl,
  newDoctorDashboardUrlFailed,
  newDoctorDashboardUrlReceived,
  updateThemeColors,
  updateThemeColorsReceived,
  updateThemeColorsFailed,
  createMessageTemplate,
  createMessageTemplateFailed,
  createMessageTemplateRecieved,
  updateMessageTemplate,
  updateMessageTemplateFailed,
  updateMessageTemplateRecieved,
  deleteMessageTemplate,
  deleteMessageTemplateFailed,
  deleteMessageTemplateRecieved,
  duplicateMessageTemplate,
  duplicateMessageTemplateFailed,
  duplicateMessageTemplateRecieved,
  fetchCustomTemplates,
  customTemplatesReceived,
  customTemplatesFailed,
  updateSelectedTemplate,
  requestFeatureFlags,
  featureFlagsReceived,
  featureFlagsFailed,
  practiceFeatureFlagsReceived,
  prepareOrthoConfigurationForSaving,
  prepareOrthoConfigurationForSavingFailed,
  deleteDoctorTag,
  deleteDoctorTagReceived,
  deleteDoctorTagFailed,
  fetchWelcomeMessages,
  welcomeMessagesReceived,
  fetchWelcomeMessagesFailed,
  createWelcomeMessage,
  createWelcomeMessageReceived,
  createWelcomeMessageFailed,
  updateWelcomeMessage,
  updateWelcomeMessageReceived,
  updateWelcomeMessageFailed
}
