import _ from 'lodash'
import { useMemo } from 'react'

export default ({ timelineItem = {} }) => {
  const numberOfReviews = useMemo(
    () => _.sumBy(timelineItem.payload?.scans, scan => scan.scanReviewIds?.length || 0),
    [timelineItem.payload]
  )

  const numberOfShares = useMemo(
    () => _.sumBy(timelineItem.payload?.scans, scan => scan.referralIds?.length || 0),
    [timelineItem.payload]
  )

  return {
    numberOfReviews,
    numberOfShares
  }
}
