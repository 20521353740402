import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import GrinCheckbox from 'components/common/GrinCheckbox'
import { ANNOUNCEMENTS_MODAL_Z_INDEX } from 'consts/modalConsts'

const useStyles = makeStyles({
  modalContainer: {
    zIndex: `${ANNOUNCEMENTS_MODAL_Z_INDEX} !important`
  },
  announcementImage: {
    width: '100%',
    paddingTop: 65,
    cursor: 'pointer'
  },
  featureAwarenessImage: {
    cursor: 'auto'
  }
})

const SalesAnnouncementModal = ({ isOpen, handleModalClose, content, announcementType, handleActionClicked }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [dontShowAgain, setDontShowAgain] = useState(false)

  return (
    <BaseModal
      className={classes.modalContainer}
      open={isOpen}
      handleClose={() => handleModalClose(dontShowAgain)}
      withCloseIcon={true}
    >
      <div className={classes.container}>
        <img
          className={[
            classes.announcementImage,
            announcementType === 'featuresAwareness' && classes.featureAwarenessImage
          ].join(' ')}
          src={content[0].image}
          alt=""
          onClick={() => handleActionClicked(dontShowAgain)}
        />
        <GrinCheckbox
          caption={t('announcements.dontShowAgainOnThisDevice')}
          checked={dontShowAgain}
          onChange={value => setDontShowAgain(value)}
        />
      </div>
    </BaseModal>
  )
}

export default SalesAnnouncementModal
