import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createPracticeMemberTagValue } from 'utils/tagUtils'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import usePatientTags from 'hooks/usePatientTags'
import { v4 } from 'uuid'

const UNASSIGNED = 'Unassigned'

export const useAssignPatient = ({ withUnassignOption = true }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { data: practiceMembers } = useSelector(state => state.profileReducer.practiceMembers)
  const { patient: assignPatient } = useSelector(state => state.practiceReducer.assignPatient)
  const { assigneeTag } = usePatientTags({ patient: assignPatient || {} })

  const [assignee, setAssignee] = useState()
  const [note, setNote] = useState('')

  const practiceMembersSelectOptions = useMemo(() => {
    const options = [
      withUnassignOption ? { value: UNASSIGNED, label: t('dialogs.assignPatient.unassigned') } : null,
      ...(practiceMembers || [])
        .filter(practiceMember => practiceMember.status !== 'invited')
        .map(practiceMember => ({
          value: practiceMember.id,
          label: createPracticeMemberTagValue(practiceMember),
          disabled: practiceMember.status === 'disabled'
        }))
    ]

    return options.filter(opt => !!opt)
  }, [practiceMembers, t, withUnassignOption])

  const handleClose = useCallback(() => {
    dispatch(Actions.setAssignPatient(null))
    setNote('')
  }, [dispatch])

  const handleSubmit = useCallback(() => {
    const pmToAssign = practiceMembersSelectOptions.find(option => option.value === assignee)
    trackEvent('Assign patient - doctor confirm assign patient', {
      newAssignee: pmToAssign.value,
      source: 'Assign patient dialog'
    })
    if (pmToAssign.value === UNASSIGNED) {
      dispatch(
        Actions.unassignPracticeMemberFromPatient({
          patientId: assignPatient.id,
          assigneePatientTagId: assigneeTag?.id
        })
      )
    } else {
      dispatch(
        Actions.assignPatientToPracticeMember({
          patientId: assignPatient.id,
          patientUserId: assignPatient.user.id || assignPatient.id,
          assigneeId: assignee,
          tag: pmToAssign.label,
          note: note
            ? {
                id: v4(),
                noteText: note,
                a_doctor: assignPatient.a_doctor
              }
            : null
        })
      )
    }

    handleClose()
  }, [assignPatient, assignee, dispatch, handleClose, note, practiceMembersSelectOptions, assigneeTag])

  useEffect(() => {
    setAssignee(assigneeTag?.assigneeId || UNASSIGNED)
  }, [assigneeTag])

  return {
    practiceMembersSelectOptions,
    setNote,
    note,
    assignee,
    setAssignee,
    handleSubmit,
    handleClose
  }
}
