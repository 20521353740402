import * as React from 'react'

const DoctorLocatorIcon = () => {
  return (
    <svg width="64" height="43" viewBox="0 0 64 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="11" width="64" height="32" rx="6" fill="#E1E5FE" />
      <path d="M0 29H44.5V16.5" stroke="#B4BBE3" strokeWidth="2" strokeDasharray="4 4" />
      <path
        d="M44.5 24L43.563 25.1713C44.1108 25.6096 44.8892 25.6096 45.437 25.1713L44.5 24ZM44.5 24C45.437 25.1713 45.4374 25.171 45.4378 25.1707L45.4388 25.1699L45.4412 25.168L45.4481 25.1624L45.4699 25.1447C45.488 25.1299 45.513 25.1094 45.5445 25.0831C45.6075 25.0306 45.6965 24.9554 45.8077 24.8584C46.0301 24.6646 46.3424 24.3835 46.7144 24.0238C47.4568 23.3061 48.4466 22.2671 49.4389 20.9771C51.397 18.4316 53.5 14.7334 53.5 10.5C53.5 5.52944 49.4706 1.5 44.5 1.5C39.5294 1.5 35.5 5.52944 35.5 10.5C35.5 14.7334 37.603 18.4316 39.5611 20.9771C40.5534 22.2671 41.5432 23.3061 42.2856 24.0238C42.6576 24.3835 42.9699 24.6646 43.1923 24.8584C43.3035 24.9554 43.3925 25.0306 43.4555 25.0831C43.487 25.1094 43.512 25.1299 43.5301 25.1447L43.5519 25.1624L43.5588 25.168L43.5612 25.1699L43.5622 25.1707C43.5626 25.171 43.563 25.1713 44.5 24Z"
        fill="#8FB4FF"
        stroke="white"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <circle cx="44.5" cy="10.5" r="3.5" fill="white" />
      <path d="M0 34H23V43H6C2.68629 43 0 40.3137 0 37V34Z" fill="#C6CCF0" />
      <rect x="32" y="34" width="23" height="9" fill="#C6CCF0" />
      <path d="M64 18L53 24V43H58C61.3137 43 64 40.3137 64 37V18Z" fill="#C6CCF0" />
      <rect y="18" width="12" height="6" fill="#C6CCF0" />
      <rect x="14" y="18" width="14" height="6" fill="#C6CCF0" />
      <path d="M0 16C0 13.2386 2.23858 11 5 11H12V16H0Z" fill="#C6CCF0" />
      <rect x="14" y="11" width="14" height="5" fill="#C6CCF0" />
      <path d="M27.5 43V29" stroke="#B4BBE3" strokeWidth="2" strokeDasharray="4 4" />
    </svg>
  )
}

export default DoctorLocatorIcon
