import React from 'react'
import { makeStyles, Grid, IconButton } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import GrinCheckbox from 'components/common/GrinCheckbox'
import CloseIcon from 'components/common/icons/Close'
import { useTranslation } from 'react-i18next'
import chatPromo1 from 'resources/chatPromo1.png'
import chatPromo2 from 'resources/chatPromo2.png'
import chatPromo3 from 'resources/chatPromo3.png'

const useStyles = makeStyles({
  container: {
    position: 'relative',
    padding: '32px 13px 8px 13px',
    borderRadius: 16,
    maxWidth: 245,
    backgroundColor: 'white',
    '&:after': {
      content: "''",
      position: 'absolute',
      right: '50%',
      bottom: -25,
      transform: 'translateX(50%)',
      background: 'var(--bg-color-10)',
      width: 25,
      height: 25,
      clipPath: 'polygon(0 0, 100% 0, 50% 50%)'
    }
  },
  promoWrapper: {
    width: 66,
    height: 66,
    border: '1px solid var(--border-color-19)',
    boxShadow: '0px 3.56667px 7.13334px -3.56667px rgba(0, 0, 0, 0.25)',
    borderRadius: 5,
    margin: 3,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textContainer: {
    maxWidth: 180,
    margin: '0 auto',
    marginTop: 20,
    marginBottom: 10
  },
  subtitle: {
    textAlign: 'center',
    marginTop: 10
  },
  ckeckbox: {
    padding: 5
  },
  checkboxLabel: {
    fontSize: 12
  },
  closeIcon: {
    padding: 3,
    position: 'absolute',
    right: 6,
    top: 4
  }
})

const images = [chatPromo1, chatPromo2, chatPromo3]

const PromotionsPopup = ({ handleClose, dontShowAgain, setDontShowAgain }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <IconButton
        onClick={handleClose}
        classes={{
          root: classes.closeIcon
        }}
      >
        <CloseIcon color="var(--text-color-12)" />
      </IconButton>
      <Grid container justifyContent="center">
        {images.map((image, i) => (
          <Grid key={i} item className={classes.promoWrapper}>
            <img src={image} alt={`promo${i}`} />
          </Grid>
        ))}
      </Grid>
      <div className={classes.textContainer}>
        <DazzedParagraph14 textAlign="center" bold>
          {t('pages.patients.selectedPatient.chat.promotions.popup.title')}
        </DazzedParagraph14>
        <DazzedParagraph12 className={classes.subtitle}>
          {t('pages.patients.selectedPatient.chat.promotions.popup.subtitle')}
        </DazzedParagraph12>
      </div>
      <GrinCheckbox
        caption={t('pages.patients.selectedPatient.chat.promotions.popup.checkbox')}
        checked={dontShowAgain}
        onChange={setDontShowAgain}
        color="var(--border-color-6)"
        className={classes.ckeckbox}
        captionClassName={classes.checkboxLabel}
      />
    </div>
  )
}

export default PromotionsPopup
