import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import CompareScansLinkScansBackground from 'components/common/icons/scanComparison/CompareScansLinkScansBackground'
import CompareScansLinked from 'components/common/icons/scanComparison/CompareScansLinked'
import CompareScansUnlinked from 'components/common/icons/scanComparison/CompareScansUnlinked'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  iconContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer'
  }
}))

const CompareScansLinkButton = ({ onClick = () => {}, isLinked }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <CompareScansLinkScansBackground />
      <div className={classes.iconContainer} onClick={onClick}>
        <Tooltip
          value={
            isLinked
              ? t('pages.patients.selectedPatient.timeline.compareScans.linkedTooltip')
              : t('pages.patients.selectedPatient.timeline.compareScans.unlinkedTooltip')
          }
        >
          {isLinked ? (
            <div style={{ marginTop: -3 }}>
              <CompareScansLinked />
            </div>
          ) : (
            <CompareScansUnlinked />
          )}
        </Tooltip>
      </div>
    </div>
  )
}

export default CompareScansLinkButton
