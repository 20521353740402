import React, { useCallback, useState } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import GrinRadio from 'components/common/GrinRadio'
import { Close } from 'components/common/icons'
import useBroadcast from 'hooks/useBroadcast'
import { isMobile } from 'utils/mobileUtils'
import { ROUTES } from 'consts'
import CloseBroadcastModalMobile from './CloseBroadcastModal.mobile'

const useStyles = ({ hasSelectedPatients }) =>
  makeStyles({
    container: {
      padding: '8px 5px 8px 15px'
    },
    title: {
      marginTop: 8,
      marginBottom: 8
    },
    linkText: {
      cursor: 'pointer',
      color: 'var(--text-color-3)',
      display: 'flex',
      alignItems: 'center'
    },
    selectedPatients: {
      color: 'var(--text-color-6)'
    },
    selectedNumber: {
      marginLeft: 5
    },
    selectedPatientsContainer: {
      display: 'flex'
    },
    closeContainer: {
      display: 'flex',
      cursor: 'pointer'
    },
    close: {
      marginRight: 3
    },
    checkbox: {
      padding: 0,
      marginRight: 8
    },
    nextButton: {
      cursor: ({ hasSelectedPatients }) => (hasSelectedPatients ? 'pointer' : 'auto'),
      color: ({ hasSelectedPatients }) => (hasSelectedPatients ? 'var(--text-color-3)' : 'var(--text-color-11)'),
      marginRight: 10,
      fontSize: 13
    }
  })({ hasSelectedPatients })

const SelectPatientsToBroadcast = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { closeBroadcastMode, onClickSelectAll, showBroadcastState: hasSelectedPatients } = useBroadcast()
  const classes = useStyles({
    hasSelectedPatients
  })

  const selectedPatients = useSelector(state => state.chatReducer.broadcast.selectedPatients.length)
  const excludedPatients = useSelector(state => state.chatReducer.broadcast.excluded.length)
  const allSelected = useSelector(state => state.chatReducer.broadcast.allSelected)

  const [isCloseBroadcastModalOpen, setIsCloseBroadcastModalOpen] = useState(false)

  const handleCloseBroadcastMode = useCallback(() => closeBroadcastMode('Cancel button clicked'), [closeBroadcastMode])
  const handleClickNext = useCallback(() => {
    if (hasSelectedPatients) {
      history.push(`${ROUTES.BROADCAST}?mode=broadcast`)
    }
  }, [history, hasSelectedPatients])

  const handleOpenModal = useCallback(() => setIsCloseBroadcastModalOpen(true), [])
  const handleCancelModal = useCallback(() => setIsCloseBroadcastModalOpen(false), [])

  const handleCloseModal = useCallback(() => {
    handleCancelModal()
    handleCloseBroadcastMode()
  }, [handleCancelModal, handleCloseBroadcastMode])

  return (
    <div className={classes.container}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <div
            className={classes.closeContainer}
            onClick={isMobile() ? handleOpenModal : handleCloseBroadcastMode}
            id="broadcast-close"
          >
            <Close color="var(--bg-color-7)" className={classes.close} />
            <DazzedParagraph12 className={classes.linkText} strong>
              {t('pages.patients.patientsList.broadcast.selectPatients.closeBroadcasts')}
            </DazzedParagraph12>
          </div>
        </Grid>
        {isMobile() && (
          <Grid item>
            <DazzedParagraph12
              thickness="bold"
              className={classes.nextButton}
              strong
              onClick={handleClickNext}
              id="broadcast-next"
            >
              {t('general.next')}
            </DazzedParagraph12>
          </Grid>
        )}
      </Grid>
      <DazzedParagraph16 bold className={classes.title}>
        {t('pages.patients.patientsList.broadcast.selectPatients.title')}
      </DazzedParagraph16>
      <Grid container>
        <Grid item xs={5}>
          <DazzedParagraph12
            thickness="bold"
            className={classes.linkText}
            onClick={onClickSelectAll}
            id="broadcast-selectAll"
          >
            {isMobile() && <GrinRadio size="small" checked={allSelected} className={classes.checkbox} />}
            {allSelected
              ? t('pages.patients.patientsList.broadcast.selectPatients.deselectAll')
              : t('pages.patients.patientsList.broadcast.selectPatients.selectAll')}
          </DazzedParagraph12>
        </Grid>
        <Grid item xs={7} className={classes.selectedPatientsContainer}>
          <DazzedParagraph12 thickness="bold" className={classes.selectedPatients}>
            {t('pages.patients.patientsList.broadcast.selectPatients.selectedPatients')}
          </DazzedParagraph12>
          <DazzedParagraph12 className={classes.selectedNumber}>
            {!allSelected
              ? selectedPatients
              : excludedPatients
              ? t('pages.patients.patientsList.broadcast.selectPatients.allSelectedWithExeption', {
                  number: excludedPatients
                })
              : t('pages.patients.patientsList.broadcast.selectPatients.allSelected')}
          </DazzedParagraph12>
        </Grid>
      </Grid>
      <CloseBroadcastModalMobile
        isOpen={isCloseBroadcastModalOpen}
        handleSubmit={handleCloseModal}
        handleCancel={handleCancelModal}
      />
    </div>
  )
}

export default SelectPatientsToBroadcast
