import React, { useCallback, useEffect, useMemo, useState } from 'react'
import StatusPicker from 'components/common/inputs/StatusPicker/StatusPicker'
import UpdateStatusModal from './Statuses/UpdateStatusModal'
import Actions from 'actions'
import useRolePermissions from 'hooks/useRolePermissions'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import usePlanLimits from 'hooks/usePlanLimits'

const PatientStatusUpdate = ({ id = '', patient, className = '', variant = 'rounded', analyticsSource }) => {
  const { permissions } = useRolePermissions()
  const dispatch = useDispatch()
  const { isChurnedPlan } = usePlanLimits()

  const isSavingStatus = useSelector(state => state.treatmentReducer.statuses.isSaving)

  const [status, setStatus] = useState(null)
  const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = useState(false)

  const patientStatus = useMemo(() => patient?.treatments?.items[0]?.currentStatus?.status, [patient])

  const didPatientDisableAccount = useMemo(() => {
    const currentTreatmentStatus = patient?.treatments?.items[0]?.currentStatus
    return (
      (currentTreatmentStatus?.status?.type === 'archived' || currentTreatmentStatus?.status?.type === 'transferred') &&
      currentTreatmentStatus?.setByUsername === patient?.username
    )
  }, [patient])

  const handleStatusSelection = useCallback(
    status => {
      if (!permissions.reactivatePatientAccount && didPatientDisableAccount) {
        return
      }

      setStatus(status)
      setIsUpdateStatusModalOpen(true)
    },
    [didPatientDisableAccount, permissions]
  )

  const cancelStatusChange = useCallback(() => {
    setStatus(patient.treatments?.items[0]?.currentStatus?.status)
    setIsUpdateStatusModalOpen(false)
  }, [patient])

  const handleStatusChangeSubmission = useCallback(
    ({ cycleInterval, cycleIntervalPeriodType, treatmentType, totalAlignersSets }) => {
      trackEvent('Patient status update - status updated', {
        statusKey: status.key,
        cycleInterval,
        cycleIntervalPeriodType,
        analyticsSource,
        treatmentType,
        totalAlignersSets
      })
      dispatch(
        Actions.updateTreatmentStatus({
          statusKey: status.key,
          treatmentId: patient.treatments?.items[0]?.id,
          cycleInterval,
          cycleIntervalPeriodType,
          treatmentType,
          totalAlignersSets
        })
      )
    },
    [dispatch, patient, status, analyticsSource]
  )

  useEffect(() => {
    setStatus(patientStatus)
  }, [patientStatus])

  return (
    <>
      <StatusPicker
        id={id}
        program={patient?.user?.program}
        onSelect={handleStatusSelection}
        variant={variant}
        selectedStatus={status}
        disabled={
          isChurnedPlan ||
          !permissions.editPatientScanSettings ||
          (!permissions.reactivatePatientAccount && didPatientDisableAccount)
        }
        className={className}
      />
      <UpdateStatusModal
        isOpen={isUpdateStatusModalOpen}
        selectedStatus={status}
        onCancel={cancelStatusChange}
        onSubmit={handleStatusChangeSubmission}
        onClose={() => setIsUpdateStatusModalOpen(false)}
        isLoading={isSavingStatus}
      />
    </>
  )
}

export default PatientStatusUpdate
