import React from 'react'
import { makeStyles } from '@material-ui/styles'
import PrimaryButton from './buttons/PrimaryButton'
import DazzedHeading36 from './text/DazzedHeading36'
import DazzedHeading24 from './text/DazzedHeading24'
import DazzedParagraph16 from './text/DazzedParagraph16'
import Ribbon from './Ribbon'
import { divideByThousands } from '../../utils/stringUtils'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    minWidth: 250,
    height: 400,
    border: '1px solid var(--border-color-25)',
    borderBottomRightRadius: 40,
    position: 'relative',
    margin: 20,
    '&:hover': {
      boxShadow: '1px 4px 15px rgba(0, 0, 0, 0.08)'
    }
  },
  topLine: {
    marginBottom: 30,
    width: '100%',
    height: 10,
    backgroundColor: ({ color }) => color
  },
  caption: {
    marginLeft: 20,
    fontSize: 20,
    marginBottom: 20
  },
  priceContainer: {
    marginBottom: 15
  },
  price: {
    display: 'inline-block',
    fontFamily: 'Arial',
    marginLeft: 20
  },
  currency: {
    display: 'inline-block',
    fontFamily: 'Arial',
    fontWeight: 600,
    fontSize: 22
  },
  slash: {
    display: 'inline-block',
    fontFamily: 'Arial',
    fontSize: 28
  },
  period: {
    display: 'inline-block',
    fontFamily: 'Arial',
    fontWeight: 500,
    fontSize: 22
  },
  scopePrice: {
    fontFamily: 'Arial',
    marginBottom: 15,
    marginLeft: 20,
    fontSize: 18
  },
  activeUsersCount: {
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: 20,
    width: 'auto',
    borderRadius: 10,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 20,
    backgroundColor: ({ color }) => color
  },
  actionButtonContainer: {
    position: 'absolute',
    bottom: 20,
    marginLeft: 20
  }
}))

const SelectPlanCard = ({
  caption,
  price,
  period,
  currency,
  activeUsersCount,
  color,
  scopePrice,
  description = '',
  actionLabel,
  isActive,
  onAction
}) => {
  const classes = useStyles({ color })
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      {isActive && <Ribbon text={t('dialogs.upgradePlan.currentPlan')} />}
      <div className={classes.topLine} />
      <DazzedHeading24 className={classes.caption}>{caption}</DazzedHeading24>

      {price != null && currency && (
        <div className={classes.priceContainer}>
          <DazzedHeading36 className={classes.price}>{`${currency}${divideByThousands(price)}`}</DazzedHeading36>
          <DazzedHeading24 className={classes.currency}>USD</DazzedHeading24>
          <DazzedHeading24 className={classes.slash}>/</DazzedHeading24>
          <DazzedHeading24 className={classes.period}>{t(`grinPlans.period.${period}Abbr`)}</DazzedHeading24>
        </div>
      )}

      {scopePrice != null && currency && (
        <DazzedParagraph16 className={classes.scopePrice} strong>
          {`+${currency}${scopePrice} USD ${t('dialogs.upgradePlan.perScope')}`}
        </DazzedParagraph16>
      )}

      {activeUsersCount && (
        <DazzedParagraph16 className={classes.activeUsersCount} strong>
          {t('dialogs.upgradePlan.upToActiveUsers', { activeUsersCount })}
        </DazzedParagraph16>
      )}

      {description}

      {!isActive && (
        <div className={classes.actionButtonContainer}>
          <PrimaryButton label={actionLabel} width={140} onClick={onAction} />
        </div>
      )}
    </div>
  )
}

export default SelectPlanCard
