import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../actions'
import ROUTES from '../../consts/routesConsts'
import SwooshLargeIcon from '../common/icons/SwooshLarge2'
import { trackEvent } from 'utils/analyticsUtils'
import BackButton from './BackButton'
import PracticeInfoForm from './PracticeInfoForm'

const useStyles = makeStyles(theme => ({
  pageContainer: {
    paddingTop: 50,
    paddingBottom: 50,
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  practiceInfoSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0',
    pointerEvents: 'none'
  }
}))

const PracticeInfo = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    isLoading: isLoadingAddressValidation,
    validationResult: isAddressValid,
    dirty: addressValidationDirty
  } = useSelector(state => state.billingReducer.addressValidation)

  const {
    clinicLogo,
    clinicHomepageUrl,
    practiceName,
    address,
    address2,
    city,
    state,
    zip,
    country,
    practicePhoneValue,
    practiceCountryCode,
    practiceEmailAddress,
    hasLocatorConsent,
    bio,
    username
  } = useSelector(state => state.authReducer)

  useEffect(() => {
    dispatch(Actions.loadPracticeInfoDraft())
    dispatch(Actions.resetAddressValidationResult())
  }, [dispatch])

  const handleFormSubmit = useCallback(() => {
    trackEvent('Sign up - practice info completed', {
      username,
      signupType: 'standard'
    })
    if (clinicHomepageUrl) {
      trackEvent('Sign up - practice info homepage url added', {
        username,
        clinicHomepageUrl,
        signupType: 'standard'
      })
    }

    dispatch(
      Actions.validateAddress({
        state,
        street: address,
        zip,
        country,
        city,
        forwardTo: ROUTES.TERMS
      })
    )
  }, [username, clinicHomepageUrl, address, state, zip, country, city, dispatch])

  const handleChangeForm = useCallback(value => dispatch(Actions.changeAuthForm(value)), [dispatch])

  const hanldeChangeLogo = useCallback(
    logo => {
      handleChangeForm({ key: 'clinicLogo', value: logo })
      if (logo) {
        trackEvent('Upload_logo_completed', {
          username
        })
      }
    },
    [handleChangeForm, username]
  )

  return (
    <div className={classes.pageContainer}>
      <SwooshLargeIcon className={classes.practiceInfoSwoosh} />
      <BackButton />
      <PracticeInfoForm
        clinicLogo={clinicLogo}
        clinicHomepageUrl={clinicHomepageUrl}
        practiceName={practiceName}
        address={address}
        address2={address2}
        city={city}
        state={state}
        zip={zip}
        country={country}
        practicePhoneValue={practicePhoneValue}
        practiceCountryCode={practiceCountryCode}
        practiceEmailAddress={practiceEmailAddress}
        hasLocatorConsent={hasLocatorConsent}
        bio={bio}
        handleFormSubmit={handleFormSubmit}
        hanldeChangeLogo={hanldeChangeLogo}
        handleChangeForm={handleChangeForm}
        isLoading={isLoadingAddressValidation}
        isAddressInvalid={addressValidationDirty && !isAddressValid}
      />
    </div>
  )
}

export default PracticeInfo
