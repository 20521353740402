import Frequency from 'components/Profile/PracticeGuidelines/Actions/Frequency'
import InstructForChewies from 'components/Profile/PracticeGuidelines/Actions/InstructForChewies'
import KeepAlignersOrGoBack from 'components/Profile/PracticeGuidelines/Actions/KeepAlignersOrGoBack'
import MessagePatient from 'components/Profile/PracticeGuidelines/Actions/MessagePatient'
import NotifyPractice from 'components/Profile/PracticeGuidelines/Actions/NotifyPractice'
import SendBrushingInstructions from 'components/Profile/PracticeGuidelines/Actions/SendBrushingInstructions'
import TypicalWireSequence from 'components/Profile/PracticeGuidelines/Actions/TypicalWireSequence'

export const GuidelineCategories = {
  AlignersTxTracking: 'alignersTxTracking',
  BracesTxTracking: 'bracesTxTracking',
  OralHygiene: 'oralHygiene',
  TxGoals: 'txGoals',
  Elastics: 'elastics',
  IPR: 'IPR',
  RPE: 'RPE',
  OtherAppliancesAndGuidelines: 'otherAppliancesAndGuidelines'
}

export const GuidelineItems = {
  AlignerFit: 'alignerFit',
  AlignerSlightlyUnseating: 'alignerSlightlyUnseating',
  AlignerSignificantlyUnseating: 'alignerSignificantlyUnseating',
  MissingAttaching: 'missingAttaching',
  ScanFrequency: 'scanFrequency',
  BrokenWireWireOutOfBracketBrokenChainMissingOTie: 'brokenWireWireOutOfBracketBrokenChainMissingOTie',
  ReadinessForWireChange: 'readinessForWireChange',
  PlaqueInflamationDetected: 'plaqueInflamationDetected',
  CriticalBadOralHygiene: 'criticalBadOralHygiene',
  WhenATxGoalIsReached: 'whenATxGoalIsReached',
  ValidatePatientCompliance: 'validatePatientCompliance',
  LowPatientCompliance: 'lowPatientCompliance',
  WhenReachingAlignerNumber: 'whenReachingAlignerNumber'
}

export const ActionTypes = {
  MessagePatient: 'messagePatient',
  NotifyPractice: 'notifyPractice',
  KeepAlignersOrGoBack: 'keepAlignersOrGoBack',
  InstructForChewies: 'instructForChewies',
  Frequency: 'frequency',
  TypicalWireSequence: 'typicalWireSequence',
  SendBrushingInstructionsToThePatient: 'sendBrushingInstructionsToThePatient'
}

export const ActionTypeComponents = {
  [ActionTypes.MessagePatient]: MessagePatient,
  [ActionTypes.NotifyPractice]: NotifyPractice,
  [ActionTypes.KeepAlignersOrGoBack]: KeepAlignersOrGoBack,
  [ActionTypes.InstructForChewies]: InstructForChewies,
  [ActionTypes.Frequency]: Frequency,
  [ActionTypes.TypicalWireSequence]: TypicalWireSequence,
  [ActionTypes.SendBrushingInstructionsToThePatient]: SendBrushingInstructions
}

export const PracticeGuidelinesConfig = {
  [GuidelineCategories.AlignersTxTracking]: {
    [GuidelineItems.AlignerFit]: [ActionTypes.MessagePatient, ActionTypes.NotifyPractice],
    [GuidelineItems.AlignerSlightlyUnseating]: [
      ActionTypes.MessagePatient,
      ActionTypes.KeepAlignersOrGoBack,
      ActionTypes.InstructForChewies,
      ActionTypes.NotifyPractice
    ],
    [GuidelineItems.AlignerSignificantlyUnseating]: [
      ActionTypes.MessagePatient,
      ActionTypes.KeepAlignersOrGoBack,
      ActionTypes.InstructForChewies,
      ActionTypes.NotifyPractice
    ],
    [GuidelineItems.MissingAttaching]: [ActionTypes.NotifyPractice],
    [GuidelineItems.ScanFrequency]: [ActionTypes.Frequency]
  },
  [GuidelineCategories.BracesTxTracking]: {
    [GuidelineItems.BrokenWireWireOutOfBracketBrokenChainMissingOTie]: [
      ActionTypes.MessagePatient,
      ActionTypes.NotifyPractice
    ],
    [GuidelineItems.ReadinessForWireChange]: [ActionTypes.NotifyPractice, ActionTypes.TypicalWireSequence],
    [GuidelineItems.ScanFrequency]: [ActionTypes.Frequency]
  },
  [GuidelineCategories.OralHygiene]: {
    [GuidelineItems.PlaqueInflamationDetected]: [
      ActionTypes.MessagePatient,
      ActionTypes.NotifyPractice,
      ActionTypes.SendBrushingInstructionsToThePatient
    ],
    [GuidelineItems.CriticalBadOralHygiene]: [ActionTypes.MessagePatient, ActionTypes.NotifyPractice]
  },
  [GuidelineCategories.TxGoals]: {
    [GuidelineItems.WhenATxGoalIsReached]: [ActionTypes.NotifyPractice]
  },
  [GuidelineCategories.Elastics]: {
    [GuidelineItems.ValidatePatientCompliance]: [ActionTypes.MessagePatient],
    [GuidelineItems.LowPatientCompliance]: [ActionTypes.MessagePatient, ActionTypes.NotifyPractice]
  },
  [GuidelineCategories.IPR]: {
    [GuidelineItems.WhenReachingAlignerNumber]: [ActionTypes.MessagePatient, ActionTypes.NotifyPractice]
  },
  [GuidelineCategories.RPE]: {
    [GuidelineItems.ValidatePatientCompliance]: [ActionTypes.MessagePatient],
    [GuidelineItems.LowPatientCompliance]: [ActionTypes.MessagePatient, ActionTypes.NotifyPractice]
  },
  [GuidelineCategories.OtherAppliancesAndGuidelines]: {}
}

export const PatientBriefCategoryOrder = {
  [GuidelineCategories.AlignersTxTracking]: 1,
  [GuidelineCategories.BracesTxTracking]: 2,
  [GuidelineCategories.OralHygiene]: 3,
  [GuidelineCategories.Elastics]: 4,
  [GuidelineCategories.TxGoals]: 5,
  [GuidelineCategories.IPR]: 6,
  [GuidelineCategories.RPE]: 7,
  [GuidelineCategories.OtherAppliancesAndGuidelines]: 8
}

export const PatientBriefModalMode = {
  NewPatient: 'new-patient',
  Update: 'update',
  View: 'view'
}

export const PatientBriefActions = {
  UntrackedTeeth: 'untrackedTeeth',
  Attachments: 'attachments',
  Class1Achieved: 'class1Achieved',
  CrossbiteCorrected: 'crossbiteCorrected',
  OverbiteCorrected: 'overbiteCorrected',
  MidlineCorrected: 'midlineCorrected',
  WireSequence: 'wireSequence',
  AlignerNumberReached: 'alignerNumberReached',
  NumberOfTurns: 'numberOfTurns',
  Elastics: 'elastics'
}

export const ElasticColors = [
  '#FF7A00',
  '#00D1FF',
  '#FF007A',
  '#BF56FF',
  '#43D48E',
  '#9F6E34',
  '#3A7FD0',
  '#FF1F1F',
  '#636989'
]
