import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import ExportTableIcon from 'components/common/icons/autochart/surfaces/ExportTableIcon'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: '4'
  },
  actionContainer: {
    cursor: 'pointer'
  },
  actionLabel: {
    textDecoration: 'underline',
    color: 'var(--text-color-48)',
    fontWeight: 500
  }
}))

const AutochartTableToolbar = ({ onExport = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const actions = useMemo(
    () => [
      {
        icon: <ExportTableIcon />,
        label: t('dialogs.autochart.table.actions.export'),
        onClick: onExport
      }
    ],
    [t, onExport]
  )

  return (
    <Grid container spacing={2} className={classes.root}>
      {actions.map((action, i) => (
        <Grid item key={`autochart-table-action-${i}`}>
          <Grid container alignItems="center" spacing={1} className={classes.actionContainer} onClick={action.onClick}>
            <Grid item>{action.icon}</Grid>
            <Grid item>
              <DazzedParagraph12 className={classes.actionLabel}>{action.label}</DazzedParagraph12>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default AutochartTableToolbar
