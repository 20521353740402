import * as React from 'react'

const GoBackAligners = props => (
  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 11L1 8C1 6.89543 1.89543 6 3 6L3 11L1 11Z" fill="#527195" />
    <rect x="4" y="11" width="5" height="2" transform="rotate(-90 4 11)" fill="#527195" />
    <path d="M7 11L7 6L9 6L9 11L7 11Z" fill="#527195" />
    <path d="M10 11L10 6C11.1046 6 12 6.89543 12 8L12 11L10 11Z" fill="#527195" />
    <path
      d="M1.5 12C0.671573 12 2.93554e-08 12.6716 6.55671e-08 13.5C1.01779e-07 14.3284 0.671573 15 1.5 15L20 15L20 12L1.5 12Z"
      fill="#527195"
    />
    <path
      d="M2 4.21051C2 2.43739 3.43739 1 5.21051 1H6.76393C8.23443 1 9.68474 0.657629 11 0C11 2.76142 8.76142 5 6 5H2V4.21051Z"
      fill="#527195"
    />
  </svg>
)

export default GoBackAligners
