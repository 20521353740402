import Actions from '../actions'
import { BILLING_PERIOD_MONTHLY } from '../consts/grinPlanConsts'
import {
  COUPON_ERRORS,
  GRIN_SCOPE_MIN_IN_ORDER,
  GRIN_SCOPE_TYPE_REGULAR,
  GRIN_SCOPE_TYPE_MINI
} from 'consts/billingConsts'
import { updateWhere } from 'utils/arrayUtils'

const initialState = {
  isLoading: false,
  paymentMethod: {
    isLoading: false,
    hasSavedCard: false,
    last4: '',
    brand: ''
  },
  upgradePlan: {
    isLoading: false,
    selectedPlanGroup: null,
    selectedPlanPeriod: BILLING_PERIOD_MONTHLY,
    selectedPlanScopesQuantity: null,
    selectedPlanScopesMiniQuantity: null,
    selectedPlanScopesShippingAddress: {},
    isUpgradePlanWarningModalOpen: false,
    isUpgradePlanForScopesWarningModalOpen: false,
    isSelectPlanModalOpen: false,
    isUpgradePlanDialogOpen: false
  },
  grinPlans: {},
  order: {
    isLoading: false,
    isLoadingTaxes: false,
    isOrderGrinKitsModalOpen: false,
    isScopesPaymentAcceptedModalOpen: false,
    grinKitsQuantity: {
      [GRIN_SCOPE_TYPE_REGULAR]: GRIN_SCOPE_MIN_IN_ORDER,
      [GRIN_SCOPE_TYPE_MINI]: null
    },
    taxAmount: 0,
    invoiceId: null,
    totalDiscount: 0,
    coupon: {
      coupon: {
        name: null,
        discountAmount: null
      },
      couponCode: null,
      isLoadingCoupon: false,
      couponError: null
    }
  },
  transactions: {
    list: [],
    isLoading: false
  },
  subscription: {},
  isSubmittingPlanRequest: false,
  seats: {
    data: {},
    isLoading: false
  },
  addressValidation: {
    isLoading: false,
    validationResult: false,
    dirty: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_DOCTOR_PAYMENT_METHOD:
    case Actions.SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          isLoading: true
        }
      }
    case Actions.DOCTOR_PAYMENT_METHOD_FAILED:
    case Actions.SAVE_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          isLoading: false
        }
      }
    case Actions.DOCTOR_PAYMENT_METHOD_RECEIVED:
    case Actions.SAVE_PAYMENT_METHOD_RECEIVED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          isLoading: false,
          hasSavedCard: !!action.payload,
          last4: action.payload?.last4 || '',
          brand: action.payload?.brand || ''
        }
      }
    case Actions.FETCH_GRIN_PLANS:
      return {
        ...state,
        isLoading: true
      }
    case Actions.GRIN_PLANS_RECEIVED:
      return {
        ...state,
        isLoading: false,
        grinPlans: action.payload
      }
    case Actions.GRIN_PLANS_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.SET_UPGRADE_PLAN_WARNING_MODAL_VISIBILITY:
      return {
        ...state,
        upgradePlan: {
          ...state.upgradePlan,
          isUpgradePlanWarningModalOpen: action.payload
        }
      }
    case Actions.SET_UPGRADE_PLAN_FOR_SCOPES_WARNING_MODAL_VISIBILITY:
      return {
        ...state,
        upgradePlan: {
          ...state.upgradePlan,
          isUpgradePlanForScopesWarningModalOpen: action.payload
        }
      }
    case Actions.SET_SELECT_PLAN_MODAL_VISIBILITY:
      return {
        ...state,
        upgradePlan: {
          ...state.upgradePlan,
          isSelectPlanModalOpen: action.payload
        }
      }
    case Actions.SET_SELECTED_PLAN_GROUP_AND_PERIOD:
      return {
        ...state,
        upgradePlan: {
          ...state.upgradePlan,
          selectedPlanGroup: action.payload.planGroup,
          selectedPlanPeriod: action.payload.planPeriod
        }
      }
    case Actions.SET_SELECTED_PLAN_SCOPE_QUANTITY_AND_SHIPPING_ADDRESS:
      return {
        ...state,
        upgradePlan: {
          ...state.upgradePlan,
          selectedPlanScopesQuantity: action.payload.planScopesQuantity,
          selectedPlanScopesMiniQuantity: action.payload.planScopesMiniQuantity,
          selectedPlanScopesShippingAddress: action.payload.planScopesShippingAddress
        }
      }
    case Actions.SET_UPGRADE_PLAN_DIALOG_VISIBILITY:
      return {
        ...state,
        upgradePlan: {
          ...state.upgradePlan,
          isUpgradePlanDialogOpen: action.payload
        },
        order: {
          ...state.order,
          invoiceId: action.payload ? null : state.order.invoiceId
        }
      }
    case Actions.UPGRADE_PLAN:
      return {
        ...state,
        upgradePlan: {
          ...state.upgradePlan,
          isLoading: true
        }
      }
    case Actions.UPGRADE_PLAN_RECEIVED:
      return {
        ...state,
        upgradePlan: {
          ...state.upgradePlan,
          isLoading: false,
          isUpgradePlanDialogOpen: false,
          selectedPlanGroup: null,
          selectedPlanPeriod: BILLING_PERIOD_MONTHLY
        }
      }
    case Actions.UPGRADE_PLAN_FAILED:
      return {
        ...state,
        upgradePlan: {
          ...state.upgradePlan,
          isLoading: false
        }
      }
    case Actions.SET_ORDER_GRIN_KITS_MODAL_VISIBILITY:
      return {
        ...state,
        order: {
          ...state.order,
          isOrderGrinKitsModalOpen: action.payload,
          invoiceId: action.payload ? null : state.order.invoiceId
        }
      }
    case Actions.CREATE_ORDER_GRIN_KITS:
      return {
        ...state,
        order: {
          ...state.order,
          isLoading: true
        }
      }
    case Actions.CREATE_ORDER_GRIN_KITS_RECEIVED:
      return {
        ...state,
        order: {
          ...state.order,
          invoiceId: null,
          isLoading: false,
          grinKitsQuantity: initialState.order.grinKitsQuantity,
          coupon: initialState.order.coupon
        }
      }
    case Actions.CREATE_ORDER_GRIN_KITS_FAILED:
      return {
        ...state,
        order: {
          ...state.order,
          isLoading: false
        }
      }
    case Actions.FETCH_DOCTOR_TRANSACTIONS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          isLoading: true
        }
      }
    case Actions.DOCTOR_TRANSACTIONS_RECEIVED:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          isLoading: false,
          list: action.payload
        }
      }
    case Actions.FETCH_TRANSACTION_PDF:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          list: updateWhere(
            state.transactions.list,
            t => t.id === action.payload.transactionId,
            t => (t.isLoadingPdf = true)
          )
        }
      }
    case Actions.FETCH_TRANSACTION_PDF_RECEIVED:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          list: updateWhere(
            state.transactions.list,
            t => t.id === action.payload.transactionId,
            t => {
              t.isLoadingPdf = false
              t.invoicePdf = action.payload?.invoicePdf
            }
          )
        }
      }
    case Actions.FETCH_TRANSACTION_PDF_FAILED:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          list: updateWhere(
            state.transactions.list,
            t => t.id === action.payload.transactionId,
            t => (t.isLoadingPdf = false)
          )
        }
      }
    case Actions.DOCTOR_TRANSACTIONS_FAILED:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          isLoading: false
        }
      }
    case Actions.FETCH_DOCTOR_SUBSCRIPTION:
      return {
        ...state,
        isLoading: true
      }
    case Actions.DOCTOR_SUBSCRIPTION_RECEIVED:
      return {
        ...state,
        isLoading: false,
        subscription: action.payload || {}
      }
    case Actions.DOCTOR_SUBSCRIPTION_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.SUBMIT_PLAN_UPGRADE_REQUEST:
    case Actions.SUBMIT_PLAN_CANCELLATION_REQUEST:
      return {
        ...state,
        isSubmittingPlanRequest: true
      }
    case Actions.SUBMIT_PLAN_UPGRADE_REQUEST_FAILED:
    case Actions.SUBMIT_PLAN_UPGRADE_REQUEST_RECEIVED:
    case Actions.SUBMIT_PLAN_CANCELLATION_REQUEST_FAILED:
    case Actions.SUBMIT_PLAN_CANCELLATION_REQUEST_RECEIVED:
      return {
        ...state,
        isSubmittingPlanRequest: false
      }
    case Actions.CALCULATE_ORDER_TAX:
      return {
        ...state,
        order: {
          ...state.order,
          isLoadingTaxes: true
        }
      }
    case Actions.ORDER_TAX_FAILED:
      return {
        ...state,
        order: {
          ...state.order,
          isLoadingTaxes: false
        }
      }
    case Actions.ORDER_TAX_RECEIVED:
      return {
        ...state,
        order: {
          ...state.order,
          isLoadingTaxes: false,
          taxAmount: action.payload.taxAmount,
          invoiceId: action.payload.invoiceId,
          totalDiscount: action.payload.totalDiscount
        }
      }
    case Actions.FETCH_DOCTOR_SEATS:
      return {
        ...state,
        seats: {
          ...state.seats,
          isLoading: true
        }
      }
    case Actions.FETCH_DOCTOR_SEATS_FAILED:
      return {
        ...state,
        seats: {
          ...state.seats,
          isLoading: false
        }
      }
    case Actions.FETCH_DOCTOR_SEATS_RECEIVED:
      return {
        ...state,
        seats: {
          ...state.seats,
          data: action.payload,
          isLoading: false
        }
      }
    case Actions.RESET_ADDRESS_VALIDATION_RESULT:
      return {
        ...state,
        addressValidation: {
          ...state.addressValidation,
          isLoading: false,
          validationResult: false,
          dirty: false
        }
      }
    case Actions.VALIDATE_ADDRESS:
      return {
        ...state,
        addressValidation: {
          ...state.addressValidation,
          isLoading: true,
          dirty: true
        }
      }
    case Actions.VALIDATE_ADDRESS_RECEIVED:
      return {
        ...state,
        addressValidation: {
          ...state.addressValidation,
          validationResult: action.payload.isValid,
          isLoading: false,
          dirty: true
        }
      }
    case Actions.VALIDATE_ADDRESS_FAILED:
      return {
        ...state,
        addressValidation: {
          ...state.addressValidation,
          isLoading: false,
          validationResult: false,
          dirty: false
        }
      }
    case Actions.DELETE_INVOICE_RECEIVED:
      return {
        ...state,
        order: {
          ...state.order,
          invoiceId: null,
          grinKitsQuantity: initialState.order.grinKitsQuantity
        }
      }
    case Actions.APPLY_COUPON_CODE:
      return {
        ...state,
        order: {
          ...state.order,
          coupon: { ...initialState.order.coupon, isLoadingCoupon: true, couponCode: action.payload.couponCode }
        }
      }
    case Actions.APPLY_COUPON_CODE_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          taxAmount: action.payload.tax / 100,
          coupon: {
            coupon: { ...action.payload.coupon, discountAmount: action.payload.coupon.discountAmount / 100 },
            isLoadingCoupon: false,
            couponError: false,
            couponCode: state.order.coupon.couponCode
          }
        }
      }
    case Actions.APPLY_COUPON_CODE_FAILURE:
      return {
        ...state,
        order: {
          ...state.order,
          coupon: {
            couponCode: null,
            coupon: state.coupon?.coupon ?? { ...initialState.order.coupon.coupon },
            isLoadingCoupon: false,
            couponError: action.payload?.couponError ?? COUPON_ERRORS.GENERAL
          }
        }
      }
    case Actions.SET_COUPON_MANUALLY:
      return {
        ...state,
        order: {
          ...state.order,
          coupon: {
            coupon: { ...action.payload, discountAmount: action.payload.discountAmount / 100 },
            isLoadingCoupon: false,
            couponError: false,
            couponCode: state.order.couponCode
          }
        }
      }
    case Actions.RESET_COUPON:
      return {
        ...state,
        order: {
          ...state.order,
          coupon: { ...initialState.order.coupon }
        }
      }
    case Actions.CLEAR_COUPON_CODE:
      return {
        ...state,
        order: {
          ...state.order,
          isLoadingTaxes: true
        }
      }
    case Actions.CLEAR_COUPON_CODE_RECEIVED:
      return {
        ...state,
        order: {
          ...state.order,
          isLoadingTaxes: false,
          taxAmount: action.payload.tax / 100,
          coupon: initialState.order.coupon
        }
      }
    case Actions.CLEAR_COUPON_CODE_FAILED:
      return {
        ...state,
        order: {
          ...state.order,
          isLoadingTaxes: false
        }
      }
    case Actions.SET_SCOPE_QUANTITY:
      return {
        ...state,
        order: {
          ...state.order,
          grinKitsQuantity: {
            ...state.order.grinKitsQuantity,
            [action.payload.scopeType]: action.payload.value
          }
        }
      }
    case Actions.SET_SCOPES_PAYMENT_ACCEPTED_MODAL_VISIBILITY:
      return {
        ...state,
        order: {
          ...state.order,
          isScopesPaymentAcceptedModalOpen: action.payload
        }
      }
    case Actions.REQUEST_ATTACH_CREDIT_CARD:
      return {
        ...state,
        isLoading: true
      }
    case Actions.ATTACH_CREDIT_CARD_FAILED:
    case Actions.ATTACH_CREDIT_CARD_RECEIVED:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
