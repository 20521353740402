import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import GrinMenu from 'components/common/menu/GrinMenu'
import { useDispatch, useSelector } from 'react-redux'
import FilterMenu from './FilterMenu'
import FilterMenuMobile from './FilterMenu.mobile'
import PatientsTagsFilter from './PatientsTagsFilter'
import PatientsSearch from './PatientsSearch'
import { TAGS_LIST_COMPRESSION_THRESHOLD_DENSE } from 'utils/tagUtils'
import FilterTagsList from 'components/common/tags/FilterTagsList'
import { isMobile } from 'react-device-detect'
import { IconButton } from '@material-ui/core'
import Filter from 'components/common/icons/Filter'
import { isEmpty } from 'lodash'
import useWindowSize from 'hooks/useWindowSize'
import { DEFAULT_TAGS_FILTER } from 'consts/filterConsts'
import { trackEvent } from 'utils/analyticsUtils'
import usePatientsFilterData from './hooks/usePatientsFilterData'
import Actions from 'actions'
import SavedViews from './SavedViews/SavedViews'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import NewSavedViewModal from './NewSavedViewModal'
import useGaFlags from 'hooks/useGaFlags'

const useStyles = ({ width }) =>
  makeStyles(theme => ({
    container: {
      boxShadow: isMobile && '0px 2px 8px rgba(0, 0, 0, 0.15)',
      minHeight: !isMobile && 75,
      paddingBottom: isMobile && 15,
      paddingTop: isMobile && 15,
      paddingLeft: !isMobile ? 20 : 15,
      backgroundColor: '#FAFAFA',
      borderBottom: '1px solid var(--border-color-3)',
      borderTop: '1px solid var(--border-color-3)'
    },
    filterButton: {
      border: '1px solid #C4C4C4',
      width: 40,
      height: 40,
      padding: 0
    },
    filterContainer: {
      maxWidth: 165,
      userSelect: 'none'
    },
    filter: {
      boxShadow: !isMobile ? '0px 4px 30px rgba(0, 0, 0, 0.15)' : 'none',
      padding: isMobile && 30
    },
    tagList: {
      width: ({ width }) => width && width - 900
    },
    mobileFilterTagList: {
      margin: '10px 10px 0px 10px'
    },
    clearAll: {
      cursor: 'pointer',
      color: 'var(--text-color-3)',
      textDecoration: 'underline'
    }
  }))({ width })

const PatientsFilter = ({
  onPatientsFilterChange = () => {},
  onFreeTextFilterChange = () => {},
  isFilterActivated,
  patientsFilter,
  freeTextFilter,
  isFilterOpen,
  setIsFilterOpen,
  hidePrograms
}) => {
  const { width } = useWindowSize()
  const classes = useStyles({ width })
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    assigneTags,
    programTags,
    treatmentTags,
    statusTags,
    customTags,
    scanTags,
    filterableSystemTags,
    appliedFilterTags,
    mapSelectedFiltersToSearchInput,
    applyFilters
  } = usePatientsFilterData()
  const gaFlags = useGaFlags()

  const [selectedFilters, setSelectedFilters] = useState(
    isEmpty(patientsFilter) ? { ...DEFAULT_TAGS_FILTER } : patientsFilter
  )

  const customTagsLoadingState = useSelector(state => state.practiceReducer.aggregatedCustomTags.status)

  const [filteredCustomTags, setFilteredCustomTags] = useState([...filterableSystemTags, ...customTags])

  const parsedTags = useMemo(() => {
    let parsedTags = []
    Object.keys(selectedFilters).forEach(filter => (parsedTags = [...parsedTags, ...selectedFilters[filter]]))

    return parsedTags
  }, [selectedFilters])
  const selectedFiltersToSearchInput = useMemo(
    () => mapSelectedFiltersToSearchInput({ selectedFilters }),
    [mapSelectedFiltersToSearchInput, selectedFilters]
  )

  const handleOpenFiltersMenu = useCallback(() => {
    setIsFilterOpen(true)
    setFilteredCustomTags([...filterableSystemTags, ...customTags])
  }, [setIsFilterOpen, filterableSystemTags, customTags])

  const handleFilterApply = useCallback(() => {
    trackEvent('Patients filter - tags filter applied', {
      tagFilters: parsedTags
    })

    const filtersToApply = mapSelectedFiltersToSearchInput({ selectedFilters })
    applyFilters(filtersToApply)
    setIsFilterOpen(false)
  }, [setIsFilterOpen, mapSelectedFiltersToSearchInput, selectedFilters, applyFilters, parsedTags])

  const isTagSelected = useCallback(
    tag => !!selectedFilters[tag.type]?.find(currTag => currTag.value === tag.value),
    [selectedFilters]
  )

  const handleClearTag = useCallback(
    tag => {
      trackEvent('Patients filter - clear tag', {
        value: tag.value
      })

      const updatedFilteres = {
        ...selectedFilters,
        [tag.type]: selectedFilters[tag.type].filter(currTag => currTag.value !== tag.value)
      }

      setSelectedFilters(updatedFilteres)
      applyFilters(mapSelectedFiltersToSearchInput({ selectedFilters: updatedFilteres }))
    },
    [setSelectedFilters, selectedFilters, mapSelectedFiltersToSearchInput, applyFilters]
  )

  const handleFilterChange = useCallback(
    selected => {
      isTagSelected(selected)
        ? setSelectedFilters(currSelected => {
            return {
              ...currSelected,
              [selected.type]: currSelected[selected.type].filter(tag => tag.value !== selected.value)
            }
          })
        : setSelectedFilters(currSelected => {
            return {
              ...currSelected,
              [selected.type]: [...currSelected[selected.type], selected]
            }
          })
    },
    [isTagSelected]
  )

  const handleClearSelectedFilters = useCallback(() => setSelectedFilters({ ...DEFAULT_TAGS_FILTER }), [])

  const handleClearAll = useCallback(() => applyFilters({}), [applyFilters])

  const handleCustomTagsFilter = useCallback(
    filter => {
      const mergedTags = [...filterableSystemTags, ...customTags]
      const filteredTags = mergedTags.filter(
        tag => tag.label.toLowerCase().includes(filter.toLowerCase()) || isTagSelected(tag)
      )

      setFilteredCustomTags(filteredTags)
    },
    [filterableSystemTags, customTags, isTagSelected]
  )

  const handleChangeNameFilter = useCallback(
    value => {
      onFreeTextFilterChange(value)
      if (value !== freeTextFilter) {
        trackEvent('Patients filter - Patients search applied', {
          value
        })
      }
    },
    [onFreeTextFilterChange, freeTextFilter]
  )

  const handleSavedViewSelected = useCallback(
    view => {
      trackEvent('Saved views - View selected', {
        view: view.name
      })
      applyFilters(view.filters)
    },
    [applyFilters]
  )

  useEffect(() => {
    if (!isFilterOpen) {
      setSelectedFilters({ ...DEFAULT_TAGS_FILTER, ...appliedFilterTags })
    }
  }, [isFilterOpen, appliedFilterTags])

  useEffect(() => {
    setFilteredCustomTags([...filterableSystemTags, ...customTags])
  }, [filterableSystemTags, customTags])

  useEffect(() => {
    if (isFilterOpen && !customTagsLoadingState) {
      dispatch(Actions.fetchAggregatedPracticeCustomTags())
    }
  }, [isFilterOpen, customTagsLoadingState, dispatch])

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" className={classes.container}>
        <Grid item xs={12}>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs={isMobile && 10} className={classes.searchContainer}>
              <PatientsSearch onNameFilter={handleChangeNameFilter} />
            </Grid>
            <Grid item className={classes.filterContainer}>
              {isMobile ? (
                <>
                  <IconButton className={classes.filterButton} aria-label={'filter'} onClick={handleOpenFiltersMenu}>
                    <Filter />
                  </IconButton>
                  <FilterMenuMobile
                    open={!!isFilterOpen}
                    onClose={handleFilterApply}
                    onOpen={handleOpenFiltersMenu}
                    onFilterSelected={handleFilterChange}
                    onClearAll={handleClearSelectedFilters}
                    onApply={handleFilterApply}
                    onTagsFilterChanged={handleCustomTagsFilter}
                    selectedFilters={selectedFilters}
                    customTags={filteredCustomTags}
                    treatments={treatmentTags}
                    programs={programTags}
                    statuses={statusTags}
                    assigneTags={assigneTags}
                    scanTags={scanTags}
                    customTagsLoadingState={customTagsLoadingState}
                  />
                </>
              ) : (
                <GrinMenu
                  disableMenuFocus={true}
                  className={classes.filter}
                  onOpen={handleOpenFiltersMenu}
                  onClose={handleFilterApply}
                  open={!!isFilterOpen}
                  transformOrigin={{
                    vertical: window.screen.width < 1100 ? 'center' : -61,
                    horizontal: 'center'
                  }}
                  triggerComponent={
                    <PatientsTagsFilter isFilterOpen={isFilterOpen} selectedFilters={selectedFilters} />
                  }
                >
                  <FilterMenu
                    onFilterSelected={handleFilterChange}
                    onClearAll={handleClearSelectedFilters}
                    onApply={handleFilterApply}
                    onTagsFilterChanged={handleCustomTagsFilter}
                    selectedFilters={selectedFilters}
                    customTags={filteredCustomTags}
                    treatments={treatmentTags}
                    programs={programTags}
                    statuses={statusTags}
                    scanTags={scanTags}
                    hidePrograms={hidePrograms}
                    assigneTags={assigneTags}
                    customTagsLoadingState={customTagsLoadingState}
                  />
                </GrinMenu>
              )}
            </Grid>
            <Grid item style={{ width: '80%' }}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item className={classes.tagList}>
                  {!isMobile && isFilterActivated && !isFilterOpen && (
                    <FilterTagsList
                      showIcon={false}
                      tags={parsedTags}
                      canDeleteCustom={true}
                      canDeleteSystem={true}
                      observeResize={false}
                      onDeleteTag={handleClearTag}
                      compressionThreshold={TAGS_LIST_COMPRESSION_THRESHOLD_DENSE}
                    />
                  )}
                </Grid>
                {!isMobile && (
                  <Grid item>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                      {isFilterActivated && (
                        <Grid item>
                          <DazzedParagraph14 strong className={classes.clearAll} onClick={handleClearAll}>
                            {t('pages.patients.patientsList.patientsTagFilter.clearLabel')}
                          </DazzedParagraph14>
                        </Grid>
                      )}
                      {gaFlags?.savedViews && (
                        <Grid item>
                          <SavedViews
                            isFilterActivated={isFilterActivated}
                            selectedFilters={selectedFiltersToSearchInput}
                            onSavedViewSelected={handleSavedViewSelected}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isMobile && isFilterActivated && !isFilterOpen && (
          <Grid item xs={12}>
            <FilterTagsList
              className={classes.mobileFilterTagList}
              showIcon={false}
              tags={parsedTags}
              canDeleteCustom={true}
              canDeleteSystem={true}
              observeResize={false}
              onDeleteTag={handleClearTag}
              compressionThreshold={TAGS_LIST_COMPRESSION_THRESHOLD_DENSE}
            />
          </Grid>
        )}
      </Grid>
      <NewSavedViewModal currentSelectedTags={selectedFilters} />
    </>
  )
}

export default PatientsFilter
