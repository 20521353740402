import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import BeforeAfterShareEmailIcon from 'components/common/icons/BeforeAfter/BeforeAfterShareEmail'
import BeforeAfterDownloadImageIcon from 'components/common/icons/BeforeAfter/BeforeAfterDownloadImage'
import { trackEvent } from 'utils/analyticsUtils'
import { isMobile } from 'utils/mobileUtils'
import { downloadFileFromUrl } from 'utils/fileUtils'

const useStyles = ({ disabled }) =>
  makeStyles(theme => ({
    root: {
      padding: 4
    },
    sharingOption: {
      cursor: disabled ? 'default' : 'pointer'
    },
    icon: {
      marginRight: '6px'
    },
    text: {
      color: disabled ? 'var(--text-color-21)' : 'var(--text-color-25)',
      textDecoration: 'underline'
    }
  }))

const BeforeAfterSharingOptions = ({ disabled = false, assetUrl, onOptionClicked }) => {
  const classes = useStyles({ disabled })()
  const { t } = useTranslation()

  const options = useMemo(
    () => [
      {
        id: 'share-email',
        label: t('dialogs.beforeAfter.sharingOptions.email'),
        icon: <BeforeAfterShareEmailIcon color={disabled ? 'var(--text-color-21)' : 'var(--text-color-25'} />
      },
      {
        id: 'download',
        label: t('dialogs.beforeAfter.sharingOptions.download'),
        icon: <BeforeAfterDownloadImageIcon color={disabled ? 'var(--text-color-21)' : 'var(--text-color-25'} />,
        onClick: () => {
          trackEvent('Before After - action', {
            action: 'download'
          })
          isMobile() ? downloadFileFromUrl(assetUrl) : window.open(assetUrl, '_blank')
        }
      }
    ],
    [t, disabled, assetUrl]
  )

  const handleShareOptionClicked = useCallback(
    option => {
      if (disabled) {
        return
      }

      option.onClick()
      onOptionClicked(option.id)
    },
    [disabled, onOptionClicked]
  )

  return (
    <Grid container spacing={2} className={classes.root}>
      {options
        .filter(option => option.onClick)
        .map((option, i) => (
          <Grid
            item
            key={`before-after-sharing-option-${i}`}
            className={classes.sharingOption}
            onClick={() => handleShareOptionClicked(option)}
          >
            <Grid container alignItems="center" spacing={1}>
              <span className={classes.icon}>{option.icon}</span>
              <DazzedParagraph14 className={classes.text} strong>
                {option.label}
              </DazzedParagraph14>
            </Grid>
          </Grid>
        ))}
    </Grid>
  )
}

export default BeforeAfterSharingOptions
