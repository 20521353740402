import { useCallback, useMemo } from 'react'

/**
 * @prop grinScans - the list of grinScans returned from the `useScanFeed` hook
 * @returns a flattened & formatted list of scan reviews with the following structure:
 * ```
 * {
 *  id,
 *  createdAt,
 *  video: { bucket, key, region } OR blob,
 *  isLocal: Boolean,
 *  reviewerName,
 *  reviewerRoleDescription,
 *  withAligner: Boolean
 * }
 * ```
 */
export default ({ grinScans = [] }) => {
  const mapScanReview = useCallback(
    ({ scan, scanReview }) => ({
      id: scanReview.id,
      createdAt: scanReview.createdAt,
      video: scanReview.video,
      isLocal: scanReview.isLocal,
      reviewerName: scanReview.reviewer?.name,
      reviewerRoleDescription: scanReview.reviewer?.roleDescription,
      withAligner: scan.withAligner
    }),
    []
  )

  const scanReviews = useMemo(
    () =>
      grinScans
        .map(scan => scan.scanReviews?.items?.map(scanReview => mapScanReview({ scan, scanReview })).flat())
        .flat()
        .sort((review1, review2) => new Date(review1.createdAt).getTime() - new Date(review2.createdAt).getTime()),
    [grinScans, mapScanReview]
  )

  return scanReviews
}
