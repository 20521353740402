import * as React from 'react'

function Refresh({ color = '#3C52EF', ...props }) {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.2436 12.7279C10.9005 15.0711 7.10148 15.0711 4.75834 12.7279C2.41519 10.3848 2.41519 6.58579 4.75834 4.24264C7.10148 1.89949 10.9005 1.89949 13.2436 4.24264"
        stroke={color}
        strokeLinecap="round"
      />
      <path d="M16.001 1.48535V6.98535H10.501L16.001 1.48535Z" fill={color} />
    </svg>
  )
}

export default Refresh
