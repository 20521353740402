import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'var(--bg-color-11)',
    padding: '12px 16px',
    margin: '4px',
    borderRadius: '6px',
    '&.loading': {
      padding: '12px',
      width: 'auto',
      borderRadius: 100
    }
  }
}))

const ShipbobActionButton = ({ label, onClick, isLoading }) => {
  const classes = useStyles()

  return (
    <ButtonBase
      className={[classes.root, isLoading ? 'loading' : ''].join(' ')}
      onClick={() => !isLoading && onClick()}
    >
      {isLoading ? <CircularProgress size={16} /> : <span>{label}</span>}
    </ButtonBase>
  )
}

export default ShipbobActionButton
