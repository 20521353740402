import { makeStyles } from '@material-ui/core'
import React from 'react'
import { animated } from 'react-spring'
import PrimaryButton from '../../common/buttons/PrimaryButton'
import SecondaryButton from '../../common/buttons/SecondaryButton'
import DazzedHeading24 from '../../common/text/DazzedHeading24'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  scanReviewPreview: {
    alignSelf: 'center',
    outline: 'none',
    width: '100%',
    borderRadius: 20,
    height: '55vh',
    margin: 0
  }
})

const ScanReviewPreview = ({ opacity, zIndex, display, video, additionalClasses, onSendClicked, onRetakeClicked }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <animated.div style={{ opacity, display }}>
      <div className={additionalClasses.scanReviewEditorContainer}>
        <div className={additionalClasses.videoColumn}>
          <div className={[classes.videoContainer, additionalClasses.videoContainer].join(' ')}>
            {video ? (
              <video
                src={video ? URL.createObjectURL(video) : null}
                controls
                muted={false}
                className={classes.scanReviewPreview}
              />
            ) : (
              <DazzedHeading24>{t('dialogs.scanReviewEditor.noVideoCaptured')}</DazzedHeading24>
            )}
          </div>
          <div className={additionalClasses.scanReviewActions}>
            <SecondaryButton
              wrapperClassName={additionalClasses.button}
              small
              label={t('dialogs.scanReviewEditor.retake')}
              onClick={onRetakeClicked}
            />
            <PrimaryButton
              wrapperClassName={additionalClasses.button}
              small
              label={t('dialogs.scanReviewEditor.send')}
              onClick={onSendClicked}
              disabled={!video}
            />
          </div>
        </div>
      </div>
    </animated.div>
  )
}

export default ScanReviewPreview
