import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import Logo from 'resources/logo.png'
import { ROUTES } from 'consts'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20,
    background: ({ variant }) => (variant === 'login' ? 'var(--bg-color-69)' : 'var(--bg-color-70)')
  },
  logo: {
    height: 24
  }
}))

const ReferralHeader = () => {
  const { pathname } = useLocation()
  const variant = useMemo(() => (pathname === ROUTES.REFERRAL_LOGIN ? 'login' : 'view'), [pathname])
  const classes = useStyles({ variant })

  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.root}>
      <Grid item>
        <img src={Logo} className={classes.logo} alt="Grin" />
      </Grid>
    </Grid>
  )
}

export default ReferralHeader
