import Actions from 'actions'
import { API, Auth, graphqlOperation, Storage } from 'aws-amplify'
import { Roles } from 'consts/authConsts'
import { DSORestrictedPaths, SupportRestrictedPaths } from 'consts/rolesConsts'
import ROUTES from 'consts/routesConsts'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { ofType } from 'redux-observable'
import { forkJoin, from, of } from 'rxjs'
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators'
import { isMobile } from 'utils/mobileUtils'
import { doctorsTotalPatients, searchDoctorSearchModels, totalPracticeMembers } from '../graphql/customQueries'
import { isUserOfRole } from '../utils/authUtils'
import { getEnvironment } from '../utils/awsUtils'
import {
  clearHelpPopupDetails,
  hideHelpWidget,
  openHelpPopup,
  setHelpPopupDetails,
  showHelpWidget
} from '../utils/FreshworksUtils'
import { buildDoctorsSearchFilter } from '../utils/searchUtils'
import { setPatientsListExpanded } from '../utils/storageUtils'
import i18n from '../resources/locales/i18n'
import * as intercom from 'utils/intercomUtils'
import { logInfo } from 'utils/logUtils'
import { StatusTypes } from '@grin-rnd/grin-api-sdk/dist/Enums/Statuses'

export const fetchAppConfig = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_APP_CONFIG, Actions.RESUME_ONBOARDING, Actions.ACCOUNT_INFO_COMPLETED),
    withLatestFrom(state$),
    filter(([action, state]) => isEmpty(state.appReducer.appconfig)),
    switchMap(() =>
      from(API.get('grinApi', '/appconfig')).pipe(
        pluck('response', 'result'),
        mergeMap(appconfig => of(Actions.fetchAppConfigReceived(appconfig))),
        catchError(({ response }) => of(Actions.fetchAppConfigFailed(response)))
      )
    )
  )

export const fetchAppConfigReceived = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_APP_CONFIG_RECEIVED),
    pluck('payload'),
    tap(({ app }) => sessionStorage.setItem('newMediaCloudfrontUrl', app.newCloudfrontUrl)),
    ignoreElements()
  )

export const searchDoctorEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_SEARCH_DOCTOR),
    withLatestFrom(state$),
    map(([action, state]) => ({
      term: action.payload,
      allowedGroups: JSON.parse(state.profileReducer.doctor?.user?.allowed_groups_permissions || null)?.map(
        groupPermission => groupPermission.groupKey
      ),
      hiDoctorIds: state.multiPracticeReducer.assignedDoctors?.data?.map(doctorSM => doctorSM.id)
    })),
    filter(({ term }) => !!term),
    switchMap(({ term, allowedGroups, hiDoctorIds }) =>
      from(
        API.graphql(
          graphqlOperation(searchDoctorSearchModels, {
            filter: buildDoctorsSearchFilter({
              filterTerm: term,
              includeMembers: false,
              groups: allowedGroups,
              doctorIds: hiDoctorIds
            }),
            limit: 10
          })
        )
      ).pipe(
        map(({ data }) => data?.searchDoctorSearchModels?.items),
        mergeMap(doctors => of(Actions.doctorSearchReceived(doctors))),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const clearDoctorSearchEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_SEARCH_DOCTOR),
    pluck('payload'),
    filter(doctorName => !doctorName),
    mergeMap(() => of(Actions.doctorSearchReceived([])))
  )

export const setHelpWidgetVisibilityEpic = action$ =>
  action$.pipe(
    ofType(Actions.SET_HELP_WIDGET_VISIBILITY),
    pluck('payload'),
    tap(visible => (visible ? showHelpWidget() : hideHelpWidget())),
    ignoreElements()
  )

export const openHelpPopupEpic = action$ =>
  action$.pipe(ofType(Actions.OPEN_HELP_POPUP), tap(openHelpPopup), ignoreElements())

export const setHelpPopupDetailsEpic = action$ =>
  action$.pipe(
    ofType(Actions.DOCTOR_DETAILS_RECEIVED),
    pluck('payload'),
    tap(({ doctor }) => setHelpPopupDetails(doctor.name, doctor.email)),
    tap(
      ({ practice }) =>
        (document.title = practice?.details?.practiceName ? `Grin - ${practice.details.practiceName}` : 'Grin')
    ),
    ignoreElements()
  )

export const clearHelpPopupDetailsEpic = action$ =>
  action$.pipe(
    ofType(Actions.SIGNOUT_RECEIVED),
    tap(() => clearHelpPopupDetails()),
    tap(() => (document.title = `Grin`)),
    ignoreElements()
  )

export const newVersionLookupEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.LOOK_FOR_NEW_VERSION),
    withLatestFrom(state$),
    filter(([, state]) => state.profileReducer.doctor?.id),
    filter(
      ([, state]) =>
        !state.treatmentReducer.scanReviewEditorDialog.open && !state.treatmentReducer.scanReviewEditorDialog.isSending
    ),
    map(([, state]) => state.appReducer.loadedAt),
    switchMap(loadedAt =>
      from(Auth.currentAuthenticatedUser()).pipe(
        switchMap(() =>
          from(fetchLatestDeploymentTime()).pipe(
            map(latestDeploymentTime => moment(latestDeploymentTime).diff(loadedAt) > 0),
            filter(newVersion => newVersion),
            mergeMap(newVersion => of(Actions.forceRefresh())),
            catchError(err => of(Actions.fetchRejected({ message: 'failed to fetch last deployment time', err })))
          )
        ),
        catchError(err => of(Actions.fetchRejected({ message: 'failed to get current authenticated user', err })))
      )
    )
  )

const fetchLatestDeploymentTime = () => {
  const env = getEnvironment()
  const bucket = env === 'production' ? 'grin-webapp-version-check' : `grin-webapp-version-check-${env}`

  return Storage.get(env, {
    bucket
  })
    .then(fetch)
    .then(response => response.json())
    .then(timestamp => new Date(timestamp))
}

export const setPatientsListExpandedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SET_PATIENTS_LIST_EXPANDED),
    pluck('payload'),
    tap(isPatientsListExpanded => setPatientsListExpanded(isPatientsListExpanded)),
    ignoreElements()
  )

export const SupportAuthEpic = (action$, state$, { history }) =>
  action$.pipe(
    ofType(Actions.LOCATION_CHANGED),
    pluck('payload'),
    filter(({ location }) => SupportRestrictedPaths.includes(location.pathname)),
    tap(() => {
      if (isUserOfRole([Roles.Admin])) {
        history.push('/')
      }
    }),
    ignoreElements()
  )

export const DSOAuthEpic = (action$, state$, { history }) =>
  action$.pipe(
    ofType(Actions.LOCATION_CHANGED),
    pluck('payload'),
    filter(
      ({ location }) =>
        DSORestrictedPaths.includes(location.pathname) || location.pathname.includes(ROUTES.SUPPORT_DASHBOARD)
    ),
    tap(() => {
      if (isUserOfRole([Roles.DsoSupport])) {
        history.push('/')
      }
    }),
    ignoreElements()
  )

export const setIsMobile = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DOCTOR_DETAILS_RECEIVED, Actions.UPDATE_DOCTOR_DETAILS_RECEIVED),
    pluck('payload'),
    map(({ doctor }) => {
      return Actions.setIsMobile(isMobile())
    })
  )

export const submitAppFeedbackEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUBMIT_APP_FEEDBACK),
    pluck('payload'),
    switchMap(({ feedbackText }) =>
      from(
        API.post('grinServerlessApi', '/ops/v1/feedback/webapp', {
          body: { text: feedbackText }
        })
      ).pipe(
        mergeMap(() => of(Actions.submitAppFeedbackReceived())),
        catchError(error => of(Actions.submitAppFeedbackFailed(error)))
      )
    )
  )

export const submitAppFeedbackReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUBMIT_APP_FEEDBACK_RECEIVED),
    mapTo(
      Actions.showAlert({
        type: 'heart',
        title: i18n.t('dialogs.shareFeedback.submittedSuccessfullyAlert')
      })
    )
  )

export const initializeIntercom = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DOCTOR_DETAILS_RECEIVED, Actions.FETCH_APP_CONFIG_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      appconfig: state.appReducer.appconfig?.app,
      doctor: state.profileReducer.doctor,
      practice: state.practiceReducer?.details
    })),
    filter(() => !intercom.isInitialized()),
    filter(({ appconfig, doctor }) => !isEmpty(appconfig) && !isEmpty(doctor)),
    filter(
      ({ appconfig, doctor }) =>
        JSON.parse(doctor.user.featureFlags.flags || '{}').intercom ||
        (appconfig.gaFlags.intercomWebapp && !doctor.email.includes('@get-grin.com'))
    ),
    switchMap(({ appconfig, doctor, practice }) =>
      forkJoin({
        appconfig: of(appconfig),
        doctor: of(doctor),
        practice: of(practice),
        totalPracticeMembers: API.graphql(
          graphqlOperation(totalPracticeMembers, {
            filter: {
              accountOwnerId: {
                eq: doctor.accountOwnerId || doctor.id
              }
            }
          })
        ).then(({ data }) => data?.searchDoctorSearchModels?.total),
        doctorsTotalPatients: API.graphql(
          graphqlOperation(doctorsTotalPatients, {
            filter: {
              doctorId: {
                eq: doctor.accountOwnerId || doctor.id
              }
            }
          })
        ).then(({ data }) => data?.searchPatientSearchModels?.total),
        doctorsTotalActivePatients: API.graphql(
          graphqlOperation(doctorsTotalPatients, {
            filter: {
              doctorId: {
                eq: doctor.accountOwnerId || doctor.id
              },
              statusType: {
                eq: StatusTypes.ActiveTreatment
              }
            }
          })
        ).then(({ data }) => data?.searchPatientSearchModels?.total)
      }).pipe(
        tap(
          ({ appconfig, doctor, practice, totalPracticeMembers, doctorsTotalPatients, doctorsTotalActivePatients }) => {
            intercom.initIntercom({
              appId: appconfig.intercom.appId.webApp,
              email: doctor.email,
              name: doctor.name,
              createdAt: moment(doctor.createdAt).unix(),
              properties: {
                env: getEnvironment(),
                userType: 'doctor',
                accessType: doctor.accessType,
                totalPracticeMembers,
                doctorsTotalPatients,
                doctorsTotalActivePatients,
                username: doctor.username,
                userId: doctor.id,
                practiceName: practice.practiceName,
                version: process.env.REACT_APP_VERSION,
                roleDescription: doctor.roleDescription,
                practiceSubscriptionType: practice.accountOwner?.user?.grinPlanKey
              }
            })

            logInfo(`Intercom initialized`, {
              appId: appconfig.intercom.appId.webApp,
              email: doctor.email
            })
          }
        ),
        ignoreElements()
      )
    )
  )

export const fetchStaticDataEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_STATIC_DATA),
    switchMap(() =>
      from(API.get('grinServerlessApi', '/platform/v1/staticData')).pipe(
        mergeMap(data => of(Actions.fetchStaticDataReceived(data))),
        catchError(error => of(Actions.fetchStaticDataFailed(error)))
      )
    )
  )

export const fetchAboutDataEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_ABOUT_DATA),
    pluck('payload'),
    switchMap(payload =>
      from(API.get('grinServerlessApi', `/platform/v1/homepage/${payload?.doctorId ?? null}`)).pipe(
        mergeMap(data => of(Actions.fetchAboutDataReceived(data))),
        catchError(err => of(Actions.fetchAboutDataFailed(err)))
      )
    )
  )
