import { IconButton, makeStyles } from '@material-ui/core'
import React, { useState, useCallback, useMemo } from 'react'
import { MoreVert } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import ScheduleSendIcon from 'components/common/icons/ScheduleSend'
import ScheduleMessagePopupMobile from './ScheduledMessages/ScheduleMessagePopup.mobile'
import ChatActionsModal from './ChatModal/ChatActionsModal'

const useStyles = makeStyles({
  menuButton: {
    margin: 0,
    marginRight: 5
  }
})

const ChatActionsMenuMobile = ({ onSend, disabled = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [isScheduleMessagePopupOpen, setIsScheduleMessagePopupOpen] = useState(false)

  const handleCloseMenu = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleCloseScheduleMessagePopup = useCallback(() => {
    trackEvent('Scheduled Messages - popup closed')
    setIsScheduleMessagePopupOpen(false)
  }, [])

  const handleClickMenu = useCallback(event => {
    trackEvent('Patients chat - Actions menu opened', { viewType: 'mobile' })
    setIsOpen(true)
  }, [])

  const actions = useMemo(
    () => [
      {
        icon: <ScheduleSendIcon />,
        title: t('pages.patients.selectedPatient.chat.sendOptions.scheduleSend'),
        onMobileClick: () => {
          trackEvent('Scheduled Messages - popup opened', { viewType: 'mobile' })
          setIsScheduleMessagePopupOpen(true)
        },
        disabled
      }
    ],
    [t, disabled]
  )

  return (
    <>
      <ChatActionsModal isOpen={isOpen} handleClose={handleCloseMenu} actions={actions} />
      <IconButton onClick={handleClickMenu} className={classes.menuButton}>
        <MoreVert />
      </IconButton>
      <ScheduleMessagePopupMobile
        onSend={onSend}
        isOpen={isScheduleMessagePopupOpen}
        onClose={handleCloseScheduleMessagePopup}
      />
    </>
  )
}

export default ChatActionsMenuMobile
