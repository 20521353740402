import * as React from 'react'
const GrinAssets = ({ ...props }) => {
  return (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1.67133C1 1.30056 1.30056 1 1.67133 1H6.21517C6.40335 1 6.58289 1.07898 6.71004 1.2177L8.38786 3.04804C8.51501 3.18675 8.69455 3.26573 8.88273 3.26573H15.3566C15.7274 3.26573 16.028 3.5663 16.028 3.93706V12.3287C16.028 12.6994 15.7274 13 15.3566 13H1.67133C1.30056 13 1 12.6994 1 12.3287V1.67133Z"
        stroke="#041333"
      />
    </svg>
  )
}

export default GrinAssets
