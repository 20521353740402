import { ROUTES } from 'consts'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { makeStyles } from '@material-ui/styles'
import Actions from 'actions'
import { MobileTabs } from 'consts/appConsts'
import { Tabs, Tab } from '@material-ui/core'
import { createIdForTest } from 'utils/testingUtils.js'

const useStyles = makeStyles(theme => ({
  tabs: {
    width: '100%'
  },
  tab: {
    flex: 1,
    fontFamily: 'Dazzed',
    fontSize: '14px',
    textTransform: 'none',
    padding: '14px 14px 10px 14px',
    borderBottom: '4px solid var(--border-color-20)',
    color: 'var(--text-color-2)'
  },
  tabSelected: {
    color: 'var(--text-color-7)',
    fontWeight: 600
  },
  tabIndicator: {
    borderBottom: '4px solid var(--border-color-1)'
  }
}))

const AppTabsMobile = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const idFromUrl = useMemo(() => pathname.slice(ROUTES.PATIENTS.length).split('/')[1], [pathname])

  const patient = useSelector(state => state.patientsReducer.patient)
  const rooms = useSelector(state => state.chatReducer.rooms)
  const tab = useSelector(state => state.appReducer.mobile.selectedTab)

  const navigateToTab = useCallback(
    pathname => {
      history.push({
        pathname,
        search: window.location.search
      })
    },
    [history]
  )

  const tabs = useMemo(
    () => [
      {
        value: MobileTabs.INBOX,
        label: t('header.tabs.inbox'),
        disabled: () => false,
        select: () => navigateToTab(ROUTES.PATIENTS),
        isSelected: () => history.location.pathname === ROUTES.PATIENTS
      },
      {
        value: MobileTabs.TIMELINE,
        label: t('header.tabs.timeline'),
        select: () =>
          navigateToTab(
            `${ROUTES.PATIENTS}/${patient?.id ? patient.id : idFromUrl ? idFromUrl : rooms[0].patientId}/${
              ROUTES.PATIENT_TIMELINE
            }`
          ),
        isSelected: () => history.location.pathname.includes(ROUTES.PATIENT_TIMELINE)
      },
      {
        value: MobileTabs.CHAT,
        label: t('header.tabs.chat'),
        select: () =>
          navigateToTab(`${ROUTES.PATIENTS}/${patient?.id ? patient.id : rooms[0].patientId}/${ROUTES.PATIENT_CHAT}`),
        isSelected: () => history.location.pathname.includes(ROUTES.PATIENT_CHAT)
      },
      {
        value: MobileTabs.NOTES,
        label: t('header.tabs.notes'),
        select: () =>
          navigateToTab(`${ROUTES.PATIENTS}/${patient?.id ? patient.id : rooms[0].patientId}/${ROUTES.PATIENT_NOTES}`),
        isSelected: () => history.location.pathname.includes(ROUTES.PATIENT_NOTES)
      }
    ],
    [t, patient, history, rooms, idFromUrl, navigateToTab]
  )

  const handleTabSelection = useCallback(
    (event, value) => {
      tabs.find(tab => tab.value === value)?.select()
    },
    [tabs]
  )

  useEffect(() => {
    dispatch(Actions.mobileSetSelectedTab(tabs.find(tab => tab.isSelected())?.value || MobileTabs.INBOX))
  }, [history.location.pathname, tabs, dispatch])

  return (
    <Tabs value={tab} onChange={handleTabSelection} classes={{ root: classes.root, indicator: classes.tabIndicator }}>
      {tabs.map(tab => (
        <Tab
          id={createIdForTest({ componentId: 'mobile-responsive-tab', title: tab.label })}
          disableRipple
          key={`app-tab-${tab.value}`}
          label={tab.label}
          value={tab.value}
          classes={{
            root: classes.tab,
            selected: classes.tabSelected
          }}
        />
      ))}
    </Tabs>
  )
}

export default AppTabsMobile
