import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useTranslation } from 'react-i18next'
import usePatientBriefViewer from 'components/Patients/PatientGuidelines/PatientBrief/usePatientBriefViewer'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import LinkButton from 'components/common/buttons/LinkButton'
import { trackEvent } from 'utils/analyticsUtils'
import PracticeViewCategory from './PracticeViewCategory'
import usePatientBriefModalOpener from 'components/Patients/PatientCard/PatientBriefTab/usePatientBriefModalOpener'
import { useSelector } from 'react-redux'
import useRolePermissions from 'hooks/useRolePermissions'

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
    overflowY: 'auto'
  },
  lastUpdated: {
    paddingTop: 8,
    paddingBottom: 15,
    color: 'var(--text-color-11)'
  },
  note: {
    color: 'var(--text-color-11)'
  },
  emptyState: {
    padding: 20
  }
}))

const PatientBriefPracticeView = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()

  const { categories, latestPatientBrief, hasPatientBrief } = usePatientBriefViewer()
  const { viewPatientBrief, updatePatientBrief } = usePatientBriefModalOpener({ analyticsSource: 'practiceViewer' })
  const { data: patientBriefData } = useSelector(state => state.patientsReducer.patientBrief)

  const lastUpdated = useMemo(() => moment(latestPatientBrief?.createdAt).format('MMM D, YYYY'), [latestPatientBrief])

  const handleEditClicked = useCallback(
    ({ analyticSource }) => {
      trackEvent('Patient brief practice view - Edit clicked', {
        analyticSource
      })
      updatePatientBrief({ patientBrief: patientBriefData[0] })
    },
    [patientBriefData, updatePatientBrief]
  )

  const handleViewClicked = useCallback(() => {
    trackEvent('Patient brief practice view - View clicked')
    viewPatientBrief({ patientBrief: patientBriefData[0] })
  }, [patientBriefData, viewPatientBrief])

  const categoriesConfig = useMemo(
    () =>
      Object.keys(latestPatientBrief?.categories)
        .filter(categoryKey => latestPatientBrief.categories[categoryKey].isEnabled)
        .map(categoryKey => {
          const categoryConfiguration = categories.find(cat => cat.key === categoryKey)

          return {
            key: categoryKey,
            icon: categoryConfiguration.icon,
            title: t(`dialogs.notifyPractice.patientBrief.categories.${categoryKey}.title`),
            items: latestPatientBrief?.categories[categoryKey].items,
            note: latestPatientBrief?.categories[categoryKey].note,
            component: categoryConfiguration.component
          }
        }),
    [categories, latestPatientBrief.categories, t]
  )

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <DazzedParagraph14 bold color="var(--text-color-11)">
              {t('dialogs.notifyPractice.patientBrief.title', { lastUpdated })}
            </DazzedParagraph14>
          </Grid>
          {hasPatientBrief && (
            <>
              <Grid item>
                <LinkButton
                  underline
                  label={t('dialogs.notifyPractice.patientBrief.view')}
                  onClick={handleViewClicked}
                />
              </Grid>
              {permissions.updatePatientBrief && (
                <Grid item>
                  <LinkButton
                    underline
                    label={t('dialogs.notifyPractice.patientBrief.edit')}
                    onClick={() => handleEditClicked({ analyticSource: 'create new brief' })}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      {!hasPatientBrief ? (
        <Grid item>
          <Grid container direction="column" alignItems="center" className={classes.emptyState} spacing={1}>
            <Grid item>
              <DazzedParagraph14 textAlign="center" color="var(--text-color-11)">
                {t('dialogs.notifyPractice.patientBrief.emptyState')}
              </DazzedParagraph14>
            </Grid>
            {permissions.updatePatientBrief && (
              <Grid item>
                <LinkButton
                  underline
                  fontSize={14}
                  label={t('dialogs.notifyPractice.patientBrief.createPatientBrief')}
                  onClick={() => handleEditClicked({ analyticSource: 'update brief' })}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item>
            <DazzedParagraph12 className={classes.lastUpdated}>
              {t('dialogs.notifyPractice.patientBrief.lastUpdated', { lastUpdated })}
            </DazzedParagraph12>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1} style={{ marginBottom: 10 }}>
              {categoriesConfig.map(categoryConfig => (
                <Grid item key={`patient-brief-practice-view-category-${categoryConfig.key}`}>
                  <PracticeViewCategory
                    icon={categoryConfig.icon}
                    title={categoryConfig.title}
                    items={categoryConfig.items}
                    note={categoryConfig.note}
                    categoryComponent={categoryConfig.component}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item>
            <DazzedParagraph12 className={classes.note}>
              {t('dialogs.notifyPractice.patientBrief.note', { lastUpdated })}
            </DazzedParagraph12>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default PatientBriefPracticeView
