import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import OggHeading40 from '../../common/text/OggHeading40'

const useStyles = makeStyles(
  theme => ({
    root: {
      maxWidth: 950,
      height: '100%',
      textAlign: 'center',
      margin: '60px auto',
      padding: ({ isMobile }) => (isMobile ? '0 20px' : 0)
    },
    title: {
      marginBottom: 15,
      fontSize: 50
    },
    paragraph: {
      margin: '40px 0',
      lineHeight: '30px',
      fontSize: 24
    },
    link: {
      color: 'var(--text-color-3)!important'
    },
    ctaContainer: {
      margin: '100px 0'
    },
    cta: {
      fontSize: 22
    }
  }),
  { isMobile }
)

const RcSignupUnavailable = props => {
  const classes = useStyles({ isMobile })
  const { t } = useTranslation()
  const doctorName = useSelector(state => state.authReducer.rcRegister.doctorDetails?.name)

  return (
    <div className={classes.root}>
      <OggHeading40 className={classes.title}>{t('pages.rcLanding.unavailable.title')}</OggHeading40>
      <DazzedParagraph16 className={classes.paragraph} textAlign="center">
        {doctorName
          ? t('pages.rcLanding.unavailable.serviceOffline', { doctorName })
          : t('pages.rcLanding.unavailable.invalidLink')}
      </DazzedParagraph16>
      <DazzedParagraph16 textAlign="center" className={classes.paragraph}>
        {t('pages.rcLanding.unavailable.contactUs1')}
        <a className={classes.emailLink} href={`mailto:${t('pages.rcLanding.footer.supportEmail')}`}>
          {t('pages.rcLanding.footer.supportEmail')}
        </a>
        {t('pages.rcLanding.unavailable.contactUs2')}
      </DazzedParagraph16>
    </div>
  )
}

export default RcSignupUnavailable
