import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Calendar } from 'components/common/icons'
import { calcOverdueDate } from 'utils/milestoneUtils'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { PATIENT_CARD_TABS } from 'consts/patientCard'
import { trackEvent } from 'utils/analyticsUtils'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import useOpenPatientCard from 'hooks/useOpenPatientCard'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme => ({
  root: {
    padding: ({ isTimelineV2 }) => (isMobile ? '2px 50px' : isTimelineV2 ? '12px 18px' : '10px 50px'),
    background: ({ isTimelineV2 }) =>
      isTimelineV2 ? 'transparent' : 'linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 25.52%)',
    borderBottom: '1px solid var(--border-color-10)',
    color: 'var(--text-color-20)'
  },
  calendarIcon: {
    display: 'flex'
  },
  changeScanDateLabel: {
    color: 'var(--text-color-54)',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

const NextScanDateBanner = () => {
  const { isTimelineV2 } = useSelector(state => state.timelineReducer)

  const classes = useStyles({ isTimelineV2 })
  const { t } = useTranslation()
  const openPatientCard = useOpenPatientCard()

  const patient = useSelector(state => state.patientsReducer?.patient)

  const nextScanDate = useMemo(() => moment(patient?.cycles?.items?.[0]?.originalEndDate), [patient?.cycles?.items])
  const nextScanInDays = useMemo(() => Math.abs(calcOverdueDate(patient)), [patient])

  const handleChangeScanDateClicked = useCallback(() => {
    trackEvent('Next scan date banner - change next scan date clicked', {
      nextScanDate,
      nextScanInDays
    })
    openPatientCard({ tabIndex: PATIENT_CARD_TABS.scanSettings.index })
  }, [nextScanDate, nextScanInDays, openPatientCard])

  return (
    <Grid container alignItems="center" className={classes.root}>
      {!isTimelineV2 && (
        <Grid item>
          <Calendar className={classes.calendarIcon} />
        </Grid>
      )}
      <Grid item>
        <DazzedParagraph12 strong>
          {nextScanInDays <= 0
            ? t('pages.patients.selectedPatient.timeline.banners.nextScanDate.descriptionToday')
            : t('pages.patients.selectedPatient.timeline.banners.nextScanDate.description', {
                nextScanInDays: nextScanInDays,
                nextScanDate: nextScanDate.format('MMM Do')
              })}
        </DazzedParagraph12>
      </Grid>
      <Grid item style={{ width: 5 }} />
      <Grid item>
        <DazzedParagraph12 strong onClick={handleChangeScanDateClicked} className={classes.changeScanDateLabel}>
          {t('pages.patients.selectedPatient.timeline.banners.nextScanDate.changeLabel')}
        </DazzedParagraph12>
      </Grid>
    </Grid>
  )
}

export default NextScanDateBanner
