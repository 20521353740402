export const ARCH_DEFAULT_COLOR = 0xaaaaaa
export const DEVIATION_COLORS = {
  MINOR_OFFSET: 0x00ff38,
  SMALL_OFFSET: 0xffb11a,
  SIGNIFICANT_OFFSET: 0xff2a2a
}
export const BRACES_DEVIATION_ARCH_COLOR = 0x00d6e4
export const ARCHES_DEVIATION_LIMITS = {
  MINOR_OFFSET: 0.15,
  SMALL_OFFSET: 0.3
}
export const MONTH_SPEED_LIMITS = {
  FAST: 0.7,
  MINOR: 0.4
}
export const ORIGIN_ARHC_MODELS_TYPES = {
  BASE: 'base',
  CURRENT: 'current',
  END: 'end'
}
