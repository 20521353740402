import { makeStyles } from '@material-ui/core/styles'
import React, { useMemo, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import SuccessIcon from '../../common/icons/Success'
import DazzedHeading24 from '../../common/text/DazzedHeading24'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import OggHeading40 from '../../common/text/OggHeading40'
import useRcDetails from '../useRcDetails'
import DownloadApp from './DownloadApp'
import PatientsOnboardingVideo from './PatientsOnboardingVideo'

const useStyles = makeStyles(
  {
    container: {
      height: '100%',
      textAlign: 'center',
      margin: '60px 0',
      padding: ({ isMobile }) => (isMobile ? '0 20px' : 0)
    },
    icon: {
      marginTop: ({ isMobile }) => (isMobile ? 60 : 90)
    },
    title: {
      marginTop: 20
    },
    subtitle: {
      marginBottom: 40
    },
    paragraph: {
      margin: '40px 0',
      lineHeight: '30px'
    }
  },
  { isMobile }
)

export default () => {
  const classes = useStyles({ isMobile })
  const { t } = useTranslation()

  const rcConfig = useRcDetails()
  const onboardingType = useMemo(() => rcConfig.onboardingType.onboardingTypeKey, [rcConfig])

  const doctorName = useMemo(() => rcConfig.name, [rcConfig])
  const practiceName = useMemo(() => rcConfig.clinicName, [rcConfig])

  useEffect(() => {
    if (doctorName && practiceName) {
      trackEvent('RC landing page - signup completed', {
        doctorName,
        practiceName,
        autoApproved: rcConfig?.approved,
        shipping: rcConfig?.shipping
      })
    }
  }, [doctorName, practiceName, rcConfig])

  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <SuccessIcon />
      </div>

      <OggHeading40 className={classes.title}>{t('pages.rcLanding.success.title')}</OggHeading40>
      <DazzedHeading24 className={classes.subtitle}>{t('pages.rcLanding.success.subtitle')}</DazzedHeading24>
      {onboardingType === 'autoApproval' && (
        <>
          <DazzedParagraph16 className={classes.paragraph} textAlign="center">
            <div>{t('pages.rcLanding.success.autoApproval.paragraph1')}</div>
            <div>{t('pages.rcLanding.success.autoApproval.paragraph2', { doctorName })}</div>
            <div>{t('pages.rcLanding.success.autoApproval.paragraph3')}</div>
          </DazzedParagraph16>
          <DownloadApp />
        </>
      )}
      {onboardingType === 'autoApprovalWithoutShipping' && (
        <>
          <DazzedParagraph16 className={classes.paragraph} textAlign="center">
            {t('pages.rcLanding.success.autoApprovalWithoutShipping.paragraph1')}
          </DazzedParagraph16>
          <DownloadApp />
        </>
      )}
      {onboardingType === 'manualApprovalWithShipping' && (
        <>
          <DazzedParagraph16 className={classes.paragraph} textAlign="center">
            {t('pages.rcLanding.success.manualApprovalWithShipping.paragraph1', { doctorName })}
          </DazzedParagraph16>
          <DazzedParagraph16 className={classes.paragraph} textAlign="center">
            {t('pages.rcLanding.success.manualApprovalWithShipping.paragraph2', { practiceName })}
          </DazzedParagraph16>
        </>
      )}

      {isMobile && <PatientsOnboardingVideo />}
    </div>
  )
}
