import {
  PASSWORD_CONFIRMATION_DONT_MATCH,
  INVALID_PASSWORD,
  AdminRoleGroups,
  RolePermissionsMap,
  EIGHT_PLUS_CHARACTERS,
  ONE_UPPER_CASE_LETTER,
  ONE_NUMBER,
  ONE_LOWER_CASE_LETTER,
  ONE_SPECIAL_CHARACTER,
  Roles,
  PracticeRoles
} from '../consts/authConsts'
import { getIdToken } from './storageUtils'
import jwt_decode from 'jwt-decode'
import { intersection } from 'lodash-es'
import i18n from '../resources/locales/i18n'

const eightPlusCharactersReg = /.{8,}$/
const oneUpperCaseLetterReg = /(?=.*?[A-Z])/
const oneNumberReg = /(?=.*?[0-9])/
const oneLowerCaseLetterReg = /(?=.*?[a-z])/
const oneSpecialCharacterReg = /(?=.*?[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])/

export const validatePasswords = (password, passwordConfirmation) => {
  if (
    password.includes(' ') ||
    !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@\\[\\\]^_`{|}~]).{8,}$/.test(password)
  ) {
    return {
      errors: {
        password: INVALID_PASSWORD
      }
    }
  } else if (password !== passwordConfirmation) {
    return {
      errors: {
        passwordConfirmation: PASSWORD_CONFIRMATION_DONT_MATCH
      }
    }
  }

  return {
    errors: null
  }
}

export const PASSWORD_REQUIREMENTS = [
  {
    code: EIGHT_PLUS_CHARACTERS,
    title: i18n.t('common.passwordStrength.characters')
  },
  {
    code: ONE_UPPER_CASE_LETTER,
    title: i18n.t('common.passwordStrength.uppercase')
  },
  {
    code: ONE_NUMBER,
    title: i18n.t('common.passwordStrength.number')
  },
  {
    code: ONE_LOWER_CASE_LETTER,
    title: i18n.t('common.passwordStrength.lower')
  },
  {
    code: ONE_SPECIAL_CHARACTER,
    title: i18n.t('common.passwordStrength.special')
  }
]

export const validatePasswordDetails = password => {
  const eightPlusCharacters = eightPlusCharactersReg.test(password)
  const oneUpperCaseLetter = oneUpperCaseLetterReg.test(password)
  const oneNumber = oneNumberReg.test(password)
  const oneLowerCaseLetter = oneLowerCaseLetterReg.test(password)
  const oneSpecialCharacter = oneSpecialCharacterReg.test(password)

  return {
    isValid:
      eightPlusCharacters &&
      oneUpperCaseLetter &&
      oneNumber &&
      oneLowerCaseLetter &&
      oneSpecialCharacter &&
      !password.includes(' '),
    [EIGHT_PLUS_CHARACTERS]: eightPlusCharacters,
    [ONE_UPPER_CASE_LETTER]: oneUpperCaseLetter,
    [ONE_NUMBER]: oneNumber,
    [ONE_LOWER_CASE_LETTER]: oneLowerCaseLetter,
    [ONE_SPECIAL_CHARACTER]: oneSpecialCharacter
  }
}

export const changeAuthForm = payload => {
  return Array.isArray(payload)
    ? payload.reduce((o, e) => ({ ...o, [e.key]: e.value }), {})
    : { [payload?.key]: payload?.value }
}

export const isUserOfRole = (roles, decodedToken) => {
  let idToken

  if (decodedToken) {
    idToken = decodedToken
  } else {
    const undecodedToken = getIdToken()

    if (undecodedToken) {
      idToken = jwt_decode(undecodedToken)
    } else {
      return false
    }
  }

  if (idToken) {
    return roles.find(role =>
      idToken['cognito:groups']
        ?.map(cognitoGroup => cognitoGroup.toLocaleLowerCase())
        .includes(role.toLocaleLowerCase())
    )
  }
  return false
}

export const getUserGroups = (jwtToken = '') => {
  const idToken = jwtToken || getIdToken()
  if (!idToken) {
    return []
  }

  const token = jwt_decode(idToken)
  return token['cognito:groups']
}

export const isAnalystUser = () => getUserGroups().find(group => group.includes('Analysts'))

export const isUserOfAnyAdminRole = () => {
  const adminGroups = AdminRoleGroups.map(roleGroup => roleGroup.toLocaleLowerCase())
  return (
    getUserGroups()
      .map(group => group.toLocaleLowerCase())
      .filter(group => adminGroups.find(roleGroup => roleGroup === group)).length > 0
  )
}

export const isUserOfAnyPracticeRole = () => {
  const roles = PracticeRoles.map(roleGroup => roleGroup.toLocaleLowerCase())
  return (
    getUserGroups()
      .map(group => group.toLocaleLowerCase())
      .filter(group => roles.find(roleGroup => roleGroup === group)).length > 0
  )
}

export const evaluateRolePermissions = (userGroups = []) => {
  if (!userGroups || !userGroups.length) {
    userGroups = getUserGroups()
  }

  return Object.keys(RolePermissionsMap).reduce((acc, curr, i, featuresList) => {
    const feature = featuresList[i]
    return {
      ...acc,
      [feature]: intersection(RolePermissionsMap[feature], userGroups).length > 0
    }
  }, {})
}

export const exportRolesPermissionsToCSV = () => {
  const headers = ['', ...Object.keys(Roles).sort((a, b) => (Roles[a] > Roles[b] ? 1 : -1))]

  let csv = Object.keys(RolePermissionsMap).map(capability =>
    [
      capability,
      ...Object.values(Roles)
        .sort((a, b) => (a > b ? 1 : -1))
        .map(role => {
          const roleCapability = RolePermissionsMap[capability]

          return !!roleCapability.includes ? (roleCapability.includes(role) ? 'V' : 'X') : '?'
        })
    ].join(',')
  )
  csv.unshift(headers.join(',')) // add header column
  csv = csv.join('\r\n')

  const res = 'data:text/csv;charset=utf-8,' + csv
  var encodedUri = encodeURI(res)
  var link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', 'role_capabilities.csv')
  document.body.appendChild(link) // Required for FF

  link.click()
}

export const validateTokenExpiration = token => {
  if (!token) {
    return null
  }

  const decodedToken = jwt_decode(token)

  if (Date.now() >= decodedToken.exp * 1000) {
    return null
  }

  return token
}

export const getParsedIdToken = () => jwt_decode(getIdToken())
