import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { ActionTypeComponents, PracticeGuidelinesConfig } from 'consts/hiToolsConsts'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  divider: {
    border: '1px solid var(--border-color-35)',
    opacity: 0.3
  },
  item: {
    padding: '25px 0px 20px 0px'
  },
  itemAction: {
    marginRight: 30
  }
}))

const CategoryItems = ({ category, itemsData, onChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const items = useMemo(
    () =>
      Object.keys(PracticeGuidelinesConfig[category]).map(itemKey => ({
        key: itemKey,
        title: t(`pages.accountSettings.practiceGuidelines.items.${itemKey}`),
        actions: PracticeGuidelinesConfig[category][itemKey].map(currActionKey => ({
          key: currActionKey,
          component: ActionTypeComponents[currActionKey]
        }))
      })),
    [t, category]
  )

  const handleActionChanged = useCallback(
    ({ itemKey, actionType, params }) => {
      onChange({
        ...itemsData,
        [itemKey]: {
          ...itemsData?.[itemKey],
          [actionType]: params
        }
      })
    },
    [itemsData, onChange]
  )

  return (
    <Grid container direction="column" justifyContent="space-between">
      {items.map(item => (
        <Grid item key={`${category}-${item.key}`}>
          <Grid container direction="column" wrap="nowrap">
            <Grid item key={item.key} className={classes.item}>
              <Grid container wrap="nowrap">
                <Grid item xs={3}>
                  <DazzedParagraph14 bold style={{ userSelect: 'none' }}>
                    {item.title}
                  </DazzedParagraph14>
                </Grid>
                <Grid item xs={9}>
                  <Grid container>
                    {item.actions.map((action, index) => (
                      <Grid
                        item
                        key={`${category}-${item.key}-action-${index}`}
                        className={index < item.actions.length ? classes.itemAction : ''}
                      >
                        {React.createElement(action.component, {
                          ...(itemsData?.[item.key]?.[action.key] || {}),
                          onChange: params => handleActionChanged({ itemKey: item.key, actionType: action.key, params })
                        })}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <div className={classes.divider} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default CategoryItems
