import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import ArrowDropdownUp from 'components/common/icons/ArrowDropdownUp'
import ArrowDropdownDown from 'components/common/icons/ArrowDropdownDown'
import Filter from 'components/common/icons/Filter'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(({ isFilterOpen }) => ({
  root: {
    transition: 'border-color .1s ease-in',
    marginLeft: 10,
    cursor: 'pointer',
    color: '#898484',
    backgroundColor: '#FFFFFF',
    borderRadius: '28px',
    height: 45,
    width: isMobile ? '80%' : 140,
    flexWrap: 'nowrap',
    boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.1)',
    border: ({ isFilterOpen }) => (isFilterOpen ? '1px solid #3C52EF' : '1px solid #FFFFFF')
  },
  arrow: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginLeft: 5,
    display: 'flex'
  },
  filterText: {
    color: '#898484'
  }
}))

const PatientsTagsFilter = ({ isFilterOpen = false }) => {
  const classes = useStyles({ isFilterOpen })
  const { t } = useTranslation()

  return (
    <Grid container justifyContent="space-evenly" alignItems="center" className={classes.root}>
      <Grid item xs={2} className={classes.icon}>
        <Filter />
      </Grid>
      <Grid item xs={5}>
        <DazzedParagraph14 className={classes.filterText}>
          {t('pages.patients.patientsList.patientsTagFilter.placeholder')}
        </DazzedParagraph14>
      </Grid>
      <Grid item xs={2} className={classes.arrow}>
        {isFilterOpen ? <ArrowDropdownUp color={'#898484'} /> : <ArrowDropdownDown color={'#898484'} />}
      </Grid>
    </Grid>
  )
}

export default PatientsTagsFilter
