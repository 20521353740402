import React, { useEffect, useState, useCallback, useMemo, Fragment } from 'react'
import { LoadScript, GoogleMap, Circle, Marker, StandaloneSearchBox } from '@react-google-maps/api'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { ALLOWED_ZIP_CODES_TYPE } from 'consts/settings'
import { DEFAULT_COUNTRY_COORDINATES, DEFAULT_MAP_ZOOM } from 'consts/mapConsts'
import { calculateRadiusInMeters, calculateZoom, getPlaceZipcode } from 'utils/mapUtils'
import Actions from 'actions'

const containerStyle = {
  width: '100%',
  height: '300px'
}

const circleOptions = {
  fillColor: '#3C52EF',
  fillOpacity: '0.3',
  strokeWeight: 0
}

const scriptLibs = ['places']

const useStyles = makeStyles({
  mapContainer: {
    position: 'relative'
  },
  loader: {
    position: 'absolute',
    zIndex: 2,
    display: 'flex',
    width: '100%',
    height: 300,
    justifyContent: 'center',
    alignItems: 'center'
  },
  googleMapSearch: {
    boxSizing: 'border-box',
    border: '1px solid transparent',
    width: '250px',
    height: '32px',
    padding: '0 12px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
    fontSize: '12px',
    fontFamily: 'Dazzed',
    outline: 'none',
    textOverflow: 'ellipses',
    position: 'absolute',
    top: 2,
    left: 2,
    transition: 'width .3s',
    '&:focus': {
      width: '85%'
    }
  }
})

const Map = ({
  children,
  coordinates,
  currentLocation,
  setCurrentLocation,
  metricType,
  searchRadius,
  zips,
  onZipcodesChange
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const isLoading = useSelector(state => state.profileReducer.zipCoordinates.isLoading)
  const googleMapsApiKey = useSelector(state => state.appReducer.appconfig.app?.apiKeys?.googleMapsApiKey)

  const [map, setMap] = useState(null)
  const [searchBox, setSearchBox] = useState(null)

  const radiusInMeters = useMemo(() => calculateRadiusInMeters(searchRadius, metricType), [metricType, searchRadius])

  const zoom = useMemo(() => calculateZoom(radiusInMeters), [radiusInMeters])

  const updateMapData = useDebouncedCallback((latLng, newZoom) => {
    map.setCenter(latLng)
    map.setZoom(newZoom)
  }, 100)

  useEffect(() => {
    if (map) {
      const latLng = coordinates[currentLocation] || DEFAULT_COUNTRY_COORDINATES
      const newZoom = coordinates[currentLocation] ? zoom : DEFAULT_MAP_ZOOM
      updateMapData(latLng, newZoom)
    }
  }, [coordinates, zips, zoom, map, currentLocation, updateMapData])

  const onLoad = useCallback(map => {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onUnmount = useCallback(() => setMap(null), [])

  const onLoadSearchBox = useCallback(loadedSearchBox => setSearchBox(loadedSearchBox), [])

  const onPlacesChanged = useCallback(() => {
    const place = searchBox.getPlaces()[0]
    const zip = getPlaceZipcode(place)
    if (zip) {
      onZipcodesChange(ALLOWED_ZIP_CODES_TYPE, [...zips, zip], zip)
    } else {
      dispatch(
        Actions.showSnackbar({
          type: 'error',
          text: t('pages.accountSettings.services.ortho.googleMapSearchFailed')
        })
      )
    }
  }, [searchBox, onZipcodesChange, zips, dispatch, t])

  return (
    <div className={classes.mapContainer}>
      {isLoading && (
        <div className={classes.loader}>
          <CircularProgress color="inherit" thickness={2} size={24} />
        </div>
      )}
      {googleMapsApiKey && (
        <LoadScript id="google-map-script" googleMapsApiKey={googleMapsApiKey} libraries={scriptLibs}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              mapTypeControl: false,
              streetViewControl: false
            }}
          >
            <StandaloneSearchBox onLoad={onLoadSearchBox} onPlacesChanged={onPlacesChanged}>
              <input
                type="text"
                placeholder={t('pages.accountSettings.services.ortho.inputPlaceholder')}
                className={classes.googleMapSearch}
              />
            </StandaloneSearchBox>
            {zips.map(zip => (
              <Fragment key={zip}>
                <Circle center={coordinates[zip]} radius={radiusInMeters} editable={false} options={circleOptions} />
                <Marker position={coordinates[zip]} animation={2} onClick={() => setCurrentLocation(zip)} />
              </Fragment>
            ))}
            {children}
          </GoogleMap>
        </LoadScript>
      )}
    </div>
  )
}

export default React.memo(Map)
