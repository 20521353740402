import { Collapse, Divider, Grid, makeStyles } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { COUNTRY_US } from 'consts/countryConsts'
import { OnlineLeads, ReferralLeads, LandingPageDesignIcon } from 'components/common/icons'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedHeading16 from 'components/common/text/DazzedHeading16'
import Chevron from 'components/common/icons/ChevrolRightSmall'
import SharingMethod from './SharingMethods'
import AutoApprovaLinks from './AutoApprovalLinks'
import { ROUTES } from 'consts'
import LandingPageDesign from './LandingPageDesign/LandingPageDesign'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(() => ({
  container: {
    border: '1px solid var(--border-color-13)',
    borderRadius: '0 0 50px 0',
    paddingBottom: 20,
    pointerEvents: ({ isEnabled }) => (isEnabled ? 'auto' : 'none'),
    opacity: ({ isEnabled }) => (isEnabled ? 1 : 0.5)
  },
  header: {
    borderBottom: '1px solid var(--border-color-14)',
    padding: '0 20px'
  },
  setUpContainer: {
    userSelect: 'none',
    marginBottom: 10
  },
  setupHeader: {
    cursor: 'pointer',
    paddingTop: 5
  },
  setupContent: {
    padding: '8px 0 15px 0'
  },
  setupDescription: {
    marginBottom: 15
  },
  landingPageDesignToggleLabel: {
    textDecoration: 'underline',
    color: 'var(--text-color-25)',
    cursor: 'pointer'
  }
}))

const RCConfiguration = ({ isEnabled }) => {
  const practiceCountry = useSelector(state => state.practiceReducer.details.country)
  const classes = useStyles({ isEnabled })
  const { t } = useTranslation()

  const [setups, setSetups] = useState([
    {
      icon: <OnlineLeads />,
      index: 0,
      title: t('pages.accountSettings.services.remoteConsultation.setups.onlineLeads.title'),
      subtitle: t('pages.accountSettings.services.remoteConsultation.setups.onlineLeads.subtitle'),
      description: (
        <Trans
          i18nKey="pages.accountSettings.services.remoteConsultation.setups.onlineLeads.description"
          components={{
            link1: (
              <a href={ROUTES.RC_DASHBOARD} target="_blank" rel="noreferrer">
                {''}
              </a>
            )
          }}
        ></Trans>
      ),
      isCollapsed: true,
      linkType: 'landingPage',
      content: <SharingMethod linkType={'landingPage'} recommendedMethod={'url'} />,
      renderCollapseToggle: isCollapsed =>
        isCollapsed ? (
          <Chevron fill="var(--text-color-27)" style={{ transform: 'rotate(90deg)' }} />
        ) : (
          <Chevron fill="var(--text-color-27)" style={{ transform: 'rotate(-90deg)' }} />
        ),
      recommendedMethod: 'url',
      isAvailable: practiceCountry === COUNTRY_US,
      additionalInfo: <AutoApprovaLinks />
    },
    {
      icon: <ReferralLeads />,
      index: 1,
      title: t('pages.accountSettings.services.remoteConsultation.setups.referralLeads.title'),
      subtitle: t('pages.accountSettings.services.remoteConsultation.setups.referralLeads.subtitle'),
      description: t('pages.accountSettings.services.remoteConsultation.setups.referralLeads.description'),
      isCollapsed: true,
      linkType: 'autoApprovalNoShipping',
      content: <SharingMethod linkType="autoApprovalNoShipping" recommendedMethod="qrCode" />,
      renderCollapseToggle: isCollapsed => (
        <Chevron fill="var(--text-color-27)" style={{ transform: isCollapsed ? 'rotate(90deg)' : 'rotate(-90deg)' }} />
      ),
      recommendedMethod: 'qrCode',
      isAvailable: true
    },
    {
      icon: <LandingPageDesignIcon />,
      index: 2,
      title: t('pages.accountSettings.services.remoteConsultation.setups.landingPageDesign.title'),
      content: <LandingPageDesign />,
      renderCollapseToggle: isCollapsed => (
        <DazzedParagraph14 className={classes.landingPageDesignToggleLabel}>
          {t(
            `pages.accountSettings.services.remoteConsultation.setups.landingPageDesign.${
              isCollapsed ? 'contentCollapsedLabel' : 'contentDisplayedLabel'
            }`
          )}
        </DazzedParagraph14>
      ),
      isCollapsed: true,
      isDesignPage: true,
      isAvailable: true
    }
  ])

  const toggleSetUpCollapse = useCallback(
    setUpIndex => {
      let updatedSetUps = [...setups]
      trackEvent('RC Section clicked', {
        event: !setups[setUpIndex].isCollapsed ? 'open' : 'close',
        sectionType: setups[setUpIndex].title
      })
      updatedSetUps[setUpIndex].isCollapsed = !setups[setUpIndex].isCollapsed
      setSetups(updatedSetUps)
    },
    [setups]
  )

  return (
    <Grid container direction="column">
      {setups
        .filter(setup => setup.isAvailable)
        .map(setup => (
          <Grid item key={setup.title} className={classes.setUpContainer}>
            <Grid
              container
              alignItems="center"
              className={classes.setupHeader}
              onClick={() => toggleSetUpCollapse(setup.index)}
            >
              <Grid item xs={1}>
                {setup.icon}
              </Grid>
              <Grid item xs={11}>
                <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <DazzedHeading16>{setup.title}</DazzedHeading16>
                      </Grid>
                      <Grid item>
                        <DazzedParagraph14>{setup.subtitle}</DazzedParagraph14>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>{setup.renderCollapseToggle(setup.isCollapsed)}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <Collapse in={!setup.isCollapsed} className={classes.setupContent}>
              <Grid container direction="column">
                {setup?.description && (
                  <Grid item className={classes.setupDescription}>
                    <DazzedParagraph14 strong>{setup.description}</DazzedParagraph14>
                  </Grid>
                )}
                <Grid item>{setup.content}</Grid>
              </Grid>
              {setup?.additionalInfo}
            </Collapse>
            {setup.index < setups.length - 1 && <Divider />}
          </Grid>
        ))}
    </Grid>
  )
}

export default RCConfiguration
