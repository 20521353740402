import { rangeArray, rangeArrayReversed } from 'utils/arrayUtils'

// Makes an array that looks like this: UL1,UL2,...,UL8,UR1,UR2,...
// This is how dentists are used to view teeth numbers
export const ORTHO_TEETH_LIST = [
  rangeArrayReversed(8).map(i => `UR${i + 1}`),
  rangeArray(8).map(i => `UL${i + 1}`),
  rangeArrayReversed(8).map(i => `LR${i + 1}`),
  rangeArray(8).map(i => `LL${i + 1}`)
].flat()

export const ORTHO_TEETH_TO_TEETH_NUMBER = {
  UR8: 1,
  UR7: 2,
  UR6: 3,
  UR5: 4,
  UR4: 5,
  UR3: 6,
  UR2: 7,
  UR1: 8,
  LR8: 32,
  LR7: 31,
  LR6: 30,
  LR5: 29,
  LR4: 28,
  LR3: 27,
  LR2: 26,
  LR1: 25,
  LL1: 24,
  LL2: 23,
  LL3: 22,
  LL4: 21,
  LL5: 20,
  LL6: 19,
  LL7: 18,
  LL8: 17,
  UL8: 16,
  UL7: 15,
  UL6: 14,
  UL5: 13,
  UL4: 12,
  UL3: 11,
  UL2: 10,
  UL1: 9
}
