import * as React from 'react'

const SeekRight = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 26.2381V8.33325L21.2593 17.2857L12 26.2381Z" fill="#4151E6" />
    <rect x="19.998" y="8.33325" width="2" height="18" fill="#4151E6" />
    <circle cx="17.332" cy="17" r="16" stroke="#4151E6" />
  </svg>
)

export default SeekRight
