import React, { useCallback, useMemo } from 'react'
import { makeStyles, TableCell, TableSortLabel } from '@material-ui/core'
import ChevronIcon from 'components/common/icons/ChevronDownSmall'
import { Refresh as RefreshIcon } from 'components/common/icons'

const useStyles = ({ withSort, isSortedByField }) =>
  makeStyles({
    headerCell: {
      fontSize: '13px',
      fontWeight: 500,
      fontFamily: 'Dazzed',
      color: isSortedByField ? 'var(--text-color-3)' : 'var(--text-color-14)',
      borderColor: 'var(--border-color-7)',
      padding: 0
    },
    content: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '18px 0 18px 16px',
      alignItems: 'center',
      backgroundColor: 'white'
    },
    sortEnabled: {
      cursor: 'pointer'
    },
    label: {
      marginRight: withSort ? '12px' : 0
    },
    refreshContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer'
    },
    refreshLabel: {
      marginLeft: 6,
      color: 'var(--text-color-3)'
    },
    sortIcon: {
      '& svg path': {
        fill: isSortedByField ? 'var(--text-color-3)' : 'var(--text-color-14)'
      }
    }
  })({ withSort, isSortedByField })

const HeaderCell = ({
  label,
  sortBy,
  sortField,
  withSort = false,
  withRefresh = false,
  sortOrder = 'desc',
  onSortOrderChange = () => null,
  onRefresh = () => null
}) => {
  const isSortedByField = useMemo(() => sortField && sortBy === sortField, [sortBy, sortField])

  const classes = useStyles({ withSort, isSortedByField })

  const handleSortOrderChange = useCallback(() => {
    const sortDirection = isSortedByField && sortOrder === 'desc' ? 'asc' : 'desc'
    onSortOrderChange(sortField, sortDirection)
  }, [sortOrder, isSortedByField, sortField, onSortOrderChange])

  return (
    <TableCell variant="head" className={classes.headerCell}>
      <div
        className={[classes.content, withSort && classes.sortEnabled].join(' ')}
        onClick={!withRefresh && withSort ? handleSortOrderChange : () => {}}
      >
        {withRefresh ? (
          <div className={classes.refreshContainer} onClick={onRefresh}>
            <RefreshIcon />
            <div className={[classes.label, classes.refreshLabel].join(' ')}>{label}</div>
          </div>
        ) : (
          <div className={classes.label}>{label}</div>
        )}
        {withSort ? (
          <TableSortLabel
            active
            direction={isSortedByField ? sortOrder : 'desc'}
            IconComponent={ChevronIcon}
            className={classes.sortIcon}
          />
        ) : null}
      </div>
    </TableCell>
  )
}

export default React.memo(HeaderCell)
