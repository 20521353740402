import moment from 'moment'
import { groupBy } from 'ramda'

export const upsertGrinScanReviewsList = (grinScans, newScanReview) => {
  const grinScanIndex = grinScans.findIndex(s => s.id === newScanReview.scan?.id)

  let mergedScans = [...grinScans]

  if (grinScanIndex !== -1) {
    mergedScans[grinScanIndex].scanReviews.items = [
      ...mergedScans[grinScanIndex].scanReviews.items,
      {
        cache: newScanReview.blob,
        isLocal: true,
        createdAt: newScanReview.uploadingDate
      }
    ]
  }

  return mergedScans
}

export const isPatientScanOverdue = patientTags => {
  return patientTags.some(tag => tag.type === 'scan' && tag.value.toLowerCase() === 'overduescan')
}

export const calcOverdueDateOld = patient => {
  const currCycle = (patient?.cycles?.items || [])[0]
  const cycleEndDate = moment(currCycle.originalEndDate).startOf('day')
  return moment(cycleEndDate).format('MMMM D, YYYY')
}

export const calcOverdueDate = patient => {
  const currCycle = (patient?.cycles?.items || [])[0]
  const cycleEndDate = moment(currCycle?.originalEndDate).startOf('day')
  return moment().startOf('day').diff(cycleEndDate, 'days')
}

export const groupByMonth = groupBy(scan => moment(scan.createdAt).format('YYYY-MM'))
export const groupByYear = groupBy(scan => moment(scan.createdAt).format('YYYY'))
