import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: 'Ogg',
    fontSize: 27,
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '41px',
    textAlign: 'center',
    color: theme.palette.primary.main
  }
}))

const OggHeading27 = ({ children, className }) => {
  const classes = useStyles()

  return <div className={[classes.text, className].join(' ')}>{children}</div>
}

export default OggHeading27
