import * as React from 'react'

const Warn = props => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.13807 1.46529C9.52472 0.807982 10.4753 0.807982 10.8619 1.46529L19.1135 15.493C19.5057 16.1596 19.025 17 18.2516 17H1.74842C0.975001 17 0.494345 16.1596 0.886482 15.493L9.13807 1.46529Z"
      fill="#FFBD39"
    />
    <rect x="9" y="6" width="2" height="5" fill="white" />
    <rect x="9" y="13" width="2" height="2" fill="white" />
  </svg>
)

export default Warn
