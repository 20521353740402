import { useCallback, useMemo } from 'react'
import { ARCHES_DEVIATION_LIMITS, MONTH_SPEED_LIMITS } from '../ArchesViewer/archesViewerConsts'
import { TeethTrackingStatuses } from '../txTrackerConsts'
import {
  FastLarge,
  MissingToothLarge,
  ModerateLarge,
  NoMovementLarge,
  NotTrackingLarge,
  PartiallyTrackingLarge,
  SlowLarge,
  TrackingLarge,
  TrackingNoMovementLarge,
  UnknownLarge
} from 'components/common/icons'
import { TreatmentTypes } from 'consts/treatmentConsts'
import { txTrackerDataByTeeth, txTrackerOverTimeByScan } from '../mockData'
import { useSelector } from 'react-redux'
import { getEnvironment } from 'utils/awsUtils'

export default () => {
  const { treatment } = useSelector(state => state.treatmentReducer)
  const patientGrinScans = useSelector(state => state.patientsReducer.patient?.grinScans?.items || [])
  const { grinScanId } = useSelector(state => state.treatmentReducer.treatmentTrackerModal)

  const isNoTeethMovement = ({ deviation, pastMonthSpeed }) =>
    (deviation === undefined || typeof deviation !== 'number') && !pastMonthSpeed

  const getTeethTrackingStatus = toothData => {
    if (!toothData) {
      return TeethTrackingStatuses.MissingTooth
    }

    const isAlignersTreatment = treatment?.type === TreatmentTypes.Aligners
    const { deviation, pastMonthSpeed } = toothData

    if (toothData.unknown) {
      return TeethTrackingStatuses.Unknown
    }

    if (isNoTeethMovement({ deviation, pastMonthSpeed })) {
      return isAlignersTreatment ? TeethTrackingStatuses.TrackingNoMovement : TeethTrackingStatuses.NoMovement
    }

    if (isAlignersTreatment) {
      if (deviation <= ARCHES_DEVIATION_LIMITS.MINOR_OFFSET) {
        return TeethTrackingStatuses.Tracking
      } else if (deviation <= ARCHES_DEVIATION_LIMITS.SMALL_OFFSET) {
        return TeethTrackingStatuses.PartiallyTracking
      }

      return TeethTrackingStatuses.NotTracking
    } else {
      if (pastMonthSpeed >= MONTH_SPEED_LIMITS.FAST) {
        return TeethTrackingStatuses.Fast
      } else if (pastMonthSpeed > MONTH_SPEED_LIMITS.MINOR && pastMonthSpeed < MONTH_SPEED_LIMITS.FAST) {
        return TeethTrackingStatuses.Moderate
      } else {
        return TeethTrackingStatuses.Slow
      }
    }

    //TODO: Handle unknown
  }

  const isProduction = useMemo(() => getEnvironment() === 'production', [])
  const grinScan = useMemo(() => patientGrinScans.find(scan => scan.id === grinScanId), [grinScanId, patientGrinScans])

  const trackingStatuses = useMemo(
    () => ({
      [TeethTrackingStatuses.Tracking]: {
        borderColor: '#C0F7C5',
        backgroundColor: '#D2FFD6',
        icon: <TrackingLarge />
      },
      [TeethTrackingStatuses.TrackingNoMovement]: {
        borderColor: '#C0F7C5',
        backgroundColor: '#D2FFD6',
        icon: <TrackingNoMovementLarge />
      },
      [TeethTrackingStatuses.PartiallyTracking]: {
        borderColor: '#FFDBA4',
        backgroundColor: '#FFECCF',
        icon: <PartiallyTrackingLarge />
      },
      [TeethTrackingStatuses.NotTracking]: {
        borderColor: '#F6B3B3',
        backgroundColor: '#FFD0D0',
        icon: <NotTrackingLarge />
      },
      [TeethTrackingStatuses.Unknown]: {
        borderColor: '#CAD9FF',
        backgroundColor: '#CAD9FF',
        icon: <UnknownLarge />
      },
      [TeethTrackingStatuses.Fast]: {
        icon: <FastLarge />,
        backgroundColor: '#DAE7F7',
        borderColor: 'transparent'
      },
      [TeethTrackingStatuses.Moderate]: {
        icon: <ModerateLarge />,
        backgroundColor: '#DAE7F7',
        borderColor: 'transparent'
      },
      [TeethTrackingStatuses.Slow]: {
        icon: <SlowLarge />,
        backgroundColor: '#DAE7F7',
        borderColor: 'transparent'
      },
      [TeethTrackingStatuses.NoMovement]: {
        icon: <NoMovementLarge />,
        backgroundColor: '#DAE7F7',
        borderColor: 'transparent'
      },
      [TeethTrackingStatuses.MissingTooth]: {
        icon: <MissingToothLarge />
      }
    }),
    []
  )

  const getToothMovementPrecantage = useCallback(
    ({ currentProgress, totalPlannedMovement }) => Math.round((currentProgress / totalPlannedMovement) * 100),
    []
  )

  return {
    getTeethTrackingStatus,
    trackingStatuses,
    getToothMovementPrecantage,
    txTrackerDataByTeeth: isProduction && grinScan ? txTrackerOverTimeByScan[grinScanId] : txTrackerDataByTeeth
  }
}
