import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import useTeethTracking from '../useTeethTracking'
import ToothProgressGraph from './ToothProgressGraph'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'

const progressStyle = {
  borderRadius: 10,
  width: 72,
  backgroundColor: '#E5E9FF',
  height: 4
}

const useStyles = ({ progressPrecantage }) =>
  makeStyles(theme => ({
    progressContainer: {
      position: 'relative',
      ...progressStyle
    },
    progress: {
      ...progressStyle,
      width: ({ progressPrecantage }) => `${progressPrecantage}%`,
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 2,
      backgroundColor: 'var(--text-color-25)'
    },
    graphTitle: {
      color: '#6080AC',
      marginTop: 10,
      marginLeft: 10
    }
  }))({ progressPrecantage })

const ToothTooltip = ({ toothData, toothNumber, shouldDisplayTotalProgress }) => {
  const { t } = useTranslation()
  const { getToothMovementPrecantage } = useTeethTracking()

  const { currentProgress, totalPlannedMovement } = toothData

  const teethMovementPrecantage = useMemo(
    () => getToothMovementPrecantage({ currentProgress, totalPlannedMovement }),
    [currentProgress, getToothMovementPrecantage, totalPlannedMovement]
  )

  const classes = useStyles({ progressPrecantage: teethMovementPrecantage })

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {shouldDisplayTotalProgress && (
              <Grid container direction="column">
                <Grid item>
                  <DazzedParagraph14 bold>
                    {t('dialogs.treatmentTracker.teethTracking.table.tooltip.overall')}
                  </DazzedParagraph14>
                </Grid>
                <Grid item>
                  <DazzedParagraph14 strong>
                    {t('dialogs.treatmentTracker.teethTracking.table.tooltip.mmProgress', {
                      current: Number(currentProgress).toFixed(2),
                      total: Number(totalPlannedMovement).toFixed(2)
                    })}
                  </DazzedParagraph14>
                </Grid>
              </Grid>
            )}
          </Grid>
          {shouldDisplayTotalProgress && (
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <div className={classes.progressContainer}>
                    <div className={classes.progress} />
                  </div>
                </Grid>
                <Grid item>
                  <DazzedParagraph14 strong>{`${teethMovementPrecantage}%`}</DazzedParagraph14>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <DazzedParagraph12 thickness="bold" className={classes.graphTitle}>
          {t('dialogs.treatmentTracker.teethTracking.table.tooltip.graphTitle')}
        </DazzedParagraph12>
      </Grid>
      <Grid item>
        <ToothProgressGraph
          toothData={toothData}
          toothNumber={toothNumber}
          shouldDisplayTotalProgress={shouldDisplayTotalProgress}
        />
      </Grid>
    </Grid>
  )
}

export default ToothTooltip
