import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { isEmpty, head } from 'lodash'
import { useTranslation } from 'react-i18next'

import Actions from '../../../actions'
import { isOnFreePlan } from '../../../utils/billingUtils'
import usePlanLimits from '../../../hooks/usePlanLimits'
import useAppSettings from '../../../hooks/useAppSettings'

import CreditCardPicker from '../../common/inputs/billing/CreditCardPicker'
import DazzedHeading16 from '../../common/text/DazzedHeading16'
import RequestCancelSubscriptionModal from './RequestCancelSubscriptionModal'
import RequestUpgradePlanModal from './RequestUpgradePlanModal'
import SubscriptionOverview from './SubscriptionOverview'
import BillingSubscriptionNote from './BillingSubscriptionNote'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 20,
    width: 660
  },
  subtitle: {
    marginBottom: 20
  }
}))

const BillingSubscriptionTab = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const planLimits = usePlanLimits()
  const appSettings = useAppSettings()

  const seats = useSelector(state => state.billingReducer.seats.data)
  const subscription = useSelector(state => state.billingReducer.subscription)
  const userPlan = useSelector(state => state.practiceReducer.billing.grinPlan)
  const userPlanKey = userPlan?.key ?? ''
  const isSubscriptionLoading = useSelector(state => state.billingReducer.isLoading)
  const isDoctorLoading = useSelector(state => state.profileReducer.isLoading)

  const isOrderOpen = useSelector(state => state.billingReducer.order.isOrderGrinKitsModalOpen)
  const isUpgradeOpen = useSelector(state => state.billingReducer.upgradePlan.isUpgradePlanDialogOpen)

  const [isRequestUpgradeModalOpen, setIsRequestUpgradeModalOpen] = useState(false)
  const [isRequestCancelModalOpen, setIsRequestCancelModalOpen] = useState(false)

  const subscriptionPrice = useMemo(() => {
    const subscriptionItem = head(subscription?.items)
    return subscriptionItem?.price || 0
  }, [subscription])

  const shouldDisplayCreditCard = useMemo(() => !isOrderOpen && !isUpgradeOpen, [isOrderOpen, isUpgradeOpen])

  useEffect(() => {
    if (!isOnFreePlan(userPlan) && !isEmpty(userPlan)) {
      dispatch(Actions.fetchDoctorSubscription())
    }
  }, [dispatch, userPlan])

  useEffect(() => {
    if (_.isEmpty(seats)) dispatch(Actions.fetchDoctorSeats())
  }, [dispatch, seats])

  return (
    <>
      <div className={classes.root}>
        {isSubscriptionLoading || isDoctorLoading || isEmpty(userPlan) ? (
          <CircularProgress />
        ) : (
          <div>
            <SubscriptionOverview
              grinPlan={userPlan}
              price={subscriptionPrice}
              activePatientsCount={seats.occupied || 0}
              maxPatients={planLimits.maxPatients}
              onCancel={() => {
                trackEvent('Subscription - cancel clicked', { userPlanKey })
                setIsRequestCancelModalOpen(true)
              }}
              onUpgrade={() => {
                trackEvent('Subscription - change plan clicked', { userPlanKey })
                isOnFreePlan(userPlan)
                  ? dispatch(Actions.setSelectPlanModalVisibility(true))
                  : setIsRequestUpgradeModalOpen(true)
              }}
              renewalDate={subscription?.currentPeriodEnd}
            />
            {!!appSettings.billingSubscriptionNote && (
              <BillingSubscriptionNote text={appSettings.billingSubscriptionNote} />
            )}
            {shouldDisplayCreditCard && (
              <>
                <DazzedHeading16 className={classes.subtitle}>
                  {t('pages.accountSettings.billing.paymentMethod')}
                </DazzedHeading16>
                <CreditCardPicker showAgreement submitLabel={t('general.save')} iconButtons={false} />
              </>
            )}
          </div>
        )}
      </div>
      <RequestUpgradePlanModal
        userPlanKey={userPlanKey}
        isOpen={isRequestUpgradeModalOpen}
        onClose={() => {
          trackEvent('Subscription - upgrade closed', { userPlanKey })
          setIsRequestUpgradeModalOpen(false)
        }}
      />
      <RequestCancelSubscriptionModal
        userPlanKey={userPlanKey}
        isOpen={isRequestCancelModalOpen}
        onClose={() => {
          trackEvent('Subscription - cancel closed', { userPlanKey })
          setIsRequestCancelModalOpen(false)
        }}
      />
    </>
  )
}

export default BillingSubscriptionTab
