import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { ConditionTypes } from 'consts/autochartConsts'
import { useSelector } from 'react-redux'
import AutochartLegendItem from './AutochartLegendItem'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'var(--bg-color-55)',
    borderRadius: '16px',
    width: '100%',
    marginTop: 16,
    padding: 16,
    '&.vertical': {
      marginTop: 0,
      maxWidth: '215px',
      marginRight: 20,
      height: '100%'
    }
  },
  divider: {
    borderRight: '1px solid var(--border-color-31)',
    margin: '0 24px',
    '&.vertical': {
      margin: '20px 0',
      width: '100%',
      height: '1px',
      borderRight: '0px',
      borderBottom: '1px solid var(--border-color-31)',
      '&.invisible': {
        margin: 0
      }
    },
    '&.invisible': {
      border: 0
    }
  },
  icon: {
    padding: '0 8px'
  }
}))

const AutochartLegend = () => {
  const classes = useStyles()
  const { isRightPanelVisible } = useSelector(state => state.autochartReducer)

  const groups = useMemo(
    () => [
      { items: [ConditionTypes.MissingTooth] },
      { items: [ConditionTypes.Caries, ConditionTypes.Filling, ConditionTypes.Crown] },
      { items: [ConditionTypes.Recession, ConditionTypes.RootCanal] },
      { items: [ConditionTypes.Plaque, ConditionTypes.Inflamation], disableDivider: true },
      { items: [ConditionTypes.Tartar, null /* this is a placeholder */] }
    ],
    []
  )

  const groupHeight = useMemo(() => (!isRightPanelVisible ? '100%' : 95), [isRightPanelVisible])

  return (
    <Grid
      container
      direction={isRightPanelVisible ? 'row' : 'column'}
      className={[classes.root, !isRightPanelVisible ? 'vertical' : ''].join(' ')}
    >
      {groups.map((group, i) => (
        <>
          <Grid item key={`autochart-legend-group-${i}`}>
            {group.items.map((condition, j) =>
              condition ? (
                <AutochartLegendItem
                  key={`autochart-legend-${condition}`}
                  condition={condition}
                  height={groupHeight / group.items.length}
                />
              ) : (
                <React.Fragment key={`autochart-legend-empty-${j}`} />
              )
            )}
          </Grid>
          {i < groups.length - 1 && (
            <Grid
              item
              className={[
                classes.divider,
                !isRightPanelVisible ? 'vertical' : '',
                group.disableDivider ? 'invisible' : ''
              ].join(' ')}
            />
          )}
        </>
      ))}
    </Grid>
  )
}

export default AutochartLegend
