import * as React from 'react'

function SvgBoards(props) {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.5" cy="11.5" r="11.5" fill="#3C52EF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 6H11V11H6V12H11V17H12V12H17V11H12V6Z" fill="white" />
    </svg>
  )
}

export default SvgBoards
