import { createTheme } from '@material-ui/core/styles'
import DazzedRegularWoff2 from '../resources/fonts/Dazzed/Dazzed-Regular.woff2'
import DazzedMediumWoff2 from '../resources/fonts/Dazzed/Dazzed-Medium.woff2'
import DazzedSemiBoldWoff2 from '../resources/fonts/Dazzed/Dazzed-SemiBold.woff2'
import OggRegularWoff2 from '../resources/fonts/Ogg/Ogg-Regular.woff2'
import OggMediumWoff2 from '../resources/fonts/Ogg/Ogg-Medium.woff2'

const DazzedRegular = {
  fontFamily: 'Dazzed',
  fontStyle: 'regular',
  fontWeight: 400,
  src: `
    local('Dazzed'),
    local('Dazzed-Regular'),
    url(${DazzedRegularWoff2}) format('woff2')
  `
}

const DazzedMedium = {
  fontFamily: 'Dazzed',
  fontStyle: 'medium',
  fontWeight: 500,
  src: `
    local('Dazzed'),
    local('Dazzed-Medium'),
    url(${DazzedMediumWoff2}) format('woff2')
  `
}

const DazzedSemiBold = {
  fontFamily: 'Dazzed',
  fontStyle: 'semibold',
  fontWeight: 600,
  src: `
    local('Dazzed'),
    local('Dazzed-SemiBold'),
    url(${DazzedSemiBoldWoff2}) format('woff2')
  `
}

const OggRegular = {
  fontFamily: 'Ogg',
  fontStyle: 'regular',
  fontWeight: 400,
  src: `
    local('Ogg'),
    local('Ogg-Regular'),
    url(${OggRegularWoff2}) format('woff2')
  `
}

const OggMedium = {
  fontFamily: 'Ogg',
  fontStyle: 'medium',
  fontWeight: 500,
  src: `
    local('Ogg'),
    local('Ogg-Medium'),
    url(${OggMediumWoff2}) format('woff2')
  `
}

export const scrollBar = {
  '&::-webkit-scrollbar': {
    width: 5
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgb(215, 215, 215)',
    borderRadius: 100
  }
}

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 728,
      md: 1440,
      lg: 1680,
      xl: 1920
    }
  },
  palette: {
    primary: {
      main: '#001335',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#33bfff',
      main: '#FFBD39',
      dark: '#007bb2',
      contrastText: '#ffffff'
    },
    info: {
      main: '#00b0ff'
    }
  },
  typography: {
    fontFamily: 'Dazzed, Arial'
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [DazzedRegular, DazzedMedium, DazzedSemiBold, OggRegular, OggMedium]
      }
    },
    MuiLink: {
      underlineHover: {
        '&:hover': {
          textDecoration: 'none'
        }
      }
    }
  }
})
