export const PATIENTS_LIST_PAGE_SIZE = 20
export const PATIENTS_LIST_PRELOAD_PAGES = 1
export const DELETED_MESSAGE_CONTENT = 'grinDeletedMessage'

export const BlueDotModes = {
  view: 'view',
  reply: 'reply'
}

export const BannerTypes = {
  TreatmentTypeCTA: 'treatmentTypeCTA',
  Overdue: 'overdue',
  DisabledPatient: 'disabledPatient',
  PausedScans: 'pausedScans',
  NextScanDate: 'nextScanDate'
}
