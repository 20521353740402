import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import BaseModal from './BaseModal'
import SuccessIcon from '../icons/Success'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../../actions'

const useStyles = makeStyles(theme => ({
  modal: {
    width: 480
  },
  message: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px'
  }
}))

const GrinPrompt = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { isOpen, type, title, message, primaryButton, secondaryButton } = useSelector(state => state.appReducer.prompt)

  const alertIcon = useMemo(() => {
    switch (type) {
      case 'success':
        return <SuccessIcon />
      default:
        return null
    }
  }, [type])

  const handleClose = useCallback(() => {
    dispatch(Actions.hidePrompt())
  }, [dispatch])

  return (
    <BaseModal
      title={title}
      open={isOpen}
      handleClose={handleClose}
      titleImageComponent={alertIcon}
      className={classes.modal}
      variant="alert"
      largerButtons={primaryButton?.isLarge}
      primaryLabel={primaryButton?.label}
      onPrimaryBtnClick={() => {
        dispatch(primaryButton.action())
        handleClose()
      }}
      secondaryLabel={secondaryButton?.label}
      onSecondaryBtnClick={secondaryButton?.onClick || handleClose}
    >
      <div className={classes.message}>{message}</div>
    </BaseModal>
  )
}

export default GrinPrompt
