import * as React from 'react'

export default () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.13178 1.51942C7.51568 0.84761 8.48437 0.847611 8.86827 1.51943L15.1451 12.5039C15.5261 13.1706 15.0447 14 14.2769 14H1.72318C0.955357 14 0.47399 13.1706 0.854937 12.5039L7.13178 1.51942Z"
      fill="#FFBD39"
    />
    <path d="M8 5V9" stroke="#001335" strokeWidth="2" />
    <path d="M8 10V12" stroke="#001335" strokeWidth="2" />
  </svg>
)
