import moment from 'moment'

export const createdAtSort = columns => (data, colIndex, order) => {
  const direction = order === 'desc' ? 1 : -1
  const isDateColumn = colIndex === columns.findIndex(column => column.name.includes('createdAt'))

  return data.sort((a, b) => {
    if (isDateColumn) {
      return moment(a.data[colIndex]).isBefore(b.data[colIndex]) ? direction : direction * -1
    }

    return a.data[colIndex] ? a.data[colIndex].localeCompare(b.data[colIndex]) * direction : direction
  })
}
