import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useLocation } from 'react-router'
import ROUTES from '../consts/routesConsts'
import { isMobile } from 'utils/mobileUtils'

const useGlobalDesignStyle = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: ({ pathname }) => {
        if (pathname.includes(ROUTES.AUTH_RC_BASIC)) return '#ffffff'
        return pathname.includes(ROUTES.AUTH) ? '#001335' : '#ffffff'
      }
    },
    '::-webkit-scrollbar': {
      width: '8px',
      background: 'white'
    },

    '::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(255, 255, 255, 0)'
    },

    '::-webkit-scrollbar-thumb': {
      background: isMobile() ? 'rgba(215, 215, 215, 0)' : 'rgb(215, 215, 215)'
    },

    '::-webkit-scrollbar-thumb:window-inactive': {
      background: '#efefef'
    },

    '::-webkit-scrollbar-thumb:hover': {
      // background: '#807e7e'
    },
    '.MuiTooltip-tooltip': {
      fontSize: 13
    }
  }
}))

const GlobalDesign = () => {
  const { pathname } = useLocation()
  useGlobalDesignStyle({ pathname })
  return <></>
}

export default GlobalDesign
