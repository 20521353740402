import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import CompareScans from 'components/common/icons/scanComparison/CompareScans'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'rgba(255, 255, 255, 1)',
    cursor: 'pointer',
    padding: '0px 6px',
    borderBottomLeftRadius: 5
  },
  text: {
    color: 'var(--text-color-25)',
    fontWeight: 500
  },
  iconContainer: {
    marginTop: 4
  }
}))

const CompareScansButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const patient = useSelector(state => state.patientsReducer.patient)

  const handleClick = useCallback(() => {
    dispatch(Actions.setCompareScansDialogOpen({ isOpen: true }))
    trackEvent('Compare Scans - compare scans button clicked', {
      patientId: patient?.id,
      treatmentType: patient?.treatments?.items[0]?.type
    })
  }, [dispatch, patient])

  return (
    <Grid container alignItems="center" spacing={1} className={classes.root} onClick={handleClick}>
      <Grid item className={classes.iconContainer}>
        <CompareScans />
      </Grid>
      <Grid item>
        <DazzedParagraph12 className={classes.text}>
          {t('pages.patients.selectedPatient.timeline.compareScans.compareScansButton')}
        </DazzedParagraph12>
      </Grid>
    </Grid>
  )
}

export default CompareScansButton
