import * as React from 'react'

function SvgArrowDiagonal(props) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.563 5.931a.522.522 0 00-.518.5.48.48 0 00.483.5h8.71L6.13 17.038a.5.5 0 10.707.707L16.945 7.638v8.71a.48.48 0 00.5.482c.276-.01.5-.24.5-.517V5.931H7.562z"
        fill="#3C52EF"
      />
    </svg>
  )
}

export default SvgArrowDiagonal
