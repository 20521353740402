import React from 'react'
import { Grid, makeStyles, Icon } from '@material-ui/core'
import GIProfileMenu from './GIProfileMenu'
import Logo from '../../resources/logo.svg'

const useStyles = makeStyles({
  appHeader: {
    width: '100%',
    height: '100%',
    borderBottom: '1px solid var(--border-color-26)'
  },
  container: {
    width: '100%',
    height: '100%'
  },
  logoContainer: {
    height: '100%',
    width: 160,
    background: 'var(--primary-color)',
    borderRadius: '0px 0px 40px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    height: '50%',
    width: '50%'
  },
  menuContainer: {
    paddingRight: '50px'
  }
})

const GIAppHeader = () => {
  const classes = useStyles()

  return (
    <header className={classes.appHeader}>
      <Grid alignItems="center" container className={classes.container} justifyContent="space-between">
        <Grid item className={classes.logoContainer}>
          <Icon className={classes.logoContainer}>
            <img src={Logo} alt="Logo" className={classes.logo} />
          </Icon>
        </Grid>
        <Grid item className={classes.menuContainer}>
          <GIProfileMenu />
        </Grid>
      </Grid>
    </header>
  )
}

export default GIAppHeader
