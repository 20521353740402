import * as React from 'react'

const NoPatientsEmptyState = props => (
  <svg width="194" height="216" viewBox="0 0 194 216" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.15"
      d="M94.5141 189.789C138.115 189.789 173.461 154.444 173.461 110.843C173.461 67.2415 138.115 31.8959 94.5141 31.8959C50.913 31.8959 15.5674 67.2415 15.5674 110.843C15.5674 154.444 50.913 189.789 94.5141 189.789Z"
      fill="white"
    />
    <path d="M82.5596 157.051L87.0416 161.758L93.9758 163.263L85.3864 154.967L82.5596 157.051Z" fill="#3C52EB" />
    <path d="M192.015 69.6984L193.235 74.6641L189.945 80.706L183.16 69.0649L192.015 69.6984Z" fill="#3C52EB" />
    <path
      d="M147.76 54.4483L186.729 67.5402C192.58 69.5088 195.244 76.2795 192.287 81.7015L143.285 171.703C140.642 176.553 134.906 178.808 129.675 177.044L90.7058 163.952C84.8546 161.983 82.1913 155.212 85.1475 149.79L134.15 59.7886C136.786 54.9387 142.522 52.6909 147.76 54.4483Z"
      fill="#3C52EB"
    />
    <path
      d="M143.605 50.0411L187.308 64.7201C191.715 66.1982 193.711 71.3001 191.49 75.3803L140.104 169.769C138.033 173.57 133.537 175.334 129.437 173.958L85.7264 159.272C81.3193 157.794 79.3235 152.692 81.5441 148.612L132.931 54.2234C135.002 50.4294 139.504 48.6652 143.605 50.0411Z"
      fill="white"
    />
    <path
      d="M143.428 49.7004L187.131 64.3794C191.538 65.8575 193.534 70.9594 191.314 75.0396L139.927 169.428C137.856 173.229 133.36 174.993 129.26 173.617L85.5565 158.938C81.1494 157.46 79.1536 152.358 81.3742 148.278L132.761 53.8895C134.832 50.0886 139.327 48.3244 143.428 49.7004Z"
      fill="#5E76EB"
    />
    <path
      d="M187.874 65.769C191.007 67.4788 192.172 71.4227 190.455 74.5697L139.069 168.958C137.223 172.344 133.231 173.917 129.573 172.684L85.8699 158.005C85.5088 157.882 85.1546 157.726 84.8208 157.549C81.6875 155.839 80.5227 151.895 82.2393 148.748L133.626 54.3596C135.472 50.9742 139.464 49.4008 143.121 50.6337L186.825 65.3127C187.186 65.4353 187.54 65.5919 187.874 65.769Z"
      fill="white"
    />
    <path
      d="M187.874 65.769C191.007 67.4788 192.172 71.4227 190.455 74.5697L139.069 168.958C137.223 172.344 133.231 173.917 129.573 172.684L85.8699 158.005C85.5088 157.882 85.1546 157.726 84.8208 157.549C81.6875 155.839 80.5227 151.895 82.2393 148.748L133.626 54.3596C135.472 50.9742 139.464 49.4008 143.121 50.6337L186.825 65.3127C187.186 65.4353 187.54 65.5919 187.874 65.769Z"
      fill="white"
    />
    <path
      d="M185.4 90.5831C185.127 90.3651 184.76 90.44 184.589 90.7534L178.22 102.456C178.05 102.769 178.132 103.205 178.411 103.423C178.684 103.641 179.051 103.566 179.222 103.253L185.591 91.5503C185.761 91.237 185.679 90.801 185.4 90.5831Z"
      fill="#001335"
    />
    <path
      d="M58.5824 165.279C58.5824 165.279 52.8742 158.712 27.2558 168.078C0.492931 177.86 -1.74806 203.921 0.908466 210.024C1.8076 214.949 30.9613 217.721 52.4587 214.82C74.6305 211.823 93.165 201.823 95.406 197.041C97.2724 193.745 96.0803 181.484 89.9839 175.128C84.2962 169.202 75.2845 170.333 58.5824 165.279Z"
      fill="#001335"
    />
    <path
      d="M17.7061 135.996C21.9974 140.573 32.3238 146.131 31.159 167.697C31.0977 168.814 30.9206 169.488 31.1862 170.422C33.1412 177.172 46.8189 176.055 51.0557 175.469C56.5118 174.713 60.3604 171.041 59.6315 167.629C58.9027 164.216 57.9286 156.526 57.9286 156.526"
      fill="#FFB939"
    />
    <path
      d="M58.4594 160.497C58.0235 157.541 57.9282 156.526 57.9282 156.526L34.4145 144.524C32.2416 145.158 31.2062 146.2 32.1871 147.828C35.9607 154.129 42.3977 160.497 58.4594 160.497Z"
      fill="#FF8E00"
    />
    <path
      d="M80.8497 116.209C79.4601 139.497 75.4208 161.574 45.4429 155.573C37.1668 153.918 30.5323 146.568 24.2996 141.323C20.1173 137.808 17.5834 134 14.9337 129.58C12.0183 124.709 11.6709 120.18 11.6709 113.027C11.6709 89.6909 25.117 71.0748 45.4429 71.0748C65.7619 71.068 82.4913 88.7305 80.8497 116.209Z"
      fill="#FFB939"
    />
    <path
      d="M23.3451 143.19C21.4038 138.932 16.7515 136.623 13.6045 128.742C9.40177 118.232 4.10915 105.705 5.66901 99.1524C7.09945 93.1378 8.47538 87.0754 15.539 79.2761C22.5959 71.4768 30.2317 66.3749 41.3278 63.7116C53.0097 60.912 78.4579 64.3996 85.4943 76.64C88.6889 82.1983 88.5867 89.1053 84.1456 93.6214C80.3379 97.4972 75.0044 98.3895 71.3329 99.6497C62.6413 102.62 33.8146 103.396 27.5002 101.809C27.5002 101.809 30.1159 106.121 30.5041 112.735C30.7289 116.549 27.2414 118.177 22.9637 115.425C17.7732 112.088 9.96713 116.461 15.5867 126.671C19.8848 134.477 24.7075 132.386 25.9949 132.216C27.2754 132.039 30.8583 135.315 29.387 141.493C28.413 145.54 26.4104 149.919 23.3451 143.19Z"
      fill="#3C52EB"
    />
    <path
      d="M43.5156 137.978C56.0422 142.726 68.8003 140.696 72.1789 134.361C72.1789 134.361 72.1993 145.117 60.3607 146.901C48.5153 148.686 43.5156 137.978 43.5156 137.978Z"
      fill="white"
    />
    <path
      d="M124.615 145.179L125.174 145.438L124.622 145.724L124.54 146.33L124.104 145.901L123.498 146.01L123.777 145.458L123.484 144.92L124.091 145.015L124.52 144.566L124.615 145.179Z"
      fill="white"
    />
    <path
      d="M136.585 55.5455C137.743 53.4132 140.256 52.4326 142.559 53.2023L187.399 68.5152C188.673 69.5708 189.068 71.4239 188.244 72.9426L183.667 81.355L143.778 154.627L136.857 167.331C135.706 169.436 133.24 170.417 130.958 169.695L101.157 159.681L87.1797 154.988C86.9822 154.92 86.7846 154.838 86.6006 154.736C85.8582 154.341 85.3406 153.701 85.0408 152.979L84.9664 152.795C84.6531 151.882 84.6939 150.847 85.1911 149.927L136.585 55.5455Z"
      fill="#CFDCFF"
    />
    <path
      d="M100.376 131.938C101.005 130.688 102.468 130.104 103.785 130.577L140.564 143.775C142.129 144.336 142.849 146.141 142.102 147.626L134.392 162.942C133.763 164.191 132.3 164.776 130.983 164.303L94.204 151.105C92.6388 150.543 91.9181 148.739 92.6658 147.253L100.376 131.938Z"
      fill="white"
    />
    <circle cx="104.934" cy="137.837" r="2.76217" transform="rotate(19.7404 104.934 137.837)" fill="#3C52EB" />
    <rect
      x="122.145"
      y="143.034"
      width="16.1127"
      height="1.84145"
      transform="rotate(19.7404 122.145 143.034)"
      fill="#001335"
    />
    <rect
      x="99.7578"
      y="141.359"
      width="37.2894"
      height="1.38109"
      transform="rotate(19.7404 99.7578 141.359)"
      fill="#CFDCFF"
    />
    <rect
      x="97.8027"
      y="144.081"
      width="37.2894"
      height="1.38109"
      transform="rotate(19.7404 97.8027 144.081)"
      fill="#CFDCFF"
    />
    <rect
      x="96.2803"
      y="146.959"
      width="25.3199"
      height="1.38109"
      transform="rotate(19.7404 96.2803 146.959)"
      fill="#CFDCFF"
    />
    <path
      d="M126.52 83.3103C127.149 82.0605 128.612 81.4764 129.929 81.949L166.708 95.147C168.273 95.7087 168.994 97.5134 168.246 98.9987L160.536 114.314C159.907 115.564 158.444 116.148 157.127 115.675L120.349 102.477C118.783 101.916 118.063 100.111 118.81 98.6255L126.52 83.3103Z"
      fill="white"
    />
    <circle cx="131.079" cy="89.209" r="2.76217" transform="rotate(19.7404 131.079 89.209)" fill="#3C52EB" />
    <rect
      x="148.289"
      y="94.4064"
      width="16.1127"
      height="1.84145"
      transform="rotate(19.7404 148.289 94.4064)"
      fill="#001335"
    />
    <rect
      x="125.902"
      y="92.7313"
      width="37.2894"
      height="1.38109"
      transform="rotate(19.7404 125.902 92.7313)"
      fill="#CFDCFF"
    />
    <rect
      x="123.947"
      y="95.4535"
      width="37.2894"
      height="1.38109"
      transform="rotate(19.7404 123.947 95.4535)"
      fill="#CFDCFF"
    />
    <rect
      x="122.426"
      y="98.3312"
      width="25.3199"
      height="1.38109"
      transform="rotate(19.7404 122.426 98.3312)"
      fill="#CFDCFF"
    />
    <path
      d="M139.363 58.9966C139.992 57.7468 141.455 57.1627 142.772 57.6353L179.551 70.8334C181.116 71.395 181.837 73.1997 181.089 74.6851L173.379 90.0003C172.75 91.2501 171.287 91.8342 169.97 91.3616L133.191 78.1635C131.626 77.6019 130.905 75.7972 131.653 74.3119L139.363 58.9966Z"
      fill="white"
    />
    <circle cx="143.921" cy="64.8953" r="2.76217" transform="rotate(19.7404 143.921 64.8953)" fill="#3C52EB" />
    <rect
      x="161.132"
      y="70.0928"
      width="16.1127"
      height="1.84145"
      transform="rotate(19.7404 161.132 70.0928)"
      fill="#001335"
    />
    <rect
      x="138.744"
      y="68.4177"
      width="37.2894"
      height="1.38109"
      transform="rotate(19.7404 138.744 68.4177)"
      fill="#CFDCFF"
    />
    <rect
      x="136.789"
      y="71.1399"
      width="37.2894"
      height="1.38109"
      transform="rotate(19.7404 136.789 71.1399)"
      fill="#CFDCFF"
    />
    <rect
      x="135.268"
      y="74.0176"
      width="25.3199"
      height="1.38109"
      transform="rotate(19.7404 135.268 74.0176)"
      fill="#CFDCFF"
    />
    <path
      d="M113.678 107.624C114.308 106.375 115.77 105.791 117.087 106.263L153.866 119.461C155.431 120.023 156.152 121.828 155.404 123.313L147.694 138.628C147.065 139.878 145.603 140.462 144.286 139.989L107.507 126.791C105.942 126.23 105.221 124.425 105.969 122.94L113.678 107.624Z"
      fill="white"
    />
    <circle cx="118.236" cy="113.523" r="2.76217" transform="rotate(19.7404 118.236 113.523)" fill="#3C52EB" />
    <rect
      x="135.447"
      y="118.721"
      width="16.1127"
      height="1.84145"
      transform="rotate(19.7404 135.447 118.721)"
      fill="#001335"
    />
    <rect
      x="113.061"
      y="117.046"
      width="37.2894"
      height="1.38109"
      transform="rotate(19.7404 113.061 117.046)"
      fill="#CFDCFF"
    />
    <rect
      x="111.105"
      y="119.768"
      width="37.2894"
      height="1.38109"
      transform="rotate(19.7404 111.105 119.768)"
      fill="#CFDCFF"
    />
    <rect
      x="109.584"
      y="122.645"
      width="25.3199"
      height="1.38109"
      transform="rotate(19.7404 109.584 122.645)"
      fill="#CFDCFF"
    />
    <path
      d="M102.948 40.2907C102.948 62.5442 142.674 107.153 142.674 107.153C142.674 107.153 183.53 62.5442 183.53 40.2907C183.53 18.0372 165.492 0 143.239 0C120.992 0 102.948 18.0372 102.948 40.2907Z"
      fill="white"
    />
    <path
      d="M135.542 73.3815C135.542 73.3815 139.111 71.3789 155.765 74.4033C159.784 75.1321 163.115 76.5217 165.833 78.2586C175.056 65.1803 183.53 50.4196 183.53 40.2907C183.53 18.0372 165.492 0 143.239 0C120.985 0 102.948 18.0372 102.948 40.2907C102.948 50.1744 110.782 64.4583 119.494 77.2914C123.022 75.9223 128.022 75.6634 135.542 73.3815Z"
      fill="#D0DBFF"
    />
    <path
      d="M140.14 104.218C141.734 106.091 142.674 107.147 142.674 107.147C142.674 107.147 143.518 106.221 144.969 104.579C143.382 104.531 141.768 104.416 140.14 104.218Z"
      fill="#D0DBFF"
    />
    <path
      d="M137.449 68.1018C137.449 68.1018 136.911 72.3386 136.509 74.2186C136.107 76.0986 138.232 78.1217 141.236 78.5372C143.865 78.8982 148.994 78.9936 151.842 73.6396C151.842 73.6396 150.99 69.4028 151.447 65.0706"
      fill="#FFB939"
    />
    <path
      d="M137.061 70.9225C144.751 70.9906 150.085 67.8641 152.537 64.6354C155.289 61.0184 136.68 61.2023 136.68 61.2023L137.061 70.9225Z"
      fill="#FF8E00"
    />
    <path
      d="M165.078 25.1621C160.916 17.9895 153.743 14.9379 150.508 14.4475C146.032 13.7731 138.512 13.3576 132.375 16.1913C125.965 19.1543 121.776 24.7398 120.591 31.7899C119.413 38.8399 119.746 45.5289 120.393 50.6376C121.313 57.9056 124.31 58.8388 124.719 62.5375C125.121 66.168 125.659 71.1269 134.112 71.3176C135.386 71.3449 136.312 71.3585 137.007 71.3517C137.252 69.6897 137.456 68.1026 137.456 68.1026L154.649 62.2377C154.649 62.2377 155.017 66.2089 153.75 69.9553C163.77 68.7633 166.392 60.9844 167.203 58.4096C170.99 46.3463 169.239 32.3348 165.078 25.1621Z"
      fill="#3C52EB"
    />
    <path d="M119.426 41.3268L128.151 38.425" stroke="#001335" strokeWidth="0.923077" strokeMiterlimit="10" />
    <path
      d="M122.416 43.629C123.261 57.7835 125.713 71.2024 143.941 67.5514C156.052 65.1264 166.31 56.0534 166.31 41.8716C166.31 27.6898 156.297 16.1918 143.941 16.1918C131.584 16.1918 121.421 26.9269 122.416 43.629Z"
      fill="#FFB939"
    />
    <path d="M147.436 46.6735L156.161 43.7717" stroke="#001335" strokeWidth="0.923077" strokeMiterlimit="10" />
    <path
      d="M123.37 33.2072C123.37 33.2072 125.509 33.4388 127.191 30.0466C128.874 26.6476 128.806 20.1085 128.806 20.1085L123.37 27.5808V33.2072Z"
      fill="#3C52EB"
    />
    <path
      d="M167.692 35.4751L163.96 26.4974L156.889 18.3507L142.428 14.5294L128.798 20.0944L127.354 28.3842C127.354 28.3842 125.133 35.2571 136.454 36.6194C147.905 37.9954 153.524 33.3294 156.181 31.4221C156.181 31.4221 153.204 38.4109 152.945 42.4297C152.748 45.4813 155.704 45.8219 158.245 43.0632C160.792 40.3045 166.596 43.9964 162.584 49.977C159.566 54.4726 155.772 53.3419 155.772 53.3419C155.772 53.3419 152.829 55.2219 153.708 58.9819C154.491 62.3401 152.72 64.5062 151.44 65.0579C151.106 66.8017 151.419 70.0508 151.419 70.0508C151.419 70.0508 151.93 70.2483 153.749 69.9418C154.996 69.7375 156.753 64.6696 157.747 61.4818L166.316 58.5596L167.692 35.4751Z"
      fill="#3C52EB"
    />
    <path
      d="M141.23 78.5372C138.226 78.1217 136.101 76.0987 136.503 74.2186C136.558 73.9802 136.612 73.6941 136.667 73.3944L135.066 73.0403L134.644 89.9399L137.443 102.398L143.015 86.7179L147.858 81.7251L151.836 75.4107L150.712 75.3562C147.79 78.9663 143.546 78.8574 141.23 78.5372Z"
      fill="#001335"
    />
    <path
      d="M152.326 72.0804C152.326 72.0804 153.62 73.688 154.942 74.7778C156.263 75.8677 153.511 83.5716 150.439 86.6368C150.439 86.6368 147.619 86.4257 146.645 86.8071C146.645 86.8071 148.934 88.2716 149.145 89.1844C149.145 89.1844 144.411 92.7196 142.252 95.5464C140.086 98.3733 138.308 101.813 137.709 103.932C137.709 103.932 136.564 98.816 140.44 91.4186C142.272 87.9174 151.257 79.11 152.326 72.0804Z"
      fill="#D0DBFF"
    />
    <path
      d="M151.427 70.4943C151.427 70.4943 153.477 73.5255 154.799 74.6153C156.12 75.7052 153.396 82.8301 150.324 85.8954C150.324 85.8954 147.279 85.834 146.305 86.2155C146.305 86.2155 148.757 87.7481 148.968 88.6677C148.968 88.6677 143.975 92.3732 141.816 95.2068C139.65 98.0336 138.199 100.997 137.708 103.946C137.708 103.946 136.128 98.4764 140.004 91.079C141.836 87.5642 151.536 77.5375 151.427 70.4943Z"
      fill="white"
    />
    <path
      d="M135.733 75.8812C133.533 84.7499 136.993 100.798 136.993 100.798L137.702 103.931C133.083 95.6281 131.06 86.5073 131.06 86.5073C131.442 85.7308 133.451 84.8453 133.451 84.8453C132.947 84.4638 130.693 84.1709 130.693 84.1709C130.693 84.1709 130.509 82.7678 130.992 80.2134C131.728 76.3717 133.703 74.4099 133.703 74.4099C134.882 73.2179 136.292 71.9032 136.292 71.9032L135.733 75.8812Z"
      fill="#D0DBFF"
    />
    <path
      d="M136.468 74.5264C134.268 83.3951 137.381 100.24 137.381 100.24L137.544 103.176C131.441 90.0296 131.441 85.9494 131.441 85.9494C131.823 85.1729 133.832 84.2874 133.832 84.2874C133.328 83.9059 131.073 83.613 131.073 83.613C131.073 83.613 130.889 82.2098 131.373 79.6555C132.109 75.8137 134.084 73.852 134.084 73.852C135.263 72.66 137.02 71.1274 137.02 71.1274L136.468 74.5264Z"
      fill="white"
    />
    <path
      d="M135.583 45.8965C135.583 49.316 138.267 52.5583 141.584 53.1441C144.901 53.7299 147.585 51.4344 147.585 48.015C147.585 44.5955 144.901 42.5589 141.584 41.9731C138.267 41.3873 135.583 42.4771 135.583 45.8965Z"
      stroke="#001335"
      strokeWidth="0.923077"
      strokeMiterlimit="10"
    />
    <path
      d="M119.263 43.0427C119.263 46.3055 121.831 49.4048 124.991 49.9633C128.152 50.5219 130.72 48.3285 130.72 45.0658C130.72 41.803 128.152 39.8549 124.991 39.2963C121.831 38.7378 119.263 39.78 119.263 43.0427Z"
      stroke="#001335"
      strokeWidth="0.923077"
      strokeMiterlimit="10"
    />
    <path
      d="M135.646 45.0655C135.632 44.3026 134.433 43.4783 133.159 43.2536C131.886 43.0288 130.646 43.4238 130.666 44.1867"
      stroke="#001335"
      strokeWidth="0.923077"
      strokeMiterlimit="10"
    />
  </svg>
)

export default NoPatientsEmptyState
