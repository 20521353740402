import * as React from 'react'

function SvgCalendar({ className, color = '#001335' }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.1995 8.71443H16.7995V10.3859H7.1995V8.71443ZM16.8 7.60014H15.6V6.52871C15.6 6.19734 15.3314 5.92871 15 5.92871C14.6686 5.92871 14.4 6.19734 14.4 6.52871V7.60014H9.6V6.52871C9.6 6.19734 9.33137 5.92871 9 5.92871C8.66863 5.92871 8.4 6.19734 8.4 6.52871V7.60014H7.2C6.5382 7.60014 6 8.10045 6 8.71443V15.9573C6 16.5718 6.5382 17.0716 7.2 17.0716H16.8C17.4618 17.0716 18 16.5718 18 15.9573V8.71443C18 8.10045 17.4618 7.60014 16.8 7.60014Z"
        fill={color}
      />
    </svg>
  )
}

export default SvgCalendar
