import React, { useRef, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import Tooltip from '../../../common/Tooltip'

const useStyles = () =>
  makeStyles({
    content: {
      lineHeight: '20px',
      fontFamily: 'Dazzed',
      fontSize: 14,
      fontWeight: 'normal',
      letterSpacing: 'normal',
      display: 'flex',
      alignItems: 'center',
      marginRight: 20
    },
    icon: {
      marginRight: 10
    },
    value: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }
  })()

const FieldWithIcon = ({ value, className, iconComponent, tooltipText = value }) => {
  const classes = useStyles()
  const ref = useRef(null)

  const [disableTooltip, setDisableTooltip] = useState(true)

  useEffect(() => {
    if (ref.current && ref.current.scrollWidth >= ref.current.clientWidth) {
      setDisableTooltip(false)
    }
  }, [value])

  return (
    <Tooltip value={tooltipText} disableTooltip={disableTooltip} arrow placement="bottom-start" variant="dark">
      <div className={classNames(classes.content, className)}>
        <div className={classes.icon}>{iconComponent}</div>
        <div ref={ref} className={classes.value}>
          {value}
        </div>
      </div>
    </Tooltip>
  )
}

export default FieldWithIcon
