import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation, Trans } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'var(--bg-color-50)',
    padding: '8px 12px'
  },
  text: {
    color: 'var(--text-color-28)',
    '& strong': {
      fontWeight: 600
    }
  }
}))

const ShipbobStatusInformationBox = ({ status, statusInformation }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <DazzedParagraph14 className={classes.text}>
        <Trans
          i18nKey={'pages.rcDashboard.dialogs.shippingDetails.shipbobSection.statusInformationTitle'}
          values={{
            status: t(`pages.rcDashboard.dialogs.shippingDetails.shippingStatuses.${status}`),
            statusInformation
          }}
        />
      </DazzedParagraph14>
    </div>
  )
}

export default ShipbobStatusInformationBox
