import * as React from 'react'

function NotifyPractice(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.66134 8.91802V8.83127C7.66357 8.42866 7.70138 8.10836 7.77479 7.87035C7.85041 7.63235 7.95718 7.44106 8.09509 7.29647C8.233 7.14967 8.40094 7.01509 8.5989 6.89276C8.73459 6.80823 8.85582 6.71592 8.96258 6.61582C9.07158 6.51351 9.15721 6.40006 9.21949 6.2755C9.28178 6.14871 9.31292 6.00747 9.31292 5.85176C9.31292 5.67604 9.27177 5.52367 9.18947 5.39466C9.10717 5.26565 8.99595 5.16555 8.85582 5.09438C8.71791 5.0232 8.56331 4.98761 8.39204 4.98761C8.23411 4.98761 8.08397 5.02208 7.94161 5.09104C7.80148 5.15777 7.6847 5.26009 7.59128 5.398C7.50008 5.53368 7.44892 5.70607 7.4378 5.91516H6.25C6.26112 5.49253 6.36344 5.13886 6.55696 4.85415C6.7527 4.56943 7.01072 4.35589 7.33103 4.21354C7.65356 4.07118 8.00945 4 8.39871 4C8.82356 4 9.19725 4.07452 9.51978 4.22355C9.84453 4.37258 10.097 4.585 10.2772 4.86082C10.4596 5.13441 10.5508 5.45917 10.5508 5.83508C10.5508 6.08866 10.5096 6.31554 10.4273 6.51573C10.3472 6.71592 10.2327 6.89387 10.0837 7.04957C9.93462 7.20528 9.75779 7.3443 9.55315 7.46664C9.37297 7.57785 9.22506 7.69352 9.10939 7.81363C8.99595 7.93375 8.91142 8.07499 8.85582 8.23737C8.80243 8.39752 8.77463 8.59549 8.7724 8.83127V8.91802H7.66134ZM8.2419 11C8.04171 11 7.86932 10.9288 7.72474 10.7865C7.58016 10.6441 7.50786 10.4706 7.50786 10.266C7.50786 10.0658 7.58016 9.8945 7.72474 9.75215C7.86932 9.60979 8.04171 9.53861 8.2419 9.53861C8.43986 9.53861 8.61114 9.60979 8.75572 9.75215C8.90253 9.8945 8.97593 10.0658 8.97593 10.266C8.97593 10.4017 8.94145 10.5251 8.8725 10.6363C8.80577 10.7475 8.71679 10.8365 8.60558 10.9032C8.49658 10.9677 8.37536 11 8.2419 11Z"
        fill="#001335"
      />
      <path
        d="M4.01033 10.8768L4.45754 11.1004L4.59993 10.8156L4.4021 10.5661L4.01033 10.8768ZM2.8265 13.2445L2.37928 13.0209L1.70846 14.3625L3.0501 13.6917L2.8265 13.2445ZM5.25198 12.0317L5.53345 11.6185L5.29088 11.4533L5.02837 11.5845L5.25198 12.0317ZM13.5 7.40039C13.5 10.2168 11.2168 12.5 8.40039 12.5V13.5C11.7691 13.5 14.5 10.7691 14.5 7.40039H13.5ZM8.40039 2.30078C11.2168 2.30078 13.5 4.58395 13.5 7.40039H14.5C14.5 4.03167 11.7691 1.30078 8.40039 1.30078V2.30078ZM3.30078 7.40039C3.30078 4.58395 5.58395 2.30078 8.40039 2.30078V1.30078C5.03167 1.30078 2.30078 4.03167 2.30078 7.40039H3.30078ZM4.4021 10.5661C3.71238 9.69632 3.30078 8.59712 3.30078 7.40039H2.30078C2.30078 8.83068 2.7937 10.1472 3.61855 11.1875L4.4021 10.5661ZM3.27371 13.4681L4.45754 11.1004L3.56311 10.6532L2.37928 13.0209L3.27371 13.4681ZM5.02837 11.5845L2.60289 12.7973L3.0501 13.6917L5.47559 12.4789L5.02837 11.5845ZM8.40039 12.5C7.33659 12.5 6.35025 12.1748 5.53345 11.6185L4.97051 12.445C5.94801 13.1108 7.1295 13.5 8.40039 13.5V12.5Z"
        fill="#001335"
      />
    </svg>
  )
}

export default NotifyPractice
