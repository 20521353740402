import React from 'react'
import { ButtonBase, makeStyles } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'

const useStyles = ({ disabled, background, color, fab }) =>
  makeStyles({
    content: {
      background: ({ disabled, background }) => (disabled ? 'lightgray' : background),
      borderRadius: 30,
      padding: ({ fab }) => (fab ? '12px' : '8px 15px'),
      color,
      fontFamily: 'Dazzed',
      fontWeight: '500',
      fontSize: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })({ disabled, background, fab })

const ActionButton = ({
  id = '',
  onClick,
  label,
  isLoading,
  type,
  background = '#FFBD39',
  color = 'black',
  iconComponent,
  disabled = false,
  alignSelf = 'flex-start'
}) => {
  const classes = useStyles({ disabled, background, color, fab: !!iconComponent && !label })
  return (
    <ButtonBase
      id={id}
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={{ borderRadius: 30, width: 'fit-content', alignSelf }}
    >
      <div className={classes.content}>
        {iconComponent}
        {label && (
          <div style={{ marginLeft: iconComponent ? 7 : 0 }}>
            {isLoading ? <CircularProgress color="inherit" size={16} /> : label}
          </div>
        )}
      </div>
    </ButtonBase>
  )
}

export default ActionButton
