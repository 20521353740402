import * as React from 'react'
const Help = ({ ...props }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="8" stroke="#041333" />
      <path d="M9 8V14" stroke="#041333" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M9 4V5" stroke="#041333" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}

export default Help
