import * as React from 'react'

function SvgEditV2(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 12.4638C19.1875 12.4638 19 12.7038 19 12.9996V16.9996C19 17.4996 18.5 17.9996 18 17.9996H6C5.5 17.9996 5 17.4059 5 16.9059V6.97754C5 6.47754 5.5 5.97754 6 5.97754L12 5.99963C12.2959 5.99963 12.5 5.84135 12.5 5.49963C12.5 5.15792 12.2959 4.99963 12 4.99963L6 4.97754C5 4.97754 4 5.97754 4 6.97754V16.9059C4 17.9059 5 18.9996 6 18.9996H18C19 18.9996 20 17.9996 20 16.9996V12.9482C20 12.6523 19.8125 12.4638 19.5 12.4638Z"
        fill="#727B8C"
      />
      <path
        d="M19.3996 4.60073C19.0149 4.21603 18.4932 3.99995 17.9492 4.00001C17.4049 3.99844 16.8827 4.2149 16.4991 4.60108L9.44271 11.6568C9.38417 11.7158 9.34001 11.7875 9.31361 11.8663L8.2422 15.0805C8.14869 15.3613 8.30046 15.6646 8.58117 15.7581C8.63563 15.7762 8.69267 15.7855 8.75005 15.7855C8.80755 15.7855 8.86471 15.7762 8.91933 15.7582L12.1336 14.6868C12.2125 14.6605 12.2842 14.6161 12.343 14.5572L19.3994 7.50087C20.2003 6.70007 20.2004 5.40163 19.3996 4.60073ZM18.6419 6.74389L11.6777 13.7081L9.59699 14.4029L10.2897 12.3249L17.2565 5.36069C17.6395 4.97844 18.2599 4.97907 18.6422 5.36208C18.8248 5.54504 18.9277 5.79273 18.9285 6.05122C18.9291 6.31109 18.826 6.56045 18.6419 6.74389Z"
        fill="#727B8C"
      />
    </svg>
  )
}

export default SvgEditV2
