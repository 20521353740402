import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import ChatModal from './ChatModal'
import Tooltip from 'components/common/Tooltip.mobile'

const useStyles = () =>
  makeStyles({
    icon: {
      width: 20,
      marginRight: 20,
      marginLeft: 10,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center'
    },
    menuItem: {
      height: 50,
      padding: 5,
      fontSize: 14,
      '&:hover': {
        backgroundColor: 'var(--bg-color-14)'
      }
    }
  })()

const ChatActionsModal = ({ isOpen, handleClose, actions }) => {
  const classes = useStyles()

  const handleClickOption = useCallback(
    (e, action) => {
      e.stopPropagation()
      action.onMobileClick()
      handleClose()
    },
    [handleClose]
  )

  return (
    <ChatModal open={isOpen} onClose={handleClose}>
      {actions.map((action, i) => (
        <Tooltip key={i} value={action.tooltip} placement="left-start" arrow>
          <MenuItem
            key={action.title}
            onClick={e => !action.disabled && handleClickOption(e, action)}
            className={classes.menuItem}
            disabled={action.disabled}
          >
            <span className={classes.icon}>{action.icon}</span>
            {action.title}
          </MenuItem>
        </Tooltip>
      ))}
    </ChatModal>
  )
}

export default React.memo(ChatActionsModal)
