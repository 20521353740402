import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import Title from '../Title'
import images from '../../../../../../resources'
import ColorPicker from '../../../../../common/ColorPicker/ColorPicker'
import PrimaryButton from '../../../../../common/buttons/PrimaryButton'
import PatientRecommendation from './PatientRecommendation'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 10,
    paddingBottom: 10,
    flexWrap: 'noWrap'
  },
  button: {
    fontSize: 15,
    marginTop: 15
  },
  previewContainer: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  backgroundContainer: {
    position: 'absolute',
    top: 12,
    zIndex: -1,
    left: 32,
    width: 170
  },
  topColor: {
    background: 'white'
  },
  bottomColor: {
    background: ({ color }) => color,
    height: 120
  },
  svg: {
    width: '100%',
    height: '100%',
    '& path': {
      fill: 'white'
    },
    '& rect': {
      fill: ({ color }) => color
    }
  },
  svgContainer: {
    position: 'relative',
    top: 'calc( var(--divider-height) / 2 * -1 )',
    height: 'var(--divider-height)',
    width: '100%',
    float: 'left'
  },
  colorContainer: {
    width: '65%'
  },
  footerTextColorLabel: {
    marginTop: 10
  },
  image: {
    height: 65
  }
}))

const FooterColor = ({
  textColor = '',
  bgColor = '',
  accentColor = '',
  suggestedBgColors = [],
  suggestedTextColors = [],
  handleBgColor = () => {},
  handleFooterBgColorOptions = () => {},
  handleTextColor = () => {},
  handleTextColorOptions = () => {}
}) => {
  const { t } = useTranslation()
  const classes = useStyles({ color: bgColor })

  return (
    <Grid container className={classes.container}>
      <Grid container direction="column" className={classes.colorContainer}>
        <Grid item>
          <Title label={t('pages.accountSettings.services.remoteConsultation.themeColors.footerColorTitle')} optional />
        </Grid>
        <Grid item>
          <ColorPicker
            handleColor={handleBgColor}
            handleOptions={handleFooterBgColorOptions}
            selectedColor={bgColor}
            suggestedColors={suggestedBgColors}
          />
        </Grid>
        <Grid item className={classes.footerTextColorLabel}>
          <Title
            label={t('pages.accountSettings.services.remoteConsultation.themeColors.footerFontColorTitle')}
            optional
          />
        </Grid>
        <Grid item>
          <ColorPicker
            handleColor={handleTextColor}
            handleOptions={handleTextColorOptions}
            selectedColor={textColor}
            suggestedColors={suggestedTextColors}
          />
        </Grid>
      </Grid>
      <Grid item className={classes.previewContainer}>
        <img alt="Footer color preview" src={images.VideoPreview} className={classes.image} />
        <Grid className={classes.backgroundContainer}>
          <div className={classes.topColor}></div>
          <div className={classes.svgContainer}>
            <svg className={classes.svg} viewBox="0 70 500 60" preserveAspectRatio="none">
              <rect x="0" y="0" width="500" height="500" style={{ stroke: 'none' }} />
              <path d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z" style={{ stroke: 'none' }}></path>
            </svg>
          </div>
          <div className={classes.bottomColor}></div>
        </Grid>
        <PatientRecommendation color={textColor} accentColor={accentColor} />
        <Grid className={classes.button}>
          <PrimaryButton
            fontColor={textColor}
            color={bgColor}
            type="button"
            borderRadius="0px 0px 24px 0px"
            height={32}
            label={t('pages.rcLanding.body.saveBtn')}
            width="170px"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FooterColor
