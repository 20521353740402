import React from 'react'
import { IconButton, InputBase } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  deleteIcon: {
    color: '#ccc'
  },
  errorCaption: {
    fontSize: 11,
    fontWeight: 'bold',
    color: 'red',
    marginLeft: 8
  }
})

const SlideBulletDesginer = ({ data, onChange, onDelete, shouldShowErrors }) => {
  const classes = useStyles()

  const handleTitleChange = value => {
    onChange({
      ...data,
      title: value
    })
  }

  const handleDescriptionChange = value => {
    if (value.length <= 550 || value.length < data.description.length) {
      onChange({
        ...data,
        description: value
      })
    }
  }

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <InputBase
            placeholder="Something great about the feature"
            value={data.title}
            onChange={e => handleTitleChange(e.target.value)}
            style={{ width: '100%', fontWeight: '600' }}
          />
        </div>
        <IconButton size="small" className={classes.deleteIcon} onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </div>
      <InputBase
        placeholder="Some additional information"
        value={data.description}
        onChange={e => handleDescriptionChange(e.target.value)}
        style={{ width: '100%' }}
      />
      {shouldShowErrors && !data.title && !data.description && (
        <div className={classes.errorCaption}>Please fill at least one of the bullet fields</div>
      )}
    </div>
  )
}

export default SlideBulletDesginer
