import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { generateScanNumberDict, getAggregateScans } from 'utils/scanUtils'

export default () => {
  const grinScans = useSelector(state => state.patientsReducer.patient?.grinScans?.items || [])

  const aggregatedScans = useMemo(() => getAggregateScans(grinScans), [grinScans])
  const scanIdToNumberDict = useMemo(() => generateScanNumberDict(aggregatedScans), [aggregatedScans])

  return {
    scanIdToNumberDict,
    aggregatedScans
  }
}
