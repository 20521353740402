import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isCountrySupportScopesOrder } from 'utils/billingUtils'
import usePlanLimits from './usePlanLimits'

export default () => {
  const { grinPlanKey } = useSelector(state => state.practiceReducer.billing)
  const practiceDetails = useSelector(state => state.practiceReducer.details)
  const { parsedPlanOverrides } = usePlanLimits()

  const hasFreeScopes = useMemo(
    state => parsedPlanOverrides?.[grinPlanKey]?.freeScopes,
    [grinPlanKey, parsedPlanOverrides]
  )
  const orderScopesManually = useMemo(
    state => parsedPlanOverrides?.[grinPlanKey]?.orderScopesManually,
    [grinPlanKey, parsedPlanOverrides]
  )

  const canClinicBuyScopes = useCallback(
    () => !orderScopesManually && isCountrySupportScopesOrder(practiceDetails.country),
    [orderScopesManually, practiceDetails.country]
  )

  return {
    canClinicBuyScopes,
    practiceDetails,
    hasFreeScopes
  }
}
