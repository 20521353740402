import * as React from 'react'

export default () => {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 7.6665L13.6667 7.6665C15.5076 7.6665 17 9.15889 17 10.9998V10.9998C17 12.8408 15.5076 14.3332 13.6667 14.3332L11 14.3332"
        stroke="#4151E6"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7 14.3335L4.33333 14.3335C2.49238 14.3335 1 12.8411 1 11.0002V11.0002C1 9.15921 2.49239 7.66683 4.33333 7.66683L7 7.66683"
        stroke="#4151E6"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M9 1V3M9 21V19" stroke="#4151E6" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M4 2.33972L5 4.07177M14 19.6602L13 17.9282" stroke="#4151E6" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M14 2.33972L13 4.07177M4 19.6602L5 17.9282" stroke="#4151E6" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}
