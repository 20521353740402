import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Tooltip } from '@material-ui/core'
import VideoCameraIcon from 'components/common/icons/VideoCameraIcon'
import DoctorCamPlaceholder from './DoctorCamPlaceholder'

const useStyles = ({ isCapturing }) =>
  makeStyles({
    cameraVideo: {
      width: 150,
      height: 150,
      borderRadius: 100,
      objectFit: 'cover',
      position: 'absolute',
      bottom: 0
    },
    camVideoAndIconContainer: {
      position: 'absolute',
      top: isCapturing ? '23px' : '20px',
      left: isCapturing ? 0 : '10px',
      zIndex: 999
    },
    cameraIconContainer: {
      width: 40,
      height: 40,
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.14)',
      position: 'absolute',
      background: 'white',
      borderRadius: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      bottom: 0,
      left: 18,
      cursor: 'pointer'
    }
  })({ isCapturing })

const DoctorCamera = ({ isCapturing, cameraElement, isCameraOn, onToggleCameraOption }) => {
  const classes = useStyles({ isCapturing })
  const { t } = useTranslation()

  return (
    <div className={classes.camVideoAndIconContainer}>
      <video
        ref={input => {
          cameraElement.current = input
        }}
        autoPlay
        muted
        className={classes.cameraVideo}
      />
      <DoctorCamPlaceholder cameraOn={isCameraOn} />
      <Tooltip
        arrow
        title={t(`dialogs.scanReviewEditor.${isCameraOn ? 'turnCamOff' : 'turnCamOn'}`)}
        placement="top"
        classes={{ arrow: classes.arrowTooltip, tooltip: classes.tooltip }}
      >
        <div className={classes.cameraIconContainer} onClick={() => onToggleCameraOption(!isCameraOn)}>
          <VideoCameraIcon cameraOff={!isCameraOn} size="30" />
        </div>
      </Tooltip>
    </div>
  )
}

export default DoctorCamera
