import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Link, Grid } from '@material-ui/core'
import BrightCheckbox from 'components/common/inputs/BrightCheckbox'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(() => ({
  termsText: {
    color: 'var(--text-color-1)',
    marginLeft: -12
  },
  link: {
    color: 'var(--text-color-10)',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}))

const TermsOfSale = ({ checked, onChange, mixpanelPayload, mixpanelPrefix }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const termsOfSaleUrl = useSelector(state => state.appReducer.appconfig?.app?.payment?.termsOfSaleUrl)

  const handleClickTerms = useCallback(() => {
    trackEvent(`${mixpanelPrefix} - terms of sale link clicked`, mixpanelPayload)
  }, [mixpanelPayload, mixpanelPrefix])

  return (
    <Grid container alignItems="center">
      <Grid item>
        <BrightCheckbox
          color="var(--bg-color-31)"
          checked={checked}
          onChange={event => onChange(event.target.checked)}
          id={`${mixpanelPrefix}-terms-of-sale-checkbox`}
        />
      </Grid>
      <Grid item>
        <DazzedParagraph16 className={classes.termsText} strong>
          {t('pages.billingInfo.termsOfSale.iAccept')}&nbsp;
        </DazzedParagraph16>
      </Grid>
      <Grid item>
        <Link href={termsOfSaleUrl} target="_blank" rel="noopener noreferrer" onClick={handleClickTerms}>
          <DazzedParagraph16 className={classes.link} strong>
            {t('pages.billingInfo.termsOfSale.termsOfSale')}
          </DazzedParagraph16>
        </Link>
      </Grid>
    </Grid>
  )
}

export default TermsOfSale
