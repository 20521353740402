import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { Grid } from '@material-ui/core'
import BeforeAfterSharingOptions from './BeforeAfterSharingOptions'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import ShareProgressSocialMediasIcon from 'components/common/icons/BeforeAfter/ShareProgressSocialMedias'
import ShareImageWithPatient from 'components/common/icons/BeforeAfter/ShareImageWithPatient'
import BeforeAfterAsset from './BeforeAfterAsset'
import OggHeading32 from 'components/common/text/OggHeading32'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px 10px 0px 10px'
  },
  assetPlaceholder: {
    width: '222px',
    height: '380px',
    border: '1px solid var(--border-color-7)',
    borderRadius: '10px'
  },
  assetContainer: {
    padding: '10px 40px'
  },
  body: {
    fontSize: 13,
    lineHeight: '22px'
  },
  actionsContainer: {
    padding: '0px 0px 10px 0px !important'
  }
}))

const BeforeAfterDialog = ({
  isOpen,
  handleClose,
  patient,
  asset,
  sendAssetInChat,
  imgSrc,
  handleShareOptionClicked,
  assetStatus,
  analyticsMetadata,
  handleSubmitFeedback,
  handleRetry,
  error
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <BaseModal
      variant="fullscreen"
      className={classes.root}
      open={isOpen}
      handleClose={handleClose}
      primaryLabel={sendAssetInChat ? t('dialogs.beforeAfter.submitButton') : ''}
      isPrimaryDisabled={!patient || !asset}
      largerPrimaryButton
      buttonsWidth={300}
      onPrimaryBtnClick={sendAssetInChat}
      actionsClassName={classes.actionsContainer}
      primaryBtnIcon={<ShareImageWithPatient disabled={!asset} />}
    >
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <OggHeading32 textAlign="center">{t('dialogs.beforeAfter.title')}</OggHeading32>
        </Grid>
        <Grid item>
          <ShareProgressSocialMediasIcon />
        </Grid>
        <Grid item>
          <DazzedParagraph14 textAlign="center" strong className={classes.body}>
            {t('dialogs.beforeAfter.mobileBody')}
          </DazzedParagraph14>
        </Grid>
        <Grid item>
          <BeforeAfterAsset imgSrc={imgSrc} onRetry={handleRetry} />
        </Grid>
        <Grid item>
          <BeforeAfterSharingOptions disabled={!asset} assetUrl={imgSrc} onOptionClicked={handleShareOptionClicked} />
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default BeforeAfterDialog
