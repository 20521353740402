import React from 'react'
import { IconButton as MuiIconButton } from '@material-ui/core'
import Tooltip from 'components/common/Tooltip'

const IconButton = ({ tooltipPosition = 'top', arrow = false, tooltipValue = '', children, ...props }) => {
  return tooltipValue ? (
    <Tooltip arrow={arrow} placement={tooltipPosition} value={tooltipValue}>
      <MuiIconButton {...props}>{children}</MuiIconButton>
    </Tooltip>
  ) : (
    <MuiIconButton {...props}>{children}</MuiIconButton>
  )
}

export default IconButton
