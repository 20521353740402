import * as React from 'react'

export default () => {
  return (
    <svg width="37" height="41" viewBox="0 0 37 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 16V18H12V15L13 13L14.5 11.5L16 10.5L17.5 10H18.5V15L17 16Z" fill="#A6AAB3" fillOpacity="0.65" />
      <path
        d="M12 16.5C12 12.9101 14.9101 10 18.5 10V10C22.0899 10 25 12.9101 25 16.5V24.5C25 28.0898 22.0899 31 18.5 31V31C14.9101 31 12 28.0898 12 24.5V16.5Z"
        stroke="#A6AAB3"
      />
      <path d="M18.5 15V10" stroke="#767986" strokeLinecap="round" />
      <path d="M12 18L17 18" stroke="#767986" />
      <path d="M18.5 10V10C14.9101 10 12 12.9101 12 16.5V18" stroke="#767986" strokeLinecap="round" />
      <rect x="17" y="15" width="3" height="6" rx="1.5" stroke="#A6AAB3" />
      <path d="M15.5 4L18.5 1L21.5 4" stroke="#A6AAB3" />
      <path d="M33 17.5L36 20.5L33 23.5" stroke="#A6AAB3" />
      <path d="M21.5 37L18.5 40L15.5 37" stroke="#A6AAB3" />
      <path d="M4 23.5L1 20.5L4 17.5" stroke="#A6AAB3" />
    </svg>
  )
}
