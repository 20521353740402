import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators'
import Actions from '../actions'
import { contentViewed, leadCreated } from '../utils/pixelUtils'

export const pixelContentViewedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PIXEL_CONTENT_VIEWED),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      contentViewed(state.pixelReducer)
      return of(Actions.pixelEventSuccess())
    }),
    catchError(error => of(Actions.pixelEventFailure(error)))
  )

export const pixelLeadCreatedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REGISTER_RC_USER_REQUESTED),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      leadCreated(state.pixelReducer, action.payload.patientEmail)
      return of(Actions.pixelEventSuccess())
    }),
    catchError(error => of(Actions.pixelEventFailure(error)))
  )
