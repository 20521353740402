import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Actions from 'actions'
import MedicalHistoryEmptyState from './MedicalHistoryEmptyState'
import { CONTENT_HEIGHT } from 'consts/patientCard'
import MedicalRecordItem from './MedicalRecordItem'

import { Grid, makeStyles, CircularProgress } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import _ from 'lodash'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  emptyStateContainer: {
    padding: 30,
    height: CONTENT_HEIGHT
  },
  container: {
    padding: '0px 30px 30px 30px',
    height: CONTENT_HEIGHT
  },
  medicalRecordItem: {
    padding: '10px 30px 0 20px'
  },
  uploadFilesWrapper: {
    padding: '0 30px 0 20px'
  },
  uploadFilesContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: '1px solid #EDEDED'
  },
  uploadFilesLabel: {
    color: 'var(--text-color-25)',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

const MedicalHistoryTab = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const fileInputRef = useRef(null)

  const isLoading = useSelector(state => state.treatmentReducer.isLoading)
  const medicalRecords = useSelector(state => JSON.parse(state.treatmentReducer.treatment.medicalRecordsData || '[]'))
  const medicalRecordsFiles = useSelector(state => state.treatmentReducer.medicalRecordsFiles)

  const handleUploadFileClick = useCallback(() => {
    trackEvent('Medical history - upload file clicked ')
    fileInputRef.current.click()
  }, [])

  const handleFileUpload = useCallback(
    file => {
      trackEvent('Medical history - upload file picked', { fileType: file?.type })
      dispatch(Actions.uploadMedicalRecord(file))
    },
    [dispatch]
  )

  useEffect(() => {
    if (medicalRecords.length && _.isEmpty(medicalRecordsFiles)) {
      dispatch(Actions.fetchMedicalRecords())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isLoading || (_.isEmpty(medicalRecordsFiles) && medicalRecords.length) ? (
    <Grid container justifyContent="center" alignItems="center" className={classes.container}>
      <Grid item>
        <Grid container>
          <Grid item>
            <CircularProgress color="inherit" size={60} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : !medicalRecords.length ? (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.emptyStateContainer}
    >
      <Grid item>
        <MedicalHistoryEmptyState onFileUpload={handleFileUpload} />
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container direction="column" wrap="nowrap" className={classes.container}>
        {medicalRecords.map(record => (
          <Grid item className={classes.medicalRecordItem} key={record.id}>
            <MedicalRecordItem record={record} />
          </Grid>
        ))}
        <Grid item className={classes.uploadFilesWrapper}>
          <Grid container className={classes.uploadFilesContainer}>
            <Grid item>
              <DazzedParagraph16 bold onClick={handleUploadFileClick} className={classes.uploadFilesLabel}>
                {t('dialogs.patientInfo.medicalHistory.uploadAdditionalFileLabel')}
              </DazzedParagraph16>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={e => handleFileUpload(e.target.files[0])}
        accept={'application/pdf, image/jpeg, image/png, image/jpg'}
      />
    </>
  )
}

export default MedicalHistoryTab
