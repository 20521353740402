import * as React from 'react'

export default ({ color = '#FFAA00', ...props }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10" cy="10" r="9" fill="#FFF8EB" stroke={color} strokeWidth="1.2" />
      <rect x="11.2285" y="5.09082" width="2.45455" height="9.81818" fill={color} />
      <rect x="6.31836" y="5.09082" width="2.45455" height="9.81818" fill={color} />
    </svg>
  )
}
