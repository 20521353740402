import * as React from 'react'

const PatientNotFound = props => (
  <svg width="118" height="106" viewBox="0 0 118 106" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M47.3484 103.181C73.4982 103.181 94.6967 81.9828 94.6967 55.8333C94.6967 29.6837 73.4982 8.48537 47.3484 8.48537C21.1987 8.48537 0.00012207 29.6837 0.00012207 55.8333C0.00012207 81.9828 21.1987 103.181 47.3484 103.181Z"
      fill="#C2D1FF"
    />
    <path
      d="M54.2855 68.9087C54.2855 68.9087 50.4282 64.4709 33.1205 70.8007C15.0358 77.4093 13.5231 86.5969 15.3192 90.7239C15.927 94.051 27.3363 103.112 50.1494 103.112C62.9369 103.112 77.6535 93.6018 79.1662 90.3752C80.4276 88.1495 79.6233 79.8636 75.5009 75.5721C71.6572 71.5594 65.5696 72.3272 54.2855 68.9087Z"
      fill="#001335"
    />
    <path
      d="M26.9304 48.8214C29.828 51.9155 36.5372 55.9739 35.7511 70.5439C35.71 71.298 35.5866 71.755 35.7694 72.3857C37.0902 76.9468 46.3359 76.1882 49.1969 75.7951C52.8806 75.2832 55.4856 72.8016 54.992 70.4982C54.4985 68.1948 53.8403 62.9984 53.8403 62.9984"
      fill="#FFB939"
    />
    <path
      d="M54.2019 65.6821C53.9048 63.6849 53.8408 62.9994 53.8408 62.9994L37.9545 54.8917C36.4874 55.3213 35.7882 56.0206 36.4509 57.1266C39.0011 61.3769 43.352 65.6821 54.2019 65.6821Z"
      fill="#FF8E00"
    />
    <path
      d="M69.324 35.7555C68.3825 51.4954 65.6586 66.4082 45.3985 62.3498C39.809 61.2301 36.6555 58.4834 32.4417 54.9368C29.6172 52.5603 26.5734 47.7752 24.7819 44.7817C22.8121 41.4911 22.579 38.4291 22.579 33.5983C22.579 17.8309 31.6647 5.25354 45.3985 5.25354C59.1322 5.25354 70.4346 17.1865 69.324 35.7555Z"
      fill="#FFB939"
    />
    <path
      d="M30.4739 53.9869C29.1623 51.1076 26.0179 49.5537 23.8927 44.2248C21.0546 37.1226 21.2831 31.6018 21.2831 31.6018C19.7383 31.3458 17.476 28.6631 18.5318 24.2345C19.5007 20.1715 23.1523 20.0756 23.1523 20.0756C23.1523 20.0756 20.4284 16.072 25.1998 10.8071C29.9712 5.54214 35.0442 7.13259 35.0442 7.13259C35.0442 7.13259 35.1219 2.08245 42.6218 0.286343C50.5147 -1.60574 58.1974 6.49733 58.1974 6.49733C58.1974 6.49733 67.7036 0.75251 72.4613 9.02468C74.623 12.7814 74.5499 17.4431 71.5518 20.496C68.9787 23.1148 65.3773 23.7181 62.8956 24.5681C57.0228 26.5745 37.5442 27.1001 33.2755 26.026C33.2755 26.026 35.0442 28.9373 35.3048 33.4116C35.4556 35.9892 33.1019 37.0861 30.2089 35.2305C26.6989 32.9774 21.4248 35.9298 25.2272 42.8309C28.1294 48.105 31.3926 46.6928 32.2609 46.5739C33.1293 46.4551 35.547 48.6671 34.5552 52.8398C33.8971 55.5728 32.5443 58.5343 30.4739 53.9869Z"
      fill="#3C52EB"
    />
    <circle cx="85.4801" cy="74" r="19" fill="white" />
    <circle cx="85.4801" cy="74" r="12.5217" stroke="#3C52EB" strokeWidth="4" />
    <path
      d="M94.0681 87.5009C93.0543 86.1009 93.2076 84.1719 94.4298 82.9497C95.6521 81.7275 97.581 81.5742 98.981 82.5879L111.388 91.5721C114.362 93.7262 114.705 98.0303 112.108 100.627C109.51 103.224 105.206 102.882 103.052 99.9075L94.0681 87.5009Z"
      fill="#3C52EB"
    />
  </svg>
)

export default PatientNotFound
