import { makeStyles } from '@material-ui/styles'
import { Auth } from 'aws-amplify'
import lottie from 'lottie-web'
import React, { useEffect } from 'react'
import { isMobile, isSafari } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Actions from '../actions'
import ROUTES from '../consts/routesConsts'
import WelcomeAnimation from '../resources/animations/welcome-page.json'
import WelcomeAnimationMobile from '../resources/animations/welcome-page.mobile.json'

const useWelcomeStyles = makeStyles(theme => ({
  welcomeContainer: {
    height: '100vh',
    transform: 'scale(70%)',
    backgroundColor: theme.palette.primary.main
  },
  welcomeAnimation: {
    height: '100%',
    width: '100%',
    maxWidth: isSafari ? 1000 : 1500,
    margin: 'auto'
  }
}))

const Welcome = ({ history, location }) => {
  const classes = useWelcomeStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    const timer = setTimeout(() => {
      const checkIfSignedIn = async () => {
        try {
          const user = await Auth.currentAuthenticatedUser()
          dispatch(Actions.resignInReceived(user))
        } catch (err) {
          history.push(ROUTES.SIGN_IN)
        }
      }

      checkIfSignedIn()
    }, 3000)

    return () => clearTimeout(timer)
  }, [history, dispatch])

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#welcomeAnimation'),
      animationData: isMobile ? WelcomeAnimationMobile : WelcomeAnimation,
      loop: false
    })
  }, [])

  return (
    <div className={classes.welcomeContainer}>
      <div id="welcomeAnimation" className={classes.welcomeAnimation} />
    </div>
  )
}

export default withRouter(Welcome)
