import React, { useMemo, useCallback, useState } from 'react'
import Actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles, Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import PDFViewer from 'components/common/PDFViewer'
import DeleteMedicalRecordModal from 'components/common/modals/DeleteMedicalRecordModal'
import MedicalHistoryActions from './MedicalHistoryActions'
import { base64ToFile } from 'utils/fileUtils'
import { trackEvent } from 'utils/analyticsUtils'
import ImagesViewer from 'components/common/ImagesViewer'

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: 15,
    paddingTop: 15,
    borderBottom: '1px solid #EDEDED'
  },
  text: {
    color: '#646464 !important'
  },
  loader: {
    padding: '11px 6px 12px 7px'
  }
}))

const MedicalRecordItem = ({ record }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [showFile, setShowFile] = useState(false)

  const medicalRecordsFiles = useSelector(state => state.treatmentReducer.medicalRecordsFiles)

  const fileType = useMemo(() => {
    const split = record?.name.split('.')
    return split?.length ? split[1].toLowerCase() : null
  }, [record])
  const isImage = useMemo(() => fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png', [fileType])

  const file = useMemo(() => {
    const fileData = medicalRecordsFiles[record.id] || null

    if (!fileData) {
      return null
    } else if (!(fileData instanceof File)) {
      return base64ToFile(fileData, record.name, fileType)
    }
    return fileData
  }, [fileType, medicalRecordsFiles, record.id, record.name])

  const deleteMedicalRecord = useCallback(() => {
    trackEvent('Medical history - delete file clicked ', { fileType })
    dispatch(Actions.deleteMedicalRecord({ fileKey: record.id }))
  }, [dispatch, fileType, record.id])

  return (
    <>
      <Grid container alignItems="center" className={classes.container}>
        <Grid item xs={6}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <MedicalHistoryActions
                record={record}
                file={file}
                showFile={showFile}
                setShowFile={setShowFile}
                setIsDeleteModalOpen={setIsDeleteModalOpen}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              {record.wasAddedByGrin && (
                <DazzedParagraph14 color="#646464">
                  {t('dialogs.patientInfo.medicalHistory.addedByGrinLabel')}
                </DazzedParagraph14>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DeleteMedicalRecordModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        onDelete={deleteMedicalRecord}
      />
      {showFile && isImage ? (
        <ImagesViewer
          imageUrls={[URL.createObjectURL(file)]}
          isOpen={showFile}
          closeImageViewer={() => setShowFile(false)}
        />
      ) : (
        <PDFViewer file={file} isOpen={showFile} onClose={setShowFile} />
      )}
    </>
  )
}
export default MedicalRecordItem
