import * as React from 'react'

const Delete = ({ color = 'white', ...props }) => {
  return (
    <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.2944 6.08755C18.2944 5.54932 18.0806 5.03313 17.7 4.65254C17.3194 4.27195 16.8032 4.05814 16.265 4.05814H12.8826C12.8826 3.16108 12.5263 2.30076 11.8919 1.66645C11.2576 1.03214 10.3973 0.675781 9.50025 0.675781C8.6032 0.675781 7.74288 1.03214 7.10857 1.66645C6.47425 2.30076 6.1179 3.16108 6.1179 4.05814H2.73555C2.2574 4.05723 1.79436 4.22558 1.42844 4.53336C1.06252 4.84114 0.817339 5.26849 0.736316 5.73972C0.655292 6.21096 0.743658 6.69566 0.985763 7.10798C1.22787 7.52031 1.60809 7.83364 2.05908 7.99249V19.1191C2.05997 19.9684 2.39771 20.7827 2.99819 21.3833C3.59867 21.9839 4.41287 22.3218 5.26216 22.3229H13.7383C14.5876 22.3218 15.4018 21.9839 16.0023 21.3833C16.6028 20.7827 16.9405 19.9684 16.9414 19.1191V7.99249C17.3357 7.85308 17.6774 7.59525 17.9195 7.25426C18.1617 6.91327 18.2926 6.50578 18.2944 6.08755V6.08755ZM9.50025 2.02872C10.0385 2.02872 10.5547 2.24254 10.9353 2.62312C11.3159 3.00371 11.5297 3.5199 11.5297 4.05814H7.47084C7.47084 3.5199 7.68465 3.00371 8.06524 2.62312C8.44583 2.24254 8.96202 2.02872 9.50025 2.02872ZM15.5885 19.1191C15.5879 19.6097 15.3929 20.08 15.046 20.427C14.6992 20.7739 14.2289 20.9692 13.7383 20.9699H5.26216C4.77158 20.9692 4.3013 20.7739 3.95447 20.427C3.60763 20.08 3.41255 19.6097 3.41202 19.1191V8.11696H15.5885V19.1191ZM16.265 6.76402H2.73555C2.55614 6.76402 2.38407 6.69275 2.25721 6.56589C2.13035 6.43902 2.05908 6.26696 2.05908 6.08755C2.05908 5.90814 2.13035 5.73608 2.25721 5.60921C2.38407 5.48235 2.55614 5.41108 2.73555 5.41108H16.265C16.4444 5.41108 16.6164 5.48235 16.7433 5.60921C16.8702 5.73608 16.9414 5.90814 16.9414 6.08755C16.9414 6.26696 16.8702 6.43902 16.7433 6.56589C16.6164 6.69275 16.4444 6.76402 16.265 6.76402Z"
        fill={color}
      />
      <path
        d="M9.50069 18.9406C9.6801 18.9406 9.85216 18.8693 9.97903 18.7425C10.1059 18.6156 10.1772 18.4435 10.1772 18.2641V10.823C10.1772 10.6435 10.1059 10.4715 9.97903 10.3446C9.85216 10.2178 9.6801 10.1465 9.50069 10.1465C9.32128 10.1465 9.14922 10.2178 9.02235 10.3446C8.89549 10.4715 8.82422 10.6435 8.82422 10.823V18.2641C8.82422 18.4435 8.89549 18.6156 9.02235 18.7425C9.14922 18.8693 9.32128 18.9406 9.50069 18.9406Z"
        fill={color}
      />
      <path
        d="M12.2058 18.2634C12.3852 18.2634 12.5572 18.1922 12.6841 18.0653C12.811 17.9384 12.8822 17.7664 12.8822 17.587V11.4987C12.8822 11.3193 12.811 11.1473 12.6841 11.0204C12.5572 10.8935 12.3852 10.8223 12.2058 10.8223C12.0264 10.8223 11.8543 10.8935 11.7274 11.0204C11.6006 11.1473 11.5293 11.3193 11.5293 11.4987V17.587C11.5293 17.7664 11.6006 17.9384 11.7274 18.0653C11.8543 18.1922 12.0264 18.2634 12.2058 18.2634Z"
        fill={color}
      />
      <path
        d="M6.79464 18.2634C6.97405 18.2634 7.14611 18.1922 7.27297 18.0653C7.39984 17.9384 7.47111 17.7664 7.47111 17.587V11.4987C7.47111 11.3193 7.39984 11.1473 7.27297 11.0204C7.14611 10.8935 6.97405 10.8223 6.79464 10.8223C6.61522 10.8223 6.44316 10.8935 6.3163 11.0204C6.18944 11.1473 6.11816 11.3193 6.11816 11.4987V17.587C6.11816 17.7664 6.18944 17.9384 6.3163 18.0653C6.44316 18.1922 6.61522 18.2634 6.79464 18.2634Z"
        fill={color}
      />
    </svg>
  )
}

export default Delete
