import * as React from 'react'

const SetNextScan = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="path-1-inside-1_8863_69525" fill="white">
        <path d="M4 7C4 5.89543 4.89543 5 6 5H18C19.1046 5 20 5.89543 20 7V17C20 18.1046 19.1046 19 18 19H6C4.89543 19 4 18.1046 4 17V7Z" />
      </mask>
      <path
        d="M6 6H18V4H6V6ZM19 7V17H21V7H19ZM18 18H6V20H18V18ZM5 17V7H3V17H5ZM6 18C5.44772 18 5 17.5523 5 17H3C3 18.6569 4.34315 20 6 20V18ZM19 17C19 17.5523 18.5523 18 18 18V20C19.6569 20 21 18.6569 21 17H19ZM18 6C18.5523 6 19 6.44772 19 7H21C21 5.34315 19.6569 4 18 4V6ZM6 4C4.34315 4 3 5.34315 3 7H5C5 6.44772 5.44772 6 6 6V4Z"
        fill="#282828"
        mask="url(#path-1-inside-1_8863_69525)"
      />
      <line x1="7.5" y1="4.5" x2="7.5" y2="6.5" stroke="#282828" strokeLinecap="round" />
      <path d="M20 8.5L4 8.5" stroke="#282828" />
      <line x1="16.5" y1="4.5" x2="16.5" y2="6.5" stroke="#282828" strokeLinecap="round" />
      <path d="M7 10.5H8" stroke="#282828" />
      <line x1="7" y1="12.5" x2="8" y2="12.5" stroke="#282828" />
      <line x1="7" y1="14.5" x2="8" y2="14.5" stroke="#282828" />
      <line x1="7" y1="16.5" x2="8" y2="16.5" stroke="#282828" />
      <line x1="10" y1="10.5" x2="11" y2="10.5" stroke="#282828" />
      <line x1="10" y1="12.5" x2="11" y2="12.5" stroke="#282828" />
      <line x1="10" y1="14.5" x2="11" y2="14.5" stroke="#282828" />
      <line x1="10" y1="16.5" x2="11" y2="16.5" stroke="#282828" />
      <line x1="13" y1="10.5" x2="14" y2="10.5" stroke="#282828" />
      <line x1="13" y1="12.5" x2="14" y2="12.5" stroke="#282828" />
      <line x1="13" y1="14.5" x2="14" y2="14.5" stroke="#282828" />
      <line x1="13" y1="16.5" x2="14" y2="16.5" stroke="#282828" />
      <line x1="16" y1="10.5" x2="17" y2="10.5" stroke="#282828" />
      <line x1="16" y1="12.5" x2="17" y2="12.5" stroke="#282828" />
      <line x1="16" y1="14.5" x2="17" y2="14.5" stroke="#282828" />
      <line x1="16" y1="16.5" x2="17" y2="16.5" stroke="#282828" />
    </svg>
  )
}

export default SetNextScan
