import * as React from 'react'

const Replace = (...props) => (
  <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.21562 9C4.95937 9 3.89062 8.56406 3.00937 7.69219C2.12812 6.82031 1.6875 5.75625 1.6875 4.5V4.40156L1.18125 4.90781C0.963788 5.12528 0.611212 5.12527 0.39375 4.90781C0.176288 4.69035 0.176288 4.33777 0.39375 4.12031L1.76386 2.7502C2.03235 2.48171 2.46765 2.48171 2.73614 2.7502L4.10625 4.12031C4.32371 4.33778 4.32371 4.69035 4.10625 4.90781C3.88879 5.12527 3.53621 5.12527 3.31875 4.90781L2.8125 4.40156V4.5C2.8125 5.4375 3.14297 6.23438 3.80391 6.89063C4.46484 7.54688 5.26875 7.875 6.21562 7.875C6.45937 7.875 6.69844 7.84688 6.93281 7.79063C7.0329 7.7666 7.13214 7.73745 7.23052 7.70317C7.47034 7.61961 7.743 7.65863 7.92258 7.83821C8.1967 8.11233 8.13583 8.57425 7.77367 8.71253C7.63994 8.76359 7.50496 8.80785 7.36875 8.84531C6.99375 8.94844 6.60938 9 6.21562 9ZM10.6111 6.2498C10.3426 6.51829 9.90735 6.51829 9.63886 6.2498L8.26875 4.87969C8.05129 4.66223 8.05129 4.30965 8.26875 4.09219C8.48621 3.87473 8.83879 3.87473 9.05625 4.09219L9.5625 4.59844V4.5C9.5625 3.5625 9.23203 2.76563 8.57109 2.10938C7.91016 1.45313 7.10625 1.125 6.15937 1.125C5.91562 1.125 5.67656 1.15313 5.44219 1.20938C5.3421 1.2334 5.24286 1.26255 5.14448 1.29683C4.90466 1.38039 4.632 1.34137 4.45242 1.16179C4.1783 0.887674 4.23917 0.425752 4.60133 0.28747C4.73506 0.236407 4.87004 0.192146 5.00625 0.154688C5.38125 0.0515625 5.76562 0 6.15937 0C7.41562 0 8.48437 0.435938 9.36562 1.30781C10.2469 2.17969 10.6875 3.24375 10.6875 4.5V4.59844L11.1938 4.09219C11.4112 3.87473 11.7638 3.87473 11.9812 4.09219C12.1987 4.30965 12.1987 4.66223 11.9812 4.87969L10.6111 6.2498Z"
      fill="#4151E6"
    />
  </svg>
)

export default Replace
