// Action Types
const SIGNUP_REQUESTED = 'SIGNUP_REQUESTED'
const SIGNUP_RECEIVED = 'SIGNUP_RECEIVED'
const SIGNIN_REQUESTED = 'SIGNIN_REQUESTED'
const SIGNIN_RECEIVED = 'SIGNIN_RECEIVED'
const RESIGNIN_RECEIVED = 'RESIGNIN_RECEIVED'
const SIGNOUT_REQUESTED = 'SIGNOUT_REQUESTED'
const SIGNOUT_RECEIVED = 'SIGNOUT_RECEIVED'
const CONFIRM_SIGNUP_REQUESTED = 'CONFIRM_SIGNUP_REQUESTED'
const CONFIRM_SIGNUP_RECEIVED = 'CONFIRM_SIGNUP_RECEIVED'
const SIGNIN_SIDE_EFFECTS_COMPLETED = 'SIGNIN_SIDE_EFFECTS_COMPLETED'
const AUTH_FORM_CHANGED = 'AUTH_FORM_CHANGED'
const BILLING_INFO_FORM_CHANGED = 'BILLING_INFO_FORM_CHANGED'
const ADD_AUTH_SCHEDULE = 'ADD_AUTH_SCHEDULE'
const DELETE_AUTH_SCHEDULE = 'DELETE_AUTH_SCHEDULE'
const CONFIRM_RESEND_SIGNUP_REQUESTED = 'CONFIRM_RESEND_SIGNUP_REQUESTED'
const CONFIRM_RESEND_SIGNUP_RECEIVED = 'CONFIRM_RESEND_SIGNUP_RECEIVED'
const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED'
const RESET_PASSWORD_RECEIVED = 'RESET_PASSWORD_RECEIVED'
const RESET_PASSWORD_SUBMIT_REQUESTED = 'RESET_PASSWORD_SUBMIT_REQUESTED'
const RESET_PASSWORD_SUBMIT_RECEIVED = 'RESET_PASSWORD_SUBMIT_RECEIVED'
const ACCOUNT_INFO_COMPLETED = 'ACCOUNT_INFO_COMPLETED'
const SIGNUP_ERRORS_UPDATED = 'SIGNUP_ERRORS_UPDATED'
const RESET_PASSWORD_ERRORS_UPDATED = 'RESET_PASSWORD_ERRORS_UPDATED'
const SET_POST_AUTH_LOCATION = 'SET_POST_AUTH_LOCATION'
const VALIDATE_ACCOUNT_INFO = 'VALIDATE_ACCOUNT_INFO'
const RECREATE_UNCONFIRMED_USER = 'RECREATE_UNCONFIRMED_USER'
const RESUME_ONBOARDING = 'RESUME_ONBOARDING'
const RESUME_DOCTOR_ONBOARDING = 'RESUME_DOCTOR_ONBOARDING'
const SAVE_PRACTICE_INFO_DRAFT = 'SAVE_PRACTICE_INFO_DRAFT'
const LOAD_PRACTICE_INFO_DRAFT = 'LOAD_PRACTICE_INFO_DRAFT'
const NOT_ALLOWED_DOCTOR_RECEIVED = 'NOT_ALLOWED_DOCTOR_RECEIVED'
const REGISTER_RC_USER_REQUESTED = 'REGISTER_RC_USER_REQUESTED'
const REGISTER_RC_USER_RECEIVED = 'REGISTER_RC_USER_RECEIVED'
const REGISTER_RC_USER_FAILED = 'REGISTER_RC_USER_FAILED'
const REQUEST_DOCTOR_PUBLIC_DETAILS = 'REQUEST_DOCTOR_PUBLIC_DETAILS'
const DOCTOR_PUBLIC_DETAILS_RECEIVED = 'DOCTOR_PUBLIC_DETAILS_RECEIVED'
const DOCTOR_PUBLIC_DETAILS_FAILED = 'DOCTOR_PUBLIC_DETAILS_FAILED'
const RESEND_CONFIRMATION_CODE = 'RESEND_CONFIRMATION_CODE'
const RESEND_CONFIRMATION_CODE_RECEIVED = 'RESEND_CONFIRMATION_CODE_RECEIVED'
const RESEND_CONFIRMATION_CODE_FAILED = 'RESEND_CONFIRMATION_CODE_FAILED'
const CHANGE_PRACTICE_MEMBERS_AUTH_FORM = 'CHANGE_PRACTICE_MEMBERS_AUTH_FORM'
const PRACTICE_MEMBERS_SIGNUP = 'PRACTICE_MEMBERS_SIGNUP'
const PRACTICE_MEMBERS_SIGNUP_RECEIVED = 'PRACTICE_MEMBERS_SIGNUP_RECEIVED'
const PRACTICE_MEMBERS_SIGNUP_FAILED = 'PRACTICE_MEMBERS_SIGNUP_FAILED'
const PRACTICE_MEMBERS_POST_SIGNUP_COMPLETED = 'PRACTICE_MEMBERS_POST_SIGNUP_COMPLETED'
const UPDATE_PRACTICE_MEMBERS_SIGNUP_ERRORS = 'UPDATE_PRACTICE_MEMBERS_SIGNUP_ERRORS'
const RESET_PRACTICE_MEMBERS_SIGNUP_ERRORS = 'RESET_PRACTICE_MEMBERS_SIGNUP_ERRORS'
const CREATE_PRACTICE_MEMBER = 'CREATE_PRACTICE_MEMBER'
const CREATE_PRACTICE_MEMBER_RECEIVED = 'CREATE_PRACTICE_MEMBER_RECEIVED'
const CREATE_PRACTICE_MEMBER_FAILED = 'CREATE_PRACTICE_MEMBER_FAILED'
const REGISTER_MIXPANEL_USER = 'REGISTER_MIXPANEL_USER'
const USER_AUTH_COMPLETED = 'USER_AUTH_COMPLETED'
const UPDATE_USER_LOGIN_INFO_RECEIVED = 'UPDATE_USER_LOGIN_INFO_RECEIVED'
const UPDATE_USER_LOGIN_INFO_FAILED = 'UPDATE_USER_LOGIN_INFO_FAILED'
const SET_USER_FOR_MFA = 'SET_USER_FOR_MFA'
const SUBMIT_MFA_CODE = 'SUBMIT_MFA_CODE'

// Action Creators
const submitMFACode = payload => ({
  type: SUBMIT_MFA_CODE,
  payload
})

const setUserForMFA = payload => ({
  type: SET_USER_FOR_MFA,
  payload
})

const requestSignUp = () => ({
  type: SIGNUP_REQUESTED
})

const signUpReceived = payload => ({
  type: SIGNUP_RECEIVED,
  payload
})

const requestResetPassword = payload => ({
  type: RESET_PASSWORD_REQUESTED,
  payload
})

const resetPasswordReceived = payload => ({
  type: RESET_PASSWORD_RECEIVED,
  payload
})

const requestResetPasswordSubmit = payload => ({
  type: RESET_PASSWORD_SUBMIT_REQUESTED,
  payload
})

const resetPasswordSubmitReceived = payload => ({
  type: RESET_PASSWORD_SUBMIT_RECEIVED,
  payload
})

const requestConfirmSignUp = () => ({
  type: CONFIRM_SIGNUP_REQUESTED
})

const signUpConfirmReceived = payload => ({
  type: CONFIRM_SIGNUP_RECEIVED,
  payload
})

const requestResendConfirmSignUp = () => ({
  type: CONFIRM_RESEND_SIGNUP_REQUESTED
})

const resendSignUpConfirmReceived = payload => ({
  type: CONFIRM_RESEND_SIGNUP_RECEIVED,
  payload
})

const requestSignIn = payload => ({
  type: SIGNIN_REQUESTED,
  payload
})

const signInReceived = payload => ({
  type: SIGNIN_RECEIVED,
  payload
})

const resignInReceived = payload => ({
  type: RESIGNIN_RECEIVED,
  payload
})

const signInSideEffectsCompleted = payload => ({
  type: SIGNIN_SIDE_EFFECTS_COMPLETED,
  payload
})

const requestSignOut = () => ({
  type: SIGNOUT_REQUESTED
})

const signOutReceived = () => ({
  type: SIGNOUT_RECEIVED
})

const changeAuthForm = payload => ({
  type: AUTH_FORM_CHANGED,
  payload
})

const changeBillingForm = payload => ({
  type: BILLING_INFO_FORM_CHANGED,
  payload
})

const addAuthSchedule = () => ({
  type: ADD_AUTH_SCHEDULE
})

const deleteAuthSchedule = id => ({
  type: DELETE_AUTH_SCHEDULE,
  payload: {
    id
  }
})

const completeAccountInfo = () => ({
  type: ACCOUNT_INFO_COMPLETED
})

const updateSignUpErrors = payload => ({
  type: SIGNUP_ERRORS_UPDATED,
  payload
})

const updateResetPasswordErrors = payload => ({
  type: RESET_PASSWORD_ERRORS_UPDATED,
  payload
})

const setPostAuthLocation = payload => ({
  type: SET_POST_AUTH_LOCATION,
  payload
})

const validateAccountInfo = payload => ({
  type: VALIDATE_ACCOUNT_INFO,
  payload
})

const recreateUnconfirmedUser = payload => ({
  type: RECREATE_UNCONFIRMED_USER,
  payload
})

const resumeOnboarding = payload => ({
  type: RESUME_ONBOARDING,
  payload
})

const resumeDoctorOnboarding = payload => ({
  type: RESUME_DOCTOR_ONBOARDING,
  payload
})

const savePracticeInfoDraft = () => ({
  type: SAVE_PRACTICE_INFO_DRAFT
})

const loadPracticeInfoDraft = () => ({
  type: LOAD_PRACTICE_INFO_DRAFT
})

const notAllowedDoctorRecevied = () => ({
  type: NOT_ALLOWED_DOCTOR_RECEIVED
})

const registerRCUserRequested = payload => ({
  type: REGISTER_RC_USER_REQUESTED,
  payload
})

const registerRCUserReceived = payload => ({
  type: REGISTER_RC_USER_RECEIVED,
  payload
})

const registerRCUserFailed = payload => ({
  type: REGISTER_RC_USER_FAILED,
  payload
})

const requestDoctorPublicDetails = payload => ({
  type: REQUEST_DOCTOR_PUBLIC_DETAILS,
  payload
})

const doctorPublicDetailsReceived = payload => ({
  type: DOCTOR_PUBLIC_DETAILS_RECEIVED,
  payload
})

const doctorPublicDetailsFailed = payload => ({
  type: DOCTOR_PUBLIC_DETAILS_FAILED,
  payload
})

const resendConfirmationCode = () => ({
  type: RESEND_CONFIRMATION_CODE
})

const resendConfirmationCodeReceived = payload => ({
  type: RESEND_CONFIRMATION_CODE_RECEIVED,
  payload
})

const resendConfirmationCodeFailed = payload => ({
  type: RESEND_CONFIRMATION_CODE_FAILED,
  payload
})

const changePracticeMembersAuthForm = payload => ({
  type: CHANGE_PRACTICE_MEMBERS_AUTH_FORM,
  payload
})

const practiceMembersSignUp = payload => ({
  type: PRACTICE_MEMBERS_SIGNUP,
  payload
})

const practiceMembersSignUpReceived = payload => ({
  type: PRACTICE_MEMBERS_SIGNUP_RECEIVED,
  payload
})

const practiceMembersSignUpFailed = payload => ({
  type: PRACTICE_MEMBERS_SIGNUP_FAILED,
  payload
})

const practiceMembersPostSignUpCompleted = payload => ({
  type: PRACTICE_MEMBERS_POST_SIGNUP_COMPLETED,
  payload
})

const updatePracticeMembersSignUpErrors = payload => ({
  type: UPDATE_PRACTICE_MEMBERS_SIGNUP_ERRORS,
  payload
})

const resetPracticeMembersSignUpErrors = payload => ({
  type: RESET_PRACTICE_MEMBERS_SIGNUP_ERRORS,
  payload
})

const createPracticeMember = payload => ({
  type: CREATE_PRACTICE_MEMBER,
  payload
})

const createPracticeMemberReceived = payload => ({
  type: CREATE_PRACTICE_MEMBER_RECEIVED,
  payload
})

const createPracticeMemberFailed = payload => ({
  type: CREATE_PRACTICE_MEMBER_FAILED,
  payload
})

const registerMixpanelUser = payload => ({
  type: REGISTER_MIXPANEL_USER,
  payload
})

const userAuthCompleted = payload => ({
  type: USER_AUTH_COMPLETED,
  payload
})

const updateUserLoginInfoReceived = payload => ({
  type: UPDATE_USER_LOGIN_INFO_RECEIVED,
  payload
})

const updateUserLoginInfoFailed = payload => ({
  type: UPDATE_USER_LOGIN_INFO_FAILED,
  payload
})

export default {
  SIGNUP_REQUESTED,
  SIGNUP_RECEIVED,
  SIGNIN_REQUESTED,
  SIGNIN_RECEIVED,
  RESIGNIN_RECEIVED,
  SIGNOUT_REQUESTED,
  SIGNOUT_RECEIVED,
  AUTH_FORM_CHANGED,
  CONFIRM_SIGNUP_REQUESTED,
  CONFIRM_SIGNUP_RECEIVED,
  BILLING_INFO_FORM_CHANGED,
  ADD_AUTH_SCHEDULE,
  DELETE_AUTH_SCHEDULE,
  CONFIRM_RESEND_SIGNUP_REQUESTED,
  CONFIRM_RESEND_SIGNUP_RECEIVED,
  RESET_PASSWORD_REQUESTED,
  RESET_PASSWORD_RECEIVED,
  RESET_PASSWORD_SUBMIT_REQUESTED,
  RESET_PASSWORD_SUBMIT_RECEIVED,
  ACCOUNT_INFO_COMPLETED,
  SIGNUP_ERRORS_UPDATED,
  RESET_PASSWORD_ERRORS_UPDATED,
  SIGNIN_SIDE_EFFECTS_COMPLETED,
  SET_POST_AUTH_LOCATION,
  VALIDATE_ACCOUNT_INFO,
  RECREATE_UNCONFIRMED_USER,
  RESUME_ONBOARDING,
  RESUME_DOCTOR_ONBOARDING,
  SAVE_PRACTICE_INFO_DRAFT,
  LOAD_PRACTICE_INFO_DRAFT,
  NOT_ALLOWED_DOCTOR_RECEIVED,
  REGISTER_RC_USER_REQUESTED,
  REGISTER_RC_USER_RECEIVED,
  REGISTER_RC_USER_FAILED,
  REQUEST_DOCTOR_PUBLIC_DETAILS,
  DOCTOR_PUBLIC_DETAILS_FAILED,
  DOCTOR_PUBLIC_DETAILS_RECEIVED,
  RESEND_CONFIRMATION_CODE,
  RESEND_CONFIRMATION_CODE_RECEIVED,
  RESEND_CONFIRMATION_CODE_FAILED,
  CHANGE_PRACTICE_MEMBERS_AUTH_FORM,
  PRACTICE_MEMBERS_SIGNUP,
  PRACTICE_MEMBERS_SIGNUP_RECEIVED,
  PRACTICE_MEMBERS_SIGNUP_FAILED,
  PRACTICE_MEMBERS_POST_SIGNUP_COMPLETED,
  UPDATE_PRACTICE_MEMBERS_SIGNUP_ERRORS,
  RESET_PRACTICE_MEMBERS_SIGNUP_ERRORS,
  CREATE_PRACTICE_MEMBER,
  CREATE_PRACTICE_MEMBER_RECEIVED,
  CREATE_PRACTICE_MEMBER_FAILED,
  REGISTER_MIXPANEL_USER,
  USER_AUTH_COMPLETED,
  UPDATE_USER_LOGIN_INFO_RECEIVED,
  UPDATE_USER_LOGIN_INFO_FAILED,
  SET_USER_FOR_MFA,
  SUBMIT_MFA_CODE,
  submitMFACode,
  setUserForMFA,
  registerMixpanelUser,
  requestSignUp,
  signUpReceived,
  requestSignIn,
  signInReceived,
  resignInReceived,
  changeAuthForm,
  requestConfirmSignUp,
  signUpConfirmReceived,
  changeBillingForm,
  requestSignOut,
  signOutReceived,
  addAuthSchedule,
  deleteAuthSchedule,
  requestResendConfirmSignUp,
  resendSignUpConfirmReceived,
  requestResetPassword,
  requestResetPasswordSubmit,
  resetPasswordReceived,
  resetPasswordSubmitReceived,
  completeAccountInfo,
  updateSignUpErrors,
  updateResetPasswordErrors,
  signInSideEffectsCompleted,
  setPostAuthLocation,
  validateAccountInfo,
  recreateUnconfirmedUser,
  resumeOnboarding,
  resumeDoctorOnboarding,
  savePracticeInfoDraft,
  loadPracticeInfoDraft,
  notAllowedDoctorRecevied,
  registerRCUserRequested,
  registerRCUserReceived,
  registerRCUserFailed,
  requestDoctorPublicDetails,
  doctorPublicDetailsFailed,
  doctorPublicDetailsReceived,
  resendConfirmationCode,
  resendConfirmationCodeReceived,
  resendConfirmationCodeFailed,
  changePracticeMembersAuthForm,
  practiceMembersSignUp,
  practiceMembersSignUpReceived,
  practiceMembersSignUpFailed,
  practiceMembersPostSignUpCompleted,
  updatePracticeMembersSignUpErrors,
  resetPracticeMembersSignUpErrors,
  createPracticeMember,
  createPracticeMemberReceived,
  createPracticeMemberFailed,
  userAuthCompleted,
  updateUserLoginInfoReceived,
  updateUserLoginInfoFailed
}
