import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Actions from '../../actions'
import { CircularProgress } from '@material-ui/core'
import BaseModal from '../common/modals/BaseModal'
import SelectPlanCard from '../common/SelectPlanCard'
import OrderGrinKitsOnPlanSelectionModal from './Order/SelectPlanScopesModal'
import DazzedParagraph16 from '../common/text/DazzedParagraph16'
import { useTranslation } from 'react-i18next'
import useClinic from '../../hooks/useClinic'
import {
  GRIN_TRIAL_PLAN,
  GRIN_ENTERPRISE_MONTHLY_PLAN,
  GRIN_ENTERPRISE_PLAN_GROUP,
  BILLING_PERIOD_MONTHLY,
  BILLING_PERIOD_ANNUALLY,
  PLAN_GROUP_COLORS,
  INTERNAL_PLAN
} from '../../consts/grinPlanConsts'

const useSelectPlanStyles = makeStyles({
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  periodAndPlansContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: 850,
    marginBottom: 40
  },
  periodContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 20,
    marginBottom: 15
  },
  periodText: {
    fontSize: 15,
    fontWeight: 500,
    color: 'var(--text-color-13)',
    cursor: 'pointer'
  },
  periodTextAnnually: {
    fontSize: 15,
    fontWeight: 500,
    color: 'var(--text-color-13)',
    cursor: 'pointer'
  },
  periodTextSelected: {
    color: 'var(--text-color-3)'
  },
  plansContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  description: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 5
  },
  contactSupportContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center'
  }
})

const BillingSelectPlanModal = () => {
  const classes = useSelectPlanStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { canClinicBuyScopes } = useClinic()

  const [isOrderGrinKitsModalOpen, setIsOrderGrinKitsModalOpen] = useState(false)

  const isOpen = useSelector(state => state.billingReducer.upgradePlan.isSelectPlanModalOpen)
  const grinPlan = useSelector(state => state.practiceReducer.billing.grinPlan)
  const grinPlans = useSelector(state => state.billingReducer.grinPlans)
  const selectedPlanPeriod = useSelector(state => state.billingReducer.upgradePlan.selectedPlanPeriod)
  const scopePrice = useSelector(state => state.productReducer.scope.data.price)
  const areGrinPlansLoading = useSelector(state => state.billingReducer.isLoading)

  const planList = useMemo(
    () =>
      Object.values(grinPlans)
        .filter(
          plan =>
            plan.period === selectedPlanPeriod &&
            plan.planGroup !== GRIN_ENTERPRISE_PLAN_GROUP &&
            plan.planGroup !== GRIN_TRIAL_PLAN
        )
        .sort((a, b) => a.maxPatients - b.maxPatients),
    [grinPlans, selectedPlanPeriod]
  )

  const handleClose = useCallback(() => {
    dispatch(Actions.setSelectedPlanGroupAndPeriod({ planGroup: null, planPeriod: BILLING_PERIOD_MONTHLY }))
    dispatch(Actions.setSelectPlanModalVisibility(false))
  }, [dispatch])

  const handleSelectPlan = useCallback(
    (planGroup, billingPeriod) => {
      dispatch(Actions.setSelectedPlanGroupAndPeriod({ planGroup, planPeriod: billingPeriod }))

      if (!canClinicBuyScopes()) {
        dispatch(Actions.setSelectPlanModalVisibility(false))
        dispatch(Actions.setUpgradePlanDialogVisibility(true))
        dispatch(
          Actions.setSelectedPlanScopesQuantityAndAddress({
            planScopesQuantity: 0,
            planScopesMiniQuantity: 0
          })
        )
      } else {
        setIsOrderGrinKitsModalOpen(true)
      }
    },
    [dispatch, canClinicBuyScopes]
  )

  const plans = useMemo(() => {
    const currency = '$'
    const plans = planList
      .filter(plan => !plan.key.includes('subscription') && plan.key !== INTERNAL_PLAN)
      .map(plan => ({
        ...plan,
        currency,
        price: grinPlans[plan.key]?.price,
        period: plan.period,
        scopePrice,
        actionLabel: t('general.select'),
        color: PLAN_GROUP_COLORS[plan.planGroup].color,
        isActive: plan.key === grinPlan?.key,
        onAction: () => handleSelectPlan(plan.planGroup, selectedPlanPeriod)
      }))

    const enterprisePlan = {
      key: GRIN_ENTERPRISE_MONTHLY_PLAN,
      planGroup: 'grin-enterprise',
      color: PLAN_GROUP_COLORS[GRIN_ENTERPRISE_PLAN_GROUP].color,
      description: (
        <>
          <DazzedParagraph16 className={classes.description} strong>
            {t('dialogs.upgradePlan.enterpriseDescription1')}
          </DazzedParagraph16>
          <DazzedParagraph16 className={classes.description} strong>
            {t('dialogs.upgradePlan.enterpriseDescription2')}
          </DazzedParagraph16>
          <DazzedParagraph16 className={classes.description} strong>
            {t('dialogs.upgradePlan.enterpriseDescription3')}
          </DazzedParagraph16>
        </>
      ),
      actionLabel: t('dialogs.upgradePlan.contactUs'),
      onAction: () => dispatch(Actions.openHelpPopup())
    }

    return [...plans, enterprisePlan]
  }, [
    planList,
    classes.description,
    grinPlans,
    scopePrice,
    grinPlan,
    handleSelectPlan,
    selectedPlanPeriod,
    dispatch,
    t
  ])

  return (
    <div>
      <BaseModal
        open={isOpen}
        title={t('dialogs.upgradePlan.selectPlanTitle')}
        variant={'fullscreen'}
        handleClose={handleClose}
        handleBackdropClick={handleClose}
      >
        {areGrinPlansLoading ? (
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
        ) : (
          <div className={classes.content}>
            <div className={classes.periodAndPlansContainer}>
              <div className={classes.periodContainer}>
                <DazzedParagraph16
                  className={[
                    classes.periodText,
                    selectedPlanPeriod === BILLING_PERIOD_MONTHLY && classes.periodTextSelected
                  ].join(' ')}
                  onClick={() =>
                    dispatch(
                      Actions.setSelectedPlanGroupAndPeriod({ planGroup: null, planPeriod: BILLING_PERIOD_MONTHLY })
                    )
                  }
                >
                  {t('grinPlans.period.monthly')} &nbsp;
                </DazzedParagraph16>
                <DazzedParagraph16
                  className={[
                    classes.periodText,
                    selectedPlanPeriod === BILLING_PERIOD_ANNUALLY && classes.periodTextSelected
                  ].join(' ')}
                  onClick={() => {
                    dispatch(
                      Actions.setSelectedPlanGroupAndPeriod({ planGroup: null, planPeriod: BILLING_PERIOD_ANNUALLY })
                    )
                  }}
                >
                  | {t('grinPlans.period.annually')}
                </DazzedParagraph16>
              </div>

              <div className={classes.plansContainer}>
                {plans.map(plan => (
                  <SelectPlanCard
                    key={plan.key}
                    caption={t(`grinPlans.displayName.${plan.planGroup}`)}
                    price={plan.price}
                    period={plan.period}
                    currency={plan.currency}
                    activeUsersCount={plan.maxPatients}
                    scopePrice={plan.scopePrice}
                    description={plan.description}
                    actionLabel={plan.actionLabel}
                    color={plan.color}
                    isActive={plan.isActive}
                    onAction={!plan.isActive && plan.onAction}
                  />
                ))}
              </div>
            </div>

            <div className={classes.contactSupportContainer}>
              <DazzedParagraph16 strong>{t('dialogs.upgradePlan.forMoreInfo')}</DazzedParagraph16>
            </div>
          </div>
        )}
      </BaseModal>
      <OrderGrinKitsOnPlanSelectionModal
        isOpen={isOrderGrinKitsModalOpen}
        handleClose={() => setIsOrderGrinKitsModalOpen(false)}
      />
    </div>
  )
}

export default BillingSelectPlanModal
