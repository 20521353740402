import * as React from 'react'

const Triangle = ({ color = 'white', ...props }) => {
  return (
    <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3.5 0L6.53109 5.25L0.468911 5.25L3.5 0Z" fill={color} />
    </svg>
  )
}

export default Triangle
