import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { Gift } from '../../common/icons'
import DazzedParagraph12 from '../../common/text/DazzedParagraph12'

const useStyles = makeStyles({
  container: {
    backgroundColor: 'var(--bg-color-16)',
    padding: '14px 20px',
    marginBottom: 20
  },
  text: {
    fontSize: '14px',
    lineHeight: '20px',
    color: 'var(--text-color-6)'
  },
  gift: {
    marginRight: 20
  }
})

const BillingSubscriptionNote = ({ text }) => {
  const classes = useStyles()

  const content = useMemo(
    () =>
      text.split('\n').map((line, index) => {
        return (
          <DazzedParagraph12 key={index} className={classes.text}>
            {line || <br />}
          </DazzedParagraph12>
        )
      }),
    [classes.text, text]
  )

  return (
    <Grid container className={classes.container} alignItems="center">
      <Grid item className={classes.gift}>
        <Gift />
      </Grid>
      <Grid item sm>
        {content}
      </Grid>
    </Grid>
  )
}

export default BillingSubscriptionNote
