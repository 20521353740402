import React, { useCallback } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { AutomationsShareFeedback } from 'components/common/icons'
import { trackEvent } from 'utils/analyticsUtils'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  shareFeedbackContainer: {
    cursor: 'pointer'
  },
  shareFeedback: {
    textDecoration: 'underline',
    textDecorationColor: 'var(--text-color-25)',
    marginLeft: 5
  }
}))

const ShareFeedback = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleShareFeedback = useCallback(() => {
    trackEvent('Automation rules - Share feedback clicked')
    dispatch(
      Actions.toggleAppFeedbackDialog({
        value: true,
        title: t('pages.accountSettings.automations.shareFeedbackModal.title'),
        description: t('pages.accountSettings.automations.shareFeedbackModal.description'),
        inputPlaceholder: t('pages.accountSettings.automations.shareFeedbackModal.inputPlaceholder'),
        displayNote: false
      })
    )
  }, [dispatch, t])

  return (
    <Grid container>
      <Grid item onClick={handleShareFeedback} className={classes.shareFeedbackContainer}>
        <Grid container alignItems="center">
          <Grid item>
            <AutomationsShareFeedback />
          </Grid>
          <Grid item className={classes.shareFeedback}>
            <DazzedParagraph14 color={'var(--text-color-25)'}>
              {t('pages.accountSettings.automations.shareFeedback')}
            </DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ShareFeedback
