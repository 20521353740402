import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import BaseModal from 'components/common/modals/BaseModal'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'

const useStyles = makeStyles(theme => ({
  root: {
    width: 445,
    minHeight: 226
  },
  messageContainer: {
    marginTop: 60
  }
}))

const UploadConfirmationModal = ({ isOpen, onClose, onUpload }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handlePrimaryBtnClick = useCallback(() => {
    onUpload()
    onClose()
  }, [onUpload, onClose])

  return (
    <BaseModal
      open={isOpen}
      handleClose={onClose}
      onPrimaryBtnClick={handlePrimaryBtnClick}
      onSecondaryBtnClick={onClose}
      primaryLabel={t('pages.patients.selectedPatient.uploadStlsModal.confirmation.primaryButtonLabel')}
      secondaryLabel={t('pages.patients.selectedPatient.uploadStlsModal.confirmation.secondaryButtonLabel')}
      className={classes.root}
      variant="alert"
    >
      <div className={classes.messageContainer}>
        <DazzedParagraph16 strong textAlign="center">
          {t('pages.patients.selectedPatient.uploadStlsModal.confirmation.message1')}
        </DazzedParagraph16>
        <DazzedParagraph16 strong textAlign="center">
          {t('pages.patients.selectedPatient.uploadStlsModal.confirmation.message2')}
        </DazzedParagraph16>
      </div>
    </BaseModal>
  )
}

export default UploadConfirmationModal
