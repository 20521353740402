import { useMemo, useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { WaitingForScope, NewLead, ReadyToScan, ScanSent } from 'components/common/icons'

export default ({ leads, withAdminActions }) => {
  const { t } = useTranslation()

  const [quickFilters, setQuickFilters] = useState({
    internalUsers: {
      isEnabled: withAdminActions,
      label: t('pages.rcDashboard.table.excludeGrin'),
      filter: lead => !lead?.email?.includes('get-grin') && !lead?.doctor?.email?.includes('get-grin'),
      isAdminAction: true,
      count: 0,
      countFunc: () => 0
    },
    all: {
      isEnabled: true,
      label: t(`general.all`),
      filter: () => true,
      count: 0,
      countFunc: leads => leads?.filter(lead => !lead.email?.includes('@get-grin.com')).length ?? 0
    },
    newLeads: {
      isEnabled: false,
      icon: isEnabled => <NewLead isEnabled={isEnabled} />,
      label: t(`pages.rcDashboard.table.leadStatuses.newLead`),
      filter: lead => lead.status === 'newLead',
      count: leads.filter(lead => lead.status === 'newLead')?.length ?? 0,
      countFunc: leads =>
        leads.filter(lead => !lead.email?.includes('@get-grin.com') && lead.status === 'newLead')?.length ?? 0
    },
    waitingForScope: {
      isEnabled: false,
      icon: isEnabled => <WaitingForScope isEnabled={isEnabled} />,
      label: t(`pages.rcDashboard.table.leadStatuses.waitingForScope`),
      filter: lead => lead.status === 'waitingForScope',
      count: leads.filter(lead => lead.status === 'waitingForScope')?.length ?? 0,
      countFunc: leads =>
        leads.filter(lead => !lead.email?.includes('@get-grin.com') && lead.status === 'waitingForScope')?.length ?? 0
    },
    readyToScan: {
      isEnabled: false,
      icon: isEnabled => <ReadyToScan isEnabled={isEnabled} />,
      label: t(`pages.rcDashboard.table.leadStatuses.readyToScan`),
      filter: lead => lead.status === 'readyToScan',
      count: leads.filter(lead => lead.status === 'readyToScan')?.length ?? 0,
      countFunc: leads =>
        leads.filter(lead => !lead.email?.includes('@get-grin.com') && lead.status === 'readyToScan')?.length ?? 0
    },
    scanSent: {
      isEnabled: false,
      icon: isEnabled => <ScanSent isEnabled={isEnabled} />,
      label: t(`pages.rcDashboard.table.leadStatuses.scanSent`),
      filter: lead => lead.status === 'scanSent',
      count: leads.filter(lead => lead.status === 'scanSent')?.length ?? 0,
      countFunc: leads =>
        leads.filter(lead => !lead.email?.includes('@get-grin.com') && lead.status === 'scanSent')?.length ?? 0
    }
  })

  const toggleQuickFilters = useCallback(
    filterName => {
      if (quickFilters[filterName].isAdminAction) {
        setQuickFilters({
          ...quickFilters,
          [filterName]: {
            ...quickFilters[filterName],
            isEnabled: !quickFilters[filterName].isEnabled
          }
        })
      } else {
        let newQuickFilters = {
          ...quickFilters
        }

        if (filterName === 'all' && quickFilters[filterName].isEnabled) {
          return
        } else if (quickFilters[filterName].isEnabled && filterName !== 'all') {
          newQuickFilters[filterName].isEnabled = false
          newQuickFilters['all'].isEnabled = true
        } else {
          newQuickFilters = Object.keys(quickFilters).reduce(
            (updatedFilters, currFilter) =>
              currFilter === filterName
                ? {
                    ...updatedFilters,
                    [currFilter]: { ...quickFilters[currFilter], isEnabled: !quickFilters[currFilter].isEnabled }
                  }
                : quickFilters[currFilter].isAdminAction
                ? { ...updatedFilters, [currFilter]: quickFilters[currFilter] }
                : { ...updatedFilters, [currFilter]: { ...quickFilters[currFilter], isEnabled: false } },
            {}
          )
        }

        setQuickFilters(newQuickFilters)
      }
    },
    [quickFilters, setQuickFilters]
  )

  useEffect(() => {
    if (leads) {
      let updatedFilters = { ...quickFilters }
      Object.keys(quickFilters).forEach(filterKey => {
        quickFilters[filterKey].count = quickFilters[filterKey].countFunc(leads)
      })

      setQuickFilters(updatedFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leads])

  const enabledFilters = useMemo(
    () =>
      Object.keys(quickFilters).reduce(
        (filters, currFilter) =>
          quickFilters[currFilter].isEnabled ? { ...filters, [currFilter]: quickFilters[currFilter] } : filters,
        {}
      ),
    [quickFilters]
  )

  const tableSearch = useCallback(({ currentLead, searchQuery = '', isAdmin = false }) => {
    const stringSearchableFields = isAdmin
      ? ['email', 'leadName', 'age', 'additionalInfo', 'shippingStatus', 'doctorName', 'practiceName']
      : ['email', 'leadName', 'age', 'additionalInfo', 'address', 'address2', 'shippingStatus', 'createdAt']

    return !!stringSearchableFields.find(
      field => !!currentLead[field]?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }, [])

  return {
    quickFilters,
    enabledFilters,
    toggleQuickFilters,
    tableSearch
  }
}
