import * as React from 'react'

const Calendar6 = props => {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.5 6.5H16.5" stroke="#5E6B80" strokeWidth="1.5" strokeLinecap="round" />
      <rect x="1.25" y="3.25" width="15.5" height="10.5" rx="1.75" stroke="#5E6B80" strokeWidth="1.5" />
      <path d="M5 3V1" stroke="#5E6B80" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M13 3V1" stroke="#5E6B80" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}

export default Calendar6
