import * as React from 'react'

const NoScanSummary = props => (
  <svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.57 5.71662C22.1236 3.12727 25.8764 3.12726 27.43 5.71662L44.3652 33.942C45.9649 36.6081 44.0444 40 40.9352 40H7.06476C3.95558 40 2.03513 36.6081 3.63479 33.942L20.57 5.71662Z"
      fill="#D4D4D4"
    />
    <path d="M24 14V26" stroke="white" strokeWidth="3" strokeLinecap="round" />
    <path d="M24 32V33" stroke="white" strokeWidth="3" strokeLinecap="round" />
  </svg>
)

export default NoScanSummary
