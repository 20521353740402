import * as React from 'react'

const TransferArrow = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7373_224327)">
        <path
          d="M16.7746 4.09019L12.911 0.226581C12.6092 -0.0752015 12.12 -0.0752015 11.8182 0.226581C11.5164 0.528364 11.5164 1.01758 11.8182 1.31936L14.3627 3.86391H0.773711C0.346952 3.86391 0.000976562 4.20989 0.000976562 4.63665C0.000976562 5.0634 0.346952 5.40938 0.773711 5.40938H14.3627L11.8182 7.9539C11.5164 8.25568 11.5164 8.74493 11.8182 9.04668C11.9691 9.19755 12.1668 9.27299 12.3646 9.27299C12.5623 9.27299 12.7601 9.19759 12.9109 9.04664L16.7746 5.183C17.0763 4.88122 17.0763 4.39197 16.7746 4.09019Z"
          fill="#282828"
        />
        <path
          d="M16.2273 11.591H2.63827L5.18276 9.04641C5.48454 8.74463 5.48454 8.25542 5.18276 7.95363C4.88104 7.65185 4.39176 7.65185 4.08998 7.95363L0.226337 11.8172C-0.0754457 12.119 -0.0754457 12.6082 0.226337 12.91L4.08994 16.7737C4.24089 16.9246 4.43861 17 4.63637 17C4.83413 17 5.03192 16.9246 5.18272 16.7737C5.48451 16.4719 5.48451 15.9826 5.18272 15.6809L2.63824 13.1364H16.2273C16.654 13.1364 17 12.7904 17 12.3637C17 11.9369 16.654 11.591 16.2273 11.591Z"
          fill="#282828"
        />
      </g>
      <defs>
        <clipPath id="clip0_7373_224327">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TransferArrow
