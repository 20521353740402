import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { ButtonBase, Grid } from '@material-ui/core'
import DazzedParagraph11 from 'components/common/text/DazzedParagraph11'
import WithAligners from 'components/common/icons/timelineV2/scan-feed/WithAligners'
import WithoutAligners from 'components/common/icons/timelineV2/scan-feed/WithoutAligners'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'white',
    padding: '4px 8px',
    borderRadius: 16
  },
  label: {
    color: 'var(--text-color-3)',
    fontWeight: 500
  }
}))

const ToggleAlignersButton = ({ onClick, withAligners }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleClick = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      onClick()
    },
    [onClick]
  )

  return (
    <ButtonBase onClick={handleClick} className={classes.root}>
      <Grid container alignItems="center">
        <Grid item style={{ marginRight: 4 }}>
          {withAligners ? <WithAligners /> : <WithoutAligners />}
        </Grid>
        <Grid item>
          <DazzedParagraph11 className={classes.label}>
            {withAligners ? t('general.withAligners') : t('general.withoutAligners')}
          </DazzedParagraph11>
        </Grid>
      </Grid>
    </ButtonBase>
  )
}

export default ToggleAlignersButton
