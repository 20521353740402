import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  container: {
    background: '#00133560',
    position: 'absolute',
    borderRadius: 20,
    fontSize: 300,
    fontFamily: 'Dazzed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white'
  }
})

const CountDown = ({ isOpen, onComplete, start = 3, className, overlayDimensions = {} }) => {
  const classes = useStyles()
  const [current, setCurrent] = useState(start)
  const { t } = useTranslation()

  useEffect(() => {
    if (current === -1) {
      onComplete()
    }
    const interval = setInterval(() => setCurrent(current - 1), 1000)
    return () => clearInterval(interval)
  })

  return (
    <div className={[classes.container, className].join(' ')} style={{ ...overlayDimensions }}>
      {current !== 0 ? current : t('dialogs.scanReviewEditor.go')}
    </div>
  )
}

export default CountDown
