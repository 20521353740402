import * as React from 'react'

const Search = props => {
  return (
    <svg width="100" height="163" viewBox="0 0 100 123" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="37.1695" cy="48.1344" r="36.1695" fill="#D2E4FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7098 83.5332C17.1956 76.6893 8.70826 63.4027 8.70826 48.1338C8.70826 32.8649 17.1956 19.5783 29.7098 12.7344C13.3129 16.1723 1 30.7151 1 48.1338C1 65.5525 13.3129 80.0953 29.7098 83.5332Z"
        fill="#E4EFFF"
      />
      <circle cx="40.1343" cy="45.1695" r="36.1695" stroke="#7498CE" strokeWidth="2" />
      <path d="M65.6308 70.666L76.8967 81.9319" stroke="#7498CE" strokeWidth="2" />
      <path
        d="M75.3533 83.3438L78.3087 80.3884L95.558 97.6378C96.3741 98.4539 96.3741 99.777 95.558 100.593V100.593C94.7419 101.409 93.4188 101.409 92.6027 100.593L75.3533 83.3438Z"
        fill="#D2E4FF"
        stroke="#7498CE"
        strokeWidth="2"
      />
      <path
        d="M83.0462 12.161C83.8063 12.161 84.4769 12.0269 85.0582 11.7586V9.52315C84.6111 9.81376 84.0522 9.94789 83.4039 9.94789C82.6885 9.94789 82.3309 9.65728 82.3309 9.07605C82.3309 8.47247 82.8003 8.1148 83.7169 7.57828C84.8122 6.90764 85.5723 6.2817 85.9971 5.72283C86.4218 5.16396 86.6453 4.38154 86.6453 3.42029C86.6453 2.52609 86.2877 1.74367 85.55 1.05068C84.8346 0.357678 83.8063 0 82.4426 0C81.1461 0 80.006 0.380032 79 1.1401V4.04622C80.0507 3.0179 81.1684 2.50374 82.3309 2.50374C83.7169 2.50374 83.8957 3.42029 83.8957 3.64383C83.9181 4.13564 83.6498 4.53803 83.3368 4.85099C83.2474 4.94041 82.8227 5.23102 82.7109 5.32044C82.5991 5.40986 82.0179 5.74519 81.9061 5.8346C80.4307 6.77351 79.693 7.86889 79.693 9.09841C79.693 10.9539 81.0566 12.161 83.0462 12.161ZM81.5037 15.8048C82.1073 16.3637 83.0462 16.3637 83.6498 15.8048C84.2534 15.2013 84.2534 14.24 83.6498 13.6811C83.0462 13.0776 82.1073 13.0776 81.5037 13.6811C80.9002 14.24 80.9002 15.2013 81.5037 15.8048Z"
        fill="#7498CE"
      />
      <path d="M35 116H100" stroke="#D2E4FF" strokeWidth="2" />
      <path d="M0 116H27" stroke="#D2E4FF" strokeWidth="2" />
      <path d="M5 122H42" stroke="#D2E4FF" strokeWidth="2" />
      <path d="M84 122H91" stroke="#D2E4FF" strokeWidth="2" />
      <path d="M48 122H65" stroke="#D2E4FF" strokeWidth="2" />
    </svg>
  )
}

export default Search
