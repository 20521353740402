import * as React from 'react'

const PDFMessage = () => {
  return (
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1H10.2839V4.75309H14.037V17H1V1Z" stroke="#3D52EF" strokeLinejoin="round" />
      <path d="M10.2839 1L14.037 4.75309" stroke="#3D52EF" />
      <path
        d="M4.77866 8.00806C5.51466 8.00806 5.95866 8.35606 5.95866 8.93606C5.95866 9.52006 5.51466 9.86806 4.77866 9.86806H4.22266V11.0001H3.72266V8.00806H4.77866ZM4.77866 9.42006C5.20666 9.42006 5.44266 9.24806 5.44266 8.93606C5.44266 8.62806 5.20666 8.45206 4.77866 8.45206H4.22266V9.42006H4.77866Z"
        fill="#3D52EF"
      />
      <path
        d="M7.4255 8.00806C8.3535 8.00806 8.9495 8.64806 8.9495 9.50006C8.9495 10.3561 8.3535 11.0001 7.4255 11.0001H6.4375V8.00806H7.4255ZM7.4255 8.42806H6.9375V10.5801H7.4255C8.0575 10.5801 8.4375 10.1681 8.4375 9.50006C8.4375 8.82806 8.0535 8.42806 7.4255 8.42806Z"
        fill="#3D52EF"
      />
      <path
        d="M11.3886 8.00806V8.45206H9.97656V9.24406H11.3766V9.69206H9.97656V11.0001H9.47656V8.00806H11.3886Z"
        fill="#3D52EF"
      />
    </svg>
  )
}

export default PDFMessage
