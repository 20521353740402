import { API, graphqlOperation } from 'aws-amplify'
import { Roles } from 'consts/authConsts'
import { ofType } from 'redux-observable'
import { forkJoin, from, of, filter, withLatestFrom, concat } from 'rxjs'
import { catchError, map, mapTo, mergeMap, pluck, switchMap, tap } from 'rxjs/operators'
import { isUserOfRole } from 'utils/authUtils'
import { mapToPromotion } from 'utils/mappers/messagesMapper'
import { buildReadersFilter } from 'utils/searchUtils'
import Actions from '../actions'
import {
  supportSearchPatientSearchModel,
  getSearchPatientSearchModel,
  doctorPlansByAccountOwnerId,
  getAccountOwner,
  doctorsByAccountOwnerIdForSupport,
  doctorByAccessType,
  doctorPlansByAccessType,
  getDoctorForSupport,
  updatePracticeMemberForSupport,
  listDoctorsForSupport,
  transactionsByUsernameSorted,
  updateLead,
  totalHiAccountOwner
} from '../graphql/customQueries'
import {
  createUserNote,
  updateDoctorPlans,
  updateFeatureFlags,
  updateGrinUser,
  updatePatient,
  updatePromotion,
  createPromotion,
  updateAnnouncement,
  createAnnouncement,
  updateMessagingPreferences,
  updateDoctor
} from '../graphql/mutations'
import { listGroups, userNotesSorted, listAnnouncements, getGrinUser } from '../graphql/queries'
import i18n from '../resources/locales/i18n'
import { fetchAll } from '../utils/graphqlUtils'
import { mapToDoctorPlansModel, mapToPracticeMembersCollection } from '../utils/mappers/doctorsMappers'
import { mapLeadsSMToPatientsSM, mapLeadToPatient, mapToUpdateLeadInput } from '../utils/mappers/patientsMapper'
import { logError } from 'utils/logUtils'

export const requestAllowedDoctors = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_ALLOWED_DOCTORS),
    switchMap(() =>
      from(fetchAll(doctorPlansByAccessType, { accessType: 'owner', limit: 1000 }, 'doctorPlansByAccessType')).pipe(
        mergeMap(allowedDoctors => of(Actions.allowedDoctorsReceived(allowedDoctors))),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const requestCreateAllowedDoctorEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_CREATE_ALLOWED_DOCTOR),
    map(({ payload }) => payload),
    map(mapToDoctorPlansModel),
    switchMap(doctorPlans =>
      from(
        API.post('grinServerlessApi', `/accounts/v2/leads/doctors`, {
          body: {
            doctorInput: doctorPlans
          }
        })
      ).pipe(
        switchMap(({ data }) => of(Actions.createAllowedDoctorReceived(data))),
        catchError(({ response }) => of(Actions.createAllowedDoctorFailed(response?.data)))
      )
    )
  )

export const createAllowedDoctorReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_ALLOWED_DOCTOR_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: 'Allowed doctor succesfully created'
      })
    )
  )

export const createAllowedDoctorFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_ALLOWED_DOCTOR_FAILED),
    map(({ payload }) => payload),
    mergeMap(error =>
      error?.code?.includes('userAlreadyExists')
        ? of(
            Actions.showSnackbar({
              text: 'This email already exists in Grin Platform',
              type: 'error'
            })
          )
        : of(
            Actions.showSnackbar({
              text: 'Unknown error occured',
              type: 'error'
            })
          )
    )
  )

export const requestImportDoctorPlans = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_IMPORT_ALLOWED_DOCTOR),
    pluck('payload'),
    map(list => list.map(mapToDoctorPlansModel)),
    switchMap(doctorPlansList =>
      from(
        API.post('grinApi', '/batchActions', {
          body: {
            entityType: 'DoctorPlan',
            inputs: doctorPlansList
          }
        })
      ).pipe(
        pluck('response', 'result'),
        mergeMap(allowedDoctors =>
          of(
            Actions.importAllowedDoctorReceived({
              totalRequests: doctorPlansList,
              allowedDoctors: allowedDoctors
            })
          )
        ),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const requestUpdateDoctorPlans = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_UPDATE_DOCTOR_PLANS),
    pluck('payload'),
    map(mapToDoctorPlansModel),
    switchMap(doctorPlans =>
      from(API.graphql(graphqlOperation(updateDoctorPlans, { input: doctorPlans }))).pipe(
        pluck('data', 'updateDoctorPlans'),
        mergeMap(updatedDoctorPlans => of(Actions.updateDoctorPlansReceived(updatedDoctorPlans))),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const supportRequestDoctors = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SUPPORT_REQUEST_DOCTORS),
    withLatestFrom(state$),
    map(([action, state]) => ({
      groups: JSON.parse(state.profileReducer.doctor?.user?.allowed_groups_permissions || '[]').map(
        groupPermission => groupPermission.groupKey
      )
    })),
    switchMap(({ groups }) =>
      from(
        fetchAll(
          listDoctorsForSupport,
          {
            limit: 500,
            filter: !groups.length
              ? undefined
              : { or: groups.map(group => ({ a_readers: { contains: `Doctor_${group}_R` } })) }
          },
          'listDoctors'
        )
      ).pipe(
        map(doctors => doctors.filter(doctor => !doctor._deleted)),
        mergeMap(doctors => of(Actions.supportRequestDoctorsReceived(doctors))),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const supportRequestOwnerDoctors = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SUPPORT_REQUEST_OWNER_DOCTORS),
    pluck('payload'),
    switchMap(() =>
      from(
        fetchAll(
          doctorByAccessType,
          {
            accessType: 'owner',
            limit: 500
          },
          'doctorByAccessType'
        )
      ).pipe(
        map(doctors => doctors.filter(doctor => !doctor._deleted)),
        mergeMap(doctors => of(Actions.supportRequestDoctorsReceived(doctors))),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const supportUpdatePatientDetailsEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_PATIENT_DETAILS),
    pluck('payload'),
    filter(({ isLead }) => !isLead),
    switchMap(patient =>
      from(API.graphql(graphqlOperation(updatePatient, { input: patient }))).pipe(
        map(({ data }) => data.updatePatient),
        mergeMap(patient => of(Actions.supportUpdatePatientDetailsReceived(patient))),
        catchError(error => of(Actions.supportUpdatePatientDetailsFailed(error)))
      )
    )
  )

export const supportUpdateLeadDetailsEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_PATIENT_DETAILS),
    pluck('payload'),
    filter(({ isLead }) => isLead),
    map(patient => mapToUpdateLeadInput(patient)),
    switchMap(patient =>
      from(API.graphql(graphqlOperation(updateLead, { input: patient }))).pipe(
        map(({ data }) => data.updatePatient),
        mergeMap(patient => of(Actions.supportUpdatePatientDetailsReceived(patient))),
        catchError(error => of(Actions.supportUpdatePatientDetailsFailed(error)))
      )
    )
  )

export const supportUpdatePatientDetailsReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_PATIENT_DETAILS_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.changesSavedSuccessfully')
      })
    )
  )

export const supportUpdatePatientDetailsFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_PATIENT_DETAILS_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const supportUpdatePatientFFEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_PATIENT_FF),
    withLatestFrom(state$),
    map(([action, state]) => ({
      featureFlags: action.payload,
      patientId: state.patientsReducer.patient?.id
    })),
    switchMap(({ featureFlags, patientId }) =>
      from(
        API.put('grinServerlessApi', `/accounts/v2/patients/featureFlags/${patientId}`, {
          body: featureFlags
        })
      ).pipe(
        mergeMap(updatedFF => of(Actions.supportUpdatePatientFFReceived(updatedFF))),
        catchError(error => of(Actions.supportUpdatePatientFFFailed(error)))
      )
    )
  )

export const supportUpdatePatientFFReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_PATIENT_FF_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.changesSavedSuccessfully')
      })
    )
  )

export const supportUpdatePatientFFFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_PATIENT_FF_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const supportFetchDoctorTransactionsEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_FETCH_DOCTOR_TRANSACTIONS),
    pluck('payload'),
    switchMap(username =>
      from(
        API.graphql(graphqlOperation(transactionsByUsernameSorted, { owner: username, sortDirection: 'DESC' }))
      ).pipe(
        map(response => response.data.transactionsByUsernameSorted.items),
        mergeMap(transactions => of(Actions.supportFetchDoctorTransactionsReceived(transactions))),
        catchError(err => of(Actions.supportFetchDoctorTransactionsFailed(err)))
      )
    )
  )

export const supportFetchDoctorTransactionsFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_FETCH_DOCTOR_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const fetchSeatsByUsernameEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_DOCTOR_SEATS_BY_USERNAME),
    pluck('payload'),
    switchMap(username =>
      from(API.get('grinApi', `/accounts/doctors/seats?doctorUsername=${username}`)).pipe(
        mergeMap(seats => of(Actions.fetchDoctorSeatsByUsernameReceived(seats))),
        catchError(err => of(Actions.fetchDoctorSeatsByUsernameFailed(err)))
      )
    )
  )

export const fetchSeatsByUsernameFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_DOCTOR_SEATS_BY_USERNAME_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const supportFetchDoctorNotesByUserIdEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID),
    pluck('payload'),
    switchMap(grinUserId =>
      from(API.graphql(graphqlOperation(userNotesSorted, { grinUserId }))).pipe(
        map(response => response.data.userNotesSorted.items),
        mergeMap(userNotes => of(Actions.supportFetchDoctorNotesByUserIdReceived(userNotes))),
        catchError(err => of(Actions.supportFetchDoctorNotesByUserIdFailed(err)))
      )
    )
  )

export const supportFetchDoctorNotesByUserIdFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const supportCreateDoctorNoteEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_CREATE_DOCTOR_NOTE),
    pluck('payload'),
    switchMap(note =>
      from(API.graphql(graphqlOperation(createUserNote, { input: note }))).pipe(
        map(response => response.data.createUserNote),
        mergeMap(response => of(Actions.supportCreateDoctorNoteReceived(response))),
        catchError(err => of(Actions.supportCreateDoctorNoteFailed(err)))
      )
    )
  )

export const supportCreateDoctorNoteFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_CREATE_DOCTOR_NOTE_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const changeDoctorEmailEpic = action$ =>
  action$.pipe(
    ofType(Actions.CHANGE_DOCTOR_EMAIL),
    pluck('payload'),
    switchMap(({ newEmail, doctorUsername, resetPassword, sendWelcomeEmail }) =>
      from(
        API.put('grinServerlessApi', '/accounts/v2/doctors/email', {
          body: { newEmail, doctorUsername, resetPassword, sendWelcomeEmail }
        })
      ).pipe(
        mergeMap(response => of(Actions.changeDoctorEmailReceived({ doctorUsername, newEmail }))),
        catchError(({ response }) => of(Actions.changeDoctorEmailFailed(response)))
      )
    )
  )

export const changeDoctorEmailFailed = action$ =>
  action$.pipe(
    ofType(Actions.CHANGE_DOCTOR_EMAIL_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const changeDoctorEmailSuccesReloadDashboard = action$ =>
  action$.pipe(ofType(Actions.CHANGE_DOCTOR_EMAIL_RECEIVED), mapTo(Actions.supportRequestDoctors()))

export const changeEmailSuccessAlert = action$ =>
  action$.pipe(
    ofType(Actions.CHANGE_DOCTOR_EMAIL_RECEIVED, Actions.CHANGE_PATIENT_EMAIL_RECEIVED),
    pluck('payload'),
    map(({ newEmail }) =>
      Actions.showAlert({
        type: 'success',
        title: 'Email changed',
        message: `User's email successfuly changed to ${newEmail}. Please note, the user might need to reload the page or relogin.`
      })
    )
  )

export const changePatientEmailEpic = action$ =>
  action$.pipe(
    ofType(Actions.CHANGE_PATIENT_EMAIL),
    pluck('payload'),
    switchMap(({ newEmail, patientId }) =>
      from(API.put('grinServerlessApi', '/accounts/v2/patients/email', { body: { newEmail, patientId } })).pipe(
        mergeMap(response => of(Actions.changePatientEmailReceived({ patientId, newEmail }))),
        catchError(({ response }) => of(Actions.changePatientEmailFailed(response)))
      )
    )
  )

export const changePatientEmailFailed = action$ =>
  action$.pipe(
    ofType(Actions.CHANGE_PATIENT_EMAIL_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const fetchUserGroupsEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_USER_GROUPS),
    switchMap(() =>
      from(API.graphql(graphqlOperation(listGroups, { limit: 1000 }))).pipe(
        map(res => res.data.listGroups.items),
        mergeMap(data => of(Actions.fetchUserGroupsReceived(data))),
        catchError(err => of(Actions.fetchUserGroupsFailed(err)))
      )
    )
  )

export const deletePromotionEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_PROMOTION),
    pluck('payload'),
    switchMap(payload =>
      from(API.graphql(graphqlOperation(updatePromotion, { input: { ...payload, categoryKey: 'Archived' } }))).pipe(
        map(res => res.data.updatePromotion),
        mergeMap(data => of(Actions.deletePromotionReceived(data))),
        catchError(err => {
          of(Actions.deletePromotionFailed(err))
        })
      )
    )
  )

export const deletePromotionSuccess = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_PROMOTION_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.changesSavedSuccessfully')
      })
    )
  )

export const deletePromotionFailed = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_PROMOTION_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )
export const listPromotionsFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_PROMOTIONS_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const savePromotionEpic = action$ =>
  action$.pipe(
    ofType(Actions.SAVE_PROMOTION),
    pluck('payload'),
    switchMap(payload =>
      from(API.graphql(graphqlOperation(payload.id ? updatePromotion : createPromotion, { input: payload }))).pipe(
        map(res => res.data[payload.id ? 'updatePromotion' : 'createPromotion']),
        map(mapToPromotion),
        mergeMap(data => of(Actions.savePromotionReceived(data))),
        catchError(err => of(Actions.savePromotionFailed()))
      )
    )
  )

export const savePromotionSuccess = action$ =>
  action$.pipe(
    ofType(Actions.SAVE_PROMOTION_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.changesSavedSuccessfully')
      })
    )
  )

export const savePromotionFailed = action$ =>
  action$.pipe(
    ofType(Actions.SAVE_PROMOTION_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const supportSaveArticleEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_SAVE_ARTICLE),
    pluck('payload'),
    switchMap(payload =>
      from(
        API.put('grinServerlessApi', '/platform/v1/homepage/articles', {
          body: {
            article: payload
          }
        })
      ).pipe(
        mergeMap(() => of(Actions.supportSaveArticleReceived())),
        catchError(err => of(Actions.supportSaveArticleFailed(err)))
      )
    )
  )

export const supportSaveArticleReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_SAVE_ARTICLE_RECEIVED, Actions.SUPPORT_DELETE_ARTICLE_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.changesSavedSuccessfully')
      })
    )
  )

export const supportArticleFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_SAVE_ARTICLE_FAILED, Actions.SUPPORT_DELETE_ARTICLE_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const supportDeleteArticleEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_DELETE_ARTICLE),
    pluck('payload'),
    switchMap(payload =>
      from(API.del('grinServerlessApi', `/platform/v1/homepage/articles/${payload}`)).pipe(
        mergeMap(() => of(Actions.supportDeleteArticleReceived())),
        catchError(err => of(Actions.supportDeleteArticleFailed(err)))
      )
    )
  )

export const searchPatient = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SUPPORT_SEARCH_PATIENT),
    withLatestFrom(state$),
    map(([action, state]) => ({
      searchTerm: action.payload,
      isAdmin: isUserOfRole([Roles.Admin]),
      allowedGroups: JSON.parse(state.profileReducer.doctor.user?.allowed_groups_permissions || '[]')
    })),
    filter(({ searchTerm }) => !!searchTerm),
    switchMap(({ searchTerm, isAdmin, allowedGroups }) =>
      from(
        API.graphql(
          graphqlOperation(supportSearchPatientSearchModel, {
            limit: 10,
            filter: {
              and: [
                {
                  or: [
                    {
                      email: {
                        matchPhrasePrefix: searchTerm
                      }
                    },
                    {
                      name: {
                        matchPhrasePrefix: searchTerm
                      }
                    },
                    {
                      id: {
                        matchPhrasePrefix: searchTerm
                      }
                    },
                    {
                      username: {
                        matchPhrasePrefix: searchTerm
                      }
                    },
                    {
                      doctorEmail: {
                        matchPhrasePrefix: searchTerm
                      }
                    },
                    {
                      communicationEmails: { matchPhrasePrefix: searchTerm }
                    }
                  ]
                }
              ],
              ...(isAdmin ? {} : buildReadersFilter(allowedGroups, 'PatientSearchModel'))
            }
          })
        )
      ).pipe(
        map(res => mapLeadsSMToPatientsSM(res.data?.searchPatientSearchModels).items),
        mergeMap(data => of(Actions.supportSearchPatientReceived(data))),
        catchError(err => of(Actions.supportSearchPatientFailed(err)))
      )
    )
  )

export const fetchLinkedPatients = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SUPPORT_FETCH_LINKED_PATIENTS),
    withLatestFrom(state$),
    map(([action, state]) => ({
      guardianId: action.payload,
      isAdmin: isUserOfRole([Roles.Admin]),
      allowedGroups: JSON.parse(state.profileReducer.doctor.user?.allowed_groups_permissions || '[]')
    })),
    switchMap(({ guardianId, isAdmin, allowedGroups }) =>
      from(
        API.graphql(
          graphqlOperation(supportSearchPatientSearchModel, {
            limit: 10,
            filter: {
              guardianId: {
                eq: guardianId
              },
              ...(isAdmin ? {} : buildReadersFilter(allowedGroups, 'PatientSearchModel'))
            }
          })
        )
      ).pipe(
        map(res => mapLeadsSMToPatientsSM(res.data?.searchPatientSearchModels).items),
        mergeMap(data => of(Actions.supportFetchLinkedPatientsReceived(data))),
        catchError(err => of(Actions.supportFetchLinkedPatientsFailed(err)))
      )
    )
  )

export const supportFetchAnnouncementsEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_FETCH_ANNOUNCEMENTS),
    switchMap(() =>
      from(API.graphql(graphqlOperation(listAnnouncements))).pipe(
        map(res => res.data.listAnnouncements.items || []),
        map(announcements => announcements.filter(announcement => !announcement._deleted)),
        mergeMap(announcements => of(Actions.supportFetchAnnouncementsReceived(announcements))),
        catchError(err => of(Actions.supportFetchAnnouncementsFailed(err)))
      )
    )
  )

export const supportSaveAnnouncementEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_SAVE_ANNOUNCEMENT),
    pluck('payload'),
    map(announcement => ({
      announcement: {
        id: announcement.id,
        status: announcement.status,
        startDate: announcement.startDate,
        endDate: announcement.endDate,
        type: announcement.type,
        trigger: announcement.trigger,
        content: announcement.content,
        conditions: announcement.conditions,
        excludedUserEmails: announcement.excludedUserEmails,
        featureFlags: announcement.type !== 'featuresAwareness' ? null : announcement.featureFlags,
        settings: announcement.settings,
        href: announcement.href,
        _version: announcement._version
      },
      isNew: !announcement.id
    })),
    switchMap(({ announcement, isNew }) =>
      from(
        API.graphql(graphqlOperation(isNew ? createAnnouncement : updateAnnouncement, { input: announcement }))
      ).pipe(
        map(res => (isNew ? res.data.createAnnouncement : res.data.updateAnnouncement)),
        mergeMap(savedAnnouncement => of(Actions.supportSaveAnnouncementReceived({ isNew, data: savedAnnouncement }))),
        catchError(err => of(Actions.supportSaveAnnouncementFailed(err)))
      )
    )
  )

export const supportSaveAnnouncementFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_SAVE_ANNOUNCEMENT_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const requestDashboardUrl = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_REQUEST_DASHBOARD_URL),
    pluck('payload'),
    switchMap(patient_id =>
      from(API.get('grinApi', patient_id ? `/ops/v1/dashboard?patient_id=${patient_id}` : `/ops/v1/dashboard`)).pipe(
        mergeMap(({ baseUrl }) => of(Actions.supportDashboardUrlReceived(baseUrl))),
        catchError(({ response }) => of(Actions.supportDashboardUrlFailed(response)))
      )
    )
  )

export const dashboardUrlFailed = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_DASHBOARD_URL_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const supportFetchPracticeMembersEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_FETCH_PRACTICE_MEMBERS),
    pluck('payload'),
    switchMap(ownerId =>
      forkJoin({
        membersLeads: API.graphql(
          graphqlOperation(doctorPlansByAccountOwnerId, {
            accountOwnerId: ownerId
          })
        ).then(({ data }) => data?.doctorPlansByAccountOwnerId?.items),
        confirmedMembers: API.graphql(
          graphqlOperation(doctorsByAccountOwnerIdForSupport, {
            accountOwnerId: ownerId
          })
        ).then(({ data }) => data?.doctorsByAccountOwnerId?.items),
        accountOwner: API.graphql(
          graphqlOperation(getAccountOwner, {
            id: ownerId
          })
        ).then(({ data }) => data?.getDoctor)
      }).pipe(
        map(mapToPracticeMembersCollection),
        mergeMap(practiceMembers => of(Actions.supportFetchPracticeMembersReceived(practiceMembers))),
        catchError(err => of(Actions.supportFetchPracticeMembersFailed(err)))
      )
    )
  )

export const supportFetchPracticeMembersFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_FETCH_PRACTICE_MEMBERS_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('error.supportPracticeMembersRequest')
      })
    )
  )

export const supportUpdatePracticeMemberEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_PRACTICE_MEMBER),
    pluck('payload'),
    map(({ details, messagingPreferences, accountOwnerId, memberId }) => ({
      promises: Object.fromEntries(
        [
          [
            'doctor',
            details &&
              API.graphql(graphqlOperation(updatePracticeMemberForSupport, { input: details })).then(
                ({ data }) => data?.updateDoctor
              )
          ],
          [
            'messagingPreferences',
            messagingPreferences &&
              API.graphql(graphqlOperation(updateMessagingPreferences, { input: messagingPreferences })).then(
                ({ data }) => data?.updateMessagingPreferences
              )
          ]
        ].filter(([, promise]) => promise)
      ),
      accountOwnerId,
      memberId
    })),
    switchMap(({ promises, accountOwnerId, memberId }) =>
      forkJoin(promises).pipe(
        mergeMap(values => of(Actions.supportUpdatePracticeMemberReceived({ ...values, accountOwnerId, memberId }))),
        catchError(error => of(Actions.supportUpdatePracticeMemberFailed(error)))
      )
    )
  )

export const supportUpdatePracticeMemberReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_PRACTICE_MEMBER_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('dialogs.doctorCardEditor.editPracticeMember.updatedSuccessfully')
      })
    )
  )

export const supportUpdatePracticeMemberFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_PRACTICE_MEMBER_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('error.unableToUpdatePracticeMember')
      })
    )
  )

export const supportReRequestPracticeMembersEpic = action$ =>
  action$.pipe(
    ofType(
      Actions.DEACTIVATE_PRACTICE_MEMBER_RECEIVED,
      Actions.ACTIVATE_PRACTICE_MEMBER_RECEIVED,
      Actions.SUPPORT_UPDATE_PRACTICE_MEMBER_RECEIVED
    ),
    filter(() => isUserOfRole(['Admins'])),
    pluck('payload'),
    map(({ accountOwnerId }) => Actions.supportFetchPracticeMembers(accountOwnerId))
  )

export const supportFetchDoctorEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_FETCH_DOCTOR),
    pluck('payload'),
    switchMap(id =>
      from(
        API.graphql(
          graphqlOperation(getDoctorForSupport, {
            id
          })
        )
      ).pipe(
        map(({ data }) => data?.getDoctor),
        mergeMap(doctor => of(Actions.supportFetchDoctorReceived(doctor))),
        catchError(error => of(Actions.supportFetchDoctorFailed(error)))
      )
    )
  )

export const supportUpdateUserEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_USER),
    pluck('payload'),
    switchMap(user =>
      from(API.graphql(graphqlOperation(updateGrinUser, { input: user }))).pipe(
        mergeMap(() => of(Actions.supportUpdateUserReceived())),
        catchError(error => of(Actions.supportUpdateUserFailed(error)))
      )
    )
  )

export const supportUpdateUserReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_USER_RECEIVED),
    withLatestFrom(state$),
    map(([, state]) => state.supportReducer.doctorsDashboard.selectedDoctor?.id),
    mergeMap(doctorId =>
      concat(
        of(
          Actions.showSnackbar({
            type: 'success',
            text: i18n.t('messages.changesSavedSuccessfully')
          })
        ),
        of(Actions.supportRequestDoctors()),
        doctorId ? of(Actions.supportFetchDoctor(doctorId)) : []
      )
    )
  )

export const supportUpdateUserFailedEpic = action$ =>
  action$.pipe(
    ofType(
      Actions.SUPPORT_UPDATE_USER_FAILED,
      Actions.SUPPORT_UPDATE_DOCTOR_FF_FAILED,
      Actions.SUPPORT_UPDATE_DOCTOR_FAILED
    ),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('error.general')
      })
    )
  )

export const supportUpdateDoctorFFEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_DOCTOR_FF),
    pluck('payload'),
    switchMap(featureFlags =>
      from(API.graphql(graphqlOperation(updateFeatureFlags, { input: featureFlags }))).pipe(
        map(({ data }) => data?.updateFeatureFlags),
        mergeMap(flags => of(Actions.supportUpdateDoctorFFReceived(flags))),
        catchError(error => of(Actions.supportUpdateDoctorFFFailed(error)))
      )
    )
  )

export const supportUpdateDoctorFFReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_DOCTOR_FF_RECEIVED),
    withLatestFrom(state$),
    map(([, state]) => state.supportReducer.doctorsDashboard.selectedDoctor?.id),
    mergeMap(doctorId =>
      concat(
        of(
          Actions.showSnackbar({
            type: 'success',
            text: i18n.t('messages.changesSavedSuccessfully')
          })
        ),
        doctorId ? of(Actions.supportFetchDoctor(doctorId)) : []
      )
    )
  )

export const supportUpdateDoctorEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_DOCTOR),
    pluck('payload'),
    switchMap(doctor =>
      from(API.graphql(graphqlOperation(updateDoctor, { input: doctor }))).pipe(
        mergeMap(() => of(Actions.supportUpdateDoctorReceived())),
        catchError(error => of(Actions.supportUpdateDoctorFailed(error)))
      )
    )
  )

export const supportUpdateDoctorReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_DOCTOR_RECEIVED),
    withLatestFrom(state$),
    map(([, state]) => state.supportReducer.doctorsDashboard.selectedDoctor?.id),
    mergeMap(doctorId =>
      concat(
        of(
          Actions.showSnackbar({
            type: 'success',
            text: i18n.t('messages.changesSavedSuccessfully')
          })
        ),
        doctorId ? of(Actions.supportFetchDoctor(doctorId)) : []
      )
    )
  )

export const supportFetchPatientEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_FETCH_PATIENT),
    pluck('payload'),
    switchMap(({ patientId }) =>
      from(
        API.graphql(
          graphqlOperation(getSearchPatientSearchModel, {
            limit: 1,
            filter: { patientId: { eq: patientId } }
          })
        )
      ).pipe(
        map(res => res.data.searchPatientSearchModels?.items[0]),
        map(patientSM =>
          patientSM.patient
            ? patientSM
            : { ...patientSM, patient: mapLeadToPatient({ lead: patientSM.lead, patientSM }) }
        ),
        mergeMap(data => of(Actions.supportFetchPatientReceived(data))),
        catchError(err => of(Actions.supportFetchPatientFailed(err)))
      )
    )
  )

export const supportGetBillingReportEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_FETCH_BILLING_REPORT),
    pluck('payload'),
    switchMap(payload =>
      from(
        API.get(
          'grinServerlessApi',
          `/billing/v1/ops/reports?allPlans=${payload.allPlans || 'false'}${
            payload?.month ? '&month=' + payload?.month : ''
          }${payload?.reset ? '&reset=' + payload?.reset : ''}`
        )
      ).pipe(
        mergeMap(data => of(Actions.supportFetchBillingReportReceived(data))),
        catchError(({ error }) => of(Actions.supportFetchBillingReportFailed(error)))
      )
    )
  )

export const supportBillingCreateDraftInvoice = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_BILLING_CREATE_DRAFT_INVOICE),
    pluck('payload'),
    switchMap(({ practiceId, customActivePatientsCount }) =>
      from(
        API.post('grinServerlessApi', `/billing/v1/ops/reports/draftInvoice`, {
          body: {
            practiceId,
            customActivePatientsCount
          }
        })
      ).pipe(
        mergeMap(data => of(Actions.supportBillingCreateDraftInvoiceReceived(data))),
        catchError(({ response }) =>
          of(Actions.supportBillingCreateDraftInvoiceFailed({ error: response.data, practiceId }))
        )
      )
    )
  )

export const supportBillingCreateDraftInvoiceFailed = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_BILLING_CREATE_DRAFT_INVOICE_FAILED),
    pluck('payload', 'practiceId'),
    map(({ practiceId }) =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('pages.opsDashboard.billing.failedToGenerateInvoice')
      })
    )
  )

export const supportBillingCreateAndPayInvoiceEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_BILLING_CREATE_AND_PAY_INVOICE),
    pluck('payload'),
    switchMap(({ practiceId, customActivePatientsCount }) =>
      from(
        API.post('grinServerlessApi', `/billing/v1/ops/reports/payInvoice`, {
          body: {
            practiceId,
            customActivePatientsCount
          }
        })
      ).pipe(
        mergeMap(data => of(Actions.supportBillingCreateAndPayInvoiceReceived(data))),
        catchError(({ response }) =>
          of(Actions.supportBillingCreateAndPayInvoiceFailed({ error: response.data, practiceId }))
        )
      )
    )
  )

export const supportBillingCreateAndPayInvoiceFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_BILLING_CREATE_AND_PAY_INVOICE_FAILED),
    pluck('payload', 'practiceId'),
    map(({ practiceId }) =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('pages.opsDashboard.billing.failedToGenerateInvoice')
      })
    )
  )

export const supportSkipConfirmationCodeEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_SKIP_CONFIRMATION_CODE),
    pluck('payload'),
    switchMap(({ email }) =>
      from(
        API.post('grinServerlessApi', '/accounts/v2/users/admin/confirm', {
          body: {
            username: email
          }
        })
      ).pipe(
        mergeMap(data => of(Actions.supportSkipConfirmationCodeReceived(data))),
        catchError(({ response }) => of(Actions.supportSkipConfirmationCodeFailed(response.data)))
      )
    )
  )

export const supportSkipConfirmationCodeResponsedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_SKIP_CONFIRMATION_CODE_FAILED, Actions.SUPPORT_SKIP_CONFIRMATION_CODE_RECEIVED),
    map(({ type, payload }) => ({
      text: payload.message,
      type: type === Actions.SUPPORT_SKIP_CONFIRMATION_CODE_FAILED ? 'error' : 'success'
    })),
    map(Actions.showSnackbar)
  )

export const supportGetAuthDetailsEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_GET_AUTH_DETAILS),
    map(({ payload }) => payload),
    switchMap(email =>
      from(API.get('grinServerlessApi', `/accounts/v2/users/authDetails/${email}`)).pipe(
        mergeMap(data => of(Actions.supportGetAuthDetailsReceived(data))),
        catchError(({ response }) => of(Actions.supportGetAuthDetailsFailed(response?.data)))
      )
    )
  )

export const supportUpdateAppSettings = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_APP_SETTINGS),
    pluck('payload'),
    switchMap(({ userId, changes }) =>
      from(
        API.graphql(
          graphqlOperation(getGrinUser, {
            id: userId
          })
        )
      ).pipe(
        map(res => res.data.getGrinUser),
        switchMap(user =>
          from(
            API.graphql(
              graphqlOperation(updateGrinUser, {
                input: {
                  id: user.id,
                  _version: user._version,
                  appSettings: JSON.stringify({
                    ...JSON.parse(user.appSettings || '{}'),
                    ...changes
                  })
                }
              })
            )
          ).pipe(
            map(res => res.data.updateGrinUser),
            mergeMap(updatedGrinUser => of(Actions.supportUpdateAppSettingsReceived(updatedGrinUser))),
            catchError(err => of(Actions.supportUpdateAppSettingsFailed(err)))
          )
        ),
        catchError(err => of(Actions.supportUpdateAppSettingsFailed(err)))
      )
    )
  )

export const supportUpdatePatientCommunicationEmailEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL),
    pluck('payload'),
    switchMap(({ newCommunicationEmail, msgPrefId, patientId }) =>
      from(
        API.put('grinServerlessApi', `/accounts/v2/patients/messagingPreferences/${patientId}`, {
          body: {
            id: msgPrefId,
            contacts: [
              {
                email: newCommunicationEmail
              }
            ]
          }
        })
      ).pipe(mergeMap(() => of(Actions.supportUpdatePatientCommunicationEmailReceived({ newCommunicationEmail }))))
    ),
    catchError(error => of(Actions.supportUpdatePatientCommunicationEmailFailed({ error })))
  )

export const supportUpdatePatientCommunicationEmailFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL_FAILED),
    pluck('payload'),
    tap(({ msgPrefId, error }) =>
      logError('An error occured while updating patient communication email', { error, msgPrefId })
    ),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const supportUpdatePatientCommunicationEmailReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL_RECEIVED),
    map(() =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.changesSavedSuccessfully')
      })
    )
  )

export const supportFetchScanSummaryVersions = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS),
    switchMap(() =>
      from(API.get(`grinServerlessApi`, '/scanSummary/v1/model/versions')).pipe(
        mergeMap(res => of(Actions.supportFetchScanSummaryVersionsReceived(res))),
        catchError(ex => of(Actions.supportFetchScanSummaryVersionsFailed(ex)))
      )
    )
  )

export const supportDeleteEmailFromSuppressionListEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST),
    pluck('payload'),
    switchMap(({ emailAddress }) =>
      from(API.del('grinServerlessApi', `/notifications/v1/suppressionList/${emailAddress}`)).pipe(
        mergeMap(() => of(Actions.supportDeleteEmailFromSuppressionListReceived({ emailAddress }))),
        catchError(ex => of(Actions.supportDeleteEmailFromSuppressionListFailed(ex?.response?.data || ex)))
      )
    )
  )

export const supportDeleteEmailFromSuppressionListReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: 'The address has been deleted from the suppression list successfully'
      })
    )
  )

export const supportFetchHIPracticesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SUPPORT_FETCH_HI_PRACTICES),
    switchMap(() =>
      from(
        fetchAll(
          totalHiAccountOwner,
          {
            filter: {
              and: [
                {
                  or: [
                    {
                      grinPlanKey: {
                        eq: 'grin-subscription-gi_annually'
                      }
                    },
                    {
                      grinPlanKey: {
                        eq: 'grin-subscription-hi_annually'
                      }
                    },
                    {
                      grinPlanKey: {
                        eq: 'grin-practice-premium_annually'
                      }
                    }
                  ]
                },
                {
                  accountOwnerId: {
                    exists: false
                  }
                },
                {
                  hiGroup: {
                    exists: true
                  }
                }
              ]
            }
          },
          'searchDoctorSearchModels'
        )
      ).pipe(
        map(doctorSMs => doctorSMs.filter(doctorSM => !doctorSM._deleted && doctorSM.doctor.user.isActive)),
        mergeMap(doctors => of(Actions.supportFetchHiPracticesReceived(doctors))),
        catchError(error => of(Actions.supportFetchHiPracticesFailed(error)))
      )
    )
  )

export const suportUpdateHiGroupsEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_HI_GROUPS),
    pluck('payload'),
    switchMap(({ accountOwnerIds, newHiGroupKey }) =>
      from(
        API.put('grinServerlessApi', `/accounts/v2/practices/hiGroups`, {
          body: {
            accountOwnerIds,
            newHiGroupKey
          }
        })
      ).pipe(mergeMap(() => of(Actions.suportUpdateHiGroupsReceived())))
    ),
    catchError(error => of(Actions.suportUpdateHiGroupsFailed(error)))
  )

export const suportUpdateHiGroupsFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_HI_GROUPS_FAILED),
    pluck('payload'),
    tap(error => logError('An error occured while trying to update hi group for practices', { error })),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )
export const suportUpdateHiGroupsReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUPPORT_UPDATE_HI_GROUPS_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: 'Updated practices HI groups successfully'
      })
    )
  )
