const FETCH_PATIENT_TIMELINE_ITEMS = 'FETCH_PATIENT_TIMELINE_ITEMS'
const FETCH_PATIENT_TIMELINE_ITEMS_RECEIVED = 'FETCH_PATIENT_TIMELINE_ITEMS_RECEIVED'
const FETCH_PATIENT_TIMELINE_ITEMS_FAILED = 'FETCH_PATIENT_TIMELINE_ITEMS_FAILED'
const SELECT_TIMELINE_ITEM = 'SELECT_TIMELINE_ITEM'
const FETCH_TIMELINE_ITEM = 'FETCH_TIMELINE_ITEM'
const FETCH_TIMELINE_ITEM_RECEIVED = 'FETCH_TIMELINE_ITEM_RECEIVED'
const FETCH_TIMELINE_ITEM_FAILED = 'FETCH_TIMELINE_ITEM_FAILED'
const NEW_SCAN_REVIEW_RECEIVED = 'NEW_SCAN_REVIEW_RECEIVED'

const fetchPatientTimelineItems = payload => ({
  type: FETCH_PATIENT_TIMELINE_ITEMS,
  payload
})

const fetchPatientTimelineItemsReceived = payload => ({
  type: FETCH_PATIENT_TIMELINE_ITEMS_RECEIVED,
  payload
})

const fetchPatientTimelineItemsFailed = payload => ({
  type: FETCH_PATIENT_TIMELINE_ITEMS_FAILED,
  payload
})

const selectTimelineItem = payload => ({
  type: SELECT_TIMELINE_ITEM,
  payload
})

const fetchTimelineItem = payload => ({
  type: FETCH_TIMELINE_ITEM,
  payload
})

const fetchTimelineItemReceived = payload => ({
  type: FETCH_TIMELINE_ITEM_RECEIVED,
  payload
})

const fetchTimelineItemFailed = payload => ({
  type: FETCH_TIMELINE_ITEM_FAILED,
  payload
})

const newScanReviewReceived = payload => ({
  type: NEW_SCAN_REVIEW_RECEIVED,
  payload
})

export default {
  FETCH_PATIENT_TIMELINE_ITEMS,
  FETCH_PATIENT_TIMELINE_ITEMS_RECEIVED,
  FETCH_PATIENT_TIMELINE_ITEMS_FAILED,
  SELECT_TIMELINE_ITEM,
  FETCH_TIMELINE_ITEM,
  FETCH_TIMELINE_ITEM_RECEIVED,
  FETCH_TIMELINE_ITEM_FAILED,
  NEW_SCAN_REVIEW_RECEIVED,
  fetchPatientTimelineItems,
  fetchPatientTimelineItemsReceived,
  fetchPatientTimelineItemsFailed,
  selectTimelineItem,
  fetchTimelineItem,
  fetchTimelineItemReceived,
  fetchTimelineItemFailed,
  newScanReviewReceived
}
