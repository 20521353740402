import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Link } from '@material-ui/core'
import BrightCheckbox from '../BrightCheckbox'
import DazzedParagraph16 from '../../text/DazzedParagraph16'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  termsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20
  },
  termsText: {
    marginLeft: -10,
    fontSize: 17,
    fontWeight: 500
  },
  link: {
    fontSize: 17,
    fontWeight: 500,
    color: 'var(--text-color-3)',
    cursor: 'pointer'
  }
}))

const CreditCardTermsOfSale = ({ checked, onChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const termsOfSaleUrl = useSelector(state => state.appReducer.appconfig?.app?.payment?.termsOfSaleUrl)

  return (
    <div className={classes.termsContainer}>
      <BrightCheckbox checked={checked} onChange={event => onChange(event.target.checked)} />
      <DazzedParagraph16 className={classes.termsText}>{t('creditCard.iAgreeTo')}&nbsp;</DazzedParagraph16>
      <Link href={termsOfSaleUrl} target="_blank" rel="noopener noreferrer">
        <DazzedParagraph16 className={classes.link} strong>
          {t('creditCard.termsOfSale')}
        </DazzedParagraph16>
      </Link>
    </div>
  )
}

export default CreditCardTermsOfSale
