import * as React from 'react'

const Camera = ({ color = '#3C52EF', ...props }) => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14 3.99999H11.8867L10.6667 2.66666H6.66667V3.99999H10.08L11.3 5.33332H14V13.3333H3.33333V7.33332H2V13.3333C2 14.0667 2.6 14.6667 3.33333 14.6667H14C14.7333 14.6667 15.3333 14.0667 15.3333 13.3333V5.33332C15.3333 4.59999 14.7333 3.99999 14 3.99999ZM5.33333 9.33332C5.33333 11.1733 6.82667 12.6667 8.66667 12.6667C10.5067 12.6667 12 11.1733 12 9.33332C12 7.49332 10.5067 5.99999 8.66667 5.99999C6.82667 5.99999 5.33333 7.49332 5.33333 9.33332ZM8.66667 7.33332C9.76667 7.33332 10.6667 8.23332 10.6667 9.33332C10.6667 10.4333 9.76667 11.3333 8.66667 11.3333C7.56667 11.3333 6.66667 10.4333 6.66667 9.33332C6.66667 8.23332 7.56667 7.33332 8.66667 7.33332ZM3.33333 3.99999H5.33333V2.66666H3.33333V0.666656H2V2.66666H0V3.99999H2V5.99999H3.33333V3.99999Z"
        fill={color}
      />
    </svg>
  )
}

export default Camera
