import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import TagsSkeleton from './TagsSkeleton'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 16
  },
  text: {
    borderRadius: 6
  }
}))

const PatientsHeaderSkeleton = () => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root} alignItems="center" spacing={2}>
      <Grid item>
        <Skeleton variant="circle" width={58} height={58} />
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Skeleton variant="rect" width={200} height={24} className={classes.text} />
          </Grid>
          <Grid item>
            <TagsSkeleton />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PatientsHeaderSkeleton
