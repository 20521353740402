import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph10 from 'components/common/text/DazzedParagraph10'
import { CopySmall } from 'components/common/icons'

const useStyles = makeStyles(() => ({
  sharingMethod: {
    width: 110,
    height: 90,
    backgroundColor: 'var(--bg-color-53)',
    borderRadius: '5px 5px 0px 0px'
  },
  sharingMethodButton: {
    width: 110,
    height: 33,
    padding: '7px 0 0 0',
    backgroundColor: 'var(--bg-color-53)',
    cursor: 'pointer',
    marginTop: 3,
    borderRadius: '0px 0px 5px 5px'
  },
  sharingMethodLabel: {
    whiteSpace: 'break-spaces',
    color: 'var(--text-color-25)'
  },
  isRecommended: {
    position: 'relative',
    '&::before': {
      zIndex: -1,
      content: "''",
      position: 'absolute',
      top: 0,
      height: '5%',
      width: '100%',
      borderLeft: '10px solid var(--bg-color-43)',
      borderRight: '10px solid var(--bg-color-43)'
    }
  },
  recommendedText: {
    color: 'white'
  },
  recommendedLabel: {
    backgroundColor: 'var(--bg-color-43)',
    padding: 2,
    zIndex: -1,
    borderRadius: '5px 5px 0px 0px'
  },
  copyIcon: {
    marginTop: 2
  }
}))

const SharingMethod = ({ isRecommended, linkType, method }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        {isRecommended && (
          <Grid item className={classes.recommendedLabel}>
            <DazzedParagraph12 thickness="strong" textAlign="center" className={classes.recommendedText}>
              {t('general.recommended')}
            </DazzedParagraph12>
          </Grid>
        )}
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={`${classes.sharingMethod} ${isRecommended && classes.isRecommended} ${method.className || ''}`}
        >
          <Grid item>{method.icon(linkType)}</Grid>
          {method?.label && (
            <Grid item>
              <DazzedParagraph12 thickness="bold" textAlign="center" className={classes.sharingMethodLabel}>
                {method?.label}
              </DazzedParagraph12>
            </Grid>
          )}
          {method?.subtitle && (
            <Grid item>
              <DazzedParagraph10 textAlign="center" className={classes.sharingMethodLabel}>
                {method.subtitle}
              </DazzedParagraph10>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item className={classes.sharingMethodButton} onClick={() => method.onClick(linkType)}>
        <Grid container justifyContent="center">
          {!method?.hideCopyIcon && <CopySmall color="var(--text-color-25)" className={classes.copyIcon} />}
          <Grid item>
            <DazzedParagraph12 thickness="strong" textAlign="center" className={classes.sharingMethodLabel}>
              {method.buttonLabel}
            </DazzedParagraph12>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SharingMethod
