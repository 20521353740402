import * as React from 'react'

const Pencil3 = props => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.56796 1.61213C10.382 0.79805 11.7019 0.798051 12.516 1.61213V1.61213C13.3301 2.42622 13.3301 3.74611 12.516 4.56019L4.5002 12.576L1.61196 12.5162L1.55214 9.62796L9.56796 1.61213Z"
        stroke="#4251E7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.125 2.77944L11.307 5.96142" stroke="#4251E7" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  )
}

export default Pencil3
