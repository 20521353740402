import * as React from 'react'

export default ({ circleColor = '#DB615D', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="8" cy="8" r="8" fill={circleColor} />
      <path d="M7.20044 3.7334H8.80044V9.60007H7.20044V3.7334Z" fill="white" />
      <path d="M7.20044 11.2H8.80044V12.8H7.20044V11.2Z" fill="white" />
    </svg>
  )
}
