import moment from 'moment'

export const calculateTimeDifference = (date1, date2, measurement) => {
  const firstDate = moment(date1)
  const secondDate = moment(date2)
  return firstDate.diff(secondDate, measurement)
}

export const generateHoursList = (step = 30, start = '00:00', end = '23:30', labelFormat = 'hh:mm a') => {
  const startTime = moment(start, 'HH:mm')
  const endTime = moment(end, 'HH:mm')
  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day')
  }
  const timeStops = []
  while (startTime <= endTime) {
    timeStops.push({
      value: new moment(startTime).format('HH:mm'),
      label: new moment(startTime).format(labelFormat)
    })
    startTime.add(step, 'minutes')
  }
  return timeStops
}

export const compareDayOfWeekNames = (a, b) => {
  const order = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  return order.indexOf(a) - order.indexOf(b)
}

export const calculateTimezoneHoursOffset = timezone => {
  const offsetHours = moment().tz(timezone).utcOffset() / 60
  return offsetHours > 0 ? `+${offsetHours}` : offsetHours.toString()
}

export const generateMonthsRange = ({ originDate, targetDate }) => {
  const monthDates = []

  const startDate = moment(originDate)
  const endDate = moment(targetDate)

  let tempDate = startDate.clone()

  while (tempDate.isSameOrBefore(endDate)) {
    monthDates.push(tempDate.clone().valueOf())
    tempDate.add(1, 'month')
  }

  return monthDates
}

export const createdAtComparator = (obj1, obj2) => {
  const timestamp1 = new Date(obj1.createdAt).getTime()
  const timestamp2 = new Date(obj2.createdAt).getTime()
  return timestamp1 - timestamp2
}
