import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Plus, Persons } from '../../../common/icons'

const useStyles = () =>
  makeStyles({
    сontainer: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Dazzed'
    },
    icon: {
      marginRight: 10,
      height: 20
    },
    content: {
      fontSize: '14px',
      position: 'relative',
      '&:hover > svg': {
        display: 'block'
      }
    },
    contentEmail: {
      fontSize: '12px'
    },
    emptyStateLabel: {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  })()

const LegalGuardianField = ({ lastName, firstName, email, handleSave, handleClickEdit = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const content = useMemo(() => {
    if (firstName || lastName) {
      return (
        <>
          {`${firstName} ${lastName}`}
          <div className={classes.contentEmail}>{`\n${email ?? ''}`} </div>
        </>
      )
    }
    return (
      <span className={classes.emptyStateLabel} onClick={handleClickEdit}>
        {t('dialogs.patientInfo.addLegalGuardian')}
      </span>
    )
  }, [classes, firstName, lastName, email, handleClickEdit, t])

  return (
    <div className={classes.сontainer}>
      <div className={classes.icon}>
        {firstName || lastName ? <Persons /> : <Plus color="black" width="20" height="20" />}
      </div>
      <div className={classes.content}>{content}</div>
    </div>
  )
}

export default LegalGuardianField
