import React, { useCallback, useState } from 'react'
import { Grid, IconButton, makeStyles, useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useQueryParams from 'hooks/useQueryParams'
import { trackEvent } from 'utils/analyticsUtils'
import Tooltip from 'components/common/Tooltip'
import NextScan from 'components/common/icons/timelineV2/scan-feed/NextScan'
import ScanFeedCard from 'components/Patients/Timeline/V2/ScanFeed/ScanFeedCard'
import PreviousScan from 'components/common/icons/timelineV2/scan-feed/PreviousScan'
import ScanHeaderBodyXl from 'components/Patients/Timeline/V2/ScanFeed/ScanHeader/ScanHeaderBody.xl'
import ScanHeaderBodyLg from 'components/Patients/Timeline/V2/ScanFeed/ScanHeader/ScanHeaderBody.lg'

const useStyles = makeStyles(theme => ({
  scanHeaderRoot: {
    padding: ({ isSmallScreen }) => (isSmallScreen ? '12px 18px' : 24)
  }
}))

const ScanHeader = ({
  grinScanId,
  scanNumber,
  alignerNumber,
  numberOfReviews,
  numberOfShares,
  scanDate,
  nextScanTimelineItemId,
  previousScanTimelineItemId,
  analyticsPayload,
  onEditAlignerNumberClicked = () => {}
}) => {
  const isSmallScreen = useMediaQuery('(max-width:1920px)') // Check if the viewport max-width is *up to* 1920px
  const classes = useStyles({ isSmallScreen })
  const { t } = useTranslation()
  const { addQueryParam } = useQueryParams()

  const [isAlignersMenuOpen, setIsAlignersMenuOpen] = useState(false)

  const navigateToTimelineItem = useCallback(
    timelineItemId => {
      addQueryParam({ timelineItem: timelineItemId })
    },
    [addQueryParam]
  )

  const handleNextScanClicked = useCallback(() => {
    trackEvent('Scan Feed - go to next scan clicked', analyticsPayload)
    navigateToTimelineItem(nextScanTimelineItemId)
  }, [navigateToTimelineItem, nextScanTimelineItemId, analyticsPayload])

  const handlePreviousScanClicked = useCallback(() => {
    trackEvent('Scan Feed - go to previous scan clicked', analyticsPayload)
    navigateToTimelineItem(previousScanTimelineItemId)
  }, [navigateToTimelineItem, previousScanTimelineItemId, analyticsPayload])

  const handleReviewsClicked = useCallback(() => {
    trackEvent('Scan Feed - Number of reviews clicked', analyticsPayload)
  }, [analyticsPayload])

  const handleSharesClicked = useCallback(() => {
    trackEvent('Scan Feed - Number of shares clicked', analyticsPayload)
  }, [analyticsPayload])

  const handleEditAlignerNumberClicked = useCallback(() => {
    trackEvent('Scan Feed - edit aligner number clicked', analyticsPayload)
    onEditAlignerNumberClicked()
  }, [analyticsPayload, onEditAlignerNumberClicked])

  return (
    <ScanFeedCard className={classes.scanHeaderRoot}>
      <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
        <Grid item>
          <Tooltip
            value={t('pages.patients.selectedPatient.timelineV2.scanHeader.previousScanTooltip')}
            placement="left"
          >
            <IconButton size="small" onClick={handlePreviousScanClicked} disabled={!previousScanTimelineItemId}>
              <PreviousScan color={previousScanTimelineItemId ? 'var(--text-color-3)' : 'var(--text-color-4)'} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          {React.createElement(isSmallScreen ? ScanHeaderBodyLg : ScanHeaderBodyXl, {
            scanNumber,
            alignerNumber,
            numberOfShares,
            numberOfReviews,
            scanDate,
            onReviewsClicked: handleReviewsClicked,
            onSharesClicked: handleSharesClicked,
            alignerMenuProps: {
              isOpen: isAlignersMenuOpen,
              setIsOpen: setIsAlignersMenuOpen,
              onEditAlignerNumberClicked: handleEditAlignerNumberClicked
            }
          })}
        </Grid>
        <Grid item>
          <Tooltip value={t('pages.patients.selectedPatient.timelineV2.scanHeader.nextScanTooltip')}>
            <IconButton size="small" onClick={handleNextScanClicked} disabled={!nextScanTimelineItemId}>
              <NextScan color={nextScanTimelineItemId ? 'var(--text-color-3)' : 'var(--text-color-4)'} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </ScanFeedCard>
  )
}

export default React.memo(ScanHeader)
