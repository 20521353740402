import * as React from 'react'

function SvgEdit({ color = '#3C52EF' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.4992 16.0721L5.00471 18.6862C4.97018 18.8694 5.13064 19.0299 5.31379 18.9952L7.92789 18.5008C7.92789 18.5008 5.4992 16.0481 5.4992 16.0721Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6722 7.31271C20.0731 6.91926 20.0731 6.28116 19.6722 5.88762L18.0862 4.33044C17.6854 3.93689 17.0355 3.93689 16.6347 4.33044L15.0059 5.92971L18.0434 8.91207L19.6722 7.31271Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2124 7L6 15.2125L8.78743 18L17 9.7876L14.2124 7Z"
        fill={color}
      />
    </svg>
  )
}

export default SvgEdit
