import { ListItemIcon, makeStyles, Menu, MenuItem } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import React, { Fragment, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Actions from 'actions'
import { ROUTES } from 'consts'
import usePlanLimits from 'hooks/usePlanLimits'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import ProfilePicture from 'components/common/ProfilePicture'
import { isUserOfRole } from 'utils/authUtils'
import useRolePermissions from 'hooks/useRolePermissions'
import useBroadcast from 'hooks/useBroadcast'
import { Roles } from 'consts/authConsts'
import { setForceDesktop, isMobile, isDesktopForced } from 'utils/mobileUtils'
import { isMobile as isMobileDevice } from 'react-device-detect'
import {
  AccountSettings,
  Circle,
  DesktopVersion,
  GrinAssets,
  Help,
  MobileInvitePatient,
  OrderGrinScopes,
  PracticeAnalytics
} from 'components/common/icons'
import { getKistOrder, updateS3PracticeLogoKey } from 'utils/storageUtils'
import { trackEvent } from 'utils/analyticsUtils'
import ShareFeedback from 'components/common/icons/ShareFeedbackIcon'
import useOrderScopes from 'hooks/useOrderScopes'
import { createIdForTest } from 'utils/testingUtils.js'

const useAppProfileStyles = makeStyles({
  appProfile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    },
    marginLeft: 15,
    position: 'relative'
  },
  appProfileName: {
    color: 'var(--primary-color)',
    fontSize: isMobile() ? '14px' : '20px',
    wordBreak: isMobile() ? 'break-word' : 'unset',
    marginLeft: !isMobile() ? '20px' : 'unset'
  },
  appProfileImage: {
    width: isMobile() ? '40px' : '48px',
    height: isMobile() ? '40px' : '48px',
    borderRadius: '50%',
    marginRight: '20px'
  },
  indicator: {
    position: 'absolute',
    right: 1,
    top: 1
  }
})

const AppProfileMenuItem = React.forwardRef(
  (
    {
      id = '',
      label,
      to,
      variant = 'default',
      handleClose,
      onClickExtra = () => {},
      disabled = false,
      hasIndicator = false,
      icon = null
    },
    ref
  ) => {
    const history = useHistory()
    const classes = makeStyles({
      menuItem: {
        fontSize: '16px',
        lineHeight: '25px',
        color: 'var(--primary-color)',
        height: '45px',
        '&:hover': {
          fontWeight: 600
        }
      },
      buttonContainer: {
        padding: '10px',
        textAlign: 'center'
      },
      indicator: {
        marginLeft: 5,
        marginTop: 2
      },
      profileItemIcon: {
        minWidth: 30
      }
    })()

    if (variant === 'button') {
      return (
        <div className={classes.buttonContainer}>
          <SecondaryButton
            id={id}
            label={label}
            fontSize={16}
            fontWeight={500}
            disabled={disabled}
            onClick={() => {
              onClickExtra()
              handleClose()
              history.push(to)
            }}
          />
        </div>
      )
    }

    const idForTestsAndAnalytics = createIdForTest({ componentId: 'header-menu', title: label })
    return (
      <MenuItem
        id={idForTestsAndAnalytics}
        classes={{ root: classes.menuItem }}
        onClick={() => {
          trackEvent('App Header - Side menu', { action: idForTestsAndAnalytics })
          onClickExtra()
          handleClose()
          history.push(to)
        }}
      >
        {icon && <ListItemIcon className={classes.profileItemIcon}>{icon}</ListItemIcon>}
        {label}
        {hasIndicator && <Circle className={classes.indicator} />}
      </MenuItem>
    )
  }
)

const AppProfileMenu = ({ anchorEl, handleClick, handleClose, doctorId }) => {
  const classes = makeStyles({
    menu: {
      width: '246px'
    }
  })()

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()
  const grinAssetsLink = useSelector(state => state.appReducer.appconfig.app?.grinAssetsLink)
  const planLimits = usePlanLimits()
  const hasDraftOrder = getKistOrder()

  const { openOrderScopesModal } = useOrderScopes()

  return (
    <Fragment>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menu }}
      >
        {permissions.opsDashboard && (
          <AppProfileMenuItem
            label={t('common.appMenu.opsDashboard')}
            to={ROUTES.SUPPORT_DASHBOARD}
            handleClose={handleClose}
          />
        )}
        {!isMobile() && (
          <AppProfileMenuItem
            label={t('common.appMenu.accountSettings')}
            icon={<AccountSettings />}
            to={ROUTES.ACCOUNT_SETTINGS}
            handleClose={handleClose}
          />
        )}
        {permissions.billing && (
          <AppProfileMenuItem
            label={t('common.appMenu.orderScopes')}
            hasIndicator={isMobile() && hasDraftOrder}
            disabled={planLimits.maxScopes <= 0}
            handleClose={handleClose}
            icon={<OrderGrinScopes />}
            onClickExtra={openOrderScopesModal}
          />
        )}
        {isMobile() && permissions.invitePatient && (
          <AppProfileMenuItem
            label={t('common.appMenu.invitePatient')}
            handleClose={handleClose}
            icon={<MobileInvitePatient />}
            to={ROUTES.INVITE_PATIENT}
          />
        )}
        {(permissions.practiceAnalytics || permissions.dsoAnalytics) && !isMobile() && (
          <AppProfileMenuItem
            label={t('common.appMenu.practiceAnalytics')}
            icon={<PracticeAnalytics />}
            to={ROUTES.NEW_DOC_STATS}
            handleClose={handleClose}
          />
        )}
        {permissions.quickSignUp && (
          <AppProfileMenuItem
            label={t('common.appMenu.quickDoctorSignup')}
            onClickExtra={() => window.open(ROUTES.QUICK_SIGN_UP, '_blank')}
            handleClose={handleClose}
          />
        )}
        {isMobileDevice && (
          <AppProfileMenuItem
            label={isDesktopForced() ? t('common.appMenu.mobileVersion') : t('common.appMenu.desktopVersion')}
            onClickExtra={() => setForceDesktop(!isDesktopForced())}
            icon={<DesktopVersion />}
            handleClose={handleClose}
          />
        )}
        <AppProfileMenuItem
          label={t('common.appMenu.grinAssets')}
          icon={<GrinAssets />}
          handleClose={handleClose}
          onClickExtra={() => window.open(grinAssetsLink, '_blank')}
          // to={ROUTES.ASSETS} // Enable when we have the new hubspot page and remove the onClick
        />
        <AppProfileMenuItem
          label={t('common.appMenu.shareFeedback')}
          icon={<ShareFeedback />}
          handleClose={handleClose}
          onClickExtra={() => {
            trackEvent('App Feedback Modal - modal opened', {
              source: 'Profile menu'
            })
            dispatch(
              Actions.toggleAppFeedbackDialog({
                value: true,
                description: t('dialogs.shareFeedback.feedbackDescription')
              })
            )
          }}
        />
        <AppProfileMenuItem
          label={t('common.appMenu.help')}
          onClickExtra={() => dispatch(Actions.openHelpPopup())}
          icon={<Help />}
          handleClose={handleClose}
        />
        <AppProfileMenuItem
          id="header-menu-logout"
          label={t('common.appMenu.logOut')}
          variant="button"
          handleClose={handleClose}
          onClickExtra={() => dispatch(Actions.requestSignOut())}
        />
      </Menu>
    </Fragment>
  )
}

const AppProfile = () => {
  const classes = useAppProfileStyles()
  const { name } = useSelector(state => state.profileReducer.doctor)
  const practiceDetails = useSelector(state => state.practiceReducer.details)
  const hasIndicator = useSelector(state => state.appReducer.hasNotificationsIndicator)
  const [anchorEl, setAnchorEl] = useState(null)

  const { closeBroadcastMode } = useBroadcast()
  const practiceLogo = useMemo(
    () => practiceDetails?.logo && updateS3PracticeLogoKey(practiceDetails.logo),
    [practiceDetails.logo]
  )

  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget)
      closeBroadcastMode('Profile menu clicked')
    },
    [closeBroadcastMode]
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <div id="header-menu" className={classes.appProfile} onClick={handleClick}>
        <ProfilePicture
          name={practiceDetails.practiceName}
          email={practiceDetails.email}
          photo={practiceLogo}
          isAdmin={isUserOfRole([Roles.Admin])}
          size={isMobile() ? 'small' : 'medium'}
        />
        {isMobile() && hasIndicator && <Circle className={classes.indicator} height="10px" width="10px" />}
        {!isMobile() && (
          <>
            <div className={classes.appProfileName}>{name}</div>
            <ArrowDropDownIcon />
          </>
        )}
      </div>
      <AppProfileMenu anchorEl={anchorEl} handleClick={handleClick} handleClose={handleClose} />
    </>
  )
}

export default AppProfile
