import * as React from 'react'

const InviteMultiplePatients = props => (
  <svg width="42" height="24" viewBox="0 0 42 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 13.3184C6 12.5186 6.47585 11.7939 7.23259 11.5348C8.27078 11.1794 9.7778 10.75 11 10.75C12.2222 10.75 13.7292 11.1794 14.7674 11.5348C15.5242 11.7939 16 12.5186 16 13.3184V16C16 16.5523 15.5523 17 15 17H7C6.44772 17 6 16.5523 6 16V13.3184Z"
      fill="#B3B9F5"
    />
    <path
      d="M14 7C14 8.65685 12.6569 10 11 10C9.34315 10 8 8.65685 8 7C8 5.34315 9.34315 4 11 4C12.6569 4 14 5.34315 14 7Z"
      fill="#B3B9F5"
    />
    <path
      d="M14 13.3184C14 12.5186 14.4758 11.7939 15.2326 11.5348C16.2708 11.1794 17.7778 10.75 19 10.75C20.2222 10.75 21.7292 11.1794 22.7674 11.5348C23.5242 11.7939 24 12.5186 24 13.3184V16C24 16.5523 23.5523 17 23 17H15C14.4477 17 14 16.5523 14 16V13.3184Z"
      fill="#D9DCFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 13.3184V16C25 17.1046 24.1046 18 23 18H15C13.8954 18 13 17.1046 13 16V13.3184C13 12.1286 13.7132 10.998 14.9087 10.5887C15.9583 10.2294 17.6 9.75 19 9.75C20.4 9.75 22.0417 10.2294 23.0913 10.5887C24.2868 10.998 25 12.1286 25 13.3184ZM15.2326 11.5348C14.4758 11.7939 14 12.5186 14 13.3184V16C14 16.5523 14.4477 17 15 17H23C23.5523 17 24 16.5523 24 16V13.3184C24 12.5186 23.5242 11.7939 22.7674 11.5348C21.7292 11.1794 20.2222 10.75 19 10.75C17.7778 10.75 16.2708 11.1794 15.2326 11.5348Z"
      fill="#4151E6"
    />
    <path
      d="M22 7C22 8.65685 20.6569 10 19 10C17.3431 10 16 8.65685 16 7C16 5.34315 17.3431 4 19 4C20.6569 4 22 5.34315 22 7Z"
      fill="#D9DCFA"
    />
    <path
      d="M23 13.3184C23 12.5186 23.4758 11.7939 24.2326 11.5348C25.2708 11.1794 26.7778 10.75 28 10.75C29.2222 10.75 30.7292 11.1794 31.7674 11.5348C32.5242 11.7939 33 12.5186 33 13.3184V16C33 16.5523 32.5523 17 32 17H24C23.4477 17 23 16.5523 23 16V13.3184Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 13.3184V16C34 17.1046 33.1046 18 32 18H24C22.8954 18 22 17.1046 22 16V13.3184C22 12.1286 22.7132 10.998 23.9087 10.5887C24.9583 10.2294 26.6 9.75 28 9.75C29.4 9.75 31.0417 10.2294 32.0913 10.5887C33.2868 10.998 34 12.1286 34 13.3184ZM24.2326 11.5348C23.4758 11.7939 23 12.5186 23 13.3184V16C23 16.5523 23.4477 17 24 17H32C32.5523 17 33 16.5523 33 16V13.3184C33 12.5186 32.5242 11.7939 31.7674 11.5348C30.7292 11.1794 29.2222 10.75 28 10.75C26.7778 10.75 25.2708 11.1794 24.2326 11.5348Z"
      fill="#4151E6"
    />
    <path
      d="M31 7C31 8.65685 29.6569 10 28 10C26.3431 10 25 8.65685 25 7C25 5.34315 26.3431 4 28 4C29.6569 4 31 5.34315 31 7Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5833 14H33.4167V16.9167H30.5V18.0833H33.4167V21H34.5833V18.0833H37.5V16.9167H34.5833V14Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.4167 13H35.5833V15.9167H38.5V19.0833H35.5833V22H32.4167V19.0833H29.5V15.9167H32.4167V13ZM33.4167 16.9167H30.5V18.0833H33.4167V21H34.5833V18.0833H37.5V16.9167H34.5833V14H33.4167V16.9167Z"
      fill="#4151E6"
    />
  </svg>
)

export default InviteMultiplePatients
