import { makeStyles, useMediaQuery } from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import PatientsChat from './Chat/PatientsChat'
import TimelineV1 from './Timeline/V1/Timeline'
import TimelineV2 from './Timeline/V2/Timeline'
import PatientsHeader from './PatientsHeader/PatientsHeader'
import useHideHelpWidget from 'hooks/useHideHelpWidget'
import PatientSkeletonV1 from './Timeline/V1/PatientSkeleton'
import { isEmpty } from 'lodash'
import ChatEmptyState from './Chat/ChatEmptyState/ChatEmptyState'
import ChatSkeletonV2 from './Timeline/V2/Skeletons/ChatSkeleton'
import { useSelector } from 'react-redux'

const useStyles = ({ isFilterOpen, isTimelineV2 }) =>
  makeStyles({
    treatmentContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white'
    },
    chatAndMilestonesDivider: {
      display: 'flex',
      flexDirection: 'row',
      height: `calc(100% - 84px)`,
      position: 'relative',
      backgroundColor: isTimelineV2 ? 'var(--bg-color-timelineV2)' : 'transparent'
    },
    shade: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#333333',
      opacity: isFilterOpen ? '.2' : 0,
      zIndex: 50,
      transition: '.2s',
      pointerEvents: 'none'
    },
    emptyStateContainer: {
      width: '100%'
    }
  })({})

const Patient = ({ isFilterOpen }) => {
  useHideHelpWidget()

  const xl = useMediaQuery('(max-width:2560px)') // Check if the viewport max-width is *up to* 2560px
  const isTimelineV2 = useSelector(state => state.timelineReducer.isTimelineV2)
  const { patient, isLoadingPatient, requestedPatientId } = useSelector(state => state.patientsReducer)

  const classes = useStyles({ isFilterOpen, isTimelineV2 })

  const isPatientNotFound = useMemo(() => requestedPatientId && isEmpty(patient), [patient, requestedPatientId])

  const renderTimelineV1View = useCallback(
    () =>
      isLoadingPatient ? (
        <PatientSkeletonV1 />
      ) : isPatientNotFound ? (
        <div className={classes.emptyStateContainer}>
          <ChatEmptyState showNoPatientSelected />
        </div>
      ) : (
        <>
          <TimelineV1 />
          <PatientsChat />
        </>
      ),
    [isLoadingPatient, isPatientNotFound, classes.emptyStateContainer]
  )

  const renderTimelineV2View = useCallback(
    () =>
      isPatientNotFound && !isLoadingPatient ? (
        <div className={classes.emptyStateContainer}>
          <ChatEmptyState showNoPatientSelected />
        </div>
      ) : (
        <>
          <div style={{ width: xl ? '55%' : '50%' }}>
            <TimelineV2 />
          </div>
          <div style={{ width: xl ? '45%' : '50%' }}>{isLoadingPatient ? <ChatSkeletonV2 /> : <PatientsChat />}</div>
        </>
      ),
    [isPatientNotFound, isLoadingPatient, classes.emptyStateContainer, xl]
  )

  const timelineComponent = useMemo(
    () => (isTimelineV2 ? renderTimelineV2View() : renderTimelineV1View()),
    [isTimelineV2, renderTimelineV1View, renderTimelineV2View]
  )

  return (
    <div className={classes.treatmentContainer}>
      <PatientsHeader />
      <div className={classes.chatAndMilestonesDivider}>
        {timelineComponent}
        <div className={classes.shade} />
      </div>
    </div>
  )
}

export default React.memo(Patient)
