import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Oval from '../../common/icons/Oval'
import useRcDetails from '../useRcDetails'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    width: 419,
    height: 518,
    position: 'relative'
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    zIndex: 999,
    position: 'relative'
  },
  oval: {
    position: 'absolute',
    zIndex: 1
  },
  ovalNeutral: {
    right: 10,
    top: 70
  },
  ovalPrimary: {
    bottom: 40,
    left: 0
  }
}))

const MobileAppPreview = ({ primaryColor = '', secondaryColor = '' }) => {
  const classes = useStyles()
  const doctorDetails = useRcDetails()

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Oval color="#E1E5FE" diameter="95" className={[classes.oval, classes.ovalNeutral].join(' ')} />
        <Oval
          color={doctorDetails.colorSettings.accentColor || doctorDetails.colorSettings.primary}
          diameter="218"
          className={[classes.oval, classes.ovalPrimary].join(' ')}
        />
        <img
          src="https://d1g4lo0dz8n4nh.cloudfront.net/images/rc-landing-page/mobile-app.png"
          className={classes.image}
          alt="Grin patient's app"
        />
      </div>
    </div>
  )
}

export default MobileAppPreview
