import * as React from 'react'

const PaintWaves = ({ className, color }) => {
  return (
    <svg
      preserveAspectRatio="none"
      width="100%"
      height="382"
      viewBox="0 0 1920 382"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.92736C0 0.92736 454.461 175.849 738.503 155C1022.55 134.15 1057.93 1.65471 1321 0.92736C1584.07 0.200005 1920 155 1920 155V382H942H0V0.92736Z"
        fill={color}
      />
    </svg>
  )
}

export default PaintWaves
