import * as React from 'react'

export default () => {
  return (
    <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1.90698C1 1.40607 1.40607 1 1.90698 1H12.7907C13.2916 1 13.6977 1.40607 13.6977 1.90698V15.5116C13.6977 16.0125 13.2916 16.4186 12.7907 16.4186H1.90698C1.40607 16.4186 1 16.0125 1 15.5116V1.90698Z"
        stroke="#4151E6"
      />
      <rect x="2.81396" y="2.81396" width="9.06977" height="5.44186" rx="0.906977" fill="#4151E6" />
      <rect x="2.81396" y="9.16284" width="9.06977" height="5.44186" rx="0.906977" fill="#4151E6" />
      <path
        d="M15.5116 13.6975L10.9767 16.4184L15.5116 19.1394"
        stroke="white"
        strokeWidth="3.62791"
        strokeLinejoin="round"
      />
      <circle cx="15.5116" cy="13.6974" r="1.36047" fill="#4151E6" />
      <circle cx="15.5116" cy="19.1393" r="1.36047" fill="#4151E6" />
      <path d="M15.5116 13.6975L10.9767 16.4184L15.5116 19.1394" stroke="#4151E6" strokeLinejoin="round" />
    </svg>
  )
}
