import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { isMobile } from 'utils/mobileUtils'
import { trackEvent } from 'utils/analyticsUtils'
import { isValidEmail } from 'utils/generalUtils'
import ShareNewScanModalMobile from './ShareNewScanModal.Mobile'
import ShareNewScanModalDesktop from './ShareNewScanModalDesktop'
import { SHARE_METHODS } from './shareScanConsts'

const ShareNewScanModalHOC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { isOpen, grinScanId, isLoading } = useSelector(state => state.treatmentReducer.shareNewScanModal)
  const { patient } = useSelector(state => state.patientsReducer)

  const [referralName, setReferralName] = useState('')
  const [speciality, setSpeciality] = useState('')
  const [shareMethod, setShareMethod] = useState(SHARE_METHODS.email)
  const [shareDestination, setShareDestination] = useState('')
  const [note, setNote] = useState('')
  const [files, setFiles] = useState([])
  const [error, setError] = useState('')

  const hasFilledAllFields = useMemo(
    () => !!referralName && !!speciality && !!shareDestination && !!note,
    [note, referralName, shareDestination, speciality]
  )
  const patientName = useMemo(() => `${patient?.details?.firstName} ${patient?.details?.lastName?.[0]}.`, [patient])

  const shareTypeOptions = useMemo(
    () =>
      Object.values(SHARE_METHODS).map(option => ({
        label: t(`dialogs.shareScan.shareBy.${option}`),
        value: option
      })),
    [t]
  )

  const handleClose = useCallback(() => {
    trackEvent('Share scan modal - closed')
    dispatch(Actions.toggleShareNewScanModal({ isOpen: false }))
    setReferralName('')
    setSpeciality('')
    setShareMethod(SHARE_METHODS.email)
    setShareDestination('')
    setNote('')
  }, [dispatch])

  const validateFields = useCallback(() => {
    if (!hasFilledAllFields) {
      return false
    }

    if (shareMethod === SHARE_METHODS.email && !isValidEmail(shareDestination)) {
      setError(t('dialogs.shareScan.emailError'))
      return false
    }

    return true
  }, [hasFilledAllFields, shareDestination, shareMethod, t])

  const handleShareScan = useCallback(() => {
    if (!validateFields()) {
      return
    }

    trackEvent('Share scan modal - Shared scan to referral', {
      files,
      referralName,
      speciality,
      shareMethod,
      shareDestination,
      grinScanId
    })
    dispatch(
      Actions.shareScanToReferral({
        referralName,
        speciality,
        shareMethod,
        shareDestination,
        note,
        files: files.map(file => ({
          key: file.key,
          bucket: file.bucket,
          region: file.region
        })),
        grinScanId,
        patientName
      })
    )
  }, [
    dispatch,
    files,
    grinScanId,
    note,
    referralName,
    shareDestination,
    shareMethod,
    speciality,
    patientName,
    validateFields
  ])

  useEffect(() => {
    if (!isOpen) {
      setReferralName('')
      setSpeciality('')
      setShareMethod(SHARE_METHODS.email)
      setShareDestination('')
      setNote('')
      setFiles([])
    }
  }, [isOpen])

  return isMobile() ? (
    <ShareNewScanModalMobile
      isOpen={isOpen}
      grinScanId={grinScanId}
      isLoading={isLoading}
      onClose={handleClose}
      onShareScan={handleShareScan}
      hasFilledAllFields={hasFilledAllFields}
      referralName={referralName}
      setReferralName={setReferralName}
      speciality={speciality}
      setSpeciality={setSpeciality}
      shareMethod={shareMethod}
      setShareMethod={setShareMethod}
      shareDestination={shareDestination}
      setShareDestination={setShareDestination}
      note={note}
      setNote={setNote}
      shareTypeOptions={shareTypeOptions}
      error={error}
      SHARE_METHODS={SHARE_METHODS}
    />
  ) : (
    <ShareNewScanModalDesktop
      isOpen={isOpen}
      grinScanId={grinScanId}
      isLoading={isLoading}
      onClose={handleClose}
      onShareScan={handleShareScan}
      hasFilledAllFields={hasFilledAllFields}
      referralName={referralName}
      setReferralName={setReferralName}
      speciality={speciality}
      setSpeciality={setSpeciality}
      shareMethod={shareMethod}
      setShareMethod={setShareMethod}
      shareDestination={shareDestination}
      setShareDestination={setShareDestination}
      note={note}
      setNote={setNote}
      shareTypeOptions={shareTypeOptions}
      error={error}
      files={files}
      setFiles={setFiles}
    />
  )
}

export default ShareNewScanModalHOC
