import React from 'react'
import { useTranslation } from 'react-i18next'
import { SpecialConditions } from './usePatientSpecialCondition'
import { Overdue, Urgent } from 'components/common/icons'
import { createIdForTest } from 'utils/testingUtils'
import Tooltip from 'components/common/Tooltip'
import Paused from 'components/common/icons/Paused'
import NeedsAttentionLarge from 'components/common/icons/NeedsAttentionLarge'

const PatientSpecialCondition = ({ condition, patientTestId, overdueDays }) => {
  const { t } = useTranslation()

  return condition === SpecialConditions.Urgent ? (
    <Urgent id={createIdForTest({ componentId: 'urgent-icon', title: patientTestId })} />
  ) : condition === SpecialConditions.Overdue ? (
    <Tooltip
      value={
        <>
          {t('pages.patients.selectedPatient.timeline.banners.scanOverdue')}&nbsp;
          <strong>
            {t(
              `pages.patients.selectedPatient.timeline.banners.${
                overdueDays > 1 ? 'scanOverdueDays' : 'scanOverdueDay'
              }`,
              {
                date: overdueDays
              }
            )}
          </strong>
        </>
      }
      placement="top"
      arrow
      variant="dark"
    >
      <Overdue id={createIdForTest({ componentId: 'overdue-icon', title: patientTestId })} />
    </Tooltip>
  ) : condition === SpecialConditions.Paused ? (
    <Paused id={createIdForTest({ componentId: 'paused-icon', title: patientTestId })} />
  ) : condition === SpecialConditions.NeedsAttention ? (
    <NeedsAttentionLarge id={createIdForTest({ componentId: 'needs-attention-icon', title: patientTestId })} />
  ) : (
    <></>
  )
}

export default PatientSpecialCondition
