import React, { useRef, useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'

const useStyles = ({ height }) =>
  makeStyles({
    sliderRoot: {
      width: 60,
      color: 'var(--bg-color-2)'
    },
    sliderRail: {
      width: 0,
      height: '0 !important',
      borderStyle: 'solid',
      borderWidth: ({ height }) => `${height}px 5px 0 5px`,
      borderColor: 'white transparent transparent transparent',
      backgroundColor: 'transparent'
    },
    sliderTrack: {
      width: '0 !important'
    },
    sliderThumb: {
      marginLeft: '0 !important'
    }
  })({ height })

const DEFAULT_MIN_VALUE = 1
const DEFAULT_MAX_VALUE = 20
const DEFAULT_STEP = 0.1

const CursorSizeSlider = ({
  drawWidth,
  defaultLineWidth,
  onChangeWidth,
  minValue = DEFAULT_MIN_VALUE,
  maxValue = DEFAULT_MAX_VALUE,
  step = DEFAULT_STEP,
  wrapperClassName = '',
  fullHeightSlider
}) => {
  const sliderRef = useRef()
  const [height, setHeight] = useState(0)
  const classes = useStyles({ height: fullHeightSlider ? height : height - 60 })

  useEffect(() => {
    if (sliderRef?.current?.clientHeight) {
      setHeight(sliderRef?.current?.clientHeight)
    }
  }, [])

  return (
    <div className={wrapperClassName} ref={sliderRef}>
      <Slider
        classes={{
          root: classes.sliderRoot,
          track: classes.sliderTrack,
          rail: classes.sliderRail,
          thumb: classes.sliderThumb
        }}
        orientation="vertical"
        defaultValue={defaultLineWidth}
        step={step}
        min={minValue}
        max={maxValue}
        value={drawWidth}
        onChange={onChangeWidth}
      />
    </div>
  )
}

export default React.memo(CursorSizeSlider)
