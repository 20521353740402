import * as React from 'react'

export default () => {
  return (
    <svg width="64" height="55" viewBox="0 0 64 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H61C62.6569 0 64 1.34315 64 3V39C64 40.6569 62.6569 42 61 42H3C1.34315 42 0 40.6569 0 39V3Z"
        fill="#ECF4FF"
      />
      <path d="M0 3C0 1.34315 1.34315 0 3 0H11V6H0V3Z" fill="#129E5A" />
      <path d="M0 12H64" stroke="#D5E2F1" />
      <path d="M16 6L16 42" stroke="#D5E2F1" />
      <path d="M32 6L32 42" stroke="#D5E2F1" />
      <path d="M48 6L48 42" stroke="#D5E2F1" />
      <path d="M0 18H64" stroke="#D5E2F1" />
      <path d="M0 24H64" stroke="#D5E2F1" />
      <path d="M0 30H64" stroke="#D5E2F1" />
      <path d="M0 36H64" stroke="#D5E2F1" />
      <path d="M0 6H64" stroke="#D5E2F1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 31H27V45.1924H23L32.0962 54.2886L41.1924 45.1924H37V31Z"
        fill="url(#paint0_linear_5_88)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_88"
          x1="32.0962"
          y1="32.5138"
          x2="32.0962"
          y2="49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#61E2AC" stopOpacity="0" />
          <stop offset="1" stopColor="#61E2AC" />
        </linearGradient>
      </defs>
    </svg>
  )
}
