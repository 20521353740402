import React from 'react'
import { makeStyles, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  placeholderWrapper: {
    backgroundColor: 'var(--bg-color-19)',
    borderRadius: '8px',
    width: '70px',
    height: '52px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const FilePlaceholder = ({ isLoading = false, placeholderIcon, className = '' }) => {
  const classes = useStyles()

  return (
    <div className={[classes.placeholderWrapper, className].join(' ')}>
      {isLoading ? <CircularProgress color="secondary" size={25} /> : placeholderIcon}
    </div>
  )
}

export default FilePlaceholder
