import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import ProfilePicture from 'components/common/ProfilePicture'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import Tooltip from 'components/common/Tooltip'
import MemberCell from './MemberCell'

const useStyles = ({ disabled }) =>
  makeStyles({
    memberInfoWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    memberNameWrapper: {
      marginLeft: 23,
      display: 'flex',
      flexDirection: 'column',
      width: 200
    },
    name: {
      color: ({ disabled }) => (disabled ? 'var(--text-color-21)' : 'inherit')
    },
    email: {
      color: ({ disabled }) => (disabled ? 'var(--text-color-21)' : 'var(--text-color-12)'),
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }
  })({ disabled })

const MemberNameCell = ({ firstName, lastName, name, avatar, email, disabled }) => {
  const classes = useStyles({ disabled })
  const memberName = useMemo(() => name || `${firstName} ${lastName}`, [firstName, lastName, name])
  return (
    <MemberCell>
      <div className={classes.memberInfoWrapper}>
        <ProfilePicture name={memberName} email={email} photo={avatar} />
        <div className={classes.memberNameWrapper}>
          <DazzedParagraph14 strong className={classes.name}>
            {memberName}
          </DazzedParagraph14>
          <Tooltip value={email} variant="dark" placement="bottom">
            <DazzedParagraph12 thin className={classes.email}>
              {email}
            </DazzedParagraph12>
          </Tooltip>
        </div>
      </div>
    </MemberCell>
  )
}

export default MemberNameCell
