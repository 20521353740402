import * as React from 'react'

export default () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.07177 1H11.0083C12.1789 1 13.1279 1.949 13.1279 3.11965V8.15597C13.1279 8.42118 13.0226 8.67554 12.835 8.86308L8.8787 12.8194C8.69116 13.0069 8.43681 13.1123 8.17159 13.1123H3.13528C1.96463 13.1123 1.01562 12.1633 1.01562 10.9926V7.05615"
        stroke="#3C52EF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M3.28906 1V5.57996" stroke="#3C52EF" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5.57812 3.28998L0.998165 3.28998" stroke="#3C52EF" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M13.128 7.57019H9.70559C8.53494 7.57019 7.58594 8.51919 7.58594 9.68984V13.1123"
        stroke="#3C52EF"
        strokeWidth="1.5"
      />
    </svg>
  )
}
