import React, { useState } from 'react'
import { makeStyles, Grid, Collapse } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import ShowExplanation from './ShowExplanation'

import { BroadcastMessage, PersonalMessage } from './Messages'

const useStyles = makeStyles({
  messages: {
    marginBottom: 30
  },
  detailed: {
    marginBottom: 7
  }
})

const BroadcastComparisonModal = ({ isOpen, handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isBroadcastExpanded, setIsBroadcastExpanded] = useState(false)
  const [isPersonalExpanded, setIsPersonalExpanded] = useState(false)

  return (
    <Grid container direction="column">
      <Grid item>
        <DazzedParagraph14 bold>
          {t('pages.patients.selectedPatient.chat.comparisonModal.broadcastTitle')}
        </DazzedParagraph14>
        <DazzedParagraph14>{t('pages.patients.selectedPatient.chat.comparisonModal.subtitle')}</DazzedParagraph14>
        <BroadcastMessage />
        <Collapse in={isBroadcastExpanded}>
          <DazzedParagraph14 className={classes.detailed}>
            {t('pages.patients.selectedPatient.chat.comparisonModal.broadcastDescription')}
          </DazzedParagraph14>
        </Collapse>
        <ShowExplanation isExpanded={isBroadcastExpanded} setIsExpanded={setIsBroadcastExpanded} />
      </Grid>
      <Grid item>
        <DazzedParagraph14 bold>
          {t('pages.patients.selectedPatient.chat.comparisonModal.personalTitle')}
        </DazzedParagraph14>
        <DazzedParagraph14>{t('pages.patients.selectedPatient.chat.comparisonModal.subtitle')}</DazzedParagraph14>
        <PersonalMessage />
        <Collapse in={isPersonalExpanded}>
          <DazzedParagraph14 className={classes.detailed}>
            {t('pages.patients.selectedPatient.chat.comparisonModal.personalDescription')}
          </DazzedParagraph14>
        </Collapse>
        <ShowExplanation isExpanded={isPersonalExpanded} setIsExpanded={setIsPersonalExpanded} />
      </Grid>
    </Grid>
  )
}

export default BroadcastComparisonModal
