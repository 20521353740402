import * as React from 'react'

const NewLead = ({ color = '#041333', isEnabled = false }) => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="10.5" r="3.5" stroke={isEnabled ? '#4151E6' : color} />
      <path d="M4 17C4 17 6.5 15 11 15C15.5 15 18 17 18 17V21H4V17Z" stroke={isEnabled ? '#4151E6' : color} />
      <mask id="path-3-outside-1_243_102" maskUnits="userSpaceOnUse" x="15" y="5" width="12" height="12" fill="black">
        <rect fill="white" x="15" y="5" width="12" height="12" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4286 9.57145L21 6L19.5714 9.57144L16 11L19.5714 12.4286L21 16L22.4286 12.4286L26 11L22.4286 9.57145Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4286 9.57145L21 6L19.5714 9.57144L16 11L19.5714 12.4286L21 16L22.4286 12.4286L26 11L22.4286 9.57145Z"
        fill={isEnabled ? '#4151E6' : color}
      />
      <path
        d="M21 6L21.9285 5.62861C21.7766 5.24895 21.4089 5 21 5C20.5911 5 20.2234 5.24895 20.0715 5.62861L21 6ZM22.4286 9.57145L21.5001 9.94284C21.6018 10.1969 21.8031 10.3983 22.0572 10.4999L22.4286 9.57145ZM19.5714 9.57144L19.9428 10.4999C20.1969 10.3983 20.3983 10.1969 20.4999 9.94283L19.5714 9.57144ZM16 11L15.6286 10.0715C15.249 10.2234 15 10.5911 15 11C15 11.4089 15.2489 11.7766 15.6286 11.9285L16 11ZM19.5714 12.4286L20.4999 12.0572C20.3983 11.8031 20.1969 11.6018 19.9428 11.5001L19.5714 12.4286ZM21 16L20.0715 16.3714C20.2234 16.751 20.5911 17 21 17C21.4089 17 21.7766 16.7511 21.9285 16.3714L21 16ZM22.4286 12.4286L22.0572 11.5001C21.8031 11.6017 21.6017 11.8031 21.5001 12.0572L22.4286 12.4286ZM26 11L26.3714 11.9285C26.7511 11.7766 27 11.4089 27 11C27 10.5911 26.751 10.2234 26.3714 10.0715L26 11ZM20.0715 6.37139L21.5001 9.94284L23.3571 9.20006L21.9285 5.62861L20.0715 6.37139ZM20.4999 9.94283L21.9285 6.37139L20.0715 5.62861L18.643 9.20005L20.4999 9.94283ZM16.3714 11.9285L19.9428 10.4999L19.2 8.64296L15.6286 10.0715L16.3714 11.9285ZM19.9428 11.5001L16.3714 10.0715L15.6286 11.9285L19.2001 13.3571L19.9428 11.5001ZM21.9285 15.6286L20.4999 12.0572L18.643 12.8L20.0715 16.3714L21.9285 15.6286ZM21.5001 12.0572L20.0715 15.6286L21.9285 16.3714L23.3571 12.8L21.5001 12.0572ZM25.6286 10.0715L22.0572 11.5001L22.8 13.3571L26.3714 11.9285L25.6286 10.0715ZM22.0572 10.4999L25.6286 11.9285L26.3714 10.0715L22.8 8.64297L22.0572 10.4999Z"
        fill="white"
        mask="url(#path-3-outside-1_243_102)"
      />
    </svg>
  )
}

export default NewLead
