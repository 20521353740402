import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Actions from '../../../../../../actions'

import DazzedParagraph12 from '../../../../../common/text/DazzedParagraph12'
import DazzedHeading24 from '../../../../../common/text/DazzedHeading24'
import { ImagePlaceholder } from '../../../../../common/icons'
import ServicesClinicLogo from '../../../ServicesClinicLogo'

const useStyles = makeStyles(theme => ({
  headerTextContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20
  },
  headerText: {
    fontFamily: 'Ogg',
    fontSize: '18px',
    color: ({ headerFontColor }) => headerFontColor,
    fontWeight: 'normal',
    lineHeight: '22px'
  },
  lineSeparator: {
    background: ({ accentColor }) => accentColor,
    minHeight: '2px',
    margin: '15px 0'
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  doctorDetails: {
    color: ({ headerFontColor }) => headerFontColor,
    paddingLeft: 15,
    fontSize: '10px',
    lineHeight: '24px',
    fontWeight: 500
  },
  placeholderImage: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  placeholderImageSize: {
    fontSize: '7px',
    color: 'var(--text-color-11)'
  },
  placeholderImageIcon: {
    marginLeft: 5
  },
  placeholderImageText: {
    color: 'var(--text-color-11)',
    marginBottom: 5,
    marginTop: 7,
    fontSize: '10px'
  },
  bold: {
    fontWeight: 600
  }
}))

const HeaderPreviewDetails = ({ headerFontColor, accentColor }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles({
    headerFontColor,
    accentColor
  })
  const doctor = useSelector(state => state.practiceReducer.accountOwner)
  const practiceDetails = useSelector(state => state.practiceReducer.details)

  const [isLogoLoading, setIsLogoLoading] = useState(false)

  useEffect(() => {
    setIsLogoLoading(false)
  }, [doctor])

  const updateClinicLogo = useCallback(
    key =>
      dispatch(
        Actions.updateClinicLogo({
          doctor,
          key: key[0]
        })
      ),
    [dispatch, doctor]
  )

  const clinicLogoLabelId = useMemo(() => 'clinicLogoDesighTab', [])

  const placeholderImage = useMemo(() => {
    return (
      <>
        <label htmlFor={clinicLogoLabelId}>
          <div className={classes.placeholderImage}>
            <DazzedParagraph12 className={classes.placeholderImageText}>
              {t('pages.accountSettings.services.remoteConsultation.yourLogo')}
            </DazzedParagraph12>
            <ImagePlaceholder
              fill="var(--text-color-11)"
              className={classes.placeholderImageIcon}
              width="30"
              height="25"
            />
            <DazzedParagraph12 className={classes.placeholderImageSize}>
              {t('pages.accountSettings.services.logoSize')}
            </DazzedParagraph12>
          </div>
        </label>
      </>
    )
  }, [
    classes.placeholderImage,
    classes.placeholderImageIcon,
    classes.placeholderImageSize,
    classes.placeholderImageText,
    clinicLogoLabelId,
    t
  ])

  return (
    <div className={classes.headerTextContainer}>
      <div className={classes.headerText}>
        {t('pages.rcLanding.header.line1')}
        <br />
        {t('pages.rcLanding.header.line2')}
        <br />
        {t('pages.rcLanding.header.line3', { doctorName: doctor.name })}
      </div>
      <div className={classes.lineSeparator} />
      <div className={classes.detailsContainer}>
        <ServicesClinicLogo
          isLoading={isLogoLoading}
          setIsLoading={setIsLogoLoading}
          clinicLogoS3Obj={practiceDetails.logo}
          updateClinicLogo={updateClinicLogo}
          placeholderImage={placeholderImage}
          size="small"
          inputId={clinicLogoLabelId}
        />
        <DazzedHeading24 className={classes.doctorDetails}>
          {doctor.name} |
          <br />
          <span className={classes.bold}>{practiceDetails.practiceName}</span>
        </DazzedHeading24>
      </div>
    </div>
  )
}

export default HeaderPreviewDetails
