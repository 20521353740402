import { createSelector } from 'reselect'
import { isGPPlan } from 'utils/billingUtils'

const treatmentReducer = state => state.treatmentReducer
const practiceReducer = state => state.practiceReducer

const IRRELEVANT_STATUSES_FOR_GP = ['retention', 'active-treatment']

export const selectStatuses = createSelector(
  [treatmentReducer, practiceReducer],
  (treatmentReducer, practiceReducer) => {
    const { data, isLoading, loadingFailed, isSaving, types } = treatmentReducer.statuses

    const isGP = isGPPlan(practiceReducer.billing.grinPlanKey)

    const filteredData = {
      ...data,
      serviceAccount: isGP
        ? data?.serviceAccount?.filter(
            status => !IRRELEVANT_STATUSES_FOR_GP.some(statusType => status.key.includes(statusType))
          )
        : data?.serviceAccount,
      rm: isGP
        ? data?.rm?.filter(status => !IRRELEVANT_STATUSES_FOR_GP.some(statusType => status.key.includes(statusType)))
        : data?.rm
    }

    return {
      isSaving,
      isLoading,
      loadingFailed,
      data: filteredData,
      types: isGP ? types?.filter(type => !IRRELEVANT_STATUSES_FOR_GP.includes(type)) : types
    }
  }
)
