import { makeStyles } from '@material-ui/styles'
import React, { useMemo, useState } from 'react'
import { isVersionNameValid } from '../../../../utils/releaseNotesUtils'
import BaseSlideDesigner from './BaseSlideDesigner'
import SlideTextareaInput from './SlideTextareaInput'
import SlideTitle from './SlideTitle'
import SlideTitleInput from './SlideTitleInput'

const useStyles = makeStyles({
  content: {
    textAlign: 'center'
  },
  description: {
    fontSize: 16,
    lineHeight: '24px',
    marginBottom: 30
  },
  textLimit: {
    marginBottom: 10,
    color: 'red'
  },
  versionNumberInput: {
    '& input': {
      letterSpacing: '5px'
    }
  }
})

const HeadlineSlideDesigner = ({
  onChange,
  data,
  onMediaPicked,
  isUploading,
  mediaS3Object,
  shouldShowErrors,
  onMediaRemoved,
  editMode,
  slideNumber,
  totalSlides,
  onNextSlide,
  onPreviousSlide,
  onClose
}) => {
  const classes = useStyles()
  const [textLimitReached, setTextLimitReached] = useState(false)

  const versionNumberErrors = useMemo(() => {
    if (!data.version) {
      return 'Required field'
    }

    if (!isVersionNameValid(data.version)) {
      return `Must be a version number format, eg ${process.env.REACT_APP_VERSION}`
    }

    return null
  }, [data.version])

  const handleTextLimitReached = () => {
    setTextLimitReached(true)
    setTimeout(() => {
      setTextLimitReached(false)
    }, 3000)
  }

  const setVersion = value => {
    onChange({
      ...data,
      version: value
    })
  }

  const setDescription = value => {
    if (value.length <= 550 || value.length < data.description.length) {
      onChange({
        ...data,
        description: value
      })
    } else {
      handleTextLimitReached()
    }
  }

  return (
    <BaseSlideDesigner
      isUploading={isUploading}
      isSlideDisabled={data.isHeadlineDisabled}
      onMediaPicked={onMediaPicked}
      contentStyle={{ justifyContent: 'center' }}
      mediaS3Object={mediaS3Object}
      onMediaRemoved={onMediaRemoved}
      editMode={editMode}
      slideNumber={slideNumber}
      totalSlides={totalSlides}
      onNextSlide={onNextSlide}
      onPreviousSlide={onPreviousSlide}
      onClose={onClose}
    >
      {editMode ? (
        <>
          <SlideTitleInput
            value={data.version}
            onChange={setVersion}
            placeholder="Version Number"
            errorMessage={shouldShowErrors && versionNumberErrors}
            className={classes.versionNumberInput}
          />

          <SlideTextareaInput
            value={data.description}
            placeholder="Say something about how awesome this new version is!"
            onChange={setDescription}
          />

          {textLimitReached && (
            <div className={classes.textLimit}>Can't add additional text due to length limit (550)</div>
          )}
        </>
      ) : (
        <div className={classes.content}>
          <SlideTitle>{data.version}</SlideTitle>
          {!!data.description && <div className={classes.description}>{data.description}</div>}
        </div>
      )}
    </BaseSlideDesigner>
  )
}

export default HeadlineSlideDesigner
