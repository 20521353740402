import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from '../../../../common/text/DazzedParagraph12'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 10,
    display: 'flex'
  },
  optional: {
    marginLeft: 5,
    color: 'var(--text-color-12)'
  }
}))

const Title = ({ label, optional }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <DazzedParagraph12>{label}</DazzedParagraph12>
      {optional && <DazzedParagraph12 className={classes.optional}>{`(${t('general.optional')})`}</DazzedParagraph12>}
    </div>
  )
}

export default Title
