import React, { useCallback, useMemo, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { actionTypes } from 'consts/automatedActionConsts'
import SelectInput from 'components/common/SelectInput'
import usePatientsFilterData from 'components/Search/hooks/usePatientsFilterData'

const useStyles = makeStyles({
  dropdown: {
    maxHeight: '350px !important'
  },
  inputValue: {
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
})

const AddPatientTag = ({ onChange, params, errors }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { customTags } = usePatientsFilterData()
  const customTagsWithNeedsScheduling = useMemo(() => [...customTags, { value: 'Needs Scheduling' }], [customTags])
  const errorMessage = useMemo(() => errors.actions?.[actionTypes.AddPatientTag], [errors])

  const [selectedTag, setSelectedTag] = useState(
    params?.tagValue ? customTagsWithNeedsScheduling.find(tag => tag.value === params.tagValue).value : null
  )

  const tagsOptions = useMemo(
    () =>
      customTagsWithNeedsScheduling.map(tag => ({
        label: tag.value,
        value: tag.value
      })),
    [customTagsWithNeedsScheduling]
  )

  const handleTagSelected = useCallback(
    tag => {
      onChange({
        type: actionTypes.AddPatientTag,
        params: {
          tagValue: tag
        }
      })
      setSelectedTag(tag)
    },
    [onChange]
  )

  return (
    <Grid container direction="column">
      <Grid item xs={6}>
        <SelectInput
          required
          searchable
          style={{ bright: true, thick: true }}
          options={tagsOptions}
          value={selectedTag}
          onChange={option => handleTagSelected(option.value)}
          placeholder={t('pages.accountSettings.automations.editorModal.patientTagPlaceholder')}
          keepErrorContainerWhenInactive
          errorMessage={errorMessage}
          selectStyle={{
            border: 0,
            borderBottom: `1px solid ${errorMessage ? 'red' : 'var(--bg-color-66'}`
          }}
          dropdownClassName={classes.dropdown}
          valueClassName={classes.inputValue}
        />
      </Grid>
    </Grid>
  )
}

export default AddPatientTag
