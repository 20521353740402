import * as React from 'react'

function SvgList(props) {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path d="M7 7h13v1a1 1 0 01-1 1H7V7zM7 11h13v1a1 1 0 01-1 1H7v-2zM7 15h13v1a1 1 0 01-1 1H7v-2z" fill="#001335" />
      <rect x={4} y={7} width={2} height={2} rx={1} fill="#001335" />
      <rect x={4} y={11} width={2} height={2} rx={1} fill="#001335" />
      <rect x={4} y={15} width={2} height={2} rx={1} fill="#001335" />
    </svg>
  )
}

export default SvgList
