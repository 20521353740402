import * as React from 'react'

export default () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2L16.5 16.5" stroke="#EB5757" strokeWidth="4" />
      <path d="M16.5 2L2 16.5" stroke="#EB5757" strokeWidth="4" />
    </svg>
  )
}
