import * as React from 'react'

const Clock = ({ className }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.5 17C13.1863 17 17 13.1863 17 8.5C17 3.81367 13.1864 0 8.5 0C3.81363 0 0 3.81367 0 8.5C0 13.1863 3.81367 17 8.5 17ZM8.5 1.13331C12.563 1.13331 15.8667 4.43697 15.8667 8.5C15.8667 12.563 12.563 15.8667 8.5 15.8667C4.43697 15.8667 1.13331 12.563 1.13331 8.5C1.13331 4.43697 4.43702 1.13331 8.5 1.13331Z"
        fill="black"
      />
      <path
        d="M10.9794 11.2087C11.0843 11.2937 11.2089 11.3334 11.3336 11.3334C11.5007 11.3334 11.6651 11.2597 11.7756 11.1209C11.9711 10.8772 11.9314 10.5202 11.6877 10.3247L9.06691 8.22801V3.96668C9.06691 3.65501 8.81192 3.40002 8.50025 3.40002C8.18858 3.40002 7.93359 3.65501 7.93359 3.96668V8.50003C7.93359 8.67288 8.01294 8.83437 8.14609 8.94201L10.9794 11.2087Z"
        fill="black"
      />
    </svg>
  )
}

export default Clock
