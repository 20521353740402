import Actions from 'actions'
import { ScanSummaryRatingOptions } from 'consts/scanSummaryConsts'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'

export default ({ grinScanId, scanNumber, parsedScanSummaryData = {}, analyticsPayload = {} }) => {
  const dispatch = useDispatch()

  const [rating, setRating] = useState('')
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)

  const submitScanSummaryFeedback = useCallback(
    feedbackData => {
      trackEvent('Scan Summary - Feedback Submitted', {
        ...analyticsPayload,
        scanId: grinScanId,
        feedback: rating
      })

      dispatch(
        Actions.scanSummaryReviewed({
          scanId: grinScanId,
          scanNumber,
          feedbackData
        })
      )
    },
    [analyticsPayload, rating, dispatch, grinScanId, scanNumber]
  )

  const setSelectedScanSummaryRating = useCallback(
    rating => {
      setRating(rating)

      if (rating === ScanSummaryRatingOptions.Positive) {
        submitScanSummaryFeedback({ feedback: rating })
      } else {
        setIsFeedbackModalOpen(true)
      }
    },
    [submitScanSummaryFeedback]
  )

  useEffect(() => {
    setRating(parsedScanSummaryData?.feedbackData?.feedBack)
  }, [parsedScanSummaryData])

  return {
    scanSummaryRating: rating,
    isFeedbackModalOpen,
    setSelectedScanSummaryRating,
    submitScanSummaryFeedback,
    setIsFeedbackModalOpen
  }
}
