import * as React from 'react'

const Clock2 = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.80145 8.44715L7.8368 8.48392L7.87286 8.44786L8.43536 7.88535L8.4706 7.85011L8.43547 7.81476L6.4625 5.82938V3.3375V3.2875H6.4125H5.6625H5.6125V3.3375V6.15V6.17014L5.62645 6.18465L7.80145 8.44715ZM4.04269 10.6522C4.65748 10.9174 5.31007 11.05 6 11.05C6.68993 11.05 7.34252 10.9174 7.95731 10.6522C8.57126 10.3872 9.10783 10.0254 9.56661 9.56661C10.0254 9.10783 10.3872 8.57126 10.6522 7.95731C10.9174 7.34252 11.05 6.68993 11.05 6C11.05 5.31007 10.9174 4.65748 10.6522 4.04269C10.3872 3.42874 10.0254 2.89217 9.56661 2.43339C9.10783 1.97462 8.57126 1.61276 7.95731 1.34784C7.34252 1.08256 6.68993 0.95 6 0.95C5.31007 0.95 4.65748 1.08256 4.04269 1.34784C3.42874 1.61276 2.89217 1.97462 2.43339 2.43339C1.97462 2.89217 1.61276 3.42874 1.34784 4.04269C1.08256 4.65748 0.95 5.31007 0.95 6C0.95 6.68993 1.08256 7.34252 1.34784 7.95731C1.61276 8.57126 1.97462 9.10783 2.43339 9.56661C2.89217 10.0254 3.42874 10.3872 4.04269 10.6522ZM8.96464 8.96464C8.14058 9.78871 7.15325 10.2 6 10.2C4.84675 10.2 3.85942 9.78871 3.03536 8.96464C2.21129 8.14058 1.8 7.15325 1.8 6C1.8 4.84675 2.21129 3.85942 3.03536 3.03536C3.85942 2.21129 4.84675 1.8 6 1.8C7.15325 1.8 8.14058 2.21129 8.96464 3.03536C9.78871 3.85942 10.2 4.84675 10.2 6C10.2 7.15325 9.78871 8.14058 8.96464 8.96464Z"
        fill="#001335"
        stroke="#001335"
        strokeWidth="0.1"
      />
    </svg>
  )
}

export default Clock2
