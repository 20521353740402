import { makeStyles, TableRow } from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { PATIENT_CARD_TABS } from 'consts/patientCard'
import { ActionRequiredCell, LastUpdateCell, PatientDetailsCell, ProgramCell } from './Cells'
import useTasks from 'hooks/useTasks'
import StatusCell from './Cells/StatusCell'

const useStyles = () =>
  makeStyles({
    root: {
      backgroundColor: 'var(--bg-color-10)'
    }
  })()

const PatientsTableRow = ({ index, patientSM }) => {
  const dispatch = useDispatch()

  const actionRequired = useMemo(() => patientSM?.lastActionItemStatus === 'open', [patientSM])
  const classes = useStyles()
  const { data: statuses } = useSelector(state => state.treatmentReducer.statuses)

  const { currentTask } = useTasks({
    actionItems: patientSM?.patient.actionItems?.items,
    lastActionItemType: patientSM?.lastActionItemType,
    patientName: patientSM.name,
    patientId: patientSM.patientId,
    blueIcon: true
  })

  const openPatientCard = useCallback(
    selectedTab => {
      dispatch(
        Actions.togglePatientCard({
          isModalOpen: true,
          patientId: patientSM.id,
          selectedTab
        })
      )
    },
    [dispatch, patientSM.id]
  )

  const handlePatientDetailsClick = useCallback(() => {
    openPatientCard(
      patientSM?.program === 'ortho' ? PATIENT_CARD_TABS.timeline.index : PATIENT_CARD_TABS.scanSettings.index
    )
  }, [patientSM, openPatientCard])

  const programStatuses = useMemo(() => statuses[patientSM.program] || [], [statuses, patientSM.program])
  const status = useMemo(
    () => programStatuses.find(option => option.key === patientSM.statusKey),
    [programStatuses, patientSM.statusKey]
  )

  return (
    <TableRow classes={{ root: classes.root }}>
      <PatientDetailsCell name={patientSM.name} photo={patientSM.profilePicture} onClick={handlePatientDetailsClick} />
      <ProgramCell program={patientSM.program} />
      <StatusCell status={status} />
      <ActionRequiredCell
        actionRequired={actionRequired}
        label={currentTask.label}
        IconComponent={currentTask.Icon}
        onClick={currentTask.handleClick}
        disabled={currentTask.disabled}
        subtitle={currentTask.subtitle}
      />
      <LastUpdateCell date={patientSM.lastInteractionTimestamp} />
      {/* <OptionsCell patient={patientSM.patient} /> */}
    </TableRow>
  )
}

export default React.memo(PatientsTableRow)
