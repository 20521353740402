import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

export default ({ grinUserId, metadata, isAdmin }) => {
  const doctor = useSelector(state => state.profileReducer.doctor)
  const patient = useSelector(state => state.patientsReducer.patient)
  const practiceMembers = useSelector(state => state.profileReducer.practiceMembers.data)

  const messageSender = useMemo(
    () => (Array.isArray(practiceMembers) ? practiceMembers.find(member => member.grinUserId === grinUserId) : null),
    [grinUserId, practiceMembers]
  )

  return useMemo(() => {
    const senderData = JSON.parse(metadata || '{}')?.senderData

    if (grinUserId === patient.user?.id) {
      return {
        photo: patient.photo,
        name: patient.details?.name,
        email: patient.details?.email,
        userType: patient.user?.type
      }
    }

    if (!isEmpty(senderData)) {
      return {
        photo: senderData.photo,
        name: senderData.name,
        email: senderData.email,
        userType: senderData.roleDescription
      }
    }

    if (grinUserId === doctor.user.id) {
      return {
        photo: doctor.photo,
        name: doctor.name,
        email: doctor.email,
        userType: doctor.roleDescription
      }
    }

    if (!!messageSender) {
      return {
        photo: messageSender.avatar,
        name: messageSender.name,
        email: messageSender.email,
        userType: messageSender.roleDescription
      }
    }

    if (isAdmin && grinUserId === patient.doctor?.user?.id) {
      return {
        photo: patient.doctor.photo,
        name: patient.doctor.name,
        email: patient.doctor.email,
        userType: patient.doctor.roleDescription
      }
    }

    return { photo: null, name: patient.doctor?.name }
  }, [doctor, metadata, patient, grinUserId, isAdmin, messageSender])
}
