import { upsertItem } from '../utils/arrayUtils'
import i18n from 'resources/locales/i18n'

const tagTypesOrder = {
  authStatus: 1,
  program: 2,
  treatment: 3,
  asignee: 4,
  scan: 5,
  custom: 6
}

export const programTagsOrder = {
  rm: 1,
  rc: 2,
  ortho: 3
}

export const treatmentTypeTagsOrder = {
  Other: 1,
  Aligners: 2,
  Braces: 3
}

export const TAGS_LIST_COMPRESSION_THRESHOLD = 120
export const TAGS_LIST_COMPRESSION_THRESHOLD_DENSE = 100

export const isCustomTag = tag => {
  return tag.type === 'custom'
}

export const createPracticeMemberTagValue = practiceMember => {
  const role = practiceMember.roleDescription ? `(${practiceMember.roleDescription})` : ''
  return `${practiceMember.name} ${
    practiceMember.status === 'disabled' ? `(${i18n.t('statuses.names.deactivated')})` : role
  }`.trim()
}

export const compareTags = (tag1, tag2) => {
  const tag1Order = tagTypesOrder[tag1.type] || Infinity
  const tag2Order = tagTypesOrder[tag2.type] || Infinity

  if (tag1Order === tag2Order) {
    return tag1.value.localeCompare(tag2.value)
  }

  return tag1Order - tag2Order
}

export const removePatientTagFromPatient = (patient, tagValue) => {
  return {
    ...patient,
    patientTags: {
      ...patient.patientTags,
      items: patient.patientTags.items.filter(patientTag => patientTag.tag.value !== tagValue)
    }
  }
}

export const updateDoctorTagCounter = (doctor, tagValue, amount) => {
  return {
    ...doctor,
    tags: {
      ...doctor.tags,
      items: doctor.tags?.items.map(tag =>
        tag.value !== tagValue
          ? tag
          : {
              ...tag,
              count: tag.count + amount
            }
      )
    }
  }
}

export const addTagIfAbsent = (doctor, tag) => {
  if (!!doctor.tags?.items.find(existingTag => !existingTag._deleted && existingTag.value === tag.value)) {
    return doctor
  }

  return {
    ...doctor,
    tags: {
      ...doctor.tags,
      items: [...doctor.tags.items, tag]
    }
  }
}

export const upsertPatientTag = (patient, patientTag, str) => {
  return patient && !!Object.keys(patient).length
    ? {
        ...patient,
        patientTags:
          patient?.id === patientTag.patientId
            ? {
                ...patient.patientTags,
                items: upsertItem({
                  array: patient.patientTags.items,
                  item: {
                    patientTagPatientId: patient.id,
                    tag: {
                      type: patientTag.assigneeId ? 'assignee' : 'custom',
                      value: patientTag.tag
                    },
                    metadata: patientTag.assigneeId
                      ? JSON.stringify({
                          assigneeId: patientTag.assigneeId,
                          unreadIndication: !!patientTag.note
                        })
                      : null,
                    _deleted: false
                  },
                  mergeOldProperties: true,
                  condition: item =>
                    JSON.parse(item.metadata || '{}').assigneeId && patientTag.assigneeId && !item._deleted
                })
              }
            : patient?.patientTags
      }
    : {}
}
