import React from 'react'
import Loader from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core'

const useLoaderStyles = makeStyles({
  loader: {
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    color: 'var(--text-color-6)',
    paddingTop: 20,
    fontSize: 15,
    fontFamily: 'Dazzed, Arial'
  }
})

const AppLoader = () => {
  const classes = useLoaderStyles()

  return (
    <Loader open={false} className={classes.loader} style={{ zIndex: 10000 }}>
      <CircularProgress />
      <span className={classes.label}>Loading...</span>
    </Loader>
  )
}

export default AppLoader
