import * as React from 'react'
const TrashIcon = ({ color = '#4251E7', width = 12, height = 13 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 4.5H9.5V9.5C9.5 10.6046 8.60457 11.5 7.5 11.5H4.5C3.39543 11.5 2.5 10.6046 2.5 9.5V4.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.5 2H10.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.70313 0.4453C3.8886 0.167101 4.20083 0 4.53518 0H7.46482C7.79917 0 8.1114 0.167101 8.29687 0.4453L9 1.5H3L3.70313 0.4453Z"
        fill={color}
      />
    </svg>
  )
}

export default TrashIcon
