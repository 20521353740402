import * as React from 'react'

const ScanSettings = ({ color = '#344563', ...props }) => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="6.5" cy="6.5" r="6.5" fill={color} />
      <path d="M6.5 3V7H10" stroke="white" />
    </svg>
  )
}

export default ScanSettings
