import * as React from 'react'

function Account(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className ?? ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill={props.fill ?? '#727B8C'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12C11.6569 12 13 10.6569 13 9C13 7.34315 11.6569 6 10 6C8.34315 6 7 7.34315 7 9C7 10.6569 8.34315 12 10 12ZM10 13C12.2091 13 14 11.2091 14 9C14 6.79086 12.2091 5 10 5C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13Z"
        fill={props.fill ?? '#727B8C'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 14.5C7.40484 14.5 5.5 16.1654 5.5 18H4.5C4.5 15.4164 7.07232 13.5 10 13.5C12.9277 13.5 15.5 15.4164 15.5 18H14.5C14.5 16.1654 12.5952 14.5 10 14.5Z"
        fill={props.fill ?? '#727B8C'}
      />
    </svg>
  )
}

export default Account
