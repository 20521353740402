import React, { useEffect } from 'react'
import { makeStyles, Modal, Grow } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import { useSelector, useDispatch } from 'react-redux'
import Actions from '../../../actions'
import CloseIcon from '../../common/icons/Close'
import { useTranslation } from 'react-i18next'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { isBoolean } from 'lodash'
import { WELCOME_DOCTOR_MODAL_Z_INDEX } from 'consts/modalConsts'

const useStyles = makeStyles({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: `${WELCOME_DOCTOR_MODAL_Z_INDEX} !important`,
    '& .MuiBackdrop-root': {
      background: '#f5f5f590'
    }
  },
  container: {
    position: 'relative',
    padding: 20,
    outline: 'none',
    background: '#FFFFFF',
    boxShadow: '0px 10px 100px rgba(0, 0, 0, 0.07)',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      margin: '20px 0'
    }
  },
  title: {
    fontFamily: 'Ogg',
    fontSize: 40
  },
  subTitle: {
    fontSize: 16,
    marginBottom: 10
  },
  videoContainer: {
    margin: 10,
    borderRadius: 20,
    overflow: 'hidden',
    position: 'relative',
    width: 652,
    height: 366
  },
  video: {
    outline: 'none',
    position: 'relative',
    width: 652,
    height: 366
  },
  closeBtn: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer'
  }
})

const WelcomeDoctorModal = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id: doctorId, name: doctorName } = useSelector(state => state.profileReducer.doctor)
  const isOpen = useSelector(state => state.profileReducer.showWelcomePopup)
  const { t } = useTranslation()
  const { isWelcomePopupShowed } = useFeatureFlags()

  useEffect(() => {
    isBoolean(isWelcomePopupShowed) && !isWelcomePopupShowed && dispatch(Actions.setWelcomePopupState(true))
  }, [dispatch, isWelcomePopupShowed])

  const handleModalClose = () => {
    dispatch(Actions.setWelcomePopupState(false))
  }

  return doctorId ? (
    <div>
      <Modal
        className={classes.modalContainer}
        open={isOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Grow in={isOpen}>
          <div className={classes.container}>
            <CloseIcon className={classes.closeBtn} color="black" width={40} height={40} onClick={handleModalClose} />
            <p className={classes.title}>
              {t('dialogs.welcomeDoctor.title')}, {doctorName}
            </p>
            <span className={classes.subTitle}>{t('dialogs.welcomeDoctor.subtitle')}</span>
            <div className={classes.videoContainer}>
              <video controls preload="" className={classes.video}>
                <source src={'https://d1g4lo0dz8n4nh.cloudfront.net/tutorials/doctorwellcome.mp4'} type="video/mp4" />
              </video>
            </div>
          </div>
        </Grow>
      </Modal>
    </div>
  ) : null
}

export default WelcomeDoctorModal
