import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import ScanFeedCard from './ScanFeedCard'
import { trackEvent } from 'utils/analyticsUtils'
import ScanView from 'components/common/ScanView/ScanView'
import { IMAGE_MODES } from 'consts/scanSummaryConsts'
import usePreventive from 'components/Patients/Timeline/ScanSummary/Preventive/usePreventive'
import ToggleAlignersButton from './ToggleAlignersButton'
import ScanSummarySharpenButton from 'components/Patients/Timeline/ScanSummary/ScanSummaryModal/ScanSummarySharpenButton'
import useFeatureFlags from 'hooks/useFeatureFlags'
import useScanSummaryImageActions from './useScanSummaryImageActions'
import useDownloadScanSummaryImages from './useDownloadScanSummaryImages'
import useScanSummaryFeedback from './useScanSummaryFeedback'
import ScanSummaryFeedbackModal from './ScanSummaryFeedbackModal'

const useStyles = makeStyles(theme => ({
  grinScanCard: {
    position: 'relative',
    padding: '0px!important'
  },
  scanViewRoot: {
    padding: '0!important'
  },
  scanViewActionsContainer: {
    padding: `10px!important`
  },
  media: {
    borderRadius: '16px 16px 0 0!important',
    width: '100%',
    padding: '0px!important',
    marginBottom: '0!important'
  },
  alignersToggleContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 999
  },
  optionMediaClassName: {
    borderRadius: 10
  },
  selectedOptionClassName: {
    borderRadius: '10px!important'
  },
  unselectedOptionClasName: {
    opacity: '1!important'
  }
}))

/**
 * @param videoS3Object `{ region, bucket, key }`
 * @param scanSummaryStatus see `ScanSummaryStatus`
 * @param scanSummaryData the raw `scanSummaryData` json from the GrinScan
 * @param setWithAligners Optional - a callback that's fired when users toggle whether to view the scan with our without aligners. If null, the toggle button is hidden. Called with a boolean value.
 */
const GrinScanCard = ({
  patientName,
  scanNumber,
  grinScanId,
  videoS3Object = {},
  scanSummaryStatus,
  scanSummaryData = '{}',
  withAligner,
  setWithAligners,
  analyticsPayload = {}
}) => {
  const classes = useStyles()
  const { scanSummaryPreventive: preventiveFF } = useFeatureFlags()

  const [displayedTileIndex, setDisplayedTileIndex] = useState(0)
  const [sharpeningEnabled, setSharpeningEnabled] = useState(true)
  const [imagesMode, setImagesMode] = useState(IMAGE_MODES.sharpened)
  const [selectedPoses, setSelectedPoses] = useState([])

  const isDisplayingImage = useMemo(() => displayedTileIndex > 0, [displayedTileIndex])
  const parsedScanSummaryData = useMemo(() => JSON.parse(scanSummaryData), [scanSummaryData])
  const normalImages = useMemo(
    () => Object.entries(parsedScanSummaryData?.[IMAGE_MODES.normal] || {}),
    [parsedScanSummaryData]
  )

  const defaultImageMode = useMemo(
    () => (sharpeningEnabled ? IMAGE_MODES.sharpened : IMAGE_MODES.normal),
    [sharpeningEnabled]
  )

  const {
    preventiveLayers,
    displayedPreventiveLayers,
    isAnyPreventiveLayerVisible,
    togglePreventiveLayer,
    toggleAllPreventiveLayers,
    clearActiveLayers
  } = usePreventive({
    imagesMode,
    setImagesMode,
    defaultImageMode
  })

  const downloadScanSummaryImages = useDownloadScanSummaryImages({
    displayedPreventiveLayers,
    patientName,
    scanNumber,
    imagesMode,
    parsedScanSummaryData,
    selectedPoses,
    editedImages: [] // Currently no option to draw, will be added later
  })

  const scanSummaryImageActions = useScanSummaryImageActions({
    parsedScanSummaryData,
    isAnyPreventiveLayerVisible,
    analyticsPayload,
    displayedPreventiveLayers,
    selectedPoses,
    imagesMode,
    editedImages: [], // Currently no option to draw, will be added later
    downloadScanSummaryImages
  })

  const {
    scanSummaryRating,
    setSelectedScanSummaryRating,
    submitScanSummaryFeedback,
    isFeedbackModalOpen,
    setIsFeedbackModalOpen
  } = useScanSummaryFeedback({ grinScanId, scanNumber, analyticsPayload, parsedScanSummaryData })

  const handleVideoPlay = useCallback(() => {
    trackEvent(`Scan - scan video played`, analyticsPayload)
  }, [analyticsPayload])

  const handleToggleWithAligners = useCallback(() => {
    const newValue = !withAligner
    setWithAligners(newValue)
    trackEvent('Scan - toggle aligners button clicked', {
      ...analyticsPayload,
      newValue: newValue ? 'With Aligners' : 'Without Aligners'
    })
  }, [withAligner, analyticsPayload, setWithAligners])

  const handleToggleSharpening = useCallback(
    isEnabled => {
      setSharpeningEnabled(isEnabled)
      trackEvent('Scan - toggle sharpning clicked', {
        ...analyticsPayload,
        isEnabled
      })
    },
    [analyticsPayload]
  )

  return (
    <>
      <ScanFeedCard className={classes.grinScanCard}>
        {setWithAligners && (
          <div className={classes.alignersToggleContainer}>
            <ToggleAlignersButton withAligners={withAligner} onClick={handleToggleWithAligners} />
          </div>
        )}
        {isDisplayingImage && (
          <ScanSummarySharpenButton
            isImageAvailable
            sharpenedImages={sharpeningEnabled}
            setSharpenedImages={handleToggleSharpening}
          />
        )}
        <div className={classes.grinScanCardBody}>
          <ScanView
            grinScanVideo={videoS3Object}
            scanSummaryStatus={scanSummaryStatus}
            scanSummaryData={scanSummaryData}
            selectedOptionIndex={displayedTileIndex}
            onOptionSelect={setDisplayedTileIndex}
            mediaClassName={classes.media}
            defaultImagesMode={defaultImageMode}
            hidePreventive={!preventiveFF}
            preventiveLayers={preventiveLayers}
            displayedPreventiveLayers={displayedPreventiveLayers}
            isAnyPreventiveLayerVisible={isAnyPreventiveLayerVisible}
            onTogglePreventiveLayer={togglePreventiveLayer}
            onToggleAllPreventiveLayers={toggleAllPreventiveLayers}
            onClearActiveLayers={clearActiveLayers}
            onPlay={handleVideoPlay}
            rootClasName={classes.scanViewRoot}
            actionsContainerClassName={classes.scanViewActionsContainer}
            optionMediaClassName={classes.optionMediaClassName}
            selectedOptionClassName={classes.selectedOptionClassName}
            unselectedOptionClasName={classes.unselectedOptionClasName}
            withPosesSelection
            selectedPoses={selectedPoses}
            setSelectedPoses={setSelectedPoses}
            scanSummaryImageActions={scanSummaryImageActions}
            withScanSummaryRating
            scanSummaryRating={scanSummaryRating}
            setScanSummaryRating={setSelectedScanSummaryRating}
            preventivePanelProps={{ fontSize: '14px', legendItemXs: 6 }}
          />
        </div>
      </ScanFeedCard>
      <ScanSummaryFeedbackModal
        images={normalImages}
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
        onSubmit={submitScanSummaryFeedback}
      />
    </>
  )
}

export default React.memo(GrinScanCard)
