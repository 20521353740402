import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import { Grid, Link } from '@material-ui/core'
import OggHeading40 from 'components/common/text/OggHeading40'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import GrinLabel from '../common/text/GrinLabel'
import images from '../../resources'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '5%'
  },
  image: {
    marginLeft: '-10px',
    marginTop: '10px'
  },
  detailsSection: {
    paddingBottom: '20px'
  },
  detailsHeader: {
    fontWeight: 'bold'
  },
  preLine: {
    whiteSpace: 'pre-line'
  },
  patentsLinkLabel: {
    fontSize: 14
  }
})

const About = ({ history, location }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { publicFilesUrl = 'https://d2jbg67iqeb510.cloudfront.net' } = useSelector(state => state.appReducer.appconfig)

  return (
    <Grid container className={classes.root} spacing={3}>
      <Grid item xs={12} id="welcomeAnimation">
        <OggHeading40 textAlign="left">{t('pages.about.title')}</OggHeading40>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DazzedParagraph16>{`v.${process.env.REACT_APP_VERSION}`}</DazzedParagraph16>
          </Grid>
          <Grid item xs={12}>
            <DazzedParagraph16>{t('pages.about.uniqueIdentifier')}: D962GGW00001</DazzedParagraph16>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <GrinLabel>{t('pages.about.instructions')}</GrinLabel>
          </Grid>
          <Grid item xs={isMobile() ? 12 : 2}>
            <Grid container>
              <Grid item xs={12}>
                <img src={images.quickGuideInstructions} alt={'Quick start guide'} className={classes.image} />
              </Grid>
              <Grid item xs={12}>
                <Link href={`${publicFilesUrl}/Grin_Getting_Started_Guide.pdf`} underline="always" color="#4151E6">
                  {t('pages.about.quickStartGuide')}
                </Link>
                {' (897 KB)'}
              </Grid>
              <Grid item xs={12}>
                {t('pages.about.language')}: EN
              </Grid>
              <Grid item xs={12}>
                {t('pages.about.devices')}: Grin Scope / Scope Mini
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={isMobile() ? 12 : 3}>
            <Grid container>
              <Grid item xs={12}>
                <img src={images.InstructionForUse} alt={'Instruction for use'} className={classes.image} />
              </Grid>
              <Grid item xs={12}>
                <Link href={`${publicFilesUrl}/Instructions_for_use_(IFU).pdf`} underline="always" color="#4151E6">
                  {t('pages.about.IFU')}
                </Link>
                {' (2.53 MB)'}
              </Grid>
              <Grid item xs={12}>
                {t('pages.about.language')}: EN, ES, FR, DE, NL
              </Grid>
              <Grid item xs={12}>
                {t('pages.about.devices')}: Grin Scope / Scope Mini
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column" className={classes.detailsSection}>
          <Grid item className={classes.detailsHeader}>
            {t('pages.about.needHelp')}
          </Grid>
          <Grid item xs={12} className={classes.preLine}>
            {t('pages.about.needHelpContent')}
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.detailsSection}>
          <Grid item className={classes.detailsHeader}>
            {t('pages.about.EAR')}
          </Grid>
          <Grid item xs={12}>
            {t('pages.about.EARdetails1')}
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.detailsSection}>
          <Grid item className={classes.detailsHeader}>
            {t('pages.about.patentNotice.title')}
          </Grid>
          <Grid item xs={12} className={classes.preLine}>
            {t('pages.about.patentNotice.description1')}
          </Grid>
          <Grid item>
            {t('pages.about.patentNotice.see')}
            <a
              href="https://get-grin.com/patents"
              target="_blank"
              className={classes.patentsLinkLabel}
              rel="noreferrer"
            >
              {t('pages.about.patentNotice.buttonLabel')}
            </a>
            {t('pages.about.patentNotice.description2')}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default About
