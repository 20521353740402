import { API, graphqlOperation, Storage } from 'aws-amplify'
import { getRoomForResolve } from 'graphql/customQueries'
import { updateRoom } from 'graphql/mutations'
import moment from 'moment'
import { pullFirst } from './arrayUtils'
import { getLastPathItem } from './stringUtils'
import { withS3Details } from './storageUtils'

const calcMessageText = message =>
  message.type === 'text' || message.type === 'support'
    ? message.content
    : message.type === 'scan' || message.type === 'doubleScan'
    ? '#New Scan Uploaded'
    : message.type === 'scanReview'
    ? '#New Scan Review Uploaded'
    : message.type === 'promotion'
    ? '#New Recommendation Sent'
    : '#New Photo Uploaded'

export const updateRoomLastMessage = ({ rooms, message }) =>
  rooms.map(room =>
    room.roomId === message.roomId
      ? {
          ...room,
          lastMessagePreview: calcMessageText(message),
          lastMessageTimestamp: message.uploadingDate ?? message.createdAt,
          isChatRoomResolved: message.isLocal || room.isChatRoomResolved
        }
      : room
  )

export const bringRoomToTop = (rooms, roomId) => {
  let updatedRooms = [...rooms]
  const room = pullFirst(updatedRooms, room => room.roomId === roomId)
  if (room) {
    updatedRooms.unshift(room)
  }
  return updatedRooms
}

export const searchPatientsInRooms = (filter, rooms) => {
  const lowerCased = filter?.toLowerCase().trim() || ''
  return rooms.filter(
    room =>
      room.patient.details.name?.toLowerCase().includes(lowerCased) ||
      room.patient.details.email?.toLowerCase().includes(lowerCased)
  )
}

export const sortRoomsByLastMessageTimestamp = rooms =>
  rooms.sort((roomA, roomB) =>
    moment(roomA.lastMessageTimestamp || '1970-01-01').isBefore(moment(roomB.lastMessageTimestamp || '1970-01-01'))
      ? 1
      : -1
  )

export const upsertMessagesList = (messages, newMessage, reverse = true) => {
  let messageIndex

  if (newMessage.type === 'newScanReview') {
    messageIndex = messages.findIndex(
      m =>
        m.type === 'newScanReview' &&
        m.isLocal &&
        getLastPathItem(m.s3UniqueId) === getLastPathItem(JSON.parse(newMessage.content)?.key)
    )
  } else {
    messageIndex = messages.findIndex(m => m.id === newMessage.id)
  }

  let mergedMessages = [...messages]

  if (messageIndex !== -1) {
    mergedMessages[messageIndex] = newMessage
  } else {
    mergedMessages = reverse ? [newMessage].concat(messages) : messages.concat(newMessage)
  }

  return mergedMessages
}

export const doesRoomMatchTagFilter = (room, tagsFilter) => {
  if (!tagsFilter?.length) {
    return true
  }

  const patientUser = room.users.items.find(url => url.grinUserId === room.patient.user.id).user
  const patientTags =
    patientUser?.patient?.patientTags?.items
      .filter(patientTag => patientTag.tag && !patientTag._deleted)
      .map(patientTag => patientTag.tag.value) || []
  return tagsFilter.every(tag => patientTags.includes(tag))
}

export const generateDummyRoom = ({ patientEmail: email, patientName: name }) => ({
  isLoading: true,
  isDummyRoom: true,
  lastMessageTimestamp: new Date().toISOString(),
  email,
  name,
  program: 'rm',
  username: 'dummy',
  doctorName: '',
  isChatRoomResolved: true,
  patient: {
    user: {
      isActive: true
    },
    username: 'dummy',
    details: { name, email },
    doctor: { name: '' }
  }
})

export const handleUploadingLocalMessages = (uploadingMessages, message) => {
  if (message.type === 'scanReview' || message.type === 'newScanReview') {
    if (message.isLocal) {
      return [...uploadingMessages, message]
    } else {
      return uploadingMessages.filter(
        m =>
          !(
            (m.type === 'scanReview' || m.type === 'newScanReview') &&
            m.isLocal &&
            getLastPathItem(m.s3UniqueId) === getLastPathItem(JSON.parse(message.content)?.key)
          )
      )
    }
  }
  return message.isLocal ? [...uploadingMessages, message] : uploadingMessages.filter(msg => msg.id !== message.id)
}

export const insertLocalMessages = (messages, uploadingMessages, activeRoomId) => {
  const currentRoomUploadingMessages = uploadingMessages.filter(message => message.roomId === activeRoomId)
  return currentRoomUploadingMessages.length ? [...messages, ...currentRoomUploadingMessages] : messages
}

export const updateRoomsWithUnreadMessages = (rooms, fetchedRooms) => {
  const newRooms = fetchedRooms.map(room => {
    const oldRoom = rooms.find(oldRoom => oldRoom.id === room.id)
    return {
      ...room,
      unreadMessages: oldRoom?.unreadMessages || []
    }
  })
  return [...rooms.filter(room => room.isDummyRoom), ...newRooms]
}

export const setChatRoomResolved = async ({ roomId, isResolved }) => {
  const room = (
    await API.graphql(
      graphqlOperation(getRoomForResolve, {
        id: roomId
      })
    )
  ).data.getRoom

  if (room.isChatRoomResolved === isResolved) {
    return
  }

  await API.graphql(
    graphqlOperation(updateRoom, {
      input: {
        id: roomId,
        _version: room._version,
        isChatRoomResolved: isResolved
      }
    })
  )
}

export const uploadMessageWithMedia = async ({ messageInput, s3Key, scheduleTime }) => {
  if (messageInput.type === 'image' || messageInput.type === 'pdf' || messageInput.type === 'video') {
    await Storage.put(s3Key, messageInput.content, {
      contentType: messageInput.contentType
    })
    messageInput.content = JSON.stringify(withS3Details(s3Key))
  }

  return API.post('grinServerlessApi', '/chat/v1/messages', {
    body: {
      messageInput,
      scheduleTime
    }
  })
}
