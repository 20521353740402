import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import FormInput from 'components/common/FormInput'
import DropzoneFilesInput from 'components/common/inputs/DropzoneFilesInput'
import BaseModal from 'components/common/modals/BaseModal'
import SelectInput from 'components/common/SelectInput'
import { v4 as uuidv4 } from 'uuid'
import {
  LowerArch,
  UpperAndLowerArches,
  UpperArch,
  LeftBite,
  RightBite,
  UploadedStlPlaceholder
} from 'components/common/icons'
import UploadConfirmationModal from './UploadConfirmationModal'
import { getScannerType, setScannerType } from 'utils/storageUtils'
import { getUploadedFiles } from 'utils/patientUtils'
import {
  UPPER_ARCH_FILENAME_PREFIX,
  LOWER_ARCH_FILENAME_PREFIX,
  UPPER_LOWER_ARCHES_FILENAME_PREFIX,
  LEFT_BITE_FILENAME_PREFIX,
  RIGHT_BITE_FILENAME_PREFIX
} from 'consts/patientCard'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 1160,
    minHeight: 670
  },
  title: {
    justifyContent: 'center'
  },
  scannerTypeWrapper: {
    width: '50%',
    margin: '0 auto'
  },
  scannerDropdown: {
    marginBottom: 63
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  fileInput: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 180,
    height: 220,
    margin: '30px 0',
    fontSize: 16,
    borderRadius: 0
  },
  scannerTypeInput: {
    marginTop: 0
  },
  archIcon: {},
  dropzoneLabel: {
    color: 'var(--text-color-3)',
    textDecoration: 'underline'
  },
  emptyState: {
    margin: 20,
    textAlign: 'center'
  }
}))

const UploadStlFilesModal = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { list } = useSelector(state => state.treatmentReducer.scannerTypes)
  const { isSaving } = useSelector(state => state.treatmentReducer.uploadStl)
  const doctorUsername = useSelector(state => state.profileReducer.doctor.username)
  const patient = useSelector(state => state.patientsReducer.patient)
  const isOpen = useSelector(state => state.patientsReducer.uploadStlFilesModal.isModalOpen)
  const { selectedRecord } = useSelector(state => state.patientsReducer.patientCard.scans)

  const [selectedScannerType, setSelectedScannerType] = useState('')
  const [scannerValue, setScannerValue] = useState('')
  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState(false)
  const [isUploadConfirmationModalOpen, setIsUploadConfirmationModalOpen] = useState(false)
  const [files, setFiles] = useState({
    upperArchFile: [],
    lowerArchFile: [],
    upperAndLowerArchesFile: [],
    leftBiteFile: [],
    rightBiteFile: []
  })

  const { upperArchFile, lowerArchFile, upperAndLowerArchesFile, leftBiteFile, rightBiteFile } = files

  const uploadedFiles = useMemo(() => getUploadedFiles(selectedRecord), [selectedRecord])

  const dropzones = useMemo(() => {
    return [
      {
        label: t('pages.patients.selectedPatient.uploadStlsModal.upperArchInputLabel'),
        IconComponent: UpperArch,
        onChange: value => setFiles({ ...files, upperArchFile: value }),
        files: upperArchFile,
        errorMessage: errors.upperArchFile,
        filenamePrefix: UPPER_ARCH_FILENAME_PREFIX
      },
      {
        label: t('pages.patients.selectedPatient.uploadStlsModal.lowerArchInputLabel'),
        IconComponent: LowerArch,
        onChange: value => setFiles({ ...files, lowerArchFile: value }),
        files: lowerArchFile,
        errorMessage: errors.lowerArchFile,
        filenamePrefix: LOWER_ARCH_FILENAME_PREFIX
      },
      {
        label: t('pages.patients.selectedPatient.uploadStlsModal.upperAndLowerArchesInputLabel'),
        IconComponent: UpperAndLowerArches,
        onChange: value => setFiles({ ...files, upperAndLowerArchesFile: value }),
        files: upperAndLowerArchesFile,
        errorMessage: errors.upperAndLowerArchesFile,
        filenamePrefix: UPPER_LOWER_ARCHES_FILENAME_PREFIX
      },
      {
        label: t('pages.patients.selectedPatient.uploadStlsModal.rightBiteInputLabel'),
        IconComponent: RightBite,
        onChange: value => setFiles({ ...files, rightBiteFile: value }),
        files: rightBiteFile,
        errorMessage: errors.rightBiteFile,
        filenamePrefix: RIGHT_BITE_FILENAME_PREFIX
      },
      {
        label: t('pages.patients.selectedPatient.uploadStlsModal.leftBiteInputLabel'),
        IconComponent: LeftBite,
        onChange: value => setFiles({ ...files, leftBiteFile: value }),
        files: leftBiteFile,
        errorMessage: errors.leftBiteFile,
        filenamePrefix: LEFT_BITE_FILENAME_PREFIX
      }
    ]
  }, [
    t,
    errors.lowerArchFile,
    errors.upperArchFile,
    errors.upperAndLowerArchesFile,
    errors.leftBiteFile,
    errors.rightBiteFile,
    lowerArchFile,
    upperArchFile,
    upperAndLowerArchesFile,
    leftBiteFile,
    rightBiteFile,
    files
  ])

  const guid = useMemo(() => uuidv4(), [])

  const otherOption = useMemo(() => t('pages.patients.selectedPatient.uploadStlsModal.otherOption'), [t])
  const isOtherOption = useMemo(() => selectedScannerType === otherOption, [selectedScannerType, otherOption])

  const validateForm = useCallback(() => {
    const updatedErrors = {}

    if (!scannerValue) {
      updatedErrors.scannerValue = t('errors.requiredField')
    }

    setErrors(updatedErrors)
    return isEmpty(updatedErrors)
  }, [scannerValue, t])

  const readyToUploadFiles = useMemo(
    () =>
      dropzones.length -
      isEmpty(upperArchFile) -
      isEmpty(lowerArchFile) -
      isEmpty(upperAndLowerArchesFile) -
      isEmpty(leftBiteFile) -
      isEmpty(rightBiteFile),
    [upperArchFile, lowerArchFile, upperAndLowerArchesFile, leftBiteFile, rightBiteFile, dropzones.length]
  )

  const isMoreThanOneReadyToUpload = useMemo(() => readyToUploadFiles > 1, [readyToUploadFiles])

  const isPrimaryDisabled = useMemo(() => readyToUploadFiles === 0, [readyToUploadFiles])

  const handleChange = useCallback(
    ({ value }) => {
      setSelectedScannerType(value)
      setScannerValue(value === otherOption ? '' : value)
    },
    [otherOption]
  )

  const handleClose = useCallback(() => {
    trackEvent('stl modal - modal closed')
    dispatch(Actions.toggleUploadStlFilesModal({ isModalOpen: false }))
  }, [dispatch])

  const handleUpload = useCallback(() => {
    const files = [...upperArchFile, ...lowerArchFile, ...upperAndLowerArchesFile, ...leftBiteFile, ...rightBiteFile]
    trackEvent('stl modal - upload files', files.length)
    setScannerType(scannerValue)
    if (selectedRecord?.id) {
      dispatch(
        Actions.uploadStlFiles({
          scannerType: scannerValue,
          files
        })
      )
    } else {
      dispatch(
        Actions.uploadInitialStlFiles({
          patientId: patient?.id,
          patientUsername: patient?.username,
          doctorUsername,
          scannerType: scannerValue,
          files
        })
      )
    }
  }, [
    dispatch,
    selectedRecord?.id,
    patient?.id,
    patient?.username,
    doctorUsername,
    scannerValue,
    upperArchFile,
    lowerArchFile,
    upperAndLowerArchesFile,
    leftBiteFile,
    rightBiteFile
  ])

  const handleSubmit = useCallback(() => {
    if (isSaving) return
    setTouched(true)
    const isValidForm = validateForm()

    if (!isValidForm) return
    isMoreThanOneReadyToUpload ? handleUpload() : setIsUploadConfirmationModalOpen(true)
  }, [isSaving, isMoreThanOneReadyToUpload, validateForm, handleUpload])

  const handleError = useCallback(({ message }) => setErrors(errors => ({ ...errors, files: message })), [])

  const clearState = useCallback(() => {
    setTouched(false)
    setErrors({})
    setScannerValue('')
    setSelectedScannerType('')
    setFiles({
      upperArchFile: [],
      lowerArchFile: [],
      upperAndLowerArchesFile: [],
      leftBiteFile: [],
      rightBiteFile: []
    })
  }, [])

  useEffect(() => {
    if (isOpen && !isEmpty(list)) {
      const scannerType = getScannerType()
      if (scannerType) {
        const selectedOption = list.includes(scannerType) ? scannerType : otherOption
        setSelectedScannerType(selectedOption)
        setScannerValue(scannerType)
      }
    }
  }, [isOpen, otherOption, list])

  useEffect(() => {
    if (isOpen && isEmpty(list)) {
      dispatch(Actions.fetchScannerTypesList())
    }
  }, [dispatch, list, isOpen])

  useEffect(() => {
    if (touched) {
      validateForm()
    }
  }, [touched, upperArchFile, scannerValue, validateForm])

  useEffect(() => {
    if (!isOpen) {
      clearState()
    }
  }, [isOpen, clearState])

  const emptyStateComponent = useMemo(
    () => (
      <span className={classes.emptyState}>
        <span className={classes.dropzoneLabel}>
          {t('pages.patients.selectedPatient.uploadStlsModal.dropzoneLabel1')}
        </span>
        {t('pages.patients.selectedPatient.uploadStlsModal.dropzoneLabel2')}
      </span>
    ),
    [t, classes.dropzoneLabel, classes.emptyState]
  )

  return (
    <BaseModal
      open={isOpen}
      variant="card"
      handleClose={handleClose}
      secondaryLabel={t('pages.patients.selectedPatient.uploadStlsModal.close')}
      onSecondaryBtnClick={handleClose}
      onPrimaryBtnClick={handleSubmit}
      isPrimaryDisabled={isPrimaryDisabled}
      isLoading={isSaving}
      primaryLabel={t('pages.patients.selectedPatient.uploadStlsModal.uploadSubmit')}
      title={t('pages.patients.selectedPatient.uploadStlsModal.title')}
      className={classes.root}
      titleClassName={classes.title}
      smothBorders
    >
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        className={classes.scannerTypeWrapper}
      >
        <Grid item xs={12}>
          <SelectInput
            value={selectedScannerType}
            values={list.concat(otherOption)}
            containerClassName={!isOtherOption && classes.scannerDropdown}
            label={t('pages.patients.selectedPatient.uploadStlsModal.scannerInputLabel')}
            style={{ bright: true, thick: true }}
            onChange={handleChange}
            placeholder={t('pages.patients.selectedPatient.uploadStlsModal.scannerInputPlaceholder')}
            required
            errorMessage={!isOtherOption && !!errors.scannerValue}
          />
        </Grid>
        {isOtherOption && (
          <Grid item xs={12}>
            <FormInput
              required
              style={{ bright: true, thick: true }}
              value={scannerValue}
              setValue={setScannerValue}
              containerClassName={classes.scannerTypeInput}
              placeholder={t('pages.patients.selectedPatient.uploadStlsModal.scannerTypePlaceholder')}
              errorMessage={!!errors.scannerValue}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          {dropzones.map(({ label, files, errorMessage, IconComponent, onChange, filenamePrefix }) => (
            <DropzoneFilesInput
              key={label}
              label={label}
              icon={<IconComponent className={classes.archIcon} />}
              fileIcon={<UploadedStlPlaceholder />}
              onError={handleError}
              onChange={onChange}
              className={classes.fileInput}
              acceptedFormats={['.stl']}
              multipleFiles={false}
              files={files}
              emptyStateComponent={emptyStateComponent}
              errorMessage={errorMessage}
              filenameGenerator={(extension, filename) => `${filenamePrefix}_${filename}_${guid}.${extension}`}
              isLoading={isSaving}
              isUploaded={!!uploadedFiles[filenamePrefix]}
            />
          ))}
        </Grid>
      </Grid>
      <UploadConfirmationModal
        isOpen={isUploadConfirmationModalOpen}
        onClose={() => setIsUploadConfirmationModalOpen(false)}
        onUpload={handleUpload}
      />
    </BaseModal>
  )
}

export default UploadStlFilesModal
