import React, { forwardRef, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { CardMedia, Grid } from '@material-ui/core'
import { getCloudFrontUrl } from 'utils/storageUtils'
import { getObjectUrl } from 'utils/mediaUtils'
import ScanViewTiles from './ScanViewTiles'
import { isMobile } from 'utils/mobileUtils'
import PreventiveLayers from 'components/Patients/Timeline/ScanSummary/Preventive/PreventiveLayers'
import PreventivePanel from 'components/Patients/Timeline/ScanSummary/Preventive/PreventivePanel'
import { IMAGE_MODES, ScanSummaryStatus } from 'consts/scanSummaryConsts'
import useFeatureFlags from 'hooks/useFeatureFlags'
import ScanSummaryIncompleteState from './ScanSummaryIncompleteState'
import LinkButton from '../buttons/LinkButton'
import ScanSummaryRating from './ScanSummaryRating'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    flexWrap: 'nowrap',
    userSelect: 'none'
  },
  selectedMediaContainer: {
    height: '80%',
    width: '100%',
    textAlign: 'center'
  },
  cardMedia: {
    borderRadius: 8,
    marginBottom: 10,
    padding: '0 4px 4px 4px',
    objectFit: 'cover',
    aspectRatio: 1.66,
    '&::-webkit-media-controls-panel': {
      backgroundImage: 'none !important' // Removes the weird gradient from the video element
    }
  },
  preventiveCardMedia: {
    borderRadius: 8,
    marginBottom: 5,
    padding: '0 4px 4px 4px',
    width: '100%',
    aspectRatio: 1.66
  },
  scanSummaryIncompleteState: {
    padding: 24
  }
}))

/**
 * @prop grinScanVideo - The scan s3 object: ` { region, bucket, key } `
 * @prop scanSummaryStatus - (Optional) See the enum `ScanSummaryStatus` for possible values. Default value is `completed`.
 * @prop scanSummaryData - The **raw** scan summary data.
 * @prop mediaClassName - (Optional) A class name to assign to the primary media component.
 * @prop defaultImagesMode - (Optional) The default scan summary images mode to display. See `IMAGE_MODES` enum. Default value is `normal`. Usually it's either `normal` or `sharpened`.
 * @prop hidePreventive - (Optional) A boolean to completely toggle on/off the preventive view. Default value is `false`.
 * @prop onPlay - (Optional) A callback that's fired when the scan video start playing.
 * @prop scanSummaryImageActions - (Optional) A list of custom actions to render. Each action should have the following structure: `{ icon, label, onClick, isDisabled }`
 * @prop withPosesSelection - (Optional) A boolean to control whether scan summary images selection is enabled. Default value is `false`.
 * @prop selectedPoses - Required if `withPosesSelection` is `true`.
 * @prop setSelectedPoses - Required if `withPosesSelection` is `true`.
 * @prop withScanSummaryRating - (Optional) A boolean that controls whether the scan summary rating feature is visible.
 * @prop scanSummaryRating - Required if `withScanSummaryRating` is `true`. See `ScanSummaryRatingOptions` for possible values.
 * @prop setScanSummaryRating - Required if `withScanSummaryRating` is `true`.
 * @prop preventivePanelProps - props that will be spreaded into the PreventivePanel component. Should be used for styling purposes only!
 *
 * @prop `preventiveLayers, displayedPreventiveLayers, isAnyPreventiveLayerVisible, onTogglePreventiveLayer, onToggleAllPreventiveLayers, onClearActiveLayers`: use the `usePreventive` hook.
 */
const ScanView = forwardRef(
  (
    {
      grinScanVideo,
      scanSummaryStatus = ScanSummaryStatus.Completed,
      scanSummaryData = '{}',
      selectedOptionIndex = 0,
      onOptionSelect = index => {},
      mediaClassName = '',
      defaultImagesMode = IMAGE_MODES.normal,
      hidePreventive = false,
      preventiveLayers,
      displayedPreventiveLayers,
      isAnyPreventiveLayerVisible,
      onTogglePreventiveLayer,
      onToggleAllPreventiveLayers,
      onClearActiveLayers,
      scanSummaryStateComponents = {},
      onPlay,
      rootClassName = '',
      actionsContainerClassName = '',
      optionMediaClassName = '',
      selectedOptionClassName = '',
      unselectedOptionClasName = '',
      scanSummaryImageActions = [],
      withPosesSelection = false,
      selectedPoses = [],
      setSelectedPoses,
      withScanSummaryRating = false,
      scanSummaryRating = '',
      setScanSummaryRating = () => {},
      preventivePanelProps = {}
    },
    videoRef
  ) => {
    const classes = useStyles()
    const featureFlags = useFeatureFlags()

    const parsedScanSummaryData = useMemo(() => JSON.parse(scanSummaryData || '{}'), [scanSummaryData])
    const parsedPreventiveImages = useMemo(
      () => parsedScanSummaryData[IMAGE_MODES.preventiveLayers],
      [parsedScanSummaryData]
    )

    const options = useMemo(() => {
      const scanSummaryImages = parsedScanSummaryData?.[defaultImagesMode] || []
      return [
        {
          type: 'video',
          src: `${getCloudFrontUrl()}/${grinScanVideo.key}`
        },
        ...Object.keys(scanSummaryImages).map(pose => ({
          type: 'img',
          src: getObjectUrl(scanSummaryImages[pose]),
          pose
        }))
      ]
    }, [parsedScanSummaryData, grinScanVideo, defaultImagesMode])

    const selectedOption = useMemo(() => options[selectedOptionIndex] || options[0], [options, selectedOptionIndex])
    const shouldDisplayPreventiveLayers = useMemo(
      () =>
        !hidePreventive &&
        featureFlags?.scanSummaryPreventive &&
        parsedPreventiveImages &&
        isAnyPreventiveLayerVisible &&
        selectedOption.type !== 'video',
      [
        featureFlags?.scanSummaryPreventive,
        isAnyPreventiveLayerVisible,
        parsedPreventiveImages,
        selectedOption?.type,
        hidePreventive
      ]
    )
    const shouldDisplayPreventiveLegend = useMemo(
      () => !hidePreventive && featureFlags?.scanSummaryPreventive && parsedPreventiveImages,
      [featureFlags?.scanSummaryPreventive, hidePreventive, parsedPreventiveImages]
    )

    useEffect(() => {
      if (selectedOption.type === 'video' && isAnyPreventiveLayerVisible) {
        onClearActiveLayers()
      }
    }, [onClearActiveLayers, isAnyPreventiveLayerVisible, selectedOption.type])

    return (
      <Grid
        container
        direction="column"
        className={[classes.root, rootClassName].join(' ')}
        justifyContent="space-between"
      >
        <Grid item className={classes.selectedMediaContainer}>
          {selectedOption &&
            (shouldDisplayPreventiveLayers ? (
              <PreventiveLayers
                backgroundImageUrl={selectedOption?.src}
                preventiveImages={parsedPreventiveImages}
                position={selectedOption?.pose}
                displayedLayers={displayedPreventiveLayers}
                className={[classes.preventiveCardMedia, mediaClassName].join(' ')}
              />
            ) : (
              <CardMedia
                className={[classes.cardMedia, mediaClassName].join(' ')}
                component={selectedOption.type}
                src={selectedOption.src}
                controls={true}
                autoPlay={isMobile()}
                ref={videoRef}
                onPlay={onPlay}
              />
            ))}
        </Grid>
        <Grid item>
          {scanSummaryStatus === ScanSummaryStatus.Completed ? (
            <Grid container direction="column" className={actionsContainerClassName}>
              <Grid item>
                <ScanViewTiles
                  selectedOptionIndex={selectedOptionIndex}
                  options={options}
                  onOptionSelect={onOptionSelect}
                  optionMediaClassName={optionMediaClassName}
                  selectedOptionClassName={selectedOptionClassName}
                  unselectedOptionClasName={unselectedOptionClasName}
                  withPosesSelection={withPosesSelection}
                  selectedPoses={selectedPoses}
                  setSelectedPoses={setSelectedPoses}
                />
              </Grid>
              {(scanSummaryImageActions?.length || withScanSummaryRating) && (
                <Grid item style={{ padding: '14px 14px 0px 14px' }}>
                  <Grid container justifyContent="space-between">
                    {scanSummaryImageActions.length && (
                      <Grid item>
                        <Grid container spacing={2}>
                          {scanSummaryImageActions.map(action => (
                            <Grid item key={`scan-summary-action-${action.label}`}>
                              <LinkButton
                                icon={action.icon}
                                disabled={action.isDisabled}
                                label={action.label}
                                onClick={action.onClick}
                                fontSize={14}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                    {withScanSummaryRating && (
                      <Grid item>
                        <ScanSummaryRating rating={scanSummaryRating} setRating={setScanSummaryRating} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {shouldDisplayPreventiveLegend && (
                <Grid item style={{ padding: '14px' }}>
                  <PreventivePanel
                    {...preventivePanelProps}
                    isAnyPreventiveLayerVisible={isAnyPreventiveLayerVisible}
                    toggleAllLayers={onToggleAllPreventiveLayers}
                    hasPreventiveLayering={!!parsedPreventiveImages}
                    preventiveLayers={preventiveLayers}
                    togglePreventiveLayer={onTogglePreventiveLayer}
                    imagesMode={IMAGE_MODES.preventive}
                    shouldDisplayFeedback={false}
                    disabled={selectedOption.type === 'video'}
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <ScanSummaryIncompleteState scanSummaryStatus={scanSummaryStatus} />
          )}
        </Grid>
      </Grid>
    )
  }
)

export default React.memo(ScanView)
