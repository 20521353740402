// Action Types
const REQUEST_UPSERT_FEATURE_FLAGS = 'REQUEST_UPSERT_FEATURE_FLAGS'
const UPSERT_FEATURE_FLAGS_RECEIVED = 'UPSERT_FEATURE_FLAGS_RECEIVED'
const UPSERT_FEATURE_FLAGS_FAILED = 'UPSERT_FEATURE_FLAGS_FAILED'

// Action Creators
const requestUpsertFeatureFlags = payload => ({
  type: REQUEST_UPSERT_FEATURE_FLAGS,
  payload
})

const upsertFeatureFlagsReceived = payload => ({
  type: UPSERT_FEATURE_FLAGS_RECEIVED,
  payload
})

const upsertFeatureFlagsFailed = payload => ({
  type: UPSERT_FEATURE_FLAGS_FAILED,
  payload
})

export default {
  REQUEST_UPSERT_FEATURE_FLAGS,
  UPSERT_FEATURE_FLAGS_RECEIVED,
  UPSERT_FEATURE_FLAGS_FAILED,
  requestUpsertFeatureFlags,
  upsertFeatureFlagsReceived,
  upsertFeatureFlagsFailed
}
