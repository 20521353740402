import React, { useCallback, useMemo } from 'react'
import { makeStyles, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import DazzedParagraph12 from '../../common/text/DazzedParagraph12'
import ProfilePicture from '../../common/ProfilePicture'
import ImageUploaderWithCrop from '../ImageUploaderWithCrop'
import { ImagePlaceholder, Trash } from '../../common/icons'

import { CLINIC_LOGO_FOLDER } from '../../../consts/settings'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  hiddenElement: {
    display: 'none'
  },
  placeholderImageText: {
    color: 'var(--text-color-11)',
    marginBottom: 5,
    marginTop: 7
  },
  profilePicture: {
    marginRight: 0,
    borderRadius: 0
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'var(--bg-color-15)',
    marginBottom: 4
  },
  placeholderImage: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  placeholderImageSize: {
    fontSize: '7px',
    color: 'var(--text-color-11)'
  },
  placeholderImageIcon: {
    marginLeft: 5
  },
  logoHover: {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'var(--bg-color-27)',
    opacity: 0,
    transition: '.3s',
    cursor: 'pointer'
  },
  profilePictureWrapper: {
    position: 'relative',
    '&:hover > div': {
      opacity: 1
    }
  },
  medium: {
    width: 115,
    height: 115
  },
  small: {
    width: 75,
    height: 75
  }
}))

const ServicesClinicLogo = ({
  isLoading,
  setIsLoading,
  clinicLogoS3Obj,
  updateClinicLogo,
  size = 'medium',
  placeholderImage,
  inputId = 'clinicLogo'
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const defaultPlaceholderImage = useMemo(() => {
    return (
      <>
        <label htmlFor={inputId}>
          <div className={classes.placeholderImage}>
            <ImagePlaceholder
              fill="var(--text-color-11)"
              className={classes.placeholderImageIcon}
              width="33"
              height="28"
            />
            <DazzedParagraph12 className={classes.placeholderImageText}>
              {t('pages.accountSettings.services.uploadLogo')}
            </DazzedParagraph12>
            <DazzedParagraph12 className={classes.placeholderImageSize}>
              {t('pages.accountSettings.services.logoSize')}
            </DazzedParagraph12>
          </div>
        </label>
      </>
    )
  }, [
    classes.placeholderImage,
    classes.placeholderImageIcon,
    classes.placeholderImageSize,
    classes.placeholderImageText,
    inputId,
    t
  ])

  const handleClickDeleteLogo = useCallback(() => {
    trackEvent('RC design - delete image clicked', { imageType: 'logo' })
    setIsLoading(true)
    updateClinicLogo([])
  }, [setIsLoading, updateClinicLogo])

  const mapToFullPath = useCallback(s3Object => {
    return !s3Object
      ? null
      : {
          ...s3Object,
          key: s3Object ? `${CLINIC_LOGO_FOLDER}/${s3Object.key}` : null
        }
  }, [])

  return (
    <>
      {isLoading ? (
        <div className={`${classes.loaderContainer} ${classes[size]}`}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.profilePictureWrapper}>
          <ProfilePicture
            photo={mapToFullPath(clinicLogoS3Obj)}
            size="custom"
            placeholderImage={placeholderImage || defaultPlaceholderImage}
            className={`${classes.profilePicture} ${classes[size]}`}
          />
          {clinicLogoS3Obj && (
            <div onClick={handleClickDeleteLogo} className={`${classes.logoHover} ${classes[size]}`}>
              <Trash />
            </div>
          )}
        </div>
      )}

      <div className={classes.hiddenElement}>
        <ImageUploaderWithCrop
          inputId={inputId}
          onUploadDone={updateClinicLogo}
          onStartLoading={isLoading => {
            trackEvent('RC design - upload image - ok clicked', { imageType: 'logo' })
            setIsLoading(isLoading)
          }}
          onCancelClicked={() => trackEvent('RC design - upload image - cancel clicked', { imageType: 'logo' })}
          onUploadClicked={() => trackEvent('RC design - upload image clicked', { imageType: 'logo' })}
          circularCrop={false}
          minWidth={250}
          minHeight={250}
          keyWithExtension
          s3Folder={CLINIC_LOGO_FOLDER}
          fillIntoSquare
        />
      </div>
    </>
  )
}

export default ServicesClinicLogo
