import React, { useCallback, useMemo, useState } from 'react'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { AroundScope, AroundTeeth } from 'components/common/icons'
import GrinRadio from 'components/common/GrinRadio'

const CROP_SETTINGS = {
  AroundTeeeth: 'aroundTeeth',
  AroundScope: 'aroundScope'
}

const ServiceAccountCropSettings = ({ onChangeSettings, cropSettings, isSaving }) => {
  const { t } = useTranslation()

  const [settings, setSettings] = useState(cropSettings)

  const handleSettingUpdated = useCallback(
    newSetting => {
      if (isSaving) {
        return
      }
      setSettings(newSetting)
      onChangeSettings(newSetting)
    },
    [isSaving, onChangeSettings]
  )

  const options = useMemo(
    () => [
      {
        key: 'around-teeth',
        icon: <AroundTeeth />,
        title: t('pages.accountSettings.services.serviceAccount.configuration.cropSettings.aroundTeeth.title'),
        description: t(
          'pages.accountSettings.services.serviceAccount.configuration.cropSettings.aroundTeeth.description'
        ),
        onClick: () => handleSettingUpdated(CROP_SETTINGS.AroundTeeeth),
        checked: CROP_SETTINGS.AroundTeeeth === settings
      },
      {
        key: 'around-scope',
        icon: <AroundScope />,
        title: t('pages.accountSettings.services.serviceAccount.configuration.cropSettings.aroundScope.title'),
        description: t(
          'pages.accountSettings.services.serviceAccount.configuration.cropSettings.aroundScope.description'
        ),
        onClick: () => handleSettingUpdated(CROP_SETTINGS.AroundScope),
        checked: CROP_SETTINGS.AroundScope === settings || !settings
      }
    ],
    [handleSettingUpdated, settings, t]
  )

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <DazzedParagraph14 bold>
          {t('pages.accountSettings.services.serviceAccount.configuration.cropSettings.title')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" wrap="nowrap">
          {options.map(option => (
            <Grid item key={option.key}>
              <Grid container spacing={1} wrap="nowrap">
                <Grid item>
                  <GrinRadio checked={option.checked} onClick={option.onClick} isDisabled={isSaving} />
                </Grid>
                <Grid item>{option.icon}</Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <DazzedParagraph14 bold>{option.title}</DazzedParagraph14>
                    </Grid>
                    <Grid item>
                      <DazzedParagraph14>{option.description}</DazzedParagraph14>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ServiceAccountCropSettings
