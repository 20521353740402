import Actions from 'actions'
import { API, graphqlOperation } from 'aws-amplify'
import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, map, mapTo, mergeMap, pluck, switchMap, withLatestFrom } from 'rxjs/operators'
import { getGrinScanForGiResolve, grinScansByTrackingStatusSorted } from '../graphql/customQueries'
import i18n from '../resources/locales/i18n'
import { updateGrinScan } from 'graphql/mutations'

export const fetchTrackingRequiredGrinScansEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_TRACKING_REQUIRED_GRIN_SCANS),
    withLatestFrom(state$),
    switchMap(([, { giReducer }]) =>
      from(
        API.graphql(
          graphqlOperation(grinScansByTrackingStatusSorted, {
            trackingStatus: 'pending',
            sortDirection: 'DESC',
            limit: 20,
            nextToken: giReducer.grinScansList.nextToken
          })
        )
      ).pipe(
        map(response => response.data.grinScansByTrackingStatusSorted),
        mergeMap(grinScans => of(Actions.fetchTrackingRequiredGrinScansReceived(grinScans))),
        catchError(({ response }) => of(Actions.fetchTrackingRequiredGrinScansFailed(response)))
      )
    )
  )

export const fetchTrackingRequiredGrinScansFailedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_TRACKNG_REQUIRED_GRIN_SCANS_FAILED),
    switchMap(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('giDashboard.failedToFetchScans')
      })
    )
  )

export const giSubmitTrackingDataEpic = action$ =>
  action$.pipe(
    ofType(Actions.SUBMIT_SCAN_TRACKING_DATA),
    pluck('payload'),
    switchMap(({ scanId, trackStatus, notes, conditions, dismissScan, isGiUser }) =>
      from(
        API.post('grinServerlessApi', '/treatments/v2/gi/trackingData', {
          body: {
            scanId,
            trackStatus,
            notes,
            dismissScan,
            conditions,
            withAutomatedMessages: !!isGiUser
          }
        })
      ).pipe(
        mergeMap(data => of(Actions.submitScanTrackingDataReceived(data))),
        catchError(err => of(Actions.submitScanTrackingDataFailed(err)))
      )
    )
  )

export const giSubmitTrackingDataFailed = action$ =>
  action$.pipe(
    ofType(Actions.SUBMIT_SCAN_TRACKING_DATA_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const giSubmitTrackingDataSuccess = action$ =>
  action$.pipe(
    ofType(Actions.SUBMIT_SCAN_TRACKING_DATA_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.changesSavedSuccessfully')
      })
    )
  )

export const giMarkScanAsDone = (action$, state$) =>
  action$.pipe(
    ofType(Actions.GI_MARK_SCAN_AS_DONE),
    withLatestFrom(state$),
    map(([action, state]) => ({
      grinScanId: action.payload.grinScanId,
      username: state.profileReducer.doctor.username
    })),
    mergeMap(({ grinScanId, username }) =>
      from(
        API.graphql(
          graphqlOperation(getGrinScanForGiResolve, {
            id: grinScanId
          })
        )
      ).pipe(
        map(res => res.data.getGrinScan),
        mergeMap(grinScan =>
          from(
            API.graphql(
              graphqlOperation(updateGrinScan, {
                input: {
                  id: grinScan.id,
                  _version: grinScan._version,
                  trackingDetails: JSON.stringify({
                    ...JSON.parse(grinScan.trackingDetails || '{}'),
                    isResolved: true,
                    resolvedAt: new Date().toISOString(),
                    resolvedBy: username
                  })
                }
              })
            )
          ).pipe(
            mergeMap(data => of(Actions.giMarkScanAsDoneReceived({ grinScanId, patientId: grinScan.patientId }))),
            catchError(error => of(Actions.giMarkScanAsDoneFailed({ error, grinScanId })))
          )
        ),
        catchError(error => console.log(error) || of(Actions.giMarkScanAsDoneFailed({ error, grinScanId })))
      )
    )
  )

export const giClearNeedsAttentionTag = action$ =>
  action$.pipe(
    ofType(Actions.GI_MARK_SCAN_AS_DONE_RECEIVED),
    pluck('payload'),
    map(({ patientId }) => Actions.removePatientTag({ patientId, tag: 'needsAttention' }))
  )

export const giMarkScanAsDoneSnackbar = action$ =>
  action$.pipe(
    ofType(Actions.GI_MARK_SCAN_AS_DONE_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.changesSavedSuccessfully')
      })
    )
  )

export const giMarkScanAsDoneErrorSnackbar = action$ =>
  action$.pipe(
    ofType(Actions.GI_MARK_SCAN_AS_DONE_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('errors.somethingWentWrongTryAgain')
      })
    )
  )
