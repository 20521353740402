import React from 'react'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const CategorySubtitle = ({ viewMode, children }) => {
  return (
    <DazzedParagraph14 bold color={viewMode ? 'var(--text-color-11)' : null}>
      {children}
    </DazzedParagraph14>
  )
}

export default CategorySubtitle
