import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import CustomDatePicker from '../common/CustomDatePicker'
import GrinLabel from '../common/text/GrinLabel'
import Tooltip from '../common/Tooltip'
import InfoIcon from '@material-ui/icons/Info'

const useStyles = makeStyles(theme => ({
  dataPickerContainer: {
    width: '100%',
    marginTop: '5px'
  }
}))

const SetNextScan = ({ date, onSet, disabled = false, title, tooltip, containerClassName = '' }) => {
  const classes = useStyles()

  return (
    <Grid container className={containerClassName}>
      <Grid container spacing={1}>
        <Grid item>
          <GrinLabel>{title}</GrinLabel>
        </Grid>
        {tooltip && (
          <Grid item>
            <Tooltip value={tooltip} variant="dark">
              <InfoIcon color="disabled" fontSize="inherit" />
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid item className={classes.dataPickerContainer}>
        <CustomDatePicker
          disabled={disabled}
          value={date}
          handleDateChange={onSet}
          inputVariant="outlined"
          minDateMessage={null}
          disablePast={true}
        />
      </Grid>
    </Grid>
  )
}

export default SetNextScan
