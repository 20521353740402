import React, { useCallback, useMemo } from 'react'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import GrinMenuItem from 'components/common/menu/GrinMenuItem'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import { Close } from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import useSavedViews from '../hooks/useSavedViews'

const useStyles = makeStyles(theme => ({
  container: {
    padding: 10
  },
  menuTitle: {
    paddingLeft: 16
  },
  viewLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  divider: {
    opacity: 0.75,
    height: 1,
    width: '100%',
    backgroundColor: 'var(--border-color-35)'
  }
}))

const SavedViewsMenu = ({ selectedSavedView, onViewSelected }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const { presetViews, parsedCustomViews: customViews } = useSavedViews()

  const hasCustomViews = useMemo(() => !!customViews.length, [customViews])

  const handleRemoveCustomView = useCallback(
    (e, view) => {
      e.stopPropagation()
      trackEvent('Saved views - Deleted saved view', {
        viewId: view.id
      })
      dispatch(Actions.deleteSavedView({ id: view.id, _version: view._version }))
    },
    [dispatch]
  )

  return (
    <Grid container direction="column" spacing={1} className={classes.container}>
      <Grid item>
        <DazzedParagraph14 color="var(--text-color-14)" className={classes.menuTitle}>
          {t('pages.patients.patientsList.savedViews.presetViews')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        {presetViews.map(viewOption => (
          <GrinMenuItem key={viewOption.id} onClick={() => onViewSelected(viewOption)}>
            <DazzedParagraph14>{viewOption.name}</DazzedParagraph14>
          </GrinMenuItem>
        ))}
      </Grid>
      {hasCustomViews && (
        <>
          <Grid item>
            <div className={classes.divider} />
          </Grid>
          <Grid item>
            <DazzedParagraph14 color="var(--text-color-14)" className={classes.menuTitle}>
              {t('pages.patients.patientsList.savedViews.customViews')}
            </DazzedParagraph14>
          </Grid>
          <Grid item>
            {customViews.map(view => (
              <GrinMenuItem key={view.id} onClick={() => onViewSelected(view)}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <DazzedParagraph14
                      color={view.id === selectedSavedView?.id ? 'var(--text-color-8)' : undefined}
                      className={classes.viewLabel}
                    >
                      {view.name}
                    </DazzedParagraph14>
                  </Grid>
                  <Grid item>
                    <IconButton size="small" onClick={e => handleRemoveCustomView(e, view)}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </GrinMenuItem>
            ))}
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default SavedViewsMenu
