import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import OggHeading40 from '../../common/text/OggHeading40'
import RCToggle from './RCToggle/RCToggle'
import OrthoToggle from './OrthoToggle/OrthoToggle'
import ServicesHeader from './ServicesHeader'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import DoctorLocatorSettings from './DoctorLocatorSettings'
import ServiceAccountToggle from './ServiceAccountToggle/ServiceAccountToggle'

const useStyles = makeStyles(theme => ({
  root: {
    width: 538,
    paddingBottom: 50
  },
  heading: {
    marginBottom: 20
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: 'var(--border-color-13)',
    marginBottom: 25
  }
}))

const Services = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const doctor = useSelector(state => state.profileReducer.doctor)

  const { isRcFFEnabled, isRcEnabled, isOrthoFFEnabled } = usePracticeConfiguration()

  if (!doctor.id || (!isRcFFEnabled && !isOrthoFFEnabled)) {
    return null
  }

  return (
    <div className={classes.root}>
      <OggHeading40 textAlign="left" className={classes.heading}>
        {t('pages.accountSettings.services.title')}
      </OggHeading40>
      <div className={classes.divider} />
      <ServicesHeader />
      {isRcFFEnabled && <RCToggle isRcFFEnabled={isRcFFEnabled} isEnabled={isRcFFEnabled && isRcEnabled} />}
      <ServiceAccountToggle />
      <DoctorLocatorSettings />
      {isOrthoFFEnabled && <OrthoToggle />}
    </div>
  )
}

export default Services
