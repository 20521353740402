import React from 'react'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Grid, makeStyles } from '@material-ui/core'
import { ArrowDropdownDown, ArrowDropdownUp } from 'components/common/icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  container: {
    userSelect: 'none',
    cursor: 'pointer'
  },
  viewLabelContainer: {
    maxWidth: '180px !important',
    whiteSpace: 'nowrap'
  },
  viewLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}))

const SavedViewsTrigger = ({ currentViewLabel, isMenuOpen }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item>
        <DazzedParagraph14 color="var(--text-color-14)" onClick={() => {}}>
          {t('pages.patients.patientsList.savedViews.view')}
        </DazzedParagraph14>
      </Grid>
      <Grid item className={classes.viewLabelContainer}>
        <DazzedParagraph14 className={classes.viewLabel}>{currentViewLabel}</DazzedParagraph14>
      </Grid>
      <Grid item>
        <div className={classes.arrow}>{isMenuOpen ? <ArrowDropdownUp /> : <ArrowDropdownDown />}</div>
      </Grid>
    </Grid>
  )
}

export default SavedViewsTrigger
