import * as React from 'react'

const Crop = ({ style, ...props }) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M4 1V11H14" stroke="#4151E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 14L11 11M1 4L4 4" stroke="#4151E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 9L11 4L6 4" stroke="#4151E6" strokeWidth="1.5" strokeLinejoin="round" />
  </svg>
)

export default Crop
