export const TagTypeToQueryType = {
  program: 'program',
  treatment: 'treatmentType',
  custom: 'tagKeywords',
  status: 'statusType',
  name: 'name',
  lastActionItemType: 'lastActionItemType',
  scan: 'tagKeywords',
  assignee: 'assigneeId',
  chat: 'isChatRoomResolved'
}

export const DEFAULT_TAGS_FILTER = {
  custom: [],
  status: [],
  program: [],
  treatment: [],
  scan: [],
  assignee: [],
  chat: []
}

export const TagFilterTypes = Object.keys(DEFAULT_TAGS_FILTER)
