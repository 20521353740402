import React, { useMemo, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Actions from 'actions'
import SwooshLargeIcon from 'components/common/icons/SwooshLarge2'
import BillingForm from '../Auth/BillingInfo/BillingForm'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'consts'
import { Grid } from '@material-ui/core'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles(theme => ({
  pageContainer: {
    padding: 15,
    paddingTop: 50,
    paddingBottom: 50,
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  swoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  practiceInfoTitle: {
    fontFamily: 'Ogg',
    fontSize: '40px',
    lineHeight: '40px',
    marginBottom: 44,
    color: 'var(--text-color-1)',
    '@media (max-width: 960px)': {
      marginBottom: 20,
      textAlign: 'center'
    }
  },
  formWrapper: {
    width: '100%',
    maxWidth: 1000,
    margin: '0 auto',
    '@media (max-width: 750px)': {
      maxWidth: '100%'
    }
  }
}))

const BillingInfo = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    emailAddress,
    roleDescription,
    practiceName,
    address,
    city,
    country,
    state,
    zip,
    username,
    billingInfo: { paymentMethodId, cardLast4, cardBrand }
  } = useSelector(state => state.quickSignUpReducer)

  const mixpanelPayload = useMemo(
    () => ({
      roleDescription,
      email: emailAddress,
      practiceName
    }),
    [emailAddress, practiceName, roleDescription]
  )

  const handleSubmit = useCallback(
    ({ paymentMethodId, cardLast4, cardBrand }) => {
      dispatch(
        Actions.changeQuickSignUpBillingForm([
          { key: 'paymentMethodId', value: paymentMethodId },
          { key: 'cardLast4', value: cardLast4 },
          { key: 'cardBrand', value: cardBrand }
        ])
      )

      dispatch(
        Actions.requestAttachCreditCard({
          paymentMethodId,
          doctorUsername: username,
          email: emailAddress,
          isQuickSignup: true
        })
      )
    },
    [dispatch, emailAddress, username]
  )

  const handleSkip = useCallback(() => {
    dispatch(Actions.finishQuickSignup({ email: emailAddress }))
    history.push(ROUTES.QUICK_SIGN_UP)
  }, [dispatch, emailAddress, history])

  return (
    <div className={classes.pageContainer}>
      {!isMobile() && <SwooshLargeIcon className={classes.swoosh} />}
      <div className={classes.formWrapper}>
        <Grid container direction="column">
          <Grid item>
            <Grid container>
              <Grid item xs={3}></Grid>
              <Grid item xs={6}>
                <div className={classes.practiceInfoTitle}>{t('pages.billingInfo.label')}</div>
              </Grid>
            </Grid>
            <Grid item>
              <BillingForm
                paymentMethodId={paymentMethodId}
                cardLast4={cardLast4}
                cardBrand={cardBrand}
                address1={address}
                city={city}
                state={state}
                zip={zip}
                country={country}
                onSkip={handleSkip}
                onSubmitCallback={handleSubmit}
                mixpanelPrefix="quickSignUp"
                mixpanelPayload={mixpanelPayload}
                skipable
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default BillingInfo
