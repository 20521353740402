import { Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Actions from 'actions'
import SwooshLargeIcon from 'components/common/icons/SwooshLarge2'
import WithLoader from 'components/common/WithLoader'
import TermsDoc from '../Auth/TermsDoc'
import BackButton from '../Auth/BackButton'

const useStyles = makeStyles(theme => ({
  termsContainer: {
    backgroundColor: theme.palette.primary.main,
    paddingBottom: 50,
    minHeight: '100%',
    '@media (max-width: 750px)': {
      padding: 15
    }
  },
  termsTitle: {
    fontFamily: 'Ogg',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '40px',
    lineHeight: '40px',
    marginBottom: '64px',
    color: 'var(--text-color-1)',
    '@media (max-width: 960px)': {
      textAlign: 'center',
      marginBottom: 20
    }
  },
  termsSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0',
    '@media (max-width: 960px)': {
      display: 'none'
    }
  },
  nextButton: {
    background: 'var(--border-color-1)',
    color: 'var(--text-color-1)',
    width: '100%',
    maxWidth: 478,
    borderRadius: '34px',
    marginBottom: 50,
    '&:hover': {
      background: 'var(--border-color-1)'
    }
  },
  noFill: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      transition: 'color 0.2s ease',
      color: 'gray',
      boxShadow: 'none'
    }
  },
  titleContainer: {
    padding: '0 25px'
  },
  contentContainer: {
    width: '50%',
    marginTop: 100,
    '@media (max-width: 960px)': {
      flex: 1,
      marginTop: 0
    },
    zIndex: 1
  },
  buttonContainer: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 960px)': {
      padding: '0 20px'
    }
  }
}))

const Terms = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isLoading = useSelector(state => state.quickSignUpReducer.isLoading)
  const practiceCountry = useSelector(state => state.quickSignUpReducer.country)

  const handleFormSubmit = e => {
    e.preventDefault()
    dispatch(Actions.requestQuickDoctorSignUp())
  }

  return (
    <form className={classes.termsContainer} onSubmit={handleFormSubmit}>
      <SwooshLargeIcon className={classes.termsSwoosh} />
      <BackButton />
      <Grid container justifyContent="center">
        <div className={classes.contentContainer}>
          <div className={classes.titleContainer}>
            <div className={classes.termsTitle}>{t('pages.terms.termsAndConditions')}</div>
          </div>
          <TermsDoc country={practiceCountry} />
          <div className={classes.buttonContainer}>
            <WithLoader isLoading={isLoading}>
              <Button variant="contained" className={classes.nextButton} type="submit">
                {t('pages.terms.iAgree')}
              </Button>
            </WithLoader>
          </div>
        </div>
      </Grid>
    </form>
  )
}

export default Terms
