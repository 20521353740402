import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Check from 'components/common/icons/Check'

const useStyles = makeStyles(theme => ({
  poseCheckbox: {
    border: '2px solid var(--bg-color-10)',
    width: 21,
    height: 21,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    '& svg': {
      display: 'none'
    },
    '&:hover': {
      borderWidth: 3
    }
  },
  checked: {
    backgroundColor: 'var(--bg-color-7)',
    '& svg': {
      display: 'block'
    },
    '&:hover': {
      borderWidth: '2px'
    }
  }
}))

const PoseCheckbox = ({ isChecked, setIsChecked = () => {} }) => {
  const classes = useStyles()

  const handleClick = useCallback(
    e => {
      e.stopPropagation()
      setIsChecked(!isChecked)
    },
    [setIsChecked, isChecked]
  )

  return (
    <div className={[classes.poseCheckbox, isChecked ? classes.checked : ''].join(' ')} onClick={handleClick}>
      <Check className="checkbox" />
    </div>
  )
}

export default PoseCheckbox
