import React, { useMemo, useState } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Collapse from '@material-ui/core/Collapse'
import DazzedHeading16 from '../../../common/text/DazzedHeading16'
import ExpandCollapseUpDownButton from '../../../common/buttons/ExpandCollapseUpDownButton'

const useStyles = makeStyles({
  lineContainer: {
    padding: 4
  },
  showMoreContainer: {
    cursor: 'pointer',
    marginBottom: 10
  },
  action: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '24px',
    color: 'var(--text-color-16)',
    opacity: 1
  },
  actionRightMarginInActive: {
    marginRight: 5
  },
  actionRightMarginActive: {
    marginRight: 9
  }
})

const ScanReviewsList = ({ items = [], itemComponent }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [showAll, setShowAll] = useState(false)

  const mostRecentItem = items[items.length - 1]
  const actionRightMargin = useMemo(
    () => (showAll ? classes.actionRightMarginActive : classes.actionRightMarginInActive),
    [classes.actionRightMarginActive, classes.actionRightMarginInActive, showAll]
  )

  return (
    items?.length > 0 && (
      <Grid container justifyContent="space-between" alignItems="flex-start" direction="row-reverse">
        <Grid item>
          <Grid
            container
            alignItems="center"
            className={classes.showMoreContainer}
            onClick={() => setShowAll(!showAll)}
          >
            {items.length > 1 && (
              <>
                <Grid item>
                  <DazzedHeading16 className={classNames(classes.action, actionRightMargin)}>
                    {showAll
                      ? t('pages.patients.selectedPatient.timeline.seeLess')
                      : t('pages.patients.selectedPatient.timeline.seeMore')}
                  </DazzedHeading16>
                </Grid>
                <ExpandCollapseUpDownButton expand={!showAll} color={'var(--text-color-16)'} />
              </>
            )}
          </Grid>
        </Grid>

        <Grid item xs={items?.length > 1 ? 9 : 12}>
          {items?.length > 1 && (
            <Collapse in={showAll}>
              {items?.map((item, index) => {
                return index < items.length - 1 && React.createElement(itemComponent, { key: index, item })
              })}
            </Collapse>
          )}

          {mostRecentItem &&
            React.createElement(itemComponent, {
              item: mostRecentItem
            })}
        </Grid>
      </Grid>
    )
  )
}

export default ScanReviewsList
