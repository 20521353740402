import { APPOINTMENT_MODES } from 'consts/pmsConsts'
import Actions from '../actions'
import { AsyncStatus } from 'consts'
import { removeWhere } from 'utils/arrayUtils'

const initialState = {
  pmsType: null,
  isLoading: false,
  isFetchingStaticData: false,
  isFetchAvailableTimeSlots: false,
  isSaving: false,
  hasFetchedStaticData: false,
  appointmentMode: 'view',
  currentPatient: null,
  patients: [],
  latestPatientsToInvite: null,
  locations: [],
  appointmentTypes: [],
  chairsByLocation: null,
  availableTimeSlots: null,
  authenticationStatus: null,
  snackbar: {
    dolphin: {
      isOpen: false,
      wasAlreadyOpened: false
    }
  },
  invitePatientsModal: {
    dolphin: {
      isOpen: false
    }
  },
  redirectPatientNotFoundPopup: {
    isOpen: false,
    pmsPatientName: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.PMS_AUTHENTICATION: {
      return {
        ...state,
        authenticationStatus: AsyncStatus.Loading
      }
    }
    case Actions.PMS_AUTHENTICATION_RECEIVED:
      return {
        ...state,
        authenticationStatus: AsyncStatus.Completed
      }
    case Actions.PMS_AUTHENTICATION_FAILED:
      return {
        ...state,
        authenticationStatus: AsyncStatus.Failed
      }
    case Actions.SET_PMS_TYPE: {
      return {
        ...state,
        pmsType: action.payload
      }
    }
    case Actions.PMS_FETCH_PATIENTS_RECEIVED:
      const { patients } = action.payload

      return {
        ...state,
        isLoading: false,
        patients
      }

    case Actions.PMS_FETCH_APPOINTMENTS_RECEIVED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.PMS_LINK_PATIENT: {
      return {
        ...state,
        currentPatient: state.patients.find(patient => patient.id === action.payload.pmsPatientId)
      }
    }
    case Actions.PMS_FETCH_STATIC_DATA: {
      return {
        ...state,
        isFetchingStaticData: true
      }
    }

    case Actions.PMS_FETCH_PATIENTS:
    case Actions.PMS_FETCH_APPOINTMENTS:
    case Actions.PMS_FETCH_PATIENT:
      return {
        ...state,
        isLoading: true
      }
    case Actions.PMS_FETCH_AVAILABLE_TIME_SLOTS:
      return {
        ...state,
        isFetchAvailableTimeSlots: true,
        availableTimeSlots: null
      }
    case Actions.PMS_FETCH_PATIENT_RECEIVED:
      const { patient } = action.payload

      return {
        ...state,
        isLoading: false,
        currentPatient: patient
      }
    case Actions.PMS_FETCH_PATIENT_FAILED:
    case Actions.PMS_FETCH_PATIENTS_FAILED:
    case Actions.PMS_CREATE_NEW_APPOINTMENT_FAILED:
    case Actions.PMS_FETCH_APPOINTMENTS_FAILED:
    case Actions.PMS_FETCH_STATIC_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        isFetchingStaticData: false,
        isSaving: false
      }
    case Actions.PMS_FETCH_AVAILABLE_TIME_SLOTS_RECEIVED:
      const { availableTimeSlots } = action.payload

      return {
        ...state,
        isFetchAvailableTimeSlots: false,
        availableTimeSlots
      }
    case Actions.PMS_FETCH_AVAILABLE_TIME_SLOTS_FAILED:
      return {
        ...state,
        isFetchAvailableTimeSlots: false,
        availableTimeSlots: []
      }
    case Actions.PMS_CREATE_NEW_APPOINTMENT:
      return {
        ...state,
        isSaving: true
      }
    case Actions.PMS_CREATE_NEW_APPOINTMENT_RECEIVED:
      return {
        ...state,
        isSaving: false,
        appointmentMode: APPOINTMENT_MODES.view,
        availableTimeSlots: null
      }

    case Actions.PMS_SET_APPOINTMENT_MODE:
      return {
        ...state,
        appointmentMode: action.payload
      }
    case Actions.TOGGLE_PATIENT_CARD:
      if (!action.payload.isModalOpen) {
        return {
          ...state,
          currentPatient: initialState.currentPatient,
          availableTimeSlots: initialState.availableTimeSlots
        }
      }

      return {
        ...state,
        appointmentMode: 'view'
      }

    case Actions.PMS_FETCH_STATIC_DATA_RECEIVED:
      return {
        ...state,
        ...action.payload,
        isFetchingStaticData: false,
        hasFetchedStaticData: true
      }

    case Actions.PMS_UNLINK_PATIENT:
      return {
        ...state,
        currentPatient: initialState.currentPatient,
        availableTimeSlots: initialState.availableTimeSlots
      }
    case Actions.PMS_FETCH_LATEST_PATIENTS_RECEIVED: {
      return {
        ...state,
        latestPatientsToInvite: action.payload
      }
    }
    case Actions.PMS_TOGGLE_SNACKBAR: {
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          [state.pmsType]: {
            ...state.snackbar[state.pmsType],
            isOpen: action.payload,
            wasAlreadyOpened: state.snackbar[state.pmsType].isOpen
          }
        }
      }
    }
    case Actions.PMS_TOGGLE_INVITE_PATIENTS_MODAL: {
      return {
        ...state,
        invitePatientsModal: {
          ...state.invitePatientsModal,
          [state.pmsType]: {
            ...state.invitePatientsModal[state.pmsType],
            isOpen: !state.invitePatientsModal[state.pmsType].isOpen
          }
        }
      }
    }
    case Actions.SET_INVITE_PATIENT_PRESET_DETAILS: {
      return {
        ...state,
        latestPatientsToInvite: removeWhere(state.latestPatientsToInvite, patient => patient.id === action.payload.id)
      }
    }
    case Actions.SIGNOUT_REQUESTED: {
      return initialState
    }
    case Actions.PMS_TOGGLE_REDIRECT_PATIENT_NOT_FOUND_MODAL: {
      return {
        ...state,
        redirectPatientNotFoundPopup: {
          ...state.redirectPatientNotFoundPopup,
          isOpen: !!action.payload?.isOpen,
          pmsPatientName: action.payload?.pmsPatientName
        }
      }
    }
    default:
      return state
  }
}
