import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { ClickAwayListener, Grid, Popper } from '@material-ui/core'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import GrinCheckbox from 'components/common/GrinCheckbox'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 999,
    borderRadius: 16,
    '-webkit-filter': 'drop-shadow(0px 3px 8px var(--border-color-21))',
    filter: 'drop-shadow(0px 3px 8px var(--border-color-21))',
    position: 'relative',
    padding: '14px',
    backgroundColor: 'white',
    maxWidth: 230,
    marginBottom: 20,
    '&:after': {
      content: "''",
      position: 'absolute',
      right: '50%',
      bottom: -25,
      transform: 'translateX(50%)',
      background: 'var(--bg-color-10)',
      width: 25,
      height: 25,
      clipPath: 'polygon(0 0, 100% 0, 50% 50%)'
    }
  },
  text: {
    fontSize: 13
  },
  checkbox: {
    padding: 5
  },
  checkboxLabel: {
    fontSize: 12
  }
}))

const ScheduledMessageWarningPopup = ({ anchorEl, open, onClose = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [dontShowAgain, setDontShowAgain] = useState(false)

  const handleClose = useCallback(
    reason => {
      onClose({
        reason,
        dontShowAgain
      })
    },
    [onClose, dontShowAgain]
  )

  return (
    <Popper
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={() => handleClose('button')}
      className={classes.root}
      placement="top-start"
    >
      <ClickAwayListener onClickAway={() => handleClose('click-away')}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <DazzedParagraph12 className={classes.text}>
              {t('pages.patients.selectedPatient.chat.scheduledMessageWarningTooltip')}
            </DazzedParagraph12>
          </Grid>
          <Grid item xs={12}>
            <GrinCheckbox
              caption={t('pages.patients.selectedPatient.chat.promotions.popup.checkbox')}
              checked={dontShowAgain}
              onChange={setDontShowAgain}
              color="var(--border-color-6)"
              className={classes.checkbox}
              captionClassName={classes.checkboxLabel}
            />
          </Grid>
          <Grid item>
            <PrimaryButton
              small
              label={t('general.gotIt')}
              onClick={() => handleClose('button')}
              className={classes.button}
              height={30}
            />
          </Grid>
        </Grid>
      </ClickAwayListener>
    </Popper>
  )
}

export default ScheduledMessageWarningPopup
