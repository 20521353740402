import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import BaseModal from '../../../common/modals/BaseModal'
import DazzedParagraph16 from '../../../common/text/DazzedParagraph16'

const useStyles = makeStyles({
  body: {
    maxWidth: 400,
    width: '100%'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 60
  },
  title: {
    maxWidth: 180
  }
})

const DeletePhotoModal = ({ title, isOpen, handleCancel, handleSubmit, isDeleting }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <BaseModal
      open={isOpen}
      secondaryLabel={t('general.cancel')}
      primaryLabel={t('general.delete')}
      className={classes.body}
      variant="alert"
      handleClose={handleCancel}
      onSecondaryBtnClick={handleCancel}
      onPrimaryBtnClick={handleSubmit}
      isLoading={isDeleting}
    >
      <div className={classes.titleContainer}>
        <DazzedParagraph16 className={classes.title} textAlign="center">
          {t('dialogs.patientInfo.records.deletePhotoModal.title')}
        </DazzedParagraph16>
      </div>
    </BaseModal>
  )
}

export default DeletePhotoModal
