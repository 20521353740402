import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { makeStyles, Grid, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import images from '../../../../resources'
import DazzedParagraph14 from '../../../common/text/DazzedParagraph14'
import { Unlink } from 'components/common/icons'
import { useDispatch, useSelector } from 'react-redux'
import actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import PmsSearchPatient from './PmsSearchPatient'

const useStyles = makeStyles({
  logo: {
    width: 100,
    height: 40,
    marginRight: 12
  },
  unlinked: {
    opacity: 0.5,
    paddingLeft: 8
  },
  unlinkIcon: {
    paddingTop: 4
  },
  linkPatientText: {
    color: 'var(--text-color-3)',
    cursor: 'pointer'
  },
  searchInput: {
    display: 'flex',
    width: '245px',
    fontFamily: 'Dazzed',
    fontSize: 14,
    paddingLeft: 5,
    background: 'rgba(0, 0, 0, 0)',
    border: 'none',
    paddingBottom: 4,
    '&:focus': {
      outline: 'none'
    },
    '::placeholder': {
      color: 'var(--text-color-14)'
    }
  },
  popoverContainer: {
    width: 300,
    padding: 16
  },
  greyText: {
    color: 'var(--text-color-14)'
  },
  patientRow: {
    paddingTop: 8
  }
})

const PmsLinkPatientRow = ({ patientPmsID }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const [isSearchPatientMode, setIsSearchPatientMode] = useState(false)

  const { currentPatient, isLoading, pmsType } = useSelector(state => state.pmsReducer)

  const pmsPatientLabel = useMemo(
    () => `${currentPatient?.firstName || ''} ${currentPatient?.lastName || ''} (${currentPatient?.displayId})`,
    [currentPatient]
  )

  const handleUnlinkPatient = useCallback(() => {
    trackEvent('PMS - unlink patient clicked', {
      pmsType,
      patientId: patientPmsID
    })
    dispatch(actions.pmsUnlinkPatient({ pmsType }))
  }, [dispatch, patientPmsID, pmsType])

  const handleLinkPatient = useCallback(
    pmsPatient => {
      if (pmsPatient) {
        trackEvent('PMS - link patient clicked', {
          pmsType,
          patientId: pmsPatient.displayId
        })
        dispatch(
          actions.pmsLinkPatient({
            pmsType,
            pmsPatientId: pmsPatient.id
          })
        )
      }
      setIsSearchPatientMode(false)
    },
    [dispatch, pmsType]
  )

  useEffect(() => {
    if (!!patientPmsID && !currentPatient) {
      dispatch(actions.pmsFetchPatient(patientPmsID))
    }
  }, [currentPatient, dispatch, patientPmsID])

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <img src={images.dolphinLogo} alt={'Dolphin Logo'} className={classes.logo} />
      </Grid>
      <Grid item>
        <DazzedParagraph14 bold>{t('dialogs.patientInfo.appointments.pms.dolphinLinkage')}</DazzedParagraph14>
      </Grid>
      {patientPmsID ? (
        isLoading && !currentPatient ? (
          <Grid item>
            <CircularProgress size={30} />
          </Grid>
        ) : (
          <>
            <Grid item>
              <DazzedParagraph14 strong>{pmsPatientLabel}</DazzedParagraph14>
            </Grid>
            <Grid item onClick={handleUnlinkPatient}>
              <Grid container alignItems="center">
                <Grid item className={`${classes.unlinkIcon} ${classes.linkPatientText}`}>
                  <Unlink />
                </Grid>
                <Grid item>
                  <DazzedParagraph14 bold className={classes.linkPatientText}>
                    {t('dialogs.patientInfo.appointments.pms.unlink')}
                  </DazzedParagraph14>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      ) : !isSearchPatientMode ? (
        <>
          <Grid item>
            <DazzedParagraph14 bold className={classes.unlinked}>
              {t('dialogs.patientInfo.appointments.pms.unlinked')}
            </DazzedParagraph14>
          </Grid>
          <Grid item>
            <DazzedParagraph14 bold className={classes.linkPatientText} onClick={() => setIsSearchPatientMode(true)}>
              {t('dialogs.patientInfo.appointments.pms.searchPatientToLink')}
            </DazzedParagraph14>
          </Grid>
        </>
      ) : (
        <PmsSearchPatient
          onClose={handleLinkPatient}
          onSelect={handleLinkPatient}
          onSearchAnalyticEvent={() =>
            trackEvent('PMS appointments tab - searched for pms patient for linking', {
              pmsType
            })
          }
        />
      )}
    </Grid>
  )
}

export default PmsLinkPatientRow
