import { Grid } from '@material-ui/core'
import { ROUTES } from 'consts'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import ReferralHeader from './ReferralHeader'
import ReferralLogin from './ReferralLogin'
import ReferralViewScan from './ReferralViewScan/ReferralViewScan'
import useCssClasses from 'hooks/useCssClasses'
import { setCloudFrontUrl } from 'utils/storageUtils'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import SessionExpiredModal from './SessionExpiredModal'

const ReferralsLandingPage = () => {
  const dispatch = useDispatch()
  useCssClasses()

  const appconfig = useSelector(state => state.appReducer.appconfig)

  useEffect(() => setCloudFrontUrl(), [])

  useEffect(() => {
    if (isEmpty(appconfig)) {
      dispatch(Actions.fetchAppConfig())
    }
  }, [appconfig, dispatch])

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <ReferralHeader />
        </Grid>
        <Grid item>
          <Switch>
            <Route exact path={ROUTES.REFERRAL_LOGIN} component={ReferralLogin} />
            <Route exact path={ROUTES.REFERRAL_VIEW_SCAN} component={ReferralViewScan} />
            <Redirect to={`${ROUTES.REFERRAL_LOGIN}${window.location.search}`} />
          </Switch>
        </Grid>
      </Grid>
      <SessionExpiredModal />
    </>
  )
}

export default ReferralsLandingPage
