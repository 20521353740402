import * as React from 'react'

const Persons = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.50484 0.897827C4.45433 0.897827 2.78613 2.56603 2.78613 4.61654C2.78613 6.66705 4.45433 8.33525 6.50484 8.33525C8.55535 8.33525 10.2235 6.66705 10.2235 4.61654C10.2235 2.56603 8.55535 0.897827 6.50484 0.897827ZM6.50484 7.1633C5.10051 7.1633 3.95801 6.0208 3.95801 4.61646C3.95801 3.21212 5.10051 2.06962 6.50484 2.06962C7.90917 2.06962 9.05167 3.21212 9.05167 4.61646C9.05167 6.0208 7.90917 7.1633 6.50484 7.1633Z"
        fill="black"
      />
      <path
        d="M17.0703 11.065H12.6654C12.5347 11.065 12.4063 11.0744 12.2801 11.0911C11.4645 9.82893 10.045 8.9917 8.43363 8.9917H4.57641C2.05301 8.9917 0 11.0446 0 13.5681V18.5163C0 18.8399 0.262344 19.1023 0.585938 19.1023H10.3216H12.4242H19.4141C19.7377 19.1023 20 18.8399 20 18.5163V13.9947C20 12.3792 18.6857 11.065 17.0703 11.065ZM9.7357 13.9947V17.9304H1.17188V13.5681C1.17188 11.6908 2.69918 10.1636 4.57645 10.1636H8.43367C9.53273 10.1636 10.5117 10.6872 11.1346 11.498C10.2961 12.0139 9.7357 12.94 9.7357 13.9947ZM18.8281 17.9303H11.8383H10.9076V13.9947C10.9076 13.0254 11.6961 12.2369 12.6654 12.2369H17.0703C18.0396 12.2369 18.8281 13.0254 18.8281 13.9947V17.9303Z"
        fill="black"
      />
      <path
        d="M14.8681 4.28198C13.2182 4.28198 11.876 5.62421 11.876 7.27409C11.876 8.92393 13.2182 10.2662 14.8681 10.2662C16.5179 10.2662 17.8602 8.92393 17.8602 7.27409C17.8602 5.62425 16.518 4.28198 14.8681 4.28198ZM14.868 9.09432C13.8644 9.09432 13.0478 8.2778 13.0478 7.27413C13.0478 6.27046 13.8644 5.45389 14.868 5.45389C15.8717 5.45389 16.6883 6.27042 16.6883 7.27413C16.6883 8.27776 15.8717 9.09432 14.868 9.09432Z"
        fill="black"
      />
    </svg>
  )
}

export default Persons
