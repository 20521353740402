import React, { useCallback } from 'react'
import { makeStyles, TableCell, Grid } from '@material-ui/core'

const useStyles = ({ highlighted, rowIndex, disabled }) =>
  makeStyles({
    root: {
      height: '78px',
      borderTop: rowIndex === 0 && highlighted ? '1px solid' : 0,
      borderColor: highlighted ? 'var(--border-color-1)' : 'var(--border-color-11)'
    },
    button: {
      '&:hover div': {
        textDecoration: disabled ? 'none' : 'underline'
      },
      width: '100%',
      cursor: disabled ? 'default' : 'pointer'
    },
    label: {
      fontSize: '14px',
      paddingLeft: 10,
      fontFamily: 'Dazzed',
      color: disabled ? 'var(--text-color-11)' : 'var(--text-color-8)',
      textAlign: 'start'
    },
    subtitle: {
      color: 'var(--text-color-11)'
    },
    icon: {
      marginTop: 2
    }
  })({ highlighted, rowIndex, disabled })

const ActionRequiredCell = ({
  rowIndex,
  actionRequired = false,
  label = '',
  subtitle = '',
  IconComponent = () => <div />,
  onClick = () => null,
  disabled = false
}) => {
  const classes = useStyles({ rowIndex, disabled })

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick()
    }
  }, [disabled, onClick])

  return (
    <TableCell align="left" classes={{ root: classes.root }} onClick={handleClick}>
      {actionRequired ? (
        <Grid container alignItems="flex-start" wrap="nowrap" className={classes.button}>
          <Grid item className={classes.icon}>
            <IconComponent disabled={disabled} />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <div className={classes.label}>{label}</div>
              <div className={[classes.label, classes.subtitle].join(' ')}>{subtitle}</div>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </TableCell>
  )
}

export default React.memo(ActionRequiredCell)
