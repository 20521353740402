import { AsyncStatus } from 'consts'
import Actions from '../actions'

const initialState = {
  notifyPractice: {
    savingStatus: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.HI_NOTIFY_PRACTICE:
      return {
        ...state,
        notifyPractice: {
          ...state.notifyPractice,
          savingStatus: AsyncStatus.Loading
        }
      }
    case Actions.HI_NOTIFY_PRACTICE_RECEIVED:
      return {
        ...state,
        notifyPractice: {
          ...state.notifyPractice,
          savingStatus: AsyncStatus.Completed
        }
      }
    case Actions.HI_NOTIFY_PRACTICE_FAILED:
      return {
        ...state,
        notifyPractice: {
          ...state.notifyPractice,
          savingStatus: AsyncStatus.Failed
        }
      }
    default:
      return state
  }
}
