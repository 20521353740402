import * as React from 'react'

const Unlink = ({ className, color = '#727B8C' }) => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.86237 12.0225L5.96516 9.12531C5.38331 8.54346 5.3833 7.60009 5.96516 7.01824L7.01869 5.96471C7.60054 5.38286 8.54391 5.38286 9.12576 5.96471L12.023 8.86193"
        stroke="#4151E6"
        strokeWidth="1.5"
      />
      <path
        d="M14.1298 10.9692L17.027 13.8664C17.6089 14.4482 17.6089 15.3916 17.027 15.9735L15.9735 17.027C15.3916 17.6088 14.4483 17.6088 13.8664 17.027L10.9692 14.1298"
        stroke="#4151E6"
        strokeWidth="1.5"
      />
      <path d="M17.8177 6.22834L16.7642 5.1748L14.6571 8.33541L17.8177 6.22834Z" fill="#4151E6" />
      <path d="M14.0715 3.5625H12.5815L13.3265 7.28731L14.0715 3.5625Z" fill="#4151E6" />
      <path d="M19.3896 10.3971V8.90723L15.6648 9.65219L19.3896 10.3971Z" fill="#4151E6" />
      <path d="M6.22834 17.8167L5.1748 16.7632L8.33541 14.6561L6.22834 17.8167Z" fill="#4151E6" />
      <path d="M3.5625 14.071V12.5811L7.28731 13.326L3.5625 14.071Z" fill="#4151E6" />
      <path d="M10.3976 19.3896H8.90771L9.65268 15.6648L10.3976 19.3896Z" fill="#4151E6" />
    </svg>
  )
}

export default Unlink
