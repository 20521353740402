import moment from 'moment'
import { getObjectUrl } from './mediaUtils'

let activePromises = {}

const resolveByUri = (key, uri) => {
  activePromises[key].forEach(promise => {
    promise.resolve({ uri })
  })
  delete activePromises[key]
}

export const getMedia = (s3Object, forceResignUrl = false) =>
  new Promise(async (resolve, reject) => {
    const key = s3Object.key

    if (!activePromises[key]) {
      activePromises[key] = [{ resolve, reject }].concat(activePromises[key] || [])

      const sessionImage = JSON.parse(sessionStorage.getItem(key))

      if (!forceResignUrl && sessionImage && moment(sessionImage.expiration).isAfter(moment())) {
        resolveByUri(key, sessionImage.url)
      } else {
        const url = getObjectUrl(s3Object)
        url && sessionStorage.setItem(key, JSON.stringify({ url, expiration: moment().add(1, 'hours') }))
        resolveByUri(key, url)
      }
    } else {
      activePromises[key] = [{ resolve, reject }].concat(activePromises[key] || [])
    }
  })
