import { makeStyles } from '@material-ui/styles'
import React, { useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import Oval from '../../common/icons/Oval'
import OnboardingStep1 from '../../common/icons/rc-landing-page/OnboardingStep1'
import OnboardingStep2 from '../../common/icons/rc-landing-page/OnboardingStep2'
import OnboardingStep3 from '../../common/icons/rc-landing-page/OnboardingStep3'
import OnboardingStep4 from '../../common/icons/rc-landing-page/OnboardingStep4'
import TimelineArrowHorizontal from '../../common/icons/TimelineArrowHorizontal'
import TimelineArrowVertical from '../../common/icons/TimelineArrowVertical'
import OggHeading40 from '../../common/text/OggHeading40'
import useRcDetails from '../useRcDetails'
import OnboardingStepText from './OnboardingStepText'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: ({ isMobile }) => (isMobile ? '0 20px' : '50px 0'),
      width: ({ isMobile }) => (isMobile ? 'auto' : '1189px'),
      margin: '0 auto'
    },
    timelineContainer: {
      display: 'flex',
      flexDirection: ({ isMobile }) => (isMobile ? 'row-reverse' : 'column'),
      alignItems: 'center'
    },
    arrow: {
      margin: ({ isMobile }) => (isMobile ? '0 10px' : '10px 0')
    },
    timelineTitle: {
      fontSize: 50,
      marginBottom: 50
    },
    eventsList: {
      display: 'flex',
      width: '100%',
      flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
      height: ({ isMobile }) => (isMobile ? '1581px' : '')
    },
    event: {
      flex: 1,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    icon: {
      textAlign: 'center'
    },
    bullet: {
      position: 'absolute',
      left: ({ isMobile }) => (isMobile ? '-20px' : 'calc(50% - 12px)'),
      bottom: ({ isMobile }) => (isMobile ? 'calc(50% - 65px)' : '-30px')
    }
  }),
  { isMobile }
)

const OnboardingSteps = () => {
  const classes = useStyles({ isMobile })
  const { t } = useTranslation()
  const rcConfig = useRcDetails()

  const onboardingType = useMemo(() => rcConfig.onboardingType.onboardingTypeKey, [rcConfig])

  const steps = useMemo(
    () => [
      {
        text: t(`pages.rcLanding.body.steps.${onboardingType}.step1`, { doctorName: rcConfig.name }),
        flip: true,
        icon: <OnboardingStep1 />
      },
      { text: t(`pages.rcLanding.body.steps.${onboardingType}.step2`), icon: <OnboardingStep2 /> },
      { text: t(`pages.rcLanding.body.steps.${onboardingType}.step3`), flip: true, icon: <OnboardingStep3 /> },
      { text: t(`pages.rcLanding.body.steps.${onboardingType}.step4`), icon: <OnboardingStep4 /> }
    ],
    [t, onboardingType, rcConfig.name]
  )

  return (
    <div className={classes.root}>
      <OggHeading40 className={classes.timelineTitle} textAlign={isMobile ? 'start' : 'center'}>
        {t('pages.rcLanding.body.timelineTitle')}
      </OggHeading40>
      <div className={classes.timelineContainer}>
        <div className={classes.eventsList}>
          {isMobile
            ? steps.map((event, index) => (
                <div className={classes.event} key={`event_top_${index}`}>
                  <div>
                    <div className={classes.icon}>{event.icon}</div>
                    <OnboardingStepText text={event.text} index={index + 1} />
                    <Oval
                      className={classes.bullet}
                      diameter={24}
                      color={rcConfig.colorSettings.accentColor || rcConfig.colorSettings.secondary}
                    />
                  </div>
                </div>
              ))
            : steps.map((event, index) => (
                <div className={classes.event} key={`event_top_${index}`}>
                  <>
                    {event.flip ? (
                      <div className={classes.icon}>{event.icon}</div>
                    ) : (
                      <OnboardingStepText text={event.text} index={index + 1} />
                    )}
                    <Oval
                      className={classes.bullet}
                      diameter={24}
                      color={rcConfig.colorSettings.accentColor || rcConfig.colorSettings.secondary}
                    />
                  </>
                </div>
              ))}
        </div>
        {isMobile ? (
          <TimelineArrowVertical color={rcConfig.colorSettings.accentColor || rcConfig.colorSettings.secondary} />
        ) : (
          <>
            <TimelineArrowHorizontal
              className={classes.arrow}
              color={rcConfig.colorSettings.accentColor || rcConfig.colorSettings.secondary}
            />
            <div className={classes.eventsList}>
              {steps.map((event, index) => (
                <div className={classes.event} key={`event_bottom_${index}`}>
                  {event.flip ? (
                    <OnboardingStepText text={event.text} index={index + 1} />
                  ) : (
                    <div className={classes.icon}>{event.icon}</div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default OnboardingSteps
