import { makeStyles } from '@material-ui/styles'
import { take, takeRight } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'utils/mobileUtils'
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill'
import { countWhile } from 'utils/arrayUtils'
import { compareTags, TAGS_LIST_COMPRESSION_THRESHOLD } from 'utils/tagUtils'
import GrinChip from '../GrinChip'
import GrinMenu from '../menu/GrinMenu'
import GrinMenuItem from '../menu/GrinMenuItem'
import GrinTag from './GrinTag'
import TagsPicker from './TagsPicker'

const useStyles = makeStyles(({ isMobile }) => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  visibleTag: {
    marginRight: 10
  },
  showMoreChip: {
    color: 'var(--text-color-16)',
    cursor: 'pointer'
  },
  tag: {
    borderRadius: 30,
    height: ({ isMobile }) => (isMobile ? 30 : 35),
    padding: ({ isMobile }) => (isMobile ? 2 : 5),
    backgroundColor: '#F0F0F0'
  }
}))

const FilterTagsList = ({
  tags = [],
  className,
  showIcon = true,
  canDeleteTags = true,
  onDeleteTag,
  observeResize = true,
  compressionThreshold = TAGS_LIST_COMPRESSION_THRESHOLD
}) => {
  const classes = useStyles({ isMobile: isMobile() })
  const { t } = useTranslation()
  const { width, ref } = useResizeDetector()

  const [displayedTags, setDisplayedTags] = useState([])
  const [hiddenTags, setHiddenTags] = useState([])
  const [hiddenTagsAmount, setHiddenTagsAmount] = useState(0)
  const [isHiddenTagsDropdownOpen, setIsHiddenTagsDropdownOpen] = useState(false)
  const [tagsWidth, setTagsWidth] = useState([])

  const sortedTags = useMemo(() => tags.sort(compareTags), [tags])

  useEffect(() => {
    const updatedDisplayedTags = take(sortedTags, sortedTags.length - hiddenTagsAmount)
    const updatedHiddenTags = takeRight(sortedTags, hiddenTagsAmount)
    setDisplayedTags(updatedDisplayedTags)
    setHiddenTags(updatedHiddenTags)
  }, [sortedTags, hiddenTagsAmount])

  const isTagElement = useCallback(element => element.children.item(0).classList.contains('tag-visible'), [])

  const handleDelete = useCallback(
    tag => {
      const deletedTagIndex = sortedTags.findIndex(sortedTag => sortedTag.value === tag.value)
      const oldTagsWidth = [...tagsWidth]
      oldTagsWidth.splice(deletedTagIndex, 1)
      onDeleteTag(tag)
      setTagsWidth(oldTagsWidth)
    },
    [onDeleteTag, sortedTags, tagsWidth]
  )

  const fitTagsToScreen = useCallback(
    (width, allTagsWidth) => {
      const fitInTagsCount = countWhile(allTagsWidth, nextWidth => nextWidth < width - compressionThreshold)
      setHiddenTagsAmount(tags.length - fitInTagsCount)
    },
    [tags.length, compressionThreshold]
  )

  const calculateAllTagsWidth = useCallback(() => {
    const tagElementWidths =
      !tagsWidth.length &&
      Array.from(ref.current.children)
        .filter(isTagElement)
        .map(element => element.offsetWidth)

    if (!tagsWidth.length) setTagsWidth(tagElementWidths)

    return tagElementWidths || tagsWidth
  }, [isTagElement, tagsWidth, ref])

  useEffect(() => {
    if (!width) {
      return
    }

    const allTagsWidth = calculateAllTagsWidth()
    fitTagsToScreen(width, allTagsWidth)
  }, [sortedTags.length, fitTagsToScreen, width, calculateAllTagsWidth])

  return (
    <div className={[classes.root, className].join(' ')} ref={ref}>
      {displayedTags.map(tag => (
        <GrinTag
          key={tag.value}
          tag={tag}
          className={[classes.tag, classes.visibleTag, 'tag-visible'].join(' ')}
          canDeleteSystem={canDeleteTags}
          canDeleteCustom={canDeleteTags}
          canDeleteStatus={canDeleteTags}
          onDelete={() => handleDelete(tag)}
        />
      ))}
      {hiddenTagsAmount > 0 && (
        <>
          <GrinMenu
            transformOrigin={{ vertical: -30, horizontal: 'left' }}
            disableHoverEffects
            triggerComponent={
              <div>
                <GrinChip
                  text={t('pages.patients.selectedPatient.tags.showMore', { amount: hiddenTags.length })}
                  className={classes.showMoreChip}
                />
              </div>
            }
            open={isHiddenTagsDropdownOpen}
            onClose={() => setIsHiddenTagsDropdownOpen(false)}
            onOpen={() => setIsHiddenTagsDropdownOpen(true)}
          >
            {hiddenTags.map(tag => (
              <div key={tag.value}>
                <GrinMenuItem nonActionable>
                  <GrinTag
                    tag={tag}
                    className={classes.tag}
                    canDeleteSystem={canDeleteTags}
                    canDeleteCustom={canDeleteTags}
                    canDeleteStatus={canDeleteTags}
                    onDelete={() => handleDelete(tag)}
                  />
                </GrinMenuItem>
              </div>
            ))}
          </GrinMenu>
        </>
      )}
      <TagsPicker showIcon={showIcon} />
    </div>
  )
}

export default FilterTagsList
