import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import PatientDetails from 'components/common/PatientDetails'
import { useSelector } from 'react-redux'
import { compareTags } from 'utils/tagUtils'

const useStyles = makeStyles({
  video: {
    width: 710,
    height: 400
  }
})

const ScanWithPatientDetails = ({ grinScanId, videoContainerClassName = '', videoClassName = '' }) => {
  const classes = useStyles()
  const { patient } = useSelector(state => state.patientsReducer)
  const grinScan = useSelector(state => state.patientsReducer.patient?.grinScans?.items || []).find(
    scan => scan.id === grinScanId
  )

  const scanVideoUrl = useMemo(
    () => (grinScan?.video?.url?.includes('#t=') ? grinScan.video.url : `${grinScan?.video?.url}#t=0.001`),
    [grinScan?.video?.url]
  )
  const tags = useMemo(
    () =>
      patient.patientTags?.items
        ?.filter(patientTag => patientTag.tag.type === 'treatment' || patientTag.tag.type === 'program')
        .map(pt => pt.tag)
        .sort(compareTags),
    [patient]
  )

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <PatientDetails name={patient?.details?.name} photo={patient?.details?.photo} tags={tags} />
      </Grid>
      <Grid item className={videoContainerClassName}>
        <video controls preload="metadata" muted className={`${classes.video} ${videoClassName}`} playsInline>
          <source src={scanVideoUrl} type="video/mp4" />
        </video>
      </Grid>
    </Grid>
  )
}

export default ScanWithPatientDetails
