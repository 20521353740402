import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { Link } from '@material-ui/core'
import ROUTES from 'consts/routesConsts'

const useStyles = makeStyles({
  root: {
    margin: '0px 20px 20px 0',
    minWidth: 300,
    height: 'calc(100% - 20px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
})

const DashboardCard = ({ label, route }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          <b>{label}</b> {t('pages.opsDashboard.tiles.dashboard')}
        </Typography>
        <br />
        <Typography variant="body2" component="p">
          {t('pages.opsDashboard.tiles.description1')}
          <br />
          {t('pages.opsDashboard.tiles.description2', { label })}
        </Typography>
      </CardContent>
      <CardActions>
        <Link component={RouterLink} to={`${ROUTES.SUPPORT_DASHBOARD}/${route}`}>
          <Button size="small">{t('pages.opsDashboard.tiles.view')}</Button>
        </Link>
      </CardActions>
    </Card>
  )
}

export default DashboardCard
