import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import moment from 'moment'
import { isEmpty } from 'lodash'
import Actions from 'actions'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import CustomDatePicker from 'components/common/CustomDatePicker'
import TreatmentTypePicker from './TreatmentTypePicker'
import StatusesPicker from './StatusesPicker'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import LabeledInputContainer from 'components/common/inputs/LabeledInputContainer'
import FrequencyPicker from 'components/common/inputs/FrequencyPicker'
import { getPeriodTypeOptions, ScanFrequencyOptions } from 'utils/statusUtils'
import WarningHexagonIcon from 'components/common/icons/WarningHexagon'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import useRolePermissions from 'hooks/useRolePermissions'
import { CONTENT_HEIGHT } from 'consts/patientCard'
import {
  ALIGNERS_TREATMENT_TYPE,
  APPLIANCE_SETS_MIN_NUMBER,
  APPLIANCE_SETS_MAX_NUMBER,
  CycleStatuses,
  CYCLE_END_DATE_FORMAT
} from 'consts/treatmentConsts'
import AlignersSets from './AlignersSets'
import { trackEvent } from 'utils/analyticsUtils'
import GrinCheckbox from 'components/common/GrinCheckbox'
import Tooltip from 'components/common/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import NextScanDateUpdatedTooltip from './NextScanDateUpdatedTooltip'
import DazzedParagraph11 from 'components/common/text/DazzedParagraph11'
import { selectStatuses } from 'selectors/statusesSelector'
import usePlanLimits from 'hooks/usePlanLimits'

const useStyles = makeStyles(theme => ({
  content: {
    height: CONTENT_HEIGHT,
    padding: 50,
    paddingLeft: '5%',
    position: 'relative'
  },
  loaderContainer: {
    height: CONTENT_HEIGHT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  inputContainer: {
    padding: 12
  },
  datePickerInput: {
    color: theme.palette.primary.main
  },
  treatmentStatusPicker: {
    margin: 0
  },
  needRmProgramWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  needRmProgram: {
    maxWidth: 490,
    marginBottom: 70,
    textAlign: 'center'
  },
  warningText: {
    margin: '0 10px',
    color: 'var(--text-color-7)'
  },
  pauseScanCheckbox: {
    marginLeft: '-9px !important'
  },
  overdueNote: {
    borderRadius: '4px',
    border: '1px solid var(--border-color-23)',
    background: 'var(--bg-color-63)',
    padding: 4
  }
}))

const ScanSettingsTab = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    data: treatment,
    isLoading,
    isUpdating: isUpdatingTreatment
  } = useSelector(state => state.patientsReducer.patientCard.treatment)
  const patient = useSelector(state => state.patientsReducer.patientCard.patient)
  const doctorUsername = useSelector(state => state.profileReducer.doctor?.username)
  const { data: statuses, isSaving: isUpdatingStatus } = useSelector(selectStatuses)
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()
  const { isChurnedPlan } = usePlanLimits()

  const [treatmentEndDate, setTreatmentEndDate] = useState(null)
  const [treatmentStartDate, setTreatmentStartDate] = useState(null)
  const [treatmentType, setTreatmentType] = useState(null)
  const [cycleInterval, setCycleInterval] = useState('')
  const [cycleIntervalPeriodType, setCycleIntervalPeriodType] = useState('')
  const [nextScanDate, setNextScanDate] = useState()
  const [status, setStatus] = useState(null)
  const [errors, setErrors] = useState({})
  const [totalAlignersSets, setTotalAlignersSets] = useState('')
  const [cycleStatus, setCycleStatus] = useState(CycleStatuses.Active)
  const [isNextScanUpdatedTooltipVisible, setIsNextScanUpdatedTooltipVisible] = useState(false)

  useEffect(() => setTreatmentType(treatment?.type), [treatment])

  const isPatientOverdue = useMemo(
    () =>
      patient?.patientTags?.items?.find(pt => pt.tag.type === 'scan' && pt.tag.value === 'overdueScan' && !pt._deleted),
    [patient]
  )
  const isEditPatientScanSettingsForbidden = useMemo(
    () => status?.type === 'transferred' || !permissions.editPatientScanSettings,
    [permissions, status]
  )
  const originalStatus = useMemo(() => treatment?.currentStatus?.status, [treatment?.currentStatus?.status])
  const programStatuses = useMemo(() => statuses[patient.user.program] || [], [statuses, patient])
  const postConfirmationStatus = useMemo(
    () =>
      treatment?.currentStatus?.status?.type === 'invited'
        ? programStatuses.find(option => option.key === treatment?.postConfirmationStatusKey)
        : null,
    [programStatuses, treatment]
  )
  const scanFrequencyDefaults = useMemo(() => JSON.parse(status?.scanFrequencyDefaults || '{}'), [status])
  const areScanSettingsDisabled = useMemo(() => !status?.scanCyclesEnabled, [status])
  const didPatientDisableAccount = useMemo(
    () =>
      (treatment?.currentStatus?.status?.type === 'archived' ||
        treatment?.currentStatus?.status?.type === 'transferred') &&
      treatment?.currentStatus?.setByUsername === patient?.username,
    [patient, treatment]
  )

  const frequencyOptions = useMemo(() => {
    if (areScanSettingsDisabled) {
      return ScanFrequencyOptions[postConfirmationStatus?.type || 'active-treatment']
    }

    return ScanFrequencyOptions[status?.type]
  }, [areScanSettingsDisabled, postConfirmationStatus, status])

  const warningText = useMemo(() => {
    const currentTreatmentStatus = treatment?.currentStatus

    if (currentTreatmentStatus?.status?.type === 'invited') {
      return t('dialogs.patientInfo.scanSettings.warnings.stillInvited')
    } else if (didPatientDisableAccount) {
      return t('dialogs.patientInfo.scanSettings.warnings.patientDisabledHisAccount', {
        date: moment(currentTreatmentStatus?.createdAt).format('MMMM Do, YYYY')
      })
    }

    return (
      <span>
        {t('dialogs.patientInfo.scanSettings.warnings.changeTreatmentStatus_1')}
        <strong>{t('dialogs.patientInfo.scanSettings.warnings.changeTreatmentStatus_2')}</strong>
        {t('dialogs.patientInfo.scanSettings.warnings.changeTreatmentStatus_3')}
      </span>
    )
  }, [didPatientDisableAccount, t, treatment])

  const isNextScanDateDisabled = useMemo(
    () =>
      !nextScanDate ||
      areScanSettingsDisabled ||
      isEditPatientScanSettingsForbidden ||
      cycleStatus === CycleStatuses.Paused,
    [nextScanDate, areScanSettingsDisabled, isEditPatientScanSettingsForbidden, cycleStatus]
  )

  const updateNextScanDateFromFrequency = useCallback(() => {
    setNextScanDate(moment().add(cycleInterval, cycleIntervalPeriodType))
  }, [cycleInterval, cycleIntervalPeriodType])

  const handlePauseScansToggle = useCallback(
    checked => {
      setCycleStatus(checked ? CycleStatuses.Paused : CycleStatuses.Active)
      setIsNextScanUpdatedTooltipVisible(!checked)

      if (!checked) {
        updateNextScanDateFromFrequency()
      }
    },
    [updateNextScanDateFromFrequency]
  )

  const handleCycleIntervalChanged = useCallback(
    value => {
      setCycleInterval(value)
      setNextScanDate(moment().add(value, cycleIntervalPeriodType))
      setIsNextScanUpdatedTooltipVisible(true)
    },
    [cycleIntervalPeriodType]
  )

  const handleStatusChange = useCallback(
    status => {
      setStatus(status)
      const statusScanDefaults = JSON.parse(status?.scanFrequencyDefaults || '{}')
      setCycleInterval(statusScanDefaults.periodLength || ScanFrequencyOptions['active-treatment'][0])
      setCycleIntervalPeriodType(statusScanDefaults?.periodType || 'day')

      if (!originalStatus?.scanCyclesEnabled) {
        setNextScanDate(moment().add(statusScanDefaults.periodType, statusScanDefaults.periodLength))
      }
    },
    [originalStatus]
  )

  const validateForm = useCallback(() => {
    const startOfCurrentCycleEndDate = moment(nextScanDate).startOf('day')
    const startOfTreatmentStartDate = moment(treatmentStartDate).startOf('day')
    const startOfTreatmentEndDate = moment(treatmentEndDate).startOf('day')

    let errors = {}

    if (treatmentEndDate && startOfTreatmentStartDate.isAfter(startOfTreatmentEndDate)) {
      errors.treatmentEndDate = t('dialogs.scanFrequency.startEndTreatmentDateError')
    }

    if (
      startOfCurrentCycleEndDate.isBefore(startOfTreatmentStartDate) ||
      (status?.type !== 'retention' && startOfCurrentCycleEndDate.isAfter(startOfTreatmentEndDate))
    ) {
      errors.nextScanDate = t('dialogs.scanFrequency.nextScanDateError')
    }

    if (!treatmentType) {
      errors.treatmentType = t('errors.requiredField')
    }

    if (
      treatmentType === ALIGNERS_TREATMENT_TYPE &&
      totalAlignersSets &&
      totalAlignersSets > APPLIANCE_SETS_MAX_NUMBER
    ) {
      errors.totalAlignersSets = t('errors.maxSetsAmount', { number: APPLIANCE_SETS_MAX_NUMBER })
    }

    if (
      treatmentType === ALIGNERS_TREATMENT_TYPE &&
      totalAlignersSets &&
      totalAlignersSets < APPLIANCE_SETS_MIN_NUMBER
    ) {
      errors.totalAlignersSets = t('errors.minSetsAmount', { number: APPLIANCE_SETS_MIN_NUMBER })
    }

    return errors
  }, [nextScanDate, status, t, treatmentEndDate, treatmentStartDate, treatmentType, totalAlignersSets])

  const handleSaveChanges = useCallback(() => {
    const errors = validateForm()
    setErrors(errors)

    if (!isEmpty(errors)) {
      return
    }

    let updateTreatmentInput = {
      treatmentId: treatment.id,
      date: treatmentStartDate && moment(treatmentStartDate).toISOString(),
      endDate: treatmentEndDate && treatmentEndDate?.format(CYCLE_END_DATE_FORMAT),
      type: treatmentType,
      cycleInterval,
      cycleIntervalPeriodType: cycleIntervalPeriodType || 'day',
      totalApplianceNumber: treatmentType === ALIGNERS_TREATMENT_TYPE && totalAlignersSets ? totalAlignersSets : null,
      cycleStatus
    }

    if (status?.key !== originalStatus?.key) {
      updateTreatmentInput.statusKey = status?.key
      updateTreatmentInput.statusSetterUsername = doctorUsername
      updateTreatmentInput.nextScanDate = nextScanDate.format(CYCLE_END_DATE_FORMAT)
    } else if (!areScanSettingsDisabled) {
      updateTreatmentInput.nextScanDate = nextScanDate.format(CYCLE_END_DATE_FORMAT)
    }
    trackEvent('Scan settings tab - changes saved')
    if (patient.isLead) {
      dispatch(
        Actions.requestPatientDetailsUpdate({
          ...patient,
          isLead: true,
          treatment: updateTreatmentInput
        })
      )
    } else {
      dispatch(Actions.updateTreatment(updateTreatmentInput))
    }
  }, [
    validateForm,
    treatment.id,
    treatmentStartDate,
    treatmentEndDate,
    treatmentType,
    cycleInterval,
    cycleIntervalPeriodType,
    totalAlignersSets,
    status?.key,
    originalStatus?.key,
    areScanSettingsDisabled,
    patient,
    doctorUsername,
    nextScanDate,
    cycleStatus,
    dispatch
  ])

  useEffect(() => {
    if (!treatment) {
      dispatch(Actions.requestPatientTreatment(patient))
    }
  }, [dispatch, patient, treatment])

  useEffect(() => {
    if (treatment?.id) {
      if (treatment?.endDate) {
        setTreatmentEndDate(moment(treatment?.endDate))
      } else {
        setTreatmentEndDate(null)
      }
      setTreatmentStartDate(treatment?.date)
      setTotalAlignersSets(treatment?.totalApplianceNumber)
    }
  }, [status, treatment?.date, treatment?.endDate, treatment?.id, treatment?.totalApplianceNumber])

  useEffect(() => {
    if (patient.cycles?.items?.length) {
      const currCycle = patient.cycles.items[0]
      setNextScanDate(moment(currCycle.originalEndDate))
      setCycleStatus(currCycle.status || CycleStatuses.Active)
    }
  }, [patient.cycles])

  useEffect(() => {
    setStatus(originalStatus)
  }, [originalStatus])

  useEffect(() => {
    setCycleInterval(treatment?.cycleInterval)
    setCycleIntervalPeriodType(treatment?.cycleIntervalPeriodType)
  }, [treatment])

  const periodTypeOptions = useMemo(() => getPeriodTypeOptions(status?.key), [status])

  const isUpdating = useMemo(() => isUpdatingTreatment || isUpdatingStatus, [isUpdatingTreatment, isUpdatingStatus])

  return isLoading && !treatment ? (
    <Grid container className={classes.loaderContainer}>
      <CircularProgress color="primary" />
    </Grid>
  ) : (
    <Grid container className={classes.content} direction="column" justifyContent="space-between">
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TreatmentTypePicker
                  treatmentType={treatmentType}
                  errorMessage={errors?.treatmentType}
                  onSet={value => {
                    setTreatmentType(value)
                    setErrors()
                  }}
                  disabled={isEditPatientScanSettingsForbidden}
                />
              </Grid>
              <Grid item xs={6}>
                <StatusesPicker
                  selectedStatus={status}
                  onSelect={handleStatusChange}
                  disabled={
                    isChurnedPlan ||
                    isEditPatientScanSettingsForbidden ||
                    (!permissions.reactivatePatientAccount && didPatientDisableAccount)
                  }
                  program={patient?.user.program}
                  originalStatus={originalStatus?.type}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ padding: '0 0 0 30px' }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CustomDatePicker
                  label={t('dialogs.patientInfo.scanSettings.beginningOfTreatment')}
                  style={{ marginRight: 33 }}
                  value={treatmentStartDate}
                  handleDateChange={newDate => setTreatmentStartDate(moment(newDate))}
                  disablePast={false}
                  inputClassName={classes.datePickerInput}
                  disabled={isEditPatientScanSettingsForbidden}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  label={t('dialogs.patientInfo.scanSettings.endOfTreatment')}
                  value={treatmentEndDate}
                  handleDateChange={newDate => setTreatmentEndDate(moment(newDate))}
                  inputClassName={classes.datePickerInput}
                  disablePast={false}
                  minDateMessage={t('dialogs.patientInfo.scanSettings.endOfTreatmentMinDateMessage')}
                  error={errors?.treatmentEndDate}
                  disabled={isEditPatientScanSettingsForbidden}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {treatmentType === ALIGNERS_TREATMENT_TYPE && (
        <Grid item>
          <AlignersSets
            alignersSets={totalAlignersSets}
            setAlignersSets={setTotalAlignersSets}
            errorMessage={errors?.totalAlignersSets}
          />
        </Grid>
      )}

      {areScanSettingsDisabled && (
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <WarningHexagonIcon />
            <DazzedParagraph16 className={classes.warningText}>{warningText}</DazzedParagraph16>
          </Grid>
        </Grid>
      )}

      {!areScanSettingsDisabled && (
        <Grid item>
          <GrinCheckbox
            checked={cycleStatus === CycleStatuses.Paused}
            onChange={handlePauseScansToggle}
            variant="filled"
            wrapperClassName={classes.pauseScanCheckbox}
            caption={
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <DazzedParagraph16 strong>
                    {t('dialogs.patientInfo.scanSettings.pauseScanCheckboxLabel')}
                  </DazzedParagraph16>
                </Grid>
                <Grid item>
                  <Tooltip value={t('dialogs.patientInfo.scanSettings.pauseScanCheckboxTooltip')} variant="dark">
                    <InfoIcon fontSize="inherit" color="disabled" />
                  </Tooltip>
                </Grid>
              </Grid>
            }
          />
        </Grid>
      )}

      <Grid item>
        <Grid container direction="row">
          <Grid item xs={areScanSettingsDisabled ? 12 : 9}>
            <LabeledInputContainer
              title={t('dialogs.invitePatient.scanFrequency')}
              tooltip={t('tooltips.cycleInterval', {
                periodType: t(`periodTypes.${cycleIntervalPeriodType}`)
              })}
            >
              <FrequencyPicker
                value={cycleInterval}
                setValue={handleCycleIntervalChanged}
                periodType={cycleIntervalPeriodType}
                defaultPeriodType={scanFrequencyDefaults?.periodType || cycleIntervalPeriodType}
                setPeriodType={setCycleIntervalPeriodType}
                options={frequencyOptions}
                periodTypeOptions={periodTypeOptions}
                disabled={areScanSettingsDisabled || isEditPatientScanSettingsForbidden}
              />
            </LabeledInputContainer>
          </Grid>
          {!areScanSettingsDisabled && (
            <Grid item xs={3} id="patient-card-set-next-scan">
              <NextScanDateUpdatedTooltip
                nextScanDate={nextScanDate}
                isOpen={isNextScanUpdatedTooltipVisible}
                onClose={() => setIsNextScanUpdatedTooltipVisible(false)}
              />
              <LabeledInputContainer
                title={t('dialogs.patientInfo.scanSettings.setNextScan')}
                tooltip={t('dialogs.invitePatient.firstScanDateTooltip')}
              >
                <CustomDatePicker
                  disabled={isNextScanDateDisabled}
                  value={nextScanDate?.toDate()}
                  handleDateChange={newDate => setNextScanDate(newDate ? moment(newDate) : null)}
                  minDateMessage={null}
                  disablePast={true}
                  error={errors?.nextScanDate}
                />
                {!isNextScanDateDisabled && isPatientOverdue && (
                  <DazzedParagraph11 className={classes.overdueNote}>
                    <Trans i18nKey={'dialogs.patientInfo.scanSettings.scanOverdueNote'} />
                  </DazzedParagraph11>
                )}
              </LabeledInputContainer>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction="row" justifyContent="center" alignContent="flex-end">
          <PrimaryButton
            label={t('general.saveChanges')}
            onClick={handleSaveChanges}
            isLoading={isUpdating}
            disabled={isEditPatientScanSettingsForbidden}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ScanSettingsTab
