import moment from 'moment'
import { makeStyles } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import React, { useCallback, useMemo, useState } from 'react'
import TimelineButtonBase from './TimelineButtonBase'
import TimeLineItemsByMonth from './TimeLineItemsByMonth'
import { isMobile } from 'utils/mobileUtils'
import { trackEvent } from 'utils/analyticsUtils'

const TimeLineItemsByYear = ({ year, monthsTimelineItems }) => {
  const useStyles = ({ isMobile }) =>
    makeStyles({
      arrow: {
        right: 0,
        marginRight: 18,
        position: 'relative'
      },
      container: {
        paddingLeft: isMobile ? '4.666%' : 20
      }
    })({ isMobile })

  const classes = useStyles({ isMobile: isMobile() })
  const shouldExpand = useMemo(
    () => !!monthsTimelineItems.find(monthWithTimelineItems => monthWithTimelineItems.shouldExpand),
    [monthsTimelineItems]
  )
  const [expand, setExpand] = useState(shouldExpand)

  const handleExpansion = useCallback(
    newExpand => {
      trackEvent('Timeline - year item clicked', {
        event: newExpand ? 'opened' : 'closed',
        yearDiff: moment().diff(year, 'years')
      })
      setExpand(newExpand)
    },
    [year]
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <TimelineButtonBase expand={expand} setExpand={handleExpansion} className={classes.arrow} value={year} />
      </Grid>
      <Collapse in={expand}>
        {monthsTimelineItems.map((monthWithTimelineItem, index) => (
          <TimeLineItemsByMonth
            key={index}
            monthName={monthWithTimelineItem.monthName}
            timelineItems={monthWithTimelineItem.timelineItems}
            shouldExpandMonth={monthWithTimelineItem.shouldExpand}
          />
        ))}
      </Collapse>
    </Grid>
  )
}

export default TimeLineItemsByYear
