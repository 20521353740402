import React, { useRef } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import Tooltip from 'components/common/Tooltip'
import Highlighter from 'react-highlight-words'

import { useLayoutEffect } from 'react'
import { useState } from 'react'

const useStyles = makeStyles({
  overflowRestrict: { overflow: 'hidden', textOverflow: 'ellipsis' }
})

const SearchFieldValue = ({ searchTerm, value }) => {
  const classes = useStyles()
  let ref = useRef()
  const [isOverflow, setIsOverflow] = useState(false)

  useLayoutEffect(() => {
    setIsOverflow(ref?.current?.offsetWidth < ref?.current?.scrollWidth)
  }, [ref])

  return (
    <Grid xs={12} item>
      <DazzedParagraph12 className={classes.overflowRestrict}>
        {
          <Tooltip
            disableTooltip={!isOverflow}
            placement="bottom"
            autoEscape={true}
            value={<Highlighter searchWords={[searchTerm]} textToHighlight={value} />}
          >
            <div ref={ref} className={classes.overflowRestrict}>
              <Highlighter autoEscape={true} searchWords={[searchTerm]} textToHighlight={value} />
            </div>
          </Tooltip>
        }
      </DazzedParagraph12>
    </Grid>
  )
}

export default SearchFieldValue
