import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import ScanSummary from '../ScanSummary/ScanSummary'
import DelayedScanOverlay from './DelayedScanOverlay'
import AlignersScanOverlay from '../AlignersScanOverlay'
import ScanApproval from './ScanApproval'
import Actions from '../../../../actions'
import moment from 'moment'
import useRolePermissions from 'hooks/useRolePermissions'
import { isMobile } from 'utils/mobileUtils'
import { trackEvent } from 'utils/analyticsUtils'
import ScanItemActions from './ScanItemActions'
import { getEnvironment } from 'utils/awsUtils'
import ScanReviewsList from './ScanReviewsList'
import ScanReviewItem from './ScanReviewItem'
import ScanReferralItem from './ScanReferralItem'
import { Roles } from 'consts/authConsts'

const useStyles = ({ isMobile }) =>
  makeStyles({
    scanReviewContainer: {
      position: 'relative',
      maxWidth: isMobile ? '78.666vw' : 350
    },
    actionButtonsContainer: {
      marginBottom: 20
    },
    video: {
      width: isMobile ? '78.666vw' : 350,
      borderRadius: 5,
      boxShadow: '1px 3px 4px gray',
      margin: '0 0 10px 0',
      '&:focus': {
        outline: 'none'
      },
      '&:-webkit-full-screen': {
        maxWidth: '100%'
      }
    },
    scanSummaryContainer: {
      width: isMobile ? '78.666vw' : 350
    },
    videoPlaceholder: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 190,
      width: isMobile ? '78.666vw' : 350
    },
    scanApprovalContainer: {
      maxWidth: isMobile ? '78.666vw' : 350
    }
  })({ isMobile })

const ScanItemDetails = ({ scan, scanIndex, showVideo }) => {
  const classes = useStyles({ isMobile: isMobile() })
  const dispatch = useDispatch()

  const { permissions, isUserOfRole } = useRolePermissions()
  const patient = useSelector(state => state.patientsReducer.patient)

  const userEmail = useSelector(state => state.profileReducer.doctor?.email)
  const isAllowedScanFeedback = useMemo(
    () => permissions.scanFeedback || (userEmail?.includes('debbie') && userEmail.includes('@get-grin.com')),
    [permissions, userEmail]
  )

  const [videoURL, setVideoURL] = useState('')

  const handleScanVideoPlayed = useCallback(
    () =>
      trackEvent('Scan - Scan video played', {
        patientId: patient?.id,
        program: patient?.user?.program,
        scanNumber: scanIndex
      }),
    [patient, scanIndex]
  )

  const minutesToUpload = useMemo(() => moment(scan.createdAt).diff(moment(scan.date), 'minutes'), [scan])
  const isDelayed = useMemo(() => minutesToUpload > 120, [minutesToUpload])

  const handleClick = useCallback(() => {
    if (getEnvironment() !== 'production' || isUserOfRole(Roles.DevAdmin)) {
      navigator.clipboard.writeText(scan.id)
    }
  }, [scan, isUserOfRole])

  useEffect(() => {
    scan?.video?.url && setVideoURL(scan?.video?.url)
  }, [scan?.video?.url])

  return (
    <Grid container direction="column">
      <Grid item xs={12} onClick={handleClick}>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            {!showVideo && <div className={classes.videoPlaceholder} />}
            {showVideo && (
              <video
                controls
                preload="metadata"
                muted
                className={classes.video}
                playsInline
                onPlay={handleScanVideoPlayed}
              >
                <source src={`${videoURL}#t=0.001`} type="video/mp4" />
              </video>
            )}
          </Grid>
          {
            //TODO : re-add this in mobile once michal has a design
            !isMobile() && isDelayed && (
              <Grid item>
                <DelayedScanOverlay date={moment(scan.date).format('MMM Do YYYY, LT')} />
              </Grid>
            )
          }
          {scan.withAligner != null && <AlignersScanOverlay withAligners={scan.withAligner} />}
        </Grid>
        {scan.scanSummaryStatus && (
          <Grid item className={classes.scanSummaryContainer}>
            <ScanSummary scan={scan} scanNumber={scanIndex} />
          </Grid>
        )}
      </Grid>
      {isAllowedScanFeedback && (
        <Grid item xs={12} className={classes.scanApprovalContainer}>
          <ScanApproval
            scan={scan}
            onFeedbackSelected={feedback => dispatch(Actions.updateScanFeedback({ id: scan.id, feedback }))}
          />
        </Grid>
      )}
      <Grid item xs={12} className={classes.scanReviewContainer}>
        <Grid container>
          <ScanReviewsList items={scan.scanReviews?.items} itemComponent={ScanReviewItem} />
          <ScanReviewsList items={scan.referrals?.items} itemComponent={ScanReferralItem} />
        </Grid>
        <ScanItemActions scan={scan} scanIndex={scanIndex} />
      </Grid>
    </Grid>
  )
}

export default ScanItemDetails
