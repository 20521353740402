import { ButtonBase, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ArrowDropdownDown from 'components/common/icons/ArrowDropdownDown'
import ArrowDropdownUp from 'components/common/icons/ArrowDropdownUp'
import GrinMenu from 'components/common/menu/GrinMenu'
import GrinMenuItem from 'components/common/menu/GrinMenuItem'
import React, { useCallback, useState } from 'react'
import { trackEvent } from 'utils/analyticsUtils'
import SendOption from './SendOption'

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    color: 'var(--text-color-1)',
    padding: '12px 0',
    height: '100%',
    overflow: 'hidden'
  },
  buttonLeft: {
    paddingLeft: '8px',
    paddingRight: '16px',
    borderLeft: '1px solid var(--border-color-22)'
  },
  menuItem: {
    padding: '12px 16px'
  }
}))

const SendOptionsMenu = ({ disabled, options }) => {
  const classes = useStyles()

  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false)

  const onSendOptionClick = useCallback(option => {
    option.action()
    setIsOptionsMenuOpen(false)

    trackEvent('Chat - send option selected', {
      type: option.type
    })
  }, [])

  const handleOptionsMenuOpen = useCallback(() => {
    setIsOptionsMenuOpen(true)
    trackEvent('Chat - send options menu opened')
  }, [])

  const handleOptionsMenuClose = useCallback(() => {
    setIsOptionsMenuOpen(false)
    trackEvent('Chat - send options menu closed')
  }, [])

  return (
    <GrinMenu
      disabled={disabled}
      onOpen={handleOptionsMenuOpen}
      onClose={handleOptionsMenuClose}
      open={isOptionsMenuOpen}
      transformOrigin={{ vertical: 65, horizontal: 0 }}
      triggerComponent={
        <ButtonBase
          disabled={disabled}
          id="patient-room-chat-send-options-menu"
          className={[classes.buttonRoot, classes.buttonLeft].join(' ')}
        >
          <Grid container>
            <Grid item>
              {isOptionsMenuOpen ? (
                <ArrowDropdownUp color="var(--text-color-1)" />
              ) : (
                <ArrowDropdownDown color="var(--text-color-1)" />
              )}
            </Grid>
          </Grid>
        </ButtonBase>
      }
    >
      {options.map((sendOption, i) => (
        <GrinMenuItem
          key={`chat-send-option-${i}`}
          onClick={() => onSendOptionClick(sendOption)}
          className={classes.menuItem}
        >
          <SendOption icon={sendOption.icon} label={sendOption.label} />
        </GrinMenuItem>
      ))}
    </GrinMenu>
  )
}

export default SendOptionsMenu
