import * as React from 'react'

const ScheduleVisit = ({ color = '#344563', ...props }) => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.409547 10.344H11.1288C11.2249 10.344 11.3178 10.3104 11.3915 10.2491C11.4945 10.1629 13.8431 8.1425 13.9863 3.78125H2.47404C2.33138 7.74009 0.168446 9.60086 0.145969 9.61943C0.0141932 9.73078 -0.0342895 9.91265 0.0249944 10.0745C0.0838682 10.2359 0.237301 10.344 0.409547 10.344V10.344Z"
        fill={color}
      />
      <path
        d="M13.5898 1.32035H11.5389V0.910175C11.5389 0.680477 11.3584 0.5 11.1287 0.5C10.899 0.5 10.7186 0.680477 10.7186 0.910175V1.32035H8.64035V0.910175C8.64035 0.680477 8.45988 0.5 8.23018 0.5C8.00048 0.5 7.82 0.680477 7.82 0.910175V1.32035H5.76913V0.910175C5.76913 0.680477 5.58865 0.5 5.35895 0.5C5.12926 0.5 4.94878 0.680477 4.94878 0.910175V1.32035H2.89791C2.66821 1.32035 2.48773 1.50083 2.48773 1.73052V2.96105H14V1.73052C14 1.50083 13.8195 1.32035 13.5898 1.32035Z"
        fill={color}
      />
      <path
        d="M11.9182 10.8769C11.6955 11.0624 11.4159 11.1633 11.1287 11.1633H2.48773V12.3938C2.48773 12.6206 2.67119 12.804 2.89791 12.804H13.5898C13.8165 12.804 14 12.6206 14 12.3938V7.7959C13.2091 9.73605 12.1056 10.7201 11.9182 10.8769Z"
        fill={color}
      />
    </svg>
  )
}

export default ScheduleVisit
