import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import BaseModal from 'components/common/modals/BaseModal'
import { StlViewer } from 'react-stl-viewer'
import { CircularProgress, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import PatientInfo from 'components/common/PatientInfo'
import { POSITION_KEYS } from 'consts/patientCard'
import StlInstructions from './StlInstructions'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: ({ isFullScreen }) => (isFullScreen ? '90%' : 1160),
    minHeight: ({ isFullScreen }) => (isFullScreen ? '90%' : 670),
    height: ({ isFullScreen }) => (isFullScreen ? '90%' : 'auto')
  },
  modalHeader: {
    padding: 32,
    width: '100%'
  },
  subtitle: {
    color: 'var(--text-color-11)',
    fontWeight: 600,
    textTransform: 'capitalize'
  },
  date: {
    color: 'var(--text-color-11)'
  },
  stl: {
    width: ({ isFullScreen }) => (isFullScreen ? '100%' : 900),
    height: ({ isFullScreen }) => (isFullScreen ? '100%' : 480),
    margin: '0 auto'
  },
  stlContainer: {
    flex: 1,
    width: '100%',
    height: '100%'
  },
  overlayLoader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  bottomContainer: {
    width: '100%'
  },
  contentContainer: {
    height: '100%'
  }
}))

const StlPreviewModal = ({ open = false, onClose, title, subtitle, fileUrl, filePosition, date, patient }) => {
  const { t } = useTranslation()

  const [isLoadingStl, setIsLoadingStl] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const classes = useStyles({ isFullScreen })

  useEffect(() => {
    if (open) {
      setIsFullScreen(false)
      setIsLoadingStl(true)
      return () => setIsLoadingStl(false)
    }
  }, [open])

  return (
    <BaseModal variant="card" open={open} handleClose={onClose} className={classes.container}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        className={classes.contentContainer}
      >
        <Grid item className={classes.modalHeader}>
          <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
            <Grid item>
              <PatientInfo patient={patient} />
            </Grid>
            <Grid item>
              <DazzedParagraph14 className={classes.subtitle}>
                {title} {subtitle && `(${subtitle})`}
              </DazzedParagraph14>
            </Grid>
            <Grid item>
              <DazzedParagraph14 className={classes.date}>
                {t('dialogs.patientInfo.records.stlPreview.uploadDate', { date })}
              </DazzedParagraph14>
            </Grid>
            <Grid item>
              <DazzedParagraph14 className={classes.subtitle}>
                {t(`pages.patients.selectedPatient.uploadStlsModal.${POSITION_KEYS[filePosition]}InputLabel`)}
              </DazzedParagraph14>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.stlContainer}>
          {open ? (
            <StlViewer
              url={fileUrl}
              shadows={true}
              orbitControls
              className={classes.stl}
              onFinishLoading={() => setIsLoadingStl(false)}
              onError={err => setIsLoadingStl(false)}
            />
          ) : (
            <div className={classes.stl}></div>
          )}
          {isLoadingStl && (
            <div className={classes.overlayLoader}>
              <CircularProgress />
            </div>
          )}
        </Grid>
        <Grid item className={classes.bottomContainer}>
          <StlInstructions onClickFullScreen={() => setIsFullScreen(!isFullScreen)} isFullScreen={isFullScreen} />
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default StlPreviewModal
