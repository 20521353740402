import { ConditionEffects, Conditions } from 'consts/autochartConsts'

export const createBlankCondition = ({ type, createdBy }) => {
  const createdAt = new Date().toISOString()
  return {
    type,
    note: '',
    properties: {},
    createdBy,
    editedBy: createdBy,
    surfaces: [],
    createdAt,
    updatedAt: createdAt
  }
}

export const validateCondition = condition => {
  const errors = []

  if (!condition?.type) {
    errors.push({ field: 'type', error: 'missing-value' })
    return errors
  }

  if (Conditions[condition.type].requireTextualNote && !condition.note) {
    errors.push({ field: 'note', error: 'missing-value' })
    return errors
  }

  const conditionDefinition = Conditions[condition.type]

  if (conditionDefinition.effect === ConditionEffects.Surfaces && !condition.surfaces?.length) {
    errors.push({ field: 'surfaces', error: 'missing-value' })
  }

  if (conditionDefinition.severityOptions && !condition.properties?.severity) {
    errors.push({ field: 'properties.severity', error: 'missing-value' })
  }

  return errors
}
