const versionNameRegex = /^\d+.{1}\d+.{1}\d+$/

export const isVersionPublished = (publish, version) => {
  const formattedVersion = extractMinorVersion(version.version)
  return !!publish.find(publishedVersion => publishedVersion.version === formattedVersion)
}

export const isVersionNameValid = versionName => {
  if (!versionName) {
    return false
  }

  return versionNameRegex.test(versionName)
}

export const extractMinorVersion = versionNumber => {
  if (!versionNumber) {
    return ''
  }

  const [major, minor] = versionNumber.split('.')
  return `${major}.${minor}`
}

export const formatRawVersionData = versionData => ({
  ...versionData,
  version: versionData.version
})
