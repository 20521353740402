import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase, Grid, Collapse, Divider } from '@material-ui/core'
import DazzedHeading14 from 'components/common/text/DazzedHeading14'
import ArrowDropdownUp from 'components/common/icons/ArrowDropdownUp'
import ArrowDropdownDown from 'components/common/icons/ArrowDropdownDown'
import CheckBoxFilter from './CheckBoxFilter'

const useStyles = makeStyles(theme => ({
  sectionContainer: {
    backgroundColor: '#F9F9F9'
  },
  headerContainer: {
    paddingLeft: 12,
    paddingRight: 12
  },
  header: {
    color: '#727B8C'
  },
  buttonContainer: {
    width: '100%',
    height: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    justifyContent: 'space-between',
    color: '#727B8C'
  },
  customTagsInnerContainer: {
    maxHeight: 250,
    overflow: 'overlay',
    paddingBottom: 8
  },
  divider: {
    marginLeft: -8,
    marginRight: -8
  }
}))

const FilterMenuSection = ({ label, onClick = () => {}, isOpen, tags, parsedSelectedFilters, onFilterSelected }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} className={classes.sectionContainer}>
      <Grid container spacing={2} className={classes.headerContainer}>
        <Grid item xs={12} onClick={onClick}>
          <ButtonBase disableTouchRipple className={classes.buttonContainer}>
            <DazzedHeading14 className={classes.header}>{label}</DazzedHeading14>
            {!isOpen && <ArrowDropdownDown color={'#727B8C'} size={12} />}
            {isOpen && <ArrowDropdownUp size={12} />}
          </ButtonBase>
        </Grid>
      </Grid>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <CheckBoxFilter
          className={classes.customTagsInnerContainer}
          filterOptions={tags}
          selectedFilters={parsedSelectedFilters}
          onChange={onFilterSelected}
        />
      </Collapse>
      <Divider className={classes.divider} />
    </Grid>
  )
}

export default FilterMenuSection
