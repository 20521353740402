import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 15,
    margin: 0
  },
  listItem: {
    margin: '8px 0px 8px 0px'
  },
  title: {
    fontWeight: 600
  }
}))

const TxGoals = ({ items, note }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <ul className={classes.list}>
      {Object.keys(items)
        .filter(itemKey => items[itemKey].isChecked)
        .map(itemKey => (
          <li key={itemKey} className={classes.listItem}>
            <span className={classes.title}>{t(`dialogs.hiPatientGuidelinesViewer.items.${itemKey}`)}</span>
            {items[itemKey].note && <span>{` - ${items[itemKey].note}`}</span>}
          </li>
        ))}
    </ul>
  )
}

export default TxGoals
