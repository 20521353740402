import Actions from 'actions'
import { DEFAULT_TAGS_FILTER } from 'consts/filterConsts'
import { mapToTaskCategories } from 'utils/mappers/taskManagerMappers'

const initialState = {
  isLoading: false,
  filters: {
    sortDirection: 'desc',
    sortBy: 'lastInteractionTimestamp',
    tags: DEFAULT_TAGS_FILTER,
    lastActionItemType: [],
    name: ''
  },
  items: [],
  taskCategories: {},
  nextToken: null,
  total: 0,
  pageIndex: 0,
  modals: {
    updateApplianceArrivalModal: {
      isOpen: false,
      patient: {
        name: '',
        id: ''
      },
      isLoading: false
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_TASK_MANAGER_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      }
    }
    case Actions.REQUEST_PATIENTS_FOR_TASK_MANAGER:
      return {
        ...state,
        items: action.payload?.newSearch && action.payload?.showLoader ? initialState.items : state.items,
        pageIndex: action.payload?.newSearch ? initialState.pageIndex : state.pageIndex,
        taskCategories: action.payload?.updateTaskCategories ? initialState.taskCategories : state.taskCategories,
        isLoading: action.payload?.showLoader
      }
    case Actions.PATIENTS_FOR_TASK_MANAGER_RECEIVED:
      return {
        ...state,
        items: action.payload.newSearch ? action.payload.items : [...state.items, ...action.payload.items],
        nextToken: action.payload.nextToken,
        total: action.payload.total,
        taskCategories: mapToTaskCategories(action.payload.taskCategories),
        isLoading: false
      }
    case Actions.PATIENTS_FOR_TASK_MANAGER_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.TOTAL_PATIENTS_FOR_TASK_MANAGER_RECEIVED:
      return {
        ...state,
        taskCategories: mapToTaskCategories(action.payload)
      }
    case Actions.SET_TASK_MANAGER_PAGE:
      return {
        ...state,
        pageIndex: action.payload
      }
    case Actions.TOGGLE_APPLIANCE_ARRIVAL_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          updateApplianceArrivalModal: {
            ...state.modals.updateApplianceArrivalModal,
            ...action.payload
          }
        }
      }
    case Actions.UPDATE_APPLIANCE_ARRIVAL_STATUS:
      return {
        ...state,
        modals: {
          ...state.modals,
          updateApplianceArrivalModal: {
            ...state.modals.updateApplianceArrivalModal,
            isLoading: true
          }
        }
      }
    case Actions.UPDATE_APPLIANCE_ARRIVAL_STATUS_FAILED:
    case Actions.UPDATE_APPLIANCE_ARRIVAL_STATUS_RECEIVED:
      return {
        ...state,
        modals: {
          ...state.modals,
          updateApplianceArrivalModal: {
            ...state.modals.updateApplianceArrivalModal,
            isOpen: false,
            isLoading: false
          }
        }
      }
    default:
      return state
  }
}
