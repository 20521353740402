import html2canvas from 'html2canvas'
import { logError } from './logUtils'
import { saveAs } from 'file-saver'

export const drawLayersFromLocalResources = ({ canvas, layerImagePaths }) => {
  const context = canvas.getContext('2d')

  layerImagePaths.forEach(path => {
    import(`resources/${path}`)
      .then(asset => {
        const img = new Image()
        img.src = asset.default
        img.onload = () => {
          context.drawImage(img, 0, 0, canvas.width, canvas.height)
        }
      })
      .catch(ex => {
        logError(`failed to import asset: ${path}: ${ex.message}`, {
          resourcePath: path,
          error: ex
        })
      })
  })
}

export const drawBackgroundWithLayers = async ({ canvas, backgroundImage, layersImages = [], onLoad = () => {} }) => {
  const context = canvas.getContext('2d')

  const aspectRatio = backgroundImage.width / backgroundImage.height
  const drawWidth = canvas.width
  const drawHeight = canvas.width / aspectRatio
  const drawX = (canvas.width - drawWidth) / 2

  context.clearRect(0, 0, canvas.width, canvas.height)
  context.drawImage(backgroundImage, drawX, 0, drawWidth, drawHeight)

  if (layersImages.length > 0) {
    layersImages.forEach(async layerImage => {
      context.drawImage(layerImage, drawX, 0, drawWidth, drawHeight)
    })
  }
  onLoad()
}

export const initCanvas = ({ canvas, width, aspectRatio = 1.66 }) => {
  canvas.style.width = width
  canvas.style.height = width / aspectRatio
  canvas.width = canvas.offsetWidth
  canvas.height = canvas.width / aspectRatio
}

export const exportElementToImage = ({ selector, fileName }) => {
  html2canvas(document.querySelector(selector)).then(canvas => {
    canvas.toBlob(blob => saveAs(blob, fileName))
  })
}

export const htmlToDataURI = async ({ selector, logging = false }) =>
  html2canvas(document.querySelector(selector), {
    logging
  }).then(canvas => canvas.toDataURL())
