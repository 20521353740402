import { Button, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import FormInput from '../common/FormInput'
import PhoneNumberInput from '../common/PhoneNumberInput'
import ImageUploader from './ImageUploader'
import { useSelector } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'
import GrinLabel from 'components/common/text/GrinLabel'
import { ROUTES } from 'consts'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const useStyles = makeStyles(theme => ({
  pageTitle: {
    fontFamily: 'Ogg',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '40px',
    lineHeight: '40px',
    marginBottom: 30,
    color: 'var(--text-color-1)',
    '@media (max-width: 960px)': {
      marginBottom: 20,
      textAlign: 'center'
    }
  },
  form: {
    width: '100%',
    maxWidth: 540,
    margin: '0 auto',
    '@media (max-width: 750px)': {
      maxWidth: '100%'
    },
    zIndex: 1
  },
  nextButton: {
    background: 'var(--border-color-1)',
    color: 'var(--text-color-1)',
    width: '100%',
    borderRadius: '34px',
    '&:hover': {
      background: 'var(--border-color-1)'
    }
  },
  input: {
    backgroundColor: 'var(--bg-color-4) !important',
    height: '40px !important',
    '&:focus': {
      border: '1px solid var(--border-color-1) !important'
    }
  },
  deactivatedInput: {
    color: 'var(--text-color-9) !important'
  },
  inputWrapper: {
    backgroundColor: 'transparent !important',
    height: 'auto !important',
    '&.error input:focus': {
      border: 'none !important'
    }
  },
  phoneInput: {
    height: '40px !important',
    '&:focus': {
      outline: '1px solid var(--border-color-1) !important'
    }
  },
  phoneInputTitle: {
    color: 'var(--text-color-1)'
  },
  firstLastName: {
    paddingLeft: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media (max-width: 960px)': {
      paddingLeft: 0,
      marginTop: 15
    }
  },
  phoneRequired: {
    color: 'var(--text-color-10)',
    fontSize: 12
  },
  inputRoot: {
    color: 'white'
  }
}))

const PersonalDetailsForm = ({
  firstName,
  lastName,
  phoneValue,
  countryCode,
  roleDescription,
  photo,
  groups = [],
  handleFormSubmit,
  hanldeChangePhoto,
  handleChangeForm,
  handleChangeGroups
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const [showPhoneError, setShowPhoneError] = useState(false)

  const grinGroups = useSelector(state => state.supportReducer.userGroups.data)
  const isOnQuickSignup = useMemo(
    () => history.location.pathname.includes(ROUTES.QUICK_SIGN_UP),
    [history.location.pathname]
  )

  const onSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!phoneValue.slice(countryCode.length)) {
        setShowPhoneError(true)
      } else {
        handleFormSubmit()
      }
    },
    [countryCode.length, handleFormSubmit, phoneValue]
  )

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      <div className={classes.pageTitle}>{t('pages.personalDetails.label')}</div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item md={3} xs={12} className={classes.uploaderConrainer}>
              <ImageUploader
                title={t('pages.personalDetails.photo.title')}
                uploadtext={t('pages.personalDetails.photo.uploadPhoto')}
                changeText={t('pages.personalDetails.photo.changePhoto')}
                value={photo}
                onChange={hanldeChangePhoto}
                type="avatar"
              />
            </Grid>
            <Grid item md={9} xs={12} className={classes.firstLastName}>
              <FormInput
                title={t('pages.personalDetails.firstName.label')}
                placeholder={t('pages.personalDetails.firstName.placeholder')}
                value={firstName}
                validationRules={{ required: true }}
                setValue={value => handleChangeForm({ key: 'firstName', value })}
                inputClassName={classes.input}
              />
              <FormInput
                title={t('pages.personalDetails.lastName.label')}
                placeholder={t('pages.personalDetails.lastName.placeholder')}
                value={lastName}
                validationRules={{ required: true }}
                setValue={value => handleChangeForm({ key: 'lastName', value })}
                inputClassName={classes.input}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PhoneNumberInput
            title={t('pages.personalDetails.phone.label')}
            value={phoneValue}
            inputClassName={classes.phoneInput}
            titleClassName={classes.phoneInputTitle}
            required
            setValue={value => {
              setShowPhoneError(false)
              handleChangeForm({
                key: 'phoneValue',
                value
              })
            }}
            setCountryCode={countryCode =>
              handleChangeForm({
                key: 'countryCode',
                value: countryCode
              })
            }
          />
          {showPhoneError && <div className={classes.phoneRequired}>{t('pages.practiceInfo.phoneRequired')}</div>}
        </Grid>
        <Grid item xs={12}>
          <FormInput
            title={t('pages.personalDetails.roleDescription.label')}
            placeholder={t('pages.personalDetails.roleDescription.placeholder')}
            value={roleDescription}
            inputClassName={classes.input}
            inputWrapperClassName={classes.inputWrapper}
            validationRules={{ required: true }}
            setValue={value =>
              handleChangeForm({
                key: 'roleDescription',
                value
              })
            }
          />
        </Grid>
        {isOnQuickSignup && (
          <Grid item xs={12}>
            <GrinLabel color={'white'}>{t('pages.personalDetails.groups')}</GrinLabel>
            <Autocomplete
              style={{
                background: 'var(--bg-color-4)'
              }}
              classes={{
                inputRoot: classes.inputRoot
              }}
              inputClassName
              multiple
              options={grinGroups}
              getOptionLabel={group => group.displayName}
              getOptionSelected={(option, value) => groups.includes(option.key)}
              filterSelectedOptions
              onChange={(event, values) => handleChangeGroups(values)}
              value={groups}
              renderInput={params => <TextField {...params} />}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button variant="contained" className={classes.nextButton} type="submit">
            {t('pages.personalDetails.button')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default PersonalDetailsForm
