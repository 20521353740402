import { makeStyles } from '@material-ui/styles'
import React from 'react'
import GrinMenuItem from '../menu/GrinMenuItem'
import GrinTag from './GrinTag'

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: '240px',
    overflowY: 'scroll'
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

const TagsPickerList = ({ tags, onTagSelected, onDeleteTag }) => {
  const classes = useStyles()

  return (
    <div className={classes.root} id="tags-picker">
      {tags.map(tag => (
        <div key={tag.id}>
          <GrinMenuItem nonActionable className={classes.tagContainer}>
            <GrinTag
              tag={tag}
              onClick={() => onTagSelected(tag)}
              onDelete={() => onDeleteTag(tag)}
              automationsPrefix="tags-picker"
            />
          </GrinMenuItem>
        </div>
      ))}
    </div>
  )
}

export default TagsPickerList
