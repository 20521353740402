const OPEN_PRELIMINARY_PLAN_DIALOG = 'OPEN_PRELIMINARY_PLAN_DIALOG'
const CLOSE_PRELIMINARY_PLAN_DIALOG = 'CLOSE_PRELIMINARY_PLAN_DIALOG'
const OPEN_SCAN_VIEW_DIALOG = 'OPEN_SCAN_VIEW_DIALOG'
const CLOSE_SCAN_VIEW_DIALOG = 'CLOSE_SCAN_VIEW_DIALOG'
const REQUEST_GRIN_SCANS = 'REQUEST_GRIN_SCANS'
const GRIN_SCANS_RECEIVED = 'GRIN_SCANS_RECEIVED'
const REQUEST_SEND_SCAN_REVIEW = 'REQUEST_SEND_SCAN_REVIEW'
const SEND_SCAN_REVIEW_RECEIVED = 'SEND_SCAN_REVIEW_RECEIVED'
const REQUEST_PATIENT_TREATMENT = 'REQUEST_PATIENT_TREATMENT'
const PATIENT_TREATMENT_RECEIVED = 'PATIENT_TREATMENT_RECEIVED'
const PATIENT_TREATMENT_FETCH_FAILED = 'PATIENT_TREATMENT_FETCH_FAILED'
const OPEN_SCAN_REVIEW_EDITOR = 'OPEN_SCAN_REVIEW_EDITOR'
const CLOSE_SCAN_REVIEW_EDITOR = 'CLOSE_SCAN_REVIEW_EDITOR'
const FETCH_SCAN_FOR_REVIEW = 'FETCH_SCAN_FOR_REVIEW'
const FETCH_SCAN_FOR_REVIEW_RECEIVED = 'FETCH_SCAN_FOR_REVIEW_RECEIVED'
const FETCH_SCAN_FOR_REVIEW_FAILED = 'FETCH_SCAN_FOR_REVIEW_FAILED'
const FETCH_SCANNER_TYPES_LIST = 'FETCH_SCANNER_TYPES_LIST'
const FETCH_SCANNER_TYPES_LIST_RECEIVED = 'FETCH_SCANNER_TYPES_LIST_RECEIVED'
const FETCH_SCANNER_TYPES_LIST_FAILED = 'FETCH_SCANNER_TYPES_LIST_FAILED'
const UPLOAD_INITIAL_STL_FILES = 'UPLOAD_INITIAL_STL_FILES'
const UPLOAD_STL_FILES = 'UPLOAD_STL_FILES'
const UPLOAD_STL_FILES_RECEIVED = 'UPLOAD_STL_FILES_RECEIVED'
const UPLOAD_STL_FILES_FAILED = 'UPLOAD_STL_FILES_FAILED'
const SAVE_PATIENT_PRELIM_PROPOSAL = 'SAVE_PATIENT_PRELIM_PROPOSAL'
const PATIENT_PRELIM_PROPOSAL_SAVED = 'PATIENT_PRELIM_PROPOSAL_SAVED'
const SAVE_PATIENT_PRELIM_PROPOSAL_FAILED = 'SAVE_PATIENT_PRELIM_PROPOSAL_FAILED'
const APPROVE_PATIENT_PRELIM_PROPOSAL = 'APPROVE_PATIENT_PRELIM_PROPOSAL'
const PATIENT_PRELIM_PROPOSAL_APPROVED = 'PATIENT_PRELIM_PROPOSAL_APPROVED'
const APPROVE_PATIENT_PRELIM_PROPOSAL_FAILED = 'APPROVE_PATIENT_PRELIM_PROPOSAL_FAILED'
const SAVE_TREATMENT_PLAN = 'SAVE_TREATMENT_PLAN'
const TREATMENT_PLAN_SAVED = 'TREATMENT_PLAN_SAVED'
const TREATMENT_PLAN_FAILED = 'TREATMENT_PLAN_FAILED'
const FETCH_PRELIMINARY_PLAN_PRODUCTS = 'FETCH_PRELIMINARY_PLAN_PRODUCTS'
const FETCH_PRELIMINARY_PLAN_PRODUCTS_RECEIVED = 'FETCH_PRELIMINARY_PLAN_PRODUCTS_RECEIVED'
const FETCH_PRELIMINARY_PLAN_PRODUCTS_FAILED = 'FETCH_PRELIMINARY_PLAN_PRODUCTS_FAILED'
const FETCH_STATUSES = 'FETCH_STATUSES'
const FETCH_STATUSES_FAILED = 'FETCH_STATUSES_FAILED'
const FETCH_STATUSES_RECEIVED = 'FETCH_STATUSES_RECEIVED'
const UPDATE_TREATMENT_STATUS = 'UPDATE_TREATMENT_STATUS'
const UPDATE_TREATMENT_STATUS_RECEIVED = 'UPDATE_TREATMENT_STATUS_RECEIVED'
const UPDATE_TREATMENT_STATUS_FAILED = 'UPDATE_TREATMENT_STATUS_FAILED'
const SCAN_SUMMARY_REVIEWED = 'SCAN_SUMMARY_REVIEWED'
const SCAN_SUMMARY_REVIEWED_RECEIVED = 'SCAN_SUMMARY_REVIEWED_RECEIVED'
const SCAN_SUMMARY_REVIEWED_FAILED = 'SCAN_SUMMARY_REVIEWED_FAILED'
const SUBMIT_PREVENTIVE_FEEDBACK = 'SUBMIT_PREVENTIVE_FEEDBACK'
const UPDATE_SCAN_FEEDBACK = 'UPDATE_SCAN_FEEDBACK'
const UPDATE_SCAN_FEEDBACK_RECEIVED = 'UPDATE_SCAN_FEEDBACK_RECEIVED'
const UPDATE_SCAN_FEEDBACK_FAILED = 'UPDATE_SCAN_FEEDBACK_FAILED'
const UPDATE_GRIN_SCAN_APPLIANCE = 'UPDATE_GRIN_SCAN_APPLIANCE'
const UPDATE_GRIN_SCAN_APPLIANCE_RECEIVED = 'UPDATE_GRIN_SCAN_APPLIANCE_RECEIVED'
const UPDATE_GRIN_SCAN_APPLIANCE_FAILED = 'UPDATE_GRIN_SCAN_APPLIANCE_FAILED'
const UPDATE_TREATMENT = 'UPDATE_TREATMENT'
const UPDATE_TREATMENT_RECEIVED = 'UPDATE_TREATMENT_RECEIVED'
const UPDATE_TREATMENT_FAILED = 'UPDATE_TREATMENT_FAILED'
const UPLOAD_MEDICAL_RECORD = 'UPLOAD_MEDICAL_RECORD'
const UPLOAD_MEDICAL_RECORD_RECEIVED = 'UPLOAD_MEDICAL_RECORD_RECEIVED'
const UPLOAD_MEDICAL_RECORD_FAILED = 'UPLOAD_MEDICAL_RECORD_FAILED'
const FETCH_MEDICAL_RECORDS = 'FETCH_MEDICAL_RECORDS'
const FETCH_MEDICAL_RECORDS_RECEIVED = 'FETCH_MEDICAL_RECORDS_RECEIVED'
const FETCH_MEDICAL_RECORDS_FAILED = 'FETCH_MEDICAL_RECORDS_FAILED'
const DELETE_MEDICAL_RECORD = 'DELETE_MEDICAL_RECORD'
const DELETE_MEDICAL_RECORD_RECEIVED = 'DELETE_MEDICAL_RECORD_RECEIVED'
const DELETE_MEDICAL_RECORD_FAILED = 'DELETE_MEDICAL_RECORD_FAILED'
const FETCH_BASE_64_IMAGE = 'FETCH_BASE_64_IMAGE'
const FETCH_BASE_64_IMAGE_RECEIVED = 'FETCH_BASE_64_IMAGE_RECEIVED'
const FETCH_BASE_64_IMAGE_FAILED = 'FETCH_BASE_64_IMAGE_FAILED'
const CLEAR_BASE_64_IMAGE = 'CLEAR_BASE_64_IMAGE'
const TOGGLE_BEFORE_AFTER_DIALOG = 'TOGGLE_BEFORE_AFTER_DIALOG'
const FETCH_BEFORE_AFTER_ASSET = 'FETCH_BEFORE_AFTER_ASSET'
const FETCH_BEFORE_AFTER_ASSET_RECEIVED = 'FETCH_BEFORE_AFTER_ASSET_RECEIVED'
const FETCH_BEFORE_AFTER_ASSET_FAILED = 'FETCH_BEFORE_AFTER_ASSET_FAILED'
const SUBMIT_BEFORE_AFTER_FEEDBACK = 'SUBMIT_BEFORE_AFTER_FEEDBACK'
const SUBMIT_BEFORE_AFTER_FEEDBACK_RECEIVED = 'SUBMIT_BEFORE_AFTER_FEEDBACK_RECEIVED'
const SUBMIT_BEFORE_AFTER_FEEDBACK_FAILED = 'SUBMIT_BEFORE_AFTER_FEEDBACK_FAILED'
const AUTO_GENERATE_BEFORE_AFTER_RECEIVED = 'AUTO_GENERATE_BEFORE_AFTER_RECEIVED'
const AUTO_GENERATE_BEFORE_AFTER_FAILED = 'AUTO_GENERATE_BEFORE_AFTER_FAILED'
const SET_CYCLE_STATUS = 'SET_CYCLE_STATUS'
const SET_CYCLE_STATUS_RECEIVED = 'SET_CYCLE_STATUS_RECEIVED'
const SET_CYCLE_STATUS_FAILED = 'SET_CYCLE_STATUS_FAILED'
const SCAN_SUMMARY_LIVE_UPDATE_RECEIVED = 'SCAN_SUMMARY_LIVE_UPDATE_RECEIVED'
const SCAN_SUMMARY_LIVE_UPDATE_FAILED = 'SCAN_SUMMARY_LIVE_UPDATE_FAILED'
const SET_COMPARE_SCANS_DIALOG_OPEN = 'SET_COMPARE_SCANS_DIALOG_OPEN'
const COMPARE_SCANS_SET_LINK_SCANS_ENABLED = 'COMPARE_SCANS_SET_LINK_SCANS_ENABLED'
const COMPARE_SCANS_SET_SCAN_SELECTION = 'COMPARE_SCANS_SET_SCAN_SELECTION'
const REPLACE_ORAL_IMAGE = 'REPLACE_ORAL_IMAGE'
const REPLACE_ORAL_IMAGE_RECEIVED = 'REPLACE_ORAL_IMAGE_RECEIVED'
const REPLACE_ORAL_IMAGE_FAILED = 'REPLACE_ORAL_IMAGE_FAILED'
const TOGGLE_REPLACE_SCAN_SUMMARY_MODAL = 'TOGGLE_REPLACE_SCAN_SUMMARY_MODAL'
const TOGGLE_SHARE_NEW_SCAN_MODAL = 'TOGGLE_SHARE_NEW_SCAN_MODAL'
const TOGGLE_VIEW_SAHRED_SCAN_MODAL = 'TOGGLE_VIEW_SAHRED_SCAN_MODAL'
const SHARE_SCAN_TO_REFERRAL = 'SHARE_SCAN_TO_REFERRAL'
const SHARE_SCAN_TO_REFERRAL_RECEIVED = 'SHARE_SCAN_TO_REFERRAL_RECEIVED'
const SHARE_SCAN_TO_REFERRAL_FAILED = 'SHARE_SCAN_TO_REFERRAL_FAILED'
const ORAL_HYGIENE_RECOMMENDATION_SET_USER_ACTION = 'ORAL_HYGIENE_RECOMMENDATION_SET_USER_ACTION'
const ORAL_HYGIENE_RECOMMENDATION_SET_USER_ACTION_RECEIVED = 'ORAL_HYGIENE_RECOMMENDATION_SET_USER_ACTION_RECEIVED'
const ORAL_HYGIENE_RECOMMENDATION_SET_USER_ACTION_FAILED = 'ORAL_HYGIENE_RECOMMENDATION_SET_USER_ACTION_FAILED'
const FETCH_PATIENT_RECORDS = 'FETCH_PATIENT_RECORDS'
const FETCH_PATIENT_RECORDS_RECEIVED = 'FETCH_PATIENT_RECORDS_RECEIVED'
const FETCH_PATIENT_RECORDS_FAILED = 'FETCH_PATIENT_RECORDS_FAILED'
const TOGGLE_SHARE_SCAN_ATTACH_FILES_MODAL = 'TOGGLE_SHARE_SCAN_ATTACH_FILES_MODAL'
const UPLOAD_SHARE_SCAN_ATTACHMENTS = 'UPLOAD_SHARE_SCAN_ATTACHMENTS'
const UPLOAD_SHARE_SCAN_ATTACHMENTS_RECEIVED = 'UPLOAD_SHARE_SCAN_ATTACHMENTS_RECEIVED'
const UPLOAD_SHARE_SCAN_ATTACHMENTS_FAILED = 'UPLOAD_SHARE_SCAN_ATTACHMENTS_FAILED'
const SET_SCAN_SUMMARY_MODAL_OPEN = 'SET_SCAN_SUMMARY_MODAL_OPEN'

const setScanSummaryModalOpen = payload => ({
  type: SET_SCAN_SUMMARY_MODAL_OPEN,
  payload
})

const uploadShareScanAttachments = payload => ({
  type: UPLOAD_SHARE_SCAN_ATTACHMENTS,
  payload
})

const uploadShareScanAttachmentsReceived = payload => ({
  type: UPLOAD_SHARE_SCAN_ATTACHMENTS_RECEIVED,
  payload
})

const uploadShareScanAttachmentsFailed = payload => ({
  type: UPLOAD_SHARE_SCAN_ATTACHMENTS_FAILED,
  payload
})

const toggleShareScanAttachFilesModal = payload => ({
  type: TOGGLE_SHARE_SCAN_ATTACH_FILES_MODAL,
  payload
})

const fetchPatientRecords = payload => ({
  type: FETCH_PATIENT_RECORDS,
  payload
})

const fetchPatientRecordsReceived = payload => ({
  type: FETCH_PATIENT_RECORDS_RECEIVED,
  payload
})

const fetchPatientRecordsFailed = payload => ({
  type: FETCH_PATIENT_RECORDS_FAILED,
  payload
})

const shareScanToReferral = payload => ({
  type: SHARE_SCAN_TO_REFERRAL,
  payload
})

const shareScanToReferralReceived = payload => ({
  type: SHARE_SCAN_TO_REFERRAL_RECEIVED,
  payload
})

const shareScanToReferralFailed = payload => ({
  type: SHARE_SCAN_TO_REFERRAL_FAILED,
  payload
})

const toggleReplaceScanSummaryImageModal = payload => ({
  type: TOGGLE_REPLACE_SCAN_SUMMARY_MODAL,
  payload
})

const replaceOralImage = payload => ({
  type: REPLACE_ORAL_IMAGE,
  payload
})

const replaceOralImageReceived = payload => ({
  type: REPLACE_ORAL_IMAGE_RECEIVED,
  payload
})

const replaceOralImageFailed = payload => ({
  type: REPLACE_ORAL_IMAGE_FAILED,
  payload
})

const requestGrinScans = payload => ({
  type: REQUEST_GRIN_SCANS,
  payload
})

const grinScansReceived = payload => ({
  type: GRIN_SCANS_RECEIVED,
  payload
})

const openScanViewDialog = payload => ({
  type: OPEN_SCAN_VIEW_DIALOG,
  payload
})

const closeScanViewDialog = () => ({
  type: CLOSE_SCAN_VIEW_DIALOG
})

const openPreliminaryPlanDialog = payload => ({
  type: OPEN_PRELIMINARY_PLAN_DIALOG,
  payload
})

const closePreliminaryPlanDialog = () => ({
  type: CLOSE_PRELIMINARY_PLAN_DIALOG
})

const requestSendScanReview = payload => ({
  type: REQUEST_SEND_SCAN_REVIEW,
  payload
})

const sendScanReviewReceived = payload => ({
  type: SEND_SCAN_REVIEW_RECEIVED,
  payload
})

const requestPatientTreatment = payload => ({
  type: REQUEST_PATIENT_TREATMENT,
  payload
})

const patientTreatmentReceived = payload => ({
  type: PATIENT_TREATMENT_RECEIVED,
  payload
})

const patientTreatmentFetchFailed = payload => ({
  type: PATIENT_TREATMENT_FETCH_FAILED,
  payload
})

const openScanReviewEditor = payload => ({
  type: OPEN_SCAN_REVIEW_EDITOR,
  payload
})

const fetchScanForReview = payload => ({
  type: FETCH_SCAN_FOR_REVIEW,
  payload
})

const fetchScanForReviewReceived = payload => ({
  type: FETCH_SCAN_FOR_REVIEW_RECEIVED,
  payload
})

const fetchScanForReviewFailed = payload => ({
  type: FETCH_SCAN_FOR_REVIEW_FAILED,
  payload
})

const closeScanReviewEditor = payload => ({
  type: CLOSE_SCAN_REVIEW_EDITOR,
  payload
})

const fetchScannerTypesList = payload => ({
  type: FETCH_SCANNER_TYPES_LIST,
  payload
})

const fetchScannerTypesListReceived = payload => ({
  type: FETCH_SCANNER_TYPES_LIST_RECEIVED,
  payload
})

const fetchScannerTypesListFailed = payload => ({
  type: FETCH_SCANNER_TYPES_LIST_FAILED,
  payload
})

const uploadInitialStlFiles = payload => ({
  type: UPLOAD_INITIAL_STL_FILES,
  payload
})

const uploadStlFiles = payload => ({
  type: UPLOAD_STL_FILES,
  payload
})

const uploadStlFilesReceived = payload => ({
  type: UPLOAD_STL_FILES_RECEIVED,
  payload
})

const uploadStlFilesFailed = payload => ({
  type: UPLOAD_STL_FILES_FAILED,
  payload
})

const savePatientPrelimPlan = payload => ({
  type: SAVE_PATIENT_PRELIM_PROPOSAL,
  payload
})

const patientPrelimPlanSaved = payload => ({
  type: PATIENT_PRELIM_PROPOSAL_SAVED,
  payload
})

const savePatientPrelimPlanFailed = payload => ({
  type: SAVE_PATIENT_PRELIM_PROPOSAL_FAILED,
  payload
})

const approvePatientPrelimPlan = payload => ({
  type: APPROVE_PATIENT_PRELIM_PROPOSAL,
  payload
})

const patientPrelimPlanApproved = payload => ({
  type: PATIENT_PRELIM_PROPOSAL_APPROVED,
  payload
})

const approvePatientPrelimPlanFailed = payload => ({
  type: APPROVE_PATIENT_PRELIM_PROPOSAL_FAILED,
  payload
})

const saveTreatmentPlan = payload => ({
  type: SAVE_TREATMENT_PLAN,
  payload
})

const treatmentPlanSaved = payload => ({
  type: TREATMENT_PLAN_SAVED,
  payload
})

const treatmentPlanFailed = payload => ({
  type: TREATMENT_PLAN_FAILED,
  payload
})

const fetchPreliminaryPlanProducts = () => ({
  type: FETCH_PRELIMINARY_PLAN_PRODUCTS
})

const fetchPreliminaryPlanProductsReceived = payload => ({
  type: FETCH_PRELIMINARY_PLAN_PRODUCTS_RECEIVED,
  payload
})

const fetchPreliminaryPlanProductsFailed = payload => ({
  type: FETCH_PRELIMINARY_PLAN_PRODUCTS_FAILED,
  payload
})

const updateGrinScanAppliance = payload => ({
  type: UPDATE_GRIN_SCAN_APPLIANCE,
  payload
})

const updateGrinScanApplianceReceived = payload => ({
  type: UPDATE_GRIN_SCAN_APPLIANCE_RECEIVED,
  payload
})

const updateGrinScanApplianceFailed = () => ({
  type: UPDATE_GRIN_SCAN_APPLIANCE_FAILED
})

const fetchStatuses = payload => ({
  type: FETCH_STATUSES,
  payload
})

const fetchStatusesReceived = payload => ({
  type: FETCH_STATUSES_RECEIVED,
  payload
})

const fetchStatusesFailed = payload => ({
  type: FETCH_STATUSES_FAILED,
  payload
})

const updateTreatmentStatus = payload => ({
  type: UPDATE_TREATMENT_STATUS,
  payload
})

const updateTreatmentStatusReceived = payload => ({
  type: UPDATE_TREATMENT_STATUS_RECEIVED,
  payload
})

const updateTreatmentStatusFailed = payload => ({
  type: UPDATE_TREATMENT_STATUS_FAILED,
  payload
})

const scanSummaryReviewed = payload => ({
  type: SCAN_SUMMARY_REVIEWED,
  payload
})

const scanSummaryReviewedFailed = payload => ({
  type: SCAN_SUMMARY_REVIEWED_FAILED,
  payload
})

const scanSummaryReviewedReceived = payload => ({
  type: SCAN_SUMMARY_REVIEWED_RECEIVED,
  payload
})

const updateScanFeedback = payload => ({
  type: UPDATE_SCAN_FEEDBACK,
  payload
})

const updateScanFeedbackReceived = payload => ({
  type: UPDATE_SCAN_FEEDBACK_RECEIVED,
  payload
})

const updateScanFeedbackFailed = payload => ({
  type: UPDATE_SCAN_FEEDBACK_FAILED,
  payload
})

const updateTreatment = payload => ({
  type: UPDATE_TREATMENT,
  payload
})

const updateTreatmentReceived = payload => ({
  type: UPDATE_TREATMENT_RECEIVED,
  payload
})

const updateTreatmentFailed = payload => ({
  type: UPDATE_TREATMENT_FAILED,
  payload
})
const uploadMedicalRecord = payload => ({
  type: UPLOAD_MEDICAL_RECORD,
  payload
})

const uploadMedicalRecordReceived = payload => ({
  type: UPLOAD_MEDICAL_RECORD_RECEIVED,
  payload
})

const uploadMedicalRecordFailed = payload => ({
  type: UPLOAD_MEDICAL_RECORD_FAILED,
  payload
})

const fetchMedicalRecords = payload => ({
  type: FETCH_MEDICAL_RECORDS,
  payload
})

const fetchMedicalRecordsReceived = payload => ({
  type: FETCH_MEDICAL_RECORDS_RECEIVED,
  payload
})

const fetchMedicalRecordsFailed = payload => ({
  type: FETCH_MEDICAL_RECORDS_FAILED,
  payload
})

const deleteMedicalRecord = payload => ({
  type: DELETE_MEDICAL_RECORD,
  payload
})

const deleteMedicalRecordReceived = payload => ({
  type: DELETE_MEDICAL_RECORD_RECEIVED,
  payload
})

const deleteMedicalRecordFailed = payload => ({
  type: DELETE_MEDICAL_RECORD_FAILED,
  payload
})

const fetchBase64Image = payload => ({
  type: FETCH_BASE_64_IMAGE,
  payload
})

const fetchBase64ImageReceived = payload => ({
  type: FETCH_BASE_64_IMAGE_RECEIVED,
  payload
})

const fetchBase64ImageFailed = payload => ({
  type: FETCH_BASE_64_IMAGE_FAILED,
  payload
})

const clearBase64Image = payload => ({
  type: CLEAR_BASE_64_IMAGE,
  payload
})

const submitPreventiveFeedback = payload => ({
  type: SUBMIT_PREVENTIVE_FEEDBACK,
  payload
})

const toggleBeforeAfterDialog = payload => ({
  type: TOGGLE_BEFORE_AFTER_DIALOG,
  payload
})

const fetchBeforeAfterAsset = payload => ({
  type: FETCH_BEFORE_AFTER_ASSET,
  payload
})

const fetchBeforeAfterAssetReceived = payload => ({
  type: FETCH_BEFORE_AFTER_ASSET_RECEIVED,
  payload
})

const fetchBeforeAfterAssetFailed = payload => ({
  type: FETCH_BEFORE_AFTER_ASSET_FAILED,
  payload
})

const submitBeforeAfterFeedback = payload => ({
  type: SUBMIT_BEFORE_AFTER_FEEDBACK,
  payload
})

const submitBeforeAfterFeedbackReceived = payload => ({
  type: SUBMIT_BEFORE_AFTER_FEEDBACK_RECEIVED,
  payload
})

const submitBeforeAfterFeedbackFailed = payload => ({
  type: SUBMIT_BEFORE_AFTER_FEEDBACK_FAILED,
  payload
})

const setCycleStatus = payload => ({
  type: SET_CYCLE_STATUS,
  payload
})

const setCycleStatusReceived = payload => ({
  type: SET_CYCLE_STATUS_RECEIVED,
  payload
})

const setCycleStatusFailed = payload => ({
  type: SET_CYCLE_STATUS_FAILED,
  payload
})

const autoGenerateBeforeAfterFailed = payload => ({
  type: AUTO_GENERATE_BEFORE_AFTER_FAILED,
  payload
})

const autoGenerateBeforeAfterReceived = payload => ({
  type: AUTO_GENERATE_BEFORE_AFTER_RECEIVED,
  payload
})

const scanSummaryLiveUpdateReceived = payload => ({
  type: SCAN_SUMMARY_LIVE_UPDATE_RECEIVED,
  payload
})

const scanSummaryLiveUpdateFailed = payload => ({
  type: SCAN_SUMMARY_LIVE_UPDATE_FAILED,
  payload
})

const setCompareScansDialogOpen = payload => ({
  type: SET_COMPARE_SCANS_DIALOG_OPEN,
  payload
})

const compareScansSetLinkScanEnabled = payload => ({
  type: COMPARE_SCANS_SET_LINK_SCANS_ENABLED,
  payload
})

const compareScansSetScanSelection = payload => ({
  type: COMPARE_SCANS_SET_SCAN_SELECTION,
  payload
})

const toggleShareNewScanModal = payload => ({
  type: TOGGLE_SHARE_NEW_SCAN_MODAL,
  payload
})

const toggleViewSharedScanModal = payload => ({
  type: TOGGLE_VIEW_SAHRED_SCAN_MODAL,
  payload
})

const oralHygieneRecommendationSetUserAction = payload => ({
  type: ORAL_HYGIENE_RECOMMENDATION_SET_USER_ACTION,
  payload
})

const oralHygieneRecommendationSetUserActionReceived = payload => ({
  type: ORAL_HYGIENE_RECOMMENDATION_SET_USER_ACTION_RECEIVED,
  payload
})

const oralHygieneRecommendationSetUserActionFailed = payload => ({
  type: ORAL_HYGIENE_RECOMMENDATION_SET_USER_ACTION_FAILED,
  payload
})

export default {
  REQUEST_GRIN_SCANS,
  GRIN_SCANS_RECEIVED,
  REQUEST_SEND_SCAN_REVIEW,
  SEND_SCAN_REVIEW_RECEIVED,
  OPEN_SCAN_VIEW_DIALOG,
  CLOSE_SCAN_VIEW_DIALOG,
  OPEN_PRELIMINARY_PLAN_DIALOG,
  CLOSE_PRELIMINARY_PLAN_DIALOG,
  REQUEST_PATIENT_TREATMENT,
  PATIENT_TREATMENT_RECEIVED,
  PATIENT_TREATMENT_FETCH_FAILED,
  OPEN_SCAN_REVIEW_EDITOR,
  CLOSE_SCAN_REVIEW_EDITOR,
  FETCH_SCAN_FOR_REVIEW,
  FETCH_SCAN_FOR_REVIEW_FAILED,
  FETCH_SCAN_FOR_REVIEW_RECEIVED,
  FETCH_SCANNER_TYPES_LIST_FAILED,
  FETCH_SCANNER_TYPES_LIST_RECEIVED,
  FETCH_SCANNER_TYPES_LIST,
  UPLOAD_INITIAL_STL_FILES,
  UPLOAD_STL_FILES,
  UPLOAD_STL_FILES_FAILED,
  UPLOAD_STL_FILES_RECEIVED,
  SAVE_PATIENT_PRELIM_PROPOSAL,
  PATIENT_PRELIM_PROPOSAL_SAVED,
  SAVE_PATIENT_PRELIM_PROPOSAL_FAILED,
  APPROVE_PATIENT_PRELIM_PROPOSAL,
  PATIENT_PRELIM_PROPOSAL_APPROVED,
  APPROVE_PATIENT_PRELIM_PROPOSAL_FAILED,
  SAVE_TREATMENT_PLAN,
  TREATMENT_PLAN_SAVED,
  TREATMENT_PLAN_FAILED,
  FETCH_PRELIMINARY_PLAN_PRODUCTS,
  FETCH_PRELIMINARY_PLAN_PRODUCTS_RECEIVED,
  FETCH_PRELIMINARY_PLAN_PRODUCTS_FAILED,
  UPDATE_GRIN_SCAN_APPLIANCE,
  UPDATE_GRIN_SCAN_APPLIANCE_RECEIVED,
  UPDATE_GRIN_SCAN_APPLIANCE_FAILED,
  FETCH_STATUSES,
  FETCH_STATUSES_FAILED,
  FETCH_STATUSES_RECEIVED,
  UPDATE_TREATMENT_STATUS_FAILED,
  UPDATE_TREATMENT_STATUS_RECEIVED,
  UPDATE_TREATMENT_STATUS,
  SCAN_SUMMARY_REVIEWED,
  SCAN_SUMMARY_REVIEWED_FAILED,
  SCAN_SUMMARY_REVIEWED_RECEIVED,
  UPDATE_SCAN_FEEDBACK,
  UPDATE_SCAN_FEEDBACK_RECEIVED,
  UPDATE_SCAN_FEEDBACK_FAILED,
  UPDATE_TREATMENT,
  UPDATE_TREATMENT_RECEIVED,
  UPDATE_TREATMENT_FAILED,
  UPLOAD_MEDICAL_RECORD,
  UPLOAD_MEDICAL_RECORD_RECEIVED,
  UPLOAD_MEDICAL_RECORD_FAILED,
  FETCH_MEDICAL_RECORDS,
  FETCH_MEDICAL_RECORDS_FAILED,
  FETCH_MEDICAL_RECORDS_RECEIVED,
  DELETE_MEDICAL_RECORD,
  DELETE_MEDICAL_RECORD_FAILED,
  DELETE_MEDICAL_RECORD_RECEIVED,
  FETCH_BASE_64_IMAGE,
  FETCH_BASE_64_IMAGE_RECEIVED,
  FETCH_BASE_64_IMAGE_FAILED,
  CLEAR_BASE_64_IMAGE,
  SUBMIT_PREVENTIVE_FEEDBACK,
  TOGGLE_BEFORE_AFTER_DIALOG,
  FETCH_BEFORE_AFTER_ASSET,
  FETCH_BEFORE_AFTER_ASSET_FAILED,
  FETCH_BEFORE_AFTER_ASSET_RECEIVED,
  SUBMIT_BEFORE_AFTER_FEEDBACK,
  SUBMIT_BEFORE_AFTER_FEEDBACK_FAILED,
  SUBMIT_BEFORE_AFTER_FEEDBACK_RECEIVED,
  SET_CYCLE_STATUS,
  SET_CYCLE_STATUS_RECEIVED,
  SET_CYCLE_STATUS_FAILED,
  AUTO_GENERATE_BEFORE_AFTER_FAILED,
  AUTO_GENERATE_BEFORE_AFTER_RECEIVED,
  SCAN_SUMMARY_LIVE_UPDATE_FAILED,
  SCAN_SUMMARY_LIVE_UPDATE_RECEIVED,
  SET_COMPARE_SCANS_DIALOG_OPEN,
  COMPARE_SCANS_SET_LINK_SCANS_ENABLED,
  COMPARE_SCANS_SET_SCAN_SELECTION,
  REPLACE_ORAL_IMAGE,
  REPLACE_ORAL_IMAGE_RECEIVED,
  REPLACE_ORAL_IMAGE_FAILED,
  TOGGLE_REPLACE_SCAN_SUMMARY_MODAL,
  TOGGLE_SHARE_NEW_SCAN_MODAL,
  SHARE_SCAN_TO_REFERRAL,
  SHARE_SCAN_TO_REFERRAL_RECEIVED,
  SHARE_SCAN_TO_REFERRAL_FAILED,
  TOGGLE_VIEW_SAHRED_SCAN_MODAL,
  ORAL_HYGIENE_RECOMMENDATION_SET_USER_ACTION,
  ORAL_HYGIENE_RECOMMENDATION_SET_USER_ACTION_RECEIVED,
  ORAL_HYGIENE_RECOMMENDATION_SET_USER_ACTION_FAILED,
  FETCH_PATIENT_RECORDS,
  FETCH_PATIENT_RECORDS_RECEIVED,
  FETCH_PATIENT_RECORDS_FAILED,
  TOGGLE_SHARE_SCAN_ATTACH_FILES_MODAL,
  UPLOAD_SHARE_SCAN_ATTACHMENTS,
  UPLOAD_SHARE_SCAN_ATTACHMENTS_RECEIVED,
  UPLOAD_SHARE_SCAN_ATTACHMENTS_FAILED,
  SET_SCAN_SUMMARY_MODAL_OPEN,
  setScanSummaryModalOpen,
  uploadShareScanAttachments,
  uploadShareScanAttachmentsReceived,
  uploadShareScanAttachmentsFailed,
  toggleShareScanAttachFilesModal,
  fetchPatientRecords,
  fetchPatientRecordsReceived,
  fetchPatientRecordsFailed,
  toggleViewSharedScanModal,
  shareScanToReferral,
  shareScanToReferralReceived,
  shareScanToReferralFailed,
  toggleShareNewScanModal,
  toggleReplaceScanSummaryImageModal,
  replaceOralImage,
  replaceOralImageReceived,
  replaceOralImageFailed,
  deleteMedicalRecord,
  deleteMedicalRecordReceived,
  deleteMedicalRecordFailed,
  fetchMedicalRecords,
  fetchMedicalRecordsReceived,
  fetchMedicalRecordsFailed,
  uploadMedicalRecord,
  uploadMedicalRecordReceived,
  uploadMedicalRecordFailed,
  fetchScanForReview,
  fetchScanForReviewFailed,
  fetchScanForReviewReceived,
  requestGrinScans,
  grinScansReceived,
  requestSendScanReview,
  sendScanReviewReceived,
  openScanViewDialog,
  closeScanViewDialog,
  openPreliminaryPlanDialog,
  closePreliminaryPlanDialog,
  requestPatientTreatment,
  patientTreatmentReceived,
  patientTreatmentFetchFailed,
  openScanReviewEditor,
  closeScanReviewEditor,
  fetchScannerTypesList,
  fetchScannerTypesListFailed,
  fetchScannerTypesListReceived,
  uploadStlFiles,
  uploadInitialStlFiles,
  uploadStlFilesFailed,
  uploadStlFilesReceived,
  savePatientPrelimPlan,
  patientPrelimPlanSaved,
  savePatientPrelimPlanFailed,
  approvePatientPrelimPlan,
  patientPrelimPlanApproved,
  approvePatientPrelimPlanFailed,
  saveTreatmentPlan,
  treatmentPlanSaved,
  treatmentPlanFailed,
  fetchPreliminaryPlanProducts,
  fetchPreliminaryPlanProductsReceived,
  fetchPreliminaryPlanProductsFailed,
  updateGrinScanAppliance,
  updateGrinScanApplianceReceived,
  updateGrinScanApplianceFailed,
  fetchStatuses,
  fetchStatusesFailed,
  fetchStatusesReceived,
  updateTreatmentStatus,
  updateTreatmentStatusFailed,
  updateTreatmentStatusReceived,
  scanSummaryReviewed,
  scanSummaryReviewedFailed,
  scanSummaryReviewedReceived,
  updateScanFeedback,
  updateScanFeedbackReceived,
  updateScanFeedbackFailed,
  updateTreatment,
  updateTreatmentReceived,
  updateTreatmentFailed,
  fetchBase64Image,
  fetchBase64ImageReceived,
  fetchBase64ImageFailed,
  clearBase64Image,
  submitPreventiveFeedback,
  toggleBeforeAfterDialog,
  fetchBeforeAfterAsset,
  fetchBeforeAfterAssetFailed,
  fetchBeforeAfterAssetReceived,
  submitBeforeAfterFeedback,
  submitBeforeAfterFeedbackReceived,
  submitBeforeAfterFeedbackFailed,
  setCycleStatus,
  setCycleStatusReceived,
  setCycleStatusFailed,
  autoGenerateBeforeAfterFailed,
  autoGenerateBeforeAfterReceived,
  scanSummaryLiveUpdateFailed,
  scanSummaryLiveUpdateReceived,
  setCompareScansDialogOpen,
  compareScansSetLinkScanEnabled,
  compareScansSetScanSelection,
  oralHygieneRecommendationSetUserAction,
  oralHygieneRecommendationSetUserActionReceived,
  oralHygieneRecommendationSetUserActionFailed
}
