import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useHistory, useParams } from 'react-router'
import Actions from 'actions'
import ROUTES from 'consts/routesConsts'
import { MobileTabs } from 'consts/appConsts'
import Timeline from './Timeline/V1/Timeline.mobile'
import PatientsChat from './Chat/PatientsChat'
import NotesTab from './PatientCard/NotesTab'
import useQueryParams from 'hooks/useQueryParams'

const PatientMobile = props => {
  const params = useParams()
  const { queryParams } = useQueryParams()
  const { referralId, grinScanId } = queryParams
  const patientId = params.patientId !== 'undefined' ? params.patientId : undefined

  const dispatch = useDispatch()
  const history = useHistory()
  const { patient } = useSelector(state => state.patientsReducer)
  const isBroadcastMode = useSelector(state => state.chatReducer.broadcast.isModeOn)

  const grinScanFromQueryParam = useMemo(
    () => patient?.grinScans?.items?.find(scan => scan.id === grinScanId),
    [grinScanId, patient?.grinScans?.items]
  )

  useEffect(() => {
    if (patientId && history.location.pathname !== ROUTES.BROADCAST) {
      dispatch(Actions.requestPatient({ patientId }))
    } else if (!patientId || (history.location.pathname === ROUTES.BROADCAST && !isBroadcastMode)) {
      history.push(ROUTES.PATIENTS)
    }
  }, [dispatch, patientId, history, isBroadcastMode])

  useEffect(() => {
    if (referralId && grinScanId && grinScanFromQueryParam) {
      dispatch(
        Actions.toggleViewSharedScanModal({
          isOpen: true,
          referralId,
          grinScanId
        })
      )
      history.replace({
        search: '',
        pathname: `${history.location.pathname}/timeline`
      })
    }
  }, [dispatch, grinScanFromQueryParam, grinScanId, history, patient, referralId])

  return (
    <>
      <Route path={ROUTES.BROADCAST} exact>
        <PatientsChat />
      </Route>
      <Route path={`${ROUTES.PATIENTS}/:patientId/${MobileTabs.TIMELINE}`} exact>
        <Timeline />
      </Route>
      <Route path={`${ROUTES.PATIENTS}/:patientId/${MobileTabs.CHAT}`} exact>
        <PatientsChat />
      </Route>
      <Route path={`${ROUTES.PATIENTS}/:patientId/${MobileTabs.NOTES}`} exact>
        <NotesTab />
      </Route>
    </>
  )
}

export default PatientMobile
