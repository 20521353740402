import * as React from 'react'

function OnboardingStep1({ color, height, width, ...props }) {
  return (
    <svg width="118" height="130" viewBox="0 0 118 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M47.3994 80.7431C47.3994 80.7431 52.0351 75.5913 72.8349 82.9396C94.5692 90.6117 96.387 111.055 94.2284 115.845C93.4981 119.708 69.8253 121.883 52.3701 119.608C34.3657 117.257 19.3162 109.41 17.4982 105.664C15.9823 103.08 16.949 93.4609 21.9032 88.4787C26.5224 83.8202 33.8384 84.7115 47.3994 80.7431Z"
        fill="#001335"
      />
      <path
        d="M117.761 70.9518C117.761 85.2407 112.663 98.332 104.139 108.479C103.149 109.669 102.101 110.816 101.012 111.922C90.4006 122.752 75.6228 129.456 59.2732 129.456C41.826 129.456 26.1916 121.845 15.4804 109.76C15.1144 109.345 14.7485 108.92 14.3909 108.488C5.85855 98.332 0.760742 85.2407 0.760742 70.9518C0.760742 51.4058 10.3409 34.0892 25.0772 23.4762C34.6907 16.5396 46.5079 12.4557 59.2732 12.4557C74.068 12.4557 87.5894 17.9535 97.8854 27.0194C110.06 37.7321 117.761 53.4436 117.761 70.9518Z"
        fill="#E1E5FE"
      />
      <path
        d="M92.8992 101.899C83.4887 111.508 70.3831 117.456 55.8834 117.456C40.4104 117.456 26.5451 110.703 17.0459 99.9802C18.071 95.0876 20.9326 89.7153 24.2219 87.7302C28.1234 85.3981 37.4603 83.3983 45.9565 82.1657C48.7738 81.7523 51.4879 81.428 53.8847 81.2137C60.7656 80.5645 66.054 81.2284 68.5171 81.6418C69.5127 81.8192 70.0434 81.9519 70.0434 81.9519C78.8053 84.771 90.2224 91.6045 92.5896 100.66C92.7002 101.08 92.8181 101.486 92.8992 101.899Z"
        fill="#001335"
      />
      <path
        d="M68.5614 83.8706C68.6646 85.8336 67.7795 87.767 66.1352 88.837C64.4683 89.9143 61.8204 90.8589 57.7126 90.741C49.5778 90.5198 46.8932 88.3131 45.9271 85.6419C45.7058 85.0364 45.6542 84.4019 45.7501 83.7674L46.4138 78.889L47.8742 68.2549L57.3733 67.0302L73.2891 64.9929C70.5527 71.2588 68.156 76.4242 68.5614 83.8706Z"
        fill="#FFB939"
      />
      <path
        d="M66.2017 69.6497C60.8986 76.0704 52.7935 78.8816 46.4141 78.8816L47.8743 68.2475L57.3735 67.0228L66.2017 69.6497Z"
        fill="#FF8E00"
      />
      <path
        d="M31.3097 24.3752C28.7211 40.6032 27.3419 56.6542 33.0355 65.628C38.7292 74.6019 51.1489 79.7747 66.1576 70.5428C81.166 61.3109 81.8516 48.6029 81.8516 48.6029L79.6096 26.4489L55.4634 16.4346L31.3097 24.3752Z"
        fill="#FFB939"
      />
      <path
        d="M70.4649 31.2826C70.4649 31.2826 67.5885 36.751 67.817 39.7398C68.0455 42.7286 70.6934 43.7692 74.3738 41.725C78.0536 39.6808 81.734 42.9648 81.734 45.924C81.734 48.8833 78.8429 55.252 75.2958 55.5029C72.5373 55.6948 69.9484 54.7576 68.739 57.3407C67.5296 59.9234 68.223 66.314 71.6743 67.3545C75.1262 68.3881 90.2005 46.3889 90.3411 30.4192C90.4738 14.9808 79.5437 2.56075 62.9272 0.494429C56.2751 -0.3321 46.4145 -1.09221 34.5405 6.92216C25.5354 13.0031 19.1338 12.0068 19.1338 12.0068C19.1338 12.0068 26.1033 42.7581 70.4649 31.2826Z"
        fill="#3C52EB"
      />
      <path
        d="M35.3223 56.5803C36.163 61.4362 38.4198 66.2993 45.1976 66.2993C51.9753 66.2993 56.2455 62.1965 57.4993 59.1778C57.4993 59.1778 50.22 62.1965 44.2756 61.4362C37.9256 60.6316 35.3223 56.5803 35.3223 56.5803Z"
        fill="white"
      />
    </svg>
  )
}

export default OnboardingStep1
