import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import PhoneNumberInput from '../../common/PhoneNumberInput'
import FormInput from '../../common/FormInput'
import ContactMenu from './ContactMenu'
import { removePlus } from '../../../utils/stringUtils'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  contactContainer: {
    marginTop: 12,
    height: 75
  },
  contactMenuWrapper: {
    marginTop: 15
  },
  errorMessage: {
    opacity: 1,
    color: 'red',
    marginTop: '2px',
    marginBottom: '5px',
    height: '17px',
    fontSize: ({ isMobile }) => (isMobile ? '10px' : '12px')
  }
}))

const Contact = ({ index, contact, updateContactValue, deleteContact, error }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        className={classes.contactContainer}
      >
        <Grid item xs={6}>
          <FormInput
            title={t('pages.accountSettings.notificationSettings.contactEmailLabel')}
            style={{ bright: true, thick: true }}
            placeholder="e.g. your@email.com"
            type="email"
            value={contact.email}
            setValue={email => updateContactValue(index, 'email', email)}
            validationRules={{ required: false }}
            keepErrorContainerWhenInactive={false}
          />
        </Grid>

        <Grid item xs={5}>
          <PhoneNumberInput
            title={t('pages.accountSettings.notificationSettings.contactPhoneLabel')}
            style={{ bright: true, thick: true }}
            value={removePlus(contact.countryCode || '') + (contact.phone || '')}
            setValue={phone => updateContactValue(index, 'phone', phone)}
            setCountryCode={countryCode => updateContactValue(index, 'countryCode', countryCode)}
          />
        </Grid>
        <Grid item xs={1} className={classes.contactMenuWrapper}>
          <ContactMenu onDelete={() => deleteContact(index)} />
        </Grid>
      </Grid>
      {error && <div className={classes.errorMessage}>{error}</div>}
    </>
  )
}

export default Contact
