import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import OggHeading32 from 'components/common/text/OggHeading32'
import { GalleryLeftArrow } from 'components/common/icons'
import { ROUTES } from 'consts'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 30
  },
  arrowLeft: {
    marginTop: 2
  }
}))

const BroadcastHeaderMobile = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const handleNavigateBack = useCallback(() => history.push(`${ROUTES.PATIENTS}?mode=broadcast`), [history])

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item onClick={handleNavigateBack} id="broadcast-backToPatients">
        <GalleryLeftArrow color="var(--bg-color-1)" className={classes.arrowLeft} />
      </Grid>
      <Grid item>
        <OggHeading32 className={classes.title}>{t('pages.patients.patientsList.broadcast.create.title')}</OggHeading32>
      </Grid>
    </Grid>
  )
}

export default BroadcastHeaderMobile
