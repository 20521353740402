import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { CurrentSTLTooth, LayerDisplayed, LayerHidden } from 'components/common/icons'
import { Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useTranslation } from 'react-i18next'
import OriginSTLPicker from './OriginSTLPicker'

const useStyles = makeStyles(theme => ({
  layerToggle: {
    cursor: 'pointer'
  }
}))

const ViewerControls = ({
  setViewerSettings,
  viewerSettings,
  selectedOriginModelType,
  setSelectedOriginModelType,
  originModelOptions,
  scanNumber,
  alignerNumber
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const controls = useMemo(
    () => [
      {
        isVisible: viewerSettings.isBaseLayerVisible,
        setIsVisible: value =>
          setViewerSettings({
            ...viewerSettings,
            isBaseLayerVisible: value
          }),
        component: (
          <OriginSTLPicker
            selectedOriginModelType={selectedOriginModelType}
            setSelectedOriginModelType={setSelectedOriginModelType}
            originModelOptions={originModelOptions}
          />
        )
      },
      {
        isVisible: viewerSettings.isCurrentLayerVisible,
        setIsVisible: value =>
          setViewerSettings({
            ...viewerSettings,
            isCurrentLayerVisible: value
          }),
        icon: <CurrentSTLTooth />,
        component: (
          <DazzedParagraph12 strong>
            {alignerNumber
              ? t('dialogs.treatmentTracker.viewerControls.currentAlignerEvaluation', { alignerNumber })
              : t('dialogs.treatmentTracker.viewerControls.currentEvaluation', { scanNumber })}
          </DazzedParagraph12>
        )
      }
    ],
    [
      alignerNumber,
      originModelOptions,
      scanNumber,
      selectedOriginModelType,
      setSelectedOriginModelType,
      setViewerSettings,
      t,
      viewerSettings
    ]
  )

  return (
    <Grid container alignItems="center" spacing={2}>
      {controls.map(({ isVisible, icon, setIsVisible, component }, index) => (
        <Grid item key={`viewer-control-${index}`}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item className={classes.layerToggle} onClick={() => setIsVisible(!isVisible)}>
              {isVisible ? <LayerDisplayed /> : <LayerHidden />}
            </Grid>
            {icon && <Grid item>{icon}</Grid>}
            {component && <Grid item>{component}</Grid>}
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default ViewerControls
