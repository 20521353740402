import * as React from 'react'

const MarkUnread = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 16.5V8.5C4 7.94772 4.44772 7.5 5 7.5H18C18.5523 7.5 19 7.94772 19 8.5V16.5C19 17.0523 18.5523 17.5 18 17.5H5C4.44772 17.5 4 17.0523 4 16.5Z"
        stroke="#282828"
      />
      <path d="M4 10L11.5 14L19 10" stroke="#282828" />
      <circle cx="19" cy="7.5" r="3.5" fill="#282828" stroke="white" strokeWidth="2" />
    </svg>
  )
}

export default MarkUnread
