import * as React from 'react'

const FeedbackOverDiagnostics = () => {
  return (
    <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.585786 0.335786 0.25 0.75 0.25H12.75C13.1642 0.25 13.5 0.585786 13.5 1C13.5 1.41421 13.1642 1.75 12.75 1.75H0.75C0.335786 1.75 0 1.41421 0 1Z"
        fill="#4151E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5C0 4.58579 0.335786 4.25 0.75 4.25H12.75C13.1642 4.25 13.5 4.58579 13.5 5C13.5 5.41421 13.1642 5.75 12.75 5.75H0.75C0.335786 5.75 0 5.41421 0 5Z"
        fill="#4151E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9C0 8.58579 0.335786 8.25 0.75 8.25H12.75C13.1642 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.1642 9.75 12.75 9.75H0.75C0.335786 9.75 0 9.41421 0 9Z"
        fill="#4151E6"
      />
      <circle cx="13.75" cy="11" r="9" fill="#F0F1FD" />
      <circle cx="13.75" cy="11" r="7" fill="#4151E6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 11L12.75 7.5C12.75 6.94772 13.1977 6.5 13.75 6.5C14.3023 6.5 14.75 6.94772 14.75 7.5L14.75 11C14.75 11.5523 14.3023 12 13.75 12C13.1977 12 12.75 11.5523 12.75 11Z"
        fill="#F0F1FD"
      />
      <circle cx="13.75" cy="14.5" r="1" fill="#F0F1FD" />
    </svg>
  )
}

export default FeedbackOverDiagnostics
