import { GuidelineCategories } from 'consts/hiToolsConsts'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  AlignersTxTracking as AlignersTxTrackingIcon,
  BracesTxTracking as BracesTxTrackingIcon,
  Elastics as ElasticsIcon,
  IPR as IPRIcon,
  OralHygiene as OralHygieneIcon,
  Other as OtherIcon,
  RPE as RPEIcon,
  TxGoals as TxGoalsIcon
} from 'components/common/icons'
import AlignersTxTracking from './Categories/AlignersTxTracking'
import BracesTxTracking from './Categories/BracesTxTracking'
import TxGoals from './Categories/TxGoals'
import Elastics from './Categories/Elastics'
import IPR from './Categories/IPR'
import RPE from './Categories/RPE'
import { isEmpty } from 'lodash'

export default () => {
  const { t } = useTranslation()

  const { data: patientBriefData } = useSelector(state => state.patientsReducer.patientBrief)
  const practiceMembers = useSelector(state => state.profileReducer.practiceMembers.data)

  const latestPatientBrief = useMemo(
    () => ({
      ...patientBriefData?.[0],
      categories: JSON.parse(patientBriefData?.[0]?.categories || '{}')
    }),
    [patientBriefData]
  )

  const lastUpdatedBy = useMemo(
    () => practiceMembers?.find(pm => pm.id === latestPatientBrief?.creatorDoctorId)?.name,
    [latestPatientBrief, practiceMembers]
  )

  const hasPatientBriefLoaded = useMemo(
    () => !!patientBriefData && !isEmpty(latestPatientBrief?.categories),
    [latestPatientBrief?.categories, patientBriefData]
  )

  const hasPatientBrief = useMemo(() => !isEmpty(latestPatientBrief.categories), [latestPatientBrief.categories])
  const categories = useMemo(
    () =>
      [
        {
          key: GuidelineCategories.AlignersTxTracking,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.alignersTxTracking.title'),
          icon: <AlignersTxTrackingIcon />,
          component: AlignersTxTracking
        },
        {
          key: GuidelineCategories.BracesTxTracking,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.bracesTxTracking.title'),
          icon: <BracesTxTrackingIcon />,
          component: BracesTxTracking
        },
        {
          key: GuidelineCategories.TxGoals,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.txGoals.title'),
          icon: <TxGoalsIcon />,
          component: TxGoals
        },
        {
          key: GuidelineCategories.Elastics,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.elastics.title'),
          icon: <ElasticsIcon />,
          component: Elastics
        },
        {
          key: GuidelineCategories.IPR,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.IPR.title'),
          icon: <IPRIcon />,
          component: IPR
        },
        {
          key: GuidelineCategories.RPE,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.RPE.title'),
          icon: <RPEIcon />,
          component: RPE
        },
        {
          key: GuidelineCategories.OralHygiene,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.oralHygiene.title'),
          icon: <OralHygieneIcon />,
          component: () => null
        },
        {
          key: GuidelineCategories.OtherAppliancesAndGuidelines,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.otherAppliancesAndGuidelines.title'),
          icon: <OtherIcon />,
          component: () => null
        }
      ].filter(category => latestPatientBrief?.categories?.[category.key]?.isEnabled),
    [latestPatientBrief, t]
  )

  return {
    hasPatientBriefLoaded,
    hasPatientBrief,
    latestPatientBrief,
    categories,
    lastUpdatedBy
  }
}
