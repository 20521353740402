import React, { useState, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import ButtonBase from '@material-ui/core/ButtonBase'
import ExpandCollapseButton from '../../../common/buttons/ExpandCollapseButton'
import ScanItemDetails from './ScanItemDetails'
import DazzedHeading18 from '../../../common/text/DazzedHeading18'
import DazzedParagraph12 from '../../../common/text/DazzedParagraph12'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import ApplianceLabel from './ApplianceLabel'
import { isMobile } from 'utils/mobileUtils'
import ScanByServiceAccount from './ScanByServiceAccount'

const ScanItem = ({ scanData, timestamp }) => {
  const useStyles = ({ isMobile }) =>
    makeStyles({
      scanContainer: {
        paddingLeft: isMobile ? 0 : 20,
        width: '100%'
      },
      headerButton: {
        width: isMobile ? '91.4667vw' : 385
      },
      scanTitle: {
        fontSize: isMobile ? 14 : 16,
        color: 'var(--text-color-3)',
        fontWeight: 'bold'
      },
      date: {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '18px',
        marginLeft: 5,
        color: 'var(--text-color-12)',
        textAlign: 'end'
      },
      dateMobile: {
        fontSize: 10,
        fontWeight: 400,
        lineHeight: '18px',
        paddingRight: '2.667vw',
        color: 'var(--text-color-12)',
        '@media (max-width: 300px)': {
          marginTop: 15
        },
        '@media (min-width: 500px)': {
          paddingRight: '0',
          paddingLeft: '4vw'
        },
        '@media (min-width: 360px)': {
          textAlign: 'end'
        }
      },
      sentByServiceAccountText: {
        fontSize: 11
      },
      video: {
        borderRadius: 5,
        boxShadow: '1px 3px 4px gray',
        maxWidth: isMobile ? '78.667vw' : 357,
        '&:focus': {
          outline: 'none'
        },
        '&:-webkit-full-screen': {
          maxWidth: '100%',
          maxHeight: '100%'
        }
      },
      videoContainer: {
        marginLeft: 18
      },
      videoContainerMobile: {
        '@media (max-width: 300px)': {
          marginTop: 15,
          marginBottom: -15
        },
        paddingLeft: '8vw'
      }
    })({ isMobile })

  const scans = useMemo(() => scanData.scans || [], [scanData])
  const classes = useStyles({ isMobile: isMobile() })
  const [expand, setExpand] = useState(scanData.shouldExpand)
  const { t } = useTranslation()

  const isServiceAccount = useMemo(() => JSON.parse(scans?.[0]?.metadata || '{}').isServiceAccount, [scans])

  const onChangeExpand = useCallback(() => {
    setExpand(!expand)
  }, [expand])

  const renderDate = useCallback(() => {
    let format = 'MMM D, LT'
    let xs = 3
    let className = classes.date

    if (isMobile()) {
      className = classes.dateMobile
      xs = window.outerWidth <= 300 ? 3 : 4
    }
    return (
      <Grid item xs={xs}>
        <DazzedParagraph12 className={className}>{moment(timestamp).format(format)}</DazzedParagraph12>
      </Grid>
    )
  }, [classes.date, classes.dateMobile, timestamp])

  return (
    <Grid container className={[classes.scanContainer, 'scan-container'].join(' ')}>
      <Grid item xs={12}>
        {!!scanData.index && (
          <ButtonBase className={classes.headerButton} disableRipple={true} onClick={onChangeExpand}>
            <Grid container spacing={1} direction="row" alignItems="center">
              <Grid item xs="auto">
                <ExpandCollapseButton leftArrowCustomStyle={{ top: 1 }} size={isMobile() ? 12 : 9} expand={expand} />
              </Grid>
              <Grid item xs={isMobile() ? (window.outerWidth <= 300 ? 8 : 7) : 8}>
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item>
                    <DazzedHeading18 className={classes.scanTitle}>
                      {t('pages.patients.selectedPatient.timeline.scanTitle')} {scanData.index}
                    </DazzedHeading18>
                  </Grid>
                  <Grid item>
                    {scans.length && Number.isInteger(scans[0].applianceIndex) && (
                      <ApplianceLabel changeExpand={onChangeExpand} scan={scans[0]} />
                    )}
                    {isServiceAccount && <ScanByServiceAccount className={classes.sentByServiceAccountText} />}
                  </Grid>
                </Grid>
              </Grid>
              {renderDate()}
            </Grid>
          </ButtonBase>
        )}
      </Grid>
      <Grid item xs={12} className={isMobile() ? classes.videoContainerMobile : classes.videoContainer}>
        <Collapse in={expand}>
          {scans.map(scan => (
            <ScanItemDetails key={scan.id} scan={scan} scanIndex={scanData.index} showVideo={expand} />
          ))}
        </Collapse>
      </Grid>
    </Grid>
  )
}

export default ScanItem
