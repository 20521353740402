import * as React from 'react'

const MagnifyingGlass = ({ color = '#4151E6' }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4.39998" cy="4.39998" r="3.88436" stroke={color} strokeWidth="1.03125" />
    <path
      d="M10.9983 11L7.33008 7.33179"
      stroke={color}
      strokeWidth="1.03125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.40137 2.75V6.04999" stroke={color} strokeWidth="1.03125" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.0498 4.39966L2.74982 4.39966"
      stroke={color}
      strokeWidth="1.03125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MagnifyingGlass
