import * as React from 'react'

const PatientTag = () => {
  return (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 13.1822C1 12.2224 1.57102 11.3528 2.47911 11.0419C3.72493 10.6153 5.53336 10.1001 7 10.1001C8.46664 10.1001 10.2751 10.6153 11.5209 11.0419C12.429 11.3528 13 12.2224 13 13.1822V16.4001C13 17.0628 12.4627 17.6001 11.8 17.6001H2.2C1.53726 17.6001 1 17.0628 1 16.4001V13.1822Z"
        fill="#041333"
      />
      <circle cx="7.00039" cy="5.60012" r="3.6" fill="#041333" />
      <path
        d="M23.7865 19.5232L24.2719 18.9054L23.7865 18.2876L21.0182 14.7644L20.2319 15.3822L21.0182 14.7644C20.639 14.2818 20.0593 14 19.4456 14H12.6787C11.5741 14 10.6787 14.8954 10.6787 16V21.8108C10.6787 22.9154 11.5741 23.8108 12.6787 23.8108H19.4456C20.0593 23.8108 20.639 23.529 21.0182 23.0465L23.7865 19.5232Z"
        fill="#041333"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  )
}

export default PatientTag
