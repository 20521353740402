import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Grid } from '@material-ui/core'
import images from 'resources'

const useStyles = makeStyles({
  modal: {
    padding: '0px 40px 0px 40px !important',
    whiteSpace: 'pre'
  },
  title: {
    fontFamily: 'Dazzed',
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'left !important',
    alignItems: 'baseline !important',
    paddingBottom: '0px !important',
    paddingLeft: '12px !important'
  },
  image: {
    width: 805,
    height: 196
  }
})

const AutomationRulesInfoModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <BaseModal
      open={isOpen}
      withCloseIcon
      rootClassName={classes.root}
      title={t('pages.accountSettings.automations.infoModal.title')}
      titleClassName={classes.title}
      onPrimaryBtnClick={handleClose}
      primaryLabel={t('general.gotIt')}
      handleClose={handleClose}
      className={classes.modal}
      maxWidth={'xl'}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <DazzedParagraph14>{t('pages.accountSettings.automations.infoModal.description')}</DazzedParagraph14>
        </Grid>
        <Grid item>
          <img src={images.automationRulesExample} className={classes.image} alt="" />
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default AutomationRulesInfoModal
