import React from 'react'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = () =>
  makeStyles({
    urgencyReasonTitle: {
      color: 'var(--text-color-11)'
    }
  })()

const UrgencyDetails = ({ isUrgent, urgencyReason }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    isUrgent &&
    urgencyReason && (
      <div>
        <DazzedParagraph12 thickness="strong" className={classes.urgencyReasonTitle}>
          {t(`pages.patients.selectedPatient.chat.urgencyReasons.title`)}
        </DazzedParagraph12>
        <DazzedParagraph12>
          {t(`pages.patients.selectedPatient.chat.urgencyReasons.${urgencyReason}`)}
        </DazzedParagraph12>
      </div>
    )
  )
}

export default UrgencyDetails
