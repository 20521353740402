import React, { useCallback, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import {
  ServiceAccountActivate,
  ServiceAccountDeactivate,
  ServiceAccountResetPassword,
  UploadPracticeLogoIllustration
} from 'components/common/icons'
import { useTranslation } from 'react-i18next'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import ImageUploaderWithCrop from 'components/Profile/ImageUploaderWithCrop'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { getCloudFrontUrl } from 'utils/storageUtils'
import { COMPOSITE_PRACTICE_LOGO_FOLDER } from 'consts/settings'
import { trackEvent } from 'utils/analyticsUtils'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import ServiceAccountCropSettings from './ServiceAccountCropSettings'
import useFeatureFlags from 'hooks/useFeatureFlags'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 20
  },
  line: {
    color: 'var(--border-color-25)',
    backgroundColor: 'var(--border-color-25)',
    height: 0.5,
    borderColor: 'var(--border-color-25)',
    marginTop: 15,
    marginBottom: 15,
    width: '100%'
  },
  actionText: {
    color: 'var(--text-color-25)'
  },
  textIconWrapper: {
    cursor: 'pointer'
  },
  detactivatedNote: {
    color: '#DB635F'
  },
  emptyStateTitle: {
    color: '#ACB0B7'
  },
  emptyStateContainer: {
    marginBottom: 20
  },
  actionsRow: { marginLeft: 8 },
  logoDescription: {
    marginBottom: 10
  },
  compositePracticeLogo: {
    maxHeight: 75,
    marginBottom: 10
  }
}))

const ServiceAccountConfiguration = ({
  isActive,
  serviceAccountEmail,
  onDeactivatePressed,
  onActivatePressed,
  onResetPasswordPressed,
  onCreateAccountPressed,
  isCreateAccountDisabled = false,
  isLoading
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const { recordsAppSettings } = usePracticeConfiguration()
  const isSettingsLoading = useSelector(state => state.profileReducer.isLoading)
  const { recordsCropSettings } = useFeatureFlags()

  const compositePracticeLogo = useMemo(() => recordsAppSettings?.compositePracticeLogo, [recordsAppSettings])

  const saveCompositePracticeLogo = useCallback(
    ({ imageKey, bucket }) => {
      trackEvent('Service account - practice logo saved')
      dispatch(
        Actions.requestUpdateAppSettings({
          recordsAppSettings: {
            ...(recordsAppSettings || {}),
            compositePracticeLogo: {
              bucket,
              key: imageKey,
              region: 'us-east-1'
            }
          }
        })
      )
    },
    [dispatch, recordsAppSettings]
  )

  const removeCompositePracticeLogo = useCallback(() => {
    trackEvent('Service account - practice logo removed')
    dispatch(
      Actions.requestUpdateAppSettings({
        recordsAppSettings: {
          ...(recordsAppSettings || {}),
          compositePracticeLogo: null
        }
      })
    )
  }, [recordsAppSettings, dispatch])

  const renderEmptyState = useCallback(
    () => (
      <Grid className={classes.emptyStateContainer} container direction="column" spacing={4} alignItems="flex-start">
        <Grid item>
          <DazzedParagraph14 className={classes.emptyStateTitle} bold>
            {t('pages.accountSettings.services.serviceAccount.configuration.emptyStateTitle')}
          </DazzedParagraph14>
        </Grid>
        <Grid item>
          <PrimaryButton
            width={'300px'}
            onClick={onCreateAccountPressed}
            label={t('pages.accountSettings.services.serviceAccount.configuration.emptyStateButton')}
            isLoading={isLoading}
            type="submit"
            disabled={isCreateAccountDisabled}
          />
        </Grid>
      </Grid>
    ),
    [classes, onCreateAccountPressed, isLoading, isCreateAccountDisabled, t]
  )

  const updateRecordsCropSettings = useCallback(
    setting => {
      trackEvent('Service account - Records crop settings updated', {
        setting
      })
      dispatch(
        Actions.requestUpdateAppSettings({
          recordsAppSettings: {
            ...(recordsAppSettings || {}),
            cropSettings: setting
          }
        })
      )
    },
    [dispatch, recordsAppSettings]
  )

  return (
    <div className={classes.container}>
      {serviceAccountEmail ? (
        <>
          <div className={classes.line} />
          <Grid container direction="column" spacing={2} justifyContent={'space-between'}>
            <Grid item style={{ wordWrap: 'break-word' }}>
              <DazzedParagraph14 bold textAlign="left">
                {serviceAccountEmail}
              </DazzedParagraph14>
              {!isActive && (
                <DazzedParagraph12 className={classes.detactivatedNote} textAlign="left">
                  {t('pages.accountSettings.services.serviceAccount.configuration.deactivated')}
                </DazzedParagraph12>
              )}
            </Grid>

            <Grid className={classes.actionsRow} item>
              {isActive ? (
                <Grid container direction="row" spacing={2} alignItems="center">
                  <Grid className={classes.textIconWrapper} onClick={onResetPasswordPressed} item>
                    <Grid container direction="row" spacing={1} alignItems="center" justifyContent="center">
                      <Grid item>
                        <ServiceAccountResetPassword />
                      </Grid>
                      <Grid item>
                        <DazzedParagraph12 className={classes.actionText} bold textAlign="center">
                          {t('pages.accountSettings.services.serviceAccount.configuration.resetPasswordButton')}
                        </DazzedParagraph12>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className={classes.textIconWrapper} onClick={onDeactivatePressed} item>
                    <Grid container direction="row" spacing={1} alignItems="center">
                      <ServiceAccountDeactivate />
                      <Grid item>
                        <DazzedParagraph12 className={classes.actionText} bold textAlign="center">
                          {t('pages.accountSettings.services.serviceAccount.configuration.deactivateButton')}
                        </DazzedParagraph12>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid className={classes.textIconWrapper} onClick={onActivatePressed} item>
                  <Grid container direction="row" spacing={1} alignItems="center">
                    <ServiceAccountActivate />
                    <Grid item>
                      <DazzedParagraph12 className={classes.actionText} bold textAlign="center">
                        {t('pages.accountSettings.services.serviceAccount.configuration.activateButton')}
                      </DazzedParagraph12>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <div className={classes.line} />
            <Grid style={{ wordWrap: 'break-word' }} justifyContent="flex-start" item>
              <DazzedParagraph14 bold textAlign="left">
                {t('pages.accountSettings.services.serviceAccount.configuration.uploadPracticeLogoTitle')}
              </DazzedParagraph14>
              <DazzedParagraph12 className={classes.logoDescription} bold textAlign="left">
                {t('pages.accountSettings.services.serviceAccount.configuration.uploadPracticeLogoDescription')}
              </DazzedParagraph12>
              {compositePracticeLogo ? (
                <img
                  src={`${getCloudFrontUrl()}/${compositePracticeLogo.key}`}
                  alt="compositePracticeLogo"
                  className={classes.compositePracticeLogo}
                />
              ) : (
                <UploadPracticeLogoIllustration style={{ marginBottom: 8, marginTop: 5 }} />
              )}
              <ImageUploaderWithCrop
                circularCrop={false}
                minHeight={200}
                minWidth={420}
                iconButtons
                label={
                  compositePracticeLogo
                    ? t('pages.accountSettings.practiceProfile.updatePracticeLogo')
                    : t('pages.accountSettings.practiceProfile.uploadPracticeLogo')
                }
                secondaryLabel={compositePracticeLogo && t('pages.accountSettings.practiceProfile.deletePracticeLogo')}
                onUploadDone={([key], bucket) => {
                  saveCompositePracticeLogo({ imageKey: `${COMPOSITE_PRACTICE_LOGO_FOLDER}/${key}`, bucket })
                }}
                s3Folder={COMPOSITE_PRACTICE_LOGO_FOLDER}
                onSecondaryClick={removeCompositePracticeLogo}
                isLoading={isSettingsLoading}
                hideLoader
              />
            </Grid>
          </Grid>
          {recordsCropSettings && (
            <>
              <div className={classes.line} />
              <ServiceAccountCropSettings
                onChangeSettings={updateRecordsCropSettings}
                cropSettings={recordsAppSettings?.cropSettings}
                isSaving={isSettingsLoading}
              />
              <div className={classes.line} />
            </>
          )}
        </>
      ) : (
        renderEmptyState()
      )}
    </div>
  )
}

export default ServiceAccountConfiguration
