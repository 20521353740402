import * as React from 'react'

const Checkmark1 = props => {
  return (
    <svg width="145" height="157" viewBox="0 0 145 117" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.609 55.2064L108.881 42.4785L60.6381 90.7213L38.7279 68.8111L26 81.5391L47.9102 103.449L47.9009 103.458L60.6288 116.186L60.6381 116.177L60.6482 116.187L73.3762 103.459L73.366 103.449L121.609 55.2064Z"
        fill="#97FFBA"
      />
      <mask id="path-2-inside-1_1_5174" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.609 54.2064L113.881 41.4785L65.6381 89.7213L43.7279 67.8111L31 80.5391L52.9102 102.449L52.9009 102.458L65.6288 115.186L65.6381 115.177L65.6482 115.187L78.3762 102.459L78.366 102.449L126.609 54.2064Z"
        />
      </mask>
      <path
        d="M113.881 41.4785L115.295 40.0643L113.881 38.6501L112.467 40.0643L113.881 41.4785ZM126.609 54.2064L128.023 55.6207L129.437 54.2064L128.023 52.7922L126.609 54.2064ZM65.6381 89.7213L64.2239 91.1355L65.6381 92.5497L67.0523 91.1355L65.6381 89.7213ZM43.7279 67.8111L45.1421 66.3969L43.7279 64.9827L42.3137 66.3969L43.7279 67.8111ZM31 80.5391L29.5858 79.1248L28.1716 80.5391L29.5858 81.9533L31 80.5391ZM52.9102 102.449L54.3244 103.863L55.7386 102.449L54.3244 101.035L52.9102 102.449ZM52.9009 102.458L51.4867 101.044L50.0725 102.458L51.4867 103.873L52.9009 102.458ZM65.6288 115.186L64.2146 116.601L65.6291 118.015L67.0433 116.6L65.6288 115.186ZM65.6381 115.177L67.0523 113.763L65.6378 112.348L64.2236 113.763L65.6381 115.177ZM65.6482 115.187L64.234 116.602L65.6482 118.016L67.0624 116.602L65.6482 115.187ZM78.3762 102.459L79.7904 103.874L81.2046 102.459L79.7904 101.045L78.3762 102.459ZM78.366 102.449L76.9518 101.035L75.5376 102.449L76.9518 103.863L78.366 102.449ZM112.467 42.8927L125.195 55.6207L128.023 52.7922L115.295 40.0643L112.467 42.8927ZM67.0523 91.1355L115.295 42.8927L112.467 40.0643L64.2239 88.3071L67.0523 91.1355ZM42.3137 69.2254L64.2239 91.1355L67.0523 88.3071L45.1421 66.3969L42.3137 69.2254ZM32.4142 81.9533L45.1421 69.2254L42.3137 66.3969L29.5858 79.1248L32.4142 81.9533ZM54.3244 101.035L32.4142 79.1248L29.5858 81.9533L51.4959 103.863L54.3244 101.035ZM54.3151 103.873L54.3244 103.863L51.4959 101.035L51.4867 101.044L54.3151 103.873ZM67.043 113.772L54.3151 101.044L51.4867 103.873L64.2146 116.601L67.043 113.772ZM64.2236 113.763L64.2143 113.772L67.0433 116.6L67.0526 116.591L64.2236 113.763ZM67.0624 113.773L67.0523 113.763L64.2239 116.591L64.234 116.602L67.0624 113.773ZM76.9619 101.045L64.234 113.773L67.0624 116.602L79.7904 103.874L76.9619 101.045ZM76.9518 103.863L76.9619 103.874L79.7904 101.045L79.7802 101.035L76.9518 103.863ZM125.195 52.7922L76.9518 101.035L79.7802 103.863L128.023 55.6207L125.195 52.7922Z"
        fill="#519969"
        mask="url(#path-2-inside-1_1_5174)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.257 43.4111L13.3337 53.6667L16.4104 43.4109L26.6667 40.334L16.4108 37.2572L13.3337 27L10.2566 37.257L2.27133e-05 40.334L10.257 43.4111Z"
        fill="#C9DBF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.7692 9.23077L80.5 15L82.2308 9.23078L88 7.50001L82.2308 5.76923L80.5 0L78.7692 5.76924L73 7.50001L78.7692 9.23077Z"
        fill="#C9DBF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.026 106.642L136.834 112.667L138.641 106.641L144.667 104.834L138.641 103.026L136.834 97L135.026 103.026L129 104.834L135.026 106.642Z"
        fill="#939FE0"
      />
    </svg>
  )
}

export default Checkmark1
