import * as React from 'react'

function SvgSwooshEllipse(props) {
  return (
    <svg width={217} height={217} fill="none" {...props} viewBox="0 0 217 217">
      <circle cx={108.5} cy={108.5} r={108} stroke="#3C52EF" />
    </svg>
  )
}

export default SvgSwooshEllipse
