import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { ROUTES } from 'consts'
import OggHeading40 from 'components/common/text/OggHeading40'
import AddPracticeMemberButton from './AddPracticeMemberButton'
import MembersTable from './Table/MembersTable'

const useStyles = makeStyles(theme => ({
  practiceMembersContainer: {
    minWidth: 850
  },
  addPracticeMemberButton: {
    textAlign: 'end',
    marginBottom: 10
  }
}))

const PracticeMembers = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { practiceMembers: isPracticeMembersFFEnabled = false } = useFeatureFlags()
  const history = useHistory()

  const doctor = useSelector(state => state.profileReducer.doctor)

  const handleClickAddMember = useCallback(() => history.push(ROUTES.PRACTICE_MEMBERS_ADD_MEMBER), [history])

  useEffect(() => {
    doctor.user && !isPracticeMembersFFEnabled && history.push(ROUTES.ACCOUNT_SETTINGS)
  }, [doctor.user, isPracticeMembersFFEnabled, history])

  if (!isPracticeMembersFFEnabled) {
    return null
  }

  return (
    <>
      <Grid container alignItems="flex-end" justifyContent="space-between" className={classes.practiceMembersContainer}>
        <Grid item xs={9}>
          <OggHeading40 textAlign="left" className={classes.heading}>
            {t('pages.accountSettings.practiceMembers.title')}
          </OggHeading40>
        </Grid>
        <Grid item xs={3} className={classes.addPracticeMemberButton}>
          <AddPracticeMemberButton onClick={handleClickAddMember} />
        </Grid>
      </Grid>
      <Grid container>
        <MembersTable />
      </Grid>
    </>
  )
}

export default PracticeMembers
