import * as React from 'react'

export default () => {
  return (
    <svg width="50" height="34" viewBox="0 0 50 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49 17L1 17" stroke="#CED0D4" strokeWidth="0.75" />
      <circle cx="25" cy="17" r="16" fill="white" stroke="#CED0D4" />
      <path d="M1 12V21.5" stroke="#CED0D4" strokeWidth="0.75" />
      <path d="M49 12V21.5" stroke="#CED0D4" strokeWidth="0.75" />
    </svg>
  )
}
