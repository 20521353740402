export const TYPES = {
  SALES: { value: 'sales', label: 'Sales' },
  FEATURES_AWARENESS: { value: 'featuresAwareness', label: 'Features awareness' },
  SURVEY: { value: 'survey', label: 'Survey' },
  INFORMATION: { value: 'information', label: 'Information' }
}

export const AnnouncementsAudienceOptions = {
  GRIN_TRIAL: { value: 'grin-trial', label: 'Trial users', types: [TYPES.FEATURES_AWARENESS.value, TYPES.SALES.value] },
  PAID_PLAN: { value: 'paid-plan', label: 'Paying users', types: [TYPES.FEATURES_AWARENESS.value, TYPES.SALES.value] },
  PATIENTS: { value: 'patients', label: 'Patients', types: [TYPES.SURVEY.value, TYPES.INFORMATION.value] }
}
