import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import TimelineItemComponents from './TimelineItems'
import ScrollUp from 'components/common/icons/timelineV2/mini-timeline/ScrollUp'
import MiniTimelineButton from './MiniTimelineButton'
import ScrollDown from 'components/common/icons/timelineV2/mini-timeline/ScrollDown'
import { Element, scroller } from 'react-scroll'
import { trackEvent } from 'utils/analyticsUtils'
import useInViewport from 'hooks/useInViewport'

const useStyles = makeStyles(theme => ({
  miniTimelineRoot: {
    position: 'relative',
    height: '100%',
    minWidth: 65
  },
  timelineContainer: {
    height: '100%',
    overflowY: 'scroll',
    padding: '8px 10px',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  verticalAxis: {
    position: 'absolute',
    top: 25,
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '1px',
    height: '100%',
    background: '#CED9E7'
  },
  timelineItemContainer: {
    marginBottom: '18px',
    background: 'var(--bg-color-timelineV2)',
    position: 'relative',
    '&.lastItem': {
      marginBottom: 0
    }
  }
}))

const TIMELINE_CONTAINER_ID = 'mini-timeline-container'
const BOTTOM_SCROLL_ID = 'mini-timeline-bottom'
const TOP_SCROLL_ID = 'mini-timeline-top'

const MiniTimeline = ({ timelineItems = [], selectedTimelineItemId, setSelectedTimelineItem, className = '' }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const timelineTopRef = useRef()
  const timelineBottomRef = useRef()
  const { isInViewport: isScrolledToTop } = useInViewport(timelineTopRef, { threshold: 0.1 })
  const { isInViewport: isScrolledToBottom } = useInViewport(timelineBottomRef, { threshold: 0.1 })

  const renderableTimelneItems = useMemo(
    () => timelineItems.filter(item => TimelineItemComponents[item.type]),
    [timelineItems]
  )

  const scrollToElement = useCallback(elementId => {
    scroller.scrollTo(elementId, {
      duration: 300,
      smooth: 'easeInQuad',
      containerId: TIMELINE_CONTAINER_ID,
      offset: -150
    })
  }, [])

  const handleScrollToTop = useCallback(() => {
    trackEvent('Mini timeline - scroll to top clicked')
    scrollToElement(TOP_SCROLL_ID)
  }, [scrollToElement])

  const handleScrollToBottom = useCallback(() => {
    trackEvent('Mini timeline - scroll to bottom clicked')
    scrollToElement(BOTTOM_SCROLL_ID)
  }, [scrollToElement])

  const handleSelectTimelineItem = useCallback(
    timelineItem => {
      setSelectedTimelineItem({
        timelineItemId: timelineItem.id,
        source: 'Mini Timeline',
        timelineItemType: timelineItem.type
      })
    },
    [setSelectedTimelineItem]
  )

  useEffect(() => {
    scrollToElement(selectedTimelineItemId || BOTTOM_SCROLL_ID)
  }, [selectedTimelineItemId, renderableTimelneItems, scrollToElement])

  return (
    <div className={[classes.miniTimelineRoot, className].join(' ')}>
      <MiniTimelineButton
        icon={<ScrollUp />}
        tooltip={t('pages.patients.selectedPatient.timelineV2.miniTimeline.buttons.scrollUpTooltip')}
        tooltipPlacement="top"
        onClick={handleScrollToTop}
        disabled={isScrolledToTop}
      />
      <Element className={classes.timelineContainer} id={TIMELINE_CONTAINER_ID}>
        <div ref={timelineTopRef}>
          <Element id={TOP_SCROLL_ID} />
        </div>
        <div className={classes.verticalAxis}></div>
        <div>
          {renderableTimelneItems.map((item, i) => (
            <Element
              className={`${classes.timelineItemContainer} ${
                i === renderableTimelneItems.length - 1 ? 'lastItem' : ''
              }`}
              id={item.id}
              key={item.id}
            >
              {React.createElement(TimelineItemComponents[item.type], {
                timelineItem: item,
                onSelect: () => handleSelectTimelineItem(item),
                isSelected: item.id === selectedTimelineItemId
              })}
            </Element>
          ))}
        </div>
        <div ref={timelineBottomRef}>
          <Element id={BOTTOM_SCROLL_ID} />
        </div>
      </Element>
      <MiniTimelineButton
        icon={<ScrollDown />}
        tooltip={t('pages.patients.selectedPatient.timelineV2.miniTimeline.buttons.scrollDownTooltip')}
        tooltipPlacement="top"
        onClick={handleScrollToBottom}
        disabled={isScrolledToBottom}
      />
    </div>
  )
}

export default React.memo(MiniTimeline)
