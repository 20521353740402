import * as React from 'react'

const RecordsApp = props => (
  <svg width={71} height={36} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M28.57 1.25a2.473 2.473 0 0 0-2.462 2.244l-2.68 28.766a2.473 2.473 0 0 0 2.463 2.703H38.43a2.473 2.473 0 0 0 2.462-2.244l2.68-28.766a2.473 2.473 0 0 0-2.463-2.703H28.57Z"
      fill="#3E52F0"
      stroke="#213598"
      strokeWidth={1.5}
    />
    <path
      d="M30.311 2h9.377l-.132.951a1.5 1.5 0 0 1-1.485 1.294h-6.858a1.057 1.057 0 0 1-1.046-1.202L30.31 2Z"
      fill="#213598"
    />
    <path
      d="M33 12.042A4.042 4.042 0 0 1 37.042 8h29.052a4.042 4.042 0 0 1 4.041 4.042v13.136a4.042 4.042 0 0 1-4.041 4.042H37.04A4.04 4.04 0 0 1 33 25.18V12.042Z"
      fill="#C4CBFA"
    />
    <path
      d="M40.4 12.437a2.021 2.021 0 0 1 1.681-.9h18.911c.676 0 1.307.337 1.682.9l4.116 6.173-4.116 6.174c-.375.562-1.006.9-1.682.9h-18.91a2.021 2.021 0 0 1-1.682-.9l-4.116-6.174 4.116-6.173Z"
      fill="#001335"
    />
    <path
      d="M51.574 18.594c0-2.216.908-4.542 2.326-4.542 1.417 0 1.798 1.38 1.798 2.47.346-.617 1.189-1.816 1.8-1.67.61.144.86 1.247.908 1.78.321-.66 1.054-1.78 1.563-1.78.478 0 .475.88.427 1.394.075-.138.186-.278.336-.414.382-.345.6 3.29-.509 3.889a.256.256 0 0 1-.254-.109.71.71 0 0 1-.255.254c-.258 0-.58-.237-.872-.6-.2-.248-.345-.617-.436-1.017-.218.321-.661.968-1.29.963-.605-.004-1.254-.436-1.254-.908-.018.405-.443 1.217-1.999 1.217-1.555 0-2.29.038-2.29-.927Z"
      fill="#fff"
    />
    <path
      d="M51.574 18.594c0-2.216-.91-4.542-2.327-4.542-1.417 0-1.798 1.38-1.798 2.47-.346-.617-1.189-1.816-1.8-1.67-.61.144-.86 1.247-.908 1.78-.321-.66-1.054-1.78-1.563-1.78-.478 0-.475.88-.427 1.394a1.582 1.582 0 0 0-.336-.414c-.382-.345-.6 3.29.509 3.889a.256.256 0 0 0 .254-.109c.087.145.206.23.255.254.258 0 .58-.237.872-.6.2-.248.345-.617.436-1.017.218.321.661.968 1.29.963.605-.004 1.254-.436 1.254-.908.018.405.443 1.217 1.999 1.217 1.555 0 2.29.038 2.29-.927Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.602 18.34c-.02.23-.03.46-.03.69v-.69h.03Zm8.166 1.929a.406.406 0 0 1-.054.034c-.259 0-.58-.238-.873-.6-.2-.249-.345-.618-.436-1.018-.218.322-.661.968-1.29.963-.605-.004-1.254-.436-1.254-.908-.018.406-.443 1.217-1.999 1.217h-.17c-1.439.002-2.12.002-2.12-.926v1.108c.037.951.291 2.853 1.018 2.853.727 0 1.103-1.163 1.2-1.745.127.521.57 1.563 1.326 1.563.756 0 .897-1.042.872-1.563.17.582.724 1.745 1.581 1.745.858 0 .927-.981.854-1.472.073.364.378 1.09 1.018 1.09.397 0 .422-1.194.327-2.341Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.544 18.34c.019.23.029.46.029.69v-.69h-.03Zm-8.166 1.929c.022.016.04.027.054.034.258 0 .58-.238.872-.6.2-.249.345-.618.436-1.018.218.322.661.968 1.29.963.605-.004 1.254-.436 1.254-.908.018.406.443 1.217 1.999 1.217h.17c1.439.002 2.12.002 2.12-.926v1.108c-.037.951-.291 2.853-1.018 2.853-.727 0-1.102-1.163-1.2-1.745-.127.521-.57 1.563-1.326 1.563-.756 0-.896-1.042-.872-1.563-.17.582-.723 1.745-1.58 1.745-.859 0-.928-.981-.855-1.472-.073.364-.378 1.09-1.017 1.09-.398 0-.423-1.194-.327-2.341Z"
      fill="#fff"
    />
    <path
      d="M23 4.432H9M5 11.432h8M1 28.432h11M7 19.432h2M6 4.432H2M16 11.432h4M15 28.432h4M12 19.432h4"
      stroke="#3E52F0"
      strokeLinecap="round"
    />
  </svg>
)

export default RecordsApp
