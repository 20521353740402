import * as React from 'react'

const FeedbackHeaderIcon = () => {
  return (
    <svg width="160" height="106" viewBox="0 0 160 106" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M159 38.8106C159 35.4717 156.293 32.765 152.954 32.765H85.2434C81.9044 32.765 79.1977 35.4717 79.1977 38.8106V83.8505C79.1977 87.1894 81.9044 89.8962 85.2433 89.8962H129.528V104.179L144.037 89.8962H152.954C156.293 89.8962 159 87.1894 159 83.8505V38.8106Z"
        fill="white"
        stroke="#3C52EF"
      />
      <path
        d="M106.163 57.5307H111.472L111.472 57.5311H111.98C112.247 57.5311 112.449 57.7335 112.449 58.0002V58.0007L112.448 73.5102C112.448 73.7775 112.246 73.9796 111.98 73.9796H106.163C105.896 73.9796 105.694 73.7771 105.694 73.5102V58.0001C105.694 57.7331 105.896 57.5307 106.163 57.5307Z"
        stroke="#3C52EF"
      />
      <path
        d="M136.867 56.1897C138.588 56.1897 139.502 57.4776 139.522 58.8223V58.8683C139.522 59.5733 139.27 60.2708 138.798 60.7726L138.475 61.1156L138.799 61.4582C139.271 61.9591 139.523 62.6563 139.523 63.3615C139.523 64.583 138.783 65.7578 137.408 65.9951L136.78 66.1036L137.035 66.688C137.194 67.0523 137.276 67.4536 137.276 67.8541C137.276 68.5564 137.035 69.2039 136.613 69.684C136.244 70.1027 135.743 70.381 135.158 70.4861L134.535 70.5982L134.789 71.1787C134.948 71.5438 135.03 71.9452 135.03 72.3472C135.03 73.7079 134.115 75.0255 132.374 75.0255H124.782C120.865 75.0255 118.312 74.1484 115.284 73.1048L115.283 73.1046L114.965 72.9953V58.1052C115.134 58.0217 115.297 57.9419 115.453 57.8655C115.483 57.8513 115.511 57.8372 115.54 57.8232L115.562 57.8126C117.385 56.9212 118.56 56.3467 119.915 54.9915L119.915 54.9914C120.778 54.1288 121.459 52.5557 121.952 50.9673C122.452 49.3549 122.788 47.6261 122.912 46.3661L122.912 46.3661L122.913 46.3586C122.982 45.5304 123.699 44.9086 124.528 44.9569L124.55 44.9587L124.55 44.9588L124.559 44.9594C126.024 45.0525 127.183 45.9552 127.811 47.6212L127.811 47.6215C128.685 49.9339 128.319 53.1557 127.039 55.4457L126.623 56.1897H127.476H136.867Z"
        stroke="#3C52EF"
      />
      <path
        d="M39.2966 75.7609H38.7966V76.2609V94.1108L20.3013 75.9045L20.1554 75.7609H19.9506H6.04563C2.98286 75.7609 0.5 73.278 0.5 70.2152V6.1316C0.5 3.06883 2.98286 0.585968 6.04563 0.585968H100.357C103.42 0.585968 105.903 3.06883 105.903 6.1316V70.2152C105.903 73.278 103.42 75.7609 100.357 75.7609H39.2966Z"
        fill="white"
        stroke="#3C52EF"
      />
      <path d="M15.7186 23.3616H90.9867" stroke="#3C52EF" strokeLinecap="round" />
      <path d="M15.7186 38.1734H90.9867" stroke="#3C52EF" strokeLinecap="round" />
      <path d="M15.7186 52.9852H53.3527" stroke="#3C52EF" strokeLinecap="round" />
    </svg>
  )
}

export default FeedbackHeaderIcon
