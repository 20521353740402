import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import BaseModal from './BaseModal'
import SuccessIcon from '../icons/Success'
import LargeHeartIcon from '../icons/LargeHeartIcon'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../../actions'
import ExclamationMarkCircle from '../icons/ExclamationMarkCircle'

const useStyles = makeStyles(theme => ({
  modal: {
    width: 480
  },
  message: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px'
  }
}))

const GrinAlert = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { isOpen, type, title, message, primaryButtonText } = useSelector(state => state.appReducer.alert)

  const alertIcon = useMemo(() => {
    switch (type) {
      case 'success':
        return <SuccessIcon />
      case 'heart':
        return <LargeHeartIcon />
      case 'alert':
        return <ExclamationMarkCircle />
      default:
        return null
    }
  }, [type])

  const handleClose = useCallback(() => {
    dispatch(Actions.hideAlert())
  }, [dispatch])

  return (
    <BaseModal
      title={title}
      open={isOpen}
      handleClose={handleClose}
      titleImageComponent={alertIcon}
      className={classes.modal}
      variant="alert"
      primaryLabel={primaryButtonText}
      onPrimaryBtnClick={primaryButtonText && handleClose}
      largerButtons
    >
      <div className={classes.message}>{message}</div>
    </BaseModal>
  )
}

export default GrinAlert
