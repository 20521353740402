import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import ROUTES from 'consts/routesConsts'
import SwooshLargeIcon from 'components/common/icons/SwooshLarge2'
import Actions from 'actions'
import PersonalDetailsForm from '../Auth/PersonalDetailsForm'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    padding: '15px 15px 50px 15px'
  },
  swoosh: {
    position: 'absolute',
    top: '0',
    left: '0',
    '@media (max-width: 960px)': {
      display: 'none'
    }
  }
}))

const PracticeInfo = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const { firstName, lastName, phoneValue, countryCode, roleDescription, photo, groups } = useSelector(
    state => state.quickSignUpReducer
  )

  const handleFormSubmit = useCallback(() => {
    trackEvent('Sign up - personal info completed', {
      signupType: 'quick'
    })
    history.push(ROUTES.QUICK_SIGN_UP_PRACTICE_INFO)
  }, [history])
  const handleChangeForm = useCallback(value => dispatch(Actions.quickSignUpFormChanged(value)), [dispatch])
  const hanldeChangePhoto = useCallback(photo => handleChangeForm({ key: 'photo', value: photo }), [handleChangeForm])
  const handleChangeGroups = useCallback(
    groups => handleChangeForm({ key: 'groups', value: groups }),
    [handleChangeForm]
  )

  return (
    <div className={classes.container}>
      <SwooshLargeIcon className={classes.swoosh} />
      <PersonalDetailsForm
        firstName={firstName}
        lastName={lastName}
        phoneValue={phoneValue}
        countryCode={countryCode}
        roleDescription={roleDescription}
        photo={photo}
        groups={groups}
        handleFormSubmit={handleFormSubmit}
        hanldeChangePhoto={hanldeChangePhoto}
        handleChangeForm={handleChangeForm}
        handleChangeGroups={handleChangeGroups}
      />
    </div>
  )
}

export default PracticeInfo
