import * as React from 'react'
const DesktopVersion = ({ ...props }) => {
  return (
    <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 5H20V12H16V5Z" stroke="#001335" strokeLinejoin="round" />
      <path d="M3.5 12V3C3.5 1.89543 4.39543 1 5.5 1H16.5C17.0523 1 17.5 1.44772 17.5 2V2" stroke="#001335" />
      <path
        d="M1 11.5C0.723858 11.5 0.5 11.7239 0.5 12C0.5 12.2761 0.723858 12.5 1 12.5V11.5ZM1 12.5H13V11.5H1V12.5Z"
        fill="#001335"
      />
    </svg>
  )
}

export default DesktopVersion
