import * as React from 'react'

function SvgCreditCard(props) {
  return (
    <svg width={16} height={12} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V10C16 11.1046 15.1046 12 14 12H2C0.895431 12 0 11.1046 0 10V2ZM2 1.5H14C14.2761 1.5 14.5 1.72386 14.5 2V3H1.5V2C1.5 1.72386 1.72386 1.5 2 1.5ZM14.5 6V10C14.5 10.2761 14.2761 10.5 14 10.5H2C1.72386 10.5 1.5 10.2761 1.5 10V6H14.5Z"
        fill="#727B8C"
      />
    </svg>
  )
}

export default SvgCreditCard
