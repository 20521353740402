import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { NoScanSummaryIcon } from 'components/common/icons'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles(theme => ({
  container: {
    height: isMobile() ? '100%' : 600
  },
  mobileText: {
    padding: '0px 20px 0px 20px'
  }
}))

const NoScanSummary = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.container}>
      <Grid item>
        <NoScanSummaryIcon />
      </Grid>
      <Grid item>
        <DazzedParagraph16
          textAlign="center"
          color="var(--text-color-57)"
          className={isMobile() ? classes.mobileText : ''}
        >
          {t('dialogs.scanSummary.noScanSummaryText')}
        </DazzedParagraph16>
      </Grid>
    </Grid>
  )
}

export default NoScanSummary
