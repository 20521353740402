import React from 'react'
import { makeStyles } from '@material-ui/core'
import TriangleDown from '../icons/TriangleDown'
import TriangleUp from '../icons/TriangleUp'

export default ({ expand, color }) => {
  const classes = makeStyles({
    icon: {
      position: 'relative',
      bottom: -3
    }
  })()

  return expand ? (
    <TriangleDown className={classes.icon} width={10} height={10} fill={color} />
  ) : (
    <TriangleUp className={classes.icon} width={10} height={10} fill={color} />
  )
}
