import * as React from 'react'

const OnlineLeads = props => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="9" r="4" stroke="#041333" />
    <path
      d="M7 17.3898C7 16.7823 7.27762 16.2073 7.8055 15.9065C8.98944 15.2318 11.4502 14.1429 15 14.1429C18.5498 14.1429 21.0106 15.2318 22.1945 15.9065C22.7224 16.2073 23 16.7823 23 17.3898V21H7V17.3898Z"
      stroke="#041333"
    />
    <circle cx="23" cy="21" r="9" fill="white" />
    <path
      d="M23 27C22.17 27 21.39 26.8425 20.66 26.5275C19.93 26.2125 19.295 25.785 18.755 25.245C18.215 24.705 17.7875 24.07 17.4725 23.34C17.1575 22.61 17 21.83 17 21C17 20.17 17.1575 19.39 17.4725 18.66C17.7875 17.93 18.215 17.295 18.755 16.755C19.295 16.215 19.93 15.7875 20.66 15.4725C21.39 15.1575 22.17 15 23 15C23.83 15 24.61 15.1575 25.34 15.4725C26.07 15.7875 26.705 16.215 27.245 16.755C27.785 17.295 28.2125 17.93 28.5275 18.66C28.8425 19.39 29 20.17 29 21C29 21.83 28.8425 22.61 28.5275 23.34C28.2125 24.07 27.785 24.705 27.245 25.245C26.705 25.785 26.07 26.2125 25.34 26.5275C24.61 26.8425 23.83 27 23 27ZM22.355 26.085V24.855C22.005 24.855 21.71 24.725 21.47 24.465C21.23 24.205 21.11 23.9 21.11 23.55V22.89L18.035 19.815C17.985 20.015 17.95 20.2125 17.93 20.4075C17.91 20.6025 17.9 20.8 17.9 21C17.9 22.3 18.3225 23.435 19.1675 24.405C20.0125 25.375 21.075 25.935 22.355 26.085ZM26.765 24.465C26.985 24.225 27.1775 23.97 27.3425 23.7C27.5075 23.43 27.6475 23.1475 27.7625 22.8525C27.8775 22.5575 27.9625 22.255 28.0175 21.945C28.0725 21.635 28.1 21.32 28.1 21C28.1 19.94 27.81 18.9775 27.23 18.1125C26.65 17.2475 25.875 16.615 24.905 16.215V16.485C24.905 16.835 24.785 17.14 24.545 17.4C24.305 17.66 24.01 17.79 23.66 17.79H22.355V19.095C22.355 19.265 22.2875 19.405 22.1525 19.515C22.0175 19.625 21.865 19.68 21.695 19.68H20.45V21H24.32C24.49 21 24.63 21.065 24.74 21.195C24.85 21.325 24.905 21.475 24.905 21.645V23.55H25.55C25.84 23.55 26.095 23.635 26.315 23.805C26.535 23.975 26.685 24.195 26.765 24.465Z"
      fill="#041333"
    />
  </svg>
)

export default OnlineLeads
