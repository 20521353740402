import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Actions from '../../actions'
import { AnnouncementsAudienceOptions } from '../../consts/announcementConsts'
import { trackEvent } from 'utils/analyticsUtils'
import SurveyAnnouncementModal from './SurveyAnnouncementModal'
import SalesAnnouncementModal from './SalesAnnouncementModal'

const Announcements = () => {
  const dispatch = useDispatch()
  const announcements = useSelector(state => state.announcmentsReducer.announcements)
  const doctorId = useSelector(state => state.profileReducer.doctor.id)

  const handleModalClose = useCallback(
    (announcementId, dontShowAgain = false) => {
      dispatch(
        Actions.dismissAnnouncement({
          announcementId,
          dontShowAgain
        })
      )
    },
    [dispatch]
  )

  const dismissAnnouncement = useCallback(
    (announcementId, dontShowAgain = false) => {
      trackEvent('Announcement Dismissed', {
        announcementId,
        doctorId,
        dontShowAgain
      })

      handleModalClose(announcementId, dontShowAgain)
    },
    [doctorId, handleModalClose]
  )

  const handleActionClicked = useCallback(
    (announcementId, conditions, announcementType, dontShowAgain, href) => {
      trackEvent('Announcement Clicked', {
        doctorId,
        announcementId,
        href
      })

      if (href) {
        window.open(href, '_blank')
        handleModalClose(announcementId, dontShowAgain)
        return
      }

      if (announcementType === 'featuresAwareness') {
        return
      } else if (conditions?.includes(AnnouncementsAudienceOptions.GRIN_TRIAL.value)) {
        dispatch(Actions.setSelectPlanModalVisibility(true))
        handleModalClose(announcementId, dontShowAgain)
      } else if (conditions?.includes(AnnouncementsAudienceOptions.PAID_PLAN.value)) {
        dispatch(Actions.setOrderGrinKitsModalVisibility(true))
        handleModalClose(announcementId, dontShowAgain)
      }
    },
    [dispatch, doctorId, handleModalClose]
  )

  return (
    <>
      {announcements.map((announcement, i) => {
        switch (announcement.type) {
          case 'featuresAwareness':
          case 'sales': {
            return (
              <SalesAnnouncementModal
                handleModalClose={dontShowAgain => dismissAnnouncement(announcement.id, dontShowAgain)}
                isOpen={announcement.isOpen}
                content={announcement.content}
                announcementType={announcement.type}
                handleActionClicked={dontShowAgain =>
                  handleActionClicked(
                    announcement.id,
                    announcement.conditions,
                    announcement.type,
                    dontShowAgain,
                    announcement.href
                  )
                }
                key={i}
              />
            )
          }
          case 'survey': {
            return (
              <SurveyAnnouncementModal
                handleModalClose={dontShowAgain => dismissAnnouncement(announcement.id, dontShowAgain)}
                isOpen={announcement.isOpen}
                content={announcement.content}
                announcementType={announcement.type}
                handleActionClicked={dontShowAgain =>
                  handleActionClicked(announcement.id, announcement.conditions, announcement.type, dontShowAgain)
                }
                key={i}
              />
            )
          }
          default: {
            return null
          }
        }
      })}
    </>
  )
}

export default Announcements
