import * as React from 'react'

export default () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.757812 0.757324L9.24309 9.24261" stroke="#3C52EF" strokeWidth="2" />
      <path d="M9.24219 0.757324L0.756906 9.24261" stroke="#3C52EF" strokeWidth="2" />
    </svg>
  )
}
