import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Collapse, Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import ArrowUp from 'components/common/icons/ArrowUpThick'
import ArrowDown from 'components/common/icons/ArrowDownThick'

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: '1px solid var(--border-color-7)',
    paddingBottom: 22,
    width: '100%',
    marginBottom: 28
  },
  titleContainer: {
    marginBottom: 6,
    cursor: 'pointer'
  },
  contentContainer: {
    marginTop: 10
  }
}))

const ScanSummaryCollapsibleSection = ({ id, label, isOpen, onSelect, children }) => {
  const classes = useStyles()

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.titleContainer} xs={12} onClick={() => onSelect(id)}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <DazzedParagraph16 strong>{label}</DazzedParagraph16>
          </Grid>
          <Grid item>{isOpen ? <ArrowUp /> : <ArrowDown />}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.contentContainer}>
        <Collapse in={isOpen}>{isOpen ? children : <></>}</Collapse>
      </Grid>
    </Grid>
  )
}

export default ScanSummaryCollapsibleSection
