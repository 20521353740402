import React, { useEffect, useMemo, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'utils/mobileUtils'
import { Check, Sketch } from 'components/common/icons'
import { withOrientationChange } from 'react-device-detect'
import { getObjectUrl } from 'utils/mediaUtils'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const IMAGE_HORIZONTAL_SPACING = 8

const useStyles = ({ isMobile, isLandscape }) =>
  makeStyles({
    container: {
      maxWidth: isMobile ? '20vw' : 175,
      height: isMobile && '70vh',
      overflow: isMobile && 'auto',
      marginLeft: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    containerMobilePortrait: {
      scrollPaddingRight: '50px',
      maxWidth: '100%',
      height: '100%',
      overflow: 'auto',
      display: 'flex',
      paddingBottom: 10,
      paddingLeft: 5,
      paddingRight: 5,
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'fixed',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    previewContainer: {
      width: isMobile ? (isLandscape ? '20vw' : '40vw') : '100%',
      minWidth: isMobile ? (isLandscape ? '20vw' : '40vw') : 'auto',
      flex: 1,
      height: 92,
      maxHeight: 92,
      borderRadius: '9px',
      position: 'relative',
      cursor: 'pointer',
      padding: 5,
      transition: 'border .4s',
      border: '1px solid transparent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    footerItem: {
      minWidth: '10vw',
      width: '10vw'
    },
    visibleHighlighted: {
      borderColor: 'var(--border-color-1)',
      boxShadow: isMobile ? '0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.19)' : undefined
    },
    emptyContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'var(--bg-color-2)',
      textAlign: 'center',
      padding: 22,
      color: 'var(--text-color-11)',
      cursor: 'default'
    },
    image: {
      width: `calc(100% - ${IMAGE_HORIZONTAL_SPACING}px)`,
      height: 73,
      objectFit: 'cover',
      borderRadius: '8px'
    },
    radioContainer: {
      position: 'absolute',
      left: 15,
      top: 15,
      width: 21,
      height: 21,
      borderRadius: '50%',
      transition: '.3s',
      zIndex: 25
    },
    radio: {
      border: '2px solid var(--bg-color-10)',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      '& svg': {
        display: 'none'
      },
      '&:hover': {
        borderWidth: 3
      }
    },
    radioSelected: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      backgroundColor: 'var(--bg-color-7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        display: 'block'
      }
    },
    emptyHeightMobile: {
      height: 102
    },
    editedImageLabel: {
      position: 'absolute',
      bottom: 5,
      left: 13
    },
    toggleAllButton: {
      color: 'var(--text-color-25)'
    }
  })()

const ModalGallery = ({
  items,
  selectedImages,
  editedImages,
  toggleSelected,
  setActive,
  activeImage,
  isLandscape,
  onToggleAll
}) => {
  const classes = useStyles({ isMobile: isMobile(), isLandscape })
  const { t } = useTranslation()
  const activeImageContainerRef = useRef(null)

  const allSelected = useMemo(() => selectedImages.length === items.length, [items, selectedImages])

  useEffect(() => {
    if (isMobile()) {
      setTimeout(() => {
        activeImageContainerRef.current.scrollIntoView({ behavior: 'smooth' })
      }, 50)
    }
  }, [activeImage])

  return (
    <>
      <div className={isMobile() && !isLandscape ? classes.containerMobilePortrait : classes.container}>
        <div className={classNames(classes.previewContainer)}>
          <DazzedParagraph14 className={classes.toggleAllButton} textAlign="start" onClick={onToggleAll}>
            {allSelected ? t('general.deselectAll') : t('general.selectAll')}
          </DazzedParagraph14>
        </div>
        {items.map(([position, url], index) =>
          !url ? (
            <div key={position} className={classNames(classes.previewContainer)}>
              <div className={classNames(classes.image, classes.emptyContainer)}>
                {t('dialogs.scanSummary.couldntBeCaptured')}
              </div>
            </div>
          ) : (
            <div
              ref={activeImage === position ? activeImageContainerRef : null}
              key={position}
              className={classNames(classes.previewContainer, activeImage === position && classes.visibleHighlighted)}
              onClick={() => setActive(position)}
            >
              <img
                src={editedImages[position] || getObjectUrl(url)}
                alt={`scan summary ${position} thumbnail`}
                className={classNames(classes.image)}
              />
              {!isMobile() && (
                <div className={classes.radioContainer} onClick={e => toggleSelected(e, position)}>
                  <div className={selectedImages.includes(position) ? classes.radioSelected : classes.radio}>
                    <Check className="checkbox" />
                  </div>
                </div>
              )}
              {editedImages[position] && (
                <div className={classes.editedImageLabel}>
                  <Sketch />
                </div>
              )}
            </div>
          )
        )}
        {isMobile() && !isLandscape && <div className={classes.footerItem} />}
      </div>
      {isMobile() && !isLandscape && <div className={classes.emptyHeightMobile} />}
    </>
  )
}

export default withOrientationChange(ModalGallery)
