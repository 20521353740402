import * as React from 'react'

const KeepAligners = props => (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="3" width="14" height="10" fill="#527195" />
    <path d="M0 1C0 0.447715 0.447715 0 1 0H13C13.5523 0 14 0.447715 14 1V2H0V1Z" fill="#527195" />
    <rect x="2" y="5" width="2" height="2" fill="#F9F9FF" />
    <rect x="2" y="9" width="2" height="2" fill="#F9F9FF" />
    <rect x="6" y="5" width="2" height="2" fill="#F9F9FF" />
    <rect x="6" y="9" width="2" height="2" fill="#F9F9FF" />
    <rect x="10" y="5" width="2" height="2" fill="#F9F9FF" />
    <rect x="10" y="9" width="2" height="2" fill="#F9F9FF" />
  </svg>
)

export default KeepAligners
