import * as React from 'react'

const Share = ({ color = 'white', ...props }) => {
  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.1683 5.74618C9.0021 5.91007 6.04183 7.22811 3.77061 9.49937C1.3391 11.9308 0 15.1524 0 18.5706V23.293L1.71066 19.3495C3.70874 15.3681 7.76066 12.7284 12.1683 12.4828V17.925L22.9776 9.09562L12.1683 0.292969V5.74618ZM13.5154 7.07581V3.12716L20.8463 9.09719L13.5154 15.0853V11.117H12.8419C10.2879 11.117 7.78001 11.8351 5.58926 13.1936C3.93024 14.2224 2.51674 15.5788 1.43169 17.1721C2.12353 11.4907 6.97672 7.07581 12.8419 7.07581H13.5154Z"
        fill={color}
      />
    </svg>
  )
}

export default Share
