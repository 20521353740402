const mapper = [
  { poseKey: 'pose_center', value: 'center' },
  { poseKey: 'pose_left', value: 'left' },
  { poseKey: 'pose_right', value: 'right' },
  { poseKey: 'pose_upper', value: 'upper' },
  { poseKey: 'pose_lower', value: 'lower' },
  { poseKey: 'profile', value: 'profile' },
  { poseKey: 'front-smiling', value: 'front-smiling' },
  { poseKey: 'front-rest', value: 'front-rest' },
  { poseKey: 'composite', value: 'composite' }
]

export const parsePoseNameFromPath = path => mapper.find(({ poseKey }) => path.includes(poseKey))?.value
