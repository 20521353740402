import React, { useCallback, useMemo } from 'react'
import GrinFloatingPopup from 'components/common/menu/GrinFloatingPopup'
import { useTranslation } from 'react-i18next'
import { Assignee2 } from 'components/common/icons'
import AssignPatientForm from '../AssignPatient/AssignPatientForm'
import { useSelector } from 'react-redux'
import { useAssignPatient } from '../AssignPatient/useAssignPatient'
import usePatientTags from 'hooks/usePatientTags'
import { trackEvent } from 'utils/analyticsUtils'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  floatingPopup: {
    height: 374,
    width: 374
  }
}))

const AssignPatientFloatingPopup = ({ triggerComponent, children, onOpen, onClose, isOpen }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    practiceMembersSelectOptions,
    setAssignee,
    assignee,
    note,
    setNote,
    handleSubmit: handleSubmitAssignPatient,
    handleClose: onCloseAssignPatient
  } = useAssignPatient({
    withUnassignOption: true
  })
  const { patient: assignPatient } = useSelector(state => state.practiceReducer.assignPatient)
  const { assigneeTag } = usePatientTags({ patient: assignPatient || {} })

  const shouldDisablePrimaryBtn = useMemo(
    () => !assigneeTag?.assigneeId && assignee === 'Unassigned',
    [assignee, assigneeTag?.assigneeId]
  )

  const handleCloseMenu = useCallback(() => {
    onClose()
    onCloseAssignPatient()
    trackEvent('Assign patient - menu closed', {
      origin: 'Patients header'
    })
  }, [onClose, onCloseAssignPatient])

  const handleSubmit = useCallback(() => {
    handleSubmitAssignPatient()
    handleCloseMenu()
  }, [handleCloseMenu, handleSubmitAssignPatient])

  return (
    <GrinFloatingPopup
      className={classes.floatingPopup}
      title={t('dialogs.assignPatient.title')}
      titleIcon={<Assignee2 color="var(--text-color-27)" style={{ marginTop: 4 }} />}
      triggerComponent={triggerComponent}
      secondaryBtnLabel={t('general.cancel')}
      primaryBtnLabel={t('general.confirm')}
      buttonProps={{
        width: 128,
        height: 36
      }}
      onPrimaryBtnClick={handleSubmit}
      onSecondaryBtnClick={handleCloseMenu}
      isPrimaryBtnDisabled={shouldDisablePrimaryBtn}
      open={isOpen}
      onOpen={onOpen}
      onClose={handleCloseMenu}
    >
      <AssignPatientForm
        assignee={assignee}
        note={note}
        practiceMembersSelectOptions={practiceMembersSelectOptions}
        onChangeAssignee={({ value }) => setAssignee(value)}
        onChangeNote={setNote}
        selectMemberInputFullWidth
      />
    </GrinFloatingPopup>
  )
}

export default AssignPatientFloatingPopup
