import { Roles } from 'consts/authConsts'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isUserOfRole } from 'utils/authUtils'

export default () => {
  const rcLeads = useSelector(state => state.rcDashboardReducer.leads)
  const isAdmin = useMemo(() => isUserOfRole([Roles.Admin]), [])

  const totalLeads = useMemo(
    () =>
      rcLeads.filter(lead =>
        !isAdmin
          ? true
          : !lead.email?.includes('@get-grin.com') &&
            JSON.parse(lead.rcData || '{}').shippingDetails?.status === 'shipmentRequired'
      ).length,
    [rcLeads, isAdmin]
  )

  return {
    totalLeads
  }
}
