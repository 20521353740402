import * as React from 'react'

export default () => {
  return (
    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 1.6665L13.6667 1.6665C15.5076 1.6665 17 3.15889 17 4.99984V4.99984C17 6.84079 15.5076 8.33317 13.6667 8.33317L11 8.33317"
        stroke="#4151E6"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7 8.3335L4.33333 8.3335C2.49238 8.3335 1 6.84111 1 5.00016V5.00016C1 3.15921 2.49238 1.66683 4.33333 1.66683L7 1.66683"
        stroke="#4151E6"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M13 5L4.33333 5" stroke="#4151E6" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}
