import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import HeaderColors from './ThemeColors/HeaderColors'
import AccentColor from './ThemeColors/AccentColor'
import FooterColor from './ThemeColors/FooterColor'
import HeaderPreview from './HeaderPreview/HeaderPreview'
import PrimaryButton from '../../../../common/buttons/PrimaryButton'
import SecondaryButton from '../../../../common/buttons/SecondaryButton'
import PreviewWebpage from './PreviewWebpage'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import Actions from '../../../../../actions'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 20,
    marginBottom: 10,
    padding: '0 20px'
  },
  secondaryButton: {
    marginRight: 15
  },
  buttons: {
    marginTop: 15
  }
}))

const LandingPageDesign = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    rcConfiguration: { rcTheme }
  } = usePracticeConfiguration()

  const grinUser = useSelector(state => state.practiceReducer?.accountOwner?.user)
  const isLoading = useSelector(state => state.practiceReducer.isLoading)

  const { accent, footer, header } = rcTheme

  const [headerBackgroundColor, setHeaderBackgroundColor] = useState(header.background.selectedColor)
  const [headerBackgroundColorOptions, setHeaderBackgroundColorOptions] = useState(header.background.options)
  const [headerFontColor, setHeaderFontColor] = useState(header.font.selectedColor)
  const [headerFontColorOptions, setHeaderFontColorOptions] = useState(header.font.options)
  const [accentColor, setAccentColor] = useState(accent.selectedColor)
  const [accentColorOptions, setAccentColorOptions] = useState(accent.options)
  const [footerBackgroundColor, setFooterBackgroundColor] = useState(footer.background.selectedColor)
  const [footerBackgroundColorOptions, setFooterBackgroundColorOptions] = useState(footer.background.options)
  const [footerTextColor, setFooterTextColor] = useState(footer.text.selectedColor)
  const [footerTextColorOptions, setFooterTextColorOptions] = useState(footer.text.options)

  const handleSaveChanges = useCallback(() => {
    trackEvent('RC design - saved changes')
    const rcTheme = {
      header: {
        background: {
          options: headerBackgroundColorOptions,
          selectedColor: headerBackgroundColor
        },
        font: {
          options: headerFontColorOptions,
          selectedColor: headerFontColor
        }
      },
      accent: {
        options: accentColorOptions,
        selectedColor: accentColor
      },
      footer: {
        background: {
          options: footerBackgroundColorOptions,
          selectedColor: footerBackgroundColor
        },
        text: {
          options: footerTextColorOptions,
          selectedColor: footerTextColor
        }
      }
    }

    dispatch(
      Actions.updateThemeColors({
        grinUser,
        rcTheme
      })
    )
  }, [
    headerBackgroundColorOptions,
    headerBackgroundColor,
    headerFontColorOptions,
    headerFontColor,
    accentColorOptions,
    accentColor,
    footerBackgroundColorOptions,
    footerBackgroundColor,
    footerTextColorOptions,
    footerTextColor,
    dispatch,
    grinUser
  ])

  const handleDiscardChanges = useCallback(() => {
    trackEvent('RC design - discard changes')
    setHeaderBackgroundColor(header.background.selectedColor)
    setHeaderBackgroundColorOptions(header.background.options)
    setHeaderFontColor(header.font.selectedColor)
    setHeaderFontColorOptions(header.font.options)
    setAccentColor(accent.selectedColor)
    setAccentColorOptions(accent.options)
    setFooterBackgroundColor(footer.background.selectedColor)
    setFooterBackgroundColorOptions(footer.background.options)
    setFooterTextColor(footer.text.selectedColor)
    setFooterTextColorOptions(footer.text.options)
  }, [
    accent.options,
    accent.selectedColor,
    footer.background.options,
    footer.background.selectedColor,
    footer.text.options,
    footer.text.selectedColor,
    header.background.options,
    header.background.selectedColor,
    header.font.options,
    header.font.selectedColor
  ])

  return (
    <>
      <PreviewWebpage
        saveChanges={handleSaveChanges}
        headerBackgroundColor={headerBackgroundColor}
        headerFontColor={headerFontColor}
        accentColor={accentColor}
        footerBackgroundColor={footerBackgroundColor}
        footerTextColor={footerTextColor}
      />
      <div className={classes.container}>
        <HeaderPreview
          headerBackgroundColor={headerBackgroundColor}
          headerFontColor={headerFontColor}
          accentColor={accentColor}
        />
        <HeaderColors
          selectedFontColor={headerFontColor}
          suggestedFontColors={headerFontColorOptions}
          selectedBgColor={headerBackgroundColor}
          suggestedBgColors={headerBackgroundColorOptions}
          handleBgColor={color => {
            trackEvent('RC design - colors changed', { color, section: 'header background color' })
            setHeaderBackgroundColor(color)
          }}
          handleBgColorOptions={setHeaderBackgroundColorOptions}
          handleFontColor={color => {
            trackEvent('RC design - colors changed', { color, section: 'header font color' })
            setHeaderFontColor(color)
          }}
          handleFontColorOptions={setHeaderFontColorOptions}
        />
        <AccentColor
          selectedColor={accentColor}
          suggestedColors={accentColorOptions}
          handleColor={color => {
            trackEvent('RC design - colors changed', { color, section: 'accent color' })
            setAccentColor(color)
          }}
          handleOptions={setAccentColorOptions}
        />
        <FooterColor
          textColor={footerTextColor}
          bgColor={footerBackgroundColor}
          accentColor={accentColor}
          suggestedBgColors={footerBackgroundColorOptions}
          suggestedTextColors={footerTextColorOptions}
          handleBgColor={color => {
            trackEvent('RC design - colors changed', { color, section: 'footer background color' })
            setFooterBackgroundColor(color)
          }}
          handleFooterBgColorOptions={setFooterBackgroundColorOptions}
          handleTextColor={color => {
            trackEvent('RC design - colors changed', { color, section: 'footer font color' })
            setFooterTextColor(color)
          }}
          handleTextColorOptions={setFooterTextColorOptions}
        />
        <Grid container alignItems="center" className={classes.buttons}>
          <SecondaryButton
            onClick={handleDiscardChanges}
            className={classes.secondaryButton}
            label={t('pages.accountSettings.services.remoteConsultation.themeColors.discardChangesButtonTitle')}
          />
          <PrimaryButton
            onClick={handleSaveChanges}
            isLoading={isLoading}
            label={t('pages.accountSettings.services.remoteConsultation.themeColors.saveChangesButtonTitle')}
          />
        </Grid>
      </div>
    </>
  )
}

export default LandingPageDesign
