import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RCDashboardEmptyStateImage from 'resources/RCDashboardEmptyState.svg'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import { trackEvent } from 'utils/analyticsUtils'
import useRolePermissions from '../../hooks/useRolePermissions'
import RCGuidance from 'components/Profile/Services/RCToggle/RCGuidance'

const useStyles = makeStyles({
  body: {
    overflowY: 'hidden',
    minWidth: '30%',
    marginTop: '10%'
  },
  textContainer: {
    marginTop: 20,
    textAlign: 'center'
  },
  guidanceContainer: {
    marginTop: 20
  }
})

const RCDashboardEmptyState = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()

  return (
    <Grid
      container
      direction="column"
      alignItems={'center'}
      justifyContent={'center'}
      style={{ overflowY: false }}
      className={classes.body}
    >
      <img src={RCDashboardEmptyStateImage} alt="emptyState" />
      <Grid item className={classes.textContainer}>
        <DazzedHeading18>
          <b>{t(`pages.rcDashboard.emptyState.noNewLeads`)}</b>
        </DazzedHeading18>
      </Grid>
      <Grid item className={classes.textContainer}>
        {t(`pages.rcDashboard.emptyState.inThisView`)}
      </Grid>
      {permissions.rcDashboardEmptyStateLink && (
        <>
          <Grid item className={classes.textContainer}>
            {t(`pages.rcDashboard.emptyState.getMoreLeads`)}
            <br />
            {t(`pages.rcDashboard.emptyState.useTheLinks`)}{' '}
            <a
              href="/profile/account-settings/services"
              target="_blank"
              onClick={() => trackEvent('RC Dashboard - empty state link click')}
            >
              {t(`pages.rcDashboard.emptyState.here`)}
            </a>{' '}
            {t(`pages.rcDashboard.emptyState.shareThem`)}
          </Grid>
          <Grid item className={classes.guidanceContainer}>
            <RCGuidance
              spacing={5}
              guidancesTypes={['howDoesItWork', 'howToManageLeads']}
              analyticSource="Empty state"
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default RCDashboardEmptyState
