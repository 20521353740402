import { BlueDotModes } from 'consts/chatConsts'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useGaFlags from './useGaFlags'

export default () => {
  const gaFlags = useGaFlags()

  const { rc, ortho, doctorLocator } = useSelector(state => state.practiceReducer.services)
  const ownerAppSettings = useSelector(state => state.practiceReducer?.accountOwner?.user?.appSettings)
  const ownerFeatureFlags = useSelector(state => state.practiceReducer?.accountOwner?.user?.featureFlags?.flags)

  const ownerAppSettingsParsed = useMemo(() => JSON.parse(ownerAppSettings || '{}'), [ownerAppSettings])
  const ownerAppFeatureFlags = useMemo(() => JSON.parse(ownerFeatureFlags || '{}'), [ownerFeatureFlags])

  return useMemo(
    () => ({
      isRcFFEnabled: rc.flagTurnedOn,
      isRcEnabled: rc.available,
      isOrthoFFEnabled: ortho.flagTurnedOn,
      isOrthoEnabled: ortho.available,
      isDoctorLocatorEnabled: doctorLocator.available,
      bio: doctorLocator.bio,
      rcConfiguration: {
        rcLinks: ownerAppSettingsParsed.rcLinks,
        rcLPBannerPhoto: ownerAppSettingsParsed.rcLPBannerPhoto,
        rcTheme: ownerAppSettingsParsed.rcTheme
      },
      isPromotionsEnabled:
        gaFlags?.promotionsList ||
        Object.keys(ownerAppFeatureFlags).some(
          flag =>
            (flag === 'promotionsListA' ||
              flag === 'promotionsListB' ||
              flag === 'promotionsListC' ||
              flag === 'promotionsListD') &&
            ownerAppFeatureFlags[flag] === true
        ),
      ownerAppFeatureFlags,
      blueDotMode: ownerAppSettingsParsed.blueDotMode || BlueDotModes.view,
      urgentScanAllowed: ownerAppSettingsParsed.urgentScanAllowed,
      recordsAppSettings: ownerAppSettingsParsed.recordsAppSettings,
      scanSummarySettings: ownerAppSettingsParsed?.scanSummarySettings
    }),
    [rc, ortho, ownerAppSettingsParsed, ownerAppFeatureFlags, gaFlags, doctorLocator]
  )
}
