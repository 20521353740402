import * as React from 'react'

const Arrow = props => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5 10L4.34375 9.32812L8.20312 5.46875H0V4.53125H8.20312L4.34375 0.671875L5 0L10 5L5 10Z" fill="white" />
    </svg>
  )
}

export default Arrow
