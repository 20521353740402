import * as React from 'react'

const Urgent = props => {
  return (
    <svg width="34" height="30" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.92871" y="23.7587" width="23.5714" height="4.74138" rx="1.62561" stroke="#BFBFBF" strokeWidth="1.5" />
      <path
        d="M8.04443 16.0369C8.04443 11.2486 11.9261 7.36694 16.7144 7.36694V7.36694C21.5027 7.36694 25.3843 11.2486 25.3843 16.0369V23.7586H8.04443V16.0369Z"
        stroke="#BFBFBF"
        strokeWidth="1.5"
      />
      <path
        d="M10.8892 21.0694V15.8814C10.8892 12.6643 13.4972 10.0563 16.7143 10.0563V10.0563"
        stroke="#BFBFBF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M30.2612 16.7142H32.4287" stroke="#BFBFBF" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M1 16.7142H3.16749" stroke="#BFBFBF" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M16.7144 3.16748L16.7144 0.999994" stroke="#BFBFBF" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M23.4878 4.98242L24.5715 3.10532" stroke="#BFBFBF" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8.85693 3.10535L9.94068 4.98245" stroke="#BFBFBF" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M28.4463 9.94092L30.3234 8.85717" stroke="#BFBFBF" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M3.10547 8.85718L4.98257 9.94092" stroke="#BFBFBF" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}

export default Urgent
