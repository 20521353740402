import * as React from 'react'

const ShippingIcon = ({ color = 'var(--text-color-8)', className = '' }) => {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 9V3H13L15 6.5V9H10Z" stroke={color} strokeLinecap="round" />
      <rect x="10" y="6" width="5" height="3" fill={color} />
      <path d="M1 3C1 1.89543 1.89543 1 3 1H10V9H1V3Z" stroke={color} />
      <circle cx="4" cy="9" r="2" fill="white" stroke={color} />
      <circle cx="11" cy="9" r="2" fill="white" stroke={color} />
    </svg>
  )
}

export default ShippingIcon
