import React, { useState, useMemo, useCallback } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import momenttz from 'moment-timezone'
import { IconButton, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import GrinMenu from 'components/common/menu/GrinMenu'
import { Trash, Pencil } from 'components/common/icons'
import BaseModal from 'components/common/modals/BaseModal'
import AppointmentForm from './AppointmentForm'

const useStyles = makeStyles({
  externalIcon: {
    marginLeft: 20,
    width: 100
  },
  row: {
    borderBottom: '1px solid var(--border-color-10)',
    height: 65
  },
  timeContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  descriptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 10,
    width: 350
  },
  divider: {
    height: 16,
    width: 1,
    marginRight: 35,
    backgroundColor: 'var(--border-color-19)'
  },
  timezoneName: {
    color: 'var(--text-color-17)',
    marginLeft: 5,
    fontSize: 15
  },
  disabled: {
    color: 'var(--text-color-19)'
  },
  menuItem: {
    height: 50,
    padding: '5px 6px 5px 0px',
    width: 192,
    fontSize: 14,
    '&:hover': {
      backgroundColor: 'var(--bg-color-14)'
    }
  },
  menuItemIcon: {
    width: 44,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center'
  },
  menuWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 20
  },
  deleteModalSubtitle: {
    margin: '0 auto',
    maxWidth: 270
  }
})

const AppointmentItem = ({
  appointment,
  disabled,
  editMode,
  setEditAppointment,
  handleEditAppointment,
  handleDeleteAppointment,
  practiceTimezone = momenttz.tz.guess(),
  considerTimezoneHours = true
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const actions = useMemo(
    () => [
      {
        icon: <Pencil />,
        title: t('dialogs.patientInfo.appointments.editAppointment'),
        onClick: () => setEditAppointment(appointment.id)
      },
      {
        icon: <Trash color="var(--bg-color-22)" />,
        title: t('general.delete'),
        onClick: () => setIsDeleteModalOpen(true),
        condition: true
      }
    ],
    [appointment.id, setEditAppointment, t]
  )

  const isDifferentTimezone = useMemo(
    () => considerTimezoneHours && practiceTimezone !== momenttz.tz.guess(),
    [considerTimezoneHours, practiceTimezone]
  )

  const onDeleteAppointment = useCallback(() => {
    handleDeleteAppointment({ id: appointment.id, _version: appointment?._version })
    setIsDeleteModalOpen(false)
  }, [appointment, handleDeleteAppointment])

  return (
    <>
      {editMode ? (
        <AppointmentForm
          appointment={appointment}
          handleSubmit={handleEditAppointment}
          handleCancel={() => setEditAppointment(null)}
        />
      ) : (
        <Grid container className={classes.row} alignItems="center">
          <Grid item xs={3}>
            <DazzedParagraph16 strong className={disabled && classes.disabled}>
              {appointment.type}
            </DazzedParagraph16>
          </Grid>
          <Grid item className={classes.divider} />
          <Grid item xs={3}>
            <DazzedParagraph16 strong className={disabled && classes.disabled}>
              {momenttz(appointment.date).tz(practiceTimezone).format('dddd, MMMM DD, YYYY')}
            </DazzedParagraph16>
          </Grid>
          <Grid item className={classes.divider} />
          <Grid item xs={3}>
            <DazzedParagraph16 strong className={disabled && classes.disabled}>
              {considerTimezoneHours
                ? momenttz(appointment.date).tz(practiceTimezone).format('hh:mma')
                : momenttz.utc(appointment.date).format('hh:mma')}
              {isDifferentTimezone && (
                <span className={[classes.timezoneName, disabled && classes.disabled].join(' ')}>
                  (
                  {t('dialogs.patientInfo.appointments.tzExplanation', {
                    timezone: momenttz(appointment.date).tz(practiceTimezone).format('z')
                  })}
                  )
                </span>
              )}
            </DazzedParagraph16>
          </Grid>
          <Grid item xs={1}>
            {!disabled && (
              <div className={classes.menuWrapper}>
                <GrinMenu
                  open={isOptionsOpen}
                  triggerComponent={
                    <IconButton>
                      <MoreVert />
                    </IconButton>
                  }
                  transformOrigin={{ vertical: 0, horizontal: 'right' }}
                  onOpen={() => setIsOptionsOpen(true)}
                  onClose={() => setIsOptionsOpen(false)}
                >
                  {actions
                    .filter(action => action.condition)
                    .map(action => (
                      <MenuItem
                        key={action.title}
                        onClick={() => {
                          action.onClick()
                          setIsOptionsOpen(false)
                        }}
                        className={classes.menuItem}
                      >
                        <span className={classes.menuItemIcon}>{action.icon}</span>
                        {action.title}
                      </MenuItem>
                    ))}
                </GrinMenu>
              </div>
            )}
          </Grid>
        </Grid>
      )}
      <BaseModal
        open={isDeleteModalOpen}
        title={t('dialogs.patientInfo.appointments.deleteAppointmentModal.title')}
        primaryLabel={t('general.yes')}
        secondaryLabel={t('general.cancel')}
        onPrimaryBtnClick={onDeleteAppointment}
        onSecondaryBtnClick={() => setIsDeleteModalOpen(false)}
        handleClose={() => setIsDeleteModalOpen(false)}
      >
        <DazzedParagraph16 className={classes.deleteModalSubtitle} textAlign="center">
          {t('dialogs.patientInfo.appointments.deleteAppointmentModal.subtitle')}
        </DazzedParagraph16>
      </BaseModal>
    </>
  )
}

export default AppointmentItem
