import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { ModalAlert } from 'components/common/icons'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  modalContainer: {
    padding: 20
  },
  container: {
    paddingTop: 20
  },
  text: {
    fontWeight: 400
  }
}))

const DeleteMedicalRecordModal = ({ isOpen, setIsOpen, onDelete }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <BaseModal
      open={isOpen}
      onPrimaryBtnClick={() => setIsOpen(false)}
      onSecondaryBtnClick={onDelete}
      primaryLabel={t('general.cancel')}
      secondaryLabel={t('general.yes')}
      handleClose={() => setIsOpen(false)}
      className={classes.modalContainer}
      primaryBtnClassname={classes.primaryBtn}
      secondaryBtnClassname={classes.secondaryBtn}
      reverseActionsOrder={true}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
        spacing={2}
      >
        <Grid item>
          <ModalAlert />
        </Grid>
        <Grid item>
          <DazzedHeading18 className={classes.text}>
            {t('dialogs.patientInfo.medicalHistory.areYouSureDeleteMessage')}
          </DazzedHeading18>
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default DeleteMedicalRecordModal
