import React, { useCallback, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { trackEvent } from 'utils/analyticsUtils'
import CreditCardForm from './CreditCardForm'
import CreditCardDisclaimer from './CreditCardDisclaimer'
import { createPaymentMethod } from 'utils/stripeUtils'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  form: {
    zIndex: 1
  },
  creditCardFormContainer: {
    height: '100%'
  }
}))

const BillingForm = ({
  paymentMethodId,
  cardLast4,
  cardBrand,
  address1,
  city,
  state,
  zip,
  country,
  skipable,
  mixpanelPayload,
  mixpanelPrefix = '',
  onSkip = () => {},
  onSubmitCallback = () => {}
}) => {
  const classes = useStyles()
  const isAttachingCreditCard = useSelector(state => state.billingReducer.isLoading)

  const [isCreatingPaymentMethod, setIsCreatingPaymentMethod] = useState(false)
  const isLoading = useMemo(
    () => isCreatingPaymentMethod || isAttachingCreditCard,
    [isAttachingCreditCard, isCreatingPaymentMethod]
  )

  const handleSkip = useCallback(() => {
    trackEvent(`${mixpanelPrefix} - skipped billing page`, mixpanelPayload)
    onSkip()
  }, [mixpanelPrefix, mixpanelPayload, onSkip])

  const handleSaveCreditCard = useCallback(
    async ({ element, cardHolderName, billingAddress }) => {
      if (isCreatingPaymentMethod) {
        return
      }

      setIsCreatingPaymentMethod(true)
      try {
        const stripePaymentMethod = await createPaymentMethod({
          element,
          cardHolderName,
          address: {
            country: billingAddress.country,
            state: billingAddress.tate,
            city: billingAddress.city,
            postal_code: billingAddress.zip,
            line1: billingAddress.address1,
            line2: billingAddress.address2
          }
        })

        setIsCreatingPaymentMethod(false)
        trackEvent(`${mixpanelPrefix} - credit card saved`, {
          paymentMethodId: stripePaymentMethod.paymentMethod.id,
          ...mixpanelPayload
        })
        onSubmitCallback({
          paymentMethodId: stripePaymentMethod.paymentMethod.id,
          cardLast4: stripePaymentMethod.paymentMethod.card.last4,
          cardBrand: stripePaymentMethod.paymentMethod.card.brand
        })
      } catch (ex) {
        setIsCreatingPaymentMethod(false)
      }
    },
    [isCreatingPaymentMethod, mixpanelPrefix, mixpanelPayload, onSubmitCallback]
  )

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Grid container className={classes.form}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <CreditCardDisclaimer mixpanelPrefix={mixpanelPrefix} mixpanelPayload={mixpanelPayload} />(
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.creditCardFormContainer}>
        <CreditCardForm
          onSubmit={handleSaveCreditCard}
          onSkip={handleSkip}
          skipable={skipable}
          isLoading={isLoading}
          mixpanelPrefix={mixpanelPrefix}
          mixpanelPayload={mixpanelPayload}
          clinicAddress={{
            country,
            state,
            zip,
            address1,
            city
          }}
        />
      </Grid>
    </Grid>
  )
}

export default BillingForm
