import { ConditionTypes } from 'consts/autochartConsts'

import Crown from './crown.png'
import Caries from './caries.png'
import Filling from './filling.png'
import Inflamation from './inflamation.png'
import MissingTooth from './missing-tooth.png'
import Plaque from './plaque.png'
import Recession from './recession.png'
import Tartar from './tartar.png'
import RootCanal from './root-canal.png'

export default {
  [ConditionTypes.Crown]: Crown,
  [ConditionTypes.Caries]: Caries,
  [ConditionTypes.Filling]: Filling,
  [ConditionTypes.Inflamation]: Inflamation,
  [ConditionTypes.MissingTooth]: MissingTooth,
  [ConditionTypes.Plaque]: Plaque,
  [ConditionTypes.Recession]: Recession,
  [ConditionTypes.Tartar]: Tartar,
  [ConditionTypes.RootCanal]: RootCanal
}
