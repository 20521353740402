import * as React from 'react'

function AddColorButton(props) {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" y="0.25" width="14.5" height="14.5" rx="7.25" stroke="#3C52EF" strokeWidth="0.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.69555 3H6.91294V6.91273L3 6.91273V7.69534H6.91294V11.6087H7.69555V7.69534H11.6087V6.91273L7.69555 6.91273V3Z"
        fill="#3C52EF"
      />
    </svg>
  )
}

export default AddColorButton
