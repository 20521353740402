import { useMemo } from 'react'

export default filters => {
  const isFilterActivated = useMemo(() => {
    let isFilterActivated = false
    if (filters) {
      Object.keys(filters).forEach(filterType => {
        if (filters[filterType].length > 0) {
          isFilterActivated = true
          return
        }
      })
    }
    return isFilterActivated
  }, [filters])

  return {
    isFilterActivated
  }
}
