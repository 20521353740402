import * as React from 'react'

const MissingTooth = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 4 }}
  >
    <rect x="1" y="1" width="16" height="16" rx="3" fill="#EFF6FF" stroke="#BDD3EE" strokeWidth="2" />
    <path d="M5 5L13 13" stroke="#BDD3EE" strokeWidth="1.5" />
    <path d="M13 5L5 13" stroke="#BDD3EE" strokeWidth="1.5" />
  </svg>
)

export default MissingTooth
