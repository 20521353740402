import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import OggHeading40 from 'components/common/text/OggHeading40'
import { Trans, useTranslation } from 'react-i18next'
import DentalCharting from 'resources/referrals/DentalCharting.png'
import CaseAcceptance from 'resources/referrals/CaseAcceptance.png'
import PersonalizedTeledentistry from 'resources/referrals/PersonalizedTeledentistry.png'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import OggHeading32 from 'components/common/text/OggHeading32'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { trackEvent } from 'utils/analyticsUtils'
import { ArrowRight } from 'components/common/icons'
import { isMobile } from 'utils/mobileUtils'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() => ({
  container: {
    padding: isMobile() ? '70px 20px 40px 20px' : '120px 20px 50px 20px',
    backgroundColor: 'var(--bg-color-69)'
  },
  title: {
    color: 'white',
    fontSize: isMobile() ? 44 : 56,
    fontWeight: 500,
    marginBottom: isMobile() ? 60 : 80
  },
  itemTitle: {
    color: 'white',
    fontSize: 32
  },
  itemDescription: {
    fontSize: isMobile() ? 16 : 20,
    lineHeight: isMobile() ? '26px' : '28px',
    fontWeight: 500,
    textAlign: 'center',
    color: 'white'
  },
  image: {
    width: 324,
    height: 324
  },
  itemsContainer: {
    marginBottom: isMobile() ? 40 : 150
  },
  buttonContainer: {
    width: isMobile() ? '100%' : 'auto',
    padding: isMobile() ? '0px 10px 0px 10px' : 0,
    marginBottom: 30
  }
}))

const LearnMore = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { referral } = useSelector(state => state.referralsReducer)

  const items = useMemo(
    () => [
      {
        title: t('pages.referrals.footer.learnMore.caseAcceptance.title'),
        description: t('pages.referrals.footer.learnMore.caseAcceptance.description'),
        image: CaseAcceptance
      },
      {
        title: t('pages.referrals.footer.learnMore.personalizedTeledentistry.title'),
        description: t('pages.referrals.footer.learnMore.personalizedTeledentistry.description'),
        image: PersonalizedTeledentistry
      },
      {
        title: (
          <Trans
            i18nKey={'pages.referrals.footer.learnMore.dentalCharting.title'}
            components={{ a: <sup style={{ fontSize: '20px' }}>{''}</sup> }}
          />
        ),
        description: (
          <Trans
            i18nKey={'pages.referrals.footer.learnMore.dentalCharting.description'}
            components={{ a: <sup style={{ fontSize: '12px' }}>{''}</sup> }}
          />
        ),
        image: DentalCharting
      }
    ],
    [t]
  )

  const handleLearnMoreClicked = useCallback(() => {
    const referralData = JSON.parse(referral?.referralData || '{}')
    trackEvent('Referrals - Review scan - Learn more clicked', {
      requesterDoctorId: referral?.doctor?.id,
      requesterDoctorName: referral?.doctor?.name,
      referralNotificationDestination: referral?.notificationDestination,
      referralName: referralData?.name
    })
    window.open('https://share.hsforms.com/1ItaDxvjoRCq4v-e5gliQbA475xx', '_blank')
  }, [referral])

  return (
    <Grid container direction="column" alignItems="center" className={classes.container}>
      <Grid item>
        <OggHeading40 textAlign={isMobile() ? 'left' : 'center'} className={classes.title}>
          {t('pages.referrals.footer.learnMore.title')}
        </OggHeading40>
      </Grid>
      <Grid item className={classes.itemsContainer}>
        <Grid container direction={isMobile() ? 'column' : 'row'} justifyContent="center" spacing={isMobile() ? 7 : 2}>
          {items.map((item, index) => (
            <Grid item key={`learn-more-item-${index}`} xs={isMobile() ? 'auto' : 12 / items.length}>
              <Grid container direction="column" alignItems="center" spacing={1}>
                <Grid item>
                  <img src={item.image} alt="" className={classes.image} />
                </Grid>
                <Grid item>
                  <OggHeading32 className={classes.itemTitle}>{item.title}</OggHeading32>
                </Grid>
                <Grid item xs={isMobile() ? 12 : 8}>
                  <DazzedHeading20 className={classes.itemDescription}>{item.description}</DazzedHeading20>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <PrimaryButton
          label={t('pages.referrals.footer.learnMore.buttonLabel')}
          icon={<ArrowRight />}
          iconSide="right"
          onClick={handleLearnMoreClicked}
          width={isMobile() ? '100%' : 'auto'}
        />
      </Grid>
    </Grid>
  )
}

export default LearnMore
