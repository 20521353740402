import Actions from 'actions'
import { PatientBriefModalMode } from 'consts/hiToolsConsts'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default ({ analyticsSource }) => {
  const dispatch = useDispatch()
  const { id: patientId, details: patientDetails, treatments } = useSelector(state => state.patientsReducer.patient)

  const treatmentType = useMemo(() => treatments?.items[0]?.type, [treatments])

  const viewPatientBrief = useCallback(
    ({ patientBrief }) => {
      dispatch(
        Actions.togglePatientBriefModal({
          isOpen: true,
          mode: PatientBriefModalMode.View,
          patientId,
          patientName: patientDetails?.name,
          originalPatientBrief: patientBrief,
          analyticsSource
        })
      )
    },
    [dispatch, patientId, patientDetails, analyticsSource]
  )

  const updatePatientBrief = useCallback(
    ({ patientBrief }) => {
      dispatch(
        Actions.togglePatientBriefModal({
          isOpen: true,
          mode: PatientBriefModalMode.Update,
          patientId,
          patientName: patientDetails?.name,
          treatmentType: treatmentType,
          originalPatientBrief: patientBrief,
          analyticsSource
        })
      )
    },
    [dispatch, patientId, patientDetails, treatmentType, analyticsSource]
  )

  return {
    viewPatientBrief,
    updatePatientBrief
  }
}
