import authConsts from './authConsts'
import routesConsts from './routesConsts'
import urlsConsts from './urlsConsts'
import flags from './flags'
import pmsConsts from './pmsConsts'

export const AUTH = authConsts
export const ROUTES = routesConsts
export const URLS = urlsConsts
export const FLAGS = flags
export const PMS = pmsConsts

export const AsyncStatus = {
  Loading: 'loading',
  Completed: 'completed',
  Failed: 'failed'
}

export default {
  ...authConsts,
  ...routesConsts,
  ...urlsConsts,
  ...flags,
  ...pmsConsts
}
