import { Grid, Grow } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import _ from 'lodash'
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import FormInput from '../common/FormInput'
import { InfoIcon, QuestionMarkIcon } from '../common/icons'
import PhoneNumberInput from '../common/PhoneNumberInput'
import SelectInput from '../common/SelectInput'
import TextArea from '../common/TextArea'
import FilledCheckBox from '../common/inputs/FilledCheckBox'
import DazzedParagraph12 from '../common/text/DazzedParagraph12'
import Tooltip from '../common/Tooltip'
import { useTranslation } from 'react-i18next'
import { useCountry } from '../../hooks/useCountry'
import { COUNTRY_CA, COUNTRY_CH, COUNTRY_IN } from '../../consts/countryConsts'
import { Collapse } from '@material-ui/core'
import ImageUploader from './ImageUploader'
import { CLINIC_LOGO_FOLDER } from 'consts/settings'
import { isValidEmail } from 'utils/generalUtils'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import doctorLocator from 'resources/doctorLocator.png'

const BIO_MAX_LENGTH = 150

const useStyles = makeStyles(theme => ({
  streetContainer: {
    position: 'relative'
  },
  countryContainer: {
    marginTop: 10
  },
  addressWarning: {
    padding: '12px 20px',
    fontSize: 16,
    position: 'absolute',
    background: 'var(--bg-color-13)',
    width: 245,
    height: 145,
    right: -270,
    top: 30,
    '&::before': {
      content: "''",
      width: 0,
      height: 0,
      position: 'absolute',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid var(--border-color-5)',
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      left: -19,
      top: 6
    },
    '@media (max-width: 960px)': {
      display: 'none'
    }
  },
  warningTitle: {
    display: 'flex',
    fontWeight: 'bold'
  },
  practiceInfoTitle: {
    fontFamily: 'Ogg',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '40px',
    lineHeight: '40px',
    marginBottom: 64,
    color: 'var(--text-color-1)',
    '@media (max-width: 960px)': {
      marginBottom: 20,
      textAlign: 'center'
    }
  },
  nextButton: {
    background: 'var(--border-color-1)',
    color: 'var(--text-color-1)',
    width: '100%',
    borderRadius: '34px',
    textTransform: 'uppercase',
    '&:hover': {
      background: 'var(--border-color-1)'
    }
  },
  phoneRequired: {
    color: 'var(--text-color-10)',
    fontSize: 12
  },
  locatorCheckboxContainer: {
    left: -11
  },
  locatorCheckboxLabel: {
    color: 'var(--text-color-1)',
    fontSize: 14
  },
  locatorInfo: {
    color: 'var(--text-color-42)',
    fontSize: 14,
    paddingTop: 14
  },
  practiceWebsite: {
    marginTop: 15
  },
  bio: {
    fontFamily: 'Arial!important'
  },
  bioLength: {
    textAlign: 'right',
    fontSize: 11,
    color: 'var(--text-color-1)',
    opacity: 0.5
  },
  form: {
    width: '100%',
    maxWidth: 540,
    margin: '0 auto',
    '@media (max-width: 750px)': {
      maxWidth: '100%'
    },
    zIndex: 1
  },
  practiceNameAndWebsite: {
    paddingLeft: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media (max-width: 960px)': {
      paddingLeft: 0,
      marginTop: 15
    }
  },
  inputWrapper: {
    height: '40px !important',
    marginTop: '0 !important',
    overflow: 'hidden'
  },
  input: {
    backgroundColor: 'var(--bg-color-4) !important',
    height: '40px !important'
  },
  phoneInput: {
    height: '40px !important',
    '&:focus': {
      outline: '1px solid var(--border-color-1) !important'
    }
  },
  phoneInputTitle: {
    color: 'var(--text-color-1)'
  },
  practiceNameInput: {
    '@media (max-width: 960px)': {
      marginBottom: 10
    }
  },
  addressErrorBox: {
    backgroundColor: 'var(--bg-color-46)',
    color: 'var(--text-color-17)',
    border: '1px solid var(--border-color-17)',
    padding: 16,
    margin: '15px 0 25px 0'
  }
}))

const PracticeInfoForm = ({
  clinicLogo,
  clinicHomepageUrl,
  practiceName,
  address,
  address2,
  city,
  state,
  zip,
  country,
  practicePhoneValue,
  practiceCountryCode,
  practiceEmailAddress,
  hasLocatorConsent,
  bio,
  handleFormSubmit,
  hanldeChangeLogo,
  handleChangeForm,
  isLoading,
  isAddressInvalid
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [showAddressWarning, setShowAddressWarning] = useState(false)
  const [showPhoneError, setShowPhoneError] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const [showZipCodeError, setShowZipCodeError] = useState(false)

  const {
    countriesOptions,
    states,
    defaultCountry,
    getDefaultState,
    getCountryKey,
    getStateKey,
    getZipCodeProperties,
    hasZipcode
  } = useCountry(country)

  const defaultState = useMemo(() => getDefaultState(country), [country, getDefaultState])
  const countryKey = useMemo(() => getCountryKey(country), [country, getCountryKey])
  const stateKey = useMemo(() => getStateKey(state, country), [getStateKey, state, country])
  const { validationSchema, placeholder, errorMessage } = useMemo(
    () => getZipCodeProperties(country),
    [country, getZipCodeProperties]
  )
  const bioLength = useMemo(
    () => t('pages.practiceInfo.doctorBioLength', { length: bio?.length || 0, maxLength: BIO_MAX_LENGTH }),
    [bio, t]
  )

  const stateLabel = useMemo(
    () =>
      country === COUNTRY_CA
        ? t('pages.practiceInfo.province')
        : country === COUNTRY_CH
        ? t('pages.practiceInfo.canton')
        : t('pages.practiceInfo.state'),
    [country, t]
  )

  const onSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!zip.match(validationSchema)) {
        setShowZipCodeError(true)
        return
      }
      if (!isValidEmail(practiceEmailAddress)) {
        setShowEmailError(true)
        return
      }
      if (!!practicePhoneValue.slice(practiceCountryCode.length)) {
        handleFormSubmit()
      } else {
        setShowPhoneError(true)
      }
    },
    [zip, validationSchema, practiceEmailAddress, practicePhoneValue, practiceCountryCode.length, handleFormSubmit]
  )

  useEffect(() => {
    if (country !== COUNTRY_IN) {
      handleChangeForm({ key: 'address2', value: '' })
    }
  }, [country, handleChangeForm])

  useEffect(() => {
    if (!hasZipcode) {
      handleChangeForm({ key: 'zip', value: '' })
    }
  }, [handleChangeForm, hasZipcode])

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <div className={classes.practiceInfoTitle}>{t('pages.practiceInfo.label')}</div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item md={3} xs={12} className={classes.uploaderConrainer}>
              <ImageUploader
                title={t('pages.practiceInfo.practiceLogo.title')}
                uploadtext={t('pages.practiceInfo.practiceLogo.uploadPhoto')}
                changeText={t('pages.practiceInfo.practiceLogo.changePhoto')}
                value={clinicLogo}
                onChange={hanldeChangeLogo}
                s3Folder={CLINIC_LOGO_FOLDER}
                circularCrop={false}
              />
            </Grid>
            <Grid item md={9} xs={12} className={classes.practiceNameAndWebsite}>
              <FormInput
                title={t('pages.practiceInfo.practiceName.label')}
                placeholder={t('pages.practiceInfo.practiceName.placeholder')}
                value={practiceName}
                validationRules={{ required: true }}
                setValue={value => handleChangeForm({ key: 'practiceName', value })}
                inputClassName={classes.input}
                containerClassName={classes.practiceNameInput}
              />
              <FormInput
                title={t('pages.practiceInfo.practiceWebsite')}
                placeholder={t('pages.practiceInfo.clinicHomepageUrl.placeholder')}
                value={clinicHomepageUrl}
                setValue={value => handleChangeForm({ key: 'clinicHomepageUrl', value })}
                inputClassName={classes.input}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={1} className={classes.streetContainer}>
            <Grid item md={7} xs={12}>
              <FormInput
                title={t('pages.practiceInfo.street')}
                value={address}
                validationRules={{ required: true }}
                setValue={address => handleChangeForm({ key: 'address', value: address })}
                onFocus={() => setShowAddressWarning(true)}
                inputClassName={classes.input}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <FormInput
                title={t('pages.practiceInfo.city')}
                value={city}
                validationRules={{ required: true }}
                setValue={city => handleChangeForm({ key: 'city', value: city })}
                inputClassName={classes.input}
              />
            </Grid>
            <Grow in={showAddressWarning} timeout={500}>
              <div className={classes.addressWarning}>
                <div className={classes.warningTitle}>
                  <InfoIcon />
                  <span>{t('pages.practiceInfo.addressWarning.title')}</span>
                </div>
                {t('pages.practiceInfo.addressWarning.body')}
              </div>
            </Grow>
          </Grid>
          <Grid container justifyContent="space-between" spacing={1} className={classes.countryContainer}>
            <Grid item md={4} xs={12}>
              <SelectInput
                label={t('pages.practiceInfo.country')}
                value={countryKey}
                defaultValue={defaultCountry.key}
                options={countriesOptions}
                required
                keepErrorContainerWhenInactive={false}
                selectStyle={{
                  height: 40,
                  marginTop: 0
                }}
                onChange={({ value: key }) => {
                  handleChangeForm([
                    {
                      key: 'country',
                      value: key
                    },
                    {
                      key: 'state',
                      value: getDefaultState(key).name
                    }
                  ])
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              {country === COUNTRY_IN ? (
                <FormInput
                  title={t('pages.practiceInfo.state')}
                  value={state}
                  setValue={state => handleChangeForm({ key: 'state', value: state })}
                  onFocus={() => setShowAddressWarning(true)}
                  inputClassName={classes.input}
                  inputWrapperClassName={classes.inputWrapper}
                />
              ) : (
                <SelectInput
                  label={stateLabel}
                  value={stateKey}
                  defaultValue={defaultState.key}
                  values={states.map(state => state.key)}
                  required={!!states.length}
                  disabled={!states.length}
                  keepErrorContainerWhenInactive={false}
                  selectStyle={{
                    height: 40,
                    marginTop: 0
                  }}
                  onChange={({ value: key }) => {
                    handleChangeForm({
                      key: 'state',
                      value: _.get(
                        states.find(s => s.key === key),
                        'name'
                      )
                    })
                  }}
                />
              )}
            </Grid>
            <Grid item md={4} xs={12}>
              <FormInput
                disabled={!hasZipcode}
                title={t('pages.practiceInfo.zip.label')}
                placeholder={placeholder}
                value={zip}
                errorMessage={showZipCodeError && errorMessage}
                validationRules={{ required: hasZipcode }}
                setValue={zip => {
                  setShowZipCodeError(false)
                  handleChangeForm({ key: 'zip', value: zip.toUpperCase() })
                }}
                inputClassName={classes.input}
                inputWrapperClassName={classes.inputWrapper}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={!!isAddressInvalid && !isLoading} style={{ width: '100%' }}>
            <Grid container className={classes.addressErrorBox}>
              <Grid item xs={12}>
                {t('errors.invalidAddress')}
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={country === COUNTRY_IN} style={{ width: '100%' }}>
            <FormInput
              title={t('pages.practiceInfo.detailedAddress')}
              value={address2}
              setValue={value => handleChangeForm({ key: 'address2', value })}
              inputClassName={classes.input}
            />
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <PhoneNumberInput
            title={t('pages.practiceInfo.practicePhone')}
            inputClassName={classes.phoneInput}
            titleClassName={classes.phoneInputTitle}
            value={practicePhoneValue}
            required
            setValue={practicePhoneValue => {
              setShowPhoneError(false)
              handleChangeForm({
                key: 'practicePhoneValue',
                value: practicePhoneValue
              })
            }}
            setCountryCode={practiceCountryCode =>
              handleChangeForm({
                key: 'practiceCountryCode',
                value: practiceCountryCode
              })
            }
          />
          {showPhoneError && <div className={classes.phoneRequired}>{t('pages.practiceInfo.phoneRequired')}</div>}
        </Grid>
        <Grid item xs={12}>
          <FormInput
            containerClassName={classes.practiceEmail}
            title={t('pages.practiceInfo.email.label')}
            placeholder={t('pages.practiceInfo.email.placeholder')}
            type="email"
            validationRules={{ required: true }}
            value={practiceEmailAddress}
            allowAutoComplete={true}
            inputClassName={classes.input}
            setValue={practiceEmailAddress => {
              setShowEmailError(false)
              handleChangeForm({
                key: 'practiceEmailAddress',
                value: practiceEmailAddress?.toLowerCase()
              })
            }}
          />
          {showEmailError && (
            <div className={classes.phoneRequired}>{t('pages.practiceInfo.email.practiceEmailError')}</div>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <FilledCheckBox
                label={t('pages.practiceInfo.hasLocatorConsent')}
                containerStyle={classes.locatorCheckboxContainer}
                labelStyle={classes.locatorCheckboxLabel}
                checked={hasLocatorConsent}
                onChange={value =>
                  handleChangeForm({
                    key: 'hasLocatorConsent',
                    value
                  })
                }
              />
            </Grid>
            <Grid item>
              <Tooltip
                value={
                  <Grid container style={{ maxWidth: 350 }}>
                    <Grid item xs={12}>
                      <img src={doctorLocator} alt="Doctor locator webpage" />
                    </Grid>
                    <Grid item xs={12}>
                      <DazzedParagraph12>{t('pages.practiceInfo.doctorLocatorTooltip')}</DazzedParagraph12>
                    </Grid>
                  </Grid>
                }
                variant="light"
              >
                <QuestionMarkIcon fontSize="inherit" color="white" />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DazzedParagraph12 className={classes.locatorInfo}>
              {t('pages.practiceInfo.doctorLocatorInfo')}
            </DazzedParagraph12>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={hasLocatorConsent}>
            <TextArea
              title={t('pages.practiceInfo.doctorBio')}
              titleComment={t('pages.practiceInfo.doctorBioComment', { length: BIO_MAX_LENGTH })}
              inputStyle={{ height: 100 }}
              className={classes.bio}
              maxLength={BIO_MAX_LENGTH}
              value={bio}
              setValue={bio =>
                handleChangeForm({
                  key: 'bio',
                  value: bio
                })
              }
            />
            <DazzedParagraph12 className={classes.bioLength}>{bioLength}</DazzedParagraph12>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <PrimaryButton
            className={classes.nextButton}
            width="100%"
            type="submit"
            isLoading={isLoading}
            label={t('general.done')}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default PracticeInfoForm
