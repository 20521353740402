import i18n from '../resources/locales/i18n'

export const PERIOD_TYPE_OPTIONS = [
  {
    label: i18n.t('dialogs.patientInfo.treatmentProposal.periodTypeOptions.weeks'),
    value: 'week'
  },
  {
    label: i18n.t('dialogs.patientInfo.treatmentProposal.periodTypeOptions.months'),
    value: 'month'
  }
]

export const VENDOR_OPTIONS = [
  {
    label: i18n.t('dialogs.patientInfo.treatmentProposal.vendorOptions.3m'),
    value: '3m'
  }
  // {
  //   label: i18n.t('dialogs.patientInfo.treatmentProposal.vendorOptions.ormco'),
  //   value: 'ormco'
  // },
  // {
  //   label: i18n.t('dialogs.patientInfo.treatmentProposal.vendorOptions.spark'),
  //   value: 'spark'
  // }
]
