import { useMemo } from 'react'
import { ORTHO_PROGRAM } from 'consts/appConsts'
import { useSelector } from 'react-redux'

export default () => {
  const doctor = useSelector(state => state.practiceReducer.accountOwner)

  const hasPatients = useMemo(
    () =>
      !!doctor?.patients?.items?.find(({ _deleted }) => !_deleted) ||
      !!doctor?.leads?.items?.find(
        ({ _deleted, conversionStatus, program }) =>
          !_deleted && (program === 'rc' ? conversionStatus !== 'pending' : true)
      ),
    [doctor]
  )

  const hasOrthoPatients = useMemo(
    () =>
      !!doctor?.patients?.items
        ?.filter(({ user }) => user?.program === ORTHO_PROGRAM)
        .find(({ _deleted }) => !_deleted),
    [doctor]
  )

  return {
    hasPatients,
    hasOrthoPatients
  }
}
