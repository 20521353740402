import React, { useMemo } from 'react'

import { Grid } from '@material-ui/core'
import { GoBackAligners, KeepAligners as KeepAlignersIcon } from 'components/common/icons'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'

const KeepAlignersOrGoBack = ({ shouldGoBack, shouldKeepAligner, days }) => {
  const { t } = useTranslation()

  const GoBack = (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={2}>
        <GoBackAligners />
      </Grid>
      <Grid item xs={10}>
        <DazzedParagraph14>{t('dialogs.hiPatientGuidelinesViewer.actions.goBack')}</DazzedParagraph14>
      </Grid>
    </Grid>
  )

  const KeepAligners = useMemo(
    () => (
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={2}>
          <KeepAlignersIcon />
        </Grid>
        <Grid item xs={10}>
          <DazzedParagraph14>{t('dialogs.hiPatientGuidelinesViewer.actions.keepAligner', { days })}</DazzedParagraph14>
        </Grid>
      </Grid>
    ),
    [days, t]
  )

  return shouldGoBack ? GoBack : shouldKeepAligner && KeepAligners
}

export default KeepAlignersOrGoBack
