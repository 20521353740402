import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import SwooshLargeIcon from 'components/common/icons/SwooshLarge1'
import FormInput from 'components/common/FormInput'
import OggHeading40 from 'components/common/text/OggHeading40'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import PasswordStrength from 'components/common/PasswordStrength'
import ROUTES from 'consts/routesConsts'
import { validatePasswordDetails } from 'utils/authUtils'
import { PASSWORD_CONFIRMATION_DONT_MATCH } from 'consts/authConsts'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  signUpSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  form: {
    width: 540
  },
  title: {
    color: 'var(--text-color-1)',
    marginBottom: 35
  },
  deactivatedInput: {
    color: 'var(--text-color-9) !important'
  },
  input: {
    height: '40px !important',
    backgroundColor: 'var(--bg-color-4) !important',
    '&:focus': {
      border: '1px solid var(--border-color-1) !important'
    }
  },
  inputContainer: {
    marginTop: 5
  },
  inputWrapper: {
    backgroundColor: 'transparent !important',
    height: 'auto !important',
    '&.error input:focus': {
      border: 'none !important'
    }
  },
  button: {
    marginTop: 20
  },
  userExists: {
    color: 'var(--text-color-1)',
    '& span': {
      color: 'var(--text-color-3)',
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  forgotPassword: {
    color: 'var(--text-color-10)',
    marginTop: 30,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
})

const SignUp = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    password,
    passwordConfirmation,
    email,
    isLoading,
    errors: globalErrors
  } = useSelector(state => state.authReducer.practiceMembers)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    setErrors(globalErrors)
  }, [globalErrors])

  useEffect(() => {
    return () => dispatch(Actions.resetPracticeMembersSignUpErrors())
  }, [dispatch])

  const isPrimaryDisabled = useMemo(
    () => !validatePasswordDetails(password).isValid || !passwordConfirmation || !email,
    [email, password, passwordConfirmation]
  )

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (password !== passwordConfirmation) {
        setErrors({ passwordConfirmation: PASSWORD_CONFIRMATION_DONT_MATCH })
      } else {
        dispatch(Actions.practiceMembersSignUp())
        trackEvent('Practice Member registration started', {
          inviteeEmail: email
        })
      }
    },
    [password, passwordConfirmation, dispatch, email]
  )

  const handleClickLogIn = useCallback(() => {
    history.push(ROUTES.SIGN_IN)
  }, [history])

  const handleClickForgotPassword = useCallback(() => {
    history.push(ROUTES.RESET_PASSWORD)
  }, [history])

  const userExistsContent = useMemo(() => {
    return (
      <>
        <DazzedParagraph12 className={classes.userExists}>
          {t('pages.signUp.userAlreadyExists1')}&nbsp;
          <span onClick={handleClickLogIn}>{t('pages.signUp.userAlreadyExists2')}</span>&nbsp;
          {t('pages.signUp.userAlreadyExists3')}
        </DazzedParagraph12>
        <DazzedParagraph12 className={classes.forgotPassword} onClick={handleClickForgotPassword}>
          {t('pages.signUp.forgotPassword')}
        </DazzedParagraph12>
      </>
    )
  }, [classes.forgotPassword, classes.userExists, handleClickForgotPassword, handleClickLogIn, t])

  return (
    <div className={classes.container}>
      <SwooshLargeIcon className={classes.signUpSwoosh} />
      <form className={classes.form} onSubmit={handleSubmit}>
        <OggHeading40 className={classes.title} textAlign="left">
          {t('pages.signUp.signUp')}
        </OggHeading40>
        <FormInput
          title={t('pages.signUp.email')}
          type="email"
          value={email}
          inputClassName={[classes.input, classes.deactivatedInput].join(' ')}
          inputWrapperClassName={classes.inputWrapper}
          containerClassName={classes.inputContainer}
          isDisabled={true}
          errorMessage={errors.email}
        />
        {errors.userAlreadyExists ? (
          userExistsContent
        ) : (
          <>
            <FormInput
              title={t('pages.signUp.password')}
              type="password"
              value={password}
              inputClassName={classes.input}
              inputWrapperClassName={classes.inputWrapper}
              containerClassName={classes.inputContainer}
              setValue={passwordConfirmation =>
                dispatch(
                  Actions.changePracticeMembersAuthForm({
                    key: 'password',
                    value: passwordConfirmation
                  })
                )
              }
              showPasswordButton
              errorMessage={!!errors.passwordConfirmation}
            />
            <FormInput
              title={t('pages.signUp.confirmPassword')}
              type="password"
              value={passwordConfirmation}
              inputClassName={classes.input}
              inputWrapperClassName={classes.inputWrapper}
              containerClassName={classes.inputContainer}
              setValue={passwordConfirmation =>
                dispatch(
                  Actions.changePracticeMembersAuthForm({
                    key: 'passwordConfirmation',
                    value: passwordConfirmation
                  })
                )
              }
              showPasswordButton
              errorMessage={errors.passwordConfirmation}
            />
            <PasswordStrength password={password} />
            <PrimaryButton
              label={t('general.next')}
              type="sybmit"
              width="100%"
              disabled={isPrimaryDisabled}
              variant={isPrimaryDisabled ? 'outlined' : 'contained'}
              className={classes.button}
              isLoading={isLoading}
            />
          </>
        )}
      </form>
    </div>
  )
}

export default SignUp
