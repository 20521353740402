import React, { useCallback, useEffect, useRef } from 'react'
import { makeStyles, Grid, CircularProgress } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Actions from 'actions'
import { CONTENT_HEIGHT } from 'consts/patientCard'

import PrimaryButton from 'components/common/buttons/PrimaryButton'
import RecordsListItem from './RecordsListItem'
import RecordsSet from './RecordsSet'
import AddTagIcon from 'components/common/icons/AddTag'
import { trackEvent } from 'utils/analyticsUtils'
import { RECORDS_TAB_MODES } from 'consts/recordsConsts'

const useStyles = makeStyles({
  container: {
    height: CONTENT_HEIGHT,
    padding: '0 50px 10px 5%',
    overflowY: 'auto'
  },
  uploadRecordsButton: {
    marginTop: 5,
    marginBottom: 5,
    border: 'none'
  },
  buttonContainer: {
    borderBottom: '1px solid var(--border-color-10)',
    borderTop: '1px solid var(--border-color-10)',
    marginTop: 20
  }
})

const RecordsTab = ({ mode = RECORDS_TAB_MODES.edit, onFileToggled = () => {}, selectedFiles }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const containerRef = useRef(null)

  const { data: records, selectedRecord, isLoading } = useSelector(state => state.patientsReducer.patientCard.scans)

  const selectRecord = useCallback(record => dispatch(Actions.setSelectedRecord(record)), [dispatch])

  const createNewRecordSet = useCallback(
    ({ shouldSendAnalytics = true }) => {
      if (shouldSendAnalytics) {
        trackEvent('Records - add new record set clicked')
      }
      selectRecord({})
    },
    [selectRecord]
  )

  useEffect(() => {
    containerRef?.current?.scrollTo(0, 0)
  }, [selectedRecord?.id])

  useEffect(() => {
    if (!records.length) {
      createNewRecordSet({ shouldSendAnalytics: false })
    }
  }, [createNewRecordSet, records.length])

  useEffect(() => {
    if (records.length === 1) {
      selectRecord(records[0])
    }
  }, [records, selectRecord])

  useEffect(() => {
    dispatch(Actions.fetchPatientRecords())
  }, [dispatch])

  return (
    <div ref={containerRef} className={classes.container}>
      {isLoading ? (
        <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ height: '100%' }}>
          <Grid item>
            <CircularProgress size={40} />
          </Grid>
        </Grid>
      ) : selectedRecord || !records.length ? (
        <RecordsSet
          selectedRecord={selectedRecord || {}}
          setSelectedRecord={selectRecord}
          mode={mode}
          onFileToggled={onFileToggled}
          selectedFiles={selectedFiles}
        />
      ) : (
        <>
          <Grid container justifyContent="center" alignItems="center" className={classes.buttonContainer}>
            <Grid item>
              <PrimaryButton
                label={t('dialogs.patientInfo.records.addNewRecordSet')}
                onClick={createNewRecordSet}
                className={classes.uploadRecordsButton}
                icon={<AddTagIcon />}
                variant="outlined"
                fontColor="var(--text-color-3)"
                width="auto"
              />
            </Grid>
          </Grid>
          {records.map(record => (
            <RecordsListItem key={record.id} record={record} setSelectedRecord={selectRecord} />
          ))}
        </>
      )}
    </div>
  )
}

export default RecordsTab
