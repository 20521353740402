import React, { useMemo } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useTranslation } from 'react-i18next'
import MessageUserInfo from '../MessageUserInfo'
import MessageTail from '../MessageTail'
import { MonochromeBroadcast } from 'components/common/icons'
import { useSelector } from 'react-redux'

const useStyles = ({ alignLeft, isLastMessage }) =>
  makeStyles({
    message: {
      border: '1px solid #C4C4C4',
      borderRadius: ({ isLastMessage, alignLeft }) =>
        isLastMessage ? (alignLeft ? '16px 16px 16px 0' : '16px 16px 0 16px') : '16px',
      minWidth: 180,
      maxWidth: 300.5,
      position: 'relative'
    },
    tailContainer: {
      zIndex: 20,
      right: ({ alignLeft }) => !alignLeft && '-12px !important',
      bottom: '-1px !important'
    },
    tail: {
      backgroundColor: '#E1E5FE !important',
      borderBottom: '1px solid #C4C4C4 !important'
    },
    circle: {
      border: '1px solid #C4C4C4 !important'
    },
    clickedFlag: {
      position: 'absolute',
      right: -2,
      top: 10,
      backgroundColor: '#FFB939',
      height: 15,
      fontSize: 10,
      lineHeight: '12px',
      color: 'var(--text-color-7)',
      padding: '1px 6px 2px 6px'
    },
    container: {
      width: 298
    },
    imageContainer: {
      height: 250
    },
    preview: {
      minWidth: 180,
      objectFit: 'cover',
      height: 250,
      maxWidth: 299,
      borderRadius: ({ alignLeft }) => (alignLeft ? '16px 16px 0px 0px' : '16px 16px 0px 0px')
    },
    footerContainer: {
      width: '100%',
      maxWidth: 300,
      backgroundColor: '#E1E5FE',
      borderRadius: ({ isLastMessage, alignLeft }) =>
        isLastMessage ? (alignLeft ? '0 0 16px 0' : '0 0 0 16px') : '0 0 16px 16px',
      padding: 15
    },
    footer: {
      display: 'inline'
    },
    messageUserInfo: {
      color: 'rgba(0, 19, 53, 0.7)',
      marginTop: 5,
      marginLeft: 11
    },
    timestamp: {
      marginTop: 8,
      fontSize: 10,
      width: '100%',
      textAlign: 'right',
      color: 'rgba(0, 19, 53, 0.45)'
    },
    broadcastIcon: {
      position: 'absolute',
      right: ({ alignLeft }) => (alignLeft ? 'auto' : 'calc(100% + 15px)'),
      left: ({ alignLeft }) => (alignLeft ? 'calc(100% + 15px)' : 'auto'),
      top: '50%',
      transform: 'translateY(-50%)'
    }
  })({ alignLeft, isLastMessage })

const PromotionMessage = ({
  content,
  alignLeft,
  link,
  name,
  userType,
  isFirstMessage,
  isLastMessage,
  timestamp,
  promotionData,
  isBroadcast,
  promotionId
}) => {
  const classes = useStyles({ alignLeft, isLastMessage })
  const doctorLink = (link.split('?') && link.split('?')[0]) || link
  const { t } = useTranslation()

  const promotions = useSelector(state => state.chatReducer.promotions?.data)
  const promotion = useMemo(() => promotions.find(promotion => promotion.id === promotionId), [promotionId, promotions])
  const parsedPromotionData = useMemo(() => (promotionData ? JSON.parse(promotionData) : {}), [promotionData])

  return (
    <Grid>
      <Grid container className={classes.message}>
        {(parsedPromotionData.firstClickedTS || parsedPromotionData.seenTS) && (
          <Grid item className={classes.clickedFlag}>
            {parsedPromotionData.firstClickedTS
              ? t(`pages.patients.selectedPatient.chat.promotions.clickedFlag`)
              : t(`pages.patients.selectedPatient.chat.promotions.viewedFlag`)}
          </Grid>
        )}
        {isLastMessage && (
          <MessageTail
            alignLeft={alignLeft}
            containerClassName={classes.tailContainer}
            tailClassName={classes.tail}
            circleClassName={classes.circle}
          />
        )}
        <Grid item>
          {isFirstMessage && <MessageUserInfo name={name} userType={userType} className={classes.messageUserInfo} />}
        </Grid>
        <Grid item>
          <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.container}>
            <Grid item className={classes.imageContainer}>
              <img src={content} className={classes.preview} alt="..." />
            </Grid>
            <Grid item className={classes.footerContainer}>
              <DazzedParagraph12 thickness={'thin'}>
                {t('pages.patients.selectedPatient.chat.promotions.footer1')}{' '}
                <a href={doctorLink} target="_blank" rel="noreferrer">
                  {t('pages.patients.selectedPatient.chat.promotions.linkText')}
                </a>
                .
              </DazzedParagraph12>
              <Grid container>
                {promotion?.provider !== 'Grin' && (
                  <>
                    <Grid item>
                      <DazzedParagraph12 thickness={'thin'} className={classes.footer}>
                        {t('pages.patients.selectedPatient.chat.promotions.footer2')}
                      </DazzedParagraph12>
                      <DazzedParagraph12 thickness={'bold'} className={classes.footer}>
                        {t('pages.patients.selectedPatient.chat.promotions.footer3')}
                      </DazzedParagraph12>
                    </Grid>
                  </>
                )}
                <Grid item className={classes.timestamp}>
                  {timestamp}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isBroadcast && <MonochromeBroadcast className={classes.broadcastIcon} />}
      </Grid>
    </Grid>
  )
}

export default PromotionMessage
