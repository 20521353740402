import * as React from 'react'

const CodeTag = props => {
  return (
    <svg width="38" height="28" viewBox="0 0 38 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 9L9 14L14 19" stroke="#4151E6" strokeWidth="1.5" />
      <path d="M24 19L29 14L24 9" stroke="#4151E6" strokeWidth="1.5" />
      <path d="M21 7L17 21" stroke="#4151E6" strokeWidth="1.5" />
    </svg>
  )
}

export default CodeTag
