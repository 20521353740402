import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import {
  ARCH_DEFAULT_COLOR,
  BRACES_DEVIATION_ARCH_COLOR,
  DEVIATION_COLORS,
  ARCHES_DEVIATION_LIMITS
} from './archesViewerConsts'
import { TreatmentTypes } from 'consts/treatmentConsts'
import { getObjectUrl } from 'utils/mediaUtils'

export const getDeviationColor = deviation => {
  if (deviation === undefined || deviation === null) {
    return null
  }
  if (deviation <= ARCHES_DEVIATION_LIMITS.MINOR_OFFSET) {
    return DEVIATION_COLORS.MINOR_OFFSET
  } else if (deviation <= ARCHES_DEVIATION_LIMITS.SMALL_OFFSET) {
    return DEVIATION_COLORS.SMALL_OFFSET
  } else {
    return DEVIATION_COLORS.SIGNIFICANT_OFFSET
  }
}

export const loadArchModels = ({
  scene,
  archesData,
  teethData,
  treatmentType,
  upperArchInitRotation,
  lowerArchInitRotation,
  upperArchOffset = 10
}) => {
  const objLoader = new OBJLoader()
  const archDefaultMaterial = new THREE.MeshStandardMaterial({
    color: ARCH_DEFAULT_COLOR,
    opacity: 0.7,
    transparent: true
  })

  archesData.forEach(arch => {
    objLoader.load(getObjectUrl(arch.file), object => {
      if (!arch.isUpper && lowerArchInitRotation) {
        object.rotation.set(lowerArchInitRotation.x, lowerArchInitRotation.y, lowerArchInitRotation.z)
      }

      if (arch.isUpper && upperArchInitRotation) {
        object.rotation.set(upperArchInitRotation.x, upperArchInitRotation.y, upperArchInitRotation.z)
      }

      if (arch.isUpper) {
        // For upper arch add Y axis offset from lower arch
        object.position.set(0, upperArchOffset, 0)
      }

      object.traverse(toothMesh => {
        if (toothMesh instanceof THREE.Mesh) {
          if (!arch.isBase) {
            // Logic for current arch
            const toothData = teethData?.[`${toothMesh.name}`]
            const toothColor =
              treatmentType === TreatmentTypes.Aligners
                ? getDeviationColor(toothData?.deviation)
                : BRACES_DEVIATION_ARCH_COLOR

            const currentToothMaterial = new THREE.MeshStandardMaterial({
              color: new THREE.Color(toothColor ?? ARCH_DEFAULT_COLOR)
            })

            toothMesh.material = currentToothMaterial
          } else {
            toothMesh.material = archDefaultMaterial
          }

          // Mirrors the mesh becuase the coordinates convention is different from the
          // original STL viewer which the STL was created
          toothMesh.scale.set(-1, 1, 1)
          // Enable display of unscanned side
          toothMesh.material.side = THREE.DoubleSide
          // Enable picking by setting the object to be an intersection object
          toothMesh.userData.isSelectable = true
        }
      })

      scene.add(object)
    })
  })
}

export const handleToothClick = intersectsObjects => {
  const toothMesh = intersectsObjects[0].object
  if (toothMesh.userData.isSelectable) {
    toothMesh.parent.traverse(child => {
      if (child.isMesh) {
        // Logic for reset changes for previous click
      }
    })

    // Clone material to separate from parent
    toothMesh.material = toothMesh.material.clone()
    // Logic for clicked toothMesh
  }
}
