import React, { useCallback } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import OggHeading27 from 'components/common/text/OggHeading27'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedParagraph10 from 'components/common/text/DazzedParagraph10'
import { useSelector } from 'react-redux'

const useStyles = () =>
  makeStyles({
    articlesContainer: {
      width: '100%'
    },
    article: {
      padding: '12px 12px 20px 12px',
      borderRadius: 10,
      border: '1px solid var(--bg-color-14)',
      maxWidth: 320,
      height: 290,
      backgroundColor: 'white',
      cursor: 'pointer'
    },
    articleMargin: {
      marginRight: 10
    },
    type: {
      color: 'var(--text-color-16)'
    },
    linkText: {
      textDecoration: 'underline'
    },
    articleDescription: {
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical'
    }
  })()

const About = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const articles = useSelector(state => state.appReducer.about.articles)

  const handleLinkClick = useCallback(link => {
    trackEvent('Empty state - articles - article clicked', { link })
    const tab = window.open(link, '_blank')
    tab.focus()
  }, [])

  return (
    !!articles?.length && (
      <Grid container direction="column" alignItems="flex-start" spacing={1}>
        <Grid item xs={12}>
          <OggHeading27>{t('pages.patients.emptyState.about.title')}</OggHeading27>
        </Grid>
        <Grid item xs={12} className={classes.articlesContainer}>
          <Grid container direction="row" alignItems="center">
            {articles.map((article, i) => (
              <Grid
                item
                xs={3}
                key={`${article.id}-${i}`}
                className={`${classes.article} ${i < articles.length - 1 ? classes.articleMargin : ''}`}
                onClick={() => handleLinkClick(article.link)}
              >
                <Grid container direction="column" wrap="nowrap" style={{ height: '100%' }}>
                  <Grid item>
                    <img src={article.imageUrl} alt="" />
                  </Grid>
                  <Grid item>
                    <DazzedParagraph10 thickness="bold" className={classes.type}>
                      {article.type}
                    </DazzedParagraph10>
                  </Grid>
                  <Grid item>
                    <DazzedParagraph14 bold>{article.title}</DazzedParagraph14>
                  </Grid>
                  <Grid item style={{ height: '100%' }}>
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      style={{
                        height: '100%'
                      }}
                    >
                      <Grid item className={classes.articleDescription}>
                        <DazzedParagraph14>{article.description}</DazzedParagraph14>
                      </Grid>
                      <Grid item>
                        <DazzedParagraph14 color="var(--text-color-51)" bold className={classes.linkText}>
                          {article.linkText}
                        </DazzedParagraph14>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    )
  )
}

export default About
