import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import BaseModal from 'components/common/modals/BaseModal'

import AssignPatientForm from './AssignPatientForm'
import { useAssignPatient } from 'components/Patients/AssignPatient/useAssignPatient'
import { Assignee2 } from 'components/common/icons'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles({
  body: {
    overflowY: 'hidden'
  },
  dialogContent: {
    padding: !isMobile() ? '35px !important' : undefined,
    minWidth: !isMobile() ? 450 : undefined
  },
  title: {
    marginTop: 20,
    padding: 0
  },
  selectedMemberValue: {
    overflow: 'hidden'
  }
})

const AssignPatientDialog = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { practiceMembersSelectOptions, setAssignee, assignee, note, setNote, handleSubmit, handleClose } =
    useAssignPatient({
      withUnassignOption: true
    })
  const { isModalOpen } = useSelector(state => state.practiceReducer.assignPatient)

  return (
    <BaseModal
      open={isModalOpen}
      withCloseIcon={true}
      contentClassName={classes.dialogContent}
      onSecondaryBtnClick={handleClose}
      secondaryLabel={t('general.cancel')}
      onPrimaryBtnClick={handleSubmit}
      primaryLabel={t('general.confirm')}
      handleClose={handleClose}
      className={classes.body}
      smothBorders
      primaryButtonId="confirm-set-assignee"
      secondaryButtonId="cancel-set-assignee"
      maxWidth={'xl'}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Assignee2 color="var(--text-color-27)" style={{ marginTop: 4 }} />
            </Grid>
            <Grid item>
              <DazzedParagraph16 strong>{t('dialogs.assignPatient.title')}</DazzedParagraph16>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <AssignPatientForm
            assignee={assignee}
            note={note}
            practiceMembersSelectOptions={practiceMembersSelectOptions}
            assignPatientCentered={false}
            onChangeAssignee={({ value }) => setAssignee(value)}
            onChangeNote={setNote}
          />
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default AssignPatientDialog
