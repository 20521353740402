import { useEffect } from 'react'
import Viewer from 'react-viewer'

const ImageViewerWrapper = props => {
  useEffect(() => {
    if (props.visible) {
      const handleDocumentClick = (element: PointerEvent) => {
        if (element.target.parentElement.classList.contains('react-viewer')) {
          props.onClose()
        }
      }

      document.addEventListener('click', handleDocumentClick)

      return () => {
        document.removeEventListener('click', handleDocumentClick)
      }
    }
  }, [props])

  return <Viewer {...props} />
}

export default ImageViewerWrapper
