import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ alignLeft }) => ({
  bubbleTailContainer: {
    position: 'absolute',
    bottom: ({ alignLeft, isBroadcast, backgroundColor }) => (isBroadcast || !alignLeft || backgroundColor ? 0 : -1),
    right: ({ alignLeft }) => (alignLeft ? undefined : -12),
    left: ({ alignLeft }) => (alignLeft ? -12 : undefined)
  },
  bubbleTail: {
    width: 12,
    height: 12,
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: ({ alignLeft, isBroadcast, backgroundColor }) =>
      backgroundColor || (isBroadcast ? 'var(--bg-color-44)' : alignLeft ? '#FFFFFF' : '#2F36541A'),
    borderBottom: ({ alignLeft, isBroadcast, backgroundColor }) =>
      backgroundColor || (isBroadcast || !alignLeft ? 'none' : '1px solid rgba(0, 19, 53, 0.3)')
  },
  bubbleTailCircle: {
    width: '200%',
    height: '200%',
    position: 'absolute',
    borderRadius: '50%',
    bottom: 0,
    backgroundColor: 'white',
    right: ({ alignLeft }) => (alignLeft ? undefined : -12),
    left: ({ alignLeft }) => (alignLeft ? -12 : undefined),
    boxShadow: ({ alignLeft }) => (alignLeft ? '-1px -1px 0 0 black' : '1px -1px 0 0 black'),
    border: ({ alignLeft, isBroadcast, backgroundColor }) =>
      isBroadcast || !alignLeft || backgroundColor ? 'none' : '1px solid rgba(0, 19, 53, 0.3)'
  }
}))

const MessageTail = ({
  alignLeft,
  containerClassName = '',
  tailClassName = '',
  circleClassName = '',
  isBroadcast,
  backgroundColor
}) => {
  const classes = useStyles({ alignLeft, isBroadcast, backgroundColor })

  return (
    <div className={[classes.bubbleTailContainer, containerClassName].join(' ')}>
      <div className={[classes.bubbleTail, tailClassName].join(' ')}>
        <div className={[classes.bubbleTailCircle, circleClassName].join(' ')} />
      </div>
    </div>
  )
}

export default MessageTail
