import Actions from 'actions'
import DownloadImages from 'components/common/icons/timelineV2/scan-feed/DownloadImages'
import SendSelectedImages from 'components/common/icons/timelineV2/scan-feed/SendSelectedImages'
import { IMAGE_MODES } from 'consts/scanSummaryConsts'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'

export default ({
  imagesMode,
  analyticsPayload,
  displayedPreventiveLayers,
  selectedPoses,
  editedImages = [],
  parsedScanSummaryData = {},
  isAnyPreventiveLayerVisible,
  downloadScanSummaryImages = async () => {}
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleDownloadSelected = useCallback(async () => {
    trackEvent('Scan summary - download images clicked', {
      ...analyticsPayload,
      preventiveLayers: displayedPreventiveLayers,
      poses: selectedPoses
    })
    await downloadScanSummaryImages()
  }, [analyticsPayload, selectedPoses, displayedPreventiveLayers, downloadScanSummaryImages])

  const handleSendSelectedImages = useCallback(() => {
    dispatch(
      Actions.sendSelectedScanSummaryImages({
        selectedImages: selectedPoses,
        editedImages,
        originalImages: parsedScanSummaryData[imagesMode]
      })
    )

    trackEvent('Summary_SendClicked', {
      amount: selectedPoses.length,
      positions: selectedPoses,
      annotatedPositions: Object.keys(editedImages).filter(key => editedImages[key]),
      imagesMode
    })
  }, [imagesMode, selectedPoses, editedImages, dispatch, parsedScanSummaryData])

  const scanSummaryImageActions = useMemo(
    () => [
      {
        icon: <SendSelectedImages />,
        label: t('pages.patients.selectedPatient.timelineV2.scanSummaryActions.sendSelected'),
        onClick: handleSendSelectedImages,
        isDisabled: !selectedPoses.length || imagesMode === IMAGE_MODES.preventive || isAnyPreventiveLayerVisible
      },
      {
        icon: <DownloadImages />,
        label: t('pages.patients.selectedPatient.timelineV2.scanSummaryActions.download'),
        onClick: handleDownloadSelected,
        isDisabled: !selectedPoses.length || isAnyPreventiveLayerVisible
      }
    ],
    [selectedPoses.length, t, handleSendSelectedImages, handleDownloadSelected, imagesMode, isAnyPreventiveLayerVisible]
  )

  return scanSummaryImageActions
}
