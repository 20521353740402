import * as React from 'react'

const Trash = ({ color = 'white', ...props }) => {
  return (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.535714 3.21429H1.60714V14.1857C1.60856 14.6854 1.80768 15.1642 2.16102 15.5176C2.51435 15.8709 2.99317 16.07 3.49286 16.0714H11.5286C12.0245 16.0644 12.4979 15.8628 12.8466 15.5101C13.1953 15.1573 13.3915 14.6817 13.3929 14.1857V3.21429H14.4643C14.6064 3.21429 14.7426 3.15784 14.8431 3.05738C14.9436 2.95691 15 2.82065 15 2.67857C15 2.53649 14.9436 2.40023 14.8431 2.29976C14.7426 2.1993 14.6064 2.14286 14.4643 2.14286H10.7143V1.07143C10.7144 0.791849 10.6052 0.523309 10.41 0.323129C10.2148 0.12295 9.94913 0.00698949 9.66964 0H5.35714C5.07298 0 4.80046 0.112882 4.59953 0.313814C4.3986 0.514746 4.28571 0.787268 4.28571 1.07143V2.14286H0.535714C0.393634 2.14286 0.257373 2.1993 0.156907 2.29976C0.0564411 2.40023 0 2.53649 0 2.67857C0 2.82065 0.0564411 2.95691 0.156907 3.05738C0.257373 3.15784 0.393634 3.21429 0.535714 3.21429ZM5.35714 1.07143H9.64286V2.14286H5.35714V1.07143ZM4.28571 3.21429H12.3214V14.1857C12.3214 14.4017 12.2356 14.6088 12.0829 14.7615C11.9302 14.9142 11.7231 15 11.5071 15H3.47143C3.2592 14.9944 3.05755 14.9062 2.90944 14.7541C2.76134 14.6019 2.6785 14.398 2.67857 14.1857V3.21429H4.28571Z"
        fill={color}
      />
      <path
        d="M5.72126 12.8569C5.86334 12.8569 5.9996 12.8005 6.10007 12.7C6.20053 12.5996 6.25698 12.4633 6.25698 12.3212V5.89265C6.25698 5.75057 6.20053 5.61431 6.10007 5.51384C5.9996 5.41337 5.86334 5.35693 5.72126 5.35693C5.57918 5.35693 5.44292 5.41337 5.34245 5.51384C5.24199 5.61431 5.18555 5.75057 5.18555 5.89265V12.3212C5.18555 12.4633 5.24199 12.5996 5.34245 12.7C5.44292 12.8005 5.57918 12.8569 5.72126 12.8569Z"
        fill={color}
      />
      <path
        d="M9.27863 12.8569C9.42071 12.8569 9.55698 12.8005 9.65744 12.7C9.75791 12.5996 9.81435 12.4633 9.81435 12.3212V5.89265C9.81435 5.75057 9.75791 5.61431 9.65744 5.51384C9.55698 5.41337 9.42071 5.35693 9.27863 5.35693C9.13655 5.35693 9.00029 5.41337 8.89983 5.51384C8.79936 5.61431 8.74292 5.75057 8.74292 5.89265V12.3212C8.74292 12.4633 8.79936 12.5996 8.89983 12.7C9.00029 12.8005 9.13655 12.8569 9.27863 12.8569Z"
        fill={color}
      />
    </svg>
  )
}

export default Trash
