import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import Actions from '../../../actions'
import { useTranslation } from 'react-i18next'
import { divideByThousands } from '../../../utils/stringUtils'
import BaseModal from '../../common/modals/BaseModal'
import ProfilePicture from '../../common/ProfilePicture'
import OggHeading40 from '../../common/text/OggHeading40'
import DazzedHeading16 from '../../common/text/DazzedHeading16'
import DazzedHeading36 from '../../common/text/DazzedHeading36'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import DazzedParagraph12 from '../../common/text/DazzedParagraph12'

const useStyles = makeStyles(() => ({
  dialog: {
    width: 800,
    minHeight: 600
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30
  },
  profileInfoContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  profileName: {
    fontSize: 26,
    lineHeight: '30px'
  },
  profileSpeciality: {
    fontWeight: 'normal',
    fontSize: 14
  },
  planInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30
  },
  planCostContainer: {
    paddingLeft: 40,
    paddingRight: 40,
    borderRight: '1px solid var(--border-color-16)'
  },
  planCost: {
    marginLeft: 15,
    fontFamily: 'Arial',
    color: 'var(--text-color-3)'
  },
  planCostText: {
    fontWeight: 'normal',
    fontSize: 12,
    color: 'var(--text-color-11)'
  },
  planDurationContainer: {
    paddingLeft: 40,
    paddingRight: 40
  },
  planDuration: {
    fontSize: 22,
    textTransform: 'lowercase'
  },
  planAppliance: {
    fontWeight: 'normal',
    fontSize: 12
  },
  notesContainer: {},
  notesTitle: {
    marginBottom: 10,
    padding: '0 5px'
  },
  notesText: {
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '26px',
    padding: '0 5px'
  },
  disclaimer: {
    backgroundColor: 'var(--bg-color-14)',
    padding: '3px 5px',
    marginTop: 10
  },
  disclaimerText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    '& span': {
      textDecoration: 'underline'
    }
  }
}))

const PreliminaryPlanDialog = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { open } = useSelector(state => state.treatmentReducer.preliminaryPlanDialog)
  const doctor = useSelector(state => state.patientsReducer.patientCard?.patient?.doctor)
  const prelimPlan = useSelector(state => state.patientsReducer.patientCard.prelimPlan.data)

  const handleClose = useCallback(() => {
    dispatch(Actions.closePreliminaryPlanDialog())
  }, [dispatch])

  const period =
    prelimPlan?.periodType === 'week'
      ? prelimPlan?.length === 1
        ? t('dialogs.patientInfo.treatmentProposal.periodTypeOptions.week')
        : t('dialogs.patientInfo.treatmentProposal.periodTypeOptions.weeks')
      : prelimPlan?.length === 1
      ? t('dialogs.patientInfo.treatmentProposal.periodTypeOptions.month')
      : t('dialogs.patientInfo.treatmentProposal.periodTypeOptions.months')
  const duration = `${prelimPlan?.length} ${period}`
  const vendor = t(`dialogs.patientInfo.treatmentProposal.vendorOptions.${prelimPlan?.vendor}`)
  const additionalVendor =
    prelimPlan?.additionalVendor &&
    t(`dialogs.patientInfo.treatmentProposal.vendorOptions.${prelimPlan?.additionalVendor}`)

  return open && prelimPlan ? (
    <BaseModal
      title={t('dialogs.patientInfo.prelimPlan.message.header')}
      open={open}
      className={classes.dialog}
      secondaryLabel={t('general.close')}
      onSecondaryBtnClick={handleClose}
      handleClose={handleClose}
    >
      <div className={classes.container}>
        <div className={classes.profileContainer}>
          <ProfilePicture
            name={doctor.name}
            photo={doctor.photo}
            alt={t('dialogs.doctorCardEditor.profilePictureLabel')}
          />

          <div className={classes.profileInfoContainer}>
            <OggHeading40 className={classes.profileName}>{`Dr. ${doctor.name}`}</OggHeading40>
            <DazzedHeading16 className={classes.profileSpeciality}>
              {t('dialogs.patientInfo.prelimPlan.dialog.orthodontist')}
            </DazzedHeading16>
          </div>
        </div>

        <div className={classes.planInfoContainer}>
          <div className={classes.planCostContainer}>
            <DazzedHeading36 className={classes.planCost}>{`$${divideByThousands(prelimPlan?.cost)}`}</DazzedHeading36>
            <DazzedHeading16 className={classes.planCostText}>
              {t('dialogs.patientInfo.prelimPlan.dialog.totalCost')}
            </DazzedHeading16>
          </div>

          <div className={classes.planDurationContainer}>
            <DazzedHeading36 className={classes.planDuration}>{duration}</DazzedHeading36>
            <DazzedHeading16 className={classes.planAppliance}>{`(${vendor}${
              additionalVendor && `, ${additionalVendor}`
            })`}</DazzedHeading16>
          </div>
        </div>
      </div>

      <div className={classes.notesContainer}>
        <DazzedHeading16 className={classes.notesTitle}>
          {t('dialogs.patientInfo.prelimPlan.dialog.notesTitle')}
        </DazzedHeading16>
        {prelimPlan?.notes.split('\n').map((line, index) => (
          <DazzedParagraph16 className={classes.notesText} key={index} strong>
            {line}
          </DazzedParagraph16>
        ))}
        <div className={classes.disclaimer}>
          <DazzedParagraph12 className={classes.disclaimerText}>
            <span>{t('dialogs.patientInfo.prelimPlan.dialog.disclaimer')}</span>:&nbsp;
            {t('dialogs.patientInfo.prelimPlan.dialog.notesDescription1')}
          </DazzedParagraph12>
        </div>
      </div>
    </BaseModal>
  ) : null
}

export default PreliminaryPlanDialog
