import * as React from 'react'

export default () => {
  return (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="10.6842" height="9.1579" rx="1.52632" fill="#4151E6" />
      <path d="M11.4474 3.05266L14.5 0.763184V8.39476L11.4474 6.10529V3.05266Z" fill="#4151E6" />
    </svg>
  )
}
