import React, { useCallback } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, makeStyles } from '@material-ui/core'
import TemplateListItem from '../Templates/TemplateListItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { TEMPLATES_TYPES } from 'consts/templatesConsts'
import DazzedHeading13 from '../../../common/text/DazzedHeading13'
import { isMobile } from 'utils/mobileUtils'

const useStyles = ({ isMobile }) =>
  makeStyles({
    accordionContainer: {
      marginLeft: 20
    },
    dropdownIcon: {
      color: '#001335',
      height: 17,
      width: 17
    },
    TemplatesTypeListHeader: {
      color: '#A3ACB1'
    },
    accordionDetails: {
      flexDirection: 'column',
      marginTop: -18,
      marginBottom: -18
    },
    typeDivider: {
      height: 0,
      marginLeft: 13,
      width: isMobile ? '92%' : '87%',
      borderBottom: '0.01px solid',
      borderBottomColor: '#EBEBEB'
    }
  })({ isMobile })

const SavedRepliesTypeList = ({
  type,
  templates = [],
  selected,
  setSelected,
  withActions,
  onDeleteTemplate,
  label = ''
}) => {
  const classes = useStyles({ isMobile: isMobile() })
  const onDeleteCustomTemplate = useCallback(
    template => {
      if (type === TEMPLATES_TYPES.CUSTOM) {
        onDeleteTemplate(template)
      }
    },
    [onDeleteTemplate, type]
  )
  return (
    templates.length > 0 && (
      <Grid item className={isMobile() ? classes.accordionContainer : ''}>
        <Accordion defaultExpanded elevation={0} square>
          <AccordionSummary expandIcon={<ArrowDropDownIcon className={classes.dropdownIcon} />}>
            <DazzedHeading13 className={classes.TemplatesTypeListHeader}>{label}</DazzedHeading13>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {templates ? (
              templates.map(template => (
                <TemplateListItem
                  type={type}
                  key={template.id}
                  title={template.title}
                  selected={template.id === selected?.id}
                  onClick={() => setSelected(template)}
                  withActions={true}
                  onDelete={() => onDeleteCustomTemplate(template)}
                />
              ))
            ) : (
              <CircularProgress color="inherit" style={{ alignSelf: 'center' }} size={12} />
            )}
          </AccordionDetails>
        </Accordion>
        <div className={classes.typeDivider} />
      </Grid>
    )
  )
}

export default SavedRepliesTypeList
