import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import Actions from 'actions'
import { InfoIcon } from 'components/common/icons'
import { DROPZONE_ACCEPTABLE_FILE_TYPES, FILE_UPLOAD_MAX_SIZE } from 'consts/appConsts'
import usePlanLimits from 'hooks/usePlanLimits'
import useRolePermissions from 'hooks/useRolePermissions'
import useSortedMessages from 'hooks/useSortedMessages'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { trackEvent } from 'utils/analyticsUtils'
import { isUserOfAnyAdminRole } from 'utils/authUtils'
import { isMobile } from 'utils/mobileUtils'
import { v4 as uuidv4 } from 'uuid'
import UploadDropzone from '../../common/UploadDropzone/UploadDropzone'
import NewMessage from './NewMessage'
import PatientChatContainer from './PatientChatContainer'
import PatientNotInvitedState from './PatientNotInvitedState'
import usePatientTags from 'hooks/usePatientTags'
import PatientBriefQuickActionsButton from '../PatientBrief/QuickActionsFloatingPopup/PatientBriefQuickActionsButton'
import PatientBriefQuickActionsButtonMobile from '../PatientBrief/QuickActionsFloatingPopupMobile/PatientBriefQuickActionsButton.mobile'
import useFeatureFlags from 'hooks/useFeatureFlags'

const PatientsChat = () => {
  const useStyles = ({ isMobile, sendChatMessages }) =>
    makeStyles({
      addedOverlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '50%',
        height: '100%',
        zIndex: 999
      },
      disabledChatContainer: {
        opacity: 0.5
      },
      sendInvitation: {
        marginTop: 10,
        fontSize: 14,
        fontWeight: 500
      },
      chatContainer: {
        flex: 1,
        position: 'relative',
        height: isMobile && sendChatMessages ? 'calc(100% - 35px)' : '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        background: 'white',
        borderLeft: !isMobile ? '1px solid var(--border-color-7)' : ''
      },
      invitedPatientWarning: {
        background: '#FFBD39',
        padding: isMobile ? 15 : 25,
        zIndex: 10,
        width: '100%',
        display: 'flex',
        '& svg': {
          width: 25,
          height: 25
        }
      },
      iconContainer: {
        width: 25,
        marginRight: 10
      },
      overlayLoader: {
        height: '100%'
      },
      messagesLoader: {
        padding: '12px'
      }
    })({ isMobile, sendChatMessages })

  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()
  const classes = useStyles({ isMobile: isMobile(), sendChatMessages: permissions.sendChatMessages })
  const { t } = useTranslation()
  const { patientId } = useParams()
  const { isChurnedPlan, isHI: isHiPractice } = usePlanLimits()
  const { hiTools: hiToolsFF } = useFeatureFlags()

  const { patient, isLoadingPatient, requestedPatientId } = useSelector(state => state.patientsReducer)
  const { needsAttentionTagDetails } = usePatientTags({ patient })

  const treatment = useSelector(state => state.treatmentReducer.treatment)
  const messages = useSortedMessages()
  const doctor = useSelector(state => state.profileReducer.doctor)
  const { accountOwner, details: practiceDetails } = useSelector(state => state.practiceReducer)
  const isLoadingMessages = useSelector(state => state.chatReducer.messages.isLoading)
  const { isModeOn: isBroadcastMode, localMessages: broadcastMessages } = useSelector(
    state => state.chatReducer.broadcast
  )

  const [filesToSend, setFilesToSend] = useState([])
  const [showDropzone, setShowDropzone] = useState(false)

  const isAdmin = useMemo(() => isUserOfAnyAdminRole(), [])
  const roomId = useMemo(() => patient.user?.rooms.items?.length && patient.user?.rooms.items[0].roomId, [patient])

  const isActive = useMemo(
    () =>
      !isChurnedPlan &&
      (isBroadcastMode || (patient.user?.isActive && !treatment?.currentStatus?.statusKey?.includes('transferred'))),
    [patient, treatment, isBroadcastMode, isChurnedPlan]
  )

  const isRecordsOnly = useMemo(() => patient?.auth_status === 'added', [patient?.auth_status])
  const messageMembers = useMemo(
    () =>
      [
        patient?.username,
        doctor?.username !== patient?.doctor?.username ? patient?.doctor?.username : doctor?.username
      ].filter(member => !!member),
    [doctor, patient]
  )

  const showPatientInvitedBanner = useMemo(
    () => patient.auth_status === 'invited' && !isBroadcastMode && !showDropzone,
    [isBroadcastMode, patient.auth_status, showDropzone]
  )

  useEffect(() => {
    if (patientId !== requestedPatientId && patient.id !== patientId && !isBroadcastMode && !isLoadingPatient) {
      dispatch(Actions.requestPatient({ patientId }))
      setShowDropzone(false)
    }
  }, [dispatch, patientId, patient.id, isBroadcastMode, isLoadingPatient, requestedPatientId])

  useEffect(() => {
    if (roomId && !isBroadcastMode) {
      roomId && dispatch(Actions.requestPatientMessages(roomId))
    }
  }, [dispatch, isBroadcastMode, roomId])

  useEffect(() => {
    if (isAdmin && patient?.patientDoctorId) {
      dispatch(Actions.requestPracticeMembers({ doctorId: patient?.patientDoctorId }))
    }
  }, [dispatch, isAdmin, patient?.patientDoctorId])

  const handleMessageSend = useCallback(
    ({ content, templateId }, options) => {
      if (isBroadcastMode) {
        dispatch(
          Actions.createBroadcastMessage({
            type: 'text',
            content,
            templateId
          })
        )
      } else {
        dispatch(
          Actions.sendChatMessage({
            members: messageMembers,
            roomId,
            type: 'text',
            content,
            patientUsername: patient.username,
            readBy: doctor.username,
            templateId,
            scheduleTime: options.scheduleTime,
            patientDoctorUsername: patient?.doctor?.username
          })
        )
      }
    },
    [dispatch, doctor, messageMembers, roomId, patient, isBroadcastMode]
  )

  const handleFileSend = useCallback(
    (files, options) => {
      trackEvent('Chat - Files sent', {
        fileType: files.map(file => file.messageType)
      })
      files.forEach(({ data, file, extension, fileTitle, id, messageType, type }) => {
        if (isBroadcastMode) {
          dispatch(
            Actions.createBroadcastFileMessage({
              id,
              type: messageType,
              content: file,
              cache: data,
              contentType: file.type,
              extension,
              metadata: JSON.stringify({
                fileTitle
              })
            })
          )
        } else {
          dispatch(
            Actions.sendChatMessage({
              id,
              members: messageMembers,
              roomId,
              type: messageType,
              content: file,
              cache: data,
              extension,
              patientUsername: patient.username,
              readBy: doctor.username,
              scheduleTime: options.scheduleTime,
              patientDoctorUsername: patient?.doctor?.username,
              metadata: JSON.stringify({
                fileTitle
              })
            })
          )
        }
      })
    },
    [dispatch, doctor, messageMembers, roomId, patient, isBroadcastMode]
  )

  const handlePromotionSend = useCallback(
    ({ imageUrl, id: promotionId, baseUrl, externalId }, options, type = 'promotion') => {
      if (isBroadcastMode) {
        dispatch(
          Actions.createBroadcastMessage({
            type: 'promotion',
            content: imageUrl,
            href: baseUrl,
            promotionId,
            entityRelatedId: promotionId
          })
        )
      } else {
        const messageId = uuidv4()
        const urlIncludingParams = `${baseUrl}?doctorId=${accountOwner.rcToken}&patientId=${patient.publicId}&doctorName=${doctor.name}&externalId=${externalId}&messageId=${messageId}`

        dispatch(
          Actions.sendChatMessage({
            id: messageId,
            type: 'promotion',
            members: messageMembers,
            roomId,
            patientUsername: patient.username,
            baseUrl: urlIncludingParams,
            promotionId,
            readBy: doctor.username,
            scheduleTime: options.scheduleTime,
            patientDoctorUsername: patient?.doctor?.username,
            content: imageUrl,
            entityRelatedId: promotionId
          })
        )
      }
    },
    [dispatch, doctor, messageMembers, patient, roomId, accountOwner, isBroadcastMode]
  )

  const loadMoreMessages = useCallback(() => {
    messages.nextToken &&
      !isLoadingMessages &&
      dispatch(Actions.requestPatientMessages(roomId, messages.nextToken, 'push'))
  }, [dispatch, messages, roomId, isLoadingMessages])

  const handleDropFile = useCallback(files => {
    if (files.length) {
      setShowDropzone(false)
      setFilesToSend(files)
    }
  }, [])

  return (
    <>
      {!isMobile() && isRecordsOnly && <PatientNotInvitedState />}
      <div className={`${classes.chatContainer} ${!isMobile() && isRecordsOnly && classes.disabledChatContainer}`}>
        {isHiPractice &&
          (needsAttentionTagDetails || hiToolsFF) &&
          (!isMobile() ? (
            <PatientBriefQuickActionsButton
              needsAttentionTagDetails={needsAttentionTagDetails}
              patientId={patient?.id || patientId}
              practiceName={practiceDetails?.practiceName}
            />
          ) : (
            needsAttentionTagDetails && (
              <PatientBriefQuickActionsButtonMobile
                needsAttentionTagDetails={needsAttentionTagDetails}
                patientId={patient?.id || patientId}
                practiceName={practiceDetails?.practiceName}
              />
            )
          ))}
        {showPatientInvitedBanner && (
          <div className={classes.invitedPatientWarning}>
            <div className={classes.iconContainer}>
              <InfoIcon />
            </div>
            {t('pages.patients.selectedPatient.chat.invitedMessage')}
          </div>
        )}
        {isLoadingMessages && (
          <Grid container justifyContent="center" alignItems="center" className={classes.messagesLoader}>
            <CircularProgress size={12} />
          </Grid>
        )}
        {!isRecordsOnly ? (
          <PatientChatContainer
            messages={isBroadcastMode ? broadcastMessages : messages.items}
            loadMoreMessages={loadMoreMessages}
          />
        ) : (
          isMobile() && <PatientNotInvitedState />
        )}
        {showDropzone && (
          <UploadDropzone
            onUploadFile={handleDropFile}
            setShowDropzone={setShowDropzone}
            acceptedFormats={DROPZONE_ACCEPTABLE_FILE_TYPES}
            maxFileSize={FILE_UPLOAD_MAX_SIZE}
            showSavedFiles
            isBroadcastMode={isBroadcastMode}
          />
        )}
        <NewMessage
          containerClassName={isMobile() && isRecordsOnly ? classes.disabledChatContainer : ''}
          isActive={isActive}
          isAdmin={!permissions.sendChatMessages}
          handleMessageSend={handleMessageSend}
          handleFileSend={handleFileSend}
          handlePromotionSend={handlePromotionSend}
          disablePromotion={patient?.user?.version === '1.27.5'}
          setShowDropzone={setShowDropzone}
          filesToSend={filesToSend}
          setFilesToSend={setFilesToSend}
        />
      </div>
    </>
  )
}

export default PatientsChat
