import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import ScanSummaryWithVideo from 'components/Referral/ScanSummaryWithVideo'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { Trans, useTranslation } from 'react-i18next'
import useScans from 'hooks/useScans'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import { trackEvent } from 'utils/analyticsUtils'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  scanView: {
    position: 'relative'
  },
  alignersToggle: {
    position: 'absolute',
    top: 15,
    left: 20,
    zIndex: 10
  },
  alignersToggleButton: {
    fontWeight: 500
  }
}))

const ScanDetails = ({ grinScan }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { scanIdToNumberDict } = useScans()

  const patientGrinScans = useSelector(state => state.patientsReducer.patient?.grinScans?.items || [])

  const [withAligners, setWithAligners] = useState(grinScan.withAligner)

  const pairedScan = useMemo(
    () => patientGrinScans.find(scan => scan.scanPairId === grinScan.scanPairId && scan.id !== grinScan.id),
    [grinScan, patientGrinScans]
  )

  const activeScan = useMemo(
    () => (grinScan.withAligner === withAligners ? grinScan : pairedScan),
    [grinScan, pairedScan, withAligners]
  )

  const scanNumber = useMemo(() => scanIdToNumberDict[grinScan.id] + 1, [grinScan.id, scanIdToNumberDict])
  const scanCreatedAt = useMemo(() => moment(grinScan.createdAt).format('MMM Do, YYYY'), [grinScan.createdAt])

  const isDoubleScan = useMemo(() => grinScan.scanPairId, [grinScan])

  const handleAlignersToggleClicked = useCallback(() => {
    trackEvent('Treatment tracker modal - With aligners toggled', {
      withAligners: !withAligners
    })
    setWithAligners(!withAligners)
  }, [withAligners])

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <DazzedParagraph16>
          {
            <Trans
              values={{
                number: scanNumber,
                createdAt: scanCreatedAt
              }}
              i18nKey={'dialogs.treatmentTracker.scanDetails.title'}
              components={{ bold: <b>{''}</b> }}
            />
          }
        </DazzedParagraph16>
      </Grid>
      <Grid item className={classes.scanView}>
        {isDoubleScan && (
          <div className={classes.alignersToggle}>
            <SecondaryButton
              width={170}
              label={
                withAligners
                  ? t('dialogs.treatmentTracker.scanDetails.withAligners')
                  : t('dialogs.treatmentTracker.scanDetails.withoutAligners')
              }
              onClick={handleAlignersToggleClicked}
              className={classes.alignersToggleButton}
            />
          </div>
        )}
        <ScanSummaryWithVideo scanVideo={activeScan?.video} scanSummaryData={activeScan.scanSummaryData} />
      </Grid>
    </Grid>
  )
}

export default ScanDetails
