import React, { useCallback, useEffect, useState } from 'react'
import SwooshSmallIcon from '../common/icons/Swoosh'
import QRCode from 'qrcode.react'
import FormInput from '../common/FormInput'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import OggHeading40 from 'components/common/text/OggHeading40'
import useCssClasses from 'hooks/useCssClasses'
import { Auth } from 'aws-amplify'
import useMFA from './useMFA'
import { useTranslation } from 'react-i18next'
import { logError } from 'utils/logUtils'
import PrimaryButton from 'components/common/buttons/PrimaryButton'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    color: 'var(--text-color-1)',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  signInSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  title: {
    fontSize: isMobile ? 32 : 40
  },
  form: {
    maxWidth: '512px',
    minWidth: isMobile ? 'auto' : 512,
    padding: 18
  },
  button: {
    background: 'var(--border-color-1)',
    color: 'var(--text-color-1)',
    width: '100%',
    borderRadius: '34px',
    '&:hover': {
      background: 'var(--border-color-1)'
    }
  },
  noFill: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      transition: 'color 0.2s ease',
      color: 'gray',
      boxShadow: 'none'
    }
  },
  inputContainer: {
    width: '100%'
  }
}))

const MFA = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { getQrCodeLink, verifyCode } = useMFA()

  const { user } = useSelector(state => state.authReducer.mfa)
  const { emailAddress } = useSelector(state => state.authReducer)

  const [code, setCode] = useState('')
  const [qrCode, setQrCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useCssClasses(true)

  const handleCodeEntered = useCallback(async () => {
    setIsLoading(true)

    try {
      await verifyCode(code)
    } catch (error) {
      logError('An error occured while trying to login with MFA', { error })
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [code, verifyCode])

  const handleKeyPress = useCallback(
    e => {
      if (e.code === 'Enter') {
        handleCodeEntered()
      }
    },
    [handleCodeEntered]
  )

  const initMfa = useCallback(async () => {
    if (!user?.challengeName && !qrCode) {
      const totpCode = await Auth.setupTOTP(user)
      setQrCode(getQrCodeLink({ username: user.username, email: emailAddress, totpCode }))
    }
  }, [emailAddress, getQrCodeLink, qrCode, user])

  useEffect(() => {
    if (user) {
      initMfa()
    }
  }, [initMfa, user])

  return (
    <Grid className={classes.root} container alignItems="center" justifyContent="center" onKeyPress={handleKeyPress}>
      <SwooshSmallIcon className={classes.signInSwoosh} />

      <Grid container direction="column" justifyContent="center" spacing={isMobile ? 5 : 3} className={classes.form}>
        {qrCode && (
          <Grid item>
            <QRCode value={qrCode} size={300} id={`otp-qr-code`} />
          </Grid>
        )}
        <Grid item>
          <OggHeading40 className={classes.title} dark textAlign={isMobile ? 'center' : 'start'}>
            {t('pages.mfa.enterCode')}
          </OggHeading40>
        </Grid>
        <Grid item />
        <Grid item>
          <FormInput
            containerClassName={classes.inputContainer}
            errorMessage={errorMessage}
            allowAutoComplete
            title={t('pages.mfa.code')}
            value={code}
            validationRules={{ required: true }}
            setValue={setCode}
          />
        </Grid>
        <Grid item>
          <PrimaryButton
            width={'100%'}
            label={t('general.enter')}
            className={classes.button}
            onClick={handleCodeEntered}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MFA
