import React from 'react'
import { makeStyles } from '@material-ui/styles'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { scrollBar } from 'styles/theme'

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 82,
    padding: '12px 0'
  },
  content: {
    flex: 1,
    maxHeight: 265,
    overflowY: 'auto',
    padding: '18px 20px',
    ...scrollBar
  },
  line: {
    wordBreak: 'break-word'
  },
  selectedReplyPlaceholder: {
    color: 'var(--text-color-11)',
    marginLeft: 5
  }
}))

const SelectedSavedReply = ({ selectedReply, onButtonClick }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <div className={classes.content}>
        {selectedReply?.text.split('\n').map((line, index) => (
          <DazzedParagraph14 key={index} className={classes.line}>
            {line || <br />}
          </DazzedParagraph14>
        ))}
        {!selectedReply && (
          <DazzedParagraph14 className={classes.selectedReplyPlaceholder}>
            {t('pages.patients.selectedPatient.chat.templates.selectedReplyPlaceholder')}
          </DazzedParagraph14>
        )}
      </div>
      {selectedReply && (
        <div className={classes.buttonContainer}>
          <PrimaryButton
            disabled={!selectedReply}
            label={t('general.select')}
            onClick={onButtonClick}
            small
            height="30px"
          />
        </div>
      )}
    </>
  )
}

export default SelectedSavedReply
