import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CircularProgress, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PreviewIcon from 'components/common/icons/Preview'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { getTransformedDescription } from 'utils/billingUtils'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { trackEvent } from 'utils/analyticsUtils'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    margin: '16px 8px'
  },
  data: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'var(--bg-color-15)',
    padding: 12,
    flex: 1
  },
  description: {
    flex: 2.5
  },
  user: {
    marginTop: 5,
    fontSize: 11,
    lineHeight: '20px',
    color: 'var(--text-color-11)'
  },
  paymentMethod: {
    flex: 2,
    paddingLeft: 10
  },
  creditCadBrand: {
    textTransform: 'capitalize'
  },
  date: {
    flex: 1
  },
  price: {
    flex: 1,
    justifyContent: 'flex-start',
    display: 'flex'
  },
  downloadBtnContainer: {
    flex: 0.5,
    justifyContent: 'flex-end'
  }
})

const TransactionItem = ({
  id,
  type,
  description,
  date,
  price,
  creditCardBrand,
  creditCardLast4,
  scopesQuantity,
  creatorUserId,
  isLoadingPdf,
  onDownloadPdfClicked = () => {}
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const practiceMembers = useSelector(state => state.profileReducer.practiceMembers.data)
  const userPlan = useSelector(state => state.practiceReducer.billing.grinPlan)

  const userPlanKey = userPlan?.key ?? ''

  const transformedDescription = useMemo(
    () => getTransformedDescription({ type, description, scopesQuantity }),
    [type, description, scopesQuantity]
  )

  const creatorUser = useMemo(() => {
    if (!creatorUserId) {
      return practiceMembers && practiceMembers[0]
    }
    return practiceMembers?.find(member => member?.grinUserId === creatorUserId)
  }, [practiceMembers, creatorUserId])

  return (
    <div className={classes.root}>
      <div className={classes.data}>
        <DazzedParagraph14 className={classes.date}>{date}</DazzedParagraph14>
        {creatorUser && (
          <div className={classes.description}>
            <DazzedParagraph14 strong>{transformedDescription}</DazzedParagraph14>
            <DazzedParagraph12 className={classes.user}>
              {t('pages.accountSettings.billing.madeBy', { name: creatorUser.name })}
              {creatorUser.roleDescription && ` - ${creatorUser.roleDescription}`}
            </DazzedParagraph12>
          </div>
        )}
        {!!creditCardLast4 && (
          <DazzedParagraph14 strong className={classes.paymentMethod}>
            <span className={classes.creditCadBrand}>{creditCardBrand}</span> xxxx-{creditCardLast4}
          </DazzedParagraph14>
        )}
        <DazzedParagraph14 strong className={classes.price}>
          {type === 'refund' && '-'}${price}
        </DazzedParagraph14>

        <div className={classes.downloadBtnContainer}>
          <Tooltip value={t('pages.accountSettings.billing.downloadInvoicePdf')}>
            <IconButton
              disabled={isLoadingPdf}
              onClick={() => {
                trackEvent('Payment history - download invoice', { transactionId: id, userPlanKey })
                onDownloadPdfClicked()
              }}
            >
              {isLoadingPdf ? <CircularProgress size={14} /> : <PreviewIcon />}
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default TransactionItem
