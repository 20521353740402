const REFERRALS_FETCH_REFERRAL_DATA = 'REFERRALS_FETCH_REFERRAL_DATA'
const REFERRALS_FETCH_REFERRAL_DATA_RECEIVED = 'REFERRALS_FETCH_REFERRAL_DATA_RECEIVED'
const REFERRALS_FETCH_REFERRAL_DATA_FAILED = 'REFERRALS_FETCH_REFERRAL_DATA_FAILED'
const REFERRALS_RESEND_CODE = 'REFERRALS_RESEND_CODE'
const REFERRALS_RESEND_CODE_RECEIVED = 'REFERRALS_RESEND_CODE_RECEIVED'
const REFERRALS_RESEND_CODE_FAILED = 'REFERRALS_RESEND_CODE_FAILED'
const REFERRALS_VERIFY_CODE = 'REFERRALS_VERIFY_CODE'
const REFERRALS_VERIFY_CODE_RECEIVED = 'REFERRALS_VERIFY_CODE_RECEIVED'
const REFERRALS_VERIFY_CODE_FAILED = 'REFERRALS_VERIFY_CODE_FAILED'
const REFERRALS_AUTH_COMPLETE = 'REFERRALS_AUTH_COMPLETE'
const REFERRALS_SUBMIT_SCAN_REVIEW = 'REFERRALS_SUBMIT_SCAN_REVIEW'
const REFERRALS_SUBMIT_SCAN_REVIEW_RECEIVED = 'REFERRALS_SUBMIT_SCAN_REVIEW_RECEIVED'
const REFERRALS_SUBMIT_SCAN_REVIEW_FAILED = 'REFERRALS_SUBMIT_SCAN_REVIEW_FAILED'
const REFERRALS_TOGGLE_SESSION_EXPIRED_MODAL = 'REFERRALS_TOGGLE_SESSION_EXPIRED_MODAL'

const referralsSubmitScanReview = payload => ({
  type: REFERRALS_SUBMIT_SCAN_REVIEW,
  payload
})

const referralsSubmitScanReviewReceived = payload => ({
  type: REFERRALS_SUBMIT_SCAN_REVIEW_RECEIVED,
  payload
})

const referralsSubmitScanReviewFailed = payload => ({
  type: REFERRALS_SUBMIT_SCAN_REVIEW_FAILED,
  payload
})

const referralsVerifyCode = payload => ({
  type: REFERRALS_VERIFY_CODE,
  payload
})

const referralsVerifyCodeReceived = payload => ({
  type: REFERRALS_VERIFY_CODE_RECEIVED,
  payload
})

const referralsVerifyCodeFailed = payload => ({
  type: REFERRALS_VERIFY_CODE_FAILED,
  payload
})

const referralsResendCode = payload => ({
  type: REFERRALS_RESEND_CODE,
  payload
})

const referralsResendCodeReceived = payload => ({
  type: REFERRALS_RESEND_CODE_RECEIVED,
  payload
})

const referralsResendCodeFailed = payload => ({
  type: REFERRALS_RESEND_CODE_FAILED,
  payload
})

const referralsFetchReferralData = payload => ({
  type: REFERRALS_FETCH_REFERRAL_DATA,
  payload
})

const referralsFetchReferralDataReceived = payload => ({
  type: REFERRALS_FETCH_REFERRAL_DATA_RECEIVED,
  payload
})

const referralsFetchReferralDataFailed = payload => ({
  type: REFERRALS_FETCH_REFERRAL_DATA_FAILED,
  payload
})

const referralsAuthComplete = payload => ({
  type: REFERRALS_AUTH_COMPLETE,
  payload
})

const referralsToggleSessionExpiredModal = payload => ({
  type: REFERRALS_TOGGLE_SESSION_EXPIRED_MODAL,
  payload
})

export default {
  REFERRALS_FETCH_REFERRAL_DATA,
  REFERRALS_FETCH_REFERRAL_DATA_RECEIVED,
  REFERRALS_FETCH_REFERRAL_DATA_FAILED,
  REFERRALS_RESEND_CODE,
  REFERRALS_RESEND_CODE_RECEIVED,
  REFERRALS_RESEND_CODE_FAILED,
  REFERRALS_VERIFY_CODE,
  REFERRALS_VERIFY_CODE_RECEIVED,
  REFERRALS_VERIFY_CODE_FAILED,
  REFERRALS_AUTH_COMPLETE,
  REFERRALS_SUBMIT_SCAN_REVIEW,
  REFERRALS_SUBMIT_SCAN_REVIEW_RECEIVED,
  REFERRALS_SUBMIT_SCAN_REVIEW_FAILED,
  REFERRALS_TOGGLE_SESSION_EXPIRED_MODAL,
  referralsToggleSessionExpiredModal,
  referralsSubmitScanReview,
  referralsSubmitScanReviewReceived,
  referralsSubmitScanReviewFailed,
  referralsVerifyCode,
  referralsVerifyCodeReceived,
  referralsVerifyCodeFailed,
  referralsResendCode,
  referralsResendCodeReceived,
  referralsResendCodeFailed,
  referralsFetchReferralData,
  referralsFetchReferralDataReceived,
  referralsFetchReferralDataFailed,
  referralsAuthComplete
}
