import React from 'react'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import _, { chain, isEmpty } from 'lodash'
import { v4 as uuidv4, v4 } from 'uuid'
import moment from 'moment'
import { ofType } from 'redux-observable'
import { concat, empty, forkJoin, from, of } from 'rxjs'
import {
  catchError,
  filter,
  flatMap,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  withLatestFrom,
  tap,
  ignoreElements
} from 'rxjs/operators'
import Actions from '../actions'
import { AWS_DATE_TIME, AWS_DATE_TIME_UTC } from '../consts/dateTimeConsts'
import {
  getCycleForUpdateStatus,
  getGrinScanForReview,
  getTreatmentsFullByPatientId,
  grinScansByPatientId,
  updateCycleMinimized,
  updateGrinScan,
  updateGrinScanForFeedback,
  getGrinScanForTimeline,
  getGrinScanMetadata,
  initialScansByPatientId
} from '../graphql/customQueries'
import { createInitialScan, updateInitialScan, updateTreatment, createSurveyResult } from '../graphql/mutations'
import { listStatuss, getInitialScan } from '../graphql/queries'
import i18n from '../resources/locales/i18n'
import { mapGrinScan, mapToGrinScansDto } from '../utils/mappers/treatmentMapper'
import { StatusTypesOrder } from '../utils/statusUtils'
import { withS3Details, grinCommonCloudfrontUrl } from '../utils/storageUtils'
import { generateMediaPath, getNewMediaBucket, getObjectUrl, mediaTypes, putObject } from 'utils/mediaUtils'
import { removeFalseValues } from '../utils/generalUtils'
import { MEDICAL_RECORDS_BUCKET } from 'consts/appConsts'
import { isUserOfAnyAdminRole } from 'utils/authUtils'
import { trackEvent } from 'utils/analyticsUtils'
import { SCANNERS_LIST_FILE, STL_FILES_DIR } from 'consts/recordsConsts'
import { logError, logInfo } from 'utils/logUtils'
import { isScanEligableForBeforeAfter } from 'utils/scanUtils'
import { CYCLE_END_DATE_FORMAT, CycleStatuses } from 'consts/treatmentConsts'
import { Trans } from 'react-i18next'
import { b64toBlob } from 'utils/fileUtils'
import fileExtension from 'file-extension'
import { mapErrorPayload } from 'utils/mappers/logMappers'
import config from 'utils/awsUtils'
import { getPatientWithRoomAndScans } from 'utils/patientUtils'

export const requestGrinScansEpic = action$ => {
  return action$.pipe(
    ofType(Actions.REQUEST_GRIN_SCANS),
    pluck('payload'),
    switchMap(patientId =>
      from(API.graphql(graphqlOperation(grinScansByPatientId, { patientId }))).pipe(
        map(({ data }) => mapToGrinScansDto(data?.grinScansByPatientId.items)),
        mergeMap(scans => of(Actions.grinScansReceived(scans))),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )
}

export const updateTreatmentReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_TREATMENT_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('messages.treatment.treatmentUpdated'),
        type: 'success'
      })
    )
  )

export const updateTreatmentFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_TREATMENT_FAILED),
    filter(() => !window.location.pathname.includes('uploadProviderStls')), // This prevents error message from providers like SPARK which uses a public page and they are not authorized for updating treatment really...
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const requestPatientTreatmentEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_PATIENT_TREATMENT),
    withLatestFrom(state$),
    map(([action, state]) => ({
      statePatient: state.patientsReducer.patient,
      patient: action.payload
    })),
    filter(({ statePatient }) => !statePatient.isLead),
    switchMap(({ patient, statePatient }) =>
      forkJoin({
        treatmentResult: API.graphql(
          graphqlOperation(getTreatmentsFullByPatientId, {
            patientId: patient.id
          })
        ),
        patientResult: patient?.id === statePatient?.id ? of(statePatient) : of(getPatientWithRoomAndScans(patient.id))
      }).pipe(
        map(({ treatmentResult, patientResult }) => {
          return {
            treatment: treatmentResult.data.treatmentsByPatientId.items[0] || patientResult.treatments.items[0],
            patient: patientResult?.data?.getPatient || patientResult
          }
        }),
        mergeMap(result => of(Actions.patientTreatmentReceived(result))),
        catchError(err => of(Actions.patientTreatmentFetchFailed(err)))
      )
    )
  )

export const fetchScanForReviewEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_SCAN_FOR_REVIEW),
    pluck('payload'),
    switchMap(payload =>
      from(API.graphql(graphqlOperation(getGrinScanForReview, { id: payload.id }))).pipe(
        map(res => mapGrinScan(res.data.getGrinScan)),
        mergeMap(grinScan => of(Actions.fetchScanForReviewReceived(grinScan))),
        catchError(err => of(Actions.fetchScanForReviewFailed(err)))
      )
    )
  )

export const fetchScanForReviewFailed = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_SCAN_FOR_REVIEW_FAILED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('messages.somethingWentWrongContactSupport'),
        type: 'error'
      })
    )
  )

export const fetchPatientTreatmentFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.PATIENT_TREATMENT_FETCH_FAILED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('messages.somethingWentWrongContactSupport'),
        type: 'error'
      })
    )
  )

export const onScanNotificationReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload,
      activePatient: state.patientsReducer.patient
    })),
    filter(({ notification }) => notification.entityType === 'Scan'),
    filter(({ activePatient, notification }) => activePatient.id === notification.entityId),
    mergeMap(({ notification }) => of(Actions.requestGrinScans(notification.entityId)))
  )

export const onTreatmentNotificationReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload,
      activePatient: state.patientsReducer.patient
    })),
    filter(({ notification }) => notification.entityType === 'Treatment'),
    filter(({ activePatient, notification }) => activePatient.id === notification.entityId),
    mergeMap(({ activePatient }) => of(Actions.requestPatientTreatment(activePatient)))
  )

export const sendScanReviewEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_SEND_SCAN_REVIEW),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action.payload,
      reviewerDoctorId: state.profileReducer.doctor.id
    })),
    switchMap(({ path, blob, scan, reviewerDoctorId }) =>
      from(
        putObject({
          path,
          data: blob,
          contentType: 'video/mp4',
          headers: {
            'x-amz-meta-s': scan.id,
            'x-amz-meta-d': scan.a_doctor,
            'x-amz-meta-p': scan.a_patient,
            'x-amz-meta-pid': scan.patientId,
            'x-amz-meta-r': reviewerDoctorId
          }
        })
      ).pipe(
        mergeMap(() => of(Actions.sendScanReviewReceived())),
        catchError(error =>
          concat(
            of(Actions.fetchRejected(error)),
            of(
              Actions.showSnackbar({
                text: `error sending review`,
                type: 'error'
              })
            )
          )
        )
      )
    )
  )

export const fetchScannerTypesList = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_SCANNER_TYPES_LIST),
    switchMap(() =>
      from(
        fetch(`${grinCommonCloudfrontUrl}/public/${SCANNERS_LIST_FILE}`)
          .then(res => res.json())
          .then(Object.keys)
      ).pipe(
        mergeMap(list => of(Actions.fetchScannerTypesListReceived(list))),
        catchError(err => of(Actions.fetchScannerTypesListFailed(err)))
      )
    )
  )

export const fetchScannerTypesListFailed = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_SCANNER_TYPES_LIST_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const uploadInitialStlFilesEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPLOAD_INITIAL_STL_FILES),
    pluck('payload'),
    switchMap(({ patientId, patientUsername, doctorUsername, scannerType, files }) =>
      forkJoin(files.map(({ data, key }) => Storage.put(`${STL_FILES_DIR}/${key}`, data))).pipe(
        map(objects => objects.map(object => object.key).map(withS3Details)),
        mergeMap(s3Objects =>
          from(
            API.graphql(
              graphqlOperation(createInitialScan, {
                input: {
                  patientId,
                  date: moment().format(AWS_DATE_TIME),
                  a_patient: patientUsername,
                  a_doctor: doctorUsername,
                  comment: 'Webapp STLs upload',
                  stls: s3Objects,
                  panoramics: [],
                  oralImages: [],
                  scannerType
                }
              })
            )
          ).pipe(
            map(res => res.data.createInitialScan),
            mergeMap(initialScan =>
              of(Actions.uploadStlFilesReceived({ initialScan, numberOfUploadedFiles: files.length }))
            ),
            catchError(error =>
              of(
                Actions.uploadStlFilesFailed({
                  step: 'createInitialScan',
                  error
                })
              )
            )
          )
        ),
        catchError(error => of(Actions.uploadStlFilesFailed({ step: 'uploadFiles', error })))
      )
    )
  )

export const uploadStlFilesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPLOAD_STL_FILES),
    withLatestFrom(state$),
    map(([{ payload }, state]) => ({
      ...payload,
      initialScanId: state.patientsReducer.patientCard.scans.selectedRecord.id,
      stls: state.patientsReducer.patientCard.scans.selectedRecord.stls.filter(stl => !stl.isLoading)
    })),
    switchMap(({ scannerType, files, stls, initialScanId }) =>
      from(API.graphql(graphqlOperation(getInitialScan, { id: initialScanId }))).pipe(
        map(response => response.data.getInitialScan),
        switchMap(({ _version, id }) =>
          forkJoin(files.map(({ data, key }) => Storage.put(`${STL_FILES_DIR}/${key}`, data))).pipe(
            map(objects => [...stls, ...objects].map(object => object.key).map(withS3Details)),
            mergeMap(s3Objects =>
              from(
                API.graphql(
                  graphqlOperation(updateInitialScan, {
                    input: {
                      id,
                      _version,
                      comment: 'Webapp STLs upload',
                      stls: s3Objects,
                      scannerType
                    }
                  })
                )
              ).pipe(
                map(res => res.data.updateInitialScan),
                mergeMap(initialScan =>
                  of(Actions.uploadStlFilesReceived({ initialScan, numberOfUploadedFiles: files.length }))
                ),
                catchError(error =>
                  of(
                    Actions.uploadStlFilesFailed({
                      step: 'updateInitialScan',
                      error
                    })
                  )
                )
              )
            ),
            catchError(error => of(Actions.uploadStlFilesFailed({ step: 'uploadFiles', error })))
          )
        )
      )
    )
  )

export const uploadStlFilesReceived = action$ =>
  action$.pipe(
    ofType(Actions.UPLOAD_STL_FILES_RECEIVED),
    pluck('payload', 'numberOfUploadedFiles'),
    mergeMap(numberOfUploadedFiles =>
      concat(
        of(Actions.toggleUploadStlFilesModal({ isModalOpen: false })),
        of(
          Actions.showSnackbar({
            type: 'success',
            text: i18n.t('pages.patients.selectedPatient.uploadStlsModal.messages.filesUploadedSuccessfully', {
              numberOfUploadedFiles
            })
          })
        )
      )
    )
  )

export const uploadStlFilesFailed = action$ =>
  action$.pipe(
    ofType(Actions.UPLOAD_STL_FILES_FAILED),
    pluck('payload', 'step'),
    map(step =>
      Actions.showSnackbar({
        type: 'error',
        text:
          step === 'uploadFiles'
            ? i18n.t('pages.patients.selectedPatient.uploadStlsModal.messages.filesUploadFailed')
            : i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const savePatientPrelimPlanEpic = action$ =>
  action$.pipe(
    ofType(Actions.SAVE_PATIENT_PRELIM_PROPOSAL),
    pluck('payload'),
    switchMap(({ id, treatmentId, patientId, tier, cost, length, periodType, vendor, additionalVendor, notes }) =>
      from(
        API.put('grinApi', '/treatments/v1/preliminaryPlans', {
          body: {
            id,
            treatmentId,
            patientId,
            tier,
            cost,
            length,
            periodType,
            vendor,
            additionalVendor,
            notes
          }
        })
      ).pipe(
        mergeMap(proposal => of(Actions.patientPrelimPlanSaved({ proposal }))),
        catchError(error => of(Actions.savePatientPrelimPlanFailed(error)))
      )
    )
  )

export const savePatientPrelimPlanFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SAVE_PATIENT_PRELIM_PROPOSAL_FAILED),
    map(() =>
      Actions.showSnackbar({
        text: i18n.t('messages.somethingWentWrongContactSupport'),
        type: 'error'
      })
    )
  )

export const approvePatientPrelimPlanEpic = action$ =>
  action$.pipe(
    ofType(Actions.APPROVE_PATIENT_PRELIM_PROPOSAL),
    pluck('payload'),
    switchMap(({ id, tier, cost, length, periodType, vendor, additionalVendor, notes }) =>
      from(
        API.put('grinApi', `/treatments/v1/preliminaryPlans/approve`, {
          body: { id, tier, cost, length, periodType, vendor, additionalVendor, notes }
        })
      ).pipe(
        mergeMap(proposal => of(Actions.patientPrelimPlanApproved({ proposal }))),
        catchError(error => of(Actions.approvePatientPrelimPlanFailed(error)))
      )
    )
  )

export const patientPrelimPlanApprovedEpic = action$ =>
  action$.pipe(
    ofType(Actions.PATIENT_PRELIM_PROPOSAL_APPROVED),
    map(() =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('dialogs.patientInfo.prelimPlan.prelimPlanApproved')
      })
    )
  )

export const approvePatientPrelimPlanFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.APPROVE_PATIENT_PRELIM_PROPOSAL_FAILED),
    map(() =>
      Actions.showSnackbar({
        text: i18n.t('messages.somethingWentWrongContactSupport'),
        type: 'error'
      })
    )
  )

export const saveTreatmentPlanEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SAVE_TREATMENT_PLAN),
    withLatestFrom(state$),
    map(([action, state]) => ({ ...action.payload, a_doctor: state.profileReducer.doctor.username })),
    switchMap(treatmentPlan =>
      from(
        API.post('grinApi', '/treatments/v1/treatmentsPlans', {
          body: { ...treatmentPlan }
        })
      ).pipe(
        mergeMap(treatmentPlan => of(Actions.treatmentPlanSaved({ treatmentPlan }))),
        catchError(error => of(Actions.treatmentPlanFailed(error)))
      )
    )
  )

export const treatmentPlanSavedEpic = action$ =>
  action$.pipe(
    ofType(Actions.TREATMENT_PLAN_SAVED),
    tap(() => trackEvent('Treatment plan - plan saved')),
    map(() =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('dialogs.patientInfo.treatmentPlan.treatmentPlanSubmitted')
      })
    )
  )

export const saveTreatmentPlanFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.TREATMENT_PLAN_FAILED),
    tap(() => trackEvent('Treatment plan - save plan failed')),
    map(() =>
      Actions.showSnackbar({
        text: i18n.t('messages.somethingWentWrongContactSupport'),
        type: 'error'
      })
    )
  )

export const fetchPreliminaryPlanProductsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_PRELIMINARY_PLAN_PRODUCTS),
    withLatestFrom(state$),
    filter(([, { treatmentReducer }]) => isEmpty(treatmentReducer.preliminaryPlanProducts.data)),
    flatMap(() =>
      from(API.get('grinApi', '/billing/v1/products/preliminaryPlans')).pipe(
        mergeMap(products => of(Actions.fetchPreliminaryPlanProductsReceived(products))),
        catchError(response => {
          concat(
            of(Actions.fetchPreliminaryPlanProductsFailed(response)),
            of(
              Actions.showSnackbar({
                text: i18n.t('messages.somethingWentWrongContactSupport'),
                type: 'error'
              })
            )
          )
        })
      )
    )
  )

export const fetchStatuses = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_STATUSES),
    switchMap(() =>
      from(API.graphql(graphqlOperation(listStatuss))).pipe(
        map(res => res.data.listStatuss.items.filter(status => !status.program.includes('whitening'))),
        map(data => ({
          data: chain(data)
            .sortBy(status => StatusTypesOrder[status.type])
            .groupBy('program')
            .value(),
          types: Object.keys(_.groupBy(data, 'type'))
        })),
        mergeMap(data => of(Actions.fetchStatusesReceived(data))),
        catchError(({ response }) => of(Actions.fetchStatusesFailed(response)))
      )
    )
  )

export const updateGrinScanApplianceEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_GRIN_SCAN_APPLIANCE),
    pluck('payload'),
    map(({ treatment, scan }) =>
      removeFalseValues({
        treatment:
          treatment &&
          API.graphql(graphqlOperation(updateTreatment, { input: treatment })).then(
            ({ data }) => data?.updateTreatment
          ),
        scan:
          scan &&
          API.graphql(graphqlOperation(updateGrinScan, { input: scan })).then(({ data }) => data?.updateGrinScan)
      })
    ),
    switchMap(promises =>
      forkJoin(promises).pipe(
        mergeMap(data => of(Actions.updateGrinScanApplianceReceived(data))),
        catchError(error => of(Actions.updateGrinScanApplianceFailed(error)))
      )
    )
  )

export const updateGrinScanApplianceReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_GRIN_SCAN_APPLIANCE_RECEIVED),
    map(() =>
      Actions.showSnackbar({
        text: i18n.t('messages.grinScan.scanUpdatedSuccessfully'),
        type: 'success'
      })
    )
  )

export const updateGrinScanApplianceFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_GRIN_SCAN_APPLIANCE_FAILED),
    map(() =>
      Actions.showSnackbar({
        text: i18n.t('messages.grinScan.failedToUpdateScan'),
        type: 'error'
      })
    )
  )

export const updateTreatmentStatusEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPDATE_TREATMENT_STATUS),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action.payload,
      doctorUsername: state.profileReducer.doctor.username,
      previousStatusKey: state.patientsReducer.patient.treatments?.items[0]?.currentStatus?.statusKey,
      totalApplianceNumber: action.payload.totalAlignersSets
    })),
    switchMap(
      ({
        statusKey,
        treatmentId,
        cycleInterval,
        cycleIntervalPeriodType,
        doctorUsername,
        nextScanDate,
        previousStatusKey,
        treatmentType,
        totalApplianceNumber
      }) =>
        from(
          API.put('grinApi', '/treatments/v1/statuses', {
            body: {
              treatmentId,
              statusKey,
              cycleInterval,
              cycleIntervalPeriodType,
              setByUsername: doctorUsername,
              nextScanDate,
              treatmentType,
              totalApplianceNumber
            }
          })
        ).pipe(
          mergeMap(data =>
            of(Actions.updateTreatmentStatusReceived({ ...data, previousStatusKey, totalApplianceNumber }))
          ),
          catchError(({ response }) => of(Actions.updateTreatmentStatusFailed({ error: response?.data })))
        )
    )
  )

export const updateTreatmentStatusSuccess = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_TREATMENT_STATUS_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('messages.treatment.statusUpdatedSuccessfully'),
        type: 'success'
      })
    )
  )

export const updateTreatmentStatusFailed = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_TREATMENT_STATUS_FAILED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('messages.somethingWentWrongContactSupport'),
        type: 'error',
        time: 6000
      })
    )
  )

export const scanSummaryReviewedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SCAN_SUMMARY_REVIEWED, Actions.SUBMIT_PREVENTIVE_FEEDBACK),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action.payload,
      scan: state.treatmentReducer.grinScans.find(currScan => currScan.id === action.payload.scanId),
      patientName: state.patientsReducer.patient.details.name
    })),
    switchMap(({ scanId, scan, feedbackData, scanNumber, patientName, preventiveFeedback }) => {
      return from(
        API.post('grinApi', '/treatments/v1/scanSummary/feedback', {
          body: { scanId, feedbackData, scanNumber, patientName, preventiveFeedback }
        })
      ).pipe(
        mergeMap(scanSummaryData => of(Actions.scanSummaryReviewedReceived({ scanSummaryData, scanId }))),
        catchError(error => of(Actions.scanSummaryReviewedFailed(error)))
      )
    })
  )

export const scanSummaryReviewedFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SCAN_SUMMARY_REVIEWED_FAILED),
    map(() =>
      Actions.showSnackbar({
        text: i18n.t('messages.somethingWentWrongContactSupport'),
        type: 'error'
      })
    )
  )

export const scanSummaryReviewedReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SCAN_SUMMARY_REVIEWED_RECEIVED),
    map(() =>
      Actions.showSnackbar({
        text: i18n.t('messages.grinScan.reviewSubmitted'),
        type: 'success'
      })
    )
  )

export const submitScanFeedbackEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPDATE_SCAN_FEEDBACK),
    withLatestFrom(state$),
    map(([action, state]) => ({
      scan: state.patientsReducer.patient?.grinScans?.items.find(scan => scan.id === action.payload.id),
      feedback: action.payload.feedback
    })),
    switchMap(({ scan, feedback }) =>
      from(
        API.graphql(
          graphqlOperation(updateGrinScanForFeedback, {
            input: {
              id: scan.id,
              _version: scan._version,
              metadata: JSON.stringify({ ...JSON.parse(scan.metadata || '{}'), feedback })
            }
          })
        )
      ).pipe(
        map(res => res.data?.updateGrinScan),
        mergeMap(scan => of(Actions.updateScanFeedbackReceived(scan))),
        catchError(error => of(Actions.updateScanFeedbackFailed({ scanId: scan.id, error })))
      )
    )
  )

export const submitScanFeedbackFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_SCAN_FEEDBACK_FAILED),
    map(() =>
      Actions.showSnackbar({
        text: i18n.t('messages.somethingWentWrongContactSupport'),
        type: 'error'
      })
    )
  )

export const updateTreatmentEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPDATE_TREATMENT),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const { treatmentId, ...body } = payload
      const previousTreatment = {
        statusKey: state.patientsReducer.patient.treatments?.items[0]?.currentStatus?.statusKey,
        a_patient: state.patientsReducer.patient.username
      }

      return from(
        API.put('grinApi', `/treatments/v1/treatments/${treatmentId}`, {
          body
        })
      ).pipe(
        mergeMap(({ updatedTreatment, updatedCycle }) =>
          of(Actions.updateTreatmentReceived({ previousTreatment, treatment: updatedTreatment, cycle: updatedCycle }))
        ),
        catchError(error => of(Actions.updateTreatmentFailed(error)))
      )
    })
  )

export const uploadMedicalRecordEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPLOAD_MEDICAL_RECORD),
    withLatestFrom(state$),
    map(([{ payload }, state]) => ({
      file: payload,
      treatmentId: state.treatmentReducer.treatment.id,
      medicalRecordsData: JSON.parse(state.treatmentReducer.treatment?.medicalRecordsData || '[]'),
      _version: state.treatmentReducer.treatment._version
    })),
    mergeMap(({ file, treatmentId, medicalRecordsData, _version }) => {
      const medicalRecordObject = {
        id: uuidv4(),
        uploadingDate: moment.utc().format(AWS_DATE_TIME_UTC),
        file
      }
      return from(
        Storage.put(medicalRecordObject.id, medicalRecordObject.file, {
          bucket: MEDICAL_RECORDS_BUCKET
        })
      ).pipe(
        mergeMap(() =>
          from(
            API.graphql(
              graphqlOperation(updateTreatment, {
                input: {
                  id: treatmentId,
                  _version,
                  medicalRecordsData: JSON.stringify([
                    ...medicalRecordsData,
                    { id: medicalRecordObject.id, wasAddedByGrin: isUserOfAnyAdminRole(), name: file.name }
                  ])
                }
              })
            )
          ).pipe(
            map(({ data }) => data?.updateTreatment),
            mergeMap(treatment => of(Actions.uploadMedicalRecordReceived({ treatment, medicalRecordObject }))),
            catchError(error => of(Actions.uploadMedicalRecordFailed(error)))
          )
        ),
        catchError(error => of(Actions.uploadMedicalRecordFailed(error)))
      )
    }),
    catchError(error => of(Actions.uploadMedicalRecordFailed(error)))
  )

export const fetchMedicalRecordsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_MEDICAL_RECORDS),
    withLatestFrom(state$),
    map(([{ payload }, state]) => ({
      medicalRecordsData: JSON.parse(state.treatmentReducer.treatment?.medicalRecordsData || '[]')
    })),
    switchMap(({ medicalRecordsData }) => {
      const fileKeys = medicalRecordsData.map(record => record.id)

      return from(API.get('grinApi', `/treatments/v1/medicalRecords?fileKeys=${fileKeys.join(',')}`)).pipe(
        mergeMap(({ body }) => {
          return of(Actions.fetchMedicalRecordsReceived(body))
        }),
        catchError(({ response }) => of(Actions.fetchMedicalRecordsFailed(response)))
      )
    })
  )

export const deleteMedicalRecordEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DELETE_MEDICAL_RECORD),
    withLatestFrom(state$),
    map(([action, state]) => ({
      fileKey: action.payload.fileKey,
      treatmentId: state.treatmentReducer.treatment.id,
      medicalRecordsData: JSON.parse(state.treatmentReducer.treatment?.medicalRecordsData || '[]')
    })),
    switchMap(({ fileKey, treatmentId, medicalRecordsData }) =>
      from(API.del('grinApi', `/treatments/v1/medicalRecords?fileKey=${fileKey}&treatmentId=${treatmentId}`)).pipe(
        mergeMap(response => of(Actions.deleteMedicalRecordReceived(response))),
        catchError(({ response }) => {
          of(Actions.deleteMedicalRecordFailed(response))
        })
      )
    )
  )

export const uploadMedicalRecordFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPLOAD_MEDICAL_RECORD_FAILED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('messages.uploadMedicalRecordError'),
        type: 'error'
      })
    )
  )

export const uploadMedicalRecordReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPLOAD_MEDICAL_RECORD_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      patientId: state.patientsReducer.patient.id,
      doctorId: state.profileReducer.doctor.id,
      wasAddedByGrin: isUserOfAnyAdminRole(),
      medicalRecordsData: action.payload.treatment.medicalRecordsData
    })),
    switchMap(({ medicalRecordsData, ...eventParams }) => {
      const parsedRecords = JSON.parse(medicalRecordsData)
      const record = parsedRecords[parsedRecords.length - 1]

      trackEvent('Medical history - record uploaded', {
        ...eventParams,
        recordType: record.name.split('.')[1]
      })
      return of(
        Actions.showSnackbar({
          text: i18n.t('messages.uploadMedicalRecordSuccess'),
          type: 'success'
        })
      )
    })
  )

export const fetchMedicalRecordsFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_MEDICAL_RECORDS_FAILED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('messages.fetchMedicalRecordsError'),
        type: 'error'
      })
    )
  )

export const deleteMedicalRecordReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DELETE_MEDICAL_RECORD_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      patientId: state.patientsReducer.patient.id,
      doctorId: state.profileReducer.doctor.id,
      wasAddedByGrin: isUserOfAnyAdminRole()
    })),
    switchMap(eventProps => {
      trackEvent('Medical history - record deleted', eventProps)

      return of(
        Actions.showSnackbar({
          text: i18n.t('messages.deleteMedicalRecordSuccess'),
          type: 'success'
        })
      )
    })
  )

export const deleteMedicalRecordFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_MEDICAL_RECORD_FAILED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('messages.deleteMedicalRecordError'),
        type: 'error'
      })
    )
  )
export const fetchBase64ImageEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_BASE_64_IMAGE),
    pluck('payload'),
    switchMap(s3Object =>
      from(
        API.get('grinApi', `/treatments/v1/scanSummary/dataUrl?imageKey=${s3Object.key}&imageBucket=${s3Object.bucket}`)
      ).pipe(
        mergeMap(({ body: base64Image }) => of(Actions.fetchBase64ImageReceived(base64Image))),
        catchError(err => of(Actions.fetchBase64ImageFailed(err)))
      )
    )
  )

export const openBeforeAfterDialogAnalyticsEpic = action$ =>
  action$.pipe(
    ofType(Actions.TOGGLE_BEFORE_AFTER_DIALOG),
    pluck('payload'),
    filter(payload => payload.open),
    tap(payload =>
      trackEvent('Before After - modal opened', {
        scanId: payload.scanId,
        patientId: payload.patientId,
        ...payload.analytics
      })
    ),
    ignoreElements()
  )

export const fetchBeforeAfterAssetEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_BEFORE_AFTER_ASSET),
    pluck('payload'),
    switchMap(({ lastScanId, firstScanId, patientId, pose, startTime = new Date().getTime() }) =>
      from(
        API.post('grinServerlessApi', '/beforeAfter/v1/asset', {
          body: {
            patientId,
            lastScanId,
            firstScanId,
            pose
          }
        })
      ).pipe(
        tap(response => {
          const logData = {
            took: new Date().getTime() - startTime,
            patientId,
            lastScanId,
            firstScanId,
            pose,
            assetS3Object: response.assetS3Object,
            assetUrl: response.assetS3Object && getObjectUrl(response.assetS3Object),
            origin: 'doctors platform'
          }
          logInfo('Before After - asset generated', logData)
          trackEvent('Before After - asset generated', logData)
        }),
        mergeMap(response => of(Actions.fetchBeforeAfterAssetReceived(response))),
        catchError(error => of(Actions.fetchBeforeAfterAssetFailed(error.response?.data)))
      )
    )
  )

export const toggleBeforeAfterDialogAfterStatusChange = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPDATE_TREATMENT_STATUS_RECEIVED, Actions.UPDATE_TREATMENT_RECEIVED),
    withLatestFrom(state$),
    filter(
      ([action, state]) =>
        (action.payload?.statusKey?.includes('retention') ||
          action.payload?.treatment?.currentStatus?.statusKey?.includes('retention')) &&
        (action.payload.previousStatusKey?.includes('active-treatment') ||
          action.payload.previousTreatment.statusKey?.includes('active-treatment')) &&
        (action.payload.a_patient === state.patientsReducer.patient.username ||
          action.payload.previousTreatment.a_patient === state.patientsReducer.patient.username)
    ),
    map(([action, state]) => ({
      scan: state.patientsReducer.patient?.grinScans.items?.reverse().find(isScanEligableForBeforeAfter),
      patientId: state.patientsReducer.patient.id
    })),
    filter(({ scan }) => !!scan),
    switchMap(({ scan, patientId }) =>
      from(
        API.post('grinServerlessApi', '/beforeAfter/v1/asset', {
          body: {
            patientId,
            lastScanId: scan.id
          }
        })
      ).pipe(
        mergeMap(() =>
          of(
            Actions.autoGenerateBeforeAfterReceived({
              scanId: scan.id,
              patientId
            })
          )
        ),
        catchError(ex => of(Actions.autoGenerateBeforeAfterFailed(ex)))
      )
    )
  )

export const autoGenerateBeforeAfterReceived = (action$, state$) =>
  action$.pipe(
    ofType(Actions.AUTO_GENERATE_BEFORE_AFTER_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      displayedPatientId: state.patientsReducer.patient.id,
      generatedAssetPatientId: action.payload.patientId,
      scanId: action.payload.scanId
    })),
    switchMap(({ displayedPatientId, generatedAssetPatientId, scanId }) => {
      if (displayedPatientId === generatedAssetPatientId) {
        logInfo('Before after asset - Opening before/after modal for patient after changing status to retention', {
          generatedAssetPatientId,
          displayedPatientId
        })
        return of(
          Actions.toggleBeforeAfterDialog({
            open: true,
            scanId,
            patientId: displayedPatientId,
            analytics: {
              source: 'status-change'
            }
          })
        )
      }

      logInfo(
        'Before after asset - Not opening before/after modal for patient, asset was generated but displayed patient was changed during generation',
        {
          generatedAssetPatientId,
          displayedPatientId
        }
      )

      return empty()
    })
  )

export const submitBeforeAfterFeedbackEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SUBMIT_BEFORE_AFTER_FEEDBACK),
    withLatestFrom(state$),
    map(([action, state]) => ({
      type: 'before-after-feedback',
      content: action.payload.freeText,
      rating: action.payload.rating,
      metadata: JSON.stringify({
        lastScanId: action.payload.lastScanId,
        patientId: action.payload.patientId
      }),
      a_doctor: state?.profileReducer.doctor?.username,
      responderId: state?.profileReducer.doctor?.id,
      responderAccountOwnerId: state?.practiceReducer?.accountOwner.id ?? state?.profileReducer.doctor?.id
    })),
    switchMap(input =>
      from(
        API.graphql(
          graphqlOperation(createSurveyResult, {
            input
          })
        )
      ).pipe(
        mergeMap(res => of(Actions.submitBeforeAfterFeedbackReceived(res.data.createSurveyResult))),
        catchError(err => of(Actions.submitAppFeedbackFailed(err)))
      )
    )
  )

export const setCycleStatusEpic = action$ =>
  action$.pipe(
    ofType(Actions.SET_CYCLE_STATUS),
    pluck('payload'),
    mergeMap(({ cycleId, status, patientId }) =>
      from(API.graphql(graphqlOperation(getCycleForUpdateStatus, { id: cycleId }))).pipe(
        map(res => res.data?.getCycle),
        map(cycle => {
          const treatment = cycle.patient?.treatments?.items?.[0]
          return {
            _version: cycle._version,
            originalEndDate:
              !treatment || status === CycleStatuses.Paused
                ? cycle.originalEndDate
                : moment().add(treatment.cycleInterval, treatment.cycleIntervalPeriodType).format(CYCLE_END_DATE_FORMAT)
          }
        }),
        mergeMap(({ _version, originalEndDate }) =>
          from(
            API.graphql(
              graphqlOperation(updateCycleMinimized, { input: { id: cycleId, status, originalEndDate, _version } })
            )
          ).pipe(
            mergeMap(() => of(Actions.setCycleStatusReceived({ cycleId, status, patientId, originalEndDate }))),
            catchError(err => of(Actions.setCycleStatusFailed(err)))
          )
        ),
        catchError(err => of(Actions.setCycleStatusFailed(err)))
      )
    )
  )

export const pauseScansSuccessSnackbar = action$ =>
  action$.pipe(
    ofType(Actions.SET_CYCLE_STATUS_RECEIVED),
    pluck('payload'),
    filter(({ status }) => status === CycleStatuses.Paused),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('pages.patients.patientsList.patientListItem.actionsMenu.pauseScanSuccessMessage'),
        type: 'success'
      })
    )
  )

export const resumeScanSuccessGrinAlert = action$ =>
  action$.pipe(
    ofType(Actions.SET_CYCLE_STATUS_RECEIVED),
    pluck('payload'),
    filter(({ status }) => status === CycleStatuses.Active),
    map(({ patientId, originalEndDate }) =>
      Actions.showAlert({
        title: i18n.t('pages.patients.patientsList.patientListItem.actionsMenu.resumeScanAlert.title'),
        message: (
          <Trans
            i18nKey={'pages.patients.patientsList.patientListItem.actionsMenu.resumeScanAlert.message'}
            values={{ date: moment(originalEndDate).format('MM.DD.YYYY') }}
          />
        ),
        primaryButtonText: i18n.t('general.gotItThanks')
      })
    )
  )

export const onScanSummaryCompletedNotificationReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload,
      activePatient: state.patientsReducer.patient
    })),
    filter(
      ({ notification, activePatient }) =>
        notification.entityType === 'ScanSummary' && activePatient.id === notification.patientId
    ),
    switchMap(({ notification }) =>
      from(API.graphql(graphqlOperation(getGrinScanForTimeline, { id: notification.entityId }))).pipe(
        map(({ data }) => data?.getGrinScan),
        mergeMap(scan => of(Actions.scanSummaryLiveUpdateReceived(scan))),
        catchError(error => of(Actions.scanSummaryLiveUpdateFailed(error)))
      )
    )
  )

export const replaceOralImageEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REPLACE_ORAL_IMAGE),
    withLatestFrom(state$),
    map(([{ payload }, { patientsReducer }]) => {
      const { originalImageKey, imageIndex, newImage } = payload

      // private/scanSummaries/<scanId>
      const objectIdToAuthorize = originalImageKey.split('/')[2]
      const recordSetId = patientsReducer.patientCard.scans.selectedRecord.id

      // If image was already replaced: ..._replaced-123.jpg -> _replaces-456.jpg
      // Otherwise: ...cropped.jpg -> cropped_replaced-123.jpg
      // Changing the name of the file each time is required in order to perevent
      // cloudfront caching
      const newImageKey = originalImageKey.includes('replaced')
        ? `${originalImageKey.split('replaced')[0]}replaced-${new Date().toISOString()}.${fileExtension(
            originalImageKey
          )}`
        : originalImageKey.replace('.', `_replaced-${new Date().toISOString()}.`)

      let updatedOralImages = patientsReducer.patientCard.scans.data[0].oralImages.map(image => ({
        key: image.key,
        bucket: image.bucket,
        region: image.region
      }))

      updatedOralImages[imageIndex].key = newImageKey

      return {
        newImage,
        recordSetId,
        newImageKey,
        imageIndex,
        updatedOralImages,
        originalImageKey,
        newImagePath: generateMediaPath({
          type: mediaTypes.scanSummary,
          objectIdToAuthorize,
          keySuffix: newImageKey.split('/').reverse()[0]
        })
      }
    }),
    switchMap(({ newImage, recordSetId, imageIndex, originalImageKey, newImageKey, newImagePath, updatedOralImages }) =>
      from(
        putObject({
          path: newImagePath,
          data: b64toBlob(newImage.split(',')[1], 'image/jpg'),
          contentType: 'image/jpg'
        })
      ).pipe(
        catchError(error => {
          logError('An error received while trying to upload new oral image to s3', {
            error: mapErrorPayload(error)
          })
          return of(Actions.replaceOralImageFailed(error))
        }),
        mergeMap(() =>
          from(
            API.put('grinServerlessApi', `/treatments/v3/records/oralImages`, {
              body: { id: recordSetId, updatedOralImages }
            })
          ).pipe(
            tap(() =>
              trackEvent('Replace records image  - Replaced image', {
                recordSetId,
                imageIndex,
                originalImageKey,
                newImageKey
              })
            ),
            mergeMap(({ newOralImages }) =>
              of(
                Actions.replaceOralImageReceived({
                  newOralImages,
                  recordSetId
                })
              )
            ),
            catchError(error => {
              logError('An error received from endpoint while trying to replace oral image', {
                error: mapErrorPayload(error)
              })
              return of(Actions.replaceOralImageFailed(error))
            })
          )
        )
      )
    )
  )

export const replaceOralImageFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.REPLACE_ORAL_IMAGE_FAILED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('dialogs.replaceScanSummaryImage.error'),
        type: 'error'
      })
    )
  )

export const shareScanToReferralEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SHARE_SCAN_TO_REFERRAL),
    pluck('payload'),
    switchMap(payload =>
      from(
        API.post('grinServerlessApi', `/platform/v1/referrals/scan`, {
          body: payload
        })
      ).pipe(
        mergeMap(({ referral }) =>
          of(Actions.shareScanToReferralReceived({ referral, grinScanId: payload.grinScanId }))
        ),
        catchError(error => of(Actions.shareScanToReferralFailed(error)))
      )
    )
  )

export const shareScanToReferralReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SHARE_SCAN_TO_REFERRAL_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('dialogs.shareScan.sharedScanSuccessfully'),
        type: 'success'
      })
    )
  )

export const shareScanToReferralFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SHARE_SCAN_TO_REFERRAL_FAILED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('dialogs.shareScan.shareScanError'),
        type: 'error'
      })
    )
  )

export const oralHygieneRecommendationUpdateUserActionEpic = action$ =>
  action$.pipe(
    ofType(Actions.ORAL_HYGIENE_RECOMMENDATION_SET_USER_ACTION),
    pluck('payload'),
    switchMap(({ grinScanId, action, dismissReason }) =>
      from(
        API.graphql(graphqlOperation(getGrinScanMetadata, { id: grinScanId }))
          .then(res => res.data.getGrinScan)
          .then(({ _version, metadata }) =>
            API.graphql(
              graphqlOperation(updateGrinScan, {
                input: {
                  id: grinScanId,
                  _version: _version,
                  metadata: JSON.stringify({
                    ...JSON.parse(metadata || '{}'),
                    oralHygieneUserAction: action,
                    oralHygieneDismissReason: dismissReason
                  })
                }
              })
            )
          )
      ).pipe(
        map(res => res.data.updateGrinScan),
        mergeMap(grinScan => of(Actions.oralHygieneRecommendationSetUserActionReceived(grinScan))),
        catchError(ex => of(Actions.oralHygieneRecommendationSetUserActionFailed({ grinScanId, error: ex })))
      )
    )
  )

export const fetchPatientRecordsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_PATIENT_RECORDS),
    withLatestFrom(state$),
    map(([, state]) => ({
      patientId: state.patientsReducer.patient.id
    })),
    switchMap(({ patientId }) =>
      from(API.graphql(graphqlOperation(initialScansByPatientId, { patientId }))).pipe(
        map(({ data }) => data?.initialScansByPatientId?.items),
        mergeMap(records => of(Actions.fetchPatientRecordsReceived(records))),
        catchError(error => of(Actions.fetchPatientRecordsFailed(error)))
      )
    )
  )

export const uploadShareScanAttachmentsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPLOAD_SHARE_SCAN_ATTACHMENTS),
    pluck('payload'),
    switchMap(({ grinScanId, files }) =>
      from(
        Promise.all(
          files.map(async file => {
            const keySuffix = `${v4()}.${file.extension}`
            const path = generateMediaPath({
              type: mediaTypes.referralAttachments,
              objectIdToAuthorize: grinScanId,
              keySuffix
            })
            await putObject({
              path,
              data: b64toBlob(file.data.split(',')[1], file.type),
              contentType: file.type
            })

            const s3Object = {
              key: `private/${mediaTypes.referralAttachments}/${grinScanId}/${keySuffix}`,
              bucket: getNewMediaBucket(),
              region: config.aws_user_files_s3_bucket_region
            }

            return {
              ...s3Object,
              url: getObjectUrl(s3Object)
            }
          })
        )
      ).pipe(
        mergeMap(filesS3Objects => of(Actions.uploadShareScanAttachmentsReceived(filesS3Objects))),
        catchError(error =>
          concat(
            of(Actions.uploadShareScanAttachmentsFailed()),
            of(
              Actions.showSnackbar({
                text: i18n.t('dialogs.shareScanAttachFiles.uploadAttachmentsError'),
                type: 'error'
              })
            )
          )
        )
      )
    )
  )
