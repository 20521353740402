import * as React from 'react'

const Trash2 = ({ color = '#4251E7', strokeWidth = '1.5', ...props }) => {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2 5H11V11C11 12.1046 10.1046 13 9 13H4C2.89543 13 2 12.1046 2 11V5Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1 2.5H12" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 0H8.5L9.5 2H3.5L4.5 0Z" fill={color} />
    </svg>
  )
}

export default Trash2
