const REQUEST_PATIENTS_FOR_TASK_MANAGER = 'REQUEST_PATIENTS_FOR_TASK_MANAGER'
const PATIENTS_FOR_TASK_MANAGER_RECEIVED = 'PATIENTS_FOR_TASK_MANAGER_RECEIVED'
const PATIENTS_FOR_TASK_MANAGER_FAILED = 'PATIENTS_FOR_TASK_MANAGER_FAILED'
const SET_TASK_MANAGER_PAGE = 'SET_TASK_MANAGER_PAGE'
const SET_TASK_MANAGER_FILTERS = 'SET_TASK_MANAGER_FILTERS'
const TOGGLE_APPLIANCE_ARRIVAL_MODAL = 'TOGGLE_APPLIANCE_ARRIVAL_MODAL'
const UPDATE_APPLIANCE_ARRIVAL_STATUS = 'UPDATE_APPLIANCE_ARRIVAL_STATUS'
const UPDATE_APPLIANCE_ARRIVAL_STATUS_RECEIVED = 'UPDATE_APPLIANCE_ARRIVAL_STATUS_RECEIVED'
const UPDATE_APPLIANCE_ARRIVAL_STATUS_FAILED = 'UPDATE_APPLIANCE_ARRIVAL_STATUS_FAILED'
const REQUEST_TOTAL_PATIENTS_FOR_TASK_MANAGER = 'REQUEST_TOTAL_PATIENTS_FOR_TASK_MANAGER'
const TOTAL_PATIENTS_FOR_TASK_MANAGER_RECEIVED = 'TOTAL_PATIENTS_FOR_TASK_MANAGER_RECEIVED'
const TOTAL_PATIENTS_FOR_TASK_MANAGER_FAILED = 'TOTAL_PATIENTS_FOR_TASK_MANAGER_FAILED'

const requestPatientsForTaskManager = payload => ({
  type: REQUEST_PATIENTS_FOR_TASK_MANAGER,
  payload
})

const patientsForTaskManagerReceived = payload => ({
  type: PATIENTS_FOR_TASK_MANAGER_RECEIVED,
  payload
})

const patientsForTaskManagerFailed = payload => ({
  type: PATIENTS_FOR_TASK_MANAGER_FAILED,
  payload
})

const setTaskManagerPage = payload => ({
  type: SET_TASK_MANAGER_PAGE,
  payload
})

const setTaskManagerFilters = payload => ({
  type: SET_TASK_MANAGER_FILTERS,
  payload
})

const toggleApplianceArrivalModal = payload => ({
  type: TOGGLE_APPLIANCE_ARRIVAL_MODAL,
  payload
})

const updateApplianceArrivalStatus = payload => ({
  type: UPDATE_APPLIANCE_ARRIVAL_STATUS,
  payload
})

const updateApplianceArrivalStatusFailed = payload => ({
  type: UPDATE_APPLIANCE_ARRIVAL_STATUS_FAILED,
  payload
})

const updateApplianceArrivalStatusReceived = payload => ({
  type: UPDATE_APPLIANCE_ARRIVAL_STATUS_RECEIVED,
  payload
})

const requestTotalPatientsForTaskManager = payload => ({
  type: REQUEST_TOTAL_PATIENTS_FOR_TASK_MANAGER,
  payload
})

const totalPatientsForTaskManagerReceived = payload => ({
  type: TOTAL_PATIENTS_FOR_TASK_MANAGER_RECEIVED,
  payload
})

const totalPatientsForTaskManagerFailed = payload => ({
  type: TOTAL_PATIENTS_FOR_TASK_MANAGER_FAILED,
  payload
})

export default {
  REQUEST_PATIENTS_FOR_TASK_MANAGER,
  PATIENTS_FOR_TASK_MANAGER_RECEIVED,
  PATIENTS_FOR_TASK_MANAGER_FAILED,
  SET_TASK_MANAGER_PAGE,
  SET_TASK_MANAGER_FILTERS,
  TOGGLE_APPLIANCE_ARRIVAL_MODAL,
  UPDATE_APPLIANCE_ARRIVAL_STATUS,
  UPDATE_APPLIANCE_ARRIVAL_STATUS_RECEIVED,
  UPDATE_APPLIANCE_ARRIVAL_STATUS_FAILED,
  REQUEST_TOTAL_PATIENTS_FOR_TASK_MANAGER,
  TOTAL_PATIENTS_FOR_TASK_MANAGER_RECEIVED,
  TOTAL_PATIENTS_FOR_TASK_MANAGER_FAILED,
  requestPatientsForTaskManager,
  patientsForTaskManagerReceived,
  patientsForTaskManagerFailed,
  setTaskManagerPage,
  setTaskManagerFilters,
  toggleApplianceArrivalModal,
  updateApplianceArrivalStatus,
  updateApplianceArrivalStatusFailed,
  updateApplianceArrivalStatusReceived,
  requestTotalPatientsForTaskManager,
  totalPatientsForTaskManagerReceived,
  totalPatientsForTaskManagerFailed
}
