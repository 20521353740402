import * as React from 'react'

const TreatmentTrackerIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1286 11.9175C13.3954 11.8465 13.5542 11.5726 13.4832 11.3058L12.3262 6.95704C12.2552 6.69018 11.9813 6.53141 11.7144 6.60241C11.4476 6.67341 11.2888 6.94729 11.3598 7.21415L12.3883 11.0797L8.52273 12.1081C8.25587 12.1791 8.09709 12.453 8.16809 12.7199C8.23909 12.9867 8.51298 13.1455 8.77984 13.0745L13.1286 11.9175ZM7.25077 7.52348C7.01186 7.38499 6.70592 7.46639 6.56743 7.70529C6.42894 7.94419 6.51033 8.25013 6.74923 8.38862L7.25077 7.52348ZM6.74923 8.38862L12.7492 11.8669L13.2508 11.0017L7.25077 7.52348L6.74923 8.38862Z"
      fill="#3C52EF"
    />
    <path
      d="M0.871445 11.9175C0.604586 11.8465 0.44581 11.5726 0.516809 11.3058L1.67381 6.95704C1.74481 6.69018 2.01869 6.53141 2.28555 6.60241C2.55241 6.67341 2.71119 6.94729 2.64019 7.21415L1.61175 11.0797L5.47727 12.1081C5.74413 12.1791 5.90291 12.453 5.83191 12.7199C5.76091 12.9867 5.48702 13.1455 5.22016 13.0745L0.871445 11.9175ZM6.74923 7.52348C6.98814 7.38499 7.29408 7.46639 7.43257 7.70529C7.57106 7.94419 7.48967 8.25013 7.25077 8.38862L6.74923 7.52348ZM7.25077 8.38862L1.25077 11.8669L0.749235 11.0017L6.74923 7.52348L7.25077 8.38862Z"
      fill="#3C52EF"
    />
    <path
      d="M6.5 8.04348C6.5 8.31962 6.72386 8.54348 7 8.54348C7.27614 8.54348 7.5 8.31962 7.5 8.04348H6.5ZM7.35355 0.646447C7.15829 0.451184 6.84171 0.451184 6.64645 0.646447L3.46447 3.82843C3.2692 4.02369 3.2692 4.34027 3.46447 4.53553C3.65973 4.7308 3.97631 4.7308 4.17157 4.53553L7 1.70711L9.82843 4.53553C10.0237 4.7308 10.3403 4.7308 10.5355 4.53553C10.7308 4.34027 10.7308 4.02369 10.5355 3.82843L7.35355 0.646447ZM7.5 8.04348V1H6.5V8.04348H7.5Z"
      fill="#3C52EF"
    />
  </svg>
)

export default TreatmentTrackerIcon
