import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import OggHeading40 from 'components/common/text/OggHeading40'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import Dentists from 'resources/referrals/Dentists.png'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles(() => ({
  container: {
    padding: isMobile() ? 0 : '0px 0px 0px 20px',
    backgroundColor: 'var(--text-color-54)'
  },
  title: {
    color: 'white',
    marginTop: isMobile() ? 40 : 0,
    marginBottom: isMobile() ? 10 : 0,
    fontSize: isMobile() ? 44 : 64
  },
  description: {
    color: 'white',
    whiteSpace: 'pre-line',
    fontSize: isMobile() ? 16 : 20,
    lineHeight: isMobile() ? '26px' : '32px',
    marginBottom: isMobile() ? 50 : 0
  },
  image: {
    width: '100%',
    maxWidth: 854,
    height: isMobile() ? 'auto' : '100%'
  },
  textContainer: {
    padding: isMobile() ? '0px 25px 0px 25px' : 0,
    height: '100%'
  }
}))

const TheFuture = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid
      container
      direction={isMobile() ? 'column-reverse' : 'row'}
      justifyContent="space-between"
      className={classes.container}
    >
      <Grid item xs={isMobile() ? 12 : 6}>
        <Grid container className={classes.textContainer} justifyContent="center" alignItems="center">
          <Grid item xs={isMobile() ? 12 : 7}>
            <Grid container direction="column" spacing={isMobile() ? 0 : 3}>
              <Grid item>
                <OggHeading40 textAlign="left" className={classes.title}>
                  {t('pages.referrals.footer.theFuture.title')}
                </OggHeading40>
              </Grid>
              <Grid item>
                <DazzedParagraph16 strong textAlign="left" className={classes.description}>
                  {t('pages.referrals.footer.theFuture.description')}
                </DazzedParagraph16>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={isMobile() ? 12 : 6}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <img src={Dentists} alt="" className={classes.image} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TheFuture
