import * as React from 'react'

const Mail = ({ color = 'black', ...props }) => {
  return (
    <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="18" height="13" rx="2" fill="#4151E6" />
      <path
        d="M1 1L8.4 6.55C8.75556 6.81667 9.24444 6.81667 9.6 6.55L17 1"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Mail
