import { TimelineItemTypes } from 'consts/timelineConsts'
import _ from 'lodash'
import moment from 'moment'
import { useCallback, useMemo } from 'react'

const MINI_TIMELINE_ITEM_TYPES = [TimelineItemTypes.GrinScan, TimelineItemTypes.Date]

/**
 * Creates lists of timeline items based on the patient TimelineItems to render in the mini timeline and main feed.
 * Each returned timeline item has the following fields: `date`, `type`, `data`.
 * @param patientTimelineItems the original patient TimelineItem entities.
 * @returns `{ miniTimelineItems: [], mainFeedItems: [], latestScanTimelineItem }`
 */
export default ({ patientTimelineItems = [] }) => {
  const rawTimelineItemMapper = useCallback(
    item => ({
      id: item.id,
      type: item.type,
      eventDate: item.eventDate,
      payload: JSON.parse(item.payload || '{}')
    }),
    []
  )

  const getItemGroupName = useCallback(
    timelineItem => moment(timelineItem.eventDate).startOf('month').toISOString(),
    []
  )

  const itemGroups = useMemo(
    () => _.groupBy(patientTimelineItems.map(rawTimelineItemMapper), getItemGroupName),
    [patientTimelineItems, getItemGroupName, rawTimelineItemMapper]
  )

  const mainFeedItems = useMemo(() => {
    const results = Object.keys(itemGroups)
      .map(date => [
        {
          id: date,
          type: TimelineItemTypes.Date,
          eventDate: date,
          payload: {
            totalScans: itemGroups[date].filter(timelineItem => timelineItem.type === TimelineItemTypes.GrinScan).length
          }
        },
        ...itemGroups[date]
      ])
      .flat()

    return _.sortBy(results, 'eventDate')
  }, [itemGroups])

  const miniTimelineItems = useMemo(
    () => mainFeedItems.filter(item => MINI_TIMELINE_ITEM_TYPES.includes(item.type)),
    [mainFeedItems]
  )

  const hasAnyScans = useMemo(
    () => mainFeedItems.some(item => item.type === TimelineItemTypes.GrinScan),
    [mainFeedItems]
  )

  return {
    miniTimelineItems,
    mainFeedItems,
    hasAnyScans
  }
}
