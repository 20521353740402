import React from 'react'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const DeleteScheduledMessagePopup = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation()

  return (
    <BaseModal
      variant="alert"
      open={isOpen}
      handleClose={onClose}
      onSecondaryBtnClick={onClose}
      onPrimaryBtnClick={onConfirm}
      secondaryLabel={t('general.cancel')}
      primaryLabel={t('general.delete')}
      title={t('pages.patients.selectedPatient.chat.deleteScheduledMessagePopup.title')}
    >
      <DazzedParagraph14 textAlign="center">
        {t('pages.patients.selectedPatient.chat.deleteScheduledMessagePopup.content')}
      </DazzedParagraph14>
    </BaseModal>
  )
}

export default DeleteScheduledMessagePopup
