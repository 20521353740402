const AUTOCHART_TOGGLE_DIALOG = 'AUTOCHART_TOGGLE_DIALOG'
const AUTOCHART_LOAD_DATA = 'AUTOCHART_LOAD_DATA'
const AUTOCHART_LOAD_DATA_RECEIVED = 'AUTOCHART_LOAD_DATA_RECEIVED'
const AUTOCHART_LOAD_DATA_FAILED = 'AUTOCHART_LOAD_DATA_FAILED'
const AUTOCHART_SET_TAB = 'AUTOCHART_SET_TAB'
const AUTOCHART_SAVE = 'AUTOCHART_SAVE'
const AUTOCHART_SAVE_RECEIVED = 'AUTOCHART_SAVE_RECEIVED'
const AUTOCHART_SAVE_FAILED = 'AUTOCHART_SAVE_FAILED'
const AUTOCHART_UPDATE_TOOTH_CONDITIONS = 'AUTOCHART_UPDATE_TOOTH_CONDITIONS'
const AUTOCHART_SET_GLOBAL_NOTES = 'AUTOCHART_SET_GLOBAL_NOTES'
const AUTOCHART_TOGGLE_RIGHT_PANEL = 'AUTOCHART_TOGGLE_RIGHT_PANEL'

const autochartToggleDialog = ({ isOpen, patientId, grinScanId }) => ({
  type: AUTOCHART_TOGGLE_DIALOG,
  payload: {
    isOpen,
    patientId,
    grinScanId
  }
})

const autochartLoadData = payload => ({
  type: AUTOCHART_LOAD_DATA,
  payload
})

const autochartLoadDataReceived = payload => ({
  type: AUTOCHART_LOAD_DATA_RECEIVED,
  payload
})

const autochartLoadDataFailed = payload => ({
  type: AUTOCHART_LOAD_DATA_FAILED,
  payload
})

const autochartSetTab = payload => ({
  type: AUTOCHART_SET_TAB,
  payload
})

const autochartSave = payload => ({
  type: AUTOCHART_SAVE,
  payload
})

const autochartSaveReceived = payload => ({
  type: AUTOCHART_SAVE_RECEIVED,
  payload
})

const autochartSaveFailed = payload => ({
  type: AUTOCHART_SAVE_FAILED,
  payload
})

const autochartUpdateToothConditions = payload => ({
  type: AUTOCHART_UPDATE_TOOTH_CONDITIONS,
  payload
})

const autochartSetGlobalNotes = payload => ({
  type: AUTOCHART_SET_GLOBAL_NOTES,
  payload
})

const autochartToggleRightPanel = payload => ({
  type: AUTOCHART_TOGGLE_RIGHT_PANEL,
  payload
})

export default {
  AUTOCHART_TOGGLE_DIALOG,
  AUTOCHART_LOAD_DATA,
  AUTOCHART_LOAD_DATA_RECEIVED,
  AUTOCHART_LOAD_DATA_FAILED,
  AUTOCHART_SET_TAB,
  AUTOCHART_SAVE,
  AUTOCHART_SAVE_RECEIVED,
  AUTOCHART_SAVE_FAILED,
  AUTOCHART_UPDATE_TOOTH_CONDITIONS,
  AUTOCHART_SET_GLOBAL_NOTES,
  AUTOCHART_TOGGLE_RIGHT_PANEL,
  autochartToggleDialog,
  autochartLoadData,
  autochartLoadDataReceived,
  autochartLoadDataFailed,
  autochartSetTab,
  autochartSave,
  autochartSaveReceived,
  autochartSaveFailed,
  autochartUpdateToothConditions,
  autochartSetGlobalNotes,
  autochartToggleRightPanel
}
