import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles, Grid, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PmsLinkPatientRow from './PmsLinkPatientRow'
import usePms from '../../../../hooks/usePms'
import { AsyncStatus } from 'consts'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import PmsAppointments from './PmsAppointments'

const useStyles = makeStyles({
  container: {
    paddingBottom: 32
  },
  section: {
    paddingTop: 24,
    paddingBottom: 24,
    borderBottom: '1px solid var(--border-color-10)'
  },
  loader: {
    position: 'absolute',
    top: '30%',
    left: '50%'
  },
  errorMessage: {
    position: 'absolute',
    top: '30%',
    left: '25%',
    whiteSpace: 'break-spaces'
  },
  newAppointment: {
    position: 'sticky',
    bottom: 10,
    marginTop: 10
  },
  noAppointmentsMessage: {
    padding: '20px 100px 0px 100px'
  },
  appointmentsLoader: {
    marginTop: 20
  }
})

const PmsAppointmentsTab = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { authenticationStatus, currentPatient } = useSelector(state => state.pmsReducer)

  const { patientPmsID, authenticatePms, loadPmsStaticData } = usePms()

  useEffect(() => {
    authenticatePms()
  }, [authenticatePms])

  useEffect(() => {
    if (authenticationStatus === AsyncStatus.Completed && patientPmsID) {
      loadPmsStaticData()
    }
  }, [authenticationStatus, loadPmsStaticData, patientPmsID])

  return (
    <Grid container className={classes.container} justifyContent="center">
      {authenticationStatus === AsyncStatus.Loading ? (
        <Grid item className={classes.loader}>
          <CircularProgress size={40} />
        </Grid>
      ) : authenticationStatus === AsyncStatus.Failed ? (
        <Grid item className={classes.errorMessage}>
          <DazzedParagraph16>{t('dialogs.patientInfo.appointments.authenticationFailedMessage')}</DazzedParagraph16>
        </Grid>
      ) : (
        authenticationStatus === AsyncStatus.Completed && (
          <Grid item xs={11}>
            <Grid container>
              <Grid item xs={12} className={classes.section}>
                <PmsLinkPatientRow patientPmsID={patientPmsID} />
              </Grid>
              {currentPatient && <PmsAppointments />}
            </Grid>
          </Grid>
        )
      )}
    </Grid>
  )
}

export default PmsAppointmentsTab
