import * as React from 'react'

const MedicalHistoryEmptyStateIcon = ({ ...props }) => {
  return (
    <svg width="77" height="97" viewBox="0 0 77 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 4H44V17H57V82H0V4Z" fill="#D2E4FF" />
      <path
        d="M23.0491 38.2456C24.6279 37.9294 25.9898 37.4756 25.9898 37.4756C25.4125 33.0489 20.6541 32.9999 20.6541 32.9999C18.6954 32.9999 17.0862 33.6677 15.8258 35.003C14.6087 36.3012 14.0002 37.9005 14.0002 39.8011C14.0002 41.6831 14.5871 43.2482 15.7609 44.4966C17.0214 45.8322 18.7048 46.4997 20.8116 46.4997C21.9729 46.4997 23.0232 46.3693 23.9624 46.1084C24.9014 45.8476 25.5809 45.5867 26.0013 45.3259L26.0075 39.5559C26.0075 39.4393 25.8962 39.3532 25.7854 39.3879C25.0356 39.6234 23.1815 40.1323 21.1175 40.4537V41.6328C21.8154 41.6453 22.2788 41.7074 22.5074 41.819C22.8595 41.9993 23.1913 42.378 23.1913 42.9555V43.5151C23.1913 43.9063 23.0268 44.2436 22.7949 44.469C22.4791 44.7758 21.9732 44.9813 21.1639 44.9813C19.6686 44.9813 18.437 44.2981 17.9892 43.2657C17.4518 42.0269 17.5208 41.344 17.5208 39.7541C17.5208 37.8162 17.5341 37.1646 18.1622 36.134C18.6705 35.3001 19.6609 34.9106 20.5155 34.9357C20.5155 34.9357 21.9745 34.8068 22.6501 36.1144C23.1335 37.0501 23.0491 38.2456 23.0491 38.2456Z"
        fill="#7498CE"
      />
      <path
        d="M36.8001 36.7007C37.2168 36.7007 37.566 36.5622 37.8478 36.2843C38.1293 36.0071 38.2702 35.67 38.2702 35.2735C38.2702 34.8546 38.1293 34.5035 37.8478 34.2202C37.566 33.9372 37.2168 33.7956 36.8001 33.7956C36.4057 33.7956 36.0705 33.9372 35.7947 34.2202C35.5186 34.5035 35.3806 34.8546 35.3806 35.2735C35.3806 35.6588 35.5212 35.993 35.803 36.276C36.0848 36.5593 36.4172 36.7007 36.8001 36.7007Z"
        fill="#7498CE"
      />
      <path
        d="M30.3683 37.4946C30.4835 37.4341 30.6205 37.5309 30.6205 37.6615L30.6182 39.5971C30.9576 39.1296 31.3626 38.7133 31.7902 38.3718C32.2175 38.0306 32.651 37.86 33.0913 37.86C33.9335 37.86 34.3549 38.1762 34.3549 38.808C34.3549 39.1369 34.2291 39.481 33.9777 39.8412C33.726 40.2015 33.4619 40.3817 33.1855 40.3817C33.0724 40.3817 32.9466 40.3533 32.8083 40.2964C32.6697 40.2395 32.5314 40.173 32.3934 40.0974C32.0413 39.9205 31.8024 39.8318 31.6768 39.8318C31.312 39.8318 31.0261 39.9677 30.8187 40.2395C30.6114 40.5116 30.5077 40.9884 30.5077 41.6708V44.5905C30.5077 44.6409 30.511 44.6878 30.5162 44.733V46.1778L27.781 46.1765V38.6183C27.781 38.6183 29.1603 38.1292 30.3683 37.4946Z"
        fill="#7498CE"
      />
      <path
        d="M35.5347 46.1772L35.5357 44.8833C35.536 44.8739 35.537 44.8651 35.537 44.8557V40.7603C35.537 40.7387 35.5362 40.7188 35.5357 40.698V38.6041C35.5357 38.6041 36.7959 38.1403 38.0193 37.5422C38.1389 37.4838 38.2782 37.5722 38.2782 37.706V40.3696C38.274 40.482 38.2712 40.5884 38.2712 40.6844V44.8366C38.2712 44.8664 38.274 44.8948 38.2782 44.9225V46.1772H35.5347Z"
        fill="#7498CE"
      />
      <path
        d="M45.2593 46.1707V44.9184C45.2617 44.8866 45.2632 44.8534 45.2632 44.8179V41.0828C45.2632 41.0353 45.2614 40.9894 45.2593 40.9438L45.2394 40.7194C45.1992 40.4213 45.1068 40.1751 44.9615 39.9828C44.7601 39.7175 44.4963 39.5847 44.1694 39.5847C43.9431 39.5847 43.7355 39.6197 43.5469 39.6891C43.3583 39.7587 43.151 39.8691 42.9245 40.0209C42.8238 40.0843 42.7737 40.198 42.7737 40.3621V44.8174C42.7737 44.8513 42.775 44.8829 42.7771 44.9132V46.1775L40.0396 46.1762V38.6256C41.3394 38.1349 42.2047 37.7236 42.6074 37.5206C42.7275 37.4601 42.8679 37.5483 42.8679 37.6834V39.1869C43.3456 38.7448 43.7918 38.4161 44.207 38.2012C44.6219 37.9865 45.0995 37.8788 45.6402 37.8788C46.3314 37.8788 46.8973 38.1253 47.3374 38.6183C47.7774 39.1113 47.9974 39.7749 47.9974 40.6091V44.8182C47.9974 44.8443 47.9982 44.8691 47.9998 44.8931V46.177L45.2591 46.1765"
        fill="#7498CE"
      />
      <path
        d="M3 1L3 0H2V1H3ZM44 17H43V18H44V17ZM60 79V80H61V79H60ZM3 79H2V80H3V79ZM44 1H45C45 0.447715 44.5523 0 44 0V1ZM60 17H61C61 16.4477 60.5523 16 60 16V17ZM60 78H3V80H60V78ZM4 79V1H2V79H4ZM3 2H44V0H3L3 2ZM43 1V17H45V1H43ZM44 18H60V16H44V18ZM59 17V79H61V17H59Z"
        fill="#7498CE"
      />
      <path d="M44 1L60 17" stroke="#7498CE" strokeWidth="2" />
      <rect x="65" y="77" width="4" height="20" fill="#7498CE" />
      <rect x="77" y="85" width="4" height="20" transform="rotate(90 77 85)" fill="#7498CE" />
    </svg>
  )
}

export default MedicalHistoryEmptyStateIcon
