const PATIENT_MESSAGES_REQUESTED = 'PATIENT_MESSAGES_REQUESTED'
const PATIENT_MESSAGES_RECEIVED = 'PATIENT_MESSAGES_RECEIVED'
const PATIENT_MESSAGES_FAILED = 'PATIENT_MESSAGES_FAILED'
const PATIENT_IMAGE_MESSAGE_CREATE_REQUESTED = 'PATIENT_IMAGE_MESSAGE_CREATE_REQUESTED'
const CREATE_SCAN_SUMMARY_IMAGE_MESSAGE = 'CREATE_SCAN_SUMMARY_IMAGE_MESSAGE'
const PATIENT_MESSAGE_CREATE_RECEIVED = 'PATIENT_MESSAGE_CREATE_RECEIVED'
const MESSAGE_ADDED = 'MESSAGE_ADDED'
const SCHEDULED_MESSAGE_ADDED = 'SCHEDULED_MESSAGE_ADDED'
const NEW_MESSAGE_PUSH_SENT = 'NEW_MESSAGE_PUSH_SENT'
const FETCH_ROOMS = 'FETCH_ROOMS'
const ROOMS_RECEIVED = 'ROOMS_RECEIVED'
const FETCH_ROOMS_FAILED = 'FETCH_ROOMS_FAILED'
const MARK_ROOM_READ = 'MARK_ROOM_READ'
const MARK_ROOM_READ_RECEIVED = 'MARK_ROOM_READ_RECEIVED'
const MARK_ROOM_READ_FAILED = 'MARK_ROOM_READ_FAILED'
const UNREAD_MESSAGES_REQUESTED = 'UNREAD_MESSAGES_REQUESTED'
const UPDATE_ROOM_READ_STATUS = 'UPDATE_ROOM_READ_STATUS'
const ADD_NEW_PATIENT_CHAT = 'ADD_NEW_PATIENT_CHAT'
const UPDATE_ROOM = 'UPDATE_ROOM'
const TIMELINE_SELECTED = 'TIMELINE_SELECTED'
const CHAT_ROOM_SELECTED = 'CHAT_ROOM_SELECTED'
const MARK_ROOM_UNREAD = 'MARK_ROOM_UNREAD'
const PIN_CHAT_ROOM = 'PIN_CHAT_ROOM'
const UNPIN_CHAT_ROOM = 'UNPIN_CHAT_ROOM'
const REMOVE_CHAT_ROOM = 'REMOVE_CHAT_ROOM'
const DELETE_MESSAGE = 'DELETE_MESSAGE'
const DELETED_MESSAGE_RECEIVED = 'DELETED_MESSAGE_RECEIVED'
const DELETE_MESSAGE_FAILED = 'DELETE_MESSAGE_FAILED'
const DELETE_MESSAGE_NOTIFICATION_FAILED = 'DELETE_MESSAGE_NOTIFICATION_FAILED'
const COPY_MESSAGE = 'COPY_MESSAGE'
const SAVE_PROMOTION = 'SAVE_PROMOTION'
const SAVE_PROMOTION_RECEIVED = 'SAVE_PROMOTION_RECEIVED'
const SAVE_PROMOTION_FAILED = 'SAVE_PROMOTION_FAILED'
const FETCH_LAST_PROMOTION_MESSAGES = 'FETCH_LAST_PROMOTION_MESSAGES'
const LAST_PROMOTION_MESSAGES_RECEIVED = 'LAST_PROMOTION_MESSAGES_RECEIVED'
const LAST_PROMOTION_MESSAGES_FAILED = 'LAST_PROMOTION_MESSAGES_FAILED'
const TOGGLE_BROADCAST_MODE = 'TOGGLE_BROADCAST_MODE'
const SELECT_PATIENT_FOR_BROADCAST = 'SELECT_PATIENT_FOR_BROADCAST'
const UNSELECT_PATIENT_FOR_BROADCAST = 'UNSELECT_PATIENT_FOR_BROADCAST'
const TOGGLE_BROADCAST_MODAL = 'TOGGLE_BROADCAST_MODAL'
const TOGGLE_BROADCAST_SELECT_ALL = 'TOGGLE_BROADCAST_SELECT_ALL'
const TOGGLE_BROADCAST_PERSONAL_MESSAGE = 'TOGGLE_BROADCAST_PERSONAL_MESSAGE'
const CREATE_BROADCAST_MESSAGE = 'CREATE_BROADCAST_MESSAGE'
const CREATE_BROADCAST_MESSAGE_RECEIVED = 'CREATE_BROADCAST_MESSAGE_RECEIVED'
const CREATE_BROADCAST_MESSAGE_FAILED = 'CREATE_BROADCAST_MESSAGE_FAILED'
const CREATE_BROADCAST_FILE_MESSAGE = 'CREATE_BROADCAST_FILE_MESSAGE'
const CREATE_BROADCAST_FILE_MESSAGE_FAILED = 'CREATE_BROADCAST_FILE_MESSAGE_FAILED'
const RESET_BROADCAST_STATE = 'RESET_BROADCAST_STATE'
const CREATE_BROADCAST_LOCAL_MESSAGE = 'CREATE_BROADCAST_LOCAL_MESSAGE'
const SEND_SELECTED_SCAN_SUMMARY_IMAGES = 'SEND_SELECTED_SCAN_SUMMARY_IMAGES'
const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE'
const SEND_CHAT_MESSAGE_FAILED = 'SEND_CHAT_MESSAGE_FAILED'
const SCHEDULED_MESSAGE_SENT = 'SCHEDULED_MESSAGE_SENT'
const DELETE_SCHEDULED_MESSAGE = 'DELETE_SCHEDULED_MESSAGE'
const DELETE_SCHEDULED_MESSAGE_RECEIVED = 'DELETE_SCHEDULED_MESSAGE_RECEIVED'
const DELETE_SCHEDULED_MESSAGE_FAILED = 'DELETE_SCHEDULED_MESSAGE_FAILED'
const SOFT_DELETE_CHAT_MESSAGE = 'SOFT_DELETE_CHAT_MESSAGE'
const SOFT_DELETE_CHAT_MESSAGE_RECEIVED = 'SOFT_DELETE_CHAT_MESSAGE_RECEIVED'
const SOFT_DELETE_CHAT_MESSAGE_FAILED = 'SOFT_DELETE_CHAT_MESSAGE_FAILED'
const TOGGLE_UPLOAD_FILE_SIZE_EXCEEDED = 'TOGGLE_UPLOAD_FILE_SIZE_EXCEEDED'

const requestPatientMessages = (roomId, nextToken, method = 'replace') => ({
  type: PATIENT_MESSAGES_REQUESTED,
  payload: { roomId, nextToken, method }
})

const patientMessagesReceived = payload => ({
  type: PATIENT_MESSAGES_RECEIVED,
  payload
})

const patientMessagesFailed = payload => ({
  type: PATIENT_MESSAGES_FAILED,
  payload
})

const createScanSummaryImageMessage = payload => ({
  type: CREATE_SCAN_SUMMARY_IMAGE_MESSAGE,
  payload
})

const requestPatientImageMessageCreate = payload => ({
  type: PATIENT_IMAGE_MESSAGE_CREATE_REQUESTED,
  payload
})

const timelineSelected = payload => ({
  type: TIMELINE_SELECTED,
  payload
})

const chatRoomSelected = payload => ({
  type: CHAT_ROOM_SELECTED,
  payload
})

const patientMessageCreateReceived = payload => ({
  type: PATIENT_MESSAGE_CREATE_RECEIVED,
  payload
})

const messageAdded = message => ({
  type: MESSAGE_ADDED,
  payload: message
})

const scheduledMessageAdded = message => ({
  type: SCHEDULED_MESSAGE_ADDED,
  payload: message
})

const newMessagePushSent = () => ({
  type: NEW_MESSAGE_PUSH_SENT
})

const fetchRooms = payload => ({
  type: FETCH_ROOMS,
  payload
})

const roomsReceived = payload => ({
  type: ROOMS_RECEIVED,
  payload
})

const fetchRoomsFailed = payload => ({
  type: FETCH_ROOMS_FAILED,
  payload
})

const markRoomRead = payload => ({
  type: MARK_ROOM_READ,
  payload
})

const markRoomReadFailed = payload => ({
  type: MARK_ROOM_READ_FAILED,
  payload
})

const markRoomReadReceived = payload => ({
  type: MARK_ROOM_READ_RECEIVED,
  payload
})

const updateRoomReadStatus = payload => ({
  type: UPDATE_ROOM_READ_STATUS,
  payload
})

const addNewPatientChat = payload => ({
  type: ADD_NEW_PATIENT_CHAT,
  payload
})

const updateRoom = payload => ({
  type: UPDATE_ROOM,
  payload
})

const markRoomUnread = payload => ({
  type: MARK_ROOM_UNREAD,
  payload
})

const pinChatRoom = payload => ({
  type: PIN_CHAT_ROOM,
  payload
})

const unpinChatRoom = () => ({
  type: UNPIN_CHAT_ROOM
})

const removeChatRoom = payload => ({
  type: REMOVE_CHAT_ROOM,
  payload
})

const deleteMessage = payload => ({
  type: DELETE_MESSAGE,
  payload
})

const deletedMessageReceived = payload => ({
  type: DELETED_MESSAGE_RECEIVED,
  payload
})

const deleteMessageFailed = payload => ({
  type: DELETE_MESSAGE_FAILED
})

const deleteMessageNotificationFailed = payload => ({
  type: DELETE_MESSAGE_NOTIFICATION_FAILED
})

const copyMessage = payload => ({
  type: COPY_MESSAGE,
  payload
})

const savePromotion = payload => ({
  type: SAVE_PROMOTION,
  payload
})

const savePromotionFailed = payload => ({
  type: SAVE_PROMOTION_FAILED,
  payload
})

const savePromotionReceived = payload => ({
  type: SAVE_PROMOTION_RECEIVED,
  payload
})

const fetchLastPromotionMessages = payload => ({
  type: FETCH_LAST_PROMOTION_MESSAGES,
  payload
})

const lastPromotionMessagesReceived = payload => ({
  type: LAST_PROMOTION_MESSAGES_RECEIVED,
  payload
})

const lastPromotionMessagesFailed = payload => ({
  type: LAST_PROMOTION_MESSAGES_FAILED,
  payload
})

const toggleBroadcastMode = payload => ({
  type: TOGGLE_BROADCAST_MODE,
  payload
})

const selectPatientForBroadcast = payload => ({
  type: SELECT_PATIENT_FOR_BROADCAST,
  payload
})

const unselectPatientForBroadcast = payload => ({
  type: UNSELECT_PATIENT_FOR_BROADCAST,
  payload
})

const toggleBroadcastSelectAll = payload => ({
  type: TOGGLE_BROADCAST_SELECT_ALL,
  payload
})

const toggleBroadcastModal = payload => ({
  type: TOGGLE_BROADCAST_MODAL,
  payload
})

const createBroadcastMessage = payload => ({
  type: CREATE_BROADCAST_MESSAGE,
  payload
})

const createBroadcastMessageReceived = payload => ({
  type: CREATE_BROADCAST_MESSAGE_RECEIVED,
  payload
})

const createBroadcastMessageFailed = payload => ({
  type: CREATE_BROADCAST_MESSAGE_FAILED,
  payload
})

const createBroadcastFileMessage = payload => ({
  type: CREATE_BROADCAST_FILE_MESSAGE,
  payload
})

const createBroadcastFileMessageFailed = payload => ({
  type: CREATE_BROADCAST_FILE_MESSAGE_FAILED,
  payload
})

const resetBroadcastState = payload => ({
  type: RESET_BROADCAST_STATE,
  payload
})

const createBroadcastLocalMessage = payload => ({
  type: CREATE_BROADCAST_LOCAL_MESSAGE,
  payload
})

const toggleBroadcastPersonalMessage = payload => ({
  type: TOGGLE_BROADCAST_PERSONAL_MESSAGE,
  payload
})

const sendSelectedScanSummaryImages = payload => ({
  type: SEND_SELECTED_SCAN_SUMMARY_IMAGES,
  payload
})

const sendChatMessage = payload => ({
  type: SEND_CHAT_MESSAGE,
  payload
})

const scheduledMessageSent = payload => ({
  type: SCHEDULED_MESSAGE_SENT,
  payload
})

const sendChatMessageFailed = payload => ({
  type: SEND_CHAT_MESSAGE_FAILED,
  payload
})

const deleteScheduledMessage = payload => ({
  type: DELETE_SCHEDULED_MESSAGE,
  payload
})

const deleteScheduledMessageReceived = payload => ({
  type: DELETE_SCHEDULED_MESSAGE_RECEIVED,
  payload
})

const deleteScheduledMessageFailed = payload => ({
  type: DELETE_SCHEDULED_MESSAGE_FAILED,
  payload
})

const softDeleteChatMessage = payload => ({
  type: SOFT_DELETE_CHAT_MESSAGE,
  payload
})

const softDeleteChatMessageReceived = payload => ({
  type: SOFT_DELETE_CHAT_MESSAGE_RECEIVED,
  payload
})

const softDeleteChatMessageFailed = payload => ({
  type: SOFT_DELETE_CHAT_MESSAGE_FAILED,
  payload
})

const toggleUploadFileSizeExceeded = payload => ({
  type: TOGGLE_UPLOAD_FILE_SIZE_EXCEEDED,
  payload
})

export default {
  PATIENT_MESSAGES_REQUESTED,
  PATIENT_MESSAGES_RECEIVED,
  PATIENT_MESSAGES_FAILED,
  CREATE_SCAN_SUMMARY_IMAGE_MESSAGE,
  PATIENT_IMAGE_MESSAGE_CREATE_REQUESTED,
  PATIENT_MESSAGE_CREATE_RECEIVED,
  NEW_MESSAGE_PUSH_SENT,
  MESSAGE_ADDED,
  SCHEDULED_MESSAGE_ADDED,
  FETCH_ROOMS,
  ROOMS_RECEIVED,
  FETCH_ROOMS_FAILED,
  MARK_ROOM_READ,
  MARK_ROOM_READ_RECEIVED,
  MARK_ROOM_READ_FAILED,
  UNREAD_MESSAGES_REQUESTED,
  UPDATE_ROOM_READ_STATUS,
  ADD_NEW_PATIENT_CHAT,
  UPDATE_ROOM,
  TIMELINE_SELECTED,
  CHAT_ROOM_SELECTED,
  MARK_ROOM_UNREAD,
  PIN_CHAT_ROOM,
  UNPIN_CHAT_ROOM,
  REMOVE_CHAT_ROOM,
  DELETE_MESSAGE,
  DELETED_MESSAGE_RECEIVED,
  DELETE_MESSAGE_FAILED,
  DELETE_MESSAGE_NOTIFICATION_FAILED,
  COPY_MESSAGE,
  SAVE_PROMOTION,
  SAVE_PROMOTION_FAILED,
  SAVE_PROMOTION_RECEIVED,
  FETCH_LAST_PROMOTION_MESSAGES,
  LAST_PROMOTION_MESSAGES_RECEIVED,
  LAST_PROMOTION_MESSAGES_FAILED,
  TOGGLE_BROADCAST_MODE,
  UNSELECT_PATIENT_FOR_BROADCAST,
  SELECT_PATIENT_FOR_BROADCAST,
  TOGGLE_BROADCAST_SELECT_ALL,
  TOGGLE_BROADCAST_MODAL,
  CREATE_BROADCAST_MESSAGE,
  CREATE_BROADCAST_MESSAGE_RECEIVED,
  CREATE_BROADCAST_MESSAGE_FAILED,
  CREATE_BROADCAST_FILE_MESSAGE,
  CREATE_BROADCAST_FILE_MESSAGE_FAILED,
  RESET_BROADCAST_STATE,
  CREATE_BROADCAST_LOCAL_MESSAGE,
  TOGGLE_BROADCAST_PERSONAL_MESSAGE,
  SEND_SELECTED_SCAN_SUMMARY_IMAGES,
  SEND_CHAT_MESSAGE,
  SEND_CHAT_MESSAGE_FAILED,
  SCHEDULED_MESSAGE_SENT,
  DELETE_SCHEDULED_MESSAGE,
  DELETE_SCHEDULED_MESSAGE_FAILED,
  DELETE_SCHEDULED_MESSAGE_RECEIVED,
  SOFT_DELETE_CHAT_MESSAGE,
  SOFT_DELETE_CHAT_MESSAGE_RECEIVED,
  SOFT_DELETE_CHAT_MESSAGE_FAILED,
  TOGGLE_UPLOAD_FILE_SIZE_EXCEEDED,
  toggleUploadFileSizeExceeded,
  requestPatientMessages,
  patientMessagesReceived,
  createScanSummaryImageMessage,
  requestPatientImageMessageCreate,
  patientMessageCreateReceived,
  newMessagePushSent,
  messageAdded,
  scheduledMessageAdded,
  fetchRooms,
  roomsReceived,
  fetchRoomsFailed,
  markRoomRead,
  markRoomReadReceived,
  markRoomReadFailed,
  updateRoomReadStatus,
  addNewPatientChat,
  updateRoom,
  timelineSelected,
  chatRoomSelected,
  markRoomUnread,
  pinChatRoom,
  unpinChatRoom,
  removeChatRoom,
  deleteMessage,
  deletedMessageReceived,
  deleteMessageFailed,
  deleteMessageNotificationFailed,
  copyMessage,
  savePromotion,
  savePromotionFailed,
  savePromotionReceived,
  patientMessagesFailed,
  fetchLastPromotionMessages,
  lastPromotionMessagesReceived,
  lastPromotionMessagesFailed,
  toggleBroadcastMode,
  selectPatientForBroadcast,
  unselectPatientForBroadcast,
  toggleBroadcastSelectAll,
  toggleBroadcastModal,
  createBroadcastMessage,
  createBroadcastMessageReceived,
  createBroadcastMessageFailed,
  createBroadcastFileMessage,
  createBroadcastFileMessageFailed,
  resetBroadcastState,
  createBroadcastLocalMessage,
  toggleBroadcastPersonalMessage,
  sendSelectedScanSummaryImages,
  sendChatMessage,
  sendChatMessageFailed,
  scheduledMessageSent,
  deleteScheduledMessage,
  deleteScheduledMessageFailed,
  deleteScheduledMessageReceived,
  softDeleteChatMessage,
  softDeleteChatMessageReceived,
  softDeleteChatMessageFailed
}
