import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CircularProgress, Grid, IconButton, makeStyles } from '@material-ui/core'
import DazzedHeading14 from 'components/common/text/DazzedHeading14'
import SelectInput from 'components/common/SelectInput'
import useRcShipping from '../useRcShipping'
import { Checkmark, Copy, Refresh } from 'components/common/icons'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { isEmpty } from 'lodash'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { getEnvironment } from 'utils/awsUtils'
import ShipbobActionButton from './ShipbobActionButton'
import ShipbobStatusInformationBox from './ShipbobStatusInformationBox'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles(theme => ({
  tooltipIcon: {
    height: '18px'
  }
}))

const ShipbobIntegration = ({ leadId, values, shipbobIntegrationActive, shippingDetails }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { shippingMethodOptions, shipbobWebUrl, inventoryItems } = useRcShipping()
  const { isCreatingOrder, isSimulatingShipment, isRefreshingLead } = useSelector(
    state => state.rcDashboardReducer.shippingDetailsDialog
  )

  const [errors, setErrors] = useState({})

  const editable = useMemo(() => !shipbobIntegrationActive, [shipbobIntegrationActive])
  const simulatable = useMemo(
    () =>
      getEnvironment() !== 'production' &&
      (shippingDetails?.status === 'processing' || shippingDetails?.status === 'shipped'),
    [shippingDetails?.status]
  )

  const refreshLead = useCallback(() => {
    if (!isRefreshingLead) {
      dispatch(Actions.reloadLeadData({ leadId }))
    }
  }, [dispatch, leadId, isRefreshingLead])

  const validateInputs = useCallback(() => {
    const errors = {}

    if (!values.shippingMethod) {
      errors.shippingMethod = t('errors.requiredField')
    }

    if (!values.inventoryItemId) {
      errors.inventoryItemId = t('errors.requiredField')
    }

    return errors
  }, [values, t])

  const handleCreateOrder = useCallback(() => {
    const errors = validateInputs()
    setErrors(errors)

    if (!isEmpty(errors)) {
      return
    }

    dispatch(
      Actions.createRcShipbobOrder({
        leadId,
        shippingMethod: values.shippingMethod,
        inventoryItemId: values.inventoryItemId?.value,
        productName: values.inventoryItemId?.originalName
      })
    )
  }, [leadId, values, dispatch, validateInputs])

  const simulateShipment = useCallback(
    action => {
      dispatch(
        Actions.simulateOrderShipment({
          shipmentId: shippingDetails.shipbobShipmentId,
          action
        })
      )
    },
    [dispatch, shippingDetails]
  )

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <DazzedHeading14>{t('pages.rcDashboard.dialogs.shippingDetails.shipbobSection.title')}</DazzedHeading14>
          &nbsp; &nbsp;
          <Tooltip
            arrow
            placement="bottom"
            value={t('pages.rcDashboard.dialogs.shippingDetails.shipbobSection.activeIntegrationTooltip')}
            wrapperClassName={classes.tooltipIcon}
          >
            {shipbobIntegrationActive && <Checkmark />}
          </Tooltip>
          &nbsp;
          <Tooltip
            arrow
            placement="bottom"
            value={t('pages.rcDashboard.dialogs.shippingDetails.shipbobSection.reloadButtonTooltip')}
          >
            <IconButton size="small" onClick={refreshLead}>
              {isRefreshingLead ? <CircularProgress size={16} /> : <Refresh color="var(--text-color-11)" />}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12}></Grid>
      {shippingDetails?.shipbobStatusInformation && (
        <Grid item xs={12}>
          <ShipbobStatusInformationBox
            status={shippingDetails.status}
            statusInformation={shippingDetails.shipbobStatusInformation}
          />
        </Grid>
      )}
      <Grid item xs={12}></Grid>
      <Grid item xs={6}>
        <SelectInput
          label={t('pages.rcDashboard.dialogs.shippingDetails.shipbobSection.shippingMethod')}
          style={{ bright: true, thick: true }}
          value={values.shippingMethod}
          values={shippingMethodOptions}
          errorMessage={errors.shippingMethod}
          disabled={!editable}
          onChange={({ value }) => values.setShippingMethod(value)}
          keepErrorContainerWhenInactive={false}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          label={t('pages.rcDashboard.dialogs.shippingDetails.shipbobSection.inventoryItemId')}
          style={{ bright: true, thick: true }}
          value={values.inventoryItemId?.label}
          errorMessage={errors.inventoryItemId}
          values={inventoryItems?.map(item => item.label)}
          disabled={!editable}
          onChange={({ value }) => values.setInventoryItemId(inventoryItems.find(item => item.label === value))}
          keepErrorContainerWhenInactive={false}
        />
      </Grid>
      {shipbobIntegrationActive && (
        <>
          <Grid item xs={6}>
            <DazzedParagraph14>
              <Trans
                i18nKey={'pages.rcDashboard.dialogs.shippingDetails.shipbobSection.shipbobOrderId'}
                values={{
                  orderId: shippingDetails?.shipbobReferenceId
                }}
              />
              &nbsp;&nbsp;&nbsp;
              <IconButton
                size="small"
                onClick={() => navigator.clipboard.writeText(shippingDetails?.shipbobReferenceId)}
              >
                <Copy color="var(--text-color-11)" />
              </IconButton>
            </DazzedParagraph14>
          </Grid>
          <Grid item xs={6}>
            <DazzedParagraph14 strong>
              {t('pages.rcDashboard.dialogs.shippingDetails.shipbobSection.shipbobShipmentId')}
              <a
                href={`${shipbobWebUrl}/Orders/${shippingDetails?.shipbobShipmentId}`}
                target="_blank"
                rel="noreferrer"
              >
                {shippingDetails?.shipbobShipmentId}
              </a>
            </DazzedParagraph14>
          </Grid>
          <Grid item xs={6}>
            <Trans
              i18nKey={'pages.rcDashboard.dialogs.shippingDetails.shipbobSection.lastUpdateTime'}
              values={{
                time: moment(shippingDetails.lastStatusUpdate).format('MMM DD YYYY, h:mm a')
              }}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          {simulatable && (
            <>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <DazzedParagraph14 bold>
                  {t('pages.rcDashboard.dialogs.shippingDetails.shipbobSection.simulate')}
                </DazzedParagraph14>
                {shippingDetails?.status === 'processing' && (
                  <ShipbobActionButton
                    onClick={() => simulateShipment('ShipOrder')}
                    label={t('pages.rcDashboard.dialogs.shippingDetails.shipbobSection.simulateOrderShipped')}
                    isLoading={isSimulatingShipment}
                  />
                )}
                {shippingDetails?.status === 'shipped' && (
                  <ShipbobActionButton
                    onClick={() => simulateShipment('DeliverOrder')}
                    label={t('pages.rcDashboard.dialogs.shippingDetails.shipbobSection.simulateOrderDelivered')}
                    isLoading={isSimulatingShipment}
                  />
                )}
              </Grid>
            </>
          )}
        </>
      )}
      {editable && (
        <Grid item xs={12}>
          <PrimaryButton
            isLoading={isCreatingOrder}
            width={130}
            label={t('pages.rcDashboard.dialogs.shippingDetails.shipbobSection.createOrderButton')}
            onClick={handleCreateOrder}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default ShipbobIntegration
