import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import AutochartTable from './AutochartTable'
import AutochartGlobalNotes from './AutochartGlobalNotes'

const useStyles = makeStyles(theme => ({
  tableContainer: {
    flex: 1
  }
}))

const AutochartTableTable = () => {
  const classes = useStyles()

  return (
    <Grid container direction="column" spacing={1} justifyContent="space-between">
      <Grid item className={classes.tableContainer}>
        <AutochartTable />
      </Grid>
      <Grid item>
        <AutochartGlobalNotes />
      </Grid>
    </Grid>
  )
}

export default AutochartTableTable
