import React, { useState, useCallback, useRef } from 'react'
import { makeStyles, Menu, Grid, MenuItem } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'

const useAppProfileStyles = makeStyles({
  appProfile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    },
    marginLeft: 15,
    position: 'relative'
  },
  buttonContainer: {
    padding: '10px',
    textAlign: 'center'
  },
  menu: {
    width: '246px'
  },
  menuItem: {
    fontSize: '16px',
    lineHeight: '25px',
    color: 'var(--primary-color)',
    height: '45px',
    '&:hover': {
      fontWeight: 600
    }
  }
})

const GIProfileMenu = () => {
  const classes = useAppProfileStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { name } = useSelector(state => state.profileReducer.doctor)
  const anchorEl = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleMenuClick = useCallback(() => {
    setIsOpen(true)
  }, [])

  return (
    <Grid container>
      <Grid item id="header-menu" className={classes.appProfile} onClick={handleMenuClick} ref={anchorEl}>
        <DazzedHeading20 light>{name}</DazzedHeading20>
        <ArrowDropDownIcon />
      </Grid>
      <Grid item>
        <Menu
          anchorEl={anchorEl?.current}
          keepMounted
          open={isOpen}
          onClose={handleClose}
          classes={{ paper: classes.menu }}
        >
          <MenuItem
            id="header-menu-logout"
            classes={{ root: classes.menuItem }}
            onClick={() => {
              dispatch(Actions.requestSignOut())
              handleClose()
            }}
          >
            {t('common.appMenu.logOut')}
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  )
}

export default GIProfileMenu
