import * as React from 'react'

const ArchViewerRight = () => (
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 7C20 3.13401 16.866 0 13 0H3.71622C2.67968 0 1.74757 0.63107 1.36261 1.59347C1.12307 2.19232 1 2.83138 1 3.47636V7V10.5236C1 11.1686 1.12307 11.8077 1.36261 12.4065C1.74757 13.3689 2.67968 14 3.71622 14H13C16.866 14 20 10.866 20 7Z"
      fill="#4151E6"
    />
    <path d="M5 5C5 3.89543 4.10457 3 3 3V3C1.89543 3 1 3.89543 1 5V7H5V5Z" stroke="#EFF6FF" />
    <path d="M5 9C5 10.1046 4.10457 11 3 11V11C1.89543 11 1 10.1046 1 9V7H5V9Z" stroke="#EFF6FF" />
    <path d="M9 5C9 3.89543 8.10457 3 7 3V3C5.89543 3 5 3.89543 5 5V7H9V5Z" stroke="#EFF6FF" />
    <path d="M9 9C9 10.1046 8.10457 11 7 11V11C5.89543 11 5 10.1046 5 9V7H9V9Z" stroke="#EFF6FF" />
    <path d="M13 5C13 3.89543 12.1046 3 11 3V3C9.89543 3 9 3.89543 9 5V7H13V5Z" stroke="#EFF6FF" />
    <path d="M13 9C13 10.1046 12.1046 11 11 11V11C9.89543 11 9 10.1046 9 9V7H13V9Z" stroke="#EFF6FF" />
    <path d="M17 6C17 4.89543 16.1046 4 15 4V4C13.8954 4 13 4.89543 13 6V7H17V6Z" stroke="#EFF6FF" />
    <path d="M17 8C17 9.10457 16.1046 10 15 10V10C13.8954 10 13 9.10457 13 8V7H17V8Z" stroke="#EFF6FF" />
  </svg>
)

export default ArchViewerRight
