import React from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import { isMobile } from 'react-device-detect'
import DazzedHeading36 from '../../common/text/DazzedHeading36'

const useStyles = makeStyles(
  theme => ({
    root: {
      textAlign: ({ isMobile }) => (isMobile ? 'start!important' : 'center!important'),
      padding: ({ isMobile }) => (isMobile ? '0 20px' : 0)
    },
    index: {
      fontSize: '40px',
      fontWeight: 'normal',
      marginBottom: 8
    }
  }),
  { isMobile }
)

const OnboardingStepText = ({ text, index }) => {
  const classes = useStyles({ isMobile })

  return (
    <div className={classes.root}>
      <DazzedHeading36 className={classes.index}>0{index}</DazzedHeading36>
      <DazzedParagraph16 textAlign={isMobile ? 'start' : 'center'}>{text}</DazzedParagraph16>
    </div>
  )
}

export default OnboardingStepText
