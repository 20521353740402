import * as React from 'react'

export default () => {
  return (
    <svg width="79" height="28" viewBox="0 0 79 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="68" cy="14" r="9.5" fill="white" stroke="white" strokeWidth="3" />
      <circle cx="68" cy="14" r="8" stroke="#4151E6" />
      <path
        d="M66.9656 16.1577V16.0637C66.9722 15.4499 67.0333 14.9614 67.1488 14.5984C67.2676 14.2354 67.4359 13.9417 67.6537 13.7173C67.8715 13.4929 68.1339 13.2883 68.4408 13.1035C68.6388 12.9781 68.817 12.8378 68.9754 12.6827C69.1338 12.5276 69.2592 12.3494 69.3516 12.1481C69.444 11.9468 69.4902 11.7241 69.4902 11.4798C69.4902 11.1861 69.4209 10.932 69.2823 10.7175C69.1437 10.503 68.9589 10.338 68.7279 10.2225C68.5002 10.1037 68.2461 10.0443 67.9656 10.0443C67.7115 10.0443 67.4689 10.0971 67.2379 10.2027C67.0069 10.3083 66.8155 10.4733 66.6637 10.6977C66.5119 10.9188 66.4244 11.2043 66.4013 11.5541H64.8965C64.9196 10.9601 65.0697 10.4585 65.3469 10.0493C65.6242 9.63675 65.9905 9.32489 66.4459 9.11368C66.9046 8.90248 67.4112 8.79688 67.9656 8.79688C68.5728 8.79688 69.1041 8.91073 69.5595 9.13843C70.0149 9.36284 70.368 9.678 70.6188 10.0839C70.8729 10.4865 71 10.9568 71 11.4947C71 11.8643 70.9422 12.1976 70.8267 12.4946C70.7112 12.7883 70.5462 13.0507 70.3317 13.2817C70.1205 13.5127 69.8664 13.7173 69.5694 13.8955C69.2889 14.0704 69.0612 14.2519 68.8863 14.44C68.7147 14.6281 68.5893 14.8509 68.5101 15.1083C68.4309 15.3657 68.388 15.6842 68.3814 16.0637V16.1577H66.9656ZM67.7131 19.1674C67.4425 19.1674 67.2098 19.0717 67.0151 18.8803C66.8204 18.6856 66.7231 18.4513 66.7231 18.1774C66.7231 17.9068 66.8204 17.6758 67.0151 17.4844C67.2098 17.2897 67.4425 17.1923 67.7131 17.1923C67.9804 17.1923 68.2114 17.2897 68.4061 17.4844C68.6041 17.6758 68.7031 17.9068 68.7031 18.1774C68.7031 18.3589 68.6569 18.5255 68.5645 18.6773C68.4754 18.8258 68.3566 18.9446 68.2081 19.0338C68.0596 19.1229 67.8946 19.1674 67.7131 19.1674Z"
        fill="#4151E6"
      />
      <path
        d="M14 1C6.8203 1 1 6.8203 1 14C1 21.1797 6.8203 27 14 27H41C48.1797 27 54 21.1797 54 14C54 6.8203 48.1797 1 41 1H14Z"
        fill="#041333"
        stroke="white"
        strokeWidth="2"
      />
      <circle cx="41" cy="14" r="10" fill="#91B3C6" />
      <path
        d="M39.7542 15.7933V15.6548C39.7578 15.0121 39.8181 14.5007 39.9353 14.1207C40.0561 13.7408 40.2265 13.4354 40.4467 13.2045C40.6669 12.9702 40.935 12.7553 41.251 12.56C41.4676 12.4251 41.6612 12.2777 41.8316 12.1179C42.0056 11.9545 42.1424 11.7734 42.2418 11.5746C42.3412 11.3722 42.3909 11.1467 42.3909 10.8981C42.3909 10.6175 42.3252 10.3743 42.1938 10.1683C42.0625 9.96236 41.8849 9.80256 41.6612 9.68892C41.441 9.57528 41.1942 9.51847 40.9208 9.51847C40.6686 9.51847 40.4289 9.57351 40.2017 9.68359C39.9779 9.79013 39.7915 9.95348 39.6424 10.1737C39.4968 10.3903 39.4151 10.6655 39.3973 10.9993H37.501C37.5188 10.3246 37.6821 9.75994 37.9911 9.3054C38.3036 8.85085 38.7155 8.50994 39.2269 8.28267C39.7418 8.0554 40.31 7.94176 40.9314 7.94176C41.6097 7.94176 42.2063 8.06072 42.7212 8.29865C43.2397 8.53658 43.6427 8.87571 43.9304 9.31605C44.2215 9.75284 44.3671 10.2713 44.3671 10.8714C44.3671 11.2763 44.3014 11.6385 44.1701 11.9581C44.0422 12.2777 43.8593 12.5618 43.6214 12.8104C43.3835 13.0589 43.1012 13.2809 42.7745 13.4762C42.4868 13.6538 42.2507 13.8384 42.066 14.0302C41.8849 14.2219 41.75 14.4474 41.6612 14.7067C41.5759 14.9624 41.5316 15.2784 41.528 15.6548V15.7933H39.7542ZM40.6811 19.1172C40.3615 19.1172 40.0862 19.0036 39.8554 18.7763C39.6246 18.549 39.5092 18.272 39.5092 17.9453C39.5092 17.6257 39.6246 17.3523 39.8554 17.125C40.0862 16.8977 40.3615 16.7841 40.6811 16.7841C40.9971 16.7841 41.2706 16.8977 41.5014 17.125C41.7358 17.3523 41.8529 17.6257 41.8529 17.9453C41.8529 18.1619 41.7979 18.359 41.6878 18.5366C41.5813 18.7141 41.4392 18.8562 41.2617 18.9627C41.0877 19.0657 40.8941 19.1172 40.6811 19.1172Z"
        fill="white"
      />
      <path
        d="M20.5855 12.2242C21.7466 11.984 22.8939 11.6788 24.0222 11.3102C23.3478 6.05788 17.7863 6 17.7863 6C15.497 6 13.616 6.79224 12.1433 8.37671C10.7203 9.91723 10.0088 11.8149 10.0088 14.0698C10.0088 16.3028 10.6947 18.1598 12.0666 19.6409C13.5397 21.2256 15.5074 22.018 17.9695 22.018C19.3268 22.018 20.5542 21.8632 21.6517 21.5537C22.7493 21.2442 23.5436 20.9347 24.0346 20.6252L24.0419 13.7789C24.0422 13.7466 24.035 13.7148 24.021 13.6859C24.007 13.6569 23.9866 13.6318 23.9613 13.6124C23.9361 13.5931 23.9067 13.58 23.8756 13.5744C23.8445 13.5687 23.8126 13.5706 23.7823 13.5798C22.9059 13.8584 20.7393 14.4629 18.327 14.8445V16.2432C19.1427 16.2584 19.6842 16.3322 19.9515 16.4645C20.363 16.6783 20.7505 17.1277 20.7505 17.813V18.4769C20.7517 18.6875 20.7115 18.8962 20.6321 19.0907C20.5528 19.2851 20.436 19.4613 20.2887 19.6088C19.9196 19.9727 19.3283 20.2169 18.3825 20.2169C16.6349 20.2169 15.1954 19.4063 14.672 18.1813C14.0437 16.7114 14.1241 15.9027 14.1241 14.0147C14.1241 11.7154 14.1398 10.9422 14.874 9.71936C15.468 8.72893 16.6255 8.26745 17.6244 8.29748C17.6244 8.29748 19.3295 8.14458 20.1192 9.69583C20.6829 10.8057 20.5855 12.2242 20.5855 12.2242Z"
        fill="white"
      />
    </svg>
  )
}
