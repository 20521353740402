import { CircularProgress, makeStyles, Avatar, Typography, Fade } from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import { isMobile } from 'utils/mobileUtils'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Skeleton } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import SearchByDoctor from '../../Search/SearchByDoctor'
import PatientsListItem from './PatientsListItem'
import PatientsListItemEmptyState from '../PatientsListItemEmptyState'
import FilteredEmptyState from '../FilteredEmptyState'
import Broadcast from '../Broadcast/Broadcast'
import useHasPatients from 'hooks/useHasPatients'
import useRolePermissions from 'hooks/useRolePermissions'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const useStyles = ({ isMobile }) =>
  makeStyles({
    container: {
      overflow: 'auto',
      height: '100%',
      '&::-webkit-scrollbar': {
        width: 5
      },

      '&::-webkit-scrollbar-thumb': {
        background: 'rgb(215, 215, 215)',
        borderRadius: 100
      },
      borderRight: '1px solid #DBDBDB'
    },
    searchContainer: {
      display: 'flex',
      justifyContent: 'center',
      borderBottom: '1px solid var(--border-color-3)'
    },
    skeleton: {
      padding: 19,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      transition: 'width .2s',
      borderBottom: '1px solid var(--border-color-3)'
    },
    skeletonRows: {
      display: 'flex',
      marginLeft: 15,
      width: '80%',
      height: 42,
      flexDirection: 'column',
      justifyContent: 'space-around'
    },
    skeletonFooter: {
      display: 'flex',
      width: '100%',
      height: 42,
      justifyContent: 'center',
      alignItems: 'center'
    },
    skeletonPseudoText: {
      borderRadius: '10px'
    }
  })({ isMobile })

const PatientsList = ({ setDoctorFilter, patientsSearchFilter }) => {
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()
  const { pathname } = useLocation()

  const { hasPatients } = useHasPatients()

  const rooms = useSelector(state => state.chatReducer.rooms)

  const { hasMore } = useSelector(state => state.chatReducer.pagination)
  const pinnedPatientSM = useSelector(state => state.chatReducer.pinnedPatient)
  const { doctorFilter, tagsFilter } = useSelector(state => state.chatReducer.filter)

  const classes = useStyles({ isMobile: isMobile() })

  const isLoading = useSelector(state => state.chatReducer.isLoading)

  const fetchMoreRooms = useCallback(() => {
    dispatch(Actions.fetchRooms({ tagsFilter, doctorFilter, withoutLoader: true, searchFilter: patientsSearchFilter }))
  }, [dispatch, tagsFilter, patientsSearchFilter, doctorFilter])

  const isBroadcastAllowed = useMemo(() => permissions.broadcast, [permissions.broadcast])

  const renderPatientSkeleton = () => {
    const skeletonArray = Array.from(Array(11).keys())
    return skeletonArray.map(i => (
      <div className={classes.skeleton} key={i}>
        <Skeleton variant="circle" height={58} width={58}>
          <Avatar />
        </Skeleton>
        <div className={classes.skeletonRows}>
          <Skeleton variant="rect" height={11} width="80%" className={classes.skeletonPseudoText}>
            <Typography></Typography>
          </Skeleton>
          <Skeleton variant="rect" height={11} width="40%" className={classes.skeletonPseudoText}>
            <Typography></Typography>
          </Skeleton>
        </div>
      </div>
    ))
  }

  return (
    <div className={classes.container} id="scrollContainer">
      {permissions.searchByDoctor && (
        <div className={classes.searchContainer}>
          <SearchByDoctor handleDoctorChange={setDoctorFilter} />
        </div>
      )}

      {isLoading && hasPatients && (
        <Fade timeout={600} in={isLoading}>
          <div>{renderPatientSkeleton()}</div>
        </Fade>
      )}
      <Fade timeout={600} in={!isLoading}>
        <div>
          <InfiniteScroll
            dataLength={rooms?.length}
            next={fetchMoreRooms}
            hasMore={hasMore}
            loader={
              <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                <CircularProgress style={{ color: '#FFBD39' }} size={25} />
              </div>
            }
            scrollableTarget="scrollContainer"
          >
            {isBroadcastAllowed && hasPatients && <Broadcast />}
            {(permissions.searchByDoctor || hasPatients) && rooms.length === 0 && <FilteredEmptyState />}
            {pinnedPatientSM && (
              <PatientsListItem {...pinnedPatientSM} isSelected={pathname.includes(pinnedPatientSM.id)} />
            )}
            {rooms.map(
              (patientSM, index) =>
                (patientSM.isDummyRoom || patientSM.id !== pinnedPatientSM?.id) &&
                (!patientSM._deleted || patientSM.isLoading) && (
                  <PatientsListItem
                    {...patientSM}
                    key={patientSM.id || patientSM.email}
                    patientTestId={index}
                    isSelected={pathname.includes(patientSM.id)}
                  />
                )
            )}
          </InfiniteScroll>
        </div>
      </Fade>
      {!hasPatients && !rooms.length && !permissions.searchByDoctor && <PatientsListItemEmptyState />}
    </div>
  )
}

export default React.memo(PatientsList)
