import * as React from 'react'

export default () => {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.68198 5.18198L5.5 2L2.31802 5.18198" stroke="#001335" />
      <path d="M0 1H11" stroke="#001335" />
      <path d="M5.5 2.5V11" stroke="#001335" />
    </svg>
  )
}
