import { Storage } from 'aws-amplify'
import fileExtension from 'file-extension'
import React, { useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PrimaryOutlinedButton from './PrimaryOutlinedButton'

const S3FileUploader = ({
  color,
  label = 'Upload',
  onUploadDone = () => {},
  multiple = false,
  supportedFileTypes = [],
  callForActionComponent,
  inputId = uuidv4(),
  isDisabled = false,
  onStartUploading = () => {}
}) => {
  const generateFileName = originalName => {
    const extension = fileExtension(originalName, { preserveCase: true })
    const withoutExtension = originalName
      .substring(0, originalName.indexOf(extension))
      .replace('+', '-')
      .replace(/[ ()]/g, '_')
    const guid = uuidv4()
    return `${withoutExtension}${guid}.${extension}`
  }

  const handleFilesUpload = useCallback(
    event => {
      const uuids = []
      const files = event.target.files

      if (files.length) {
        onStartUploading(files.length)
      }

      for (let index = 0; index < files.length; index++) {
        const file = files[index]
        const uuid = generateFileName(file.name)
        uuids.push(uuid)
        Storage.put(uuid, file, {
          contentType: file.type
        }).then(() => {
          if (index + 1 === files.length) {
            onUploadDone(uuids)
          }
        })
      }
    },
    [onStartUploading, onUploadDone]
  )

  return (
    <>
      <input
        multiple={multiple}
        id={inputId}
        type="file"
        accept={supportedFileTypes}
        onChange={handleFilesUpload}
        style={{ display: 'none' }}
        disabled={isDisabled}
      />
      <label htmlFor={inputId}>{callForActionComponent ?? <PrimaryOutlinedButton label={label} color={color} />}</label>
    </>
  )
}

export default S3FileUploader
