import { API } from 'aws-amplify'
import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, mapTo, mergeMap, pluck, switchMap } from 'rxjs/operators'
import Actions from '../actions'
import i18n from '../resources/locales/i18n'

export const regenerateScanEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.QA_REGENERATE_SCAN),
    pluck('payload'),
    switchMap(payload =>
      from(
        API.post('grinServerlessApi', `/treatments/v3/qaTools/fakeScan`, {
          body: payload
        })
      ).pipe(
        mergeMap(() => of(Actions.qaRegenerateScanReceived())),
        catchError(error => of(Actions.qaRegenerateScanFailed(error)))
      )
    )
  )

export const regenerateScanReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.QA_REGENERATE_SCAN_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('dialogs.regenerateScan.regeneratedSuccessfully'),
        type: 'success'
      })
    )
  )
export const regenerateScanFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.QA_REGENERATE_SCAN_FAILED),
    mapTo(
      Actions.showSnackbar({
        text: i18n.t('dialogs.regenerateScan.regenerationFailed'),
        type: 'error'
      })
    )
  )
