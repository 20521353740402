import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { TreatmentTrackOptions } from 'consts/giConsts'
import OnTrackGrinScanItem from 'components/common/icons/timelineV2/mini-timeline/OnTrackGrinScanItem'
import OffTrackGrinScanItem from 'components/common/icons/timelineV2/mini-timeline/OffTrackGrinScanItem'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'

const useStyles = makeStyles(theme => ({
  root: {
    width: 'fit-content',
    borderRadius: 6,
    padding: 5,
    lineHeight: 0.8,
    backgroundColor: ({ trackStatus }) =>
      trackStatus === TreatmentTrackOptions.OnTrack ? 'rgba(222, 246, 233, 1)' : 'rgba(248, 234, 231, 1)'
  },
  reasons: {
    marginLeft: 5,
    textDecoration: 'underline',
    '&.clickable': {
      cursor: 'pointer'
    }
  }
}))

const TrackStatusTag = ({ trackStatus, reasons = [], reasonsOnClick }) => {
  const classes = useStyles({ trackStatus })
  const { t } = useTranslation()

  return (
    <Grid container className={classes.root} alignItems="center">
      <Grid item style={{ marginRight: 3 }}>
        {trackStatus === TreatmentTrackOptions.OnTrack ? <OnTrackGrinScanItem /> : <OffTrackGrinScanItem />}
      </Grid>
      <Grid item>
        <DazzedParagraph12 thickness="strong">
          {t(`pages.patients.selectedPatient.timelineV2.trackStatus.${trackStatus}`)}
          {reasons.length > 0 && (
            <span
              className={[classes.reasons, reasonsOnClick ? 'clickable' : ''].join(' ')}
              onClick={reasonsOnClick || (() => {})}
            >
              {t(`pages.patients.selectedPatient.timelineV2.trackStatus.${reasons.length > 1 ? 'reasons' : 'reason'}`, {
                reasons: reasons.length
              })}
            </span>
          )}
        </DazzedParagraph12>
      </Grid>
    </Grid>
  )
}

export default TrackStatusTag
