import * as React from 'react'

const SmallSearch = ({ color = '#001335', ...props }) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7325 6.11606C10.7325 8.66542 8.66574 10.7321 6.11624 10.7321C3.56674 10.7321 1.5 8.66542 1.5 6.11606C1.5 3.5667 3.56674 1.5 6.11624 1.5C8.66574 1.5 10.7325 3.5667 10.7325 6.11606ZM9.61532 10.1018C8.6815 10.9222 7.45696 11.4196 6.11624 11.4196C3.18706 11.4196 0.8125 9.04514 0.8125 6.11606C0.8125 3.18699 3.18706 0.8125 6.11624 0.8125C9.04541 0.8125 11.42 3.18699 11.42 6.11606C11.42 7.45705 10.9223 8.68179 10.1015 9.61567L14.1181 13.6319C14.2523 13.7662 14.2523 13.9838 14.1181 14.1181C13.9838 14.2523 13.7662 14.2523 13.6319 14.1181L9.61532 10.1018Z"
        fill={color}
      />
    </svg>
  )
}

export default SmallSearch
