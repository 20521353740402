import { Grid, IconButton, makeStyles } from '@material-ui/core'
import React, { useState, useCallback, useMemo } from 'react'
import { createIdForTest } from 'utils/testingUtils.js'
import { MoreVert } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Pencil4, Trash2 } from 'components/common/icons'
import { trackEvent } from 'utils/analyticsUtils'
import GrinMenu from 'components/common/menu/GrinMenu'
import GrinMenuItem from 'components/common/menu/GrinMenuItem'

const useStyles = makeStyles({
  menuItem: {
    height: 50,
    padding: '5px 6px 5px 0px',
    width: 200,
    '&:hover': {
      backgroundColor: 'var(--bg-color-14)'
    }
  },
  menuItemIcon: { paddingTop: 4, paddingRight: 4, width: 40, textAlign: 'center' },
  menuButton: {
    margin: 0,
    marginRight: 5
  }
})

const NoteActionsMenu = ({ noteId, onEdit, onDelete }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = useCallback(() => {
    setIsOpen(false)
    setAnchorEl(null)
  }, [])

  const handleClick = useCallback(
    event => {
      trackEvent('Patient note - Actions menu opened', { noteId, viewType: 'mobile' })
      setIsOpen(true)
      setAnchorEl(event.currentTarget)
    },
    [noteId]
  )

  const handleItemClick = useCallback(
    onClick => {
      handleClose()
      onClick()
    },
    [handleClose]
  )

  const actions = useMemo(
    () => [
      {
        icon: <Pencil4 />,
        label: t('general.edit'),
        onClick: () => {
          trackEvent('Patient note - Edit note clicked', { noteId, viewType: 'mobile' })
          handleClose()
          onEdit()
        }
      },
      {
        icon: <Trash2 color="var(--text-color-7)" strokeWidth="1" />,
        label: t('general.delete'),
        onClick: () => {
          trackEvent('Patient note - Delete note clicked', { noteId, viewType: 'mobile' })
          handleClose()
          onDelete()
        }
      }
    ],
    [handleClose, noteId, onDelete, onEdit, t]
  )

  return (
    <GrinMenu
      transformOrigin={{ vertical: -50, horizontal: 'left' }}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      className={classes.menu}
      triggerComponent={
        <IconButton onClick={handleClick} className={classes.menuButton}>
          <MoreVert />
        </IconButton>
      }
    >
      {actions.map((action, index) => (
        <GrinMenuItem
          id={createIdForTest({ componentId: 'notes-actions-menu', title: action.label })}
          key={action.label}
          onClick={() => handleItemClick(action.onClick)}
          className={classes.menuItem}
        >
          <Grid container alignItems="center" style={{ height: 50 }}>
            <Grid item className={classes.menuItemIcon}>
              {action.icon}
            </Grid>
            <Grid item>{action.label}</Grid>
          </Grid>
        </GrinMenuItem>
      ))}
    </GrinMenu>
  )
}

export default NoteActionsMenu
