import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Fullscreen, FullscreenExit } from '@material-ui/icons'
import OrbitControl from 'components/Patients/PatientCard/RecordsTab/StlPreviewModal/OrbitControl'
import MouseDragIcon from 'components/common/icons/MouseDrag'
import MouseZoomIcon from 'components/common/icons/MouseZoom'

const useStyles = makeStyles(theme => ({
  fullScreenIcon: {
    opacity: 0.5,
    cursor: 'pointer',
    marginRight: 20
  }
}))

const StlPreviewModal = ({ onClickFullScreen, isFullScreen }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container direction="row" alignItems="center" justifyContent="space-between">
      <Grid item />
      <Grid item>
        <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
          <Grid item>
            <OrbitControl icon={<MouseDragIcon />} label={t('dialogs.patientInfo.records.stlPreview.dragToRotate')} />
          </Grid>
          <Grid item>
            <OrbitControl icon={<MouseZoomIcon />} label={t('dialogs.patientInfo.records.stlPreview.scrollToZoom')} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <div onClick={onClickFullScreen} className={classes.fullScreenIcon}>
          {isFullScreen ? <FullscreenExit fontSize="large" /> : <Fullscreen fontSize="large" />}
        </div>
      </Grid>
    </Grid>
  )
}

export default StlPreviewModal
