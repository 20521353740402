import Actions from 'actions'
import { AsyncStatus } from 'consts'

const initialState = {
  context: {
    accountOwnerId: null,
    practiceName: null,
    savedReplies: [],
    customTags: [],
    grinPlan: null,
    dso: null
  },
  assignedDoctors: {
    data: [],
    loadStatus: null
  },
  savedRepliesByPractice: {},
  customTagsByPractice: {},
  practiceGuidelines: {},
  practiceFFs: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.MP_SET_CONTEXT_PRACTICE:
      const doctorSM = state.assignedDoctors.data?.find(doc => doc.id === action.payload.doctorId)
      return {
        ...state,
        context: {
          ...state.context,
          accountOwnerId: action.payload.doctorId,
          practiceName: doctorSM?.practiceName,
          grinPlan: action.payload.grinPlan,
          dso: doctorSM?.dso
        }
      }
    case Actions.HI_FETCH_ASSIGNED_DOCTORS:
      return {
        ...state,
        assignedDoctors: {
          ...state.assignedDoctors,
          loadStatus: AsyncStatus.Loading
        }
      }
    case Actions.HI_FETCH_ASSIGNED_DOCTORS_RECEIVED:
      return {
        ...state,
        assignedDoctors: {
          ...state.assignedDoctors,
          data: action.payload,
          loadStatus: AsyncStatus.Completed
        }
      }
    case Actions.HI_FETCH_ASSIGNED_DOCTORS_FAILED:
      return {
        ...state,
        assignedDoctors: {
          ...state.assignedDoctors,
          isLoading: false,
          loadStatus: AsyncStatus.Failed
        }
      }
    case Actions.HI_FETCH_PRACTICE_SAVED_REPLIES_RECEIVED:
      return {
        ...state,
        savedRepliesByPractice: {
          ...state.savedRepliesByPractice,
          [action.payload.doctorId]: action.payload.savedReplies
        },
        context: {
          ...state.context,
          savedReplies: action.payload.savedReplies
        }
      }
    case Actions.MP_FETCH_CUSTOM_TAGS_BY_DOCTOR_RECEIVED:
      return {
        ...state,
        customTagsByPractice: {
          ...state.customTagsByPractice,
          [action.payload.doctorId]: action.payload.customTags
        },
        context: {
          ...state.context,
          customTags: action.payload.customTags
        }
      }
    case Actions.PATIENT_REQUESTED:
      return {
        ...state,
        practiceGuidelines: initialState.practiceGuidelines
      }
    case Actions.HI_FETCH_PRACTICE_GUIDELINES_RECEIVED:
      return {
        ...state,
        practiceGuidelines: action.payload
      }
    case Actions.MP_FETCH_PRACTICE_FFS_RECEIVED:
      return {
        ...state,
        practiceFFs: action.payload
      }
    case Actions.SIGNOUT_REQUESTED:
      return initialState
    default:
      return state
  }
}
