import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Actions from '../../actions'
import BaseModal from '../common/modals/BaseModal'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'utils/mobileUtils'
import { isOnFreePlan } from 'utils/billingUtils'

const useStyles = makeStyles({
  body: {
    minWidth: 480
  },
  content: {
    textAlign: 'center'
  }
})

const BillingUpgradePlanForScopesWarningModal = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isOpen = useSelector(state => state.billingReducer.upgradePlan.isUpgradePlanForScopesWarningModalOpen)
  const userPlan = useSelector(state => state.practiceReducer.billing.grinPlan)

  const handleCancel = useCallback(() => {
    dispatch(Actions.setUpgradePlanForScopesWarningModalVisibility(false))
  }, [dispatch])

  const handleSelectPlan = useCallback(() => {
    handleCancel()
    dispatch(Actions.setSelectPlanModalVisibility(true))
  }, [handleCancel, dispatch])

  return (
    <BaseModal
      open={isOpen}
      title={t('dialogs.upgradePlan.warningTitle')}
      onSecondaryBtnClick={handleCancel}
      secondaryLabel={t('general.cancel')}
      onPrimaryBtnClick={handleSelectPlan}
      primaryLabel={isOnFreePlan(userPlan) ? null : !isMobile() && t('dialogs.upgradePlan.warningSelectPlanButton')}
      handleClose={handleCancel}
      className={!isMobile() && classes.body}
      largerButtons={true}
      variant="alert"
    >
      <div className={classes.content}>
        <div>{t('dialogs.upgradePlan.warningScopesDescription1')}</div>
        <div>{t('dialogs.upgradePlan.warningScopesDescription2')}</div>
      </div>
    </BaseModal>
  )
}

export default BillingUpgradePlanForScopesWarningModal
