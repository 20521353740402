import { API, graphqlOperation } from 'aws-amplify'

export const fetchAll = async (query, variables, queryName, pageToken) => {
  let result = []

  const res = await API.graphql(
    graphqlOperation(query, {
      ...variables,
      nextToken: pageToken
    })
  )

  const { items, nextToken } = res.data[queryName] || {}

  if (nextToken) {
    return [...result, ...items, ...(await fetchAll(query, variables, queryName, nextToken))]
  } else {
    return [...result, ...items]
  }
}

export const getEntityIdAndVersion = async ({ entityType, id }) => {
  const queryName = `get${entityType}`
  const queryBody = `query ($id: ID!) {
    ${queryName}(id: $id) {
      _version
    }
  }
  `

  const res = await API.graphql(graphqlOperation(queryBody, { id }))
  const entity = res.data[queryName]
  return {
    id,
    _version: entity._version
  }
}

/**
 * Accepts a list of fields and transforms them into graphql query fields.
 * If the `fields` argument is a string, the function returns it as is.
 * Example:
 * ```
 * formatDynamicFields(['id', '_version', { patient: ['id', 'a_doctor'] }])
 * ```
 * returns
 * ```
 *  id
 *  _version
 *  patient {
 *    id
 *    a_doctor
 *  }
 * ```
 */
export const formatDynamicFields = (fields, trace = []) => {
  if (typeof fields === 'string') {
    return fields
  }

  if (!Array.isArray(fields)) {
    throw new Error(`expected an array or string but received '${typeof fields}'. trace: ${trace.join(' / ')}`)
  }

  let result = ''

  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]
    const fieldType = typeof field

    if (fieldType === 'string') {
      result += `${field}\n`
    } else if (fieldType === 'object') {
      //eslint-disable-next-line no-loop-func
      Object.keys(field).forEach(nestedKey => {
        result += `${nestedKey} {\n${formatDynamicFields(field[nestedKey], [...trace, `${nestedKey}`])}}\n`
      })
    } else {
      throw new Error(
        `received an invalid field at index ${i} with type '${fieldType}'. trace: ${trace.join(' / ')} / ${field}`
      )
    }
  }

  return result
}
