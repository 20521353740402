import React from 'react'
import { Grid } from '@material-ui/core'
import moment from 'moment'
import SelectInput from 'components/common/SelectInput'
import LabeledInputContainer from 'components/common/inputs/LabeledInputContainer'
import SetNextScan from 'components/Patients/SetNextScan'
import FrequencyPicker from 'components/common/inputs/FrequencyPicker'
import AlignersSets from 'components/Patients/PatientCard/AlignersSets'
import { ALIGNERS_TREATMENT_TYPE } from 'consts/treatmentConsts'
import { useTranslation } from 'react-i18next'

const TreatmentSection = ({
  values,
  formErrors,
  treatmentStatusOptions,
  scanFrequencyDefaults,
  scanFrequencyOptions,
  periodTypeOptions
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Grid item xs={6}>
        <SetNextScan
          title={t('dialogs.invitePatient.firstScanDate')}
          date={values.firstScanDate.toDate()}
          onSet={newDate => values.setFirstScanDate(newDate ? moment(newDate) : null)}
          tooltip={t('dialogs.invitePatient.firstScanDateTooltip')}
        />
      </Grid>
      {values.treatmentType === ALIGNERS_TREATMENT_TYPE && (
        <Grid item xs={12}>
          <AlignersSets
            alignersSets={values.totalAlignersSets}
            setAlignersSets={values.setTotalAlignersSets}
            errorMessage={formErrors?.totalAlignersSets}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <SelectInput
          label={t('dialogs.invitePatient.treatmentStatusLabel')}
          options={treatmentStatusOptions}
          value={values.treatmentStatus}
          onChange={({ value }) => {
            values.setTreatmentStatus(value)
          }}
          style={{ bright: true, thick: true }}
          selectStyle={{ fontWeight: 500, paddingLeft: 0 }}
          keepErrorContainerWhenInactive={false}
          errorMessage={formErrors?.treatmentStatus}
          tooltip={t('dialogs.invitePatient.treatmentStatusTooltip')}
        />
      </Grid>
      <Grid item xs={12}>
        <LabeledInputContainer
          title={t('dialogs.invitePatient.scanFrequency')}
          tooltip={t('tooltips.cycleInterval', { periodType: 'day' })}
        >
          <FrequencyPicker
            value={values.cycleInterval}
            setValue={values.setCycleInterval}
            periodType={values.cycleIntervalPeriodType}
            defaultPeriodType={scanFrequencyDefaults?.periodType}
            setPeriodType={values.setCycleIntervalPeriodType}
            options={scanFrequencyOptions}
            periodTypeOptions={periodTypeOptions}
          />
        </LabeledInputContainer>
      </Grid>
    </>
  )
}

export default TreatmentSection
