import * as React from 'react'

export default () => (
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="3.53809" width="22" height="9.30769" rx="1.69231" stroke="#3C52EF" />
    <path d="M9.45974 8.19276L4.38281 5.6543V10.7312L9.45974 8.19276Z" fill="#3C52EF" />
    <path d="M20.4597 8.19276L15.3828 5.6543V10.7312L20.4597 8.19276Z" fill="#3C52EF" />
    <rect x="10.3125" y="1" width="3.38462" height="14.3846" fill="#F5FAFF" />
    <path d="M12 1V15.3846" stroke="#3C52EF" strokeLinecap="round" />
  </svg>
)
