import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 7,
    background: `var(--bg-color-58)`,
    width: 48,
    height: 48,
    cursor: ({ editable }) => (editable ? 'pointer' : 'default'),
    paddingTop: 4
  },
  toothLabel: {
    color: 'var(--text-color-56)',
    fontWeight: 500,
    userSelect: 'none'
  },
  marking: {
    borderRadius: '100%',
    width: 14,
    height: 14,
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'var(--text-color-1)',
    margin: 0.5,
    '&.disabled': {
      opacity: 0.3
    }
  }
}))

const ElasticsToothGridItem = ({ tooth, onClick = () => {}, editable, values, highlightedElasticIndex }) => {
  const classes = useStyles({ editable })

  const handleClick = useCallback(() => {
    if (editable) {
      onClick()
    }
  }, [editable, onClick])

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      className={[classes.root].join(' ')}
      onClick={handleClick}
    >
      <Grid item>
        <Grid container>
          {values.map(elastic => (
            <Grid item key={`tooth-elastic-${elastic.elasticName}`}>
              <div
                className={[
                  classes.marking,
                  highlightedElasticIndex >= 0 && highlightedElasticIndex !== elastic.elasticIndex ? 'disabled' : ''
                ].join(' ')}
                style={{ background: elastic.color }}
              >
                {elastic.selection}
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        <DazzedParagraph16 className={classes.toothLabel}>{tooth}</DazzedParagraph16>
      </Grid>
    </Grid>
  )
}

export default ElasticsToothGridItem
