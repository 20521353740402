import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import GreySearch from '../../../common/icons/GreySearch'
import PmsSearchPatientsResults from './PmsSearchPatientsResults'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import actions from 'actions'
import { useDispatch } from 'react-redux'
import usePms from 'hooks/usePms'

const useStyles = makeStyles({
  searchInput: {
    display: 'flex',
    width: '300px',
    fontFamily: 'Dazzed',
    fontSize: 14,
    paddingLeft: 5,
    background: 'rgba(0, 0, 0, 0)',
    border: 'none',
    borderBottom: '1px solid var(--border-color-13)',
    paddingBottom: 4,
    '&:focus': {
      outline: 'none'
    },
    '::placeholder': {
      color: 'var(--text-color-14)'
    }
  },
  searchInputContainer: {
    paddingLeft: 8,
    paddingTop: 2
  }
})

const PmsSearchPatient = ({ onSelect = () => {}, onClose = () => {}, onSearchAnalyticEvent = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const anchorEl = useRef(null)
  const { pmsType } = usePms()

  const [searchValue, setSearchValue] = useState('')
  const [resultsPopoverVisible, setResultsPopoverVisible] = useState(false)
  const [debouncedSearchValue] = useDebounce(searchValue, 300)

  const handleCloseSearchResults = useCallback(() => {
    setSearchValue('')
    setResultsPopoverVisible(false)
    onClose()
  }, [onClose, setSearchValue])

  useEffect(() => {
    if (debouncedSearchValue.length >= 2) {
      onSearchAnalyticEvent()
      dispatch(actions.pmsFetchPatients(debouncedSearchValue))
      setResultsPopoverVisible(true)
    } else {
      setResultsPopoverVisible(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue, dispatch])

  return (
    <Grid item id="pms-search-patient">
      <Grid container className={classes.searchInputContainer} alignItems="center">
        <Grid item>
          <GreySearch width={24} height={24} />
        </Grid>
        <Grid item>
          <input
            ref={anchorEl}
            className={classes.searchInput}
            placeholder={t('dialogs.patientInfo.appointments.pms.searchPatientPlaceHolder', {
              pmsType: t(`pms.types.${pmsType}`)
            })}
            type="text"
            name="patients-search"
            value={searchValue}
            autoComplete="off"
            onChange={({ target }) => {
              setSearchValue(target.value)
            }}
          />
        </Grid>
        {resultsPopoverVisible && (
          <PmsSearchPatientsResults
            isVisible={resultsPopoverVisible}
            anchorEl={anchorEl}
            onClose={handleCloseSearchResults}
            onSelectPatient={pmsPatient => {
              handleCloseSearchResults()
              onSelect(pmsPatient)
            }}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default PmsSearchPatient
