import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'

export const initPixels = ({ facebookPixelId, ga4MeasurementId, gtmId }) => {
  if (facebookPixelId) {
    ReactPixel.init(facebookPixelId, {}, { autoConfig: true })
  }
  if (ga4MeasurementId) {
    ReactGA.initialize(ga4MeasurementId, {})
  }
  if (gtmId) {
    TagManager.initialize({ gtmId })
  }
}

export const contentViewed = pixelState => {
  initPixels(pixelState)
  if (pixelState.facebookPixelId) {
    ReactPixel.track('ViewContent', {})
    ReactPixel.pageView()
  }
  if (pixelState.ga4MeasurementId) {
    ReactGA.event({
      category: 'RC Landing Page',
      action: 'ContentView'
    })
    ReactGA.send('pageview')
  }
  if (pixelState.gtmId) {
    TagManager.dataLayer({ dataLayerName: 'ContentView', dataLayer: {} })
    TagManager.dataLayer({ dataLayer: { event: 'pageview' } })
  }
}

export const leadCreated = (pixelState, email) => {
  if (pixelState.facebookPixelId) {
    ReactPixel.track('Lead', { email })
  }
  if (pixelState.ga4MeasurementId) {
    ReactGA.event({
      category: 'RC Landing Page',
      action: 'Lead',
      label: email
    })
  }
  if (pixelState.gtmId) {
    TagManager.dataLayer({ dataLayerName: 'Lead', dataLayer: { email } })
  }
}
