import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '60vh',
    overflowY: 'auto',
    padding: '0 50px',
    color: 'white',
    '@media (maxWidth: 960px)': {
      padding: 0
    },
    '& ol, & ul': {
      padding: 0,
      listStyleType: 'none'
    }
  },
  fw400: {
    fontWeight: 400
  }
})

const USTermsDoc = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <p>
        <strong>Terms of Use for Dental Practice</strong>
      </p>
      <p>
        <span className={classes.fw400}>Last Updated: </span>
        <span className={classes.fw400}>April 19, 2024</span>
      </p>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <p>
        <span className={classes.fw400}>These Terms of Use for Dental Practice (as modified, the &ldquo;</span>
        <strong>Terms</strong>
        <span className={classes.fw400}>
          &rdquo;) govern (i) the order and use by you, a dental practice providing general and/or specialty dental
          services (the &ldquo;
        </span>
        <strong>Practice</strong>
        <span className={classes.fw400}>&rdquo;, &ldquo;</span>
        <strong>you</strong>
        <span className={classes.fw400}>&rdquo;, or &ldquo;</span>
        <strong>your</strong>
        <span className={classes.fw400}>&rdquo;), of the Grin scope product (the &ldquo;</span>
        <strong>Device</strong>
        <span className={classes.fw400}>
          &rdquo;); and (ii) your order and right to access and use the Grin services (as modified, the &ldquo;
        </span>
        <strong>Grin Services</strong>
        <span className={classes.fw400}>&rdquo;), the Grin digital platform (as modified, the &ldquo;</span>
        <strong>Platform</strong>
        <span className={classes.fw400}>&rdquo;), the website at get-grin.com (as modified, the &ldquo;</span>
        <strong>Site</strong>
        <span className={classes.fw400}>
          &rdquo;) and the various other related services, premium and other features, functions, software, applications
          and websites (together with the Grin Services, the Platform and the Site, the &ldquo;
        </span>
        <strong>Services</strong>
        <span className={classes.fw400}>
          &rdquo;) provided to you by Get-Grin Inc. and/or its affiliate, Grin Group, LLC (collectively, &ldquo;
        </span>
        <strong>Grin</strong>
        <span className={classes.fw400}>&rdquo;, &ldquo;</span>
        <strong>we</strong>
        <span className={classes.fw400}>&rdquo;, &ldquo;</span>
        <strong>our</strong>
        <span className={classes.fw400}>&rdquo; or &ldquo;</span>
        <strong>us</strong>
        <span className={classes.fw400}>&rdquo;) pursuant to an order (each, an &ldquo;</span>
        <strong>Order</strong>
        <span className={classes.fw400}>
          &rdquo;) for one or more Devices and/or subscriptions for Services (each, a &ldquo;
        </span>
        <strong>Subscription</strong>
        <span className={classes.fw400}>
          &rdquo;), including Orders made in connection with any Program Administration Agreement (&ldquo;
        </span>
        <strong>PAA</strong>
        <span className={classes.fw400}>
          &rdquo;).&nbsp; The Terms also govern the access and use of Devices and Services by your licensed general
          and/or specialty dental professionals (the &ldquo;
        </span>
        <strong>Providers</strong>
        <span className={classes.fw400}>&rdquo;), your patients (&ldquo;</span>
        <strong>Patients</strong>
        <span className={classes.fw400}>&rdquo;) and other users (collectively, the &ldquo;</span>
        <strong>Users</strong>
        <span className={classes.fw400}>
          &rdquo;) who order, access and use Devices and Services on behalf of the Practice.&nbsp; All capitalized terms
          not defined in these Terms shall have the meanings given to them in the applicable Order.&nbsp; These Terms,
          together with the Order, Grin&rsquo;s Privacy Policy (
        </span>
        <a href="https://get-grin.com/privacy/">
          <span className={classes.fw400}>https://get-grin.com/privacy/</span>
        </a>
        <span className={classes.fw400}>) (the &ldquo;</span>
        <strong>Privacy Policy</strong>
        <span className={classes.fw400}>
          &rdquo;) and any and all exhibits, modifications and additions thereto, as applicable, comprise a legally
          binding contract (the &ldquo;
        </span>
        <strong>Agreement</strong>
        <span className={classes.fw400}>
          &rdquo;) between you and Grin regarding your order, access and use of the Devices and the Services.&nbsp; In
          the event any terms and conditions of the applicable Order or PAA are inconsistent with these Terms, the
          provisions of the Order or PAA shall control.
        </span>
      </p>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <p>
        <span className={classes.fw400}>PLEASE READ THE FOLLOWING TERMS CAREFULLY</span>
      </p>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <p>
        <span className={classes.fw400}>
          BY CLICKING &ldquo;I ACCEPT&rdquo; OR OTHERWISE AGREEING TO AN ORDER, OR BY DOWNLOADING, INSTALLING OR
          OTHERWISE ACCESSING OR USING THE DEVICES OR ANY OF THE SERVICES OR OTHERWISE SIGNIFYING YOUR ACCEPTANCE OF THE
          AGREEMENT, INCLUDING THESE TERMS, YOU REPRESENT AND WARRANT THAT (I) YOU HAVE THE FULL RIGHT, CAPACITY, POWER
          AND AUTHORITY TO ENTER INTO THE AGREEMENT WITH GRIN ON BEHALF OF YOUR PRACTICE AND TO PERFORM YOUR OBLIGATIONS
          UNDER THE AGREEMENT; (II); THE AGREEMENT HAS BEEN DULY AND VALIDLY EXECUTED AND DELIVERED AND CONSTITUTES A
          VALID AND BINDING OBLIGATION OF THE PRACTICE, ENFORCEABLE AGAINST THE PRACTICE IN ACCORDANCE WITH ITS TERMS;
          (III) YOU HAVE NOT RELIED ON ANY STATEMENT OR REPRESENTATION (WHETHER NEGLIGENCY OR INNOCENTLY MADE) NOT
          EXPRESSLY SET OUT IN THE AGREEMENT; AND (IV) YOU HAVE READ AND UNDERSTAND THE AGREEMENT AND ALL MODIFICATIONS
          AND ADDITIONS THERETO.&nbsp; IF YOU DO NOT AGREE TO THE AGREEMENT, YOU ARE NOT AUTHORIZED TO ACCESS OR USE THE
          DEVICES OR ANY OF THE SERVICES.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>ARBITRATION NOTICE</strong>
          <span className={classes.fw400}>.</span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span className={classes.fw400}>
          Except for certain kinds of disputes as described in Section 13, you agree that disputes arising under the
          Agreement will be resolved by binding, individual arbitration, and YOU AND GRIN ARE EACH WAIVING THE RIGHT TO
          A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR
          RIGHT TO GO TO COURT to assert or defend your rights under the Agreement (except for matters that may be taken
          to small claims court). Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>ELIGIBILITY</strong>
          <span className={classes.fw400}>.</span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span className={classes.fw400}>YOU REPRESENT TO GRIN THAT:</span>
      </p>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <ul>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            EACH PROVIDER IN THE PRACTICE IS A DULY LICENSED GENERAL OR SPECIALTY DENTIST IN GOOD STANDING WITH THE
            STATE MEDICAL BOARD OR OTHER APPLICABLE AGENCY OF THE STATE OR, IF IN CANADA, THE APPLICABLE DENTAL
            REGULATORY COLLEGE IN THE PROVINCE WHERE YOU ARE LICENSED TO ACCESS AND USE THE DEVICES AND
            THE&nbsp;SERVICES;
          </span>
        </li>
      </ul>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <ul>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            YOU ARE SOLELY RESPONSIBLE FOR DETERMINING WHETHER YOUR PATIENTS ARE ELIGIBLE TO ACCESS AND USE THE DEVICES
            AND THE SERVICES.&nbsp; IF YOU DO NOT AUTHORIZE A PATIENT TO ACCESS AND USE THE DEVICE AND THE SERVICES,
            THEN SUCH PATIENT WILL BE PROHIBITED FROM ACCESSING AND USING THE DEVICE AND THE SERVICES;
          </span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            EACH PROVIDER AND USER ACCESSING AND USING THE DEVICES AND SERVICES HAS REACHED THE AGE OF MAJORITY IN SUCH
            PROVIDER&rsquo;S OR USER&rsquo;S JURISDICTION OF RESIDENCE;
          </span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            YOU AND EACH OF YOUR PROVIDERS AND USERS WILL COMPLY WITH THE TERMS OF THE AGREEMENT, INCLUDING THESE TERMS,
            AND ALL APPLICABLE LOCAL, STATE, FEDERAL, PROVINCIAL, NATIONAL AND INTERNATIONAL LAWS, RULES AND REGULATIONS
            (COLLECTIVELY, &ldquo;
          </span>
          <strong>LAWS</strong>
          <span className={classes.fw400}>
            &rdquo;) AND YOU SHALL BE RESPONSIBLE FOR ANY AND ALL BREACHES OR VIOLATIONS OF THIS AGREEMENT OR ANY LAWS
            BY YOUR PROVIDERS AND OTHER USERS; AND
          </span>
        </li>
      </ul>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <ul>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            NEITHER YOU NOR ANY OF YOUR PROVIDERS OR OTHER USERS ARE A COMPETITOR OF GRIN AND/OR INTEND TO USE THE
            DEVICES OR THE SERVICES FOR REASONS THAT ARE IN COMPETITION WITH GRIN OR OTHERWISE TO REPLICATE THE DEVICES
            OR SOME OR ALL OF THE GRIN SERVICES FOR ANY REASON.
          </span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>Privacy</strong>
            <span className={classes.fw400}>.</span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <span className={classes.fw400}>
          Your privacy and the privacy of your Patients are important to Grin. Our goal is to make the Devices and the
          Services as good, useful and rewarding for you and your Patients as possible. To do that, Grin may collect and
          process information from you or from or about your Patients, including personally identifiable information as
          set forth in more detail in our Privacy Policy and/or our data processing agreement (&ldquo;
        </span>
        <strong>DPA</strong>
        <span className={classes.fw400}>
          &rdquo;).&nbsp; To the extent that you need a DPA, you should request Grin to provide you with the DPA and you
          should return such DPA signed to{' '}
        </span>
        <a href="mailto:support@get-grin.com">
          <span className={classes.fw400}>support@get-grin.com</span>
        </a>
        <span className={classes.fw400}>.</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span className={classes.fw400}>
          You represent and warrant that you have provided all necessary notices and disclosures, have obtained all
          necessary consents and otherwise have all authority under applicable privacy Laws for Grin to collect, use and
          process personal information (including patient data) on your behalf to provide the Services.&nbsp; Grin has
          no obligation to review any notices or consents or determine the sufficiency of such processes under privacy
          Laws. We will use personal information in performing the Services &ldquo;as is&rdquo;, and we are not
          responsible for reviewing, validating or otherwise confirming the accuracy, appropriateness or completeness of
          such data. Notwithstanding the foregoing, Grin shall comply with all applicable laws and regulations regarding
          the collection, use and disclosure of personal information, including but not limited to (i) The Health
          Insurance Portability and Accountability Act of 1996 (&ldquo;
        </span>
        <strong>HIPAA</strong>
        <span className={classes.fw400}>
          &rdquo;); (ii) the Privacy and Security Standards (45 C.F.R. Parts 160 and 164) and the Standards for
          Electronic Transactions (45 C.F.R Parts 160 and 162) (collectively, the &ldquo;
        </span>
        <strong>Standards</strong>
        <span className={classes.fw400}>
          &rdquo;) promulgated or as to be promulgated by the Secretary of Health and Human Services on and after the
          applicable effective dates specified in the Standards; (iii) The Health Information Technology Economic and
          Clinical Health Act of 2009 (the &ldquo;
        </span>
        <strong>HITECH</strong> <strong>Act</strong>
        <span className={classes.fw400}>&rdquo;); and (iv) General Data Protection Regulation (&ldquo;</span>
        <strong>GDPR</strong>
        <span className={classes.fw400}>
          &rdquo;); and/or (iv) in Canada, federal and provincial privacy and health privacy legislation applicable to
          Grin. Grin shall not disclose to any third party, except where required or permitted by law and the Business
          Associate Agreement attached hereto as{' '}
        </span>
        <strong>Exhibit A</strong>
        <span className={classes.fw400}> and incorporated herein by reference (the &ldquo;</span>
        <strong>BAA</strong>
        <span className={classes.fw400}>
          &rdquo;) or, if applicable, the Canadian Privacy Addendum attached hereto as{' '}
        </span>
        <strong>Exhibit B</strong>
        <span className={classes.fw400}> and incorporated herein by reference (the &ldquo;</span>
        <strong>CPA</strong>
        <span className={classes.fw400}>
          &rdquo;), any medical record or other information regarding any Patients, and in such case, disclosures shall
          be made in accordance with the applicable policies of the parties, the BAA, the CPA and the Standards. All
          medical information and personal information concerning specific Patients, including but not limited to the
          identification of the Patients, derived from the access and use of the Devices and the Services shall be
          processed in accordance with the applicable policies of the parties, the BAA, the CPA and/or the DPA.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span className={classes.fw400}>
          You acknowledge that Grin may from time-to-time issue updated or upgraded versions of the Services, including
          the software-related Services (the &ldquo;
        </span>
        <strong>App</strong>
        <span className={classes.fw400}>
          &rdquo;) and may (subject to your device settings) automatically and electronically update or upgrade the
          version of the App that you are then currently using on your mobile device. You consent to receive updates or
          upgrades to the App automatically without providing further consent each time. The App (including any updates
          or upgrades) may: (i) cause your device to automatically communicate with our servers to deliver the
          functionality described in the App description or through new features as they are introduced and to record
          usage metrics and (ii) affect preferences or data stored on your device. We are not responsible if an update
          or upgrade affects how the App works if this is caused by your own equipment or device not supporting the
          update or upgrade. You can withdraw consent at any time by uninstalling the app or by contacting us at&nbsp;
        </span>
        <a href="mailto:support@get-grin.com">
          <span className={classes.fw400}>support@get-grin.com</span>
        </a>
        <span className={classes.fw400}>.</span>
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>General</strong>
            <span className={classes.fw400}>.</span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Communications.&nbsp; The Devices and the Services, including the &ldquo;
          </span>
          <strong>Remote Consultation</strong>
          <span className={classes.fw400}>
            &rdquo; feature, are part of Grin&rsquo;s communication platform to assist you in communicating with your
            Patients. How you and your Patients choose to communicate with each other, however, is strictly between you
            and your Patients. The Grin Services are just one way of communicating with your Patients and you are free
            to use any other means to contact or interact with your Patients.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Your Patients. YOU ARE SOLELY RESPONSIBLE FOR (I) DECIDING WHICH PATIENTS YOU WILL ACCEPT INTO YOUR PRACTICE
            AND (II) ANY AND ALL DENTAL, ORTHODONTIC OR OTHER SERVICES OR ADVICE YOU PROVIDE IN CONNECTION WITH THE
            DEVICES, SERVICES OR OTHERWISE.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            No Medical, Dental or Orthodontic Services. GRIN DOES NOT PROVIDE MEDICAL, DENTAL OR ORTHODONTIC SERVICES.
            GRIN PROVIDES THE DEVICES AND THE SERVICES TO FACILITATE PROVIDER&rsquo;S PROVISION OF GENERAL OR SPECIALTY
            DENTAL SERVICES TO PATIENTS. NOTHING IN THE SERVICES SHOULD BE INTERPRETED AS MEDICAL, DENTAL OR ORTHODONTIC
            ADVICE, DIAGNOSIS OR TREATMENT. NOTHING IN THE SERVICES IS OR SHOULD BE CONSIDERED, OR USED AS A SUBSTITUTE
            FOR, MEDICAL, DENTAL OR ORTHODONTIC ADVICE, DIAGNOSIS OR TREATMENT. You should always discuss any questions
            or concerns that your Patients might have about their health or medical condition.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>Subscription; Account; Access.</strong>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Subscription. You must purchase a Subscription to access and use the Services, unless otherwise agreed by
            Grin. The purchase of a Subscription is governed by the Agreement, including these Terms.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Account. To make use of the Devices and the Services, you will have to create an account (&ldquo;
          </span>
          <strong>Account</strong>
          <span className={classes.fw400}>
            &rdquo;).&nbsp; You agree not to create an Account for anyone else (other than other Users within your
            Practice) or use the Account of another without the other&rsquo;s permission (other than other Users within
            your Practice).&nbsp; When creating your Account, you will be required to provide us with some information
            about the Practice and a Provider&rsquo;s name, email address and other contact information.&nbsp; You shall
            not share your Account with any other person or access the Services except as authorized under the
            Agreement. You agree that the information you provide is accurate and complete information and that you will
            keep it accurate and up-to-date at all times. You are solely responsible for the activity that occurs in,
            and maintaining the confidentiality of, your Account and password.&nbsp; You must notify us immediately of
            any breach of security or unauthorized use of your Account.&nbsp; As between you and us, you are solely
            responsible and liable for the activity that occurs in connection with your Account.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Access. Subject to your complete and ongoing compliance with the Agreement, Grin grants you, solely for use
            by your Providers and other Users to provide general and/or specialty dental services, a limited,
            non-exclusive, non-transferable, non-sublicensable, revocable right during the term of your Subscription to
            access and use the Devices and the Services.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Prevention of Unauthorized Use. Grin reserves the right to exercise whatever lawful means it deems necessary
            to prevent the unauthorized access or use of the Services or the circumvention of Services, including, but
            not limited to, technological barriers, IP mapping, and directly contacting your Internet Service Provider
            (ISP) regarding such unauthorized use.
          </span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <ol>
        <li className={classes.fw400}>
          <strong>Reviews</strong>
          <span className={classes.fw400}>
            . Providers, Patients and other Users may submit opinions, assessments and reviews about the Device and the
            Services (&ldquo;
          </span>
          <strong>User Review</strong>
          <span className={classes.fw400}>
            &rdquo;). You shall be solely responsible for your User Review and the consequences&nbsp;of posting,
            publishing or uploading any User Review. We do not verify the correctness or reliability of any User Review.
            We have complete discretion whether to publish your User Review, and we reserve the right in our sole
            discretion and without further notice to you, to monitor, censor, edit, remove, delete, and/or remove any
            User Review. You acknowledge and agree that Grin shall have no liability for any losses or damages that may
            arise or result from a User Review.&nbsp;By submitting a User Review, you hereby grant Grin a worldwide,
            irrevocable, non-exclusive, fully paid, royalty-free, perpetual, sublicensable and transferable license to
            use, reproduce, distribute, prepare derivative works of and display the User Review for any purpose,
            including without limitation for publishing and redistributing part or all of your User Reviews (and
            derivative works thereof) in any media formats and through any media channels and to improve the Device and
            the Services without restriction and create other products and services, and you hereby waive any moral
            rights in your User Reviews or rights to compensation, to the extent permitted by Law.
          </span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <ol>
        <li className={classes.fw400}>
          <strong>Prohibited Conduct</strong>
          <span className={classes.fw400}>.</span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <p>
        <span className={classes.fw400}>
          BY ACCESSING OR USING THE DEVICES AND THE SERVICES, YOU AGREE THAT NEITHER YOU NOR ANY PROVIDER OR USER WILL:
        </span>
      </p>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Decipher, decompile, disassemble, reverse engineer, modify, translate, or otherwise attempt to derive source
            code, algorithms, tags, specifications, architectures, structures or other elements of the Devices or
            Services, in whole or in part (except to the extent that the Laws of your jurisdiction make such
            restrictions unenforceable);
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Access or use the Devices or the Services for the benefit of anyone other than your Practice or your
            Patients and Users except in accordance with the Agreement, including selling, renting, reselling or
            distributing, in whole or in part, the Devices or any of the Services;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Copy, disclose or distribute any data or other information available through the Devices, the Services
            and/or information, in any medium, including without limitation on the Site, by any automated or
            non-automated &ldquo;scraping;&rdquo;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Interfere with, circumvent or disable any security or other technological features or measures of any of the
            Services or attempt to gain unauthorized access to any of the Services or its related systems or networks;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Use bots or other automated methods to access the Services, download any information, send or redirect
            messages or perform any other activities through the Services;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Take any action that Grin determines, in its sole discretion, imposes or may impose, an unreasonable or
            disproportionately large load on its infrastructure;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Upload invalid data, viruses, worms or other software agents through the Services;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Collect, harvest or derive any personally identifiable information, including names, email addresses from
            the Services, except as may be expressly contemplated by the Agreement and the Privacy Policy;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Impersonate any person or entity, use a fictitious name or misrepresent your affiliation with a person or
            entity;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Use the Devices or any of the Services for any unlawful or inappropriate activities, including to circumvent
            Grin, build a competitive product or otherwise by providing application or device related services that are
            in competition with Grin; or
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Use any of the Services for any commercial solicitation purposes except as authorized by the Agreement,
            including sending commercial electronic messages.
          </span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <ol>
        <li className={classes.fw400}>
          <strong>Orders, Delivery, Title, Payments and Returns.</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Offerings.&nbsp; Grin may have limited quantities of the Device and shall have no obligation to accept any
            order for Devices or Services until the transaction is final and approved, as confirmed by Grin (&ldquo;
          </span>
          <strong>Acceptance</strong>
          <span className={classes.fw400}>
            &rdquo;).&nbsp; Prior to Acceptance, (i) the fees and charges (the &ldquo;
          </span>
          <strong>Fee</strong>
          <span className={classes.fw400}>
            s&rdquo;) for Devices and Services are subject to change without notice; (ii) Grin reserves the right to
            modify or discontinue the Device and any of the Services (or any part or content thereof) without notice at
            any time; and (iii) Grin shall not be liable to you or to any third-party for any modification, Fee change,
            suspension or discontinuance of Devices or any of the Services. Grin reserves the right, but is not
            obligated, to limit the sales of Devices to any person, geographic region or jurisdiction. Grin may exercise
            this right on a case-by-case basis. Any offer for the Devices or the Services is void where prohibited.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Payments.&nbsp; You agree to pay all fees and charges for Devices and Services at the time and in the manner
            and amounts set forth in the applicable Order (the &ldquo;
          </span>
          <strong>Order Process</strong>
          <span className={classes.fw400}>
            &rdquo;).&nbsp; The Fees are exclusive of all taxes, customs, duties and shipping charges applicable to your
            Order, which shall be solely borne by you. The cost of shipping Devices (if any) shall be specified during
            the Order Process. All payments shall be non-refundable except as provided under the Returns Policy or
            required by applicable Law. Payment of the Purchase Price and Fees shall be made via the method specified
            during the Order Process or pursuant to the applicable Order. Any purchase of Devices is subject to a
            minimum of 10 Devices per purchase.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Payment Processing Service.&nbsp; Payments are processed through the third-party e-commerce platform and
            payment processing service made available by Stripe.com or other service providers{' '}
          </span>
          <strong>(&ldquo;Payment Processor</strong>
          <span className={classes.fw400}>
            &rdquo;) and are subject to the terms and the applicable provisions of the Payment Processor&rsquo;s Terms
            of Service.&nbsp; Stripe&rsquo;s Terms of Service are available at:{' '}
          </span>
          <a href="https://stripe.com/ssa">
            <span className={classes.fw400}>https://stripe.com/ssa</span>
          </a>
          <span className={classes.fw400}>.</span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Delivery.&nbsp; Delivery of Devices shall be made in accordance with Grin&rsquo;s Shipping Policy, as
            modified from time to time (as modified, the &ldquo;
          </span>
          <strong>Shipping Policy</strong>
          <span className={classes.fw400}>&rdquo;), which is incorporated herein by reference and available at </span>
          <a href="http://www.get-grin.com/terms/shipping">
            <span className={classes.fw400}>www.get-grin.com/terms/shipping</span>
          </a>
          <span className={classes.fw400}>
            , to the delivery address you provide during the Order Process or as separately arranged with Grin. Grin
            shall make commercially reasonable efforts to meet the delivery dates specified during the Order
            Process.&nbsp; However, you acknowledge that delivery may be dependent on third parties outside of
            Grin&rsquo;s control, and in no event shall Grin be liable for any delays.&nbsp; Risk of loss of, and damage
            to, Devices shall pass to you upon delivery.&nbsp; Grin shall retain title to the Device until you have paid
            the full Purchase Price.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Errors, Inaccuracies and Omissions. Occasionally, there may be information on the Site or in the Order
            Process that contains typographical errors, inaccuracies or omissions that may relate to Devices or Services
            descriptions, pricing, promotions, offers, Device shipping charges, transit times and availability. Grin
            reserves the right to correct any errors, inaccuracies or omissions, and to change or update information or
            cancel Orders if any such information is inaccurate at any time without prior notice (including after you
            have submitted your Order). Grin undertakes no obligation to update, amend or clarify information in the
            Order Process or on any related website, including without limitation, pricing information, except as
            required by Law. No specified update or refresh date applied in the Order Process or on any related website
            should be taken to indicate that all information in the Grin Services or on any related website has been
            modified or updated.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Accuracy of Billing and Account Information. Grin reserves the right to refuse any Order you place with
            Grin. In the event that Grin makes a change to, or cancels, an Order, Grin will use commercially reasonable
            efforts to notify you by contacting the e-mail and/or billing address and/or phone number provided at the
            time the Order was made. You agree to provide current, complete and accurate purchase and account
            information for all Orders made from and agree to promptly update your and other information, including your
            email address and credit card numbers and expiration dates, so that Grin can complete your transactions and
            contact you as needed.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            You agree not to remove, alter or conceal, in whole or in part, any trademarks, service marks, serial
            numbers, logos or other proprietary notices or indicia fixed or attached to the Device. Grin may, but is not
            obligated to, provide you with documentation and support services in connection with the Device. You are
            entitled to use the Device in accordance with any documentation that may be provided to you by Grin. You
            must use the Device in compliance with all applicable Laws, including export requirements.&nbsp; Any returns
            or refunds related to Devices shall be made only in accordance with Grin&rsquo;s Returns and Refunds Policy,
            as modified from time to time (as modified, the &ldquo;
          </span>
          <strong>Refunds Policy</strong>
          <span className={classes.fw400}>&rdquo;), which is incorporated herein by reference and available at </span>
          <a href="http://www.get-grin.com/terms/returns">
            <span className={classes.fw400}>http://www.get-grin.com/terms/returns</span>
          </a>
          <span className={classes.fw400}>.</span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Except in connection with the &ldquo;Remote Consultation&rdquo; feature, it is your responsibility to
            provide the Devices to your Patients. With respect to the &ldquo;Remote Consultation&rdquo; feature, the
            Devices will be shipped directly to your Patients in accordance with the applicable Order and your
            directions.&nbsp; Devices may only be used by your Patients in connection with the Services and in
            accordance with instructions provided by you or Grin.&nbsp; In order to fully utilize the Platform and
            purchase the Devices, you are required to purchase and subscribe to the Grin Services.
          </span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <ol>
        <li className={classes.fw400}>
          <strong>Intellectual Property Rights</strong>
          <span className={classes.fw400}>.</span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Ownership.&nbsp; You acknowledge and agree that all right, title and interest in (i) the underlying
            technology of Devices; (ii) the Grin Services; and (iii) all intellectual property rights evidenced by,
            embodied in and/or related to the Devices and the Grin Services, including any copyrights, patents and trade
            secrets, are and shall remain the exclusive property of Grin and/or its third-party licensors. Grin shall
            also own all right, title and interest in any anonymous performance results generated under or by the
            Devices and the Services. We reserve all rights not expressly granted in the Agreement in and to the Devices
            and the Services.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Content and Marks.&nbsp; You acknowledge and agree that: (i) the content on all Grin Services, including
            without limitation, the text, descriptions, software, applications, source code, object code, graphics,
            photos, sounds, videos, interactive features and services (collectively, the &ldquo;
          </span>
          <strong>Materials</strong>
          <span className={classes.fw400}>
            &rdquo;); and (ii) the trademarks, service marks and logos contained therein (&ldquo;
          </span>
          <strong>Marks</strong>
          <span className={classes.fw400}>,&rdquo; together with the Materials, the &ldquo;</span>
          <strong>Content</strong>
          <span className={classes.fw400}>
            &rdquo;) are the property of Grin and/or its third-party licensors and may be protected by applicable
            copyright or other intellectual property Laws and treaties.&nbsp; &ldquo;Get-Grin&rdquo;,
            &ldquo;Grin&rdquo;, the Grin logo, and other marks are Marks of Grin or its affiliates.&nbsp;&nbsp;All other
            trademarks, service marks and logos used on the Devices and the Grin Services are the trademarks, service
            marks, or logos of their respective owners.&nbsp; We reserve all rights not expressly granted in and to the
            Content.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Use of Content.&nbsp; The Content is provided to you for your use only in connection with the Services, and
            may not be used, modified, copied, distributed, transmitted, broadcast, displayed, sold, licensed,
            de-compiled, or otherwise exploited for any other purposes except in connection with the advertising,
            marketing and promotion of the Services, subject to Grin&rsquo;s prior written approval, which approval
            shall not be unreasonably withheld. &nbsp;If you download or print a copy of the Content you must retain all
            copyright and other proprietary notices contained therein. You shall not make any changes to any of the
            promotional or other marketing materials provided by Grin without Grin&rsquo;s prior written approval, which
            approval shall not be unreasonably withheld.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Feedback.&nbsp; If you choose to provide input, suggestions, comments, feedback, improvement requests or
            other recommendations regarding the Devices or the Services (collectively, &ldquo;
          </span>
          <strong>Feedback</strong>
          <span className={classes.fw400}>
            &rdquo;), then you hereby grant Grin a worldwide, irrevocable, non-exclusive, fully paid, royalty-free,
            perpetual, sublicensable and transferable license to use or otherwise exploit the Feedback in any manner and
            for any purpose without restriction. You should not submit any Feedback to us that you do not wish to
            license to us as stated above. We have no obligation (i) to maintain any Feedback in confidence; (ii) to pay
            any compensation for any Feedback; or (iii) to respond to any Feedback. You grant us the right to use the
            name that you submit in connection with any Feedback.
          </span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <ol>
        <li className={classes.fw400}>
          <strong>Warranty Disclaimers</strong>
          <span className={classes.fw400}>.</span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            This Section 10 applies whether or not you have paid for the Devices or the Services. SOME JURISDICTIONS DO
            NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES. IF YOU RESIDE IN SUCH A JURISDICTION, THE AGREEMENT, INCLUDING
            THESE TERMS, APPLY TO YOU TO THE FULLEST EXTENT SUCH EXCLUSIONS ARE PERMITTED UNDER THE LAWS OF THE
            JURISDICTION WHERE YOU RESIDE.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Grin hereby represents and warrants to you that each Device will be free from material defects in materials
            and workmanship under normal use and will operate substantially in accordance with the specifications and
            any instructions provided by Grin (the &ldquo;
          </span>
          <strong>Limited Warranty</strong>
          <span className={classes.fw400}>
            &rdquo;) for a period of ninety (90) days from the delivery date of the Device (the &ldquo;
          </span>
          <strong>Warranty Period</strong>
          <span className={classes.fw400}>&rdquo;).</span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            The Limited Warranty in Section 10(b) will not apply if the defect(s) relate to (i) normal wear and tear;
            (ii) defects caused by knowing or intentional misuse; (iii) non-compliance with the terms of these
            Terms;&nbsp;(iv) willful or deliberate damage; or (v) any failure by you to package the Device adequately
            for transportation to Grin in accordance with the Returns Policy.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            EXCEPT FOR THE LIMITED WARRANTY SET FORTH IN SECTIONS 10(B) AND 10(C), THE DEVICES AND THE SERVICES ARE
            PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS, AND WITHOUT REPRESENTATIONS,
            WARRANTIES OR CONDITIONS OF ANY KIND EITHER EXPRESS OR IMPLIED.&nbsp; GRIN HEREBY DISCLAIMS ALL WARRANTIES,
            EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
            PARTICULAR PURPOSE, NON-INFRINGEMENT, AND THOSE ARISING BY STATUTE OR FROM A COURSE OF DEALING OR USAGE OF
            TRADE PRACTICE.&nbsp; GRIN DOES NOT GUARANTEE THAT THE GRIN SERVICES, INCLUDING THE PLATFORM, WILL BE FREE
            OF BUGS, MALFUNCTIONS, SECURITY BREACHES, VIRUS ATTACKS, OR ILLEGAL PENETRATIONS. THE SERVICES MAY
            OCCASIONALLY BE UNAVAILABLE FOR ROUTINE MAINTENANCE, UPGRADING, OR OTHER REASONS.&nbsp; GRIN DOES NOT
            WARRANT THAT ANY OF THE AFOREMENTIONED ISSUES WILL BE CORRECTED. YOU AGREE THAT GRIN WILL NOT BE HELD
            RESPONSIBLE FOR ANY CONSEQUENCES TO YOU, ANY PROVIDER, USER, PATIENT OR OTHER THIRD PARTY THAT MAY RESULT
            FROM TECHNICAL PROBLEMS OF THE INTERNET, SLOW CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF OUR OR OTHER
            SERVERS.&nbsp; WE DO NOT WARRANT, ENDORSE OR GUARANTEE ANY CONTENT, PRODUCT OR SERVICE THAT IS FEATURED OR
            ADVERTISED ON THE SERVICES BY A THIRD PARTY.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            GRIN SHALL NOT BE LIABLE TO YOU, YOUR PROVIDERS, YOUR PATIENTS OR OTHER USERS IN ANY WAY FOR THE RESULTS OR
            OUTCOMES OF THE DEVICES OR THE SERVICES PROVIDED HEREUNDER OR FOR ANY ORTHODONTIC, MEDICAL OR CLINICAL
            ADVICE, TREATMENT OR DIAGNOSTIC PROVIDED THROUGH THE DEVICES OR THE SERVICES, INCLUDING, WITHOUT LIMITATION,
            FOR YOUR OR YOUR PROVIDERS&rsquo; OR USERS&rsquo; ACTS OR OMISSIONS AND/OR OR ANY ACTIVITIES OCCURRING ON
            THE PREMISES OF YOUR PRACTICE. FOR CLARITY, ALL THE ABOVE ARE YOUR RESPONSIBILITY AND NOT GRIN&rsquo;S
            RESPONSIBILITY.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            YOU HEREBY ACKNOWLEDGE THAT YOUR, YOUR PROVIDERS&rsquo; AND YOUR USERS&rsquo; USE OF THE DEVICES AND THE
            GRIN SERVICES IS VOLUNTARY AND AT YOUR OWN RISK, AND YOU AGREE THAT GRIN WILL NOT BE HELD RESPONSIBLE OR
            LIABLE&nbsp; FOR ANY CONSEQUENCES (SUCH AS, BUT NOT LIMITED TO, HEALTH PROBLEMS, PERSONAL INJURY OR DEATH)
            TO ANY PATIENT OR OTHER THIRD PARTY THAT RESULT FROM (I) YOUR, YOUR PROVIDERS&rsquo; AND YOUR USERS&rsquo;
            USE OF (OR INABILITY TO USE) THE DEVICES OR THE SERVICES OR (II) YOUR, YOUR PROVIDERS&rsquo; AND YOUR
            USERS&rsquo; NEGLECT OR MISUSE OF THE DEVICES OR THE SERVICES. YOU ACKNOWLEDGE THAT GRIN IS NOT A LICENSED
            MEDICAL CARE PROVIDER AND THAT YOU, YOUR PROVIDERS AND YOUR USERS ARE SOLELY RESPONSIBLE FOR THE DENTAL,
            ORTHODONTIC OR MEDICAL INFORMATION OR ADVICE PROVIDED BY YOU OR YOUR PROVIDERS THROUGH THE DEVICES AND THE
            SERVICES. YOU HEREBY ACKNOWLEDGE THAT ANY DENTAL, ORTHODONTIC, MEDICAL, OR OTHER SIMILAR PROFESSIONAL ADVICE
            PROVIDED VIA THE DEVICES OR THE SERVICES IS YOUR RESPONSIBILITY.&nbsp;
          </span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <ol>
        <li className={classes.fw400}>
          <strong>Limitation of Liability</strong>
          <span className={classes.fw400}>.</span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            TO THE FULLEST EXTENT PERMISSIBLE BY LAW, NEITHER GRIN NOR ANY OF ITS AFFILIATES&rsquo; OR THEIR RESPECTIVE
            EMPLOYEES, OFFICERS, DIRECTORS, PARTNERS, MEMBERS, ADVISORS OR AGENTS (COLLECTIVELY, THE &ldquo;
          </span>
          <strong>GRIN PARTIES</strong>
          <span className={classes.fw400}>
            &rdquo;) SHALL BE LIABLE FOR LOST PROFITS, LOST BUSINESS OPPORTUNITIES OR COSTS OF PROCUREMENT OF SUBSTITUTE
            GOODS BY ANYONE OR FOR ANY DIRECT, INDIRECT, EXEMPLARY, SPECIAL, CONSEQUENTIAL, PUNITIVE OR INCIDENTAL
            DAMAGES OF ANY KIND, OR FOR ANY LOSS OF DATA, REPUTATION OR ANY OTHER INTANGIBLE LOSS, ARISING FROM OR IN
            CONNECTION WITH THE AGREEMENT, YOUR DENTAL OR ORTHODONTIC SERVICES OR OUT OF YOUR ACCESS OR USE OF, OR
            INABILITY TO ACCESS OR USE, THE DEVICES OR THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT
            (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, EVEN IF ANY GRIN PARTY HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES OR LOSSES. Some jurisdictions do not allow the limitation or exclusion of
            liability for incidental or consequential damages, so the above limitations may not apply to you.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            IN NO EVENT SHALL THE AGGREGATE LIABILITY OF GRIN FOR ANY DAMAGES ARISING UNDER THE AGREEMENT, YOUR PRACTICE
            OR OUT OF YOUR ACCESS OR USE OF, OR INABILITY TO ACCESS AND USE, THE DEVICES OR THE SERVICES EXCEED THE
            TOTAL AMOUNT OF FEES PAID BY YOU TO GRIN WITH RESPECT TO DEVICES AND SERVICES IN THE TWELVE (12) MONTH
            PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            EACH PROVISION OF THE AGREEMENT THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR
            EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THE AGREEMENT.
            THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE
            PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THE AGREEMENT. THE LIMITATIONS IN THIS
            SECTION 11 WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
          </span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <ol>
        <li className={classes.fw400}>
          <strong>Indemnity</strong>
          <span className={classes.fw400}>
            .&nbsp; To the fullest extent permitted by Law, you agree to defend, indemnify and hold harmless each Grin
            Party from and against any and all claims, damages, obligations, losses, liabilities, costs and expenses
            (including but not limited to attorney&rsquo;s fees and costs) arising from (i) the breach or violation of
            the Agreement, including these Terms, by you or any of your Providers, Users, employees, contractors or
            other representatives (each, a &ldquo;
          </span>
          <strong>Practice Party</strong>
          <span className={classes.fw400}>
            &rdquo;); (ii) the misuse of the Devices or any of the Services by any Practice Party; (iii) the violation
            of Law by any Practice Party; or (iv) any claims asserted by or on behalf of any Patient related to, or in
            connection with, the dental, orthodontic and other services that you or any Practice Party provides.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Dispute Resolution and Arbitration</strong>
          <span className={classes.fw400}>.</span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span className={classes.fw400}>
              Generally. In the interest of resolving disputes between you and Grin in the most expedient and
              cost-effective manner, and except as described in Sections 13(b) and 13(c), you and Grin agree that every
              dispute arising in connection with the Agreement will be resolved by binding arbitration. Arbitration is
              less formal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, may
              allow for more limited discovery than in court, and can be subject to very limited review by courts.
              Arbitrators can award the same damages and relief that a court can award. This agreement to arbitrate
              disputes includes all claims arising out of or relating to any aspect of the Agreement, including these
              Terms, whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and
              regardless of whether a claim arises during or after the termination of this Agreement. YOU UNDERSTAND AND
              AGREE THAT, BY ENTERING INTO THE AGREEMENT, YOU AND GRIN ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR
              TO PARTICIPATE IN A CLASS ACTION.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span className={classes.fw400}>
              Exceptions.&nbsp;Despite the provisions of Section 13(a), nothing in the Agreement will be deemed to
              restrict or otherwise limit the right of either party to: (i) bring an individual action in small claims
              court to the extent that the applicable requirements have been met; (ii) pursue an enforcement action
              through the applicable federal, state, provincial or local agency if that action is available; (iii) seek
              injunctive relief in a court of law in aid of arbitration; or (d) to file suit in any court of competent
              jurisdiction to address an intellectual property infringement claim.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span className={classes.fw400}>
              Opt-Out. If you do not wish to resolve disputes by binding arbitration, you may opt out of the provisions
              of this Section 13 within 30 days after the date that you enter into the Agreement by sending a letter to
              Get-Grin, Inc., Attention: Legal Department &ndash; Arbitration Opt-Out, 382A Rte 59, Airmont, NY 10952
              that specifies: your full legal name, the email address associated with your&nbsp; on the Services, and a
              statement that you wish to opt out of arbitration (&ldquo;
            </span>
            <strong>Opt-Out Notice</strong>
            <span className={classes.fw400}>
              &rdquo;). Once Grin receives your Opt-Out Notice, this Section 13 (other than Section 11(b)) will be null
              and void, and in that case, the exclusive jurisdiction and venue described in Section 17 will govern any
              action arising out of or related to the Agreement. The remaining provisions of the Agreement will not be
              affected by your Opt-Out Notice.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span className={classes.fw400}>
              Arbitration. Any arbitration between you and Grin will be settled under the Federal Arbitration Act and
              administered by the American Arbitration Association (&ldquo;
            </span>
            <strong>AAA</strong>
            <span className={classes.fw400}>
              &rdquo;) under its (i) Arbitration Rules if you are located in the United States or (ii) International
              Arbitration Rules if you are located outside of the United States (collectively, &ldquo;
            </span>
            <strong>AAA Rules</strong>
            <span className={classes.fw400}>
              &rdquo;) as modified by the Agreement. The AAA Rules and filing forms are available online
              at&nbsp;www.adr.org, by calling the AAA at 1-800-778-7879, or by contacting Grin. The arbitrator has
              exclusive authority to resolve any dispute relating to the interpretation, applicability or enforceability
              of this binding arbitration agreement. Any arbitration hearing will take place at a location to be agreed
              upon in New York County, New York, but if the claim is for $10,000 USD or less, you may choose whether the
              arbitration will be conducted: (i) solely on the basis of documents submitted to the arbitrator or (ii)
              through a non-appearance based telephone hearing. Regardless of the manner in which the arbitration is
              conducted, the arbitrator must issue a reasoned written decision sufficient to explain the essential
              findings and conclusions on which the decision and award, if any, are based.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span className={classes.fw400}>
              Notice of Arbitration; Process. A party who intends to seek arbitration must first send a written notice
              of the dispute to the other party by certified or registered mail or by Federal Express (signature
              required) or, only if that other party has not provided a current physical address, then by electronic
              mail (&ldquo;
            </span>
            <strong>Notice of Arbitration</strong>
            <span className={classes.fw400}>
              &rdquo;). Grin&rsquo;s address for Notice is: Get-Grin, Inc., 382A Rte 59, Airmont, NY 10952. The Notice
              of Arbitration must: (i) describe the nature and basis of the claim or dispute and (ii) set forth the
              specific relief sought (&ldquo;
            </span>
            <strong>Demand</strong>
            <span className={classes.fw400}>
              &rdquo;). The parties will make good faith efforts to resolve the claim directly, but if the parties do
              not reach an agreement to do so within thirty (30) days after the Notice of Arbitration is received,
              either you or Grin may commence an arbitration proceeding. All arbitration proceedings between the parties
              will be confidential unless otherwise agreed by the parties in writing. During the arbitration, the amount
              of any settlement offer made by you or Grin must not be disclosed to the arbitrator until after the
              arbitrator makes a final decision and award, if any. If the arbitrator awards you an amount higher than
              the last written settlement amount offered by Grin in settlement of the dispute prior to the award, then
              Grin will pay to you the higher of: (i) the amount awarded by the arbitrator or (ii) $10,000.00 USD.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span className={classes.fw400}>
              Fees; Prevailing Party. If you commence arbitration in accordance with the Agreement, Grin will reimburse
              you for your payment of the filing fee, unless your claim is for more than $10,000 USD, in which case the
              payment of any fees will be decided by the AAA Rules. The party prevailing in any arbitration shall be
              entitled to receive in addition to all other damages to which it may be entitled, the costs incurred by
              such party in conducting the suit, action or proceeding, including reasonable attorneys&rsquo; fees and
              expenses and court costs.&nbsp;The arbitrator may make rulings and resolve disputes as to the payment and
              reimbursement of fees or expenses at any time during the proceeding and upon request from either party
              made within fourteen (14) days of the arbitrator&rsquo;s ruling on the merits.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span className={classes.fw400}>
              No Class Actions. YOU AND GRIN AGREE THAT EACH PARTY MAY BRING CLAIMS AGAINST THE OTHER PARTY ONLY IN YOUR
              OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
              PROCEEDING. Further, unless both you and Grin otherwise agree, the arbitrator may not consolidate more
              than one person&rsquo;s claims and may not otherwise preside over any form of a representative or class
              proceeding.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span className={classes.fw400}>
              Modifications to this Arbitration Provision. If Grin makes any future change to this arbitration
              provision, other than a change to Grin&rsquo;s address for Notice of Arbitration, you may reject the
              change by sending us written notice within 30 days of the change to Grin&rsquo;s address for Notice of
              Arbitration, in which case (i) your&nbsp; with Grin and your right to access and use the Devices, the Grin
              Services and the Platform will be immediately terminated and (ii) this arbitration provision, as in effect
              immediately prior to the changes you rejected will survive.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span className={classes.fw400}>
              Enforceability. If Section 13(h) or the entirety of this Section 13 is found to be unenforceable, then the
              entirety of this Section 13 (other than Section 13(b)) will be null and void and, in that case, the
              exclusive jurisdiction and venue described in Section 19 will govern any action arising out of or related
              to the Agreement, including these Terms, the Devices or any of the Services.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span className={classes.fw400}>
              YOU AGREE THAT ANY CAUSE OF ACTION THAT YOU MAY HAVE ARISING OUT OF OR RELATED TO YOUR USE OF THE DEVICES
              OR THE GRIN SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES.&nbsp; OTHERWISE,
              SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>Termination</strong>
            <span className={classes.fw400}>.</span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span className={classes.fw400}>
              Grin.&nbsp; If you breach or violate any term of this Agreement, Grin may, upon prior written notice to
              you, suspend your Subscription and your ability to access and use the Services. You agree that Grin, in
              its sole discretion, for any or no reason and without penalty, may terminate your access and use of the
              Devices or any of the Services or any Account (or any part thereof) that you may have with Grin and remove
              and discard all or any part of your Account or user profile, at any time. Grin may also, in its sole
              discretion and at any time, prohibit you from accessing and using the Devices and the Services or
              discontinue providing access to the Services, or any part thereof, with or without notice. You agree that
              any termination of your ability to access or use the Devices or the Services or any Account you may have,
              or portion thereof, may be affected without prior notice, and you agree that Grin will not be liable to
              you or any third party for any such termination or suspension.&nbsp; These remedies are in addition to any
              other remedies that Grin may have at law or in equity. As provided herein, Grin does not permit copyright
              infringing activities on the Site or any of the other Services, and Grin shall be permitted to terminate
              access to the Site or any of the other Services. BY ACCEPTING THE AGREEMENT, INCLUDING THESE TERMS, YOU
              WAIVE AND SHALL HOLD EACH GRIN PARTY HARMLESS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY GRIN
              DURING OR AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF
              INVESTIGATIONS BY EITHER GRIN OR LAW ENFORCEMENT AUTHORITIES.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span className={classes.fw400}>
              You. Your only remedy with respect to any dissatisfaction with (i) the Devices or any Services; (ii) any
              term of the Agreement, including these Terms; or (iii) any policy or practice of Grin in operating the
              Services, is to terminate your Subscription and your use of the Devices and Services and your Account (if
              any). You may terminate your use of the Services and your Subscription (if any) at any time by sending an
              email request to us at&nbsp;
            </span>
            <a href="mailto:support@get-grin.com">
              <span className={classes.fw400}>support@get-grin.com</span>
            </a>
            <span className={classes.fw400}>
              &nbsp;with the relevant Order reference number. Any Subscription cancellation shall become effective at
              the end of your current Subscription term (the &ldquo;
            </span>
            <strong>Cancellation Effective Date</strong>
            <span className={classes.fw400}>
              &rdquo;). You shall remain liable for all amounts due under your Subscription through the Cancellation
              Effective Date. If you have elected monthly billing for your annual subscription, you agree that Grin may
              continue charging your payment method monthly through the remaining portion of the Subscription term or
              may at its sole discretion accelerate any remaining balance owed through the end of the Subscription term
              and charge your payment method on file for such amount. For example, if you are on an annual subscription
              with a monthly billing cycle that started on January 5, 2023 and you cancel on February 15, 2023, then the
              cancellation will be effective on January 4, 2024 (the last day of your Subscription term), and you will
              remain liable for all amounts owed through the Cancellation Effective Date. Except as expressly provided
              herein, payments are nonrefundable and there are no refunds or credits for partially used Subscription
              periods. You may continue to use the benefits of your Subscription after cancellation through the
              Cancellation Effective Date.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span className={classes.fw400}>
              Upon termination of the Agreement for any reason, you shall cease all use of the Devices and the Services.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <span className={classes.fw400}>
              This Section 14 and Section 3 (Privacy), 7 (Prohibited Conduct), 9 (Intellectual Property Rights), 10
              (Warranty Disclaimers), 11 (Limitation of Liability), 12 (Indemnity), 13 (Dispute Resolution and
              Arbitration), Section 17 (Export), Section 19 (Governing Law) and Section 24 (Miscellaneous) shall survive
              the termination of the Agreement.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Independent Contractors</strong>
          <span className={classes.fw400}>
            .&nbsp; You and Grin are independent contractors. Nothing in the Agreement creates a partnership, joint
            venture, agency, or employment relationship between you and Grin. You must not under any circumstances make,
            or undertake, any warranties, representations, commitments or obligations on behalf of Grin.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Modification</strong>
          <span className={classes.fw400}>.</span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Grin reserves the right, in its sole discretion, to change, modify, add or remove portions of these Terms at
            any time by posting the amended Terms to the Site or any of the other Services. If Grin updates these Terms,
            it will update the &ldquo;last updated&rdquo; date at the top of the Terms.&nbsp; Please check these Terms,
            including any Additional Terms, periodically for changes. Your continued use of the Devices or Services
            after the posting of changes constitutes your binding acceptance of such changes. In the event of a change
            to these Terms that materially modifies your rights or obligations, Grin will use commercially reasonable
            efforts to notify you of such change. Grin may provide notice through a pop-up or banner within any of the
            Services, by sending an email to any address you may have used to register for an Account, or through other
            similar mechanisms. Additionally, if the changed Terms materially modify your rights or obligations, Grin
            may require you to provide consent by accepting the changed Terms. If Grin requires your acceptance of the
            changed Terms, changes are effective only after your acceptance. For all other changes, except as stated
            elsewhere by Grin, such amended Terms will automatically be effective, replacing the previously-effective
            Terms or fees, thirty (30) days after they are initially posted on any of the Services.&nbsp;&nbsp;IF AT ANY
            TIME YOU DO NOT AGREE TO THESE TERMS, PLEASE IMMEDIATELY TERMINATE YOUR USE OF ALL SERVICES.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            To the extent that any modifications to the Terms or Additional Terms are not allowed under applicable Laws,
            the prior most recent version of the Terms or Additional Terms shall continue to apply.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Grin reserves the right to modify, in its absolute discretion and without notice to you (or any Provider or
            other User), the Devices, the Services and their content, including its design and the services that are
            provided through them.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Export Laws</strong>
          <span className={classes.fw400}>
            .&nbsp;&nbsp;You agree to comply fully with all applicable export laws and regulations to ensure that none
            of the Devices, Services nor any technical data related thereto are exported or re-exported directly or
            indirectly in violation of, or used for any purposes prohibited by, such laws and regulations.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Assignment</strong>
          <span className={classes.fw400}>
            .&nbsp;&nbsp;The Agreement, and any rights and licenses granted thereunder, may not be transferred or
            assigned by you, in whole or in part, without Grin&rsquo;s prior written consent, but may be assigned by
            Grin without restriction or notification to you. Any prohibited assignment shall be null and void.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Governing Law</strong>
          <span className={classes.fw400}>
            .&nbsp;&nbsp;The Agreement, including these Terms, and the relationship between you and Grin shall be
            governed by and construed in accordance with the laws of the State of New York, without regard to its
            principles of conflict of laws. The exclusive jurisdiction in every matter and/or issue regarding the
            Agreement that is not subject to arbitration or any exception pursuant to Section 13 will be exclusively
            granted to the state and Federal courts in New York County, New York.&nbsp; The party prevailing in any
            legal suit, action or proceeding shall be entitled to receive in addition to all other damages to which it
            may be entitled, the costs incurred by such party in conducting the suit, action or proceeding, including
            reasonable attorneys&rsquo; fees and expenses and court costs.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Additional Terms</strong>
          <span className={classes.fw400}>
            . Your Subscription and your access and use of the Devices and the Services are subject to all additional
            terms, policies, rules or guidelines applicable to the Devices and the Grin Services that we may post on or
            link to or from the Site, the Grin Services or the Platform (collectively, the &ldquo;
          </span>
          <strong>Additional Terms</strong>
          <span className={classes.fw400}>
            &rdquo;). All Additional Terms are incorporated by this reference into, and made a part of, the Agreement.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Consent to Electronic Communications</strong>
          <span className={classes.fw400}>
            . By using the Devices and the Services, you consent to receiving certain electronic communications from us
            as further described in our Privacy Policy. Please read our Privacy Policy to learn more about our
            electronic communications practices. You agree that any notices, agreements, disclosures or other
            communications that we send to you electronically will satisfy any legal communication requirements,
            including that those communications be in writing.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Support</strong>
          <span className={classes.fw400}>
            . Support shall be provided via email at&nbsp;support@get-grin.com&nbsp;or through the &ldquo;Help&rdquo;
            link on our website between the hours of 9:00 am and 5:00 pm EST Monday to Friday, excluding all Federal,
            state and other company holidays. Grin shall use commercially reasonable efforts to respond to any issues
            within one (1) business day.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Geographic Restrictions</strong>
          <span className={classes.fw400}>
            . Grin is based in the state of New York in the United States. The Devices and the Services are intended
            solely for users located within the United States and Canada. Grin makes no claims that accessing or using
            the Devices or the Services or any of the content is accessible or appropriate outside of the United States
            or Canada. Accessing or using the Devices or the Services may not be legal by certain persons or in certain
            countries. If you access or use the Devices or the Services from outside the United States or Canada, you do
            so on your own initiative and are responsible for compliance with local laws and you agree to waive, and
            hereby do waive, any legal or equitable rights or remedies you have or may have against Grin with respect
            thereto.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Miscellaneous</strong>
          <span className={classes.fw400}>
            .&nbsp;The Agreement, including these Terms, the Order and the Privacy Policy, constitute the entire
            agreement between you and Grin concerning your Subscription, the Devices and Services. Use of section
            headers in the Agreement is for convenience only and will not have any impact on the interpretation of any
            provision. Throughout the Agreement, the use of the word &ldquo;including&rdquo; means &ldquo;including but
            not limited to.&rdquo;&nbsp; If any provision of the Agreement is deemed invalid by a court of competent
            jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of
            the Agreement, which shall remain in full force and effect.&nbsp; NO WAIVER OF ANY TERM OF THE AGREEMENT
            SHALL BE DEEMED A FURTHER OR CONTINUING WAIVER OF SUCH TERM OR ANY OTHER TERM, AND A PARTY&rsquo;S FAILURE
            TO ASSERT ANY RIGHT OR PROVISION UNDER THE AGREEMENT SHALL NOT CONSTITUTE A WAIVER OF SUCH RIGHT OR
            PROVISION.&nbsp; You hereby waive any applicable rights to require an original (non-electronic) signature or
            delivery or retention of non-electronic records, to the extent not prohibited under applicable Law.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Contact Information</strong>
          <span className={classes.fw400}>
            . The Devices and the Services are offered by Get-Grin Inc. and/or Grin Group, LLC, located at 382A Rte 59,
            Airmont, NY 10952.&nbsp; If&nbsp;you have any questions, complaints, or claims regarding the Devices or any
            of the Services,&nbsp;you may contact us by sending correspondence to that address or by emailing us
            at&nbsp;
          </span>
          <a href="mailto:support@get-grin.com">
            <span className={classes.fw400}>support@get-grin.com</span>
          </a>
          <span className={classes.fw400}>.</span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;&nbsp;</span>
      </p>
      <ol>
        <li className={classes.fw400}>
          <strong>Notice to California Residents</strong>
          <span className={classes.fw400}>
            . If you are a California resident, under California Civil Code Section 1789.3, you may contact the
            Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer
            Affairs in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone at
            (800) 952-5210 in order to resolve a complaint regarding the Devices or the Services or to receive further
            information regarding use of the Devices or the Services.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>English Language</strong>
          <span className={classes.fw400}>
            .&nbsp;It is the express wish of the parties that the Agreement and related documents be drawn up in
            English. Il est de la volont&eacute; expresse des parties que cette convention et tous les documents
            s&rsquo;y rattachant soient r&eacute;dig&eacute;s en anglais.
          </span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <p>
        <br />
        <br />
        <br />
      </p>
      <p>
        <span className={classes.fw400}>&copy; Get-Grin Inc. 2023</span>
      </p>
      <p>
        <strong>EXHIBIT A</strong>
      </p>
      <p>
        <strong>HIPAA BUSINESS ASSOCIATE AGREEMENT</strong>
      </p>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <p>
        <span className={classes.fw400}>
          This HIPAA Business Associate Agreement (&ldquo;BAA&rdquo;) is entered into and effective (the
          &ldquo;Effective Date&rdquo;) upon your acceptance of the Terms between you (&ldquo;Covered Entity&rdquo;) and
          Get-Grin, Inc. (&ldquo;Business Associate&rdquo;) (each a &ldquo;Party&rdquo;&nbsp;and collectively, the
          &ldquo;Parties&rdquo;).
        </span>
      </p>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <p>
        <strong>RECITALS</strong>
      </p>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Covered Entity is a &ldquo;covered entity&rdquo; as that term is defined under the Health Insurance
            Portability and Accountability Act of 1996 (Public Law 104-91), as amended, (&ldquo;HIPAA&rdquo;), and the
            regulations promulgated thereunder by the Secretary of the U.S. Department of Health and Human Services
            (&ldquo;Secretary&rdquo;), including, without limitation, the regulations codified at 45 C.F.R. Parts 160
            and 164 (&ldquo;HIPAA Regulations&rdquo;);
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Business Associate performs Services (as defined below) for or on behalf of Covered Entity, and in
            performing said Services; Business Associate creates, receives, maintains, or transmits Protected Health
            Information (&ldquo;PHI&rdquo;);
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            The Parties intend to protect the privacy and provide for the security of PHI Disclosed by Covered Entity to
            Business Associate, or received or created by Business Associate, when providing Services in compliance with
            HIPAA, the Health Information Technology for Economic and Clinical Health Act (Public Law 111-005)
            (&ldquo;the&nbsp;HITECH Act&rdquo;) and its implementing regulations and guidance issued by the Secretary,
            and other applicable state and federal laws, all as amended from time to time; and
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            As a covered entity, Covered Entity is required under HIPAA to enter into a BAA with Business Associate that
            meets certain requirements with respect to the Use and Disclosure of PHI, which are met by this BAA.
          </span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <p>
        <strong>AGREEMENT</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span className={classes.fw400}>
          In consideration of the Recitals and for other good and valuable consideration, the receipt and adequacy of
          which is hereby acknowledged, the Parties agree as follows:
        </span>
      </p>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <p>
        <strong>ARTICLE I</strong>
        <strong>
          <br />
        </strong>
        <strong>DEFINITIONS</strong>
      </p>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <p>
        <span className={classes.fw400}>
          The following terms shall have the meaning set forth below.&nbsp; Capitalized terms used in this BAA and not
          otherwise defined shall have the meanings ascribed to them in HIPAA, the HIPAA Regulations, or the HITECH Act,
          as applicable.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            &ldquo;Breach&rdquo; shall have the meaning given under 42 U.S.C. &sect; 17921(1) and 45 C.F.R. &sect;
            164.402.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            &ldquo;Designated Record Set&rdquo; shall have the meaning given such term under 45 C.F.R. &sect; 164.501.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            &ldquo;Disclose&rdquo; and &ldquo;Disclosure&rdquo; mean, with respect to PHI, the release, transfer,
            provision of access to, or divulging in any other manner of PHI outside of Business Associate or to other
            than members of its Workforce, as set forth in 45 C.F.R. &sect; 160.103.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            &ldquo;Electronic PHI&rdquo; or &ldquo;e-PHI&rdquo; means PHI that is transmitted or maintained in
            electronic media, as set forth in 45 C.F.R. &sect; 160.103.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            &ldquo;Protected Health Information&rdquo; and &ldquo;PHI&rdquo; mean any information, whether oral or
            recorded in any form or medium, that: (a) relates to the past, present or future physical or mental health
            or condition of an individual; the provision of health care to an individual, or the past, present or future
            payment for the provision of health care to an individual; (b) identifies the individual (or for which there
            is a reasonable basis for believing that the information can be used to identify the individual); and (c)
            shall have the meaning given to such term under the Privacy Rule, including, but not limited to, 45 C.F.R.
            &sect; 160.103.&nbsp; Protected Health Information includes e-PHI.
          </span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;&nbsp;</span>
      </p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            &ldquo;Security Incident&rdquo; shall have the meaning given to such term under 45 C.F.R. &sect; 164.304.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            &ldquo;Services&rdquo; shall mean the services for or functions on behalf of Covered Entity provided by
            Business Associate pursuant to any service or other agreement(s) between Covered Entity and Business
            Associates which may be in effect now or from time to time (&ldquo;Underlying Agreement&rdquo;), or, if no
            such agreement is in effect, the services or functions performed by Business Associate that constitute a
            Business Associate relationship, as set forth in 45 C.F.R. &sect; 160.103.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            &ldquo;Unsecured PHI&rdquo; shall have the meaning given to such term under 42 U.S.C. &sect; 17932(h), 45
            C.F.R. &sect; 164.402, and guidance issued pursuant to the HITECH Act including, but not limited to the
            guidance issued on April 17, 2009 and published in 74 Federal Register 19006 (April 27, 2009) by the
            Secretary.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            &ldquo;Use&rdquo; or &ldquo;Uses&rdquo; mean, with respect to PHI, the sharing, employment, application,
            utilization, examination or analysis of such PHI within Business Associate&rsquo;s internal operations, as
            set forth in 45 C.F.R. &sect; 160.103.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            &ldquo;Workforce&rdquo; shall have the meaning given to such term under 45 C.F.R. &sect; 160.103.
          </span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <p>
        <strong>ARTICLE II</strong>
        <strong>
          <br />
        </strong>
        <strong>TERM AND TERMINATION</strong>
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>Term</strong>
            <span className={classes.fw400}>
              .&nbsp;&nbsp; The term of this Agreement shall be effective as of the Effective Date and shall terminate
              upon the termination of the Underlying Agreement between the Parties, unless earlier terminated pursuant
              to the terms hereof.
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>Termination for Cause</strong>
            <span className={classes.fw400}>
              . &nbsp;Upon Covered Entity&rsquo;s knowledge of a material breach or violation of this BAA by Business
              Associate, Covered Entity shall either:
            </span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Notify Business Associate of the breach in writing, and provide an opportunity for Business Associate to
            cure the breach or end the violation within ten (10) business days of such notification; provided that if
            Business Associate fails to cure the breach or end the violation within such time period to the satisfaction
            of Covered Entity, Covered Entity may immediately terminate this BAA upon written notice to Business
            Associate;&nbsp;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Upon written notice to Business Associate, immediately terminate this BAA if Covered Entity determines that
            such breach cannot be cured; or
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            If neither termination nor cure is feasible, Covered Entity shall report the violation to the Secretary.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>Disposition of Protected Health Information Upon Termination or Expiration</strong>
            <span className={classes.fw400}>.</span>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Upon termination or expiration of this BAA, Business Associate shall either return or destroy all PHI
            received from, or created or received by Business Associate on behalf of Covered Entity, that Business
            Associate still maintains in any form and retain no copies of such PHI.&nbsp; If Covered Entity requests
            that Business Associate return PHI, PHI shall be returned in a mutually agreed upon format and timeframe, at
            no additional charge to Covered Entity.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            If return or destruction is not feasible, Business Associate shall (a) retain only that PHI which is
            necessary for Business Associate to continue its proper management and administration or to carry out its
            legal responsibilities; (b) return to Covered Entity the remaining PHI that Business Associate still
            maintains in any form; (c) continue to extend the protections of this BAA to the PHI for as long as Business
            Associate retains the PHI; (d) limit further Uses and Disclosures of such PHI to those purposes that make
            the return or destruction of the PHI infeasible and subject to the same conditions set out in Section 3(a)
            and 3(b) above, which applied prior to termination; and (e) return to Covered Entity the PHI retained by
            Business Associate when it is no longer needed by Business Associate for its proper management and
            administration or to carry out its legal responsibilities.&nbsp;
          </span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <p>
        <strong>ARTICLE III</strong>
        <strong>
          <br />
        </strong>
        <strong>OBLIGATIONS OF BUSINESS ASSOCIATE</strong>
      </p>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <ol>
        <li className={classes.fw400}>
          <strong>Permitted Uses and Disclosures of Protected Health Information</strong>
          <span className={classes.fw400}>
            . &nbsp;Business Associate shall not Use or Disclose PHI other than to perform the Services, as permitted or
            required by this BAA, or as required by law.&nbsp; Business Associate shall not Use or Disclose PHI in any
            manner that would constitute a violation of Subpart E of 45 C.F.R. Part 164 if so Used or Disclosed by
            Covered Entity.&nbsp; However, Business Associate may Use or Disclose PHI (a) for the proper management and
            administration of Business Associate; (b) to carry out the legal responsibilities of Business Associate,
            provided that with respect to any such Disclosure either: (i) the Disclosure is Required by Law; or (ii)
            Business Associate obtains a written agreement from the person to whom the PHI is to be Disclosed that such
            person will hold the PHI in confidence and will not Use and further Disclose such PHI except as Required by
            Law and for the purpose(s) for which it was Disclosed by Business Associate to such person, and that such
            person will notify Business Associate of any instances of which it is aware in which the confidentiality of
            the PHI has been breached; (c) for Data Aggregation purposes for the Health Care Operations of Covered
            Entity.&nbsp; To the extent that Business Associate carries out one or more of Covered Entity&rsquo;s
            obligations under Subpart E of 45 C.F.R. Part 164, Business Associate must comply with the requirements of
            Subpart E that apply to the Covered Entity in the performance of such obligations.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Prohibited Marketing and Sale of PHI</strong>
          <span className={classes.fw400}>
            .&nbsp;&nbsp; Notwithstanding any other provision in this BAA, Business Associate shall comply with the
            following requirements:&nbsp; (i) Business Associate shall not Use or Disclose PHI for fundraising or
            marketing purposes, except to the extent expressly authorized or permitted by this BAA and consistent with
            the requirements of 42 U.S.C. &sect; 17936, 45 C.F.R. &sect;&sect; 164.514(f), and 164.508(a)(3)(ii), and
            (iii) Business Associate shall not directly or indirectly receive remuneration in exchange for PHI except
            with the prior written consent of Covered Entity and as permitted by the HITECH Act, 42 U.S.C. &sect;
            17935(d)(2), and 45 C.F.R. &sect; 164.502(a)(5)(ii).
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Adequate Safeguards of PHI</strong>
          <span className={classes.fw400}>
            .&nbsp; Business Associate shall implement and maintain appropriate safeguards to prevent Use or Disclosure
            of PHI other than as provided for by this BAA.&nbsp; Business Associate shall reasonably and appropriately
            protect the confidentially, integrity, and availability of e-PHI that it creates, receives, maintains or
            transmits on behalf of Covered Entity in compliance with Subpart C of 45 C.F.R. Part 164 to prevent Use or
            Disclosure of PHI other than as provided for by this BAA.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Mitigation</strong>
          <span className={classes.fw400}>
            .&nbsp; Business Associate agrees to mitigate, to the extent practicable, any harmful effect that is known
            to Business Associate of a Use or Disclosure of PHI by Business Associate in violation of the requirements
            of this BAA.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Reporting Non-Permitted Use or Disclosure</strong>
          <span className={classes.fw400}>.</span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Reporting Security Incidents and Non-Permitted Use or Disclosure.&nbsp; Business Associate shall report to
            Covered Entity in writing each Security Incident or Use or Disclosure that is made by Business Associate,
            members of its Workforce or Subcontractors that is not specifically permitted by this BAA no later than
            three (3) business days after becoming aware of such Security Incident or non-permitted Use or Disclosure,
            in accordance with the notice provisions set forth herein.&nbsp; Business Associate shall investigate each
            Security Incident or non-permitted Use or Disclosure of Covered Entity&rsquo;s PHI that it discovers to
            determine whether such Security Incident or non-permitted Use or Disclosure constitutes a reportable Breach
            of Unsecured PHI.&nbsp; Business Associate shall document and retain records of its investigation of any
            Breach, including its reports to Covered Entity under this Article III, Section 5(a).&nbsp; Upon request of
            Covered Entity, Business Associate shall furnish to Covered Entity the documentation of its investigation
            and an assessment of whether such Security Incident or non-permitted Use or Disclosure constitutes a
            reportable Breach.&nbsp; If such Security Incident or non-permitted Use or Disclosure constitutes a
            reportable Breach of Unsecured PHI, then Business Associate shall comply with the additional requirements of
            Article III, Section 5(b) below.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            Breach of Unsecured PHI.&nbsp; If Business Associate determines that a reportable Breach of Unsecured PHI
            has occurred, Business Associate shall provide a written report to Covered Entity without unreasonable delay
            but no later than thirty (30) calendar days after discovery of the Breach. To the extent that information is
            available to Business Associate, Business Associate&rsquo;s written report to Covered Entity shall be in
            accordance with 45 C.F.R. &sect;164.410(c). Business Associate shall cooperate with Covered Entity in
            meeting Covered Entity&rsquo;s obligations under the HITECH Act with respect to such Breach. Covered Entity
            shall have sole control over the timing and method of providing notification of such Breach to the affected
            individual(s), the Secretary and, if applicable, the media, as required by the HITECH Act.&nbsp; Business
            Associate shall reimburse Covered Entity for its reasonable costs and expenses in providing the
            notification, including, but not limited to, any administrative costs associated with providing notice,
            printing and mailing costs, and costs of mitigating the harm (which may include the costs of obtaining
            credit monitoring services and identity theft insurance) for affected individuals whose PHI has or may have
            been compromised as a result of the Breach.&nbsp;
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Availability of Internal Practices, Books, and Records to Government</strong>
          <span className={classes.fw400}>
            .&nbsp; Business Associate agrees to make its internal practices, books and records relating to the Use and
            Disclosure of PHI received from, or created or received by, the Business Associate on behalf of Covered
            Entity available to the Secretary for purposes of determining Covered Entity&rsquo;s compliance with HIPAA,
            the HIPAA Regulations, and the HITECH Act.&nbsp; Except to the extent prohibited by law, Business Associate
            shall notify Covered Entity of all requests served upon Business Associate for information or documentation
            by or on behalf of the Secretary.&nbsp; Business Associate agrees to provide to Covered Entity proof of its
            compliance with the HIPAA Security Standards.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Access to and Amendment of Protected Health Information</strong>
          <span className={classes.fw400}>
            .&nbsp; To the extent that Business Associate maintains a Designated Record Set on behalf of Covered Entity
            and within fifteen (15) days of a request by Covered Entity, Business Associate shall (a) make the PHI it
            maintains (or which is maintained by its Subcontractors) in Designated Record Sets available to Covered
            Entity for inspection and copying, or to an individual to enable Covered Entity to fulfill its obligations
            under 45 C.F.R. &sect; 164.524, or (b) amend the PHI it maintains (or which is maintained by its
            Subcontractors) in Designated Record Sets to enable the Covered Entity to fulfill its obligations under 45
            C.F.R. &sect; 164.526.&nbsp; Business Associate shall not Disclose PHI to a health plan for payment or
            Health Care Operations purposes if and to the extent that Covered Entity has informed Business Associate
            that the patient has requested this special restriction, and has paid out of pocket in full for the health
            care item or service to which the PHI solely relates, consistent with 42 U.S.C. &sect; 17935(a) and 42
            C.F.R. &sect; 164.522(a)(1)(vi).&nbsp; If Business Associate maintains PHI in a Designated Record Set
            electronically, Business Associate shall provide such information in the electronic form and format
            requested by the Covered Entity if it is readily reproducible in such form and format, and, if not, in such
            other form and format agreed to by Covered Entity to enable Covered Entity to fulfill its obligations under
            42 U.S.C. &sect; 17935(e) and 45 C.F.R. &sect; 164.524(c)(2).&nbsp; Business Associate shall notify Covered
            Entity within fifteen (15) days of receipt of a request for access to PHI.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Accounting</strong>
          <span className={classes.fw400}>
            .&nbsp; To the extent that Business Associate maintains a Designated Record Set on behalf of Covered Entity,
            within thirty (30) days of receipt of a request from Covered Entity or an individual for an accounting of
            disclosures of PHI, Business Associate and its Subcontractors shall make available to Covered Entity the
            information required to provide an accounting of disclosures to enable Covered Entity to fulfill its
            obligations under 45 C.F.R. &sect; 164.528 and its obligations under 42 U.S.C. &sect; 17935(c).&nbsp;
            Business Associate shall notify Covered Entity within fifteen (15) days of receipt of a request by an
            individual or other requesting party for an accounting of disclosures of PHI.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Use of Subcontractors</strong>
          <span className={classes.fw400}>
            . &nbsp;Business Associate shall require each of its Subcontractors that creates, maintains, receives, or
            transmits PHI on behalf of Business Associate, to execute a Business Associate Agreement that imposes on
            such Subcontractors the same restrictions, conditions, and requirements that apply to Business Associate
            under this BAA with respect to PHI.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Minimum Necessary</strong>
          <span className={classes.fw400}>
            .&nbsp; Business Associate (and its Subcontractors) shall, to the extent practicable, limits its request,
            Use, or Disclosure of PHI to the minimum amount of PHI necessary to accomplish the purpose of the request,
            Use or Disclosure, in accordance with 42 U.S.C. &sect; 17935(b) and 45 C.F.R. &sect; 164.502(b)(1) or any
            other guidance issued thereunder.&nbsp;
          </span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <p>
        <strong>ARTICLE IV</strong>
        <strong>
          <br />
        </strong>
        <strong>MISCELLANEOUS</strong>
      </p>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Amendment to Comply with Law</strong>
          <span className={classes.fw400}>
            . &nbsp;This BAA shall be deemed amended to incorporate any mandatory obligations of Covered Entity or
            Business Associate under the HITECH Act and its implementing HIPAA Regulations.&nbsp; Additionally, the
            Parties agree to take such action as is necessary to amend this BAA from time to time as necessary for
            Covered Entity to implement its obligations pursuant to HIPAA, the HIPAA Regulations, or the HITECH Act.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Notices</strong>
          <span className={classes.fw400}>
            . Any notices required or permitted to be given hereunder by either Party to the other shall be given in
            writing:&nbsp; (1) by personal delivery; (2) by electronic mail or facsimile with confirmation sent by
            United States first class registered or certified mail, postage prepaid, return receipt requested; (3) by
            bonded courier or by a nationally recognized overnight delivery service; or (4) by United States first class
            registered or certified mail, postage prepaid, return receipt, in each case, addressed to a Party on the
            signature page(s) to this Agreement or to such other addresses as the Parties may request in writing by
            notice given pursuant to this Article IV, Section 2.&nbsp; Notices shall be deemed received on the earliest
            of personal delivery; upon delivery by electronic facsimile with confirmation from the transmitting machine
            that the transmission was completed; twenty-four (24) hours following deposit with a bonded courier or
            overnight delivery service; or seventy-two (72) hours following deposit in the U.S. mail as required herein.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Relationship of Parties</strong>
          <span className={classes.fw400}>
            .&nbsp; Business Associate is an independent contractor and not an agent of Covered Entity under this
            BAA.&nbsp; Business Associate has the sole right and obligation to supervise, manage, contract, direct,
            procure, perform or cause to be performed all Business Associate obligations under this BAA.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Survival</strong>
          <span className={classes.fw400}>
            .&nbsp; The respective rights and obligations of the Parties under Article II, Section 3 and Article IV,
            Sections 2 and 5 of this BAA shall survive the termination of this BAA.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Applicable Law and Venue</strong>
          <span className={classes.fw400}>
            . &nbsp;This Agreement shall be governed by and construed in accordance with the laws of the State of New
            York, without regards to its conflict of laws principles.&nbsp; The Parties agree that all actions or
            proceedings arising in connection with this BAA shall be tried and litigated exclusively in the State or
            federal (if permitted by law and if a Party elects to file an action in federal court) courts located in the
            State of New York.&nbsp;
          </span>
        </li>
      </ol>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <p>
        <strong>EXHIBIT B</strong>
      </p>
      <p>
        <strong>CANADIAN PRIVACY ADDENDUM</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <span className={classes.fw400}>
          In the event of any conflict between the Terms and this Canadian Privacy Addendum (&ldquo;Addendum&rdquo;),
          the terms and conditions of this Addendum shall control.&nbsp; Except as modified herein, all terms and
          conditions of the Terms shall remain in full force and effect.&nbsp;
        </span>
      </p>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <ol>
        <li className={classes.fw400}>
          <strong>Terms</strong>
          <span className={classes.fw400}>
            .&nbsp; Capitalized terms used but not otherwise defined in this Addendum have the meaning set out in the
            Terms. The term &ldquo;Personal Information&rdquo; means information of an identifiable individual
            transferred by Provider, or its permitted agents, to Grin or otherwise collected by Grin hereunder including
            patient name, mailing address, email address, age, images and video of dental and/or orthodontic treatments,
            and correspondence relating to same.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Grin Obligations</strong>
          <span className={classes.fw400}>
            .&nbsp;To the extent that Grin receives Personal Information in the course of providing the Grin Remote
            monitoring and Remote consultation software services under the Agreement, Grin shall:
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ul>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            collect, use, access, disclose, modify, or otherwise process Personal Information only to the extent
            necessary for the purpose of rendering the Services in accordance with this Addendum and as otherwise
            instructed by Provider in writing or as otherwise permitted hereunder.&nbsp;
          </span>
        </li>
      </ul>
      <ul>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            notify Provider without delay&nbsp;in writing of any (i) enquiry received from an individual relating to,
            among other things, the individual&rsquo;s right to access, modify or correct Personal Information, (ii)
            complaint received by Grin relating to the processing of Personal Information, and (iii) if not legally
            prohibited from doing so, order, demand, warrant or any other document purporting to compel the production
            of any Personal Information, and promptly co-operate with all reasonable instructions of Provider with
            respect to any action taken with respect to such enquiry or complaint.
          </span>
        </li>
      </ul>
      <ul>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            limit access to Personal Information to its employees and personnel who have a need for access to the
            Personal Information solely for the purposes of Grin rendering the Services under this Addendum.
          </span>
        </li>
      </ul>
      <ul>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            where Grin provides access to Personal Information to a third party (including an affiliate) or contracts
            such rights or obligations, enter into a written agreement with each contractor, sub processor or third
            party that imposes obligations on the contractor or third party that are substantially similar to those
            imposed on Grin under this Addendum.&nbsp;&nbsp;
          </span>
        </li>
      </ul>
      <ul>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            only retain Personal Information for as long as necessary to provide the Services or as otherwise instructed
            by Provider, and thereafter securely destroy or delete the information in accordance with medical record
            retention protocols.
          </span>
        </li>
      </ul>
      <ul>
        <li className={classes.fw400}>
          <span className={classes.fw400}>
            require its employees and other personnel with access to Personal Information to agree, in writing, to
            protect the confidentiality and security of Personal Information in accordance with the terms of this
            Addendum, and otherwise properly advise and train each of such employees and other personnel of the
            requirements of Grin under this Addendum and applicable privacy laws.
          </span>
        </li>
      </ul>
      <p>
        <span className={classes.fw400}>&nbsp;</span>
      </p>
      <ol>
        <li className={classes.fw400}>
          <strong>Data Security</strong>
          <span className={classes.fw400}>
            . Grin agrees that it has established and shall maintain throughout the Term an information security program
            designed to meet applicable laws and regulations. Grin agrees that such program includes administrative,
            technical, and physical safeguards designed to: (i) protect the security, confidentiality, and integrity of
            Personal Information; (ii) protect against anticipated threats or hazards to the security, confidentiality,
            and integrity of Personal information; (iii) protect against unauthorized access to, or use of, Personal
            Information; (iv) ensure compliance with an incident response program; and (v) ensure the proper disposal of
            Personal Information.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Security Incident; Response</strong>
          <span className={classes.fw400}>
            .&nbsp; Grin shall notify the Provider at the first reasonable opportunity if it becomes aware of the loss,
            theft or unauthorized access to, use or disclosure of Personal Information in the custody or control of Grin
            or other material breach of Grin&rsquo;s obligations under this Addendum. Grin shall use commercially
            reasonable efforts to address the security incident in a timely manner.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Provider Obligations</strong>
          <span className={classes.fw400}>
            .&nbsp; Provider agrees to provide all necessary notices and obtain all necessary authorizations or consents
            that may be required under and in accordance with applicable federal, provincial or local privacy laws,
            rules or regulations in order to transmit Personal Information to Grin and enable Grin and its authorized
            employees and personnel to use and share Personal Information as contemplated by the Agreement. Provider
            shall notify Grin of any withdrawals of consent or other restrictions on the use or disclosure of Personal
            Information. Grin shall have no responsibility to review the sufficiency or accuracy of such notices or
            consents. Provider shall ensure that all Personal Information is accurate and complete, and Grin will have
            no obligation to review or confirm the accuracy or completeness of any Personal Information.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Compliance with Laws</strong>
          <span className={classes.fw400}>
            .&nbsp;Grin and Provider shall comply with all applicable federal, provincial and local laws, rules and
            regulations concerning the privacy and security of Personal Information including, without limitation,
            federal and provincial private sector and health sector privacy legislation.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li className={classes.fw400}>
          <strong>Termination</strong>
          <span className={classes.fw400}>
            .&nbsp; Without limiting anything in the Agreement, Provider shall be entitled to terminate the Agreement in
            the event of Grin&rsquo;s material breach of this Addendum.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
    </div>
  )
}

export default USTermsDoc
