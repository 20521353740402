import { makeStyles } from '@material-ui/styles'
import { take, takeRight } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill'
import { countWhile } from 'utils/arrayUtils'
import { compareTags, TAGS_LIST_COMPRESSION_THRESHOLD } from 'utils/tagUtils'
import GrinChip from '../GrinChip'
import GrinMenu from '../menu/GrinMenu'
import GrinMenuItem from '../menu/GrinMenuItem'
import GrinTag from './GrinTag'
import TagsPicker from './TagsPicker'

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flex: 1,
    paddingTop: 4,
    paddingBottom: 4
  },
  visibleTag: {
    marginRight: 10
  },
  assigneeTag: {
    marginLeft: 10,
    color: 'var(--text-color-16)'
  },
  showMoreChip: {
    color: 'var(--text-color-16)',
    cursor: 'pointer'
  },
  progressContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'var(--bg-color-28)',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const TagsList = ({
  tags = [],
  assigneeTag = null,
  className,
  showIcon = true,
  canDeleteSystem = false,
  canDeleteCustom = true,
  canDeleteScan = true,
  onDeleteTag,
  observeResize = true,
  compressionThreshold = TAGS_LIST_COMPRESSION_THRESHOLD
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { width, ref } = useResizeDetector()

  const [displayedTags, setDisplayedTags] = useState([])
  const [hiddenTags, setHiddenTags] = useState([])
  const [hiddenTagsAmount, setHiddenTagsAmount] = useState(0)
  const [isHiddenTagsDropdownOpen, setIsHiddenTagsDropdownOpen] = useState(false)
  const [tagsWidth, setTagsWidth] = useState([])

  const sortedTags = useMemo(() => tags.sort(compareTags), [tags])

  const tagsTooltipValues = useMemo(
    () => ({
      rm: t('pages.patients.selectedPatient.tags.remoteMonitoring'),
      rc: t('pages.patients.selectedPatient.tags.remoteConsultation')
    }),
    [t]
  )

  const isTagElement = useCallback(
    element =>
      element.classList.contains(classes.visibleTag) || element.firstChild.classList.contains(classes.visibleTag),
    [classes]
  )

  const isAssigneeTagElement = useCallback(
    element =>
      element.classList.contains(classes.assigneeTag) || element.firstChild.classList.contains(classes.assigneeTag),
    [classes.assigneeTag]
  )

  const handleDelete = useCallback(
    tagValue => {
      const deletedTagIndex = sortedTags.findIndex(sortedTag => sortedTag.value === tagValue)
      const newTagsWidth = [...tagsWidth]
      newTagsWidth.splice(deletedTagIndex, 1)
      onDeleteTag(tagValue)
      setTagsWidth(newTagsWidth)
    },
    [onDeleteTag, sortedTags, tagsWidth]
  )

  const fitTagsToScreen = useCallback(
    (width, allTagsWidth, assigneeTagWidth) => {
      const fitInTagsCount = countWhile(
        allTagsWidth,
        nextWidth => nextWidth < width - assigneeTagWidth - compressionThreshold
      )

      setHiddenTagsAmount(tags.length - fitInTagsCount)
    },
    [tags.length, compressionThreshold]
  )

  const calculateAssigneTagWidth = useCallback(
    () => Array.from(ref.current.children).find(isAssigneeTagElement)?.offsetWidth || 0,
    [isAssigneeTagElement, ref]
  )

  const calculateAllTagsWidth = useCallback(() => {
    const tagElementWidths =
      !tagsWidth.length &&
      Array.from(ref.current.children)
        .filter(isTagElement)
        .map(element => element.offsetWidth)

    if (!tagsWidth.length && tagElementWidths.length) setTagsWidth(tagElementWidths)

    return tagElementWidths || tagsWidth
  }, [isTagElement, tagsWidth, ref])

  useEffect(() => {
    const updatedDisplayedTags = take(sortedTags, sortedTags.length - hiddenTagsAmount)
    const updatedHiddenTags = takeRight(sortedTags, hiddenTagsAmount)

    setDisplayedTags(updatedDisplayedTags)
    setHiddenTags(updatedHiddenTags)
  }, [sortedTags, hiddenTagsAmount])

  useEffect(() => {
    if (!width) {
      return
    }
    const allTagsWidth = calculateAllTagsWidth()
    const assigneeTagWidth = calculateAssigneTagWidth()

    fitTagsToScreen(width, allTagsWidth, assigneeTagWidth)
  }, [calculateAllTagsWidth, calculateAssigneTagWidth, fitTagsToScreen, width])

  const renderTag = useCallback(
    tag => (
      <GrinTag
        tooltipValue={tagsTooltipValues[tag?.value]}
        key={tag.value}
        tag={tag}
        className={[classes.tag, classes.visibleTag].join(' ')}
        canDeleteSystem={canDeleteSystem}
        canDeleteCustom={canDeleteCustom}
        canDeleteScan={canDeleteScan}
        onDelete={() => handleDelete(tag.value)}
        onClick={tag.onClick}
      />
    ),
    [tagsTooltipValues, canDeleteSystem, canDeleteCustom, canDeleteScan, handleDelete, classes]
  )

  return (
    <div className={[classes.root, className].join(' ')} ref={ref}>
      {displayedTags.map(renderTag)}
      {hiddenTagsAmount > 0 && (
        <>
          <GrinMenu
            transformOrigin={{ vertical: -30, horizontal: 'left' }}
            disableHoverEffects
            triggerComponent={
              <div>
                <GrinChip
                  text={t('pages.patients.selectedPatient.tags.showMore', { amount: hiddenTags.length })}
                  className={classes.showMoreChip}
                />
              </div>
            }
            open={isHiddenTagsDropdownOpen}
            onClose={() => setIsHiddenTagsDropdownOpen(false)}
            onOpen={() => setIsHiddenTagsDropdownOpen(true)}
          >
            {hiddenTags.map(tag => (
              <div key={tag.value}>
                <GrinMenuItem nonActionable>{renderTag(tag)}</GrinMenuItem>
              </div>
            ))}
          </GrinMenu>
        </>
      )}
      <TagsPicker showIcon={showIcon} />
      {assigneeTag && (
        <GrinTag
          key={assigneeTag.value}
          tag={assigneeTag}
          showUnseenIndicator={assigneeTag.showUnseenIndicator}
          className={classes.assigneeTag}
          onDelete={null}
          onClick={assigneeTag.onClick}
        />
      )}
    </div>
  )
}

export default TagsList
