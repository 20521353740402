import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import GrinCheckbox from 'components/common/GrinCheckbox'
import MultipleSiblingLink from 'components/common/icons/MultipleSiblingLink'
import CloseIcon from 'components/common/icons/Close'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { QuestionMarkIcon } from 'components/common/icons'
import Tooltip from 'components/common/Tooltip'
import DazzedHeading12 from 'components/common/text/DazzedHeading12'
import { Trans, useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import SearchSibling from './SearchSibling'
import GrinChip from 'components/common/GrinChip'
import ProfilePicture from 'components/common/ProfilePicture'
import { trackEvent } from 'utils/analyticsUtils'
import { useDispatch } from 'react-redux'
import actions from 'actions'

const useStyles = makeStyles({
  siblingLink: {
    marginLeft: '-10px !important'
  },
  linkToSiblingTooltipText: {
    color: 'var(--text-color-29)'
  },
  input: {
    border: 0,
    flex: 1,
    borderBottom: '1px solid var(--border-color-8)',
    fontSize: '13px',
    fontFamily: 'Dazzed',
    background: 'transparent',
    outline: 'none',
    padding: '8px 0',
    width: 380
  },
  searchItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  menu: {
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    width: '193px'
  },
  chip: {
    backgroundColor: 'rgba(226, 229, 252, 0.35)',
    border: '0.85px solid var(--border-color-23)',
    borderRadius: 4,
    color: 'var(--text-color-31)',
    paddingRight: 12,
    paddingLeft: 12
  },
  siblingLinkDisabled: {
    opacity: 0.3
  },
  errorMessage: {
    position: 'absolute'
  },
  containerOnError: {
    marginBottom: 10
  }
})

const LinkToSiblingRow = ({ values, onDeselectSibling, formErrors }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onSelectSibling = useCallback(
    sibling => {
      trackEvent('Link accounts - choose an account', {
        email: sibling.email,
        name: sibling.name,
        parentEmail: sibling.parentEmail
      })
      values.setSiblingToLink(sibling)
    },
    [values]
  )

  const onCheckboxClicked = useCallback(
    isChecked => {
      trackEvent('Link accounts - mark checkbox', { value: isChecked })
      if (!isChecked) {
        dispatch(actions.searchSiblingPatients(''))
      }
      values.setGuardianEmail(null)
      values.setShouldLinkToSibling(isChecked)
    },
    [values, dispatch]
  )

  return (
    <Grid item xs={12} id="invite-patient-link-account">
      <Grid
        container
        className={formErrors?.sibling && classes.containerOnError}
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item>
          <GrinCheckbox
            disabledWrapperClassName={classes.siblingLinkDisabled}
            disabled={!!values.siblingToLink}
            onChange={onCheckboxClicked}
            errorMessage={formErrors?.sibling}
            checked={values.shouldLinkToSibling}
            wrapperClassName={classes.siblingLink}
            customErrorMessageClassName={classes.errorMessage}
            variant="filled"
            caption={
              <Grid spacing={1} container>
                <Grid item>
                  <MultipleSiblingLink />
                </Grid>
                <Grid item>
                  <DazzedParagraph14>{t('dialogs.invitePatient.linkToSiblingRow.linkToSibling')}</DazzedParagraph14>
                </Grid>
              </Grid>
            }
          />
        </Grid>
        {!values.siblingToLink && values.shouldLinkToSibling && (
          <Grid item>
            <SearchSibling onSelectSibling={onSelectSibling} />
          </Grid>
        )}
        {values.siblingToLink && (
          <Grid item>
            <GrinChip
              size={'medium'}
              className={classes.chip}
              onClick={onDeselectSibling}
              onDelete={onDeselectSibling}
              clickable={false}
              actionIcon={<CloseIcon color={'var(--bg-color-45)'} />}
              icon={
                <ProfilePicture
                  photo={values.siblingToLink?.profilePicture}
                  name={values.siblingToLink?.name}
                  email={values.siblingToLink?.email}
                  size={'icon'}
                />
              }
              text={<DazzedHeading12>{values.siblingToLink?.name}</DazzedHeading12>}
            />
          </Grid>
        )}

        <Grid item>
          <Tooltip
            placement={values.shouldLinkToSibling ? 'bottom' : 'right'}
            value={<Trans>{t('dialogs.invitePatient.linkToSiblingRow.tooltip')}</Trans>}
            variant="dark"
          >
            <Grid alignItems="center" spacing={0} container>
              <Grid item style={{ paddingRight: 5 }}>
                <QuestionMarkIcon color="disabled" fontSize="inherit" />
              </Grid>
              <Grid item>
                <DazzedHeading12 className={classes.linkToSiblingTooltipText}>
                  {t('dialogs.invitePatient.linkToSiblingRow.tooltipCTA')}
                </DazzedHeading12>
              </Grid>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LinkToSiblingRow
