import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import NewMessageInput from './NewMessageInput'
import { getDoctorsMessageDraft } from 'utils/storageUtils'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { isMobile } from 'utils/mobileUtils'
import ChatActions from './ChatActions'
import ViewMode from './ViewMode'
import BroadcastPersonalMessageCheckbox from '../Broadcast/BroadcastPersonalMessageCheckbox'
import ScheduledMessageWarningPopup from './ScheduledMessages/ScheduledMessageWarningPopup'
import { trackEvent } from 'utils/analyticsUtils'
import {
  setDoNotShowScheduledMessageWarningTooltip,
  getDoNotShowScheduledMessageWarningTooltip
} from 'utils/storageUtils'
import useNavigation from 'hooks/useNavigation'
import { useTranslation } from 'react-i18next'

const PRE_SET_TEXT_TYPE_QUERY_PARAM = 'preSetTextType'

const NewMessage = ({
  isActive,
  isAdmin,
  handleMessageSend,
  handleFileSend,
  handlePromotionSend,
  disablePromotion = false,
  setShowDropzone,
  filesToSend,
  setFilesToSend,
  containerClassName = ''
}) => {
  const useStyles = ({ isMobile, isBroadcastMode }) => {
    return makeStyles({
      root: {
        padding: isMobile ? '20px 10px 20px 0' : '20px 10px',
        position: isMobile && 'fixed',
        bottom: isMobile && 0,
        backgroundColor: isMobile && 'white',
        width: isMobile && '100%',
        zIndex: 20,
        borderTop: isBroadcastMode ? '1px solid var(--border-color-7)' : 'nope',
        paddingTop: isBroadcastMode ? '5px' : '20px'
      },

      inputContainer: {
        flex: 1,
        paddingLeft: 5
      },
      buttonsContainer: {
        minHeight: '48px'
      }
    })({ isMobile, isBroadcastMode })
  }

  const { patientId } = useParams()
  const { t } = useTranslation()
  const navigation = useNavigation()

  const inputContainerRef = useRef()
  const [message, setMessage] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [promotion, setPromotion] = useState(null)
  const [inputFocus, setInputFocus] = useState(false)
  const [isScheduledMessageTooltipOpen, setIsScheduledMessageTooltipOpen] = useState(false)

  const isBroadcastMode = useSelector(state => state.chatReducer.broadcast.isModeOn)
  const wasLastSentMessageScheduled = useSelector(
    state => state.chatReducer.scheduledMessages.wasLastSentMessageScheduled
  )
  const patientName = useSelector(state => state.patientsReducer?.patient?.details?.name)

  const classes = useStyles({ isMobile: isMobile(), isBroadcastMode })

  const isMessageEmpty = useMemo(
    () => !promotion && !selectedTemplate && !message,
    [promotion, selectedTemplate, message]
  )

  const handleTemplateSelection = useCallback(template => {
    setSelectedTemplate(template)
    setMessage(message => message.concat(template.text))
    setInputFocus(true)
  }, [])

  const sendMessage = useCallback(
    (content, options) => {
      handleMessageSend({ content, templateId: selectedTemplate?.id }, options)
      setSelectedTemplate(null)
    },
    [handleMessageSend, selectedTemplate]
  )

  const handleCloseScheduledMessagePopup = useCallback(({ reason, dontShowAgain }) => {
    setIsScheduledMessageTooltipOpen(false)
    trackEvent(`Scheduled Message Warning Tooltip Closed`, {
      reason,
      dontShowAgain
    })

    if (dontShowAgain) {
      setDoNotShowScheduledMessageWarningTooltip(true)
    }
  }, [])

  useEffect(() => {
    if (isBroadcastMode) {
      setMessage('')
      setSelectedTemplate(null)
    } else {
      const draftMessage = getDoctorsMessageDraft(patientId)

      if (!draftMessage) {
        setSelectedTemplate(null)
        setMessage('')
      } else {
        setMessage(draftMessage)
      }
    }
  }, [patientId, isBroadcastMode])

  useEffect(() => {
    setShowDropzone(false)
  }, [message]) //eslint-disable-line

  useEffect(() => {
    if (
      wasLastSentMessageScheduled &&
      !getDoNotShowScheduledMessageWarningTooltip() &&
      !isBroadcastMode &&
      !isMessageEmpty &&
      !sessionStorage.getItem('wasScheduledMessageWarningPopupOpen')
    ) {
      setIsScheduledMessageTooltipOpen(true)
      sessionStorage.setItem('wasScheduledMessageWarningPopupOpen', true)
    }
  }, [isBroadcastMode, isMessageEmpty, wasLastSentMessageScheduled])

  useEffect(() => {
    const preSetTextType = navigation.getQueryParam(PRE_SET_TEXT_TYPE_QUERY_PARAM)

    if (preSetTextType) {
      switch (preSetTextType) {
        case 'sendSelfiesReminder':
          setMessage(t('pages.rcDashboard.actions.sendSelfiesReminderChatPreText', { patientName }))
          break
        case 'sendScanReminder':
          setMessage(t('pages.rcDashboard.actions.sendScanReminderChatPreText', { patientName }))
          break

        default:
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isActive && !isAdmin && (
        <Grid container className={`${classes.root} ${containerClassName}`} alignItems="flex-end">
          {isBroadcastMode && (
            <Grid item xs={12}>
              <BroadcastPersonalMessageCheckbox />
            </Grid>
          )}
          <ChatActions
            setShowDropzone={setShowDropzone}
            promotionToSend={promotion}
            setPromotion={setPromotion}
            setInputFocus={setInputFocus}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            handleTemplateSelection={handleTemplateSelection}
            disablePromotion={disablePromotion}
            message={message}
            setFilesToSend={setFilesToSend}
            filesToSend={filesToSend}
          />
          {isScheduledMessageTooltipOpen && (
            <ScheduledMessageWarningPopup
              anchorEl={inputContainerRef?.current}
              open={isScheduledMessageTooltipOpen}
              onClose={handleCloseScheduledMessagePopup}
            />
          )}
          <Grid item className={classes.inputContainer} innerRef={inputContainerRef}>
            <NewMessageInput
              message={message}
              inputFocus={inputFocus}
              setInputFocus={setInputFocus}
              setMessage={setMessage}
              promotionToSend={promotion}
              setPromotion={setPromotion}
              handleMessageSend={sendMessage}
              handleFileSend={handleFileSend}
              handlePromotionSend={handlePromotionSend}
              patientId={patientId}
              setFilesToSend={setFilesToSend}
              filesToSend={filesToSend}
            />
          </Grid>
        </Grid>
      )}
      {isActive === false && <ViewMode />}
    </>
  )
}

export default NewMessage
