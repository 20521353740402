import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'

export default () => {
  const { search } = useLocation()
  const history = useHistory()

  const queryParams = useMemo(() => queryString.parse(search), [search])

  const setQueryParams = useCallback(
    (params, hash = '') => {
      history.push({
        search: queryString.stringify(params),
        hash: hash ? `#${hash}` : ''
      })
    },
    [history]
  )

  const addQueryParam = useCallback(
    param => {
      setQueryParams({
        ...queryParams,
        ...param
      })
    },
    [setQueryParams, queryParams]
  )

  return {
    queryParams,
    setQueryParams,
    addQueryParam
  }
}
