import React, { useRef, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { AttachFile } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'
import fileExtension from 'file-extension'
import { fileTypeToMessageType } from 'utils/fileUtils'
import { DROPZONE_ACCEPTABLE_FILE_TYPES } from 'consts/appConsts'
import useFiles from 'hooks/useFiles'

const useStyles = makeStyles(theme => ({}))

const Attach = React.forwardRef(({ onUploadFiles, multiple }, ref) => {
  const classes = useStyles({
    attach: {
      display: 'flex',
      borderRadius: '20px',
      height: '51px',
      width: '40px',
      right: '70px',
      position: 'relative',
      background: 'transparent',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box'
    }
  })

  const { validateFilesSize } = useFiles({
    additionalMapper: file => {
      const extension = fileExtension(file.name)
      const id = uuidv4()

      const props = {
        messageType: fileTypeToMessageType(file.type),
        extension,
        originalKey: file.name,
        id,
        key: `${id}.${extension}`
      }

      return props
    }
  })

  const inputRef = useRef(null)

  const attachFile = useCallback(message => {
    inputRef.current.click()
  }, [])

  const onInputClick = useCallback(e => {
    e.target.value = ''
  }, [])

  const handleSelectFiles = useCallback(
    async e => {
      const filteredFiles = await validateFilesSize({ files: e.target.files })
      onUploadFiles(filteredFiles)
    },
    [onUploadFiles, validateFilesSize]
  )

  return (
    <div className={classes.attach}>
      <input
        multiple={multiple}
        ref={inputRef}
        id="primary-outline-button"
        type="file"
        accept={DROPZONE_ACCEPTABLE_FILE_TYPES.join(',')}
        onChange={handleSelectFiles}
        onClick={onInputClick}
        style={{ display: 'none' }}
      />
      <IconButton onClick={attachFile} ref={ref}>
        <AttachFile fontSize="small" style={{ color: 'var(--text-color-3)' }} />
      </IconButton>
    </div>
  )
})

export default Attach
