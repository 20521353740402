import * as React from 'react'

const TreatmentPlan = ({ color = '#344563', ...props }) => {
  return (
    <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.25115 8.23786C3.59304 8.23786 4.14557 12.6595 2.2111 12.6595C1.0896 12.6595 0.938666 9.5944 0.859857 6.47968C0.838801 5.6475 0.515895 4.85019 0.241251 4.06435C0.102481 3.66729 0 3.20317 0 2.71082C0 1.28156 1.65811 0.547498 2.76352 0.547498C3.64785 0.547498 4.97465 0.817279 5.5275 0.95217C6.08021 0.801447 7.4067 0.5 8.29102 0.5C9.39643 0.5 10.7782 1.32906 10.7782 2.71082C10.7782 3.33153 10.5458 3.92319 10.2768 4.38795C9.95461 4.94463 9.70416 5.56632 9.72888 6.20905C9.82961 8.82803 9.64923 12.6595 8.29102 12.6595C6.35656 12.6595 6.90926 8.23786 5.25115 8.23786Z"
        fill={color}
      />
    </svg>
  )
}

export default TreatmentPlan
