import { makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { createNumberRanges } from 'utils/arrayUtils'

const useStyles = makeStyles({
  uploadedSTLs: {
    background: '#F2F3F5',
    height: '100%',
    marginTop: 10,
    borderRadius: 10,
    padding: 20
  },
  stlsRange: {
    lineHeight: '30px'
  }
})

const PreviouslyUploadedStls = ({ stls }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const availableUpperSTLs = useMemo(() => createNumberRanges(Object.keys(stls?.upper || {})), [stls])
  const availableLowerSTLs = useMemo(() => createNumberRanges(Object.keys(stls?.lower || {})), [stls])
  return (
    <div className={classes.uploadedSTLs}>
      <div className={classes.stlsRange}>
        {t('dialogs.patientInfo.txTrackerSetup.upperLabel')}:{' '}
        {availableUpperSTLs.map(([rangeStart, rangeEnd]) => (rangeEnd ? `${rangeStart}-${rangeEnd}` : rangeStart))}
      </div>
      <div className={classes.stlsRange}>
        {t('dialogs.patientInfo.txTrackerSetup.lowerLabel')}:{' '}
        {availableLowerSTLs.map(([rangeStart, rangeEnd]) => (rangeEnd ? `${rangeStart}-${rangeEnd}` : rangeStart))}
      </div>
    </div>
  )
}
export default PreviouslyUploadedStls
