import Actions from 'actions'
import useRolePermissions from 'hooks/useRolePermissions'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getEnvironment } from 'utils/awsUtils'

const PLACEHOLDER = '-'

export default ({ lead } = {}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()

  const shippingStatusOptions = useMemo(
    () => [
      {
        label: PLACEHOLDER,
        value: PLACEHOLDER
      },
      {
        label: t('pages.rcDashboard.dialogs.shippingDetails.shippingStatuses.shipmentRequired'),
        value: 'shipmentRequired'
      },
      {
        label: t('pages.rcDashboard.dialogs.shippingDetails.shippingStatuses.processing'),
        value: 'processing'
      },
      {
        label: t('pages.rcDashboard.dialogs.shippingDetails.shippingStatuses.shipped'),
        value: 'shipped'
      },
      {
        label: t('pages.rcDashboard.dialogs.shippingDetails.shippingStatuses.delivered'),
        value: 'delivered'
      },
      {
        label: t('pages.rcDashboard.dialogs.shippingDetails.shippingStatuses.notRequired'),
        value: 'notRequired'
      },
      {
        label: t('pages.rcDashboard.dialogs.shippingDetails.shippingStatuses.onHold'),
        value: 'onHold'
      }
    ],
    [t]
  )

  const { inventoryItems, shippingMethods } = useSelector(state => state.rcDashboardReducer.shippingDetailsDialog)

  const shippingMethodOptions = useMemo(
    () => shippingMethods?.map(shippingMethod => shippingMethod.value),
    [shippingMethods]
  )

  const rcData = useMemo(() => JSON.parse(lead?.rcData || '{}'), [lead])
  const shippingDetails = useMemo(() => rcData.shippingDetails, [rcData])
  const shipbobIntegrationActive = useMemo(() => !!shippingDetails?.shipbobReferenceId, [shippingDetails])
  const shipbobWebUrl = useMemo(
    () =>
      getEnvironment() === 'production'
        ? 'https://web.shipbob.com/app/merchant/#'
        : 'https://webstage.shipbob.com/app/merchant/#',
    []
  )

  const [shippingMethod, setShippingMethod] = useState(null)
  const [shippingStatus, setShippingStatus] = useState(shippingStatusOptions[0])
  const [inventoryItemId, setInventoryItemId] = useState(null)
  const [trackingNumber, setTrackingNumber] = useState()

  useEffect(() => {
    setShippingStatus(
      shippingStatusOptions.find(option => option?.value === rcData?.shippingDetails?.status) || PLACEHOLDER
    )
    setShippingMethod(rcData?.shippingDetails?.shippingMethod)
    setInventoryItemId(inventoryItems?.find(item => item.value === rcData?.shippingDetails?.inventoryItemId))
    setTrackingNumber(rcData?.shippingDetails?.trackingNumber)
  }, [rcData, shippingStatusOptions, inventoryItems])

  useEffect(() => {
    if (!inventoryItems && permissions.rcDashboardAdminActions) {
      dispatch(Actions.fetchRcShippingConfiguration())
    }
  }, [inventoryItems, dispatch, permissions])

  useEffect(() => {
    const selectedInventoryItem = rcData?.shippingDetails?.inventoryItemId
    if (inventoryItems && selectedInventoryItem) {
      setInventoryItemId(inventoryItems.find(item => item.value === selectedInventoryItem))
    }
  }, [inventoryItems, rcData?.shippingDetails?.inventoryItemId])

  useEffect(() => {
    setShippingMethod(shippingMethodOptions?.[0])
  }, [shippingMethodOptions])

  return {
    shippingStatusOptions,
    shippingMethodOptions,
    shipbobIntegrationActive,
    shippingDetails,
    shipbobWebUrl,
    inventoryItems,
    values: {
      shippingMethod,
      setShippingMethod,
      shippingStatus,
      setShippingStatus,
      inventoryItemId,
      setInventoryItemId,
      trackingNumber,
      setTrackingNumber,
      trackingUrl: shippingDetails?.trackingUrl
    }
  }
}
