import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Trans, useTranslation } from 'react-i18next'
import { ButtonBase, CircularProgress, Collapse, Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import PreventiveFeedbackParameters from './PreventiveFeedbackParameters'
import { trackEvent } from 'utils/analyticsUtils'
import { ThumbsUp, FeedbackMissingDiagnostics, FeedbackOverDiagnostics } from 'components/common/icons'
import PreventiveFeedbackOption from './PreventiveFeedbackOption'

const useStyles = makeStyles(theme => ({
  root: {
    userSelect: 'none',
    borderRadius: '10px',
    background: 'var(--bg-color-48)',
    width: '100%',
    padding: '16px 10px',
    marginRight: 24
  },
  text: {
    color: 'var(--text-color-27)'
  },
  titleContainer: {
    padding: '0 8px 8px 12px'
  },
  submitContainer: {
    padding: '8px 12px 0 12px'
  },
  submit: {
    color: 'var(--text-color-25)',
    textTransform: 'capitalize'
  },
  parametersSelectionLabel: {
    padding: '2px 10px'
  }
}))

const PreventiveFeedback = ({ show = false, feedbackData = {}, onSubmit, isLoading }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [preventiveFeedback, setPreventiveFeedback] = useState()
  const [preventiveFeedbackParameters, setPreventiveFeedbackParameters] = useState([])

  const feedbackOptions = useMemo(
    () => [
      {
        value: 'good',
        icon: <ThumbsUp color="var(--bg-color-43)" />,
        label: t('dialogs.scanSummary.preventive.feedback.options.good')
      },
      {
        value: 'missingDiagnostics',
        icon: <FeedbackMissingDiagnostics />,
        label: t('dialogs.scanSummary.preventive.feedback.options.missingDiagnostics')
      },
      {
        value: 'overDiagnostics',
        icon: <FeedbackOverDiagnostics />,
        label: t('dialogs.scanSummary.preventive.feedback.options.overDiagnostics')
      }
    ],
    [t]
  )

  const selectedFeedbackOption = useMemo(
    () => feedbackOptions.find(option => option.value === preventiveFeedback),
    [preventiveFeedback, feedbackOptions]
  )

  const hasChanges = useMemo(
    () =>
      feedbackData?.preventiveFeedback !== preventiveFeedback ||
      JSON.stringify(feedbackData?.preventiveFeedbackParameters) !== JSON.stringify(preventiveFeedbackParameters),
    [feedbackData, preventiveFeedback, preventiveFeedbackParameters]
  )

  const handleFeedbackSelection = useCallback(option => {
    trackEvent('Scan Summary - preventive feedback selected', { feedback: option.value })
    setPreventiveFeedback(option.value)
    setPreventiveFeedbackParameters([])
  }, [])

  const handleClearFeedback = useCallback(() => {
    trackEvent('Scan Summary - preventive feedback cleared')
    setPreventiveFeedback(null)
  }, [])

  const handleSubmit = useCallback(() => {
    onSubmit({
      preventiveFeedback,
      preventiveFeedbackParameters
    })
  }, [onSubmit, preventiveFeedback, preventiveFeedbackParameters])

  useEffect(() => {
    setPreventiveFeedback(feedbackData?.preventiveFeedback)
    setPreventiveFeedbackParameters(feedbackData?.preventiveFeedbackParameters || [])
  }, [feedbackData?.preventiveFeedback, feedbackData?.preventiveFeedbackParameters])

  return (
    <Collapse in={show}>
      <Grid container justifyContent="space-between" direction="row" className={classes.root}>
        <Grid item>
          <Grid container>
            <Grid item xs={12} className={classes.titleContainer}>
              <DazzedParagraph16 strong className={classes.text}>
                {t('dialogs.scanSummary.preventive.feedback.title')}
              </DazzedParagraph16>
            </Grid>
            {!selectedFeedbackOption ? (
              <>
                {feedbackOptions.map(option => (
                  <Grid item xs={12} key={`scan-summary-preventive-feedback-option-${option.value}`}>
                    <PreventiveFeedbackOption
                      icon={option.icon}
                      label={option.label}
                      value={option.value}
                      onClick={() => handleFeedbackSelection(option)}
                    />
                  </Grid>
                ))}
              </>
            ) : (
              <>
                <PreventiveFeedbackOption
                  icon={selectedFeedbackOption.icon}
                  value={selectedFeedbackOption.value}
                  label={selectedFeedbackOption.label}
                  selected
                  onDelete={handleClearFeedback}
                />
                {preventiveFeedback !== feedbackOptions[0].value && (
                  <>
                    <DazzedParagraph16 className={[classes.text, classes.parametersSelectionLabel].join(' ')}>
                      <Trans
                        i18nKey={`dialogs.scanSummary.preventive.feedback.parametersSelectionLabel.${preventiveFeedback}`}
                      ></Trans>
                    </DazzedParagraph16>
                    <PreventiveFeedbackParameters
                      selectedParameters={preventiveFeedbackParameters}
                      onChange={setPreventiveFeedbackParameters}
                    />
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.submitContainer}>
          {isLoading ? (
            <CircularProgress size={18} />
          ) : (
            <ButtonBase disabled={!hasChanges || isLoading || !preventiveFeedback} onClick={handleSubmit}>
              <DazzedParagraph16 bold className={classes.submit}>
                {hasChanges ? t('general.submit') : t('dialogs.scanSummary.preventive.feedback.feedbackSubmitted')}
              </DazzedParagraph16>
            </ButtonBase>
          )}
        </Grid>
      </Grid>
    </Collapse>
  )
}

export default PreventiveFeedback
