import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Actions from 'actions'
import { AsyncStatus, ROUTES } from 'consts'
import useQueryParams from 'hooks/useQueryParams'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ToothLoader from 'resources/webapp-overlay-loader.gif'
import useReferralLogin from '../useReferralLogin'
import DazzedHeading36 from 'components/common/text/DazzedHeading36'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import ScanSummaryWithVideo from '../ScanSummaryWithVideo'
import { trackEvent } from 'utils/analyticsUtils'
import ReferralsFooter from '../ReferralsFooter/ReferralsFooter'
import { isMobile } from 'utils/mobileUtils'
import ReferralViewScanAttachments from './ReferralViewScanAttachments'
import Tooltip from 'components/common/Tooltip'
import useHideHelpWidget from 'hooks/useHideHelpWidget'

const useStyles = makeStyles(theme => ({
  root: {
    padding: isMobile() ? 0 : '30px 0px 0px 0px'
  },
  title: {
    fontSize: isMobile() ? 20 : 32,
    lineHeight: isMobile() ? '30px' : '64px',
    whiteSpace: 'pre',
    textAlign: 'center',
    fontWeight: 500
  },
  patientName: {
    fontSize: isMobile() ? 12 : 16,
    marginBottom: isMobile() ? 0 : 30
  },
  reviewSubmittedText: {
    marginTop: isMobile() ? 5 : 20,
    color: 'var(--text-color-59)'
  },
  content: {
    padding: isMobile() ? 10 : 0,
    backgroundColor: 'white',
    borderBottomRightRadius: isMobile() ? 50 : 75,
    paddingBottom: isMobile() ? 50 : 70
  },
  contentBackground: {
    width: '100%',
    backgroundColor: 'var(--text-color-54)'
  },
  loading: {
    paddingTop: isMobile() ? 104 : 0,
    height: isMobile() ? '100vh' : 740
  },
  reviewContainer: {
    paddingLeft: isMobile() ? 0 : 20
  },
  scanView: {
    padding: isMobile() ? 10 : 0,
    width: isMobile() ? 'auto' : 832,
    height: isMobile() ? 'auto' : 560
  },
  attachmentsContainer: {
    marginTop: 20
  },
  tooltipContainer: {
    position: 'fixed',
    bottom: 20,
    left: '50%'
  },
  tooltipContent: {
    backgroundColor: 'var(--bg-color-16) !important',
    border: '1px solid var(--border-color-34) !important',
    borderRadius: 10,
    padding: 15,
    maxWidth: 'fit-content'
  },
  tooltipArrow: {
    color: 'var(--bg-color-16) !important',
    '&::before': {
      border: '1px solid var(--border-color-34) !important'
    }
  }
}))

const ReferralViewScan = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { queryParams } = useQueryParams()
  const { getToken } = useReferralLogin()
  useHideHelpWidget()

  const { globalLoadingState, referral, viewScan } = useSelector(state => state.referralsReducer)
  const parsedReferralData = useMemo(() => JSON.parse(referral?.referralData || '{}'), [referral?.referralData])

  const [referralReview, setReferralReview] = useState('')
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const isSubmitting = useMemo(() => viewScan.isSubmitting, [viewScan.isSubmitting])
  const referralId = useMemo(() => queryParams.referralId, [queryParams])
  const attachments = useMemo(() => JSON.parse(referral?.referralData || '{}')?.files, [referral?.referralData])

  const fetchReferralData = useCallback(() => {
    const token = getToken()
    if (token) {
      if (token.isExpired) {
        dispatch(Actions.referralsToggleSessionExpiredModal(true))
      } else {
        dispatch(
          Actions.referralsFetchReferralData({
            referralId,
            token: token.raw
          })
        )
      }
    } else {
      history.push(`${ROUTES.REFERRAL}${window.location.search}`)
    }
  }, [dispatch, getToken, referralId, history])

  const handleSubmitReview = useCallback(() => {
    const token = getToken()
    if (!token?.isExpired) {
      trackEvent('Referrals - Review scan - review submitted', {
        referralReview,
        requesterDoctorName: referral?.doctor?.name,
        referralEmail: referral?.notificationDestination
      })
      dispatch(
        Actions.referralsSubmitScanReview({
          token: token.raw,
          referralId,
          referralReview
        })
      )
    } else {
      sessionStorage.setItem('referralReview', referralReview)
      dispatch(Actions.referralsToggleSessionExpiredModal(true))
    }
  }, [dispatch, getToken, referral, referralId, referralReview])

  useEffect(() => {
    fetchReferralData()
  }, [fetchReferralData])

  useEffect(() => {
    const savedReferralReview = sessionStorage.getItem('referralReview')
    if (savedReferralReview) {
      setReferralReview(savedReferralReview)
      sessionStorage.removeItem('referralReview')
    }
  }, [referralReview])

  useEffect(() => {
    if (referral?.doctor?.name && referral?.notificationDestination) {
      trackEvent('Referrals - Review scan - page visited', {
        requesterDoctorName: referral?.doctor?.name,
        referralEmail: referral?.notificationDestination
      })
    }
  }, [referral])

  useEffect(() => {
    const showTooltipTimeout = setTimeout(() => setIsTooltipOpen(true), 20000)

    const onPageScrolled = () => {
      trackEvent('Referral - Review Scan - Page scrolled', {
        YOffset: window.pageYOffset
      })
      setIsTooltipOpen(false)
      clearTimeout(showTooltipTimeout)
    }

    if (isMobile()) {
      window.addEventListener('touchend', () => {
        if (window.scrollY > 0) {
          onPageScrolled()
        }
      })
    } else {
      window.addEventListener('scrollend', onPageScrolled)
    }

    return () => {
      window.removeEventListener(isMobile() ? 'touchend' : 'scrollend', onPageScrolled)
    }
  }, [])

  return (
    <>
      <Grid container direction="column" alignItems="center" className={classes.root}>
        <div className={classes.contentBackground}>
          {globalLoadingState === AsyncStatus.Loading ? (
            <Grid item className={`${classes.loading} ${classes.content}`}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <img src={ToothLoader} alt="Loading" />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item className={classes.content}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <DazzedHeading36 className={classes.title}>
                    {t('pages.referrals.review.title', {
                      requesterDoctorName: `${referral?.doctor?.name}${isMobile() ? '\n' : ''}`
                    })}
                  </DazzedHeading36>
                </Grid>
                <Grid item>
                  <DazzedParagraph16 className={classes.patientName}>
                    <Trans
                      i18nKey={'pages.referrals.review.patientName'}
                      components={{ bold: <b>{''}</b> }}
                      values={{ patientName: parsedReferralData?.patientName }}
                    />
                  </DazzedParagraph16>
                </Grid>
                <Grid item xs={10}>
                  <Grid container direction={isMobile() ? 'column' : 'row'}>
                    <Grid item xs={isMobile() ? 12 : 6}>
                      <Grid container justifyContent={isMobile() ? 'flex-start' : 'flex-end'}>
                        <Grid item className={classes.scanView}>
                          {referral?.grinScan?.video && (
                            <ScanSummaryWithVideo
                              scanVideo={referral.grinScan.video}
                              scanSummaryData={referral?.grinScan?.scanSummaryData}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={isMobile() ? 12 : 6} className={classes.reviewContainer}>
                      <Grid container direction="column">
                        <Grid item>
                          <DazzedParagraph16 bold>
                            {t('pages.referrals.review.noteLabel', { requesterDoctorName: referral?.doctor?.name })}
                          </DazzedParagraph16>
                        </Grid>
                        <Grid item xs={12}>
                          <DazzedParagraph16>{`"${referral?.grinDoctorNote}"`}</DazzedParagraph16>
                        </Grid>
                        {!!attachments?.length && (
                          <Grid item xs={12} className={classes.attachmentsContainer}>
                            <ReferralViewScanAttachments attachments={attachments} />
                          </Grid>
                        )}
                        {referral.reviewStatus === 'completed' ? (
                          <DazzedParagraph16 bold className={classes.reviewSubmittedText}>
                            {t('pages.referrals.review.reviewSubmittedMessage')}
                          </DazzedParagraph16>
                        ) : (
                          <>
                            <Grid item style={{ marginTop: isMobile() ? 15 : 30 }}>
                              <DazzedParagraph16 bold>{t('pages.referrals.review.reviewInputLabel')}</DazzedParagraph16>
                            </Grid>
                            <Grid item>
                              <TextAreaAutosize
                                inputStyle={{
                                  fontSize: 14,
                                  fontWeight: 500,
                                  height: isMobile() ? 136 : 160,
                                  width: isMobile() ? '100%' : 550,
                                  overflow: 'auto',
                                  borderRadius: 8
                                }}
                                rowsMax={4}
                                style={{ bright: true }}
                                className={classes.messageInput}
                                keepErrorContainerWhenInactive={false}
                                value={referralReview}
                                setValue={setReferralReview}
                              />
                            </Grid>
                            <Grid item style={{ marginTop: 10 }}>
                              <PrimaryButton
                                label={t('general.reply')}
                                fontColor="var(--text-color-58)"
                                color="var(--bg-color-71)"
                                onClick={handleSubmitReview}
                                isLoading={isSubmitting}
                                disabled={!referralReview}
                                width={isMobile() ? '100%' : undefined}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <ReferralsFooter />
          </Grid>
        </div>
      </Grid>
      <Tooltip
        open={isTooltipOpen}
        arrow
        placement="top"
        value={
          <DazzedParagraph16 strong textAlign="center">
            <Trans i18nKey={'pages.referrals.review.tooltip'} components={{ bold: <b>{''}</b> }} />
          </DazzedParagraph16>
        }
        containerClassName={classes.tooltipContainer}
        tooltipContentClassName={classes.tooltipContent}
        arrowClassName={classes.tooltipArrow}
      />
    </>
  )
}

export default ReferralViewScan
