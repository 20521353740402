import * as React from 'react'

const Link = props => (
  <svg width="38" height="28" viewBox="0 0 38 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 18L27 18C29.2091 18 31 16.2091 31 14V14C31 11.7909 29.2091 10 27 10L20 10"
      stroke="#4151E6"
      strokeWidth="1.5"
    />
    <path
      d="M17 10L10 10C7.79086 10 6 11.7909 6 14V14C6 16.2091 7.79086 18 10 18H17"
      stroke="#4151E6"
      strokeWidth="1.5"
    />
    <path d="M12 14H25" stroke="#4151E6" strokeWidth="1.5" />
  </svg>
)

export default Link
