import React, { useCallback } from 'react'
import SwooshLargeIcon from '../common/icons/SwooshLarge1'
import SwooshSmallIcon from '../common/icons/Swoosh'
import FormInput from '../common/FormInput'
import Actions from '../../actions'
import ROUTES from '../../consts/routesConsts'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from 'react-router-dom'
import { Grid, Button, Link } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import WithLoader from '../common/WithLoader'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import OggHeading40 from 'components/common/text/OggHeading40'
import useCssClasses from 'hooks/useCssClasses'
import { useTranslation } from 'react-i18next'
import { getEnvironment } from 'utils/awsUtils'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    color: 'var(--text-color-1)',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  signInSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  title: {
    fontSize: isMobile ? 32 : 40
  },
  form: {
    maxWidth: '512px',
    minWidth: isMobile ? 'auto' : 512,
    padding: 18
  },
  nextButton: {
    background: 'var(--border-color-1)',
    color: 'var(--text-color-1)',
    width: '100%',
    borderRadius: '34px',
    '&:hover': {
      background: 'var(--border-color-1)'
    }
  },
  noFill: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      transition: 'color 0.2s ease',
      color: 'gray',
      boxShadow: 'none'
    }
  },
  inputContainer: {
    width: '100%'
  }
}))

const SignIn = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isLoading = useSelector(state => state.authReducer.isLoading)
  const { emailAddress, password, errors } = useSelector(state => state.authReducer)
  useCssClasses(true)

  const handleFormSubmit = useCallback(
    e => {
      e.preventDefault()
      dispatch(Actions.requestSignIn())
    },
    [dispatch]
  )

  return (
    <Grid className={classes.root} container alignItems="center" justifyContent="center">
      {!isMobile ? (
        <SwooshLargeIcon className={classes.signInSwoosh} />
      ) : (
        <SwooshSmallIcon className={classes.signInSwoosh} />
      )}

      <form onSubmit={handleFormSubmit}>
        <Grid container direction="column" justifyContent="center" spacing={isMobile ? 5 : 3} className={classes.form}>
          <Grid item></Grid>
          <Grid item>
            <OggHeading40 className={classes.title} dark textAlign={isMobile ? 'center' : 'start'}>
              Sign in to your account
            </OggHeading40>
          </Grid>
          <Grid item />
          <Grid item>
            <FormInput
              containerClassName={classes.inputContainer}
              type="email"
              allowAutoComplete={true}
              errorMessage={t(errors.signIn)}
              title="Email Address"
              value={emailAddress}
              validationRules={{ required: true }}
              setValue={emailAddress =>
                dispatch(
                  Actions.changeAuthForm({
                    key: 'emailAddress',
                    value: emailAddress.toLowerCase()
                  })
                )
              }
            />
          </Grid>
          <Grid item>
            <FormInput
              containerClassName={classes.inputContainer}
              title="Password"
              allowAutoComplete={true}
              value={password}
              type="password"
              validationRules={{ required: true }}
              setValue={password => dispatch(Actions.changeAuthForm({ key: 'password', value: password }))}
            />
            <Link
              component={RouterLink}
              to={ROUTES.RESET_PASSWORD}
              className={classNames(classes.nextButton, classes.noFill)}
            >
              Forgot Password
            </Link>
          </Grid>
          <Grid item>
            <WithLoader isLoading={isLoading}>
              <Button variant="contained" className={classes.nextButton} type="submit">
                Sign In
              </Button>
            </WithLoader>
          </Grid>
          <Grid item>
            {!isMobile && (
              <Grid container justifyContent="center">
                <Link component={RouterLink} to={ROUTES.SIGN_UP}>
                  <Button variant="contained" className={classNames(classes.nextButton, classes.noFill)}>
                    Create Account
                  </Button>
                </Link>
              </Grid>
            )}
            {getEnvironment() !== 'production' && (
              <DazzedParagraph14 textAlign="center" color={'#fff'}>
                {getEnvironment()}
              </DazzedParagraph14>
            )}
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}

export default SignIn
