import * as React from 'react'

const InvitePatient = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 13.3184C6 12.5186 6.47585 11.7939 7.23259 11.5348C8.27078 11.1794 9.7778 10.75 11 10.75C12.2222 10.75 13.7292 11.1794 14.7674 11.5348C15.5242 11.7939 16 12.5186 16 13.3184V16C16 16.5523 15.5523 17 15 17H7C6.44772 17 6 16.5523 6 16V13.3184Z"
      fill="white"
    />
    <path
      d="M14 7C14 8.65685 12.6569 10 11 10C9.34315 10 8 8.65685 8 7C8 5.34315 9.34315 4 11 4C12.6569 4 14 5.34315 14 7Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5833 14H16.4167V16.9167H13.5V18.0833H16.4167V21H17.5833V18.0833H20.5V16.9167H17.5833V14Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4167 13H18.5833V15.9167H21.5V19.0833H18.5833V22H15.4167V19.0833H12.5V15.9167H15.4167V13ZM16.4167 16.9167H13.5V18.0833H16.4167V21H17.5833V18.0833H20.5V16.9167H17.5833V14H16.4167V16.9167Z"
      fill="#4151E6"
    />
  </svg>
)

export default InvitePatient
