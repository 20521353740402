import * as React from 'react'

const PartiallyTrackingLarge = () => (
  <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: 4 }}>
    <rect x="0.5" width="3" height="12" fill="#CF8922" />
    <rect x="0.5" y="15" width="3" height="3" fill="#CF8922" />
  </svg>
)

export default PartiallyTrackingLarge
