import * as React from 'react'

function SvgRight(props) {
  return (
    <svg width={16} height={16} fill="none" {...props}>
      <path d="M7 12l4-4-4-4" stroke="#fff" strokeLinecap="round" />
    </svg>
  )
}

export default SvgRight
