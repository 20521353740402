import * as React from 'react'

const Eye = ({ className, ...props }) => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M6.00013 3.36353C5.0974 3.36353 4.36377 4.09715 4.36377 4.99989C4.36377 5.90262 5.0974 6.63625 6.00013 6.63625C6.90287 6.63625 7.63649 5.90262 7.63649 4.99989C7.63649 4.09715 6.90284 3.36353 6.00013 3.36353Z"
        fill="#3C52EF"
      />
      <path
        d="M6.00001 0.909058C3.27273 0.909058 0.943646 2.60541 0 4.99997C0.943646 7.39451 3.27273 9.09088 6.00001 9.09088C8.73001 9.09088 11.0564 7.39451 12 4.99997C11.0564 2.60541 8.73001 0.909058 6.00001 0.909058ZM6.00001 7.72723C4.49456 7.72723 3.27273 6.5054 3.27273 4.99994C3.27273 3.49449 4.49456 2.27269 6.00001 2.27269C7.50547 2.27269 8.7273 3.49451 8.7273 4.99997C8.7273 6.50543 7.50547 7.72723 6.00001 7.72723Z"
        fill="#3C52EF"
      />
    </svg>
  )
}

export default Eye
