import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid } from '@material-ui/core'
import FormInput from './common/FormInput'
import BaseModal from './common/modals/BaseModal'
import SelectInput from './common/SelectInput'
import DazzedHeading16 from './common/text/DazzedHeading16'
import Actions from '../actions'
import _, { isEmpty } from 'lodash'
import { isValidZipcode } from '../utils/generalUtils'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCountry } from '../hooks/useCountry'
import { COUNTRY_CA, COUNTRY_US, COUNTRY_IN } from '../consts/countryConsts'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles({
  body: {
    minWidth: isMobile() ? 0 : 500
  },
  validationError: {
    color: 'red',
    textAlign: 'center'
  }
})

const ChangeAddressModal = ({ isOpen, address, onChangeRequested, onCloseRequested }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { isLoading, validationResult, dirty } = useSelector(state => state.billingReducer.addressValidation)

  const [address1, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState(COUNTRY_US)
  const [state, setState] = useState('')
  const [stateKey, setStateKey] = useState('')
  const [zip, setZip] = useState('')

  const [formErrors, setFormErrors] = useState()

  const { countriesOptions, states, getDefaultState, getStateKey, getZipCodeProperties, hasZipcode } =
    useCountry(country)

  const countryDefaultState = useMemo(() => getDefaultState(country), [country, getDefaultState])
  const { placeholder } = useMemo(() => getZipCodeProperties(country), [country, getZipCodeProperties])

  const validateInputs = useCallback(() => {
    const errors = {}

    if (!address1?.trim()) {
      errors.address = t('dialogs.changeAddress.addressError')
    }

    if (!city?.trim()) {
      errors.city = t('dialogs.changeAddress.cityError')
    }

    if (!country?.trim()) {
      errors.country = t('dialogs.changeAddress.countryError')
    }

    if (states.length && !stateKey?.trim()) {
      errors.stateKey = t('dialogs.changeAddress.stateError')
    }

    if (hasZipcode) {
      if (!zip) {
        errors.zipCode = t('dialogs.changeAddress.emptyZipError')
      } else if (!isValidZipcode(zip, country)) {
        errors.zipCode = t('dialogs.changeAddress.invalidZipError')
      }
    }

    setFormErrors(errors)
    return isEmpty(errors)
  }, [address1, city, country, states.length, stateKey, hasZipcode, t, zip])

  const setFields = useCallback(() => {
    setAddress(address?.address1)
    setAddress2(address?.address2)
    setCity(address?.city)
    setCountry(address?.country || COUNTRY_US)
    setState(address?.state)
    setZip(address?.zip)
    setFormErrors()
  }, [address])

  const handleCancel = useCallback(() => {
    setFields()
    onCloseRequested()
  }, [setFields, onCloseRequested])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!validateInputs()) {
        return
      }

      dispatch(
        Actions.validateAddress({
          state: stateKey,
          street: address1,
          zip,
          country,
          city
        })
      )
    },
    [validateInputs, dispatch, stateKey, address1, zip, country, city]
  )

  useEffect(() => {
    setFields()
  }, [address, setFields])

  useEffect(() => {
    if (state) {
      setStateKey(getStateKey(state, country))
    }
  }, [state, countryDefaultState.key, states, getStateKey, country])

  useEffect(() => {
    dispatch(Actions.resetAddressValidationResult())
  }, [dispatch, isOpen])

  useEffect(() => {
    if (dirty && validationResult) {
      onChangeRequested({ address1, address2, city, country, state, zip })
      onCloseRequested()
    }
  }, [dirty, validationResult, onChangeRequested, onCloseRequested, address1, address2, city, country, state, zip])

  useEffect(() => {
    if (!hasZipcode) {
      setZip('')
    }
  }, [hasZipcode])

  return (
    <BaseModal
      open={isOpen}
      title={t('dialogs.changeAddress.changeAddressTitle')}
      secondaryLabel={t('general.cancel')}
      primaryLabel={t('general.save')}
      className={classes.body}
      variant="alert"
      handleClose={handleCancel}
      onSecondaryBtnClick={handleCancel}
      onPrimaryBtnClick={handleSubmit}
      isLoading={isLoading}
    >
      <form onSubmit={handleSubmit}>
        <FormInput
          title={t('dialogs.changeAddress.addressLabel')}
          value={address1}
          setValue={setAddress}
          style={{ bright: true, thick: true }}
          validationRules={{ required: true }}
          errorMessage={formErrors?.address}
          allowAutoComplete={false}
        />
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <FormInput
              title={t('dialogs.changeAddress.cityLabel')}
              value={city}
              setValue={setCity}
              style={{ bright: true, thick: true }}
              validationRules={{ required: true }}
              errorMessage={formErrors?.city}
              allowAutoComplete={false}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            {country === COUNTRY_IN ? (
              <FormInput
                title={t('dialogs.changeAddress.stateLabel')}
                value={state}
                setValue={setState}
                style={{ bright: true, thick: true }}
                allowAutoComplete={false}
              />
            ) : (
              <SelectInput
                label={
                  country === COUNTRY_CA
                    ? t('dialogs.changeAddress.provinceLabel')
                    : t('dialogs.changeAddress.stateLabel')
                }
                style={{ bright: true, thick: true }}
                value={stateKey}
                values={states.map(state => state.key)}
                required={!!states.length}
                disabled={!states.length}
                errorMessage={formErrors?.stateKey}
                keepErrorContainerWhenInactive={false}
                onChange={({ value: key }) =>
                  setState(
                    _.get(
                      states.find(s => s.key === key),
                      'name'
                    )
                  )
                }
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <SelectInput
              label={t('dialogs.changeAddress.countryLabel')}
              style={{ bright: true, thick: true }}
              value={country}
              options={countriesOptions}
              required
              keepErrorContainerWhenInactive={false}
              onChange={({ value: key }) => {
                setCountry(key)
                setState(getDefaultState(key).name)
                setStateKey(getDefaultState(key).key)
              }}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <FormInput
              title={t('dialogs.changeAddress.zipLabel')}
              style={{ bright: true, thick: true }}
              placeholder={placeholder}
              value={zip}
              errorMessage={formErrors?.zipCode}
              setValue={setZip}
              keepErrorContainerWhenInactive={false}
              validationRules={{
                required: hasZipcode
              }}
              isDisabled={!hasZipcode}
            />
          </Grid>
        </Grid>
        {country === COUNTRY_IN && (
          <FormInput
            title={t('dialogs.changeAddress.detailedAddressLabel')}
            value={address2}
            setValue={setAddress2}
            style={{ bright: true, thick: true }}
            validationRules={{ required: true }}
          />
        )}
      </form>
      {dirty && !isLoading && !validationResult && (
        <DazzedHeading16 className={classes.validationError}>
          {t('dialogs.changeAddress.incorrectAddressError')}
        </DazzedHeading16>
      )}
    </BaseModal>
  )
}

export default ChangeAddressModal
