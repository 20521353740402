import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { TEMPLATES_TYPES } from 'consts/templatesConsts'
import { selectCustomTemplates, selectSystemTemplates } from 'selectors/profileSelector'
import SavedRepliesTypeList from './SavedRepliesTypeList'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles(() => ({
  emptyState: {
    color: 'var(--text-color-11)',
    margin: 15,
    maxWidth: 150
  },
  mobileEmptyState: {
    color: 'var(--text-color-11)',
    textAlign: 'center',
    margin: '30px 50px'
  }
}))

const SavedRepliesList = ({ templates, selected, setSelected, onDeleteTemplate, filterTemplates }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const customTemplates = useSelector(selectCustomTemplates)
  const systemTemplates = useSelector(selectSystemTemplates)

  const { savedReplies: hiContextTemplates, practiceName: hiContextPracticeName } = useSelector(
    state => state.multiPracticeReducer.context
  )

  const filteredCustomTemplates = filterTemplates(TEMPLATES_TYPES.CUSTOM, customTemplates)
  const filteredSystemTemplates = filterTemplates(TEMPLATES_TYPES.SYSTEM, systemTemplates)
  const filteredHiTemplates = filterTemplates(TEMPLATES_TYPES.CUSTOM, hiContextTemplates)

  const templatesList = useMemo(
    () => [
      {
        type: TEMPLATES_TYPES.CUSTOM,
        label: t('pages.patients.selectedPatient.chat.templates.customTypeHeader'),
        templates: filterTemplates(TEMPLATES_TYPES.CUSTOM, customTemplates)
      },
      {
        label: hiContextPracticeName,
        type: TEMPLATES_TYPES.CUSTOM,
        templates: filterTemplates(TEMPLATES_TYPES.CUSTOM, hiContextTemplates)
      },
      {
        type: TEMPLATES_TYPES.SYSTEM,
        label: t('pages.patients.selectedPatient.chat.templates.systemTypeHeader'),
        templates: filterTemplates(TEMPLATES_TYPES.SYSTEM, systemTemplates)
      }
    ],
    [t, filterTemplates, customTemplates, systemTemplates, hiContextPracticeName, hiContextTemplates]
  )

  const templatesLength = useMemo(
    () => [...filteredCustomTemplates, ...filteredSystemTemplates, ...filteredHiTemplates].length,
    [filteredCustomTemplates, filteredSystemTemplates, filteredHiTemplates]
  )

  return !!templatesLength ? (
    templatesList
      .filter(({ templates }) => templates?.length > 0)
      .map(({ type, templates, label }, i) => (
        <SavedRepliesTypeList
          {...{
            label,
            key: `templates-list-${i}`,
            type,
            templates,
            selected,
            setSelected,
            onDeleteTemplate
          }}
        />
      ))
  ) : (
    <DazzedParagraph14 className={isMobile() ? classes.mobileEmptyState : classes.emptyState}>
      {t('pages.accountSettings.savedReplies.emptyFilteredTemplates')}
    </DazzedParagraph14>
  )
}

export default SavedRepliesList
