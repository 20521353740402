import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { ROUTES } from 'consts'
import useFeatureFlags from 'hooks/useFeatureFlags'
import useRolePermissions from 'hooks/useRolePermissions'
import Actions from 'actions'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import OggHeading40 from 'components/common/text/OggHeading40'
import Chevron from 'components/common/icons/ChevrolRightSmall'
import NewMemberForm from './NewMemberForm'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: 10,
    marginBottom: 5
  },
  container: {
    width: 500
  },
  activeLink: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  chevronContainer: {
    marginLeft: 3,
    marginTop: 5,
    width: 20
  }
}))

const AddPracticeMember = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { practiceMembers: isPracticeMembersFFEnabled } = useFeatureFlags()
  const { permissions } = useRolePermissions()

  const practiceName = useSelector(state => state.practiceReducer.details.practiceName)
  const isLoading = useSelector(state => state.profileReducer.practiceMembers.isLoading)
  const errors = useSelector(state => state.profileReducer.practiceMembers.errors)
  const doctor = useSelector(state => state.profileReducer.doctor)

  const handleSubmit = useCallback(
    values => {
      dispatch(Actions.invitePracticeMember(values))
      trackEvent('Invite Practice Member', {
        inviterRole: doctor.roleDescription || 'accountOwner',
        inviteeRole: values.accessType
      })
    },
    [dispatch, doctor.roleDescription]
  )

  const handleCancel = useCallback(() => history.push(ROUTES.PRACTICE_MEMBERS), [history])

  useEffect(() => {
    dispatch(Actions.practiceMemberResetErrors())
  }, [dispatch])

  if (!isPracticeMembersFFEnabled || !permissions.editPracticeMembers) {
    return null
  }

  return (
    <div className={classes.container}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <DazzedParagraph12 className={classes.activeLink} onClick={handleCancel}>
            {t('pages.accountSettings.practiceMembers.addPracticeMember.breadcrumbs.teamMembers')}
          </DazzedParagraph12>
        </Grid>
        <Grid item className={classes.chevronContainer}>
          <Chevron />
        </Grid>
        <Grid item>
          <DazzedParagraph12 thickness="bold">
            {t('pages.accountSettings.practiceMembers.addPracticeMember.breadcrumbs.addPracticeMember')}
          </DazzedParagraph12>
        </Grid>
      </Grid>
      <OggHeading40 textAlign="left" className={classes.heading}>
        {t('pages.accountSettings.practiceMembers.addPracticeMember.title')}
      </OggHeading40>
      <DazzedParagraph12>
        {t('pages.accountSettings.practiceMembers.addPracticeMember.subtitle', { practiceName })}
      </DazzedParagraph12>
      <NewMemberForm
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        errors={errors}
        isLoading={isLoading}
        primaryButtonLabel={t('pages.accountSettings.practiceMembers.addPracticeMember.form.sendInviteBtn')}
      />
    </div>
  )
}

export default AddPracticeMember
