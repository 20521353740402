import React, { useCallback, useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { SeekLeft, SeekRight, SeekerPause, SeekerPlay } from 'components/common/icons'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import { trackEvent } from 'utils/analyticsUtils'
import { useTranslation } from 'react-i18next'
import { getVideoFrame } from 'utils/videoUtils'

const SEEK_MODES = {
  backwards: 'backwards',
  forward: 'forward'
}
const SEEK_FPS = 15
const SEEK_TIME_RATE = 1 / SEEK_FPS // FPS step in seconds

const useStyles = makeStyles({
  video: {
    width: 664
  },
  seekerButton: {
    cursor: 'pointer'
  },
  useFrameButton: {
    marginBottom: 5
  }
})

const VideoFrameSeeker = ({ videoRef, videoUrl, onUseFrame }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  const handleToggleVideo = useCallback(() => {
    trackEvent('Replace scan summary image modal - Video toggled', {
      mode: !isVideoPlaying ? 'play' : 'pause'
    })

    if (isVideoPlaying) {
      videoRef?.current?.pause()
    } else {
      videoRef?.current?.play()
    }
    setIsVideoPlaying(!isVideoPlaying)
  }, [isVideoPlaying, videoRef])

  const handleSeekByFrames = useCallback(
    seekMode => {
      const video = videoRef.current
      const currentVideoTime = video.currentTime

      let newVideoTime
      if (seekMode === SEEK_MODES.backwards) {
        newVideoTime = currentVideoTime - SEEK_TIME_RATE
      } else {
        newVideoTime = currentVideoTime + SEEK_TIME_RATE
      }
      video.currentTime = newVideoTime
      trackEvent('Replace scan summary image modal - Seek frame used', {
        newVideoTime,
        seekMode
      })
    },
    [videoRef]
  )

  const handleUseFrame = useCallback(async () => {
    setIsLoading(true)
    const newFrame = await getVideoFrame({
      videoUrl,
      time: videoRef?.current?.currentTime
    })
    trackEvent('Replace scan summary image modal - Use frame clicked', {
      frameTime: videoRef?.current?.currentTime
    })
    onUseFrame(newFrame)
    setIsLoading(false)
  }, [onUseFrame, videoRef, videoUrl])

  useEffect(() => {
    const video = videoRef.current

    if (!video) {
      return
    }

    video.onplaying = () => setIsVideoPlaying(true)
    video.onpause = () => setIsVideoPlaying(false)
  }, [videoRef])

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <video controls preload="metadata" muted className={classes.video} playsInline ref={videoRef}>
          <source src={videoUrl} type="video/mp4" />
        </video>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item className={classes.seekerButton} onClick={() => handleSeekByFrames(SEEK_MODES.backwards)}>
            <SeekLeft />
          </Grid>
          <Grid item className={classes.seekerButton} onClick={handleToggleVideo}>
            {!isVideoPlaying ? <SeekerPlay /> : <SeekerPause />}
          </Grid>
          <Grid item className={classes.seekerButton} onClick={() => handleSeekByFrames(SEEK_MODES.forward)}>
            <SeekRight />
          </Grid>
          <Grid item className={classes.useFrameButton}>
            <SecondaryButton
              isLoading={isLoading}
              height={32}
              label={t('dialogs.replaceScanSummaryImage.frameSelection.buttonLabel')}
              onClick={handleUseFrame}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default VideoFrameSeeker
