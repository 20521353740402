import * as React from 'react'

function GalleryLeftArrow({ color = '#A8A8A8', ...props }) {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M4.58615 10.0214L13.3742 18.7851C13.6621 19.0721 14.1283 19.0716 14.4158 18.7837C14.703 18.4957 14.7022 18.0293 14.4143 17.7421L6.14931 9.49997L14.4146 1.25786C14.7025 0.970634 14.7032 0.504505 14.4161 0.216537C14.272 0.0721817 14.0832 3.8147e-06 13.8944 3.8147e-06C13.7061 3.8147e-06 13.5181 0.0716991 13.3742 0.215054L4.58615 8.97858C4.44748 9.11656 4.36966 9.30433 4.36966 9.49997C4.36966 9.69561 4.4477 9.88316 4.58615 10.0214Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="19" height="19" fill="white" transform="translate(19 19) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GalleryLeftArrow
