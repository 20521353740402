import React from 'react'
import USTermsDoc from './termsDocs/USTermsDoc'
import CATermsDoc from './termsDocs/CATermsDoc'
import { COUNTRY_CA } from 'consts/countryConsts'

export const termsVersion = country => {
  switch (country) {
    case COUNTRY_CA:
      return 'CA-v1'
    default:
      return 'US-v4'
  }
}

const Terms = ({ country }) => {
  switch (country) {
    case COUNTRY_CA:
      return <CATermsDoc />
    default:
      return <USTermsDoc />
  }
}

export default Terms
