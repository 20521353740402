import React, { forwardRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import GrinLabel from './text/GrinLabel'
import { TextareaAutosize, CircularProgress } from '@material-ui/core'

const useFormInputStyles = props =>
  makeStyles({
    container: {
      position: 'relative'
    },
    formInput: {
      fontFamily: 'Dazzed, Arial',
      resize: 'none',
      width: '100%',
      marginTop: '5px',
      padding: '5px',
      fontSize: '16px',
      transition: 'border .5s ease',
      background: ({ isDisabled, bright }) =>
        isDisabled ? 'var(--bg-color-11)' : bright ? 'var(--bg-color-10)' : 'var(--bg-color-4)',
      border: ({ bright, dark }) => (bright ? '1px solid var(--border-color-2)' : '1px solid var(--border-color-6)'),
      color: ({ bright, dark }) => (bright ? 'var(--text-color-6)' : 'var(--text-color-1)'),
      '&:focus': {
        outline: 'none'
      },
      '&.error': {
        border: ({ bright }) => (bright ? '1px solid red' : '1px solid var(--board-card-color-2)')
      },
      '&::placeholder': {
        fontFamily: 'Dazzed, Arial',
        fontSize: '13px',
        color: ({ bright, dark }) => (bright ? 'black' : 'var(--text-color-1)'),
        opacity: 0.3
      },
      '&::-webkit-scrollbar': {
        width: '8px',
        background: 'white'
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(255, 255, 255, 0)'
      },

      '&::-webkit-scrollbar-thumb': {
        background: 'rgb(215, 215, 215)'
      },

      '&::-webkit-scrollbar-thumb:window-inactive': {
        background: '#efefef'
      }
    },
    landingTheme: {
      width: '100%',
      height: '248px!important',
      lineHeight: 1.5,
      padding: 30,
      fontSize: '18px',
      border: '1px solid var(--border-color-1)!important',
      color: 'var(--text-color-6)',
      '&:focus': {
        outline: 'none'
      },
      '&.error': {
        border: '1px solid red'
      },
      '&::placeholder': {
        fontFamily: 'Dazzed, Arial',
        fontSize: '18px',
        color: 'var(--text-color-11)!important',
        fontStyle: 'normal',
        opacity: 1
      }
    },
    errorMessage: {
      opacity: 0,
      color: ({ bright }) => (bright ? 'red' : 'var(--text-color-10)'),
      marginTop: '2px',
      marginBottom: '5px',
      height: '17px',
      fontSize: '12px',
      transition: 'opacity .15s ease',
      '&.active': {
        opacity: 1
      }
    },
    loadingContainer: {
      position: 'absolute',
      top: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    }
  })({ isDisabled: props.isDisabled, thick: props.thick, bright: props.bright })

const TextAreaAutosize = forwardRef(
  (
    {
      id,
      title,
      value,
      setValue,
      onFocus,
      type = 'text',
      placeholder,
      style = { bright: false, thick: false, landing: false },
      validationRules = {},
      errorMessage,
      isDisabled = false,
      inputStyle = {},
      className = '',
      containerClassName = '',
      keepErrorContainerWhenInactive = true,
      rowsMin = 1,
      rowsMax,
      onKeyDown,
      maxLength,
      isLoading = false,
      labelClassName = ''
    },
    ref
  ) => {
    const classes = useFormInputStyles({
      isDisabled,
      bright: style.bright,
      thick: style.thick
    })

    return (
      <div className={[classes.container, containerClassName].join(' ')}>
        {title && (
          <GrinLabel color={!style.bright && 'var(--text-color-1)'} className={labelClassName}>
            {`${title}${validationRules.required ? '*' : ''}`}
          </GrinLabel>
        )}
        <TextareaAutosize
          className={[
            classes.formInput,
            style.landing ? classes.landingTheme : '',
            errorMessage ? 'error' : '',
            className
          ].join(' ')}
          style={inputStyle}
          onChange={({ target }) => setValue(target.value)}
          placeholder={placeholder}
          disabled={isDisabled}
          error={errorMessage}
          minRows={rowsMin}
          maxRows={rowsMax}
          value={value}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
          id={id}
          ref={ref}
          onFocus={onFocus}
        />
        {keepErrorContainerWhenInactive && errorMessage && (
          <div className={`${classes.errorMessage} ${errorMessage ? 'active' : ''}`}>{errorMessage}</div>
        )}
        {isLoading && (
          <div className={classes.loadingContainer}>
            <CircularProgress size={27} />
          </div>
        )}
      </div>
    )
  }
)

export default TextAreaAutosize
