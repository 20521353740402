import * as React from 'react'

function Chatbubble1(props) {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 2C1 1.44771 1.44772 1 2 1H10C10.5523 1 11 1.44772 11 2V7.03125C11 7.58353 10.5523 8.03125 10 8.03125H3L1 9.22917V2Z"
        stroke="#3C52EF"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default Chatbubble1
