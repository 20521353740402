import React, { useCallback, useEffect, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Trans, useTranslation } from 'react-i18next'
import { actionTypes } from 'consts/automatedActionConsts'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import ErrorMessage from 'components/common/text/ErrorMessage'
import RuleExecutionHourInput from '../RuleExecutionHourInput'
import DazzedParagraph11 from 'components/common/text/DazzedParagraph11'
import usePracticeTimezone from 'hooks/usePracticeTimezone'
import SelectInput from 'components/common/SelectInput'
import { useSelector } from 'react-redux'

const useStyles = props =>
  makeStyles({
    messageInput: {
      border: 'none!important'
    },
    messageInputContainer: {
      borderRadius: 10,
      border: ({ errorMessage }) => `1px solid ${errorMessage ? 'red' : 'var(--bg-color-65)'}`,
      paddingLeft: 10
    },
    executionHourContainer: {
      marginTop: 20
    },
    selectSenderContainer: {
      marginBottom: 20
    }
  })(props)

const SendMessage = ({ onChange, params, errors, isTimeBased }) => {
  const errorMessage = useMemo(() => errors.actions?.[actionTypes.SendMessage], [errors])
  const classes = useStyles({ errorMessage })
  const { t } = useTranslation()

  const { timezoneUtcOffset } = usePracticeTimezone()
  const accountOwnerId = useSelector(state => state.practiceReducer.accountOwner.id)
  const { data: practiceMembers } = useSelector(state => state.profileReducer.practiceMembers)

  const senderOptions = useMemo(
    () =>
      practiceMembers
        .filter(member => member.status !== 'invited' && member.status !== 'disabled')
        .map(member => ({
          value: member.id,
          label: `${member.name}${member.roleDescription ? ` (${member.roleDescription})` : ''}`
        })),
    [practiceMembers]
  )

  const handleParamsChange = useCallback(
    ({ message, executionHour, senderDoctorId }) => {
      onChange({
        type: actionTypes.SendMessage,
        params: {
          message: message ?? (params?.message || ''),
          executionHour: executionHour ?? (params?.executionHour || ''),
          senderDoctorId: senderDoctorId ?? (params?.senderDoctorId || '')
        }
      })
    },
    [onChange, params]
  )

  useEffect(() => {
    if (!params?.senderDoctorId) {
      handleParamsChange({ senderDoctorId: accountOwnerId })
    }
  }, [accountOwnerId, handleParamsChange, params])

  return (
    <Grid container direction="column">
      <Grid item>
        <SelectInput
          style={{ bright: true, thick: true }}
          options={senderOptions}
          value={params?.senderDoctorId || accountOwnerId}
          onChange={option => handleParamsChange({ senderDoctorId: option.value })}
          label={t('pages.accountSettings.automations.editorModal.selectSender')}
          keepErrorContainerWhenInactive={false}
          containerClassName={classes.selectSenderContainer}
        />
      </Grid>
      <Grid item>
        <DazzedParagraph14 strong>
          {t('pages.accountSettings.automations.editorModal.sendMessageLabel')}
        </DazzedParagraph14>
      </Grid>
      <Grid item xs={12} className={classes.messageInputContainer}>
        <TextAreaAutosize
          inputStyle={{ fontSize: 14, fontWeight: 500, height: 100, overflow: 'auto' }}
          rowsMax={4}
          style={{ bright: true }}
          className={classes.messageInput}
          placeholder={t('pages.accountSettings.automations.editorModal.sendMessagePlaceholder')}
          keepErrorContainerWhenInactive={false}
          value={params?.message || ''}
          setValue={message => handleParamsChange({ message })}
        />
      </Grid>
      {errorMessage && (
        <Grid item>
          <ErrorMessage active={!!errorMessage} text={errorMessage} />
        </Grid>
      )}
      {isTimeBased && (
        <>
          <Grid item className={classes.executionHourContainer}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <DazzedParagraph14 strong>
                  {t('pages.accountSettings.automations.editorModal.sendThisMessageAt')}
                </DazzedParagraph14>
              </Grid>
              <Grid item>
                <RuleExecutionHourInput
                  value={params?.executionHour || ''}
                  onChange={value => handleParamsChange({ executionHour: value })}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: 5 }}>
            <DazzedParagraph11>
              <Trans
                i18nKey={'pages.accountSettings.automations.editorModal.executionHourWithTimezone'}
                values={{
                  hour: params?.executionHour,
                  utcOffset: timezoneUtcOffset
                }}
              />
            </DazzedParagraph11>
          </Grid>
        </>
      )}
    </Grid>
  )
}
export default SendMessage
