import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'
import { rangeArray } from 'utils/arrayUtils'

const useStyles = makeStyles(theme => ({
  miniTimelineSkeletonRoot: {
    position: 'relative',
    height: '100%',
    minWidth: 65,
    marginTop: 38 // back button (38px)
  },
  timelineContainer: {
    height: '100%',
    padding: '8px 10px',
    overflowY: 'hidden'
  },
  verticalAxis: {
    position: 'absolute',
    top: 25,
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '1px',
    height: '100%',
    background: '#CED9E7',
    opacity: '1',
    zIndex: 999
  },
  itemsContainer: {
    paddingTop: 10
  },
  timelineItem: {
    zIndex: 9999,
    background: 'var(--bg-color-timelineV2)',
    marginBottom: 18,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  }
}))

const NUM_OF_SCANS = 30
const SCANS_PER_MONTH = 4

const MiniTimelineSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.miniTimelineSkeletonRoot}>
      <div style={{ height: 25 }} />
      <div className={classes.timelineContainer}>
        <div className={classes.verticalAxis} />
        <div className={classes.itemsContainer}>
          {rangeArray(NUM_OF_SCANS).map(i => (
            <React.Fragment key={i}>
              {i % SCANS_PER_MONTH === 2 ? (
                <div className={classes.timelineItem}>
                  <Skeleton variant="text" width={'100%'} />
                </div>
              ) : (
                <></>
              )}
              <div className={classes.timelineItem} style={{ padding: 3 }}>
                <Skeleton variant="circle" width={10} height={10} />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div style={{ height: 25 }} />
    </div>
  )
}

export default MiniTimelineSkeleton
