import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import LegendImages from 'resources/autochart/Legend'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  img: {
    objectFit: 'contain',
    height: '100%',
    width: '100%'
  },
  label: {
    marginLeft: 12
  }
}))

const AutochartLegendItem = ({ condition, height = '100%' }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" style={{ height }}>
      <Grid item style={{ height: `calc(${height}px - 8px)` }}>
        <img alt="" src={LegendImages[condition]} className={classes.img} />
      </Grid>
      <Grid item className={classes.label}>
        <DazzedParagraph12>{t(`dialogs.autochart.conditions.${condition}`)}</DazzedParagraph12>
      </Grid>
    </Grid>
  )
}

export default AutochartLegendItem
