import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import BaseModal from 'components/common/modals/BaseModal'
import AutochartGrid from './AutochartGrid/AutochartGrid'
import { Grid } from '@material-ui/core'
import { AsyncStatus } from 'consts'
import AutochartLegend from './AutochartLegend/AutochartLegend'
import AutochartRightPanel from './AutochartRightPanel'
import ConfirmationModal from 'components/common/ConfirmationModal'
import useAutochart from './AutochartGrid/useAutochart'
import AutochartHeader from './AutochartHeader'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    padding: '32px 32px 0 32px'
  },
  dialogContent: {
    padding: '0 24px 10px 24px!important'
  },
  rightPanelContainer: {
    padding: '0 4px'
  }
}))

const AutochartDialog = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { closeAutochartDialog, saveAutochartData } = useAutochart()
  const { isOpen, isTouched, isSaving, status, isRightPanelVisible } = useSelector(state => state.autochartReducer)

  const [confirmCloseModalOpen, setConfirmCloseModalOpen] = useState(false)

  const handleClose = useCallback(() => {
    // if not touched, close. if touched & confirmation modal is closed, open it. if touched & modal opened, close

    if (!isTouched) {
      closeAutochartDialog()
    } else if (!confirmCloseModalOpen) {
      setConfirmCloseModalOpen(true)
    } else {
      closeAutochartDialog()
    }
  }, [isTouched, confirmCloseModalOpen, closeAutochartDialog])

  useEffect(() => {
    setConfirmCloseModalOpen(false)
  }, [isOpen])

  return (
    isOpen && (
      <>
        <BaseModal
          open={isOpen}
          className={classes.root}
          primaryLabel={t('general.saveChanges')}
          onPrimaryBtnClick={saveAutochartData}
          isLoading={isSaving}
          secondaryLabel={t('general.close')}
          onSecondaryBtnClick={handleClose}
          handleClose={handleClose}
          largerButtons
          disableEnforceFocus
          contentClassName={classes.dialogContent}
        >
          <Grid container spacing={2}>
            <Grid item xs={isRightPanelVisible ? 6 : 11}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <AutochartHeader />
                </Grid>
                <Grid item>
                  <Grid container direction={isRightPanelVisible ? 'column' : 'row-reverse'}>
                    <Grid item xs={isRightPanelVisible ? 12 : 10}>
                      <AutochartGrid isLoading={status === AsyncStatus.Loading} />
                    </Grid>
                    <Grid item xs={isRightPanelVisible ? 12 : 2}>
                      <AutochartLegend />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={isRightPanelVisible ? 6 : 1} className={classes.rightPanelContainer}>
              <AutochartRightPanel />
            </Grid>
          </Grid>
        </BaseModal>
        <ConfirmationModal
          isOpen={confirmCloseModalOpen}
          onClose={() => setConfirmCloseModalOpen(false)}
          onConfirm={handleClose}
          title={t('pages.accountSettings.leaveWithoutSavingModal.title')}
          text={t('pages.accountSettings.leaveWithoutSavingModal.content')}
          additionalText={t('pages.accountSettings.leaveWithoutSavingModal.content2')}
          acceptButtonText={t('general.close')}
        />
      </>
    )
  )
}

export default AutochartDialog
