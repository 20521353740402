const QA_REGENERATE_SCAN = 'QA_REGENERATE_SCAN'
const QA_REGENERATE_SCAN_RECEIVED = 'QA_REGENERATE_SCAN_RECEIVED'
const QA_REGENERATE_SCAN_FAILED = 'QA_REGENERATE_SCAN_FAILED'
const QA_TOGGLE_REGENERATE_SCAN_MODAL = 'QA_TOGGLE_REGENERATE_SCAN_MODAL'

const qaToggleRegenerateScanModal = payload => ({
  type: QA_TOGGLE_REGENERATE_SCAN_MODAL,
  payload
})

const qaRegenerateScan = payload => ({
  type: QA_REGENERATE_SCAN,
  payload
})

const qaRegenerateScanReceived = payload => ({
  type: QA_REGENERATE_SCAN_RECEIVED,
  payload
})

const qaRegenerateScanFailed = payload => ({
  type: QA_REGENERATE_SCAN_FAILED,
  payload
})

export default {
  QA_REGENERATE_SCAN,
  QA_REGENERATE_SCAN_RECEIVED,
  QA_REGENERATE_SCAN_FAILED,
  QA_TOGGLE_REGENERATE_SCAN_MODAL,
  qaToggleRegenerateScanModal,
  qaRegenerateScan,
  qaRegenerateScanReceived,
  qaRegenerateScanFailed
}
