import * as React from 'react'

const MissingToothLarge = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 4 }}
  >
    <path d="M1 1L17 17" stroke="#BDD3EE" strokeWidth="1.5" />
    <path d="M17 1L1 17" stroke="#BDD3EE" strokeWidth="1.5" />
  </svg>
)

export default MissingToothLarge
