import React from 'react'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import ExternalLinkIcon from 'components/common/icons/ExternalLink'
import CloseIcon from 'components/common/icons/Close'

const useStyles = () =>
  makeStyles({
    container: {
      paddingRight: 15,
      paddingLeft: 15,
      height: 60,
      borderBottom: '1px solid var(--border-color-11)'
    },
    button: {
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    titleText: {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  })()

const TemplatesHeader = ({ onClose, onOpenTemplatesLink = null, title }) => {
  const classes = useStyles()

  return (
    <Grid container justifyContent="space-between" alignItems="center" className={classes.container}>
      <Grid item xs={1}>
        {!!onOpenTemplatesLink && (
          <IconButton onClick={onOpenTemplatesLink}>
            <ExternalLinkIcon />
          </IconButton>
        )}
      </Grid>
      <DazzedHeading18 className={onOpenTemplatesLink && classes.titleText} onClick={onOpenTemplatesLink}>
        {title}
      </DazzedHeading18>
      <IconButton onClick={onClose}>
        <CloseIcon color="#ABB0B8" />
      </IconButton>
    </Grid>
  )
}

export default TemplatesHeader
