import { useSelector } from 'react-redux'

export default () => {
  const practiceCountry = useSelector(state => state.practiceReducer.details?.country || 'US')
  const grinPricingUrl = useSelector(state => {
    const pricingUrlByCountry = state.appReducer.appconfig?.app?.payment?.grinPricingUrlByCountry || {}
    return pricingUrlByCountry[practiceCountry] || pricingUrlByCountry['US']
  })

  return grinPricingUrl
}
