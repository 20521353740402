import { Storage } from 'aws-amplify'
import { logInfo } from './logUtils'
import { S3Instance, getCloudFrontUrl } from './storageUtils'
import axios from 'axios'
import { blobToBase64 } from './fileUtils'

export const putObject = params =>
  new Promise((resolve, reject) => {
    S3Instance().putObject(params, (err, data) => {
      if (err) {
        reject(err)
        return
      }
      resolve(data)
    })
  })

export const listObjects = params =>
  new Promise((resolve, reject) => {
    S3Instance().listObjects(params, (err, data) => {
      if (err) {
        reject(err)
        return
      }
      resolve(data)
    })
  })

export const getObject = params =>
  new Promise((resolve, reject) => {
    S3Instance().getObject(params, (err, data) => {
      if (err) {
        reject(err)
        return
      }
      resolve(data)
    })
  })

export const fetchS3File = ({ key, bucket }) => {
  // /public/some/path -> some/path
  // /some/path -> some/path
  // some/path -> some/path

  key = key.includes('public') ? key.replace('public', '') : key
  key = key.startsWith('/') ? key.replace('/', '') : key

  logInfo(`S3: fetching file from bucket: ${bucket}. key: ${key}`, {
    args: { key, bucket }
  })

  const startTime = new Date().getTime()

  return Storage.get(key, { bucket })
    .then(url => {
      logInfo(`S3: received download link for object: ${bucket}/${key}. url: ${url}`, { args: { key, bucket }, url })
      return fetch(url)
    })
    .then(res => res.blob())
    .then(blobToBase64)
    .then(base64 => base64.split(',')[1])
    .then(result => {
      logInfo(`S3: object downloaded successfully: ${bucket}/${key}`, {
        args: { bucket, key },
        took: new Date().getTime() - startTime
      })
      return result
    })
    .catch(error => {
      logInfo(`S3: failed to download object: ${bucket}/${key}`, { args: { key, bucket }, error })
      throw error
    })
}

export const fetchCloudFrontFile = ({ key }) =>
  axios
    .get(`${getCloudFrontUrl()}/${key}`, { responseType: 'arraybuffer' })
    .then(res => btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')))
