import * as React from 'react'

export default () => {
  return (
    <svg width="26" height="34" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 3.58064C1 2.15539 2.15539 1 3.58065 1L13.9032 1V11.3226H24.5294V30.4194C24.5294 31.8446 23.374 33 21.9488 33H3.58064C2.15539 33 1 31.8446 1 30.4194V3.58064Z"
        stroke="#ABB0B8"
        strokeLinejoin="round"
      />
      <path d="M13.9033 1L24.2259 11.3226" stroke="#ABB0B8" />
    </svg>
  )
}
