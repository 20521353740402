import * as React from 'react'

const Assignee2 = ({ color = '#4151E6', style }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <circle cx="9" cy="9" r="8" stroke={color} strokeWidth="1.33333" />
      <mask id="mask0_54_149" maskUnits="userSpaceOnUse" x="1" y="1" width="16" height="16">
        <circle cx="9" cy="9" r="8" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_54_149)">
        <circle cx="9.00001" cy="8.111" r="3.33333" stroke={color} strokeWidth="1.33333" />
        <circle cx="8.99999" cy="17.0001" r="4.44444" stroke={color} strokeWidth="1.33333" />
      </g>
    </svg>
  )
}

export default Assignee2
