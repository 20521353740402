import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import ScanFeedCard from '../ScanFeedCard'
import { ScanFeedPageSectionNames } from 'consts/timelineConsts'
import Carousel from 'react-material-ui-carousel'
import ScanReferralItem from 'components/Patients/Timeline/V2/ScanFeed/ScanReferralsCard/ScanReferralItem'
import { scrollBar } from 'styles/theme'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  referralItemContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
    paddingRight: 8,
    ...scrollBar
  }
}))

/**
 * @prop referrals - the `referrals` list as returned from the `useScanReferrals` hook.
 */
const ScanReferralsCard = ({ referrals = [], analyticsPayload = {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleCarouselSelectionChanged = useCallback(
    index => {
      const selectedReferral = referrals[index]
      trackEvent(`Scan Referrals - selected referral changed`, {
        ...analyticsPayload,
        referralId: selectedReferral.id,
        referralIndex: index
      })
    },
    [analyticsPayload, referrals]
  )

  return (
    <ScanFeedCard
      id={ScanFeedPageSectionNames.Shares}
      title={t('pages.patients.selectedPatient.timelineV2.scanFeed.referrals.title')}
    >
      <Carousel
        indicatorIconButtonProps={{ style: { color: 'rgba(217, 217, 217, 1)' } }}
        activeIndicatorIconButtonProps={{ style: { color: 'var(--bg-color-7)' } }}
        animation={'slide'}
        autoPlay={false}
        swipe={false}
        cycleNavigation
        indicatorContainerProps={{ style: { marginTop: 24 } }}
        onChange={handleCarouselSelectionChanged}
        indicators={referrals.length > 1}
      >
        {referrals.map(referral => (
          <div key={referral.id} className={classes.referralItemContainer}>
            <ScanReferralItem
              key={referral.id}
              withAligner={referral.withAligner}
              referralName={referral.referralName}
              referralSpeciality={referral.referralSpeciality}
              notificationDestination={referral.notificationDestination}
              grinDoctorNote={referral.grinDoctorNote}
              referralReview={referral.referralReview}
              reviewSubmissionDate={referral.reviewSubmissionDate}
              attachments={referral.attachments}
              createdAt={referral.createdAt}
              createdBy={referral.createdBy}
            />
          </div>
        ))}
      </Carousel>
    </ScanFeedCard>
  )
}

export default ScanReferralsCard
