export const GRIN_TRIAL_PLAN = 'grin-trial'
export const GRIN_PRACTICE_MONTHLY_PLAN = 'grin-practice_monthly'
export const GRIN_PRACTICE_ANNUALLY_PLAN = 'grin-practice_annually'
export const PRACTICE_PLUS_MONTHLY_PLAN = 'practice-plus_monthly'
export const PRACTICE_PLUS_ANNUALLY_PLAN = 'practice-plus_annually'
export const GRIN_ENTERPRISE_MONTHLY_PLAN = 'grin-enterprise_monthly'
export const GRIN_ENTERPRISE_ANNUALLY_PLAN = 'grin-enterprise_annually'
export const INTERNAL_PLAN = 'internal'

export const GRIN_PRACTICE_PLAN_GROUP = 'grin-practice'
export const GRIN_PRACTICE_PLUS_PLAN_GROUP = 'practice-plus'
export const GRIN_ENTERPRISE_PLAN_GROUP = 'grin-enterprise'
export const GRIN_SUBSCRIPTION_GROUP = 'grin-subscription'
export const GRIN_SUBSCRIPTION_V2_GROUP = 'grin-subscription-v2'
export const GRIN_CHURNED_PRACTICE_GROUP = 'grin-churn'

export const BILLING_PERIOD_MONTHLY = 'monthly'
export const BILLING_PERIOD_ANNUALLY = 'annually'

export const PLAN_GROUP_COLORS = {
  [GRIN_TRIAL_PLAN]: {
    color: '#E0E0E0'
  },
  [GRIN_PRACTICE_PLAN_GROUP]: {
    color: '#8FB4FF'
  },
  [GRIN_PRACTICE_PLUS_PLAN_GROUP]: {
    color: '#97FFBA'
  },
  [GRIN_ENTERPRISE_PLAN_GROUP]: {
    color: '#E3FDEB'
  },
  [GRIN_SUBSCRIPTION_GROUP]: {
    color: '#BBD2FF'
  },
  [INTERNAL_PLAN]: {
    color: '#D6E9C6'
  }
}
