import * as React from 'react'

function VideoCameraIcon(props) {
  return props.cameraOff ? (
    <svg width="16" height="16" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0624 2.04442L13.4005 0.688868C13.4676 0.661703 13.5402 0.651837 13.612 0.660149C13.6838 0.668461 13.7524 0.694694 13.8117 0.736505C13.8711 0.778317 13.9193 0.834407 13.9522 0.899769C13.985 0.965131 14.0014 1.03773 13.9998 1.11109V4.00553V6.89998C13.9996 6.9724 13.9819 7.04366 13.9483 7.10754C13.9147 7.17143 13.8662 7.22601 13.8072 7.26654C13.7481 7.30706 13.6801 7.33231 13.6093 7.34007C13.5384 7.34784 13.4667 7.33789 13.4005 7.31109L10.0624 5.95553V2.04442Z"
        fill="#001335"
      />
      <path d="M1 0H7.5L1.5 8H1C0.447715 8 0 7.55228 0 7V1C0 0.447715 0.447715 0 1 0Z" fill="#001335" />
      <path d="M8.5 8H4L9.5 0.5V7C9.5 7.55228 9.05228 8 8.5 8Z" fill="#001335" />
    </svg>
  ) : (
    <svg width={props.size || '16'} height={props.size || '16'} viewBox="0 0 16 16" fill="none">
      <path
        d="M4 10.074V5.92584C4 5.76868 4.06146 5.61795 4.17085 5.50682C4.28025 5.39569 4.42862 5.33325 4.58333 5.33325H9.83328C9.98799 5.33325 10.1364 5.39569 10.2458 5.50682C10.3552 5.61795 10.4166 5.76868 10.4166 5.92584V10.074C10.4166 10.2312 10.3552 10.3819 10.2458 10.493C10.1364 10.6042 9.98799 10.6666 9.83328 10.6666H4.58333C4.42862 10.6666 4.28025 10.6042 4.17085 10.493C4.06146 10.3819 4 10.2312 4 10.074ZM12.9337 5.79251L10.7083 6.69622V9.30362L12.9337 10.2073C12.9778 10.2252 13.0256 10.2318 13.0729 10.2266C13.1201 10.2215 13.1654 10.2046 13.2048 10.1776C13.2442 10.1506 13.2765 10.1142 13.2989 10.0716C13.3213 10.029 13.3331 9.98153 13.3333 9.93325V6.07399C13.3343 6.02509 13.3233 5.97669 13.3015 5.93311C13.2796 5.88954 13.2474 5.85214 13.2079 5.82427C13.1683 5.79639 13.1225 5.77891 13.0747 5.77336C13.0269 5.76782 12.9784 5.7744 12.9337 5.79251Z"
        fill={props.color || '#001335'}
      />
    </svg>
  )
}

export default VideoCameraIcon
