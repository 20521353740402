import * as React from 'react'

const ShowInvitationCode = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none">
      <rect x="6.5" y="7.5" width="17" height="12" rx="1.5" fill="white" stroke="#282828" />
      <path d="M7 8L14.2929 15.2929C14.6834 15.6834 15.3166 15.6834 15.7071 15.2929L23 8" stroke="#282828" />
      <path d="M7 19L12.5 13.5" stroke="#282828" />
      <path d="M23 19L17.5 13.5" stroke="#282828" />
      <circle cx="23.5" cy="19.5" r="6.5" fill="white" />
      <circle cx="23.5" cy="19.5" r="5" fill="#282828" stroke="#282828" />
      <path d="M23.5 20V22.5" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <circle cx="23.5" cy="17" r="1" fill="white" />
    </svg>
  )
}

export default ShowInvitationCode
