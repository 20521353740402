import * as React from 'react'

export default () => {
  return (
    <svg width="20" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.51172 5.74406C3.51172 4.51038 4.51181 3.51029 5.74549 3.51029H14.6806C15.9142 3.51029 16.9143 4.51038 16.9143 5.74406V14.6791C16.9143 15.9128 15.9142 16.9129 14.6806 16.9129H5.74549C4.51181 16.9129 3.51172 15.9128 3.51172 14.6791V5.74406Z"
        fill="#041333"
      />
      <path
        d="M12.0637 9.03681C12.7558 8.89362 13.4395 8.71176 14.112 8.49206C13.7101 5.36155 10.3953 5.32706 10.3953 5.32706C9.03088 5.32706 7.90977 5.79925 7.03198 6.74363C6.18384 7.66181 5.75977 8.79288 5.75977 10.1368C5.75977 11.4677 6.16859 12.5746 6.98625 13.4573C7.86428 14.4018 9.03703 14.8741 10.5045 14.8741C11.3135 14.8741 12.0451 14.7819 12.6992 14.5974C13.3534 14.4129 13.8268 14.2284 14.1194 14.044L14.1238 9.96342C14.124 9.94421 14.1197 9.92522 14.1114 9.90799C14.103 9.89076 14.0908 9.87577 14.0758 9.86422C14.0607 9.85268 14.0432 9.84491 14.0247 9.84154C14.0062 9.83817 13.9871 9.83929 13.9691 9.84481C13.4467 10.0108 12.1554 10.3711 10.7176 10.5986V11.4322C11.2038 11.4413 11.5265 11.4853 11.6858 11.5641C11.9311 11.6916 12.1621 11.9594 12.1621 12.3678V12.7635C12.1628 12.8891 12.1388 13.0135 12.0915 13.1294C12.0442 13.2453 11.9746 13.3503 11.8868 13.4382C11.6668 13.6551 11.3144 13.8006 10.7507 13.8006C9.70907 13.8006 8.85111 13.3175 8.53916 12.5874C8.16467 11.7113 8.21256 11.2293 8.21256 10.104C8.21256 8.73354 8.22196 8.27272 8.65953 7.54387C9.01359 6.95356 9.70347 6.67851 10.2988 6.6964C10.2988 6.6964 11.3151 6.60527 11.7858 7.52985C12.1217 8.19137 12.0637 9.03681 12.0637 9.03681Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.86354 0.999641C7.32617 0.999641 7.7012 1.37468 7.7012 1.8373V3.51263H6.02588V1.8373C6.02588 1.37468 6.40091 0.999641 6.86354 0.999641Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4279 6.86216C19.4279 7.32479 19.0529 7.69982 18.5903 7.69982L16.9149 7.69982L16.9149 6.0245L18.5903 6.0245C19.0529 6.0245 19.4279 6.39953 19.4279 6.86216Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.86354 19.43C7.32617 19.43 7.7012 19.055 7.7012 18.5924V16.9171H6.02588V18.5924C6.02588 19.055 6.40091 19.43 6.86354 19.43Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.999268 6.86216C0.999268 7.32479 1.3743 7.69982 1.83693 7.69982L3.51225 7.69982L3.51225 6.0245L1.83693 6.0245C1.3743 6.0245 0.999268 6.39953 0.999268 6.86216Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2141 0.999641C10.6767 0.999641 11.0517 1.37468 11.0517 1.8373V3.51263H9.3764V1.8373C9.3764 1.37468 9.75144 0.999641 10.2141 0.999641Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4279 10.2109C19.4279 10.6735 19.0529 11.0485 18.5903 11.0485L16.9149 11.0485L16.9149 9.37321L18.5903 9.37321C19.0529 9.37321 19.4279 9.74825 19.4279 10.2109Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2141 19.43C10.6767 19.43 11.0517 19.055 11.0517 18.5924V16.9171H9.3764V18.5924C9.3764 19.055 9.75144 19.43 10.2141 19.43Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.999268 10.2109C0.999268 10.6735 1.3743 11.0485 1.83693 11.0485L3.51225 11.0485L3.51225 9.37321L1.83693 9.37321C1.3743 9.37321 0.999268 9.74825 0.999268 10.2109Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5645 0.999641C14.0272 0.999641 14.4022 1.37468 14.4022 1.8373V3.51263H12.7269V1.8373C12.7269 1.37468 13.1019 0.999641 13.5645 0.999641Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4279 13.5632C19.4279 14.0258 19.0529 14.4008 18.5903 14.4008L16.9149 14.4008L16.9149 12.7255L18.5903 12.7255C19.0529 12.7255 19.4279 13.1005 19.4279 13.5632Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5645 19.43C14.0272 19.43 14.4022 19.055 14.4022 18.5924V16.9171H12.7269V18.5924C12.7269 19.055 13.1019 19.43 13.5645 19.43Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.999268 13.5632C0.999268 14.0258 1.3743 14.4008 1.83693 14.4008L3.51225 14.4008L3.51225 12.7255L1.83693 12.7255C1.3743 12.7255 0.999268 13.1005 0.999268 13.5632Z"
        fill="#041333"
      />
    </svg>
  )
}
