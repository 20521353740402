import moment from 'moment'
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import Actions from 'actions'
import ProfilePicture from 'components/common/ProfilePicture'
import FormInput from 'components/common/FormInput'
import ImageUploaderWithCrop from './ImageUploaderWithCrop'
import PhoneNumberInput from 'components/common/PhoneNumberInput'
import { removePlus, getCountryCode, getPhoneNumber } from 'utils/stringUtils'
import { useTranslation } from 'react-i18next'
import DazzedHeading24 from 'components/common/text/DazzedHeading24'
import LeaveModal from 'components/common/modals/LeaveModal'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  phoneRequired: {
    color: 'red',
    fontWeight: 500,
    marginBottom: 15,
    height: 17,
    fontSize: 12
  },
  profilePicture: {
    marginRight: 15
  },
  name: {
    fontSize: 22,
    fontWeight: 'normal',
    marginBottom: 5
  },
  profilePictureContainer: {
    paddingBottom: 20
  },
  saveButton: {
    paddingTop: 10
  },
  title: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
    color: 'var(--text-color-2)'
  },
  body: {
    fontSize: '16px',
    paddingTop: '10px',
    lineHeight: '25px',
    color: 'var(--text-color-6)',
    marginBottom: '40px'
  }
}))

const EditProfile = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { doctor, isLoading } = useSelector(state => state.profileReducer)

  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [phoneValue, setPhoneValue] = useState('')
  const [jobTitleValue, setJobTitleValue] = useState('')

  const [formErrors, setFormErrors] = useState()

  const isDataUnsaved = useMemo(
    () =>
      !isEmpty(doctor) &&
      (doctor.name !== name ||
        doctor.countryCode !== getCountryCode(countryCode) ||
        doctor.roleDescription !== jobTitleValue ||
        doctor.phoneNumber !== getPhoneNumber(phoneValue, countryCode)),
    [countryCode, doctor, jobTitleValue, name, phoneValue]
  )

  const requestDoctorPhotoUpdate = useCallback(
    payload => dispatch(Actions.requestDoctorPhotoUpdate(payload)),
    [dispatch]
  )

  const removeDoctorPhoto = useCallback(() => dispatch(Actions.removeDoctorPhoto({ doctor })), [dispatch, doctor])

  const requestDoctorDetailsUpdate = useCallback(() => {
    const payload = {
      id: doctor.id,
      name,
      phoneNumber: getPhoneNumber(phoneValue, countryCode),
      countryCode: getCountryCode(countryCode),
      roleDescription: jobTitleValue
    }
    dispatch(Actions.requestDoctorDetailsUpdate(payload))
  }, [dispatch, doctor.id, name, countryCode, phoneValue, jobTitleValue])

  useEffect(() => {
    setName(doctor.name)
    setEmail(doctor.email)
    if (doctor.countryCode && doctor.phoneNumber) {
      setPhoneValue(removePlus(doctor.countryCode) + doctor.phoneNumber)
      setCountryCode(doctor.countryCode)
    }

    setJobTitleValue(doctor.roleDescription)
  }, [doctor])

  const validateInputs = useCallback(() => {
    const errors = {}

    if (!name?.trim().length) {
      errors.name = t('pages.accountSettings.editProfile.nameRequiredError')
    }

    if (!phoneValue.slice(countryCode.length)?.trim().length) {
      errors.phoneValue = t('pages.accountSettings.editProfile.practiceZipRequiredError')
    }

    if (!jobTitleValue?.trim().length) {
      errors.jobTitleValue = t('pages.accountSettings.editProfile.jobTitleRequiredError')
    }

    setFormErrors(errors)
    return isEmpty(errors)
  }, [name, phoneValue, countryCode.length, jobTitleValue, t])

  const handleSubmit = e => {
    e.preventDefault()
    if (!validateInputs()) return
    trackEvent('Edit profile - profile details updated')
    requestDoctorDetailsUpdate()
  }

  useEffect(() => {
    setFormErrors()
  }, [countryCode, name, phoneValue, validateInputs])

  return (
    <>
      <LeaveModal blockNavigation={!isLoading && isDataUnsaved} />
      <Grid container>
        <Grid item xs={4}>
          <Grid container direction="column">
            <Grid item className={classes.profilePictureContainer}>
              <Grid container>
                <Grid item>
                  <ProfilePicture
                    name={doctor.name}
                    email={doctor.email}
                    photo={doctor.photo}
                    size="big"
                    className={classes.profilePicture}
                  />
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <DazzedHeading24 className={classes.name}>{doctor.name}</DazzedHeading24>
                    </Grid>
                    <Grid item>
                      <ImageUploaderWithCrop
                        iconButtons
                        label={
                          doctor.photo?.key
                            ? t('pages.accountSettings.editProfile.updateProfilePicture')
                            : t('pages.accountSettings.editProfile.uploadProfilePicture')
                        }
                        secondaryLabel={
                          doctor.photo?.key && t('pages.accountSettings.editProfile.deleteProfilePicture')
                        }
                        onUploadDone={key => requestDoctorPhotoUpdate({ key: key[0], doctor })}
                        onSecondaryClick={removeDoctorPhoto}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormInput
                    title={t('pages.accountSettings.editProfile.nameLabel')}
                    style={{ bright: true, thick: true }}
                    value={name}
                    validationRules={{ required: true }}
                    errorMessage={formErrors?.name}
                    setValue={setName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInput
                    title={t('pages.accountSettings.editProfile.emailLabel')}
                    style={{ bright: true, thick: true }}
                    value={email}
                    validationRules={{ required: true }}
                    isDisabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneNumberInput
                    title={t('pages.accountSettings.editProfile.phoneNumberLabel')}
                    style={{ bright: true, thick: true }}
                    value={phoneValue}
                    notes={t('pages.accountSettings.editProfile.phoneNumberMessage')}
                    validationRules={{ required: true }}
                    setValue={setPhoneValue}
                    setCountryCode={setCountryCode}
                  />
                </Grid>
                {formErrors?.phoneValue && (
                  <div className={classes.phoneRequired}>
                    {t('pages.accountSettings.editProfile.phoneRequiredError')}
                  </div>
                )}
                <Grid item xs={12}>
                  <FormInput
                    title={t('pages.accountSettings.editProfile.jobTitleLabel')}
                    style={{ bright: true, thick: true }}
                    value={jobTitleValue}
                    validationRules={{ required: true }}
                    errorMessage={formErrors?.jobTitleValue}
                    setValue={setJobTitleValue}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.saveButton}>
              <PrimaryButton
                label={t('pages.accountSettings.editProfile.primaryBtn')}
                isLoading={isLoading}
                type="submit"
                disabled={isLoading || !isDataUnsaved}
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Grid container direction="column" justifyContent="center">
                <Grid item className={classes.title}>
                  {t('pages.profile.joinedDateLabel')}
                </Grid>
                <Grid item className={classes.body}>
                  {doctor?.createdAt ? moment(doctor.createdAt).format('MMM Do YYYY') : t('pages.profile.notAvailable')}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default EditProfile
