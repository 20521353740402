export const Surfaces = {
  B: 'B',
  M: 'M',
  L: 'L',
  D: 'D',
  O: 'O'
}

export const ConditionEffects = {
  WholeTooth: 'whole-tooth',
  Surfaces: 'surfaces'
}

export const ConditionTypes = {
  MissingTooth: 'missing-tooth',
  Caries: 'caries',
  Filling: 'filling',
  Crown: 'crown',
  Recession: 'recession',
  Inflamation: 'inflammation',
  Tartar: 'tartar',
  Plaque: 'plaque',
  RootCanal: 'root-canal',
  TextualNote: 'textual-note'
}

export const DrawMethod = {
  Layer: 'layer',
  Icon: 'icon'
}

export const Conditions = {
  [ConditionTypes.Caries]: {
    id: ConditionTypes.Caries,
    effect: ConditionEffects.Surfaces,
    renderPrecedence: 2,
    severityOptions: {
      options: 4,
      lowestLabel: 'enamel',
      highestLabel: 'dentin'
    },
    drawMethod: DrawMethod.Layer
  },
  [ConditionTypes.Filling]: {
    id: ConditionTypes.Filling,
    effect: ConditionEffects.Surfaces,
    renderPrecedence: 3,
    types: ['amalgam', 'composite', 'other'],
    drawMethod: DrawMethod.Layer
  },
  [ConditionTypes.MissingTooth]: {
    id: ConditionTypes.MissingTooth,
    effect: ConditionEffects.WholeTooth,
    renderPrecedence: 1,
    globalAsset: true,
    drawMethod: DrawMethod.Layer
  },
  [ConditionTypes.Crown]: {
    id: ConditionTypes.Crown,
    effect: ConditionEffects.WholeTooth,
    renderPrecedence: 5,
    types: ['metal', 'tooth-colored', 'other'],
    drawMethod: DrawMethod.Layer
  },
  [ConditionTypes.Recession]: {
    id: ConditionTypes.Recession,
    effect: ConditionEffects.WholeTooth,
    renderPrecedence: 1,
    globalAsset: true,
    drawMethod: DrawMethod.Layer
  },
  [ConditionTypes.Inflamation]: {
    id: ConditionTypes.Inflamation,
    effect: ConditionEffects.WholeTooth,
    drawMethod: DrawMethod.Icon,
    renderPrecedence: 2
  },
  [ConditionTypes.Tartar]: {
    id: ConditionTypes.Tartar,
    effect: ConditionEffects.WholeTooth,
    drawMethod: DrawMethod.Icon,
    renderPrecedence: 1
  },
  [ConditionTypes.Plaque]: {
    id: ConditionTypes.Plaque,
    effect: ConditionEffects.WholeTooth,
    drawMethod: DrawMethod.Icon,
    renderPrecedence: 3
  },
  [ConditionTypes.RootCanal]: {
    id: ConditionTypes.RootCanal,
    effect: ConditionEffects.WholeTooth,
    renderPrecedence: 4,
    drawMethod: DrawMethod.Layer
  },
  [ConditionTypes.TextualNote]: {
    id: ConditionTypes.TextualNote,
    effect: ConditionEffects.WholeTooth,
    renderPrecedence: -1,
    requireTextualNote: true,
    drawMethod: null
  }
}

const LeftSideSurfaces = [Surfaces.B, Surfaces.M, Surfaces.L, Surfaces.D, Surfaces.O]
const RightSideSurfaces = [Surfaces.B, Surfaces.D, Surfaces.L, Surfaces.M, Surfaces.O]

export const Teeth = {
  1: { number: 1, surfaces: LeftSideSurfaces },
  2: { number: 2, surfaces: LeftSideSurfaces },
  3: { number: 3, surfaces: LeftSideSurfaces },
  4: { number: 4, surfaces: LeftSideSurfaces },
  5: { number: 5, surfaces: LeftSideSurfaces },
  6: { number: 6, surfaces: LeftSideSurfaces },
  7: { number: 7, surfaces: LeftSideSurfaces },
  8: { number: 8, surfaces: LeftSideSurfaces },
  9: { number: 9, surfaces: RightSideSurfaces },
  10: { number: 10, surfaces: RightSideSurfaces },
  11: { number: 11, surfaces: RightSideSurfaces },
  12: { number: 12, surfaces: RightSideSurfaces },
  13: { number: 13, surfaces: RightSideSurfaces },
  14: { number: 14, surfaces: RightSideSurfaces },
  15: { number: 15, surfaces: RightSideSurfaces },
  16: { number: 16, surfaces: RightSideSurfaces },
  17: { number: 17, surfaces: RightSideSurfaces },
  18: { number: 18, surfaces: RightSideSurfaces },
  19: { number: 19, surfaces: RightSideSurfaces },
  20: { number: 20, surfaces: RightSideSurfaces },
  21: { number: 21, surfaces: RightSideSurfaces },
  22: { number: 22, surfaces: RightSideSurfaces },
  23: { number: 23, surfaces: RightSideSurfaces },
  24: { number: 24, surfaces: LeftSideSurfaces },
  25: { number: 25, surfaces: LeftSideSurfaces },
  26: { number: 26, surfaces: LeftSideSurfaces },
  27: { number: 27, surfaces: LeftSideSurfaces },
  28: { number: 28, surfaces: LeftSideSurfaces },
  29: { number: 29, surfaces: LeftSideSurfaces },
  30: { number: 30, surfaces: LeftSideSurfaces },
  31: { number: 31, surfaces: LeftSideSurfaces },
  32: { number: 32, surfaces: LeftSideSurfaces }
}

export const AutochartTabs = {
  Scan: 'scan',
  Table: 'table'
}
