import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'

export default () => {
  const dispatch = useDispatch()
  const doctorId = useSelector(state => state.profileReducer.doctor.id)
  const { isModeOn, selectedPatients, allSelected, excluded, isPersonalMessage, localMessages } = useSelector(
    state => state.chatReducer.broadcast
  )

  const showBroadcastState = useMemo(
    () => isModeOn && (selectedPatients.length || allSelected),
    [selectedPatients, isModeOn, allSelected]
  )

  const trackBroadcastEvent = useCallback(
    (eventType, extraParams = {}) => {
      trackEvent(eventType, {
        doctorId,
        method: allSelected ? 'filter' : 'ids',
        ...(allSelected
          ? {
              excludedPatients: excluded.length
            }
          : {
              selectedPatients: selectedPatients.length
            }),
        type: isPersonalMessage ? 'personal' : 'broadcast',
        ...extraParams
      })
    },
    [allSelected, doctorId, excluded.length, selectedPatients.length, isPersonalMessage]
  )

  const closeBroadcastMode = useCallback(
    reason => {
      if (isModeOn) {
        dispatch(Actions.toggleBroadcastMode(false))
        trackBroadcastEvent('Broadcast: Cancel flow', {
          reason
        })
      }
    },
    [dispatch, isModeOn, trackBroadcastEvent]
  )

  const onClickSelectAll = useCallback(() => {
    dispatch(Actions.toggleBroadcastSelectAll(!allSelected))
    if (allSelected) {
      trackBroadcastEvent('Broadcast: Deselected all patients')
    } else {
      trackBroadcastEvent('Broadcast: Selected all patients')
    }
  }, [allSelected, dispatch, trackBroadcastEvent])

  const togglePersonalMessage = useCallback(
    value => {
      dispatch(Actions.toggleBroadcastPersonalMessage(value))
      if (value) {
        trackBroadcastEvent('Broadcast: Selected Personal Message', {
          type: 'personal'
        })
      } else {
        trackBroadcastEvent('Broadcast: Deselected Personal Message', {
          type: 'broadcast'
        })
      }
    },
    [dispatch, trackBroadcastEvent]
  )

  return {
    onClickSelectAll,
    closeBroadcastMode,
    showBroadcastState,
    trackBroadcastEvent,
    isModeOn,
    isPersonalMessage,
    togglePersonalMessage,
    selectedPatients,
    allSelected,
    localMessages
  }
}
