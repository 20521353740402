import React from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const BrightCheckbox = ({ label, checked, onChange, color, id, className = '', disabled = false }) => {
  const classes = makeStyles(theme => ({
    root: {
      color: color || theme.palette.primary.main
    },
    checked: {
      color: `${color || theme.palette.primary.main} !important`
    },
    label: {
      color: color || theme.palette.primary.main
    }
  }))({ color })

  return (
    <FormControlLabel
      className={className}
      classes={{ label: classes.label }}
      control={
        <Checkbox
          disabled={disabled}
          classes={{ root: classes.root, checked: classes.checked }}
          checked={checked}
          onChange={onChange}
          value={label}
          id={id}
        />
      }
      label={label}
    />
  )
}

export default BrightCheckbox
