import { API, graphqlOperation } from 'aws-amplify'
import { ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, mergeMap, pluck, switchMap, withLatestFrom, filter, tap } from 'rxjs/operators'
import Actions from 'actions'
import { grinUserByUsernameTypeByADoctor } from '../graphql/queries'
import i18n from '../resources/locales/i18n'
import { trackEvent } from 'utils/analyticsUtils'
import {
  automationRulesByDoctorId,
  createAutomationRule,
  createMessageTemplate,
  createPracticeGuidelines,
  createSavedView,
  deleteMessageTemplate,
  deleteSavedView,
  getAutomationRule,
  getSavedViewsByDoctorId,
  getTemplatesByTypeAndByDoctorId,
  practiceGuidelinesByDoctorIdSorted,
  updateAutomationRule
} from 'graphql/customQueries'
import { generateMediaPath, getNewMediaBucket, mediaTypes, putObject } from 'utils/mediaUtils'
import { v4 } from 'uuid'
import config from 'utils/awsUtils'
import { TEMPLATES_TYPES } from 'consts/templatesConsts'
import { filterCustomTemplates } from 'utils/templatesUtils'
import { logInfo } from 'utils/logUtils'
import { filterCustomSavedViews } from 'utils/savedViewsUtils'
import { SAVED_VIEWS_TYPES } from 'consts/savedViewsConsts'

export const createServiceAccountEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_SERVICE_ACCOUNT_REQUESTED),
    pluck('payload'),
    tap(({ email }) => trackEvent('Service account - create requested', { email })),
    switchMap(({ email, password }) =>
      from(API.post('grinServerlessApi', `/accounts/v2/serviceAccount`, { body: { email, password } })).pipe(
        mergeMap(result => {
          trackEvent('Service account - create successfully', { email })
          return of(Actions.createServiceAccountReceived({ ...result.patient, email }))
        }),
        catchError(error => {
          trackEvent('Service account - create failed', { email })
          return of(Actions.createServiceAccountFailed({ error: error?.response?.data }))
        })
      )
    )
  )

export const onServiceAccountUpdatedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload
    })),
    filter(({ notification }) => notification.type === 'serviceAccountUpdated'),
    map(() => Actions.fetchServiceAccount())
  )

export const serviceAccountEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_SERVICE_ACCOUNT_REQUESTED, Actions.DOCTOR_DETAILS_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => {
      const { doctor } = state?.profileReducer
      const { accountOwner } = state?.practiceReducer
      return {
        accountOwnerUsername: accountOwner?.username ?? doctor?.username
      }
    }),
    switchMap(({ accountOwnerUsername }) =>
      from(
        API.graphql(
          graphqlOperation(grinUserByUsernameTypeByADoctor, {
            a_doctor: { eq: accountOwnerUsername },
            type: 'serviceAccount'
          })
        )
      ).pipe(
        map(res => res.data.grinUserByUsernameTypeByADoctor?.items?.[0]),
        mergeMap(patient => of(Actions.fetchServiceAccountReceived(patient))),
        catchError(error => of(Actions.fetchServiceAccountFailed(error)))
      )
    )
  )

export const createServiceFailedAccountEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_SERVICE_ACCOUNT_FAILED),
    pluck('payload'),
    map(({ error }) =>
      Actions.showSnackbar({
        type: 'error',
        text: error?.message || i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const setServiceAccountPasswordEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SERVICE_ACCOUNT_SET_PASSWORD_REQUESTED),
    withLatestFrom(state$),
    map(([action, state]) => {
      const { serviceAccount } = state?.practiceReducer
      return {
        patientId: serviceAccount.user.patient.id,
        password: action.payload.password
      }
    }),
    switchMap(({ patientId, password }) =>
      from(
        API.put('grinServerlessApi', `/accounts/v2/serviceAccount/password`, { body: { patientId, password } })
      ).pipe(
        mergeMap(() => of(Actions.serviceAccountSetPaswordReceived())),
        catchError(error => of(Actions.serviceAccountSetPaswordFailed({ error: error?.response?.data })))
      )
    )
  )

export const setServiceAccountPasswordFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SERVICE_ACCOUNT_SET_PASSWORD_FAILED),
    pluck('payload'),
    map(({ error }) =>
      Actions.showSnackbar({
        type: 'error',
        text: error?.message || i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const setServiceAccountPasswordReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SERVICE_ACCOUNT_SET_PASSWORD_RECEIVED),
    mergeMap(() =>
      of(
        Actions.showSnackbar({
          type: 'success',
          text: i18n.t('messages.users.serviceAccountPasswordChanged')
        })
      )
    )
  )

export const deactivateServiceAccountEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DEACTIVATE_SERVICE_ACCOUNT_REQUESTED),
    withLatestFrom(state$),
    map(([action, state]) => {
      const { serviceAccount } = state?.practiceReducer
      return {
        patientId: serviceAccount.user.patient.id
      }
    }),
    switchMap(({ patientId, password }) =>
      from(
        API.put('grinServerlessApi', `/accounts/v2/serviceAccount/active`, { body: { patientId, isActive: false } })
      ).pipe(
        mergeMap(() => of(Actions.deactivateServiceAccountReceived())),
        catchError(error => of(Actions.deactivateServiceAccountFailed({ error: error?.response?.data })))
      )
    )
  )

export const deactivateServiceAccountFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DEACTIVATE_SERVICE_ACCOUNT_FAILED),
    pluck('payload'),
    map(({ error }) =>
      Actions.showSnackbar({
        type: 'error',
        text: error?.message || i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const deactivateServiceAccountReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DEACTIVATE_SERVICE_ACCOUNT_RECEIVED),
    mergeMap(() =>
      of(
        Actions.showSnackbar({
          type: 'success',
          text: i18n.t('messages.users.serviceAccountDeactivated')
        })
      )
    )
  )

export const activateServiceAccountEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.ACTIVATE_SERVICE_ACCOUNT_REQUESTED),
    withLatestFrom(state$),
    map(([action, state]) => {
      const { serviceAccount } = state?.practiceReducer
      return {
        patientId: serviceAccount.user.patient.id
      }
    }),
    switchMap(({ patientId, password }) =>
      from(
        API.put('grinServerlessApi', `/accounts/v2/serviceAccount/active`, { body: { patientId, isActive: true } })
      ).pipe(
        mergeMap(() => of(Actions.activateServiceAccountReceived())),
        catchError(error => of(Actions.activateServiceAccountFailed({ error: error?.response?.data })))
      )
    )
  )

export const activateServiceAccountFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.ACTIVATE_SERVICE_ACCOUNT_FAILED),
    pluck('payload'),
    map(({ error }) =>
      Actions.showSnackbar({
        type: 'error',
        text: error?.message || i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const activateServiceAccountReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.ACTIVATE_SERVICE_ACCOUNT_RECEIVED),
    mergeMap(() =>
      of(
        Actions.showSnackbar({
          type: 'success',
          text: i18n.t('messages.users.serviceAccountActivated')
        })
      )
    )
  )

export const fetchDoctorAutomationRulesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_DOCTOR_AUTOMATION_RULES),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      from(
        API.graphql(
          graphqlOperation(automationRulesByDoctorId, {
            doctorId:
              action.payload?.doctorId || state.profileReducer.doctor.accountOwnerId || state.profileReducer.doctor.id
          })
        )
      ).pipe(
        map(res => res.data.automationRulesByDoctorId?.items.filter(rule => !rule.isDeleted && !rule._deleted)),
        mergeMap(rules => of(Actions.fetchDoctorAutomationRulesReceived(rules))),
        catchError(error => of(Actions.fetchDoctorAutomationRulesFailed(error)))
      )
    )
  )

export const automationRulesFailedEpic = action$ =>
  action$.pipe(
    ofType(
      Actions.FETCH_DOCTOR_AUTOMATION_RULES_FAILED,
      Actions.TOGGLE_AUTOMATION_RULE_FAILED,
      Actions.UPDATE_AUTOMATION_RULE_FAILED,
      Actions.CREATE_AUTOMATION_RULE_FAILED
    ),
    pluck('payload'),
    map(({ error }) =>
      Actions.showSnackbar({
        type: 'error',
        text: error?.message || i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const automationRulesSuccessEpic = action$ =>
  action$.pipe(
    ofType(
      Actions.UPDATE_AUTOMATION_RULE_RECEIVED,
      Actions.CREATE_AUTOMATION_RULE_RECEIVED,
      Actions.DELETE_AUTOMATION_RULE_RECEIVED
    ),
    map(action =>
      Actions.showSnackbar({
        type: 'success',
        text: action?.payload?.message
      })
    )
  )

export const toggleAutomationRuleEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.TOGGLE_AUTOMATION_RULE),
    pluck('payload'),
    switchMap(({ id, isEnabled }) =>
      from(
        API.graphql(
          graphqlOperation(getAutomationRule, {
            id
          })
        )
      ).pipe(
        map(res => res.data?.getAutomationRule),
        catchError(error =>
          of(
            Actions.toggleAutomationRuleFailed({
              id,
              error,
              message: i18n.t('pages.accountSettings.automations.error')
            })
          )
        ),
        switchMap(({ _version }) =>
          from(
            API.graphql(
              graphqlOperation(updateAutomationRule, {
                input: {
                  id,
                  _version,
                  isEnabled
                }
              })
            )
          ).pipe(
            map(res => res.data?.updateAutomationRule),
            mergeMap(rule => of(Actions.toggleAutomationRuleReceived(rule))),
            catchError(error =>
              of(
                Actions.toggleAutomationRuleFailed({
                  id,
                  error,
                  message: i18n.t('pages.accountSettings.automations.toggleError')
                })
              )
            )
          )
        )
      )
    )
  )

export const deleteAutomationRuleEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DELETE_AUTOMATION_RULE),
    pluck('payload'),
    switchMap(({ id }) =>
      from(
        API.graphql(
          graphqlOperation(getAutomationRule, {
            id
          })
        )
      ).pipe(
        map(res => res.data?.getAutomationRule),
        catchError(error =>
          of(
            Actions.deleteAutomationRuleFailed({
              id,
              ...error,
              message: i18n.t('pages.accountSettings.automations.deletionError')
            })
          )
        ),
        switchMap(({ _version }) =>
          from(
            API.graphql(
              graphqlOperation(updateAutomationRule, {
                input: {
                  id,
                  _version,
                  isDeleted: true
                }
              })
            )
          ).pipe(
            mergeMap(() =>
              of(
                Actions.deleteAutomationRuleReceived({
                  message: i18n.t('pages.accountSettings.automations.deleteSuccessMessage')
                })
              )
            ),
            catchError(error =>
              of(
                Actions.deleteAutomationRuleFailed({
                  id,
                  ...error,
                  message: i18n.t('pages.accountSettings.automations.deletionError')
                })
              )
            )
          )
        )
      )
    )
  )

export const updateAutomationRuleEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPDATE_AUTOMATION_RULE),
    pluck('payload'),
    switchMap(({ id, name, triggerType, conditions, actions }) =>
      from(
        API.graphql(
          graphqlOperation(getAutomationRule, {
            id
          })
        )
      ).pipe(
        map(res => res.data?.getAutomationRule),
        catchError(error =>
          of(
            Actions.updateAutomationRuleFailed({
              id,
              error,
              message: i18n.t('pages.accountSettings.automations.updateError')
            })
          )
        ),
        switchMap(({ _version }) =>
          from(
            API.graphql(
              graphqlOperation(updateAutomationRule, {
                input: {
                  id,
                  _version,
                  name,
                  triggerType,
                  actions,
                  conditions,
                  isEnabled: true
                }
              })
            )
          ).pipe(
            map(res => res.data?.updateAutomationRule),
            mergeMap(rule =>
              concat(
                of(
                  Actions.updateAutomationRuleReceived({
                    rule,
                    message: i18n.t('pages.accountSettings.automations.updateSuccessMessage')
                  })
                ),
                of(
                  Actions.toggleRuleEditorModal({
                    isOpen: false
                  })
                )
              )
            ),
            catchError(error =>
              of(
                Actions.updateAutomationRuleFailed({
                  id,
                  error,
                  message: i18n.t('pages.accountSettings.automations.updateError')
                })
              )
            )
          )
        )
      )
    )
  )

export const createAutomationRuleEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_AUTOMATION_RULE),
    withLatestFrom(state$),
    map(([action, state]) => ({
      ...action.payload,
      a_doctor: state.profileReducer.doctor.accountOwner?.username || state.profileReducer.doctor.username
    })),
    switchMap(payload =>
      from(
        API.graphql(
          graphqlOperation(createAutomationRule, {
            input: {
              ...payload,
              isEnabled: true
            }
          })
        )
      ).pipe(
        map(res => res.data?.createAutomationRule),
        mergeMap(rule =>
          concat(
            of(
              Actions.toggleRuleEditorModal({
                isOpen: false
              })
            ),
            of(
              Actions.createAutomationRuleReceived({
                rule,
                message: i18n.t('pages.accountSettings.automations.createSuccessMessage')
              })
            )
          )
        ),
        catchError(error =>
          of(
            Actions.createAutomationRuleFailed({
              error,
              message: i18n.t('pages.accountSettings.automations.createError')
            })
          )
        )
      )
    )
  )

export const fetchSavedFilesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_SAVED_FILES),
    withLatestFrom(state$),
    map(([action, state]) => ({
      doctorId: state.profileReducer?.doctor?.id,
      accountOwnerId: state.practiceReducer.accountOwner.id
    })),
    switchMap(({ doctorId, accountOwnerId }) =>
      from(
        API.graphql(
          graphqlOperation(getTemplatesByTypeAndByDoctorId, {
            type: TEMPLATES_TYPES.FILE,
            doctorId: { eq: accountOwnerId },
            limit: 1000
          })
        )
      ).pipe(
        map(res => res?.data?.templatesByTypeAndByDoctorId?.items),
        map(savedFiles => filterCustomTemplates(savedFiles, doctorId)),
        mergeMap(savedFiles => of(Actions.fetchSavedFilesReceived(savedFiles))),
        catchError(err => of(Actions.fetchSavedFilesFailed(err)))
      )
    )
  )

export const fetchSavedViewsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_SAVED_VIEWS),
    withLatestFrom(state$),
    map(([action, state]) => ({
      doctorId: state.profileReducer?.doctor?.id,
      accountOwnerId: state.practiceReducer.accountOwner.id
    })),
    switchMap(({ doctorId, accountOwnerId }) =>
      from(
        API.graphql(
          graphqlOperation(getSavedViewsByDoctorId, {
            accountOwnerId,
            limit: 1000
          })
        )
      ).pipe(
        map(res => res?.data?.savedViewsByDoctorId?.items),
        map(savedViews => filterCustomSavedViews({ savedViews, doctorId })),
        mergeMap(savedViews => of(Actions.fetchSavedViewsReceived(savedViews || []))),
        catchError(err => of(Actions.fetchSavedViewsFailed(err)))
      )
    )
  )

export const createNewSavedViewEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_NEW_SAVED_VIEW),
    withLatestFrom(state$),
    map(([action, state]) => ({
      name: action.payload.name,
      filters: action.payload.filters,
      doctorId: state.profileReducer?.doctor?.id,
      accountOwnerId: state.practiceReducer.accountOwner.id
    })),
    switchMap(({ name, filters, doctorId, accountOwnerId }) =>
      from(
        API.graphql(
          graphqlOperation(createSavedView, {
            input: {
              name,
              isPrivate: true,
              type: SAVED_VIEWS_TYPES.custom,
              filters: JSON.stringify(filters),
              accountOwnerId,
              creatorDoctorId: doctorId
            }
          })
        )
      ).pipe(
        map(res => res?.data?.createSavedView),
        mergeMap(newSavedView => of(Actions.createNewSavedViewReceived(newSavedView))),
        catchError(err => of(Actions.createNewSavedViewFailed(err)))
      )
    )
  )

export const createNewSavedViewFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_NEW_SAVED_VIEW_FAILED, Actions.DELETE_SAVED_FILE_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const createNewSavedViewReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_NEW_SAVED_VIEW_RECEIVED),
    map(() =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.savedViews.newViewCreated')
      })
    )
  )

export const deleteSavedViewEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DELETE_SAVED_VIEW),
    pluck('payload'),
    switchMap(({ id, _version }) =>
      from(
        API.graphql(
          graphqlOperation(deleteSavedView, {
            input: {
              id,
              _version
            }
          })
        )
      ).pipe(
        mergeMap(() => of(Actions.deleteSavedViewReceived())),
        catchError(err => of(Actions.deleteSavedViewFailed(err)))
      )
    )
  )

export const addSavedFileEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.ADD_SAVED_FILE),
    withLatestFrom(state$),
    map(([{ payload }, state]) => ({
      fileData: payload.file,
      a_doctor: state.practiceReducer.accountOwner.username,
      doctorId: state.practiceReducer.accountOwner.id,
      creatorDoctorId: state.profileReducer.doctor.id,
      creatorDoctorUsername: state.profileReducer.doctor.username,
      savedFileId: v4()
    })),
    switchMap(({ fileData, savedFileId, a_doctor, doctorId, creatorDoctorId, creatorDoctorUsername }) =>
      from(
        putObject({
          path: generateMediaPath({
            type: mediaTypes.savedFile,
            objectIdToAuthorize: savedFileId,
            keySuffix: fileData.key
          }),
          data: fileData.data,
          contentType: fileData.file.type
        })
      ).pipe(
        catchError(error => of(Actions.addSavedFileFailed(error))),
        mergeMap(() =>
          from(
            API.graphql(
              graphqlOperation(createMessageTemplate, {
                input: {
                  id: savedFileId,
                  title: fileData.key,
                  type: TEMPLATES_TYPES.FILE,
                  attachments: [
                    {
                      key: `private/savedFiles/${savedFileId}/${fileData.key}`,
                      bucket: getNewMediaBucket(),
                      region: config.aws_user_files_s3_bucket_region
                    }
                  ],
                  a_doctor,
                  doctorId,
                  creatorDoctorId,
                  creatorDoctorUsername,
                  isPrivate: false
                }
              })
            )
          ).pipe(
            map(res => res.data.createMessageTemplate),
            mergeMap(newSavedFile => of(Actions.addSavedFileReceived(newSavedFile))),
            catchError(error => of(Actions.addSavedFileFailed(error)))
          )
        ),
        catchError(error => of(Actions.addSavedFileFailed(error)))
      )
    ),
    catchError(error => of(Actions.addSavedFileFailed(error)))
  )

export const deleteSavedFileEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DELETE_SAVED_FILE),
    pluck('payload'),
    switchMap(({ id, _version }) =>
      from(
        API.graphql(
          graphqlOperation(deleteMessageTemplate, {
            input: {
              id,
              _version
            }
          })
        )
      ).pipe(
        mergeMap(() => of(Actions.deleteSavedFileReceived())),
        catchError(error => of(Actions.deleteSavedFileFailed(error)))
      )
    )
  )

export const savedFileErrorsEpic = action$ =>
  action$.pipe(
    ofType(Actions.ADD_SAVED_FILE_FAILED, Actions.DELETE_SAVED_FILE_FAILED, Actions.SEND_SAVED_FILE_FAILED),
    pluck('payload'),
    map(({ error }) =>
      Actions.showSnackbar({
        type: 'error',
        text: error?.message || i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const fetchPracticeGuidelinesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_PRACTICE_GUIDELINES),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      from(
        API.graphql(
          graphqlOperation(practiceGuidelinesByDoctorIdSorted, {
            doctorId:
              state.multiPracticeReducer.context.accountOwnerId ||
              state.profileReducer.doctor.accountOwnerId ||
              state.profileReducer.doctor.id,
            sortDirection: 'DESC',
            limit: 1
          })
        )
      ).pipe(
        map(res => res.data.practiceGuidelinesByDoctorIdSorted?.items[0]), // Use the latest guidelines
        mergeMap(practiceGuidelines => of(Actions.fetchPracticeGuidelinesReceived(practiceGuidelines))),
        catchError(error => of(Actions.fetchPracticeGuidelinesFailed(error)))
      )
    )
  )

export const fetchHiPracticeGuidelinesFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_PRACTICE_GUIDELINES_FAILED, Actions.SAVE_PRACTICE_GUIDELINES_FAILED),
    pluck('payload'),
    map(({ error }) =>
      Actions.showSnackbar({
        type: 'error',
        text: error?.message || i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const savePracticeGuidelinesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SAVE_PRACTICE_GUIDELINES),
    withLatestFrom(state$),
    map(([action, state]) => ({
      updatedCategories: action.payload,
      creatorDoctorId: state.profileReducer.doctor.id,
      accountOwnerId: state.practiceReducer.accountOwner.id,
      doctorUsername: state.practiceReducer.accountOwner.username
    })),
    switchMap(({ updatedCategories, creatorDoctorId, accountOwnerId, doctorUsername }) =>
      from(
        API.graphql(
          graphqlOperation(createPracticeGuidelines, {
            input: {
              doctorId: accountOwnerId,
              a_doctor: doctorUsername,
              creatorDoctorId,
              categories: JSON.stringify(updatedCategories)
            }
          })
        )
      ).pipe(
        mergeMap(response => of(Actions.savePracticeGuidelinesReceived(response.data.createPracticeGuidelines))),
        catchError(error => of(Actions.savePracticeGuidelinesFailed(error)))
      )
    )
  )

export const savePracticeGuidelinesReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.SAVE_PRACTICE_GUIDELINES_RECEIVED),
    map(() =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.practiceGuidelinesSaved')
      })
    )
  )

export const resolveHiNeedsAttention = action$ =>
  action$.pipe(
    ofType(Actions.RESOLVE_HI_NEEDS_ATTENTION),
    pluck('payload'),
    switchMap(({ note, patientId }) =>
      from(
        API.post('grinServerlessApi', '/treatments/v2/gi/notifyPractice/resolve', {
          body: {
            patientId,
            note
          }
        })
      ).pipe(
        mergeMap(() =>
          concat(
            of(Actions.resolveHiNeedsAttentionReceived()),
            of(
              Actions.showSnackbar({
                text: i18n.t('messages.resolveNeedsAttention.submittedSuccessfully'),
                type: 'success'
              })
            )
          )
        ),
        catchError(
          error => concat(of(Actions.resolveHiNeedsAttentionFailed(error))),
          of(
            Actions.showSnackbar({
              text: i18n.t('messages.resolveNeedsAttention.failedToResolve'),
              type: 'error'
            })
          )
        )
      )
    )
  )

export const fetchAggregatedPracticeCustomTagsEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS),
    map(() => ({
      startTime: new Date().getTime()
    })),
    switchMap(({ startTime }) =>
      from(API.get('grinServerlessApi', '/tags/v2/customTags/distinct')).pipe(
        tap(response =>
          logInfo('FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS: completed', {
            took: new Date().getTime() - startTime,
            tagsCount: response.tags.length
          })
        ),
        mergeMap(response => of(Actions.fetchAggregatedPracticeCustomTagsReceived(response))),
        catchError(error => of(Actions.fetchAggregatedPracticeCustomTagsFailed(error)))
      )
    )
  )
