import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Collapse, Grid } from '@material-ui/core'
import GrinTabs from 'components/common/tabs/GrinTabs'
import { trackEvent } from 'utils/analyticsUtils'
import GrinTab from 'components/common/tabs/GrinTab'
import { useTranslation } from 'react-i18next'
import { ChevronDown, ChevronUp } from 'components/common/icons'
import PracticeGuidelines from './PracticeGuidelines/PracticeGuidelines'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { scrollBar } from 'styles/theme'
import PatientBrief from './PatientBrief/PatientBrief'
import PatientNotes from './PatientNotes'

const useStyles = makeStyles(theme => ({
  root: {
    width: 500,
    backgroundColor: 'white',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    boxShadow: '0px 4px 14px 0px #00000040',
    border: 1,
    padding: 5,
    zIndex: 99999,
    position: 'absolute',
    bottom: 0,
    left: 10
  },
  header: {
    padding: 5,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  tab: {
    whiteSpace: 'pre',
    width: 190
  },
  notesTab: {
    whiteSpace: 'pre',
    width: 110
  },
  tabContent: {
    height: '55vh',
    overflowY: 'auto',
    ...scrollBar
  },
  collapse: {
    width: '100%',
    overflowY: 'hidden'
  }
}))

const PatientGuidelinesViewer = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const practiceGuidelines = useSelector(state => state.multiPracticeReducer.practiceGuidelines)

  const [isOpen, setIsOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)

  const hasPracticeGuidelines = useMemo(() => !isEmpty(practiceGuidelines), [practiceGuidelines])
  const tabs = useMemo(
    () => [
      {
        index: 0,
        id: 'patient-brief',
        title: t('dialogs.hiPatientGuidelinesViewer.tabs.patientBrief.title'),
        Component: PatientBrief,
        tabClass: classes.tab
      },
      {
        index: 1,
        id: 'practice-guidelines',
        title: t('dialogs.hiPatientGuidelinesViewer.tabs.practiceGuidelines.title'),
        Component: PracticeGuidelines,
        tabClass: classes.tab
      },
      {
        index: 2,
        id: 'patient-notes',
        title: t('dialogs.hiPatientGuidelinesViewer.tabs.notes.title'),
        Component: PatientNotes,
        tabClass: classes.notesTab
      }
    ],
    [classes, t]
  )

  const handleTabSelection = useCallback(
    (event, value) => {
      const selectedTab = tabs.find(tab => tab.index === value)
      trackEvent('HI patient guidelines viewer - Tab selected', {
        tab: selectedTab.label
      })
      setSelectedTab(value)

      if (!isOpen) {
        setIsOpen(true)
      }
    },
    [isOpen, tabs]
  )

  const handleOpen = useCallback(() => {
    trackEvent(`HI patient guidelines viewer - Viewer ${!isOpen ? 'opened' : 'closed'}`)
    setIsOpen(!isOpen)
  }, [isOpen])

  return (
    hasPracticeGuidelines && (
      <Grid container direction="column" alignItems="center" className={classes.root}>
        <Grid item xs={12} onClick={handleOpen} className={classes.header}>
          {isOpen ? <ChevronDown /> : <ChevronUp />}
        </Grid>
        <Grid item>
          <GrinTabs value={selectedTab} onChange={handleTabSelection}>
            {tabs.map((tab, index) => (
              <GrinTab key={tab.id} label={tab.title} value={tab.index} marginRight="0px" className={tab.tabClass} />
            ))}
          </GrinTabs>
        </Grid>
        <Collapse in={isOpen} className={classes.collapse}>
          <Grid item className={classes.tabContent}>
            {tabs
              .filter(tab => tab.index === selectedTab)
              .map(Tab => (
                <Tab.Component key={Tab.index} />
              ))}
          </Grid>
        </Collapse>
      </Grid>
    )
  )
}

export default PatientGuidelinesViewer
