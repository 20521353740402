import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { trackEvent } from 'utils/analyticsUtils'
import PreventiveLegendItem from './PreventiveLegendItem'

const PreventiveLegend = ({
  hasMultiLayers,
  layers,
  toggleLayer = () => {},
  legendItemXs = 12,
  spacing = 2,
  textAlign,
  disabled = false,
  fontSize
}) => {
  const displayLayerClicked = useCallback(
    (type, label, visible) => {
      trackEvent('Scan Summary - Preventive layers - toggled layer', {
        type,
        display: !visible
      })
      toggleLayer(type)
    },
    [toggleLayer]
  )

  return (
    <Grid container spacing={spacing}>
      {layers.map(({ label, color, visible, type }, index) => (
        <Grid item key={`preventive-legend-${label}`} xs={legendItemXs} style={{ paddingLeft: 0 }}>
          <PreventiveLegendItem
            disabled={disabled}
            color={color}
            label={label}
            layerType={type}
            visible={visible}
            toggleable={hasMultiLayers}
            onClick={displayLayerClicked}
            textAlign={textAlign}
            fontSize={fontSize}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default PreventiveLegend
