import React, { useCallback, useState } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import DazzedParagraph16 from '../../../common/text/DazzedParagraph16'
import AppointmentItem from './AppointmentItem'
import { useDispatch, useSelector } from 'react-redux'
import actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles({
  title: {
    marginLeft: 10,
    color: 'var(--text-color-11)'
  }
})

const PmsAppointmentSection = ({ icon, title, appointments, isPastAppointments = false }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [editAppointment, setEditAppointment] = useState('')

  const { pmsType } = useSelector(state => state.pmsReducer)

  const handleDeleteAppointment = useCallback(
    id => {
      trackEvent('PMS appointments - delete appointment clicked', {
        id,
        pmsType
      })
      dispatch(actions.pmsDeleteAppointment(id))
    },
    [dispatch, pmsType]
  )

  return (
    <Grid container alignItems="center">
      <Grid item>{icon}</Grid>
      <Grid item>
        <DazzedParagraph16 strong className={classes.title}>
          {title}
        </DazzedParagraph16>
      </Grid>
      {appointments.map((appointment, index) => (
        <AppointmentItem
          key={`${appointment.id}-${index}`}
          appointment={appointment}
          disabled={isPastAppointments}
          editMode={appointment.id === editAppointment}
          setEditAppointment={setEditAppointment}
          handleDeleteAppointment={() => handleDeleteAppointment(appointment.id)}
          considerTimezoneHours={false}
          isLastItem={index === appointments.length - 1}
        />
      ))}
    </Grid>
  )
}

export default PmsAppointmentSection
