import React, { useState, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import StatusPickerTrigger from './StatusPickerTrigger'
import GrinMenu from '../../menu/GrinMenu'
import StatusPickerDropdownBody from './StatusPickerDropdownBody'
import { CircularProgress } from '@material-ui/core'
import ErrorIcon from '../../icons/Error'
import { useSelector } from 'react-redux'
import { isEmpty, uniqBy } from 'lodash'
import { useDispatch } from 'react-redux'
import Actions from '../../../../actions'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from '../../text/DazzedParagraph12'
import StatusInfoModal from '../../../Patients/Statuses/StatusInfoModal'
import { selectStatuses } from 'selectors/statusesSelector'

const useStyles = makeStyles(theme => ({
  menu: {
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    width: '193px'
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    flexDirection: 'column'
  },
  errorText: {
    textAlign: 'center'
  }
}))

const StatusPicker = ({
  id = '',
  statusesToDisplay = null,
  selectedStatus,
  onSelect,
  variant = 'rounded',
  program,
  className,
  disabled,
  showOnlyAssignableStatuses = true
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { isLoading, loadingFailed, data } = useSelector(selectStatuses)
  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false)
  const [isStatusInfoModalOpen, setIsStatusInfoModalOpen] = useState(false)

  const allStatusOptions = useMemo(
    () =>
      uniqBy(
        Object.values(data).reduce((prevStatus, allStatus) => [...allStatus, ...prevStatus], []),
        'type'
      ),
    [data]
  )

  const statusOptions = useMemo(
    () =>
      !showOnlyAssignableStatuses
        ? allStatusOptions
        : data[program]?.filter(status =>
            selectedStatus?.type !== 'transferred'
              ? status.type !== 'transferred' && (!statusesToDisplay || statusesToDisplay?.includes(status.type))
              : true
          ),
    [allStatusOptions, data, program, selectedStatus?.type, showOnlyAssignableStatuses, statusesToDisplay]
  )
  const isDisabled = useMemo(() => {
    if (!showOnlyAssignableStatuses) {
      return false
    }

    return (
      disabled ||
      selectedStatus?.type === 'invited' ||
      selectedStatus?.type === 'transferred' ||
      selectedStatus?.type === 'records-only'
    )
  }, [disabled, selectedStatus?.type, showOnlyAssignableStatuses])
  const handleDropdownOpen = useCallback(() => {
    if (isDisabled) {
      return
    }

    setIsOptionsMenuOpen(true)

    if (isEmpty(data) && !isLoading) {
      dispatch(Actions.fetchStatuses())
    }
  }, [isDisabled, data, isLoading, dispatch])

  const handleDropdownClose = useCallback(() => setIsOptionsMenuOpen(false), [])

  const handleStatusSelection = useCallback(
    status => {
      onSelect(status)
      setIsOptionsMenuOpen(false)
    },
    [onSelect]
  )

  return (
    <div>
      <GrinMenu
        id={id}
        className={classes.menu}
        onOpen={handleDropdownOpen}
        onClose={handleDropdownClose}
        open={isOptionsMenuOpen}
        transformOrigin={{ vertical: variant === 'rectangle' ? -45 : -35, horizontal: -15 }}
        triggerComponent={
          <StatusPickerTrigger
            className={className}
            variant={variant}
            selectedStatus={selectedStatus}
            isOpen={isOptionsMenuOpen}
            disabled={isDisabled}
          />
        }
      >
        {isLoading ? (
          <div className={classes.loaderContainer}>
            <CircularProgress size={12} />
          </div>
        ) : loadingFailed ? (
          <div className={classes.loaderContainer}>
            <div>
              <ErrorIcon color="#EB5757" height="18" width="18" />
            </div>
            <DazzedParagraph12 className={classes.errorText}>{t('statuses.menu.loadingFailed')}</DazzedParagraph12>
          </div>
        ) : (
          <StatusPickerDropdownBody
            onInfoClick={() => setIsStatusInfoModalOpen(true)}
            statusOptions={statusOptions}
            onSelect={handleStatusSelection}
            selectedStatus={selectedStatus}
            showOnlyAssignableStatuses={showOnlyAssignableStatuses}
          />
        )}
      </GrinMenu>
      <StatusInfoModal
        isOpen={isStatusInfoModalOpen}
        statuses={statusOptions}
        onClose={() => setIsStatusInfoModalOpen(false)}
        onSelect={handleStatusSelection}
        selectedStatus={selectedStatus}
      />
    </div>
  )
}

export default StatusPicker
