import { IconButton, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useMemo, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCloudFrontUrl } from '../../../utils/storageUtils'
import useInViewport from '../../../hooks/useInViewport'
import DownChevronCircle from '../../common/icons/DownChevronCircle'
import DazzedHeading24 from '../../common/text/DazzedHeading24'
import useRcDetails from '../useRcDetails'
import headerScanGif from '../../../resources/RC_header_scan.gif'
import '../animations.css'

const useStyles = makeStyles(
  theme => ({
    root: {},
    header: {
      minHeight: ({ isMobile }) => (isMobile ? 'auto' : '585px'),
      width: '100%',
      display: 'flex',
      backgroundColor: ({ colorSettings }) => colorSettings.headerBackgroundColor || 'var(--bg-color-29)',
      flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
      borderBottomRightRadius: '200px',
      overflow: 'hidden'
    },
    headerTextContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: ({ isMobile }) => (isMobile ? 60 : 20)
    },
    headerText: {
      fontFamily: 'Ogg',
      padding: 45,
      fontSize: ({ isMobile }) => (isMobile ? '40px' : '66px'),
      color: ({ colorSettings }) => colorSettings.headerFontColor || theme.palette.primary.main,
      fontWeight: 'normal',
      lineHeight: ({ isMobile }) => (isMobile ? '50px' : '74px')
    },
    lineSeparator: {
      background: ({ colorSettings }) => colorSettings.accentColor || colorSettings.secondary,
      minHeight: '6px',
      width: ({ isMobile }) => (isMobile ? '100%' : '450px'),
      margin: '30px 0'
    },
    detailsContainer: {
      display: 'flex',
      flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
      alignItems: ({ isMobile }) => (isMobile ? 'flex-start' : 'center'),
      width: ({ isMobile }) => (isMobile ? '100%' : '450px'),
      justifyContent: ({ isMobile }) => (isMobile ? 'center' : 'space-between')
    },
    logoContainer: {
      marginTop: 20,
      marginBottom: 20,
      width: ({ isMobile }) => (isMobile ? '100px' : '250PX'),
      height: ({ isMobile }) => (isMobile ? '100PX' : '250PX')
    },
    logoImg: {
      height: '100%',
      width: '100%',
      objectFit: 'contain'
    },
    doctorDetails: {
      paddingLeft: 30,
      color: ({ colorSettings }) => colorSettings.headerFontColor || theme.palette.primary.main
    },
    logoLink: {
      color: 'inherit!important',
      pointerEvents: ({ homepageUrl }) => (homepageUrl ? 'auto' : 'none'),
      transition: 'opacity 0.25s ease',
      '&:hover': {
        opacity: 0.9
      }
    },
    practiceLink: {
      color: ({ colorSettings }) => colorSettings.headerFontColor || theme.palette.primary.main,
      pointerEvents: ({ homepageUrl }) => (homepageUrl ? 'auto' : 'none')
    },
    headerImageContainer: {
      flex: 1
    },
    headerImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    scrollDownButtonContainer: {
      position: 'fixed',
      left: 0,
      right: 0,
      width: 'auto',
      textAlign: 'center',
      bottom: 0,
      zIndex: 999,
      animation: 'ca3_fade_move_down 2s ease-in-out infinite'
    }
  }),
  { isMobile }
)

export default () => {
  const doctorDetails = useRcDetails()
  const classes = useStyles({
    isMobile,
    colorSettings: doctorDetails.colorSettings,
    homepageUrl: doctorDetails.homepageUrl
  })
  const { t } = useTranslation()
  const ref = useRef()

  const { isInViewport } = useInViewport(ref)
  const isBodyContentVisible = useSelector(state => !state.appReducer.rcLandingPage.isContentVisible)
  const shouldShowScrollButton = useMemo(
    () => isInViewport && isBodyContentVisible,
    [isInViewport, isBodyContentVisible]
  )

  const cloudFrontUrl = getCloudFrontUrl()
  const bannerPhotoUrl = useMemo(
    () => (doctorDetails.rcLPBannerPhoto ? `${cloudFrontUrl}/rc-lp-banners/${doctorDetails.rcLPBannerPhoto}` : {}),
    [doctorDetails.rcLPBannerPhoto, cloudFrontUrl]
  )

  return (
    <div className={classes.root} ref={ref}>
      <div className={classes.header}>
        <div className={classes.headerTextContainer}>
          <div className={classes.headerText}>
            {t('pages.rcLanding.header.line1')}
            <br />
            {t('pages.rcLanding.header.line2')}
            {isMobile ? <>&nbsp;</> : <br />}
            {t('pages.rcLanding.header.line3', { doctorName: doctorDetails.name })}
          </div>
          <div className={classes.lineSeparator} />
          <div className={classes.detailsContainer}>
            {doctorDetails?.logoUrl && (
              <a
                className={classes.logoLink}
                href={doctorDetails.homepageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={classes.logoContainer}>
                  <img className={classes.logoImg} alt="Clinic logo" src={doctorDetails.logoUrl} />
                </div>
              </a>
            )}
            <DazzedHeading24 className={classes.doctorDetails}>
              {doctorDetails.name} |
              <br />
              <a
                className={classes.practiceLink}
                href={doctorDetails.homepageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {doctorDetails.clinicName}
              </a>
            </DazzedHeading24>
          </div>
        </div>
        {!isMobile && (
          <div className={classes.headerImageContainer}>
            <img
              src={!!doctorDetails.rcLPBannerPhoto ? bannerPhotoUrl : headerScanGif}
              className={classes.headerImage}
              alt="Remote Consultation"
            />
          </div>
        )}
      </div>
      {shouldShowScrollButton && (
        <div className={classes.scrollDownButtonContainer}>
          <Link href="#body">
            <IconButton>
              <DownChevronCircle color={doctorDetails.colorSettings.primary} />
            </IconButton>
          </Link>
        </div>
      )}
    </div>
  )
}
