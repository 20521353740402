const SET_PMS_TYPE = 'SET_PMS_TYPE'
const PMS_AUTHENTICATION = 'PMS_AUTHENTICATION'
const PMS_AUTHENTICATION_RECEIVED = 'PMS_AUTHENTICATION_RECEIVED'
const PMS_AUTHENTICATION_FAILED = 'PMS_AUTHENTICATION_FAILED'
const PMS_FETCH_PATIENTS = 'PMS_FETCH_PATIENTS'
const PMS_FETCH_PATIENTS_RECEIVED = 'PMS_FETCH_PATIENTS_RECEIVED'
const PMS_FETCH_PATIENTS_FAILED = 'PMS_FETCH_PATIENTS_FAILED'
const PMS_FETCH_PATIENT = 'PMS_FETCH_PATIENT'
const PMS_FETCH_PATIENT_RECEIVED = 'PMS_FETCH_PATIENT_RECEIVED'
const PMS_FETCH_PATIENT_FAILED = 'PMS_FETCH_PATIENT_FAILED'
const PMS_FETCH_AVAILABLE_TIME_SLOTS = 'PMS_FETCH_AVAILABLE_TIME_SLOTS'
const PMS_FETCH_AVAILABLE_TIME_SLOTS_RECEIVED = 'PMS_FETCH_AVAILABLE_TIME_SLOTS_RECEIVED'
const PMS_FETCH_AVAILABLE_TIME_SLOTS_FAILED = 'PMS_FETCH_AVAILABLE_TIME_SLOTS_FAILED'
const PMS_CREATE_NEW_APPOINTMENT = 'PMS_CREATE_NEW_APPOINTMENT'
const PMS_CREATE_NEW_APPOINTMENT_RECEIVED = 'PMS_CREATE_NEW_APPOINTMENT_RECEIVED'
const PMS_CREATE_NEW_APPOINTMENT_FAILED = 'PMS_CREATE_NEW_APPOINTMENT_FAILED'
const PMS_LINK_PATIENT = 'PMS_LINK_PATIENT'
const PMS_LINK_PATIENT_RECEIVED = 'PMS_LINK_PATIENT_RECEIVED'
const PMS_LINK_PATIENT_FAILED = 'PMS_LINK_PATIENT_FAILED'
const PMS_UNLINK_PATIENT = 'PMS_UNLINK_PATIENT'
const PMS_UNLINK_PATIENT_RECEIVED = 'PMS_UNLINK_PATIENT_RECEIVED'
const PMS_UNLINK_PATIENT_FAILED = 'PMS_UNLINK_PATIENT_FAILED'
const PMS_FETCH_APPOINTMENTS = 'PMS_FETCH_APPOINTMENTS'
const PMS_FETCH_APPOINTMENTS_RECEIVED = 'PMS_FETCH_APPOINTMENTS_RECEIVED'
const PMS_FETCH_APPOINTMENTS_FAILED = 'PMS_FETCH_APPOINTMENTS_FAILED'
const PMS_SET_APPOINTMENT_MODE = 'PMS_SET_APPOINTMENT_MODE'
const PMS_DELETE_APPOINTMENT = 'PMS_DELETE_APPOINTMENT'
const PMS_DELETE_APPOINTMENT_RECEIVED = 'PMS_DELETE_APPOINTMENT_RECEIVED'
const PMS_DELETE_APPOINTMENT_FAILED = 'PMS_DELETE_APPOINTMENT_FAILED'
const PMS_FETCH_STATIC_DATA = 'PMS_FETCH_STATIC_DATA'
const PMS_FETCH_STATIC_DATA_RECEIVED = 'PMS_FETCH_STATIC_DATA_RECEIVED'
const PMS_FETCH_STATIC_DATA_FAILED = 'PMS_FETCH_STATIC_DATA_FAILED'
const PMS_AUTOMATIC_LINKING_ATTEMPT = 'PMS_AUTOMATIC_LINKING_ATTEMPT'
const PMS_AUTOMATIC_LINKING_ATTEMPT_FAILED = 'PMS_AUTOMATIC_LINKING_ATTEMPT_FAILED'
const PMS_FETCH_LATEST_PATIENTS = 'PMS_FETCH_LATEST_PATIENTS'
const PMS_FETCH_LATEST_PATIENTS_RECEIVED = 'PMS_FETCH_LATEST_PATIENTS_RECEIVED'
const PMS_FETCH_LATEST_PATIENTS_FAILED = 'PMS_FETCH_LATEST_PATIENTS_FAILED'
const PMS_TOGGLE_SNACKBAR = 'PMS_TOGGLE_SNACKBAR'
const PMS_TOGGLE_INVITE_PATIENTS_MODAL = 'PMS_TOGGLE_INVITE_PATIENTS_MODAL'
const PMS_TOGGLE_REDIRECT_PATIENT_NOT_FOUND_MODAL = 'PMS_TOGGLE_REDIRECT_PATIENT_NOT_FOUND_MODAL'
const PMS_REDIRECT_FAILED = 'PMS_REDIRECT_FAILED'
const PMS_REDIRECT_SEARCH_LINKED_PATIENT = 'PMS_REDIRECT_SEARCH_LINKED_PATIENT'
const PMS_REDIRECT_SEARCH_LINKED_PATIENT_RECEIVED = 'PMS_REDIRECT_SEARCH_LINKED_PATIENT_RECEIVED'

const pmsRedirectSearchLinkedPatient = payload => ({
  type: PMS_REDIRECT_SEARCH_LINKED_PATIENT,
  payload
})

const pmsRedirectSearchLinkedPatientReceived = payload => ({
  type: PMS_REDIRECT_SEARCH_LINKED_PATIENT_RECEIVED,
  payload
})

const pmsToggleInvitePatientsModal = payload => ({
  type: PMS_TOGGLE_INVITE_PATIENTS_MODAL,
  payload
})

const pmsToggleSnackbar = payload => ({
  type: PMS_TOGGLE_SNACKBAR,
  payload
})

const pmsFetchLatestPatients = payload => ({
  type: PMS_FETCH_LATEST_PATIENTS,
  payload
})

const pmsFetchLatestPatientsReceived = payload => ({
  type: PMS_FETCH_LATEST_PATIENTS_RECEIVED,
  payload
})

const pmsFetchLatestPatientsFailed = payload => ({
  type: PMS_FETCH_LATEST_PATIENTS_FAILED,
  payload
})

const pmsAutomaticLinkingAttempt = payload => ({
  type: PMS_AUTOMATIC_LINKING_ATTEMPT,
  payload
})

const pmsAutomaticLinkingAttemptFailed = payload => ({
  type: PMS_AUTOMATIC_LINKING_ATTEMPT_FAILED,
  payload
})

const pmsDeleteAppointment = payload => ({
  type: PMS_DELETE_APPOINTMENT,
  payload
})

const pmsDeleteAppointmentReceived = payload => ({
  type: PMS_DELETE_APPOINTMENT_RECEIVED,
  payload
})

const pmsDeleteAppointmentFailed = payload => ({
  type: PMS_DELETE_APPOINTMENT_FAILED,
  payload
})

const pmsFetchStaticData = payload => ({
  type: PMS_FETCH_STATIC_DATA,
  payload
})

const pmsFetchStaticDataReceived = payload => ({
  type: PMS_FETCH_STATIC_DATA_RECEIVED,
  payload
})

const pmsFetchStaticDataFailed = payload => ({
  type: PMS_FETCH_STATIC_DATA_FAILED,
  payload
})

const pmsCreateNewAppointment = payload => ({
  type: PMS_CREATE_NEW_APPOINTMENT,
  payload
})

const pmsCreateNewAppointmentReceived = payload => ({
  type: PMS_CREATE_NEW_APPOINTMENT_RECEIVED,
  payload
})

const pmsCreateNewAppointmentFailed = payload => ({
  type: PMS_CREATE_NEW_APPOINTMENT_FAILED,
  payload
})

const pmsFetchAvailableTimeSlots = payload => ({
  type: PMS_FETCH_AVAILABLE_TIME_SLOTS,
  payload
})

const pmsFetchAvailableTimeSlotsReceived = payload => ({
  type: PMS_FETCH_AVAILABLE_TIME_SLOTS_RECEIVED,
  payload
})

const pmsFetchAvailableTimeSlotsFailed = payload => ({
  type: PMS_FETCH_AVAILABLE_TIME_SLOTS_FAILED,
  payload
})

const pmsFetchPatient = payload => ({
  type: PMS_FETCH_PATIENT,
  payload
})

const pmsFetchPatientReceived = payload => ({
  type: PMS_FETCH_PATIENT_RECEIVED,
  payload
})

const pmsFetchPatientFailed = payload => ({
  type: PMS_FETCH_PATIENT_FAILED,
  payload
})

const setPmsType = payload => ({
  type: SET_PMS_TYPE,
  payload
})

const pmsFetchAppointments = payload => ({
  type: PMS_FETCH_APPOINTMENTS,
  payload
})

const pmsFetchAppointmentsReceived = payload => ({
  type: PMS_FETCH_APPOINTMENTS_RECEIVED,
  payload
})

const pmsFetchAppointmentsFailed = payload => ({
  type: PMS_FETCH_APPOINTMENTS_FAILED,
  payload
})

const pmsUnlinkPatient = payload => ({
  type: PMS_UNLINK_PATIENT,
  payload
})

const pmsUnlinkPatientReceived = payload => ({
  type: PMS_UNLINK_PATIENT_RECEIVED,
  payload
})

const pmsUnlinkPatientFailed = payload => ({
  type: PMS_UNLINK_PATIENT_FAILED,
  payload
})

const pmsLinkPatient = payload => ({
  type: PMS_LINK_PATIENT,
  payload
})

const pmsLinkPatientReceived = payload => ({
  type: PMS_LINK_PATIENT_RECEIVED,
  payload
})

const pmsLinkPatientFailed = payload => ({
  type: PMS_LINK_PATIENT_FAILED,
  payload
})

const pmsFetchPatients = payload => ({
  type: PMS_FETCH_PATIENTS,
  payload
})

const pmsFetchPatientsReceived = payload => ({
  type: PMS_FETCH_PATIENTS_RECEIVED,
  payload
})

const pmsFetchPatientsFailed = payload => ({
  type: PMS_FETCH_PATIENTS_FAILED,
  payload
})

const pmsAuthentication = payload => ({
  type: PMS_AUTHENTICATION,
  payload
})

const pmsAuthenticationReceived = payload => ({
  type: PMS_AUTHENTICATION_RECEIVED,
  payload
})

const pmsAuthenticationFailed = payload => ({
  type: PMS_AUTHENTICATION_FAILED,
  payload
})

const pmsSetAppointmentMode = payload => ({
  type: PMS_SET_APPOINTMENT_MODE,
  payload
})

const pmsToggleRedirectPatientNotFoundModal = payload => ({
  type: PMS_TOGGLE_REDIRECT_PATIENT_NOT_FOUND_MODAL,
  payload
})

const pmsRedirectFailed = payload => ({
  type: PMS_REDIRECT_FAILED,
  payload
})

export default {
  SET_PMS_TYPE,
  PMS_AUTHENTICATION,
  PMS_AUTHENTICATION_FAILED,
  PMS_AUTHENTICATION_RECEIVED,
  PMS_FETCH_PATIENTS,
  PMS_FETCH_PATIENTS_RECEIVED,
  PMS_FETCH_PATIENTS_FAILED,
  PMS_LINK_PATIENT,
  PMS_LINK_PATIENT_RECEIVED,
  PMS_LINK_PATIENT_FAILED,
  PMS_UNLINK_PATIENT,
  PMS_UNLINK_PATIENT_RECEIVED,
  PMS_UNLINK_PATIENT_FAILED,
  PMS_FETCH_APPOINTMENTS,
  PMS_FETCH_APPOINTMENTS_RECEIVED,
  PMS_FETCH_APPOINTMENTS_FAILED,
  PMS_FETCH_PATIENT,
  PMS_FETCH_PATIENT_RECEIVED,
  PMS_FETCH_PATIENT_FAILED,
  PMS_FETCH_AVAILABLE_TIME_SLOTS,
  PMS_FETCH_AVAILABLE_TIME_SLOTS_RECEIVED,
  PMS_FETCH_AVAILABLE_TIME_SLOTS_FAILED,
  PMS_CREATE_NEW_APPOINTMENT,
  PMS_CREATE_NEW_APPOINTMENT_RECEIVED,
  PMS_CREATE_NEW_APPOINTMENT_FAILED,
  PMS_DELETE_APPOINTMENT,
  PMS_DELETE_APPOINTMENT_RECEIVED,
  PMS_DELETE_APPOINTMENT_FAILED,
  PMS_SET_APPOINTMENT_MODE,
  PMS_FETCH_STATIC_DATA,
  PMS_FETCH_STATIC_DATA_RECEIVED,
  PMS_FETCH_STATIC_DATA_FAILED,
  PMS_AUTOMATIC_LINKING_ATTEMPT,
  PMS_AUTOMATIC_LINKING_ATTEMPT_FAILED,
  PMS_FETCH_LATEST_PATIENTS,
  PMS_FETCH_LATEST_PATIENTS_RECEIVED,
  PMS_FETCH_LATEST_PATIENTS_FAILED,
  PMS_TOGGLE_SNACKBAR,
  PMS_TOGGLE_INVITE_PATIENTS_MODAL,
  PMS_TOGGLE_REDIRECT_PATIENT_NOT_FOUND_MODAL,
  PMS_REDIRECT_FAILED,
  PMS_REDIRECT_SEARCH_LINKED_PATIENT,
  PMS_REDIRECT_SEARCH_LINKED_PATIENT_RECEIVED,
  pmsRedirectSearchLinkedPatient,
  pmsRedirectSearchLinkedPatientReceived,
  pmsRedirectFailed,
  pmsToggleRedirectPatientNotFoundModal,
  pmsToggleInvitePatientsModal,
  pmsToggleSnackbar,
  pmsFetchLatestPatients,
  pmsFetchLatestPatientsReceived,
  pmsFetchLatestPatientsFailed,
  pmsAutomaticLinkingAttempt,
  pmsAutomaticLinkingAttemptFailed,
  pmsDeleteAppointment,
  pmsDeleteAppointmentReceived,
  pmsDeleteAppointmentFailed,
  pmsFetchStaticData,
  pmsFetchStaticDataReceived,
  pmsFetchStaticDataFailed,
  pmsSetAppointmentMode,
  pmsCreateNewAppointment,
  pmsCreateNewAppointmentReceived,
  pmsCreateNewAppointmentFailed,
  pmsFetchAvailableTimeSlots,
  pmsFetchAvailableTimeSlotsReceived,
  pmsFetchAvailableTimeSlotsFailed,
  pmsFetchPatient,
  pmsFetchPatientReceived,
  pmsFetchPatientFailed,
  setPmsType,
  pmsFetchAppointments,
  pmsFetchAppointmentsReceived,
  pmsFetchAppointmentsFailed,
  pmsUnlinkPatient,
  pmsUnlinkPatientReceived,
  pmsUnlinkPatientFailed,
  pmsLinkPatient,
  pmsLinkPatientReceived,
  pmsLinkPatientFailed,
  pmsFetchPatients,
  pmsFetchPatientsReceived,
  pmsFetchPatientsFailed,
  pmsAuthentication,
  pmsAuthenticationFailed,
  pmsAuthenticationReceived
}
