import * as React from 'react'

const NotifyPractice = props => (
  <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12.9782C0 11.7781 0.715191 10.6935 1.81824 10.2208L3.84864 9.35058C5.86103 8.48813 8.13896 8.48813 10.1514 9.35058L12.1818 10.2208C13.2848 10.6935 14 11.7781 14 12.9782V15C14 15.5523 13.5523 16 13 16H1C0.447716 16 0 15.5523 0 15V12.9782Z"
      fill="#527195"
    />
    <circle cx="7" cy="4" r="4" fill="#527195" />
    <path d="M5 9L7 16L3.5 12L5 9Z" fill="white" />
    <path d="M9 9L7 16L10.5 12L9 9Z" fill="white" />
    <path
      d="M17.7276 4.91043C17.358 3.43207 18.4761 2 20 2C21.5239 2 22.642 3.43207 22.2724 4.91043L21.2425 9.02986C21.1 9.60002 20.5877 10 20 10C19.4123 10 18.9 9.60002 18.7575 9.02986L17.7276 4.91043Z"
      fill="#527195"
    />
    <circle cx="20" cy="13" r="2" fill="#527195" />
  </svg>
)

export default NotifyPractice
