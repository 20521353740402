import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Collapse } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import GrinRadio from 'components/common/GrinRadio'
import ExpandMore from 'components/common/ExpandMore'
import { MEMBER_TYPES } from 'consts/appConsts'

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    marginTop: 15
  },
  expandMore: {
    fontWeight: 'normal',
    fontSize: 12
  },
  expandedText: {
    marginTop: 10,
    marginLeft: 30,
    maxWidth: 430
  },
  radio: {
    padding: 0,
    marginRight: 10
  },
  itemContainer: {
    margin: '15px 0'
  }
}))

const AccessPicker = ({ value, setValue }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [options, setOptions] = useState([
    {
      label: MEMBER_TYPES.manager.label,
      value: MEMBER_TYPES.manager.key,
      expanded: false,
      expandedText: t('pages.accountSettings.practiceMembers.addPracticeMember.form.accountManagerDescription')
    },
    {
      label: MEMBER_TYPES.member.label,
      value: MEMBER_TYPES.member.key,
      expanded: false,
      expandedText: t('pages.accountSettings.practiceMembers.addPracticeMember.form.teamMemberDescription')
    }
  ])

  const handleChangeValue = useCallback(value => setValue(value), [setValue])
  const handleChangeExpanded = useCallback(
    optionType => {
      const newOptions = options.map(item => {
        return { ...item, expanded: item.value === optionType ? !item.expanded : item.expanded }
      })
      setOptions(newOptions)
    },
    [options]
  )

  return (
    <div>
      <DazzedParagraph12 thickness="bold" className={classes.sectionTitle}>
        {t('pages.accountSettings.practiceMembers.addPracticeMember.form.access')}
      </DazzedParagraph12>
      {options.map(option => (
        <Grid container key={option.value} direction="column" className={classes.itemContainer}>
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <GrinRadio
                  className={classes.radio}
                  size="small"
                  checked={option.value === value}
                  onClick={() => handleChangeValue(option.value)}
                />
              </Grid>
              <Grid item>
                <DazzedParagraph12>{option.label}</DazzedParagraph12>
              </Grid>
              <Grid item>
                <ExpandMore
                  color="var(--text-color-18)"
                  expanded={option.expanded}
                  onClick={() => handleChangeExpanded(option.value)}
                  className={classes.expandMore}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Collapse in={option.expanded}>
              <DazzedParagraph12 className={classes.expandedText}>{option.expandedText}</DazzedParagraph12>
            </Collapse>
          </Grid>
        </Grid>
      ))}
    </div>
  )
}

export default AccessPicker
