import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BaseModal from '../../common/modals/BaseModal'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'

const useStyles = makeStyles({
  dialog: {
    zIndex: '1500 !important'
  },
  modal: {
    width: '480px'
  },
  link: {
    color: 'var(--text-color-3)',
    cursor: 'pointer'
  },
  img: {
    marginBottom: 20,
    marginTop: 20
  }
})

const GrantPermissionsDialog = ({ open, onClose = () => {}, permissionType = '' }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <BaseModal
      rootClassName={classes.dialog}
      className={classes.modal}
      open={open}
      title={t('dialogs.scanReviewEditor.errorTitle')}
      handleClose={onClose}
    >
      {permissionType === 'mobile' ? (
        <DazzedParagraph16 textAlign="center">
          {t('dialogs.scanReviewEditor.mobileNotSupportedLine1')} <br />
          {t('dialogs.scanReviewEditor.mobileNotSupportedLine2')}
        </DazzedParagraph16>
      ) : permissionType === 'media recorder' ? (
        <DazzedParagraph16 textAlign="center">
          {t('dialogs.scanReviewEditor.safariNotSupportedLine1') +
            t('dialogs.scanReviewEditor.safariNotSupportedLine2')}
        </DazzedParagraph16>
      ) : permissionType === 'safari' ? (
        <DazzedParagraph16 textAlign="center">
          {t('dialogs.scanReviewEditor.safariNotSupportedLine1')} <br />
          {t('dialogs.scanReviewEditor.safariNotSupportedLine2')}
        </DazzedParagraph16>
      ) : (
        <DazzedParagraph16 textAlign="center">
          {t('dialogs.scanReviewEditor.missingPermission', { permissionType })}
        </DazzedParagraph16>
      )}
    </BaseModal>
  )
}

export default GrantPermissionsDialog
