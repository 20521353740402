import { getCloudFrontUrl } from '../storageUtils'
import moment from 'moment'

export const mapToTreatmentModel = treatment => ({
  id: treatment.id,
  patientId: treatment.patientId,
  date: treatment.date,
  endDate: treatment.endDate,
  status: treatment.status,
  cycleInterval: treatment.cycleInterval,
  cycleIntervalPeriodType: treatment.cycleIntervalPeriodType,
  stageDefaultDuration: treatment.stageDefaultDuration,
  introMeetingDate: treatment.introMeetingDate,
  type: treatment.type,
  totalApplianceNumber: treatment.totalApplianceNumber,
  a_doctor: treatment.a_doctor,
  a_patient: treatment.a_patient,
  _version: treatment._version
})

export const mapToGrinScansDto = scans => (scans ?? []).map(scan => mapGrinScan(scan))

export const mapGrinScan = scan => ({
  ...scan,
  video: { ...scan.video, url: `${getCloudFrontUrl()}/${scan.video.key}` },
  scanReviews: {
    ...(scan.scanReviews || {}),
    items: scan.scanReviews?.items
      ? scan.scanReviews.items
          .map(mapToScanReviewDto)
          .sort((A, B) => (moment(A.createdAt).isBefore(B.createdAt) ? -1 : 1))
      : []
  }
})

export const mapToScanReviewDto = scanReview => ({
  ...scanReview,
  video: {
    ...scanReview.video,
    url: `${getCloudFrontUrl()}/${scanReview.video.key}`
  }
})
