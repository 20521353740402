import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import TemplateListItem from '../Templates/TemplateListItem'
import { Close } from 'components/common/icons'

const useStyles = () =>
  makeStyles({
    landscapeTitleContainer: {
      paddingLeft: 25,
      marginTop: 20
    },
    landscapeTitle: {
      margin: '10px 0'
    },
    promotionsListContainer: {
      paddingLeft: 20,
      maxHeight: 200,
      overflowY: 'auto',
      overflowX: 'hidden'
    }
  })()

const LandscapePromotionsList = ({ onModalClose, groupedPromotions, activeTab, handleChangeTab }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid item xs={3}>
      <Grid container justifyContent="space-between" className={classes.landscapeTitleContainer}>
        <DazzedParagraph16 bold className={classes.landscapeTitle}>
          {t('pages.patients.selectedPatient.chat.promotions.title')}
        </DazzedParagraph16>
        <Close color="var(--text-color-14)" onClick={onModalClose} />
      </Grid>
      <Grid container className={classes.promotionsListContainer}>
        {groupedPromotions.map((promotion, index) => (
          <TemplateListItem
            key={promotion.id}
            title={promotion.title}
            selected={promotion.id === groupedPromotions[activeTab]?.id}
            onClick={event => handleChangeTab(event, index)}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default LandscapePromotionsList
