import { AsyncStatus } from 'consts'
import Actions from '../actions'
import { AutochartTabs, ConditionTypes } from 'consts/autochartConsts'
import { updateWhere } from 'utils/arrayUtils'

const initialState = {
  isOpen: false,
  status: null,
  tab: AutochartTabs.Table,
  grinScan: null,
  patientSM: null,
  isTouched: false,
  isSaving: false,
  isRightPanelVisible: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.AUTOCHART_TOGGLE_DIALOG:
      return {
        ...state,
        isOpen: action.payload.isOpen,
        grinScan: initialState.grinScan,
        patientSM: initialState.patientSM,
        tab: AutochartTabs.Table,
        isTouched: false,
        isRightPanelVisible: initialState.isRightPanelVisible
      }
    case Actions.AUTOCHART_LOAD_DATA:
      return {
        ...state,
        status: AsyncStatus.Loading,
        isTouched: false
      }
    case Actions.AUTOCHART_LOAD_DATA_RECEIVED:
      return {
        ...state,
        status: AsyncStatus.Completed,
        grinScan: action.payload.grinScan,
        patientSM: action.payload.patientSM
      }
    case Actions.AUTOCHART_LOAD_DATA_FAILED:
      return {
        ...state,
        status: AsyncStatus.Failed
      }
    case Actions.AUTOCHART_SET_TAB:
      return {
        ...state,
        tab: action.payload
      }
    case Actions.AUTOCHART_UPDATE_TOOTH_CONDITIONS:
      return {
        ...state,
        isTouched: true,
        grinScan: {
          ...state.grinScan,
          autochart: {
            ...state.grinScan.autochart,
            teeth: updateWhere(
              state.grinScan.autochart.teeth,
              tooth => tooth.number === action.payload.toothNumber,
              tooth => {
                tooth.conditions = JSON.stringify(action.payload.conditions)
                tooth.exists = !action.payload.conditions.find(
                  condition => condition.type === ConditionTypes.MissingTooth
                )
              }
            )
          }
        }
      }
    case Actions.AUTOCHART_SET_GLOBAL_NOTES:
      return {
        ...state,
        isTouched: true,
        grinScan: {
          ...state.grinScan,
          autochart: {
            ...state.grinScan.autochart,
            notes: action.payload.notes
          }
        }
      }
    case Actions.AUTOCHART_SAVE:
      return {
        ...state,
        isSaving: true
      }
    case Actions.AUTOCHART_SAVE_RECEIVED:
      return {
        ...state,
        isTouched: false,
        isSaving: false
      }
    case Actions.AUTOCHART_SAVE_FAILED:
      return {
        ...state,
        isSaving: false
      }
    case Actions.AUTOCHART_TOGGLE_RIGHT_PANEL:
      return {
        ...state,
        isRightPanelVisible: action.payload.value
      }
    default:
      return state
  }
}
