const REQUEST_PRACTICE_MEMBERS = 'REQUEST_PRACTICE_MEMBERS'
const PRACTICE_MEMBERS_RECEIVED = 'PRACTICE_MEMBERS_RECEIVED'
const PRACTICE_MEMBERS_FAILED = 'PRACTICE_MEMBERS_FAILED'
const CANCEL_PRACTICE_MEMBER_INVITATION = 'CANCEL_PRACTICE_MEMBER_INVITATION'
const CANCEL_PRACTICE_MEMBER_INVITATION_RECEIVED = 'CANCEL_PRACTICE_MEMBER_INVITATION_RECEIVED'
const CANCEL_PRACTICE_MEMBER_INVITATION_FAILED = 'CANCEL_PRACTICE_MEMBER_INVITATION_FAILED'
const RESEND_PRACTICE_MEMBER_INVITATION = 'RESEND_PRACTICE_MEMBER_INVITATION'
const RESEND_PRACTICE_MEMBER_INVITATION_RECEIVED = 'RESEND_PRACTICE_MEMBER_INVITATION_RECEIVED'
const RESEND_PRACTICE_MEMBER_INVITATION_FAILED = 'RESEND_PRACTICE_MEMBER_INVITATION_FAILED'
const DEACTIVATE_PRACTICE_MEMBER = 'DEACTIVATE_PRACTICE_MEMBER'
const DEACTIVATE_PRACTICE_MEMBER_RECEIVED = 'DEACTIVATE_PRACTICE_MEMBER_RECEIVED'
const DEACTIVATE_PRACTICE_MEMBER_FAILED = 'DEACTIVATE_PRACTICE_MEMBER_FAILED'
const ACTIVATE_PRACTICE_MEMBER = 'ACTIVATE_PRACTICE_MEMBER'
const ACTIVATE_PRACTICE_MEMBER_RECEIVED = 'ACTIVATE_PRACTICE_MEMBER_RECEIVED'
const ACTIVATE_PRACTICE_MEMBER_FAILED = 'ACTIVATE_PRACTICE_MEMBER_FAILED'
const PRACTICE_MEMBER_ERRORS_RECEIVED = 'PRACTICE_MEMBER_ERRORS_RECEIVED'
const PRACTICE_MEMBER_RESET_ERRORS = 'PRACTICE_MEMBER_RESET_ERRORS'
const INVITE_PRACTICE_MEMBER = 'INVITE_PRACTICE_MEMBER'
const INVITE_PRACTICE_MEMBER_RECEIVED = 'INVITE_PRACTICE_MEMBER_RECEIVED'
const INVITE_PRACTICE_MEMBER_FAILED = 'INVITE_PRACTICE_MEMBER_FAILED'
const CHANGE_PRACTICE_MEMBER_ACCESS = 'CHANGE_PRACTICE_MEMBER_ACCESS'
const CHANGE_PRACTICE_MEMBER_ACCESS_RECEIVED = 'CHANGE_PRACTICE_MEMBER_ACCESS_RECEIVED'
const CHANGE_PRACTICE_MEMBER_ACCESS_FAILED = 'CHANGE_PRACTICE_MEMBER_ACCESS_FAILED'
const ASSIGN_PATIENT_TO_PRACTICE_MEMBER = 'ASSIGN_PATIENT_TO_PRACTICE_MEMBER'
const ASSIGN_PATIENT_TO_PRACTICE_MEMBER_RECEIVED = 'ASSIGN_PATIENT_TO_PRACTICE_MEMBER_RECEIVED'
const ASSIGN_PATIENT_TO_PRACTICE_MEMBER_FAILED = 'ASSIGN_PATIENT_TO_PRACTICE_MEMBER_FAILED'
const SET_ASSIGN_PATIENT = 'SET_ASSIGN_PATIENT'
const SET_ASSIGNEE_SEEN_TAG = 'SET_ASSIGNEE_SEEN_TAG'
const SET_ASSIGNEE_SEEN_TAG_SUCCEDED = 'SET_ASSIGNEE_SEEN_TAG_SUCCEDED'
const UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT = 'UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT'
const UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT_RECEIVED = 'UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT_RECEIVED'
const UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT_FAILED = 'UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT_FAILED'

const unassignPracticeMemberFromPatient = payload => ({
  type: UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT,
  payload
})

const unassignPracticeMemberFromPatientReceived = payload => ({
  type: UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT_RECEIVED,
  payload
})

const unassignPracticeMemberFromPatientFailed = payload => ({
  type: UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT_FAILED,
  payload
})

const requestPracticeMembers = payload => ({
  type: REQUEST_PRACTICE_MEMBERS,
  payload
})

const practiceMembersReceived = payload => ({
  type: PRACTICE_MEMBERS_RECEIVED,
  payload
})

const practiceMembersFailed = payload => ({
  type: PRACTICE_MEMBERS_FAILED,
  payload
})

const cancelPracticeMemberInvitation = payload => ({
  type: CANCEL_PRACTICE_MEMBER_INVITATION,
  payload
})

const cancelPracticeMemberInvitationReceived = payload => ({
  type: CANCEL_PRACTICE_MEMBER_INVITATION_RECEIVED,
  payload
})

const cancelPracticeMemberInvitationFailed = payload => ({
  type: CANCEL_PRACTICE_MEMBER_INVITATION_FAILED,
  payload
})

const resendPracticeMemberInvitation = payload => ({
  type: RESEND_PRACTICE_MEMBER_INVITATION,
  payload
})

const resendPracticeMemberInvitationReceived = payload => ({
  type: RESEND_PRACTICE_MEMBER_INVITATION_RECEIVED,
  payload
})

const resendPracticeMemberInvitationFailed = payload => ({
  type: RESEND_PRACTICE_MEMBER_INVITATION_FAILED,
  payload
})

const deactivatePracticeMember = payload => ({
  type: DEACTIVATE_PRACTICE_MEMBER,
  payload
})

const deactivatePracticeMemberReceived = payload => ({
  type: DEACTIVATE_PRACTICE_MEMBER_RECEIVED,
  payload
})

const deactivatePracticeMemberFailed = payload => ({
  type: DEACTIVATE_PRACTICE_MEMBER_FAILED,
  payload
})

const activatePracticeMember = payload => ({
  type: ACTIVATE_PRACTICE_MEMBER,
  payload
})

const activatePracticeMemberReceived = payload => ({
  type: ACTIVATE_PRACTICE_MEMBER_RECEIVED,
  payload
})

const activatePracticeMemberFailed = payload => ({
  type: ACTIVATE_PRACTICE_MEMBER_FAILED,
  payload
})

const practiceMemberErrorsReceived = payload => ({
  type: PRACTICE_MEMBER_ERRORS_RECEIVED,
  payload
})

const practiceMemberResetErrors = () => ({
  type: PRACTICE_MEMBER_RESET_ERRORS
})

const invitePracticeMember = payload => ({
  type: INVITE_PRACTICE_MEMBER,
  payload
})

const invitePracticeMemberReceived = payload => ({
  type: INVITE_PRACTICE_MEMBER_RECEIVED,
  payload
})

const invitePracticeMemberFailed = payload => ({
  type: INVITE_PRACTICE_MEMBER_FAILED,
  payload
})

const changePracticeMemberAccess = payload => ({
  type: CHANGE_PRACTICE_MEMBER_ACCESS,
  payload
})

const changePracticeMemberAccessReceived = payload => ({
  type: CHANGE_PRACTICE_MEMBER_ACCESS_RECEIVED,
  payload
})

const changePracticeMemberAccessFailed = payload => ({
  type: CHANGE_PRACTICE_MEMBER_ACCESS_FAILED,
  payload
})

const assignPatientToPracticeMember = payload => ({
  type: ASSIGN_PATIENT_TO_PRACTICE_MEMBER,
  payload
})

const assignPatientToPracticeMemberReceived = payload => ({
  type: ASSIGN_PATIENT_TO_PRACTICE_MEMBER_RECEIVED,
  payload
})

const assignPatientToPracticeMemberFailed = payload => ({
  type: ASSIGN_PATIENT_TO_PRACTICE_MEMBER_FAILED,
  payload
})

const setAssignPatient = payload => ({
  type: SET_ASSIGN_PATIENT,
  payload
})

const setAssigneeSeenTag = payload => ({
  type: SET_ASSIGNEE_SEEN_TAG,
  payload
})

const setAssigneeSeenTagSucceded = payload => ({
  type: SET_ASSIGNEE_SEEN_TAG_SUCCEDED,
  payload
})

export default {
  REQUEST_PRACTICE_MEMBERS,
  PRACTICE_MEMBERS_RECEIVED,
  PRACTICE_MEMBERS_FAILED,
  CANCEL_PRACTICE_MEMBER_INVITATION,
  CANCEL_PRACTICE_MEMBER_INVITATION_RECEIVED,
  CANCEL_PRACTICE_MEMBER_INVITATION_FAILED,
  RESEND_PRACTICE_MEMBER_INVITATION,
  RESEND_PRACTICE_MEMBER_INVITATION_RECEIVED,
  RESEND_PRACTICE_MEMBER_INVITATION_FAILED,
  DEACTIVATE_PRACTICE_MEMBER,
  DEACTIVATE_PRACTICE_MEMBER_RECEIVED,
  DEACTIVATE_PRACTICE_MEMBER_FAILED,
  ACTIVATE_PRACTICE_MEMBER,
  ACTIVATE_PRACTICE_MEMBER_RECEIVED,
  ACTIVATE_PRACTICE_MEMBER_FAILED,
  PRACTICE_MEMBER_ERRORS_RECEIVED,
  INVITE_PRACTICE_MEMBER,
  INVITE_PRACTICE_MEMBER_RECEIVED,
  INVITE_PRACTICE_MEMBER_FAILED,
  PRACTICE_MEMBER_RESET_ERRORS,
  CHANGE_PRACTICE_MEMBER_ACCESS,
  CHANGE_PRACTICE_MEMBER_ACCESS_RECEIVED,
  CHANGE_PRACTICE_MEMBER_ACCESS_FAILED,
  ASSIGN_PATIENT_TO_PRACTICE_MEMBER,
  ASSIGN_PATIENT_TO_PRACTICE_MEMBER_RECEIVED,
  ASSIGN_PATIENT_TO_PRACTICE_MEMBER_FAILED,
  SET_ASSIGN_PATIENT,
  SET_ASSIGNEE_SEEN_TAG,
  SET_ASSIGNEE_SEEN_TAG_SUCCEDED,
  UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT,
  UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT_FAILED,
  UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT_RECEIVED,
  requestPracticeMembers,
  practiceMembersReceived,
  practiceMembersFailed,
  cancelPracticeMemberInvitation,
  cancelPracticeMemberInvitationReceived,
  cancelPracticeMemberInvitationFailed,
  resendPracticeMemberInvitation,
  resendPracticeMemberInvitationReceived,
  resendPracticeMemberInvitationFailed,
  deactivatePracticeMember,
  deactivatePracticeMemberReceived,
  deactivatePracticeMemberFailed,
  activatePracticeMember,
  activatePracticeMemberReceived,
  activatePracticeMemberFailed,
  practiceMemberErrorsReceived,
  practiceMemberResetErrors,
  invitePracticeMember,
  invitePracticeMemberReceived,
  invitePracticeMemberFailed,
  changePracticeMemberAccess,
  changePracticeMemberAccessReceived,
  changePracticeMemberAccessFailed,
  assignPatientToPracticeMember,
  assignPatientToPracticeMemberReceived,
  assignPatientToPracticeMemberFailed,
  setAssignPatient,
  setAssigneeSeenTag,
  setAssigneeSeenTagSucceded,
  unassignPracticeMemberFromPatient,
  unassignPracticeMemberFromPatientReceived,
  unassignPracticeMemberFromPatientFailed
}
