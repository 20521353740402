import React, { useState, useCallback, useEffect } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import { getObjectUrl } from 'utils/mediaUtils'

const useStyles = makeStyles(theme => ({
  image: {
    height: 89,
    objectFit: 'cover',
    border: '1px solid #ABB0B8',
    borderRadius: '5px'
  },
  position: {
    color: '#80879A',
    fontWeight: '500 !important'
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 5,
    width: '20%',
    height: 143
  },
  previewContainer: {
    flex: 1,
    height: 100,
    borderRadius: '9px',
    position: 'relative',
    cursor: 'pointer',
    padding: 8,
    border: '1px solid #ABB0B8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'border-color .5s ease-out'
  },
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'var(--bg-color-2)',
    textAlign: 'center',
    padding: 22,
    color: 'var(--text-color-11)'
  },
  selected: {
    borderColor: 'var(--border-color-1) !important',
    border: '2px solid !important',
    transition: 'border-color .5s linear'
  },
  selectedText: {
    color: 'var(--text-color-3) !important',
    transition: 'border-color .5s linear'
  },
  issueContainer: {
    border: '1px solid #ABB0B8',
    width: 143,
    height: 52,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 5,
    transition: 'border-color .5s ease-out'
  },
  issue: {
    width: '90%',
    color: '#727B8C',
    padding: '5px 10px 5px 10px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'border-color .5s ease-out'
  },
  comment: {
    resize: 'none',
    fontFamily: 'Dazzed',
    fontSize: 14,
    background: 'transparent',
    border: '1px solid #ABB0B8',
    borderRadius: 5,
    paddingLeft: 14,
    paddingTop: 14,
    width: '100%',
    height: '85px',
    '&:focus': {
      outline: 'none'
    },
    '&::placeholder': {
      fontFamily: 'Dazzed',
      fontWeight: 500,
      fontStyle: 'normal',
      color: '#A6AAB3',
      fintSize: 16
    }
  }
}))

const NegativeFeedback = ({ images, onSubmitReview, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const issues = ['poorScanQuality', 'wrongAngle', 'issuesWithFocus', 'incompleteView', 'missingAngle', 'other']
  const [comment, setComment] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [selectedIssues, setSelectedIssues] = useState([])

  const handleImageSelection = useCallback(
    imageKey => {
      setSelectedImages(images =>
        images.includes(imageKey) ? images.filter(image => image !== imageKey) : [...images, imageKey]
      )
    },
    [setSelectedImages]
  )

  const handleIssueSelection = useCallback(
    issueKey => {
      setSelectedIssues(currIssues =>
        currIssues.includes(issueKey) ? currIssues.filter(issue => issue !== issueKey) : [...currIssues, issueKey]
      )
    },
    [setSelectedIssues]
  )

  const handleSubmitFeedback = useCallback(() => {
    let feedbackData = { feedBack: 'bad' }

    if (comment !== '') {
      feedbackData.comment = comment
    }

    selectedImages.forEach(image => (feedbackData[image] = true))
    selectedIssues.forEach(issue => (feedbackData[issue] = true))
    onSubmitReview(false, feedbackData)
    onClose()
  }, [comment, onSubmitReview, selectedImages, selectedIssues, onClose])

  useEffect(() => {
    if (selectedImages.length > 0 && !isValid) {
      setIsValid(true)
    } else if (selectedImages.length === 0) {
      setIsValid(false)
    }
  }, [isValid, selectedImages.length])

  return (
    <Grid container style={{ padding: '10px 10px 30px 10px' }}>
      <Grid container direction="row" style={{ marginBottom: 25, userSelect: 'none', height: '100%' }}>
        <Grid item style={{ marginBottom: 10 }}>
          <DazzedParagraph16 strong={true}>{t('dialogs.scanSummary.negativeFeedback.images.title')}</DazzedParagraph16>
        </Grid>
        <Grid container direction="row" style={{ marginBottom: 10 }}>
          {images.map(([position, s3Object]) => (
            <Grid item key={position} className={classes.imageContainer} onClick={() => handleImageSelection(position)}>
              <div className={`${classes.previewContainer} ${selectedImages.includes(position) && classes.selected}`}>
                {s3Object ? (
                  <img src={`${getObjectUrl(s3Object)}`} alt="" className={classes.image} />
                ) : (
                  <div className={`${classes.image} ${classes.emptyContainer}`}>
                    {t('dialogs.scanSummary.couldntBeCaptured')}
                  </div>
                )}
              </div>
              <DazzedParagraph14 strong={true} className={classes.position}>
                {t(`dialogs.scanSummary.negativeFeedback.images.${position}`)}
              </DazzedParagraph14>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: 35, userSelect: 'none' }}>
        <Grid item style={{ marginBottom: 10 }}>
          <DazzedParagraph16 strong={true}>{t('dialogs.scanSummary.negativeFeedback.issues.title')}</DazzedParagraph16>
        </Grid>
        <Grid container justifyContent="space-evenly" alignItems="center">
          {issues.map(issue => (
            <Grid
              item
              key={issue}
              className={`${classes.issueContainer} ${selectedIssues.includes(issue) && classes.selected}`}
              onClick={() => handleIssueSelection(issue)}
            >
              <DazzedParagraph14
                className={`${classes.issue} ${selectedIssues.includes(issue) && classes.selectedText}`}
                strong={true}
              >
                {t(`dialogs.scanSummary.negativeFeedback.issues.${issue}`)}
              </DazzedParagraph14>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container direction="column" style={{ marginBottom: 25 }}>
        <Grid item style={{ marginBottom: 10 }}>
          <DazzedParagraph16 strong={true}>{t('dialogs.scanSummary.negativeFeedback.comment.title')}</DazzedParagraph16>
        </Grid>
        <Grid item>
          <textarea
            className={classes.comment}
            placeholder={t('dialogs.scanSummary.negativeFeedback.comment.placeholder')}
            value={comment}
            onChange={({ target }) => {
              setComment(target.value)
            }}
            onKeyDown={e => e.stopPropagation()}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item style={{ marginRight: 5 }}>
          <SecondaryButton
            height={48}
            width={160}
            label={t('dialogs.scanSummary.negativeFeedback.skipLabel')}
            onClick={handleSubmitFeedback}
          />
        </Grid>
        <Grid item>
          <PrimaryButton
            disabled={!isValid}
            height={48}
            width={160}
            label={t('dialogs.scanSummary.negativeFeedback.submitLabel')}
            onClick={handleSubmitFeedback}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NegativeFeedback
