import * as React from 'react'

const ResendInviteLarge = ({ ...props }) => {
  return (
    <svg width="102" height="79" viewBox="0 0 102 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="14.5" y="13.5" width="72.695" height="50.2903" rx="4.98094" fill="white" stroke="#3C52EF" />
      <path
        d="M16.3384 15.3385L47.6789 46.679C49.4288 48.4289 52.266 48.4289 54.0159 46.679L85.3565 15.3385"
        stroke="#3C52EF"
      />
      <path d="M16.3384 61.9518L39.6451 38.6451" stroke="#3C52EF" />
      <path d="M85.3564 61.9518L62.0497 38.6451" stroke="#3C52EF" />
      <circle cx="87.2769" cy="63.6976" r="14.7231" fill="white" />
      <circle cx="87.2769" cy="63.6976" r="10.5165" stroke="#3C52EF" />
      <path d="M87.2768 69.7207L87.2768 61.69" stroke="#3C52EF" />
      <path d="M87.2768 58.3438L87.2768 57.6746" stroke="#3C52EF" />
    </svg>
  )
}

export default ResendInviteLarge
