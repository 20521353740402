import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { rangeArray } from 'utils/arrayUtils'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  scanActionsSkeletonRoot: {
    margin: '12px 0'
  },
  item: {
    borderRadius: '8px'
  }
}))

const ScanActionsSkeleton = ({ itemsCount = 5 }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      className={classes.scanActionsSkeletonRoot}
      spacing={1}
    >
      {rangeArray(itemsCount).map(i => (
        <Grid item key={`scan-action-skeleton-${i}`}>
          <Skeleton variant="rect" height={20} width={70} className={classes.item} />
        </Grid>
      ))}
    </Grid>
  )
}

export default ScanActionsSkeleton
