import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import EnableCategory from 'components/common/icons/patientBrief/EnableCategory'
import DisableCategory from 'components/common/icons/patientBrief/DisableCategory'
import SetNote from 'components/common/icons/patientBrief/SetNote'
import CategoryComponents from './CategoryComponents'
import CategoryNote from './CategoryNote'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import CategorySubtitle from './CategoryComponents/CategorySubtitle'
import { trackEvent } from 'utils/analyticsUtils'
import CategoryCheckMark from 'components/common/icons/patientBrief/CategoryCheckMark'

const useStyles = makeStyles(theme => ({
  root: {
    width: '470px',
    padding: '16px 24px',
    borderRadius: 8,
    backgroundColor: ({ isEnabled, viewMode }) =>
      viewMode ? 'var(--bg-color-24)' : isEnabled ? 'var(--bg-color-72)' : '#fff',
    border: ({ isEnabled, errorState, viewMode }) =>
      viewMode
        ? '0px'
        : isEnabled
        ? errorState
          ? '1px solid red'
          : '1px solid var(--bg-color-72)'
        : '1px solid var(--border-color-7)',
    transition: 'background-color ease 0.2s'
  },
  title: {
    fontWeight: ({ viewMode }) => (viewMode ? 'bold' : '500')
  },
  errorMessage: {
    marginTop: 8,
    color: 'red'
  }
}))

const PatientBriefCategory = ({
  category,
  categoryValues = {},
  setCategoryValues = () => {},
  error = '',
  viewMode
}) => {
  const classes = useStyles({ isEnabled: categoryValues.isEnabled, errorState: !!error, viewMode })
  const { t } = useTranslation()

  const [isNoteInputVisible, setIsNoteInputVisible] = useState(false)

  const handleChange = useCallback(
    (changes = {}) => {
      setCategoryValues({ ...categoryValues, ...changes })
    },
    [setCategoryValues, categoryValues]
  )

  const handleChangeItems = useCallback(
    changes => {
      handleChange({
        items: {
          ...(categoryValues.items || {}),
          ...changes
        }
      })
    },
    [handleChange, categoryValues]
  )

  const handleSaveNote = useCallback(
    draftNote => {
      handleChange({ note: draftNote })
      setIsNoteInputVisible(false)
    },
    [handleChange]
  )

  const handleEnableCategory = useCallback(() => {
    trackEvent('Patient brief - category selected', { category })
    handleChange({ isEnabled: true })
  }, [handleChange, category])

  const handleDisableCategory = useCallback(() => {
    trackEvent('Patient brief - category deselected', { category })
    handleChange({ isEnabled: false })
    setIsNoteInputVisible(false)
  }, [handleChange, category])

  const handleAddNote = useCallback(() => {
    trackEvent('Patient brief - category note added', { category })
    setIsNoteInputVisible(true)
  }, [category])

  const actions = useMemo(
    () =>
      [
        {
          icon: <EnableCategory />,
          condition: !categoryValues?.isEnabled,
          onClick: handleEnableCategory
        },
        {
          icon: <SetNote />,
          condition: categoryValues?.isEnabled && !isNoteInputVisible,
          onClick: handleAddNote
        },
        {
          icon: <DisableCategory />,
          condition: categoryValues?.isEnabled,
          onClick: handleDisableCategory
        }
      ].filter(action => action.condition),
    [categoryValues, isNoteInputVisible, handleDisableCategory, handleEnableCategory, handleAddNote]
  )

  return (
    <div>
      <Grid container direction="column" className={classes.root} spacing={1}>
        <Grid item>
          <Grid container spacing={1} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                {categoryValues?.isEnabled && (
                  <Grid item>
                    <CategoryCheckMark />
                  </Grid>
                )}
                <Grid item>
                  <DazzedParagraph16 className={classes.title}>
                    {t(`dialogs.patientBrief.categories.${category}.title`)}
                  </DazzedParagraph16>
                </Grid>
              </Grid>
            </Grid>
            {!viewMode && (
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  {actions.map((action, i) => (
                    <Grid item key={`patient-brief-action-${i}`}>
                      <IconButton size="small" onClick={action.onClick}>
                        {action.icon}
                      </IconButton>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {categoryValues.isEnabled && (
          <>
            {CategoryComponents[category] && (
              <Grid item>
                {React.createElement(CategoryComponents[category], {
                  categoryValues: categoryValues.items,
                  onChange: handleChangeItems,
                  viewMode
                })}
              </Grid>
            )}
            {viewMode && categoryValues.note && (
              <Grid item>
                <CategorySubtitle viewMode>{t('dialogs.patientBrief.categoryNoteSectionTitle')}</CategorySubtitle>
                <DazzedParagraph14 strong>{categoryValues.note}</DazzedParagraph14>
              </Grid>
            )}
            {!viewMode && (
              <Grid item>
                <CategoryNote
                  note={categoryValues.note}
                  editMode={isNoteInputVisible}
                  onSaveChanges={handleSaveNote}
                  onCancelChanges={() => setIsNoteInputVisible(false)}
                  onEnterEditMode={() => setIsNoteInputVisible(true)}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
      {categoryValues.isEnabled && error && (
        <DazzedParagraph12 className={classes.errorMessage}>{error}</DazzedParagraph12>
      )}
    </div>
  )
}

export default PatientBriefCategory
