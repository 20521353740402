import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(({ color, palette }) => ({
  root: {
    fontFamily: 'Dazzed',
    fontSize: 16,
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: ({ color }) => color ?? palette.primary.main
  },
  soft: {
    color: '#727B8C'
  },
  strong: {
    fontWeight: 500
  },
  bold: {
    fontWeight: 600
  }
}))

const DazzedParagraph16 = ({
  children,
  soft = false,
  strong = false,
  bold = false,
  textAlign = 'left',
  className,
  disabeld = false,
  color,
  onClick = () => {}
}) => {
  const classes = useStyles({ color })

  return (
    <div
      style={{ textAlign }}
      className={[
        classes.root,
        soft ? classes.soft : '',
        strong ? classes.strong : '',
        bold ? classes.bold : '',
        className
      ].join(' ')}
      onClick={disabeld ? () => {} : onClick}
    >
      {children}
    </div>
  )
}

export default DazzedParagraph16
