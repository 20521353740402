import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.main,
    fontFamily: 'Dazzed',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px'
  }
}))

const DazzedHeading14 = ({ className, children, regular }) => {
  const classes = useStyles({})

  return (
    <div className={[classes.heading, className].join(' ')} style={regular ? { fontWeight: 400 } : {}}>
      {children}
    </div>
  )
}

export default DazzedHeading14
