import * as React from 'react'

function SvgError({ height = 25, width = 25, color = '#727B8C' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M8.38542 21.875H2.60417C1.16771 21.875 0 20.7073 0 19.2708V2.60417C0 1.16771 1.16771 0 2.60417 0H15.1042C16.5406 0 17.7083 1.16771 17.7083 2.60417V9.92708C17.7083 10.2146 17.475 10.4479 17.1875 10.4479C16.9 10.4479 16.6667 10.2156 16.6667 9.92708V2.60417C16.6667 1.74271 15.9656 1.04167 15.1042 1.04167H2.60417C1.74271 1.04167 1.04167 1.74271 1.04167 2.60417V19.2708C1.04167 20.1323 1.74271 20.8333 2.60417 20.8333H8.38542C8.67292 20.8333 8.90625 21.0667 8.90625 21.3542C8.90625 21.6417 8.67292 21.875 8.38542 21.875Z"
          fill={color}
        />
        <path
          d="M14.0625 9.37468H3.64583C3.35833 9.37468 3.125 9.14134 3.125 8.85384C3.125 8.56634 3.35833 8.33301 3.64583 8.33301H14.0625C14.35 8.33301 14.5833 8.56634 14.5833 8.85384C14.5833 9.14134 14.35 9.37468 14.0625 9.37468Z"
          fill={color}
        />
        <path
          d="M9.89583 13.5417H3.64583C3.35833 13.5417 3.125 13.3083 3.125 13.0208C3.125 12.7333 3.35833 12.5 3.64583 12.5H9.89583C10.1833 12.5 10.4167 12.7333 10.4167 13.0208C10.4167 13.3083 10.1833 13.5417 9.89583 13.5417Z"
          fill={color}
        />
        <path
          d="M8.85417 5.20866H3.64583C3.35833 5.20866 3.125 4.97533 3.125 4.68783C3.125 4.40033 3.35833 4.16699 3.64583 4.16699H8.85417C9.14167 4.16699 9.375 4.40033 9.375 4.68783C9.375 4.97533 9.14167 5.20866 8.85417 5.20866Z"
          fill={color}
        />
        <path
          d="M23.4376 25H11.9792C11.1178 25 10.4167 24.299 10.4167 23.4375C10.4167 23.1312 10.5011 22.8448 10.6615 22.6052L16.3782 13.25C16.648 12.7906 17.1595 12.5 17.7084 12.5C18.2574 12.5 18.7688 12.7906 19.0428 13.2583L24.7667 22.625C24.9157 22.8448 25.0001 23.1312 25.0001 23.4375C25.0001 24.299 24.299 25 23.4376 25ZM17.7084 13.5417C17.5272 13.5417 17.3605 13.6354 17.273 13.7844L11.5386 23.1677C11.4813 23.2531 11.4584 23.3396 11.4584 23.4375C11.4584 23.724 11.6928 23.9583 11.9792 23.9583H23.4376C23.724 23.9583 23.9584 23.724 23.9584 23.4375C23.9584 23.3396 23.9355 23.2531 23.8907 23.1875L18.149 13.7927C18.0563 13.6354 17.8897 13.5417 17.7084 13.5417Z"
          fill={color}
        />
        <path
          d="M17.7083 20.8333C17.4208 20.8333 17.1875 20.6 17.1875 20.3125V16.1458C17.1875 15.8583 17.4208 15.625 17.7083 15.625C17.9958 15.625 18.2292 15.8583 18.2292 16.1458V20.3125C18.2292 20.6 17.9958 20.8333 17.7083 20.8333Z"
          fill={color}
        />
        <path
          d="M17.7083 22.9167C17.996 22.9167 18.2292 22.6835 18.2292 22.3958C18.2292 22.1082 17.996 21.875 17.7083 21.875C17.4207 21.875 17.1875 22.1082 17.1875 22.3958C17.1875 22.6835 17.4207 22.9167 17.7083 22.9167Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgError
