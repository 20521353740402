import { TEMPLATES_TYPES } from 'consts/templatesConsts'
import { groupBy } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export default () => {
  const { files } = useSelector(state => state.practiceReducer.savedFiles)

  const allFiles = useMemo(
    () => [
      ...files.map(file => ({
        ...file,
        name: file.title
      }))
    ],
    [files]
  )

  const practiceFiles = useMemo(() => allFiles.filter(file => file.type === TEMPLATES_TYPES.FILE), [allFiles])
  const systemFilesByCategory = useMemo(
    () =>
      groupBy(
        allFiles.filter(file => file.type === TEMPLATES_TYPES.SYSTEM_FILE),
        systemFile => systemFile.category
      ),
    [allFiles]
  )

  return {
    practiceFiles,
    systemFilesByCategory
  }
}
