import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedHeading14 from 'components/common/text/DazzedHeading14'
import DazzedHeading13 from 'components/common/text/DazzedHeading13'
import ProfilePicture from 'components/common/ProfilePicture'

const LeadNameAndEmail = ({ note, selectedLead, email, name, justifyContent = 'center' }) => {
  const useComponentStyles = makeStyles({
    leadNameContainer: {
      marginLeft: 10,
      marginBottom: 20
    }
  })

  const classes = useComponentStyles()

  return (
    <Grid container justifyContent={justifyContent}>
      <Grid className={classes.leadNameContainer} item>
        <ProfilePicture name={selectedLead?.leadName || name} />
      </Grid>
      <Grid className={classes.leadNameContainer} item>
        <DazzedHeading14>{selectedLead?.leadName || name}</DazzedHeading14>
        <DazzedHeading13>{selectedLead?.email || email}</DazzedHeading13>
      </Grid>
      {note && <DazzedHeading14>{note}</DazzedHeading14>}
    </Grid>
  )
}

export default LeadNameAndEmail
