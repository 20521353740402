import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import PatientDetails from 'components/common/PatientDetails'
import { compareTags } from 'utils/tagUtils'
import LinkButton from 'components/common/buttons/LinkButton'
import { useTranslation } from 'react-i18next'
import ShareProgress from 'components/common/icons/scan-item-actions/ShareProgress'
import useScansComparison from './useScansComparison'
import { useSelector } from 'react-redux'

const CompareScansHeader = () => {
  const { t } = useTranslation()

  const { patient } = useSelector(state => state.patientsReducer)
  const { generateBeforeAfterForSelectedScans, canGenerateBeforeAfter } = useScansComparison()

  const tags = useMemo(
    () =>
      patient.patientTags?.items
        ?.filter(patientTag => patientTag.tag.type === 'treatment' || patientTag.tag.type === 'program')
        .map(pt => pt.tag)
        .sort(compareTags),
    [patient]
  )

  return (
    <Grid container alignItems="flex-end" justifyContent="space-between">
      <Grid item>
        <PatientDetails name={patient?.details?.name} photo={patient?.details?.photo} tags={tags} />
      </Grid>
      <Grid item>
        <LinkButton
          label={t('pages.patients.selectedPatient.timeline.actions.shareProgress')}
          icon={
            <div style={{ marginTop: 7 }}>
              <ShareProgress />
            </div>
          }
          onClick={generateBeforeAfterForSelectedScans}
          disabled={!canGenerateBeforeAfter}
        />
      </Grid>
    </Grid>
  )
}

export default CompareScansHeader
