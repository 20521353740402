import { METERS_IN_KM, METERS_IN_MILE } from 'consts/mapConsts'

export const calculateZoom = radiusInMeters => {
  if (radiusInMeters > 40000) return 8
  if (radiusInMeters > 20000) return 9
  if (radiusInMeters > 10000) return 10
  return 11
}

export const calculateRadiusInMeters = (searchRadius, metricType) => {
  return metricType === 'km' ? searchRadius * METERS_IN_KM : searchRadius * METERS_IN_MILE
}

export const getPlaceZipcode = place => {
  const zipcode = place.address_components.find(component => component.types.includes('postal_code'))
  return zipcode?.long_name
}
