const MP_SET_CONTEXT_PRACTICE = 'MP_SET_CONTEXT_PRACTICE'
const HI_FETCH_ASSIGNED_DOCTORS = 'HI_FETCH_ASSIGNED_DOCTORS'
const HI_FETCH_ASSIGNED_DOCTORS_RECEIVED = 'HI_FETCH_ASSIGNED_DOCTORS_RECEIVED'
const HI_FETCH_ASSIGNED_DOCTORS_FAILED = 'HI_FETCH_ASSIGNED_DOCTORS_FAILED'
const HI_FETCH_PRACTICE_SAVED_REPLIES = 'HI_FETCH_PRACTICE_SAVED_REPLIES'
const HI_FETCH_PRACTICE_SAVED_REPLIES_RECEIVED = 'HI_FETCH_PRACTICE_SAVED_REPLIES_RECEIVED'
const HI_FETCH_PRACTICE_SAVED_REPLIES_FAILED = 'HI_FETCH_PRACTICE_SAVED_REPLIES_FAILED'
const MP_FETCH_CUSTOM_TAGS_BY_DOCTOR = 'MP_FETCH_CUSTOM_TAGS_BY_DOCTOR'
const MP_FETCH_CUSTOM_TAGS_BY_DOCTOR_RECEIVED = 'MP_FETCH_CUSTOM_TAGS_BY_DOCTOR_RECEIVED'
const MP_FETCH_CUSTOM_TAGS_BY_DOCTOR_FAILED = 'MP_FETCH_CUSTOM_TAGS_BY_DOCTOR_FAILED'
const HI_FETCH_PRACTICE_GUIDELINES = 'HI_FETCH_PRACTICE_GUIDELINES'
const HI_FETCH_PRACTICE_GUIDELINES_RECEIVED = 'HI_FETCH_PRACTICE_GUIDELINES_RECEIVED'
const HI_FETCH_PRACTICE_GUIDELINES_FAILED = 'HI_FETCH_PRACTICE_GUIDELINES_FAILED'
const MP_FETCH_PRACTICE_FFS = 'MP_FETCH_PRACTICE_FFS'
const MP_FETCH_PRACTICE_FFS_RECEIVED = 'MP_FETCH_PRACTICE_FFS_RECEIVED'
const MP_FETCH_PRACTICE_FFS_FAILED = 'MP_FETCH_PRACTICE_FFS_FAILED'

const mpFetchPracticeFFs = payload => ({
  type: MP_FETCH_PRACTICE_FFS,
  payload
})

const mpFetchPracticeFFsReceived = payload => ({
  type: MP_FETCH_PRACTICE_FFS_RECEIVED,
  payload
})

const mpFetchPracticeFFsFailed = payload => ({
  type: MP_FETCH_PRACTICE_FFS_FAILED,
  payload
})

const hiFetchPracticeGuidelines = payload => ({
  type: HI_FETCH_PRACTICE_GUIDELINES,
  payload
})

const hiFetchPracticeGuidelinesReceived = payload => ({
  type: HI_FETCH_PRACTICE_GUIDELINES_RECEIVED,
  payload
})

const hiFetchPracticeGuidelinesFailed = payload => ({
  type: HI_FETCH_PRACTICE_GUIDELINES_FAILED,
  payload
})

const mpSetContextPractice = payload => ({
  type: MP_SET_CONTEXT_PRACTICE,
  payload
})

const hiFetchPracticeSavedReplies = payload => ({
  type: HI_FETCH_PRACTICE_SAVED_REPLIES,
  payload
})

const hiFetchPracticeSavedRepliesReceived = payload => ({
  type: HI_FETCH_PRACTICE_SAVED_REPLIES_RECEIVED,
  payload
})

const hiFetchPracticeSavedRepliesFailed = payload => ({
  type: HI_FETCH_PRACTICE_SAVED_REPLIES_FAILED,
  payload
})

const hiFetchAssignedDoctors = payload => ({
  type: HI_FETCH_ASSIGNED_DOCTORS,
  payload
})

const hiFetchAssignedDoctorsReceived = payload => ({
  type: HI_FETCH_ASSIGNED_DOCTORS_RECEIVED,
  payload
})

const hiFetchAssignedDoctorsFailed = payload => ({
  type: HI_FETCH_ASSIGNED_DOCTORS_FAILED,
  payload
})

const mpFetchCustomTagsByDoctor = payload => ({
  type: MP_FETCH_CUSTOM_TAGS_BY_DOCTOR,
  payload
})

const mpFetchCustomTagsByDoctorReceived = payload => ({
  type: MP_FETCH_CUSTOM_TAGS_BY_DOCTOR_RECEIVED,
  payload
})

const mpFetchCustomTagsByDoctorFailed = payload => ({
  type: MP_FETCH_CUSTOM_TAGS_BY_DOCTOR_FAILED,
  payload
})

export default {
  MP_SET_CONTEXT_PRACTICE,
  HI_FETCH_ASSIGNED_DOCTORS,
  HI_FETCH_ASSIGNED_DOCTORS_FAILED,
  HI_FETCH_ASSIGNED_DOCTORS_RECEIVED,
  HI_FETCH_PRACTICE_SAVED_REPLIES,
  HI_FETCH_PRACTICE_SAVED_REPLIES_RECEIVED,
  HI_FETCH_PRACTICE_SAVED_REPLIES_FAILED,
  MP_FETCH_CUSTOM_TAGS_BY_DOCTOR,
  MP_FETCH_CUSTOM_TAGS_BY_DOCTOR_FAILED,
  MP_FETCH_CUSTOM_TAGS_BY_DOCTOR_RECEIVED,
  HI_FETCH_PRACTICE_GUIDELINES,
  HI_FETCH_PRACTICE_GUIDELINES_RECEIVED,
  HI_FETCH_PRACTICE_GUIDELINES_FAILED,
  MP_FETCH_PRACTICE_FFS,
  MP_FETCH_PRACTICE_FFS_RECEIVED,
  MP_FETCH_PRACTICE_FFS_FAILED,
  mpFetchPracticeFFs,
  mpFetchPracticeFFsReceived,
  mpFetchPracticeFFsFailed,
  hiFetchPracticeGuidelines,
  hiFetchPracticeGuidelinesReceived,
  hiFetchPracticeGuidelinesFailed,
  mpSetContextPractice,
  hiFetchAssignedDoctors,
  hiFetchAssignedDoctorsFailed,
  hiFetchAssignedDoctorsReceived,
  hiFetchPracticeSavedReplies,
  hiFetchPracticeSavedRepliesReceived,
  hiFetchPracticeSavedRepliesFailed,
  mpFetchCustomTagsByDoctor,
  mpFetchCustomTagsByDoctorFailed,
  mpFetchCustomTagsByDoctorReceived
}
