import { InputBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback } from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '8px 14px',
    borderBottom: '1px solid var(--border-color-7)'
  },
  input: {
    fontFamily: 'Dazzed',
    color: theme.palette.primary.main
  }
}))

const TagsPickerInput = ({ value, setValue, onEnterPressed = () => {} }) => {
  const classes = useStyles()

  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        onEnterPressed()
      }
    },
    [onEnterPressed]
  )

  return (
    <div className={classes.root}>
      &#8288;
      <InputBase
        autoFocus
        value={value}
        onKeyPress={handleKeyPress}
        onChange={e => setValue(e.target.value)}
        className={classes.input}
      />
    </div>
  )
}

export default TagsPickerInput
