import * as React from 'react'

const Flag = ({ color = '#3C52EF', ...props }) => {
  return (
    <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.5 20.1074V1.96875C0.5 1.1343 1.1383 0.5 1.875 0.5H13.125C13.8617 0.5 14.5 1.1343 14.5 1.96875V20.1074L7.76116 15.9799L7.5 15.8199L7.23884 15.9799L0.5 20.1074Z"
        stroke={color}
      />
    </svg>
  )
}

export default Flag
