import * as React from 'react'

export default () => {
  return (
    <svg width="41" height="26" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 2.97419C0 1.88388 0.883877 1 1.97419 1L9.87097 1V8.89677H18V23.5058C18 24.5961 17.1161 25.48 16.0258 25.48H1.97419C0.883877 25.48 0 24.5961 0 23.5058V2.97419Z"
        fill="#8FB4FF"
      />
      <path d="M9.37 1.5L17.2668 9.39677" stroke="#8FB4FF" strokeWidth="1.97419" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1924 25.2886L36.1924 25.2886L36.1924 11.0962L40.1924 11.0962L31.0962 1.99999L22 11.0962L26.1924 11.0962L26.1924 25.2886Z"
        fill="url(#paint0_linear_5_104)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_104"
          x1="31.0962"
          y1="23.7748"
          x2="31.0962"
          y2="7.28857"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#61E2AC" stopOpacity="0" />
          <stop offset="1" stopColor="#61E2AC" />
        </linearGradient>
      </defs>
    </svg>
  )
}
