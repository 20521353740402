import { TIME_FORMAT_12 } from './dateTimeConsts'

export const ALIGNERS_TREATMENT_TYPE = 'Aligners'
export const BRACES_TREATMENT_TYPE = 'Braces'
export const OTHER_TREATMENT_TYPE = 'Other'

export const TreatmentTypes = {
  Aligners: ALIGNERS_TREATMENT_TYPE,
  Braces: BRACES_TREATMENT_TYPE,
  Other: OTHER_TREATMENT_TYPE
}

export const CYCLE_END_DATE_FORMAT = TIME_FORMAT_12

export const APPLIANCE_SETS_MIN_NUMBER = 1
export const APPLIANCE_SETS_MAX_NUMBER = 99

export const APPLIANCE_MIN_NUMBER = 0
export const APPLIANCE_MAX_NUMBER = 99

export const CycleStatuses = {
  Active: 'active',
  Paused: 'paused'
}
