import { Grid, makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Book } from 'components/common/icons'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: 10
  },
  item: {
    cursor: 'pointer',
    textDecorationColor: 'var(--text-color-25)',
    textDecoration: 'underline'
  },
  icon: {
    marginRight: 5
  }
}))

const ServiceAccountGuidance = ({ spacing = 3, guidancesTypes = [], analyticSource }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const guidances = useMemo(
    () => [
      {
        type: 'howDoesItWork',
        text: t('pages.accountSettings.services.serviceAccount.guidance1'),
        onClick: () => {
          trackEvent('RecordsApp guidance clicked', { analyticSource, guidanceType: 'howDoesItWork' })
          window.open('https://www.get-grin.com/records-app', '_blank')
        }
      }
    ],
    [analyticSource, t]
  )

  return (
    <Grid container spacing={spacing} className={classes.container}>
      {guidances
        .filter(guidance => guidancesTypes.includes(guidance.type))
        .map(guidance => (
          <Grid item key={guidance.text} onClick={guidance.onClick}>
            <Grid container alignItems="center" className={classes.item}>
              <Grid item>
                <Book className={classes.icon} />
              </Grid>
              <Grid item>
                <DazzedParagraph14 bold color="var(--text-color-25)">
                  {guidance.text}
                </DazzedParagraph14>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  )
}

export default ServiceAccountGuidance
