import moment from 'moment'
import { groupBy } from 'ramda'
import { getAggregateScans } from './scanUtils'

export const createTimelineItems = (grinScans, timelineEventLog) => {
  const scansTimeLineItems = createScansTimeLineItems(grinScans)
  const eventsTimeLineItems = createEventsTimeLineItems(timelineEventLog)

  const timelineItems = [...scansTimeLineItems, ...eventsTimeLineItems].sort(
    (a, b) => moment(a.timestamp) - moment(b.timestamp)
  )

  const groupByMonth = groupBy(timelineItem => moment(timelineItem.timestamp).format('YYYY-MM'))

  const timelineItemsByMonth = groupByMonth(timelineItems)

  const monthsWithTimelineItems = Object.keys(timelineItemsByMonth).map(date => ({
    monthName: moment(date).format('MMMM'),
    year: moment(date).format('YYYY'),
    shouldExpand: !!timelineItemsByMonth[date].find(timelineItem => timelineItem.data.shouldExpand),
    timelineItems: timelineItemsByMonth[date]
  }))
  const groupByYear = groupBy(monthsWithTimelineItem => monthsWithTimelineItem.year)

  const yearsWithTimeLineItems = groupByYear(monthsWithTimelineItems)

  return yearsWithTimeLineItems
}

export const createScansTimeLineItems = scans => {
  if (!scans) {
    return []
  }

  const aggregatedScans = getAggregateScans(scans)
  const scansTimeLineItems = aggregatedScans.map(currPair => ({
    type: 'scan',
    timestamp:
      currPair.length === 2
        ? new Date(currPair[0]?.createdAt) > new Date(currPair[1]?.createdAt)
          ? currPair[0]?.createdAt
          : currPair[1]?.createdAt
        : currPair[0].createdAt,
    data: {
      scans: currPair
    }
  }))

  const sortedIndexedScansTimeLineItems = scansTimeLineItems.sort((a, b) => moment(a.timestamp) - moment(b.timestamp))

  for (let i = 0; i < scansTimeLineItems.length; i++) {
    sortedIndexedScansTimeLineItems[i].data.index = i + 1
  }

  if (sortedIndexedScansTimeLineItems.length > 0)
    scansTimeLineItems[scansTimeLineItems.length - 1].data.shouldExpand = true
  if (sortedIndexedScansTimeLineItems.length > 1)
    scansTimeLineItems[scansTimeLineItems.length - 2].data.shouldExpand = true

  return sortedIndexedScansTimeLineItems || []
}

const createEventsTimeLineItems = timelineEventLog => {
  const eventsTimeLineItems =
    timelineEventLog?.map(event => ({
      type: 'event',
      timestamp: event.timestamp,
      data: event
    })) || []

  return eventsTimeLineItems
}
