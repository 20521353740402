import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  upgradeTag: {
    padding: 5,
    marginLeft: 3,
    color: 'white',
    backgroundColor: '#2134BF',
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 600
  }
}))

const UpgradeTag = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return <span className={classes.upgradeTag}>{t('general.upgrade').toUpperCase()}</span>
}

export default UpgradeTag
