import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionItemTypes } from 'consts/appConsts'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import moment from 'moment-timezone'
import {
  TreatmentPlan,
  UpdateApplianceArrival,
  ScanReview,
  ScanSettings,
  ScheduleVisit,
  Upload,
  Mail,
  Dollar
} from 'components/common/icons'

import { ROUTES } from 'consts'
import { PATIENT_CARD_TABS } from 'consts/patientCard'
import Actions from 'actions'

export default (props = {}) => {
  const { lastActionItemType, patientId, blueIcon, patientName, actionItems = [] } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const practiceTimezone = useSelector(state => state.practiceReducer.details.practiceTimezone)
  const iconColor = useMemo(() => (blueIcon ? 'var(--bg-color-43)' : 'var(--bg-color-42)'), [blueIcon])

  const openPatientScanReview = useCallback(() => {
    const reviewScanActionItem = actionItems.find(
      actionItem =>
        actionItem.type === ActionItemTypes.REVIEW_SCAN || actionItem.type === ActionItemTypes.REVIEW_FIRST_SCAN
    )

    const params = JSON.parse(reviewScanActionItem?.params || '{}')

    if (params.originEntityType !== 'GrinScan' || !params.originEntityId) {
      window.location.href = `${window.location.origin}${ROUTES.PATIENTS}/${patientId}?openScanReview=true`
      return
    }

    dispatch(
      Actions.fetchScanForReview({
        id: params.originEntityId
      })
    )
  }, [actionItems, patientId, dispatch])

  const openChatWithPatient = useCallback(() => history.push(`${ROUTES.PATIENTS}/${patientId}`), [history, patientId])

  const openPatientCard = useCallback(
    selectedTab => {
      dispatch(
        Actions.togglePatientCard({
          isModalOpen: true,
          patientId,
          selectedTab
        })
      )
    },
    [dispatch, patientId]
  )

  const openUpdateApplianceArrivalModal = useCallback(() => {
    dispatch(Actions.toggleApplianceArrivalModal({ isOpen: true, patient: { name: patientName, id: patientId } }))
  }, [dispatch, patientId, patientName])

  const actionRequiredParams = useCallback(
    actionItemType => {
      const actionItem = actionItems.find(actionItem => actionItem.type === actionItemType)
      const actionItemParams = JSON.parse(actionItem?.params || '{}')

      switch (actionItemType) {
        case ActionItemTypes.TREATMENT_PROPOSAL_REQUIRED:
          return {
            disabled: !!actionItemParams?.originEntityId,
            subtitle:
              actionItemParams?.proposalStatus &&
              t(`pages.taskManager.treatmentProposalRequiredStatuses.${actionItemParams.proposalStatus}`)
          }
        case ActionItemTypes.SET_SCAN_SETTINGS:
        case ActionItemTypes.UPLOAD_PATIENT_SCANS:
          const isPendingForAppointment =
            practiceTimezone &&
            moment(actionItemParams?.firstAppointmentDate).diff(moment().tz(practiceTimezone).startOf('day'), 'days') >
              0

          return {
            subtitle: isPendingForAppointment && t(`pages.taskManager.${actionItemType}.pending`)
          }

        default:
          return {}
      }
    },
    [actionItems, practiceTimezone, t]
  )

  const tasks = useMemo(
    () => [
      {
        Icon: ({ disabled }) => <Mail color={disabled ? 'var(--text-color-11)' : iconColor} />,
        label: t('pages.taskManager.actions.newMessage'),
        value: ActionItemTypes.NEW_MESSAGE,
        handleClick: openChatWithPatient
      },
      {
        Icon: ({ disabled }) => <ScanReview color={disabled ? 'var(--text-color-11)' : iconColor} />,
        label: t('pages.taskManager.actions.reviewScan'),
        value: ActionItemTypes.REVIEW_FIRST_SCAN,
        handleClick: openPatientScanReview
      },
      {
        Icon: ({ disabled }) => <ScanReview color={disabled ? 'var(--text-color-11)' : iconColor} />,
        label: t('pages.taskManager.actions.reviewScan'),
        value: ActionItemTypes.REVIEW_SCAN,
        handleClick: openPatientScanReview
      },
      {
        Icon: ({ disabled }) => <Dollar color={disabled ? 'var(--text-color-11)' : iconColor} />,
        label: t('pages.taskManager.actions.treatmentProposalRequired'),
        value: ActionItemTypes.TREATMENT_PROPOSAL_REQUIRED,
        handleClick: () => openPatientCard(PATIENT_CARD_TABS.prelimPlan.index)
      },
      {
        Icon: ({ disabled }) => <ScheduleVisit color={disabled ? 'var(--text-color-11)' : iconColor} />,
        label: t('pages.taskManager.actions.scheduleVisit'),
        value: ActionItemTypes.SCHEDULE_VISIT,
        handleClick: () => openPatientCard(PATIENT_CARD_TABS.appointments.index)
      },
      {
        Icon: ({ disabled }) => <Upload color={disabled ? 'var(--text-color-11)' : iconColor} />,
        label: t('pages.taskManager.actions.uploadPatientScans'),
        value: ActionItemTypes.UPLOAD_PATIENT_SCANS,
        handleClick: () => openPatientCard(PATIENT_CARD_TABS.records.index)
      },
      {
        Icon: ({ disabled }) => <TreatmentPlan color={disabled ? 'var(--text-color-11)' : iconColor} />,
        label: t('pages.taskManager.actions.treatmentPlanRequired'),
        value: ActionItemTypes.TREATMENT_PLAN_REQUIRED,
        handleClick: () => openPatientCard(PATIENT_CARD_TABS.treatmentPlan.index)
      },
      {
        Icon: ({ disabled }) => <ScanSettings color={disabled ? 'var(--text-color-11)' : iconColor} />,
        label: t('pages.taskManager.actions.setScanSettings'),
        value: ActionItemTypes.SET_SCAN_SETTINGS,
        handleClick: () => openPatientCard(PATIENT_CARD_TABS.scanSettings.index)
      },
      {
        Icon: ({ disabled }) => <UpdateApplianceArrival color={disabled ? 'var(--text-color-11)' : iconColor} />,
        label: t('pages.taskManager.actions.updateApplianceArrival'),
        value: ActionItemTypes.UPDATE_APPLIANCE_ARRIVAL,
        handleClick: openUpdateApplianceArrivalModal
      }
    ],
    [iconColor, openChatWithPatient, openPatientCard, openPatientScanReview, openUpdateApplianceArrivalModal, t]
  )
  const currentTask = useMemo(() => {
    const requiredParams = actionRequiredParams(lastActionItemType)
    return {
      ...(tasks.find(task => task.value === lastActionItemType) || {}),
      ...requiredParams
    }
  }, [actionRequiredParams, lastActionItemType, tasks])

  return {
    tasks,
    currentTask
  }
}
