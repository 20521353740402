import React, { useEffect } from 'react'
import Actions from 'actions'
import { makeStyles } from '@material-ui/styles'
import Notes from 'components/NotesTab/Notes'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStatus } from 'consts'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    padding: 10
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const PatientNotes = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { data: notes, status } = useSelector(state => state.patientsReducer.notes)
  const { user } = useSelector(state => state.patientsReducer.patient)

  useEffect(() => {
    if (!notes && !status && user?.id) {
      dispatch(
        Actions.fetchPatientNotes({
          patientGrinUserId: user.id
        })
      )
    }
  }, [dispatch, notes, status, user])

  return (
    <div className={classes.container}>
      {status === AsyncStatus.Loading ? (
        <div className={classes.loader}>
          <CircularProgress size={24} />
        </div>
      ) : (
        <Notes list={notes || []} editable={false} />
      )}
    </div>
  )
}

export default PatientNotes
