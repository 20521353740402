import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Grid, CircularProgress } from '@material-ui/core'
import { createTimelineItems } from '../../../../utils/timelineUtils'
import TimeLineItemsByYear from './TimeLineItemsByYear'
import { useTranslation } from 'react-i18next'
import { Element, scroller } from 'react-scroll'
import usePatientBanner from 'hooks/usePatientBanner'
import Banner from '../Banner'
import { scrollBar } from 'styles/theme'
import actions from 'actions'
import { BannerTypes } from 'consts/chatConsts'
import ScanSummaryModal from '../ScanSummary/ScanSummaryModal/ScanSummaryModal'

const useStyles = ({ bannerType }) =>
  makeStyles({
    containers: {
      flex: '1 1 1%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      fontSize: 30,
      textAlign: 'left',
      color: 'var(--text-color-11)',
      position: 'relative',
      overflowY: 'auto',
      overflowX: 'hidden',
      height: `calc(100% - ${
        bannerType
          ? bannerType === BannerTypes.Overdue
            ? '32px'
            : bannerType === BannerTypes.TreatmentTypeCTA
            ? '67px'
            : bannerType === BannerTypes.DisabledPatient
            ? '70px'
            : '0px'
          : '0px'
      })`,
      padding: '0 4.2666vw',
      ...scrollBar
    },
    noScansContainer: {
      justifyContent: 'center'
    },
    noScans: {
      textAlign: 'center',
      height: '90%',
      fontSize: 14,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    overlayLoader: {
      height: '100%'
    }
  })({ bannerType })

const Timeline = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const bannerType = usePatientBanner()
  const grinScans = useSelector(state => state.treatmentReducer.grinScans)
  const treatment = useSelector(state => state.treatmentReducer.treatment)
  const { patient, fetchedScansLeadId } = useSelector(state => state.patientsReducer)
  const isLoading = useSelector(state => state.patientsReducer.isLoadingPatient)

  const classes = useStyles({ bannerType })

  const yearsWithTimelineItems = useMemo(
    () => createTimelineItems(grinScans || [], treatment?.timelineEventLog || []),
    [grinScans, treatment]
  )

  const isScansAndEventlogsLoaded = useMemo(
    () => Array.isArray(grinScans) && Array.isArray(treatment?.timelineEventLog),
    [grinScans, treatment]
  )

  useEffect(() => {
    if (isScansAndEventlogsLoaded) {
      scroller.scrollTo('bottomRef', {
        duration: 700,
        delay: 1500,
        smooth: 'linear',
        containerId: 'timelineContainer',
        offset: 400
      })
    }
  }, [isScansAndEventlogsLoaded])

  useEffect(() => {
    if (patient?.id && !patient?.grinScans?.items?.length && fetchedScansLeadId !== patient.id) {
      dispatch(actions.fetchLeadScans({ leadId: patient.id }))
    }
  }, [dispatch, fetchedScansLeadId, patient])

  return (
    <>
      {bannerType && <Banner type={bannerType} />}
      {isLoading ? (
        <Grid container justifyContent="center" alignItems="center" className={classes.overlayLoader}>
          <CircularProgress />
        </Grid>
      ) : (
        <Element
          className={[classes.containers, !patient.grinScans?.items?.length && classes.noScansContainer].join(' ')}
          id="timelineContainer"
        >
          <Grid>
            {patient.grinScans?.items?.length ? (
              Object.entries(yearsWithTimelineItems).map(([year, monthsTimelineItems]) => (
                <Grid item key={year}>
                  <TimeLineItemsByYear year={year} monthsTimelineItems={monthsTimelineItems} />
                </Grid>
              ))
            ) : (
              <div className={classes.noScans}>{t('pages.patients.selectedPatient.timeline.noScansYet')}</div>
            )}
          </Grid>
          <Element name="bottomRef" />
        </Element>
      )}
      <ScanSummaryModal />
    </>
  )
}

export default Timeline
