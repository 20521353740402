import React from 'react'
import BaseModal from 'components/common/modals/BaseModal'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { Grid, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles({
  body: {
    paddingLeft: isMobile() ? 40 : 0,
    paddingRight: isMobile() ? 40 : 0
  }
})

const BroadcastConfirmationModal = ({ onCancel, onSend }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { isLoading, isConfirmationModalOpen, selectedPatients, allSelected, isPersonalMessage } = useSelector(
    state => state.chatReducer.broadcast
  )

  return (
    <BaseModal
      open={isConfirmationModalOpen}
      handleClose={onCancel}
      onSecondaryBtnClick={onCancel}
      onPrimaryBtnClick={onSend}
      primaryLabel={t('general.send')}
      secondaryLabel={t('general.cancel')}
      title={t('dialogs.broadcast.title')}
      variant="alert"
      largerButtons={!isMobile()}
      withCloseIcon={!isMobile()}
      isLoading={isLoading}
      primaryButtonId="sendBroadcast-send"
      secondaryButtonId="sendBroadcast-cancel"
    >
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <DazzedParagraph16 textAlign="center" className={classes.body}>
            {t(isPersonalMessage ? 'dialogs.broadcast.subtitlePersonal' : 'dialogs.broadcast.subtitle', {
              amount: allSelected ? t('dialogs.broadcast.allSelected') : selectedPatients.length,
              s: selectedPatients.length > 1 || allSelected ? 's' : ''
            })}
          </DazzedParagraph16>
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default BroadcastConfirmationModal
