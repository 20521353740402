import Actions from '../actions'
import { US_STATES, DEFAULT_COUNTRY } from '../consts/countryConsts'
import { getPracticeInfoDraft } from '../utils/storageUtils'
import { changeAuthForm } from '../utils/authUtils'

const initialState = {
  redirectAfterAuth: null,
  isLoading: false,
  errors: {},
  confirmationCode: '',
  resentConfirmationCode: false,
  name: '',
  firstName: '',
  lastName: '',
  roleDescription: '',
  photo: null,
  clinicLogo: null,
  degrees: null,
  emailAddress: '',
  password: '',
  passwordConfirmation: '',
  username: null,
  practiceName: '',
  address: '',
  address2: '',
  city: '',
  state: US_STATES[0].name,
  country: DEFAULT_COUNTRY.key,
  zip: '',
  phoneValue: '',
  countryCode: '',
  practicePhoneValue: '',
  practiceCountryCode: '',
  practiceEmailAddress: '',
  clinicHomepageUrl: '',
  hasLocatorConsent: true,
  bio: '',
  program: '',
  requireBillingInSignup: false,
  billingInfo: {
    paymentMethodId: '',
    cardLast4: '',
    cardBrand: ''
  },
  hasResumedOnboarding: false,
  rcRegister: {
    isLoading: true,
    error: null,
    doctorDetails: {},
    rcToken: null,
    rcColors: {},
    rcLPBannerPhoto: {}
  },
  practiceMembers: {
    isLoading: false,
    errors: {},
    email: '',
    password: '',
    passwordConfirmation: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    countryCode: '',
    roleDescription: '',
    photo: null,
    country: ''
  },
  mfa: {
    user: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.SIGNOUT_RECEIVED:
      return initialState
    case Actions.SIGNUP_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case Actions.SIGNUP_RECEIVED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.CONFIRM_RESEND_SIGNUP_REQUESTED:
      return {
        ...state,
        resentConfirmationCode: false
      }
    case Actions.CONFIRM_RESEND_SIGNUP_RECEIVED:
      return {
        ...state,
        resentConfirmationCode: true
      }
    case Actions.CONFIRM_SIGNUP_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case Actions.CONFIRM_SIGNUP_RECEIVED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.CREATE_DOCTOR_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case Actions.FETCH_REJECTED:
    case Actions.CREATE_DOCTOR_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.SIGNIN_RECEIVED:
      return {
        ...state,
        username: action.payload?.username
      }
    case Actions.SIGNIN_SIDE_EFFECTS_COMPLETED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.SIGNIN_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case Actions.AUTH_FORM_CHANGED:
      const newValues = changeAuthForm(action.payload)
      return {
        ...state,
        ...newValues
      }
    case Actions.BILLING_INFO_FORM_CHANGED:
      const newBillingValues = changeAuthForm(action.payload)
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          ...newBillingValues
        }
      }
    case Actions.RESET_PASSWORD_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case Actions.RESET_PASSWORD_RECEIVED:
      return {
        ...state,
        errors: initialState.errors,
        isLoading: false
      }
    case Actions.RESET_PASSWORD_SUBMIT_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case Actions.RESET_PASSWORD_SUBMIT_RECEIVED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.NOT_ALLOWED_DOCTOR_RECEIVED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.ACCOUNT_INFO_COMPLETED:
      return {
        ...state,
        isLoading: true
      }
    case Actions.SIGNUP_ERRORS_UPDATED:
    case Actions.RESET_PASSWORD_ERRORS_UPDATED:
      return {
        ...state,
        isLoading: false,
        errors: { ...action.payload }
      }
    case Actions.SET_POST_AUTH_LOCATION:
      return {
        ...state,
        redirectAfterAuth: action.payload
      }
    case Actions.RESUME_DOCTOR_ONBOARDING:
      return {
        ...state,
        emailAddress: action.payload.email,
        requireBillingInSignup: action.payload.requireBillingInSignup,
        hasResumedOnboarding: true,
        isLoading: false
      }
    case Actions.LOAD_PRACTICE_INFO_DRAFT:
      const draft = getPracticeInfoDraft()
      return {
        ...state,
        ...draft
      }
    case Actions.REGISTER_RC_USER_REQUESTED:
      return {
        ...state,
        rcRegister: {
          ...state.rcRegister,
          isLoading: true,
          error: null
        }
      }
    case Actions.REGISTER_RC_USER_FAILED:
      return {
        ...state,
        rcRegister: {
          ...state.rcRegister,
          isLoading: false,
          error: action.payload
        }
      }
    case Actions.REGISTER_RC_USER_RECEIVED:
      return {
        ...state,
        rcRegister: {
          ...state.rcRegister,
          isLoading: false,
          error: null
        }
      }
    case Actions.REQUEST_DOCTOR_PUBLIC_DETAILS:
      return {
        ...state,
        rcRegister: {
          ...state.rcRegister,
          isLoading: true,
          rcToken: action.payload
        }
      }
    case Actions.DOCTOR_PUBLIC_DETAILS_RECEIVED:
      return {
        ...state,
        rcRegister: {
          ...state.rcRegister,
          isLoading: false,
          doctorDetails: action.payload
        }
      }
    case Actions.DOCTOR_PUBLIC_DETAILS_FAILED:
      return {
        ...state,
        rcRegister: {
          ...state.rcRegister,
          isLoading: false
        }
      }
    case Actions.CHANGE_PRACTICE_MEMBERS_AUTH_FORM:
      const updatedValues = changeAuthForm(action.payload)
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          ...updatedValues
        }
      }
    case Actions.CREATE_PRACTICE_MEMBER:
    case Actions.PRACTICE_MEMBERS_SIGNUP:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          isLoading: true,
          errors: initialState.practiceMembers.errors
        }
      }
    case Actions.PRACTICE_MEMBERS_SIGNUP_FAILED:
    case Actions.CREATE_PRACTICE_MEMBER_FAILED:
    case Actions.CREATE_PRACTICE_MEMBER_RECEIVED:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          isLoading: false
        }
      }
    case Actions.PRACTICE_MEMBERS_POST_SIGNUP_COMPLETED:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          isLoading: false
        }
      }
    case Actions.UPDATE_PRACTICE_MEMBERS_SIGNUP_ERRORS:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          isLoading: false,
          errors: { ...action.payload }
        }
      }
    case Actions.RESET_PRACTICE_MEMBERS_SIGNUP_ERRORS:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          errors: initialState.practiceMembers.errors
        }
      }
    case Actions.SET_USER_FOR_MFA:
      return {
        ...state,
        mfa: {
          ...state.mfa,
          user: action.payload
        }
      }
    default:
      return state
  }
}
