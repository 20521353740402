import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles, Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import SelectedStatus from 'components/Patients/Statuses/SelectedStatus'
import { Warn } from 'components/common/icons'

const useStyles = () =>
  makeStyles({
    description: {
      maxWidth: 450,
      margin: '0 auto',
      '& span': {
        fontWeight: 'bold'
      }
    },
    btnsContainer: {
      marginTop: 30
    },
    untransferrable: {
      color: 'red'
    },
    divider: {
      margin: '10px 0px 10px 0px',
      width: '100%',
      height: 1,
      backgroundColor: 'var(--bg-color-33)'
    }
  })()

export default ({
  patientId,
  patientProgram,
  onConfirmTransfer,
  onClickCancel,
  isLoading,
  isValidPatientApp,
  isValidDoctorsSubscription
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const statuses = useSelector(state => state.treatmentReducer.statuses.data)
  const { selectedPatientLinkedAccounts: { data: selectedPatientLinkedAccounts } = { data: [] } } = useSelector(
    state => state.supportReducer.patientsDashboard.selectedPatient
  )

  const activeLinkedPatients = useMemo(
    () => selectedPatientLinkedAccounts.filter(patientSM => !!patientSM?.username && patientSM.id !== patientId),
    [patientId, selectedPatientLinkedAccounts]
  )
  const status = useMemo(
    () => statuses[patientProgram]?.find(status => status.type === 'transferred'),
    [patientProgram, statuses]
  )
  const statusSettings = useMemo(() => JSON.parse(status?.settings || '{}'), [status])
  const patientHasActiveSiblings = useMemo(() => activeLinkedPatients.length > 0, [activeLinkedPatients.length])
  const patientLinkedLeads = useMemo(
    () => selectedPatientLinkedAccounts.filter(patientSM => !patientSM?.username),
    [selectedPatientLinkedAccounts]
  )
  const patientHasLinkedLeads = useMemo(() => patientLinkedLeads.length > 0, [patientLinkedLeads.length])
  const isTransferrable = useMemo(
    () => (isValidPatientApp && isValidDoctorsSubscription) || patientHasLinkedLeads,
    [isValidDoctorsSubscription, isValidPatientApp, patientHasLinkedLeads]
  )

  return (
    <Grid container direction="column" justifyContent="center" spacing={2}>
      <Grid item>
        <SelectedStatus type={status?.type} color={statusSettings.color} />
      </Grid>
      <Grid item>
        <DazzedParagraph14 textAlign="center" className={classes.description}>
          {t('dialogs.transferPatient.statusDescription1')}
          <span>&nbsp;{t('dialogs.transferPatient.statusDescription2')}&nbsp;</span>
          {t('dialogs.transferPatient.statusDescription3')}
          <span>&nbsp;{t('dialogs.transferPatient.statusDescription4')}</span>
        </DazzedParagraph14>
      </Grid>
      {isTransferrable && patientHasActiveSiblings && (
        <>
          <div className={classes.divider} />
          <Grid item>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Warn />
              </Grid>
              <Grid item>
                <DazzedParagraph14 bold>{t('dialogs.transferPatient.transferNote')}</DazzedParagraph14>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
              {activeLinkedPatients.map((currPatientSM, index) => (
                <Grid item key={currPatientSM.id}>
                  <DazzedParagraph14 strong>{`${currPatientSM.patient.details.name}${
                    index < activeLinkedPatients.length - 1 ? ', ' : ''
                  }`}</DazzedParagraph14>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )}
      <Grid item>
        <Grid container justifyContent="center" spacing={2} className={classes.btnsContainer}>
          <Grid item>
            <SecondaryButton label={t('general.cancel')} onClick={onClickCancel} />
          </Grid>
          <Grid item>
            <PrimaryButton
              label={t('dialogs.transferPatient.confirmTransfer')}
              onClick={onConfirmTransfer}
              isLoading={isLoading}
              disabled={!isTransferrable}
            />
          </Grid>
          {!isTransferrable && (
            <Grid item>
              <DazzedParagraph14 textAlign="center" className={classes.untransferrable}>
                {patientHasLinkedLeads
                  ? t('dialogs.transferPatient.hasLinkedLeads', {
                      leadsNames: patientLinkedLeads.map(patientSM => patientSM.patient.details.name).join(', ')
                    })
                  : !isValidDoctorsSubscription
                  ? t('dialogs.transferPatient.invalidDoctorsPlan')
                  : t('dialogs.transferPatient.invalidPatientAppVersion')}
              </DazzedParagraph14>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
