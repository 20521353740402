import React, { useCallback } from 'react'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import Actions from 'actions'

const useStyles = makeStyles(theme => ({
  modalDescription: {
    marginTop: 40,
    marginBottom: 10
  },
  modal: {
    width: 'auto !important'
  },
  modalContent: {
    padding: '0 47px 40px 47px !important'
  },
  modalButton: {
    width: 'auto !important'
  }
}))
const ServiceAccountDeactivateModal = ({ isOpen, isLoading, onClose, serviceAccountEmail }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onDeactivatePressed = useCallback(() => {
    trackEvent('Service account - deactivate modal - deactivate account pressed')
    dispatch(Actions.deactivateServiceAccount())
  }, [dispatch])

  return (
    <BaseModal
      open={isOpen}
      title={t('pages.accountSettings.services.serviceAccount.modals.deactivate.title')}
      variant="alert"
      isLoading={isLoading}
      onPrimaryBtnClick={onDeactivatePressed}
      onSecondaryBtnClick={onClose}
      primaryLabel={t('pages.accountSettings.services.serviceAccount.modals.deactivate.deactivateAccount')}
      secondaryLabel={t('general.cancel')}
      handleClose={onClose}
      className={classes.modal}
      contentClassName={classes.modalContent}
      largerPrimaryButton={true}
    >
      <DazzedParagraph16 textAlign="center">
        {t('pages.accountSettings.services.serviceAccount.modals.deactivate.description')}
      </DazzedParagraph16>
      <DazzedParagraph16 className={classes.modalDescription} bold textAlign="center">
        {serviceAccountEmail}
      </DazzedParagraph16>
    </BaseModal>
  )
}

export default ServiceAccountDeactivateModal
