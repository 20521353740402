import { ClickAwayListener, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PatientBriefFloatingIcon from 'components/common/icons/PatientBriefFloatingIcon'
import PatientBriefUnreadFloatingIcon from 'components/common/icons/PatientBriefUnreadFloatingIcon'
import GrinFloatingPopup from 'components/common/menu/GrinFloatingPopup'
import React, { useCallback, useState } from 'react'
import PatientBriefQuickActionsContentMobile from './PatientBriefQuickActionsContent.mobile'
import { trackEvent } from 'utils/analyticsUtils'
import useRolePermissions from 'hooks/useRolePermissions'

const useStyles = makeStyles(theme => ({
  container: {
    zIndex: 900,
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer'
  },
  floatingPopup: {
    width: '82vw',
    padding: '0px 15px'
  },
  tooltip: {
    background: 'white'
  }
}))

const PatientBriefQuickActionsButtonMobile = ({ needsAttentionTagDetails, patientId, practiceName }) => {
  const classes = useStyles()
  const { isHiUser } = useRolePermissions()
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const { reason, note } = needsAttentionTagDetails || {}

  const handlePatientBriefQuickActionsButtonClick = useCallback(
    () =>
      trackEvent('Patient Brief Quick Actions - Button Clicked', {
        action: tooltipOpen ? 'close' : 'open',
        isHiUser,
        practiceName,
        patientId
      }),
    [isHiUser, tooltipOpen, practiceName, patientId]
  )

  const handlePatientBriefQuickActionsPopupClosed = useCallback(
    () => trackEvent('Patient Brief Quick Actions - closed'),
    []
  )

  return (
    <Grid className={classes.container} onClick={() => setTooltipOpen(!tooltipOpen)}>
      <Grid item>
        <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
          <div>
            <GrinFloatingPopup
              containerClassName={classes.floatingPopup}
              placement="left-start"
              automaticallyOpen={!isHiUser && !!needsAttentionTagDetails}
              triggerComponent={
                <div onClick={handlePatientBriefQuickActionsButtonClick}>
                  {needsAttentionTagDetails ? (
                    <PatientBriefUnreadFloatingIcon
                      style={{ boxShadow: '0px 2px 24px -7px #0000004D', borderRadius: 50 }}
                    />
                  ) : (
                    <PatientBriefFloatingIcon style={{ boxShadow: '0px 2px 24px -7px #0000004D', borderRadius: 50 }} />
                  )}
                </div>
              }
              open={tooltipOpen}
              onClose={handlePatientBriefQuickActionsPopupClosed}
              withCloseIcon={false}
            >
              <PatientBriefQuickActionsContentMobile
                reason={reason}
                note={note}
                patientId={patientId}
                needsAttentionTagApplied={!!needsAttentionTagDetails}
              />
            </GrinFloatingPopup>
          </div>
        </ClickAwayListener>
      </Grid>
    </Grid>
  )
}

export default PatientBriefQuickActionsButtonMobile
