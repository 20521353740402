import Actions from 'actions'
import jwtDecode from 'jwt-decode'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useQueryParams from 'hooks/useQueryParams'
import { ReferralsSessionKey, ReferralsTokenKey } from 'consts/referralConsts'

export default () => {
  const { queryParams } = useQueryParams()
  const dispatch = useDispatch()

  const { destination, cooldownTime } = useSelector(state => state.referralsReducer.authorization)
  const referralId = useMemo(() => queryParams.referralId, [queryParams])

  const getToken = useCallback(() => {
    const token = sessionStorage.getItem(ReferralsTokenKey)
    if (!token) {
      return null
    }

    const decodedToken = jwtDecode(token)
    const isExpired = decodedToken.exp <= new Date().getTime() / 1000

    if (decodedToken.referralId !== referralId) {
      sessionStorage.removeItem(ReferralsTokenKey)
      return null
    }

    return {
      raw: token,
      decoded: decodedToken,
      isExpired
    }
  }, [referralId])

  const getCachedSession = useCallback(() => {
    const session = sessionStorage.getItem(ReferralsSessionKey)
    if (session) {
      return JSON.parse(session)
    }

    return null
  }, [])

  const getSessionId = useCallback(() => getCachedSession()?.sessionId, [getCachedSession])

  const requestVerificationCode = useCallback(() => {
    const session = getCachedSession()
    if (session) {
      dispatch(
        Actions.referralsResendCode({
          sessionId: session.sessionId,
          cooldownTime: session.cooldownTime,
          destination: session.destination,
          referralId
        })
      )
    } else {
      dispatch(
        Actions.referralsFetchReferralData({
          referralId
        })
      )
    }
  }, [dispatch, referralId, getCachedSession])

  const verifyCode = useCallback(
    ({ password }) => {
      dispatch(
        Actions.referralsVerifyCode({
          sessionId: getSessionId(),
          code: password
        })
      )
    },
    [dispatch, getSessionId]
  )

  const completeAuth = useCallback(() => {
    const token = getToken()
    if (token) {
      dispatch(Actions.referralsAuthComplete({ token: token.raw }))
      return true
    } else {
      return false
    }
  }, [getToken, dispatch])

  return {
    otp: {
      destination,
      cooldownTime
    },
    requestVerificationCode,
    verifyCode,
    completeAuth,
    getToken
  }
}
