import Actions from 'actions'
import ImagesViewer from 'components/common/ImagesViewer'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DeletePhotoModal from './DeletePhotoModal'
import RecordsSetHeader from './RecordsSetHeader'
import StlPreviewModal from './StlPreviewModal/StlPreviewModal'
import Uploads from './Uploads'

import {
  ImagePlaceholder as OralImagesPlaceholder,
  PanoramicsPlaceholder,
  StlsPlaceholder,
  UploadedStlPlaceholder
} from 'components/common/icons'
import { TIME_FORMAT_3 } from 'consts/dateTimeConsts'
import { trackEvent } from 'utils/analyticsUtils'
import { logInfo } from 'utils/logUtils'
import { parsePoseNameFromPath } from 'utils/scanSummaryUtils'
import ReplaceScanSummaryImageModal from './ReplaceScanSummaryImageModal'
import { extractScanIdFromScanSummaryKey } from 'utils/mediaUtils'
import { RECORDS_TAB_MODES } from 'consts/recordsConsts'

const RecordsSet = ({ selectedRecord, setSelectedRecord, mode, selectedFiles, onFileToggled = () => {} }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { isOpen: isReplaceScanSummaryModalOpen } = useSelector(
    state => state.treatmentReducer.replaceScanSummaryImageModal
  )

  const [isDeletePhotoModalOpen, setIsDeletePhotoModalOpen] = useState(false)
  const [deletingPhoto, setDeletingPhoto] = useState({})
  const [isDeleting, setIsDeleting] = useState(false)
  const [date, setDate] = useState(moment())
  const [previewStl, setPreviewStl] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)
  const [imageToReplaceData, setImageToReplaceData] = useState()

  const patient = useSelector(state => state.patientsReducer.patient)
  const patientName = useSelector(state => state.patientsReducer.patientCard.patient.details.name)
  const { isUploading } = useSelector(state => state.patientsReducer.patientCard.scans)
  const { items: grinScans } = useSelector(state => state.patientsReducer.patientCard.patient.grinScans)

  const oralImages = useMemo(() => selectedRecord?.oralImages || [], [selectedRecord?.oralImages])
  const panoramics = useMemo(() => selectedRecord?.panoramics || [], [selectedRecord?.panoramics])
  const stls = useMemo(() => selectedRecord?.stls || [], [selectedRecord?.stls])
  const recordsRelatedScan = useMemo(() => {
    const grinScanId = extractScanIdFromScanSummaryKey(imageToReplaceData?.key)
    return grinScans.find(scan => scan.id === grinScanId)
  }, [grinScans, imageToReplaceData])

  useEffect(() => {
    setDate(selectedRecord.date || moment())
  }, [selectedRecord.date])

  useEffect(() => {
    setIsDeleting(false)
    setIsDeletePhotoModalOpen(false)
  }, [selectedRecord])

  const onChangeRecordsSetDate = useCallback(
    date => {
      setDate(moment(date))
      if (selectedRecord.id) {
        dispatch(
          Actions.requestUpdatePatientInitialScan({
            ...selectedRecord,
            date,
            oralImages,
            panoramics,
            stls
          })
        )
      }
    },
    [dispatch, selectedRecord, oralImages, panoramics, stls]
  )

  const onStartUploadingItems = useCallback(
    data => {
      const { oralImages = [], panoramics = [] } = data
      let imagesAmount = 0
      let type = ''
      if (oralImages.length > 0) {
        imagesAmount = oralImages.filter(image => image.isLoading).length
        type = 'oralImage'
      } else if (panoramics.length > 0) {
        imagesAmount = panoramics.filter(image => image.isLoading).length
        type = 'panoramics'
      }

      trackEvent('Records - upload file', { amount: imagesAmount, fileType: type })
      dispatch(Actions.startUploadingPatientInitialScanFiles(data))
    },
    [dispatch]
  )

  const handleUploadFiles = useCallback(
    newPhotosArray => {
      if (selectedRecord.id) {
        dispatch(
          Actions.requestUpdatePatientInitialScan({
            ...selectedRecord,
            oralImages,
            panoramics,
            stls,
            ...newPhotosArray
          })
        )
      } else {
        dispatch(
          Actions.requestCreatePatientInitialScan({
            panoramics: [],
            oralImages: [],
            stls: [],
            date,
            ...newPhotosArray
          })
        )
      }
    },
    [dispatch, selectedRecord, oralImages, panoramics, stls, date]
  )

  const onDeletePhoto = useCallback((type, key) => {
    trackEvent('Records - delete image clicked', { fileType: type })
    setDeletingPhoto({
      type,
      key
    })
    setIsDeletePhotoModalOpen(true)
  }, [])

  const handleDeletePhoto = useCallback(() => {
    trackEvent('Records - delete item', { fileType: deletingPhoto.type })
    dispatch(
      Actions.deletePatientInitialScanItem({
        ...selectedRecord,
        oralImages,
        panoramics,
        stls,
        [deletingPhoto.type]: selectedRecord[deletingPhoto.type].filter(({ key }) => key !== deletingPhoto.key)
      })
    )

    setIsDeleting(true)
    setDeletingPhoto({})
  }, [deletingPhoto.key, deletingPhoto.type, dispatch, selectedRecord, oralImages, panoramics, stls])

  const handleDownloadZipFiles = useCallback(
    (files, type) => {
      trackEvent('Records - downloading files', {
        fileType: type,
        filesAmount: files.length,
        recordSetId: selectedRecord.id
      })
      logInfo(`RecordsSet: downloading initial scan files`, {
        files,
        type,
        initialScanId: selectedRecord.id
      })
      if (files.length) {
        dispatch(
          Actions.downloadPatientFilesZip({
            files: files.map((file, i) => ({ ...file, outputFilename: parsePoseNameFromPath(file.key) || `${i + 1}` })),
            name: `${patientName}-${type}`
          })
        )
      }
    },
    [dispatch, patientName, selectedRecord]
  )

  const handleOpenUploadStlFilesModal = useCallback(() => {
    trackEvent('stl modal - opened')
    dispatch(Actions.toggleUploadStlFilesModal({ isModalOpen: true }))
  }, [dispatch])

  const cancelNewRecord = useCallback(() => {
    trackEvent('Records - cancel new record')
    dispatch(Actions.deleteRecordsSet({ id: selectedRecord.id }))
  }, [dispatch, selectedRecord])

  const handleOpenImage = useCallback(({ files, index }) => {
    trackEvent('Records - open image')
    setPreviewImage({ images: files.map(({ url }) => url).filter(i => i), activeIndex: index })
  }, [])

  const handleReplaceImage = useCallback(
    originalImageData => {
      dispatch(Actions.toggleReplaceScanSummaryImageModal(true))
      setImageToReplaceData(originalImageData)
    },
    [dispatch]
  )

  const uploadsData = useMemo(
    () => [
      {
        type: 'oralImages',
        placeholders: 8,
        uploadedFiles: oralImages,
        title: t('dialogs.patientInfo.records.photosTitle'),
        subtitle: t('dialogs.patientInfo.records.photosSubtitle'),
        placeholderIcon: <OralImagesPlaceholder />,
        supportedFileTypes: ['image/png, image/jpeg, image/jpg'],
        handleOpen: handleOpenImage,
        handleReplace: handleReplaceImage,
        isFileReplaceable: fileUrl => fileUrl?.includes('scanSummaries')
      },
      {
        type: 'stls',
        placeholders: 5,
        uploadedFiles: stls,
        title: t('dialogs.patientInfo.records.threeDScansTitle'),
        subtitle: t('dialogs.patientInfo.records.threeDScansSubtitle'),
        placeholderIcon: <StlsPlaceholder />,
        onPlusClick: handleOpenUploadStlFilesModal,
        supportedFileTypes: ['.stl'],
        handleOpen: setPreviewStl,
        previewRenderer: () => <UploadedStlPlaceholder />
      },
      {
        type: 'panoramics',
        placeholders: 1,
        uploadedFiles: panoramics,
        title: t('dialogs.patientInfo.records.xRayTitle'),
        subtitle: t('dialogs.patientInfo.records.xRaySubtitle'),
        placeholderIcon: <PanoramicsPlaceholder />,
        supportedFileTypes: ['image/png, image/jpeg, image/jpg'],
        handleOpen: handleOpenImage
      }
    ],
    [oralImages, t, handleOpenImage, handleReplaceImage, stls, handleOpenUploadStlFilesModal, panoramics]
  )

  return (
    <>
      <RecordsSetHeader
        record={selectedRecord}
        date={date}
        setDate={onChangeRecordsSetDate}
        onCancel={cancelNewRecord}
        onSubmit={() => setSelectedRecord(null)}
        onBack={() => setSelectedRecord(null)}
      />
      {uploadsData.map(
        (
          {
            placeholders,
            uploadedFiles,
            type,
            title,
            subtitle,
            placeholderIcon,
            onPlusClick,
            handleOpen,
            previewRenderer,
            handleReplace,
            isFileReplaceable
          },
          index
        ) => (
          <Uploads
            key={index}
            placeholders={placeholders}
            uploadedFiles={uploadedFiles}
            type={type}
            title={title}
            subtitle={subtitle}
            onStartUploadingItems={onStartUploadingItems}
            handleUploadFiles={handleUploadFiles}
            onDeletePhoto={onDeletePhoto}
            isUploading={isUploading}
            placeholderIcon={placeholderIcon}
            handleDownloadZipFiles={handleDownloadZipFiles}
            onPlusClick={onPlusClick}
            onItemOpen={handleOpen}
            onReplace={handleReplace}
            isFileReplaceable={isFileReplaceable}
            previewRenderer={previewRenderer}
            isSelectionMode={mode === RECORDS_TAB_MODES.selection}
            selectedFiles={selectedFiles}
            onFileToggled={onFileToggled}
          />
        )
      )}
      <DeletePhotoModal
        isOpen={isDeletePhotoModalOpen}
        isDeleting={isDeleting}
        handleCancel={() => {
          setIsDeletePhotoModalOpen(false)
          setDeletingPhoto({})
        }}
        handleSubmit={handleDeletePhoto}
      />
      <StlPreviewModal
        open={!!previewStl}
        title={t('dialogs.patientInfo.records.stlPreview.title')}
        subtitle={selectedRecord.scannerType}
        fileUrl={previewStl?.url}
        filePosition={previewStl?.position}
        date={moment(selectedRecord.createdAt).format(TIME_FORMAT_3)}
        patient={patient}
        onClose={() => setPreviewStl(null)}
      />
      <ImagesViewer
        isVisible={!!previewImage}
        onClose={() => setPreviewImage(null)}
        imageUrls={previewImage?.images}
        activeIndex={previewImage?.activeIndex}
      />
      {isReplaceScanSummaryModalOpen && (
        <ReplaceScanSummaryImageModal
          videoUrl={recordsRelatedScan?.video?.url}
          originalImageUrl={imageToReplaceData?.url}
          originalImageKey={imageToReplaceData?.key}
          imageIndex={imageToReplaceData?.index}
        />
      )}
    </>
  )
}

export default RecordsSet
