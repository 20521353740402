import * as React from 'react'

const MessagePatient = props => (
  <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12.9782C0 11.7781 0.715191 10.6935 1.81824 10.2208L3.84864 9.35058C5.86103 8.48813 8.13896 8.48813 10.1514 9.35058L12.1818 10.2208C13.2848 10.6935 14 11.7781 14 12.9782V15C14 15.5523 13.5523 16 13 16H1C0.447716 16 0 15.5523 0 15V12.9782Z"
      fill="#527195"
    />
    <circle cx="7" cy="4" r="4" fill="#527195" />
    <path
      d="M15 4C15 3.44772 15.4477 3 16 3H24C24.5523 3 25 3.44772 25 4V9.03125C25 9.58353 24.5523 10.0312 24 10.0312H18L15 12.2292V4Z"
      fill="#527195"
    />
  </svg>
)

export default MessagePatient
