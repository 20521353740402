import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SelectInput from 'components/common/SelectInput'
import TextArea from 'components/common/TextArea'

const useStyles = makeStyles({
  selectedMemberValue: {
    overflow: 'hidden'
  },
  noteInput: {
    borderRadius: 10
  }
})

const AssignPatient = ({
  practiceMembersSelectOptions,
  assignee,
  note,
  onChangeAssignee,
  onChangeNote,
  assignPatientCentered = true,
  selectInputProps = {},
  selectMemberInputFullWidth = false
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const isUnassignedSelected = useMemo(() => assignee === 'Unassigned', [assignee])

  return (
    <Grid container direction="column" spacing={2} style={{ overflowY: false }}>
      <Grid item xs={12}>
        <Grid container justifyContent={assignPatientCentered ? 'center' : 'flex-start'}>
          <Grid item xs={selectMemberInputFullWidth ? 12 : 8}>
            <SelectInput
              label={t('dialogs.assignPatient.selectMember')}
              options={practiceMembersSelectOptions}
              value={assignee}
              valueClassName={classes.selectedMemberValue}
              onChange={onChangeAssignee}
              style={{ bright: true, thick: true }}
              selectStyle={{ fontWeight: 500, paddingLeft: 0 }}
              {...selectInputProps}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextArea
          inputStyle={{ height: 100, padding: 16 }}
          value={note}
          setValue={onChangeNote}
          style={{ bright: true }}
          placeholder={t('dialogs.assignPatient.addNote')}
          className={classes.noteInput}
          isDisabled={isUnassignedSelected}
        />
      </Grid>
    </Grid>
  )
}

export default AssignPatient
