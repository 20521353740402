import { useDispatch, useSelector } from 'react-redux'
import usePms from './usePms'
import { useEffect, useMemo, useState } from 'react'
import { AsyncStatus } from 'consts'
import actions from 'actions'

export default grinPatient => {
  const dispatch = useDispatch()
  const { doctorPms, patientPmsID } = usePms()
  const [autoLinkRequired, setAutoLinkRequired] = useState(true)

  const { authenticationStatus } = useSelector(state => state.pmsReducer)
  const { isModalOpen: isPatientCardOpened } = useSelector(state => state.patientsReducer.patientCard)

  const shouldAttemptPmsAutoLinking = useMemo(
    () =>
      !!doctorPms &&
      !grinPatient?.isLead &&
      authenticationStatus === AsyncStatus.Completed &&
      !patientPmsID &&
      autoLinkRequired,
    [authenticationStatus, autoLinkRequired, doctorPms, grinPatient, patientPmsID]
  )

  useEffect(() => {
    if (shouldAttemptPmsAutoLinking) {
      dispatch(
        actions.pmsAutomaticLinkingAttempt({
          id: grinPatient.id,
          firstName: grinPatient.details.firstName,
          lastName: grinPatient.details.lastName,
          birthdate: grinPatient.details.birthdate,
          email: grinPatient.details.email
        })
      )
    }

    setAutoLinkRequired(false)
  }, [dispatch, grinPatient, shouldAttemptPmsAutoLinking])

  useEffect(() => {
    if (patientPmsID) {
      setAutoLinkRequired(false)
    }
  }, [patientPmsID])

  useEffect(() => {
    setAutoLinkRequired(isPatientCardOpened)
  }, [isPatientCardOpened])
}
