import * as React from 'react'

const Plus = ({ color = '#3C52EF', ...props }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="11" cy="11" r="10.5" stroke={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4785 5.73926H10.522V10.5219H5.73926L5.73926 11.4785H10.522V16.261H11.4785V11.4785H16.261V10.5219H11.4785V5.73926Z"
        fill={color}
      />
    </svg>
  )
}

export default Plus
