import * as React from 'react'

const ArchViewerCenter = () => (
  <svg width="28" height="14" viewBox="0 0 28 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 5C0 2.23858 2.23858 0 5 0H23C25.7614 0 28 2.23858 28 5V9C28 11.7614 25.7614 14 23 14H5C2.23858 14 0 11.7614 0 9V5Z"
      fill="#4151E6"
    />
    <path d="M14 5C14 3.89543 14.8954 3 16 3V3C17.1046 3 18 3.89543 18 5V7H14V5Z" stroke="#EFF6FF" />
    <path d="M14 9C14 10.1046 14.8954 11 16 11V11C17.1046 11 18 10.1046 18 9V7H14V9Z" stroke="#EFF6FF" />
    <path d="M10 5C10 3.89543 10.8954 3 12 3V3C13.1046 3 14 3.89543 14 5V7H10V5Z" stroke="#EFF6FF" />
    <path d="M10 9C10 10.1046 10.8954 11 12 11V11C13.1046 11 14 10.1046 14 9V7H10V9Z" stroke="#EFF6FF" />
    <path d="M6 5C6 3.89543 6.89543 3 8 3V3C9.10457 3 10 3.89543 10 5V7H6V5Z" stroke="#EFF6FF" />
    <path d="M22 5C22 3.89543 21.1046 3 20 3V3C18.8954 3 18 3.89543 18 5V7H22V5Z" stroke="#EFF6FF" />
    <path d="M6 9C6 10.1046 6.89543 11 8 11V11C9.10457 11 10 10.1046 10 9V7H6V9Z" stroke="#EFF6FF" />
    <path d="M22 9C22 10.1046 21.1046 11 20 11V11C18.8954 11 18 10.1046 18 9V7H22V9Z" stroke="#EFF6FF" />
    <path d="M2 6C2 4.89543 2.89543 4 4 4V4C5.10457 4 6 4.89543 6 6V7H2V6Z" stroke="#EFF6FF" />
    <path d="M26 6C26 4.89543 25.1046 4 24 4V4C22.8954 4 22 4.89543 22 6V7H26V6Z" stroke="#EFF6FF" />
    <path d="M2 8C2 9.10457 2.89543 10 4 10V10C5.10457 10 6 9.10457 6 8V7H2V8Z" stroke="#EFF6FF" />
    <path d="M26 8C26 9.10457 25.1046 10 24 10V10C22.8954 10 22 9.10457 22 8V7H26V8Z" stroke="#EFF6FF" />
  </svg>
)

export default ArchViewerCenter
