import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Radio } from '@material-ui/core'

const GrinRadio = ({ checked, size = 'medium', isDisabled = false, className = '', ...props }) => {
  const classes = makeStyles({
    radioChecked: {
      '& svg': {
        color: ({ isDisabled }) => (isDisabled ? 'rgba(0, 0, 0, 0.26)' : '#3C52EF!important')
      },
      '& svg:first-child': {
        color: ({ isDisabled }) => (isDisabled ? 'rgba(0, 0, 0, 0.26)' : 'rgba(0, 0, 0, 0.54) !important')
      }
    }
  })({ isDisabled })

  return (
    <Radio
      {...props}
      size={size}
      color="primary"
      className={[checked ? classes.radioChecked : '', className].join(' ')}
      checked={checked}
    />
  )
}

export default GrinRadio
