import { Grid, makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useTimer } from 'react-timer-hook'
import Actions from '../actions'
import PrimaryButton from './common/buttons/PrimaryButton'
import FormInput from './common/FormInput'
import SwooshLargeIcon from './common/icons/SwooshLarge3'
import DazzedParagraph12 from './common/text/DazzedParagraph12'
import DazzedParagraph14 from './common/text/DazzedParagraph14'
import DazzedParagraph16 from './common/text/DazzedParagraph16'
import OggHeading40 from './common/text/OggHeading40'

const useStyles = makeStyles(theme => ({
  resendButton: {
    color: 'var(--text-color-1)',
    cursor: 'pointer'
  },
  resendButtonDisabled: {
    color: 'var(--text-color-4)'
  },
  confirmation: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main
  },
  confirmationTitle: {
    color: 'var(--text-color-1)'
  },
  description: {
    color: 'var(--text-color-1)',
    marginBottom: 20,
    whiteSpace: 'pre-wrap'
  },
  confirmationCodeInputContainer: {
    flexDirection: 'column'
  },
  signUpSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  resentText: {
    color: '#73D694'
  },
  resentNote: {
    fontWeight: 400,
    color: 'var(--text-color-1)'
  },
  resentTimer: {
    color: 'var(--text-color-4)',
    whiteSpace: 'pre-wrap'
  }
}))

const Confirmation = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { confirmationCode, errors, isLoading, resentConfirmationCode } = useSelector(state => state.authReducer)
  const {
    seconds,
    restart: startResendCodeCooldown,
    isRunning: isResendCooldown
  } = useTimer({
    expiryTimestamp: Date.now(),
    autoStart: false,
    onExpire: () =>
      dispatch(
        Actions.changeAuthForm({
          key: 'resentConfirmationCode',
          value: false
        })
      )
  })

  const handleResendCode = useCallback(() => {
    if (isLoading) {
      return
    }

    const cooldown = new Date()
    cooldown.setSeconds(cooldown.getSeconds() + 30)
    startResendCodeCooldown(cooldown)
    dispatch(Actions.requestResendConfirmSignUp())
  }, [dispatch, startResendCodeCooldown, isLoading])

  return (
    <div className={classes.confirmation}>
      <SwooshLargeIcon className={classes.signUpSwoosh} />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={5} />
        <Grid item xs={3}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <OggHeading40 textAlign="left" className={classes.confirmationTitle}>
                {t('pages.confirmation.title')}
              </OggHeading40>
            </Grid>
            <Grid item>
              <DazzedParagraph16 className={classes.description}>
                {t('pages.confirmation.description')}
              </DazzedParagraph16>
            </Grid>
            <Grid item xs={9}>
              <FormInput
                title={t('pages.confirmation.inputLabel')}
                value={confirmationCode}
                errorMessage={errors.confirmationCode}
                containerClassName={classes.confirmationCodeInputContainer}
                keepErrorContainerWhenInactive={false}
                style={{ bright: false, thick: true }}
                setValue={confirmationCode =>
                  dispatch(
                    Actions.changeAuthForm({
                      key: 'confirmationCode',
                      value: confirmationCode?.trim()
                    })
                  )
                }
              />
            </Grid>
            <Grid item xs={9}>
              <PrimaryButton
                width={'100%'}
                label={t('general.next')}
                onClick={() => {
                  dispatch(Actions.requestConfirmSignUp())
                }}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item>
                  <DazzedParagraph16
                    disabled={isResendCooldown}
                    className={isResendCooldown ? classes.resendButtonDisabled : classes.resendButton}
                    onClick={handleResendCode}
                  >
                    {t('pages.confirmation.resentCodeLabel')}
                  </DazzedParagraph16>
                </Grid>
                {isResendCooldown && (
                  <Grid item>
                    <DazzedParagraph14 className={classes.resentTimer}>
                      {t('pages.confirmation.resendTimer', { seconds })}
                    </DazzedParagraph14>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {resentConfirmationCode && (
              <Grid item xs={9}>
                <DazzedParagraph14 textAlign="center" className={classes.resentText}>
                  {t('pages.confirmation.confirmationCodeResentText')}
                </DazzedParagraph14>
                <DazzedParagraph12 textAlign="center" className={classes.resentNote}>
                  {t('pages.confirmation.spamFolderNote')}
                </DazzedParagraph12>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={4} />
      </Grid>
    </div>
  )
}

export default Confirmation
