import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import DropzoneFilesInput from 'components/common/inputs/DropzoneFilesInput'
import SavedFiles from 'components/Patients/Chat/SavedFiles/SavedFiles'
import DropzonePlaceholder from './DropzonePlaceholder'

const useStyles = ({ isBroadcastMode, showSavedFiles }) =>
  makeStyles({
    container: {
      marginBottom: 0,
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      paddingBottom: isBroadcastMode ? 140 : 90,
      backgroundColor: 'rgba(255,255,255,.95)',
      padding: 10
    },
    dropzone: {
      height: showSavedFiles ? '55%' : '100%',
      border: '1px dashed var(--border-color-1)',
      borderRadius: 0,
      backgroundColor: 'transparent',
      cursor: 'auto'
    }
  })({ isBroadcastMode })

const UploadDropzone = ({
  onUploadFile,
  setShowDropzone = () => {},
  isClosable = true,
  acceptedFormats = [],
  maxFileSize,
  showSavedFiles = false,
  containerClassName = '',
  dropzoneClassName = '',
  isBroadcastMode,
  note
}) => {
  const classes = useStyles({ isBroadcastMode, showSavedFiles })

  const handleCloseFileUpload = useCallback(() => setShowDropzone(false), [setShowDropzone])

  return (
    <div className={`${classes.container} ${containerClassName}`}>
      <DropzoneFilesInput
        className={`${classes.dropzone} ${dropzoneClassName}`}
        files={[]}
        onChange={onUploadFile}
        acceptedFormats={acceptedFormats}
        isLoading={false}
        dropzoneProps={{ noClick: true }}
        readAsDataURL
        multipleFiles
        maxFileSize={maxFileSize}
      >
        <DropzonePlaceholder
          handleCloseDropzone={handleCloseFileUpload}
          isClosable={isClosable}
          isBroadcastMode={isBroadcastMode}
          note={note}
        />
      </DropzoneFilesInput>
      {showSavedFiles && <SavedFiles onSelectFile={onUploadFile} handleCloseSavedFiles={handleCloseFileUpload} />}
    </div>
  )
}

export default React.memo(UploadDropzone)
