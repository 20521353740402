import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import TheFuture from './TheFuture'
import LearnMore from './LearnMore'
import Footer from 'resources/referrals/Footer.png'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles(() => ({
  footer: {
    width: '100%',
    height: isMobile() ? 57 : 106,
    objectFit: isMobile() ? 'cover' : 'contain'
  },
  footerContainer: {
    backgroundColor: 'var(--bg-color-69)'
  }
}))

const ReferralsFooter = props => {
  const classes = useStyles()

  return (
    <Grid container direction="column">
      <Grid item>
        <TheFuture />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <LearnMore />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" className={classes.footerContainer}>
          <Grid item>
            <img src={Footer} alt="" className={classes.footer} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReferralsFooter
