import * as React from 'react'

function SelectArrow(props) {
  return (
    <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0002 2.07526C15.0002 2.34986 14.8848 2.62446 14.6544 2.83445L10.0294 7.04502C9.35439 7.65991 8.45617 7.99805 7.50021 7.99805C6.54425 7.99805 5.64603 7.65937 4.97103 7.04502L0.346011 2.83445C-0.117087 2.41232 -0.114706 1.73066 0.351368 1.31122C0.817441 0.891779 1.57161 0.894471 2.0353 1.31607L6.66033 5.52663C6.88473 5.73123 7.18354 5.84377 7.50021 5.84377C7.81688 5.84377 8.11569 5.73123 8.34069 5.52663L12.9657 1.31607C13.4294 0.893932 14.183 0.891779 14.6496 1.31122C14.883 1.52175 15.0002 1.7985 15.0002 2.07526Z"
        fill="#C4C4C4"
      />
    </svg>
  )
}

export default SelectArrow
