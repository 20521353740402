import React from 'react'
import GrinTabs from 'components/common/tabs/GrinTabs'
import GrinTab from 'components/common/tabs/GrinTab'

const PortraitPromotionsList = ({ groupedPromotions, activeTab, handleChangeTab }) => {
  return (
    <GrinTabs
      value={activeTab}
      variant={groupedPromotions.length > 3 ? 'scrollable' : 'fullWidth'}
      centered={groupedPromotions.length < 4}
      onChange={handleChangeTab}
    >
      {groupedPromotions.map((tab, index) => (
        <GrinTab key={tab.id} label={tab.title} marginRight="0px" value={tab.index} />
      ))}
    </GrinTabs>
  )
}

export default PortraitPromotionsList
