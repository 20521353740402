import { useMemo, useCallback } from 'react'
import useGaFlags from 'hooks/useGaFlags'
import compareVersions from 'compare-versions'

export default ({ experimental, force } = {}) => {
  const { scanSummaryModelVersion: gaVersion } = useGaFlags()

  const formatVersionString = useCallback((version = '') => {
    // The model version numbers can have one of the following structes: V8-3, V8-3_note

    if (!version.toLowerCase().startsWith('v')) {
      return ''
    }

    version = version.slice(1)

    const numberPart = version.split('_')[0]
    const formatted = numberPart.replaceAll('-', '.')

    if (!compareVersions.validate(formatted)) {
      return ''
    }

    return formatted
  }, [])

  const experimentalFormatted = useMemo(() => formatVersionString(experimental), [experimental, formatVersionString])
  const gaFormatted = useMemo(() => formatVersionString(gaVersion), [gaVersion, formatVersionString])

  const actualVersion = useMemo(() => {
    if (force) {
      return force
    }

    if (!experimentalFormatted) {
      return gaVersion
    }

    if (!gaFormatted) {
      return gaVersion
    }

    if (compareVersions(gaFormatted, experimentalFormatted) > 0) {
      return gaVersion
    }

    return experimental
  }, [force, gaFormatted, experimentalFormatted, experimental, gaVersion])

  const validateVersionString = useCallback(
    (version = '') => {
      if (!version) {
        return true
      }

      return formatVersionString(version) !== ''
    },
    [formatVersionString]
  )

  const isScanSummaryVersionAtLeast = useCallback(
    version => {
      return !version || !actualVersion
        ? false
        : compareVersions(formatVersionString(actualVersion), formatVersionString(version)) >= 0
    },
    [actualVersion, formatVersionString]
  )

  return {
    gaVersion,
    actualVersion,
    validateVersionString,
    isScanSummaryVersionAtLeast
  }
}
