import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Dazzed',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '18px',
    color: 'var(--text-color-6)'
  },
  bold: {
    fontWeight: '600 !important'
  },
  thin: {
    fontWeight: '400 !important'
  },
  strong: {
    fontWeight: '500 !important'
  }
}))

const DazzedParagraph12 = ({ children, className, thickness = 'strong', onClick, id, textAlign = 'left' }) => {
  const classes = useStyles()

  return (
    <div
      style={textAlign ? { textAlign } : {}}
      className={[
        classes.root,
        thickness === 'bold' && classes.bold,
        thickness === 'thin' && classes.thin,
        thickness === 'strong' && classes.strong,
        className
      ].join(' ')}
      onClick={onClick}
      id={id}
    >
      {children}
    </div>
  )
}

export default DazzedParagraph12
