import { Link, makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    marginBottom: 14,
    display: 'flex',
    color: theme.palette.primary.main,
    '&.selected': {
      color: 'var(--text-color-8)',
      fontWeight: 600
    }
  }
}))

const NavigationLink = ({ label, route, className, nested = false, onClick = () => {} }) => {
  const classes = useStyles()
  const { pathname } = useLocation()

  const isSelected = useMemo(() => (nested ? pathname.includes(route) : pathname === route), [nested, route, pathname])

  return (
    <Link
      component={RouterLink}
      to={route}
      className={[classes.root, isSelected ? 'selected' : '', className].join(' ')}
      onClick={onClick}
    >
      {label}
    </Link>
  )
}

export default NavigationLink
