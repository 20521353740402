import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedHeading24 from '../../common/text/DazzedHeading24'
import DazzedParagraph12 from '../../common/text/DazzedParagraph12'
import {
  isOnFreePlan,
  isOnEnterprise,
  isOnGrinSubscription,
  getGrinSubscriptionPrices,
  isOnGrinSubscriptionV2
} from '../../../utils/billingUtils'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { Link } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import SubscriptionHeaderPlan from './SubscriptionHeaderPlan'
import SubscriptionPricingRows from './SubscriptionPricingRows'
import SubscriptionActions from './SubscriptionActions'
import usePricingUrls from 'hooks/usePricingUrls'

const useStyles = makeStyles(isNewPricing => ({
  root: {
    backgroundColor: 'var(--bg-color-5)',
    padding: 24,
    marginBottom: 20
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  contactToUpgrade: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  price: {
    display: 'inline-block',
    fontFamily: 'Arial'
  },
  currency: {
    display: 'inline-block',
    fontFamily: 'Arial',
    fontWeight: 600,
    fontSize: 20
  },
  slash: {
    display: 'inline-block',
    fontFamily: 'Arial'
  },
  period: {
    display: 'inline-block',
    fontFamily: 'Arial',
    fontWeight: 600,
    fontSize: 20
  },
  planDescription: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 32,
    justifyContent: 'space-between'
  },
  planFeeDescription: {
    display: 'flex',
    marginLeft: 4,
    marginBottom: 15
  },
  link: {
    fontWeight: 500,
    color: 'var(--text-color-3)',
    marginRight: 5,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  linkWrapper: {
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  oldPricingWrapper: {
    flexDirection: 'row'
  },
  arrow: {
    alignSelf: 'center',
    color: 'var(--text-color-3)',
    width: 14,
    height: 18
  }
}))

const SubscriptionOverview = ({
  grinPlan,
  price,
  activePatientsCount,
  maxPatients,
  onCancel,
  onContactUs,
  onUpgrade,
  renewalDate
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const practiceCountry = useSelector(state => state.practiceReducer.details?.country || 'US')
  const grinPricingUrl = usePricingUrls()

  const isFreePlan = useMemo(() => isOnFreePlan(grinPlan), [grinPlan])
  const isEnterprise = useMemo(() => isOnEnterprise(grinPlan), [grinPlan])
  const isSubscription = useMemo(() => isOnGrinSubscription(grinPlan) || isOnGrinSubscriptionV2(grinPlan), [grinPlan])

  const currentPricePerPatient = useMemo(
    () => getGrinSubscriptionPrices(practiceCountry)[grinPlan?.key],
    [grinPlan?.key, practiceCountry]
  )

  const handleClickLink = useCallback(() => {
    trackEvent(`billing tab - see pricing plans clicked`)
    window.open(grinPricingUrl, '_blank')
  }, [grinPricingUrl])

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <SubscriptionHeaderPlan
          isTrial={isFreePlan}
          grinPlan={grinPlan}
          isSubscription={isSubscription}
          renewalDate={renewalDate}
        />
        {isSubscription && (
          <SubscriptionPricingRows currentPricePerPatient={currentPricePerPatient} price={price} grinPlan={grinPlan} />
        )}
        {!!price && !isFreePlan && !isEnterprise && !isSubscription ? (
          <div className={classes.oldPricingWrapper}>
            <DazzedHeading24 className={classes.price}>${price}</DazzedHeading24>
            <DazzedHeading24 className={classes.currency}>USD</DazzedHeading24>
            <DazzedHeading24 className={classes.slash}>/</DazzedHeading24>
            <DazzedHeading24 className={classes.period}>{t(`grinPlans.period.${grinPlan.period}Abbr`)}</DazzedHeading24>
          </div>
        ) : (
          <>
            {isFreePlan && (
              <DazzedParagraph14 className={classes.contactToUpgrade}>
                &nbsp;{t(`pages.accountSettings.billing.contactToUpgrade`)}
              </DazzedParagraph14>
            )}
            <div className={classes.planFeeDescription}>
              <Link className={classes.linkWrapper} onClick={handleClickLink}>
                <DazzedParagraph14 className={classes.link}>
                  {t('pages.accountSettings.billing.seePricingPlans')}
                </DazzedParagraph14>
                <ArrowForward className={classes.arrow} />
              </Link>
            </div>
          </>
        )}
      </div>
      {!isSubscription && !isFreePlan && (
        <div className={classes.planDescription}>
          <DazzedParagraph12>
            {activePatientsCount}/{maxPatients} {t('pages.accountSettings.billing.activeUsers')}
          </DazzedParagraph12>
        </div>
      )}
      <SubscriptionActions isTrial={isFreePlan} onCancel={onCancel} onUpgrade={onUpgrade} />
    </div>
  )
}

export default SubscriptionOverview
