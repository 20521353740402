import * as React from 'react'

export default ({ className }) => {
  return (
    <svg
      width="82"
      height="82"
      fill="#86E18A"
      viewBox="0 0 82 82"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="41" cy="41" r="41" fill="#86E18A" />
      <path d="M24 41L36 53L61 28" stroke="white" strokeWidth="4" strokeLinecap="square" />
    </svg>
  )
}
