import React from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { IconButton, Tooltip as MuiTooltip } from '@material-ui/core'
import { Trans } from 'react-i18next'
import CloseIcon from 'components/common/icons/Close1'

const useStyles = makeStyles(theme => ({
  parent: {
    width: '100%'
  },
  popper: {
    pointerEvents: 'all',
    width: 235,
    opacity: 1
  },
  tooltip: {
    opacity: 1,
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.15)',
    background: `var(--bg-color-10)`,
    color: 'var(--text-color-6)',
    padding: 20,
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 400
  },
  arrow: {
    color: `var(--bg-color-10)`
  },
  closeIconContainer: {
    position: 'absolute',
    right: -16,
    top: -16,
    cursor: 'pointer',
    zIndex: 9999
  },
  tooltipContentRoot: {
    position: 'relative'
  }
}))

const NextScanDateUpdatedTooltip = ({ children, nextScanDate, isOpen, onClose = () => {} }) => {
  const classes = useStyles()

  return (
    <MuiTooltip
      title={
        <div className={classes.tooltipContentRoot} onClick={onClose}>
          <IconButton size="small" className={[classes.closeIconContainer].join(' ')}>
            <CloseIcon color="black" />
          </IconButton>
          <Trans
            i18nKey={'dialogs.patientInfo.scanSettings.nextScanDateUpdatedTooltip'}
            values={{
              nextScanDate: moment(nextScanDate).format('MM.DD.YYYY')
            }}
          />
        </div>
      }
      open={isOpen}
      arrow
      disableHoverListener
      placement="top"
      classes={{
        popper: classes.popper,
        tooltip: classes.tooltip,
        arrow: classes.arrow
      }}
    >
      {<div className={classes.parent}></div>}
    </MuiTooltip>
  )
}

export default NextScanDateUpdatedTooltip
