import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { makeStyles } from '@material-ui/styles'
import { sortBy } from 'lodash'
import Actions from 'actions'
import TransactionItem from './TransactionItem'

const useStyles = makeStyles(() => ({
  root: {},
  contentContainer: {
    marginBottom: 20
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  emptyStateContainer: {
    marginTop: 50
  }
}))

const PAGE_SIZE = 10

const BillingPaymentHistoryTab = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const username = useSelector(state => state.practiceReducer.accountOwner.username)
  const { isLoading, list } = useSelector(state => state.billingReducer.transactions)

  const [page, setPage] = useState(1)

  const orderedList = useMemo(() => sortBy(list, transaction => new Date(transaction.createdAt)).reverse(), [list])
  const pagesCount = useMemo(() => Math.ceil(orderedList.length / PAGE_SIZE), [orderedList])
  const pageList = useMemo(() => orderedList.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE), [orderedList, page])

  const handleDownloadPdf = useCallback(
    transaction => {
      if (transaction.invoicePdf) {
        window.open(transaction.invoicePdf, '_blank')
      } else {
        dispatch(Actions.fetchTransactionPdf({ transactionId: transaction.id }))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (username) {
      dispatch(Actions.fetchDoctorTransactions())
    }
  }, [dispatch, username])

  return (
    <div className={classes.root}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className={classes.contentContainer}>
          {pageList.map(transaction => (
            <TransactionItem
              key={transaction.id}
              id={transaction.id}
              type={transaction.type}
              description={transaction.description}
              date={new Date(transaction.createdAt).toLocaleDateString()}
              creditCardLast4={transaction.creditCardLast4}
              creditCardBrand={transaction.creditCardBrand}
              scopesQuantity={transaction.scopesQuantity}
              price={transaction.amount}
              downloadUrl={transaction.invoicePdf}
              userId={transaction.owner}
              creatorUserId={transaction.creatorUserId}
              isLoadingPdf={transaction.isLoadingPdf}
              onDownloadPdfClicked={() => handleDownloadPdf(transaction)}
            />
          ))}
          {list.length ? (
            <div className={classes.paginationContainer}>
              <Pagination count={pagesCount} page={page} onChange={(event, value) => setPage(value)} />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  )
}

export default BillingPaymentHistoryTab
