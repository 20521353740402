import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import GrinMenu from 'components/common/menu/GrinMenu'
import { trackEvent } from 'utils/analyticsUtils'
import { Grid } from '@material-ui/core'
import { ArrowDropdownDown, ArrowDropdownUp, BaseSTLTooth } from 'components/common/icons'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import GrinMenuItem from 'components/common/menu/GrinMenuItem'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  arrow: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  pickerTrigger: {
    cursor: 'pointer'
  }
}))

const OriginSTLPicker = ({ selectedOriginModelType, setSelectedOriginModelType, originModelOptions }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const handleOpenMenu = useCallback(() => {
    trackEvent('Treatment tracker - Model viewer - Origin STL picker opened')
    setIsOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    trackEvent('Treatment tracker - Model viewer - Origin STL picker closed')
    setIsOpen(false)
  }, [])

  const handleOptionSelected = useCallback(
    option => {
      trackEvent('Treatment tracker - Model viewer - Origin STL selected', {
        selectedSTL: option.label
      })
      setIsOpen(false)
      setSelectedOriginModelType(option.type)
    },
    [setSelectedOriginModelType]
  )

  return (
    <GrinMenu
      open={isOpen}
      onClose={handleCloseMenu}
      onOpen={handleOpenMenu}
      transformOrigin={{ vertical: -35, horizontal: 0 }}
      triggerComponent={
        <Grid container alignItems="center" spacing={1} className={classes.pickerTrigger}>
          <Grid item>
            <BaseSTLTooth />
          </Grid>
          <Grid item>
            <DazzedParagraph12 strong>
              {t(`dialogs.treatmentTracker.viewerControls.${selectedOriginModelType}`)}
            </DazzedParagraph12>
          </Grid>
          <Grid item>
            <div className={classes.arrow}>{isOpen ? <ArrowDropdownUp /> : <ArrowDropdownDown />}</div>
          </Grid>
        </Grid>
      }
    >
      <div>
        {originModelOptions.map(option => (
          <GrinMenuItem key={`origin-stl-picker-item-${option.type}`} onClick={() => handleOptionSelected(option)}>
            <DazzedParagraph12 strong>{option.label}</DazzedParagraph12>
          </GrinMenuItem>
        ))}
      </div>
    </GrinMenu>
  )
}

export default OriginSTLPicker
