import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { QuestionMarkExplain, ResendInviteLarge } from 'components/common/icons'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import DazzedHeading16 from 'components/common/text/DazzedHeading16'
import DazzedHeading24 from 'components/common/text/DazzedHeading24'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import BaseModal from 'components/common/modals/BaseModal'
import Actions from 'actions'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles({
  modal: {
    minWidth: 500
  },
  container: {
    textAlign: 'center',
    padding: 20
  },
  siblingsModeTitle: {
    lineHeight: '28px'
  },
  iconContainer: { marginRight: 10, alignSelf: 'flex-start' },
  subtitle: { marginBottom: 20 },
  siblingModeContent: {
    paddingLeft: 8
  },
  title: { padding: 20 }
})

const InvitationCodeModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const { isOpen, invitationCode, siblingEmail, isOldInvitation } = useSelector(
    state => state.patientsReducer.invitationCodeModal
  )

  const handleClosePopup = useCallback(() => {
    dispatch(Actions.toggleInvitationCodeModal({ isModalOpen: false }))
  }, [dispatch])

  return (
    <BaseModal
      className={classes.modal}
      open={isOpen}
      largerButtons
      variant="alert"
      onPrimaryBtnClick={handleClosePopup}
      primaryLabel={t('general.close')}
      handleClose={handleClosePopup}
    >
      <Grid container justifyContent="center" alignItems="center" className={classes.container}>
        <Grid item xs={12}>
          <ResendInviteLarge />
        </Grid>
        <Grid item xs={12} className={classes.title}>
          {!siblingEmail ? (
            <DazzedHeading24>{t('dialogs.invitationCode.title')}</DazzedHeading24>
          ) : (
            <DazzedHeading20 className={classes.siblingsModeTitle}>
              {t('dialogs.invitationCode.siblingsMode.title')}
            </DazzedHeading20>
          )}
        </Grid>
        {!siblingEmail ? (
          <>
            <Grid item xs={12}>
              <DazzedHeading16>{t('dialogs.invitationCode.subtitle')}</DazzedHeading16>
            </Grid>
            <Grid item xs={12}>
              <DazzedHeading20>{!isOldInvitation ? invitationCode : '-'}</DazzedHeading20>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <DazzedHeading16>{t('dialogs.invitationCode.siblingsMode.subtitle')}</DazzedHeading16>
            </Grid>
            <Grid item xs={12}>
              <DazzedParagraph14 textAlign={'center'} className={classes.subtitle}>
                {siblingEmail}
              </DazzedParagraph14>
            </Grid>
          </>
        )}
        {!siblingEmail && (
          <Grid item xs={1} className={classes.iconContainer}>
            <QuestionMarkExplain />
          </Grid>
        )}

        {isOldInvitation ? (
          <Grid item xs={10}>
            <DazzedParagraph14>{t('dialogs.invitationCode.oldInviation.content')}</DazzedParagraph14>
          </Grid>
        ) : !siblingEmail ? (
          <Grid item xs={10}>
            <DazzedParagraph14>{t('dialogs.invitationCode.content1')}</DazzedParagraph14>
            <DazzedParagraph14>&nbsp;</DazzedParagraph14>

            <DazzedParagraph14>{t('dialogs.invitationCode.content2')}</DazzedParagraph14>
          </Grid>
        ) : (
          <Grid item xs={11}>
            <DazzedParagraph14>{t('dialogs.invitationCode.siblingsMode.content1')}</DazzedParagraph14>
            <DazzedParagraph14 className={classes.siblingModeContent}>
              {t('dialogs.invitationCode.siblingsMode.content2')}
            </DazzedParagraph14>
            <DazzedParagraph14 className={classes.siblingModeContent}>
              <Trans i18nKey={t('dialogs.invitationCode.siblingsMode.content3')} components={{ bold: <b>{''}</b> }} />
            </DazzedParagraph14>
            <DazzedParagraph14 className={classes.siblingModeContent}>
              {t('dialogs.invitationCode.siblingsMode.content4')}
            </DazzedParagraph14>
          </Grid>
        )}
      </Grid>
    </BaseModal>
  )
}

export default InvitationCodeModal
