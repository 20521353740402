import * as React from 'react'

const List1 = props => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.46189 12.1177C1.19653 12.1177 0.964345 12.0182 0.765326 11.8191C0.566307 11.6201 0.466797 11.3879 0.466797 11.1226V1.17161C0.466797 0.906252 0.566307 0.674062 0.765326 0.475043C0.964345 0.276023 1.19653 0.176514 1.46189 0.176514H13.005C13.2704 0.176514 13.5026 0.276023 13.7016 0.475043C13.9006 0.674062 14.0001 0.906252 14.0001 1.17161V11.1226C14.0001 11.3879 13.9006 11.6201 13.7016 11.8191C13.5026 12.0182 13.2704 12.1177 13.005 12.1177H1.46189ZM1.46189 11.1226H13.005V1.17161H1.46189V11.1226ZM2.58967 9.46409H5.90666V8.13729H2.58967V9.46409ZM8.92512 8.13729L12.2089 4.85347L11.2636 3.90813L8.92512 6.26319L7.97978 5.31785L7.05102 6.26319L8.92512 8.13729ZM2.58967 6.81049H5.90666V5.4837H2.58967V6.81049ZM2.58967 4.1569H5.90666V2.83011H2.58967V4.1569ZM1.46189 11.1226V1.17161V11.1226Z"
        fill="white"
      />
    </svg>
  )
}

export default List1
