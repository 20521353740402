import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import TagsList from 'components/common/tags/TagsList'
import useRolePermissions from 'hooks/useRolePermissions'
import useBroadcast from 'hooks/useBroadcast'
import Actions from 'actions'
import usePatientTags from 'hooks/usePatientTags'
import PatientStatusUpdate from './PatientStatusUpdate'
import { Skeleton } from '@material-ui/lab'
import { isLandscape } from 'utils/mobileUtils'
import BroadcastHeaderMobile from 'components/Patients/Broadcast/BroadcastHeader.mobile'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import ProfilePicture from 'components/common/ProfilePicture'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import { ArrowDown, ArrowUp } from 'components/common/icons'
import Assignee from './PatientsHeader/Assignee'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '10px',
    boxSizing: 'border-box',
    borderBottom: '1px solid var(--border-color-7)',
    paddingTop: 15,
    backgroundColor: 'white'
  },
  patientName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'left',
    maxWidth: '80%'
  },
  icon: {
    padding: 0
  },
  headerActions: {
    maxHeight: '50px'
  },
  statusPicker: {
    margin: 0,
    maxWidth: '160px'
  },
  tagsList: {
    width: '100%'
  },
  skeletonPseudoText: {
    borderRadius: '10px'
  },
  skeletonStatus: {
    borderRadius: '24px'
  },
  broadcastWarningContainer: {
    padding: '15px 12px 5px 12px',
    borderBottom: '1px solid var(--border-color-7)',
    flex: 1
  }
}))

const PatientsHeaderMobile = () => {
  const classes = useStyles()
  const { permissions } = useRolePermissions()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const patient = useSelector(state => state.patientsReducer.patient)
  const isLoading = useSelector(state => state.patientsReducer.isLoadingPatient)
  const {
    isModeOn: isBroadcastMode,
    showBroadcastState,
    isPersonalMessage,
    allSelected,
    selectedPatients,
    localMessages
  } = useBroadcast()
  const { tags, assigneeTag } = usePatientTags({ patient })

  const [isCollapsed, setIsCollapsed] = useState(false)

  const togglePatientCard = useCallback(() => {
    dispatch(
      Actions.toggleMobilePatientCard({
        isModalOpen: true
      })
    )
  }, [dispatch])

  const handleDeleteTag = useCallback(
    tagValue => {
      patient?.id && dispatch(Actions.removePatientTag({ patientId: patient.id, tag: tagValue }))
    },
    [patient, dispatch]
  )

  const toggleCollapse = useCallback(() => setIsCollapsed(!isCollapsed), [isCollapsed])

  const showBroadcastBanner = useMemo(
    () => showBroadcastState && !localMessages.length,
    [localMessages.length, showBroadcastState]
  )

  const renderPatientHeaderSkeleton = () => (
    <>
      <Grid item xs={isLandscape() ? 5 : 12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Skeleton variant="circle" height={30} width={30} />
          </Grid>
          <Skeleton variant="rect" height={27} width={180} className={classes.skeletonPseudoText}>
            <Typography></Typography>
          </Skeleton>
        </Grid>
      </Grid>
    </>
  )

  return (
    <>
      <Grid container direction="row" className={classes.root} spacing={1}>
        {isLoading ? (
          renderPatientHeaderSkeleton()
        ) : isBroadcastMode ? (
          <BroadcastHeaderMobile />
        ) : (
          <>
            <Grid item xs={isLandscape() ? 5 : 12}>
              <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item xs={10}>
                  <Grid container alignItems="center" spacing={1} onClick={togglePatientCard}>
                    <Grid item>
                      <ProfilePicture
                        photo={patient?.photo}
                        name={patient?.details?.name}
                        email={patient?.details?.email}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <DazzedHeading18 className={classes.patientName}>{patient?.details?.name}</DazzedHeading18>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton onClick={toggleCollapse}>{isCollapsed ? <ArrowDown /> : <ArrowUp />}</IconButton>
                </Grid>
              </Grid>
            </Grid>
            {isCollapsed && (
              <>
                <Grid item xs={isLandscape() ? 7 : 12} className={classes.headerActions}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={6}>
                      <TagsList
                        tags={tags}
                        onDeleteTag={handleDeleteTag}
                        compressionThreshold={90}
                        canDeleteCustom={permissions.editPatientTags}
                        canDeleteScan={false}
                        showIcon={permissions.editPatientTags}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <PatientStatusUpdate
                        variant="noBorder"
                        patient={patient}
                        className={classes.statusPicker}
                        analyticsSource="Patient header mobile"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Assignee patient={patient} assigneeTag={assigneeTag} />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      {showBroadcastBanner && (
        <Grid container>
          <Grid item className={classes.broadcastWarningContainer}>
            <DazzedParagraph14 bold>
              {t(isPersonalMessage ? 'dialogs.broadcast.subtitlePersonal' : 'dialogs.broadcast.subtitle', {
                amount: allSelected ? t('dialogs.broadcast.allSelected') : selectedPatients.length,
                s: selectedPatients.length > 1 || allSelected ? 's' : ''
              })}
            </DazzedParagraph14>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default PatientsHeaderMobile
