import * as React from 'react'

const SendMessage = () => (
  <svg width="52" height="38" viewBox="0 0 52 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1.27274" width="37.0909" height="26.1818" rx="4.36364" stroke="#ACB0B7" strokeWidth="1.5" />
    <path
      d="M2.09082 2.36364L18.0026 18.2754C18.8546 19.1275 20.2361 19.1275 21.0881 18.2754L36.9999 2.36364"
      stroke="#ACB0B7"
      strokeWidth="1.5"
    />
    <path d="M2.09082 26.3636L14.0908 14.3636" stroke="#ACB0B7" strokeWidth="1.5" />
    <path d="M37 26.3636L25 14.3636" stroke="#ACB0B7" strokeWidth="1.5" />
    <mask
      id="path-5-outside-1_40_29"
      maskUnits="userSpaceOnUse"
      x="25.1819"
      y="16.9091"
      width="26"
      height="21"
      fill="black"
    >
      <rect fill="white" x="25.1819" y="16.9091" width="26" height="21" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.4545 29.6364V34L49 27.4545L42.4545 20.9091V25.2727H27.1818V29.6364H42.4545Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.4545 29.6364V34L49 27.4545L42.4545 20.9091V25.2727H27.1818V29.6364H42.4545Z"
      fill="#ACB0B7"
    />
    <path
      d="M42.4545 34H40.9545V37.6213L43.5152 35.0607L42.4545 34ZM42.4545 29.6364H43.9545V28.1364H42.4545V29.6364ZM49 27.4545L50.0607 28.5152L51.1213 27.4545L50.0607 26.3939L49 27.4545ZM42.4545 20.9091L43.5152 19.8484L40.9545 17.2878V20.9091H42.4545ZM42.4545 25.2727V26.7727H43.9545V25.2727H42.4545ZM27.1818 25.2727V23.7727H25.6818V25.2727H27.1818ZM27.1818 29.6364H25.6818V31.1364H27.1818V29.6364ZM43.9545 34V29.6364H40.9545V34H43.9545ZM47.9393 26.3939L41.3939 32.9393L43.5152 35.0607L50.0607 28.5152L47.9393 26.3939ZM41.3939 21.9698L47.9393 28.5152L50.0607 26.3939L43.5152 19.8484L41.3939 21.9698ZM43.9545 25.2727V20.9091H40.9545V25.2727H43.9545ZM27.1818 26.7727H42.4545V23.7727H27.1818V26.7727ZM28.6818 29.6364V25.2727H25.6818V29.6364H28.6818ZM42.4545 28.1364H27.1818V31.1364H42.4545V28.1364Z"
      fill="white"
      mask="url(#path-5-outside-1_40_29)"
    />
  </svg>
)

export default SendMessage
