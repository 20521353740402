import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import GrinRadioGroup from 'components/common/GrinRadioGroup'
import { Sizes } from 'consts/styleConsts'
import { ScanFrequencyOptions } from 'utils/statusUtils'
import { useTranslation } from 'react-i18next'
import FormInput from 'components/common/FormInput'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 14
  },
  checkbox: {
    padding: '0px 9px 0px 0px'
  },
  inputWrapper: {
    margin: '3px 0px 0px 0px !important',
    border: 'none !important'
  },
  input: {
    textAlign: 'center',
    width: '40px !important',
    border: 'none !important',
    borderBottom: '1px solid !important',
    fontSize: '14px !important',
    fontFamily: 'Dazzed'
  }
}))

const Frequency = ({ isCustom = false, frequency, onChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const options = useMemo(
    () => [
      ...ScanFrequencyOptions['active-treatment'].map(option => ({
        label: `${option} ${t('pages.accountSettings.practiceGuidelines.actions.frequency.days')}`,
        value: option
      })),
      { label: t('pages.accountSettings.practiceGuidelines.actions.frequency.custom'), value: 'custom' }
    ],
    [t]
  )

  const handleFrequencySelected = useCallback(
    value =>
      onChange({
        frequency: value === 'custom' ? frequency : Number(value),
        isCustom: value === 'custom'
      }),
    [frequency, onChange]
  )

  const handleCustomFrequencyChanged = useCallback(
    value => {
      if (value > 90 || value < 1) {
        return
      }

      onChange({
        frequency: value,
        isCustom: true
      })
    },
    [onChange]
  )

  return (
    <Grid container wrap="nowrap">
      <Grid item>
        <GrinRadioGroup
          size={Sizes.Small}
          value={isCustom ? 'custom' : frequency ?? ''}
          setValue={handleFrequencySelected}
          options={options}
        />
      </Grid>
      {isCustom && (
        <Grid item>
          <FormInput
            type="number"
            value={frequency}
            setValue={handleCustomFrequencyChanged}
            style={{ bright: true, thick: false }}
            keepErrorContainerWhenInactive={false}
            inputClassName={classes.input}
            inputWrapperClassName={classes.inputWrapper}
            inputSuffix={t('pages.accountSettings.practiceGuidelines.actions.keepAlignersOrGoBack.moreDays')}
            maxLength={2}
            validationRules={{ required: true, pattern: '^[0-9]+$' }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default Frequency
