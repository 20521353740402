import * as React from 'react'

function SvgCheckboxOn({ color = '#FFBD39' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="16" height="16" fill={color} />
      <path d="M9 11.7171L11.6629 14L15.5 9.5" stroke="white" strokeWidth="2" />
    </svg>
  )
}

export default SvgCheckboxOn
