import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ScanFeedCard from 'components/Patients/Timeline/V2/ScanFeed/ScanFeedCard'
import { ScanFeedPageSectionNames } from 'consts/timelineConsts'
import ScanReviewItem from 'components/Patients/Timeline/V2/ScanFeed/ScanReviewsCard/ScanReviewItem'
import { trackEvent } from 'utils/analyticsUtils'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import { Grid, makeStyles } from '@material-ui/core'
import { scrollBar } from 'styles/theme'

const useStyles = makeStyles(theme => ({
  scanReviewsList: {
    maxHeight: '370px',
    overflowY: 'auto',
    ...scrollBar
  }
}))

/**
 * @prop scanReviews - the `scanReviews` list as returned from the `useScanReviews` hook.
 */
const ScanReviewsCard = ({ scanReviews = [], analyticsPayload = {} }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleScanReviewClick = useCallback(
    scanReview => {
      trackEvent('Scan feed - scan review video clicked', analyticsPayload)
      dispatch(
        Actions.openScanViewDialog({
          s3Object: scanReview.video,
          isLocal: scanReview.isLocal,
          cache: scanReview.isLocal ? scanReview.video : null,
          type: 'scanReview'
        })
      )
    },
    [analyticsPayload, dispatch]
  )

  return (
    <ScanFeedCard
      title={t('pages.patients.selectedPatient.timelineV2.scanFeed.scanReviews.title')}
      id={ScanFeedPageSectionNames.Reviews}
    >
      <Grid container wrap="wrap" spacing={2} className={classes.scanReviewsList}>
        {scanReviews.map((scanReview, i) => (
          <Grid item key={`scan-review-${scanReview.id}`} style={{ marginRight: i < scanReviews.length - 1 ? 60 : 0 }}>
            <ScanReviewItem
              video={scanReview.video}
              isLocal={scanReview.isLocal}
              createdAt={scanReview.createdAt}
              reviewerName={scanReview.reviewerName}
              reviewerRoleDescription={scanReview.reviewerRoleDescription}
              withAligner={scanReview.withAligner}
              onClick={() => handleScanReviewClick(scanReview)}
            />
          </Grid>
        ))}
      </Grid>
    </ScanFeedCard>
  )
}

export default React.memo(ScanReviewsCard)
