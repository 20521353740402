import * as React from 'react'

const AroundScope = props => (
  <svg width="79" height="57" viewBox="0 0 79 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="78.5339" height="56.0956" rx="5.60956" fill="#7A97F8" />
    <path
      d="M10.035 11.7252C11.3596 8.41376 14.5668 6.24237 18.1334 6.24237H60.4005C63.967 6.24237 67.1743 8.41377 68.4989 11.7252L73.7322 24.8085C74.564 26.888 74.564 29.2077 73.7322 31.2872L68.4989 44.3705C67.1743 47.6819 63.9671 49.8533 60.4005 49.8533H18.1334C14.5668 49.8533 11.3596 47.6819 10.035 44.3705L4.80172 31.2872C3.96993 29.2077 3.96993 26.888 4.80172 24.8085L10.035 11.7252Z"
      fill="#BE5C5C"
    />
    <path
      d="M15.34 37.9273C15.1622 40.8039 17.2418 43.2713 20.0711 43.8198C21.6758 44.1308 23.4398 44.3618 24.9015 44.2801C29.8563 44.003 34.4139 45.8981 39.1803 45.8981C43.9466 45.8981 48.5043 44.003 53.459 44.2801C54.9207 44.3618 56.6847 44.1308 58.2894 43.8198C61.1188 43.2713 63.1983 40.8039 63.0205 37.9273C62.9933 37.4872 62.9496 37.062 62.8838 36.6755C62.3423 33.4938 62.8838 32.307 62.8838 32.307H15.4767C15.4767 32.307 16.0182 33.4938 15.4767 36.6755C15.4109 37.062 15.3672 37.4872 15.34 37.9273Z"
      fill="#F7A2A2"
    />
    <path
      d="M61.0398 32.0957L60.8899 29.6765C61.1511 29.5969 61.5302 29.4928 61.9435 29.4159C62.2974 29.3501 62.65 29.3089 62.9279 29.2837C62.8696 30.0812 62.7002 31.1353 62.4742 32.0268C62.3461 32.532 62.2059 32.9606 62.0682 33.2531C62.0257 33.3433 61.9878 33.4112 61.9561 33.4601C61.9458 33.4529 61.9345 33.4446 61.9222 33.4351C61.8287 33.3621 61.7215 33.2485 61.6086 33.1027C61.3838 32.8125 61.1889 32.4644 61.0821 32.2593C61.0593 32.2155 61.0439 32.1612 61.0398 32.0957ZM61.8847 33.5509C61.8847 33.5508 61.8864 33.5491 61.8898 33.5466C61.8864 33.5498 61.8847 33.5511 61.8847 33.5509Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M17.3207 32.0957L17.4706 29.6765C17.2093 29.5969 16.8302 29.4928 16.4169 29.4159C16.0631 29.3501 15.7105 29.3089 15.4325 29.2837C15.4909 30.0812 15.6602 31.1353 15.8863 32.0268C16.0143 32.532 16.1546 32.9606 16.2923 33.2531C16.3348 33.3433 16.3727 33.4112 16.4043 33.4601C16.4146 33.4529 16.4259 33.4446 16.4382 33.4351C16.5318 33.3621 16.639 33.2485 16.7519 33.1027C16.9767 32.8125 17.1715 32.4644 17.2784 32.2593C17.3011 32.2155 17.3166 32.1612 17.3207 32.0957ZM16.4757 33.5509C16.4757 33.5508 16.474 33.5491 16.4707 33.5466C16.474 33.5498 16.4757 33.5511 16.4757 33.5509Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M58.7128 32.762L58.5526 30.1763C58.8351 30.0894 59.2572 29.9721 59.7189 29.8862C60.1125 29.813 60.5045 29.768 60.8085 29.7411C60.7592 30.4426 60.6704 31.5605 60.5129 32.5501C60.4224 33.1187 60.3133 33.6167 60.1866 33.9624C60.1336 34.107 60.0852 34.2031 60.0473 34.2612C59.9975 34.2473 59.9347 34.2209 59.8582 34.1749C59.7305 34.098 59.5924 33.9831 59.4517 33.8406C59.1699 33.5554 58.9257 33.2094 58.7825 32.9894C58.7442 32.9304 58.7185 32.8537 58.7128 32.762ZM60.0069 34.3134C60.0069 34.3134 60.0075 34.3128 60.0087 34.3117C60.0075 34.313 60.0069 34.3135 60.0069 34.3134Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M19.6474 32.762L19.8077 30.1763C19.5251 30.0894 19.1031 29.9721 18.6413 29.8862C18.2478 29.813 17.8557 29.768 17.5518 29.7411C17.601 30.4426 17.6898 31.5605 17.8473 32.5501C17.9379 33.1187 18.0469 33.6167 18.1737 33.9624C18.2267 34.107 18.275 34.2031 18.3129 34.2612C18.3627 34.2473 18.4255 34.2209 18.502 34.1749C18.6297 34.098 18.7678 33.9831 18.9085 33.8406C19.1903 33.5554 19.4345 33.2094 19.5777 32.9894C19.6161 32.9304 19.6417 32.8537 19.6474 32.762ZM18.3534 34.3134C18.3534 34.3134 18.3528 34.3128 18.3515 34.3117C18.3528 34.313 18.3534 34.3135 18.3534 34.3134Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M55.4272 33.1222L55.2405 30.4107C55.6148 30.3235 56.1592 30.2182 56.5836 30.2182C57.0416 30.2182 57.6543 30.2394 58.08 30.2568L57.9896 32.9751C57.9801 33.26 57.9548 33.5238 57.8948 33.7712C57.8155 34.0977 57.688 34.5251 57.5129 34.8603C57.4251 35.0282 57.3387 35.1466 57.262 35.2175C57.1893 35.2846 57.1526 35.2857 57.1434 35.2857C57.1133 35.2857 57.0177 35.2653 56.8494 35.1383C56.6904 35.0182 56.5165 34.8408 56.34 34.6275C55.9882 34.2023 55.6784 33.6987 55.5109 33.409C55.4638 33.3278 55.4347 33.231 55.4272 33.1222Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M22.9334 33.1222L23.1201 30.4107C22.7458 30.3235 22.2013 30.2182 21.777 30.2182C21.319 30.2182 20.7062 30.2394 20.2806 30.2568L20.371 32.9751C20.3805 33.26 20.4058 33.5238 20.4658 33.7712C20.5451 34.0977 20.6726 34.5251 20.8477 34.8603C20.9355 35.0282 21.0219 35.1466 21.0986 35.2175C21.1713 35.2846 21.208 35.2857 21.2172 35.2857C21.2473 35.2857 21.3429 35.2653 21.5112 35.1383C21.6702 35.0182 21.8441 34.8408 22.0206 34.6275C22.3723 34.2023 22.6822 33.6987 22.8497 33.409C22.8968 33.3278 22.9259 33.231 22.9334 33.1222Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M51.1993 30.5654L51.4067 33.6007C51.4179 33.7651 51.4727 33.9129 51.5627 34.0296C51.8076 34.3473 52.217 34.848 52.6584 35.2646C52.8795 35.4732 53.0964 35.6491 53.2943 35.77C53.5007 35.8962 53.6385 35.9328 53.7127 35.9328C53.7644 35.9328 53.8276 35.9147 53.916 35.815C54.0119 35.7068 54.1053 35.5345 54.1873 35.3095C54.3504 34.8622 54.42 34.3446 54.4439 34.114C54.4505 34.05 54.4541 33.9831 54.4569 33.9056L51.1993 30.5654ZM51.1993 30.5654C51.6343 30.5187 52.2803 30.4609 52.7986 30.4609C53.3404 30.4609 54.0956 30.5579 54.5754 30.6293L54.4569 33.9056L51.1993 30.5654Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M27.1612 30.5654L26.9538 33.6007C26.9425 33.7651 26.8878 33.9129 26.7978 34.0296C26.5528 34.3473 26.1434 34.848 25.702 35.2646C25.481 35.4732 25.2641 35.6491 25.0662 35.77C24.8597 35.8962 24.722 35.9328 24.6478 35.9328C24.5961 35.9328 24.5329 35.9147 24.4445 35.815C24.3486 35.7068 24.2551 35.5345 24.1731 35.3095C24.0101 34.8622 23.9405 34.3446 23.9166 34.114C23.9099 34.05 23.9064 33.9831 23.9036 33.9056L27.1612 30.5654ZM27.1612 30.5654C26.7261 30.5187 26.0802 30.4609 25.5619 30.4609C25.02 30.4609 24.2649 30.5579 23.7851 30.6293L23.9036 33.9056L27.1612 30.5654Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M45.9435 30.5852C46.4992 30.5013 47.421 30.38 48.079 30.38C48.7446 30.38 49.68 30.5041 50.233 30.588L50.1149 33.8536C50.0972 34.3425 50.0453 34.7975 49.906 35.2152C49.779 35.5958 49.5988 36.0315 49.3706 36.3624C49.1309 36.7101 48.9243 36.8228 48.7666 36.8228C48.4794 36.8228 48.0822 36.6505 47.6349 36.3406C47.2064 36.0438 46.8077 35.6749 46.5338 35.3997L46.1794 35.7525L46.5338 35.3997C46.3831 35.2484 46.2897 35.0358 46.2709 34.7941L45.9435 30.5852Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M32.4167 30.5852C31.861 30.5013 30.9392 30.38 30.2812 30.38C29.6156 30.38 28.6802 30.5041 28.1272 30.588L28.2454 33.8536C28.263 34.3425 28.315 34.7975 28.4543 35.2152C28.5812 35.5958 28.7614 36.0315 28.9896 36.3624C29.2293 36.7101 29.4359 36.8228 29.5936 36.8228C29.8808 36.8228 30.278 36.6505 30.7253 36.3406C31.1539 36.0438 31.5525 35.6749 31.8265 35.3997L32.1808 35.7525L31.8265 35.3997C31.9772 35.2484 32.0705 35.0358 32.0893 34.7941L32.4167 30.5852Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M39.6803 32.8226V30.6399C40.3175 30.557 41.4987 30.4205 42.3354 30.4205C43.172 30.4205 44.3532 30.557 44.9905 30.6399V32.8226C44.9905 33.983 44.7894 35.0883 44.1826 35.9595C43.9121 36.3478 43.6017 36.7172 43.2706 36.985C42.9372 37.2547 42.6237 37.3891 42.3354 37.3891C42.0471 37.3891 41.7336 37.2547 41.4002 36.985C41.0691 36.7172 40.7587 36.3478 40.4882 35.9595C39.8814 35.0883 39.6803 33.983 39.6803 32.8226Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M38.6803 32.8226V30.6399C38.0431 30.557 36.8619 30.4205 36.0252 30.4205C35.1886 30.4205 34.0074 30.557 33.3701 30.6399V32.8226C33.3701 33.983 33.5712 35.0883 34.178 35.9595C34.4485 36.3478 34.7589 36.7172 35.09 36.985C35.4234 37.2547 35.7369 37.3891 36.0252 37.3891C36.3135 37.3891 36.627 37.2547 36.9604 36.985C37.2915 36.7172 37.6019 36.3478 37.8724 35.9595C38.4792 35.0883 38.6803 33.983 38.6803 32.8226Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M12.2658 16.9728C12.088 14.0962 14.1675 11.6289 16.9969 11.0804C18.6016 10.7693 20.3656 10.5383 21.8273 10.62C25.3706 10.8182 27.0358 13.047 30.5846 13.047C34.1335 13.047 35.7931 10.62 39.342 10.62C42.8909 10.62 44.5505 13.047 48.0994 13.047C51.6483 13.047 53.3134 10.8182 56.8568 10.62C58.3184 10.5383 60.0824 10.7693 61.6871 11.0804C64.5165 11.6289 66.5961 14.0962 66.4183 16.9728C66.3911 17.4129 66.3473 17.8381 66.2816 18.2246C65.7401 21.4063 63.0456 25.8291 63.0456 25.8291H15.6385C15.6385 25.8291 12.944 21.4063 12.4025 18.2246C12.3367 17.8381 12.293 17.4129 12.2658 16.9728Z"
      fill="#F7A2A2"
    />
    <path
      d="M62.3173 23.4323C62.3173 23.1449 62.3663 22.859 62.4839 22.5967C62.7191 22.0718 63.1686 21.2179 63.6029 21.2179C64.1356 21.2179 64.295 22.4444 64.3309 22.8131C64.3377 22.8825 64.3397 22.9517 64.3397 23.0214V25.2446C64.3397 25.7407 64.294 26.2385 64.131 26.707C63.9385 27.2604 63.6222 27.9326 63.2212 27.9326C62.6502 27.9326 62.4014 26.9101 62.3358 26.5787C62.3223 26.5105 62.3173 26.4414 62.3173 26.3719V23.4323Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M16.3665 23.4323C16.3665 23.1449 16.3174 22.859 16.1998 22.5967C15.9646 22.0718 15.5151 21.2179 15.0809 21.2179C14.5481 21.2179 14.3887 22.4444 14.3528 22.8131C14.3461 22.8825 14.344 22.9517 14.344 23.0214V25.2446C14.344 25.7407 14.3897 26.2385 14.5527 26.707C14.7452 27.2604 15.0615 27.9326 15.4625 27.9326C16.0335 27.9326 16.2823 26.9101 16.3479 26.5787C16.3614 26.5105 16.3665 26.4414 16.3665 26.3719V23.4323Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M60.3759 23.615C60.3759 23.2099 60.4748 22.8104 60.6875 22.4656C61.0718 21.8425 61.7259 20.8943 62.1468 20.8943C62.6951 20.8943 62.848 22.4409 62.8777 22.8292C62.8822 22.8876 62.8837 22.9461 62.8837 23.0046V25.3491C62.8837 25.776 62.8474 26.2032 62.7352 26.6151C62.5526 27.2856 62.2113 28.2561 61.7652 28.2561C61.3555 28.2561 60.9363 27.9053 60.667 27.6254C60.4651 27.4155 60.3759 27.1284 60.3759 26.8372V23.615Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M18.3081 23.615C18.3081 23.2099 18.2092 22.8104 17.9965 22.4656C17.6121 21.8425 16.9581 20.8943 16.5371 20.8943C15.9888 20.8943 15.836 22.4409 15.8062 22.8292C15.8017 22.8876 15.8002 22.9461 15.8002 23.0046V25.3491C15.8002 25.776 15.8366 26.2032 15.9487 26.6151C16.1313 27.2856 16.4727 28.2561 16.9187 28.2561C17.3285 28.2561 17.7477 27.9053 18.0169 27.6254C18.2188 27.4155 18.3081 27.1284 18.3081 26.8372V23.615Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M57.0591 23.3212C57.0591 22.7961 57.2258 22.2825 57.5716 21.8873C58.1141 21.2673 58.9726 20.4088 59.567 20.4088C60.3061 20.4088 60.6368 21.6855 60.7414 22.2102C60.7708 22.3574 60.7805 22.5066 60.7805 22.6567V26.2426C60.7805 26.3439 60.7664 26.4434 60.7346 26.5397C60.5552 27.0843 59.867 28.9842 59.0007 28.9842C58.2388 28.9842 57.5229 27.9281 57.2161 27.4072C57.1078 27.2232 57.0591 27.0123 57.0591 26.7988V23.3212Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M21.6249 23.3212C21.6249 22.7961 21.4582 22.2825 21.1124 21.8873C20.5698 21.2673 19.7113 20.4088 19.117 20.4088C18.3778 20.4088 18.0472 21.6855 17.9425 22.2102C17.9132 22.3574 17.9035 22.5066 17.9035 22.6567V26.2426C17.9035 26.3439 17.9176 26.4434 17.9493 26.5397C18.1288 27.0843 18.817 28.9842 19.6833 28.9842C20.4451 28.9842 21.161 27.9281 21.4678 27.4072C21.5762 27.2232 21.6249 27.0123 21.6249 26.7988V23.3212Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M53.1758 23.5043C53.1758 22.8684 53.4225 22.255 53.8945 21.8289C54.6044 21.1882 55.6661 20.3279 56.25 20.3279C56.7617 20.3279 57.151 20.7195 57.3978 21.0719C57.6373 21.4139 57.7062 21.837 57.7062 22.2546V26.4337C57.7062 26.6205 57.6572 26.8031 57.5582 26.9616C57.1296 27.6473 55.8655 29.5505 55.0365 29.5505C54.1903 29.5505 53.4575 27.6248 53.2407 26.9959C53.1959 26.8659 53.1758 26.7299 53.1758 26.5924V23.5043Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M25.5082 23.5043C25.5082 22.8684 25.2615 22.255 24.7895 21.8289C24.0796 21.1882 23.0178 20.3279 22.434 20.3279C21.9223 20.3279 21.533 20.7195 21.2862 21.0719C21.0467 21.4139 20.9778 21.837 20.9778 22.2546V26.4337C20.9778 26.6205 21.0268 26.8031 21.1258 26.9616C21.5543 27.6473 22.8184 29.5505 23.6475 29.5505C24.4936 29.5505 25.2265 27.6248 25.4432 26.9959C25.4881 26.8659 25.5082 26.7299 25.5082 26.5924V23.5043Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M47.4319 23.4435C47.4319 22.8861 47.5699 22.3335 47.9023 21.886C48.4531 21.1443 49.4284 20.0853 50.5061 20.0853C51.7167 20.0853 52.798 21.8527 53.2937 22.8072C53.4949 23.1947 53.5802 23.6272 53.5802 24.0639V29.1999C53.5802 29.7593 53.1424 30.2207 52.5838 30.2501L48.8795 30.4451C48.0926 30.4865 47.4319 29.8597 47.4319 29.0717V23.4435Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M31.2521 23.4435C31.2521 22.8861 31.114 22.3335 30.7817 21.886C30.2308 21.1443 29.2556 20.0853 28.1779 20.0853C26.9673 20.0853 25.886 21.8527 25.3903 22.8072C25.1891 23.1947 25.1037 23.6272 25.1037 24.0639V29.1999C25.1037 29.7593 25.5416 30.2207 26.1001 30.2501L29.8045 30.4451C30.5914 30.4865 31.2521 29.8597 31.2521 29.0717V23.4435Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M39.342 24.0494C39.342 21.8154 41.153 20.0044 43.387 20.0044C45.621 20.0044 47.432 21.8154 47.432 24.0494V29.146C47.432 29.9056 46.8163 30.5213 46.0567 30.5213H40.7173C39.9578 30.5213 39.342 29.9056 39.342 29.146V24.0494Z"
      fill="white"
      stroke="#BE5C5C"
    />
    <path
      d="M39.342 24.0494C39.342 21.8154 37.531 20.0044 35.2971 20.0044C33.0631 20.0044 31.2521 21.8154 31.2521 24.0494V29.146C31.2521 29.9056 31.8678 30.5213 32.6274 30.5213H37.9667C38.7263 30.5213 39.342 29.9056 39.342 29.146V24.0494Z"
      fill="white"
      stroke="#BE5C5C"
    />
  </svg>
)

export default AroundScope
