import * as React from 'react'

const UnavailableCloud = () => {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M52.3203 16.148C51.3672 14.7642 50.2621 13.4992 48.9874 12.423C45.5157 9.35109 40.9701 7.52768 36 7.52768C25.7849 7.52768 17.3433 15.3201 16.2941 25.3853C7.45297 27.4584 1.03413 35.4006 1.03413 44.6665C1.03413 49.0872 2.48275 53.1581 4.9553 56.455C5.95098 57.7992 7.1251 58.9955 8.42243 60.0458L0 68.4682L3.53163 72L72 3.53163L68.4684 0L52.3203 16.148Z"
        fill="#E1E5FE"
      />
      <path
        d="M51.1602 64.4718C62.0745 64.4718 70.9659 55.5806 70.9659 44.6663C70.9659 35.8 65.0967 28.1574 56.8795 25.7098L18.2922 64.297C19.1165 64.4221 19.9658 64.4718 20.8398 64.4718H51.1602Z"
        fill="#E1E5FE"
      />
    </svg>
  )
}

export default UnavailableCloud
