import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, IconButton } from '@material-ui/core'
import moment from 'moment'
import { Grid } from '@material-ui/core'
import BaseModal from 'components/common/modals/BaseModal'
import { useTranslation } from 'react-i18next'
import OggHeading32 from 'components/common/text/OggHeading32'
import CustomDatePicker from 'components/common/CustomDatePicker'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import LabeledInputContainer from 'components/common/inputs/LabeledInputContainer'
import { Mail, Phone } from 'components/common/icons'
import { getPeriodTypeOptions, scanFrequencyCustomOption, ScanFrequencyOptions } from 'utils/statusUtils'
import usePatientTags from 'hooks/usePatientTags'
import SelectInput from 'components/common/SelectInput'
import TagsList from 'components/common/tags/TagsList'
import PatientStatusUpdate from 'components/Patients/PatientStatusUpdate'
import ProfilePicture from 'components/common/ProfilePicture'
import useRolePermissions from 'hooks/useRolePermissions'
import Actions from 'actions'
import isEmpty from 'lodash'
import FormInput from 'components/common/FormInput'

const useStyles = makeStyles({
  root: {
    paddingRight: 0,
    paddingLeft: 0
  },
  headerContainer: {
    backgroundColor: '#F9F9F9',
    padding: 10
  },
  patientName: {
    maxWidth: '85%',
    margin: '0 auto',
    wordWrap: 'break-word'
  },
  closeIcon: {
    right: 0,
    top: 0
  },
  tagsList: {
    justifyContent: 'center'
  },
  profilePictureContainer: {
    marginBottom: 5
  },
  contactIconContainer: {
    border: '0.5px solid #3C52EF',
    borderRadius: '50%',
    height: 43,
    width: 43
  },
  programTagContainer: {
    position: 'relative',
    width: 'fit-content',
    margin: '-10px auto 0 auto',
    borderRadius: '5px',
    padding: '2px 5px'
  },
  statusPicker: {
    margin: 0,
    maxWidth: '180px'
  },
  programTag: {
    color: ({ program }) => (program === 'ortho' ? 'var(--text-color-1)' : 'var(--text-color-7)'),
    textTransform: ({ program }) => (program === 'serviceAccount' ? 'none' : 'uppercase')
  },
  rm: {
    background: 'var(--bg-color-14)'
  },
  rc: {
    background: 'var(--bg-color-13)'
  },
  ortho: {
    background: 'var(--bg-color-26)'
  },
  serviceAccount: {
    background: 'var(--bg-color-14)'
  },
  inputWrapper: {
    fontSize: 15,
    padding: '0 0 0 10px !important'
  },
  inputSuffixClassname: {
    marginLeft: ({ cycleInterval }) => (cycleInterval >= 10 ? 5 : -5),
    fontWeight: 500,
    color: 'black',
    alignSelf: 'center'
  },
  inputClassName: {
    paddingLeft: '2px !important',
    width: '15% !important'
  },
  scanFrequencyDropdown: {
    maxHeight: '100%'
  }
})

const PatientCard = ({ patient }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const customInputRef = useRef(null)
  const { permissions } = useRolePermissions()
  const { data: treatment, isUpdating } = useSelector(state => state.patientsReducer.patientCard.treatment)
  const { tags: patientTags } = usePatientTags({
    patient,
    tagsTypes: ['status', 'authStatus', 'treatment', 'scan', 'custom']
  })
  const { data: statuses } = useSelector(state => state.treatmentReducer.statuses)
  const isMobilePatientCardOpen = useSelector(state => state.patientsReducer.mobilePatientCard.isModalOpen)
  const [isCustomFrequency, setIsCustomFrequency] = useState(false)
  const [cycleIntervalPeriodType, setCycleIntervalPeriodType] = useState('')
  const [treatmentEndDate, setTreatmentEndDate] = useState(null)
  const [treatmentStartDate, setTreatmentStartDate] = useState(null)
  const [cycleInterval, setCycleInterval] = useState('')
  const [status, setStatus] = useState(null)
  const [errors, setErrors] = useState({})
  const [nextScanDate, setNextScanDate] = useState()
  const [, setForceUpdate] = useState()
  const classes = useStyles({ program: patient.user?.program, cycleInterval })

  const treatmentCycleInterval = useMemo(() => treatment?.cycleInterval, [treatment?.cycleInterval])
  const treatmentCycleIntervalPeriodType = useMemo(
    () => treatment?.cycleIntervalPeriodType,
    [treatment?.cycleIntervalPeriodType]
  )

  const originalStatus = useMemo(() => treatment?.currentStatus?.status, [treatment])
  const programStatuses = useMemo(() => statuses[patient.user?.program] || [], [statuses, patient])
  const postConfirmationStatus = useMemo(
    () =>
      treatment?.currentStatus?.status?.type === 'invited'
        ? programStatuses.find(option => option.key === treatment?.postConfirmationStatusKey)
        : null,
    [programStatuses, treatment]
  )
  const areScanSettingsDisabled = useMemo(() => !status?.scanCyclesEnabled, [status])
  const { frequencyOptions, statusScanDefaults } = useMemo(() => {
    let options = []
    let statusScanDefaults = {}

    if (areScanSettingsDisabled) {
      options = ScanFrequencyOptions[postConfirmationStatus?.type || 'active-treatment']
      statusScanDefaults = JSON.parse(postConfirmationStatus?.scanFrequencyDefaults || '{}')
    } else {
      options = ScanFrequencyOptions[status?.type]
      statusScanDefaults = JSON.parse(status?.scanFrequencyDefaults || '{}')
    }

    let frequencyOptions = options.map(option => ({
      label: option.toString().concat(' ' + t(`periodTypes.${statusScanDefaults.periodType}`)),
      value: option
    }))

    frequencyOptions = frequencyOptions.concat(scanFrequencyCustomOption)

    return { frequencyOptions, statusScanDefaults }
  }, [areScanSettingsDisabled, postConfirmationStatus, status, t])

  const validateForm = useCallback(() => {
    const startOfCurrentCycleEndDate = moment(nextScanDate).startOf('day')
    const startOfTreatmentStartDate = moment(treatmentStartDate).startOf('day')
    const startOfTreatmentEndDate = moment(treatmentEndDate).startOf('day')

    let errors = {}

    if (
      startOfCurrentCycleEndDate.isBefore(startOfTreatmentStartDate) ||
      (status?.type !== 'retention' && startOfCurrentCycleEndDate.isAfter(startOfTreatmentEndDate))
    ) {
      errors.nextScanDate = t('dialogs.scanFrequency.nextScanDateError')
    }

    setErrors(errors)
  }, [nextScanDate, status, t, treatmentEndDate, treatmentStartDate])

  const handleCycleInterval = useCallback(interval => +interval || 7, [])

  const handleClose = useCallback(() => dispatch(Actions.toggleMobilePatientCard({ isModalOpen: false })), [dispatch])

  const handleSubmit = useCallback(() => {
    validateForm()

    if (!isEmpty(errors)) {
      return
    }

    let updateTreatmentInput = {
      treatmentId: treatment.id,
      date: treatmentStartDate && moment(treatmentStartDate).toISOString(),
      endDate: treatmentEndDate && treatmentEndDate?.format('YYYY-MM-DD'),
      type: patient.treatments.items[0].type,
      cycleInterval: handleCycleInterval(cycleInterval),
      cycleIntervalPeriodType
    }

    if (!areScanSettingsDisabled) {
      updateTreatmentInput.nextScanDate = nextScanDate.format('YYYY-MM-DD')
    }
    dispatch(Actions.updateTreatment(updateTreatmentInput))
  }, [
    errors,
    validateForm,
    patient,
    dispatch,
    handleCycleInterval,
    areScanSettingsDisabled,
    nextScanDate,
    cycleInterval,
    treatment,
    cycleIntervalPeriodType,
    treatmentEndDate,
    treatmentStartDate
  ])

  const setCustomValue = useCallback(
    value => {
      if (value === '0' || +value > 90 || !value.toString().match('^[0-9]*$')) {
        setForceUpdate({})
      } else {
        setCycleInterval(value)
      }
    },
    [setCycleInterval]
  )

  useEffect(() => {
    if (patient.id && !treatment) {
      dispatch(Actions.requestPatientTreatment(patient))
    }
  }, [dispatch, patient, treatment])

  useEffect(() => {
    if (patient.cycles?.items?.length) {
      const currCycle = patient.cycles.items[0]
      setNextScanDate(moment(currCycle.originalEndDate))
    }
  }, [patient.cycles])

  useEffect(() => {
    if (treatment?.id) {
      if (treatment?.endDate) {
        setTreatmentEndDate(moment(treatment?.endDate))
      } else {
        setTreatmentEndDate(null)
      }
      setTreatmentStartDate(treatment?.date)
    }
  }, [status, treatment])

  useEffect(() => {
    setStatus(originalStatus)
  }, [originalStatus, status])

  useEffect(() => {
    setCycleInterval(treatmentCycleInterval)
    setCycleIntervalPeriodType(treatmentCycleIntervalPeriodType)
  }, [treatmentCycleInterval, treatmentCycleIntervalPeriodType])

  useEffect(() => {
    const isCustomScanFrequency =
      cycleIntervalPeriodType !== statusScanDefaults.periodType ||
      !frequencyOptions.find(option => option.value === treatmentCycleInterval)
    setIsCustomFrequency(isCustomScanFrequency)
  }, [treatmentCycleInterval, frequencyOptions, cycleIntervalPeriodType, statusScanDefaults])

  const periodTypeOptions = useMemo(() => getPeriodTypeOptions(status?.key), [status])

  return patient?.user ? (
    <BaseModal
      fullScreen
      variant="fullscreen"
      open={isMobilePatientCardOpen}
      handleClose={handleClose}
      primaryLabel={t('general.saveChanges')}
      largerButtons={true}
      contentClassName={classes.root}
      onPrimaryBtnClick={handleSubmit}
      isPrimaryDisabled={areScanSettingsDisabled || (isCustomFrequency && cycleInterval === '')}
      isLoading={isUpdating}
      closeIconClassName={classes.closeIcon}
    >
      <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid item style={{ marginBottom: 15 }}>
          <Grid container justifyContent="center" alignItems="center" spacing={1} className={classes.headerContainer}>
            <Grid item xs={12}>
              <OggHeading32 className={classes.patientName}>{patient.details.name}</OggHeading32>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item sm>
                  <Grid container justifyContent="center" alignItems="center">
                    <a href={`tel:+${patient.details.countryCode}${patient.details.phone}`}>
                      <IconButton className={classes.contactIconContainer}>
                        <Phone />
                      </IconButton>
                    </a>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    className={classes.profilePictureContainer}
                  >
                    <Grid item>
                      <ProfilePicture
                        photo={patient.photo}
                        name={patient.details.name}
                        size="large"
                        alt="Profile picture"
                      />
                    </Grid>
                    <Grid
                      item
                      className={`${classes.programTagContainer} 
                        ${patient.user.program === 'rm' && classes.rm} 
                        ${patient.user.program === 'rc' && classes.rc}
                        ${patient.user.program === 'ortho' && classes.ortho}
                        ${patient.user.program === 'serviceAccount' && classes.serviceAccount} `}
                    >
                      <DazzedParagraph12 className={classes.programTag}>
                        {t(`common.tags.${patient.user.program}`)}
                      </DazzedParagraph12>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <a href={`mailto:${patient.details.email}`}>
                        <IconButton className={classes.contactIconContainer}>
                          <Mail />
                        </IconButton>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TagsList
                  tags={patientTags}
                  className={classes.tagsList}
                  canDeleteCustom={false}
                  canDeleteScan={false}
                  showIcon={false}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={5}>
                    <PatientStatusUpdate patient={patient} analyticsSource="Patient card mobile" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11}>
          {!areScanSettingsDisabled && (
            <LabeledInputContainer
              title={t('dialogs.patientInfo.scanSettings.setNextScan')}
              tooltip={t('dialogs.invitePatient.firstScanDateTooltip')}
              isMobile
            >
              <CustomDatePicker
                disabled={!nextScanDate || areScanSettingsDisabled || !permissions.editPatientScanSettings}
                value={nextScanDate?.toDate()}
                handleDateChange={newDate => setNextScanDate(newDate ? moment(newDate) : null)}
                minDateMessage={null}
                disablePast={true}
                error={errors?.nextScanDate}
              />
            </LabeledInputContainer>
          )}
        </Grid>
        <Grid item xs={11}>
          <LabeledInputContainer
            title={t('dialogs.invitePatient.scanFrequency')}
            tooltip={t('tooltips.cycleInterval', {
              periodType: t(`periodTypes.${cycleIntervalPeriodType}`)
            })}
            isMobile
          >
            <SelectInput
              style={{ bright: true, thick: true, width: '100%' }}
              dropdownClassName={classes.scanFrequencyDropdown}
              options={frequencyOptions}
              value={isCustomFrequency ? 'custom' : cycleInterval}
              onChange={option => {
                let cycleInterval = option.value
                if (option.value === 'custom') {
                  setIsCustomFrequency(true)
                  cycleInterval = 7
                } else {
                  setIsCustomFrequency(false)
                  setCycleInterval(cycleInterval)
                }
              }}
              disabled={areScanSettingsDisabled || !permissions.editPatientScanSettings}
            />
          </LabeledInputContainer>
          {isCustomFrequency && (
            <>
              <FormInput
                withCounter
                name="customScanFrequency"
                inputClassName={classes.inputClassName}
                inputSuffix={periodTypeOptions.length === 1 ? t('dialogs.scanFrequency.daysLowercase') : null}
                inputSuffixClassname={classes.inputSuffixClassname}
                maxLength={2}
                style={{ bright: true, thick: false }}
                setValue={setCustomValue}
                value={cycleInterval}
                validationRules={{ required: true, pattern: '^[0-9]+$' }}
                inputWrapperClassName={classes.inputWrapper}
                disabledDecrement={cycleInterval <= 1 || isUpdating}
                disabledIncrement={cycleInterval >= 90 || isUpdating}
                ref={customInputRef}
              />
              {periodTypeOptions.length > 1 && (
                <SelectInput
                  style={{ bright: true, thick: true, width: '100%' }}
                  dropdownClassName={classes.scanFrequencyDropdown}
                  options={periodTypeOptions}
                  value={cycleIntervalPeriodType}
                  onChange={({ value }) => setCycleIntervalPeriodType(value)}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </BaseModal>
  ) : null
}

export default PatientCard
