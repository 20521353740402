import * as React from 'react'

const TimelineArrowHorizontal = ({ color, className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="1581"
      viewBox="0 0 16 1581"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM7.29289 1580.71C7.68342 1581.1 8.31658 1581.1 8.70711 1580.71L15.0711 1574.34C15.4616 1573.95 15.4616 1573.32 15.0711 1572.93C14.6805 1572.54 14.0474 1572.54 13.6569 1572.93L8 1578.59L2.34315 1572.93C1.95262 1572.54 1.31946 1572.54 0.928932 1572.93C0.538408 1573.32 0.538408 1573.95 0.928932 1574.34L7.29289 1580.71ZM7 1L7 1580H9L9 1H7Z"
        fill={color}
      />
    </svg>
  )
}

export default TimelineArrowHorizontal
