import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ProfilePicture from 'components/common/ProfilePicture'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  root: {
    width: '640px'
  },
  roleDescription: {
    color: 'var(--text-color-11)'
  }
}))

const DeactivatePracticeMemberModal = ({ name, roleDescription, avatar }) => {
  const classes = useStyles()

  return (
    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <ProfilePicture size="small" name={name} photo={avatar} />
          </Grid>
          <Grid item>
            <DazzedParagraph14>{name}</DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <DazzedParagraph14 className={classes.roleDescription}>{roleDescription}</DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default DeactivatePracticeMemberModal
