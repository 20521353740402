import * as React from 'react'

function UploadRecords(props) {
  return (
    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 3C1 1.89543 1.89543 1 3 1H9V7H15V18C15 19.1046 14.1046 20 13 20H3C1.89543 20 1 19.1046 1 18V3Z"
        stroke="#4151E6"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M8.79072 17.214H7.19697V11.8749L4.96572 14.1062L3.8501 12.9905L7.94072 8.8999L12.0845 12.9374L10.9688 14.053L8.79072 11.8749V17.214Z"
        fill="#4151E6"
      />
      <path d="M9 1L15 7" stroke="#4151E6" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  )
}

export default UploadRecords
