import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { isMobile } from 'react-device-detect'
import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'

const useStyles = makeStyles(theme => ({
  paragraph: {
    margin: '40px 0',
    lineHeight: '30px'
  },
  separator: {
    height: 30
  },
  link: {
    cursor: 'pointer'
  }
}))

const DownloadApp = () => {
  const classes = useStyles()

  const { t } = useTranslation()

  const link = useMemo(() => t('pages.rcLanding.success.downloadAppLink'), [t])

  const handleClick = useCallback(() => {
    const tab = window.open(link, '_blank')
    tab.focus()
  }, [link])

  if (!isMobile) {
    return (
      <>
        <DazzedParagraph16 textAlign="center" className={classes.paragraph}>
          <div>{t('pages.rcLanding.success.downloadAppText')}</div>
          {!isMobile && <div>{t('pages.rcLanding.success.scanQrCode')}</div>}
        </DazzedParagraph16>
        <QRCode value={link} />
      </>
    )
  }

  return (
    <div>
      <img
        src="https://d1g4lo0dz8n4nh.cloudfront.net/images/rc-landing-page/GooglePlay.png"
        alt="Download the Grin App"
        onClick={handleClick}
        className={classes.link}
      />
      <div className={classes.separator} />
      <img
        src="https://d1g4lo0dz8n4nh.cloudfront.net/images/rc-landing-page/AppStore.png"
        alt="Download the Grin App"
        className={classes.link}
        onClick={handleClick}
      />
    </div>
  )
}

export default DownloadApp
