import React, { useMemo, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedHeading14 from 'components/common/text/DazzedHeading14'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import BaseModal from 'components/common/modals/BaseModal'
import { Grid, ButtonBase, CircularProgress } from '@material-ui/core'
import CheckBoxFilter from './CheckBoxFilter'
import SearchIcon from 'components/common/icons/Search'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import Actions from 'actions'
import { useDispatch } from 'react-redux'
import FilterMenuSection from './FilterMenuSection.mobile'
import { AsyncStatus } from 'consts'

const useStyles = makeStyles(theme => ({
  container: { backgroundColor: '#F9F9F9', padding: 10 },
  actionsContainer: { backgroundColor: '#F9F9F9', paddingTop: 20 },
  sectionContainer: { backgroundColor: '#F9F9F9' },
  headerContainer: { paddingLeft: 12, paddingRight: 12 },
  header: { color: '#727B8C' },
  divider: { marginLeft: -8, marginRight: -8 },
  checkboxes: {},
  buttonContainer: {
    width: '100%',
    height: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    justifyContent: 'space-between',
    color: '#727B8C'
  },
  customTagsInnerContainer: {
    maxHeight: 250,
    overflow: 'overlay',
    paddingBottom: 8
  },
  customTagsFilterContainer: {
    border: '1px solid #CBCBCB',
    paddingLeft: 5,
    borderRadius: 100,
    height: 40,
    '&:focus': {
      outline: 'none'
    }
  },
  customTagsFilter: {
    fontSize: 16,
    border: 0,
    fontFamily: 'Dazzed',
    fontWeight: 400,
    width: '95%',
    lineHeight: '24px',
    outline: 'none',
    '&:placeholder': {
      color: 'var(--text-color-2)'
    }
  },
  customTagsEmptyStateText: {
    color: 'var(--text-color-11)',
    textAlign: 'center',
    margin: '0 10px'
  }
}))

const FilterMenu = ({
  open = false,
  onClose = () => {},
  customTags = [],
  programs = [],
  treatments = [],
  statuses = [],
  selectedFilters = [],
  assigneTags = [],
  scanTags,
  onFilterSelected = () => {},
  onApply = () => {},
  onClearAll = () => {},
  onTagsFilterChanged = () => {},
  customTagsLoadingState
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [openedSections, setOpenedSections] = useState({
    byStatus: false,
    byTreatment: false,
    byProgram: false,
    byAssignee: false,
    scanTags: false
  })

  const toggleSection = sectionName =>
    setOpenedSections({ ...openedSections, [sectionName]: !openedSections[sectionName] })

  const parsedSelectedFilters = useMemo(() => {
    let parsedFilters = []
    Object.keys(selectedFilters).forEach(filter => {
      parsedFilters[filter] = selectedFilters[filter].map(currFilter => ({
        value: currFilter.value,
        label: currFilter.label
      }))
    })

    return parsedFilters
  }, [selectedFilters])

  const hasSelectedTags = useMemo(
    () => Object.keys(selectedFilters).find(tagType => !!selectedFilters[tagType].length),
    [selectedFilters]
  )

  const [customTagsFilter, setCustomTagsFilter] = useState('')

  const handleCustomTagsFilterChanged = useCallback(
    filter => {
      setCustomTagsFilter(filter)
      onTagsFilterChanged(filter)
    },
    [setCustomTagsFilter, onTagsFilterChanged]
  )

  const handleClose = useCallback(() => {
    setCustomTagsFilter('')
    onClose()
  }, [setCustomTagsFilter, onClose])

  return (
    <BaseModal
      fullScreen
      variant="fullscreen"
      open={open}
      handleClose={handleClose}
      primaryLabel={t('pages.patients.patientsList.patientsTagFilter.submitLabel')}
      secondaryLabel={t('pages.patients.patientsList.patientsTagFilter.clearLabel')}
      isPrimaryDisabled={!hasSelectedTags}
      actionsClassName={classes.actionsContainer}
      contentClassName={classes.container}
      onPrimaryBtnClick={onApply}
      onSecondaryBtnClick={onClearAll}
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ backgroundColor: 'white' }} justifyContent="center">
            <Grid item xs={12}>
              <Grid container spacing={2} style={{ padding: 12 }}>
                <Grid item xs={12}>
                  <DazzedHeading14>{t('pages.patients.patientsList.patientsTagFilter.tagsTitle')}</DazzedHeading14>
                </Grid>

                <Grid item xs={12}>
                  <Grid container className={classes.customTagsFilterContainer} alignItems="center">
                    <Grid item xs={2}>
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <SearchIcon />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={10}>
                      <input
                        className={classes.customTagsFilter}
                        placeholder={t('pages.patients.patientsList.patientsTagFilter.searchPlaceholder')}
                        type="text"
                        name="patients-search"
                        value={customTagsFilter}
                        autoComplete="off"
                        onChange={({ target }) => {
                          handleCustomTagsFilterChanged(target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {customTagsLoadingState === AsyncStatus.Loading ? (
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <CircularProgress size={16} />
                  </Grid>
                </Grid>
              ) : customTagsLoadingState === AsyncStatus.Failed ? (
                <Grid item>
                  <Grid container justifyContent="center" alignItems="center">
                    <ButtonBase onClick={() => dispatch(Actions.fetchAggregatedPracticeCustomTags())} disableRipple>
                      <DazzedParagraph12 className={classes.customTagsEmptyStateText}>
                        {t('pages.patients.patientsList.patientsTagFilter.failedToLoadTags')}
                      </DazzedParagraph12>
                    </ButtonBase>
                  </Grid>
                </Grid>
              ) : customTags.length ? (
                <CheckBoxFilter
                  className={classes.customTagsInnerContainer}
                  filterOptions={customTags}
                  selectedFilters={parsedSelectedFilters}
                  onChange={onFilterSelected}
                />
              ) : (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <DazzedParagraph14 className={classes.customTagsEmptyStateText}>
                      {t('pages.patients.patientsList.patientsTagFilter.filteredCustomTagsEmptyState', {
                        filter: customTagsFilter
                      })}
                    </DazzedParagraph14>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <FilterMenuSection
              label={t('pages.patients.patientsList.patientsTagFilter.statusTitle')}
              isOpen={openedSections.byStatus}
              tags={statuses}
              onClick={() => toggleSection('byStatus')}
              onFilterSelected={onFilterSelected}
              parsedSelectedFilters={parsedSelectedFilters}
            />
            <FilterMenuSection
              label={t('pages.patients.patientsList.patientsTagFilter.treatmentTypeTitle')}
              tags={treatments}
              isOpen={openedSections.byTreatment}
              onClick={() => toggleSection('byTreatment')}
              onFilterSelected={onFilterSelected}
              parsedSelectedFilters={parsedSelectedFilters}
            />
            <FilterMenuSection
              label={t('pages.patients.patientsList.patientsTagFilter.scanFiltersTitle')}
              isOpen={openedSections.scanTags}
              onFilterSelected={onFilterSelected}
              parsedSelectedFilters={parsedSelectedFilters}
              tags={scanTags}
              onClick={() => toggleSection('scanTags')}
            />
            <FilterMenuSection
              label={t('pages.patients.patientsList.patientsTagFilter.programTitle')}
              isOpen={openedSections.byProgram}
              tags={programs}
              onClick={() => toggleSection('byProgram')}
              onFilterSelected={onFilterSelected}
              parsedSelectedFilters={parsedSelectedFilters}
            />
            <FilterMenuSection
              label={t('pages.patients.patientsList.patientsTagFilter.assignee')}
              isOpen={openedSections.byAssignee}
              tags={assigneTags}
              onClick={() => toggleSection('byAssignee')}
              onFilterSelected={onFilterSelected}
              parsedSelectedFilters={parsedSelectedFilters}
            />
          </Grid>
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default FilterMenu
