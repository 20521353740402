import React, { useMemo } from 'react'
import { makeStyles, Grid, CircularProgress } from '@material-ui/core'
import TrashIcon from '../../../common/icons/TrashOutlined'
import FilePlaceholder from './FilePlaceholder'
import MagnifyingGlass from 'components/common/icons/MagnifyingGlass'
import { Replace } from 'components/common/icons'
import { useTranslation } from 'react-i18next'
import DazzedParagraph10 from 'components/common/text/DazzedParagraph10'
import GrinCheckbox from 'components/common/GrinCheckbox'

const useStyles = makeStyles(theme => ({
  itemWrapper: {
    position: 'relative',
    borderRadius: '8px',
    width: '70px',
    height: '52px',
    '&:hover > div': {
      opacity: 1
    }
  },
  item: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    borderRadius: '8px'
  },
  itemHover: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: '.3s',
    padding: '2px 5px 2px 5px'
  },
  actionIcon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)!important'
  },
  itemLoadingContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  trashContainer: {
    position: 'absolute',
    top: -5,
    right: -5,
    background: '#fff',
    boxShadow: '0px 1px 5px -1px rgba(0, 0, 0, 0.5)',
    padding: '3px 5px 0 5px',
    borderRadius: 10,
    cursor: 'pointer',
    opacity: 0,
    transition: '.3s',
    '&:hover': {
      opacity: 1
    }
  },
  checkFileContainer: {
    position: 'absolute',
    top: 3,
    right: 3,
    background: '#fff',
    borderRadius: 5,
    cursor: 'pointer'
  },
  actionItemContainer: {
    width: '100%',
    cursor: 'pointer',
    backgroundColor: 'rgba(255,255,255,0.7)',
    padding: '1px 2px 1px 2px',
    borderRadius: 5
  },
  viewItemContainer: {
    marginBottom: 2
  },
  actionItemLabel: {
    color: 'var(--text-color-25)'
  },
  checkbox: {
    height: 16,
    width: 16
  }
}))

const UploadsFile = ({
  url,
  isLoading,
  itemKey,
  placeholderIcon,
  type,
  onDeletePhoto,
  onSelect = () => {},
  onOpen,
  onReplace,
  previewRenderer,
  isSelectionMode = false,
  selectedFiles = [],
  onFileToggled
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const isFileSelected = useMemo(
    () => isSelectionMode && !!selectedFiles.find(file => file.key === itemKey),
    [isSelectionMode, itemKey, selectedFiles]
  )

  return (
    <Grid item>
      {url ? (
        <div className={classes.itemWrapper}>
          {previewRenderer ? previewRenderer() : <img src={url} alt="upload" className={classes.item} />}
          {isLoading ? (
            <div className={classes.itemLoadingContainer}>
              <CircularProgress color="secondary" size={25} />
            </div>
          ) : (
            <>
              {onOpen && (
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  wrap="nowrap"
                  className={classes.itemHover}
                >
                  <Grid
                    item
                    onClick={onOpen}
                    className={`${classes.actionItemContainer} ${onReplace ? classes.viewItemContainer : ''}`}
                  >
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item>
                        <MagnifyingGlass />
                      </Grid>
                      <Grid item>
                        <DazzedParagraph10 thickness="bold" className={classes.actionItemLabel}>
                          {t('dialogs.patientInfo.records.oralPhotoHover.view')}
                        </DazzedParagraph10>
                      </Grid>
                    </Grid>
                  </Grid>
                  {!isSelectionMode && onReplace && (
                    <Grid item className={classes.actionItemContainer} onClick={onReplace}>
                      <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
                        <Grid item>
                          <Replace />
                        </Grid>
                        <Grid item>
                          <DazzedParagraph10 thickness="bold" className={classes.actionItemLabel}>
                            {t('dialogs.patientInfo.records.oralPhotoHover.replace')}
                          </DazzedParagraph10>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
              {isSelectionMode ? (
                <div className={classes.checkFileContainer}>
                  <GrinCheckbox
                    variant="filled"
                    checked={isFileSelected}
                    onChange={() =>
                      onFileToggled({
                        key: itemKey,
                        url,
                        type
                      })
                    }
                    borderColor="var(--bg-color-43)"
                    className={classes.checkbox}
                  />
                </div>
              ) : (
                onDeletePhoto && (
                  <div className={classes.trashContainer} onClick={() => onDeletePhoto(type, itemKey)}>
                    <TrashIcon />
                  </div>
                )
              )}
            </>
          )}
        </div>
      ) : (
        <FilePlaceholder placeholderIcon={placeholderIcon} isLoading={isLoading} />
      )}
    </Grid>
  )
}

export default UploadsFile
