import React from 'react'
import { makeStyles } from '@material-ui/styles'
import ProfilePicture from 'components/common/ProfilePicture'
import classNames from 'classnames'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  avatarContainer: {
    marginRight: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  avatarLabelContainer: {
    width: 'fit-content',
    margin: '-10px auto 0 auto',
    position: 'relative',
    padding: '2px 5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px'
  },
  avatarLabelText: {
    color: ({ program }) => (program === 'ortho' ? 'var(--text-color-1)' : 'var(--text-color-7)'),
    textTransform: ({ program }) => (program === 'serviceAccount' ? 'none' : 'uppercase')
  },
  rm: {
    background: 'var(--bg-color-14)'
  },
  rc: {
    background: 'var(--bg-color-13)'
  },
  ortho: {
    background: 'var(--bg-color-26)'
  },
  serviceAccount: {
    background: 'var(--bg-color-14)'
  }
}))

const PatientAvatar = ({ name, photo, program }) => {
  const classes = useStyles({ program })
  const { t } = useTranslation()

  return (
    <div className={classes.avatarContainer}>
      <ProfilePicture name={name} photo={photo} size="largeBig" alt="Profile Picture" />
      {program && (
        <div
          className={classNames(
            classes.avatarLabelContainer,
            program === 'rm' && classes.rm,
            program === 'rc' && classes.rc,
            program === 'ortho' && classes.ortho,
            program === 'serviceAccount' && classes.serviceAccount
          )}
        >
          <DazzedParagraph12
            className={classes.avatarLabelText}
            textAlign={program === 'serviceAccount' ? 'center' : 'left'}
          >
            {t(`common.tags.${program}`)}
          </DazzedParagraph12>
        </div>
      )}
    </div>
  )
}

export default PatientAvatar
