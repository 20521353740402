import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, IconButton } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { Close } from 'components/common/icons'

const useStyles = ({ clickable }) =>
  makeStyles({
    root: {
      margin: '3px',
      padding: '0px 5px 0 22px',
      width: '100%',
      cursor: ({ clickable }) => (clickable ? 'pointer' : 'default'),
      '&:hover, &.selected': {
        backgroundColor: 'var(--bg-color-49)',
        borderRadius: '4px'
      }
    },
    text: {
      color: 'var(--text-color-25)'
    }
  })({ clickable })

const PreventiveFeedbackOption = ({ icon, value, label, onClick, selected = false, onDelete }) => {
  const classes = useStyles({ clickable: !!onClick })

  return (
    <Grid
      container
      alignItems="center"
      spacing={1}
      onClick={onClick || (() => {})}
      className={[classes.root, selected ? 'selected' : '']}
      justifyContent="space-between"
    >
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>{icon}</Grid>
          <Grid item>
            <DazzedParagraph16 className={classes.text}>{label}</DazzedParagraph16>
          </Grid>
        </Grid>
      </Grid>
      {onDelete && (
        <Grid item>
          <IconButton size="small" onClick={onDelete}>
            <Close color={'var(--text-color-25)'} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

export default PreventiveFeedbackOption
