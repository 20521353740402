import React, { useCallback } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { SpeechBubble } from 'components/common/icons'
import Actions from 'actions'
import useBroadcast from 'hooks/useBroadcast'
import { isMobile } from 'utils/mobileUtils'
import useQueryParams from 'hooks/useQueryParams'

const useStyles = () =>
  makeStyles({
    container: {
      padding: 15,
      height: 'var(patient-header-height)'
    },
    subtitle: {
      fontSize: 11
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: 9
    },
    buttonContainer: {
      paddingLeft: 15
    }
  })()

const CreateBroadcast = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { trackBroadcastEvent } = useBroadcast()
  const { addQueryParam } = useQueryParams()

  const handleClickCreateBroadcast = useCallback(() => {
    dispatch(Actions.toggleBroadcastMode(true))
    if (isMobile()) {
      addQueryParam({ mode: 'broadcast' })
    }
    trackBroadcastEvent('Broadcast: Broadcast initialised')
  }, [dispatch, trackBroadcastEvent, addQueryParam])

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid item xs={2} className={classes.iconContainer}>
        <SpeechBubble />
      </Grid>
      <Grid item xs={7}>
        <DazzedParagraph14 bold>{t('pages.patients.patientsList.broadcast.create.title')}</DazzedParagraph14>
        <DazzedParagraph14 className={classes.subtitle}>
          {t('pages.patients.patientsList.broadcast.create.subtitle')}
        </DazzedParagraph14>
      </Grid>
      <Grid item xs={3} className={classes.buttonContainer}>
        <PrimaryButton
          onClick={handleClickCreateBroadcast}
          label={t('pages.patients.patientsList.broadcast.create.create')}
          width="61px"
          height="25px"
          variant="outlined"
          fontColor="var(--text-color-3)"
          fontSize="12px"
          id="broadcast-create"
        />
      </Grid>
    </Grid>
  )
}

export default CreateBroadcast
