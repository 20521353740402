import * as React from 'react'

function ThumbsDown({ isSelected, width, height, unselectedColor = '' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.416 4.74601L14.416 13.3647C14.416 14.1585 15.0586 14.8011 15.8525 14.8011L19.683 14.8011C20.4759 14.8011 21.1194 14.1585 21.1194 13.3647L21.1194 4.74601C21.1194 3.9531 20.4759 3.30957 19.683 3.30957L15.8525 3.30957C15.0586 3.30957 14.416 3.9531 14.416 4.74601ZM15.3736 13.3647L15.3736 4.74601C15.3736 4.48171 15.5882 4.2672 15.8525 4.2672L19.683 4.2672C19.9473 4.2672 20.1618 4.48171 20.1618 4.74601L20.1618 13.3647C20.1618 13.629 19.9473 13.8435 19.683 13.8435L15.8525 13.8435C15.5882 13.8435 15.3736 13.629 15.3736 13.3647Z"
        fill="#727B8C"
        stroke={isSelected ? '#3C52EF' : unselectedColor}
        strokeWidth={0.5}
      />
      <path d="M14.416 4.74601L14.416 13.3647C14.416 14.1585 15.0586 14.8011 15.8525 14.8011L19.683 14.8011C20.4759 14.8011 21.1194 14.1585 21.1194 13.3647L21.1194 4.74601C21.1194 3.9531 20.4759 3.30957 19.683 3.30957L15.8525 3.30957C15.0586 3.30957 14.416 3.9531 14.416 4.74601ZM15.3736 13.3647L15.3736 4.74601C15.3736 4.48171 15.5882 4.2672 15.8525 4.2672L19.683 4.2672C19.9473 4.2672 20.1618 4.48171 20.1618 4.74601L20.1618 13.3647C20.1618 13.629 19.9473 13.8435 19.683 13.8435L15.8525 13.8435C15.5882 13.8435 15.3736 13.629 15.3736 13.3647Z" />
      <path
        d="M1.906 11.979C1.77863 12.6771 1.96824 13.3963 2.42312 13.9412C2.87799 14.486 3.5512 14.8011 4.2608 14.8011L7.14996 14.8011C7.28403 14.8011 7.4114 14.8576 7.50237 14.9562C7.59335 15.0539 7.63835 15.1861 7.62686 15.3201L7.37692 18.3261C7.27541 19.5385 8.09993 20.634 9.29313 20.8725L9.42816 20.8993C9.96443 21.0065 10.5151 20.8006 10.8483 20.3668L15.2735 14.6144C15.3377 14.5311 15.3731 14.4276 15.3731 14.3223L15.3731 5.70364C15.3731 5.50829 15.2534 5.33208 15.0715 5.2593L10.969 3.61793C10.4595 3.41395 9.91655 3.30957 9.36783 3.30957L5.47986 3.30957C4.32305 3.30957 3.33095 4.13696 3.1241 5.27558L1.906 11.979ZM2.84735 12.1504L4.06641 5.447C4.1909 4.76421 4.78558 4.2672 5.47986 4.2672L9.36783 4.2672C9.79493 4.2672 10.2172 4.3486 10.6127 4.50661L14.4155 6.02828L14.4155 14.1595L10.0899 19.7827C9.97784 19.9273 9.79493 19.9962 9.61585 19.9608L9.48083 19.934C8.76452 19.7903 8.27039 19.1334 8.33072 18.4056L8.58162 15.3996C8.61513 14.9993 8.47915 14.6029 8.20719 14.307C7.93522 14.012 7.55121 13.8435 7.14996 13.8435L4.2608 13.8435C3.83466 13.8435 3.4315 13.6548 3.15857 13.3273C2.88565 13.0008 2.77169 12.5689 2.84735 12.1504Z"
        fill="#727B8C"
        stroke={isSelected ? '#3C52EF' : unselectedColor}
        strokeWidth={0.5}
      />
      <path
        d="M1.906 11.979C1.77863 12.6771 1.96824 13.3963 2.42312 13.9412C2.87799 14.486 3.5512 14.8011 4.2608 14.8011L7.14996 14.8011C7.28403 14.8011 7.4114 14.8576 7.50237 14.9562C7.59335 15.0539 7.63835 15.1861 7.62686 15.3201L7.37692 18.3261C7.27541 19.5385 8.09993 20.634 9.29313 20.8725L9.42816 20.8993C9.96443 21.0065 10.5151 20.8006 10.8483 20.3668L15.2735 14.6144C15.3377 14.5311 15.3731 14.4276 15.3731 14.3223L15.3731 5.70364C15.3731 5.50829 15.2534 5.33208 15.0715 5.2593L10.969 3.61793C10.4595 3.41395 9.91655 3.30957 9.36783 3.30957L5.47986 3.30957C4.32305 3.30957 3.33095 4.13696 3.1241 5.27558L1.906 11.979ZM2.84735 12.1504L4.06641 5.447C4.1909 4.76421 4.78558 4.2672 5.47986 4.2672L9.36783 4.2672C9.79493 4.2672 10.2172 4.3486 10.6127 4.50661L14.4155 6.02828L14.4155 14.1595L10.0899 19.7827C9.97784 19.9273 9.79493 19.9962 9.61585 19.9608L9.48083 19.934C8.76452 19.7903 8.27039 19.1334 8.33072 18.4056L8.58162 15.3996C8.61513 14.9993 8.47915 14.6029 8.20719 14.307C7.93522 14.012 7.55121 13.8435 7.14996 13.8435L4.2608 13.8435C3.83466 13.8435 3.4315 13.6548 3.15857 13.3273C2.88565 13.0008 2.77169 12.5689 2.84735 12.1504Z "
        stroke={isSelected ? '#3C52EF' : unselectedColor}
        strokeWidth={0.5}
      />
    </svg>
  )
}

export default ThumbsDown
