import * as React from 'react'

function SvgChatbubble(props) {
  return (
    <svg width={16} height={16} fill="none" {...props}>
      <mask id="Chatbubble_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.454 10.908a5.714 5.714 0 10-1.724 1.662L14 14l-1.546-3.092z"
        />
      </mask>
      <path
        d="M12.454 10.908l-.83-.56-.326.484.261.523.895-.447zm-1.725 1.661l.401-.916-.482-.211-.447.278.528.85zM14 14l-.4.916 2.53 1.107-1.235-2.47L14 14zm-1.57-6.286c0 .977-.297 1.883-.804 2.634l1.657 1.12a6.688 6.688 0 001.146-3.754h-2zM7.713 3a4.714 4.714 0 014.714 4.714h2A6.714 6.714 0 007.714 1v2zM3 7.714A4.714 4.714 0 017.714 3V1A6.714 6.714 0 001 7.714h2zm4.714 4.714A4.714 4.714 0 013 7.714H1a6.714 6.714 0 006.714 6.714v-2zm2.487-.708a4.688 4.688 0 01-2.487.708v2c1.299 0 2.514-.37 3.544-1.01L10.2 11.72zm4.2 1.364l-3.27-1.431-.803 1.832 3.27 1.431.802-1.832zm-2.842-1.73l1.546 3.093 1.789-.894-1.546-3.093-1.789.895z"
        fill="#001335"
        mask="url(#Chatbubble_svg__a)"
      />
    </svg>
  )
}

export default SvgChatbubble
