import Actions from 'actions'
import useQueryParams from 'hooks/useQueryParams'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logInfo } from 'utils/logUtils'

export default () => {
  const dispatch = useDispatch()
  const { queryParams } = useQueryParams()
  const { patient, shouldOpenScanReview } = useSelector(state => state.patientsReducer)

  useEffect(() => {
    if (queryParams.openScanReview) {
      logInfo(`Opening scan review modal from url...`)
      dispatch(Actions.setShouldOpenScanReview(true))
    }
  }, [dispatch, queryParams.openScanReview])

  useEffect(() => {
    if (shouldOpenScanReview && patient?.id) {
      dispatch(Actions.setShouldOpenScanReview(false))
      dispatch(Actions.openScanReviewEditor(patient.grinScans.items[0]))
    }
  }, [dispatch, patient, shouldOpenScanReview])
}
