import React from 'react'
import { makeStyles } from '@material-ui/core'

const useColorPickerStyles = makeStyles({
  colorPicker: {
    display: 'flex'
  }
})

const useStyles = ({ selected, color }) =>
  makeStyles({
    colorCircle: {
      width: 17,
      height: 17,
      backgroundClip: 'content-box',
      padding: 1,
      borderRadius: 17,
      border: ({ selected, color }) => (selected === color ? '1px solid var(--border-color-4)' : 0),
      backgroundColor: ({ color }) => color,
      marginRight: 13,
      cursor: 'pointer'
    }
  })({ selected, color })

const ColorCircle = ({ selected, color, onClick }) => {
  const classes = useStyles({ selected, color })
  return <div className={classes.colorCircle} onClick={onClick} />
}

const ColorPicker = ({ colors, selectedColor, onChangeColor }) => {
  const classes = useColorPickerStyles()
  return (
    <div className={classes.colorPicker}>
      {colors.map(color => (
        <ColorCircle key={color} selected={selectedColor} color={color} onClick={() => onChangeColor(color)} />
      ))}
    </div>
  )
}

export default React.memo(ColorPicker)
