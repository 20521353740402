import * as React from 'react'
const ScheduleSend = ({ color = 'var(--text-color-26)' }) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 14.3158V0L14.8526 6.26316H13.5329C13.175 6.26316 12.8357 6.27061 12.5151 6.28553C12.1945 6.30044 11.9149 6.33026 11.6763 6.375L1.34211 2.01316V5.77105L6.75526 7.15789L1.34211 8.5V12.2355L7.65 9.59605C7.5307 9.84956 7.43004 10.1441 7.34803 10.4796C7.26601 10.8151 7.21009 11.0873 7.18026 11.2961L0 14.3158ZM12.75 16.1053C11.5868 16.1053 10.5877 15.6914 9.75263 14.8638C8.91754 14.0362 8.5 13.0408 8.5 11.8776C8.5 10.6996 8.91754 9.69298 9.75263 8.85789C10.5877 8.02281 11.5868 7.60526 12.75 7.60526C13.9132 7.60526 14.9123 8.02281 15.7474 8.85789C16.5825 9.69298 17 10.6996 17 11.8776C17 13.0408 16.5825 14.0362 15.7474 14.8638C14.9123 15.6914 13.9132 16.1053 12.75 16.1053ZM14.3158 14.1816L14.9645 13.4882L13.1303 11.6539V8.96974H12.2132V12.0789L14.3158 14.1816ZM1.34211 9.59605V2.01316V12.2355V9.59605Z"
        fill={color}
      />
    </svg>
  )
}

export default ScheduleSend
