import * as React from 'react'

const Calendar2 = ({ className, color = '#727B8C' }) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.0327 1.69641H10.9688V0.970192C10.9688 0.435992 10.5357 0.00292969 10.0015 0.00292969H9.9628C9.4286 0.00292969 8.99554 0.435992 8.99554 0.970192V1.69641H4.00446V0.970192C4.00446 0.435992 3.5714 0.00292969 3.0372 0.00292969H2.99851C2.46431 0.00292969 2.03125 0.435992 2.03125 0.970192V1.69641H0.967262C0.433062 1.69641 0 2.12948 0 2.66368V13.0327C0 13.567 0.433062 14 0.967262 14H12.0327C12.5669 14 13 13.567 13 13.0327V2.66368C13 2.12948 12.5669 1.69641 12.0327 1.69641ZM11.375 12.4717H1.625V4.61755H11.375V12.4717Z"
        fill={color}
      />
      <path
        d="M2.94773 8.17181H4.63069C4.71616 8.17181 4.78545 8.10251 4.78545 8.01704V6.33408C4.78545 6.24862 4.71616 6.17932 4.63069 6.17932H2.94773C2.86226 6.17932 2.79297 6.24862 2.79297 6.33408V8.01704C2.79297 8.10251 2.86226 8.17181 2.94773 8.17181Z"
        fill={color}
      />
      <path
        d="M5.65867 8.17181H7.34164C7.4271 8.17181 7.4964 8.10251 7.4964 8.01704V6.33408C7.4964 6.24862 7.4271 6.17932 7.34164 6.17932H5.65867C5.5732 6.17932 5.50391 6.24862 5.50391 6.33408V8.01704C5.50391 8.10251 5.5732 8.17181 5.65867 8.17181Z"
        fill={color}
      />
      <path
        d="M8.36765 8.17181H10.0506C10.1361 8.17181 10.2054 8.10251 10.2054 8.01704V6.33408C10.2054 6.24862 10.1361 6.17932 10.0506 6.17932H8.36765C8.28219 6.17932 8.21289 6.24862 8.21289 6.33408V8.01704C8.21289 8.10251 8.28219 8.17181 8.36765 8.17181Z"
        fill={color}
      />
      <path
        d="M2.94773 10.8802H4.63069C4.71616 10.8802 4.78545 10.8109 4.78545 10.7254V9.04246C4.78545 8.95699 4.71616 8.8877 4.63069 8.8877H2.94773C2.86226 8.8877 2.79297 8.95699 2.79297 9.04246V10.7254C2.79297 10.8109 2.86226 10.8802 2.94773 10.8802Z"
        fill={color}
      />
      <path
        d="M5.65867 10.8802H7.34164C7.4271 10.8802 7.4964 10.8109 7.4964 10.7254V9.04246C7.4964 8.95699 7.4271 8.8877 7.34164 8.8877H5.65867C5.5732 8.8877 5.50391 8.95699 5.50391 9.04246V10.7254C5.50391 10.8109 5.5732 10.8802 5.65867 10.8802Z"
        fill={color}
      />
      <path
        d="M8.36765 10.8802H10.0506C10.1361 10.8802 10.2054 10.8109 10.2054 10.7254V9.04246C10.2054 8.95699 10.1361 8.8877 10.0506 8.8877H8.36765C8.28219 8.8877 8.21289 8.95699 8.21289 9.04246V10.7254C8.21289 10.8109 8.28219 10.8802 8.36765 10.8802Z"
        fill={color}
      />
    </svg>
  )
}

export default Calendar2
