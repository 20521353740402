import * as React from 'react'

function LeadInfo({ color = '#3C52EF' }) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#F5F6FE" />
      <circle cx="20" cy="20" r="10" stroke={color} strokeWidth="0.75" strokeLinecap="round" />
      <path
        d="M26 24L25.2305 22.7175C24.7509 21.9181 24.0819 21.2491 23.2825 20.7695L22.0153 20.0092C22.0082 20.0049 22.008 19.9947 22.0149 19.9901V19.9901C23.5592 18.9605 23.7755 16.7755 22.4631 15.4631L22.2525 15.2525C21.0085 14.0085 18.9915 14.0085 17.7475 15.2525L17.5369 15.4631C16.2245 16.7755 16.4408 18.9605 17.9851 19.9901V19.9901C17.992 19.9947 17.9918 20.0049 17.9847 20.0092L16.7175 20.7695C15.9181 21.2491 15.2491 21.9181 14.7695 22.7175L14 24"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default LeadInfo
