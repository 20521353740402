/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGrinUser = /* GraphQL */ `
  mutation CreateGrinUser(
    $input: CreateGrinUserInput!
    $condition: ModelGrinUserConditionInput
  ) {
    createGrinUser(input: $input, condition: $condition) {
      id
      username
      identityId
      timezone
      version
      model
      deviceName
      language
      type
      a_doctor
      a_readers
      a_writers
      isActive
      owner
      program
      authConfirmationDate
      appSettings
      groups
      termsVersion
      updatedAt
      createdAt
      marketingData
      allowed_groups_permissions
      _version
      _deleted
      _lastChangedAt
      grinPlanKey
      stripeCustomerId
      planOverrides
      messagingPreferences {
        id
        reminderTime
        whiteningTime
        whiteningScanTime
        whiteningScanDays
        reminders {
          type
          medium
        }
        engagements {
          type
          medium
        }
        entities {
          type
          medium
        }
        contacts {
          name
          phone
          countryCode
          email
        }
        a_readers
        a_writers
        owner
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      featureFlags {
        id
        featureFlagsUserId
        username
        a_readers
        a_writers
        a_doctor
        flags
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      messages {
        items {
          id
          grinUserId
          roomId
          uploadingDate
          owner
          members
          a_readers
          a_writers
          type
          content
          readBy
          href
          hrefLabel
          promotionId
          promotionData
          templateId
          metadata
          isTransferred
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      rooms {
        items {
          id
          members
          a_readers
          a_writers
          grinUserId
          roomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateGrinUser = /* GraphQL */ `
  mutation UpdateGrinUser(
    $input: UpdateGrinUserInput!
    $condition: ModelGrinUserConditionInput
  ) {
    updateGrinUser(input: $input, condition: $condition) {
      id
      username
      identityId
      timezone
      externalIds
      version
      model
      deviceName
      language
      type
      a_doctor
      a_readers
      a_writers
      isActive
      owner
      program
      authConfirmationDate
      appSettings
      groups
      termsVersion
      updatedAt
      createdAt
      marketingData
      allowed_groups_permissions
      _version
      _deleted
      _lastChangedAt
      grinPlanKey
      stripeCustomerId
      planOverrides
      messagingPreferences {
        id
        reminderTime
        whiteningTime
        whiteningScanTime
        whiteningScanDays
        reminders {
          type
          medium
        }
        engagements {
          type
          medium
        }
        entities {
          type
          medium
          filters
        }
        contacts {
          name
          phone
          countryCode
          email
        }
        a_readers
        a_writers
        owner
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      featureFlags {
        id
        featureFlagsUserId
        username
        a_readers
        a_writers
        a_doctor
        flags
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          externalIds
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      messages {
        items {
          id
          grinUserId
          roomId
          uploadingDate
          owner
          members
          a_readers
          a_writers
          type
          content
          readBy
          href
          hrefLabel
          promotionId
          promotionData
          templateId
          metadata
          isTransferred
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      rooms {
        items {
          id
          members
          a_readers
          a_writers
          grinUserId
          roomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteGrinUser = /* GraphQL */ `
  mutation DeleteGrinUser(
    $input: DeleteGrinUserInput!
    $condition: ModelGrinUserConditionInput
  ) {
    deleteGrinUser(input: $input, condition: $condition) {
      id
      username
      identityId
      timezone
      version
      model
      deviceName
      language
      type
      a_doctor
      a_readers
      a_writers
      isActive
      owner
      program
      authConfirmationDate
      appSettings
      groups
      termsVersion
      updatedAt
      createdAt
      marketingData
      allowed_groups_permissions
      _version
      _deleted
      _lastChangedAt
      grinPlanKey
      stripeCustomerId
      planOverrides
      messagingPreferences {
        id
        reminderTime
        whiteningTime
        whiteningScanTime
        whiteningScanDays
        reminders {
          type
          medium
        }
        engagements {
          type
          medium
        }
        entities {
          type
          medium
          filters
        }
        contacts {
          name
          phone
          countryCode
          email
        }
        a_readers
        a_writers
        owner
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      featureFlags {
        id
        featureFlagsUserId
        username
        a_readers
        a_writers
        a_doctor
        flags
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      messages {
        items {
          id
          grinUserId
          roomId
          uploadingDate
          owner
          members
          a_readers
          a_writers
          type
          content
          readBy
          href
          hrefLabel
          promotionId
          promotionData
          templateId
          metadata
          isTransferred
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      rooms {
        items {
          id
          members
          a_readers
          a_writers
          grinUserId
          roomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createMessagingPreferences = /* GraphQL */ `
  mutation CreateMessagingPreferences(
    $input: CreateMessagingPreferencesInput!
    $condition: ModelMessagingPreferencesConditionInput
  ) {
    createMessagingPreferences(input: $input, condition: $condition) {
      id
      reminderTime
      whiteningTime
      whiteningScanTime
      whiteningScanDays
      reminders {
        type
        medium
      }
      engagements {
        type
        medium
      }
      entities {
        type
        medium
      }
      contacts {
        name
        phone
        countryCode
        email
      }
      a_readers
      a_writers
      owner
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateMessagingPreferences = /* GraphQL */ `
  mutation UpdateMessagingPreferences(
    $input: UpdateMessagingPreferencesInput!
    $condition: ModelMessagingPreferencesConditionInput
  ) {
    updateMessagingPreferences(input: $input, condition: $condition) {
      id
      reminderTime
      whiteningTime
      whiteningScanTime
      whiteningScanDays
      reminders {
        type
        medium
      }
      engagements {
        type
        medium
      }
      entities {
        type
        medium
        filters
      }
      contacts {
        name
        phone
        countryCode
        email
      }
      a_readers
      a_writers
      owner
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      user {
        id
        username
        identityId
        timezone
        version
        externalIds
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteMessagingPreferences = /* GraphQL */ `
  mutation DeleteMessagingPreferences(
    $input: DeleteMessagingPreferencesInput!
    $condition: ModelMessagingPreferencesConditionInput
  ) {
    deleteMessagingPreferences(input: $input, condition: $condition) {
      id
      reminderTime
      whiteningTime
      whiteningScanTime
      whiteningScanDays
      reminders {
        type
        medium
      }
      engagements {
        type
        medium
      }
      entities {
        type
        medium
      }
      contacts {
        name
        phone
        countryCode
        email
      }
      a_readers
      a_writers
      owner
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createFeatureFlags = /* GraphQL */ `
  mutation CreateFeatureFlags(
    $input: CreateFeatureFlagsInput!
    $condition: ModelFeatureFlagsConditionInput
  ) {
    createFeatureFlags(input: $input, condition: $condition) {
      id
      featureFlagsUserId
      username
      a_readers
      a_writers
      a_doctor
      flags
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateFeatureFlags = /* GraphQL */ `
  mutation UpdateFeatureFlags(
    $input: UpdateFeatureFlagsInput!
    $condition: ModelFeatureFlagsConditionInput
  ) {
    updateFeatureFlags(input: $input, condition: $condition) {
      id
      featureFlagsUserId
      username
      a_readers
      a_writers
      a_doctor
      flags
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteFeatureFlags = /* GraphQL */ `
  mutation DeleteFeatureFlags(
    $input: DeleteFeatureFlagsInput!
    $condition: ModelFeatureFlagsConditionInput
  ) {
    deleteFeatureFlags(input: $input, condition: $condition) {
      id
      featureFlagsUserId
      username
      a_readers
      a_writers
      a_doctor
      flags
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createLead = /* GraphQL */ `
  mutation CreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phone
      countryCode
      type
      status
      program
      extraData
      a_doctor
      a_readers
      a_writers
      doctorId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateLead = /* GraphQL */ `
  mutation UpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phone
      countryCode
      type
      status
      program
      extraData
      a_doctor
      a_readers
      a_writers
      doctorId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteLead = /* GraphQL */ `
  mutation DeleteLead(
    $input: DeleteLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    deleteLead(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phone
      countryCode
      type
      status
      program
      extraData
      a_doctor
      a_readers
      a_writers
      doctorId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      publicId
      patientDoctorId
      username
      auth_status
      task_status
      details {
        name
        firstName
        lastName
        email
        phone
        countryCode
        birthdate
        address
        city
        zipcode
        state
        country
        ageRange
        parentFirstName
        parentLastName
        parentEmail
        address2
        treatmentType
      }
      owner
      a_doctor
      a_readers
      a_writers
      isActive
      photo {
        bucket
        region
        key
      }
      plan
      rcToRmDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      initialScans {
        items {
          id
          patientId
          date
          comment
          a_doctor
          a_patient
          a_readers
          a_writers
          scannerType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      treatments {
        items {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      grinScans {
        items {
          id
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          date
          status
          isOffCycle
          aligner
          appliance
          applianceIndex
          treatmentType
          isTransferred
          createdAt
          updatedAt
          simulationStatus
          scanPairId
          withAligner
          scanSummaryStatus
          scanSummaryData
          scanGuideAIMetadata
          metadata
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pearlScans {
        items {
          id
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          status
          scanUrls
          uploadedAt
          createdAt
          updatedAt
          AIData
          index
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      cycles {
        items {
          id
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          createdAt
          updatedAt
          startDate
          originalEndDate
          actualEndDate
          index
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      actionItems {
        items {
          id
          actionItemDoctorId
          actionItemPatientId
          type
          priority
          status
          params
          a_doctor
          a_readers
          a_writers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      patientTags {
        items {
          id
          patientTagPatientId
          patientTagTagId
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      id
      publicId
      patientDoctorId
      username
      auth_status
      task_status
      guardianId
      details {
        name
        firstName
        lastName
        email
        phone
        countryCode
        birthdate
        address
        city
        zipcode
        state
        country
        ageRange
        parentFirstName
        parentLastName
        parentEmail
        address2
        treatmentType
      }
      owner
      a_doctor
      a_readers
      a_writers
      isActive
      photo {
        bucket
        region
        key
      }
      plan
      rcToRmDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      initialScans {
        items {
          id
          patientId
          date
          comment
          a_doctor
          a_patient
          a_readers
          a_writers
          scannerType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      treatments {
        items {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      grinScans {
        items {
          id
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          date
          status
          isOffCycle
          aligner
          appliance
          applianceIndex
          treatmentType
          isTransferred
          createdAt
          updatedAt
          simulationStatus
          scanPairId
          withAligner
          scanSummaryStatus
          scanSummaryData
          scanGuideAIMetadata
          metadata
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pearlScans {
        items {
          id
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          status
          scanUrls
          uploadedAt
          createdAt
          updatedAt
          AIData
          index
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      cycles {
        items {
          id
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          createdAt
          updatedAt
          startDate
          originalEndDate
          actualEndDate
          index
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      actionItems {
        items {
          id
          actionItemDoctorId
          actionItemPatientId
          type
          priority
          status
          params
          a_doctor
          a_readers
          a_writers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      patientTags {
        items {
          id
          patientTagPatientId
          patientTagTagId
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient(
    $input: DeletePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    deletePatient(input: $input, condition: $condition) {
      id
      publicId
      patientDoctorId
      username
      auth_status
      task_status
      details {
        name
        firstName
        lastName
        email
        phone
        countryCode
        birthdate
        address
        city
        zipcode
        state
        country
        ageRange
        parentFirstName
        parentLastName
        parentEmail
        address2
        treatmentType
      }
      owner
      a_doctor
      a_readers
      a_writers
      isActive
      photo {
        bucket
        region
        key
      }
      plan
      rcToRmDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      initialScans {
        items {
          id
          patientId
          date
          comment
          a_doctor
          a_patient
          a_readers
          a_writers
          scannerType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      treatments {
        items {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      grinScans {
        items {
          id
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          date
          status
          isOffCycle
          aligner
          appliance
          applianceIndex
          treatmentType
          isTransferred
          createdAt
          updatedAt
          simulationStatus
          scanPairId
          withAligner
          scanSummaryStatus
          scanSummaryData
          scanGuideAIMetadata
          metadata
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pearlScans {
        items {
          id
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          status
          scanUrls
          uploadedAt
          createdAt
          updatedAt
          AIData
          index
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      cycles {
        items {
          id
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          createdAt
          updatedAt
          startDate
          originalEndDate
          actualEndDate
          index
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      actionItems {
        items {
          id
          actionItemDoctorId
          actionItemPatientId
          type
          priority
          status
          params
          a_doctor
          a_readers
          a_writers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      patientTags {
        items {
          id
          patientTagPatientId
          patientTagTagId
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createDoctor = /* GraphQL */ `
  mutation CreateDoctor(
    $input: CreateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    createDoctor(input: $input, condition: $condition) {
      id
      rcToken
      username
      auth_status
      email
      a_readers
      a_writers
      name
      degrees
      clinic {
        practiceName
        address1
        address2
        city
        zip
        state
        country
        phone
        additionalPhone
        email
        countryCode
        practicePhone
        practiceCountryCode
        calendarId
        availability
        homepageUrl
        logo {
          bucket
          region
          key
        }
      }
      owner
      photo {
        bucket
        region
        key
      }
      allowedZipcodes
      startPracticeYear
      hasLocatorConsent
      bio
      contactEmail
      contactName
      phoneNumber
      countryCode
      metricType
      searchRadius
      areaOfCoverage
      accountOwnerId
      roleDescription
      accessType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      patients {
        items {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      accountOwner {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        hasLocatorConsent
        bio
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      scopesOrdered
      billingInfo {
        id
        owner
        payeeName
        phoneNumber
        payeeZip
        accountNumber
        routingNumber
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      messageTemplates {
        items {
          id
          type
          a_doctor
          doctorId
          title
          text
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          value
          type
          count
          owner
          settings
          tagDoctorId
          a_readers
          a_writers
          createdAt
          updatedAt
          tagClass
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateDoctor = /* GraphQL */ `
  mutation UpdateDoctor(
    $input: UpdateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    updateDoctor(input: $input, condition: $condition) {
      id
      rcToken
      username
      auth_status
      email
      a_readers
      a_writers
      name
      degrees
      clinic {
        practiceName
        address1
        address2
        city
        zip
        state
        country
        phone
        additionalPhone
        email
        countryCode
        practicePhone
        practiceCountryCode
        calendarId
        availability
        homepageUrl
        logo {
          bucket
          region
          key
        }
      }
      owner
      photo {
        bucket
        region
        key
      }
      allowedZipcodes
      startPracticeYear
      hasLocatorConsent
      bio
      contactEmail
      contactName
      phoneNumber
      countryCode
      metricType
      searchRadius
      areaOfCoverage
      accountOwnerId
      roleDescription
      accessType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminders {
            type
            medium
          }
          entities {
            type
            medium
            filters
          }
          contacts {
            name
            phone
            email
            countryCode
          }
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      patients {
        items {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      accountOwner {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        hasLocatorConsent
        bio
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
          featureFlags {
            id
            featureFlagsUserId
            username
            a_readers
            a_writers
            a_doctor
            flags
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      scopesOrdered
      billingInfo {
        id
        owner
        payeeName
        phoneNumber
        payeeZip
        accountNumber
        routingNumber
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      messageTemplates {
        items {
          id
          type
          a_doctor
          doctorId
          title
          text
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          value
          type
          count
          owner
          settings
          tagDoctorId
          a_readers
          a_writers
          createdAt
          updatedAt
          tagClass
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteDoctor = /* GraphQL */ `
  mutation DeleteDoctor(
    $input: DeleteDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    deleteDoctor(input: $input, condition: $condition) {
      id
      rcToken
      username
      auth_status
      email
      a_readers
      a_writers
      name
      degrees
      clinic {
        practiceName
        address1
        address2
        city
        zip
        state
        country
        phone
        additionalPhone
        email
        countryCode
        practicePhone
        practiceCountryCode
        calendarId
        availability
        homepageUrl
        logo {
          bucket
          region
          key
        }
      }
      owner
      photo {
        bucket
        region
        key
      }
      allowedZipcodes
      startPracticeYear
      hasLocatorConsent
      bio
      contactEmail
      contactName
      phoneNumber
      countryCode
      metricType
      searchRadius
      areaOfCoverage
      accountOwnerId
      roleDescription
      accessType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      patients {
        items {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      accountOwner {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        hasLocatorConsent
        bio
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      scopesOrdered
      billingInfo {
        id
        owner
        payeeName
        phoneNumber
        payeeZip
        accountNumber
        routingNumber
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      messageTemplates {
        items {
          id
          type
          a_doctor
          doctorId
          title
          text
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          value
          type
          count
          owner
          settings
          tagDoctorId
          a_readers
          a_writers
          createdAt
          updatedAt
          tagClass
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createInitialScan = /* GraphQL */ `
  mutation CreateInitialScan(
    $input: CreateInitialScanInput!
    $condition: ModelInitialScanConditionInput
  ) {
    createInitialScan(input: $input, condition: $condition) {
      id
      patientId
      date
      comment
      oralImages {
        bucket
        region
        key
      }
      panoramics {
        bucket
        region
        key
      }
      stls {
        bucket
        region
        key
      }
      a_doctor
      a_patient
      a_readers
      a_writers
      scannerType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateInitialScan = /* GraphQL */ `
  mutation UpdateInitialScan(
    $input: UpdateInitialScanInput!
    $condition: ModelInitialScanConditionInput
  ) {
    updateInitialScan(input: $input, condition: $condition) {
      id
      patientId
      date
      comment
      oralImages {
        bucket
        region
        key
      }
      panoramics {
        bucket
        region
        key
      }
      stls {
        bucket
        region
        key
      }
      a_doctor
      a_patient
      a_readers
      a_writers
      scannerType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInitialScan = /* GraphQL */ `
  mutation DeleteInitialScan(
    $input: DeleteInitialScanInput!
    $condition: ModelInitialScanConditionInput
  ) {
    deleteInitialScan(input: $input, condition: $condition) {
      id
      patientId
      date
      comment
      oralImages {
        bucket
        region
        key
      }
      panoramics {
        bucket
        region
        key
      }
      stls {
        bucket
        region
        key
      }
      a_doctor
      a_patient
      a_readers
      a_writers
      scannerType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTreatment = /* GraphQL */ `
  mutation CreateTreatment(
    $input: CreateTreatmentInput!
    $condition: ModelTreatmentConditionInput
  ) {
    createTreatment(input: $input, condition: $condition) {
      id
      patientId
      type
      date
      statusId
      endDate
      simulation {
        images {
          bucket
          region
          key
        }
        videos {
          bucket
          region
          key
        }
        status
        backlog
      }
      offer {
        price
        currency
        status
        video {
          bucket
          region
          key
        }
        message
        tier
      }
      stageDefaultDuration
      cycleInterval
      introMeetingDate
      defaultApplianceName
      currentApplianceIndex
      timelineEventLog {
        eventType
        timestamp
        params
      }
      postConfirmationStatusKey
      cycleIntervalPeriodType
      whiteningProductType
      maxWhiteningTreatments
      status
      a_doctor
      a_patient
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      proposals {
        items {
          id
          treatmentId
          patientId
          a_doctor
          a_patient
          tier
          cost
          length
          periodType
          vendor
          additionalVendor
          status
          notes
          a_readers
          a_writers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      treatmentsPlans {
        items {
          id
          treatmentId
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          tier
          cost
          length
          periodType
          vendor
          additionalVendor
          status
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stages {
        items {
          id
          treatmentId
          allignerId
          index
          startDate
          endDate
          duration
          alert
          status
          milestoneId
          a_doctor
          a_patient
          a_readers
          a_writers
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      currentStatus {
        id
        statusId
        statusKey
        treatmentId
        setByUsername
        a_patient
        a_doctor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        treatment {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          key
          program
          type
          period
          maxScansPerPeriod
          hasLimitedScans
          shouldReceiveReminders
          isManualAssignmentAllowed
          isChatAllowed
          requireDoubleScan
          scanCyclesEnabled
          scanFrequencyDefaults
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      treatmentStatuses {
        items {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateTreatment = /* GraphQL */ `
  mutation UpdateTreatment(
    $input: UpdateTreatmentInput!
    $condition: ModelTreatmentConditionInput
  ) {
    updateTreatment(input: $input, condition: $condition) {
      id
      patientId
      type
      date
      statusId
      endDate
      txTrackerStls
      simulation {
        images {
          bucket
          region
          key
        }
        videos {
          bucket
          region
          key
        }
        status
        backlog
      }
      offer {
        price
        currency
        status
        video {
          bucket
          region
          key
        }
        message
        tier
      }
      stageDefaultDuration
      cycleInterval
      introMeetingDate
      defaultApplianceName
      currentApplianceIndex
      totalApplianceNumber
      timelineEventLog {
        eventType
        timestamp
        params
      }
      postConfirmationStatusKey
      cycleIntervalPeriodType
      whiteningProductType
      maxWhiteningTreatments
      status
      a_doctor
      a_patient
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      proposals {
        items {
          id
          treatmentId
          patientId
          a_doctor
          a_patient
          tier
          cost
          length
          periodType
          vendor
          additionalVendor
          status
          notes
          a_readers
          a_writers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      treatmentsPlans {
        items {
          id
          treatmentId
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          tier
          cost
          length
          periodType
          vendor
          additionalVendor
          status
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stages {
        items {
          id
          treatmentId
          allignerId
          index
          startDate
          endDate
          duration
          alert
          status
          milestoneId
          a_doctor
          a_patient
          a_readers
          a_writers
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      currentStatus {
        id
        statusId
        statusKey
        treatmentId
        setByUsername
        a_patient
        a_doctor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        treatment {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          key
          program
          type
          period
          maxScansPerPeriod
          hasLimitedScans
          shouldReceiveReminders
          isManualAssignmentAllowed
          isChatAllowed
          requireDoubleScan
          scanCyclesEnabled
          scanFrequencyDefaults
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      treatmentStatuses {
        items {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      medicalRecordsData
    }
  }
`;
export const deleteTreatment = /* GraphQL */ `
  mutation DeleteTreatment(
    $input: DeleteTreatmentInput!
    $condition: ModelTreatmentConditionInput
  ) {
    deleteTreatment(input: $input, condition: $condition) {
      id
      patientId
      type
      date
      statusId
      endDate
      simulation {
        images {
          bucket
          region
          key
        }
        videos {
          bucket
          region
          key
        }
        status
        backlog
      }
      offer {
        price
        currency
        status
        video {
          bucket
          region
          key
        }
        message
        tier
      }
      stageDefaultDuration
      cycleInterval
      introMeetingDate
      defaultApplianceName
      currentApplianceIndex
      timelineEventLog {
        eventType
        timestamp
        params
      }
      postConfirmationStatusKey
      cycleIntervalPeriodType
      whiteningProductType
      maxWhiteningTreatments
      status
      a_doctor
      a_patient
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      proposals {
        items {
          id
          treatmentId
          patientId
          a_doctor
          a_patient
          tier
          cost
          length
          periodType
          vendor
          additionalVendor
          status
          notes
          a_readers
          a_writers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      treatmentsPlans {
        items {
          id
          treatmentId
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          tier
          cost
          length
          periodType
          vendor
          additionalVendor
          status
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stages {
        items {
          id
          treatmentId
          allignerId
          index
          startDate
          endDate
          duration
          alert
          status
          milestoneId
          a_doctor
          a_patient
          a_readers
          a_writers
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      currentStatus {
        id
        statusId
        statusKey
        treatmentId
        setByUsername
        a_patient
        a_doctor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        treatment {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          key
          program
          type
          period
          maxScansPerPeriod
          hasLimitedScans
          shouldReceiveReminders
          isManualAssignmentAllowed
          isChatAllowed
          requireDoubleScan
          scanCyclesEnabled
          scanFrequencyDefaults
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      treatmentStatuses {
        items {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createProposal = /* GraphQL */ `
  mutation CreateProposal(
    $input: CreateProposalInput!
    $condition: ModelProposalConditionInput
  ) {
    createProposal(input: $input, condition: $condition) {
      id
      treatmentId
      patientId
      a_doctor
      a_patient
      tier
      cost
      length
      periodType
      vendor
      additionalVendor
      status
      notes
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProposal = /* GraphQL */ `
  mutation UpdateProposal(
    $input: UpdateProposalInput!
    $condition: ModelProposalConditionInput
  ) {
    updateProposal(input: $input, condition: $condition) {
      id
      treatmentId
      patientId
      a_doctor
      a_patient
      tier
      cost
      length
      periodType
      vendor
      additionalVendor
      status
      notes
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProposal = /* GraphQL */ `
  mutation DeleteProposal(
    $input: DeleteProposalInput!
    $condition: ModelProposalConditionInput
  ) {
    deleteProposal(input: $input, condition: $condition) {
      id
      treatmentId
      patientId
      a_doctor
      a_patient
      tier
      cost
      length
      periodType
      vendor
      additionalVendor
      status
      notes
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $input: CreateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    createAppointment(input: $input, condition: $condition) {
      id
      patientId
      a_doctor
      a_patient
      address
      date
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $input: UpdateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    updateAppointment(input: $input, condition: $condition) {
      id
      patientId
      a_doctor
      a_patient
      address
      date
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment(
    $input: DeleteAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      patientId
      a_doctor
      a_patient
      address
      date
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTreatmentPlan = /* GraphQL */ `
  mutation CreateTreatmentPlan(
    $input: CreateTreatmentPlanInput!
    $condition: ModelTreatmentPlanConditionInput
  ) {
    createTreatmentPlan(input: $input, condition: $condition) {
      id
      treatmentId
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      tier
      cost
      length
      periodType
      vendor
      additionalVendor
      status
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTreatmentPlan = /* GraphQL */ `
  mutation UpdateTreatmentPlan(
    $input: UpdateTreatmentPlanInput!
    $condition: ModelTreatmentPlanConditionInput
  ) {
    updateTreatmentPlan(input: $input, condition: $condition) {
      id
      treatmentId
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      tier
      cost
      length
      periodType
      vendor
      additionalVendor
      status
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTreatmentPlan = /* GraphQL */ `
  mutation DeleteTreatmentPlan(
    $input: DeleteTreatmentPlanInput!
    $condition: ModelTreatmentPlanConditionInput
  ) {
    deleteTreatmentPlan(input: $input, condition: $condition) {
      id
      treatmentId
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      tier
      cost
      length
      periodType
      vendor
      additionalVendor
      status
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createStage = /* GraphQL */ `
  mutation CreateStage(
    $input: CreateStageInput!
    $condition: ModelStageConditionInput
  ) {
    createStage(input: $input, condition: $condition) {
      id
      treatmentId
      allignerId
      index
      startDate
      endDate
      duration
      alert
      status
      milestoneId
      scans {
        images {
          bucket
          region
          key
        }
        video {
          bucket
          region
          key
        }
        date
      }
      a_doctor
      a_patient
      a_readers
      a_writers
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateStage = /* GraphQL */ `
  mutation UpdateStage(
    $input: UpdateStageInput!
    $condition: ModelStageConditionInput
  ) {
    updateStage(input: $input, condition: $condition) {
      id
      treatmentId
      allignerId
      index
      startDate
      endDate
      duration
      alert
      status
      milestoneId
      scans {
        images {
          bucket
          region
          key
        }
        video {
          bucket
          region
          key
        }
        date
      }
      a_doctor
      a_patient
      a_readers
      a_writers
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteStage = /* GraphQL */ `
  mutation DeleteStage(
    $input: DeleteStageInput!
    $condition: ModelStageConditionInput
  ) {
    deleteStage(input: $input, condition: $condition) {
      id
      treatmentId
      allignerId
      index
      startDate
      endDate
      duration
      alert
      status
      milestoneId
      scans {
        images {
          bucket
          region
          key
        }
        video {
          bucket
          region
          key
        }
        date
      }
      a_doctor
      a_patient
      a_readers
      a_writers
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createGrinScan = /* GraphQL */ `
  mutation CreateGrinScan(
    $input: CreateGrinScanInput!
    $condition: ModelGrinScanConditionInput
  ) {
    createGrinScan(input: $input, condition: $condition) {
      id
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      images {
        bucket
        region
        key
      }
      video {
        bucket
        region
        key
      }
      date
      status
      isOffCycle
      aligner
      appliance
      applianceIndex
      treatmentType
      isTransferred
      createdAt
      updatedAt
      simulationStatus
      scanPairId
      withAligner
      scanSummaryStatus
      scanSummaryData
      scanGuideAIMetadata
      metadata
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      scanReviews {
        items {
          id
          grinScanId
          a_doctor
          a_patient
          a_readers
          a_writers
          reviewerDoctorId
          isTransferred
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateGrinScan = /* GraphQL */ `
  mutation UpdateGrinScan(
    $input: UpdateGrinScanInput!
    $condition: ModelGrinScanConditionInput
  ) {
    updateGrinScan(input: $input, condition: $condition) {
      id
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      images {
        bucket
        region
        key
      }
      video {
        bucket
        region
        key
      }
      date
      status
      isOffCycle
      aligner
      appliance
      applianceIndex
      treatmentType
      isTransferred
      createdAt
      updatedAt
      simulationStatus
      scanPairId
      withAligner
      scanSummaryStatus
      scanSummaryData
      scanGuideAIMetadata
      metadata
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      scanReviews {
        items {
          id
          grinScanId
          a_doctor
          a_patient
          a_readers
          a_writers
          reviewerDoctorId
          isTransferred
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteGrinScan = /* GraphQL */ `
  mutation DeleteGrinScan(
    $input: DeleteGrinScanInput!
    $condition: ModelGrinScanConditionInput
  ) {
    deleteGrinScan(input: $input, condition: $condition) {
      id
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      images {
        bucket
        region
        key
      }
      video {
        bucket
        region
        key
      }
      date
      status
      isOffCycle
      aligner
      appliance
      applianceIndex
      treatmentType
      isTransferred
      createdAt
      updatedAt
      simulationStatus
      scanPairId
      withAligner
      scanSummaryStatus
      scanSummaryData
      scanGuideAIMetadata
      metadata
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      scanReviews {
        items {
          id
          grinScanId
          a_doctor
          a_patient
          a_readers
          a_writers
          reviewerDoctorId
          isTransferred
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createPearlScan = /* GraphQL */ `
  mutation CreatePearlScan(
    $input: CreatePearlScanInput!
    $condition: ModelPearlScanConditionInput
  ) {
    createPearlScan(input: $input, condition: $condition) {
      id
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      status
      scanUrls
      uploadedAt
      createdAt
      updatedAt
      AIData
      index
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updatePearlScan = /* GraphQL */ `
  mutation UpdatePearlScan(
    $input: UpdatePearlScanInput!
    $condition: ModelPearlScanConditionInput
  ) {
    updatePearlScan(input: $input, condition: $condition) {
      id
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      status
      scanUrls
      uploadedAt
      createdAt
      updatedAt
      AIData
      index
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deletePearlScan = /* GraphQL */ `
  mutation DeletePearlScan(
    $input: DeletePearlScanInput!
    $condition: ModelPearlScanConditionInput
  ) {
    deletePearlScan(input: $input, condition: $condition) {
      id
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      status
      scanUrls
      uploadedAt
      createdAt
      updatedAt
      AIData
      index
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createCycle = /* GraphQL */ `
  mutation CreateCycle(
    $input: CreateCycleInput!
    $condition: ModelCycleConditionInput
  ) {
    createCycle(input: $input, condition: $condition) {
      id
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      createdAt
      updatedAt
      startDate
      originalEndDate
      actualEndDate
      index
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateCycle = /* GraphQL */ `
  mutation UpdateCycle(
    $input: UpdateCycleInput!
    $condition: ModelCycleConditionInput
  ) {
    updateCycle(input: $input, condition: $condition) {
      id
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      createdAt
      updatedAt
      startDate
      originalEndDate
      actualEndDate
      index
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteCycle = /* GraphQL */ `
  mutation DeleteCycle(
    $input: DeleteCycleInput!
    $condition: ModelCycleConditionInput
  ) {
    deleteCycle(input: $input, condition: $condition) {
      id
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      createdAt
      updatedAt
      startDate
      originalEndDate
      actualEndDate
      index
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createScanReview = /* GraphQL */ `
  mutation CreateScanReview(
    $input: CreateScanReviewInput!
    $condition: ModelScanReviewConditionInput
  ) {
    createScanReview(input: $input, condition: $condition) {
      id
      grinScanId
      a_doctor
      a_patient
      a_readers
      a_writers
      video {
        bucket
        region
        key
      }
      reviewerDoctorId
      isTransferred
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      reviewer {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateScanReview = /* GraphQL */ `
  mutation UpdateScanReview(
    $input: UpdateScanReviewInput!
    $condition: ModelScanReviewConditionInput
  ) {
    updateScanReview(input: $input, condition: $condition) {
      id
      grinScanId
      a_doctor
      a_patient
      a_readers
      a_writers
      video {
        bucket
        region
        key
      }
      reviewerDoctorId
      isTransferred
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      reviewer {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteScanReview = /* GraphQL */ `
  mutation DeleteScanReview(
    $input: DeleteScanReviewInput!
    $condition: ModelScanReviewConditionInput
  ) {
    deleteScanReview(input: $input, condition: $condition) {
      id
      grinScanId
      a_doctor
      a_patient
      a_readers
      a_writers
      video {
        bucket
        region
        key
      }
      reviewerDoctorId
      isTransferred
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      reviewer {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createBillingInfo = /* GraphQL */ `
  mutation CreateBillingInfo(
    $input: CreateBillingInfoInput!
    $condition: ModelBillingInfoConditionInput
  ) {
    createBillingInfo(input: $input, condition: $condition) {
      id
      owner
      payeeName
      phoneNumber
      payeeZip
      accountNumber
      routingNumber
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateBillingInfo = /* GraphQL */ `
  mutation UpdateBillingInfo(
    $input: UpdateBillingInfoInput!
    $condition: ModelBillingInfoConditionInput
  ) {
    updateBillingInfo(input: $input, condition: $condition) {
      id
      owner
      payeeName
      phoneNumber
      payeeZip
      accountNumber
      routingNumber
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          externalIds
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteBillingInfo = /* GraphQL */ `
  mutation DeleteBillingInfo(
    $input: DeleteBillingInfoInput!
    $condition: ModelBillingInfoConditionInput
  ) {
    deleteBillingInfo(input: $input, condition: $condition) {
      id
      owner
      payeeName
      phoneNumber
      payeeZip
      accountNumber
      routingNumber
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom(
    $input: CreateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    createRoom(input: $input, condition: $condition) {
      id
      members
      lastMessagePreview
      lastMessageTimestamp
      sortField
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messages {
        items {
          id
          grinUserId
          roomId
          uploadingDate
          owner
          members
          a_readers
          a_writers
          type
          content
          readBy
          href
          hrefLabel
          promotionId
          promotionData
          templateId
          metadata
          isTransferred
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          members
          a_readers
          a_writers
          grinUserId
          roomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom(
    $input: UpdateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    updateRoom(input: $input, condition: $condition) {
      id
      members
      lastMessagePreview
      lastMessageTimestamp
      sortField
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messages {
        items {
          id
          grinUserId
          roomId
          uploadingDate
          owner
          members
          a_readers
          a_writers
          type
          content
          readBy
          href
          hrefLabel
          promotionId
          promotionData
          templateId
          metadata
          isTransferred
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          members
          a_readers
          a_writers
          grinUserId
          roomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom(
    $input: DeleteRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    deleteRoom(input: $input, condition: $condition) {
      id
      members
      lastMessagePreview
      lastMessageTimestamp
      sortField
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messages {
        items {
          id
          grinUserId
          roomId
          uploadingDate
          owner
          members
          a_readers
          a_writers
          type
          content
          readBy
          href
          hrefLabel
          promotionId
          promotionData
          templateId
          metadata
          isTransferred
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          members
          a_readers
          a_writers
          grinUserId
          roomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createScheduledMessage = /* GraphQL */ `
  mutation CreateScheduledMessage(
    $input: CreateScheduledMessageInput!
    $condition: ModelScheduledMessageConditionInput
  ) {
    createScheduledMessage(input: $input, condition: $condition) {
      id
      grinUserId
      owner
      roomId
      payload
      scheduleTime
      a_readers
      a_writers
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      grinUserId
      roomId
      uploadingDate
      owner
      members
      a_readers
      a_writers
      type
      content
      readBy
      pictures {
        bucket
        region
        key
      }
      href
      hrefLabel
      promotionId
      promotionData
      templateId
      metadata
      isTransferred
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      grinUserId
      roomId
      uploadingDate
      owner
      members
      a_readers
      a_writers
      type
      content
      readBy
      pictures {
        bucket
        region
        key
      }
      href
      hrefLabel
      promotionId
      promotionData
      templateId
      metadata
      isTransferred
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      grinUserId
      roomId
      uploadingDate
      owner
      members
      a_readers
      a_writers
      type
      content
      readBy
      pictures {
        bucket
        region
        key
      }
      href
      hrefLabel
      promotionId
      promotionData
      templateId
      metadata
      isTransferred
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPromotion = /* GraphQL */ `
  mutation CreatePromotion(
    $input: CreatePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    createPromotion(input: $input, condition: $condition) {
      id
      title
      provider
      description
      keywords
      categoryKey
      imageUrl
      thumbnailUrl
      externalId
      baseUrl
      featureFlagIds
      metadata
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePromotion = /* GraphQL */ `
  mutation UpdatePromotion(
    $input: UpdatePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    updatePromotion(input: $input, condition: $condition) {
      id
      title
      provider
      description
      keywords
      categoryKey
      imageUrl
      thumbnailUrl
      externalId
      baseUrl
      featureFlagIds
      metadata
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePromotion = /* GraphQL */ `
  mutation DeletePromotion(
    $input: DeletePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    deletePromotion(input: $input, condition: $condition) {
      id
      title
      provider
      description
      keywords
      categoryKey
      imageUrl
      thumbnailUrl
      externalId
      baseUrl
      featureFlagIds
      metadata
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMessageTemplate = /* GraphQL */ `
  mutation CreateMessageTemplate(
    $input: CreateMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    createMessageTemplate(input: $input, condition: $condition) {
      id
      type
      a_doctor
      doctorId
      title
      text
      attachments {
        bucket
        region
        key
      }
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateMessageTemplate = /* GraphQL */ `
  mutation UpdateMessageTemplate(
    $input: UpdateMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    updateMessageTemplate(input: $input, condition: $condition) {
      id
      type
      a_doctor
      doctorId
      title
      text
      attachments {
        bucket
        region
        key
      }
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteMessageTemplate = /* GraphQL */ `
  mutation DeleteMessageTemplate(
    $input: DeleteMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    deleteMessageTemplate(input: $input, condition: $condition) {
      id
      type
      a_doctor
      doctorId
      title
      text
      attachments {
        bucket
        region
        key
      }
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createUserRoomLink = /* GraphQL */ `
  mutation CreateUserRoomLink(
    $input: CreateUserRoomLinkInput!
    $condition: ModelUserRoomLinkConditionInput
  ) {
    createUserRoomLink(input: $input, condition: $condition) {
      id
      members
      a_readers
      a_writers
      grinUserId
      roomId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateUserRoomLink = /* GraphQL */ `
  mutation UpdateUserRoomLink(
    $input: UpdateUserRoomLinkInput!
    $condition: ModelUserRoomLinkConditionInput
  ) {
    updateUserRoomLink(input: $input, condition: $condition) {
      id
      members
      a_readers
      a_writers
      grinUserId
      roomId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteUserRoomLink = /* GraphQL */ `
  mutation DeleteUserRoomLink(
    $input: DeleteUserRoomLinkInput!
    $condition: ModelUserRoomLinkConditionInput
  ) {
    deleteUserRoomLink(input: $input, condition: $condition) {
      id
      members
      a_readers
      a_writers
      grinUserId
      roomId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      type
      readStatus
      owner
      title
      subtitle
      body
      entityType
      entityId
      method
      reminderType
      grinUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      type
      readStatus
      owner
      title
      subtitle
      body
      entityType
      entityId
      method
      reminderType
      grinUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      type
      readStatus
      owner
      title
      subtitle
      body
      entityType
      entityId
      method
      reminderType
      grinUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createDoctorPlans = /* GraphQL */ `
  mutation CreateDoctorPlans(
    $input: CreateDoctorPlansInput!
    $condition: ModelDoctorPlansConditionInput
  ) {
    createDoctorPlans(input: $input, condition: $condition) {
      id
      name
      email
      auth_status
      firstName
      lastName
      accessType
      accountOwnerId
      owner
      plans
      program
      addedBy
      a_doctor
      a_readers
      a_writers
      requireBillingInSignup
      doctorGroups
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      signupProgress
      accountOwner {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        hasLocatorConsent
        bio
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateDoctorPlans = /* GraphQL */ `
  mutation UpdateDoctorPlans(
    $input: UpdateDoctorPlansInput!
    $condition: ModelDoctorPlansConditionInput
  ) {
    updateDoctorPlans(input: $input, condition: $condition) {
      id
      name
      email
      auth_status
      firstName
      lastName
      accessType
      accountOwnerId
      signupProgress
      owner
      plans
      program
      addedBy
      a_doctor
      a_readers
      a_writers
      requireBillingInSignup
      doctorGroups
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      accountOwner {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        hasLocatorConsent
        bio
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteDoctorPlans = /* GraphQL */ `
  mutation DeleteDoctorPlans(
    $input: DeleteDoctorPlansInput!
    $condition: ModelDoctorPlansConditionInput
  ) {
    deleteDoctorPlans(input: $input, condition: $condition) {
      id
      name
      email
      auth_status
      firstName
      lastName
      accessType
      accountOwnerId
      owner
      plans
      program
      addedBy
      a_doctor
      a_readers
      a_writers
      doctorGroups
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      accountOwner {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        hasLocatorConsent
        bio
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createGrinPlan = /* GraphQL */ `
  mutation CreateGrinPlan(
    $input: CreateGrinPlanInput!
    $condition: ModelGrinPlanConditionInput
  ) {
    createGrinPlan(input: $input, condition: $condition) {
      id
      key
      type
      displayName
      maxScopes
      maxPatients
      stripeProductId
      period
      planGroup
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateGrinPlan = /* GraphQL */ `
  mutation UpdateGrinPlan(
    $input: UpdateGrinPlanInput!
    $condition: ModelGrinPlanConditionInput
  ) {
    updateGrinPlan(input: $input, condition: $condition) {
      id
      key
      type
      displayName
      maxScopes
      maxPatients
      stripeProductId
      period
      planGroup
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteGrinPlan = /* GraphQL */ `
  mutation DeleteGrinPlan(
    $input: DeleteGrinPlanInput!
    $condition: ModelGrinPlanConditionInput
  ) {
    deleteGrinPlan(input: $input, condition: $condition) {
      id
      key
      type
      displayName
      maxScopes
      maxPatients
      stripeProductId
      period
      planGroup
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createLocale = /* GraphQL */ `
  mutation CreateLocale(
    $input: CreateLocaleInput!
    $condition: ModelLocaleConditionInput
  ) {
    createLocale(input: $input, condition: $condition) {
      id
      platform
      key
      en
      es
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateLocale = /* GraphQL */ `
  mutation UpdateLocale(
    $input: UpdateLocaleInput!
    $condition: ModelLocaleConditionInput
  ) {
    updateLocale(input: $input, condition: $condition) {
      id
      platform
      key
      en
      es
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteLocale = /* GraphQL */ `
  mutation DeleteLocale(
    $input: DeleteLocaleInput!
    $condition: ModelLocaleConditionInput
  ) {
    deleteLocale(input: $input, condition: $condition) {
      id
      platform
      key
      en
      es
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      owner
      type
      description
      creditCardBrand
      creditCardLast4
      amount
      a_readers
      a_writers
      invoicePdf
      createdAt
      paymentMethodStripeId
      invoiceStripeId
      scopesQuantity
      relatedProductIds
      creatorUserId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      creatorUser {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      owner
      type
      description
      creditCardBrand
      creditCardLast4
      amount
      a_readers
      a_writers
      invoicePdf
      createdAt
      paymentMethodStripeId
      invoiceStripeId
      scopesQuantity
      relatedProductIds
      creatorUserId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      creatorUser {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      owner
      type
      description
      creditCardBrand
      creditCardLast4
      amount
      a_readers
      a_writers
      invoicePdf
      createdAt
      paymentMethodStripeId
      invoiceStripeId
      scopesQuantity
      relatedProductIds
      creatorUserId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      creatorUser {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createUserNote = /* GraphQL */ `
  mutation CreateUserNote(
    $input: CreateUserNoteInput!
    $condition: ModelUserNoteConditionInput
  ) {
    createUserNote(input: $input, condition: $condition) {
      id
      grinUserId
      owner
      a_doctor
      createdAt
      updatedAt
      body
      metadata
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserNote = /* GraphQL */ `
  mutation UpdateUserNote(
    $input: UpdateUserNoteInput!
    $condition: ModelUserNoteConditionInput
  ) {
    updateUserNote(input: $input, condition: $condition) {
      id
      grinUserId
      owner
      a_doctor
      createdAt
      updatedAt
      body
      a_readers
      a_writers
      metadata
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserNote = /* GraphQL */ `
  mutation DeleteUserNote(
    $input: DeleteUserNoteInput!
    $condition: ModelUserNoteConditionInput
  ) {
    deleteUserNote(input: $input, condition: $condition) {
      id
      grinUserId
      owner
      a_doctor
      createdAt
      updatedAt
      body
      a_readers
      a_writers
      metadata
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createActionItem = /* GraphQL */ `
  mutation CreateActionItem(
    $input: CreateActionItemInput!
    $condition: ModelActionItemConditionInput
  ) {
    createActionItem(input: $input, condition: $condition) {
      id
      actionItemDoctorId
      actionItemPatientId
      type
      priority
      status
      params
      a_doctor
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateActionItem = /* GraphQL */ `
  mutation UpdateActionItem(
    $input: UpdateActionItemInput!
    $condition: ModelActionItemConditionInput
  ) {
    updateActionItem(input: $input, condition: $condition) {
      id
      actionItemDoctorId
      actionItemPatientId
      type
      priority
      status
      params
      a_doctor
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteActionItem = /* GraphQL */ `
  mutation DeleteActionItem(
    $input: DeleteActionItemInput!
    $condition: ModelActionItemConditionInput
  ) {
    deleteActionItem(input: $input, condition: $condition) {
      id
      actionItemDoctorId
      actionItemPatientId
      type
      priority
      status
      params
      a_doctor
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      value
      type
      count
      owner
      settings
      tagDoctorId
      a_readers
      a_writers
      createdAt
      updatedAt
      tagClass
      _version
      _deleted
      _lastChangedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      value
      type
      count
      owner
      settings
      tagDoctorId
      a_readers
      a_writers
      createdAt
      updatedAt
      tagClass
      _version
      _deleted
      _lastChangedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      value
      type
      count
      owner
      settings
      tagDoctorId
      a_readers
      a_writers
      createdAt
      updatedAt
      tagClass
      _version
      _deleted
      _lastChangedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createPatientTag = /* GraphQL */ `
  mutation CreatePatientTag(
    $input: CreatePatientTagInput!
    $condition: ModelPatientTagConditionInput
  ) {
    createPatientTag(input: $input, condition: $condition) {
      id
      patientTagPatientId
      patientTagTagId
      a_doctor
      a_patient
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      tag {
        id
        value
        type
        count
        owner
        settings
        tagDoctorId
        a_readers
        a_writers
        createdAt
        updatedAt
        tagClass
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
    }
  }
`;
export const updatePatientTag = /* GraphQL */ `
  mutation UpdatePatientTag(
    $input: UpdatePatientTagInput!
    $condition: ModelPatientTagConditionInput
  ) {
    updatePatientTag(input: $input, condition: $condition) {
      id
      patientTagPatientId
      patientTagTagId
      a_doctor
      a_patient
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      tag {
        id
        value
        type
        count
        owner
        settings
        tagDoctorId
        a_readers
        a_writers
        createdAt
        updatedAt
        tagClass
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
    }
  }
`;
export const deletePatientTag = /* GraphQL */ `
  mutation DeletePatientTag(
    $input: DeletePatientTagInput!
    $condition: ModelPatientTagConditionInput
  ) {
    deletePatientTag(input: $input, condition: $condition) {
      id
      patientTagPatientId
      patientTagTagId
      a_doctor
      a_patient
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      tag {
        id
        value
        type
        count
        owner
        settings
        tagDoctorId
        a_readers
        a_writers
        createdAt
        updatedAt
        tagClass
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
    }
  }
`;
export const createDoctorSearchModel = /* GraphQL */ `
  mutation CreateDoctorSearchModel(
    $input: CreateDoctorSearchModelInput!
    $condition: ModelDoctorSearchModelConditionInput
  ) {
    createDoctorSearchModel(input: $input, condition: $condition) {
      id
      doctorId
      username
      email
      name
      photo {
        bucket
        region
        key
      }
      firstName
      lastName
      featureFlags
      grinUserId
      practiceName
      practiceEmail
      allowedZipcodes
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      grinUser {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateDoctorSearchModel = /* GraphQL */ `
  mutation UpdateDoctorSearchModel(
    $input: UpdateDoctorSearchModelInput!
    $condition: ModelDoctorSearchModelConditionInput
  ) {
    updateDoctorSearchModel(input: $input, condition: $condition) {
      id
      doctorId
      username
      email
      name
      photo {
        bucket
        region
        key
      }
      firstName
      lastName
      featureFlags
      grinUserId
      practiceName
      practiceEmail
      allowedZipcodes
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      grinUser {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteDoctorSearchModel = /* GraphQL */ `
  mutation DeleteDoctorSearchModel(
    $input: DeleteDoctorSearchModelInput!
    $condition: ModelDoctorSearchModelConditionInput
  ) {
    deleteDoctorSearchModel(input: $input, condition: $condition) {
      id
      doctorId
      username
      email
      name
      photo {
        bucket
        region
        key
      }
      firstName
      lastName
      featureFlags
      grinUserId
      practiceName
      practiceEmail
      allowedZipcodes
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      grinUser {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createPatientSearchModel = /* GraphQL */ `
  mutation CreatePatientSearchModel(
    $input: CreatePatientSearchModelInput!
    $condition: ModelPatientSearchModelConditionInput
  ) {
    createPatientSearchModel(input: $input, condition: $condition) {
      id
      patientPublicId
      patientId
      email
      name
      username
      firstName
      lastName
      parentFirstName
      parentLastName
      parentEmail
      doctorId
      doctorName
      doctorEmail
      doctorUsername
      tagKeywords
      lastActionItemType
      lastActionItemStatus
      openActionItems
      lastInteractionTimestamp
      isActive
      grinUserId
      roomId
      lastMessageTimestamp
      lastMessagePreview
      featureFlags
      program
      profilePicture {
        bucket
        region
        key
      }
      statusKey
      statusType
      statusId
      treatmentType
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      grinUser {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      room {
        id
        members
        lastMessagePreview
        lastMessageTimestamp
        sortField
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messages {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
      }
      patientTags {
        items {
          id
          patientTagPatientId
          patientTagTagId
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      status {
        id
        statusId
        statusKey
        treatmentId
        setByUsername
        a_patient
        a_doctor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        treatment {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          key
          program
          type
          period
          maxScansPerPeriod
          hasLimitedScans
          shouldReceiveReminders
          isManualAssignmentAllowed
          isChatAllowed
          requireDoubleScan
          scanCyclesEnabled
          scanFrequencyDefaults
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const updatePatientSearchModel = /* GraphQL */ `
  mutation UpdatePatientSearchModel(
    $input: UpdatePatientSearchModelInput!
    $condition: ModelPatientSearchModelConditionInput
  ) {
    updatePatientSearchModel(input: $input, condition: $condition) {
      id
      patientPublicId
      patientId
      email
      name
      username
      firstName
      lastName
      parentFirstName
      parentLastName
      parentEmail
      doctorId
      doctorName
      doctorEmail
      doctorUsername
      tagKeywords
      lastActionItemType
      lastActionItemStatus
      openActionItems
      lastInteractionTimestamp
      isActive
      grinUserId
      roomId
      lastMessageTimestamp
      lastMessagePreview
      featureFlags
      program
      profilePicture {
        bucket
        region
        key
      }
      statusKey
      statusType
      statusId
      treatmentType
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      grinUser {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      room {
        id
        members
        lastMessagePreview
        lastMessageTimestamp
        sortField
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messages {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
      }
      patientTags {
        items {
          id
          patientTagPatientId
          patientTagTagId
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      status {
        id
        statusId
        statusKey
        treatmentId
        setByUsername
        a_patient
        a_doctor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        treatment {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          key
          program
          type
          period
          maxScansPerPeriod
          hasLimitedScans
          shouldReceiveReminders
          isManualAssignmentAllowed
          isChatAllowed
          requireDoubleScan
          scanCyclesEnabled
          scanFrequencyDefaults
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const deletePatientSearchModel = /* GraphQL */ `
  mutation DeletePatientSearchModel(
    $input: DeletePatientSearchModelInput!
    $condition: ModelPatientSearchModelConditionInput
  ) {
    deletePatientSearchModel(input: $input, condition: $condition) {
      id
      patientPublicId
      patientId
      email
      name
      username
      firstName
      lastName
      parentFirstName
      parentLastName
      parentEmail
      doctorId
      doctorName
      doctorEmail
      doctorUsername
      tagKeywords
      lastActionItemType
      lastActionItemStatus
      openActionItems
      lastInteractionTimestamp
      isActive
      grinUserId
      roomId
      lastMessageTimestamp
      lastMessagePreview
      featureFlags
      program
      profilePicture {
        bucket
        region
        key
      }
      statusKey
      statusType
      statusId
      treatmentType
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      grinUser {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      room {
        id
        members
        lastMessagePreview
        lastMessageTimestamp
        sortField
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messages {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
      }
      patientTags {
        items {
          id
          patientTagPatientId
          patientTagTagId
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      status {
        id
        statusId
        statusKey
        treatmentId
        setByUsername
        a_patient
        a_doctor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        treatment {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          key
          program
          type
          period
          maxScansPerPeriod
          hasLimitedScans
          shouldReceiveReminders
          isManualAssignmentAllowed
          isChatAllowed
          requireDoubleScan
          scanCyclesEnabled
          scanFrequencyDefaults
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const createStatus = /* GraphQL */ `
  mutation CreateStatus(
    $input: CreateStatusInput!
    $condition: ModelStatusConditionInput
  ) {
    createStatus(input: $input, condition: $condition) {
      id
      key
      program
      type
      period
      maxScansPerPeriod
      hasLimitedScans
      shouldReceiveReminders
      isManualAssignmentAllowed
      isChatAllowed
      requireDoubleScan
      scanCyclesEnabled
      scanFrequencyDefaults
      settings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateStatus = /* GraphQL */ `
  mutation UpdateStatus(
    $input: UpdateStatusInput!
    $condition: ModelStatusConditionInput
  ) {
    updateStatus(input: $input, condition: $condition) {
      id
      key
      program
      type
      period
      maxScansPerPeriod
      hasLimitedScans
      shouldReceiveReminders
      isManualAssignmentAllowed
      isChatAllowed
      requireDoubleScan
      scanCyclesEnabled
      scanFrequencyDefaults
      settings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteStatus = /* GraphQL */ `
  mutation DeleteStatus(
    $input: DeleteStatusInput!
    $condition: ModelStatusConditionInput
  ) {
    deleteStatus(input: $input, condition: $condition) {
      id
      key
      program
      type
      period
      maxScansPerPeriod
      hasLimitedScans
      shouldReceiveReminders
      isManualAssignmentAllowed
      isChatAllowed
      requireDoubleScan
      scanCyclesEnabled
      scanFrequencyDefaults
      settings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTreatmentStatus = /* GraphQL */ `
  mutation CreateTreatmentStatus(
    $input: CreateTreatmentStatusInput!
    $condition: ModelTreatmentStatusConditionInput
  ) {
    createTreatmentStatus(input: $input, condition: $condition) {
      id
      statusId
      statusKey
      treatmentId
      setByUsername
      a_patient
      a_doctor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      treatment {
        id
        patientId
        type
        date
        statusId
        endDate
        simulation {
          status
          backlog
        }
        offer {
          price
          currency
          status
          message
          tier
        }
        stageDefaultDuration
        cycleInterval
        introMeetingDate
        defaultApplianceName
        currentApplianceIndex
        timelineEventLog {
          eventType
          timestamp
          params
        }
        postConfirmationStatusKey
        cycleIntervalPeriodType
        whiteningProductType
        maxWhiteningTreatments
        status
        a_doctor
        a_patient
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        proposals {
          nextToken
          startedAt
        }
        treatmentsPlans {
          nextToken
          startedAt
        }
        stages {
          nextToken
          startedAt
        }
        currentStatus {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        treatmentStatuses {
          nextToken
          startedAt
        }
      }
      status {
        id
        key
        program
        type
        period
        maxScansPerPeriod
        hasLimitedScans
        shouldReceiveReminders
        isManualAssignmentAllowed
        isChatAllowed
        requireDoubleScan
        scanCyclesEnabled
        scanFrequencyDefaults
        settings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const updateTreatmentStatus = /* GraphQL */ `
  mutation UpdateTreatmentStatus(
    $input: UpdateTreatmentStatusInput!
    $condition: ModelTreatmentStatusConditionInput
  ) {
    updateTreatmentStatus(input: $input, condition: $condition) {
      id
      statusId
      statusKey
      treatmentId
      setByUsername
      a_patient
      a_doctor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      treatment {
        id
        patientId
        type
        date
        statusId
        endDate
        simulation {
          status
          backlog
        }
        offer {
          price
          currency
          status
          message
          tier
        }
        stageDefaultDuration
        cycleInterval
        introMeetingDate
        defaultApplianceName
        currentApplianceIndex
        timelineEventLog {
          eventType
          timestamp
          params
        }
        postConfirmationStatusKey
        cycleIntervalPeriodType
        whiteningProductType
        maxWhiteningTreatments
        status
        a_doctor
        a_patient
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        proposals {
          nextToken
          startedAt
        }
        treatmentsPlans {
          nextToken
          startedAt
        }
        stages {
          nextToken
          startedAt
        }
        currentStatus {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        treatmentStatuses {
          nextToken
          startedAt
        }
      }
      status {
        id
        key
        program
        type
        period
        maxScansPerPeriod
        hasLimitedScans
        shouldReceiveReminders
        isManualAssignmentAllowed
        isChatAllowed
        requireDoubleScan
        scanCyclesEnabled
        scanFrequencyDefaults
        settings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const deleteTreatmentStatus = /* GraphQL */ `
  mutation DeleteTreatmentStatus(
    $input: DeleteTreatmentStatusInput!
    $condition: ModelTreatmentStatusConditionInput
  ) {
    deleteTreatmentStatus(input: $input, condition: $condition) {
      id
      statusId
      statusKey
      treatmentId
      setByUsername
      a_patient
      a_doctor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      treatment {
        id
        patientId
        type
        date
        statusId
        endDate
        simulation {
          status
          backlog
        }
        offer {
          price
          currency
          status
          message
          tier
        }
        stageDefaultDuration
        cycleInterval
        introMeetingDate
        defaultApplianceName
        currentApplianceIndex
        timelineEventLog {
          eventType
          timestamp
          params
        }
        postConfirmationStatusKey
        cycleIntervalPeriodType
        whiteningProductType
        maxWhiteningTreatments
        status
        a_doctor
        a_patient
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        proposals {
          nextToken
          startedAt
        }
        treatmentsPlans {
          nextToken
          startedAt
        }
        stages {
          nextToken
          startedAt
        }
        currentStatus {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        treatmentStatuses {
          nextToken
          startedAt
        }
      }
      status {
        id
        key
        program
        type
        period
        maxScansPerPeriod
        hasLimitedScans
        shouldReceiveReminders
        isManualAssignmentAllowed
        isChatAllowed
        requireDoubleScan
        scanCyclesEnabled
        scanFrequencyDefaults
        settings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      username
      key
      displayName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      username
      key
      displayName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      username
      key
      displayName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRolePermission = /* GraphQL */ `
  mutation CreateRolePermission(
    $input: CreateRolePermissionInput!
    $condition: ModelRolePermissionConditionInput
  ) {
    createRolePermission(input: $input, condition: $condition) {
      id
      roleKey
      description
      permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRolePermission = /* GraphQL */ `
  mutation UpdateRolePermission(
    $input: UpdateRolePermissionInput!
    $condition: ModelRolePermissionConditionInput
  ) {
    updateRolePermission(input: $input, condition: $condition) {
      id
      roleKey
      description
      permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRolePermission = /* GraphQL */ `
  mutation DeleteRolePermission(
    $input: DeleteRolePermissionInput!
    $condition: ModelRolePermissionConditionInput
  ) {
    deleteRolePermission(input: $input, condition: $condition) {
      id
      roleKey
      description
      permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement(
    $input: CreateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    createAnnouncement(input: $input, condition: $condition) {
      id
      status
      startDate
      endDate
      type
      trigger
      content
      conditions
      excludedUserEmails
      featureFlags
      createdAt
      updatedAt
      href
      settings
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement(
    $input: UpdateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    updateAnnouncement(input: $input, condition: $condition) {
      id
      status
      startDate
      endDate
      type
      trigger
      content
      conditions
      excludedUserEmails
      featureFlags
      createdAt
      updatedAt
      href
      settings
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement(
    $input: DeleteAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    deleteAnnouncement(input: $input, condition: $condition) {
      id
      status
      startDate
      endDate
      type
      trigger
      content
      conditions
      excludedUserEmails
      featureFlags
      createdAt
      updatedAt
      href
      settings
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSurveyResult = /* GraphQL */ `
  mutation CreateSurveyResult(
    $input: CreateSurveyResultInput!
    $condition: ModelSurveyResultConditionInput
  ) {
    createSurveyResult(input: $input, condition: $condition) {
      id
      responderId
      responderAccountOwnerId
      type
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
