import { makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SelectInput from '../../common/SelectInput'
import LabeledInputContainer from '../../common/inputs/LabeledInputContainer'
import TreatmentStatusLabel from '../../common/inputs/StatusPicker/TreatmentStatusLabel'
import { selectStatuses } from 'selectors/statusesSelector'

const useStyles = makeStyles(() => ({
  inputContainer: {
    marginTop: 4
  }
}))

const StatusesPicker = ({ selectedStatus, onSelect, disabled, program, originalStatus }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data: statuses } = useSelector(selectStatuses)
  const isDisabled = useMemo(() => disabled || originalStatus === 'invited', [disabled, originalStatus])

  const skippedStatuses = useMemo(
    () => [
      originalStatus !== 'invited' && 'invited',
      originalStatus !== 'new-lead' && 'new-lead',
      originalStatus !== 'transferred' && 'transferred',
      originalStatus !== 'deleted' && 'deleted'
    ],
    [originalStatus]
  )
  const availableStatuses = useMemo(
    () => statuses[program]?.filter(({ type }) => !skippedStatuses.includes(type)),
    [program, skippedStatuses, statuses]
  )

  const options = useMemo(
    () =>
      availableStatuses.map((stat, index) => ({
        label: <TreatmentStatusLabel status={stat.key} direction="column" />,
        value: stat.key,
        inputValue: <TreatmentStatusLabel status={stat.key} showSubtitle={false} />
      })),
    [availableStatuses]
  )

  return (
    <LabeledInputContainer
      title={t('dialogs.patientInfo.scanSettings.treatmentStatus')}
      tooltip={t('tooltips.treatmentStatus')}
    >
      <div className={classes.inputContainer}>
        <SelectInput
          style={{ bright: true, thick: true }}
          options={options}
          value={selectedStatus?.key}
          onChange={option => onSelect(availableStatuses.find(({ key }) => key === option.value))}
          disabled={isDisabled}
        />
      </div>
    </LabeledInputContainer>
  )
}

export default StatusesPicker
