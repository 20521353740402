import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  label: {
    fontFamily: 'Dazzed',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '14px',
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    opacity: 0.5,
    marginBottom: 5,
    whiteSpace: 'nowrap'
  }
}))

const GrinLabel = ({ color, children, className, fontSize = 12 }) => {
  const classes = useStyles()

  return (
    <div className={[classes.label, className].join(' ')} style={{ color, fontSize }}>
      {children}
    </div>
  )
}

export default GrinLabel
