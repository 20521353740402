import React, { useState, useCallback, useRef, useMemo } from 'react'
import PrimaryOutlinedButton from '../common/buttons/PrimaryOutlinedButton'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Dialog, DialogActions, DialogContent, makeStyles } from '@material-ui/core'
import Actions from '../../actions'
import WithLoader from '../common/WithLoader'
import SecondaryButton from '../common/buttons/SecondaryButton'
import PrimaryButton from '../common/buttons/PrimaryButton'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { Storage } from 'aws-amplify'
import { Camera as CameraIcon, Delete as DeleteIcon } from 'components/common/icons'
import { getEnvironment } from 'utils/awsUtils'

const ImageUploaderWithCrop = ({
  onUploadDone = () => {},
  label,
  inputId = uuidv4(),
  circularCrop = true,
  minWidth = 150,
  minHeight = 150,
  keyWithExtension = false,
  s3Folder = '',
  onStartLoading = () => null,
  fillIntoSquare = false,
  keepOriginalSize = false,
  allDimentionsCropAllowed = false,
  iconButtons = false,
  secondaryLabel = false,
  onSecondaryClick = () => {},
  onUploadClicked = () => {},
  onCancelClicked = () => {},
  isLoading,
  hideLoader = false
}) => {
  const classes = makeStyles({
    content: {
      margin: 'auto'
    },
    dialogActions: {
      justifyContent: 'center',
      '@media (max-width: 960px)': {
        flexDirection: 'column',
        '& > button': {
          margin: '5px 0'
        }
      }
    },
    buttons: {
      display: 'flex'
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      color: 'var(--text-color-3)',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    lastLabel: {
      marginLeft: 20
    },
    labelIcon: {
      marginRight: 6
    },
    labelText: {
      color: 'var(--text-color-3)'
    }
  })()

  const [upImg, setUpImg] = useState(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [minWidthDimen, setMinWidthDimen] = useState(minWidth)
  const [minHeightDimen, setMinHeightDimen] = useState(minHeight)
  const [completedCrop, setCompletedCrop] = useState(null)

  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const dispatch = useDispatch()

  const isLoadingState = useMemo(() => isLoading || isUploadingImage, [isLoading, isUploadingImage])

  const requestFileUpload = () => dispatch(Actions.requestFileUpload())
  const fileUploadReceived = () => dispatch(Actions.fileUploadReceived())

  const handleUploadDone = useCallback(
    key => {
      const bucket = `scans-${getEnvironment()}`
      setIsUploadingImage(false)
      onUploadDone([key], bucket)
    },
    [onUploadDone]
  )

  const onImageLoaded = useCallback(
    img => {
      imgRef.current = img
      const minWidthDimen = img.width > minWidth ? minWidth : img.width
      const minHeightDimen = img.height > minHeight ? minHeight : img.height
      const offsetX = img.width / 2 - minWidthDimen / 2
      const offsetY = img.height / 2 - minHeightDimen / 2
      setMinWidthDimen(minWidthDimen)
      setMinHeightDimen(minHeightDimen)
      const initialCrop = {
        width: minWidthDimen,
        height: minHeightDimen,
        x: offsetX,
        y: offsetY,
        ...(!allDimentionsCropAllowed && { aspect: minWidthDimen / minHeightDimen })
      }

      setCrop(initialCrop)
      setCompletedCrop(initialCrop)
      return false
    },
    [allDimentionsCropAllowed, minHeight, minWidth]
  )

  const fillImageIntoSquare = useCallback(
    image => {
      const c = document.createElement('canvas')
      const ctx = c.getContext('2d')

      const biggestSide = Math.max(image.width, image.height)

      c.width = Math.max(biggestSide, minWidth)
      c.height = Math.max(biggestSide, minHeight)

      const offsetX = (c.width - image.width) / 2
      const offsetY = (c.height - image.height) / 2

      ctx.fillStyle = 'rgba(0, 0, 0, 0)'
      ctx.fillRect(0, 0, c.width, c.height)

      ctx.drawImage(image, offsetX, offsetY, image.width, image.height)
      var blob = c.toDataURL('image/png')
      return blob
    },
    [minHeight, minWidth]
  )

  const onSelectFile = e => {
    if (e.target.files?.length > 0) {
      if (fillIntoSquare) {
        const url = URL.createObjectURL(e.target.files[0])
        const image = document.createElement('img')
        image.src = url
        image.onload = function () {
          const updatedBlobiImage = fillImageIntoSquare(image)
          setUpImg(updatedBlobiImage)
          URL.revokeObjectURL(url)
        }
      } else {
        const reader = new FileReader()
        reader.addEventListener('load', () => setUpImg(reader.result))
        reader.readAsDataURL(e.target.files[0])
      }
    }
  }
  // this is to allow selecting the same file multiple times
  const onInputClick = e => {
    onUploadClicked()
    e.target.value = ''
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = keepOriginalSize ? crop.width * scaleX : crop.width
    canvas.height = keepOriginalSize ? crop.height * scaleY : crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      keepOriginalSize ? crop.width * scaleX : crop.width,
      keepOriginalSize ? crop.height * scaleY : crop.height
    )

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        blob.name = fileName // eslint-disable-line no-param-reassign
        resolve(blob)
      }, 'image/png')
    })
  }

  const generateS3Params = useCallback(() => {
    const uuid = uuidv4()
    const key = `${uuid}${keyWithExtension ? '.png' : ''}`
    const s3Path = s3Folder ? `${s3Folder}/${key}` : key
    return {
      s3Path,
      key
    }
  }, [keyWithExtension, s3Folder])

  return (
    <>
      <WithLoader isLoading={isLoadingState && !hideLoader}>
        <input
          multiple={false}
          id={inputId}
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          onClick={onInputClick}
          style={{ display: 'none' }}
          disabled={hideLoader && isLoadingState}
        />
        <div className={classes.buttons}>
          <label htmlFor={inputId}>
            {iconButtons ? (
              <div className={classes.label}>
                <CameraIcon className={classes.labelIcon} />
                <DazzedParagraph12 className={classes.labelText}>{label}</DazzedParagraph12>
              </div>
            ) : (
              <PrimaryOutlinedButton label={label} />
            )}
          </label>
          {iconButtons && secondaryLabel && (
            <div className={[classes.label, classes.lastLabel].join(' ')} onClick={onSecondaryClick}>
              <DeleteIcon className={classes.labelIcon} />
              <DazzedParagraph12 className={classes.labelText}>{secondaryLabel}</DazzedParagraph12>
            </div>
          )}
        </div>
      </WithLoader>
      <Dialog open={upImg != null}>
        <DialogContent classes={{ root: classes.content }}>
          <ReactCrop
            keepSelection
            src={upImg}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onChange={c => setCrop(c)}
            onComplete={c => setCompletedCrop(c)}
            imageStyle={{ maxWidth: 'auto', maxHeight: '50vh' }}
            circularCrop={circularCrop}
            minWidth={minWidthDimen}
            minHeight={minHeightDimen}
          />
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <PrimaryButton
            label="OK"
            onClick={() => {
              setUpImg(null)
              requestFileUpload()
              setIsUploadingImage(true)

              getCroppedImg(imgRef.current, completedCrop, 'croppedAvatar.png').then(croppedImg => {
                onStartLoading(true)
                const { s3Path, key } = generateS3Params()
                Storage.put(s3Path, croppedImg, {
                  contentType: 'image/png'
                })
                  .then(() => handleUploadDone(key))
                  .then(fileUploadReceived)
              })
            }}
          />
          <SecondaryButton
            label="Cancel"
            onClick={() => {
              onCancelClicked()
              setUpImg(null)
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ImageUploaderWithCrop
