import { makeStyles } from '@material-ui/styles'
import { API } from 'aws-amplify'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { createPaymentMethod } from 'utils/stripeUtils'
import PrimaryButton from '../../buttons/PrimaryButton'
import BaseModal from '../../modals/BaseModal'
import CreditCardForm from './CreditCardForm'
import CreditCardPreview from './CreditCardPreview'
import CreditCardTermsOfSale from './CreditCardTermsOfSale'
import PoweredByStripe from './PoweredByStripe'
import { useTranslation } from 'react-i18next'
import { useCountry } from 'hooks/useCountry'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(() => ({
  actionButtons: {
    marginTop: 20
  }
}))

const CreditCardPicker = ({
  submitLabel,
  hideSubmit = false,
  submitButtonWidth,
  showTitle = true,
  iconButtons = true,
  showPoweredByLabel = true,
  showAgreement = false,
  isSubmitLoading = false,
  onSubmit,
  handleAddressChanged = () => {},
  shippingAddress,
  setAddress
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const userPlan = useSelector(state => state.practiceReducer.billing.grinPlan)
  const userPlanKey = userPlan?.key ?? ''

  const { hasSavedCard, isLoading, last4, brand } = useSelector(state => state.billingReducer.paymentMethod)
  const [isPreviewMode, setIsPreviewMode] = useState(true)
  const [agreedTerms, setAgreedTerms] = useState(false)
  const { getStateKey } = useCountry()

  const handleSubmit = useCallback(
    () => !isLoading && !isSubmitLoading && !!onSubmit && onSubmit(),
    [onSubmit, isLoading, isSubmitLoading]
  )

  const handleSaveCreditCard = useCallback(
    async ({ element, cardHolderName, billingAddress }) => {
      if (isLoading || isSubmitLoading) {
        return false
      }

      dispatch(Actions.savePaymentMethod())
      try {
        const stripePaymentMethod = await createPaymentMethod({
          element,
          cardHolderName,
          address: {
            country: billingAddress.country,
            state: getStateKey(billingAddress.state, billingAddress.country),
            city: billingAddress.city,
            postal_code: billingAddress.zip,
            line1: billingAddress.address1
          }
        })

        const paymentMethod = await API.put('grinApi', '/billing/v1/paymentMethods', {
          body: {
            paymentMethodId: stripePaymentMethod.paymentMethod.id
          }
        })
        dispatch(Actions.savePaymentMethodReceived(paymentMethod))
        return true
      } catch (ex) {
        dispatch(Actions.savePaymentMethodFailed(ex))
        return false
      }
    },
    [dispatch, getStateKey, isLoading, isSubmitLoading]
  )

  const handleChangeCreditCard = useCallback(
    props => {
      trackEvent('Change credit card - save pressed', { userPlanKey })
      handleSaveCreditCard(props)
    },
    [userPlanKey, handleSaveCreditCard]
  )

  const handleCancelCreditCard = useCallback(() => {
    trackEvent('Change credit card - cancel pressed', { userPlanKey })
    setIsPreviewMode(true)
  }, [userPlanKey, setIsPreviewMode])

  const saveAndPay = useCallback(
    async cardProps => {
      if (await handleSaveCreditCard(cardProps)) {
        handleSubmit()
      }
    },
    [handleSaveCreditCard, handleSubmit]
  )

  useEffect(() => {
    setIsPreviewMode(hasSavedCard && !isLoading)
  }, [hasSavedCard, isLoading])

  return (
    <div>
      {hasSavedCard && (
        <div>
          <form
            id="credit-card-form"
            onSubmit={e => {
              e.preventDefault()
              if (isLoading || isSubmitLoading) {
                return
              }
              onSubmit()
            }}
          >
            <CreditCardPreview
              creditCardData={{ last4, brand }}
              onChangeRequested={() => {
                trackEvent('Change credit card - change pressed', { userPlanKey })
                setIsPreviewMode(false)
              }}
              showTitle={showTitle}
              iconButtons={iconButtons}
            />
            {showPoweredByLabel && <PoweredByStripe />}
            {onSubmit && !hideSubmit && (
              <>
                {showAgreement && <CreditCardTermsOfSale checked={agreedTerms} onChange={setAgreedTerms} />}
                <div className={classes.actionButtons}>
                  <PrimaryButton
                    label={submitLabel == null ? t('creditCard.submitLabel') : submitLabel}
                    width={submitButtonWidth}
                    disabled={isLoading || isSubmitLoading || (showAgreement && !agreedTerms)}
                    isLoading={isLoading || isSubmitLoading}
                    type="submit"
                  />
                </div>
              </>
            )}
          </form>
        </div>
      )}
      {!isPreviewMode && !hasSavedCard && (
        <CreditCardForm
          isLoading={isLoading || isSubmitLoading}
          showAgreement={showAgreement}
          agreedTerms={agreedTerms}
          submitLabel={submitLabel == null ? t('creditCard.submitLabel') : submitLabel}
          submitButtonWidth={submitButtonWidth}
          buttonsAlign="flex-start"
          onSubmit={saveAndPay}
          setAgreedTerms={setAgreedTerms}
          handleAddressChanged={handleAddressChanged}
          hideSubmit={hideSubmit}
          shippingAddress={shippingAddress}
          setAddress={setAddress}
        />
      )}
      <BaseModal
        title={t('creditCard.updateCardTitle')}
        variant="alert"
        open={!isPreviewMode && hasSavedCard}
        handleClose={() => setIsPreviewMode(true)}
      >
        <CreditCardForm
          submitLabel={t('general.save')}
          secondaryLabel={t('general.cancel')}
          onSubmit={handleChangeCreditCard}
          onSecondaryClicked={handleCancelCreditCard}
          isLoading={isLoading}
          agreedTerms={true}
          showPoweredByLabel={false}
          handleAddressChanged={handleAddressChanged}
          shippingAddress={shippingAddress}
          setAddress={setAddress}
        />
      </BaseModal>
    </div>
  )
}

export default CreditCardPicker
