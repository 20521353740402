import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import ScanWithPatientDetails from './ScanWithPatientDetails'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles({
  modal: {
    padding: '50px 30px 0px 10px'
  },
  content: {
    paddingRight: '0px !important'
  },
  container: {
    height: '100%',
    paddingRight: 3,
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  video: {
    width: '100% !important',
    height: '100% !important'
  },
  note: {
    padding: 15,
    backgroundColor: 'var(--bg-color-14)',
    borderRadius: 8
  },
  notesContainer: {
    paddingTop: '4px!important',
    maxHeight: 500,
    overflowY: 'scroll'
  }
})

const ViewSharedScanModalMobile = ({ isOpen, onClose, grinScanId, colleaguesName, referral }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <BaseModal
      open={isOpen}
      variant="fullscreen"
      handleClose={onClose}
      className={classes.modal}
      hideScroll
      contentClassName={classes.content}
      onPrimaryBtnClick={onClose}
      largerPrimaryButton
      buttonsWidth={130}
      primaryLabel={t('general.close')}
    >
      {isOpen && (
        <div className={classes.container}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <DazzedParagraph16 bold>{t('dialogs.viewSharedScan.title')}</DazzedParagraph16>
            </Grid>
            <Grid item>
              <ScanWithPatientDetails grinScanId={grinScanId} videoClassName={classes.video} />
            </Grid>
            <Grid item>
              <DazzedParagraph14 bold>{t('dialogs.viewSharedScan.colleagueDetails')}</DazzedParagraph14>
              <DazzedParagraph14>{colleaguesName}</DazzedParagraph14>
              <DazzedParagraph14>{referral.notificationDestination}</DazzedParagraph14>
            </Grid>
            <Grid item>
              <DazzedParagraph14 strong>{t('dialogs.viewSharedScan.noteTitle')}</DazzedParagraph14>
              <DazzedParagraph14 strong className={classes.note}>{`"${referral.grinDoctorNote}"`}</DazzedParagraph14>
            </Grid>
            <Grid item>
              {referral.reviewStatus === 'completed' && referral.referralReview ? (
                <>
                  <DazzedParagraph14 strong>{t('dialogs.viewSharedScan.colleaguesResponse')}</DazzedParagraph14>
                  <DazzedParagraph14
                    strong
                    className={classes.note}
                  >{`"${referral.referralReview}"`}</DazzedParagraph14>
                </>
              ) : (
                <DazzedParagraph14>{t('dialogs.viewSharedScan.notRespondFromColleague')}</DazzedParagraph14>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </BaseModal>
  )
}

export default ViewSharedScanModalMobile
