import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import Tooltip from 'components/common/Tooltip'
import PracticeViewCategoryTooltip from './PracticeViewCategoryTooltip'

const useStyles = makeStyles(theme => ({
  container: {
    height: 92,
    width: 97,
    padding: 20,
    backgroundColor: 'var(--bg-color-73)',
    borderRadius: 8
  },
  tooltip: {
    padding: '0px !important',
    width: '400px !important'
  }
}))

const PracticeViewCategory = ({ icon, title, note, items, categoryComponent }) => {
  const classes = useStyles()

  return (
    <Tooltip
      variant="light"
      placement="bottom"
      value={
        <PracticeViewCategoryTooltip
          icon={icon}
          title={title}
          note={note}
          items={items}
          categoryComponent={categoryComponent}
        />
      }
      tooltipContentClassName={classes.tooltip}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
        className={classes.container}
      >
        <Grid item>{icon}</Grid>
        <Grid item>
          <DazzedParagraph12>{title}</DazzedParagraph12>
        </Grid>
      </Grid>
    </Tooltip>
  )
}

export default PracticeViewCategory
