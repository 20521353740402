import React from 'react'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { AutomationRulesEmptyState as AutomationRulesEmptyStateIcon } from 'components/common/icons'
import { useTranslation } from 'react-i18next'

const AutomationRulesEmptyState = () => {
  const { t } = useTranslation()

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <AutomationRulesEmptyStateIcon />
      </Grid>
      <Grid item>
        <DazzedParagraph14 bold color="var(--text-color-53)">
          {t('pages.accountSettings.automations.emptyStateTitle')}
        </DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default AutomationRulesEmptyState
