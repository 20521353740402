import { updateAllWhere } from './arrayUtils'

/**
 * Updates the TimelineItem's nested `GrinScan` objects that match the provided `predicate`, according to the return value of the `mapper`.
 * Returns a reference to the new `TimelineItem` object without mutating the original.
 * @param timelineItem - the TimelineItem object.
 * @param predicate - a predicate callback that will be called with each of the GrinScan objects.
 * @param mapper - a mapper callback that will be called with each GrinScan that matched the `predicate`.
 */
export const updateTimelineItemGrinScans = ({
  timelineItem = {},
  predicate = grinScan => false,
  mapper = scan => scan
}) => {
  if (timelineItem.grinScan) {
    return {
      ...timelineItem,
      grinScan: predicate(timelineItem.grinScan) ? mapper(timelineItem.grinScan) : timelineItem.grinScan
    }
  }

  return {
    ...timelineItem,
    grinScans: {
      ...timelineItem.grinScans,
      items: updateAllWhere(timelineItem.grinScans.items, predicate, mapper)
    }
  }
}

/**
 * Updates the scans within the `timelineItem`'s payload that match the `scanPredicate` according to the provided `mapper`.
 * Returns a reference to the updated version of `timelineItem` after the update, without mutating the original.
 * @param timelineItem - the raw TimelineItem object (with a stringified payload).
 * @param scanPredicate - a predicate callback that will be called with each of the scan objects in the `timelineItem` payload.
 * @param mapper - a mapper callback that will be called with each scan that match the `predicate`.
 * @returns the updated `timelineItem`.
 */
export const updateTimelineItemPayloadScans = ({
  timelineItem = {},
  scanPredicate = scan => false,
  mapper = scan => scan
}) => {
  const timelineItemPayload = JSON.parse(timelineItem.payload || '{}')
  return {
    ...timelineItem,
    payload: JSON.stringify({
      ...timelineItemPayload,
      scans: updateAllWhere(timelineItemPayload.scans, scanPredicate, mapper)
    })
  }
}

/**
 * Adds the `scanReview` object to the TimelineItem. If it's already there, returns a reference to the original TimelineItem.
 * Returns a reference to the new `TimelineItem` object without mutating the original.
 * @param timelineItem - the TimelineItem object to update.
 * @param scanId - the id of the GrinScan this scan review belongs to.
 * @param scanReview - a `ScanReview` object with the following structure:
 * ```
 * id,
 * createdAt,
 * reviewer: { id, name, roleDescription },
 * isLocal,
 * video: { key, bucket, region }, // Either video or cache
 * cache
 * ```
 */
export const addScanReviewToTimelineItem = ({ timelineItem = {}, scanId = '', scanReview = {} }) => {
  let updatedTimelineItem = updateTimelineItemPayloadScans({
    timelineItem,
    scanPredicate: scan => scan.id === scanId && !scan.scanReviewIds.includes(scanReview.id),
    mapper: scan => ({
      ...scan,
      scanReviewIds: [...scan.scanReviewIds, scanReview.id]
    })
  })

  updatedTimelineItem = updateTimelineItemGrinScans({
    timelineItem: updatedTimelineItem,
    predicate: scan => scan.id === scanId && scan.scanReviews.items.every(r => r.id !== scanReview.id),
    mapper: scan => ({
      ...scan,
      scanReviews: {
        items: [...(scan.scanReviews?.items || []), scanReview]
      }
    })
  })

  return updatedTimelineItem
}

/**
 * Adds the `referral` object to a TimelineItem object if the following conditions are met:
 * 1) The shared scan is associated with the timeline item.
 * 2) The referral is not already presented on the timeline item.
 * Returns an updated version of `timelineItem` without mutating the original.
 * @param timelineItem - the TimelineItem object to update.
 * @param scanId - the id of the GrinScan this referral belongs to.
 * @param referral - the Referral object to add.
 * @returns the updated `timelineItem`.
 */
export const addReferralToTimelineItem = ({ timelineItem = {}, scanId = '', referral = {} }) => {
  let updatedTimelineItem = updateTimelineItemPayloadScans({
    timelineItem,
    scanPredicate: scan => scan.id === scanId && !scan.referralIds.includes(referral.id),
    mapper: scan => ({
      ...scan,
      referralIds: [...scan.referralIds, referral.id]
    })
  })

  updatedTimelineItem = updateTimelineItemGrinScans({
    timelineItem: updatedTimelineItem,
    predicate: scan =>
      scan.id === scanId && scan.referrals.items.every(scanReferral => scanReferral.id !== referral.id),
    mapper: scan => ({
      ...scan,
      referrals: {
        items: [...scan.referrals.items, referral]
      }
    })
  })

  return updatedTimelineItem
}
