import React, { useMemo } from 'react'
import { ButtonBase, Box, makeStyles } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  buttonBase: {
    borderRadius: ({ borderRadius }) => borderRadius,
    width: ({ small, btnWidth }) => (small ? 110 : btnWidth),
    height: ({ height }) => height
  },
  box: {
    backgroundColor: ({ isOutlined, disabled, color }) => (isOutlined ? 'transparent' : disabled ? 'lightgray' : color),
    color: ({ isOutlined, disabled, fontColor }) => (isOutlined && disabled ? 'var(--text-color-11)' : fontColor),
    border: ({ isOutlined, disabled, fontColor }) =>
      `1px solid ${isOutlined ? (disabled ? 'var(--text-color-11)' : fontColor) : 'transparent'}`
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: ({ disableIconMargin, iconSide }) => (disableIconMargin || iconSide === 'right' ? 0 : 15),
      marginLeft: ({ disableIconMargin, iconSide }) => (disableIconMargin || iconSide === 'left' ? 0 : 15)
    }
  }
}))

const PrimaryButton = ({
  id = '',
  onClick = () => {},
  label,
  isLoading,
  type,
  disabled = false,
  small = false,
  fontWeight = 'normal',
  fontSize = '14px',
  width = '192px',
  height = '45px',
  borderRadius = '30px',
  className = '',
  formId,
  icon,
  fontColor = 'var(--text-color-1)',
  color = 'var(--bg-color-7)',
  variant = 'contained',
  disableIconMargin = false,
  iconSide = 'left'
}) => {
  const isOutlined = useMemo(() => variant === 'outlined', [variant])
  const classes = useStyles({
    borderRadius,
    small,
    btnWidth: width,
    isOutlined,
    disabled,
    fontColor,
    color,
    height,
    disableIconMargin,
    iconSide
  })
  return (
    <ButtonBase
      id={id}
      onClick={e => !isLoading && onClick(e)}
      type={type}
      disabled={disabled}
      className={classes.buttonBase}
      form={formId}
    >
      <Box
        borderRadius={borderRadius}
        pl="20px"
        pr="20px"
        fontFamily="Dazzed"
        fontWeight={fontWeight}
        fontSize={fontSize}
        height={height}
        width={small ? '110px' : width}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={[classes.box, className].join(' ')}
      >
        {isLoading ? (
          <CircularProgress color="inherit" size={16} />
        ) : (
          <span className={classes.label}>
            {iconSide === 'left' && icon}
            {label}
            {iconSide === 'right' && icon}
          </span>
        )}
      </Box>
    </ButtonBase>
  )
}

export default PrimaryButton
