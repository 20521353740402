import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Trans, useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import GiBrainMedium from 'components/common/icons/gi/GiBrainMedium'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { TreatmentTrackOptions } from 'consts/giConsts'
import PositiveFeedback from 'components/common/icons/gi/GiPositiveFeedback'
import NegativeFeedback from 'components/common/icons/gi/GiNegativeFeedback'
import GiConditionList from './GiConditionsList'
import GiResolveButton from './GiResolveButton'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import useRolePermissions from 'hooks/useRolePermissions'
import { AsyncStatus } from 'consts'
import BaseAIMessage from '../BaseAIMessage'
import usePatientTags from 'hooks/usePatientTags'
import UntrackedTeethList from './UntrackedTeethList'
import TeethTrackingSettingsButton from './TeethTrackingSettingsButton'
import GrinChip from 'components/common/GrinChip'

const useStyles = makeStyles(theme => ({
  messageContentContainer: {
    flexWrap: 'nowrap',
    position: 'relative'
  },
  openScanLink: {
    color: 'var(--text-color-25)',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  timestamp: {
    marginTop: 8,
    fontSize: 10,
    textAlign: 'start',
    color: 'rgba(0, 19, 53, 0.45)',
    marginLeft: 3,
    marginBottom: 10
  },
  disabledOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
    background: '#fff',
    opacity: 0.6
  },
  text: {
    fontSize: 13
  }
}))

const GrintelligenceMessage = ({ id, content, grinScan, timestamp, patientId }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()
  const resolveStatus = useSelector(state => state.giReducer.resolvedScans[grinScan?.id])
  const patient = useSelector(state => state.patientsReducer.patient)
  const { tags } = usePatientTags({ patient })

  const parsedContent = useMemo(() => JSON.parse(content || '{}'), [content])
  const parsedTrackingDetails = useMemo(() => JSON.parse(grinScan?.trackingDetails || '{}'), [grinScan])
  const isResolved = useMemo(
    () => parsedTrackingDetails.isResolved || resolveStatus === AsyncStatus.Completed,
    [parsedTrackingDetails, resolveStatus]
  )

  const hasNeedsAttentionTag = useMemo(
    () => tags?.some(tag => tag.type === 'scan' && tag.value === 'needsAttention'),
    [tags]
  )

  const isResolveButtonVisible = useMemo(
    () =>
      parsedContent.giData?.trackStatus === TreatmentTrackOptions.OffTrack &&
      permissions.resolveGiScan &&
      hasNeedsAttentionTag &&
      !isResolved,
    [parsedContent, isResolved, permissions, hasNeedsAttentionTag]
  )

  const handleViewScan = useCallback(() => {
    if (!grinScan) {
      return
    }

    dispatch(
      Actions.openScanViewDialog({
        type: 'scan',
        s3Object: grinScan.video
      })
    )
  }, [grinScan, dispatch])

  const handleResolve = useCallback(() => {
    trackEvent('gi - mark as done clicked', {
      grinScanId: grinScan.id,
      patientId
    })

    dispatch(
      Actions.giMarkScanAsDone({
        grinScanId: grinScan.id
      })
    )
  }, [dispatch, patientId, grinScan])

  return (
    <BaseAIMessage
      containerDivId={`message-scan-${grinScan?.id}`}
      id={id}
      timestamp={timestamp}
      titleIcon={<GiBrainMedium />}
      title={t('pages.patients.selectedPatient.chat.gi.giMessage.title')}
      color="var(--gi-color)"
      bottomComponent={
        parsedTrackingDetails?.untrackedTeeth?.length ? (
          <UntrackedTeethList teethList={parsedTrackingDetails.untrackedTeeth} />
        ) : (
          <TeethTrackingSettingsButton />
        )
      }
    >
      <Grid item>
        <Grid container alignItems="center" direction="row" spacing={1} className={classes.messageContentContainer}>
          {isResolved && <div className={classes.disabledOverlay} />}
          <Grid item>
            {parsedContent.giData?.trackStatus === TreatmentTrackOptions.OnTrack ? (
              <PositiveFeedback />
            ) : (
              <NegativeFeedback />
            )}
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <DazzedParagraph12 thickness="thin" className={classes.text}>
                  <Trans
                    i18nKey={
                      parsedContent.giData?.trackStatus === TreatmentTrackOptions.OnTrack
                        ? 'pages.patients.selectedPatient.chat.gi.giMessage.positiveContent'
                        : 'pages.patients.selectedPatient.chat.gi.giMessage.negativeContent'
                    }
                    components={{
                      a: <span onClick={handleViewScan} className={isResolved ? '' : classes.openScanLink} />
                    }}
                  />
                </DazzedParagraph12>
              </Grid>
              <Grid item>
                <GiConditionList conditions={parsedContent.giData?.conditions} />
              </Grid>
              {parsedContent?.giData?.notes && (
                <Grid item>
                  <GrinChip text={parsedContent.giData.notes} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isResolveButtonVisible && (
        <Grid item style={{ marginTop: 10 }}>
          <GiResolveButton onClick={handleResolve} isLoading={resolveStatus === AsyncStatus.Loading} />
        </Grid>
      )}
    </BaseAIMessage>
  )
}

export default GrintelligenceMessage
