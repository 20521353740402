import * as React from 'react'

const ViewModeEye = props => {
  return (
    <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.15148 9.90215C8.47854 9.90215 7.82071 9.7026 7.26118 9.32873C6.70165 8.95486 6.26555 8.42347 6.00802 7.80175C5.7505 7.18004 5.68312 6.49592 5.8144 5.8359C5.94569 5.17589 6.26974 4.56963 6.74558 4.09379C7.22142 3.61795 7.82768 3.29389 8.4877 3.16261C9.14771 3.03132 9.83183 3.0987 10.4535 3.35623C11.0753 3.61375 11.6067 4.04985 11.9805 4.60938C12.3544 5.16892 12.5539 5.82675 12.5539 6.49969C12.553 7.40178 12.1942 8.26664 11.5563 8.90451C10.9184 9.54238 10.0536 9.90117 9.15148 9.90215ZM9.15148 4.328C8.72196 4.328 8.30209 4.45537 7.94496 4.69399C7.58782 4.93262 7.30947 5.2718 7.1451 5.66862C6.98073 6.06544 6.93773 6.5021 7.02152 6.92337C7.10532 7.34463 7.31215 7.73159 7.61587 8.03531C7.91958 8.33902 8.30654 8.54586 8.72781 8.62965C9.14908 8.71345 9.58573 8.67044 9.98255 8.50607C10.3794 8.3417 10.7185 8.06335 10.9572 7.70622C11.1958 7.34908 11.3232 6.92921 11.3232 6.49969C11.3225 5.92392 11.0935 5.37192 10.6864 4.96479C10.2793 4.55766 9.72725 4.32865 9.15148 4.328Z"
        fill="#898484"
      />
      <path
        d="M9.15151 13C5.37551 13 2.17182 9.91815 0.597664 8.08062C0.212528 7.64415 0 7.08209 0 6.5C0 5.91791 0.212528 5.35585 0.597664 4.91938C2.17182 3.08185 5.37551 0 9.15151 0C12.9275 0 16.1312 3.08185 17.7054 4.91938C18.0904 5.35591 18.3028 5.91795 18.3028 6.5C18.3028 7.08205 18.0904 7.64409 17.7054 8.08062C16.1312 9.91754 12.9275 13 9.15151 13ZM9.15151 1.23077C5.86843 1.23077 2.96874 4.04369 1.53243 5.72C1.33679 5.93264 1.2282 6.21105 1.2282 6.5C1.2282 6.78895 1.33679 7.06736 1.53243 7.28C2.96813 8.95631 5.86782 11.7692 9.1509 11.7692C12.434 11.7692 15.3337 8.95631 16.77 7.28C16.9656 7.06736 17.0742 6.78895 17.0742 6.5C17.0742 6.21105 16.9656 5.93264 16.77 5.72C15.3343 4.04308 12.4346 1.23077 9.15151 1.23077Z"
        fill="#898484"
      />
    </svg>
  )
}

export default ViewModeEye
