import * as React from 'react'

const SendScanReminder = ({ disabled = false }) => {
  return (
    <svg width="40" height="30" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 5.56155C1 4.64382 1.62459 3.84385 2.51493 3.62127L11 1.5V11.5L2.51493 9.37873C1.62459 9.15615 1 8.35618 1 7.43845V5.56155Z"
        stroke={disabled ? '#A7A9BA' : '#4151E6'}
      />
      <path
        d="M9.14721 2.56344C9.67291 1.59966 10.6831 1 11.7809 1H18.2191C19.3169 1 20.3271 1.59966 20.8528 2.56344L22.2164 5.06344C22.7048 5.95888 22.7048 7.04112 22.2164 7.93656L20.8528 10.4366C20.3271 11.4003 19.3169 12 18.2191 12H11.7809C10.6831 12 9.67291 11.4003 9.14721 10.4366L7.78358 7.93656C7.29516 7.04112 7.29516 5.95888 7.78358 5.06344L9.14721 2.56344Z"
        fill="white"
        stroke={disabled ? '#A7A9BA' : '#4151E6'}
      />
      <path
        d="M11.1005 4.57945C11.6704 3.89547 12.5148 3.5 13.4051 3.5H16.5949C17.4852 3.5 18.3296 3.89547 18.8995 4.57945L19.433 5.21963C20.0511 5.96132 20.0511 7.03868 19.433 7.78037L18.8995 8.42055C18.3296 9.10453 17.4852 9.5 16.5949 9.5H13.4051C12.5148 9.5 11.6704 9.10453 11.1005 8.42055L10.567 7.78037C9.9489 7.03868 9.9489 5.96132 10.567 5.21963L11.1005 4.57945Z"
        fill="white"
        stroke={disabled ? '#A7A9BA' : '#4151E6'}
        strokeWidth="0.75"
      />
      <path
        d="M18 8.5C17.1716 8.5 16.5 9.17157 16.5 10V22C16.5 22.6067 16.8655 23.1536 17.426 23.3858C17.9865 23.618 18.6317 23.4897 19.0607 23.0607L21.6213 20.5H31C31.8284 20.5 32.5 19.8284 32.5 19V10C32.5 9.17157 31.8284 8.5 31 8.5H18Z"
        fill="white"
        stroke="white"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M18 10H31V19H21L18 22V10Z"
        fill="white"
        stroke={disabled ? '#A7A9BA' : '#4151E6'}
        strokeLinejoin="round"
      />
      <path d="M21 13H28" stroke={disabled ? '#A7A9BA' : '#4151E6'} strokeLinecap="round" />
      <path d="M21 16H28" stroke={disabled ? '#A7A9BA' : '#4151E6'} strokeLinecap="round" />
    </svg>
  )
}

export default SendScanReminder
