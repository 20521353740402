import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AutochartTableDescriptionCell from './AutochartTableDescriptionCell'
import AutochartTableNotesCell from './AutochartTableNotesCell'
import useAutochart from '../AutochartGrid/useAutochart'
import { exportToCsv } from 'utils/fileUtils'
import useConditionDescription from '../AutochartGrid/useConditionDescription'

export default ({ autochart }) => {
  const { t } = useTranslation()

  const { updateConditionNotes } = useAutochart()
  const { getPropertiesDescription } = useConditionDescription()

  const columns = useMemo(() => {
    return [
      {
        name: 'date',
        label: t('dialogs.autochart.table.columns.date')
      },
      {
        name: 'toothNumber',
        label: t('dialogs.autochart.table.columns.toothNumber'),
        textAlign: 'center',
        render: row => <DazzedParagraph12 thickness="bold">{row.toothNumber}</DazzedParagraph12>
      },
      {
        name: 'description',
        label: t('dialogs.autochart.table.columns.description'),
        render: row => (
          <AutochartTableDescriptionCell
            label={row.description}
            createdByGrin={row.createdByGrin}
            editorId={row.editorId}
          />
        )
      },
      {
        name: 'surfaces',
        label: t('dialogs.autochart.table.columns.surfaces'),
        textAlign: 'center',
        render: row => (
          <DazzedParagraph12 thickness="bold" textAlign="center">
            {row.surfaces}
          </DazzedParagraph12>
        )
      },
      {
        name: 'notes',
        label: t('dialogs.autochart.table.columns.notes'),
        render: row => (
          <AutochartTableNotesCell
            notes={row.notes}
            onEdit={notes =>
              updateConditionNotes({ toothNumber: row.toothNumber, conditionType: row.conditionType, notes })
            }
          />
        )
      }
    ]
  }, [t, updateConditionNotes])

  const rows = useMemo(() => {
    if (!autochart.teeth) {
      return []
    }

    return Object.values(autochart.teeth)
      .filter(tooth => tooth.conditions?.length > 0)
      .map(tooth =>
        tooth.conditions.map(condition => {
          const properiesDescription = getPropertiesDescription({
            conditionType: condition.type,
            properties: condition.properties
          })
          return {
            conditionType: condition.type,
            toothNumber: tooth.number,
            date: moment(condition.updatedAt).format('MM/DD/YYYY'),
            description: `${t(`dialogs.autochart.conditions.${condition.type}`)} ${
              properiesDescription ? `(${properiesDescription})` : ''
            }`,
            surfaces: !condition.surfaces?.length ? '-' : condition.surfaces?.sort((c1, c2) => c1 - c2).join(''),
            notes: condition.note,
            createdByGrin: condition.editedBy === 'Grin',
            editorId: condition.editedBy !== 'Grin' ? condition.editedBy : null
          }
        })
      )
      .flat()
  }, [autochart, t, getPropertiesDescription])

  const downloadTableCsv = useCallback(
    ({ fileName = 'autochart.csv' }) => {
      if (!rows.length) {
        return
      }

      const exportData = rows.map(row =>
        columns.reduce(
          (final, column) => ({
            ...final,
            [column.label]: row[column.name]
          }),
          {}
        )
      )

      exportToCsv({ data: exportData, fileName })
    },
    [rows, columns]
  )

  return {
    columns,
    rows,
    downloadTableCsv
  }
}
