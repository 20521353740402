import { Tabs } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const GrinTabs = withStyles({
  indicator: {
    backgroundColor: 'var(--text-color-3)'
  }
})(Tabs)

export default GrinTabs
