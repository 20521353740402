import moment from 'moment'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import usePracticeGuidelines from './usePracticeGuidelines'
import TrackingGuidelines from '../TrackingGuidelines'
import useHiTools from 'hooks/useHiTools'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'

const PracticeGuidelines = () => {
  const { t } = useTranslation()

  const { hasAnyPracticeGuidelinesConfigured } = useHiTools()
  const { practiceGuidelines } = useSelector(state => state.multiPracticeReducer)
  const practiceMembers = useSelector(state => state.profileReducer.practiceMembers.data)

  const lastUpdatedBy = useMemo(
    () => practiceMembers?.find(pm => pm.id === practiceGuidelines?.creatorDoctorId)?.name,
    [practiceGuidelines, practiceMembers]
  )
  const lastUpdated = useMemo(() => moment(practiceGuidelines.createdAt).format('MMM D, YYYY'), [practiceGuidelines])
  const parsedCategories = useMemo(() => JSON.parse(practiceGuidelines.categories || '{}'), [practiceGuidelines])
  const { categories } = usePracticeGuidelines({ categoriesData: parsedCategories })

  return !hasAnyPracticeGuidelinesConfigured ? (
    <TrackingGuidelines
      lastUpdated={lastUpdated}
      lastUpdatedBy={lastUpdatedBy}
      categories={categories}
      categoriesData={parsedCategories}
    />
  ) : (
    <div>
      <DazzedParagraph14>{t('dialogs.hiPatientGuidelinesViewer.tabs.patientBrief.emptyState')}</DazzedParagraph14>
    </div>
  )
}

export default PracticeGuidelines
