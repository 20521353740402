import * as React from 'react'

export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#91B3C6" />
      <path
        d="M8.75422 11.7933V11.6548C8.75777 11.0121 8.81814 10.5007 8.93533 10.1207C9.05606 9.74077 9.22652 9.43537 9.44669 9.20455C9.66686 8.97017 9.93497 8.75533 10.251 8.56001C10.4676 8.42507 10.6612 8.2777 10.8316 8.1179C11.0056 7.95455 11.1424 7.77344 11.2418 7.57457C11.3412 7.37216 11.3909 7.14666 11.3909 6.89808C11.3909 6.61754 11.3252 6.37429 11.1938 6.16832C11.0625 5.96236 10.8849 5.80256 10.6612 5.68892C10.441 5.57528 10.1942 5.51847 9.92077 5.51847C9.66863 5.51847 9.42893 5.57351 9.20166 5.68359C8.97794 5.79013 8.7915 5.95348 8.64236 6.17365C8.49676 6.39027 8.41508 6.66548 8.39733 6.99929H6.50102C6.51878 6.32457 6.68213 5.75994 6.99108 5.3054C7.30358 4.85085 7.71551 4.50994 8.22687 4.28267C8.74179 4.0554 9.30997 3.94176 9.93142 3.94176C10.6097 3.94176 11.2063 4.06072 11.7212 4.29865C12.2397 4.53658 12.6427 4.87571 12.9304 5.31605C13.2215 5.75284 13.3671 6.27131 13.3671 6.87145C13.3671 7.27628 13.3014 7.63849 13.1701 7.9581C13.0422 8.2777 12.8593 8.56179 12.6214 8.81037C12.3835 9.05895 12.1012 9.28089 11.7745 9.47621C11.4868 9.65376 11.2507 9.83842 11.066 10.0302C10.8849 10.2219 10.75 10.4474 10.6612 10.7067C10.5759 10.9624 10.5316 11.2784 10.528 11.6548V11.7933H8.75422ZM9.68106 15.1172C9.36146 15.1172 9.08625 15.0036 8.85542 14.7763C8.6246 14.549 8.50919 14.272 8.50919 13.9453C8.50919 13.6257 8.6246 13.3523 8.85542 13.125C9.08625 12.8977 9.36146 12.7841 9.68106 12.7841C9.99711 12.7841 10.2706 12.8977 10.5014 13.125C10.7358 13.3523 10.8529 13.6257 10.8529 13.9453C10.8529 14.1619 10.7979 14.359 10.6878 14.5366C10.5813 14.7141 10.4392 14.8562 10.2617 14.9627C10.0877 15.0657 9.89413 15.1172 9.68106 15.1172Z"
        fill="white"
      />
    </svg>
  )
}
