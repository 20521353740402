import React, { useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import ThumbnailUnavailable from 'components/common/icons/timelineV2/mini-timeline/ThumbnailUnavailable'
import { CircularProgress } from '@material-ui/core'
import DazzedParagraph11 from 'components/common/text/DazzedParagraph11'
import { Skeleton } from '@material-ui/lab'
import { logInfo } from 'utils/logUtils'
import WithAlignerTransparent from 'components/common/icons/timelineV2/WithAlignerTransparent'
import WithoutAlignerTransparent from 'components/common/icons/timelineV2/WithoutAlignerTransparent'
import PlayWithBackground from 'components/common/icons/PlayWithBackground'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '115px',
    aspectRatio: 1.79,
    cursor: ({ clickable }) => (clickable ? 'pointer' : 'default')
  },
  scanThumbnailMedia: {
    width: '100%',
    height: '100%',
    borderRadius: 6,
    '&::-webkit-media-controls-panel': {
      backgroundImage: 'none !important' // Removes the weird gradient from the video element
    }
  },
  placeholderContainer: {
    background: 'rgba(242, 243, 245, 1)',
    borderRadius: 6,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 4
  },
  alignerOverlayContainer: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  videoOverlay: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%'
  }
}))

export const ScanThumbnailStatuses = {
  Pending: 'pending',
  Completed: 'completed',
  Failed: 'failed'
}

/**
 * @param mediaType Either `video` or `image`
 * @param src The media src
 * @param status? Use the values from the `ScanThumbnailStatuses` enum. If not provided, `completed` is assumed.
 * @param renderAlignerIconOverlay? Whether to show the with/without aligner icon overlay.
 * @param withAligner? Determines whether the render the with aligner icon or the without aligner icon. Only relevant if `renderAlignerIconOverlay` is provided.
 */
const ScanThumbnail = ({
  mediaType,
  src,
  status = ScanThumbnailStatuses.Completed,
  renderAlignerIconOverlay = false,
  withAligner,
  containerClassName = '',
  onClick
}) => {
  const classes = useStyles({ clickable: !!onClick })
  const { t } = useTranslation()
  const mediaLoadStartTime = useRef()

  const [isLoadingMedia, setIsLoadingMedia] = useState(false)

  const handleMediaLoaded = useCallback(() => {
    setIsLoadingMedia(false)
    logInfo(`ScanThumbnail: finished loading media`, {
      mediaType,
      took: new Date().getTime() - mediaLoadStartTime.current
    })
  }, [mediaType])

  useEffect(() => {
    setIsLoadingMedia(true)
    mediaLoadStartTime.current = new Date().getTime()
  }, [src, mediaType])

  return (
    <div className={[classes.root, containerClassName].join(' ')} onClick={onClick}>
      {renderAlignerIconOverlay && (
        <div className={classes.alignerOverlayContainer}>
          {withAligner ? <WithAlignerTransparent /> : <WithoutAlignerTransparent />}
        </div>
      )}
      {status === ScanThumbnailStatuses.Completed && src ? (
        <>
          {isLoadingMedia && <Skeleton variant="rect" className={classes.scanThumbnailMedia} />}
          <div style={{ display: isLoadingMedia ? 'none' : 'block' }}>
            {mediaType === 'image' ? (
              <img src={src} alt="Scan thumbnail" className={classes.scanThumbnailMedia} onLoad={handleMediaLoaded} />
            ) : (
              <>
                <video
                  src={src}
                  controls={false}
                  muted
                  className={classes.scanThumbnailMedia}
                  onLoadedData={handleMediaLoaded}
                />
                <div className={classes.videoOverlay}>
                  <PlayWithBackground />
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className={classes.placeholderContainer}>
          {status === ScanThumbnailStatuses.Pending && src ? (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress size={13} />
              <DazzedParagraph11 textAlign="center">
                {t('pages.patients.selectedPatient.timelineV2.generatingScanSummary')}
              </DazzedParagraph11>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <ThumbnailUnavailable />
              <DazzedParagraph11 textAlign="center">
                {t('pages.patients.selectedPatient.timelineV2.thumbnailUnavailable')}
              </DazzedParagraph11>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ScanThumbnail
