import AWS from 'aws-sdk'
import config from '../utils/awsUtils'
import { getEnvironment } from './awsUtils'
import { CLINIC_LOGO_FOLDER } from 'consts/settings'

export function getDoctorId() {
  return localStorage.getItem('doctorId')
}

export function setDoctorId(doctorId) {
  localStorage.setItem('doctorId', doctorId)
}

export function getDoctorEmail() {
  return localStorage.getItem('doctorEmail')
}

export function setDoctorEmail(email) {
  localStorage.setItem('doctorEmail', email)
}

export function setBillingInfoRequired(value) {
  localStorage.setItem('requireBillingInfo', value)
}

export function isBillingInfoRequired() {
  return localStorage.getItem('requireBillingInfo') === 'true'
}

export function removeDoctorData() {
  localStorage.removeItem('doctorId')
  localStorage.removeItem('doctorUsername')
  localStorage.removeItem('doctorEmail')
  localStorage.removeItem('requireBillingInfo')
}

export function getDoctorUsername() {
  return localStorage.getItem('doctorUsername')
}

export function setDoctorUsername(doctorUsername) {
  localStorage.setItem('doctorUsername', doctorUsername)
}

export function removeForceDesktop() {
  localStorage.removeItem('forceDesktop')
}

export function getUserGroups() {
  return sessionStorage.getItem('userGroups') || []
}

export function getUserFlag(key) {
  return localStorage.getItem(`flag_${key}`)
}

export function setUserFlag(key, value) {
  localStorage.setItem(`flag_${key}`, value)
}

export function removeUserFlag(key) {
  localStorage.removeItem(`flag_${key}`)
}

export const setPracticeInfoDraft = data => {
  localStorage.setItem('practiceInfo', JSON.stringify(data))
}

export const clearPracticeInfoDraft = data => {
  localStorage.removeItem('practiceInfo')
}

export const getPracticeInfoDraft = () => {
  return JSON.parse(localStorage.getItem('practiceInfo'))
}

export const setDoctorsMessageDraft = (id, text) => {
  const drafts = JSON.parse(localStorage.getItem('chatDrafts')) || {}
  drafts[id] = text
  localStorage.setItem('chatDrafts', JSON.stringify(drafts))
}

export const getDoctorsMessageDraft = id => {
  const drafts = JSON.parse(localStorage.getItem('chatDrafts')) || {}
  return drafts[id] || ''
}

export const removeDoctorsMessageDraft = id => {
  const drafts = JSON.parse(localStorage.getItem('chatDrafts')) || {}
  delete drafts[id]
  return localStorage.setItem('chatDrafts', JSON.stringify(drafts))
}

export const setRCShowMore = data => {
  localStorage.setItem('rcShowMore', JSON.stringify(data))
}

export const getRCShowMore = data => {
  return JSON.parse(localStorage.getItem('rcShowMore'))
}

export const setOrthoShowMore = data => {
  localStorage.setItem('orthoShowMore', JSON.stringify(data))
}

export const getOrthoShowMore = data => {
  return JSON.parse(localStorage.getItem('orthoShowMore'))
}

export const setServiceAccountShowMore = data => {
  localStorage.setItem('serviceAccountShowMore', JSON.stringify(data))
}
export const getServiceAccountShowMore = data => {
  return JSON.parse(localStorage.getItem('serviceAccountShowMore'))
}

export const setPatientsListExpanded = data => {
  localStorage.setItem('patientsListExpanded', JSON.stringify(data))
}

export const getPatientsListExpanded = data => {
  const value = JSON.parse(localStorage.getItem('patientsListExpanded'))
  return value === null ? true : value
}

export const setKistOrder = data => {
  localStorage.setItem('kitsOrder', JSON.stringify(data))
}

export const getKistOrder = () => {
  const kitsOrder = JSON.parse(localStorage.getItem('kitsOrder'))
  return kitsOrder
}

export const removeKitsOrder = () => {
  localStorage.removeItem('kitsOrder')
}

export const setDoNotShowPromotionsPopupOnThisDevice = data => {
  localStorage.setItem('doNotShowPromotionsPopup', data)
}

export const setDoNotShowScheduledMessageWarningTooltip = data => {
  localStorage.setItem('doNotShowScheduledMessageWarning', data)
}

export const getScannerType = () => {
  return localStorage.getItem('scannerType') || ''
}

export const setScannerType = scannerType => {
  localStorage.setItem('scannerType', scannerType)
}

export const getDoNotShowPromotionsPopupOnThisDevice = () => {
  return JSON.parse(localStorage.getItem('doNotShowPromotionsPopup'))
}

export const getDoNotShowPersonalMessagePopupOnThisDevice = () => {
  return localStorage.getItem('doNotShowPersonalMessagePopup')
}

export const getDoNotShowScheduledMessageWarningTooltip = () => {
  return localStorage.getItem('doNotShowScheduledMessageWarning')
}

export const setDoNotShowPersonalMessagePopupOnThisDevice = data => {
  localStorage.setItem('doNotShowPersonalMessagePopup', data)
}

export const getDismissedAnnouncementsList = () => JSON.parse(localStorage.getItem('dismissedAnnouncements') || '[]')

export const dismissAnnouncementForGood = announcementId => {
  const announcementsFilter = getDismissedAnnouncementsList()
  localStorage.setItem('dismissedAnnouncements', JSON.stringify([...announcementsFilter, announcementId]))
}

export const calculateCloudFrontUrl = () => {
  switch (getEnvironment()) {
    case 'newdev':
      return 'https://d1vc6solu77ibp.cloudfront.net'
    case 'idev':
      return 'https://d1vc6solu77ibp.cloudfront.net'
    case 'staging':
      return 'https://d30dcxplr09ks0.cloudfront.net'
    case 'production':
      return 'https://d6srlm5xifzvg.cloudfront.net'
    case 'ordev':
      return 'https://d3vyz1cxbuhdlk.cloudfront.net'
    case 'tomerkodev':
      return 'https://d1uct56apz1nca.cloudfront.net'
    case 'adirdev':
      return 'https://d1mb2ju4m5nl56.cloudfront.net'
    case 'tomdev':
      return 'https://d77p1g0g2h5t0.cloudfront.net'
    default:
      return 'https://d1vc6solu77ibp.cloudfront.net'
  }
}

export const grinCommonCloudfrontUrl = 'https://d1g4lo0dz8n4nh.cloudfront.net'

export function setCloudFrontUrl() {
  sessionStorage.setItem('cloudfrontUrl', calculateCloudFrontUrl())
}

export function getCloudFrontUrl() {
  return sessionStorage.getItem('cloudfrontUrl') || calculateCloudFrontUrl()
}

export function getNewMediaCloudfrontUrl() {
  return sessionStorage.getItem('newMediaCloudfrontUrl')
}

export function withS3Details(fileKey) {
  return {
    bucket: config.aws_user_files_s3_bucket,
    key: fileKey,
    region: config.aws_user_files_s3_bucket_region
  }
}

export const stripToS3Object = object => ({
  key: object.key,
  bucket: object.bucket,
  region: object.region
})

export const getAccessToken = () => {
  return localStorage.getItem(
    `CognitoIdentityServiceProvider.${config.aws_user_pools_web_client_id}.${getDoctorUsername()}.accessToken`
  )
}

export const getIdToken = () => {
  return (
    sessionStorage.getItem('referrals.token') ||
    localStorage.getItem(
      `CognitoIdentityServiceProvider.${config.aws_user_pools_web_client_id}.${getDoctorUsername()}.idToken`
    )
  )
}

export const S3Instance = () =>
  new AWS.S3({
    apiVersion: '2006-03-01'
  })

export const updateS3PracticeLogoKey = s3Object => {
  return s3Object?.key
    ? {
        ...s3Object,
        key: s3Object ? `${CLINIC_LOGO_FOLDER}/${s3Object.key}` : null
      }
    : null
}

export const s3ObjectToCloudfront = s3Object => {
  const cloudfrontUrl = getCloudFrontUrl()
  const key = s3Object?.key.replace('public/', '')
  return `${cloudfrontUrl}/${key}`
}

export const extractS3Key = s3Object => s3Object.key

export const updateS3Key = s3Object => ({
  ...s3Object,
  key: s3Object.key.replace('public/', '')
})

export const get3DScanPosition = s3Object => {
  return s3Object.key.replace('stls/', '').split('_')[0]
}
