import { makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import SecondaryButton from '../../common/buttons/SecondaryButton'

const useStyles = makeStyles(isNewPricing => ({
  action: {
    background: 'transparent'
  },
  buttonWrapper: {
    marginRight: 10
  }
}))
const SubscriptionActions = ({ isTrial, onCancel, onUpgrade }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleContactUsClickLink = useCallback(() => {
    trackEvent(`billing tab - contact us clicked`)
    window.open(`mailto:sales@get-grin.com;orders@get-grin.com`, '_blank')
  }, [])

  return (
    <div>
      {isTrial && (
        <SecondaryButton
          label={t('pages.accountSettings.billing.contactUs')}
          onClick={handleContactUsClickLink}
          className={classes.action}
          wrapperClassName={classes.buttonWrapper}
        />
      )}
      {onCancel && !isTrial && (
        <SecondaryButton
          label={t('pages.accountSettings.billing.cancelPlan')}
          onClick={onCancel}
          className={classes.action}
          wrapperClassName={classes.buttonWrapper}
        />
      )}
      {onUpgrade && !isTrial && (
        <SecondaryButton
          label={t('pages.accountSettings.billing.changePlan')}
          onClick={onUpgrade}
          className={classes.action}
          wrapperClassName={classes.buttonWrapper}
        />
      )}
    </div>
  )
}

export default SubscriptionActions
