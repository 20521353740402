import React, { useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import MiniTimeline from './MiniTimeline/MiniTimeline'
import useTimelimeItems from './useTimelineItems'
import TimelineBackButton from './TimelineBackButton'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { AsyncStatus } from 'consts'
import ScanFeedSkeleton from './Skeletons/ScanFeedSkeleton'
import useTimelineItemSelection from './useTimelineItemSelection'
import useLoadPatientTimelineItems from './useLoadPatientTimelineItems'
import ScanFeed from './ScanFeed/ScanFeed'
import { scrollBar } from 'styles/theme'
import TimelineEmptyState from './TimelineEmptyState'
import PatientBanner from './PatientBanner'
import MainFeed from './MainFeed/MainFeed'
import MiniTimelineSkeleton from './Skeletons/MiniTimelineSkeleton'

const useStyles = makeStyles(theme => ({
  timelineBody: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    height: 'calc(100% - var(--patient-banner-height))'
  },
  miniTimelineContainer: {
    height: 'calc(100% - 88px)' // 88px = back button (38px) + scroll to top (25px) + scroll to bottom (25px)
  },
  feedContainer: {
    flex: 1,
    padding: '0 16px 16px 16px',
    height: '100%',
    overflowY: 'scroll',
    ...scrollBar,
    '&::-webkit-scrollbar': {
      background: 'var(--bg-color-timelineV2)',
      width: 5
    }
  }
}))

const Timeline = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { isLoadingPatient } = useSelector(state => state.patientsReducer)
  const { loadStatus: timelineItemsLoadStatus, data: patientTimelineItems } = useSelector(
    state => state.timelineReducer.mainFeed.timelineItems
  )

  useLoadPatientTimelineItems()
  const { selectedTimelineItemId, setSelectedTimelineItem, clearSelectedTimelineItem } = useTimelineItemSelection()
  const { miniTimelineItems, mainFeedItems, hasAnyScans } = useTimelimeItems({
    patientTimelineItems: patientTimelineItems
  })

  const isLoadingTimelineItems = useMemo(
    () => timelineItemsLoadStatus === AsyncStatus.Loading,
    [timelineItemsLoadStatus]
  )

  useEffect(() => {
    if (timelineItemsLoadStatus === AsyncStatus.Completed) {
      dispatch(
        Actions.selectTimelineItem({
          timelineItemId: selectedTimelineItemId
        })
      )
    }
  }, [selectedTimelineItemId, timelineItemsLoadStatus, dispatch])

  return (
    <>
      <PatientBanner isLoading={isLoadingPatient} />
      <div className={classes.timelineBody}>
        {isLoadingTimelineItems ? (
          <div className={classes.miniTimelineContainer}>
            <MiniTimelineSkeleton />
          </div>
        ) : hasAnyScans ? (
          <div className={classes.miniTimelineContainer}>
            <TimelineBackButton visible={!!selectedTimelineItemId} onClick={clearSelectedTimelineItem} />
            <MiniTimeline
              timelineItems={miniTimelineItems}
              selectedTimelineItemId={selectedTimelineItemId}
              setSelectedTimelineItem={setSelectedTimelineItem}
            />
          </div>
        ) : (
          <></>
        )}
        <div className={classes.feedContainer}>
          {isLoadingTimelineItems ? (
            <ScanFeedSkeleton />
          ) : hasAnyScans ? (
            selectedTimelineItemId ? (
              <ScanFeed />
            ) : (
              <MainFeed timelineItems={mainFeedItems} setSelectedTimelineItem={setSelectedTimelineItem} />
            )
          ) : (
            <TimelineEmptyState />
          )}
        </div>
      </div>
    </>
  )
}

export default Timeline
