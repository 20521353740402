import Actions from '../actions'
import { updateWhere, upsertItem, replaceItem, removeWhere, updateAllWhere } from '../utils/arrayUtils'
import {
  bringRoomToTop,
  generateDummyRoom,
  updateRoomLastMessage,
  upsertMessagesList,
  handleUploadingLocalMessages,
  insertLocalMessages,
  updateRoomsWithUnreadMessages
} from '../utils/chatUtils'
import { find, matchesProperty } from 'lodash'
import { upsertPatientTag, removePatientTagFromPatient } from '../utils/tagUtils'
import { mapToPromotions } from 'utils/mappers/messagesMapper'
import { GI_RESULT_MSG_TYPE } from 'consts/giConsts'

const initialState = {
  messages: {
    items: [],
    isLoading: false
  },
  scheduledMessages: {
    items: [],
    wasLastSentMessageScheduled: false
  },
  activeRoomId: null,
  pinnedPatient: null,
  rooms: [],
  filter: {
    tagsFilter: {},
    searchFilter: null,
    doctorFilter: ''
  },
  pagination: {
    hasMore: false,
    nextToken: null
  },
  shouldAutoScrollChat: true,
  overlayLoader: true,
  shouldRequestRooms: true,
  uploadingMessages: [],
  promotions: {
    data: [],
    lastMessages: {
      roomId: null,
      data: []
    },
    isFetching: false,
    fetchError: false,
    isSaving: false
  },
  isLoading: false,
  broadcast: {
    isModeOn: false,
    selectedPatients: [],
    excluded: [],
    allSelected: false,
    isLoading: false,
    isConfirmationModalOpen: false,
    localMessages: [],
    isPersonalMessage: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.SIGNOUT_RECEIVED:
      return initialState
    case Actions.PATIENT_REQUESTED:
      return {
        ...state,
        messages: initialState.messages
      }
    case Actions.PATIENT_MESSAGES_REQUESTED:
      return {
        ...state,
        shouldAutoScrollChat: !action.payload.nextToken,
        activeRoomId: action.payload.roomId,
        messages: {
          ...state.messages,
          isLoading: true
        }
      }
    case Actions.SEARCH_RESULTS_RECEIVED:
      return {
        ...state
      }
    case Actions.PATIENT_MESSAGES_RECEIVED:
      return {
        ...state,
        messages: {
          ...state.messages,
          items:
            action.payload.method === 'push'
              ? [...state.messages.items, ...action.payload?.messages.items]
              : insertLocalMessages(action.payload?.messages.items, state.uploadingMessages, state.activeRoomId),
          nextToken: action.payload?.messages?.nextToken,
          isLoading: false
        },
        scheduledMessages: {
          ...state.scheduledMessages,
          items: [...action.payload?.scheduledMessages?.items.filter(scheduledMessage => !scheduledMessage._deleted)]
        }
      }
    case Actions.PATIENT_MESSAGES_FAILED:
      return {
        ...state,
        messages: {
          ...state.messages,
          isLoading: false
        }
      }
    case Actions.MESSAGE_ADDED: {
      let messages = state.messages.items
      if (action.payload.roomId === state.activeRoomId) {
        messages = upsertMessagesList(state.messages.items, action.payload)

        // If recevied a GI result message,
        // update the grinScan object in other messages which are associated to the same scan
        if (action.payload.type === GI_RESULT_MSG_TYPE) {
          messages = updateAllWhere(
            messages,
            msg => msg.entityRelatedId === action.payload.entityRelatedId,
            msg => ({ ...msg, grinScan: action.payload.grinScan })
          )
        }
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          items: messages
        },
        rooms: bringRoomToTop(
          updateRoomLastMessage({ rooms: state.rooms, message: action.payload }),
          action.payload.roomId
        ),
        shouldAutoScrollChat: true,
        uploadingMessages: handleUploadingLocalMessages(state.uploadingMessages, action.payload)
      }
    }
    case Actions.SCHEDULED_MESSAGE_ADDED:
      return {
        ...state,
        scheduledMessages: {
          ...state.scheduledMessages,
          items:
            action.payload.roomId === state.activeRoomId && !action.payload.isSelf
              ? [...state.scheduledMessages.items, { ...action.payload, cache: action.payload.cache }]
              : state.scheduledMessages.items
        },
        rooms: bringRoomToTop(
          updateRoomLastMessage({ rooms: state.rooms, message: JSON.parse(action.payload.payload) }),
          action.payload.roomId
        ),
        shouldAutoScrollChat: true
      }
    case Actions.SCHEDULED_MESSAGE_SENT:
      return {
        ...state,
        scheduledMessages: {
          ...state.scheduledMessages,
          items: replaceItem(state.scheduledMessages.items, action.payload)
        }
      }
    case Actions.FETCH_ROOMS:
      return {
        ...state,
        isLoading: !action.payload?.withoutLoader || action.payload?.isLoading,
        filter: {
          tagsFilter: action.payload?.tagsFilter || {},
          searchFilter: action.payload?.searchFilter || null,
          doctorFilter: action.payload?.doctorFilter || ''
        },
        pagination: action.payload?.newSearch ? { ...initialState.pagination } : { ...state.pagination }
      }
    case Actions.ROOMS_RECEIVED:
      let rooms = []
      if (action.payload.newSearch) rooms = updateRoomsWithUnreadMessages(state.rooms, action.payload.items)
      else {
        rooms = action.payload.addToTop
          ? [
              ...action.payload.items,
              ...state.rooms.filter(patientSM => !find(action.payload.items, matchesProperty('id', patientSM.id)))
            ]
          : [...state.rooms, ...action.payload.items]
      }

      return {
        ...state,
        shouldRequestRooms: false,
        rooms,
        overlayLoader: false,
        isLoading: false,
        isFetchingNext: false,
        pagination: {
          nextToken: action.payload.nextToken,
          hasMore: state.hasMore || !!action.payload.nextToken
        }
      }
    case Actions.FETCH_ROOMS_FAILED:
      return {
        ...state,
        isLoading: false,
        isFetchingNext: false
      }
    case Actions.MARK_ROOM_UNREAD:
      return {
        ...state,
        rooms: updateWhere(
          state.rooms,
          room => room.roomId === action.payload,
          room => (room.isChatRoomResolved = false)
        )
      }
    case Actions.UPDATE_ROOM_READ_STATUS:
      return {
        ...state,
        rooms: state.rooms.map(patientSM => ({
          ...patientSM,
          isChatRoomResolved: action.payload[patientSM?.id] ?? patientSM.isChatRoomResolved
        }))
      }
    case Actions.ADD_NEW_PATIENT_CHAT:
      return {
        ...state,
        rooms: [
          action.payload,
          ...state.rooms.filter(room => room.patient.details.email !== action.payload.patient.details.email)
        ]
      }
    case Actions.UPDATE_ROOM:
      return {
        ...state,
        rooms: upsertItem({ array: state.rooms, item: action.payload, mergeOldProperties: true })
      }
    case Actions.INVITE_NEW_PATIENT:
      return {
        ...state,
        rooms: [generateDummyRoom(action.payload), ...state.rooms]
      }
    case Actions.INVITE_NEW_PATIENT_FAILED:
      return {
        ...state,
        rooms: state.rooms.filter(
          room => room.patient.details.email !== action.payload.patientEmail || room.isDummyRoom !== true
        )
      }
    case Actions.CHAT_ROOM_SELECTED:
    case Actions.TIMELINE_SELECTED:
      return {
        ...state,
        pinnedPatient: initialState.pinnedPatient
      }
    case Actions.PIN_CHAT_ROOM:
      return {
        ...state,
        pinnedPatient: action.payload
      }
    case Actions.UNPIN_CHAT_ROOM:
      return {
        ...state,
        pinnedPatient: null
      }
    case Actions.RESEND_PATIENT_INVITATION_REQUESTED:
    case Actions.DELETE_PATIENT_REQUESTED:
      return {
        ...state,
        ...(state.pinnedPatient?.id === action.payload.patientId && {
          pinnedPatient: { ...state.pinnedPatient, isLoading: true }
        }),
        rooms: updateWhere(
          state.rooms,
          room => room.patient.id === action.payload.patientId,
          room => (room.isLoading = true)
        )
      }
    case Actions.RESEND_PATIENT_INVITATION_RECEIVED:
      return {
        ...state,
        ...(state.pinnedPatient?.id === action.payload.patientId && {
          pinnedPatient: { ...state.pinnedPatient, isLoading: false }
        }),
        rooms: !action.payload.wasNewPatientCreated
          ? updateWhere(
              state.rooms,
              room => room.patient.id === action.payload.patientId,
              room => (room.isLoading = false)
            )
          : state.rooms.filter(room => room.patient.id !== action.payload.patientId)
      }
    case Actions.DELETE_PATIENT_RECEIVED:
      return {
        ...state,
        rooms: state.rooms.filter(room => room.patient.id !== action.payload.patientId)
      }
    case Actions.RESEND_PATIENT_INVITATION_FAILED:
    case Actions.DELETE_PATIENT_FAILED:
      return {
        ...state,
        ...(state.pinnedPatient?.id === action.payload.patientId && {
          pinnedPatient: { ...state.pinnedPatient, isLoading: false }
        }),
        rooms: updateWhere(
          state.rooms,
          room => room.patient.id === action.payload.patientId,
          room => (room.isLoading = false)
        )
      }
    case Actions.UPDATE_PATIENT_DETAILS_RECEIVED:
      return {
        ...state,
        rooms: updateWhere(
          state.rooms,
          room => room.patient.id === action.payload.patient.id,
          room => {
            room.name = action.payload.patient.details.name
            room.firstName = action.payload.patient.details.firstName
            room.lastName = action.payload.patient.details.lastName
            return (room.patient = { ...room.patient, ...action.payload.patient })
          }
        )
      }
    case Actions.ASSIGN_PATIENT_TO_PRACTICE_MEMBER:
      return {
        ...state,
        rooms: updateWhere(
          state.rooms,
          room => room.patientId === action.payload.patientId,
          room => {
            room.patient = upsertPatientTag(room.patient, action.payload)
            room.tagKeywords = [...room.tagKeywords, action.payload]
          }
        )
      }
    case Actions.ASSIGN_PATIENT_TAG:
      return {
        ...state,
        rooms: updateWhere(
          state.rooms,
          room => room.patientId === action.payload.patientId,
          room => (room.tagKeywords = [...room.tagKeywords, action.payload])
        )
      }
    case Actions.REMOVE_PATIENT_TAG:
      return {
        ...state,
        rooms: updateWhere(
          state.rooms,
          room => room.patientId === action.payload.patientId,
          room => {
            room.patient = removePatientTagFromPatient(room.patient, action.payload.tag)
            room.tagKeywords = room.tagKeywords.filter(tag => tag !== action.payload.tag)
          }
        )
      }
    case Actions.UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT:
      return {
        ...state,
        rooms: updateWhere(
          state.rooms,
          room => room.patientId === action.payload.patientId,
          room => {
            const assigneeTag = room.patientTags.items.find(patientTag => patientTag.tag.type === 'assignee')
            if (assigneeTag) {
              room.patient = removePatientTagFromPatient(room.patient, assigneeTag.tag.value)
              room.tagKeywords = room.tagKeywords.filter(tag => tag !== assigneeTag.tag.value)
            }
          }
        )
      }
    case Actions.REMOVE_CHAT_ROOM:
      return {
        ...state,
        rooms: updateWhere(
          state.rooms,
          room => room.patientId === action.payload.patientId,
          room => room._deleted === true
        )
      }
    case Actions.COPY_MESSAGE:
      return {
        ...state,
        shouldAutoScrollChat: false
      }
    case Actions.DELETE_MESSAGE:
      return {
        ...state,
        shouldAutoScrollChat: false,
        messages: {
          ...state.messages,
          items: updateWhere(
            state.messages.items,
            message => message.id === action.payload.id,
            message => (message.isDeleting = true)
          )
        }
      }
    case Actions.UPDATE_TREATMENT_STATUS_RECEIVED:
      return {
        ...state,
        rooms: updateWhere(
          state.rooms,
          room => room.patientId === action.payload.patientId,
          room => (room.statusKey = action.payload.statusKey)
        )
      }
    case Actions.UPDATE_TREATMENT_RECEIVED:
      return {
        ...state,
        rooms: updateWhere(
          state.rooms,
          room => room.username === action.payload.treatment.a_patient,
          room => (room.statusKey = action.payload.treatment.currentStatus.statusKey)
        )
      }
    case Actions.DELETED_MESSAGE_RECEIVED:
      return {
        ...state,
        messages: {
          ...state.messages,
          items: replaceItem(state.messages.items, action.payload)
        }
      }
    case Actions.FETCH_STATIC_DATA_RECEIVED: {
      return {
        ...state,
        promotions: {
          ...state.promotions,
          data: mapToPromotions(action.payload.promotions),
          isFetching: false,
          fetchError: false
        }
      }
    }
    case Actions.SAVE_PROMOTION:
      return {
        ...state,
        promotions: {
          ...state.promotions,
          isSaving: true
        }
      }
    case Actions.SAVE_PROMOTION_RECEIVED: {
      return {
        ...state,
        promotions: {
          ...state.promotions,
          isSaving: false,
          data: upsertItem({ array: state.promotions.data, item: action.payload })
        }
      }
    }
    case Actions.SAVE_PROMOTION_FAILED: {
      return {
        ...state,
        promotions: {
          ...state.promotions,
          isSaving: false
        }
      }
    }
    case Actions.DELETE_PROMOTION: {
      return {
        ...state,
        promotions: {
          ...state.promotions,
          isSaving: true
        }
      }
    }
    case Actions.DELETE_PROMOTION_RECEIVED: {
      return {
        ...state,
        promotions: {
          ...state.promotions,
          isSaving: false,
          data: updateWhere(
            state.promotions.data,
            promotion => promotion.id === action.payload.id,
            promotion => {
              promotion.categoryKey = action.payload.categoryKey
              promotion._version = action.payload._version
            }
          )
        }
      }
    }
    case Actions.DELETE_PROMOTION_FAILED: {
      return {
        ...state,
        promotions: {
          ...state.promotions,
          isSaving: false
        }
      }
    }
    case Actions.LAST_PROMOTION_MESSAGES_RECEIVED: {
      return {
        ...state,
        promotions: {
          ...state.promotions,
          lastMessages: {
            ...state.promotions.lastMessages,
            roomId: action.payload.roomId,
            data: action.payload.messages
          }
        }
      }
    }
    case Actions.TOGGLE_BROADCAST_MODE: {
      return {
        ...state,
        broadcast: {
          ...initialState.broadcast,
          isModeOn: action.payload
        }
      }
    }
    case Actions.TOGGLE_BROADCAST_PERSONAL_MESSAGE: {
      return {
        ...state,
        broadcast: {
          ...state.broadcast,
          isPersonalMessage: action.payload
        }
      }
    }
    case Actions.UNSELECT_PATIENT_FOR_BROADCAST: {
      if (state.broadcast.allSelected) {
        return {
          ...state,
          broadcast: {
            ...state.broadcast,
            excluded: [...state.broadcast.excluded, action.payload],
            localMessages: initialState.broadcast.localMessages
          }
        }
      } else {
        return {
          ...state,
          broadcast: {
            ...state.broadcast,
            selectedPatients: removeWhere(state.broadcast.selectedPatients, id => id === action.payload),
            localMessages: initialState.broadcast.localMessages
          }
        }
      }
    }
    case Actions.SELECT_PATIENT_FOR_BROADCAST: {
      if (state.broadcast.allSelected) {
        return {
          ...state,
          broadcast: {
            ...state.broadcast,
            excluded: removeWhere(state.broadcast.excluded, id => id === action.payload),
            localMessages: initialState.broadcast.localMessages
          }
        }
      } else {
        return {
          ...state,
          broadcast: {
            ...state.broadcast,
            selectedPatients: [...state.broadcast.selectedPatients, action.payload],
            localMessages: initialState.broadcast.localMessages
          }
        }
      }
    }
    case Actions.TOGGLE_BROADCAST_SELECT_ALL: {
      if (action.payload) {
        return {
          ...state,
          broadcast: {
            ...state.broadcast,
            allSelected: true,
            selectedPatients: initialState.broadcast.selectedPatients,
            localMessages: initialState.broadcast.localMessages
          }
        }
      } else {
        return {
          ...state,
          broadcast: {
            ...state.broadcast,
            allSelected: false,
            excluded: initialState.broadcast.excluded,
            localMessages: initialState.broadcast.localMessages
          }
        }
      }
    }
    case Actions.TOGGLE_BROADCAST_MODAL: {
      return {
        ...state,
        broadcast: {
          ...state.broadcast,
          isConfirmationModalOpen: action.payload
        }
      }
    }
    case Actions.CREATE_BROADCAST_MESSAGE:
    case Actions.CREATE_BROADCAST_FILE_MESSAGE: {
      return {
        ...state,
        broadcast: {
          ...state.broadcast,
          isLoading: true
        }
      }
    }
    case Actions.CREATE_BROADCAST_MESSAGE_RECEIVED:
    case Actions.CREATE_BROADCAST_MESSAGE_FAILED:
    case Actions.CREATE_BROADCAST_FILE_MESSAGE_FAILED: {
      return {
        ...state,
        broadcast: {
          ...state.broadcast,
          isLoading: false,
          isConfirmationModalOpen: false
        }
      }
    }
    case Actions.RESET_BROADCAST_STATE: {
      return {
        ...state,
        broadcast: {
          ...initialState.broadcast,
          isModeOn: true
        }
      }
    }
    case Actions.CREATE_BROADCAST_LOCAL_MESSAGE: {
      return {
        ...state,
        broadcast: {
          ...state.broadcast,
          localMessages: upsertMessagesList(state.broadcast.localMessages, action.payload, false)
        }
      }
    }
    case Actions.DELETE_SCHEDULED_MESSAGE: {
      return {
        ...state,
        scheduledMessages: {
          ...state.scheduledMessages,
          items: updateWhere(
            state.scheduledMessages.items,
            scheduledMessage => scheduledMessage.id === action.payload.id,
            scheduledMessage => (scheduledMessage.isDeleting = true)
          )
        }
      }
    }
    case Actions.DELETE_SCHEDULED_MESSAGE_FAILED: {
      return {
        ...state,
        scheduledMessages: {
          ...state.scheduledMessages,
          items: updateWhere(
            state.scheduledMessages.items,
            scheduledMessage => scheduledMessage.id === action.payload.id,
            scheduledMessage => (scheduledMessage.isDeleting = false)
          )
        }
      }
    }
    case Actions.DELETE_SCHEDULED_MESSAGE_RECEIVED: {
      return {
        ...state,
        scheduledMessages: {
          ...state.scheduledMessages,
          items: removeWhere(
            state.scheduledMessages.items,
            scheduledMessage => scheduledMessage.id === action.payload.id
          )
        }
      }
    }
    case Actions.SEND_CHAT_MESSAGE: {
      return {
        ...state,
        scheduledMessages: {
          ...state.scheduledMessages,
          wasLastSentMessageScheduled: !!action.payload.scheduleTime
        }
      }
    }
    case Actions.MARK_ROOM_READ: {
      return {
        ...state,
        rooms: updateWhere(
          state.rooms,
          room => room.roomId === action.payload.roomId,
          room => (room.isChatRoomResolved = true)
        )
      }
    }
    case Actions.MARK_ROOM_READ_FAILED: {
      return {
        ...state,
        rooms: updateWhere(
          state.rooms,
          room => room.roomId === action.payload.roomId,
          room => (room.isChatRoomResolved = false)
        )
      }
    }
    case Actions.RESOLVE_SCAN_URGENCY:
      const { messageId } = action.payload

      return {
        ...state,
        messages: {
          ...state.messages,
          items: updateWhere(
            state.messages.items,
            message => message.id === messageId,
            message => (message.metadata = JSON.stringify({ ...JSON.parse(message.metadata || '{}'), isUrgent: false }))
          )
        }
      }
    case Actions.SOFT_DELETE_CHAT_MESSAGE:
    case Actions.SOFT_DELETE_CHAT_MESSAGE_RECEIVED:
      return {
        ...state,
        messages: {
          ...state.messages,
          items: updateWhere(
            state.messages.items,
            msg => msg.id === action.payload.messageId,
            msg => (msg.isDeleted = true)
          )
        }
      }
    case Actions.SOFT_DELETE_CHAT_MESSAGE_FAILED:
      return {
        ...state,
        messages: {
          ...state.messages,
          items: updateWhere(
            state.messages.items,
            msg => msg.id === action.payload.messageId,
            msg => (msg.isDeleted = false)
          )
        }
      }
    case Actions.SUBMIT_SCAN_TRACKING_DATA_RECEIVED:
      return {
        ...state,
        messages: {
          ...state.messages,
          items: updateWhere(
            state.messages.items,
            msg => msg.entityRelatedId === action.payload.grinScan.id,
            msg => (msg.grinScan = { ...msg.grinScan, ...action.payload.grinScan })
          )
        }
      }

    default:
      return state
  }
}
