import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { ClickAwayListener, Grid } from '@material-ui/core'
import DazzedParagraph11 from 'components/common/text/DazzedParagraph11'
import useTeethTracking from './useTeethTracking'
import { trackEvent } from 'utils/analyticsUtils'
import Tooltip from 'components/common/Tooltip'
import ToothTooltip from './ToothTooltip/ToothTooltip'
import { TeethTrackingStatuses } from '../txTrackerConsts'

const progressStyle = {
  borderRadius: 10,
  width: '100%',
  backgroundColor: '#BDD3EE',
  height: 4
}

const useStyles = ({ backgroundColor, borderColor, progressPrecantage }) =>
  makeStyles(theme => ({
    status: {
      borderRadius: 7,
      border: ({ borderColor }) => `2px solid ${borderColor ? borderColor : 'var(--border-color-38)'}`,
      backgroundColor: ({ backgroundColor }) => backgroundColor || 'transparent',
      [theme.breakpoints.down('md')]: {
        width: 40,
        height: 28
      },
      [theme.breakpoints.up('md')]: {
        width: 48,
        height: 32
      },
      cursor: 'pointer'
    },
    toothLabel: {
      color: 'var(--text-color-61)'
    },
    progressContainer: {
      position: 'relative',
      marginTop: 2,
      zIndex: 1,
      ...progressStyle
    },
    progress: {
      ...progressStyle,
      position: 'absolute',
      width: ({ progressPrecantage }) => `${progressPrecantage}%`,
      top: 0,
      left: 0,
      zIndex: 2,
      backgroundColor: '#6F8BC7'
    },
    tooltip: {
      maxWidth: '1000px !important'
    }
  }))({ borderColor, backgroundColor, progressPrecantage })

const ToothGridItem = ({ toothTitle, toothNumber, toothData, shouldDisplayTotalProgress }) => {
  const { getTeethTrackingStatus, trackingStatuses, getToothMovementPrecantage } = useTeethTracking()

  const [showTooltip, setShowTooltip] = useState(false)

  const teethTrackingStatus = useMemo(() => getTeethTrackingStatus(toothData), [getTeethTrackingStatus, toothData])

  const currentTeethTrackingStatus = useMemo(
    () => trackingStatuses[teethTrackingStatus],
    [teethTrackingStatus, trackingStatuses]
  )
  const handleClick = useCallback(() => {
    if (
      teethTrackingStatus === TeethTrackingStatuses.MissingTooth ||
      teethTrackingStatus === TeethTrackingStatuses.Untracked
    ) {
      return
    }

    trackEvent('Treatment tracker modal - Teeth tracking - Tooth clicked', {
      toothNumber
    })
    setShowTooltip(true)
  }, [teethTrackingStatus, toothNumber])

  const toothMovementPrecentage = useMemo(() => {
    if (
      teethTrackingStatus === TeethTrackingStatuses.MissingTooth ||
      teethTrackingStatus === TeethTrackingStatuses.Untracked
    ) {
      return null
    }

    const { currentProgress, totalPlannedMovement } = toothData

    return getToothMovementPrecantage({ currentProgress, totalPlannedMovement })
  }, [getToothMovementPrecantage, teethTrackingStatus, toothData])

  const classes = useStyles({
    borderColor: currentTeethTrackingStatus?.borderColor,
    backgroundColor: currentTeethTrackingStatus?.backgroundColor,
    progressPrecantage: toothMovementPrecentage
  })

  return (
    <Tooltip
      variant="light"
      arrow
      placement="top"
      open={showTooltip}
      value={
        <ToothTooltip
          toothData={toothData}
          toothNumber={toothNumber}
          shouldDisplayTotalProgress={shouldDisplayTotalProgress}
        />
      }
      tooltipContentClassName={classes.tooltip}
    >
      <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <DazzedParagraph11 thickness="bold" className={classes.toothLabel}>
              {toothTitle}
            </DazzedParagraph11>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={classes.status}
              onClick={handleClick}
            >
              <Grid item>{currentTeethTrackingStatus?.icon}</Grid>
            </Grid>
          </Grid>
          {shouldDisplayTotalProgress && toothMovementPrecentage && (
            <div className={classes.progressContainer}>
              <div className={classes.progress} />
            </div>
          )}
        </Grid>
      </ClickAwayListener>
    </Tooltip>
  )
}

export default ToothGridItem
