import * as React from 'react'

const Gender = ({ style }) => (
  <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <circle cx="5.03901" cy="4.81208" r="4.03901" stroke="#1F1F1F" strokeWidth="0.85" strokeLinejoin="round" />
    <circle cx="13.9609" cy="10.961" r="4.03901" stroke="#1F1F1F" strokeWidth="0.85" strokeLinejoin="round" />
    <path
      d="M4.67437 16.2057C4.67437 16.4404 4.86464 16.6307 5.09937 16.6307C5.33409 16.6307 5.52437 16.4404 5.52437 16.2057H4.67437ZM4.67437 8.85107V16.2057H5.52437V8.85107H4.67437Z"
      fill="#1F1F1F"
    />
    <path
      d="M2.68799 13.6738H7.39012"
      stroke="#1F1F1F"
      strokeWidth="0.85"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9611 0.592224L14.2616 0.291704C14.0956 0.125731 13.8265 0.125731 13.6605 0.291704L13.9611 0.592224ZM16.3733 3.60551C16.5393 3.77148 16.8084 3.77148 16.9743 3.60551C17.1403 3.43954 17.1403 3.17044 16.9743 3.00447L16.3733 3.60551ZM10.9478 3.00447C10.7818 3.17044 10.7818 3.43954 10.9478 3.60551C11.1137 3.77148 11.3828 3.77148 11.5488 3.60551L10.9478 3.00447ZM14.3861 6.92201V0.592224H13.5361V6.92201H14.3861ZM13.6605 0.892745L16.3733 3.60551L16.9743 3.00447L14.2616 0.291704L13.6605 0.892745ZM13.6605 0.291704L10.9478 3.00447L11.5488 3.60551L14.2616 0.892745L13.6605 0.291704Z"
      fill="#1F1F1F"
    />
  </svg>
)

export default Gender
