import * as React from 'react'

const MultipleSiblingLink = props => (
  <svg width={21} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1 8.686s.854-1.708 5.124-1.708 5.124 1.708 5.124 1.708v3.416H1V8.686Z"
      stroke="#041333"
      strokeWidth={0.854}
    />
    <circle cx={6.124} cy={3.562} r={2.562} stroke="#041333" strokeWidth={0.854} />
    <path d="M20 8V7h-9a3 3 0 0 0-3 3v3h12V8Z" fill="#fff" stroke="#fff" strokeWidth={2} />
    <path
      d="M9 8.686s.854-1.708 5.124-1.708 5.124 1.708 5.124 1.708v3.416H9V8.686Z"
      stroke="#041333"
      strokeWidth={0.854}
    />
    <circle cx={14.124} cy={3.562} r={2.562} stroke="#041333" strokeWidth={0.854} />
  </svg>
)

export default MultipleSiblingLink
