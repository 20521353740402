import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import HourGlass from '../../../common/icons/HourGlass'
import Tooltip from '../../../common/Tooltip'

const DelayedScanOverlay = ({ date }) => {
  const classes = makeStyles({
    container: {
      display: 'flex',
      justifyContent: 'flex-start',
      height: 44
    },
    hourglass: {
      width: '11px',
      height: '14px',
      marginLeft: '3px',
      marginTop: '-14px'
    },
    bubble: {
      backgroundColor: 'var(--bg-color-12)',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 163,
      height: '100%',
      lineHeight: '18px'
    },
    text: {
      fontFamily: 'Dazzed',
      fontSize: 12,
      color: 'white',
      fontWeight: 500
    }
  })()

  const { t } = useTranslation()

  return (
    <Grid container direction="row" className={classes.container}>
      <Grid item className={classes.hourglass}>
        <Tooltip
          arrow
          value={
            <span style={{ display: 'block' }}>
              {t('pages.patients.selectedPatient.timeline.scanCreatedOn')}
              <br />
              {date}
            </span>
          }
          variant="dark"
        >
          <HourGlass />
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default DelayedScanOverlay
