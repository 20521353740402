import * as React from 'react'

function SvgUp(props) {
  return (
    <svg width={16} height={16} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.653 7.89a.522.522 0 000 .719.48.48 0 00.694 0l6.148-6.366V15.5a.5.5 0 101 0V2.232l6.158 6.377a.48.48 0 00.694 0 .522.522 0 000-.72l-7-7.249L8 .28l-.347.36-7 7.25z"
        fill="#3C52EF"
      />
    </svg>
  )
}

export default SvgUp
