import { GuidelineCategories } from 'consts/hiToolsConsts'
import AlignersTxTrackingCategory from './AlignersTxTrackingCategory'
import BracesTxTrackingCategory from './BracesTxTrackingCategory'
import ElasticsCategory from './ElasticsCategory'
import TxGoalsCategory from './TxGoalsCategory'
import IPRCategory from './IPRCategory'
import RPECategory from './RPECategory'

export default {
  [GuidelineCategories.AlignersTxTracking]: AlignersTxTrackingCategory,
  [GuidelineCategories.BracesTxTracking]: BracesTxTrackingCategory,
  [GuidelineCategories.Elastics]: ElasticsCategory,
  [GuidelineCategories.TxGoals]: TxGoalsCategory,
  [GuidelineCategories.IPR]: IPRCategory,
  [GuidelineCategories.RPE]: RPECategory
}
