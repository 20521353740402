import * as React from 'react'

function SvgSwooshTiny(props) {
  return (
    <svg width={32} height={24} fill="none" {...props}>
      <path
        d="M1 23a31.34 31.34 0 0018.722-6.074C25.35 12.799 29.158 7.156 31 1"
        stroke="#FFBD39"
        strokeMiterlimit={10}
      />
    </svg>
  )
}

export default SvgSwooshTiny
