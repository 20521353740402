import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles, Grid, Tooltip, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import S3FileUploader from '../../../common/buttons/S3FileUploader'
import { Plus as PlusIcon, Download as DownloadIcon, Trash as TrashIcon } from '../../../common/icons'
import { withS3Details } from 'utils/storageUtils'

const useStyles = makeStyles(theme => ({
  actionButton: {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.disabled': {
      cursor: 'auto'
    }
  },
  tooltipPopper: {
    opacity: 1
  },
  tooltipPopperArrow: {
    color: theme.palette.primary.main
  },
  tooltip: {
    opacity: 1,
    backgroundColor: theme.palette.primary.main
  },
  download: {
    fill: ({ activeDownload, isLoading }) => (activeDownload && !isLoading ? 'var(--bg-color-7)' : 'var(--bg-color-22)')
  }
}))

const RecordsButtons = ({
  handleUpload,
  handleDelete,
  onStartUploading,
  handleDownloadZip,
  isDisabled,
  activeDownload,
  onPlusClick,
  isDeleteEnabled = false,
  supportedFileTypes
}) => {
  const { t } = useTranslation()
  const { isLoading } = useSelector(state => state.patientsReducer.downloadPatientFiles)

  const classes = useStyles({ activeDownload, isLoading })
  const [isFilesDownloading, setIsFilesDownloading] = useState(false)

  const handleDownload = useCallback(
    event => {
      if (!isFilesDownloading) {
        setIsFilesDownloading(true)
        handleDownloadZip(event)
      }
    },
    [isFilesDownloading, handleDownloadZip]
  )

  const onUploadDone = useCallback(keys => handleUpload(keys.map(withS3Details)), [handleUpload])

  useEffect(() => {
    isFilesDownloading && !isLoading && setIsFilesDownloading(false)
  }, [isFilesDownloading, isLoading])

  return (
    <Grid container spacing={2} justifyContent="center">
      {isDeleteEnabled ? (
        <Grid item>
          <Tooltip
            title={t('dialogs.patientInfo.records.tooltips.delete')}
            arrow
            placement="top"
            classes={{
              popper: classes.tooltipPopper,
              tooltip: classes.tooltip,
              arrow: classes.tooltipPopperArrow
            }}
          >
            <div className={classes.actionButton} onClick={handleDelete}>
              <TrashIcon color="var(--bg-color-7)" />
            </div>
          </Tooltip>
        </Grid>
      ) : (
        <Grid item>
          {onPlusClick ? (
            <div className={classes.actionButton} onClick={onPlusClick}>
              <PlusIcon />
            </div>
          ) : (
            <S3FileUploader
              isDisabled={isDisabled}
              onStartUploading={onStartUploading}
              onUploadDone={onUploadDone}
              supportedFileTypes={supportedFileTypes}
              multiple={true}
              callForActionComponent={
                <Tooltip
                  title={t('dialogs.patientInfo.records.tooltips.upload')}
                  arrow
                  placement="top"
                  classes={{
                    popper: classes.tooltipPopper,
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipPopperArrow
                  }}
                >
                  <div className={classes.actionButton}>
                    <PlusIcon />
                  </div>
                </Tooltip>
              }
            />
          )}
        </Grid>
      )}
      <Grid item>
        <Tooltip
          title={t('dialogs.patientInfo.records.tooltips.download')}
          arrow
          placement="top"
          classes={{
            popper: classes.tooltipPopper,
            tooltip: classes.tooltip,
            arrow: classes.tooltipPopperArrow
          }}
        >
          <div className={classes.actionButton} onClick={handleDownload}>
            {isFilesDownloading ? <CircularProgress size={16} /> : <DownloadIcon className={classes.download} />}
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default RecordsButtons
