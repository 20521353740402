import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements } from '@stripe/react-stripe-js'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PrimaryButton from '../../buttons/PrimaryButton'
import SecondaryButton from '../../buttons/SecondaryButton'
import FormInput from '../../FormInput'
import CalendarIcon from '../../icons/Calendar'
import LockIcon from '../../icons/Lock'
import ErrorMessage from '../../text/ErrorMessage'
import BillingAddressPicker from './BillingAddressPicker'
import CreditCardTermsOfSale from './CreditCardTermsOfSale'
import PoweredByStripe from './PoweredByStripe'
import StripeInputWrapper from './StripeInputWrapper'

const useStyles = makeStyles(() => ({
  smallHorizontalSpacer: {
    width: '15px'
  },
  verticalSeparator: {
    height: '20px'
  },
  actionButtons: {
    display: 'flex'
  },
  shippingAddressContainer: {
    marginTop: 30
  },
  errorMessage: {
    opacity: 0,
    color: ({ bright }) => (bright ? 'red' : 'var(--text-color-10)'),
    marginTop: '2px',
    marginBottom: '5px',
    height: '17px',
    fontSize: '12px',
    transition: 'opacity .15s ease',
    '&.active': {
      opacity: 1
    }
  },
  cardholderName: {
    '& input': {
      height: '30px !important'
    }
  }
}))

const CreditCardForm = ({
  onSubmit,
  submitLabel,
  hideSubmit = false,
  submitButtonWidth,
  secondaryLabel,
  onSecondaryClicked,
  buttonsAlign = 'center',
  showAgreement,
  agreedTerms,
  setAgreedTerms,
  showPoweredByLabel = true,
  isLoading,
  handleAddressChanged,
  shippingAddress,
  setAddress
}) => {
  const classes = useStyles()
  const elements = useElements()
  const { t } = useTranslation()

  const practiceDetails = useSelector(state => state.practiceReducer.details)

  const [errors, setErrors] = useState({})
  const [cardHolderName, setCardHolderName] = useState('')
  const [completedFields, setCompletedFields] = useState({})
  const [billingAddress, setBillingAddress] = useState({})
  const [dirty, setDirty] = useState(false)

  const hasErrors = useMemo(() => isEmpty(errors), [errors])
  const isCardCompleted = useMemo(
    () => completedFields.cardNumber && completedFields.cardExpiry && completedFields.cardCvc && !!cardHolderName,
    [cardHolderName, completedFields.cardCvc, completedFields.cardExpiry, completedFields.cardNumber]
  )

  const handleCardChange = useCallback(cardData => {
    setCompletedFields(completed => ({
      ...completed,
      [cardData.elementType]: cardData.complete
    }))

    setErrors(errors => ({
      ...errors,
      [cardData.elementType]: cardData.error?.message
    }))
  }, [])

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault()
      setDirty(true)
      if (!elements || hasErrors || !isCardCompleted || !cardHolderName) {
        return
      }
      onSubmit({ element: elements.getElement(CardNumberElement), cardHolderName, billingAddress })
    },
    [elements, hasErrors, isCardCompleted, onSubmit, cardHolderName, billingAddress]
  )

  useEffect(() => {
    setBillingAddress(practiceDetails)
  }, [practiceDetails])

  return (
    <form onSubmit={handleSubmit} id="credit-card-form">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StripeInputWrapper
            label={t('creditCard.cardNumberLabel')}
            stripeElement={CardNumberElement}
            withBrandIcon
            onChange={handleCardChange}
            error={errors.cardNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            title={t('creditCard.cardholderNameLabel')}
            value={cardHolderName}
            setValue={setCardHolderName}
            style={{ bright: true, thick: true }}
            errorMessage={errors.cardHolderName}
            containerClassName={classes.cardholderName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StripeInputWrapper
            label={t('creditCard.monthYearLabel')}
            icon={<CalendarIcon color="#727b8c" />}
            stripeElement={CardExpiryElement}
            onChange={handleCardChange}
            error={errors.cardExpiry}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StripeInputWrapper
            label={t('creditCard.cvcLabel')}
            icon={<LockIcon />}
            stripeElement={CardCvcElement}
            onChange={handleCardChange}
            error={errors.cardCvc}
          />
        </Grid>
        <Grid item xs={12}>
          {dirty && isEmpty(errors) && !isCardCompleted && (
            <ErrorMessage center active text={t('creditCard.fillCreditCardDetails')} size="medium" />
          )}
        </Grid>
      </Grid>

      <div className={classes.verticalSeparator} />
      <BillingAddressPicker
        address={billingAddress}
        setAddress={address => {
          setBillingAddress(address)
          handleAddressChanged(address)
        }}
      />
      <div className={classes.verticalSeparator} />
      {showPoweredByLabel && <PoweredByStripe />}
      {showAgreement && <CreditCardTermsOfSale checked={agreedTerms} onChange={setAgreedTerms} />}
      {shippingAddress && (
        <div className={classes.shippingAddressContainer}>
          <BillingAddressPicker
            label={t('dialogs.orderScopes.shippingAddress')}
            address={shippingAddress}
            setAddress={setAddress}
          />
        </div>
      )}
      <div className={classes.verticalSeparator} />
      <div className={classes.actionButtons} style={{ justifyContent: buttonsAlign }}>
        {!!secondaryLabel && (
          <>
            <SecondaryButton label={secondaryLabel} onClick={onSecondaryClicked} small />
            <div className={classes.smallHorizontalSpacer} />
          </>
        )}
        {!hideSubmit && (
          <PrimaryButton
            label={submitLabel == null ? t('general.save') : submitLabel}
            width={submitButtonWidth}
            small={!submitButtonWidth}
            type="submit"
            disabled={isLoading || !isCardCompleted || (showAgreement && !agreedTerms)}
            isLoading={isLoading}
          />
        )}
      </div>
      <div className={classes.verticalSeparator} />
    </form>
  )
}

export default CreditCardForm
