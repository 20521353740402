import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import BaseModal from 'components/common/modals/BaseModal'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import AutomationRuleConditionsEditor from './AutomationRuleConditionsEditor/AutomationRuleConditionsEditor'
import RuleEditor from './RuleEditor'
import AutomationRulesActionEditor from './AutomationRulesActionEditor/AutomationRulesActionEditor'
import { SystemAutomationsDoctorId, TriggerConfigs } from 'consts/automatedActionConsts'
import { trackEvent } from 'utils/analyticsUtils'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import ErrorMessage from 'components/common/text/ErrorMessage'
import useAutomationRulesValidations from './useAutomationRulesValidations'

const MAX_NAME_LENGTH = 60

const useStyles = makeStyles({
  modal: {
    padding: '0px 40px 0px 40px !important',
    whiteSpace: 'pre',
    height: ({ withMultipleConditions }) => (withMultipleConditions ? 710 : 650),
    width: 1245
  },
  title: {
    fontFamily: 'Dazzed',
    fontSize: 24,
    fontWeight: 600,
    textAlign: 'left !important',
    alignItems: 'baseline !important',
    paddingBottom: '0px !important',
    paddingLeft: '12px !important'
  },
  nameInput: {
    marginTop: 20,
    height: 32,
    width: 480,
    outline: 'none',
    border: 'none',
    fontSize: 16,
    paddingLeft: 14,
    borderBottom: '1px solid var(--border-color-11)'
  },
  nameInputError: {
    borderBottom: '1px solid red'
  },
  triggerTypeLabel: {
    color: 'var(--text-color-11)'
  },
  content: {
    width: '100%',
    marginTop: 35
  },
  triggerTypeDropdown: {
    maxHeight: '350px !important'
  },
  nameCharacterCount: {
    color: 'var(--text-color-55)'
  }
})

const AutomationRuleEditorModal = ({ isSystem = false }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles({ withMultipleConditions: isSystem })

  const { isOpen, isSaving, ruleId } = useSelector(state => state.practiceReducer.automationRules.editorModal)
  const { doctor } = useSelector(state => state.profileReducer)
  const selectedRule = useSelector(state => state.practiceReducer.automationRules.data || []).find(
    rule => rule.id === ruleId
  )

  const selectedRuleConditions = useMemo(() => JSON.parse(selectedRule?.conditions || '[]'), [selectedRule])
  const selectedRuleActions = useMemo(() => JSON.parse(selectedRule?.actions || '[]'), [selectedRule])

  const [name, setName] = useState(selectedRule?.name || '')
  const [triggerType, setTriggerType] = useState(selectedRule?.triggerType || '')
  const [conditions, setConditions] = useState(selectedRuleConditions || [])
  const [actions, setActions] = useState(selectedRuleActions || [])
  const [actionType, setActionType] = useState(selectedRuleActions?.[0]?.type || '')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const { hasErrors, errors, noErrors } = useAutomationRulesValidations({
    name,
    triggerType,
    actionType,
    actions,
    conditions
  })

  const triggerConfig = useMemo(() => TriggerConfigs[triggerType] || {}, [triggerType])

  const shouldEnableConfirmButton = useMemo(
    () => (triggerConfig.isRequired ? conditions.length > 0 : true),
    [conditions, triggerConfig]
  )
  const nameCharacterCount = useMemo(() => name?.length || 0, [name])
  const editorErrors = useMemo(() => (isSubmitted ? errors : noErrors), [errors, isSubmitted, noErrors])

  const handleConfirm = useCallback(() => {
    setIsSubmitted(true)

    if (hasErrors) {
      return
    }

    const filteredConditions = conditions.filter(condition => !!condition.type)

    const analyticsPayload = {
      name,
      triggerType,
      conditions: JSON.stringify(filteredConditions),
      actionType
    }
    const mutationPayload = {
      name,
      triggerType,
      conditions: JSON.stringify(filteredConditions),
      actions: JSON.stringify(actions)
    }

    if (selectedRule) {
      trackEvent('Automation Rules - Update automation rule', analyticsPayload)
      dispatch(
        Actions.updateAutomationRule({
          ...mutationPayload,
          id: selectedRule.id
        })
      )
    } else {
      trackEvent('Automation Rules - Create new automation rule', analyticsPayload)
      dispatch(
        Actions.createAutomationRule({
          ...mutationPayload,
          isSystem,
          doctorId: isSystem ? SystemAutomationsDoctorId : doctor?.accountOwnerId || doctor.id
        })
      )
    }
  }, [actionType, actions, conditions, dispatch, doctor, hasErrors, isSystem, name, selectedRule, triggerType])

  const handleClose = useCallback(() => {
    dispatch(
      Actions.toggleRuleEditorModal({
        isOpen: false
      })
    )
  }, [dispatch])

  const handleNameChange = useCallback(value => {
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value)
    }
  }, [])

  useEffect(() => {
    if (!isOpen) {
      setName('')
      setTriggerType('')
      setConditions([])
      setActionType('')
      setActions([])
      setIsSubmitted(false)
    }
  }, [isOpen, selectedRule])

  return (
    <BaseModal
      open={isOpen}
      withCloseIcon
      title={
        ruleId
          ? t('pages.accountSettings.automations.editorModal.editTitle')
          : t('pages.accountSettings.automations.editorModal.createTitle')
      }
      titleClassName={classes.title}
      onPrimaryBtnClick={handleConfirm}
      onSecondaryBtnClick={handleClose}
      primaryLabel={t('general.confirm')}
      isPrimaryDisabled={!shouldEnableConfirmButton}
      isLoading={isSaving}
      secondaryLabel={t('general.cancel')}
      handleClose={handleClose}
      className={classes.modal}
      maxWidth={'xl'}
    >
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <input
                value={name}
                placeholder={t('pages.accountSettings.automations.editorModal.namePlaceholder')}
                className={`${classes.nameInput} ${editorErrors.requiredFields?.name && classes.nameInputError}`}
                onChange={({ target }) => handleNameChange(target.value)}
              />
              <DazzedParagraph12 className={classes.nameCharacterCount}>
                {t('pages.accountSettings.automations.editorModal.nameCharactersCount', {
                  number: nameCharacterCount,
                  total: MAX_NAME_LENGTH
                })}
              </DazzedParagraph12>
              {editorErrors.requiredFields?.name && (
                <ErrorMessage active={editorErrors.requiredFields?.name} text={editorErrors.requiredFields?.name} />
              )}
            </Grid>
            <Grid item className={classes.content}>
              <RuleEditor
                triggerType={triggerType}
                setTriggerType={setTriggerType}
                actionType={actionType}
                setActionType={setActionType}
                setConditions={setConditions}
                errors={editorErrors}
              />
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item xs={5}>
                  {triggerType && (
                    <AutomationRuleConditionsEditor
                      triggerType={triggerType}
                      conditions={conditions}
                      setConditions={setConditions}
                      selectedRuleConditions={selectedRuleConditions}
                      withMultipleConditions={isSystem}
                      errors={editorErrors}
                    />
                  )}
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={5}>
                  <AutomationRulesActionEditor
                    actionType={actionType}
                    actions={actions}
                    setActions={setActions}
                    errors={editorErrors}
                    isTimeBased={triggerConfig.isTimeBased}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default AutomationRuleEditorModal
