import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import ROUTES from '../../consts/routesConsts'
import OggHeading40 from '../common/text/OggHeading40'
import BillingSubscriptionTab from './Billing/BillingSubscriptionTab'
import BillingPaymentHistoryTab from './Billing/BillingPaymentHistoryTab'
import NavigationLink from './NavigationLink'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() => ({
  root: {
    width: 750
  },
  heading: {
    marginBottom: 24
  },
  tabs: {
    display: 'flex'
  },
  tab: {
    marginRight: 24
  }
}))

const Billing = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const tabs = useMemo(
    () => [
      { label: t('pages.accountSettings.billing.subscriptionLabel'), route: ROUTES.BILLING_SUBSCRIPTION },
      { label: t('pages.accountSettings.billing.paymentHistoryLabel'), route: ROUTES.BILLING_PAYMENT_HISTORY }
    ],
    [t]
  )

  const userPlan = useSelector(state => state.practiceReducer.billing.grinPlan)
  const userPlanKey = userPlan?.key ?? ''

  return (
    <div className={classes.root}>
      <OggHeading40 textAlign="left" className={classes.heading}>
        {t('pages.accountSettings.billing.billing')}
      </OggHeading40>
      <div className={classes.tabs}>
        {tabs.map(({ label, route }) => (
          <NavigationLink
            onClick={() => trackEvent(`${label} tab - clicked`, { userPlanKey })}
            key={route}
            label={label}
            route={route}
            className={classes.tab}
          />
        ))}
      </div>

      <Switch>
        <Route exact path={ROUTES.BILLING_SUBSCRIPTION} component={BillingSubscriptionTab} />
        <Route exact path={ROUTES.BILLING_PAYMENT_HISTORY} component={BillingPaymentHistoryTab} />
        <Redirect to={ROUTES.BILLING_SUBSCRIPTION} />
      </Switch>
    </div>
  )
}

export default Billing
