import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Title from '../Title'
import ColorPicker from '../../../../../common/ColorPicker/ColorPicker'
import images from '../../../../../../resources'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 10,
    paddingBottom: 5,
    borderBottom: '1px solid var(--border-color-14)'
  },
  colorContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  imageContainer: {
    marginRight: 25,
    position: 'relative',
    zIndex: 1
  },
  image: {
    height: 120
  },
  spotContainer: {
    position: 'absolute',
    bottom: 10,
    left: -12,
    zIndex: -1
  },
  spot: {
    borderRadius: '50%',
    width: 60,
    height: 60
  }
}))

const AccentColor = ({
  selectedColor = '',
  suggestedColors = [],
  handleColor = () => {},
  handleOptions = () => {}
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid container justifyContent="space-between" className={classes.container}>
      <Grid className={classes.colorContainer}>
        <Grid>
          <Title
            label={t('pages.accountSettings.services.remoteConsultation.themeColors.SpotBubbleColorTitle')}
            optional
          />
        </Grid>
        <Grid>
          <ColorPicker
            selectedColor={selectedColor}
            suggestedColors={suggestedColors}
            handleColor={handleColor}
            handleOptions={handleOptions}
          />
        </Grid>
      </Grid>
      <Grid className={classes.imageContainer}>
        <img alt="Accent color preview" src={images.AccentColor} className={classes.image} />
        <Grid className={classes.spotContainer}>
          <div style={{ backgroundColor: selectedColor }} className={classes.spot}></div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AccentColor
