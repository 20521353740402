import { Grid, useMediaQuery } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AlignerMenu from './AlignerMenu'
import ScanDateLabel from 'components/Patients/Timeline/V2/ScanDateLabel'
import ScanStats from '../../ScanStats'

const ScanHeaderBodyXl = ({
  scanNumber,
  alignerNumber,
  numberOfReviews,
  numberOfShares,
  onReviewsClicked,
  onSharesClicked,
  scanDate,
  alignerMenuProps = {}
}) => {
  const xl = useMediaQuery(theme => theme.breakpoints.up('xl'))
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" justifyContent="space-between" spacing={xl ? 2 : 1}>
      <Grid item>
        <Grid container alignItems="center" spacing={xl ? 2 : 1} justifyContent="space-between">
          <Grid item>
            <DazzedParagraph14 bold>
              {t('pages.patients.selectedPatient.timelineV2.scanHeader.scanNumber', { scanNumber })}
            </DazzedParagraph14>
          </Grid>
          {alignerNumber && (
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <DazzedParagraph14 strong>
                    {t('pages.patients.selectedPatient.timelineV2.scanHeader.alignerNumber', { alignerNumber })}
                  </DazzedParagraph14>
                </Grid>
                <Grid item>
                  <AlignerMenu {...alignerMenuProps} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <ScanDateLabel timestamp={scanDate} />
      </Grid>
      <Grid item>
        <ScanStats
          numberOfReviews={numberOfReviews}
          numberOfShares={numberOfShares}
          onReviewsClicked={onReviewsClicked}
          onSharesClicked={onSharesClicked}
        />
      </Grid>
    </Grid>
  )
}

export default React.memo(ScanHeaderBodyXl)
