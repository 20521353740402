import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '1px',
    background: 'var(--bg-color-56)',
    position: 'relative'
  },
  button: {
    color: 'var(--text-color-48)',
    fontWeight: 500,
    cursor: 'pointer',
    border: '1px solid var(--border-color-7)',
    background: 'var(--bg-color-10)',
    borderRadius: '30px',
    fontSize: '11px',
    fontFamily: 'Dazzed',
    width: '52px',
    height: '20px',
    textAlign: 'center',
    position: 'absolute',
    transform: 'rotate(270deg) translateY(-50%) translateX(50%)',
    top: '50%',
    left: 'calc(50% - 15px)'
  }
}))

const AutochartTabsToggle = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isRightPanelVisible } = useSelector(state => state.autochartReducer)

  const handleToggle = useCallback(() => {
    const value = !isRightPanelVisible
    trackEvent(`Autochart - toggle right panel`, { value: value ? 'ON' : 'OFF' })
    dispatch(Actions.autochartToggleRightPanel({ value }))
  }, [dispatch, isRightPanelVisible])

  return (
    <div className={classes.root}>
      <div className={classes.button} onClick={handleToggle}>
        {t(`dialogs.autochart.tabsToggle.${isRightPanelVisible ? 'hide' : 'show'}`)}
      </div>
    </div>
  )
}

export default AutochartTabsToggle
