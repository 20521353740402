// Action Types
const CREATE_SERVICE_ACCOUNT_REQUESTED = 'CREATE_SERVICE_ACCOUNT_REQUESTED'
const CREATE_SERVICE_ACCOUNT_RECEIVED = 'CREATE_SERVICE_ACCOUNT_RECEIVED'
const CREATE_SERVICE_ACCOUNT_FAILED = 'CREATE_SERVICE_ACCOUNT_FAILED'
const FETCH_SERVICE_ACCOUNT_REQUESTED = 'FETCH_SERVICE_ACCOUNT_REQUESTED'
const FETCH_SERVICE_ACCOUNT_RECEIVED = 'FETCH_SERVICE_ACCOUNT_RECEIVED'
const FETCH_SERVICE_ACCOUNT_FAILED = 'FETCH_SERVICE_ACCOUNT_FAILED'
const SERVICE_ACCOUNT_SET_PASSWORD_REQUESTED = 'SERVICE_ACCOUNT_SET_PASSWORD_REQUESTED'
const SERVICE_ACCOUNT_SET_PASSWORD_RECEIVED = 'SERVICE_ACCOUNT_SET_PASSWORD_RECEIVED'
const SERVICE_ACCOUNT_SET_PASSWORD_FAILED = 'SERVICE_ACCOUNT_SET_PASSWORD_FAILED'
const DEACTIVATE_SERVICE_ACCOUNT_REQUESTED = 'DEACTIVATE_SERVICE_ACCOUNT_REQUESTED'
const DEACTIVATE_SERVICE_ACCOUNT_RECEIVED = 'DEACTIVATE_SERVICE_ACCOUNT_RECEIVED'
const DEACTIVATE_SERVICE_ACCOUNT_FAILED = 'DEACTIVATE_SERVICE_ACCOUNT_FAILED'
const ACTIVATE_SERVICE_ACCOUNT_REQUESTED = 'ACTIVATE_SERVICE_ACCOUNT_REQUESTED'
const ACTIVATE_SERVICE_ACCOUNT_RECEIVED = 'ACTIVATE_SERVICE_ACCOUNT_RECEIVED'
const ACTIVATE_SERVICE_ACCOUNT_FAILED = 'ACTIVATE_SERVICE_ACCOUNT_FAILED'
const FETCH_DOCTOR_AUTOMATION_RULES = 'FETCH_DOCTOR_AUTOMATION_RULES'
const FETCH_DOCTOR_AUTOMATION_RULES_RECEIVED = 'FETCH_DOCTOR_AUTOMATION_RULES_RECEIVED'
const FETCH_DOCTOR_AUTOMATION_RULES_FAILED = 'FETCH_DOCTOR_AUTOMATION_RULES_FAILED'
const TOGGLE_AUTOMATION_RULE = 'TOGGLE_AUTOMATION_RULE'
const TOGGLE_AUTOMATION_RULE_RECEIVED = 'TOGGLE_AUTOMATION_RULE_RECEIVED'
const TOGGLE_AUTOMATION_RULE_FAILED = 'TOGGLE_AUTOMATION_RULE_FAILED'
const DELETE_AUTOMATION_RULE = 'DELETE_AUTOMATION_RULE'
const DELETE_AUTOMATION_RULE_FAILED = 'DELETE_AUTOMATION_RULE_FAILED'
const DELETE_AUTOMATION_RULE_RECEIVED = 'DELETE_AUTOMATION_RULE_RECEIVED'
const TOGGLE_RULE_EDITOR_MODAL = 'TOGGLE_RULE_EDITOR_MODAL'
const TOGGLE_DELETE_RULE_MODAL = 'TOGGLE_DELETE_RULE_MODAL'
const CREATE_AUTOMATION_RULE = 'CREATE_AUTOMATION_RULE'
const CREATE_AUTOMATION_RULE_RECEIVED = 'CREATE_AUTOMATION_RULE_RECEIVED'
const CREATE_AUTOMATION_RULE_FAILED = 'CREATE_AUTOMATION_RULE_FAILED'
const UPDATE_AUTOMATION_RULE = 'UPDATE_AUTOMATION_RULE'
const UPDATE_AUTOMATION_RULE_RECEIVED = 'UPDATE_AUTOMATION_RULE_RECEIVED'
const UPDATE_AUTOMATION_RULE_FAILED = 'UPDATE_AUTOMATION_RULE_FAILED'
const TOGGLE_ENABLE_AUTOMATION_RULE_POPUP = 'TOGGLE_ENABLE_AUTOMATION_RULE_POPUP'
const ADD_SAVED_FILE = 'ADD_SAVED_FILE'
const ADD_SAVED_FILE_RECEIVED = 'ADD_SAVED_FILE_RECEIVED'
const ADD_SAVED_FILE_FAILED = 'ADD_SAVED_FILE_FAILED'
const FETCH_SAVED_FILES = 'FETCH_SAVED_FILES'
const FETCH_SAVED_FILES_RECEIVED = 'FETCH_SAVED_FILES_RECEIVED'
const FETCH_SAVED_FILES_FAILED = 'FETCH_SAVED_FILES_FAILED'
const DELETE_SAVED_FILE = 'DELETE_SAVED_FILE'
const DELETE_SAVED_FILE_RECEIVED = 'DELETE_SAVED_FILE_RECEIVED'
const DELETE_SAVED_FILE_FAILED = 'DELETE_SAVED_FILE_FAILED'
const SEND_SAVED_FILE_FAILED = 'SEND_SAVED_FILE_FAILED'
const TOGGLE_SAVED_FILE_LOADING = 'TOGGLE_SAVED_FILE_LOADING'
const FETCH_PRACTICE_GUIDELINES = 'FETCH_PRACTICE_GUIDELINES'
const FETCH_PRACTICE_GUIDELINES_RECEIVED = 'FETCH_PRACTICE_GUIDELINES_RECEIVED'
const FETCH_PRACTICE_GUIDELINES_FAILED = 'FETCH_PRACTICE_GUIDELINES_FAILED'
const SAVE_PRACTICE_GUIDELINES = 'SAVE_PRACTICE_GUIDELINES'
const SAVE_PRACTICE_GUIDELINES_RECEIVED = 'SAVE_PRACTICE_GUIDELINES_RECEIVED'
const SAVE_PRACTICE_GUIDELINES_FAILED = 'SAVE_PRACTICE_GUIDELINES_FAILED'
const RESOLVE_HI_NEEDS_ATTENTION = 'RESOLVE_HI_NEEDS_ATTENTION'
const RESOLVE_HI_NEEDS_ATTENTION_RECEIVED = 'RESOLVE_HI_NEEDS_ATTENTION_RECEIVED'
const RESOLVE_HI_NEEDS_ATTENTION_FAILED = 'RESOLVE_HI_NEEDS_ATTENTION_FAILED'
const FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS = 'FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS'
const FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS_RECEIVED = 'FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS_RECEIVED'
const FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS_FAILED = 'FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS_FAILED'
const FETCH_SAVED_VIEWS = 'FETCH_SAVED_VIEWS'
const FETCH_SAVED_VIEWS_RECEIVED = 'FETCH_SAVED_VIEWS_RECEIVED'
const FETCH_SAVED_VIEWS_FAILED = 'FETCH_SAVED_VIEWS_FAILED'
const CREATE_NEW_SAVED_VIEW = 'CREATE_NEW_SAVED_VIEW'
const CREATE_NEW_SAVED_VIEW_RECEIVED = 'CREATE_NEW_SAVED_VIEW_RECEIVED'
const CREATE_NEW_SAVED_VIEW_FAILED = 'CREATE_NEW_SAVED_VIEW_FAILED'
const DELETE_SAVED_VIEW = 'DELETE_SAVED_VIEW'
const DELETE_SAVED_VIEW_RECEIVED = 'DELETE_SAVED_VIEW_RECEIVED'
const DELETE_SAVED_VIEW_FAILED = 'DELETE_SAVED_VIEW_FAILED'
const TOGGLE_NEW_SAVED_VIEW_MODAL = 'TOGGLE_NEW_SAVED_VIEW_MODAL'

const toggleNewSavedViewModal = payload => ({
  type: TOGGLE_NEW_SAVED_VIEW_MODAL,
  payload
})

const deleteSavedView = payload => ({
  type: DELETE_SAVED_VIEW,
  payload
})

const deleteSavedViewReceived = payload => ({
  type: DELETE_SAVED_VIEW_RECEIVED,
  payload
})

const deleteSavedViewFailed = payload => ({
  type: DELETE_SAVED_VIEW_FAILED,
  payload
})

const createNewSavedView = payload => ({
  type: CREATE_NEW_SAVED_VIEW,
  payload
})

const createNewSavedViewReceived = payload => ({
  type: CREATE_NEW_SAVED_VIEW_RECEIVED,
  payload
})

const createNewSavedViewFailed = payload => ({
  type: CREATE_NEW_SAVED_VIEW_FAILED,
  payload
})

const fetchSavedViews = payload => ({
  type: FETCH_SAVED_VIEWS,
  payload
})

const fetchSavedViewsReceived = payload => ({
  type: FETCH_SAVED_VIEWS_RECEIVED,
  payload
})

const fetchSavedViewsFailed = payload => ({
  type: FETCH_SAVED_VIEWS_FAILED,
  payload
})

const fetchAggregatedPracticeCustomTags = payload => ({
  type: FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS,
  payload
})

const fetchAggregatedPracticeCustomTagsReceived = payload => ({
  type: FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS_RECEIVED,
  payload
})

const fetchAggregatedPracticeCustomTagsFailed = payload => ({
  type: FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS_FAILED,
  payload
})

const savePracticeGuidelines = payload => ({
  type: SAVE_PRACTICE_GUIDELINES,
  payload
})

const savePracticeGuidelinesReceived = payload => ({
  type: SAVE_PRACTICE_GUIDELINES_RECEIVED,
  payload
})

const savePracticeGuidelinesFailed = payload => ({
  type: SAVE_PRACTICE_GUIDELINES_FAILED,
  payload
})

const fetchPracticeGuidelines = payload => ({
  type: FETCH_PRACTICE_GUIDELINES,
  payload
})

const fetchPracticeGuidelinesReceived = payload => ({
  type: FETCH_PRACTICE_GUIDELINES_RECEIVED,
  payload
})

const fetchPracticeGuidelinesFailed = payload => ({
  type: FETCH_PRACTICE_GUIDELINES_FAILED,
  payload
})

const deleteSavedFile = payload => ({
  type: DELETE_SAVED_FILE,
  payload
})

const deleteSavedFileReceived = payload => ({
  type: DELETE_SAVED_FILE_RECEIVED,
  payload
})

const deleteSavedFileFailed = payload => ({
  type: DELETE_SAVED_FILE_FAILED,
  payload
})

const fetchSavedFiles = payload => ({
  type: FETCH_SAVED_FILES,
  payload
})

const fetchSavedFilesReceived = payload => ({
  type: FETCH_SAVED_FILES_RECEIVED,
  payload
})

const fetchSavedFilesFailed = payload => ({
  type: FETCH_SAVED_FILES_FAILED,
  payload
})

const addSavedFile = payload => ({
  type: ADD_SAVED_FILE,
  payload
})

const addSavedFileReceived = payload => ({
  type: ADD_SAVED_FILE_RECEIVED,
  payload
})

const addSavedFileFailed = payload => ({
  type: ADD_SAVED_FILE_FAILED,
  payload
})

const updateAutomationRule = payload => ({
  type: UPDATE_AUTOMATION_RULE,
  payload
})

const updateAutomationRuleReceived = payload => ({
  type: UPDATE_AUTOMATION_RULE_RECEIVED,
  payload
})

const updateAutomationRuleFailed = payload => ({
  type: UPDATE_AUTOMATION_RULE_FAILED,
  payload
})

const createAutomationRule = payload => ({
  type: CREATE_AUTOMATION_RULE,
  payload
})

const createAutomationRuleReceived = payload => ({
  type: CREATE_AUTOMATION_RULE_RECEIVED,
  payload
})

const createAutomationRuleFailed = payload => ({
  type: CREATE_AUTOMATION_RULE_FAILED,
  payload
})

const toggleDeleteRuleModal = payload => ({
  type: TOGGLE_DELETE_RULE_MODAL,
  payload
})

const toggleRuleEditorModal = payload => ({
  type: TOGGLE_RULE_EDITOR_MODAL,
  payload
})

const deleteAutomationRule = payload => ({
  type: DELETE_AUTOMATION_RULE,
  payload
})

const deleteAutomationRuleReceived = payload => ({
  type: DELETE_AUTOMATION_RULE_RECEIVED,
  payload
})

const deleteAutomationRuleFailed = payload => ({
  type: DELETE_AUTOMATION_RULE_FAILED,
  payload
})

const toggleAutomationRule = payload => ({
  type: TOGGLE_AUTOMATION_RULE,
  payload
})

const toggleAutomationRuleReceived = payload => ({
  type: TOGGLE_AUTOMATION_RULE_RECEIVED,
  payload
})

const toggleAutomationRuleFailed = payload => ({
  type: TOGGLE_AUTOMATION_RULE_FAILED,
  payload
})

const fetchDoctorAutomationRules = payload => ({
  type: FETCH_DOCTOR_AUTOMATION_RULES,
  payload
})

const fetchDoctorAutomationRulesReceived = payload => ({
  type: FETCH_DOCTOR_AUTOMATION_RULES_RECEIVED,
  payload
})

const fetchDoctorAutomationRulesFailed = payload => ({
  type: FETCH_DOCTOR_AUTOMATION_RULES_FAILED,
  payload
})

const createServiceAccount = payload => ({
  type: CREATE_SERVICE_ACCOUNT_REQUESTED,
  payload
})

const createServiceAccountReceived = payload => ({
  type: CREATE_SERVICE_ACCOUNT_RECEIVED,
  payload
})

const createServiceAccountFailed = payload => ({
  type: CREATE_SERVICE_ACCOUNT_FAILED,
  payload
})

const fetchServiceAccount = payload => ({
  type: FETCH_SERVICE_ACCOUNT_REQUESTED,
  payload
})

const fetchServiceAccountReceived = payload => ({
  type: FETCH_SERVICE_ACCOUNT_RECEIVED,
  payload
})

const fetchServiceAccountFailed = payload => ({
  type: FETCH_SERVICE_ACCOUNT_FAILED,
  payload
})

const serviceAccountSetPasword = payload => ({
  type: SERVICE_ACCOUNT_SET_PASSWORD_REQUESTED,
  payload
})

const serviceAccountSetPaswordReceived = payload => ({
  type: SERVICE_ACCOUNT_SET_PASSWORD_RECEIVED,
  payload
})

const serviceAccountSetPaswordFailed = payload => ({
  type: SERVICE_ACCOUNT_SET_PASSWORD_FAILED,
  payload
})

const deactivateServiceAccount = payload => ({
  type: DEACTIVATE_SERVICE_ACCOUNT_REQUESTED,
  payload
})

const deactivateServiceAccountReceived = payload => ({
  type: DEACTIVATE_SERVICE_ACCOUNT_RECEIVED,
  payload
})

const deactivateServiceAccountFailed = payload => ({
  type: DEACTIVATE_SERVICE_ACCOUNT_FAILED,
  payload
})

const activateServiceAccount = payload => ({
  type: ACTIVATE_SERVICE_ACCOUNT_REQUESTED,
  payload
})

const activateServiceAccountReceived = payload => ({
  type: ACTIVATE_SERVICE_ACCOUNT_RECEIVED,
  payload
})

const activateServiceAccountFailed = payload => ({
  type: ACTIVATE_SERVICE_ACCOUNT_FAILED,
  payload
})

const toggleEnableAutomationRulePopup = payload => ({
  type: TOGGLE_ENABLE_AUTOMATION_RULE_POPUP,
  payload
})

const sendSavedFileFailed = payload => ({
  type: SEND_SAVED_FILE_FAILED,
  payload
})

const toggleSavedFileLoading = payload => ({
  type: TOGGLE_SAVED_FILE_LOADING,
  payload
})

const resolveHiNeedsAttention = payload => ({
  type: RESOLVE_HI_NEEDS_ATTENTION,
  payload
})

const resolveHiNeedsAttentionReceived = payload => ({
  type: RESOLVE_HI_NEEDS_ATTENTION_RECEIVED,
  payload
})

const resolveHiNeedsAttentionFailed = payload => ({
  type: RESOLVE_HI_NEEDS_ATTENTION_FAILED,
  payload
})

export default {
  CREATE_SERVICE_ACCOUNT_REQUESTED,
  CREATE_SERVICE_ACCOUNT_RECEIVED,
  CREATE_SERVICE_ACCOUNT_FAILED,
  FETCH_SERVICE_ACCOUNT_REQUESTED,
  FETCH_SERVICE_ACCOUNT_RECEIVED,
  FETCH_SERVICE_ACCOUNT_FAILED,
  SERVICE_ACCOUNT_SET_PASSWORD_REQUESTED,
  SERVICE_ACCOUNT_SET_PASSWORD_RECEIVED,
  SERVICE_ACCOUNT_SET_PASSWORD_FAILED,
  DEACTIVATE_SERVICE_ACCOUNT_REQUESTED,
  DEACTIVATE_SERVICE_ACCOUNT_RECEIVED,
  DEACTIVATE_SERVICE_ACCOUNT_FAILED,
  ACTIVATE_SERVICE_ACCOUNT_REQUESTED,
  ACTIVATE_SERVICE_ACCOUNT_RECEIVED,
  ACTIVATE_SERVICE_ACCOUNT_FAILED,
  FETCH_DOCTOR_AUTOMATION_RULES,
  FETCH_DOCTOR_AUTOMATION_RULES_RECEIVED,
  FETCH_DOCTOR_AUTOMATION_RULES_FAILED,
  TOGGLE_AUTOMATION_RULE,
  TOGGLE_AUTOMATION_RULE_RECEIVED,
  TOGGLE_AUTOMATION_RULE_FAILED,
  DELETE_AUTOMATION_RULE,
  DELETE_AUTOMATION_RULE_RECEIVED,
  DELETE_AUTOMATION_RULE_FAILED,
  TOGGLE_RULE_EDITOR_MODAL,
  TOGGLE_DELETE_RULE_MODAL,
  CREATE_AUTOMATION_RULE,
  CREATE_AUTOMATION_RULE_RECEIVED,
  CREATE_AUTOMATION_RULE_FAILED,
  UPDATE_AUTOMATION_RULE,
  UPDATE_AUTOMATION_RULE_RECEIVED,
  UPDATE_AUTOMATION_RULE_FAILED,
  TOGGLE_ENABLE_AUTOMATION_RULE_POPUP,
  ADD_SAVED_FILE,
  ADD_SAVED_FILE_RECEIVED,
  ADD_SAVED_FILE_FAILED,
  FETCH_SAVED_FILES,
  FETCH_SAVED_FILES_RECEIVED,
  FETCH_SAVED_FILES_FAILED,
  FETCH_SAVED_VIEWS,
  FETCH_SAVED_VIEWS_RECEIVED,
  FETCH_SAVED_VIEWS_FAILED,
  DELETE_SAVED_FILE,
  DELETE_SAVED_FILE_RECEIVED,
  DELETE_SAVED_FILE_FAILED,
  SEND_SAVED_FILE_FAILED,
  TOGGLE_SAVED_FILE_LOADING,
  FETCH_PRACTICE_GUIDELINES,
  FETCH_PRACTICE_GUIDELINES_RECEIVED,
  FETCH_PRACTICE_GUIDELINES_FAILED,
  SAVE_PRACTICE_GUIDELINES,
  SAVE_PRACTICE_GUIDELINES_RECEIVED,
  SAVE_PRACTICE_GUIDELINES_FAILED,
  RESOLVE_HI_NEEDS_ATTENTION,
  RESOLVE_HI_NEEDS_ATTENTION_RECEIVED,
  RESOLVE_HI_NEEDS_ATTENTION_FAILED,
  FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS,
  FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS_RECEIVED,
  FETCH_AGGREGATED_PRACTICE_CUSTOM_TAGS_FAILED,
  CREATE_NEW_SAVED_VIEW,
  CREATE_NEW_SAVED_VIEW_RECEIVED,
  CREATE_NEW_SAVED_VIEW_FAILED,
  DELETE_SAVED_VIEW,
  DELETE_SAVED_VIEW_RECEIVED,
  DELETE_SAVED_VIEW_FAILED,
  TOGGLE_NEW_SAVED_VIEW_MODAL,
  toggleNewSavedViewModal,
  deleteSavedView,
  deleteSavedViewReceived,
  deleteSavedViewFailed,
  createNewSavedView,
  createNewSavedViewReceived,
  createNewSavedViewFailed,
  savePracticeGuidelines,
  savePracticeGuidelinesReceived,
  savePracticeGuidelinesFailed,
  fetchPracticeGuidelines,
  fetchPracticeGuidelinesReceived,
  fetchPracticeGuidelinesFailed,
  toggleSavedFileLoading,
  sendSavedFileFailed,
  deleteSavedFile,
  deleteSavedFileReceived,
  deleteSavedFileFailed,
  fetchSavedFiles,
  fetchSavedFilesReceived,
  fetchSavedFilesFailed,
  fetchSavedViews,
  fetchSavedViewsReceived,
  fetchSavedViewsFailed,
  addSavedFile,
  addSavedFileReceived,
  addSavedFileFailed,
  toggleEnableAutomationRulePopup,
  createAutomationRule,
  createAutomationRuleReceived,
  createAutomationRuleFailed,
  updateAutomationRule,
  updateAutomationRuleReceived,
  updateAutomationRuleFailed,
  toggleDeleteRuleModal,
  toggleRuleEditorModal,
  deleteAutomationRule,
  deleteAutomationRuleReceived,
  deleteAutomationRuleFailed,
  toggleAutomationRule,
  toggleAutomationRuleReceived,
  toggleAutomationRuleFailed,
  fetchDoctorAutomationRules,
  fetchDoctorAutomationRulesReceived,
  fetchDoctorAutomationRulesFailed,
  createServiceAccount,
  createServiceAccountReceived,
  createServiceAccountFailed,
  fetchServiceAccount,
  fetchServiceAccountReceived,
  fetchServiceAccountFailed,
  serviceAccountSetPasword,
  serviceAccountSetPaswordReceived,
  serviceAccountSetPaswordFailed,
  deactivateServiceAccount,
  deactivateServiceAccountFailed,
  deactivateServiceAccountReceived,
  activateServiceAccount,
  activateServiceAccountFailed,
  activateServiceAccountReceived,
  resolveHiNeedsAttention,
  resolveHiNeedsAttentionReceived,
  resolveHiNeedsAttentionFailed,
  fetchAggregatedPracticeCustomTags,
  fetchAggregatedPracticeCustomTagsReceived,
  fetchAggregatedPracticeCustomTagsFailed
}
