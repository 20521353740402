import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import NoteItemActions from './NoteItemActions'
import { windInputCursor } from 'utils/inputUtils'
import Actions from 'actions'
import { scrollBar } from 'styles/theme'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: 20
  },
  noteBodyWrapper: {
    padding: 15,
    border: '1px solid var(--border-color-7)',
    borderRadius: '15px 15px 0px 15px'
  },
  noteContent: {
    outline: 'none',
    color: 'var(--text-color-6)',
    paddingBottom: 4.8
  },
  noteDate: {
    textAlign: 'right',
    lineHeight: '14px',
    letterSpacing: '0.15em',
    textTransform: 'uppercase'
  },
  footer: {
    marginTop: 5,
    opacity: 0.5
  },
  actionsContainer: {
    width: isMobile() ? 'auto' : 125
  },
  textArea: {
    border: 'none !important',
    outline: 'none',
    fontFamily: 'Dazzed',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: 'var(--text-color-6)',
    padding: '0 !important',
    margin: '0 !important',
    ...scrollBar
  },
  noteContentContainer: {
    maxWidth: isMobile ? '80%' : 'auto'
  },
  assigneeName: {
    fontWeight: 600
  }
})

const NoteItem = React.forwardRef(
  (
    {
      id,
      body,
      date,
      metadata,
      onDelete,
      onEdit,
      onCancelEditing,
      onSaveEditedNote,
      isEditingMode,
      isCreating,
      doctorUserId,
      testIdPrefix = '',
      editable
    },
    ref
  ) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const noteContentRef = useRef(null)

    const { creatorName, creatorRoleDescription, creatorUserId, assigneeName } = useMemo(() => {
      const { creatorName, creatorRoleDescription, creatorUserId, assigneeName } = JSON.parse(metadata || '{}')
      return {
        creatorName: creatorName ? creatorName.trim() : '',
        creatorRoleDescription: creatorRoleDescription ? creatorRoleDescription.trim() : '',
        creatorUserId: creatorUserId ? creatorUserId.trim() : '',
        assigneeName: assigneeName ? assigneeName.trim() : ''
      }
    }, [metadata])
    const isEditAllowed = useMemo(() => creatorUserId === doctorUserId, [creatorUserId, doctorUserId])

    const [value, setValue] = useState(body)

    const handleCancelEditing = useCallback(() => {
      onCancelEditing()
      setValue(body)
    }, [body, onCancelEditing])

    const handleSaveEditedNote = useCallback(() => {
      const trimmedValue = value.trim()
      if (!trimmedValue) {
        dispatch(
          Actions.showSnackbar({
            type: 'error',
            text: t('dialogs.notes.noEmptyNote')
          })
        )
      } else if (trimmedValue !== body) {
        onSaveEditedNote(trimmedValue)
        setValue(trimmedValue)
      } else {
        handleCancelEditing()
      }
    }, [body, dispatch, handleCancelEditing, onSaveEditedNote, t, value])

    useEffect(() => {
      if (isCreating) {
        handleCancelEditing()
      }
    }, [handleCancelEditing, isCreating])

    useEffect(() => {
      if (isEditingMode) {
        const end = noteContentRef.current.value.length
        noteContentRef.current.focus()
        noteContentRef.current.setSelectionRange(end, end)
      }
    }, [isEditingMode])

    return (
      <div className={classes.container} ref={ref}>
        <Grid container justifyContent="space-between" alignItems="center" className={classes.noteBodyWrapper}>
          <Grid item className={classes.noteContentContainer}>
            {isEditingMode ? (
              <TextAreaAutosize
                rowsMax={6}
                style={{ bright: true }}
                className={classes.textArea}
                keepErrorContainerWhenInactive={false}
                value={value}
                setValue={setValue}
                id={`${testIdPrefix}-body`}
                ref={noteContentRef}
                onFocus={windInputCursor}
              />
            ) : (
              <DazzedParagraph16 className={classes.noteContent} strong>
                <span className={classes.assigneeName}>{!!assigneeName && `@${assigneeName} `}</span>
                {value.split('\n').map((line, index) => line || <br />)}
              </DazzedParagraph16>
            )}
          </Grid>
          <Grid item className={classes.actionsContainer}>
            {editable && (
              <NoteItemActions
                noteId={id}
                isEditingMode={isEditingMode}
                handleCancelEditing={handleCancelEditing}
                handleSaveEditedNote={handleSaveEditedNote}
                onDelete={onDelete}
                onEdit={onEdit}
                isEditAllowed={isEditAllowed}
                showAsMenu={isMobile()}
                testIdPrefix=""
              />
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center" className={classes.footer}>
          <Grid item>
            <DazzedParagraph12>{`${creatorName} ${
              creatorRoleDescription ? `(${creatorRoleDescription})` : ''
            }`}</DazzedParagraph12>
          </Grid>
          <Grid item>
            <DazzedParagraph12 className={classes.noteDate}>{date}</DazzedParagraph12>
          </Grid>
        </Grid>
      </div>
    )
  }
)

export default NoteItem
