import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Tab, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BaseModal from 'components/common/modals/BaseModal'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import useRcShipping from 'components/RCDashboard/useRcShipping'
import ShipbobIntegration from './ShipbobIntegration'
import LeadNameAndEmail from '../LeadNameAndEmail'
import ShippingStatus from './ShippingStatus'
import ShippingAudit from './ShippingAudit'

const useStyles = makeStyles({
  body: {
    overflowY: 'hidden',
    minWidth: '920px'
  },
  dialogContent: {
    paddingLeft: 35,
    paddingRight: 35,
    marginBottom: 10
  },
  leadName: {
    paddingBottom: '0!important'
  },
  tabsContainer: {
    paddingTop: '0!important'
  },
  tabsIndicator: {
    backgroundColor: 'var(--border-color-1)'
  },
  tabRoot: {
    minWidth: '100px'
  },
  tab: {
    textTransform: 'none',
    color: 'var(--primary-color)',
    fontFamily: 'Dazzed',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px'
  }
})

const RCShippingDetailsDialog = ({ leadId, isOpen, isSaving, handleClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const leads = useSelector(state => state.rcDashboardReducer.leads)
  const lead = useMemo(() => leads?.find(lead => lead.id === leadId), [leads, leadId])
  const audit = useMemo(() => JSON.parse(lead?.audit || '[]'), [lead])

  const { shipbobIntegrationActive, values, shippingDetails } = useRcShipping({ lead })

  const [tab, setTab] = useState('form')

  useEffect(() => {
    if (isOpen) {
      dispatch(Actions.resetRcShippingDetailsDialog())
      setTab('form')
    }
  }, [isOpen, dispatch])

  return (
    <BaseModal
      variant="alert"
      open={isOpen}
      withCloseIcon={true}
      isLoading={isSaving}
      title={t('pages.rcDashboard.dialogs.shippingDetails.title')}
      contentClassName={classes.dialogContent}
      handleClose={handleClose}
      className={classes.body}
      smothBorders
      primaryButtonId="save-lead-shipping-details"
      secondaryButtonId="cancel-lead-shipping-details"
    >
      <Grid container alignItems={'center'} justifyContent={'center'} spacing={4} style={{ overflowY: false }}>
        <Grid item xs={12} className={classes.leadName}>
          <LeadNameAndEmail
            email={lead?.email}
            name={`${lead?.firstName} ${lead?.lastName}`}
            justifyContent="flex-start"
          />
        </Grid>
        <Grid item xs={12} className={classes.tabsContainer}>
          <Tabs value={tab} onChange={(e, tab) => setTab(tab)} classes={{ indicator: classes.tabsIndicator }}>
            <Tab
              value="form"
              label={t('pages.rcDashboard.dialogs.shippingDetails.tabs.form')}
              classes={{ wrapper: classes.tab, root: classes.tabRoot }}
            />
            <Tab
              value="audit"
              label={t('pages.rcDashboard.dialogs.shippingDetails.tabs.audit')}
              classes={{ wrapper: classes.tab, root: classes.tabRoot }}
            />
          </Tabs>
        </Grid>
        {tab === 'form' && (
          <>
            <Grid item xs={12}>
              <ShippingStatus
                lead={lead}
                shipbobIntegrationActive={shipbobIntegrationActive}
                values={values}
                currentShippingStatus={shippingDetails?.status}
              />
            </Grid>
            <Grid item xs={12}>
              <ShipbobIntegration
                leadId={lead?.id}
                values={values}
                shipbobIntegrationActive={shipbobIntegrationActive}
                shippingDetails={shippingDetails}
              />
            </Grid>
          </>
        )}
        {tab === 'audit' && (
          <Grid item xs={12}>
            <ShippingAudit audit={audit} />
          </Grid>
        )}
      </Grid>
    </BaseModal>
  )
}

export default RCShippingDetailsDialog
