import { Auth } from 'aws-amplify'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { isUserOfRole } from 'utils/authUtils'
import { Roles } from 'consts/authConsts'

export default () => {
  const dispatch = useDispatch()

  const { user } = useSelector(state => state.authReducer.mfa)

  const getQrCodeLink = ({ username, totpCode, email }) =>
    `otpauth://totp/${username}?secret=${totpCode}&issuer=Grin: ${email}`

  const verifyCode = useCallback(
    async code => {
      let loggedUser = user

      if (!loggedUser?.challengeName) {
        await Auth.verifyTotpToken(loggedUser, code)

        let preferredMFA = 'TOTP'

        if (!isUserOfRole([Roles.DevAdmin, Roles.Admin])) {
          preferredMFA = 'SMS'
        }

        await Auth.setPreferredMFA(loggedUser, preferredMFA)
      } else {
        await Auth.confirmSignIn(loggedUser, code, loggedUser.challengeName)

        loggedUser = await Auth.currentAuthenticatedUser()
      }

      dispatch(Actions.signInReceived(loggedUser))
    },
    [dispatch, user]
  )

  return {
    getQrCodeLink,
    verifyCode
  }
}
