import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import IconButton from 'components/common/buttons/IconButton'
import GrinMenu from 'components/common/menu/GrinMenu'
import TemplatesHeader from './TemplatesHeader'
import TemplatesList from './TemplatesList'
import SelectedTemplate from './SelectedTemplate'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(({ height, width }) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: ({ width }) => (width ? width : 500),
    height: ({ height }) => (height ? height : 400),
    userSelect: 'none',
    overflow: 'hidden'
  },
  menu: {
    borderRadius: 10,
    width: ({ width }) => (width ? width : 500),
    height: ({ height }) => (height ? height : 400),
    padding: 0,
    overflow: 'unset !important',
    position: 'relative',
    transform: 'translateX(13px) !important',
    '&:after': {
      content: "''",
      position: 'absolute',
      right: 10,
      bottom: -25,
      transform: 'translateX(-50%)',
      background: 'var(--bg-color-10)',
      width: 25,
      height: 25,
      clipPath: 'polygon(0 0, 100% 0, 50% 50%)'
    }
  },
  body: {
    flex: 1
  },
  loadingContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  templatesListContainer: {
    height: '100%',
    maxHeight: ({ height }) => (height ? height - 50 : 350)
  }
}))

const Templates = ({
  id = '',
  onTemplateApplied = () => {},
  onSetInputFocus,
  templates,
  renderIcon,
  searchPlaceholder,
  isSearchable = true,
  isSelectDefault = false,
  isLoading = false,
  renderSelectedTemplate,
  onOpenTemplatesLink,
  renderEmptyState,
  isCreatable = true,
  title,
  tooltipValue = '',
  height,
  width,
  templatesSize = 5,
  selectedTemplateSize = 7,
  transformOrigin,
  renderList,
  disabled = false,
  onOpenCallback = () => {},
  onCloseCallback = () => {},
  onTemplateSelected = () => {}
}) => {
  const classes = useStyles({ height, width })
  const firstTemplate = useMemo(() => templates[0], [templates])

  const [selectedTemplate, setSelectedTemplate] = useState(isSelectDefault ? firstTemplate : null)
  const [open, setOpen] = useState(false)

  const handleClickApplyButton = useCallback(() => {
    onTemplateApplied(selectedTemplate)
    setOpen(false)
    onSetInputFocus && onSetInputFocus(true)
  }, [selectedTemplate, onTemplateApplied, onSetInputFocus])

  const handleClose = () => {
    setOpen(false)
    onCloseCallback()
    onSetInputFocus && onSetInputFocus(true)
  }

  const selectTemplate = useCallback(
    value => {
      setSelectedTemplate(value)
      onTemplateSelected(value)
    },
    [onTemplateSelected]
  )

  useEffect(() => {
    if (!open) {
      setSelectedTemplate(null)
    }
  }, [open, setSelectedTemplate])

  useEffect(() => {
    if (open && isSelectDefault) {
      setSelectedTemplate(firstTemplate)
    }
  }, [open, isSelectDefault, setSelectedTemplate, firstTemplate])

  const handleOpen = useCallback(() => {
    setOpen(true)
    onOpenCallback()
  }, [onOpenCallback])

  return (
    <GrinMenu
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      disableMenuFocus={true}
      triggerComponent={
        <IconButton id={id} disabled={disabled} tooltipValue={tooltipValue} style={{ width: 44, height: 44 }}>
          {renderIcon()}
        </IconButton>
      }
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: transformOrigin ? transformOrigin : 415,
        horizontal: 'right'
      }}
      disabled={disabled}
      className={classes.menu}
    >
      <div className={classes.content}>
        <TemplatesHeader onClose={handleClose} onOpenTemplatesLink={onOpenTemplatesLink} title={title} />
        {isLoading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : templates.length ? (
          <Grid container className={classes.body}>
            <Grid item sm={templatesSize} className={classes.templatesListContainer}>
              <TemplatesList
                isSearchable={isSearchable}
                templates={templates}
                selected={selectedTemplate}
                setSelected={selectTemplate}
                searchPlaceholder={searchPlaceholder}
                renderList={renderList}
                isCreatable={isCreatable}
              />
            </Grid>
            <Grid item sm={selectedTemplateSize}>
              <SelectedTemplate
                selectedTemplate={selectedTemplate}
                onButtonClick={handleClickApplyButton}
                renderSelectedTemplate={renderSelectedTemplate}
              />
            </Grid>
          </Grid>
        ) : (
          renderEmptyState()
        )}
      </div>
    </GrinMenu>
  )
}

export default Templates
