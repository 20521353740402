import * as React from 'react'

export default props => (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 2C1 1.44772 1.44772 1 2 1H12C12.5523 1 13 1.44772 13 2V8C13 8.55228 12.5523 9 12 9H7L4 12V9H2C1.44772 9 1 8.55228 1 8V2Z"
      stroke="#041333"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path d="M3 4H11" stroke="#041333" />
    <path d="M3 6H9" stroke="#041333" />
  </svg>
)
