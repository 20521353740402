import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import useAutochart from '../AutochartGrid/useAutochart'
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Table, Grid } from '@material-ui/core'
import useAutochartTable from './useAutochartTable'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import AutochartTableToolbar from './AutochartTableToolbar'
import { useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  tableContainer: {
    height: 'calc(var(--autochart-chart-height) - 8px)'
  },
  columnHeader: {
    '&.date': {
      width: '64px'
    },
    '&.toothNumber': {
      width: '32px'
    },
    '&.surfaces': {
      width: '64px'
    },
    '&.description': {
      width: '25%'
    }
  },
  tableCell: {
    padding: '8px 12px',
    borderBottom: '1px solid var(--border-color-7)',
    background: '#fff',
    '&.sideBorders': {
      borderRight: '1px solid var(--border-color-7)'
    },
    '&.noBorderBottom': {
      borderBottom: '0px'
    }
  },
  tableRow: {
    transition: 'background-color 0.5s',
    '&:hover': {
      backgroundColor: 'var(--bg-color-59)'
    }
  },
  columnHeaderLabel: {
    color: 'var(--text-color-50)',
    fontWeight: 500
  },
  cellLabel: {
    fontWeight: 500
  }
}))

const AutochartTable = () => {
  const classes = useStyles()

  const { autochart } = useAutochart()
  const { patientSM } = useSelector(state => state.autochartReducer)

  const { rows, columns, downloadTableCsv } = useAutochartTable({ autochart })

  const handleExport = useCallback(() => {
    if (!patientSM) {
      return
    }

    downloadTableCsv({ fileName: `Autochart - ${patientSM.name}` })
    trackEvent(`autochart - export table`, { patientId: patientSM.id, autochartId: autochart.id })
  }, [patientSM, downloadTableCsv, autochart])

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <AutochartTableToolbar onExport={handleExport} />
      </Grid>
      <Grid item>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, i) => (
                  <TableCell
                    key={`autochart-table-header-${column.name}`}
                    className={[
                      classes.tableCell,
                      classes.columnHeader,
                      column.name,
                      i < columns.length - 1 ? 'sideBorders' : ''
                    ].join(' ')}
                  >
                    <DazzedParagraph12 className={classes.columnHeaderLabel} textAlign={column.textAlign}>
                      {column.label}
                    </DazzedParagraph12>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow key={`table-row-${i}`} className={classes.tableRow}>
                  {columns.map((column, j) => (
                    <TableCell
                      key={`table-cell-${i}-${column.name}`}
                      className={[
                        classes.tableCell,
                        j < columns.length - 1 ? 'sideBorders' : '',
                        i === rows.length - 1 ? 'noBorderBottom' : ''
                      ].join(' ')}
                    >
                      {column.render ? (
                        column.render(row)
                      ) : (
                        <DazzedParagraph12 className={classes.cellLabel} textAlign={column.textAlign}>
                          {row[column.name]}
                        </DazzedParagraph12>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default AutochartTable
