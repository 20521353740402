import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles, CircularProgress } from '@material-ui/core'
import compareVersions from 'compare-versions'
import Actions from 'actions'
import BaseModal from 'components/common/modals/BaseModal'
import TransferingData from './TransferingData'
import ConfirmTransfer from './ConfirmTransfer'
import { DATA_TYPES } from 'consts/patientsConsts'
import { HIGHER_VERSION } from 'consts/version'
import { isEmpty } from 'lodash'

const useStyles = () =>
  makeStyles({
    loadingWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 30
    },
    container: {
      width: '700px !important',
      maxWidth: 1000,
      height: 'auto'
    }
  })()

export default () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const isOpen = useSelector(state => state.patientsReducer.transferPatient.isModalOpen)
  const { isLoadingPatient, patient: patientsReducerPatient } = useSelector(state => state.patientsReducer)
  const { data: opsDashboardPatient } = useSelector(state => state.supportReducer.patientsDashboard?.selectedPatient)
  const { doctors, isLoading: isDoctorsLoading } = useSelector(state => state.supportReducer)
  const isLoading = useSelector(state => state.patientsReducer.transferPatient.isLoading)

  const [dataTypes, setDataTypes] = useState(DATA_TYPES)
  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [confirmTransferStep, setConfirmTransferStep] = useState(false)

  const isFetchingData = useMemo(() => isLoadingPatient || isDoctorsLoading, [isDoctorsLoading, isLoadingPatient])
  const patient = useMemo(
    () => (isEmpty(patientsReducerPatient) ? opsDashboardPatient?.patient : patientsReducerPatient),
    [opsDashboardPatient, patientsReducerPatient]
  )

  const isValidPatientApp = useMemo(
    () => compareVersions(patient?.user?.version || '0.0.0', '1.38.3') === HIGHER_VERSION,
    [patient]
  )
  const isValidDoctorsSubscription = useMemo(() => selectedDoctor?.user?.grinPlanKey !== 'grin-trial', [selectedDoctor])

  const handleClose = useCallback(() => dispatch(Actions.setTransferPatientModalVisibility(false)), [dispatch])

  useEffect(() => {
    if (isOpen && !isDoctorsLoading && !doctors.length) {
      dispatch(Actions.supportRequestOwnerDoctors())
    }
  }, [dispatch, doctors, isDoctorsLoading, isOpen])

  useEffect(() => {
    if (isOpen) {
      setConfirmTransferStep(false)
      setSelectedDoctor(null)
      setDataTypes(DATA_TYPES)
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && patient?.guardianId) {
      dispatch(Actions.supportFetchLinkedPatients(patient.guardianId))
    }
  }, [isOpen, patient?.guardianId, dispatch])

  const handleClickNext = useCallback(() => setConfirmTransferStep(true), [])
  const handleConfirmTransfer = useCallback(
    () => dispatch(Actions.transferPatient({ patientId: patient?.id, doctorId: selectedDoctor?.id, dataTypes })),
    [dataTypes, dispatch, patient, selectedDoctor]
  )

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleClose}
      variant="alert"
      title={
        confirmTransferStep
          ? t('dialogs.confirmation.title')
          : t('dialogs.transferPatient.title', { patientName: patient?.name })
      }
      className={classes.container}
    >
      {isFetchingData ? (
        <div className={classes.loadingWrapper}>
          <CircularProgress color="inherit" />
        </div>
      ) : confirmTransferStep ? (
        <ConfirmTransfer
          patientProgram={patient?.user?.program}
          patientId={patient?.id}
          isValidPatientApp={isValidPatientApp}
          isValidDoctorsSubscription={isValidDoctorsSubscription}
          onConfirmTransfer={handleConfirmTransfer}
          onClickCancel={handleClose}
          isLoading={isLoading}
        />
      ) : (
        <TransferingData
          patient={patient}
          doctors={doctors}
          selectedDoctor={selectedDoctor}
          setSelectedDoctor={setSelectedDoctor}
          dataTypes={dataTypes}
          setDataTypes={setDataTypes}
          onClickCancel={handleClose}
          onClickNext={handleClickNext}
        />
      )}
    </BaseModal>
  )
}
