import * as React from 'react'

function RightBite(props) {
  return (
    <svg width="41" height="16" viewBox="0 0 41 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.85747 5.71423C0.645887 5.47786 1.02891 3.42854 1.17177 2.62855C1.17177 2.62855 1.51473 2.62855 2.02901 2.79998C2.42691 2.93261 2.97186 3.31426 2.97186 3.31426C2.97186 3.05712 3.14328 2.79998 3.48614 2.79998C4.10327 2.79998 5.22898 3.94282 5.71468 4.51424C5.77183 4.19996 6.05754 3.5714 6.74324 3.5714C7.42895 3.5714 8.22894 3.91425 8.54322 4.08568C8.45751 3.59997 8.42322 2.62855 8.97179 2.62855C9.52035 2.62855 11.0289 4.11425 11.7146 4.8571C11.6003 3.88568 11.5946 1.94285 12.486 1.94285C13.3774 1.94285 14.4003 3.31426 14.8003 3.99996C14.8003 3.25712 15.126 1.77142 16.4288 1.77142C17.7317 1.77142 18.9145 3.48569 19.3431 4.34282C19.3431 3.59997 19.7202 2.11427 21.2288 2.11427C22.7373 2.11427 23.6859 3.99996 23.9716 4.94281C24.343 4.08568 25.4802 2.37141 27.0573 2.37141C28.6344 2.37141 29.4858 4.02854 29.7144 4.8571C30.1715 3.97139 31.3772 2.19999 32.5429 2.19999C33.7086 2.19999 34.2858 3.51426 34.4286 4.17139C34.9143 3.25712 35.5429 1.59999 36.1429 1.59999C36.6045 1.59999 36.7429 2.37141 37 3.22855C37.5143 1.94285 38.7143 1 39.1429 1C39.4857 1 39.7429 1.45714 39.8286 1.68571C39.8286 1.68571 40 2.62232 40 3.22855C40 3.83477 40 3.91425 39.8286 4.77138C39.6571 5.62852 38.5429 8.19992 37.1715 7.4285C36.6915 8.31991 35.3143 8.19992 34.6858 8.02849C34.4286 8.45706 33.5715 9.31419 32.2001 9.31419C30.8287 9.31419 30.0858 9.14276 29.8858 9.05705C29.7715 9.25705 29.0458 9.65704 27.0573 9.65704C25.0687 9.65704 24.2288 8.74277 24.0573 8.28563C23.9145 8.57134 23.2345 9.14276 21.6574 9.14276C20.0802 9.14276 19.4574 8.22849 19.3431 7.77135C19.0574 8.19992 18.2117 9.05705 17.1145 9.05705C16.0174 9.05705 15.1717 8.02849 14.886 7.51421C14.8289 7.94278 14.5432 8.79991 13.8574 8.79991C13.1717 8.79991 12.3146 7.65706 11.9718 7.08564C11.8003 7.54278 11.286 8.45706 10.6003 8.45706C9.91463 8.45706 9.22893 7.54278 8.97179 7.08564C7.7718 7.4285 6.74324 7.34278 5.97182 6.48565C4.25756 7.10279 3.37185 6.05708 3.14328 5.45709C2.80065 5.71423 2.36008 5.81228 1.85747 5.71423Z"
        stroke="#737B8A"
      />
      <path
        d="M3.14404 5.54285C2.65833 5.82856 1.87549 6.51998 2.62976 6.99997C3.38404 7.47997 4.34405 6.99997 4.68691 6.74283C4.28692 7.39997 3.58978 8.86852 4.34406 9.1428C5.09834 9.41709 6.71546 9.02852 7.42974 8.79995C7.28688 9.62851 7.18974 11.3542 7.94402 11.6285C8.6983 11.9028 10.3154 11.1142 11.0297 10.6856C11.0011 11.3999 10.944 12.9142 11.2868 13.1713C11.5935 13.4013 13.2868 12.3713 14.1154 11.7999C14.2011 12.657 14.5097 14.3713 15.0582 14.3713C15.6068 14.3713 17.0011 12.4285 17.6296 11.4571C17.7725 12.4856 18.3496 14.5427 19.5153 14.5427C20.681 14.5427 21.7724 12.5428 22.1724 11.5428C22.3153 12.5428 22.8753 14.5427 23.9724 14.5427C25.0695 14.5427 25.6867 12.7142 25.8581 11.7999C26.0581 12.6285 26.6809 14.2856 27.5724 14.2856C28.4638 14.2856 28.9152 12.7428 29.0295 11.9713C29.3438 12.657 30.1609 14.0285 30.9152 14.0285C31.6695 14.0285 31.9152 12.3713 31.9437 11.5428C32.258 12.2571 33.0752 13.6856 33.8294 13.6856C34.5837 13.6856 34.9151 12.2571 34.9437 11.4571C35.2866 12.0571 36.0751 13.0856 36.8294 13.0856C37.5837 13.0856 37.658 11.8856 37.6008 11.2856C37.6865 11.6285 37.9265 12.3142 38.2008 12.3142C38.4751 12.3142 39.1437 9.91422 38.2008 7.42854"
        stroke="#737B8A"
      />
      <path d="M2.97266 3.31421C3.00123 3.9142 3.07551 5.18276 3.14408 5.45704" stroke="#737B8A" />
      <path d="M5.71484 4.42859C5.74341 4.9143 5.83484 6.00571 5.97198 6.48571" stroke="#737B8A" />
      <path d="M8.54297 4.08569L8.97154 7.17137" stroke="#737B8A" />
      <path d="M11.7148 4.77136L11.972 7.17133" stroke="#737B8A" />
      <path d="M14.8008 3.82849L14.8865 7.68559" stroke="#737B8A" />
      <path d="M19.3438 4.08569V7.85708" stroke="#737B8A" />
      <path d="M23.9727 4.85706L24.0584 8.3713" stroke="#737B8A" />
      <path d="M29.7148 4.77136L29.8863 9.05703" stroke="#737B8A" />
      <path d="M34.5137 3.99988L34.6851 8.11412" stroke="#737B8A" />
      <path d="M37 3.14282L37.1714 7.42849" stroke="#737B8A" />
      <path d="M7.42969 8.79994C7.68683 8.39994 8.26968 7.51424 8.54396 7.17139" stroke="#737B8A" />
      <path d="M11.0293 10.7713C11.115 10.1999 11.4064 8.71419 11.8864 7.34277" stroke="#737B8A" />
      <path d="M14.1148 11.8856C14.0576 11.257 13.9776 9.75992 14.1148 8.79993" stroke="#737B8A" />
      <path d="M17.6289 11.5427C17.6575 10.9427 17.7318 9.58845 17.8003 8.97131" stroke="#737B8A" />
      <path d="M22.1719 11.7141V9.1427" stroke="#737B8A" />
      <path d="M25.8574 12.1427L25.9431 9.57129" stroke="#737B8A" />
      <path d="M29.0293 11.9713V9.4856" stroke="#737B8A" />
      <path d="M31.9434 11.7142L32.0291 9.31421" stroke="#737B8A" />
      <path d="M35.1148 8.19983L34.9434 11.4569" stroke="#737B8A" />
      <path d="M37.1719 7.51416C37.3433 8.31415 37.669 10.2398 37.6004 11.5427" stroke="#737B8A" />
      <path
        d="M1.85747 5.71423C0.645887 5.47786 1.02891 3.42854 1.17177 2.62855C1.17177 2.62855 1.51473 2.62855 2.02901 2.79998C2.42691 2.93261 2.97186 3.31426 2.97186 3.31426C2.97186 3.05712 3.14328 2.79998 3.48614 2.79998C4.10327 2.79998 5.22898 3.94282 5.71468 4.51424C5.77183 4.19996 6.05754 3.5714 6.74324 3.5714C7.42895 3.5714 8.22894 3.91425 8.54322 4.08568C8.45751 3.59997 8.42322 2.62855 8.97179 2.62855C9.52035 2.62855 11.0289 4.11425 11.7146 4.8571C11.6003 3.88568 11.5946 1.94285 12.486 1.94285C13.3774 1.94285 14.4003 3.31426 14.8003 3.99996C14.8003 3.25712 15.126 1.77142 16.4288 1.77142C17.7317 1.77142 18.9145 3.48569 19.3431 4.34282C19.3431 3.59997 19.7202 2.11427 21.2288 2.11427C22.7373 2.11427 23.6859 3.99996 23.9716 4.94281C24.343 4.08568 25.4802 2.37141 27.0573 2.37141C28.6344 2.37141 29.4858 4.02854 29.7144 4.8571C30.1715 3.97139 31.3772 2.19999 32.5429 2.19999C33.7086 2.19999 34.2858 3.51426 34.4286 4.17139C34.9143 3.25712 35.5429 1.59999 36.1429 1.59999C36.6045 1.59999 36.7429 2.37141 37 3.22855C37.5143 1.94285 38.7143 1 39.1429 1C39.4857 1 39.7429 1.45714 39.8286 1.68571C39.8286 1.68571 40 2.62232 40 3.22855C40 3.83477 40 3.91425 39.8286 4.77138C39.6571 5.62852 38.5429 8.19992 37.1715 7.4285C36.6915 8.31991 35.3143 8.19992 34.6858 8.02849C34.4286 8.45706 33.5715 9.31419 32.2001 9.31419C30.8287 9.31419 30.0858 9.14276 29.8858 9.05705C29.7715 9.25705 29.0458 9.65704 27.0573 9.65704C25.0687 9.65704 24.2288 8.74277 24.0573 8.28563C23.9145 8.57134 23.2345 9.14276 21.6574 9.14276C20.0802 9.14276 19.4574 8.22849 19.3431 7.77135C19.0574 8.19992 18.2117 9.05705 17.1145 9.05705C16.0174 9.05705 15.1717 8.02849 14.886 7.51421C14.8289 7.94278 14.5432 8.79991 13.8574 8.79991C13.1717 8.79991 12.3146 7.65706 11.9718 7.08564C11.8003 7.54278 11.286 8.45706 10.6003 8.45706C9.91463 8.45706 9.22893 7.54278 8.97179 7.08564C7.7718 7.4285 6.74324 7.34278 5.97182 6.48565C4.25756 7.10279 3.37185 6.05708 3.14328 5.45709C2.80065 5.71423 2.36008 5.81228 1.85747 5.71423Z"
        fill="#737B8A"
        fillOpacity="0.25"
      />
      <path
        d="M2.62976 6.99997C1.87549 6.51998 2.65833 5.82856 3.14404 5.54285L4.5 6.5H6L7.5 7.5L9 7L10.5 8.5L12 7L14 9L15 7.5L17 9L19.5 8L21.5 9L24 8.5L27 9.5L30 9H32L34.5 8H36L37.1797 7.42854H38.2008C39.1437 9.91422 38.4751 12.3142 38.2008 12.3142C37.9265 12.3142 37.6865 11.6285 37.6008 11.2856C37.658 11.8856 37.5837 13.0856 36.8294 13.0856C36.0751 13.0856 35.2866 12.0571 34.9437 11.4571C34.9151 12.2571 34.5837 13.6856 33.8294 13.6856C33.0752 13.6856 32.258 12.2571 31.9437 11.5428C31.9152 12.3713 31.6695 14.0285 30.9152 14.0285C30.1609 14.0285 29.3438 12.657 29.0295 11.9713C28.9152 12.7428 28.4638 14.2856 27.5724 14.2856C26.6809 14.2856 26.0581 12.6285 25.8581 11.7999C25.6867 12.7142 25.0695 14.5427 23.9724 14.5427C22.8753 14.5427 22.3153 12.5428 22.1724 11.5428C21.7724 12.5428 20.681 14.5427 19.5153 14.5427C18.3496 14.5427 17.7725 12.4856 17.6296 11.4571C17.0011 12.4285 15.6068 14.3713 15.0582 14.3713C14.5097 14.3713 14.2011 12.657 14.1154 11.7999C13.2868 12.3713 11.5935 13.4013 11.2868 13.1713C10.944 12.9142 11.0011 11.3999 11.0297 10.6856C10.3154 11.1142 8.6983 11.9028 7.94402 11.6285C7.18974 11.3542 7.28688 9.62851 7.42974 8.79995C6.71546 9.02852 5.09834 9.41709 4.34406 9.1428C3.58978 8.86852 4.28692 7.39997 4.68691 6.74283C4.34405 6.99997 3.38404 7.47997 2.62976 6.99997Z"
        fill="#737B8A"
        fillOpacity="0.25"
      />
    </svg>
  )
}

export default RightBite
