import * as React from 'react'

const MarkUnread = ({ ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.8095 5.71653L14.0938 4.45081V2.34375C14.0938 2.08487 13.8839 1.875 13.625 1.875H10.6562L8.28125 0.09375C8.11459 -0.03125 7.88541 -0.03125 7.71875 0.09375L5.34375 1.875H2.375C2.11612 1.875 1.90625 2.08487 1.90625 2.34375V4.45081L0.190469 5.71653C0.0706875 5.80491 0 5.94491 0 6.09375V15.5312C0 15.7901 0.209875 16 0.46875 16H15.5312C15.7901 16 16 15.7901 16 15.5312V6.09375C16 5.94491 15.9293 5.80491 15.8095 5.71653ZM15.0625 6.33044V7.6785L14.0938 8.16088V5.61578L15.0625 6.33044ZM8 1.05469L9.09375 1.875H6.90625L8 1.05469ZM13.1562 2.8125V8.62769L8 11.1951L2.84375 8.62769V2.8125H13.1562ZM1.90625 5.61581V8.16088L0.9375 7.6785V6.33044L1.90625 5.61581ZM0.9375 15.0625V8.72581L7.79106 12.1384C7.92262 12.2039 8.07734 12.2039 8.20894 12.1384L15.0625 8.72578V15.0625H0.9375Z"
        fill="#282828"
      />
    </svg>
  )
}

export default MarkUnread
