import { useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import Actions from '../actions'
import { calcOverdueDate, isPatientScanOverdue } from '../utils/milestoneUtils'
import usePatientTags from './usePatientTags'
import { BannerTypes } from 'consts/chatConsts'

const bannersPriority = {
  disabledPatient: 1,
  paused: 2,
  overdue: 3,
  treatmentTypeCTA: 4,
  nextScanDate: 5
}

export default () => {
  const dispatch = useDispatch()

  const patient = useSelector(state => state.patientsReducer.patient)
  const { tags: patientTags } = usePatientTags({ patient })
  const { data: treatment } = useSelector(state => state.patientsReducer.patientCard.treatment)

  const patientStatus = useMemo(() => patient?.treatments?.items[0]?.currentStatus?.status, [patient])

  useEffect(() => {
    if (patient.id && (!treatment || treatment.patientId !== patient.id)) {
      dispatch(Actions.requestPatientTreatment(patient))
    }
  }, [dispatch, patient, treatment])

  const banners = useMemo(
    () => [
      {
        type: BannerTypes.Overdue,
        condition: isPatientScanOverdue(patientTags) && calcOverdueDate(patient) > 0,
        priority: bannersPriority.overdue
      },
      {
        type: BannerTypes.TreatmentTypeCTA,
        condition: treatment && !treatment.type && patient?.user?.isActive,
        priority: bannersPriority.treatmentTypeCTA
      },
      {
        type: BannerTypes.DisabledPatient,
        condition:
          !patient.user?.isActive || (treatment && treatment?.currentStatus?.statusKey?.includes('transferred')),
        priority: bannersPriority.disabledPatient
      },
      {
        type: BannerTypes.PausedScans,
        priority: bannersPriority.paused,
        condition: patientTags.some(tag => tag.type === 'scan' && tag.value === 'paused')
      },
      {
        type: BannerTypes.NextScanDate,
        priority: bannersPriority.nextScanDate,
        condition: patientStatus?.scanCyclesEnabled
      }
    ],
    [patient, treatment, patientTags, patientStatus]
  )

  return patient?.details?.name
    ? _.minBy(
        banners.filter(({ condition }) => condition),
        'priority'
      )?.type
    : null
}
