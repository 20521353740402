import * as React from 'react'

const Close1 = props => {
  return (
    <svg width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 0.75L8.99986 8.74986" stroke={props.color || 'var(--text-color-3)'} strokeWidth="1.2" />
      <path d="M9 0.75L1.00014 8.74986" stroke={props.color || 'var(--text-color-3)'} strokeWidth="1.2" />
    </svg>
  )
}

export default Close1
