const FETCH_REJECTED = 'FETCH_REJECTED'
const REQUEST_ALLOWED_DOCTORS = 'REQUEST_ALLOWED_DOCTORS'
const ALLOWED_DOCTORS_RECEIVED = 'ALLOWED_DOCTORS_RECEIVED'
const REQUEST_UPDATE_DOCTOR_PLANS = 'REQUEST_UPDATE_DOCTOR_PLANS'
const SUPPORT_REQUEST_DOCTORS = 'SUPPORT_REQUEST_DOCTORS'
const SUPPORT_REQUEST_DOCTORS_RECEIVED = 'SUPPORT_REQUEST_DOCTORS_RECEIVED'
const SUPPORT_UPDATE_PATIENT_DETAILS = 'SUPPORT_UPDATE_PATIENT_DETAILS'
const SUPPORT_UPDATE_PATIENT_DETAILS_RECEIVED = 'SUPPORT_UPDATE_PATIENT_DETAILS_RECEIVED'
const SUPPORT_UPDATE_PATIENT_DETAILS_FAILED = 'SUPPORT_UPDATE_PATIENT_DETAILS_FAILED'
const SUPPORT_UPDATE_PATIENT_FF = 'SUPPORT_UPDATE_PATIENT_FF'
const SUPPORT_UPDATE_PATIENT_FF_RECEIVED = 'SUPPORT_UPDATE_PATIENT_FF_RECEIVED'
const SUPPORT_UPDATE_PATIENT_FF_FAILED = 'SUPPORT_UPDATE_PATIENT_FF_FAILED'
const UPDATE_DOCTOR_PLANS_RECEIVED = 'UPDATE_DOCTOR_PLANS_RECEIVED'
const REQUEST_CREATE_ALLOWED_DOCTOR = 'REQUEST_CREATE_ALLOWED_DOCTOR'
const CREATE_ALLOWED_DOCTOR_RECEIVED = 'CREATE_ALLOWED_DOCTOR_RECEIVED'
const CREATE_ALLOWED_DOCTOR_FAILED = 'CREATE_ALLOWED_DOCTOR_FAILED'
const REQUEST_IMPORT_ALLOWED_DOCTOR = 'REQUEST_IMPORT_ALLOWED_DOCTOR'
const IMPORT_ALLOWED_DOCTOR_RECEIVED = 'IMPORT_ALLOWED_DOCTOR_RECEIVED'
const IMPORT_DOCTOR_PLANS_CORRUPTED_CSV = 'IMPORT_DOCTOR_PLANS_CORRUPTED_CSV'
const FETCH_DOCTOR_SEATS_BY_USERNAME = 'FETCH_DOCTOR_SEATS_BY_USERNAME'
const FETCH_DOCTOR_SEATS_BY_USERNAME_RECEIVED = 'FETCH_DOCTOR_SEATS_BY_USERNAME_RECEIVED'
const FETCH_DOCTOR_SEATS_BY_USERNAME_FAILED = 'FETCH_DOCTOR_SEATS_BY_USERNAME_FAILED'
const SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID = 'SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID'
const SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID_RECEIVED = 'SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID_RECEIVED'
const SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID_FAILED = 'SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID_FAILED'
const SUPPORT_CREATE_DOCTOR_NOTE = 'SUPPORT_CREATE_DOCTOR_NOTE'
const SUPPORT_CREATE_DOCTOR_NOTE_RECEIVED = 'SUPPORT_CREATE_DOCTOR_NOTE_RECEIVED'
const SUPPORT_CREATE_DOCTOR_NOTE_FAILED = 'SUPPORT_CREATE_DOCTOR_NOTE_FAILED'
const CHANGE_DOCTOR_EMAIL = 'CHANGE_DOCTOR_EMAIL'
const CHANGE_DOCTOR_EMAIL_RECEIVED = 'CHANGE_DOCTOR_EMAIL_RECEIVED'
const CHANGE_DOCTOR_EMAIL_FAILED = 'CHANGE_DOCTOR_EMAIL_FAILED'
const CHANGE_PATIENT_EMAIL = 'CHANGE_PATIENT_EMAIL'
const CHANGE_PATIENT_EMAIL_RECEIVED = 'CHANGE_PATIENT_EMAIL_RECEIVED'
const CHANGE_PATIENT_EMAIL_FAILED = 'CHANGE_PATIENT_EMAIL_FAILED'
const FETCH_USER_GROUPS = 'FETCH_USER_GROUPS'
const FETCH_USER_GROUPS_RECEIVED = 'FETCH_USER_GROUPS_RECEIVED'
const FETCH_USER_GROUPS_FAILED = 'FETCH_USER_GROUPS_FAILED'
const SAVE_PROMOTION = 'SAVE_PROMOTION'
const SAVE_PROMOTION_RECEIVED = 'SAVE_PROMOTION_RECEIVED'
const SAVE_PROMOTION_FAILED = 'SAVE_PROMOTION_FAILED'
const DELETE_PROMOTION = 'DELETE_PROMOTION'
const DELETE_PROMOTION_RECEIVED = 'DELETE_PROMOTION_RECEIVED'
const DELETE_PROMOTION_FAILED = 'DELETE_PROMOTION_FAILED'
const SUPPORT_SEARCH_PATIENT = 'SUPPORT_SEARCH_PATIENT'
const SUPPORT_SEARCH_PATIENT_RECEIVED = 'SUPPORT_SEARCH_PATIENT_RECEIVED'
const SUPPORT_SEARCH_PATIENT_FAILED = 'SUPPORT_SEARCH_PATIENT_FAILED'
const SUPPORT_CLEAR_PATIENTS_SEARCH_RESULTS = 'SUPPORT_CLEAR_PATIENTS_SEARCH_RESULTS'
const SUPPORT_SET_SELECTED_PATIENT = 'SUPPORT_SET_SELECTED_PATIENT'
const SUPPORT_FETCH_ANNOUNCEMENTS = 'SUPPORT_FETCH_ANNOUNCEMENTS'
const SUPPORT_FETCH_ANNOUNCEMENTS_RECEIVED = 'SUPPORT_FETCH_ANNOUNCEMENTS_RECEIVED'
const SUPPORT_FETCH_ANNOUNCEMENTS_FAILED = 'SUPPORT_FETCH_ANNOUNCEMENTS_FAILED'
const SUPPORT_SAVE_ANNOUNCEMENT = 'SUPPORT_SAVE_ANNOUNCEMENT'
const SUPPORT_SAVE_ANNOUNCEMENT_RECEIVED = 'SUPPORT_SAVE_ANNOUNCEMENT_RECEIVED'
const SUPPORT_SAVE_ANNOUNCEMENT_FAILED = 'SUPPORT_SAVE_ANNOUNCEMENT_FAILED'
const SUPPORT_REQUEST_DASHBOARD_URL = 'SUPPORT_REQUEST_DASHBOARD_URL'
const SUPPORT_DASHBOARD_URL_RECEIVED = 'SUPPORT_DASHBOARD_URL_RECEIVED'
const SUPPORT_DASHBOARD_URL_FAILED = 'SUPPORT_DASHBOARD_URL_FAILED'
const SUPPORT_FETCH_PRACTICE_MEMBERS = 'SUPPORT_FETCH_PRACTICE_MEMBERS'
const SUPPORT_FETCH_PRACTICE_MEMBERS_RECEIVED = 'SUPPORT_FETCH_PRACTICE_MEMBERS_RECEIVED'
const SUPPORT_FETCH_PRACTICE_MEMBERS_FAILED = 'SUPPORT_FETCH_PRACTICE_MEMBERS_FAILED'
const SUPPORT_UPDATE_PRACTICE_MEMBER = 'SUPPORT_UPDATE_PRACTICE_MEMBER'
const SUPPORT_UPDATE_PRACTICE_MEMBER_RECEIVED = 'SUPPORT_UPDATE_PRACTICE_MEMBER_RECEIVED'
const SUPPORT_UPDATE_PRACTICE_MEMBER_FAILED = 'SUPPORT_UPDATE_PRACTICE_MEMBER_FAILED'
const SUPPORT_REQUEST_OWNER_DOCTORS = 'SUPPORT_REQUEST_OWNER_DOCTORS'
const SUPPORT_FETCH_DOCTOR = 'SUPPORT_FETCH_DOCTOR'
const SUPPORT_FETCH_DOCTOR_RECEIVED = 'SUPPORT_FETCH_DOCTOR_RECEIVED'
const SUPPORT_FETCH_DOCTOR_FAILED = 'SUPPORT_FETCH_DOCTOR_FAILED'
const SUPPORT_RESET_DOCTOR = 'SUPPORT_RESET_DOCTOR'
const SUPPORT_UPDATE_USER = 'SUPPORT_UPDATE_USER'
const SUPPORT_UPDATE_USER_RECEIVED = 'SUPPORT_UPDATE_USER_RECEIVED'
const SUPPORT_UPDATE_USER_FAILED = 'SUPPORT_UPDATE_USER_FAILED'
const SUPPORT_UPDATE_DOCTOR = 'SUPPORT_UPDATE_DOCTOR'
const SUPPORT_UPDATE_DOCTOR_RECEIVED = 'SUPPORT_UPDATE_DOCTOR_RECEIVED'
const SUPPORT_UPDATE_DOCTOR_FAILED = 'SUPPORT_UPDATE_DOCTOR_FAILED'
const SUPPORT_UPDATE_DOCTOR_FF = 'SUPPORT_UPDATE_DOCTOR_FF'
const SUPPORT_UPDATE_DOCTOR_FF_RECEIVED = 'SUPPORT_UPDATE_DOCTOR_FF_RECEIVED'
const SUPPORT_UPDATE_DOCTOR_FF_FAILED = 'SUPPORT_UPDATE_DOCTOR_FF_FAILED'
const SUPPORT_FETCH_DOCTOR_TRANSACTIONS = 'SUPPORT_FETCH_DOCTOR_TRANSACTIONS'
const SUPPORT_FETCH_DOCTOR_TRANSACTIONS_RECEIVED = 'SUPPORT_FETCH_DOCTOR_TRANSACTIONS_RECEIVED'
const SUPPORT_FETCH_DOCTOR_TRANSACTIONS_FAILED = 'SUPPORT_FETCH_DOCTOR_TRANSACTIONS_FAILED'
const SUPPORT_FETCH_PATIENT = 'SUPPORT_FETCH_PATIENT'
const SUPPORT_FETCH_PATIENT_RECEIVED = 'SUPPORT_FETCH_PATIENT_RECEIVED'
const SUPPORT_FETCH_PATIENT_FAILED = 'SUPPORT_FETCH_PATIENT_FAILED'
const SUPPORT_FETCH_LINKED_PATIENTS = 'SUPPORT_FETCH_LINKED_PATIENTs'
const SUPPORT_FETCH_LINKED_PATIENTS_RECEIVED = 'SUPPORT_FETCH_LINKED_PATIENTs_RECEIVED'
const SUPPORT_FETCH_LINKED_PATIENTA_FAILED = 'SUPPORT_FETCH_LINKED_PATIENTs_FAILED'
const SUPPORT_FETCH_BILLING_REPORT = 'SUPPORT_FETCH_BILLING_REPORT'
const SUPPORT_FETCH_BILLING_REPORT_RECEIVED = 'SUPPORT_FETCH_BILLING_REPORT_RECEIVED'
const SUPPORT_FETCH_BILLING_REPORT_FAILED = 'SUPPORT_FETCH_BILLING_REPORT_FAILED'
const SUPPORT_BILLING_SET_CUSTOM_ACTIVE_PATIENTS_COUNT = 'SUPPORT_BILLING_SET_CUSTOM_ACTIVE_PATIENTS_COUNT'
const SUPPORT_BILLING_CREATE_DRAFT_INVOICE = 'SUPPORT_BILLING_CREATE_DRAFT_INVOICE'
const SUPPORT_BILLING_CREATE_DRAFT_INVOICE_RECEIVED = 'SUPPORT_BILLING_CREATE_DRAFT_INVOICE_RECEIVED'
const SUPPORT_BILLING_CREATE_DRAFT_INVOICE_FAILED = 'SUPPORT_BILLING_CREATE_DRAFT_INVOICE_FAILED'
const SUPPORT_SKIP_CONFIRMATION_CODE = 'SUPPORT_SKIP_CONFIRMATION_CODE'
const SUPPORT_SKIP_CONFIRMATION_CODE_RECEIVED = 'SUPPORT_SKIP_CONFIRMATION_CODE_RECEIVED'
const SUPPORT_SKIP_CONFIRMATION_CODE_FAILED = 'SUPPORT_SKIP_CONFIRMATION_CODE_FAILED'
const SUPPORT_SAVE_ARTICLE = 'SAVE_ARTICLE'
const SUPPORT_SAVE_ARTICLE_RECEIVED = 'SAVE_ARTICLE_RECEIVED'
const SUPPORT_SAVE_ARTICLE_FAILED = 'SAVE_ARTICLE_FAILED'
const SUPPORT_DELETE_ARTICLE = 'SUPPORT_DELETE_ARTICLE'
const SUPPORT_DELETE_ARTICLE_RECEIVED = 'SUPPORT_DELETE_ARTICLE_RECEIVED'
const SUPPORT_DELETE_ARTICLE_FAILED = 'SUPPORT_DELETE_ARTICLE_FAILED'
const SUPPORT_GET_AUTH_DETAILS = 'SUPPORT_GET_AUTH_DETAILS'
const SUPPORT_GET_AUTH_DETAILS_RECEIVED = 'SUPPORT_GET_AUTH_DETAILS_RECEIVED'
const SUPPORT_GET_AUTH_DETAILS_FAILED = 'SUPPORT_GET_AUTH_DETAILS_FAILED'
const SUPPORT_UPDATE_APP_SETTINGS = 'SUPPORT_UPDATE_APP_SETTINGS'
const SUPPORT_UPDATE_APP_SETTINGS_RECEIVED = 'SUPPORT_UPDATE_APP_SETTINGS_RECEIVED'
const SUPPORT_UPDATE_APP_SETTINGS_FAILED = 'SUPPORT_UPDATE_APP_SETTINGS_FAILED'
const SUPPORT_FETCH_SYSTEM_AUTOMATIONS = 'SUPPORT_FETCH_SYSTEM_AUTOMATIONS'
const SUPPORT_FETCH_SYSTEM_AUTOMATIONS_RECEIVED = 'SUPPORT_FETCH_SYSTEM_AUTOMATIONS_RECEIVED'
const SUPPORT_FETCH_SYSTEM_AUTOMATIONS_FAILED = 'SUPPORT_FETCH_SYSTEM_AUTOMATIONS_FAILED'
const SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL = 'SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL'
const SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL_RECEIVED = 'SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL_RECEIVED'
const SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL_FAILED = 'SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL_FAILED'
const SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS = 'SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS'
const SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS_RECEIVED = 'SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS_RECEIVED'
const SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS_FAILED = 'SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS_FAILED'
const SUPPORT_BILLING_CREATE_AND_PAY_INVOICE = 'SUPPORT_BILLING_CREATE_AND_PAY_INVOICE'
const SUPPORT_BILLING_CREATE_AND_PAY_INVOICE_RECEIVED = 'SUPPORT_BILLING_CREATE_AND_PAY_INVOICE_RECEIVED'
const SUPPORT_BILLING_CREATE_AND_PAY_INVOICE_FAILED = 'SUPPORT_BILLING_CREATE_AND_PAY_INVOICE_FAILED'
const SUPPORT_CLEAR_SUPPRESSION_LIST_STATE = 'SUPPORT_CLEAR_SUPPRESSION_LIST_STATE'
const SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST = 'SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST'
const SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST_RECEIVED = 'SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST_RECEIVED'
const SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST_FAILED = 'SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST_FAILED'
const SUPPORT_FETCH_HI_PRACTICES = 'SUPPORT_FETCH_HI_PRACTICES'
const SUPPORT_FETCH_HI_PRACTICES_RECEIVED = 'SUPPORT_FETCH_HI_PRACTICES_RECEIVED'
const SUPPORT_FETCH_HI_PRACTICES_FAILED = 'SUPPORT_FETCH_HI_PRACTICES_FAILED'
const SUPPORT_UPDATE_HI_GROUPS = 'SUPPORT_UPDATE_HI_GROUPS'
const SUPPORT_UPDATE_HI_GROUPS_RECEIVED = 'SUPPORT_UPDATE_HI_GROUPS_RECEIVED'
const SUPPORT_UPDATE_HI_GROUPS_FAILED = 'SUPPORT_UPDATE_HI_GROUPS_FAILED'
const SUPPORT_TOGGLE_UPDATE_HI_GROUPS_MODAL = 'SUPPORT_TOGGLE_UPDATE_HI_GROUPS_MODAL'

const supportToggleUpdateHiGroupsModal = payload => ({
  type: SUPPORT_TOGGLE_UPDATE_HI_GROUPS_MODAL,
  payload
})

const suportUpdateHiGroups = payload => ({
  type: SUPPORT_UPDATE_HI_GROUPS,
  payload
})

const suportUpdateHiGroupsReceived = payload => ({
  type: SUPPORT_UPDATE_HI_GROUPS_RECEIVED,
  payload
})

const suportUpdateHiGroupsFailed = payload => ({
  type: SUPPORT_UPDATE_HI_GROUPS_FAILED,
  payload
})

const supportFetchHiPractices = payload => ({
  type: SUPPORT_FETCH_HI_PRACTICES,
  payload
})

const supportFetchHiPracticesReceived = payload => ({
  type: SUPPORT_FETCH_HI_PRACTICES_RECEIVED,
  payload
})

const supportFetchHiPracticesFailed = payload => ({
  type: SUPPORT_FETCH_HI_PRACTICES_FAILED,
  payload
})

const supportBillingCreateAndPayInvoice = payload => ({
  type: SUPPORT_BILLING_CREATE_AND_PAY_INVOICE,
  payload
})

const supportBillingCreateAndPayInvoiceReceived = payload => ({
  type: SUPPORT_BILLING_CREATE_AND_PAY_INVOICE_RECEIVED,
  payload
})

const supportBillingCreateAndPayInvoiceFailed = payload => ({
  type: SUPPORT_BILLING_CREATE_AND_PAY_INVOICE_FAILED,
  payload
})

const supportUpdatePatientCommunicationEmail = payload => ({
  type: SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL,
  payload
})

const supportUpdatePatientCommunicationEmailReceived = payload => ({
  type: SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL_RECEIVED,
  payload
})

const supportUpdatePatientCommunicationEmailFailed = payload => ({
  type: SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL_FAILED,
  payload
})

const supportDeleteArticle = payload => ({
  type: SUPPORT_DELETE_ARTICLE,
  payload
})

const supportDeleteArticleReceived = payload => ({
  type: SUPPORT_DELETE_ARTICLE_RECEIVED,
  payload
})

const supportDeleteArticleFailed = payload => ({
  type: SUPPORT_DELETE_ARTICLE_FAILED,
  payload
})

const fetchRejected = payload => ({
  type: FETCH_REJECTED,
  payload
})

const requestAllowedDoctors = () => ({
  type: REQUEST_ALLOWED_DOCTORS
})

const allowedDoctorsReceived = payload => ({
  type: ALLOWED_DOCTORS_RECEIVED,
  payload
})

const requestUpdateDoctorPlans = payload => ({
  type: REQUEST_UPDATE_DOCTOR_PLANS,
  payload
})

const supportRequestDoctors = payload => ({
  type: SUPPORT_REQUEST_DOCTORS,
  payload
})

const supportRequestDoctorsReceived = payload => ({
  type: SUPPORT_REQUEST_DOCTORS_RECEIVED,
  payload
})

const supportUpdatePatientDetails = payload => ({
  type: SUPPORT_UPDATE_PATIENT_DETAILS,
  payload
})

const supportUpdatePatientDetailsReceived = payload => ({
  type: SUPPORT_UPDATE_PATIENT_DETAILS_RECEIVED,
  payload
})

const supportUpdatePatientDetailsFailed = payload => ({
  type: SUPPORT_UPDATE_PATIENT_DETAILS_FAILED,
  payload
})

const supportUpdatePatientFF = payload => ({
  type: SUPPORT_UPDATE_PATIENT_FF,
  payload
})

const supportUpdatePatientFFReceived = payload => ({
  type: SUPPORT_UPDATE_PATIENT_FF_RECEIVED,
  payload
})

const supportUpdatePatientFFFailed = payload => ({
  type: SUPPORT_UPDATE_PATIENT_FF_FAILED,
  payload
})

const updateDoctorPlansReceived = payload => ({
  type: UPDATE_DOCTOR_PLANS_RECEIVED,
  payload
})

const requestCreateAllowedDoctor = payload => ({
  type: REQUEST_CREATE_ALLOWED_DOCTOR,
  payload
})

const createAllowedDoctorReceived = payload => ({
  type: CREATE_ALLOWED_DOCTOR_RECEIVED,
  payload
})

const createAllowedDoctorFailed = payload => ({
  type: CREATE_ALLOWED_DOCTOR_FAILED,
  payload
})

const requestImportAllowedDoctor = payload => ({
  type: REQUEST_IMPORT_ALLOWED_DOCTOR,
  payload
})

const importAllowedDoctorReceived = payload => ({
  type: IMPORT_ALLOWED_DOCTOR_RECEIVED,
  payload
})

const importDoctorPlansCorruptedCsv = payload => ({
  type: IMPORT_DOCTOR_PLANS_CORRUPTED_CSV,
  payload
})

const fetchDoctorSeatsByUsername = payload => ({
  type: FETCH_DOCTOR_SEATS_BY_USERNAME,
  payload
})

const fetchDoctorSeatsByUsernameReceived = payload => ({
  type: FETCH_DOCTOR_SEATS_BY_USERNAME_RECEIVED,
  payload
})

const fetchDoctorSeatsByUsernameFailed = payload => ({
  type: FETCH_DOCTOR_SEATS_BY_USERNAME_FAILED,
  payload
})

const supportFetchDoctorNotesByUserId = payload => ({
  type: SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID,
  payload
})

const supportFetchDoctorNotesByUserIdReceived = payload => ({
  type: SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID_RECEIVED,
  payload
})

const supportFetchDoctorNotesByUserIdFailed = payload => ({
  type: SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID_FAILED,
  payload
})

const supportCreateDoctorNote = payload => ({
  type: SUPPORT_CREATE_DOCTOR_NOTE,
  payload
})

const supportCreateDoctorNoteReceived = payload => ({
  type: SUPPORT_CREATE_DOCTOR_NOTE_RECEIVED,
  payload
})

const supportCreateDoctorNoteFailed = payload => ({
  type: SUPPORT_CREATE_DOCTOR_NOTE_FAILED,
  payload
})

const changeDoctorEmail = payload => ({
  type: CHANGE_DOCTOR_EMAIL,
  payload
})

const changeDoctorEmailReceived = payload => ({
  type: CHANGE_DOCTOR_EMAIL_RECEIVED,
  payload
})

const changeDoctorEmailFailed = payload => ({
  type: CHANGE_DOCTOR_EMAIL_FAILED,
  payload
})

const changePatientEmail = payload => ({
  type: CHANGE_PATIENT_EMAIL,
  payload
})

const changePatientEmailReceived = payload => ({
  type: CHANGE_PATIENT_EMAIL_RECEIVED,
  payload
})

const changePatientEmailFailed = payload => ({
  type: CHANGE_PATIENT_EMAIL_FAILED,
  payload
})

const fetchUserGroups = payload => ({
  type: FETCH_USER_GROUPS,
  payload
})

const fetchUserGroupsFailed = payload => ({
  type: FETCH_USER_GROUPS_FAILED,
  payload
})

const fetchUserGroupsReceived = payload => ({
  type: FETCH_USER_GROUPS_RECEIVED,
  payload
})

const savePromotion = payload => ({
  type: SAVE_PROMOTION,
  payload
})

const savePromotionFailed = payload => ({
  type: SAVE_PROMOTION_FAILED,
  payload
})

const savePromotionReceived = payload => ({
  type: SAVE_PROMOTION_RECEIVED,
  payload
})

const deletePromotion = payload => ({
  type: DELETE_PROMOTION,
  payload
})

const deletePromotionReceived = payload => ({
  type: DELETE_PROMOTION_RECEIVED,
  payload
})

const deletePromotionFailed = payload => ({
  type: DELETE_PROMOTION_FAILED,
  payload
})

const supportSearchPatient = payload => ({
  type: SUPPORT_SEARCH_PATIENT,
  payload
})

const supportSearchPatientReceived = payload => ({
  type: SUPPORT_SEARCH_PATIENT_RECEIVED,
  payload
})

const supportSearchPatientFailed = payload => ({
  type: SUPPORT_SEARCH_PATIENT_FAILED,
  payload
})

const supportSetSelectedPatient = payload => ({
  type: SUPPORT_SET_SELECTED_PATIENT,
  payload
})

const supportClearPatientsSearchResults = payload => ({
  type: SUPPORT_CLEAR_PATIENTS_SEARCH_RESULTS,
  payload
})

const supportFetchAnnouncements = payload => ({
  type: SUPPORT_FETCH_ANNOUNCEMENTS,
  payload
})

const supportFetchAnnouncementsReceived = payload => ({
  type: SUPPORT_FETCH_ANNOUNCEMENTS_RECEIVED,
  payload
})

const supportFetchAnnouncementsFailed = payload => ({
  type: SUPPORT_FETCH_ANNOUNCEMENTS_FAILED,
  payload
})

const supportSaveAnnouncement = payload => ({
  type: SUPPORT_SAVE_ANNOUNCEMENT,
  payload
})

const supportSaveAnnouncementReceived = payload => ({
  type: SUPPORT_SAVE_ANNOUNCEMENT_RECEIVED,
  payload
})

const supportSaveAnnouncementFailed = payload => ({
  type: SUPPORT_SAVE_ANNOUNCEMENT_FAILED,
  payload
})

const supportRequestDashboardUrl = payload => ({
  type: SUPPORT_REQUEST_DASHBOARD_URL,
  payload
})

const supportDashboardUrlReceived = payload => ({
  type: SUPPORT_DASHBOARD_URL_RECEIVED,
  payload
})

const supportDashboardUrlFailed = payload => ({
  type: SUPPORT_DASHBOARD_URL_FAILED,
  payload
})

const supportFetchPracticeMembers = payload => ({
  type: SUPPORT_FETCH_PRACTICE_MEMBERS,
  payload
})

const supportFetchPracticeMembersReceived = payload => ({
  type: SUPPORT_FETCH_PRACTICE_MEMBERS_RECEIVED,
  payload
})

const supportFetchPracticeMembersFailed = payload => ({
  type: SUPPORT_FETCH_PRACTICE_MEMBERS_FAILED,
  payload
})

const supportUpdatePracticeMember = payload => ({
  type: SUPPORT_UPDATE_PRACTICE_MEMBER,
  payload
})

const supportUpdatePracticeMemberReceived = payload => ({
  type: SUPPORT_UPDATE_PRACTICE_MEMBER_RECEIVED,
  payload
})

const supportUpdatePracticeMemberFailed = payload => ({
  type: SUPPORT_UPDATE_PRACTICE_MEMBER_FAILED,
  payload
})

const supportRequestOwnerDoctors = () => ({
  type: SUPPORT_REQUEST_OWNER_DOCTORS
})

const supportFetchDoctor = payload => ({
  type: SUPPORT_FETCH_DOCTOR,
  payload
})

const supportFetchDoctorReceived = payload => ({
  type: SUPPORT_FETCH_DOCTOR_RECEIVED,
  payload
})

const supportFetchDoctorFailed = payload => ({
  type: SUPPORT_FETCH_DOCTOR_FAILED,
  payload
})

const supportResetDoctor = () => ({
  type: SUPPORT_RESET_DOCTOR
})

const supportUpdateUser = payload => ({
  type: SUPPORT_UPDATE_USER,
  payload
})

const supportUpdateUserReceived = payload => ({
  type: SUPPORT_UPDATE_USER_RECEIVED,
  payload
})

const supportUpdateUserFailed = payload => ({
  type: SUPPORT_UPDATE_USER_FAILED,
  payload
})

const supportUpdateDoctor = payload => ({
  type: SUPPORT_UPDATE_DOCTOR,
  payload
})

const supportUpdateDoctorReceived = payload => ({
  type: SUPPORT_UPDATE_DOCTOR_RECEIVED,
  payload
})

const supportUpdateDoctorFailed = payload => ({
  type: SUPPORT_UPDATE_DOCTOR_FAILED,
  payload
})

const supportUpdateDoctorFF = payload => ({
  type: SUPPORT_UPDATE_DOCTOR_FF,
  payload
})

const supportUpdateDoctorFFReceived = payload => ({
  type: SUPPORT_UPDATE_DOCTOR_FF_RECEIVED,
  payload
})

const supportUpdateDoctorFFFailed = payload => ({
  type: SUPPORT_UPDATE_DOCTOR_FF_FAILED,
  payload
})

const supportFetchDoctorTransactions = payload => ({
  type: SUPPORT_FETCH_DOCTOR_TRANSACTIONS,
  payload
})

const supportFetchDoctorTransactionsReceived = payload => ({
  type: SUPPORT_FETCH_DOCTOR_TRANSACTIONS_RECEIVED,
  payload
})

const supportFetchDoctorTransactionsFailed = payload => ({
  type: SUPPORT_FETCH_DOCTOR_TRANSACTIONS_FAILED,
  payload
})

const supportFetchPatient = payload => ({
  type: SUPPORT_FETCH_PATIENT,
  payload
})

const supportFetchPatientReceived = payload => ({
  type: SUPPORT_FETCH_PATIENT_RECEIVED,
  payload
})

const supportFetchPatientFailed = payload => ({
  type: SUPPORT_FETCH_PATIENT_FAILED,
  payload
})

const supportFetchLinkedPatients = payload => ({
  type: SUPPORT_FETCH_LINKED_PATIENTS,
  payload
})

const supportFetchLinkedPatientsReceived = payload => ({
  type: SUPPORT_FETCH_LINKED_PATIENTS_RECEIVED,
  payload
})

const supportFetchLinkedPatientsFailed = payload => ({
  type: SUPPORT_FETCH_LINKED_PATIENTA_FAILED,
  payload
})

const supportFetchBillingReport = payload => ({
  type: SUPPORT_FETCH_BILLING_REPORT,
  payload
})

const supportFetchBillingReportReceived = payload => ({
  type: SUPPORT_FETCH_BILLING_REPORT_RECEIVED,
  payload
})

const supportFetchBillingReportFailed = payload => ({
  type: SUPPORT_FETCH_BILLING_REPORT_FAILED,
  payload
})

const supportBillingSetCustomActivePatientsCount = payload => ({
  type: SUPPORT_BILLING_SET_CUSTOM_ACTIVE_PATIENTS_COUNT,
  payload
})

const supportBillingCreateDraftInvoice = payload => ({
  type: SUPPORT_BILLING_CREATE_DRAFT_INVOICE,
  payload
})

const supportBillingCreateDraftInvoiceReceived = payload => ({
  type: SUPPORT_BILLING_CREATE_DRAFT_INVOICE_RECEIVED,
  payload
})

const supportBillingCreateDraftInvoiceFailed = payload => ({
  type: SUPPORT_BILLING_CREATE_DRAFT_INVOICE_FAILED,
  payload
})

const supportSkipConfirmationCode = payload => ({
  type: SUPPORT_SKIP_CONFIRMATION_CODE,
  payload
})

const supportSkipConfirmationCodeReceived = payload => ({
  type: SUPPORT_SKIP_CONFIRMATION_CODE_RECEIVED,
  payload
})

const supportSkipConfirmationCodeFailed = payload => ({
  type: SUPPORT_SKIP_CONFIRMATION_CODE_FAILED,
  payload
})

const supportSaveArticle = payload => ({
  type: SUPPORT_SAVE_ARTICLE,
  payload
})

const supportSaveArticleFailed = payload => ({
  type: SUPPORT_SAVE_ARTICLE_FAILED,
  payload
})

const supportSaveArticleReceived = payload => ({
  type: SUPPORT_SAVE_ARTICLE_RECEIVED,
  payload
})

const supportGetAuthDetails = payload => ({
  type: SUPPORT_GET_AUTH_DETAILS,
  payload
})

const supportGetAuthDetailsReceived = payload => ({
  type: SUPPORT_GET_AUTH_DETAILS_RECEIVED,
  payload
})

const supportGetAuthDetailsFailed = payload => ({
  type: SUPPORT_GET_AUTH_DETAILS_FAILED,
  payload
})

const supportUpdateAppSettings = payload => ({
  type: SUPPORT_UPDATE_APP_SETTINGS,
  payload
})

const supportUpdateAppSettingsReceived = payload => ({
  type: SUPPORT_UPDATE_APP_SETTINGS_RECEIVED,
  payload
})

const supportUpdateAppSettingsFailed = payload => ({
  type: SUPPORT_UPDATE_APP_SETTINGS_FAILED,
  payload
})

const supportFetchSystemAutomations = payload => ({
  type: SUPPORT_FETCH_SYSTEM_AUTOMATIONS,
  payload
})

const supportFetchSystemAutomationsReceived = payload => ({
  type: SUPPORT_FETCH_SYSTEM_AUTOMATIONS_RECEIVED,
  payload
})

const supportFetchSystemAutomationsFailed = payload => ({
  type: SUPPORT_FETCH_SYSTEM_AUTOMATIONS_FAILED,
  payload
})

const supportFetchScanSummaryVersions = payload => ({
  type: SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS,
  payload
})

const supportFetchScanSummaryVersionsReceived = payload => ({
  type: SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS_RECEIVED,
  payload
})

const supportFetchScanSummaryVersionsFailed = payload => ({
  type: SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS_FAILED,
  payload
})

const supportDeleteEmailFromSuppressionList = payload => ({
  type: SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST,
  payload
})

const supportDeleteEmailFromSuppressionListReceived = payload => ({
  type: SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST_RECEIVED,
  payload
})

const supportDeleteEmailFromSuppressionListFailed = payload => ({
  type: SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST_FAILED,
  payload
})

const supportClearSuppressionListState = payload => ({
  type: SUPPORT_CLEAR_SUPPRESSION_LIST_STATE,
  payload
})

export default {
  FETCH_REJECTED,
  REQUEST_ALLOWED_DOCTORS,
  ALLOWED_DOCTORS_RECEIVED,
  REQUEST_UPDATE_DOCTOR_PLANS,
  SUPPORT_REQUEST_DOCTORS,
  SUPPORT_REQUEST_DOCTORS_RECEIVED,
  SUPPORT_UPDATE_PATIENT_DETAILS,
  SUPPORT_UPDATE_PATIENT_DETAILS_RECEIVED,
  SUPPORT_UPDATE_PATIENT_DETAILS_FAILED,
  SUPPORT_UPDATE_PATIENT_FF,
  SUPPORT_UPDATE_PATIENT_FF_RECEIVED,
  SUPPORT_UPDATE_PATIENT_FF_FAILED,
  UPDATE_DOCTOR_PLANS_RECEIVED,
  REQUEST_IMPORT_ALLOWED_DOCTOR,
  IMPORT_ALLOWED_DOCTOR_RECEIVED,
  IMPORT_DOCTOR_PLANS_CORRUPTED_CSV,
  FETCH_DOCTOR_SEATS_BY_USERNAME,
  FETCH_DOCTOR_SEATS_BY_USERNAME_RECEIVED,
  FETCH_DOCTOR_SEATS_BY_USERNAME_FAILED,
  SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID,
  SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID_RECEIVED,
  SUPPORT_FETCH_DOCTOR_NOTES_BY_USER_ID_FAILED,
  SUPPORT_CREATE_DOCTOR_NOTE,
  SUPPORT_CREATE_DOCTOR_NOTE_RECEIVED,
  SUPPORT_CREATE_DOCTOR_NOTE_FAILED,
  CHANGE_DOCTOR_EMAIL,
  CHANGE_DOCTOR_EMAIL_FAILED,
  CHANGE_DOCTOR_EMAIL_RECEIVED,
  CHANGE_PATIENT_EMAIL,
  CHANGE_PATIENT_EMAIL_FAILED,
  CHANGE_PATIENT_EMAIL_RECEIVED,
  FETCH_USER_GROUPS,
  FETCH_USER_GROUPS_RECEIVED,
  FETCH_USER_GROUPS_FAILED,
  SAVE_PROMOTION,
  SAVE_PROMOTION_FAILED,
  SAVE_PROMOTION_RECEIVED,
  DELETE_PROMOTION,
  DELETE_PROMOTION_FAILED,
  DELETE_PROMOTION_RECEIVED,
  SUPPORT_SEARCH_PATIENT,
  SUPPORT_SEARCH_PATIENT_FAILED,
  SUPPORT_SEARCH_PATIENT_RECEIVED,
  SUPPORT_SET_SELECTED_PATIENT,
  SUPPORT_CLEAR_PATIENTS_SEARCH_RESULTS,
  SUPPORT_FETCH_ANNOUNCEMENTS,
  SUPPORT_FETCH_ANNOUNCEMENTS_RECEIVED,
  SUPPORT_FETCH_ANNOUNCEMENTS_FAILED,
  SUPPORT_SAVE_ANNOUNCEMENT,
  SUPPORT_SAVE_ANNOUNCEMENT_FAILED,
  SUPPORT_SAVE_ANNOUNCEMENT_RECEIVED,
  REQUEST_CREATE_ALLOWED_DOCTOR,
  CREATE_ALLOWED_DOCTOR_RECEIVED,
  CREATE_ALLOWED_DOCTOR_FAILED,
  SUPPORT_REQUEST_DASHBOARD_URL,
  SUPPORT_DASHBOARD_URL_RECEIVED,
  SUPPORT_DASHBOARD_URL_FAILED,
  SUPPORT_FETCH_PRACTICE_MEMBERS,
  SUPPORT_FETCH_PRACTICE_MEMBERS_RECEIVED,
  SUPPORT_FETCH_PRACTICE_MEMBERS_FAILED,
  SUPPORT_UPDATE_PRACTICE_MEMBER,
  SUPPORT_UPDATE_PRACTICE_MEMBER_RECEIVED,
  SUPPORT_UPDATE_PRACTICE_MEMBER_FAILED,
  SUPPORT_REQUEST_OWNER_DOCTORS,
  SUPPORT_FETCH_DOCTOR,
  SUPPORT_FETCH_DOCTOR_RECEIVED,
  SUPPORT_FETCH_DOCTOR_FAILED,
  SUPPORT_RESET_DOCTOR,
  SUPPORT_UPDATE_USER,
  SUPPORT_UPDATE_USER_RECEIVED,
  SUPPORT_UPDATE_USER_FAILED,
  SUPPORT_UPDATE_DOCTOR,
  SUPPORT_UPDATE_DOCTOR_RECEIVED,
  SUPPORT_UPDATE_DOCTOR_FAILED,
  SUPPORT_UPDATE_DOCTOR_FF,
  SUPPORT_UPDATE_DOCTOR_FF_RECEIVED,
  SUPPORT_UPDATE_DOCTOR_FF_FAILED,
  SUPPORT_FETCH_DOCTOR_TRANSACTIONS,
  SUPPORT_FETCH_DOCTOR_TRANSACTIONS_RECEIVED,
  SUPPORT_FETCH_DOCTOR_TRANSACTIONS_FAILED,
  SUPPORT_FETCH_PATIENT,
  SUPPORT_FETCH_PATIENT_RECEIVED,
  SUPPORT_FETCH_PATIENT_FAILED,
  SUPPORT_FETCH_LINKED_PATIENTS,
  SUPPORT_FETCH_LINKED_PATIENTS_RECEIVED,
  SUPPORT_FETCH_LINKED_PATIENTA_FAILED,
  SUPPORT_FETCH_BILLING_REPORT,
  SUPPORT_FETCH_BILLING_REPORT_FAILED,
  SUPPORT_FETCH_BILLING_REPORT_RECEIVED,
  SUPPORT_BILLING_SET_CUSTOM_ACTIVE_PATIENTS_COUNT,
  SUPPORT_BILLING_CREATE_DRAFT_INVOICE,
  SUPPORT_BILLING_CREATE_DRAFT_INVOICE_FAILED,
  SUPPORT_BILLING_CREATE_DRAFT_INVOICE_RECEIVED,
  SUPPORT_SKIP_CONFIRMATION_CODE,
  SUPPORT_SKIP_CONFIRMATION_CODE_FAILED,
  SUPPORT_SKIP_CONFIRMATION_CODE_RECEIVED,
  SUPPORT_SAVE_ARTICLE,
  SUPPORT_SAVE_ARTICLE_FAILED,
  SUPPORT_SAVE_ARTICLE_RECEIVED,
  SUPPORT_DELETE_ARTICLE,
  SUPPORT_DELETE_ARTICLE_RECEIVED,
  SUPPORT_DELETE_ARTICLE_FAILED,
  SUPPORT_GET_AUTH_DETAILS,
  SUPPORT_GET_AUTH_DETAILS_RECEIVED,
  SUPPORT_GET_AUTH_DETAILS_FAILED,
  SUPPORT_UPDATE_APP_SETTINGS,
  SUPPORT_UPDATE_APP_SETTINGS_RECEIVED,
  SUPPORT_UPDATE_APP_SETTINGS_FAILED,
  SUPPORT_FETCH_SYSTEM_AUTOMATIONS,
  SUPPORT_FETCH_SYSTEM_AUTOMATIONS_RECEIVED,
  SUPPORT_FETCH_SYSTEM_AUTOMATIONS_FAILED,
  SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL,
  SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL_RECEIVED,
  SUPPORT_UPDATE_PATIENT_COMMUNICATION_EMAIL_FAILED,
  SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS,
  SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS_RECEIVED,
  SUPPORT_FETCH_SCAN_SUMMARY_VERSIONS_FAILED,
  SUPPORT_BILLING_CREATE_AND_PAY_INVOICE,
  SUPPORT_BILLING_CREATE_AND_PAY_INVOICE_RECEIVED,
  SUPPORT_BILLING_CREATE_AND_PAY_INVOICE_FAILED,
  SUPPORT_CLEAR_SUPPRESSION_LIST_STATE,
  SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST,
  SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST_RECEIVED,
  SUPPORT_DELETE_EMAIL_FROM_SUPPRESSION_LIST_FAILED,
  SUPPORT_FETCH_HI_PRACTICES,
  SUPPORT_FETCH_HI_PRACTICES_RECEIVED,
  SUPPORT_FETCH_HI_PRACTICES_FAILED,
  SUPPORT_UPDATE_HI_GROUPS,
  SUPPORT_UPDATE_HI_GROUPS_RECEIVED,
  SUPPORT_UPDATE_HI_GROUPS_FAILED,
  SUPPORT_TOGGLE_UPDATE_HI_GROUPS_MODAL,
  supportToggleUpdateHiGroupsModal,
  suportUpdateHiGroups,
  suportUpdateHiGroupsReceived,
  suportUpdateHiGroupsFailed,
  supportFetchHiPractices,
  supportFetchHiPracticesReceived,
  supportFetchHiPracticesFailed,
  supportClearSuppressionListState,
  supportDeleteEmailFromSuppressionList,
  supportDeleteEmailFromSuppressionListReceived,
  supportDeleteEmailFromSuppressionListFailed,
  supportBillingCreateAndPayInvoice,
  supportBillingCreateAndPayInvoiceReceived,
  supportBillingCreateAndPayInvoiceFailed,
  supportFetchScanSummaryVersions,
  supportFetchScanSummaryVersionsReceived,
  supportFetchScanSummaryVersionsFailed,
  supportUpdatePatientCommunicationEmail,
  supportUpdatePatientCommunicationEmailReceived,
  supportUpdatePatientCommunicationEmailFailed,
  supportDeleteArticle,
  supportDeleteArticleReceived,
  supportDeleteArticleFailed,
  supportSaveArticle,
  supportSaveArticleFailed,
  supportSaveArticleReceived,
  fetchRejected,
  requestAllowedDoctors,
  allowedDoctorsReceived,
  requestUpdateDoctorPlans,
  supportRequestDoctors,
  supportRequestDoctorsReceived,
  supportUpdatePatientDetails,
  supportUpdatePatientDetailsReceived,
  supportUpdatePatientDetailsFailed,
  supportUpdatePatientFF,
  supportUpdatePatientFFReceived,
  supportUpdatePatientFFFailed,
  updateDoctorPlansReceived,
  requestCreateAllowedDoctor,
  createAllowedDoctorReceived,
  createAllowedDoctorFailed,
  requestImportAllowedDoctor,
  importAllowedDoctorReceived,
  importDoctorPlansCorruptedCsv,
  fetchDoctorSeatsByUsername,
  fetchDoctorSeatsByUsernameReceived,
  fetchDoctorSeatsByUsernameFailed,
  supportFetchDoctorNotesByUserId,
  supportFetchDoctorNotesByUserIdReceived,
  supportFetchDoctorNotesByUserIdFailed,
  supportCreateDoctorNote,
  supportCreateDoctorNoteReceived,
  supportCreateDoctorNoteFailed,
  changeDoctorEmail,
  changeDoctorEmailReceived,
  changeDoctorEmailFailed,
  changePatientEmail,
  changePatientEmailReceived,
  changePatientEmailFailed,
  fetchUserGroups,
  fetchUserGroupsFailed,
  fetchUserGroupsReceived,
  savePromotion,
  savePromotionFailed,
  savePromotionReceived,
  deletePromotion,
  deletePromotionReceived,
  deletePromotionFailed,
  supportSearchPatient,
  supportSearchPatientReceived,
  supportSearchPatientFailed,
  supportFetchLinkedPatients,
  supportFetchLinkedPatientsReceived,
  supportFetchLinkedPatientsFailed,
  supportSetSelectedPatient,
  supportClearPatientsSearchResults,
  supportFetchAnnouncements,
  supportFetchAnnouncementsReceived,
  supportFetchAnnouncementsFailed,
  supportSaveAnnouncement,
  supportSaveAnnouncementFailed,
  supportSaveAnnouncementReceived,
  supportRequestDashboardUrl,
  supportDashboardUrlReceived,
  supportDashboardUrlFailed,
  supportFetchPracticeMembers,
  supportFetchPracticeMembersReceived,
  supportFetchPracticeMembersFailed,
  supportUpdatePracticeMember,
  supportUpdatePracticeMemberReceived,
  supportUpdatePracticeMemberFailed,
  supportRequestOwnerDoctors,
  supportFetchDoctor,
  supportFetchDoctorReceived,
  supportFetchDoctorFailed,
  supportResetDoctor,
  supportUpdateUser,
  supportUpdateUserReceived,
  supportUpdateUserFailed,
  supportUpdateDoctor,
  supportUpdateDoctorReceived,
  supportUpdateDoctorFailed,
  supportUpdateDoctorFF,
  supportUpdateDoctorFFReceived,
  supportUpdateDoctorFFFailed,
  supportFetchDoctorTransactions,
  supportFetchDoctorTransactionsReceived,
  supportFetchDoctorTransactionsFailed,
  supportFetchPatient,
  supportFetchPatientReceived,
  supportFetchPatientFailed,
  supportFetchBillingReport,
  supportFetchBillingReportReceived,
  supportFetchBillingReportFailed,
  supportBillingSetCustomActivePatientsCount,
  supportBillingCreateDraftInvoice,
  supportBillingCreateDraftInvoiceFailed,
  supportBillingCreateDraftInvoiceReceived,
  supportSkipConfirmationCode,
  supportSkipConfirmationCodeReceived,
  supportSkipConfirmationCodeFailed,
  supportGetAuthDetails,
  supportGetAuthDetailsReceived,
  supportGetAuthDetailsFailed,
  supportUpdateAppSettings,
  supportUpdateAppSettingsReceived,
  supportUpdateAppSettingsFailed,
  supportFetchSystemAutomations,
  supportFetchSystemAutomationsReceived,
  supportFetchSystemAutomationsFailed
}
