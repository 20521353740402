import { useDispatch, useSelector } from 'react-redux'
import usePracticeConfiguration from './usePracticeConfiguration'
import { PMS } from '../consts'
import { useCallback, useEffect } from 'react'
import { validateTokenExpiration } from 'utils/authUtils'
import actions from 'actions'
import { HAS_STATIC_DATA, PMS_INTEGRATIONS } from 'consts/pmsConsts'
import DolphinNewAppointment from 'components/Patients/PatientCard/AppointmentsTab/PmsNewAppointment/DolphinNewAppointment'
import DolphinIcon from 'components/common/icons/DolphinIcon'

export default () => {
  const dispatch = useDispatch()
  const { ownerAppFeatureFlags } = usePracticeConfiguration()

  const doctorExternalIds = useSelector(state =>
    JSON.parse((state.practiceReducer.accountOwner || state.profileReducer?.doctor)?.user?.externalIds || '{}')
  )
  const { pmsType, hasFetchedStaticData, appointmentTypes } = useSelector(state => state.pmsReducer)

  const getPmsKey = () => {
    const pmsKey = Object.keys(doctorExternalIds).find(key => Object.values(PMS.PMS_INTEGRATIONS).includes(key))

    return !!pmsKey && !!doctorExternalIds[pmsKey] ? pmsKey : null
  }

  const doctorPms = getPmsKey()

  const patientExternalIds = useSelector(state =>
    JSON.parse(state.patientsReducer?.patientCard?.patient?.user?.externalIds || '{}')
  )

  const getPmsToken = useCallback(
    () => validateTokenExpiration(localStorage.getItem(`${doctorPms}_token`)),
    [doctorPms]
  )
  const renderPmsNewAppointment = useCallback(
    props => {
      switch (pmsType) {
        case PMS_INTEGRATIONS.dolphin:
          return <DolphinNewAppointment {...props} />
        default:
          return null
      }
    },
    [pmsType]
  )
  const renderPmsIcon = useCallback(() => {
    switch (pmsType) {
      case PMS_INTEGRATIONS.dolphin:
        return <DolphinIcon />
      default:
        return <></>
    }
  }, [pmsType])
  const authenticatePms = useCallback(() => {
    const pmsToken = getPmsToken()
    dispatch(actions.pmsAuthentication({ pmsType: doctorPms, pmsToken }))
  }, [dispatch, doctorPms, getPmsToken])

  const loadPmsStaticData = useCallback(() => {
    if (getPmsToken() && HAS_STATIC_DATA.includes(pmsType) && !hasFetchedStaticData) {
      dispatch(actions.pmsFetchStaticData())
    }
  }, [dispatch, getPmsToken, hasFetchedStaticData, pmsType])

  const getAppointmentType = useCallback(
    apt => appointmentTypes?.find(aptType => aptType.id === apt.appointmentTypeId)?.name,
    [appointmentTypes]
  )

  useEffect(() => {
    if (!pmsType && !!doctorPms) {
      dispatch(actions.setPmsType(doctorPms))
    }
  }, [dispatch, doctorPms, pmsType])

  return {
    pmsIntegrationsFF: ownerAppFeatureFlags.pmsIntegrations,
    doctorExternalIds,
    doctorPms,
    patientExternalIds,
    patientPmsID: patientExternalIds[doctorPms],
    renderPmsNewAppointment,
    authenticatePms,
    loadPmsStaticData,
    pmsType,
    getPmsToken,
    renderPmsIcon,
    getAppointmentType
  }
}
