import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedHeading16 from 'components/common/text/DazzedHeading16'
import BaseModal from 'components/common/modals/BaseModal'
import { isMobile } from 'utils/mobileUtils'
import ModalContent from './ModalContent'
import ModalContentMobile from './ModalContent.mobile'
import { scrollBar } from 'styles/theme'

const useStyles = makeStyles({
  container: {
    minWidth: isMobile() ? 0 : 900,
    padding: isMobile() ? '30px 10px 10px 10px' : '30px 30px 20px 30px',
    height: isMobile() ? '80%' : 'auto'
  },
  title: {
    marginBottom: 20
  },
  subtitle: {
    marginBottom: 15
  },
  content: {
    ...scrollBar
  }
})

const BroadcastComparisonModal = ({ isOpen, handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleClose}
      className={classes.container}
      primaryLabel={t('pages.patients.selectedPatient.chat.comparisonModal.gotIt')}
      buttonsHeight="36px"
      buttonsWidth={isMobile() ? '100%' : '128px'}
      onPrimaryBtnClick={handleClose}
      largerButtons={true}
      smothBorders
      contentClassName={classes.content}
      primaryButtonId="broadcastComparisonModal-confirm"
    >
      <DazzedHeading16 className={classes.title}>
        {t('pages.patients.selectedPatient.chat.comparisonModal.title')}
      </DazzedHeading16>
      {!isMobile() && (
        <DazzedParagraph14 className={classes.subtitle}>
          {t('pages.patients.selectedPatient.chat.comparisonModal.subtitle')}
        </DazzedParagraph14>
      )}
      {isMobile() ? <ModalContentMobile /> : <ModalContent />}
    </BaseModal>
  )
}

export default BroadcastComparisonModal
