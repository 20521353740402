import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { Check2, Close2, ResolveLead, ScanSent, SendScanReminder, SendSelfiesReminder } from 'components/common/icons'
import DazzedParagraph11 from 'components/common/text/DazzedParagraph11'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'

const useDashboardStyles = makeStyles({
  gridContainer: {
    width: 'auto',
    cursor: 'pointer'
  },
  approveActionLabel: {
    color: 'var(--text-color-33)',
    textDecoration: 'underline'
  },
  denyActionLabel: {
    color: 'var(--text-color-17)',
    textDecoration: 'underline'
  },
  opsActionLabel: {
    color: 'var(--text-color-3)',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  doctorActionLabel: {
    color: 'var(--text-color-25)',
    textDecoration: 'underline',
    cursor: 'pointer',
    wordWrap: 'break-word'
  },
  patientNotSignedUpText: {
    color: 'var(--text-color-14)',
    userSelect: 'none'
  },
  mr: {
    marginRight: 7
  },
  scanSent: {
    padding: '5px 5px 0px 0px'
  },
  clickable: {
    cursor: 'pointer'
  },
  resolveLeadVertically: {
    padding: 5
  },
  resolveLeadHorizontally: {
    paddingTop: 4
  }
})

const RCDashboardActions = ({
  lead,
  isLeadBeingSaved,
  onActionSelected,
  LEAD_ACTIONS,
  withAdminActions = false,
  analyticsSource,
  alignment
}) => {
  const classes = useDashboardStyles()
  const { t } = useTranslation()

  const patient = useMemo(() => lead?.patient, [lead])
  const alignVertically = useMemo(() => alignment === 'column', [alignment])
  const reminderIcon = useMemo(() => {
    switch (lead.status) {
      case 'waitingForScope':
      case 'newLead':
        return <SendSelfiesReminder />
      case 'readyToScan':
        return <SendScanReminder />
      default:
    }
  }, [lead])

  const sendReminderType = useMemo(
    () =>
      lead.status === 'waitingForScope'
        ? 'sendSelfiesReminder'
        : lead.status === 'readyToScan'
        ? 'sendScanReminder'
        : 'sendSelfiesReminder',
    [lead]
  )

  const openPatientChat = useCallback(
    action => {
      trackEvent(`RC dashboard - lead action click`, {
        action,
        leadId: lead.id,
        analyticsViewMode: 'doctor',
        analyticsSource
      })
      window.open(`${window.location.origin}/patients/${lead.id}?preSetTextType=${action}`, '_blank')
    },
    [lead, analyticsSource]
  )

  const actions = useMemo(
    () => [
      {
        condition: () => lead.conversionStatus === 'pending',
        component: (
          <Grid container direction="row" className={classes.gridContainer} spacing={2}>
            <Grid item>
              <Grid container direction="row">
                <Grid item className={classes.mr}>
                  <Close2 color={'var(--text-color-17)'} />
                </Grid>
                <Grid item onClick={() => onActionSelected({ lead, action: LEAD_ACTIONS.DENY })}>
                  <DazzedParagraph12 className={classes.denyActionLabel} spacing={2}>
                    {t(`pages.rcDashboard.actions.deny`)}
                  </DazzedParagraph12>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row">
                <Grid item className={classes.mr}>
                  <Check2 color="#57B63F" />
                </Grid>
                <Grid item onClick={() => onActionSelected({ lead, action: LEAD_ACTIONS.APPROVE })}>
                  <DazzedParagraph12 className={classes.approveActionLabel}>
                    {t(`pages.rcDashboard.actions.approve`)}
                  </DazzedParagraph12>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      },
      {
        condition: () => lead.conversionStatus !== 'pending' && lead.conversionStatus !== 'denied' && withAdminActions,
        component: (
          <>
            {lead.rcData.withShipping && (
              <Grid item>
                <Grid container direction="row">
                  <Grid item onClick={() => onActionSelected({ lead, action: LEAD_ACTIONS.SHIPPING_DETAILS })}>
                    <DazzedParagraph12 className={classes.opsActionLabel}>
                      {t(`pages.rcDashboard.actions.shippingDetails`)}
                    </DazzedParagraph12>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item>
              <Grid container direction="row">
                <Grid item onClick={() => onActionSelected({ lead, action: LEAD_ACTIONS.OPS_DETAILS })}>
                  <DazzedParagraph12 className={classes.opsActionLabel}>
                    {t(`pages.rcDashboard.actions.opsDetails`)}
                  </DazzedParagraph12>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      },
      {
        condition: () =>
          lead.conversionStatus !== 'pending' &&
          lead.conversionStatus !== 'denied' &&
          !withAdminActions &&
          (!patient || patient.grinScans.items?.length === 0),
        component: (
          <>
            {patient ? (
              <Grid
                container
                wrap="nowrap"
                direction="row"
                alignItems="center"
                onClick={() => openPatientChat(sendReminderType)}
              >
                <Grid item className={classes.clickable}>
                  {reminderIcon}
                </Grid>
                <Grid item>
                  <DazzedParagraph14 className={classes.doctorActionLabel}>
                    {t(`pages.rcDashboard.actions.${sendReminderType}`)}
                  </DazzedParagraph14>
                </Grid>
              </Grid>
            ) : (
              <Grid item>
                <DazzedParagraph11 thickness="bold" className={classes.patientNotSignedUpText}>
                  {t('pages.rcDashboard.actions.patientNotSignedUp')}
                </DazzedParagraph11>
              </Grid>
            )}
          </>
        )
      },
      {
        condition: () => patient?.grinScans?.items?.length >= 1,
        component: (
          <Grid
            container
            direction={alignVertically ? 'column' : 'row'}
            justifyContent={alignVertically ? 'center' : 'flex-start'}
            alignItems={alignVertically ? 'flex-start' : 'center'}
            spacing={alignVertically ? 0 : 1}
          >
            <Grid item onClick={() => onActionSelected({ lead, action: LEAD_ACTIONS.RESOLVE_LEAD })}>
              <Grid container>
                <Grid
                  item
                  className={alignVertically ? classes.resolveLeadVertically : classes.resolveLeadHorizontally}
                >
                  <ResolveLead />
                </Grid>
                <Grid item>
                  <DazzedParagraph14 className={classes.doctorActionLabel}>
                    {t('pages.rcDashboard.actions.resolveLead')}
                  </DazzedParagraph14>
                </Grid>
              </Grid>
            </Grid>
            <Grid item onClick={() => openPatientChat('viewScan')} style={!alignVertically ? { paddingBottom: 8 } : {}}>
              <Grid container alignItems="center">
                <Grid item className={classes.scanSent}>
                  <ScanSent color={'var(--text-color-25)'} />
                </Grid>
                <Grid item>
                  <DazzedParagraph14 className={classes.doctorActionLabel}>
                    {t('pages.rcDashboard.actions.viewScan')}
                  </DazzedParagraph14>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    ],
    [
      classes,
      t,
      lead,
      patient,
      reminderIcon,
      sendReminderType,
      alignVertically,
      onActionSelected,
      LEAD_ACTIONS,
      withAdminActions,
      openPatientChat
    ]
  )
  const activeAction = useMemo(() => actions.find(action => action.condition()), [actions])

  return isLeadBeingSaved ? <CircularProgress color="primary" /> : activeAction.component
}

export default RCDashboardActions
