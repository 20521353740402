import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import SelectInput from 'components/common/SelectInput'
import useRcShipping from '../useRcShipping'
import FormInput from 'components/common/FormInput'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import TrackingUrl from './TrackingUrl'
import { trackEvent } from 'utils/analyticsUtils'

const ShippingStatus = ({ lead, values, shipbobIntegrationActive, currentShippingStatus }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { shippingStatusOptions } = useRcShipping()

  const isSavingStatus = useSelector(state => state.rcDashboardReducer.isSaving)

  const handleSave = useCallback(() => {
    if (!lead) {
      return
    }

    trackEvent('RC Dashboard - shipping details saved manually')

    const rcData = JSON.parse(lead.rcData || '{}')
    dispatch(
      Actions.updateRCLead({
        id: lead.id,
        _version: lead._version,
        rcData: JSON.stringify({
          ...rcData,
          shippingDetails: {
            ...(rcData.shippingDetails || {}),
            shipbobStatusInformation: '',
            status: values.shippingStatus?.value,
            trackingNumber: values.trackingNumber
          }
        })
      })
    )
  }, [dispatch, values, lead])

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <SelectInput
          label={t('pages.rcDashboard.dialogs.shippingDetails.shippingStatus')}
          style={{ bright: true, thick: true }}
          value={values.shippingStatus.label}
          values={shippingStatusOptions.map(option => option.label)}
          onChange={({ value }) => {
            values.setShippingStatus(shippingStatusOptions.find(option => option.label === value))
          }}
          keepErrorContainerWhenInactive={false}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          title={t('pages.rcDashboard.dialogs.shippingDetails.trackingNumber')}
          style={{ bright: true, thick: true }}
          value={values.trackingNumber}
          setValue={values.setTrackingNumber}
        />
      </Grid>
      {currentShippingStatus !== values.shippingStatus?.value && (
        <Grid item xs={12}>
          <DazzedParagraph12>
            {t(
              shipbobIntegrationActive
                ? 'pages.rcDashboard.dialogs.shippingDetails.manualUpdateEmailsResendWarning'
                : 'pages.rcDashboard.dialogs.shippingDetails.manualUpdateEmailsWontBeSentWarning'
            )}
          </DazzedParagraph12>
        </Grid>
      )}
      {values.trackingUrl && (
        <Grid item xs={12}>
          <TrackingUrl href={values.trackingUrl} />
        </Grid>
      )}
      {shipbobIntegrationActive && (
        <Grid item xs={12}>
          <DazzedParagraph12>
            {t('pages.rcDashboard.dialogs.shippingDetails.manualUpdateIntegrationActiveWarning')}
          </DazzedParagraph12>
        </Grid>
      )}
      <Grid item xs={12}>
        <PrimaryButton isLoading={isSavingStatus} width={130} label={t('general.save')} onClick={handleSave} />
      </Grid>
    </Grid>
  )
}

export default ShippingStatus
