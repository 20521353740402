import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import OggHeading40 from 'components/common/text/OggHeading40'
import BulkInvite from './BulkInvite/BulkInvite'
import BlueDotSettings from './BlueDotSettings/BlueDotSettings'
import useRolePermissions from 'hooks/useRolePermissions'
import { Grid } from '@material-ui/core'
import UrgentScan from './UrgentScan/UrgentScanSettings'

const useStyles = makeStyles(theme => ({
  root: {
    width: 538,
    paddingBottom: 50
  },
  heading: {
    marginBottom: 20
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: 'var(--border-color-13)',
    marginBottom: 35
  }
}))

const AdvancedActions = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { permissions } = useRolePermissions()
  const actions = useMemo(
    () => [
      {
        type: 'bulkInvite',
        component: BulkInvite,
        condition: () => true
      },
      {
        type: 'blueDot',
        component: BlueDotSettings,
        condition: () => permissions.updateBlueDotBehaviour
      },
      {
        type: 'urgenScan',
        component: UrgentScan,
        condition: () => permissions.toggleUrgentScanOption
      }
    ],
    [permissions.updateBlueDotBehaviour, permissions.toggleUrgentScanOption]
  )

  return (
    <div className={classes.root}>
      <OggHeading40 textAlign="left" className={classes.heading}>
        {t('pages.accountSettings.advancedActions.title')}
      </OggHeading40>
      <div className={classes.divider} />
      <Grid container direction="column" spacing={6}>
        {actions
          .filter(action => action.condition())
          .map((action, i) => (
            <Grid item key={`advnaced-action-${i}`} id={`advanced-action-${action.type}`}>
              {React.createElement(action.component, {
                key: action.type
              })}
            </Grid>
          ))}
      </Grid>
    </div>
  )
}

export default AdvancedActions
