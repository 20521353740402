import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedHeading16 from 'components/common/text/DazzedHeading16'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { SmallSearch as SmallSearchIcon } from 'components/common/icons'
import { Grid, Divider } from '@material-ui/core'
import SavedRepliesList from '../SavedReplies/SavedRepliesList'
import { selectCustomTemplates, selectSystemTemplates } from 'selectors/profileSelector'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#FFFFFF',
    padding: '10px 0'
  },
  actionsContainer: {
    backgroundColor: '#FFFFFF',
    paddingTop: 20
  },
  header: {
    paddingLeft: 30,
    height: 50
  },
  backIcon: {
    marginRight: 15
  },
  searchSection: {
    margin: '10px 15px 10px 30px'
  },
  inputContainer: {
    borderRadius: 28,
    backgroundColor: 'var(--bg-color-36)',
    height: 36
  },
  searchInput: {
    fontFamily: 'Dazzed',
    fontSize: 14,
    background: 'transparent',
    border: 'none',
    width: '100%',
    '&:focus': {
      outline: 'none'
    }
  },
  emptyState: {
    color: 'var(--text-color-11)',
    textAlign: 'center',
    margin: '30px 50px'
  },
  divider: {
    margin: '10px 20px 0 30px'
  },
  selectedTemplateTitle: {
    margin: '20px 40px 10px 40px'
  },
  selectedTemplateContent: {
    padding: '0px 40px 10px 40px'
  },
  line: {
    wordBreak: 'break-word'
  },
  buttonContainer: {
    marginTop: 20,
    height: 82
  }
}))

const TemplatesModal = ({ open = false, onClose = () => {}, selectedTemplate, setSelectedTemplate, onSelect }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [filter, setFilter] = useState('')
  const customTemplates = useSelector(selectCustomTemplates)
  const systemTemplates = useSelector(selectSystemTemplates)

  const templates = useMemo(() => (systemTemplates ?? []).concat(customTemplates), [systemTemplates, customTemplates])

  const filterTemplates = useCallback(
    (type, templates) =>
      templates
        ?.filter(
          template =>
            template?.type === type && template?.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
        )
        .sort((a, b) => a.title.localeCompare(b.title)) || [],
    [filter]
  )

  const handleSearchInputChange = useCallback(({ target }) => {
    setFilter(target.value)
  }, [])

  const handleClose = useCallback(() => {
    onClose()
    setSelectedTemplate(null)
    setFilter('')
  }, [onClose, setSelectedTemplate])

  const handleSelectTemplate = useCallback(
    template => {
      onClose()
      onSelect(template)
    },
    [onClose, onSelect]
  )

  return (
    <BaseModal
      fullScreen
      variant="fullscreen"
      open={open}
      handleClose={handleClose}
      actionsClassName={classes.actionsContainer}
      contentClassName={classes.container}
      primaryLabel={selectedTemplate ? t('general.select') : ''}
      onPrimaryBtnClick={() => handleSelectTemplate(selectedTemplate)}
      largerButtons
      refTrigger={selectedTemplate}
    >
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" className={classes.header}>
            {selectedTemplate && (
              <ArrowBackRoundedIcon onClick={() => setSelectedTemplate(null)} className={classes.backIcon} />
            )}
            <DazzedHeading16>
              {selectedTemplate
                ? t('pages.patients.selectedPatient.chat.templates.backToSavedReplies')
                : t('pages.patients.selectedPatient.chat.templates.title')}
            </DazzedHeading16>
          </Grid>
          {!selectedTemplate && (
            <>
              <Grid item xs={12} className={classes.searchSection}>
                <Grid container className={classes.inputContainer} alignItems="center">
                  <Grid item xs={2}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <SmallSearchIcon />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <input
                      className={classes.searchInput}
                      type="text"
                      placeholder={t('pages.patients.selectedPatient.chat.templates.searchTemplatesPlaceholder')}
                      value={filter}
                      onChange={handleSearchInputChange}
                      onKeyDown={e => e.stopPropagation()}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <SavedRepliesList
                templates={templates}
                selected={selectedTemplate}
                setSelected={setSelectedTemplate}
                filterTemplates={filterTemplates}
              />
            </>
          )}
          {selectedTemplate && (
            <>
              <Divider className={classes.divider} />
              <Grid container direction="column">
                <Grid item xs={12}>
                  <DazzedHeading16 className={classes.selectedTemplateTitle}>{selectedTemplate.title}</DazzedHeading16>
                  <Grid container className={classes.selectedTemplateContent}>
                    {selectedTemplate?.text.split('\n').map((line, index) => (
                      <DazzedParagraph14 key={index} className={classes.line}>
                        {line || <br />}
                      </DazzedParagraph14>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default TemplatesModal
