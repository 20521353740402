import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { CircularProgress, Grid } from '@material-ui/core'
import { AnnouncementsAudienceOptions, TYPES } from 'consts/announcementConsts'
import SelectInput from 'components/common/SelectInput'
import { actionTypes } from 'consts/automatedActionConsts'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const ShowAnnouncement = ({ onChange = () => {}, params = {} }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isLoading, data: announcements } = useSelector(state => state.supportReducer.announcements.list)

  const filteredAnnouncements = useMemo(
    () =>
      announcements.filter(announcement =>
        announcement.conditions.includes(AnnouncementsAudienceOptions.PATIENTS.value)
      ),
    [announcements]
  )

  const announcementOptions = useMemo(
    () =>
      filteredAnnouncements.map(announcement => {
        const content = JSON.parse(announcement.content || '[]')?.[0]
        const announcementTypeLabel = Object.values(TYPES).find(type => type.value === announcement.type)?.label
        return {
          value: announcement.id,
          label: `${content.title} (${announcementTypeLabel})`
        }
      }),
    [filteredAnnouncements]
  )

  const handleChange = useCallback(
    option => {
      onChange({
        type: actionTypes.ShowAnnouncement,
        params: {
          announcementId: option.value,
          announcementTitle: announcementOptions.find(opt => opt.value === option.value)?.label
        }
      })
    },
    [onChange, announcementOptions]
  )

  useEffect(() => {
    dispatch(Actions.supportFetchAnnouncements())
  }, [dispatch])

  return (
    <Grid container direction="column">
      <DazzedParagraph14 strong>
        {t('pages.accountSettings.automations.editorModal.selectAnnouncementLabel')}
      </DazzedParagraph14>
      {isLoading ? (
        <Grid item>
          <CircularProgress size={16} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <SelectInput
            options={announcementOptions}
            value={params?.announcementId}
            onChange={handleChange}
            style={{ bright: true, thick: true }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default ShowAnnouncement
