import Actions from 'actions'
import { SPECIAL_PROVIDERS, TreatmentSTLsStatuses } from 'components/Patients/TreatmentTracker/txTrackerConsts'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

export default ({ setIsUploaderComponentLoading, patientId, treatmentId, treatmentType, txTrackerStls, mode }) => {
  const DROPZONE_CONTAINER_ID = 'dropzoneContainer'
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [provider, setProvider] = useState(null)
  const [invalidFiles, setInvalidFiles] = useState(null)
  const [isUploadCompletedOpen, setIsUploadCompletedOpen] = useState(false)

  const driveUploaderId = useSelector(state => state.appReducer.appconfig?.webapp?.driveUploaderId)

  const driveFolderName = useMemo(
    () => `@txTracker@${patientId}@${treatmentId}@${provider}@${treatmentType}`,
    [patientId, treatmentId, provider, treatmentType]
  )

  const { status: treatmentStlsStatus, stls } = useMemo(() => JSON.parse(txTrackerStls || '{}'), [txTrackerStls])

  const providerOptions = useMemo(
    () => [
      //TODO: WE NEED TO EDIT THE REGEXPS BASED ON THE STLS, IGNORE AT THE MOMENT
      { label: '3M', value: '3m', validationRegex: /^[\w\-. ]+$/ },
      { label: 'Spark', value: 'spark', validationRegex: /^[\w\-. ]+$/ },
      { label: 'uLab', value: 'uLab', validationRegex: /^[\w\-. ]+$/ },
      { label: 'Other', value: 'other', validationRegex: /^(?!.*\bSTAGING\b)[\w\-. ]+$/ }
    ],
    []
  )

  const handleRequestProviderSTLs = useCallback(
    () => dispatch(Actions.requestProviderStls({ patientId, provider })),
    [dispatch, provider, patientId]
  )

  const handleDriveUploaderInitEvent = useCallback(
    ({ status, results, instance }) => {
      setIsUploaderComponentLoading(false)
      document.getElementsByClassName('uploader__heading')[0].innerText = t(
        'dialogs.patientInfo.txTrackerSetup.dragAndDropHere'
      )
      instance.setCustomSubfolderName(driveFolderName)
      instance.setHook('beforeupload', (done, files) => {
        const invalidFiles = Object.values(files).filter(f => {
          const providerData = providerOptions.find(p => p.value === provider)
          return providerData && !providerData.validationRegex.test(f.name)
        })

        if (invalidFiles.length > 0) {
          setInvalidFiles(invalidFiles)
        } else {
          done()
        }
      })
    },
    [driveFolderName, provider, providerOptions, setIsUploaderComponentLoading, t]
  )

  const handleDriveUploaderDoneEvent = useCallback(
    ({ status, results, instance }) => {
      setIsUploadCompletedOpen(true)
      dispatch(
        Actions.updateTreatment({
          treatmentId,
          txTrackerStls: JSON.stringify({ stls, status: TreatmentSTLsStatuses.PendingFilesTransfer })
        })
      )
    },
    [dispatch, treatmentId, stls]
  )

  const driveUploaderCallbackFunc = useCallback(
    (status, results, instance) => {
      switch (status) {
        case 'init': {
          handleDriveUploaderInitEvent({ status, results, instance })
          break
        }
        case 'done': {
          handleDriveUploaderDoneEvent({ status, results, instance })
          break
        }
        default: {
          break
        }
      }
    },
    [handleDriveUploaderDoneEvent, handleDriveUploaderInitEvent]
  )

  // Unfortunately we have to do all kind of shit in order to work with the DriveUploader component.
  // For each provider change we need to completely unload and reload the DriveUploader component & Script in order for the
  // 'init' event to be called and set the relevant subFolderName (which relies on provider and patient's state)
  useEffect(() => {
    const existingDriveUploaderScript = document.getElementById('driveUploaderScript')
    const driveUploaderComponent = document.getElementById('driveUploaderComponent')

    if (existingDriveUploaderScript) {
      existingDriveUploaderScript.remove()
      window.driveUploaderCallbackFunc = null
      driveUploaderComponent?.remove()
    }
    if ((provider && !SPECIAL_PROVIDERS.includes(provider)) || mode === 'provider') {
      const head = document.querySelector('head')
      const driveUploaderScript = document.createElement('script')
      const driveUploaderCallbackScript = document.createElement('script')

      driveUploaderScript.id = 'driveUploaderScript'
      window.driveUploaderCallbackFunc = driveUploaderCallbackFunc
      driveUploaderScript.src = `https://driveuploader.com/upload/${driveUploaderId}/embed.js?callback=driveUploaderCallbackFunc`

      driveUploaderCallbackScript.innerHTML = driveUploaderCallbackFunc.toString()

      const fileUploadDivToAppend = document.createElement('div')
      fileUploadDivToAppend.id = 'driveUploaderComponent'
      fileUploadDivToAppend.className = 'driveuploader-fill'
      fileUploadDivToAppend.setAttribute('style', 'height: 100%; width: 100%')

      const dropzone = document.getElementById(DROPZONE_CONTAINER_ID)

      dropzone.appendChild(fileUploadDivToAppend)
      head.appendChild(driveUploaderScript)
      head.appendChild(driveUploaderCallbackScript)
    }
  }, [driveUploaderCallbackFunc, provider, driveUploaderId, mode, DROPZONE_CONTAINER_ID])

  return {
    DROPZONE_CONTAINER_ID,
    provider,
    setProvider,
    invalidFiles,
    setInvalidFiles,
    isUploadCompletedOpen,
    setIsUploadCompletedOpen,
    handleRequestProviderSTLs,
    providerOptions,
    treatmentStlsStatus,
    stls
  }
}
