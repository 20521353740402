import React, { useEffect, useRef } from 'react'
import { drawBackgroundWithLayers, initCanvas } from 'utils/canvasUtils'
import { getObjectUrl, loadImage } from 'utils/mediaUtils'

const PreventiveLayers = ({
  backgroundImageUrl,
  preventiveImages,
  position,
  displayedLayers,
  onLoad = () => {},
  className
}) => {
  const canvasRef = useRef()

  useEffect(() => {
    const drawPreventiveLayers = async () => {
      let canvas = canvasRef.current
      initCanvas({ canvas })

      const backgroundImagePromise = loadImage(backgroundImageUrl)

      const [backgroundImage, ...layersImages] = await Promise.all([
        backgroundImagePromise,
        ...displayedLayers.map(layer => loadImage(getObjectUrl(preventiveImages[position]?.[layer])))
      ])

      drawBackgroundWithLayers({
        canvas,
        backgroundImage,
        layersImages,
        onLoad
      })
    }

    drawPreventiveLayers()
  }, [backgroundImageUrl, displayedLayers, onLoad, position, preventiveImages])

  return <canvas id="preventive-layers-canvas" ref={canvasRef} className={className} />
}

export default React.memo(PreventiveLayers)
