import { useCallback } from 'react'
import { ConditionTypes } from 'consts/autochartConsts'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation()

  const getPropertyValueDescription = useCallback(
    (conditionType, value) => {
      switch (conditionType) {
        case ConditionTypes.Recession:
          return t(`dialogs.autochart.toothEditor.conditionProperties.mm`, { number: `~${Number(value).toFixed(1)}` })
        default:
          break
      }
    },
    [t]
  )

  const getPropertiesDescription = useCallback(
    ({ conditionType, properties = {} }) => {
      if (properties.severity) {
        return t('dialogs.autochart.toothEditor.conditionProperties.severityLevel', {
          severity: properties.severity
        })
      } else if (properties.type) {
        return t(`dialogs.autochart.toothEditor.conditionProperties.${properties.type}`)
      } else if (properties.value) {
        return getPropertyValueDescription(conditionType, properties.value)
      } else {
        return ''
      }
    },
    [t, getPropertyValueDescription]
  )

  return { getPropertyValueDescription, getPropertiesDescription }
}
