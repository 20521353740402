import { makeStyles } from '@material-ui/styles'
import ROUTES from 'consts/routesConsts'
import useCssClasses from 'hooks/useCssClasses'
import useRolePermissions from 'hooks/useRolePermissions'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { trackEvent } from 'utils/analyticsUtils'
import { validatePasswords } from 'utils/authUtils'
import { isValidEmail } from 'utils/generalUtils'
import QuickSignUpBillingInfo from './BillingInfo'
import QuickSignUpPersonalDetails from './PersonalDetails'
import QuickSignUpPracticeInfo from './PracticeInfo'
import QuickSignUpPassword from './QuickSignUp'
import QuickSignUpTerms from './Terms'

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    overflowY: 'auto'
  }
}))

const QuickSignUpRouter = () => {
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const { permissions } = useRolePermissions()
  useCssClasses()
  const { password, passwordConfirmation, emailAddress } = useSelector(state => state.quickSignUpReducer)

  const passwordProvided = useMemo(
    () =>
      emailAddress &&
      password &&
      passwordConfirmation &&
      isValidEmail(emailAddress) &&
      !validatePasswords(password, passwordConfirmation).errors,

    [emailAddress, password, passwordConfirmation]
  )

  useEffect(() => {
    if (permissions.quickSignUp) {
      const path = location.pathname
      if (path !== ROUTES.QUICK_SIGN_UP_PASSWORD && (!passwordProvided || path === ROUTES.QUICK_SIGN_UP)) {
        trackEvent('Sign up started', {
          email: emailAddress,
          signupType: 'quick'
        })
        history.push(ROUTES.QUICK_SIGN_UP_PASSWORD)
        return
      }
    } else {
      history.push(ROUTES.WELCOME)
    }
  }, [emailAddress, history, location, passwordProvided, permissions])

  return (
    <div className={classes.wrapper}>
      <Switch>
        <Route path={ROUTES.QUICK_SIGN_UP_PASSWORD} component={QuickSignUpPassword} />
        <Route path={ROUTES.QUICK_SIGN_UP_PERSONAL_DETAILS} component={QuickSignUpPersonalDetails} />
        <Route path={ROUTES.QUICK_SIGN_UP_PRACTICE_INFO} component={QuickSignUpPracticeInfo} />
        <Route path={ROUTES.QUICK_SIGN_UP_BILLING_INFO} component={QuickSignUpBillingInfo} />
        <Route path={ROUTES.QUICK_SIGN_UP_TERMS} component={QuickSignUpTerms} />
      </Switch>
    </div>
  )
}

export default QuickSignUpRouter
