import i18n from 'resources/locales/i18n'

export const StatusTypesOrder = {
  invited: 1,
  'records-only': 2,
  'new-lead': 3,
  observation: 4,
  'active-treatment': 5,
  retention: 6,
  archived: 7
}

export const ScanFrequencyOptions = {
  'active-treatment': [7, 14, 21, 28],
  observation: [3, 6],
  retention: [3, 6]
}

export const scanFrequencyCustomOption = {
  label: i18n.t('dialogs.scanFrequency.custom'),
  value: 'custom'
}

export const DEFAULT_PERIOD_TYPE_OPTIONS = ['day', 'month']
const periodTypeOptionsByStatus = {
  'active-treatment_rm': ['day'],
  'active-treatment_rc': ['day'],
  'active-treatment_ortho': ['day']
}

export const getPeriodTypeOptions = statusKey =>
  (periodTypeOptionsByStatus[statusKey] || DEFAULT_PERIOD_TYPE_OPTIONS).map(periodType => ({
    label: i18n.t(`periodTypes.${periodType}`),
    value: periodType
  }))

export const isPatientActive = patient => {
  if (!patient) {
    return false
  }

  const status = patient?.treatments?.items[0]?.currentStatus?.status
  return status?.scanCyclesEnabled
}
