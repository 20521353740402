import { makeStyles, CircularProgress } from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'
import ImageIcon from '@material-ui/icons/Image'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import fileExtension from 'file-extension'
import { IMAGE_INPUT_FILE_TYPES } from 'consts/appConsts'

const useStyles = makeStyles(theme => ({
  container: {
    border: '2px dashed',
    borderColor: '#ccc',
    outline: 'none',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    borderRadius: '10px',
    color: '#777',
    backgroundColor: '#fff'
  },
  content: {
    fontSize: '18px',
    textAlign: 'center'
  },
  title: {
    margin: 0
  },
  icon: {
    fontSize: '72px'
  }
}))

export default ({ onFileAccepted, onError, isLoading }) => {
  const classes = useStyles()

  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(file => {
        const reader = new FileReader()
        const extension = fileExtension(file.name)

        reader.onload = () => {
          onFileAccepted({
            data: reader.result,
            type: file.type,
            extension,
            key: `${uuidv4()}.${extension}`
          })
        }
        reader.readAsArrayBuffer(file)
      })
    },
    [onFileAccepted]
  )

  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    onDrop,
    multiple: false,
    accept: IMAGE_INPUT_FILE_TYPES,
    disabled: isLoading
  })

  return (
    <div {...getRootProps()} className={classes.container}>
      <input {...getInputProps()} />
      <div className={classes.content}>
        {isLoading ? (
          <>
            <CircularProgress color="secondary" />
          </>
        ) : isDragReject ? (
          <>
            <BlockIcon className={classes.icon} />
            <p className={classes.title}>File is not supported!</p>
          </>
        ) : (
          <>
            <ImageIcon className={classes.icon} />
            <div className={classes.title}>
              <div>Drag or click here</div>
              <div>420 x 210</div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
