import * as React from 'react'

function SvgEllipses(props) {
  return (
    <svg width={32} height={32} fill="none" {...props}>
      <circle cx={8} cy={16} r={2} fill="#001335" />
      <circle cx={16} cy={16} r={2} fill="#001335" />
      <circle cx={24} cy={16} r={2} fill="#001335" />
    </svg>
  )
}

export default SvgEllipses
