import * as React from 'react'

function AddMember(props) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9555 5.6077C3.9555 3.42349 5.72615 1.65283 7.91033 1.65283C10.0945 1.65283 11.8652 3.42349 11.8652 5.6077C11.8652 6.81406 11.3251 7.89426 10.4734 8.61966C13.1792 9.65779 15.1117 12.2868 15.1117 15.3475C15.1117 15.7387 14.7946 16.0558 14.4034 16.0558C14.0122 16.0558 13.6951 15.7387 13.6951 15.3475C13.6951 12.1676 11.0903 9.56281 7.9104 9.56281C4.73039 9.56281 2.12565 12.1676 2.12565 15.3475C2.12565 15.7387 1.80852 16.0558 1.41732 16.0558C1.02612 16.0558 0.708984 15.7387 0.708984 15.3475C0.708984 12.2868 2.64154 9.65779 5.34727 8.61966C4.49564 7.89426 3.9555 6.81407 3.9555 5.6077ZM7.91033 3.0695C6.50856 3.0695 5.37216 4.20589 5.37216 5.6077C5.37216 7.0095 6.50856 8.14593 7.91033 8.14593C9.31219 8.14593 10.4486 7.0095 10.4486 5.6077C10.4486 4.20589 9.31219 3.0695 7.91033 3.0695Z"
        fill="#3C52EF"
      />
      <path
        d="M12.7474 2.36108C12.3583 2.36108 12.043 2.67822 12.043 3.06942C12.043 3.46062 12.3583 3.77775 12.7474 3.77775H15.5885C15.9776 3.77775 16.293 3.46062 16.293 3.06942C16.293 2.67822 15.9776 2.36108 15.5885 2.36108H12.7474Z"
        fill="#3C52EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1673 0.944336C14.5585 0.944336 14.8757 1.25971 14.8757 1.64875V4.48991C14.8757 4.87896 14.5585 5.19434 14.1673 5.19434C13.7761 5.19434 13.459 4.87896 13.459 4.48991V1.64875C13.459 1.25971 13.7761 0.944336 14.1673 0.944336Z"
        fill="#3C52EF"
      />
    </svg>
  )
}

export default AddMember
