export const showHelpWidget = () => {
  window.FreshworksWidget && window.FreshworksWidget('show', 'launcher')
}

export const hideHelpWidget = () => {
  window.FreshworksWidget && window.FreshworksWidget('hide', 'launcher')
}

export const openHelpPopup = () => {
  window.FreshworksWidget && window.FreshworksWidget('open')
}

export const setHelpPopupDetails = (name, email) => {
  window.FreshworksWidget &&
    window.FreshworksWidget('identify', 'ticketForm', {
      name: name,
      email: email
    })
}

export const clearHelpPopupDetails = () => {
  window.FreshworksWidget &&
    window.FreshworksWidget('identify', 'ticketForm', {
      name: '',
      email: ''
    })
}
