import React, { useCallback, useMemo } from 'react'
import { actionTypes } from 'consts/automatedActionConsts'
import SendMessage from './SendMessage'
import { Grid, makeStyles } from '@material-ui/core'
import AddPatientTag from './AddPatientTag'
import ShowAnnouncement from './ShowAnnouncement'
import AssignPatient from './AssignPatient'

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: 45
  }
}))

const AutomationRulesActionEditor = ({ actionType, actions, setActions, errors, isTimeBased = false }) => {
  const classes = useStyles()

  const actionParams = useMemo(() => actions?.[0]?.params || null, [actions])

  const handleActionChange = useCallback(
    ({ type, params }) => {
      setActions([
        {
          type,
          params
        }
      ])
    },
    [setActions]
  )

  const actionsComponents = useMemo(
    () => ({
      [actionTypes.AddPatientTag]: AddPatientTag,
      [actionTypes.SendMessage]: SendMessage,
      [actionTypes.AssignPatient]: AssignPatient,
      [actionTypes.ShowAnnouncement]: ShowAnnouncement
    }),
    []
  )

  return (
    <Grid container alignItems="center" className={classes.container}>
      {actionsComponents[actionType] && (
        <Grid item xs={12}>
          {React.createElement(actionsComponents[actionType], {
            onChange: handleActionChange,
            params: actionParams,
            errors,
            isTimeBased
          })}
        </Grid>
      )}
    </Grid>
  )
}

export default AutomationRulesActionEditor
