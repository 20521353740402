import React from 'react'

const SvgSuccess = props => {
  return (
    <svg width="73" height="67" viewBox="0 0 73 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.5 65.3737L32.8993 62.1243C26.6932 56.4451 21.5702 51.5417 17.5304 47.414C13.4906 43.2864 10.2704 39.5979 7.86994 36.3484C5.46946 33.099 3.7862 30.157 2.82016 27.5223C1.85412 24.8876 1.37109 22.2237 1.37109 19.5305C1.37109 14.2612 3.14217 9.8554 6.68434 6.31324C10.2265 2.77108 14.603 1 19.8138 1C23.151 1 26.2394 1.7904 29.079 3.3712C31.9186 4.952 34.3922 7.23538 36.5 10.2213C38.959 7.05973 41.5644 4.73244 44.3161 3.23947C47.0679 1.74649 50.0246 1 53.1862 1C58.397 1 62.7734 2.77108 66.3156 6.31324C69.8578 9.8554 71.6289 14.2612 71.6289 19.5305C71.6289 22.2237 71.1458 24.8876 70.1798 27.5223C69.2137 30.157 67.5305 33.099 65.13 36.3484C62.7295 39.5979 59.5094 43.2864 55.4696 47.414C51.4297 51.5417 46.3068 56.4451 40.1007 62.1243L36.5 65.3737Z"
        stroke="#3C52EF"
      />
    </svg>
  )
}

export default SvgSuccess
