import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import actions from 'actions'

export default () => {
  const { appconfig, isFetchingStaticData } = useSelector(state => state.appReducer)
  const promotions = useSelector(state => state.chatReducer.promotions?.data)
  const systemTags = useSelector(state => state.profileReducer.systemTags?.data)
  const { data: systemTemplates } = useSelector(state => state.profileReducer.systemTemplates)
  const { data: statuses } = useSelector(state => state.treatmentReducer.statuses)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!isFetchingStaticData && (isEmpty(statuses) || !systemTemplates || !systemTags.length || !promotions.length)) {
      dispatch(actions.fetchStaticData())
    }
  }, [appconfig, dispatch, isFetchingStaticData, promotions, statuses, systemTags, systemTemplates])
}
