import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TableCell } from '@material-ui/core'

const useStyles = ({ disabled, capitalized }) =>
  makeStyles({
    memberCell: {
      textTransform: ({ capitalized }) => (capitalized ? 'capitalize' : 'none'),
      color: ({ disabled }) => (disabled ? 'var(--text-color-21)' : 'inherit')
    }
  })({ disabled, capitalized })

const MemberCell = ({ children, disabled, className = '', capitalized = false }) => {
  const classes = useStyles({ disabled, capitalized })
  return <TableCell className={[classes.memberCell, className].join(' ')}>{children}</TableCell>
}

export default MemberCell
