import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { GI_RESULT_MSG_TYPE } from 'consts/giConsts'
import GiAvatar from 'components/common/icons/gi/GiAvatar'
import ProfilePicture from 'components/common/ProfilePicture'
import { OH_NOTE_MSG_TYPE } from 'consts/oralHygieneNoteConsts'
import GrinAiAvatar from 'components/common/icons/GrinAiAvatar'

const useStyles = makeStyles(
  theme => ({
    profilePicture: {
      right: ({ alignLeft }) => (alignLeft ? undefined : -50),
      left: ({ alignLeft }) => (alignLeft ? -50 : undefined),
      justifyContent: ({ alignLeft }) => (alignLeft ? 'flex-start' : 'flex-end'),
      position: 'absolute',
      bottom: ({ messageType }) => (messageType === GI_RESULT_MSG_TYPE ? 55 : 0),
      display: 'flex',
      '& div, .avatar, img': {
        justifyContent: 'center',
        margin: 0
      }
    }
  }),
  {}
)

const MessageAvatar = ({ messageType, name, email, photo, alignLeft }) => {
  const classes = useStyles({ alignLeft, messageType })

  return (
    <div className={classes.profilePicture}>
      {messageType === GI_RESULT_MSG_TYPE ? (
        <GiAvatar />
      ) : messageType === OH_NOTE_MSG_TYPE ? (
        <GrinAiAvatar />
      ) : (
        <ProfilePicture name={name} email={email} photo={photo} size="small" />
      )}
    </div>
  )
}

export default MessageAvatar
