import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../actions'
import { assemblePlanKey, getRenewDate } from '../../utils/billingUtils'
import { divideByThousands } from '../../utils/stringUtils'
import CreditCardPaymentIcons from '../common/inputs/billing/CreditCardPaymentIcons'
import CreditCardPicker from '../common/inputs/billing/CreditCardPicker'
import BaseModal from '../common/modals/BaseModal'
import OrderItemsList from '../common/OrderItemsList'
import DazzedHeading16 from '../common/text/DazzedHeading16'
import DazzedParagraph12 from '../common/text/DazzedParagraph12'
import { useCountry } from '../../hooks/useCountry'
import { BILLING_PERIOD_ANNUALLY, BILLING_PERIOD_MONTHLY } from '../../consts/grinPlanConsts'
import { COUNTRY_US, COUNTRY_IN } from '../../consts/countryConsts'

const useStyles = makeStyles({
  body: {
    top: 40,
    minWidth: window.innerWidth,
    height: window.innerHeight - 80,
    borderRadius: 0
  },
  contentContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    width: '50%',
    alignSelf: 'center'
  },
  typeText: {
    marginBottom: 8,
    fontWeight: 600
  },
  calculationItemNote: {
    marginLeft: 15,
    marginTop: 10,
    marginBottom: 10,
    fontSize: 14
  },
  horizontalDivider: {
    marginTop: 30,
    marginBottom: 30,
    height: 1,
    backgroundColor: 'var(--text-color-12)'
  },
  creditCardContainer: {
    width: 540,
    marginBottom: 20
  },
  creditCardTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  creditCardTitle: {
    marginBottom: 20
  },
  shippingAddressTitle: {
    marginTop: 30,
    marginBottom: 20
  },
  termsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20
  },
  link: {
    fontWeight: 500,
    color: 'var(--text-color-3)',
    cursor: 'pointer'
  }
})

const BillingUpgradePlanDialog = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isOpen = useSelector(state => state.billingReducer.upgradePlan.isUpgradePlanDialogOpen)
  const selectedPlanGroup = useSelector(state => state.billingReducer.upgradePlan.selectedPlanGroup)
  const selectedPlanPeriod = useSelector(state => state.billingReducer.upgradePlan.selectedPlanPeriod)
  const isSelectedPlanUpgrading = useSelector(state => state.billingReducer.upgradePlan.isLoading)
  const grinPlans = useSelector(state => state.billingReducer.grinPlans)
  const practiceDetails = useSelector(state => state.practiceReducer.details)
  const [shippingAddress, setShippingAddress] = useState({})

  const scopesQuantity = useSelector(state => state.billingReducer.upgradePlan.selectedPlanScopesQuantity)
  const scopesMiniQuantity = useSelector(state => state.billingReducer.upgradePlan.selectedPlanScopesMiniQuantity)
  const { price: scopePrice } = useSelector(state => state.productReducer.scope.data)
  const shippingFee = useSelector(state => state.productReducer.shippingFee)
  const { price: scopeMiniPrice } = useSelector(state => state.productReducer.scopeMini.data)
  const scopesShippingAddress = useSelector(state => state.billingReducer.upgradePlan.selectedPlanScopesShippingAddress)

  const isCalculatingTaxes = useSelector(state => state.billingReducer.order.isLoadingTaxes)
  const taxes = useSelector(state => state.billingReducer.order.taxAmount)

  const selectedPlan = grinPlans[assemblePlanKey(selectedPlanGroup, selectedPlanPeriod)]

  const currency = '$'
  const monthPrice = useMemo(() => grinPlans[`${selectedPlanGroup}_monthly`]?.price, [grinPlans, selectedPlanGroup])

  const annualPrice = useMemo(() => monthPrice * 12, [monthPrice])

  const scopePriceTotal = useMemo(() => scopesQuantity * scopePrice || 0, [scopePrice, scopesQuantity])
  const scopeMiniPriceTotal = useMemo(
    () => scopesMiniQuantity * scopeMiniPrice || 0,
    [scopeMiniPrice, scopesMiniQuantity]
  )

  const { coupon } = useSelector(state => state.billingReducer.order.coupon)

  const { getStateKey } = useCountry()

  const mappedShippingAddress = useMemo(() => {
    const address = {
      street: shippingAddress.address1,
      city: shippingAddress.city,
      country: shippingAddress.country,
      state: getStateKey(shippingAddress.state, shippingAddress.country),
      zip: shippingAddress.zip
    }
    if (shippingAddress.country === COUNTRY_IN) {
      address.detailedAddress = shippingAddress.address2
    }
    return address
  }, [getStateKey, shippingAddress])

  const setInitialShippingAddress = useCallback(() => {
    setShippingAddress({
      address1: practiceDetails?.address1,
      address2: practiceDetails?.address2,
      city: practiceDetails?.city,
      country: practiceDetails?.country || COUNTRY_US,
      state: practiceDetails?.state,
      zip: practiceDetails?.zip
    })
  }, [practiceDetails])

  const handleClose = useCallback(() => {
    dispatch(Actions.setUpgradePlanDialogVisibility(false))
    dispatch(Actions.setSelectedPlanGroupAndPeriod({ planGroup: null, planPeriod: BILLING_PERIOD_MONTHLY }))
  }, [dispatch])

  const handleUpgradePlan = useCallback(() => {
    if (isSelectedPlanUpgrading) {
      return
    }

    dispatch(
      Actions.upgradePlan({
        plan: selectedPlan,
        ...((!!scopesQuantity || !!scopesMiniQuantity) && {
          scopesQuantity: +scopesQuantity,
          scopesMiniQuantity: +scopesMiniQuantity
        }),
        shippingAddress: mappedShippingAddress
      })
    )
  }, [isSelectedPlanUpgrading, scopesQuantity, scopesMiniQuantity, mappedShippingAddress, selectedPlan, dispatch])

  const discountItems = useMemo(
    () =>
      coupon?.discountAmount ? [{ description: [coupon.name], price: coupon.discountAmount, isCoupon: true }] : [],
    [coupon]
  )

  const scopeOrderItems = useMemo(
    () =>
      scopesQuantity > 0
        ? [
            {
              description: `${currency}${scopePrice} x ${scopesQuantity} ${t('dialogs.upgradePlan.grinScopes')}`,
              price: scopePriceTotal
            }
          ]
        : [],
    [scopePrice, scopePriceTotal, scopesQuantity, t]
  )

  const scopesMiniOrderItems = useMemo(
    () =>
      scopesMiniQuantity > 0
        ? [
            {
              description: `${currency}${scopeMiniPrice} x ${scopesMiniQuantity} ${t(
                'dialogs.upgradePlan.grinScopesMini'
              )}`,
              price: scopeMiniPriceTotal
            }
          ]
        : [],
    [scopeMiniPrice, scopeMiniPriceTotal, scopesMiniQuantity, t]
  )

  const shippingFeeOrderItem = useMemo(
    () =>
      scopesQuantity > 0 || scopesMiniQuantity > 0
        ? [
            {
              description: t('dialogs.upgradePlan.shippingFee'),
              price: shippingFee
            }
          ]
        : [],
    [scopesMiniQuantity, scopesQuantity, shippingFee, t]
  )

  const monthlyOrderItems = useMemo(
    () => [
      {
        description: `${t('dialogs.upgradePlan.description1', {
          price: `${currency}${divideByThousands(monthPrice)}`,
          activeUsers: selectedPlan?.maxPatients
        })}*`,
        price: monthPrice
      },
      ...scopeOrderItems,
      ...scopesMiniOrderItems,
      ...shippingFeeOrderItem,
      ...discountItems,
      { description: `${t('dialogs.upgradePlan.tax')}**`, price: taxes }
    ],
    [t, monthPrice, selectedPlan, scopeOrderItems, scopesMiniOrderItems, shippingFeeOrderItem, taxes, discountItems]
  )

  const annuallyOrderItems = useMemo(
    () => [
      {
        description: `${t('dialogs.upgradePlan.description2', {
          price: `${currency}${divideByThousands(monthPrice)}`,
          activeUsers: selectedPlan?.maxPatients
        })}`,
        price: annualPrice
      },
      {
        description: t('dialogs.upgradePlan.annualDiscount2'),
        price: -(annualPrice - selectedPlan?.price)
      },
      ...scopeOrderItems,
      ...scopesMiniOrderItems,
      ...shippingFeeOrderItem,
      ...discountItems,
      { description: `${t('dialogs.upgradePlan.tax')}**`, price: taxes }
    ],
    [
      t,
      monthPrice,
      selectedPlan,
      annualPrice,
      scopeOrderItems,
      scopesMiniOrderItems,
      shippingFeeOrderItem,
      taxes,
      discountItems
    ]
  )

  useEffect(() => {
    setInitialShippingAddress()
  }, [practiceDetails, setInitialShippingAddress])

  useEffect(() => {
    if (isOpen) {
      dispatch(Actions.resetCoupon({}))

      dispatch(
        Actions.calculateOrderTax({
          quantity: scopesQuantity,
          quantityMini: scopesMiniQuantity,
          shippingAddress: mappedShippingAddress,
          grinPlanKey: selectedPlan.key
        })
      )
    }
  }, [scopesQuantity, scopesShippingAddress, dispatch, mappedShippingAddress, isOpen, selectedPlan, scopesMiniQuantity])

  const resetCoupon = useCallback(() => dispatch(Actions.clearCouponCode()), [dispatch])

  return isOpen ? (
    <BaseModal
      open={isOpen}
      title={t('dialogs.upgradePlan.yourPlan', { plan: t(`grinPlans.displayName.${selectedPlan.planGroup}`) })}
      variant={'fullscreen'}
      handleClose={handleClose}
      handleBackdropClick={handleClose}
    >
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <OrderItemsList
            onResetCoupon={resetCoupon}
            hasCoupon={coupon.discountAmount}
            couponFieldWidth={450}
            items={selectedPlanPeriod === BILLING_PERIOD_ANNUALLY ? annuallyOrderItems : monthlyOrderItems}
            isLoading={isCalculatingTaxes}
          />
          <DazzedParagraph12 className={classes.calculationItemNote} strong>
            {`* ${t('dialogs.upgradePlan.disclaimer1', { date: getRenewDate(selectedPlanPeriod) })}`}
          </DazzedParagraph12>
          <DazzedParagraph12 className={classes.calculationItemNote} strong>
            {`** ${t('dialogs.upgradePlan.disclaimer2')}`}
          </DazzedParagraph12>

          <div className={classes.horizontalDivider} />

          <div className={classes.creditCardContainer}>
            <div className={classes.creditCardTitleContainer}>
              <DazzedHeading16 className={classes.creditCardTitle}>
                {t('dialogs.upgradePlan.paymentMethod')}
              </DazzedHeading16>
              <CreditCardPaymentIcons />
            </div>
            <CreditCardPicker
              submitLabel={t('dialogs.upgradePlan.purchaseButton')}
              submitButtonWidth="350px"
              iconButtons={false}
              isSubmitLoading={isSelectedPlanUpgrading}
              showAgreement={true}
              onSubmit={handleUpgradePlan}
              shippingAddress={!!scopesQuantity && shippingAddress}
              setAddress={!!scopesQuantity && setShippingAddress}
              handleAddressChanged={!scopesQuantity ? setShippingAddress : () => {}}
            />
          </div>
        </div>
      </div>
    </BaseModal>
  ) : null
}

export default BillingUpgradePlanDialog
