import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../../actions'
import {
  GRIN_SCOPE_MAX_IN_ORDER,
  GRIN_SCOPE_MIN_IN_ORDER,
  GRIN_SCOPE_TYPE_MINI,
  GRIN_SCOPE_TYPE_REGULAR
} from '../../../consts/billingConsts'
import { assemblePlanKey } from '../../../utils/billingUtils'
import BaseModal from '../../common/modals/BaseModal'
import OrderGrinKitsAmountContainer from './OrderGrinKitsAmountContainer'
import OrderGrinKitsComparison from './OrderGrinKitsComparison'

const useStyles = makeStyles(() => ({
  body: {
    minWidth: 680
  },
  subTitle: {
    marginBottom: 15,
    fontWeight: 500
  }
}))

const OrderGrinKitsModal = ({ isOpen, handleClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isLoading = useSelector(state => state.billingReducer.order.isLoading)
  const grinPlans = useSelector(state => state.billingReducer.grinPlans)
  const productScope = useSelector(state => state.productReducer.scope.data)
  const productScopeMini = useSelector(state => state.productReducer.scopeMini.data)
  const shippingFee = useSelector(state => state.productReducer.shippingFee)
  const selectedPlanGroup = useSelector(state => state.billingReducer.upgradePlan.selectedPlanGroup)
  const selectedPlanPeriod = useSelector(state => state.billingReducer.upgradePlan.selectedPlanPeriod)

  const selectedPlan = grinPlans[assemblePlanKey(selectedPlanGroup, selectedPlanPeriod)]

  const [quantity, setQuantity] = useState(GRIN_SCOPE_MIN_IN_ORDER)
  const [quantityMini, setQuantityMini] = useState(null)
  const [showProductComparison, setShowProductComparison] = useState(false)

  const [formErrors, setFormErrors] = useState()

  const products = useMemo(
    () => [
      {
        type: GRIN_SCOPE_TYPE_REGULAR,
        price: productScope?.price || 0,
        quantity: quantity,
        maxQuantity: selectedPlan?.maxScopes,
        onSetQuantity: setQuantity
      },
      {
        type: GRIN_SCOPE_TYPE_MINI,
        price: productScopeMini?.price || 0,
        quantity: quantityMini,
        maxQuantity: selectedPlan?.maxScopes,
        onSetQuantity: setQuantityMini
      }
    ],
    [productScope, productScopeMini, quantity, quantityMini, selectedPlan]
  )

  const resetFields = useCallback(() => {
    setQuantity(GRIN_SCOPE_MIN_IN_ORDER)
    setQuantityMini(null)
    setFormErrors()
  }, [setQuantity, setFormErrors])

  const validateForm = useCallback(() => {
    const errors = {}

    if (!quantity && !quantityMini) {
      errors.quantity = t('dialogs.orderScopes.quantityError')
    }
    if (quantity % GRIN_SCOPE_MIN_IN_ORDER !== 0) {
      errors.quantity = t('dialogs.orderScopes.quantityMultiplyError')
    }
    if (quantityMini % GRIN_SCOPE_MIN_IN_ORDER !== 0) {
      errors.quantityMini = t('dialogs.orderScopes.quantityMultiplyError')
    }
    if (quantity < GRIN_SCOPE_MIN_IN_ORDER && !quantityMini) {
      errors.quantity = t('dialogs.orderScopes.minQuantityError', { minScopes: GRIN_SCOPE_MIN_IN_ORDER })
    }
    if (quantityMini < GRIN_SCOPE_MIN_IN_ORDER && !quantity) {
      errors.quantityMini = t('dialogs.orderScopes.minQuantityError', { minScopes: GRIN_SCOPE_MIN_IN_ORDER })
    }
    if (quantity > GRIN_SCOPE_MAX_IN_ORDER) {
      errors.quantity = t('dialogs.orderScopes.maxquantityError', { maxScopes: GRIN_SCOPE_MAX_IN_ORDER })
    }
    if (quantityMini > GRIN_SCOPE_MAX_IN_ORDER) {
      errors.quantityMini = t('dialogs.orderScopes.maxquantityError', { maxScopes: GRIN_SCOPE_MAX_IN_ORDER })
    }

    setFormErrors(errors)
    return isEmpty(errors)
  }, [quantity, quantityMini, t])

  const handleCancel = useCallback(() => {
    handleClose()
  }, [handleClose])

  const handleNotNowClick = useCallback(() => {
    handleClose()
    dispatch(Actions.setSelectPlanModalVisibility(false))
    dispatch(Actions.setUpgradePlanDialogVisibility(true))
    dispatch(
      Actions.setSelectedPlanScopesQuantityAndAddress({
        planScopesQuantity: 0,
        planScopesMiniQuantity: 0
      })
    )
  }, [dispatch, handleClose])

  const handleSubmit = useCallback(() => {
    if (!validateForm()) {
      return
    }

    handleClose()
    dispatch(
      Actions.setSelectedPlanScopesQuantityAndAddress({
        planScopesQuantity: quantity,
        planScopesMiniQuantity: quantityMini
      })
    )
    dispatch(Actions.setSelectPlanModalVisibility(false))
    dispatch(Actions.setUpgradePlanDialogVisibility(true))
  }, [dispatch, handleClose, quantity, quantityMini, validateForm])

  useEffect(() => {
    validateForm()
  }, [quantity, validateForm])

  useEffect(() => {
    if (!isOpen) {
      resetFields()
    }
  }, [isOpen, resetFields])

  return isOpen ? (
    showProductComparison ? (
      <BaseModal
        open={showProductComparison}
        primaryLabel={t('general.back')}
        className={classes.body}
        withCloseIcon={false}
        onPrimaryBtnClick={() => setShowProductComparison(false)}
      >
        <OrderGrinKitsComparison />
      </BaseModal>
    ) : (
      <BaseModal
        open={isOpen}
        title={t('dialogs.orderScopes.title')}
        secondaryLabel={t('dialogs.orderScopes.notNow')}
        primaryLabel={t('general.next')}
        className={classes.body}
        isLoading={isLoading}
        handleClose={handleCancel}
        onSecondaryBtnClick={handleNotNowClick}
        onPrimaryBtnClick={handleSubmit}
        withCloseIcon={false}
        isPrimaryDisabled={!isEmpty(formErrors)}
      >
        <OrderGrinKitsAmountContainer
          products={products}
          shippingFee={shippingFee || 0}
          formErrors={formErrors}
          onShowProductComparison={() => setShowProductComparison(true)}
        />
      </BaseModal>
    )
  ) : null
}

export default OrderGrinKitsModal
