import * as React from 'react'

const Computer = props => (
  <svg width={137} height={81} viewBox="0 0 137 81" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={20.1841} y={1} width={96.6316} height={62.5263} rx={4} stroke="#3C52EF" />
    <path
      d="M19.257 68.534A4 4 0 0121.254 68h94.492c.701 0 1.389.184 1.997.534l12.596 7.26c1.773 1.022 1.048 3.732-.999 3.732H7.66c-2.047 0-2.772-2.71-.999-3.733l12.596-7.259z"
      stroke="#3C52EF"
    />
  </svg>
)

export default Computer
