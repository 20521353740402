import React, { useCallback, useState } from 'react'
import BaseModal from 'components/common/modals/BaseModal'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { validatePasswords } from 'utils/authUtils'
import ServiceAccountPasswordValidation from './ServiceAccountPasswordValidation'
import { useDispatch } from 'react-redux'
import Actions from 'actions'

const useStyles = makeStyles(theme => ({
  modal: {
    width: 'auto !important',
    paddingTop: 20
  },
  modalContent: {
    padding: '20px 47px 40px 47px !important'
  },
  passwordValidation: {
    color: 'var(--text-color-35)'
  },
  passwordInputContainer: {
    marginBottom: 30
  },
  note: {
    marginTop: 30,
    color: 'var(--border-color-33)'
  }
}))

const ServiceAccountResetPasswordModal = ({
  isOpen,
  isLoading,
  handleToggleRcService,
  onDeactivatePressed,
  onClose,
  serviceAccountEmail
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('')

  const onSubmitPressed = useCallback(() => {
    const { errors } = validatePasswords(password, passwordConfirmation)
    if (errors) {
      setPasswordError(errors?.password)
      setPasswordConfirmationError(errors?.passwordConfirmation)
    } else {
      setPasswordError(null)
      setPasswordConfirmationError(null)
      dispatch(Actions.serviceAccountSetPasword({ password }))
    }
  }, [setPasswordError, password, passwordConfirmation, dispatch])

  return (
    <BaseModal
      open={isOpen}
      title={t('pages.accountSettings.services.serviceAccount.modals.resetPassword.title')}
      variant="alert"
      isLoading={isLoading}
      onPrimaryBtnClick={() => onSubmitPressed(true)}
      onSecondaryBtnClick={onClose}
      primaryLabel={t('pages.accountSettings.services.serviceAccount.modals.resetPassword.primaryButton')}
      secondaryLabel={t('general.cancel')}
      handleClose={onClose}
      className={classes.modal}
      contentClassName={classes.modalContent}
      largerPrimaryButton={true}
    >
      <ServiceAccountPasswordValidation
        password={password}
        passwordConfirmation={passwordConfirmation}
        passwordError={passwordError}
        passwordConfirmationError={passwordConfirmationError}
        onPasswordChange={setPassword}
        onPasswordConfirmationChange={setPasswordConfirmation}
      />
    </BaseModal>
  )
}

export default ServiceAccountResetPasswordModal
