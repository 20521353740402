import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAllFeatureFlags } from 'hooks/useAllFeatureFlags'
import useScanSummarySettings from 'components/Support/SupportDashboard/EditDoctor/useScanSummarySettings'
import usePracticeConfiguration from './usePracticeConfiguration'

export default (user, practiceOwnerUser = null) => {
  if (user?.type === 'patient') {
    return useMemo(() => JSON.parse(user?.featureFlags?.flags || '{}'), [user.featureFlags.flags])
  }

  const { doctorFeatureFlags } = useAllFeatureFlags()
  const { scanSummarySettings } = usePracticeConfiguration()
  const { isScanSummaryVersionAtLeast } = useScanSummarySettings(scanSummarySettings)
  const mpPracticeFFs = useSelector(state => state.multiPracticeReducer.practiceFFs)

  const accountOwnerFF = useSelector(state => {
    const accountOwnerUser = practiceOwnerUser || (!user && state.practiceReducer?.accountOwner?.user)
    return JSON.parse(accountOwnerUser?.featureFlags?.flags || '{}')
  })

  const currentDoctorFeatureFlags = useSelector(state => state.profileReducer?.doctor?.user?.featureFlags)

  const practiceFFs = useMemo(() => doctorFeatureFlags.filter(flag => flag?.isPractice), [doctorFeatureFlags])
  const sharedFF = useMemo(
    () =>
      Object.keys(accountOwnerFF).reduce((currFFs, currFFKey) => {
        if (practiceFFs.find(currPracticeFF => currPracticeFF.key === currFFKey && currPracticeFF.type === 'doctor')) {
          return {
            ...currFFs,
            [currFFKey]: accountOwnerFF[currFFKey]
          }
        }

        return currFFs
      }, {}),
    [accountOwnerFF, practiceFFs]
  )

  const appSettingsFF = useMemo(() => {
    return { recordsCropSettings: isScanSummaryVersionAtLeast('V2-1-0') }
  }, [isScanSummaryVersionAtLeast])

  return useMemo(() => {
    let featureFlags
    if (!user) {
      featureFlags = currentDoctorFeatureFlags
    } else {
      featureFlags = user?.featureFlags
    }

    const result = {
      ...(featureFlags ? JSON.parse(featureFlags.flags) : {}),
      ...sharedFF,
      ...(user ? {} : mpPracticeFFs),
      ...appSettingsFF
    }

    return result
  }, [currentDoctorFeatureFlags, mpPracticeFFs, sharedFF, user, appSettingsFF])
}
