import { makeStyles } from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'
import React, { useCallback, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import { CircularProgress } from '@material-ui/core'
import fileExtension from 'file-extension'
import FilePlaceholder from 'components/Patients/PatientCard/RecordsTab/FilePlaceholder'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import GrinLabel from 'components/common/text/GrinLabel'
import Tooltip from 'components/common/Tooltip'
import { fileTypeToMessageType } from 'utils/fileUtils'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    border: '2px dashed',
    borderColor: '#ccc',
    outline: 'none',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    borderRadius: '10px',
    color: '#777',
    backgroundColor: '#fff',
    cursor: 'pointer',
    '&.error': {
      borderColor: 'red'
    },
    '&.uploaded': {
      cursor: 'no-drop',
      opacity: '.5'
    }
  },
  errorMessage: {
    color: 'red',
    marginTop: '5px',
    width: '100%'
  },
  fileWrapper: {
    width: '100%'
  },
  filePlaceholder: {
    margin: 0
  },
  filenameWrapper: {
    height: 45,
    margin: 20,
    width: 160
  },
  filename: {
    color: 'var(--text-color-14)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    whiteSpace: 'nowrap'
  },
  label: {
    margin: '25px 0'
  },
  loader: {
    position: 'absolute',
    bottom: 0,
    marginBottom: 5
  },
  uploadedLabel: {
    height: 85,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default ({
  onChange,
  onError,
  isLoading,
  isUploaded = false,
  acceptedFormats,
  multipleFiles,
  className,
  files = [],
  emptyStateComponent,
  maxFilesNumber,
  errorMessage,
  filenameGenerator,
  fileIcon,
  icon,
  label = '',
  dropzoneProps,
  readAsDataURL,
  children,
  maxFileSize
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const mapFile = useCallback(
    file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        const extension = fileExtension(file.name)
        reader.onload = () => {
          resolve({
            data: reader.result,
            type: file.type,
            extension,
            originalKey: file.name,
            messageType: fileTypeToMessageType(file.type),
            key: filenameGenerator ? filenameGenerator(extension, file.name) : `${uuidv4()}.${extension}`,
            file
          })
        }
        if (readAsDataURL) {
          reader.readAsDataURL(file)
        } else {
          reader.readAsArrayBuffer(file)
        }
      }),
    [filenameGenerator, readAsDataURL]
  )

  const onDrop = useCallback(
    async (acceptedFiles, fileRejections) => {
      if (!!maxFilesNumber && acceptedFiles.length > maxFilesNumber) {
        onError({ code: 'maxFilesNumberExeeded', message: `Maximum files number can be selected is ${maxFilesNumber}` })
        return
      }

      let numOfExceededSizeFiles = 0

      fileRejections.forEach(file => {
        file.errors.forEach(err => {
          if (err.code === 'file-too-large') {
            numOfExceededSizeFiles++
          }
        })
      })

      if (numOfExceededSizeFiles > 0) {
        let message = t('pages.patients.selectedPatient.chat.dropzone.errors.multipleFileSizeExceeded', {
          numOfFiles: fileRejections.length
        })

        if (acceptedFiles.length + fileRejections.length === 1) {
          message = t('pages.patients.selectedPatient.chat.dropzone.errors.singleFileSizeExceeded')
        } else if (fileRejections.length === 1) {
          message = t('pages.patients.selectedPatient.chat.dropzone.errors.multipleFileUploadSingleFileSizeExceeded')
        }

        dispatch(
          Actions.toggleUploadFileSizeExceeded({
            message
          })
        )
      }

      const mappedFiles = await Promise.all(acceptedFiles.map(mapFile))
      onChange(mappedFiles)
    },
    [maxFilesNumber, mapFile, onChange, onError, dispatch, t]
  )

  const { getRootProps, getInputProps, isDragReject, open, draggedFiles } = useDropzone({
    onDrop,
    multiple: multipleFiles,
    accept: acceptedFormats,
    disabled: isLoading || isUploaded,
    maxSize: maxFileSize,
    ...dropzoneProps
  })

  /* React-dropzone cannot determine the type of files with filetype '.stl' and returns empty-string mime-type.
     Below we check if acceptedFormats includes .stl filetype and first DraggedFile returns empty string
     we return that isDragReject equals to false.
     Example of draggedFiles[0]: {kind: 'file', type: ''}
  */
  const unsupportFiletype = useMemo(() => {
    if (draggedFiles && draggedFiles[0]?.type === '' && acceptedFormats.includes('.stl')) {
      return false
    }
    return isDragReject
  }, [acceptedFormats, draggedFiles, isDragReject])

  return (
    <>
      <div
        {...getRootProps()}
        className={[classes.container, className, errorMessage && 'error', isUploaded && 'uploaded'].join(' ')}
      >
        <input {...getInputProps()} />
        {label && <GrinLabel className={classes.label}>{label}</GrinLabel>}
        {!isLoading && unsupportFiletype && (
          <>
            <BlockIcon className={classes.icon} />
            <p className={classes.title}>File is not supported!</p>
          </>
        )}
        {!unsupportFiletype && !!files.length && (
          <>
            <FilePlaceholder placeholderIcon={fileIcon} className={classes.filePlaceholder} />
            <div className={classes.filenameWrapper}>
              <Tooltip arrow value={files[0].originalKey} placement="bottom" variant="dark">
                <DazzedParagraph12 className={classes.filename}>{files[0].originalKey}</DazzedParagraph12>
              </Tooltip>
            </div>
            {isLoading && (
              <div className={classes.loader}>
                <CircularProgress color="primary" size={24} />
              </div>
            )}
          </>
        )}
        {!unsupportFiletype && !files.length && icon}
        {!unsupportFiletype && !files.length && !isUploaded && emptyStateComponent}
        {!unsupportFiletype && !files.length && isUploaded && (
          <span className={classes.uploadedLabel}>File uploaded</span>
        )}
        {!unsupportFiletype && children && React.cloneElement(children, { openDropzoneFilePicker: open })}
      </div>
      {errorMessage && <DazzedParagraph12 className={classes.errorMessage}>{errorMessage}</DazzedParagraph12>}
    </>
  )
}
