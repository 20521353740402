import React from 'react'
import { makeStyles } from '@material-ui/styles'
import AuditItem from './AuditItem'
import { Grid } from '@material-ui/core'
import moment from 'moment'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 12px'
  }
}))

const AuditItemsContainer = ({ auditItems }) => {
  const classes = useStyles()

  return (
    <Grid container direction="column" className={classes.root}>
      {auditItems.map((item, i) => (
        <Grid item xs={12} key={i}>
          <Grid container>
            <Grid item xs={3}>
              <DazzedParagraph14 strong>{moment(item.time).format('MMM DD YYYY, hh:mm:ss')}</DazzedParagraph14>
            </Grid>
            <Grid item xs={9}>
              <AuditItem {...item} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default AuditItemsContainer
