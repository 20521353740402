import * as React from 'react'

const StlsPlaceholder = () => {
  return (
    <svg width="27" height="33" viewBox="0 0 27 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.3459 7.75461L23.0359 4.43605C22.8473 4.24688 22.5413 4.24662 22.3523 4.43508C22.1632 4.62367 22.1629 4.9297 22.3514 5.11874L25.0273 7.80154H19.6998C19.2555 7.80154 18.8941 7.44015 18.8941 6.99587V1.65264L20.7583 3.52165C20.947 3.71063 21.253 3.71108 21.442 3.52256C21.631 3.33403 21.6313 3.02788 21.4429 2.8389L18.9419 0.331547C18.7319 0.12085 18.4407 0 18.1432 0H2.09741C1.12004 0 0.324951 0.795158 0.324951 1.77246V9.22337C0.324951 9.4904 0.541385 9.70677 0.80835 9.70677C1.07531 9.70677 1.29175 9.4904 1.29175 9.22337V4.19422H17.9273V6.99594C17.9273 7.97324 18.7225 8.7684 19.6998 8.7684H25.7085V31.2275C25.7085 31.6718 25.3471 32.0332 24.9028 32.0332H2.09741C1.65314 32.0332 1.29175 31.6718 1.29175 31.2275V30.1641H10.0319C10.2989 30.1641 10.5153 29.9477 10.5153 29.6807C10.5153 29.4136 10.2989 29.1973 10.0319 29.1973H1.29175V22.4297H16.3682C16.4571 22.4297 16.5294 22.5019 16.5294 22.5908V29.0361C16.5294 29.125 16.4571 29.1973 16.3682 29.1973H12.2255C11.9585 29.1973 11.7421 29.4136 11.7421 29.6807C11.7421 29.9477 11.9585 30.1641 12.2255 30.1641H16.3682C16.9901 30.1641 17.4962 29.658 17.4962 29.0361V22.5908C17.4962 21.9689 16.9901 21.4629 16.3682 21.4629H1.29175V11.4792C1.29175 11.2122 1.07531 10.9958 0.80835 10.9958C0.541385 10.9958 0.324951 11.2122 0.324951 11.4792V31.2275C0.324951 32.2048 1.12004 33 2.09741 33H24.9027C25.8801 33 26.6752 32.2048 26.6752 31.2275V8.55112C26.6752 8.25032 26.5582 7.96737 26.3459 7.75461ZM1.29175 3.22743V1.77246C1.29175 1.32819 1.65314 0.966797 2.09741 0.966797H17.9273V3.22743H1.29175Z"
        fill="#BBBBBB"
      />
      <path
        d="M11.9883 23.3965C11.7213 23.3965 11.5049 23.6129 11.5049 23.8799V27.7438C11.5049 27.979 11.6743 28.1802 11.9062 28.2202C11.9408 28.2262 11.9673 28.2307 12.3989 28.2307C12.6059 28.2307 12.9062 28.2296 13.3452 28.2272C13.6122 28.2257 13.8274 28.008 13.8259 27.7411C13.8245 27.4741 13.6097 27.2579 13.3398 27.2604C13.074 27.2619 12.7388 27.2632 12.4717 27.2635V23.8799C12.4717 23.6129 12.2552 23.3965 11.9883 23.3965Z"
        fill="#BBBBBB"
      />
      <path
        d="M5.85303 27.2637C5.46863 27.2637 5.09873 27.1088 4.86361 26.8493C4.6843 26.6515 4.37866 26.6364 4.18079 26.8157C3.98292 26.995 3.9679 27.3006 4.14721 27.4985C4.56261 27.9568 5.20031 28.2305 5.85303 28.2305C6.66804 28.2305 7.3524 27.7105 7.48034 26.9939C7.57663 26.4547 7.33184 25.7503 6.35615 25.3904C5.88113 25.2152 5.43892 25.0243 5.30318 24.9649C5.19793 24.8803 5.20147 24.7624 5.20856 24.7133C5.21836 24.645 5.26792 24.4783 5.51613 24.4035C6.0605 24.2396 6.56504 24.6372 6.58032 24.6494C6.78463 24.8191 7.08789 24.7919 7.25894 24.5884C7.43071 24.3841 7.40429 24.0791 7.19991 23.9074C7.16343 23.8767 6.29389 23.1595 5.23718 23.4777C4.70331 23.6386 4.32562 24.0593 4.2515 24.5759C4.18672 25.0079 4.40393 25.5877 4.86683 25.8292C4.8892 25.8391 5.42177 26.0762 6.02151 26.2974C6.19109 26.36 6.57993 26.5365 6.52856 26.8239C6.49002 27.0401 6.22679 27.2637 5.85303 27.2637Z"
        fill="#BBBBBB"
      />
      <path
        d="M9.49836 28.2305C9.76532 28.2305 9.98175 28.0141 9.98175 27.7471V24.3633H10.5705C10.8374 24.3633 11.0539 24.1469 11.0539 23.8799C11.0539 23.6129 10.8374 23.3965 10.5705 23.3965H8.43481C8.16785 23.3965 7.95142 23.6129 7.95142 23.8799C7.95142 24.1469 8.16785 24.3633 8.43481 24.3633H9.01496V27.7471C9.01496 28.0141 9.23139 28.2305 9.49836 28.2305Z"
        fill="#BBBBBB"
      />
      <path
        d="M18.3233 13.926C18.5903 13.926 18.8067 13.7096 18.8067 13.4426V10.6511C18.8022 10.5171 18.7477 10.3442 18.5812 10.2423L13.7297 7.18299C13.5714 7.08302 13.3696 7.0836 13.2119 7.18434L8.41659 10.2436C8.30772 10.3179 8.18971 10.4583 8.19319 10.6511V16.9888C8.19319 17.154 8.27743 17.3075 8.41659 17.3964L13.2119 20.4556C13.2912 20.5062 13.3815 20.5315 13.4719 20.5315C13.5614 20.5315 13.651 20.5067 13.7297 20.457L18.5812 17.3977C18.7215 17.3092 18.8067 17.1548 18.8067 16.9888V15.6984C18.8067 15.4314 18.5903 15.215 18.3233 15.215C18.0563 15.215 17.8399 15.4314 17.8399 15.6984V16.7222L13.9552 19.1717V13.9771L17.8399 11.5274V13.4425C17.8399 13.7096 18.0563 13.926 18.3233 13.926ZM13.4734 8.16435L17.417 10.6511L13.4734 13.138L9.57532 10.6511L13.4734 8.16435ZM9.15999 11.5329L12.9885 13.9754V19.1662L9.15999 16.7238V11.5329Z"
        fill="#BBBBBB"
      />
    </svg>
  )
}

export default StlsPlaceholder
