import * as React from 'react'

export default () => (
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9L10 9" stroke="var(--text-color-25)" strokeLinecap="round" />
    <path d="M5.5 0.5L5.5 9" stroke="var(--text-color-25)" strokeLinecap="round" />
    <path
      d="M1.85355 5.35355L5.14645 8.64645C5.34171 8.84171 5.65829 8.84171 5.85355 8.64645L9.14645 5.35355C9.46143 5.03857 9.23835 4.5 8.79289 4.5H2.20711C1.76165 4.5 1.53857 5.03857 1.85355 5.35355Z"
      fill="var(--text-color-25)"
    />
  </svg>
)
