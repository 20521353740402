import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import Send from 'components/common/icons/Send'
import LinkButton from 'components/common/buttons/LinkButton'
import SmallX from 'components/common/icons/SmallX'

const useStyles = makeStyles(theme => ({
  textarea: {
    resize: 'none',
    fontFamily: 'Dazzed',
    fontSize: 14,
    background: 'transparent',
    border: '1px solid var(--border-color-32)',
    borderRadius: 10,
    padding: 14,
    width: '100%',
    height: '84px'
  },
  buttonLabel: {
    fontWeight: 'bold!important'
  }
}))

const OralHygieneNoteMessageInput = ({ category, onSend, onDismiss, treatmentType = 'Other' }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [patientMessage, setPatientMessage] = useState('')

  const actions = useMemo(
    () => [
      {
        onClick: () => onSend(patientMessage),
        label: t('pages.patients.selectedPatient.chat.oralHygieneRecommendation.sendButton'),
        icon: (
          <div style={{ marginTop: 3 }}>
            <Send color="var(--text-color-25)" />
          </div>
        )
      },
      {
        onClick: () => onDismiss('wrong-recommendation'),
        icon: <SmallX />,
        label: t('pages.patients.selectedPatient.chat.oralHygieneRecommendation.dismissWrongRecommendation')
      },
      {
        onClick: () => onDismiss('dismiss'),
        label: t('general.dismiss')
      }
    ],
    [t, onSend, patientMessage, onDismiss]
  )

  useEffect(() => {
    setPatientMessage(
      t(`pages.patients.selectedPatient.chat.oralHygieneRecommendation.${category}.defaultMessage.${treatmentType}`)
    )
  }, [t, category, treatmentType])

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <textarea
          className={classes.textarea}
          value={patientMessage}
          onChange={({ target }) => setPatientMessage(target.value)}
          onKeyDown={e => e.stopPropagation()}
        />
      </Grid>
      <Grid item>
        <Grid container alignItems="center" justifyContent="space-between">
          {actions.map((action, i) => (
            <Grid item key={`oral-hygiene-actions-${i}`}>
              <LinkButton
                fontSize={12}
                underline
                labelClassName={classes.buttonLabel}
                icon={action.icon}
                label={action.label}
                onClick={action.onClick}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OralHygieneNoteMessageInput
