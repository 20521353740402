import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CachedImage from '../../common/CachedImage'

const useStyles = makeStyles({
  container: {
    width: 150,
    height: 150,
    background: '#FFBD39',
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold'
  },
  avatar: {
    width: 150,
    height: 150,
    borderRadius: '50%',
    backgroundColor: 'var(--bg-color-10)'
  }
})

const DoctorCamPlaceholder = ({ cameraOn }) => {
  const classes = useStyles()
  const { name, photo } = useSelector(state => state.profileReducer.doctor)
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      {cameraOn ? (
        `${t('dialogs.scanReviewEditor.startingCamera')}...`
      ) : photo ? (
        <CachedImage s3Object={photo} alt={name} applyClassName={classes.avatar} />
      ) : (
        `${t('general.drDegree')}. ${name}`
      )}
    </div>
  )
}

export default DoctorCamPlaceholder
