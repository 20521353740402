import clinical from './clinical'
import missingInfo from './missingInfo'
import other from './other'
import scheduling from './scheduling'
import patientQuestion from './patientQuestion'
import { NOTIFY_PRACTICE_REASONS } from 'consts/giConsts'

export default {
  [NOTIFY_PRACTICE_REASONS.CLINICAL]: clinical,
  [NOTIFY_PRACTICE_REASONS.MISSING_INFO]: missingInfo,
  [NOTIFY_PRACTICE_REASONS.OTHER]: other,
  [NOTIFY_PRACTICE_REASONS.SCHEDULING]: scheduling,
  [NOTIFY_PRACTICE_REASONS.PATIENT_QUESTION]: patientQuestion
}
