import { Grid } from '@material-ui/core'
import React from 'react'
import InfoIcon from '@material-ui/icons/Info'
import GrinLabel from '../text/GrinLabel'
import Tooltip from '../Tooltip'
import MobileTooltip from '../Tooltip.mobile'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    height: '80px'
  },
  mobileLabel: {
    marginBottom: 2
  },
  mobileInfoIcon: {
    transform: 'scale(1.5)'
  }
})

const LabeledInputContainer = ({ children, title, tooltip, isMobile = false, className = '' }) => {
  const classes = useStyles()

  return (
    <Grid container className={[classes.root, className].join(' ')}>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <GrinLabel className={`${isMobile && classes.mobileLabel}`}>{title}</GrinLabel>
        </Grid>
        {tooltip && (
          <Grid item className={`${isMobile && classes.mobileInfoIcon}`}>
            {isMobile ? (
              <MobileTooltip value={tooltip} variant="dark">
                <InfoIcon color="disabled" fontSize="inherit" />
              </MobileTooltip>
            ) : (
              <Tooltip value={tooltip} variant="dark">
                <InfoIcon color="disabled" fontSize="inherit" />
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  )
}

export default LabeledInputContainer
