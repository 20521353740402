import React, { useState, useMemo, useCallback, useRef } from 'react'
import { isMobile } from 'utils/mobileUtils'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'
import Attach from './Attach'
import SavedReplies from './SavedReplies/SavedReplies'
import Promotions from './Promotions/Promotions'
import ChatActionsModal from './ChatModal/ChatActionsModal'
import PromotionsModal from './ChatModal/PromotionsModal'
import TemplatesModal from './ChatModal/TemplatesModal'
import IconButton from 'components/common/buttons/IconButton'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import { Flag, PromotionsIcon } from 'components/common/icons'
import { AttachFile } from '@material-ui/icons'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import useRolePermissions from 'hooks/useRolePermissions'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = ({ isActionsModalOpen }) =>
  makeStyles({
    buttonsContainer: {
      minHeight: '48px'
    },
    mobileIcon: {
      transform: 'scale(1.3)',
      color: isActionsModalOpen ? 'var(--text-color-14)' : 'var(--text-color-3)'
    },
    icon: {
      color: 'var(--text-color-3)'
    },
    hidden: {
      display: 'none'
    }
  })({ isActionsModalOpen })

const ChatActions = ({
  promotionToSend,
  setIsTemplatesOpen,
  setShowDropzone,
  setPromotion,
  setInputFocus,
  selectedTemplate,
  setSelectedTemplate,
  handleTemplateSelection,
  disablePromotion = false,
  message,
  setFilesToSend,
  filesToSend
}) => {
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()
  const isPromotionsEnabled = usePracticeConfiguration().isPromotionsEnabled && permissions.sendPromotionsInChat
  const attachmentsRef = useRef(null)
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false)
  const [isPromotionsModalOpen, setIsPromotionsModalOpen] = useState(false)
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false)
  const classes = useStyles({ isActionsModalOpen })

  const handleClickAttach = useCallback(() => {
    trackEvent('Chat actions - Send attachment clicked')
    attachmentsRef.current.click()
  }, [])

  const togglePromotionsModal = useCallback(() => {
    trackEvent(`Promotions - popup ${isPromotionsModalOpen ? 'opened' : 'closed'}`)
    setIsPromotionsModalOpen(!isPromotionsModalOpen)
  }, [isPromotionsModalOpen])
  const toggleTemplatesModal = useCallback(() => {
    trackEvent('Chat actions - Saved replies modal opened')
    setIsTemplatesModalOpen(!isTemplatesModalOpen)
  }, [isTemplatesModalOpen])
  const toggleActionsModal = useCallback(() => setIsActionsModalOpen(!isActionsModalOpen), [isActionsModalOpen])

  const shouldDisableActionsButton = useMemo(
    () => filesToSend.length || promotionToSend,
    [filesToSend.length, promotionToSend]
  )

  const actions = useMemo(
    () =>
      [
        {
          icon: <Flag fontSize="small" />,
          component: (
            <SavedReplies
              id="patient-room-chat-replays-button"
              onTemplateApplied={handleTemplateSelection}
              setInputFocus={setInputFocus}
              setShowDropzone={setShowDropzone}
            />
          ),
          title: t('pages.patients.selectedPatient.chat.templates.title'),
          onMobileClick: toggleTemplatesModal,
          condition: !shouldDisableActionsButton
        },
        {
          icon: <AttachFile fontSize="small" className={classes.icon} />,
          component: (
            <IconButton
              id="patient-room-chat-file-button"
              onClick={() => setShowDropzone(true)}
              tooltipValue={t('pages.patients.selectedPatient.chat.attachFile')}
            >
              <AttachFile fontSize="small" className={classes.icon} />
            </IconButton>
          ),
          title: t('pages.patients.selectedPatient.chat.attachFile'),
          onMobileClick: handleClickAttach,
          condition: !shouldDisableActionsButton
        },
        {
          icon: <PromotionsIcon color={disablePromotion ? 'var(--text-color-12)' : '#3C52EB'} />,
          component: (
            <Promotions
              id="patient-room-chat-promotion-button"
              disabled={disablePromotion}
              onPromotionSelection={setPromotion}
              message={message}
              setShowDropzone={setShowDropzone}
            />
          ),
          title: t('pages.patients.selectedPatient.chat.promotions.title'),
          onMobileClick: togglePromotionsModal,
          condition: !shouldDisableActionsButton && isPromotionsEnabled,
          disabled: disablePromotion,
          tooltip: disablePromotion ? t('pages.patients.selectedPatient.chat.promotions.disabled') : ''
        }
      ].filter(action => action.condition),
    [
      shouldDisableActionsButton,
      isPromotionsEnabled,
      handleTemplateSelection,
      setInputFocus,
      setPromotion,
      setShowDropzone,
      t,
      handleClickAttach,
      togglePromotionsModal,
      toggleTemplatesModal,
      disablePromotion,
      classes,
      message
    ]
  )

  return (
    <>
      <Grid item className={classes.buttonsContainer}>
        {isMobile() && actions.length > 1 ? (
          <>
            <ChatActionsModal isOpen={isActionsModalOpen} handleClose={toggleActionsModal} actions={actions} />
            <PromotionsModal
              isOpen={isPromotionsModalOpen}
              handleClose={togglePromotionsModal}
              onPromotionSelection={setPromotion}
            />
            <TemplatesModal
              open={isTemplatesModalOpen}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              onClose={toggleTemplatesModal}
              onSelect={handleTemplateSelection}
            />
            <IconButton onClick={toggleActionsModal}>
              <AddRoundedIcon className={classes.mobileIcon} />
            </IconButton>
          </>
        ) : (
          <Grid container>
            {actions.map(({ component, title }) => (
              <Grid item key={title}>
                {component}
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      <Grid item className={classes.hidden}>
        <Attach ref={attachmentsRef} onUploadFiles={setFilesToSend} multiple />
      </Grid>
    </>
  )
}

export default ChatActions
