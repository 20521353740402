import * as React from 'react'

export default () => {
  return (
    <svg width="55" height="28" viewBox="0 0 55 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 1C6.8203 1 1 6.8203 1 14C1 21.1797 6.8203 27 14 27H41C48.1797 27 54 21.1797 54 14C54 6.8203 48.1797 1 41 1H14Z"
        fill="#7F5FF9"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M12.3823 8.37885L12.6599 8.34939L12.7716 8.11627C13.5869 6.41533 16.0086 6.41533 16.8239 8.11627L16.9357 8.34939V20.0701L16.6239 20.4671C15.4952 21.9049 13.2141 21.4701 12.6934 19.7179L11.5488 19.5453C10.0056 19.3126 9.11486 17.6667 9.76414 16.2475L10.3627 14.9391L10.2363 14.8137C9.22853 13.814 9.43122 12.1327 10.6478 11.4011L10.5907 11.2436C10.1165 9.93354 10.9969 8.52591 12.3823 8.37885Z"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M14.9747 21.3464C13.6282 21.3464 12.5366 20.2548 12.5366 18.9082C12.5366 18.1105 12.9196 17.4023 13.5118 16.9575"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M14.2439 12.2026C13.5961 11.8285 13.3741 11.0002 13.7482 10.3523C13.9697 9.96855 14.3507 9.73423 14.7592 9.68472"
        stroke="white"
        strokeLinecap="round"
      />
      <path d="M10.3774 14.8436C10.7763 14.1528 11.4621 13.731 12.1974 13.6418" stroke="white" strokeLinecap="round" />
      <path d="M16.9318 4.5V24.1141" stroke="white" strokeLinecap="round" strokeDasharray="2.08 2.08" />
      <path d="M21.4462 7.70898L19.0154 10.1398H16.9318" stroke="white" />
      <path d="M21.4462 20.9053L19.0154 18.4744H16.9318" stroke="white" />
      <path d="M24.2244 14.3071H16.9318" stroke="white" />
      <path d="M24.2244 10.4873H21.7935L19.7099 12.5709H16.9318" stroke="white" />
      <path d="M24.2244 18.127H21.7935L19.7099 16.0434H16.9318" stroke="white" />
      <circle cx="21.4462" cy="7.70903" r="1.0418" fill="#7F5FF9" stroke="white" />
      <circle cx="21.4462" cy="20.9051" r="1.0418" fill="#7F5FF9" stroke="white" />
      <circle cx="24.2197" cy="10.4871" r="1.0418" fill="#7F5FF9" stroke="white" />
      <circle cx="24.2197" cy="18.127" r="1.0418" fill="#7F5FF9" stroke="white" />
      <circle cx="24.2197" cy="14.3072" r="1.0418" fill="#7F5FF9" stroke="white" />
      <circle cx="41" cy="14" r="10" fill="#26C36F" />
      <path d="M35.668 13.6666L39.3346 17.3333L46.3346 10.3333" stroke="white" strokeWidth="2" />
    </svg>
  )
}
