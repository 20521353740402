import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedHeading16 from 'components/common/text/DazzedHeading16'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { trackEvent } from 'utils/analyticsUtils'
import Actions from 'actions'
import useRolePermissions from 'hooks/useRolePermissions'
import { isPatientActive } from 'utils/statusUtils'
import TeethSelectionGrid from 'components/common/inputs/TeethSelectionGrid/TeethSelectionGrid'
import UntrackedToothGridItem from 'components/common/inputs/TeethSelectionGrid/UntrackedToothGridItem'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 40
  },
  description: {
    fontWeight: 500
  },
  teethGridContainer: {
    marginTop: 30
  }
}))

const GiTrackSettingsTab = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { permissions } = useRolePermissions()
  const { data: treatment, isUpdating } = useSelector(state => state.patientsReducer.patientCard.treatment)
  const patient = useSelector(state => state.patientsReducer.patientCard.patient)

  const [untrackedTeeth, setUntrackedTeeth] = useState([])

  const isPatientUserActive = useMemo(() => isPatientActive(patient), [patient])
  const giTrackSettings = useMemo(() => JSON.parse(treatment?.giTrackSettings || '{}'), [treatment])

  const handleSubmit = useCallback(() => {
    trackEvent(`Patient card - track settings - submit clicked`, {
      patientId: patient?.id,
      untrackedTeeth
    })

    dispatch(
      Actions.updateTreatment({
        treatmentId: treatment.id,
        giTrackSettings: JSON.stringify({ untrackedTeeth })
      })
    )
  }, [untrackedTeeth, treatment, patient, dispatch])

  useEffect(() => {
    setUntrackedTeeth(giTrackSettings?.untrackedTeeth || [])
  }, [giTrackSettings])

  return (
    <Grid container direction="column" justifyContent="space-between" className={classes.root} spacing={3}>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <DazzedHeading16>{t('dialogs.patientInfo.giTrackSettings.untrackedTeethTitle')}</DazzedHeading16>
          </Grid>
          <Grid item>
            <DazzedParagraph14 className={classes.description}>
              {t('dialogs.patientInfo.giTrackSettings.untrackedTeethDescription')}
            </DazzedParagraph14>
          </Grid>
          <Grid item className={classes.teethGridContainer}>
            <TeethSelectionGrid
              selections={untrackedTeeth}
              setSelections={setUntrackedTeeth}
              editable={permissions.editGiTrackingSettings}
              toothGridItemComponent={UntrackedToothGridItem}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {permissions.editGiTrackingSettings && (
          <Grid container justifyContent="center" alignItems="center">
            <PrimaryButton
              label={t('general.submit')}
              onClick={handleSubmit}
              isLoading={isUpdating}
              disabled={!patient || !isPatientUserActive}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default GiTrackSettingsTab
