import React, { useState, useEffect, useRef, useCallback } from 'react'
import { getMedia } from '../../utils/imageProvider'

const CachedImage = ({ s3Object, alt, applyClassName, onLoad = () => {} }) => {
  const [url, setUrl] = useState(null)
  const retriesRef = useRef(0)

  const getMediaAsync = useCallback(async (s3Object, forceResignUrl) => {
    const s3Result = s3Object ? await getMedia(s3Object, forceResignUrl) : null
    setUrl(s3Result?.uri)
  }, [])

  useEffect(() => {
    if (!url) {
      getMediaAsync(s3Object, false)
    }
  }, [getMediaAsync, s3Object, url])

  const retry = useCallback(async () => {
    if (retriesRef.current > 3) {
      return
    }
    retriesRef.current += 1

    getMediaAsync(s3Object, true)
  }, [getMediaAsync, s3Object])

  return <img src={url} alt={alt} className={applyClassName} onError={retry} onLoad={e => onLoad(e.target.src)} />
}

export default CachedImage
