import * as React from 'react'

function LowerArch(props) {
  return (
    <svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.98633 26.9285C6.06691 26.1427 6.43358 24.5713 7.25556 24.5713C8.28303 24.5713 8.58523 24.8735 8.94787 24.8131C9.3105 24.7526 9.49182 24.4504 9.9149 24.4504"
        stroke="#737B8A"
      />
      <path
        d="M4.77734 23.9669C4.79749 23.3021 5.06745 21.9724 5.98613 21.9724C7.13449 21.9724 7.07405 22.2142 7.618 22.2142C8.16196 22.2142 8.88723 22.0329 9.18943 22.2746"
        stroke="#737B8A"
      />
      <path
        d="M1.15144 8.49451C0.958038 7.96264 0.990273 5.37179 1.03057 4.14286C1.11112 4.08242 1.32057 3.96154 1.51397 3.96154C1.70738 3.96154 2.07807 4.40476 2.23925 4.62637C2.40042 4.28388 2.75903 3.5989 2.90408 3.5989C3.04914 3.5989 3.36745 4.28388 3.50848 4.62637C3.60921 4.12271 3.87112 3.11538 4.11287 3.11538C4.35463 3.11538 5.26123 4.24359 5.6843 4.80769C5.80518 3.73993 6.14364 1.6044 6.53046 1.6044C6.91727 1.6044 8.54511 3.337 9.31068 4.2033C9.79419 3.337 10.9063 1.6044 11.4865 1.6044C12.0667 1.6044 13.1385 2.97436 13.6019 3.65934C14.2667 2.77289 15.8986 1 17.1074 1C18.3162 1 19.7667 2.61172 20.2502 3.41758V9.46154C20.0085 9.80403 19.7063 10.489 16.6843 10.489C14.025 10.489 13.6623 9.0989 13.4206 8.73626C11.4865 9.94506 9.79419 8.85714 9.31068 7.89011C9.06892 8.37363 7.31617 9.76374 6.65134 9.76374C6.11947 9.76374 5.78503 8.837 5.6843 8.37363C5.32166 9.0989 3.6898 9.21978 3.44804 8.97802C3.15793 9.17143 2.40042 9.01832 2.17881 8.85714C1.83635 8.95788 1.34485 9.02637 1.15144 8.49451Z"
        stroke="#737B8A"
        strokeWidth="0.5"
      />
      <path d="M13.6022 3.65942L13.4209 8.73635" stroke="#737B8A" strokeWidth="0.5" />
      <path d="M9.31055 4.20337V7.89018" stroke="#737B8A" strokeWidth="0.5" />
      <path d="M5.68457 4.80762V8.37355" stroke="#737B8A" strokeWidth="0.5" />
      <path d="M3.50868 4.62646L3.44824 8.97811" stroke="#737B8A" strokeWidth="0.5" />
      <path d="M2.23915 4.62646L2.17871 8.85723" stroke="#737B8A" strokeWidth="0.5" />
      <path
        d="M5.74454 13.6923C5.74454 13.6923 4.71707 13.5352 4.47531 13.3901C4.23355 13.2451 3.72989 13.6521 3.50827 13.8737C3.0449 14.4176 2.11816 15.989 2.11816 17.9231C2.11816 18.588 2.84344 19.5348 3.20608 19.1319C2.98446 19.555 2.54124 20.6429 2.54124 21.6099C2.54124 22.8187 3.26652 24.2693 3.99179 24.2693C4.57201 24.2693 4.75736 24.0275 4.7775 23.9066C4.61633 24.6722 4.39069 26.3 4.7775 26.6869C5.16432 27.0737 5.74454 26.9689 5.9863 26.8682C6.02659 27.6337 6.21597 29.2737 6.65113 29.7088C7.0863 30.144 8.48446 29.5275 9.12915 29.1649C9.12915 29.9506 9.29838 31.6912 9.97531 32.3682C10.6522 33.0451 12.1914 31.5623 12.8764 30.7363C13.1786 31.6832 13.9885 33.577 14.8105 33.577C15.6324 33.577 16.3215 32.4488 16.5632 31.8847C16.8251 32.5898 17.5182 34 18.1951 34C18.872 34 19.8471 32.5898 20.25 31.8847V29.044C20.1694 28.903 19.972 28.6209 19.827 28.6209C19.6819 28.6209 18.0742 28.5 17.2885 28.4396C17.0669 28.4597 16.6116 28.5847 16.5632 28.9231C16.3819 28.6209 15.8984 28.1978 15.3544 28.0165C14.9193 27.8715 13.924 27.7143 13.4808 27.6539C13.3599 27.674 13.094 27.7748 12.9973 28.0165C12.8764 27.7143 11.0632 25.7803 10.6401 25.7198C10.5193 25.4176 10.2171 24.6319 9.91487 24.4506C9.91487 24.1484 10.0357 23 9.25003 22.2748C9.31047 21.6704 9.55223 19.011 8.40388 17.8627C8.5852 17.077 8.40388 14.7803 7.6786 13.8737C7.34014 13.4506 5.74454 13.6923 5.74454 13.6923Z"
        stroke="#737B8A"
      />
      <path
        d="M4.47531 13.3901C4.71707 13.5352 5.74454 13.6923 5.74454 13.6923C5.74454 13.6923 7.34014 13.4506 7.6786 13.8737C8.40388 14.7803 8.5852 17.077 8.40388 17.8627C9.55223 19.011 9.31047 21.6704 9.25003 22.2748C10.0357 23 9.91487 24.1484 9.91487 24.4506C10.2171 24.6319 10.5193 25.4176 10.6401 25.7198C11.0632 25.7803 12.8764 27.7143 12.9973 28.0165C13.094 27.7748 13.3599 27.674 13.4808 27.6539C13.924 27.7143 14.9193 27.8715 15.3544 28.0165C15.8984 28.1978 16.3819 28.6209 16.5632 28.9231C16.6116 28.5847 17.0669 28.4597 17.2885 28.4396C18.0742 28.5 19.6819 28.6209 19.827 28.6209C19.972 28.6209 20.1694 28.903 20.25 29.044V31.8847C19.8471 32.5898 18.872 34 18.1951 34C17.5182 34 16.8251 32.5898 16.5632 31.8847C16.3215 32.4488 15.6325 33.577 14.8105 33.577C13.9885 33.577 13.1786 31.6832 12.8764 30.7363C12.1914 31.5623 10.6522 33.0451 9.97531 32.3682C9.29838 31.6912 9.12915 29.9506 9.12915 29.1649C8.48446 29.5275 7.0863 30.144 6.65113 29.7088C6.21597 29.2737 6.02659 27.6337 5.9863 26.8682C5.74454 26.9689 5.16432 27.0737 4.7775 26.6869C4.39298 26.3023 4.61368 24.6914 4.77463 23.9203C4.74331 24.0448 4.55085 24.2693 3.99179 24.2693C3.26652 24.2693 2.54124 22.8187 2.54124 21.6099C2.54124 20.6429 2.98446 19.555 3.20608 19.1319C2.84344 19.5348 2.11816 18.588 2.11816 17.9231C2.11816 15.989 3.0449 14.4176 3.50827 13.8737C3.72989 13.6521 4.23355 13.2451 4.47531 13.3901Z"
        fill="#737B8A"
        fillOpacity="0.25"
      />
      <path d="M16.5635 28.9231V31.9451" stroke="#737B8A" />
      <path d="M12.9978 28.0166L12.877 30.7364" stroke="#737B8A" />
      <path
        d="M10.6399 25.7197C10.2168 25.6593 9.60033 26.0582 9.55198 26.445C9.50363 26.8318 9.31022 28.3992 9.12891 29.2252"
        stroke="#737B8A"
      />
      <path
        d="M3.14551 19.1923C3.36712 18.5677 3.97957 17.3791 4.6565 17.6208C5.50265 17.923 5.68397 18.2857 6.10705 18.2857C6.53012 18.2857 7.98067 17.5604 8.40375 17.8626"
        stroke="#737B8A"
      />
      <path
        d="M34.5146 26.9285C34.4341 26.1427 34.0674 24.5713 33.2454 24.5713C32.2179 24.5713 31.9157 24.8735 31.5531 24.8131C31.1905 24.7526 31.0092 24.4504 30.5861 24.4504"
        stroke="#737B8A"
      />
      <path
        d="M35.7236 23.9669C35.7035 23.3021 35.4335 21.9724 34.5148 21.9724C33.3665 21.9724 33.4269 22.2142 32.883 22.2142C32.339 22.2142 31.6137 22.0329 31.3115 22.2746"
        stroke="#737B8A"
      />
      <path
        d="M39.3495 8.49451C39.5429 7.96264 39.5107 5.37179 39.4704 4.14286C39.3899 4.08242 39.1804 3.96154 38.987 3.96154C38.7936 3.96154 38.4229 4.40476 38.2617 4.62637C38.1006 4.28388 37.7419 3.5989 37.5969 3.5989C37.4518 3.5989 37.1335 4.28388 36.9925 4.62637C36.8918 4.12271 36.6299 3.11538 36.3881 3.11538C36.1463 3.11538 35.2398 4.24359 34.8167 4.80769C34.6958 3.73993 34.3573 1.6044 33.9705 1.6044C33.5837 1.6044 31.9559 3.337 31.1903 4.2033C30.7068 3.337 29.5947 1.6044 29.0145 1.6044C28.4343 1.6044 27.3625 2.97436 26.8991 3.65934C26.2343 2.77289 24.6024 1 23.3936 1C22.1848 1 20.7343 2.61172 20.2507 3.41758V9.46154C20.4925 9.80403 20.7947 10.489 23.8167 10.489C26.476 10.489 26.8387 9.0989 27.0804 8.73626C29.0145 9.94506 30.7068 8.85714 31.1903 7.89011C31.4321 8.37363 33.1848 9.76374 33.8496 9.76374C34.3815 9.76374 34.7159 8.837 34.8167 8.37363C35.1793 9.0989 36.8112 9.21978 37.0529 8.97802C37.343 9.17143 38.1006 9.01832 38.3222 8.85714C38.6646 8.95788 39.1561 9.02637 39.3495 8.49451Z"
        stroke="#737B8A"
        strokeWidth="0.5"
      />
      <path d="M26.8988 3.65942L27.0801 8.73635" stroke="#737B8A" strokeWidth="0.5" />
      <path d="M31.1904 4.20337V7.89018" stroke="#737B8A" strokeWidth="0.5" />
      <path d="M34.8164 4.80762V8.37355" stroke="#737B8A" strokeWidth="0.5" />
      <path d="M36.9923 4.62646L37.0527 8.97811" stroke="#737B8A" strokeWidth="0.5" />
      <path d="M38.2618 4.62646L38.3223 8.85723" stroke="#737B8A" strokeWidth="0.5" />
      <path
        d="M34.7564 13.6923C34.7564 13.6923 35.7839 13.5352 36.0257 13.3901C36.2674 13.2451 36.7711 13.6521 36.9927 13.8737C37.4561 14.4176 38.3828 15.989 38.3828 17.9231C38.3828 18.588 37.6575 19.5348 37.2949 19.1319C37.5165 19.555 37.9597 20.6429 37.9597 21.6099C37.9597 22.8187 37.2345 24.2693 36.5092 24.2693C35.929 24.2693 35.7436 24.0275 35.7235 23.9066C35.8846 24.6722 36.1103 26.3 35.7235 26.6869C35.3367 27.0737 34.7564 26.9689 34.5147 26.8682C34.4744 27.6337 34.285 29.2737 33.8498 29.7088C33.4147 30.144 32.0165 29.5275 31.3718 29.1649C31.3718 29.9506 31.2026 31.6912 30.5257 32.3682C29.8487 33.0451 28.3096 31.5623 27.6246 30.7363C27.3224 31.6832 26.5125 33.577 25.6905 33.577C24.8685 33.577 24.1795 32.4488 23.9378 31.8847C23.6759 32.5898 22.9828 34 22.3059 34C21.629 34 20.6539 32.5898 20.2509 31.8847V29.044C20.3315 28.903 20.529 28.6209 20.674 28.6209C20.8191 28.6209 22.4268 28.5 23.2125 28.4396C23.4341 28.4597 23.8894 28.5847 23.9378 28.9231C24.1191 28.6209 24.6026 28.1978 25.1465 28.0165C25.5817 27.8715 26.577 27.7143 27.0202 27.6539C27.1411 27.674 27.407 27.7748 27.5037 28.0165C27.6246 27.7143 29.4378 25.7803 29.8608 25.7198C29.9817 25.4176 30.2839 24.6319 30.5861 24.4506C30.5861 24.1484 30.4652 23 31.2509 22.2748C31.1905 21.6704 30.9487 19.011 32.0971 17.8627C31.9158 17.077 32.0971 14.7803 32.8224 13.8737C33.1608 13.4506 34.7564 13.6923 34.7564 13.6923Z"
        stroke="#737B8A"
      />
      <path
        d="M36.0257 13.3901C35.7839 13.5352 34.7564 13.6923 34.7564 13.6923C34.7564 13.6923 33.1608 13.4506 32.8224 13.8737C32.0971 14.7803 31.9158 17.077 32.0971 17.8627C30.9487 19.011 31.1905 21.6704 31.2509 22.2748C30.4652 23 30.5861 24.1484 30.5861 24.4506C30.2839 24.6319 29.9817 25.4176 29.8608 25.7198C29.4378 25.7803 27.6246 27.7143 27.5037 28.0165C27.407 27.7748 27.1411 27.674 27.0202 27.6539C26.577 27.7143 25.5817 27.8715 25.1465 28.0165C24.6026 28.1978 24.1191 28.6209 23.9378 28.9231C23.8894 28.5847 23.4341 28.4597 23.2125 28.4396C22.4268 28.5 20.8191 28.6209 20.674 28.6209C20.529 28.6209 20.3315 28.903 20.2509 29.044V31.8847C20.6539 32.5898 21.629 34 22.3059 34C22.9828 34 23.6759 32.5898 23.9378 31.8847C24.1795 32.4488 24.8685 33.577 25.6905 33.577C26.5125 33.577 27.3224 31.6832 27.6246 30.7363C28.3096 31.5623 29.8487 33.0451 30.5257 32.3682C31.2026 31.6912 31.3718 29.9506 31.3718 29.1649C32.0165 29.5275 33.4147 30.144 33.8498 29.7088C34.285 29.2737 34.4744 27.6337 34.5147 26.8682C34.7564 26.9689 35.3367 27.0737 35.7235 26.6869C36.108 26.3023 35.8873 24.6914 35.7263 23.9203C35.7577 24.0448 35.9501 24.2693 36.5092 24.2693C37.2345 24.2693 37.9597 22.8187 37.9597 21.6099C37.9597 20.6429 37.5165 19.555 37.2949 19.1319C37.6575 19.5348 38.3828 18.588 38.3828 17.9231C38.3828 15.989 37.4561 14.4176 36.9927 13.8737C36.7711 13.6521 36.2674 13.2451 36.0257 13.3901Z"
        fill="#737B8A"
        fillOpacity="0.25"
      />
      <path d="M23.9375 28.9231V31.9451" stroke="#737B8A" />
      <path d="M27.5031 28.0166L27.624 30.7364" stroke="#737B8A" />
      <path
        d="M29.8611 25.7197C30.2842 25.6593 30.9006 26.0582 30.949 26.445C30.9973 26.8318 31.1908 28.3992 31.3721 29.2252"
        stroke="#737B8A"
      />
      <path
        d="M37.3555 19.1923C37.1339 18.5677 36.5214 17.3791 35.8445 17.6208C34.9983 17.923 34.817 18.2857 34.3939 18.2857C33.9709 18.2857 32.5203 17.5604 32.0972 17.8626"
        stroke="#737B8A"
      />
    </svg>
  )
}

export default LowerArch
