import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TableRow } from '@material-ui/core'
import { TableCell } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  cell: {
    borderBottom: 0
  },
  loaderContainer: {
    height: '70vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
}))

const PatientsTableLoader = props => {
  const classes = useStyles()

  return (
    <TableRow>
      <TableCell colSpan={5} align="center" className={classes.cell}>
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      </TableCell>
    </TableRow>
  )
}

export default PatientsTableLoader
