import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { trackEvent } from 'utils/analyticsUtils'
import { PatientNotFound } from 'components/common/icons'

const useStyles = makeStyles(theme => ({
  modal: {
    minWidth: 600
  }
}))

const PmsPatientNotFoundModal = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isOpen, pmsPatientName } = useSelector(state => state.pmsReducer.redirectPatientNotFoundPopup)

  const handleClose = useCallback(() => {
    dispatch(
      Actions.pmsToggleRedirectPatientNotFoundModal({
        isOpen: false,
        pmsPatientName: null
      })
    )
  }, [dispatch])

  const handleSearchPatient = useCallback(() => {
    trackEvent('PMS redirect patient not found modal - Search patient manually clicked')
    dispatch(Actions.setPatientsPresetFilter({ name: pmsPatientName }))
    handleClose()
  }, [dispatch, handleClose, pmsPatientName])

  return (
    isOpen && (
      <BaseModal
        open={isOpen}
        withCloseIcon
        variant="alert"
        title={t('pms.redirectPatientNotFoundModal.title')}
        titleImageComponent={<PatientNotFound />}
        onPrimaryBtnClick={handleSearchPatient}
        largerPrimaryButton
        buttonsWidth={245}
        onSecondaryBtnClick={handleClose}
        primaryLabel={t('pms.redirectPatientNotFoundModal.primaryButtonLabel')}
        secondaryLabel={t('general.cancel')}
        handleClose={() => {
          trackEvent('PMS redirect patient not found modal - toggled', {
            action: 'closed'
          })
          handleClose()
        }}
        className={classes.modal}
      >
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <DazzedParagraph14 strong textAlign="center">
              {t('pms.redirectPatientNotFoundModal.description')}
            </DazzedParagraph14>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </BaseModal>
    )
  )
}

export default PmsPatientNotFoundModal
