import * as React from 'react'

const FeedbackMissingDiagnostics = () => {
  return (
    <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H13C13.4142 0.25 13.75 0.585786 13.75 1C13.75 1.41421 13.4142 1.75 13 1.75H1C0.585786 1.75 0.25 1.41421 0.25 1Z"
        fill="#4151E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 5C0.25 4.58579 0.585786 4.25 1 4.25H13C13.4142 4.25 13.75 4.58579 13.75 5C13.75 5.41421 13.4142 5.75 13 5.75H1C0.585786 5.75 0.25 5.41421 0.25 5Z"
        fill="#4151E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 9C0.25 8.58579 0.585786 8.25 1 8.25H13C13.4142 8.25 13.75 8.58579 13.75 9C13.75 9.41421 13.4142 9.75 13 9.75H1C0.585786 9.75 0.25 9.41421 0.25 9Z"
        fill="#4151E6"
      />
      <circle cx="14" cy="11" r="9" fill="#F0F1FD" />
      <circle cx="14" cy="11" r="7" fill="#4151E6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 11C11 10.4477 11.4477 10 12 10H16C16.5523 10 17 10.4477 17 11C17 11.5523 16.5523 12 16 12H12C11.4477 12 11 11.5523 11 11Z"
        fill="#F0F1FD"
      />
    </svg>
  )
}

export default FeedbackMissingDiagnostics
