import * as React from 'react'

export default props => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="21" cy="21" r="20" fill="white" stroke="#E5E7EA" />
      <path
        d="M11.269 14.6182C11.269 12.7677 12.7692 11.2676 14.6197 11.2676H28.0223C29.8728 11.2676 31.3729 12.7677 31.3729 14.6182V28.0208C31.3729 29.8713 29.8728 31.3715 28.0223 31.3715H14.6197C12.7692 31.3715 11.269 29.8713 11.269 28.0208V14.6182Z"
        fill="#041333"
      />
      <path
        d="M24.0956 19.5568C25.1336 19.342 26.1593 19.0692 27.1681 18.7397C26.5651 14.0439 21.593 13.9922 21.593 13.9922C19.5463 13.9922 17.8646 14.7005 16.548 16.117C15.2758 17.4943 14.6396 19.1909 14.6396 21.2068C14.6396 23.2032 15.2529 24.8635 16.4794 26.1876C17.7964 27.6044 19.5555 28.3127 21.7568 28.3127C22.9702 28.3127 24.0676 28.1744 25.0488 27.8977C26.03 27.621 26.7402 27.3443 27.1792 27.0676L27.1857 20.9467C27.1859 20.9179 27.1795 20.8894 27.167 20.8636C27.1545 20.8377 27.1363 20.8153 27.1137 20.7979C27.0911 20.7806 27.0648 20.769 27.037 20.7639C27.0092 20.7589 26.9807 20.7605 26.9536 20.7688C26.1701 21.0178 24.233 21.5583 22.0764 21.8994V23.1499C22.8057 23.1635 23.2898 23.2295 23.5287 23.3477C23.8966 23.539 24.2431 23.9407 24.2431 24.5534V25.1469C24.2442 25.3352 24.2082 25.5218 24.1373 25.6956C24.0663 25.8695 23.9619 26.027 23.8302 26.1588C23.5002 26.4843 22.9716 26.7026 22.126 26.7026C20.5636 26.7026 19.2767 25.9779 18.8087 24.8827C18.247 23.5686 18.3188 22.8455 18.3188 21.1576C18.3188 19.1019 18.3329 18.4107 18.9893 17.3174C19.5204 16.4319 20.5552 16.0194 21.4482 16.0462C21.4482 16.0462 22.9727 15.9095 23.6786 17.2964C24.1826 18.2887 24.0956 19.5568 24.0956 19.5568Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.296 7.5C16.99 7.5 17.5525 8.06255 17.5525 8.75649V11.2695H15.0396V8.75649C15.0396 8.06255 15.6021 7.5 16.296 7.5Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.1431 16.2975C35.1431 16.9915 34.5805 17.554 33.8866 17.554L31.3736 17.554L31.3736 15.041L33.8866 15.041C34.5805 15.041 35.1431 15.6036 35.1431 16.2975Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.296 35.1445C16.99 35.1445 17.5525 34.582 17.5525 33.888V31.3751H15.0396V33.888C15.0396 34.582 15.6021 35.1445 16.296 35.1445Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 16.2975C7.5 16.9915 8.06255 17.554 8.75649 17.554L11.2695 17.554L11.2695 15.041L8.75649 15.041C8.06255 15.041 7.5 15.6036 7.5 16.2975Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3219 7.5C22.0159 7.5 22.5784 8.06255 22.5784 8.75649V11.2695H20.0654V8.75649C20.0654 8.06255 20.628 7.5 21.3219 7.5Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.1431 21.319C35.1431 22.0129 34.5805 22.5755 33.8866 22.5755L31.3736 22.5755L31.3736 20.0625L33.8866 20.0625C34.5805 20.0625 35.1431 20.6251 35.1431 21.319Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3219 35.1445C22.0159 35.1445 22.5784 34.582 22.5784 33.888V31.3751H20.0654V33.888C20.0654 34.582 20.628 35.1445 21.3219 35.1445Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 21.319C7.5 22.0129 8.06255 22.5755 8.75649 22.5755L11.2695 22.5755L11.2695 20.0625L8.75649 20.0625C8.06255 20.0625 7.5 20.6251 7.5 21.319Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3478 7.5C27.0417 7.5 27.6043 8.06255 27.6043 8.75649V11.2695H25.0913V8.75649C25.0913 8.06255 25.6539 7.5 26.3478 7.5Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.1431 26.3463C35.1431 27.0403 34.5805 27.6028 33.8866 27.6028L31.3736 27.6028L31.3736 25.0898L33.8866 25.0898C34.5805 25.0898 35.1431 25.6524 35.1431 26.3463Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3478 35.1445C27.0417 35.1445 27.6043 34.582 27.6043 33.888V31.3751H25.0913V33.888C25.0913 34.582 25.6539 35.1445 26.3478 35.1445Z"
        fill="#041333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 26.3463C7.5 27.0403 8.06255 27.6028 8.75649 27.6028L11.2695 27.6028L11.2695 25.0898L8.75649 25.0898C8.06255 25.0898 7.5 25.6524 7.5 26.3463Z"
        fill="#041333"
      />
    </svg>
  )
}
