import React, { useEffect, useRef, useState, useCallback } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import BaseModal from 'components/common/modals/BaseModal'
import NoteItem from './NoteItem'
import { Warning } from 'components/common/icons'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles({
  notes: {
    paddingTop: isMobile() ? 0 : 35,
    minHeight: 'calc(100% - 90px)'
  },
  noNotes: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  deleteModalIcon: {
    padding: '40px 40px 0px 40px'
  },
  deleteModalText: {
    marginTop: 35,
    marginBottom: 10
  }
})

const Notes = ({
  list,
  handleDelete = () => {},
  handleEdit = () => {},
  isDeleting,
  isCreating,
  doctorUserId,
  editable = true
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const ref = useRef(null)

  const [noteToDelete, setNoteToDelete] = useState(null)
  const [noteToEdit, setNoteToEdit] = useState(null)

  const handleCloseDeleteModal = useCallback(() => setNoteToDelete(null), [])
  const handleDeleteNote = useCallback(
    () =>
      handleDelete({
        id: noteToDelete.id,
        _version: noteToDelete._version
      }),
    [noteToDelete, handleDelete]
  )

  const handleCancelEditing = useCallback(() => setNoteToEdit(null), [])
  const handleSaveEditedNote = useCallback(
    (note, editedBody) => {
      if (note.body !== editedBody) {
        handleEdit({
          id: note.id,
          body: editedBody,
          _version: note._version
        })
      }
      setNoteToEdit(null)
    },
    [handleEdit]
  )

  useEffect(() => {
    if (!isMobile() && list.length && ref?.current) {
      if (ref?.current?.scrollIntoView) {
        ref.current.scrollIntoView({
          behavior: 'instant'
        })
      }
    }
  }, [list.length])

  useEffect(() => {
    if (!isDeleting) {
      handleCloseDeleteModal()
    }
  }, [handleCloseDeleteModal, isDeleting])

  return (
    <>
      {!!list.length ? (
        <div className={classes.notes} id="notes-container">
          {list.map((note, index) => (
            <NoteItem
              ref={index === list.length - 1 ? ref : null}
              key={note.id}
              id={note.id}
              date={moment(note.createdAt).format('DD MMM YYYY hh:mm A')}
              metadata={note.metadata}
              body={note.body}
              onDelete={() => setNoteToDelete(note)}
              onEdit={() => setNoteToEdit(note)}
              onCancelEditing={handleCancelEditing}
              onSaveEditedNote={editedBody => handleSaveEditedNote(note, editedBody)}
              isEditingMode={noteToEdit?.id === note.id}
              isCreating={isCreating}
              doctorUserId={doctorUserId}
              testIdPrefix={`note-${index}`}
              editable={editable}
            />
          ))}
          <BaseModal
            open={!!noteToDelete}
            onPrimaryBtnClick={handleDeleteNote}
            onSecondaryBtnClick={handleCloseDeleteModal}
            primaryLabel={t('general.yes')}
            secondaryLabel={t('general.cancel')}
            handleClose={handleCloseDeleteModal}
            isLoading={isDeleting}
            buttonsHeight="36px"
            primaryButtonId="deleteNote-confirm"
            secondaryButtonId="deleteNote-cancel"
          >
            <Grid container direction="column" alignItems="center" className={classes.deleteModalIcon}>
              <Grid item>
                <Warning />
              </Grid>
              <Grid item>
                <DazzedParagraph16 strong className={classes.deleteModalText}>
                  {t('dialogs.notes.deleteNote')}
                </DazzedParagraph16>
              </Grid>
            </Grid>
          </BaseModal>
        </div>
      ) : (
        <DazzedParagraph16 className={classes.noNotes}>{t('dialogs.notes.noNotes')}</DazzedParagraph16>
      )}
    </>
  )
}

export default Notes
