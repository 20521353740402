import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import { InfoSmall } from 'components/common/icons'
import { Overdue, ReinvitePatientsEmptyState } from 'components/common/icons'
import OggHeading27 from 'components/common/text/OggHeading27'
import images from 'resources'
import DazzedHeading36 from 'components/common/text/DazzedHeading36'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import { useSelector } from 'react-redux'
import useAppliedPatientsFilter from 'components/Search/hooks/useAppliedPatientsFilter'
import { createIdForTest } from 'utils/testingUtils.js'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import Tooltip from 'components/common/Tooltip'

const useStyles = () =>
  makeStyles({
    card: {
      maxWidth: 600,
      padding: '15px 30px 20px 15px !important',
      backgroundSize: 'cover',
      borderRadius: '10px 15px 60px 10px',
      border: '1px solid var(--bg-color-14)'
    },
    overdueIcon: {
      marginTop: 4
    },
    invitePatientsIcon: {
      marginBottom: -4
    },
    cardMargin: {
      marginRight: 20
    },
    cardsContainer: {
      width: '100%',
      paddingRight: '50px !important'
    },
    amount: {
      paddingLeft: '20px !important'
    },
    actionButton: {
      padding: 20,
      fontWeight: 600
    },
    description: {
      whiteSpace: 'pre-wrap'
    },
    cardTooltip: {
      display: 'flex',
      alignSelf: 'center'
    }
  })()

const UsefulInformation = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { overdueCount, invitedPatientsCount } = useSelector(state => state.appReducer.about)
  const { applyFilters } = useAppliedPatientsFilter()

  const hasMetrics = useMemo(
    () => !isNaN(overdueCount) && !isNaN(invitedPatientsCount),
    [invitedPatientsCount, overdueCount]
  )

  const cards = useMemo(
    () => [
      {
        key: 'overdueScans',
        icon: <Overdue className={classes.overdueIcon} />,
        title: t('pages.patients.emptyState.usefulInformation.overdueScans.title'),
        tooltip: t('pages.patients.emptyState.usefulInformation.overdueScans.tooltip'),
        buttonLabel: t('pages.patients.emptyState.usefulInformation.overdueScans.buttonLabel'),
        backgroundImage: images.overduePatientsBackground,
        backgroundImageConfetti: images.overduePatientsBackgroundConfetti,
        amount: overdueCount,
        disabled: overdueCount === 0,
        handleClick: () => {
          trackEvent('Empty state - filter overdue patients clicked')
          applyFilters({
            scan: ['overdueScan']
          })
        }
      },
      {
        key: 'invitedPatients',
        icon: <ReinvitePatientsEmptyState className={classes.invitePatientsIcon} />,
        title: t('pages.patients.emptyState.usefulInformation.invitedPatients.title'),
        tooltip: t('pages.patients.emptyState.usefulInformation.invitedPatients.tooltip'),
        buttonLabel: t('pages.patients.emptyState.usefulInformation.invitedPatients.buttonLabel'),
        backgroundImage: images.invitedPatientsBackground,
        backgroundImageConfetti: images.invitedPatientsBackgroundConfetti,
        amount: invitedPatientsCount,
        disabled: invitedPatientsCount === 0,
        handleClick: () => {
          trackEvent('Empty state - filter invited patients clicked')
          applyFilters({
            status: ['invited']
          })
        }
      }
    ],
    [applyFilters, classes, invitedPatientsCount, overdueCount, t]
  )

  return (
    hasMetrics && (
      <Grid container direction="column" alignItems="flex-start" spacing={1}>
        <Grid item>
          <OggHeading27>{t('pages.patients.emptyState.usefulInformation.title')}</OggHeading27>
        </Grid>
        <Grid item className={classes.cardsContainer}>
          <Grid container wrap="nowrap">
            {cards.map((card, i) => (
              <Grid
                item
                xs={6}
                key={`${card.label}-${i}`}
                className={`${classes.card} ${i < cards.length - 1 ? classes.cardMargin : ''}`}
                style={{
                  backgroundImage: `url('${card.amount > 0 ? card.backgroundImage : card.backgroundImageConfetti}')`
                }}
              >
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  id={createIdForTest({ componentId: 'empty-state-insights', title: card.key })}
                >
                  <Grid item>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>{card.icon}</Grid>
                      <Grid item>
                        <DazzedParagraph16 strong>{card.title}</DazzedParagraph16>
                      </Grid>
                      <Grid item>
                        <Tooltip
                          value={card.tooltip}
                          variant="dark"
                          placement="bottom"
                          arrow
                          style={{ paddingTop: 5 }}
                          wrapperClassName={classes.cardTooltip}
                        >
                          <InfoSmall fontSize="inherit" />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <DazzedParagraph12 className={classes.description}>{card.description}</DazzedParagraph12>
                  </Grid>
                  <Grid item className={classes.amount}>
                    <DazzedHeading36 thin>{card.amount}</DazzedHeading36>
                  </Grid>
                  <Grid item>
                    <SecondaryButton
                      dense
                      disabled={card.disabled}
                      label={card.buttonLabel}
                      onClick={card.handleClick}
                      transparentBackground
                      className={classes.actionButton}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    )
  )
}

export default UsefulInformation
