import { Collapse } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { REMOTE_CONSULTATION_PROGRAM } from 'consts/appConsts'
import { COUNTRY_US } from 'consts/countryConsts'
import { getRCShowMore as getRCShowMoreFromStorage, setRCShowMore as setRCShowMoreToStorage } from 'utils/storageUtils'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import ProgramChip from '../../../Patients/ProgramChip'
import ExpandMore from 'components/common/ExpandMore'
import ServicesToggle from '../ServicesToggle'
import RCConfiguration from './RCConfiguration'
import { trackEvent } from 'utils/analyticsUtils'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import RCGuidance from './RCGuidance'
import usePlanLimits from 'hooks/usePlanLimits'

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 12
  },
  spacer: {
    height: 30
  },
  largeSpacer: {
    height: 45
  },
  smallSpacer: {
    height: 15
  },
  noteText: {
    color: 'var(--text-color-17)',
    marginBottom: 10
  },
  description: {
    marginBottom: 8
  },
  expandMore: {
    textDecoration: 'underline',
    color: 'var(--text-color-25)',
    fontWeight: 400
  }
}))

const RCToggle = ({ isRcFFEnabled, isEnabled }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isFreePlan } = usePlanLimits()

  const isLoading = useSelector(state => state.profileReducer.isLoading)
  const practiceCountry = useSelector(state => state.practiceReducer.details.country)

  const [isTurnOnModalOpen, setIsTurnOnModalOpen] = useState(false)
  const [isTurnOffModalOpen, setIsTurnOffModalOpen] = useState(false)
  const [showMoreExpanded, setShowMoreExpanded] = useState(!!getRCShowMoreFromStorage())

  useEffect(() => {
    if (!isLoading) {
      setIsTurnOffModalOpen(false)
      setIsTurnOnModalOpen(false)
    }
  }, [isLoading])

  const handleToggleRcService = useCallback(
    isRcEnabled => {
      trackEvent(`RC - turn ${isRcEnabled ? 'on' : 'off'} - applied `)
      dispatch(Actions.requestUpdateAppSettings({ isRcEnabled }))
    },
    [dispatch]
  )

  const handleChangeShowMoreExpandable = useCallback(() => {
    setShowMoreExpanded(!showMoreExpanded)
    setRCShowMoreToStorage(!showMoreExpanded)
  }, [showMoreExpanded])

  const onTogglePressed = useCallback(checked => {
    trackEvent(`RC - turn ${checked ? 'on' : 'off'} toggle - clicked `)
    checked ? setIsTurnOnModalOpen(true) : setIsTurnOffModalOpen(true)
  }, [])

  const handleCloseModal = useCallback((isTurnOnModal = true) => {
    trackEvent(`RC - turn ${isTurnOnModal ? 'on' : 'off'} - closed `)
    isTurnOnModal ? setIsTurnOnModalOpen(false) : setIsTurnOffModalOpen(false)
  }, [])

  useEffect(() => {
    setShowMoreExpanded(isEnabled)
  }, [isEnabled])

  return (
    <>
      <ServicesToggle
        tagComponent={<ProgramChip program={REMOTE_CONSULTATION_PROGRAM} />}
        title={t('pages.accountSettings.services.remoteConsultation.title')}
        checked={isEnabled}
        disabled={isFreePlan || !isRcFFEnabled}
        onChange={onTogglePressed}
        tooltipText={t('pages.accountSettings.services.remoteConsultation.tooltip')}
      >
        <RCGuidance guidancesTypes={['howDoesItWork', 'howToSetUp']} analyticSource="Settings" />
        <DazzedParagraph14 strong className={classes.description}>
          {t('pages.accountSettings.services.remoteConsultation.description')}
        </DazzedParagraph14>
        {practiceCountry === COUNTRY_US && (
          <DazzedParagraph14 className={classes.noteText}>
            {t('pages.accountSettings.services.remoteConsultation.costNote')}
          </DazzedParagraph14>
        )}
        {isEnabled && (
          <>
            <Collapse in={showMoreExpanded}>
              <RCConfiguration isEnabled={isEnabled} />
            </Collapse>
            <DazzedParagraph14>
              <ExpandMore
                expanded={showMoreExpanded}
                onClick={handleChangeShowMoreExpandable}
                showLessText={t('pages.accountSettings.services.remoteConsultation.hideMoreSettings')}
                showMoreText={t('pages.accountSettings.services.remoteConsultation.showMoreSettings')}
                className={classes.expandMore}
              />
            </DazzedParagraph14>
          </>
        )}
      </ServicesToggle>

      <BaseModal
        open={isTurnOnModalOpen}
        title={t('pages.accountSettings.services.remoteConsultation.warningTitle')}
        variant="alert"
        isLoading={isLoading}
        onPrimaryBtnClick={() => handleToggleRcService(true)}
        onSecondaryBtnClick={() => handleCloseModal(true)}
        primaryLabel={t('pages.accountSettings.services.remoteConsultation.turnOn')}
        secondaryLabel={t('general.cancel')}
        handleClose={() => handleCloseModal(true)}
      >
        <DazzedParagraph16 textAlign="center">
          {t('pages.accountSettings.services.remoteConsultation.turnOnWarning')}
        </DazzedParagraph16>
      </BaseModal>

      <BaseModal
        open={isTurnOffModalOpen}
        title={t('pages.accountSettings.services.remoteConsultation.warningTitle')}
        variant="alert"
        isLoading={isLoading}
        onPrimaryBtnClick={() => handleToggleRcService(false)}
        onSecondaryBtnClick={() => handleCloseModal(false)}
        primaryLabel={t('pages.accountSettings.services.remoteConsultation.turnOff')}
        secondaryLabel={t('general.cancel')}
        handleClose={() => handleCloseModal(false)}
      >
        <DazzedParagraph16 textAlign="center">
          {t('pages.accountSettings.services.remoteConsultation.turnOffWarning')}
        </DazzedParagraph16>
      </BaseModal>
    </>
  )
}

export default RCToggle
