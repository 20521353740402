import React, { useMemo } from 'react'
import moment from 'moment'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  date: {
    margin: '5px 0px 5px 0px',
    userSelect: 'none'
  }
}))

const DateLabelTimelineItem = ({ timelineItem }) => {
  const classes = useStyles()

  const label = useMemo(() => moment(timelineItem.eventDate).format(`MMMM 'YY`), [timelineItem])

  return (
    <DazzedParagraph14 color="var(--text-color-11)" className={classes.date}>
      {label}
    </DazzedParagraph14>
  )
}

export default DateLabelTimelineItem
