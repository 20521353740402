import { useCallback, useMemo } from 'react'
import useQueryParams from 'hooks/useQueryParams'
import { trackEvent } from 'utils/analyticsUtils'

export default () => {
  const { setQueryParams, queryParams } = useQueryParams()

  const selectedTimelineItemId = useMemo(() => queryParams.timelineItem, [queryParams.timelineItem])

  const setSelectedTimelineItem = useCallback(
    ({ timelineItemId, source, timelineItemType, section = '' }) => {
      setQueryParams({ ...queryParams, timelineItem: timelineItemId }, section)

      if (source || timelineItemType) {
        trackEvent(`Timeline - item selected`, {
          timelineItemId,
          source,
          timelineItemType
        })
      }
    },
    [setQueryParams, queryParams]
  )

  const clearSelectedTimelineItem = useCallback(() => {
    setQueryParams({ ...queryParams, timelineItem: undefined })
  }, [setQueryParams, queryParams])

  return {
    selectedTimelineItemId,
    setSelectedTimelineItem,
    clearSelectedTimelineItem
  }
}
