import moment from 'moment'
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { isMobile } from 'utils/mobileUtils'
import LeadNameAndEmail from './LeadNameAndEmail'
import { Grid } from '@material-ui/core'
import RCDashboardActions from './RCDashboardActions'
import useRolePermissions from 'hooks/useRolePermissions'

const useStyles = makeStyles(theme => ({
  modal: {
    minWidth: '720px'
  },
  titleContainer: {
    textAlign: 'start',
    width: '100%',
    padding: '0 16px'
  },
  modalContent: {
    textAlign: 'start'
  },
  customContentWrapper: {
    padding: '16px',
    height: '100%'
  },
  divider: {
    background: 'var(--bg-color-51)',
    height: 1,
    margin: '0 0 16px 0'
  },
  infoRow: {
    padding: '8px 24px!important'
  },
  infoLabel: {
    fontWeight: 500
  },
  patientsNote: {
    wordBreak: 'break-word',
    whiteSpace: 'pre'
  },
  actions: {
    marginTop: 40
  },
  actionButton: {
    color: 'var(--text-color-3)',
    fontWeight: 600,
    cursor: 'pointer',
    '& span': {
      textDecoration: 'underline',
      margin: '0 6px'
    }
  },
  approveLabel: {
    color: 'var(--text-color-33)'
  },
  denyLabel: {
    color: 'var(--text-color-17)'
  },
  address2: {
    padding: '0px 24px!important'
  },
  spacer: {
    width: '50px'
  }
}))

const RCLeadInfoPopup = ({
  isOpen,
  lead,
  onClose,
  onDeny,
  onApprove,
  handleActionSelection,
  leadsBeingSaved,
  LEAD_ACTIONS
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()

  return (
    <BaseModal
      open={isOpen}
      handleClose={onClose}
      title={t('pages.rcDashboard.dialogs.leadInfo.title')}
      className={classes.modal}
      variant={isMobile() ? 'fullscreen' : 'alert'}
      titleClassName={classes.titleContainer}
      contentClassName={classes.modalContent}
    >
      {isOpen && (
        <Grid container direction="column" justifyContent="space-between" className={classes.customContentWrapper}>
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <LeadNameAndEmail selectedLead={lead} justifyContent="flex-start" />
              </Grid>
              <Grid item>
                <div className={classes.divider} />
              </Grid>
              <Grid item className={classes.infoRow}>
                <span className={classes.infoLabel}>{t('pages.rcDashboard.table.age')}:</span> {lead?.age}
              </Grid>
              <Grid item className={classes.infoRow}>
                <span className={classes.infoLabel}>{t('pages.rcDashboard.table.phone')}:</span> {lead?.phone}
              </Grid>
              <Grid item className={classes.infoRow}>
                <Grid container spacing={2}>
                  <Grid item>
                    <span className={classes.infoLabel}>{t('pages.rcDashboard.table.address')}:</span>
                    <div>{lead?.address}</div>
                  </Grid>
                  <Grid item>
                    <span className={classes.infoLabel}>{t('pages.rcDashboard.table.country')}:</span>
                    <div>{lead?.country}</div>
                  </Grid>
                  <Grid item>
                    <span className={classes.infoLabel}>{t('pages.rcDashboard.table.state')}:</span>
                    <div>{lead?.state}</div>
                  </Grid>
                </Grid>
              </Grid>
              {lead?.address2 !== '-' && (
                <Grid item className={classes.address2}>
                  <div>{lead?.address2}</div>
                </Grid>
              )}
              <Grid item className={classes.infoRow}>
                <span className={classes.infoLabel}>{t('pages.rcDashboard.table.leadSubmissionDate')}:</span>
                <div>{moment(lead?.createdAt).format('ll')}</div>
              </Grid>
              <Grid item className={classes.infoRow}>
                <span className={classes.infoLabel}>{t('pages.rcDashboard.table.additionalInfo')}:</span>
                <div className={classes.patientsNote}>{lead?.additionalInfo}</div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.infoRow}>
            <Grid
              container
              className={classes.actions}
              spacing={2}
              justifyContent={isMobile() ? 'center' : 'flex-start'}
            >
              <RCDashboardActions
                lead={lead.leadObj}
                LEAD_ACTIONS={LEAD_ACTIONS}
                isLeadBeingSaved={!!leadsBeingSaved[lead?.id]}
                onActionSelected={handleActionSelection}
                withAdminActions={permissions.rcDashboardAdminActions}
                analyticsSource="Lead info popup"
                alignment="row"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </BaseModal>
  )
}

export default RCLeadInfoPopup
