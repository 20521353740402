import { differenceWith } from 'lodash'
import { createSelector } from 'reselect'
import { isUserOfAnyAdminRole } from 'utils/authUtils'
import i18n from 'resources/locales/i18n'
import { isGPPlan } from 'utils/billingUtils'
import { gpSystemTemplatesKeys } from 'consts/gpConsts'

const profileState = state => state.profileReducer
const patientsState = state => state.patientsReducer
const multiPracticeState = state => state.multiPracticeReducer

export const selectPickableTags = createSelector(
  [profileState, multiPracticeState, patientsState],
  (profile, multiPracticeState, patients) => {
    let doctorTags = []

    if (isUserOfAnyAdminRole()) {
      doctorTags = multiPracticeState.context.customTags
    } else {
      doctorTags = profile.doctor?.tags?.items?.filter(tag => !tag._deleted && !tag.isDeleted) || []
    }

    const patientTags =
      patients.patient?.patientTags?.items?.filter(patientTag => !patientTag._deleted && patientTag.tag) || []

    const pickableTags = differenceWith(
      doctorTags,
      patientTags,
      (tag, patientTag) => tag.value === patientTag.tag.value
    )

    return pickableTags
  }
)

export const selectCustomTemplates = state =>
  state.profileReducer.customTemplates.data?.sort((a, b) => a.title.localeCompare(b.title))

export const selectSystemTemplates = state =>
  state.profileReducer.systemTemplates?.data
    ?.filter(template =>
      isGPPlan(state.practiceReducer.billing.grinPlanKey)
        ? gpSystemTemplatesKeys.some(gpTemplateKey => template.title.includes(gpTemplateKey))
        : true
    )
    ?.map(template => ({ ...template, title: i18n.t(template.title), text: i18n.t(template.text) }))
    .sort((a, b) => a.title.localeCompare(b.title))
