import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import FormInput from 'components/common/FormInput'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { PatientBriefActions } from 'consts/hiToolsConsts'

const useStyles = makeStyles(theme => ({
  input: {
    width: '50px!important'
  }
}))

const RPECategory = ({ categoryValues = {}, onChange, viewMode }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <DazzedParagraph14 strong>
          {t('dialogs.patientBrief.actions.numberOfTurns')}
          {viewMode && <span style={{ marginLeft: 3 }}>{categoryValues[PatientBriefActions.NumberOfTurns]}</span>}
        </DazzedParagraph14>
      </Grid>
      {!viewMode && (
        <Grid item>
          <FormInput
            value={categoryValues[PatientBriefActions.NumberOfTurns]}
            setValue={numberOfTurns => onChange({ [PatientBriefActions.NumberOfTurns]: numberOfTurns })}
            style={{ bright: true, borderBottom: true }}
            keepErrorContainerWhenInactive={false}
            inputWrapperClassName={classes.input}
            placeholder={t('dialogs.patientBrief.actions.numberOfTurnsInputPlaceholder')}
            type="number"
            minValue={0}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default RPECategory
