import React from 'react'
import { makeStyles, TableCell } from '@material-ui/core'
import PatientStatus from 'components/Patients/PatientStatus'

const useStyles = ({ highlighted, rowIndex }) =>
  makeStyles({
    root: {
      height: '78px',
      borderTop: rowIndex === 0 && highlighted ? '1px solid' : 0,
      borderColor: highlighted ? 'var(--border-color-1)' : 'var(--border-color-11)'
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Dazzed',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 500
    }
  })({ highlighted, rowIndex })

const ProgramCell = ({ status, actionRequired = false, rowIndex }) => {
  const classes = useStyles({ highlighted: actionRequired, rowIndex })
  return (
    <TableCell align="left" classes={{ root: classes.root }}>
      <div className={classes.content}>
        <PatientStatus status={status} />
      </div>
    </TableCell>
  )
}

export default React.memo(ProgramCell)
