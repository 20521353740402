import { CircularProgress, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../actions'
import { isValidEmail } from '../../utils/generalUtils'
import FormInput from '../common/FormInput'
import BaseModal from '../common/modals/BaseModal'
import { isMobile } from 'utils/mobileUtils'
import { trackEvent } from 'utils/analyticsUtils'
import { AsyncStatus } from 'consts'

const useInvitePatientStyles = ({ isMobile }) =>
  makeStyles({
    body: {
      minWidth: isMobile ? 'inherit' : 650,
      maxHeight: 800
    },
    loaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1
    },
    numberOfLicenses: {
      lineHeight: 1.5,
      padding: 8,
      letterSpacing: '0.1em',
      fontWeight: 500,
      color: 'var(--text-color-2)'
    },
    cyclePicker: {
      paddingTop: 15
    },
    dialogContent: {
      paddingLeft: 35,
      paddingRight: 35
    },
    legalGuardian: {
      color: '#3C52EF',
      marginLeft: 4
    },
    sectionHeader: {
      paddingLeft: 6,
      display: 'inline-block',
      lineHeight: '20px'
    }
  })({ isMobile })

const ResendInvitePatientModal = ({ handleClose, doctorId, isOpen }) => {
  const classes = useInvitePatientStyles({ isMobile: isMobile() })
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [formErrors, setFormErrors] = useState()
  const [shouldShowErrors, setShouldShowErrors] = useState(false)

  const patient = useSelector(state => state.patientsReducer.invite.resendToPatient)
  const doctorPatients = useSelector(state => state.chatReducer.rooms)
  const { status: siblingsLoadStatus, siblings: siblingsList } = useSelector(
    state => state.patientsReducer.invite.resend
  )

  const isRecordsOnly = useMemo(() => patient?.isRecordsOnly, [patient?.isRecordsOnly])

  const hasActiveSiblings = useMemo(() => siblingsList.some(patientSM => !!patientSM.patient), [siblingsList])
  const isEmailDisabled = useMemo(
    () => patient?.user.program === 'rc' || patient?.user.program === 'ortho' || hasActiveSiblings,
    [hasActiveSiblings, patient?.user.program]
  )

  const resendInvitation = useCallback(() => {
    trackEvent('Resend invite popup', { action: 'resend' })
    dispatch(
      Actions.resendPatientInvitation({
        patientEmail: email.toLowerCase(),
        patientFirstName: firstName,
        patientLastName: lastName,
        patientName: `${firstName} ${lastName}`,
        patientId: patient.id,
        isLead: patient.isLead
      })
    )
  }, [dispatch, email, firstName, lastName, patient])

  const validateInputs = useCallback(() => {
    const errors = {}

    if (patient?.user.program === 'rc' || patient?.user.program === 'ortho') {
      return true
    }

    if (!firstName?.trim()) {
      errors.firstName = t('errors.requiredField')
    }

    if (!lastName?.trim()) {
      errors.lastName = t('errors.requiredField')
    }

    if (!email) {
      errors.email = t('errors.requiredField')
    } else if (!isValidEmail(email)) {
      errors.email = t('errors.invalidEmail')
    } else if (patient?.details.email !== email && doctorPatients.find(room => room.patient.details.email === email)) {
      errors.email = t('dialogs.invitePatient.errors.patientExists')
    }

    setFormErrors(errors)
    return isEmpty(errors)
  }, [patient, firstName, lastName, email, doctorPatients, t])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!validateInputs()) {
        setShouldShowErrors(true)
        return
      }
      resendInvitation()

      handleClose()
    },
    [validateInputs, resendInvitation, handleClose]
  )

  useEffect(() => {
    setEmail(isRecordsOnly ? '' : patient.details.email)

    if (patient.details.firstName && patient.details.lastName) {
      setFirstName(patient.details.firstName)
      setLastName(patient.details.lastName)
    } else {
      setFirstName(patient.details.name.substr(0, patient.details.name.indexOf(' ')))
      setLastName(patient.details.name.substr(patient.details.name.indexOf(' ') + 1))
    }
  }, [isRecordsOnly, patient])

  useEffect(() => {
    if (shouldShowErrors) {
      validateInputs()
    }
  }, [email, shouldShowErrors, validateInputs])

  useEffect(() => {
    if (!patient?.guardianId) {
      return
    }

    dispatch(Actions.fetchPatientSiblingsForResend({ guardianId: patient.guardianId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BaseModal
      open={isOpen}
      withCloseIcon={false}
      title={isRecordsOnly ? t('dialogs.invitePatient.sendInviteTitle') : t('dialogs.invitePatient.resendTitle')}
      contentClassName={classes.dialogContent}
      onSecondaryBtnClick={() => {
        trackEvent('Resend invite popup', { action: 'cancel' })
        handleClose()
      }}
      isPrimaryDisabled={siblingsLoadStatus === AsyncStatus.Loading}
      secondaryLabel={t('general.cancel')}
      onPrimaryBtnClick={handleSubmit}
      primaryLabel={isRecordsOnly ? t('general.send') : t('dialogs.invitePatient.resendBtn')}
      handleClose={handleClose}
      className={[classes.body].join(' ')}
    >
      <Grid container spacing={isMobile() ? 1 : 3}>
        {siblingsLoadStatus === AsyncStatus.Loading ? (
          <Grid item style={{ width: '100%' }}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <CircularProgress size={40} />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={isMobile() ? 12 : 6}>
              <FormInput
                title={t('dialogs.invitePatient.firstNameLabel')}
                style={{ bright: true, thick: true }}
                value={firstName}
                setValue={setFirstName}
                allowAutoComplete={false}
                errorMessage={formErrors?.firstName}
                isDisabled={patient?.user.program === 'rc' || patient?.user.program === 'ortho'}
              />
            </Grid>
            <Grid item xs={isMobile() ? 12 : 6}>
              <FormInput
                title={t('dialogs.invitePatient.lastNameLabel')}
                style={{ bright: true, thick: true }}
                value={lastName}
                setValue={setLastName}
                allowAutoComplete={false}
                errorMessage={formErrors?.lastName}
                isDisabled={patient?.user.program === 'rc' || patient?.user.program === 'ortho'}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                title={t('dialogs.invitePatient.contactEmailLabel')}
                style={{ bright: true, thick: true }}
                value={email}
                setValue={setEmail}
                allowAutoComplete={false}
                errorMessage={formErrors?.email}
                isDisabled={isEmailDisabled}
              />
            </Grid>
          </>
        )}
      </Grid>
    </BaseModal>
  )
}

export default ResendInvitePatientModal
