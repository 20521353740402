import { generateZipFromS3Objects } from 'utils/fileUtils'
import { saveAs } from 'file-saver'
import { logError } from 'utils/logUtils'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import { useTranslation } from 'react-i18next'

export default () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const downloadAttachments = async attachments => {
    try {
      const zip = await generateZipFromS3Objects(attachments)
      saveAs(zip, `attachments.zip`)
    } catch (error) {
      logError('An error occured while trying to download attachments of shared scan', {
        error
      })
      dispatch(
        Actions.showSnackbar({
          text: t('pages.referrals.review.downloadError'),
          type: 'error'
        })
      )
    }
  }

  return {
    downloadAttachments
  }
}
