import React from 'react'
import BaseModal from 'components/common/modals/BaseModal'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  title: {
    paddingBottom: '10px !important'
  },
  subtitle: {
    marginBottom: 15
  },
  actions: {
    paddingBottom: '18px !important'
  }
})

const CloseBroadcastModalMobile = ({ isOpen, handleSubmit, handleCancel }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleCancel}
      onSecondaryBtnClick={handleCancel}
      onPrimaryBtnClick={handleSubmit}
      primaryLabel={t('general.confirm')}
      secondaryLabel={t('general.cancel')}
      title={t('pages.patients.patientsList.broadcast.closeBroadcastBodal.title')}
      variant="alert"
      withCloseIcon={false}
      titleClassName={classes.title}
      actionsClassName={classes.actions}
      primaryButtonId="closeBroadcast-confirm"
      secondaryButtonId="closeBroadcast-cancel"
    >
      <DazzedParagraph14 className={classes.subtitle} strong textAlign="center">
        {t('pages.patients.patientsList.broadcast.closeBroadcastBodal.subtitle')}
      </DazzedParagraph14>
    </BaseModal>
  )
}

export default CloseBroadcastModalMobile
