import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { FormControlLabel, RadioGroup } from '@material-ui/core'
import GrinRadio from './GrinRadio'
import classNames from 'classnames'
import { Sizes } from 'consts/styleConsts'

const GrinRadioGroup = ({
  options = [],
  value,
  setValue = value => {},
  size = Sizes.Medium,
  isDisabled = false,
  radioGroupClassName,
  labelClassName,
  labelRootClassName = '',
  customComponent
}) => {
  const classes = makeStyles({
    label: {
      color: 'var(--text-color-6)'
    },
    smallLabel: {
      fontSize: 14
    }
  })({ isDisabled })

  return (
    <RadioGroup row value={value} onChange={e => setValue(e.target.value)} className={classNames(radioGroupClassName)}>
      {options.map(option => (
        <FormControlLabel
          classes={{
            root: labelRootClassName,
            label: classNames(classes.label, labelClassName, size === Sizes.Small && classes.smallLabel)
          }}
          disabled={isDisabled}
          key={option.value}
          value={option.value}
          label={option.label || option.value}
          control={<GrinRadio size={size} checked={value === option.value} />}
        />
      ))}
      {value === 'custom' && customComponent}
    </RadioGroup>
  )
}

export default GrinRadioGroup
