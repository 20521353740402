import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { isMobile } from 'utils/mobileUtils'
import BeforeAfterDialogDesktop from './BeforeAfterDialog.Desktop'
import BeforeAfterDialogMobile from './BeforeAfterDialog.mobile'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getObjectUrl } from 'utils/mediaUtils'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import useRolePermissions from 'hooks/useRolePermissions'
import { SCAN_SUMMARY_POSES } from 'consts/scanSummaryConsts'

const BeforeAfterDialogHOC = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isOpen, assetStatus, asset, patientId, lastScanId, firstScanId, error } = useSelector(
    state => state.treatmentReducer.beforeAfter
  )
  const patient = useSelector(state => state.patientsReducer.patient)
  const doctor = useSelector(state => state.profileReducer.doctor)
  const imgSrc = useMemo(() => (asset ? getObjectUrl(asset) : ''), [asset])
  const { permissions } = useRolePermissions()

  const [messageText, setMessageText] = useState(t('messages.beforeAfterMessage'))
  const [selectedPose, setSelectedPose] = useState(SCAN_SUMMARY_POSES.center)

  const analyticsMetadata = useMemo(
    () => ({
      lastScanId,
      firstScanId,
      patientId: patient.id
    }),
    [lastScanId, patient?.id, firstScanId]
  )

  const handleClose = useCallback(() => {
    dispatch(Actions.toggleBeforeAfterDialog({ open: false }))
    setSelectedPose(SCAN_SUMMARY_POSES.center)
  }, [dispatch])

  const handleRequestAsset = useCallback(() => {
    dispatch(
      Actions.fetchBeforeAfterAsset({
        patientId,
        lastScanId,
        firstScanId,
        pose: selectedPose
      })
    )
  }, [dispatch, patientId, lastScanId, firstScanId, selectedPose])

  const handleRetry = useCallback(() => {
    trackEvent('Before After - retry clicked', {
      patientId,
      firstScanId,
      lastScanId
    })

    handleRequestAsset()
  }, [handleRequestAsset, patientId, lastScanId, firstScanId])

  const handleShareOptionClicked = useCallback(
    action => {
      trackEvent('Before After - action', {
        action,
        ...analyticsMetadata,
        isMobile: isMobile()
      })
    },
    [analyticsMetadata]
  )

  const sendAssetInChat = useCallback(() => {
    if (!patient || !asset || !permissions.sendChatMessages) {
      return
    }

    handleShareOptionClicked('send in chat')

    dispatch(
      Actions.sendChatMessage({
        members: [patient.username, patient.doctor.username],
        roomId: patient.user?.rooms.items?.[0].roomId,
        type: 'beforeAfter',
        content: JSON.stringify({
          shareAsset: asset,
          text: messageText
        }),
        patientUsername: patient.username,
        readBy: doctor.username,
        patientDoctorUsername: patient?.doctor?.username
      })
    )

    handleClose()
  }, [patient, doctor, asset, dispatch, handleClose, handleShareOptionClicked, messageText, permissions])

  const handleSubmitFeedback = useCallback(
    ({ rating, freeText }) => {
      dispatch(
        Actions.submitBeforeAfterFeedback({
          rating,
          freeText,
          patientId,
          lastScanId
        })
      )
    },
    [dispatch, patientId, lastScanId]
  )

  useEffect(() => {
    if (isOpen && selectedPose) {
      handleRequestAsset()
    } else {
      setMessageText(t('messages.beforeAfterMessage'))
    }
  }, [isOpen, handleRequestAsset, t, selectedPose])

  return isMobile() ? (
    <BeforeAfterDialogMobile
      isOpen={isOpen}
      handleClose={handleClose}
      patient={patient}
      asset={asset}
      sendAssetInChat={permissions.sendChatMessages && sendAssetInChat}
      imgSrc={imgSrc}
      handleShareOptionClicked={handleShareOptionClicked}
      assetStatus={assetStatus}
      analyticsMetadat={analyticsMetadata}
      handleSubmitFeedback={handleSubmitFeedback}
      handleRetry={handleRetry}
      error={error}
    />
  ) : (
    <BeforeAfterDialogDesktop
      selectedPose={selectedPose}
      setSelectedPose={setSelectedPose}
      lastScanId={lastScanId}
      isOpen={isOpen}
      handleClose={handleClose}
      patient={patient}
      asset={asset}
      sendAssetInChat={permissions.sendChatMessages && sendAssetInChat}
      imgSrc={imgSrc}
      handleShareOptionClicked={handleShareOptionClicked}
      assetStatus={assetStatus}
      analyticsMetadat={analyticsMetadata}
      handleSubmitFeedback={handleSubmitFeedback}
      handleRetry={handleRetry}
      error={error}
      messageText={messageText}
      setMessageText={setMessageText}
    />
  )
}
export default BeforeAfterDialogHOC
