import { AsyncStatus } from 'consts'
import { ScanTrackingStatus, TrackingConditions, TreatmentTrackOptions } from 'consts/giConsts'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { isEmpty } from 'lodash'
import { sortTeeth } from 'utils/giUtils'
import { trackEvent } from 'utils/analyticsUtils'
import useRolePermissions from 'hooks/useRolePermissions'
import { logWarn } from 'utils/logUtils'

export default ({ scanId }) => {
  const dispatch = useDispatch()

  const { status: submissionStatus } = useSelector(state => state.giReducer.scanTracking)
  const { grinScans, treatment } = useSelector(state => state.treatmentReducer)

  const [trackStatus, setTrackStatus] = useState('')
  const [notes, setNotes] = useState('')
  const [isDismissed, setIsDismissed] = useState(null)
  const [selectedConditions, setSelectedConditions] = useState({})

  const conditionOptions = useMemo(
    () => Object.values(TrackingConditions).filter(condition => condition.trackingStatus === trackStatus),
    [trackStatus]
  )

  const { isGiUser } = useRolePermissions()
  const giTrackSettings = useMemo(() => JSON.parse(treatment?.giTrackSettings || '{}'), [treatment])
  const untrackedTeeth = useMemo(() => sortTeeth(giTrackSettings?.untrackedTeeth), [giTrackSettings])
  const grinScan = useMemo(() => grinScans.find(scan => scan.id === scanId), [scanId, grinScans])
  const isLoading = useMemo(() => submissionStatus === AsyncStatus.Loading, [submissionStatus])
  const isEditAllowed = useMemo(
    () => !grinScan?.trackingStatus || grinScan?.trackingStatus === ScanTrackingStatus.Pending,
    [grinScan]
  )
  const isNoteEnabled = useMemo(
    () =>
      Object.keys(selectedConditions).find(
        conditionType => selectedConditions[conditionType] && TrackingConditions[conditionType]?.withNote
      ),
    [selectedConditions]
  )
  const isSubmitDisabled = useMemo(() => {
    return (
      submissionStatus === AsyncStatus.Loading ||
      !trackStatus ||
      (trackStatus === TreatmentTrackOptions.OffTrack && isEmpty(selectedConditions)) ||
      (isNoteEnabled && !notes)
    )
  }, [trackStatus, submissionStatus, selectedConditions, isNoteEnabled, notes])

  const setConditionEnabled = useCallback(
    ({ conditionId, checked }) => {
      setSelectedConditions({ ...selectedConditions, [conditionId]: checked ? { selections: [] } : undefined })
    },
    [selectedConditions]
  )

  const setConditionSelections = useCallback(
    ({ conditionId, selections = [] }) => {
      setSelectedConditions({
        ...selectedConditions,
        [conditionId]: { ...selectedConditions[conditionId], selections }
      })
    },
    [selectedConditions]
  )

  const submit = useCallback(() => {
    trackEvent(`${isGiUser ? 'GI' : 'HI'} - review done click`, {
      status: trackStatus,
      patientId: treatment?.patientId,
      scanId,
      notes,
      reasons: Object.keys(selectedConditions)
    })

    dispatch(
      Actions.submitScanTrackingData({
        scanId,
        trackStatus,
        notes,
        conditions: selectedConditions,
        isGiUser
      })
    )
  }, [scanId, trackStatus, notes, selectedConditions, dispatch, treatment, isGiUser])

  const dismiss = useCallback(() => {
    if (isGiUser) {
      logWarn('Tried to dismiss scan tracking with GI user')
      return
    }

    trackEvent('HI - review dismiss clicked', {
      patientId: treatment?.patientId,
      scanId
    })

    dispatch(
      Actions.submitScanTrackingData({
        scanId,
        trackStatus: 'not-required',
        dismissScan: true,
        isGiUser: false
      })
    )
  }, [scanId, dispatch, treatment, isGiUser])

  useEffect(() => {
    const trackingDetails = JSON.parse(grinScan.trackingDetails || '{}')
    setTrackStatus(trackingDetails.trackStatus)
    setNotes(trackingDetails.notes)
    setSelectedConditions(trackingDetails.conditions || {})
    setIsDismissed(trackingDetails.isDismissed)
  }, [grinScan])

  useEffect(() => {
    const trackingDetails = JSON.parse(grinScan.trackingDetails || '{}')
    setSelectedConditions(trackingDetails.conditions || {})
  }, [trackStatus, grinScan.trackingDetails])

  return {
    scanTrackingStatus: grinScan?.trackingStatus,
    isEditAllowed,
    isSubmitDisabled,
    isLoading,
    conditionOptions,
    giTrackSettings,
    untrackedTeeth,
    isNoteEnabled,
    isDismissed,
    setConditionEnabled,
    setConditionSelections,
    submit,
    dismiss,
    values: {
      trackStatus,
      setTrackStatus,
      notes,
      setNotes,
      conditions: selectedConditions,
      setConditions: setSelectedConditions
    }
  }
}
