import * as React from 'react'

const UploadedStlPlaceholder = props => {
  return (
    <svg width="65" height="52" viewBox="0 0 72 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23 22V42C23 43.1046 23.8954 44 25 44H47C48.1046 44 49 43.1046 49 42V19.8284C49 19.298 48.7893 18.7893 48.4142 18.4142L45 15M23 20.5V13C23 11.8954 23.8954 11 25 11H40.1716C40.702 11 41.2107 11.2107 41.5858 11.5858L44 14"
        stroke="#4151E5"
        strokeWidth="0.75"
      />
      <path d="M41 11V17C41 18.1046 41.8954 19 43 19H49" stroke="#4151E5" strokeWidth="0.75" />
      <path d="M23 15H41" stroke="#4151E5" strokeWidth="0.75" />
      <path
        d="M35.7344 18.8541L40.2334 21.499L35.7344 24.144L31.2354 21.499L35.7344 18.8541Z"
        stroke="#4151E5"
        strokeWidth="0.75"
        strokeLinejoin="round"
      />
      <path
        d="M35.7344 31.144L40.2334 28.499L40.2334 26.7493M35.7344 31.144L31.2354 28.499L31.2354 21.5M35.7344 31.144V24M40.2334 21.5L40.2334 24.9995"
        stroke="#4151E5"
        strokeWidth="0.75"
        strokeLinejoin="round"
      />
      <path
        d="M28.2909 39.1099C27.9683 39.1099 27.6566 39.0549 27.3559 38.9449C27.0626 38.8349 26.8353 38.6773 26.6739 38.4719C26.6153 38.3913 26.5859 38.3106 26.5859 38.2299C26.5859 38.1346 26.6226 38.0539 26.6959 37.9879C26.7766 37.9146 26.8646 37.8779 26.9599 37.8779C27.0113 37.8779 27.0626 37.8889 27.1139 37.9109C27.1653 37.9256 27.2056 37.9549 27.2349 37.9989C27.3523 38.1383 27.5099 38.2483 27.7079 38.3289C27.9133 38.4096 28.1259 38.4499 28.3459 38.4499C28.6026 38.4499 28.8116 38.3876 28.9729 38.2629C29.1416 38.1383 29.2259 37.9733 29.2259 37.7679C29.2259 37.5333 29.1269 37.3683 28.9289 37.2729C28.7383 37.1703 28.5146 37.0969 28.2579 37.0529C28.0233 37.0089 27.7849 36.9466 27.5429 36.8659C27.3009 36.7779 27.0993 36.6533 26.9379 36.4919C26.7766 36.3233 26.6959 36.0959 26.6959 35.8099C26.6959 35.4213 26.8426 35.1133 27.1359 34.8859C27.4366 34.6586 27.8363 34.5449 28.3349 34.5449C28.5989 34.5449 28.8409 34.5853 29.0609 34.6659C29.2809 34.7393 29.4643 34.8419 29.6109 34.9739C29.6989 35.0473 29.7429 35.1389 29.7429 35.2489C29.7429 35.3369 29.7099 35.4139 29.6439 35.4799C29.5779 35.5459 29.5009 35.5789 29.4129 35.5789C29.3249 35.5789 29.2406 35.5496 29.1599 35.4909C28.9253 35.3003 28.6356 35.2049 28.2909 35.2049C27.7043 35.2049 27.4109 35.4029 27.4109 35.7989C27.4109 35.9896 27.4989 36.1216 27.6749 36.1949C27.8583 36.2609 28.1039 36.3306 28.4119 36.4039C28.5586 36.4333 28.7163 36.4773 28.8849 36.5359C29.0609 36.5873 29.2296 36.6643 29.3909 36.7669C29.5523 36.8623 29.6843 36.9943 29.7869 37.1629C29.8896 37.3243 29.9409 37.5296 29.9409 37.7789C29.9409 38.1749 29.7869 38.4976 29.4789 38.7469C29.1783 38.9889 28.7823 39.1099 28.2909 39.1099Z"
        fill="#4151E5"
      />
      <path
        d="M32.2466 39.1099C32.1293 39.1099 32.034 39.0769 31.9606 39.0109C31.8946 38.9449 31.8616 38.8606 31.8616 38.7579V35.5569C31.8616 35.3736 31.7663 35.2819 31.5756 35.2819H30.8826C30.6553 35.2819 30.5416 35.1683 30.5416 34.9409C30.5416 34.7136 30.6553 34.5999 30.8826 34.5999H33.5996C33.7023 34.5999 33.7866 34.6329 33.8526 34.6989C33.9186 34.7576 33.9516 34.8383 33.9516 34.9409C33.9516 35.0436 33.9186 35.1279 33.8526 35.1939C33.7866 35.2526 33.7023 35.2819 33.5996 35.2819H32.9176C32.727 35.2819 32.6316 35.3736 32.6316 35.5569V38.7579C32.6316 38.8606 32.595 38.9449 32.5216 39.0109C32.4556 39.0769 32.364 39.1099 32.2466 39.1099Z"
        fill="#4151E5"
      />
      <path
        d="M35.3825 38.9999C35.1992 38.9999 35.0525 38.9449 34.9425 38.8349C34.8325 38.7249 34.7775 38.5783 34.7775 38.3949V34.9079C34.7775 34.7833 34.8178 34.6916 34.8985 34.6329C34.9865 34.5743 35.0782 34.5449 35.1735 34.5449C35.2688 34.5449 35.3532 34.5743 35.4265 34.6329C35.5072 34.6916 35.5475 34.7833 35.5475 34.9079V38.0319C35.5475 38.1419 35.5695 38.2189 35.6135 38.2629C35.6575 38.2996 35.7308 38.3179 35.8335 38.3179H36.9665C37.0838 38.3179 37.1682 38.3546 37.2195 38.4279C37.2782 38.5013 37.3075 38.5783 37.3075 38.6589C37.3075 38.7469 37.2782 38.8276 37.2195 38.9009C37.1682 38.9669 37.0838 38.9999 36.9665 38.9999H35.3825Z"
        fill="#4151E5"
      />
      <path
        d="M23 33H38.5C39.0523 33 39.5 33.4477 39.5 34V40C39.5 40.5523 39.0523 41 38.5 41H34.5M23 41H33"
        stroke="#4151E5"
        strokeWidth="0.75"
      />
      <rect x="1" y="1" width="70" height="53" rx="9" stroke="#4151E5" strokeWidth="0.75" />
    </svg>
  )
}

export default UploadedStlPlaceholder
