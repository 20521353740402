import * as React from 'react'
const VideoThumbnail = ({ ...props }) => {
  return (
    <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 3.5735C1 2.15219 2.1522 1 3.5735 1H14.9024V8.07713H21.6579V22.8748C21.6579 24.2961 20.5057 25.4483 19.0844 25.4483H3.5735C2.1522 25.4483 1 24.2961 1 22.8748V3.5735Z"
        stroke="#4151E6"
        strokeLinejoin="round"
      />
      <path d="M21.6578 8.07713L14.9023 1V8.07713H21.6578Z" stroke="#4151E6" strokeLinejoin="round" />
      <path
        d="M8 11.3042C8 10.5188 8.86395 10.04 9.53 10.4562L14.6432 13.652C15.2699 14.0437 15.2699 14.9563 14.6432 15.348L9.53 18.5438C8.86395 18.96 8 18.4812 8 17.6958V11.3042Z"
        fill="#4151E6"
      />
    </svg>
  )
}

export default VideoThumbnail
