import { isUserOfAnyAdminRole } from 'utils/authUtils'
import { API, graphqlOperation } from 'aws-amplify'
import { ofType } from 'redux-observable'
import { forkJoin, of, from } from 'rxjs'
import { catchError, filter, map, mapTo, mergeMap, switchMap, withLatestFrom, pluck } from 'rxjs/operators'
import Actions from 'actions'
import i18n from '../resources/locales/i18n'
import { COUNT_LIMIT, PATIENTS_PER_PAGE } from 'consts/taskManagerConsts'
import {
  countPatientSearchModels,
  searchPatientSearchModelsForDashboard,
  searchPatientSearchModelsForTasks
} from '../graphql/customQueries'
import { mapToRequestPatientsForTaskManager } from 'utils/mappers/taskManagerMappers'

export const requestTotalPatientsForTaskManagerEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_TOTAL_PATIENTS_FOR_TASK_MANAGER),
    filter(() => !isUserOfAnyAdminRole()),
    withLatestFrom(state$),
    map(mapToRequestPatientsForTaskManager),
    switchMap(({ filter }) =>
      from(
        API.graphql(
          graphqlOperation(searchPatientSearchModelsForTasks, {
            filter: {
              doctorId: filter.doctorId,
              program: { eq: 'ortho' },
              lastActionItemStatus: { eq: 'open' }
            },
            limit: COUNT_LIMIT
          })
        )
      ).pipe(
        map(response => response.data?.searchPatientSearchModels.items),
        mergeMap(taskCategories => of(Actions.totalPatientsForTaskManagerReceived(taskCategories))),
        catchError(error => of(Actions.totalPatientsForTaskManagerFailed(error)))
      )
    )
  )

export const requestPatientsForTaskManagerEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_PATIENTS_FOR_TASK_MANAGER),
    filter(() => !isUserOfAnyAdminRole()),
    withLatestFrom(state$),
    map(mapToRequestPatientsForTaskManager),
    switchMap(({ filter, from, sort, newSearch, showLoader }) =>
      forkJoin({
        results: API.graphql(
          graphqlOperation(searchPatientSearchModelsForDashboard, {
            filter,
            from,
            sort,
            limit: PATIENTS_PER_PAGE
          })
        ),
        total: API.graphql(
          graphqlOperation(countPatientSearchModels, {
            filter,
            limit: COUNT_LIMIT
          })
        ),
        taskCategories: API.graphql(
          graphqlOperation(searchPatientSearchModelsForTasks, {
            filter: {
              doctorId: filter.doctorId,
              program: { eq: 'ortho' },
              lastActionItemStatus: { eq: 'open' }
            },
            limit: COUNT_LIMIT
          })
        )
      }).pipe(
        map(({ results, total, taskCategories }) => ({
          items: results.data?.searchPatientSearchModels.items,
          nextToken: results.data?.searchPatientSearchModels.nextToken,
          total: total.data?.searchPatientSearchModels.total,
          taskCategories: taskCategories.data?.searchPatientSearchModels.items,
          showLoader,
          newSearch
        })),
        mergeMap(data => of(Actions.patientsForTaskManagerReceived(data))),
        catchError(error => of(Actions.patientsForTaskManagerFailed(error)))
      )
    )
  )

export const requestPatientsForTaskManagerFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.PATIENTS_FOR_TASK_MANAGER_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.taskManager.failedToFetchPatientsForTaskManager')
      })
    )
  )

export const setTaskManagerPageEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SET_TASK_MANAGER_PAGE),
    withLatestFrom(state$),
    map(([action, state]) => ({
      requestedPageOffset: action.payload * PATIENTS_PER_PAGE,
      actualFetchedPatients: state.taskManagerReducer.items.length
    })),
    filter(({ requestedPageOffset, actualFetchedPatients }) => actualFetchedPatients <= requestedPageOffset),
    map(() =>
      Actions.requestPatientsForTaskManager({
        newSearch: false,
        showLoader: true
      })
    )
  )

export const setTaskManagerFiltersEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SET_TASK_MANAGER_FILTERS),
    mapTo(
      Actions.requestPatientsForTaskManager({
        newSearch: true,
        showLoader: true
      })
    )
  )

export const onChangeActionItemTypeNotificationReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload,
      accountOwnerId: state.practiceReducer.accountOwner.id
    })),
    filter(
      ({ notification, accountOwnerId }) =>
        notification.entityType === 'PatientSearchModel' &&
        notification.method === 'MODIFY' &&
        notification.isActionItemTypeChanged &&
        notification.doctorId === accountOwnerId
    ),
    mergeMap(() =>
      of(
        Actions.requestPatientsForTaskManager({
          newSearch: true,
          showLoader: false
        })
      )
    )
  )

export const onChangeActionItemParamsNotificationReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    pluck('payload'),
    filter(notification => notification.entityType === 'ActionItem'),
    mapTo(
      Actions.requestPatientsForTaskManager({
        newSearch: true,
        showLoader: false
      })
    )
  )

export const updateApplianceArrivalEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPDATE_APPLIANCE_ARRIVAL_STATUS),
    pluck('payload'),
    switchMap(({ patientId, actionItemTypes }) =>
      from(
        API.put('grinApi', `/treatments/v1/actionItems/patient/${patientId}/resolve`, {
          body: {
            actionItemTypes
          }
        })
      ).pipe(
        mergeMap(() => of(Actions.updateApplianceArrivalStatusReceived())),
        catchError(() => of(Actions.updateApplianceArrivalStatusFailed()))
      )
    )
  )

export const updateApplianceArrivalEpicReceived = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_APPLIANCE_ARRIVAL_STATUS_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.taskManager.updateApplianceArrivalStatusSuccessfully')
      })
    )
  )

export const updateApplianceArrivalEpicFailed = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_APPLIANCE_ARRIVAL_STATUS_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.taskManager.failedToUpdateApplianceArrivalStatus')
      })
    )
  )
