import Actions from 'actions'
import { replaceItem, removeWhere, updateWhere } from 'utils/arrayUtils'
import { isUserOfRole } from 'utils/authUtils'
import { Roles } from 'consts/authConsts'

const initialState = {
  isLoading: true,
  isSaving: false,
  leadsBeingSaved: {},
  leads: [],
  shippingDetailsDialog: {
    isCreatingOrder: false,
    isSimulatingShipment: false,
    isLoadingShippingConfiguration: false,
    isRefreshingLead: false,
    inventoryItems: null,
    shippingMethods: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_LEADS: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.FETCH_LEADS_RECEIVED: {
      return {
        ...state,
        isLoading: false,
        leads: [
          ...action.payload.pendingLeads,
          ...action.payload.convertedLeads
            .filter(currLead => currLead.lead)
            .map(currLead => ({
              ...currLead.lead,
              statusType: currLead.statusType,
              patient: currLead?.patient
            }))
        ]
      }
    }
    case Actions.FETCH_LEADS_FAILED: {
      return {
        ...state,
        isLoading: false
      }
    }
    case Actions.UPDATE_RC_LEAD: {
      return {
        ...state,
        isSaving: true,
        leadsBeingSaved: {
          ...state.leadsBeingSaved,
          [action.payload.id]: true
        }
      }
    }
    case Actions.UPDATE_RC_LEAD_SUCCEEDED: {
      return {
        ...state,
        isSaving: false,
        leadsBeingSaved: {
          ...state.leadsBeingSaved,
          [action.payload.id]: false
        },
        leads: isUserOfRole([Roles.Admin])
          ? replaceItem(state.leads, action.payload)
          : removeWhere(state.leads, lead => lead.id === action.payload.id)
      }
    }
    case Actions.UPDATE_RC_LEAD_FAILED: {
      return {
        ...state,
        isSaving: false,
        leadsBeingSaved: {
          ...state.leadsBeingSaved,
          [action.payload.id]: false
        }
      }
    }
    case Actions.APPROVE_RC_LEAD: {
      return {
        ...state,
        isSaving: true,
        leadsBeingSaved: {
          ...state.leadsBeingSaved,
          [action.payload.id]: true
        }
      }
    }
    case Actions.APPROVE_RC_LEAD_SUCCEEDED: {
      const rcData = JSON.parse(action.payload?.rcData || '{}')

      return {
        ...state,
        isSaving: false,
        leadsBeingSaved: {
          ...state.leadsBeingSaved,
          [action.payload.id]: false
        },
        leads: replaceItem(state.leads, {
          ...action.payload,
          conversionStatus: 'approved',
          rcData: JSON.stringify({
            ...rcData,
            shippingDetails: {
              ...rcData?.shippingDetails,
              status: 'processing'
            }
          })
        })
      }
    }
    case Actions.APPROVE_RC_LEAD_FAILED: {
      return {
        ...state,
        isSaving: false,
        leadsBeingSaved: {
          ...state.leadsBeingSaved,
          [action.payload.id]: false
        }
      }
    }
    case Actions.NEW_LEAD_RECEIVED: {
      return {
        ...state,
        leads: [action.payload].concat(state.leads)
      }
    }
    case Actions.RESET_RC_SHIPPING_DETAILS_DIALOG: {
      return {
        ...state,
        shippingDetailsDialog: {
          ...initialState.shippingDetailsDialog,
          inventoryItems: state.shippingDetailsDialog.inventoryItems,
          shippingMethods: state.shippingDetailsDialog.shippingMethods
        }
      }
    }
    case Actions.CREATE_RC_SHIPBOB_ORDER: {
      return {
        ...state,
        shippingDetailsDialog: {
          ...state.shippingDetailsDialog,
          isCreatingOrder: true
        }
      }
    }
    case Actions.CREATE_RC_SHIPBOB_ORDER_RECEIVED: {
      return {
        ...state,
        leads: updateWhere(
          state.leads,
          lead => lead.id === action.payload.id,
          lead => (lead.rcData = action.payload.rcData)
        ),
        shippingDetailsDialog: {
          ...state.shippingDetailsDialog,
          isCreatingOrder: false
        }
      }
    }
    case Actions.CREATE_RC_SHIPBOB_ORDER_FAILED: {
      return {
        ...state,
        shippingDetailsDialog: {
          ...state.shippingDetailsDialog,
          isCreatingOrder: false
        }
      }
    }
    case Actions.SIMULATE_ORDER_SHIPMENT: {
      return {
        ...state,
        shippingDetailsDialog: {
          ...state.shippingDetailsDialog,
          isSimulatingShipment: true
        }
      }
    }
    case Actions.SIMULATE_ORDER_SHIPMENT_RECEIVED:
    case Actions.SIMULATE_ORDER_SHIPMENT_FAILED: {
      return {
        ...state,
        shippingDetailsDialog: {
          ...state.shippingDetailsDialog,
          isSimulatingShipment: initialState.shippingDetailsDialog.isSimulatingShipment
        }
      }
    }
    case Actions.FETCH_RC_SHIPPING_CONFIGURATION: {
      return {
        ...state,
        shippingDetailsDialog: {
          ...state.shippingDetailsDialog,
          isLoadingShippingConfiguration: true
        }
      }
    }
    case Actions.FETCH_RC_SHIPPING_CONFIGURATION_FAILED: {
      return {
        ...state,
        shippingDetailsDialog: {
          ...state.shippingDetailsDialog,
          isLoadingShippingConfiguration: false
        }
      }
    }
    case Actions.FETCH_RC_SHIPPING_CONFIGURATION_RECEIVED: {
      return {
        ...state,
        shippingDetailsDialog: {
          ...state.shippingDetailsDialog,
          isLoadingShippingConfiguration: false,
          inventoryItems: action.payload.inventoryItems.map(item => ({
            label: `${item.name} (${item.id})`,
            value: item.id,
            originalName: item.name
          })),
          shippingMethods: action.payload.shippingMethods
        }
      }
    }
    case Actions.RELOAD_LEAD_DATA: {
      return {
        ...state,
        shippingDetailsDialog: {
          ...state.shippingDetailsDialog,
          isRefreshingLead: true
        }
      }
    }
    case Actions.RELOAD_LEAD_DATA_FAILED: {
      return {
        ...state,
        shippingDetailsDialog: {
          ...state.shippingDetailsDialog,
          isRefreshingLead: false
        }
      }
    }
    case Actions.RELOAD_LEAD_DATA_RECEIVED: {
      return {
        ...state,
        leads: replaceItem(state.leads, action.payload),
        shippingDetailsDialog: {
          ...state.shippingDetailsDialog,
          isRefreshingLead: false
        }
      }
    }

    case Actions.RESOLVE_LEAD:
      return {
        ...state,
        leads: removeWhere(state.leads, lead => lead.id === action.payload)
      }
    default:
      return state
  }
}
