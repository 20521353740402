import React, { useEffect } from 'react'
import { Fade, Grid, Typography, makeStyles } from '@material-ui/core'
import QuickLinks from './QuickLinks'
import UsefulInformation from './UsefulInformation'
import About from './About'
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import images from 'resources'
import actions from 'actions'
import InvitePatients from './InvitePatients'
import useHasPatients from 'hooks/useHasPatients'

const useStyles = () =>
  makeStyles({
    placeholderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    emptyStateContainer: {
      width: '100%',
      height: '100%',
      padding: '10px 0px 40px 70px',
      margin: '20px 0px 20px 0px',
      backgroundImage: `url('${images.emptyStateBackground}')`,
      backgroundSize: 'cover',
      overflowY: 'scroll'
    },
    quickLinksContainer: {
      marginRight: 50
    },
    skeletonWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    skeletonContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '40px 40px 40px 50px',
      width: '100%',
      height: '100%'
    },
    skeleton: {
      margin: 20,
      transition: 'width .2s',
      borderRadius: 16
    },
    usefulInfoSkeleton: {
      paddingTop: 220
    },
    articlesSkeleton: {
      paddingTop: 250
    },
    quickLinksSkeleton: {
      paddingTop: 100
    },
    quickLinksSkeletonContainer: {
      borderTop: '1px solid var(--bg-color-14)',
      borderBottom: '1px solid var(--bg-color-14)',
      padding: 10
    }
  })()

const DetailedEmptyState = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isLoading } = useSelector(state => state.appReducer.about)
  const accountOwnerId = useSelector(state => state.practiceReducer.accountOwner.id)
  const { hasPatients } = useHasPatients()

  useEffect(() => {
    dispatch(actions.fetchAboutData({ doctorId: accountOwnerId }))
  }, [accountOwnerId, dispatch])

  const renderLoadingSkeleton = () => {
    const skeletonCards = (
      <div className={classes.skeletonWrapper}>
        {Array.from(Array(2).keys()).map(i => (
          <Skeleton
            key={`skeleton-card-${i}`}
            variant="rect"
            width="45%"
            className={`${classes.skeleton} ${classes.usefulInfoSkeleton}`}
          >
            <Typography></Typography>
          </Skeleton>
        ))}
      </div>
    )
    const quickLinksSkeleton = (
      <div className={classes.quickLinksSkeletonContainer}>
        <Skeleton variant="rect" width="95%" className={`${classes.skeleton} ${classes.quickLinksSkeleton}`}>
          <Typography></Typography>
        </Skeleton>
      </div>
    )
    const skeletonArticles = (
      <div className={classes.skeletonWrapper}>
        {Array.from(Array(3).keys()).map(i => (
          <Skeleton
            key={`skeleton-article-${i}`}
            variant="rect"
            width="25%"
            className={`${classes.skeleton} ${classes.articlesSkeleton}`}
          >
            <Typography></Typography>
          </Skeleton>
        ))}
      </div>
    )

    return (
      <div className={classes.skeletonContainer}>
        {skeletonCards}
        {quickLinksSkeleton}
        {skeletonArticles}
      </div>
    )
  }

  return (
    <>
      {isLoading && (
        <Fade timeout={600} in={isLoading}>
          {renderLoadingSkeleton()}
        </Fade>
      )}
      {!isLoading && (
        <Fade timeout={600} in={!isLoading}>
          <Grid container direction="column" wrap="nowrap" className={classes.emptyStateContainer} spacing={2}>
            <Grid item>{hasPatients ? <UsefulInformation /> : <InvitePatients />}</Grid>
            <Grid item className={classes.quickLinksContainer}>
              <QuickLinks />
            </Grid>
            <Grid item>
              <About />
            </Grid>
          </Grid>
        </Fade>
      )}
    </>
  )
}

export default DetailedEmptyState
