import React from 'react'
import TitleWithText from 'components/Patients/PatientGuidelines/PatientBrief/Categories/TitleWithText'
import { useTranslation } from 'react-i18next'

const RPE = ({ items, note }) => {
  const { t } = useTranslation()

  const { numberOfTurns } = items

  return (
    <div>
      {numberOfTurns ? (
        <TitleWithText title={`${t(`dialogs.hiPatientGuidelinesViewer.items.numberOfTurns`)}:`} text={numberOfTurns} />
      ) : (
        <></>
      )}
    </div>
  )
}

export default RPE
