import React, { useCallback, useState, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import scanPlacholder from 'resources/scan-placeholder.png'
import scanReviewPlacholder from 'resources/scanreview-placeholder.png'
import Actions from 'actions'
import VideoWrapper from 'components/common/VideoWrapper'
import AlignersScanOverlay from '../../Timeline/AlignersScanOverlay'
import { isMobile } from 'utils/mobileUtils'
import MessageTypeWrapper from '../MessageTypeWrapper'
import { trackEvent } from 'utils/analyticsUtils'
import UrgencyDetails from './UrgencyDetails'
import ScanByServiceAccount from 'components/Patients/Timeline/V1/ScanByServiceAccount'
import { ScanTrackingStatus } from 'consts/giConsts'
import ScanMessageTrackingStatus from './ScanMessageTrackingStatus'

const useStyles = ({ alignLeft }) =>
  makeStyles({
    container: {
      position: 'relative',
      textAlign: 'center',
      color: 'white',
      cursor: 'pointer',
      height: '100%',
      fontSize: 0
    },
    preview: {
      maxHeight: 210,
      maxWidth: 210,
      borderRadius: 8
    },
    text: {
      position: 'absolute',
      top: 4,
      left: 14,
      fontSize: 10,
      fontWeight: 500
    },
    alignersOverlayIcon: {
      top: '5px!important',
      right: '5px!important'
    },
    video: {
      maxWidth: '90%',
      height: '141px',
      alignSelf: 'center',
      outline: 'none',
      borderRadius: 20
    },
    timestamp: {
      position: 'absolute',
      bottom: 2,
      right: 18,
      fontSize: 10,
      textAlign: 'right',
      color: 'white'
    },
    messageUserInfo: {
      color: 'rgba(0, 19, 53, 0.7)',
      marginBottom: 3,
      marginLeft: 5
    },
    urgencyReasonTitle: {
      color: 'var(--text-color-11)'
    }
  })({ alignLeft })

const ScanMessage = ({
  id,
  content,
  type,
  isLocal,
  cache,
  alignLeft,
  timestamp,
  name,
  userType,
  withAligners = false,
  isFirstMessage = false,
  isLastMessage = false,
  isUrgent = false,
  urgencyReason = null,
  patientId,
  grinScan
}) => {
  const classes = useStyles({ alignLeft })
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isVideoInitiated, setisVideoInitiated] = useState(false)

  const isDoubleScan = useMemo(() => type === 'doubleScan', [type])
  const scanMetadata = useMemo(() => JSON.parse(grinScan?.metadata || '{}'), [grinScan])

  const handleOnClickScan = useCallback(() => {
    trackEvent('Chat - scan video clicked', {
      isMobile: isMobile(),
      patientId,
      messageId: id
    })

    if (isMobile()) {
      setisVideoInitiated(true)
    } else {
      dispatch(Actions.openScanViewDialog({ s3Object: content, type, cache, isLocal }))
    }
  }, [content, dispatch, type, cache, isLocal, patientId, id])

  return (
    <Grid container direction="column">
      <Grid item>
        <MessageTypeWrapper
          id={id}
          isFirstMessage={isFirstMessage}
          isLastMessage={isLastMessage}
          alignLeft={alignLeft}
          name={name}
          userType={userType}
          useBubble={!isDoubleScan}
          infoClassName={classes.messageUserInfo}
          isUrgent={isUrgent}
        >
          {isVideoInitiated ? (
            <VideoWrapper s3Object={content} isLocal={isLocal} cache={cache} className={classes.video} autoplay />
          ) : (
            <div className={classes.container} onClick={handleOnClickScan}>
              <AlignersScanOverlay
                withAligners={type === 'doubleScan' ? withAligners : content?.withAligner}
                containerClassName={classes.alignersOverlayIcon}
              />
              <img
                src={type === 'scan' || type === 'doubleScan' ? scanPlacholder : scanReviewPlacholder}
                className={classes.preview}
                alt={''}
              />
              <div className={classes.text}>{`a ${
                type === 'scan' || type === 'doubleScan'
                  ? t('pages.patients.selectedPatient.chat.scanWasUploadedLabel')
                  : t('pages.patients.selectedPatient.chat.scanReviewWasUploadedLabel')
              }`}</div>
              {timestamp && <div className={classes.timestamp}>{timestamp}</div>}
            </div>
          )}
          <UrgencyDetails isUrgent={isUrgent} urgencyReason={urgencyReason} />
          {urgencyReason && grinScan?.trackingStatus && grinScan?.trackingStatus !== ScanTrackingStatus.NotRequired && (
            <div style={{ height: '8px' }} />
          )}
        </MessageTypeWrapper>
      </Grid>
      {grinScan?.trackingStatus && (
        <ScanMessageTrackingStatus
          grinScanId={grinScan.id}
          trackingStatus={grinScan.trackingStatus}
          trackingDetails={grinScan.trackingDetails}
        />
      )}
      {scanMetadata?.isServiceAccount && <ScanByServiceAccount />}
    </Grid>
  )
}

export default ScanMessage
