import * as React from 'react'

const Book = ({ className }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 1.95496C0 1.36334 0.511316 0.899125 1.09627 0.987684C3.35491 1.32963 5.54827 2.02411 7.59555 3.04775L8 3.24998L8.40445 3.04775C10.4517 2.02411 12.6451 1.32963 14.9037 0.987684C15.4887 0.899125 16 1.36334 16 1.95496V11.145C16 11.6587 15.6103 12.0864 15.1016 12.1587C12.7732 12.4894 10.5115 13.1942 8.40445 14.2478L8 14.45L7.59555 14.2478C5.48853 13.1942 3.22678 12.4894 0.898391 12.1587C0.389745 12.0864 0 11.6587 0 11.145V1.95496Z"
        fill="#4151E6"
      />
      <path
        d="M7.20117 2.44995H8.80117V10.45C8.80117 10.8918 8.443 11.25 8.00117 11.25C7.55934 11.25 7.20117 10.8918 7.20117 10.45V2.44995Z"
        fill="white"
      />
    </svg>
  )
}

export default Book
