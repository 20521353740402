import Actions from 'actions'
import { ofType } from 'redux-observable'
import { withLatestFrom, map, switchMap, mergeMap, catchError, mapTo, pluck, filter } from 'rxjs/operators'
import { from, of, concat, forkJoin } from 'rxjs'
import {
  leadsByDoctorIdByProgramByConversionStatus,
  updateLead,
  getLeadForLiveUpdates,
  getLeadForRcDashboard,
  searchPatientSearchModelsWithScans,
  searchPatientSearchModelsWithScansAndGrinPlan,
  leadsByProgramByConversionStatusWithGrinPlan
} from 'graphql/customQueries'
import { fetchAll } from 'utils/graphqlUtils'
import { API, graphqlOperation } from 'aws-amplify'
import { isUserOfAnyAdminRole } from 'utils/authUtils'
import i18n from 'resources/locales/i18n'

export const fetchLeadsTrigger = action$ =>
  action$.pipe(ofType(Actions.DOCTOR_DETAILS_RECEIVED), mapTo(Actions.fetchLeads()))

export const fetchLeadsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_LEADS),
    withLatestFrom(state$),
    map(([action, state]) => ({
      doctorId: state.practiceReducer.accountOwner.id,
      isAnyAdminRole: isUserOfAnyAdminRole()
    })),
    switchMap(({ doctorId, isAnyAdminRole }) =>
      forkJoin({
        pendingLeads: from(
          isAnyAdminRole
            ? fetchAll(
                leadsByProgramByConversionStatusWithGrinPlan,
                { program: 'rc', conversionStatus: { eq: 'pending' } },
                'leadsByProgramByConversionStatus'
              )
            : fetchAll(
                leadsByDoctorIdByProgramByConversionStatus,
                {
                  doctorId,
                  programConversionStatus: {
                    eq: {
                      program: 'rc',
                      conversionStatus: 'pending'
                    }
                  }
                },
                'leadsByDoctorIdByProgramByConversionStatus'
              )
        ),
        convertedLeads: isAnyAdminRole
          ? fetchAll(
              searchPatientSearchModelsWithScansAndGrinPlan,
              {
                filter: { program: { eq: 'rc' } }
              },
              'searchPatientSearchModels'
            )
          : fetchAll(
              searchPatientSearchModelsWithScans,
              {
                filter: {
                  or: [{ statusKey: { eq: 'new-lead_rc' } }, { statusKey: { eq: 'invited_rc' } }],
                  doctorId: { eq: doctorId }
                }
              },
              'searchPatientSearchModels'
            )
      }).pipe(
        mergeMap(leads => of(Actions.fetchLeadsRecevied(leads))),
        catchError(err => of(Actions.fetchLeadsFailed(err)))
      )
    )
  )

export const approveRCLeadEpic = action$ =>
  action$.pipe(
    ofType(Actions.APPROVE_RC_LEAD),
    pluck('payload'),
    switchMap(leadInput =>
      from(
        API.post('grinServerlessApi', '/accounts/v2/leads/patients/approve', {
          body: leadInput
        })
      ).pipe(
        mergeMap(({ lead }) =>
          concat(
            of(Actions.approveRCLeadSucceeded(lead)),
            of(
              Actions.showSnackbar({
                type: 'success',
                text: i18n.t('pages.rcDashboard.actions.updateLeadSucceeded')
              })
            )
          )
        ),
        catchError(err =>
          concat(
            of(Actions.approveRCLeadFailed(err)),
            of(
              Actions.showSnackbar({
                type: 'error',
                text: i18n.t('pages.rcDashboard.actions.updateLeadFailed')
              })
            )
          )
        )
      )
    )
  )

export const updateRCLeadEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_RC_LEAD),
    pluck('payload'),
    switchMap(leadInput =>
      from(API.graphql(graphqlOperation(updateLead, { input: leadInput }))).pipe(
        mergeMap(({ data }) =>
          concat(
            of(Actions.updateRCLeadSucceeded(data.updateLead)),
            of(
              Actions.showSnackbar({
                type: 'success',
                text: i18n.t('pages.rcDashboard.actions.updateLeadSucceeded')
              })
            )
          )
        ),
        catchError(err =>
          concat(
            of(Actions.updateRCLeadFailed(err)),
            of(
              Actions.showSnackbar({
                type: 'error',
                text: i18n.t('pages.rcDashboard.actions.updateLeadFailed')
              })
            )
          )
        )
      )
    )
  )

export const onNewLeadNotificationReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload
    })),
    filter(({ notification }) => notification.entityType === 'Lead' && notification.method === 'INSERT'),
    switchMap(({ notification }) =>
      from(API.graphql(graphqlOperation(getLeadForLiveUpdates, { id: notification.entityId }))).pipe(
        map(({ data }) => data.getLead),
        filter(lead => lead.conversionStatus !== 'approved'),
        mergeMap(lead => of(Actions.newLeadReceived({ ...lead, ...JSON.parse(lead.rcData || '{}') }))),
        catchError(err => of(Actions.newLeadLiveUpdateFailed(err)))
      )
    )
  )

export const createRcShipbobOrderEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_RC_SHIPBOB_ORDER),
    pluck('payload'),
    switchMap(({ leadId, shippingMethod, inventoryItemId, productName }) =>
      from(
        API.post('grinServerlessApi', '/ops/v1/orders/rc/scope', {
          body: {
            leadId,
            shippingMethod,
            inventoryItemId,
            productName
          }
        })
      ).pipe(
        mergeMap(updatedLead => of(Actions.createRcShipbobOrderReceived(updatedLead))),
        catchError(({ response }) => of(Actions.createRcShipbobOrderFailed(response)))
      )
    )
  )

export const simulateOrderShipmentEpic = action$ =>
  action$.pipe(
    ofType(Actions.SIMULATE_ORDER_SHIPMENT),
    pluck('payload'),
    switchMap(({ shipmentId, action }) =>
      from(
        API.post('grinServerlessApi', '/ops/v1/orders/simulate', {
          body: {
            shipmentId,
            action
          }
        })
      ).pipe(
        mergeMap(updatedLead => of(Actions.simulateOrderShipmentReceived({ shipmentId, action }))),
        catchError(({ response }) => of(Actions.simulateOrderShipmentFailed(response)))
      )
    )
  )

export const simulateOrderShipmentFailed = action$ =>
  action$.pipe(
    ofType(Actions.SIMULATE_ORDER_SHIPMENT_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        time: 10000,
        text: i18n.t('pages.rcDashboard.dialogs.shippingDetails.shipbobSection.shipmentSimulationFailed')
      })
    )
  )

export const fetchRcShippingConfigurationEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_RC_SHIPPING_CONFIGURATION),
    switchMap(({ shipmentId, action }) =>
      from(API.get('grinServerlessApi', '/ops/v1/orders/rc/configuration')).pipe(
        mergeMap(configuration => of(Actions.fetchRcShippingConfigurationReceived(configuration))),
        catchError(({ response }) => of(Actions.fetchRcShippingConfigurationFailed(response)))
      )
    )
  )

export const reloadLeadDataReceived = action$ =>
  action$.pipe(
    ofType(Actions.RELOAD_LEAD_DATA),
    pluck('payload'),
    switchMap(({ leadId }) =>
      from(API.graphql(graphqlOperation(getLeadForRcDashboard, { id: leadId }))).pipe(
        mergeMap(res => of(Actions.reloadLeadDataReceived(res.data.getLead))),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )
