import React from 'react'
import { Grid } from '@material-ui/core'
import { Chewies } from 'components/common/icons'
import { useTranslation } from 'react-i18next'

const InstructForChewies = () => {
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={2}>
        <Chewies />
      </Grid>
      <Grid item xs={10}>
        {t('dialogs.hiPatientGuidelinesViewer.actions.instructForChewies')}
      </Grid>
    </Grid>
  )
}

export default InstructForChewies
