export const normalizeProgram = (program, t) => {
  switch (program) {
    case t('common.tags.remoteMonitoring'):
      return t('common.tags.rm')
    case t('common.tags.remoteConsultation'):
      return t('common.tags.rc')
    case t('common.tags.orthodontics'):
      return t('common.tags.ortho')
    case 'serviceAccount':
      return t('common.tags.serviceAccount')
    default:
      return program?.toLowerCase()
  }
}

export const getFullProgramName = (programShortcut, t) => {
  switch (programShortcut) {
    case t('common.tags.rm'):
      return t('common.tags.remoteMonitoring')
    case t('common.tags.rc'):
      return t('common.tags.remoteConsultation')
    case t('common.tags.ortho'):
      return t('common.tags.orthodontics')
    case 'serviceAccount':
      return t('common.tags.serviceAccount')
    default:
      return null
  }
}
