import * as React from 'react'

function SvgSwooshLarge1(props) {
  return (
    <svg width={390} height={285} fill="none" {...props}>
      <path
        d="M0 283.996c84.476.363 169.779-25.134 242.759-78.411C315.738 152.307 365.12 79.472 389 0"
        stroke="#FFBD39"
        strokeMiterlimit={10}
      />
      <circle cx={104} cy={271} r={5.5} fill="#001335" stroke="#FFBD39" />
      <circle cx={104} cy={271} r={4} fill="#FFBD39" />
      <circle cx={246} cy={203} r={5.5} fill="#001335" stroke="#FFBD39" />
      <circle cx={350} cy={88} r={5.5} fill="#001335" stroke="#FFBD39" />
    </svg>
  )
}

export default SvgSwooshLarge1
