import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MessageTypeWrapper from '../MessageTypeWrapper'
import EyeSmallGrey from 'components/common/icons/EyeSmallGrey'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles(
  theme => ({
    messageBody: {
      padding: '0 12px',
      marginLeft: 6,
      borderLeft: ({ color }) => `4px solid ${color}`,
      width: ({ isMobile, width }) => (isMobile ? 'auto' : width || 410)
    },
    messageContentContainer: {
      flexWrap: 'nowrap',
      position: 'relative'
    },
    titleIconContainer: {
      padding: '0 2px !important',
      height: 26
    },
    title: {
      color: ({ color }) => color
    },
    timestamp: {
      marginTop: 8,
      fontSize: 10,
      textAlign: 'start',
      color: 'rgba(0, 19, 53, 0.45)',
      marginLeft: 3
    },
    visibilityNote: {
      color: 'var(--text-color-11)'
    },
    bottomComponent: {
      marginTop: 12,
      marginLeft: 4
    }
  }),
  {}
)

const BaseAIMessage = ({
  id,
  timestamp,
  containerDivId,
  title = '',
  titleIcon,
  color = '#000',
  onlyVisibleToPractice = true,
  children,
  bottomComponent,
  width
}) => {
  const classes = useStyles({ color, isMobile: isMobile(), width })
  const { t } = useTranslation()

  return (
    <MessageTypeWrapper id={id} alignLeft={true} useBubble={false}>
      <Grid container direction="column" className={classes.messageBody} spacing={1} id={containerDivId}>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            {titleIcon && (
              <Grid item className={classes.titleIconContainer}>
                {titleIcon}
              </Grid>
            )}
            <Grid item>
              <DazzedParagraph12 thickness="bold" className={classes.title}>
                {title || t('pages.patients.selectedPatient.chat.grinAiMessage.title')}
              </DazzedParagraph12>
            </Grid>
            {onlyVisibleToPractice && (
              <Grid item>
                <DazzedParagraph12 className={classes.visibilityNote}>
                  (<EyeSmallGrey /> {t('pages.patients.selectedPatient.chat.grinAiMessage.onlyVisibleToPractice')})
                </DazzedParagraph12>
              </Grid>
            )}
          </Grid>
        </Grid>
        {children}
      </Grid>
      {bottomComponent && <div className={classes.bottomComponent}>{bottomComponent}</div>}
      <div className={classes.timestamp}>{timestamp}</div>
    </MessageTypeWrapper>
  )
}

export default BaseAIMessage
