import * as React from 'react'

const LandingPageDesignIcon = props => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.1309 8.64645L15.7773 8.29289L15.4238 8.64645L9.05983 15.0104C8.08352 15.9867 8.08352 17.5696 9.05983 18.5459L13.3025 22.7886C14.2788 23.7649 15.8617 23.7649 16.838 22.7886L23.202 16.4246L23.5555 16.0711L23.202 15.7175L16.1309 8.64645Z"
      fill="white"
      stroke="#041333"
    />
    <mask id="mask0_166_5" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="8" y="9" width="15" height="15">
      <path
        d="M15.7773 9L22.8484 16.0711L16.4845 22.435C15.7034 23.2161 14.4371 23.2161 13.656 22.435L9.41338 18.1924C8.63233 17.4113 8.63233 16.145 9.41338 15.364L15.7773 9Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_166_5)">
      <path d="M22.8484 16.0711L15.0702 23.8492L7.99917 16.7782L22.8484 16.0711Z" fill="#041333" />
    </g>
    <path
      d="M24.3281 19L26.2755 22.3244C26.7945 23.2103 26.65 24.3349 25.924 25.061C25.0426 25.9424 23.6136 25.9424 22.7323 25.061C22.0062 24.3349 21.8618 23.2103 22.3807 22.3244L24.3281 19Z"
      fill="#041333"
    />
  </svg>
)

export default LandingPageDesignIcon
