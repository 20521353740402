import { API } from 'aws-amplify'
import { isEmpty } from 'lodash'
import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mapTo, mergeMap, pluck, switchMap, withLatestFrom } from 'rxjs/operators'
import Actions from '../actions'

export const watchSelectPlanModalVisibleEpic = (action$, state$) => {
  return action$.pipe(
    ofType(Actions.SET_SELECT_PLAN_MODAL_VISIBILITY),
    pluck('payload'),
    withLatestFrom(state$),
    filter(
      ([isOpen, { billingReducer }]) =>
        isOpen && !billingReducer.grinPlans.isLoading && isEmpty(billingReducer.grinPlans)
    ),
    map(({ payload }) => Actions.fetchGrinPlans())
  )
}

export const fetchGrinScopeProductsTrigger = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SET_ORDER_GRIN_KITS_MODAL_VISIBILITY, Actions.SET_SELECT_PLAN_MODAL_VISIBILITY),
    withLatestFrom(state$),
    map(([action, state]) => ({
      isOpened: action.payload,
      wasScopeLoaded: !isEmpty(state.productReducer.scope.data)
    })),
    filter(({ isOpened, wasScopeLoaded }) => !!isOpened && !wasScopeLoaded),
    mapTo(Actions.fetchGrinScopeProducts())
  )

export const fetchGrinScopeProductsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_GRIN_SCOPE_PRODUCTS),
    withLatestFrom(state$),
    map(([, state]) => ({
      plan: state.practiceReducer?.billing?.grinPlan
    })),
    switchMap(({ plan }) =>
      from(API.get('grinApi', `/billing/v1/products/scope?includeScopeMini=true&grinPlanGroup=${plan.planGroup}`)).pipe(
        mergeMap(scopeProduct => of(Actions.fetchGrinScopeProductsReceived(scopeProduct))),
        catchError(response => of(Actions.fetchGrinScopeProductsFailed(response)))
      )
    )
  )
