import React from 'react'
import { makeStyles, TableCell } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { TIME_FORMAT_4, TIME_FORMAT_5 } from '../../../../consts/dateTimeConsts'

const useStyles = ({ highlighted, rowIndex }) =>
  makeStyles({
    root: {
      height: '78px',
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Dazzed',
      color: 'var(--text-color-11)',
      borderTop: rowIndex === 0 && highlighted ? '1px solid' : 0,
      borderColor: highlighted ? 'var(--border-color-1)' : 'var(--border-color-11)'
    }
  })({ highlighted, rowIndex })

const LastUpdateCell = ({ rowIndex, actionRequired = false, date = null }) => {
  const { t } = useTranslation()
  const classes = useStyles({ highlighted: actionRequired, rowIndex })
  return (
    <TableCell align="left" classes={{ root: classes.root }}>
      {date
        ? `${moment(date).format(TIME_FORMAT_4)}\u00A0\u00A0\u00A0${moment(date).format(TIME_FORMAT_5)}`
        : t('pages.taskManager.table.notAvailable')}
    </TableCell>
  )
}

export default React.memo(LastUpdateCell)
