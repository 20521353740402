const grinScanFull = `
          id
          patientId
          a_doctor
          a_patient
          date
          metadata
          status
          createdAt
          _version
          _deleted
          simulationStatus
          appliance
          scanPairId
          applianceIndex
          treatmentType
          scanSummaryData
          scanSummaryStatus
          autochartId
          withAligner
          trackingStatus
          trackingDetails
          treatmentTrackerData
          treatmentTrackerStatus
          video {
            bucket
            region
            key
          }
          scanReviews {
            items {
              id
              a_doctor
              a_patient
              video {
                bucket
                region
                key
              }
              createdAt
              _version
              _deleted
              _lastChangedAt
              updatedAt
              reviewer {
                id
                name
              }
            }
            nextToken
            startedAt
          }
          referrals {
            items {
              id
              referralData
              notificationMedium
              notificationDestination
              grinDoctorNote
              doctorId
              doctor {
                name
                roleDescription
              }
              relatedEntityId
              referralReview
              reviewStatus
              entityType
              createdAt
              reviewSubmissionDate
            }
          }
        `

export const getPatients = `query GetDoctor($id: ID!) {
  getDoctor(id: $id) {
    patients {
      items {
        id
        username
        auth_status
        task_status
        owner
        a_doctor
      }
      nextToken
    }
  }
}
`

export const listMessages = `query ListMessages(
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      author {
        id
        username
        type
      }
      authorId
      content
      messageRoomId
      createdAt
      updatedAt
    }
  }
}
`

export const customListGrinUsers = `query ListGrinUsers(
  $filter: ModelGrinUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listGrinUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      type
    }
  }
}
`

export const patientByUsername = `query PatientByUsername(
  $username: String
) {
  patientByUsername(
    username: $username
  ) {
    items {
      id
      patientDoctorId
      gender
      doctor {
        id
        username
        auth_status
        email
        name
        photo {
          bucket
          region
          key
        }
        degrees
        owner
        _version
        _deleted
        _lastChangedAt
      }
      username
      auth_status
      task_status
      initialScans {
        nextToken
        startedAt
      }
      treatments {
        items {
          id
          date
          status
          simulation {
            status
            backlog
          }
          offer {
            price
            currency
            status
            message
            tier
          }
          stages {
            nextToken
          }
          a_doctor
          a_patient
        }
        nextToken
      }
      details {
        name
        firstName
        lastName
        email
        phone
        countryCode
        birthdate
        address
        city
        zipcode
        state
        country
        ageRange
        parentFirstName
        parentLastName
        address2
        treatmentType
      }
      owner
      a_doctor
      photo {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        isActive
        type
        _version
        _deleted
        _lastChangedAt
      }
    }
    nextToken
    startedAt
  }
}
`

export const grinUserByUsername = `query GrinUserByUsername(
  $username: String
  $sortDirection: ModelSortDirection
  $filter: ModelGrinUserFilterInput
  $limit: Int
  $nextToken: String
) {
  grinUserByUsername(
    username: $username
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      username
      type
      version
      model
      deviceName
      patient {
        id
        _version
        username
        auth_status
        task_status
        owner
        a_doctor
        photo
      }
      doctor {
        id
        username
        auth_status
        email
        name
        photo
        degrees
        owner
      }
      rooms {
        nextToken
      }
      messages {
        nextToken
      }
    }
    nextToken
  }
}
`

export const getTreatmentFull = `
  query GetTreatment($id: ID!) {
    getTreatment(id: $id) {
      id
      patientId
      date
      endDate
      status
      simulation {
        images {
          bucket
          region
          key
        }
        videos {
          bucket
          region
          key
        }
        status
        backlog
      }
      offer {
        price
        currency
        status
        video {
          bucket
          region
          key
        }
        message
        tier
      }
      stageDefaultDuration
      cycleInterval
      a_doctor
      a_patient
      _version
      _deleted
      _lastChangedAt
      txTrackerStls
      stages {
        items {
          id
          treatmentId
          allignerId
          index
          startDate
          endDate
          duration
          alert
          status
          milestoneId
          a_doctor
          a_patient
          _version
          _deleted
          _lastChangedAt
          scans {
            video {
              bucket
              region
              key
            }
            date
          }
        }
        nextToken
        startedAt
      }
    }
  }
`

export const getTreatmentsFullByPatientId = /* GraphQL */ `
  query TreatmentsByPatientId(
    $patientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTreatmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    treatmentsByPatientId(
      patientId: $patientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        date
        type
        endDate
        postConfirmationStatusKey
        status
        type
        defaultApplianceName
        currentApplianceIndex
        totalApplianceNumber
        medicalRecordsData
        giTrackSettings
        txTrackerStls
        timelineEventLog {
          eventType
          timestamp
          params
        }
        currentStatus {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_doctor
          a_patient
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          status {
            id
            key
            program
            type
            period
            maxScansPerPeriod
            hasLimitedScans
            shouldReceiveReminders
            isManualAssignmentAllowed
            isChatAllowed
            requireDoubleScan
            scanCyclesEnabled
            scanFrequencyDefaults
            settings
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
        }
        simulation {
          images {
            bucket
            region
            key
          }
          videos {
            bucket
            region
            key
          }
          status
          backlog
        }
        offer {
          price
          currency
          status
          video {
            bucket
            region
            key
          }
          message
          tier
        }
        cycleInterval
        cycleIntervalPeriodType
        introMeetingDate
        a_doctor
        a_patient
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`

export const getPatientWithRoomWithoutScans = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      publicId
      patientDoctorId
      username
      auth_status
      task_status
      guardianId
      createdAt
      plan
      gender
      initialScans {
        items {
          oralImages {
            bucket
            region
            key
          }
          panoramics {
            bucket
            region
            key
          }
          stls {
            bucket
            region
            key
          }
          id
          patientId
          date
          comment
          scannerType
          createdAt
          a_doctor
          a_patient
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      patientTags {
        items {
          id
          patientTagPatientId
          patientTagTagId
          a_doctor
          a_patient
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          metadata
          tag {
            id
            value
            type
            count
            owner
            settings
            tagDoctorId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
        }
        nextToken
        startedAt
      }
      treatments {
        items {
          id
          patientId
          date
          type
          defaultApplianceName
          currentApplianceIndex
          totalApplianceNumber
          timelineEventLog {
            eventType
            timestamp
            params
          }
          endDate
          status
          cycleInterval
          cycleIntervalPeriodType
          postConfirmationStatusKey
          introMeetingDate
          giTrackSettings
          txTrackerStls
          currentStatus {
            id
            statusId
            statusKey
            treatmentId
            setByUsername
            a_doctor
            a_patient
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            status {
              id
              key
              program
              type
              period
              maxScansPerPeriod
              hasLimitedScans
              shouldReceiveReminders
              isManualAssignmentAllowed
              isChatAllowed
              requireDoubleScan
              scanCyclesEnabled
              scanFrequencyDefaults
              settings
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
          }
          stageDefaultDuration
          medicalRecordsData
          a_doctor
          a_patient
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      cycles(sortDirection: DESC, limit: 3) {
        items {
          id
          patientId
          a_doctor
          a_patient
          createdAt
          updatedAt
          startDate
          originalEndDate
          actualEndDate
          index
          status
          _version
          _deleted
        }
      }
      details {
        name
        firstName
        lastName
        email
        phone
        countryCode
        birthdate
        address
        city
        zipcode
        state
        country
        ageRange
        parentFirstName
        parentLastName
        parentEmail
        address2
        treatmentType
      }
      owner
      a_doctor
      photo {
        bucket
        region
        key
      }
      doctor {
        id
        username
        auth_status
        email
        name
        degrees
        owner
        photo {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          type
          isActive
          grinPlanKey
          a_doctor
          _version
          _deleted
          _lastChangedAt
        }
      }
      actionItems {
        items {
          type
          status
          params
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        type
        isActive
        a_doctor
        version
        externalIds
        timezone
        _version
        _deleted
        _lastChangedAt
        messagingPreferences {
          contacts {
            email
          }
        }
        messages {
          nextToken
          startedAt
        }
        program
        featureFlags {
          id
          flags
        }
        rooms {
          items {
            id
            members
            grinUserId
            roomId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
        }
      }
    }
  }
`
export const grinScansByPatientIdForGetPatientWithRoom = /* GraphQL */ `
  query GrinScansByPatientId(
    $patientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGrinScanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    grinScansByPatientId(
      patientId: $patientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${grinScanFull}
      }
      nextToken
      startedAt
    }
  }
`

export const doctorByUsername = /* GraphQL */ `
  query DoctorByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        accountOwnerId
        createdAt
        auth_status
        email
        name
        degrees
        rcToken
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        countryCode
        phoneNumber
        roleDescription
        bio
        contactEmail
        contactName
        metricType
        searchRadius
        areaOfCoverage
        clinic {
          practiceName
          practiceTimezone
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          countryCode
          practicePhone
          practiceCountryCode
          email
          calendarId
          homepageUrl
          logo {
            bucket
            region
            key
          }
        }
        tags {
          items {
            id
            type
            value
            count
            settings
            _deleted
          }
        }
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          _version
          _deleted
          _lastChangedAt
        }
        patients {
          items {
            id
            _deleted
            user {
              program
            }
          }
        }
        owner
        photo {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        accountOwner {
          id
          rcToken
          hasLocatorConsent
          bio
          username
          clinic {
            practiceName
            practiceTimezone
            address1
            address2
            city
            zip
            state
            country
            phone
            additionalPhone
            countryCode
            practicePhone
            practiceCountryCode
            email
            calendarId
            homepageUrl
            logo {
              bucket
              region
              key
            }
          }
          user {
            id
            _version
            stripeCustomerId
            externalIds
            grinPlanKey
            planOverrides
            appSettings
            featureFlags {
              id
              _version
              flags
            }
          }
          leads {
            items {
              id
              conversionStatus
              program
              _deleted
            }
          }
          patients {
            items {
              id
              _deleted
              user {
                program
              }
            }
          }
        }
        user {
          id
          externalIds
          username
          timezone
          type
          program
          appSettings
          grinPlanKey
          stripeCustomerId
          planOverrides
          allowed_groups_permissions
          a_doctor
          _version
          _deleted
          featureFlags {
            id
            username
            flags
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          messagingPreferences {
            id
            reminders {
              type
              medium
            }
            entities {
              type
              medium
              filters
            }
            contacts {
              name
              phone
              email
              countryCode
            }
            _version
            _deleted
            _lastChangedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`

export const getDoctor = /* GraphQL */ `
  query GetDoctor($id: ID!) {
    getDoctor(id: $id) {
      id
      username
      accountOwnerId
      createdAt
      auth_status
      email
      name
      countryCode
      phoneNumber
      degrees
      rcToken
      allowedZipcodes
      startPracticeYear
      hasLocatorConsent
      bio
      contactEmail
      contactName
      metricType
      searchRadius
      areaOfCoverage
      roleDescription
      accessType
      clinic {
        practiceName
        address1
        address2
        city
        zip
        state
        country
        phone
        additionalPhone
        countryCode
        practicePhone
        practiceTimezone
        practiceCountryCode
        email
        calendarId
        homepageUrl
        logo {
          bucket
          region
          key
        }
      }
      tags {
        items {
          id
          type
          value
          count
          settings
          isDeleted
          _deleted
        }
      }
      billingInfo {
        id
        owner
        payeeName
        phoneNumber
        payeeZip
        accountNumber
        routingNumber
        _version
        _deleted
        _lastChangedAt
      }
      leads {
        items {
          id
          conversionStatus
          program
          _deleted
        }
      }
      patients {
        items {
          id
          _deleted
          user {
            program
          }
        }
      }
      owner
      photo {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      accountOwner {
        id
        rcToken
        username
        rcToken
        username
        hasLocatorConsent
        bio
        name
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          countryCode
          practicePhone
          practiceCountryCode
          practiceTimezone
          email
          calendarId
          homepageUrl
          logo {
            bucket
            region
            key
          }
        }
        user {
          id
          externalIds
          _version
          stripeCustomerId
          grinPlanKey
          planOverrides
          appSettings
          featureFlags {
            id
            _version
            flags
          }
        }
        leads {
          items {
            id
            conversionStatus
            program
            _deleted
          }
        }
        patients {
          items {
            id
            _deleted
            user {
              program
            }
          }
        }
      }
      user {
        id
        username
        timezone
        type
        program
        appSettings
        grinPlanKey
        stripeCustomerId
        externalIds
        planOverrides
        allowed_groups_permissions
        a_doctor
        _version
        _deleted
        featureFlags {
          id
          username
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messagingPreferences {
          id
          reminders {
            type
            medium
          }
          entities {
            type
            medium
            filters
          }
          contacts {
            name
            phone
            email
            countryCode
          }
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`

export const updateDoctor = /* GraphQL */ `
  mutation UpdateDoctor($input: UpdateDoctorInput!, $condition: ModelDoctorConditionInput) {
    updateDoctor(input: $input, condition: $condition) {
      id
      username
      auth_status
      email
      accountOwnerId
      name
      degrees
      createdAt
      rcToken
      allowedZipcodes
      startPracticeYear
      bio
      contactEmail
      contactName
      metricType
      searchRadius
      areaOfCoverage
      clinic {
        practiceName
        address1
        address2
        city
        zip
        state
        country
        phone
        additionalPhone
        countryCode
        email
        calendarId
        homepageUrl
        logo {
          bucket
          region
          key
        }
      }
      tags {
        items {
          id
          type
          value
          count
          settings
          _deleted
        }
      }
      billingInfo {
        id
        owner
        payeeName
        phoneNumber
        payeeZip
        accountNumber
        routingNumber
        doctor {
          id
          username
          auth_status
          email
          name
          degrees
          owner
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      leads {
        items {
          id
          conversionStatus
          program
          _deleted
        }
      }
      patients {
        items {
          id
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      owner
      photo {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        type
        appSettings
        grinPlanKey
        stripeCustomerId
        externalIds
        planOverrides
        allowed_groups_permissions
        timezone
        program
        featureFlags {
          id
          username
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          user {
            id
            username
            timezone
            type
            a_doctor
            isActive
            owner
            program
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        patient {
          id
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          username
          auth_status
          email
          name
          degrees
          owner
          _version
          _deleted
          _lastChangedAt
        }
        messagingPreferences {
          id
          reminders {
            type
            medium
          }
          entities {
            type
            medium
            filters
          }
          contacts {
            name
            phone
            email
            countryCode
          }
          _version
          _deleted
          _lastChangedAt
          user {
            id
            username
            type
            a_doctor
            _version
            _deleted
            _lastChangedAt
          }
        }
        a_doctor
        _version
        _deleted
        _lastChangedAt
        messages {
          nextToken
          startedAt
        }
        rooms {
          items {
            id
            grinUserId
            members
            roomId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`

export const listRooms = /* GraphQL */ `
  query ListRooms($filter: ModelRoomFilterInput, $limit: Int, $nextToken: String) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastMessagePreview
        lastMessageTimestamp
        createdAt
        _version
        _deleted
        users {
          items {
            grinUserId
            user {
              username
              isActive
              a_doctor
              _version
              _deleted
              _lastChangedAt
              program
              patient {
                id
                _version
                patientDoctorId
                isActive
                username
                auth_status
                task_status
                plan
                details {
                  name
                  firstName
                  lastName
                  email
                  phone
                  countryCode
                  birthdate
                  address
                  city
                  zipcode
                  state
                  country
                  ageRange
                  parentFirstName
                  parentLastName
                  address2
                  treatmentType
                }
                user {
                  id
                  username
                  program
                  _version
                  isActive
                }
                doctor {
                  id
                  username
                  auth_status
                  email
                  name
                  degrees
                  owner
                  createdAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                owner
                a_doctor
                photo {
                  bucket
                  region
                  key
                }
              }
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`

export const roomsSorted = /* GraphQL */ `
  query RoomsSorted(
    $sortField: String
    $lastMessageTimestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roomsSorted(
      sortField: $sortField
      lastMessageTimestamp: $lastMessageTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastMessagePreview
        lastMessageTimestamp
        createdAt
        _version
        _deleted
        users {
          items {
            grinUserId
            user {
              username
              isActive
              a_doctor
              _version
              _deleted
              _lastChangedAt
              program
              patient {
                id
                _version
                patientDoctorId
                isActive
                username
                auth_status
                task_status
                plan
                details {
                  name
                  firstName
                  lastName
                  email
                  phone
                  countryCode
                  birthdate
                  address
                  city
                  zipcode
                  state
                  country
                  ageRange
                  parentFirstName
                  parentLastName
                  address2
                  treatmentType
                }
                user {
                  id
                  username
                  _version
                  program
                  isActive
                }
                doctor {
                  id
                  username
                  auth_status
                  email
                  name
                  degrees
                  owner
                  createdAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                patientTags {
                  items {
                    metadata
                    id
                    _deleted
                    tag {
                      id
                      value
                      type
                      settings
                      tagClass
                      _deleted
                    }
                  }
                  nextToken
                  startedAt
                }
                owner
                a_doctor
                photo {
                  bucket
                  region
                  key
                }
              }
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`

export const getRoomWithPatient = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      members
      lastMessagePreview
      lastMessageTimestamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messages {
        items {
          id
          grinUserId
          roomId
          owner
          members
          type
          content
          readBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          members
          grinUserId
          user {
            id
            username
            type
            isActive
            a_doctor
            _version
            _deleted
            _lastChangedAt
            program
            patient {
              id
              _version
              patientDoctorId
              isActive
              username
              auth_status
              task_status
              plan
              details {
                name
                firstName
                lastName
                email
                phone
                countryCode
                birthdate
                address
                city
                zipcode
                state
                country
                ageRange
                parentFirstName
                parentLastName
                address2
                treatmentType
              }
              user {
                id
                program
                isActive
              }
              doctor {
                id
                username
                auth_status
                email
                name
                degrees
                owner
                createdAt
                _version
                _deleted
                _lastChangedAt
              }
              owner
              a_doctor
              photo {
                bucket
                region
                key
              }
            }
          }
          roomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`

export const grinScansByPatientId = /* GraphQL */ `
  query GrinScansByPatientId(
    $patientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGrinScanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    grinScansByPatientId(
      patientId: $patientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        a_doctor
        a_patient
        images {
          bucket
          region
          key
        }
        video {
          bucket
          region
          key
        }
        date
        status
        isOffCycle
        aligner
        appliance
        applianceIndex
        treatmentType
        createdAt
        updatedAt
        simulationStatus
        scanPairId
        withAligner
        scanSummaryStatus
        scanSummaryData
        autochartId
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        scanReviews {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`

export const listPatientsWithScans = /* GraphQL */ `
  query ListPatients($filter: ModelPatientFilterInput, $limit: Int, $nextToken: String) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          address2
          treatmentType
        }
        owner
        a_doctor
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          externalIds
          username
          timezone
          model
          version
          deviceName
          isActive
          type
          a_doctor
          owner
          program
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          termsVersion
          featureFlags {
            id
            _version
            flags
          }
        }
        doctor {
          id
          username
          auth_status
          email
          name
          degrees
          owner
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        grinScans(limit: 1000) {
          items {
            id
            createdAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`

export const listPatientsCreationDate = /* GraphQL */ `
  query ListPatients($filter: ModelPatientFilterInput, $limit: Int, $nextToken: String) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        auth_status
        _deleted
        id
        doctor {
          email
        }
        user {
          authConfirmationDate
          isActive
          program
        }
        grinScans {
          items {
            createdAt
          }
        }
      }
      nextToken
    }
  }
`

export const listDoctors = /* GraphQL */ `
  query ListDoctors($filter: ModelDoctorFilterInput, $limit: Int, $nextToken: String) {
    listDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rcToken
        username
        auth_status
        email
        name
        degrees
        accountOwnerId
        accountOwner {
          id
          rcToken
          username
          email
          name
          createdAt
          countryCode
          hasLocatorConsent
          bio
          phoneNumber
          user {
            grinPlanKey
          }
          clinic {
            practiceName
          }
          _version
        }
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          homepageUrl
          logo {
            bucket
            region
            key
          }
        }
        owner
        photo {
          bucket
          region
          key
        }
        createdAt
        accountOwnerId
        roleDescription
        accessType
        phoneNumber
        countryCode
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          externalIds
          username
          timezone
          type
          a_doctor
          isActive
          owner
          groups
          program
          authConfirmationDate
          appSettings
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          termsVersion
          planOverrides
          stripeCustomerId
          grinPlanKey
          featureFlags {
            id
            flags
            _version
          }
          messagingPreferences {
            id
            reminders {
              type
              medium
            }
            entities {
              type
              medium
              filters
            }
            contacts {
              name
              phone
              email
              countryCode
            }
            _version
            _deleted
            _lastChangedAt
            user {
              id
              username
              type
              a_doctor
              _version
              _deleted
              _lastChangedAt
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`

export const listDoctorsCreationDate = /* GraphQL */ `
  query ListDoctors($filter: ModelDoctorFilterInput, $limit: Int, $nextToken: String) {
    listDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        _deleted
        email
      }
    }
  }
`

export const listGrinScansCreationDate = /* GraphQL */ `
  query ListGrinScans($filter: ModelGrinScanFilterInput, $limit: Int, $nextToken: String) {
    listGrinScans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        _deleted
        patientId
      }
      nextToken
    }
  }
`

export const listStatsDoctorPlanss = /* GraphQL */ `
  query ListDoctorPlanss($filter: ModelDoctorPlansFilterInput, $limit: Int, $nextToken: String) {
    listDoctorPlanss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        email
        a_doctor
        _deleted
        createdAt
      }
      nextToken
      startedAt
    }
  }
`

export const onNewNotification = /* GraphQL */ `
  subscription OnNewNotification($grinUserId: ID!) {
    onNewNotification(grinUserId: $grinUserId) {
      id
      type
      readStatus
      owner
      title
      subtitle
      body
      entityType
      entityId
      method
      reminderType
      grinUserId
      createdAt
      updatedAt
      _deleted
      user {
        id
        username
        timezone
        type
        a_doctor
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        termsVersion
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        messagingPreferences {
          id
          reminderTime
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        featureFlags {
          id
          username
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          name
          degrees
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`

export const updateGrinUserWithoutBillingFields = /* GraphQL */ `
  mutation UpdateGrinUser($input: UpdateGrinUserInput!, $condition: ModelGrinUserConditionInput) {
    updateGrinUser(input: $input, condition: $condition) {
      id
      username
      timezone
      type
      a_doctor
      isActive
      owner
      program
      authConfirmationDate
      appSettings
      termsVersion
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      messagingPreferences {
        id
        reminderTime
        reminders {
          type
          medium
          filters
        }
        entities {
          type
          medium
          filters
        }
        contacts {
          name
          phone
          countryCode
          email
        }
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          externalIds
          username
          timezone
          type
          a_doctor
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          termsVersion
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      featureFlags {
        id
        username
        a_doctor
        flags
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          externalIds
          username
          timezone
          type
          a_doctor
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          termsVersion
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      patient {
        id
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          address2
          treatmentType
        }
        owner
        a_doctor
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          timezone
          type
          a_doctor
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          termsVersion
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          name
          degrees
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          email
          countryCode
          calendarId
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          timezone
          type
          a_doctor
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          termsVersion
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          items {
            id
            patientDoctorId
            username
            auth_status
            task_status
            owner
            a_doctor
            _version
            _deleted
            _lastChangedAt
            patientTags {
              items {
                id
                patientTagPatientId
                patientTagTagId
                a_doctor
                a_patient
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                metadata
                tag {
                  id
                  value
                  type
                  count
                  owner
                  settings
                  tagDoctorId
                  tagClass
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
              }
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      messages {
        items {
          id
          grinUserId
          roomId
          uploadingDate
          owner
          members
          type
          content
          readBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      rooms {
        items {
          id
          members
          grinUserId
          roomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`

export const doctorPatientsWithActionItems = /* GraphQL */ `
  query PatientsByDoctorId(
    $doctorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientsByDoctorId(
      patientDoctorId: $doctorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientDoctorId
        username
        auth_status
        task_status
        gender
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          address2
          treatmentType
        }
        owner
        a_doctor
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          timezone
          language
          type
          a_doctor
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          termsVersion
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          name
          degrees
          owner
          allowedZipcodes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          items {
            id
            patientId
            date
            endDate
            status
            stageDefaultDuration
            cycleInterval
            introMeetingDate
            a_doctor
            a_patient
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems(
          sortDirection: DESC
          statusPriorityUpdatedAt: { beginsWith: { status: "open" } }
        ) {
          items {
            id
            actionItemDoctorId
            actionItemPatientId
            type
            status
            a_doctor
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`

export const getPatientWithOpenActionItems = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      patientDoctorId
      username
      auth_status
      task_status
      details {
        name
        firstName
        lastName
        email
        phone
        countryCode
        birthdate
        address
        city
        zipcode
        state
        country
        ageRange
        parentFirstName
        parentLastName
        address2
        treatmentType
      }
      owner
      a_doctor
      isActive
      photo {
        bucket
        region
        key
      }
      plan
      rcToRmDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        timezone
        language
        type
        a_doctor
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        termsVersion
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          calendarId
          homepageUrl
        }
        owner
        allowedZipcodes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      actionItems(
        sortDirection: DESC
        statusPriorityUpdatedAt: { beginsWith: { status: "open" } }
      ) {
        items {
          id
          doctorId
          patientId
          type
          status
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`

export const getTemplatesByTypeAndByDoctorId = /* GraphQL */ `
  query templatesByTypeAndByDoctorId(
    $type: String
    $doctorId: ModelIDKeyConditionInput
    $limit: Int
  ) {
    templatesByTypeAndByDoctorId(limit: $limit, type: $type, doctorId: $doctorId) {
      items {
        type
        title
        text
        isPrivate
        doctorId
        attachments {
          key
          bucket
          region
        }
        category
        creatorDoctorId
        _deleted
        _version
        id
      }
    }
  }
`

export const getSavedViewsByDoctorId = /* GraphQL */ `
  query savedViewsByDoctorId(
    $accountOwnerId: ID
    $filter: ModelSavedViewFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    savedViewsByDoctorId(limit: $limit, accountOwnerId: $accountOwnerId, nextToken: $nextToken, sortDirection: $sortDirection, filter: $filter) {
      items {
        name
        type
        filters
        isPrivate
        accountOwnerId
        creatorDoctorId
        _deleted
        _version
        id
      }
    }
  }
`

export const createSavedView = /* GraphQL */ `
  mutation createSavedView(
    $input: CreateSavedViewInput!
    $condition: ModelSavedViewConditionInput
  ) {
    createSavedView(condition: $condition, input: $input) {
      name
      type
      filters
      isPrivate
      accountOwnerId
      creatorDoctorId
      _deleted
      _version
      id
    }
  }
`

export const deleteSavedView = /* GraphQL */ `
  mutation deleteSavedView(
    $input: DeleteSavedViewInput!
    $condition: ModelSavedViewConditionInput
  ) {
    deleteSavedView(input: $input, condition: $condition) {
      _version
    }
  }
`

export const getWelcomeMessagesByDoctorId = /* GraphQL */ `
  query TemplatesByDoctorId(
    $doctorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    templatesByDoctorId(
      doctorId: $doctorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        type
        title
        text
        isPrivate
        doctorId
        creatorDoctorId
        _deleted
        _version
        id
      }
    }
  }
`

export const updatePatientForDoctorCard = /* GraphQL */ `
  mutation UpdatePatient($input: UpdatePatientInput!, $condition: ModelPatientConditionInput) {
    updatePatient(input: $input, condition: $condition) {
      id
      patientDoctorId
      username
      auth_status
      task_status
      details {
        name
        firstName
        lastName
        email
        phone
        countryCode
        birthdate
        address
        city
        zipcode
        state
        country
        ageRange
        parentFirstName
        parentLastName
        parentEmail
        address2
        treatmentType
      }
      owner
      a_doctor
      isActive
      photo {
        bucket
        region
        key
      }
      plan
      rcToRmDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        timezone
        language
        type
        a_doctor
        isActive
        externalIds
        version
        owner
        program
        authConfirmationDate
        appSettings
        termsVersion
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        messagingPreferences {
          contacts {
              name
              phone
              email
              countryCode
            }
        }
        rooms {
          items {
            id
            members
            grinUserId
            roomId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
        }
        featureFlags {
          id
          username
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          bio
          contactEmail
          contactName
          metricType
          searchRadius
          areaOfCoverage
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          calendarId
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        bio
        contactEmail
        contactName
        metricType
        searchRadius
        areaOfCoverage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          timezone
          language
          type
          a_doctor
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          termsVersion
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        patients {
          nextToken
          startedAt
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      initialScans {
        items {
          id
          oralImages {
            bucket
            region
            key
          }
          panoramics {
            bucket
            region
            key
          }
          stls {
            bucket
            region
            key
          }
          patientId
          date
          comment
          a_doctor
          a_patient
          scannerType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      treatments {
        items {
          id
          patientId
          date
          endDate
          status
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          a_doctor
          a_patient
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          currentStatus {
            id
            statusId
            statusKey
            treatmentId
            setByUsername
            a_doctor
            a_patient
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            status {
              id
              key
              program
              type
              period
              maxScansPerPeriod
              hasLimitedScans
              shouldReceiveReminders
              isManualAssignmentAllowed
              isChatAllowed
              requireDoubleScan
              scanCyclesEnabled
              scanFrequencyDefaults
              settings
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
          }
        }
        nextToken
        startedAt
      }
      grinScans {
        items {
          ${grinScanFull}
        }
        nextToken
        startedAt
      }
      
      cycles(sortDirection: DESC) {
        items {
          id
          patientId
          a_doctor
          a_patient
          createdAt
          updatedAt
          startDate
          originalEndDate
          actualEndDate
          index
          status
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      actionItems {
        items {
          id
          actionItemDoctorId
          actionItemPatientId
          type
          status
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`

const patientSearchModelObject = `
  id
  patientId
  email
  name
  username
  firstName
  lastName
  parentFirstName
  parentLastName
  parentEmail
  doctorId
  doctorName
  doctorEmail
  doctorUsername
  tagKeywords
  lastActionItemType
  lastActionItemStatus
  lastInteractionTimestamp
  isActive
  grinUserId
  roomId
  lastMessageTimestamp
  trackingStatus
  lastMessagePreview
  featureFlags
  program
  statusKey
  isChatRoomResolved
  treatmentType
  patientTags {
    items {
      id
      metadata
      tag {
        value
        type
      }
      _deleted
    }
  }
  profilePicture {
    bucket
    region
    key
  }
  lead {
    id
    email
    firstName
    lastName
    program
    invitationCode
    guardianId
    conversionStatus
    rmData
    rcData
    serviceAccountData
  }
  patient {
    id
    auth_status
    details {
      email
      name
    }
    user {
      id
      isActive
      program
      version
      _version
    }
    patientTags {
      items {
        id
        metadata
        tag {
          value
          type
        }
        _deleted
      }
    }
    cycles(sortDirection: DESC) {
      items { 
        id
        originalEndDate
        status
      }
    }
    _version
  }
  createdAt
  updatedAt
  _version
  _deleted
`

export const searchPatientSearchModels = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        ${patientSearchModelObject}
      }
      nextToken
      total
    }
  }
`

export const searchLeadSearchModels = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        lead {
          id
          email
          conversionStatus
          invitationCode
          lastName
          firstName
          guardianFirstName
          guardianLastName
          guardianId
          guardianEmail
          messages
          phone
          countryCode
          type
          program
          postConfirmationStatusKey
          rmData
          rcData
          serviceAccountData
          orthoData
          a_doctor
          doctorId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          doctor {
            id
            username
            auth_status
            email
            name
            degrees
            owner
            photo {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            user {
              id
              username
              type
              isActive
              a_doctor
              grinPlanKey
              _version
              _deleted
              _lastChangedAt
            }
          }
          initialScans {
            items {
              oralImages {
                bucket
                region
                key
              }
              panoramics {
                bucket
                region
                key
              }
              stls {
                bucket
                region
                key
              }
              id
              patientId
              date
              comment
              scannerType
              createdAt
              a_doctor
              a_patient
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
        }
        room {
          id
          lastMessageTimestamp
          lastMessagePreview
          isChatRoomResolved
          members
          sortField
          messages {
            items {
              content
              createdAt
              grinUserId
              hrefLabel
              href
              id
              isBroadcast
              isTransferred
              members
              metadata
              owner
              promotionData
              promotionId
              readBy
              roomId
              templateId
              type
              updatedAt
              uploadingDate
              _lastChangedAt
              pictures {
                bucket
                key
                region
              }
            }
          }
        }
        patientTags {
          items {
            id
            patientTagPatientId
            patientTagTagId
            a_doctor
            a_patient
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            metadata
            tag {
              id
              value
              type
              count
              owner
              settings
              tagDoctorId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
          }
        }
        patient {
          id
          guardianId
        }
      }
      nextToken
      total
    }
  }
`

export const searchPatientSearchModelsForDashboard = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        patientId
        email
        name
        username
        firstName
        lastName
        parentFirstName
        parentLastName
        doctorId
        doctorName
        doctorEmail
        doctorUsername
        tagKeywords
        lastActionItemType
        lastActionItemStatus
        lastInteractionTimestamp
        isActive
        grinUserId
        roomId
        trackingStatus
        lastMessageTimestamp
        lastMessagePreview
        featureFlags
        program
        statusKey
        profilePicture {
          bucket
          region
          key
        }
        patient {
          id
          _version
          auth_status
          details {
            email
            name
          }
          user {
            id
            isActive
            program
            version
            _version
          }
          actionItems(
            sortDirection: DESC
            limit: 10
            statusPriorityUpdatedAt: { beginsWith: { status: "open" } }
          ) {
            items {
              id
              actionItemDoctorId
              actionItemPatientId
              type
              params
              priority
              status
              a_doctor
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          patientTags {
            items {
              id
              tag {
                value
                type
              }
              metadata
              _deleted
            }
          }
          cycles(sortDirection: DESC) {
            items {
              id
              originalEndDate
              status
            }
          }
        }
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      total
    }
  }
`

export const searchPatientSearchModelsForDashboardFilters = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      total
    }
  }
`

export const searchPatientSearchModelsForTasks = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        lastActionItemStatus
        lastActionItemType
        lastInteractionTimestamp
        openActionItems
      }
    }
  }
`

export const searchPatients = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        ${patientSearchModelObject}
      }
      nextToken
      total
    }
  }
`

export const countPatientSearchModels = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      total
    }
  }
`

export const getPatientSearchModel = /* GraphQL */ `
  query GetPatientSearchModel($id: ID!) {
    getPatientSearchModel(id: $id) {
      ${patientSearchModelObject}
    }
  }
`

export const searchDoctorSearchModels = /* GraphQL */ `
  query SearchDoctorSearchModels(
    $filter: SearchableDoctorSearchModelFilterInput
    $sort: SearchableDoctorSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDoctorSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        email
        name
        photo {
          bucket
          region
          key
        }
      }
      nextToken
      total
    }
  }
`

export const totalHiAccountOwner = /* GraphQL */ `
  query SearchDoctorSearchModels(
    $filter: SearchableDoctorSearchModelFilterInput
    $sort: SearchableDoctorSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDoctorSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        practiceName
        name
        email
        hiGroup
        grinPlanKey
        _deleted
        doctor {
          user {
            isActive
            grinPlans {
              items {
                displayName
              }
            }
          }
        }
      }
      nextToken
      total
    }
  }
`

export const totalPracticeMembers = /* GraphQL */ `
  query SearchDoctorSearchModels(
    $filter: SearchableDoctorSearchModelFilterInput
    $sort: SearchableDoctorSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDoctorSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      total
    }
  }
`

export const doctorsTotalPatients = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      total 
    }
  }
`

export const createMessageTemplate = /* GraphQL */ `
  mutation CreateMessageTemplate(
    $input: CreateMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    createMessageTemplate(input: $input, condition: $condition) {
      id
      type
      title
      text
      isPrivate
      doctorId
      attachments {
        key
        bucket
        region
      }
      creatorDoctorId
      _deleted
      _version
    }
  }
`

export const updateMessageTemplate = /* GraphQL */ `
  mutation UpdateMessageTemplate(
    $input: UpdateMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    updateMessageTemplate(input: $input, condition: $condition) {
      id
      type
      title
      text
      isPrivate
      doctorId
      creatorDoctorId
      _deleted
      _version
    }
  }
`

export const deleteMessageTemplate = /* GraphQL */ `
  mutation DeleteMessageTemplate(
    $input: DeleteMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    deleteMessageTemplate(input: $input, condition: $condition) {
      id
    }
  }
`

export const tagsByType = /* GraphQL */ `
  query TagsByType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        value
        type
        count
        owner
        settings
        tagDoctorId
        a_readers
        a_writers
        createdAt
        updatedAt
        tagClass
        isDeleted
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`

export const tagsByDoctorId = /* GraphQL */ `
  query TagsByDoctorId(
    $tagDoctorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByDoctorId(
      tagDoctorId: $tagDoctorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        value
        type
        count
        owner
        settings
        tagDoctorId
        a_readers
        a_writers
        createdAt
        updatedAt
        tagClass
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`

export const updateGrinScan = /* GraphQL */ `
  mutation UpdateGrinScan($input: UpdateGrinScanInput!, $condition: ModelGrinScanConditionInput) {
    updateGrinScan(input: $input, condition: $condition) {
      id
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      images {
        bucket
        region
        key
      }
      video {
        bucket
        region
        key
      }
      date
      status
      isOffCycle
      aligner
      appliance
      applianceIndex
      treatmentType
      createdAt
      updatedAt
      simulationStatus
      scanPairId
      withAligner
      scanSummaryStatus
      scanSummaryData
      metadata
      _version
      _deleted
      _lastChangedAt
    }
  }
`

export const supportSearchPatientSearchModel = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        patientId
        doctorName
        doctorEmail
        program
        username
        profilePicture {
          bucket
          region
          key
        }
        patient {
          id
          _version
          gender
          details {
            name
            email
          }
        }
        lead {
          id
          invitationCode
          email
          firstName
          lastName
        }
        statusKey
        _version
        _deleted
      }
      nextToken
      total 
    }
  }
`

export const searchPatientSearchModelForLinkedAccounts = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        email
        patient {
          id
        }
      }
      nextToken
      total
    }
  }
`

export const invitePatientSearchPatientSearchModel = /* GraphQL */ `
query SearchPatientSearchModels(
  $filter: SearchablePatientSearchModelFilterInput
  $sort: SearchablePatientSearchModelSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchPatientSearchModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      patientId
      profilePicture {
        bucket
        region
        key
      }
      email
      name
      parentFirstName
      parentLastName
      parentEmail
      guardianId
      parentName
      statusType
      _version
      _deleted
    }
    nextToken
    total
  }
}
`


export const getSearchPatientSearchModel = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        patientId
        email
        name
        username
        firstName
        lastName
        guardianId
        parentFirstName
        parentLastName
        doctorId
        doctorName
        doctorEmail
        doctorUsername
        tagKeywords
        lastActionItemType
        lastActionItemStatus
        lastInteractionTimestamp
        isActive
        grinUserId
        roomId
        trackingStatus
        lastMessageTimestamp
        lastMessagePreview
        featureFlags
        program
        statusKey
        statusType
        profilePicture {
          bucket
          region
          key
        }
        status {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        patient {
          id
          gender
          patientDoctorId
          username
          auth_status
          task_status
          guardianId
          doctor {
            email
            name
          }
          patientTags {
            items {
              id
              patientTagPatientId
              patientTagTagId
              a_doctor
              a_patient
              a_readers
              a_writers
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              tag {
                id
                value
                type
                count
                owner
                settings
                tagDoctorId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
            }
            nextToken
            startedAt
          }
          details {
            name
            firstName
            lastName
            email
            phone
            countryCode
            birthdate
            address
            city
            zipcode
            state
            country
            ageRange
            isParentCheckedConsent
            parentEmail
            guardianRelation
            parentFirstName
            parentLastName
            address2
            treatmentType
          }
          owner
          a_doctor
          isActive
          photo {
            bucket
            region
            key
          }
          plan
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          user {
            id
            externalIds
            username
            timezone
            model
            version
            deviceName
            isActive
            type
            a_doctor
            owner
            program
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            termsVersion
            featureFlags {
              id
              _version
              flags
            }
            messagingPreferences {
              id
              contacts {
                email
                name
                phone
                countryCode
              }
            }
          }
        }
        lead {
          id
          email
          conversionStatus
          invitationCode
          lastName
          firstName
          guardianFirstName
          guardianLastName
          guardianEmail
          messages
          phone
          countryCode
          type
          program
          postConfirmationStatusKey
          rmData
          rcData
          serviceAccountData
          orthoData
          a_doctor
          a_readers
          a_writers
          doctorId
          createdAt
          updatedAt
          _version
          _deleted
        }
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      total
    }
  }
`

export const doctorPlansByAccountOwnerId = /* GraphQL */ `
  query DoctorPlansByAccountOwnerId(
    $accountOwnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorPlansFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorPlansByAccountOwnerId(
      accountOwnerId: $accountOwnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        auth_status
        firstName
        lastName
        accessType
        accountOwnerId
        addedBy
        _version
        _deleted
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`

export const doctorsByAccountOwnerIdForSupport = /* GraphQL */ `
  query DoctorsByAccountOwnerId(
    $accountOwnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorsByAccountOwnerId(
      accountOwnerId: $accountOwnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        auth_status
        email
        name
        photo {
          bucket
          region
          key
        }
        accountOwnerId
        roleDescription
        accessType
        phoneNumber
        countryCode
        user {
          id
          externalIds
          messagingPreferences {
            id
            contacts {
              name
              phone
              email
              countryCode
            }
            _version
          }
        }
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`

export const doctorsByAccountOwnerId = /* GraphQL */ `
  query DoctorsByAccountOwnerId(
    $accountOwnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorsByAccountOwnerId(
      accountOwnerId: $accountOwnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        auth_status
        email
        name
        user {
          id
          externalIds
        }
        photo {
          bucket
          region
          key
        }
        accountOwnerId
        roleDescription
        accessType
        username
        phoneNumber
        countryCode
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`

export const getAccountOwner = /* GraphQL */ `
  query GetDoctor($id: ID!) {
    getDoctor(id: $id) {
      id
      auth_status
      email
      name
      photo {
        bucket
        region
        key
      }
      accountOwnerId
      roleDescription
      accessType
      username
      phoneNumber
      countryCode
      user {
        id
        messagingPreferences {
          id
          contacts {
            name
            phone
            email
            countryCode
          }
          _version
        }
        featureFlags {
          flags
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`

export const updateDoctorPlans = /* GraphQL */ `
  mutation UpdateDoctorPlans(
    $input: UpdateDoctorPlansInput!
    $condition: ModelDoctorPlansConditionInput
  ) {
    updateDoctorPlans(input: $input, condition: $condition) {
      id
    }
  }
`

export const doctorPlansByEmail = /* GraphQL */ `
  query DoctorPlansByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorPlansFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorPlansByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        requireBillingInSignup
        signupProgress
      }
      nextToken
      startedAt
    }
  }
`

export const doctorByAccessType = /* GraphQL */ `
  query doctorByAccessType(
    $accessType: String
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorByAccessType(
      accessType: $accessType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rcToken
        username
        auth_status
        email
        name
        degrees
        hasLocatorConsent
        bio
        accountOwnerId
        accountOwner {
          id
          rcToken
          email
          name
          createdAt
          countryCode
          phoneNumber
          _version
          hasLocatorConsent
          bio
        }
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          calendarId
          homepageUrl
          logo {
            bucket
            region
            key
          }
        }
        owner
        photo {
          bucket
          region
          key
        }
        createdAt
        accountOwnerId
        roleDescription
        accessType
        phoneNumber
        countryCode
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          externalIds
          username
          timezone
          type
          a_doctor
          isActive
          owner
          groups
          program
          authConfirmationDate
          appSettings
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          termsVersion
          planOverrides
          stripeCustomerId
          grinPlanKey
          featureFlags {
            id
            flags
            _version
          }
          messagingPreferences {
            id
            reminders {
              type
              medium
            }
            entities {
              type
              medium
              filters
            }
            contacts {
              name
              phone
              email
              countryCode
            }
            _version
            _deleted
            _lastChangedAt
            user {
              id
              username
              type
              a_doctor
              _version
              _deleted
              _lastChangedAt
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`

export const doctorPlansByAccessType = /* GraphQL */ `
  query doctorPlansByAccessType(
    $accessType: String
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorPlansFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorPlansByAccessType(
      accessType: $accessType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        auth_status
        firstName
        lastName
        accessType
        accountOwnerId
        signupProgress
        plans
        program
        a_doctor
        a_readers
        a_writers
        doctorGroups
        requireBillingInSignup
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`

export const getDoctorForSupport = /* GraphQL */ `
  query GetDoctor($id: ID!) {
    getDoctor(id: $id) {
      id
      rcToken
      username
      auth_status
      email
      name
      degrees
      accountOwnerId
      bio
      hasLocatorConsent
      accountOwner {
        id
        username
        email
        name
        createdAt
        countryCode
        phoneNumber
        hasLocatorConsent
        bio
        user {
          id
          externalIds
          username
          timezone
          type
          a_doctor
          isActive
          owner
          groups
          program
          authConfirmationDate
          appSettings
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          termsVersion
          planOverrides
          stripeCustomerId
          grinPlanKey
          featureFlags {
            id
            flags
            _version
          }
          messagingPreferences {
            id
            reminders {
              type
              medium
            }
            entities {
              type
              medium
              filters
            }
            contacts {
              name
              phone
              email
              countryCode
            }
            _version
            _deleted
            _lastChangedAt
            user {
              id
              username
              type
              a_doctor
              _version
              _deleted
              _lastChangedAt
            }
          }
        }
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          homepageUrl
          logo {
            bucket
            region
            key
          }
        }
        _version
      }
      clinic {
        practiceName
        address1
        address2
        city
        zip
        state
        country
        phone
        additionalPhone
        email
        countryCode
        practicePhone
        practiceCountryCode
        calendarId
        homepageUrl
        logo {
          bucket
          region
          key
        }
      }
      owner
      photo {
        bucket
        region
        key
      }
      createdAt
      accountOwnerId
      roleDescription
      accessType
      phoneNumber
      countryCode
      _version
      _deleted
      _lastChangedAt
      updatedAt
      user {
        id
        username
        timezone
        type
        a_doctor
        isActive
        owner
        externalIds
        groups
        program
        authConfirmationDate
        appSettings
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        termsVersion
        planOverrides
        stripeCustomerId
        grinPlanKey
        featureFlags {
          id
          flags
          _version
        }
        messagingPreferences {
          id
          reminders {
            type
            medium
          }
          entities {
            type
            medium
            filters
          }
          contacts {
            name
            phone
            email
            countryCode
          }
          _version
          _deleted
          _lastChangedAt
          user {
            id
            username
            type
            a_doctor
            _version
            _deleted
            _lastChangedAt
          }
        }
      }
    }
  }
`

export const updatePracticeMemberForSupport = /* GraphQL */ `
  mutation UpdateDoctor($input: UpdateDoctorInput!, $condition: ModelDoctorConditionInput) {
    updateDoctor(input: $input, condition: $condition) {
      id
      rcToken
      username
      auth_status
      email
      name
      degrees
      accountOwnerId
      accountOwner {
        id
        username
        email
        name
        createdAt
        countryCode
        phoneNumber
        hasLocatorConsent
        bio
        user {
          id
          externalIds
          username
          timezone
          type
          a_doctor
          isActive
          owner
          groups
          program
          authConfirmationDate
          appSettings
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          termsVersion
          planOverrides
          stripeCustomerId
          grinPlanKey
          featureFlags {
            id
            flags
            _version
          }
          messagingPreferences {
            id
            reminders {
              type
              medium
            }
            entities {
              type
              medium
              filters
            }
            contacts {
              name
              phone
              email
              countryCode
            }
            _version
            _deleted
            _lastChangedAt
            user {
              id
              username
              type
              a_doctor
              _version
              _deleted
              _lastChangedAt
            }
          }
        }
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          homepageUrl
          logo {
            bucket
            region
            key
          }
        }
        _version
      }
      clinic {
        practiceName
        address1
        address2
        city
        zip
        state
        country
        phone
        additionalPhone
        email
        countryCode
        practicePhone
        practiceCountryCode
        calendarId
        homepageUrl
        logo {
          bucket
          region
          key
        }
      }
      owner
      photo {
        bucket
        region
        key
      }
      createdAt
      accountOwnerId
      roleDescription
      accessType
      phoneNumber
      countryCode
      _version
      _deleted
      _lastChangedAt
      updatedAt
      user {
        id
        username
        timezone
        type
        a_doctor
        isActive
        owner
        groups
        program
        authConfirmationDate
        appSettings
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        termsVersion
        planOverrides
        stripeCustomerId
        grinPlanKey
        featureFlags {
          id
          flags
          _version
        }
        messagingPreferences {
          id
          reminders {
            type
            medium
          }
          entities {
            type
            medium
            filters
          }
          contacts {
            name
            phone
            email
            countryCode
          }
          _version
          _deleted
          _lastChangedAt
          user {
            id
            username
            type
            a_doctor
            _version
            _deleted
            _lastChangedAt
          }
        }
      }
    }
  }
`

export const listDoctorsForSupport = /* GraphQL */ `
  query ListDoctors($filter: ModelDoctorFilterInput, $limit: Int, $nextToken: String) {
    listDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        name
        accountOwnerId
        createdAt
        hasLocatorConsent
        bio
        accountOwner {
          hasLocatorConsent
          bio
          email
          user {
            grinPlanKey
          }
          clinic {
            practiceName
            phone
            email
          }
        }
        clinic {
          practiceName
          phone
          address1
          address2
          city
          state
          zip
          country
          email
        }
        photo {
          bucket
          region
          key
        }
        roleDescription
        phoneNumber
        countryCode
        _version
        _deleted
        user {
          grinPlanKey
          groups
          termsVersion
          program
          featureFlags {
            flags
          }
          messagingPreferences {
            id
            reminders {
              type
              medium
            }
            entities {
              type
              medium
              filters
            }
            contacts {
              name
              phone
              email
              countryCode
            }
            _version
            _deleted
            _lastChangedAt
            user {
              id
              username
              type
              a_doctor
              _version
              _deleted
              _lastChangedAt
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`

export const updateDoctorAppSetting = /* GraphQL */ `
  mutation UpdateGrinUser($input: UpdateGrinUserInput!, $condition: ModelGrinUserConditionInput) {
    updateGrinUser(input: $input, condition: $condition) {
      id
      appSettings
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`

export const transactionsByUsernameSorted = /* GraphQL */ `
  query TransactionsByUsernameSorted(
    $owner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByUsernameSorted(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        type
        description
        creditCardBrand
        creditCardLast4
        creatorUserId
        amount
        a_readers
        a_writers
        createdAt
        scopesQuantity
      }
      nextToken
      startedAt
    }
  }
`

export const updateGrinScanForFeedback = /* GraphQL */ `
  mutation UpdateGrinScan($input: UpdateGrinScanInput!, $condition: ModelGrinScanConditionInput) {
    updateGrinScan(input: $input, condition: $condition) {
      id
      _version
      metadata
    }
  }
`

export const updateGrinUserForExternalIds = /* GraphQL */ `
  mutation UpdateGrinUser($input: UpdateGrinUserInput!, $condition: ModelGrinUserConditionInput) {
    updateGrinUser(input: $input, condition: $condition) {
      id
      externalIds
      _version
    }
  }
`

export const getRoomForResolve = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      _version
      isChatRoomResolved
    }
  }
`

export const getGrinScanForReview = /* GraphQL */ `
  query GetGrinScan($id: ID!) {
    getGrinScan(id: $id) {
      id
      patientId
      a_doctor
      a_patient
      date
      metadata
      status
      createdAt
      _version
      _deleted
      simulationStatus
      appliance
      scanPairId
      applianceIndex
      treatmentType
      scanSummaryData
      scanSummaryStatus
      withAligner
      video {
        bucket
        region
        key
      }
    }
  }
`

export const updateDoctorTag = /* GraphQL */ `
  mutation UpdateTag($input: UpdateTagInput!, $condition: ModelTagConditionInput) {
    updateTag(input: $input, condition: $condition) {
      id
      value
      type
      count
      owner
      settings
      tagDoctorId
      a_readers
      a_writers
      createdAt
      updatedAt
      isDeleted
      tagClass
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const scheduledMessagesByRoomIdSorted = /* GraphQL */ `
  query ScheduledMessagesByRoomIdSorted(
    $roomId: ID
    $nextToken: String
    $sortDirection: ModelSortDirection
    $scheduleTime: ModelStringKeyConditionInput
    $limit: Int
  ) {
    scheduledMessagesByRoomIdSorted(
      roomId: $roomId
      nextToken: $nextToken
      sortDirection: $sortDirection
      limit: $limit
      scheduleTime: $scheduleTime
    ) {
      items {
        id
        grinUserId
        owner
        roomId
        payload
        scheduleTime
        status
        _deleted
        _version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getScheduledMessage = /* GraphQL */ `
  query GetScheduledMessage($id: ID!) {
    getScheduledMessage(id: $id) {
      id
      grinUserId
      owner
      roomId
      payload
      scheduleTime
      status
      _deleted
      _version
      createdAt
      updatedAt
    }
  }
`

export const searchPatientsByTag = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      total
    }
  }
`

export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      email
      conversionStatus
      invitationCode
      lastName
      firstName
      guardianFirstName
      guardianLastName
      guardianEmail
      messages
      phone
      countryCode
      type
      program
      postConfirmationStatusKey
      rmData
      rcData
      serviceAccountData
      orthoData
      a_doctor
      a_readers
      a_writers
      doctorId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          practiceTimezone
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          grinUserPatientId
          grinUserDoctorId
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`

export const updateLead = /* GraphQL */ `
  mutation UpdateLead($input: UpdateLeadInput!, $condition: ModelLeadConditionInput) {
    updateLead(input: $input, condition: $condition) {
      id
      email
      conversionStatus
      invitationCode
      lastName
      firstName
      guardianFirstName
      guardianLastName
      guardianEmail
      messages
      phone
      countryCode
      type
      program
      postConfirmationStatusKey
      rmData
      rcData
      serviceAccountData
      orthoData
      a_doctor
      a_readers
      a_writers
      doctorId
      doctor {
        name
        clinic {
          practiceName
        }
      }
      createdAt
      audit
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`

const leadData = `
  id
  email
  firstName
  lastName
  phone
  countryCode
  type
  conversionStatus
  program
  rcData
  rmData
  serviceAccountData
  audit
  a_doctor
  a_readers
  a_writers
  doctorId
  doctor {
    name
    clinic {
      practiceName
    }
  }
  createdAt
  updatedAt
  _version
  _deleted
  _lastChangedAt
`

export const leadsByDoctorIdByProgram = /* GraphQL */ `
  query LeadsByDoctorIdByProgram(
    $doctorId: ID
    $program: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByDoctorIdByProgram(
      doctorId: $doctorId
      program: $program
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${leadData}
      }
      nextToken
      startedAt
    }
  }
`

export const leadsByDoctorIdByProgramByConversionStatus = /* GraphQL */ `
  query LeadsByDoctorIdByProgramByConversionStatus(
    $doctorId: ID
    $programConversionStatus: ModelLeadLeadsByDoctorIdByProgramByConversionStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByDoctorIdByProgramByConversionStatus(
      doctorId: $doctorId
      programConversionStatus: $programConversionStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${leadData}
      }
      nextToken
      startedAt
    }
  }
`

export const leadsByProgramByConversionStatus = /* GraphQL */ `
  query leadsByProgramByConversionStatus(
    $conversionStatus: ModelStringKeyConditionInput
    $filter: ModelLeadFilterInput
    $limit: Int
    $program: String
    $sortDirection: ModelSortDirection
    $nextToken: String
  ) {
    leadsByProgramByConversionStatus(
      conversionStatus: $conversionStatus,
      filter: $filter
      limit: $limit
      program: $program,
      sortDirection: $sortDirection
      nextToken: $nextToken
    ) {
      items {
        ${leadData}
      }
      nextToken
      startedAt
    }
  }
`;

export const leadsByProgramByConversionStatusWithGrinPlan = /* GraphQL */ `
  query leadsByProgramByConversionStatus(
    $conversionStatus: ModelStringKeyConditionInput
    $filter: ModelLeadFilterInput
    $limit: Int
    $program: String
    $sortDirection: ModelSortDirection
    $nextToken: String
  ) {
    leadsByProgramByConversionStatus(
      conversionStatus: $conversionStatus,
      filter: $filter
      limit: $limit
      program: $program,
      sortDirection: $sortDirection
      nextToken: $nextToken
    ) {
      items {
        ${leadData}
        doctor {
          user {
            grinPlanKey
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const searchPatientSearchModelsWithScans = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        lead {
          ${leadData}
        }
        patient {
          id
          patientTags {
            items {
              id
              tag {
                type
                value
              }
              metadata
              _deleted
            }
          }
          treatments {
            items {
              id
              currentStatus {
                statusKey
              }
            }
          }
          grinScans {
            items {
              id
              status
              createdAt
              trackingStatus
              trackingDetails
              metadata
            }
          }
          user {
            id
          },
          details {
            email
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const searchPatientSearchModelsWithScansAndGrinPlan = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        lead {
          ${leadData}
          doctor {
            user {
              grinPlanKey
            }
          }
        }
        statusType
        patient {
          id
          patientTags {
            items {
              id
              tag {
                type
                value
              }
              metadata
              _deleted
            }
          }
          treatments {
            items {
              id
              currentStatus {
                statusKey
              }
            }
          }
          grinScans {
            items {
              id
              status
              createdAt
              trackingStatus
              trackingDetails
              metadata
            }
          }
          user {
            id
          },
          details {
            email
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const getLeadForLiveUpdates = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      email
      firstName
      lastName
      phone
      countryCode
      type
      conversionStatus
      program
      rcData
      rmData
      serviceAccountData
      a_doctor
      a_readers
      a_writers
      doctorId
      doctor {
        name
        clinic {
          practiceName
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`

export const leadsByProgram = /* GraphQL */ `
  query LeadsByProgram(
    $program: String
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByProgram(
      program: $program
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        phone
        countryCode
        type
        conversionStatus
        program
        rcData
        rmData
        serviceAccountData
        a_doctor
        a_readers
        a_writers
        audit
        doctorId
        doctor {
          name
          email
          clinic {
            practiceName
          }
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`

export const getLeadForRcDashboard = /* GraphQL */ `
  query getLead($id: ID!) {
    getLead(id: $id) {
      id
      email
      firstName
      lastName
      phone
      countryCode
      type
      conversionStatus
      program
      rcData
      rmData
      serviceAccountData
      a_doctor
      a_readers
      a_writers
      doctorId
      doctor {
        name
        email
        clinic {
          practiceName
        }
      }
      audit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`

export const grinScansByLeadId = /* GraphQL */ `
  query GrinScansByPatientId(
    $patientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGrinScanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    grinScansByPatientId(
      patientId: $patientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${grinScanFull}
      }
      nextToken
      startedAt
    }
  }
`

export const grinScansByTrackingStatusSorted = /* GraphQL */ `
  query GrinScansByTrackingStatusSorted(
    $trackingStatus: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGrinScanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    grinScansByTrackingStatusSorted(
      trackingStatus: $trackingStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        trackingStatus
        trackingDetails
        metadata
        scanPairId
        patient {
          id
          details {
            firstName
            lastName
          }
          photo {
              key
              region
              bucket
            }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const autochartGetPatientSearchModel = /* GraphQL */ `
  query GetPatientSearchModel($id: ID!) {
    getPatientSearchModel(id: $id) {
      id
      name
      profilePicture {
        bucket
        key
        region
      }
      program
    }
  }
`

const autochartFields = `
  id
  notes
  teeth {
    exists
    number
    conditions
  }
  _version
`

export const autochartGetGrinScan = /* GraphQL */ `
  query GetGrinScan($id: ID!) {
    getGrinScan(id: $id) {
      id
      autochart {
        ${autochartFields}
      }
      scanSummaryData
      video {
        bucket
        region
        key
      }
    }
  }
`

export const autochartGetAutochart = /* GraphQL */ `
  query GetAutochart($id: ID!) {
    getAutochart(id: $id) {
      ${autochartFields}
    }
  }
`

export const updateAutochart = /* GraphQL */ `
  mutation updateAutochart($input: UpdateAutochartInput!) {
    updateAutochart(input: $input) {
        ${autochartFields}
    }
  }
`

export const searchSiblingSearchModels = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
      }
      nextToken
      total
    }
  }
`

export const searchSiblingSearchModelsForResendInvite = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        patient {
          id
        }
      }
      nextToken
      total
    }
  }
`

export const searchDoctorSearchModelsForHI = /* GraphQL */ `
  query SearchDoctorSearchModels(
    $filter: SearchableDoctorSearchModelFilterInput
    $sort: SearchableDoctorSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDoctorSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        email
        name
        practiceName
        dso
      }
      nextToken
      total
    }
  }
`

export const updateCycleMinimized = /* GraphQL */ `
  mutation UpdateCycle(
    $input: UpdateCycleInput!
    $condition: ModelCycleConditionInput
  ) {
    updateCycle(input: $input, condition: $condition) {
      id
    }
  }
`

export const getCycleForUpdateStatus = /* GraphQL */ `
  query GetCycle($id: ID!) {
    getCycle(id: $id) {
      _version
      originalEndDate
      patient {
        treatments {
          items {
            cycleInterval
            cycleIntervalPeriodType
          }
        }
      }
    }
  }
`

export const getGrinScanForGiResolve = /* GraphQL */ `
  query GetGrinScan($id: ID!) {
    getGrinScan(id: $id) {
      id
      _version
      patientId
      trackingDetails
    }
  }
`

export const automationRulesByDoctorId  = /* GraphQL */ `
  query AutomationRulesByDoctorId(
    $doctorId: ID
    $filter: ModelAutomationRuleFilterInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    automationRulesByDoctorId(
      doctorId: $doctorId
      filter: $filter
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        _version
        name
        triggerType
        conditions
        actions
        isEnabled
        isDeleted
        _deleted
      }
      nextToken
    }
  }
`

export const updateAutomationRule = /* GraphQL */ `
  mutation UpdateAutomationRule($input: UpdateAutomationRuleInput!, $condition: ModelAutomationRuleConditionInput) {
    updateAutomationRule(input: $input, condition: $condition) {
      id
      _version
      name
      triggerType
      conditions
      actions
      isEnabled
      isDeleted
      _deleted
    }
  }
`

export const getAutomationRule = /* GraphQL */ `
  query GetAutomationRule($id: ID!) {
    getAutomationRule(id: $id) {
      id
      _version
    }
  }
`

export const deleteAutomationRule = /* GraphQL */ `
  mutation DeleteAutomationRule($input: DeleteAutomationRuleInput!, $condition: ModelAutomationRuleConditionInput) {
    deleteAutomationRule(input: $input, condition: $condition) {
      id
    }
  }
`

export const createAutomationRule = /* GraphQL */ `
  mutation CreateAutomationRule($input: CreateAutomationRuleInput!, $condition: ModelAutomationRuleConditionInput) {
    createAutomationRule(input: $input, condition: $condition) {
      id
      _version
      name
      triggerType
      conditions
      actions
      isEnabled
      isDeleted
      _deleted
    }
  }
`

export const getMessageVersion = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      _version
    }
  }
`

export const getGrinScanForTimeline = /* GraphQL */ `
  query GetGrinScan($id: ID!) {
    getGrinScan(id: $id) {
      ${grinScanFull}   
    }
  }
`

export const searchPatientSearchModelsForPmsRedirect = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
      }
      nextToken
      total
    }
  }
`

export const getMessagingPreferencesVersion = /* GraphQL */ `
  query GetMessagingPreferences($id: ID!) {
    getMessagingPreferences(id: $id) {
      id
      _version
    }
  }
`

export const getGrinScanMetadata = /* GraphQL */ `
  query GetGrinScan($id: ID!) {
    getGrinScan(id: $id) {
      id
      _version
      metadata
    }
  }
`

export const initialScansByPatientId = /* GraphQL */ `
  query InitialScansByPatientId(
    $patientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInitialScanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    initialScansByPatientId(
      patientId: $patientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        oralImages {
          bucket
          region
          key
        }
        panoramics {
          bucket
          region
          key
        }
        stls {
          bucket
          region
          key
        }
        id
        patientId
        date
        comment
        scannerType
        createdAt
        a_doctor
        a_patient
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`

export const practiceGuidelinesByDoctorIdSorted = /* GraphQL */ `
  query PracticeGuidelinesByDoctorIdSorted(
    $doctorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPracticeGuidelinesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    practiceGuidelinesByDoctorIdSorted(
      doctorId: $doctorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        creatorDoctorId
        categories
      }
      nextToken
    }
  }
`

export const createPracticeGuidelines = /* GraphQL */ `
  mutation CreatePracticeGuidelines(
    $input: CreatePracticeGuidelinesInput!
    $condition: ModelPracticeGuidelinesConditionInput
  ) {
    createPracticeGuidelines(input: $input, condition: $condition) {
      id
      creatorDoctorId
      categories
      createdAt
    }
  }
`

const patientBriefObject = `
  id
  createdAt
  categories
  customTitle
  practiceNotes
  creatorDoctorId
  doctorId
  a_doctor
`

export const createPatientBrief = /* GraphQL */ `
  mutation CreatePatientBrief(
    $input: CreatePatientBriefInput!
  ) {
    createPatientBrief(input: $input) {
      ${patientBriefObject}
    }
  }
`
export const patientBriefsByPatientIdSorted = /* GraphQL */ `
  query PatientBriefsByPatientIdSorted(
    $patientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPatientBriefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientBriefsByPatientIdSorted(
      patientId: $patientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${patientBriefObject}   
      }
      nextToken
    }
  }
`


export const searchTagsForFetchDoctorTags = /* GraphQL */ `
  query SearchTags(
    $filter: SearchableTagFilterInput
    $sort: SearchableTagSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTags(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
      items {
        id
        value
        type
        count
        owner
        settings
        tagDoctorId
        a_readers
        a_writers
        createdAt
        updatedAt
        tagClass
        isDeleted
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
    }
  }
`

export const getDoctorWithFFs = /* GraphQL */ `
  query GetDoctor($id: ID!) {
    getDoctor(id: $id) {
        user {
          featureFlags {
            id
            _version
            flags
          }
      }
    }
  }
`

export const grinUserByUsernameVersionOnly = `query GrinUserByUsername(
  $username: String
  $sortDirection: ModelSortDirection
  $filter: ModelGrinUserFilterInput
  $limit: Int
  $nextToken: String
) {
  grinUserByUsername(
    username: $username
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      _version
    }
    nextToken
  }
}
`

export const updateGrinUserMinified = /* GraphQL */ `
  mutation UpdateGrinUser($input: UpdateGrinUserInput!, $condition: ModelGrinUserConditionInput) {
    updateGrinUser(input: $input, condition: $condition) {
      id
      _version
    }
  }
`