import * as React from 'react'

const Speaker = props => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.3829e-05 4.63398C-0.00600633 5.16323 0.320239 5.63954 0.815916 5.82512V3.55709C0.815916 3.52445 0.832218 3.47551 0.832218 3.44287C0.3332 3.62695 0.00130186 4.10204 8.3829e-05 4.63398Z"
        fill="black"
      />
      <path
        d="M4.45393 7.44043H2.31641L3.11594 10.3449C3.23433 10.7881 3.63616 11.0962 4.09495 11.0954C4.42139 11.0993 4.73023 10.9479 4.92712 10.6875C5.12591 10.4398 5.18722 10.1089 5.09027 9.80642L4.45393 7.44043Z"
        fill="black"
      />
      <path d="M0.832031 3.42646H0.848334V3.41016C0.848334 3.42646 0.848334 3.42646 0.832031 3.42646Z" fill="black" />
      <path
        d="M12.5309 3.198H12.4004V6.13505H12.5309C13.342 6.13505 13.9994 5.47757 13.9994 4.66654C13.9994 3.85549 13.342 3.198 12.5309 3.198Z"
        fill="black"
      />
      <path
        d="M1.30469 3.55706V5.87407C1.32233 6.47409 1.81398 6.95125 2.41422 6.951H5.38391V2.54541H2.41422C1.82682 2.54541 1.30469 2.96966 1.30469 3.55706Z"
        fill="black"
      />
      <path d="M5.875 7.01627L11.9123 9.43119V0L5.875 2.41492V7.01627Z" fill="black" />
    </svg>
  )
}

export default Speaker
