import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { logError } from 'utils/logUtils'
import { mapErrorPayload } from 'utils/mappers/logMappers'

export default ({ onPlay = () => {}, onPause = () => {}, onSeek = time => {} } = {}) => {
  const { linkEnabled } = useSelector(state => state.treatmentReducer.compareScans)
  let videos = useRef([])

  const onPlayDebounced = useDebouncedCallback(onPlay, 500)
  const onPauseDebounced = useDebouncedCallback(onPause, 500)
  const onSeekDebounced = useDebouncedCallback(onSeek, 500)

  const getVideoAnalytics = useCallback(
    videoRef => ({
      side: videoRef?.side,
      playerTime: videoRef?.currentTime,
      linkEnabled
    }),
    [linkEnabled]
  )

  const syncVideos = useCallback(
    (sourceVideo, callback) => {
      if (!linkEnabled) {
        return
      }

      try {
        videos.current.forEach(video => {
          if (video.tagName === 'VIDEO' && video !== sourceVideo) {
            callback(video)
          }
        })
      } catch (ex) {
        logError(`useScanLinkage - syncVideos failed`, mapErrorPayload(ex))
      }
    },
    [linkEnabled]
  )

  const resetAllVideos = useCallback(() => {
    syncVideos(null, video => {
      if (video.currentTime !== 0) {
        video.currentTime = 0
      }

      if (!video.paused) {
        video.pause()
      }
    })
  }, [syncVideos])

  const handlePlay = useCallback(
    videoRef => {
      onPlayDebounced(getVideoAnalytics(videoRef))
      syncVideos(videoRef, video => {
        if (video.paused) {
          video.play()
        }
      })
    },
    [syncVideos, onPlayDebounced, getVideoAnalytics]
  )

  const handlePause = useCallback(
    videoRef => {
      onPauseDebounced(getVideoAnalytics(videoRef))
      syncVideos(videoRef, video => {
        if (!video.paused) {
          video.pause()
        }
      })
    },
    [syncVideos, onPauseDebounced, getVideoAnalytics]
  )

  const handleSeek = useCallback(
    videoRef => {
      onSeekDebounced(getVideoAnalytics(videoRef))
      syncVideos(videoRef, video => {
        // Delay and error tolerance, preventing infinite loops
        if (Math.abs(videoRef.currentTime - video.currentTime) > 0.2) {
          video.currentTime = videoRef.currentTime
        }
      })
    },
    [syncVideos, onSeekDebounced, getVideoAnalytics]
  )

  const registerVideo = useCallback(
    videoRef => {
      if (videoRef.tagName !== 'VIDEO') {
        return
      }

      videos.current.push(videoRef)
      videoRef.onplaying = () => handlePlay(videoRef)
      videoRef.onpause = () => handlePause(videoRef)
      videoRef.onseeked = () => handleSeek(videoRef)

      if (linkEnabled) {
        resetAllVideos()
      }
    },
    [videos, handlePause, handlePlay, handleSeek, resetAllVideos, linkEnabled]
  )

  const unregisterVideo = useCallback(
    videoRef => {
      videos.current = videos.current.filter(v => v !== videoRef)
      videoRef.onplaying = null
      videoRef.onpause = null
      videoRef.onseeked = null
    },
    [videos]
  )

  useEffect(() => {
    if (linkEnabled) {
      resetAllVideos()
    }
  }, [linkEnabled]) // eslint-disable-line

  return {
    registerVideo,
    unregisterVideo,
    resetAllVideos
  }
}
