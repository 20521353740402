import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Actions from 'actions'
import useRolePermissions from 'hooks/useRolePermissions'
import { CONTENT_HEIGHT } from 'consts/patientCard'

import TreatmentForm from './TreatmentForm'

const useStyles = makeStyles({
  container: {
    height: CONTENT_HEIGHT,
    padding: '30px 50px 0 5%',
    position: 'relative',
    overflowY: 'auto'
  },
  loaderContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    zIndex: 999,
    backgroundColor: 'var(--bg-color-28)'
  }
})

const TreatmentPlanTab = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()

  const patientId = useSelector(state => state.patientsReducer.patientCard.patientId)
  const treatmentId = useSelector(state => state.patientsReducer.patientCard.patient?.treatments?.items[0].id)
  const preliminaryPlanProducts = useSelector(state => state.treatmentReducer.preliminaryPlanProducts.data)
  const { isLoading, data: treatmentPlan } = useSelector(state => state.patientsReducer.patientCard.treatmentPlan)

  const { data: prelimPlan } = useSelector(state => state.patientsReducer.patientCard.prelimPlan)

  const [treatmentProposalValues, setTreatmentProposalValues] = useState({
    tier: '',
    cost: '',
    length: '',
    periodType: 'month',
    vendor: '',
    additionalVendor: '',
    notes: ''
  })
  const [isTreatmentFormDirty, setIsTreatmentFormDirty] = useState(true)

  useEffect(() => {
    if (treatmentPlan) {
      setTreatmentProposalValues({ ...treatmentPlan })
    } else if (prelimPlan) {
      setTreatmentProposalValues({ ...prelimPlan })
    }
  }, [prelimPlan, treatmentPlan])

  useEffect(() => {
    dispatch(Actions.fetchPreliminaryPlanProducts())
  }, [dispatch])

  const saveTreatmentPlan = useCallback(
    e => {
      e.preventDefault()
      const action = Actions.saveTreatmentPlan
      dispatch(
        action({
          tier: treatmentProposalValues.tier,
          cost: treatmentProposalValues.cost,
          length: treatmentProposalValues.length,
          periodType: treatmentProposalValues.periodType,
          vendor: treatmentProposalValues.vendor,
          additionalVendor: treatmentProposalValues.additionalVendor,
          notes: treatmentProposalValues.notes,
          treatmentId,
          patientId
        })
      )
      setIsTreatmentFormDirty(false)
    },
    [dispatch, patientId, treatmentId, treatmentProposalValues]
  )

  const handleChangeTreatmentProposalValues = useCallback(
    values => {
      setTreatmentProposalValues(values)
      !isTreatmentFormDirty && setIsTreatmentFormDirty(true)
    },
    [isTreatmentFormDirty]
  )

  const isPrelimApproved = useMemo(
    () => prelimPlan?.status === 'approved' || prelimPlan?.status === 'purchased',
    [prelimPlan?.status]
  )

  const hasAccessToEdit = useMemo(() => permissions.submitPrelimPlan, [permissions.submitPrelimPlan])

  return (
    <>
      {isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress color="primary" />
        </div>
      )}
      <div className={classes.container}>
        <TreatmentForm
          values={treatmentProposalValues}
          preliminaryPlanProducts={preliminaryPlanProducts}
          onSubmitForm={saveTreatmentPlan}
          setValues={handleChangeTreatmentProposalValues}
          buttonLabel={t('dialogs.patientInfo.treatmentPlan.buttonLabel')}
          disableFields={!hasAccessToEdit}
          hasAccessToEdit={hasAccessToEdit}
          disableButton={
            isLoading ||
            !treatmentProposalValues.vendor ||
            (treatmentProposalValues.tier === 3 && !treatmentProposalValues.additionalVendor) ||
            !treatmentProposalValues.periodType ||
            !treatmentProposalValues.length ||
            !treatmentProposalValues.cost ||
            !treatmentProposalValues.tier ||
            !isTreatmentFormDirty ||
            !isPrelimApproved
          }
        />
      </div>
    </>
  )
}

export default TreatmentPlanTab
