import React from 'react'
import DazzedHeading16 from '../../common/text/DazzedHeading16'
import DazzedHeading24 from '../../common/text/DazzedHeading24'
import DazzedParagraph12 from '../../common/text/DazzedParagraph12'
import GrinChip from '../../common/GrinChip'
import moment from 'moment'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(isNewPricing => ({
  headerPlanContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: isNewPricing ? 'space-between' : 'default',
    marginBottom: isNewPricing ? 15 : 0
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

const SubscriptionHeaderPlan = ({ isTrial, grinPlan, isSubscription, renewalDate }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div className={classes.headerPlanContainer}>
      {!isTrial ? (
        <>
          <div className={classes.titleWrapper}>
            <DazzedHeading16>{grinPlan?.displayName}</DazzedHeading16>
            {!isSubscription && (
              <DazzedParagraph12 className={classes.capitalize}>
                &nbsp;&nbsp;({t(`grinPlans.period.${grinPlan.period}`)})
              </DazzedParagraph12>
            )}
          </div>
          <DazzedParagraph12>
            {t('pages.accountSettings.billing.automaticallyRenewedOn')}:&nbsp;&nbsp;
            <GrinChip text={moment(renewalDate).format('MMMM D, YYYY')} />
          </DazzedParagraph12>
        </>
      ) : (
        <>
          <DazzedHeading24>{t('pages.accountSettings.billing.trialTitle')}</DazzedHeading24>
        </>
      )}
    </div>
  )
}

export default SubscriptionHeaderPlan
