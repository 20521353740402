import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { daysAgo } from '../../../../utils/stringUtils'
import actions from '../../../../actions'
import { Trans, useTranslation } from 'react-i18next'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles({
  heading: {
    'text-wrap': 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'var(--text-color-60)!important',
    marginBottom: 10
  },
  sharedText: {
    display: 'inline',
    marginLeft: '0px',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 0,
    color: 'var(--text-color-11)',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
})

const ScanReferralItem = ({ item }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const doctor = useSelector(state => state.profileReducer.doctor)
  const currentPatientDoctor = useSelector(state => state.patientsReducer.patient?.doctor)

  const sentByLabel = useMemo(() => {
    if (doctor?.id === item.doctorId || (!item?.doctorId && doctor?.id === currentPatientDoctor?.id)) {
      return t('pages.patients.selectedPatient.timeline.referrals.you')
    } else {
      return item?.doctor?.name
    }
  }, [doctor?.id, item, currentPatientDoctor?.id, t])
  const sendDate = useMemo(() => daysAgo({ date: item?.createdAt, format: 'MMM Do' }), [item])
  const referralName = useMemo(() => JSON.parse(item.referralData || '{}')?.name || '', [item])

  const viewSharedScan = useCallback(() => {
    dispatch(
      actions.toggleViewSharedScanModal({
        isOpen: true,
        referralId: item.id,
        grinScanId: item.relatedEntityId
      })
    )
  }, [dispatch, item.id, item.relatedEntityId])

  return (
    <Tooltip
      value={t('pages.patients.selectedPatient.timeline.referrals.scanSharedTooltip', {
        sentBy: sentByLabel,
        date: sendDate,
        referralName
      })}
      placement="bottom"
      delay={1000}
    >
      <DazzedParagraph12 className={classes.heading}>
        <Trans
          i18nKey="pages.patients.selectedPatient.timeline.referrals.scanSharedButton"
          values={{
            sentBy: sentByLabel,
            date: sendDate,
            referralName
          }}
          components={{
            a: <span onClick={viewSharedScan} className={classes.sharedText} />
          }}
        />
      </DazzedParagraph12>
    </Tooltip>
  )
}

export default ScanReferralItem
