import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Collapse, Grid, CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useHistory } from 'react-router'
import LegalGuardianModalMobile from './LegalGuardianModal.mobile'
import useInvitePatientData from 'components/Profile/InvitePatientModal/useInvitePatientData'
import useHideHelpWidget from 'hooks/useHideHelpWidget'
import useCssClasses from 'hooks/useCssClasses'

import DazzedHeading24 from 'components/common/text/DazzedHeading24'

import FormInput from 'components/common/FormInput'
import SelectInput from 'components/common/SelectInput'
import PhoneNumberInput from 'components/common/PhoneNumberInput'
import LabeledInputContainer from 'components/common/inputs/LabeledInputContainer'
import SetNextScan from 'components/Patients/SetNextScan'
import FrequencyPicker from 'components/common/inputs/FrequencyPicker'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import AlignersSets from 'components/Patients/PatientCard/AlignersSets'
import { isValidEmail } from 'utils/generalUtils'
import { ROUTES } from 'consts'
import { ALIGNERS_TREATMENT_TYPE } from 'consts/treatmentConsts'
import { getPeriodTypeOptions } from 'utils/statusUtils'
import Actions from 'actions'
import GrinRadioGroup from 'components/common/GrinRadioGroup'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles({
  sectionHeader: {
    textAlign: 'center',
    margin: '10px 0'
  },
  legalGuardianIcon: {
    height: 17,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5
  },
  addLegalGuardianBtn: {
    margin: '10px 0'
  },
  container: {
    padding: '0 15px',
    overflowY: 'auto',
    height: '100%',
    position: 'relative'
  },
  divider: {
    height: 1,
    backgroundColor: '#E9E9E9',
    width: 'calc(100% + 30px)',
    margin: '15px 0 15px -15px'
  },
  input: {
    marginBottom: 5
  },
  firstScanDateinput: {
    marginBottom: 15
  },
  backButton: {
    marginTop: 15,
    marginBottom: 30
  },
  frequency: {
    height: 'auto',
    marginBottom: 30
  },
  alignersSets: {
    marginTop: 2,
    marginBottom: 12
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    zIndex: 500,
    background: 'var(--bg-color-5)'
  },
  isPatientUnder18Wrapper: {
    marginBottom: 10,
    marginTop: 20
  },
  isPatientUnder18: {
    fontWeight: '500'
  },
  isPatientUnder18Checkbox: {
    marginBottom: 20
  },
  required: {
    color: '#828999'
  }
})

const InvitePatientMobile = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  useHideHelpWidget()
  useCssClasses(true)

  const {
    values,
    formErrors,
    seats,
    isFetchingSeats,
    setShouldShowErrors,
    doctorId,
    scanFrequencyDefaults,
    treatmentTypeOptions,
    treatmentStatusOptions,
    scanFrequencyOptions,
    inviteNewPatient,
    validateInputs,
    clearFields,
    showUpgradePlanWarningModal
  } = useInvitePatientData()

  const { isLoading, mobilePopup } = useSelector(state => state.patientsReducer.invite)

  const [isUnder18ModalOpened, setIsUnder18ModalOpened] = useState(false)

  const patientUnder18CheckboxOptions = useMemo(
    () => [
      { label: t('general.yes'), value: true },
      { label: t('general.no'), value: false }
    ],
    [t]
  )

  const onTapPatientIsUnder18 = useCallback(
    value => {
      setIsUnder18ModalOpened(value === 'true')
      trackEvent(`invite patient - checked over 18`, { isMobile: true })
    },
    [setIsUnder18ModalOpened]
  )

  const isPrimaryDisabled = useMemo(
    () =>
      !values.firstName?.trim() ||
      !values.lastName?.trim() ||
      !values.email ||
      !isValidEmail(values.email) ||
      !values.treatmentType ||
      !values.treatmentStatus,
    [values.email, values.firstName, values.lastName, values.treatmentType, values.treatmentStatus]
  )

  const periodTypeOptions = useMemo(() => getPeriodTypeOptions(values?.treatmentStatus), [values])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!validateInputs()) {
        setShouldShowErrors(true)
        return
      }
      inviteNewPatient({ shouldOpenPatientBriefModal: false })
      history.push(ROUTES.PATIENTS)
    },
    [validateInputs, inviteNewPatient, setShouldShowErrors, history]
  )

  const handleClickBack = useCallback(() => {
    history.goBack()
  }, [history])

  useEffect(() => {
    if (doctorId) dispatch(Actions.fetchDoctorSeats())
  }, [dispatch, doctorId])

  useEffect(() => {
    if (mobilePopup?.type === 'success') {
      clearFields()
    }
  }, [clearFields, mobilePopup])

  useEffect(() => {
    if (doctorId && !isFetchingSeats && seats.left <= 0) {
      history.push(ROUTES.PATIENTS)
      showUpgradePlanWarningModal()
    }
  }, [showUpgradePlanWarningModal, doctorId, history, seats.left, isFetchingSeats])

  if (!doctorId) return null

  return (
    <>
      <div className={classes.container}>
        {isFetchingSeats && (
          <div className={classes.loaderContainer}>
            <CircularProgress size={24} />
          </div>
        )}
        <DazzedHeading24 className={classes.sectionHeader}>{t('dialogs.invitePatient.title')}</DazzedHeading24>
        <Grid className={classes.isPatientUnder18Wrapper} spacing={1} alignItems="center" container direction="row">
          <Grid item>
            <DazzedParagraph14 className={classes.isPatientUnder18}>
              {t('dialogs.invitePatient.isUnder18MobileLabel')}
            </DazzedParagraph14>
          </Grid>
          <Grid item>
            <DazzedParagraph12 className={classes.required}>
              {t('dialogs.invitePatient.requiredSubHeader')}
            </DazzedParagraph12>
          </Grid>
        </Grid>
        <GrinRadioGroup
          radioGroupClassName={classes.isPatientUnder18Checkbox}
          value={values.legalGuardianNeeded}
          setValue={onTapPatientIsUnder18}
          options={patientUnder18CheckboxOptions}
          size="small"
        />
        <FormInput
          title={t('dialogs.invitePatient.firstNameLabel')}
          style={{ bright: true, thick: true }}
          value={values.firstName}
          setValue={values.setFirstName}
          allowAutoComplete={false}
          errorMessage={formErrors?.firstName}
          containerClassName={classes.input}
        />
        <FormInput
          title={t('dialogs.invitePatient.lastNameLabel')}
          style={{ bright: true, thick: true }}
          value={values.lastName}
          setValue={values.setLastName}
          allowAutoComplete={false}
          errorMessage={formErrors?.lastName}
          containerClassName={classes.input}
        />
        <FormInput
          title={t('dialogs.invitePatient.contactEmailLabel')}
          style={{ bright: true, thick: true }}
          value={values.email}
          setValue={values.setEmail}
          allowAutoComplete={false}
          errorMessage={formErrors?.email}
          containerClassName={classes.input}
        />
        <PhoneNumberInput
          title={t('dialogs.invitePatient.phoneNumberLabel')}
          subtitle={t('dialogs.invitePatient.phoneNumberSubtitle')}
          setCountryCode={values.setCountryCode}
          setCountry={values.setPhoneCountry}
          setPhone={values.setPhone}
          country={values.phoneCountry}
          value={`${values.countryCode}${values.phone}`}
          style={{ bright: true, thick: true }}
          notes={t('dialogs.invitePatient.phoneNumberConsention')}
        />
        <Collapse in={values.legalGuardianNeeded}>
          <div className={classes.divider} />
          <FormInput
            title={t('dialogs.invitePatient.firstNameGuardianLabel')}
            style={{ bright: true, thick: true }}
            value={values.guardianFirstName}
            setValue={values.setGuardianFirstName}
            allowAutoComplete={false}
            errorMessage={formErrors?.guardianFirstName}
            containerClassName={classes.input}
          />
          <FormInput
            title={t('dialogs.invitePatient.lastNameGuardianLabel')}
            style={{ bright: true, thick: true }}
            value={values.guardianLastName}
            setValue={values.setGuardianLastName}
            allowAutoComplete={false}
            errorMessage={formErrors?.guardianLastName}
          />
        </Collapse>
        <LabeledInputContainer
          title={t('dialogs.invitePatient.treatmentTypeLabel')}
          tooltip={t('dialogs.invitePatient.treatmentTypeTooltip')}
          isMobile
        >
          <SelectInput
            options={treatmentTypeOptions}
            value={values.treatmentType}
            placeholder={t('dialogs.invitePatient.treatmentTypePlaceholder')}
            onChange={({ value }) => values.setTreatmentType(value)}
            style={{ bright: true, thick: true }}
            selectStyle={{ fontWeight: 500, paddingLeft: 0 }}
            keepErrorContainerWhenInactive={false}
            errorMessage={formErrors?.treatmentType}
            containerClassName={classes.input}
          />
        </LabeledInputContainer>
        {values.treatmentType === ALIGNERS_TREATMENT_TYPE && (
          <AlignersSets
            alignersSets={values.totalAlignersSets}
            setAlignersSets={values.setTotalAlignersSets}
            className={classes.alignersSets}
            errorMessage={formErrors?.totalAlignersSets}
          />
        )}
        <LabeledInputContainer
          title={t('dialogs.invitePatient.firstScanDate')}
          tooltip={t('dialogs.invitePatient.firstScanDateTooltip')}
          isMobile
        >
          <SetNextScan
            date={values.firstScanDate.toDate()}
            onSet={newDate => values.setFirstScanDate(newDate ? moment(newDate) : null)}
            containerClassName={classes.firstScanDateinput}
          />
        </LabeledInputContainer>
        <LabeledInputContainer
          title={t('dialogs.invitePatient.treatmentStatusLabel')}
          tooltip={t('dialogs.invitePatient.treatmentStatusTooltip')}
          isMobile
        >
          <SelectInput
            options={treatmentStatusOptions}
            value={values.treatmentStatus}
            onChange={({ value }) => {
              values.setTreatmentStatus(value)
            }}
            style={{ bright: true, thick: true }}
            selectStyle={{ fontWeight: 500, paddingLeft: 0 }}
            keepErrorContainerWhenInactive={false}
            errorMessage={formErrors?.treatmentStatus}
            containerClassName={classes.input}
          />
        </LabeledInputContainer>
        <LabeledInputContainer
          title={t('dialogs.invitePatient.scanFrequency')}
          tooltip={t('tooltips.cycleInterval', {
            periodType: t(`periodTypes.${values.cycleIntervalPeriodType}`)
          })}
          isMobile
          className={classes.frequency}
        >
          <FrequencyPicker
            value={values.cycleInterval}
            setValue={values.setCycleInterval}
            periodType={values.cycleIntervalPeriodType}
            defaultPeriodType={scanFrequencyDefaults?.periodType}
            setPeriodType={values.setCycleIntervalPeriodType}
            periodTypeOptions={periodTypeOptions}
            options={scanFrequencyOptions}
          />
        </LabeledInputContainer>

        <PrimaryButton
          label={t('dialogs.invitePatient.inviteBtn')}
          type="submit"
          width="100%"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={isPrimaryDisabled}
        />
        <SecondaryButton
          label={t('general.cancel')}
          width="100%"
          wrapperClassName={classes.backButton}
          onClick={handleClickBack}
        />
      </div>
      <LegalGuardianModalMobile isOpen={isUnder18ModalOpened} handleGotIt={() => setIsUnder18ModalOpened(false)} />
    </>
  )
}

export default InvitePatientMobile
