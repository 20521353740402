import React from 'react'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'

const Note = ({ note }) => {
  const { t } = useTranslation()

  return (
    <Grid container direction="column">
      <Grid item>
        <DazzedParagraph14 bold>{t(`dialogs.hiPatientGuidelinesViewer.items.note`)}</DazzedParagraph14>
      </Grid>
      <Grid item>
        <DazzedParagraph14>{note}</DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default Note
