import React, { useMemo } from 'react'
import { makeStyles, TableRow, TableCell } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedHeading36 from 'components/common/text/DazzedHeading36'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { WavingHand, Checkmark1, Search1 } from 'components/common/icons'

const useStyles = () =>
  makeStyles({
    tableCell: {
      border: 0
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      marginTop: 120,
      padding: '0 32px',
      alignItems: 'center',
      justifyContent: 'center'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    title: {
      color: 'var(--text-color-23)',
      marginBottom: 24
    },
    subtitle: {
      color: 'var(--text-color-24)'
    }
  })()

const DashboardEmptyState = ({ variant }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const icon = useMemo(() => {
    if (variant === 'noPatients') {
      return <WavingHand />
    } else if (variant === 'noResults') {
      return <Search1 />
    } else {
      return <Checkmark1 />
    }
  }, [variant])
  return (
    variant && (
      <TableRow>
        <TableCell colSpan={5} className={classes.tableCell}>
          <div className={classes.container}>
            <div className={classes.content}>
              {icon}
              <DazzedHeading36 className={classes.title}>{t(`pages.taskManager.${variant}.title`)}</DazzedHeading36>
              <DazzedParagraph16 className={classes.subtitle}>
                {t(`pages.taskManager.${variant}.subtitle`)}
              </DazzedParagraph16>
            </div>
          </div>
        </TableCell>
      </TableRow>
    )
  )
}

export default DashboardEmptyState
