import { AsyncStatus } from 'consts'
import Actions from '../actions'

const initialState = {
  grinScansList: {
    data: [],
    isLoading: false,
    nextTokne: null
  },
  scanTracking: {
    currentScanId: null,
    status: null
  },
  resolvedScans: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_TREATMENT_REQUIRED_GRIN_SCANS:
      return {
        ...state,
        grinScansList: {
          ...state.grinScansList,
          isLoading: true
        }
      }
    case Actions.FETCH_TRACKNG_REQUIRED_GRIN_SCANS_RECEIVED:
      return {
        ...state,
        grinScansList: {
          data: [...state.grinScansList.data, ...action.payload.items],
          isLoading: false,
          nextToken: action.payload.nextToken ? action.payload.nextToken : state.grinScansList.nextToken
        }
      }
    case Actions.FETCH_TRACKNG_REQUIRED_GRIN_SCANS_FAILED:
      return {
        ...state,
        grinScansList: {
          ...state.grinScansList,
          isLoading: false
        }
      }
    case Actions.SUBMIT_SCAN_TRACKING_DATA:
      return {
        ...state,
        scanTracking: {
          ...state.scanTracking,
          status: AsyncStatus.Loading,
          currentScanId: action.payload.scanId
        }
      }
    case Actions.SUBMIT_SCAN_TRACKING_DATA_FAILED:
      return {
        ...state,
        scanTracking: {
          ...state.scanTracking,
          status: AsyncStatus.Failed
        }
      }
    case Actions.SUBMIT_SCAN_TRACKING_DATA_RECEIVED:
      return {
        ...state,
        scanTracking: {
          ...state.scanTracking,
          status: AsyncStatus.Completed
        }
      }
    case Actions.PATIENT_RECEIVED:
      return {
        ...state,
        resolvedScans: initialState.resolvedScans
      }
    case Actions.GI_MARK_SCAN_AS_DONE:
      return {
        ...state,
        resolvedScans: {
          [action.payload.grinScanId]: AsyncStatus.Loading
        }
      }
    case Actions.GI_MARK_SCAN_AS_DONE_RECEIVED:
      return {
        ...state,
        resolvedScans: {
          ...state.resolvedScans,
          [action.payload.grinScanId]: AsyncStatus.Completed
        }
      }
    case Actions.GI_MARK_SCAN_AS_DONE_FAILED:
      return {
        ...state,
        resolvedScans: {
          ...state.resolvedScans,
          [action.payload.grinScanId]: AsyncStatus.Failed
        }
      }
    default:
      return state
  }
}
