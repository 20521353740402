import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../actions'
import BaseModal from '../common/modals/BaseModal'
import DazzedParagraph16 from '../common/text/DazzedParagraph16'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'utils/mobileUtils'
import { trackEvent } from 'utils/analyticsUtils'

export default () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.patientsReducer.delete.isModalOpen)
  const patient = useSelector(state => state.patientsReducer.delete.patient)
  const { t } = useTranslation()
  return (
    <BaseModal
      open={isOpen}
      handleClose={() => {
        trackEvent('Delete patient popup', { action: 'cancel' })
        dispatch(Actions.deletePatientCanceled())
      }}
      variant="alert"
      title={t('dialogs.deletePatient.title')}
      largerButtons={!isMobile()}
      primaryLabel={t('dialogs.deletePatient.no')}
      onPrimaryBtnClick={() => {
        trackEvent('Delete patient popup', { action: 'cancel' })
        dispatch(Actions.deletePatientCanceled())
      }}
      secondaryLabel={t('dialogs.deletePatient.yes')}
      onSecondaryBtnClick={() => {
        trackEvent('Delete patient popup', { action: 'delete' })
        dispatch(
          Actions.deletePatientRequested({
            patientId: patient.id,
            patientName: patient.details.name,
            isLead: patient.isLead
          })
        )
      }}
    >
      <DazzedParagraph16 textAlign="center">
        {t('dialogs.deletePatient.body', { name: patient?.details.name || '' })}?
      </DazzedParagraph16>
    </BaseModal>
  )
}
