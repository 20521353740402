const OPEN_RELEASE_NOTES_EDITOR = 'OPEN_RELEASE_NOTES_EDITOR'
const UPLOAD_RELEASE_NOTE_MEDIA = 'UPLOAD_RELEASE_NOTE_MEDIA'
const UPLOAD_RELEASE_NOTE_MEDIA_RECEIVED = 'UPLOAD_RELEASE_NOTE_MEDIA_RECEIVED'
const ADD_RELEASE_NOTES_SLIDE = 'ADD_RELEASE_NOTES_SLIDE'
const REMOVE_RELEASE_NOTES_SLIDE = 'REMOVE_RELEASE_NOTES_SLIDE'
const SET_RELEASE_NOTES_SLIDE_DATA = 'SET_RELEASE_NOTES_SLIDE_DATA'
const SET_RELEASE_NOTES_HEADLINE_DETAILS = 'SET_RELEASE_NOTES_HEADLINE_DETAILS'
const SAVE_RELEASE_NOTES = 'SAVE_RELEASE_NOTES'
const SAVE_RELEASE_NOTES_RECEIVED = 'SAVE_RELEASE_NOTES_RECEIVED'
const SAVE_RELEASE_NOTES_FAILED = 'SAVE_RELEASE_NOTES_FAILED'
const FETCH_ALL_RELEASE_NOTES = 'FETCH_ALL_RELEASE_NOTES'
const FETCH_ALL_RELEASE_NOTES_RECEIVED = 'FETCH_ALL_RELEASE_NOTES_RECEIVED'
const PUBLISH_RELEASE_NOTES = 'PUBLISH_RELEASE_NOTES'
const PUBLISH_RELEASE_NOTES_RECEIVED = 'PUBLISH_RELEASE_NOTES_RECEIVED'
const REMOVE_RELEASE_NOTES_SLIDE_MEDIA = 'REMOVE_RELEASE_NOTES_SLIDE_MEDIA'
const SET_RELEASE_NOTES_SLIDE_EDIT_MODE = 'SET_RELEASE_NOTES_SLIDE_EDIT_MODE'
const FETCH_PUBLISH_FILE = 'FETCH_PUBLISH_FILE'
const FETCH_PUBLISH_FILE_RECEIVED = 'FETCH_PUBLISH_FILE_RECEIVED'
const SET_MODAL_RELEASE_NOTES = 'SET_MODAL_RELEASE_NOTES'
const SET_RELEASE_NOTES_MODAL_VISIBILITY = 'SET_RELEASE_NOTES_MODAL_VISIBILITY'
const REVIEW_VERSION_RELEASE_NOTES = 'REVIEW_VERSION_RELEASE_NOTES'
const REVIEW_VERSION_RELEASE_NOTES_RECEIVED = 'REVIEW_VERSION_RELEASE_NOTES_RECEIVED'
const REQUEST_EDIT_RELEASE_NOTES = 'REQUEST_EDIT_RELEASE_NOTES'
const REQUEST_DELETE_RELEASE_NOTES = 'REQUEST_DELETE_RELEASE_NOTES'
const DELETE_RELEASE_NOTES_RECEIVED = 'DELETE_RELEASE_NOTES_RECEIVED'
const DELETE_RELEASE_NOTES_FAILED = 'DELETE_RELEASE_NOTES_FAILED'
const REQUEST_UNPUBLISH_RELEASE_NOTES = 'REQUEST_UNPUBLISH_RELEASE_NOTES'
const UNPUBLISH_RELEASE_NOTES_RECEIVED = 'UNPUBLISH_RELEASE_NOTES_RECEIVED'
const UNPUBLISH_RELEASE_NOTES_FAILED = 'UNPUBLISH_RELEASE_NOTES_FAILED'

const openReleaseNotesEditor = payload => ({
  type: OPEN_RELEASE_NOTES_EDITOR,
  payload
})

const uploadReleaseNoteMedia = payload => ({
  type: UPLOAD_RELEASE_NOTE_MEDIA,
  payload
})

const uploadReleaseNoteMediaReceived = payload => ({
  type: UPLOAD_RELEASE_NOTE_MEDIA_RECEIVED,
  payload
})

const addReleaseNotesSlide = payload => ({
  type: ADD_RELEASE_NOTES_SLIDE,
  payload
})

const removeReleaseNotesSlide = payload => ({
  type: REMOVE_RELEASE_NOTES_SLIDE,
  payload
})

const setReleaseNotesSlideData = payload => ({
  type: SET_RELEASE_NOTES_SLIDE_DATA,
  payload
})

const setReleaseNotesHeadlineDetails = payload => ({
  type: SET_RELEASE_NOTES_HEADLINE_DETAILS,
  payload
})

const saveReleaseNotes = payload => ({
  type: SAVE_RELEASE_NOTES,
  payload
})

const saveReleaseNotesReceived = payload => ({
  type: SAVE_RELEASE_NOTES_RECEIVED,
  payload
})

const saveReleaseNotesFailed = payload => ({
  type: SAVE_RELEASE_NOTES_FAILED,
  payload
})

const fetchAllReleaseNotes = () => ({
  type: FETCH_ALL_RELEASE_NOTES
})

const fetchAllReleaseNotesReceived = payload => ({
  type: FETCH_ALL_RELEASE_NOTES_RECEIVED,
  payload
})

const publishReleaseNotes = payload => ({
  type: PUBLISH_RELEASE_NOTES,
  payload
})

const publishReleaseNotesReceived = payload => ({
  type: PUBLISH_RELEASE_NOTES_RECEIVED,
  payload
})

const removeReleaseNotesSlideMedia = payload => ({
  type: REMOVE_RELEASE_NOTES_SLIDE_MEDIA,
  payload
})

const setReleaseNotesSlideEditMode = payload => ({
  type: SET_RELEASE_NOTES_SLIDE_EDIT_MODE,
  payload
})

const fetchPublishFile = payload => ({
  type: FETCH_PUBLISH_FILE,
  payload
})

const fetchPublishFileReceived = payload => ({
  type: FETCH_PUBLISH_FILE_RECEIVED,
  payload
})

const setModalReleaseNotes = payload => ({
  type: SET_MODAL_RELEASE_NOTES,
  payload
})

const setReleaseNotesModalVisibility = payload => ({
  type: SET_RELEASE_NOTES_MODAL_VISIBILITY,
  payload
})

const reviewVersionReleaseNotes = payload => ({
  type: REVIEW_VERSION_RELEASE_NOTES,
  payload
})

const reviewVersionReleaseNotesReceived = payload => ({
  type: REVIEW_VERSION_RELEASE_NOTES_RECEIVED,
  payload
})

const requestEditReleaseNotes = payload => ({
  type: REQUEST_EDIT_RELEASE_NOTES,
  payload
})

const requestDeleteReleaseNotes = payload => ({
  type: REQUEST_DELETE_RELEASE_NOTES,
  payload
})

const deleteReleaseNotesReceived = payload => ({
  type: DELETE_RELEASE_NOTES_RECEIVED,
  payload
})

const deleteReleaseNotesFailed = payload => ({
  type: DELETE_RELEASE_NOTES_FAILED,
  payload
})

const requestUnpublishReleaseNotes = payload => ({
  type: REQUEST_UNPUBLISH_RELEASE_NOTES,
  payload
})

const unpublishReleaseNotesReceived = payload => ({
  type: UNPUBLISH_RELEASE_NOTES_RECEIVED,
  payload
})

const unpublishReleaseNotesFailed = payload => ({
  type: UNPUBLISH_RELEASE_NOTES_FAILED,
  payload
})

export default {
  OPEN_RELEASE_NOTES_EDITOR,
  UPLOAD_RELEASE_NOTE_MEDIA,
  UPLOAD_RELEASE_NOTE_MEDIA_RECEIVED,
  ADD_RELEASE_NOTES_SLIDE,
  REMOVE_RELEASE_NOTES_SLIDE,
  SET_RELEASE_NOTES_SLIDE_DATA,
  SET_RELEASE_NOTES_HEADLINE_DETAILS,
  SAVE_RELEASE_NOTES,
  SAVE_RELEASE_NOTES_RECEIVED,
  SAVE_RELEASE_NOTES_FAILED,
  FETCH_ALL_RELEASE_NOTES,
  FETCH_ALL_RELEASE_NOTES_RECEIVED,
  PUBLISH_RELEASE_NOTES,
  PUBLISH_RELEASE_NOTES_RECEIVED,
  REMOVE_RELEASE_NOTES_SLIDE_MEDIA,
  SET_RELEASE_NOTES_SLIDE_EDIT_MODE,
  FETCH_PUBLISH_FILE,
  FETCH_PUBLISH_FILE_RECEIVED,
  SET_MODAL_RELEASE_NOTES,
  SET_RELEASE_NOTES_MODAL_VISIBILITY,
  REVIEW_VERSION_RELEASE_NOTES,
  REVIEW_VERSION_RELEASE_NOTES_RECEIVED,
  REQUEST_EDIT_RELEASE_NOTES,
  REQUEST_DELETE_RELEASE_NOTES,
  DELETE_RELEASE_NOTES_FAILED,
  DELETE_RELEASE_NOTES_RECEIVED,
  REQUEST_UNPUBLISH_RELEASE_NOTES,
  UNPUBLISH_RELEASE_NOTES_FAILED,
  UNPUBLISH_RELEASE_NOTES_RECEIVED,
  openReleaseNotesEditor,
  uploadReleaseNoteMedia,
  uploadReleaseNoteMediaReceived,
  addReleaseNotesSlide,
  removeReleaseNotesSlide,
  setReleaseNotesSlideData,
  setReleaseNotesHeadlineDetails,
  saveReleaseNotes,
  saveReleaseNotesReceived,
  saveReleaseNotesFailed,
  fetchAllReleaseNotes,
  fetchAllReleaseNotesReceived,
  publishReleaseNotes,
  publishReleaseNotesReceived,
  removeReleaseNotesSlideMedia,
  setReleaseNotesSlideEditMode,
  fetchPublishFile,
  fetchPublishFileReceived,
  setModalReleaseNotes,
  setReleaseNotesModalVisibility,
  reviewVersionReleaseNotes,
  reviewVersionReleaseNotesReceived,
  requestEditReleaseNotes,
  requestDeleteReleaseNotes,
  deleteReleaseNotesFailed,
  deleteReleaseNotesReceived,
  requestUnpublishReleaseNotes,
  unpublishReleaseNotesFailed,
  unpublishReleaseNotesReceived
}
