import * as React from 'react'

const JPG = ({ ...props }) => {
  return (
    <svg width="33" height="41" viewBox="0 0 33 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0251 34C10.8091 34 11.3131 33.576 11.3131 32.888V28.016H10.5611V32.768C10.5611 33.104 10.3131 33.344 9.95309 33.344H9.28909L9.38509 34H10.0251ZM14.8273 28.016H12.7793V34H13.5313V31.6H14.8273C16.2673 31.6 17.1233 30.936 17.1233 29.8C17.1233 28.68 16.2673 28.016 14.8273 28.016ZM14.8273 30.936H13.5313V28.68H14.8273C15.8113 28.68 16.3633 29.08 16.3633 29.8C16.3633 30.528 15.8113 30.936 14.8273 30.936ZM23.4332 31.16H21.2652V31.792H22.6412C22.5292 32.8 21.8412 33.408 20.8092 33.408C19.5532 33.408 18.6492 32.408 18.6492 31C18.6492 29.592 19.5452 28.6 20.7612 28.6C21.6172 28.6 22.2812 29.16 22.4732 29.968L23.2252 29.824C23.0012 28.672 22.0012 27.92 20.8012 27.92C19.1132 27.92 17.8892 29.208 17.8892 31C17.8892 32.8 19.1212 34.096 20.8092 34.096C22.4892 34.096 23.4892 32.952 23.4332 31.16Z"
        fill="#646464"
      />
      <path
        d="M1 4C1 2.34315 2.34315 1 4 1H21.8431C22.904 1 23.9214 1.42143 24.6716 2.17157L30.8284 8.32843C31.5786 9.07857 32 10.096 32 11.1569V37C32 38.6569 30.6569 40 29 40H4C2.34315 40 1 38.6569 1 37V4Z"
        stroke="#646464"
        strokeWidth="0.75"
      />
    </svg>
  )
}

export default JPG
