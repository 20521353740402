import Actions from 'actions'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default () => {
  const dispatch = useDispatch()

  const { patient } = useSelector(state => state.patientsReducer)
  const doctor = useSelector(state => state.profileReducer.doctor)

  const sendMessage = useCallback(
    ({ type, content }) => {
      dispatch(
        Actions.sendChatMessage({
          members: [patient.username, patient.doctor.username],
          roomId: patient.user?.rooms.items?.[0].roomId,
          type,
          content,
          patientUsername: patient.username,
          readBy: doctor.username,
          patientDoctorUsername: patient?.doctor?.username
        })
      )
    },
    [dispatch, doctor, patient]
  )

  return {
    sendMessage
  }
}
