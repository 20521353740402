import React from 'react'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'

import DazzedParagraph16 from '../../../common/text/DazzedParagraph16'

const useStyles = makeStyles(theme => ({
  timelineElem: {
    height: '61px',
    boxSizing: 'borderBox',
    display: 'flex',
    marginRight: '68px'
  },
  imageContainer: {
    width: '61px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '13px'
  },
  imageBackground: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    backgroundColor: ({ filled }) => (filled ? 'var(--bg-color-7)' : 'var(--bg-color-21)'),
    '&:after': {
      content: '""',
      width: '1px',
      height: '42px',
      backgroundColor: 'var(--border-color-7)',
      position: 'absolute',
      left: '50%',
      top: '100%',
      transform: 'translateX(-50%)',
      display: ({ lastItem }) => (lastItem ? 'none' : 'block')
    }
  },
  text: {
    flex: 1,
    borderBottom: '1px solid var(--border-color-10)',
    display: 'flex',
    alignItems: 'center'
  },
  milestoneName: {
    color: 'var(--text-color-7)',
    marginRight: '13px'
  },
  milestoneTimestamp: {
    color: 'var(--text-color-7)',
    opacity: '.7',
    fontSize: '12px',
    marginTop: '4px'
  }
}))

const TimelineItem = React.forwardRef(({ text, date, type, lastItem, filled, icon }, ref) => {
  const classes = useStyles({ lastItem, filled })

  return (
    <div className={classes.timelineElem} ref={ref}>
      <div className={classes.imageContainer}>
        <div className={classes.imageBackground}>
          <img src={icon} alt="action icon" />
        </div>
      </div>
      <div className={classes.text}>
        <DazzedParagraph16 className={classes.milestoneName}>{text}</DazzedParagraph16>
        {date && (
          <DazzedParagraph16 className={classes.milestoneTimestamp}>
            ({moment(date).format('D MMM YYYY h:mm A')})
          </DazzedParagraph16>
        )}
      </div>
    </div>
  )
})

export default TimelineItem
