import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 7,
    background: `var(--bg-color-58)`,
    width: 48,
    height: 48,
    cursor: ({ editable }) => (editable ? 'pointer' : 'default'),
    transition: 'background 0.2s ease',
    '&.selected': {
      background: `var(--bg-color-75)`
    },
    '&:hover': {
      background: ({ editable }) => (editable ? 'var(--bg-color-75)' : `var(--bg-color-58)`)
    }
  },
  toothLabel: {
    color: 'var(--text-color-56)',
    fontWeight: 500,
    '&.selected': {
      color: 'var(--text-color-1)!important'
    }
  }
}))

const GenericToothGridItem = ({
  tooth,
  isSelected = false,
  onClick = () => {},
  editable,
  customClasses = { root: '', toothLabel: '' }
}) => {
  const classes = useStyles({ editable })

  const handleClick = useCallback(() => {
    if (editable) {
      onClick()
    }
  }, [editable, onClick])

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={[classes.root, isSelected ? 'selected' : '', customClasses?.root || ''].join(' ')}
      onClick={handleClick}
    >
      <DazzedParagraph16
        className={[classes.toothLabel, isSelected ? 'selected' : '', customClasses?.toothLabel || ''].join(' ')}
      >
        {tooth}
      </DazzedParagraph16>
    </Grid>
  )
}

export default GenericToothGridItem
