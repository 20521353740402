import { Menu, MenuItem, Grid } from '@material-ui/core'
import React, { Fragment, useMemo, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import useBroadcast from 'hooks/useBroadcast'
import IconButton from 'components/common/buttons/IconButton'
import { createIdForTest } from 'utils/testingUtils.js'
import usePatientActionsMenu from './usePatientActionsMenu'

const useStyles = makeStyles({
  menu: {
    '& ul': {
      padding: 0
    }
  },
  menuItem: {
    height: 50,
    padding: '5px 6px 5px 0px',
    width: 200,
    '&:hover': {
      backgroundColor: 'var(--bg-color-14)'
    }
  },
  menuItemIcon: { paddingTop: 4, paddingRight: 4, width: 40, textAlign: 'center' },
  menuButton: {
    margin: 0,
    marginRight: 5
  }
})

export default ({
  patient,
  iconComponent,
  iconSize,
  onClose,
  roomId,
  isLoading = false,
  status,
  buttonClassName,
  isSupportDashboard = false,
  tooltipValue = '',
  isDeleted = false,
  isLead = false,
  hasUnreadMessages
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)
  const { closeBroadcastMode } = useBroadcast()

  const isDisabled = useMemo(() => isLoading || isDeleted, [isDeleted, isLoading])

  const { actions } = usePatientActionsMenu({
    patient,
    roomId,
    status,
    isLead,
    hasUnreadMessages,
    isSupportDashboard
  })

  const handleOpen = useCallback(
    event => {
      event.stopPropagation()
      event.preventDefault()
      setAnchorEl(event.currentTarget)
      closeBroadcastMode('Patient actions clicked')
    },
    [closeBroadcastMode]
  )

  const handleClose = useCallback(
    event => {
      event.stopPropagation()
      event.preventDefault()
      setAnchorEl(null)

      if (!!onClose) {
        onClose()
      }
    },
    [onClose]
  )

  const handleActionSelection = useCallback(
    (event, action) => {
      action.onClick()
      handleClose(event)
    },
    [handleClose]
  )

  return actions.length > 0 ? (
    <Fragment>
      <IconButton
        arrow
        tooltipValue={tooltipValue}
        disabled={isDisabled}
        onClick={handleOpen}
        size={iconSize}
        className={[classes.menuButton, buttonClassName].join('')}
      >
        {iconComponent}
      </IconButton>
      <Menu
        transformOrigin={{ vertical: -40, horizontal: 'left' }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        className={classes.menu}
      >
        {actions.map((action, index) => (
          <MenuItem
            id={createIdForTest({ componentId: 'patient-quick-actions', title: action.title })}
            key={action.title}
            onClick={event => handleActionSelection(event, action)}
            className={classes.menuItem}
          >
            <Grid container alignItems="center" style={{ height: 50 }}>
              <Grid item className={classes.menuItemIcon}>
                {action.icon}
              </Grid>
              <Grid item>{action.title}</Grid>
            </Grid>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  ) : null
}
