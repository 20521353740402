import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import OrderGrinKitInfo from './OrderGrinKitInfo'
import images from '../../../resources'

const useStyles = makeStyles({
  body: {
    display: 'flex',
    flexDirection: 'row',
    height: 468
  }
})

const OrderGrinKitsComparison = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const scopes = [
    {
      title: t('dialogs.orderScopes.grinScope'),
      subTitle: t('dialogs.orderScopes.grinScopeDescription'),
      scopeImage: images.grinScopeInfo,
      arrowImage: images.grinScopeWidthArrow,
      width: `71.1 ${t('dialogs.orderScopes.grinScopeWidthUnit')}`,
      description: t('dialogs.orderScopes.grinScopeDescription2')
    },
    {
      title: t('dialogs.orderScopes.grinScopeMini'),
      subTitle: t('dialogs.orderScopes.grinScopeMiniDescription'),
      scopeImage: images.grinScopeMiniInfo,
      arrowImage: images.grinScopeMiniWidthArrow,
      width: `60 ${t('dialogs.orderScopes.grinScopeWidthUnit')}`,
      description: t('dialogs.orderScopes.grinScopeMiniDescription2')
    }
  ]

  return (
    <div className={classes.body}>
      {scopes.map(item => (
        <OrderGrinKitInfo key={item.title} {...item} />
      ))}
    </div>
  )
}

export default OrderGrinKitsComparison
