import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import actions from '../../../actions'
import PrimaryButton from '../../common/buttons/PrimaryButton'
import DazzedHeading16 from '../../common/text/DazzedHeading16'
import OggHeading40 from '../../common/text/OggHeading40'
import images from '../../../resources'

const useStyles = () =>
  makeStyles({
    preliminaryPlanBubble: {
      position: 'relative',
      textAlign: 'center',
      backgroundColor: 'var(--bg-color-10)',
      backgroundImage: 'url(' + images.confetti + ')',
      backgroundPosition: 'center 80px',
      backgroundRepeat: 'no-repeat',
      border: '1px solid rgba(0, 19, 53, 0.1)',
      borderRadius: 16,
      width: 330,
      height: 260,
      maxWidth: '90%',
      paddingTop: 20
    },
    preliminaryPlanTitle: {
      position: 'relative',
      height: 20,
      marginBottom: 45
    },
    preliminaryPlanTitleText: {
      position: 'absolute',
      top: 7,
      left: 42,
      fontSize: 12
    },
    trapezoidDown: {
      position: 'absolute',
      left: 30,
      width: '80%',
      height: 20,
      background: 'var(--bg-color-13)',
      transform: 'perspective(4px) rotateX(-1deg)'
    },
    trapezoidUp: {
      position: 'absolute',
      top: 19,
      left: 30,
      width: '80%',
      height: 20,
      background: 'var(--bg-color-13)',
      transform: 'perspective(4px) rotateX(1deg)'
    },
    preliminaryPlanHeader: {
      marginBottom: 30,
      marginLeft: 50,
      marginRight: 50,
      fontSize: 25,
      fontWeight: 400,
      lineHeight: '35px',
      color: 'var(--text-color-7)'
    }
  })()

const PreliminaryPlanMessage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleOnClick = useCallback(() => {
    dispatch(actions.openPreliminaryPlanDialog())
  }, [dispatch])

  return (
    <div className={classes.preliminaryPlanBubble}>
      <div className={classes.preliminaryPlanTitle}>
        <div className={classes.trapezoidDown} />
        <div className={classes.trapezoidUp} />
        <DazzedHeading16 className={classes.preliminaryPlanTitleText}>
          {t('dialogs.patientInfo.prelimPlan.message.title')}
        </DazzedHeading16>
      </div>

      <OggHeading40 className={classes.preliminaryPlanHeader}>
        {t('dialogs.patientInfo.prelimPlan.message.header')}
      </OggHeading40>
      <PrimaryButton
        label={t('dialogs.patientInfo.prelimPlan.message.actionButton')}
        width={140}
        onClick={handleOnClick}
      />
    </div>
  )
}

export default PreliminaryPlanMessage
