import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import GrinChip from 'components/common/GrinChip'
import { Grid } from '@material-ui/core'
import { TrackingConditionsTypes } from 'consts/giConsts'

const GiConditionList = ({ conditions = {} }) => {
  const { t } = useTranslation()

  const tagLabels = useMemo(
    () =>
      Object.keys(conditions)
        .filter(conditionType => conditionType !== TrackingConditionsTypes.Other)
        .map(condition => {
          const selections = conditions[condition].selections
          const conditionLabel = t(`dialogs.scanSummary.treatmentTracking.conditions.${condition}`)

          if (!selections?.length) {
            return conditionLabel
          }

          return `${conditionLabel} (${selections.join(', ')})`
        }),
    [t, conditions]
  )

  return tagLabels.length > 0 ? (
    <Grid container spacing={1}>
      {tagLabels.map((text, i) => (
        <Grid item key={`gi-condition-${i}`}>
          <GrinChip text={text} />
        </Grid>
      ))}
    </Grid>
  ) : (
    <></>
  )
}

export default GiConditionList
