import React, { useEffect, useState } from 'react'
import Input from 'react-verification-code-input'
import { AsyncStatus } from 'consts'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from './text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  input: {
    '& input': {
      fontFamily: 'Dazzed!important'
    }
  },
  codeError: {
    padding: '0 40px',
    color: 'var(--text-color-17)'
  }
}))

const VerificationCodeInput = ({ onVerifyCode, verifyLoadingState, resendLoadingState, verificationCodeError }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [shouldResetVerificationCodeField, setShouldResetVerificationCodeField] = useState(false)

  useEffect(() => {
    if (verificationCodeError) {
      setShouldResetVerificationCodeField(true)
    }
  }, [verificationCodeError])

  useEffect(() => {
    if (verificationCodeError && shouldResetVerificationCodeField) {
      setShouldResetVerificationCodeField(false)
    }
  }, [shouldResetVerificationCodeField, verificationCodeError])

  return (
    !shouldResetVerificationCodeField && (
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Input
            autoFocus
            fields={6}
            className={classes.input}
            onComplete={onVerifyCode}
            loading={verifyLoadingState === AsyncStatus.Loading}
            disabled={resendLoadingState === AsyncStatus.Loading}
          />
        </Grid>
        {verificationCodeError && (
          <Grid item>
            <DazzedParagraph14 strong className={classes.codeError} textAlign="center">
              {t('pages.referrals.login.invalidCode')}
            </DazzedParagraph14>
          </Grid>
        )}
      </Grid>
    )
  )
}
export default VerificationCodeInput
