import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import ScanViewTiles from 'components/common/ScanView/ScanViewTiles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getObjectUrl } from 'utils/mediaUtils'
import { trackEvent } from 'utils/analyticsUtils'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  selectPose: {
    color: 'var(--text-color-46)'
  }
}))

const BeforeAfterPoseSelection = ({ scanId, onPoseSelected }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const lastScan = useSelector(state =>
    state.patientsReducer.patient?.grinScans?.items?.find(scan => scan.id === scanId)
  )

  const [selectedPoseIndex, setSelectedPoseIndex] = useState(0)

  const scanSummaryData = useMemo(() => JSON.parse(lastScan?.scanSummaryData || '{}'), [lastScan])
  const options = useMemo(() => {
    const scanSummaryImages = scanSummaryData?.normalImages || []
    return [
      ...Object.keys(scanSummaryImages).map(pose => ({
        type: 'img',
        src: getObjectUrl(scanSummaryImages[pose]),
        pose
      }))
    ]
  }, [scanSummaryData?.normalImages])

  const handlePoseSelected = useCallback(
    newPoseIndex => {
      setSelectedPoseIndex(newPoseIndex)
      const pose = Object.keys(scanSummaryData?.normalImages || [])[newPoseIndex]
      trackEvent('Before after - Pose selected', {
        pose,
        scanId
      })
      onPoseSelected(pose)
    },
    [onPoseSelected, scanSummaryData?.normalImages, scanId]
  )

  return (
    <Grid container direction="column" alignItems="center" spacing={1}>
      <Grid item>
        <DazzedParagraph14 strong className={classes.selectPose}>
          {t('dialogs.beforeAfter.selectPose')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <ScanViewTiles
          direction="column"
          xs={12}
          selectedOptionIndex={selectedPoseIndex}
          options={options}
          onOptionSelect={handlePoseSelected}
        />
      </Grid>
    </Grid>
  )
}

export default BeforeAfterPoseSelection
