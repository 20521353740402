import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph16 from './text/DazzedParagraph16'
import { CircularProgress, IconButton } from '@material-ui/core'
import { CancelRounded } from '@material-ui/icons'
import { divideByThousands } from 'utils/stringUtils'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles(() => ({
  root: {
    padding: '4px 22px',
    backgroundColor: 'var(--bg-color-15)',
    position: 'relative'
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12
  },
  overlayLoader: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,.6)',
    zIndex: 20
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 0',
    position: 'relative'
  },
  calculationItemDivider: {
    marginTop: 4,
    marginBottom: 4,
    height: 1,
    backgroundColor: 'var(--text-color-12)'
  },
  text: {
    fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: 500
  },
  totalDue: {
    fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: isMobile() ? 600 : 500
  },
  coupon: {
    paddingTop: 20,
    paddingBottom: 20
  },
  clearIconContainer: {
    position: 'absolute',
    left: '100%',
    top: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 1
  },
  clearIcon: {
    fontSize: '0.9rem'
  }
}))

const OrderItemsList = ({
  couponFieldWidth = null,
  items = [],
  isLoading = false,
  onResetCoupon = () => null,
  hasCoupon = false,
  className = ''
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  // const { coupon } = useSelector(state => state.billingReducer.order.coupon)
  // const shouldDisplayCoupons = useMemo(() => coupon.discountAmount === null, [coupon])
  const total = useMemo(() => items.reduce((acc, item) => acc + (item.isCoupon ? -item.price : item.price), 0), [items])

  return (
    <div className={[classes.root, className].join(' ')}>
      {isLoading && !hasCoupon ? (
        <div className={classes.loaderContainer}>
          <CircularProgress size={24} />
        </div>
      ) : (
        <>
          {isLoading && hasCoupon && (
            <div className={classes.overlayLoader}>
              <CircularProgress size={24} />
            </div>
          )}
          {items.map(({ description, price, isCoupon }) => (
            <div className={classes.item} key={description}>
              <DazzedParagraph16 className={classes.text} strong>
                {description}
              </DazzedParagraph16>
              <DazzedParagraph16 className={classes.text} strong>
                {price < 0 || isCoupon ? '-' : ''}${divideByThousands(Math.abs(price))}&nbsp;USD
              </DazzedParagraph16>
              {isCoupon && (
                <div className={classes.clearIconContainer}>
                  <IconButton onClick={onResetCoupon} size="small">
                    <CancelRounded fontSize="small" classes={{ fontSizeSmall: classes.clearIcon }} />
                  </IconButton>
                </div>
              )}
            </div>
          ))}
          <div className={classes.calculationItemDivider} />
          <div className={classes.item}>
            <DazzedParagraph16 className={classes.totalDue}>{t('dialogs.upgradePlan.totalDue')}</DazzedParagraph16>
            <DazzedParagraph16 className={classes.totalDue}>${divideByThousands(total)}&nbsp;USD</DazzedParagraph16>
          </div>
          {/* Shtivi: disabled since the new billing tiers (subscription-v2)
           {shouldDisplayCoupons && (
            <div className={classes.coupon}>
              <CouponField containerWidth={couponFieldWidth} />
            </div>
          )} */}
        </>
      )}
    </div>
  )
}

export default OrderItemsList
