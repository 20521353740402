import React, { useCallback } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Chevron2 } from 'components/common/icons'

const useStyles = ({ isExpanded }) =>
  makeStyles({
    container: {
      marginBottom: 20
    },
    chevron: {
      transform: isExpanded ? 'rotate(90deg)' : 'rotate(-90deg)',
      marginLeft: 10,
      marginTop: 5
    }
  })({ isExpanded })

const ShowExplanation = ({ isExpanded, setIsExpanded }) => {
  const classes = useStyles({ isExpanded })
  const { t } = useTranslation()

  const handleChangeState = useCallback(() => setIsExpanded(!isExpanded), [isExpanded, setIsExpanded])

  return (
    <Grid container onClick={handleChangeState} alignItems="center" className={classes.container}>
      <Grid item>
        <DazzedParagraph14 color="var(--text-color-3)" strong>
          {isExpanded
            ? t('pages.patients.selectedPatient.chat.comparisonModal.hideExplanation')
            : t('pages.patients.selectedPatient.chat.comparisonModal.showExplanation')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <Chevron2 className={classes.chevron} color="var(--text-color-3)" />
      </Grid>
    </Grid>
  )
}

export default ShowExplanation
