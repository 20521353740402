import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { IconButton, Grid } from '@material-ui/core'
import { ChromePicker } from 'react-color'
import { useCallback } from 'react'
import PickerOptions from './PickerOptions'
import AddColorButton from '../icons/AddColorButton'

const useStyles = makeStyles(() => ({
  picker: {
    position: 'absolute',
    bottom: '16px',
    right: '98px'
  },
  container: {
    position: 'relative'
  },
  arrowDown: {
    overflow: 'hidden',
    marginRight: '15px',
    width: '0',
    height: '0',
    boxSizing: 'border-box',
    border: '1em solid black',
    borderColor: 'transparent transparent #fff #fff',
    transformOrigin: '0 0',
    transform: 'rotate(-45deg)',
    content: '""',
    zIndex: 2,
    boxShadow: '-3px 3px 3px 0 rgb(0 0 0 / 30%);'
  },
  addColorButton: {
    padding: 5
  }
}))

const RCColorPicker = ({
  suggestedColors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5'],
  selectedColor = '#3f51b5',
  handleColor = () => {},
  handleOptions = () => {}
}) => {
  const [showPicker, setShowPicker] = useState(false)
  const pickerRef = useRef(null)
  const buttonRef = useRef(null)
  const [options, setOptions] = useState(suggestedColors)

  const classes = useStyles()

  const handlePickerClick = useCallback(
    e => {
      if (!showPicker && buttonRef.current.contains(e.target)) {
        setShowPicker(true)
      } else if (pickerRef.current && !pickerRef.current.contains(e.target)) {
        setShowPicker(false)
        setOptions(prevOptions => {
          if (!prevOptions.some(currColor => currColor === selectedColor)) {
            prevOptions.reverse().pop()
            prevOptions = [...prevOptions.reverse(), selectedColor]
          }
          handleOptions(prevOptions)
          return prevOptions
        })
      }
    },
    [handleOptions, selectedColor, showPicker]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handlePickerClick)

    return () => {
      document.removeEventListener('mousedown', handlePickerClick)
    }
  }, [handlePickerClick])

  const handleColorChanged = useCallback(
    color => {
      handleColor(color)
    },
    [handleColor]
  )

  return (
    <Grid container alignItems="center">
      <Grid item>
        <PickerOptions options={options} onClick={handleColorChanged} color={selectedColor} />
      </Grid>
      <Grid item ref={buttonRef} className={classes.container}>
        {showPicker && (
          <Grid container alignItems="center" direction="column" className={classes.picker} ref={pickerRef}>
            <Grid item>
              <ChromePicker color={selectedColor} onChange={color => handleColorChanged(color.hex)} />
            </Grid>
            <div className={classes.arrowDown}></div>
          </Grid>
        )}
        <IconButton className={classes.addColorButton}>
          <AddColorButton />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default RCColorPicker
