import * as React from 'react'

export default () => {
  return (
    <svg width="79" height="28" viewBox="0 0 79 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="68" cy="14" r="9.5" fill="white" stroke="white" strokeWidth="3" />
      <circle cx="68" cy="14" r="8" stroke="#4151E6" />
      <path
        d="M66.9656 16.1577V16.0637C66.9722 15.4499 67.0333 14.9614 67.1488 14.5984C67.2676 14.2354 67.4359 13.9417 67.6537 13.7173C67.8715 13.4929 68.1339 13.2883 68.4408 13.1035C68.6388 12.9781 68.817 12.8378 68.9754 12.6827C69.1338 12.5276 69.2592 12.3494 69.3516 12.1481C69.444 11.9468 69.4902 11.7241 69.4902 11.4798C69.4902 11.1861 69.4209 10.932 69.2823 10.7175C69.1437 10.503 68.9589 10.338 68.7279 10.2225C68.5002 10.1037 68.2461 10.0443 67.9656 10.0443C67.7115 10.0443 67.4689 10.0971 67.2379 10.2027C67.0069 10.3083 66.8155 10.4733 66.6637 10.6977C66.5119 10.9188 66.4244 11.2043 66.4013 11.5541H64.8965C64.9196 10.9601 65.0697 10.4585 65.3469 10.0493C65.6242 9.63675 65.9905 9.32489 66.4459 9.11368C66.9046 8.90248 67.4112 8.79688 67.9656 8.79688C68.5728 8.79688 69.1041 8.91073 69.5595 9.13843C70.0149 9.36284 70.368 9.678 70.6188 10.0839C70.8729 10.4865 71 10.9568 71 11.4947C71 11.8643 70.9422 12.1976 70.8267 12.4946C70.7112 12.7883 70.5462 13.0507 70.3317 13.2817C70.1205 13.5127 69.8664 13.7173 69.5694 13.8955C69.2889 14.0704 69.0612 14.2519 68.8863 14.44C68.7147 14.6281 68.5893 14.8509 68.5101 15.1083C68.4309 15.3657 68.388 15.6842 68.3814 16.0637V16.1577H66.9656ZM67.7131 19.1674C67.4425 19.1674 67.2098 19.0717 67.0151 18.8803C66.8204 18.6856 66.7231 18.4513 66.7231 18.1774C66.7231 17.9068 66.8204 17.6758 67.0151 17.4844C67.2098 17.2897 67.4425 17.1923 67.7131 17.1923C67.9804 17.1923 68.2114 17.2897 68.4061 17.4844C68.6041 17.6758 68.7031 17.9068 68.7031 18.1774C68.7031 18.3589 68.6569 18.5255 68.5645 18.6773C68.4754 18.8258 68.3566 18.9446 68.2081 19.0338C68.0596 19.1229 67.8946 19.1674 67.7131 19.1674Z"
        fill="#4151E6"
      />
      <path
        d="M14 1C6.8203 1 1 6.8203 1 14C1 21.1797 6.8203 27 14 27H41C48.1797 27 54 21.1797 54 14C54 6.8203 48.1797 1 41 1H14Z"
        fill="#041333"
        stroke="white"
        strokeWidth="2"
      />
      <circle cx="41" cy="14" r="10" fill="#26C36F" />
      <path d="M35.668 13.6663L39.3346 17.333L46.3346 10.333" stroke="white" strokeWidth="2" />
      <path
        d="M20.585 12.2242C21.7461 11.984 22.8934 11.6788 24.0217 11.3102C23.3473 6.05788 17.7858 6 17.7858 6C15.4966 6 13.6156 6.79224 12.1428 8.37671C10.7198 9.91723 10.0083 11.8149 10.0083 14.0698C10.0083 16.3028 10.6942 18.1598 12.0661 19.6409C13.5392 21.2256 15.5069 22.018 17.969 22.018C19.3263 22.018 20.5537 21.8632 21.6512 21.5537C22.7488 21.2442 23.5431 20.9347 24.0341 20.6252L24.0414 13.7789C24.0417 13.7466 24.0345 13.7148 24.0205 13.6859C24.0066 13.6569 23.9861 13.6318 23.9609 13.6124C23.9356 13.5931 23.9062 13.58 23.8751 13.5744C23.844 13.5687 23.8121 13.5706 23.7818 13.5798C22.9055 13.8584 20.7388 14.4629 18.3265 14.8445V16.2432C19.1422 16.2584 19.6837 16.3322 19.951 16.4645C20.3625 16.6783 20.75 17.1277 20.75 17.813V18.4769C20.7512 18.6875 20.711 18.8962 20.6316 19.0907C20.5523 19.2851 20.4355 19.4613 20.2882 19.6088C19.9191 19.9727 19.3278 20.2169 18.382 20.2169C16.6344 20.2169 15.1949 19.4063 14.6715 18.1813C14.0432 16.7114 14.1236 15.9027 14.1236 14.0147C14.1236 11.7154 14.1394 10.9422 14.8735 9.71936C15.4676 8.72893 16.625 8.26745 17.6239 8.29748C17.6239 8.29748 19.329 8.14458 20.1187 9.69583C20.6824 10.8057 20.585 12.2242 20.585 12.2242Z"
        fill="white"
      />
    </svg>
  )
}
