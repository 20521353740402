import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../../actions'
import BaseModal from '../../common/modals/BaseModal'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import { makeStyles } from '@material-ui/styles'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  spacer: {
    height: 24
  }
}))

const RequestCancelSubscriptionModal = ({ isOpen, onClose, userPlanKey }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isLoading = useSelector(state => state.billingReducer.isSubmittingPlanRequest)

  const [requestSubmitted, setRequestSubmitted] = useState(false)

  const handleSend = useCallback(() => {
    trackEvent('Subscription - cancel sent', { userPlanKey })
    setRequestSubmitted(true)
    dispatch(Actions.submitPlanCancellationRequest())
  }, [userPlanKey, dispatch])

  useEffect(() => {
    if (requestSubmitted && !isLoading) {
      onClose()
    }
  }, [isLoading, requestSubmitted, onClose])

  useEffect(() => {
    if (!isOpen) {
      setRequestSubmitted(false)
    }
  }, [isOpen])

  return (
    <BaseModal
      open={isOpen}
      variant="alert"
      title={t('pages.accountSettings.billing.requestCancelSubscriptionTitle')}
      primaryLabel={t('general.continue')}
      onPrimaryBtnClick={handleSend}
      secondaryLabel={t('general.close')}
      onSecondaryBtnClick={onClose}
      handleClose={onClose}
      isLoading={isLoading}
      largerButtons
    >
      <DazzedParagraph16 textAlign="center">
        {t('pages.accountSettings.billing.requestCancelSubscriptionDescription1')}
        <div className={classes.spacer} />
        {t('pages.accountSettings.billing.requestCancelSubscriptionDescription2')}
      </DazzedParagraph16>
    </BaseModal>
  )
}

export default RequestCancelSubscriptionModal
