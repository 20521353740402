import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid, InputBase } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import BeforeAfterFeedbackRating from './BeforeAfterFeedbackRating'
import { useCallback } from 'react'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'var(--bg-color-48)',
    borderRadius: 10,
    padding: 16,
    marginTop: 16
  },
  freeTextInput: {
    fontWeight: 500,
    fontSize: 12,
    color: 'var(--text-color-45)',
    borderBottom: '1px solid var(--border-color-27)',
    fontFamily: 'Dazzed',
    width: '100%'
  },
  submitButton: {
    marginTop: 10,
    textDecoration: 'underline',
    color: 'var(--text-color-25)',
    '& span': {
      cursor: 'pointer',
      '&.disabled': {
        cursor: 'default',
        color: 'var(--text-color-14)'
      }
    }
  },
  feedbackSubmittedMsg: {
    color: 'var(--text-color-29)'
  }
}))

const BeforeAfterFeedbackBody = ({ analyticsMetadata = {}, onSubmit = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [rating, setRating] = useState(0)
  const [freeText, setFreeText] = useState('')
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false)
  const isSubmitDisabled = useMemo(() => rating < 1, [rating])

  const handleRatingSelected = useCallback(
    rating => {
      setRating(rating)
      trackEvent('Before After - feedback - rating selected', {
        rating: rating === 0 ? 'cleared' : rating,
        ...analyticsMetadata
      })
    },
    [analyticsMetadata]
  )

  const handleSubmit = useCallback(() => {
    if (isSubmitDisabled) {
      return
    }

    trackEvent('Before After - feedback - submit', {
      rating,
      freeText
    })
    onSubmit({
      rating,
      freeText
    })
    setIsFeedbackSubmitted(true)
  }, [onSubmit, rating, isSubmitDisabled, freeText])

  return (
    <Grid container className={classes.root} spacing={1}>
      {isFeedbackSubmitted ? (
        <DazzedParagraph14 bold className={classes.feedbackSubmittedMsg}>
          <div>{t('dialogs.beforeAfter.feedback.feedbackSubmitted')}</div>
          <div>{t('dialogs.beforeAfter.feedback.thankYou')}</div>
        </DazzedParagraph14>
      ) : (
        <>
          <Grid item xs={12}>
            <DazzedParagraph14 strong>{t('dialogs.beforeAfter.feedback.feedbackQuestion')}</DazzedParagraph14>
          </Grid>
          <Grid item xs={12}>
            <BeforeAfterFeedbackRating selectedRating={rating} onRatingSelected={handleRatingSelected} />
          </Grid>
          <Grid item xs={12}>
            <InputBase
              value={freeText}
              onChange={e => setFreeText(e.target.value)}
              className={classes.freeTextInput}
              placeholder={t('dialogs.beforeAfter.feedback.freeTextPlaceholder')}
            />
          </Grid>
          <Grid item xs={12}>
            <DazzedParagraph14 bold className={classes.submitButton}>
              <span onClick={handleSubmit} className={isSubmitDisabled ? 'disabled' : ''}>
                {t('dialogs.beforeAfter.feedback.submitFeedback')}
              </span>
            </DazzedParagraph14>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default BeforeAfterFeedbackBody
