import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  scanDateLabel: {
    color: ({ color }) => color,
    fontWeight: 400
  }
}))

const ScanDateLabel = ({
  timestamp,
  withDaysAgo = true,
  className = '',
  color = 'rgba(114, 123, 140, 1)',
  dateFornmat = 'MMMM Do YYYY, h:mm A'
}) => {
  const classes = useStyles({ color })

  const [dateLabel, setDateLabel] = useState('')

  const updateDateLabel = useCallback(() => {
    const formattedDate = moment(timestamp).format(dateFornmat)

    if (withDaysAgo) {
      setDateLabel(`${formattedDate} (${moment(timestamp).fromNow()})`)
    } else {
      setDateLabel(formattedDate)
    }
  }, [withDaysAgo, timestamp, dateFornmat])

  useEffect(() => {
    updateDateLabel()

    if (!withDaysAgo || moment(timestamp).diff(moment(), 'day') >= 1) {
      return null
    }

    const interval = setInterval(updateDateLabel, 30000)
    return () => clearInterval(interval)
  }, [withDaysAgo, timestamp, updateDateLabel])

  return <DazzedParagraph12 className={[classes.scanDateLabel, className].join(' ')}>{dateLabel}</DazzedParagraph12>
}

export default ScanDateLabel
