import React from 'react'
import { makeStyles } from '@material-ui/styles'
import SwooshLargeIcon from '../common/icons/SwooshLarge1'
import { Box, Button, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import ROUTES from '../../consts/routesConsts'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  swoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  container: {
    padding: '100px 0',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Ogg',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '40px',
    lineHeight: '40px',
    marginBottom: '64px',
    color: 'var(--text-color-1)'
  },
  nextButton: {
    background: 'var(--border-color-1)',
    color: 'var(--text-color-1)',
    width: '478px',
    borderRadius: '34px',
    '&:hover': {
      background: 'var(--border-color-1)'
    }
  },
  noFill: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      transition: 'color 0.2s ease',
      color: 'gray',
      boxShadow: 'none'
    }
  },
  message: {
    color: 'var(--text-color-1)',
    width: '478px',
    alignSelf: 'center',
    marginBottom: '50px'
  }
}))

const EmailNotVerified = () => {
  const classes = useStyles()
  return (
    <div>
      <SwooshLargeIcon className={classes.swoosh} />
      <Box display="flex" flexDirection="column" justifyContent="center" className={classes.container}>
        <div className={classes.title}>Join The Grin Network</div>
        <div className={classes.message}>
          You must be an approved Grin provider to access the platform. It's easy! Just select 'Join Network' below to
          enter your information. We will get back to you soon with your sign-up information.
        </div>
        <Box display="flex" justifyContent="center" mt="20px">
          <Link href={'https://get-grin.com/contact/'}>
            <Button variant="contained" type={'submit'} className={classes.nextButton}>
              JOIN THE GRIN NETWORK
            </Button>
          </Link>
        </Box>
        <Box display="flex" justifyContent="center" mt="20px">
          <Link component={RouterLink} to={ROUTES.SIGN_UP}>
            <Button variant="contained" className={classNames(classes.nextButton, classes.noFill)}>
              BACK TO CREATE ACCOUNT
            </Button>
          </Link>
        </Box>
      </Box>
    </div>
  )
}

export default EmailNotVerified
