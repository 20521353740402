import * as React from 'react'

export default ({ color = '#4451DE' }) => {
  return (
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L8 8" stroke={color} strokeWidth="2" />
      <path d="M8 1L1 8" stroke={color} strokeWidth="2" />
    </svg>
  )
}
