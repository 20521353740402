import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ReactSketchCanvas } from 'react-sketch-canvas'
import { CircularProgress, makeStyles } from '@material-ui/core'
import BaseModal from 'components/common/modals/BaseModal'
import ConfirmationModal from 'components/common/ConfirmationModal'
import ButtonsGroup from 'components/common/annotations/ButtonsGroup'
import ActionButton from 'components/common/annotations/ActionButton'
import { hexToRgba } from 'utils/colorUtils'
import Actions from 'actions'
import { isEmpty } from 'lodash'
import { trackEvent } from 'utils/analyticsUtils'
import {
  DEFAULT_LINE_WIDTH,
  DEFAULT_BRUSH_OPACITY,
  DEFAULT_LINE_COLOR,
  DEFAULT_RGBA_COLOR,
  DRAW_CONFIG_NAME
} from 'consts/annotationsConsts'

const useStyles = () =>
  makeStyles({
    modal: {
      maxWidth: '100%'
    },
    modalContent: {
      marginTop: 60,
      marginBottom: 30,
      width: 1100,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    canvas: {
      border: 'none !important',
      minWidth: 743
    },
    loaderContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 12
    },
    sliderWrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      marginTop: 24,
      marginBottom: 100
    }
  })()

const ImageEditor = ({
  canvasWidth,
  canvasHeight,
  backgroundUrl,
  drawMode,
  onDrawMode,
  imagePosition,
  onUpdateImage,
  imageS3Object,
  modalWidth
}) => {
  const classes = useStyles()
  const canvasRef = useRef()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isLoading, base64Image } = useSelector(state => state.treatmentReducer.annotations)

  const [eraseMode, setEraseMode] = useState(false)
  const [drawColor, setDrawColor] = useState(DEFAULT_LINE_COLOR)
  const [drawOpacity, setDrawOpacity] = useState(DEFAULT_BRUSH_OPACITY)
  const [drawRgbaColor, setDrawRgbaColor] = useState(DEFAULT_RGBA_COLOR)
  const [drawWidth, setDrawWidth] = useState(DEFAULT_LINE_WIDTH)

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const backgroundImage = useMemo(() => backgroundUrl || base64Image, [backgroundUrl, base64Image])

  const handleDrawMode = useCallback(() => {
    onDrawMode()
    dispatch(Actions.clearBase64Image())
  }, [dispatch, onDrawMode])

  const handleEraseMode = useCallback(
    value => {
      setEraseMode(value)
      canvasRef.current.eraseMode(value)
      value &&
        trackEvent('Annotation_EraseClicked', {
          position: imagePosition
        })
    },
    [imagePosition]
  )

  const handleChangeColor = useCallback(
    color => {
      setDrawColor(color)
      const rgbaColor = hexToRgba(color, drawOpacity)
      setDrawRgbaColor(rgbaColor)
      handleEraseMode(false)
      trackEvent('Annotation_ColorChanged', {
        fromColor: drawColor,
        toColor: color
      })
    },
    [setDrawColor, handleEraseMode, drawOpacity, drawColor]
  )

  const handleChangeOpacity = useCallback(
    (event, newOpacity) => {
      setDrawOpacity(newOpacity)
      const rgbaColor = hexToRgba(drawColor, newOpacity)
      setDrawRgbaColor(rgbaColor)
    },
    [setDrawOpacity, drawColor]
  )

  const handleChangeWidth = useCallback(
    (event, newWidth) => {
      setDrawWidth(newWidth)
    },
    [setDrawWidth]
  )

  const handleClearCanvas = useCallback(() => {
    canvasRef.current.clearCanvas()
    trackEvent('Annotation_ClearAllClicked', {
      position: imagePosition
    })
  }, [imagePosition])

  const handleUndo = useCallback(() => {
    canvasRef.current.undo()
    trackEvent('Annotation_UndoClicked', {
      position: imagePosition
    })
  }, [imagePosition])

  const handleRedo = useCallback(() => {
    canvasRef.current.redo()
    trackEvent('Annotation_RedoClicked', {
      position: imagePosition
    })
  }, [imagePosition])

  const handleSaveConfig = useCallback(() => {
    const config = JSON.stringify({
      drawColor,
      drawOpacity,
      drawRgbaColor,
      drawWidth
    })
    localStorage.setItem(DRAW_CONFIG_NAME, config)
  }, [drawColor, drawWidth, drawOpacity, drawRgbaColor])

  const handleSendImage = useCallback(async () => {
    const paths = await canvasRef.current.exportPaths()
    if (!!paths.length) {
      const finalImage = await canvasRef.current.exportImage()
      onUpdateImage(finalImage)
    }
    handleSaveConfig()
    handleDrawMode()
    trackEvent('Annotation_DoneClicked', {
      position: imagePosition
    })
  }, [onUpdateImage, handleDrawMode, handleSaveConfig, imagePosition])

  const handleCancel = useCallback(() => {
    setIsConfirmationModalOpen(false)
    handleSaveConfig()
    handleDrawMode()
  }, [handleDrawMode, handleSaveConfig])

  const handleClose = useCallback(async () => {
    const paths = await canvasRef.current.exportPaths()
    !!paths.length ? setIsConfirmationModalOpen(true) : handleDrawMode()
  }, [handleDrawMode])

  useEffect(() => {
    const drawConfig = JSON.parse(localStorage.getItem(DRAW_CONFIG_NAME) || '{}')
    if (!isEmpty(drawConfig)) {
      setDrawColor(drawConfig.drawColor)
      setDrawOpacity(drawConfig.drawOpacity)
      setDrawWidth(drawConfig.drawWidth)
      setDrawRgbaColor(drawConfig.drawRgbaColor)
    }
  }, [])

  useEffect(() => {
    drawMode &&
      imageS3Object?.key &&
      imageS3Object?.bucket &&
      !backgroundUrl &&
      dispatch(Actions.fetchBase64Image(imageS3Object))
  }, [dispatch, drawMode, backgroundUrl, imageS3Object])

  return (
    <>
      <BaseModal
        open={drawMode}
        handleClose={handleClose}
        className={classes.modal}
        contentClassName={classes.modalContent}
      >
        {isLoading && (
          <div className={classes.loaderContainer}>
            <CircularProgress size={60} color="secondary" />
          </div>
        )}
        <ReactSketchCanvas
          ref={canvasRef}
          className={classes.canvas}
          canvasColor="var(--bg-color-4)"
          backgroundImage={backgroundImage}
          width={canvasWidth}
          height={canvasHeight}
          exportWithBackgroundImage
          strokeColor={drawColor}
          strokeWidth={drawWidth}
          eraserWidth={drawWidth * 3}
        />
        <ButtonsGroup
          canvasWidth={canvasWidth}
          drawMode={drawMode}
          eraseMode={eraseMode}
          drawWidth={drawWidth}
          drawColor={drawColor}
          drawOpacity={drawOpacity}
          defaultLineWidth={DEFAULT_LINE_WIDTH}
          defaultBrushOpacity={DEFAULT_BRUSH_OPACITY}
          onEraseMode={handleEraseMode}
          onUndoAction={handleUndo}
          onRedoAction={handleRedo}
          onSaveConfig={handleSaveConfig}
          onClearCanvas={handleClearCanvas}
          onChangeWidth={handleChangeWidth}
          onChangeColor={handleChangeColor}
          onChangeOpacity={handleChangeOpacity}
          sliderClassName={classes.sliderWrapper}
          fullHeightSlider
        >
          <ActionButton label={t('general.cancel')} onClick={handleClose} />
          <ActionButton label={t('dialogs.scanSummary.annotations.done')} onClick={handleSendImage} />
        </ButtonsGroup>
      </BaseModal>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title={t('dialogs.scanSummary.annotations.modal.title')}
        text={t('dialogs.scanSummary.annotations.modal.subtitle')}
        acceptButtonText={t('dialogs.scanSummary.annotations.modal.primaryBtnLabel')}
        declineButtonText={t('dialogs.scanSummary.annotations.modal.secondaryBtnLabel')}
        onConfirm={handleCancel}
        onClose={() => setIsConfirmationModalOpen(false)}
        smallText
        largerButtons
      />
    </>
  )
}

export default React.memo(ImageEditor)
