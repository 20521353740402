import React, { useMemo } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useTranslation } from 'react-i18next'
import { validatePasswordDetails, PASSWORD_REQUIREMENTS } from 'utils/authUtils'
import { Check1 as CheckIcon } from 'components/common/icons'

const useStyles = makeStyles({
  title: {
    color: 'var(--text-color-22)',
    marginBottom: 15
  },
  text: {
    color: 'var(--text-color-22)'
  },
  requirementsContainer: {
    maxWidth: 400,
    '& svg': {
      marginBottom: 2
    }
  }
})

const VALIDATION_COLORS = {
  VERIFIED: 'var(--text-color-39)',
  NON_VERFIED: 'var(--text-color-40)'
}
export const PasswordStrength = ({
  password,
  verifiedColor = VALIDATION_COLORS.VERIFIED,
  nonvVerifiedColor = VALIDATION_COLORS.NON_VERFIED,
  titleClassName = '',
  textClassName = ''
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const passwordValidationResult = useMemo(() => validatePasswordDetails(password), [password])

  return (
    <div>
      <DazzedParagraph12 className={[classes.title, titleClassName].join(' ')}>
        {t('common.passwordStrength.mustHave')}
      </DazzedParagraph12>
      <Grid container className={classes.requirementsContainer} spacing={1}>
        {PASSWORD_REQUIREMENTS.map(({ title, code }) => (
          <Grid item key={title}>
            <Grid container spacing={1}>
              <Grid item>
                <CheckIcon color={passwordValidationResult[code] ? verifiedColor : nonvVerifiedColor} />
              </Grid>
              <Grid item>
                <DazzedParagraph12 className={[classes.text, textClassName].join(' ')}>{title}</DazzedParagraph12>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default PasswordStrength
