import React, { useCallback, useMemo } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import GrinLabel from '../../common/text/GrinLabel'
import SelectInput from '../../common/SelectInput'
import FormInput from '../../common/FormInput'
import { SelectArrow } from '../../common/icons'
import GrinRadioGroup from '../../common/GrinRadioGroup'
import PrimaryButton from '../../common/buttons/PrimaryButton'
import TextArea from '../../common/TextArea'
import DazzedParagraph12 from '../../common/text/DazzedParagraph12'
import { PERIOD_TYPE_OPTIONS, VENDOR_OPTIONS } from '../../../consts/proposalConsts'

const useStyles = makeStyles({
  textArea: {
    fontWeight: 500
  },
  button: {
    whiteSpace: 'nowrap'
  },
  selectArrow: {
    position: 'absolute',
    right: '30px',
    pointerEvents: 'none'
  },
  notesOptional: {
    marginLeft: '8px',
    fontSize: '7px',
    textTransform: 'none',
    marginTop: '1px'
  },
  radioGroupWrapper: {
    marginTop: '12px'
  },
  disclaimer: {
    backgroundColor: 'var(--bg-color-14)',
    padding: '3px 13px'
  },
  disclaimerText: {
    fontSize: 10,
    lineHeight: '20px',
    '& span': {
      fontWeight: 'bold'
    }
  },
  notAllowedToEditMessage: {
    fontSize: 16,
    color: 'var(--text-color-21)',
    paddingBottom: 15
  }
})

const TreatmentForm = ({
  values,
  preliminaryPlanProducts,
  buttonLabel,
  disableFields,
  disableButton,
  setValues,
  onSubmitForm,
  type,
  hasAccessToEdit
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const isPrelim = useMemo(() => type === 'prelim', [type])

  const handleChangeTier = useCallback(
    ({ value }) => {
      setValues({
        ...values,
        tier: value,
        cost: preliminaryPlanProducts.find(option => option.tier === value).price,
        additionalVendor: value === 3 ? values.additionalVendor : ''
      })
    },
    [preliminaryPlanProducts, setValues, values]
  )

  const tierOptions = useMemo(
    () =>
      preliminaryPlanProducts.map(option => ({
        label: t(`dialogs.patientInfo.treatmentProposal.tierOptions.tire${option.tier}`),
        value: option.tier
      })),
    [preliminaryPlanProducts, t]
  )

  return (
    <Grid container spacing={3}>
      <Grid item sm={6}>
        <SelectInput
          options={tierOptions}
          value={values.tier}
          disabled={disableFields || !preliminaryPlanProducts.length}
          label={t('dialogs.patientInfo.treatmentProposal.treatmentTier')}
          onChange={handleChangeTier}
          style={{ bright: true, thick: true }}
          selectStyle={{ fontWeight: 500, paddingLeft: 0 }}
          keepErrorContainerWhenInactive={false}
        />
      </Grid>
      <Grid item sm={6}>
        <FormInput
          title={t('dialogs.patientInfo.treatmentProposal.cost')}
          value={values.cost ? '$' + values.cost : ''}
          setValue={({ value }) => setValues({ ...values, cost: value })}
          style={{ bright: true, thick: true }}
          isDisabled
          inputStyle={{ paddingLeft: '9px' }}
          keepErrorContainerWhenInactive={false}
        />
      </Grid>
      <Grid item sm={6}>
        <GrinLabel>{t('dialogs.patientInfo.treatmentProposal.treatmentLength')}</GrinLabel>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <FormInput
              value={values.length}
              setValue={value => setValues({ ...values, length: +value })}
              style={{ bright: true, thick: true }}
              isDisabled={disableFields}
              type="number"
              inputStyle={{ paddingLeft: '9px' }}
              minValue={1}
            />
          </Grid>
          <Grid item className={classes.radioGroupWrapper}>
            <GrinRadioGroup
              isDisabled={disableFields}
              value={values.periodType}
              setValue={value => setValues({ ...values, periodType: value })}
              options={PERIOD_TYPE_OPTIONS}
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6}>
        <SelectInput
          options={VENDOR_OPTIONS}
          value={values.vendor}
          disabled={disableFields}
          label={
            values.tier === 3
              ? t('dialogs.patientInfo.treatmentProposal.aligners')
              : t('dialogs.patientInfo.treatmentProposal.appliance')
          }
          onChange={({ value }) => setValues({ ...values, vendor: value })}
          style={{ bright: true, thick: true }}
          selectStyle={{ fontWeight: 500, paddingLeft: 0 }}
          keepErrorContainerWhenInactive={false}
        />
      </Grid>
      {values.tier === 3 && (
        <>
          <Grid item sm={6} />
          <Grid item sm={6}>
            <SelectInput
              options={VENDOR_OPTIONS}
              value={values.additionalVendor}
              disabled={disableFields}
              label={t('dialogs.patientInfo.treatmentProposal.braces')}
              onChange={({ value }) => setValues({ ...values, additionalVendor: value })}
              style={{ bright: true, thick: true }}
              selectStyle={{ fontWeight: 500, paddingLeft: 0 }}
              iconComponent={() => (
                <div className={classes.selectArrow}>
                  <SelectArrow />
                </div>
              )}
              keepErrorContainerWhenInactive={false}
            />
          </Grid>
        </>
      )}
      <Grid item sm={12}>
        <TextArea
          title={t('dialogs.patientInfo.treatmentProposal.notes')}
          titleComment={t('dialogs.patientInfo.treatmentProposal.optional')}
          titleCommentClassName={classes.notesOptional}
          isDisabled={disableFields}
          setValue={value => setValues({ ...values, notes: value })}
          style={{ bright: true }}
          value={values.notes}
          maxLength={800}
          className={classes.textArea}
          inputStyle={{ paddingLeft: '14px' }}
        />
        {hasAccessToEdit && isPrelim && (
          <div className={classes.disclaimer}>
            <DazzedParagraph12 className={classes.disclaimerText}>
              <span>{t('dialogs.patientInfo.prelimPlan.dialog.disclaimer')}</span>:&nbsp;
              {t('dialogs.patientInfo.prelimPlan.dialog.notesDescription1')}
            </DazzedParagraph12>
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center" alignContent="center">
          <Grid item>
            {hasAccessToEdit ? (
              <PrimaryButton
                disabled={disableButton}
                label={buttonLabel}
                onClick={onSubmitForm}
                className={classes.button}
              />
            ) : (
              <DazzedParagraph12 className={classes.notAllowedToEditMessage} thickness="bold">
                {isPrelim
                  ? t('dialogs.patientInfo.prelimPlan.notAllowedToEditMessage')
                  : t('dialogs.patientInfo.treatmentProposal.notAllowedToEditMessage')}
              </DazzedParagraph12>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TreatmentForm
