import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Collapse, Grid } from '@material-ui/core'
import GrinSwitch from 'components/common/GrinSwitch'
import { trackEvent } from 'utils/analyticsUtils'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { useTranslation } from 'react-i18next'
import Chevron from 'components/common/icons/ChevrolRightSmall'
import { PracticeGuidelineInfo } from 'components/common/icons'
import Tooltip from 'components/common/Tooltip'
import CategoryItems from './CategoryItems'
import CategoryNote from './CategoryNote'

const useStyles = makeStyles(theme => ({
  container: {
    width: 1020
  },
  header: {
    cursor: 'pointer',
    padding: '20px 0px 5px 0px'
  },
  divider: {
    border: '1px solid var(--border-color-11)',
    opacity: 0.3
  },
  title: {
    userSelect: 'none'
  },
  disabledOverlay: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: '#fff',
    opacity: 0.7,
    zIndex: 9999
  }
}))

const GuidelineCategory = ({ type, isEnabled = false, isCollapsed, onCollapseClick, note, items, onChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleCollapseToggled = useCallback(() => {
    trackEvent('Practice guidelines - Category collapse toggled', {
      category: type,
      isCollapsed: !isCollapsed
    })
    onCollapseClick({ categoryKey: type })
  }, [isCollapsed, onCollapseClick, type])

  const handleCategoryToggle = useCallback(
    e => {
      e.stopPropagation()
      trackEvent(`Practice guidelines - Toggled category ${isEnabled ? 'off' : 'on'}`, {
        category: type
      })
      onChange({
        [type]: {
          isEnabled: !isEnabled,
          note,
          items
        }
      })
    },
    [isEnabled, items, note, onChange, type]
  )

  const handleCategoryItemsChanged = useCallback(
    updatedItems =>
      onChange({
        [type]: {
          isEnabled,
          note,
          items: updatedItems
        }
      }),
    [isEnabled, note, onChange, type]
  )

  const handleCategoryNoteChanged = useCallback(
    newNote =>
      onChange({
        [type]: {
          isEnabled,
          note: newNote,
          items
        }
      }),
    [isEnabled, items, onChange, type]
  )

  return (
    <Grid container id={type} direction="column" wrap="nowrap" className={classes.container}>
      <Grid item onClick={handleCollapseToggled} className={classes.header}>
        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <GrinSwitch checked={isEnabled} onClick={e => handleCategoryToggle(e)} />
              </Grid>
              <Grid item>
                <DazzedParagraph16 bold className={classes.title}>
                  {t(`pages.accountSettings.practiceGuidelines.categories.${type}.title`)}
                </DazzedParagraph16>
              </Grid>
              <Grid item>
                <Tooltip
                  arrow
                  placement="top"
                  value={t(`pages.accountSettings.practiceGuidelines.categories.${type}.tooltip`)}
                >
                  <PracticeGuidelineInfo style={{ marginTop: 5 }} />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Chevron
              fill="var(--text-color-27)"
              style={{ transform: isCollapsed ? 'rotate(90deg)' : 'rotate(-90deg)' }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Collapse in={!isCollapsed} style={{ marginBottom: 20, position: 'relative' }}>
          <div className={!isEnabled ? classes.disabledOverlay : ''}></div>
          <CategoryItems category={type} itemsData={items} onChange={handleCategoryItemsChanged} />
          <CategoryNote note={note} onChange={handleCategoryNoteChanged} />
        </Collapse>
      </Grid>
      <div className={classes.divider} />
    </Grid>
  )
}

export default GuidelineCategory
