import * as React from 'react'

const SpeechBubble = props => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1319_33477)">
        <path
          d="M5.00006 12.3525C4.027 12.3525 3.23535 13.1442 3.23535 14.1172C3.23535 15.0903 4.027 15.8819 5.00006 15.8819C5.97312 15.8819 6.76476 15.0903 6.76476 14.1172C6.76476 13.1442 5.97312 12.3525 5.00006 12.3525ZM5.00006 14.7055C4.6757 14.7055 4.41182 14.4416 4.41182 14.1172C4.41182 13.7929 4.6757 13.529 5.00006 13.529C5.32441 13.529 5.58829 13.7929 5.58829 14.1172C5.58829 14.4416 5.32441 14.7055 5.00006 14.7055Z"
          fill="#001335"
        />
        <path
          d="M10.294 12.3525C9.32094 12.3525 8.5293 13.1442 8.5293 14.1172C8.5293 15.0903 9.32094 15.8819 10.294 15.8819C11.2671 15.8819 12.0587 15.0903 12.0587 14.1172C12.0587 13.1442 11.2671 12.3525 10.294 12.3525ZM10.294 14.7055C9.96965 14.7055 9.70577 14.4416 9.70577 14.1172C9.70577 13.7929 9.96965 13.529 10.294 13.529C10.6184 13.529 10.8822 13.7929 10.8822 14.1172C10.8822 14.4416 10.6184 14.7055 10.294 14.7055Z"
          fill="#001335"
        />
        <path
          d="M15.5882 12.3525C14.6151 12.3525 13.8235 13.1442 13.8235 14.1172C13.8235 15.0903 14.6151 15.8819 15.5882 15.8819C16.5613 15.8819 17.3529 15.0903 17.3529 14.1172C17.3529 13.1442 16.5613 12.3525 15.5882 12.3525ZM15.5882 14.7055C15.2638 14.7055 15 14.4416 15 14.1172C15 13.7929 15.2638 13.529 15.5882 13.529C15.9125 13.529 16.1764 13.7929 16.1764 14.1172C16.1764 14.4416 15.9125 14.7055 15.5882 14.7055Z"
          fill="#001335"
        />
        <path
          d="M27.6471 10.5882H27.0588V2.35294C27.0588 1.05553 26.0033 0 24.7059 0H8.23529C6.93788 0 5.88235 1.05553 5.88235 2.35294V5.29412H2.35294C1.05553 5.29412 0 6.34965 0 7.64706V20.5882C0 21.8857 1.05553 22.9412 2.35294 22.9412H8.01771L9.91129 24.5643C10.1317 24.7531 10.4566 24.7531 10.6769 24.5643L11.7647 23.6319V25.8824C11.7647 27.1798 12.8202 28.2353 14.1176 28.2353H18.6059L20.4995 29.8584C20.7199 30.0472 21.0449 30.0472 21.2652 29.8584L23.1588 28.2353H27.6471C28.9445 28.2353 30 27.1798 30 25.8824V12.9412C30 11.6438 28.9445 10.5882 27.6471 10.5882ZM7.05882 2.35294C7.05882 1.70424 7.58659 1.17647 8.23529 1.17647H24.7059C25.3546 1.17647 25.8824 1.70424 25.8824 2.35294V10.5882H20.5882V7.64706C20.5882 6.34965 19.5327 5.29412 18.2353 5.29412H7.05882V2.35294ZM12.3529 21.7647C12.2106 21.7647 12.0719 21.8188 11.9701 21.9063L10.2941 23.3429L8.61812 21.9063C8.51147 21.8149 8.37571 21.7647 8.23529 21.7647H2.35294C1.70424 21.7647 1.17647 21.2369 1.17647 20.5882V7.64706C1.17647 6.99835 1.70424 6.47059 2.35294 6.47059H18.2353C18.884 6.47059 19.4118 6.99835 19.4118 7.64706V20.5882C19.4118 21.2369 18.884 21.7647 18.2353 21.7647H12.3529ZM28.8235 25.8824C28.8235 26.5311 28.2958 27.0588 27.6471 27.0588H22.9412C22.8008 27.0588 22.665 27.109 22.5584 27.2004L20.8824 28.637L19.2064 27.2004C19.0997 27.1091 18.9639 27.0588 18.8235 27.0588H14.1176C13.4689 27.0588 12.9412 26.5311 12.9412 25.8824V22.9412H18.2353C19.5327 22.9412 20.5882 21.8857 20.5882 20.5882V11.7647H27.6471C28.2958 11.7647 28.8235 12.2925 28.8235 12.9412V25.8824Z"
          fill="#001335"
        />
      </g>
      <defs>
        <clipPath id="clip0_1319_33477">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SpeechBubble
