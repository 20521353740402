const FETCH_ANNOUNCEMENTS = 'FETCH_ANNOUNCEMENTS'
const ANNOUNCEMENTS_RECEIVED = 'ANNOUNCEMENTS_RECEIVED'
const FETCH_ANNOUNCEMENTS_FAILED = 'FETCH_ANNOUNCEMENTS_FAILED'
const TOGGLE_ANNOUNCEMENT_OPEN = 'TOGGLE_ANNOUNCEMENT_OPEN'
const DISMISS_ANNOUNCEMENT = 'DISMISS_ANNOUNCEMENT'
const SUBMIT_SURVEY_RESULT = 'SUBMIT_SURVEY_RESULT'
const SUBMIT_SURVEY_RESULT_FAILED = 'SUBMIT_SURVEY_RESULT_FAILED'
const SUBMIT_SURVEY_RESULT_RECEIVED = 'SUBMIT_SURVEY_RESULT_RECEIVED'
const RESET_SURVEY_RESULT = 'RESET_SURVEY_RESULT'

const fetchAnnouncements = payload => ({
  type: FETCH_ANNOUNCEMENTS,
  payload
})

const announcementsReceived = payload => ({
  type: ANNOUNCEMENTS_RECEIVED,
  payload
})

const fetchAnnouncementsFailed = payload => ({
  type: FETCH_ANNOUNCEMENTS_FAILED,
  payload
})

const toggleAnnouncementOpen = payload => ({
  type: TOGGLE_ANNOUNCEMENT_OPEN,
  payload
})

const dismissAnnouncement = payload => ({
  type: DISMISS_ANNOUNCEMENT,
  payload
})

const submitSurveyResult = payload => ({
  type: SUBMIT_SURVEY_RESULT,
  payload
})

const submitSurveyResultReceived = payload => ({
  type: SUBMIT_SURVEY_RESULT_RECEIVED,
  payload
})

const submitSurveyResultFailed = payload => ({
  type: SUBMIT_SURVEY_RESULT_FAILED,
  payload
})

const resetSurveyResult = payload => ({
  type: RESET_SURVEY_RESULT,
  payload
})

export default {
  FETCH_ANNOUNCEMENTS,
  ANNOUNCEMENTS_RECEIVED,
  FETCH_ANNOUNCEMENTS_FAILED,
  TOGGLE_ANNOUNCEMENT_OPEN,
  DISMISS_ANNOUNCEMENT,
  SUBMIT_SURVEY_RESULT,
  SUBMIT_SURVEY_RESULT_FAILED,
  SUBMIT_SURVEY_RESULT_RECEIVED,
  RESET_SURVEY_RESULT,
  fetchAnnouncements,
  announcementsReceived,
  fetchAnnouncementsFailed,
  dismissAnnouncement,
  toggleAnnouncementOpen,
  submitSurveyResult,
  submitSurveyResultFailed,
  submitSurveyResultReceived,
  resetSurveyResult
}
