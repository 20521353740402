import * as React from 'react'

function VideoStopIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="video-stop-button"
    >
      <rect width="12" height="12" rx="2" fill="white" />
    </svg>
  )
}

export default VideoStopIcon
