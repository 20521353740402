import { makeStyles, Table, TableBody } from '@material-ui/core'
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import {
  selectTaskManagerCurrentPage,
  selectTaskManagerFilters,
  selectTaskManagerPaginationData,
  selectIsLoading
} from '../../../selectors/taskManagerSelectors'
import useHasPatients from 'hooks/useHasPatients'
import TaskManagerEmptyState from './TaskManagerEmptyState'
import PatientsTableFooter from './PatientsTableFooter'
import PatientsTableHeader from './PatientsTableHeader'
import PatientsTableLoader from './PatientsTableLoader'
import PatientsTableRow from './PatientsTableRow'
import TaskCategoriesSideBar from '../TaskCategoriesSideBar'
import PatientsFilter from 'components/Search/PatientsFilter'
import { DEFAULT_TAGS_FILTER } from 'consts/filterConsts'
import useParsedFilters from 'hooks/useParsedFilters'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  tableContainer: {
    flex: 1,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  table: {
    marginBottom: 20
  },
  taskManagerContainer: {
    display: 'flex',
    width: '100%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
})

const PatientsTable = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { hasOrthoPatients } = useHasPatients()

  const pagePatients = useSelector(selectTaskManagerCurrentPage)
  const filters = useSelector(selectTaskManagerFilters)
  const paginationData = useSelector(selectTaskManagerPaginationData)
  const isLoading = useSelector(selectIsLoading)

  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [patientsSearchFilter, setPatientsSearchFilter] = useState('')
  const [patientsTagsFilter, setPatientsTagsFilter] = useState(DEFAULT_TAGS_FILTER)
  const { isFilterActivated } = useParsedFilters(patientsTagsFilter)

  const emptyStateVariant = useMemo(
    () => (filters.name || isFilterActivated ? 'noResults' : hasOrthoPatients ? 'emptyState' : 'noPatients'),
    [filters.name, hasOrthoPatients, isFilterActivated]
  )

  const handleSortOrderChange = useCallback(
    (sortBy, sortDirection) =>
      dispatch(
        Actions.setTaskManagerFilters({
          sortBy,
          sortDirection
        })
      ),
    [dispatch]
  )

  const handlePageChange = useCallback(page => dispatch(Actions.setTaskManagerPage(page)), [dispatch])
  const handleRefreshDashboard = useCallback(
    () =>
      dispatch(
        Actions.requestPatientsForTaskManager({ newSearch: true, showLoader: true, updateTaskCategories: true })
      ),
    [dispatch]
  )

  const handleActionChanged = useCallback(
    lastActionItemType => {
      trackEvent('Sidebar action clicked', {
        actionType: lastActionItemType[0] || 'all'
      })
      dispatch(Actions.setTaskManagerFilters({ lastActionItemType, name: '' }))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(
      Actions.setTaskManagerFilters({
        sortBy: 'lastInteractionTimestamp',
        name: patientsSearchFilter,
        tags: Object.entries(patientsTagsFilter).reduce((acc, [key, tags]) => {
          if (key === 'program') {
            return { ...acc, [key]: ['ortho'] }
          }
          return { ...acc, [key]: tags.map(tag => tag.value) }
        }, {})
      })
    )
  }, [dispatch, patientsSearchFilter, patientsTagsFilter])

  return (
    <div className={classes.container}>
      <PatientsFilter
        onChange={setPatientsTagsFilter}
        isFilterActivated={isFilterActivated}
        patientsFilter={patientsTagsFilter}
        onNameFilter={setPatientsSearchFilter}
        patientsSearchFilter={patientsSearchFilter}
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        hidePrograms
      />
      <div className={classes.taskManagerContainer}>
        <TaskCategoriesSideBar
          selectedCategory={filters.lastActionItemType[0]}
          onTaskCategoryChanged={handleActionChanged}
        />
        <div className={classes.tableContainer}>
          <Table stickyHeader className={classes.table}>
            <colgroup>
              <col style={{ width: '25%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '20%' }} />
              {/* <col style={{ width: '8%' }} /> */}
            </colgroup>
            <PatientsTableHeader
              sortOrder={filters.sortDirection}
              sortBy={filters.sortBy}
              onSortOrderChange={handleSortOrderChange}
              onRefresh={handleRefreshDashboard}
            />
            {!pagePatients?.length && !isLoading && <TaskManagerEmptyState variant={emptyStateVariant} />}
            <TableBody>
              {!isLoading &&
                pagePatients?.map((patientSM, index) => (
                  <PatientsTableRow key={patientSM.id} index={index} patientSM={patientSM} />
                ))}
              {isLoading && <PatientsTableLoader />}
            </TableBody>
            {!isLoading && <PatientsTableFooter paginationData={paginationData} onPageChange={handlePageChange} />}
          </Table>
        </div>
      </div>
    </div>
  )
}

export default PatientsTable
