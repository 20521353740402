import React, { useCallback, useEffect, useState } from 'react'
import StatusPicker from 'components/common/inputs/StatusPicker/StatusPicker'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { ProgramTypes } from '@grin-rnd/grin-api-sdk/dist/Enums/Programs'
import { conditionTypes } from 'consts/automatedActionConsts'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  statusPicker: {
    width: 225,
    border: 0,
    borderRadius: '0px !important',
    borderBottom: '1px solid var(--border-color-12)'
  },
  disclaimer: {
    marginTop: 10,
    whiteSpace: 'break-spaces'
  }
}))

const TreatmentStatusUpdatedCondition = ({ onChange, params }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const presetStatus = useSelector(state => state.treatmentReducer.statuses.data?.[ProgramTypes.RM]).find(status =>
    params?.statusType ? status.type === params?.statusType : status.type === 'retention'
  )

  const [changeStatusTo, setChangeStatusTo] = useState(presetStatus)

  useEffect(() => {
    onChange({
      type: conditionTypes.TreatmentStatusUpdated,
      params: {
        statusType: presetStatus.type
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStatusChange = useCallback(
    newStatus => {
      onChange({
        type: conditionTypes.TreatmentStatusUpdated,
        params: {
          statusType: newStatus.type
        }
      })
      setChangeStatusTo(newStatus)
    },
    [onChange]
  )

  return (
    <>
      <StatusPicker
        id={'automation-rule-condition-status-picker'}
        program={'rc'}
        statusesToDisplay={['active-treatment', 'observation', 'retention']}
        onSelect={handleStatusChange}
        selectedStatus={changeStatusTo}
        className={classes.statusPicker}
      />
      <DazzedParagraph12 className={classes.disclaimer}>
        {t('pages.accountSettings.automations.editorModal.treatmentUpdatedDisclaimer')}
      </DazzedParagraph12>
    </>
  )
}

export default TreatmentStatusUpdatedCondition
