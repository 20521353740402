import * as React from 'react'

const CrossedCircle = ({ color = '#282828', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C15.9953 3.5837 12.4163 0.00472099 8 0ZM3.55661 2.77025C4.79743 1.7172 6.3726 1.1403 8 1.14288C9.62074 1.14238 11.1887 1.71867 12.4234 2.76861L2.7663 12.4257C0.318248 9.54119 0.672088 5.2183 3.55661 2.77025ZM12.4434 13.2298C11.2026 14.2828 9.62744 14.8597 8.00003 14.8572C6.37929 14.8577 4.81129 14.2814 3.5766 13.2314L13.2338 3.57426C15.6818 6.45882 15.3279 10.7817 12.4434 13.2298Z"
        fill={color}
      />
    </svg>
  )
}

export default CrossedCircle
