import * as React from 'react'

const Cancel = () => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L10 10" stroke="#4451DE" strokeWidth="2" />
    <path d="M10 1L1 10" stroke="#4451DE" strokeWidth="2" />
  </svg>
)

export default Cancel
