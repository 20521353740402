import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Clock2 as ClockIcon } from 'components/common/icons'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { formatScheduleTime } from 'utils/stringUtils'

const useStyles = makeStyles(theme => ({
  marginBottom: 5
}))

const MessageScheduleTimeLabel = ({ scheduleTime }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container className={classes.scheduleTimeContainer} alignItems="center" spacing={1}>
      <Grid item>
        <ClockIcon />
      </Grid>
      <Grid item>
        <DazzedParagraph12 thickness="thin">
          {t('pages.patients.selectedPatient.chat.scheduledFor', { time: formatScheduleTime(scheduleTime) })}
        </DazzedParagraph12>
      </Grid>
    </Grid>
  )
}

export default MessageScheduleTimeLabel
