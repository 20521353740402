import * as React from 'react'

const SvgLeftArrow = ({ color = '#fff', ...props }) => {
  return (
    <svg width={32} height={32} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.759 26.359a.522.522 0 00.72.008.48.48 0 00.008-.695L5.217 16.5H28a.5.5 0 000-1H5.216l9.27-9.172a.48.48 0 00-.008-.695.522.522 0 00-.72.008L3.656 15.638a.47.47 0 00-.013.014l-.006.005-.347.343.347.343.006.006a.63.63 0 00.013.013L13.76 26.36z"
        fill={color}
      />
    </svg>
  )
}

export default SvgLeftArrow
