import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import CategoryItems from './CategoryItems'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    overflowY: 'auto'
  },
  lastUpdated: {
    paddingBottom: 15,
    color: 'var(--text-color-11)'
  },
  categoriesContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  category: {
    backgroundColor: 'var(--bg-color-73)'
  },
  categoryTitle: {
    padding: '5px 0px 5px 10px'
  },
  firstCategory: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  }
}))

const Categories = ({ categories, categoriesData, actions }) => {
  const classes = useStyles()

  return (
    <div className={classes.categoriesContainer}>
      {categories.map((category, index) => (
        <div className={`${classes.category} ${index === 0 ? classes.firstCategory : ''}`} key={category.key}>
          <Grid container>
            <Grid item>
              <Grid container alignItems="center" className={classes.categoryTitle}>
                <Grid item style={{ marginRight: 5 }}>
                  {category.icon}
                </Grid>
                <Grid item>
                  <DazzedParagraph14 strong>{category.title}</DazzedParagraph14>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CategoryItems
                categoryComponent={category.component}
                items={categoriesData?.[category.key]?.items}
                actions={actions}
                note={categoriesData?.[category.key]?.note}
              />
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  )
}

export default Categories
