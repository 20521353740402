import React, { useCallback, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import SwooshLargeIcon from 'components/common/icons/SwooshLarge1'
import FormInput from 'components/common/FormInput'
import OggHeading40 from 'components/common/text/OggHeading40'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import PhoneNumberInput from 'components/common/PhoneNumberInput'
import ROUTES from 'consts/routesConsts'
import Actions from 'actions'
import { removePlus } from 'utils/stringUtils'

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  signUpSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  form: {
    width: 540
  },
  title: {
    color: 'var(--text-color-1)',
    marginBottom: 25
  },
  phoneInput: {
    height: '40px !important',
    '&:focus': {
      outline: '1px solid var(--border-color-1) !important'
    }
  },
  phoneInputTitle: {
    color: 'var(--text-color-1)'
  },
  input: {
    height: '40px !important',
    backgroundColor: 'var(--bg-color-4) !important',
    '&:focus': {
      border: '1px solid var(--border-color-1)'
    }
  },
  inputWrapper: {
    backgroundColor: 'transparent !important',
    height: 'auto !important',
    '&.error input:focus': {
      border: 'none !important'
    }
  },
  button: {
    marginTop: 30
  }
})

const Details = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  const [fullPhoneNumber, setFullPhoneNumber] = useState('')

  const { firstName, lastName, roleDescription, phoneNumber, countryCode } = useSelector(
    state => state.authReducer.practiceMembers
  )

  const [errors, setErrors] = useState({})

  const validateFields = useCallback(() => {
    let localErrors = {}
    if (!firstName) {
      localErrors.firstName = t('pages.authPracticeMembers.details.firstNameError')
    }
    if (!lastName) {
      localErrors.lastName = t('pages.authPracticeMembers.details.lastNameError')
    }
    if (!phoneNumber) {
      localErrors.phoneNumber = t('pages.authPracticeMembers.details.phoneNumberError')
    }

    return localErrors
  }, [firstName, lastName, phoneNumber, t])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      let localErrors = validateFields()
      if (Object.keys(localErrors).length) {
        setErrors(localErrors)
      } else {
        history.push({ pathname: ROUTES.AUTH_PRACTICE_MEMBERS_PHOTO, search: location.search })
      }
    },
    [history, location.search, validateFields]
  )

  const isPrimaryDisabled = useMemo(() => !firstName || !lastName || !phoneNumber, [firstName, lastName, phoneNumber])
  return (
    <div className={classes.container}>
      <SwooshLargeIcon className={classes.signUpSwoosh} />
      <form className={classes.form} onSubmit={handleSubmit}>
        <OggHeading40 className={classes.title} textAlign="left">
          {t('pages.authPracticeMembers.details.createAccount')}
        </OggHeading40>
        <Grid container spacing={2}>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormInput
                  title={t('pages.authPracticeMembers.details.firstName')}
                  value={firstName}
                  inputClassName={classes.input}
                  inputWrapperClassName={classes.inputWrapper}
                  errorMessage={errors.firstName}
                  setValue={value =>
                    dispatch(
                      Actions.changePracticeMembersAuthForm({
                        key: 'firstName',
                        value: value
                      })
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  title={t('pages.authPracticeMembers.details.lastName')}
                  value={lastName}
                  inputClassName={classes.input}
                  inputWrapperClassName={classes.inputWrapper}
                  errorMessage={errors.lastName}
                  setValue={value =>
                    dispatch(
                      Actions.changePracticeMembersAuthForm({
                        key: 'lastName',
                        value: value
                      })
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PhoneNumberInput
              title={t('pages.authPracticeMembers.details.phoneNumber')}
              value={fullPhoneNumber}
              inputClassName={classes.phoneInput}
              titleClassName={classes.phoneInputTitle}
              errorMessage={errors.phoneNumber}
              setValue={value => {
                setFullPhoneNumber(value)
                dispatch(
                  Actions.changePracticeMembersAuthForm({
                    key: 'phoneNumber',
                    value: value.substring(removePlus(countryCode).length)
                  })
                )
              }}
              setCountryCode={countryCode =>
                dispatch(
                  Actions.changePracticeMembersAuthForm({
                    key: 'countryCode',
                    value: '+' + countryCode
                  })
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              title={t('pages.authPracticeMembers.details.roleDescription')}
              value={roleDescription}
              inputClassName={classes.input}
              inputWrapperClassName={classes.inputWrapper}
              setValue={value =>
                dispatch(
                  Actions.changePracticeMembersAuthForm({
                    key: 'roleDescription',
                    value: value
                  })
                )
              }
            />
          </Grid>
        </Grid>
        <PrimaryButton
          label={t('general.next')}
          type="submit"
          width="100%"
          className={classes.button}
          disabled={isPrimaryDisabled}
          variant={isPrimaryDisabled ? 'outlined' : 'contained'}
        />
      </form>
    </div>
  )
}

export default Details
