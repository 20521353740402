import React from 'react'
import { Snackbar, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../actions'
import Alert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'

const useStyles = makeStyles({
  snackbar: {
    zIndex: '2000 !important'
  }
})

const AppSnackbar = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { snackbarText, snackbarOpen, snackbarType, snackbarDuration } = useSelector(state => state.appReducer)

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={snackbarDuration}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={Slide}
      onClose={() => dispatch(Actions.hideSnackbar())}
      className={classes.snackbar}
    >
      <Alert variant="filled" onClose={() => dispatch(Actions.hideSnackbar())} severity={snackbarType}>
        {snackbarText}
      </Alert>
    </Snackbar>
  )
}

export default AppSnackbar
