import React, { useMemo } from 'react'
import TitleWithText from 'components/Patients/PatientGuidelines/PatientBrief/Categories/TitleWithText'
import { Grid } from '@material-ui/core'
import { sortTeeth } from 'utils/giUtils'

const Elastics = ({ items }) => {
  const { elastics } = items

  const elasticItems = useMemo(
    () =>
      elastics.map(elastic => ({
        title: elastic.name,
        content: sortTeeth(Object.keys(elastic.selections))
          .map(tooth => `${tooth} (${elastic.selections[tooth]})`)
          .join(', ')
      })),
    [elastics]
  )

  return (
    <Grid container direction="column" spacing={2}>
      {elasticItems.map(item => (
        <Grid item key={item.title}>
          <TitleWithText title={item.title} text={item.content} />
        </Grid>
      ))}
    </Grid>
  )
}

export default Elastics
