import React from 'react'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const TypicalWireSequence = ({ value }) => {
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <DazzedParagraph14 strong color="var(--text-color-11)">
          {t('dialogs.hiPatientGuidelinesViewer.actions.typicalWireSequence')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <DazzedParagraph14>{value}</DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default TypicalWireSequence
