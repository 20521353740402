import * as React from 'react'

const Calendar4 = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 6L6 6C5.44772 6 5 6.44772 5 7L5 18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V7C19 6.44772 18.5523 6 18 6ZM6 5C4.89543 5 4 5.89543 4 7L4 18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V7C20 5.89543 19.1046 5 18 5L6 5Z"
        fill="white"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M19 9H5V8H19V9Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 4C15.7761 4 16 4.22386 16 4.5L16 6.5C16 6.77614 15.7761 7 15.5 7C15.2239 7 15 6.77614 15 6.5L15 4.5C15 4.22386 15.2239 4 15.5 4Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 4C8.77614 4 9 4.22386 9 4.5L9 6.5C9 6.77614 8.77614 7 8.5 7C8.22386 7 8 6.77614 8 6.5L8 4.5C8 4.22386 8.22386 4 8.5 4Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11.5C12.2761 11.5 12.5 11.7239 12.5 12V16C12.5 16.2761 12.2761 16.5 12 16.5C11.7239 16.5 11.5 16.2761 11.5 16V12C11.5 11.7239 11.7239 11.5 12 11.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 14C9.5 13.7239 9.72386 13.5 10 13.5H14C14.2761 13.5 14.5 13.7239 14.5 14C14.5 14.2761 14.2761 14.5 14 14.5H10C9.72386 14.5 9.5 14.2761 9.5 14Z"
        fill="white"
      />
    </svg>
  )
}

export default Calendar4
