import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from '../common/text/DazzedParagraph14'
import { Trash2, Pencil3, Check2, Close1 } from 'components/common/icons'
import NoteActionsMenu from './NoteActionsMenu'

const useStyles = makeStyles({
  noteAction: {
    cursor: 'pointer'
  },
  disabledNoteAction: {
    opacity: 0.3,
    pointerEvents: 'none'
  },
  editNoteAction: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  editNoteActionIcon: {
    marginTop: 2,
    marginRight: 3
  }
})

const NoteItemActions = ({
  noteId,
  isEditingMode,
  handleCancelEditing,
  handleSaveEditedNote,
  onDelete,
  onEdit,
  isEditAllowed,
  showAsMenu,
  testIdPrefix = ''
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      {isEditingMode ? (
        <Grid container justifyContent="space-around" alignItems="center" spacing={1}>
          <Grid
            item
            onClick={handleCancelEditing}
            className={classes.editNoteAction}
            id={`${testIdPrefix}-edit-cancel`}
          >
            <Close1 className={classes.editNoteActionIcon} />
            <DazzedParagraph14 color="var(--text-color-3)"> {t('general.cancel')}</DazzedParagraph14>
          </Grid>
          <Grid
            item
            onClick={handleSaveEditedNote}
            className={classes.editNoteAction}
            id={`${testIdPrefix}-edit-confirm`}
          >
            <Check2 color="var(--text-color-3)" width={13} height={9} className={classes.editNoteActionIcon} />
            <DazzedParagraph14 color="var(--text-color-3)"> {t('general.done')}</DazzedParagraph14>
          </Grid>
        </Grid>
      ) : showAsMenu ? (
        isEditAllowed && <NoteActionsMenu onEdit={onEdit} onDelete={onDelete} noteId={noteId} />
      ) : (
        <Grid container justifyContent="center" alignItems="center" spacing={4}>
          <Grid
            item
            onClick={onDelete}
            className={[classes.noteAction, !isEditAllowed && classes.disabledNoteAction].join(' ')}
            id={`${testIdPrefix}-delete-button`}
          >
            <Trash2 />
          </Grid>
          <Grid
            item
            onClick={onEdit}
            className={[classes.noteAction, !isEditAllowed && classes.disabledNoteAction].join(' ')}
            id={`${testIdPrefix}-edit-button`}
          >
            <Pencil3 />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default NoteItemActions
