import React, { useEffect, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/plain.css'
import GrinLabel from './text/GrinLabel'
import { Grid } from '@material-ui/core'
import DazzedParagraph12 from '../common/text/DazzedParagraph12'

const useStyles = makeStyles(() => ({
  phoneContainer: {
    marginTop: '5px',
    marginBottom: ({ notes }) => (notes ? 0 : 7),
    '&.error': {
      border: '1px solid var(--board-card-color-2)'
    },
    '&.error input:focus': {
      outline: 'none !important'
    }
  },
  subtitle: {
    marginLeft: 8,
    fontSize: 12,
    textTransform: 'none'
  },
  phoneInput: {
    border: ({ style }) =>
      (style.bright ? '1px solid var(--border-color-2)' : '1px solid var(--border-color-6)') + ' !important',
    height: ({ style }) => (style.thick ? '42px' : '30px') + ' !important',
    width: '100% !important',
    color: ({ style }) => (style.bright ? 'var(--text-color-6)' : 'var(--text-color-1)') + ' !important',
    background: ({ style }) => (style.bright ? 'var(--bg-color-10)' : 'var(--bg-color-4)') + ' !important',
    '&::placeholder': {
      fontFamily: 'Dazzed, Arial',
      fontSize: '13px',
      color: ({ style }) => (style.bright ? 'black' : 'var(--text-color-1)'),
      fontStyle: 'italic',
      opacity: 0.3
    },
    '@media (max-width: 960px)': {
      minHeight: '40px !important',
      fontSize: '16px !important'
    }
  },
  phoneCountries: {
    border: ({ style }) =>
      (style.bright ? '1px solid var(--border-color-2)' : '1px solid var(--border-color-6)') + ' !important',
    color: 'black'
  },
  formInputTitle: {
    fontSize: 12,
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
    opacity: ({ style }) => (style.bright ? '1' : '0.5'),
    marginBottom: '5px',
    color: ({ style }) => (style.bright ? 'var(--text-color-2)' : 'var(--text-color-1)')
  },
  notes: {
    color: 'var(--text-color-12)',
    fontSize: 11,
    marginBottom: 5,
    height: 17,
    fontWeight: 'normal'
  },
  label: {
    '@media (max-width: 960px)': {
      fontSize: '14px !important'
    }
  },
  errorMessage: {
    color: 'var(--text-color-10)',
    fontSize: 12,
    marginTop: 2
  }
}))

const PhoneNumberInput = ({
  title,
  subtitle = '',
  notes = null,
  validationRules = {},
  country,
  setCountry = () => {},
  setCountryCode = () => {},
  setPhone = () => {},
  value,
  setValue = () => {},
  required = false,
  style = { bright: false, thick: false },
  titleClassName = '',
  inputClassName = '',
  disabled = false,
  errorMessage = ''
}) => {
  const classes = useStyles({ style, notes })

  const [phoneValue, setPhoneValue] = useState(value)

  useEffect(() => {
    if (value !== phoneValue?.replace(/[^0-9]/gi, '')) {
      setPhoneValue(value)
    }
  }, [value]) //eslint-disable-line

  const handleChange = useCallback(
    (value, country, e, formattedValue) => {
      setCountryCode(country.dialCode)
      setCountry(country.countryCode)
      setValue(value)
      setPhone(value.slice(country.dialCode.length))
      setPhoneValue(formattedValue)
    },
    [setCountry, setCountryCode, setValue, setPhone]
  )

  return (
    <Grid container>
      <Grid container direction="row" alignItems="center">
        {!!title && (
          <Grid item>
            <GrinLabel className={[classes.label, titleClassName].join(' ')}>{`${title}${
              required ? '*' : ''
            }`}</GrinLabel>
          </Grid>
        )}
        {!!subtitle && (
          <Grid item>
            <GrinLabel className={classes.subtitle} color={!style.bright && 'var(--text-color-1)'}>
              {subtitle}
            </GrinLabel>
          </Grid>
        )}
      </Grid>

      <PhoneInput
        country={country || 'us'}
        disableCountryGuess={!!country}
        inputProps={{ required }}
        value={phoneValue}
        onChange={handleChange}
        enableSearch={true}
        containerClass={[classes.phoneContainer, errorMessage ? 'error' : ''].join(' ')}
        inputClass={[classes.phoneInput, inputClassName].join(' ')}
        buttonClass={classes.phoneCountries}
        searchNotFound="No countries found"
        enableTerritories={true}
        disabled={disabled}
      />
      <DazzedParagraph12 className={classes.notes}>{notes}</DazzedParagraph12>
      {errorMessage && <div className={classes.errorMessage}>{errorMessage}</div>}
    </Grid>
  )
}

export default PhoneNumberInput
