import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import MemberCell from './MemberCell'
import { TIME_FORMAT_7 } from 'consts/dateTimeConsts'

const useStyles = ({ disabled }) =>
  makeStyles({
    addedByRow: {
      color: ({ disabled }) => (disabled ? 'var(--text-color-21)' : '#848B9A')
    }
  })({ disabled })

const MemberDateCell = ({ dateAdded, accessType, disabled, addedBy }) => {
  const classes = useStyles({ disabled })
  const { t } = useTranslation()

  return (
    <MemberCell disabled={disabled}>
      <div>{moment(dateAdded).format(TIME_FORMAT_7)}</div>
      <div className={classes.addedByRow}>
        {accessType === 'owner'
          ? t('pages.accountSettings.practiceMembers.types.accountCreator')
          : t('pages.accountSettings.practiceMembers.addedBy', {
              user: addedBy || t('pages.accountSettings.practiceMembers.support')
            })}
      </div>
    </MemberCell>
  )
}

export default MemberDateCell
