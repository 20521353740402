import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import GrinCheckbox from 'components/common/GrinCheckbox'
import FormInput from 'components/common/FormInput'

const useStyles = makeStyles(theme => ({
  checkbox: {
    padding: '4px 4px 4px 0'
  },
  noteInput: {
    margin: '0px!important',
    padding: '0px!important'
  },
  label: {
    fontSize: 14,
    fontWeight: 600
  }
}))

const CheckboxWithNote = ({
  label,
  isChecked = false,
  setIsChecked,
  note = '',
  setNote,
  noteInputPlaceholder = ''
}) => {
  const classes = useStyles()

  return (
    <Grid container alignItems="center">
      <Grid item xs={6}>
        <GrinCheckbox
          onChange={setIsChecked}
          checked={isChecked}
          variant="filled"
          size="small"
          caption={label}
          className={classes.checkbox}
          captionClassName={classes.label}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          value={note}
          setValue={setNote}
          placeholder={noteInputPlaceholder}
          style={{ bright: true, borderBottom: true }}
          keepErrorContainerWhenInactive={false}
          inputWrapperClassName={classes.noteInput}
        />
      </Grid>
    </Grid>
  )
}

export default CheckboxWithNote
