import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import useAutochart from '../AutochartGrid/useAutochart'
import AutochartScanView from './AutochartScanView'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px 40px',
    height: '100%'
  },
  scanViewContainer: {
    height: 'calc(var(--autochart-chart-height) - 8px)'
  }
}))

const AutochartScanTab = () => {
  const classes = useStyles()
  const { grinScan } = useAutochart()

  return (
    <Grid container className={classes.root}>
      {grinScan && (
        <Grid item className={classes.scanViewContainer}>
          <AutochartScanView grinScanVideo={grinScan.video} scanSummaryData={grinScan.scanSummaryData} />
        </Grid>
      )}
    </Grid>
  )
}

export default AutochartScanTab
