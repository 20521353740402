import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { isMobile } from 'utils/mobileUtils'
import Actions from 'actions'
import BaseModal from 'components/common/modals/BaseModal'
import { Pencil } from 'components/common/icons'
import useRolePermissions from 'hooks/useRolePermissions'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import {
  APPLIANCE_SETS_MIN_NUMBER,
  APPLIANCE_SETS_MAX_NUMBER,
  APPLIANCE_MIN_NUMBER,
  APPLIANCE_MAX_NUMBER
} from 'consts/treatmentConsts'

const useStyles = ({ isMobile, applianceTotalNumberError, applianceIndexError }) =>
  makeStyles({
    container: {
      color: 'var(--text-color-11)',
      fontSize: isMobile ? 14 : 16,
      lineHeight: '18px',
      paddingTop: isMobile ? 0 : 2
    },
    modal: {
      padding: '0 30px'
    },
    pencil: {
      marginLeft: 5,
      cursor: 'pointer'
    },
    input: {
      outline: 'none',
      fontSize: 20,
      textAlign: 'center',
      width: 80,
      border: 'none',
      paddingLeft: 14,
      '@media (max-width: 960px)': {
        paddingLeft: 0
      }
    },
    applianceIndex: {
      borderBottom: applianceIndexError ? '1px solid red' : '1px solid var(--border-color-11)'
    },
    applianceTotalNumber: {
      borderBottom: applianceTotalNumberError ? '1px solid red' : '1px solid var(--border-color-11)'
    },
    errorMessage: {
      color: 'red',
      fontSize: 12,
      textAlign: 'center',
      marginTop: 5
    },
    modalText: {
      fontSize: 20
    }
  })()

const ApplianceLabel = ({ changeExpand, scan }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()

  const treatment = useSelector(state => state.patientsReducer.patientCard.treatment.data)

  const [applianceIndex, setApplianceIndex] = useState(null)
  const [applianceTotalNumber, setApplianceTotalNumber] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [errors, setErrors] = useState(null)

  const errorText = useMemo(() => errors && Object.values(errors)[0], [errors])

  const classes = useStyles({
    isMobile: isMobile(),
    applianceTotalNumberError: errors?.applianceTotalNumber,
    applianceIndexError: errors?.applianceIndex
  })

  useEffect(() => {
    setApplianceTotalNumber(treatment?.totalApplianceNumber)
  }, [treatment?.totalApplianceNumber])

  useEffect(() => {
    setApplianceIndex(scan?.applianceIndex)
  }, [scan?.applianceIndex])

  const validateForm = useCallback(() => {
    let errors = {}

    if (applianceTotalNumber < APPLIANCE_SETS_MIN_NUMBER) {
      errors.applianceTotalNumber = t('errors.minSetsAmount', { number: APPLIANCE_SETS_MIN_NUMBER })
    }
    if (applianceTotalNumber > APPLIANCE_SETS_MAX_NUMBER) {
      errors.applianceTotalNumber = t('errors.maxSetsAmount', { number: APPLIANCE_SETS_MAX_NUMBER })
    }
    if (applianceIndex < APPLIANCE_MIN_NUMBER) {
      errors.applianceIndex = t('errors.minAppliance', { number: APPLIANCE_MIN_NUMBER })
    }
    if (applianceIndex > APPLIANCE_MAX_NUMBER) {
      errors.applianceIndex = t('errors.maxAppliance', { number: APPLIANCE_MAX_NUMBER })
    }

    return errors
  }, [applianceIndex, applianceTotalNumber, t])

  const handleClose = useCallback(() => {
    setApplianceIndex(scan.applianceIndex)
    setApplianceTotalNumber(treatment.totalApplianceNumber)
    setErrors(null)
    setIsModalOpen(false)
  }, [scan, treatment])

  const handleSave = useCallback(() => {
    const errors = validateForm()
    if (!isEmpty(errors)) {
      setErrors(errors)
      return
    }

    setIsModalOpen(false)
    setErrors(null)

    dispatch(
      Actions.updateGrinScanAppliance({
        scan: applianceIndex !== scan.applianceIndex && {
          id: scan.id,
          _version: scan._version,
          applianceIndex
        },
        treatment: applianceTotalNumber !== treatment.totalApplianceNumber && {
          id: treatment.id,
          _version: treatment._version,
          totalApplianceNumber: applianceTotalNumber
        }
      })
    )
  }, [applianceIndex, applianceTotalNumber, dispatch, scan, treatment, validateForm])

  return (
    <div onClick={e => e.stopPropagation()}>
      <div className={classes.container}>
        <span onClick={changeExpand}>{`${scan.appliance} ${applianceIndex} ${
          applianceTotalNumber ? t('dialogs.editAppliance.of', { number: applianceTotalNumber }) : ''
        }`}</span>
        {!isMobile() && permissions.editScanAppliance && (
          <Pencil className={classes.pencil} onClick={() => setIsModalOpen(true)} />
        )}
      </div>
      <BaseModal
        open={isModalOpen}
        handleClose={handleClose}
        title={t('dialogs.editAppliance.title')}
        largerButtons
        primaryLabel={t('general.saveChanges')}
        onPrimaryBtnClick={handleSave}
        secondaryLabel={t('general.cancel')}
        onSecondaryBtnClick={handleClose}
        className={classes.modal}
        isPrimaryDisabled={!applianceIndex?.toString() || !applianceTotalNumber}
      >
        <Grid container justifyContent="center" spacing={1} alignItems="center">
          <Grid item>
            <DazzedParagraph16 className={classes.modalText}>
              {t('dialogs.editAppliance.alignersSetNumber')}
            </DazzedParagraph16>
          </Grid>
          <Grid item>
            <input
              value={applianceIndex}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              className={[classes.input, classes.applianceIndex].join(' ')}
              onChange={({ target }) => setApplianceIndex(target.value)}
              min={APPLIANCE_MIN_NUMBER}
              max={APPLIANCE_MAX_NUMBER}
            />
          </Grid>
          <Grid item>
            <DazzedParagraph16>{t('dialogs.editAppliance.of')}</DazzedParagraph16>
          </Grid>
          <Grid item>
            <input
              value={applianceTotalNumber}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              className={[classes.input, classes.applianceTotalNumber].join(' ')}
              onChange={({ target }) => setApplianceTotalNumber(target.value)}
              min={APPLIANCE_SETS_MIN_NUMBER}
              max={APPLIANCE_SETS_MAX_NUMBER}
            />
          </Grid>
        </Grid>
        {errorText && <div className={classes.errorMessage}>{errorText}</div>}
      </BaseModal>
    </div>
  )
}

export default ApplianceLabel
