import React, { Fragment, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@material-ui/icons/Info'

import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { GRIN_SCOPE_TYPE_REGULAR } from 'consts/billingConsts'

const useStyles = makeStyles({
  scopeName: {
    fontSize: '18px',
    fontWeight: 600,
    borderBottom: '1px solid var(--text-color-4)',
    margin: '15px auto',
    padding: '0 10px 5px 10px',
    width: 'fit-content'
  },
  infoIcon: {
    marginLeft: 4,
    transform: 'translateY(3px)'
  },
  controls: {
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 600,
    margin: '0 5px',
    cursor: 'pointer'
  },
  input: {
    height: 33,
    maxWidth: 60,
    alignSelf: 'center',
    outline: 'none',
    textAlign: 'center',
    border: '1px solid var(--text-color-4)'
  },
  currencyPrefix: {
    fontFamily: 'Roboto',
    fontWeight: 500
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  amountContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    minHeight: 21,
    justifyContent: 'center'
  },
  kitWrapper: {
    margin: '10px 0'
  },
  prompt: {
    color: ({ quantityError }) => (quantityError ? 'var(--text-color-17)' : 'var(--text-color-11)'),
    textAlign: 'center',
    maxWidth: 150,
    marginTop: 5
  },
  grinScopeImage: {
    marginRight: 5
  },
  note: {
    marginBottom: 20,
    color: 'red'
  }
})

const OrderGrinKitsItemMobile = ({
  name,
  description,
  image,
  type,
  price,
  hidePrice,
  quantity,
  formErrors,
  onSetQuantity,
  onClickMinusQuantity,
  onClickPlusQuantity,
  maxQuantity,
  setScopeDescriptionPopupType
}) => {
  const { t } = useTranslation()

  const isRegular = useMemo(() => type === GRIN_SCOPE_TYPE_REGULAR, [type])

  const error = useMemo(
    () => (isRegular ? formErrors?.quantity : formErrors?.quantityMini),
    [formErrors?.quantity, formErrors?.quantityMini, isRegular]
  )

  const classes = useStyles({ quantityError: !!error })

  const hasReachedOrderLimit = useMemo(() => quantity === maxQuantity, [maxQuantity, quantity])

  const promptMessage = useMemo(() => {
    const orderMaxLimitText = hasReachedOrderLimit && t('dialogs.orderScopes.maxSubscriptionQuantityError')
    return error || orderMaxLimitText || t('dialogs.orderScopes.quantityMultiplyError')
  }, [error, hasReachedOrderLimit, t])

  return (
    <Fragment>
      <DazzedParagraph16 className={classes.scopeName} strong textAlign="center">
        {name}
        <InfoIcon
          color="disabled"
          fontSize="small"
          className={classes.infoIcon}
          onClick={() => setScopeDescriptionPopupType(type)}
        />
      </DazzedParagraph16>
      <DazzedParagraph14 textAlign="center" strong>
        {description}
      </DazzedParagraph14>
      <Grid container alignItems="center" justifyContent="center" spacing={2} className={classes.kitWrapper}>
        <Grid item xs={4}>
          <img className={classes.grinScopeImage} src={image} alt="Grin scope" />
        </Grid>
        <Grid item xs={6}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item className={classes.inputWrapper}>
              <span className={classes.controls} onClick={onClickMinusQuantity}>
                -
              </span>
              <input
                className={classes.input}
                type="number"
                pattern="[0-9]*"
                value={quantity}
                onChange={({ target }) => {
                  const newValue = isNaN(target.value) ? quantity : target.value.length === 0 ? '' : +target.value
                  onSetQuantity(+newValue)
                }}
              />

              <span className={classes.controls} onClick={onClickPlusQuantity}>
                +
              </span>
            </Grid>
            <Grid item>{<DazzedParagraph12 className={classes.prompt}>{promptMessage}</DazzedParagraph12>}</Grid>
            {!hidePrice && (
              <Grid item className={classes.amountContainer} xs={12}>
                {!error && quantity > 0 && (
                  <>
                    <span className={classes.currencyPrefix}>$</span>
                    <DazzedParagraph12>
                      {t('dialogs.orderScopes.scopesAmountPrice', { price: quantity * price })}
                    </DazzedParagraph12>
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default OrderGrinKitsItemMobile
