import { makeStyles } from '@material-ui/styles'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import NotifyPractice from 'components/common/icons/NotifyPractice'
import NotifyPracticeDisabled from 'components/common/icons/NotifyPracticeDisabled'
import { AsyncStatus } from 'consts'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import NotifyPracticeFloatingPopup from '../PatientsHeader/NotifyPracticeFloatingPopup'

const useStyles = makeStyles(theme => ({
  notifyButton: {
    background: 'transparent'
  }
}))

const NotifyPracticeButton = ({ patientId, isDisabled }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const savingStatus = useSelector(state => state.hiReducer.notifyPractice.savingStatus)
  const practiceName = useSelector(state => state.multiPracticeReducer.context.practiceName)

  const handleNotifyPracticeClicked = useCallback(() => {
    trackEvent('Notify Practice - button clicked', {
      practiceName,
      patientId
    })
    setIsMenuOpen(true)
  }, [patientId, practiceName])

  return (
    <NotifyPracticeFloatingPopup
      isOpen={isMenuOpen}
      onOpen={() => {}}
      onClose={() => setIsMenuOpen(false)}
      patientId={patientId}
      practiceName={practiceName}
      triggerComponent={
        <SecondaryButton
          useContentHeight
          useContentWidth
          disableRipple
          hideBorder
          icon={isDisabled ? <NotifyPracticeDisabled /> : <NotifyPractice />}
          iconPosition="top"
          label={t('pages.patients.selectedPatient.header.notifyPractice')}
          className={classes.notifyButton}
          onClick={handleNotifyPracticeClicked}
          isLoading={savingStatus === AsyncStatus.Loading}
          loaderStyle={{ marginTop: 24 }}
          disabled={isDisabled}
        />
      }
    />
  )
}

export default NotifyPracticeButton
