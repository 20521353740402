import * as React from 'react'

const Dollar = ({ color, ...props }) => {
  return (
    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.66 4.634C2.66 4.87667 2.744 5.07267 2.912 5.222C3.08933 5.362 3.31333 5.46933 3.584 5.544C3.864 5.61867 4.23733 5.698 4.704 5.782C5.47867 5.922 6.118 6.08533 6.622 6.272C7.13533 6.44933 7.574 6.77133 7.938 7.238C8.31133 7.69533 8.498 8.33467 8.498 9.156C8.498 9.884 8.29267 10.5187 7.882 11.06C7.48067 11.6013 6.92533 11.9933 6.216 12.236V14.322H4.914V12.502C4.82067 12.5113 4.67133 12.516 4.466 12.516C4.26067 12.516 4.06 12.502 3.864 12.474V14.322H2.548V12.124C1.848 11.816 1.26933 11.368 0.812 10.78C0.364 10.192 0.0933333 9.52467 0 8.778L2.296 8.498C2.408 9.09533 2.66933 9.576 3.08 9.94C3.5 10.304 4.01333 10.486 4.62 10.486C5.05867 10.486 5.43667 10.3787 5.754 10.164C6.07133 9.94 6.23 9.66 6.23 9.324C6.23 9.02533 6.132 8.78733 5.936 8.61C5.74 8.42333 5.49267 8.288 5.194 8.204C4.89533 8.11067 4.48933 8.01733 3.976 7.924C3.21067 7.77467 2.59 7.616 2.114 7.448C1.638 7.27067 1.22733 6.972 0.882 6.552C0.546 6.12267 0.378 5.52533 0.378 4.76C0.378 4.13467 0.569333 3.57933 0.952 3.094C1.344 2.60867 1.876 2.24467 2.548 2.002V0H3.864V1.736C4.088 1.71733 4.26533 1.708 4.396 1.708C4.51733 1.708 4.69 1.71733 4.914 1.736V0H6.216V2.058C6.87867 2.338 7.42467 2.744 7.854 3.276C8.29267 3.808 8.56333 4.396 8.666 5.04L6.328 5.278C6.188 4.78333 5.93133 4.39133 5.558 4.102C5.18467 3.81267 4.718 3.668 4.158 3.668C3.71 3.668 3.346 3.75667 3.066 3.934C2.79533 4.11133 2.66 4.34467 2.66 4.634Z"
        fill={color}
      />
    </svg>
  )
}

export default Dollar
