import { makeStyles, Backdrop, CircularProgress } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import PatientsTable from './Table/PatientsTable'
import Actions from '../../actions'
import ROUTES from '../../consts/routesConsts'
import { isOnFreePlan } from '../../utils/billingUtils'
import useHideHelpWidget from '../../hooks/useHideHelpWidget'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: ({ isTrial }) => (isTrial ? 'calc(100% - 80px - 64px)' : 'calc(100% - 80px)'),
    display: 'flex',
    overflowY: 'hidden'
  },
  loader: {
    zIndex: 9999,
    backgroundColor: '#f5f5f590',
    color: 'var(--border-color-5)'
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
}))

const TaskManager = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  useHideHelpWidget()

  const userPlan = useSelector(state => state.practiceReducer.billing.grinPlan)
  const doctor = useSelector(state => state.profileReducer.doctor)
  const isWelcomeDoctorModalOpen = useSelector(state => state.profileReducer.showWelcomePopup)
  const showAppLoader = useSelector(state => state.patientsReducer.overlayLoader)

  const classes = useStyles({ isTrial: isOnFreePlan(userPlan) })

  useEffect(() => {
    const checkIfSignedIn = async () => {
      try {
        await Auth.currentAuthenticatedUser()
      } catch (err) {
        history.push(ROUTES.WELCOME)
      }
    }

    checkIfSignedIn()
  }, [location, history, dispatch])

  useEffect(() => {
    doctor.id &&
      dispatch(
        Actions.requestPatientsForTaskManager({
          newSearch: true,
          showLoader: true,
          updateTaskCategoriesSideBar: true
        })
      )
  }, [dispatch, doctor.id])

  if (showAppLoader) {
    return (
      <Backdrop className={classes.loader} open={showAppLoader}>
        <CircularProgress color="inherit" size={60} />
      </Backdrop>
    )
  }
  return isWelcomeDoctorModalOpen ? null : (
    <Fragment>
      <div className={classes.container}>
        {doctor.id ? (
          <PatientsTable />
        ) : (
          <div className={classes.loaderContainer}>
            <CircularProgress />
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default TaskManager
