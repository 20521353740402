import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import SwooshLargeIcon from 'components/common/icons/SwooshLarge1'
import OggHeading40 from 'components/common/text/OggHeading40'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  signUpSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  content: {
    width: 470,
    '& > div': {
      color: 'var(--text-color-1)'
    }
  },
  title: {
    marginBottom: 50
  },
  text: {
    marginBottom: 50
  },
  contactUs: {
    '& a': {
      color: 'var(--text-color-10)',
      textDecoration: 'underline'
    }
  }
})

const DeactivatedAccount = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <SwooshLargeIcon className={classes.signUpSwoosh} />
        <OggHeading40 className={classes.title} textAlign="left">
          {t('pages.authPracticeMembers.deactivated.deactivatedAccount')}
        </OggHeading40>
        <DazzedParagraph16 className={classes.text}>
          {t('pages.authPracticeMembers.deactivated.text')}
        </DazzedParagraph16>
        <DazzedParagraph16>{t('pages.authPracticeMembers.deactivated.anyQuestions')}</DazzedParagraph16>
        <DazzedParagraph16 className={classes.contactUs}>
          {t('pages.authPracticeMembers.deactivated.contactUs')}&nbsp;
          <a href="mailto:support@get-grin.com">{t('general.emailGetGrinSupport')}</a>
        </DazzedParagraph16>
      </div>
    </div>
  )
}

export default DeactivatedAccount
