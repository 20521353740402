import _ from 'lodash'
import Actions from 'actions'
import { IMAGE_MODES } from 'consts/scanSummaryConsts'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { drawBackgroundWithLayers } from 'utils/canvasUtils'
import { logError } from 'utils/logUtils'
import { downloadMedia, loadImage } from 'utils/mediaUtils'

export default ({
  patientName,
  scanNumber,
  imagesMode = IMAGE_MODES.normal,
  parsedScanSummaryData = {},
  selectedPoses = [],
  editedImages = [],
  displayedPreventiveLayers
}) => {
  const dispatch = useDispatch()

  const activeImagesSet = useMemo(() => parsedScanSummaryData?.[imagesMode] || {}, [parsedScanSummaryData, imagesMode])

  const dispatchDownloadImages = useCallback(
    ({ files, isLocal }) => {
      dispatch(
        Actions.downloadPatientFilesZip({
          name: `${patientName}-scan-${scanNumber}-${imagesMode}`,
          files,
          isLocal
        })
      )
    },
    [dispatch, patientName, scanNumber, imagesMode]
  )

  const downloadPreventiveImages = useCallback(async () => {
    try {
      const preventiveLayersImagesSet = parsedScanSummaryData[IMAGE_MODES.preventiveLayers]
      const displayedCanvas = document.getElementById('preventive-layers-canvas')
      let images = []

      for (const pose of selectedPoses) {
        let layersImages = []
        let canvas = document.createElement('canvas')
        canvas.height = displayedCanvas.height
        canvas.width = displayedCanvas.width

        const backgroundImage = await loadImage(await downloadMedia(parsedScanSummaryData[IMAGE_MODES.normal][pose]))

        for (let layer of displayedPreventiveLayers) {
          layersImages.push(await loadImage(await downloadMedia(preventiveLayersImagesSet[pose][layer])))
        }

        drawBackgroundWithLayers({
          canvas,
          backgroundImage,
          layersImages
        })

        images.push({
          data: canvas.toDataURL(),
          key: `${pose}.png`,
          outputFilename: pose
        })
      }

      dispatchDownloadImages({ files: images, isLocal: true })
    } catch (err) {
      logError('An error occured while trying to prepare preventive layers for download', err)
      trackEvent('Scan summary - Download preventive images failed')
      dispatch(Actions.downloadPatientFilesZipFailed())
    }
  }, [selectedPoses, parsedScanSummaryData, dispatch, dispatchDownloadImages, displayedPreventiveLayers])

  const downloadNormalImages = useCallback(() => {
    const [editedPoses, originalPoses] = _.partition(
      selectedPoses,
      currSelectedPose => !!editedImages[currSelectedPose]
    )

    const files = editedPoses
      .map(editedImageToDownload => ({
        isLocal: true,
        data: editedImages[editedImageToDownload],
        filename: editedImageToDownload,
        key: `${editedImageToDownload}.png`
      }))
      .concat(
        originalPoses.map(pose => {
          const fileS3Object = activeImagesSet[pose]
          return {
            key: fileS3Object.key,
            bucket: fileS3Object.bucket,
            outputFilename: pose
          }
        })
      )

    dispatchDownloadImages({ files })
  }, [dispatchDownloadImages, editedImages, selectedPoses, activeImagesSet])

  const downloadImages = useCallback(async () => {
    const isAnyPreventiveLayerVisible = displayedPreventiveLayers?.length > 0

    if (isAnyPreventiveLayerVisible) {
      await downloadPreventiveImages()
    } else {
      downloadNormalImages()
    }
  }, [displayedPreventiveLayers, downloadNormalImages, downloadPreventiveImages])

  return downloadImages
}
