import { GuidelineCategories } from 'consts/hiToolsConsts'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AlignersTxTracking,
  BracesTxTracking,
  Elastics,
  IPR,
  OralHygiene,
  Other,
  RPE,
  TxGoals
} from 'components/common/icons'
import PracticeGuidelineGenericCategory from './PracticeGuidelineGenericCategory'
import usePatientBriefViewer from '../PatientBrief/usePatientBriefViewer'
import { isEmpty } from 'lodash'

export default ({ categoriesData }) => {
  const { t } = useTranslation()

  const { latestPatientBrief } = usePatientBriefViewer()

  const categories = useMemo(
    () =>
      [
        {
          key: GuidelineCategories.AlignersTxTracking,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.alignersTxTracking.title'),
          icon: <AlignersTxTracking />,
          component: PracticeGuidelineGenericCategory
        },
        {
          key: GuidelineCategories.BracesTxTracking,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.bracesTxTracking.title'),
          icon: <BracesTxTracking />,
          component: PracticeGuidelineGenericCategory
        },
        {
          key: GuidelineCategories.TxGoals,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.txGoals.title'),
          icon: <TxGoals />,
          component: PracticeGuidelineGenericCategory
        },
        {
          key: GuidelineCategories.Elastics,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.elastics.title'),
          icon: <Elastics />,
          component: PracticeGuidelineGenericCategory
        },
        {
          key: GuidelineCategories.IPR,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.IPR.title'),
          icon: <IPR />,
          component: PracticeGuidelineGenericCategory
        },
        {
          key: GuidelineCategories.RPE,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.RPE.title'),
          icon: <RPE />,
          component: PracticeGuidelineGenericCategory
        },
        {
          key: GuidelineCategories.OralHygiene,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.oralHygiene.title'),
          icon: <OralHygiene />,
          component: PracticeGuidelineGenericCategory
        },
        {
          key: GuidelineCategories.OtherAppliancesAndGuidelines,
          title: t('dialogs.hiPatientGuidelinesViewer.categories.otherAppliancesAndGuidelines.title'),
          icon: <Other />,
          component: PracticeGuidelineGenericCategory
        }
      ].filter(
        category =>
          categoriesData?.[category.key]?.isEnabled &&
          (!isEmpty(latestPatientBrief.categories)
            ? latestPatientBrief?.categories?.[category.key]?.isEnabled
            : true) &&
          (categoriesData?.[category.key]?.items || categoriesData?.[category.key]?.note)
      ),
    [categoriesData, latestPatientBrief, t]
  )

  return {
    categories
  }
}
