import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.main,
    fontFamily: 'Dazzed',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '40px'
  }
}))

const DazzedHeading18 = ({ className, children, ...props }) => {
  const classes = useStyles()

  return (
    <div className={[classes.heading, className].join(' ')} {...props}>
      {children}
    </div>
  )
}

export default DazzedHeading18
