import React, { useCallback, useEffect } from 'react'
import BaseModal from '../common/modals/BaseModal'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ROUTES } from 'consts'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import Actions from 'actions'
import { ReferralsSessionKey, ReferralsTokenKey } from 'consts/referralConsts'

const SessionExpiredModal = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const { isOpen } = useSelector(state => state.referralsReducer.sessionExpiredModal)

  const handleRefresh = useCallback(() => {
    trackEvent('Referrals - Session expired modal - refresh clicked')
    dispatch(Actions.referralsToggleSessionExpiredModal(false))
    sessionStorage.removeItem(ReferralsSessionKey)
    sessionStorage.removeItem(ReferralsTokenKey)
    history.push(`${ROUTES.REFERRAL}${window.location.search}`)
  }, [dispatch, history])

  useEffect(() => {
    if (isOpen) {
      trackEvent('Referrals - Session expired modal - opened')
    }
  }, [isOpen])

  return (
    <BaseModal
      open={isOpen}
      onPrimaryBtnClick={handleRefresh}
      title={t('pages.referrals.sessionExpiredModal.title')}
      primaryLabel={t('pages.referrals.sessionExpiredModal.buttonLabel')}
      handleClose={handleRefresh}
      variant="alert"
    />
  )
}

export default SessionExpiredModal
