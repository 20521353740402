import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { makeStyles, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Actions from '../../../../../../actions'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import config from '../../../../../../utils/awsUtils'

import { Plus1, Trash } from '../../../../../common/icons'
import headerScanGif from '../../../../../../resources/RC_header_scan.gif'
import ImageUploaderWithCrop from '../../../../ImageUploaderWithCrop'
import { getCloudFrontUrl } from '../../../../../../utils/storageUtils'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  headerImageContainer: {
    flex: 1,
    position: 'relative'
  },
  changeBannerPhotoContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0,
    transition: '0.3s',
    '&:hover': {
      opacity: 1
    }
  },
  changeBannerPhotoContent: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  changeBannerPhotoButton: {
    border: '1px solid white',
    padding: '5px 12px',
    borderRadius: '25px',
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  changeBannerPhotoNote: {
    fontSize: '8px',
    marginTop: 5,
    textAlign: 'center'
  },
  plusIcon: {
    marginRight: 5
  },
  trashIcon: {
    width: '13px',
    marginRight: 5
  },
  headerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  hiddenElement: {
    display: 'none'
  }
}))

const HeaderPreviewBannerPhoto = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    rcConfiguration: { rcLPBannerPhoto }
  } = usePracticeConfiguration()
  const [isBannerPhotoLoading, setIsBannerPhotoLogoLoading] = useState(false)

  useEffect(() => {
    setIsBannerPhotoLogoLoading(false)
  }, [rcLPBannerPhoto])

  const rcLpBannerPhotoLabelId = useMemo(() => 'rcLpBanner', [])

  const updateRcLpBanner = useCallback(
    key => {
      dispatch(
        Actions.requestUpdateAppSettings({
          rcLPBannerPhoto: key[0]
            ? {
                key: key[0],
                bucket: config.aws_user_files_s3_bucket,
                region: config.aws_user_files_s3_bucket_region
              }
            : null
        })
      )
    },
    [dispatch]
  )

  const handleClickDeleteBannerPhoto = useCallback(() => {
    setIsBannerPhotoLogoLoading(true)
    updateRcLpBanner([])
  }, [updateRcLpBanner])

  const uploadImageContent = useMemo(() => {
    return (
      <label htmlFor={rcLpBannerPhotoLabelId}>
        <div className={classes.changeBannerPhotoButton}>
          <Plus1 className={classes.plusIcon} />
          {t('pages.accountSettings.services.remoteConsultation.uploadImage')}
        </div>
        <div className={classes.changeBannerPhotoNote}>
          {t('pages.accountSettings.services.remoteConsultation.imageSize')}
        </div>
      </label>
    )
  }, [classes.plusIcon, classes.changeBannerPhotoButton, classes.changeBannerPhotoNote, rcLpBannerPhotoLabelId, t])

  const deleteImageContent = useMemo(() => {
    return (
      <div className={classes.changeBannerPhotoButton} onClick={handleClickDeleteBannerPhoto}>
        <Trash className={classes.trashIcon} />
        {t('pages.accountSettings.services.remoteConsultation.deleteImage')}
      </div>
    )
  }, [classes.changeBannerPhotoButton, classes.trashIcon, handleClickDeleteBannerPhoto, t])

  const cloudFrontUrl = getCloudFrontUrl()
  const bannerPhotoUrl = useMemo(
    () => (rcLPBannerPhoto ? `${cloudFrontUrl}/rc-lp-banners/${rcLPBannerPhoto.key}` : headerScanGif),
    [rcLPBannerPhoto, cloudFrontUrl]
  )

  return (
    <>
      <div className={classes.headerImageContainer}>
        <img src={bannerPhotoUrl} className={classes.headerImage} alt="Remote Consultation" />
        <div className={classes.changeBannerPhotoContainer}>
          <div className={classes.changeBannerPhotoContent}>
            {isBannerPhotoLoading ? (
              <CircularProgress color="secondary" />
            ) : rcLPBannerPhoto ? (
              deleteImageContent
            ) : (
              uploadImageContent
            )}
          </div>
        </div>
      </div>
      <div className={classes.hiddenElement}>
        <ImageUploaderWithCrop
          inputId={rcLpBannerPhotoLabelId}
          onUploadDone={updateRcLpBanner}
          onStartLoading={isLoading => {
            trackEvent('RC design - upload image - ok clicked', { imageType: 'side image' })
            setIsBannerPhotoLogoLoading(isLoading)
          }}
          onCancelClicked={() => trackEvent('RC design - upload image - cancel clicked', { imageType: 'side image' })}
          onUploadClicked={() => trackEvent('RC design - upload image clicked', { imageType: 'side image' })}
          circularCrop={false}
          keyWithExtension
          s3Folder="rc-lp-banners"
          keepOriginalSize
          allDimentionsCropAllowed
        />
      </div>
    </>
  )
}

export default HeaderPreviewBannerPhoto
