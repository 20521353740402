import { makeStyles } from '@material-ui/core'
import { isMobile } from 'utils/mobileUtils'
import { useHistory } from 'react-router'
import Logo from 'resources/logo.svg'
import ChristmasLogo from 'resources/grinChristmasLogo.svg'
import { ROUTES } from 'consts'

const isChristmasTheme = false

const useStyles = makeStyles({
  appLogo: {
    width: isMobile() ? '120px' : '160px',
    height: isMobile() ? '60px' : '100%',
    background: 'var(--primary-color)',
    borderRadius: '0px 0px 40px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    '& :hover': {
      cursor: 'pointer'
    }
  },
  img: {
    height: isChristmasTheme ? (isMobile() ? 60 : 'auto') : isMobile() ? 23 : 35,
    marginRight: isChristmasTheme ? (isMobile() ? 0 : 0) : isMobile() ? 20 : 0
  },
  christmasLogoImg: {
    width: '100%'
  }
})

const AppLogo = () => {
  const classes = useStyles()
  const { push } = useHistory()
  return (
    <div className={classes.appLogo} onClick={() => push(ROUTES.PATIENTS)}>
      <img src={isChristmasTheme ? ChristmasLogo : Logo} alt="Logo" className={classes.img} />
    </div>
  )
}

export default AppLogo
