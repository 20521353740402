import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import ScanFeedCard from './ScanFeedCard'
import { ScanFeedPageSectionNames } from 'consts/timelineConsts'
import { TreatmentTrackOptions } from 'consts/giConsts'
import OnTrackGrinScanItem from 'components/common/icons/timelineV2/mini-timeline/OnTrackGrinScanItem'
import OffTrackGrinScanItem from 'components/common/icons/timelineV2/mini-timeline/OffTrackGrinScanItem'
import GiConditionList from 'components/Patients/Chat/MessageTypes/GI/GiConditionsList'
import GrinChip from 'components/common/GrinChip'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: ({ trackStatus }) => (trackStatus === TreatmentTrackOptions.OnTrack ? 8 : 24)
  },
  trackStatusIcon: {
    marginRight: 8,
    lineHeight: 0.8
  }
}))

const ScanTrackingCard = ({ trackStatus = '', conditions = [], notes = '' }) => {
  const classes = useStyles({ trackStatus })
  const { t } = useTranslation()

  return (
    <ScanFeedCard
      id={ScanFeedPageSectionNames.ScanTracking}
      title={t(`pages.patients.selectedPatient.timelineV2.trackStatus.${trackStatus}`)}
      titleClassName={classes.title}
      titleIcon={
        <div className={classes.trackStatusIcon}>
          {trackStatus === TreatmentTrackOptions.OnTrack ? <OnTrackGrinScanItem /> : <OffTrackGrinScanItem />}
        </div>
      }
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <GiConditionList conditions={conditions} />
        </Grid>
        {notes && (
          <Grid item>
            <GrinChip text={notes} />
          </Grid>
        )}
      </Grid>
    </ScanFeedCard>
  )
}

export default ScanTrackingCard
