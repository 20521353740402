import * as React from 'react'

const SeekerPlay = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6309 26.2381V8.33325L25.5568 17.2857L11.6309 26.2381Z" fill="#4151E6" />
    <circle cx="16.668" cy="17" r="16" stroke="#4151E6" />
  </svg>
)

export default SeekerPlay
