import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ViewModeEye } from 'components/common/icons'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'

const useStyles = ({ isBanner }) =>
  makeStyles({
    deactivatedContainer: {
      height: isBanner && 'var(--patient-banner-height)',
      width: '100%',
      padding: isBanner ? 0 : '20px 30px 20px 30px',
      backgroundColor: isBanner ? '#E3E3E3' : ''
    },
    deactivatedUser: {
      width: '100%',
      padding: 10,
      background: !isBanner && '#F2F3F5',
      border: !isBanner && '1px solid rgba(30, 30, 30, 0.24)',
      borderRadius: isBanner ? 0 : 10,
      color: '#EB5757',
      fontSize: 12
    },
    disabledPatientMessage: {
      color: isBanner ? '#19191999 !important' : '#898484 !important'
    }
  })({ isBanner })

const ViewMode = ({ isBanner }) => {
  const classes = useStyles({ isBanner })
  const { t } = useTranslation()

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.deactivatedContainer}>
      <Grid item style={{ width: '100%' }}>
        <Grid
          container
          direction={'row'}
          justifyContent="center"
          alignItems="center"
          className={classes.deactivatedUser}
          spacing={1}
        >
          <Grid item>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
              <ViewModeEye />
              <Grid item>
                <DazzedParagraph12 thickness={'bold'} className={classes.disabledPatientMessage}>
                  {t('pages.patients.selectedPatient.chat.patientDisabledMessageViewMode')}
                </DazzedParagraph12>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <DazzedParagraph12 className={classes.disabledPatientMessage}>
              {t('pages.patients.selectedPatient.chat.patientDisabledMessage')}
            </DazzedParagraph12>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ViewMode
