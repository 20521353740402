import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { useCountry } from 'hooks/useCountry'
import FormInput from '../FormInput'
import { Grid } from '@material-ui/core'
import SelectInput from '../SelectInput'
import { COUNTRY_CA, COUNTRY_IN } from 'consts/countryConsts'

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%'
  }
}))

const BillingAddressForm = ({ values, onChange = () => {}, theme = 'bright' }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { countriesOptions, states, getDefaultState, getZipCodeProperties, hasZipcode } = useCountry(values.country)
  const { placeholder } = useMemo(() => getZipCodeProperties(values.country), [getZipCodeProperties, values])

  const handleFieldChange = useCallback(
    field => {
      onChange({
        ...values,
        ...field
      })
    },
    [values, onChange]
  )

  return (
    <Grid container direction="column" className={classes.form}>
      <Grid item>
        <FormInput
          title={t('dialogs.changeAddress.addressLabel')}
          value={values.address1}
          setValue={value => handleFieldChange({ address1: value })}
          style={{ bright: theme === 'bright', thick: true }}
          validationRules={{ required: true }}
          allowAutoComplete={false}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <FormInput
              title={t('dialogs.changeAddress.cityLabel')}
              value={values.city}
              setValue={value => handleFieldChange({ city: value })}
              style={{ bright: theme === 'bright', thick: true }}
              validationRules={{ required: true }}
              allowAutoComplete={false}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            {values.country === COUNTRY_IN ? (
              <FormInput
                title={t('dialogs.changeAddress.stateLabel')}
                value={values.state}
                setValue={value => handleFieldChange({ state: value })}
                style={{ bright: theme === 'bright', thick: true }}
                allowAutoComplete={false}
              />
            ) : (
              <SelectInput
                label={
                  values.country === COUNTRY_CA
                    ? t('dialogs.changeAddress.provinceLabel')
                    : t('dialogs.changeAddress.stateLabel')
                }
                style={{ bright: theme === 'bright', thick: true }}
                value={states.find(state => [state.name, state.key].includes(values.state))?.key || values.state}
                values={states.map(state => state.key)}
                required={!!states.length}
                disabled={!states.length}
                keepErrorContainerWhenInactive={false}
                onChange={entry =>
                  handleFieldChange({
                    state: entry.value
                  })
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <SelectInput
              label={t('dialogs.changeAddress.countryLabel')}
              style={{ bright: theme === 'bright', thick: true }}
              value={values.country}
              options={countriesOptions}
              required
              keepErrorContainerWhenInactive={false}
              onChange={({ value }) => handleFieldChange({ country: value, state: getDefaultState(value).key })}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <FormInput
              title={t('dialogs.changeAddress.zipLabel')}
              style={{ bright: theme === 'bright', thick: true }}
              placeholder={placeholder}
              value={values.zip}
              setValue={value => handleFieldChange({ zip: value })}
              keepErrorContainerWhenInactive={false}
              validationRules={{
                required: hasZipcode
              }}
              isDisabled={!hasZipcode}
            />
          </Grid>
        </Grid>
      </Grid>

      {values.country === COUNTRY_IN && (
        <Grid item>
          <FormInput
            title={t('dialogs.changeAddress.detailedAddressLabel')}
            value={values.address2}
            setValue={value => handleFieldChange({ address2: value })}
            style={{ bright: theme === 'bright', thick: true }}
            validationRules={{ required: true }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default BillingAddressForm
