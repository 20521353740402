import React from 'react'
import { getObjectUrl } from 'utils/mediaUtils'

const VideoWrapper = ({ s3Object, isLocal, cache, muted, className, autoplay }) => {
  return (
    <video controls preload="" muted={muted} className={className} autoPlay={autoplay}>
      <source src={isLocal ? URL.createObjectURL(cache) : getObjectUrl(s3Object)} type="video/mp4" />
    </video>
  )
}

export default VideoWrapper
