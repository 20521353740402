import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import defaultTranslations from './webapp-en'
import HttpApi from 'i18next-http-backend'
import moment from 'moment'
import { getEnvironment } from 'utils/awsUtils'
import 'moment/locale/es'

const defaultLang = localStorage.getItem('language') || 'en'
const env = getEnvironment()
moment.locale(defaultLang)

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: defaultTranslations
      },
      es: {
        translations: {}
      }
    },
    lng: defaultLang,
    fallbackLng: 'en',
    ns: ['translations'],
    fallbackNS: 'translations',
    defaultNS: 'translations',
    // debug: true,
    keySeparator: '.',
    interpolation: { escapeValue: false }
  })
  .then(() => {
    if (env === 'production') {
      new HttpApi().loadUrl(`https://d3pwxdice7vmvq.cloudfront.net/webapp-${i18n.language}.json`, (err, res) => {
        i18n.addResourceBundle(i18n.language, 'translations', res, true, true)
      })
    }
  })

export default i18n
