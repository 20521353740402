const ROOT = '/'
const UNAVAILABLE = '/unavailable'
const AUTH = '/auth'
const AUTH_RC = '/auth/rc/:rcToken'
const AUTH_RC_SUCCESS = '/auth/rc/success'
const AUTH_RC_FAIL = '/auth/rc/fail'
const AUTH_RC_BASIC = '/auth/rc'
const AUTH_RC_UNAVAILABLE = '/auth/rc/unavailable'

const WELCOME = `${AUTH}/welcome`
const SIGN_IN = `${AUTH}/sign-in`
const MFA = `${AUTH}/mfa`
const SIGN_UP = `${AUTH}/sign-up`
const RESET_PASSWORD = `${AUTH}/reset-password`
const SET_PASSWORD = `${AUTH}/set-password`
const PERSONAL_DETAILS = `${AUTH}/personal-details`
const PRACTICE_INFO = `${AUTH}/practice-info`
const EMAIL_NOT_VERIFIED = `${AUTH}/email-not-verified`
const TERMS = `${AUTH}/terms`
const BILLING_INFO = `${AUTH}/billing-info`
const CONFIRMATION = `${AUTH}/confirmation`

const AUTH_PRACTICE_MEMBERS = `${AUTH}/practice-members`
const AUTH_PRACTICE_MEMBERS_SIGN_UP = `${AUTH_PRACTICE_MEMBERS}/sign-up`
const AUTH_PRACTICE_MEMBERS_DETAILS = `${AUTH_PRACTICE_MEMBERS}/details`
const AUTH_PRACTICE_MEMBERS_PHOTO = `${AUTH_PRACTICE_MEMBERS}/photo`
const AUTH_PRACTICE_MEMBERS_DEACTIVATED = `${AUTH_PRACTICE_MEMBERS}/deactivated`
const AUTH_PRACTICE_MEMBERS_TERMS = `${AUTH_PRACTICE_MEMBERS}/terms`

const QUICK_SIGN_UP = '/quickSignUp'
const QUICK_SIGN_UP_PASSWORD = `${QUICK_SIGN_UP}/password`
const QUICK_SIGN_UP_PRACTICE_INFO = `${QUICK_SIGN_UP}/practice-info`
const QUICK_SIGN_UP_PERSONAL_DETAILS = `${QUICK_SIGN_UP}/personal-details`
const QUICK_SIGN_UP_TERMS = `${QUICK_SIGN_UP}/terms`
const QUICK_SIGN_UP_BILLING_INFO = `${QUICK_SIGN_UP}/billing-info`

const TASK_MANAGER = '/task-manager'
const RC_DASHBOARD = '/rc-dashboard'

const GI_DASHBOARD = `/gi-dashboard`
const PATIENTS = `/patients`
const INVITE_PATIENT = '/invite-patient'
const DDX = `/ddx`
const PROFILE = `/profile`
const DOC_STATS = '/stats'
const NEW_DOC_STATS = '/new-stats'
const PATIENT_TIMELINE = `timeline`
const PATIENT_CHAT = `chat`
const PATIENT_NOTES = `notes`
const ASSETS = '/assets'
const PMS = '/pms'

const BROADCAST = `${PATIENTS}/broadcast`
const SUPPORT_DASHBOARD = `/support-dashboard`
const SUPPORT_DASHBOARD_PICKER = `${SUPPORT_DASHBOARD}/picker`
const DOCTORS_AND_MEMBERS_DASHBOARD = `${SUPPORT_DASHBOARD}/doctors-and-members`
const PATIENTS_DASHBOARD = `${SUPPORT_DASHBOARD}/patients`
const ALLOWED_DOCTORS = `${SUPPORT_DASHBOARD}/allowed-doctors`
const RELEASE_NOTES_DASHBOARD = `${SUPPORT_DASHBOARD}/release-notes`
const RELEASE_NOTES_EDITOR = `${SUPPORT_DASHBOARD}/release-notes/editor`
const STATS_DASHBOARD = `${SUPPORT_DASHBOARD}/stats`
const PROMOTIONS_DASHBOARD = `${SUPPORT_DASHBOARD}/promotions`
const ARTICLES_DASHBOARD = `${SUPPORT_DASHBOARD}/articles`
const ANNOUNCEMENTS_DASHBOARD = `${SUPPORT_DASHBOARD}/announcements`
const OPS_ACTIONS = `${SUPPORT_DASHBOARD}/ops-actions`
const OPS_BILLING_DASHBOARD = `${SUPPORT_DASHBOARD}/billing`
const SYSTEM_AUTOMATIONS_DASHBOARD = `${SUPPORT_DASHBOARD}/system-automations`
const HI_PRACTICES_DASHBOARD = `${SUPPORT_DASHBOARD}/hi-practices`

const ACCOUNT_SETTINGS = `${PROFILE}/account-settings`

const PASSWORD = `${ACCOUNT_SETTINGS}/password`
const BILLING = `${ACCOUNT_SETTINGS}/billing`
const BILLING_SUBSCRIPTION = `${BILLING}/subscription`
const BILLING_PAYMENT_HISTORY = `${BILLING}/transactions`
const ACCOUNT = `${ACCOUNT_SETTINGS}/account`
const NOTIFICATION_SETTINGS = `${ACCOUNT_SETTINGS}/notification-settings`
const SERVICES = `${ACCOUNT_SETTINGS}/services`
const AUTOMATIONS = `${ACCOUNT_SETTINGS}/automations`
const SAVED_REPLIES = `${ACCOUNT_SETTINGS}/saved-replies`
const CUSTOM_MESSAGES = `${ACCOUNT_SETTINGS}/custom-messages`
const PRACTICE_PROFILE = `${ACCOUNT_SETTINGS}/practice-profile`
const PRACTICE_MEMBERS = `${ACCOUNT_SETTINGS}/practice-members`
const ABOUT = `${ACCOUNT_SETTINGS}/about`
const PRACTICE_MEMBERS_ADD_MEMBER = `${PRACTICE_MEMBERS}/add`
const ADVANCED_ACTIONS = `${ACCOUNT_SETTINGS}/advanced-actions`
const PRACTICE_GUIDELINES = `${ACCOUNT_SETTINGS}/practice-guidelines`

const WHITENING = '/whitening'
const WHITENING_DASHBOARD = `${WHITENING}/dashboard`
const WHITENING_LANDING_PAGE = `${WHITENING}/landing-page`

const PMS_REDIRECT_LANDING_PAGE = `${PMS}/landing`

const REFERRAL = `/referral`
const REFERRAL_LOGIN = `/referral/login`
const REFERRAL_VIEW_SCAN = `/referral/scan`

const UPLOAD_PROVIDER_STLS = `/uploadProviderStls`

export default {
  ROOT,
  UNAVAILABLE,
  AUTH,
  WELCOME,
  SIGN_IN,
  MFA,
  SIGN_UP,
  SET_PASSWORD,
  RESET_PASSWORD,
  TASK_MANAGER,
  RC_DASHBOARD,
  GI_DASHBOARD,
  PATIENTS,
  INVITE_PATIENT,
  DDX,
  ALLOWED_DOCTORS,
  PROFILE,
  SUPPORT_DASHBOARD,
  SUPPORT_DASHBOARD_PICKER,
  DOCTORS_AND_MEMBERS_DASHBOARD,
  PATIENTS_DASHBOARD,
  RELEASE_NOTES_DASHBOARD,
  RELEASE_NOTES_EDITOR,
  PROMOTIONS_DASHBOARD,
  ARTICLES_DASHBOARD,
  ANNOUNCEMENTS_DASHBOARD,
  OPS_ACTIONS,
  STATS_DASHBOARD,
  ASSETS,
  ACCOUNT_SETTINGS,
  ABOUT,
  NOTIFICATION_SETTINGS,
  PASSWORD,
  AUTOMATIONS,
  BILLING,
  BILLING_SUBSCRIPTION,
  BILLING_PAYMENT_HISTORY,
  PERSONAL_DETAILS,
  PRACTICE_INFO,
  EMAIL_NOT_VERIFIED,
  TERMS,
  CONFIRMATION,
  BILLING_INFO,
  ACCOUNT,
  SERVICES,
  SAVED_REPLIES,
  CUSTOM_MESSAGES,
  PRACTICE_PROFILE,
  PRACTICE_MEMBERS,
  PRACTICE_MEMBERS_ADD_MEMBER,
  DOC_STATS,
  NEW_DOC_STATS,
  AUTH_RC,
  AUTH_RC_BASIC,
  AUTH_RC_SUCCESS,
  AUTH_RC_FAIL,
  AUTH_RC_UNAVAILABLE,
  PATIENT_CHAT,
  PATIENT_NOTES,
  PATIENT_TIMELINE,
  QUICK_SIGN_UP,
  QUICK_SIGN_UP_PASSWORD,
  QUICK_SIGN_UP_PRACTICE_INFO,
  QUICK_SIGN_UP_PERSONAL_DETAILS,
  QUICK_SIGN_UP_TERMS,
  QUICK_SIGN_UP_BILLING_INFO,
  AUTH_PRACTICE_MEMBERS,
  AUTH_PRACTICE_MEMBERS_SIGN_UP,
  AUTH_PRACTICE_MEMBERS_DETAILS,
  AUTH_PRACTICE_MEMBERS_PHOTO,
  AUTH_PRACTICE_MEMBERS_DEACTIVATED,
  AUTH_PRACTICE_MEMBERS_TERMS,
  BROADCAST,
  WHITENING_DASHBOARD,
  WHITENING_LANDING_PAGE,
  OPS_BILLING_DASHBOARD,
  ADVANCED_ACTIONS,
  SYSTEM_AUTOMATIONS_DASHBOARD,
  PMS_REDIRECT_LANDING_PAGE,
  REFERRAL,
  REFERRAL_LOGIN,
  REFERRAL_VIEW_SCAN,
  PRACTICE_GUIDELINES,
  UPLOAD_PROVIDER_STLS,
  HI_PRACTICES_DASHBOARD
}
