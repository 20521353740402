import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from '../../common/modals/BaseModal'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import FrequencyPicker from '../../common/inputs/FrequencyPicker'
import { getPeriodTypeOptions, ScanFrequencyOptions } from '../../../utils/statusUtils'
import ArchivedStatusMessage from './ArchivedStatusMessage'
import SelectedStatus from './SelectedStatus'
import { isMobile } from 'utils/mobileUtils'
import TreatmentTypePicker from '../PatientCard/TreatmentTypePicker'
import { StatusTypes } from '@grin-rnd/grin-api-sdk/dist/Enums/Statuses'
import AlignersSets from '../PatientCard/AlignersSets'
import { ALIGNERS_TREATMENT_TYPE } from 'consts/treatmentConsts'

const useStyles = makeStyles(() => ({
  root: {
    width: '750px',
    maxWidth: '750px'
  },
  subtitle: {
    fontWeight: 600,
    fontSize: '14px',
    width: '100%'
  },
  radioGroup: {
    justifyContent: 'center',
    flexDirection: isMobile() ? 'column' : 'row'
  },
  archivedMessage: {
    width: '350px'
  }
}))

const UpdateStatusModal = ({
  isOpen,
  selectedStatus,
  onCancel = () => {},
  onSubmit = () => {},
  onClose = () => {},
  isLoading = false
}) => {
  const classes = useStyles({ isMobile: isMobile() })
  const { t } = useTranslation()

  const [didSubmit, setDidSubmit] = useState(false)
  const [status, setStatus] = useState(null)
  const [selectedFrequency, setSelectedFrequency] = useState('')
  const [selectedCycleIntervalPeriodType, setSelectedCycleIntervalPeriodType] = useState('')
  const [treatmentType, setTreatmentType] = useState()
  const [totalAlignersSets, setTotalAlignersSets] = useState('')

  const statusSettings = useMemo(() => JSON.parse(status?.settings || '{}'), [status])
  const frequencyOptions = useMemo(() => ScanFrequencyOptions[status?.type], [status])

  useEffect(() => {
    if (isOpen) {
      setStatus(selectedStatus)
    }
  }, [selectedStatus, isOpen])

  const scanFrequencyDefaults = useMemo(() => JSON.parse(status?.scanFrequencyDefaults || '{}'), [status])

  const periodTypeOptions = useMemo(() => getPeriodTypeOptions(selectedStatus?.key), [selectedStatus])

  const subtitleText = useMemo(() => {
    if (!status?.hasLimitedScans) {
      return t('statuses.menu.subtitles.unlimited')
    }

    if (status?.maxScansPerPeriod > 0) {
      return t('statuses.menu.subtitles.limited', { maxScansPerPeriod: status.maxScansPerPeriod })
    }

    return t('statuses.menu.subtitles.scansNotAvailable')
  }, [status, t])

  const handleSubmit = useCallback(() => {
    setDidSubmit(true)
    onSubmit({
      cycleInterval: selectedFrequency,
      cycleIntervalPeriodType: selectedCycleIntervalPeriodType,
      treatmentType,
      totalAlignersSets
    })
  }, [onSubmit, selectedCycleIntervalPeriodType, selectedFrequency, totalAlignersSets, treatmentType])

  useEffect(() => {
    if (isOpen && didSubmit && !isLoading) {
      onClose()
    }
  }, [didSubmit, isLoading, isOpen, onClose])

  useEffect(() => {
    if (!isOpen) {
      setDidSubmit(false)
    }
  }, [isOpen])

  useEffect(() => {
    setSelectedFrequency(scanFrequencyDefaults.periodLength)
    setSelectedCycleIntervalPeriodType(scanFrequencyDefaults.periodType)
  }, [scanFrequencyDefaults])

  useEffect(() => {
    if (treatmentType !== ALIGNERS_TREATMENT_TYPE) {
      setTotalAlignersSets(null)
    }
  }, [treatmentType])

  return (
    <BaseModal
      open={isOpen}
      primaryLabel={isMobile() ? t('general.save') : t('statuses.updateStatusModal.primaryButton')}
      secondaryLabel={t('statuses.updateStatusModal.secondaryButton')}
      onSecondaryBtnClick={onCancel}
      onPrimaryBtnClick={handleSubmit}
      isPrimaryDisabled={status?.type === StatusTypes.ActiveTreatment && !treatmentType}
      isLoading={isLoading}
      withCloseIcon
      handleClose={onCancel}
      className={classes.root}
      largerButtons={!isMobile()}
      title={t('statuses.updateStatusModal.title')}
      variant={isMobile() ? 'fullscreen' : undefined}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <SelectedStatus type={status?.type} color={statusSettings.color} subtitleText={subtitleText} />
            </Grid>
            {status?.type === StatusTypes.ActiveTreatment && (
              <>
                <Grid item xs={10}>
                  <TreatmentTypePicker
                    treatmentType={treatmentType}
                    onSet={value => {
                      setTreatmentType(value)
                    }}
                    tooltip={false}
                    placeholder
                  />
                </Grid>
                {treatmentType === ALIGNERS_TREATMENT_TYPE && (
                  <Grid item xs={10}>
                    <AlignersSets alignersSets={totalAlignersSets} setAlignersSets={setTotalAlignersSets} />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
        {status?.type !== 'archived' ? (
          <Grid item xs={12}>
            <Grid container>
              <DazzedParagraph16 className={classes.subtitle} textAlign={isMobile() ? 'start' : 'center'}>
                {t('statuses.updateStatusModal.subtitle')}
              </DazzedParagraph16>
              <FrequencyPicker
                radioGroupClassName={classes.radioGroup}
                periodType={selectedCycleIntervalPeriodType}
                defaultPeriodType={scanFrequencyDefaults.periodType}
                setPeriodType={setSelectedCycleIntervalPeriodType}
                options={frequencyOptions}
                value={selectedFrequency}
                setValue={setSelectedFrequency}
                periodTypeOptions={periodTypeOptions}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ArchivedStatusMessage className={classes.archivedMessage} status={status} />
          </Grid>
        )}
      </Grid>
    </BaseModal>
  )
}

export default UpdateStatusModal
