import React from 'react'
import Note from './PracticeGuidelines/Note'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 15px 8px 15px',
    backgroundColor: 'var(--bg-color-74)'
  }
}))

const CategoryItems = ({ categoryComponent, items, note }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {React.createElement(categoryComponent, {
        items
      })}
      {note && (
        <div style={{ width: '100%' }}>
          <Note note={note} />
        </div>
      )}
    </div>
  )
}

export default CategoryItems
