export const createNoteMetadata = ({ state, assigneeId }) =>
  JSON.stringify({
    creatorName: state.profileReducer.doctor.name,
    creatorRoleDescription: state.profileReducer.doctor.roleDescription,
    creatorUserId: state.profileReducer.doctor.user.id,
    assigneeName: state.profileReducer.practiceMembers?.data?.find(pm => pm.id === assigneeId)?.name
  })

export const mapToCreateNoteInput = ([note, state]) => {
  return {
    body: note,
    grinUserId: state.patientsReducer.patientCard.patient.user.id,
    a_doctor: state.patientsReducer.patientCard.patient.a_doctor,
    metadata: createNoteMetadata({ state })
  }
}

export const mapToNoteAnalyticsPayload = ([note, state]) => {
  return {
    doctorId: state.profileReducer.doctor.id,
    doctorPracticeRole: state.profileReducer.doctor.roleDescription,
    doctorName: state.profileReducer.doctor.name,
    practiceName: state.practiceReducer.details.practiceName,
    patientUserId: note.grinUserId,
    noteId: note.id
  }
}
