import { updateWhere } from 'utils/arrayUtils'
import Actions from '../actions'

const initialState = {
  announcements: [],
  surveyResultError: null,
  surveyResultSuccess: null,
  isLoadingSurveyResults: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.ANNOUNCEMENTS_RECEIVED:
      return {
        ...state,
        announcements: action.payload
      }
    case Actions.TOGGLE_ANNOUNCEMENT_OPEN:
      return {
        ...state,
        announcements: updateWhere(
          state.announcements,
          announcement => announcement.id === action.payload,
          announcement => (announcement.isOpen = !announcement.isOpen)
        )
      }
    case Actions.RESET_SURVEY_RESULT:
      return {
        ...state,
        surveyResultError: null,
        surveyResultSuccess: null,
        isLoadingSurveyResults: false
      }
    case Actions.SUBMIT_SURVEY_RESULT:
      return {
        ...state,
        isLoadingSurveyResults: true
      }
    case Actions.SUBMIT_SURVEY_RESULT_RECEIVED:
      return {
        ...state,
        surveyResultError: null,
        surveyResultSuccess: true,
        isLoadingSurveyResults: false
      }
    case Actions.SUBMIT_SURVEY_RESULT_FAILED:
      return {
        ...state,
        surveyResultError: 'announcements.survey.submitResponseError',
        isLoadingSurveyResults: false
      }
    default:
      return state
  }
}
