import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import ProgramChip from 'components/Patients/ProgramChip'
import WelcomeMessageConfirmationModal from './WelcomeMessageConfirmationModal'
import Actions from 'actions'
import { scrollBar } from 'styles/theme'
import { trackEvent } from 'utils/analyticsUtils'
import { TEMPLATES_TYPES } from 'consts/templatesConsts'
import { REMOTE_CONSULTATION_PROGRAM, REMOTE_MONITORING_PROGRAM, SERVICE_ACCOUNT_PROGRAM } from 'consts/appConsts'

const useStyles = makeStyles(theme => ({
  welcomeMessageContainer: {
    width: '100%',
    maxWidth: 700,
    paddingRight: 20
  },
  welcomeMessageInputWrapper: {
    borderRadius: 10,
    border: '1px solid #ABB0B8',
    marginTop: 20,
    height: 120,
    width: '100%',
    overflowY: 'hidden',
    position: 'relative'
  },
  welcomeMessageInput: {
    boxSizing: 'border-box',
    overflowY: 'auto',
    border: 0,
    borderRadius: 10,
    width: '100%',
    height: 120,
    fontFamily: 'Dazzed',
    fontSize: 14,
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.2px',
    textAlign: 'left',
    padding: '15px 26px 32px 20px',
    resize: 'none',
    whiteSpace: 'pre-wrap',
    '&:focus': {
      outline: 'none'
    },
    ...scrollBar
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  charactersCountInfo: {
    marginTop: 10,
    color: 'var(--text-color-14)',
    '&.exceeded': {
      color: 'var(--text-color-17)'
    }
  },
  welcomeMessageButtonGroup: {
    marginTop: 20,
    marginBottom: 70
  },
  welcomeMessageButton: {
    marginRight: 15
  },
  backToDefaultButton: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'var(--text-color-3)',
    paddingRight: 15
  }
}))

const WELCOME_MESSAGE_MAX_LENGTH = 600

const WelcomeMessage = ({ program }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { isLoading } = useSelector(state => state.profileReducer.welcomeMessages[program])
  const { data } = useSelector(state => state.profileReducer.welcomeMessages)
  const accountOwner = useSelector(state => state.practiceReducer.accountOwner)

  const [welcomeMessageText, setWelcomeMessageText] = useState('')
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isModified, setIsModified] = useState(false)

  const welcomeMessage = useMemo(
    () =>
      data?.find(
        welcomeMessage =>
          welcomeMessage.type ===
          `${TEMPLATES_TYPES.WELCOME_MESSAGE}${program === REMOTE_MONITORING_PROGRAM ? '' : program.toUpperCase()}`
      ),
    [data, program]
  )

  const defaultWelcomeMessage = useMemo(
    () =>
      program === REMOTE_CONSULTATION_PROGRAM
        ? t('pages.accountSettings.welcomeMessage.rcPlaceholder', { doctorName: accountOwner.name })
        : program === SERVICE_ACCOUNT_PROGRAM
        ? t('pages.accountSettings.welcomeMessage.serviceAccountPlaceholder', {
            practiceName: accountOwner.clinic?.practiceName
          })
        : t('pages.accountSettings.welcomeMessage.placeholder', { doctorName: accountOwner.name }),
    [accountOwner, program, t]
  )

  const originalText = useMemo(() => {
    return welcomeMessage?.text || defaultWelcomeMessage
  }, [defaultWelcomeMessage, welcomeMessage?.text])

  const shouldDisableButtons = useMemo(
    () => originalText === welcomeMessageText || isLoading,
    [isLoading, originalText, welcomeMessageText]
  )

  const shouldDisplayBackToDefault = useMemo(
    () => defaultWelcomeMessage !== welcomeMessageText && !isLoading,
    [defaultWelcomeMessage, isLoading, welcomeMessageText]
  )

  const handleMessageChange = useCallback(({ target }) => {
    setWelcomeMessageText(target.value)
    setIsModified(true)
  }, [])

  const handleSubmit = useCallback(() => {
    welcomeMessage?.id
      ? dispatch(
          Actions.updateWelcomeMessage({ ...welcomeMessage, text: welcomeMessageText, program: program.toUpperCase() })
        )
      : dispatch(Actions.createWelcomeMessage({ text: welcomeMessageText, program: program.toUpperCase() }))
    trackEvent(`Custom message - message ${data?.id ? 'updated' : 'created'} `, {
      content: welcomeMessageText,
      doctorId: accountOwner.id,
      program
    })
    setIsConfirmationModalOpen(false)
    setIsModified(false)
  }, [data?.id, dispatch, welcomeMessage, welcomeMessageText, program, accountOwner.id])

  const handleOpenConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(true)
    welcomeMessageText !== originalText &&
      trackEvent('Custom welcome message - confirmation modal opened', { content: welcomeMessage, program })
  }, [welcomeMessageText, originalText, welcomeMessage, program])

  const handleBackToDefault = useCallback(() => setWelcomeMessageText(defaultWelcomeMessage), [defaultWelcomeMessage])

  useEffect(() => {
    if (!isLoading && data && accountOwner.name && !isModified) {
      setWelcomeMessageText(originalText)
    }
  }, [isLoading, originalText, data, accountOwner.name, isModified])

  return (
    <div className={classes.welcomeMessageContainer}>
      <DazzedParagraph14 strong className={classes.subtitle}>
        {t('pages.accountSettings.welcomeMessage.programDescription')}
        <ProgramChip program={program} />
      </DazzedParagraph14>
      <div className={classes.welcomeMessageInputWrapper}>
        <textarea
          id="account-settings-welcome-message-textarea"
          className={classes.welcomeMessageInput}
          type="text"
          name="message-content"
          value={welcomeMessageText}
          column={15}
          rows={15}
          maxLength={WELCOME_MESSAGE_MAX_LENGTH}
          autoComplete="off"
          onChange={handleMessageChange}
          disabled={isLoading}
        />
        {isLoading && (
          <div className={classes.loader}>
            <CircularProgress size={40} />
          </div>
        )}
      </div>
      <Grid container justifyContent="space-between">
        <Grid item>
          <DazzedParagraph12
            className={[
              classes.charactersCountInfo,
              welcomeMessageText.length === WELCOME_MESSAGE_MAX_LENGTH ? 'exceeded' : ''
            ].join(' ')}
          >
            {t('pages.accountSettings.welcomeMessage.charactersCount', {
              length: welcomeMessageText.length,
              maxLength: WELCOME_MESSAGE_MAX_LENGTH
            })}
          </DazzedParagraph12>
        </Grid>
        <Grid item className={classes.welcomeMessageButtonGroup}>
          {shouldDisplayBackToDefault && (
            <span
              id="account-settings-welcome-message-back-to-default"
              onClick={handleBackToDefault}
              className={classes.backToDefaultButton}
            >
              {t('general.backToDefault')}
            </span>
          )}
          <SecondaryButton
            id="account-settings-welcome-message-discard-changes"
            small
            label={t('general.discard')}
            disabled={shouldDisableButtons}
            onClick={() => setWelcomeMessageText(originalText)}
            wrapperClassName={classes.welcomeMessageButton}
          />
          <PrimaryButton
            id="account-settings-welcome-message-open-modal"
            type="button"
            small
            label={t('general.save')}
            disabled={!welcomeMessageText || shouldDisableButtons}
            onClick={handleOpenConfirmationModal}
          />
        </Grid>
      </Grid>
      <WelcomeMessageConfirmationModal
        isOpen={isConfirmationModalOpen}
        content={welcomeMessageText}
        accountOwner={accountOwner}
        handleClose={() => setIsConfirmationModalOpen(false)}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  )
}

export default WelcomeMessage
