import React from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import { getInitials, getEmailPrefix } from '../../utils/generalUtils'
import CachedImage from './CachedImage'
import { isMobile } from 'utils/mobileUtils'
import Tooltip from './Tooltip'

const useProfilePictureStyles = makeStyles(() => ({
  avatar: {
    borderRadius: '50%',
    padding: 20
  },
  profilePicture: {
    borderRadius: '50%',
    backgroundColor: 'transparent'
  },
  admin: {
    background: 'var(--bg-color-4)',
    fontFamily: 'Dazzy',
    fontWeight: 'bold',
    fontSize: 28
  },
  icon: {
    height: 25,
    width: 25,
    fontSize: 12,
    padding: 0
  },
  tiny: {
    height: 28,
    width: 28,
    fontSize: 14
  },
  small: {
    height: 40,
    width: 40
  },
  medium: {
    height: '48px !important',
    width: '48px !important'
  },
  large: {
    height: '58px !important',
    width: '58px !important',
    marginRight: 'unset'
  },
  largeBig: {
    height: '64px !important',
    width: '64px !important'
  },
  big: {
    height: '70px',
    width: '70px'
  },
  huge: {
    height: '100px',
    width: '100px'
  },
  placeholderImage: {
    backgroundColor: 'var(--bg-color-15)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 4
  },
  avatarContainer: {
    borderRadius: '50%'
  }
}))

const ProfilePicture = ({ photo, name, email, size = 'medium', placeholderImage, className, tooltip = false }) => {
  const classes = useProfilePictureStyles()

  if (photo) {
    return (
      <CachedImage
        s3Object={photo}
        alt={name}
        applyClassName={`${classes.profilePicture} ${classes[size]} ${className}`}
      />
    )
  }

  if (placeholderImage && !isMobile()) {
    return (
      <div
        className={`${classes.profilePicture} ${size !== 'custom' ? classes[size] : ''}
        ${classes.placeholderImage}
        ${className}`}
      >
        {placeholderImage}
      </div>
    )
  }

  return (
    <Tooltip value={name} variant="dark" arrow placement="top" disableTooltip={!tooltip}>
      <div className={classes.avatarContainer}>
        <Avatar className={`${classes.avatar} ${size !== 'custom' ? classes[size] : ''}  ${className}`}>
          {getInitials(name || getEmailPrefix(email))}
        </Avatar>
      </div>
    </Tooltip>
  )
}

export default ProfilePicture
