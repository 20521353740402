import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: 'Ogg',
    fontSize: 40,
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '56px',
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  dark: {
    color: 'var(--text-color-1)'
  }
}))

const OggHeading40 = ({ children, textAlign = 'center', className, dark = false }) => {
  const classes = useStyles({})

  return (
    <div className={[classes.text, dark ? classes.dark : '', className].join(' ')} style={{ textAlign }}>
      {children}
    </div>
  )
}

export default OggHeading40
