import * as React from 'react'

const Urgent = props => {
  return (
    <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="4.00146" y="18.7931" width="18.4286" height="3.70689" rx="1.27093" stroke="#F25700" />
      <path
        d="M6.43726 12.7562C6.43726 9.0126 9.47202 5.97784 13.2156 5.97784V5.97784C16.9591 5.97784 19.9939 9.0126 19.9939 12.7562V18.7931H6.43726V12.7562Z"
        stroke="#F25700"
      />
      <path
        d="M8.66138 16.6906V12.6345C8.66138 10.1193 10.7004 8.08032 13.2156 8.08032V8.08032"
        stroke="#F25700"
        strokeLinecap="round"
      />
      <path d="M23.8069 13.2858H25.5015" stroke="#F25700" strokeLinecap="round" />
      <path d="M0.929932 13.2858H2.62451" stroke="#F25700" strokeLinecap="round" />
      <path d="M13.2156 2.69458L13.2156 1" stroke="#F25700" strokeLinecap="round" />
      <path d="M18.5112 4.11353L19.3585 2.64598" stroke="#F25700" strokeLinecap="round" />
      <path d="M7.07275 2.646L7.92004 4.11355" stroke="#F25700" strokeLinecap="round" />
      <path d="M22.3877 7.99017L23.8552 7.14288" stroke="#F25700" strokeLinecap="round" />
      <path d="M2.57617 7.14288L4.04372 7.99017" stroke="#F25700" strokeLinecap="round" />
    </svg>
  )
}

export default Urgent
