import * as React from 'react'

const LoginEmail = ({ color = 'black' }) => {
  return (
    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.23375 1.00021C3.35806 1.00021 1 3.32967 1 6.23396C1 9.13825 3.35817 11.4677 6.23375 11.4677C8.47679 11.4677 10.4034 10.0299 11.1513 8.04559L12.4453 8.04559L13.5381 6.75158L14.6309 8.04559L14.6885 8.04559L15.7813 6.75158L16.8741 8.04559L16.9317 8.04559L18.0245 6.75158L19.1173 8.04559L21.1297 6.23386L19.203 4.42212L11.151 4.42212C10.4321 2.43785 8.5054 1 6.23371 1L6.23375 1.00021ZM3.24293 6.98164C2.84035 6.57905 2.84035 5.91769 3.24293 5.48623C3.64552 5.08364 4.30689 5.08364 4.73834 5.48623C5.14093 5.88882 5.14093 6.55018 4.73834 6.98164C4.33576 7.38422 3.64553 7.38422 3.24293 6.98164Z"
        stroke={color}
      />
    </svg>
  )
}

export default LoginEmail
