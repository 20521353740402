import React from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  title: {
    color: 'var(--text-color-11)'
  }
}))

const TitleWithText = ({ title, text }) => {
  const classes = useStyles()

  return (
    <Grid container direction="column">
      <Grid item>
        <DazzedParagraph14 bold className={classes.title}>
          {title}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <DazzedParagraph14>{text}</DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default TitleWithText
