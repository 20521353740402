import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ isNotPatient, highlight }) => ({
  messageInfo: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 10,
    marginBottom: 3
  },
  userName: {
    color: ({ isNotPatient }) => (isNotPatient ? 'inherit' : 'var(--text-color-3)'),
    fontWeight: 500
  },
  circle: {
    margin: '0 6px',
    fontSize: 3,
    color: 'rgba(0, 0, 0, 0.3)',
    verticalAlign: 'middle'
  },
  userRole: {
    textTransform: 'capitalize'
  }
}))

const MessageUserInfo = ({ name, userType, className = '' }) => {
  const isNotPatient = useMemo(() => userType !== 'patient', [userType])
  const classes = useStyles({ isNotPatient })

  return (
    <div className={[classes.messageInfo, className].join(' ')}>
      <span className={classes.userName} title={name}>
        {name}
      </span>
      {userType && (
        <>
          <span className={classes.circle}>●</span>
          <span className={classes.userRole}>{userType}</span>
        </>
      )}
    </div>
  )
}

export default MessageUserInfo
