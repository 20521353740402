export const PMS_INTEGRATIONS = {
  dolphin: 'dolphin'
}

export const APPOINTMENT_MODES = {
  new: 'new',
  view: 'view'
}

export const HAS_STATIC_DATA = [PMS_INTEGRATIONS.dolphin]

export default {
  PMS_INTEGRATIONS
}
