import * as React from 'react'

const ArrowRight = props => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.5 8.5H18" stroke="white" />
    <path d="M11 1L18.5 8.5L11 16" stroke="white" />
  </svg>
)

export default ArrowRight
