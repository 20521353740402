import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import SmallDotIcon from 'components/common/icons/SmallDot'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 4,
    marginLeft: 2
  },
  colorDot: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '5px 0'
  },
  body: {
    paddingLeft: '8px',
    '&.disabled *': {
      color: 'var(--text-color-12)!important'
    },
    '&.selected *': {
      color: 'var(--text-color-3)!important'
    }
  },
  title: {
    fontSize: 13,
    color: 'var(--text-color-11)'
  },
  bold: {
    fontWeight: 600
  }
}))

const PatientStatus = ({ status, textClassName = '', size = 'medium', bold = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const statusSettings = useMemo(() => JSON.parse(status?.settings || '{}'), [status])

  return (
    <div className={classes.root}>
      <div className={classes.colorDot}>
        <SmallDotIcon color={statusSettings.color} />
      </div>
      <div className={[classes.body, !status?.isManualAssignmentAllowed ? 'disabled' : ''].join(' ')}>
        {size === 'medium' ? (
          <DazzedParagraph12 className={[classes.title, textClassName].join(' ')}>
            {t(`statuses.names.${status?.type || 'unset'}`)}
          </DazzedParagraph12>
        ) : (
          <DazzedParagraph14 className={`${classes.title} ${textClassName} ${bold ? classes.bold : ''}`}>
            {t(`statuses.names.${status?.type || 'unset'}`)}
          </DazzedParagraph14>
        )}
      </div>
    </div>
  )
}

export default PatientStatus
