import * as React from 'react'

const LayerHidden = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 9.15002C17 9.15002 13.4183 14.15 9 14.15C4.58172 14.15 1 9.15002 1 9.15002C1 9.15002 4.58172 4.15002 9 4.15002C13.4183 4.15002 17 9.15002 17 9.15002Z"
        stroke="#4151E6"
        strokeWidth="1.25"
      />
      <circle cx="9" cy="9.15002" r="2.5" stroke="#4151E6" strokeWidth="1.25" />
      <path d="M3 4.5L15.5 17" stroke="white" strokeWidth="3" />
      <path d="M3.5 3L16 15.5" stroke="#4151E6" strokeWidth="1.25" />
    </svg>
  )
}

export default LayerHidden
