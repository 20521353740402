import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Title from '../Title'

import HeaderPreviewDetails from './HeaderPreviewDetails'
import HeaderPreviewBannerPhoto from './HeaderPreviewBannerPhoto'

const useStyles = makeStyles(theme => ({
  header: {
    minHeight: '210px',
    width: '100%',
    display: 'flex',
    backgroundColor: ({ headerBackgroundColor }) => headerBackgroundColor,
    flexDirection: 'row',
    borderBottomRightRadius: '100px',
    overflow: 'hidden'
  }
}))

const HeaderPreview = ({ headerBackgroundColor, headerFontColor, accentColor }) => {
  const { t } = useTranslation()
  const classes = useStyles({
    headerBackgroundColor
  })

  return (
    <>
      <Title label={t('pages.accountSettings.services.remoteConsultation.setBannerPhoto')} optional />
      <div className={classes.header}>
        <HeaderPreviewDetails headerFontColor={headerFontColor} accentColor={accentColor} />
        <HeaderPreviewBannerPhoto />
      </div>
    </>
  )
}

export default HeaderPreview
