import React from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import ScanDateLabel from '../../ScanDateLabel'

const useStyles = makeStyles(theme => ({
  noteContainer: {
    borderRadius: 8,
    background: 'rgba(242, 243, 245, 1)',
    padding: 24,
    width: '100%',
    margin: '10px 0'
  },
  dateLabel: {
    textAlign: 'right!important'
  }
}))

const ScanReferralNote = ({ title = '', note = '', date }) => {
  const classes = useStyles()

  return (
    <div>
      <DazzedParagraph12 thickness="strong">{title}</DazzedParagraph12>
      <div className={classes.noteContainer}>{note}</div>
      <ScanDateLabel className={classes.dateLabel} timestamp={date} color="rgba(114, 123, 140, 1)" withDaysAgo />
    </div>
  )
}

export default ScanReferralNote
