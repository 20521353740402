import * as React from 'react'

function SvgPreview(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 18V6C18 4.89543 17.1046 4 16 4H10.8284C10.298 4 9.78929 4.21071 9.41421 4.58579L8 6L6.58579 7.41421C6.21071 7.78929 6 8.29799 6 8.82843V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18Z"
        stroke="#727B8C"
      />
      <path d="M10 4V8H6.5" stroke="#727B8C" />
    </svg>
  )
}

export default SvgPreview
