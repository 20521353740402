import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import DeleteIcon from 'components/common/icons/Close7x7'
import { ORTHO_TEETH_LIST } from 'consts/teethConsts'

const useStyles = makeStyles(theme => ({
  inputField: {
    borderBottom: '2px solid var(--border-color-24)',
    flexWrap: 'wrap'
  },
  chip: {
    background: 'var(--bg-color-23)',
    borderRadius: 8,
    height: 23
  },
  chipLabel: {
    fontSize: 14,
    fontWeight: 400,
    paddingLeft: 8,
    paddingRight: 6
  },
  chipDeleteIcon: {
    width: '9px',
    height: '9px',
    margin: '0 8px 0 0'
  }
}))

const TeethListInput = ({ values = [], setValues, disabledTeeth = [], disableCloseOnSelect = false }) => {
  const classes = useStyles()

  const [inputValue, setInputValue] = useState('')

  return (
    <Autocomplete
      multiple
      autoHighlight={!!inputValue}
      filterSelectedOptions
      options={ORTHO_TEETH_LIST}
      value={values.sort((a, b) => Number(a) - Number(b))}
      onChange={(e, values) => setValues(values)}
      disableCloseOnSelect={disableCloseOnSelect}
      getOptionDisabled={option => disabledTeeth.includes(option)}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          style={{ borderBottom: '0px' }}
          InputProps={{ ...params.InputProps, disableUnderline: true, className: classes.inputField }}
        />
      )}
      getOptionLabel={option => option.toString()}
      inputValue={inputValue}
      onInputChange={(e, value) => setInputValue(value)}
      ChipProps={{
        classes: { root: classes.chip, label: classes.chipLabel },
        deleteIcon: <DeleteIcon className={classes.chipDeleteIcon} />
      }}
    />
  )
}

export default TeethListInput
