import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { TrackingConditions, TrackingSelectionModes } from 'consts/giConsts'
import GrinCheckbox from 'components/common/GrinCheckbox'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import TeethListInput from './TeethListInput'

const useStyles = makeStyles(theme => ({
  checkbox: {
    padding: 4
  }
}))

const TrackingConditionOption = ({
  conditionId,
  checked = false,
  onToggle = () => {},
  selections = [],
  onSelectionsChanged = () => {},
  untrackedTeeth = []
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const conditionData = useMemo(() => TrackingConditions[conditionId], [conditionId])

  const handleCheckboxToggle = useCallback(
    checked => {
      onToggle({ conditionId, checked })
    },
    [onToggle, conditionId]
  )

  const handleSelectionsChanged = useCallback(
    values => {
      onSelectionsChanged({ conditionId, selections: values })
    },
    [onSelectionsChanged, conditionId]
  )

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <GrinCheckbox
              checked={checked}
              onChange={handleCheckboxToggle}
              variant="filled"
              className={classes.checkbox}
            />
          </Grid>
          <Grid item>
            <DazzedParagraph14 strong>
              {t(`dialogs.scanSummary.treatmentTracking.conditions.${conditionId}`)}
            </DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
      {checked && conditionData.selectionMode === TrackingSelectionModes.Teeth && (
        <Grid item>
          <TeethListInput
            values={selections}
            setValues={handleSelectionsChanged}
            disabledTeeth={untrackedTeeth}
            disableCloseOnSelect
          />
        </Grid>
      )}
    </Grid>
  )
}

export default TrackingConditionOption
