import { IconButton, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React, { useCallback, useState } from 'react'
import BaseSlideDesigner from './BaseSlideDesigner'
import SlideBulletDesginer from './SlideBulletDesginer'
import SlideTextareaInput from './SlideTextareaInput'
import SlideTitle from './SlideTitle'
import SlideTitleInput from './SlideTitleInput'

const useStyles = makeStyles(theme => ({
  bullets: {
    padding: '0 0 0 25px',
    marginBottom: 30,
    backgroundColor: '#fff'
  },
  title: {
    fontSize: 40,
    lineHeight: '40px',
    marginBottom: 30,
    textAlign: 'center'
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '24px',
    marginBottom: 30
  },
  bulletItem: {
    marginBottom: 6,
    fontSize: 16
  },
  bulletTitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600
  },
  bulletDescription: {
    lineHeight: '24px'
  },
  bulletsEditContainer: {
    borderRadius: 5,
    border: '1px solid #ccc',
    padding: '10px 10px 10px 25px',
    marginBottom: 20,
    backgroundColor: '#fff',
    zIndex: 1
  },
  bulletsEditorActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textLimit: {
    marginBottom: 10,
    color: 'red'
  }
}))

const BulletsSlideDesigner = ({
  onChange,
  data,
  onMediaPicked,
  isUploading,
  mediaS3Object,
  shouldShowErrors,
  onMediaRemoved,
  editMode,
  slideNumber,
  isHeadlineDisabled,
  totalSlides,
  onNextSlide,
  onPreviousSlide,
  hasPrevious,
  onClose
}) => {
  const classes = useStyles()
  const [textLimitReached, setTextLimitReached] = useState(false)

  const handleTextLimitReached = () => {
    setTextLimitReached(true)
    setTimeout(() => {
      setTextLimitReached(false)
    }, 3000)
  }

  const setTitle = title => {
    onChange({
      ...data,
      title
    })
  }

  const setSubtitle = subtitle => {
    if (subtitle.length <= 260 || subtitle.length < data.subtitle.length) {
      onChange({
        ...data,
        subtitle
      })
    } else {
      handleTextLimitReached()
    }
  }

  const setBullets = useCallback(
    bullets => {
      onChange({
        ...data,
        bullets
      })
    },
    [onChange, data]
  )

  const addBullet = useCallback(() => {
    setBullets(
      data.bullets.concat({
        title: '',
        description: ''
      })
    )
  }, [data.bullets, setBullets])

  const handleBulletRemove = useCallback(
    index => {
      const updatedBullets = [...data.bullets]
      updatedBullets.splice(index, 1)
      setBullets(updatedBullets)
    },
    [data.bullets, setBullets]
  )

  const handleBulletUpdate = useCallback(
    (i, bulletData) => {
      const updatedBullets = [...data.bullets]
      updatedBullets[i] = bulletData
      setBullets(updatedBullets)
    },
    [data.bullets, setBullets]
  )

  return (
    <BaseSlideDesigner
      isUploading={isUploading}
      isSlideDisabled={data.isDisabled}
      onMediaPicked={onMediaPicked}
      mediaS3Object={mediaS3Object}
      onMediaRemoved={onMediaRemoved}
      editMode={editMode}
      slideNumber={slideNumber}
      totalSlides={totalSlides}
      onNextSlide={onNextSlide}
      onPreviousSlide={onPreviousSlide}
      hasPrevious={hasPrevious}
      onClose={onClose}
    >
      {editMode ? (
        <>
          <SlideTitleInput
            placeholder="Slide headline *"
            onChange={setTitle}
            value={data.title}
            errorMessage={shouldShowErrors && !data.title && 'Required field'}
          />
          <SlideTextareaInput
            placeholder="Add a description to this slide (optional)"
            value={data.subtitle}
            onChange={setSubtitle}
          />

          {textLimitReached && (
            <div className={classes.textLimit}>Can't add additional text due to length limit (260)</div>
          )}

          <div className={classes.bulletsEditContainer}>
            <ul className={classes.bullets}>
              {data.bullets.map((bullet, i) => (
                <li key={i}>
                  <SlideBulletDesginer
                    data={bullet}
                    onChange={data => handleBulletUpdate(i, data)}
                    onDelete={() => handleBulletRemove(i)}
                    shouldShowErrors={shouldShowErrors}
                  />
                </li>
              ))}
            </ul>
            <div className={classes.bulletsEditorActionsContainer}>
              <IconButton onClick={addBullet}>
                <AddIcon />
              </IconButton>
            </div>
          </div>
        </>
      ) : (
        <div className={classes.content}>
          <SlideTitle>{data.title}</SlideTitle>
          {data.subtitle && <div className={classes.subtitle}>{data.subtitle}</div>}
          {data.bullets && (
            <ul className={classes.bullets}>
              {data.bullets?.map((bullet, i) => (
                <li key={i} className={classes.bulletItem}>
                  <div className={classes.bulletTitle}>{bullet.title}</div>
                  {!!bullet.description && <div className={classes.bulletDescription}>{bullet.description}</div>}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </BaseSlideDesigner>
  )
}

export default BulletsSlideDesigner
