import * as React from 'react'

const Check = ({ color = 'white', ...props }) => {
  return (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.59503 8.82662C4.48048 8.93791 4.3242 9 4.16185 9C3.99951 9 3.84323 8.93791 3.72868 8.82662L0.26926 5.48465C-0.0897532 5.13789 -0.0897532 4.57558 0.26926 4.22947L0.702431 3.81096C1.06156 3.4642 1.64304 3.4642 2.00206 3.81096L4.16185 5.8972L9.99794 0.260075C10.3571 -0.0866916 10.9391 -0.0866916 11.2976 0.260075L11.7307 0.678579C12.0898 1.02535 12.0898 1.58754 11.7307 1.93377L4.59503 8.82662Z"
        fill={color}
      />
    </svg>
  )
}

export default Check
