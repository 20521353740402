import React, { Fragment, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useTasks from 'hooks/useTasks'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Skeleton } from '@material-ui/lab'
import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { ActionItemTypes } from 'consts/appConsts'

const useStyles = makeStyles({
  filterContainer: {
    margin: '60px 9px 0 16px'
  },
  actionItemContainer: {
    height: 54,
    paddingRight: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    borderBottom: '1px solid var(--border-color-14)',
    userSelect: 'none'
  },
  selectedActionItem: {
    backgroundColor: 'var(--bg-color-40)'
  },
  actionItemWithoutBorder: {
    borderBottom: 0,
    paddingBottom: 1
  },
  actionItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px'
  },
  actionItemIcon: {
    width: 15,
    marginRight: 20,
    display: 'flex',
    justifyContent: 'center'
  },
  actionItemLabel: {
    width: 170
  },
  actionItemSummary: {
    width: 14
  }
})

const TaskCategoriesSideBar = ({ selectedCategory = 'total', onTaskCategoryChanged }) => {
  const classes = useStyles()
  const { tasks } = useTasks()
  const { t } = useTranslation()
  const { taskCategories, isLoading: isLoadingTasks } = useSelector(state => state.taskManagerReducer)

  const hasLoadedCategories = useMemo(() => !!Object.keys(taskCategories).length, [taskCategories])

  const actions = useMemo(
    () =>
      [
        {
          label: t('pages.taskManager.actions.all'),
          value: 'total'
        },
        ...tasks
      ].filter(action => ![ActionItemTypes.NEW_MESSAGE, ActionItemTypes.REVIEW_SCAN].includes(action.value)),
    [t, tasks]
  )

  const shouldShowDivider = useCallback(
    (value, index) =>
      selectedCategory === value || actions[index + 1]?.value === selectedCategory || index === actions.length - 1,
    [actions, selectedCategory]
  )

  return (
    <div className={classes.filterContainer}>
      {actions.map(({ label, value, Icon }, index) => (
        <Fragment key={value}>
          <div
            className={[
              classes.actionItemContainer,
              selectedCategory === value && classes.selectedActionItem,
              shouldShowDivider(value, index) && classes.actionItemWithoutBorder
            ].join(' ')}
            onClick={() => onTaskCategoryChanged(value !== 'total' ? [value] : [])}
          >
            <div className={classes.actionItem}>
              {Icon && (
                <div className={classes.actionItemIcon}>
                  <Icon />
                </div>
              )}
              <DazzedParagraph14 bold={selectedCategory === value} className={classes.actionItemLabel}>
                {label}
              </DazzedParagraph14>
            </div>
            {isLoadingTasks && !hasLoadedCategories ? (
              <Skeleton variant="circle" height={14} width={14} />
            ) : (
              <DazzedParagraph14
                bold={selectedCategory === value}
                color="var(--text-color-3)"
                className={classes.actionItemSummary}
              >
                {taskCategories[value]}
              </DazzedParagraph14>
            )}
          </div>
        </Fragment>
      ))}
    </div>
  )
}

export default TaskCategoriesSideBar
