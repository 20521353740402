import React, { useCallback } from 'react'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'utils/mobileUtils'
import { ThumbsDown, ThumbsUp } from 'components/common/icons'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'

const useStyles = () =>
  makeStyles({
    rateText: {
      color: '#80879A',
      marginRight: 5
    },
    rateContainer: {
      marginTop: 5
    }
  })()

const ScanSummaryRate = ({ isPositiveReview, onSubmitReview, setIsNegativeFeedback }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleNegativeFeedbackClick = useCallback(() => {
    if (isMobile()) {
      onSubmitReview(false, { feedBack: 'bad' })
    } else {
      isPositiveReview !== false && setIsNegativeFeedback(true)
    }
  }, [isPositiveReview, onSubmitReview, setIsNegativeFeedback])

  return (
    <Grid container alignItems="center" justifyContent="flex-end" className={classes.rateContainer}>
      <Grid item>
        <DazzedParagraph16 className={classes.rateText}>{t('dialogs.scanSummary.rateText')}</DazzedParagraph16>
      </Grid>
      <Grid item>
        <IconButton onClick={() => onSubmitReview(true, { feedBack: 'good' })}>
          <ThumbsUp isSelected={isPositiveReview} />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={handleNegativeFeedbackClick}>
          <ThumbsDown isSelected={isPositiveReview === false} />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default ScanSummaryRate
