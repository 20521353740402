import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import CrestXGrin from 'components/common/icons/CrestXGrin'
import WhiteningCalendar from 'components/common/icons/WhiteningCalendar'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import moment from 'moment'
import useCssClasses from 'hooks/useCssClasses'
import useHideHelpWidget from 'hooks/useHideHelpWidget'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'var(--bg-color-50)',
    height: '100%',
    width: '100%'
  },
  container: {
    width: '400px'
  },
  text: {
    color: 'var(--text-color-28)',
    // fontSize: 20,
    textAlign: 'center!important'
  },
  countdown: {
    fontSize: 26
  }
}))

const WhiteningLandingPage = () => {
  const classes = useStyles()

  const dday = useMemo(() => moment('2022-10-31T06:00:00-04:00'), [])
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0 })

  useCssClasses()
  useHideHelpWidget()

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown({
        minutes: Math.ceil(dday.diff(moment.now(), 'minutes', true) % 60)
          .toString()
          .padStart(2, '0'),
        hours: Math.floor(dday.diff(moment.now(), 'hours') % 24),
        days: Math.floor(dday.diff(moment.now(), 'days')).toString()
      })
    }, 1000)
    return () => clearInterval(timer)
  }, [countdown, dday])

  return (
    <Grid container className={classes.root} alignItems="center" justifyContent="center">
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={4}
          className={classes.container}
        >
          <Grid item xs={12}>
            <CrestXGrin />
          </Grid>
          <Grid item xs={12}>
            <DazzedParagraph16 className={classes.text} bold>
              <span style={{ fontSize: 20 }}>
                It’s great to have you here,
                <br />
                the study will start soon!
              </span>
            </DazzedParagraph16>
          </Grid>
          <Grid item xs={12}>
            <WhiteningCalendar />
          </Grid>
          <Grid item xs={12}>
            <DazzedParagraph16 className={classes.text} strong>
              Monday, October 31, 2022
            </DazzedParagraph16>
          </Grid>
          <Grid item xs={12}>
            <DazzedParagraph16 className={[classes.text, classes.countdown].join(' ')} bold>
              {countdown.days} days, {countdown.hours} hours, {countdown.minutes} minutes
            </DazzedParagraph16>
          </Grid>
          <Grid item xs={12}>
            <DazzedParagraph16 className={classes.text}>
              When the countdown is over, please scan
              <br />
              the QR code again to download the app.
            </DazzedParagraph16>
          </Grid>
          <Grid item xs={12}>
            <DazzedParagraph16 className={classes.text}>Thank You,</DazzedParagraph16>
            <DazzedParagraph16 className={classes.text} bold>
              The Crest X Grin team
            </DazzedParagraph16>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default WhiteningLandingPage
