import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import CategoryItems from 'components/Patients/PatientGuidelines/CategoryItems'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  categoryTitle: {
    padding: '5px 0px 5px 10px',
    backgroundColor: 'var(--bg-color-73)'
  },
  noFurtherInfoContainer: {
    padding: 20
  }
}))

const PracticeViewCategoryTooltip = ({ icon, title, note, items, categoryComponent }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const shouldDisplayItems = useMemo(
    () =>
      note || Object.keys(items).some(itemKey => items[itemKey].length > 0 || Object.keys(items[itemKey]).length > 0),
    [items, note]
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems="center" className={classes.categoryTitle}>
          <Grid item style={{ marginRight: 5 }}>
            {icon}
          </Grid>
          <Grid item>
            <DazzedParagraph14 strong>{title}</DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {shouldDisplayItems ? (
          <CategoryItems categoryComponent={categoryComponent} items={items} note={note} />
        ) : (
          <div className={classes.noFurtherInfoContainer}>
            <DazzedParagraph14 textAlign="center">
              {t(`dialogs.notifyPractice.patientBrief.noFurtherInfo`)}
            </DazzedParagraph14>
          </div>
        )}
      </Grid>
    </Grid>
  )
}

export default PracticeViewCategoryTooltip
