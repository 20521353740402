import i18n from '../resources/locales/i18n'

export const RC_LINKS_TYPES = [
  {
    value: 'landingPage',
    shippedByGrin: true
  },
  {
    value: 'autoApproval',
    shippedByGrin: true
  },
  {
    value: 'autoApprovalNoShipping',
    shippedByGrin: false
  }
]

export const METRIC_OPTIONS = [
  {
    label: i18n.t('pages.accountSettings.services.ortho.miles'),
    value: 'miles'
  },
  { label: i18n.t('pages.accountSettings.services.ortho.km'), value: 'km' }
]

export const BIO_TEXT_MAX_LENGTH = 600
export const AREA_OF_COVERAGE_MIN_DISTANCE = 0
export const AREA_OF_COVERAGE_MAX_DISTANCE = 30

export const CLINIC_LOGO_FOLDER = 'clinic-logos'
export const COMPOSITE_PRACTICE_LOGO_FOLDER = 'composite-practice-logos'

export const AREA_OF_COVERAGE_TYPE = 'areaOfCoverage'
export const ALLOWED_ZIP_CODES_TYPE = 'allowedZipCodes'
