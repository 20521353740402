import * as React from 'react'

const SendMobile = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.890496 0.234504L16.625 7.75L0.890498 15.2656L0.890497 9.41072L9.98214 7.75004L0.890497 6.08929L0.890496 0.234504Z"
        fill="white"
      />
    </svg>
  )
}

export default SendMobile
