import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import GrinTabs from 'components/common/tabs/GrinTabs'
import { useTranslation } from 'react-i18next'
import FilesLibraryTab from './FilesLibraryTab'
import GrinTab from 'components/common/tabs/GrinTab'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  container: {
    height: '45%',
    width: '100%',
    backgroundColor: 'var(--bg-color-67)'
  },
  tabsContainer: {
    borderBottom: '1px solid var(--border-color-7)'
  },
  tab: {
    whiteSpace: 'pre',
    maxWidth: 120
  },
  tabContent: {
    zIndex: 1001,
    overflow: 'hidden'
  }
}))

const SavedFiles = ({ onSelectFile = () => {}, handleCloseSavedFiles }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [selectedTab, setSelectedTab] = useState(0)

  const tabs = useMemo(() => {
    return [
      {
        index: 0,
        label: t('pages.patients.selectedPatient.chat.dropzone.savedFiles.tabs.grinLibrary.title'),
        Component: FilesLibraryTab,
        isSystem: true,
        condition: true
      },
      {
        index: 1,
        label: t('pages.patients.selectedPatient.chat.dropzone.savedFiles.tabs.practiceLibrary.title'),
        Component: FilesLibraryTab,
        isSystem: false,
        condition: true
      }
    ]
      .sort((a, b) => (a.index > b.index ? 1 : -1))
      .filter(tab => tab.condition)
  }, [t])

  const handleTabSelection = useCallback(
    (event, value) => {
      const selectedTab = tabs.find(tab => tab.index === value)
      trackEvent('Saved files - tab selected', {
        tab: selectedTab.label
      })
      setSelectedTab(value)
    },
    [tabs]
  )

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.container}>
      <Grid item>
        <GrinTabs
          value={selectedTab}
          variant="fullWidth"
          onChange={handleTabSelection}
          className={classes.tabsContainer}
        >
          {tabs.map(tab => (
            <GrinTab key={tab.index} label={tab.label} marginRight="0px" value={tab.index} className={classes.tab} />
          ))}
        </GrinTabs>
      </Grid>
      <Grid item xs={12} className={classes.tabContent}>
        {tabs
          .filter(tab => tab.index === selectedTab)
          .map(Tab => (
            <Tab.Component
              key={Tab.index}
              isSystem={Tab.isSystem}
              onSelectFile={onSelectFile}
              handleCloseSavedFiles={handleCloseSavedFiles}
            />
          ))}
      </Grid>
    </Grid>
  )
}

export default SavedFiles
