import React, { useCallback } from 'react'
import SwooshLargeIcon from '../common/icons/SwooshLarge1'
import SwooshSmallIcon from '../common/icons/Swoosh'
import FormInput from '../common/FormInput'
import Actions from '../../actions'
import { makeStyles } from '@material-ui/styles'
import { Grid, Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import WithLoader from '../common/WithLoader'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import OggHeading40 from 'components/common/text/OggHeading40'
import useCssClasses from 'hooks/useCssClasses'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
    color: 'var(--text-color-1)',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  signInSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  title: {
    fontSize: isMobile ? 32 : 40
  },
  form: {
    maxWidth: '512px',
    minWidth: isMobile ? 'auto' : 512,
    padding: 18
  },
  nextButton: {
    background: 'var(--border-color-1)',
    color: 'var(--text-color-1)',
    width: '100%',
    borderRadius: '34px',
    '&:hover': {
      background: 'var(--border-color-1)'
    }
  },
  noFill: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      transition: 'color 0.2s ease',
      color: 'gray',
      boxShadow: 'none'
    }
  },
  inputContainer: {
    width: '100%'
  }
}))

const SetPassword = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  useCssClasses(true)

  const { password, passwordConfirmation, confirmationCode, isLoading, errors } = useSelector(
    state => state.authReducer
  )

  const handleFormSubmit = useCallback(
    e => {
      e.preventDefault()
      dispatch(Actions.requestResetPasswordSubmit())
    },
    [dispatch]
  )

  const resendConfirmationCode = useCallback(() => dispatch(Actions.resendConfirmationCode()), [dispatch])

  return (
    <Grid className={classes.root} container alignItems="center" justifyContent="center">
      {!isMobile ? (
        <SwooshLargeIcon className={classes.signInSwoosh} />
      ) : (
        <SwooshSmallIcon className={classes.signInSwoosh} />
      )}

      <form onSubmit={handleFormSubmit}>
        <Grid container direction="column" justifyContent="center" spacing={isMobile ? 5 : 3} className={classes.form}>
          <Grid item></Grid>
          <Grid item>
            <OggHeading40 className={classes.title} dark textAlign={isMobile ? 'center' : 'start'}>
              {t('pages.setPassword.title')}
            </OggHeading40>
          </Grid>
          <Grid item />
          <Grid item>
            <FormInput
              containerClassName={classes.inputContainer}
              title={t('pages.setPassword.inputs.confirmationCodeTitle')}
              placeholder={t('pages.setPassword.inputs.confirmationCodePlaceholder')}
              errorMessage={errors.confirmationCode}
              validationRules={{ required: true }}
              value={confirmationCode}
              setValue={confirmationCode =>
                dispatch(
                  Actions.changeAuthForm({
                    key: 'confirmationCode',
                    value: confirmationCode
                  })
                )
              }
            />
          </Grid>
          <Grid item>
            <FormInput
              containerClassName={classes.inputContainer}
              title={t('pages.setPassword.inputs.newPasswordTitle')}
              placeholder={t('pages.setPassword.inputs.newPasswordPlaceholder')}
              value={password}
              type="password"
              errorMessage={errors.password}
              setValue={password =>
                dispatch(
                  Actions.changeAuthForm({
                    key: 'password',
                    value: password
                  })
                )
              }
            />
          </Grid>
          <Grid item>
            <FormInput
              title={t('pages.setPassword.inputs.confirmPasswordTitle')}
              placeholder={t('pages.setPassword.inputs.confirnPasswordPlaceholder')}
              value={passwordConfirmation}
              type="password"
              errorMessage={errors.passwordConfirmation}
              validationRules={{ required: true }}
              setValue={passwordConfirmation =>
                dispatch(
                  Actions.changeAuthForm({
                    key: 'passwordConfirmation',
                    value: passwordConfirmation
                  })
                )
              }
            />
          </Grid>
          <Grid item />
          <Grid item>
            <WithLoader isLoading={isLoading}>
              <Button variant="contained" className={classes.nextButton} type="submit">
                {t('general.save')}
              </Button>
            </WithLoader>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={classNames(classes.nextButton, classes.noFill)}
              onClick={resendConfirmationCode}
            >
              {t('pages.setPassword.resendConfirmationCode')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}

export default SetPassword
