import queryString from 'query-string'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useRouteMatch } from 'react-router'
import { getCloudFrontUrl } from '../../utils/storageUtils'

const COLORS_URL_PARAM = 'colors'
const BANNER_URL_PARAM = 'bannerPhoto'

export default () => {
  const location = useLocation()
  const match = useRouteMatch()
  const rcToken = useMemo(() => match.params.rcToken, [match])

  const publicDetails = useSelector(state => state.authReducer.rcRegister.doctorDetails)
  const defaultColorSettings = useMemo(() => ({ primary: '#3E52F0', secondary: '#97FFBA' }), [])

  const {
    approved,
    shipping,
    iframeMode,
    utm_source,
    utm_medium,
    utm_id,
    utm_campaign,
    utm_term,
    utm_content,
    skipCaptcha
  } = useMemo(() => {
    const params = queryString.parse(location.search)
    return {
      ...params,
      approved: params.approved === 'true',
      shipping: params.shipping === 'true'
    }
  }, [location])

  const onboardingTypeKey = useMemo(() => {
    if (approved && shipping) {
      return 'autoApproval'
    } else if (approved && !shipping) {
      return 'autoApprovalWithoutShipping'
    } else {
      return 'manualApprovalWithShipping'
    }
  }, [approved, shipping])

  const doctorDetails = useMemo(() => {
    if (!publicDetails.name) {
      return {
        ...queryString.parse(location.search),
        colorSettings: defaultColorSettings
      }
    }

    if (!publicDetails.colorSettings) {
      return {
        ...publicDetails,
        colorSettings: defaultColorSettings
      }
    }

    return publicDetails
  }, [location, publicDetails, defaultColorSettings])

  const urlColors = useMemo(() => {
    const params = queryString.parse(location.search)
    const colorsValue = params[COLORS_URL_PARAM]
    return colorsValue ? JSON.parse(colorsValue) : {}
  }, [location.search])

  const bannerUrl = useMemo(() => {
    const params = queryString.parse(location.search)
    const banner = params[BANNER_URL_PARAM]
    return banner || doctorDetails?.rcLPBannerPhoto?.key || ''
  }, [location.search, doctorDetails?.rcLPBannerPhoto?.key])

  const logoUrl = useMemo(
    () => (doctorDetails?.logo ? `${getCloudFrontUrl()}/clinic-logos/${doctorDetails.logo}` : null),
    [doctorDetails]
  )

  const country = useMemo(() => queryString.parse(location.search).country, [location.search])

  return {
    ...doctorDetails,
    country,
    colorSettings: {
      ...doctorDetails.colorSettings,
      ...publicDetails.rcColors,
      ...urlColors
    },
    rcLPBannerPhoto: bannerUrl,
    logoUrl,
    iframeMode,
    onboardingType: {
      approved,
      shipping,
      onboardingTypeKey
    },
    campaignParams: {
      utm_source,
      utm_medium,
      utm_id,
      utm_campaign,
      utm_term,
      utm_content
    },
    rcToken,
    skipCaptcha
  }
}
