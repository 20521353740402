import * as React from 'react'

const DownloadArrow = () => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 11.3999H13" stroke="#4151E6" strokeLinecap="round" />
    <path d="M7 1V11.4" stroke="#4151E6" strokeLinecap="round" />
    <path d="M3.39999 7L6.99999 10.6L10.6 7" stroke="#4151E6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default DownloadArrow
