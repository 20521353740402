import * as React from 'react'

function ServiceAccountDeactivate(props) {
  return (
    <svg width={21} height={21} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={7.66683} cy={4.33333} r={3.33334} stroke="#4151E6" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M1 11.592c0-.675.308-1.315.904-1.631 1.058-.562 3.02-1.342 5.763-1.342 2.742 0 4.704.78 5.762 1.342.596.316.904.956.904 1.63v2.742H1v-2.741z"
        stroke="#4151E6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={14.3332} cy={14.3334} r={6.66667} fill="#fff" />
      <circle
        cx={14.333}
        cy={14.3334}
        r={4}
        fill="#4151E6"
        stroke="#4151E6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.667 11.667L17 17M17 11.667L11.667 17" stroke="#fff" strokeWidth={1.5} strokeLinejoin="round" />
    </svg>
  )
}

export default ServiceAccountDeactivate
