import * as React from 'react'

const RPE = props => (
  <svg width="34" height="21" viewBox="0 0 34 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="16.2565" y="12.905" width="1.48634" height="1.85792" fill="#001335" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2791 9.56006H21.9239V18.1065H17.2791V15.9699L18.208 15.2732V13.8333V12.3934L17.2791 11.6967V9.56006ZM21.1795 12.9042H18.95V14.7621H21.1795V12.9042Z"
      fill="#001335"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7236 9.56006H12.0788V18.1065H16.7236V15.9699L15.7947 15.2732V13.8333V12.3934L16.7236 11.6967V9.56006ZM12.8232 12.9042H15.0527V14.7621H12.8232V12.9042Z"
      fill="#001335"
    />
    <path d="M21.2744 10.0252L24.2471 7.33118" stroke="#001335" />
    <path d="M12.7283 10.0252L9.75559 7.33118" stroke="#001335" />
    <path
      d="M28.754 4.77658L27.8705 2.71515C27.3609 1.52597 25.9745 0.986608 24.7952 1.51873V1.51873C23.8208 1.95838 23.275 3.00877 23.4753 4.0588L25.9243 16.8986C26.2055 18.3729 27.3145 19.5512 28.769 19.9212V19.9212C30.4363 20.3454 32.1603 19.4714 32.8036 17.8758L32.8327 17.8038C33.5002 16.1483 32.8252 14.2552 31.2609 13.3955L30.6689 13.0702C29.5183 12.4379 28.1414 12.3677 26.9325 12.8797L24.8988 13.7411"
      stroke="#001335"
    />
    <path
      d="M5.24868 4.77658L6.13215 2.71515C6.6418 1.52597 8.0282 0.986608 9.2075 1.51873V1.51873C10.1819 1.95838 10.7277 3.00877 10.5274 4.0588L8.07838 16.8986C7.79719 18.3729 6.68822 19.5512 5.2337 19.9212V19.9212C3.56643 20.3454 1.84242 19.4714 1.19906 17.8758L1.17001 17.8038C0.50249 16.1483 1.17751 14.2552 2.74181 13.3955L3.33377 13.0702C4.48436 12.4379 5.86125 12.3677 7.07018 12.8797L9.10387 13.7411"
      stroke="#001335"
    />
    <path d="M21.2744 17.6429H22.7903C24.0096 17.6429 25.2217 17.8309 26.3837 18.2003V18.2003" stroke="#001335" />
    <path d="M12.7283 17.6429H11.2124C9.99307 17.6429 8.78102 17.8309 7.61898 18.2003V18.2003" stroke="#001335" />
  </svg>
)

export default RPE
