import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import ROUTES from '../../consts/routesConsts'
import axios from 'axios'
import PrimaryButton from '../common/buttons/PrimaryButton'
import jwt_decode from 'jwt-decode'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    borderTop: '1px solid var(--border-color-3)',
    overflowY: 'hidden',
    flex: 1
  },
  buttonsContainer: {
    display: 'flex',
    padding: 20,
    '& .MuiButtonBase-root': {
      margin: '0 20px'
    }
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 500,
    '& tr, th, td': {
      border: '1px solid black'
    }
  }
}))

const getAccessToken = str => {
  const firstPart = str.split('access_token=')[1]

  if (!firstPart) {
    return
  }

  const accessToken = firstPart.split('&expires')[0]

  return accessToken
}

const Ddx = () => {
  const classes = useStyles()
  const [patients, setPatients] = useState([])

  const keepAccessToken = () => {
    const accessToken = getAccessToken(window.location.href)

    if (accessToken) {
      localStorage.setItem('ddx-access-token', accessToken)
    }
  }

  const isTokenExpired = decodedToken => {
    return Date.now() >= decodedToken.exp * 1000
  }

  useEffect(() => {
    const token = localStorage.getItem('ddx-access-token')
    if (token) {
      const decoded = jwt_decode(token)

      if (isTokenExpired(decoded)) {
        localStorage.removeItem('ddx-access-token')

        alert('DDX Token is expired, you need to re-sign in')
      } else {
        keepAccessToken()
      }
    } else {
      keepAccessToken()
    }
  })

  const handleLogin = () => {
    axios
      .get(
        'https://ddxstaging.com/authorize?response_type=token&client_id=grin.ddx.testing&redirect_uri=https%3A%2F%2Fdev.get-grin.com%2Fddx%2F&scope=openid%20profile%20practice.patient.read%20order.read%20order.write&state=1&nonce=1'
      )
      .then(data => {
        window.open(data.request.responseURL)
      })
      .catch(err => console.error('err', err))
  }

  const fetchPatients = () => {
    axios
      // .get('https://www.ddxstaging.com/service/practice_portal/patients_get', {
      .get('https://o3996hzqsd.execute-api.us-east-1.amazonaws.com/idev/doctors/geo?ddx=1', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ddx-access-token')}`
        }
      })
      .then(({ data }) => {
        console.log('data', data)
        setPatients(data)
      })
      .catch(err => console.error('err', err))
  }

  const renderDdxPage = () => (
    <div className={classes.container}>
      <Route path={ROUTES.DDX} exact>
        <div className={classes.buttonsContainer}>
          <PrimaryButton onClick={handleLogin} label={'Login to your DDX account'} />
          <PrimaryButton onClick={fetchPatients} label={'Fetch Your Patients'} />
        </div>

        {patients.length ? (
          <div className={classes.tableContainer}>
            DDX Patients List
            <table>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Gender</th>
                  <th>Creation Date</th>
                </tr>
              </thead>
              <tbody>
                {patients.map(p => {
                  return (
                    <tr>
                      <td>{p.first_name}</td>
                      <td>{p.last_name}</td>
                      <td>{p.gender}</td>
                      <td>{p.add_date}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div>Nothing to show</div>
        )}
      </Route>
    </div>
  )

  const renderLoader = () => (
    <div></div>
    // <Backdrop className={classes.loader} open={showAppLoader}>
    //   <CircularProgress color="inherit" size={60} />
    // </Backdrop>
  )

  if (false) {
    return renderLoader()
  } else {
    return renderDdxPage()
  }
}

export default Ddx
