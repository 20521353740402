import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Collapse, Grid } from '@material-ui/core'
import BeforeAfterFeedbackIcon from 'components/common/icons/BeforeAfter/BeforeAfterFeedbackIcon'
import ExpandMore from 'components/common/ExpandMore'
import { trackEvent } from 'utils/analyticsUtils'
import BeforeAfterFeedbackBody from './BeforeAfterFeedbackBody'

const useStyles = makeStyles(theme => ({
  title: {
    textDecoration: 'none'
  }
}))

const BeforeAfterFeedback = ({ analyticsMetadata = {}, onSubmit = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [expanded, setExpanded] = useState(false)

  const handleCtaClick = useCallback(() => {
    setExpanded(!expanded)
    trackEvent('Before After - feedback section click', {
      toggle: expanded ? 'off' : 'on',
      ...analyticsMetadata
    })
  }, [expanded, analyticsMetadata])

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <BeforeAfterFeedbackIcon />
          </Grid>
          <Grid item>
            <ExpandMore
              expanded={expanded}
              onClick={handleCtaClick}
              className={classes.title}
              showLessText={t('dialogs.beforeAfter.feedback.title')}
              showMoreText={t('dialogs.beforeAfter.feedback.title')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Collapse in={expanded}>
          <BeforeAfterFeedbackBody analyticsMetadata={analyticsMetadata} onSubmit={onSubmit} />
        </Collapse>
      </Grid>
    </Grid>
  )
}

export default BeforeAfterFeedback
