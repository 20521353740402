import * as React from 'react'

const Location = props => {
  return (
    <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.25323 0.900146C5.32956 0.900146 3.48469 1.63764 2.12445 2.95039C0.764213 4.26314 3.93607e-05 6.0436 3.93607e-05 7.90011C-0.00592991 9.64209 0.667253 11.3225 1.88587 12.6076L6.9087 18.2469C6.95126 18.295 7.00409 18.3335 7.06357 18.36C7.12304 18.3864 7.18775 18.4001 7.25323 18.4001C7.31871 18.4001 7.38342 18.3864 7.44289 18.36C7.50237 18.3335 7.5552 18.295 7.59776 18.2469L12.6206 12.6076C13.8392 11.3225 14.5124 9.64209 14.5064 7.90011C14.5064 6.0436 13.7422 4.26314 12.382 2.95039C11.0218 1.63764 9.1769 0.900146 7.25323 0.900146ZM11.9315 12.0388L7.25323 17.2932L2.57492 12.0432C1.74258 11.1669 1.19244 10.0758 0.991611 8.90298C0.790782 7.73013 0.947938 6.52622 1.44391 5.43809C1.93989 4.34996 2.75325 3.42464 3.78479 2.77497C4.81633 2.12531 6.02147 1.77938 7.25323 1.77938C8.48499 1.77938 9.69013 2.12531 10.7217 2.77497C11.7532 3.42464 12.5666 4.34996 13.0625 5.43809C13.5585 6.52622 13.7157 7.73013 13.5148 8.90298C13.314 10.0758 12.7639 11.1669 11.9315 12.0432V12.0388Z"
        fill="black"
      />
      <path
        d="M7.25335 4.8374C6.62573 4.8374 6.01222 5.01701 5.49037 5.35352C4.96853 5.69003 4.56181 6.16833 4.32163 6.72792C4.08145 7.28752 4.01861 7.90328 4.14105 8.49735C4.26349 9.09141 4.56572 9.63709 5.00951 10.0654C5.4533 10.4937 6.01872 10.7854 6.63427 10.9035C7.24983 11.0217 7.88787 10.961 8.4677 10.7292C9.04754 10.4975 9.54314 10.1049 9.89182 9.60131C10.2405 9.09769 10.4266 8.50559 10.4266 7.89988C10.4266 7.08766 10.0923 6.30871 9.49719 5.73438C8.90208 5.16006 8.09495 4.8374 7.25335 4.8374ZM7.25335 10.0874C6.80505 10.0874 6.36682 9.95908 5.99408 9.71871C5.62134 9.47835 5.33082 9.13671 5.15926 8.737C4.98771 8.33729 4.94282 7.89746 5.03028 7.47313C5.11774 7.0488 5.33361 6.65902 5.65061 6.3531C5.9676 6.04717 6.37147 5.83883 6.81115 5.75443C7.25083 5.67002 7.70657 5.71334 8.12075 5.87891C8.53492 6.04448 8.88891 6.32485 9.13797 6.68458C9.38703 7.04431 9.51997 7.46724 9.51997 7.89988C9.51997 8.48004 9.28116 9.03644 8.85609 9.44667C8.43102 9.8569 7.85449 10.0874 7.25335 10.0874Z"
        fill="black"
      />
    </svg>
  )
}

export default Location
