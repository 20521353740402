import * as React from 'react'

export default () => {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 7V8C1 9.10457 1.89543 10 3 10H9C10.1046 10 11 9.10457 11 8V7"
        stroke="#4451DE"
        strokeLinecap="round"
      />
      <path d="M4 6L6 8L8 6" stroke="#4451DE" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 8V1" stroke="#4451DE" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
