import React, { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Grid } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import GrinCheckbox from 'components/common/GrinCheckbox'
import { DATA_TYPES } from 'consts/patientsConsts'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import SecondaryButton from 'components/common/buttons/SecondaryButton'

const useStyles = () =>
  makeStyles({
    container: {
      width: '600px !important',
      minHeight: 350
    },
    text: {
      marginTop: 30
    },
    listBox: {
      maxHeight: 160,
      fontSize: '14px'
    },
    inputRoot: {
      fontSize: '14px',
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      '& > fieldset': {
        border: '1px solid var(--border-color-7) !important'
      }
    },
    checkbox: {
      padding: 5
    },
    btnsContainer: {
      marginTop: 30
    },
    optionTitle: {
      lineHeight: '20px'
    }
  })()

export default ({
  patient,
  doctors,
  selectedDoctor,
  setSelectedDoctor,
  dataTypes,
  setDataTypes,
  onClickCancel,
  onClickNext
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const autocompleteOptions = useMemo(
    () => doctors.filter(doctor => doctor.id !== patient?.doctor?.id),
    [doctors, patient]
  )

  const handleChangeDataType = useCallback(
    (checked, type) => {
      setDataTypes(checked ? dataTypes.concat([type]) : dataTypes.filter(dataType => dataType !== type))
    },
    [dataTypes, setDataTypes]
  )

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item md={3}>
            <DazzedParagraph14 className={classes.optionTitle}>
              {t('dialogs.transferPatient.fromDoctor')}
            </DazzedParagraph14>
          </Grid>
          <Grid item md={9}>
            <DazzedParagraph14>{`${patient?.doctor?.name} (${patient?.doctor?.email})`}</DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item md={3}>
            <DazzedParagraph14>{t('dialogs.transferPatient.toDoctor')}</DazzedParagraph14>
          </Grid>
          <Grid item md={9}>
            <Autocomplete
              disablePortal
              options={autocompleteOptions}
              getOptionLabel={({ name, email }) => `${name} (${email})`}
              renderInput={params => <TextField {...params} variant="outlined" />}
              classes={{ listbox: classes.listBox, inputRoot: classes.inputRoot }}
              onChange={(e, value) => setSelectedDoctor(value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item md={3}>
            <DazzedParagraph14>{t('dialogs.transferPatient.dataToTransfer')}</DazzedParagraph14>
          </Grid>
          <Grid item md={9}>
            {DATA_TYPES.map(type => (
              <GrinCheckbox
                className={classes.checkbox}
                key={type}
                caption={t(`dialogs.transferPatient.${type}`)}
                checked={dataTypes.includes(type)}
                onChange={handleChangeDataType}
                name={type}
                color="var(--bg-color-1)"
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="center" spacing={2} className={classes.btnsContainer}>
          <Grid item>
            <SecondaryButton label={t('general.cancel')} onClick={onClickCancel} />
          </Grid>
          <Grid item>
            <PrimaryButton label={t('general.next')} onClick={onClickNext} disabled={!selectedDoctor} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
