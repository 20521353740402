import * as React from 'react'

const TrackingLarge = () => (
  <svg
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 4 }}
  >
    <path d="M2 8.5L7.5 14L19.5 2" stroke="#5CB664" strokeWidth="3" />
  </svg>
)

export default TrackingLarge
