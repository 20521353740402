import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import ScanItem from './ScanItem'
import EventItem from './EventItems/EventItem'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import TimelineButtonBase from './TimelineButtonBase'
import { isMobile } from 'utils/mobileUtils'
import { trackEvent } from 'utils/analyticsUtils'

const TimeLineItemsByMonth = ({ monthName, timelineItems, shouldExpandMonth }) => {
  const useStyles = ({ isMobile }) =>
    makeStyles({
      month: {
        fontFamily: 'Dazzed',
        fontSize: isMobile ? 25 : 40,
        color: 'black'
      },
      arrow: {
        right: isMobile ? 12 : 5,
        position: 'relative'
      },
      container: {
        paddingLeft: isMobile ? '4.6666%' : 20
      }
    })({ isMobile })

  const classes = useStyles({ isMobile: isMobile() })

  const [expand, setExpand] = useState(shouldExpandMonth)

  const handleExpansion = useCallback(
    newExpand => {
      trackEvent('Timeline - month item clicked', {
        event: newExpand ? 'opened' : 'closed',
        month: monthName
      })
      setExpand(newExpand)
    },
    [monthName]
  )

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <TimelineButtonBase expand={expand} setExpand={handleExpansion} className={classes.arrow} value={monthName} />
      </Grid>
      <Collapse in={expand}>
        {expand
          ? timelineItems.map((timelineItem, index) =>
              timelineItem.type === 'scan' ? (
                <ScanItem key={index} scanData={timelineItem.data} timestamp={timelineItem.timestamp} />
              ) : timelineItem.type === 'event' ? (
                <EventItem key={index} event={timelineItem.data} />
              ) : null
            )
          : null}
      </Collapse>
    </Grid>
  )
}

export default TimeLineItemsByMonth
