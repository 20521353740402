const SAVE_PAYMENT_METHOD = 'SAVE_PAYMENT_METHOD'
const SAVE_PAYMENT_METHOD_RECEIVED = 'SAVE_PAYMENT_METHOD_RECEIVED'
const SAVE_PAYMENT_METHOD_FAILED = 'SAVE_PAYMENT_METHOD_FAILED'
const FETCH_GRIN_PLANS = 'FETCH_GRIN_PLANS'
const GRIN_PLANS_RECEIVED = 'GRIN_PLANS_RECEIVED'
const GRIN_PLANS_FAILED = 'GRIN_PLANS_FAILED'
const FETCH_DOCTOR_PAYMENT_METHOD = 'FETCH_DOCTOR_PAYMENT_METHOD'
const DOCTOR_PAYMENT_METHOD_RECEIVED = 'DOCTOR_PAYMENT_METHOD_RECEIVED'
const DOCTOR_PAYMENT_METHOD_FAILED = 'DOCTOR_PAYMENT_METHOD_FAILED'
const SET_UPGRADE_PLAN_WARNING_MODAL_VISIBILITY = 'SET_UPGRADE_PLAN_WARNING_MODAL_VISIBILITY'
const SET_UPGRADE_PLAN_FOR_SCOPES_WARNING_MODAL_VISIBILITY = 'SET_UPGRADE_PLAN_FOR_SCOPES_WARNING_MODAL_VISIBILITY'
const SET_SELECT_PLAN_MODAL_VISIBILITY = 'SET_SELECT_PLAN_MODAL_VISIBILITY'
const SET_SELECTED_PLAN_GROUP_AND_PERIOD = 'SET_SELECTED_PLAN_GROUP_AND_PERIOD'
const SET_SELECTED_PLAN_SCOPE_QUANTITY_AND_SHIPPING_ADDRESS = 'SET_SELECTED_PLAN_SCOPE_QUANTITY_AND_SHIPPING_ADDRESS'
const SET_UPGRADE_PLAN_DIALOG_VISIBILITY = 'SET_UPGRADE_PLAN_DIALOG_VISIBILITY'
const UPGRADE_PLAN = 'UPGRADE_PLAN'
const UPGRADE_PLAN_RECEIVED = 'UPGRADE_PLAN_RECEIVED'
const UPGRADE_PLAN_FAILED = 'UPGRADE_PLAN_FAILED'
const SET_ORDER_GRIN_KITS_MODAL_VISIBILITY = 'SET_ORDER_GRIN_KITS_MODAL_VISIBILITY'
const CREATE_ORDER_GRIN_KITS = 'CREATE_ORDER_GRIN_KITS'
const CREATE_ORDER_GRIN_KITS_RECEIVED = 'CREATE_ORDER_GRIN_KITS_RECEIVED'
const CREATE_ORDER_GRIN_KITS_FAILED = 'CREATE_ORDER_GRIN_KITS_FAILED'
const FETCH_DOCTOR_TRANSACTIONS = 'FETCH_DOCTOR_TRANSACTIONS'
const DOCTOR_TRANSACTIONS_RECEIVED = 'DOCTOR_TRANSACTIONS_RECEIVED'
const DOCTOR_TRANSACTIONS_FAILED = 'DOCTOR_TRANSACTIONS_FAILED'
const FETCH_DOCTOR_SUBSCRIPTION = 'FETCH_DOCTOR_SUBSCRIPTION'
const DOCTOR_SUBSCRIPTION_RECEIVED = 'DOCTOR_SUBSCRIPTION_RECEIVED'
const DOCTOR_SUBSCRIPTION_FAILED = 'DOCTOR_SUBSCRIPTION_FAILED'
const SUBMIT_PLAN_UPGRADE_REQUEST = 'SUBMIT_PLAN_UPGRADE_REQUEST'
const SUBMIT_PLAN_UPGRADE_REQUEST_RECEIVED = 'SUBMIT_PLAN_UPGRADE_REQUEST_RECEIVED'
const SUBMIT_PLAN_UPGRADE_REQUEST_FAILED = 'SUBMIT_PLAN_UPGRADE_REQUEST_FAILED'
const CALCULATE_ORDER_TAX = 'CALCULATE_ORDER_TAX'
const ORDER_TAX_RECEIVED = 'ORDER_TAX_RECEIVED'
const ORDER_TAX_FAILED = 'ORDER_TAX_FAILED'
const SUBMIT_PLAN_CANCELLATION_REQUEST = 'SUBMIT_PLAN_CANCELLATION_REQUEST'
const SUBMIT_PLAN_CANCELLATION_REQUEST_RECEIVED = 'SUBMIT_PLAN_CANCELLATION_REQUEST_RECEIVED'
const SUBMIT_PLAN_CANCELLATION_REQUEST_FAILED = 'SUBMIT_PLAN_CANCELLATION_REQUEST_FAILED'
const FETCH_DOCTOR_SEATS = 'FETCH_DOCTOR_SEATS'
const FETCH_DOCTOR_SEATS_RECEIVED = 'FETCH_DOCTOR_SEATS_RECEIVED'
const FETCH_DOCTOR_SEATS_FAILED = 'FETCH_DOCTOR_SEATS_FAILED'
const VALIDATE_ADDRESS = 'VALIDATE_ADDRESS'
const VALIDATE_ADDRESS_RECEIVED = 'VALIDATE_ADDRESS_RECEIVED'
const VALIDATE_ADDRESS_FAILED = 'VALIDATE_ADDRESS_FAILED'
const RESET_ADDRESS_VALIDATION_RESULT = 'RESET_ADDRESS_VALIDATION_RESULT'
const APPLY_COUPON_CODE = 'APPLY_COUPON_CODE'
const APPLY_COUPON_CODE_SUCCESS = 'APPLY_COUPON_CODE_SUCCESS'
const APPLY_COUPON_CODE_FAILURE = 'APPLY_COUPON_CODE_FAILURE'
const SET_COUPON_MANUALLY = 'SET_COUPON_MANUALLY'
const CLEAR_COUPON_CODE = 'CLEAR_COUPON_CODE'
const CLEAR_COUPON_CODE_RECEIVED = 'CLEAR_COUPON_CODE_RECEIVED'
const CLEAR_COUPON_CODE_FAILED = 'CLEAR_COUPON_CODE_FAILED'
const RESET_COUPON = 'RESET_COUPON'
const DELETE_INVOICE = 'DELETE_INVOICE'
const DELETE_INVOICE_RECEIVED = 'DELETE_INVOICE_RECEIVED'
const DELETE_INVOICE_FAILED = 'DELETE_INVOICE_FAIELD'
const SET_SCOPE_QUANTITY = 'SET_SCOPE_QUANTITY'
const SET_SCOPES_PAYMENT_ACCEPTED_MODAL_VISIBILITY = 'SET_SCOPES_PAYMENT_ACCEPTED_MODAL_VISIBILITY'
const REQUEST_ATTACH_CREDIT_CARD = 'REQUEST_ATTACH_CREDIT_CARD'
const ATTACH_CREDIT_CARD_RECEIVED = 'ATTACH_CREDIT_CARD_RECEIVED'
const ATTACH_CREDIT_CARD_FAILED = 'ATTACH_CREDIT_CARD_FAILED'
const FETCH_TRANSACTION_PDF = 'FETCH_TRANSACTION_PDF'
const FETCH_TRANSACTION_PDF_RECEIVED = 'FETCH_TRANSACTION_PDF_RECEIVED'
const FETCH_TRANSACTION_PDF_FAILED = 'FETCH_TRANSACTION_PDF_FAILED'

const savePaymentMethod = payload => ({
  type: SAVE_PAYMENT_METHOD,
  payload
})

const savePaymentMethodReceived = payload => ({
  type: SAVE_PAYMENT_METHOD_RECEIVED,
  payload
})

const savePaymentMethodFailed = payload => ({
  type: SAVE_PAYMENT_METHOD_FAILED,
  payload
})

const fetchGrinPlans = payload => ({
  type: FETCH_GRIN_PLANS,
  payload
})

const grinPlansReceived = payload => ({
  type: GRIN_PLANS_RECEIVED,
  payload
})

const grinPlansFailed = payload => ({
  type: GRIN_PLANS_FAILED,
  payload
})

const fetchDoctorPaymentMethod = payload => ({
  type: FETCH_DOCTOR_PAYMENT_METHOD,
  payload
})

const doctorPaymentMethodReceived = payload => ({
  type: DOCTOR_PAYMENT_METHOD_RECEIVED,
  payload
})

const doctorPaymentMethodFailed = payload => ({
  type: DOCTOR_PAYMENT_METHOD_FAILED,
  payload
})

const setUpgradePlanWarningModalVisibility = payload => ({
  type: SET_UPGRADE_PLAN_WARNING_MODAL_VISIBILITY,
  payload
})

const setUpgradePlanForScopesWarningModalVisibility = payload => ({
  type: SET_UPGRADE_PLAN_FOR_SCOPES_WARNING_MODAL_VISIBILITY,
  payload
})

const setSelectPlanModalVisibility = payload => ({
  type: SET_SELECT_PLAN_MODAL_VISIBILITY,
  payload
})

const setSelectedPlanGroupAndPeriod = payload => ({
  type: SET_SELECTED_PLAN_GROUP_AND_PERIOD,
  payload
})

const setSelectedPlanScopesQuantityAndAddress = payload => ({
  type: SET_SELECTED_PLAN_SCOPE_QUANTITY_AND_SHIPPING_ADDRESS,
  payload
})

const setUpgradePlanDialogVisibility = payload => ({
  type: SET_UPGRADE_PLAN_DIALOG_VISIBILITY,
  payload
})

const upgradePlan = payload => ({
  type: UPGRADE_PLAN,
  payload
})

const upgradePlanReceived = payload => ({
  type: UPGRADE_PLAN_RECEIVED,
  payload
})

const upgradePlanFailed = payload => ({
  type: UPGRADE_PLAN_FAILED,
  payload
})

const setOrderGrinKitsModalVisibility = payload => ({
  type: SET_ORDER_GRIN_KITS_MODAL_VISIBILITY,
  payload
})

const createOrderGrinKits = payload => ({
  type: CREATE_ORDER_GRIN_KITS,
  payload
})

const createOrderGrinKitsReceived = payload => ({
  type: CREATE_ORDER_GRIN_KITS_RECEIVED,
  payload
})

const createOrderGrinKitsFailed = payload => ({
  type: CREATE_ORDER_GRIN_KITS_FAILED,
  payload
})

const fetchDoctorTransactions = payload => ({
  type: FETCH_DOCTOR_TRANSACTIONS,
  payload
})

const doctorTransactionsReceived = payload => ({
  type: DOCTOR_TRANSACTIONS_RECEIVED,
  payload
})

const doctorTransactionsFailed = payload => ({
  type: DOCTOR_TRANSACTIONS_FAILED,
  payload
})

const fetchDoctorSubscription = payload => ({
  type: FETCH_DOCTOR_SUBSCRIPTION,
  payload
})

const doctorSubscriptionReceived = payload => ({
  type: DOCTOR_SUBSCRIPTION_RECEIVED,
  payload
})

const doctorSubscriptionFailed = payload => ({
  type: DOCTOR_SUBSCRIPTION_FAILED,
  payload
})

const submitPlanUpgradeRequest = payload => ({
  type: SUBMIT_PLAN_UPGRADE_REQUEST,
  payload
})

const submitPlanUpgradeRequestReceived = payload => ({
  type: SUBMIT_PLAN_UPGRADE_REQUEST_RECEIVED,
  payload
})

const submitPlanUpgradeRequestFailed = payload => ({
  type: SUBMIT_PLAN_UPGRADE_REQUEST_FAILED,
  payload
})

const calculateOrderTax = payload => ({
  type: CALCULATE_ORDER_TAX,
  payload
})

const ordersTaxReceived = payload => ({
  type: ORDER_TAX_RECEIVED,
  payload
})

const ordersTaxFailed = payload => ({
  type: ORDER_TAX_FAILED,
  payload
})

const submitPlanCancellationRequest = payload => ({
  type: SUBMIT_PLAN_CANCELLATION_REQUEST,
  payload
})

const submitPlanCancellationRequestReceived = payload => ({
  type: SUBMIT_PLAN_CANCELLATION_REQUEST_RECEIVED,
  payload
})

const submitPlanCancellationRequestFailed = payload => ({
  type: SUBMIT_PLAN_CANCELLATION_REQUEST_FAILED,
  payload
})

const fetchDoctorSeats = payload => ({
  type: FETCH_DOCTOR_SEATS,
  payload
})

const fetchDoctorSeatsReceived = payload => ({
  type: FETCH_DOCTOR_SEATS_RECEIVED,
  payload
})

const fetchDoctorSeatsFailed = payload => ({
  type: FETCH_DOCTOR_SEATS_FAILED,
  payload
})

const validateAddress = payload => ({
  type: VALIDATE_ADDRESS,
  payload
})

const validateAddressReceived = payload => ({
  type: VALIDATE_ADDRESS_RECEIVED,
  payload
})

const validateAddressFailed = payload => ({
  type: VALIDATE_ADDRESS_FAILED,
  payload
})

const resetAddressValidationResult = payload => ({
  type: RESET_ADDRESS_VALIDATION_RESULT,
  payload
})

const applyCouponCode = payload => ({
  type: APPLY_COUPON_CODE,
  payload
})

const applyCouponCodeSuccess = payload => ({
  type: APPLY_COUPON_CODE_SUCCESS,
  payload
})

const applyCouponCodeFailure = payload => ({
  type: APPLY_COUPON_CODE_FAILURE,
  payload
})

const setCouponManually = payload => ({
  type: SET_COUPON_MANUALLY,
  payload
})

const resetCoupon = payload => ({
  type: RESET_COUPON,
  payload
})

const deleteInvoice = payload => ({
  type: DELETE_INVOICE,
  payload
})

const deleteInvoiceReceived = payload => ({
  type: DELETE_INVOICE_RECEIVED,
  payload
})

const deleteInvoiceFailed = payload => ({
  type: DELETE_INVOICE_FAILED,
  payload
})

const clearCouponCode = () => ({
  type: CLEAR_COUPON_CODE
})

const clearCouponCodeReceived = payload => ({
  type: CLEAR_COUPON_CODE_RECEIVED,
  payload
})

const clearCouponCodeFailed = () => ({
  type: CLEAR_COUPON_CODE_FAILED
})

const setScopeQuantity = payload => ({
  type: SET_SCOPE_QUANTITY,
  payload
})

const setScopesPaymentAcceptedModalVisibility = payload => ({
  type: SET_SCOPES_PAYMENT_ACCEPTED_MODAL_VISIBILITY,
  payload
})

const requestAttachCreditCard = payload => ({
  type: REQUEST_ATTACH_CREDIT_CARD,
  payload
})

const attachCreditCardReceived = payload => ({
  type: ATTACH_CREDIT_CARD_RECEIVED,
  payload
})

const attachCreditCardFailed = payload => ({
  type: ATTACH_CREDIT_CARD_FAILED,
  payload
})

const fetchTransactionPdf = payload => ({
  type: FETCH_TRANSACTION_PDF,
  payload
})

const fetchTransactionPdfReceived = payload => ({
  type: FETCH_TRANSACTION_PDF_RECEIVED,
  payload
})

const fetchTransactionPdfFailed = payload => ({
  type: FETCH_TRANSACTION_PDF_FAILED,
  payload
})

export default {
  SAVE_PAYMENT_METHOD_FAILED,
  SAVE_PAYMENT_METHOD_RECEIVED,
  SAVE_PAYMENT_METHOD,
  FETCH_GRIN_PLANS,
  GRIN_PLANS_RECEIVED,
  GRIN_PLANS_FAILED,
  FETCH_DOCTOR_PAYMENT_METHOD,
  DOCTOR_PAYMENT_METHOD_RECEIVED,
  DOCTOR_PAYMENT_METHOD_FAILED,
  SET_UPGRADE_PLAN_WARNING_MODAL_VISIBILITY,
  SET_UPGRADE_PLAN_FOR_SCOPES_WARNING_MODAL_VISIBILITY,
  SET_SELECT_PLAN_MODAL_VISIBILITY,
  SET_SELECTED_PLAN_GROUP_AND_PERIOD,
  SET_SELECTED_PLAN_SCOPE_QUANTITY_AND_SHIPPING_ADDRESS,
  SET_UPGRADE_PLAN_DIALOG_VISIBILITY,
  UPGRADE_PLAN,
  UPGRADE_PLAN_RECEIVED,
  UPGRADE_PLAN_FAILED,
  SET_ORDER_GRIN_KITS_MODAL_VISIBILITY,
  CREATE_ORDER_GRIN_KITS,
  CREATE_ORDER_GRIN_KITS_RECEIVED,
  CREATE_ORDER_GRIN_KITS_FAILED,
  FETCH_DOCTOR_TRANSACTIONS,
  DOCTOR_TRANSACTIONS_RECEIVED,
  DOCTOR_TRANSACTIONS_FAILED,
  FETCH_DOCTOR_SUBSCRIPTION,
  DOCTOR_SUBSCRIPTION_RECEIVED,
  DOCTOR_SUBSCRIPTION_FAILED,
  SUBMIT_PLAN_UPGRADE_REQUEST,
  SUBMIT_PLAN_UPGRADE_REQUEST_FAILED,
  SUBMIT_PLAN_UPGRADE_REQUEST_RECEIVED,
  CALCULATE_ORDER_TAX,
  ORDER_TAX_FAILED,
  ORDER_TAX_RECEIVED,
  SUBMIT_PLAN_CANCELLATION_REQUEST,
  SUBMIT_PLAN_CANCELLATION_REQUEST_FAILED,
  SUBMIT_PLAN_CANCELLATION_REQUEST_RECEIVED,
  FETCH_DOCTOR_SEATS,
  FETCH_DOCTOR_SEATS_FAILED,
  FETCH_DOCTOR_SEATS_RECEIVED,
  RESET_ADDRESS_VALIDATION_RESULT,
  VALIDATE_ADDRESS,
  VALIDATE_ADDRESS_FAILED,
  VALIDATE_ADDRESS_RECEIVED,
  APPLY_COUPON_CODE,
  APPLY_COUPON_CODE_SUCCESS,
  APPLY_COUPON_CODE_FAILURE,
  SET_COUPON_MANUALLY,
  CLEAR_COUPON_CODE,
  CLEAR_COUPON_CODE_RECEIVED,
  CLEAR_COUPON_CODE_FAILED,
  RESET_COUPON,
  DELETE_INVOICE,
  DELETE_INVOICE_FAILED,
  DELETE_INVOICE_RECEIVED,
  SET_SCOPE_QUANTITY,
  SET_SCOPES_PAYMENT_ACCEPTED_MODAL_VISIBILITY,
  REQUEST_ATTACH_CREDIT_CARD,
  ATTACH_CREDIT_CARD_RECEIVED,
  ATTACH_CREDIT_CARD_FAILED,
  FETCH_TRANSACTION_PDF,
  FETCH_TRANSACTION_PDF_RECEIVED,
  FETCH_TRANSACTION_PDF_FAILED,
  requestAttachCreditCard,
  attachCreditCardReceived,
  attachCreditCardFailed,
  savePaymentMethod,
  savePaymentMethodFailed,
  savePaymentMethodReceived,
  fetchGrinPlans,
  grinPlansReceived,
  grinPlansFailed,
  fetchDoctorPaymentMethod,
  doctorPaymentMethodReceived,
  doctorPaymentMethodFailed,
  setUpgradePlanWarningModalVisibility,
  setUpgradePlanForScopesWarningModalVisibility,
  setSelectPlanModalVisibility,
  setSelectedPlanGroupAndPeriod,
  setSelectedPlanScopesQuantityAndAddress,
  setUpgradePlanDialogVisibility,
  upgradePlan,
  upgradePlanReceived,
  upgradePlanFailed,
  setOrderGrinKitsModalVisibility,
  createOrderGrinKits,
  createOrderGrinKitsReceived,
  createOrderGrinKitsFailed,
  fetchDoctorTransactions,
  doctorTransactionsReceived,
  doctorTransactionsFailed,
  fetchDoctorSubscription,
  doctorSubscriptionReceived,
  doctorSubscriptionFailed,
  submitPlanUpgradeRequest,
  submitPlanUpgradeRequestFailed,
  submitPlanUpgradeRequestReceived,
  calculateOrderTax,
  ordersTaxReceived,
  ordersTaxFailed,
  submitPlanCancellationRequest,
  submitPlanCancellationRequestFailed,
  submitPlanCancellationRequestReceived,
  fetchDoctorSeats,
  fetchDoctorSeatsReceived,
  fetchDoctorSeatsFailed,
  validateAddress,
  validateAddressReceived,
  validateAddressFailed,
  resetAddressValidationResult,
  applyCouponCode,
  applyCouponCodeSuccess,
  applyCouponCodeFailure,
  setCouponManually,
  resetCoupon,
  deleteInvoice,
  deleteInvoiceFailed,
  deleteInvoiceReceived,
  clearCouponCode,
  clearCouponCodeReceived,
  clearCouponCodeFailed,
  setScopeQuantity,
  setScopesPaymentAcceptedModalVisibility,
  fetchTransactionPdf,
  fetchTransactionPdfReceived,
  fetchTransactionPdfFailed
}
