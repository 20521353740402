import React, { useCallback, useMemo } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { TIME_FORMAT_9 } from 'consts/dateTimeConsts'
import Actions from 'actions'
import RecordsButtons from './RecordsButtons'
import { trackEvent } from 'utils/analyticsUtils'
import { logInfo } from 'utils/logUtils'

const useStyles = makeStyles({
  recordListContainer: {
    padding: '15px 0 15px 15px',
    cursor: 'pointer',
    borderBottom: '1px solid var(--border-color-10)'
  },
  recordsDetails: {
    color: 'var(--text-color-12)'
  }
})

const RecordsListItem = ({ record, setSelectedRecord }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const patientName = useSelector(state => state.patientsReducer.patientCard.patient.details.name)

  const files = useMemo(
    () => [...record.oralImages, ...record.stls, ...record.panoramics],
    [record.oralImages, record.stls, record.panoramics]
  )

  const isDownloadEnabled = useMemo(() => !!files.length, [files])

  const handleDeleteRecordsSet = useCallback(
    event => {
      trackEvent('Records - delete  set', {
        oralImages: record.oralImages?.length ?? 0,
        panoramics: record.panoramics?.length ?? 0,
        stls: record.stls?.length ?? 0
      })
      event.stopPropagation()
      event.preventDefault()
      dispatch(Actions.deleteRecordsSet({ id: record.id }))
    },
    [dispatch, record]
  )

  const handleDownloadRecordsSet = useCallback(
    event => {
      trackEvent('Records - download record set', {
        oralImages: record.oralImages?.length ?? 0,
        panoramics: record.panoramics?.length ?? 0,
        stls: record.stls?.length ?? 0,
        recordSetId: record.id
      })

      logInfo(`RecordsListItem: downloading inital scan files`, {
        files,
        initialScanId: record.id
      })

      event.stopPropagation()
      event.preventDefault()
      isDownloadEnabled &&
        dispatch(
          Actions.downloadPatientFilesZip({
            files: files.map((file, i) => ({ ...file, outputFilename: `${i + 1}` })),
            name: `${patientName}-recordsSet-${record.date}`
          })
        )
    },
    [dispatch, files, patientName, record, isDownloadEnabled]
  )

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={classes.recordListContainer}
      onClick={() => setSelectedRecord(record)}
      key={record.id}
    >
      <Grid item xs={2}>
        <DazzedParagraph14 strong>{moment(record.date).format(TIME_FORMAT_9)}</DazzedParagraph14>
      </Grid>
      <Grid item lg={9} xs={8}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <DazzedParagraph14 className={classes.recordsDetails}>
              {t('dialogs.patientInfo.records.details', {
                photos: record.oralImages.length,
                stls: record.stls.length,
                xRays: record.panoramics.length
              })}
            </DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={1} xs={2}>
        <RecordsButtons
          isDeleteEnabled
          handleDelete={handleDeleteRecordsSet}
          handleDownloadZip={handleDownloadRecordsSet}
          activeDownload={isDownloadEnabled}
        />
      </Grid>
    </Grid>
  )
}

export default RecordsListItem
