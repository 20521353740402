import React from 'react'
import { TableHead, TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { HeaderCell } from './Cells'

const PatientsTableHeader = ({
  sortOrder = 'desc',
  sortBy,
  onSortOrderChange = () => null,
  onRefresh = () => null
}) => {
  const { t } = useTranslation()
  return (
    <TableHead>
      <TableRow>
        <HeaderCell
          label={t('pages.taskManager.table.patientDetails')}
          withSort
          sortField="name"
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSortOrderChange={onSortOrderChange}
        />
        <HeaderCell label={t('pages.taskManager.table.program')} />
        <HeaderCell label={t('pages.taskManager.table.status')} />
        <HeaderCell label={t('pages.taskManager.table.actionRequired')} />
        <HeaderCell
          label={t('pages.taskManager.table.lastUpdate')}
          withSort
          sortField="lastInteractionTimestamp"
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSortOrderChange={onSortOrderChange}
        />
        {/* <HeaderCell label={t('pages.taskManager.table.refresh')} withRefresh onRefresh={onRefresh} /> */}
      </TableRow>
    </TableHead>
  )
}

export default React.memo(PatientsTableHeader)
