import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Tablet, WelcomeToGrin } from 'components/common/icons'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import useRolePermissions from 'hooks/useRolePermissions'
import DetailedEmptyState from './DetailedEmptyState'
import { useSelector } from 'react-redux'
import usePlanLimits from 'hooks/usePlanLimits'
import SupportDashboardPicker from 'components/Support/SupportDashboard/SupportDashboardPicker'

const useStyles = () =>
  makeStyles({
    noPatientSelected: {
      marginTop: 10
    },
    noPatientSelectedContainer: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },
    supportEmptyStateContainer: {
      height: '100%'
    }
  })()

const ChatEmptyState = ({ name, showNoPatientSelected }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { permissions } = useRolePermissions()
  const isLoading = useSelector(state => state.chatReducer.isLoading)
  const { isGP } = usePlanLimits()

  return showNoPatientSelected ? (
    !isLoading && (
      <div className={classes.noPatientSelectedContainer}>
        <Tablet />
        <DazzedParagraph16 className={classes.noPatientSelected}>
          {t('pages.patients.noPatientSelected')}
        </DazzedParagraph16>
      </div>
    )
  ) : permissions.detailedEmptyState && isGP === false ? (
    <DetailedEmptyState hasPatients />
  ) : permissions.opsDashboardEmptyState ? (
    <SupportDashboardPicker />
  ) : (
    <Grid container alignItems="center" justifyContent="center" className={classes.supportEmptyStateContainer}>
      <Grid item>
        <WelcomeToGrin />
      </Grid>
    </Grid>
  )
}

export default ChatEmptyState
