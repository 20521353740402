import React, { useState, useCallback, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import TextArea from '../../../common/TextArea'
import OggHeading40 from '../../../common/text/OggHeading40'
import DazzedParagraph12 from '../../../common/text/DazzedParagraph12'
import FormInput from '../../../common/FormInput'
import AreaOfCoveragePicker from './AreaOfCoveragePicker'
import PrimaryButton from '../../../common/buttons/PrimaryButton'
import Teeth from '../../../common/icons/Teeth'
import useAppSettings from '../../../../hooks/useAppSettings'
import Actions from '../../../../actions'
import { isValidEmail, isValidZipcode } from '../../../../utils/generalUtils'
import { BIO_TEXT_MAX_LENGTH } from '../../../../consts/settings'
import { COUNTRY_US } from '../../../../consts/countryConsts'
import { isEmpty } from 'lodash'

const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid var(--border-color-13)',
    borderRadius: '0 0 50px 0',
    padding: 20,
    pointerEvents: ({ isOrthoEnabled }) => (isOrthoEnabled ? 'auto' : 'none'),
    opacity: ({ isOrthoEnabled }) => (isOrthoEnabled ? 1 : 0.5)
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    color: 'var(--text-color-6)',
    opacity: 1,
    textTransform: 'none',
    marginLeft: 5,
    marginTop: 2,
    marginBottom: 10,
    lineHeight: '18px',
    letterSpacing: 'normal',
    fontSize: 22,
    fontWeight: 400
  },
  subTitle: {
    color: 'var(--text-color-6)',
    opacity: 1,
    textTransform: 'none',
    marginBottom: 10,
    lineHeight: '18px',
    letterSpacing: 'normal',
    fontSize: 10
  },
  label: {
    color: 'var(--text-color-6)',
    opacity: 1,
    textTransform: 'none',
    marginBottom: 10,
    lineHeight: '18px',
    letterSpacing: 'normal'
  },
  prompt: {
    opacity: 0.5,
    fontWeight: 'normal',
    marginLeft: 4
  },
  datePicker: {
    fontSize: '12px'
  },
  charactersCountDown: {
    textAlign: 'end',
    opacity: ({ bioLimitReached }) => (bioLimitReached ? '1' : '0.5'),
    fontWeight: ({ bioLimitReached }) => (bioLimitReached ? '500' : 'normal'),
    color: ({ bioLimitReached }) => (bioLimitReached ? 'var(--text-color-17)' : 'var(--text-color-6)')
  },
  requestsContactInput: {
    border: '1px solid var(--border-color-11) !important',
    padding: '12px 16px',
    borderRadius: '4px',
    '& input': {
      fontSize: '12px !important',
      padding: '12px 4px',
      '&::placeholder': {
        fontSize: '12px',
        fontStyle: 'normal'
      }
    }
  },
  contact: {
    marginBottom: 15
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15
  }
}))

const OrthoConfiguration = () => {
  const { isOrthoEnabled } = useAppSettings()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const doctor = useSelector(state => state.practiceReducer.accountOwner)
  const isLoading = useSelector(state => state.profileReducer.isLoading)

  const [startPracticeYear] = useState(doctor.startPracticeYear || null)
  const [bio, setBio] = useState(doctor.bio || '')
  const [zips, setZips] = useState(doctor.allowedZipcodes ? doctor.allowedZipcodes.split(', ') : [])
  const [metricType, setMetricType] = useState(doctor.metricType || 'km')
  const [searchRadius, setSearchRadius] = useState(doctor.searchRadius || 30)
  const [contactName, setContactName] = useState(doctor.contactName || '')
  const [contactEmail, setContactEmail] = useState(doctor.contactEmail || '')

  const [formErrors, setFormErrors] = useState({})

  const classes = useStyles({ isOrthoEnabled, bioLimitReached: BIO_TEXT_MAX_LENGTH === bio.length })

  const validateForm = useCallback(() => {
    const errors = {}
    if (!!contactEmail && !isValidEmail(contactEmail)) {
      errors.contactEmail = t('messages.searchAreaCoverage.invalidEmail')
    }

    return errors
  }, [contactEmail, t])

  const getIsAreaChanged = useCallback(() => {
    return (
      doctor.allowedZipcodes !== zips.join(', ') ||
      doctor.metricType !== metricType ||
      doctor.searchRadius !== searchRadius
    )
  }, [doctor, metricType, searchRadius, zips])

  const getIsFormChanged = useCallback(() => {
    return (
      doctor.startPracticeYear !== startPracticeYear ||
      doctor.bio !== bio ||
      (doctor.contactName || '') !== contactName ||
      (doctor.contactEmail || '') !== contactEmail ||
      getIsAreaChanged()
    )
  }, [bio, contactEmail, contactName, doctor, getIsAreaChanged, startPracticeYear])

  const saveValues = useCallback(() => {
    const errors = validateForm()
    if (!isEmpty(errors)) {
      setFormErrors(errors)
      return
    }

    const doctorObject = {
      id: doctor.id,
      startPracticeYear,
      bio,
      allowedZipcodes: zips.join(', '),
      metricType,
      searchRadius,
      contactEmail,
      contactName,
      country: COUNTRY_US
    }
    dispatch(Actions.prepareOrthoConfigurationForSaving(doctorObject))
  }, [
    bio,
    contactEmail,
    contactName,
    dispatch,
    metricType,
    searchRadius,
    startPracticeYear,
    validateForm,
    zips,
    doctor.id
  ])

  const getValidatedZipCodes = useCallback(zips => {
    const country = COUNTRY_US
    return {
      validZipCodes: zips.filter(zip => isValidZipcode(zip, country)),
      invalidZipCodes: zips.filter(zip => !isValidZipcode(zip, country)),
      country
    }
  }, [])

  const handleChangeZips = useCallback(
    (type, newZips) => {
      const { validZipCodes, invalidZipCodes, country } = getValidatedZipCodes(newZips)
      if (invalidZipCodes.length) {
        dispatch(
          Actions.showSnackbar({
            type: `error`,
            text: t('messages.searchAreaCoverage.invalidZipCode', { zip: invalidZipCodes, country })
          })
        )
      }

      if (validZipCodes?.length !== zips.length) {
        setZips(validZipCodes)
      }
    },
    [getValidatedZipCodes, zips, dispatch, t]
  )

  // const renderInputMask = useCallback(
  //   year => {
  //     if (!year) {
  //       return ''
  //     }

  //     const countOfYears = moment().diff(moment(year), 'years')
  //     const yearsText = !countOfYears
  //       ? ''
  //       : countOfYears === 1
  //       ? t('pages.accountSettings.services.ortho.year', {
  //           count: countOfYears
  //         })
  //       : t('pages.accountSettings.services.ortho.years', {
  //           count: countOfYears
  //         })

  //     return t('pages.accountSettings.services.ortho.inPracticeSince', {
  //       year,
  //       yearsText
  //     })
  //   },
  //   [t]
  // )

  const isFormSubmittable = useMemo(() => !!zips.length && getIsFormChanged(), [getIsFormChanged, zips.length])

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Teeth />
        <OggHeading40 className={classes.title}>
          {t('pages.accountSettings.services.ortho.personalDataTitle')}
        </OggHeading40>
      </div>
      <DazzedParagraph12 className={classes.subTitle}>
        {t('pages.accountSettings.services.ortho.personalDataSubTitle')}
      </DazzedParagraph12>
      {/* <DazzedParagraph12 className={classes.label}>
        {t('pages.accountSettings.services.ortho.experienceLabel')}
      </DazzedParagraph12>
      <CustomDatePicker
        style={{ marginBottom: 10 }}
        value={startPracticeYear}
        handleDateChange={value => setStartPracticeYear(moment(value).format('YYYY'))}
        views={['year']}
        inputVariant="outlined"
        disablePast={false}
        disableFuture={true}
        format="yyyy"
        placeholder={t('pages.accountSettings.services.ortho.experiencePlaceholder')}
        inputClassName={classes.datePicker}
        inputValueMask={renderInputMask}
      /> */}
      <DazzedParagraph12 className={classes.label}>
        {t('pages.accountSettings.services.ortho.bioLabel')}
        <span className={classes.prompt}>
          {t('pages.accountSettings.services.ortho.bioPrompt', { length: BIO_TEXT_MAX_LENGTH })}
        </span>
      </DazzedParagraph12>
      <TextArea
        placeholder={t('pages.accountSettings.services.ortho.bioPlaceholder')}
        value={bio}
        setValue={setBio}
        keepErrorContainerWhenInactive={false}
        maxLength={BIO_TEXT_MAX_LENGTH}
        style={{ bright: true }}
        inputStyle={{
          border: '1px solid var(--border-color-11)',
          padding: '12px 16px',
          borderRadius: '4px',
          fontSize: '12px',
          marginTop: 0
        }}
        placeholderStyles={{
          fontStyle: 'normal',
          fontSize: '12px',
          opacity: 0.33
        }}
      />
      <DazzedParagraph12 className={classes.charactersCountDown}>
        {t('pages.accountSettings.services.ortho.bioCharactersCountDown', {
          maxLength: BIO_TEXT_MAX_LENGTH,
          currentLength: bio.length
        })}
      </DazzedParagraph12>

      <DazzedParagraph12 className={classes.label}>
        {t('pages.accountSettings.services.ortho.requestsContact.label')}
      </DazzedParagraph12>
      <Grid container spacing={2} className={classes.contact}>
        <Grid item xs={6}>
          <DazzedParagraph12 className={classes.label}>
            {t('pages.accountSettings.services.ortho.requestsContact.recipientName')}
          </DazzedParagraph12>
          <FormInput
            style={{ bright: true, thick: true }}
            value={contactName}
            keepErrorContainerWhenInactive={false}
            placeholder={t('pages.accountSettings.services.ortho.requestsContact.recipientNamePlaceholder')}
            setValue={setContactName}
            inputWrapperClassName={classes.requestsContactInput}
          />
        </Grid>
        <Grid item xs={6}>
          <DazzedParagraph12 className={classes.label}>
            {t('pages.accountSettings.services.ortho.requestsContact.email')}
          </DazzedParagraph12>
          <FormInput
            style={{ bright: true, thick: true }}
            value={contactEmail}
            keepErrorContainerWhenInactive={false}
            placeholder={t('pages.accountSettings.services.ortho.requestsContact.emailPlaceholder')}
            setValue={value => {
              setFormErrors()
              setContactEmail(value?.trim())
            }}
            inputWrapperClassName={classes.requestsContactInput}
            errorMessage={formErrors?.contactEmail}
          />
        </Grid>
      </Grid>

      <AreaOfCoveragePicker
        zips={zips}
        handleChangeZips={handleChangeZips}
        metricType={metricType}
        setMetricType={setMetricType}
        searchRadius={searchRadius}
        setSearchRadius={setSearchRadius}
        doctor={doctor}
      />

      <div className={classes.saveButtonContainer}>
        <PrimaryButton
          label={t('pages.accountSettings.services.ortho.saveChanges')}
          isLoading={isLoading}
          disabled={!isFormSubmittable}
          onClick={saveValues}
        />
      </div>
    </div>
  )
}

export default OrthoConfiguration
