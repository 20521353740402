import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { makeStyles, Grid, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import ChatModal from './ChatModal'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import usePromotions from 'hooks/usePromotions'
import Actions from 'actions'
import Product from '../Promotions/Product'
import LandscapePromotionsList from '../Promotions/LandscapePromotionsList'
import PortraitPromotionsList from '../Promotions/PortraitPromotionsList'
import { isLandscape } from 'utils/mobileUtils'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = ({ isLandscape }) =>
  makeStyles({
    container: {
      padding: '5px 0'
    },
    title: {
      lineHeight: '40px'
    },
    scrollContainer: {
      padding: 10
    },
    productsContainer: {
      height: ({ isLandscape }) => (isLandscape ? 233 : 370),
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    productItem: {
      width: 204,
      height: ({ isLandscape }) => (isLandscape ? 216 : 343),
      padding: 5,
      marginRight: 5
    },
    loadingContainer: {
      textAlign: 'center'
    }
  })({ isLandscape })

const PromotionsModal = ({ isOpen, handleClose, onPromotionSelection }) => {
  const classes = useStyles({ isLandscape: isLandscape() })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const scrollRef = useRef(null)

  const { groupedPromotions } = usePromotions()
  const isLoading = useSelector(state => state.chatReducer.promotions.isLoading)
  const roomId = useSelector(state => state.chatReducer.activeRoomId)

  const [activeTab, setActiveTab] = useState(0)

  const handleChangeTab = useCallback((e, value) => setActiveTab(value), [])
  const handlePromotionSelection = useCallback(
    product => {
      trackEvent('Promotions - product selected', { product })
      handleClose()
      onPromotionSelection(product)
    },
    [handleClose, onPromotionSelection]
  )

  useEffect(() => {
    if (roomId && isOpen) {
      dispatch(Actions.fetchLastPromotionMessages({ roomId }))
    }
  }, [dispatch, roomId, isOpen])

  useEffect(() => {
    if (isOpen) {
      setActiveTab(0)
    }
  }, [isOpen])
  useEffect(
    () =>
      scrollRef?.current.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      }),
    [activeTab]
  )

  const products = useMemo(() => groupedPromotions[activeTab]?.products || [], [groupedPromotions, activeTab])

  return (
    <ChatModal withCloseButton={!isLandscape()} open={isOpen} onClose={handleClose} className={classes.container}>
      {!isLandscape() && (
        <DazzedParagraph16 bold textAlign="center" className={classes.title}>
          {t('pages.patients.selectedPatient.chat.promotions.title')}
        </DazzedParagraph16>
      )}
      {isLoading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color="secondary" size={25} />
        </div>
      ) : (
        <>
          {!isLandscape() && (
            <PortraitPromotionsList
              groupedPromotions={groupedPromotions}
              activeTab={activeTab}
              handleChangeTab={handleChangeTab}
            />
          )}
          <Grid container>
            {isLandscape() && (
              <LandscapePromotionsList
                onModalClose={handleClose}
                groupedPromotions={groupedPromotions}
                activeTab={activeTab}
                handleChangeTab={handleChangeTab}
              />
            )}
            <Grid item xs={isLandscape() ? 9 : 12} className={classes.scrollContainer}>
              <Grid container className={classes.productsContainer} wrap="nowrap" ref={scrollRef}>
                {products.map(product => (
                  <Grid item key={product.id} className={classes.productItem}>
                    <Product product={product} onClick={() => handlePromotionSelection(product)} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </ChatModal>
  )
}

export default PromotionsModal
