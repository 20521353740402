import * as React from 'react'

const TrackingNoMovement = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 4 }}
  >
    <rect x="1" y="1" width="18" height="18" rx="4" fill="#D2FFD6" stroke="#C0F7C5" strokeWidth="2" />
    <path
      d="M15.4419 7.52307L15.8839 7.08113L15.4419 6.63919L14.3608 5.55806L13.9189 5.11612L13.4769 5.55806L8.34364 10.6913L6.52307 8.87078L6.08113 8.42884L5.63919 8.87078L4.55806 9.95191L4.11612 10.3939L4.55806 10.8358L7.9017 14.1794L8.34364 14.6214L8.78558 14.1794L15.4419 7.52307Z"
      stroke="#5CB664"
      strokeWidth="1.25"
    />
  </svg>
)

export default TrackingNoMovement
