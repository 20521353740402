import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { CircularProgress, makeStyles, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import ArrowBack from '@material-ui/icons/ArrowBackIosRounded'
import ArrowForward from '@material-ui/icons/ArrowForwardIosRounded'
import { withOrientationChange } from 'react-device-detect'
import { isMobile } from 'utils/mobileUtils'
import useRolePermissions from 'hooks/useRolePermissions'
import ActionButton from 'components/common/annotations/ActionButton'
import { Sketch, Delete1, Show, Hide } from 'components/common/icons'
import ImageAnnotations from '../Annotations/ImageAnnotations'
import ScanSummarySharpenButton from './ScanSummarySharpenButton'
import { finishTimeEvent, timeEvent, trackEvent } from 'utils/analyticsUtils'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { getObjectUrl } from 'utils/mediaUtils'
import PreventiveLayers from '../Preventive/PreventiveLayers'

const useStyles = ({ isLandscape, imageWidth, isMobile }) =>
  makeStyles({
    container: {
      borderRadius: '8px',
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
      height: isMobile ? (isLandscape ? window.innerHeight * 0.7 : '40vh') : '455px'
    },
    drawModeButton: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      borderTopLeftRadius: 10,
      '& svg': {
        fill: 'white'
      }
    },
    activeImage: {
      aspectRatio: 1.66,
      height: '100%',
      width: !isMobile ? '100%' : isLandscape ? 'auto' : '100%',
      '&.hidden': {
        visibility: 'hidden'
      },
      objectFit: isMobile ? 'contain' : 'cover'
    },
    imgLoaderContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    radioBtn: {
      color: 'var(--bg-color-10)'
    },
    radio: {
      position: 'absolute',
      left: 10,
      top: 10
    },
    notAvailable: {
      height: '100%',
      cursor: 'default',
      backgroundColor: 'var(--bg-color-35)',
      textAlign: 'center',
      boxShadow: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    notAvailableText: {
      color: 'var(--text-color-11)',
      fontSize: 32
    },
    arrow: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 60,
      height: 60,
      color: 'var(--text-color-1)',
      '& svg': {
        transform: 'scale(1.5)'
      },
      '& svg > path': {
        boxShadow: '4px 4px 8px 0px rgba(34, 60, 80, 0.6)'
      }
    },
    arrowBack: {
      left: ({ isMobile, imageWidth }) =>
        isMobile && imageWidth ? `calc(15px + (100% - ${imageWidth + 'px'}) /2)` : 10
    },
    arrowForward: {
      right: ({ isMobile, imageWidth }) =>
        isMobile && imageWidth ? `calc(15px + (100% - ${imageWidth + 'px'}) /2)` : 10
    },
    markButton: {
      marginLeft: 10,
      marginBottom: 10,
      border: '1px solid var(--border-color-4)'
    },
    preventiveLayers: {
      height: '100%',
      aspectRatio: 1.66
    }
  })({ isLandscape, imageWidth, isMobile })

const ScanSummaryActiveImage = ({
  activeImage,
  activeImagePosition,
  editedImages,
  sharpenedImages,
  setSharpenedImages,
  onNavigateBack,
  isLandscape,
  onNavigateForward,
  items,
  setEditedImages,
  showPreventiveLayers,
  preventiveImages,
  displayedPreventiveLayers
}) => {
  let activeImageRef = useRef(null)
  const containerRef = useRef()

  const { permissions } = useRolePermissions()
  const { t } = useTranslation()

  const [imageWidth, setImageWidth] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [localUrl, setLocalUrl] = useState(null)
  const [drawMode, setDrawMode] = useState(false)
  const [canvasWidth, setCanvasWidth] = useState(0)
  const [canvasHeight, setCanvasHeight] = useState(0)
  const [showSketch, setShowSketch] = useState(true)

  useEffect(() => {
    if (isMobile() && activeImageRef?.current) {
      let img = activeImageRef?.current
      const ratio = img.naturalWidth / img.naturalHeight
      let width = img.height * ratio
      if (width > img.width) {
        width = img.width
      }
      setImageWidth(width)
    }
  }, [activeImageRef?.current?.width])

  const classes = useStyles({ isLandscape, imageWidth, isMobile: isMobile() })

  const isImageAvailable = useMemo(() => !!activeImage, [activeImage])
  const isImageEdited = useMemo(() => editedImages[activeImagePosition], [editedImages, activeImagePosition])
  const url = useMemo(() => getObjectUrl(activeImage), [activeImage])

  useEffect(() => {
    setIsLoading(true)
    timeEvent('Load tracking - Scan summary active image')
    setLocalUrl(url)
    setShowSketch(true)
  }, [url])

  const onImageLoad = useCallback(
    ({ target: img }) => {
      setIsLoading(false)
      finishTimeEvent('Load tracking - Scan summary active image', {
        newScanSummaryArchitecture: url?.key ? url.key.includes('private') : false
      })
      setCanvasWidth(img.naturalWidth)
      setCanvasHeight(img.naturalHeight)
    },
    [url]
  )

  const handleDrawMode = useCallback(() => {
    setDrawMode(!drawMode)
    !drawMode &&
      trackEvent('Summary_EditImageClicked', {
        position: activeImagePosition
      })
  }, [setDrawMode, drawMode, activeImagePosition])

  const handleUpdateEditedImages = useCallback(
    image => {
      setEditedImages({
        ...editedImages,
        [activeImagePosition]: image
      })
      !image && setShowSketch(true)
    },
    [activeImagePosition, editedImages, setEditedImages]
  )

  return (
    <div ref={containerRef} className={classes.container}>
      <ImageAnnotations
        imageS3Object={activeImage}
        canvasWidth={canvasWidth}
        canvasHeight={canvasHeight}
        backgroundUrl={editedImages[activeImagePosition]}
        drawMode={drawMode}
        imagePosition={activeImagePosition}
        onUpdateImage={handleUpdateEditedImages}
        onDrawMode={handleDrawMode}
      />
      <div className={classes.drawModeButton}>
        {!isMobile() && permissions.scanSummaryAnnotations && isImageAvailable && !showPreventiveLayers && (
          <PrimaryButton
            width="160px"
            color="var(--bg-color-39)"
            icon={<Sketch />}
            fontSize={12}
            label={t('dialogs.scanSummary.annotations.mark')}
            onClick={handleDrawMode}
            className={classes.markButton}
          />
        )}
        {isImageEdited && (
          <>
            <ActionButton
              label={t('dialogs.scanSummary.annotations.clear')}
              onClick={() => handleUpdateEditedImages('')}
            >
              <Delete1 />
            </ActionButton>
            <ActionButton
              label={t(`dialogs.scanSummary.annotations.${showSketch ? 'hide' : 'show'}`)}
              onClick={() => setShowSketch(!showSketch)}
            >
              {showSketch ? <Hide /> : <Show />}
            </ActionButton>
          </>
        )}
      </div>
      {isImageAvailable && (
        <>
          {isLoading && (
            <div className={classes.imgLoaderContainer}>
              <CircularProgress />
            </div>
          )}
          {showPreventiveLayers ? (
            <PreventiveLayers
              backgroundImageUrl={localUrl}
              preventiveImages={preventiveImages}
              className={classes.preventiveLayers}
              position={activeImagePosition}
              displayedLayers={displayedPreventiveLayers}
              onLoad={() => setIsLoading(false)}
            />
          ) : (
            <img
              key={localUrl}
              src={(showSketch && editedImages[activeImagePosition]) || localUrl}
              alt="Selected"
              ref={activeImageRef}
              onLoad={onImageLoad}
              className={[classes.activeImage, isLoading ? 'hidden' : ''].join(' ')}
            />
          )}
        </>
      )}
      {!isImageAvailable && (
        <div className={classes.notAvailable}>
          <DazzedParagraph16 className={classes.notAvailableText}>
            {t('dialogs.scanSummary.couldntBeCaptured')}
          </DazzedParagraph16>
        </div>
      )}
      {!isImageEdited && (
        <ScanSummarySharpenButton
          isLoading={isLoading}
          isImageAvailable={isImageAvailable}
          sharpenedImages={sharpenedImages}
          setSharpenedImages={setSharpenedImages}
        />
      )}
      <IconButton className={[classes.arrow, classes.arrowBack].join(' ')} onClick={onNavigateBack}>
        <ArrowBack />
      </IconButton>
      <IconButton className={[classes.arrow, classes.arrowForward].join(' ')} onClick={onNavigateForward}>
        <ArrowForward />
      </IconButton>
    </div>
  )
}

export default React.memo(withOrientationChange(ScanSummaryActiveImage))
