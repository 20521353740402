import { findLastIndex } from 'lodash'
import { useMemo } from 'react'
import Calendar from 'resources/icons/timeline/calendar.svg'
import Check from 'resources/icons/timeline/check.svg'
import CreditCard from 'resources/icons/timeline/creditCard.svg'
import Doc from 'resources/icons/timeline/doc.svg'
import Eye from 'resources/icons/timeline/eye.svg'
import Grin from 'resources/icons/timeline/grin.svg'
import Star from 'resources/icons/timeline/star.svg'
import Tooth from 'resources/icons/timeline/tooth.svg'
import Clock from 'resources/icons/timeline/clock.svg'
import Box from 'resources/icons/timeline/box.svg'

export default ({ patient, treatment, proposal, initialScan, treatmentPlan }) => {
  const MilestoneDefinitions = useMemo(
    () => [
      {
        type: 'newPatient',
        icon: Star,
        condition: () => true
      },
      {
        type: 'scanSubmitted',
        icon: Check,
        condition: ({ patient }) => patient?.grinScans?.items.length > 0
      },
      {
        type: 'scanReviewRequired',
        icon: Eye,
        condition: ({ patient }) =>
          patient?.grinScans.items.length > 0 &&
          !patient?.grinScans.items.find(scan => scan.scanReviews?.items.length === 0)
      },
      {
        type: 'createPlan',
        icon: Doc,
        condition: ({ proposal }) => !!proposal
      },
      {
        type: 'proposalIsBeingReviewed',
        icon: Grin,
        condition: ({ proposal }) => proposal?.status === 'approved'
      },
      {
        type: 'payment',
        icon: CreditCard,
        condition: ({ proposal }) => proposal?.status === 'purchased'
      },
      {
        type: 'scheduleAppointment',
        icon: Calendar,
        condition: ({ treatment }) => treatment?.introMeetingDate
      },
      {
        type: 'uploadScans',
        icon: Tooth,
        condition: ({ initialScan }) => !!initialScan?.id
      },
      {
        type: 'createTreatmentPlan',
        icon: Doc,
        condition: ({ treatmentPlan }) => !!treatmentPlan
      },
      {
        type: 'setScanSettings',
        icon: Clock,
        condition: ({ patient }) =>
          patient.treatments?.items[0]?.currentStatus?.status?.type !== 'new-lead' &&
          patient.treatments?.items[0]?.currentStatus?.status?.type !== 'invited'
      },
      {
        type: 'updateApplianceArrival',
        icon: Box,
        condition: ({ patient }) =>
          patient?.actionItems?.items.find(
            action => action.type === 'updateApplianceArrival' && action.status === 'resolved'
          )
      },
      {
        type: 'treatmentInProgress',
        icon: Check,
        condition: ({ patient }) => patient.treatments?.items[0]?.currentStatus?.status?.type === 'active-treatment'
      }
    ],
    []
  )

  const lastCompletedMilestoneIndex = useMemo(
    () =>
      findLastIndex(MilestoneDefinitions, milestone =>
        milestone.condition({
          patient,
          treatment,
          proposal,
          initialScan,
          treatmentPlan
        })
      ),
    [MilestoneDefinitions, patient, treatment, proposal, initialScan, treatmentPlan]
  )

  const lastCompletedMilestone = useMemo(
    () => (lastCompletedMilestoneIndex === -1 ? null : MilestoneDefinitions[lastCompletedMilestoneIndex].type),
    [MilestoneDefinitions, lastCompletedMilestoneIndex]
  )

  const milestones = useMemo(
    () =>
      MilestoneDefinitions.map((milestone, index) => ({
        ...milestone,
        done: index <= lastCompletedMilestoneIndex
      })),
    [MilestoneDefinitions, lastCompletedMilestoneIndex]
  )

  return { milestones, lastCompletedMilestone }
}
