import * as React from 'react'
const OrderGrinScopes = ({ ...props }) => {
  return (
    <svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.62283 1.94291C8.85063 1.37343 9.40219 1 10.0155 1H18.9845C19.5978 1 20.1494 1.37343 20.3772 1.94291L21.3315 4.32874C21.7606 5.40159 21.7606 6.59841 21.3315 7.67126L20.3772 10.0571C20.1494 10.6266 19.5978 11 18.9845 11H10.0155C9.40219 11 8.85063 10.6266 8.62283 10.0571L7.6685 7.67126C7.23936 6.59841 7.23936 5.40159 7.6685 4.32874L8.62283 1.94291Z"
        stroke="#041333"
      />
      <path
        d="M10.0554 3.84119C10.3071 3.32642 10.83 3 11.403 3H17.597C18.17 3 18.6929 3.32642 18.9446 3.84118L19.0337 4.02355C19.6434 5.27065 19.6434 6.72934 19.0337 7.97645L18.9446 8.15881C18.6929 8.67358 18.17 9 17.597 9H11.403C10.83 9 10.3071 8.67358 10.0554 8.15882L9.96626 7.97645C9.35657 6.72935 9.35657 5.27066 9.96626 4.02355L10.0554 3.84119Z"
        stroke="#041333"
      />
      <path
        d="M8.5 2.16666L2.14382 3.72039C1.47236 3.88452 1 4.48626 1 5.17749V6.82249C1 7.51372 1.47236 8.11546 2.14382 8.27959L8.5 9.83332"
        stroke="#041333"
      />
    </svg>
  )
}

export default OrderGrinScopes
