import moment from 'moment'
import { getEnvironment } from 'utils/awsUtils'

const simpleAlignersTxTrackerByTeeth = {
  2: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-02-12T00:00:00.000ZT00:00:00.000Z',
      currentProgress: 0.0183331724256277,
      deviation: null
    }
  ],
  3: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.0104923248291015,
      deviation: null
    }
  ],
  4: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.179875448346138,
      deviation: 0.04667
    }
  ],
  5: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.0136515479534864,
      deviation: null
    }
  ],
  6: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.0203605666756629,
      deviation: null
    }
  ],
  7: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.03558543138206,
      deviation: null
    }
  ],
  8: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.054524227976799,
      deviation: null
    }
  ],
  9: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.0729700624942779,
      deviation: null
    }
  ],
  10: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.0746350735425949,
      deviation: null
    }
  ],
  11: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.2130365520715713,
      deviation: 0.02261
    }
  ],
  12: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.2345156744122505,
      deviation: 0.0
    }
  ],
  13: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.1511469036340713,
      deviation: 0.0
    }
  ],
  14: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.0686192959547042,
      deviation: null
    }
  ],
  15: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.0792257934808731,
      deviation: null
    }
  ],
  18: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.0612486228346824,
      deviation: null
    }
  ],
  19: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.0861654207110405,
      deviation: null
    }
  ],
  20: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.1714648306369781,
      deviation: null
    }
  ],
  21: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.2101061046123504,
      deviation: null
    }
  ],
  22: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.1964713111519813,
      deviation: null
    }
  ],
  23: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.1679010167717933,
      deviation: null
    }
  ],
  24: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.0611619967967271,
      deviation: null
    }
  ],
  25: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.1102745980024337,
      deviation: null
    }
  ],
  26: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.1141213998198509,
      deviation: null
    }
  ],
  27: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.2372221946716308,
      deviation: null
    }
  ],
  28: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.1841866075992584,
      deviation: null
    }
  ],
  29: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.2360323220491409,
      deviation: null
    }
  ],
  30: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.0558829493820667,
      deviation: null
    }
  ],
  31: [
    {
      createdAt: '2024-02-12T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2024-03-31T00:00:00.000Z',
      currentProgress: 0.1573749482631683,
      deviation: null
    }
  ]
}

const alignersTxTrackerByTeeth = {
  2: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.3784040808677673,
      deviation: null
    }
  ],
  3: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.2122786939144134,
      deviation: null
    }
  ],
  4: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.3170665353536606,
      deviation: 0.05
    }
  ],
  5: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.3168398141860962,
      deviation: 0.1
    }
  ],
  6: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.1490199154615402,
      deviation: 0.15
    }
  ],
  7: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.1574322879314422,
      deviation: null
    }
  ],
  8: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.6942868041992188,
      deviation: 0.20507
    }
  ],
  9: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.5079766511917114,
      deviation: 0.23867
    }
  ],
  10: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.2840317189693451,
      deviation: null
    }
  ],
  11: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.3580116629600525,
      deviation: 0.12
    }
  ],
  12: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.2362730503082275,
      deviation: 0.05
    }
  ],
  13: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.2408637851476669,
      deviation: null
    }
  ],
  14: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.1751712039113044,
      deviation: null
    }
  ],
  18: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.45869180560112,
      deviation: null
    }
  ],
  19: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.1534493789076805,
      deviation: null
    }
  ],
  20: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.1622559428215026,
      deviation: null
    }
  ],
  21: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.2923153638839721,
      deviation: null
    }
  ],
  22: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.2030440753698349,
      deviation: 0.40839
    }
  ],
  23: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.2782356888055801,
      deviation: null
    }
  ],
  24: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.1569234952330589,
      deviation: null
    }
  ],
  26: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.2460280135273933,
      deviation: null
    }
  ],
  27: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.2092240178585052,
      deviation: 0.20178
    }
  ],
  28: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.2327850759029388,
      deviation: null
    }
  ],
  29: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.1963328570127487,
      deviation: null
    }
  ],
  30: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.111209437251091,
      deviation: null
    }
  ],
  31: [
    {
      createdAt: '2021-12-13T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-03-23T00:00:00.000Z',
      currentProgress: 0.2822907865047455,
      deviation: null
    }
  ]
}

const bracesTxTrackerByTeeth = {
  2: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.3044104427099228,
      deviation: 0.4943
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.2742896229028702,
      deviation: 0.07107
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.6272694170475006,
      deviation: 0
    }
  ],
  3: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.0502978190779686,
      deviation: 0.29278
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.216847957670689,
      deviation: 0.60978
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.5121695697307587,
      deviation: 0.51368
    }
  ],
  4: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.0770240537822246,
      deviation: 0.3922
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.0665599107742309,
      deviation: 0.34596
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.5887300074100494,
      deviation: 0.61522
    }
  ],
  5: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.406157448887825,
      deviation: 0.04742
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.468028575181961,
      deviation: 0.72018
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.6468590497970581,
      deviation: 0
    }
  ],
  6: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.2065894156694412,
      deviation: 0.14424
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.3624164015054703,
      deviation: 0.72975
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.3835542798042297,
      deviation: 0.6303
    }
  ],
  7: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.2092212289571762,
      deviation: 0.12481
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.3677500188350677,
      deviation: 0.43865
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.5456689894199371,
      deviation: 0.32968
    }
  ],
  8: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.3100441396236419,
      deviation: 0.19438
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.4150493592023849,
      deviation: 0.14289
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.932752251625061,
      deviation: 0
    }
  ],
  9: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.4569743871688843,
      deviation: 0.72316
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.4795405268669128,
      deviation: 0.08035
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.7496334314346313,
      deviation: 0.64138
    }
  ],
  10: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.1984383910894394,
      deviation: 0.19299
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.3508036434650421,
      deviation: 0.61815
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.4962644428014755,
      deviation: 0.40488
    }
  ],
  11: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.4279364049434662,
      deviation: 0.75494
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.3465944528579712,
      deviation: 0.85578
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.543916642665863,
      deviation: 0.4935
    }
  ],
  12: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.2434270530939102,
      deviation: 0.57627
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.3626706302165985,
      deviation: 0.42472
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.3936923742294311,
      deviation: 0.31799
    }
  ],
  13: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.2649314105510711,
      deviation: 0.03414
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.0633072927594184,
      deviation: 0.22905
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.389206975698471,
      deviation: 0.45686
    }
  ],
  14: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.2678715586662292,
      deviation: 0.06959
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.2611085176467895,
      deviation: 0.06959
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.4581523835659027,
      deviation: 0.50758
    }
  ],
  15: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.2441438287496566,
      deviation: 0.08839
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.3309777677059173,
      deviation: 0.55875
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.6021267473697662,
      deviation: 0
    }
  ],
  16: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    }
  ],
  17: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    }
  ],
  18: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.1718649268150329,
      deviation: 0.25931
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.1761629581451416,
      deviation: 0.25931
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.5416974425315857,
      deviation: 0.44765
    }
  ],
  19: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.1178641468286514,
      deviation: 0.16803
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.1955391988158226,
      deviation: 0.4418
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.0256747920066118,
      deviation: 0.46164
    }
  ],
  20: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.4883695393800735,
      deviation: 0
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.4394789189100265,
      deviation: 0.54383
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.4743549823760986,
      deviation: 0.60507
    }
  ],
  21: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.480673611164093,
      deviation: 0
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.418826162815094,
      deviation: 0.54599
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.5476183891296387,
      deviation: 0
    }
  ],
  22: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.3166059255599975,
      deviation: 0.02006
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.2285277545452118,
      deviation: 0.08967
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.2800400555133819,
      deviation: 0.2973
    }
  ],
  23: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.477361187338829,
      deviation: 0.16357
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.4129277765750885,
      deviation: 0.19017
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.7191266417503357,
      deviation: 0.4518
    }
  ],
  24: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.4449311047792434,
      deviation: 0.60789
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.5040809512138367,
      deviation: 0.22551
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 1.0242297649383545,
      deviation: 0.49357
    }
  ],
  25: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.4434473812580108,
      deviation: 0.33146
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.5127229690551758,
      deviation: 0.27655
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 1.129100680351257,
      deviation: 0.60596
    }
  ],
  26: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.3958863914012909,
      deviation: 0.22918
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.5618507266044617,
      deviation: 0.55426
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.7796830534934998,
      deviation: 0.47533
    }
  ],
  27: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.1253744661808014,
      deviation: 0.09144
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.128502607345581,
      deviation: 0.20053
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.208875298500061,
      deviation: 0
    }
  ],
  28: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.1860495954751968,
      deviation: 0.19364
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.0982555896043777,
      deviation: 0.32652
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.4861057102680206,
      deviation: 0.57338
    }
  ],
  29: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.2452328950166702,
      deviation: 0.28094
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.2680045962333679,
      deviation: 0.31508
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.4371131062507629,
      deviation: 0.0978
    }
  ],
  30: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.1523537784814834,
      deviation: 0.09944
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.1600379645824432,
      deviation: 0.16887
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.3314902186393738,
      deviation: 0
    }
  ],
  31: [
    {
      createdAt: '2023-05-11T00:00:00.000Z',
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: '2023-07-02T21:00:00.000Z',
      currentProgress: 0.0987303256988525,
      deviation: 0.17545
    },
    {
      createdAt: '2023-08-21T21:00:00.000Z',
      currentProgress: 0.3316892981529236,
      deviation: 0.32887
    },
    {
      createdAt: '2024-03-27T21:00:00.000Z',
      currentProgress: 0.3638560771942138,
      deviation: 0.32887
    }
  ]
}

export const txTrackerDataByTeeth = {
  12: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.23,
      deviation: 0
    }
  ],
  13: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.15,
      deviation: 0
    }
  ],
  5: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 29 }).startOf('day').toISOString(),
      currentProgress: 0.01,
      deviation: null
    }
  ],
  4: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.17,
      deviation: 0.04
    }
  ],
  6: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.02,
      deviation: null
    }
  ],
  7: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.03,
      deviation: null
    }
  ],
  11: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.21,
      deviation: 0.02
    }
  ],
  10: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.07,
      deviation: null
    }
  ],
  14: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.06,
      deviation: null
    }
  ],
  8: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.05,
      deviation: null
    }
  ],
  2: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.01,
      deviation: null
    }
  ],
  1: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    }
  ],
  3: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0.1,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.06,
      deviation: null
    }
  ],
  9: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.07,
      deviation: null
    }
  ],
  15: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.07,
      deviation: null
    }
  ],
  16: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.06,
      deviation: null
    }
  ],
  17: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    }
  ],
  18: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.06,
      deviation: null
    }
  ],
  19: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.08,
      deviation: null
    }
  ],
  20: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.17,
      deviation: null
    }
  ],
  21: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.21,
      deviation: null
    }
  ],
  22: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.19,
      deviation: null
    }
  ],
  23: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.16,
      deviation: null
    }
  ],
  24: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.06,
      deviation: null
    }
  ],
  25: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.11,
      deviation: null
    }
  ],
  26: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.11,
      deviation: null
    }
  ],
  27: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.23,
      deviation: null
    }
  ],
  28: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.18,
      deviation: null
    }
  ],
  29: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.23,
      deviation: null
    }
  ],
  30: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.05,
      deviation: null
    }
  ],
  31: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0.15,
      deviation: null
    }
  ],
  32: [
    {
      createdAt: moment({ year: 2023, month: 2, day: 12 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    },
    {
      createdAt: moment({ year: 2023, month: 3, day: 30 }).startOf('day').toISOString(),
      currentProgress: 0,
      deviation: 0
    }
  ]
}

// Aligners

// Simple aligners case
const simpleAlignersTreatmentMockData = {
  scanId: 'a765ec8d-fa74-4116-a15a-dd06258e529b',
  teeth: {
    2: {
      deviation: null,
      totalPlannedMovement: 0.2314417213201522,
      currentProgress: 0.0183331724256277,
      currentPlannedProgress: 0.0557059068232774
    },
    3: {
      deviation: null,
      totalPlannedMovement: 0.136067047715187,
      currentProgress: 0.0104923248291015,
      currentPlannedProgress: 0.0292816311120986
    },
    4: {
      deviation: 0.04667,
      totalPlannedMovement: 0.5775922536849976,
      currentProgress: 0.179875448346138,
      currentPlannedProgress: 0.2130474746227264
    },
    5: {
      deviation: null,
      totalPlannedMovement: 0.5662521719932556,
      currentProgress: 0.0136515479534864,
      currentPlannedProgress: 0.1796279549598693
    },
    6: {
      deviation: null,
      totalPlannedMovement: 0.4887669682502746,
      currentProgress: 0.0203605666756629,
      currentPlannedProgress: 0.157586008310318
    },
    7: {
      deviation: null,
      totalPlannedMovement: 0.2596593052148819,
      currentProgress: 0.03558543138206,
      currentPlannedProgress: 0.0944015756249427
    },
    8: {
      deviation: null,
      totalPlannedMovement: 0.5740934312343597,
      currentProgress: 0.054524227976799,
      currentPlannedProgress: 0.2117628306150436
    },
    9: {
      deviation: null,
      totalPlannedMovement: 0.676600456237793,
      currentProgress: 0.0729700624942779,
      currentPlannedProgress: 0.1519245207309723
    },
    10: {
      deviation: null,
      totalPlannedMovement: 0.5427375435829163,
      currentProgress: 0.0746350735425949,
      currentPlannedProgress: 0.0688951686024665
    },
    11: {
      deviation: 0.02261,
      totalPlannedMovement: 0.3584510684013366,
      currentProgress: 0.2130365520715713,
      currentPlannedProgress: 0.256669670343399
    },
    12: {
      deviation: 0.0,
      totalPlannedMovement: 0.8890089392662048,
      currentProgress: 0.2345156744122505,
      currentPlannedProgress: 0.2704605758190155
    },
    13: {
      deviation: 0.0,
      totalPlannedMovement: 0.6394598484039307,
      currentProgress: 0.1511469036340713,
      currentPlannedProgress: 0.1777872294187545
    },
    14: {
      deviation: null,
      totalPlannedMovement: 0.1091331765055656,
      currentProgress: 0.0686192959547042,
      currentPlannedProgress: 0.0689269602298736
    },
    15: {
      deviation: null,
      totalPlannedMovement: 0.3208678364753723,
      currentProgress: 0.0792257934808731,
      currentPlannedProgress: 0.1187063083052635
    },
    18: {
      deviation: null,
      totalPlannedMovement: 0.090734839439392,
      currentProgress: 0.0612486228346824,
      currentPlannedProgress: 0.0612486228346824
    },
    19: {
      deviation: null,
      totalPlannedMovement: 0.3074637949466705,
      currentProgress: 0.0861654207110405,
      currentPlannedProgress: 0.0861654207110405
    },
    20: {
      deviation: null,
      totalPlannedMovement: 0.5144954323768616,
      currentProgress: 0.1714648306369781,
      currentPlannedProgress: 0.1714648306369781
    },
    21: {
      deviation: null,
      totalPlannedMovement: 0.4576366543769836,
      currentProgress: 0.2101061046123504,
      currentPlannedProgress: 0.2101061046123504
    },
    22: {
      deviation: null,
      totalPlannedMovement: 0.5158099830150604,
      currentProgress: 0.1964713111519813,
      currentPlannedProgress: 0.1964713111519813
    },
    23: {
      deviation: null,
      totalPlannedMovement: 0.4040919542312622,
      currentProgress: 0.1679010167717933,
      currentPlannedProgress: 0.1679010167717933
    },
    24: {
      deviation: null,
      totalPlannedMovement: 0.2279394716024398,
      currentProgress: 0.0611619967967271,
      currentPlannedProgress: 0.0611619967967271
    },
    25: {
      deviation: null,
      totalPlannedMovement: 0.2974576950073242,
      currentProgress: 0.1102745980024337,
      currentPlannedProgress: 0.1102745980024337
    },
    26: {
      deviation: null,
      totalPlannedMovement: 0.1841548904776573,
      currentProgress: 0.1141213998198509,
      currentPlannedProgress: 0.1141213998198509
    },
    27: {
      deviation: null,
      totalPlannedMovement: 0.5301475524902344,
      currentProgress: 0.2372221946716308,
      currentPlannedProgress: 0.2372221946716308
    },
    28: {
      deviation: null,
      totalPlannedMovement: 0.3802547752857208,
      currentProgress: 0.1841866075992584,
      currentPlannedProgress: 0.1841866075992584
    },
    29: {
      deviation: null,
      totalPlannedMovement: 0.7265366911888123,
      currentProgress: 0.2360323220491409,
      currentPlannedProgress: 0.2360323220491409
    },
    30: {
      deviation: null,
      totalPlannedMovement: 0.1089380644261837,
      currentProgress: 0.0558829493820667,
      currentPlannedProgress: 0.0558829493820667
    },
    31: {
      deviation: null,
      totalPlannedMovement: 0.2691497802734375,
      currentProgress: 0.1573749482631683,
      currentPlannedProgress: 0.1573749482631683
    }
  },
  archModels: {
    upper: {
      key: `obj-files/simple_aligners_output_mesh_upper.obj`,
      bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
    },
    lower: {
      key: `obj-files/simple_aligners_output_mesh_lower.obj`,
      bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
    }
  }
}

// Complex aligners case
const alignersTreatmentMockData = {
  scanId: '32728d32-8242-4495-b2e4-994b7eceb003',
  needsRotation: true,
  teeth: {
    2: {
      deviation: null,
      currentTotalProgress: 0.5858457088470459,
      totalPlannedMovement: 0.9538391828536988,
      currentProgress: 0.3784040808677673
    },
    3: {
      deviation: null,
      currentTotalProgress: 0.2924657464027405,
      totalPlannedMovement: 0.5650776624679565,
      currentProgress: 0.2122786939144134
    },
    4: {
      deviation: 0.05,
      currentTotalProgress: 0.4084115475416183,
      totalPlannedMovement: 0.5831407904624939,
      currentProgress: 0.3170665353536606
    },
    5: {
      deviation: 0.1,
      currentTotalProgress: 0.4116942286491394,
      totalPlannedMovement: 0.6489304900169373,
      currentProgress: 0.3168398141860962
    },
    6: {
      deviation: 0.15,
      currentTotalProgress: 0.2955940365791321,
      totalPlannedMovement: 0.1707984805107116,
      currentProgress: 0.1490199154615402
    },
    7: {
      deviation: null,
      currentTotalProgress: 0.2197454273700714,
      totalPlannedMovement: 0.4431966543197632,
      currentProgress: 0.1574322879314422
    },
    8: {
      deviation: 0.20507,
      currentTotalProgress: 0.9035165309906006,
      totalPlannedMovement: 1.592535376548767,
      currentProgress: 0.6942868041992188
    },
    9: {
      deviation: 0.23867,
      currentTotalProgress: 0.6662819385528564,
      totalPlannedMovement: 1.4118394255638125,
      currentProgress: 0.5079766511917114
    },
    10: {
      deviation: null,
      currentTotalProgress: 0.3915527164936065,
      totalPlannedMovement: 0.6501712203025818,
      currentProgress: 0.2840317189693451
    },
    11: {
      deviation: 0.12,
      currentTotalProgress: 0.5081778764724731,
      totalPlannedMovement: 0.6359325647354126,
      currentProgress: 0.3580116629600525
    },
    12: {
      deviation: 0.05,
      currentTotalProgress: 0.3188469707965851,
      totalPlannedMovement: 0.66753751039505,
      currentProgress: 0.2362730503082275
    },
    13: {
      deviation: null,
      currentTotalProgress: 0.3276787996292114,
      totalPlannedMovement: 0.5690655708312988,
      currentProgress: 0.2408637851476669
    },
    14: {
      deviation: null,
      currentTotalProgress: 0.242074616253376,
      totalPlannedMovement: 0.48466357588768,
      currentProgress: 0.1751712039113044
    },
    18: {
      deviation: null,
      currentTotalProgress: 0.6940650343894958,
      totalPlannedMovement: 1.1686854362487793,
      currentProgress: 0.45869180560112
    },
    19: {
      deviation: null,
      currentTotalProgress: 0.2351351827383041,
      totalPlannedMovement: 0.429340124130249,
      currentProgress: 0.1534493789076805
    },
    20: {
      deviation: null,
      currentTotalProgress: 0.2442945837974548,
      totalPlannedMovement: 0.5175848007202148,
      currentProgress: 0.1622559428215026
    },
    21: {
      deviation: null,
      currentTotalProgress: 0.4301004558801651,
      totalPlannedMovement: 0.886818140745163,
      currentProgress: 0.2923153638839721
    },
    22: {
      deviation: 0.40839,
      currentTotalProgress: 0.4077488481998443,
      totalPlannedMovement: 0.6104023456573486,
      currentProgress: 0.2030440753698349
    },
    23: {
      deviation: null,
      currentTotalProgress: 0.375769779086113,
      totalPlannedMovement: 0.4818640500307083,
      currentProgress: 0.2782356888055801
    },
    24: {
      deviation: null,
      currentTotalProgress: 0.2868743538856506,
      totalPlannedMovement: 0.98476180434227,
      currentProgress: 0.1569234952330589
    },
    26: {
      deviation: null,
      currentTotalProgress: 0.400232270359993,
      totalPlannedMovement: 0.5613057911396027,
      currentProgress: 0.2460280135273933
    },
    27: {
      deviation: 0.20178,
      currentTotalProgress: 0.4123354852199554,
      totalPlannedMovement: 0.713994026184082,
      currentProgress: 0.2092240178585052
    },
    28: {
      deviation: null,
      currentTotalProgress: 0.3204783648252487,
      totalPlannedMovement: 0.4786491096019745,
      currentProgress: 0.2327850759029388
    },
    29: {
      deviation: null,
      currentTotalProgress: 0.3021460026502609,
      totalPlannedMovement: 0.7137345671653748,
      currentProgress: 0.1963328570127487
    },
    30: {
      deviation: null,
      currentTotalProgress: 0.1786485910415649,
      totalPlannedMovement: 0.3382967114448547,
      currentProgress: 0.111209437251091
    },
    31: {
      deviation: null,
      currentTotalProgress: 0.3906939029693603,
      totalPlannedMovement: 0.5826146602630615,
      currentProgress: 0.2822907865047455
    }
  },
  archModels: {
    upper: {
      key: `/obj-files/aligners_output_upper.obj`,
      bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
    },
    lower: {
      key: `/obj-files/aligners_output_lower.obj`,
      bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
    }
  }
}

// Simple aligners case
const simpleAlignersBaseArchModels = {
  upper: {
    key: `/obj-files/simple_aligners_base_mesh_upper.obj`,
    bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
  },
  lower: {
    key: `/obj-files/simple_aligners_base_mesh_lower.obj`,
    bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
  }
}

const simpleAlignersCurrentArchModels = {
  upper: {
    key: `/obj-files/simple_aligners_current_upper.obj`,
    bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
  },
  lower: {
    key: `/obj-files/simple_aligners_current_lower.obj`,
    bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
  }
}

const simpleAlignersEndArchModels = {
  upper: {
    key: `/obj-files/simple_aligners_final_mesh_upper.obj`,
    bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
  },
  lower: {
    key: `/obj-files/simple_aligners_final_mesh_lower.obj`,
    bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
  }
}

// Complex aligners case
const alignersBaseArchModels = {
  upper: {
    key: `/obj-files/aligners_base_upper.obj`,
    bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
  },
  lower: {
    key: `/obj-files/aligners_base_lower.obj`,
    bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
  }
}

const alignersCurrentArchModels = {
  upper: {
    key: `/obj-files/aligners_current_upper.obj`,
    bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
  },
  lower: {
    key: `/obj-files/aligners_current_lower.obj`,
    bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
  }
}

const alignersEndArchModels = {
  upper: {
    key: `/obj-files/aligners_final_upper.obj`,
    bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
  },
  lower: {
    key: `/obj-files/aligners_final_lower.obj`,
    bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
  }
}

export const alignersMockData = [simpleAlignersTreatmentMockData, alignersTreatmentMockData]
export const alignersMockDataSolventumDemo = [
  { ...alignersTreatmentMockData, scanId: 'bcf0633d-c2c5-47ad-b522-07d6ff5f2129' }
]
export const alignersArchModels = [
  {
    scanId: 'a765ec8d-fa74-4116-a15a-dd06258e529b',
    baseArchModels: simpleAlignersBaseArchModels,
    currentArchModels: simpleAlignersCurrentArchModels,
    endArchModels: simpleAlignersEndArchModels
  },
  {
    scanId: '32728d32-8242-4495-b2e4-994b7eceb003',
    baseArchModels: alignersBaseArchModels,
    currentArchModels: alignersCurrentArchModels,
    endArchModels: alignersEndArchModels
  },
  {
    scanId: 'bcf0633d-c2c5-47ad-b522-07d6ff5f2129',
    baseArchModels: alignersBaseArchModels,
    currentArchModels: alignersCurrentArchModels,
    endArchModels: alignersEndArchModels
  }
]

// Braces

// Braces scan 5
const bracesTreatmentMockData5 = {
  scanId: 'cd68f576-ef9a-428d-a238-538d8fcc7d45',
  teeth: {
    2: {
      deviation: 0.4943,
      currentPlannedProgress: 0.6781813502311707,
      totalPlannedMovement: 0.6781813502311707,
      currentProgress: 0.3044104427099228,
      pastMonthSpeed: 0.175621409255725
    },
    3: {
      deviation: 0.29278,
      currentPlannedProgress: 0.6257267594337463,
      totalPlannedMovement: 0.6257267594337463,
      currentProgress: 0.0502978190779686,
      pastMonthSpeed: 0.0290179725449819
    },
    4: {
      deviation: 0.3922,
      currentPlannedProgress: 0.6877968311309814,
      totalPlannedMovement: 0.6877968311309814,
      currentProgress: 0.0770240537822246,
      pastMonthSpeed: 0.0444369541051296
    },
    5: {
      deviation: 0.04742,
      currentPlannedProgress: 0.6222929060459137,
      totalPlannedMovement: 0.6222929060459137,
      currentProgress: 0.406157448887825,
      pastMonthSpeed: 0.234321605127591
    },
    6: {
      deviation: 0.14424,
      currentPlannedProgress: 0.459975853562355,
      totalPlannedMovement: 0.459975853562355,
      currentProgress: 0.2065894156694412,
      pastMonthSpeed: 0.119186201347755
    },
    7: {
      deviation: 0.12481,
      currentPlannedProgress: 0.549061119556427,
      totalPlannedMovement: 0.549061119556427,
      currentProgress: 0.2092212289571762,
      pastMonthSpeed: 0.120704555167602
    },
    8: {
      deviation: 0.19438,
      currentPlannedProgress: 0.915839821100235,
      totalPlannedMovement: 0.915839821100235,
      currentProgress: 0.3100441396236419,
      pastMonthSpeed: 0.17887161901364
    },
    9: {
      deviation: 0.72316,
      currentPlannedProgress: 0.8847343921661377,
      totalPlannedMovement: 0.8847343921661377,
      currentProgress: 0.4569743871688843,
      pastMonthSpeed: 0.26363906952051
    },
    10: {
      deviation: 0.19299,
      currentPlannedProgress: 0.5046602487564087,
      totalPlannedMovement: 0.5046602487564087,
      currentProgress: 0.1984383910894394,
      pastMonthSpeed: 0.114483687166984
    },
    11: {
      deviation: 0.75494,
      currentPlannedProgress: 0.5705288946628571,
      totalPlannedMovement: 0.5705288946628571,
      currentProgress: 0.4279364049434662,
      pastMonthSpeed: 0.246886387467384
    },
    12: {
      deviation: 0.57627,
      currentPlannedProgress: 0.4204823970794678,
      totalPlannedMovement: 0.4204823970794678,
      currentProgress: 0.2434270530939102,
      pastMonthSpeed: 0.140438684477256
    },
    13: {
      deviation: 0.03414,
      currentPlannedProgress: 0.4832155704498291,
      totalPlannedMovement: 0.4832155704498291,
      currentProgress: 0.2649314105510711,
      pastMonthSpeed: 0.152845044548695
    },
    14: {
      deviation: 0.06959,
      currentPlannedProgress: 0.5810065269470215,
      totalPlannedMovement: 0.5810065269470215,
      currentProgress: 0.2678715586662292,
      pastMonthSpeed: 0.154541283845901
    },
    15: {
      deviation: 0.08839,
      currentPlannedProgress: 0.6822005212306976,
      totalPlannedMovement: 0.6822005212306976,
      currentProgress: 0.2441438287496566,
      pastMonthSpeed: 0.140852208894033
    },
    18: {
      deviation: 0.25931,
      currentPlannedProgress: 0.5117763876914978,
      totalPlannedMovement: 0.5117763876914978,
      currentProgress: 0.1718649268150329,
      pastMonthSpeed: 0.0991528423932882
    },
    19: {
      deviation: 0.16803,
      currentPlannedProgress: 0.4167130142450332,
      totalPlannedMovement: 0.4167130142450332,
      currentProgress: 0.1178641468286514,
      pastMonthSpeed: 0.0679985462472989
    },
    20: {
      deviation: 0,
      currentPlannedProgress: 0.557363361120224,
      totalPlannedMovement: 0.557363361120224,
      currentProgress: 0.4883695393800735,
      pastMonthSpeed: 0.281751657334658
    },
    21: {
      deviation: 0,
      currentPlannedProgress: 0.5134328603744507,
      totalPlannedMovement: 0.5134328603744507,
      currentProgress: 0.480673611164093,
      pastMonthSpeed: 0.277311698748515
    },
    22: {
      deviation: 0.02006,
      currentPlannedProgress: 0.4629642367362976,
      totalPlannedMovement: 0.4629642367362976,
      currentProgress: 0.3166059255599975,
      pastMonthSpeed: 0.182657264746152
    },
    23: {
      deviation: 0.16357,
      currentPlannedProgress: 0.864485889673233,
      totalPlannedMovement: 0.864485889673233,
      currentProgress: 0.477361187338829,
      pastMonthSpeed: 0.275400685003171
    },
    24: {
      deviation: 0.60789,
      currentPlannedProgress: 1.2281550168991089,
      totalPlannedMovement: 1.2281550168991089,
      currentProgress: 0.4449311047792434,
      pastMonthSpeed: 0.256691021988025
    },
    25: {
      deviation: 0.33146,
      currentPlannedProgress: 1.3337032794952393,
      totalPlannedMovement: 1.3337032794952393,
      currentProgress: 0.4434473812580108,
      pastMonthSpeed: 0.255835027648852
    },
    26: {
      deviation: 0.22918,
      currentPlannedProgress: 0.9515911936759948,
      totalPlannedMovement: 0.9515911936759948,
      currentProgress: 0.3958863914012909,
      pastMonthSpeed: 0.228395995039206
    },
    27: {
      deviation: 0.09144,
      currentPlannedProgress: 0.2233923375606537,
      totalPlannedMovement: 0.2233923375606537,
      currentProgress: 0.1253744661808014,
      pastMonthSpeed: 0.0723314227966162
    },
    28: {
      deviation: 0.19364,
      currentPlannedProgress: 0.5674974918365479,
      totalPlannedMovement: 0.5674974918365479,
      currentProgress: 0.1860495954751968,
      pastMonthSpeed: 0.107336305081844
    },
    29: {
      deviation: 0.28094,
      currentPlannedProgress: 0.645390510559082,
      totalPlannedMovement: 0.645390510559082,
      currentProgress: 0.2452328950166702,
      pastMonthSpeed: 0.141480516355771
    },
    30: {
      deviation: 0.09944,
      currentPlannedProgress: 0.3597595393657684,
      totalPlannedMovement: 0.3597595393657684,
      currentProgress: 0.1523537784814834,
      pastMonthSpeed: 0.0878964106623943
    },
    31: {
      deviation: 0.17545,
      currentPlannedProgress: 0.3861749172210693,
      totalPlannedMovement: 0.3861749172210693,
      currentProgress: 0.0987303256988525,
      pastMonthSpeed: 0.0569598032877995
    }
  },
  archModels: {
    upper: {
      key: `/obj-files/braces_upper_output_5.obj`,
      bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
    },
    lower: {
      key: `/obj-files/braces_lower_output_5.obj`,
      bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
    }
  }
}

// Braces scan 10
const bracesTreatmentMockData10 = {
  scanId: 'f2aca68c-4eb2-4607-9239-208726c167c3',
  teeth: {
    2: {
      deviation: 0.07107,
      currentPlannedProgress: 0.6781813502311707,
      totalPlannedMovement: 0.6781813502311707,
      currentProgress: 0.2742896229028702,
      pastMonthSpeed: 0.262241294980049
    },
    3: {
      deviation: 0.60978,
      currentPlannedProgress: 0.6257267594337463,
      totalPlannedMovement: 0.6257267594337463,
      currentProgress: 0.216847957670689,
      pastMonthSpeed: 0.267145776748657
    },
    4: {
      deviation: 0.34596,
      currentPlannedProgress: 0.6877968311309814,
      totalPlannedMovement: 0.6877968311309814,
      currentProgress: 0.0665599107742309,
      pastMonthSpeed: 0.0623742535710334
    },
    5: {
      deviation: 0.72018,
      currentPlannedProgress: 0.6222929060459137,
      totalPlannedMovement: 0.6222929060459137,
      currentProgress: 0.468028575181961,
      pastMonthSpeed: 0.443280124664307
    },
    6: {
      deviation: 0.72975,
      currentPlannedProgress: 0.459975853562355,
      totalPlannedMovement: 0.459975853562355,
      currentProgress: 0.3624164015054703,
      pastMonthSpeed: 0.300085607171059
    },
    7: {
      deviation: 0.43865,
      currentPlannedProgress: 0.549061119556427,
      totalPlannedMovement: 0.549061119556427,
      currentProgress: 0.3677500188350677,
      pastMonthSpeed: 0.304338502883911
    },
    8: {
      deviation: 0.14289,
      currentPlannedProgress: 0.915839821100235,
      totalPlannedMovement: 0.915839821100235,
      currentProgress: 0.4150493592023849,
      pastMonthSpeed: 0.373047271370888
    },
    9: {
      deviation: 0.08035,
      currentPlannedProgress: 0.8847343921661377,
      totalPlannedMovement: 0.8847343921661377,
      currentProgress: 0.4795405268669128,
      pastMonthSpeed: 0.470514070987701
    },
    10: {
      deviation: 0.61815,
      currentPlannedProgress: 0.5046602487564087,
      totalPlannedMovement: 0.5046602487564087,
      currentProgress: 0.3508036434650421,
      pastMonthSpeed: 0.289857542514801
    },
    11: {
      deviation: 0.85578,
      currentPlannedProgress: 0.5705288946628571,
      totalPlannedMovement: 0.5705288946628571,
      currentProgress: 0.3465944528579712,
      pastMonthSpeed: 0.314057672023773
    },
    12: {
      deviation: 0.42472,
      currentPlannedProgress: 0.4204823970794678,
      totalPlannedMovement: 0.4204823970794678,
      currentProgress: 0.3626706302165985,
      pastMonthSpeed: 0.314973199367523
    },
    13: {
      deviation: 0.22905,
      currentPlannedProgress: 0.4832155704498291,
      totalPlannedMovement: 0.4832155704498291,
      currentProgress: 0.0633072927594184,
      pastMonthSpeed: 0.0173423543572427
    },
    14: {
      deviation: 0.06959,
      currentPlannedProgress: 0.5810065269470215,
      totalPlannedMovement: 0.5810065269470215,
      currentProgress: 0.2611085176467895,
      pastMonthSpeed: 0.258403301239014
    },
    15: {
      deviation: 0.55875,
      currentPlannedProgress: 0.6822005212306976,
      totalPlannedMovement: 0.6822005212306976,
      currentProgress: 0.3309777677059173,
      pastMonthSpeed: 0.296244192123413
    },
    18: {
      deviation: 0.25931,
      currentPlannedProgress: 0.5117763876914978,
      totalPlannedMovement: 0.5117763876914978,
      currentProgress: 0.1761629581451416,
      pastMonthSpeed: 0.174443745613098
    },
    19: {
      deviation: 0.4418,
      currentPlannedProgress: 0.4167130142450332,
      totalPlannedMovement: 0.4167130142450332,
      currentProgress: 0.1955391988158226,
      pastMonthSpeed: 0.164469178020954
    },
    20: {
      deviation: 0.54383,
      currentPlannedProgress: 0.557363361120224,
      totalPlannedMovement: 0.557363361120224,
      currentProgress: 0.4394789189100265,
      pastMonthSpeed: 0.419922670722008
    },
    21: {
      deviation: 0.54599,
      currentPlannedProgress: 0.5134328603744507,
      totalPlannedMovement: 0.5134328603744507,
      currentProgress: 0.418826162815094,
      pastMonthSpeed: 0.394087183475494
    },
    22: {
      deviation: 0.08967,
      currentPlannedProgress: 0.4629642367362976,
      totalPlannedMovement: 0.4629642367362976,
      currentProgress: 0.2285277545452118,
      pastMonthSpeed: 0.193296486139298
    },
    23: {
      deviation: 0.19017,
      currentPlannedProgress: 0.864485889673233,
      totalPlannedMovement: 0.864485889673233,
      currentProgress: 0.4129277765750885,
      pastMonthSpeed: 0.387154412269592
    },
    24: {
      deviation: 0.22551,
      currentPlannedProgress: 1.2281550168991089,
      totalPlannedMovement: 1.2281550168991089,
      currentProgress: 0.5040809512138367,
      pastMonthSpeed: 0.480421012639999
    },
    25: {
      deviation: 0.27655,
      currentPlannedProgress: 1.3337032794952393,
      totalPlannedMovement: 1.3337032794952393,
      currentProgress: 0.5127229690551758,
      pastMonthSpeed: 0.48501273393631
    },
    26: {
      deviation: 0.55426,
      currentPlannedProgress: 0.9515911936759948,
      totalPlannedMovement: 0.9515911936759948,
      currentProgress: 0.5618507266044617,
      pastMonthSpeed: 0.495464992523193
    },
    27: {
      deviation: 0.20053,
      currentPlannedProgress: 0.2233923375606537,
      totalPlannedMovement: 0.2233923375606537,
      currentProgress: 0.128502607345581,
      pastMonthSpeed: 0.127251350879669
    },
    28: {
      deviation: 0.32652,
      currentPlannedProgress: 0.5674974918365479,
      totalPlannedMovement: 0.5674974918365479,
      currentProgress: 0.0982555896043777,
      pastMonthSpeed: 0.0631379872560501
    },
    29: {
      deviation: 0.31508,
      currentPlannedProgress: 0.645390510559082,
      totalPlannedMovement: 0.645390510559082,
      currentProgress: 0.2680045962333679,
      pastMonthSpeed: 0.258895915746689
    },
    30: {
      deviation: 0.16887,
      currentPlannedProgress: 0.3597595393657684,
      totalPlannedMovement: 0.3597595393657684,
      currentProgress: 0.1600379645824432,
      pastMonthSpeed: 0.156964290142059
    },
    31: {
      deviation: 0.32887,
      currentPlannedProgress: 0.3861749172210693,
      totalPlannedMovement: 0.3861749172210693,
      currentProgress: 0.3316892981529236,
      pastMonthSpeed: 0.238505709171295
    }
  },
  archModels: {
    upper: {
      key: `/obj-files/braces_upper_output_10.obj`,
      bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
    },
    lower: {
      key: `/obj-files/braces_lower_output_10.obj`,
      bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
    }
  }
}

// Braces scan 15
const bracesTreatmentMockData15 = {
  scanId: '3d8b6c91-47e1-4711-a65b-e31afb9565bd',
  teeth: {
    2: {
      deviation: 0,
      currentPlannedProgress: 0.6781813502311707,
      totalPlannedMovement: 0.6781813502311707,
      currentProgress: 0.6272694170475006,
      pastMonthSpeed: 0.322643019361039
    },
    3: {
      deviation: 0.51368,
      currentPlannedProgress: 0.6257267594337463,
      totalPlannedMovement: 0.6257267594337463,
      currentProgress: 0.5121695697307587,
      pastMonthSpeed: 0.425472518556739
    },
    4: {
      deviation: 0.61522,
      currentPlannedProgress: 0.6877968311309814,
      totalPlannedMovement: 0.6877968311309814,
      currentProgress: 0.5887300074100494,
      pastMonthSpeed: 0.138090060998316
    },
    5: {
      deviation: 0,
      currentPlannedProgress: 0.6222929060459137,
      totalPlannedMovement: 0.6222929060459137,
      currentProgress: 0.6468590497970581,
      pastMonthSpeed: 0.4925259004717
    },
    6: {
      deviation: 0.6303,
      currentPlannedProgress: 0.459975853562355,
      totalPlannedMovement: 0.459975853562355,
      currentProgress: 0.3835542798042297,
      pastMonthSpeed: 0.365312001272424
    },
    7: {
      deviation: 0.32968,
      currentPlannedProgress: 0.549061119556427,
      totalPlannedMovement: 0.549061119556427,
      currentProgress: 0.5456689894199371,
      pastMonthSpeed: 0.392122480559022
    },
    8: {
      deviation: 0,
      currentPlannedProgress: 0.915839821100235,
      totalPlannedMovement: 0.915839821100235,
      currentProgress: 0.932752251625061,
      pastMonthSpeed: 0.485967563643847
    },
    9: {
      deviation: 0.64138,
      currentPlannedProgress: 0.8847343921661377,
      totalPlannedMovement: 0.8847343921661377,
      currentProgress: 0.7496334314346313,
      pastMonthSpeed: 0.516539554889888
    },
    10: {
      deviation: 0.40488,
      currentPlannedProgress: 0.5046602487564087,
      totalPlannedMovement: 0.5046602487564087,
      currentProgress: 0.4962644428014755,
      pastMonthSpeed: 0.370729780360444
    },
    11: {
      deviation: 0.4935,
      currentPlannedProgress: 0.5705288946628571,
      totalPlannedMovement: 0.5705288946628571,
      currentProgress: 0.543916642665863,
      pastMonthSpeed: 0.373624889817956
    },
    12: {
      deviation: 0.31799,
      currentPlannedProgress: 0.4204823970794678,
      totalPlannedMovement: 0.4204823970794678,
      currentProgress: 0.3936923742294311,
      pastMonthSpeed: 0.366920184190959
    },
    13: {
      deviation: 0.45686,
      currentPlannedProgress: 0.4832155704498291,
      totalPlannedMovement: 0.4832155704498291,
      currentProgress: 0.389206975698471,
      pastMonthSpeed: 0.10795108494285
    },
    14: {
      deviation: 0.50758,
      currentPlannedProgress: 0.5810065269470215,
      totalPlannedMovement: 0.5810065269470215,
      currentProgress: 0.4581523835659027,
      pastMonthSpeed: 0.288100828046668
    },
    15: {
      deviation: 0,
      currentPlannedProgress: 0.6822005212306976,
      totalPlannedMovement: 0.6822005212306976,
      currentProgress: 0.6021267473697662,
      pastMonthSpeed: 0.36812146355028
    },
    18: {
      deviation: 0.44765,
      currentPlannedProgress: 0.5117763876914978,
      totalPlannedMovement: 0.5117763876914978,
      currentProgress: 0.5416974425315857,
      pastMonthSpeed: 0.226236175184381
    },
    19: {
      deviation: 0.46164,
      currentPlannedProgress: 0.4167130142450332,
      totalPlannedMovement: 0.4167130142450332,
      currentProgress: 0.0256747920066118,
      pastMonthSpeed: 0.120920517979419
    },
    20: {
      deviation: 0.60507,
      currentPlannedProgress: 0.557363361120224,
      totalPlannedMovement: 0.557363361120224,
      currentProgress: 0.4743549823760986,
      pastMonthSpeed: 0.444256461850584
    },
    21: {
      deviation: 0,
      currentPlannedProgress: 0.5134328603744507,
      totalPlannedMovement: 0.5134328603744507,
      currentProgress: 0.5476183891296387,
      pastMonthSpeed: 0.436468933543114
    },
    22: {
      deviation: 0.2973,
      currentPlannedProgress: 0.4629642367362976,
      totalPlannedMovement: 0.4629642367362976,
      currentProgress: 0.2800400555133819,
      pastMonthSpeed: 0.235584234129893
    },
    23: {
      deviation: 0.4518,
      currentPlannedProgress: 0.864485889673233,
      totalPlannedMovement: 0.864485889673233,
      currentProgress: 0.7191266417503357,
      pastMonthSpeed: 0.454872826599095
    },
    24: {
      deviation: 0.49357,
      currentPlannedProgress: 1.2281550168991089,
      totalPlannedMovement: 1.2281550168991089,
      currentProgress: 1.0242297649383545,
      pastMonthSpeed: 0.57533421336788
    },
    25: {
      deviation: 0.60596,
      currentPlannedProgress: 1.3337032794952393,
      totalPlannedMovement: 1.3337032794952393,
      currentProgress: 1.129100680351257,
      pastMonthSpeed: 0.597158271972447
    },
    26: {
      deviation: 0.47533,
      currentPlannedProgress: 0.9515911936759948,
      totalPlannedMovement: 0.9515911936759948,
      currentProgress: 0.7796830534934998,
      pastMonthSpeed: 0.591690771383782
    },
    27: {
      deviation: 0,
      currentPlannedProgress: 0.2233923375606537,
      totalPlannedMovement: 0.2233923375606537,
      currentProgress: 0.208875298500061,
      pastMonthSpeed: 0.139512565037976
    },
    28: {
      deviation: 0.57338,
      currentPlannedProgress: 0.5674974918365479,
      totalPlannedMovement: 0.5674974918365479,
      currentProgress: 0.4861057102680206,
      pastMonthSpeed: 0.151385743119945
    },
    29: {
      deviation: 0.0978,
      currentPlannedProgress: 0.645390510559082,
      totalPlannedMovement: 0.645390510559082,
      currentProgress: 0.4371131062507629,
      pastMonthSpeed: 0.291170145550819
    },
    30: {
      deviation: 0,
      currentPlannedProgress: 0.3597595393657684,
      totalPlannedMovement: 0.3597595393657684,
      currentProgress: 0.3314902186393738,
      pastMonthSpeed: 0.183524574727228
    },
    31: {
      deviation: 0.32887,
      currentPlannedProgress: 0.3861749172210693,
      totalPlannedMovement: 0.3861749172210693,
      currentProgress: 0.3638560771942138,
      pastMonthSpeed: 0.336095706240772
    }
  },
  archModels: {
    upper: {
      key: `/obj-files/braces_upper_output_15.obj`,
      bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
    },
    lower: {
      key: `/obj-files/braces_lower_output_15.obj`,
      bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
    }
  }
}

export const bracesMockData = [bracesTreatmentMockData5, bracesTreatmentMockData10, bracesTreatmentMockData15]
export const bracesMockDataSolventumDemo = [
  { ...bracesTreatmentMockData5, scanId: '66d82f66-3454-488b-a195-7e8ee97f5b79' },
  { ...bracesTreatmentMockData10, scanId: '489de606-678a-4d83-bb0d-f98815c68a7b' },
  { ...bracesTreatmentMockData15, scanId: 'da83f031-d7e5-4d74-800e-d091f2c5174c' }
]
export const bracesArchModels = [
  {
    baseArchModels: {
      upper: {
        key: `/obj-files/braces_base_upper.obj`,
        bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
      },
      lower: {
        key: `/obj-files/braces_base_lower.obj`,
        bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
      }
    },
    currentArchModels: {
      upper: {
        key: `/obj-files/braces_current_upper.obj`,
        bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
      },
      lower: {
        key: `/obj-files/braces_current_lower.obj`,
        bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
      }
    },
    endArchModels: {
      upper: {
        key: `/obj-files/braces_final_upper.obj`,
        bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
      },
      lower: {
        key: `/obj-files/braces_final_lower.obj`,
        bucket: `scans-${getEnvironment() === 'production' ? 'production' : 'staging'}`
      }
    }
  }
]

export const txTrackerOverTimeByScan = {
  'a765ec8d-fa74-4116-a15a-dd06258e529b': simpleAlignersTxTrackerByTeeth,
  '32728d32-8242-4495-b2e4-994b7eceb003': alignersTxTrackerByTeeth,
  'bcf0633d-c2c5-47ad-b522-07d6ff5f2129': alignersTxTrackerByTeeth,
  'cd68f576-ef9a-428d-a238-538d8fcc7d45': bracesTxTrackerByTeeth,
  'f2aca68c-4eb2-4607-9239-208726c167c3': bracesTxTrackerByTeeth,
  '3d8b6c91-47e1-4711-a65b-e31afb9565bd': bracesTxTrackerByTeeth,
  '66d82f66-3454-488b-a195-7e8ee97f5b79': bracesTxTrackerByTeeth,
  '489de606-678a-4d83-bb0d-f98815c68a7b': bracesTxTrackerByTeeth,
  'da83f031-d7e5-4d74-800e-d091f2c5174c': bracesTxTrackerByTeeth
}
