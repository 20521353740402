import { API } from 'aws-amplify'
import { Auth } from 'aws-amplify'
import { push } from 'connected-react-router'
import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, map, mapTo, mergeMap, pluck, switchMap, withLatestFrom, filter } from 'rxjs/operators'
import Actions from '../actions'
import ROUTES from '../consts/routesConsts'
import { mapToCreateDoctorInputQuickSignUp } from '../utils/mappers/doctorsMappers'
import { isUserOfRole } from '../utils/authUtils'
import { trackEvent } from 'utils/analyticsUtils'
import i18n from '../resources/locales/i18n'

export const requestQuickDoctorSignUpEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_QUICK_DOCTOR_SIGN_UP),
    filter(() => isUserOfRole(['Admins', 'Sales'])),
    withLatestFrom(state$),
    map(([, { quickSignUpReducer: quickSignUpProps }]) => mapToCreateDoctorInputQuickSignUp(quickSignUpProps)),
    switchMap(body =>
      from(
        API.post('grinServerlessApi', '/accounts/v2/doctors/quickSignUp', {
          body
        })
      ).pipe(
        mergeMap(({ doctor }) => of(Actions.quickDoctorSignUpReceived(doctor))),
        catchError(error => of(Actions.quickDoctorSignUpFailed(error)))
      )
    )
  )

export const quickDoctorSignUpReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.QUICK_DOCTOR_SIGN_UP_RECEIVED),
    map(body => push(ROUTES.QUICK_SIGN_UP_BILLING_INFO))
  )

export const quickDoctorSignUpFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.QUICK_DOCTOR_SIGN_UP_FAILED),
    pluck('payload'),
    mergeMap(error => {
      trackEvent('Sign up failed', {
        signupType: 'quick'
      })

      return of(
        Actions.showSnackbar({
          type: 'error',
          text:
            error.response?.data?.code === 'userAlreadyExists'
              ? i18n.t('messages.signUp.userAlreadyExists')
              : i18n.t('messages.signUp.doctorSignUpFailed')
        })
      )
    })
  )

export const finishDoctorQuickSignupEpic = action$ =>
  action$.pipe(
    ofType(Actions.FINISH_QUICK_SIGN_UP),
    pluck('payload'),
    mergeMap(({ email }) => {
      trackEvent('Sign up completed', {
        email,
        signupType: 'quick'
      })

      return of(
        Actions.showSnackbar({
          type: 'success',
          text: i18n.t('messages.signUp.doctorSignedUpSuccessfully', {
            email
          })
        })
      )
    })
  )

export const verifyDoctorEmailEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.VERIFY_DOCTOR_EMAIL),
    filter(() => isUserOfRole(['Admins', 'Sales'])),
    withLatestFrom(state$),
    map(([, { quickSignUpReducer }]) => quickSignUpReducer.emailAddress),
    switchMap(email =>
      from(Auth.signIn(email, 'wrong')).pipe(
        mergeMap(() => of(Actions.verifyDoctorEmailFailed())),
        catchError(error =>
          error.code === 'UserNotFoundException'
            ? of(Actions.verifyDoctorEmailReceived())
            : of(Actions.verifyDoctorEmailFailed())
        )
      )
    )
  )

export const verifyDoctorEmailReceivedEpic = action$ =>
  action$.pipe(ofType(Actions.VERIFY_DOCTOR_EMAIL_RECEIVED), mapTo(push(ROUTES.QUICK_SIGN_UP_PERSONAL_DETAILS)))

export const verifyDoctorEmailFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.VERIFY_DOCTOR_EMAIL_FAILED),
    mergeMap(err => of(Actions.updateQuickSignUpErrors({ emailAddress: i18n.t('messages.signUp.userAlreadyExists') })))
  )
