import React, { useCallback } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { conditionTypes } from 'consts/automatedActionConsts'

const useStyles = makeStyles(theme => ({
  alignerNumberInput: {
    outline: 'none',
    fontSize: 16,
    textAlign: 'center',
    height: 32,
    width: 80,
    border: 'none',
    borderBottom: ({ error }) => (error ? '1px solid red' : '1px solid var(--border-color-11)'),
    paddingLeft: 14
  }
}))

const PatientScanCountCondition = ({ onChange, params }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleChange = useCallback(
    newNumber => {
      onChange({
        type: conditionTypes.PatientScanCount,
        params: {
          scansCount: newNumber
        }
      })
    },
    [onChange]
  )

  return (
    <Grid container alignItems="center">
      <Grid item>
        <DazzedParagraph14 strong>
          {t('pages.accountSettings.automations.editorModal.enterNumberOfScans')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <input
          value={params?.scansCount || ''}
          type="number"
          inputMode="numeric"
          pattern="[0-9]*"
          placeholder={t('pages.accountSettings.automations.editorModal.enterNumberOfScansPlaceholder')}
          className={classes.alignerNumberInput}
          onChange={({ target }) => handleChange(target.value)}
          min={1}
        />
      </Grid>
    </Grid>
  )
}
export default PatientScanCountCondition
