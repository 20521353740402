import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import appReducer from './appReducer'
import authReducer from './authReducer'
import profileReducer from './profileReducer'
import patientsReducer from './patientsReducer'
import chatReducer from './chatReducer'
import treatmentReducer from './treatmentReducer'
import supportReducer from './supportReducer'
import releaseNotesReducer from './releaseNotesReducer'
import billingReducer from './billingReducer'
import productReducer from './productReducer'
import quickSignUpReducer from './quickSignUpReducer'
import practiceReducer from './practiceReducer'
import pixelReducer from './pixelReducer'
import taskManagerReducer from './taskManagerReducer'
import announcmentsReducer from './announcmentsReducer'
import rcDashboardReducer from './rcDashboardReducer'
import giReducer from './giReducer'
import hiReducer from './hiReducer'
import pmsReducer from './pmsReducer'
import autochartReducer from './autochartReducer'
import multiPracticeReducer from './multiPracticeReducer'
import referralsReducer from './referralsReducer'
import qaReducer from './qaReducer'
import timelineReducer from './timelineReducer'
import txTrackerReducer from './txTrackerReducer'

export default history =>
  combineReducers({
    router: connectRouter(history),
    appReducer,
    authReducer,
    billingReducer,
    chatReducer,
    patientsReducer,
    practiceReducer,
    productReducer,
    profileReducer,
    quickSignUpReducer,
    releaseNotesReducer,
    supportReducer,
    treatmentReducer,
    pixelReducer,
    taskManagerReducer,
    announcmentsReducer,
    rcDashboardReducer,
    giReducer,
    hiReducer,
    pmsReducer,
    autochartReducer,
    multiPracticeReducer,
    referralsReducer,
    qaReducer,
    timelineReducer,
    txTrackerReducer
  })
