import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Actions from 'actions'
import SwooshLargeIcon from 'components/common/icons/SwooshLarge2'
import BillingForm from './BillingForm'
import routesConsts from 'consts/routesConsts'
import { Grid } from '@material-ui/core'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles(theme => ({
  pageContainer: {
    paddingTop: 100,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  swoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  practiceInfoTitle: {
    fontFamily: 'Ogg',
    fontSize: '40px',
    lineHeight: '40px',
    color: 'var(--text-color-1)',
    '@media (max-width: 960px)': {
      marginBottom: 20,
      textAlign: 'center'
    }
  },
  formWrapper: {
    height: '100%',
    width: '100%',
    maxWidth: 1000,
    margin: '0 auto',
    '@media (max-width: 750px)': {
      maxWidth: '100%'
    }
  },
  billingFormContainer: {
    height: 'calc(88vh - 20%)',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const BillingInfo = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    address,
    city,
    state,
    zip,
    country,
    billingInfo: { paymentMethodId, cardLast4, cardBrand },
    roleDescription,
    emailAddress,
    username,
    practiceName
  } = useSelector(state => state.authReducer)
  const practiceDetails = useSelector(state => state.practiceReducer.details)

  const mixpanelPayload = useMemo(
    () => ({
      roleDescription,
      email: emailAddress,
      username,
      practiceName
    }),
    [emailAddress, practiceName, roleDescription, username]
  )

  const handleSubmit = useCallback(
    ({ paymentMethodId, cardLast4, cardBrand }) => {
      dispatch(
        Actions.changeBillingForm([
          { key: 'paymentMethodId', value: paymentMethodId },
          { key: 'cardLast4', value: cardLast4 },
          { key: 'cardBrand', value: cardBrand }
        ])
      )

      dispatch(
        Actions.requestAttachCreditCard({
          paymentMethodId,
          doctorUsername: username,
          email: emailAddress,
          isQuickSignup: false
        })
      )
    },
    [dispatch, emailAddress, username]
  )

  const handleSkip = useCallback(() => {
    history.push(routesConsts.PATIENTS)
  }, [history])

  return (
    <div className={classes.pageContainer}>
      {!isMobile() && <SwooshLargeIcon className={classes.swoosh} />}

      <Grid container alignItems="flex-start" className={classes.formWrapper} spacing={3}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <div className={classes.practiceInfoTitle}>{t('pages.billingInfo.label')}</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.billingFormContainer}>
          <BillingForm
            paymentMethodId={paymentMethodId}
            cardLast4={cardLast4}
            cardBrand={cardBrand}
            address1={address || practiceDetails.address1}
            city={city || practiceDetails.city}
            state={state || practiceDetails.state}
            zip={zip || practiceDetails.zip}
            country={country || practiceDetails.country}
            onSkip={handleSkip}
            onSubmitCallback={handleSubmit}
            mixpanelPrefix="Sign up"
            mixpanelPayload={mixpanelPayload}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default BillingInfo
