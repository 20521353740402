import { useMemo } from 'react'
import { calcOverdueDate, isPatientScanOverdue } from 'utils/milestoneUtils'

export const SpecialConditions = {
  Urgent: 'urgent',
  Paused: 'paused',
  Overdue: 'overdue',
  NeedsAttention: 'needsAttention'
}

export default ({ patient, patientTags }) => {
  const isPaused = useMemo(() => patientTags.some(tag => tag.type === 'scan' && tag.value === 'paused'), [patientTags])
  const isUrgent = useMemo(() => patientTags.some(tag => tag.type === 'scan' && tag.value === 'urgent'), [patientTags])
  const isNeedsAttention = useMemo(
    () => patientTags.some(tag => tag.type === 'scan' && tag.value === 'needsAttention'),
    [patientTags]
  )
  const overdueDays = useMemo(
    () => (isPatientScanOverdue(patientTags) ? calcOverdueDate(patient) : null),
    [patientTags, patient]
  )

  const specialCondition = useMemo(() => {
    if (isNeedsAttention) {
      return SpecialConditions.NeedsAttention
    } else if (isUrgent) {
      return SpecialConditions.Urgent
    } else if (isPaused) {
      return SpecialConditions.Paused
    } else if (overdueDays) {
      return SpecialConditions.Overdue
    } else {
      return null
    }
  }, [isUrgent, isPaused, overdueDays, isNeedsAttention])

  return {
    overdueDays,
    specialCondition
  }
}
