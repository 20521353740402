import React, { useMemo } from 'react'
import { BannerTypes } from 'consts/chatConsts'
import DisabledPatientBanner from './Banners/DisabledPatientBanner'
import TreatmentTypeCtaBanner from './Banners/TreatmentTypeCtaBanner'
import PausedScansBanner from './Banners/PausedScansBanner'
import ScanOverdueBanner from './Banners/ScanOverdueBanner'
import NextScanDateBanner from './Banners/NextScanDateBanner'

const Banner = ({ type }) => {
  const banners = useMemo(
    () => ({
      [BannerTypes.Overdue]: ScanOverdueBanner,
      [BannerTypes.TreatmentTypeCTA]: TreatmentTypeCtaBanner,
      [BannerTypes.DisabledPatient]: DisabledPatientBanner,
      [BannerTypes.PausedScans]: PausedScansBanner,
      [BannerTypes.NextScanDate]: NextScanDateBanner
    }),
    []
  )

  return React.createElement(banners[type])
}

export default Banner
