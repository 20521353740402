import React from 'react'
import { Grid } from '@material-ui/core'
import { MessagePatient as MessagePatientIcon } from 'components/common/icons'
import { useTranslation } from 'react-i18next'

const MessagePatient = ({ isChecked }) => {
  const { t } = useTranslation()

  return (
    isChecked && (
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={2}>
          <MessagePatientIcon />
        </Grid>
        <Grid item xs={10}>
          {t('dialogs.hiPatientGuidelinesViewer.actions.messagePatient')}
        </Grid>
      </Grid>
    )
  )
}

export default MessagePatient
