import Actions from 'actions'
import { push } from 'connected-react-router'
import { ROUTES } from 'consts'
import { TimelineItemTypes } from 'consts/timelineConsts'
import * as timelineGraphql from 'graphql/timeline.graphql'
import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { fetchAll } from 'utils/graphqlUtils'
import { logInfo } from 'utils/logUtils'
import queryString from 'query-string'
import { API, graphqlOperation } from 'aws-amplify'

export const fetchPatientTimelineItemsEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_PATIENT_TIMELINE_ITEMS),
    pluck('payload'),
    switchMap(({ patientId, startTime = new Date().getTime() }) =>
      from(
        fetchAll(
          timelineGraphql.timelineItemsByPatientIdSorted(timelineGraphql.mainFeed.timelineItem),
          {
            patientId,
            sortDirection: 'DESC',
            limit: 500
          },
          'timelineItemsByPatientIdSorted'
        )
      ).pipe(
        tap((timelineItems = []) =>
          logInfo(`FETCH_PATIENT_TIMELINE_ITEMS_RECEIVED`, {
            took: new Date().getTime() - startTime,
            timelineItemsCount: timelineItems.length,
            sizeInKilobytes: JSON.stringify(timelineItems).length / 1024
          })
        ),
        mergeMap(timelineItems =>
          of(
            Actions.fetchPatientTimelineItemsReceived({
              data: timelineItems,
              nextToken: null
            })
          )
        ),
        catchError(err => of(Actions.fetchPatientTimelineItemsFailed(err)))
      )
    )
  )

export const navigateToLatestScanItem = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_PATIENT_TIMELINE_ITEMS_RECEIVED),
    filter(() => window.location.pathname.startsWith(ROUTES.PATIENTS)),
    pluck('payload', 'data'),
    map(timelineItems => ({
      timelineItems,
      latestScanItem: timelineItems.find(item => item.type === TimelineItemTypes.GrinScan),
      queryParams: queryString.parse(window.location.search)
    })),
    filter(({ queryParams, timelineItems }) => {
      const currentlySelectedTimelineItem = queryParams.timelineItem
      const doesCurrentlySelectedTimelineItemPresent = timelineItems.find(
        item => item.id === currentlySelectedTimelineItem
      )
      return !currentlySelectedTimelineItem || !doesCurrentlySelectedTimelineItemPresent
    }),
    map(({ latestScanItem, queryParams }) =>
      push(
        `${window.location.pathname}?${queryString.stringify({
          ...queryParams,
          timelineItem: latestScanItem?.id
        })}`
      )
    )
  )

export const fetchTimelineItemEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_TIMELINE_ITEM),
    pluck('payload'),
    switchMap(({ timelineItemId, startTime = new Date().getTime() }) =>
      from(
        API.graphql(
          graphqlOperation(timelineGraphql.getTimelineItem(timelineGraphql.scanFeed.timelineItem), {
            id: timelineItemId
          })
        )
      ).pipe(
        tap(() =>
          logInfo(`FETCH_TIMELINE_ITEM_RECEIVED`, {
            took: new Date().getTime() - startTime,
            timelineItemId
          })
        ),
        map(res => res.data.getTimelineItem),
        mergeMap(timelineItem => of(Actions.fetchTimelineItemReceived({ timelineItem }))),
        catchError(ex => of(Actions.fetchTimelineItemFailed(ex)))
      )
    )
  )

export const scanReviewReceivedLiveUpdatesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    pluck('payload'),
    filter(({ entityType, method }) => entityType === 'ScanReview' && method === 'INSERT'),
    withLatestFrom(state$),
    map(([payload, state]) => ({
      scanReviewId: payload.scanReviewId,
      isTimelineV2: state.timelineReducer.isTimelineV2
    })),
    filter(({ isTimelineV2 }) => isTimelineV2),
    switchMap(({ scanReviewId }) =>
      from(
        API.graphql(
          graphqlOperation(timelineGraphql.getScanReview(timelineGraphql.scanFeed.scanReview), {
            id: scanReviewId
          })
        )
      ).pipe(
        map(res => res.data.getScanReview),
        mergeMap(scanReview => of(Actions.newScanReviewReceived({ scanReview }))),
        catchError(ex =>
          of(Actions.notificationReceivedFailed({ scanReviewId, message: 'failed to fetch scan review' }))
        )
      )
    )
  )
