import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useLocation, Prompt } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from '../text/DazzedParagraph16'

const LeaveModal = ({ blockNavigation }) => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [nextLocation, setNextLocation] = useState(location)
  const [navigationApproved, setNavigationApproved] = useState(false)
  const [shouldUnload, setShouldUnload] = useState(false)

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
    setShouldUnload(false)
  }, [])

  const openModal = useCallback(() => setIsModalOpen(true), [])

  const handleUserNavigation = useCallback(
    (nextLocation, action) => {
      if (action === 'POP') {
        return true
      }

      if (!navigationApproved && blockNavigation) {
        openModal()
        setNextLocation(nextLocation)
        return false
      }
      return true
    },
    [navigationApproved, openModal, blockNavigation]
  )

  const handleNavigationConfirmed = useCallback(() => {
    closeModal()
    setNavigationApproved(true)
  }, [closeModal])

  useEffect(() => {
    if (navigationApproved && nextLocation) {
      setShouldUnload(true)
      history.push(nextLocation.pathname)
    }
  }, [navigationApproved, nextLocation, history])

  useEffect(() => {
    const unload = event => {
      if (blockNavigation && !shouldUnload) {
        event.returnValue = 'true'
      }
      if (shouldUnload) {
        event.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', unload)

    return () => window.removeEventListener('beforeunload', unload)
  }, [blockNavigation, shouldUnload])

  return (
    <>
      <Prompt when message={handleUserNavigation} />
      <BaseModal
        variant="alert"
        open={isModalOpen}
        handleClose={closeModal}
        title={t('pages.accountSettings.leaveWithoutSavingModal.title')}
        primaryLabel={t('pages.accountSettings.leaveWithoutSavingModal.primaryBtn')}
        secondaryLabel={t('general.cancel')}
        onPrimaryBtnClick={handleNavigationConfirmed}
        onSecondaryBtnClick={closeModal}
        largerButtons
      >
        <Grid container alignItems="center" direction="column">
          <DazzedParagraph16>{t('pages.accountSettings.leaveWithoutSavingModal.content')}</DazzedParagraph16>
          <DazzedParagraph16>{t('pages.accountSettings.leaveWithoutSavingModal.content2')}</DazzedParagraph16>
        </Grid>
      </BaseModal>
    </>
  )
}

export default LeaveModal
