import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import CustomDatePicker from 'components/common/CustomDatePicker'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { LeftArrow1 as LeftArrowIcon } from 'components/common/icons'
import { TIME_FORMAT_11 } from 'consts/dateTimeConsts'

const useStyles = makeStyles({
  header: {
    position: 'sticky',
    zIndex: 1,
    top: 0,
    backgroundColor: 'var(--bg-color-10)',
    paddingTop: 0
  },
  actionButtonContainer: {
    cursor: 'pointer'
  },
  actionButtonLabel: {
    margin: '0 10px',
    fontWeight: 600,
    color: 'var(--text-color-3)',
    textDecoration: 'underline'
  },
  recordDate: {
    color: 'var(--text-color-11)'
  },
  recordDatePicker: {
    margin: '5px',
    '& div': {
      marginTop: 0,
      marginBottom: 0
    },
    '& svg': {
      top: 5
    }
  }
})

const RecordsSetHeader = ({ record, date, setDate, onCancel, onSubmit, onBack }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { data: records } = useSelector(state => state.patientsReducer.patientCard.scans)

  return (
    <Grid container alignItems="center" spacing={2} className={classes.header}>
      <Grid item>
        <Grid container alignItems="center">
          {!!records.length && (
            <Grid item>
              <Grid container alignItems="center" onClick={onBack} className={classes.actionButtonContainer}>
                <Grid item>
                  <LeftArrowIcon />
                </Grid>
                <Grid item>
                  <DazzedParagraph14 className={classes.actionButtonLabel}>{t('general.back')}</DazzedParagraph14>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container alignItems="center">
          <CustomDatePicker
            className={classes.recordDatePicker}
            format={TIME_FORMAT_11}
            value={date}
            handleDateChange={setDate}
            disablePast={false}
            disableFuture
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RecordsSetHeader
