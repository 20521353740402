import React, { useEffect, useMemo } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router'
import queryString from 'query-string'
import ROUTES from 'consts/routesConsts'
import { useDispatch } from 'react-redux'
import SignUp from './SignUp'
import Details from './Details'
import Photo from './Photo'
import Terms from './Terms'
import DeactivatedAccount from './DeactivatedAccount'
import { setCloudFrontUrl } from 'utils/storageUtils'
import Actions from 'actions'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: theme.palette.primary.main
  }
}))

export default () => {
  const styles = useStyles()
  const location = useLocation()
  const dispatch = useDispatch()

  const params = useMemo(() => queryString.parse(location.search), [location])

  useEffect(() => {
    dispatch(
      Actions.changePracticeMembersAuthForm([
        {
          key: 'firstName',
          value: params.firstName || ''
        },
        {
          key: 'lastName',
          value: params.lastName || ''
        },
        {
          key: 'email',
          value: params.email || ''
        },
        {
          key: 'country',
          value: params.country || ''
        }
      ])
    )
  }, [dispatch]) //eslint-disable-line

  useEffect(() => {
    setCloudFrontUrl()
  }, [])

  return (
    <div className={styles.container}>
      <Switch>
        <Route exact path={ROUTES.AUTH_PRACTICE_MEMBERS_SIGN_UP} component={SignUp} />
        <Route exact path={ROUTES.AUTH_PRACTICE_MEMBERS_DETAILS} component={Details} />
        <Route exact path={ROUTES.AUTH_PRACTICE_MEMBERS_PHOTO} component={Photo} />
        <Route exact path={ROUTES.AUTH_PRACTICE_MEMBERS_DEACTIVATED} component={DeactivatedAccount} />
        <Route exact path={ROUTES.AUTH_PRACTICE_MEMBERS_TERMS} component={Terms} />
        <Route
          path={ROUTES.AUTH_PRACTICE_MEMBERS}
          render={() => <Redirect to={{ pathname: ROUTES.AUTH_PRACTICE_MEMBERS_SIGN_UP, search: location.search }} />}
        />
      </Switch>
    </div>
  )
}
