import React, { useState, useCallback } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, makeStyles } from '@material-ui/core'
import { useDebouncedCallback } from 'use-debounce'

const useStyles = makeStyles(() => ({
  tag: {
    borderRadius: '7px'
  },
  inputRoot: {
    maxHeight: '120px',
    overflowY: 'auto',
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none'
    }
  },
  root: {
    border: '1px solid var(--border-color-11)',
    borderRadius: '4px'
  }
}))

const AutocompleteWithChips = ({
  value,
  onChange,
  placeholder = '',
  options = [],
  validateInput = () => true,
  ...props
}) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')

  const onChangeAutocomplete = useCallback(
    (e, value, reason) => {
      if (['select-option', 'remove-option'].includes(reason)) {
        setInputValue('')
        onChange(value)
      }
    },
    [onChange]
  )

  const onChangeInput = useCallback(
    e => {
      const string = e.target.value
      if (string.includes(',') || string.includes(';')) {
        const preparedForSubmit = string.split(/,|;| /).filter(el => !!el.trim() && !value.includes(el))
        onChange(value.concat(preparedForSubmit))
        setInputValue('')
      } else {
        setInputValue(e.target.value)
      }
    },
    [onChange, value]
  )

  const onBlurInput = useDebouncedCallback(() => {
    if (inputValue && !value.includes(inputValue)) {
      onChange(value.concat(inputValue), inputValue)
      setInputValue('')
    }
  }, 50)

  return (
    <Autocomplete
      {...props}
      multiple
      classes={{
        tag: classes.tag,
        inputRoot: classes.inputRoot,
        root: classes.root
      }}
      value={value}
      inputValue={inputValue}
      options={options}
      freeSolo={true}
      autoSelect={true}
      renderInput={params => {
        return (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            onBlur={onBlurInput}
            onChange={onChangeInput}
            onKeyDown={e => {
              const separatorKeys = [',', ';', ' ', 'Enter', 'Tab']
              if (separatorKeys.includes(e.key)) {
                e.preventDefault()
                if (validateInput(inputValue)) {
                  onBlurInput()
                }
              }
            }}
          />
        )
      }}
      onChange={onChangeAutocomplete}
      disableClearable
    />
  )
}

export default AutocompleteWithChips
