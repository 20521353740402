const HI_NOTIFY_PRACTICE = 'HI_NOTIFY_PRACTICE'
const HI_NOTIFY_PRACTICE_RECEIVED = 'HI_NOTIFY_PRACTICE_RECEIVED'
const HI_NOTIFY_PRACTICE_FAILED = 'HI_NOTIFY_PRACTICE_FAILED'

const hiNotifyPractice = payload => ({
  type: HI_NOTIFY_PRACTICE,
  payload
})

const hiNotifyPracticeReceived = payload => ({
  type: HI_NOTIFY_PRACTICE_RECEIVED,
  payload
})

const hiNotifyPracticeFailed = payload => ({
  type: HI_NOTIFY_PRACTICE_FAILED,
  payload
})

export default {
  hiNotifyPractice,
  hiNotifyPracticeReceived,
  hiNotifyPracticeFailed,
  HI_NOTIFY_PRACTICE,
  HI_NOTIFY_PRACTICE_RECEIVED,
  HI_NOTIFY_PRACTICE_FAILED
}
