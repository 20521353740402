import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import GrinLabel from './text/GrinLabel'
import { isMobile } from 'utils/mobileUtils'
import { Grid, ClickAwayListener, IconButton } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import DazzedParagraph16 from './text/DazzedParagraph16'
import { Eye1 as EyeIcon } from './icons'

const useFormInputStyles = props =>
  makeStyles({
    formInputContainer: {
      position: 'relative',
      width: '100%',
      height: ({ thick }) => (thick ? '42px' : '30px'),
      marginTop: '5px',
      marginBottom: ({ notes }) => (notes ? 0 : 8),
      padding: ({ bright, borderBottom }) => (borderBottom ? '0px' : bright ? '5px' : 0),
      transition: 'border .5s ease',
      display: 'flex',
      alignItems: 'center',
      border: ({ bright, dark, borderBottom }) =>
        borderBottom ? '0px' : bright ? '1px solid var(--border-color-2)' : '1px solid var(--border-color-6)',
      borderBottom: ({ borderBottom }) => (borderBottom ? '1px solid var(--border-color-13)' : undefined),
      '&.error': {
        border: ({ bright, borderBottom }) =>
          borderBottom ? '0px' : bright ? '1px solid red' : '1px solid var(--board-card-color-2)',
        borderBottom: ({ borderBottom }) => (borderBottom ? '1px solid red' : undefined)
      },
      background: ({ isDisabled, bright, borderBottom }) =>
        borderBottom
          ? 'transparent'
          : isDisabled
          ? bright
            ? 'var(--bg-color-11)'
            : 'var(--bg-color-39)'
          : bright
          ? 'var(--bg-color-10)'
          : 'initial',
      '@media (max-width: 960px)': {
        height: 'auto !important'
      }
    },
    iconContainer: {
      marginRight: 5,
      marginTop: 3,
      marginLeft: 5
    },
    subtitle: {
      marginLeft: 8,
      fontSize: 12,
      textTransform: 'none'
    },
    formInput: {
      background: ({ isDisabled, bright, borderBottom }) =>
        borderBottom
          ? 'transparent'
          : isDisabled
          ? 'var(--bg-color-11)'
          : bright
          ? 'var(--bg-color-10)'
          : 'var(--bg-color-4)',
      width: '100%',
      border: '0px',
      height: ({ thick }) => (thick ? '100%' : 'auto'),
      padding: ({ bright }) => (bright ? 0 : '5px'),
      fontSize: '16px',
      color: ({ bright, dark }) => (bright ? 'var(--text-color-6)' : 'var(--text-color-1)'),
      '&:invalid': {
        boxShadow: 'none'
      },
      '&:focus': {
        outline: 'none'
      },
      '&::placeholder': {
        fontFamily: 'Dazzed, Arial',
        fontSize: '13px',
        color: ({ bright, dark }) => (bright ? 'black' : 'var(--text-color-1)'),
        fontStyle: ({ borderBottom }) => (borderBottom ? 'none' : 'italic'),
        opacity: 0.3
      },
      '@media (max-width: 960px)': {
        height: '40px !important',
        fontSize: '18px !important'
      }
    },
    landingThemeContainer: {
      width: '100%',
      border: '1px solid var(--border-color-1)!important',
      height: '48px!important',
      marginTop: 5
    },
    landingThemeInput: {
      padding: ({ isMobile }) => (isMobile ? '10px!important' : '20px!important'),
      height: '100%!important',
      fontSize: ({ isMobile }) => (isMobile ? '12px' : '16px'),
      color: 'var(--text-color-6)',
      '&:focus': {
        outline: 'none'
      },
      '&.error': {
        border: '1px solid red'
      },
      '&::placeholder': {
        fontFamily: 'Dazzed, Arial',
        fontSize: ({ isMobile }) => (isMobile ? '12px' : '16px'),
        color: 'var(--text-color-11)!important',
        fontStyle: 'normal',
        opacity: 1
      }
    },
    notes: {
      color: 'var(--text-color-12)',
      fontSize: 11,
      marginBottom: 5,
      height: 17,
      fontWeight: 'normal'
    },
    errorMessage: {
      opacity: 0,
      color: ({ bright }) => (bright ? 'red' : 'var(--text-color-10)'),
      marginTop: '2px',
      marginBottom: '5px',
      height: '17px',
      fontSize: ({ isMobile }) => (isMobile ? '10px' : '12px'),
      transition: 'opacity .15s ease',
      '&.active': {
        opacity: 1
      }
    },
    suggestions: {
      position: 'absolute',
      top: 45,
      left: 0,
      width: '100%',
      padding: '12px',
      border: '1px solid var(--border-color-12)',
      backgroundColor: '#fff',
      zIndex: 999
    },
    suggestionItem: {
      cursor: 'pointer',
      marginBottom: '10px'
    },
    label: {
      '@media (max-width: 960px)': {
        fontSize: '14px !important'
      }
    },
    counter: {
      padding: 5
    },
    showPasswordBtn: {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '0 10px',
      cursor: 'pointer'
    }
  })({
    isDisabled: props.isDisabled,
    thick: props.thick,
    bright: props.bright,
    borderBottom: props.borderBottom,
    isMobile: isMobile(),
    notes: props.notes
  })

const calcAutocompleteStatus = (type, allowAutocomplete) => {
  if (!allowAutocomplete && type === 'password') {
    return 'new-password'
  } else {
    return allowAutocomplete ? 'on' : 'off'
  }
}

const FormInput = forwardRef(
  (
    {
      title,
      subtitle,
      value,
      setValue,
      onFocus = () => {},
      onBlur = () => {},
      type = 'text',
      placeholder,
      style = { bright: false, thick: false, landing: false },
      allowAutoComplete = false,
      validationRules = {},
      notes = '',
      errorMessage = '',
      isDisabled,
      inputStyle = {},
      titleStyle = {},
      keepErrorContainerWhenInactive = true,
      minValue,
      maxValue,
      iconComponent,
      containerClassName = '',
      inputWrapperClassName = '',
      inputClassName = '',
      options = [],
      withCounter = false,
      disabledDecrement = false,
      disabledIncrement = false,
      inputSuffix = '',
      inputSuffixClassname = '',
      inputSuffixJustifyContent = 'flex-start',
      showPasswordButton = false,
      id,
      ...props
    },
    ref
  ) => {
    const classes = useFormInputStyles({
      isDisabled,
      notes,
      bright: style.bright,
      thick: style.thick,
      borderBottom: style.borderBottom
    })

    const [showOptions, setShowOptions] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const inputType = useMemo(
      () => (type === 'password' ? (showPassword ? 'text' : 'password') : type),
      [showPassword, type]
    )

    const suggestions = useMemo(
      () => options.filter(option => option.trim().toLowerCase().startsWith(value?.toLowerCase())),
      [value, options]
    )

    const handleClickShowPassword = useCallback(() => {
      setShowPassword(!showPassword)
    }, [showPassword])

    const handleFocus = useCallback(() => {
      setShowOptions(true)
      onFocus()
    }, [onFocus])

    const handleBlur = useCallback(() => {
      onBlur()

      if (showOptions) {
        return
      }

      setShowOptions(false)
    }, [onBlur, showOptions])

    const handleOptionSelect = useCallback(
      option => {
        setValue(option)
        setShowOptions(false)
      },
      [setValue]
    )

    const handleDecrement = useCallback(() => {
      onFocus()
      setValue(+value - 1)
    }, [value, onFocus, setValue])

    const handleIncrement = useCallback(() => {
      onFocus()
      setValue(+value + 1)
    }, [value, onFocus, setValue])

    return (
      <Grid container className={containerClassName}>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            {title && (
              <GrinLabel color={!style.bright && 'var(--text-color-1)'} className={classes.label} style={titleStyle}>
                {`${title}${validationRules.required ? '*' : ''}`}
              </GrinLabel>
            )}
          </Grid>
          <Grid item>
            {subtitle && (
              <GrinLabel className={classes.subtitle} color={!style.bright && 'var(--text-color-1)'}>
                {subtitle}
              </GrinLabel>
            )}
          </Grid>
        </Grid>
        <ClickAwayListener onClickAway={() => setShowOptions(false)}>
          <div
            className={[
              classes.formInputContainer,
              errorMessage ? 'error' : '',
              style.landing ? classes.landingThemeContainer : '',
              inputWrapperClassName
            ].join(' ')}
          >
            {!!iconComponent && <div className={classes.iconContainer}>{iconComponent}</div>}
            <input
              id={props.name}
              value={value ?? ''}
              type={inputType}
              placeholder={placeholder}
              className={[classes.formInput, style.landing ? classes.landingThemeInput : '', inputClassName].join(' ')}
              style={inputStyle}
              onChange={({ target }) => setValue(target.value)}
              autoComplete={calcAutocompleteStatus(type, allowAutoComplete)}
              required={validationRules.required}
              pattern={validationRules.pattern}
              disabled={isDisabled}
              onFocus={handleFocus}
              onBlur={handleBlur}
              min={minValue}
              max={maxValue}
              ref={ref}
              {...props}
            />
            {inputSuffix && inputSuffixClassname && (
              <Grid onClick={() => ref.current.focus()} container justifyContent={inputSuffixJustifyContent}>
                <DazzedParagraph16 for={props.name} className={inputSuffixClassname}>
                  {inputSuffix}
                </DazzedParagraph16>
              </Grid>
            )}
            {withCounter && (
              <Grid className={classes.counter} container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <IconButton onClick={handleDecrement} size="small" disabled={disabledDecrement || isDisabled}>
                    <RemoveIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleIncrement} size="small" disabled={disabledIncrement || isDisabled}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
            {type === 'password' && showPasswordButton && (
              <div className={classes.showPasswordBtn} onClick={handleClickShowPassword}>
                <EyeIcon />
              </div>
            )}
            {showOptions && suggestions.length > 0 && (
              <div className={classes.suggestions}>
                {suggestions.map(suggestion => (
                  <div
                    key={`suggestion-${suggestion}`}
                    className={classes.suggestionItem}
                    onClick={() => handleOptionSelect(suggestion)}
                  >
                    <DazzedParagraph14>{suggestion}</DazzedParagraph14>
                  </div>
                ))}
              </div>
            )}
          </div>
        </ClickAwayListener>
        {!errorMessage?.length && notes && <div className={classes.notes}>{notes}</div>}
        {(keepErrorContainerWhenInactive || errorMessage?.length > 0) && (
          <div className={`${classes.errorMessage} ${errorMessage ? 'active' : ''}`}>{errorMessage}</div>
        )}
      </Grid>
    )
  }
)

export default FormInput
