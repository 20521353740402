import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid, InputBase } from '@material-ui/core'
import TrackButton from './TrackButton'
import { ScanTrackingStatus, TreatmentTrackOptions } from 'consts/giConsts'
import TrackingConditionOption from './TrackingConditionOption'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import useTreatmentTracking from './useTreatmentTracking'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { sortTeeth } from 'utils/giUtils'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import useRolePermissions from 'hooks/useRolePermissions'
import ConfirmationModal from 'components/common/ConfirmationModal'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  notesInput: {
    fontSize: 14,
    fontWeight: 500,
    border: '1px solid var(--border-color-24)',
    borderRadius: 12,
    width: '100%',
    padding: 16
  },
  disabledOverlay: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: '#fff',
    opacity: 0.7,
    zIndex: 9999
  }
}))

const TreatmentTrackingPanel = ({ scanId }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isHiUser } = useRolePermissions()
  const [confirmationOpen, setConfirmationOpen] = useState(false)

  const {
    scanTrackingStatus,
    isEditAllowed,
    isSubmitDisabled,
    isLoading,
    values,
    conditionOptions,
    untrackedTeeth,
    isNoteEnabled,
    isDismissed,
    setConditionEnabled,
    setConditionSelections,
    submit,
    dismiss
  } = useTreatmentTracking({
    scanId
  })

  const handleConfirm = useCallback(() => {
    dismiss()
    setConfirmationOpen(false)
  }, [dismiss])

  return scanTrackingStatus === ScanTrackingStatus.NotRequired && isDismissed ? (
    <div>
      <DazzedParagraph14 strong>{t('dialogs.scanSummary.treatmentTracking.scanWasDismissed')}</DazzedParagraph14>
    </div>
  ) : scanTrackingStatus === ScanTrackingStatus.NotRequired || !scanTrackingStatus ? (
    <div>
      <DazzedParagraph14 strong>{t('dialogs.scanSummary.treatmentTracking.scanNotRequired')}</DazzedParagraph14>
    </div>
  ) : (
    <>
      <Grid container direction="column" spacing={2} className={classes.root}>
        {!isEditAllowed && <div className={classes.disabledOverlay} />}
        {isEditAllowed && untrackedTeeth?.length && (
          <Grid item>
            <DazzedParagraph12>
              {t('pages.patients.selectedPatient.chat.gi.untrackedTeeth', {
                teeth: sortTeeth(untrackedTeeth).join(', ')
              })}
            </DazzedParagraph12>
          </Grid>
        )}
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <TrackButton
                value={TreatmentTrackOptions.OnTrack}
                onClick={values.setTrackStatus}
                isSelected={values.trackStatus === TreatmentTrackOptions.OnTrack}
              />
            </Grid>
            <Grid item>
              <TrackButton
                value={TreatmentTrackOptions.OffTrack}
                onClick={values.setTrackStatus}
                isSelected={values.trackStatus === TreatmentTrackOptions.OffTrack}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            {conditionOptions.map(condition => (
              <Grid item key={`tracking-condition-option-${condition.id}`}>
                <TrackingConditionOption
                  conditionId={condition.id}
                  onToggle={setConditionEnabled}
                  checked={!!values.conditions[condition.id]}
                  selections={values.conditions[condition.id]?.selections}
                  onSelectionsChanged={setConditionSelections}
                  untrackedTeeth={untrackedTeeth}
                  disableCloseOnSelect
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        {isNoteEnabled && (
          <Grid item>
            <InputBase
              value={values.notes}
              onChange={e => values.setNotes(e.target.value)}
              multiline
              rows={4}
              className={classes.notesInput}
              placeholder={t('dialogs.scanSummary.treatmentTracking.notesPlaceholder')}
            />
          </Grid>
        )}
        <Grid item>
          {isEditAllowed && (
            <Grid container justifyContent="space-between">
              <PrimaryButton
                label={t('general.submit')}
                onClick={submit}
                small
                isLoading={isLoading}
                disabled={isSubmitDisabled}
              />
              {isHiUser && (
                <SecondaryButton
                  label={t('general.dismiss')}
                  onClick={() => setConfirmationOpen(true)}
                  small
                  isLoading={isLoading}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <ConfirmationModal
        isOpen={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleConfirm}
        title={t('dialogs.scanSummary.treatmentTracking.dismissConfirmation.title')}
        acceptButtonText={t('general.dismiss')}
      />
    </>
  )
}

export default TreatmentTrackingPanel
