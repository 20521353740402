import * as React from 'react'

function Pencil(props) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.17563 8.67432C1.0574 8.67432 0.939407 8.62929 0.849122 8.53901C0.668782 8.35867 0.668782 8.06634 0.849122 7.886L8.06593 0.66919C8.24604 0.488851 8.5386 0.488851 8.71894 0.66919C8.89928 0.849529 8.89928 1.14186 8.71894 1.3222L1.50213 8.53901C1.41208 8.62906 1.29385 8.67432 1.17563 8.67432Z"
        fill="#BCB8B8"
      />
      <path
        d="M0.462278 12.0001C0.430182 12.0001 0.397624 11.9966 0.365066 11.9897C0.115685 11.9361 -0.0431801 11.6907 0.0103905 11.4413L0.723897 8.11554C0.777467 7.86616 1.02408 7.70776 1.2723 7.76086C1.52168 7.81443 1.68055 8.05989 1.62698 8.30927L0.913472 11.635C0.86706 11.8519 0.675406 12.0001 0.462278 12.0001Z"
        fill="#BCB8B8"
      />
      <path
        d="M3.78793 11.2865C3.66971 11.2865 3.55171 11.2415 3.46143 11.1512C3.28109 10.9709 3.28109 10.6785 3.46143 10.4982L10.6782 3.28162C10.8583 3.10128 11.1509 3.10128 11.3312 3.28162C11.5116 3.46196 11.5116 3.75429 11.3312 3.93463L4.11467 11.1512C4.02438 11.2415 3.90616 11.2865 3.78793 11.2865Z"
        fill="#BCB8B8"
      />
      <path
        d="M0.461517 12.0001C0.248388 12.0001 0.0569655 11.8518 0.0103221 11.635C-0.0430177 11.3856 0.115616 11.1402 0.364997 11.0866L3.69077 10.3731C3.94038 10.3202 4.18584 10.4786 4.23918 10.7278C4.29252 10.9771 4.13388 11.2226 3.8845 11.2762L0.558729 11.9897C0.526171 11.9968 0.493613 12.0001 0.461517 12.0001Z"
        fill="#BCB8B8"
      />
      <path
        d="M9.69853 5.37605C9.58031 5.37605 9.46208 5.33102 9.37203 5.24074L6.75977 2.62847C6.57943 2.44813 6.57943 2.1558 6.75977 1.97546C6.93987 1.79513 7.23267 1.79513 7.41278 1.97546L10.025 4.58773C10.2054 4.76807 10.2054 5.0604 10.025 5.24074C9.93498 5.33102 9.81676 5.37605 9.69853 5.37605Z"
        fill="#BCB8B8"
      />
      <path
        d="M11.0047 4.06999C10.8864 4.06999 10.7682 4.02496 10.6779 3.93468C10.4976 3.75434 10.4976 3.46201 10.6779 3.28144C10.9349 3.02444 11.0765 2.67646 11.0765 2.30192C11.0765 1.92739 10.9349 1.57941 10.6779 1.32241C10.4207 1.06518 10.0727 0.923633 9.69818 0.923633C9.32365 0.923633 8.97567 1.06518 8.71867 1.32241C8.53856 1.50275 8.24623 1.50298 8.06543 1.32241C7.88509 1.14207 7.88509 0.849742 8.06543 0.669172C8.49677 0.237605 9.07658 0 9.69818 0C10.3196 0 10.8996 0.237605 11.3309 0.669172C11.7625 1.10051 12.0001 1.68032 12.0001 2.30192C12.0001 2.92353 11.7625 3.50334 11.3309 3.93468C11.2411 4.02473 11.1229 4.06999 11.0047 4.06999Z"
        fill="#BCB8B8"
      />
    </svg>
  )
}

export default Pencil
