import * as React from 'react'

export default () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 3">
        <circle id="Ellipse 1" cx="8.5" cy="8.5" r="7.5" stroke="#212121" strokeWidth="0.75" />
        <g id="Group 1">
          <path
            id="Rectangle 4"
            d="M6.39891 5.95867C6.3989 5.42774 6.97892 5.10052 7.43333 5.37509L11.6392 7.91644C12.0782 8.18167 12.0782 8.81832 11.6392 9.08356L7.43344 11.625C6.97903 11.8995 6.39901 11.5723 6.399 11.0414L6.39891 5.95867Z"
            fill="#212121"
          />
        </g>
      </g>
    </svg>
  )
}
