import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedHeading16 from 'components/common/text/DazzedHeading16'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import SelectInput from 'components/common/SelectInput'
import { useSelector } from 'react-redux'
import { TIME_FORMAT_6 } from 'consts/dateTimeConsts'
import { Grid, IconButton } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBackIosRounded'
import ArrowForward from '@material-ui/icons/ArrowForwardIosRounded'
import { trackEvent } from 'utils/analyticsUtils'
import { getObjectByIndexCyclic } from 'utils/arrayUtils'
import { generateScanNumberDict, getAggregateScans } from 'utils/scanUtils'
import { isLandscape } from 'utils/mobileUtils'

const useStyles = ({ isMobile }) =>
  makeStyles(theme => ({
    mobileTitle: {
      fontSize: '16px'
    },
    mobileTitleContainer: {
      paddingTop: 10
    },
    arrow: {
      color: 'var(--text-color-25)',
      fontSize: 8
    },
    inputValue: {
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }
  }))({ isMobile })

const ScanSummaryModalTitle = ({ isMobile, isNegativeFeedback, patientName, selectedScanId, setSelectedScanId }) => {
  const classes = useStyles({ isMobile })
  const { t } = useTranslation()

  const grinScans = useSelector(state => state.patientsReducer.patient?.grinScans?.items || [])

  const aggregatedScans = useMemo(() => getAggregateScans(grinScans), [grinScans])
  const scanIdToNumberDict = useMemo(() => generateScanNumberDict(aggregatedScans), [aggregatedScans])
  const scanIds = useMemo(() => Object.keys(scanIdToNumberDict), [scanIdToNumberDict])
  const scan = useMemo(() => grinScans.find(scan => scan.id === selectedScanId), [grinScans, selectedScanId])
  const scanTimestamp = useMemo(() => scan && moment(scan?.createdAt).format(TIME_FORMAT_6), [scan])
  const scanOptions = useMemo(
    () =>
      aggregatedScans.reduce(
        (options, currPairScan, scanIndex) => [
          ...options,
          ...currPairScan.map(scan => ({
            label: t('dialogs.scanSummary.title.scanSelectorOption', {
              number: scanIndex + 1,
              withAligner:
                currPairScan.length > 1
                  ? scan.withAligner
                    ? t('dialogs.scanSummary.title.withAligners')
                    : t('dialogs.scanSummary.title.withoutAligners')
                  : ''
            }),
            value: scan.id
          }))
        ],
        []
      ),
    [aggregatedScans, t]
  )
  const hasMultipleScans = useMemo(() => grinScans.length > 1, [grinScans])

  const handleShowNextScan = useCallback(() => {
    trackEvent('Scan summary modal - Navigate to next scan clicked')
    setSelectedScanId(getObjectByIndexCyclic(scanIds, scanIds.indexOf(selectedScanId) + 1))
  }, [scanIds, selectedScanId, setSelectedScanId])

  const handleShowPreviousScan = useCallback(() => {
    trackEvent('Scan summary modal - Navigate to previous scan clicked')
    setSelectedScanId(getObjectByIndexCyclic(scanIds, scanIds.indexOf(selectedScanId) - 1))
  }, [scanIds, selectedScanId, setSelectedScanId])

  return (
    (!isMobile || !isLandscape()) && (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item className={isMobile ? classes.mobileTitleContainer : ''}>
          <Grid container justifyContent="center" alignItems="center" wrap={isMobile ? 'nowrap' : 'wrap'} spacing={1}>
            {hasMultipleScans && !isNegativeFeedback && (
              <Grid item>
                <IconButton
                  size={isMobile ? 'small' : 'medium'}
                  className={classes.arrow}
                  onClick={handleShowPreviousScan}
                >
                  <ArrowBack fontSize="small" />
                </IconButton>
              </Grid>
            )}
            <Grid item>
              <DazzedHeading20 className={isMobile ? classes.mobileTitle : ''}>
                {!isMobile && isNegativeFeedback
                  ? t('dialogs.scanSummary.negativeFeedback.title')
                  : t('dialogs.scanSummary.title.label')}
              </DazzedHeading20>
            </Grid>
            {!isNegativeFeedback && (
              <Grid item>
                <SelectInput
                  style={{ bright: true }}
                  options={scanOptions}
                  value={selectedScanId}
                  onChange={option => setSelectedScanId(option.value)}
                  selectStyle={{ border: 'none', borderBottom: '1px solid var(--border-color-2)', width: 225 }}
                  valueClassName={classes.inputValue}
                  keepErrorContainerWhenInactive={false}
                />
              </Grid>
            )}
            {hasMultipleScans && !isNegativeFeedback && (
              <Grid item>
                <IconButton size={isMobile ? 'small' : 'medium'} className={classes.arrow} onClick={handleShowNextScan}>
                  <ArrowForward fontSize="small" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        {isMobile && (
          <Grid item>
            <DazzedHeading16 className={classes.patientName}>{patientName}</DazzedHeading16>
            <span className={classes.timestampMobile}>{scanTimestamp}</span>
          </Grid>
        )}
      </Grid>
    )
  )
}

export default ScanSummaryModalTitle
