// Action Types
const PATIENT_OPENED_UPDATE_RECEIVED = 'PATIENT_OPENED_UPDATE_RECEIVED'
const PATIENT_OPENED_UPDATE_REQUESTED = 'PATIENT_OPENED_UPDATE_REQUESTED'
const PATIENT_REQUESTED = 'PATIENT_REQUESTED'
const PATIENT_RECEIVED = 'PATIENT_RECEIVED'
const FETCH_PATIENT_FAILED = 'FETCH_PATIENT_FAILED'
const SEARCH_RESULTS_RECEIVED = 'SEARCH_RESULTS_RECEIVED'
const INVITE_NEW_PATIENT = 'INVITE_NEW_PATIENT'
const INVITE_NEW_PATIENT_RECEIVED = 'INVITE_NEW_PATIENT_RECEIVED'
const INVITE_NEW_PATIENT_FAILED = 'INVITE_NEW_PATIENT_FAILED'
const CLEAR_INVITE_MODAL_POPUP_PROPS = 'CLEAR_INVITE_MODAL_POPUP_PROPS'
const REQUEST_OPEN_INVITE_PATIENT_MODAL = 'REQUEST_OPEN_INVITE_PATIENT_MODAL'
const SET_INVITE_PATIENT_MODAL_VISIBILITY = 'SET_INVITE_PATIENT_MODAL_VISIBILITY'
const SHOW_RESEND_PATIENT_INVITATION_MODAL = 'SHOW_RESEND_PATIENT_INVITATION_MODAL'
const RESEND_PATIENT_INVITATION_REQUESTED = 'RESEND_PATIENT_INVITATION_REQUESTED'
const RESEND_PATIENT_INVITATION_RECEIVED = 'RESEND_PATIENT_INVITATION_RECEIVED'
const RESEND_PATIENT_INVITATION_FAILED = 'RESEND_PATIENT_INVITATION_FAILED'
const REQUEST_INVITATION_CODE_MODAL = 'REQUEST_INVITATION_CODE_MODAL'
const TOGGLE_INVITATION_CODE_MODAL = 'TOGGLE_INVITATION_CODE_MODAL'
const DELETE_PATIENT_INITIATED = 'DELETE_PATIENT_INITIATED'
const DELETE_PATIENT_CANCELED = 'DELETE_PATIENT_CANCELED'
const DELETE_PATIENT_REQUESTED = 'DELETE_PATIENT_REQUESTED'
const DELETE_PATIENT_RECEIVED = 'DELETE_PATIENT_RECEIVED'
const DELETE_PATIENT_FAILED = 'DELETE_PATIENT_FAILED'
const SEARCH_PATIENTS = 'SEARCH_PATIENTS'
const SEARCH_PATIENTS_RECEIVED = 'SEARCH_PATIENTS_RECEIVED'
const SERACH_PATIENTS_FAILED = 'SERACH_PATIENTS_FAILED'
const REQUEST_PATIENT_DETAILS_UPDATE = 'REQUEST_PATIENT_DETAILS_UPDATE'
const UPDATE_PATIENT_DETAILS_RECEIVED = 'UPDATE_PATIENT_DETAILS_RECEIVED'
const UPDATE_PATIENT_DETAILS_FAILED = 'UPDATE_PATIENT_DETAILS_FAILED'
const TOGGLE_PATIENT_CARD = 'TOGGLE_PATIENT_CARD'
const TOGGLE_MOBILE_PATIENT_CARD = 'TOGGLE_MOBILE_PATIENT_CARD'
const REQUEST_PATIENT_NOTES = 'REQUEST_PATIENT_NOTES'
const PATIENT_NOTES_RECEIVED = 'PATIENT_NOTES_RECEIVED'
const REQUEST_PATIENT_NOTES_FAILED = 'REQUEST_PATIENT_NOTES_FAILED'
const CREATE_PATIENT_NOTE = 'CREATE_PATIENT_NOTE'
const CREATE_PATIENT_NOTE_RECEIVED = 'CREATE_PATIENT_NOTE_RECEIVED'
const CREATE_PATIENT_NOTE_FAILED = 'CREATE_PATIENT_NOTE_FAILED'
const DELETE_PATIENT_NOTE = 'DELETE_PATIENT_NOTE'
const DELETE_PATIENT_NOTE_RECEIVED = 'DELETE_PATIENT_NOTE_RECEIVED'
const DELETE_PATIENT_NOTE_FAILED = 'DELETE_PATIENT_NOTE_FAILED'
const EDIT_PATIENT_NOTE = 'EDIT_PATIENT_NOTE'
const EDIT_PATIENT_NOTE_RECEIVED = 'EDIT_PATIENT_NOTE_RECEIVED'
const EDIT_PATIENT_NOTE_FAILED = 'EDIT_PATIENT_NOTE_FAILED'
const START_UPLOADING_PATIENT_INITIAL_SCAN_FILES = 'START_UPLOADING_PATIENT_INITIAL_SCAN_FILES'
const REQUEST_UPDATE_PATIENT_INITIAL_SCAN = 'REQUEST_UPDATE_PATIENT_INITIAL_SCAN'
const REQUEST_CREATE_PATIENT_INITIAL_SCAN = 'REQUEST_CREATE_PATIENT_INITIAL_SCAN'
const SAVE_PATIENT_INITIAL_SCAN_RECEIVED = 'SAVE_PATIENT_INITIAL_SCAN_RECEIVED'
const SAVE_PATIENT_INITIAL_SCAN_FAILED = 'SAVE_PATIENT_INITIAL_SCAN_FAILED'
const DELETE_PATIENT_INITIAL_SCAN_ITEM = 'DELETE_PATIENT_INITIAL_SCAN_ITEM'
const DOWNLOAD_PATIENT_FILES_ZIP = 'DOWNLOAD_PATIENT_FILES_ZIP'
const DOWNLOAD_PATIENT_FILES_ZIP_SUCCESS = 'DOWNLOAD_PATIENT_FILES_ZIP_SUCCESS'
const DOWNLOAD_PATIENT_FILES_ZIP_FAILED = 'DOWNLOAD_PATIENT_FILES_ZIP_FAILED'
const ASSIGN_PATIENT_TAG = 'ASSIGN_PATIENT_TAG'
const ASSIGN_PATIENT_TAG_RECEIVED = 'ASSIGN_PATIENT_TAG_RECEIVED'
const ASSIGN_PATIENT_TAG_FAILED = 'ASSIGN_PATIENT_TAG_FAILED'
const REMOVE_PATIENT_TAG = 'REMOVE_PATIENT_TAG'
const REMOVE_PATIENT_TAG_RECEIVED = 'REMOVE_PATIENT_TAG_RECEIVED'
const REMOVE_PATIENT_TAG_FAILED = 'REMOVE_PATIENT_TAG_FAILED'
const SHOULD_OPEN_SCAN_REVIEW = 'SHOULD_OPEN_SCAN_REVIEW'
const RESET_PATIENT = 'RESET_PATIENT'
const SET_TRANSFER_PATIENT_MODAL_VISIBILITY = 'SET_TRANSFER_PATIENT_MODAL_VISIBILITY'
const TRANSFER_PATIENT = 'TRANSFER_PATIENT'
const TRANSFER_PATIENT_RECEIVED = 'TRANSFER_PATIENT_RECEIVED'
const TRANSFER_PATIENT_FAILED = 'TRANSFER_PATIENT_FAILED'
const REQUEST_PATIENT_APPOINTMENTS = 'REQUEST_PATIENT_APPOINTMENTS'
const PATIENT_APPOINTMENTS_RECEIVED = 'PATIENT_APPOINTMENTS_RECEIVED'
const PATIENT_APPOINTMENTS_FAILED = 'PATIENT_APPOINTMENTS_FAILED'
const CREATE_PATIENT_APPOINTMENT = 'CREATE_PATIENT_APPOINTMENT'
const CREATE_PATIENT_APPOINTMENT_RECEIVED = 'CREATE_PATIENT_APPOINTMENT_RECEIVED'
const CREATE_PATIENT_APPOINTMENT_FAILED = 'CREATE_PATIENT_APPOINTMENT_FAILED'
const UPDATE_PATIENT_APPOINTMENT = 'UPDATE_PATIENT_APPOINTMENT'
const UPDATE_PATIENT_APPOINTMENT_RECEIVED = 'UPDATE_PATIENT_APPOINTMENT_RECEIVED'
const UPDATE_PATIENT_APPOINTMENT_FAILED = 'UPDATE_PATIENT_APPOINTMENT_FAILED'
const DELETE_PATIENT_APPOINTMENT = 'DELETE_PATIENT_APPOINTMENT'
const DELETE_PATIENT_APPOINTMENT_RECEIVED = 'DELETE_PATIENT_APPOINTMENT_RECEIVED'
const DELETE_PATIENT_APPOINTMENT_FAILED = 'DELETE_PATIENT_APPOINTMENT_FAILED'
const TOGGLE_UPLOAD_STL_FILES_MODAL = 'TOGGLE_UPLOAD_STL_FILES_MODAL'
const SET_SELECTED_RECORD = 'SET_SELECTED_RECORD'
const DELETE_RECORDS_SET = 'DELETE_RECORDS_SET'
const DELETE_RECORDS_SET_RECEIVED = 'DELETE_RECORDS_SET_RECEIVED'
const DELETE_RECORDS_SET_FAILED = 'DELETE_RECORDS_SET_FAILED'
const FETCH_PATIENTS_WITH_TAG = 'FETCH_PATIENTS_WITH_TAG'
const FETCH_PATIENTS_WITH_TAG_RECEIVED = 'FETCH_PATIENTS_WITH_TAG_RECEIVED'
const FETCH_PATIENTS_WITH_TAG_FAILED = 'FETCH_PATIENTS_WITH_TAG_FAILED'
const SEARCH_SIBLINGS_PATIENTS = 'SEARCH_SIBLINGS_PATIENTS'
const SEARCH_SIBLINGS_PATIENTS_RECEIVED = 'SEARCH_SIBLINGS_PATIENTS_RECEIVED'
const SEARCH_SIBLINGS_PATIENTS_FAILED = 'SEARCH_SIBLINGS_PATIENTS_FAILED'
const SEARCH_GUARDIAN = 'SEARCH_GUARDIAN'
const SEARCH_GUARDIAN_RECEIVED = 'SEARCH_GUARDIAN_RECEIVED'
const SEARCH_GUARDIAN_FAILED = 'SEARCH_GUARDIAN_FAILED'
const FETCH_LEAD_SCANS = 'FETCH_LEAD_SCANS'
const FETCH_LEAD_SCANS_RECEIVED = 'FETCH_LEAD_SCANS_RECEIVED'
const FETCH_LEAD_SCANS_FAILED = 'FETCH_LEAD_SCANS_FAILED'
const UPLOAD_BULK_INVITE_FORM = 'UPLOAD_BULK_INVITE_FORM'
const UPLOAD_BULK_INVITE_FORM_RECEIVED = 'UPLOAD_BULK_INVITE_FORM_RECEIVED'
const UPLOAD_BULK_INVITE_FORM_FAILED = 'UPLOAD_BULK_INVITE_FORM_FAILED'
const TOGGLE_BULK_INVITE_POPUP = 'TOGGLE_BULK_INVITE_POPUP'
const RESOLVE_SCAN_URGENCY = 'RESOLVE_SCAN_URGENCY'
const RESOLVE_SCAN_URGENCY_RECEIVED = 'RESOLVE_SCAN_URGENCY_RECEIVED'
const RESOLVE_SCAN_URGENCY_FAILED = 'RESOLVE_SCAN_URGENCY_FAILED'
const FETCH_PATIENT_SIBLINGS = 'FETCH_PATIENT_SIBLINGS'
const FETCH_PATIENT_SIBLINGS_RECEIVED = 'FETCH_PATIENT_SIBLINGS_RECEIVED'
const FETCH_PATIENT_SIBLINGS_FAILED = 'FETCH_PATIENT_SIBLINGS_FAILED'
const FETCH_PATIENT_SIBLINGS_FOR_RESEND = 'FETCH_PATIENT_SIBLINGS_FOR_RESEND'
const FETCH_PATIENT_SIBLINGS_FOR_RESEND_RECEIVED = 'FETCH_PATIENT_SIBLINGS_FOR_RESEND_RECEIVED'
const FETCH_PATIENT_SIBLINGS_FOR_RESEND_FAILED = 'FETCH_PATIENT_SIBLINGS_FOR_RESEND_FAILED'
const TOGGLE_PATIENT_BRIEF_MODAL = 'TOGGLE_PATIENT_BRIEF_MODAL'
const CREATE_PATIENT_BRIEF = 'CREATE_PATIENT_BRIEF'
const CREATE_PATIENT_BRIEF_RECEIVED = 'CREATE_PATIENT_BRIEF_RECEIVED'
const CREATE_PATIENT_BRIEF_FAILED = 'CREATE_PATIENT_BRIEF_FAILED'
const FETCH_PATIENT_BRIEF = 'FETCH_PATIENT_BRIEF'
const FETCH_PATIENT_BRIEF_RECEIVED = 'FETCH_PATIENT_BRIEF_RECEIVED'
const FETCH_PATIENT_BRIEF_FAILED = 'FETCH_PATIENT_BRIEF_FAILED'
const FETCH_PATIENT_NOTES = 'FETCH_PATIENT_NOTES'
const FETCH_PATIENT_NOTES_RECEIVED = 'FETCH_PATIENT_NOTES_RECEIVED'
const FETCH_PATIENT_NOTES_FAILED = 'FETCH_PATIENT_NOTES_FAILED'

const fetchPatientNotes = payload => ({
  type: FETCH_PATIENT_NOTES,
  payload
})

const fetchPatientNotesReceived = payload => ({
  type: FETCH_PATIENT_NOTES_RECEIVED,
  payload
})

const fetchPatientNotesFailed = payload => ({
  type: FETCH_PATIENT_NOTES_FAILED,
  payload
})

const fetchPatientBrief = payload => ({
  type: FETCH_PATIENT_BRIEF,
  payload
})

const fetchPatientBriefReceived = payload => ({
  type: FETCH_PATIENT_BRIEF_RECEIVED,
  payload
})

const fetchPatientBriefFailed = payload => ({
  type: FETCH_PATIENT_BRIEF_FAILED,
  payload
})

const fetchPatientSiblingsForResend = payload => ({
  type: FETCH_PATIENT_SIBLINGS_FOR_RESEND,
  payload
})

const fetchPatientSiblingsForResendReceived = payload => ({
  type: FETCH_PATIENT_SIBLINGS_FOR_RESEND_RECEIVED,
  payload
})

const fetchPatientSiblingsForResendFailed = payload => ({
  type: FETCH_PATIENT_SIBLINGS_FOR_RESEND_FAILED,
  payload
})
const SET_INVITE_PATIENT_PRESET_DETAILS = 'SET_INVITE_PATIENT_PRESET_DETAILS'
const SET_PATIENTS_PRESET_FILTER = 'SET_PATIENTS_PRESET_FILTER'

const setInvitePatientPresetDetails = payload => ({
  type: SET_INVITE_PATIENT_PRESET_DETAILS,
  payload
})

const resolveScanUrgency = payload => ({
  type: RESOLVE_SCAN_URGENCY,
  payload
})

const resolveScanUrgencyReceived = payload => ({
  type: RESOLVE_SCAN_URGENCY_RECEIVED,
  payload
})

const resolveScanUrgencyFailed = payload => ({
  type: RESOLVE_SCAN_URGENCY_FAILED,
  payload
})

// Action Creators
const requestUpdateOpenedPatientReceived = patient => ({
  type: PATIENT_OPENED_UPDATE_RECEIVED,
  payload: patient
})

const requestUpdateOpenedPatient = patient => ({
  type: PATIENT_OPENED_UPDATE_REQUESTED,
  payload: patient
})

const requestPatient = ({ patientId }) => ({
  type: PATIENT_REQUESTED,
  payload: { patientId }
})

const patientReceived = ({ patient }) => ({
  type: PATIENT_RECEIVED,
  payload: {
    patient
  }
})

const fetchPatientFailed = payload => ({
  type: FETCH_PATIENT_FAILED,
  payload
})

const searchResultsReceived = payload => ({
  type: SEARCH_RESULTS_RECEIVED,
  payload
})

const inviteNewPatient = payload => ({
  type: INVITE_NEW_PATIENT,
  payload
})

const inviteNewPatientReceived = payload => ({
  type: INVITE_NEW_PATIENT_RECEIVED,
  payload
})

const inviteNewPatientFailed = payload => ({
  type: INVITE_NEW_PATIENT_FAILED,
  payload
})

const setInvitePatientModalVisibility = payload => ({
  type: SET_INVITE_PATIENT_MODAL_VISIBILITY,
  payload
})

const showResendPatientInvitationModal = payload => ({
  type: SHOW_RESEND_PATIENT_INVITATION_MODAL,
  payload
})

const resendPatientInvitation = payload => ({
  type: RESEND_PATIENT_INVITATION_REQUESTED,
  payload
})

const resendPatientInvitationReceived = payload => ({
  type: RESEND_PATIENT_INVITATION_RECEIVED,
  payload
})

const resendPatientInvitationFailed = payload => ({
  type: RESEND_PATIENT_INVITATION_FAILED,
  payload
})

const openInvitationCodeModal = payload => ({
  type: REQUEST_INVITATION_CODE_MODAL,
  payload
})

const toggleInvitationCodeModal = payload => ({
  type: TOGGLE_INVITATION_CODE_MODAL,
  payload
})

const deletePatientInitiated = payload => ({
  type: DELETE_PATIENT_INITIATED,
  payload
})

const deletePatientRequested = payload => ({
  type: DELETE_PATIENT_REQUESTED,
  payload
})

const deletePatientCanceled = payload => ({
  type: DELETE_PATIENT_CANCELED,
  payload
})

const deletePatientReceived = payload => ({
  type: DELETE_PATIENT_RECEIVED,
  payload
})

const deletePatientFailed = payload => ({
  type: DELETE_PATIENT_FAILED,
  payload
})

const searchPatients = payload => ({
  type: SEARCH_PATIENTS,
  payload
})

const searchPatientsReceived = payload => ({
  type: SEARCH_PATIENTS_RECEIVED,
  payload
})

const searchPatientsFailed = payload => ({
  type: SERACH_PATIENTS_FAILED,
  payload
})

const searchSiblingPatients = payload => ({
  type: SEARCH_SIBLINGS_PATIENTS,
  payload
})

const searchSiblingPatientsReceived = payload => ({
  type: SEARCH_SIBLINGS_PATIENTS_RECEIVED,
  payload
})

const searchSiblingPatientsFailed = payload => ({
  type: SEARCH_SIBLINGS_PATIENTS_FAILED,
  payload
})

const searchGuardian = payload => ({
  type: SEARCH_GUARDIAN,
  payload
})

const searchGuardianReceived = payload => ({
  type: SEARCH_GUARDIAN_RECEIVED,
  payload
})

const searchGuardianFailed = payload => ({
  type: SEARCH_GUARDIAN_FAILED,
  payload
})

const requestOpenInvitePatientModal = payload => ({
  type: REQUEST_OPEN_INVITE_PATIENT_MODAL,
  payload
})

const requestPatientDetailsUpdate = payload => ({
  type: REQUEST_PATIENT_DETAILS_UPDATE,
  payload
})

const updatePatientDetailsReceived = payload => ({
  type: UPDATE_PATIENT_DETAILS_RECEIVED,
  payload
})

const updatePatientDetailsFailed = payload => ({
  type: UPDATE_PATIENT_DETAILS_FAILED,
  payload
})

const togglePatientCard = payload => ({
  type: TOGGLE_PATIENT_CARD,
  payload
})

const toggleMobilePatientCard = payload => ({
  type: TOGGLE_MOBILE_PATIENT_CARD,
  payload
})

const requestPatientNotes = payload => ({
  type: REQUEST_PATIENT_NOTES,
  payload
})

const patientNotesReceived = payload => ({
  type: PATIENT_NOTES_RECEIVED,
  payload
})

const requestPatientNotesFailed = () => ({
  type: REQUEST_PATIENT_NOTES_FAILED
})

const createPatientNote = payload => ({
  type: CREATE_PATIENT_NOTE,
  payload
})

const createPatientNoteReceived = payload => ({
  type: CREATE_PATIENT_NOTE_RECEIVED,
  payload
})

const createPatientNoteFailed = () => ({
  type: CREATE_PATIENT_NOTE_FAILED
})

const deletePatientNote = payload => ({
  type: DELETE_PATIENT_NOTE,
  payload
})

const deletePatientNoteReceived = payload => ({
  type: DELETE_PATIENT_NOTE_RECEIVED,
  payload
})

const deletePatientNoteFailed = () => ({
  type: DELETE_PATIENT_NOTE_FAILED
})

const editPatientNote = payload => ({
  type: EDIT_PATIENT_NOTE,
  payload
})

const editPatientNoteReceived = payload => ({
  type: EDIT_PATIENT_NOTE_RECEIVED,
  payload
})

const editPatientNoteFailed = () => ({
  type: EDIT_PATIENT_NOTE_FAILED
})

const startUploadingPatientInitialScanFiles = payload => ({
  type: START_UPLOADING_PATIENT_INITIAL_SCAN_FILES,
  payload
})

const requestUpdatePatientInitialScan = payload => ({
  type: REQUEST_UPDATE_PATIENT_INITIAL_SCAN,
  payload
})

const requestCreatePatientInitialScan = payload => ({
  type: REQUEST_CREATE_PATIENT_INITIAL_SCAN,
  payload
})

const savePatientInitialScanReceived = payload => ({
  type: SAVE_PATIENT_INITIAL_SCAN_RECEIVED,
  payload
})

const savePatientInitialScanFailed = () => ({
  type: SAVE_PATIENT_INITIAL_SCAN_FAILED
})
const deletePatientInitialScanItem = payload => ({
  type: DELETE_PATIENT_INITIAL_SCAN_ITEM,
  payload
})

const downloadPatientFilesZip = payload => ({
  type: DOWNLOAD_PATIENT_FILES_ZIP,
  payload
})

const downloadPatientFilesZipSuccess = payload => ({
  type: DOWNLOAD_PATIENT_FILES_ZIP_SUCCESS,
  payload
})

const downloadPatientFilesZipFailed = payload => ({
  type: DOWNLOAD_PATIENT_FILES_ZIP_FAILED,
  payload
})

const assignPatientTag = payload => ({
  type: ASSIGN_PATIENT_TAG,
  payload
})

const assignPatientTagReceived = payload => ({
  type: ASSIGN_PATIENT_TAG_RECEIVED,
  payload
})

const assignPatientTagFailed = payload => ({
  type: ASSIGN_PATIENT_TAG_FAILED,
  payload
})

const removePatientTag = payload => ({
  type: REMOVE_PATIENT_TAG,
  payload
})

const removePatientTagReceived = payload => ({
  type: REMOVE_PATIENT_TAG_RECEIVED,
  payload
})

const removePatientTagFailed = payload => ({
  type: REMOVE_PATIENT_TAG_FAILED,
  payload
})

const setShouldOpenScanReview = payload => ({
  type: SHOULD_OPEN_SCAN_REVIEW,
  payload
})

const resetPatient = () => ({
  type: RESET_PATIENT
})

const setTransferPatientModalVisibility = payload => ({
  type: SET_TRANSFER_PATIENT_MODAL_VISIBILITY,
  payload
})

const transferPatient = payload => ({
  type: TRANSFER_PATIENT,
  payload
})

const transferPatientReceived = payload => ({
  type: TRANSFER_PATIENT_RECEIVED,
  payload
})

const transferPatientFailed = payload => ({
  type: TRANSFER_PATIENT_FAILED,
  payload
})

const clearInviteModalPopupProps = payload => ({
  type: CLEAR_INVITE_MODAL_POPUP_PROPS,
  payload
})

const requestPatientAppointments = payload => ({
  type: REQUEST_PATIENT_APPOINTMENTS,
  payload
})

const patientAppointmentsReceived = payload => ({
  type: PATIENT_APPOINTMENTS_RECEIVED,
  payload
})

const patientAppointmentsFailed = () => ({
  type: PATIENT_APPOINTMENTS_FAILED
})

const createPatientAppointment = payload => ({
  type: CREATE_PATIENT_APPOINTMENT,
  payload
})

const createPatientAppointmentReceived = payload => ({
  type: CREATE_PATIENT_APPOINTMENT_RECEIVED,
  payload
})

const createPatientAppointmentFailed = () => ({
  type: CREATE_PATIENT_APPOINTMENT_FAILED
})

const updatePatientAppointment = payload => ({
  type: UPDATE_PATIENT_APPOINTMENT,
  payload
})

const updatePatientAppointmentReceived = payload => ({
  type: UPDATE_PATIENT_APPOINTMENT_RECEIVED,
  payload
})

const updatePatientAppointmentFailed = () => ({
  type: UPDATE_PATIENT_APPOINTMENT_FAILED
})

const deletePatientAppointment = payload => ({
  type: DELETE_PATIENT_APPOINTMENT,
  payload
})

const deletePatientAppointmentReceived = payload => ({
  type: DELETE_PATIENT_APPOINTMENT_RECEIVED,
  payload
})

const deletePatientAppointmentFailed = () => ({
  type: DELETE_PATIENT_APPOINTMENT_FAILED
})

const toggleUploadStlFilesModal = payload => ({
  type: TOGGLE_UPLOAD_STL_FILES_MODAL,
  payload
})

const setSelectedRecord = payload => ({
  type: SET_SELECTED_RECORD,
  payload
})

const deleteRecordsSet = payload => ({
  type: DELETE_RECORDS_SET,
  payload
})

const deleteRecordsSetReceived = payload => ({
  type: DELETE_RECORDS_SET_RECEIVED,
  payload
})

const deleteRecordsSetFailed = payload => ({
  type: DELETE_RECORDS_SET_FAILED,
  payload
})

const fetchPatientsWithTag = payload => ({
  type: FETCH_PATIENTS_WITH_TAG,
  payload
})

const fetchPatientsWithTagReceived = payload => ({
  type: FETCH_PATIENTS_WITH_TAG_RECEIVED,
  payload
})

const fetchPatientsWithTagFailed = payload => ({
  type: FETCH_PATIENTS_WITH_TAG_FAILED,
  payload
})

const fetchLeadScans = payload => ({
  type: FETCH_LEAD_SCANS,
  payload
})

const fetchLeadScansReceived = payload => ({
  type: FETCH_LEAD_SCANS_RECEIVED,
  payload
})

const fetchLeadScansFailed = payload => ({
  type: FETCH_LEAD_SCANS_FAILED,
  payload
})

const uploadBulkInviteForm = payload => ({
  type: UPLOAD_BULK_INVITE_FORM,
  payload
})

const uploadBulkInviteFormReceived = payload => ({
  type: UPLOAD_BULK_INVITE_FORM_RECEIVED,
  payload
})

const uploadBulkInviteFormFailed = payload => ({
  type: UPLOAD_BULK_INVITE_FORM_FAILED,
  payload
})

const toggleBulkInvitePopup = payload => ({
  type: TOGGLE_BULK_INVITE_POPUP,
  payload
})

const fetchPatientSiblings = payload => ({
  type: FETCH_PATIENT_SIBLINGS,
  payload
})

const fetchPatientSiblingsReceived = payload => ({
  type: FETCH_PATIENT_SIBLINGS_RECEIVED,
  payload
})

const fetchPatientSiblingsFailed = payload => ({
  type: FETCH_PATIENT_SIBLINGS_FAILED,
  payload
})

const setPatientsPresetFilter = payload => ({
  type: SET_PATIENTS_PRESET_FILTER,
  payload
})

const togglePatientBriefModal = payload => ({
  type: TOGGLE_PATIENT_BRIEF_MODAL,
  payload
})

const createPatientBrief = payload => ({
  type: CREATE_PATIENT_BRIEF,
  payload
})

const createPatientBriefReceived = payload => ({
  type: CREATE_PATIENT_BRIEF_RECEIVED,
  payload
})

const createPatientBriefFailed = payload => ({
  type: CREATE_PATIENT_BRIEF_FAILED,
  payload
})

export default {
  PATIENT_REQUESTED,
  PATIENT_RECEIVED,
  FETCH_PATIENT_FAILED,
  SEARCH_RESULTS_RECEIVED,
  REQUEST_OPEN_INVITE_PATIENT_MODAL,
  INVITE_NEW_PATIENT,
  INVITE_NEW_PATIENT_RECEIVED,
  INVITE_NEW_PATIENT_FAILED,
  SET_INVITE_PATIENT_MODAL_VISIBILITY,
  SHOW_RESEND_PATIENT_INVITATION_MODAL,
  RESEND_PATIENT_INVITATION_REQUESTED,
  RESEND_PATIENT_INVITATION_RECEIVED,
  RESEND_PATIENT_INVITATION_FAILED,
  REQUEST_INVITATION_CODE_MODAL,
  TOGGLE_INVITATION_CODE_MODAL,
  DELETE_PATIENT_INITIATED,
  DELETE_PATIENT_CANCELED,
  DELETE_PATIENT_REQUESTED,
  DELETE_PATIENT_RECEIVED,
  DELETE_PATIENT_FAILED,
  SEARCH_PATIENTS,
  SEARCH_PATIENTS_RECEIVED,
  SERACH_PATIENTS_FAILED,
  PATIENT_OPENED_UPDATE_REQUESTED,
  PATIENT_OPENED_UPDATE_RECEIVED,
  REQUEST_PATIENT_DETAILS_UPDATE,
  UPDATE_PATIENT_DETAILS_RECEIVED,
  UPDATE_PATIENT_DETAILS_FAILED,
  TOGGLE_PATIENT_CARD,
  TOGGLE_MOBILE_PATIENT_CARD,
  REQUEST_PATIENT_NOTES,
  PATIENT_NOTES_RECEIVED,
  REQUEST_PATIENT_NOTES_FAILED,
  CREATE_PATIENT_NOTE,
  CREATE_PATIENT_NOTE_RECEIVED,
  CREATE_PATIENT_NOTE_FAILED,
  DELETE_PATIENT_NOTE,
  DELETE_PATIENT_NOTE_RECEIVED,
  DELETE_PATIENT_NOTE_FAILED,
  EDIT_PATIENT_NOTE,
  EDIT_PATIENT_NOTE_RECEIVED,
  EDIT_PATIENT_NOTE_FAILED,
  START_UPLOADING_PATIENT_INITIAL_SCAN_FILES,
  REQUEST_UPDATE_PATIENT_INITIAL_SCAN,
  REQUEST_CREATE_PATIENT_INITIAL_SCAN,
  SAVE_PATIENT_INITIAL_SCAN_RECEIVED,
  SAVE_PATIENT_INITIAL_SCAN_FAILED,
  DOWNLOAD_PATIENT_FILES_ZIP,
  DOWNLOAD_PATIENT_FILES_ZIP_SUCCESS,
  DOWNLOAD_PATIENT_FILES_ZIP_FAILED,
  DELETE_PATIENT_INITIAL_SCAN_ITEM,
  ASSIGN_PATIENT_TAG,
  ASSIGN_PATIENT_TAG_RECEIVED,
  ASSIGN_PATIENT_TAG_FAILED,
  REMOVE_PATIENT_TAG,
  REMOVE_PATIENT_TAG_RECEIVED,
  REMOVE_PATIENT_TAG_FAILED,
  SHOULD_OPEN_SCAN_REVIEW,
  RESET_PATIENT,
  SET_TRANSFER_PATIENT_MODAL_VISIBILITY,
  TRANSFER_PATIENT,
  TRANSFER_PATIENT_RECEIVED,
  TRANSFER_PATIENT_FAILED,
  CLEAR_INVITE_MODAL_POPUP_PROPS,
  REQUEST_PATIENT_APPOINTMENTS,
  PATIENT_APPOINTMENTS_RECEIVED,
  PATIENT_APPOINTMENTS_FAILED,
  CREATE_PATIENT_APPOINTMENT,
  CREATE_PATIENT_APPOINTMENT_RECEIVED,
  CREATE_PATIENT_APPOINTMENT_FAILED,
  UPDATE_PATIENT_APPOINTMENT,
  UPDATE_PATIENT_APPOINTMENT_RECEIVED,
  UPDATE_PATIENT_APPOINTMENT_FAILED,
  DELETE_PATIENT_APPOINTMENT,
  DELETE_PATIENT_APPOINTMENT_RECEIVED,
  DELETE_PATIENT_APPOINTMENT_FAILED,
  TOGGLE_UPLOAD_STL_FILES_MODAL,
  SET_SELECTED_RECORD,
  DELETE_RECORDS_SET,
  DELETE_RECORDS_SET_RECEIVED,
  DELETE_RECORDS_SET_FAILED,
  FETCH_PATIENTS_WITH_TAG,
  FETCH_PATIENTS_WITH_TAG_FAILED,
  FETCH_PATIENTS_WITH_TAG_RECEIVED,
  SEARCH_SIBLINGS_PATIENTS,
  SEARCH_SIBLINGS_PATIENTS_RECEIVED,
  SEARCH_SIBLINGS_PATIENTS_FAILED,
  SEARCH_GUARDIAN,
  SEARCH_GUARDIAN_RECEIVED,
  SEARCH_GUARDIAN_FAILED,
  FETCH_LEAD_SCANS,
  FETCH_LEAD_SCANS_RECEIVED,
  FETCH_LEAD_SCANS_FAILED,
  UPLOAD_BULK_INVITE_FORM,
  UPLOAD_BULK_INVITE_FORM_FAILED,
  UPLOAD_BULK_INVITE_FORM_RECEIVED,
  TOGGLE_BULK_INVITE_POPUP,
  RESOLVE_SCAN_URGENCY,
  RESOLVE_SCAN_URGENCY_RECEIVED,
  RESOLVE_SCAN_URGENCY_FAILED,
  FETCH_PATIENT_SIBLINGS,
  FETCH_PATIENT_SIBLINGS_FAILED,
  FETCH_PATIENT_SIBLINGS_RECEIVED,
  FETCH_PATIENT_SIBLINGS_FOR_RESEND,
  FETCH_PATIENT_SIBLINGS_FOR_RESEND_RECEIVED,
  FETCH_PATIENT_SIBLINGS_FOR_RESEND_FAILED,
  SET_INVITE_PATIENT_PRESET_DETAILS,
  SET_PATIENTS_PRESET_FILTER,
  TOGGLE_PATIENT_BRIEF_MODAL,
  CREATE_PATIENT_BRIEF,
  CREATE_PATIENT_BRIEF_RECEIVED,
  CREATE_PATIENT_BRIEF_FAILED,
  FETCH_PATIENT_BRIEF,
  FETCH_PATIENT_BRIEF_RECEIVED,
  FETCH_PATIENT_BRIEF_FAILED,
  FETCH_PATIENT_NOTES,
  FETCH_PATIENT_NOTES_RECEIVED,
  FETCH_PATIENT_NOTES_FAILED,
  fetchPatientNotes,
  fetchPatientNotesReceived,
  fetchPatientNotesFailed,
  fetchPatientBrief,
  fetchPatientBriefReceived,
  fetchPatientBriefFailed,
  setPatientsPresetFilter,
  setInvitePatientPresetDetails,
  fetchPatientSiblingsForResend,
  fetchPatientSiblingsForResendReceived,
  fetchPatientSiblingsForResendFailed,
  resolveScanUrgency,
  resolveScanUrgencyReceived,
  resolveScanUrgencyFailed,
  fetchLeadScans,
  fetchLeadScansReceived,
  fetchLeadScansFailed,
  requestPatient,
  patientReceived,
  fetchPatientFailed,
  searchResultsReceived,
  inviteNewPatient,
  inviteNewPatientReceived,
  inviteNewPatientFailed,
  showResendPatientInvitationModal,
  resendPatientInvitation,
  resendPatientInvitationReceived,
  resendPatientInvitationFailed,
  setInvitePatientModalVisibility,
  openInvitationCodeModal,
  toggleInvitationCodeModal,
  deletePatientRequested,
  deletePatientInitiated,
  deletePatientCanceled,
  deletePatientReceived,
  deletePatientFailed,
  searchPatients,
  searchPatientsReceived,
  searchPatientsFailed,
  requestUpdateOpenedPatient,
  requestUpdateOpenedPatientReceived,
  requestOpenInvitePatientModal,
  requestPatientDetailsUpdate,
  updatePatientDetailsReceived,
  updatePatientDetailsFailed,
  togglePatientCard,
  toggleMobilePatientCard,
  requestPatientNotes,
  patientNotesReceived,
  requestPatientNotesFailed,
  createPatientNote,
  createPatientNoteReceived,
  createPatientNoteFailed,
  deletePatientNote,
  deletePatientNoteReceived,
  deletePatientNoteFailed,
  editPatientNote,
  editPatientNoteReceived,
  editPatientNoteFailed,
  startUploadingPatientInitialScanFiles,
  requestUpdatePatientInitialScan,
  requestCreatePatientInitialScan,
  savePatientInitialScanReceived,
  savePatientInitialScanFailed,
  deletePatientInitialScanItem,
  downloadPatientFilesZip,
  downloadPatientFilesZipSuccess,
  downloadPatientFilesZipFailed,
  assignPatientTag,
  assignPatientTagFailed,
  assignPatientTagReceived,
  removePatientTag,
  removePatientTagFailed,
  removePatientTagReceived,
  setShouldOpenScanReview,
  resetPatient,
  setTransferPatientModalVisibility,
  transferPatient,
  transferPatientReceived,
  transferPatientFailed,
  clearInviteModalPopupProps,
  requestPatientAppointments,
  patientAppointmentsReceived,
  patientAppointmentsFailed,
  createPatientAppointment,
  createPatientAppointmentReceived,
  createPatientAppointmentFailed,
  updatePatientAppointment,
  updatePatientAppointmentReceived,
  updatePatientAppointmentFailed,
  deletePatientAppointment,
  deletePatientAppointmentReceived,
  deletePatientAppointmentFailed,
  toggleUploadStlFilesModal,
  setSelectedRecord,
  deleteRecordsSet,
  deleteRecordsSetReceived,
  deleteRecordsSetFailed,
  fetchPatientsWithTag,
  fetchPatientsWithTagReceived,
  fetchPatientsWithTagFailed,
  searchSiblingPatients,
  searchSiblingPatientsReceived,
  searchSiblingPatientsFailed,
  searchGuardian,
  searchGuardianReceived,
  searchGuardianFailed,
  uploadBulkInviteForm,
  uploadBulkInviteFormFailed,
  uploadBulkInviteFormReceived,
  toggleBulkInvitePopup,
  fetchPatientSiblings,
  fetchPatientSiblingsFailed,
  fetchPatientSiblingsReceived,
  togglePatientBriefModal,
  createPatientBrief,
  createPatientBriefReceived,
  createPatientBriefFailed
}
