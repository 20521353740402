import React, { useState, useCallback, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import GrinLabel from './GrinLabel'
import { Pencil } from '../icons'
import { windInputCursor } from 'utils/inputUtils'

const useStyles = ({ editMode, editable, allTextClickable, minInputWith }) =>
  makeStyles({
    сontainer: {
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      maxWidth: '100%',
      wordBreak: 'break-word',
      position: 'relative',
      paddingRight: 20,
      '&:hover > svg': {
        display: editMode || !editable ? 'none' : 'block'
      },
      cursor: allTextClickable ? 'pointer' : 'auto',
      lineHeight: '20px',
      fontFamily: 'Dazzed',
      fontSize: 16,
      fontWeight: 'normal',
      letterSpacing: 'normal'
    },
    title: {
      marginRight: 5,
      marginBottom: -2,
      height: 22,
      display: 'flex',
      alignItems: 'center'
    },
    value: {
      opacity: 1,
      textTransform: 'none',
      marginBottom: 0,
      outline: 'none',
      borderBottom: `1px dashed ${editMode ? 'var(--border-color-9)' : 'transparent'}`,
      maxWidth: '100%',
      minWidth: editMode ? minInputWith : 0,
      textOverflow: editMode ? 'clip' : 'ellipsis',
      overflow: 'hidden',
      overflowX: editMode ? 'auto' : 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    pencil: {
      position: 'absolute',
      right: 0,
      bottom: '50%',
      transform: 'translateY(50%)',
      cursor: 'pointer',
      display: 'none'
    },
    icon: {
      marginRight: 10
    },
    errorMessage: {
      color: 'var(--text-color-17)',
      fontWeight: 500,
      fontSize: '12px'
    }
  })({ editMode, editable, allTextClickable, minInputWith })

const EditableLabel = ({
  value = '',
  title = '',
  editable = true,
  iconComponent,
  className,
  contentClassName = '',
  handleSave,
  fieldName,
  allTextClickable = false,
  allowSetEmptyValue = false,
  placeholder,
  validationRegex,
  errorMessage = '',
  valuePrefix,
  minInputWith = 20
}) => {
  const inputRef = useRef(null)
  const [editMode, setEditMode] = useState(false)
  const [error, setError] = useState(false)

  const classes = useStyles({ editMode, editable, allTextClickable, minInputWith })

  useEffect(() => {
    if (editMode && editable && inputRef.current) {
      inputRef.current.focus()
      inputRef.current.scrollLeft = inputRef.current.scrollWidth
    }
  }, [editMode, editable])

  const handleClickEdit = useCallback(() => {
    setEditMode(true)
    if (valuePrefix && !value) {
      inputRef.current.textContent = valuePrefix
    }
  }, [value, valuePrefix])

  const validateInput = useCallback(
    input => {
      return validationRegex ? new RegExp(validationRegex).test(input) : true
    },
    [validationRegex]
  )

  const handleBlurAndSave = useCallback(() => {
    const updatedValue = inputRef.current.textContent.trim()
    if (!validateInput(updatedValue)) {
      if (!error) {
        setError(true)
        inputRef.current.focus()
      } else {
        inputRef.current.textContent = value
        setEditMode(false)
        setError(false)
      }
      return
    }

    if (!updatedValue && !allowSetEmptyValue) {
      inputRef.current.textContent = value
      return
    }

    if (updatedValue === valuePrefix) {
      inputRef.current.textContent = ''
      if (updatedValue.replace(valuePrefix, '') !== value) {
        handleSave({ [fieldName]: '' })
      }
    } else if (updatedValue !== value) {
      handleSave({ [fieldName]: updatedValue })
    }
    setError(false)
    setEditMode(false)
    inputRef.current.scrollLeft = 0
  }, [allowSetEmptyValue, error, fieldName, handleSave, validateInput, value, valuePrefix])

  const onKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleBlurAndSave()
      }
    },
    [handleBlurAndSave]
  )

  return (
    <>
      <div
        className={classNames(classes.сontainer, className)}
        onClick={allTextClickable && editable ? handleClickEdit : null}
      >
        {iconComponent ? (
          <div className={classes.icon}>{iconComponent}</div>
        ) : (
          title && <GrinLabel className={classes.title}>{title}</GrinLabel>
        )}
        <div
          ref={inputRef}
          className={classNames(classes.value, contentClassName)}
          contentEditable={editMode}
          suppressContentEditableWarning={editMode}
          onBlur={handleBlurAndSave}
          onFocus={windInputCursor}
          onKeyPress={onKeyPress}
        >
          {value}
        </div>
        {!value && !editMode && <div className={classes.value}>{placeholder}</div>}
        <Pencil className={classes.pencil} onClick={handleClickEdit} />
      </div>
      {error && <div className={classes.errorMessage}>{errorMessage}</div>}
    </>
  )
}

export default EditableLabel
