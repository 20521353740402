import React, { useMemo, useState, useCallback } from 'react'
import SearchIcon from 'components/common/icons/Search'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedHeading14 from 'components/common/text/DazzedHeading14'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Grid, ButtonBase, CircularProgress } from '@material-ui/core'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import CheckBoxFilter from './CheckBoxFilter'
import CloseIcon from 'components/common/icons/Close'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { AsyncStatus } from 'consts'

const useStyles = makeStyles(theme => ({
  container: {
    height: 390
  },
  section: {
    padding: 20,
    overflow: 'hidden',
    '@media (max-width: 1350px)': {
      padding: 15
    }
  },
  section2: {
    height: '100%',
    backgroundColor: 'var(--bg-color-19)',
    borderRadius: 10
  },
  leftSectionContainer: {
    width: 300,
    height: '100%',
    '@media (max-width: 1350px)': {
      width: 270
    },
    '@media (max-width: 1150px)': {
      width: 250
    }
  },
  rightSectionColumn: {
    width: 230,
    '@media (max-width: 1350px)': {
      width: 200,
      paddingLeft: 5,
      paddingRight: 5
    },
    '@media (max-width: 1150px)': {
      width: 180
    }
  },
  title: {
    paddingBottom: 10,
    borderBottom: '1px solid var(--border-color-14)',
    marginBottom: 15
  },
  selectedCustomTag: {
    padding: '10px 5px 10px 5px ',
    color: 'var(--primary-color)',
    display: 'flex',
    margin: '3px 0',
    fontSize: '1.1em',
    borderRadius: 5,
    height: 30,
    alignItems: 'center'
  },
  buttons: {
    marginRight: 15,
    marginBottom: 15
  },
  submitButton: {
    marginLeft: 15
  },
  containerWithButtons: {
    height: '100%'
  },
  customTagsInnerContainer: {
    height: '100%',
    flexWrap: 'nowrap',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '2px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'var(--bg-color-45)',
      borderRadius: '70px'
    }
  },
  customTagsFilterContainer: {
    border: '1px solid var(--border-color-12)',
    paddingLeft: 5,
    borderRadius: 100,
    height: 40,
    '&:focus': {
      outline: 'none'
    }
  },
  customTagsFilter: {
    fontSize: 14,
    border: 0,
    fontFamily: 'Dazzed',
    fontWeight: 400,
    width: '95%',
    lineHeight: '24px',
    outline: 'none',
    '&:placeholder': {
      color: 'var(--text-color-2)'
    }
  },
  customTagsEmptyState: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 175
  },
  customTagsEmptyStateText: {
    overflow: 'hidden',
    height: '100%',
    textOverflow: 'ellipsis',
    color: 'var(--text-color-11)'
  },
  fullContainer: {
    height: '100%'
  },
  filterItem: {
    height: '23%',
    maxWidth: '100%'
  },
  checkboxesItem: {
    height: '77%'
  },
  assignee: {
    maxHeight: 185,
    paddingRight: 5,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '2px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'var(--bg-color-45)',
      borderRadius: '70px'
    }
  }
}))

const FilterMenu = ({
  customTags = [],
  programs = [],
  treatments = [],
  statuses = [],
  assigneTags = [],
  scanTags = [],
  selectedFilters = [],
  onFilterSelected = () => {},
  onApply = () => {},
  onClearAll = () => {},
  onTagsFilterChanged = () => {},
  hidePrograms,
  customTagsLoadingState
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const parsedSelectedFilters = useMemo(() => {
    let parsedFilters = []
    Object.keys(selectedFilters).forEach(filter => {
      parsedFilters[filter] = selectedFilters[filter].map(currFilter => ({
        value: currFilter.value,
        label: currFilter.label
      }))
    })

    return parsedFilters
  }, [selectedFilters])

  const hasSelectedTags = useMemo(
    () => Object.keys(selectedFilters).find(tagType => !!selectedFilters[tagType].length),
    [selectedFilters]
  )

  const doctorId = useSelector(state => state.profileReducer.doctor.id)

  const [customTagsFilter, setCustomTagsFilter] = useState('')

  const handleCustomTagsFilterChanged = useCallback(
    filter => {
      setCustomTagsFilter(filter)
      onTagsFilterChanged(filter)
    },
    [setCustomTagsFilter, onTagsFilterChanged]
  )

  return (
    <Grid container className={classes.container}>
      <Grid item className={`${classes.section} ${classes.leftSectionContainer}`}>
        <Grid container direction="column" className={classes.fullContainer}>
          <Grid item className={classes.filterItem}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <DazzedHeading14>{t('pages.patients.patientsList.patientsTagFilter.tagsTitle')}</DazzedHeading14>
              </Grid>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.customTagsFilterContainer}
                >
                  <Grid item xs={2}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <SearchIcon />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={customTagsFilter ? 8 : 10}>
                    <input
                      className={classes.customTagsFilter}
                      placeholder={t('pages.patients.patientsList.patientsTagFilter.searchPlaceholder')}
                      type="text"
                      name="patients-search"
                      value={customTagsFilter}
                      autoComplete="off"
                      onChange={({ target }) => {
                        handleCustomTagsFilterChanged(target.value)
                      }}
                    />
                  </Grid>
                  {customTagsFilter && (
                    <Grid item xs={2}>
                      <ButtonBase onClick={() => handleCustomTagsFilterChanged('')}>
                        <CloseIcon color="black" />
                      </ButtonBase>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.checkboxesItem}>
            <Grid
              container
              className={`${classes.fullContainer} ${!customTags.length && classes.customTagsEmptyState}`}
            >
              {customTagsLoadingState === AsyncStatus.Loading ? (
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <CircularProgress size={16} />
                </Grid>
              ) : customTagsLoadingState === AsyncStatus.Failed ? (
                <Grid item xs={12}>
                  <ButtonBase onClick={() => dispatch(Actions.fetchAggregatedPracticeCustomTags())} disableRipple>
                    <DazzedParagraph12 className={classes.customTagsEmptyStateText} textAlign="center">
                      {t('pages.patients.patientsList.patientsTagFilter.failedToLoadTags')}
                    </DazzedParagraph12>
                  </ButtonBase>
                </Grid>
              ) : customTags.length ? (
                <Grid item xs={12} className={classes.fullContainer}>
                  <CheckBoxFilter
                    className={classes.customTagsInnerContainer}
                    filterOptions={customTags}
                    selectedFilters={parsedSelectedFilters}
                    onChange={onFilterSelected}
                  />
                </Grid>
              ) : (
                <Grid item xs={8}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <DazzedParagraph14 className={classes.customTagsEmptyStateText}>
                        {t('pages.patients.patientsList.patientsTagFilter.filteredCustomTagsEmptyState', {
                          filter: customTagsFilter
                        })}
                      </DazzedParagraph14>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container className={classes.section2}>
          <Grid item className={`${classes.section} ${classes.rightSectionColumn}`}>
            <DazzedHeading14 className={classes.title}>
              {t('pages.patients.patientsList.patientsTagFilter.statusTitle')}
            </DazzedHeading14>
            <CheckBoxFilter
              filterOptions={statuses}
              selectedFilters={parsedSelectedFilters}
              onChange={onFilterSelected}
            />
          </Grid>
          <Grid item className={`${classes.section} ${classes.rightSectionColumn}`}>
            <Grid container direction="column" style={{ height: '100%' }} justifyContent="space-between">
              <Grid item>
                <DazzedHeading14 className={classes.title}>
                  {t('pages.patients.patientsList.patientsTagFilter.treatmentTypeTitle')}
                </DazzedHeading14>
                <CheckBoxFilter
                  filterOptions={treatments}
                  selectedFilters={parsedSelectedFilters}
                  onChange={onFilterSelected}
                />
              </Grid>
              <Grid item>
                <DazzedHeading14 className={classes.title}>
                  {t('pages.patients.patientsList.patientsTagFilter.scanFiltersTitle')}
                </DazzedHeading14>
                <CheckBoxFilter
                  filterOptions={scanTags}
                  selectedFilters={parsedSelectedFilters}
                  onChange={onFilterSelected}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="flex-end"
              className={classes.containerWithButtons}
            >
              <Grid item>
                <Grid container>
                  {!hidePrograms && (
                    <Grid item className={`${classes.section} ${classes.rightSectionColumn}`}>
                      <DazzedHeading14 className={classes.title}>
                        {t('pages.patients.patientsList.patientsTagFilter.programTitle')}
                      </DazzedHeading14>
                      <CheckBoxFilter
                        filterOptions={programs}
                        selectedFilters={parsedSelectedFilters}
                        onChange={onFilterSelected}
                      />
                    </Grid>
                  )}
                  <Grid item className={`${classes.section} ${classes.rightSectionColumn}`}>
                    <DazzedHeading14 className={classes.title}>
                      {t('pages.patients.patientsList.patientsTagFilter.assignee')}
                    </DazzedHeading14>
                    <CheckBoxFilter
                      filterOptions={assigneTags.sort((tag1, tag2) => (tag1.value === doctorId ? -1 : 1))}
                      selectedFilters={parsedSelectedFilters}
                      onChange={onFilterSelected}
                      className={classes.assignee}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container className={classes.buttons}>
                  <Grid item>
                    <SecondaryButton
                      height={40}
                      width={110}
                      label={t('pages.patients.patientsList.patientsTagFilter.clearLabel')}
                      onClick={onClearAll}
                    />
                  </Grid>
                  <Grid item className={classes.submitButton}>
                    <PrimaryButton
                      id="filter-apply-button"
                      height={40}
                      width={110}
                      disabled={!hasSelectedTags}
                      label={t('pages.patients.patientsList.patientsTagFilter.submitLabel')}
                      onClick={onApply}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(FilterMenu)
