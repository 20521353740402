import * as React from 'react'

const LayerDisplayed = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 9C17 9 13.4183 14 9 14C4.58172 14 1 9 1 9C1 9 4.58172 4 9 4C13.4183 4 17 9 17 9Z"
        stroke="#4151E6"
        strokeWidth="1.25"
      />
      <circle cx="9" cy="9" r="2.5" stroke="#4151E6" strokeWidth="1.25" />
    </svg>
  )
}

export default LayerDisplayed
