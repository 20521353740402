import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import { AsyncStatus } from 'consts'
import ScanFeedSkeleton from 'components/Patients/Timeline/V2/Skeletons/ScanFeedSkeleton'
import GrinScanCard from 'components/Patients/Timeline/V2/ScanFeed/GrinScanCard'
import ScanHeader from 'components/Patients/Timeline/V2/ScanFeed/ScanHeader/ScanHeader'
import useScanFeed from 'components/Patients/Timeline/V2/ScanFeed/useScanFeed'
import EditAlignerNumberModal from './EditAlignerNumberModal'
import ScanActionsSkeleton from '../Skeletons/ScanActionsSkeleton'
import ScanActions from './ScanActions'
import ScanTrackingCard from './ScanTrackingCard'
import ScanReviewsCard from './ScanReviewsCard/ScanReviewsCard'
import ScanReferralsCard from './ScanReferralsCard/ScanReferralsCard'
import CompareScansDialog from 'components/Patients/Timeline/ScanComparison/CompareScansDialog'

const useStyles = makeStyles(theme => ({
  scanFeedRoot: {},
  scanActionsContainer: {
    margin: `12px`
  }
}))

const ScanFeed = () => {
  const classes = useStyles()

  const {
    loadStatus,
    displayedGrinScan,
    isDoubleScan,
    scanNumber,
    timelineItem,
    scanStats,
    nextScanItem,
    previousScanItem,
    grinScanWithAligner,
    isEditAlignerModalOpen,
    grinScanIds,
    trackingDetails,
    scanReviews,
    referrals,
    withAligner,
    setIsEditAlignerModalOpen,
    setWithAligner
  } = useScanFeed()

  const { patient, isLoadingPatient } = useSelector(state => state.patientsReducer)

  const analyticsPayload = useMemo(
    () => ({
      patientId: patient?.id,
      program: patient?.user?.program,
      scanNumber,
      grinScanId: displayedGrinScan?.id,
      timelineV2: true
    }),
    [patient, scanNumber, displayedGrinScan]
  )

  return loadStatus === AsyncStatus.Loading ? (
    <ScanFeedSkeleton />
  ) : (
    <>
      <div className={classes.scanFeedRoot}>
        <div className={classes.scanActionsContainer}>
          {isLoadingPatient ? (
            <ScanActionsSkeleton />
          ) : (
            <ScanActions
              analyticsPayload={analyticsPayload}
              displayedScanId={displayedGrinScan?.id}
              scanNumber={scanNumber}
              timelineItemScanIds={grinScanIds}
              withAligner={withAligner}
            />
          )}
        </div>
        <ScanHeader
          grinScanId={displayedGrinScan.id}
          scanNumber={scanNumber}
          alignerNumber={timelineItem?.payload?.alignerNumber}
          numberOfReviews={scanStats.numberOfReviews}
          numberOfShares={scanStats.numberOfShares}
          scanDate={timelineItem.eventDate}
          nextScanTimelineItemId={nextScanItem?.id}
          previousScanTimelineItemId={previousScanItem?.id}
          analyticsPayload={analyticsPayload}
          onEditAlignerNumberClicked={() => setIsEditAlignerModalOpen(true)}
        />
        <GrinScanCard
          patientName={patient?.details?.name}
          scanNumber={scanNumber}
          grinScanId={displayedGrinScan.id}
          videoS3Object={displayedGrinScan.video}
          scanSummaryData={displayedGrinScan.scanSummaryData}
          scanSummaryStatus={displayedGrinScan.scanSummaryStatus}
          withAligner={displayedGrinScan.withAligner}
          setWithAligners={isDoubleScan ? setWithAligner : null}
          analyticsPayload={analyticsPayload}
        />
        {trackingDetails && (
          <ScanTrackingCard
            trackStatus={trackingDetails.trackStatus}
            conditions={trackingDetails.conditions}
            notes={trackingDetails.notes}
          />
        )}
        {!!scanReviews.length && <ScanReviewsCard scanReviews={scanReviews} analyticsPayload={analyticsPayload} />}
        {!!referrals.length && <ScanReferralsCard referrals={referrals} analyticsPayload={analyticsPayload} />}
      </div>
      {grinScanWithAligner && (
        <EditAlignerNumberModal
          isOpen={isEditAlignerModalOpen}
          setIsOpen={setIsEditAlignerModalOpen}
          alignerNumber={timelineItem?.payload?.alignerNumber}
          grinScanId={grinScanWithAligner.id}
          grinScanVersion={grinScanWithAligner._version}
          analyticsPayload={analyticsPayload}
        />
      )}
      <CompareScansDialog />
    </>
  )
}

export default ScanFeed
