import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isValidEmail } from 'utils/generalUtils'

export default () => {
  const { t } = useTranslation()

  const validateNameField = useCallback(
    ({ fieldName, fieldValue, errorsDict }) => {
      if (!fieldValue?.trim()) {
        errorsDict[fieldName] = t('errors.requiredField')
      }
    },
    [t]
  )

  const validateSelectionField = useCallback(
    ({ fieldName, fieldValue, errorsDict }) => {
      if (fieldValue == null) {
        errorsDict[fieldName] = t('errors.requiredSelection')
      }
    },
    [t]
  )

  const validateEmailField = useCallback(
    ({ fieldName, fieldValue, errorsDict }) => {
      if (!fieldValue) {
        errorsDict[fieldName] = t('errors.requiredField')
      } else if (!isValidEmail(fieldValue)) {
        errorsDict[fieldName] = t('errors.invalidEmail')
      }
    },
    [t]
  )

  const validateFields = useCallback(
    ({ nameFields = [], emailFields = [], selectionFields = [], errorsDict }) => {
      nameFields.forEach(({ fieldName, fieldValue }) => validateNameField({ fieldName, fieldValue, errorsDict }))
      emailFields.forEach(({ fieldName, fieldValue }) => validateEmailField({ fieldName, fieldValue, errorsDict }))
      selectionFields.forEach(({ fieldName, fieldValue }) =>
        validateSelectionField({ fieldName, fieldValue, errorsDict })
      )
      return errorsDict
    },
    [validateEmailField, validateNameField, validateSelectionField]
  )

  return {
    validateNameField,
    validateEmailField,
    validateFields,
    validateSelectionField
  }
}
