import React, { useCallback } from 'react'
import { Box, makeStyles, Button } from '@material-ui/core'
import Actions from '../../actions'
import avatar from '../../resources/GrayscaleUserAvatar.png'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { isMobile } from 'utils/mobileUtils'
import { ROUTES } from 'consts'
import { trackEvent } from 'utils/analyticsUtils'

const PatientsListItemEmptyState = ({ onClick }) => {
  const classes = makeStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 132,
      borderBottom: '1px solid var(--border-color-3)',
      background: 'rgba(242, 243, 245, 0.5);',
      padding: '20px 15px 20px 15px',
      cursor: 'pointer'
    },
    inviteBtn: {
      height: 32,
      width: 102,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 40,
      fontSize: 14,
      textTransform: 'none',
      border: '1px solid var(--border-color-1)',
      color: 'var(--text-color-3)'
    },
    patientItemHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginLeft: 20
    },
    label: {
      marginBottom: 10
    },
    profilePicture: {
      marginRight: 20,
      borderRadius: 40,
      height: 80,
      width: 80,
      marginLeft: 20
    }
  })({})

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const handleOpenInvitePatientModal = useCallback(() => {
    trackEvent('Invite patient - modal opened', {
      source: 'Patients list empty state'
    })

    isMobile() ? history.push(ROUTES.INVITE_PATIENT) : dispatch(Actions.requestOpenInvitePatientModal())
  }, [dispatch, history])

  return (
    <Box className={classes.container} onClick={onClick}>
      <img src={avatar} className={classes.profilePicture} alt="avatar" />
      <Box width="100%">
        <Box className={classes.patientItemHeader}>
          <span className={classes.label}>{t('pages.patients.patientsList.noPatientsYet')}</span>
          <Button className={classes.inviteBtn} onClick={handleOpenInvitePatientModal}>
            {t('pages.patients.patientsList.invitePatientBtn')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default PatientsListItemEmptyState
