import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Grid } from '@material-ui/core'
import LinkedAccounts from 'components/common/icons/LinkedAccounts'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useSelector } from 'react-redux'
import { AsyncStatus } from 'consts'

const LinkedAccountsSection = () => {
  const { t } = useTranslation()

  const { status: siblingsLoadStatus, list: siblingsList } = useSelector(
    state => state.patientsReducer.patientCard?.siblings || {}
  )
  const { id: patientId } = useSelector(state => state.patientsReducer.patientCard?.patient)

  const filteredSiblings = useMemo(
    () => siblingsList?.filter(sibling => sibling.id !== patientId),
    [siblingsList, patientId]
  )

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <LinkedAccounts />
          </Grid>
          <Grid item>
            <DazzedParagraph14 bold>
              {t('dialogs.patientInfo.legalGuardian.linkedAccountsSectionTitle')}
            </DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {siblingsLoadStatus === AsyncStatus.Loading ? (
          <CircularProgress size={12} />
        ) : !filteredSiblings?.length ? (
          <DazzedParagraph14 strong>{t('dialogs.patientInfo.legalGuardian.noLinkedAccountsFound')}</DazzedParagraph14>
        ) : (
          <Grid container spacing={1}>
            {filteredSiblings.map((sibling, i) => (
              <Grid item key={sibling.id}>
                <DazzedParagraph14 strong>
                  <a href={`${window.location.origin}/patients/${sibling.id}`} target="_blank" rel="noreferrer">
                    {sibling.name}
                  </a>
                  {i < filteredSiblings.length - 1 && ','}
                </DazzedParagraph14>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default LinkedAccountsSection
