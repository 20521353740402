import * as React from 'react'

const CrestXGrin = props => {
  return (
    <svg width="100" height="84" viewBox="0 0 100 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.68526 17.0722C1.68526 13.0413 4.95296 9.77361 8.98387 9.77361H91.7015C95.7324 9.77361 99.0001 13.0413 99.0001 17.0722V75.4611C99.0001 79.4921 95.7324 82.7598 91.7015 82.7598H8.98387C4.95295 82.7598 1.68526 79.4921 1.68526 75.4612V17.0722Z"
        fill="white"
        stroke="#002D72"
        strokeWidth="2"
      />
      <rect x="8.98388" y="36.5352" width="17.0301" height="17.0301" rx="2.43287" stroke="#002D72" strokeWidth="1.5" />
      <rect x="8.98388" y="58.431" width="17.0301" height="17.0301" rx="2.43287" stroke="#002D72" strokeWidth="1.5" />
      <rect x="30.8797" y="36.5352" width="17.0301" height="17.0301" rx="2.43287" stroke="#002D72" strokeWidth="1.5" />
      <rect x="30.8797" y="58.431" width="17.0301" height="17.0301" rx="2.43287" stroke="#002D72" strokeWidth="1.5" />
      <rect x="52.7756" y="36.5352" width="17.0301" height="17.0301" rx="2.43287" stroke="#002D72" strokeWidth="1.5" />
      <rect
        x="52.7756"
        y="58.431"
        width="17.0301"
        height="17.0301"
        rx="2.43287"
        fill="#002D72"
        fillOpacity="0.5"
        stroke="#002D72"
        strokeWidth="1.5"
      />
      <rect x="74.6714" y="36.5352" width="17.0301" height="17.0301" rx="2.43287" stroke="#002D72" strokeWidth="1.5" />
      <rect x="74.6714" y="58.431" width="17.0301" height="17.0301" rx="2.43287" stroke="#002D72" strokeWidth="1.5" />
      <path d="M26.014 2.475V14.6394" stroke="#002D72" strokeWidth="3.5" strokeLinecap="round" />
      <path d="M74.6714 2.475V14.6394" stroke="#002D72" strokeWidth="3.5" strokeLinecap="round" />
      <rect opacity="0.25" x="8.98388" y="21.938" width="82.7176" height="7.29861" rx="3.64931" fill="#002D72" />
    </svg>
  )
}

export default CrestXGrin
