import * as React from 'react'

const Copy = ({ color = 'var(--text-color-3)', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.14286 3.85714V2C8.14286 1.44772 7.69514 1 7.14286 1H2C1.44772 1 1 1.44772 1 2V7.14286C1 7.69514 1.44772 8.14286 2 8.14286H3.85714"
        stroke={color}
        strokeLinejoin="round"
      />
      <rect x="3.85938" y="3.85693" width="7.14286" height="7.14286" rx="1" stroke={color} strokeLinejoin="round" />
    </svg>
  )
}

export default Copy
