import React, { useCallback, useEffect, useMemo } from 'react'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { LittleMan } from 'components/common/icons'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import GrinRadioGroup from 'components/common/GrinRadioGroup'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import usePms from 'hooks/usePms'
import PmsSearchPatient from 'components/Patients/PatientCard/AppointmentsTab/PmsSearchPatient'
import { useSelector } from 'react-redux'
import { AsyncStatus } from 'consts'
import { calculateAge } from 'utils/patientUtils'
import useClinic from 'hooks/useClinic'

const useStyles = makeStyles({
  patientInfoHeader: {
    marginBottom: '10px'
  },
  sectionHeader: {
    paddingLeft: 6,
    display: 'inline-block',
    lineHeight: '20px'
  },
  required: {
    color: '#828999'
  }
})

const PatientInfoHeader = ({ values }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { doctorPms, renderPmsIcon, authenticatePms, pmsType } = usePms()
  const { authenticationStatus } = useSelector(state => state.pmsReducer)
  const { practiceDetails } = useClinic()

  const patientUnder18CheckboxOptions = useMemo(
    () => [
      { label: t('general.yes'), value: true },
      { label: t('general.no'), value: false }
    ],
    [t]
  )
  const onTapPatientIsUnder18 = useCallback(
    value => {
      trackEvent(`invite patient - checked ${value ? 'under 18' : 'over 18'}`, { isMobile: false })
      values.setLegalGuardianNeeded(value)
      values.setShouldLinkToSibling(null)
      values.setSiblingToLink(null)
    },
    [values]
  )

  const handleSelectPmsPatient = useCallback(
    pmsPatient => {
      values.setFirstName(pmsPatient.firstName || '')
      values.setLastName(pmsPatient.lastName || '')
      values.setLastName(pmsPatient.lastName || '')
      const countryCode = practiceDetails?.countryCode || '+1'
      values.setPhone(`${countryCode}${pmsPatient.phoneNumber || ''}`)
      values.setEmail(pmsPatient.email || '')

      const { under18 } = calculateAge({ birthdate: pmsPatient.birthDate })
      onTapPatientIsUnder18(under18)
      if (under18) {
        values.setGuardianEmail(pmsPatient.email || '')
      }

      trackEvent('Invite patient modal - Auto filled details from pms', {
        patientId: pmsPatient.id,
        pmsType
      })
    },
    [pmsType, values, onTapPatientIsUnder18, practiceDetails]
  )

  useEffect(() => {
    if (!!doctorPms) {
      authenticatePms()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorPms])

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Grid className={classes.patientInfoHeader} item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <LittleMan />
                <DazzedHeading18 className={classes.sectionHeader}>
                  {t('dialogs.invitePatient.patientInfo')}
                </DazzedHeading18>
              </Grid>
              {!!doctorPms &&
                (authenticationStatus === AsyncStatus.Loading ? (
                  <Grid item>
                    <CircularProgress size={30} />
                  </Grid>
                ) : (
                  <Grid item>
                    <Grid container alignItems="center">
                      <Grid item>
                        <PmsSearchPatient
                          onSelect={handleSelectPmsPatient}
                          onSearchAnalyticEvent={() =>
                            trackEvent('Invite patient modal - searched for patient from PMS', {
                              pmsType
                            })
                          }
                        />
                      </Grid>
                      <Grid item>{renderPmsIcon()}</Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid spacing={2} alignItems="center" container direction="row" id="invite-patient-under-18">
            <Grid item>
              <Grid spacing={1} alignItems="center" container direction="row">
                <Grid item>
                  <DazzedParagraph16>
                    {t('dialogs.invitePatient.under18SubHeader1')}
                    <strong>
                      <u>{t('dialogs.invitePatient.under18SubHeader2')}</u>
                    </strong>
                    {t('dialogs.invitePatient.under18SubHeader3')}
                  </DazzedParagraph16>
                </Grid>
                <Grid item>
                  <DazzedParagraph12 className={classes.required}>
                    {t('dialogs.invitePatient.requiredSubHeader')}
                  </DazzedParagraph12>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <GrinRadioGroup
                value={values.legalGuardianNeeded}
                setValue={value => onTapPatientIsUnder18(value === 'true')}
                options={patientUnder18CheckboxOptions}
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PatientInfoHeader
