import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import _ from 'lodash'
import ElasticsToothGridItem from './ElasticsToothGridItem'
import { ORTHO_TEETH_LIST } from 'consts/teethConsts'

const useStyles = makeStyles(() => ({
  row: {
    margin: '10px'
  }
}))

const ElasticsTeethSelectionGrid = ({
  elastics = [],
  currentElasticIndex,
  editable = true,
  onToothClick = tooth => {},
  maxElasticsOnTooth
}) => {
  const classes = useStyles()

  const matrix = useMemo(() => {
    const [UL, UR, LL, LR] = _.chunk(ORTHO_TEETH_LIST, 8)
    return [
      [UL, UR],
      [LL, LR]
    ]
  }, [])

  const teethDict = useMemo(
    () =>
      ORTHO_TEETH_LIST.reduce(
        (final, tooth) => ({
          ...final,
          [tooth]: elastics
            .map((elastic, elasticIndex) => ({
              elasticIndex,
              elasticName: elastic.name,
              color: elastic.color,
              selection: elastic.selections[tooth]
            }))
            .filter(elastic => elastic.selection)
        }),
        {}
      ),
    [elastics]
  )

  const isToothSelectedInCurrentElastic = useCallback(
    tooth => elastics[currentElasticIndex]?.selections[tooth],
    [elastics, currentElasticIndex]
  )

  return (
    <Grid container direction="column">
      {matrix.map((row, rowI) => (
        <Grid item key={`teeth-selection-row-${rowI}`} className={classes.row}>
          <Grid container spacing={1} direction="row">
            {row.map((section, sectionI) => (
              <Grid
                item
                key={`teeth-selection-section-${sectionI}`}
                style={{ marginRight: sectionI % 2 === 0 ? 10 : 0 }}
              >
                <Grid container spacing={1}>
                  {section.map(tooth => (
                    <Grid item key={`teeth-selection-tooth-${tooth}`}>
                      <ElasticsToothGridItem
                        tooth={tooth}
                        onClick={() => onToothClick(tooth)}
                        values={teethDict[tooth]}
                        highlightedElasticIndex={currentElasticIndex}
                        editable={
                          editable &&
                          (teethDict[tooth]?.length < maxElasticsOnTooth || isToothSelectedInCurrentElastic(tooth))
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default ElasticsTeethSelectionGrid
