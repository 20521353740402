import * as React from 'react'

function AddScopes(props) {
  return (
    <svg width="39" height="19" viewBox="0 0 39 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.5 10.4148C10.5 8.70541 11.7348 7.24609 13.4206 6.9631L22.1364 5.50002V17.0454L13.4206 15.5823C11.7348 15.2994 10.5 13.84 10.5 12.1306V10.4148Z"
        fill="white"
        stroke="#3B52EF"
      />
      <path
        d="M28.5221 4.90349L28.5486 4.90473L28.575 4.90316L32.1669 4.69032C33.9243 4.58617 35.5745 5.53978 36.3618 7.1144C37.6707 9.73213 37.6707 12.8133 36.3618 15.4311C35.5745 17.0057 33.9243 17.9593 32.1669 17.8551L28.575 17.6423L28.5486 17.6407L28.5221 17.642L22.6176 17.9182C21.1107 17.9887 19.6831 17.2405 18.8837 15.9613C17.0908 13.0927 17.0908 9.45276 18.8837 6.58412C19.6831 5.30493 21.1107 4.55676 22.6176 4.62726L28.5221 4.90349Z"
        fill="white"
        stroke="#3B52EF"
      />
      <path
        d="M27.9972 7.47923L28.0241 7.4805L28.051 7.47889L30.2084 7.34904C31.2423 7.28682 32.2126 7.85092 32.6701 8.78018C33.4438 10.3518 33.4438 12.1936 32.6701 13.7653C32.2126 14.6945 31.2423 15.2586 30.2084 15.1964L28.051 15.0666L28.0241 15.0649L27.9972 15.0662L24.4108 15.2366C23.5265 15.2786 22.6893 14.8366 22.2253 14.0826C21.1649 12.3594 21.1649 10.186 22.2253 8.46284C22.6893 7.70888 23.5265 7.2668 24.4108 7.30882L27.9972 7.47923Z"
        stroke="#3B52EF"
      />
      <path d="M6.5 1V12" stroke="white" strokeWidth="13" />
      <path d="M12 6.5L1 6.5" stroke="white" strokeWidth="12" />
      <path d="M6.5 2V11" stroke="#3B52EF" strokeWidth="2" />
      <path d="M11 6.5L2 6.5" stroke="#3B52EF" strokeWidth="2" />
    </svg>
  )
}

export default AddScopes
