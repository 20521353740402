import * as React from 'react'

export default () => {
  return (
    <svg width="15" height="23" viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 7.5C1 3.91015 3.91015 1 7.5 1V1C11.0899 1 14 3.91015 14 7.5V15.5C14 19.0898 11.0899 22 7.5 22V22C3.91015 22 1 19.0898 1 15.5V7.5Z"
        stroke="#A6AAB3"
      />
      <path d="M7.5 6V1" stroke="#A6AAB3" strokeWidth="0.5" />
      <rect x="6" y="6" width="3" height="6" rx="1.5" fill="#A6AAB3" fillOpacity="0.65" stroke="#767986" />
    </svg>
  )
}
