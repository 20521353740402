import React, { useCallback, useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import OggHeading40 from 'components/common/text/OggHeading40'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph10 from 'components/common/text/DazzedParagraph10'
import AutocompleteWithChips from 'components/common/AutocompleteWithChips'
import GrinSlider from 'components/common/GrinSlider'
import GrinRadioGroup from 'components/common/GrinRadioGroup'
import Location from 'components/common/icons/Location'
import Actions from 'actions'
import {
  METRIC_OPTIONS,
  AREA_OF_COVERAGE_MIN_DISTANCE,
  AREA_OF_COVERAGE_MAX_DISTANCE,
  ALLOWED_ZIP_CODES_TYPE
} from 'consts/settings'
import { isEqual, uniq } from 'lodash'
import { isValidZipcode } from 'utils/generalUtils'
import { COUNTRY_US } from 'consts/countryConsts'
import Map from './Map'

const useStyles = makeStyles(() => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    color: 'var(--text-color-6)',
    opacity: 1,
    textTransform: 'none',
    marginLeft: 5,
    marginTop: 2,
    marginBottom: 10,
    lineHeight: '18px',
    letterSpacing: 'normal',
    fontSize: 22,
    fontWeight: 400
  },
  subTitle: {
    marginBottom: 10
  },
  label: {
    color: 'var(--text-color-6)',
    opacity: 1,
    textTransform: 'none',
    marginBottom: 10,
    lineHeight: '18px',
    letterSpacing: 'normal'
  },
  prompt: {
    opacity: 0.5,
    fontWeight: 'normal',
    marginLeft: 4
  },
  coverageRadius: {
    marginTop: 15
  },
  distance: {
    marginTop: 7,
    flexWrap: 'nowrap'
  },
  distanceAligner: {
    marginTop: 5,
    textAlign: 'center'
  },
  generateButton: {
    margin: '15px 0'
  },
  radioLabel: {
    fontSize: '12px'
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15
  },
  map: {
    width: '100%',
    height: 300
  },
  noteText: {
    color: 'var(--text-color-17)',
    marginBottom: 10
  },
  invisibleNoteText: {
    visibility: 'hidden'
  }
}))

const AreaOfCoveragePicker = ({
  zips,
  handleChangeZips,
  metricType,
  setMetricType,
  searchRadius,
  setSearchRadius,
  doctor
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const country = useSelector(state => state.practiceReducer.accountOwner.clinic.country) || COUNTRY_US
  const coordinates = useSelector(state => state.profileReducer.zipCoordinates.data)

  const [currentLocation, setCurrentLocation] = useState(zips[0])
  const [showSearchRadiusError, setShowSearchRadiusError] = useState(false)

  useEffect(() => {
    if (!!zips.length) {
      dispatch(
        Actions.getZipCoordinates({
          zipcodes: zips.join(','),
          country
        })
      )
    }
  }, [dispatch, zips, country])

  useEffect(() => {
    if (!zips.includes(currentLocation)) {
      setCurrentLocation(zips[0])
    }
  }, [zips, currentLocation])

  useEffect(() => {
    searchRadius && showSearchRadiusError && setShowSearchRadiusError(false)
  }, [searchRadius, showSearchRadiusError, setShowSearchRadiusError])

  const handleChangeSearchRadius = useCallback(
    (e, value) => {
      setSearchRadius(value)
      if (value === 0 && searchRadius !== value) {
        setShowSearchRadiusError(true)
      }
    },
    [setSearchRadius, searchRadius, setShowSearchRadiusError]
  )

  const handleChangeZipCodes = useCallback(
    (type, values, newValue) => {
      if (newValue && !isValidZipcode(newValue, country)) {
        dispatch(
          Actions.showSnackbar({
            type: `error`,
            text: t('messages.searchAreaCoverage.invalidZipCode', { zip: newValue, country })
          })
        )

        return
      }

      if (newValue && zips.includes(newValue)) {
        dispatch(
          Actions.showSnackbar({
            type: `error`,
            text: t('messages.searchAreaCoverage.zipCodeAlreadyAdded', { zip: newValue })
          })
        )

        return
      }

      const areEqual = isEqual(zips, uniq(values))
      if (!areEqual) {
        handleChangeZips(type, values)
      }
    },
    [zips, dispatch, t, handleChangeZips, country]
  )

  return (
    <>
      <div className={classes.titleContainer}>
        <Location />
        <OggHeading40 className={classes.title}>
          {t('pages.accountSettings.services.ortho.workingAreaTitle')}
        </OggHeading40>
      </div>
      <DazzedParagraph10 className={classes.subTitle}>
        {t('pages.accountSettings.services.ortho.workingAreaSubTitle')}
      </DazzedParagraph10>
      <DazzedParagraph12 className={classes.label}>
        {t('pages.accountSettings.services.ortho.practiceLabel')}
      </DazzedParagraph12>
      <DazzedParagraph12 className={classes.label}>
        {t('pages.accountSettings.services.ortho.zipsLabel')}
        <span className={classes.prompt}>{t('pages.accountSettings.services.ortho.zipsPrompt')}</span>
      </DazzedParagraph12>
      <AutocompleteWithChips
        size="small"
        value={zips}
        onChange={(values, newValue) => handleChangeZipCodes(ALLOWED_ZIP_CODES_TYPE, values, newValue)}
      />
      <DazzedParagraph10 className={classes.coverageRadius}>
        {t('pages.accountSettings.services.ortho.setCoverageRadius')}
      </DazzedParagraph10>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <GrinSlider
            value={searchRadius}
            handleChange={handleChangeSearchRadius}
            min={AREA_OF_COVERAGE_MIN_DISTANCE}
            max={AREA_OF_COVERAGE_MAX_DISTANCE}
            minLabel={t(`pages.accountSettings.services.ortho.${metricType}MinDistanceLabel`, {
              distance: AREA_OF_COVERAGE_MIN_DISTANCE
            })}
            maxLabel={t(`pages.accountSettings.services.ortho.${metricType}MaxDistanceLabel`, {
              distance: AREA_OF_COVERAGE_MAX_DISTANCE
            })}
          />
        </Grid>
        <Grid item xs={2} className={classes.distanceAligner}>
          {`${searchRadius} ${t(
            `pages.accountSettings.services.ortho.${metricType === 'km' ? 'km' : searchRadius === 1 ? 'mile' : 'miles'}`
          )}`}
        </Grid>
        <Grid item xs={2}>
          <GrinRadioGroup
            value={metricType}
            setValue={setMetricType}
            options={METRIC_OPTIONS}
            size="small"
            radioGroupClassName={classes.distance}
            labelClassName={classes.radioLabel}
          />
        </Grid>
        <Grid item xs={12}>
          <DazzedParagraph10
            className={[classes.noteText, !showSearchRadiusError && classes.invisibleNoteText].join(' ')}
          >
            {t('pages.accountSettings.services.ortho.nullRadiusInfoMessage')}
          </DazzedParagraph10>
        </Grid>
        <Grid item xs={12}>
          <Map
            metricType={metricType}
            searchRadius={searchRadius}
            coordinates={coordinates}
            zips={zips}
            currentLocation={currentLocation}
            setCurrentLocation={setCurrentLocation}
            onZipcodesChange={handleChangeZipCodes}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default AreaOfCoveragePicker
