import * as React from 'react'

export default ({ color = 'var(--text-color-25)' }) => (
  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1"
      y="1"
      width="10.6667"
      height="8"
      rx="1.33333"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66602 1.3335L5.62558 5.29306C6.0161 5.68358 6.64926 5.68358 7.03979 5.29306L10.9993 1.3335"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
