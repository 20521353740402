import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAllFeatureFlags } from 'hooks/useAllFeatureFlags'
import SelectInput from 'components/common/SelectInput'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { conditionTypes } from 'consts/automatedActionConsts'

const PracticeHasFeatureFlagCondition = ({ onChange = () => {}, params = {} }) => {
  const { t } = useTranslation()
  const { doctorFeatureFlags } = useAllFeatureFlags()

  const options = useMemo(
    () => doctorFeatureFlags.map(ff => ({ value: ff.key, label: ff.label })),
    [doctorFeatureFlags]
  )

  return (
    <Grid container>
      <Grid item>
        <DazzedParagraph14 strong>
          {t('pages.accountSettings.automations.editorModal.selectFeatureFlag')}
        </DazzedParagraph14>
        <Grid item>
          <SelectInput
            options={options}
            value={params.featureFlag}
            onChange={option =>
              onChange({ type: conditionTypes.PracticeHasFeatureFlag, params: { featureFlag: option.value } })
            }
            style={{ bright: true, thick: true }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PracticeHasFeatureFlagCondition
