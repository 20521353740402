import * as React from 'react'

const Unknown = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 4 }}
  >
    <rect x="1" y="1" width="16" height="16" rx="3" fill="#CAD9FF" stroke="#829DE3" strokeWidth="2" />
    <path
      d="M8.09766 10.4901V10.4091C8.10334 9.88068 8.15589 9.46023 8.25533 9.14773C8.3576 8.83523 8.50249 8.58239 8.68999 8.3892C8.87749 8.19602 9.10334 8.01989 9.36754 7.8608C9.538 7.75284 9.69141 7.6321 9.82777 7.49858C9.96413 7.36506 10.0721 7.21165 10.1516 7.03835C10.2312 6.86506 10.271 6.6733 10.271 6.46307C10.271 6.21023 10.2113 5.99148 10.092 5.80682C9.97266 5.62216 9.81357 5.48011 9.6147 5.38068C9.41868 5.27841 9.19993 5.22727 8.95845 5.22727C8.7397 5.22727 8.53089 5.27273 8.33203 5.36364C8.13317 5.45455 7.96839 5.59659 7.83771 5.78977C7.70703 5.98011 7.63175 6.22585 7.61186 6.52699H6.31641C6.33629 6.01562 6.46555 5.58381 6.70419 5.23153C6.94283 4.87642 7.25817 4.60795 7.65021 4.42614C8.0451 4.24432 8.48118 4.15341 8.95845 4.15341C9.48118 4.15341 9.93857 4.25142 10.3306 4.44744C10.7227 4.64062 11.0266 4.91193 11.2425 5.26136C11.4613 5.60795 11.5707 6.01278 11.5707 6.47585C11.5707 6.79403 11.521 7.08097 11.4215 7.33665C11.3221 7.58949 11.18 7.81534 10.9954 8.0142C10.8136 8.21307 10.5948 8.3892 10.3391 8.54261C10.0977 8.69318 9.90163 8.84943 9.75107 9.01136C9.60334 9.1733 9.49538 9.36506 9.4272 9.58665C9.35902 9.80824 9.32209 10.0824 9.31641 10.4091V10.4901H8.09766ZM8.74112 13.081C8.50817 13.081 8.30788 12.9986 8.14027 12.8338C7.97266 12.6662 7.88885 12.4645 7.88885 12.2287C7.88885 11.9957 7.97266 11.7969 8.14027 11.6321C8.30788 11.4645 8.50817 11.3807 8.74112 11.3807C8.97124 11.3807 9.1701 11.4645 9.33771 11.6321C9.50817 11.7969 9.5934 11.9957 9.5934 12.2287C9.5934 12.3849 9.55362 12.5284 9.47408 12.6591C9.39737 12.7869 9.2951 12.8892 9.16726 12.9659C9.03942 13.0426 8.89737 13.081 8.74112 13.081Z"
      fill="#51669B"
    />
  </svg>
)

export default Unknown
