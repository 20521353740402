import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default () => {
  const { t } = useTranslation()

  const accountOwnerId = useSelector(state => state.practiceReducer.accountOwner.id)
  const customViews = useSelector(state => state.practiceReducer.savedViews.views)

  const generatePresets = useCallback(
    ({ accountOwnerId }) => [
      {
        id: 'none',
        name: t('pages.patients.patientsList.savedViews.presets.none'),
        type: 'system',
        isPrivate: false,
        filters: {}
      },
      {
        id: 'unreadAndAssignedTo',
        name: t('pages.patients.patientsList.savedViews.presets.unreadAndAssignedToMe'),
        type: 'system',
        isPrivate: false,
        filters: {
          chat: ['unread'],
          assignee: [accountOwnerId]
        }
      },
      {
        id: 'needsPracticeAttention',
        name: t('pages.patients.patientsList.savedViews.presets.needsPracticeAttention'),
        type: 'system',
        isPrivate: false,
        filters: {
          scan: ['needsAttention']
        }
      },
      {
        id: 'recordsAppLeads',
        name: t('pages.patients.patientsList.savedViews.presets.recordsAppLeads'),
        type: 'system',
        isPrivate: false,
        filters: {
          status: ['records-only', 'new-lead']
        }
      }
    ],
    [t]
  )

  const parsedCustomViews = useMemo(
    () =>
      customViews.map(view => ({
        ...view,
        filters: JSON.parse(view.filters || '{}')
      })),
    [customViews]
  )
  const presetViews = useMemo(() => generatePresets({ accountOwnerId }), [accountOwnerId, generatePresets])
  const allSavedViews = useMemo(() => [...presetViews, ...parsedCustomViews], [parsedCustomViews, presetViews])

  return {
    presetViews,
    parsedCustomViews,
    allSavedViews
  }
}
