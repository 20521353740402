import * as React from 'react'

const CloseLarge = props => {
  return (
    <svg width={props.width || 29} height={props.height || 28} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1488 13.9999L0.601562 25.5471L2.84074 27.7863L14.3879 16.239L25.9351 27.7863L28.1743 25.5471L16.6271 13.9999L28.1741 2.45292L25.9349 0.213745L14.3879 11.7607L2.84098 0.213745L0.601805 2.45292L12.1488 13.9999Z"
        fill="#7A94A5"
      />
    </svg>
  )
}

export default CloseLarge
