import { ClickAwayListener, Grid, makeStyles, Popper } from '@material-ui/core'
import Actions from 'actions'
import { PromotionsIcon } from 'components/common/icons'
import usePromotions from 'hooks/usePromotions'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { setDoNotShowPromotionsPopupOnThisDevice } from 'utils/storageUtils'
import TemplateListItem from '../Templates/TemplateListItem'
import Templates from '../Templates/Templates'
import PromotionsPopup from './PromotionsPopup'
import SelectedCategory from './SelectedCategory'

const useStyles = makeStyles({
  container: {
    paddingLeft: 20
  },
  promotionPopup: {
    zIndex: 999,
    borderRadius: 16,
    '-webkit-filter': 'drop-shadow(0px 3px 8px var(--border-color-21))',
    filter: 'drop-shadow(0px 3px 8px var(--border-color-21))',
    paddingBottom: 23
  }
})

const Promotions = ({ id = '', onPromotionSelection, disabled = false, message, setShowDropzone }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const promotionsIconRef = useRef(null)

  const { groupedPromotions, isMessageContainKeyword } = usePromotions()
  const isLoading = useSelector(state => state.chatReducer.promotions.isLoading)
  const roomId = useSelector(state => state.chatReducer.activeRoomId)

  const [isPromotionsPopupOpen, setIsPromotionsPopupOpen] = useState(null)
  const [dontShowPromotionPopupAgain, setDontShowPromotionPopupAgain] = useState(false)

  const handlePromotionApplied = useCallback(
    async promotion => {
      onPromotionSelection(promotion)
    },
    [onPromotionSelection]
  )

  const renderSelectedOffer = useCallback(
    (selectedCategory, onButtonClick) => (
      <SelectedCategory
        category={selectedCategory}
        onSelect={product => {
          onButtonClick()

          trackEvent('Promotions - promotion selected', {
            categoryKey: product.categoryKey,
            featureFlagsIds: product.featureFlagsIds,
            title: product.title,
            externalId: product.externalId
          })
          handlePromotionApplied(product)
        }}
      />
    ),
    [handlePromotionApplied]
  )

  const renderList = useCallback(
    ({ templates, selected, setSelected, onDeleteTemplate }) => {
      return (
        <Grid container className={classes.container}>
          {templates.map(promotion => (
            <TemplateListItem
              key={promotion.id}
              title={promotion.title}
              selected={promotion.id === selected?.id}
              onClick={() => setSelected(promotion)}
              onDelete={() => onDeleteTemplate(promotion)}
            />
          ))}
        </Grid>
      )
    },
    [classes.container]
  )

  const renderIcon = useCallback(
    () => <PromotionsIcon ref={promotionsIconRef} color={disabled ? 'var(--text-color-12)' : '#3C52EB'} />,
    [disabled]
  )

  const renderEmptyState = () => {}

  useEffect(() => {
    if (isMessageContainKeyword(message) && isPromotionsPopupOpen === null) {
      setIsPromotionsPopupOpen(true)
      trackEvent('Promotions tooltip opened')
    }
  }, [isMessageContainKeyword, isPromotionsPopupOpen, message])

  const handleClosePromotionsPopup = useCallback(() => {
    setIsPromotionsPopupOpen(false)
    setDoNotShowPromotionsPopupOnThisDevice(dontShowPromotionPopupAgain)
    trackEvent('Promotions tooltip closed', { reason: 'close button' })
  }, [dontShowPromotionPopupAgain])

  const handleOpenAction = useCallback(() => {
    trackEvent('Promotions - popup opened')
    setIsPromotionsPopupOpen(false)
    setShowDropzone(false)
    dispatch(Actions.fetchLastPromotionMessages({ roomId }))
  }, [setShowDropzone, roomId, dispatch])

  const handleCloseAction = useCallback(() => {
    trackEvent('Promotions - popup closed')
  }, [])

  const handleCategorySelectedAction = useCallback(value => {
    trackEvent('Promotions - category selected', {
      category: value.title,
      products: value.products.map(item => ({ title: item?.title, externalId: item?.externalId }))
    })
  }, [])

  const handleClickAway = useCallback(() => {
    if (isPromotionsPopupOpen) {
      setIsPromotionsPopupOpen(false)
      trackEvent('Promotions tooltip closed', { reason: 'click away' })
    }
  }, [isPromotionsPopupOpen])

  return (
    <>
      <Templates
        id={id}
        templates={groupedPromotions}
        renderIcon={renderIcon}
        renderSelectedTemplate={renderSelectedOffer}
        renderList={renderList}
        isCreatable={false}
        isSearchable={false}
        isSelectDefault={true}
        isLoading={isLoading}
        title={t('pages.patients.selectedPatient.chat.promotions.title')}
        tooltipValue={t(`pages.patients.selectedPatient.chat.promotions.${disabled ? 'disabled' : 'title'}`)}
        renderEmptyState={renderEmptyState}
        height={435}
        width={658}
        transformOrigin={450}
        templatesSize={3}
        selectedTemplateSize={9}
        disabled={disabled}
        setIsPromotionsPopupOpen={setIsPromotionsPopupOpen}
        isPromotionsPopupOpen={isPromotionsPopupOpen}
        onOpenCallback={handleOpenAction}
        onCloseCallback={handleCloseAction}
        onTemplateSelected={handleCategorySelectedAction}
      />
      <Popper
        anchorEl={promotionsIconRef.current}
        keepMounted
        open={!!isPromotionsPopupOpen}
        onClose={handleClosePromotionsPopup}
        className={classes.promotionPopup}
        placeholder="top"
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <PromotionsPopup
              handleClose={handleClosePromotionsPopup}
              dontShowAgain={dontShowPromotionPopupAgain}
              setDontShowAgain={setDontShowPromotionPopupAgain}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default Promotions
