import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { animate, findClickedSceneObjects, init3DView } from './Grin3DViewerUtils'
import { DEFAULT_BACKGROUND_COLOR } from './Grin3DViewerConsts'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%'
  }
}))

const Grin3DViewer = ({
  onMeshClicked = () => {},
  onSceneLoaded,
  zCameraPosition = 50,
  cameraTopOffset,
  bgColor = DEFAULT_BACKGROUND_COLOR,
  zoom,
  zoomOnly = false,
  customControls
}) => {
  const classes = useStyles()
  const containerRef = useRef()

  useEffect(() => {
    const { scene, camera, renderer, controls } = init3DView({
      containerElement: containerRef.current,
      zCameraPosition,
      hexBgColor: bgColor,
      cameraTopOffset,
      zoom,
      zoomOnly,
      customControls
    })

    onSceneLoaded({ scene, camera })

    const element = containerRef.current
    const onClick = event => {
      const intersectsObjects = findClickedSceneObjects({ event, camera, scene })
      onMeshClicked(intersectsObjects)
    }

    element.addEventListener('click', onClick)
    const newChild = element.appendChild(renderer.domElement)

    const animationFrameId = animate({ renderer, scene, camera, controls })

    return () => {
      cancelAnimationFrame(animationFrameId)
      element.removeEventListener('click', onClick)
      element.removeChild(newChild)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSceneLoaded])

  return <div ref={containerRef} className={classes.container}></div>
}

export default Grin3DViewer
