import * as React from 'react'

const ArrowDropdownDown = ({ color = '#001335', size = 7, isMobile = false }) => {
  return isMobile ? (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.99982 0.46875L4.34024 4.65113L0.680664 0.46875H7.99982Z" fill="#041333" />
    </svg>
  ) : (
    <svg width={size} height={size} viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.22754 6.45544L-0.000171675 3.22773L3.22754 3.22773L6.45525 3.22773L3.22754 6.45544Z" fill={color} />
    </svg>
  )
}

export default ArrowDropdownDown
