import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../../actions'
import BaseModal from '../../common/modals/BaseModal'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import TextArea from '../../common/TextArea'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  textareaContainer: {
    marginTop: 15
  }
}))

const RequestUpgradePlanModal = ({ isOpen, onClose, userPlanKey }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.billingReducer.isSubmittingPlanRequest)

  const [requestText, setRequestText] = useState('')
  const [requestSubmitted, setRequestSubmitted] = useState(false)

  const handleSubmit = useCallback(() => {
    trackEvent('Subscription - upgrade sent', { userPlanKey })
    dispatch(Actions.submitPlanUpgradeRequest({ userRequest: requestText }))
    setRequestSubmitted(true)
  }, [requestText, userPlanKey, dispatch])

  useEffect(() => {
    if (requestSubmitted && !isLoading) {
      onClose()
    }
  }, [isLoading, requestSubmitted, onClose])

  useEffect(() => {
    if (!isOpen) {
      setRequestSubmitted(false)
    }
  }, [isOpen])

  return (
    <BaseModal
      open={isOpen}
      variant="alert"
      title={t('pages.accountSettings.billing.requestUpgradePlanTitle')}
      primaryLabel={t('general.send')}
      onPrimaryBtnClick={handleSubmit}
      secondaryLabel={t('general.cancel')}
      onSecondaryBtnClick={onClose}
      handleClose={onClose}
      isLoading={isLoading}
      largerButtons
    >
      <DazzedParagraph16 textAlign="center">
        {t('pages.accountSettings.billing.requestUpgradePlanDescription')}
      </DazzedParagraph16>
      <div className={classes.textareaContainer}>
        <TextArea setValue={setRequestText} style={{ bright: true }} keepErrorContainerWhenInactive={false} />
      </div>
    </BaseModal>
  )
}

export default RequestUpgradePlanModal
