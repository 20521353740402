import TriangleDown from '../icons/TriangleDown'
import TriangleRight from '../icons/TriangleRight'
import { makeStyles } from '@material-ui/core'
import React from 'react'
import { isMobile } from 'utils/mobileUtils'

export default ({
  expand,
  color = 'var(--text-color-3)',
  size,
  leftArrowCustomStyle = {},
  downArrowCustomStyle = {}
}) => {
  const useStyles = ({ isMobile }) =>
    makeStyles({
      leftArrowIcon: {
        position: 'relative',
        height: size,
        width: size,
        top: 2,
        ...leftArrowCustomStyle
      },
      downArrowIcon: {
        position: 'relative',
        height: size,
        width: size,
        top: isMobile ? 6 : 4,
        ...downArrowCustomStyle
      }
    })({ isMobile })
  const classes = useStyles({ isMobile: isMobile() })
  const className = expand ? classes.downArrowIcon : classes.leftArrowIcon

  return expand ? (
    <TriangleDown className={className} fill={color} width={size || 16} height={size || 16} />
  ) : (
    <TriangleRight className={className} fill={color} width={size || 16} height={size || 16} />
  )
}
