import * as React from 'react'

const CrestXGrin = props => {
  return (
    <svg width="167" height="40" viewBox="0 0 167 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.0033 15.278C36.8276 15.3151 35.6839 15.6756 34.6944 16.3212C33.7049 16.9668 32.9067 17.8732 32.3851 18.9435L33.4294 15.8452H26.2031L22.5482 26.5685H29.9964L30.8918 23.9341V23.9473C31.1835 23.1093 31.5708 22.3087 32.0457 21.562C32.5762 20.7178 33.3796 20.0872 34.3181 19.7787C35.2565 19.4702 36.2714 19.5028 37.1887 19.8711L40.5069 15.763C39.7457 15.4417 38.9295 15.2759 38.1051 15.2754H38.0006"
        fill="#1C4588"
      />
      <path
        d="M68.509 15.196C63.6271 15.196 58.9906 16.2746 58.9906 18.766C58.9906 20.8624 64.0918 22.0392 64.0918 23.4386C64.0918 24.1727 62.7473 24.3158 61.784 24.3158C60.2985 24.3158 58.4945 23.7858 57.0482 23.2795L56.2337 25.5986C58.7582 26.6031 62.1886 27.043 64.4233 27.043C70.313 27.043 72.9289 25.3362 72.9289 23.6267C72.9289 21.0055 67.4778 19.9772 67.4778 18.546C67.4778 17.8013 68.7101 17.4461 70.3756 17.4461C71.5847 17.4639 72.7879 17.6195 73.9627 17.9099L74.631 15.8638C73.1299 15.453 70.715 15.196 68.5351 15.196H68.509Z"
        fill="#1C4588"
      />
      <path
        d="M45.697 20.131C45.749 19.9821 45.8128 19.8376 45.8876 19.699C46.2978 18.9795 46.8905 18.3849 47.604 17.977C48.3176 17.5691 49.1258 17.3629 49.9445 17.38C51.2055 17.38 51.9625 17.865 51.9625 18.4587C51.9625 19.3359 50.6311 20.253 47.7072 20.253C47.0354 20.2493 46.3644 20.2086 45.697 20.131ZM37.9616 22.2858C37.9616 24.6579 40.6976 27.0564 46.146 27.0564C49.151 27.1023 52.1367 26.5625 54.9413 25.4662L55.8028 23.012C53.5734 23.8725 51.207 24.3091 48.8219 24.3001C45.8145 24.3001 45.2662 22.9378 45.2662 22.1798C45.2666 22.0091 45.2876 21.8391 45.3289 21.6736C51.0279 22.3043 57.6825 21.0931 57.6825 18.297C57.6825 16.2536 54.4897 15.151 50.4875 15.151C44.4465 15.151 37.9564 17.5364 37.9564 22.2699"
        fill="#1C4588"
      />
      <path
        d="M78.7506 12.5113L77.5288 15.8587H75.6674L74.9208 18.032H76.7769L75.0278 23.1631C74.9118 23.5345 74.8502 23.9212 74.845 24.3107C74.845 25.6624 75.7692 27.0512 79.7426 27.0512C81.4261 27.0231 83.095 26.7299 84.6898 26.1819L85.3608 24.2074H84.0554C83.6038 24.2074 82.7788 23.9874 82.7788 22.8159C82.7788 22.2647 83.0634 21.4907 83.2461 20.9607C83.2879 20.8467 83.7213 19.556 84.2382 18.0188H87.2561L88.0001 15.8587H84.9718C85.4313 14.5124 86.2771 12.2171 86.4807 11.6367L78.7506 12.5113Z"
        fill="#1C4588"
      />
      <path
        d="M0.00260922 20.3776C0.00260922 15.2756 7.47953 10.6667 16.4994 10.6667H16.857C19.9271 10.7064 22.9921 10.9741 26.164 12.2728L25.2659 14.9523C23.4343 14.4388 21.5514 14.1374 19.653 14.0538C14.6745 14.0538 10.3486 16.3596 10.3486 19.6752C10.3486 21.4669 11.915 24.1013 16.1391 24.1013C18.3364 24.0595 20.5121 23.6521 22.5796 22.8954L21.4074 26.3091C11.8628 29.8314 0.0417705 27.8277 0 20.3723"
        fill="#1C4588"
      />
      <g clipPath="url(#clip0_1006_139)">
        <path
          d="M131.284 16.7593C132.67 16.4727 134.038 16.1086 135.384 15.6688C134.58 9.4024 127.945 9.33334 127.945 9.33334C125.213 9.33334 122.969 10.2785 121.212 12.1689C119.514 14.0069 118.665 16.271 118.665 18.9612C118.665 21.6254 119.484 23.841 121.121 25.608C122.878 27.4987 125.226 28.444 128.163 28.444C129.783 28.444 131.247 28.2594 132.556 27.8901C133.866 27.5208 134.813 27.1516 135.399 26.7823L135.408 18.6141C135.408 18.5757 135.4 18.5376 135.383 18.5031C135.366 18.4687 135.342 18.4387 135.312 18.4156C135.282 18.3924 135.247 18.3769 135.21 18.3701C135.173 18.3634 135.134 18.3656 135.098 18.3767C134.053 18.709 131.468 19.4302 128.59 19.8855V21.5542C129.563 21.5724 130.209 21.6604 130.528 21.8182C131.019 22.0734 131.481 22.6096 131.481 23.4271V24.2192C131.483 24.4705 131.435 24.7195 131.34 24.9515C131.245 25.1835 131.106 25.3937 130.93 25.5696C130.49 26.0039 129.784 26.2952 128.656 26.2952C126.571 26.2952 124.853 25.3281 124.229 23.8666C123.479 22.1129 123.575 21.148 123.575 18.8955C123.575 16.1522 123.594 15.2298 124.47 13.7708C125.179 12.5892 126.56 12.0386 127.751 12.0744C127.751 12.0744 129.786 11.892 130.728 13.7428C131.4 15.0669 131.284 16.7593 131.284 16.7593Z"
          fill="#1C4588"
        />
        <path
          d="M150.459 14.5721C151.04 14.5721 151.527 14.3752 151.92 13.9813C152.11 13.7975 152.26 13.5754 152.362 13.329C152.463 13.0827 152.513 12.8176 152.509 12.5504C152.509 11.9574 152.313 11.4603 151.92 11.0593C151.527 10.6583 151.04 10.4579 150.459 10.4581C150.197 10.4538 149.937 10.5051 149.695 10.6089C149.454 10.7126 149.236 10.8665 149.056 11.0608C148.67 11.4618 148.477 11.9588 148.477 12.5519C148.477 13.0974 148.674 13.5704 149.066 13.971C149.459 14.3715 149.923 14.5719 150.459 14.5721Z"
          fill="#1C4588"
        />
        <path
          d="M141.49 15.6954C141.651 15.6098 141.842 15.7468 141.842 15.9318L141.839 18.6717C142.305 18.0215 142.855 17.4379 143.473 16.9362C144.068 16.4532 144.673 16.2117 145.287 16.2117C146.462 16.2117 147.049 16.659 147.049 17.5536C147.049 18.0194 146.874 18.5069 146.523 19.0163C146.172 19.5256 145.804 19.7808 145.418 19.7817C145.237 19.7766 145.059 19.7356 144.893 19.661C144.695 19.5781 144.502 19.4841 144.314 19.3792C143.823 19.1306 143.49 19.0055 143.315 19.0041C142.806 19.0041 142.408 19.1965 142.119 19.5812C141.83 19.966 141.685 20.6414 141.684 21.6074V25.7406C141.685 25.8081 141.689 25.8756 141.697 25.9426V27.9876L137.883 27.9857V17.2866C137.883 17.2866 139.806 16.5942 141.49 15.6954Z"
          fill="#1C4588"
        />
        <path
          d="M148.694 27.9872V26.1556C148.694 26.1423 148.694 26.1298 148.694 26.1165V20.319C148.694 20.2887 148.694 20.2607 148.694 20.2311V17.267C148.694 17.267 150.451 16.6105 152.157 15.7637C152.196 15.7451 152.238 15.7367 152.281 15.7392C152.323 15.7418 152.364 15.7553 152.4 15.7784C152.436 15.8015 152.466 15.8335 152.487 15.8715C152.508 15.9095 152.519 15.9522 152.519 15.9956V19.7662C152.513 19.9254 152.51 20.076 152.51 20.2119V26.0895C152.51 26.1302 152.513 26.1708 152.519 26.211V27.9872H148.694Z"
          fill="#1C4588"
        />
        <path
          d="M162.255 27.9795V26.207C162.258 26.1616 162.26 26.1147 162.26 26.0644V20.7754C162.26 20.7082 162.258 20.6432 162.255 20.579L162.227 20.2614C162.171 19.8394 162.043 19.4908 161.84 19.2186C161.559 18.8432 161.191 18.6554 160.735 18.6551C160.439 18.6517 160.146 18.7017 159.867 18.8028C159.561 18.9243 159.269 19.082 158.999 19.2725C158.858 19.3621 158.788 19.5231 158.788 19.7555V26.0626C158.788 26.1106 158.788 26.1553 158.793 26.1985V27.9883H154.976V17.297C156.788 16.6024 157.995 16.02 158.558 15.7327C158.596 15.7135 158.638 15.7046 158.681 15.7068C158.724 15.709 158.765 15.7222 158.801 15.7452C158.837 15.7683 158.867 15.8003 158.888 15.8384C158.909 15.8764 158.919 15.9193 158.919 15.9628V18.0916C159.585 17.4653 160.208 17.0001 160.787 16.6958C161.365 16.3915 162.032 16.2394 162.785 16.2394C163.75 16.2394 164.539 16.5883 165.152 17.2862C165.764 17.9842 166.071 18.9236 166.072 20.1045V26.0626C166.072 26.0995 166.072 26.1346 166.075 26.1686V27.9861H162.254"
          fill="#1C4588"
        />
      </g>
      <path
        d="M108.276 11.9893H107.156L103.385 17.552L99.6146 11.9893H98.4946L102.713 18.2613L98.2519 25H99.3532L103.385 18.952L107.417 25H108.519L104.057 18.2613L108.276 11.9893Z"
        fill="#1C4588"
      />
      <defs>
        <clipPath id="clip0_1006_139">
          <rect width="47.41" height="19.111" fill="white" transform="translate(118.667 9.33334)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CrestXGrin
