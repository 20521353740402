import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import BeforeAfterPoseSelection from './BeforeAfterPoseSelection'
import BeforeAfterLoader from 'resources/beforeAfter/before-after-loader.gif'
import BeforeAfterErrorState from './BeforeAfterErrorState'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'utils/mobileUtils'
import TextAreaAutosize from 'components/common/TextareaAutosize'

const useStyles = makeStyles(
  {
    messageInput: {
      fontSize: '12px!important',
      border: '0.5px solid var(--border-color-7)!important',
      borderRadius: 8,
      fontWeight: 500,
      padding: '12px!important',
      lineHeight: '21px'
    },
    messageLabel: {
      textTransform: 'capitalize',
      letterSpacing: 'initial',
      fontSize: '13px!important',
      fontWeight: 500,
      color: 'var(--text-color-46)',
      opacity: 1
    },
    assetContainer: {
      padding: '10px 40px',
      marginLeft: 10,
      borderLeft: isMobile ? 'none' : '0.75px solid var(--border-color-7)'
    },
    assetPlaceholderContainer: {
      padding: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    assetPlaceholder: {
      objectFit: 'none',
      width: '222px',
      height: '380px',
      border: '1px solid var(--border-color-7)',
      borderRadius: '10px'
    },
    assetImg: {
      boxShadow: '0px 3.82201px 13.377px rgba(0, 0, 0, 0.2)',
      borderRadius: 8,
      width: 222,
      height: 380
    }
  },
  { isMobile }
)

const BeforeAfterAsset = ({ scanId, setSelectedPose, assetStatus, error, onRetry, imgSrc, message, setMessage }) => {
  const classes = useStyles({ isMobile: isMobile() })
  const { t } = useTranslation()

  return (
    <Grid container direction="column" className={classes.assetContainer}>
      <Grid item>
        <Grid container wrap="nowrap">
          {!isMobile() && (
            <Grid item xs={2}>
              <BeforeAfterPoseSelection scanId={scanId} onPoseSelected={pose => setSelectedPose(pose)} />
            </Grid>
          )}
          <Grid item xs={10} className={classes.assetPlaceholderContainer}>
            {assetStatus === 'loading' ? (
              <img src={BeforeAfterLoader} alt="before-after-loader" className={classes.assetPlaceholder} />
            ) : assetStatus === 'error' ? (
              <BeforeAfterErrorState
                onRetry={error === 'unexpectedError' ? onRetry : null}
                errorMessage={t(`dialogs.beforeAfter.errors.${error}`)}
              />
            ) : (
              <Grid container direction="column" alignItems="center" spacing={2} justifyContent="center">
                <Grid item>
                  <img className={classes.assetImg} src={imgSrc} alt="before-after-asset" />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {!isMobile() && (
        <Grid item>
          <TextAreaAutosize
            title={t('dialogs.beforeAfter.messageInputLabel')}
            className={classes.messageInput}
            value={message}
            setValue={setMessage}
            style={{ bright: true, thick: true }}
            rowsMin={3}
            rowsMax={3}
            labelClassName={classes.messageLabel}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default BeforeAfterAsset
