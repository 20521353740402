import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import WithAligners from 'components/common/icons/timelineV2/scan-feed/WithAligners'
import WithoutAligners from 'components/common/icons/timelineV2/scan-feed/WithoutAligners'
import ScanReferralNote from './ScanReferralNote'
import Attachments from 'components/Patients/ShareScan/Attachments'

const useStyles = makeStyles(theme => ({
  notificationDestinationLabel: {
    marginTop: 12
  },
  sharedByLabel: {
    marginTop: 40
  },
  alignersIcon: {
    lineHeight: 0.8,
    marginRight: 8
  },
  verticalSpacing: {
    marginBottom: 24
  },
  attachmentsLabel: {
    marginBottom: 16
  }
}))

const ScanReferralItem = ({
  referralName,
  referralSpeciality,
  notificationDestination,
  grinDoctorNote,
  referralReview,
  attachments = [],
  withAligner,
  createdAt,
  reviewSubmissionDate,
  createdBy = {}
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div>
      <Grid container>
        <Grid item xs={4}>
          <DazzedParagraph14 strong>
            {referralName} <div style={{ fontWeight: 400 }}>{referralSpeciality}</div>
          </DazzedParagraph14>
          <DazzedParagraph12 className={classes.notificationDestinationLabel} thickness="strong">
            {notificationDestination}
          </DazzedParagraph12>
          <DazzedParagraph12 className={classes.sharedByLabel} thickness="strong">
            {t('pages.patients.selectedPatient.timelineV2.scanFeed.referrals.sharedBy')}
          </DazzedParagraph12>
          <DazzedParagraph14 strong>
            {createdBy.name}
            <div style={{ fontWeight: 400 }}>{createdBy.roleDescription}</div>
          </DazzedParagraph14>
        </Grid>
        <Grid item xs={8}>
          {withAligner !== null && (
            <Grid container alignItems="center" className={classes.verticalSpacing}>
              <Grid item className={classes.alignersIcon}>
                {withAligner ? (
                  <WithAligners color="rgba(4, 19, 51, 1)" />
                ) : (
                  <WithoutAligners color="rgba(4, 19, 51, 1)" />
                )}
              </Grid>
              <Grid item>
                <DazzedParagraph14 strong thickness="strong">
                  {withAligner ? t('general.withAligners') : t('general.withoutAligners')}
                </DazzedParagraph14>
              </Grid>
            </Grid>
          )}
          <div item className={classes.verticalSpacing}>
            <ScanReferralNote
              title={t('pages.patients.selectedPatient.timelineV2.scanFeed.referrals.yourNote')}
              note={grinDoctorNote}
              date={createdAt}
            />
          </div>
          <div>
            {referralReview ? (
              <ScanReferralNote
                title={t('pages.patients.selectedPatient.timelineV2.scanFeed.referrals.colleaguesResponse')}
                note={referralReview}
                date={reviewSubmissionDate}
              />
            ) : (
              <DazzedParagraph12 thickness="strong">
                {t('pages.patients.selectedPatient.timelineV2.scanFeed.referrals.notRespondFromColleague')}
              </DazzedParagraph12>
            )}
          </div>
        </Grid>
      </Grid>
      {!!attachments?.length && (
        <div>
          <DazzedParagraph14 bold className={classes.attachmentsLabel}>
            {t('dialogs.viewSharedScan.attachments')}
          </DazzedParagraph14>
          <Attachments files={attachments} editable={false} />
        </div>
      )}
    </div>
  )
}

export default ScanReferralItem
