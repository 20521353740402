import moment from 'moment'
import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { LeadInfo } from 'components/common/icons'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import DolphinIcon from 'components/common/icons/DolphinIcon'

const useStyles = makeStyles(() => ({
  patientsContainer: {
    maxHeight: 500,
    overflowX: 'hidden',
    overflowY: 'scroll',
    maskImage: 'linear-gradient(to bottom, black calc(100% - 48px), transparent 100%)',
    marginTop: -15
  },
  nameAndEmailContainer: {
    padding: '0px 0px 0px 4px !important'
  },
  divider: {
    borderBottom: '1px solid var(--bg-color-51)',
    paddingTop: 12
  },
  patient: {
    paddingTop: 15,
    paddingRight: 10
  },
  email: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 180
  }
}))

const PatientsToInviteTable = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { latestPatientsToInvite } = useSelector(state => state.pmsReducer)

  const handleClickInvitePatient = useCallback(
    patientToInvite => {
      dispatch(
        actions.setInvitePatientPresetDetails({
          id: patientToInvite.id,
          email: patientToInvite.email || '',
          firstName: patientToInvite.firstName,
          lastName: patientToInvite.lastName
        })
      )
      dispatch(actions.requestOpenInvitePatientModal(true))
      trackEvent('Invite patient - modal opened', {
        source: 'New dolphin patients modal'
      })
    },
    [dispatch]
  )

  return latestPatientsToInvite.length > 0 ? (
    <Grid container direction="column">
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <DolphinIcon />
          </Grid>
          <Grid item>
            <DazzedParagraph14 bold>{t('pms.invitePatientsModal.dolphin.listTitle')}</DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ paddingBottom: -15 }}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <DazzedParagraph14 color="var(--text-color-14)">
              {t('pms.invitePatientsModal.dolphin.patientsTable.header.patientName')}
            </DazzedParagraph14>
          </Grid>
          <Grid item xs={2}>
            <DazzedParagraph14 color="var(--text-color-14)">
              {t('pms.invitePatientsModal.dolphin.patientsTable.header.dolphinId')}
            </DazzedParagraph14>
          </Grid>
          <Grid item xs={6}>
            <DazzedParagraph14 color="var(--text-color-14)">
              {t('pms.invitePatientsModal.dolphin.patientsTable.header.birthdate')}
            </DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.patientsContainer}>
        {latestPatientsToInvite.map(currPatient => (
          <div key={currPatient.id}>
            <Grid container alignItems="center" spacing={4} className={classes.patient}>
              <Grid item xs={4} style={{ paddingRight: 0 }}>
                <Grid container spacing={1} wrap="nowrap">
                  <Grid item>
                    <LeadInfo color="#9298A6" />
                  </Grid>
                  <Grid item className={classes.nameAndEmailContainer}>
                    <Grid container direction="column">
                      <Grid item>
                        <DazzedParagraph14>{`${currPatient.firstName} ${currPatient.lastName}`}</DazzedParagraph14>
                      </Grid>
                      <Grid item>
                        <DazzedParagraph14 color="var(--text-color-30)" className={classes.email}>
                          {currPatient.email ?? '-'}
                        </DazzedParagraph14>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                {currPatient.displayId}
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <DazzedParagraph14>{moment(currPatient.birthDate).format('MMM Do, YYYY')}</DazzedParagraph14>
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      label={t('pms.invitePatientsModal.dolphin.patientsTable.inviteButtonLabel')}
                      width="150px"
                      onClick={() => handleClickInvitePatient(currPatient)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.divider} />
          </div>
        ))}
      </Grid>
    </Grid>
  ) : (
    <DazzedParagraph14 textAlign="center" className={classes.description}>
      {t('pms.invitePatientsModal.dolphin.doneMessage')}
    </DazzedParagraph14>
  )
}

export default PatientsToInviteTable
