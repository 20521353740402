import React from 'react'
import { makeStyles } from '@material-ui/styles'
import StripeIcon from '../../icons/Stripe'
import DazzedParagraph12 from '../../text/DazzedParagraph12'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  poweredBy: {
    display: 'flex',
    alignItems: 'center',
    color: 'var(--text-color-11)'
  }
}))

const PoweredByStripe = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <DazzedParagraph12 className={classes.poweredBy}>
      {t('creditCard.poweredBy')}&nbsp;&nbsp;
      <StripeIcon />
    </DazzedParagraph12>
  )
}

export default PoweredByStripe
