import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import ColorLegendIcon from 'components/common/icons/ColorLegendIcon'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { LayerDisplayed, LayerHidden } from 'components/common/icons'

const useStyles = makeStyles(theme => ({
  layerToggle: {
    cursor: 'pointer',
    paddingBottom: '0px !important'
  },
  labelContainer: {
    userSelect: 'none'
  },
  label: {
    fontSize: ({ fontSize }) => fontSize || '16px'
  },
  legendIcon: {
    paddingBottom: '8px !important'
  }
}))

const PreventiveLegendItem = ({
  toggleable = false,
  layerType,
  color,
  label,
  visible,
  onClick = () => {},
  textAlign,
  disabled = false,
  fontSize = 16
}) => {
  const classes = useStyles({ fontSize })

  const handleClick = useCallback(
    () => !disabled && onClick(layerType, label, visible),
    [disabled, label, layerType, onClick, visible]
  )
  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      {toggleable && (
        <Grid item className={classes.layerToggle} onClick={handleClick}>
          <Grid item>{visible ? <LayerDisplayed /> : <LayerHidden />}</Grid>
        </Grid>
      )}
      <Grid item className={classes.legendIcon}>
        <ColorLegendIcon color={color} />
      </Grid>
      <Grid item className={classes.labelContainer}>
        <DazzedParagraph16 textAlign={textAlign} strong className={classes.label}>
          {label}
        </DazzedParagraph16>
      </Grid>
    </Grid>
  )
}

export default PreventiveLegendItem
