import * as React from 'react'

function NotifyPractice(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 7H14V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V7Z" stroke="#001335" />
      <path d="M2 5C2 4.44772 2.44772 4 3 4H13C13.5523 4 14 4.44772 14 5V7H2V5Z" stroke="#001335" />
      <rect x="9" y="9" width="3" height="3" fill="#001335" />
      <path d="M4 3C4 2.44772 4.44772 2 5 2C5.55228 2 6 2.44772 6 3V4H4V3Z" fill="#001335" />
      <path d="M10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3V4H10V3Z" fill="#001335" />
    </svg>
  )
}

export default NotifyPractice
