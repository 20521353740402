import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase, Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 5px',
    height: '100%'
  },
  buttonText: {
    color: 'var(--text-color-25)'
  },
  icon: {
    margin: '3px 5px 0 5px'
  }
}))

const ScanActionButton = ({
  icon = <></>,
  label,
  onClick = () => {},
  elementId = 'share-scan-button',
  className = '',
  labelClassName = ''
}) => {
  const classes = useStyles()

  return (
    <ButtonBase onClick={onClick} className={[classes.root, className].join(' ')} id={elementId}>
      <Grid container alignItems="center" className={classes.buttonText}>
        <Grid item className={classes.icon}>
          {icon}
        </Grid>
        <DazzedParagraph12 className={[classes.buttonText, labelClassName].join(' ')}>{label}</DazzedParagraph12>
      </Grid>
    </ButtonBase>
  )
}

export default ScanActionButton
