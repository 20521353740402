import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  titleInput: {
    marginBottom: 20,
    backgroundColor: '#fff',
    '& input': {
      fontSize: 40,
      textAlign: 'center',
      fontFamily: 'ogg'
    }
  }
}))

const SlideTitleInput = ({ placeholder, errorMessage, onChange, value, className }) => {
  const classes = useStyles()

  return (
    <TextField
      className={[classes.titleInput, className].join(' ')}
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
      error={!!errorMessage}
      helperText={errorMessage}
      variant="outlined"
    />
  )
}

export default SlideTitleInput
