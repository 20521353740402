import * as React from 'react'

export default () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.188 5.436V7.536H7.148V12.604H5.048V7.536H0.00800006V5.436H5.048V0.395998H7.148V5.436H12.188Z"
        fill="#3C52EF"
      />
    </svg>
  )
}
