import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { Chevron2 } from 'components/common/icons'

const useStyles = makeStyles(theme => ({
  arrowBackContainer: {
    cursor: 'pointer',
    left: '5%',
    top: '7%',
    position: 'absolute',
    '@media (max-width: 750px)': {
      position: 'relative',
      width: '100%',
      marginTop: 20,
      marginBottom: 20,
      left: 0,
      top: 0
    }
  }
}))

const BackButton = () => {
  const classes = useStyles()
  const history = useHistory()

  const handleClickBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <div className={classes.arrowBackContainer} onClick={handleClickBack}>
      <Chevron2 />
    </div>
  )
}

export default BackButton
