const FETCH_LEADS = 'FETCH_LEADS'
const FETCH_LEADS_RECEIVED = 'FETCH_LEADS_RECEIVED'
const FETCH_LEADS_FAILED = 'FETCH_LEADS_FAILED'
const UPDATE_RC_LEAD = 'UPDATE_RC_LEAD'
const UPDATE_RC_LEAD_SUCCEEDED = 'UPDATE_RC_LEAD_SUCCEEDED'
const UPDATE_RC_LEAD_FAILED = 'UPDATE_RC_LEAD_FAILED'
const APPROVE_RC_LEAD = 'APPROVE_RC_LEAD'
const APPROVE_RC_LEAD_SUCCEEDED = 'APPROVE_RC_LEAD_SUCCEEDED'
const APPROVE_RC_LEAD_FAILED = 'APPROVE_RC_LEAD_FAILED'
const NEW_LEAD_RECEIVED = 'NEW_LEAD_RECEIVED'
const NEW_LEAD_LIVE_UPDATE_FAILED = 'NEW_LEAD_LIVE_UPDATE_FAILED'
const RESET_RC_SHIPPING_DETAILS_DIALOG = 'RESET_RC_SHIPPING_DETAILS_DIALOG'
const CREATE_RC_SHIPBOB_ORDER = 'CREATE_RC_SHIPBOB_ORDER'
const CREATE_RC_SHIPBOB_ORDER_RECEIVED = 'CREATE_RC_SHIPBOB_ORDER_RECEIVED'
const CREATE_RC_SHIPBOB_ORDER_FAILED = 'CREATE_RC_SHIPBOB_ORDER_FAILED'
const SIMULATE_ORDER_SHIPMENT = 'SIMULATE_ORDER_SHIPMENT'
const SIMULATE_ORDER_SHIPMENT_RECEIVED = 'SIMULATE_ORDER_SHIPMENT_RECEIVED'
const SIMULATE_ORDER_SHIPMENT_FAILED = 'SIMULATE_ORDER_SHIPMENT_FAILED'
const FETCH_RC_SHIPPING_CONFIGURATION = 'FETCH_RC_SHIPPING_CONFIGURATION'
const FETCH_RC_SHIPPING_CONFIGURATION_RECEIVED = 'FETCH_RC_SHIPPING_CONFIGURATION_RECEIVED'
const FETCH_RC_SHIPPING_CONFIGURATION_FAILED = 'FETCH_RC_SHIPPING_CONFIGURATION_FAILED'
const RELOAD_LEAD_DATA = 'RELOAD_LEAD_DATA'
const RELOAD_LEAD_DATA_RECEIVED = 'RELOAD_LEAD_DATA_RECEIVED'
const RELOAD_LEAD_DATA_FAILED = 'RELOAD_LEAD_DATA_FAILED'
const RESOLVE_LEAD = 'RESOLVE_LEAD'

const fetchLeads = payload => ({
  type: FETCH_LEADS,
  payload
})

const fetchLeadsRecevied = payload => ({
  type: FETCH_LEADS_RECEIVED,
  payload
})

const fetchLeadsFailed = payload => ({
  type: FETCH_LEADS_FAILED,
  payload
})

const updateRCLead = payload => ({
  type: UPDATE_RC_LEAD,
  payload
})

const updateRCLeadSucceeded = payload => ({
  type: UPDATE_RC_LEAD_SUCCEEDED,
  payload
})

const updateRCLeadFailed = payload => ({
  type: UPDATE_RC_LEAD_FAILED,
  payload
})

const approveRCLead = payload => ({
  type: APPROVE_RC_LEAD,
  payload
})

const approveRCLeadSucceeded = payload => ({
  type: APPROVE_RC_LEAD_SUCCEEDED,
  payload
})

const approveRCLeadFailed = payload => ({
  type: APPROVE_RC_LEAD_FAILED,
  payload
})

const newLeadReceived = payload => ({
  type: NEW_LEAD_RECEIVED,
  payload
})

const newLeadLiveUpdateFailed = payload => ({
  type: NEW_LEAD_LIVE_UPDATE_FAILED,
  payload
})

const resetRcShippingDetailsDialog = payload => ({
  type: RESET_RC_SHIPPING_DETAILS_DIALOG,
  payload
})

const createRcShipbobOrder = payload => ({
  type: CREATE_RC_SHIPBOB_ORDER,
  payload
})

const createRcShipbobOrderReceived = payload => ({
  type: CREATE_RC_SHIPBOB_ORDER_RECEIVED,
  payload
})

const createRcShipbobOrderFailed = payload => ({
  type: CREATE_RC_SHIPBOB_ORDER_FAILED,
  payload
})

const simulateOrderShipment = payload => ({
  type: SIMULATE_ORDER_SHIPMENT,
  payload
})

const simulateOrderShipmentFailed = payload => ({
  type: SIMULATE_ORDER_SHIPMENT_FAILED,
  payload
})

const simulateOrderShipmentReceived = payload => ({
  type: SIMULATE_ORDER_SHIPMENT_RECEIVED,
  payload
})

const fetchRcShippingConfiguration = payload => ({
  type: FETCH_RC_SHIPPING_CONFIGURATION,
  payload
})

const fetchRcShippingConfigurationFailed = payload => ({
  type: FETCH_RC_SHIPPING_CONFIGURATION_FAILED,
  payload
})

const fetchRcShippingConfigurationReceived = payload => ({
  type: FETCH_RC_SHIPPING_CONFIGURATION_RECEIVED,
  payload
})

const reloadLeadData = payload => ({
  type: RELOAD_LEAD_DATA,
  payload
})

const reloadLeadDataReceived = payload => ({
  type: RELOAD_LEAD_DATA_RECEIVED,
  payload
})

const reloadLeadDataFailed = payload => ({
  type: RELOAD_LEAD_DATA_FAILED,
  payload
})

const resolveLead = payload => ({
  type: RESOLVE_LEAD,
  payload
})

export default {
  FETCH_LEADS,
  FETCH_LEADS_RECEIVED,
  FETCH_LEADS_FAILED,
  UPDATE_RC_LEAD,
  APPROVE_RC_LEAD,
  APPROVE_RC_LEAD_SUCCEEDED,
  APPROVE_RC_LEAD_FAILED,
  UPDATE_RC_LEAD_SUCCEEDED,
  UPDATE_RC_LEAD_FAILED,
  NEW_LEAD_RECEIVED,
  NEW_LEAD_LIVE_UPDATE_FAILED,
  RESET_RC_SHIPPING_DETAILS_DIALOG,
  CREATE_RC_SHIPBOB_ORDER,
  CREATE_RC_SHIPBOB_ORDER_FAILED,
  CREATE_RC_SHIPBOB_ORDER_RECEIVED,
  SIMULATE_ORDER_SHIPMENT,
  SIMULATE_ORDER_SHIPMENT_FAILED,
  SIMULATE_ORDER_SHIPMENT_RECEIVED,
  FETCH_RC_SHIPPING_CONFIGURATION,
  FETCH_RC_SHIPPING_CONFIGURATION_FAILED,
  FETCH_RC_SHIPPING_CONFIGURATION_RECEIVED,
  RELOAD_LEAD_DATA,
  RELOAD_LEAD_DATA_FAILED,
  RELOAD_LEAD_DATA_RECEIVED,
  RESOLVE_LEAD,
  fetchLeads,
  fetchLeadsRecevied,
  fetchLeadsFailed,
  updateRCLead,
  approveRCLead,
  approveRCLeadSucceeded,
  approveRCLeadFailed,
  updateRCLeadSucceeded,
  updateRCLeadFailed,
  newLeadReceived,
  newLeadLiveUpdateFailed,
  resetRcShippingDetailsDialog,
  createRcShipbobOrder,
  createRcShipbobOrderReceived,
  createRcShipbobOrderFailed,
  simulateOrderShipment,
  simulateOrderShipmentReceived,
  simulateOrderShipmentFailed,
  fetchRcShippingConfiguration,
  fetchRcShippingConfigurationReceived,
  fetchRcShippingConfigurationFailed,
  reloadLeadData,
  reloadLeadDataFailed,
  reloadLeadDataReceived,
  resolveLead
}
