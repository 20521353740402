import { makeStyles, Grid, CircularProgress, ButtonBase } from '@material-ui/core'
import React, { useCallback, useState, useRef, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useDebounce } from 'use-debounce'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from 'components/common/icons/Close'
import SearchIcon from '../../components/common/icons/Search'
import { useTranslation } from 'react-i18next'
import Actions from 'actions'

const usePatientsSearchStyles = makeStyles({
  container: {
    boxShadow: !isMobile && '0px 2px 30px rgba(0, 0, 0, 0.1)',
    borderRadius: '28px',
    padding: '0px 0px 0px 10px',
    '& path': {
      stroke: '#C4C4C4'
    },
    '&:hover': {
      cursor: 'pointer'
    },
    backgroundColor: '#FFFFFF',
    border: isMobile ? '1px solid #C4C4C4' : '1px solid #FFFFFF',
    transition: 'border-color .1s',
    '&:focus-within': {
      border: '1px solid #3C52EF'
    },
    maxWidth: isMobile ? '95%' : '98%',
    minWidth: isMobile ? '100px' : '300px'
  },
  serachIconContainer: {
    textAlign: isMobile ? 'center' : 'unset',
    paddingTop: isMobile ? 2 : 'unset'
  },
  searchInput: {
    display: 'flex',
    width: '245px',
    fontFamily: 'Dazzed',
    height: isMobile ? 40 : 45,
    fontSize: isMobile ? 16 : 14,
    paddingLeft: 5,
    background: 'rgba(0, 0, 0, 0)',
    border: 'none',
    color: '#898484',
    '&:focus': {
      outline: 'none'
    },
    '::placeholder': {
      color: '#898484'
    }
  },
  filledSearchInput: {
    width: '190px'
  }
})

const PatientsSearch = ({ onNameFilter = () => {} }) => {
  const classes = usePatientsSearchStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const wrapperRef = useRef(null)

  const [filter, setFilter] = useState('')
  const [isStartWriting, setIsStartWriting] = useState(false)
  const [debouncedFilter] = useDebounce(filter, 300)

  const isLoading = useSelector(state => state.patientsReducer.search.isLoading)
  const { name: presetName } = useSelector(state => state.patientsReducer.presetFilter)

  const handleFinishSearch = useCallback(() => {
    setFilter('')
  }, [])

  useEffect(() => {
    setIsStartWriting(false)
    onNameFilter(debouncedFilter)
  }, [dispatch, debouncedFilter, onNameFilter])

  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleFinishSearch()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleFinishSearch, wrapperRef])

  useEffect(() => {
    if (presetName) {
      setFilter(presetName)
      dispatch(Actions.setPatientsPresetFilter({ name: '' }))
    }
  }, [dispatch, presetName])

  return (
    <Grid container alignItems="center" justifyContent={'space-between'} className={classes.container}>
      <Grid item xs={10}>
        <Grid container alignItems="center">
          <Grid item xs={2} className={classes.serachIconContainer}>
            <SearchIcon width={isMobile ? 13 : 32} height={isMobile ? 13 : 32} />
          </Grid>
          <Grid item xs={10}>
            <input
              className={[classes.searchInput, filter && classes.filledSearchInput].join(' ')}
              placeholder={t('pages.patients.patientsList.patientsHeader.patientsSearchPlaceholder')}
              type="text"
              name="patients-search"
              value={filter}
              autoComplete="off"
              onChange={({ target }) => {
                setFilter(target.value)
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        {filter && (
          <>
            {isStartWriting || isLoading ? (
              <CircularProgress size={14} />
            ) : (
              <ButtonBase
                onClick={() => {
                  handleFinishSearch()
                }}
              >
                <CloseIcon />
              </ButtonBase>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default PatientsSearch
