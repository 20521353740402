import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import CreateBroadcast from './CreateBroadcast'
import SelectPatientsToBroadcast from './SelectPatientsToBroadcast'
import useBroadcast from 'hooks/useBroadcast'
import Actions from 'actions'
import { isMobile } from 'utils/mobileUtils'

const useStyles = () =>
  makeStyles({
    container: {
      borderBottom: '1px solid var(--border-color-3)'
    },
    subtitle: {
      fontSize: 11
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: 9
    },
    buttonContainer: {
      paddingLeft: 15
    }
  })()

const Broadcast = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { closeBroadcastMode } = useBroadcast()

  const { isModeOn } = useSelector(state => state.chatReducer.broadcast)

  useEffect(() => {
    if (!isMobile()) {
      dispatch(Actions.toggleBroadcastMode(false))
    }
    return () => {
      if (!isMobile()) {
        closeBroadcastMode('Page changed')
      }
    }
  }, [dispatch]) // eslint-disable-line

  return <div className={classes.container}>{isModeOn ? <SelectPatientsToBroadcast /> : <CreateBroadcast />}</div>
}

export default Broadcast
