import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import _ from 'lodash'
import { ORTHO_TEETH_LIST } from 'consts/teethConsts'
import { sortTeeth } from 'utils/giUtils'

const useStyles = makeStyles(() => ({
  row: {
    margin: '0 10px 20px 0'
  }
}))

const TeethSelectionGrid = ({
  selections = [],
  setSelections = (selections = []) => {},
  editable = true,
  toothGridItemComponent,
  toothGridItemComponentProps = {},
  gridRowClassName = ''
}) => {
  const classes = useStyles()

  const matrix = useMemo(() => {
    const [UL, UR, LL, LR] = _.chunk(ORTHO_TEETH_LIST, 8)
    return [
      [UL, UR],
      [LL, LR]
    ]
  }, [])

  const handleClick = useCallback(
    tooth => {
      if (selections.includes(tooth)) {
        setSelections(sortTeeth(selections.filter(t => t !== tooth)))
      } else {
        setSelections(sortTeeth([...selections, tooth]))
      }
    },
    [setSelections, selections]
  )

  return (
    <Grid container direction="column">
      {matrix.map((row, rowI) => (
        <Grid item key={`teeth-selection-row-${rowI}`} className={[classes.row, gridRowClassName].join(' ')}>
          <Grid container spacing={1} direction="row">
            {row.map((section, sectionI) => (
              <Grid item key={`teeth-selection-section-${sectionI}`} style={{ marginRight: 10 }}>
                <Grid container spacing={1}>
                  {section.map(tooth => (
                    <Grid item key={`teeth-selection-tooth-${tooth}`}>
                      {toothGridItemComponent &&
                        React.createElement(toothGridItemComponent, {
                          ...toothGridItemComponentProps,
                          tooth,
                          isSelected: selections.includes(tooth),
                          onClick: () => handleClick(tooth),
                          editable
                        })}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default TeethSelectionGrid
