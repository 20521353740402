import * as React from 'react'

export default ({ color = 'white', ...props }) => {
  return (
    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87455 1.16669C7.1837 0.857524 7.68498 0.857524 7.99413 1.16669C8.30328 1.47586 8.30328 1.97711 7.99413 2.28628L3.34016 6.94022C3.03101 7.24937 3.03101 7.75065 3.34016 8.0598L7.99413 12.7138C8.30328 13.0229 8.30328 13.5242 7.99413 13.8333C7.68498 14.1425 7.1837 14.1425 6.87455 13.8333L1.10102 8.0598C0.791848 7.75065 0.791848 7.24937 1.10102 6.94022L6.87455 1.16669Z"
        fill={color}
      />
    </svg>
  )
}
