import Actions from 'actions'
import { API, graphqlOperation } from 'aws-amplify'
import { REMOTE_MONITORING_PROGRAM } from 'consts/appConsts'
import { TEMPLATES_TYPES } from 'consts/templatesConsts'
import { uniqBy } from 'lodash-es'
import momenttz from 'moment-timezone'
import { ofType } from 'redux-observable'
import { concat, empty, forkJoin, from, of } from 'rxjs'
import { catchError, filter, map, mapTo, mergeMap, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { evaluateRolePermissions } from 'utils/authUtils'
import config from 'utils/awsUtils'
import { fetchAll, getEntityIdAndVersion } from 'utils/graphqlUtils'
import {
  mapToCombinedDoctorPracticeEntity,
  mapToCreateDoctorInput,
  mapToUpdateDoctorInput
} from 'utils/mappers/doctorsMappers'
import { buildReadersFilter } from 'utils/searchUtils'
import { getDoctorId, setBillingInfoRequired, setDoctorId } from 'utils/storageUtils'
import { filterCustomTemplates } from 'utils/templatesUtils'
import { termsVersion } from '../components/Auth/TermsDoc'
import {
  createMessageTemplate,
  deleteMessageTemplate,
  getDoctor,
  getTemplatesByTypeAndByDoctorId,
  getWelcomeMessagesByDoctorId,
  updateMessageTemplate,
  updateDoctorTag,
  getMessagingPreferencesVersion,
  searchTagsForFetchDoctorTags
} from '../graphql/customQueries'
import { updateBillingInfo, updateGrinUser, updateMessagingPreferences, updateDoctor } from '../graphql/mutations'
import { doctorPlansByEmail, getFeatureFlags, getGrinUser, patientsByDoctorIdByPlan } from '../graphql/queries'
import i18n from '../resources/locales/i18n'
import { logInfo } from 'utils/logUtils'

export const loadDoctorAfterSignInEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SIGNIN_SIDE_EFFECTS_COMPLETED),
    pluck('payload'),
    tap(({ doctor, requireBillingInfo }) => {
      setDoctorId(doctor?.id)
      setBillingInfoRequired(requireBillingInfo)
    }),
    map(({ doctor }) => mapToCombinedDoctorPracticeEntity(doctor)),
    map(doctor => Actions.doctorDetailsReceived(doctor))
  )

export const loadDoctorDetailsTrigger = action$ =>
  action$.pipe(
    ofType(Actions.USER_AUTH_COMPLETED),
    tap(() => logInfo(`USER_AUTH_COMPLETED: requesting doctor details. doctor id: ${getDoctorId()}`)),
    map(() => Actions.requestDoctorDetails())
  )

export const requestDoctorDetailsEpic = action$ =>
  action$.pipe(
    ofType(Actions.DOCTOR_DETAILS_REQUESTED),
    map(() => getDoctorId()),
    filter(id => id),
    switchMap(id =>
      from(
        API.graphql(
          graphqlOperation(getDoctor, {
            id
          })
        )
      ).pipe(
        map(({ data }) => data?.getDoctor),
        map(mapToCombinedDoctorPracticeEntity),
        mergeMap(response => of(Actions.doctorDetailsReceived(response))),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const requestDoctorDetailsReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DOCTOR_DETAILS_RECEIVED),
    pluck('payload'),
    mergeMap(({ id }) => {
      const featureFlags = JSON.parse(state$.value.profileReducer.doctor.user?.featureFlags?.flags ?? {})
      const { isWelcomePopupShowed } = featureFlags
      return concat(
        of(Actions.fetchCustomTemplates()),
        of(Actions.fetchSavedFiles()),
        of(Actions.fetchSavedViews()),
        isWelcomePopupShowed ? of(Actions.fetchAnnouncements(id)) : empty(),
        of(Actions.fetchDoctorTags())
      )
    })
  )

export const createDoctorEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_DOCTOR_REQUESTED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      doctorDto: mapToCreateDoctorInput([action, state]),
      billingInfo: {
        paymentMethodId: state.authReducer.billingInfo.paymentMethodId
      },
      practiceCountry: state.authReducer.country
    })),
    switchMap(({ doctorDto, billingInfo, practiceCountry }) =>
      from(
        API.post('grinServerlessApi', '/accounts/v2/doctors', {
          body: {
            doctorDto,
            billingInfo,
            timezone: momenttz.tz.guess(),
            termsVersion: termsVersion(practiceCountry)
          }
        })
      ).pipe(
        mergeMap(response => of(Actions.createDoctorReceived(response))),
        catchError(error => of(Actions.createDoctorFailed(error)))
      )
    )
  )

export const createDoctorFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_DOCTOR_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const prepareOrthoConfigurationForSavingEpic = action$ =>
  action$.pipe(
    ofType(Actions.PREPARE_ORTHO_CONFIGURATION_FOR_SAVING),
    pluck('payload'),
    switchMap(({ allowedZipcodes, metricType, searchRadius, country, ...config }) =>
      from(
        API.get(
          'grinApi',
          `/accounts/doctors/areaOfCoverage?zipcodes=${allowedZipcodes}&metricType=${metricType}&searchRadius=${searchRadius}&country=${country}`
        )
      ).pipe(
        map(response => response.areaOfCoverage),
        mergeMap(areaOfCoverage =>
          of(
            Actions.requestDoctorDetailsUpdate({
              areaOfCoverage,
              allowedZipcodes,
              metricType,
              searchRadius,
              ...config
            })
          )
        ),
        catchError(() =>
          of(
            Actions.prepareOrthoConfigurationForSavingFailed({
              allowedZipcodes,
              metricType,
              searchRadius,
              ...config
            })
          )
        )
      )
    )
  )

export const prepareOrthoConfigurationForSavingFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.PREPARE_ORTHO_CONFIGURATION_FOR_SAVING_FAILED),
    pluck('payload'),
    map(doctor =>
      concat(
        of(Actions.requestDoctorDetailsUpdate(doctor)),
        of(
          Actions.showSnackbar({
            type: 'error',
            text: i18n.t('messages.searchAreaCoverage.fail')
          })
        )
      )
    )
  )

export const updateDoctorEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_DOCTOR_DETAILS_REQUESTED),
    pluck('payload'),
    map(payload => mapToUpdateDoctorInput(payload)),
    switchMap(doctor =>
      from(API.graphql(graphqlOperation(getDoctor, { id: doctor.id }))).pipe(
        map(({ data }) => data?.getDoctor),
        switchMap(({ _version }) =>
          from(
            API.graphql(
              graphqlOperation(updateDoctor, {
                input: {
                  ...doctor,
                  _version
                }
              })
            )
          ).pipe(
            mergeMap(({ data }) =>
              of(
                Actions.updateDoctorDetailsReceived({
                  ...mapToCombinedDoctorPracticeEntity(data?.updateDoctor),
                  showSnackbar: true,
                  snackbarText: i18n.t('messages.changesSavedSuccessfully')
                })
              )
            ),
            catchError(error =>
              concat(
                of(Actions.fetchRejected(error)),
                of(
                  Actions.showSnackbar({
                    type: 'error',
                    text: i18n.t('messages.somethingWentWrongContactSupport')
                  })
                )
              )
            )
          )
        ),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const updatePracticeEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_PRACTICE_DETAILS),
    pluck('payload'),
    map(payload => mapToUpdateDoctorInput(payload)),
    switchMap(doctor =>
      from(API.graphql(graphqlOperation(getDoctor, { id: doctor.accountOwnerId || doctor.id }))).pipe(
        map(({ data }) => data?.getDoctor),
        switchMap(({ _version }) =>
          from(
            API.graphql(
              graphqlOperation(updateDoctor, {
                input: {
                  ...doctor,
                  _version
                }
              })
            )
          ).pipe(
            map(({ data }) => data?.updateDoctor),
            mergeMap(doctor =>
              of(
                Actions.updatePracticeDetailsReceived({
                  clinic: doctor.clinic,
                  bio: doctor.bio,
                  hasLocatorConsent: doctor.hasLocatorConsent,
                  snackbarText: i18n.t('messages.changesSavedSuccessfully')
                })
              )
            ),
            catchError(error =>
              concat(
                of(Actions.updatePracticeDetailsFailed(error)),
                of(
                  Actions.showSnackbar({
                    type: 'error',
                    text: i18n.t('messages.somethingWentWrongContactSupport')
                  })
                )
              )
            )
          )
        ),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const searchForAreaOfCoverageEpic = action$ =>
  action$.pipe(
    ofType(Actions.GET_AREA_OF_COVERAGE),
    pluck('payload'),
    switchMap(({ zipcodes, metricType, searchRadius, country }) =>
      from(
        API.get(
          'grinApi',
          `/accounts/doctors/areaOfCoverage?zipcodes=${zipcodes}&metricType=${metricType}&searchRadius=${searchRadius}&country=${country}`
        )
      ).pipe(
        map(response => response.areaOfCoverage),
        mergeMap(areaOfCoverage => concat(of(Actions.getAreaOfCoverageReceived({ areaOfCoverage })))),
        catchError(({ response }) =>
          concat(
            of(Actions.getAreaOfCoverageFailed(response)),
            of(
              Actions.showSnackbar({
                type: 'error',
                text: i18n.t('messages.searchAreaCoverage.fail')
              })
            )
          )
        )
      )
    )
  )

export const getZipCoordinatesEpic = action$ =>
  action$.pipe(
    ofType(Actions.GET_ZIP_COORDINATES),
    pluck('payload'),
    switchMap(({ zipcodes, country }) =>
      from(API.get('grinApi', `/accounts/doctors/zipCoordinates?zipcodes=${zipcodes}&country=${country}`)).pipe(
        mergeMap(zipCoordinates => of(Actions.zipCoordinatesReceived(zipCoordinates))),
        catchError(({ response }) =>
          concat(
            of(Actions.zipCoordinatesFailed(response)),
            of(
              Actions.showSnackbar({
                type: 'error',
                text: i18n.t('messages.zipCoordinates.fail')
              })
            )
          )
        )
      )
    )
  )

export const requestDoctorPhotoUpdate = action$ =>
  action$.pipe(
    ofType(Actions.DOCTOR_PHOTO_UPDATE_REQUESTED),
    pluck('payload'),
    switchMap(({ key, doctor }) =>
      from(
        API.graphql(
          graphqlOperation(updateDoctor, {
            input: {
              id: doctor.id,
              _version: doctor._version,
              photo: {
                bucket: config.aws_user_files_s3_bucket,
                region: config.aws_user_files_s3_bucket_region,
                key
              }
            }
          })
        )
      ).pipe(
        mergeMap(({ data }) =>
          of(
            Actions.updateDoctorDetailsReceived({
              ...mapToCombinedDoctorPracticeEntity(data?.updateDoctor),
              snackbarText: 'Profile picture was changed successfully'
            })
          )
        ),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const removeDoctorPhotoEpic = action$ =>
  action$.pipe(
    ofType(Actions.REMOVE_DOCTOR_PHOTO_REQUESTED),
    pluck('payload'),
    switchMap(({ doctor }) =>
      from(
        API.graphql(
          graphqlOperation(updateDoctor, {
            input: {
              id: doctor.id,
              _version: doctor._version,
              photo: null
            }
          })
        )
      ).pipe(
        mergeMap(({ data }) =>
          of(
            Actions.updateDoctorDetailsReceived({
              ...mapToCombinedDoctorPracticeEntity(data?.updateDoctor),
              snackbarText: 'Profile picture was removed successfully'
            })
          )
        ),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const updatePracticeLogo = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPDATE_PRACTICE_LOGO),
    withLatestFrom(state$),
    map(([action, state]) => ({
      key: action.payload.key,
      doctor: action.payload.doctor,
      clinic: state.practiceReducer.details
    })),
    switchMap(({ key, doctor, clinic }) =>
      from(API.graphql(graphqlOperation(getDoctor, { id: doctor.accountOwnerId || doctor.id }))).pipe(
        map(({ data }) => data?.getDoctor),
        switchMap(({ _version }) =>
          from(
            API.graphql(
              graphqlOperation(updateDoctor, {
                input: {
                  id: doctor.accountOwnerId || doctor.id,
                  _version: _version,
                  clinic: {
                    ...clinic,
                    logo: {
                      bucket: config.aws_user_files_s3_bucket,
                      region: config.aws_user_files_s3_bucket_region,
                      key
                    }
                  }
                }
              })
            )
          ).pipe(
            map(({ data }) => data?.updateDoctor.clinic),
            mergeMap(clinic =>
              of(
                Actions.updatePracticeDetailsReceived({
                  clinic,
                  snackbarText: 'Practice Logo was changed successfully'
                })
              )
            ),
            catchError(error => of(Actions.fetchRejected(error)))
          )
        ),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const removePracticeLogoEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REMOVE_PRACTICE_LOGO),
    withLatestFrom(state$),
    map(([action, state]) => ({
      doctor: action.payload.doctor,
      clinic: state.practiceReducer.details
    })),
    switchMap(({ doctor, clinic }) =>
      from(API.graphql(graphqlOperation(getDoctor, { id: doctor.accountOwnerId || doctor.id }))).pipe(
        map(({ data }) => data?.getDoctor),
        switchMap(({ _version }) =>
          from(
            API.graphql(
              graphqlOperation(updateDoctor, {
                input: {
                  id: doctor.accountOwnerId || doctor.id,
                  _version: _version,
                  clinic: {
                    ...clinic,
                    logo: null
                  }
                }
              })
            )
          ).pipe(
            map(({ data }) => data?.updateDoctor.clinic),
            mergeMap(clinic =>
              of(
                Actions.updatePracticeDetailsReceived({
                  clinic,
                  snackbarText: 'Practice Logo was removed successfully'
                })
              )
            ),
            catchError(error => of(Actions.fetchRejected(error)))
          )
        ),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const requestUpdateMessagingPreferencesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPDATE_MESSAGING_PREFERENCES_REQUESTED),
    withLatestFrom(state$),
    map(([action, state]) => ({ action, doctor: state.profileReducer.doctor })),
    switchMap(({ action, doctor }) =>
      from(
        API.graphql(
          graphqlOperation(getMessagingPreferencesVersion, {
            id: action.payload.id
          })
        )
          .then(res => res.data.getMessagingPreferences)
          .then(({ _version }) =>
            API.graphql(
              graphqlOperation(updateMessagingPreferences, {
                input: {
                  ...action.payload,
                  _version
                }
              })
            )
          )
      ).pipe(
        mergeMap(({ data }) =>
          of(
            Actions.updateDoctorDetailsReceived({
              ...mapToCombinedDoctorPracticeEntity({
                ...doctor,
                user: {
                  ...doctor.user,
                  messagingPreferences: data?.updateMessagingPreferences
                }
              }),
              snackbarText: 'Notifications preferences changed successfully'
            })
          )
        ),
        catchError(err =>
          concat(
            of(Actions.fetchRejected(err)),
            of(
              Actions.showSnackbar({
                type: 'error',
                text: i18n.t('messages.somethingWentWrongContactSupport')
              })
            )
          )
        )
      )
    )
  )

export const requestDoctorPlans = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_DOCTOR_PLANS),
    withLatestFrom(state$),
    map(([action, state]) => ({
      email: action.payload,
      doctor: state.profileReducer.doctor
    })),
    switchMap(({ email, doctor }) =>
      from(API.graphql(graphqlOperation(doctorPlansByEmail, { email }))).pipe(
        map(({ data }) => data.doctorPlansByEmail.items),
        catchError(error => of(Actions.fetchRejected(error))),
        filter(doctorPlansItems => doctorPlansItems.length > 0),
        map(doctorPlansItems => doctorPlansItems[0]),
        switchMap(doctorPlans =>
          from(
            forkJoin(
              doctorPlans.plans.map(plan =>
                API.graphql(
                  graphqlOperation(patientsByDoctorIdByPlan, {
                    patientDoctorId: doctor.id,
                    plan: { eq: plan.name },
                    limit: 1000
                  })
                )
              )
            )
          ).pipe(
            map(patientPlans => ({
              ...doctorPlans,
              plans: doctorPlans.plans.map((p, i) => ({
                ...p,
                activeLicenses: patientPlans[i].data.patientsByDoctorIdByPlan.items.filter(p => !p._deleted).length
              }))
            })),
            mergeMap(doctorNewPlans => of(Actions.doctorPlansReceived(doctorNewPlans))),
            catchError(error => of(Actions.fetchRejected(error)))
          )
        )
      )
    )
  )

export const requestUpdateBillingInfoEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPDATE_BILLING_INFO_REQUESTED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      billingInfo: action.payload,
      doctor: state.profileReducer.doctor
    })),
    switchMap(({ billingInfo, doctor }) =>
      from(
        API.graphql(
          graphqlOperation(updateBillingInfo, {
            input: {
              ...billingInfo,
              id: doctor.billingInfo.id,
              billingInfoDoctorId: doctor.id,
              _version: doctor.billingInfo._version
            }
          })
        )
      ).pipe(
        pluck('data', 'updateBillingInfo'),
        mergeMap(updatedBillingInfo =>
          of(
            Actions.updateDoctorDetailsReceived({
              ...mapToCombinedDoctorPracticeEntity({
                ...doctor,
                billingInfo: updatedBillingInfo
              }),
              snackbarText: 'Billing info updated successfully'
            })
          )
        ),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const requestUpdateAppSettings = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_UPDATE_APP_SETTINGS),
    withLatestFrom(state$),
    map(([action, state]) => ({
      changes: action.payload,
      doctor: state.practiceReducer.accountOwner
    })),
    switchMap(({ changes, doctor }) =>
      from(
        API.graphql(
          graphqlOperation(getGrinUser, {
            id: doctor.user.id
          })
        )
      ).pipe(
        map(res => res.data.getGrinUser),
        switchMap(accountOwnerUser =>
          from(
            API.graphql(
              graphqlOperation(updateGrinUser, {
                input: {
                  id: accountOwnerUser.id,
                  _version: accountOwnerUser._version,
                  appSettings: JSON.stringify({
                    ...JSON.parse(accountOwnerUser.appSettings || '{}'),
                    ...changes
                  })
                }
              })
            )
          ).pipe(
            map(res => res.data.updateGrinUser),
            mergeMap(updatedGrinUser =>
              of(
                Actions.updateDoctorDetailsReceived({
                  ...mapToCombinedDoctorPracticeEntity({
                    ...doctor,
                    user: {
                      ...updatedGrinUser
                    }
                  }),
                  snackbarText: "We've updated your settings succesfully"
                })
              )
            ),
            catchError(err => of(Actions.fetchRejected(err)))
          )
        ),
        catchError(err => of(Actions.fetchRejected(err)))
      )
    )
  )

export const fetchScopedCustomTagsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_DOCTOR_TAGS),
    withLatestFrom(state$),
    map(([, state]) => ({
      doctor: state.profileReducer.doctor,
      customTagsPermissions: evaluateRolePermissions().customTags,
      startDate: new Date()
    })),
    switchMap(({ doctor, customTagsPermissions, startDate }) =>
      from(
        !customTagsPermissions
          ? Promise.resolve([])
          : fetchAll(
              searchTagsForFetchDoctorTags,
              {
                limit: 1000,
                filter: {
                  and: {
                    type: {
                      eq: 'custom'
                    },
                    ...buildReadersFilter(JSON.parse(doctor.user.allowed_groups_permissions || '[]'), 'Tag', 'eq')
                  }
                }
              },
              'searchTags'
            )
      ).pipe(
        tap(tags =>
          logInfo('FETCH_DOCTOR_TAGS: completed', {
            tagsCount: tags.length,
            took: new Date().getTime() - startDate.getTime()
          })
        ),
        map(tags => tags.filter(tag => !tag._deleted)),
        map(tags => uniqBy(tags, tag => tag.value)),
        mergeMap(tags => of(Actions.doctorTagsReceived(tags))),
        catchError(err => of(Actions.doctorTagsFailed(err)))
      )
    )
  )

export const downloadQRCodeEpic = action$ =>
  action$.pipe(
    ofType(Actions.DOWNLOAD_QR_CODE),
    pluck('payload'),
    map(({ qrCodeId, fileName }) => {
      const canvas = document.getElementById(qrCodeId)
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      let downloadLink = document.createElement('a')
      downloadLink.href = pngUrl
      downloadLink.download = `${fileName}.png`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      return Actions.qrCodeDownloaded()
    })
  )

export const qrCodeDownloadedEpic = action$ =>
  action$.pipe(
    ofType(Actions.QR_CODE_DOWNLOADED),
    map(() =>
      Actions.showSnackbar({
        type: `success`,
        text: i18n.t('pages.accountSettings.services.remoteConsultation.qrCodeDownloaded')
      })
    )
  )

export const updateClinicLogoEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPDATE_CLINIC_LOGO),
    withLatestFrom(state$),
    map(([action, state]) => ({
      doctor: action.payload.doctor,
      key: action.payload.key,
      practiceDetails: state.practiceReducer.details
    })),
    map(({ doctor, key, practiceDetails }) =>
      Actions.requestDoctorDetailsUpdate({
        ...doctor,
        clinic: {
          ...practiceDetails,
          logo: key
            ? {
                key,
                bucket: config.aws_user_files_s3_bucket,
                region: config.aws_user_files_s3_bucket_region
              }
            : null
        }
      })
    )
  )

export const fetchEmbeddedDashboardUrl = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_EMBEDDED_DASHBOARD_URL),
    switchMap(() =>
      from(API.get('grinApi', '/accounts/doctors/embeddedDashboard')).pipe(
        map(data => data.iframeUrl),
        mergeMap(data => of(Actions.embeddedDashboardUrlReceived(data))),
        catchError(({ response }) => of(Actions.embeddedDashboardUrlFailed(response)))
      )
    )
  )

export const fetchEmbeddedDashboardUrlFailed = action$ =>
  action$.pipe(
    ofType(Actions.EMBEDDED_DASHBOARD_URL_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const fetchNewDoctorDashboardUrl = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_NEW_DOCTOR_DASHBOARD_URL),
    switchMap(() =>
      from(API.get('grinServerlessApi', `/platform/v1/stats`)).pipe(
        map(data => data.iFrames),
        mergeMap(data => of(Actions.newDoctorDashboardUrlReceived(data))),
        catchError(({ response }) => of(Actions.newDoctorDashboardUrlFailed(response)))
      )
    )
  )

export const fetchNewDoctorDashboardUrlFailed = action$ =>
  action$.pipe(
    ofType(Actions.NEW_DOCTOR_DASHBOARD_URL_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const updateThemeColorsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPDATE_THEME_COLORS),
    withLatestFrom(state$),
    map(([action, state]) => ({
      grinUser: state.practiceReducer.accountOwner.user,
      rcTheme: action.payload.rcTheme
    })),
    switchMap(({ grinUser, rcTheme }) =>
      from(
        getEntityIdAndVersion({ entityType: 'GrinUser', id: grinUser.id }).then(({ _version }) =>
          API.graphql(
            graphqlOperation(updateGrinUser, {
              input: {
                id: grinUser.id,
                _version,
                appSettings: JSON.stringify({
                  ...JSON.parse(grinUser.appSettings || '{}'),
                  rcTheme
                })
              }
            })
          )
        )
      ).pipe(
        mergeMap(({ data }) =>
          of(
            Actions.updateThemeColorsReceived({
              grinUser: data?.updateGrinUser,
              showSnackbar: true,
              snackbarText: i18n.t('messages.changesSavedSuccessfully')
            })
          )
        )
      )
    ),
    catchError(error =>
      of(
        Actions.updateThemeColorsFailed({
          showSnackbar: true,
          snackbarText: i18n.t('messages.changesSavedSuccessfully')
        })
      )
    )
  )

export const createWelcomeMessageEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_WELCOME_MESSAGE),
    withLatestFrom(state$),
    map(([{ payload }, state]) => ({
      input: {
        type: `${TEMPLATES_TYPES.WELCOME_MESSAGE}${
          payload.program.toLowerCase() === REMOTE_MONITORING_PROGRAM ? '' : payload.program
        }`,
        a_doctor: state.profileReducer.doctor.username,
        doctorId: state.practiceReducer.accountOwner.id,
        creatorDoctorId: state.profileReducer.doctor.id,
        title: 'Automated Welcome Message',
        text: payload.text,
        isPrivate: false
      },
      program: payload.program
    })),
    switchMap(({ input, program }) =>
      from(API.graphql(graphqlOperation(createMessageTemplate, { input }))).pipe(
        pluck('data', 'createMessageTemplate'),
        mergeMap(welcomeMessage => of(Actions.createWelcomeMessageReceived({ welcomeMessage, program }))),
        catchError(error => of(Actions.createWelcomeMessageFailed(error)))
      )
    )
  )

export const updateWelcomeMessageEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_WELCOME_MESSAGE),
    map(({ payload }) => ({
      id: payload.id,
      _version: payload._version,
      text: payload.text
    })),
    switchMap(input =>
      from(API.graphql(graphqlOperation(updateMessageTemplate, { input }))).pipe(
        pluck('data', 'updateMessageTemplate'),
        mergeMap(welcomeMessage => of(Actions.updateWelcomeMessageReceived(welcomeMessage))),
        catchError(({ response }) => of(Actions.updateWelcomeMessageFailed(response)))
      )
    )
  )

export const createWelcomeMessageReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_WELCOME_MESSAGE_RECEIVED),
    withLatestFrom(state$),
    filter(([, state]) => state.profileReducer.doctor.id),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('pages.accountSettings.welcomeMessage.createdSuccessfully')
      })
    )
  )

export const updateWelcomeMessageReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPDATE_WELCOME_MESSAGE_RECEIVED),
    withLatestFrom(state$),
    filter(([, state]) => state.profileReducer.doctor.id),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('pages.accountSettings.welcomeMessage.updatedSuccessfully')
      })
    )
  )

export const updateWelcomeMessageFailedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_WELCOME_MESSAGE_FAILED, Actions.UPDATE_WELCOME_MESSAGE_FAILED),
    withLatestFrom(state$),
    filter(([, state]) => state.profileReducer.doctor.id),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const createMessageTemplateEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_MESSAGE_TEMPLATE),
    withLatestFrom(state$),
    map(([{ payload }, state]) => ({
      type: 'custom',
      a_doctor: state.profileReducer.doctor.username,
      doctorId: state.practiceReducer.accountOwner.id,
      creatorDoctorId: state.profileReducer.doctor.id,
      title: payload.title,
      text: payload.text,
      isPrivate: payload.isPrivate
    })),
    switchMap(input =>
      from(
        API.graphql(
          graphqlOperation(createMessageTemplate, {
            input
          })
        )
      ).pipe(
        pluck('data', 'createMessageTemplate'),
        mergeMap(messageTemplate =>
          concat(
            of(Actions.createMessageTemplateRecieved(messageTemplate)),
            of(
              Actions.showSnackbar({
                type: 'success',
                text: i18n.t('pages.patients.selectedPatient.chat.templates.templateCreated')
              })
            )
          )
        ),
        catchError(error =>
          concat(
            of(Actions.createMessageTemplateFailed(error)),
            of(
              Actions.showSnackbar({
                type: 'error',
                text: i18n.t('messages.somethingWentWrong')
              })
            )
          )
        )
      )
    )
  )

export const duplicateMessageTemplateEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DUPLICATE_MESSAGE_TEMPLATE),
    withLatestFrom(state$),
    map(([{ payload }, state]) => ({
      type: 'custom',
      a_doctor: state.profileReducer.doctor.username,
      doctorId: state.practiceReducer.accountOwner.id,
      creatorDoctorId: state.profileReducer.doctor.id,
      title: `Duplicate of ${payload.title}`,
      isPrivate: payload.isPrivate,
      text: payload.text
    })),
    switchMap(input =>
      from(
        API.graphql(
          graphqlOperation(createMessageTemplate, {
            input
          })
        )
      ).pipe(
        pluck('data', 'createMessageTemplate'),
        mergeMap(messageTemplate =>
          concat(
            of(Actions.duplicateMessageTemplateRecieved(messageTemplate)),
            of(
              Actions.showSnackbar({
                type: 'success',
                text: i18n.t('pages.patients.selectedPatient.chat.templates.templateDuplicated', {
                  templateTitle: messageTemplate.title
                })
              })
            )
          )
        ),
        catchError(error =>
          concat(
            of(Actions.duplicateMessageTemplateFailed(error)),
            of(
              Actions.showSnackbar({
                type: 'error',
                text: i18n.t('messages.somethingWentWrong')
              })
            )
          )
        )
      )
    )
  )

export const updateMessageTemplateEpic = action$ =>
  action$.pipe(
    ofType(Actions.UPDATE_MESSAGE_TEMPLATE),
    map(({ payload }) => ({
      id: payload.id,
      _version: payload._version,
      isPrivate: payload.isPrivate,
      title: payload.title,
      text: payload.text
    })),
    switchMap(input =>
      from(
        API.graphql(
          graphqlOperation(updateMessageTemplate, {
            input
          })
        )
      ).pipe(
        pluck('data', 'updateMessageTemplate'),
        mergeMap(messageTemplate =>
          concat(
            of(Actions.updateMessageTemplateRecieved(messageTemplate)),
            of(
              Actions.showSnackbar({
                type: 'success',
                text: i18n.t('pages.patients.selectedPatient.chat.templates.templateUpdated', {
                  templateTitle: messageTemplate.title
                })
              })
            )
          )
        ),
        catchError(({ response }) => of(Actions.updateMessageTemplateFailed(response)))
      )
    )
  )

export const deleteMessageTemplateEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_MESSAGE_TEMPLATE),
    map(({ payload }) => ({
      id: payload.id,
      _version: payload._version
    })),
    switchMap(input =>
      from(
        API.graphql(
          graphqlOperation(deleteMessageTemplate, {
            input
          })
        )
      ).pipe(
        pluck('data', 'deleteMessageTemplate'),
        mergeMap(messageTemplate =>
          concat(
            of(Actions.deleteMessageTemplateRecieved(messageTemplate)),
            of(
              Actions.showSnackbar({
                type: 'success',
                text: i18n.t('pages.patients.selectedPatient.chat.templates.templateDeleted')
              })
            )
          )
        ),
        catchError(({ response }) => of(Actions.deleteMessageTemplateFailed(response)))
      )
    )
  )

export const fetchWelcomeMessageTemplatesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_WELCOME_MESSAGES),
    withLatestFrom(state$),
    map(([_, state]) => ({
      accountOwnerId: state.practiceReducer.accountOwner.id
    })),
    switchMap(({ accountOwnerId }) =>
      from(
        API.graphql(
          graphqlOperation(getWelcomeMessagesByDoctorId, {
            doctorId: accountOwnerId,
            filter: {
              type: { contains: TEMPLATES_TYPES.WELCOME_MESSAGE }
            },
            limit: 100
          })
        )
      ).pipe(
        map(res => res?.data?.templatesByDoctorId?.items),
        mergeMap(templates => of(Actions.welcomeMessagesReceived(templates))),
        catchError(err => of(Actions.fetchWelcomeMessagesFailed(err)))
      )
    )
  )

export const fetchCustomTemplatesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_CUSTOM_TEMPLATES),
    withLatestFrom(state$),
    map(([action, state]) => ({
      doctorId: state.profileReducer?.doctor?.id,
      accountOwnerId: state.practiceReducer.accountOwner.id
    })),
    switchMap(({ doctorId, accountOwnerId }) =>
      from(
        API.graphql(
          graphqlOperation(getTemplatesByTypeAndByDoctorId, {
            type: TEMPLATES_TYPES.CUSTOM,
            doctorId: { eq: accountOwnerId },
            limit: 1000
          })
        )
      ).pipe(
        map(res => res?.data?.templatesByTypeAndByDoctorId?.items),
        map(templates => filterCustomTemplates(templates, doctorId)),
        mergeMap(templates => of(Actions.customTemplatesReceived(templates))),
        catchError(err => of(Actions.customTemplatesFailed(err)))
      )
    )
  )

export const customTemplatesFailedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_CUSTOM_TEMPLATES_FAILED),
    withLatestFrom(state$),
    filter(([, state]) => state.profileReducer.doctor.id),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('pages.patients.selectedPatient.chat.templates.failedToFetchCustomTemplates')
      })
    )
  )

export const onDoctorFeatureFlagsNotificationReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload,
      doctorId: state.profileReducer.doctor.id
    })),
    filter(
      ({ notification, doctorId }) =>
        notification.entityType === 'FeatureFlags' &&
        notification.method === 'MODIFY' &&
        notification.entityId === doctorId
    ),
    mergeMap(() => of(Actions.requestFeatureFlags({})))
  )

export const onNewPatientTagNotificationReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload
    })),
    filter(({ notification }) => notification.entityType === 'Tag' && notification.method === 'INSERT'),
    mergeMap(() => of(Actions.fetchDoctorTags()))
  )

export const onPracticeFeatureFlagsNotificationReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload,
      accountOwnerId: state.practiceReducer.accountOwner.id
    })),
    filter(
      ({ notification, accountOwnerId }) =>
        notification.entityType === 'FeatureFlags' &&
        notification.method === 'MODIFY' &&
        notification.entityId === accountOwnerId
    ),
    mergeMap(({ accountOwnerId }) => of(Actions.requestFeatureFlags({ isPracticeFF: true })))
  )

export const requestFeatureFlagsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_FEATURE_FLAGS),
    withLatestFrom(state$),
    map(([action, state]) => ({
      id: action.payload?.isPracticeFF
        ? state.practiceReducer.accountOwner?.user?.featureFlags?.id
        : state.profileReducer.doctor?.user?.featureFlags?.id,
      isPracticeFF: action.payload?.isPracticeFF
    })),
    mergeMap(({ id, isPracticeFF }) =>
      from(API.graphql(graphqlOperation(getFeatureFlags, { id }))).pipe(
        map(({ data }) => data.getFeatureFlags),
        mergeMap(response =>
          of(isPracticeFF ? Actions.practiceFeatureFlagsReceived(response) : Actions.featureFlagsReceived(response))
        ),
        catchError(error => of(Actions.featureFlagsFailed(error)))
      )
    )
  )

export const deleteDoctorTagEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_DOCTOR_TAG),
    pluck('payload'),
    switchMap(tag =>
      from(
        API.graphql(
          graphqlOperation(updateDoctorTag, {
            input: {
              id: tag.id,
              _version: tag._version,
              isDeleted: true
            }
          })
        )
      ).pipe(
        map(({ data }) => data?.updateTag),
        mergeMap(tag => of(Actions.deleteDoctorTagReceived(tag))),
        catchError(error => of(Actions.deleteDoctorTagFailed(error)))
      )
    )
  )

export const deleteDoctorTagReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_DOCTOR_TAG_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('pages.patients.selectedPatient.tags.deletedSuccessfully')
      })
    )
  )

export const deleteDoctorTagFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_CUSTOM_TEMPLATES_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('pages.patients.selectedPatient.tags.failedToDeleteTag')
      })
    )
  )
