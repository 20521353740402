import * as React from 'react'

export default () => {
  return (
    <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1.30645C1 0.861061 1.36106 0.5 1.80645 0.5L5.03226 0.5V3.72581H8.35294V9.69355C8.35294 10.1389 7.99188 10.5 7.54649 10.5H1.80645C1.36106 10.5 1 10.1389 1 9.69355V1.30645Z"
        stroke="#041333"
        strokeLinejoin="round"
      />
      <path d="M5.03223 0.5L8.25803 3.72581" stroke="#041333" />
    </svg>
  )
}
