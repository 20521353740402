import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'
import { rangeArray } from 'utils/arrayUtils'
import ScanActionsSkeleton from './ScanActionsSkeleton'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 10px',
    width: '100%'
  },
  navigation: {
    margin: '8px 0 16px 0',
    width: '100%',
    borderRadius: 16
  },
  scanVideo: {
    borderRadius: '16px 16px 0 0',
    width: '100%',
    height: 'auto',
    aspectRatio: 1.8
  },
  imageSkeleton: {
    borderRadius: '8px',
    width: '100%',
    aspectRatio: 1.8,
    height: 'auto'
  },
  scanReviewContainer: {
    display: 'flex',
    margin: '4px 0 16px 0',
    justifyContent: 'space-between'
  }
}))

const ScanFeedSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ScanActionsSkeleton />
      <Skeleton variant="rect" height={60} className={classes.navigation} />
      <Skeleton variant="rect" className={classes.scanVideo} />
      <div className={classes.scanReviewContainer}>
        {rangeArray(6).map(i => (
          <div key={`scan-summary-skeleton-${i}`} style={{ flex: 1, padding: 6 }}>
            <Skeleton variant="rect" className={classes.imageSkeleton} />
          </div>
        ))}
      </div>
      <Skeleton variant="rect" height={100} className={classes.imageSkeleton} />
    </div>
  )
}

export default ScanFeedSkeleton
