import * as React from 'react'

const SmallLock = () => {
  return (
    <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 6C0 5.44772 0.447715 5 1 5H7C7.55228 5 8 5.44772 8 6V10C8 10.5523 7.55228 11 7 11H1C0.447715 11 0 10.5523 0 10V6Z"
        fill="#818999"
      />
      <path d="M2 5V3C2 1.89543 2.89543 1 4 1V1C5.10457 1 6 1.89543 6 3V5" stroke="#818999" strokeWidth="1.5" />
    </svg>
  )
}

export default SmallLock
