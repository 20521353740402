import { makeStyles } from '@material-ui/styles'
import queryString from 'query-string'
import React, { useMemo, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { trackEvent } from 'utils/analyticsUtils'
import ROUTES from '../../../consts/routesConsts'
import PrimaryButton from '../../common/buttons/PrimaryButton'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import OggHeading40 from '../../common/text/OggHeading40'
import useRcDetails from '../useRcDetails'

const useStyles = makeStyles(
  () => ({
    root: {
      maxWidth: 950,
      height: '100%',
      textAlign: 'center',
      margin: '60px auto',
      padding: ({ isMobile }) => (isMobile ? '0 20px' : 0)
    },
    title: {
      marginBottom: 15,
      fontSize: 50
    },
    paragraph: {
      margin: '40px 0',
      lineHeight: '30px',
      fontSize: 24
    },
    link: {
      color: 'var(--text-color-3)!important'
    },
    ctaContainer: {
      margin: '100px 0'
    },
    cta: {
      fontSize: 22,
      backgroundColor: ({ colorSettings }) => colorSettings.footerBackgroundColor,
      color: ({ colorSettings }) => colorSettings.footerTextColor
    }
  }),
  { isMobile }
)

const RcSignupUnavailable = () => {
  const doctorDetails = useRcDetails()
  const classes = useStyles({ isMobile, colorSettings: doctorDetails.colorSettings })
  const { t } = useTranslation()
  const history = useHistory()

  const { rcToken } = useMemo(() => queryString.parse(history.location.search), [history])
  const doctorName = useMemo(() => doctorDetails.name, [doctorDetails])
  const practiceName = useMemo(() => doctorDetails.clinicName, [doctorDetails])

  useEffect(() => {
    if (doctorName && practiceName) {
      trackEvent('RC landing page - signup failed', {
        doctorName,
        practiceName
      })
    }
  }, [doctorName, practiceName])

  return (
    <div className={classes.root}>
      <OggHeading40 className={classes.title}>{t('pages.rcLanding.signupFailed.title')}</OggHeading40>
      <DazzedParagraph16 className={classes.paragraph} textAlign="center">
        {t('pages.rcLanding.signupFailed.paragraph1')}
      </DazzedParagraph16>
      <DazzedParagraph16 textAlign="center" className={classes.paragraph}>
        {t('pages.rcLanding.signupFailed.contactUs1')}
        <a href={`mailto:${t('pages.rcLanding.footer.supportEmail')}`}>{t('pages.rcLanding.footer.supportEmail')}</a>
        {t('pages.rcLanding.signupFailed.contactUs2')}
      </DazzedParagraph16>
      <div className={classes.ctaContainer}>
        <PrimaryButton
          label={t('pages.rcLanding.signupFailed.ctaText')}
          type="button"
          borderRadius="0px 0px 24px 0px"
          height={64}
          width={isMobile ? '100%' : '300px'}
          className={classes.cta}
          onClick={() => (window.location.href = `${ROUTES.AUTH_RC_BASIC}/${rcToken}/${history.location.search}`)}
        />
      </div>
    </div>
  )
}

export default RcSignupUnavailable
