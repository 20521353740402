import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { Skeleton } from '@material-ui/lab'
import ProfilePicture from './ProfilePicture'
import DazzedHeading16 from './text/DazzedHeading16'
import GrinChip from './GrinChip'
import { useTranslation } from 'react-i18next'
import GrinTag from './tags/GrinTag'

const useStyles = makeStyles(theme => ({
  programTag: {
    width: 'fit-content',
    margin: '-10px auto 0 auto',
    position: 'relative',
    padding: '2px 5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px'
  },
  rm: {
    background: 'var(--bg-color-14)'
  },
  rc: {
    background: 'var(--bg-color-13)'
  },
  ortho: {
    background: 'var(--bg-color-26)'
  },
  serviceAccount: {
    background: 'var(--bg-color-14)'
  }
}))

const PatientDetails = ({ name, photo, program, isLoading, tags = [] }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        {isLoading ? (
          <Skeleton variant="circle" width={48} height={48} />
        ) : (
          <ProfilePicture name={name} photo={photo} size="medium" />
        )}
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            {isLoading ? (
              <Skeleton variant="text" width={120} height={24} />
            ) : (
              <DazzedHeading16>{name}</DazzedHeading16>
            )}
          </Grid>
          {isLoading ? (
            <Skeleton variant="rect" width={40} height={18} />
          ) : (
            <Grid item>
              <Grid container spacing={1}>
                {program && (
                  <Grid item>
                    <GrinChip
                      text={t(`common.tags.${program}`)?.toUpperCase()}
                      className={classes[program.toLowerCase()]}
                    />
                  </Grid>
                )}
                {tags.map(tag => (
                  <Grid item key={`tag-${tag.id}`}>
                    <GrinTag tag={tag} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PatientDetails
