import { ElasticColors } from 'consts/hiToolsConsts'
import { isEmpty } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { removeAt } from 'utils/arrayUtils'

export const Modes = {
  Edit: 'edit',
  View: 'view'
}

export const ToothMarkingOptions = ['B', 'L']

export default () => {
  const { t } = useTranslation()

  const [draftElastics, setDraftElastics] = useState([])
  const [mode, setMode] = useState(Modes.View)
  const [modifiedElasticIndex, setModifiedElasticIndex] = useState(-1)
  const [modifiedElastic, setModifiedElastic] = useState(null)

  const elasticsPreview = useMemo(() => {
    const elastics = [...draftElastics]
    elastics[modifiedElasticIndex] = modifiedElastic
    return elastics
  }, [draftElastics, modifiedElastic, modifiedElasticIndex])

  const findNextAvailableColor = useCallback(() => {
    return ElasticColors.find(color => !draftElastics.some(elastic => elastic.color === color))
  }, [draftElastics])

  const createEmptyElastic = useCallback(
    () => ({
      name: t('dialogs.patientBrief.setupElasticsPopup.defaultElasticName', { number: draftElastics.length + 1 }),
      color: findNextAvailableColor(),
      selections: {}
    }),
    [t, draftElastics, findNextAvailableColor]
  )

  const removeElastic = useCallback(
    index => {
      const updatedElastics = removeAt(draftElastics, index)
      setDraftElastics(updatedElastics)
    },
    [draftElastics]
  )

  const enterEditMode = useCallback(
    elasticIndex => {
      const elastic = draftElastics[elasticIndex]
      if (!elastic) {
        return
      }

      setMode(Modes.Edit)
      setModifiedElasticIndex(elasticIndex)
      setModifiedElastic({ ...elastic })
    },
    [draftElastics]
  )

  const exitEditMode = useCallback(() => {
    setMode(Modes.View)
    setModifiedElasticIndex(-1)
    setModifiedElastic(null)
  }, [])

  const cancelEdit = useCallback(() => {
    if (isEmpty(draftElastics[modifiedElasticIndex].selections)) {
      removeElastic(modifiedElasticIndex)
    }

    exitEditMode()
  }, [exitEditMode, draftElastics, modifiedElasticIndex, removeElastic])

  const saveModifiedElastic = useCallback(() => {
    if (isEmpty(modifiedElastic.selections)) {
      removeElastic(modifiedElasticIndex)
    } else {
      const updatedDraftElastics = [...draftElastics]
      updatedDraftElastics[modifiedElasticIndex] = modifiedElastic
      setDraftElastics(updatedDraftElastics)
    }
    exitEditMode()
  }, [exitEditMode, draftElastics, modifiedElastic, modifiedElasticIndex, removeElastic])

  const addElastic = useCallback(() => {
    const elastic = createEmptyElastic()
    const updatedElastics = [...draftElastics, elastic]
    setDraftElastics(updatedElastics)
    setMode(Modes.Edit)
    setModifiedElasticIndex(updatedElastics.length - 1)
    setModifiedElastic({ ...elastic })
  }, [createEmptyElastic, draftElastics])

  const handleToothClick = useCallback(
    tooth => {
      if (!modifiedElastic) {
        return
      }

      const currentElasticToothSelection = modifiedElastic.selections[tooth]
      const nextSelection = !currentElasticToothSelection
        ? 0
        : ToothMarkingOptions.indexOf(currentElasticToothSelection) + 1

      setModifiedElastic({
        ...modifiedElastic,
        selections: {
          ...modifiedElastic.selections,
          [tooth]: ToothMarkingOptions[nextSelection]
        }
      })
    },
    [modifiedElastic]
  )

  return {
    draftElastics,
    setDraftElastics,
    mode,
    elasticsPreview,
    modifiedElasticIndex,
    createEmptyElastic,
    addElastic,
    enterEditMode,
    cancelEdit,
    saveModifiedElastic,
    handleToothClick,
    removeElastic
  }
}
