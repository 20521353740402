import React, { useState, useMemo, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import usePracticeConfiguration from 'hooks/usePracticeConfiguration'

import DazzedParagraph12 from '../../../../common/text/DazzedParagraph12'
import { Eye } from '../../../../common/icons'
import BaseModal from '../../../../common/modals/BaseModal'
import { getEnvironmentHostname } from '../../../../../utils/awsUtils'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'var(--bg-color-33)',
    minHeight: 50,
    padding: '0 20px'
  },
  eye: {
    marginRight: 6
  },
  previewBtn: {
    border: '1px solid var(--border-color-1)',
    borderRadius: 20,
    padding: '3px 12px',
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer'
  },
  btnText: {
    color: 'var(--text-color-3)',
    fontSize: 10
  },
  modal: {
    width: 1000,
    maxWidth: '100%',
    height: '90%',
    padding: 0
  },
  modalContent: {
    overflow: 'hidden',
    height: '100%'
  },
  contentClassName: {
    height: '100%'
  },
  iframe: {
    transform: 'scale(0.5)',
    transformOrigin: '0 0',
    zoom: 0.5,
    height: '200%',
    width: '200%'
  }
}))

const PreviewWebpage = ({
  headerBackgroundColor,
  headerFontColor,
  accentColor,
  footerBackgroundColor,
  footerTextColor,
  saveChanges
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const doctor = useSelector(state => state.profileReducer.doctor)
  const practiceDetails = useSelector(state => state.practiceReducer.details)
  const {
    rcConfiguration: { rcLPBannerPhoto }
  } = usePracticeConfiguration()

  const lpSrc = useMemo(() => {
    const baseUrl = new URL(`/auth/rc/${doctor.rcToken}`, getEnvironmentHostname())
    baseUrl.searchParams.append('name', doctor.name)
    baseUrl.searchParams.append('clinicName', practiceDetails.practiceName)
    baseUrl.searchParams.append('city', practiceDetails.city)
    baseUrl.searchParams.append('country', practiceDetails.country)
    rcLPBannerPhoto && baseUrl.searchParams.append('bannerPhoto', rcLPBannerPhoto.key)
    baseUrl.searchParams.append(
      'colors',
      JSON.stringify({
        headerBackgroundColor,
        headerFontColor,
        accentColor,
        footerBackgroundColor,
        footerTextColor
      })
    )
    return baseUrl.href
  }, [
    accentColor,
    practiceDetails,
    doctor.name,
    doctor.rcToken,
    footerBackgroundColor,
    footerTextColor,
    headerBackgroundColor,
    headerFontColor,
    rcLPBannerPhoto
  ])

  const handlePrimaryBtnClick = useCallback(() => {
    setIsModalOpen(false)
    saveChanges()
  }, [saveChanges])

  const openPreviewModal = useCallback(() => {
    trackEvent('RC design - preview clicked')
    setIsModalOpen(true)
  }, [])

  const handleClosePreviewModal = useCallback(() => {
    trackEvent('RC design - preview closed')
    setIsModalOpen(false)
  }, [])

  return (
    <div className={classes.container}>
      <DazzedParagraph12>{t('pages.accountSettings.services.remoteConsultation.previewWebpageText')}</DazzedParagraph12>
      <div className={classes.previewBtn} onClick={openPreviewModal}>
        <Eye className={classes.eye} />
        <DazzedParagraph12 className={classes.btnText}>
          {t('pages.accountSettings.services.remoteConsultation.previewWebpage')}
        </DazzedParagraph12>
      </div>
      <BaseModal
        title={t('pages.accountSettings.services.remoteConsultation.preview')}
        open={isModalOpen}
        handleClose={handleClosePreviewModal}
        className={classes.modal}
        primaryLabel={t('general.saveChanges')}
        secondaryLabel={t('general.close')}
        onPrimaryBtnClick={handlePrimaryBtnClick}
        handleBackdropClick={handleClosePreviewModal}
        onSecondaryBtnClick={handleClosePreviewModal}
        largerButtons
        contentClassName={classes.contentClassName}
      >
        <div className={classes.modalContent}>
          <iframe title="lp" src={lpSrc} frameBorder="0" className={classes.iframe} />
        </div>
      </BaseModal>
    </div>
  )
}

export default PreviewWebpage
