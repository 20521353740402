import { useCallback, useMemo } from 'react'
import {
  SetNextScan,
  MarkUnread,
  ResendInvite,
  ShowInvitationCode,
  CrossedCircle,
  TransferArrow,
  Assignee,
  MarkRead,
  Paused,
  Resume
} from 'components/common/icons'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import { useDispatch, useSelector } from 'react-redux'
import useRolePermissions from 'hooks/useRolePermissions'
import { isMobile } from 'utils/mobileUtils'
import { PATIENT_CARD_TABS } from 'consts/patientCard'
import usePatientTags from 'hooks/usePatientTags'
import Actions from 'actions'
import { CycleStatuses } from 'consts/treatmentConsts'
import { isUserOfAnyAdminRole, isUserOfRole } from 'utils/authUtils'
import { Roles } from 'consts/authConsts'

const forbiddenStatusesForTransferPatientAction = ['invited', 'archived', 'transferred']

export default ({ status, isSupportDashboard, roomId, patient, isLead = false, hasUnreadMessages }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { permissions } = useRolePermissions()
  const { assigneeTag } = usePatientTags({ patient })

  const doctor = useSelector(state => state.profileReducer.doctor)

  const currentCycle = useMemo(() => patient.cycles?.items?.[0], [patient])
  const isRecordsOnly = useMemo(() => patient?.auth_status === 'added', [patient?.auth_status])

  const sendActionClickedAnalytic = useCallback(
    action => {
      trackEvent('Patient menu item clicked', {
        action,
        status: status?.key,
        patientId: patient?.id
      })
    },
    [status, patient]
  )

  const chatRoomSelectedPayload = useMemo(
    () => ({
      roomId,
      doctor,
      patientId: patient.id,
      disableRedirect: isSupportDashboard
    }),
    [doctor, patient, roomId, isSupportDashboard]
  )

  const allActions = useMemo(
    () => [
      {
        icon: <Resume />,
        title: t('pages.patients.patientsList.patientListItem.actionsMenu.resumeScans'),
        onClick: () => {
          sendActionClickedAnalytic('Resume scans')
          dispatch(
            Actions.setCycleStatus({
              patientId: patient.id,
              cycleId: currentCycle.id,
              status: CycleStatuses.Active
            })
          )
        },
        condition: () =>
          patient?.user?.isActive === true &&
          patient?.auth_status !== 'transferred' &&
          currentCycle?.status === CycleStatuses.Paused &&
          status?.scanCyclesEnabled &&
          !isUserOfRole([Roles.Admin])
      },
      {
        icon: <SetNextScan />,
        title: t('pages.patients.patientsList.patientListItem.actionsMenu.setNextScan'),
        onClick: () => {
          sendActionClickedAnalytic('Set Next Scan')
          if (isMobile()) {
            !isSupportDashboard && dispatch(Actions.timelineSelected(chatRoomSelectedPayload))
            dispatch(
              Actions.toggleMobilePatientCard({
                isModalOpen: true
              })
            )
          }
          if (!isMobile()) {
            dispatch(Actions.chatRoomSelected(chatRoomSelectedPayload))
            dispatch(
              Actions.togglePatientCard({
                isModalOpen: true,
                patientId: patient.id,
                selectedTab: PATIENT_CARD_TABS.scanSettings.index
              })
            )
          }
        },
        condition: () =>
          patient?.user?.isActive === true &&
          patient?.auth_status !== 'transferred' &&
          status?.scanCyclesEnabled &&
          currentCycle?.status !== CycleStatuses.Paused
      },
      {
        icon: <Paused color="#212121" />,
        title: t('pages.patients.patientsList.patientListItem.actionsMenu.pauseScans'),
        onClick: () => {
          sendActionClickedAnalytic('Pause scans')
          dispatch(
            Actions.setCycleStatus({
              patientId: patient.id,
              cycleId: currentCycle.id,
              status: CycleStatuses.Paused
            })
          )
        },
        condition: () =>
          patient?.user?.isActive === true &&
          patient?.auth_status !== 'transferred' &&
          currentCycle?.status !== CycleStatuses.Paused &&
          status?.scanCyclesEnabled &&
          !isUserOfRole([Roles.Admin])
      },
      {
        icon: <ShowInvitationCode />,
        title: 'See invitation code',
        onClick: () => {
          sendActionClickedAnalytic('See invitation code')
          dispatch(
            Actions.openInvitationCodeModal({
              invitationCode: patient.invitationCode,
              guardianId: patient.guardianId,
              isOldInvitation: patient.auth_status === 'invited' && !isLead
            })
          )
        },
        condition: () => patient?.auth_status === 'invited' && !isRecordsOnly
      },
      {
        icon: <ResendInvite />,
        title: isRecordsOnly
          ? t('pages.patients.patientsList.patientListItem.actionsMenu.sendInvitation')
          : t('pages.patients.patientsList.patientListItem.actionsMenu.resendInvitation'),
        onClick: () => {
          sendActionClickedAnalytic(isRecordsOnly ? 'Send invite (GPs)' : 'Resend invite')
          dispatch(Actions.showResendPatientInvitationModal({ ...patient, isLead, isRecordsOnly }))
        },
        condition: () => patient?.auth_status === 'invited' || isRecordsOnly
      },
      {
        icon: <CrossedCircle />,
        title: t('pages.patients.patientsList.patientListItem.actionsMenu.deletePatient'),
        onClick: () => {
          sendActionClickedAnalytic('Delete patient')
          dispatch(Actions.deletePatientInitiated({ ...patient, isLead }))
        },
        condition: () => patient?.auth_status === 'invited' || isRecordsOnly || permissions.deleteConfirmedPatients
      },
      {
        icon: <MarkUnread />,
        title: t('pages.patients.patientsList.patientListItem.actionsMenu.markUnread'),
        onClick: () => {
          sendActionClickedAnalytic('Mark unread')
          dispatch(
            Actions.markRoomUnread({
              roomId,
              analytics: {
                reason: 'manual',
                patientId: patient.id
              }
            })
          )
        },
        condition: () =>
          patient?.auth_status !== 'transferred' &&
          !hasUnreadMessages &&
          permissions.toggleRoomReadStatus &&
          !isRecordsOnly
      },
      {
        icon: <MarkRead />,
        title: t('pages.patients.patientsList.patientListItem.actionsMenu.markRead'),
        onClick: () => {
          sendActionClickedAnalytic('Mark as read')
          dispatch(
            Actions.markRoomRead({
              roomId,
              analytics: {
                reason: 'manual',
                patientId: patient.id
              }
            })
          )
        },
        condition: () =>
          patient?.auth_status !== 'transferred' &&
          hasUnreadMessages &&
          permissions.toggleRoomReadStatus &&
          !isRecordsOnly
      },
      {
        icon: <Assignee color={'var(--text-color-43)'} />,
        title: !assigneeTag
          ? t('pages.patients.patientsList.patientListItem.actionsMenu.assignToDoctor')
          : t('pages.patients.patientsList.patientListItem.actionsMenu.manageAssignee'),
        onClick: () => {
          trackEvent('Assign patient - doctor click on assign patient to team member', {
            origin: 'Patients list'
          })
          dispatch(Actions.setAssignPatient({ patient, isModalOpen: true }))
        },
        condition: () =>
          patient?.auth_status !== 'transferred' && permissions.setPatientAssignee && !isUserOfAnyAdminRole()
      },
      {
        icon: <TransferArrow />,
        title: t('pages.patients.patientsList.patientListItem.actionsMenu.transferPatient'),
        onClick: () => {
          sendActionClickedAnalytic('Transfer patient')
          dispatch(Actions.chatRoomSelected(chatRoomSelectedPayload))
          dispatch(Actions.setTransferPatientModalVisibility(true))
        },
        condition: () =>
          permissions.transferPatient &&
          !isMobile() &&
          !forbiddenStatusesForTransferPatientAction.includes(status?.type)
      }
    ],
    [
      t,
      assigneeTag,
      sendActionClickedAnalytic,
      dispatch,
      patient,
      currentCycle,
      status,
      isSupportDashboard,
      chatRoomSelectedPayload,
      isLead,
      isRecordsOnly,
      permissions,
      roomId,
      hasUnreadMessages
    ]
  )

  const actions = useMemo(() => allActions.filter(action => !action.condition || action.condition()), [allActions])

  return {
    allActions,
    actions
  }
}
