import * as React from 'react'

function UpperAndLowerArches(props) {
  return (
    <svg width="41" height="17" viewBox="0 0 41 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.09961 10.5L1.09961 11V10L1.59961 9L1.09961 8V6.5V4H1.59961L2.09961 4.5L3.09961 3.5L3.59961 4.5L4.09961 3L4.59961 3.5L5.59961 4.5L6.09961 2.5L6.59961 1.5L8.09961 3L9.09961 4H9.59961L10.5996 2.5L11.5996 1.5L12.0996 2L13.5996 3.5L14.5996 2.5L16.0996 1.5L17.5996 1L19.0996 2L20.0996 3H20.5996L21.5996 2L23.0996 1L24.5996 1.5L26.0996 2.5L27.0996 4L27.5996 3L29.0996 1.5L30.0996 2.5L31.0996 4L32.0996 3.5L33.0996 2.5L34.0996 1.5L34.5996 2.5V3.5L35.0996 4.5L36.5996 3L37.0996 4.5L37.5996 3.5L38.0996 4L38.5996 4.5L39.0996 4H39.5996V4.5V6.5V8.5L39.0996 9L39.5996 11H39.0996L38.5996 10.5L38.0996 12L37.0996 11.5L36.5996 11V13.5L36.0996 13L35.5996 12.5L35.0996 14L34.0996 13.5L33.5996 13L33.0996 11.5V13.5L32.5996 15L32.0996 16H31.0996L30.0996 15L29.5996 14L29.0996 12.5L28.5996 12V14.5L28.0996 15.5L27.5996 16L26.5996 15.5L26.0996 15L25.5996 13.5L25.0996 12.5L24.5996 12L24.0996 15L23.0996 16H22.0996L21.0996 15L20.5996 13.5V12.5L20.0996 12V12.5L19.5996 15L18.5996 16H17.5996L17.0996 15.5L16.5996 14.5L16.0996 13.5V12H15.5996L14.5996 15L13.0996 16H12.5996L12.0996 15V14L11.5996 12.5L11.0996 14L10.0996 15.5L9.09961 16H8.59961L8.09961 15L7.59961 13.5V11.5L6.59961 13.5L5.59961 14L5.09961 11L4.09961 13.5V10.5L3.59961 11L2.59961 12L2.09961 11.5V10.5Z"
        fill="#737B8A"
        fillOpacity="0.25"
      />
      <path
        d="M20.09 10C20.2567 12 20.09 16 18.09 16C16.09 16 15.9233 12.3333 16.09 10.5C15.7567 12.3333 14.69 16 13.09 16C11.49 16 11.7566 11.3333 12.09 9C11.923 11.3333 11.0891 16 9.08906 16C7.08906 16 7.58906 11.3333 8.08906 9C7.53333 11.5934 7.09001 14 5.58911 14C5.08903 14 4.88911 10.6 6.08911 9C4.58903 12 4.59238 14 4.09184 13.5C3.73816 13.1467 3.78903 10.2 4.58903 9C3.59153 11 3.39184 12 2.59184 12C1.79184 12 2.25851 10 2.59184 9C2.42507 9.83333 1.88591 11.411 1.09153 11C0.800814 10.8496 1.26628 9.66667 1.59961 9"
        stroke="#737B8A"
      />
      <path
        d="M20.5096 10C20.343 12 20.5096 16 22.5096 16C24.5096 16 24.6763 12.3333 24.5096 10.5C24.843 12.3333 25.9096 16 27.5096 16C29.1096 16 28.843 11.3333 28.5096 9C28.6766 11.3333 29.5106 16 31.5106 16C33.5106 16 33.0106 11.3333 32.5106 9C33.0663 11.5934 33.5096 14 35.0105 14C35.5106 14 35.7996 10.6 34.5996 9C36.0997 12 36.0072 14 36.5078 13.5C36.8614 13.1467 36.8106 10.2 36.0106 9C37.0081 11 37.2078 12 38.0078 12C38.8078 12 38.3411 10 38.0078 9C38.1745 9.83333 38.7137 11.411 39.5081 11C39.7988 10.8496 39.3333 9.66667 39 9"
        stroke="#737B8A"
      />
      <path
        d="M1.25105 8.49451C1.05765 7.96264 1.08988 5.37179 1.13018 4.14286C1.21072 4.08242 1.42018 3.96154 1.61358 3.96154C1.80699 3.96154 2.17768 4.40476 2.33886 4.62637C2.50003 4.28388 2.85864 3.5989 3.00369 3.5989C3.14875 3.5989 3.46706 4.28388 3.60809 4.62637C3.70882 4.12271 3.97072 3.11538 4.21248 3.11538C4.45424 3.11538 5.36083 4.24359 5.78391 4.80769C5.90479 3.73993 6.24325 1.6044 6.63007 1.6044C7.01688 1.6044 8.64472 3.337 9.41028 4.2033C9.8938 3.337 11.0059 1.6044 11.5861 1.6044C12.1663 1.6044 13.2381 2.97436 13.7015 3.65934C14.3663 2.77289 15.9982 1 17.207 1C18.4158 1 19.8663 2.61172 20.3498 3.41758V9.46154C20.1081 9.80403 19.8059 10.489 16.7839 10.489C14.1246 10.489 13.7619 9.0989 13.5202 8.73626C11.5861 9.94506 9.8938 8.85714 9.41028 7.89011C9.16853 8.37363 7.41578 9.76374 6.75094 9.76374C6.21908 9.76374 5.88464 8.837 5.78391 8.37363C5.42127 9.0989 3.78941 9.21978 3.54765 8.97802C3.25754 9.17143 2.50003 9.01832 2.27842 8.85714C1.93596 8.95788 1.44446 9.02637 1.25105 8.49451Z"
        stroke="#737B8A"
      />
      <path d="M13.7008 3.65942L13.5195 8.73635" stroke="#737B8A" />
      <path d="M9.41016 4.20337V7.89018" stroke="#737B8A" />
      <path d="M5.7832 4.80762V8.37355" stroke="#737B8A" />
      <path d="M3.60731 4.62646L3.54688 8.97811" stroke="#737B8A" />
      <path d="M2.33974 4.62646L2.2793 8.85723" stroke="#737B8A" />
      <path
        d="M39.4482 8.49451C39.6416 7.96264 39.6093 5.37179 39.569 4.14286C39.4885 4.08242 39.279 3.96154 39.0856 3.96154C38.8922 3.96154 38.5215 4.40476 38.3604 4.62637C38.1992 4.28388 37.8406 3.5989 37.6955 3.5989C37.5505 3.5989 37.2322 4.28388 37.0911 4.62637C36.9904 4.12271 36.7285 3.11538 36.4867 3.11538C36.245 3.11538 35.3384 4.24359 34.9153 4.80769C34.7944 3.73993 34.456 1.6044 34.0692 1.6044C33.6823 1.6044 32.0545 3.337 31.2889 4.2033C30.8054 3.337 29.6933 1.6044 29.1131 1.6044C28.5329 1.6044 27.4611 2.97436 26.9977 3.65934C26.3329 2.77289 24.701 1 23.4922 1C22.2834 1 20.8329 2.61172 20.3494 3.41758V9.46154C20.5911 9.80403 20.8933 10.489 23.9153 10.489C26.5746 10.489 26.9373 9.0989 27.179 8.73626C29.1131 9.94506 30.8054 8.85714 31.2889 7.89011C31.5307 8.37363 33.2834 9.76374 33.9483 9.76374C34.4801 9.76374 34.8146 8.837 34.9153 8.37363C35.2779 9.0989 36.9098 9.21978 37.1516 8.97802C37.4417 9.17143 38.1992 9.01832 38.4208 8.85714C38.7633 8.95788 39.2548 9.02637 39.4482 8.49451Z"
        stroke="#737B8A"
      />
      <path d="M26.9984 3.65942L27.1797 8.73635" stroke="#737B8A" />
      <path d="M31.2891 4.20337V7.89018" stroke="#737B8A" />
      <path d="M34.916 4.80762V8.37355" stroke="#737B8A" />
      <path d="M37.0919 4.62646L37.1523 8.97811" stroke="#737B8A" />
      <path d="M38.3595 4.62646L38.4199 8.85723" stroke="#737B8A" />
    </svg>
  )
}

export default UpperAndLowerArches
