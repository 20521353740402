import React, { useCallback, useEffect } from 'react'
import { Grid, Snackbar, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../actions'
import Slide from '@material-ui/core/Slide'
import { Trans, useTranslation } from 'react-i18next'
import usePms from 'hooks/usePms'
import { trackEvent } from 'utils/analyticsUtils'
import { Close2 } from 'components/common/icons'
import actions from '../../actions'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { isMobile } from 'utils/mobileUtils'
import DolphinIcon from 'components/common/icons/DolphinIcon'

const useStyles = makeStyles({
  snackbar: {
    top: '16px !important',
    zIndex: '700 !important'
  },
  ctaContainer: {
    position: 'relative',
    padding: '0px 18px 0px 8px',
    backgroundColor: 'var(--bg-color-64)',
    borderRadius: 6
  },
  ctaDescription: {
    whiteSpace: 'pre-line'
  },
  ctaText: {
    textDecoration: 'underline',
    color: 'var(--text-color-25)',
    cursor: 'pointer',
    userSelect: 'none'
  },
  closeIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
    cursor: 'pointer'
  }
})

const DolphinSnackbar = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const { authenticatePms, getPmsToken, doctorPms } = usePms()

  const { doctor } = useSelector(state => state.profileReducer)
  const { isOpen, wasAlreadyOpened } = useSelector(state => state.pmsReducer.snackbar.dolphin)
  const { latestPatientsToInvite } = useSelector(state => state.pmsReducer)

  const handleClose = useCallback(() => dispatch(Actions.pmsToggleSnackbar(false)), [dispatch])
  const handleCtaClick = useCallback(() => {
    trackEvent('Dolphin invite latest patients snackbar - view patients clicked')
    handleClose()
    dispatch(actions.pmsToggleInvitePatientsModal())
  }, [dispatch, handleClose])

  useEffect(() => {
    if (isOpen) {
      trackEvent('Dolphin invite latest patients snackbar - displayed', {
        numOfPatients: latestPatientsToInvite?.length
      })
    }
  }, [isOpen, latestPatientsToInvite])

  useEffect(() => {
    if (doctorPms && doctor.id && !getPmsToken()) {
      authenticatePms()
    }
  }, [authenticatePms, doctor, doctorPms, getPmsToken])

  useEffect(() => {
    if (!isMobile() && !!doctorPms && doctor.id && latestPatientsToInvite?.length > 0 && !isOpen && !wasAlreadyOpened) {
      dispatch(Actions.pmsToggleSnackbar(true))
    }
  }, [dispatch, doctor.id, doctorPms, isOpen, latestPatientsToInvite?.length, wasAlreadyOpened])

  useEffect(() => {
    if (!!doctorPms && doctor.id && !latestPatientsToInvite && !!getPmsToken()) {
      dispatch(Actions.pmsFetchLatestPatients())
    }
  }, [dispatch, doctor, doctorPms, getPmsToken, latestPatientsToInvite])

  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={Slide}
      onClose={handleClose}
      disableWindowBlurListener
      ClickAwayListenerProps={{ onClickAway: () => null }}
      className={classes.snackbar}
    >
      <Grid container alignItems="center" spacing={2} className={classes.ctaContainer}>
        <Grid item>
          <DolphinIcon />
        </Grid>
        <Grid item>
          <DazzedParagraph14 strong textAlign="center" className={classes.ctaDescription}>
            <Trans
              values={{
                count: latestPatientsToInvite?.length ?? 0,
                patients: latestPatientsToInvite?.length > 1 ? 'patients' : 'patient'
              }}
              i18nKey={'pms.snackbar.dolphin.description'}
              components={{ bold: <b>{''}</b> }}
            />
          </DazzedParagraph14>
        </Grid>
        <Grid item>
          <DazzedParagraph14 bold className={classes.ctaText} onClick={handleCtaClick}>
            {t('pms.snackbar.dolphin.ctaText')}
          </DazzedParagraph14>
        </Grid>
        <div className={classes.closeIcon} onClick={handleClose}>
          <Close2 color="#000000" />
        </div>
      </Grid>
    </Snackbar>
  )
}

export default DolphinSnackbar
