import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import GrinCheckbox from 'components/common/GrinCheckbox'
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
  text: {
    color: 'var(--text-color-27)'
  },
  checkbox: {
    padding: '3px 6px'
  }
}))

const PreventiveFeedbackParameters = ({ selectedParameters, onChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const diagnosisParameters = useMemo(
    () => [
      t('dialogs.scanSummary.preventive.feedback.parameters.inflamation'),
      t('dialogs.scanSummary.preventive.feedback.parameters.plaque'),
      t('dialogs.scanSummary.preventive.feedback.parameters.recession'),
      t('dialogs.scanSummary.preventive.feedback.parameters.tartar')
    ],
    [t]
  )

  return (
    <Grid container>
      {diagnosisParameters.map((parameter, i) => (
        <Grid item xs={6} key={`preventive-feedback-parameter-item-${i}`}>
          <GrinCheckbox
            className={classes.checkbox}
            variant="filled"
            checked={selectedParameters.includes(parameter)}
            onCheck={() => onChange([...selectedParameters, parameter])}
            onUncheck={() => onChange(_.remove(selectedParameters, p => p !== parameter))}
            caption={parameter}
            captionClassName={classes.text}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default PreventiveFeedbackParameters
