import React from 'react'
import { makeStyles } from '@material-ui/styles'

import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  feedCardRoot: {
    margin: 12,
    padding: `24px`,
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.06)',
    borderRadius: 16
  },
  feedCardTitleContainer: {
    marginBottom: 24
  }
}))

const ScanFeedCard = ({ title, titleIcon, className = '', titleClassName = '', children, id = '' }) => {
  const classes = useStyles()

  return (
    <div className={[classes.feedCardRoot, className].join(' ')} id={id}>
      {title && (
        <Grid container alignItems="center" className={[classes.feedCardTitleContainer, titleClassName].join(' ')}>
          {titleIcon && <Grid item>{titleIcon}</Grid>}
          <Grid item>
            <DazzedParagraph14 bold>{title}</DazzedParagraph14>
          </Grid>
        </Grid>
      )}
      {children}
    </div>
  )
}

export default ScanFeedCard
