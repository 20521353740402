import { useCallback, useMemo } from 'react'
import { createdAtComparator } from 'utils/dateUtils'

/**
 * @prop grinScans - the list of grinScans returned from the `useScanFeed` hook.
 * @returns a list the referrals associated with the provided grin scans, sorted by date desc, with the following structure:
 * ```
 * {
 *  id,
 *  scanId,
 *  withAligner,
 *  createdAt,
 *  referralName,
 *  referralSpeciality,
 *  notificationDestination,
 *  grinDoctorNote,
 *  referralReview,
 *  reviewSubmissionDate,
 *  attachments: [S3Object],
 *  createdBy: { name, roleDescription }
 * }
 * ```
 */
export default ({ grinScans = [] }) => {
  const mapScanReferral = useCallback(({ scan, referral }) => {
    const {
      name: referralName,
      speciality: referralSpeciality,
      files: attachments
    } = JSON.parse(referral.referralData || '{}')
    return {
      id: referral.id,
      scanId: scan.id,
      createdAt: referral.createdAt,
      withAligner: scan.withAligner,
      referralName,
      referralSpeciality,
      notificationDestination: referral.notificationDestination,
      grinDoctorNote: referral.grinDoctorNote,
      referralReview: referral.referralReview,
      reviewSubmissionDate: referral.reviewSubmissionDate,
      attachments,
      createdBy: referral.doctor
    }
  }, [])

  const referrals = useMemo(
    () =>
      grinScans
        .map(scan => scan.referrals?.items?.map(referral => mapScanReferral({ scan, referral })).flat())
        .flat()
        .sort(createdAtComparator)
        .reverse(),
    [grinScans, mapScanReferral]
  )

  return referrals
}
