import React, { useCallback, useMemo, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress, Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import useRolePermissions from 'hooks/useRolePermissions'
import DisplaySavedFiles from './DisplaySavedFiles'
import { DROPZONE_ACCEPTABLE_FILE_TYPES } from 'consts/appConsts'
import useFiles from 'hooks/useFiles'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { b64toBlob } from 'utils/fileUtils'
import { trackEvent } from 'utils/analyticsUtils'
import { AsyncStatus } from 'consts'
import ErrorMessage from 'components/common/text/ErrorMessage'
import useSavedFiles from './useSavedFiles'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles(() => ({
  container: {
    padding: 10,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  addFileTitle: {
    color: 'var(--text-color-54)',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  uneditable: {
    cursor: 'auto',
    color: 'var(--text-color-4)'
  }
}))

const FilesLibraryTab = ({ isSystem, onSelectFile = () => {}, handleCloseSavedFiles }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { validateFilesSize } = useFiles({ additionalMapper: file => ({ originalKey: file.name, key: file.name }) })
  const { practiceFiles, systemFilesByCategory } = useSavedFiles()
  const { status } = useSelector(state => state.practiceReducer.savedFiles)

  const { permissions } = useRolePermissions()
  const uploadInputRef = useRef(null)

  const editable = useMemo(() => !isSystem && permissions.editSavedFiles, [isSystem, permissions])

  const attachFile = useCallback(
    message => {
      if (!editable) {
        return
      }

      uploadInputRef.current.click()
    },
    [editable]
  )

  const handleAddNewFile = useCallback(
    async e => {
      trackEvent('Saved files - New file added')
      const filteredFiles = await validateFilesSize({ files: e.target.files })

      if (filteredFiles.length > 0) {
        const file = filteredFiles[0]
        dispatch(
          Actions.addSavedFile({
            file: {
              ...file,
              data: b64toBlob(file.data.split(',')[1], file.type)
            }
          })
        )
      }
    },
    [dispatch, validateFilesSize]
  )

  return (
    <>
      <Grid container direction="column" wrap="nowrap" className={classes.container} spacing={1}>
        {status === AsyncStatus.Loading ? (
          <Grid item style={{ height: '100%' }}>
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
              <Grid item>
                <CircularProgress size={30} />
              </Grid>
            </Grid>
          </Grid>
        ) : status === AsyncStatus.Failed ? (
          <Grid item style={{ height: '100%' }}>
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
              <Grid item>
                <ErrorMessage active center text={t('pages.patients.selectedPatient.chat.dropzone.savedFiles.error')} />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container direction="column" spacing={1}>
              {!isSystem && (
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <Tooltip
                        placement="top"
                        disableTooltip={editable}
                        value={t(
                          'pages.patients.selectedPatient.chat.dropzone.savedFiles.tabs.practiceLibrary.addNewFileTooltip'
                        )}
                        arrow
                      >
                        <DazzedParagraph14
                          className={`${classes.addFileTitle} ${!editable ? classes.uneditable : ''}`}
                          onClick={attachFile}
                        >
                          {t(
                            'pages.patients.selectedPatient.chat.dropzone.savedFiles.tabs.practiceLibrary.addFileTitle'
                          )}
                        </DazzedParagraph14>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item>
                {isSystem ? (
                  <Grid container direction="column" wrap="nowrap" spacing={1}>
                    {Object.keys(systemFilesByCategory).map(category => (
                      <Grid item key={category}>
                        <Grid container direction="column" wrap="nowrap">
                          <Grid item>
                            <DazzedParagraph12 thickness="bold">{category}</DazzedParagraph12>
                          </Grid>
                          <Grid item>
                            <DisplaySavedFiles
                              files={systemFilesByCategory[category]}
                              onSelectFile={onSelectFile}
                              handleCloseSavedFiles={handleCloseSavedFiles}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <DisplaySavedFiles
                    files={practiceFiles}
                    editable={editable}
                    onSelectFile={onSelectFile}
                    handleCloseSavedFiles={handleCloseSavedFiles}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <input
        multiple={false}
        ref={uploadInputRef}
        type="file"
        accept={DROPZONE_ACCEPTABLE_FILE_TYPES.join(',')}
        onChange={handleAddNewFile}
        onClick={() => {}}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default FilesLibraryTab
