import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import SelectInput from 'components/common/SelectInput'
import ScanView from 'components/common/ScanView/ScanView'
import Actions from 'actions'
import MissingScanMessage from './MissingScanMessage'
import { trackEvent } from 'utils/analyticsUtils'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import useScansComparison from './useScansComparison'

const useStyles = makeStyles(theme => ({
  root: {
    width: 560
  },
  media: {
    height: '332px!important',
    borderRadius: '12px!important'
  },
  toggleAlignersButtonContainer: {
    position: 'absolute',
    top: 15,
    left: 15,
    zIndex: 999
  },
  toggleAlignersButton: {
    padding: '6px 10px',
    fontSize: 12,
    fontWeight: 500
  }
}))

const ScanSelection = ({
  scanSelectionOptions = [],
  side,
  scanLinkage,
  preventiveLayers,
  displayedPreventiveLayers,
  isAnyPreventiveLayerVisible,
  onTogglePreventiveLayer,
  onToggleAllPreventiveLayers,
  onClearActiveLayers
}) => {
  const classes = useStyles()
  const videoRef = useRef()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { selectedScans } = useScansComparison()

  const { scanIndex, selectedTile, withAligners } = useSelector(
    state => state.treatmentReducer.compareScans[side] || {}
  )

  const selectedScan = useMemo(() => selectedScans[side], [selectedScans, side])

  const updateSelection = useCallback(
    ({ scanIndex: newScanIndex, selectedTile: newSelectedTile, withAligners: newWithAligners }) => {
      dispatch(
        Actions.compareScansSetScanSelection({
          side,
          scanIndex: newScanIndex ?? scanIndex,
          selectedTile: newSelectedTile ?? selectedTile,
          withAligners: newWithAligners ?? withAligners
        })
      )
    },
    [dispatch, side, scanIndex, selectedTile, withAligners]
  )

  const setScanIndex = useCallback(
    scanIndex => {
      trackEvent('Compare Scans - scan selected', { scanIndex, side })
      updateSelection({ scanIndex: scanIndex - 1 })
    },
    [updateSelection, side]
  )

  const setPose = useCallback(
    poseIndex => {
      trackEvent('Compare Scans - set pose', { poseIndex, side })
      updateSelection({ selectedTile: poseIndex })
    },
    [updateSelection, side]
  )

  const toggleWithAligners = useCallback(() => {
    const updatedValue = !withAligners
    trackEvent(`Compare Scans - toggle with/without aligners`, { withAligners: updatedValue ? 'Yes' : 'No', side })
    updateSelection({ withAligners: updatedValue })
  }, [updateSelection, side, withAligners])

  useEffect(() => {
    const video = videoRef.current
    if (!video) {
      return
    }

    video.side = side
    scanLinkage.registerVideo(video)
    return () => scanLinkage.unregisterVideo(video)
  }, [scanLinkage, side])

  return (
    <Grid container direction="column" spacing={1} className={classes.root}>
      <Grid item>
        <SelectInput
          style={{ bright: true, thick: true }}
          options={scanSelectionOptions}
          onChange={({ value }) => setScanIndex(value)}
          value={scanIndex + 1}
        />
      </Grid>
      <Grid item style={{ position: 'relative' }}>
        {selectedScan?.scanPairId && (
          <div className={classes.toggleAlignersButtonContainer}>
            <PrimaryButton
              color="var(--bg-color-10)"
              width="auto"
              height="auto"
              label={
                withAligners
                  ? t('pages.patients.selectedPatient.timeline.compareScans.withAligners')
                  : t('pages.patients.selectedPatient.timeline.compareScans.withoutAligners')
              }
              fontColor="var(--text-color-25)"
              className={classes.toggleAlignersButton}
              onClick={toggleWithAligners}
            />
          </div>
        )}
        {selectedScan ? (
          <ScanView
            grinScanVideo={selectedScan?.video}
            scanSummaryData={selectedScan?.scanSummaryData}
            ref={videoRef}
            selectedOptionIndex={selectedTile}
            onOptionSelect={setPose}
            mediaClassName={classes.media}
            preventiveLayers={preventiveLayers}
            displayedPreventiveLayers={displayedPreventiveLayers}
            isAnyPreventiveLayerVisible={isAnyPreventiveLayerVisible}
            onTogglePreventiveLayer={onTogglePreventiveLayer}
            onToggleAllPreventiveLayers={onToggleAllPreventiveLayers}
            onClearActiveLayers={onClearActiveLayers}
          />
        ) : (
          <MissingScanMessage
            message={
              withAligners
                ? t('pages.patients.selectedPatient.timeline.compareScans.scanWithAlignersMissing')
                : t('pages.patients.selectedPatient.timeline.compareScans.scanWithoutAlignersMissing')
            }
          />
        )}
      </Grid>
    </Grid>
  )
}

export default ScanSelection
