import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react'
import { makeStyles, TextareaAutosize, IconButton } from '@material-ui/core'
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Picker from 'emoji-picker-react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { isMobile } from 'utils/mobileUtils'
import { setDoctorsMessageDraft, removeDoctorsMessageDraft } from 'utils/storageUtils'
import BroadcastConfirmationModal from '../Broadcast/BroadcastConfirmationModal'
import Actions from 'actions'
import useBroadcast from 'hooks/useBroadcast'
import { trackEvent } from 'utils/analyticsUtils'
import SendButton from './SendButton/SendButton'
import ChatFilesPreview from './ChatFilesPreview'
import ChatActionsMenuMobile from './ChatActionsMenu.mobile'

const NewMessageInput = ({
  handleMessageSend,
  handleFileSend,
  handlePromotionSend,
  setPromotion,
  promotionToSend,
  message,
  inputFocus,
  setInputFocus,
  setMessage,
  patientId,
  setFilesToSend,
  filesToSend
}) => {
  const disabled = {
    background: 'rgba(242, 243, 245, 0.5)'
  }
  const useStyles = ({ isMobile }) =>
    makeStyles({
      disabled: {
        ...disabled
      },
      inputContainer: {
        width: '100%',
        display: 'flex',
        position: 'relative',
        alignItems: 'flex-end'
      },
      inputWrapper: {
        flex: '1',
        width: '100%',
        border: '1px solid rgba(0, 19, 53, 0.1)',
        borderRadius: '35px',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        background: 'var(--bg-color-10)',
        minHeight: '48px',
        boxSizing: 'border-box'
      },
      input: {
        width: '100%',
        resize: 'none',
        minHeight: '35px',
        fontFamily: 'Dazzed',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: isMobile ? 16 : 14,
        lineHeight: '20px',
        border: 'none',
        padding: '13px 0 0 13px',
        marginBottom: 13,
        '&:focus': {
          outline: 'none'
        },
        '&:disabled': {
          ...disabled
        }
      },
      send: {
        marginLeft: '8px',
        display: 'flex',
        border: '1px solid #3C52EF',
        height: 50,
        width: 50,
        position: 'relative',
        background: '#fafafa',
        boxSizing: 'border-box'
      },
      sendButtonContainer: {
        padding: '0 8px'
      },
      emojiContainer: {
        display: 'flex',
        alignItems: 'flex-end'
      },
      emojiButton: {
        color: 'var(--text-color-12)',
        '&:hover': {
          backgroundColor: 'unset'
        }
      }
    })({ isMobile })

  const [displayEmojiPicker, setDisplayEmojiPicker] = useState(false)
  const emojiRef = useDetectClickOutside({ onTriggered: () => setDisplayEmojiPicker(false) })
  const textareaRef = useRef()

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles({ isMobile: isMobile() })
  const { trackBroadcastEvent } = useBroadcast()

  const sendAllowed = useMemo(
    () => !!message.trim() || promotionToSend || filesToSend.length,
    [message, promotionToSend, filesToSend]
  )

  const isBroadcastMode = useSelector(state => state.chatReducer.broadcast.isModeOn)

  useEffect(() => {
    inputFocus && textareaRef.current?.focus()
    inputFocus && setInputFocus(false)
  }, [inputFocus, setInputFocus])

  const handleShowEmoji = useCallback(() => {
    setDisplayEmojiPicker(prevState => !prevState)
  }, [setDisplayEmojiPicker])

  const sendMessage = useCallback(
    options => {
      let messageType = filesToSend.length ? 'files' : promotionToSend ? 'promotion' : 'text'
      if (isBroadcastMode) {
        trackBroadcastEvent('Broadcast: Confirm broadcast modal', {
          messageType
        })
      }
      if (filesToSend.length) {
        handleFileSend(filesToSend, options)
        setFilesToSend([])
      } else if (promotionToSend) {
        handlePromotionSend(promotionToSend, options)
        setPromotion(null)
      } else {
        message?.trim() !== '' && handleMessageSend(message, options)
        setMessage('')
        setDisplayEmojiPicker(false)
        removeDoctorsMessageDraft(patientId)
      }

      trackEvent('Chat - Chat message sent', {
        messageType,
        patientId
      })
    },
    [
      handleFileSend,
      handleMessageSend,
      handlePromotionSend,
      filesToSend,
      isBroadcastMode,
      message,
      patientId,
      promotionToSend,
      setFilesToSend,
      setMessage,
      setPromotion,
      trackBroadcastEvent
    ]
  )

  const handleSendMessage = useCallback(
    (options = {}, trigger = 'button click') => {
      const messageType = filesToSend.length ? 'files' : promotionToSend ? 'promotion' : 'text'
      if (isBroadcastMode && sendAllowed) {
        dispatch(Actions.toggleBroadcastModal(true))
        trackBroadcastEvent('Broadcast: Click send message button', {
          messageType
        })
      } else {
        sendMessage(options)
        trackEvent(options.scheduleTime ? 'Chat - scheduled message sent' : 'Chat - message sent', {
          trigger,
          messageType,
          patientId
        })
      }
    },
    [
      isBroadcastMode,
      promotionToSend,
      filesToSend.length,
      sendAllowed,
      dispatch,
      trackBroadcastEvent,
      sendMessage,
      patientId
    ]
  )

  const enterPressed = useCallback(
    e => {
      if (!isMobile() && e.key === 'Enter' && !e.shiftKey) {
        handleSendMessage({}, 'enter key')
        e.preventDefault()
        return false
      }
      return true
    },
    [handleSendMessage]
  )

  const removeImagePreview = useCallback(
    (id, isPromotion) => {
      if (isPromotion) {
        setPromotion(null)
      } else {
        setFilesToSend(filesToSend.filter(item => item.key !== id))
      }
    },
    [setPromotion, setFilesToSend, filesToSend]
  )

  const handleEmojiClick = useCallback(
    emojiObj => {
      setMessage(message + emojiObj.emoji)
    },
    [message, setMessage]
  )

  const handleCloseBroadcastModal = useCallback(() => {
    dispatch(Actions.toggleBroadcastModal(false))
    trackBroadcastEvent('Broadcast: Cancel broadcast modal')
  }, [dispatch, trackBroadcastEvent])

  return (
    <div className={classes.inputContainer} ref={emojiRef}>
      <div className={classes.inputWrapper}>
        {promotionToSend || filesToSend.length ? (
          <ChatFilesPreview
            items={promotionToSend ? [{ ...promotionToSend, messageType: 'promotion' }] : filesToSend}
            removePreview={removeImagePreview}
            isPromotion={!!promotionToSend}
          />
        ) : (
          <TextareaAutosize
            id="patient-room-chat-message-textbox"
            ref={textareaRef}
            maxRows={3}
            placeholder={t('pages.patients.selectedPatient.chat.inputPlaceholder')}
            className={classes.input}
            value={message}
            onChange={({ target: { value } }) => setMessage(value)}
            onKeyDown={enterPressed}
            onBlur={() => setDoctorsMessageDraft(patientId, message)}
          />
        )}
        {!isMobile() && !filesToSend.length && !promotionToSend && (
          <div className={classes.emojiContainer}>
            <IconButton onClick={handleShowEmoji} className={classes.emojiButton}>
              <InsertEmoticonIcon />
            </IconButton>
          </div>
        )}
      </div>
      {displayEmojiPicker && (
        <Picker
          disableSkinTonePicker={true}
          disableAutoFocus={true}
          pickerStyle={{ position: 'absolute', bottom: 75, right: 70 }}
          onEmojiClick={(event, emojiObj) => handleEmojiClick(emojiObj)}
        />
      )}
      <div className={classes.sendButtonContainer}>
        <SendButton onSend={handleSendMessage} disabled={!sendAllowed} />
      </div>
      {isMobile() && <ChatActionsMenuMobile onSend={handleSendMessage} disabled={!sendAllowed} />}
      {isBroadcastMode && <BroadcastConfirmationModal onCancel={handleCloseBroadcastModal} onSend={sendMessage} />}
    </div>
  )
}

export default NewMessageInput
