import React, { useCallback, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import FormInput from 'components/common/FormInput'
import InvitationLogo from 'components/common/icons/InvitationLogo'
import GrinRadioGroup from 'components/common/GrinRadioGroup'
import { Trans, useTranslation } from 'react-i18next'
import PhoneNumberInput from 'components/common/PhoneNumberInput'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { createIdForTest } from 'utils/testingUtils.js'
import LinkToSiblingRow from './LinkToSiblingRow'
import LinkedSibling from './LinkedSibling'
import { trackEvent } from 'utils/analyticsUtils'
import { useDispatch, useSelector } from 'react-redux'
import actions from 'actions'

const useStyles = makeStyles({
  sectionHeader: {
    paddingLeft: 6,
    display: 'inline-block',
    lineHeight: '20px'
  },
  notes: {
    color: 'var(--text-color-11)'
  },
  notesContainer: {
    paddingTop: '0px !important',
    marginTop: '0px !important'
  },
  invitationMessage: {
    paddingTop: '0px !important',
    margin: '0px !important'
  },
  errorMessage: {
    color: 'red',
    padding: '0px !important',
    margin: '0px !important',
    fontWeight: '400'
  },
  sendInvitationRadioGroup: {
    paddingRight: '0px !important'
  },
  siblingLink: {
    marginLeft: '-10px !important'
  },
  siblingNotesContainer: {
    backgroundColor: 'var(--bg-color-5)',
    border: '0.85px solid var(--border-color-23)',
    borderRadius: 4,
    color: 'var(--text-color-31)',
    marginRight: 12,
    marginLeft: 12
  }
})

const PatientInfoFormBody = ({ values, formErrors }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { results = [] } = useSelector(state => state.patientsReducer.siblingSearch)

  const recpientOptions = useMemo(
    () => [
      { label: t('patient'), value: true },
      { label: t('guardian'), value: false }
    ],
    [t]
  )

  const formInputStyle = useMemo(
    () => ({
      bright: true,
      thick: true
    }),
    []
  )

  const renderFormItem = useCallback(
    ({ title, style, value, setValue, allowAutoComplete, errorMessage }) => (
      <Grid key={title} item xs={6} id={createIdForTest({ componentId: 'invite-patient', title })}>
        <FormInput
          title={title}
          style={style}
          value={value}
          setValue={setValue}
          allowAutoComplete={allowAutoComplete}
          errorMessage={errorMessage}
        />
      </Grid>
    ),
    []
  )

  const guardianFields = useMemo(
    () => [
      {
        title: t('dialogs.invitePatient.firstNameGuardianLabel'),
        style: formInputStyle,
        value: values.guardianFirstName,
        setValue: values.setGuardianFirstName,
        allowAutoComplete: false,
        errorMessage: formErrors?.guardianFirstName
      },
      {
        title: t('dialogs.invitePatient.lastNameGuardianLabel'),
        style: formInputStyle,
        value: values.guardianLastName,
        setValue: values.setGuardianLastName,
        allowAutoComplete: false,
        errorMessage: formErrors?.guardianLastName
      },
      {
        title: t('dialogs.invitePatient.emailGuardianLabel'),
        style: formInputStyle,
        value: values.guardianEmail,
        setValue: value => {
          dispatch(actions.searchGuardian(value))
          values.setGuardianEmail(value)
        },
        allowAutoComplete: false,
        errorMessage: formErrors?.guardianEmail
      }
    ],
    [t, formInputStyle, values, formErrors, dispatch]
  )

  const patientFields = useMemo(
    () => [
      {
        title: t('dialogs.invitePatient.firstNameLabel'),
        style: formInputStyle,
        value: values.firstName,
        setValue: values.setFirstName,
        allowAutoComplete: false,
        errorMessage: formErrors?.firstName
      },
      {
        title: t('dialogs.invitePatient.lastNameLabel'),
        style: formInputStyle,
        value: values.lastName,
        setValue: values.setLastName,
        allowAutoComplete: false,
        errorMessage: formErrors?.lastName
      }
    ],
    [t, formInputStyle, values, formErrors]
  )

  const onDeselectSibling = useCallback(
    sibling => {
      trackEvent('Link accounts - remove a linked account')
      values.setSiblingToLink(null)
    },
    [values]
  )

  const shouldShowSiblingLinkNote = useMemo(() => {
    if (results?.length > 0) {
      trackEvent('Link accounts - link suggestion shown')
      return true
    }
    return false
  }, [results?.length])

  return (
    <>
      {patientFields.map(item => renderFormItem(item))}
      <>
        <LinkToSiblingRow formErrors={formErrors} onDeselectSibling={onDeselectSibling} values={values} />
        {values.siblingToLink && <LinkedSibling onDeselectSibling={onDeselectSibling} values={values} />}
        {!values.shouldLinkToSibling && (
          <>
            {values.legalGuardianNeeded && <>{guardianFields.map(item => renderFormItem(item))}</>}
            {shouldShowSiblingLinkNote && (
              <Grid item xs={12} className={classes.siblingNotesContainer}>
                <DazzedParagraph14>
                  <Trans i18nKey={'dialogs.invitePatient.siblingLinkNote'} />
                </DazzedParagraph14>
              </Grid>
            )}
            {values.legalGuardianNeeded && (
              <Grid item xs={12} id={'invite-patient-send-to'}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <InvitationLogo />
                    <DazzedHeading18 className={classes.sectionHeader}>
                      {t('dialogs.invitePatient.sendInvitationHeader')}
                    </DazzedHeading18>
                  </Grid>
                  <Grid className={classes.sendInvitationRadioGroup} item>
                    <GrinRadioGroup
                      value={values.showRecepientEmail}
                      setValue={value => values.setShowRecepientEmail(value === 'true')}
                      options={recpientOptions}
                    />
                  </Grid>
                  <Grid item>
                    {formErrors?.showRecepientEmail && (
                      <DazzedParagraph12 className={classes.errorMessage}>
                        {formErrors.showRecepientEmail}
                      </DazzedParagraph12>
                    )}
                  </Grid>
                  <Grid style={{ paddingTop: 0 }} item>
                    <DazzedParagraph12 className={classes.invitationMessage}>
                      {t('dialogs.invitePatient.guardianInvitiationMessage')}
                    </DazzedParagraph12>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </>
      {!values.shouldLinkToSibling &&
        (values.showRecepientEmail || !values.legalGuardianNeeded) &&
        renderFormItem({
          title: values.legalGuardianNeeded
            ? t('dialogs.invitePatient.recepientEmailLabel')
            : t('dialogs.invitePatient.patientEmailLabel'),
          style: formInputStyle,
          value: values.email,
          setValue: values.setEmail,
          allowAutoComplete: false,
          errorMessage: formErrors?.email
        })}

      {!values.shouldLinkToSibling && (values.showRecepientEmail !== null || !values.legalGuardianNeeded) && (
        <>
          <Grid item xs={6} id={'invite-patient-phone-number'}>
            <PhoneNumberInput
              title={t('dialogs.invitePatient.phoneNumberLabel')}
              subtitle={t('dialogs.invitePatient.phoneNumberSubtitle')}
              setCountryCode={values.setCountryCode}
              setCountry={values.setPhoneCountry}
              setPhone={values.setPhone}
              country={values.phoneCountry}
              value={`${values.countryCode}${values.phone}`}
              style={formInputStyle}
            />
            <Grid item className={classes.notesContainer} xs={12}>
              <DazzedParagraph12 className={classes.notes}>
                <strong>{t('dialogs.invitePatient.note')}</strong>
                {t('dialogs.invitePatient.phoneNumberConsention')}
              </DazzedParagraph12>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default PatientInfoFormBody
