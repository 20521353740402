import * as React from 'react'

const ServiceAccountActivate = props => (
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.95658 3.42723L5.64399 6.6689C5.57163 6.71232 5.4848 6.6689 5.4848 6.58207V4.55602C0.578871 4.88888 0 7.04517 0 7.04517C0 2.55892 3.589 1.93663 5.4848 1.92216V0.0987166C5.4848 0.011886 5.57163 -0.0315294 5.64399 0.0263578L9.95658 3.26804C10.0145 3.31145 10.0145 3.38381 9.95658 3.42723Z"
      fill="#727B8C"
    />
  </svg>
)

export default ServiceAccountActivate
