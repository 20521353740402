import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import OggHeading32 from 'components/common/text/OggHeading32'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles({
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: 25,
    paddingTop: isMobile() ? 30 : 60
  },
  title: {
    marginBottom: 15,
    fontWeight: 600
  },
  subText: {
    fontSize: 14,
    marginBottom: 20
  },
  widthText: {
    fontSize: 13,
    marginBottom: 20
  },
  grinScopeImage: {
    alignSelf: 'center',
    marginBottom: 20,
    width: 150
  },
  grinScopeArrowImage: {
    alignSelf: 'center',
    marginBottom: 10,
    height: 10
  }
})

const OrderGrinKitInfo = ({ title, subTitle, scopeImage, arrowImage, width, description }) => {
  const classes = useStyles()

  return (
    <div className={classes.body}>
      {isMobile() ? (
        <OggHeading32>{title}</OggHeading32>
      ) : (
        <DazzedParagraph16 className={classes.title} textAlign="center">
          {title}
        </DazzedParagraph16>
      )}
      <DazzedParagraph12 className={classes.subText} textAlign="center">
        {subTitle}
      </DazzedParagraph12>
      <img className={classes.grinScopeImage} src={scopeImage} alt="Grin scope" />
      <img className={classes.grinScopeArrowImage} src={arrowImage} alt="arrow" />
      <DazzedParagraph12 className={classes.widthText} textAlign="center">
        {width}
      </DazzedParagraph12>
      <DazzedParagraph12 className={classes.subText} textAlign="center">
        {description}
      </DazzedParagraph12>
    </div>
  )
}

export default OrderGrinKitInfo
