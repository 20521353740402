import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export default shippingAddress => {
  const allScopeShippingFees = useSelector(state => state.productReducer.shippingFees)
  const practiceCountry = useSelector(state => state.practiceReducer.details.country)
  const shippingCountry = useMemo(
    () => shippingAddress?.country || practiceCountry,
    [practiceCountry, shippingAddress?.country]
  )
  const scopesQuantities = useSelector(state => state.billingReducer.order.grinKitsQuantity)

  const totalScopesAmount = useMemo(
    () => Object.values(scopesQuantities).reduce((currAmount, totalAmount) => currAmount + totalAmount, 0),
    [scopesQuantities]
  )
  const shippingFee = useMemo(() => {
    let priceKey

    if (totalScopesAmount < 30) {
      priceKey = `${shippingCountry.toLowerCase()}${totalScopesAmount}ScopesPriceId`
    } else {
      priceKey = `${shippingCountry.toLowerCase()}30+ScopesPriceId`
    }

    if (!allScopeShippingFees?.prices) return 0

    const priceId = allScopeShippingFees.metadata[priceKey]

    return allScopeShippingFees.prices.find(price => price.priceId === priceId)
  }, [allScopeShippingFees, shippingCountry, totalScopesAmount])

  return {
    allScopeShippingFees,
    shippingFee
  }
}
