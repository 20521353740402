import * as React from 'react'

export default () => (
  <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.375 4C9.375 5.38071 8.25571 6.5 6.875 6.5C5.49429 6.5 4.375 5.38071 4.375 4C4.375 2.61929 5.49429 1.5 6.875 1.5C8.25571 1.5 9.375 2.61929 9.375 4ZM10.375 4C10.375 5.933 8.808 7.5 6.875 7.5C4.942 7.5 3.375 5.933 3.375 4C3.375 2.067 4.942 0.5 6.875 0.5C8.808 0.5 10.375 2.067 10.375 4ZM1.75 13.1389C1.75 11.2829 3.0934 9.74061 4.86089 9.43144L4.67836 10.1616L4.25 11.875L5.53507 12.6705L6.875 13.5H2.11111C1.91167 13.5 1.75 13.3383 1.75 13.1389ZM8.28344 9.37529L8.02991 10.1178L7.38424 12.0087L6.875 13.5L6.36576 12.0087L5.72009 10.1178L5.46656 9.37529L5.51389 9.375H8.23611C8.25191 9.375 8.26768 9.3751 8.28344 9.37529ZM8.6198 8.39023C8.49323 8.38014 8.36527 8.375 8.23611 8.375H5.51389C5.38473 8.375 5.25677 8.38014 5.1302 8.39023L5.125 8.375L5.12101 8.39097C2.67378 8.59074 0.75 10.6402 0.75 13.1389C0.75 13.8906 1.35939 14.5 2.11111 14.5H11.6389C12.3906 14.5 13 13.8906 13 13.1389C13 10.6402 11.0762 8.59074 8.62899 8.39097L8.625 8.375L8.6198 8.39023ZM8.88911 9.43144C10.6566 9.74061 12 11.2829 12 13.1389C12 13.3383 11.8383 13.5 11.6389 13.5H6.875L8.21493 12.6705L9.5 11.875L9.07164 10.1616L8.88911 9.43144Z"
      fill="#001335"
    />
  </svg>
)
