import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import ExclamationMarkCircle from 'components/common/icons/ExclamationMarkCircle'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Refresh } from 'components/common/icons'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 6,
    width: '222px',
    height: '380px',
    border: '1px solid var(--border-color-7)',
    borderRadius: '10px'
  },
  retry: {
    marginTop: 10,
    cursor: 'pointer'
  },
  retryText: {
    color: 'var(--text-color-3)',
    textDecoration: 'underline'
  },
  retryIcon: {
    marginTop: 5
  }
}))

const BeforeAfterErrorState = ({ onRetry, errorMessage = '' }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid className={classes.root} container spacing={1} alignItems="center" justifyContent="center" direction="column">
      <Grid item>
        <ExclamationMarkCircle />
      </Grid>
      <Grid item>
        <DazzedParagraph14 strong textAlign="center">
          {errorMessage}
        </DazzedParagraph14>
      </Grid>
      {onRetry && (
        <Grid item>
          <Grid container onClick={onRetry} className={classes.retry} spacing={1} alignItems="center">
            <Grid item className={classes.retryIcon}>
              <Refresh />
            </Grid>
            <Grid item>
              <DazzedParagraph14 strong className={classes.retryText}>
                {t('general.retry')}
              </DazzedParagraph14>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default BeforeAfterErrorState
