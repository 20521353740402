import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none'
  },
  iconContainer: {
    width: 26,
    height: 26,
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const ScanStatItem = ({ label, icon, onClick = () => {}, href }) => {
  const classes = useStyles()

  return (
    <a href={href} className={classes.link}>
      <Grid container alignItems="center" spacing={1} className={classes.scanStatItemContainer} onClick={onClick}>
        <Grid item>
          <div className={classes.iconContainer}>{icon}</div>
        </Grid>
        <Grid item>
          <DazzedParagraph14 strong>{label}</DazzedParagraph14>
        </Grid>
      </Grid>
    </a>
  )
}

export default ScanStatItem
