import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import {
  Fast,
  Moderate,
  MissingTooth,
  NotTracking,
  PartiallyTracking,
  Tracking,
  Unknown,
  TrackingNoMovement,
  Slow,
  NoMovement
} from 'components/common/icons'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { TreatmentTypes } from 'consts/treatmentConsts'
import { useSelector } from 'react-redux'
import Tooltip from 'components/common/Tooltip'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  legendItem: { marginRight: 20 },
  label: {
    userSelect: 'none'
  }
}))

const TeethTrackingLegend = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { treatment } = useSelector(state => state.treatmentReducer)

  const alignersLegendItems = useMemo(
    () => [
      {
        icon: <Tracking />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.tracking'),
        explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.tracking')
      },
      {
        icon: <TrackingNoMovement />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.trackingNoMovement'),
        explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.trackingNoMovement')
      },
      {
        icon: <PartiallyTracking />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.partiallyTracking'),
        explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.partiallyTracking')
      },
      {
        icon: <NotTracking />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.notTracking'),
        explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.notTracking')
      },
      {
        icon: <Unknown />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.unknown'),
        explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.unknown')
      },
      {
        icon: <MissingTooth />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.missingTooth')
      }
    ],
    [t]
  )

  const bracesLegendItems = useMemo(
    () => [
      {
        icon: <Fast />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.fast')
      },
      {
        icon: <Moderate />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.moderate')
      },
      {
        icon: <Slow />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.slow')
      },
      {
        icon: <NoMovement />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.noMovement')
      },
      {
        icon: <Unknown />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.unknown')
      },
      {
        icon: <MissingTooth />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.missingTooth')
      }
    ],
    [t]
  )

  const legendItems = useMemo(
    () => (treatment?.type === TreatmentTypes.Aligners ? alignersLegendItems : bracesLegendItems),
    [alignersLegendItems, bracesLegendItems, treatment]
  )

  return (
    <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
      {legendItems.map(item => (
        <Tooltip
          disableTooltip={!item?.explanation}
          variant="light"
          arrow
          placement="top"
          value={<DazzedParagraph14>{item.explanation}</DazzedParagraph14>}
        >
          <Grid item key={item.label} className={classes.legendItem}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>{item.icon}</Grid>
              <Grid item>
                <DazzedParagraph12 className={classes.label}>{item.label}</DazzedParagraph12>
              </Grid>
            </Grid>
          </Grid>
        </Tooltip>
      ))}
    </Grid>
  )
}

export default TeethTrackingLegend
