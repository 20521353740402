import * as React from 'react'

function Highlighter({ color = 'white', ...props }) {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2438 0.539953C12.1362 0.431955 12.0083 0.346262 11.8676 0.28779C11.7268 0.229318 11.5759 0.199219 11.4234 0.199219C11.271 0.199219 11.1201 0.229318 10.9793 0.28779C10.8385 0.346262 10.7107 0.431955 10.6031 0.539953L3.04688 8.08449L3.02345 8.11495C3.00887 8.13138 2.99628 8.14948 2.98595 8.16886L1.26095 11.2954C1.21196 11.3845 1.19325 11.487 1.20762 11.5876C1.22199 11.6883 1.26868 11.7815 1.34063 11.8532L1.41095 11.9235L0.133603 13.1985C0.0807485 13.2511 0.0411738 13.3154 0.0181601 13.3863C-0.00485355 13.4572 -0.0106432 13.5325 0.00127131 13.606C0.0131858 13.6796 0.0424611 13.7492 0.0866699 13.8092C0.130879 13.8692 0.188747 13.9177 0.255478 13.9509L1.87501 14.7525C1.93925 14.7865 2.01089 14.8042 2.0836 14.804C2.14546 14.8041 2.20671 14.7919 2.26383 14.7681C2.32095 14.7444 2.37281 14.7096 2.41642 14.6657L3.28126 13.7915L3.35157 13.8618C3.43908 13.9499 3.55792 13.9996 3.68204 14.0001C3.7638 14.0002 3.8443 13.98 3.91641 13.9415L7.02188 12.2259C7.04157 12.2149 7.06037 12.2023 7.07813 12.1884L7.1086 12.1626L14.6625 4.60167C14.8772 4.38262 14.9975 4.0881 14.9975 3.78136C14.9975 3.47462 14.8772 3.1801 14.6625 2.96105L12.2438 0.539953ZM1.98048 13.7634L1.25626 13.4025L2.07188 12.5868L2.61563 13.1259L1.98048 13.7634ZM3.76173 12.9501L2.25235 11.4407L3.49688 9.18839L6.01407 11.7056L3.76173 12.9501ZM13.9992 3.93839L6.79688 11.1525L4.05001 8.4173L11.2641 1.20323C11.2846 1.18204 11.3092 1.16519 11.3364 1.15369C11.3636 1.14218 11.3928 1.13625 11.4223 1.13625C11.4518 1.13625 11.481 1.14218 11.5082 1.15369C11.5354 1.16519 11.5599 1.18204 11.5805 1.20323L13.9992 3.62198C14.0388 3.66519 14.0607 3.72163 14.0607 3.78019C14.0607 3.83875 14.0388 3.89518 13.9992 3.93839V3.93839Z"
        fill={color}
      />
    </svg>
  )
}

export default Highlighter
