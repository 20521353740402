import * as React from 'react'

const Check2 = ({ color = 'var(--text-color-3)', width = 17, height = 12, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 6L6 11L16 1" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default Check2
