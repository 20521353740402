import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  colorCircle: {
    borderRadius: '50%',
    width: '15px',
    height: '15px',
    margin: 5,
    boxShadow: 'rgb(242, 242, 242) 0px 0px 0px 1px',
    cursor: 'pointer'
  },
  selected: {
    boxShadow: '#3C52EF 0px 0px 0px 1px inset',
    borderRadius: '50%',
    transition: 'width 0.5s, height 0.5s, box-shadow 0.2s '
  }
}))

const PickerOptions = ({ color, options, onClick }) => {
  const classes = useStyles(options)

  return (
    <Grid container alignItems="center">
      {!!options &&
        options.map(currOption => (
          <Grid key={currOption} className={`${color === currOption && classes.selected}`}>
            <Grid
              item
              className={classes.colorCircle}
              onClick={() => onClick(currOption)}
              style={{ backgroundColor: `${currOption}` }}
            ></Grid>
          </Grid>
        ))}
    </Grid>
  )
}

export default PickerOptions
