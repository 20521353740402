import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Actions from '../actions'

export default () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Actions.setHelpWidgetVisibility(false))
    return () => dispatch(Actions.setHelpWidgetVisibility(true))
  }, [dispatch])
}
