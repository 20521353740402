import React from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph12 from './text/DazzedParagraph12'

const useStyles = makeStyles(() => ({
  ribbon: {
    position: 'absolute',
    top: -4,
    right: -4,
    width: 100,
    height: 100,
    overflow: 'hidden',
    '&::before': {
      position: 'absolute',
      top: 0,
      left: 0,
      content: "''",
      display: 'block',
      border: '2px solid #203AB7',
      borderTopColor: 'transparent',
      borderRightColor: 'transparent'
    },
    '&::after': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      content: "''",
      display: 'block',
      border: '2px solid #203AB7',
      borderTopColor: 'transparent',
      borderRightColor: 'transparent'
    }
  },
  ribbonSpan: {
    position: 'absolute',
    top: 22,
    left: -55,
    transform: 'rotate(45deg)',
    display: 'block',
    padding: '6px 0 0 10px',
    width: 225,
    height: 30,
    backgroundColor: '#031433',
    color: 'var(--text-color-1)',
    fontSize: 14,
    textAlign: 'center'
  }
}))

const Ribbon = ({ text }) => {
  const classes = useStyles()
  return (
    <div className={classes.ribbon}>
      <DazzedParagraph12 className={classes.ribbonSpan}>{text}</DazzedParagraph12>
    </div>
  )
}

export default Ribbon
