import * as React from 'react'

function SvgLock(props) {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.28571 5.33333H8.57143V3.33333C8.57143 1.46667 7 0 5 0C3 0 1.42857 1.46667 1.42857 3.33333V5.33333H0.714286C0.285714 5.33333 0 5.6 0 6V11.3333C0 11.6667 0.285714 12 0.714286 12H9.28571C9.71429 12 10 11.6667 10 11.3333V6C10 5.6 9.71429 5.33333 9.28571 5.33333ZM2.85714 5.33333V3.33333C2.85714 2.2 3.78571 1.33333 5 1.33333C6.21429 1.33333 7.14286 2.2 7.14286 3.33333V5.33333H2.85714Z"
        fill="#727B8C"
      />
    </svg>
  )
}

export default SvgLock
