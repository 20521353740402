const CLEAR_REQUEST_PROVIDER_STLS_STATUS = 'CLEAR_REQUEST_PROVIDER_STLS_STATUS'
const REQUEST_PROVIDER_STLS = 'REQUEST_PROVIDER_STLS'
const REQUEST_PROVIDER_STLS_RECEIVED = 'REQUEST_PROVIDER_STLS_RECEIVED'
const REQUEST_PROVIDER_STLS_FAILED = 'REQUEST_PROVIDER_STLS_FAILED'
const SET_TREATMENT_TRACKER_MODAL_OPEN = 'SET_TREATMENT_TRACKER_MODAL_OPEN'
const UPLOAD_TX_TRACKER_STLS_COMPLETED = 'UPLOAD_TX_TRACKER_STLS_COMPLETED'

const setTreatmentTrackerModalOpen = payload => ({
  type: SET_TREATMENT_TRACKER_MODAL_OPEN,
  payload
})

const requestProviderStls = payload => ({
  type: REQUEST_PROVIDER_STLS,
  payload
})

const requestProviderStlsReceived = () => ({
  type: REQUEST_PROVIDER_STLS_RECEIVED
})

const requestProviderStlsFailed = () => ({
  type: REQUEST_PROVIDER_STLS_FAILED
})

const uploadTxTrackerStlsCompleted = payload => ({
  type: UPLOAD_TX_TRACKER_STLS_COMPLETED,
  payload
})

const clearProviderStlsRequestStatus = () => ({
  type: CLEAR_REQUEST_PROVIDER_STLS_STATUS
})

export default {
  SET_TREATMENT_TRACKER_MODAL_OPEN,
  REQUEST_PROVIDER_STLS,
  REQUEST_PROVIDER_STLS_RECEIVED,
  REQUEST_PROVIDER_STLS_FAILED,
  UPLOAD_TX_TRACKER_STLS_COMPLETED,
  CLEAR_REQUEST_PROVIDER_STLS_STATUS,
  clearProviderStlsRequestStatus,
  uploadTxTrackerStlsCompleted,
  setTreatmentTrackerModalOpen,
  requestProviderStls,
  requestProviderStlsReceived,
  requestProviderStlsFailed
}
