import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'
import BrightCheckbox from '../../common/inputs/BrightCheckbox'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import CreditCardPicker from '../../common/inputs/billing/CreditCardPicker'
import BillingAddressPicker from '../../common/inputs/billing/BillingAddressPicker'
import OrderItemsList from '../../common/OrderItemsList'
import CreditCardPaymentIcons from 'components/common/inputs/billing/CreditCardPaymentIcons'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'utils/mobileUtils'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  sectionTitle: {
    marginTop: 20,
    marginBottom: 15,
    fontWeight: 500
  },
  termsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20
  },
  termsText: {
    marginLeft: -10,
    fontWeight: 500
  },
  link: {
    fontWeight: 500,
    color: 'var(--text-color-3)',
    cursor: 'pointer'
  },
  list: {
    backgroundColor: 'rgba(60, 82, 239, .1)',
    borderBottomRightRadius: '50px'
  },
  paymentMethodTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  subtitle: {
    color: '#727B8C',
    margin: '0px 40px 10px 40px'
  }
})

const OrderGrinKitsCheckout = ({
  quantity,
  quantityMini,
  currency,
  kitPrice,
  kitMiniPrice,
  shippingFee,
  taxes,
  totalDiscount,
  shippingAddress,
  agreedTerms,
  shouldShowErrors,
  setAgreedTerms,
  onChangeShippingAddress,
  resetShippingAddress,
  isLoading = false,
  onCardSaved,
  coupon,
  onResetCoupon
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { hasSavedCard } = useSelector(state => state.billingReducer.paymentMethod)
  const termsOfSaleUrl = useSelector(state => state.appReducer.appconfig?.app?.payment?.termsOfSaleUrl)

  const orderItems = useMemo(
    () => [
      ...(quantity > 0
        ? [
            {
              description: `${currency}${kitPrice} USD x ${quantity} ${t('dialogs.orderScopes.kitsText')}`,
              price: kitPrice * quantity
            }
          ]
        : []),
      ...(quantityMini > 0
        ? [
            {
              description: `${currency}${kitPrice} USD x ${quantityMini} ${t('dialogs.orderScopes.kitsMiniText')}`,
              price: kitMiniPrice * quantityMini
            }
          ]
        : []),
      { description: t('dialogs.orderScopes.shippingText'), price: shippingFee },
      { description: t('dialogs.orderScopes.taxesText'), price: taxes },
      ...(totalDiscount > 0 ? [{ description: t('dialogs.orderScopes.discountText'), price: -1 * totalDiscount }] : []),
      ...(coupon.discountAmount ? [{ description: [coupon.name], price: coupon.discountAmount, isCoupon: true }] : [])
    ],
    [quantity, currency, kitPrice, t, quantityMini, kitMiniPrice, shippingFee, taxes, coupon, totalDiscount]
  )

  return (
    <>
      {isMobile() && !hasSavedCard && (
        <DazzedParagraph16 textAlign="center" className={classes.subtitle}>
          {t('dialogs.orderScopes.provideCreditCard')}
        </DazzedParagraph16>
      )}
      <OrderItemsList
        items={orderItems}
        isLoading={isLoading}
        onResetCoupon={onResetCoupon}
        hasCoupon={coupon.discountAmount}
        className={isMobile() && classes.list}
      />
      {isMobile() ? (
        <div className={classes.paymentMethodTitleContainer}>
          <DazzedParagraph16 className={classes.sectionTitle}>
            {t('dialogs.orderScopes.paymentMethod')}:
          </DazzedParagraph16>

          <CreditCardPaymentIcons />
        </div>
      ) : (
        <DazzedParagraph16 className={classes.sectionTitle}>
          {t('dialogs.orderScopes.creditCardPaymentText')}:
        </DazzedParagraph16>
      )}
      <CreditCardPicker
        showTitle={false}
        iconButtons
        showPoweredByLabel={false}
        hideSubmit={true}
        onSubmit={onCardSaved}
      />
      <DazzedParagraph16 className={classes.sectionTitle}>{t('dialogs.orderScopes.deliveryText')}:</DazzedParagraph16>
      <BillingAddressPicker
        label=""
        address={shippingAddress}
        setAddress={onChangeShippingAddress}
        resetAddress={resetShippingAddress}
      />
      <div className={classes.termsContainer}>
        <BrightCheckbox
          checked={agreedTerms}
          onChange={event => setAgreedTerms(event.target.checked)}
          color={!agreedTerms && shouldShowErrors && 'red'}
        />
        <DazzedParagraph16 className={classes.termsText}>{t('creditCard.iAgreeTo')}&nbsp;</DazzedParagraph16>
        <Link href={termsOfSaleUrl} target="_blank" rel="noopener noreferrer">
          <DazzedParagraph16 className={classes.link} strong>
            {t('creditCard.termsOfSale')}
          </DazzedParagraph16>
        </Link>
      </div>
    </>
  )
}

export default OrderGrinKitsCheckout
