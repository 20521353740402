export const ROTATION_DIRECTIONS = {
  LEFT: 'left',
  RIGHT: 'right'
}

const getRotatedImage = async ({ imageElement, scaleX, scaleY, rotationDegrees }) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  canvas.width = imageElement.width * scaleX
  canvas.height = imageElement.height * scaleY

  const centerX = canvas.width / 2
  const centerY = canvas.height / 2

  const rotationRadians = (rotationDegrees * Math.PI) / 180

  ctx.save()

  ctx.translate(centerX, centerY)
  ctx.rotate(rotationRadians)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(imageElement, 0, 0)

  ctx.restore()

  return canvas
}

export const getCroppedImgWithRotation = async ({
  imageElement,
  imageWidth,
  imageHeight,
  cropDimensions,
  rotationDegrees
}) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const scaleX = imageElement.naturalWidth / imageElement.width
  const scaleY = imageElement.naturalHeight / imageElement.height

  const rotatedImageCanvas = await getRotatedImage({ imageElement, scaleX, scaleY, rotationDegrees })

  const cropXStart = cropDimensions.x * scaleX
  const cropYStart = cropDimensions.y * scaleY
  const cropCalcWidth = cropDimensions.width * scaleX
  const cropCalcHeight = cropDimensions.height * scaleY

  canvas.width = cropCalcWidth
  canvas.height = cropCalcHeight

  ctx.drawImage(
    rotatedImageCanvas,
    cropXStart,
    cropYStart,
    cropCalcWidth,
    cropCalcHeight,
    0,
    0,
    cropCalcWidth,
    cropCalcHeight
  )

  const dataUrl = canvas.toDataURL()
  canvas.remove()
  rotatedImageCanvas.remove()

  return dataUrl
}
