import React, { useMemo } from 'react'
import moment from 'moment'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { TIME_FORMAT_9 } from 'consts/dateTimeConsts'
import usePatientBriefSummary from './usePatientBriefSummary'

const useStyles = makeStyles({
  briefListContainer: {
    padding: '15px 0 15px 15px',
    cursor: 'pointer',
    borderBottom: '1px solid var(--border-color-10)',
    transition: 'background 0.2s ease',
    '&:hover': {
      background: 'var(--bg-color-14)'
    }
  },
  briefDetails: {
    color: 'var(--text-color-11)'
  },
  columnText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
})

const BriefListItem = ({ brief, onClick = () => {} }) => {
  const classes = useStyles()
  const { getBriefSummary } = usePatientBriefSummary()

  const parsedBriefCategories = useMemo(() => JSON.parse(brief.categories || '{}'), [brief])
  const briefSummary = useMemo(() => getBriefSummary(parsedBriefCategories), [getBriefSummary, parsedBriefCategories])

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={classes.briefListContainer}
      onClick={onClick}
      key={brief.id}
      spacing={1}
    >
      <Grid item xs={2}>
        <DazzedParagraph14 bold>{moment(brief.createdAt).format(TIME_FORMAT_9)}</DazzedParagraph14>
      </Grid>
      <Grid item xs={8}>
        <DazzedParagraph14 className={[classes.briefDetails, classes.columnText].join(' ')}>
          {briefSummary}
        </DazzedParagraph14>
      </Grid>
      <Grid item xs={2}>
        {brief.customTitle && (
          <DazzedParagraph14 bold className={classes.columnText}>
            {brief.customTitle}
          </DazzedParagraph14>
        )}
      </Grid>
    </Grid>
  )
}

export default BriefListItem
