import TreatmentStatusUpdated from 'components/Profile/Services/Automations/AutomationRuleDisplayComponents/TreatmentStatusUpdated'
import { AutomationsAssignPatient, Message, PatientTag } from 'components/common/icons'
import { actionTypes, conditionTypes } from 'consts/automatedActionConsts'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useRolePermissions from './useRolePermissions'

export default action => {
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()

  const ruleActions = useMemo(
    () => ({
      [actionTypes.AddPatientTag]: {
        icon: <PatientTag />,
        title: t(`pages.accountSettings.automations.actionTypes.${actionTypes.AddPatientTag}`),
        getContent: params =>
          t(`pages.accountSettings.automations.actionTypes.content.${actionTypes.AddPatientTag}`, {
            tag: params.tagValue
          }),
        validate: params =>
          !params?.tagValue
            ? { error: t(`pages.accountSettings.automations.actionTypes.validations.patientTagError`) }
            : {}
      },
      [actionTypes.SendMessage]: {
        icon: <Message />,
        title: t(`pages.accountSettings.automations.actionTypes.${actionTypes.SendMessage}`),
        getContent: params =>
          t(`pages.accountSettings.automations.actionTypes.content.${actionTypes.SendMessage}`, {
            message: params.message
          }),
        validate: params =>
          !params?.message
            ? { error: t(`pages.accountSettings.automations.actionTypes.validations.sendMessageError`) }
            : {}
      },
      [actionTypes.AssignPatient]: {
        icon: <AutomationsAssignPatient />,
        title: t(`pages.accountSettings.automations.actionTypes.${actionTypes.AssignPatient}`),
        getContent: params =>
          t(`pages.accountSettings.automations.actionTypes.content.${actionTypes.AssignPatient}`, {
            practiceMember: params?.assigneeName
          }),
        validate: params =>
          !params?.assigneeId
            ? { error: t(`pages.accountSettings.automations.actionTypes.validations.practiceMemberRequired`) }
            : {}
      },
      [actionTypes.ShowAnnouncement]: {
        icon: <></>,
        title: t(`pages.accountSettings.automations.actionTypes.${actionTypes.ShowAnnouncement}`),
        getContent: params =>
          t(`pages.accountSettings.automations.actionTypes.content.${actionTypes.ShowAnnouncement}`, {
            announcement: params.announcementTitle
          }),
        validate: () => {}
      }
    }),
    [t]
  )

  const ruleConditions = useMemo(
    () => ({
      [conditionTypes.DuringDays]: {
        getContent: params =>
          params.duringDays.map(day => `${day.charAt(0).toUpperCase()}${day.substring(1, 3)}`).join(', '),
        validate: params =>
          params.duringDays?.length > 0
            ? {}
            : { error: t('pages.accountSettings.automations.conditions.validations.duringDaysError') },
        isAllowed: true
      },
      [conditionTypes.DateRange]: {
        getContent: params =>
          `${moment(params.fromDate).format('MMM Do')} - ${moment(params.toDate).format('MMM Do YY')}`,
        validate: params =>
          moment(params.fromDate).isAfter(moment(params.toDate))
            ? { error: t('pages.accountSettings.automations.conditions.validations.duringDatesError') }
            : {},
        isAllowed: true
      },
      [conditionTypes.AlignerNumber]: {
        getContent: params =>
          t(`pages.accountSettings.automations.conditionTypes.${conditionTypes.AlignerNumber}`, {
            alignerNumber: params.alignerNumber
          }),
        validate: params =>
          !params.alignerNumber
            ? { error: t('pages.accountSettings.automations.conditions.validations.alignerNumberError') }
            : {},
        isAllowed: true
      },
      [conditionTypes.TreatmentStatusUpdated]: {
        getContent: params => <TreatmentStatusUpdated params={params} />,
        isAllowed: true,
        validate: () => {}
      },
      [conditionTypes.PatientScanCount]: {
        getContent: params =>
          t(`pages.accountSettings.automations.conditionTypes.${conditionTypes.PatientScanCount}`, params),
        validate: params =>
          !params.scansCount
            ? { error: t('pages.accountSettings.automations.conditions.validations.scansCountError') }
            : {},
        isAllowed: permissions.opsDashboardSystemAutomations
      },
      [conditionTypes.PracticeHasFeatureFlag]: {
        getContent: params =>
          t(`pages.accountSettings.automations.conditionTypes.${conditionTypes.PracticeHasFeatureFlag}`, params),
        validate: params =>
          !params.featureFlag
            ? { error: t('pages.accountSettings.automations.conditions.validations.practiceHasFeatureFlagError') }
            : {},
        isAllowed: permissions.opsDashboardSystemAutomations
      },
      [conditionTypes.ScanIsUrgent]: {
        getContent: params => t(`pages.accountSettings.automations.conditionTypes.${conditionTypes.ScanIsUrgent}`),
        validate: () => {},
        isAllowed: true
      },
      [conditionTypes.OverdueDays]: {
        getContent: params =>
          t(`pages.accountSettings.automations.conditionTypes.${conditionTypes.OverdueDays}`, {
            numberOfDays: params.overdueDays
          }),
        isAllowed: true,
        validate: params =>
          !params.overdueDays
            ? { error: t('pages.accountSettings.automations.conditions.validations.required') }
            : params.overdueDays < 1 || params.overdueDays > 30
            ? { error: t('pages.accountSettings.automations.conditions.validations.rangeError', { min: 1, max: 30 }) }
            : {}
      }
    }),
    [t, permissions]
  )

  const allowedRuleConditions = useMemo(
    () => Object.keys(ruleConditions).filter(conditionType => ruleConditions[conditionType].isAllowed),
    [ruleConditions]
  )

  const isConditionAllowed = useCallback(
    condtionType => {
      return allowedRuleConditions.includes(condtionType)
    },
    [allowedRuleConditions]
  )

  return {
    ruleActions,
    ruleConditions,
    allowedRuleConditions,
    isConditionAllowed
  }
}
