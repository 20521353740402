import React from 'react'
import BaseModal from 'components/common/modals/BaseModal'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import Computer from 'components/common/icons/Computer'

const useStyles = makeStyles({
  body: {
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '50px',
    paddingBottom: '50px'
  },
  container: {
    marginTop: 80,
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: { alignItems: 'center', width: '100%' }
})

const LegalGuardianModalMobile = ({ isOpen, handleGotIt, handleCancel }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleGotIt}
      onPrimaryBtnClick={handleGotIt}
      primaryLabel={t('dialogs.invitePatient.isUnder18ModalMobile.button')}
      variant="alert"
      largerButtons={true}
      buttonsWidth={'100%'}
      buttonsHeight={50}
      butttonFontSize={16}
      contentClassName={classes.container}
      primaryButtonId="isUnder18ModalMobile-gotIt"
    >
      <Computer className={classes.icon} />
      <DazzedParagraph16 className={classes.body} strong textAlign="center">
        {t('dialogs.invitePatient.isUnder18ModalMobile.body')}
      </DazzedParagraph16>
    </BaseModal>
  )
}

export default LegalGuardianModalMobile
