import { makeStyles } from '@material-ui/styles'
import React from 'react'
import PaymentIcon from 'react-payment-icons'
import GrayActionBox from '../../GrayActionBox'
import EditIcon from '../../icons/EditV2'
import DazzedParagraph16 from '../../text/DazzedParagraph16'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  cardData: {
    display: 'flex',
    alignItems: 'center'
  },
  brand: {
    textTransform: 'capitalize'
  },
  cardPreview: {
    color: ({ dark }) => (dark ? 'white' : 'initial')
  },
  icon: {
    width: 24,
    marginRight: 12
  }
}))

const CreditCardPreview = ({
  creditCardData,
  showTitle,
  iconButtons,
  onChangeRequested = () => {},
  variant = 'bright',
  buttonLabel,
  title
}) => {
  const classes = useStyles({ dark: variant === 'dark' })
  const { t } = useTranslation()

  return (
    <GrayActionBox
      label={!showTitle ? '' : title || t('creditCard.creditCard')}
      buttonText={iconButtons ? '' : buttonLabel || t('general.change')}
      onAction={onChangeRequested}
      actionIcon={iconButtons && <EditIcon />}
      variant={variant}
    >
      <div className={classes.cardData}>
        <PaymentIcon id={creditCardData.brand} className={classes.icon} />
        <DazzedParagraph16 className={classes.cardPreview}>
          <span className={classes.brand}>{creditCardData.brand}</span>
          {` xxxx-${creditCardData.last4}`}
        </DazzedParagraph16>
      </div>
    </GrayActionBox>
  )
}

export default CreditCardPreview
