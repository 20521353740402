import Actions from 'actions'
import React, { useCallback, useRef, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import VideoFrameSeeker from './VideoFrameSeeker'
import CropImage from '../../CropImage'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import LinkButton from 'components/common/buttons/LinkButton'
import { logError } from 'utils/logUtils'
import { useDispatch } from 'react-redux'

export const REPLACE_IMAGE_MODES = {
  frameSelection: 'frameSelection',
  crop: 'crop'
}

const useStyles = makeStyles({
  previousImage: {
    width: 206,
    height: 124,
    borderRadius: 5
  },
  buttonsContainer: {
    marginTop: 10
  }
})

const ExtractFrameFromVideo = ({
  mode,
  setMode,
  originalImageUrl,
  videoUrl,
  onConfirm,
  imageHeight,
  imageWidth,
  isLoading
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const videoRef = useRef()

  const [selectedFrame, setSelectedFrame] = useState()
  const [croppedFrame, setCroppedFrame] = useState()
  const [shouldDisableButtons, setShouldDisableButtons] = useState(false)

  const handleUseFrame = useCallback(
    frame => {
      setMode(REPLACE_IMAGE_MODES.crop)
      setSelectedFrame(frame)
    },
    [setMode]
  )

  const handleBackToFrameSelection = useCallback(() => {
    trackEvent('Replace scan summary image modal - Clicked back to frame selection')
    setMode(REPLACE_IMAGE_MODES.frameSelection)
    setCroppedFrame()
    setSelectedFrame()
  }, [setMode])

  const handleJumpToFrame = useCallback(() => {
    // Extracing frame number from scan summary image key: frame_xxx_...
    // example: /private/scanSummaries/<grinScanId>/frame_12345....
    const frameTimeInVideo = Number(originalImageUrl.split('frame_')[1].split('_')[0]) / 3

    if (!frameTimeInVideo) {
      trackEvent('Replace scan summary image modal - Unable to jump to frame', {
        frameTimeInVideo
      })
      logError('Replace scan summary image modal - Unable to jump to frame', {
        frameTimeInVideo,
        originalImageUrl
      })
      dispatch(
        Actions.showSnackbar({
          type: 'error',
          text: t('dialogs.replaceScanSummaryImage.jumpToFrameError')
        })
      )
      return
    }
    trackEvent('Replace scan summary image modal - Jumped to origin frame', {
      frameTimeInVideo
    })
    videoRef.current.currentTime = frameTimeInVideo
  }, [dispatch, originalImageUrl, t])

  return (
    <Grid container spacing={3}>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <DazzedParagraph14>{t('dialogs.replaceScanSummaryImage.previousFrame')}</DazzedParagraph14>
          </Grid>
          <Grid item>
            <img src={originalImageUrl} alt="Previous Frame" className={classes.previousImage} />
          </Grid>
          <Grid item>
            <LinkButton
              label={t('dialogs.replaceScanSummaryImage.jumpToFrame')}
              underline
              onClick={handleJumpToFrame}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <DazzedParagraph14>{t('dialogs.replaceScanSummaryImage.newFrame')}</DazzedParagraph14>
          </Grid>
          <Grid item>
            {mode === REPLACE_IMAGE_MODES.frameSelection ? (
              <VideoFrameSeeker videoRef={videoRef} videoUrl={videoUrl} onUseFrame={handleUseFrame} />
            ) : (
              <>
                <CropImage
                  image={selectedFrame}
                  croppedImage={croppedFrame}
                  setCroppedImage={setCroppedFrame}
                  onCropToggled={setShouldDisableButtons}
                  imageWidth={imageWidth}
                  imageHeight={imageHeight}
                />
                <Grid item className={classes.buttonsContainer}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <SecondaryButton
                        height={32}
                        width={250}
                        label={t('dialogs.replaceScanSummaryImage.crop.backButtonLabel')}
                        onClick={handleBackToFrameSelection}
                        disabled={shouldDisableButtons}
                      />
                    </Grid>
                    <Grid item>
                      <PrimaryButton
                        height={32}
                        label={t('general.confirm')}
                        onClick={() => onConfirm(croppedFrame || selectedFrame)}
                        isLoading={isLoading}
                        disabled={shouldDisableButtons}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ExtractFrameFromVideo
