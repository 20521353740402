import React from 'react'
import { makeStyles, TableCell } from '@material-ui/core'
import ProgramChip from 'components/common/ProgramChip'

const useStyles = ({ highlighted, rowIndex }) =>
  makeStyles({
    root: {
      height: '78px',
      borderTop: rowIndex === 0 && highlighted ? '1px solid' : 0,
      borderColor: highlighted ? 'var(--border-color-1)' : 'var(--border-color-11)'
    }
  })({ highlighted, rowIndex })

const ProgramCell = ({ rowIndex, actionRequired = false, program = '' }) => {
  const classes = useStyles({ highlighted: actionRequired, rowIndex })
  return (
    <TableCell align="left" classes={{ root: classes.root }}>
      <ProgramChip program={program} />
    </TableCell>
  )
}

export default React.memo(ProgramCell)
