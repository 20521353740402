import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SelectInput from 'components/common/SelectInput'
import TextArea from 'components/common/TextArea'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import reasonIcons from 'components/common/icons/notifyPractice/index'
import GrinCheckbox from 'components/common/GrinCheckbox'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  noteInput: {
    borderRadius: 10
  },
  checkbox: {
    paddingLeft: 0
  },
  checkboxCaption: {
    fontSize: 14
  }
})

const NotifyPracticeForm = ({ note, reason, reasons, onChangeNote, onChangeReason, onChangeNotifyDSO }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dso = useSelector(state => state.multiPracticeReducer.context.dso)
  const isPartOfDSO = useMemo(() => dso !== 'none', [dso])

  const reasonOptions = useMemo(
    () =>
      Object.values(reasons).map(reason => ({
        label: t(`dialogs.notifyPractice.reasons.${reason}`),
        value: reason,
        icon: React.createElement(reasonIcons[reason])
      })),
    [t, reasons]
  )

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <DazzedParagraph14 bold>{t(`dialogs.notifyPractice.reasonLabel`)}</DazzedParagraph14>
          </Grid>
          <Grid item>
            <SelectInput
              selectStyle={{ border: 'none', fontSize: 14, fontWeight: 500, marginTop: -2 }}
              showArrow={false}
              dropdownClassName={classes.dropdown}
              style={{ bright: true, thick: true }}
              value={reason}
              options={reasonOptions}
              keepErrorContainerWhenInactive={false}
              onChange={({ value }) => onChangeReason(value)}
            />
          </Grid>
        </Grid>
        {isPartOfDSO && (
          <Grid container alignItems="center">
            <Grid item>
              <GrinCheckbox
                caption={t(`dialogs.notifyPractice.shareWithDSO`)}
                className={classes.checkbox}
                captionClassName={classes.checkboxCaption}
                onChange={onChangeNotifyDSO}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <TextArea
          inputStyle={{ height: 135, padding: 16 }}
          value={note}
          setValue={onChangeNote}
          style={{ bright: true }}
          placeholder={t('dialogs.notifyPractice.notePlaceholder')}
          className={classes.noteInput}
        />
      </Grid>
    </Grid>
  )
}

export default NotifyPracticeForm
