import * as React from 'react'

function Overdue({ color = '#EA3525', ...props }) {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.4434 1.78867H15.4227V0.556641C15.4227 0.249227 15.1735 0 14.8661 0C14.5587 0 14.3094 0.249227 14.3094 0.556641V1.78867H11.8454V0.556641C11.8454 0.249227 11.5962 0 11.2887 0C10.9813 0 10.7321 0.249227 10.7321 0.556641V1.78867H8.26797V0.556641C8.26797 0.249227 8.01874 0 7.71133 0C7.40391 0 7.15469 0.249227 7.15469 0.556641V1.78867H4.69063V0.556641C4.69063 0.249227 4.4414 0 4.13398 0C3.82657 0 3.57734 0.249227 3.57734 0.556641V1.78867H0.556641C0.249227 1.78867 0 2.0379 0 2.34531V18.4434C0 18.7508 0.249227 19 0.556641 19H13.0773C16.3431 19 19 16.3431 19 13.0773V2.34531C19 2.0379 18.7508 1.78867 18.4434 1.78867ZM13.0773 17.8867C10.4255 17.8867 8.26797 15.7293 8.26797 13.0773C8.26797 10.4254 10.4255 8.26797 13.0774 8.26797C15.7293 8.26797 17.8867 10.4255 17.8867 13.0773C17.8867 15.7292 15.7293 17.8867 13.0773 17.8867ZM17.8867 9.62487C16.8105 8.13003 15.0558 7.15472 13.0773 7.15472C9.81157 7.15472 7.15465 9.81161 7.15465 13.0774C7.15465 15.0558 8.12996 16.8106 9.62484 17.8868H1.11328V2.90195H3.57734V4.13398C3.57734 4.4414 3.82657 4.69063 4.13398 4.69063C4.4414 4.69063 4.69063 4.4414 4.69063 4.13398V2.90195H7.15469V4.13398C7.15469 4.4414 7.40391 4.69063 7.71133 4.69063C8.01874 4.69063 8.26797 4.4414 8.26797 4.13398V2.90195H10.7321V4.13398C10.7321 4.4414 10.9813 4.69063 11.2887 4.69063C11.5961 4.69063 11.8453 4.4414 11.8453 4.13398V2.90195H14.3094V4.13398C14.3094 4.4414 14.5586 4.69063 14.8661 4.69063C15.1735 4.69063 15.4227 4.4414 15.4227 4.13398V2.90195H17.8867V9.62487Z"
        fill={color}
      />
      <path
        d="M14.8659 12.5206H13.6339V11.2886C13.6339 10.9812 13.3847 10.7319 13.0773 10.7319C12.7699 10.7319 12.5206 10.9812 12.5206 11.2886V13.0772C12.5206 13.3846 12.7699 13.6339 13.0773 13.6339H14.8659C15.1734 13.6339 15.4226 13.3846 15.4226 13.0772C15.4226 12.7698 15.1734 12.5206 14.8659 12.5206Z"
        fill={color}
      />
    </svg>
  )
}

export default Overdue
