import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TextArea from 'components/common/TextArea'
import LinkButton from 'components/common/buttons/LinkButton'
import { Check2 } from 'components/common/icons'
import Chatbubble1 from 'components/common/icons/Chatbubble1'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  needsAttentionHeader: {
    padding: '10px 20px',
    borderRadius: '10px 10px 0px 0px',
    background: '#FFF2D7',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end'
  },
  needsAttentionTitle: {
    color: '#DB615D',
    fontSize: 16,
    fontWeight: 600,
    marginLeft: 10
  },
  needsAttentionBody: {
    padding: '10px 20px',
    borderRadius: '0px 0px 10px 10px',
    background: '#FFFAF0'
  },
  needsAttentionReason: {
    fontWeight: 500,
    color: '#727B8C',
    paddingBottom: 10
  },
  needsAttentionNote: {
    fontWeight: 500,
    paddingBottom: 10
  },
  practiceReply: {
    border: '1px solid #B2B2B2',
    borderRadius: 10
  },
  practiceActionsContainer: {
    marginTop: 5
  }
}))

const PracticeReplySection = ({ onSubmit, isSaving, patientId }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isReplying, setIsReplying] = useState(false)
  const [practiceReply, setPracticeReply] = useState('')
  const practiceDetails = useSelector(state => state.practiceReducer.details)

  const handleReplyOrCancelClicked = useCallback(() => {
    const action = !isReplying ? 'reply' : 'cancel'
    trackEvent(`Needs Attention - ${action} clicked`, {
      patientId,
      practiceName: practiceDetails.practiceName
    })
    setIsReplying(!isReplying)
  }, [isReplying, patientId, practiceDetails])

  const handleSubmitOrResolveClicked = useCallback(() => {
    const action = isReplying ? 'reply saved' : 'resolved'
    trackEvent(`Needs Attention - ${action}`, {
      patientId,
      practiceName: practiceDetails.practiceName
    })
    onSubmit({ action: isReplying ? 'reply' : 'resolve', note: isReplying ? practiceReply : null })
  }, [isReplying, practiceReply, onSubmit, patientId, practiceDetails])

  return (
    <>
      {isReplying && (
        <TextArea
          inputStyle={{ height: 100, padding: 16, background: '#FFFAF0', fontSize: 14 }}
          value={practiceReply}
          setValue={setPracticeReply}
          style={{ bright: true }}
          placeholder={t('dialogs.patientBriefQuickActions.practiceReplyPlaceholder')}
          className={classes.practiceReply}
          isDisabled={isSaving}
        />
      )}
      <Grid container spacing={2} className={classes.practiceActionsContainer}>
        <Grid item>
          <LinkButton
            label={
              isReplying ? t('dialogs.patientBriefQuickActions.cancel') : t('dialogs.patientBriefQuickActions.reply')
            }
            fontSize={14}
            underline
            onClick={handleReplyOrCancelClicked}
            labelClassName={classes.practiceActions}
            icon={!isReplying && <Chatbubble1 />}
            disabled={isSaving}
          />
        </Grid>
        <Grid item>
          <LinkButton
            label={
              isReplying ? t('dialogs.patientBriefQuickActions.submit') : t('dialogs.patientBriefQuickActions.resolve')
            }
            fontSize={14}
            underline
            onClick={handleSubmitOrResolveClicked}
            isLoading={isSaving}
            labelClassName={classes.practiceActions}
            icon={!isReplying && <Check2 width={15} height={10} />}
            disabled={isReplying ? !practiceReply : false}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default PracticeReplySection
