import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'
import 'date-fns'
import React, { useMemo, useState } from 'react'
import CalendarIcon from './icons/Calendar'
import GrinLabel from './text/GrinLabel'
import i18n from '../../resources/locales/i18n'
import { useCallback } from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    justifyContent: 'space-around',
    width: '100%'
  },
  icon: {
    cursor: 'auto',
    zIndex: 1,
    position: 'absolute',
    right: 0,
    top: 20
  },
  pickerOutlined: {
    margin: 0,
    height: '42px'
  },
  pickerInputOutlined: {
    padding: '5px 16px'
  },
  iconOutlined: {
    top: '50%',
    right: 10,
    transform: 'translateY(-50%)',
    cursor: 'pointer'
  },
  outlined: {
    border: '1px solid var(--border-color-2)',
    borderRadius: 0
  },
  errorMessage: {
    opacity: 0,
    color: 'red',
    marginTop: '2px',
    marginBottom: '5px',
    height: '17px',
    fontSize: '12px',
    transition: 'opacity .15s ease',
    '&.active': {
      opacity: 1
    }
  }
}))

const CustomDatePicker = ({
  value,
  label = '',
  minDate = undefined,
  maxDate = new Date('2100-01-01'),
  handleDateChange = () => {},
  disabled = false,
  disablePast = true,
  disableFuture = false,
  style = {},
  minDateMessage = i18n.t('datePicker.minDateMessage'),
  maxDateMessage = i18n.t('datePicker.maxDateMessage'),
  noIcon = false,
  iconComponent,
  className,
  inputClassName,
  inputVariant = 'standard',
  views = ['year', 'month', 'date'],
  format = 'MM/dd/yyyy',
  placeholder = i18n.t('datePicker.placeholder'),
  inputValueMask,
  error,
  keepErrorContainer = false,
  labelClassName,
  datePickerClassName
}) => {
  const classes = useStyles()

  const isOutlined = useMemo(() => inputVariant === 'outlined', [inputVariant])
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = useCallback(
    date => {
      if (!disabled) {
        handleDateChange(date)
      }
    },
    [disabled, handleDateChange]
  )

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.root} style={style}>
        {label && (
          <div>
            <GrinLabel className={labelClassName}>{label}</GrinLabel>
          </div>
        )}
        <div
          className={[classes.datePickerContainer, className].join(' ')}
          onClick={() => {
            !isOpen && !disabled && setIsOpen(true)
          }}
        >
          <DatePicker
            open={isOpen}
            disabled={disabled}
            disablePast={disablePast}
            disableFuture={disableFuture}
            style={{ width: '100%' }}
            margin="normal"
            format={format}
            views={views}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            placeholder={placeholder}
            onChange={handleChange}
            onClose={() => setIsOpen(false)}
            minDateMessage={minDateMessage}
            maxDateMessage={maxDateMessage}
            InputProps={{
              disableUnderline: isOutlined ? true : false,
              className: [isOutlined && classes.pickerInputOutlined, inputClassName].join(' ')
            }}
            className={isOutlined ? classes.pickerOutlined : datePickerClassName}
            TextFieldComponent={props => (
              <TextField
                classes={{ root: isOutlined ? classes.outlined : '' }}
                {...props}
                value={inputValueMask ? inputValueMask(props.value) : props.value}
              />
            )}
          />
          {!noIcon && !iconComponent && (
            <CalendarIcon className={[classes.icon, isOutlined && classes.iconOutlined].join(' ')} />
          )}
          {!noIcon && iconComponent}
        </div>
        {(keepErrorContainer || !!error) && (
          <div className={`${classes.errorMessage} ${error ? 'active' : ''}`}>{error}</div>
        )}
      </div>
    </MuiPickersUtilsProvider>
  )
}

export default CustomDatePicker
