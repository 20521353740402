import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { BroadcastMessage, PersonalMessage } from './Messages'

const useStyles = makeStyles({
  separator: {
    backgroundColor: 'var(--border-color-7)',
    width: 1,
    height: '100%'
  },
  messages: {
    marginBottom: 30
  }
})

const BroadcastComparisonModal = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container justifyContent="space-between" className={classes.messages}>
      <Grid item xs={5}>
        <BroadcastMessage />
        <DazzedParagraph14 bold>
          {t('pages.patients.selectedPatient.chat.comparisonModal.broadcastTitle')}
        </DazzedParagraph14>
        <DazzedParagraph14>
          {t('pages.patients.selectedPatient.chat.comparisonModal.broadcastDescription')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <div className={classes.separator} />
      </Grid>
      <Grid item xs={5}>
        <PersonalMessage />
        <DazzedParagraph14 bold>
          {t('pages.patients.selectedPatient.chat.comparisonModal.personalTitle')}
        </DazzedParagraph14>
        <DazzedParagraph14>
          {t('pages.patients.selectedPatient.chat.comparisonModal.personalDescription')}
        </DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default BroadcastComparisonModal
