import * as React from 'react'

const Download = props => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.3359 17H0.664062C0.297272 17 0 16.7027 0 16.3359C0 15.9691 0.297272 15.6719 0.664062 15.6719H16.3359C16.7027 15.6719 17 15.9691 17 16.3359C17 16.7027 16.7027 17 16.3359 17ZM9.88338 13.4286L13.3851 9.93279C13.6447 9.67378 13.6451 9.2533 13.3859 8.99377C13.1269 8.73424 12.7064 8.73385 12.4469 8.99299L8.94461 12.489C8.81919 12.6145 8.65253 12.6836 8.4751 12.6836C8.29767 12.6836 8.131 12.6145 8.00623 12.4898L4.55428 9.02723C4.29527 8.76744 3.87478 8.76679 3.61512 9.0258C3.35546 9.28468 3.35481 9.70517 3.61369 9.96496L7.06643 13.4282C7.44269 13.8045 7.94294 14.0117 8.4751 14.0117C9.00726 14.0117 9.50751 13.8045 9.88338 13.4286ZM9.13086 10.0273V0.664062C9.13086 0.297272 8.83359 0 8.4668 0C8.10001 0 7.80273 0.297272 7.80273 0.664062V10.0273C7.80273 10.3941 8.10001 10.6914 8.4668 10.6914C8.83359 10.6914 9.13086 10.3941 9.13086 10.0273Z" />
    </svg>
  )
}

export default Download
