import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import SmallDotIcon from '../../common/icons/SmallDot'
import DazzedParagraph12 from '../../common/text/DazzedParagraph12'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles(() => ({
  selectedStatusBanner: {
    backgroundColor: 'var(--bg-color-34)',
    textAlign: 'center',
    padding: '12px',
    marginBottom: '32px',
    '& div': {
      fontSize: 14
    }
  },
  statusTitle: {
    margin: '0 18px 0 10px',
    fontWeight: 600
  },
  statusColor: {
    margin: '0 10px'
  },
  statusLimitations: {
    color: 'var(--text-color-18)',
    fontSize: '12px!important'
  }
}))

const SelectedStatus = ({ subtitleText, color, type }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction={isMobile() ? 'column' : 'row'}
      spacing={isMobile() ? 1 : 0}
      className={classes.selectedStatusBanner}
    >
      <Grid item>
        <DazzedParagraph16 textAlign="center">{t('statuses.updateStatusModal.selectedStatus')}</DazzedParagraph16>
      </Grid>
      <Grid item>
        <DazzedParagraph16 textAlign="center" className={classes.statusTitle}>
          <Grid container alignItems="center">
            <span className={classes.statusColor}>
              <SmallDotIcon color={color} />
            </span>
            {t(`statuses.names.${type}`)}
          </Grid>
        </DazzedParagraph16>
      </Grid>
      {subtitleText && (
        <Grid item>
          <DazzedParagraph12 className={classes.statusLimitations}>{subtitleText}</DazzedParagraph12>
        </Grid>
      )}
    </Grid>
  )
}

export default SelectedStatus
