import * as React from 'react'

const Plus1 = ({ color = 'white', ...props }) => {
  return (
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.69555 0H3.91294V3.91273L0 3.91273V4.69534H3.91294V8.6087H4.69555V4.69534H8.6087V3.91273L4.69555 3.91273V0Z"
        fill={color}
      />
    </svg>
  )
}

export default Plus1
