import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from '../../../common/modals/BaseModal'
import { trackEvent } from 'utils/analyticsUtils'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import ExtractFrameFromVideo, {
  REPLACE_IMAGE_MODES
} from '../../../common/modals/ExtractFrameFromVideo/ExtractFrameFromVideo'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'

const SCAN_SUMMARY_IMAGE_WIDTH = 664
const SCAN_SUMMARY_IMAGE_HEIGHT = 374

const useStyles = makeStyles({
  modal: {
    minWidth: 990,
    paddingLeft: 15
  },
  title: {
    fontFamily: 'Dazzed',
    fontSize: 20,
    fontWeight: 600,
    textAlign: 'left !important',
    width: '100%',
    padding: '10px 0px 0px 12px !important'
  }
})

const ReplaceScanSummaryImageModal = ({ originalImageUrl, originalImageKey, imageIndex, videoUrl, onImageChanged }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [mode, setMode] = useState(REPLACE_IMAGE_MODES.frameSelection)

  const { isOpen, isSaving } = useSelector(state => state.treatmentReducer.replaceScanSummaryImageModal)

  const handleClose = useCallback(() => {
    setMode(REPLACE_IMAGE_MODES.frameSelection)
    dispatch(Actions.toggleReplaceScanSummaryImageModal(false))
    trackEvent('Replace scan summary image modal - Modal closed')
  }, [dispatch])

  const handleConfirm = useCallback(
    newImage => {
      trackEvent('Replace scan summary image modal - Confirm replace image clicked')
      dispatch(
        Actions.replaceOralImage({
          newImage,
          imageIndex,
          originalImageKey
        })
      )
    },
    [dispatch, imageIndex, originalImageKey]
  )

  useEffect(() => {
    if (isOpen) {
      trackEvent('Replace scan summary image modal - Modal opened')
    }
  }, [isOpen])

  return (
    <BaseModal
      open={isOpen}
      title={t(`dialogs.replaceScanSummaryImage.${mode}.title`)}
      className={classes.modal}
      titleClassName={classes.title}
      handleClose={handleClose}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <DazzedParagraph14 strong>{t(`dialogs.replaceScanSummaryImage.${mode}.description`)}</DazzedParagraph14>
        </Grid>
        <Grid item>
          <ExtractFrameFromVideo
            originalImageUrl={originalImageUrl}
            videoUrl={videoUrl}
            onConfirm={handleConfirm}
            imageHeight={SCAN_SUMMARY_IMAGE_HEIGHT}
            imageWidth={SCAN_SUMMARY_IMAGE_WIDTH}
            isLoading={isSaving}
            mode={mode}
            setMode={setMode}
          />
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default ReplaceScanSummaryImageModal
