import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AutochartTabs } from 'consts/autochartConsts'
import Actions from 'actions'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { trackEvent } from 'utils/analyticsUtils'
import AutochartTableTab from './AutochartTableTab/AutochartTableTab'
import AutochartTabsToggle from './AutochartTabsToggle'
import AutochartScanTab from './AutochartScanTab/AutochartScanTab'

const useStyles = makeStyles(
  {
    root: {
      height: '100%'
    },
    tabsContainer: {
      flex: 1
    },
    tabButtonsContainer: {
      borderBottom: '1px solid var(--border-color-7)'
    },
    tab: {
      width: ({ tabs }) => `${100 / tabs}%`,
      borderBottom: '2px solid #fff',
      '&.selected': {
        borderBottom: '2px solid var(--text-color-48)'
      }
    },
    buttonContainer: {
      padding: '8px 12px',
      width: '100%'
    },
    tabLabel: {
      '&.selected': {
        fontWeight: 'bold!important',
        color: 'var(--text-color-48)!important'
      }
    },
    tabBody: {
      flex: 1
    }
  },
  {}
)

const AutochartRightPanel = () => {
  const { t } = useTranslation()
  const classes = useStyles({ tabs: Object.keys(AutochartTabs).length })
  const dispatch = useDispatch()
  const { tab: selectedTabId, isRightPanelVisible } = useSelector(state => state.autochartReducer)

  const tabs = useMemo(
    () => [
      {
        id: AutochartTabs.Scan,
        label: t('dialogs.autochart.tabs.scan'),
        component: <AutochartScanTab />
      },
      {
        id: AutochartTabs.Table,
        label: t('dialogs.autochart.tabs.table'),
        component: <AutochartTableTab />
      }
    ],
    [t]
  )

  const selectedTab = useMemo(() => tabs.find(tab => tab.id === selectedTabId), [tabs, selectedTabId])

  const handleChangeTab = useCallback(
    tab => {
      dispatch(Actions.autochartSetTab(tab))
      trackEvent('Autochart- tab selected', {
        tab
      })
    },
    [dispatch]
  )

  return (
    <Grid container className={classes.root} spacing={2} justifyContent="flex-end">
      <Grid item>
        <AutochartTabsToggle />
      </Grid>
      {isRightPanelVisible && (
        <Grid item className={classes.tabsContainer}>
          <Grid container direction="column">
            <Grid item>
              <Grid container className={classes.tabButtonsContainer}>
                {tabs.map(tab => (
                  <Grid
                    item
                    key={`autochart-tab-${tab.id}`}
                    className={[classes.tab, tab.id === selectedTabId ? 'selected' : ''].join(' ')}
                  >
                    <ButtonBase
                      className={classes.buttonContainer}
                      onClick={() => handleChangeTab(tab.id)}
                      disableRipple
                    >
                      <DazzedParagraph14
                        strong={tab.id !== tab}
                        textAlign="center"
                        className={[classes.tabLabel, tab.id === selectedTabId ? 'selected' : ''].join(' ')}
                      >
                        {tab.label}
                      </DazzedParagraph14>
                    </ButtonBase>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {selectedTab && (
              <Grid item className={classes.tabBody}>
                {selectedTab.component}
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default AutochartRightPanel
