import React, { useMemo, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useRolePermissions from 'hooks/useRolePermissions'
import { makeStyles } from '@material-ui/styles'
import { IconButton, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { Mail, Cancel, CrossedCircle, CheckCircle, Pencil2 as Pencil } from 'components/common/icons'
import MemberCell from './MemberCell'
import ConfirmationModal from 'components/common/ConfirmationModal'
import ResendInvitationModal from '../ResendInvitationModal'
import GrinMenu from 'components/common/menu/GrinMenu'
import { TeamMemberStatuses } from 'consts/authConsts'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import DeactivatePracticeMemberModal from '../../DeactivatePracticeMember/DeactivatePracticeMemberModal'

const useStyles = makeStyles({
  optionsCell: {
    paddingRight: 0
  },
  membersTableMenu: {
    color: 'var(--text-color-6)',
    '& ul': {
      padding: 0
    }
  },
  membersTableMenuItem: {
    height: 50,
    padding: '5px 6px 5px 0px',
    width: 192,
    fontSize: 14,
    '&:hover': {
      backgroundColor: 'var(--bg-color-14)'
    }
  },
  membersTableMenuItemIcon: {
    width: 44,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center'
  }
})

const MemberOptionsCell = ({
  status,
  firstName,
  lastName,
  email,
  memberId,
  doctorId,
  isOwner,
  handleEditMember,
  name,
  avatar,
  roleDescription
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { permissions } = useRolePermissions()

  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false)
  const [isCancelInvitationModalOpen, setIsCancelInvitationModalOpen] = useState(false)
  const [isResendInvitationModalOpen, setIsResendInvitationModalOpen] = useState(false)
  const [isDeactivateUserModalOpen, setIsDeactivateUserModalOpen] = useState(false)

  const doctorRoleDescription = useSelector(state => state.profileReducer.doctor.roleDescription)

  const cancelInvitation = useCallback(() => {
    setIsCancelInvitationModalOpen(false)
    dispatch(Actions.cancelPracticeMemberInvitation({ id: memberId, name }))
    trackEvent('Cancel Practice Member invite', {
      roleOfCancelingUser: doctorRoleDescription || 'accountOwner'
    })
  }, [dispatch, memberId, name, doctorRoleDescription])

  const resendInvitation = useCallback(
    values => {
      setIsResendInvitationModalOpen(false)
      dispatch(Actions.resendPracticeMemberInvitation({ ...values, doctorId, id: memberId }))
    },
    [dispatch, doctorId, memberId]
  )

  const deactivateUser = useCallback(() => {
    setIsDeactivateUserModalOpen(false)
    dispatch(Actions.deactivatePracticeMember(memberId))
    trackEvent('Deactivate Practice Member', {
      roleOfDeactivatingUser: doctorRoleDescription || 'accountOwner'
    })
  }, [dispatch, doctorRoleDescription, memberId])

  const activateUser = useCallback(() => dispatch(Actions.activatePracticeMember(memberId)), [dispatch, memberId])

  const actions = useMemo(
    () => [
      {
        icon: <Cancel />,
        title: t('pages.accountSettings.practiceMembers.table.actions.cancelInvitation'),
        onClick: () => setIsCancelInvitationModalOpen(true),
        condition: () => status === TeamMemberStatuses.invited
      },
      {
        icon: <Mail />,
        title: t('pages.accountSettings.practiceMembers.table.actions.resendInvite'),
        onClick: () => setIsResendInvitationModalOpen(true),
        condition: () => status === TeamMemberStatuses.invited
      },
      {
        icon: <CrossedCircle color="black" />,
        title: t('pages.accountSettings.practiceMembers.table.actions.deactivateUser'),
        onClick: () => setIsDeactivateUserModalOpen(true),
        condition: () => !isOwner && status === TeamMemberStatuses.confirmed
      },
      {
        icon: <CheckCircle />,
        title: t('pages.accountSettings.practiceMembers.table.actions.activateUser'),
        onClick: activateUser,
        condition: () => status === TeamMemberStatuses.disabled
      },
      {
        icon: <Pencil />,
        title: t('pages.accountSettings.practiceMembers.table.actions.editPersonalInfo'),
        onClick: () => handleEditMember(memberId),
        condition: () => permissions.opsDashboardEditPracticeMembers && status === TeamMemberStatuses.confirmed
      }
    ],
    [activateUser, handleEditMember, memberId, permissions.opsDashboardEditPracticeMembers, status, isOwner, t]
  )

  const filteredActions = useMemo(() => actions.filter(action => !action.condition || action.condition()), [actions])

  return (
    <MemberCell className={classes.optionsCell}>
      {!!filteredActions.length && (
        <>
          {status && permissions.editPracticeMembers && (
            <GrinMenu
              open={isOptionsMenuOpen}
              triggerComponent={
                <IconButton>
                  <MoreVert />
                </IconButton>
              }
              transformOrigin={{ vertical: -40, horizontal: 'left' }}
              className={classes.membersTableMenu}
              onOpen={() => setIsOptionsMenuOpen(true)}
              onClose={() => setIsOptionsMenuOpen(false)}
            >
              {filteredActions.map(action => (
                <MenuItem
                  key={action.title}
                  onClick={() => {
                    action.onClick()
                    setIsOptionsMenuOpen(false)
                  }}
                  className={classes.membersTableMenuItem}
                >
                  <span className={classes.membersTableMenuItemIcon}>{action.icon}</span>
                  {action.title}
                </MenuItem>
              ))}
            </GrinMenu>
          )}
          <ConfirmationModal
            title={t('pages.accountSettings.practiceMembers.modals.cancelInvitation.title')}
            text={t('pages.accountSettings.practiceMembers.modals.cancelInvitation.content', { name })}
            additionalText={t('pages.accountSettings.practiceMembers.modals.cancelInvitation.content2')}
            acceptButtonText={t('pages.accountSettings.practiceMembers.modals.cancelInvitation.primaryBtn')}
            largerButtons
            smallText
            isOpen={isCancelInvitationModalOpen}
            onClose={() => setIsCancelInvitationModalOpen(false)}
            onConfirm={cancelInvitation}
          />
          <ResendInvitationModal
            firstName={firstName}
            lastName={lastName}
            email={email}
            isOpen={isResendInvitationModalOpen}
            onClose={() => setIsResendInvitationModalOpen(false)}
            onConfirm={resendInvitation}
          />
          <DeactivatePracticeMemberModal
            isOpen={isDeactivateUserModalOpen}
            onClose={() => setIsDeactivateUserModalOpen(false)}
            name={name}
            avatar={avatar}
            roleDescription={roleDescription}
            memberId={memberId}
            onSubmit={deactivateUser}
          />
        </>
      )}
    </MemberCell>
  )
}

export default MemberOptionsCell
