import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Actions from '../../actions'
import ROUTES from '../../consts/routesConsts'
import { isUserOfRole } from 'utils/authUtils'
import { Roles } from '../../consts/authConsts'
import OggHeading40 from '../common/text/OggHeading40'

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: '1px solid rgba(0, 19, 53, 0.1)',
    width: '100%',
    height: '100%'
  },
  loaderContainer: {
    position: 'absolute',
    transformOrigin: '50% 0',
    top: '50%',
    left: '50%'
  },
  content: {
    width: '90%',
    maxWidth: 1200,
    height: '100%',
    margin: '0 auto'
  },
  toolbar: {
    margin: '40px 40px 16px 40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  toolbarActions: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: 5
    }
  },
  dashboardContainer: {
    width: '100%',
    display: 'flex',
    height: '100%',
    margin: '50px',
    justifyContent: 'center'
  },
  dashboardIframe: {
    width: '100%',
    height: '75%'
  }
}))

const DoctorStats = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { isLoading, dashboardUrl } = useSelector(state => state.profileReducer.stats)

  useEffect(() => {
    if (isUserOfRole([Roles.Admin])) {
      history.push(ROUTES.STATS_DASHBOARD)
    }
  }, [history])

  useEffect(() => {
    dispatch(Actions.fetchEmbeddedDashboardUrl())
  }, [dispatch])

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.toolbar}>
          <OggHeading40 textAlign="left">{t('pages.accountSummary.title')}</OggHeading40>
        </div>
        {isLoading ? (
          <div className={classes.loaderContainer}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <div className={classes.dashboardContainer}>
            <iframe title="dashboard" src={dashboardUrl} frameBorder="0" className={classes.dashboardIframe}></iframe>
          </div>
        )}
      </div>
    </div>
  )
}

export default DoctorStats
