import * as React from 'react'

const Download = ({ width = 18, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 1.5V13" stroke="#4251E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 9L9 13L13 9" stroke="#4251E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.5 14.5H16.5" stroke="#4251E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default Download
