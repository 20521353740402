import React, { useCallback } from 'react'
import SwooshLargeIcon from '../common/icons/SwooshLarge1'
import SwooshSmallIcon from '../common/icons/Swoosh'
import FormInput from '../common/FormInput'
import Actions from '../../actions'
import ROUTES from '../../consts/routesConsts'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from 'react-router-dom'
import { Grid, Button, Link } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import WithLoader from '../common/WithLoader'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import OggHeading40 from 'components/common/text/OggHeading40'
import useCssClasses from 'hooks/useCssClasses'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
    color: 'var(--text-color-1)',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  signInSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  title: {
    fontSize: isMobile ? 32 : 40
  },
  form: {
    maxWidth: '512px',
    minWidth: isMobile ? 'auto' : 512,
    padding: 18
  },
  nextButton: {
    background: 'var(--border-color-1)',
    color: 'var(--text-color-1)',
    width: '100%',
    borderRadius: '34px',
    '&:hover': {
      background: 'var(--border-color-1)'
    }
  },
  noFill: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      transition: 'color 0.2s ease',
      color: 'gray',
      boxShadow: 'none'
    }
  }
}))

const ResetPassword = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.authReducer.isLoading)
  const { emailAddress, errors } = useSelector(state => state.authReducer)
  useCssClasses(true)

  const handleFormSubmit = useCallback(
    e => {
      e.preventDefault()
      dispatch(Actions.requestResetPassword())
    },
    [dispatch]
  )

  return (
    <Grid className={classes.root} container alignItems="center" justifyContent="center">
      {!isMobile ? (
        <SwooshLargeIcon className={classes.signInSwoosh} />
      ) : (
        <SwooshSmallIcon className={classes.signInSwoosh} />
      )}

      <form onSubmit={handleFormSubmit}>
        <Grid container direction="column" justifyContent="center" spacing={isMobile ? 5 : 3} className={classes.form}>
          <Grid item></Grid>
          <Grid item>
            <OggHeading40 className={classes.title} dark textAlign={isMobile ? 'center' : 'start'}>
              Reset Password
            </OggHeading40>
          </Grid>
          <Grid item />
          <Grid item>
            <FormInput
              title="Email Address"
              type="email"
              allowAutoComplete={true}
              value={emailAddress}
              validationRules={{ required: true }}
              errorMessage={errors.email}
              setValue={emailAddress =>
                dispatch(
                  Actions.changeAuthForm({
                    key: 'emailAddress',
                    value: emailAddress
                  })
                )
              }
            />
          </Grid>
          <Grid item sm={false} />
          <Grid item>
            <WithLoader isLoading={isLoading}>
              <Button variant="contained" className={classes.nextButton} type="submit">
                Reset
              </Button>
            </WithLoader>
          </Grid>
          <Grid container justifyContent="center">
            <Link component={RouterLink} to={ROUTES.SIGN_IN}>
              <Button variant="contained" className={classNames(classes.nextButton, classes.noFill)}>
                Back to sign in
              </Button>
            </Link>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}

export default ResetPassword
