import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Oval from '../../common/icons/Oval'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import OggHeading40 from '../../common/text/OggHeading40'
import useRcDetails from '../useRcDetails'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: ({ background }) => background
  },
  title: {
    color: ({ textColor }) => textColor,
    fontSize: 50
  },
  content: {
    maxWidth: '1088px',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    padding: '60px 0'
  },
  imageContainer: {
    position: 'relative',
    flex: 1
  },
  image: {
    position: 'relative',
    zIndex: 2
  },
  oval: {
    position: 'absolute',
    left: 80,
    bottom: 10,
    zIndex: 1
  },
  textContainer: {
    flex: 1
  },
  patientQuote: {
    fontSize: 32,
    lineHeight: '46px',
    color: ({ textColor }) => textColor
  },
  patientName: {
    marginTop: 20,
    fontSize: 18,
    lineHeight: '28.8px',
    color: ({ textColor }) => textColor
  }
}))

const PatientRecommendation = () => {
  const doctorDetails = useRcDetails()

  const classes = useStyles({
    background: doctorDetails.colorSettings.footerBackgroundColor || doctorDetails.colorSettings.primary,
    textColor: doctorDetails.colorSettings.footerTextColor || doctorDetails.colorSettings.secondary
  })

  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <OggHeading40 className={classes.title}>{t('pages.rcLanding.body.patientReview.title')}</OggHeading40>
      <div className={classes.content}>
        <div className={classes.imageContainer}>
          <img
            src="https://d1g4lo0dz8n4nh.cloudfront.net/images/rc-landing-page/patient-review-image.png"
            alt="Recommendation"
            className={classes.image}
          />
          <Oval
            diameter={134}
            color={doctorDetails.colorSettings.accentColor || doctorDetails.colorSettings.secondary}
            className={classes.oval}
          />
        </div>
        <div className={classes.textContainer}>
          <DazzedParagraph16 className={classes.patientQuote}>
            {t('pages.rcLanding.body.patientReview.quote')}
          </DazzedParagraph16>
          <DazzedParagraph16 className={classes.patientName}>
            {t('pages.rcLanding.body.patientReview.patientName')}
          </DazzedParagraph16>
        </div>
      </div>
    </div>
  )
}

export default PatientRecommendation
