import * as React from 'react'

const ShareFeedbackQuickLink = ({ className }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0281 24.2149L11.7549 24.1485L11.5563 24.3474L7.3777 28.5312C7.37768 28.5312 7.37766 28.5312 7.37763 28.5313C7.3767 28.5322 7.37417 28.5343 7.36924 28.5363C7.36459 28.5381 7.35936 28.5392 7.35441 28.5395C7.35434 28.5395 7.35427 28.5394 7.35419 28.5394C7.35199 28.539 7.34822 28.5379 7.34339 28.5359L7.34344 28.5358L7.33315 28.5317C7.33021 28.5305 7.32716 28.5285 7.3241 28.5239C7.32071 28.5188 7.31868 28.5124 7.31868 28.5058V22.4592V22.2003L7.10733 22.0509C4.56867 20.256 3.1665 17.7866 3.1665 15.2598C3.1665 12.717 4.55592 10.3718 6.87879 8.64451C9.20146 6.91741 12.4313 5.83333 16.0139 5.83333C19.5938 5.83333 22.8209 6.91731 25.1415 8.64432C27.4623 10.3715 28.8506 12.7168 28.8506 15.2598V15.3096C28.8326 17.8306 27.4475 20.1553 25.1418 21.8706C22.8211 23.597 19.594 24.681 16.0141 24.681C14.6429 24.681 13.3059 24.5253 12.0281 24.2149Z"
        stroke="#3C52EF"
      />
    </svg>
  )
}

export default ShareFeedbackQuickLink
