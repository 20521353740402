import * as React from 'react'

const Pencil2 = props => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.77896 5.99917C0.997911 5.21813 0.997911 3.9518 1.77896 3.17075L3.17107 1.77863C3.95212 0.997584 5.21845 0.997585 5.9995 1.77863L13.0706 8.8497L8.85003 13.0702L1.77896 5.99917Z"
        stroke="#001335"
        strokeWidth="1.5"
      />
      <path
        d="M15.1919 10.971L15.5565 15.5562L10.9713 15.1916L8.85001 13.0703L13.0706 8.84973L15.1919 10.971Z"
        stroke="#001335"
        strokeWidth="1.5"
      />
      <path d="M4.64844 5.25104L7.06018 2.8393" stroke="#001335" strokeWidth="1.5" />
    </svg>
  )
}

export default Pencil2
