import { makeStyles, Grid, createTheme, ThemeProvider } from '@material-ui/core'
import Actions from 'actions'
import ConfirmationModal from 'components/common/ConfirmationModal'
import WithLoader from 'components/common/WithLoader'
import useHideHelpWidget from 'hooks/useHideHelpWidget'
import useRolePermissions from 'hooks/useRolePermissions'
import _ from 'lodash'
import MUIDataTable from 'mui-datatables'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { trackEvent } from 'utils/analyticsUtils'
import { mapToRCLeadsDashboard, mapToRcLead } from 'utils/mappers/leadsMappers'
import { createdAtSort } from 'utils/muiDataTableUtils'
import LeadNameAndEmail from './LeadNameAndEmail'
import RCDashboardActions from './RCDashboardActions'
import RCDashboardEmptyState from './RCDashboardEmptyState'
import RCLeadInfoPopup from './RCLeadInfoPopup'
import RCOpsDetailsDialog from './RCOpsDetailsDialog'
import RCShippingDetailsDialog from './RCShippingDetailsDialog/RCShippingDetailsDialog'
import queryString from 'query-string'
import { isMobile } from 'utils/mobileUtils'
import { ROUTES } from 'consts'
import QuickFilters from './QuickFilters'
import { LeadInfo } from 'components/common/icons'
import RCResolvePatientPopup from './RCResolvePatientPopup'
import RCLeadStatus from './RCLeadStatus'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import ShowMoreText from 'react-show-more-text'
import RCShippingStatus from './RCShippingStatus'
import useRcFilters from './useRcFilters'
import { daysAgo } from 'utils/stringUtils'

const useSupportDashboardStyles = makeStyles({
  container: {
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 40,
    justifyContent: 'center'
  },
  chip: {
    margin: 1
  },
  mb: {
    marginBottom: 10
  },
  leadStatusCell: {
    width: 90
  },
  leadInfoIcon: {
    cursor: 'pointer',
    marginTop: 3
  },
  patientsNote: {
    wordBreak: 'break-word'
  },
  clickable: {
    cursor: 'pointer',
    color: 'var(--text-color-25)',
    textDecoration: 'underline'
  },
  trunacte: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
})
const getMuiTableTheme = () =>
  createTheme({
    typography: {
      fontFamily: 'Dazzed'
    },
    overrides: {
      MUIDataTableToolbar: {
        actions: {
          display: 'flex',
          flex: '3 !important',
          flexDirection: 'row-reverse',
          alignItems: 'center',
          justifyContent: 'flex-end'
        },
        left: {
          flex: 1
        }
      },
      MuiPaper: {
        root: {
          boxShadow: '0px 0px 0px gray !important'
        }
      },
      MuiTableCell: {
        root: {
          background: 'white !important',
          maxWidth: '300px !important'
        }
      },
      MUIDataTableSearch: {
        searchText: {
          flex: '1 !important'
        }
      },
      MUIDataTable: {
        paper: {
          width: '100% !important'
        }
      },
      MUIDataTableHeadCell: {
        data: {
          fontSize: '12px !important',
          color: 'var(--text-color-14) !important'
        },
        root: {
          fontSize: '12px !important',
          color: 'var(--text-color-14) !important'
        }
      }
    }
  })

const RCDashboard = () => {
  const classes = useSupportDashboardStyles()
  const { search } = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()

  const leads = useSelector(state => state.rcDashboardReducer.leads)
  const isLoading = useSelector(state => state.rcDashboardReducer.isLoading)
  const isSaving = useSelector(state => state.rcDashboardReducer.isSaving)
  const leadsBeingSaved = useSelector(state => state.rcDashboardReducer.leadsBeingSaved)
  const [selectedLead, setSelectedLead] = useState(null)
  const [action, setAction] = useState(null)
  const [hasSentLeadsCountAnalytic, setHasSentLeadsCountAnalytic] = useState(false)

  const analyticsViewMode = useMemo(
    () => (permissions.rcDashboardAdminActions ? 'support view' : 'doctor view'),
    [permissions]
  )

  const LEAD_ACTIONS = useMemo(
    () => ({
      APPROVE: 'approve',
      DENY: 'deny',
      SHIPPING_DETAILS: 'shippingDetails',
      OPS_DETAILS: 'opsDetails',
      INFO: 'info',
      RESOLVE_LEAD: 'resolveLead'
    }),
    []
  )

  const leadIdFromQuery = useMemo(() => queryString.parse(search)?.leadId, [search])
  const mappedLeads = useMemo(() => (leads.length > 0 ? mapToRCLeadsDashboard(leads) : []), [leads])

  const { enabledFilters, quickFilters, toggleQuickFilters, tableSearch } = useRcFilters({
    leads: mappedLeads,
    withAdminActions: permissions.rcDashboardAdminActions
  })
  const filteredLeads = useMemo(
    () =>
      Object.keys(enabledFilters).reduce(
        (updatedLeads, currFilter) => updatedLeads.filter(enabledFilters[currFilter].filter),
        mappedLeads
      ),
    [enabledFilters, mappedLeads]
  )

  const approveRCLead = useCallback(
    leadInput => {
      dispatch(Actions.approveRCLead(leadInput))
    },
    [dispatch]
  )

  const updateRCLead = useCallback(
    leadInput => {
      dispatch(Actions.updateRCLead(leadInput))
    },
    [dispatch]
  )

  const handleCloseDialog = useCallback(() => {
    setAction(null)
    setSelectedLead(null)
    history.push({
      search: ''
    })

    if (isMobile()) {
      history.push({
        pathname: ROUTES.PATIENTS
      })
    }
  }, [history])

  const handleApproveLead = useCallback(() => {
    trackEvent('RC dashboard - lead approve confirm', { leadId: selectedLead.id, analyticsViewMode })
    approveRCLead({
      id: selectedLead.id,
      _version: selectedLead._version,
      conversionStatus: 'approved',
      program: 'rc'
    })
    handleCloseDialog()
  }, [approveRCLead, handleCloseDialog, selectedLead, analyticsViewMode])

  const handleDenyLead = useCallback(
    ({ comment: denialReason }) => {
      trackEvent('RC dashboard - lead deny confirm', { leadId: selectedLead.id, denialReason, analyticsViewMode })
      updateRCLead({
        id: selectedLead.id,
        _version: selectedLead._version,
        conversionStatus: 'denied',
        program: 'rc',
        rcData: JSON.stringify({
          ...selectedLead.rcData,
          denialReason
        })
      })
      handleCloseDialog()
    },
    [updateRCLead, selectedLead, analyticsViewMode, handleCloseDialog]
  )

  const renderSubmissionDataColumn = useCallback(
    (r, rowContext) => {
      const lead = rowContext.rowData[0]

      return (
        <>
          {lead.submissionType.split(';').map((text, i) => (
            <div className={classes.mb} key={i}>
              {text}
            </div>
          ))}
        </>
      )
    },
    [classes.mb]
  )

  const handleActionSelection = useCallback(
    ({ lead, action, sendAnalytics = true }) => {
      if (sendAnalytics) {
        trackEvent(`RC dashboard - lead action click`, { action, leadId: lead.id, analyticsViewMode })
      }

      setAction(action)
      setSelectedLead(lead)
    },
    [analyticsViewMode]
  )

  const handleLeadTableSelection = useCallback(
    rowContext => {
      const lead = rowContext?.rowData[0]
      if (!lead) {
        return
      }

      trackEvent(`RC dashboard - lead action click`, { action: LEAD_ACTIONS.INFO, leadId: lead.id, analyticsViewMode })

      history.push({
        search: `?leadId=${lead.id}`
      })
    },
    [analyticsViewMode, history, LEAD_ACTIONS]
  )

  useEffect(() => {
    if (leadIdFromQuery && leads?.length) {
      trackEvent(`RC dashboard - lead selected from url`, { leadIdFromQuery, analyticsViewMode })
      const lead = leads.find(lead => lead.id === leadIdFromQuery)
      if (!lead) {
        return
      }

      handleActionSelection({ lead: mapToRcLead(lead), action: LEAD_ACTIONS.INFO, sendAnalytics: false })
    }
  }, [leadIdFromQuery, leads, handleActionSelection, LEAD_ACTIONS.INFO, analyticsViewMode])

  useEffect(() => {
    if (mappedLeads && !hasSentLeadsCountAnalytic) {
      trackEvent('RC dashboard - leads shown', {
        amount: mappedLeads.length
      })
      setHasSentLeadsCountAnalytic(true)
    }
  }, [hasSentLeadsCountAnalytic, mappedLeads])

  const renderActionsColumn = useCallback(
    (r, rowContext) => {
      const lead = rowContext.rowData[0]

      return (
        <RCDashboardActions
          lead={lead}
          LEAD_ACTIONS={LEAD_ACTIONS}
          isLeadBeingSaved={!!leadsBeingSaved[lead.id]}
          onActionSelected={handleActionSelection}
          withAdminActions={permissions.rcDashboardAdminActions}
          analyticsSource="RC dashboard"
          alignment="column"
        />
      )
    },
    [LEAD_ACTIONS, leadsBeingSaved, handleActionSelection, permissions]
  )

  const columns = useMemo(
    () => [
      {
        name: 'leadObj',
        options: { display: 'excluded', filter: false }
      },
      {
        name: 'leadName',
        label: t('pages.rcDashboard.table.patientDetails'),
        options: {
          customBodyRender: (name, rowContext) => {
            const lead = rowContext.rowData[0]

            return (
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={2} className={classes.leadInfoIcon} onClick={() => handleLeadTableSelection(rowContext)}>
                  <LeadInfo />
                </Grid>
                <Grid item xs={10}>
                  <Grid container direction="column" justifyContent="center">
                    <Grid item xs={12}>
                      <DazzedParagraph14 strong className={classes.trunacte}>
                        {name}
                      </DazzedParagraph14>
                    </Grid>
                    <Grid item xs={12}>
                      <DazzedParagraph14 className={classes.trunacte}>{lead.email}</DazzedParagraph14>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          },
          filterOptions: {
            logic: (name, filters, row) => {
              const lead = row[0]
              const searchQuery = filters[0] || ''

              return !lead.leadNameAndEmail.toLowerCase().includes(searchQuery.toLowerCase())
            }
          }
        }
      },
      { name: 'age', label: t('pages.rcDashboard.table.age') },
      {
        name: 'additionalInfo',
        label: t('pages.rcDashboard.table.additionalInfo'),
        options: {
          customBodyRender: (note, rowContext) => (
            <ShowMoreText
              lines={2}
              more={t('general.showMore')}
              less={t('general.showLess')}
              className={classes.patientsNote}
              anchorClass={classes.clickable}
              onClick={() => {
                handleLeadTableSelection(rowContext)
              }}
              expanded={false}
              expandByClick={false}
            >
              {note}
            </ShowMoreText>
          ),
          display: !permissions.rcDashboardAdminActions
        }
      },
      {
        name: 'phone',
        label: t('pages.rcDashboard.table.phone'),
        options: {
          display: false
        }
      },
      {
        name: 'address',
        label: t('pages.rcDashboard.table.address'),
        options: {
          customBodyRender: (r, rowContext) => {
            const lead = rowContext.rowData[0]
            return `${lead.rcData.address}, ${lead.rcData.address2}, ${lead.rcData.city}, ${lead.rcData.state}, ${lead.rcData.zipcode}, ${lead.rcData.country}`
          }
        }
      },
      {
        name: 'state',
        label: t('pages.rcDashboard.table.state'),
        options: {
          display: false
        }
      },
      {
        name: 'country',
        label: t('pages.rcDashboard.table.country'),
        options: {
          display: false
        }
      },
      {
        name: 'doctorName',
        label: t('pages.rcDashboard.table.doctorName'),
        options: {
          display: !permissions.rcDashboardAdminActions ? 'excluded' : true,
          filter: permissions.rcDashboardAdminActions
        }
      },
      {
        name: 'practiceName',
        label: t('pages.rcDashboard.table.practiceName'),
        options: {
          display: !permissions.rcDashboardAdminActions ? 'excluded' : false,
          filter: permissions.rcDashboardAdminActions
        }
      },
      {
        name: 'submissionType',
        label: t('pages.rcDashboard.table.submissionType'),
        options: {
          customBodyRender: renderSubmissionDataColumn,
          display: !permissions.rcDashboardAdminActions ? 'excluded' : true,
          sort: false,
          filter: false
        }
      },
      {
        name: 'shippingStatus',
        label: t('pages.rcDashboard.table.shippingStatus'),
        options: {
          customBodyRender: (r, rowContext) => {
            const lead = rowContext.rowData[0]

            return <RCShippingStatus lead={lead} withAdminActions={permissions.rcDashboardAdminActions} />
          },
          display: true,
          filter: true,
          filterType: 'dropdown'
        }
      },
      {
        name: 'createdAt',
        label: t('pages.rcDashboard.table.leadSubmissionDate'),
        options: {
          customBodyRender: (createdAt, rowContext) =>
            daysAgo({ date: createdAt, format: 'll', isFirstLetterCapital: true }),
          sort: true,
          sortOrder: 'desc'
        }
      },
      {
        name: 'lastUpdateDate',
        label: t('pages.rcDashboard.table.leadLastUpdateDate'),
        options: {
          customBodyRender: (lastUpdateDate, rowContext) =>
            daysAgo({ date: lastUpdateDate, format: 'll', isFirstLetterCapital: true }),
          display: true,
          sort: true,
          sortOrder: 'desc'
        }
      },
      {
        name: 'status',
        label: t('pages.rcDashboard.table.leadStatus'),
        options: {
          customBodyRender: (status, rowContext) => <RCLeadStatus status={rowContext.rowData[0]?.status} />,
          display: true,
          filter: false
        }
      },
      ...(permissions.rcDashboardAdminActions
        ? [
            {
              name: 'conversionStatus',
              label: t('pages.rcDashboard.table.conversionStatus'),
              options: {
                display: false,
                filterType: 'dropdown',
                customBodyRender: (r, rowContext) => {
                  const lead = rowContext.rowData[0]
                  return t(`pages.rcDashboard.table.leadConversionStatuses.${lead.conversionStatus}`)
                }
              }
            },
            {
              name: 'leadId',
              label: t('pages.rcDashboard.table.leadId'),
              options: {
                display: false
              }
            },
            {
              name: 'leadApprovalDate',
              label: t('pages.rcDashboard.table.leadApprovalDate'),
              options: {
                customBodyRender: (leadApprovalDate, rowContext) =>
                  leadApprovalDate
                    ? daysAgo({ date: leadApprovalDate, format: 'll', isFirstLetterCapital: true })
                    : '-',
                display: false,
                sort: true,
                sortOrder: 'desc'
              }
            },
            {
              name: 'statusType',
              label: t('pages.rcDashboard.table.statusType'),
              options: {
                display: false,
                filter: false
              }
            },
            {
              name: 'grinPlanKey',
              label: t('pages.rcDashboard.table.subscriptionPlan'),
              options: {
                display: false,
                filter: false
              }
            }
          ]
        : []),
      {
        name: 'actions',
        label: t('pages.rcDashboard.table.actions'),
        options: {
          customBodyRender: renderActionsColumn,
          filter: false,
          sort: false
        }
      }
    ],
    [t, permissions, renderSubmissionDataColumn, renderActionsColumn, classes, handleLeadTableSelection]
  )

  useHideHelpWidget()

  const options = {
    filterType: 'textField',
    rowsPerPage: 30,
    rowsPerPageOptions: [10, 30, 50, 100],
    download: permissions.opsDashboardDownloadCsv,
    downloadOptions: {
      filename: 'RcLeads.csv',
      filterOptions: {
        useDisplayedRowsOnly: true
      }
    },
    print: false,
    selectableRowsHideCheckboxes: true,
    customSort: createdAtSort(columns),
    sortOrder: {
      name: 'createdAt',
      direction: 'desc'
    },
    searchAlwaysOpen: true,
    searchPlaceholder: 'Search for anything',
    onChangePage: currentPage => trackEvent('RC dashboard - change page click', { analyticsViewMode, currentPage }),
    onChangeRowsPerPage: rowsPerPage =>
      trackEvent('RC dashboard - change rows per page click', { analyticsViewMode, rowsPerPage }),
    onColumnSortChange: (changedColumn, direction) =>
      trackEvent('RC dashboard - column sort change', { analyticsViewMode, direction, column: changedColumn }),
    onSearchChange: _.debounce(text => trackEvent('RC dashboard - search', { analyticsViewMode, text }), 300),
    customToolbar: () => (
      <QuickFilters
        quickFilters={quickFilters}
        toggleQuickFilters={toggleQuickFilters}
        leads={mappedLeads}
        withAdminActions={permissions.rcDashboardAdminActions}
      />
    ),
    textLabels: {
      body: {
        noMatch: t('pages.rcDashboard.table.noMatchText')
      }
    },
    customSearch: (searchQuery, currentRow, columns) => {
      const lead = currentRow[0]
      return tableSearch({ searchQuery, currentLead: lead, isAdmin: permissions.rcDashboardAdminActions })
    }
  }

  return (
    <div className={classes.container}>
      <WithLoader isLoading={isLoading}>
        {leads?.length === 0 ? (
          <RCDashboardEmptyState />
        ) : (
          <ThemeProvider theme={getMuiTableTheme()}>
            <MUIDataTable data={filteredLeads} columns={columns} options={options} />
          </ThemeProvider>
        )}
      </WithLoader>
      <ConfirmationModal
        isOpen={action === LEAD_ACTIONS.APPROVE && !!selectedLead}
        onConfirm={handleApproveLead}
        onClose={handleCloseDialog}
        AdditionalComponent={<LeadNameAndEmail selectedLead={selectedLead} />}
        smallText
        largerButtons={!isMobile()}
        fullScreen={isMobile()}
        title={t('pages.rcDashboard.actions.approveRCLeadConfirmation')}
        acceptButtonText={t('pages.rcDashboard.actions.approveLead')}
      />
      <ConfirmationModal
        isOpen={action === LEAD_ACTIONS.DENY && !!selectedLead}
        onConfirm={handleDenyLead}
        onClose={handleCloseDialog}
        title={t('pages.rcDashboard.dialogs.denialConfirmation.title')}
        acceptButtonText={t('pages.rcDashboard.actions.denyLead')}
        withComment
        AdditionalComponent={<LeadNameAndEmail selectedLead={selectedLead} />}
        smallText
        largerButtons={!isMobile()}
        fullScreen={isMobile()}
        commentFieldTitle={t('pages.rcDashboard.dialogs.denialConfirmation.reasonLabel')}
        commentFieldPlaceholder={t('pages.rcDashboard.dialogs.denialConfirmation.reasonPlaceholder')}
      />
      <RCShippingDetailsDialog
        isOpen={action === LEAD_ACTIONS.SHIPPING_DETAILS}
        leadId={selectedLead?.id}
        isSaving={isSaving}
        handleClose={handleCloseDialog}
      />
      <RCOpsDetailsDialog
        isOpen={action === LEAD_ACTIONS.OPS_DETAILS}
        lead={selectedLead}
        isSaving={isSaving}
        handleClose={handleCloseDialog}
      />
      <RCLeadInfoPopup
        isOpen={action === LEAD_ACTIONS.INFO}
        lead={selectedLead}
        onApprove={lead => handleActionSelection({ lead, action: LEAD_ACTIONS.APPROVE })}
        onDeny={lead => handleActionSelection({ lead, action: LEAD_ACTIONS.DENY })}
        onClose={handleCloseDialog}
        LEAD_ACTIONS={LEAD_ACTIONS}
        handleActionSelection={handleActionSelection}
        leadsBeingSaved={leadsBeingSaved}
      />
      <RCResolvePatientPopup
        isOpen={action === LEAD_ACTIONS.RESOLVE_LEAD}
        lead={selectedLead}
        onClose={handleCloseDialog}
      />
    </div>
  )
}

export default RCDashboard
