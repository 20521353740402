import { makeStyles, Grid } from '@material-ui/core'
import React from 'react'
import GIGrinScanList from './GIGrinScanList'
import GIAppHeader from './GIAppHeader'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%'
  },
  appHeader: {
    width: '100%',
    height: '88px'
  },
  content: {
    width: '100%',
    height: 'calc(100% - 88px)'
  }
}))

const GIDashboard = () => {
  const classes = useStyles()
  return (
    <Grid container className={classes.container}>
      <Grid item className={classes.appHeader}>
        <GIAppHeader />
      </Grid>
      <Grid item className={classes.content}>
        <GIGrinScanList />
      </Grid>
    </Grid>
  )
}

export default GIDashboard
