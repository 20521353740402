import Actions from 'actions'
import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import GrinMenu from 'components/common/menu/GrinMenu'
import { trackEvent } from 'utils/analyticsUtils'
import SavedViewsMenu from './SavedViewsMenu'
import SavedViewsTrigger from './SavedViewsTrigger'
import { useDispatch } from 'react-redux'
import useSavedViews from '../hooks/useSavedViews'
import { isEqual } from 'lodash'

const useStyles = makeStyles(theme => ({
  saveView: {
    cursor: 'pointer',
    color: 'var(--text-color-3)',
    textDecoration: 'underline'
  },
  menu: {
    overflowY: 'hidden',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px'
  },
  menuTitle: {
    paddingLeft: 16
  }
}))

const SavedViews = ({ isFilterActivated, selectedFilters, onSavedViewSelected }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { allSavedViews } = useSavedViews()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const selectedSavedView = useMemo(
    () => allSavedViews.find(view => isEqual(view.filters, selectedFilters)),
    [allSavedViews, selectedFilters]
  )
  const currentViewLabel = useMemo(
    () => selectedSavedView?.name || t('pages.patients.patientsList.savedViews.custom'),
    [selectedSavedView, t]
  )

  const handleSaveViewClicked = useCallback(() => {
    trackEvent('Saved views - Save new view clicked')
    dispatch(Actions.toggleNewSavedViewModal(true))
  }, [dispatch])

  const handleViewSelected = useCallback(
    view => {
      onSavedViewSelected(view)
      setIsMenuOpen(false)
    },
    [onSavedViewSelected]
  )

  const handleCloseMenu = useCallback(() => {
    trackEvent('Saved views - Menu closed')
    setIsMenuOpen(false)
  }, [])

  const handleOpenMenu = useCallback(() => {
    trackEvent('Saved views - Menu opened')
    setIsMenuOpen(true)
  }, [])

  return (
    <Grid container spacing={1}>
      {isFilterActivated && !selectedSavedView && (
        <Grid item>
          <DazzedParagraph14 strong className={classes.saveView} onClick={handleSaveViewClicked}>
            {t('pages.patients.patientsList.savedViews.saveView')}
          </DazzedParagraph14>
        </Grid>
      )}
      <Grid item>
        <GrinMenu
          disableMenuFocus
          className={classes.menu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          open={isMenuOpen}
          transformOrigin={{
            vertical: -30,
            horizontal: 'center'
          }}
          triggerComponent={<SavedViewsTrigger currentViewLabel={currentViewLabel} isMenuOpen={isMenuOpen} />}
        >
          <SavedViewsMenu selectedSavedView={selectedSavedView} onViewSelected={handleViewSelected} />
        </GrinMenu>
      </Grid>
    </Grid>
  )
}

export default SavedViews
