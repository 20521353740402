import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.main,
    fontFamily: 'Dazzed',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '40px'
  }
}))

const DazzedHeading24 = ({ className, children }) => {
  const classes = useStyles()

  return <div className={[classes.heading, className].join(' ')}>{children}</div>
}

export default DazzedHeading24
