import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import BaseModal from '../../common/modals/BaseModal'
import StatusInfoItem from './StatusInfoItem'

const useStyles = makeStyles(theme => ({
  root: {
    height: '460px',
    width: '620px'
  }
}))

const StatusInfoModal = ({ statuses = [], isOpen, onSelect, onClose, selectedStatus }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleSelect = useCallback(
    status => {
      onSelect(status)
      onClose()
    },
    [onClose, onSelect]
  )

  return (
    <BaseModal
      className={classes.root}
      open={isOpen}
      title={t('statuses.statusInfoModal.title')}
      withCloseIcon
      handleClose={onClose}
    >
      <Grid container direction="column">
        {statuses.map(status => (
          <Grid item key={status.id}>
            <StatusInfoItem
              status={status}
              isSelected={status.key === selectedStatus?.key}
              onSelect={() => handleSelect(status)}
            />
          </Grid>
        ))}
      </Grid>
    </BaseModal>
  )
}

export default StatusInfoModal
