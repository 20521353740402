import * as React from 'react'
const GifThumbnail = ({ ...props }) => {
  return (
    <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 3.5735C1 2.15219 2.1522 1 3.5735 1H14.9024V8.07713H21.6579V22.8748C21.6579 24.2961 20.5057 25.4483 19.0844 25.4483H3.5735C2.1522 25.4483 1 24.2961 1 22.8748V3.5735Z"
        stroke="#4151E6"
        strokeLinejoin="round"
      />
      <path d="M21.6579 8.07713L14.9025 1V8.07713H21.6579Z" stroke="#4151E6" strokeLinejoin="round" />
      <path
        d="M10.8003 14.875C10.8633 16.534 9.9953 17.584 8.4343 17.584C6.9363 17.584 5.8443 16.45 5.8443 14.875C5.8443 13.307 6.9293 12.18 8.4273 12.18C9.5193 12.18 10.4363 12.866 10.6323 13.93L9.7433 14.098C9.5963 13.447 9.0713 12.985 8.3713 12.985C7.4333 12.985 6.7473 13.762 6.7473 14.875C6.7473 15.995 7.4403 16.772 8.4343 16.772C9.2393 16.772 9.7433 16.331 9.8553 15.61H8.7633V14.875H10.8003ZM12.5971 12.264V17.5H11.7221V12.264H12.5971ZM17.1052 12.264V13.041H14.6342V14.427H17.0842V15.211H14.6342V17.5H13.7592V12.264H17.1052Z"
        fill="#4151E6"
      />
    </svg>
  )
}

export default GifThumbnail
