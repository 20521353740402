import * as React from 'react'

function InfoIcon({ isOpen, color = '#3C52EB', ...props }) {
  return isOpen ? (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.38891 11L4.80891 6.34L1.22891 11H0.0889063L4.22891 5.62L0.308906 0.56H1.44891L4.80891 4.9L8.16891 0.56H9.28891L5.36891 5.62L9.56891 11H8.38891Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg width="6" height="14" viewBox="0 0 6 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.07422 1.39258C2.07422 1.11263 2.17188 0.871745 2.36719 0.669922C2.5625 0.468099 2.80339 0.367188 3.08984 0.367188C3.36979 0.367188 3.60742 0.468099 3.80273 0.669922C4.00456 0.865234 4.10547 1.10612 4.10547 1.39258C4.10547 1.67253 4.00456 1.91341 3.80273 2.11523C3.60742 2.31055 3.36979 2.4082 3.08984 2.4082C2.80339 2.4082 2.5625 2.31055 2.36719 2.11523C2.17188 1.91341 2.07422 1.67253 2.07422 1.39258ZM0.824219 13.7266C1.52083 13.6615 1.96029 13.5443 2.14258 13.375C2.32487 13.1992 2.41602 12.7305 2.41602 11.9688V7.31055C2.41602 6.88737 2.38672 6.5944 2.32812 6.43164C2.23047 6.16471 2.02539 6.03125 1.71289 6.03125C1.64128 6.03125 1.56966 6.03776 1.49805 6.05078C1.43294 6.0638 1.23763 6.11589 0.912109 6.20703V5.9043L1.33203 5.76758C2.47135 5.39648 3.26562 5.11654 3.71484 4.92773C3.89714 4.84961 4.01432 4.81055 4.06641 4.81055C4.07943 4.85612 4.08594 4.90495 4.08594 4.95703V11.9688C4.08594 12.7109 4.17383 13.1764 4.34961 13.3652C4.5319 13.554 4.9388 13.6745 5.57031 13.7266V14H0.824219V13.7266Z"
        fill={color}
      />
    </svg>
  )
}

export default InfoIcon
