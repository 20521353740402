import * as React from 'react'

const Message = ({ color = '#AFAFAF', width = 12, height = 10, ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 6V20L7.2 16.8H18C19.1046 16.8 20 15.9046 20 14.8V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6Z"
        fill="#041333"
      />
    </svg>
  )
}

export default Message
