import { ButtonBase, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Send from 'components/common/icons/Send'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ScheduleMessagePopup from '../ScheduledMessages/ScheduleMessagePopup'
import SendOptionsMenu from './SendOptionsMenu'
import ScheduleSendIcon from 'components/common/icons/ScheduleSend'
import { trackEvent } from 'utils/analyticsUtils'
import { isMobile } from 'utils/mobileUtils'
import { SendMobile } from 'components/common/icons'

const useStyles = ({ withOptions }) =>
  makeStyles(theme => ({
    root: {
      backgroundColor: 'var(--bg-color-43)',
      borderRadius: 70
    },
    labelContainer: {
      paddingLeft: 5
    },
    label: {
      color: 'var(--text-color-1)'
    },
    buttonRoot: {
      color: 'var(--text-color-1)',
      padding: '12px 0',
      height: '100%',
      overflow: 'hidden'
    },
    buttonRight: {
      paddingRight: ({ withOptions }) => (withOptions ? '8px' : '16px'),
      paddingLeft: '12px'
    },
    buttonMobile: {
      padding: '15px 16px 15px 17px'
    }
  }))({ withOptions })

const SendButton = ({ disabled = false, onSend = () => {} }) => {
  const { t } = useTranslation()

  const isBroadcastMode = useSelector(state => state.chatReducer.broadcast.isModeOn)

  const [isScheduledMessagePopupOpen, setIsScheduledMessagePopupOpen] = useState(false)

  const handleCloseScheduleMessagePopup = useCallback(() => {
    setIsScheduledMessagePopupOpen(false)
    trackEvent('Scheduled Messages - popup closed')
  }, [])

  const handleOpenScheduleMessagePopup = useCallback(() => {
    setIsScheduledMessagePopupOpen(true)
    trackEvent('Scheduled Messages - popup opened')
  }, [])

  const userSendOptions = useMemo(
    () =>
      [
        {
          type: 'scheduleSend',
          label: t('pages.patients.selectedPatient.chat.sendOptions.scheduleSend'),
          icon: <ScheduleSendIcon />,
          condition: () => !isBroadcastMode && !isMobile(),
          action: handleOpenScheduleMessagePopup
        }
      ].filter(option => option.condition()),
    [t, isBroadcastMode, handleOpenScheduleMessagePopup]
  )

  const classes = useStyles({ withOptions: userSendOptions.length > 0 })

  return (
    <>
      <Grid container alignItems="stretch" className={classes.root}>
        <Grid item>
          <ButtonBase
            disabled={disabled}
            id="patient-room-chat-send-message-button"
            className={isMobile() ? classes.buttonMobile : [classes.buttonRoot, classes.buttonRight].join(' ')}
            onClick={onSend}
          >
            <Grid container alignItems="center">
              <Grid item>{isMobile() ? <SendMobile /> : <Send />}</Grid>
              {!isMobile() && (
                <Grid item className={classes.labelContainer}>
                  <DazzedParagraph14 className={classes.label}>
                    {t('pages.patients.selectedPatient.chat.sendButtonLabel')}
                  </DazzedParagraph14>
                </Grid>
              )}
            </Grid>
          </ButtonBase>
        </Grid>
        {userSendOptions.length > 0 && (
          <Grid item>
            <SendOptionsMenu disabled={disabled} options={userSendOptions} />
          </Grid>
        )}
      </Grid>
      <ScheduleMessagePopup
        onSend={onSend}
        onClose={handleCloseScheduleMessagePopup}
        isOpen={isScheduledMessagePopupOpen}
      />
    </>
  )
}

export default SendButton
