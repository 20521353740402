import React from 'react'
import DazzedParagraph12 from '../../common/text/DazzedParagraph12'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(isNewPricing => ({
  pricingPerPatientRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  period: {
    display: 'inline-block',
    fontFamily: 'Arial',
    fontWeight: 600,
    fontSize: 20
  },
  planFeeDescription: {
    display: 'flex',
    marginLeft: 4,
    marginBottom: 15
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  line: {
    backgroundColor: '#E5E5E5',
    height: 1.5,
    marginBottom: 10,
    alignSelf: 'flex-end',
    display: 'flex'
  }
}))

const SubscriptionPricingRows = ({ currentPricePerPatient, price, grinPlan }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <>
      <div>
        <div className={classes.pricingPerPatientRow}>
          <DazzedHeading20>
            {t('pages.accountSettings.billing.perPatientTitle', {
              price: currentPricePerPatient
            })}
          </DazzedHeading20>
          <DazzedParagraph14 className={classes.capitalize}>
            &nbsp;&nbsp;({t(`grinPlans.period.monthly`)})
          </DazzedParagraph14>
        </div>
        <div className={classes.line} />
      </div>
      <>
        <div className={classes.pricingPerPatientRow}>
          <DazzedHeading20>{t('pages.accountSettings.billing.standardFee', { price })}</DazzedHeading20>
          <DazzedParagraph14 className={classes.capitalize}>
            &nbsp;&nbsp;({t(`grinPlans.period.${grinPlan.period}`)})
          </DazzedParagraph14>
        </div>
        <div className={classes.planFeeDescription}>
          <DazzedParagraph12>
            {grinPlan.period === 'annually'
              ? t('pages.accountSettings.billing.paidOncePerYear')
              : t('pages.accountSettings.billing.paidOncePerMonth')}
          </DazzedParagraph12>
        </div>
      </>
    </>
  )
}

export default SubscriptionPricingRows
