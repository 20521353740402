import React, { useState, useRef } from 'react'
import {
  makeStyles,
  ButtonBase,
  Grid,
  TextField,
  InputAdornment,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popper,
  Grow,
  ClickAwayListener,
  CircularProgress
} from '@material-ui/core'
import Actions from '../../actions'
import SearchIcon from '../../components/common/icons/Search'
import CloseIcon from '../../components/common/icons/Close'
import { useDispatch, useSelector } from 'react-redux'
import ProfilePicture from '../common/ProfilePicture'

const useSearchStyles = makeStyles({
  container: {
    width: 300,
    marginTop: 20,
    marginBottom: 20
  },
  input: {
    borderWidth: '1px !important',
    borderRadius: 28,
    boxShadow: '0px 2px 10px rgb(0 0 0 / 10%)',
    transition: '0.3s'
  },
  inputRoot: {
    '& input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 50px var(--bg-color-10) inset' /* Fix autofill background color */,
      '-webkit-text-fill-color': 'var(--text-color-7)'
    },

    '& input:-webkit-autofill:focus': {
      '-webkit-box-shadow': '0 0 0 50px var(--bg-color-10) inset' /* Fix autofill background color */,
      '-webkit-text-fill-color': 'var(--text-color-7)'
    }
  }
})

const SearchByDoctor = ({ handleDoctorChange }) => {
  const classes = useSearchStyles()
  const wrapperRef = useRef(null)
  const [doctorFilter, setDoctorFilter] = useState('')
  const [showList, setShowList] = useState(false)
  const anchorRef = React.useRef(null)
  const doctorsResults = useSelector(state => state.appReducer.doctorSearchResults)
  const isLoadingDoctors = useSelector(state => state.appReducer.doctorSearchIsLoading)
  const dispatch = useDispatch()

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setShowList(false)
  }

  return (
    <Grid container className={classes.container} ref={wrapperRef}>
      <Grid item xs={12}>
        <TextField
          style={{ width: '100%' }}
          id="input-with-icon-textfield"
          placeholder="Filter by doctor name..."
          variant="outlined"
          value={doctorFilter}
          ref={anchorRef}
          autoComplete="false"
          onChange={({ target, currentTarget }) => {
            setDoctorFilter(target.value)
            if (target.value && target.value.length) {
              dispatch(Actions.requestSearchDoctor(target.value))
              setShowList(true)
            } else {
              setShowList(false)
              handleDoctorChange(null)
            }
          }}
          InputProps={{
            classes: { notchedOutline: classes.input, root: classes.inputRoot },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <ButtonBase
                  onClick={() => {
                    setDoctorFilter('')
                    handleDoctorChange('')
                    dispatch(Actions.requestSearchDoctor(''))
                  }}
                >
                  <CloseIcon color={'black'} />
                </ButtonBase>
              </InputAdornment>
            )
          }}
        />
      </Grid>
      {showList && doctorFilter && (
        <Grid item xs={12}>
          <Popper open={showList} anchorEl={anchorRef.current} transition style={{ width: 300 }}>
            {({ TransitionProps, placement }) => {
              return (
                <Grow
                  {...TransitionProps}
                  style={{ width: '100%', transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <List id="menu-list-grow">
                        {!isLoadingDoctors ? (
                          doctorsResults && doctorsResults.length ? (
                            doctorsResults.map((doctor, index) => {
                              return (
                                <ListItem
                                  button
                                  onClick={event => {
                                    setShowList(false)
                                    setDoctorFilter(doctor.name)
                                    handleDoctorChange(doctor.id)
                                    handleClose(event)
                                  }}
                                  key={index}
                                >
                                  <ListItemAvatar>
                                    <ProfilePicture photo={doctor.photo} name={doctor.name} size="small" />
                                  </ListItemAvatar>
                                  <ListItemText primary={doctor.name} secondary={doctor.email} />
                                </ListItem>
                              )
                            })
                          ) : (
                            <ListItem alignItems="center">
                              <ListItemText primary={'No doctors found'} />
                            </ListItem>
                          )
                        ) : (
                          <ListItem alignItems="center">
                            <CircularProgress />
                          </ListItem>
                        )}
                      </List>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )
            }}
          </Popper>
        </Grid>
      )}
    </Grid>
  )
}

export default SearchByDoctor
