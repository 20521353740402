import * as React from 'react'

function SvgAdd(props) {
  return (
    <svg
      width={props.width ? props.width : '24'}
      height={props.height ? props.height : '24'}
      viewBox="0 0 24 24"
      fill={props.backColor ? props.backColor : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule={props.backColor ? props.backColor : 'evenodd'}
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill={props.backColor ? props.backColor : '#727B8C'}
      />
      <path
        d="M11.5 12.5V16H12.5V12.5H16V11.5L12.5 11.5V8H11.5V11.5H8V12.5H11.5Z"
        fill={props.plusColor ? props.plusColor : '#727B8C'}
      />
    </svg>
  )
}

export default SvgAdd
