import Actions from '../actions'
import { mapRecords, mapInitialScanPhotos, filterFailedRecords } from '../utils/mappers/initialScansMappers'
import { removePatientTagFromPatient, upsertPatientTag } from '../utils/tagUtils'
import { replaceItem, upsertItem, updateWhere, removeWhere } from '../utils/arrayUtils'
import { isEmpty } from 'lodash'
import { AsyncStatus } from 'consts'
import { CycleStatuses } from 'consts/treatmentConsts'

const initialState = {
  requestedPatientId: null,
  patient: {},
  fetchedScansLeadId: null,
  isLoadingPatient: false,
  patients: [],
  search: {
    isLoading: false,
    results: []
  },
  invite: {
    isLoading: false,
    isModalOpen: false,
    isResendMode: false,
    resendToPatient: null,
    mobilePopup: {
      isOpen: false,
      type: 'success',
      patientName: ''
    },
    presetDetails: {},
    resend: {
      siblings: [],
      status: null
    }
  },
  invitationCodeModal: {
    isOpen: false,
    invitationCode: null,
    isOldInvitation: false
  },
  delete: {
    isModalOpen: false
  },
  overlayLoader: false,
  patientCard: {
    isModalOpen: false,
    isLoading: false,
    selectedTab: 0,
    patientId: null,
    patient: null,
    isLoadingDetails: false,
    prelimPlan: {
      data: null,
      isLoading: false
    },
    treatmentPlan: {
      data: null,
      isLoading: false
    },
    treatment: {
      data: null,
      isLoading: false,
      isUpdating: false
    },
    notes: {
      isLoading: false,
      isCreating: false,
      isDeleting: false,
      data: null
    },
    scans: {
      data: null,
      selectedRecord: null,
      isUploading: false,
      isLoading: false
    },
    appointments: {
      data: null,
      isLoading: false
    },
    siblings: {
      status: null,
      list: []
    }
  },
  mobilePatientCard: {
    isModalOpen: false
  },
  shouldOpenScanReview: false,
  transferPatient: {
    isModalOpen: false,
    isLoading: false
  },
  uploadStlFilesModal: {
    isModalOpen: false
  },
  downloadPatientFiles: {
    isLoading: false
  },
  patientsByTag: {
    isLoading: false,
    count: null
  },
  siblingSearch: {
    isLoading: false,
    results: []
  },
  bulkInviteForm: {
    isOpen: false,
    status: null,
    errors: {}
  },
  presetFilter: {
    name: ''
  },
  patientBriefModal: {
    isOpen: false,
    saveStatus: null,
    patientId: null,
    patientName: null,
    doctorId: null,
    a_doctor: null,
    treatmentType: null,
    originalPatientBrief: null,
    analyticsSource: null
  },
  patientBrief: {
    data: null,
    nextToken: null,
    loadingStatus: false
  },
  notes: {
    status: null,
    data: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_REJECTED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.SIGNOUT_RECEIVED:
      return initialState
    case Actions.REQUEST_PATIENT_DETAILS_UPDATE:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          isLoadingDetails: true
        }
      }
    case Actions.UPDATE_PATIENT_DETAILS_RECEIVED:
      return {
        ...state,
        patient: {
          ...state.patient,
          ...action.payload.patient
        },
        patientCard: {
          ...state.patientCard,
          isLoadingDetails: false,
          patient: {
            ...state.patientCard.patient,
            ...action.payload.patient
          }
        }
      }
    case Actions.UPDATE_PATIENT_DETAILS_FAILED:
      return {
        patientCard: {
          ...state.patientCard,
          isLoadingDetails: false
        }
      }
    case Actions.CHAT_ROOM_SELECTED:
      if (!action.payload.previewDetails) {
        return { ...state, patient: {} }
      }

      return {
        ...state,
        patient: {
          details: {
            name: action.payload.previewDetails.name,
            photo: action.payload.previewDetails.photo
          }
        }
      }
    case Actions.PATIENT_REQUESTED:
      return {
        ...state,
        requestedPatientId: action.payload.patientId,
        isLoadingPatient: true,
        fetchedScansLeadId: null,
        patientCard: {
          ...state.patientCard,
          isLoading: true,
          patient: initialState.patientCard.patient,
          prelimPlan: initialState.patientCard.prelimPlan,
          treatmentsPlans: initialState.patientCard.treatmentsPlans,
          treatment: initialState.patientCard.treatment,
          notes: initialState.patientCard.notes,
          scans: initialState.patientCard.scans,
          appointments: initialState.patientCard.appointments,
          siblings: initialState.patientCard.siblings
        },
        patientBrief: initialState.patientBrief,
        notes: initialState.notes
      }
    case Actions.FETCH_PATIENT_FAILED:
      return {
        ...state,
        isLoadingPatient: false
      }
    case Actions.PATIENT_OPENED_UPDATE_RECEIVED:
      return {
        ...state,
        patient: {
          ...state.patient,
          ...action.payload
        },
        patientCard: {
          ...state.patientCard,
          patient: {
            ...state.patientCard.patient,
            ...action.payload
          }
        }
      }
    case Actions.PATIENT_TREATMENT_RECEIVED:
      const patient = {
        ...action.payload.patient,
        patientTags: {
          items: action.payload.patient.patientTags.items.filter(patientTag => !patientTag._deleted)
        }
      }

      return {
        ...state,
        patient,
        patientCard: {
          ...state.patientCard,
          patient,
          treatment: {
            ...state.patientCard.treatment,
            data: action.payload.treatment,
            isLoading: false,
            isUpdating: false
          }
        }
      }
    case Actions.PATIENT_RECEIVED:
      if (!action.payload.patient) {
        return {
          ...state,
          isLoadingPatient: false
        }
      }

      const patientWithFilteredTags = action.payload?.patient
        ? {
            ...action.payload.patient,
            patientTags: {
              items: action.payload.patient.patientTags.items.filter(patientTag => !patientTag._deleted)
            }
          }
        : {}

      return {
        ...state,
        isLoadingPatient: false,
        requestedPatientId: initialState.requestedPatientId,
        patient: patientWithFilteredTags ?? {},
        patientCard: {
          ...state.patientCard,
          patient: patientWithFilteredTags ?? {},
          scans: {
            ...state.patientCard.scans,
            data: mapRecords(action.payload?.patient.initialScans?.items)
          },
          treatment: {
            data: action.payload?.patient.treatments?.items?.[0]
          },
          isLoading: false
        }
      }
    case Actions.SUPPORT_SET_SELECTED_PATIENT: {
      return {
        ...state,
        patient: action.payload?.patient
      }
    }
    case Actions.SUPPORT_FETCH_PATIENT_RECEIVED: {
      return {
        ...state,
        patient: action.payload?.patient
      }
    }
    case Actions.SEARCH_PATIENTS:
      return {
        ...state,
        search: {
          ...state.search,
          results: !action.payload?.filter ? initialState.search.results : state.search.results,
          isLoading: !!action.payload?.filter
        }
      }
    case Actions.SEARCH_PATIENTS_RECEIVED: {
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false,
          results: action.payload
        }
      }
    }
    case Actions.SERACH_PATIENTS_FAILED:
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false
        }
      }
    case Actions.SHOW_RESEND_PATIENT_INVITATION_MODAL:
      return {
        ...state,
        invite: {
          ...state.invite,
          isModalOpen: true,
          isResendMode: true,
          resendToPatient: action.payload
        }
      }
    case Actions.SET_INVITE_PATIENT_MODAL_VISIBILITY:
      return {
        ...state,
        invite: {
          ...state.invite,
          isModalOpen: action.payload,
          isResendMode: false,
          resendToPatient: null
        }
      }
    case Actions.INVITE_NEW_PATIENT:
    case Actions.RESEND_PATIENT_INVITATION_REQUESTED:
      return {
        ...state,
        invite: {
          ...state.invite,
          isLoading: true
        }
      }
    case Actions.RESEND_PATIENT_INVITATION_RECEIVED:
    case Actions.RESEND_PATIENT_INVITATION_FAILED:
      return {
        ...state,
        invite: {
          ...state.invite,
          isLoading: false
        }
      }

    case Actions.TOGGLE_INVITATION_CODE_MODAL: {
      return {
        ...state,
        invitationCodeModal: {
          isOpen: !state.invitationCodeModal.isOpen,
          invitationCode: action.payload.invitationCode,
          siblingEmail: action.payload.siblingEmail,
          isOldInvitation: action.payload.isOldInvitation
        }
      }
    }
    case Actions.INVITE_NEW_PATIENT_FAILED:
      return {
        ...state,
        invite: {
          ...state.invite,
          isLoading: false,
          mobilePopup: action.payload.mobilePopup
            ? {
                isOpen: true,
                patientName: action.payload.patientName,
                type: 'error'
              }
            : {
                ...state.invite.mobilePopup,
                isOpen: false
              }
        }
      }
    case Actions.INVITE_NEW_PATIENT_RECEIVED:
      return {
        ...state,
        invite: {
          ...state.invite,
          isLoading: false,
          mobilePopup: action.payload.mobilePopup
            ? {
                isOpen: true,
                type: 'success',
                patientName: action.payload.patientName
              }
            : {
                ...state.invite.mobilePopup,
                isOpen: false
              }
        }
      }
    case Actions.CLEAR_INVITE_MODAL_POPUP_PROPS:
      return {
        ...state,
        invite: {
          ...state.invite,
          mobilePopup: {
            ...state.invite.mobilePopup,
            isOpen: false,
            patientName: ''
          }
        }
      }
    case Actions.DELETE_PATIENT_INITIATED:
      return {
        ...state,
        delete: {
          ...state.invite,
          isModalOpen: true,
          patient: action.payload
        }
      }
    case Actions.DELETE_PATIENT_CANCELED:
    case Actions.DELETE_PATIENT_REQUESTED:
      return {
        ...state,
        delete: {
          ...state.delete,
          isModalOpen: false
        }
      }
    case Actions.UPLOAD_STL_FILES_RECEIVED:
      if (action.payload.initialScan.patientId === state.patient.id) {
        return {
          ...state,
          patient: {
            ...state.patient,
            initialScans: {
              ...state.patient.initialScans,
              items: [...state.patient.initialScans.items, action.payload.initialScan]
            }
          },
          patientCard: {
            ...state.patientCard,
            scans: {
              ...state.patientCard.scans,
              isUploading: false,
              selectedRecord: mapInitialScanPhotos(action.payload.initialScan),
              data: mapRecords(state.patientCard.scans.data, action.payload.initialScan)
            },
            isLoading: false
          }
        }
      }
      return state
    case Actions.SET_PATIENTS_FILTER_BY_NAME:
      return {
        ...state,
        patientsPage: 0,
        patientsFilters: {
          ...state.patientsFilters,
          byName: action.payload
        }
      }
    case Actions.SET_PATIENTS_FILTER_BY_PROGRAM:
      return {
        ...state,
        patientsPage: 0,
        patientsFilters: {
          ...state.patientsFilters,
          byProgram: action.payload
        }
      }
    case Actions.SET_PATIENTS_FILTER_BY_ACTION:
      return {
        ...state,
        patientsPage: 0,
        patientsFilters: {
          ...state.patientsFilters,
          byAction: action.payload
        }
      }
    case Actions.TOGGLE_PATIENT_CARD:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          scans: {
            ...state.patientCard.scans,
            selectedRecord: null
          },
          appointments: action.payload.isModalOpen
            ? state.patientCard.appointments
            : initialState.patientCard.appointments,
          ...action.payload
        }
      }
    case Actions.TOGGLE_MOBILE_PATIENT_CARD:
      return {
        ...state,
        mobilePatientCard: {
          ...state.mobilePatientCard,
          ...action.payload
        }
      }
    case Actions.SAVE_PATIENT_PRELIM_PROPOSAL:
    case Actions.APPROVE_PATIENT_PRELIM_PROPOSAL:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          prelimPlan: {
            ...state.patientCard.prelimPlan,
            isLoading: true
          }
        }
      }
    case Actions.PATIENT_PRELIM_PROPOSAL_SAVED:
    case Actions.PATIENT_PRELIM_PROPOSAL_APPROVED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          prelimPlan: {
            isLoading: false,
            data: action.payload.proposal
          }
        }
      }
    case Actions.SAVE_PATIENT_PRELIM_PROPOSAL_FAILED:
    case Actions.APPROVE_PATIENT_PRELIM_PROPOSAL_FAILED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          prelimPlan: {
            isLoading: false
          }
        }
      }
    case Actions.REQUEST_PATIENT_TREATMENT:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          treatment: {
            ...state.patientCard.treatment,
            isLoading: true,
            isUpdating: false
          }
        }
      }
    case Actions.UPDATE_TREATMENT:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          treatment: {
            ...state.patientCard.treatment,
            isUpdating: true
          }
        }
      }
    case Actions.UPDATE_TREATMENT_FAILED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          treatment: {
            ...state.patientCard.treatment,
            isUpdating: false
          }
        }
      }
    case Actions.UPDATE_TREATMENT_RECEIVED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          treatment: {
            ...state.patientCard.treatment,
            data: action.payload.treatment,
            isUpdating: false
          }
        },
        patient: {
          ...state.patient,
          cycles: {
            ...state.cycles,
            items: action.payload.cycle
              ? replaceItem(state.patient.cycles.items, action.payload.cycle)
              : state.patient.cycles.items
          },
          treatments: {
            items: replaceItem(state.patient.treatments.items, action.payload.treatment)
          }
        }
      }
    case Actions.REQUEST_PATIENT_NOTES:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          notes: {
            ...state.patientCard.notes,
            isLoading: true
          }
        }
      }
    case Actions.PATIENT_NOTES_RECEIVED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          notes: {
            ...state.patientCard.notes,
            data: action.payload,
            isLoading: false
          }
        }
      }
    case Actions.CREATE_PATIENT_NOTE:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          notes: {
            ...state.patientCard.notes,
            isCreating: true
          }
        }
      }
    case Actions.CREATE_PATIENT_NOTE_RECEIVED:
      return state.patientCard?.patient?.user?.id === action.payload.grinUserId
        ? {
            ...state,
            patientCard: {
              ...state.patientCard,
              notes: {
                ...state.patientCard.notes,
                data: [...(state.patientCard.notes.data || []), action.payload],
                isCreating: false
              }
            }
          }
        : { ...state }
    case Actions.REQUEST_PATIENT_NOTES_FAILED:
    case Actions.CREATE_PATIENT_NOTE_FAILED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          notes: {
            ...state.patientCard.notes,
            isLoading: false,
            isCreating: false
          }
        }
      }
    case Actions.UPLOAD_INITIAL_STL_FILES:
    case Actions.UPLOAD_STL_FILES:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          scans: {
            ...state.patientCard.scans,
            selectedRecord: {
              ...state.patientCard.scans.selectedRecord,
              stls: [
                ...(state.patientCard.scans.selectedRecord.stls || []),
                ...action.payload.files.map(item => ({
                  url: null,
                  isLoading: true
                }))
              ]
            },
            isUploading: true
          }
        }
      }
    case Actions.UPLOAD_STL_FILES_FAILED: {
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          scans: {
            ...state.patientCard.scans,
            selectedRecord: {
              ...state.patientCard.scans.selectedRecord,
              stls: filterFailedRecords(state.patientCard.scans.selectedRecord.stls)
            },
            isUploading: true
          }
        }
      }
    }
    case Actions.START_UPLOADING_PATIENT_INITIAL_SCAN_FILES:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          scans: {
            ...state.patientCard.scans,
            selectedRecord: {
              ...state.patientCard.scans.selectedRecord,
              ...action.payload
            },
            isUploading: true
          }
        }
      }
    case Actions.SAVE_PATIENT_INITIAL_SCAN_RECEIVED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          scans: {
            ...state.patientCard.scans,
            selectedRecord: mapInitialScanPhotos(action.payload),
            data: mapRecords(state.patientCard.scans.data, action.payload),
            isUploading: false
          }
        }
      }
    case Actions.SAVE_PATIENT_INITIAL_SCAN_FAILED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          scans: {
            ...state.patientCard.scans,
            selectedRecord: {
              ...state.patientCard.scans.selectedRecord,
              oralImages: filterFailedRecords(state.patientCard.scans.selectedRecord.oralImages),
              panoramics: filterFailedRecords(state.patientCard.scans.selectedRecord.panoramics)
            },
            isUploading: false
          }
        }
      }
    case Actions.ASSIGN_PATIENT_TAG:
    case Actions.ASSIGN_PATIENT_TO_PRACTICE_MEMBER:
      return {
        ...state,
        isTagsLoading: true,
        patient: upsertPatientTag(state.patient, action.payload),
        patientCard: {
          ...state.patientCard,
          patient: upsertPatientTag(state.patientCard.patient, action.payload)
        }
      }
    case Actions.REMOVE_PATIENT_TAG:
      return {
        ...state,
        isTagsLoading: true,
        patient: removePatientTagFromPatient(state.patient, action.payload.tag),
        patientCard: {
          ...state.patientCard,
          patient:
            state.patientCard.patient?.id === action.payload.patientId
              ? removePatientTagFromPatient(state.patientCard.patient, action.payload.tag)
              : state.patientCard.patient
        }
      }
    case Actions.UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT: {
      const assigneePatientTag = state?.patient?.patientTags?.items?.find(
        patientTag => patientTag.tag.type === 'assignee'
      )
      if (!assigneePatientTag) {
        return state
      }

      return {
        ...state,
        isTagsLoading: true,
        patient: removePatientTagFromPatient(state.patient, assigneePatientTag.tag.value),
        patientCard: {
          ...state.patientCard,
          patient:
            state.patientCard.patient?.id === action.payload.patientId
              ? removePatientTagFromPatient(state.patientCard.patient, assigneePatientTag.tag.value)
              : state.patientCard.patient
        }
      }
    }
    case Actions.ASSIGN_PATIENT_TAG_RECEIVED:
    case Actions.REMOVE_PATIENT_TAG_RECEIVED:
    case Actions.ASSIGN_PATIENT_TAG_FAILED:
    case Actions.REMOVE_PATIENT_TAG_FAILED:
    case Actions.UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT_RECEIVED:
    case Actions.UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT_FAILED:
      return {
        ...state,
        isTagsLoading: false
      }
    case Actions.SHOULD_OPEN_SCAN_REVIEW:
      return {
        ...state,
        shouldOpenScanReview: action.payload
      }
    case Actions.UPDATE_TREATMENT_STATUS_RECEIVED:
      let updatedPatient = state.patient
      const { totalApplianceNumber, ...newCurrentStatus } = action.payload
      if (updatedPatient?.treatments?.items[0]) {
        updatedPatient.treatments.items[0] = {
          ...updatedPatient.treatments.items[0],
          currentStatus: newCurrentStatus,
          totalApplianceNumber
        }
      }
      return {
        ...state,
        patient: updatedPatient
      }
    case Actions.RESET_PATIENT:
      return {
        ...state,
        patient: initialState.patient,
        patientCard: initialState.patientCard
      }
    case Actions.SET_TRANSFER_PATIENT_MODAL_VISIBILITY:
      return {
        ...state,
        transferPatient: {
          ...state.transferPatient,
          isModalOpen: action.payload
        }
      }
    case Actions.TRANSFER_PATIENT:
      return {
        ...state,
        transferPatient: {
          ...state.transferPatient,
          isLoading: true
        }
      }
    case Actions.TRANSFER_PATIENT_RECEIVED:
      return {
        ...state,
        transferPatient: {
          ...state.transferPatient,
          isLoading: false,
          isModalOpen: false
        }
      }
    case Actions.TRANSFER_PATIENT_FAILED:
      return {
        ...state,
        transferPatient: {
          ...state.transferPatient,
          isLoading: false
        }
      }
    case Actions.LINK_CLOUD_9_PATIENT_SUCCESS:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          patient: {
            ...state.patientCard.patient,
            user: {
              ...state.patientCard.patient.user,
              ...action.payload
            }
          }
        }
      }
    case Actions.REQUEST_PATIENT_APPOINTMENTS:
    case Actions.CREATE_PATIENT_APPOINTMENT:
    case Actions.UPDATE_PATIENT_APPOINTMENT:
    case Actions.DELETE_PATIENT_APPOINTMENT:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          appointments: {
            ...state.patientCard.appointments,
            isLoading: true
          }
        }
      }
    case Actions.PATIENT_APPOINTMENTS_RECEIVED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          appointments: {
            ...state.patientCard.appointments,
            data: action.payload,
            isLoading: false
          }
        }
      }
    case Actions.CREATE_PATIENT_APPOINTMENT_RECEIVED:
      const newAppointmentsArray = [...(state.patientCard.appointments.data || []), action.payload]
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          appointments: {
            ...state.patientCard.appointments,
            data: newAppointmentsArray,
            isLoading: false
          }
        }
      }
    case Actions.UPDATE_PATIENT_APPOINTMENT_RECEIVED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          appointments: {
            ...state.patientCard.appointments,
            data: upsertItem({
              array: state.patientCard.appointments.data,
              item: action.payload,
              mergeOldProperties: true
            }),
            isLoading: false
          }
        }
      }
    case Actions.DELETE_PATIENT_APPOINTMENT_RECEIVED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          appointments: {
            ...state.patientCard.appointments,
            data: removeWhere(state.patientCard.appointments.data, appointment => appointment.id === action.payload.id),
            isLoading: false
          }
        }
      }
    case Actions.PATIENT_APPOINTMENTS_FAILED:
    case Actions.CREATE_PATIENT_APPOINTMENT_FAILED:
    case Actions.UPDATE_PATIENT_APPOINTMENT_FAILED:
    case Actions.DELETE_PATIENT_APPOINTMENT_FAILED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          appointments: {
            ...state.patientCard.appointments,
            isLoading: false
          }
        }
      }
    case Actions.UPDATE_SCAN_FEEDBACK_RECEIVED: {
      return {
        ...state,
        isSubmittingScanFeedback: false,
        patient: isEmpty(state.patient)
          ? state.patient
          : {
              ...state.patient,
              grinScans: {
                ...state.patient.grinScans,
                items: updateWhere(
                  state.patient.grinScans?.items || [],
                  scan => scan.id === action.payload.id,
                  scan => {
                    scan.metadata = action.payload.metadata
                    scan._version = action.payload._version
                  }
                )
              }
            }
      }
    }
    case Actions.TOGGLE_UPLOAD_STL_FILES_MODAL: {
      return {
        ...state,
        uploadStlFilesModal: {
          ...state.uploadStlFilesModal,
          ...action.payload
        }
      }
    }
    case Actions.SAVE_TREATMENT_PLAN:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          treatmentPlan: {
            ...state.treatmentPlan,
            isLoading: true
          }
        }
      }
    case Actions.TREATMENT_PLAN_SAVED:
    case Actions.TREATMENT_PLAN_FAILED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          treatmentPlan: {
            ...state.treatmentPlan,
            isLoading: false
          }
        }
      }
    case Actions.SET_SELECTED_RECORD:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          scans: {
            ...state.patientCard.scans,
            selectedRecord: action.payload
          }
        }
      }
    case Actions.DELETE_RECORDS_SET_RECEIVED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          scans: {
            ...state.patientCard.scans,
            data: state.patientCard.scans.data.filter(record => record.id !== action.payload.id),
            selectedRecord: null
          }
        }
      }
    case Actions.DOWNLOAD_PATIENT_FILES_ZIP:
      return {
        ...state,
        downloadPatientFiles: {
          isLoading: true
        }
      }
    case Actions.DOWNLOAD_PATIENT_FILES_ZIP_SUCCESS:
    case Actions.DOWNLOAD_PATIENT_FILES_ZIP_FAILED:
      return {
        ...state,
        downloadPatientFiles: {
          isLoading: false
        }
      }
    case Actions.DELETE_PATIENT_NOTE:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          notes: {
            ...state.patientCard.notes,
            isDeleting: true
          }
        }
      }
    case Actions.DELETE_PATIENT_NOTE_RECEIVED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          notes: {
            ...state.patientCard.notes,
            data: removeWhere(state.patientCard.notes.data, note => note.id === action.payload.id),
            isDeleting: false
          }
        }
      }
    case Actions.DELETE_PATIENT_NOTE_FAILED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          notes: {
            ...state.patientCard.notes,
            isDeleting: false
          }
        }
      }
    case Actions.EDIT_PATIENT_NOTE_RECEIVED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          notes: {
            ...state.patientCard.notes,
            data: replaceItem(state.patientCard.notes.data, action.payload)
          }
        }
      }
    case Actions.SET_ASSIGNEE_SEEN_TAG: {
      return {
        ...state,
        patient: {
          ...state.patient,
          patientTags: {
            ...state.patient.patientTags,
            items: upsertItem({
              array: state.patient.patientTags.items,
              item: {
                ...action.payload.patientTag,
                metadata: JSON.stringify({
                  ...JSON.parse(action.payload.patientTag.metadata || '{}'),
                  unreadIndication: false
                })
              },
              mergeOldProperties: true
            })
          }
        },
        patientCard: {
          ...state.patientCard,
          patient:
            state.patientCard.patient?.id === action.payload.patientId
              ? {
                  ...state.patientCard.patient,
                  patientTags: {
                    ...state.patientCard.patient.patientTags,
                    items: upsertItem({
                      array: state.patient.patientCard.patientTags.items,
                      item: {
                        ...action.payload.patientTag,
                        metadata: JSON.stringify({
                          ...JSON.parse(action.payload.patientTag.metadata || '{}'),
                          unreadIndication: false
                        })
                      },
                      mergeOldProperties: true
                    })
                  }
                }
              : state.patientCard.patient
        }
      }
    }
    case Actions.SET_ASSIGNEE_SEEN_TAG_SUCCEDED:
      return {
        ...state,
        patient: {
          ...state.patient,
          patientTags: {
            ...state.patient.patientTags,
            items: upsertItem({
              array: state.patient.patientTags.items,
              item: action.payload,
              mergeOldProperties: true,
              condition: item => JSON.parse(item.metadata || '{}').assigneeId && action.payload.assigneeId
            })
          }
        },
        patientCard: {
          ...state.patientCard,
          patient:
            state.patientCard.patient?.id === action.payload.patientTagPatientId
              ? {
                  ...state.patientCard.patient,
                  patientTags: {
                    ...state.patientCard.patient.patientTags,
                    items: upsertItem({
                      array: state.patientCard.patient.patientTags.items,
                      item: action.payload,
                      mergeOldProperties: true
                    }),
                    condition: item => JSON.parse(item.metadata || '{}').assigneeId && action.payload.assigneeId
                  }
                }
              : state.patientCard.patient
        }
      }
    case Actions.FETCH_PATIENTS_WITH_TAG:
      return {
        ...state,
        patientsByTag: {
          ...state.patientsByTag,
          isLoading: true
        }
      }
    case Actions.FETCH_PATIENTS_WITH_TAG_RECEIVED:
      return {
        ...state,
        patientsByTag: {
          ...state.patientsByTag,
          isLoading: false,
          count: action.payload
        }
      }
    case Actions.FETCH_PATIENTS_WITH_TAG_FAILED:
      return {
        ...state,
        patientsByTag: {
          ...state.patientsByTag,
          isLoading: false,
          count: initialState.patientsByTag.count
        }
      }
    case Actions.SEARCH_SIBLINGS_PATIENTS:
      return {
        ...state,
        siblingSearch: {
          ...state.siblingSearch,
          results: initialState.siblingSearch.results,
          isLoading: action.payload !== '',
          error: null
        }
      }
    case Actions.SEARCH_SIBLINGS_PATIENTS_RECEIVED: {
      return {
        ...state,
        siblingSearch: {
          ...state.siblingSearch,
          isLoading: false,
          results: action.payload
        }
      }
    }
    case Actions.SEARCH_SIBLINGS_PATIENTS_FAILED:
      return {
        ...state,
        siblingSearch: {
          ...state.siblingSearch,
          isLoading: false,
          error: action.payload
        }
      }
    case Actions.SEARCH_GUARDIAN:
      return {
        ...state,
        siblingSearch: {
          ...state.siblingSearch,
          results: initialState.siblingSearch.results,
          isLoading: action.payload !== '',
          searchTerm: action.payload
        }
      }
    case Actions.SEARCH_GUARDIAN_RECEIVED:
      return {
        ...state,
        siblingSearch: {
          ...state.siblingSearch,
          isLoading: false,
          results: action.payload,
          searchTerm: action.payload?.length > 0 ? state.siblingSearch.searchTerm : ''
        }
      }
    case Actions.SEARCH_GUARDIAN_FAILED:
      return {
        ...state,
        siblingSearch: {
          ...state.siblingSearch,
          searchTerm: '',
          results: initialState.siblingSearch.results,
          isLoading: false
        }
      }
    case Actions.FETCH_LEAD_SCANS: {
      return {
        ...state,
        fetchedScansLeadId: action.payload.leadId
      }
    }
    case Actions.FETCH_LEAD_SCANS_RECEIVED: {
      return {
        ...state,
        patient: {
          ...state.patient,
          grinScans: {
            items: action.payload
          }
        },
        patientCard: {
          ...state.patientCard,
          patient: {
            ...state.patientCard.patient,
            grinScans: {
              ...state.patientCard.patient?.grinScans,
              items: action.payload
            }
          }
        }
      }
    }
    case Actions.FETCH_LEAD_SCANS_FAILED: {
      return {
        ...state,
        patient: {
          ...state.patient,
          grinScans: {
            items: []
          }
        }
      }
    }
    case Actions.TOGGLE_BULK_INVITE_POPUP: {
      return {
        ...state,
        bulkInviteForm: {
          ...state.bulkInviteForm,
          isOpen: action.payload.isOpen,
          status: initialState.bulkInviteForm.status,
          errors: initialState.bulkInviteForm.errors
        }
      }
    }
    case Actions.UPLOAD_BULK_INVITE_FORM: {
      return {
        ...state,
        bulkInviteForm: {
          ...state.bulkInviteForm,
          status: 'uploading',
          errors: initialState.bulkInviteForm.errors
        }
      }
    }
    case Actions.UPLOAD_BULK_INVITE_FORM_RECEIVED: {
      return {
        ...state,
        bulkInviteForm: {
          ...state.bulkInviteForm,
          status: 'successful'
        }
      }
    }
    case Actions.UPLOAD_BULK_INVITE_FORM_FAILED: {
      return {
        ...state,
        bulkInviteForm: {
          ...state.bulkInviteForm,
          isOpen: action.payload.statusCode === 422,
          status: 'fail',
          errors: action.payload.errors || initialState.bulkInviteForm.errors
        }
      }
    }
    case Actions.PMS_CREATE_NEW_APPOINTMENT_RECEIVED:
      const { appointment } = action.payload

      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          appointments: {
            ...state.patientCard.appointments,
            data: [...(state.patientCard.appointments?.data || []), appointment]
          }
        }
      }
    case Actions.PMS_DELETE_APPOINTMENT:
      const appointmentId = action.payload

      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          appointments: {
            ...state.patientCard.appointments,
            data: state.patientCard.appointments?.data.filter(apt => apt.id !== appointmentId)
          }
        }
      }
    case Actions.PMS_LINK_PATIENT: {
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          appointments: initialState.patientCard.appointments,
          patient: {
            ...state.patientCard.patient,
            user: {
              ...state.patientCard.patient.user,
              externalIds: JSON.stringify({
                ...JSON.parse(state.patientCard.patient.user.externalIds || '{}'),
                [action.payload.pmsType]: action.payload.pmsPatientId
              })
            }
          }
        }
      }
    }
    case Actions.PMS_UNLINK_PATIENT: {
      let patientExternalIds = JSON.parse(state.patientCard.patient.user.externalIds || '{}')
      delete patientExternalIds[action.payload.pmsType]

      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          appointments: initialState.patientCard.appointments,
          patient: {
            ...state.patientCard.patient,
            user: {
              ...state.patientCard.patient.user,
              externalIds: JSON.stringify(patientExternalIds)
            }
          }
        }
      }
    }
    case Actions.PMS_FETCH_PATIENT_RECEIVED:
    case Actions.PMS_FETCH_APPOINTMENTS_RECEIVED:
      const { appointments } = action.payload

      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          appointments: {
            ...state.patientCard.appointments,
            data: appointments
          }
        }
      }
    case Actions.PMS_FETCH_APPOINTMENTS_FAILED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          appointments: {
            ...state.patientCard.appointments,
            data: [...(state.patientCard.appointments?.data || []), []]
          }
        }
      }
    case Actions.RESOLVE_SCAN_URGENCY_RECEIVED:
      const { removedTag } = action.payload

      return {
        ...state,
        patient: removedTag ? removePatientTagFromPatient(state.patient, 'urgent') : state.patient,
        patientCard: {
          ...state.patientCard,
          patient: removedTag
            ? state.patientCard.patient?.id === action.payload.patientId
              ? removePatientTagFromPatient(state.patientCard.patient)
              : state.patientCard.patient
            : state.patientCard.patient
        }
      }
    case Actions.FETCH_PATIENT_SIBLINGS:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          siblings: {
            ...state.patientCard.siblings,
            status: AsyncStatus.Loading
          }
        }
      }
    case Actions.FETCH_PATIENT_SIBLINGS_FAILED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          siblings: {
            ...state.patientCard.siblings,
            status: AsyncStatus.Failed
          }
        }
      }
    case Actions.FETCH_PATIENT_SIBLINGS_RECEIVED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          siblings: {
            ...state.patientCard.siblings,
            status: AsyncStatus.Completed,
            list: action.payload
          }
        }
      }
    case Actions.FETCH_PATIENT_SIBLINGS_FOR_RESEND:
      return {
        ...state,
        invite: {
          ...state.invite,
          resend: {
            ...state.invite.resend,
            status: AsyncStatus.Loading
          }
        }
      }
    case Actions.FETCH_PATIENT_SIBLINGS_FOR_RESEND_FAILED:
      return {
        ...state,
        invite: {
          ...state.invite,
          resend: {
            ...state.invite.resend,
            status: AsyncStatus.Failed
          }
        }
      }
    case Actions.FETCH_PATIENT_SIBLINGS_FOR_RESEND_RECEIVED:
      return {
        ...state,
        invite: {
          ...state.invite,
          resend: {
            ...state.invite.resend,
            status: AsyncStatus.Completed,
            siblings: action.payload
          }
        }
      }
    case Actions.SET_CYCLE_STATUS:
      if (action.payload.status !== CycleStatuses.Active || action.payload.patientId !== state.patient?.id) {
        return state
      }

      return {
        ...state,
        patient: {
          ...state.patient,
          patientTags: {
            ...state.patient.patientTags,
            items: removeWhere(
              state.patient.patientTags.items,
              patientTag => patientTag.tag?.type === 'scan' && patientTag.tag?.value === 'paused'
            )
          }
        }
      }
    case Actions.SET_INVITE_PATIENT_PRESET_DETAILS:
      return {
        ...state,
        invite: {
          ...state.invite,
          presetDetails: action.payload
        }
      }
    case Actions.SET_PATIENTS_PRESET_FILTER:
      return {
        ...state,
        presetFilter: {
          ...state.presetFilter,
          name: action.payload.name
        }
      }
    case Actions.REPLACE_ORAL_IMAGE_RECEIVED:
      const { newOralImages, recordSetId } = action.payload

      const updatedInitialScans = mapRecords(
        updateWhere(
          state.patientCard.scans.data,
          initialScan => initialScan.id === recordSetId,
          initialScan => {
            initialScan.oralImages = newOralImages
          }
        )
      )

      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          scans: {
            ...state.patientCard.scans,
            data: updatedInitialScans,
            selectedRecord: updatedInitialScans.find(recordSet => recordSet.id === recordSetId)
          }
        }
      }
    case Actions.SHARE_SCAN_TO_REFERRAL_RECEIVED:
      const { referral, grinScanId } = action.payload

      return {
        ...state,
        patient: {
          ...state.patient,
          grinScans: {
            ...state.patient.grinScans,
            items: updateWhere(
              state.patient.grinScans.items,
              grinScan => grinScanId === grinScan.id,
              grinScan => {
                grinScan.referrals.items = upsertItem({
                  array: grinScan.referrals.items,
                  item: referral,
                  mergeOldProperties: true
                })
              }
            )
          }
        }
      }
    case Actions.FETCH_PATIENT_RECORDS:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          scans: {
            ...state.patientCard.scans,
            isLoading: true
          }
        }
      }
    case Actions.FETCH_PATIENT_RECORDS_RECEIVED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          scans: {
            ...state.patientCard.scans,
            isLoading: false,
            data: mapRecords(action.payload)
          }
        }
      }
    case Actions.FETCH_PATIENT_RECORDS_FAILED:
      return {
        ...state,
        patientCard: {
          ...state.patientCard,
          scans: {
            ...state.patientCard.scans,
            isLoading: false
          }
        }
      }
    case Actions.SCAN_SUMMARY_LIVE_UPDATE_RECEIVED:
      const grinScan = action.payload
      return {
        ...state,
        patient: {
          ...state.patient,
          grinScans: {
            ...state.patient.grinScans,
            items: grinScan ? replaceItem(state.patient.grinScans.items, grinScan) : state.patient.grinScans.items
          }
        }
      }
    case Actions.TOGGLE_PATIENT_BRIEF_MODAL:
      return {
        ...state,
        patientBriefModal: {
          ...state.patientBriefModal,
          isOpen: action.payload.isOpen,
          saveStatus: initialState.patientBriefModal.saveStatus,
          mode: action.payload.mode,
          patientName: action.payload.patientName,
          patientId: action.payload.patientId || action.payload.originalPatientBrief?.patientId,
          doctorId: action.payload.doctorId || action.payload.originalPatientBrief?.doctorId,
          a_doctor: action.payload.doctorUsername || action.payload.originalPatientBrief?.a_doctor,
          treatmentType: action.payload.treatmentType,
          originalPatientBrief: action.payload.originalPatientBrief,
          analyticsSource: action.payload.analyticsSource
        }
      }
    case Actions.CREATE_PATIENT_BRIEF:
      return {
        ...state,
        patientBriefModal: {
          ...state.patientBriefModal,
          saveStatus: AsyncStatus.Loading
        }
      }
    case Actions.CREATE_PATIENT_BRIEF_FAILED:
      return {
        ...state,
        patientBriefModal: {
          ...state.patientBriefModal,
          saveStatus: AsyncStatus.Failed
        }
      }
    case Actions.CREATE_PATIENT_BRIEF_RECEIVED:
      return {
        ...state,
        patientBriefModal: {
          ...state.patientBriefModal,
          saveStatus: AsyncStatus.Completed
        },
        patientBrief: {
          ...state.patientBrief,
          data: [action.payload.patientBrief, ...(state.patientBrief?.data || [])]
        }
      }

    case Actions.FETCH_PATIENT_BRIEF:
      return {
        ...state,
        patientBrief: {
          ...state.patientBrief,
          loadingStatus: AsyncStatus.Loading
        }
      }
    case Actions.FETCH_PATIENT_BRIEF_RECEIVED:
      return {
        ...state,
        patientBrief: {
          data: [...(state.patientBrief.data || []), ...action.payload.items],
          nextToken: action.payload.nextToken,
          loadingStatus: AsyncStatus.Completed
        }
      }
    case Actions.FETCH_PATIENT_BRIEF_FAILED:
      return {
        ...state,
        patientBrief: {
          ...state.patientBrief,
          data: {},
          loadingStatus: AsyncStatus.Failed
        }
      }
    case Actions.RESOLVE_HI_NEEDS_ATTENTION_RECEIVED:
      return {
        ...state,
        patient: {
          ...state.patient,
          patientTags: {
            ...state.patient.patientTags,
            items: state.patient.patientTags.items.filter(patientTag => patientTag.tag?.value !== 'needsAttention')
          }
        }
      }
    case Actions.FETCH_PATIENT_NOTES:
      return {
        ...state,
        notes: {
          ...state.notes,
          status: AsyncStatus.Loading
        }
      }
    case Actions.FETCH_PATIENT_NOTES_RECEIVED:
      return {
        ...state,
        notes: {
          ...state.notes,
          status: AsyncStatus.Completed,
          data: action.payload
        }
      }
    case Actions.FETCH_PATIENT_NOTES_FAILED:
      return {
        ...state,
        notes: {
          ...state.notes,
          data: [],
          status: AsyncStatus.Failed
        }
      }
    default:
      return state
  }
}
