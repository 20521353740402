import * as React from 'react'

const DolphinIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9222 0.480957L0.529175 6.17413L6.22235 23.5671L23.6153 17.874L20.5737 8.58166L20.5526 8.59882C17.4469 8.07143 14.8701 9.55669 13.0401 11.4001C12.9633 11.5533 12.8264 11.8756 12.9166 12.0775C13.0857 12.4561 13.5624 12.1502 14.0025 11.8677C14.3305 11.6572 14.6383 11.4597 14.7832 11.5684C14.9784 11.7149 14.5172 12.5946 13.6802 13.1805C13.1689 13.5384 12.8595 13.4857 12.5355 13.4305C12.322 13.3941 12.102 13.3567 11.8136 13.435C11.5115 13.5171 11.2533 13.7226 11.0856 13.8861L11.05 13.9441C10.6632 14.5783 10.3971 15.178 10.1839 15.6585C9.89423 16.3113 9.70225 16.744 9.4379 16.744C8.84403 16.744 9.04195 15.0754 9.18336 14.2835C9.18336 14.2835 7.75771 13.3808 8.75915 10.9745C9.07306 10.2202 9.84769 8.75584 10.7501 7.67422C10.981 7.30855 11.2037 6.87195 11.4278 6.43277C11.7171 5.86571 12.0086 5.29435 12.3227 4.86584C12.7848 4.23526 13.4003 3.9571 13.6802 4.27193C13.7815 4.38594 13.6674 4.64832 13.5467 4.9258C13.3377 5.40618 13.109 5.9318 13.9446 5.81121C17.1466 4.87932 19.3135 5.6436 19.6564 5.77925L17.9222 0.480957Z"
      fill="#007AC3"
    />
  </svg>
)

export default DolphinIcon
