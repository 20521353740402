import React from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import FooterBar from './FooterBar'
import PatientRecommendation from './PatientRecommendation'
import PatientsOnboardingVideo from './PatientsOnboardingVideo'

const LandingPageFooter = () => {
  const { footerVariant } = useSelector(state => state.appReducer.rcLandingPage)

  return (
    <div>
      {!isMobile && <PatientsOnboardingVideo withBackground={!isMobile} />}
      {footerVariant === 'extended' && !isMobile && <PatientRecommendation />}
      <FooterBar />
    </div>
  )
}

export default LandingPageFooter
