import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import {
  setServiceAccountShowMore as setServiceAccountShowMoreToStorage,
  getServiceAccountShowMore as getServiceAccountShowMoreFromStorage
} from 'utils/storageUtils'
import ServicesToggle from '../ServicesToggle'
import ExpandMore from 'components/common/ExpandMore'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import ServiceAccountConfiguration from './ServiceAccountConfiguration'
import ServiceAccountDeactivateModal from './ServiceAccountDeactivateModal'
import ServiceAccountCreateAccountModal from './ServiceAccountCreateAccountModal'
import ServiceAccountResetPasswordModal from './ServiceAccountResetPasswordModal'
import { trackEvent } from 'utils/analyticsUtils'
import Actions from 'actions'
import { RecordsApp } from 'components/common/icons'
import ServiceAccountGuidance from './ServiceAccountGuidance'
import usePlanLimits from 'hooks/usePlanLimits'
import ServiceAccountBlockedForPlanModal from './ServiceAccountBlockedForPlanModal'

const useStyles = makeStyles(theme => ({
  description: {
    marginBottom: 10
  },
  expandMore: {
    textDecoration: 'underline',
    color: 'var(--text-color-25)',
    fontWeight: 400
  }
}))

const ServiceAccountToggle = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isLoading, user: serviceAccountUser } = useSelector(state => state.practiceReducer.serviceAccount)
  const { isLegacy1Plan, grinPlanKey, isFreePlan, isChurnedPlan } = usePlanLimits()

  const serviceAccountEmail = serviceAccountUser?.patient?.details?.email
  const isServiceAccountEnabled = !!serviceAccountUser?.isActive

  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false)
  const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] = useState(false)
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false)
  const [isRecordsAppBlockedForPlanOpen, setIsRecordsAppBlockedForPlanOpen] = useState(false)
  const [showMoreExpanded, setShowMoreExpanded] = useState(!!getServiceAccountShowMoreFromStorage())

  const activateServiceAccount = useCallback(() => {
    dispatch(Actions.activateServiceAccount())
  }, [dispatch])

  useEffect(() => {
    if (!isLoading) {
      setIsDeactivateModalOpen(false)
      setIsResetPasswordModalOpen(false)
    }
  }, [isLoading])

  const openDeactivateModal = useCallback(() => {
    trackEvent('Service account - Deactivate modal opened')
    setIsDeactivateModalOpen(true)
  }, [])

  const openCreateAccountModal = useCallback(() => {
    trackEvent('Service account - create account modal opened')
    setIsCreateAccountModalOpen(true)
  }, [])

  const openResetPasswordModal = useCallback(() => {
    trackEvent('Service account - reset password modal opened')
    setIsResetPasswordModalOpen(true)
  }, [])

  const openRecordsAppBlockedForPlanModal = useCallback(() => {
    trackEvent('Service account - records app blocked due to billing plan', {
      isLegacy1Plan,
      grinPlanKey,
      isChurnedPlan
    })
    setIsRecordsAppBlockedForPlanOpen(true)
  }, [isLegacy1Plan, grinPlanKey, isChurnedPlan])

  const handleToggleChange = useCallback(
    checked => {
      if (checked) {
        trackEvent('Service account - toggled', { mode: 'on' })

        if (!serviceAccountEmail) {
          if (isLegacy1Plan || isChurnedPlan) {
            openRecordsAppBlockedForPlanModal()
          } else {
            openCreateAccountModal()
          }
        } else {
          activateServiceAccount()
        }
      } else {
        trackEvent('Service account - toggled', { mode: 'off' })
        openDeactivateModal()
      }
    },
    [
      serviceAccountEmail,
      isLegacy1Plan,
      isChurnedPlan,
      openDeactivateModal,
      openCreateAccountModal,
      activateServiceAccount,
      openRecordsAppBlockedForPlanModal
    ]
  )

  const handleChangeShowMoreExpandable = useCallback(() => {
    setShowMoreExpanded(!showMoreExpanded)
    setServiceAccountShowMoreToStorage(!showMoreExpanded)
  }, [showMoreExpanded])

  useEffect(() => {
    setShowMoreExpanded(isServiceAccountEnabled)
  }, [isServiceAccountEnabled])

  return (
    <>
      <ServicesToggle
        title={t('pages.accountSettings.services.serviceAccount.title')}
        checked={isServiceAccountEnabled}
        onChange={handleToggleChange}
        isLoading={isLoading}
        tagComponent={<RecordsApp />}
        disabled={isFreePlan}
      >
        <ServiceAccountGuidance guidancesTypes={['howDoesItWork']} analyticSource="Settings" />
        <DazzedParagraph14 className={classes.description} strong>
          {t('pages.accountSettings.services.serviceAccount.description')}
        </DazzedParagraph14>
        <Collapse in={showMoreExpanded}>
          {
            <ServiceAccountConfiguration
              isLoading={isLoading}
              isActive={isServiceAccountEnabled}
              serviceAccountEmail={serviceAccountEmail}
              onDeactivatePressed={openDeactivateModal}
              onResetPasswordPressed={openResetPasswordModal}
              onCreateAccountPressed={openCreateAccountModal}
              isCreateAccountDisabled={isChurnedPlan}
              onActivatePressed={() => {
                trackEvent('Service account - Activate account pressed')
                activateServiceAccount()
              }}
            />
          }
        </Collapse>
        <DazzedParagraph14 strong>
          <ExpandMore
            showMoreText={t('pages.accountSettings.services.serviceAccount.showMoreSettings')}
            showLessText={t('pages.accountSettings.services.serviceAccount.hideMoreSettings')}
            expanded={showMoreExpanded}
            onClick={handleChangeShowMoreExpandable}
            className={classes.expandMore}
          />
        </DazzedParagraph14>
      </ServicesToggle>
      {isDeactivateModalOpen && (
        <ServiceAccountDeactivateModal
          isOpen={isDeactivateModalOpen}
          isLoading={isLoading}
          onClose={() => setIsDeactivateModalOpen(false)}
          serviceAccountEmail={serviceAccountEmail}
        />
      )}
      {isResetPasswordModalOpen && (
        <ServiceAccountResetPasswordModal
          isOpen={isResetPasswordModalOpen}
          isLoading={isLoading}
          onClose={() => setIsResetPasswordModalOpen(false)}
          serviceAccountEmail={serviceAccountEmail}
        />
      )}
      {isCreateAccountModalOpen && (
        <ServiceAccountCreateAccountModal
          isOpen={isCreateAccountModalOpen}
          isLoading={isLoading}
          onClose={() => setIsCreateAccountModalOpen(false)}
          serviceAccountEmail={serviceAccountEmail}
        />
      )}
      {isRecordsAppBlockedForPlanOpen && (
        <ServiceAccountBlockedForPlanModal
          isOpen={isRecordsAppBlockedForPlanOpen}
          isLoading={isLoading}
          onClose={() => setIsRecordsAppBlockedForPlanOpen(false)}
        />
      )}
    </>
  )
}

export default ServiceAccountToggle
