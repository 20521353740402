import { Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { InfoSmall } from 'components/common/icons'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import OggHeading40 from 'components/common/text/OggHeading40'
import { ROUTES } from 'consts'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { trackEvent } from 'utils/analyticsUtils'
import AutomationRules from './AutomationRules'
import AutomationRuleEditorModal from './AutomationRulesEditorModal/AutomationRuleEditorModal'
import AutomationRulesInfoModal from './AutomationRulesInfoModal'
import DeleteAutomationRuleModal from './DeleteAutomationRuleModal'
import ShareFeedback from './ShareFeedback'
import ToggleRuleConfirmationPopup from './ToggleRuleConfirmationPopup'
import useRolePermissions from 'hooks/useRolePermissions'
import useFeatureFlags from 'hooks/useFeatureFlags'
import usePlanLimits from 'hooks/usePlanLimits'
import AutomationPreview from './AutomationPreview'
import AutomationsBlockedForPlanModal from './AutomationsBlockedForPlanModal'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingBottom: 50
  },
  heading: {
    marginBottom: 20
  },
  description: {
    whiteSpace: 'pre'
  },
  divider: {
    width: '55%',
    height: 1,
    backgroundColor: 'var(--border-color-13)',
    marginBottom: 25
  }
}))

const Automations = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { permissions } = useRolePermissions()
  const { practiceAutomations: practiceAutomationsFF = false } = useFeatureFlags()
  const { isLegacy1Plan } = usePlanLimits()

  const isUpgradeRequired = useMemo(
    () => isLegacy1Plan && !practiceAutomationsFF,
    [isLegacy1Plan, practiceAutomationsFF]
  )

  const { isOpen: isEditorModalOpen } = useSelector(state => state.practiceReducer.automationRules.editorModal)

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAutomationsBlockedForPlanOpen, setIsAutomationsBlockedForPlanOpen] = useState(false)

  const handleInfoClicked = useCallback(() => {
    trackEvent('Automation rules - Info modal opened')
    setIsInfoModalOpen(true)
  }, [])

  const handleUpgradePlanClicked = useCallback(() => {
    trackEvent('Automations - Upgrade my plan clicked')
    setIsAutomationsBlockedForPlanOpen(true)
  }, [])

  useEffect(() => {
    !permissions.automations && history.push(ROUTES.ACCOUNT_SETTINGS)
  }, [permissions, history])

  return (
    <>
      <Grid container direction="column" className={classes.root}>
        <Grid item>
          <OggHeading40 textAlign="left" className={classes.heading}>
            {t('pages.accountSettings.automations.title')}
          </OggHeading40>
        </Grid>
        <Grid item>
          <div className={classes.divider} />
        </Grid>
        <Grid item>
          <DazzedParagraph14 strong className={classes.description}>
            {t('pages.accountSettings.automations.description')}
            <IconButton onClick={handleInfoClicked} size="small" disableRipple>
              <InfoSmall />
            </IconButton>
          </DazzedParagraph14>
        </Grid>
        {!isUpgradeRequired && (
          <Grid item>
            <ShareFeedback />
          </Grid>
        )}
        <Grid item>
          {isUpgradeRequired ? (
            <AutomationPreview onUpgradePlanClicked={handleUpgradePlanClicked} />
          ) : (
            <AutomationRules />
          )}
        </Grid>
      </Grid>
      <AutomationRulesInfoModal isOpen={isInfoModalOpen} setIsOpen={setIsInfoModalOpen} />
      {isEditorModalOpen && <AutomationRuleEditorModal />}
      <DeleteAutomationRuleModal />
      <ToggleRuleConfirmationPopup />
      {isAutomationsBlockedForPlanOpen && (
        <AutomationsBlockedForPlanModal
          isOpen={isAutomationsBlockedForPlanOpen}
          onClose={() => setIsAutomationsBlockedForPlanOpen(false)}
        />
      )}
    </>
  )
}

export default Automations
