import { useEffect, useState, useMemo } from 'react'

export default function useInViewport(ref, intersectionObserverOptions) {
  const [isInViewport, setIsInViewport] = useState(false)
  const [intersectionRatio, setIntersectionRatio] = useState()

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsInViewport(entry.isIntersecting)
        setIntersectionRatio(entry.intersectionRatio)
      }, intersectionObserverOptions),
    [intersectionObserverOptions]
  )

  useEffect(() => {
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [observer, ref])

  return { isInViewport, intersectionRatio }
}
