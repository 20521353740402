import * as React from 'react'

function InfoIcon({ color = '#001335', width = 25, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox={`0 0 25 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0713 12C21.0713 16.9704 17.0423 21 12.0709 21C7.10107 21 3.07129 16.9704 3.07129 12C3.07129 7.02961 7.10107 3 12.0709 3C17.0423 3 21.0713 7.02961 21.0713 12ZM10.9463 10.875H13.1963V16.5H10.9463V10.875ZM13.1963 7.5H10.9463V9.75H13.1963V7.5Z"
        fill={color}
      />
    </svg>
  )
}

export default InfoIcon
