import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import GrinFloatingPopup from 'components/common/menu/GrinFloatingPopup'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  bodyContainer: {
    padding: 8
  }
}))

const PatientBriefFloatingPopup = ({
  title,
  isOpen,
  onCancel,
  onConfirm,
  anchorEl,
  children,
  description,
  isPrimaryBtnDisabled,
  className
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <GrinFloatingPopup
      title={title}
      open={isOpen}
      onClose={onCancel}
      onOpen={() => {}}
      className={className}
      customAnchorEl={anchorEl}
      primaryBtnLabel={t('general.confirm')}
      onPrimaryBtnClick={onConfirm}
      secondaryBtnLabel={t('general.cancel')}
      onSecondaryBtnClick={onCancel}
      buttonProps={{ width: 145 }}
      withCloseIcon={false}
      isPrimaryBtnDisabled={isPrimaryBtnDisabled}
    >
      <div className={classes.bodyContainer}>
        {description && (
          <>
            <DazzedParagraph14 strong>{description}</DazzedParagraph14>
            <div style={{ height: 32 }} />
          </>
        )}
        {children}
      </div>
    </GrinFloatingPopup>
  )
}

export default PatientBriefFloatingPopup
