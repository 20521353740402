import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.main,
    fontFamily: 'Dazzed',
    fontWeight: 600,
    fontSize: 36,
    lineHeight: '40px'
  },
  thin: {
    fontWeight: '400 !important'
  },
  strong: {
    fontWeight: '500 !important'
  }
}))

const DazzedHeading36 = ({ className, children, thin }) => {
  const classes = useStyles()

  return <div className={[classes.heading, thin ? classes.thin : '', className].join(' ')}>{children}</div>
}

export default DazzedHeading36
