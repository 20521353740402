import * as React from 'react'

const ResendInvite = ({ ...props }) => {
  return (
    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6.5" y="5.5" width="17" height="12" rx="1.5" fill="white" stroke="#282828" />
      <path d="M7 6L14.2929 13.2929C14.6834 13.6834 15.3166 13.6834 15.7071 13.2929L23 6" stroke="#282828" />
      <path d="M7 17L12.5 11.5" stroke="#282828" />
      <path d="M23 17L17.5 11.5" stroke="#282828" />
      <path
        d="M18 15.9H17.5V16.4V18.6V19.1H18H24.5V21V22.2071L25.3536 21.3536L28.8536 17.8536L29.2071 17.5L28.8536 17.1464L25.3536 13.6464L24.5 12.7929V14V15.9H18Z"
        fill="#282828"
        stroke="white"
      />
    </svg>
  )
}

export default ResendInvite
