import * as React from 'react'

const ExclamationMarkCircle = () => {
  return (
    <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="21.6532"
        cy="21.6532"
        r="21.6532"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 30.623 0)"
        stroke="#3C52EF"
      />
      <path d="M30.623 17.3716V36.1161" stroke="#3C52EF" strokeLinecap="round" />
      <path d="M30.623 40.6411V43.2266" stroke="#3C52EF" strokeLinecap="round" />
    </svg>
  )
}

export default ExclamationMarkCircle
