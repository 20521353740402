import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SmallDotIcon from '../../icons/SmallDot'

const useStyles = ({ direction }) =>
  makeStyles({
    container: {
      display: 'flex',
      margin: '3px 0',
      '& svg': {
        marginRight: 10,
        marginTop: 9
      }
    },
    subtitle: {
      fontSize: 11,
      marginTop: 3,
      marginLeft: direction === 'row' ? 5 : 0
    },
    textContainer: {
      display: 'flex',
      flexDirection: direction,
      alignItems: direction === 'row' ? 'center' : 'flex-start'
    }
  })({ direction })

const TreatmentStatusLabel = ({ status, direction = 'row', showSubtitle = true }) => {
  const classes = useStyles({ direction })
  const { t } = useTranslation()
  const { data } = useSelector(state => state.treatmentReducer.statuses)
  const [type, program] = useMemo(() => status.split('_'), [status])

  const currentStatus = useMemo(
    () => data[program]?.find(statusObj => statusObj.type === type) || {},
    [data, program, type]
  )
  const statusSettings = useMemo(() => JSON.parse(currentStatus.settings || '{}'), [currentStatus])
  const subtitleText = useMemo(() => {
    if (!currentStatus.hasLimitedScans) {
      return t('statuses.menu.subtitles.unlimited')
    }

    if (currentStatus.maxScansPerPeriod > 0) {
      return t('statuses.menu.subtitles.limited', { maxScansPerPeriod: currentStatus.maxScansPerPeriod })
    }

    return t('statuses.menu.subtitles.scansNotAvailable')
  }, [currentStatus.hasLimitedScans, currentStatus.maxScansPerPeriod, t])

  return (
    <div className={classes.container}>
      <SmallDotIcon color={statusSettings.color} />
      <div className={classes.textContainer}>
        {t(`statuses.names.${currentStatus.type}`)}
        {showSubtitle && <div className={classes.subtitle}>({subtitleText})</div>}
      </div>
    </div>
  )
}

export default TreatmentStatusLabel
